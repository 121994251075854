import { TranslateService } from '@ngx-translate/core';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from '../services/notification-toaster.service';
import {
  ButtonToasterElement,
  ButtonToasterElementStyle,
} from '../ui/notification-toaster/button-toaster-element/button-toaster-element.component';
import { IconMessageToasterElement } from '../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { ERRORS, REQUESTS } from '../common/utils/notification-constants';
import { ToasterPopupStyle } from '../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { DevicesManagerService } from '../services/devices-manager.service';
import { DEVICE } from '../sessions/panel/participants-manager/participants-manager.component';
import { intercomArticles } from '../intercom-articles';

export class DevicesNotifications {
  constructor(
    private translateService: TranslateService,
    private notificationToasterService: NotificationToasterService,
    private devicesManagerService: DevicesManagerService,
  ) {}

  showGrantMicAccessNotification(): void {
    this.showGrantDeviceAccessNotification(
      'mic',
      this.translateService.instant('Grant microphone access'),
      ERRORS.GRANT_MICROPHONE_ACCESS,
      this.translateService.instant(
        'Please enable microphone access in your browser settings to join with audio.',
      ),
      990,
    );
  }

  showGrantCamAccessNotification(): void {
    this.showGrantDeviceAccessNotification(
      'videocam',
      this.translateService.instant('Grant camera access'),
      ERRORS.GRANT_CAMERA_ACCESS,
      this.translateService.instant(
        'Please enable camera access in your browser settings to join with video.',
      ),
      980,
    );
  }

  showGrantScreenshareAccessNotification() {
    const title = new IconMessageToasterElement(
      { icon: 'screen_share', size: 16 },
      this.translateService.instant('Screenshare access denied'),
    );
    const message = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(
        'Please enable screen sharing permissions for your browser in your computer’s System Preferences.',
      ),
    );
    const screenShareHelpButton = new ButtonToasterElement(
      [{ icon: 'help_outline', size: 16 }, this.translateService.instant('Need help?')],
      {
        handler: () => {
          window.open(intercomArticles.HowToEnableScreenSharingPermissions);
        },
        close: false,
      },
      ButtonToasterElementStyle.RAISED,
    );
    const grantShareScreenNotificationData = new NotificationDataBuilder(
      ERRORS.GRANT_SCREENSHARE_ACCESS,
    )
      .type(NotificationType.ERROR)
      .style(ToasterPopupStyle.ERROR)
      .topElements([title])
      .middleElements([message])
      .bottomElements([screenShareHelpButton])
      .priority(980)
      .build();
    this.notificationToasterService.showNotification(grantShareScreenNotificationData);
  }

  showScreenshareFailedNotification() {
    const title = new IconMessageToasterElement(
      { icon: 'screen_share', size: 16 },
      this.translateService.instant('Screenshare failed'),
    );
    const message = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(
        'Something went wrong while trying to start the screenshare. Please try again. If you continue to run into issues, please restart your browser.',
      ),
    );
    const screenShareHelpButton = new ButtonToasterElement(
      [{ icon: 'help_outline', size: 16 }, this.translateService.instant('Need help?')],
      {
        handler: () => {
          window.open(intercomArticles.WhatToDoWhenYourScreenShareFails);
        },
        close: false,
      },
      ButtonToasterElementStyle.RAISED,
    );
    const grantShareScreenNotificationData = new NotificationDataBuilder(
      ERRORS.GRANT_SCREENSHARE_ACCESS,
    )
      .type(NotificationType.ERROR)
      .style(ToasterPopupStyle.ERROR)
      .topElements([title])
      .middleElements([message])
      .bottomElements([screenShareHelpButton])
      .priority(980)
      .build();
    this.notificationToasterService.showNotification(grantShareScreenNotificationData);
  }

  private showGrantDeviceAccessNotification(
    titleIcon: string,
    titleMsg: string,
    notificationCode: string,
    bodyMsg: string,
    priority: number,
  ) {
    const button = new ButtonToasterElement(
      [{ icon: 'help_outline', size: 16 }, this.translateService.instant('Need help?')],
      {
        handler: () => {
          window.open(intercomArticles.SettingUpYourVideoAndMicrophonePermissionsInSpaces);
        },
        close: false,
      },
      ButtonToasterElementStyle.RAISED,
    );

    const titleElement = new IconMessageToasterElement({ icon: titleIcon, size: 16 }, titleMsg);
    const messageElement = new IconMessageToasterElement(undefined, bodyMsg);
    const grantDeviceAccessNotificationData = new NotificationDataBuilder(notificationCode)
      .type(NotificationType.ERROR)
      .style(ToasterPopupStyle.ERROR)
      .topElements([titleElement])
      .middleElements([messageElement])
      .bottomElements([button])
      .dismissable(true)
      .priority(priority)
      .build();

    this.notificationToasterService.showNotification(grantDeviceAccessNotificationData);
  }

  showCamNotFoundNotification(): void {
    this.showDeviceNotFoundNotification(
      this.translateService.instant('Camera not found'),
      this.translateService.instant(
        'Please verify that your camera is connected correctly, or select another camera in device settings.',
      ),
      ERRORS.CAMERA_NOT_FOUND,
      960,
    );
  }

  showMicNotFoundNotification(): void {
    this.showDeviceNotFoundNotification(
      this.translateService.instant('Microphone not found'),
      this.translateService.instant(
        'Please verify that your mic is connected correctly or select another microphone in device settings.',
      ),
      ERRORS.MICROPHONE_NOT_FOUND,
      970,
    );
  }

  private showDeviceNotFoundNotification(
    titleMsg: string,
    bodyMsg: string,
    notificationCode: string,
    priority: number,
  ): void {
    const settingsButton = new ButtonToasterElement(
      [{ icon: 'settings', size: 16 }, this.translateService.instant('Settings')],
      {
        handler: () => this.devicesManagerService.openDeviceSelection(),
        close: false,
      },
      ButtonToasterElementStyle.RAISED,
    );

    const needHelpButton = new ButtonToasterElement(
      [{ icon: 'help_outline', size: 16 }, this.translateService.instant('Need help?')],
      {
        handler: () => {
          window.open(intercomArticles.HowToChangeYourCameraAndMicrophoneInSpaces);
        },
        close: false,
      },
      ButtonToasterElementStyle.FLAT,
    );

    const titleElement = new IconMessageToasterElement({ icon: 'warning', size: 16 }, titleMsg);
    const messageElement = new IconMessageToasterElement(undefined, bodyMsg);
    const micNotFoundNotificationData = new NotificationDataBuilder(notificationCode)
      .type(NotificationType.ERROR)
      .style(ToasterPopupStyle.ERROR)
      .topElements([titleElement])
      .middleElements([messageElement])
      .bottomElements([settingsButton, needHelpButton])
      .priority(priority)
      .build();
    this.notificationToasterService.showNotification(micNotFoundNotificationData);
  }

  showCamInUseByAnotherAppNotification(): void {
    this.showDeviceInUseByAnotherAppNotification(
      this.translateService.instant('No camera input detected'),
      this.translateService.instant(
        'Please check that your camera is configured correctly and is not being used by another application.',
      ),
      ERRORS.NO_CAMERA_INPUT_DETECTED,
      960,
    );
  }

  showMicInUseByAnotherAppNotification(): void {
    this.showDeviceInUseByAnotherAppNotification(
      this.translateService.instant('Microphone not sending audio'),
      this.translateService.instant(
        'Please check that your microphone is configured correctly and is not being used by another application.',
      ),
      ERRORS.MICROPHONE_NOT_SENDING_AUDIO,
      970,
    );
  }

  private showDeviceInUseByAnotherAppNotification(
    titleMsg: string,
    bodyMsg: string,
    notificationCode: string,
    priority: number,
  ) {
    const settingsButton = new ButtonToasterElement(
      [{ icon: 'settings', size: 16 }, this.translateService.instant('Settings')],
      {
        handler: () => this.devicesManagerService.openDeviceSelection(),
        close: false,
      },
      ButtonToasterElementStyle.RAISED,
    );

    const needHelpButton = new ButtonToasterElement(
      [{ icon: 'help_outline', size: 16 }, this.translateService.instant('Need help?')],
      {
        handler: () => {
          window.open(intercomArticles.SettingUpYourVideoAndMicrophonePermissionsInSpaces);
        },
        close: false,
      },
      ButtonToasterElementStyle.FLAT,
    );

    const titleElement = new IconMessageToasterElement({ icon: 'warning', size: 16 }, titleMsg);
    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(bodyMsg),
    );
    const noMicInputNotificationData = new NotificationDataBuilder(notificationCode)
      .type(NotificationType.ERROR)
      .style(ToasterPopupStyle.ERROR)
      .topElements([titleElement])
      .middleElements([messageElement])
      .bottomElements([settingsButton, needHelpButton])
      .priority(priority)
      .build();
    this.notificationToasterService.showNotification(noMicInputNotificationData);
  }

  getDeviceNotificationCodes(device: DEVICE): string[] {
    switch (device) {
      case DEVICE.MIC:
        return [
          ERRORS.GRANT_MICROPHONE_ACCESS,
          ERRORS.MICROPHONE_NOT_FOUND,
          ERRORS.MICROPHONE_NOT_SENDING_AUDIO,
          ERRORS.FAILED_TO_START_MICROPHONE,
          REQUESTS.REQUEST_TO_UNMUTE,
        ];
      case DEVICE.CAM:
        return [
          ERRORS.GRANT_CAMERA_ACCESS,
          ERRORS.CAMERA_NOT_FOUND,
          ERRORS.NO_CAMERA_INPUT_DETECTED,
          ERRORS.FAILED_TO_START_CAMERA,
          REQUESTS.REQUEST_TO_ENABLE_CAMERA,
        ];
    }
  }

  showCamGeneralErrorNotification(): void {
    this.showDeviceGeneralErrorNotification(
      this.translateService.instant('Failed to start camera'),
      this.translateService.instant(
        'Verify that your camera is set-up correctly and try again. If issues persist, restart your browser or select another camera in device settings.',
      ),
      ERRORS.FAILED_TO_START_CAMERA,
      1050,
    );
  }

  showMicGeneralErrorNotification(): void {
    this.showDeviceGeneralErrorNotification(
      this.translateService.instant('Failed to start microphone'),
      this.translateService.instant(
        'Verify that your microphone is set-up correctly and try again. If issues persist, restart your browser or select another microphone in device settings.',
      ),
      ERRORS.FAILED_TO_START_MICROPHONE,
      1060,
    );
  }

  showDeviceGeneralErrorNotification(
    titleMsg: string,
    bodyMsg: string,
    notificationCode: string,
    priority: number,
  ): void {
    const settingsButton = new ButtonToasterElement(
      [{ icon: 'settings', size: 16 }, this.translateService.instant('Settings')],
      {
        handler: () => this.devicesManagerService.openDeviceSelection(),
        close: false,
      },
      ButtonToasterElementStyle.RAISED,
    );

    const needHelpButton = new ButtonToasterElement(
      [{ icon: 'help_outline', size: 16 }, this.translateService.instant('Need help?')],
      {
        handler: () => {
          window.open(intercomArticles.SettingUpYourVideoAndMicrophonePermissionsInSpaces);
        },
        close: false,
      },
      ButtonToasterElementStyle.FLAT,
    );

    const titleElement = new IconMessageToasterElement({ icon: 'warning', size: 16 }, titleMsg);
    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(bodyMsg),
    );
    const deviceGeneralErrorNotificationData = new NotificationDataBuilder(notificationCode)
      .type(NotificationType.ERROR)
      .style(ToasterPopupStyle.ERROR)
      .topElements([titleElement])
      .middleElements([messageElement])
      .bottomElements([settingsButton, needHelpButton])
      .priority(priority)
      .build();
    this.notificationToasterService.showNotification(deviceGeneralErrorNotificationData);
  }
}

<div class="select-file-container" appDragAndDropFile (fileDropped)="onFileDropped($event)">
  <div class="select-file-content">
    <app-pencil-icon class="select-file-import-icon" [name]="'import'" [type]="IconTypes.svgIcon">
    </app-pencil-icon>
    <p class="select-file-drag-drop-text">{{ 'Drag & drop your file here' | translate }}</p>

    <div
      class="no-file-selected-content"
      [hidden]="
        (file$ | async) !== undefined &&
        uploadFileComputerService.fileUploadError$.value === undefined
      "
    >
      <div class="select-file-or-separator-container">
        <mat-divider class="select-file-or-separator-dash"></mat-divider>
        <p class="select-file-or-separator-text">or</p>
        <mat-divider class="select-file-or-separator-dash"></mat-divider>
      </div>

      <input
        #fileUpload
        style="display: none"
        type="file"
        [accept]="supportedDocumentTypes"
        required
        (change)="selectFile($event)"
        data-name="upload-tool-selection-computer-add-file"
      />

      <app-pencil-button
        class="pencil-button primary medium"
        [label]="customButtonText ?? 'Select file' | translate"
        [icon]="'attach_file'"
        [iconConfig]="{ type: IconTypes.materialIcon }"
        (clickEvent)="fileUpload.click()"
        data-name="upload-select-file"
      >
      </app-pencil-button>
      <p class="select-file-max-size-text">
        {{ MAX_FILE_SIZE_IN_MB }} {{ 'MB max file size' | translate }}
      </p>
      <div
        *ngIf="(uploadFileComputerService.fileUploadError$ | async) !== undefined"
        class="select-file-error-container"
      >
        <app-pencil-icon
          class="select-file-error-icon"
          [name]="'error'"
          [type]="IconTypes.matIcon"
        ></app-pencil-icon>
        <p class="select-file-error-text">{{ errorMessage$ | async }}</p>
      </div>
    </div>
  </div>

  <p
    *ngIf="
      (file$ | async) !== undefined &&
      uploadFileComputerService.fileUploadError$.value === undefined
    "
    class="select-file-selected-name-text"
  >
    {{ (file$ | async)?.name }}
  </p>

  <div
    class="upload-file-bottom-bar"
    *ngIf="
      (file$ | async) !== undefined &&
      uploadFileComputerService.fileUploadError$.value === undefined
    "
  >
    <div style="flex-grow: 1"></div>
    <app-pencil-button
      class="pencil-button bottom-bar-button bottom-bar-button-bordered light medium"
      [label]="'Select another file' | translate"
      [icon]="'attach_file' | translate"
      [iconConfig]="{ type: IconTypes.materialIcon }"
      (clickEvent)="fileUpload.click()"
    >
    </app-pencil-button>
    <div style="width: 8px"></div>
    <app-pencil-button
      class="pencil-button bottom-bar-button primary medium"
      [label]="'Import file' | translate"
      [icon]="'arrow_forward'"
      [iconConfig]="{ type: IconTypes.matIcon }"
      (clickEvent)="importFile()"
    >
    </app-pencil-button>
  </div>
</div>

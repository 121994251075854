import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { ISuggestionsGroup, ISuggestion } from 'src/app/models/searching';

/**
 * Note: Tags here means Pills/chips that appear inside the search bar. so TagInput here means allowing this type of pills to appear in the search bar.
 */
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  // The placeholder for the search bar.
  @Input() searchTitle = 'Search';

  // this option makes the value of the search being updated whenever any change occurs to the input.
  @Input() searchContinuously = false;

  // Option to search on clear/reset
  @Input() searchOnClear = true;

  // The available options of the autocomplete.
  @Input() suggestionGroups?: ISuggestionsGroup[];

  // Hybrid mode is an option where the search bar Show Filter suggestions and allow the user to
  // add filters by clicking on them (the suggestions is given by 'suggestions' and the selected
  // suggestion is emitted by 'addFilter'), But if the user press 'enter' it will behave normally
  // and will emit the input as a normal search bar.
  // it's by default false, since not all search bar has filters and suggestions.
  @Input() HybridFilterMode = false;

  // It allows to have tags in the search bar.
  @Input() TagInput = false;

  // Used when TagInput is enabled, used to keep track of filters being added from external world.
  @Input() editFilterEvent?: Observable<ISuggestion>;

  // To Allow the host component to change the value of the search bar.
  @Input() editSearchValue?: string;

  // For the suggestion header.
  @Input() searchForLabel?: string; // the type of items that the search bar is used for (files, folders, ..etc).
  @Input() suggestionTypeLabel?: string; // The type of suggestions (Topics, Types, ...etc).

  @Input() debounceDueTime = 100;

  // Filter is used to filter on a given input string, search by name.
  @Output() filter = new EventEmitter<string>();

  // it's used to edit Custom filter based on the suggestions.
  @Output() editFilter = new EventEmitter<ISuggestion>();

  // Keep track of search event.
  lastSearch = false;

  filteredOptions: Observable<ISuggestion[]>;
  searchControl = new UntypedFormControl('');
  tags: ISuggestion[] = [];
  previousSearchValue = '';

  constructor() {
    /**
     * To Update the autocomplete panel.
     */
    this.filteredOptions = this.searchControl.valueChanges.pipe(
      debounceTime(this.debounceDueTime),
      map((value) => this._filter(value)),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.editSearchValue &&
      changes.editSearchValue.previousValue !== changes.editSearchValue.currentValue
    ) {
      this.searchControl.setValue(changes.editSearchValue.currentValue);
    }
  }

  ngOnInit() {
    /**
     * Get Fired When new value for search bar is provided from the host (external word).
     */
    if (this.editFilterEvent) {
      this.editFilterEvent.pipe(untilDestroyed(this)).subscribe((suggestion) => {
        const index = this.tags.findIndex((tag) => this.equalSingleSuggestion(tag, suggestion));
        if (index < 0) {
          this.tags.push(suggestion);
        } else {
          this.tags.splice(index, 1);
        }
      });
    }

    /**
     * To detect input change for searching.
     */
    this.searchControl.valueChanges
      .pipe(
        debounceTime(this.debounceDueTime),
        distinctUntilChanged(),
        map((value) => value.toLowerCase()),
      )
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.detectInputChange(value);
      });
  }

  private _filter(value: any): ISuggestion[] {
    // If no suggestions, Or the user just Pressed 'Enter', then it's not enabled.
    if (!this.suggestionGroups || !value.length) {
      return [];
    }

    // For regex matching.
    const regex = new RegExp(value, 'i');

    // The returned filtered list.
    const ans: ISuggestion[] = [];
    this.suggestionGroups.forEach((group) => {
      group.suggestions.forEach((suggestion) => {
        // See if the suggestions contains the value (user input).
        const filteredFilters = suggestion.secondary.filter((filter) => {
          const suggestionName = suggestion.main?.name?.length
            ? filter.name.concat(' in ').concat(suggestion.main.name)
            : filter.name;
          return suggestionName.match(regex);
        });
        if (filteredFilters.length) {
          ans.push({
            main: suggestion.main,
            secondary: filteredFilters,
            metaData: suggestion.metaData,
          });
        }
      });
    });
    // to randomize the filtered list.
    return ans.sort((a, b) => a.secondary[0].name.localeCompare(b.secondary[0].name));
  }

  search(): void {
    // To dismiss suggestions Overlay.
    this.lastSearch = true;

    // Prevent uneccesary search
    if (this.previousSearchValue !== this.searchControl.value || this.previousSearchValue !== this.editSearchValue) {
      // Emit the search value to the Parent.
      this.filter.emit(this.searchControl.value);
      this.previousSearchValue = this.searchControl.value;
    }
  }

  reset(): void {
    this.searchControl.reset('');
    if (!this.searchContinuously) {
      this.search();
    }
  }

  refreshOnEmpty() {
    if (this.searchControl.value.length == 0 && !this.searchContinuously && this.searchOnClear) {
      this.search();
    }
  }

  /**
   * it's called when a suggestion (from Autocomplete) is clicked to add a new filter,
   * and emit an event to the parent component.
   * @param secondary is the secondary element from the suggestion being clicked.
   */
  selectingSuggestion(
    suggestion: ISuggestion,
    secondary: { name: string; ids: Set<string> },
  ): void {
    // If it's not in the hybridMode then ignore.
    if (!this.HybridFilterMode) {
      return;
    }

    const suggestClone = {
      main: suggestion.main,
      secondary: [secondary],
      metaData: {
        ...suggestion.metaData,
        enable: true,
      },
    };
    // To see if that tag is duplicated, if so then ignore it, otherwise Add it and emit an event.
    if (!this.tags.find((suggest) => this.equalSingleSuggestion(suggest, suggestClone))) {
      this.editFilter.emit(suggestClone);
      if (this.TagInput) {
        this.tags.push(suggestClone);
      }
    }
    this.searchControl.reset('');
  }

  /**
   * it delete a filter and emits an event to the parent component.
   * @param index index of the filter in tags.
   */
  deleteFilter(index: number): void {
    const deleted = this.tags.splice(index, 1);
    if (!deleted) {
      return;
    }
    if (deleted[0].metaData) {
      deleted[0].metaData.enable = false;
    }
    this.editFilter.emit(deleted[0]);
  }

  detectInputChange(value: string): void {
    // To show dialog when input change.
    this.lastSearch = false;
    if (this.searchContinuously) {
      this.filter.emit(value);
    }
  }

  private equalSingleSuggestion(a: ISuggestion, b: ISuggestion): boolean {
    return a.main?.name === b.main?.name && b.secondary[0].name === a.secondary[0].name;
  }
}

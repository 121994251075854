import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as Sentry from '@sentry/browser';
import {
  CallParticipant,
  CallProvider,
  RTCDevices,
  RTCInterface,
  TrackType,
} from '../common/interfaces/rtc-interface';
import { User } from '../models/user';
import { DEFAULT_DEVICE_ID } from '../common/utils/devices-handle-util';
import { DailyService } from './daily.service';
import { UserService } from './user.service';
import { VideoAIService } from './video-ai.service';
import { LivekitService } from './livekit.service';
import { SpaceRecordingService } from './space-recording.service';
import { TelemetryService } from './telemetry.service';
import { FLAGS, FlagsService } from './flags.service';

export interface HideSelfViewState {
  active: boolean;
  localParticipant: CallParticipant | undefined;
}

export enum StopVideoCallReason {
  LEAVE_CALL = 'leave_call',
  END_CALL_FOR_EVERYONE = 'end_call_for_everyone',
  LEAVE_CALL_WARNING_MODAL = 'leave_call_warning_modal',
  FORCE_LEAVE_CALL_WARNING_MODAL = 'force_leave_call_warning_modal',
  LEAVE_CALL_PROGRAMMATICALLY = 'leave_call_programmatically',
}

// Prefix for remote video containers
// eslint-disable-next-line frontend-rules/ngx-translate-service
const remoteVideoPrefix = 'remote-player-container-';
// eslint-disable-next-line frontend-rules/ngx-translate-service
const remoteAudioPrefix = 'remote-audio-player-container-';

@Injectable({
  providedIn: 'root',
})
export class RtcServiceController {
  service!: RTCInterface;
  user!: User;
  _provider: CallProvider = CallProvider.DAILY;
  providerChanged: BehaviorSubject<CallProvider | null> = new BehaviorSubject<CallProvider | null>(
    null,
  );
  mediaDeviceKinds: MediaDeviceKind[] = ['audioinput', 'audiooutput', 'videoinput'];
  resetOnStopVideoCallRTC = new Subject<boolean>();
  // Used BehaviorSubject to keep tracking of latest hide self view state
  hideSelfViewState = new BehaviorSubject<HideSelfViewState>({
    active: false,
    localParticipant: undefined,
  });

  constructor(
    private injector: Injector,
    private userService: UserService,
    private videoAIService: VideoAIService,
    private telemetry: TelemetryService,
    private flagsService: FlagsService,
  ) {
    // Load the default provider
    this.service = this.injector.get<RTCInterface>(DailyService);

    this.userService.user.subscribe((user) => {
      if (user) {
        this.user = user.user;
      }
    });

    this.setRTCSessionVars();
  }

  set provider(provider: CallProvider) {
    if (this._provider !== provider) {
      switch (provider) {
        case CallProvider.DAILY:
          this.service = this.injector.get<RTCInterface>(DailyService);
          break;
        case CallProvider.LIVEKIT:
          this.service = this.injector.get<RTCInterface>(LivekitService);
          break;
        default:
          this.service = this.injector.get<RTCInterface>(DailyService);
          break;
      }
      this._provider = provider;
      this.providerChanged.next(this._provider);
      this.service.isNoiseCancellationEnabled = this.flagsService.isFlagEnabled(
        FLAGS.NOISE_CANCELLATION_DEFAULT_STATE,
      );
    }
    this.setRTCSessionVars();
  }

  clearProviderChanged() {
    this.providerChanged.next(null);
  }

  stopRemoteAudio(participant: CallParticipant, trackType: TrackType, prefix: string): void {
    const containerId = `${prefix}${remoteAudioPrefix}${trackType}-${participant.participantId}`;
    const audioPlayer = document.getElementById(containerId) as HTMLAudioElement;
    if (!audioPlayer) {
      return;
    }
    audioPlayer.pause();
  }
  // Attempts to play remote audio for a participant, the success of the function
  // is a boolean. This is used to retry again when data may be available
  async playRemoteAudio(
    track: MediaStreamTrack,
    participant: CallParticipant,
    elementId: string,
    trackType: TrackType,
    prefix = '',
  ): Promise<boolean> {
    const containerId = `${prefix}${remoteAudioPrefix}${trackType}-${participant.participantId}`;
    let audioPlayer = document.getElementById(containerId) as HTMLAudioElement;

    if (!audioPlayer) {
      audioPlayer = document.createElement('audio') as HTMLAudioElement;
    }

    audioPlayer.id = containerId;
    const container = document.getElementById(elementId);

    if (!container) {
      console.error('RTC container error: could not find audio container', elementId);
      return false;
    }

    container.append(audioPlayer);
    audioPlayer.srcObject = new MediaStream([track]);

    try {
      await this.playAudio(participant, trackType, audioPlayer);
      return true;
    } catch (err) {
      return new Promise((resolve) => {
        this.telemetry.event('[Playing Audio event]', {
          participant,
          action: 'Waiting for user interaction',
        });
        document.addEventListener(
          'click',
          async () => {
            await this.playAudio(participant, trackType, audioPlayer);
            resolve(true);
          },
          { once: true },
        );
      });
    }
  }

  private async playAudio(
    participant: CallParticipant,
    trackType: TrackType,
    audioPlayer: HTMLAudioElement,
  ) {
    try {
      await audioPlayer?.play();
      this.service.attachAudioElement(participant, trackType, audioPlayer);

      this.telemetry.event('[Playing Audio event]', {
        participant,
        action: 'Playing audio succeeded',
      });
    } catch (error) {
      this.telemetry.event('[Playing Audio event]', {
        participant,
        action: 'Playing audio failed',
        error,
      });
      throw error;
    }
  }

  // Some generic functions that attach videos to containers
  static getVideoElement(
    participantId: string,
    trackType: TrackType,
    prefix = '',
  ): HTMLVideoElement {
    // WARNING: DO NOT CHANGE THIS PATTERN FOR FULLSCREEN AND SCREEN SHARING. CSS STYLING WILL BREAK.
    const videoId = `${prefix}${remoteVideoPrefix}${trackType}-${participantId}`;
    // Get / Create the dom element to contain the video
    let videoPlayer = document.getElementById(videoId) as HTMLVideoElement;
    if (!videoPlayer) {
      videoPlayer = document.createElement('video') as HTMLVideoElement;
    }
    videoPlayer.id = videoId;
    videoPlayer.classList.add('peer-video-box');
    videoPlayer.muted = true;
    videoPlayer.playsInline = true;
    videoPlayer.autoplay = true;
    videoPlayer.controls = false;
    return videoPlayer;
  }

  static getInsertedVideoElement(
    participantId: string,
    trackType: TrackType,
    prefix = '',
  ): HTMLVideoElement | undefined {
    // WARNING: DO NOT CHANGE THIS PATTERN FOR FULLSCREEN AND SCREEN SHARING. CSS STYLING WILL BREAK.
    const videoId = `${prefix}${remoteVideoPrefix}${trackType}-${participantId}`;
    // Get / Create the dom element to contain the video
    return document.getElementById(videoId) as HTMLVideoElement;
  }

  public getTrack(participant: CallParticipant, trackType: TrackType) {
    switch (trackType) {
      case TrackType.AUDIO:
        return participant.audioTrack;
      case TrackType.SCREEN:
        return participant.screenVideoTrack;
      case TrackType.VIDEO:
        return participant.videoTrack;
      case TrackType.SCREEN_AUDIO:
        return participant.screenAudioTrack;
    }
  }

  // Attempts to play a video track, the success of the call is a boolean.
  // This is to indicate to the caller to retry when the data is available
  async playVideo(
    track: MediaStreamTrack,
    participant: CallParticipant,
    containerId: string,
    trackType: TrackType,
    prefix = '',
  ): Promise<boolean> {
    const videoPlayer = RtcServiceController.getVideoElement(
      participant.participantId,
      trackType,
      prefix,
    );
    if (!videoPlayer) {
      return false;
    }
    const containerHTMLElement = document.getElementById(containerId);
    if (!containerHTMLElement) {
      return false;
    }
    containerHTMLElement.insertAdjacentElement('afterbegin', videoPlayer);
    try {
      await this.service.attachVideo(videoPlayer, track, participant, trackType);
      if (
        participant.userId === this.user?._id &&
        participant.video &&
        trackType === TrackType.VIDEO
      ) {
        this.videoAIService.setUserVideoElement(videoPlayer);
        await this.videoAIService.start();
      }
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async stopVideo(participant: CallParticipant, trackType: TrackType, prefix = ''): Promise<void> {
    const playerContainer = RtcServiceController.getVideoElement(
      participant.participantId,
      trackType,
      prefix,
    );
    if (playerContainer) {
      await this.service.detachVideo(playerContainer);
    }
  }

  public trackIsShared(state: string): boolean {
    return this.trackExists(state) && state !== 'hidden';
  }

  public trackExists(state: string): boolean {
    return ['playable', 'loading', 'sendable', 'interrupted', 'hidden'].includes(state);
  }

  // Passing PresenceProvider & DevicesManagerService as injecting them at the constructor will cause circular dependency
  async stopVideoCall(
    spaceRecordingService: SpaceRecordingService,
    stopVideoCallReason: StopVideoCallReason,
    opts?: {
      isBreakoutRoomChange?: boolean;
    },
  ): Promise<void> {
    this.telemetry.event('[Meeting event] Leave call', {
      stopVideoCallReason,
    });

    if (!this.service.isConnected() && !this.service.isConnecting()) {
      return;
    }

    try {
      this.resetOnStopVideoCallRTC.next(true);
      await this.service.stopScreenShare();

      if (
        spaceRecordingService.isRecordingInProgress() &&
        (spaceRecordingService.isCurrentRecordingLocal() ||
          (!spaceRecordingService.isAutomaticCloudRecordingEnabled &&
            this.service.getNumberOfParticipants() == 1 &&
            !opts?.isBreakoutRoomChange))
        // ^ in this case end the cloud recording because you are the only participant (Only manual CR)
      ) {
        await spaceRecordingService.stopRecording();
      }
      await this.service.leave();
    } catch (error) {
      this.telemetry.event('[Meeting event] Leave call', {
        leaveCallError: error instanceof Error ? error.message : error,
      });
    }
  }

  // Return devices list without using the providers
  async getDevices(): Promise<RTCDevices> {
    const rtcDevices: RTCDevices = {
      cameras: [],
      mics: [],
      speakers: [],
      activeCameraId: null,
      activeMicId: null,
      activeSpeakerId: null,
    };

    let devices: MediaDeviceInfo[] | undefined;
    try {
      devices = await navigator.mediaDevices.enumerateDevices();

      this.mediaDeviceKinds.forEach((kind) => {
        const specificMediaDeviceKind = devices?.filter((device) => device.kind === kind);

        this.setRtcDevices(rtcDevices, specificMediaDeviceKind || []);
      });
    } catch (enumerateDevicesError) {
      Sentry.captureException(new Error('Enumerate Devices Error'), {
        extra: {
          context: JSON.stringify(enumerateDevicesError),
        },
      });
    }
    // If any func fails, we will return devices obj
    return rtcDevices;
  }

  private setRtcDevices(rtcDevices: RTCDevices, devices: MediaDeviceInfo[]) {
    devices.forEach((device) => {
      switch (device.kind) {
        case 'videoinput':
          rtcDevices.cameras.push(device);
          break;
        case 'audioinput':
          rtcDevices.mics.push(device);
          break;
        case 'audiooutput':
          rtcDevices.speakers.push(device);
          break;
        default:
          // unsupported device type
          break;
      }
    });
  }

  public async getCurrentDeviceInfo(
    kind: MediaDeviceKind,
    groupId: string,
  ): Promise<MediaDeviceInfo | undefined> {
    const rtcDevices = await this.getDevices();
    const devicesKind = kind === 'audioinput' ? rtcDevices.mics : rtcDevices.cameras;

    const requestedDevice = devicesKind.find(
      (device) => groupId === device.groupId && device.deviceId !== DEFAULT_DEVICE_ID,
    );

    return requestedDevice;
  }

  public activateHideSelfView(localParticipant: CallParticipant) {
    this.hideSelfViewState.next({
      active: true,
      localParticipant: localParticipant,
    });
  }

  // Used for turning off/resetting hide self view
  public resetHideSelfViewState(forceRemovingVideoElement = false) {
    if (!this.hideSelfViewState.getValue().active) {
      return;
    }
    // Used to remove video element from hide self container
    // For ex: On ending the call
    if (forceRemovingVideoElement) {
      const localParticipant = this.hideSelfViewState.getValue().localParticipant;
      if (localParticipant) {
        const videoPlayer = RtcServiceController.getVideoElement(
          localParticipant.participantId,
          TrackType.VIDEO,
          '',
        );
        videoPlayer.parentNode?.removeChild(videoPlayer);
      }
    }
    this.hideSelfViewState.next({
      active: false,
      localParticipant: undefined,
    });
  }

  private setRTCSessionVars(): void {
    this.telemetry.setSessionVars({
      user_local_mic_broadcasting: Boolean(this.service.isMicEnabled()),
      user_local_cam_broadcasting: Boolean(this.service.isCameraEnabled()),
      user_in_call: Boolean(this.service.isConnected()),
      provider: this._provider === CallProvider.LIVEKIT ? 'livekit' : 'daily',
    });
  }
}

/**
 * Provides methods to test input devices by getting their streams.
 */
export const devicePreviewHelpers = {
  getVideoStream: async (cameraId: string) => {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { deviceId: cameraId, width: { ideal: 1280 }, height: { ideal: 720 } },
    });
    return stream;
  },
  getAudioStream: async (micId: string) => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: micId },
    });
    return stream;
  },
  stopVideoStream: async (cameraId: string) => {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { deviceId: cameraId },
    });
    stream.getVideoTracks().forEach((track) => track.stop());
  },
  stopAudioStream: async (micId: string) => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: micId },
    });
    stream.getAudioTracks().forEach((track) => track.stop());
  },
};

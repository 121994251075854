import { AfterViewInit, Directive, ElementRef, NgZone, Renderer2 } from '@angular/core';

@Directive({
  selector: '[fitText]',
})
export class FitTextDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef, private zone: NgZone, private renderer: Renderer2) {}
  ngAfterViewInit(): void {
    const output = this.elementRef.nativeElement;
    const parent = output?.parentElement;
    if (!output || !parent || !(output as any).style) {
      return;
    }
    this.zone.runOutsideAngular(() => {
      this.renderer.setStyle(output, 'overflow', 'auto');
      let min = 1;
      let max = parent.getBoundingClientRect().width;
      while (min <= max) {
        const mid = min + Math.floor((max - min) / 2);
        // We subtract 0.5 because the comparison between the clientWidth and scrollWidth doesn't always guarantee that the font fits
        this.renderer.setStyle(output, 'font-size', `${mid - 0.5}px`);
        if (output.clientWidth < output.scrollWidth || output.clientHeight < output.scrollHeight) {
          max = mid - 1;
        } else {
          min = mid + 1;
        }
      }
      this.renderer.setStyle(output, 'overflow', 'initial');
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TrackType } from '../common/interfaces/rtc-interface';

export const getVideoId = (currentUser: boolean | undefined, uid: string, type: TrackType): string => {
  const containerId = currentUser ?  `${type}-userVideoId` : `${type}-container-${uid}`;
  return containerId;

};

@Pipe({
  name: 'sessionCallVideoId',
})
export class SessionCallVideoIdPipe implements PipeTransform {

  transform(currentUser: boolean | undefined, uid: string, type: TrackType): string {
    return getVideoId(currentUser, uid, type);
  }

}

<video id="popout-video" class="background-item"></video>
<div id="konva-container" class="background-item"></div>
<div id="screenshot-invisible-container"></div>
<canvas
  appChromeCanvasBugWorkaround
  #grid_canvas
  id="grid-fabric-canvas"
  class="fabric-canvas"
  [style.background-color]="frameBackgroundColor"
  [style.z-index]="zIndex$ | async"
>
</canvas>

<div class="radio-selection-container">
  <app-radio-selection-item
    class="radio-item"
    *ngFor="let selectionItem of selectionItems; index as i"
    [hidden]="selectionItem.hidden"
    [selectionItem]="selectionItem"
    [isSelected]="i === selectedIndex"
    (selectItem)="selectItem(i)"
  >
  </app-radio-selection-item>
</div>

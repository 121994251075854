export class SpaceUtils {
  public concatChunks(arrays: any[]): Uint8Array {
    if (arrays.length === 0) {
      return new Uint8Array(0);
    }
    // Get the total length of all arrays.
    let length = 0;
    arrays.forEach((item: string | any[]) => {
      length += item.length;
    });

    // Create a new array with total length and merge all source arrays.
    const mergedArray = new Uint8Array(length);
    let offset = 0;
    arrays.forEach((item) => {
      mergedArray.set(item, offset);
      offset += item.length;
    });
    return mergedArray;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PresenceRoom } from '../services/presence_v2.service';
import { UserService } from '../services/user.service';
import { IExpandAndFullScreenChange, SessionView } from '../services/session-shared-data.service';
import { TemporaryUserMetadata } from '../sessions/common/y-session';

export type VPT = number[];
// The location of a user in the session
export interface Location {
  vpt: VPT;
  canvasSize: [number, number];
  frameUID: string;
}

export interface TemporaryUserMetadataEntryBase {
  leader?: boolean;
  userId: string;
  frameUID?: string;
  location?: Location;
  raiseHand?: boolean;
  sessionView?: SessionView;
  expandAndFullScreenChange?: IExpandAndFullScreenChange;
  sessionViewMetaData?: any;
  breakoutRoomUid?: string;
}

export interface TemporaryUserMetadataEntryYJS extends TemporaryUserMetadataEntryBase {
  spaceId: undefined;
  userUniqueHash: undefined;
  timestamp: undefined;
}

export interface TemporaryUserMetadataEntryRedis extends TemporaryUserMetadataEntryBase {
  spaceId: string;
  // this is the same unique hash used in presence => userService.userUniqueHash
  userUniqueHash: string;
  // this is used to be able to compare two data rows to know who is the new one that we should respect
  timestamp: number;
}

export type TemporaryUserMetadataEntry =
  | TemporaryUserMetadataEntryYJS
  | TemporaryUserMetadataEntryRedis;

@Injectable({
  providedIn: 'root',
})
export class TemporaryUserMetadataRepositoryService {
  private metadata$ = new BehaviorSubject(new Map<string, TemporaryUserMetadataEntry>());

  constructor(private userService: UserService) {}

  public spaceTemporaryMetadata$: Observable<Map<string, TemporaryUserMetadataEntry>> =
    this.metadata$.asObservable();

  public getSpaceMetadata() {
    return this.metadata$.getValue();
  }

  // TODO: remove key param and get it from userService.userUniqueHash once we removed the backward compatibility code
  // TODO: remove the space id param and get it from the space repo once removed yjs backward compatibility code
  public getCurrentUserMetadata(key: string, spaceId: string): TemporaryUserMetadataEntry {
    return this.metadata$.getValue().get(key) || this.getDefaultMetadata(spaceId);
  }

  public setNewEvents(userMetadataEvents: TemporaryUserMetadataEntryRedis[]) {
    const metadata = this.metadata$.getValue();
    let dataUpdated = false;
    userMetadataEvents.forEach((userMetadata) => {
      if (this.updateEntryIfPossible(metadata, userMetadata)) {
        dataUpdated = true;
      }
    });
    if (dataUpdated) {
      this.metadata$.next(metadata);
    }
  }

  // TODO: remove this function once removing yjs backward compatibility code
  public setFullStateFromYjs(temporaryUserMetadata: TemporaryUserMetadata) {
    // yjs is taking care of having the latest correct values.
    // we will just set them
    const connections = Object.values(temporaryUserMetadata);
    const metadata = this.metadata$.value;

    // remove all old yjs updates
    for (const [k, v] of metadata) {
      if (!v.spaceId) {
        metadata.delete(k);
      }
    }

    for (const userMetadata of connections) {
      for (const [tabId, tempData] of Object.entries(userMetadata)) {
        metadata.set(tabId, tempData);
      }
    }
    this.metadata$.next(metadata);
  }

  public setNewFullState(roomPresence: PresenceRoom) {
    const presence = new Set(roomPresence.presence.map((user) => user.uniqueHash));

    const metadata = this.metadata$.getValue();

    let dataUpdated = false;

    // note: here we rely on the presence and whenever the user presence added again we will re-add the event again.
    for (const k of metadata.keys()) {
      // only delete updates that has spaceId which means that it cames from redis
      if (!presence.has(k) && metadata.get(k)?.spaceId) {
        metadata.delete(k);
        dataUpdated = true;
      }
    }

    // setting new user metadata
    for (const userMetadata of roomPresence.usersTemporaryMetadata) {
      if (presence.has(userMetadata.userUniqueHash)) {
        if (this.updateEntryIfPossible(metadata, userMetadata)) {
          dataUpdated = true;
        }
      }
    }
    if (dataUpdated) {
      this.metadata$.next(metadata);
    }
  }

  public clearData() {
    this.metadata$.next(new Map());
  }

  private getDefaultMetadata(spaceId: string): TemporaryUserMetadataEntry {
    return {
      userUniqueHash: this.userService.userUniqueHash,
      userId: this.userService.userId!,
      spaceId: spaceId,
      timestamp: 0, // here we start with 0, so new events will update that
    };
  }

  private updateEntryIfPossible(
    metadata: Map<string, TemporaryUserMetadataEntry>,
    entry: TemporaryUserMetadataEntryRedis,
  ): boolean {
    const existingTimestamp = metadata.get(entry.userUniqueHash)?.timestamp;
    if (!existingTimestamp || existingTimestamp < entry.timestamp) {
      metadata.set(entry.userUniqueHash, entry);
      return true;
    }
    return false;
  }
}

import { Component, Input } from '@angular/core';
import { User, REVOKED_TYPES } from '../../models/user';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent {

  @Input() user?: User;

  @Input() compact?: boolean;

  REVOKED_TYPES = REVOKED_TYPES;
}

import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Libraries } from '../../../../dialogs/upload/upload/upload.component';
import { ResourceItemModel } from '../../../../models/resource';
import { IconTypes } from '../../../../standalones/components/pencil-icon/pencil-icon.component';

@Component({
  selector: 'app-upload-panel',
  templateUrl: './upload-panel.component.html',
  styleUrls: ['./upload-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadPanelComponent {
  constructor(private translateService: TranslateService) {}

  @Output() closeSubmit = new EventEmitter<void>();

  uploadData = {
    title: this.translateService.instant('Upload'),
    libraries: [Libraries.COMPUTER, Libraries.PENCIL_FILES, Libraries.IMAGES, Libraries.CAMERA],
    type: [ResourceItemModel.IMAGE, ResourceItemModel.VIDEO, ResourceItemModel.DOCUMENT],
    newPdfFlow: true,
  };
  protected readonly IconTypes = IconTypes;
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { VirtualBackgroundType } from 'src/app/common/interfaces/rtc-interface';
import { VirtualBackgroundInsertableStreamService } from '../virtual-background-insertable-stream.service';
import { UploadFileService } from '../upload-file.service';
import { ResourcesService } from '../resources.service';
import { UserService } from '../user.service';
import { AclService } from '../acl.service';
import { LocalTracksManagerService } from '../local-tracks-manager.service';
import { VBClickEvent, VirtualBackgroundPanelBase } from './virtual-background-panel-base';

@Injectable()
export class VirtualBackgroundPanelLocalStreamService extends VirtualBackgroundPanelBase {
  private isPlayingVideoSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    dialog: MatDialog,
    translate: TranslateService,
    uploadFileService: UploadFileService,
    resourcesService: ResourcesService,
    userService: UserService,
    aclService: AclService,
    virtualBackgroundInsertableStreamService: VirtualBackgroundInsertableStreamService,
    private localTrackManagerService: LocalTracksManagerService,
  ) {
    super(
      dialog,
      translate,
      uploadFileService,
      resourcesService,
      userService,
      aclService,
      virtualBackgroundInsertableStreamService,
    );
  }

  async setImageBackground(vbClickEvent: VBClickEvent) {
    this.isPlayingVideoSubject.next(false);
    await this.localTrackManagerService.changeVbEffect({
      type: VirtualBackgroundType.BACKGROUND_IMAGE,
      backgroundImg: vbClickEvent.image.src,
    });
    this.isPlayingVideoSubject.next(true);
  }

  async removeBackground() {
    this.isPlayingVideoSubject.next(false);
    await this.localTrackManagerService.changeVbEffect({
      type: VirtualBackgroundType.NONE,
    });
    this.isPlayingVideoSubject.next(true);
  }

  async blur() {
    this.isPlayingVideoSubject.next(false);
    await this.localTrackManagerService.changeVbEffect({
      type: VirtualBackgroundType.BACKGROUND_BLUR,
    });
    this.isPlayingVideoSubject.next(true);
  }

  getVideoStream() {
    return this.localTrackManagerService.getVideoStream$();
  }

  isVideoPlaying() {
    return this.isPlayingVideoSubject;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { InviteSessionUser } from '../models/session-share';
import { SessionUser } from '../models/session';

@Pipe({
  name: 'userInviteInfo',
})
export class UserInviteInfoPipe implements PipeTransform {
  transform(email: string, sessionUser?: SessionUser): InviteSessionUser {
    if (sessionUser) {
      return new InviteSessionUser(email, sessionUser);
    }
    return new InviteSessionUser(email);
  }
}

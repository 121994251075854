import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FlagsService } from 'src/app/services/flags.service';

@Component({
  selector: 'app-feature-flags',
  templateUrl: './feature-flags.component.html',
  styleUrls: ['./feature-flags.component.scss'],
})
export class FeatureFlagsComponent {
  customFeatureFlags: Record<string, boolean>;

  constructor(
    public dialogRef: MatDialogRef<FeatureFlagsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private flagsService: FlagsService,
  ) {

    if (flagsService.customFeatureFlags) {
      this.customFeatureFlags = Object.assign({}, flagsService.customFeatureFlags);
    } else {
      this.customFeatureFlags = Object.assign({}, flagsService.featureFlags);
    }
  }

  saveFlags() {
    this.flagsService.overwriteFlags(this.customFeatureFlags);
    location.reload();
  }

  resetFlags() {
    this.flagsService.resetFlags();
    location.reload();
  }

}

import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  filter,
  map,
  pluck,
  take,
} from 'rxjs';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filterNil } from '@ngneat/elf';
import { Context } from '../models/context';
import { ChatOptions } from '../communication/chat/chat.component';
import { MessageAction } from '../models/messaging';
import { KeyScenariosOnSpaces, TelemetryService } from './telemetry.service';
import { RealtimeService } from './realtime.service';
import { UserService } from './user.service';

export class MessengerWindowState {
  context: Context;
  canClose: boolean;
  expanded: boolean;
  title?: string;
  hidden: boolean;
  constructor(context: Context, title?: string, canClose = true, expanded = true, hidden = false) {
    this.context = context;
    if (title) {
      this.title = title;
    }
    this.canClose = canClose;
    this.expanded = expanded;
    this.hidden = hidden;
  }
}

export class MessageSignal {
  context: Context;
  action: string;
  fromUser: string;
  title?: string;
  uid?: string;

  constructor(messageSignal: MessageSignal) {
    this.context = messageSignal.context;
    this.action = messageSignal.action;
    this.fromUser = messageSignal.fromUser;
    this.title = messageSignal.title;
  }
}
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class MessengerService {
  newChat: ReplaySubject<MessengerWindowState> = new ReplaySubject<MessengerWindowState>(1);

  incomingChat: ReplaySubject<MessageSignal> = new ReplaySubject<MessageSignal>(1);

  removeChat: Subject<Context> = new Subject<Context>();

  chatRemoved: Subject<string> = new Subject<string>();

  // Returns a list of all the active chats.
  activeChats: BehaviorSubject<MessengerWindowState[]> = new BehaviorSubject<
    MessengerWindowState[]
  >([]);

  public deletedPrivateMessageId$ = new Subject<string>();

  public receivedSpaceLevelMessage$ = this.realtimeService
    .getCurrentConversationMessageUpdates()
    .pipe(filter((message) => message.messageAction === MessageAction.Add));

  public deletedSpaceLevelMessageId$ = this.realtimeService
    .getCurrentConversationMessageUpdates()
    .pipe(
      filter((message) => message.messageAction === MessageAction.Delete),
      map((message) => message._id),
    );

  public allSpaceLevelMessageDeleted$ = this.realtimeService
    .getCurrentConversationMessageUpdates()
    .pipe(
      filter((message) => message.messageAction === MessageAction.DeleteAll),
      map((message) => message.messageAction),
    );

  // Tracks unread public messages
  public unreadPublicMessagesMap = new Set<string>();
  public unreadSpaceLevelMessageCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public unreadPrivateMessageCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public chatPanelOpened$ = new Subject<boolean>();
  public activeChatOptions: BehaviorSubject<ChatOptions | null> =
    new BehaviorSubject<ChatOptions | null>(null);

  constructor(
    private telemetry: TelemetryService,
    private realtimeService: RealtimeService,
    private userService: UserService,
  ) {
    combineLatest([
      this.userService.user.pipe(filterNil(), pluck('user'), take(1)),
      this.receivedSpaceLevelMessage$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([currentUser, message]) => {
        if (message.author._id !== currentUser._id) {
          this.unreadPublicMessagesMap.add(message._id);
          this.unreadSpaceLevelMessageCount$.next(this.unreadPublicMessagesMap.size);

          this.telemetry.startPerfScenario(KeyScenariosOnSpaces.RECEIVED_CHAT_MESSAGE, {
            action: message.messageAction,
            fromUser: message.author._id,
          });
        }
      });

    this.deletedSpaceLevelMessageId$.pipe(untilDestroyed(this)).subscribe((messageId) => {
      if (this.unreadPublicMessagesMap.has(messageId)) {
        this.unreadPublicMessagesMap.delete(messageId);
        this.unreadSpaceLevelMessageCount$.next(this.unreadPublicMessagesMap.size);
      }
    });

    this.unreadSpaceLevelMessageCount$.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value === 0) {
        this.unreadPublicMessagesMap.clear();
      }
    });

    this.allSpaceLevelMessageDeleted$.pipe(untilDestroyed(this)).subscribe(() => {
      this.unreadPublicMessagesMap.clear();
      this.unreadSpaceLevelMessageCount$.next(this.unreadPublicMessagesMap.size);
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FitTextDirective } from './fit-text.directive';
import { FocusDirective } from './focus.directive';
import { LazyImgDirective } from './lazy-img.directive';
import { DynamicHoverStyleDirective } from './dynamic-hover-style.directive';
import { CanvasItemStateDirective } from './canvas-item-state.directive';
import { ThrottleClickDirective } from './throttle-click.directive';
import { TilesContainerObserverDirective } from './tiles-container-observer.directive';
import { ScrollDetectorDirective } from './visible.directive';
import { TextareaAutosizeDirective } from './textarea-autosize.directive';
import { ContentEditableDirective } from './content-editable.directive ';
import { ShowSubmenuOnClickDirective } from './show-submenu-on-click.directive';
import { ScrollHelperDirective } from './scroll-helper.directive';
import { DragAndDropFileDirective } from './drag-and-drop-file.directive';
import { IntersectionObserveElementDirective } from './intersection-observe-last-element.directive';
import { PreventEnterDirective } from './prevent-enter.directive';
import { FirstElementVisibleDirective } from './first-element-visible.directive';

@NgModule({
  declarations: [
    FocusDirective,
    LazyImgDirective,
    DynamicHoverStyleDirective,
    CanvasItemStateDirective,
    ThrottleClickDirective,
    TilesContainerObserverDirective,
    ScrollDetectorDirective,
    TextareaAutosizeDirective,
    ContentEditableDirective,
    ShowSubmenuOnClickDirective,
    FitTextDirective,
    ScrollHelperDirective,
    DragAndDropFileDirective,
    IntersectionObserveElementDirective,
    PreventEnterDirective,
    FirstElementVisibleDirective,
  ],
  exports: [
    FocusDirective,
    DynamicHoverStyleDirective,
    CanvasItemStateDirective,
    ThrottleClickDirective,
    TilesContainerObserverDirective,
    ScrollDetectorDirective,
    TextareaAutosizeDirective,
    ContentEditableDirective,
    ShowSubmenuOnClickDirective,
    FitTextDirective,
    ScrollHelperDirective,
    DragAndDropFileDirective,
    IntersectionObserveElementDirective,
    PreventEnterDirective,
    FirstElementVisibleDirective,
  ],
  imports: [CommonModule],
})
export class DirectivesModule {}

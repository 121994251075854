<div id="course-details-form-wrapper">
  <div class="details-main">
    <div class="details-form">
      <form [formGroup]="form">
        <div class="banner-wrapper">
          <div class="banner">
            <div
              class="clicker pcl-details-form-upload-banner-image"
              [appTooltip]="'Upload Banner Image' | translate"
              appTooltipArrow="bottom"
              *ngIf="!uploadingImage"
              (click)="file.click()"
            ></div>
            <div class="banner-image" *ngIf="form?.value.image_url">
              <img [src]="form?.value.image_url" [alt]="course?.name" />
            </div>
            <i *ngIf="!form?.value.image_url" class="material-icons">add</i>
            <input
              type="file"
              accept="image/*"
              #file
              class="d-none"
              (change)="uploadImage($event)"
            />
          </div>
          <div *ngIf="uploadingImage" class="image-uploading-overlay">
            <mat-progress-bar
              *ngIf="uploadPercentage"
              mode="determinate"
              [value]="uploadPercentage"
            ></mat-progress-bar>
          </div>
        </div>
        <div class="fields">
          <div class="control-row">
            <label for="course-name">{{ 'course name' | translate }}</label>
            <input matInput id="course-name" formControlName="name" />
          </div>
          <div class="control-row">
            <label for="course-description">{{ 'description (optional)' | translate }}</label>
            <textarea
              formControlName="description"
              id="course-description"
              [disabled]="isLoading"
            ></textarea>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="details-footer">
    <button
      mat-flat-button
      class="archive pcl-course-details-archive"
      *ngIf="allowArchive"
      (click)="onArchiveCourse()"
    >
      <i class="material-icons-outlined"> archive </i>
      {{ 'Archive course' | translate }}
    </button>
    <button
      mat-flat-button
      class="ok pcl-course-details-save"
      [disabled]="!form?.valid || !form?.dirty || isLoading"
      (click)="onSubmit()"
    >
      <i class="material-icons-outlined"> done </i>
      {{ course ? 'Save' : ('Create' | translate) }}
    </button>
  </div>
</div>

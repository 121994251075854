import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newLine',
})
export class NewLinePipe implements PipeTransform {
  transform(text: string): any {
    const execTest = /\r|\n/.exec(text);
    if (!execTest) {
      return false;
    } else {
      return !text.substr(0, 2).trim();
    }
  }
}

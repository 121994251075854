import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, delayWhen, map, shareReplay, switchMap } from 'rxjs';
import { FLAGS, FlagsService } from 'src/app/services/flags.service';
import { modifiedTimer } from './utilities/ZoneUtils';

export interface TipState {
  isEnabled: boolean;
  refreshRate: number;
  itemsList: string[];
}
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TipsService {
  private static readonly TIPS_STORAGE_KEY = 'pencil-tip-cache';

  enabledDefault = false;
  refreshRateDefault = 5000;
  tipsDefaults: string[] = [
    'You can change board backgrounds to customize your Space',
    'Students can work independently in a Space using Private Boards',
    'You can move boards across breakout rooms',
    'Our Youtube channel has plenty of videos to help you make the most out of Pencil Spaces',
    "We offer 24/7 Live Support! Just click 'Help' in a Space if you need it",
    'You can import your files from Google Drive and OneDrive directly into Spaces',
  ];
  tipsState$ = new BehaviorSubject<TipState>(this.loadFromLocalStorage());
  currentTipText$: Observable<string>;
  timerLastFiredTS = 0;

  constructor(private flagsService: FlagsService) {
    this.currentTipText$ = this.tipsState$.pipe(
      map((state) => ({ ...state, shuffledItemList: this.shuffleList(state.itemsList) })),
      delayWhen((state) => {
        const delta = Date.now() - this.timerLastFiredTS;
        if (delta > state.refreshRate) {
          return modifiedTimer(0);
        } else {
          return modifiedTimer(state.refreshRate - delta);
        }
      }),
      switchMap((state) =>
        modifiedTimer(0, state.refreshRate).pipe(
          map((idx) => {
            this.timerLastFiredTS = Date.now();
            return state.shuffledItemList[idx % state.itemsList.length];
          }),
        ),
      ),
      shareReplay(),
    );

    // When flagService changes, load items for flagService and reset local cache
    this.flagsService.featureFlagsChanged.pipe(untilDestroyed(this)).subscribe(() => {
      // Replace tips
      const flagTips: string[] = this.flagsService.featureFlagsVariables[FLAGS.ENABLE_LOADING_TIPS]
        ? Object.keys(
            this.flagsService.featureFlagsVariables[FLAGS.ENABLE_LOADING_TIPS].tips_items as Record<
              string,
              boolean
            >,
          )
        : [];

      // Only enable if >1 tip and flags is active
      const isEnabled =
        this.flagsService.isFlagEnabled(FLAGS.ENABLE_LOADING_TIPS) && flagTips.length > 0;

      // Update refresh rate with data in flags
      const refreshRate =
        (this.flagsService.featureFlagsVariables[FLAGS.ENABLE_LOADING_TIPS]
          ?.tips_refresh_rate as number) ?? this.refreshRateDefault;

      const updatedState = {
        isEnabled: isEnabled,
        refreshRate: refreshRate,
        itemsList: flagTips,
      };
      this.tipsState$.next(updatedState);
      this.setToLocalStorage(updatedState);
    });
  }

  setToLocalStorage(items): void {
    try {
      localStorage.setItem(TipsService.TIPS_STORAGE_KEY, JSON.stringify(items));
    } catch {
      return;
    }
  }

  loadFromLocalStorage(): TipState {
    try {
      const rawData = localStorage.getItem(TipsService.TIPS_STORAGE_KEY);
      if (rawData) {
        return JSON.parse(rawData) as TipState;
      } else {
        throw new Error();
      }
    } catch {
      return {
        isEnabled: this.enabledDefault,
        refreshRate: this.refreshRateDefault,
        itemsList: this.tipsDefaults,
      } as TipState;
    }
  }

  shuffleList(itemsList: Array<string>): Array<string> {
    return itemsList
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  }

  generateTip$(): Observable<string> {
    return this.currentTipText$;
  }
}

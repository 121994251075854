import { ComponentType } from '@angular/cdk/portal';
import { Injectable, NgZone } from '@angular/core';
import { DialogPosition, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { first } from 'rxjs/operators';

class CallbackQueue {
  private readonly queue: (() => void)[] = [];

  public enqueue(cb: () => void) {
    this.queue.push(cb);
    // There is only one item in the queue run it immediately
    if (this.queue.length === 1) {
      cb();
    }
  }

  public dequeue() {
    this.queue.splice(0, 1);

    if (this.queue.length > 0) {
      const cb = this.queue[0];
      cb();
    }
  }
}

interface ShowModalOptions<T> {
  beforeOpened?: () => void;
  afterClosed?: (val: T) => void;
  afterOpened?: () => void;
  positionRelative?: HTMLElement | null;
}

@Injectable({
  providedIn: 'root',
})
export class ModalManagerService {
  constructor(private matDialog: MatDialog, private ngZone: NgZone) {}

  private readonly queue = new CallbackQueue();

  public showModal<T, D = any, R = any>(
    component: ComponentType<T>,
    dialogConfig?: MatDialogConfig<D>,
    modalOptions?: ShowModalOptions<R>,
  ) {
    const boundingBox: DOMRect | undefined =
      modalOptions?.positionRelative?.getBoundingClientRect();
    if (boundingBox) {
      const config: MatDialogConfig = dialogConfig || {};
      const position: DialogPosition = dialogConfig?.position || {};
      position.top = position.top
        ? `calc(${position.top} + ${boundingBox.bottom}px)`
        : `${boundingBox.bottom}px`;
      config.position = position;
      dialogConfig = config;
    }

    this.queue.enqueue(() => {
      if (modalOptions?.beforeOpened) {
        modalOptions.beforeOpened();
      }
      // Hotfix: To force any opened modal to run inside the zone
      this.ngZone.run(() => {
        const openedDialog = this.matDialog.open(component, dialogConfig);
        openedDialog
          .afterClosed()
          .pipe(first())
          .subscribe((val) => {
            this.queue.dequeue();
            if (modalOptions?.afterClosed) {
              modalOptions.afterClosed(val);
            }
          });
        openedDialog
          .afterOpened()
          .pipe(first())
          .subscribe(() => {
            if (modalOptions?.afterOpened) {
              modalOptions.afterOpened();
            }
          });
      });
    });
  }
}

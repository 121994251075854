// Description for Thumbs Up gesture
import { Gesture, GestureName } from '../models/gesture';
import { FingerType, FingerDirection, FingerCurl } from '../models/finger';

const thumbsUpGesture = new Gesture(GestureName.THUMBS_UP);

// Add curls
thumbsUpGesture.addCurl(FingerType.Thumb, FingerCurl.NoCurl);
thumbsUpGesture.addCurls(FingerType.Index, [FingerCurl.FullCurl, FingerCurl.HalfCurl]);
thumbsUpGesture.addCurls(FingerType.Middle, [FingerCurl.FullCurl, FingerCurl.HalfCurl]);
thumbsUpGesture.addCurls(FingerType.Ring, [FingerCurl.FullCurl, FingerCurl.HalfCurl]);
thumbsUpGesture.addCurls(FingerType.Pinky, [FingerCurl.FullCurl, FingerCurl.HalfCurl]);

// Add Directions
thumbsUpGesture.addDirections(FingerType.Thumb, [
  FingerDirection.VerticalUp,
  FingerDirection.DiagonalUpLeft,
  FingerDirection.DiagonalUpRight,
]);

// Add Linear Open
thumbsUpGesture.addLinearOpenY(FingerType.Thumb, true);
thumbsUpGesture.addLinearOpenY(FingerType.Pinky, false);
thumbsUpGesture.addLinearOpenY(FingerType.Index, false);
thumbsUpGesture.addLinearOpenY(FingerType.Middle, false);
thumbsUpGesture.addLinearOpenY(FingerType.Ring, false);

thumbsUpGesture.addLinearOpenX(FingerType.Index, false);
thumbsUpGesture.addLinearOpenX(FingerType.Middle, false);
thumbsUpGesture.addLinearOpenX(FingerType.Pinky, false);
thumbsUpGesture.addLinearOpenX(FingerType.Ring, false);

export default thumbsUpGesture;

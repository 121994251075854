import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ImageSearchService {
  nextPage = '';
  currentPage = 1;
  currentPrompt = '';

  constructor(private http: HttpClient) {}

  /**
   * will make an API call to Pexels API to get images
   * the images returned in different sizes ( small, medium, large )
   * @param prompt the query to search for
   * @param page the current page for the query
   * @param perPage the maximum number of result per page
   */
  searchImages(prompt: string, page = 1, perPage = 80): Observable<HttpResponse<any>> {
    if (prompt !== this.currentPrompt) {
      this.currentPrompt = prompt;
      this.nextPage = '';
      this.currentPage = 1;
    }
    const url =
      this.nextPage ||
      `https://api.pexels.com/v1/search?per_page=${perPage}&page=${page}&query=${prompt}`;
    return this.http
      .get<any>(url, {
        headers: {
          Authorization: environment.pexels.apiKey,
          'X-Skip-Interceptor': '',
        },
      })
      .pipe(
        map((response) => {
          this.nextPage = response.next_page ?? '';
          this.currentPage = response.page;
          return response.photos;
        }),
      );
  }
}

<ui-card
  class="notification cursor-pointer"
  [ngClass]="{
    'notification--read': !(notification?.createdAt | checkNew: lastViewed)
  }"
>
  <div class="d-flex align-items-center justify-content-between h-100">
    <div *ngIf="notification" class="notification-user" (click)="open()">
      <app-profile-photo
        width="38"
        [user]="notification.content?.message_id?.author"
        [isClickEnabled]="isClickEnabled"
      ></app-profile-photo>
      <span
        class="notification-type-ico d-flex justify-content-center align-items-center"
        [ngClass]="{
          comment: notification.type === 'COMMENT'
        }"
      >
        <ng-container [ngSwitch]="notification.type">
          <mat-icon *ngSwitchCase="'COMMENT'">alternate_email</mat-icon>
        </ng-container>
      </span>
    </div>
    <div class="content-wrap notification-text h-100">
      <div *ngIf="notification" class="notification-content" (click)="open()">
        <ng-container
          *ngFor="let msgContent of notification.content?.message_id?.content; let i = index"
          [ngSwitch]="msgContent.type"
        >
          <ui-fragment
            *ngSwitchCase="MessageContentType.Text"
            [fragment]="notification.content?.message_id?.content[i]"
            [replaceEquations]="true"
          >
          </ui-fragment>
          <p *ngSwitchCase="MessageContentType.Image">{{ 'Sent an image.' | translate }}</p>
          <p *ngSwitchCase="MessageContentType.Video">{{ 'Sent a video.' | translate }}</p>
          <p *ngSwitchCase="MessageContentType.Equation">{{ 'Sent an equation.' | translate }}</p>
          <p *ngSwitchDefault>{{ 'Sent an attachment.' | translate }}</p>
        </ng-container>
      </div>
      <div *ngIf="notification" class="notification-time" (click)="open()">
        <span class="dot" *ngIf="notification?.createdAt | checkNew: lastViewed"></span>
        <ng-container *ngIf="notification.timeLabel === 'Today'">
          {{ notification?.createdAt | notificationTime }}
        </ng-container>
        <ng-container *ngIf="notification.timeLabel === 'Yesterday'">
          {{ 'Yesterday ' + (notification?.createdAt | notificationTime) }}
        </ng-container>
        <ng-container
          *ngIf="notification.timeLabel !== 'Yesterday' && notification.timeLabel !== 'Today'"
        >
          {{ notification?.createdAt | dateLocale: 'mini' }}
        </ng-container>
      </div>
    </div>
  </div>
</ui-card>

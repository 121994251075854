import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { filterNil } from '@ngneat/elf';
import { User } from '../models/user';
import { Permissions, Session } from '../models/session';
import { SpaceRepository, filterNotSynced } from '../state/space.repository';
import { FLAGS, FlagsService } from './flags.service';
import { AclService, Feature } from './acl.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class SpacePermissionsManagerService {
  user$ = new BehaviorSubject<undefined | User>(undefined);
  currentUserPermissions$ = new BehaviorSubject(new Permissions());
  leaveCallEnabled$ = this.spaceRepo.isCurrentUserHost$;

  readonly recordSessionEnabled$ = this.user$.pipe(
    filterNil(),
    map(
      (user) =>
        this.flagsService.isFlagEnabled(FLAGS.WB_RECORD_SESSION) &&
        this.aclService.isAllowed(user, Feature.record_event_session),
    ),
  );

  readonly raiseHandEnabled$ = this.spaceRepo.isCurrentUserHost$.pipe(
    map((host) => (host ? this.flagsService.isFlagEnabled(FLAGS.WB_EMOTES) : false)),
  );

  readonly canCreateBoard$ = combineLatest([
    this.spaceRepo.activeSpace$.pipe(filterNotSynced()),
    this.spaceRepo.activeSpaceCurrentRoom$,
  ]).pipe(
    map(() => this.canCreateBoards()),
    startWith(false),
    distinctUntilChanged(),
  );

  get isHost(): boolean {
    return this.spaceRepo.isCurrentUserHost();
  }

  constructor(
    private spaceRepo: SpaceRepository,
    private userService: UserService,
    private flagsService: FlagsService,
    private aclService: AclService,
  ) {
    // In case of breakout rooms flag is enabled, use the global permissions as user permissions
    // as user permissions are not supported yet on FE with/without breakout rooms.
    // But if the flag is disabled use the previous flow as once changing the global permissions, we are updating
    // each session user, then post this update to DB which broadcasts changes to others using realtime.
    this.spaceRepo.activeSpaceCurrentRoom$
      .pipe(
        filterNil(),
        map((currentRoom) => currentRoom?.permissions ?? new Permissions()),
      )
      .subscribe(this.currentUserPermissions$);

    this.userService.user
      .pipe(
        map((u) => u?.user),
        filterNil(),
      )
      .subscribe(this.user$);
  }

  isPermissionEnabled(permissionName: string): boolean {
    return this._isPermissionEnabled(this.currentUserPermissions$.getValue(), permissionName);
  }

  canOpenCam$: Observable<boolean> = this.currentUserPermissions$.pipe(
    map((permissions) => this._isPermissionEnabled(permissions, 'shareVideo')),
    distinctUntilChanged(),
  );

  canOpenCam(): boolean {
    return this.isPermissionEnabled('shareVideo');
  }

  canOpenMic$: Observable<boolean> = this.currentUserPermissions$.pipe(
    map((permissions) => this._isPermissionEnabled(permissions, 'shareAudio')),
    distinctUntilChanged(),
  );

  canOpenMic(): boolean {
    return this.isPermissionEnabled('shareAudio');
  }

  canShareScreen$: Observable<boolean> = this.currentUserPermissions$.pipe(
    map((permissions) => this._isPermissionEnabled(permissions, 'screenShare')),
    distinctUntilChanged(),
  );

  canShareScreen(): boolean {
    return this.isPermissionEnabled('screenShare');
  }

  canStartCall(): boolean {
    return this.isHost;
  }

  canEndCallForEveryone$: Observable<boolean> = this.spaceRepo.isCurrentUserHost$;

  canEndCallForEveryone(): boolean {
    return this.isHost;
  }

  canCreateBoards(): boolean {
    return this.isPermissionEnabled('createBoards');
  }

  public readonly spaceLockedForCurrentUser$ = combineLatest([
    this.spaceRepo.activeSpaceLocked$,
    this.spaceRepo.activeSpace$,
  ]).pipe(
    map(
      ([spaceLocked, activeSpace]) =>
        spaceLocked && !Session.isOwnedByUser(activeSpace, this.userService.userId),
    ),
  );

  private _isPermissionEnabled(permissions: Permissions, permissionName: string): boolean {
    if (this.isHost) {
      return true;
    }

    if (typeof permissions[permissionName] === 'boolean' || !permissions[permissionName]) {
      return !!permissions[permissionName];
    } else {
      return permissions[permissionName][this.user$.getValue()?._id as string];
    }
  }
}

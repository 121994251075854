import { Upload } from 'tus-js-client';
import { ToastrService } from 'ngx-toastr';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { CreateCommentComponent } from 'src/app/commenting/create-comment/create-comment.component';
import { ListCommentsComponent } from 'src/app/commenting/list-comments/list-comments.component';
import { CreateComponent } from 'src/app/content/course/create/create.component';
import { Message } from 'src/app/models/user';

import { VimeoUploadService } from 'src/app/services/vimeo-upload.service';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { Note as NoteType } from 'src/app/models/note';
import { MessageListComponent } from 'src/app/messages/message-list/message-list.component';
import { ResourcesService } from 'src/app/services/resources.service';
import { IResource, ResourceItemModel } from 'src/app/models/resource';
import { percentage } from '@angular/fire/storage';
import { FragmentType, TypedFragment } from '../../../common/typed-fragment/typed-fragment';
import {
  Explanation,
  Fragment,
  FragmentMetadata,
  MultipleChoice,
  Question,
} from '../../../models/question';

export enum CollectionType {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Question = 'QUESTION',
  Option = 'OPTION',
  Hint = 'HINT',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Explanation = 'SOLUTION',
  Tricks = 'TRICKS',
  More = 'MORE',
  Notes = 'NOTES',
  Answer = 'ANSWER',
  HowTo = 'HOWTO',
  Rubric = 'RUBRIC',
  Comment = 'COMMENT',
  Screenshot = 'SCREENSHOT',
  Title = 'TITLE',
  Note = 'NOTE',
  AuthorNote = 'AUTHOR_NOTE',
}

export enum NUMBER_TYPES {
  ALPHABET_LOWERCASE = 'ALPHABET_LOWERCASE',
  ALPHABET_UPPERCASE = 'ALPHABET_UPPERCASE',
  ROMAN_LOWERCASE = 'ROMAN_LOWERCASE',
  ROMAN_UPPERCASE = 'ROMAN_UPPERCASE',
  NUMERICAL = 'NUMERICAL',
  BLANK = 'BLANK',
}

export class FragmentCollection {
  type?: CollectionType | string;
  uploadProgress?: Observable<number | undefined>;
  description?: string;
  fragments: TypedFragment[] = [];
  tempFragments: TypedFragment[] = [];
  answersFragments: TypedFragment[] = [];
  option_data: string;
  is_answer = false;
  explanationCollection?: FragmentCollection;
  updated = false;
  component:
    | CreateComponent
    | ListCommentsComponent
    | CreateCommentComponent
    | MessageListComponent;
  expl_type = '';
  showImage = false;
  showVideo = false;
  showPhet = false;
  showUploadVideo = false;
  noExpl = false;
  explLater = false;
  uploadingService?: Upload;
  level = 0;
  number = '';
  numberType?: NUMBER_TYPES;
  id?: string;
  answerFormat?: string;
  lines = 0;
  checked?: boolean;
  marks?: number;
  explId?: string;

  constructor(
    // TODO: This is a mess, needs to be cleaned up.
    component:
      | CreateComponent
      | ListCommentsComponent
      | CreateCommentComponent
      | MessageListComponent,
    type?: CollectionType | string,
    description?: string,
    option_data = '',
    metadata?: any,
  ) {
    this.type = type;
    this.description = description;
    this.option_data = option_data;
    this.component = component;
    if (metadata) {
      this.noExpl = metadata.explanation_na;
      this.explLater = metadata.explanation_pending;
    }
    if (this.type === CollectionType.Option) {
      this.explanationCollection = new FragmentCollection(
        component,
        CollectionType.Explanation,
        this.description,
        this.option_data,
      );
      if ('explanationCollectionMap' in this.component) {
        this.component.explanationCollectionMap.set(
          `EXPL_${this.option_data}`,
          this.explanationCollection,
        );
      }
    }
  }

  static FromNote(comp: CreateComponent, note: NoteType): FragmentCollection {
    const coll = new FragmentCollection(comp, CollectionType.Note);
    coll.description = 'Note';
    for (const frag of note.note_fragments) {
      coll.fragments.push(TypedFragment.fromFragment(frag));
    }
    coll.addEmptyFragment();
    return coll;
  }

  static FromTitle(comp: CreateComponent, note: NoteType): FragmentCollection {
    const coll = new FragmentCollection(comp, CollectionType.Title);
    coll.description = 'Title';
    for (const frag of note.title_fragments) {
      coll.fragments.push(TypedFragment.fromFragment(frag));
    }
    coll.addEmptyFragment();
    return coll;
  }

  static FromExplanation(
    comp: CreateComponent,
    question: Question,
    expl: Explanation,
    description = '',
  ): FragmentCollection {
    const coll = new FragmentCollection(comp);
    if (expl.type.startsWith('EXPL')) {
      coll.expl_type = expl.type;
      coll.type = CollectionType.Explanation;
      coll.option_data = expl.type.split('_')[1];
      if (question.multiple_choice) {
        coll.explLater =
          question.multiple_choice.find((m) => m.option_id === coll.option_data)
            ?.explanation_pending ?? true;
        coll.noExpl =
          question.multiple_choice.find((m) => m.option_id === coll.option_data)?.explanation_na ??
          true;
      }
    } else {
      coll.type = expl.type as CollectionType;
    }
    coll['checked'] = expl.explanation_complete;
    coll.description = description;
    coll.is_answer = question.answer && question.answer.option_id === coll.option_data;
    coll.explId = expl._id;
    for (const frag of expl.remark) {
      const typedFrag = TypedFragment.fromFragment(frag);
      coll.fragments.push(typedFrag);
    }
    coll.addEmptyFragment();
    return coll;
  }

  static FromQuestion(
    comp: CreateComponent,
    question: Question,
    description?: string,
  ): FragmentCollection {
    const coll = new FragmentCollection(comp, CollectionType.Question);
    coll.description = description || 'Question';
    if (question['metadata']) {
      coll['answerFormat'] = question['metadata']['answerFormat'];
      coll['lines'] = question['metadata']['lines'] || 0;
      coll['marks'] = question['metadata']['marks'];
    }
    for (const frag of question.question_fragments) {
      coll.fragments.push(TypedFragment.fromFragment(frag));
    }
    coll.addEmptyFragment();
    return coll;
  }

  static FromComment(
    comp: ListCommentsComponent | CreateCommentComponent | MessageListComponent,
    message?: Message,
  ): FragmentCollection {
    const coll = new FragmentCollection(comp, CollectionType.Comment);
    coll.description = 'Comment';
    if (message) {
      for (const frag of message.content) {
        coll.fragments.push(TypedFragment.fromFragment(frag));
      }
    }
    coll.addEmptyFragment();
    return coll;
  }

  static FromSubQuestion(comp: CreateComponent, question: Question): FragmentCollection[] {
    const colls: FragmentCollection[] = [];
    const questionColl = new FragmentCollection(comp, CollectionType.Question);
    questionColl.description = 'Question';
    questionColl['id'] = question._id;
    for (const frag of question.question_fragments) {
      questionColl.fragments.push(TypedFragment.fromFragment(frag));
    }
    colls.push(questionColl);
    if (question.sub_questions) {
      for (const sub of question.sub_questions) {
        if (sub['level']) {
          const coll = new FragmentCollection(comp, CollectionType.Question);
          coll.description = 'Question';
          coll['number'] = sub['number'];
          coll['numberType'] = sub['numberType'];
          coll['level'] = sub['level'];
          coll['id'] = sub['_id'];
          coll['answerFormat'] = sub['metadata'] && sub['metadata']['answerFormat'];
          coll['lines'] = sub['metadata'] && sub['metadata']['lines'];
          coll['marks'] = sub['metadata'] && sub['metadata']['marks'];
          for (const frag of sub['question_fragments']) {
            coll.fragments.push(TypedFragment.fromFragment(frag));
          }
          if (sub.answer) {
            coll['checked'] = sub.answer['explanation_complete'];
          }
          coll.addEmptyFragment();
          colls.push(coll);
        }
      }
    }
    return colls;
  }

  static FromAnswer(comp: CreateComponent, question: Question): FragmentCollection {
    const coll = new FragmentCollection(comp, CollectionType.Answer);
    coll.description = 'Answer';
    if (question.answer) {
      coll.noExpl = question.answer.explanation_na;
      coll.explLater = question.answer.explanation_pending;
      coll['checked'] = question.answer.explanation_complete;
      for (const frag of question.answer.choice_fragments) {
        coll.fragments.push(TypedFragment.fromFragment(frag));
      }
    }
    coll.addEmptyFragment();
    return coll;
  }

  static FromNotesFragments(
    comp: CreateComponent,
    frags: Fragment[],
    description = '',
  ): FragmentCollection {
    const coll = new FragmentCollection(comp, CollectionType.Notes);
    coll.description = description || 'Notes';
    if (!frags) {
      return coll;
    }
    for (const frag of frags) {
      coll.fragments.push(TypedFragment.fromFragment(frag));
    }
    coll.addEmptyFragment();
    return coll;
  }

  static FromAuthorNote(comp: CreateComponent, note: NoteType): FragmentCollection {
    const coll = new FragmentCollection(comp, CollectionType.AuthorNote);
    coll.description = 'Author Note';
    if (note.authors_notes_fragments !== undefined) {
      for (const frag of note.authors_notes_fragments) {
        coll.fragments.push(TypedFragment.fromFragment(frag));
      }
    }
    coll.addEmptyFragment();
    return coll;
  }

  static FromScreenshotFragments(comp: CreateComponent, frags: Fragment[]): FragmentCollection {
    const coll = new FragmentCollection(comp, CollectionType.Screenshot);
    coll.description = 'Screenshot';
    if (!frags) {
      if (coll.fragments.length === 0) {
        coll.addTextFragment();
      }
      return coll;
    }
    for (const frag of frags) {
      coll.fragments.push(TypedFragment.fromFragment(frag));
    }
    coll.addEmptyFragment();
    return coll;
  }

  static FromOption(
    comp: CreateComponent,
    question: Question,
    mc: MultipleChoice,
  ): FragmentCollection {
    const coll = new FragmentCollection(comp, CollectionType.Option);
    coll.description = `Option ${mc.option_id}`;
    coll.option_data = mc.option_id;
    coll.is_answer = question.answer.option_id === mc.option_id;
    coll.noExpl = mc.explanation_na;
    coll.explLater = mc.explanation_pending;
    for (const frag of mc.choice_fragments) {
      coll.fragments.push(TypedFragment.fromFragment(frag));
    }
    coll.addEmptyFragment();
    return coll;
  }

  addEmptyFragment(): void {
    const lastFragment = this.fragments[this.fragments.length - 1];
    if (
      this.fragments.length === 0 ||
      (lastFragment.type !== FragmentType.Text && lastFragment.type !== FragmentType.Katex)
    ) {
      this.addTextFragment();
    }
  }

  placeholder(): string {
    switch (this.type) {
      case CollectionType.Question: {
        return 'Enter text here...';
      }
      case CollectionType.Option: {
        return 'Type choice here...';
      }
      case CollectionType.Explanation: {
        return 'Write explanation here...';
      }
      case CollectionType.Hint: {
        return 'Write hint here...';
      }
      case CollectionType.Answer: {
        return 'Write answer here...';
      }
      case CollectionType.Note: {
        return 'Enter notes';
      }
      case CollectionType.Title: {
        return 'Enter title';
      }
      case CollectionType.AuthorNote: {
        return 'Enter author notes';
      }
      default: {
        return 'Type here...';
      }
    }
  }

  addKatexFragment(): void {
    const frag = new TypedFragment(
      FragmentType.Katex,
      this.description,
      this.option_data,
      undefined,
      true,
    );
    this.addFragment(frag);
  }

  addTextFragment(myscript = false, initialData = '', index = -1): void {
    this.addFragment(
      new TypedFragment(
        FragmentType.Text,
        this.description,
        this.option_data,
        initialData,
        myscript,
      ),
      index,
    );
  }

  addAnswerTextFragment(myscript = false): void {
    // This.updated = true;
    this.fragments.push(
      new TypedFragment(
        FragmentType.AnswerText,
        this.description,
        this.option_data,
        undefined,
        myscript,
      ),
    );
  }

  addTableFragment(index = -1): void {
    const newTableData = [
      [
        [{ type: FragmentType.Text, data: '' }],
        [{ type: FragmentType.Text, data: '' }],
        [{ type: FragmentType.Text, data: '' }],
      ],
      [
        [{ type: FragmentType.Text, data: '' }],
        [{ type: FragmentType.Text, data: '' }],
        [{ type: FragmentType.Text, data: '' }],
      ],
    ];
    const fragment = new TypedFragment(
      FragmentType.Table,
      this.description,
      this.option_data,
      JSON.stringify(newTableData),
    );
    this.addFragment(fragment, index);
  }

  addSubQuestionFragment(myscript = false): void {
    // This.updated = true;
    this.fragments.push(
      new TypedFragment(FragmentType.Text, this.description, this.option_data, undefined, myscript),
    );
  }

  validateVideoUrl(url: string): boolean {
    if (url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|vimeo|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
      const match = url.match(regExp);
      if (match) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  addPhet(url: string, fragmentIndex = -1, callback?: any): void {
    this.updated = true;
    const fragment = new TypedFragment(FragmentType.Phet, this.description, this.option_data);
    fragment.fragment.data = url;
    this.addFragment(fragment, fragmentIndex);
    if (callback) {
      callback();
    }
    if ('saveData' in this.component) {
      this.component.saveData(undefined, this);
    }
    this.showPhet = false;
  }

  addVideo(url: string, toastService: ToastrService, fragmentIndex = -1, callback?: any): void {
    if (!this.validateVideoUrl(url)) {
      toastService.error('Please enter a YouTube or Vimeo video URL');
      return;
    }
    this.updated = true;
    const fragment = new TypedFragment(FragmentType.Youtube, this.description, this.option_data);
    fragment.fragment.data = url;
    this.addFragment(fragment, fragmentIndex);
    if (callback) {
      callback();
    }
    if ('saveData' in this.component) {
      this.component.saveData(undefined, this);
    }
    this.showVideo = false;
  }

  addPdf(url: string, fragmentIndex = -1, callback?: any): void {
    this.updated = true;
    const fragment = new TypedFragment(FragmentType.PDF, this.description, this.option_data);
    fragment.fragment.data = url;
    this.addFragment(fragment, fragmentIndex);
    if (callback) {
      callback();
    }
    if ('saveData' in this.component) {
      this.component.saveData(undefined, this);
    }
    this.showVideo = false;
  }

  addImage(url: string, fragmentIndex = -1): void {
    this.updated = true;
    const fragment = new TypedFragment(FragmentType.Image, this.description, this.option_data);
    fragment.fragment.data = url;
    if (fragment.fragment.metadata) {
      fragment.fragment.metadata['img_width'] = '350px';
    } else {
      fragment.fragment.metadata = new FragmentMetadata();
      fragment.fragment.metadata['img_width'] = '350px';
    }
    this.addFragment(fragment, fragmentIndex);
    if ('saveData' in this.component) {
      this.component.saveData(undefined, this);
    }
    this.showImage = false;
  }

  addVideoFile(
    fileInput: File,
    service: VimeoUploadService,
    resourceService: ResourcesService,
    coursesIds?: string[],
    fragmentIndex = -1,
    callback?: any,
  ): Observable<number | undefined> | undefined {
    this.updated = true;
    if (fileInput) {
      const fragment = new TypedFragment(FragmentType.Youtube, this.description, this.option_data);

      const percentageSubject = new BehaviorSubject(0.01);
      this.uploadProgress = percentageSubject.asObservable();
      service.createVideo(fileInput).subscribe((response) => {
        if (!fileInput) {
          return;
        }
        fragment.fragment.data = response.body.link;
        this.uploadingService = service.tusUpload(
          fileInput,
          response.body.upload.upload_link,
          (progressPercentage: number) => {
            percentageSubject.next(progressPercentage);
          },
          () => {
            this.addFragment(fragment, fragmentIndex);
            this.uploadProgress = undefined;
            // Create a corresponding resource document.
            this.createResource(
              response.body.link,
              fileInput,
              ResourceItemModel.VIDEO,
              resourceService,
              coursesIds,
            ).subscribe();
            if ('saveData' in this.component) {
              this.component.saveData(undefined, this, false, true);
            }
            if (callback) {
              callback();
            }
          },
        );
        if (this.uploadingService) {
          this.uploadingService.start();
        }
      });
    }
    this.showUploadVideo = false;
    return this.uploadProgress;
  }

  cancelUpload(): void {
    if (this.uploadingService) {
      this.uploadingService.abort(true, () => {
        this.uploadingService = undefined;
        this.uploadProgress = undefined;
      });
    }
  }

  addFile(
    fileInput: File,
    uploadService: UploadFileService,
    resourceService: ResourcesService,
    coursesIds?: string[],
    fragmentIndex = -1,
    callback?: any,
    type = FragmentType.Image,
  ): void {
    this.updated = true;
    if (fileInput) {
      const fragment = new TypedFragment(type, this.description, this.option_data);
      const reader = new FileReader();

      if (type === FragmentType.Image) {
        fragment.fragment.metadata = new FragmentMetadata();
        fragment.fragment.metadata['img_width'] = '350px';
      }

      const task = uploadService.uploadToFireStorage(fileInput, (data) => {
        fragment.fragment.data = data;
        fragment.fragment.uploadProgress = undefined;
        this.updateFragment(fragment);
        this.uploadProgress = undefined;
        this.createResource(data, fileInput, type, resourceService, coursesIds).subscribe();
        if ('saveData' in this.component) {
          this.component.saveData(undefined, this);
        }
        if (callback) {
          callback();
        }
      });
      if (task) {
        reader.readAsDataURL(fileInput);
        fragment.fragment.uploadProgress = 1;
        this.addFragment(fragment, fragmentIndex);
        reader.onloadend = () => {
          fragment.fragment.data = reader.result;
          this.updateFragment(fragment);
        };
        percentage(task).subscribe((data) => {
          fragment.fragment.uploadProgress = data.progress || 1;
          this.updateFragment(fragment);
          this.uploadProgress = of(data.progress);
        });
      }
    }
    if (type === FragmentType.Image) {
      this.showImage = false;
    }
  }

  deleteFragment(fragment: TypedFragment): void {
    this.updated = true;
    this.fragments = this.fragments.filter((f) => f !== fragment);
    if (this.fragments.length === 0) {
      this.addTextFragment();
    }
  }

  wordCount(): number {
    let wordCount = 0;
    for (const fragment of this.fragments) {
      if (fragment.fragment.type !== 'TEXT' || !fragment.fragment.data) {
        continue;
      }
      wordCount += fragment.fragment.data.split(' ').length;
    }
    return wordCount;
  }

  /* selectAnswer(component:any): void {
    this.updated = true;
    for (const collection of component.model.fragments) {
      collection.is_answer = false;
    }
    this.is_answer = true;
    component.model.question.answer.option_id = this.option_data;
    if ('saveData' in this.component) {
      this.component.saveData(undefined, this);
    }
  } */

  deleteAnswerFragment(fragment: TypedFragment): void {
    this.updated = true;
    this.answersFragments = this.answersFragments.filter((f) => f !== fragment);
  }

  /**
   * @desc Add Frgment before Answer Fragment
   * @param fragment
   * @returns none
   */
  addFragment(fragment: TypedFragment, index = -1): void {
    if (
      index > -1 &&
      this.fragments[index].type === FragmentType.Text &&
      !this.fragments[index].fragment.data
    ) {
      this.fragments.splice(index, 1, fragment);
      return;
    }
    if (index > -1) {
      this.fragments.splice(index + 1, 0, fragment);
      return;
    }
    const answerFragmentIndex = this.fragments.findIndex((f) => f.type === 'ANSWERTEXT');
    if (answerFragmentIndex === -1) {
      this.fragments.push(fragment);
    } else {
      this.fragments.splice(answerFragmentIndex, 0, fragment);
    }
  }

  /**
   * @desc Update Fragment during and after upload
   * @param fragment
   * @returns none
   */
  updateFragment(fragment: TypedFragment, index?: number): void {
    const fragId = index || this.fragments.findIndex((f) => f.uid === fragment.uid);
    this.fragments[fragId] = fragment;
  }

  /**
   * Used to create Resource document for the given file.
   * @param url
   * @param fileInput
   * @param type
   * @param resourceService
   */
  createResource(
    url: string,
    fileInput: File,
    type: FragmentType | ResourceItemModel,
    resourceService: ResourcesService,
    courses?: string[],
  ) {
    const resource = {
      url,
      name: fileInput.name,
      size: fileInput.size,
      metadata: {
        topics_ids: [],
        minGrade: 0,
        maxGrade: 10,
      },
      type: type,
      acl: { public: false, visibility: 'CLASS' },
      path: 'Other',
      protected: true,
      courses: courses ?? [],
    };
    return resourceService.createResource(resource);
  }

  /**
   * Used to add Component course id to the file being added to that component.
   * @param files
   * @param resourceService
   * @returns True if at least one file get updated.
   */
  addCourseToResource(
    files: IResource | IResource[],
    resourceService: ResourcesService,
    courseID?: string,
  ) {
    // check course id
    if (!courseID?.length) {
      return;
    }
    // deals with array only.
    const fileList = Array.isArray(files) ? files : [files];
    const updatedFiles = fileList.reduce((list: IResource[], file: IResource) => {
      if (!file.courses) {
        file.courses = [];
      }
      const index = file.courses.findIndex((id: string) => id === courseID);
      if (index < 0) {
        file.courses.push(courseID);
        list.push(file);
      }
      return list;
    }, []);

    // Only include the diff, removing all info other than the id and course list
    const mappedFiles = updatedFiles.map((file) => ({
      _id: file._id,
      courses: file.courses,
    }));

    // empty list.
    if (!mappedFiles.length) {
      return;
    }
    return mappedFiles.length === 1
      ? resourceService.editResource(mappedFiles[0])
      : resourceService.editResources(mappedFiles);
  }
}

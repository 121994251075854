import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { cloneDeep, last } from 'lodash-es';
import { asyncScheduler, BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import {
  areDatesWithinFiveMinutes,
  contextEquals,
  idEquals,
  isContentMessage,
} from 'src/app/common/utils/messaging';
import { FragmentCollection } from 'src/app/content/course/create/create.model';
import { EmptyCheckPipe } from 'src/app/pipes/empty-check.pipe';

import {
  ContentMessage,
  ConversationContent,
  MetadataMessage,
  UpdateType,
  MessageWithUser,
  TypedMessage,
  ContentMessageGroupByDate,
} from 'src/app/models/messaging';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessagingService } from 'src/app/services/messaging.service';
import { EditorTypes } from 'src/app/ui/advanced-editor/advanced-text-fragment.component';
import { DateFormatEnum } from 'src/app/pipes/dateLocale.pipe';
import { encodeContext } from 'src/app/models/context';
import * as moment from 'moment-timezone';
import { FLAGS, FlagsService } from 'src/app/services/flags.service';
import { Message } from '../../models/user';

@UntilDestroy()
@Component({
  selector: 'app-message-list[conversationContent]',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageListComponent implements AfterViewInit, OnChanges {
  @Input() conversationContent!: ConversationContent;
  @Input() typingUserIds: string[] = [];
  @Input() enableEditing = true;
  @Input() displayLoading = false;
  @Input() displayParticipantName = false;
  @Input() clickAvatars = true;

  @Output() updateMessage = new EventEmitter<ContentMessage>();
  @Output() deleteMessage = new EventEmitter<ContentMessage>();
  @Output() fetchMoreMessages = new EventEmitter<TypedMessage>();
  @Output() private replyMessage = new EventEmitter<ContentMessage>();

  @ViewChild('messageListContainer') messageListContainer!: ElementRef<HTMLElement>;

  public DateFormatEnum = DateFormatEnum;

  editorMessage: ContentMessage | null = null;
  editorCollection: FragmentCollection | null = null;

  private pendingMessageSubscription?: Subscription;
  public pendingMessages: Message[] | null = null;
  public addMessageFailed = false;
  public loadingMoreMessages = false;

  isContentMessage = isContentMessage;
  UpdateType = UpdateType;

  private lastEmittedMessage: TypedMessage | null = null;
  editorTypes = EditorTypes;
  imageSigningEnabled = !this.flagService.isFlagEnabled(FLAGS.DISABLE_RESOURCE_SIGNING);

  constructor(
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef,
    private flagService: FlagsService,
  ) {}

  ngAfterViewInit(): void {
    this.subscribeToPendingMessages(encodeContext(this.conversationContent.context));
    fromEvent(this.messageListContainer.nativeElement, 'scroll')
      .pipe(throttleTime(200, asyncScheduler, { leading: false, trailing: true }))
      .pipe(untilDestroyed(this))
      .subscribe(this.handleScroll.bind(this));
  }

  public isFirstChatOfDay(message: Message) {
    const [lastChat] = this.conversationContent.messages;
    return lastChat ? !moment(lastChat.createdAt).isSame(message.createdAt, 'day') : true;
  }

  public checkSuccessiveChat(message: Message, groupIndex: number) {
    if (!this.pendingMessages || groupIndex !== 0) {
      return 0;
    }
    const [firstPendingChat] = this.pendingMessages;
    return this.chatIsSuccessive(firstPendingChat, message);
  }

  public checkConsecutiveChat(message: Message) {
    const [lastChat, secondToLastChat] = this.conversationContent.messages;
    let match = this.chatIsSuccessive(message, lastChat);
    if (match > 0 && this.chatIsSuccessive(message, secondToLastChat)) {
      match++;
    }
    return match;
  }

  public chatIsSuccessive(firstMessage: Message, secondMessage: Message) {
    if (
      firstMessage?.firstAttemptTimestamp &&
      secondMessage?.createdAt &&
      areDatesWithinFiveMinutes(firstMessage.firstAttemptTimestamp, secondMessage.createdAt) &&
      firstMessage.author._id === secondMessage.author._id &&
      !firstMessage.parent &&
      !secondMessage.isEdited
    ) {
      return 1;
    }
    return 0;
  }

  public getCurrentData() {
    return Date.now();
  }

  public subscribeToPendingMessages(context: string): void {
    this.pendingMessageSubscription?.unsubscribe();
    if (!this.messagingService.pendingMessages.has(context)) {
      this.messagingService.pendingMessages.set(
        context,
        new BehaviorSubject<Message[] | null>(null),
      );
    }
    this.pendingMessageSubscription = this.messagingService.pendingMessages
      .get(context)
      ?.pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data) {
          this.handleFailedMessages(data);
        } else {
          this.resetPendingMessages();
        }
      });
  }

  public readonly trackByDate = (index: number, messageGroup: ContentMessageGroupByDate) =>
    messageGroup.date;

  public readonly trackByDateAndUserId = (index: number, messageGroup: ContentMessageGroupByDate) =>
    `${messageGroup.date}_${messageGroup.userId}`;

  public readonly trackByIdAndUpdatedAt = (index: number, message: TypedMessage) =>
    `${message._id}_${message.updatedAt}`;

  public readonly trackByCreateDate = (index: number, message: TypedMessage) => message.updatedAt;

  public handleFailedMessages(data: Message[]) {
    const failedMessages: Message[] = this.filterOutSentChat(data);
    if (failedMessages.length) {
      this.setPendingMessages(failedMessages);
    } else {
      this.resetPendingMessages();
    }
  }

  public filterOutSentChat(data: Message[]): Message[] {
    let failedMessages: Message[] = [];

    if (data.length > 0 && this.conversationContent.messages.length > 0) {
      failedMessages = data.filter((item) =>
        this.conversationContent.messages.find((message) => message?._id !== item?._id),
      );
    } else if (data.length) {
      failedMessages = data;
    }
    return failedMessages;
  }

  public setPendingMessages(messages: Message[]) {
    this.pendingMessages = [...messages];
    this.addMessageFailed = true;
    this.changeDetector.detectChanges();
  }

  public resetPendingMessages() {
    this.pendingMessages = null;
    this.addMessageFailed = false;
  }

  moreMessagesToFetch(): boolean {
    return this.conversationContent.messages.length < this.conversationContent.totalMessages;
  }

  private handleScroll(): void {
    /* Because messageListContainer uses the column-reverse flex-direction,
     * scrollTop measures from the bottom of the element in negative pixels.
     * This is also why we can't use ngx-infinite-scroll here, since it doesn't
     * seem to be able to handle this case, and has some limitations with
     * upwards scrolling.
     */
    const scrollElement = this.messageListContainer.nativeElement;

    if (
      this.moreMessagesToFetch() &&
      (-scrollElement.scrollTop + scrollElement.offsetHeight) / scrollElement.scrollHeight > 0.8
    ) {
      this.emitFetchMoreMessages();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.conversationContent &&
      !changes.conversationContent.firstChange &&
      !contextEquals(changes.conversationContent.previousValue)(
        changes.conversationContent.currentValue,
      )
    ) {
      this.clearMessageActions();
    }

    if (changes.conversationContent) {
      this.loadingMoreMessages = false;
    }
  }

  setEditorMessage(message: ContentMessage): void {
    this.editorMessage = message;
    /* This feels sketchy... Message content gets mutated directly by editor,
     * which I don't think we want, so message gets cloned first.
     */
    this.editorCollection = FragmentCollection.FromComment(this, cloneDeep(message));
  }

  saveEditorMessage(message: ContentMessage): void {
    if (this.editorCollection) {
      const updatedMessage = cloneDeep(message);
      const EmptyCheck = new EmptyCheckPipe();
      if (EmptyCheck.transform(this.editorCollection?.fragments)) {
        return;
      }

      updatedMessage.content = this.editorCollection.fragments.map(({ fragment }) => fragment);
      updatedMessage.isEdited = true;
      this.updateMessage.emit(updatedMessage);
      this.clearMessageActions();
    }
  }

  closeEditor(): void {
    this.clearMessageActions();
  }

  emitDeleteMessage(message: ContentMessage): void {
    this.deleteMessage.emit(message);
    this.clearMessageActions();
  }

  emitFetchMoreMessages(): void {
    const oldestMessage = last(this.conversationContent.messages);

    // Deduplicate consecutive emissions.
    if (
      oldestMessage &&
      !(this.lastEmittedMessage && idEquals(oldestMessage)(this.lastEmittedMessage))
    ) {
      this.lastEmittedMessage = oldestMessage;
      this.loadingMoreMessages = true;
      this.fetchMoreMessages.emit(oldestMessage);
    }
  }

  private clearMessageActions(): void {
    this.editorMessage = null;
    this.editorCollection = null;
  }

  displayTyping(): boolean {
    return Boolean(
      this.typingUserIds.find(
        (typingUserId) => typingUserId !== this.conversationContent.currentUser._id,
      ),
    );
  }

  addCurrentUser(message: MetadataMessage): MessageWithUser {
    return {
      message,
      currentUser: this.conversationContent.currentUser,
    };
  }

  public emitReplyMessage(message: ContentMessage): void {
    this.replyMessage.emit(message);
  }

  public deletePendingMessage(message: Message): void {
    this.messagingService.removeMessageFromPendingMessagesArray(
      encodeContext(message.context),
      message._id,
    );
  }
}

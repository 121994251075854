import { Injectable, Injector } from '@angular/core';
import * as FullStory from '@fullstory/browser';
import { Observable, filter, map } from 'rxjs';
import { Intercom } from 'ng-intercom';
import { versions } from 'src/environments/versions';
import { environment } from '../../environments/environment';
import { UserData } from '../models/user';
import { NetworkEvent } from '../common/interfaces/rtc-interface';
import {
  CountedMetric,
  ExternalMetric,
  PerformanceLogger,
  ScenarioRecord,
} from './performance.logger.service';
import { NavigatorConnectionTarget } from './network.service';

export enum KeyScenariosOnSpaces {
  LEADER_MODER_TOGGLING = 'Leader Mode toggling',
  JOIN_CALL = 'Join Call',
  SWITCHING_TO_WHITEBOARD_FROM_GALLERY = 'Moving from Gallery view to Whiteboard view',
  TOGGLING_CHAT = 'TOGGLING Chat',
  TOGGLING_TABS = 'TOGGLING TABS',
  SWITCHING_BOARDS = 'Switching Boards',
  RECEIVED_CHAT_MESSAGE = 'Receiving chat message',
  RENDERING_WEBVIEWER = 'Rendering a webviewer',
  RENDERING_PDF = 'Rendering a PDF',
  SWITCHING_VIEW = 'Switching View',
  DEVICE_TOGGLING = 'Device Toggling',
}

/* Wrapper around FullStory API to add addition functionality like sessionVars */

@Injectable({
  providedIn: 'root',
})
export class TelemetryService {
  private perfLogger!: PerformanceLogger;
  private readonly INTERCOM_EVENTS_WHITE_LIST = [
    /space-manager-loaded/,
    /spaces_did_load/,
    /joined_or_started_call/,
    /spaceLoaded/,
    /techcheck_step_progressed/,
    /techcheck_device_benchmark_did_complete/,
    /techcheck_network_benchmark_did_complete/,
  ];

  // Listen to Performance Scenario Completions (to get Perf metrics for specific scenrios)
  public readonly perfScenarioCompleted$: Observable<ScenarioRecord>;

  // Listen to latest Performance Metrics (to get general Perf snapshot)
  public readonly currPerfMetrics$: Observable<Record<string, any>>;

  constructor(private intercom: Intercom, injector: Injector) {
    this.perfLogger = new PerformanceLogger(injector);
    this.perfScenarioCompleted$ = this.perfLogger.scenarioCompleted$;
    this.currPerfMetrics$ = this.perfScenarioCompleted$.pipe(
      filter((record) => record.scenarioName === this.perfLogger.PERFORMANCE_SCENARIO),
      map((record) => record.perfMetrics),
    );
  }

  private sessionVars: { [key: string]: any } = {};

  public init() {
    FullStory.init({ orgId: environment.fullstoryOrgId });

    // start perf logger
    this.perfLogger.start();

    this.perfLogger.scenarioCompleted$.subscribe(async (scenario) => {
      if (scenario.scenarioName === this.perfLogger.PERFORMANCE_SCENARIO) {
        // update last know session performance
        this.event('Performance', scenario.scenarioProperties, scenario.perfMetrics, [
          'cpu.p95',
          'fps.p95',
          'cd.p95',
          'yDocRx.p95',
          'yDocTx.p95',
        ]);
      } else {
        this.event(
          `[Scenario Profile] ${scenario.scenarioName}`,
          scenario.scenarioProperties,
          scenario.perfMetrics,
          ['duration', 'cpu.p95', 'fps.p95', 'cd.p95'],
        );
      }
    });
  }

  public identifyUser(userData?: UserData) {
    const user = userData?.user;
    const intercom = userData?.intercom;

    if (user?._id) {
      // Identify with FullStory
      const userFSObj: any = {
        displayName: user.name,
        email: user.email,
        institution: user.institution?.name,
        featureFlags: user.feature_flags,
        exptTrialLength: user.feature_flag_variables?.enable_billing.expt_trial_length,
        billingStatus: user.billingStatus,
        release: versions.version,
      };
      if (user.info?.roleType) {
        userFSObj.roleType = user.info.roleType;
        userFSObj.role = user.info.role;
        userFSObj.roleOther = user.info.roleOther;
      }
      FullStory.identify(user._id, userFSObj);

      // Identify with Intercom
      if (intercom?.intercomToken) {
        // intercom tracking
        const userIntercomObj: any = {
          app_id: 'keleytc7',
          email: user.email,
          user_id: user._id,
          name: user.name,
          personas: user.personas,
          institution: user.institution?._id,
          user_hash: intercom.intercomToken,
          custom_launcher_selector: '#intercomHelpLink',
          hide_default_launcher: true,
          // Supports all optional configuration.
          widget: {
            activator: '#intercom',
          },
        };
        if (user.info?.roleType) {
          userIntercomObj.roleType = user.info.roleType;
          userIntercomObj.role = user.info.role;
          userIntercomObj.roleOther = user.info.roleOther;
        }
        this.intercom.shutdown();
        this.intercom.boot(userIntercomObj);
      }
    }
  }

  public getPerfLogger() {
    return this.perfLogger;
  }

  public shutdown() {
    FullStory.shutdown();
  }

  public restart() {
    FullStory.restart();
  }

  public toggleDOMTracking(enabled: boolean) {
    // enabled = OFF => Don't track DOM
    // enabled = ON => Track DOM
    FullStory.consent(enabled);
  }

  public log(logLevel: FullStory.LogLevel, logMessage: string) {
    FullStory.log(logLevel, logMessage);
  }

  public event(
    eventName: string,
    eventProperties: Record<string, any> = {},
    perfMetrics?: Record<string, any>,
    summaryKeys: (string | Record<string, string>)[] = [], // list of keys to be included in event log summary
  ) {
    try {
      if (!perfMetrics) {
        // Get latest (real-time) perf metrics to attach to the event
        perfMetrics = this.perfLogger.getRealtimePerfMetrics();
      }
      // merge session and event properties
      const logProperties = {
        ...this.sessionVars,
        ...eventProperties,
      };

      const logPropertiesWithPerfMetrics = {
        ...this.sessionVars,
        ...perfMetrics,
        ...eventProperties,
      };

      // log to FullStory
      // Append legacy metrics for FullStory
      const fsEventProperties = {
        ...logPropertiesWithPerfMetrics,
        ...this.getLegacyPerfMetrics(logPropertiesWithPerfMetrics),
      };
      FullStory.event(eventName, fsEventProperties);

      // log to Intercom (only whitelisted events)
      let logToIntercom = false;
      for (const regex of this.INTERCOM_EVENTS_WHITE_LIST) {
        if (regex.test(eventName)) {
          logToIntercom = true;
          break;
        }
      }
      if (logToIntercom) {
        this.intercom.trackEvent(eventName, logProperties); // ignore perf metrics for intercom
      }

      // log Telemetry events to console for non-prod builds
      if (!environment.production) {
        const summaryStr = this.getLogSummaryStr(logPropertiesWithPerfMetrics, summaryKeys);
        const eventPropsStr = eventProperties.length
          ? `: ${JSON.stringify(eventProperties, null, 2)}`
          : '';
        console.groupCollapsed(`[Telemetry] ${eventName}${summaryStr}${eventPropsStr}`);
        console.log(`${JSON.stringify(logPropertiesWithPerfMetrics, null, 2)}`);
        console.groupEnd();
      }
      return logPropertiesWithPerfMetrics;
    } catch (e) {
      console.log('[Telemetry] Failed to log event', e);
    }
  }

  public errorEvent(eventName: string, eventProperties: Record<string, any> = {}) {
    this.event(`[Error] ${eventName}`, eventProperties);
  }

  public debugEvent(eventName: string, eventProperties: Record<string, any> = {}) {
    this.event(`[Debug] ${eventName}`, eventProperties);
  }

  public setSessionVars(updatedProperties: { [key: string]: any }) {
    // Update the sessionVars with the new properties
    this.sessionVars = { ...this.sessionVars, ...updatedProperties };
    // Also udpate page properties
    FullStory.setVars('page', this.sessionVars);
  }

  public incrementMetric(metric: CountedMetric) {
    this.perfLogger.incrementCountedMetric(metric);
  }

  public updateMetric(metric: ExternalMetric, value: number) {
    this.perfLogger.updateMetric(metric, value);
  }

  public startPerfScenario(scenarioName: string, scenarioProperties?: Record<string, any>): void {
    this.perfLogger.startPerfScenario(scenarioName, scenarioProperties);
  }

  public endPerfScenario(scenarioName: string, scenarioProperties?: Record<string, any>): void {
    this.perfLogger.endPerfScenario(scenarioName, scenarioProperties);
  }

  public setNavigatorConnectionTarget(navigatorConnectionTarget?: NavigatorConnectionTarget): void {
    this.perfLogger.navigatorConnectionTarget = navigatorConnectionTarget;
  }

  public setNetworkEvent(networkEvent: NetworkEvent | null): void {
    this.perfLogger.networkEvent = networkEvent;
  }

  private getLegacyPerfMetrics(logProperties: Record<string, any>) {
    // Maps the new keys to old keys until we have enough data in the new keys for FS dashboard
    // @TODO: stoor - remove this after 90 days of going to prod and update FS dashboards
    const legacyMetrics: Record<string, any> = {};
    legacyMetrics['cpu_p50'] = this.getObjectByKeys(logProperties, 'cpu.p50');
    legacyMetrics['cpu_p95'] = this.getObjectByKeys(logProperties, 'cpu.p95');
    legacyMetrics['cdps_p50'] = this.getObjectByKeys(logProperties, 'cd.p50');
    legacyMetrics['fps_p50'] = this.getObjectByKeys(logProperties, 'fps.p50');
    legacyMetrics['usedJSHeapSize'] = this.getObjectByKeys(logProperties, 'memory.usedJSHeapSize');
    return legacyMetrics;
  }

  private getLogSummaryStr(
    logProperties: Record<string, any>,
    summaryKeys: (string | Record<string, string>)[],
  ) {
    const summary: Record<string, any> = {};
    for (const summaryKey of summaryKeys) {
      let label: string;
      let key: string;
      if (typeof summaryKey === 'string') {
        label = summaryKey;
        key = summaryKey;
      } else {
        // (typeof summaryKey === 'object')
        [label, key] = Object.entries(summaryKey)[0];
      }
      // extract summary keys from logPropertiesWithPerfMetrics
      const val = this.getObjectByKeys(logProperties, key);
      if (val !== undefined) {
        summary[label] = val;
      }
    }
    let summaryStr = '';
    if (Object.keys(summary).length) {
      summaryStr = Object.entries(summary)
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ');
      summaryStr = ` (${summaryStr})`;
    }
    return summaryStr;
  }

  /**
   * Can be used to get nested keys from an object using "." notation
   * const myObject = {
      level1: {
        level2: {
          level3: 'Value'
        }
      }
    };
    const result = getObjectByKeys(myObject, 'level1.level2.level3');
   */
  private getObjectByKeys(object: any, compoundKey: string) {
    return compoundKey.split('.').reduce((nestedObj, key) => {
      if (nestedObj && typeof nestedObj === 'object' && key in nestedObj) {
        return nestedObj[key];
      }
      return undefined;
    }, object);
  }
}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-visibility-confirm-dialog',
  templateUrl: 'visibility-confirm.component.html',
  styleUrls: ['./visibility-confirm.component.scss'],
})
export class VisibilityConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<VisibilityConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}
}

import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import 'quill-mention';
import Quill from 'quill';

import { UploadFileService } from 'src/app/services/upload-file.service';
import { Context } from 'src/app/models/context';
import { UserInfo } from 'src/app/models/user';
import { MentionService } from 'src/app/services/mention.service';
import { FragmentCollection } from 'src/app/content/course/create/create.model';
import { getConvertedHtml, getHtmlFromFormattedText } from 'src/app/common/utils/common-util';
import { ResourcesService } from 'src/app/services/resources.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SessionSharedDataService } from 'src/app/services/session-shared-data.service';
import { QuestionsService } from '../../services/questions.service';
import { TypedFragment } from '../../common/typed-fragment/typed-fragment';
import { MyscriptComponent } from '../myscript/myscript.component';
import { InterceptorSkipHeader } from '../../auth.interceptor';

const Delta = Quill.import('delta');

interface UserMentionItem {
  id: string;
  value: string;
  userInfo: UserInfo;
}

enum FormatTypes {
  LIST_BULLETED = 'LIST_BULLETED',
  LIST_NUMBERED = 'LIST_NUMBERED',
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  UNDERLINE = 'UNDERLINE',
  SUBSCRIPT = 'SUBSCRIPT',
  SUPERSCRIPT = 'SUPERSCRIPT',
  HIGHLIGHT = 'HIGHLIGHT',
}

export enum EditorTypes {
  NORMAL = 'NORMAL',
  MESSAGE = 'MESSAGE',
  COMMENT = 'COMMENT',
}

@UntilDestroy()
@Directive({
  selector: '[advanced-text-fragment][fragment][coll]',
})
export class AdvancedTextFragmentDirective implements OnChanges, OnInit {
  @Input() fragment!: TypedFragment;
  @Input() coll!: FragmentCollection;
  @Input() placeholder?: string;
  @Input() emitTyping = false;
  @Input() showFocus = false;
  @Input() focusToEnd = 0;
  @Input() focusPosition = 0;
  @Input() selected = false;
  @Input() type: EditorTypes = EditorTypes.NORMAL;
  @Input() componentUsedFromChatBox = false;
  @Input() fromTable = false;
  @Input() messageContext?: Context;
  @Input() chatIdentifier?: string;
  @Input() disableNewFragment = false;
  @Input() selectedRange = null;
  @Input() enableList = true;
  @Input() enableShiftEnter = false;
  @Input() enableFloatingToolbar = false;
  @Input() first = false;
  @Input() last = false;
  @Input() formatText = true;
  @Output() saveEvent = new EventEmitter();
  @Output() saveState = new EventEmitter();
  @Output() saveEditor = new EventEmitter();
  @Output() focused = new EventEmitter();
  @Output() blurred = new EventEmitter();
  @Output() clickEnter = new EventEmitter();
  @Output() clickBackspace = new EventEmitter();
  @Output() clickDelete = new EventEmitter();
  @Output() hideFocus = new EventEmitter();
  @Output() setSelectionRange = new EventEmitter();
  @Output() sendSegments = new EventEmitter();
  @Output() saveImage = new EventEmitter();
  @Output() backspaceToRemoveFragment = new EventEmitter();
  @Output() handleArrowKeys = new EventEmitter();
  @Output() dropStart: EventEmitter<boolean> = new EventEmitter();

  el: ElementRef;
  saver = new Subject<string>();
  editor: Quill;
  isTyping = new Subject();
  isShiftClicked = false;
  isEnterClicked = false;
  isControlClicked = false;
  isContentSet = false;
  cursorPosition = 0;
  lastTextChangeIndex = 0;
  isBackspaceClickedAtFormula = false;
  isBackspaceClicked = false;
  private mentionMenuOpen = false;
  deleteCountAtFirstIndex = 0;
  isFragmentChanged = true;
  isKeyEventsDisabled = false;

  constructor(
    el: ElementRef,
    private questionsService: QuestionsService,
    public dialog: MatDialog,
    private httpClient: HttpClient,
    private uploadService: UploadFileService,
    private mentionService: MentionService,
    private resourceService: ResourcesService,
    private zone: NgZone,
    private sessionSharedService: SessionSharedDataService,
  ) {
    this.el = el;

    this.saver
      .pipe(debounceTime(1000), distinctUntilChanged())
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.saveEditor.emit(res);
        this.saveEvent.emit(this.coll);
        this.saveState.emit(false);
      });

    this.questionsService.isKatexDialogOpened.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res && (this.selected || this.type === EditorTypes.COMMENT)) {
        this.emitCursorPositionForFormula(this.lastTextChangeIndex);
      }
    });

    this.sessionSharedService.isDisableKeyEvents$.pipe(untilDestroyed(this)).subscribe((val) => {
      this.isKeyEventsDisabled = val;
    });

    this.questionsService.operation.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res?.type && this.selected) {
        const format = this.editor.getFormat();
        switch (res?.type) {
          case FormatTypes.LIST_BULLETED:
            this.editor.format('list', format.list === 'bullet' ? '' : 'bullet');
            break;
          case FormatTypes.LIST_NUMBERED:
            this.editor.format('list', format.list === 'ordered' ? '' : 'ordered');
            break;
          case FormatTypes.BOLD:
            this.editor.format('bold', !format.bold);
            break;
          case FormatTypes.ITALIC:
            this.editor.format('italic', !format.italic);
            break;
          case FormatTypes.UNDERLINE:
            this.editor.format('underline', !format.underline);
            break;
          case FormatTypes.SUBSCRIPT:
            this.editor.format('script', format.script === 'sub' ? '' : 'sub');
            break;
          case FormatTypes.SUPERSCRIPT:
            this.editor.format('script', format.script === 'super' ? '' : 'super');
            break;
          case FormatTypes.HIGHLIGHT:
            this.editor.format('background', res.value);
            break;
          default:
            break;
        }
        const formats = this.editor.getFormat();
        this.questionsService.selectedFormat.next(Object.keys(formats).length ? formats : null);
      }
    });
  }

  openKatexDialog(katexData = null, index): void {
    const dialogRef = this.dialog.open(MyscriptComponent, {
      width: '800px',
      panelClass: 'math-quill-dialog-container',
      data: { katex: katexData },
    });
    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (index !== undefined) {
          if (result) {
            this.editor.deleteText(index, 1);
            this.editor.insertEmbed(index, 'formula', result);
          }
          this.editor.setSelection(index + 1);
        } else {
          const range = this.editor.getSelection(true);
          if (result) {
            this.editor.deleteText(range.index, 1);
            this.editor.insertEmbed(range.index, 'formula', result);
          }
          this.editor.setSelection(range.index + range.length + 1);
        }
      });
  }

  getImage(imageUrl: string): Observable<Blob> {
    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
    return this.httpClient.get(imageUrl, { responseType: 'blob', headers: headers });
  }

  emitCursorPositionForFormula(currentPosition: number): void {
    let positionForFormula = currentPosition;
    this.editor.getContents(0, currentPosition + 1).ops?.forEach((op) => {
      if (op.insert.formula) {
        positionForFormula += op.insert.formula.length + 2;
      } else if (typeof op.insert === 'string' && op.insert.includes('$')) {
        positionForFormula += op.insert.match(/\$/g).length * 2;
      } else if (op?.attributes) {
        if (op?.attributes?.list === 'bullet') {
          positionForFormula += 2; // bullet is [* ]
        }
        if (op?.attributes?.list === 'ordered') {
          positionForFormula += 3; // ordered is [1. ]
        }
        if (op?.attributes?.script === 'super' || op?.attributes?.script === 'sub') {
          positionForFormula += 11; // super or sub is <sup></sup> or <sub></sub>
        }
        if (op?.attributes?.bold) {
          positionForFormula += 4; // bold is **X**
        }
        if (op?.attributes?.italic) {
          positionForFormula += 2; // italic is *X*
        }
        if (op?.attributes?.underline) {
          positionForFormula += 7; // underline is <u></u>
        }
      }
    });
    if (currentPosition === 0) {
      const ops = this.editor.getContents().ops;
      if (ops.findIndex((op) => typeof op.insert === 'string' && op.insert.trim().length) < 0) {
        ops.forEach((op) => {
          if (op?.insert?.formula) {
            positionForFormula += op.insert.formula.length + 2;
          } else if (op?.attributes) {
            if (op?.attributes?.list === 'bullet') {
              positionForFormula += 2;
            }
            if (op?.attributes?.list === 'ordered') {
              positionForFormula += 3;
            }
            if (op?.attributes?.script === 'super' || op?.attributes?.script === 'sub') {
              positionForFormula += 11;
            }
            if (op?.attributes?.bold) {
              positionForFormula += 4;
            }
            if (op?.attributes?.italic) {
              positionForFormula += 2;
            }
            if (op?.attributes?.underline) {
              positionForFormula += 7;
            }
          }
        });
      }
    }
    this.questionsService.editorCursorPosition.next(positionForFormula);
  }

  extractFilesFromHtml(event): Observable<Blob> | null {
    /* this function is called during a paste event when
    you paste html from a web page after copying by
    clicking on Copy Image.
    It extracts the image file from the html.
    The reason we use urls[0] is because we upload
    one image at a time which is consistent with
    the old editor.
     */
    const urls: string[] = [];
    const html = event.clipboardData.getData('text/html');
    html.replace(/<(img src|img [^>]* src) *="([^"]*)"/gi, function (m, n, src) {
      urls.push(src);
    });

    if (urls.length) {
      event.preventDefault();
      return this.getImage(urls[0]);
    }
    return null;
  }

  ngOnInit(): void {
    // Add this function as the first handler to the Enter Event
    this.editor.keyboard.bindings[13].unshift({
      key: 13,
      handler: (range, context) => {
        // Prevent event bubble when the enter key is pressed on the an empty chat
        if (this.componentUsedFromChatBox || context.empty) {
          return false;
        }
        return true;
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.editor &&
      changes.focusToEnd &&
      changes.focusToEnd.currentValue > changes.focusToEnd.previousValue
    ) {
      this.editor.focus();
    }
    if (!this.editor) {
      this.setupQuillEditor();
    }

    if (changes.placeholder?.currentValue !== changes.placeholder?.previousValue) {
      this.editor.root.dataset.placeholder = changes.placeholder.currentValue;
    }

    if (this.showFocus && changes.showFocus) {
      this.editor.focus();
      this.editor.setSelection(this.focusPosition);
      this.questionsService.shiftEnterClicked.next('');
    }

    if (changes.fragment) {
      this.resetEditorContent();
    }
  }

  resetEditorContent(): void {
    if (this.editor) {
      if (
        (typeof this.fragment.editorHtml !== 'undefined' && this.fragment.editorHtml !== null) ||
        (typeof this.fragment.fragment?.data !== 'undefined' &&
          this.fragment.fragment?.data !== null)
      ) {
        const convertedHtml = getConvertedHtml(
          this.fragment.editorHtml ||
            getHtmlFromFormattedText(this.fragment.fragment.data || '', this.fromTable),
        );
        this.editor.setContents(this.editor.clipboard.convert(convertedHtml));
      } else {
        this.editor.setContents(this.editor.clipboard.convert(this.fragment.fragment.data));
      }
      this.isContentSet = true;
    }
  }

  setupQuillEditor(): void {
    this.zone.runOutsideAngular(() => {
      const quillMentionConfig = {
        onOpen: this.onQuillMentionOpen.bind(this),
        onClose: this.onQuillMentionClose.bind(this),
        allowedChars: /^[A-Za-z\s]*$/,
        mentionDenotationChars: ['@'],
        positioningStrategy: 'fixed',
        defaultMenuOrientation: 'top',
        mentionContainerClass: 'user-mention-list-container',
        listItemClass: 'user-mention-list-item',
        renderItem: this.renderQuillMentionItem,
        source: this.quillMentionSource.bind(this),
        renderLoading: () => 'Loading...',
      };

      this.editor = new Quill(this.el.nativeElement, {
        modules: {
          formula: true,
          toolbar: this.enableFloatingToolbar && [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
          ],
          mention:
            this.messageContext && this.mentionService.enableMentions(this.messageContext)
              ? quillMentionConfig
              : undefined,
        },
        placeholder: this.placeholder,
        theme: 'bubble', // 'bubble' or 'snow'
      });

      this.el.nativeElement.addEventListener('click', this.setupClickEventListener.bind(this));
      this.el.nativeElement.addEventListener(
        'dblclick',
        this.setupDoubleClickEventListener.bind(this),
      );
      this.el.nativeElement.addEventListener('keyup', this.setupKeyUpEventListener.bind(this));
      this.el.nativeElement.addEventListener('keydown', this.setupKeyDownEventListener.bind(this));
      this.editor.root.addEventListener('paste', this.setupPastEventListener.bind(this));
      this.editor.root.addEventListener('drop', this.setupDropEventListener.bind(this));
      this.editor.on('text-change', this.setupTextChangeEventListener.bind(this));
      this.editor.on('selection-change', this.setupSelectionChangeEventListener.bind(this));
    });
  }

  onQuillMentionOpen(): void {
    this.mentionMenuOpen = true;
  }

  onQuillMentionClose(): void {
    /* Called asynchronously so that this.notificationOpen is not set to false
     * until after this.setupKeyDownEventListener() runs. This way,
     * this.setupKeyDownEventListener() can stop event propagation if necessary,
     * and prevent messages from being sent when the enter key is used to select
     * a user from the mentions popup menu.
     *
     * See https://github.com/afry/quill-mention/issues/172#issuecomment-844567097
     */
    requestAnimationFrame(() => (this.mentionMenuOpen = false));
  }

  async quillMentionSource(
    searchTerm: string,
    renderList: (userMentions: UserMentionItem[]) => void,
  ): Promise<void> {
    try {
      const matchingUserInfo = await firstValueFrom(
        this.mentionService.findMatchingUsers(searchTerm),
      );
      renderList(
        matchingUserInfo.map((userInfo) => ({
          id: userInfo._id,
          value: userInfo.name,
          userInfo,
        })),
      );
    } catch {
      renderList([]);
    }
  }

  renderQuillMentionItem(userMention: UserMentionItem): string {
    const profileImage = userMention.userInfo.profile_image;
    const names = userMention.userInfo.name.split(' ');
    const initials = `${(names[0] && names[0][0]) || ''}${(names[1] && names[1][0]) || ''}`;

    return `
      <div class="user-mention-photo" role="img" ${
        profileImage ? `style="background-image: url(${profileImage});"` : ''
      }>
        ${profileImage ? '' : initials}
      </div>
      <div class="user-mention-text-container">
        <span class="user-mention-name">${userMention.userInfo.name}</span>
        <span class="user-mention-email">${userMention.userInfo.email}</span>
      </div>
    `;
  }

  setupClickEventListener(event): void {
    // check triple click and select all text
    if (event.detail === 3) {
      // using setTimeout because before triple event, single event is triggered first
      requestAnimationFrame(() => {
        this.editor.setSelection(0, this.editor.getText().length);
      });
      return;
    }
    this.hideFocus.emit();
    // calculate cursor position when there are formulas in the fragment
    const index = this.editor.getSelection(true)?.index;
    this.emitCursorPositionForFormula(index);
    const cursorDataDeltaAt = this.editor.getContents(index, 1);
    const cursorDataDeltaBefore = this.editor.getContents(index - 1, 1);
    if (cursorDataDeltaAt.ops.length > 0) {
      const { insert } = cursorDataDeltaAt.ops[0];
      if (insert) {
        const { formula } = insert;
        if (formula) {
          return this.openKatexDialog(formula, index);
        }
      }
    }
    if (cursorDataDeltaBefore.ops.length > 0) {
      const { insert } = cursorDataDeltaBefore.ops[0];
      if (insert) {
        const { formula } = insert;
        if (formula) {
          return this.openKatexDialog(formula, index - 1);
        }
      }
    }
  }

  setupDoubleClickEventListener(): void {
    // calculate cursor position when there are formulas in the fragment
    const index = this.editor.getSelection(true)?.index;
    const len = this.editor.getText().length;
    if (index >= len - 1) {
      this.editor.setSelection(0, len);
    }
  }

  handleBackspaceKeyup(): void {
    // if backspace is clicked at formula, then delete formula
    if (this.isBackspaceClickedAtFormula) {
      this.editor.deleteText(this.cursorPosition, 1);
      this.isBackspaceClickedAtFormula = false;
    } else {
      if (this.cursorPosition === 0 && this.editor.getText().length > 1) {
        this.clickBackspace.emit();
      }
      if (this.cursorPosition === 1) {
        this.deleteCountAtFirstIndex++;
      }
      if (this.deleteCountAtFirstIndex === 2) {
        this.cursorPosition = 0;
        this.deleteCountAtFirstIndex = 0;
      }
    }
  }

  checkIfDataNotList(isList, lines, texts): void {
    // check if the fragment data is not a bullet or ordered list and includes equations
    // and enter is clicked at the first or last index of the fragment
    if (
      !isList &&
      (this.cursorPosition === 0 ||
        this.cursorPosition === this.editor.getText().length ||
        (lines[0] === texts[0] &&
          (texts[0]?.slice(-1) === '$' ||
            texts[0]?.slice(-1) === '*' ||
            texts[0]?.includes('<sub>') ||
            texts[0]?.includes('<sup>') ||
            texts[0]?.includes('<u>'))))
    ) {
      if (this.cursorPosition !== 0) {
        // if enter is clicked at the last index
        this.clickEnter.emit(['']);
        this.editor.setContents(
          this.editor.clipboard.convert(
            lines.slice(0, lines.length > 1 ? lines.length - 1 : lines.length).join(''),
          ),
        );
      } else {
        // if enter is clicked at the first index
        this.clickEnter.emit([lines.join('')]);
        this.editor.setContents('');
      }
    } else {
      this.clickEnter.emit(texts);
      if (texts.length > 1 || (texts.length === 1 && texts[0])) {
        this.editor.setContents(
          this.editor.clipboard.convert(lines.slice(0, lines.length - 2).join('\n')),
        );
      }
    }
  }

  handleOtherKeysUp(e: KeyboardEvent): void {
    if (e.key !== 'Enter' && this.isShiftClicked) {
      this.isShiftClicked = false;
      return;
    }
    if (e.key === 'Enter' && this.isShiftClicked) {
      this.questionsService.shiftEnterClicked.next(this.coll.option_data);
      this.editor.setContents(
        this.editor.clipboard.convert(this.fragment.fragment.data?.split('\n')[0]),
      );
      return;
    }
    if (e.key === 'Enter') {
      if (
        this.editor.getFormat()?.list === 'bullet' ||
        this.editor.getFormat()?.list === 'ordered'
      ) {
        if (this.editor.getText().trim().length) {
          return;
        }
        const ops = this.editor.getContents().ops;
        if (ops.findIndex((op) => typeof op.insert === 'string' && op.insert.trim().length) < 0) {
          return;
        }
      }
      e.preventDefault();
      if (this.disableNewFragment) {
        this.clickEnter.emit(null);
        this.editor.setContents(
          this.editor.clipboard.convert(this.fragment.fragment.data?.split('\n').join('')),
        );
        return;
      }
      const texts: string[] = [];
      const bulletCheckRegex = /\*\s\s\s(.*?)/;
      const orderedCheckRegex = /\d\.\s\s(.*?)/;
      const lines = this.fragment.fragment.data?.split('\n') || [];
      let isList = false;
      lines.slice(lines.length - 2).forEach((t) => {
        const includesBulletList = bulletCheckRegex.test(t);
        const includesOrderedList = orderedCheckRegex.test(t);
        if (t && (includesBulletList || includesOrderedList)) {
          isList = true;
        }
        if (t && !includesBulletList && !includesOrderedList) {
          texts.push(t);
        }
      });
      if (texts.length === 0) {
        texts.push('');
      }
      if (!this.enableList) {
        isList = false;
      }

      this.checkIfDataNotList(isList, lines, texts);
      this.editor.setSelection(this.editor.getText().length);
      this.saveEvent.emit(this.coll);
    } else if (
      e.key === 'Delete' &&
      this.editor.getSelection()?.index + 1 === this.editor.getText().length
    ) {
      if (this.editor.getText() === '\n') {
        this.editor.setContents('');
      }
      this.clickDelete.emit();
    }
  }

  setupKeyUpEventListener(e: KeyboardEvent): void {
    if (this.isKeyEventsDisabled && e.key !== 'Enter') {
      e.stopPropagation();
      return;
    }
    if (this.type === EditorTypes.MESSAGE || this.type === EditorTypes.COMMENT) {
      return;
    }
    this.isEnterClicked = false;
    if (
      this.isControlClicked &&
      (e.key === 'a' ||
        (navigator.platform.indexOf('Mac') > -1 && e.key === 'Meta') ||
        (navigator.platform.indexOf('Mac') === -1 && e.key === 'Control'))
    ) {
      this.isControlClicked = false;
      return;
    }
    if (e.key === 'Backspace') {
      this.handleBackspaceKeyup();
    } else {
      this.handleOtherKeysUp(e);
    }
  }

  setupKeyDownEventListener(e: KeyboardEvent): void {
    if (this.isKeyEventsDisabled && e.key !== 'Enter') {
      e.stopPropagation();
      return;
    }

    if (this.emitTyping) {
      this.isTyping.next(true);
    }
    if (this.type === EditorTypes.MESSAGE) {
      return;
    }
    this.isBackspaceClicked = false;
    if (e.key === 'Backspace') {
      this.isBackspaceClicked = true;
      if (this.cursorPosition === 0 && this.editor.getLength() <= 1) {
        e.preventDefault();
        e.stopPropagation();
        this.backspaceToRemoveFragment.emit();
        return;
      }
    }
    if (e.key === 'ArrowDown' && !this.last) {
      e.preventDefault();
      e.stopPropagation();
      this.handleArrowKeys.emit({ key: e.key, position: this.cursorPosition });
    }
    if (e.key === 'ArrowUp' && !this.first) {
      e.preventDefault();
      e.stopPropagation();
      this.handleArrowKeys.emit({ key: e.key, position: this.cursorPosition });
    }
    if (e.key === 'Enter' && this.mentionMenuOpen) {
      e.stopPropagation();
    }
    if (this.enableShiftEnter && e.key === 'Shift') {
      this.isShiftClicked = true;
    } else if (e.key === 'Enter') {
      this.isEnterClicked = true;
    } else if (e.key === 'a' && this.isControlClicked) {
      this.editor.setSelection(0, this.editor.getText().length);
    } else if (
      (navigator.platform.indexOf('Mac') > -1 && e.key === 'Meta') ||
      (navigator.platform.indexOf('Mac') === -1 && e.key === 'Control')
    ) {
      this.isControlClicked = true;
    }
  }

  setupPastEventListener(event): void {
    // When pasting over a selected text, the browser will delete the selected text
    // after this function completes resulting in unexpected behavior
    // timeout to allow browser delete then proceed with pasting logic
    const image_files = event.clipboardData.files;
    if (image_files && image_files[0]) {
      if (this.fromTable) {
        this.saveImage.emit(image_files[0]);
        return;
      }
      this.coll.addFile(image_files[0], this.uploadService, this.resourceService);
      this.coll.updated = true;
      event.preventDefault();
    } else {
      requestAnimationFrame(() => {
        if (this.extractFilesFromHtml(event)) {
          this.extractFilesFromHtml(event)
            ?.pipe(untilDestroyed(this))
            .subscribe((blob) => {
              this.coll.addFile(<File>blob, this.uploadService, this.resourceService);
              event.preventDefault();
            });
        } else {
          this.handleNonHTMLSource(event);
        }
        const nonEmptyTexts = this.editor
          .getText()
          .split('\n')
          .filter((t) => t);
        if (!this.componentUsedFromChatBox && nonEmptyTexts[1] && !this.editor.getFormat()?.list) {
          const texts: string[] = [];
          nonEmptyTexts.splice(1).forEach((t) => {
            texts.push(this.editor.clipboard.convert(t).ops[0]?.insert || '');
          });
          this.clickEnter.emit(texts);
          this.editor.setContents(
            this.editor.clipboard.convert(this.editor.getText().split('\n')[0]),
          );
          this.saveEvent.emit(this.coll);
          this.saveState.emit(false);
        }
        this.fragment.html = this.editor.root.innerHTML;
        this.saver.next(this.fragment.html);
      });
    }
  }

  public handleNonHTMLSource(event) {
    event.preventDefault();
    const range = this.editor.getSelection(true);
    const text = event.clipboardData.getData('text/plain');
    if (range) {
      const delta = new Delta().retain(range.index).delete(range.length).insert(text);
      const index = text.length + range.index;
      this.editor.updateContents(delta, 'silent');
      requestAnimationFrame(() => {
        this.editor.setSelection(index);
      });
    } else {
      const delta = new Delta().retain(this.cursorPosition).insert(text);
      this.editor.updateContents(delta, 'silent');
      requestAnimationFrame(() => {
        this.editor.setSelection(this.cursorPosition + text.length);
      });
    }
    this.editor.scrollIntoView();
  }

  cleanupFragmentForListFormatting(markup: any[]): any {
    let modifiedMarkup: any[] = [];

    for (const currentObj of markup) {
      if (!currentObj?.attributes) {
        const parts: string[] = currentObj.insert.split('\n');
        const fragmentedArray = parts.map((part, index) => ({
          insert: index < parts.length - 1 ? `${part  }\n` : part,
        }));
        modifiedMarkup = modifiedMarkup.concat(fragmentedArray);
        continue;
      }
      modifiedMarkup.push(currentObj);
    }

    return modifiedMarkup;
  }

  private handleListFormatting(ops: any[]): any[] {
    if (this.type === EditorTypes.MESSAGE && this.formatText) {
      return this.cleanupFragmentForListFormatting(ops);
    }
    return ops;
  }

  setupTextChangeEventListener(delta, oldDelta, source): void {
    if (this.type === EditorTypes.MESSAGE && this.isContentSet && !this.formatText) {
      this.fragment.fragment.data = this.editor.getText();
      this.coll.updated = true;
      this.saver.next(this.fragment.fragment.data);
      return;
    }
    if (
      !this.isContentSet ||
      this.editor.getText().split(' ').length > 5000 ||
      delta.ops.findIndex((op) => op.insert?.formula === 'true') > -1
    ) {
      return;
    }
    if (source === 'user') {
      this.isFragmentChanged = true;
    }
    if (this.editor.getSelection()) {
      this.questionsService.selectedFormat.next(this.editor.getFormat());
    }
    // calculate cursor position when there are formulas in the fragment
    // use setTimeout because we move cursor position manually after adding a latex from dialog
    // and it's not available immediately
    requestAnimationFrame(() => {
      if (this.editor.getSelection()?.index && !this.isEnterClicked) {
        this.cursorPosition = this.editor.getSelection().index;
        this.lastTextChangeIndex = this.cursorPosition;
      }
    });
    let fragmentData = '';
    let tempFragData = ''; // this variable is required because list determining delta is come after its content
    const ops = this.editor.getContents().ops;
    let isEnterClickedAtFormula = false;
    if (
      ops[ops.length - 1]?.insert === '\n' &&
      ops[ops.length - 3]?.insert === '\n' &&
      ops[ops.length - 2]?.insert?.formula &&
      ops[ops.length - 2]?.insert?.formula === ops[ops.length - 4]?.insert?.formula
    ) {
      isEnterClickedAtFormula = true;
    }
    let filteredOps = isEnterClickedAtFormula ? ops.slice(0, ops.length - 2) : ops;

    filteredOps = this.handleListFormatting(filteredOps);

    filteredOps.forEach((op, i) => {
      if (op.insert?.formula) {
        // if editor delta is a formula then wrap with $
        tempFragData += `$$${op.insert.formula}$$`;
      } else {
        // if editor delta is not a formula
        // if delta determines the list then wrap the above deltas with list sign
        if (
          typeof op.insert === 'string' &&
          !op.insert.includes(' ') &&
          !op.insert.trim().length &&
          op.attributes?.list
        ) {
          const ordered = op.attributes?.list === 'ordered';
          const bullet = op.attributes?.list === 'bullet';
          if (this.enableList) {
            fragmentData += `${ordered ? '1. ' : ''}${bullet ? '* ' : ''}${tempFragData}${
              tempFragData ? '\n' : ''
            }`;
          } else {
            fragmentData += `${tempFragData}\n`;
          }
          tempFragData = '';
        } else if (
          typeof op.insert === 'string' &&
          !op.insert.includes(' ') &&
          !op.insert.trim().length &&
          !op.attributes?.list &&
          !op.attributes?.td
        ) {
          // if not list then just add empty line
          fragmentData += `${tempFragData}\n`;
          tempFragData = '';
        } else if (op.insert.mention) {
          const mention: DOMStringMap = op.insert.mention;
          tempFragData += `<user-mention data-id="${mention.id}" data-name="${mention.value}" />`;
        } else if (typeof op.insert === 'string') {
          // if delta is a normal text
          const bold = op.attributes?.bold;
          const italic = op.attributes?.italic;
          const underline = op.attributes?.underline;
          const sup = op.attributes?.script === 'super';
          const sub = op.attributes?.script === 'sub';
          tempFragData += `${sup ? '<sup>' : ''}${sub ? '<sub>' : ''}${underline ? '<u>' : ''}`;
          tempFragData += `${bold ? '**' : ''}${italic ? '*' : ''}`;
          tempFragData += `${
            /\$\$(.*?)\$\$/.test(op.insert) ? op.insert : this.escapeLatexDelimeter(op.insert)
          }`;
          tempFragData += `${italic ? '*' : ''}${bold ? '**' : ''}`;
          tempFragData += `${underline ? '</u>' : ''}${sub ? '</sub>' : ''}${sup ? '</sup>' : ''}`;
          if (tempFragData.includes('\n')) {
            fragmentData += tempFragData;
            tempFragData = '';
          }
        }
      }
    });
    if (delta.ops[0]?.retain && delta.ops[1]?.attributes?.list) {
      const format = delta.ops[1]?.attributes?.list;
      this.lastTextChangeIndex += format === 'bullet' ? 2 : 3;
      fragmentData += format === 'bullet' ? '* ' : '1. ';
    }

    if (
      this.fragment.fragment.data !== fragmentData &&
      /\$\$(.*?)\$\$/.test(fragmentData) &&
      this.cursorPosition === 0 &&
      this.isFragmentChanged
    ) {
      requestAnimationFrame(() => {
        this.isFragmentChanged = false;
        this.resetEditorContent();
      });
    }

    // set focus into a specific position after adding a latex from dialog
    if (this.showFocus) {
      if (this.focusPosition) {
        this.editor.setSelection(this.focusPosition);
      } else {
        this.editor.setSelection(this.editor.getText().length);
      }
    }
    this.fragment.fragment.data = fragmentData;
    if (this.isContentSet) {
      this.coll.updated = true;
      this.saver.next(this.fragment.fragment.data);
    }
  }

  escapeLatexDelimeter(str: string): string {
    /*
      Whenever $ is typed in the editor, we should escape it because it affects to latex.
      But adding one backslash doesn't work because /\$\$(.*?)\$\$/ considers that
      \$\$...\$\$ and $$...$$ are same.
      So added 2 backslashes and because we add backslashes whenever editor is updated,
      unlimited backslashes will be added.
      To prevent it, replaced multiple backslashes with only 2.
    */
    return str
      .replace(/\$/g, '\\\\$')
      .replace(/\\\\\\\\\$/g, '\\\\$')
      .replace(/\\\\\\\$/g, '\\\\$');
  }

  setupDropEventListener(event): void {
    event.stopPropagation();
    this.dropStart.emit(true);
    const image_data = event.dataTransfer;
    if (image_data && image_data.files) {
      for (const image_file of image_data.files) {
        if (image_file.type.includes('image')) {
          this.coll.addFile(image_file, this.uploadService, this.resourceService);
          event.preventDefault();
        }
      }
    }
  }

  setupSelectionChangeEventListener(range, oldRange, source): void {
    if (this.type === EditorTypes.MESSAGE) {
      return;
    }
    // we can use params later
    this.setSelectionRange.emit(range);
    if (range) {
      this.editor.root.dataset.placeholder = '';
      this.cursorPosition = range.index;
      // check if Backspace is clicked at formula
      if (oldRange?.index - range?.index === 1 && source === 'user' && this.isBackspaceClicked) {
        this.isBackspaceClickedAtFormula = true;
      }
      if (source === 'user') {
        this.lastTextChangeIndex = range.index;
      }
      this.focused.emit();
      if (range.length) {
        this.editor
          .getContents(this.editor.getSelection()?.index, this.editor.getSelection()?.length)
          .ops.forEach((op) => {
            if (op.insert?.formula) {
              this.questionsService.formulaSelected.next(true);
            }
          });
        this.questionsService.textSelected.next(true);
        this.questionsService.selectedFormat.next(this.editor.getFormat());
      } else {
        this.questionsService.textSelected.next(false);
        this.questionsService.selectedFormat.next(null);
        this.questionsService.formulaSelected.next(false);
      }
    } else {
      this.editor.root.dataset.placeholder = this.placeholder;
      this.cursorPosition = 0;
      this.blurred.emit();
    }
  }
}

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription, lastValueFrom } from 'rxjs';
import { ReferralService } from 'src/app/services/referral.service';
import { UserService } from 'src/app/services/user.service';
import { TelemetryService } from 'src/app/services/telemetry.service';
import { Router, UrlSerializer } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { IconMessageToasterElement } from 'src/app/ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from 'src/app/services/notification-toaster.service';
import { ToasterPopupStyle } from 'src/app/ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { User } from 'src/app/models/user';
import { modifiedSetTimeout } from 'src/app/utilities/ZoneUtils';
import { SUCCESSES } from '../utils/notification-constants';

export enum ReferralSource {
  INVITE = 'invite',
  ADD_TIME = 'add_time',
}

@UntilDestroy()
@Component({
  selector: 'app-referral-dialog',
  templateUrl: './referral-dialog.component.html',
  styleUrls: ['./referral-dialog.component.scss'],
})
export class ReferralDialogComponent implements OnInit {
  currentUser?: User;
  subscribers: Subscription[] = [];
  referralUrl = '';
  referralId = '';
  sessionId = '';
  referralSource: ReferralSource;
  emails: string[] = [];
  invalidEmails: string[] = [];
  showLinkCopied = false;

  constructor(
    private userService: UserService,
    private referralService: ReferralService,
    private translateService: TranslateService,
    private notificationToasterService: NotificationToasterService,
    private telemetry: TelemetryService,
    private router: Router,
    private serializer: UrlSerializer,
    @Optional() private dialogRef: MatDialogRef<ReferralDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.sessionId = data.sessionId;
    this.referralSource = data.referralSource;
  }

  async ngOnInit() {
    this.userService.user.pipe(untilDestroyed(this)).subscribe((userData) => {
      this.currentUser = userData?.user;
    });
    await this.getReferralLink(true);
  }

  async sendReferralEmails() {
    if (!this.emails.length || this.invalidEmails.length) {
      return;
    }
    if (!this.referralId) {
      this.referralId = await this.getReferralLink(false);
    }
    this.showNotification();
    this.close();
    this.referralService
      .sendReferralEmails(this.referralId, this.emails.join(' '), this.sessionId)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.telemetry.event('referral_sent', {
          n_users_referred: this.emails.length,
        });
        this.emails = [];
      });
  }

  async getReferralLink(copyLink: boolean) {
    if (!this.currentUser) {
      return;
    }

    const referral_user = this.currentUser._id;
    const space_id = this.sessionId;
    const utm_source = 'copy_link';
    const utm_medium = 'invite';
    const resData: any = await lastValueFrom(
      this.referralService.createReferral(referral_user, space_id, copyLink),
    );
    if (copyLink) {
      const urlTree = this.router.createUrlTree([], {
        queryParams: {
          referral_id: resData.referral_id,
          utm_source,
          utm_medium,
        },
      });
      this.referralUrl = location.origin + this.serializer.serialize(urlTree);
    }
    return resData.referral_id;
  }

  showNotification() {
    const isSingleEmail = this.emails.length === 1;
    const topElement = new IconMessageToasterElement(
      { icon: 'check', size: 16 },
      isSingleEmail
        ? this.translateService.instant('Referral sent!')
        : this.translateService.instant('Referrals sent!'),
    );

    const notificationDataBuilder = new NotificationDataBuilder(SUCCESSES.REFERRAL_SENT)
      .type(NotificationType.SUCCESS)
      .style(ToasterPopupStyle.SUCCESS)
      .priority(380)
      .timeOut(5)
      .topElements([topElement]);

    const middleElementString = isSingleEmail
      ? this.translateService.instant(
          'Your referral is on its way! Please ask your friend to check their email!',
        )
      : this.translateService.instant(
          'Your referrals are on their way! Please ask your friends to check their emails!',
        );

    const middleElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(middleElementString),
    );

    const notificationData = notificationDataBuilder.middleElements([middleElement]).build();
    this.notificationToasterService.showNotification(notificationData);
  }

  close() {
    this.dialogRef.close();
  }

  copyLink() {
    if (this.referralUrl) {
      this.showLinkCopied = true;
      navigator.clipboard.writeText(this.referralUrl);
      modifiedSetTimeout(() => {
        this.showLinkCopied = false;
      }, 3000);
    }
  }

  handleEmailsInput({
    emails,
    invalidEmails,
  }: {
    emails: string[];
    invalidEmails: string[];
  }): void {
    this.emails = emails;
    this.invalidEmails = invalidEmails;
  }
}

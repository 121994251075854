import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { inRange, isEqual } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject, filter, firstValueFrom, fromEvent, tap } from 'rxjs';
import { FrameItem, ItemModel, WebViewerState } from '../models/session';
import { ResourceItemModel } from '../models/resource';
import {
  AppsConfiguration,
  IMAGES_DEFAULT_WIDTH,
  OTHER_ITEMS_DEFAULT_HEIGHT,
} from '../sessions/session/iframe/additional-apps.utils';
import { FragmentType } from '../common/typed-fragment/typed-fragment';
import {
  CanvasItem,
  ItemData,
  Position,
} from '../sessions/session/items-canvas/items-canvas.component';
import { YStaticCanvas, YCanvas } from '../sessions/session/wb-canvas/fabric-utils';
import { BoardItemsInteractionsManager } from '../sessions/session/items-canvas/board-items-interactions-manager';
import { SpacesService } from './spaces.service';
import { SessionSharedDataService } from './session-shared-data.service';
import { SessionsVptService } from './sessions-vpt.service';
import { UiService } from './ui.service';
import { TelemetryService } from './telemetry.service';
import { FLAGS, FlagsService } from './flags.service';

export enum AppIconSource {
  ICON = 'ICON',
  ASSET_IMG = 'ASSET-IMG',
  HTTP_IMG = 'HTTP-IMG',
}

enum DefaultAppTitles {
  IFRAME = 'IFrame',
  WEB_VIEWER = 'Web Viewer',
  MARIO = 'Mario',
}

export interface OverlayApp {
  title: string;
  icon: string;
  iconSource: AppIconSource;
}

export interface CanvasItemInteractionBar {
  title?: string;
  translateTitle?: boolean;
  icon?: {
    height: number;
    width: number;
    content: string;
    type: AppIconSource;
  };
  allowedActions: {
    duplicate: boolean;
    refresh: boolean;
    fullscreen: boolean;
  };
  adjustPosition?: {
    width?: number;
    height?: number;
    top?: number;
    left?: number;
  };
}

export const isItemWithSpecialZIndex = (type: ItemModel): boolean =>
  [
    ItemModel.IFrame,
    ItemModel.WebViewer,
    ItemModel.Mario,
    ItemModel.Chat_GPT,
    ItemModel.RemoteVideo,
    FragmentType.WebViewer,
    FragmentType.Mario,
    FragmentType.Iframe,
  ].includes(type);

interface ImageResourceLoadingInfo {
  img?: string;
  height: number;
  width: number;
}

@Injectable({
  providedIn: 'root',
})
export class ItemsCanvasService {
  private _uploadingImagesMap = new Map<string, ImageResourceLoadingInfo>();
  private _uploadingFilesMap = new Map<string, File>();
  private _resourceLoadingResourceMap = new Map<string, string>();
  private _resourcesLoadingItemsMap = new Map<string, BehaviorSubject<FrameItem[]>>();
  public formulaRendered$ = new Subject<{
    canvasItem: CanvasItem;
    relatedFabricObject: fabric.Object;
  }>();
  public formulaObjectRendered$ = this.formulaRendered$.asObservable();

  private disableResourceSigning: boolean = this.flagsService.isFlagEnabled(
    FLAGS.DISABLE_RESOURCE_SIGNING,
  );

  constructor(
    private translate: TranslateService,
    private spacesService: SpacesService,
    private sharedDataService: SessionSharedDataService,
    private sessionsVptService: SessionsVptService,
    private uiService: UiService,
    private telemetry: TelemetryService,
    private flagsService: FlagsService,
    private boardItemsInteractionsManager: BoardItemsInteractionsManager,
  ) {}

  createOverlayAppFromAppsConfig(type: string, itemState?: WebViewerState): OverlayApp | null {
    const app = AppsConfiguration[type]?.app;
    if (app?.icon) {
      const iconSource = this.getIconSource(app.icon as string);

      const iconsMap = {
        collaborative_browser: 'overlay_web_viewer',
        code_iframe: 'overlay_iframe',
      };

      return {
        title: itemState?.title ?? app.title,
        icon: iconsMap[app.icon] ?? app.icon,
        iconSource,
      };
    }
    return null;
  }

  createOverlayAppForResource(resourceTypeModel?: ResourceItemModel): OverlayApp | undefined {
    switch (resourceTypeModel) {
      case ResourceItemModel.VIDEO:
        return {
          title: this.translate.instant('Video'),
          icon: 'video',
          iconSource: AppIconSource.ICON,
        };
      case ResourceItemModel.DOCUMENT:
      case ResourceItemModel.PDF:
        return {
          title: this.translate.instant('Document'),
          icon: 'pdf-file',
          iconSource: AppIconSource.ICON,
        };
    }

    return undefined;
  }

  createOverlayApp(item: FrameItem): OverlayApp | undefined {
    switch (item.model) {
      case ItemModel.Chat_GPT:
        return {
          title: this.translate.instant('Chat with GPT'),
          icon: 'app_chat_gpt_blue',
          iconSource: AppIconSource.ICON,
        };
      case ItemModel.WebViewer:
        const wvOverlayData = this.createOverlayAppFromAppsConfig(
          item.browser_state?.type as string,
          item.browser_state,
        );

        return (
          wvOverlayData ?? {
            title: this.translate.instant('Web Viewer'),
            icon: 'web_viewer',
            iconSource: AppIconSource.ICON,
          }
        );
      case ItemModel.IFrame:
        const ifOverlayData = this.createOverlayAppFromAppsConfig(
          item.iframe_state?.type as string,
        );
        return (
          ifOverlayData ?? {
            title: this.translate.instant('Web Viewer'),
            icon: 'iframe',
            iconSource: AppIconSource.ICON,
          }
        );

      case ItemModel.Mario:
        const mrOverlayData = this.createOverlayAppFromAppsConfig(item.mario_state?.name as string);
        return (
          mrOverlayData ?? {
            title: this.translate.instant('Web Viewer'),
            icon: 'iframe',
            iconSource: AppIconSource.ICON,
          }
        );
      case ItemModel.Resource:
        return this.createOverlayAppForResource(item.resource_state?.resource_item_model);
    }

    return undefined;
  }

  getInteractionBarIcon(item: FrameItem): {
    type: AppIconSource;
    content: string;
    height: number;
    width: number;
  } {
    let config: { app: { icon: string } };
    try {
      switch (item.model) {
        case ItemModel.Chat_GPT:
          return {
            type: AppIconSource.HTTP_IMG,
            content: item.gpt_state?.icon as string,
            height: 32,
            width: 32,
          };
        case ItemModel.IFrame:
          config = AppsConfiguration[item.iframe_state?.type as string];
          return {
            type: this.getIconSource(config?.app.icon),
            content: config.app.icon as string,
            height: 32,
            width: 32,
          };
        case ItemModel.WebViewer:
          config = AppsConfiguration[item.browser_state?.type as string];
          return {
            type: this.getIconSource(config.app.icon),
            content: config.app.icon,
            height: 32,
            width: 32,
          };
        case ItemModel.Mario:
          config = AppsConfiguration[item.mario_state?.name as string];
          return {
            type: this.getIconSource(config.app.icon),
            content: config.app.icon,
            height: 32,
            width: 32,
          };
      }

      return {
        type: AppIconSource.ICON,
        content: 'collaborative_browser',
        height: 32,
        width: 32,
      };
    } catch (error) {
      Sentry.captureException(error);

      return {
        type: AppIconSource.ICON,
        content: 'collaborative_browser',
        height: 32,
        width: 32,
      };
    }
  }

  createInteractionBar(item: FrameItem): CanvasItemInteractionBar | null {
    const icon = this.getInteractionBarIcon(item);

    let interactionBar: CanvasItemInteractionBar | null = null;
    let config: { app: { icon: string; title: string; allowFullscreen?: boolean } };
    switch (item.model) {
      case ItemModel.Chat_GPT:
        interactionBar = {
          title: item.gpt_state?.name as string,
          translateTitle: false,
          icon,
          allowedActions: {
            duplicate: false,
            refresh: false,
            fullscreen: false,
          },
          adjustPosition: {
            width: 3,
            top: 0,
            left: 2,
          },
        };
        break;
      case ItemModel.IFrame:
        config = AppsConfiguration[item.iframe_state?.type as string];
        interactionBar = {
          title: config?.app.title || this.translate.instant(DefaultAppTitles.IFRAME),
          translateTitle: true,
          icon,
          allowedActions: {
            duplicate: true,
            refresh: true,
            fullscreen: true,
          },
        };
        break;
      case ItemModel.WebViewer:
        config = AppsConfiguration[item.browser_state?.type as string];
        interactionBar = {
          title:
            item.browser_state?.title ??
            config?.app.title ??
            this.translate.instant(DefaultAppTitles.WEB_VIEWER),
          translateTitle: true,
          icon,
          allowedActions: {
            duplicate: true,
            refresh: true,
            fullscreen: true,
          },
        };
        break;
      case ItemModel.Mario:
        config = AppsConfiguration[item.mario_state?.name as string];
        interactionBar = {
          title: config?.app.title || this.translate.instant(DefaultAppTitles.MARIO),
          translateTitle: true,
          icon,
          allowedActions: {
            duplicate: true,
            refresh: true,
            fullscreen: config?.app.allowFullscreen ?? false,
          },
        };
        break;
    }

    return interactionBar;
  }

  getAppName(item: FrameItem): string | undefined {
    let title;
    switch (item.model) {
      case ItemModel.Chat_GPT:
        title = item.gpt_state?.name as string;
        break;
      case ItemModel.IFrame:
        title = AppsConfiguration[item.iframe_state?.type as string]?.app?.title;
        break;
      case ItemModel.WebViewer:
        title = AppsConfiguration[item.browser_state?.type as string]?.app?.title;
        break;
      case ItemModel.Mario:
        title = AppsConfiguration[item.mario_state?.name as string]?.app?.title;
        break;
    }

    return title;
  }

  async getTrustedUrl(dataUrl: string, isPublicUrl = false): Promise<string> {
    try {
      const hasExpiresToken = !!new URL(dataUrl).searchParams.get('Expires');
      if (
        isPublicUrl ||
        (this.disableResourceSigning && !hasExpiresToken) ||
        (dataUrl.startsWith(environment.googleStorageBucketURL) && !hasExpiresToken)
      ) {
        // Return URL if it is a public resource (like resource library resource)
        return dataUrl;
      } else {
        const [trustedUrl] = await firstValueFrom(this.spacesService.getSignedUrl(dataUrl));
        return trustedUrl;
      }
    } catch (err) {
      Sentry.captureException(err);
      return dataUrl;
    }
  }

  getImageDimension(item: ItemData): Promise<{ height: number; width: number }> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        if (item.options?.isResourceLibraryObject) {
          return resolve({ height: img.height, width: img.width });
        }
        let width = IMAGES_DEFAULT_WIDTH;
        let height: number = img.height * (IMAGES_DEFAULT_WIDTH / img.width);

        if (item.options?.height) {
          height = item.options?.height;
          width = img.width * (height / img.height);
        }
        if (item.options?.width) {
          width = item.options?.width;
          height = img.height * (width / img.width);
        }

        resolve({ height: height, width: width });
      };

      img.onerror = () => resolve({ height: 0, width: 0 });
      img.src = item.resourceUrl || '';
    });
  }

  getImageAndDimensionFromFile(id: string): Promise<ImageResourceLoadingInfo> {
    const imagesMap = this._uploadingImagesMap;
    const file = this._uploadingFilesMap.get(id) as File;
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = function (event) {
        const img = new Image();

        img.src = event.target?.result as string;
        img.onload = function () {
          const output = {
            width: IMAGES_DEFAULT_WIDTH,
            height: img.height * (IMAGES_DEFAULT_WIDTH / img.width),
            img: img.src,
          };
          imagesMap.set(id, output);
          resolve(output);
        };
      };

      reader.readAsDataURL(file);
    });
  }

  insertResourceLoadingItem(data: {
    id: string;
    type: ResourceItemModel;
    sessionId: string;
    frameUid: string;
    file: File;
    options?: { index: number; id: string };
  }): void {
    const item = new ItemData(data.id, ItemModel.ResourceLoading, false, data.type);
    item.sessionId = data.sessionId;
    item.frameUid = data.frameUid;
    item.options = data.options;
    item.locked = true;

    if (data.type !== ResourceItemModel.VIDEO) {
      this._uploadingFilesMap.set(data.id, data.file);
    }
    this.sharedDataService.selectedItemsToInsert.next([item]);
  }

  getResourceLoadingFrameItem(uploadId: string, frameUid?: string): FrameItem | undefined {
    if (!frameUid) {
      return;
    }

    return this.loadingResourcesItemsByFrame$(frameUid)
      .getValue()
      .find((item) => item.content_id === uploadId);
  }

  addResourceLoadingItem(item: FrameItem, frameUid: string): void {
    const items = this.loadingResourcesItemsByFrame$(frameUid).getValue();
    items.push(item);

    this.setLoadingItemsForFrame(items, frameUid);
  }

  addResourceLoadingItemToRemoveList(uploadId: string, resourceItemId: string): void {
    this._resourceLoadingResourceMap.set(resourceItemId, uploadId);
  }

  removeRelatedResourceLoadingItems(resourceItemId: string, frameUId?: string): void {
    if (!frameUId) {
      return;
    }

    const items = this.loadingResourcesItemsByFrame$(frameUId).getValue();
    const uploadId = this._resourceLoadingResourceMap.get(resourceItemId);
    if (!items.length || !uploadId) {
      return;
    }
    const filteredItems = items.filter((item) => item.content_id !== uploadId);
    this._uploadingImagesMap.delete(uploadId);
    this._uploadingFilesMap.delete(uploadId);
    this.setLoadingItemsForFrame(filteredItems, frameUId);
  }

  removeResourceLoadingItem(uploadId: string, frameUId: string): void {
    const items = this.loadingResourcesItemsByFrame$(frameUId).getValue();
    if (!items.length) {
      return;
    }
    const filteredItems = items.filter((item) => item.content_id !== uploadId);

    this._uploadingImagesMap.delete(uploadId);
    this._uploadingFilesMap.delete(uploadId);
    this.setLoadingItemsForFrame(filteredItems, frameUId);
  }

  clearResourceLoadingItems(frameUId: string): void {
    this.setLoadingItemsForFrame([], frameUId);
    this._resourceLoadingResourceMap.clear();
    this._uploadingImagesMap.clear();
    this._uploadingFilesMap.clear();
  }

  getImageSourceByUploadId(id: string): string | undefined {
    return this._uploadingImagesMap.get(id)?.img;
  }

  getImageSourceByItemId(resourceItemId: string): string | undefined {
    const uploadId = this._resourceLoadingResourceMap.get(resourceItemId);
    return this._uploadingImagesMap.get(uploadId as string)?.img;
  }

  getFileByItemId(resourceItemId: string): Observable<Uint8Array> | undefined {
    const uploadId = this._resourceLoadingResourceMap.get(resourceItemId);
    const file = this._uploadingFilesMap.get(uploadId as string);
    if (!file) {
      return undefined;
    }

    return new Observable((observer) => {
      const fileReader = new FileReader();
      fileReader.onload = function (ev) {
        if (ev.target?.result) {
          observer.next(new Uint8Array(ev.target.result as ArrayBuffer));
        }

        observer.complete();
      };

      fileReader.readAsArrayBuffer(file);
    });
  }

  async getImageDataById(id: string): Promise<{
    img?: string;
    height: number;
    width: number;
  } | null> {
    if (this._uploadingFilesMap.has(id)) {
      return this.getImageAndDimensionFromFile(id);
    }

    return null;
  }

  getIconSource(icon: string): AppIconSource {
    return icon.includes('.') ? AppIconSource.ASSET_IMG : AppIconSource.ICON;
  }

  getRelatedResourceLoadingPosition(
    resourceItemId: string,
    frameUid: string,
  ): { top: string; left: string } | null {
    const uploadId = this._resourceLoadingResourceMap.get(resourceItemId);
    const resourceLoadingItem = this.loadingResourcesItemsByFrame$(frameUid)
      .getValue()
      .find((item) => item.content_id === uploadId);

    const top = resourceLoadingItem?.position?.get('top');
    const left = resourceLoadingItem?.position?.get('left');
    if (!top || !left) {
      return null;
    }

    return {
      top,
      left,
    };
  }

  validateMetaData(metaData: Map<string, string>): void {
    const keysDefaultValues = {
      height: OTHER_ITEMS_DEFAULT_HEIGHT.toString(),
      width: OTHER_ITEMS_DEFAULT_HEIGHT.toString(),
      rotation: '0',
      top: '100',
      left: '100',
    };
    for (const [key, value] of Object.entries(keysDefaultValues)) {
      if (isNaN(Number(metaData.get(key)))) {
        metaData.set(key, value);
      }
    }
  }

  setFabricObjectState(canvasItem: CanvasItem, metaData: Map<string, string>): void {
    if (!metaData || !canvasItem.relatedFabricItem) {
      return;
    }

    if (canvasItem.resizable || canvasItem.type === ItemModel.ResourceLoading) {
      canvasItem.relatedFabricItem.set({
        height: parseFloat(metaData.get('height') ?? OTHER_ITEMS_DEFAULT_HEIGHT.toString()),
        width: parseFloat(metaData.get('width') ?? OTHER_ITEMS_DEFAULT_HEIGHT.toString()),
        angle: parseFloat(metaData.get('rotation') ?? '0'),
      });
    }
    if (canvasItem.movable || canvasItem.type === ItemModel.ResourceLoading) {
      const previousPosition = {
        left: canvasItem.relatedFabricItem.left,
        top: canvasItem.relatedFabricItem.top,
      };
      const newPosition = {
        left: parseFloat(metaData?.get('left') ?? '100'),
        top: parseFloat(metaData?.get('top') ?? '100'),
      };
      const positionChanged = !isEqual(previousPosition, newPosition);
      if (!positionChanged) {
        return;
      }

      const itemIsPartOfAMultiSelection = !!canvasItem.relatedFabricItem.group;
      if (itemIsPartOfAMultiSelection) {
        // Because (it seems) that the fabric object of a multiSelected one will be a fabric object that spans over all the selected items
        // So we remove that selection to avoid changing the position of the selection instead of moving the item itself
        this.boardItemsInteractionsManager.triggerDeselectAll();
      }
      canvasItem.relatedFabricItem.set(newPosition);

      canvasItem.relatedItem.position?.set('top', `${newPosition.top}`);
      canvasItem.relatedItem.position?.set('left', `${newPosition.left}`);
    }
  }

  setItemState(
    itemId: string,
    options: {
      index?: number;
      canvasItems: { [id: string]: CanvasItem };
      canvas?: YStaticCanvas | YCanvas;
    },
    metaData?: Map<string, string>,
  ): void {
    const canvasItem = options.canvasItems[itemId];
    if (!metaData || !canvasItem.relatedFabricItem) {
      return;
    }
    this.validateMetaData(metaData);
    this.setFabricObjectState(canvasItem, metaData);

    if (
      [FragmentType.Iframe, FragmentType.WebViewer, FragmentType.Mario].includes(
        canvasItem.relatedFabricItem.type as FragmentType,
      )
    ) {
      canvasItem.relatedFabricItem.minScaleLimit =
        400 / (canvasItem.relatedFabricItem.width ?? 400);
    }

    canvasItem.relatedFabricItem.setCoords();
    canvasItem.relatedFabricItem.fire('remoteChange');

    options.canvas?.requestRenderAll();

    if (metaData.has('index')) {
      canvasItem.itemState.index = parseInt(metaData.get('index') ?? '-1', 10);
    } else {
      canvasItem.itemState.index = options.index ?? 0;
    }
    canvasItem.itemState.fontSize = parseInt(metaData.get('font:size') ?? '32', 10);
    canvasItem.itemState.fontColor = metaData.get('font:color') ?? canvasItem.itemState.fontColor;
    canvasItem.itemState.backgroundColor =
      metaData.get('background:color') ?? canvasItem.itemState.backgroundColor;
    if (canvasItem.relatedFabricItem) {
      canvasItem.relatedFabricItem['itemData'] =
        canvasItem.itemSettings?.resourceComponentInputs?.fragment?.fragment.data;
      canvasItem.relatedFabricItem['settings'] = metaData;
    }
  }

  getDropPositionOnCanvas(event): Position {
    const boundingRect = this.sessionsVptService.getViewPortBoundingRect();
    if (boundingRect !== undefined && window.innerHeight && window.innerWidth) {
      // Changing the drop point to be relative the canvas dimensions not the screen ones.
      const x = event.layerX;
      const relativeX = x / window.innerWidth;
      const width = boundingRect.right - boundingRect.left;
      const newX = boundingRect.left + relativeX * width;

      const y = event.layerY;
      const relativeY = y / window.innerHeight;
      const height = boundingRect.bottom - boundingRect.top;
      const newY = boundingRect.top + relativeY * height;
      return {
        top: newY,
        left: newX,
      };
    } else {
      const centerCoords = this.sessionsVptService.getCenterCoords();
      return {
        top: centerCoords.y,
        left: centerCoords.x,
      };
    }
  }

  setItemsCanvasSessionVars(objects: FrameItem[]) {
    let count_pdfs = 0;
    let count_webviewers = 0;
    let count_iframe_mario_apps = 0;
    let count_images = 0;

    objects.forEach((o) => {
      if (
        o.resource_state?.resource_item_model === ResourceItemModel.PDF ||
        o.resource_state?.resource_item_model === ResourceItemModel.DOCUMENT
      ) {
        count_pdfs++;
      }

      if (o.resource_state?.resource_item_model === ResourceItemModel.IMAGE) {
        count_images++;
      }

      if (o.model === ItemModel.WebViewer) {
        count_webviewers++;
      }

      if (
        !this.uiService.isMobile.getValue() &&
        [ItemModel.Mario, ItemModel.IFrame].includes(o.model as ItemModel)
      ) {
        count_iframe_mario_apps++;
      }
    });
    this.telemetry.setSessionVars({
      count_pdfs,
      count_webviewers,
      count_iframe_mario_apps,
      count_images,
    });
  }

  setLoadingItemsForFrame(frameItems: FrameItem[], frameUId: string) {
    if (this._resourcesLoadingItemsMap.has(frameUId)) {
      this._resourcesLoadingItemsMap.get(frameUId)?.next(frameItems);
    } else {
      this._resourcesLoadingItemsMap.set(frameUId, new BehaviorSubject<FrameItem[]>(frameItems));
    }
  }

  loadingResourcesItemsByFrame$(frameUId: string): BehaviorSubject<FrameItem[]> {
    if (!this._resourcesLoadingItemsMap.has(frameUId)) {
      this._resourcesLoadingItemsMap.set(frameUId, new BehaviorSubject<FrameItem[]>([]));
    }

    return this._resourcesLoadingItemsMap.get(frameUId) ?? new BehaviorSubject<FrameItem[]>([]);
  }

  getObjectResizeRestrictionObservable$(
    object: fabric.Object,
    restrictionBaseOn: 'height' | 'width',
    options: { max: number; min: number; aspectRatio: number },
  ): Observable<fabric.IEvent<Event>> {
    const getClippedDimension = (scaledDimension: number): number => {
      if (inRange(scaledDimension, options.min, options.max)) {
        return scaledDimension;
      }

      return scaledDimension > options.max ? options.max : options.min;
    };

    return fromEvent(this.sharedDataService.fabricCanvas as fabric.Canvas, 'object:scaling').pipe(
      filter(
        (event) =>
          event.target === object ||
          ((event.target as fabric.Group)?._objects || []).includes(object),
      ),
      tap((event) => {
        let height = object.getScaledHeight();
        let width = object.getScaledWidth();
        const scaledDimension = restrictionBaseOn === 'height' ? height : width;
        const clippedDimension = getClippedDimension(scaledDimension);

        if (restrictionBaseOn === 'height') {
          height = clippedDimension;
          width = clippedDimension * options.aspectRatio;
        } else {
          width = clippedDimension;
          height = clippedDimension * options.aspectRatio;
        }

        object.set({
          height,
          width,
          scaleX: 1,
          scaleY: 1,
        });
      }),
      tap((event) => {
        const target = event.target as fabric.Object & { lastTop?: number; lastLeft?: number };

        const { lastLeft, lastTop, left, top } = target;
        if (lastLeft && lastTop) {
          object.set({
            left: lastLeft ?? left,
            top: lastTop ?? top,
          });
        }

        this.sharedDataService.fabricCanvas?.fire('object:modified', object);

        event.e.preventDefault();
        event.e.stopPropagation();
      }),
    );
  }

  captureElementClickBehindRelatedFabricObject$(
    relatedFabricItem: fabric.Object,
    targetElement?: HTMLElement,
  ): Observable<fabric.IEvent<Event>> {
    return fromEvent(this.sharedDataService.fabricCanvas as fabric.Canvas, 'mouse:down').pipe(
      filter((event) => event.target === relatedFabricItem && Boolean(targetElement)),
      filter((event) => {
        const { clientX, clientY } = event.e as any as { clientX: number; clientY: number };

        const boundingClientRect = (targetElement as HTMLElement).getBoundingClientRect();

        return (
          clientX >= boundingClientRect.left &&
          clientX <= boundingClientRect.right &&
          clientY >= boundingClientRect.top &&
          clientY <= boundingClientRect.bottom
        );
      }),
    );
  }
}

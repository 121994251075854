import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-user-intersection-wrapper',
  templateUrl: './user-intersection-wrapper.component.html',
  styleUrls: ['./user-intersection-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserIntersectionWrapperComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public rootMargin = '0px';
  @Input() disabled = false;
  @Output() intersectionChange = new EventEmitter<IntersectionObserverEntry>();

  private observer?: IntersectionObserver;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.setUpIntersectionObserver();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled?.currentValue !== changes.disabled?.previousValue) {
      this.toggleElementObserve();
    }
  }

  private setUpIntersectionObserver() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          this.intersectionChange.emit(entry);
        });
      },
      { rootMargin: this.rootMargin },
    );
    this.toggleElementObserve();
  }

  private toggleElementObserve(): void {
    if (this.disabled) {
      this.observer?.unobserve(this.elementRef.nativeElement);
    } else {
      this.observer?.observe(this.elementRef.nativeElement);
    }
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }
}

import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { enableElfProdMode } from '@ngneat/elf';
// import { devTools } from '@ngneat/elf-devtools';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  enableElfProdMode();
} /* else { // Commented as it leads to page crashing. If you want to use it locally, just un-comment this line.
  devTools();
} */

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((moduleRef) => {
    const ngZone = moduleRef.injector.get(NgZone);

    const originalAddEventListener = window.addEventListener;
    window.addEventListener = function (
      type: string,
      listener: EventListenerOrEventListenerObject,
      options?: boolean | AddEventListenerOptions,
    ): void {
      if (type === 'message') {
        ngZone.runOutsideAngular(() => {
          originalAddEventListener.call(window, type, listener as any, options);
        });
        return;
      }
      originalAddEventListener.call(window, type as any, listener as any, options);
    };
  })
  .catch((err) => console.error(err));

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements AfterViewInit {
  @Input() error = false;
  @Input() text = '';
  @Input() link?: { text: string; href: string };
  @Input() arrow?: 'top' | 'left' | 'right' | 'bottom' | 'bottom-right' | 'top-right';
  @Input() tipStyle = '';
  @Input() createNewLine = false;
  @Input() theme: 'black' | 'grey' | 'grey2' | 'white' | 'red' = 'black';
  @Input() textStyle = '';
  @Input() wrapText = false;
  @Input() showTemplate = false;
  @Input() class: string = '';

  constructor(private elRef: ElementRef) {}

  ngAfterViewInit() {
    this.elRef.nativeElement.style.setProperty('--app-tooltip-theme', this.getThemeColor());
  }

  private getThemeColor() {
    if (this.theme === 'grey') {
      return '#333333';
    } else if (this.theme === 'grey2') {
      return '#4F4F4F';
    } else if (this.theme === 'white') {
      return '#FFFFFF';
    } else if (this.theme === 'red') {
      return '#E5616D';
    } else {
      return '#111111';
    }
  }
}

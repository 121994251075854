import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLService } from 'src/app/services/dynamic-url.service';
import {
  RequestAccessClientBaseService,
  SPACES_URL,
  headersWithSkipErrorInterceptor,
  httpOptions,
} from '../base/request-access-client-base.service';
import { AccessResponseBody } from '../base/request-access-base.service';

const WAITING_ROOM_REQUEST_URL: string = 'waitingRoomRequest';
const WAITING_ROOM_RESPONSE_URL: string = 'waitingRoomResponse';

@Injectable({
  providedIn: 'root',
})
export class WaitingRoomClientService extends RequestAccessClientBaseService {
  constructor(protected http: HttpClient, protected urlService: URLService) {
    super(http, urlService);
  }

  sendAccessRequest(sessionID: string): Observable<any> {
    return this.http.patch<any>(
      `${this.urlService.getDynamicUrl()}/${SPACES_URL}/${sessionID}/${WAITING_ROOM_REQUEST_URL}`,
      JSON.stringify({}),
      headersWithSkipErrorInterceptor,
    );
  }

  respondToAccessRequest(sessionID: string, body: AccessResponseBody): Observable<any> {
    return this.http.patch<AccessResponseBody>(
      `${this.urlService.getDynamicUrl()}/${SPACES_URL}/${sessionID}/${WAITING_ROOM_RESPONSE_URL}`,
      JSON.stringify(body),
      httpOptions,
    );
  }
}

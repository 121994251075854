import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-all',
  templateUrl: './select-all.component.html',
  styleUrls: ['./select-all.component.css'],
})
export class SelectAllComponent {
  constructor(
    public dialogRef: MatDialogRef<SelectAllComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  select() {
    this.dialogRef.close(true);
  }
}

import { Injectable } from '@angular/core';
import produce from 'immer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Mutex } from 'async-mutex';
import { BehaviorSubject } from 'rxjs';
import {
  CallParticipant,
  ParticipantEvent,
  ParticipantEventAction,
  TrackEvent,
  TrackEventAction,
  TrackState,
  TrackType,
} from '../common/interfaces/rtc-interface';
import { SubjectMap } from '../sessions/common/session-data-structures';
import { RtcServiceController } from './rtc.service';
import { FLAGS, FlagsService } from './flags.service';
import { SessionCallParticipantsService } from './session-call-participants.service';
import { ProviderStateService } from './provider-state.service';

// State for a stream
export interface UserTrackState {
  track?: MediaStreamTrack;

  // The state of this track from the SFU's point of view
  trackState: TrackState;

  // The three possible states for subscription
  subscribed: boolean | 'staged';

  // The states of the different containers
  videoStates: { [key: number]: VideoState };
}

// State of a video container
interface VideoState {
  // If the container is playing this stream
  playing: boolean;

  // If the container is visible
  visible?: boolean;
}

// Different types of containers
export enum VideoTypes {
  TILE = 0,
  FULLSCREEN = 1,
}

const createBaseVideoState = (): { [key: number]: VideoState } => {
  const obj: { [key: number]: VideoState } = {};
  for (const key in VideoTypes) {
    obj[key] = { playing: false };
  }
  return obj;
};

const baseTrackState: UserTrackState = {
  track: undefined,
  trackState: 'off',
  subscribed: false,
  videoStates: createBaseVideoState(),
};

export interface UserTracksState {
  audio: UserTrackState;
  video: UserTrackState;
  screenVideo: UserTrackState;
  screenAudio: UserTrackState;
  participant?: CallParticipant;
}

const baseUserTracksState: UserTracksState = {
  audio: baseTrackState,
  video: baseTrackState,
  screenVideo: baseTrackState,
  screenAudio: baseTrackState,
};

export type VideoTrackType = Exclude<TrackType, typeof TrackType.AUDIO>;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SessionCallTracksService {
  public userTracksStates: SubjectMap<UserTracksState> = new SubjectMap();
  private usersDevicesStates: BehaviorSubject<{ [key: string]: { mic: boolean; cam: boolean } }> =
    new BehaviorSubject({});
  usersDevicesStates$ = this.usersDevicesStates.asObservable();
  useStaged = false;
  publishUserTracksStateMutex: { [key: string]: { publishMutex: Mutex } } = {};
  handleTracksEventsMutex: { [key: string]: { handleParticipantTracksEventsMutex: Mutex } } = {};
  constructor(
    private rtcService: RtcServiceController,
    private flagsService: FlagsService,
    private sessionCallParticipantsService: SessionCallParticipantsService,
    private providerState: ProviderStateService,
  ) {
    // Disable this old service while flag is enabled as no need to keep storing states on both services
    // as the flag won't be switched during the call
    if (!this.flagsService.isFlagEnabled(FLAGS.CALL_TRACKS_V2)) {
      this.setupEvents();
    }
    if (this.flagsService.isFlagEnabled(FLAGS.USE_STAGED_INSTEAD_OF_UNSUBSCRIBE)) {
      this.useStaged = true;
    }
  }

  private setupEvents() {
    this.sessionCallParticipantsService.participantEvents$.subscribe((e) => {
      if (e) {
        this.handleParticipantEvent(e);
      }
    });
    this.providerState.trackEvents$.pipe(untilDestroyed(this)).subscribe((e) => {
      if (e) {
        this.handleTrackEvent(e);
      }
    });
  }

  // Updates the tracks in the central state based on the track event
  private handleTrackEvent(trackEvent: TrackEvent): void {
    const { participant, action, type } = trackEvent;

    this.handleTracksEventsMutex[
      participant.participantId
    ].handleParticipantTracksEventsMutex.runExclusive(() => {
      // To check if the current started screen sharing has a playable audio or not and subscribe only for this track
      // Then un-subscribe from screenShareAuido after the screen sharing is stopped
      this.handleScreenShareAudio(participant, action, type);

      this.setUserIdDevicesStatesMap(participant);

      const currentState = this.getParticipantTracksState(participant.participantId);
      const track = this.rtcService.getTrack(participant, type);

      if (!currentState) {
        return;
      }

      let nextState = this.updateTracks(currentState, track, action, type);
      nextState = this.updateUserTracksState(nextState, trackEvent.participant);

      this.publishUserTracksState(
        participant.participantId,
        this.updateParticipantWithUserTracks(nextState, participant),
      );
    });
  }

  setUserIdDevicesStatesMap(participant: CallParticipant): void {
    const userId = participant.userId;
    const mic = this.rtcService.trackIsShared(participant.audio);
    const cam = this.rtcService.trackIsShared(participant.video);
    const state = this.usersDevicesStates.getValue();
    state[userId] = { mic, cam };
    this.usersDevicesStates.next(state);
  }

  private handleScreenShareAudio(
    participant: CallParticipant,
    action: TrackEventAction,
    type: TrackType,
  ) {
    if (type === TrackType.SCREEN) {
      switch (action) {
        case TrackEventAction.START:
          this.switchScreenShareAudioSub(participant, true);
          break;
        case TrackEventAction.STOP:
          this.switchScreenShareAudioSub(participant, false);
      }
    }
  }

  private switchScreenShareAudioSub(participant: CallParticipant, enable: boolean) {
    if (this.checkIfScreenShareAudioActive(participant) && enable) {
      this.subscribe(participant.participantId, TrackType.SCREEN_AUDIO);
    } else if (!enable) {
      this.unsubscribe(participant.participantId, TrackType.SCREEN_AUDIO);
    }
  }

  private checkIfScreenShareAudioActive(participant: CallParticipant): boolean {
    return !!(participant.screenAudio && this.rtcService.trackIsShared(participant.screenAudio));
  }

  // Produces the new track state based on the event
  private updateTracks(
    state: UserTracksState,
    track: MediaStreamTrack | undefined,
    trackAction: TrackEventAction,
    trackType: TrackType,
  ) {
    return produce(state, (draft) => {
      switch (trackAction) {
        case TrackEventAction.STOP:
          draft[trackType].track = undefined;
          for (const key in VideoTypes) {
            draft[trackType].videoStates[key].playing = false;
          }
          break;
        case TrackEventAction.START:
          draft[trackType].track = track;
          for (const key in VideoTypes) {
            draft[trackType].videoStates[key].playing = false;
          }
          break;
      }
    });
  }

  // Keeps track of which participants are in the call
  // remove their state if they leave
  // inserts the base state if they join
  private async handleParticipantEvent(participantEvent: ParticipantEvent) {
    const { participant, action } = participantEvent;

    switch (action) {
      case ParticipantEventAction.JOINED:
        // Put the initial state into the observable for that user
        const publishMutex = new Mutex();
        const handleParticipantTracksEventsMutex = new Mutex();
        this.publishUserTracksStateMutex[participant.participantId] = { publishMutex };
        this.handleTracksEventsMutex[participant.participantId] = {
          handleParticipantTracksEventsMutex,
        };
        this.publishUserTracksState(
          participant.participantId,
          this.updateParticipantWithUserTracks(baseUserTracksState, participant),
        );
        this.subscribe(participant.participantId, TrackType.AUDIO); // Always subscribe to audio
        break;
      // @TODO Bassam Fix the potential race condition with track events and leaving the meeting
      // @TODO [Abedalrhamn] reset usersDevicesStates for local user on leave call
      case ParticipantEventAction.LEFT:
        // Remove that user from the list of observables
        await this.publishUserTracksState(participant.participantId, null);
        delete this.publishUserTracksStateMutex[participant.participantId];
        const state = this.usersDevicesStates.getValue();
        delete state[participant.userId];
        this.usersDevicesStates.next(state);
        if (participant.local) {
          this.resetUsersDevicesStates();
        }
        break;
      // To update mic/cam states with participants manager
      case ParticipantEventAction.UPDATED:
        this.setUserIdDevicesStatesMap(participant);
    }
  }

  resetUsersDevicesStates(): void {
    this.usersDevicesStates.next({});
  }

  private getParticipantTracksState(participantId: string): UserTracksState | null {
    return this.userTracksStates.get(participantId).getValue();
  }

  private updateUserTracksState(
    state: UserTracksState,
    participant: CallParticipant,
  ): UserTracksState {
    return produce(state, (draftState) => {
      draftState.audio.trackState = participant.audio;
      draftState.video.trackState = participant.video;
      draftState.screenVideo.trackState = participant.screen;
      draftState.screenAudio.trackState = participant.screenAudio ?? 'off';
    });
  }

  private updateTrackVisibility(
    state: UserTracksState,
    trackType: VideoTrackType,
    visible: boolean,
    videoType: VideoTypes,
  ) {
    return produce(state, (draft) => {
      draft[trackType].videoStates[videoType].visible = visible;
    });
  }

  // Update a tracks visibility and also determine if we should subscribe to that track
  public updateTracksVisibility(
    participantId: string,
    trackType: VideoTrackType,
    visible: boolean,
    videoType: VideoTypes,
    subscribedToVideos = true,
  ) {
    const currentState = this.getParticipantTracksState(participantId);
    if (!currentState) {
      return;
    }

    const nextState = this.updateTrackVisibility(currentState, trackType, visible, videoType);
    this.publishUserTracksState(participantId, nextState);

    if (subscribedToVideos) {
      const streamVisible = Object.values(nextState[trackType].videoStates)
        .map((x) => x.visible)
        .includes(true);
      this.updateSubscriptions(participantId, trackType, streamVisible);
    }
  }

  public updateTrackPlaying(
    participantId: string,
    trackType: TrackType,
    videoType: VideoTypes,
    playing: boolean,
  ) {
    const currentState = this.getParticipantTracksState(participantId);
    if (!currentState) {
      return;
    }

    if (trackType === TrackType.VIDEO && playing && !currentState.video.track) {
      return;
    }

    const nextState = produce(currentState, (draft) => {
      draft[trackType].videoStates[videoType].playing = playing;
    });

    this.publishUserTracksState(participantId, nextState);
  }

  private updateSubscriptions(participantId: string, trackType: VideoTrackType, visible: boolean) {
    const currentState = this.getParticipantTracksState(participantId);
    if (!currentState) {
      return;
    }

    const nextState = produce(currentState, (draft) => {
      draft[trackType].subscribed = this.useStaged ? (visible ? true : 'staged') : visible;
    });

    if (
      !currentState.participant?.local &&
      this.rtcService.service.isSubscribed?.(participantId, trackType) ===
        nextState[trackType].subscribed
    ) {
      return;
    }

    this.publishUserTracksState(participantId, nextState);

    if (visible) {
      this.subscribe(participantId, trackType);
    } else {
      if (this.useStaged) {
        this.stage(participantId, trackType);
      } else {
        this.unsubscribe(participantId, trackType);
      }
    }
  }

  public updateCallSubscriptions(
    participantId: string,
    trackType: VideoTrackType,
    visible: boolean,
  ) {
    this.updateSubscriptions(participantId, trackType, visible);
  }

  private updateParticipantWithUserTracks(
    userTracksState: UserTracksState,
    participant: CallParticipant,
  ): UserTracksState {
    return produce(userTracksState, (draftState) => {
      draftState.participant = participant;
    });
  }

  private subscribe(participantId: string, trackType: TrackType) {
    this.rtcService.service.subscribe?.(participantId, trackType);
  }

  private stage(participantId: string, trackType: TrackType) {
    this.rtcService.service.stage?.(participantId, trackType);
  }

  private unsubscribe(participantId: string, trackType: TrackType) {
    this.rtcService.service.unsubscribe?.(participantId, trackType);
  }

  private publishUserTracksState(
    participantId: string,
    userTracksState: UserTracksState | null,
  ): Promise<void> {
    return this.publishUserTracksStateMutex[participantId]?.publishMutex.runExclusive(() => {
      this.userTracksStates.get(participantId).next(userTracksState);
    });
  }
}

<div class="text-container" [style]="customStyles">
  <div
    class="title-err-msg-container"
    [class.above]="errorLocation === 'above'"
    [hidden]="isTitleValid$ | async"
    [style.left.px]="7 + textField.clientWidth / 2"
  >
    <div
      class="title-err-msg"
      [ngClass]="{ 'top-tip': errorLocation !== 'above', 'bottom-tip': errorLocation === 'above' }"
      [class.above]="errorLocation === 'above'"
    >
      Titles must be {{ maxAllowedCharacters }} characters max
    </div>
  </div>
  <span
    [id]="spanId"
    class="text-box h-100"
    [ngClass]="{ selected: (isEditing$ | async), error: (isTitleValid$ | async) === false }"
    [ngStyle]="
      disabled && { border: '1px solid transparent', background: 'transparent', cursor: 'pointer' }
    "
    [style.display]="
      (isEditing$ | async)
        ? spanEditingInlineFlex
          ? 'inline-flex'
          : 'inline'
        : customStyles
        ? 'flex'
        : 'block'
    "
    [attr.contenteditable]="!disabled"
    spellcheck="false"
    (keydown.enter)="textField.blur()"
    (keydown)="preventNewLine($event); $event.stopPropagation()"
    (keypress)="preventExceedMaxAllowedCharsLength($event)"
    (keyup)="checkValidTitle($event)"
    (blur)="onBlur(textField.innerText)"
    (click)="onClick($event)"
    (selectstart)="onSelect($event)"
    (paste)="handleTitlePaste($event)"
    (dragenter)="$event.preventDefault()"
    #textField
    >{{
      trucateStringBy || (isEditing$ | async) === false
        ? (mainText | truncate: [trucateStringBy || 25])
        : mainText
    }}</span
  >
</div>

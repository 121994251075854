<div id="periodic-table-wrapper" cdkDrag cdkDragHandle class="border bg-white">
  <div class="gadget-header grabbable">
    <app-gadget-header
      icon="science"
      height="15"
      width="15"
      [title]="'Periodic Table' | translate"
      (closed)="closed.emit()"
    >
    </app-gadget-header>
  </div>
  <div class="table-wrapper grabbable">
    <table class="w-100 text-center">
      <thead>
        <tr>
          <th colspan="18">{{ 'Group' | translate }}</th>
        </tr>
        <tr>
          <th>I</th>
          <th>II</th>
          <th colspan="10"></th>
          <th>III</th>
          <th>IV</th>
          <th>V</th>
          <th>VI</th>
          <th>VII</th>
          <th>VIII</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of tableBody">
          <td
            *ngFor="let col of row"
            [attr.colspan]="col.colspan"
            class="{{ col.class }}"
            [style.background]="col.color"
          >
            <p *ngFor="let c of col.content">{{ c }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

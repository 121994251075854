import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDisplayFolder, IFolder, IFolderResponse } from 'src/app/models/resource';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ResourcesService } from '../../services/resources.service';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';

@UntilDestroy()
@Component({
  selector: 'app-move-to-folder',
  templateUrl: './move-to-folder.component.html',
  styleUrls: ['./move-to-folder.component.scss'],
})
export class MoveToFolderComponent {
  user?: User;
  searchMode = false;
  searchVal = '';
  rootFolder?: IFolderResponse;
  currentFolder?: IFolderResponse;
  selectedIndex?: number | null;
  selectedFiles: string[];
  selectedFolders: IDisplayFolder[];
  filteredFolders: any[] = [];
  foldersPerPage = 5000; // show all folders

  constructor(
    // ActiveFolderID represents the id of folder being rendered in Files view.
    @Inject(MAT_DIALOG_DATA)
    public data: {
      selectedFiles?: string[];
      selectedFolders?: IDisplayFolder[];
      activeFolderID?: string;
    },
    private userService: UserService,
    private resourceService: ResourcesService,
    public dialogRef: MatDialogRef<MoveToFolderComponent>,
  ) {
    this.selectedFiles = this.data.selectedFiles || [];
    this.selectedFolders = this.data.selectedFolders || [];
    this.userService.user.pipe(untilDestroyed(this)).subscribe((res: any) => {
      if (res && res.user) {
        this.user = res.user;
        this.resourceService
          .getFolder('root', { folder_num: this.foldersPerPage })
          .pipe(untilDestroyed(this))
          .subscribe((folder) => {
            this.rootFolder = folder;
            this.currentFolder = this.rootFolder;
            this.filteredFolders = this.validFolders();
          });
      }
    });
  }

  search(): void {
    if (!this.searchVal) {
      this.searchMode = false;
      return;
    }
    this.searchMode = true;
    if (this.currentFolder?.subdirectories) {
      this.filteredFolders = this.validFolders(
        this.currentFolder?.subdirectories.filter(
          (f) => f.name?.toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1,
        ),
      );
    } else {
      this.filteredFolders = [];
    }
  }

  cancelSearch(): void {
    this.searchMode = false;
    this.searchVal = '';
    this.filteredFolders = this.validFolders();
  }

  setHoveredIndex(i: number | null) {
    this.selectedIndex = i;
  }

  goToFolder(folderId: string) {
    if (!folderId) {
      return;
    }
    this.resourceService
      .getFolder(folderId, { folder_num: this.foldersPerPage })
      .pipe(untilDestroyed(this))
      .subscribe((folder) => {
        this.currentFolder = folder;
        this.filteredFolders = this.validFolders();
        if (this.searchMode) {
          this.search();
        }
      });
  }

  /**
   * To get the valid version of folders if provided, Or subdirectories of current folder.
   * By valid, We mean that it won't provide any loops, choosing folder to be moved to itself or to one of its descendants.
   * @param folders
   * @returns
   */
  validFolders(folders?: IFolder[]) {
    if (!folders) {
      folders = this.currentFolder?.subdirectories || [];
    }
    const selectedIdMap = this.selectedFolders.map((folder) => folder._id);
    folders.forEach((sub_folder) => {
      if (selectedIdMap?.includes(sub_folder._id)) {
        // just a way to diff between valid and invalid folders.
        sub_folder._id = '';
      }
    });
    return folders;
  }

  moveToFolder() {
    // Close the dialog and returning some info about the destination folder.
    if (this.currentFolder) {
      return this.dialogRef.close({
        selectedFolder: {
          _id: this.currentFolder._id,
          name: this.currentFolder.name,
        },
      });
    }
    return;
  }
}

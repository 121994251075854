<div
  [class]="class + ' position-relative'"
  [class.cml-8]="arrow === 'left'"
  [class.cmr-8]="arrow === 'right'"
  [style.padding-top]="arrow === 'top' || 'top-right' ? '8px' : 0"
  [style.padding-bottom]="arrow === 'bottom' || arrow === 'bottom-right' ? '8px' : 0"
>
  <div [ngClass]="{ wrap: wrapText }" class="custom-tip">
    <em class="material-icons" *ngIf="error">info</em>
    <span
      *ngIf="!showTemplate"
      class="custom-tip-text"
      [ngClass]="{ 'create-new-line': createNewLine }"
      [style]="textStyle"
    >
      {{ text | translate }}
      <ng-content></ng-content>
      <a *ngIf="link" href="{{ link?.href }}"> {{ link?.text }}</a>
    </span>
    <ng-content *ngIf="showTemplate"></ng-content>
  </div>

  <div *ngIf="arrow === 'left'" class="custom-left-tip-after" [style]="tipStyle"></div>
  <div *ngIf="arrow === 'right'" class="custom-right-tip-after" [style]="tipStyle"></div>
  <div *ngIf="arrow === 'top'" class="custom-top-tip-after" [style]="tipStyle"></div>
  <div *ngIf="arrow === 'bottom'" class="custom-bottom-tip-after" [style]="tipStyle"></div>
  <div
    *ngIf="arrow === 'bottom-right'"
    class="custom-bottom-right-tip-after"
    [style]="tipStyle"
  ></div>
  <div *ngIf="arrow === 'top-right'" class="custom-top-right-tip-after" [style]="tipStyle"></div>
</div>

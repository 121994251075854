import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SVGIconComponentsModule } from 'src/app/common/svg-icon/svg-icon-components.module';

@Component({
  selector: 'app-pdf-hidden-page',
  templateUrl: './hidden-page.component.html',
  styleUrls: ['./hidden-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SVGIconComponentsModule],
})
export class HiddenPageComponent {
  @Input() height = 1108;
  @Input() width = 856;
}

import { DateRange } from '@angular/material/datepicker';
import * as moment from 'moment';

export enum DatepickerRangeName {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  PAST_7_DAYS = 'Past 7 days',
  PAST_14_DAYS = 'Past 14 days',
  PAST_30_DAYS = 'Past 30 days',
  PAST_90_DAYS = 'Past 90 days',
  CUSTOM = 'Custom',
}

export const DEFAULT_DATEPICKER_RANGES = new Map<string, { name: string; range: DateRange<Date> }>([
  [
    DatepickerRangeName.TODAY,
    { name: DatepickerRangeName.TODAY, range: new DateRange<Date>(new Date(), new Date()) },
  ],
  [
    DatepickerRangeName.YESTERDAY,
    {
      name: DatepickerRangeName.YESTERDAY,
      range: new DateRange<Date>(
        moment().subtract(1, 'd').toDate(),
        moment().subtract(1, 'd').toDate(),
      ),
    },
  ],
  [
    DatepickerRangeName.PAST_7_DAYS,
    {
      name: DatepickerRangeName.PAST_7_DAYS,
      range: new DateRange<Date>(
        moment()
          .subtract(7 - 1, 'd')
          .toDate(),
        new Date(),
      ),
    },
  ],
  [
    DatepickerRangeName.PAST_14_DAYS,
    {
      name: DatepickerRangeName.PAST_14_DAYS,
      range: new DateRange<Date>(
        moment()
          .subtract(14 - 1, 'd')
          .toDate(),
        new Date(),
      ),
    },
  ],
  [
    DatepickerRangeName.PAST_30_DAYS,
    {
      name: DatepickerRangeName.PAST_30_DAYS,
      range: new DateRange<Date>(
        moment()
          .subtract(30 - 1, 'd')
          .toDate(),
        new Date(),
      ),
    },
  ],
  [
    DatepickerRangeName.PAST_90_DAYS,
    {
      name: DatepickerRangeName.PAST_90_DAYS,
      range: new DateRange<Date>(
        moment()
          .subtract(90 - 1, 'd')
          .toDate(),
        new Date(),
      ),
    },
  ],
  [
    DatepickerRangeName.CUSTOM,
    { name: DatepickerRangeName.CUSTOM, range: new DateRange<Date>(null, null) },
  ],
]);

export const DEFAULT_INITIAL_DATEPICKER_RANGE = DEFAULT_DATEPICKER_RANGES.get(
  DatepickerRangeName.PAST_30_DAYS,
);

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorInterceptorSkipHeader } from '../error.interceptor';
import { Site } from '../models/user';
import { URLService } from './dynamic-url.service';

interface SiteData {
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class InstitutionSitesService {
  constructor(private readonly urlService: URLService, private readonly http: HttpClient) {}
  private apiUrl = `${this.urlService.getDynamicUrl()}/sites`;
  private headers = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }).set(
      ErrorInterceptorSkipHeader,
      '',
    ),
  };

  addSite(siteName: string): Observable<Site> {
    const siteData: SiteData = { name: siteName };
    return this.http.post<Site>(`${this.apiUrl}/`, siteData, {
      ...this.headers,
    });
  }

  updateSite(siteId: string, siteName: string): Observable<Site> {
    const siteData: SiteData = { name: siteName };
    return this.http.patch<Site>(`${this.apiUrl}/${siteId}`, siteData, {
      ...this.headers,
    });
  }
}

import { NgZone } from '@angular/core';
import {
  Observable,
  Subscription,
  SchedulerLike,
  SchedulerAction,
  timer,
  interval,
  debounceTime,
  OperatorFunction,
} from 'rxjs';
import { AsyncScheduler } from 'rxjs/internal/scheduler/AsyncScheduler';
import { AsyncAction } from 'rxjs/internal/scheduler/AsyncAction';
import { NgZoneHolderService } from '../services/ng-zone-holder.service';

declare let Zone: any;

/**
 * Class decorator to turn off Angular Change Detection for events generated by the decorated class.
 * This done by running class constructor and methods in Zone.root instead of NgZone.
 *
 * Usage:
 * @RunOutsideAngular
 * export class Service {
 *   constructor() {
 *     setInterval(() => {
 *       console.log('ctor tick');
 *     }, 1000);
 *   }
 *
 *   run() {
 *     setTimeout(() => {
 *       console.log('tick');
 *     }, 1000);
 *
 *     setInterval(() => {
 *       console.log('tick interval');
 *     }, 1000)
 *   }
 * }
 */
export function RunOutsideAngular(target: any) {
  Object.getOwnPropertyNames(target.prototype)
    .filter((p) => typeof target.prototype[p] === 'function')
    .forEach((p) => {
      const originalMethod = target.prototype[p];
      target.prototype[p] = function (...args: any[]) {
        return Zone.root.run(() => originalMethod.apply(this, args));
      };
    });

  const ctor: any = function (...args: any[]) {
    return Zone.root.run(() => new target(...args));
  };
  ctor.prototype = target.prototype;
  return ctor;
}

/** Use to cause an observable to enter the specified zone */
export function enterZone(zone: NgZone) {
  return <T>(source: Observable<T>) =>
    new Observable<T>((observer) =>
      source.subscribe({
        next: (x) => zone.run(() => observer.next(x)),
        error: (err) => zone.run(() => observer.error(err)),
        complete: () => zone.run(() => observer.complete()),
      }),
    );
}

/** Use to cause an observable to leave the specified zone */
export function leaveZone(zone: NgZone) {
  return <T>(source: Observable<T>) =>
    new Observable<T>((observer) =>
      source.subscribe({
        next: (x) => zone.runOutsideAngular(() => observer.next(x)),
        error: (err) => zone.runOutsideAngular(() => observer.error(err)),
        complete: () => zone.runOutsideAngular(() => observer.complete()),
      }),
    );
}

/** Use to scheudule aync taks in the specified (Angular) zone */
class InZoneScheduler extends AsyncScheduler {
  constructor(private zone: NgZone) {
    super(AsyncAction);
  }
  public schedule<T>(
    work: (this: SchedulerAction<T>, state?: T) => void,
    delay = 0,
    state?: T,
  ): Subscription {
    return this.zone.run(() => super.schedule(work, delay, state));
  }
}

/** Use to scheudule aync taks outside the specified (Angular) zone */
class OutsideZoneScheduler extends AsyncScheduler {
  constructor(private zone: NgZone) {
    super(AsyncAction);
  }
  public schedule<T>(
    work: (this: SchedulerAction<T>, state?: T) => void,
    delay = 0,
    state?: T,
  ): Subscription {
    return this.zone.runOutsideAngular(() => super.schedule(work, delay, state));
  }
}

/** Returns an instance of an InZoneScheduler */
export function inZoneScheduler(zone: NgZone): SchedulerLike {
  return new InZoneScheduler(zone);
}

/** Returns an instance of an OutsideZoneScheduler */
export function outsideZoneScheduler(zone: NgZone): SchedulerLike {
  return new OutsideZoneScheduler(zone);
}

export function modifiedSetTimeout(
  cb: (...args: any[]) => void,
  timeout = 0,
  forceRunOutsideZone = false,
  ...args: any[]
) {
  const runInsideZone = NgZone.isInAngularZone();
  return setTimeout(() => {
    if (runInsideZone && !forceRunOutsideZone) {
      NgZoneHolderService.ngZone.run(() => {
        cb(...args);
      });
    } else {
      cb(...args);
    }
  }, timeout);
}

export function modifiedSetInterval(
  cb: (...args: any[]) => void,
  ms: number,
  forceRunOutsideZone = false,
  ...args: any[]
) {
  const runInsideZone = NgZone.isInAngularZone();
  return setInterval(() => {
    if (runInsideZone && !forceRunOutsideZone) {
      NgZoneHolderService.ngZone.run(() => {
        cb(...args);
      });
    } else {
      cb(...args);
    }
  }, ms);
}

// default will be to run inside zone unless passing false to @runInsideZone param
export function modifiedTimer(
  dueTime: number | Date,
  forceRunOutsideZone?: boolean,
): Observable<number>;
export function modifiedTimer(
  dueTime: number | Date,
  period: number,
  forceRunOutsideZone?: boolean,
): Observable<number>;
export function modifiedTimer(
  dueTime: number | Date,
  period: number,
  scheduler: SchedulerLike,
  forceRunOutsideZone?: boolean,
): Observable<number>;
export function modifiedTimer(...args: any[]): Observable<number> {
  const runInsideZone = NgZone.isInAngularZone();
  let ret: Observable<number> | undefined;
  let forceRunOutsideZone = false;
  if (args.length > 0 && typeof args[args.length - 1] === 'boolean') {
    forceRunOutsideZone = args[args.length - 1];
    args.pop();
  }
  if (args.length === 1) {
    ret = timer(args[0]);
  } else if (args.length === 2) {
    ret = timer(args[0], args[1]);
  } else if (args.length === 3) {
    ret = timer(args[0], args[1], args[2]);
  } else {
    throw new Error('Invalid arguments');
  }

  if (runInsideZone && !forceRunOutsideZone) {
    return ret.pipe(enterZone(NgZoneHolderService.ngZone));
  } else {
    return ret;
  }
}

export function modifiedInterval(period = 0, forceRunOutsideZone = false) {
  const runInsideZone = NgZone.isInAngularZone();
  const ret = interval(period);
  if (runInsideZone && !forceRunOutsideZone) {
    return ret.pipe(enterZone(NgZoneHolderService.ngZone));
  } else {
    return ret;
  }
}

export function modifiedDebounceTime<T>(period: number, forceRunOutsideZone = false): OperatorFunction<T, T>  {
  return (source: Observable<T>): Observable<T> => {
    const runInsideZone = NgZone.isInAngularZone();
    const ret = source.pipe(debounceTime(period));
    if (runInsideZone && !forceRunOutsideZone) {
      return ret.pipe(enterZone(NgZoneHolderService.ngZone));
    } else {
      return ret;
    }
  };
}
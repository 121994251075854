import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  SessionSharedDataService,
  SessionView,
} from 'src/app/services/session-shared-data.service';

@Injectable({
  providedIn: 'root',
})

// currently this is just to solve circular dependancy issues
export class WaitingRoomRepoService {
  private _waitingRoom$: Subject<string[]> = new Subject<string[]>();
  public waitingRoom$ = this._waitingRoom$.asObservable();

  private _isCurrentlyWaitingRoomActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public isCurrentlyWaitingRoomActive$ = this._isCurrentlyWaitingRoomActive$.asObservable();

  private _acceptAllInWaitingRoom$: Subject<boolean> = new Subject<boolean>();
  public acceptAllInWaitingRoom$ = this._acceptAllInWaitingRoom$.asObservable();

  private _usersRemovedFromSpace: Subject<string[]> = new Subject<string[]>();
  public usersRemovedFromSpace = this._usersRemovedFromSpace.asObservable();

  constructor(private sessionSharedDataService: SessionSharedDataService) {}

  emitWaitingRoom(spaceID: string, waitingRoomID: string) {
    this._isCurrentlyWaitingRoomActive$.next(true);
    this._waitingRoom$.next([spaceID, waitingRoomID]);
    this.sessionSharedDataService.sessionView.switchToSessionView(SessionView.WAITING_ROOM);
  }

  exitWaitingRoom() {
    this._isCurrentlyWaitingRoomActive$.next(false);
  }

  isCurrentlyWaitingRoomActive(): boolean {
    return this._isCurrentlyWaitingRoomActive$.value;
  }

  emitAcceptAllInWR() {
    this._acceptAllInWaitingRoom$.next(true);
  }

  emitUsersRemovedFromSpace(userIDs: string[]) {
    this._usersRemovedFromSpace.next(userIDs);
  }
}

import { Directive, ElementRef, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, fromEvent, tap } from 'rxjs';

@UntilDestroy()
@Directive({
  selector: '[appScrollHelper]',
})
export class ScrollHelperDirective implements OnInit {
  @Input() debounceTime = 200;
  @Output() scrolling = new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef, private zone: NgZone) {}

  private _shouldEmit = true;

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      fromEvent(this.elementRef.nativeElement, 'wheel')
        .pipe(
          untilDestroyed(this),
          tap(() => {
            if (this._shouldEmit) {
              this.emitChange(true);
              this._shouldEmit = false;
            }
          }),
          debounceTime(this.debounceTime),
        )
        .subscribe(() => {
          this.emitChange(false);
          this._shouldEmit = true;
        });
    });
  }

  emitChange(value: boolean): void {
    this.zone.run(() => {
      this.scrolling.next(value);
    });
  }
}

<div *ngIf="user">
  <div class="title-section">
    <span class="title text-capitalize">
      {{ title | translate }}
    </span>
  </div>

  <div class="revoked-section text-center">
    <div class="revoked-icon">
      <i class="material-icons icon">error_outline</i>
    </div>
    <div
      *ngIf="user.revoked?.type === REVOKED_TYPES.SUSPENDED"
      class="revoked-text justify-content-center cpx-24"
    >
      <span *ngIf="user.revoked?.institution">
        {{
          'Your account with ' + user.revoked?.institution.name + ' has been suspended.' | translate
        }}
      </span>
      <span *ngIf="!user.revoked?.institution">
        {{ 'Your account with ' + user.institution.name + ' has been suspended.' | translate }}
      </span>
    </div>
    <div
      *ngIf="user.revoked?.type === REVOKED_TYPES.DELETED"
      class="revoked-text justify-content-center cpx-24"
    >
      <span *ngIf="user.revoked?.institution">
        {{ 'Your account was removed from ' + user.revoked?.institution.name | translate }}
      </span>
      <span *ngIf="!user.revoked?.institution">
        {{ 'Your account was removed.' | translate }}
      </span>
    </div>
    <div class="note">{{ 'Contact your institution admin for details.' | translate }}</div>
    <div class="revoked-note" *ngIf="user.revoked && user.revoked.note">
      <div class="note text-left">{{ 'Note (from institution)' | translate }}</div>
      <textarea readonly>{{ user.revoked.note }}</textarea>
    </div>
  </div>

  <div class="buttons cpt-32 cpb-24">
    <button mat-flat-button class="btn-okay cmr-16" (click)="close()">
      <i class="material-icons">check</i>
      <span class="text-capitalize">{{ 'okay' | translate }}</span>
    </button>
  </div>
</div>

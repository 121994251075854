<mat-expansion-panel
  (opened)="panelOpenState = true"
  (closed)="panelOpenState = false; currentLimit = limitToShow"
  [expanded]="panelOpenState"
>
  <mat-expansion-panel-header class="filter-panel">
    <mat-panel-title class="filter-panel-header ms-2">
      <span class="filter-header">{{ filterSet.name | translate }}</span>
    </mat-panel-title>
    <mat-panel-description>
      <div *ngIf="sort" class="options-buttons">
        <span
          class="material-icons icons"
          (click)="$event.stopPropagation()"
          [class.disable-sort-button]="!panelOpenState"
          [matMenuTriggerFor]="menu"
          aria-label="Example icon-button with a menu"
        >
          sort</span
        >
        <mat-menu #menu="matMenu">
          <button mat-menu-item disabled>
            <span class="sort-header">{{ 'SORT BY' | translate }}</span>
          </button>
          <button
            *ngFor="let sort of sortTypes"
            mat-menu-item
            [class.selectedSort]="sort.name === currentSortStrategy?.name"
            (click)="$event.stopPropagation(); setSortStrategy(sort)"
          >
            <mat-icon
              [class.disabledSort]="sort.name !== currentSortStrategy?.name"
              class="done-icon"
            >
              done
            </mat-icon>
            <span class="sort-content"> {{ sort.name | translate }} </span>
          </button>
        </mat-menu>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div *ngIf="searchbar" class="search-bar">
    <app-search-bar
      *ngIf="panelOpenState"
      [searchTitle]="'Search for '.concat(filterSet.name).concat(' filters') | translate"
      [searchContinuously]="true"
      (filter)="setSearchValue($event)"
    >
    </app-search-bar>
  </div>
  <!--TODO: We need to add generic attribute instead of syllabus code, to work for any filter.-->
  <ng-select
    #dropdownSelector
    *ngIf="dropdown && !checkbox"
    appendTo="body"
    [(ngModel)]="selectedFilter"
    (change)="chooseFilter($event)"
    [clearable]="false"
    [virtualScroll]="true"
    [searchable]="false"
    [placeholder]="placeholderForDropdown | translate"
    style="width: 100%; margin-bottom: 16px"
    class="pcl-filter-dropdown-selectedFilter"
    focus
  >
    <ng-option [value]="undefined"> {{ placeholderForDropdown | translate }}</ng-option>
    <ng-option *ngFor="let filter of filteredList" [value]="filter">
      {{ filter.item.name }}</ng-option
    >
  </ng-select>
  <ng-container *ngIf="checkbox && !dropdown">
    <div
      *ngFor="let filter of filteredList | slice: 0:currentLimit"
      class="my-1 filter-list"
      #tooltip="ngbTooltip"
      [ngbTooltip]="
        (showCourses && filter.course
          ? (filter | displayFilter).concat(': \n').concat(filter.course.name)
          : (filter | displayFilter)
        ) | translate
      "
      [tooltipClass]="'filtersTooltip'"
    >
      <mat-checkbox
        [(ngModel)]="filter.value"
        class="float-left align-middle mx-2"
        (change)="chooseFilter(filter)"
      ></mat-checkbox>
      <span class="filter-panel-text filter-name">{{
        filter
          | displayFilter
          | middleTruncate: (showCourses && filter.course ? 12 : 24)
          | translate
      }}</span>
      <span class="filter-sub-name" *ngIf="showCourses && filter.course">
        {{ ' in' | translate }} {{ filter.course.name | middleTruncate: 12 }}</span
      >
    </div>
  </ng-container>
  <div *ngIf="!filteredList.length && !dropdown" class="not-found-message">
    {{ 'No matching filters found' | translate }}
  </div>
  <div *ngIf="currentLimit < filteredList.length && !dropdown">
    <span (click)="currentLimit = filteredList.length" class="link">
      {{
        'View '.concat((filteredList.length - currentLimit).toString()).concat(' more') | translate
      }}
    </span>
  </div>
</mat-expansion-panel>

<div class="social-buttons-container">
  <button
    shareButton="whatsapp"
    data-name="referral-social-whatsapp-btn"
    [url]="url"
    (click)="$event.stopPropagation()"
  >
    <svg-icon [width]="20" [height]="20" [icon]="'invite-whatsapp'"></svg-icon>
  </button>
  <button
    shareButton="linkedin"
    data-name="referral-social-linkedin-btn"
    [url]="url"
    (click)="$event.stopPropagation()"
  >
    <svg-icon [width]="20" [height]="20" [icon]="'invite-linkedin'"></svg-icon>
  </button>
  <button
    shareButton="facebook"
    data-name="referral-social-facebook-btn"
    *ngIf="!isMobile"
    [url]="url"
    (click)="$event.stopPropagation()"
  >
    <svg-icon [width]="20" [height]="20" [icon]="'invite-facebook'"></svg-icon>
  </button>
  <ng-container *ngIf="isMobile">
    <button
      style="margin-left: -4px"
      [matMenuTriggerFor]="extraSocialPlatforms"
      #socialMenuTriggerRef="matMenuTrigger"
      (click)="$event.stopPropagation()"
      class="expand-social-list"
    >
      <mat-icon [class.open-dropdown]="socialMenuTriggerRef.menuOpen">{{
        !socialMenuTriggerRef.menuOpen ? 'arrow_drop_down' : 'arrow_drop_up'
      }}</mat-icon>
    </button>
  </ng-container>
</div>
<mat-menu
  #extraSocialPlatforms="matMenu"
  yPosition="below"
  xPosition="before"
  class="social-platforms-menu"
>
  <ng-container *ngIf="isMobile">
    <button mat-menu-item class="social-platforms-menu-entry" shareButton="messenger">
      <svg-icon [width]="16" [height]="16" [icon]="'invite-messenger'"></svg-icon>
      <span>Messenger</span>
    </button>
    <button
      mat-menu-item
      class="social-platforms-menu-entry"
      shareButton="facebook"
      url="shareLink"
    >
      <svg-icon [width]="16" [height]="16" [icon]="'invite-facebook'"></svg-icon>
      <span>Facebook</span>
    </button>
    <button mat-menu-item class="social-platforms-menu-entry" shareButton="sms">
      <svg-icon [width]="16" [height]="16" [icon]="'invite-sms'"></svg-icon>
      <span>SMS</span>
    </button>

    <button mat-menu-item class="social-platforms-menu-entry" shareButton="viber">
      <svg-icon [width]="16" [height]="16" [icon]="'invite-viber'"></svg-icon>
      <span>Viber</span>
    </button>
  </ng-container>
</mat-menu>

import { AfterViewInit, Component, ElementRef, Inject, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Intercom } from 'ng-intercom';
import { filter, first, fromEvent, merge, switchMap, tap } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/dialogs/confirmation-modal/confirmation-modal.component';
import { WbDialogService } from 'src/app/services/wb-dialog.service';
import { modifiedInterval } from 'src/app/utilities/ZoneUtils';
import { User } from '../../../models/user';
import { AclService, Feature } from '../../../services/acl.service';
import { FLAGS, FlagsService } from '../../../services/flags.service';
import { ModalManagerService } from '../../../services/modal-manager.service';
import { WbRecorderYoutubeUploadComponent } from './wb-recorder-youtube-upload/wb-recorder-youtube-upload.component';

enum VideoDestination {
  LOCAL,
  YOUTUBE,
  CLOUD,
}

@Component({
  selector: 'app-wb-recorder',
  templateUrl: './wb-recorder.component.html',
  styleUrls: ['./wb-recorder.component.scss'],
})
export class WbRecorderComponent implements AfterViewInit {
  readonly VideoDestionation = VideoDestination;
  public recordingFile?: File;
  public feature = Feature;
  public savingType = VideoDestination.LOCAL;
  public youtubeUpload = false;

  // Indicates that a local copy should be downloaded in addition to uploading to the cloud
  saveLocalBackup = false;
  isGuestUser = true;
  isLoadingMetadata = true;
  @ViewChild('previewRecording') private previewVideoEl?: ElementRef<HTMLVideoElement>;

  constructor(
    public intercom: Intercom,
    private aclService: AclService,
    private flagsService: FlagsService,
    private modalManagerService: ModalManagerService,
    @Inject(MAT_DIALOG_DATA)
    public data: { file: File; user: User; title: string; reason?: string },
    @Optional() public dialogRef: MatDialogRef<WbRecorderComponent>,
    wbDialogService: WbDialogService,
    private translateService: TranslateService,
    private matDialog: MatDialog,
  ) {
    if (!this.aclService.isGuest(this.data.user)) {
      this.isGuestUser = false;
    }

    this.youtubeUpload = this.flagsService.isFlagEnabled(FLAGS.WB_UPLOAD_YOUTUBE);
    wbDialogService.pushNewDialogRef(dialogRef);
  }

  ngAfterViewInit(): void {
    if (!this.previewVideoEl) {
      return;
    }

    const objectURL = URL.createObjectURL(this.data.file);
    const vid = this.previewVideoEl.nativeElement;
    vid.src = objectURL;

    vid.preload = 'metadata';
    vid.load();

    // Poll every 3 seconds for the duration as
    // `timeupdate` does not always fire
    const durationPoll$ = modifiedInterval(3000).pipe(
      filter(() => !isNaN(vid.duration) && isFinite(vid.duration)),
      first(),
    );

    // Using the native video element doesn't show the recorded video duration before playing it -> SPAC-3414
    // Below a hacky solution to show the duration without playing the video
    const timeUpdate$ = fromEvent(vid, 'loadedmetadata').pipe(
      tap(() => (vid.currentTime = 1e101)),
      switchMap(() => fromEvent(vid, 'timeupdate')),
      first(),
    );

    // Merge the two Observables
    merge(durationPoll$, timeUpdate$)
      .pipe(first())
      .subscribe(() => {
        // Once getting the current time update, we need to reset it again to 0 to let the user plays the video normally
        vid.currentTime = 0;
        vid.controls = true;
        this.isLoadingMetadata = false;
      });
  }

  confirm(): void {
    if (this.savingType === VideoDestination.LOCAL) {
      this.saveLocally();
      this.dialogRef.close();
    } else if (this.savingType === VideoDestination.CLOUD) {
      if (this.saveLocalBackup) {
        this.saveLocally();
      }
      this.uploadToCloud();
    } else if (this.savingType === VideoDestination.YOUTUBE) {
      const YoutubeUploaderParams = {
        width: '400px',
        minHeight: '306px',
        panelClass: 'record-wb-dialog',
        data: { file: this.data.file },
      };

      if (this.saveLocalBackup) {
        this.saveLocally();
      }

      this.modalManagerService.showModal(WbRecorderYoutubeUploadComponent, YoutubeUploaderParams);
      this.dialogRef.close();
    }
  }

  saveLocally(): void {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const url = window.URL.createObjectURL(this.data.file);
    a.href = url;
    // Force adding file type extension
    let fileExt = 'webm';
    if (this.data.file.type.split('/')[1]) {
      fileExt = this.data.file.type.split('/')[1];
    }
    a.download = `${this.data.file.name}.${fileExt}`;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  uploadToCloud(): void {
    this.dialogRef.close(this.data.file);
  }

  dismiss(): void {
    this.showAreYouSureCancelSaving();
  }

  showAreYouSureCancelSaving() {
    const confirmationModalParams = {
      panelClass: 'confirmation-modal',
      data: {
        title: this.translateService.instant('Cancel saving recording?'),
        body: this.translateService.instant('Are you sure?'),
        positiveBtn: this.translateService.instant('No'),
        positiveBtnClass: 'primary',
        negativeBtn: this.translateService.instant('Yes'),
      },
      disableClose: true,
    };

    const modalOptions = {
      afterClosed: (keepTheRecord: boolean) => {
        if (!keepTheRecord) {
          this.dialogRef.close();
        }
      },
    };

    // Used MatDialog service directly as our current ModalService is supporting only showing one-by-one modal
    // Also we have currently this case for showing nested modals
    this.matDialog
      .open(ConfirmationModalComponent, confirmationModalParams)
      .afterClosed()
      .pipe(first())
      .subscribe((val) => {
        if (modalOptions?.afterClosed) {
          modalOptions.afterClosed(val);
        }
      });
  }
}

import { KeyValue } from '@angular/common';
import { set } from 'lodash-es';

import { TopicRootNode } from '../../../../models/user';
import { NUMBER_TYPES } from '../../create/create.model';

// Romanize number
// https://stackoverflow.com/a/32851198
const romanize = (num) => {
  const lookup = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };
  let roman = '';
  let i;
  for (i in lookup) {
    if (num) {
      while (num >= lookup[i]) {
        roman += i;
        num -= lookup[i];
      }
    }
  }
  return roman;
};

const sortByOrder = (topics: any) => {
  topics.sort((a: any, b: any) => (a.order < b.order ? -1 : 1));
};

export enum modes {
  edit = 'edit',
  create = 'create',
  readonly = 'readonly',
}

export interface NewNode {
  path: string;
  name: string;
  numbering: string | number;
  position?: number;
  key: string;
  newChild?: boolean;
  ancestors?: any;
  updateOnly?: boolean;
}

const romanLetters = ['i', 'v', 'x', 'l', 'c', 'd', 'm', 'I', 'V', 'X', 'C', 'D', 'M'];
const isLowercase = (char: string): boolean => char.charCodeAt(0) > 96;

export const orderByOrder =
  (a: KeyValue<string, any>, b: KeyValue<string, any>): number =>
    a.value.order < b.value.order ? -1 : (a.value.order > b.value.order ? 1 : 0);

export const getParent = (paths: string[], topics: any): any => {
  let parent = topics;

  for (let i = 1; i < paths.length; i++) {
    if (i === 1) {
      parent = parent[paths[i - 1]];
    } else {
      parent = parent.children[paths[i - 1]];
    }
  }

  return parent;
};

export const getSiblings = (paths: string[], topics: any): any => {
  let siblings;

  // Get Parent
  const parent = getParent(paths, topics);
  if (paths.length === 1) {
    // For root level items
    siblings = parent;
  } else {
    siblings = parent.children;
  }

  return siblings;
};

export const getSiblingKeyByOrder = (siblings: any, order: number): string => {
  let siblingKey = '';

  Object.entries(siblings).forEach(([key, value]: [string, any]) => {
    if (value.order === order) {
      siblingKey = key;
    }
  });

  return siblingKey;
};

export const getItemFromPath = (paths: string[], topics: any): any => {
  let selectedItem;
  // Traverse paths to get selected item
  paths.forEach((p, i) => {
    if (i === 0) {
      selectedItem = topics[p];
    } else {
      selectedItem = selectedItem.children[p];
    }
  });

  return selectedItem;
};

export const drop = (
  {
    currentIndex,
    previousIndex,
    item,
  }: {
    currentIndex: number;
    previousIndex: number;
    item: any;
  },
  siblings: any,
): any => {
  const { data: path } = item;
  const updatedSiblings = { ...siblings };
  const paths = path.split('.');
  const itemPath = paths[paths.length - 1];
  updatedSiblings[itemPath].order = currentIndex;

  if (currentIndex < previousIndex) {
    // Move others down
    Object.entries(updatedSiblings).forEach(([key, value]: [string, any]) => {
      updatedSiblings[key] = value;
      if (key !== itemPath && value.order >= currentIndex && value.order < previousIndex) {
        updatedSiblings[key].order++;
      }
    });
  } else {
    // Move others up
    Object.entries(updatedSiblings).forEach(([key, value]: [string, any]) => {
      updatedSiblings[key] = value;
      if (key !== itemPath && value.order <= currentIndex && value.order > previousIndex) {
        updatedSiblings[key].order--;
      }
    });
  }

  return updatedSiblings;
};

export class Topic {
  // Different from topic model, used only in builder UI
  name: string;
  order: number;
  collapsed: boolean;
  children: any;
  numbering: string | number;
  secondary?: boolean; // Denotes topic was added in edit mode, used in edit mode only
  position?: number; // Only used in edit mode, corresponds to actual leaf position
  key?: string;

  constructor({
    name,
    order,
    collapsed,
    children,
    numbering,
    secondary,
    position,
    key,
  }: {
    name?: string;
    order?: number;
    children?: any;
    collapsed?: boolean;
    numbering?: string | number;
    secondary?: boolean;
    position?: number;
    key?: string;
  }) {
    this.name = name || '';
    this.order = order || 0;
    this.collapsed = collapsed || false;
    this.children = children || {};
    this.numbering = numbering || '';
    this.secondary = secondary || false;
    this.position = position;
    this.key = key || '';
  }
}

const NUMBER_TYPES_TO_LEVELS = {
  [NUMBER_TYPES.NUMERICAL]: 1,
  [NUMBER_TYPES.ALPHABET_LOWERCASE]: 2,
  [NUMBER_TYPES.ROMAN_LOWERCASE]: 3,
  [NUMBER_TYPES.ALPHABET_UPPERCASE]: 4,
  [NUMBER_TYPES.ROMAN_UPPERCASE]: 5,
  [NUMBER_TYPES.BLANK]: 6
};

export const getOrderingNumber = (index: number, level: number): string | number => {
  // Styles: 1,a,i,A,I (Topics)
  const l = level < 7 ? level : level % 6;
  const recurseLetter = (num: number, caps: boolean): string => {
    const charCodeOffset = caps ? 65 : 97;
    if (num > 25) {
      const newNum = Math.floor(num / 26);
      const remainder = num % 26;
      return `${recurseLetter(newNum - 1, caps)}${String.fromCharCode(remainder + charCodeOffset)}`;
    } else {
      return String.fromCharCode(num + charCodeOffset);
    }
  };
  switch (l) {
    case 1: {
      return index + 1;
    }

    case 2: {
      return recurseLetter(index, false);
    }

    case 3: {
      const roman = romanize(index + 1);
      return roman.toLowerCase();
    }

    case 4: {
      return recurseLetter(index, true);
    }

    case 5: {
      return romanize(index + 1);
    }

    case 6: {
      return '';
    }

    default: {
      return index + 1;
    }
  }
};

export const getOrderingNumberByType = (index: number, type?: NUMBER_TYPES): string =>
  getOrderingNumber(index, NUMBER_TYPES_TO_LEVELS[type || 0]).toString();

export const getAncestorTree = (paths: string[] | null, topics: Topic[], topicRoots: TopicRootNode[]): any => {
  const ancestorTree: any = {
    name: topicRoots[0].topic_node.name,
    _id: topicRoots[0].topic_node._id,
  };

  if (paths) {
    const pathAcc = [paths[0]];
    paths.forEach((path, i) => {
      if (i === 0) {
        const prime = getItemFromPath(pathAcc, topics);
        ancestorTree.child = {
          name: prime.name,
          numbering: prime.numbering,
          _id: prime.key,
        };
      } else {
        pathAcc.push(path);
        const childSelector = pathAcc.map(() => 'child').join('.');
        const nextAncestor = getItemFromPath(pathAcc, topics);
        set(ancestorTree, childSelector, {
          name: nextAncestor.name,
          numbering: nextAncestor.numbering,
          _id: nextAncestor.key,
        });
      }
    });
  }

  return ancestorTree;
};

export const lastLeaf = (topic: Topic): any => {
  // traverse to the last leaf of a given node
  const traverse = (traverseTopic: any) => {
    const childrenArray = Object.values(traverseTopic.children);
    sortByOrder(childrenArray);
    if (!childrenArray.length) {
      return traverseTopic;
    }

    const leaf = childrenArray[childrenArray.length - 1];
    return traverse(leaf);
  };

  return traverse(topic);
};

export const updateSiblingsOrder = (
  topics: any,
  newNodes: NewNode[],
  orderThreshold: number,
  parentPaths: string[] | null,
): void => {
  Object.values(topics).forEach((child: any) => {
    if (child.order >= orderThreshold) {
      child.order++;
      child.numbering = getOrderingNumber(child.order, parentPaths ? parentPaths.length + 1 : 1);

      const newNode = newNodes.find((node) => node.key === child.key);
      if (newNode) {
        newNode.numbering = child.numbering;
      } else {
        newNodes.push({
          path: parentPaths ? `${parentPaths.join('.')}.${child.key}` : child.key,
          name: child.name,
          numbering: child.numbering,
          key: child.key,
          newChild: false,
          updateOnly: true,
        });
      }
    }
  });
};

export const getNumberType = (letter: string): NUMBER_TYPES => {
  if (romanLetters.includes(letter)) {
    return isLowercase(letter) ? NUMBER_TYPES.ROMAN_LOWERCASE : NUMBER_TYPES.ROMAN_UPPERCASE;
  }
  
  if (letter.charCodeAt(0) > 96) {
    return NUMBER_TYPES.ALPHABET_LOWERCASE;
  }
  
  if (letter.charCodeAt(0) < 58) {
    return NUMBER_TYPES.NUMERICAL;
  }
  
  if (letter) {
    return NUMBER_TYPES.ALPHABET_UPPERCASE;
  }

  if (letter === '') {
    return NUMBER_TYPES.BLANK;
  }

  return NUMBER_TYPES.ALPHABET_LOWERCASE;
};

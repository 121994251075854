import { TranslateService } from '@ngx-translate/core';

export enum panelID {
  account = 'account',
  payment = 'payment',
  language = 'language',
  permissions = 'permissions',
  advanced = 'advanced',
  sitesManagement = 'sitesManagement',
  customAttributes = 'customAttributes',
  aiAssist = 'aiAssist',
  billableUsage = 'billableUsage',
}

export interface PanelData {
  id: panelID;
  name: string;
  description: string;
}

export class SettingsConstantsTransformer {
  readonly PANELS: {
    [key: string]: PanelData;
  };

  constructor(private translateService: TranslateService) {
    this.PANELS = {
      ACCOUNT: {
        id: panelID.account,
        name: this.translateService.instant('Account'),
        description: this.translateService.instant('Adjust contact details and preferences'),
      },
      PAYMENT: {
        id: panelID.payment,
        name: this.translateService.instant('Billing & Payment'),
        description: this.translateService.instant('Choose the plan that’s best for you'),
      },
      LANGUAGE: {
        id: panelID.language,
        name: this.translateService.instant('Language & Region'),
        description: this.translateService.instant("Define your account's language and region"),
      },
      PERMISSIONS: {
        id: panelID.permissions,
        name: this.translateService.instant('Permissions'),
        description: this.translateService.instant(
          'Admin permissions for all Spaces in your institution',
        ),
      },
      ADVANCED: {
        id: panelID.advanced,
        name: this.translateService.instant('Advanced'),
        description: this.translateService.instant('Adjust preferences in Spaces'),
      },
      SITES_MANAGEMENT: {
        id: panelID.sitesManagement,
        name: this.translateService.instant('Sites'),
        description: this.translateService.instant('Manage sites in your institution'),
      },
      CUSTOM_ATTRIBUTES: {
        id: panelID.customAttributes,
        name: this.translateService.instant('Custom Attributes'),
        description: this.translateService.instant(
          'Categorizes Spaces, events and users through a custom tag',
        ),
      },
      AI_ASSIST: {
        id: panelID.aiAssist,
        name: this.translateService.instant('AI Assist'),
        description: this.translateService.instant('Enable AI features in your Institution'),
      },
      BILLABLE_USAGE: {
        id: panelID.billableUsage,
        name: this.translateService.instant('Plans & Billing'),
        description: this.translateService.instant(
          'Manage your institution’s billing details and view billing usage',
        ),
      },
    };
  }

  get providersToNames() {
    return this.PANELS;
  }
}

import {
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  NgZone,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { TooltipComponent } from 'src/app/ui/tooltip/tooltip.component';
import {
  Subscription,
  throttleTime,
  delay,
  filter,
  fromEvent,
  merge,
  tap,
  switchMap,
  Observable,
  take,
} from 'rxjs';
import { DeviceAndBrowserDetectorService } from 'src/app/services/device-and-browser-detector.service';
import { modifiedSetTimeout } from 'src/app/utilities/ZoneUtils';

export type Placement =
  | 'top-center'
  | 'bottom-center'
  | 'center-end'
  | 'top-end'
  | 'bottom-end'
  | 'center-start'
  | 'top-start'
  | 'bottom-start';

const THROTTLE_TIME = 300;
const STAY_ON_TOOLTIP_HOVER_DELAY = 500;

const eventOptions = {
  passive: true, // optimize performance by making sure to the browser that preventDefault will never get called
};

const isMouseInsideElement = (event: MouseEvent, nativeElement: HTMLElement): boolean => {
  const rect = nativeElement.getBoundingClientRect();
  return (
    event.clientX >= rect.left &&
    event.clientX <= rect.right &&
    event.clientY >= rect.top &&
    event.clientY <= rect.bottom
  );
};

const mouseMoveOut$ = (nativeElement: HTMLElement) =>
  fromEvent(document, 'mousemove', eventOptions).pipe(
    filter((event) => !isMouseInsideElement(event as MouseEvent, nativeElement)),
  );

const hideByValues = {
  visibility: {
    visible: 'visible',
    hidden: 'hidden',
  },
  display: {
    visible: 'block',
    hidden: 'none',
  },
};

@Directive({
  selector: '[appTooltip]',
  exportAs: 'appTooltip',
  standalone: true,
})
export class TooltipDirective implements OnInit, OnDestroy, OnChanges {
  private overlayRef?: OverlayRef;
  private portal?: TemplatePortal | ComponentPortal<TooltipComponent>;
  private observer?: IntersectionObserver;
  private initialPlacement?: Placement;
  private clickSubscription?: Subscription;
  private showHideSubscription?: Subscription;
  private closeTimeoutId?: NodeJS.Timer;
  private tooltipMouseEnterSubscription?: Subscription;
  private tooltipMouseLeaveSubscription?: Subscription;

  @Input() appTooltip: TemplateRef<TooltipComponent> | string = '';
  @Input('appTooltipDisabled') disabled = false;
  @Input('appTooltipOpenOnClick') openOnClick = false;
  @Input('appTooltipCloseDelay') closeDelay = 100;
  @Input('appTooltipOpenDelay') openDelay = 0;
  @Input('appTooltipPanelClass') panelClass: string | string[] | undefined;

  @Input('appTooltipPlacement') placement: Placement = 'top-center';
  @Input('appTooltipArrow') arrow?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'bottom-right'
    | 'top-right';
  @Input('appTooltipTipStyle') tipStyle = '';
  @Input('appTooltipCreateNewLine') createNewLine = false;
  @Input('appTooltipMaxWidth') maxWidth?: string | number;
  @Input('appTooltipStayWhenTooltipHover') stayWhenTooltipHover = false;
  @Input('appTooltipTheme') theme: 'black' | 'grey' | 'grey2' = 'black';
  @Input('appTooltipHideBy') hideBy: 'visibility' | 'display' = 'visibility';

  // adjust cdk-overlay position
  @Input('appTooltipOffsetX') offsetX = 0;
  @Input('appTooltipOffsetY') offsetY = 0;

  @Input() appTooltipKeepWhenLeavingViewport = false;
  @Input() appTooltipTriggerType: 'automatic' | 'manual' = 'automatic';

  @Output() appTooltipOpened = new EventEmitter();
  @Output() appTooltipClosed = new EventEmitter();

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private deviceService: DeviceAndBrowserDetectorService,
    private zone: NgZone,
  ) {}

  ngOnInit(): void {
    this.initializeTooltip();
  }

  private initializeTooltip() {
    if (!this.disabled) {
      this.createIntersectionObserver();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && !changes.disabled.firstChange) {
      if (!changes.disabled.currentValue) {
        this.createIntersectionObserver();
      } else {
        this.destroyTooltip();
      }
    }

    if (changes.appTooltip && !changes.appTooltip.firstChange) {
      this.destroyTooltip();
      this.initializeTooltip();
    }
  }

  ngOnDestroy(): void {
    this.destroyTooltip();
  }

  createIntersectionObserver() {
    // create it at initialization only
    // instead of creating it everytime the tooltip is in the viewport in the IntersectionObserver subscription
    if (this.appTooltipKeepWhenLeavingViewport) {
      this.createOverlayRef();
    }
    this.observer = new IntersectionObserver((entries) => {
      /**
       * when the element loose intersection, we detach tooltip immediately
       * and unsubscribe from mouse events
       * when the element recover intersection, we subscribe back to events
       */
      const [entry] = entries;
      if (!entry.isIntersecting) {
        // in scrollable views, when the tooltip gets destroyed and re-created frequently,
        // it is better to just hide and show the tooltip instead of destroying it and re-creating it
        // to not cause CPU spikes on scrolling
        if (this.appTooltipKeepWhenLeavingViewport) {
          this.hide();
        } else {
          this.detach();
        }
        this.unsubscribeFromShowHideEvents();
      } else {
        this.zone.runOutsideAngular(() => {
          if (this.initialPlacement !== this.placement || !this.overlayRef) {
            if (this.appTooltipKeepWhenLeavingViewport) {
              this.show();
            } else {
              this.createOverlayRef();
            }
            this.initialPlacement = this.placement;
          }

          this.handleEvents();
        });
      }
    });
    this.observer.observe(this.elementRef.nativeElement);
  }

  destroyTooltip(): void {
    this.detach();
    this.observer?.disconnect();
    this.unsubscribeFromTooltipHoverEvents();
    this.unsubscribeFromShowHideEvents();
  }

  private handleEvents(): void {
    if (this.appTooltipTriggerType === 'manual') {
      return;
    }
    /**
     * the idea here is to have only one type of listners at the moment,
     * when tooltip is hidden, we will be listening to mouseenter/touchstart
     * once an event is fired, we show tooltip and switch to hide events
     * until tooltip gets hidden then switch back to show events and so on
     */
    const show$: Observable<Event> = fromEvent(
      this.elementRef.nativeElement,
      this.platformSupportsMouseEvents ? 'mouseenter' : 'touchstart',
      eventOptions,
    ).pipe(
      tap(() => {
        if (this.disabled) {
          this.hide();
        }
      }),
      filter(() => !this.disabled),
      tap(() => {
        this.clearCloseTimeout();
      }),
      throttleTime(THROTTLE_TIME),
      filter(() => !this.isOpen),
      delay(this.openDelay),
      tap(() => {
        this.attach();
        this.show();
      }),
      switchMap(() => hide$),
    );

    const hideEvents$ = this.platformSupportsMouseEvents
      ? merge(
          fromEvent(this.elementRef.nativeElement, 'mouseleave', eventOptions),
          mouseMoveOut$(this.elementRef.nativeElement).pipe(take(1)),
        )
      : merge(
          fromEvent(this.elementRef.nativeElement, 'touchend', eventOptions),
          fromEvent(this.elementRef.nativeElement, 'touchcancel', eventOptions),
        );

    const hide$ = hideEvents$.pipe(
      delay(this.stayWhenTooltipHover ? STAY_ON_TOOLTIP_HOVER_DELAY : 0),
      filter(() => this.isOpen),
      tap(() => this.hide(this.closeDelay)),
      switchMap(() => show$),
    );

    this.showHideSubscription = (this.isOpen ? hide$ : show$).subscribe();
  }

  private clearCloseTimeout(): void {
    if (this.closeTimeoutId) {
      clearTimeout(this.closeTimeoutId);
      this.closeTimeoutId = undefined;
    }
  }

  private unsubscribeFromTooltipHoverEvents() {
    this.tooltipMouseEnterSubscription?.unsubscribe();
    this.tooltipMouseEnterSubscription = undefined;

    this.tooltipMouseLeaveSubscription?.unsubscribe();
    this.tooltipMouseLeaveSubscription = undefined;
  }

  private unsubscribeFromShowHideEvents(): void {
    this.clickSubscription?.unsubscribe();
    this.clickSubscription = undefined;

    this.showHideSubscription?.unsubscribe();
    this.showHideSubscription = undefined;
  }

  public open(): void {
    if (!this.overlayRef) {
      this.createOverlayRef();
    }
    this.attach();

    this.hide();
    this.show();
  }

  public close(): void {
    this.hide();
  }

  public toggle(): void {
    this.isOpen ? this.hide() : this.show();
  }

  private show() {
    this.display = true;

    if (this.stayWhenTooltipHover) {
      this.addTooltipHoverEvents();
    }
  }

  private hide(timeout = 0) {
    if (!this.isOpen) {
      return;
    }

    this.closeTimeoutId = modifiedSetTimeout(() => {
      this.display = false;
      this.closeTimeoutId = undefined;
    }, timeout);

    this.unsubscribeFromTooltipHoverEvents();
  }

  private detach(): void {
    this.overlayRef?.detach();
    this.overlayRef?.dispose();
    this.overlayRef = undefined;
  }

  public updatePosition(): boolean {
    if (this.overlayRef?.hasAttached()) {
      this.overlayRef.updatePosition();
      return true;
    }

    return false;
  }

  private attach(): void {
    this.zone.run(() => {
      const attached = this.updatePosition();
      if (attached) {
        return;
      }

      if (this.isString) {
        this.portal = new ComponentPortal(TooltipComponent);
        const ref = this.overlayRef?.attach<TooltipComponent>(
          this.portal,
        ) as ComponentRef<TooltipComponent>;
        ref.instance.text = this.appTooltip as string;
        ref.instance.arrow = this.arrow;
        ref.instance.tipStyle = this.tipStyle;
        ref.instance.createNewLine = this.createNewLine;
      } else {
        this.portal = new TemplatePortal(
          this.appTooltip as TemplateRef<TooltipComponent>,
          this.viewContainerRef,
        );
        this.overlayRef?.attach(this.portal);
      }
    });
  }

  private addTooltipHoverEvents() {
    if (this.appTooltipTriggerType === 'manual') {
      return;
    }
    const tooltipElement = this.overlayRef?.overlayElement as HTMLElement; // gets the HTML element of the tooltip
    this.tooltipMouseEnterSubscription = fromEvent(tooltipElement, 'mouseenter')
      .pipe(take(1))
      .subscribe(() => {
        this.unsubscribeFromShowHideEvents();
      });

    this.tooltipMouseLeaveSubscription = fromEvent(tooltipElement, 'mouseleave', eventOptions)
      .pipe(take(1))
      .subscribe(() => {
        this.handleEvents();
      });
  }

  private placementParser(): ConnectedPosition[] {
    const placement: ConnectedPosition = {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      offsetX: this.offsetX,
      offsetY: this.offsetY,
    };

    switch (this.placement) {
      case 'top-center':
        placement.originX = 'center';
        placement.originY = 'top';
        placement.overlayX = 'center';
        placement.overlayY = 'bottom';
        break;
      case 'bottom-center':
        placement.originX = 'center';
        placement.overlayX = 'center';
        break;
      case 'center-end':
        placement.originX = 'end';
        placement.originY = 'top';
        placement.overlayX = 'start';
        break;
      case 'top-end':
        placement.originX = 'end';
        placement.originY = 'top';
        placement.overlayX = 'start';
        placement.overlayY = 'bottom';
        break;
      case 'bottom-end':
        placement.originX = 'end';
        placement.overlayX = 'start';
        break;
      case 'center-start':
        placement.originY = 'center';
        placement.overlayY = 'center';
        break;
      case 'top-start':
        placement.originY = 'top';
        placement.overlayY = 'bottom';
        break;
    }

    return [placement];
  }

  private createOverlayRef(): void {
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions(this.placementParser()),
      hasBackdrop: false,
      maxWidth: this.maxWidth,
      panelClass: this.panelClass,
      disposeOnNavigation: true,
    });
  }

  private get isString(): boolean {
    return typeof this.appTooltip === 'string';
  }

  private set display(value: boolean) {
    if (!this.overlayRef?.overlayElement) {
      return;
    }

    const { visible, hidden } = hideByValues[this.hideBy];
    this.overlayRef.overlayElement.style[this.hideBy] = value ? visible : hidden;
    value ? this.appTooltipOpened.emit() : this.appTooltipClosed.emit();
  }

  public get isOpen(): boolean {
    return this.overlayRef?.overlayElement.style[this.hideBy] === hideByValues[this.hideBy].visible;
  }

  private get platformSupportsMouseEvents(): boolean {
    return Boolean(
      !this.deviceService.isAndroidPhone() &&
        !this.deviceService.isAndroidTablet() &&
        !this.deviceService.isiOSiPhone() &&
        !this.deviceService.isiOSiPad(),
    );
  }
}

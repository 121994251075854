import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDragAndDropFile]',
})
export class DragAndDropFileDirective {
  @HostBinding('class.fileOver') fileOver: boolean = false;
  @Output() fileDropped = new EventEmitter<FileList>();

  // Costly due to HostListener
  // TODO: Migrate from HostListener to our special DomListener or don't use in big components

  @HostListener('dragover', ['$event'])
  public onDragOver(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.fileOver = false;
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer?.files;
    if (files && files?.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}

<div
  class="control-row"
  [appTooltip]="options?.disableOptions?.reason"
  [appTooltipDisabled]="!options?.disableOptions?.disabled"
  [appTooltipCreateNewLine]="true"
  appTooltipPlacement="bottom-center"
  #tooltip="appTooltip"
>
  <div
    *ngIf="this.options.upgradeOptions; else toggleContent"
    appUpgradePlanBlocker
    [options]="{
      requiredSubscriptions: options.upgradeOptions?.requiredSubscriptions ?? [],
      arrow: 'top',
      offsetX: 10,
      offsetY: 5,
      placement: 'bottom'
    }"
    [upgradePlanEvaluationType]="options.upgradeOptions?.evaluationType"
    (isUpgradePlanBlockerEnabled)="handleUpgradePlanBlocker($event)"
  >
    <ng-container *ngTemplateOutlet="toggleContent"></ng-container>
  </div>
</div>

<ng-template #toggleContent>
  <div
    formGroupName="settings"
    class="control-row-container"
    [class.disabled]="options?.disableOptions?.disabled || isUpgradePlanBlockerEnabled"
  >
    <div class="control-row-content">
      <svg-icon
        *ngIf="isUpgradePlanBlockerEnabled"
        class="pro-badge cmr-4"
        [style.opacity]="'1'"
        [style.min-width]="'20px'"
        [width]="'20px'"
        [height]="'20px'"
        [icon]="'pro_badge'"
      ></svg-icon>
      <div class="row-details">
        <div class="_title">
          <span>
            {{ options.titleText }}
          </span>
        </div>
        <div
          class="_subtitle"
          *ngIf="form?.value?.settings[options.setting] && options.inactiveText"
        >
          {{ options.activeText }}
        </div>
        <div
          class="_subtitle"
          *ngIf="!form?.value?.settings[options.setting] && options.activeText"
        >
          {{ options.inactiveText }}
        </div>
      </div>
    </div>
    <div class="control-toggle">
      <mat-slide-toggle
        class="custom-mat-toggle"
        [formControlName]="options.setting"
      ></mat-slide-toggle>
    </div>
  </div>
</ng-template>

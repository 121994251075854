export function formatMillis(milliseconds: number) {
  const hours = Math.floor(milliseconds / (60 * 60 * 1000));

  milliseconds = milliseconds % (60 * 60 * 1000);
  const minutes = Math.floor(milliseconds / (60 * 1000));

  milliseconds = milliseconds % (60 * 1000);
  const seconds = Math.floor(milliseconds / 1000);

  return `${hours} hr, ${minutes} min, ${seconds} sec`;
}

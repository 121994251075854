<div class="template-container">
  <div
    class="template-box"
    [class.blank]="isBlankTemplate"
    (click)="handleTemplateClicked.emit(spaceTemplate)"
  >
    <span
      class="template-icon"
    >
      <svg-icon
        [width]="isBlankTemplate ? '48' : '32'"
        [height]="isBlankTemplate? '48' : '32'"
        [icon]="isBlankTemplate ? 'blank-add' :spaceTemplate?.icon"
      >
      </svg-icon>
    </span>

    <span class="institution-logo" *ngIf="
      currentUser?.institution?.logo
      && spaceTemplate?.institution === currentUser?.institution._id
      && !spaceTemplate?.isGeneral "
    >
      <img [src]="userService.user.getValue()?.user?.institution?.logo" [alt]="userService.user.getValue()?.user?.institution?.name" />
    </span>
  </div>
  <div class="template-body">

    <div class="template-info">
      <span
        class="name"
        [appTooltip]="nameTooltip"
        [appTooltipPlacement]="'bottom-center'"
        [appTooltipDisabled]="spaceTemplate?.name?.length < 19"
      >
        {{ isBlankTemplate? ('Blank Space' | translate) : spaceTemplate?.name }}
      </span>
      <span class="author" *ngIf="spaceTemplate?.authorName">by {{spaceTemplate?.authorName}}</span>

      <ng-template #nameTooltip>
        <app-tooltip
          [text]="spaceTemplate?.name"
        >
        </app-tooltip>
      </ng-template>
    </div>

    <!--    Action Menu-->
    <button
      mat-icon-button
      class="more-icon"
      *ngIf="!spaceTemplate?.isGeneral && !isBlankTemplate
      && currentPageView === SpacesHomePageView.ALL_TEMPLATES_VIEW"
      (click)="$event.preventDefault(); $event.stopPropagation()"
      [matMenuTriggerFor]="actionMenu"
      #actionMenuTrigger="matMenuTrigger"
      data-name="template-actions-trigger"
      [class.menu-opened]="actionMenuOpened$ | async"
      (menuOpened)="actionMenuOpened$.next(true)"
      (menuClosed)="actionMenuOpened$.next(false)"
    >
      <mat-icon>more_horiz</mat-icon>
    </button>

    <mat-menu
      #actionMenu="matMenu"
      xPosition="after"
      class="template-action-menu"
    >

      <!--      Edit Template Button-->
      <button
        mat-button
        data-name="space-template-edit-template-button"
        (click)="onEditButton()"
      >
        <svg-icon class="template-menu-item-icon" icon="edit_menu_icon" width="18" height="18">
        </svg-icon>
        <span class="template-menu-item-text">{{ 'Edit template' | translate }}</span>
      </button>

      <!--      Delete Template Button-->
      <button
        mat-button
        data-name="space-template-delete-template-button"
        class="delete_button"
        (click)="onDeleteButton()"
        (mouseover)="deleteIcon = 'delete_outline_red'"
        (mouseleave)="deleteIcon = 'delete_menu_icon'"
      >
        <svg-icon
          width="18"
          height="18"
          class="template-menu-item-icon"
          [icon]="deleteIcon"
        ></svg-icon>
        <span class="template-menu-item-text">{{ 'Remove from gallery' | translate }}</span>
      </button>
    </mat-menu>
  </div>

</div>

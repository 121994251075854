import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[appFirstElementVisible]',
})
export class FirstElementVisibleDirective implements AfterViewInit, OnChanges {
  @Input() shouldElementBeVisible = false;
  @Input() topPosition = 0;
  @Input() leftPosition = 0;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.checkPreviousSiblingsVisibility();
  }

  ngAfterViewInit(): void {
    this.checkPreviousSiblingsVisibility();
  }

  private checkPreviousSiblingsVisibility() {
    let sibling = this.el.nativeElement.previousElementSibling;

    while (sibling) {
      if (!sibling.hasAttribute('hidden')) {
        this.renderer.removeClass(this.el.nativeElement, 'first-element-visible');
        return;
      }
      sibling = sibling.previousElementSibling;
    }
    if (this.shouldElementBeVisible) {
      this.renderer.addClass(this.el.nativeElement, 'first-element-visible');
      return;
    }

    this.renderer.removeClass(this.el.nativeElement, 'first-element-visible');
  }
}

import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'button[ui-primary], button[ui-primary-outlined]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() icon?: string;
  @HostBinding('class') @Input() color!: string;
  @HostBinding('class.round') @Input() round!: boolean;
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isInSets',
})
export class IsInSetsPipe implements PipeTransform {
  transform(containers: any, set: any, all = true): any {
    const checkedItemIDs = containers
      .filter((container) => container.checked)
      .map((container) => container.item._id);
    const setItemIDs = set.items.map((item) => item._id);
    const allIncluded = checkedItemIDs.every(id => setItemIDs.includes(id));
    const someIncluded = checkedItemIDs.some(id => setItemIDs.includes(id));

    if (all) {
      return allIncluded;
    }

    return allIncluded ? false : someIncluded;
  }
}

<div class="recording-parent-container">
  <ng-container
    *ngIf="
      data.recording.streamingLink
        | embedVimeoVideo: { attr: { width: '100%', height: '100%' } }
        | async as vimeoData;
      else loadingError
    "
  >
    <ng-container *ngIf="vimeoData.status === RECORDING_STATUS.READY; else loading">
      <div
        *ngIf="data.recording.streamingLink; else fallbackVideoPlayer"
        [innerHtml]="vimeoData.innerHTML"
        class="recording-video-container"
      ></div>
    </ng-container>
    <ng-template #loading>
      <div class="recording-video-container">
        <div
          class="d-flex flex-column align-items-center justify-content-center"
          style="height: 100%"
        >
          <app-spinner></app-spinner>
          <ng-container
            *ngIf="
              vimeoData.status === RECORDING_STATUS.PROCESSING;
              then processingRecording;
              else loadingRecording
            "
          >
          </ng-container>
          <ng-template #processingRecording>
            <span class="loading-text">
              {{
                'Your recording is being processed. It will be available for viewing in a few minutes...'
                  | translate
              }}
            </span>
          </ng-template>
          <ng-template #loadingRecording>
            <span class="loading-text">
              {{ 'Loading recording...' | translate }}
            </span>
          </ng-template>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #fallbackVideoPlayer>
    <div class="recording-video-container">
      <video style="height: 100%; width: 100%" controls>
        <source [src]="data.recording.downloadLink" type="video/mp4" />
      </video>
    </div>
  </ng-template>

  <ng-template #loadingError>
    <div class="recording-video-container">
      <div
        class="d-flex flex-column align-items-center justify-content-center"
        style="height: 100%"
      >
        <app-spinner></app-spinner>
        <span class="loading-text">
          {{
            'There was an error loading your recording. Please try again in a few minutes...'
              | translate
          }}
        </span>
      </div>
    </div>
  </ng-template>

  <mat-icon class="material-icons-outlined close-icon" (click)="closeDialog()">close</mat-icon>
</div>

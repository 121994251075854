import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Site, User } from 'src/app/models/user';
import { AclService } from 'src/app/services/acl.service';

interface SelectSiteBottomSheetData {
  user: User;
}

@Component({
  selector: 'app-select-site-bottom-sheet',
  templateUrl: './select-site-bottom-sheet.component.html',
  styleUrls: ['./select-site-bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSiteBottomSheetComponent {
  form: UntypedFormGroup;
  availableSites: Site[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private aclService: AclService,
    private bottomSheetRef: MatBottomSheetRef<SelectSiteBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: SelectSiteBottomSheetData,
  ) {
    this.form = this.formBuilder.group({
      siteId: [null, Validators.required],
    });
    this.availableSites = this.aclService.getUserSites(this.data.user) ?? [];
  }

  submit() {
    this.bottomSheetRef.dismiss({ siteId: this.form.value?.siteId });
  }

  close() {
    this.bottomSheetRef.dismiss();
  }

  setSelectedSiteId(siteId: string) {
    this.form.get('siteId')?.setValue(siteId);
    this.form.get('siteId')?.updateValueAndValidity();
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import pdfjsLib from 'src/assets/pdfjs-config';
import { InvalidPDFException } from 'pdfjs-dist';
import * as Sentry from '@sentry/browser';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IconTypes } from '../../../../standalones/components/pencil-icon/pencil-icon.component';
import { UploadFileComputerService } from '../../../../services/upload-file-computer.service';
import { FileUploadError, MAX_PDF_PAGES, PDFBoardStrategy } from '../../../../models/upload';

enum UploadPDFComputerStage {
  selectPages,
  boardStrategy,
}

const TOO_MANY_PAGES_FILE_ERROR = 'TooManyPagesFile';
const COULD_NOT_LOAD_PDF_FROM_FILE = 'CouldNotLoadPdfFromFile';

@UntilDestroy()
@Component({
  selector: 'app-upload-pdf-computer',
  templateUrl: './upload-pdf-computer.component.html',
  styleUrls: ['./upload-pdf-computer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadPDFComputerComponent implements OnDestroy {
  protected selectedIndices: number[] = [];

  stage$ = new BehaviorSubject<UploadPDFComputerStage>(UploadPDFComputerStage.selectPages);
  canSplitPDF$ = new BehaviorSubject<boolean>(false);

  @Output() returnToSelectFileStage = new EventEmitter<void>();
  @Output() cancelFlow = new EventEmitter<void>();
  @Output() uploadSubmitted = new EventEmitter<void>();

  protected readonly UploadComputerStage = UploadPDFComputerStage;
  protected readonly IconTypes = IconTypes;

  constructor(public uploadFileComputerService: UploadFileComputerService) {
    this.uploadFileComputerService.rawFile$.pipe(untilDestroyed(this)).subscribe(async (file) => {
      this.stage$.next(UploadPDFComputerStage.selectPages);
      file && (await this.loadPDFAndNavigateToSelection(file));
    });
  }

  submitIndices(selectedIndices: number[]) {
    this.selectedIndices = selectedIndices;
    this.stage$.next(UploadPDFComputerStage.boardStrategy);
  }

  goBackToPreview() {
    this.stage$.next(UploadPDFComputerStage.selectPages);
  }

  private async loadPDFAndNavigateToSelection(file: File): Promise<void> {
    try {
      const fileURL = URL.createObjectURL(file);
      const pdfTpUpload = await pdfjsLib.getDocument(fileURL).promise;

      if (!pdfTpUpload) {
        throw new Error(COULD_NOT_LOAD_PDF_FROM_FILE);
      }
      if (pdfTpUpload.numPages > MAX_PDF_PAGES) {
        throw new Error(TOO_MANY_PAGES_FILE_ERROR);
      }

      this.canSplitPDF$.next(await this.uploadFileComputerService.canSplitPDFPages(pdfTpUpload));
      if (this.canSplitPDF$.getValue()) {
        this.stage$.next(UploadPDFComputerStage.selectPages);
      } else {
        this.stage$.next(UploadPDFComputerStage.boardStrategy);
      }

      this.uploadFileComputerService.pdfToUpload = pdfTpUpload;
    } catch (err) {
      let fileUploadError: FileUploadError;
      if (err instanceof InvalidPDFException) {
        fileUploadError = FileUploadError.Corrupt;
      } else if (err.message === TOO_MANY_PAGES_FILE_ERROR) {
        fileUploadError = FileUploadError.TooManyPages;
      } else {
        fileUploadError = FileUploadError.Other;
        Sentry.captureException(err);
      }

      this.returnToSelectFileStage.emit();
      this.uploadFileComputerService.fileUploadError$.next({
        fileName: file.name,
        error: fileUploadError,
      });

      this.restUploadFlow();
    }
  }

  async importPages(selectedBoardStrategy: PDFBoardStrategy) {
    try {
      const submitPagesPromise = this.uploadFileComputerService.submitImportedPages(
        selectedBoardStrategy,
        'application/pdf',
        this.selectedIndices,
        this.canSplitPDF$.getValue(),
      );
      // We exited the flow before submission just so that we close the panel before we insert the pdf
      // that's just so we can center the inserted pages correctly.
      this.exitFlow();
      await submitPagesPromise;
    } catch (err) {
      Sentry.captureException(err);
      await this.uploadFileComputerService.showFailedToProcessPDFNotification();
    }
  }

  exitFlow() {
    this.uploadSubmitted.emit();
  }

  cancelSubmit() {
    this.cancelFlow.emit();
    this.restUploadFlow();
  }

  ngOnDestroy(): void {
    this.restUploadFlow();
  }

  restUploadFlow() {
    this.selectedIndices = [];
    this.uploadFileComputerService.resetFiles();
  }
}

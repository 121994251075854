import { AnalyticsUserStat, IAnalyticsInsight, SessionStatus } from '../models/analytics';

/**
 * Converts Date object to string format, ex. Dec 15th 2022, 17:55
 * @param date
 * @returns string version of date
 */
function getDateString(date: Date | string): string {
  date = new Date(date);
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate() + getDaySuffix(date.getDate());
  const year = date.getFullYear();
  return `${month} ${day} ${year}, ${getTimeString(date)}`;
}
/**
 * Converts duration between two date objects to a string format, ex 2 hrs 15 min
 * @param startTime
 * @param endTime
 * @returns string version of duration
 */
function getSessionTime(sessionLength: number | undefined): string {
  if (sessionLength) {
    return convertMinutesDurationToString(sessionLength);
  }
  return 'N/A';
}

function getSessionLength(
  startTime: Date | string,
  endTime: Date | string | null,
): number | undefined {
  if (!endTime) {
    return undefined;
  }
  startTime = new Date(startTime);
  endTime = new Date(endTime);
  return Math.max((endTime.getTime() - startTime.getTime()) / 60000, 1);
}

/**
 * Converts callStats array to duration in minutes
 * @param callStats
 * @returns duration in minutes
 */
function getCallTime(callStats: IAnalyticsInsight['callStats']): number {
  let durationInMinutes = 0;
  callStats.forEach((call) => {
    durationInMinutes +=
      (new Date(call.callEndTime).getTime() - new Date(call.callStartTime).getTime()) / 60000;
  });
  return durationInMinutes;
}

export function getTimeString(date: Date | string): string {
  const dateObj = new Date(date);
  let hours = dateObj.getHours();
  const TT = hours >= 12 ? 'pm' : 'am';
  hours = hours > 12 ? hours - 12 : hours;
  const minutes = dateObj.getMinutes();
  const minutesPadded = minutes >= 10 ? minutes : `0${minutes}`;
  return `${hours}:${minutesPadded}${TT}`;
}

/**
 * Returns suffix of day
 * @param n day
 * @returns
 */
export function getDaySuffix(n: number): string {
  return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
}

/**
 *
 * @param durationInMinutes
 * @returns string version of duration
 */
export function convertMinutesDurationToString(durationInMinutes: number): string {
  if (durationInMinutes === undefined) {
    return 'N/A';
  }
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = Math.floor(durationInMinutes % 60);
  const minutesSuffix = minutes <= 1 ? 'min' : 'mins';
  if (hours > 0) {
    const hoursSuffix = hours > 1 ? 'hrs' : 'hr';
    return `${hours} ${hoursSuffix} ${minutes} ${minutesSuffix}`;
  } else if (minutes >= 1) {
    return `${minutes} ${minutesSuffix}`;
  } else {
    return '<1 min';
  }
}

export function parseSessionTitle(sessionTitle: string) {
  if (new Date(sessionTitle).toUTCString() === sessionTitle) {
    return getDateString(sessionTitle);
  }
  return sessionTitle;
}

export function getMaxCallTime(attendees: Array<AnalyticsUserStat>) {
  let max = 0;
  attendees.forEach((attendee) => {
    const duration = attendee.callDuration ?? 0;
    if (duration > max) {
      max = duration;
    }
  });
  return max / 60;
}

export function hasCall(session: IAnalyticsInsight) {
  if (session.callStats.length > 0) {
    return true;
  }
  for (const attendee of session.attendees) {
    if ((attendee.callDuration ?? 0) > 0) {
      return true;
    }
  }
  return false;
}

export interface IHandleEndSession {
  endedSessionId: string;
  newSessionId: string;
  newSession: IAnalyticsInsight;
  endedSession: IAnalyticsInsight;
}
export interface IAnalyticsInsightParsed extends IAnalyticsInsight {
  parsedCallLength: string;
  sessionLength?: number;
  numberOfAttendees: number;
  parsedSessionLength: string;
  parsedSessionStartTime: string;
  parsedCurrentSessionStatus: SessionStatus;
  parsedSessionTitle: string;
  callLength: number;
  parsedBillableTimeLength: string;
}

export function formatAndAugmentSessionFields(
  session: IAnalyticsInsight,
  subtractHostDuration: boolean,
): IAnalyticsInsightParsed {
  const callLength = getCallLength(session);
  const sessionLength = getSessionLength(session.sessionStartTime, session.sessionEndTime);
  const billableTimeLength =
    (session.billableSpaceTimeMinutes || 0) -
    (subtractHostDuration ? session.billableHostDiscountSpaceTimeMinutes || 0 : 0);
  return {
    ...session,
    parsedCallLength: getCallLengthString(callLength),
    numberOfAttendees: session.attendees.length,
    parsedSessionStartTime: getDateString(new Date(session.sessionStartTime)),
    parsedCurrentSessionStatus: getParsedCurrentSessionStatus(session),
    parsedSessionTitle: parseSessionTitle(session.sessionTitle),
    callLength,
    sessionLength,
    parsedSessionLength: getSessionTime(sessionLength),
    parsedBillableTimeLength: getBillableTimeString(billableTimeLength),
  };
}

function getCallLength(session: IAnalyticsInsight): number {
  if (session.callStats.length > 0) {
    return Math.max(getCallTime(session.callStats), getMaxCallTime(session.attendees));
  } else {
    return getMaxCallTime(session.attendees);
  }
}

function getBillableTimeString(billableTimeLength: number) {
  return billableTimeLength === 0 ? '' : convertMinutesDurationToString(billableTimeLength);
}

function getCallLengthString(callLength: number) {
  return callLength === 0 ? '' : convertMinutesDurationToString(callLength);
}

function getParsedCurrentSessionStatus(session: IAnalyticsInsight): SessionStatus {
  if (session.status === SessionStatus.PROCESSING) {
    return SessionStatus.PROCESSING;
  } else if (!!session.sessionEndTime === false) {
    return SessionStatus.IN_PROGRESS;
  } else {
    return SessionStatus.PROCESSED;
  }
}

export enum SpaceDataLevel {
  ROOM = 'ROOM',
  SPACE = 'SPACE',
}

import { FragmentType, TypedFragment } from '../common/typed-fragment/typed-fragment';

const truncationLength = 100; // Maximum number of characters
const numberOfLines = 3; // Maximum number of lines

export const truncateTextFragment = (typedFragment: TypedFragment): TypedFragment | null => {
  if (typedFragment.type !== FragmentType.Text) {
    return null;
  }

  const truncatedFragment = new TypedFragment();
  truncatedFragment.fragment = typedFragment.fragment;
  truncatedFragment.type = typedFragment.type as FragmentType;
  truncatedFragment.description = typedFragment.description || '';
  truncatedFragment.option_data = typedFragment.option_data || '';

  let fragmentData = typedFragment.fragment?.data || '';

  if (!fragmentData) {
    return typedFragment;
  }
  
  const newlines = fragmentData.match(/\n/g);

  // If data is list and number of lines exceed threshold,
  // Reduce number of lines and return
  if (newlines && newlines.length > numberOfLines) {
    const lines = fragmentData.split('\n');
    fragmentData = lines.splice(0, numberOfLines).join('\n').concat('...');
    truncatedFragment.fragment.data = fragmentData;
    return truncatedFragment;
  }
  
  // If data is plain text, truncate if necessary
  if (fragmentData.length <= 100) {
    return typedFragment;
  }

  fragmentData = fragmentData.substring(0, truncationLength - 3).concat('...');
  fragmentData.substring(0, truncationLength - 3).concat('...');
  truncatedFragment.fragment.data = fragmentData;

  return truncatedFragment;
};

import { trigger, state, style, transition, animate } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SUBSCRIPTION_TYPES, proFeatureSubscriptionTypes } from 'src/app/models/payment';
import { SettingsService } from 'src/app/services/settings.service';
import { panelID } from 'src/app/settings/settings.constants';
import { arraysHaveSameValues } from 'src/app/utilities/array.utils';
import { IconTypes } from 'src/app/standalones/components/pencil-icon/pencil-icon.component';
const fadeIn = trigger('fadeIn', [
  state('void', style({ opacity: 0 })), // Initial state
  transition(':enter, :leave', [animate('0.325s', style({ opacity: 1 }))]),
]);
@Component({
  selector: 'app-upgrade-plan-blocker',
  templateUrl: './upgrade-plan-blocker.component.html',
  styleUrls: ['./upgrade-plan-blocker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn],
})
export class UpgradePlanBlockerComponent {
  @Input() arrow?: 'top' | 'bottom' | 'left' | 'right';
  @Input() set requiredSubscriptions(subscriptionTypes: SUBSCRIPTION_TYPES[]) {
    if (arraysHaveSameValues(subscriptionTypes, proFeatureSubscriptionTypes)) {
      this.ctaText = [this.translate.instant('Pro'), this.translate.instant('Enterprise')];
    } else if (arraysHaveSameValues(subscriptionTypes, [SUBSCRIPTION_TYPES.ENTERPRISE])) {
      this.ctaText = [this.translate.instant('Enterprise')];
    } else {
      this.ctaText = [this.translate.instant('Pro'), this.translate.instant('Enterprise')];
    }
  }

  ctaText: string[] = [];

  constructor(private translate: TranslateService, private settingsService: SettingsService) {}

  openSettingsPayment(): void {
    this.settingsService.showSettingsPanel(panelID.payment);
  }

  protected readonly IconTypes = IconTypes;
}

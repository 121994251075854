<ng-template #UserAvatar>
  <app-lightweight-user-avatar
    [isUser]="!!user"
    [profileImage]="user?.profile_image"
    [userRole]="user?.role"
    [userEmail]="user?.email"
    [userName]="user?.name"
    [width]="width"
    [fontSize]="fontSize"
    [fontWeight]="fontWeight"
    [fontColor]="fontColor"
    [showStatus]="showStatus"
    [opaqueOverlay]="opaqueOverlay"
    [isInstitution]="!!institution"
    [institutionLogo]="institution?.logo"
    [institutionName]="institution?.name"
    [showName]="showName"
    [showYouWord]="showYouWord"
    [nameMaxWidth]="nameMaxWidth"
    [imgScale]="imgScale"
    [enableImageBorder]="enableImageBorder"
    [parentDisplay]="parentDisplay"
    [showAsChip]="showAsChip"
    [imgBorder]="imgBorder"
    [showHostTag]="showHostTag"
    (click)="!hasMenu && profileClicked()"
  >
  </app-lightweight-user-avatar>
</ng-template>

<div
  #profileMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="profileMenu"
  [style.pointer-events]="hasMenu ? 'all' : 'none'"
>
  <ng-template *ngTemplateOutlet="UserAvatar"></ng-template>
</div>

<mat-menu
  #profileMenu="matMenu"
  backdropClass="profile-menu-backdrop"
  [class]="isRTL ? 'profile-menu rtl' : 'profile-menu'"
>
  <div class="user-details">
    <div class="picture">
      <ng-template *ngTemplateOutlet="UserAvatar"></ng-template>
    </div>
    <div class="info" *ngIf="user?.name || user?.email">
      <div #name class="name" *ngIf="user?.name">
        <app-clip-text [text]="user?.name"></app-clip-text>
      </div>
      <div #email class="email" *ngIf="!user?.isAnonymous && user?.email">
        <app-clip-text [text]="user?.email"></app-clip-text>
      </div>
    </div>
  </div>
  <!-- Language selector has a mat-menu of its own, so we need a -->
  <!-- proper structure for it to work on hover -->
  <!-- Desktop Menu -->
  <app-pencil-button
    [matMenuTriggerFor]="langMenu"
    #langMenuTrigger="matMenuTrigger"
    *ngIf="!isMobile"
    (mouseover)="langMenuTrigger.openMenu()"
    class="pencil-button menu-item menu-lang-selector"
    data-name="spaces-manager-user-avatar-menu-language"
    (clickEvent)="langMenuTrigger.openMenu(); $event.stopPropagation()"
  >
    <div class="item-left">
      <span class="material-icons icon">translate</span>
      <span>{{ 'Language' | translate }}</span>
    </div>
    <div class="item-right">
      <span class="material-icons">chevron_right</span>
    </div>

    <mat-menu #langMenu="matMenu" [hasBackdrop]="false" (mouseleave)="langMenuTrigger.closeMenu()">
      <div *ngFor="let lang of langCodeMap">
        <app-pencil-button
          [selected]="langCode === lang.code"
          (clickEvent)="changeLang(lang)"
          [attr.data-name]="
            'spaces-manager-user-avatar-menu-language-selection-' + lang.originalLabel.toLowerCase()
          "
          class="pencil-button menu-item language-menu-item"
        >
          {{ lang.originalLabel }} / {{ lang.label | translate }}
          <mat-icon *ngIf="langCode === lang.code" class="check-icon">check</mat-icon>
        </app-pencil-button>
      </div>
    </mat-menu>
  </app-pencil-button>
  <app-pencil-button
    id="btn-home-avatar-menu-settings"
    (clickEvent)="openSettingsPanel()"
    *ngIf="!isMobile && !user?.isAnonymous"
    data-name="spaces-manager-user-avatar-menu-settings"
    class="pencil-button menu-item settings-menu-item"
    icon="settings"
    [iconConfig]="{ type: IconTypes.materialIcon, outlined: true }"
    [label]="'Settings' | translate"
  >
  </app-pencil-button>
  <app-pencil-button
    (clickEvent)="authService.logout()"
    *ngIf="!isMobile"
    data-name="spaces-manager-user-avatar-menu-logout"
    class="pencil-button menu-item settings-menu-item"
    icon="logout"
    [iconConfig]="{ type: IconTypes.materialIcon }"
    [label]="'Sign-out' | translate"
  >
  </app-pencil-button>

  <!-- Mobile Menu -->
  <div
    class="menu-header"
    (click)="$event.stopPropagation()"
    *ngIf="isMobile"
    data-name="spaces-manager-user-avatar-menu-mobile-options"
  >
    <span>{{ 'Options' | translate }}</span>
    <span class="material-icons" (click)="profileMenuTrigger.closeMenu()">close</span>
  </div>
  <div class="menu-body" (click)="$event.stopPropagation()" *ngIf="isMobile">
    <button
      mat-flat-button
      (click)="openSettingsPanel()"
      data-name="spaces-manager-user-avatar-menu-mobile-settings"
    >
      <span class="material-icons-outlined icon">settings</span>
      <span>{{ 'Settings' | translate }}</span>
    </button>
    <button
      mat-flat-button
      (click)="authService.logout()"
      data-name="spaces-manager-user-avatar-menu-mobile-logout"
    >
      <span class="material-icons icon">logout</span>
      <span>{{ 'Sign-out' | translate }}</span>
    </button>
  </div>
</mat-menu>

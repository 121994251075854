import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import * as moment from 'moment';
import { DomListenerFactoryService } from 'src/app/services/dom-listener-factory.service';
import { DomListener } from 'src/app/utilities/DomListener';
import { TooltipDirective } from 'src/app/standalones/directives/pncl-tooltip.directive';

enum TimeValidationError {
  DATE_FORMAT = 'format-invalid',
  TOO_EARLY = 'too-early',
}

@Component({
  selector: 'app-time-selector[value]',
  templateUrl: './time-selector.component.html',
  styleUrls: ['./time-selector.component.scss'],
})
export class TimeSelectorComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() times: string[] = [];
  @Input() after?: string;
  @Input() value!: string;
  @Output() handleSelect = new EventEmitter();
  @ViewChild('timeInput') timeInput!: ElementRef;
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  @ViewChild('timeInvalidTooltip') timeInvalidTooltip?: TooltipDirective;

  menuShown = false;
  invalidTime?: TimeValidationError;
  invalidErrorEnums = TimeValidationError;
  previousValue!: string;
  TWENTY_FOUR_HOUR_FORMAT = 'HH:mm';
  TARGET_FORMAT = 'hh:mm a';
  SUPPORTED_FORMATS = ['h:mm a', 'h:mma', 'HH:mm', 'H:mm', 'ha', 'h a', 'h', 'HH'];
  domListener: DomListener;

  constructor(
    private domListenerFactoryService: DomListenerFactoryService,
    private ngZone: NgZone,
  ) {
    this.domListener = this.domListenerFactoryService.createInstance();
    this.domListener.add(
      window,
      'keydown',
      (event: KeyboardEvent) => {
        if (event.key === 'Escape' && this.menuShown && this.menuTrigger) {
          this.ngZone.run(() => {
            this.menuTrigger.closeMenu();
            this.revertInput();
          });
          event.preventDefault();
        }
      },
      true,
    );
  }

  ngOnDestroy(): void {
    this.domListener.clear();
  }

  ngAfterViewInit(): void {
    this.cleanUpRenderStates();
    this.previousValue = this.value;
    this.timeInput.nativeElement.value = this.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && this.timeInput) {
      // Update if a new time is received
      this.timeInput.nativeElement.value = this.value;
    }
  }

  saveCurrentTime(): void {
    if (this.timeInput) {
      this.previousValue = this.timeInput.nativeElement.value;
    }
  }

  convertStringToDate(input: string): moment.Moment | undefined {
    input = input.trim();
    const date = moment(input, [this.TARGET_FORMAT, ...this.SUPPORTED_FORMATS], true);
    return date.isValid() ? date : undefined;
  }

  checkDateAfter(date: moment.Moment): boolean {
    return moment(this.after, this.TARGET_FORMAT, true).isBefore(date);
  }

  getDateAndValidityStatusFromText(input: string): {
    date: undefined | moment.Moment;
    invalidStatus: undefined | TimeValidationError;
  } {
    const date = this.convertStringToDate(input);
    const isValidTimeFormat = !!date;
    const isAfterConstraintSatisfied = !this.after || (!!date && this.checkDateAfter(date));
    let invalidStatus;
    if (!input || !isValidTimeFormat) {
      invalidStatus = TimeValidationError.DATE_FORMAT;
    } else if (!isAfterConstraintSatisfied) {
      invalidStatus = TimeValidationError.TOO_EARLY;
    }
    return { date, invalidStatus };
  }

  validateTextInput(): void {
    const status = this.getDateAndValidityStatusFromText(this.timeInput.nativeElement.value);
    this.invalidTime = status.invalidStatus;
    if (!!this.invalidTime && !this.timeInvalidTooltip?.isOpen) {
      this.timeInvalidTooltip?.open();
    }
  }

  cleanUpRenderStates() {
    if (document.activeElement === this.timeInput.nativeElement) {
      this.timeInput.nativeElement.blur();
    }
    if (!this.timeInvalidTooltip?.isOpen) {
      this.timeInvalidTooltip?.close();
    }
  }

  revertInput(): void {
    this.timeInput.nativeElement.value = this.previousValue;
    this.invalidTime = undefined;
    this.cleanUpRenderStates();
  }

  processInput(): void {
    const status = this.getDateAndValidityStatusFromText(this.timeInput.nativeElement.value);
    if (status.date && !status.invalidStatus) {
      this.select(status.date.format(this.TARGET_FORMAT));
    } else {
      this.revertInput();
    }
  }

  select(timeKey: string): void {
    this.cleanUpRenderStates();
    this.timeInput.nativeElement.value = timeKey;
    this.invalidTime = undefined;
    this.saveCurrentTime(); // Save newly selected time in buffer
    this.handleSelect.emit(timeKey);
  }
}

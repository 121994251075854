import { Socket, ManagerOptions, SocketOptions, io } from 'socket.io-client';
import * as Comlink from 'comlink';
import { ReplaySubject } from 'rxjs';
import {
  PencilSocket,
  EmitOptions,
  BrowserLifecycleEvent,
  SocketIOOptions,
  CallbackIdentifier,
  SocketManagerEvent,
} from './pencil-socket';
import { SocketIOWorker } from './web-socket-connection.worker';

// ---- Socket.io implementation ----
export class SocketIOWrapper<Ev extends string> extends PencilSocket<Ev> {
  private _socket: Socket;

  constructor(url: string, opts?: Partial<ManagerOptions & SocketOptions>) {
    super(url, opts);
    const _opts = Object.assign({}, opts);
    _opts.reconnection = true;

    this._socket = io(url, opts);
    return;
  }

  public async getSocketId(): Promise<string> {
    return this._socket.id;
  }
  public off(ev: Ev, cb: (...args: unknown[]) => void): void {
    this._socket.off(ev, cb as never);
  }

  public async connect(): Promise<void> {
    this._socket.connect();
  }
  public async disconnect(): Promise<void> {
    this._socket.disconnect();
  }
  public async connected(): Promise<boolean> {
    return this._socket.connected ?? false;
  }

  public on(ev: Ev | string, cb: (...args: unknown[]) => void): this {
    // Mangager events are listened to using socket.io.on instead of socket.on
    if (Object.values(SocketManagerEvent).includes(ev as never)) {
      this._socket.io.on(ev as never, cb as never);
    } else {
      this._socket.on(ev, cb as never);
    }
    return this;
  }

  public emit(ev: Ev, ...args: unknown[]): this {
    const options = this.clearEmitOptions();

    if (!this._socket) {
      return this;
    }

    const socket = this._applyOptions(this._socket, options);
    socket.emit(ev, ...args);

    return this;
  }
  private _applyOptions(socket: Socket, options: EmitOptions): Socket {
    let _socket = socket;
    if (options.volatile) {
      _socket = socket.volatile;
    }

    if (options.timeout) {
      _socket = socket.timeout(options.timeout);
    }
    return _socket;
  }
}

// ---- Socket.io web-worker wrapper ----

/**
 * SocketIOWorkerWrapper wraps the SocketIOWorker to mask all of the web-worker specific code
 */
export class SocketIOWorkerWrapper<Ev extends string> extends PencilSocket<Ev> {
  private readonly worker: Worker;
  private socketIOWorker?: Comlink.Remote<SocketIOWorker<Ev>>;

  private readonly _callbacks: Record<string, ((...args: any[]) => void)[] | undefined> = {};
  private readonly _bufferedOn: ReplaySubject<Ev> = new ReplaySubject();
  private readonly _bufferedEmits: ReplaySubject<{ ev: Ev; args: unknown[] }> = new ReplaySubject();

  constructor(socketUrl: string, opts?: SocketIOOptions) {
    super(socketUrl, opts);

    // Create the web worker
    this.worker = new Worker(new URL('./web-socket-connection.worker.ts', import.meta.url));

    // Listen for messages from the worker to execute callbacks
    // registered using `on(ev)`
    this.worker.onmessage = (message) => {
      const { ev, args } = message.data;
      this._callbacks[ev]?.forEach((cb) => cb(...args));
    };

    this.setupBrowserListeners();
  }

  /**
   * As Comlink works asynchronously we need to have a seperate method to setup the Comlink wrapper.
   *
   * @param url
   * @param options
   */
  public async setup(): Promise<void> {
    const remoteSocketIOWorker = Comlink.wrap<typeof SocketIOWorker>(this.worker);
    this.socketIOWorker = await new remoteSocketIOWorker(this.url, this.opts);

    // ---- Apply Buffered Messages ----
    this._bufferedEmits.subscribe(({ ev, args }) => {
      this.emit(ev, ...args);
    });

    this._bufferedOn.subscribe((ev) => this.socketIOWorker?.on(ev as never));
  }

  public async getSocketId(): Promise<string> {
    if (!this.socketIOWorker) {
      throw new Error(
        'Please wait for the socket worker to finish setup() before calling this method',
      );
    }
    return await this.socketIOWorker.socketId();
  }

  public off(ev: Ev, cb: (...args: unknown[]) => void): void {
    this._callbacks[ev] = this._callbacks[ev]?.filter((x) => x !== cb);
    return;
  }

  public async connect(): Promise<void> {
    await this.socketIOWorker?.connect();
  }
  public async disconnect(): Promise<void> {
    await this.socketIOWorker?.disconnect();
  }
  public async connected(): Promise<boolean> {
    return (await this.socketIOWorker?.connected()) ?? false;
  }

  public on(ev: Ev, cb: (...args: unknown[]) => void): this {
    // Add callback to the registered callbacks
    if (!this._callbacks[ev]) {
      this._callbacks[ev] = [cb];
    } else {
      this._callbacks[ev]?.push(cb);
    }

    // Buffer calls to on to fix race conditions
    if (!this.socketIOWorker) {
      this._bufferedOn.next(ev);
      return this;
    }

    // Tell the worker to send the event to the main thread
    this.socketIOWorker?.on(ev as never);

    return this;
  }

  public emit(ev: Ev, ...args: unknown[]): this {
    // Get the options set for emit
    const opts = this.clearEmitOptions();

    const [_args, cb] = this._parseArgs(...args);

    // Buffer messages if the worker has not been setup yet
    if (!this.socketIOWorker) {
      this._bufferedEmits.next({ ev, args });
      return this;
    }

    if (cb) {
      // Replace the callback with the CallbackIdentifer to tell the worker to
      // register an emit callback on it's end.
      // note: this is done to ensure that we don't have to searialize functions
      //       to the web worker, doing so is brittle and may break.
      this.socketIOWorker?.emit(ev, opts, ..._args, CallbackIdentifier).then((res) => {
        cb(...res);
      });
    } else {
      this.socketIOWorker?.emit(ev, opts, ...args);
    }

    return this;
  }

  /**
   * Setup listeners for browser events
   */
  private setupBrowserListeners() {
    Object.values(BrowserLifecycleEvent).forEach((event) => {
      addEventListener(event, () => {
        this.worker.postMessage({ event });
      });
    });
  }
}

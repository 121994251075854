// Description for hand raised gesture
import { Gesture, GestureName } from '../models/gesture';
import { FingerType, FingerCurl } from '../models/finger';

const handRaisedGesture = new Gesture(GestureName.HAND_RAISED);

// Add curls
handRaisedGesture.addCurl(FingerType.Thumb, FingerCurl.NoCurl);
handRaisedGesture.addCurl(FingerType.Index, FingerCurl.NoCurl);
handRaisedGesture.addCurl(FingerType.Middle, FingerCurl.NoCurl);
handRaisedGesture.addCurl(FingerType.Ring, FingerCurl.NoCurl);
handRaisedGesture.addCurl(FingerType.Pinky, FingerCurl.NoCurl);

// Add Linear Open
handRaisedGesture.addLinearOpenY(FingerType.Index, true);
handRaisedGesture.addLinearOpenY(FingerType.Middle, true);
handRaisedGesture.addLinearOpenY(FingerType.Ring, true);
handRaisedGesture.addLinearOpenY(FingerType.Pinky, true);
handRaisedGesture.addLinearOpenY(FingerType.Thumb, true);

handRaisedGesture.addLinearX(true);

export default handRaisedGesture;

import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { JoinCallPanelComponent } from '../dialogs/join-call-panel/join-call-panel.component';
import { SpaceDeviceModalComponent } from '../sessions/session/wb-video-controls/wb-video-controls-buttons/space-device-modal/space-device-modal.component';

@Injectable({
  providedIn: 'root',
})
export class WbDialogService {
  private activeWhiteBoardDialogsSubject = new BehaviorSubject<MatDialogRef<any>[]>([]);

  public pushNewDialogRef<T>(dialogRef: MatDialogRef<T>): void {
    this.activeWhiteBoardDialogsSubject.next(
      this.activeWhiteBoardDialogsSubject.getValue().concat([dialogRef]),
    );

    // pull dialog ref ones closed
    dialogRef.afterClosed().subscribe(() => {
      this.activeWhiteBoardDialogsSubject.next(
        this.activeWhiteBoardDialogsSubject.getValue().filter((ref) => ref !== dialogRef),
      );
    });
  }

  public closeAllActiveDialogs(): void {
    this.activeWhiteBoardDialogsSubject.getValue().forEach((dialogRef) => {
      dialogRef.close();
    });
  }

  get joinCallDialogRef$(): Observable<MatDialogRef<JoinCallPanelComponent> | undefined> {
    return this.activeWhiteBoardDialogsSubject
      .asObservable()
      .pipe(map((refs) => refs.find((ref) => ref.id)));
  }

  get joinCallDialogRef(): MatDialogRef<JoinCallPanelComponent> | undefined {
    return this.activeWhiteBoardDialogsSubject
      .getValue()
      .find((ref) => ref.componentInstance instanceof JoinCallPanelComponent);
  }

  getDialogRefOf<T>(component: new (...args: any[]) => T): MatDialogRef<T> | undefined {
    return this.activeWhiteBoardDialogsSubject
      .getValue()
      .find((ref) => ref.componentInstance instanceof component);
  }

  get spaceDeviceModalRef(): MatDialogRef<SpaceDeviceModalComponent> | undefined {
    return this.activeWhiteBoardDialogsSubject
      .getValue()
      .find((ref) => ref.componentInstance instanceof SpaceDeviceModalComponent);
  }

  get isDialogOpen(): boolean {
    return this.activeWhiteBoardDialogsSubject.getValue().length !== 0;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { GadgetHeaderModule } from '../gadget-header/gadget-header.module';
import { SVGIconComponentsModule } from '../svg-icon/svg-icon-components.module';
import { CalculatorComponent } from './calculator.component';

@NgModule({
  declarations: [CalculatorComponent],
  exports: [CalculatorComponent],
  imports: [
    DragDropModule,
    CommonModule,
    TranslateModule,
    GadgetHeaderModule,
    SVGIconComponentsModule,
  ],
})
export class CalculatorModule {}

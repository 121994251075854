export type HiddenPropertyType = 'hidden' | 'msHidden' | 'webkitHidden';
export type VisibilityChangePropertyType =
  | 'visibilitychange'
  | 'msvisibilitychange'
  | 'webkitvisibilitychange';

export const getBrowserVisibilityProperties = (
  document: Document & { msHidden?: boolean; webkitHidden?: boolean },
):
  | {
      hiddenPropertyName: HiddenPropertyType;
      visibilityChangePropertyName: VisibilityChangePropertyType;
    }
  | undefined => {
  if (typeof document.hidden !== 'undefined') {
    return { hiddenPropertyName: 'hidden', visibilityChangePropertyName: 'visibilitychange' };
  } else if (typeof document.msHidden !== 'undefined') {
    return { hiddenPropertyName: 'msHidden', visibilityChangePropertyName: 'msvisibilitychange' };
  } else if (typeof document.webkitHidden !== 'undefined') {
    return {
      hiddenPropertyName: 'webkitHidden',
      visibilityChangePropertyName: 'webkitvisibilitychange',
    };
  }
};

import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import {
  getStorage,
  ref,
  UploadTask,
  uploadBytesResumable,
  getDownloadURL,
  StorageError,
} from '@angular/fire/storage';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, first, firstValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { IResource, ResourceItemModel } from '../models/resource';
import { IconMessageToasterElement } from '../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { ToasterPopupStyle } from '../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { ERRORS } from '../common/utils/notification-constants';
import {
  ComputerFile,
  UPLOAD_LIMITS,
  UPLOAD_METHOD,
  UploadedFile,
} from '../dialogs/upload/upload/upload.component';
import { ISpaceUI, SpaceRepository } from '../state/space.repository';
import { FileDetails } from '../content/upload-task/upload-task.component';
import {
  ExternalFile,
  ItemData,
  UploadFile,
} from '../sessions/session/items-canvas/items-canvas.component';
import { ISession, ItemModel } from '../models/session';
import { ConfirmationModalComponent } from '../dialogs/confirmation-modal/confirmation-modal.component';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from './notification-toaster.service';

import { TelemetryService } from './telemetry.service';
import { UserService } from './user.service';
import { SpaceBoardsService } from './space-boards.service';
import { SessionSharedDataService } from './session-shared-data.service';

// Maximum allowed size upload - 25 MBs
const MAX_FILE_SIZE_ALLOWED_MB = 25;
// Maximum upload retry time is 60 secs
const MAX_UPLOAD_RETRY_TIME = 60 * 1000;

export enum UploadFailedNotificationStatus {
  SYSTEM_FAILURE = 'SYSTEM_FAILURE',
  CANNOT_CREATE_BOARDS = 'CANNOT_CREATE_BOARDS',
  SIZE_LIMIT = 'SIZE_LIMIT',
  VIMEO_UPLOAD_FAILED = 'VIMEO_UPLOAD_FAILED',
}

// OneDrive
export const ACCEPTED_ONEDRIVE_TYPES = [
  '.docx',
  '.doc',
  '.pptx',
  '.ppt',
  '.odp',
  '.odt',
  '.pdf',
  '.png',
  '.jpg',
  '.jpeg',
  '.svg',
  '.tif',
  '.tiff',
  '.mp4',
  '.webm',
  '.hwp',
  '.hwpx',
];

// Google Drive
export const SUPPORTED_GDRIVE_DOWNLOAD_MIME_TYPES = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.text',
  'application/pdf',
  'application/x-hwp',
  'application/x-hwpx',
  'image/png',
  'image/jpeg',
  'image/svg+xml',
  'image/tiff',
  'video/mp4',
  'video/webm',
];

export const SUPPORTED_GDRIVE_ADDITIONAL_DOWNLOAD_EXTENSIONS = ['.hwp', '.hwpx'];

export enum SUPPORTED_GDRIVE_TYPES {
  GDOCS = 'application/vnd.google-apps.document',
  GSLIDES = 'application/vnd.google-apps.presentation',
  GSHEETS = 'application/vnd.google-apps.spreadsheet',
}

export function checkIfValidGoogleDriveDocType(mimeType: string, name: string) {
  return (
    Object.values(SUPPORTED_GDRIVE_DOWNLOAD_MIME_TYPES).includes(mimeType) ||
    (name.includes('.') &&
      Object.values(SUPPORTED_GDRIVE_ADDITIONAL_DOWNLOAD_EXTENSIONS).includes(
        `.${name.split('.').pop()}`,
      ))
  );
}

// LibreOffice PDF Converter
export const ACCEPTED_LIBREOFFICE_MIME_TYPES = [
  'application/msword',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.text',
  'application/x-hwp',
  'application/x-hwpx',
];

export const ACCEPTED_LIBREOFFICE_EXTENSIONS = ['.hwp', '.hwpx'];

export function checkIfValidLibreOfficeType(file: File) {
  return (
    ACCEPTED_LIBREOFFICE_MIME_TYPES.some((s) => file.type.startsWith(s)) ||
    ACCEPTED_LIBREOFFICE_EXTENSIONS.some((s) => file.name.endsWith(s))
  );
}

export function resolveDocumentMimeType(file: File) {
  if (['', 'application/octet-stream'].includes(file.type)) {
    if (file.name.toLowerCase().endsWith('.hwpx')) {
      return new File([file], file.name, { type: 'application/x-hwpx' });
    } else if (file.name.toLowerCase().endsWith('.hwp')) {
      return new File([file], file.name, { type: 'application/x-hwp' });
    } else {
      return file;
    }
  } else {
    return file;
  }
}

export function getAcceptedLibreOfficePickerTypes() {
  return `${ACCEPTED_LIBREOFFICE_MIME_TYPES.concat(ACCEPTED_LIBREOFFICE_EXTENSIONS).join(', ')}`;
}

export interface GroupUploadData {
  uploadId: string;
  totalFiles: number;
  uploadedFilesCount: number;
}

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  readonly uploadTasksProgressObservables$ = new BehaviorSubject<Record<string, any>>({});
  readonly uploadedFiles$ = new BehaviorSubject<Record<string, File | UploadFile | undefined>>({});
  private readonly _pendingLeftPanelFile$ = new BehaviorSubject<File | undefined>(undefined);
  readonly pendingLeftPanelFile$ = this._pendingLeftPanelFile$.asObservable();
  readonly groupUploadProgress$ = new BehaviorSubject<Record<string, GroupUploadData>>({});

  private _fileUploadsInProgress = 0;

  uploadedFiles: Record<string, File | UploadFile | undefined> = {};
  uploadTasksCancellationFunctions: Record<string, () => any | undefined> = {};
  uploadIdsMap: Record<string, string> = {};
  uploadTasksProgressObservables: Record<string, any> = {};

  static checkFileType(file: FileDetails | File): ResourceItemModel {
    if (file.type) {
      if (file.type.startsWith('video/')) {
        return ResourceItemModel.VIDEO;
      }
      if (file.type.startsWith('image/')) {
        return ResourceItemModel.IMAGE;
      }
    }
    return ResourceItemModel.DOCUMENT;
  }

  constructor(
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private notificationToasterService: NotificationToasterService,
    private telemetry: TelemetryService,
    private userService: UserService,
    private spaceRepo: SpaceRepository,
    private spaceBoardsService: SpaceBoardsService,
    private sharedDataService: SessionSharedDataService,
    private matDialog: MatDialog,
  ) {
    this.sharedDataService.renderedItemsIds$.subscribe((renderedItems) => {
      this.completeUploadForRenderedItems(renderedItems);
    });
  }

  uploadBase64String(base64String: string, fileName: string) {
    const text = atob(base64String);
    const blob = new Blob([text], { type: 'text/plain' });
    const file = new File([blob], fileName);
    const storage = getStorage();
    const uploadRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(uploadRef, file);
    uploadTask.catch((error) => this.handleError(error));
  }

  uploadToFireStorage(
    file: File,
    onSuccess: (url: string) => void,
    onFailure?: () => void,
    appendFileNameToId = false,
    maxFileSizeInMB = MAX_FILE_SIZE_ALLOWED_MB,
  ): UploadTask | undefined {
    if (file.size > maxFileSizeInMB * 1024 * 1024) {
      this.toastrService.error(
        `${this.translateService.instant(
          'File exceeds maximum upload limit',
        )} ${maxFileSizeInMB} MBs`,
      );
      return undefined;
    }
    const id = uuid() + (appendFileNameToId ? file.name : '');

    const storage = getStorage();
    storage.maxUploadRetryTime = MAX_UPLOAD_RETRY_TIME;
    const uploadRef = ref(storage, id);

    const uploadTask = uploadBytesResumable(uploadRef, file);
    uploadTask.catch((error) => {
      this.handleError(error);
      if (onFailure) {
        onFailure();
      }
    });
    uploadTask.then(() =>
      getDownloadURL(uploadRef).then((url) => {
        if (url.includes('base64')) {
          this.telemetry.errorEvent('corrupted_image', {
            fileName: file.name,
            fileSize: file.size,
            userId: this.userService.userId,
            content_id: id,
            reason: 'firebase_url',
            sessionId: this.spaceRepo.activeSpaceId,
          });
          this.uploadBase64String(url, `corrupted_images/${id}`);
          return;
        }

        onSuccess(url);
      }),
    );
    return uploadTask;
  }

  showUploadFailedNotification(reason?: UploadFailedNotificationStatus) {
    let messageText;
    if (reason && reason === UploadFailedNotificationStatus.SYSTEM_FAILURE) {
      messageText = this.translateService.instant(
        "We weren't able to upload your file(s) due to a system issue. Please try again.",
      );
    } else if (reason && reason === UploadFailedNotificationStatus.VIMEO_UPLOAD_FAILED) {
      messageText = this.translateService.instant(
        "Sorry, we weren't able to upload your file(s). Please try again in a few moments.",
      );
    } else if (reason && reason === UploadFailedNotificationStatus.SIZE_LIMIT) {
      messageText = [
        this.translateService.instant('Cannot upload documents larger than'),
        UPLOAD_LIMITS.DOCUMENT.SIZE,
        this.translateService.instant('MB'),
      ].join(' ');
    } else {
      messageText = this.translateService.instant(
        "We weren't able to upload your file(s) due to an issue with your internet connection. Please try again.",
      );
    }

    const title = new IconMessageToasterElement(
      { icon: 'cloud_upload', size: 16 },
      this.translateService.instant('Upload failed'),
    );
    const message = new IconMessageToasterElement(undefined, messageText);
    const uploadFailedNotificationData = new NotificationDataBuilder(ERRORS.UPLOAD_FAILED)
      .type(NotificationType.ERROR)
      .style(ToasterPopupStyle.ERROR)
      .topElements([title])
      .middleElements([message])
      .bottomElements([])
      .dismissable(false)
      .timeOut(10)
      .priority(1030)
      .build();
    this.notificationToasterService.showNotification(uploadFailedNotificationData);
  }

  private handleError(error: StorageError) {
    switch (error.code) {
      case 'storage/canceled':
        break;
      default:
        this.showUploadFailedNotification();
    }
  }

  public extractFileType(f: File): ResourceItemModel {
    if (f.type.startsWith('image/')) {
      return ResourceItemModel.IMAGE;
    } else if (f.type.startsWith('video/')) {
      return ResourceItemModel.VIDEO;
    } else if (f.type.startsWith('application/pdf') || checkIfValidLibreOfficeType(f)) {
      return ResourceItemModel.DOCUMENT;
    } else {
      return ResourceItemModel.ANY;
    }
  }

  public containsMoreThanOneType(files: ExternalFile[], type: string): boolean {
    const filesOfType = files.filter((fileOrObject) => {
      const file = fileOrObject instanceof File ? fileOrObject : fileOrObject.file;
      return file.type === type;
    });

    return filesOfType.length > 1;
  }

  public AllowOneFileFromType(files: ExternalFile[], type: string): ExternalFile[] {
    let foundType = false;

    return files.filter((fileOrObject) => {
      const file = fileOrObject instanceof File ? fileOrObject : fileOrObject.file;

      if (file.type === type && !foundType) {
        foundType = true;
        return true; // Keep the first file of the specified type
      }

      return false; // Filter out other files of the same type
    });
  }

  public handleUpload(result: UploadedFile): void {
    if (!this.spaceRepo.activeSpace || !result) {
      return;
    }
    const frameUid =
      this.spaceRepo.activeSpace.selectedBoardUid ??
      this.spaceBoardsService.getActiveSpaceCurrentRoomAccessibleBoards()?.[0].uid; // default to first frame

    if (result.uploadMethod === UPLOAD_METHOD.PENCIL_LIBRARY) {
      let items = (result.files as IResource[]).map((file) => {
        if (file.url && file.url.includes('base64')) {
          this.telemetry.errorEvent('corrupted_image', {
            reason: 'pencil_cloud',
            user: this.userService.userId,
            spaceId: this.spaceRepo.activeSpaceId,
          });
          return;
        }
        const item = new ItemData(
          file._id,
          ItemModel.Resource,
          false,
          file.type,
          file.url,
          file.name,
        );
        item.sessionId = (this.spaceRepo.activeSpace as ISession & ISpaceUI)._id;
        item.frameUid = frameUid;
        return item;
      });
      items = items.filter((item) => !!item);
      this.sharedDataService.selectedItemsToInsert.next(items as ItemData[]);
    } else if (result.uploadMethod === UPLOAD_METHOD.COMPUTER) {
      const files: UploadFile[] = [];
      for (const file of result.files as ComputerFile[]) {
        files.push(
          new UploadFile(
            file.file,
            file.file.name,
            file.file.size,
            file.type,
            this.spaceRepo.activeSpace._id,
            frameUid,
          ),
        );
      }
      this.sharedDataService.uploadItem.next(files);
    } else {
      const selectedFrameUid = frameUid;
      const url = (result.files as string[])[0];
      const fileName = this.fileNameFromURL(url);
      this.sharedDataService.uploadItem.next([
        new UploadFile(
          url,
          fileName,
          0,
          ResourceItemModel.VIDEO,
          this.spaceRepo.activeSpace._id,
          selectedFrameUid,
        ),
      ]);
    }
  }

  /*
   *  Get the name which is between the last ( / ) and the ( . ) before the extension
   * */
  fileNameFromURL(url: string) {
    if (!url) {
      return '';
    }
    const splitNameBySlashes = url.split('/');
    if (!splitNameBySlashes.length) {
      return '';
    }
    const textAfterLastSlash = splitNameBySlashes[splitNameBySlashes.length - 1];
    const textBeforePeriod = textAfterLastSlash?.split('.')[0];
    if (!textBeforePeriod) {
      return url;
    }
    return textBeforePeriod;
  }

  setPendingLeftPanelFile(pendingLeftPanelFile: File | undefined) {
    this._pendingLeftPanelFile$.next(pendingLeftPanelFile);
  }

  cancelUpload(uploadId: string): void {
    if (this.uploadTasksCancellationFunctions[uploadId]) {
      this.uploadTasksCancellationFunctions[uploadId]();
    }
    this.deleteUploadTasksObjects(uploadId);
  }

  completeUploadForRenderedItems(renderedItemsIds: Set<string>): void {
    for (const itemId of renderedItemsIds) {
      if (this.uploadIdsMap[itemId]) {
        this.cancelUpload(this.uploadIdsMap[itemId]);
        delete this.uploadIdsMap[itemId];
        this.sharedDataService.removeRenderedItem(itemId);
      }
    }
  }

  onResourceCreated(
    resourceType: ResourceItemModel | undefined,
    contentId: string,
    uploadId: string,
  ) {
    if (resourceType === ResourceItemModel.VIDEO) {
      this.deleteUploadTasksObjects(uploadId);
    } else {
      this.uploadIdsMap[contentId] = uploadId;
    }
  }

  createUploadTaskObjects(
    uploadId: string,
    fileInput: File | UploadFile,
    percentageObservable: Observable<{ progress: number }>,
    cancelUpload: () => any,
  ) {
    this.uploadTasksCancellationFunctions = {
      ...this.uploadTasksCancellationFunctions,
      [uploadId]: cancelUpload,
    };
    if (this.isGroupUploadTask(fileInput)) {
      this.createGroupUploadProgressObject(fileInput);
    } else {
      this.uploadTasksProgressObservables = {
        ...this.uploadTasksProgressObservables,
        [uploadId]: percentageObservable,
      };
    }

    this.uploadedFiles = {
      ...this.uploadedFiles,
      [uploadId]: fileInput,
    };
    this.uploadTasksProgressObservables$.next(this.uploadTasksProgressObservables);
    this.uploadedFiles$.next(this.uploadedFiles);
  }

  isGroupUploadTask(fileInput: File | UploadFile): boolean {
    const uploadFileOptions = (fileInput as UploadFile)?.options;
    return uploadFileOptions?.groupUploadData !== undefined;
  }

  createGroupUploadProgressObject(fileInput) {
    const uploadFileOptions = (fileInput as UploadFile)?.options;
    if (!uploadFileOptions || !uploadFileOptions.groupUploadData) {
      return;
    }
    const groupUploadData = uploadFileOptions.groupUploadData;
    const groupUploadId = groupUploadData.uploadId;
    if (!this.groupUploadProgress$.value[groupUploadId]) {
      this.groupUploadProgress$.next({
        ...this.groupUploadProgress$.value,
        [groupUploadId]: groupUploadData,
      });
    }
  }

  deleteUploadTasksObjects(uploadId) {
    delete this.uploadTasksCancellationFunctions[uploadId];
    this.uploadTasksCancellationFunctions = { ...this.uploadTasksCancellationFunctions };

    delete this.uploadTasksProgressObservables[uploadId];
    this.uploadTasksProgressObservables = { ...this.uploadTasksProgressObservables };

    delete this.uploadedFiles[uploadId];
    this.uploadedFiles = { ...this.uploadedFiles };

    this.uploadedFiles$.next(this.uploadedFiles);
    this.uploadTasksProgressObservables$.next(this.uploadTasksProgressObservables);
  }

  // TODO: make the parameter type safe (blocked on the refactoring of items-canvas.component)
  updateGroupUploadProgress(options: any) {
    if (!options || !options.groupUploadData) {
      return;
    }

    const notificationGroup: GroupUploadData = options.groupUploadData;

    const groupProgresses = { ...this.groupUploadProgress$.getValue() };

    const groupUploadId = notificationGroup.uploadId;

    const groupProgress = groupProgresses[groupUploadId];
    if (!groupProgress) {
      return;
    }

    groupProgress.uploadedFilesCount = groupProgress.uploadedFilesCount + 1;

    if (groupProgress.uploadedFilesCount === groupProgress.totalFiles) {
      delete groupProgresses[groupUploadId];
    }

    this.groupUploadProgress$.next(groupProgresses);
  }

  incrementUploadCounter() {
    this._fileUploadsInProgress++;
  }

  decrementUploadCounter() {
    this._fileUploadsInProgress--;
    if (this._fileUploadsInProgress < 0) {
      this._fileUploadsInProgress = 0;
      console.error('_fileUploadsInProgress unexpectedly moved below zero');
    }
  }

  resetUploadingCounter() {
    this._fileUploadsInProgress = 0;
  }

  isFileUploadInProgress() {
    return this._fileUploadsInProgress !== 0;
  }

  confirmLeavingSpaceWithUploadInProgress(): Promise<boolean> {
    if (!this.isFileUploadInProgress()) {
      return Promise.resolve(true);
    }
    const modalConfig = {
      panelClass: 'confirmation-modal',
      data: {
        title: this.translateService.instant('Leave Space?'),
        body: this.translateService.instant(
          'By leaving this Space, any upload in progress may not be saved. Are you sure?',
        ),
        positiveBtn: this.translateService.instant('Leave Space'),
        positiveBtnIcon: 'arrow_back',
        negativeBtn: this.translateService.instant('Cancel'),
        negativeBtnIcon: 'close',
      },
      disableClose: true,
    };

    return firstValueFrom(
      this.matDialog.open(ConfirmationModalComponent, modalConfig).afterClosed().pipe(first()),
    );
  }
}

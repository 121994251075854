<div
  class="preview-page-item-container"
  [ngClass]="{ 'preview-page-item-container-selected': isSelected }"
  (click)="toggleSelection.emit()"
>
  <canvas appChromeCanvasBugWorkaround class="p-1" #canvas></canvas>
  <div class="page-number-box">
    <p class="page-number-box-text">{{ pageNumber }}</p>
  </div>
  <div *ngIf="isSelected" class="selected-box-container">
    <app-pencil-icon
      class="selected-box-icon"
      [name]="'check_box'"
      [type]="IconTypes.materialIcon"
    ></app-pencil-icon>
  </div>
</div>

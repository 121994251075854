import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyCheck',
  pure: false,
})
export class EmptyCheckPipe implements PipeTransform {
  transform(fragments, isPrint?: any): any {
    if (!fragments || fragments.length === 0) {
      return true;
    }
    let empty = true;
    fragments.forEach(f => {
      if (isPrint) {
        if (f.type !== 'YOUTUBE' && (f.data || f.fragment?.data)) {
          empty = false;
        }
      } else {
        if (f.data?.trim() || f.fragment?.data?.trim()) {
          empty = false;
        }
      }
    });
    return empty;
  }
}

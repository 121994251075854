import { Pipe, PipeTransform } from '@angular/core';

interface SubQues {
  extra_set: {
    type: any;
    explanation_complete: boolean;
  }[];
}
@Pipe({
  name: 'explanationCheck',
})
export class ExplanationCheckPipe implements PipeTransform {
  transform(subQuestion: SubQues, type?: any): any {
    let isExist = false;
    if (subQuestion.extra_set) {
      for (let i = 0; i < subQuestion.extra_set.length; i++) {
        if (subQuestion.extra_set[i].type === type) {
          if (subQuestion.extra_set[i].explanation_complete) {
            isExist = true;
          }
          break;
        }
      }
    }
    return isExist;
  }
}

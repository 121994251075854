<ng-container *ngIf="loading" [ngSwitch]="layoutType">
  <ng-container
    *ngSwitchCase="'image-loading'"
    [ngTemplateOutlet]="resourceTemplate"
    [ngTemplateOutletContext]="{
      text: 'Loading image...',
      icon: 'search_images',
      processing: false
    }"
  >
  </ng-container>
  <ng-container
    *ngSwitchCase="'image'"
    [ngTemplateOutlet]="resourceTemplate"
    [ngTemplateOutletContext]="{
      text: 'Loading image...',
      icon: 'search_images',
      processing: false
    }"
  >
  </ng-container>
  <ng-container
    *ngSwitchCase="'video'"
    [ngTemplateOutlet]="resourceTemplate"
    [ngTemplateOutletContext]="{
      text: 'Uploading video...',
      icon: 'cam',
      processing: false
    }"
  >
  </ng-container>
  <ng-container
    *ngSwitchCase="'file'"
    [ngTemplateOutlet]="resourceTemplate"
    [ngTemplateOutletContext]="{
      text: 'Uploading file...',
      icon: 'pdf-file',
      processing: false
    }"
  >
  </ng-container>
  <ng-container
    *ngSwitchCase="'processing'"
    [ngTemplateOutlet]="resourceTemplate"
    [ngTemplateOutletContext]="{
      text: 'Processing your video - it may take a few minutes before it is available to view.',
      icon: icon,
      processing: true
    }"
  >
  </ng-container>
  <ng-container
    *ngSwitchCase="'tests-in-progress'"
    [ngTemplateOutlet]="resourceTemplate"
    [ngTemplateOutletContext]="{
      text: 'Running tests... Please wait a moment.',
      icon: icon,
      processing: true
    }"
  >
  </ng-container>
  <ng-container
    *ngSwitchCase="'custom'"
    [ngTemplateOutlet]="resourceTemplate"
    [ngTemplateOutletContext]="customLayoutConfig ? customLayoutConfig : defaultCustomLayout"
  >
  </ng-container>
  <ng-container *ngSwitchDefault [ngTemplateOutlet]="default"> </ng-container>
</ng-container>

<ng-template #default>
  <div
    class="position-absolute account-loading"
    [ngClass]="{ 'w-100 h-100 bg-styling': !hasDarkBg }"
  >
    <div class="tips-loader-container">
      <app-spinner [size]="size" [icon]="icon"> </app-spinner>

      <div class="loading-progress-bar progress" *ngIf="progress" @fade>
        <div
          class="progress-bar bg-success progress-bar-striped progress-bar-animated"
          [style.width]="progress + '%'"
        ></div>
      </div>
      <div *ngIf="currentTip$ | async as currentTip">
        <div
          *ngIf="
            showTipsIndicator && (tipsService.tipsState$ | async)?.isEnabled && currentTip.length
          "
          class="tips-container"
        >
          <div class="tips-container-title">Did you know?</div>
          <div
            class="tips-container-text"
            *ngIf="currentTip"
            [@fadeDivChange]="currentTipCounter$ | async"
          >
            {{ currentTip$ | async }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #resourceTemplate let-text="text" let-icon="icon" let-processing="processing">
  <div class="loading-container">
    <app-spinner *ngIf="processing" [size]="size" [icon]="icon" [style]="'processing'">
    </app-spinner>
    <svg-icon *ngIf="!processing" [icon]="icon" [width]="iconSize" [height]="iconSize"></svg-icon>
    <p *ngIf="!smallContainer || !smallContainerControlEnabled" class="text">
      {{ text | translate }}
    </p>
    <ng-container *ngIf="!processing && (!smallContainer || !smallContainerControlEnabled)">
      <ng-container *ngIf="progress !== undefined; else indeterminate">
        <mat-progress-bar
          class="progress-indicator"
          [mode]="'determinate'"
          [value]="progress"
        ></mat-progress-bar>
      </ng-container>
      <ng-template #indeterminate>
        <app-pncl-progress-bar></app-pncl-progress-bar>
      </ng-template>
    </ng-container>
  </div>
</ng-template>

/* eslint prefer-const: ["error", {"ignoreReadBeforeAssign": true}] */

import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { FlagsService, FLAGS } from 'src/app/services/flags.service';

import {
  SubscriptionStatus,
  SUBSCRIPTION_TYPES,
  HubspotContactProperties,
  SubscriptionEvent,
} from 'src/app/models/payment';
import { User } from 'src/app/models/user';
import { ModalManagerService } from 'src/app/services/modal-manager.service';
import { PopUpPageType, PaymentService } from 'src/app/services/payment.service';
import { ReferralService } from 'src/app/services/referral.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UserService } from '../../../services/user.service';
import { SettingsConstantsTransformer } from '../../settings.constants';

@UntilDestroy()
@Component({
  selector: 'app-user-settings-payment',
  templateUrl: './user-settings-payment.component.html',
  styleUrls: ['./user-settings-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsPaymentComponent {
  @Input() currentBilling!: SUBSCRIPTION_TYPES;
  @Input() isMobile = false;
  @Input() PANELS?: SettingsConstantsTransformer['PANELS'];
  @Output() goBack = new EventEmitter<any>();
  @Output() closePanel = new EventEmitter();

  BillingStatus = SUBSCRIPTION_TYPES;
  remainingBillingDays = -1;
  remainingFreeDays = -1;
  remainingSubscriptionDays = 5;
  billingStatus = SubscriptionStatus.INACTIVE;
  SubscriptionStatus = SubscriptionStatus;
  userID = '';
  user?: User;
  height = 550;
  width = 500;
  loading = false;
  referralAccepted: boolean | undefined;
  isViewingAnnualPlan: boolean = false;
  isUserOnAnnualPlan?: boolean;
  monthlyPlanID?: string;
  annualPlanID?: string;

  constructor(
    public referralService: ReferralService,
    private userService: UserService,
    private paymentService: PaymentService,
    private changeDetectorRef: ChangeDetectorRef,
    public modalManagerService: ModalManagerService,
    private flagsService: FlagsService,
  ) {
    if (this.flagsService.isFlagEnabled(FLAGS.ENABLE_BILLING)) {
      this.monthlyPlanID = this.flagsService.featureFlagsVariables.enable_billing
        .monthly_plan_id as string;
      this.annualPlanID = this.flagsService.featureFlagsVariables.enable_billing
        .annual_plan_id as string;
    }
    this.referralService.upgradedReferrals.pipe(untilDestroyed(this)).subscribe((referrals) => {
      this.referralAccepted = !!referrals?.length;
      this.changeDetectorRef.markForCheck();
    });
    this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
      this.user = res?.user;
      if (res?.user?.billingStatus?.subscription_status) {
        this.billingStatus = res?.user?.billingStatus?.subscription_status;
      }
      if (res?.user?.subscriptionType === SUBSCRIPTION_TYPES.PAID) {
        const { user } = res;
        this.userID = user._id;
        const { subscription } = user.billingStatus || {};

        if (
          subscription?.subscriptionEvents?.[0]?.eventType ===
          SubscriptionEvent.subscription_updated_manually
        ) {
          this.goBack.emit();
        }

        if (subscription?.nextBillingAt) {
          this.remainingBillingDays = moment(subscription.nextBillingAt * 1000).diff(
            Date.now(),
            'd',
          );
          this.isUserOnAnnualPlan = subscription.plan === this.annualPlanID;
          this.changeDetectorRef.detectChanges();
        }
      }
      if (res?.user?.billingStatus?.forceBillDate) {
        this.remainingFreeDays = moment(res?.user?.billingStatus?.forceBillDate).diff(
          Date.now(),
          'd',
        );
      }
      this.paymentService
        .updateHubspotContactProperties(HubspotContactProperties.APP_PLANS_LAST_VISIT)
        .pipe(untilDestroyed(this))
        .subscribe();
    });
  }

  createPortalSession(): void {
    this.paymentService.openBufferWindow('Management Portal', this.width, this.height);
    const planID = this.isUserOnAnnualPlan ? this.annualPlanID : this.monthlyPlanID;
    this.paymentService.HandlePortalSession(this.userID, planID as string);
  }

  async startCheckout(): Promise<void> {
    this.loading = true;
    this.paymentService.openBufferWindow(
      `${PopUpPageType.CreateSubscribtion} session`,
      this.width,
      this.height,
    );
    const planID = this.isViewingAnnualPlan ? this.annualPlanID : this.monthlyPlanID;
    await this.paymentService.handleCheckout(PopUpPageType.CreateSubscribtion, planID as string);
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }
  async SwitchPlan(): Promise<void> {
    this.loading = true;
    this.paymentService.openBufferWindow(
      `${PopUpPageType.SwitchSubscription} session`,
      this.width,
      this.height,
    );
    const planID = this.isViewingAnnualPlan ? this.annualPlanID : this.monthlyPlanID;
    await this.paymentService.handleCheckout(PopUpPageType.SwitchSubscription, planID as string);
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  openContactSales() {
    window.open(
      'https://share.hsforms.com/1sKUHxugoQ3y-223g_6ahow5cl2y',
      '_blank',
      'noreferrer noopener',
    );
  }

  handleChange(event: MatSlideToggleChange) {
    this.isViewingAnnualPlan = !event.checked;
  }
}

import { Injectable } from '@angular/core';
import { filterNil } from '@ngneat/elf';
import { Capability } from '../models/capability';
import { SpaceRepository } from '../state/space.repository';
import { Institution } from '../models/user';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class CapabilityTierService {
  private enabledCapabilities: Set<Capability> = new Set();

  constructor(private spaceRepo: SpaceRepository, private userService: UserService) {
    this.listenToActiveSpace();
    this.listenToUser();
  }

  private listenToActiveSpace(): void {
    this.spaceRepo.activeSpaceId$.pipe(filterNil()).subscribe(() => {
      this.updatePencilFilesCapability(this.spaceRepo?.activeSpace?.institution);
    });
  }

  private listenToUser(): void {
    this.userService.user.pipe(filterNil()).subscribe((userData) => {
      /* Dev Comment:
       * We only update the Pencil Files capability based on user data
       * when we're not inside an active space. This ensures that the
       * space-specific settings take precedence when a space is active.
       */
      if (!this.spaceRepo.activeSpace) {
        this.updatePencilFilesCapability(userData.user.institution);
      }
    });
  }

  private updatePencilFilesCapability(institution: Institution | null | undefined): void {
    /* Dev Comment:
     * Pencil Files Capability is DISABLED when:
     * 1. Institution is null or undefined
     * 2. Settings exist and disablePencilFiles is explicitly set to true
     *
     * Pencil Files Capability is ENABLED when:
     * 1. Institution exists and settings are undefined
     * 2. Settings exist and disablePencilFiles is undefined, null, or false
     */

    if (!institution || institution?.settings?.disablePencilFiles === true) {
      this.disableCapability(Capability.PENCIL_FILES);
    } else {
      this.enableCapability(Capability.PENCIL_FILES);
    }
  }

  isCapabilityEnabled(capability: Capability): boolean {
    return this.enabledCapabilities.has(capability);
  }

  enableCapability(capability: Capability): void {
    this.enabledCapabilities.add(capability);
  }

  disableCapability(capability: Capability): void {
    this.enabledCapabilities.delete(capability);
  }
}

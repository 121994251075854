import { ADT, matchI } from 'ts-adt';
import { SpaceAuth } from '../../auth/models.ts/models';
import { BaseADT, ResponseADT } from '../../utils/adt_utils/adt_utils';

export type Event = 'Subscribe' | 'Unsubscribe' | 'Event';

export type BaseEvent = { scope: Scope };

export type Scope = ADT<{
  SpaceUser: {
    spaceId: string;
    userId: string;
    auth?: SpaceAuth;
  };
  SpaceFrame: {
    spaceId: string;
    frameUID: string;
    auth?: SpaceAuth;
  };
  Mario: {
    spaceId: string; // uid of the space
    marioId: string; // uid of the mario instance
    eventId: string; // uid for a type of event
    auth?: SpaceAuth;
  };
}>;

export type Request = BaseADT<
  BaseEvent,
  {
    Subscribe: {};
    Unsubscribe: {};
    Event: {
      data: unknown;
    };
  }
>;

export type Response = BaseADT<
  BaseEvent,
  {
    Subscribe: {
      Response: ResponseADT<{}, { msg: string }>;
    };
    Unsubscribe: {
      Response: ResponseADT<{}, { msg: string }>;
    };
    Event: {
      data: unknown;
    };
  }
>;

export const _getRoomNameForScope = (scope: Scope): string => {
  return matchI(scope)({
    SpaceUser: (spaceUser) => `_${spaceUser.spaceId}-${spaceUser.userId}`,
    SpaceFrame: (spaceFrame) => `_${spaceFrame.spaceId}-${spaceFrame.frameUID}`,
    Mario: (mario) => `_${mario.spaceId}-${mario.marioId}-${mario.eventId}`
  });
};

export const EventName = 'EventProvider';

import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Mutex } from 'async-mutex';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TelemetryService } from 'src/app/services/telemetry.service';
import { ERRORS } from '../common/utils/notification-constants';
import { ButtonToasterElement } from '../ui/notification-toaster/button-toaster-element/button-toaster-element.component';
import {
  CustomNotificationToastrComponent,
  ToasterPopupStyle,
} from '../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import {
  IconBackground,
  IconMessageToasterElement,
} from '../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { Icon } from '../ui/notification-toaster/toaster-icon/toaster-icon.component';

const TOAST_BASE_CLASS = 'notification-popup';
interface OptionsButtonState {
  items: {
    text: string;
    onClick: () => void;
  }[];
}

export interface TopRightOptions extends OptionsButtonState {
  icon: string;
}

export interface BottomElementOptions extends OptionsButtonState {
  buttonText: string;
  menuTitle: string;
  onPrimaryButtonClick: () => void;
}

export const DEFAULT_NOTIFICAION_TIMEOUT = 7.5;

export interface NotificationData {
  style?: ToasterPopupStyle;
  topElements: [IconMessageToasterElement];
  topRightOptions?: TopRightOptions;
  middleElements: IconMessageToasterElement[];
  bottomElements: ButtonToasterElement[];
  bottomElementsStacked?: boolean;
  bottomElementButtonOptions?: BottomElementOptions;
  timeout?: number;
  width?: number;
  height?: string;
  toastClass?: string;
  dismissible?: boolean;
  priority?: number;
  showOnMobile?: boolean;
  type?: NotificationType;
  unique?: boolean;
  notificationCode: string;
  showProgressBar: boolean;
  progressBarColor?: string;
  version2Notification?: boolean;
  extendedTimeOut?: number;
  onActivateTick?: boolean;
}

export class NotificationDataBuilder {
  private readonly _notificationData: NotificationData;
  constructor(notificationCode: string) {
    const defaultTitleElement = new IconMessageToasterElement(undefined, 'Default title');

    // default notification data
    this._notificationData = {
      style: ToasterPopupStyle.INFO,
      topElements: [defaultTitleElement],
      topRightOptions: undefined,
      middleElements: [],
      bottomElements: [],
      timeout: undefined,
      width: 235,
      height: 'fit-content',
      dismissible: true,
      priority: 0,
      showOnMobile: true,
      toastClass: TOAST_BASE_CLASS,
      type: NotificationType.INFO,
      unique: false,
      notificationCode: notificationCode,
      showProgressBar: true,
      progressBarColor: undefined, // The defualt value is being handeled inside the build method for each notification version
      version2Notification: false,
      extendedTimeOut: 1000,
      onActivateTick: false,
    };
  }

  build() {
    if (this._notificationData.progressBarColor === undefined) {
      this._notificationData.progressBarColor = this._notificationData.version2Notification
        ? '#BBD8FF'
        : '#000000';
    }

    if (!this._notificationData.timeout) {
      this._notificationData.showProgressBar = false;
    }

    return this._notificationData;
  }

  /**
   * Sets notification style
   * @param style ToasterPopupStyle
   * @returns NotificationDataBuilder
   */
  style(style: ToasterPopupStyle): NotificationDataBuilder {
    this._notificationData.style = style;
    return this;
  }

  /**
   * Sets the top elements array of the notification
   * @param topElements [IconMessageToasterElement]
   * @returns NotificationDataBuilder
   */
  topElements(topElements: [IconMessageToasterElement]): NotificationDataBuilder {
    this._notificationData.topElements = topElements;
    return this;
  }

  /**
   * Sets the top right elements in the dropdown menu of the notification
   * @param topRightOptions Option[]
   * @returns NotificationDataBuilder
   */
  topRightOptions(topRightOptions: TopRightOptions): NotificationDataBuilder {
    this._notificationData.topRightOptions = topRightOptions;
    return this;
  }

  /**
   * Sets the middle elements array of the notification
   * @param middleElements [IconMessageToasterElement]
   * @returns NotificationDataBuilder
   */
  middleElements(middleElements: IconMessageToasterElement[]): NotificationDataBuilder {
    this._notificationData.middleElements = middleElements;
    return this;
  }

  /**
   * Sets the bottom elements (buttons) array of the notification
   * @param bottomElements ButtonToasterElement[]
   * @param bottomElementsStacked boolean (is we want the element to be at the top of each others rather than beside)
   * @returns NotificationDataBuilder
   */
  bottomElements(
    bottomElements: ButtonToasterElement[],
    bottomElementsStacked: boolean = false,
  ): NotificationDataBuilder {
    this._notificationData.bottomElements = bottomElements;
    this._notificationData.bottomElementsStacked = bottomElementsStacked;
    return this;
  }

  /**
   * Sets the button in the bottom elements with a dropdown menu in the notification
   * @param bottomElementOptions BottomElementOptions (string, Option[])
   * @returns NotificationDataBuilder
   */
  bottomElementOptionsButton(bottomElementOptions: BottomElementOptions): NotificationDataBuilder {
    this._notificationData.bottomElementButtonOptions = bottomElementOptions;
    return this;
  }

  /**
   * Sets the timeout for notification to live in seconds
   * @param number timeOut number of seconds
   * @returns NotificationDataBuilder
   */
  timeOut(timeOut: number): NotificationDataBuilder {
    this._notificationData.timeout = timeOut * 1000;
    return this;
  }

  /**
   * Sets the width of notification.
   * @param width number. Default is 229
   * @returns NotificationDataBuilder
   */
  width(width: number): NotificationDataBuilder {
    this._notificationData.width = width;
    return this;
  }

  /**
   * Sets the height style of notification might be style values or 'number.px'.
   * @param height string. Default is fit-content
   * @returns NotificationDataBuilder
   */
  height(height: string): NotificationDataBuilder {
    this._notificationData.height = height;
    return this;
  }

  /**
   * Sets the style classes of toastr.
   * @param toastClass string. Default is ""
   * @returns NotificationDataBuilder
   */
  toastClass(toastClass: string): NotificationDataBuilder {
    this._notificationData.toastClass = `${TOAST_BASE_CLASS} ${toastClass}`;
    return this;
  }

  /**
   * Whether the notification has a progressbar or not.
   * @param showProgressBar boolean. Default is true
   * @returns NotificationDataBuilder
   */
  showProgressBar(showProgressBar: boolean): NotificationDataBuilder {
    this._notificationData.showProgressBar = showProgressBar;
    return this;
  }
  /**
   * Determine progress bar color.
   * @param progressBarColor string. Default is #000000
   * @returns NotificationDataBuilder
   */
  progressBarColor(progressBarColor: string): NotificationDataBuilder {
    this._notificationData.progressBarColor = progressBarColor;
    return this;
  }

  /**
   * Determine if we should apply the V2 styling or not.
   * @param version2Notification boolean. Default is false
   * @returns NotificationDataBuilder
   */
  version2Notification(version2Notification: boolean): NotificationDataBuilder {
    this._notificationData.version2Notification = version2Notification;
    return this;
  }

  /**
   * Whether the notification is dismissable or not
   * @param dismissable boolean. Default is true
   * @returns NotificationDataBuilder
   */
  dismissable(dismissable: boolean): NotificationDataBuilder {
    this._notificationData.dismissible = dismissable;
    return this;
  }

  /**
   * Sets the priority of the notification. Higher priority is shown on top of the stack
   * @param priority number. Default is 0
   * @returns NotificationDataBuilder
   */
  priority(priority: number): NotificationDataBuilder {
    this._notificationData.priority = priority;
    return this;
  }

  /**
   * Whether to show the notification on mobile or not (Not ready yet)
   * @param showOnMobile boolean. Default is true
   * @returns NotificationDataBuilder
   */
  showOnMobile(showOnMobile: boolean): NotificationDataBuilder {
    this._notificationData.showOnMobile = showOnMobile;
    return this;
  }

  /**
   * Sets the type of notification (ERROR, REQUEST, WARNING, SUCCESS or INFO)
   * @param type NotificationType
   * @returns NotificationDataBuilder
   */
  type(type: NotificationType): NotificationDataBuilder {
    this._notificationData.type = type;
    return this;
  }

  /**
   * Whether to show more notification of the same type or not (globally set for now)
   * @param unique boolean.
   * @returns NotificationDataBuilder
   */
  unique(unique: boolean): NotificationDataBuilder {
    this._notificationData.unique = unique;
    return this;
  }

  /**
   * Sets extended timeout (duration notification will stay active after user hovers on it)
   * @param extendedTimeOutInSeconds
   * @returns NotificationDataBuilder
   */
  extendedTimeout(extendedTimeOutInSeconds: number): NotificationDataBuilder {
    this._notificationData.extendedTimeOut = extendedTimeOutInSeconds * 1000;
    return this;
  }

  /**
   * Determines the need to run inside angular's zone then builds the toast
   * @param onActivateTick
   * @returns NotificationDataBuilder
   */
  onActivateTick(onActivateTick: boolean): NotificationDataBuilder {
    this._notificationData.onActivateTick = onActivateTick;
    return this;
  }
}

export enum NotificationType {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
  REQUEST = 'request',
}

export interface NotificationDataElementOption {
  text?: string;
  icon?: {
    icon: string;
    size: number;
  };
}

export interface NotificationDataOptions {
  topElement?: NotificationDataElementOption[];
  middleElement?: NotificationDataElementOption[];
  bottomElements?: NotificationDataElementOption[];
  type: NotificationType;
  style: ToasterPopupStyle;
  dismissable?: boolean;
  timeOut?: number;
  priority?: number;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationToasterService {
  private notificationCategoriesMap: Map<string, Set<number>>;
  private currentNotifications: Map<number, NotificationData>;
  private pendingShowNotificationIds: Map<string, Set<number>>;
  private pendingDismissCodes: Set<string>;
  private sourceURL?: string;
  private destinationURL?: string;
  private routerSubscription: Subscription;
  private showNotificationMutex = new Mutex();
  private loadingNotificationCounter: any = {};

  private readonly LOADING_NOTIFICATION_TIMOUT_IN_SECONDS = 15;

  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private telemetry: TelemetryService,
  ) {
    this.notificationCategoriesMap = new Map();
    this.currentNotifications = new Map();
    this.pendingShowNotificationIds = new Map<string, Set<number>>();
    this.pendingDismissCodes = new Set<string>();
    this.sourceURL = this.router.url;
    this.destinationURL = this.router.url;
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.sourceURL = this.router.url;
      }

      if (event instanceof NavigationEnd) {
        this.destinationURL = this.router.url;
      }
      if (this.routeChanged()) {
        this.dismissAllNotifications();
      }
    });
  }

  private routeChanged(): boolean {
    // handling the copy/paste url case
    if (this.sourceURL === '/') {
      return false;
    }
    return this.sourceURL?.split('/')[1] != this.destinationURL?.split('/')[1];
  }

  public getActiveNotificationsCount() {
    return this.currentNotifications.size;
  }

  public async showNotification(notificationData: NotificationData, onHidden?: () => void) {
    await this.showNotificationMutex.runExclusive(() => {
      this.telemetryLogger(notificationData);
      let toast: ActiveToast<any>;
      const notificationDataStack: NotificationData[] = [];

      if (this.isDuplicateNotification(notificationData)) {
        return;
      }
      // pop lower priority notifications
      if (this.toastrService.toasts.length > 0) {
        let toastIndex = this.toastrService.toasts.length - 1;
        while (
          toastIndex >= 0 &&
          this.currentNotifications &&
          this.currentNotifications.get(this.toastrService.toasts[toastIndex].toastId) &&
          this.currentNotifications.get(this.toastrService.toasts[toastIndex].toastId)!.priority &&
          notificationData.priority &&
          this.currentNotifications.get(this.toastrService.toasts[toastIndex].toastId)!.priority! >
            notificationData.priority
        ) {
          notificationDataStack.push(
            this.currentNotifications.get(this.toastrService.toasts[toastIndex].toastId)!,
          );
          this.toastrService.remove(this.toastrService.toasts[toastIndex].toastId);
          toastIndex--;
        }
      }

      switch (notificationData.type) {
        case NotificationType.ERROR:
          toast = this.showErrorNotification(notificationData);
          break;
        case NotificationType.WARNING:
          toast = this.showWarningNotification(notificationData);
          break;
        case NotificationType.INFO:
          toast = this.showInfoNotification(notificationData);
          break;
        case NotificationType.SUCCESS:
          toast = this.showSuccessNotification(notificationData);
          break;
        case NotificationType.REQUEST:
          toast = this.showRequestNotification(notificationData);
          break;
        default:
          toast = this.showInfoNotification(notificationData);
      }
      if (!this.pendingShowNotificationIds.has(notificationData.notificationCode)) {
        this.pendingShowNotificationIds.set(notificationData.notificationCode, new Set());
      }
      this.pendingShowNotificationIds.get(notificationData.notificationCode)?.add(toast.toastId);
      toast.onShown.subscribe(() => {
        // handle dimissing notifications that were not shown yet
        this.pendingShowNotificationIds
          .get(notificationData.notificationCode)
          ?.delete(toast.toastId);
        let notificationItems = this.notificationCategoriesMap.get(
          notificationData.notificationCode,
        );
        if (this.pendingDismissCodes.has(notificationData.notificationCode) && notificationItems) {
          this.pendingDismissCodes.delete(notificationData.notificationCode);
          notificationItems.forEach((item) => this.toastrService.remove(item));
          this.notificationCategoriesMap.set(notificationData.notificationCode, new Set<number>());
          notificationItems.clear();
        }
        // manage notifications state
        if (notificationItems) {
          notificationItems.add(toast.toastId);
        } else {
          notificationItems = new Set<number>();
          notificationItems.add(toast.toastId);
        }
        this.notificationCategoriesMap.set(notificationData.notificationCode, notificationItems);
        this.currentNotifications.set(toast.toastId, notificationData);
      });
      const s = toast.onHidden.subscribe(() => {
        this.currentNotifications.delete(toast.toastId);
        const notificationItems = this.notificationCategoriesMap.get(
          notificationData.notificationCode,
        );
        if (notificationItems) {
          notificationItems.delete(toast.toastId);
          if (notificationItems.size == 0) {
            this.notificationCategoriesMap.delete(notificationData.notificationCode);
          }
        }
        if (onHidden) {
          onHidden();
        }
        s.unsubscribe(); // unsubscribe when the toast is gone
      });

      // redisplay the popped notifications
      while (notificationDataStack.length > 0) {
        this.showNotification(notificationDataStack.pop()!);
      }
    });
  }

  private showErrorNotification(notificationData: NotificationData): ActiveToast<any> {
    if (!notificationData.topElements[0].icon && !notificationData.topElements[0].spinner) {
      notificationData.topElements[0].icon = { icon: 'warning', size: 16 };
    }
    const toast = this.toastrService.error(
      notificationData.middleElements[0].msg,
      `ERROR:${notificationData.topElements[0].msg}`,
      {
        tapToDismiss: false,
        disableTimeOut: !notificationData.timeout,
        timeOut: notificationData.timeout,
        payload: notificationData,
        toastComponent: CustomNotificationToastrComponent,
        progressBar: false,
        progressAnimation: 'increasing',
        toastClass: `${TOAST_BASE_CLASS} ${notificationData.toastClass}`,
        onActivateTick: notificationData.onActivateTick,
      },
    );
    return toast;
  }

  private showWarningNotification(notificationData: NotificationData) {
    if (!notificationData.topElements[0].icon && !notificationData.topElements[0].spinner) {
      notificationData.topElements[0].icon = { icon: 'warning', size: 16 };
    }
    const toast = this.toastrService.warning(
      notificationData.middleElements[0]?.msg ?? '',
      `WARNING:${notificationData.topElements[0].msg}`,
      {
        tapToDismiss: false,
        disableTimeOut: !notificationData.timeout,
        timeOut: notificationData.timeout,
        payload: notificationData,
        toastComponent: CustomNotificationToastrComponent,
        progressBar: false,
        progressAnimation: 'increasing',
        toastClass: `${TOAST_BASE_CLASS} ${notificationData.toastClass}`,
        extendedTimeOut: notificationData.extendedTimeOut,
        onActivateTick: notificationData.onActivateTick,
      },
    );
    return toast;
  }

  private showInfoNotification(notificationData: NotificationData) {
    if (!notificationData.topElements[0].icon && !notificationData.topElements[0].spinner) {
      notificationData.topElements[0].icon = { icon: 'info', size: 16 };
    }
    const toast = this.toastrService.info(
      notificationData.middleElements[0]?.msg ?? '',
      `INFO:${notificationData.topElements[0].msg}`,
      {
        tapToDismiss: false,
        disableTimeOut: !notificationData.timeout,
        timeOut: notificationData.timeout,
        payload: notificationData,
        toastComponent: CustomNotificationToastrComponent,
        progressBar: false,
        progressAnimation: 'increasing',
        extendedTimeOut: notificationData.extendedTimeOut,
        onActivateTick: notificationData.onActivateTick,
      },
    );
    return toast;
  }

  private showSuccessNotification(notificationData: NotificationData) {
    if (!notificationData.topElements[0].icon && !notificationData.topElements[0].spinner) {
      notificationData.topElements[0].icon = { icon: 'check', size: 16 };
    }
    const toast = this.toastrService.success(
      notificationData.middleElements[0]?.msg,
      `SUCCESS:${notificationData.topElements[0].msg}`,
      {
        tapToDismiss: false,
        disableTimeOut: !notificationData.timeout,
        timeOut: notificationData.timeout,
        payload: notificationData,
        toastComponent: CustomNotificationToastrComponent,
        progressBar: false,
        progressAnimation: 'increasing',
        toastClass: `${TOAST_BASE_CLASS} ${notificationData.toastClass}`,
        extendedTimeOut: notificationData.extendedTimeOut,
        onActivateTick: notificationData.onActivateTick,
      },
    );
    return toast;
  }

  private showRequestNotification(notificationData: NotificationData) {
    if (!notificationData.topElements[0].icon && !notificationData.topElements[0].spinner) {
      notificationData.topElements[0].icon = { icon: 'info', size: 16 };
    }
    const toast = this.toastrService.info(
      notificationData.middleElements[0].msg,
      `REQUEST:${notificationData.topElements[0].msg}`,
      {
        tapToDismiss: false,
        disableTimeOut: !notificationData.timeout,
        timeOut: notificationData.timeout,
        payload: notificationData,
        toastComponent: CustomNotificationToastrComponent,
        progressBar: false,
        progressAnimation: 'increasing',
        toastClass: `${TOAST_BASE_CLASS} ${notificationData.toastClass}`,
        extendedTimeOut: notificationData.extendedTimeOut,
        onActivateTick: notificationData.onActivateTick,
      },
    );
    return toast;
  }

  public showLoadingNotification(
    text: string,
    notificationCode: string,
    autoDismiss = false,
    onTimedOut?: () => void,
    maxLines?: number,
  ): void {
    const notificationAlreadyShowing = this.checkIfNotificationActiveByCode(notificationCode);

    if (!notificationAlreadyShowing) {
      this.loadingNotificationCounter[notificationCode] = 0;
    }

    const numberOfShownNotifications = this.loadingNotificationCounter[notificationCode] ?? 0;

    this.loadingNotificationCounter[notificationCode] = numberOfShownNotifications + 1;

    if (!notificationAlreadyShowing) {
      // show notification on the first time only

      const iconToastOptions = [
        undefined, // icon
        text as any, // str
        undefined, // countDown
        undefined, // callback
        true, // spinner
        undefined, // iconBackground
        undefined, // removeMargin
        undefined, // outlined
        undefined, // style
        this.buildMaxLinesTextStyle(maxLines), // textStyle
      ];

      const titleElement = new IconMessageToasterElement(...iconToastOptions);
      const notificationDataBuilder = new NotificationDataBuilder(notificationCode)
        .style(ToasterPopupStyle.INFO)
        .type(NotificationType.INFO)
        .topElements([titleElement])
        .dismissable(false);

      if (autoDismiss) {
        notificationDataBuilder.timeOut(this.LOADING_NOTIFICATION_TIMOUT_IN_SECONDS);
      }
      this.showNotification(notificationDataBuilder.build(), () => {
        if (this.loadingNotificationCounter[notificationCode] && onTimedOut) {
          onTimedOut();
        }
      });
    }
  }

  private buildMaxLinesTextStyle(maxLines?: number): string {
    if (!maxLines) {
      return '';
    }
    return `display: -webkit-box;
    -webkit-line-clamp: ${maxLines};
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis`;
  }

  public dismissLoadingNotification(notificationCode: string): void {
    if (!this.checkIfNotificationActiveByCode(notificationCode)) {
      this.loadingNotificationCounter[notificationCode] = 0;
      return;
    }

    if (this.loadingNotificationCounter[notificationCode]) {
      this.loadingNotificationCounter[notificationCode] -= 1;
    }
    if (this.loadingNotificationCounter[notificationCode] === 0) {
      this.dismissNotificationsByCode([notificationCode]);
    }
  }

  public dismissNotificationsByCode(notificationCodes: string[]) {
    // delay the action till the notifications are rendered
    notificationCodes.forEach((code) => {
      const notificationItems = this.notificationCategoriesMap.get(code);
      if (notificationItems) {
        notificationItems.forEach((item) => {
          this.toastrService.remove(item);
        });
      } else if (this.pendingShowNotificationIds.has(code)) {
        this.pendingDismissCodes.add(code);
      }
    });
  }

  public checkIfNotificationActiveByCode(notificationCode: string): boolean {
    const notificationItems = this.notificationCategoriesMap.get(notificationCode);
    if (notificationItems && notificationItems.size > 0) {
      return true;
    } else {
      return false;
    }
  }

  public dismissAllNotifications() {
    this.toastrService.clear();
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  private telemetryLogger(notificationData: NotificationData) {
    const { type, notificationCode, middleElements, topElements } = notificationData;
    const message = middleElements.length ? middleElements[0].msg : undefined;
    const title = topElements.length ? topElements[0].msg : undefined;
    this.telemetry.event('Notification Event', {
      type,
      title,
      message,
      code: notificationCode,
    });
  }

  private isDuplicateNotification(notificationData: NotificationData) {
    return this.notificationCategoriesMap.has(notificationData.notificationCode);
  }

  showDefaultErrorNotification(titleMsg: string, contentMsg: string, errorClass: ERRORS): void {
    const titleElement = new IconMessageToasterElement(
      { icon: 'error_outlined', size: 16 },
      titleMsg,
      undefined,
      undefined,
      undefined,
      IconBackground.ERROR,
      true,
      true,
    );

    const messageElement = new IconMessageToasterElement(undefined, contentMsg);

    const notificationData = new NotificationDataBuilder(errorClass)
      .style(ToasterPopupStyle.WARN)
      .type(NotificationType.WARNING)
      .timeOut(6)
      .topElements([titleElement])
      .middleElements([messageElement])
      .version2Notification(true)
      .width(304)
      .build();

    this.showNotification(notificationData);
  }

  showDefaultSuccessNotification(messageContent: string, notificationCode: string) {
    const topElement = new IconMessageToasterElement(
      { icon: 'check', size: 18 },
      messageContent,
      undefined,
      undefined,
      undefined,
      IconBackground.SUCCESS,
      true,
      true,
    );

    const successNotificationData = new NotificationDataBuilder(notificationCode)
      .style(ToasterPopupStyle.SUCCESS)
      .type(NotificationType.SUCCESS)
      .version2Notification(true)
      .priority(380)
      .width(304)
      .timeOut(5)
      .topElements([topElement])
      .build();

    this.showNotification(successNotificationData);
  }

  /**
   * This is the most comprehensive function for Notification V2, containing the most used values.
   */
  showDefaultErrorNotificationV2(
    titleMsg: string,
    contentMsg: string,
    notificationCode: ERRORS,
    titleIcon: Icon = { icon: 'warning_amber', size: 18 },
    bottomElements: ButtonToasterElement[] = [],
    timeOut: number = 10,
    onActivateTick: boolean = false, // For more info, please refer to https://pencilapp.slack.com/archives/G01KJ1DC249/p1725300624486079
    onTimedOut: () => void = () => {},
  ): void {
    const titleElement = new IconMessageToasterElement(
      titleIcon,
      titleMsg,
      undefined,
      undefined,
      undefined,
      IconBackground.ERROR,
      true,
      true,
    );

    const messageElement = new IconMessageToasterElement(undefined, contentMsg);

    const notificationData = new NotificationDataBuilder(notificationCode)
      .style(ToasterPopupStyle.ERROR)
      .type(NotificationType.ERROR)
      .timeOut(timeOut)
      .topElements([titleElement])
      .middleElements([messageElement])
      .bottomElements(bottomElements)
      .version2Notification(true)
      .width(304)
      .onActivateTick(onActivateTick)
      .build();

    this.showNotification(notificationData, onTimedOut);
  }
}

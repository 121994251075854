<div *ngIf="currentFolders.length !== 0">
  <span class="context-header font-weight-bold"> {{ 'Folders' | translate }}</span>
</div>
<div class="folder-container d-flex flex-row flex-wrap justify-content-start">
  <ng-container>
    <div *ngFor="let folder of currentFolders; let i = index" class="folder-btn-container">
      <button
        [ngClass]="{
          'btn-folder-selected': folder.xMeta?.display.selected,
          'btn-folder': !folder.xMeta?.display.selected
        }"
        class="d-flex justify-content-start align-items-center cpr-16 cpl-16 cpt-4 cpb-4"
        appTooltip="{{ folder.name }}"
        appTooltipPlacement="bottom-center"
        (click)="openFolder.emit({ folder: folder._id, append: true })"
      >
        <span class="material-icons"> folder_open </span>
        <span class="text">&nbsp;&nbsp;{{ folder.name | truncate: [maxNameLength] }} </span>
      </button>
    </div>
  </ng-container>
</div>
<div *ngIf="currentFiles.length !== 0">
  <span class="context-header font-weight-bold">{{ 'Files' | translate }}</span>
</div>
<div
  infiniteScroll
  [infiniteScrollDistance]="2"
  (scrolled)="onScrollDown()"
  [scrollWindow]="false"
  [infiniteScrollContainer]="'.files-container'"
  [fromRoot]="true"
  class="d-flex flex-row flex-wrap justify-content-start"
  *ngIf="activeFolder && (currentFolders.length !== 0 || currentFiles.length !== 0)"
>
  <ng-container>
    <div
      class="file-item"
      *ngFor="let file of currentFiles; let i = index"
      [ngStyle]="file.xMeta.display.style"
      (click)="toggleFileSelection(i, $event)"
      (dblclick)="toggleFileSelection(i, $event)"
    >
      <div
        class="img-container align-items-center justify-content-center overflow-hidden d-flex"
        [ngClass]="{
          'img-container-selected': file.xMeta?.display.selected
        }"
        appTooltip="{{ file.name }}"
        appTooltipPlacement="bottom-center"
        appTooltipCreateNewLine="true"
        appTooltipPanelClass="grid-tooltip-panel"
        appTooltipOffsetY="20"
      >
        <img
          *ngIf="file.type === resourceTypes.IMAGE"
          [src]="file.url"
          [alt]="file.name"
          loading="lazy"
        />
        <div
          *ngIf="file.type === resourceTypes.VIDEO"
          [innerHtml]="file.embedding"
          class="w-100"
        ></div>
        <app-pdf-fragment-container
          *ngIf="
            file.type === resourceTypes.DOCUMENT && (file.name || file.altName)?.endsWith('.pdf')
          "
          class="pdf-viewer"
          [src]="file.url"
          [page]="1"
        >
        </app-pdf-fragment-container>
        <div
          *ngIf="
            file.type === resourceTypes.DOCUMENT && !(file.name || file.altName)?.endsWith('.pdf')
          "
        >
          <i class="material-icons-outlined thumbnail">text_snippet</i>
        </div>
      </div>
      <!--@es-->
      <span class="img-label">{{
        file.name || file.altName | fileNameFormat: [maxNameLength]
      }}</span>
    </div>
  </ng-container>
</div>

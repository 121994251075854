import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PDFPageProxy } from 'pdfjs-dist';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IconTypes } from '../../../../../standalones/components/pencil-icon/pencil-icon.component';

export interface PreviewItem {
  isSelected: boolean;
  pdfPage: PDFPageProxy;
}

@UntilDestroy()
@Component({
  selector: 'app-pdf-preview-selector',
  templateUrl: './pdf-preview-selector.component.html',
  styleUrls: ['./pdf-preview-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfPreviewSelectorComponent {
  @Input() fileName: string = '';
  @Input() pageItems: PreviewItem[] = [];
  @Output() selectedIndicesSubmitted = new EventEmitter<number[]>();
  @Output() cancelClicked = new EventEmitter<void>();

  protected readonly IconTypes = IconTypes;

  private getUserSelectedPagesIndices(): number[] {
    const items: number[] = [];
    this.pageItems.forEach((item, index) => {
      if (item.isSelected) {
        items.push(index);
      }
    });

    return items;
  }

  // TODO: Refactor to an observable instead of getter
  get selectedPagesCount(): number {
    return this.pageItems.filter((item) => item.isSelected).length;
  }

  selectAll() {
    this.pageItems.forEach((item) => (item.isSelected = true));
  }

  deselectAll() {
    this.pageItems.forEach((item) => (item.isSelected = false));
  }

  toggleItemSelection(index: number) {
    this.pageItems[index].isSelected = !this.pageItems[index].isSelected;
  }

  onCancelClick() {
    this.cancelClicked.emit();
  }

  onNextClick() {
    const indices = this.getUserSelectedPagesIndices();
    this.selectedIndicesSubmitted.emit(indices);
  }
}

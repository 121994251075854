import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetModule,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { MomentTimezonePickerModule } from 'moment-timezone-picker';
import { IntercomModule } from 'ng-intercom';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { WebcamModule } from 'ngx-webcam';
import { SafePipeModule } from 'safe-pipe';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTableModule } from '@angular/material/table';

import { MatIconModule } from '@angular/material/icon';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth.interceptor';
import { BillingActivate } from './billing.guard';
import { CalculatorModule } from './common/calculator/calculator.module';
import { CommonComponentsModule } from './common/common-components.module';
import { GraphModule } from './common/graph/graph.module';
import { PeriodicTableModule } from './common/periodic-table/periodic-table.module';
import { ZoomUtil } from './common/utils/zoom.util';
import { AddIframeComponent } from './dialogs/add-iframe/add-iframe.component';
import { AddWebViewerComponent } from './dialogs/add-web-viewer/add-web-viewer.component';
import { BreakoutRoomDialogComponent } from './dialogs/breakout-room-dialog/breakout-room-dialog.component';
import { ChangeLanguageConfirmationDialogComponent } from './dialogs/change-language-confirmation-dialog/change-language-confirmation-dialog.component';
import { CloseFrameComponent } from './dialogs/close-frame/close-frame.component';
import { CommonDialogComponent } from './dialogs/common-dialog/common-dialog.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationModalV2Component } from './dialogs/confirmation-modal-v2/confirmation-modal-v2.component';
import { ConfirmationModalComponent } from './dialogs/confirmation-modal/confirmation-modal.component';
import { DeleteSpaceCommentComponent } from './dialogs/delete-space-comment/delete-space-comment.component';
import { DuplicateSpaceComponent } from './dialogs/duplicate-space/duplicate-space.component';
import { EmbeddedGoogleLoginComponent } from './dialogs/embedded-google-login/embedded-google-login.component';
import { LinkLoginComponent } from './dialogs/link-login/link-login.component';
import { LogoutDialogComponent } from './dialogs/logout/logout.component';
import { UploadDialogComponent as MediaUploadDialogComponent } from './dialogs/media-upload/upload-dialog.component';
import { MoveToFolderComponent } from './dialogs/move-to-folder/move-to-folder.component';
import { NotSupportedBrowsersComponent } from './dialogs/not-supported-browsers/not-supported-browsers.component';
import { PhetEmbedDialogComponent } from './dialogs/phet-embed/phet-embed.component';
import { RatingDialogComponent } from './dialogs/rating-dialog/rating-dialog.component';
import { RenameSpaceComponent } from './dialogs/rename-space/rename-space.component';
import { RevokedInfoDialogComponent } from './dialogs/revoked-info-dialog/revoke-info-dialog.component';
import { SelectAllComponent } from './dialogs/select-all/select-all.component';
import { SpeedbumpModalComponent } from './dialogs/speedbump-modal/speedbump-modal.component';
import { UserInfoInputDialogComponent } from './dialogs/user-info-input-dialog/user-info-input-dialog.component';
import { VirtualBackgroundPanelComponent } from './dialogs/virtual-background-panel/virtual-background-panel.component';
import { VisibilityConfirmDialogComponent } from './dialogs/visibility-confirm/visibility-confirm.component';
import { VisibilityDraftAlertDialogComponent } from './dialogs/visibility-draft-alert/visibility-draft-alert.component';
import { DirectivesModule } from './directives/directives.module';
import { ErrorInterceptor } from './error.interceptor';
import { LoginActivate } from './login.guard';
import { MessagesModule } from './messages/messages.module';
import { AppMaterialModule } from './modules/app-material/app-material.module';
import { FeatureFlagsComponent } from './nav-bar/feature-flags/feature-flags.component';
import { ImpersonateDialogComponent, NavBarComponent } from './nav-bar/nav-bar.component';
import { PipeModule } from './pipes/pipes.module';
import { UpgradeVersionNotificationComponent } from './popup-notifications/upgrade-version-notification/upgrade-version-notification.component';
import { SentryErrorHandler } from './sentry-error-handler';
import { EmbedVideoService } from './services/embed-video.service';
import { SpaceBoardsService } from './services/space-boards.service';
import { SessionAnalyticsModule } from './session-analytics/session-analytics.module';
import { SessionActivate } from './session.guard';
import { UserSettingsAccountComponent } from './settings/user-settings-panel/user-settings-account/user-settings-account.component';
import { UserSettingsLanguageComponent } from './settings/user-settings-panel/user-settings-language/user-settings-language.component';
import { UserSettingsPanelComponent } from './settings/user-settings-panel/user-settings-panel.component';
import { UserSettingsPaymentComponent } from './settings/user-settings-panel/user-settings-payment/user-settings-payment.component';
import { UserSettingsPermissionsComponent } from './settings/user-settings-panel/user-settings-permissions/user-settings-permissions.component';
import { UserSettingsAiAssistComponent } from './settings/user-settings-panel/user-settings-ai-assist/user-settings-ai-assist.component';
import { SpaceRepository } from './state/space.repository';
import { ToolbarStateRepository } from './state/toolbar-state.repository';
import { UIPropsRepository } from './state/ui-props.repository';
import { SwitcherComponent } from './switcher/switcher.component';
import { UiModule } from './ui/ui.module';
import { UserActivate } from './user.guard';
import { UsersModule } from './users/users.module';
import { ObservableUtils } from './utilities/ObservableUtils';
import { ObjectDuplicationUtilsService } from './utilities/object-duplication-utils.service';
import { TelemetryService } from './services/telemetry.service';
import { UserSettingsPermissionsToggleComponent } from './settings/user-settings-panel/user-settings-permissions/user-settings-permissions-toggle/user-settings-permissions-toggle.component';
import { UserSettingsPermissionsSelectComponent } from './settings/user-settings-panel/user-settings-permissions/user-settings-permissions-select/user-settings-permissions-select.component';
import { SelectSpaceDialogComponent } from './dialogs/select-space-dialog/select-space-dialog.component';
import { CreateSpaceDialogComponent } from './dialogs/create-space-dialog/create-space-dialog.component';
import { NetworkCacheService } from './services/network-cache.service';
import { TooltipDirective } from './standalones/directives/pncl-tooltip.directive';
import { UserSettingsAdvancedComponent } from './settings/user-settings-panel/user-settings-advanced/user-settings-advanced.component';
import { AddPencilAiComponent } from './dialogs/add-pencil-ai/add-pencil-ai.component';
import { PencilButtonComponent } from './standalones/components/pencil-button/pencil-button.component';
import { PencilIconComponent } from './standalones/components/pencil-icon/pencil-icon.component';
import { AvailableEventHostsDialogComponent } from './dialogs/available-event-hosts-dialog/available-event-hosts-dialog.component';
import { UploadDialogComponent } from './dialogs/upload/upload-dialog.component';
import { UploadModule } from './upload/upload.module';
import { ChooseSpaceTemplateDialogComponent } from './dialogs/choose-space-template-dialog/choose-space-template-dialog.component';
import { UserSettingsSitesManagementComponent } from './settings/user-settings-panel/user-settings-sites-management/user-settings-sites-management.component';
import { UserSettingsInstitutionCustomAttributesComponent } from './settings/user-settings-panel/user-settings-institution-custom-attributes/user-settings-institution-custom-attributes.component';
import { PencilPillComponent } from './standalones/components/pencil-pill/pencil-pill.component';
import { UserSettingsBillableUsageComponent } from './settings/user-settings-panel/user-settings-billable-usage/user-settings-billable-usage.component';
import { CustomExpansionPanelComponent } from './standalones/components/custom-expansion-panel/custom-expansion-panel.component';

export function telemetryInitializer(telemetry: TelemetryService) {
  return () => {
    telemetry.init();
    telemetry.toggleDOMTracking(true); // start capturing DOM on app start
  };
}

export function networkCacheInitializer(networkCache: NetworkCacheService) {
  return async () => {
    // Clear all cached values on start
    await networkCache.clearAllCaches();
  };
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const isLocalHost = (hostname = window.location.hostname) =>
  ['localhost', '127.0.0.1', '', '::1'].includes(hostname);

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    LogoutDialogComponent,
    SelectAllComponent,
    ImpersonateDialogComponent,
    VisibilityConfirmDialogComponent,
    VisibilityDraftAlertDialogComponent,
    FeatureFlagsComponent,
    UploadDialogComponent,
    MediaUploadDialogComponent,
    CommonDialogComponent,
    PhetEmbedDialogComponent,
    MoveToFolderComponent,
    LinkLoginComponent,
    NotSupportedBrowsersComponent,
    CloseFrameComponent,
    RevokedInfoDialogComponent,
    UpgradeVersionNotificationComponent,
    RenameSpaceComponent,
    ConfirmationModalComponent,
    RatingDialogComponent,
    AddIframeComponent,
    AddWebViewerComponent,
    RatingDialogComponent,
    BreakoutRoomDialogComponent,
    VirtualBackgroundPanelComponent,
    ChangeLanguageConfirmationDialogComponent,
    EmbeddedGoogleLoginComponent,
    SwitcherComponent,
    DeleteSpaceCommentComponent,
    DuplicateSpaceComponent,
    UserSettingsPanelComponent,
    UserSettingsAccountComponent,
    UserSettingsLanguageComponent,
    UserSettingsAdvancedComponent,
    UserSettingsPaymentComponent,
    UserSettingsAiAssistComponent,
    UserSettingsBillableUsageComponent,
    ConfirmationModalV2Component,
    ConfirmationDialogComponent,
    UserSettingsPermissionsComponent,
    UserSettingsPermissionsToggleComponent,
    UserSettingsPermissionsSelectComponent,
    UserSettingsSitesManagementComponent,
    UserSettingsInstitutionCustomAttributesComponent,
    SpeedbumpModalComponent,
    UserInfoInputDialogComponent,
    SelectSpaceDialogComponent,
    CreateSpaceDialogComponent,
    AddPencilAiComponent,
    AvailableEventHostsDialogComponent,
    ChooseSpaceTemplateDialogComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: telemetryInitializer,
      deps: [TelemetryService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: networkCacheInitializer,
      deps: [NetworkCacheService],
      multi: true,
    },
    LoginActivate,
    UserActivate,
    BillingActivate,
    SessionActivate,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    EmbedVideoService,
    ObservableUtils,
    ZoomUtil,
    ObjectDuplicationUtilsService,
    SpaceRepository,
    SpaceBoardsService,
    UIPropsRepository,
    ToolbarStateRepository,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: MatBottomSheetRef,
      useValue: {},
    },
    {
      provide: MAT_BOTTOM_SHEET_DATA,
      useValue: {},
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {},
    },
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('./notification-service-worker.js', {
      enabled: !isLocalHost() || environment.runServiceWorkerLocally,
    }),
    ReactiveFormsModule,
    FormsModule,
    AppMaterialModule,
    provideAuth(() => {
      const auth = getAuth();
      return auth;
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideDatabase(() => {
      const database = getDatabase();
      return database;
    }),
    provideStorage(() => {
      const storage = getStorage();
      return storage;
    }),
    DragDropModule,
    UsersModule,
    CalculatorModule,
    GraphModule,
    PeriodicTableModule,
    PipeModule,
    SafePipeModule,
    UiModule,
    MessagesModule,
    CommonComponentsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    IntercomModule.forRoot({
      appId: 'keleytc7',
      updateOnRouterChange: true,
    }),
    GoogleTagManagerModule.forRoot({
      id: environment.gtmId,
    }),
    NgSelectModule,
    NgxFileDropModule,
    DirectivesModule,
    WebcamModule,
    ImageCropperModule,
    InfiniteScrollModule,
    OverlayModule,
    SessionAnalyticsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    MatProgressBarModule,
    MatBottomSheetModule,
    MatDialogModule,
    MomentTimezonePickerModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatSelectModule,
    MatTabsModule,
    MatSlideToggleModule,
    NgbTooltipModule,
    TooltipDirective,
    MatTableModule,
    PencilButtonComponent,
    PencilIconComponent,
    MatIconModule,
    UploadModule,
    PencilPillComponent,
    CustomExpansionPanelComponent,
  ],
})
export class AppModule {}

import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { DebouncedFunc } from 'lodash';
import { INFOS } from '../common/utils/notification-constants';
import {
  OverlayCanvas,
  RtEvent,
} from '../sessions/session/overlay-canvas/overlay-canvas.component';
import {
  RealTimeDrawingHandler,
  RealTimePointerHandler,
} from '../sessions/session/wb-toolbar/toolbars/realtime-common';
import { ToasterPopupStyle } from '../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { IconMessageToasterElement } from '../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { FlagsService } from './flags.service';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from './notification-toaster.service';
import { ScribbleType, SessionSharedDataService } from './session-shared-data.service';
import { SessionsVptService } from './sessions-vpt.service';

@Injectable({
  providedIn: 'root',
})
export class SessionRtdService implements OnDestroy {
  public rtEventEmitter: EventEmitter<RtEvent[]> = new EventEmitter();
  public rtSingleEventEmitter: EventEmitter<RtEvent> = new EventEmitter();
  private realTimeDrawingHandler?: RealTimeDrawingHandler;
  private realTimePointerHandler?: RealTimePointerHandler;

  // Emitted when there is a yjs update from the socket server with new scribbles
  public readonly scribblesAdded: Subject<any[]> = new Subject();

  // Holds a reference to the current OverlayCanvas and its render function for RTD.
  overlayCanvas?: OverlayCanvas;
  private renderFunc?: DebouncedFunc<() => void>;

  private subscriptions: Subscription[] = [];

  constructor(
    public flagsService: FlagsService,
    public sharedDataService: SessionSharedDataService,
    public sessionsVptService: SessionsVptService,
    private notificationsService: NotificationToasterService,
    private translate: TranslateService,
  ) {
    this.realTimeDrawingHandler = new RealTimeDrawingHandler(
      this.flagsService,
      this.sharedDataService,
      this.sessionsVptService,
      this,
    );

    const pointerAnimateDuration =
      (this.flagsService.featureFlagsVariables.event_buffering
        .receive_realtime_buffer_ms as number) ?? 30; // default to 30ms
    this.realTimePointerHandler = new RealTimePointerHandler(
      this.flagsService,
      this.sharedDataService,
      this.sessionsVptService,
      this,
      pointerAnimateDuration,
    );
    this.setupEventHandlers();
  }

  private setupEventHandlers() {
    const sub = this.rtEventEmitter.subscribe((rtEvents) => {
      if (this.realTimePointerHandler) {
        this.realTimePointerHandler.handleRTUpdates(rtEvents);
      }
      if (this.realTimeDrawingHandler) {
        this.realTimeDrawingHandler.handleRTUpdates(rtEvents);
      }
    });
    this.subscriptions.push(sub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.realTimePointerHandler?.onDestroy();
    this.realTimeDrawingHandler?.onDestroy();
  }

  setOverlayCanvas(overlayCanvas: OverlayCanvas, renderFunc?: DebouncedFunc<() => void>) {
    this.overlayCanvas = overlayCanvas;
    this.renderFunc = renderFunc;
    this.sharedDataService.fabricCanvas?.on('remote:added', this.handleRemoteAdded.bind(this));
  }

  clearOverlayCanvas() {
    this.sharedDataService.fabricCanvas?.off('remote:added');
    this.overlayCanvas = undefined;
    this.renderFunc = undefined;
  }

  renderOverlayCanvas() {
    if (this.renderFunc) {
      this.renderFunc();
    }
  }

  private handleRemoteAdded(data: any) {
    const objectsAdded = data.objectsAdded;
    const scribblesAdded = objectsAdded.filter((obj: any) =>
      [ScribbleType.DRAW, ScribbleType.HIGHLIGHT].includes(obj.ScribbleType),
    );
    this.scribblesAdded.next(scribblesAdded);
  }

  togglePointers(cursorsHidden: boolean, showNotification = true) {
    this.realTimePointerHandler?.togglePointers(cursorsHidden);
    this.notificationsService.dismissNotificationsByCode([
      cursorsHidden ? INFOS.SPACE_CURSORS_RESTORED : INFOS.SPACE_CURSORS_HIDDEN,
    ]);
    const titleElement = new IconMessageToasterElement(
      { svgIcon: 'cursor_hidden_dark', size: 16 },
      cursorsHidden
        ? this.translate.instant('Cursors hidden')
        : this.translate.instant('Cursors restored'),
    );
    const messageElement = new IconMessageToasterElement(
      undefined,
      cursorsHidden
        ? this.translate.instant("Other people's cursors have been hidden for you only.")
        : this.translate.instant("Other people's cursors have been restored."),
    );

    if (showNotification) {
      const togglePointersNotificationData = new NotificationDataBuilder(
        cursorsHidden ? INFOS.SPACE_CURSORS_HIDDEN : INFOS.SPACE_CURSORS_RESTORED,
      )
        .style(ToasterPopupStyle.INFO)
        .type(NotificationType.INFO)
        .timeOut(5)
        .topElements([titleElement])
        .middleElements([messageElement])
        .dismissable(false)
        .build();
      this.notificationsService.showNotification(togglePointersNotificationData);
    }
  }
}

// By default the pointer is the same space as the viewport
// This changes the pointer to use the absolute pointer which is
// the point in the transformed canvas that will render in the same place
// as the viewport
// Full Explanation: https://docs.google.com/document/d/1EhfANJTD1153K3RejO6Pb2piUp716AIoe3ZVwe39k2g/edit?usp=sharing
export const scaleEvent = (e: fabric.IEvent) => {
  e.pointer = e.absolutePointer;
  return e;
};

import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map } from 'rxjs';
import { User, UserInfo } from 'src/app/models/user';
import { MessengerService, MessengerWindowState } from 'src/app/services/messenger.service';
import { UserService } from 'src/app/services/user.service';
import { encodeContext, setContextUsers } from 'src/app/models/context';
import { PanelView } from 'src/app/sessions/panel/panel.component';
/*
  The chat.component and chat-window.component is intended for use in the new Spaces UI
  whereas, the messenger.component and messenger-window.component for the old Spaces UI.
  All which is controlled by the spaces ui flag.
*/
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SessionSharedDataService } from 'src/app/services/session-shared-data.service';
import { MessagingService } from 'src/app/services/messaging.service';
import {
  ConversationType,
  MessageAction,
  NewUserConversationRequest,
} from 'src/app/models/messaging';

import { ConversationService } from 'src/app/services/conversation.service';
import { FLAGS, FlagsService } from 'src/app/services/flags.service';
import { SpaceRepository } from 'src/app/state/space.repository';
import { Session } from 'src/app/models/session';
import { TranslateService } from '@ngx-translate/core';
import { maxTextLength } from 'src/app/common/utils/messaging';
import { TranscriptRepository } from 'src/app/state/transcript.repository';
import { SpacePermissionsManagerService } from 'src/app/services/space-permissions-manager.service';
import { TelemetryService } from 'src/app/services/telemetry.service';
import { ChatRepository } from '../state/chat-ui.repository';

export enum ChatOptions {
  chat,
  transcript,
  // to-be implemented
  private,
  timeline,
}

@UntilDestroy()
@Component({
  selector: 'app-chat-view',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  @Input() enableMessaging = true;
  @Input() public permittedUsersIds?: Set<string>;
  @Output() public changePanelView = new EventEmitter<PanelView>();
  totalMessages = 0;

  public unreadChat = false;
  public isSpaceOwner$ = this.spaceRepo.activeSpace$
    .pipe(map((space) => Session.isOwnedByUser(space, this.user)))
    .pipe(distinctUntilChanged());
  public unreadPrivateChat = false;
  public isSpaceDirectMessagingEnabled = false;
  public maxNameLength = maxTextLength;
  public existingChatList?: Set<string>;
  public activeChatsIds: Set<string> = new Set();
  public openedChatUser: User | null = null;
  public MainPanelView = PanelView;
  public ChatOptions = ChatOptions;
  public MessageAction = MessageAction;
  public chatOptions!: ChatOptions | null;
  public activeChats: MessengerWindowState[];
  public userLookup?: { [id: string]: UserInfo };
  public user?: User;
  public spaceTranscriptsEnabled = false;

  isActiveChat = false;

  spaceId: string | undefined;

  constructor(
    private userService: UserService,
    public messengerService: MessengerService,
    public messagingService: MessagingService,
    private translateService: TranslateService,
    private conversationService: ConversationService,
    private spaceSharedDataService: SessionSharedDataService,
    private flagsService: FlagsService,
    private spaceRepo: SpaceRepository,
    private chatRepository: ChatRepository,
    private transcriptRepo: TranscriptRepository,
    public spacePermissionsManagerService: SpacePermissionsManagerService,
    private telemetry: TelemetryService,
  ) {
    this.activeChats = [];
    this.messengerService.activeChats.next(this.activeChats);
    this.messagingService.initializePrivateChatList();

    if (this.flagsService.isFlagEnabled(FLAGS.SPACES_DIRECT_MESSAGING)) {
      this.isSpaceDirectMessagingEnabled = true;
    } else {
      this.messengerService.activeChatOptions.next(ChatOptions.chat);
    }

    combineLatest([this.userService.allUsers, this.userService.user])
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        // If one value is false don't run
        if (!data.every((v) => v)) {
          return;
        }
        const reducer = (dict: any = {}, currUser: UserInfo) => ({
          ...dict,
          [currUser._id]: currUser,
        });
        this.userLookup = data[0]?.profiles?.reduce(reducer, {});
        this.user = data[1]?.user;

        this.messengerService.newChat.pipe(untilDestroyed(this)).subscribe((newChat) => {
          this.createNewChat(newChat);
        });
      });

    this.messengerService.removeChat.pipe(untilDestroyed(this)).subscribe((context) => {
      if (context) {
        const contextString = encodeContext(context);
        this.activeChats = this.activeChats.filter(
          (chat) => encodeContext(chat.context) !== contextString,
        );
        this.activeChatsIds.delete(contextString);
        this.messengerService.chatRemoved.next(contextString);
        this.messengerService.activeChats.next(this.activeChats);
      }
    });
  }

  public ngOnInit(): void {
    if (this.activeChats.length === 0) {
      this.messengerService.newChat.pipe(untilDestroyed(this)).subscribe((newChat) => {
        this.createNewChat(newChat);
      });
    }

    this.messengerService.chatPanelOpened$.pipe(untilDestroyed(this)).subscribe((value) => {
      if (this.openedChatUser) {
        this.openChat(ChatOptions.private, this.openedChatUser);
      }
    });
    this.initializeMessageList();

    this.transcriptRepo
      .canShowTranscriptionUI()
      .pipe(untilDestroyed(this))
      .subscribe((canShowTranscriptionUI) => {
        this.spaceTranscriptsEnabled = canShowTranscriptionUI;
      });

    this.chatRepository.isActiveChat$
      .pipe(untilDestroyed(this))
      .subscribe((val) => (this.isActiveChat = val));

    this.isSpaceOwner$.pipe(untilDestroyed(this)).subscribe((val: boolean) => {
      this.chatRepository.setSpaceOwner(val);
    });

    combineLatest([this.spaceRepo.activeSpaceId$, this.spaceSharedDataService.hasUserLeftSpace$])
      .pipe(untilDestroyed(this))
      .subscribe(([spaceId, hasUserLeftSpace]) => {
        if (hasUserLeftSpace || (!spaceId && this.spaceId !== spaceId)) {
          this.onDismissChatMenu();
        }

        this.spaceId = spaceId;
      });

    this.messagingService.openChat$.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data) {
        this.openChat(data.chatOptions, data.user as User);
        this.messagingService.clearOpenedPrivateChat();
      }
    });
  }

  /**
   * initializeMessageList
   */
  public initializeMessageList() {
    // Check if there are unread chat for Everyone
    this.messengerService.unreadSpaceLevelMessageCount$
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (this.chatOptions !== ChatOptions.chat) {
          this.unreadChat = !!value;
          this.chatRepository.setChatUnreadMessage(!!value);
        }
      });

    // Check if there are unread private chat
    this.messengerService.unreadPrivateMessageCount
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.unreadPrivateChat = !!value;
      });

    // List of users id you have chatted with before.
    this.messagingService.existingChatList$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.existingChatList = value;
    });
    this.messagingService.openedPrivateChatUser$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.openedChatUser = value;
    });
    this.messengerService.activeChatOptions.pipe(untilDestroyed(this)).subscribe((value) => {
      this.chatOptions = value;
      if (!value) {
        this.chatRepository.resetActiveChat();
        this.chatRepository.resetChatMenuTopHeader();
      }
    });
  }

  public createNewChat(newChat: MessengerWindowState): void {
    // @TODO support more than one person
    if (newChat?.context?.users?.length) {
      const userId = newChat?.context?.users[0];
      if (this.user?._id) {
        setContextUsers(newChat.context, [...newChat.context.users, this.user._id]);
      }
      if (
        this.user?._id !== userId &&
        (!this.permittedUsersIds || this.permittedUsersIds.has(userId))
      ) {
        this.addOrReplaceChat(newChat);
      }
    } else if (newChat?.context?.session) {
      this.addOrReplaceChat(newChat);
    }
  }

  public addOrReplaceChat(newChat: MessengerWindowState): void {
    const contextStr = newChat.context && encodeContext(newChat.context);
    if (!contextStr) {
      return;
    }

    this.activeChatsIds.add(contextStr);
    const updatedChats = this.activeChats.filter(
      (chat) => encodeContext(chat.context) !== encodeContext(newChat.context),
    );
    updatedChats.push(newChat);
    this.activeChats = [...updatedChats];
    this.messengerService.activeChats.next(this.activeChats);

    this.conversationService.totalMessagesCount$.pipe(untilDestroyed(this)).subscribe((count) => {
      this.totalMessages = count;
    });
  }

  public openChat(chatOptions: ChatOptions, user?: User) {
    // If you have never chatted before, start a new chat.
    if (user && !this.existingChatList?.has(user._id)) {
      const newConversation: NewUserConversationRequest = {
        conversationType: ConversationType.UserConversation,
        user: <UserInfo>(<unknown>user),
      };
      this.startNewUserConversation(newConversation);
    }

    // Delete unread count for opened chat and dispatch
    const newCustomChatPreviews = this.messagingService.customChatPreviews$.value.map(
      (chatPreview) => {
        if (chatPreview.user?._id === user?._id) {
          chatPreview.unreadChatIds?.clear();
        }
        return chatPreview;
      },
    );
    this.messagingService.customChatPreviews$.next([...newCustomChatPreviews]);

    this.messagingService.calUnreadPrivateChat();

    if (!user) {
      this.messagingService.openedPrivateChatUser$.next(null);
      this.messengerService.unreadSpaceLevelMessageCount$.next(0);
    } else {
      this.messagingService.openedPrivateChatUser$.next(user);
    }

    this.messengerService.activeChatOptions.next(chatOptions);

    if (chatOptions === ChatOptions.transcript) {
      this.transcriptRepo.updateSpaceTranscriptUnreadStatus(false);
    }

    this.chatRepository.setActiveChat(true);
    this.chatRepository.updateChatMenuTopHeader({ option: chatOptions, user: user });
  }

  private startNewUserConversation(newUserConversation: NewUserConversationRequest) {
    this.messagingService.addNewUserConversation(newUserConversation);
  }

  public closeChat() {
    if (this.chatOptions === ChatOptions.chat && this.isSpaceDirectMessagingEnabled) {
      this.messengerService.unreadSpaceLevelMessageCount$.next(0);
    }

    if (this.chatOptions === ChatOptions.transcript) {
      this.transcriptRepo.updateSpaceTranscriptUnreadStatus(false);
    }

    this.messagingService.openedPrivateChatUser$.next(null);

    this.onDismissChatMenu();

    this.telemetry.setSessionVars({
      chat_panel_all_chats_open: false,
      chat_panel_chat_is_open:
        this.spaceSharedDataService.leftPanelView.getValue()?.panelView === PanelView.chat,
    });
  }

  public onClose(): void {
    if (this.chatOptions === ChatOptions.chat && this.isSpaceDirectMessagingEnabled) {
      this.messengerService.unreadSpaceLevelMessageCount$.next(0);
    }
    this.changePanelView.emit(undefined);
  }

  public onDeleteAllChat(): void {
    if (
      confirm(
        `${this.translateService.instant(
          'Are you sure you want to reset the general chat? This will erase all messages in the public chat and cannot be undone. Private messages will not be affected.',
        )}`,
      )
    ) {
      if (this.spaceRepo.activeSpaceId) {
        this.conversationService.deleteAllMessages(this.spaceRepo.activeSpaceId);
      }
    }
  }

  onOpenChatMenuOption(event: { option: ChatOptions; user?: User }): void {
    this.openChat(event.option, event.user);
    const chat_panel_all_chats_open = event.option === ChatOptions.chat;
    this.telemetry.setSessionVars({
      chat_panel_all_chats_open,
      chat_panel_chat_is_open: !chat_panel_all_chats_open,
    });
  }

  onDismissChatMenu() {
    if (this.isSpaceDirectMessagingEnabled) {
      this.messengerService.activeChatOptions.next(null);
    }

    this.chatRepository.setActiveChat(false);
    this.chatRepository.updateChatMenuTopHeader(null);
  }
}

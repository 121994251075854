export interface APIAnalyticsInsightResponse {
  sessions: Array<IAnalyticsInsight>;
  totalRecords: number;
  totalPages: number;
  pageNumber: number;
  pageSize: number;
}

export interface IAnalyticsInsight {
  sessionId: string; // It is the current session id
  spaceId: string; // Space id
  sessionTitle: string;
  sessionVisibility: string;
  sessionStartTime: Date;
  sessionEndTime: Date | null;
  callStats: CallStat[];
  attendees: AnalyticsSpaceUserStat[];
  roomAttendees: RoomAttendees[];
  status: SessionStatus;
  archived: boolean;
  billableSpaceTimeMinutes: number;
  billableHostDiscountSpaceTimeMinutes: number;
}

export interface RoomAttendees {
  roomUid: string;
  roomName: string;
  isActive: boolean;
  attendees: AnalyticsRoomUserStat[];
}

interface IAnalyticsUserStat {
  userId: string;
  _id?: string;
  name: string;
  email: string;
  callActiveTalkingDuration: number;
  callMicOnDuration: number;
  callVideoOnDuration: number;
  callCountRaisedHands: number;
  callCountReactions: number;
  callDuration: number;
  profile_image?: any;
}

export interface IAnalyticsSpaceUserStat extends IAnalyticsUserStat {
  spaceCountPublicMessagesSent: number;
  spaceCountPrivateMessagesSent: number;
  spaceDurationInBackground: number;
  spaceFirstJoinTime: Date;
  sessionJoinTime?: Date; // for backward compatibility
  spaceLastLeaveTime: Date;
  spaceCountJoined: number;
  spaceDuration: number;
}

export interface IAnalyticsRoomUserStat extends IAnalyticsUserStat {
  roomCountPublicMessagesSent: number;
  roomCountPrivateMessagesSent: number;
  roomDurationInBackground: number;
  roomFirstJoinTime: Date;
  sessionJoinTime?: Date; // for backward compatibility
  roomLastLeaveTime: Date;
  roomCountJoined: number;
  roomDuration: number;
}

export interface CallStat {
  callStartTime: Date;
  callEndTime: Date;
}

export class AnalyticsUserStat {
  userId: string;
  callActiveTalkingDuration: number;
  name: string;
  email: string;
  callMicOnDuration: number;
  callVideoOnDuration: number;
  callCountRaisedHands: number;
  callCountReactions: number;
  callDuration: number;
  userImgObject?: any;

  constructor(attendee: Partial<IAnalyticsUserStat>) {
    this.userId = attendee.userId ?? attendee._id ?? '';
    this.name = attendee.name ?? '';
    this.email = attendee.email ?? '';
    this.callDuration = attendee.callDuration ?? 0;
    this.callMicOnDuration = attendee.callMicOnDuration ?? 0;
    this.callVideoOnDuration = attendee.callVideoOnDuration ?? 0;
    this.callCountRaisedHands = attendee.callCountRaisedHands ?? 0;
    this.callCountReactions = attendee.callCountReactions ?? 0;
    this.callActiveTalkingDuration = attendee.callActiveTalkingDuration ?? 0;
    this.userImgObject = { profile_image: attendee.profile_image, name: attendee.name };
  }
}

export class AnalyticsSpaceUserStat extends AnalyticsUserStat implements IAnalyticsSpaceUserStat {
  spaceCountPublicMessagesSent: number;
  spaceCountPrivateMessagesSent: number;
  spaceDurationInBackground: number;
  spaceFirstJoinTime: Date;
  spaceLastLeaveTime: Date;
  spaceCountJoined: number;
  spaceDuration: number;

  constructor(attendee: Partial<IAnalyticsSpaceUserStat>) {
    super(attendee);
    this.spaceCountPublicMessagesSent = attendee.spaceCountPublicMessagesSent ?? 0;
    this.spaceCountPrivateMessagesSent = attendee.spaceCountPrivateMessagesSent ?? 0;
    this.spaceDurationInBackground = attendee.spaceDurationInBackground ?? 0;
    this.spaceFirstJoinTime = attendee.spaceFirstJoinTime ?? attendee.sessionJoinTime ?? new Date();
    this.spaceLastLeaveTime = attendee.spaceLastLeaveTime ?? new Date();
    this.spaceCountJoined = attendee.spaceCountJoined ?? 0;
    this.spaceDuration = attendee.spaceDuration ?? 0;
  }
}

export class AnalyticsRoomUserStat extends AnalyticsUserStat implements IAnalyticsRoomUserStat {
  roomCountPublicMessagesSent: number;
  roomCountPrivateMessagesSent: number;
  roomDurationInBackground: number;
  roomFirstJoinTime: Date;
  roomLastLeaveTime: Date;
  roomCountJoined: number;
  roomDuration: number;

  constructor(attendee: Partial<IAnalyticsRoomUserStat>) {
    super(attendee);
    this.roomCountPublicMessagesSent = attendee.roomCountPublicMessagesSent ?? 0;
    this.roomCountPrivateMessagesSent = attendee.roomCountPrivateMessagesSent ?? 0;
    this.roomDurationInBackground = attendee.roomDurationInBackground ?? 0;
    this.roomFirstJoinTime = attendee.roomFirstJoinTime ?? attendee.sessionJoinTime ?? new Date();
    this.roomLastLeaveTime = attendee.roomLastLeaveTime ?? new Date();
    this.roomCountJoined = attendee.roomCountJoined ?? 0;
    this.roomDuration = attendee.roomDuration ?? 0;
  }
}

export enum AnalyticsEventType {
  JOIN_SPACE = 'joinSpace',
  LEAVE_SPACE = 'leaveSpace',
  DELETE_SPACE = 'deleteSpace',
  JOIN_CALL = 'joinCall',
  LEAVE_CALL = 'leaveCall',
  UNMUTE_VIDEO = 'unmuteVideo',
  MUTE_VIDEO = 'muteVideo',
  UNMUTE_AUDIO = 'unmuteAudio',
  MUTE_AUDIO = 'muteAudio',
  HAND_RAISED = 'handRaised',
  REACTIONS = 'reactions',
  START_SPEECH = 'startSpeech',
  END_SPEECH = 'endSpeech',
  HIDE_TAB = 'hideTab',
  UNHIDE_TAB = 'unhideTab',
}

export enum AnalyticsInsightType {
  SPEECH_TIME = 'callActiveTalkingDuration',
  AUDIO_TIME = 'callMicOnDuration',
  VIDEO_TIME = 'callVideoOnDuration',
  HAND_RAISED = 'callCountRaisedHands',
  REACTIONS = 'callCountReactions',
  TIME_ON_CALL = 'callDuration',
}

export enum SessionStatus {
  IN_PROGRESS = 'inProgress',
  PROCESSING = 'processing',
  PROCESSED = 'processed',
}

export class AnalyticsEvent {
  space: string;
  user: string;
  eventType: AnalyticsEventType;
  timestamp: Date;

  constructor(space: string, user: string, eventType: AnalyticsEventType, timestamp: Date) {
    this.user = user;
    (this.space = space), (this.eventType = eventType), (this.timestamp = timestamp);
  }
}

<div *ngIf="canvasItem">
  <div
    #box
    id="questionContainer"
    class="resizable-draggable"
    [ngClass]="{
      'app-size-limits':
        canvasItem.type === ItemModel.IFrame ||
        canvasItem.type === ItemModel.WebViewer ||
        canvasItem.type === ItemModel.Chat_GPT
    }"
    appCanvasItemState
    [relatedFabricObject]="canvasItem.relatedFabricItem"
    [static]="static"
    [maintainAspectRatio]="
      canvasItem.resizable &&
      canvasItem.type !== ItemModel.Formula &&
      canvasItem.type !== ItemModel.IFrame &&
      canvasItem.type !== ItemModel.Mario &&
      canvasItem.type !== ItemModel.Chat_GPT &&
      canvasItem.type !== ItemModel.WebViewer
    "
    [itemModel]="canvasItem.type"
    [translateOrigin]="true"
    [stateCheckActive]="
      (sharedDataService.sessionView.current$ | async)?.view !== sessionView.FULLSCREEN_APP
    "
    [style.margin-top.px]="(canvasItem.interactionBar$ | async) ? INTERACTIONS_BAR_HEIGHT : 0"
  >
    <app-wb-app-overlay
      *ngIf="canvasItem?.overlayApp"
      [hidden]="!static && (spaceZoomCutoff.shouldCutOff$ | async) === false"
      [height]="canvasItem?.relatedFabricItem.height"
      [width]="canvasItem?.relatedFabricItem.width"
      [data]="canvasItem?.overlayApp"
      [showTitle]="!static"
    ></app-wb-app-overlay>

    <ng-container
      *ngIf="
        !(
          canvasItem.overlayApp &&
          (static || (canvasItem?.type === ItemModel.WebViewer && webViewerDisabled))
        )
      "
    >
      <div
        [ngStyle]="{
          height: '100%',
          width: '100%'
        }"
      >
        <div
          class="question"
          [style.width.px]="700"
          *ngIf="
            !canvasItem.itemSettings?.notInvitedToCourse &&
            !canvasItem.itemSettings?.cannotAccessQuestion &&
            (canvasItem.itemSettings?.wrappedComponent === WrappedComponent.questionComponent ||
              canvasItem.itemSettings?.wrappedComponent === WrappedComponent.noteComponent)
          "
        >
          <app-question-view
            *ngIf="canvasItem.itemSettings?.componentInputs.item && user"
            [hideCommentCreator]="true"
            [canvasItem]="canvasItem"
            [hideComments]="true"
            [fromPractice]="false"
            [fromSession]="true"
            [pageType]="
              canvasItem.itemSettings?.wrappedComponent === WrappedComponent.questionComponent
                ? 'questions'
                : 'notes'
            "
            [question]="canvasItem.itemSettings?.componentInputs?.item"
            [courseId]="canvasItem.itemSettings?.componentInputs?.courseId"
            [sessionId]="''"
            [showExtras]="false"
            [user]="user"
            [questionLoadedForPerview]="loadForBoardPreview"
            [static]="static"
            (answered)="canvasItem.itemSettings?.componentInputs?.questionAnswer($event)"
            (showDetails)="canvasItem.itemSettings?.componentInputs?.showDetails()"
            (fragmentLoadComplete)="updateRelatedFabricObjectSize($event)"
          ></app-question-view>
        </div>
        <div
          *ngIf="
            canvasItem.itemSettings?.cannotAccessQuestion ||
            canvasItem.itemSettings?.notInvitedToCourse
          "
          [style.width.px]="700"
          [style.height.px]="700"
          class="cant-access"
        >
          <div class="cant-access-content">
            <div class="circle">
              <svg-icon [height]="'72'" [width]="'72'" [icon]="'cant-access'"></svg-icon>
            </div>
            <div style="margin-top: 18px">
              <div *ngIf="canvasItem.itemSettings?.cannotAccessQuestion" class="question-access">
                <p>{{ 'You do not have access to this question' | translate }}</p>
                <div style="margin-top: 37px" *ngIf="false">
                  <button
                    mat-flat-button
                    class="btn-request d-sm-inline-block d-none"
                    color="primary"
                  >
                    <em class="material-icons">mail_outline</em>
                    <span>{{ 'Request access' | translate }}</span>
                  </button>
                </div>
              </div>
              <div *ngIf="canvasItem.itemSettings?.notInvitedToCourse">
                <p style="width: 422px; font-size: 24px; line-height: 30px">
                  {{
                    'You do not have access to this question because you are not in the course.'
                      | translate
                  }}
                </p>
                <p style="width: 310px; line-height: 20px; font-size: 16px; margin-left: 56px">
                  {{ 'Please ask the course admin to grant you access' | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <app-pdf-viewer
          class="pdfContainer"
          *ngIf="
            canvasItem &&
            canvasItem.itemSettings?.wrappedComponent === WrappedComponent.fragment &&
            canvasItem.itemSettings?.resourceComponentInputs?.fragment?.type === fragmentType.PDF
          "
          [canvasItem]="canvasItem"
          [previewBoard]="static"
          (pdfRendered)="updateRelatedFabricObjectSize($event)"
        ></app-pdf-viewer>
        <app-resource-loading-item-canvas
          *ngIf="canvasItem.type === ItemModel.ResourceLoading && !this.static"
          [canvasItem]="canvasItem"
        ></app-resource-loading-item-canvas>
        <app-formula
          *ngIf="canvasItem.type === ItemModel.Formula"
          class="break-inside-avoid"
          [canvasItem]="canvasItem"
          [relatedFabricObject]="canvasItem.relatedFabricItem"
          (fragmentLoadComplete)="updateRelatedFabricObjectSize($event)"
          [style.display]="'inline-block'"
          [style.padding.px]="1.5"
        >
        </app-formula>
        <app-video
          *ngIf="
            canvasItem.itemSettings?.resourceComponentInputs?.fragment?.type ===
            fragmentType.Youtube
          "
          [fragment]="canvasItem.itemSettings?.resourceComponentInputs?.fragment?.fragment"
          [canvasItem]="canvasItem"
          [selected]="interactionsEnabled$ | async"
          [adaptiveWidth]="false"
        >
        </app-video>

        <app-image-item-canvas
          *ngIf="
            canvasItem.itemSettings?.resourceComponentInputs?.fragment?.type === fragmentType.Image
          "
          [canvasItem]="canvasItem"
          [static]="static"
          [width]="canvasItem?.relatedFabricItem.width"
          [height]="canvasItem?.relatedFabricItem.height"
        ></app-image-item-canvas>

        <div
          class="iframe-container"
          *ngIf="
            canvasItem.type === ItemModel.IFrame ||
            canvasItem.type === ItemModel.WebViewer ||
            canvasItem.type === ItemModel.Mario
          "
        >
          <app-iframe
            [canvasItem]="canvasItem"
            [static]="static"
            [selected]="interactionsEnabled$ | async"
            [selectedUsingInteractionsBar]="relatedFabricObject?.selectedUsingInteractionsBar"
          >
          </app-iframe>
        </div>
        <div
          [style.width]="'100%'"
          [style.height]="'100%'"
          *ngIf="canvasItem.type === ItemModel.Chat_GPT"
        >
          <app-chat-gpt
            *ngIf="canvasItem"
            [canvasItem]="canvasItem"
            [selected]="interactionsEnabled$ | async"
          ></app-chat-gpt>
        </div>
        <div
          [style.width]="'100%'"
          [style.height]="'100%'"
          *ngIf="canvasItem.type === ItemModel.RemoteVideo"
        >
          <app-remote-video-item-canvas
            [canvasItem]="canvasItem"
            (sentDeleteItemEvent)="sentDeleteItemEvent.emit($event)"
          ></app-remote-video-item-canvas>
        </div>
      </div>
    </ng-container>
  </div>
</div>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { encodeResourceFilterParams, IFolderFilterParams, ResourceFilterParams } from '../models/params';
import { IFolder, IFolderResponse, IResource, ResourceItemModel } from '../models/resource';
import { URLService } from './dynamic-url.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class ResourcesService {
  baseUrl: string;
  types = ResourceItemModel
  // generate next as true to launch upload resource dialog
  createResourceIntent = new BehaviorSubject(false);

  constructor(private http: HttpClient, private urlService: URLService) {
    this.baseUrl = `${this.urlService.getDynamicUrl()}/tutor/resources`;
  }

  getUserResources(
    page: number,
    resourcePerPage: number,
    filters?: ResourceFilterParams,
  ): Observable<any> {
    let getUrl = `${this.baseUrl}?`;
    if (page) {
      getUrl += `page=${page}&`;
    }
    if (resourcePerPage) {
      getUrl += `num=${resourcePerPage}&`;
    }
    if (filters) {
      getUrl += `filters=${encodeResourceFilterParams(filters)}`;
    }
    return this.http.get(getUrl);
  }

  getResourceSummaryForUser() {
    const getUrl = `${this.urlService.getDynamicUrl()}/tutor/resources/summary`;
    return this.http.get(getUrl);
  }

  getResourceSummaryForInstitution() {
    const getUrl = `${this.urlService.getDynamicUrl()}/tutor/resources/summary/institution`;
    return this.http.get(getUrl);
  }

  createResource(resource) {
    const postUrl = `${this.urlService.getDynamicUrl()}/tutor/resources`;
    const data = { resource };
    return this.http
      .post<{ resource: IResource }>(postUrl, JSON.stringify(data), {
        ...httpOptions,
        observe: 'response',
      })
      .pipe(retry(3));
  }

  deleteResources(resources: string[]) {
    return this.http.request('delete', this.baseUrl, {
      ...httpOptions,
      body: { resources },
    });
  }

  deleteFolders(folders: string[]) {
    return this.http.request('delete', `${this.baseUrl}/delete/bulk`, {
      ...httpOptions,
      body: { folders },
    });
  }

  getResource(resourceId: string) {
    const getUrl = `${this.urlService.getDynamicUrl()}/tutor/resources/${resourceId}`;
    return this.http.get(getUrl);
  }

  editResource(resource) {
    const patchUrl = `${this.urlService.getDynamicUrl()}/tutor/resources/${resource._id.toString()}`;
    return this.http.patch(patchUrl, { resource: resource });
  }

  createFolder(folder: Partial<IFolder>) {
    const postUrl = `${this.urlService.getDynamicUrl()}/tutor/resources/new`;
    return this.http
      .post<IFolder>(postUrl, JSON.stringify(folder), {
        ...httpOptions,
        observe: 'response',
      })
      .pipe(retry(3));
  }

  getFolder(folderId: string, params?: IFolderFilterParams) {
    let getUrl = `${this.urlService.getDynamicUrl()}/tutor/resources/list/${folderId}?`;
    if (params) {
      for (const param in params) {
        if (param === 'filters') {
          getUrl += `${param}=${encodeResourceFilterParams(params[param])}&`
        }
        else if (Object.prototype.hasOwnProperty.call(params, param)) {
          getUrl += `${param}=${String(params[param])}&`;
        }
      }
    }
    return this.http.get<IFolderResponse>(getUrl);
  }

  editResources(updatedResources: any[]) {
    return this.http.patch(`${this.urlService.getDynamicUrl()}/tutor/resources/edit/bulk`, {
      resources: updatedResources,
    });
  }

  editFolders(updatedFolders: any[]) {
    return this.http.patch(`${this.urlService.getDynamicUrl()}/tutor/resources/list/edit/bulk`, {
      folders: updatedFolders,
    });
  }
}

export interface MessageFilterParams {
  questionId?: string;
  noteId?: string;
  worksheetId?: string;
  sessionId?: string;
  courseId?: string;
  institutionId?: string;
  userIds?: string[];
  groupId?: string;
  // Id of canvas item (in case of comments)
  itemId?: string;
  // Id of the room (in case of comments)
  breakoutRoomId?: string;
  // board Id (in case of comments)
  boardId?: string;
  // app id (in case of space apps ex: chat with gpt)
  appId?: string;
}

export const encodeMessageFilterParams = (messageFilterParams: MessageFilterParams): string =>
  btoa(JSON.stringify(messageFilterParams));

export interface QuestionFilterParams {
  query?: string;
  courseId?: string;
  currentQuestion?: string;
  sessionId?: string;
  difficulties?: number[];
  qualities?: number[];
  approvalState?: number[];
  lang?: string;
  draft?: boolean;
  published?: boolean;
  autotyped?: boolean;
  disabled?: boolean;
  openEnded?: boolean;
  approval?: boolean;
  unapproved?: boolean;
  multipleChoice?: boolean;
  explanationPending?: boolean;
  explanationCompleted?: boolean;
  explanationNotRequired?: boolean;
  annotationsPending?: boolean;
  annotated?: boolean;
  hasVideo?: boolean;
  hasImage?: boolean;
  hasPhet?: boolean;
  school?: string;
  grade?: string;
  exam?: string;
  paper?: string;
  year?: number;
  author?: string;
  explAuthor?: string;
  topics?: string[];
  explFilters?: string[];
  sortMethod?: 'timestamp';
  minGrade?: number;
  maxGrade?: number;
  customAttributes?: string[];
  worksheets?: string[];
  visibility?: string[];
  practiceFilters?: string[];
}

export const defaultQuestionFilterParams = (): QuestionFilterParams => ({
  difficulties: [],
  qualities: [],
  approvalState: [],
  topics: [],
  explFilters: [],
  sortMethod: 'timestamp',
  customAttributes: [],
  worksheets: [],
  visibility: [],
  practiceFilters: [],
});

export const getQuestionFilterParamsBase64 = (
  filters: QuestionFilterParams | undefined,
): { filters: string } | Record<string, never> => {
  if (filters) {
    return {
      filters: btoa(JSON.stringify(filters)),
    };
  } else {
    return {};
  }
};

export const encodeQuestionFilterParams = (filters: QuestionFilterParams): string => {
  if (filters) {
    return btoa(JSON.stringify(filters));
  } else {
    return '';
  }
};

export enum ResourceFilterParamsEnum {
  Course = 'courseId',
  Difficulty = 'difficulties',
  Type = 'type',
  Language = 'lang',
  hasPhet = 'hasPhet',
  School = 'school',
  Grade = 'grade',
  Exam = 'exam',
  Paper = 'paper',
  Year = 'year',
  Author = 'author',
  Topics = 'topics',
  SortMethod = 'sortMethod',
  MinGrade = 'minGrade',
  MaxGrade = 'maxGrade',
  Visibility = 'visibility',
  CreatedStart = 'createdStart',
  CreatedEnd = 'createdEnd',
  EditedStart = 'editedStart',
  EditedEnd = 'editedEnd',
  Query = 'query',
  CustomAttributes = 'customAttributes',
  EnforcingFilter = 'enforcingFilter',
}

export interface ResourceFilterParams {
  courseId?: string | string[];
  difficulties?: number[];
  type?: string[];
  lang?: string;
  hasPhet?: boolean;
  school?: string;
  grade?: string;
  exam?: string;
  paper?: string;
  year?: number;
  author?: string[];
  topics?: string[];
  sortMethod?: 'timestamp'; // TODO: Should be some sort of enum?
  minGrade?: number;
  maxGrade?: number;
  visibility?: string[];
  createdStart?: string;
  createdEnd?: string;
  editedStart?: string;
  editedEnd?: string;
  query?: string;
  customAttributes?: string[];
  // Holds the filter that should be enforced enforce (Special cases to apply single filters without flattening files hierarchy).
  enforcingFilter?: ResourceFilterParamsEnum;
}

export const encodeResourceFilterParams = (
  resourceFilterParams: ResourceFilterParams | undefined,
): string => {
  if (!resourceFilterParams) {
    return '';
  }
  return btoa(JSON.stringify(resourceFilterParams));
};

export interface IFolderFilterParams {
  folder_num?: number;
  folder_page?: number;
  resource_page?: number;
  resource_num?: number;
  filters?: Record<string, any>;
}

<div class="top-section">
  <div class="header-section">
    <button mat-icon-button class="header-button-right header-button" (click)="close()">
      <div class="material-icons">close</div>
    </button>
    <div class="text-center header-text">{{ 'You rock' | translate }}, {{ data?.user?.name }}!</div>
  </div>
  <div class="d-block text-center body-margin">
    <img
      src="assets/imgs/refer-dialog-bg.png"
      class="d-block mx-auto bottom-margin"
      alt="refer background"
      height="84"
    />
    <div class="font-18 fw-6 text-center cmy-20">
      {{ data?.referredUser?.name || data?.referredUser?.email }} {{ 'just went Pro!' | translate }}
    </div>
    <div class="text-dark text-center font-14 cmb-8">
      {{ 'Thanks to you' | translate }},
      {{ data?.referredUser?.name || data?.referredUser?.email }}
      {{
        "just signed up to Pencil Spaces Pro. We couldn't have done it without you - and we mean it!"
          | translate
      }}
    </div>
    <div class="text-dark text-center font-14">
      {{ "In fact, as a way of saying thanks, we're giving you 1 month access to" | translate }}
      <strong>{{ 'Pencil Spaces Pro' | translate }}</strong
      >. {{ 'You earned it.' | translate }}
    </div>
    <div class="d-flex flex-column">
      <button
        mat-button
        class="referral-email-button cmt-20"
        data-name="referral-send-emails-btn"
        (click)="shareReferral()"
      >
        <span class="material-icons-outlined cmr-8 font-18">person_add</span>
        <span>{{ 'Refer more friends' | translate }}</span>
      </button>
    </div>
  </div>
</div>

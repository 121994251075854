import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapObjectsToField',
})
export class MapObjectsToFieldPipe implements PipeTransform {
  transform(inputArray: any[], fieldName: string): any[] {
    if (!inputArray || !fieldName) {
      return [];
    }
    return inputArray.map((input) => input[fieldName]);
  }
}

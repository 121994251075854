import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { BehaviorSubject, map } from 'rxjs';
import * as Sentry from '@sentry/browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PreviewItem } from '../pdf-preview-selector/pdf-preview-selector.component';
import { IconTypes } from '../../../../../standalones/components/pencil-icon/pencil-icon.component';
import { UploadFileComputerService } from '../../../../../services/upload-file-computer.service';
import { MAX_PDF_PAGES } from '../../../../../models/upload';

@UntilDestroy()
@Component({
  selector: 'app-load-pdf-and-preview',
  templateUrl: './load-pdf-and-preview.component.html',
  styleUrls: ['./load-pdf-and-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadPdfAndPreviewComponent {
  @Output() submitSelectedIndices = new EventEmitter<number[]>();
  @Output() cancelSubmit = new EventEmitter<void>();

  isLoading$ = new BehaviorSubject<boolean>(true);
  pageItems: PreviewItem[] = [];

  private _pagesPrepared = new BehaviorSubject(0);
  progressValue$ = this._pagesPrepared.pipe(
    map((pagesPrepared) => (pagesPrepared / this.totalPageCount) * 100),
  );

  readonly fileName$ = this.uploadFileComputerService.rawFile$.pipe(
    untilDestroyed(this),
    map((file) => file?.name ?? ''),
  );

  protected readonly IconTypes = IconTypes;

  constructor(private uploadFileComputerService: UploadFileComputerService) {
    this.uploadFileComputerService.pdfToUpload$
      .pipe(untilDestroyed(this))
      .subscribe(async (pdfToUpload) => this.loadPDFPages(pdfToUpload));
  }

  protected get totalPageCount(): number {
    return this.uploadFileComputerService.pdfToUpload?.numPages ?? MAX_PDF_PAGES;
  }

  private async loadPDFPages(pdfToUpload: PDFDocumentProxy | undefined): Promise<void> {
    if (!pdfToUpload) {
      return;
    }
    this._pagesPrepared.next(0);
    this.isLoading$.next(true);
    try {
      const loadingPagesPromises = Array.from({ length: pdfToUpload.numPages }, (_, index) =>
        this.loadPdfPage(pdfToUpload, index + 1),
      );
      this.pageItems = await Promise.all(loadingPagesPromises);
    } catch (err) {
      Sentry.captureException(err);
      this.cancelSubmit.emit();
      await this.uploadFileComputerService.showFailedToProcessPDFNotification();
    }
    this.isLoading$.next(false);
  }

  private async loadPdfPage(pdf: PDFDocumentProxy, pageNumber: number): Promise<PreviewItem> {
    const pdfPage = await pdf.getPage(pageNumber);
    this.incrementLoading();
    return {
      isSelected: false,
      pdfPage,
    };
  }

  private incrementLoading() {
    this._pagesPrepared.next(this._pagesPrepared.value + 1);
  }

  selectedIndicesSubmitted(selectedIndices: number[]) {
    this.submitSelectedIndices.emit(selectedIndices);
  }

  cancelClicked() {
    this.cancelSubmit.emit();
  }
}

<span id="session-statistics" *ngIf="currentSession">
  <div class="pt-2 d-flex justify-content-between align-items-center">
    <span id="session-title-wrapper" class="d-flex align-items-center">
      <div
        id="session-title"
        [ngClass]="{ selected: isEditingSessionName }"
        (keydown.enter)="editSessionName()"
        (keydown)="preventNewLine($event); $event.stopPropagation()"
        (keydown.esc)="cancelEditSessionName()"
        (keyup)="isSessionTitleValid($event)"
        #sessionTitleElement
      >
        {{ currentSession.parsedSessionTitle }}
        <ng-template #invalidSessionTitleTooltip>
          <app-tooltip
            [text]="'Session title should be maximum 50 characters' | translate"
            [arrow]="'bottom'"
          ></app-tooltip>
        </ng-template>
      </div>
      <svg-icon
        *ngIf="!isEditingSessionName"
        [icon]="'edit_pencil'"
        [width]="20"
        [height]="20"
        class="edit-session-button"
        (click)="onEditSessionName()"
        [ngbTooltip]="editSessionTooltip"
        placement="'top'"
      >
        <ng-template #editSessionTooltip>
          <app-tooltip [text]="'Rename Session' | translate" [arrow]="'bottom'"></app-tooltip>
        </ng-template>
      </svg-icon>
      <div *ngIf="isEditingSessionName" class="rename-session-btn-grp">
        <div
          [ngbTooltip]="isSessionTitleInvalid ? invalidSessionTitleTooltip : null"
          placement="'top'"
        >
          <ng-template #invalidSessionTitleTooltip>
            <app-tooltip
              [text]="'Session title should be maximum 50 characters' | translate"
              [arrow]="'bottom'"
            ></app-tooltip>
          </ng-template>
          <button
            class="btn confirm-rename-session"
            (click)="editSessionName()"
            [disabled]="isSessionTitleInvalid"
          >
            Done
          </button>
        </div>
        <button class="btn cancel-rename-session" (click)="cancelEditSessionName()">Cancel</button>
      </div>
    </span>
    <button
      *ngIf="currentSession.parsedCurrentSessionStatus === SessionStatus.IN_PROGRESS"
      class="btn end-ongoing-session"
      (click)="handleEndOngoingSession($event)"
    >
      <svg-icon
        class="end-ongoing-session-icon row-content-icon"
        [icon]="'stop-circle-black'"
        [width]="18"
        [height]="18"
      ></svg-icon>
      {{ 'End Session' | translate }}
    </button>
  </div>
  <div *ngIf="currentSession.parsedCurrentSessionStatus === SessionStatus.IN_PROGRESS" class="row">
    <div class="col-md-auto" style="color: #2f80ed">{{ 'In Progress' | translate }}</div>
    <div class="col-md-auto row-content d-flex align-items-center" style="color: #4f4f4f">
      <svg-icon
        class="row-content-icon"
        id="timer-icon"
        [icon]="'timer'"
        [width]="16"
        [height]="16"
      ></svg-icon>
      {{ 'Note: Analytics will be available when session ends' | translate }}
    </div>
  </div>

  <div *ngIf="currentSession.parsedCurrentSessionStatus === SessionStatus.PROCESSING" class="row">
    <div class="col-md-auto" style="color: #2f80ed">{{ 'Processing' | translate }}</div>
    <div class="col-md-auto row-content d-flex align-items-center" style="color: #4f4f4f">
      <svg-icon
        class="row-content-icon"
        id="timer-icon"
        [icon]="'timer'"
        [width]="16"
        [height]="16"
      ></svg-icon>
      {{ 'Note: Session Analytics is currently processing' | translate }}
    </div>
  </div>

  <div *ngIf="currentSession.parsedCurrentSessionStatus === SessionStatus.PROCESSED" class="row">
    <div
      class="col-md-auto row-content d-flex align-items-center"
      [ngbTooltip]="sessionLengthTooltip"
    >
      <svg-icon
        class="row-content-icon"
        [icon]="'timer_grey'"
        [width]="24"
        [height]="24"
      ></svg-icon>
      <span class="duration">{{ currentSession.parsedSessionLength }}</span
      >&nbsp;<span class="analysis-category">{{ 'session' | translate }}</span>
    </div>
    <ng-template #sessionLengthTooltip>
      <app-tooltip
        [text]="'Session started at ' + currentSession.parsedSessionStartTime | translate"
        [arrow]="'top'"
      ></app-tooltip>
    </ng-template>
    <div class="col-md-auto row-content d-flex align-items-center">
      <span id="call-icon" class="material-icons row-content-icon font-24">call</span>
      <span class="duration">{{
        currentSession.parsedCallLength || NO_CALLS_TEXT | translate | translate
      }}</span>
      <span *ngIf="currentSession.parsedCallLength" class="analysis-category"
        >&nbsp;{{ 'call' | translate }}</span
      >
    </div>
    <div class="col-md-auto row-content d-flex align-items-center">
      <svg-icon
        class="row-content-icon"
        [icon]="'people_outline'"
        [width]="24"
        [height]="24"
      ></svg-icon>
      <span class="duration">{{ currentSession.numberOfAttendees }}</span
      >&nbsp;
      <span class="analysis-category">{{
        currentSession.numberOfAttendees === 1
          ? ('attendee' | translate)
          : ('attendees' | translate)
      }}</span>
    </div>
    <div *ngIf="enterpriseSpace" class="col-md-auto row-content d-flex align-items-center">
      <svg-icon class="row-content-icon" [icon]="'bill'" [width]="24" [height]="24"></svg-icon>
      <span class="duration">{{
        currentSession.parsedBillableTimeLength || NO_BILLABLE_TIME_TEXT
      }}</span
      >&nbsp;
      <span class="analysis-category">{{ 'billed' | translate }}</span>
    </div>
  </div>
</span>

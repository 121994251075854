import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, defaultIfEmpty, distinctUntilChanged, forkJoin, from, take } from 'rxjs';
import { RTCDevices } from 'src/app/common/interfaces/rtc-interface';
import { PROMPTS } from 'src/app/common/utils/call-states-constants';
import {
  DeviceType,
  DevicesHandleUtil,
  TIMEOUT_GET_USER_MEDIA_CALL,
} from 'src/app/common/utils/devices-handle-util';
import { intercomArticlesIds } from 'src/app/intercom-articles';
import { AudioService } from 'src/app/services/audio.service';
import { DeviceErrorsNotificationsService } from 'src/app/services/device-errors-notifications.service';
import { DeviceState, DevicesManagerService } from 'src/app/services/devices-manager.service';
import { DomListenerFactoryService } from 'src/app/services/dom-listener-factory.service';
import { RtcServiceController } from 'src/app/services/rtc.service';
import { UiService } from 'src/app/services/ui.service';
import { DomListener } from 'src/app/utilities/DomListener';
import { environment } from 'src/environments/environment';
import { Intercom } from 'ng-intercom';
import { DeviceAndBrowserDetectorService } from 'src/app/services/device-and-browser-detector.service';
import { GUIDES } from 'src/app/dialogs/steps-guide/steps-guide.component';
import {
  DEFAULT_DEVICE_STATE,
  DeviceErrorType,
  VIDEO_STREAM_CAPTURE_HEIGHT,
  VIDEO_STREAM_CAPTURE_WIDTH,
} from 'src/app/models/device-manger';
import { DeviceErrorHelperModelBase } from 'src/app/common/utils/device-error-helper/device-error-helper-model-base';
import { NoInputHelper } from 'src/app/common/utils/device-error-helper/no-input-helper';
import { NotFoundHelper } from 'src/app/common/utils/device-error-helper/not-found-helper';
import { PermissionHelper } from 'src/app/common/utils/device-error-helper/permission-helper';
import { TranslateService } from '@ngx-translate/core';
import { DEVICE } from 'src/app/sessions/panel/participants-manager/participants-manager.component';
import { VirtualBackgroundInsertableStreamService } from 'src/app/services/virtual-background-insertable-stream.service';
import { SessionSharedDataService } from 'src/app/services/session-shared-data.service';
import { BlockByOSHelper } from 'src/app/common/utils/device-error-helper/block-by-os-helper';
import { modifiedSetInterval } from 'src/app/utilities/ZoneUtils';
import { LocalTracksManagerService } from 'src/app/services/local-tracks-manager.service';
import { cloneDeep } from 'lodash';
import { TelemetryService } from 'src/app/services/telemetry.service';
import { filterNil } from '@ngneat/elf';

export enum DevicesType {
  MICS = 'mics',
  CAMS = 'cams',
  SPEAKERS = 'speakers',
}

@UntilDestroy()
@Component({
  template: '',
})
export abstract class DevicesSettingsBaseComponent implements OnInit, OnDestroy {
  domListener: DomListener;

  readonly VOLUME_INTERVAL_FPS = 100;
  readonly troubleshootArticleId =
    intercomArticlesIds.SettingUpYourVideoAndMicrophonePermissionsInSpacesId;
  readonly camTroubleshootId = intercomArticlesIds.TroubleshootingCameraPermissionsId;
  readonly micTroubleshootId = intercomArticlesIds.TroubleshootingMicrophoneSpeakerPermissionsId;
  readonly isNoiseCancellationSupported =
    this.rtcServiceController.service.isNoiseCancellationSupported();

  // this is if the real device is desktop
  isDesktop = false;
  // this is depending on the screen size
  isMobile = false;

  // this is the dropdown value for the devices
  // with the active devices IDS
  devices: RTCDevices = {
    cameras: [],
    mics: [],
    speakers: [],
    activeCameraId: null,
    activeMicId: null,
    activeSpeakerId: null,
  };

  enableNoiseCancellation = this.rtcServiceController.service.isNoiseCancellationEnabled;

  isLoadingDevices = true;
  readonly LOADING = 'loading';
  selectedCamMatSelect = this.LOADING;
  selectedMicMatSelect = this.LOADING;
  selectedSpeakerMatSelect = this.LOADING;

  // these variables are ment to be readonly as any change to them should go throw the local track manager service
  readonly camState: Readonly<DeviceState> = this.localTrackManagerService.getCamDeviceState();
  readonly micState: Readonly<DeviceState> = this.localTrackManagerService.getMicDeviceState();

  volumeInterval: NodeJS.Timer | null = null;
  audioAnalyzer: AnalyserNode | null = null;

  helperGuide: DeviceErrorHelperModelBase | undefined;
  guideLoading = false;

  // to make sure not to execute any code after the component is destroyed
  isComponentDestroyed = false;

  // permission status change listeners
  camPermissionStatusChangeListener?: any;
  micPermissionStatusChangeListener?: any;
  camPermissionStatus?: any;
  micPermissionStatus?: any;

  @ViewChild('deviceVideoPreview') videoElement!: { nativeElement: HTMLVideoElement };

  deviceChangeListener: EventListener | null = null;

  constructor(
    private domListenerFactoryService: DomListenerFactoryService,
    public deviceDetectorService: DeviceDetectorService,
    public uiService: UiService,
    public rtcServiceController: RtcServiceController,
    public devicesManagerService: DevicesManagerService,
    protected ngZone: NgZone,
    private deviceAndBrowserDetectorService: DeviceAndBrowserDetectorService,
    private deviceErrorsNotificationsService: DeviceErrorsNotificationsService,
    protected audioService: AudioService,
    private intercom: Intercom,
    public currentPrompt: PROMPTS,
    protected translateService: TranslateService,
    protected virtualBackgroundInsertableStreamService: VirtualBackgroundInsertableStreamService,
    protected sessionSharedDataService: SessionSharedDataService,
    protected localTrackManagerService: LocalTracksManagerService,
    private telemetryService: TelemetryService,
    private handleAudioDetectionStatus: Boolean,
  ) {
    this.domListener = this.domListenerFactoryService.createInstance();
    this.isDesktop = this.deviceDetectorService.isDesktop();
  }

  async ngOnInit() {
    this.setupKeyListenerForClosingModal();
    this.uiService.isMobile.pipe(untilDestroyed(this)).subscribe((res) => {
      this.isMobile = res;
    });
    const shouldShowPrompt = await this.shouldShowPromptLayout();
    if (shouldShowPrompt) {
      this.devicesManagerService.logPermissionsModalShownToFullStory();
      this.setDevicesStateToPermissionDeniedError();
      this.currentPrompt = PROMPTS.FIRST_TIME_JOINING;
    } else {
      // this means we will not show the prompt layout
      // so we will show normal call layout and we need to
      // setup the call video and audio
      this.getAvailableStreams();
    }
  }

  ngAfterViewInit(): void {
    this.deviceChangeListener = this.devicesManagerService.subscribeToDeviceChange(() => {
      this.getDevices(true);
    });

    this.listenToStreamsChange();
  }

  ngOnDestroy(): void {
    this.isComponentDestroyed = true;
    this.domListener.clear();
    if (this.volumeInterval) {
      clearInterval(this.volumeInterval);
    }
    this.camPermissionStatus?.removeEventListener('change', this.camPermissionStatusChangeListener);
    this.micPermissionStatus?.removeEventListener('change', this.micPermissionStatusChangeListener);
    this.devicesManagerService.unsubscribeFromDeviceChange(this.deviceChangeListener);
  }

  protected async acquireVideoStream() {
    try {
      await this.localTrackManagerService.acquireVideoStream();
    } catch (e) {
      // empty catch block as the error state will be captured on the device state
    }
  }

  protected async acquireAudioStream() {
    try {
      await this.localTrackManagerService.acquireAudioStream();
    } catch (e) {
      // empty catch block as the error state will be captured on the device state
    }
  }

  abstract closeDialog(): void;
  abstract getComponentPromptLayout(): PROMPTS;

  private listenToStreamsChange() {
    this.localTrackManagerService
      .getAudioStream$()
      .pipe(untilDestroyed(this), distinctUntilChanged(), filterNil())
      .subscribe((audioStream) => {
        this.playAudioStream(audioStream);
      });
    this.localTrackManagerService
      .getVideoStream$()
      .pipe(untilDestroyed(this), distinctUntilChanged(), filterNil())
      .subscribe((videoStream) => {
        this.playVideoStream(videoStream);
      });
  }

  private setDevicesStateToPermissionDeniedError() {
    const newDevicesState = cloneDeep(DEFAULT_DEVICE_STATE);
    newDevicesState.hasError = true;
    newDevicesState.errorType = DeviceErrorType.PERMISSION_DENIED;
    newDevicesState.isGettingStream = false;
    DevicesHandleUtil.onGettingStreamFinished(newDevicesState);
    this.isLoadingDevices = false;
    this.localTrackManagerService.updateCamDeviceState(newDevicesState);
    this.localTrackManagerService.updateMicDeviceState(newDevicesState);
  }

  private setupKeyListenerForClosingModal() {
    this.domListener.add(
      'document',
      'keyup.esc',
      () => {
        this.closeDialog();
      },
      true,
    );
  }

  private async shouldShowPromptLayout(): Promise<boolean> {
    try {
      const camPermissionStatus = await navigator.permissions.query({
        name: 'camera' as PermissionName,
      });

      const micPermissionStatus = await navigator.permissions.query({
        name: 'microphone' as PermissionName,
      });

      if (camPermissionStatus.state === 'prompt' && micPermissionStatus.state === 'prompt') {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  private async getAvailableStreams() {
    // If we are allowed to get both streams, we will try firstly the merged request for both devices
    // to make the user only allow permission one time for both devices
    // Then, we will stop the acquired streams if succeeded and do acquire individual stream for each device
    if (
      !this.localTrackManagerService.isVideoTrackAcquired() &&
      !this.localTrackManagerService.isAudioTrackAcquired()
    ) {
      try {
        const streams = await this.getUserMediaWithTimeout(
          {
            video: {
              deviceId: this.localTrackManagerService.getVideoDeviceId(),
              width: { ideal: VIDEO_STREAM_CAPTURE_WIDTH },
              height: { ideal: VIDEO_STREAM_CAPTURE_HEIGHT },
            },
            audio: { deviceId: this.localTrackManagerService.getAudioDeviceId() },
          },
          TIMEOUT_GET_USER_MEDIA_CALL,
        );

        // close the tracks to acquire them individually
        streams.getTracks().forEach((trakc) => trakc.stop());
      } catch (e: unknown) {
        // To show the exact join call modal in case of granting permissions failure
        this.hidePromptLayoutIfExist();
      }
    }

    // Using await only for Firefox as we need enabled streams to return devices list in case of temporarily permissions
    // This will speed up other browsers as they don't care about this case
    if (this.deviceAndBrowserDetectorService.isFirefox()) {
      await this.handleGettingIndividualStreams();
    } else {
      this.handleGettingIndividualStreams();
    }
    this.getDevices();
    this.autoDetectionPermissionsChange();
  }

  private getUserMediaWithTimeout(
    constraints: MediaStreamConstraints,
    timeout: number,
  ): Promise<MediaStream> {
    return Promise.race<MediaStream>([
      navigator.mediaDevices.getUserMedia(constraints),
      new Promise<MediaStream>((_, reject) =>
        setTimeout(() => reject(new Error('getUserMedia timeout')), timeout),
      ),
    ]);
  }

  private getDevices(isDevicesChanged = false) {
    this.rtcServiceController.getDevices().then((devices) => {
      this.setupDevices(devices, isDevicesChanged);
    });
  }

  private setupDevices(devices: RTCDevices, isDevicesChanged = false) {
    this.showLoadingUI();

    this.devices = devices;
    this.eliminateDevicesWithNoLabelOrId();

    this.setCurrentCamDevice(isDevicesChanged);
    this.setCurrentMicDevice(isDevicesChanged);
    this.setCurrentSpeakerDevice();

    this.handleSavedDeviceNotFound();

    this.handleNoProvidedSpeakerDevice();
    this.setCurrentActiveDevicesInTheDropDown();

    this.isLoadingDevices = false;

    this.handleSpecialDevicesErrorState();
  }

  private hidePromptLayoutIfExist() {
    if (this.currentPrompt === PROMPTS.FIRST_TIME_JOINING) {
      this.currentPrompt = this.getComponentPromptLayout();
    }
  }

  private showLoadingUI() {
    this.isLoadingDevices = true;
    this.selectedCamMatSelect = this.LOADING;
    this.selectedMicMatSelect = this.LOADING;
    this.selectedSpeakerMatSelect = this.LOADING;
  }

  private eliminateDevicesWithNoLabelOrId() {
    this.devices.cameras = this.devices.cameras.filter((camera) => camera.deviceId && camera.label);
    this.devices.mics = this.devices.mics.filter((mic) => mic.deviceId && mic.label);
    this.devices.speakers = this.devices.speakers.filter(
      (speaker) => speaker.deviceId && speaker.label,
    );
  }

  private setCurrentCamDevice(isDevicesChanged: boolean) {
    if (this.devices.cameras.length > 0) {
      const cameraDeviceId = this.localTrackManagerService.getVideoDeviceId();
      if (this.devices.cameras.filter((cam) => cam.deviceId === cameraDeviceId).length != 0) {
        this.devices.activeCameraId = cameraDeviceId;
        this.acquireVideoStream();
      } else {
        this.devices.activeCameraId = this.devices.cameras[0].deviceId;
        if (isDevicesChanged) {
          this.setCameraPreview(this.devices.cameras[0].deviceId);
        }
      }
    }
  }

  private setCurrentMicDevice(isDevicesChanged: boolean) {
    if (this.devices.mics.length > 0) {
      const micDeviceId = this.localTrackManagerService.getAudioDeviceId();
      if (this.devices.mics.filter((mic) => mic.deviceId === micDeviceId).length != 0) {
        this.devices.activeMicId = micDeviceId;
        this.acquireAudioStream();
      } else {
        this.devices.activeMicId = this.devices.mics[0].deviceId;
        if (isDevicesChanged) {
          this.setMicPreview(this.devices.mics[0].deviceId);
        }
      }
    }
  }

  private setCurrentSpeakerDevice() {
    if (this.devices.speakers.length > 0) {
      const defaultSpeakerId = localStorage.getItem('defaultSpeakerId');
      if (
        defaultSpeakerId !== null &&
        this.devices.speakers.filter((speakers) => speakers.deviceId === defaultSpeakerId).length !=
          0
      ) {
        this.devices.activeSpeakerId = defaultSpeakerId;
      } else {
        this.setSpeakerPreview(this.devices.speakers[0].deviceId);
      }
    }
  }

  // In case of the saved device isn't found, but the returned devices list have another devices
  private handleSavedDeviceNotFound() {
    if (
      this.camState.hasError &&
      this.camState.errorType === DeviceErrorType.NOT_FOUND &&
      this.devices.cameras.length > 0
    ) {
      this.acquireVideoStream();
    }

    if (
      this.micState.hasError &&
      this.micState.errorType === DeviceErrorType.NOT_FOUND &&
      this.devices.mics.length > 0
    ) {
      this.acquireAudioStream();
    }
  }

  // As for certain browsers we don't have any error with the speaker, but enumerateDevices() doesn't return any speaker device.
  private handleNoProvidedSpeakerDevice() {
    if (this.devices.speakers && this.devices.speakers.length === 0) {
      this.selectedSpeakerMatSelect = 'default';
    }
  }

  private setCurrentActiveDevicesInTheDropDown() {
    if (this.devices.activeCameraId) {
      this.selectedCamMatSelect = this.devices.activeCameraId;
    }
    if (this.devices.activeMicId) {
      this.selectedMicMatSelect = this.devices.activeMicId;
    }
    if (this.devices.activeSpeakerId) {
      this.selectedSpeakerMatSelect = this.devices.activeSpeakerId;
    }
  }

  private async playVideoStream(videoStream: MediaStream | null) {
    try {
      this.videoElement.nativeElement.srcObject = videoStream;
      this.telemetryService.event(`[start playing video] ${this.currentPrompt.toString()}`);
      await this.videoElement.nativeElement.play();
      this.telemetryService.event(`[done playing video] ${this.currentPrompt.toString()}`);
    } catch (camError) {
      /* empty catch block */
    }
    this.handleSpecialDevicesErrorState();
  }

  private async playAudioStream(audioStream: MediaStream | null) {
    try {
      if (this.volumeInterval) {
        clearInterval(this.volumeInterval);
      }
      if (this.handleAudioDetectionStatus && audioStream) {
        this.handleAudioDetection(audioStream);
      }
    } catch (micError) {
      /* empty catch block */
    }
    this.handleSpecialDevicesErrorState();
  }

  private handleAudioDetection(audioStream: MediaStream) {
    const audioContext = new AudioContext();
    const audioSource = audioContext.createMediaStreamSource(audioStream);
    this.audioAnalyzer = audioContext.createAnalyser();
    this.audioAnalyzer.smoothingTimeConstant = 0.3;
    this.audioAnalyzer.fftSize = 512;
    this.audioAnalyzer.minDecibels = -127;
    this.audioAnalyzer.maxDecibels = 0;
    audioSource.connect(this.audioAnalyzer);

    const frequencyData = new Uint8Array(this.audioAnalyzer.frequencyBinCount);

    // Turn off uncessary Change detection from setInterval
    this.ngZone.runOutsideAngular(() => {
      this.volumeInterval = modifiedSetInterval(
        this.checkAudioVolume.bind(this, frequencyData),
        this.VOLUME_INTERVAL_FPS,
      );
    });
  }

  private checkAudioVolume(frequencyData: Uint8Array) {
    this.audioAnalyzer?.getByteFrequencyData(frequencyData);
    const adjustedVolume = Math.floor(
      ((frequencyData.reduce((acc: any, curr: any) => acc + curr) / frequencyData.length) * 100) /
        127,
    );
    const newMicIsOnValue = adjustedVolume > 10 ? true : false;
    if (newMicIsOnValue !== this.micState.state) {
      this.ngZone.run(() => {
        if (adjustedVolume < 10) {
          this.localTrackManagerService.updateMicDeviceState({ ...this.micState, state: false });
        } else {
          this.localTrackManagerService.updateMicDeviceState({ ...this.micState, state: true });
        }
      });
    }
  }

  private async handleGettingIndividualStreams() {
    return Promise.all([this.acquireVideoStream(), this.acquireAudioStream()]);
  }

  private async autoDetectionPermissionsChange() {
    // It's not supported by Safari. So, it's surrounded by a try/catch
    try {
      await this.autoDetectCamPermissionChange();
      await this.autoDetectMicPermissionChange();
    } catch (err) {
      if (!environment.production) {
        console.log(err);
      }
    }
  }

  private async autoDetectCamPermissionChange() {
    if (!this.camPermissionStatusChangeListener) {
      this.camPermissionStatus = await navigator.permissions.query({
        name: 'camera' as PermissionName,
      });

      this.camPermissionStatusChangeListener = () => {
        this.ngZone.run(async () => {
          if (!this.camState.isGettingStream) {
            // close the track as a cleanup because closing the permission event can fire before the track being marked as ended
            // which will prevent re-aquiring the stream
            this.localTrackManagerService.closeVideoTrack();
            await this.acquireVideoStream();
            if (!this.camState.hasError) {
              this.getDevices();
            }
          }
        });
      };

      this.camPermissionStatus.addEventListener('change', this.camPermissionStatusChangeListener);
    }
  }

  private async autoDetectMicPermissionChange() {
    if (!this.micPermissionStatusChangeListener) {
      this.micPermissionStatus = await navigator.permissions.query({
        name: 'microphone' as PermissionName,
      });

      this.micPermissionStatusChangeListener = () => {
        this.ngZone.run(async () => {
          // close the track as a cleanup because closing the permission event can fire before the track ended
          // which will prevent re-aquiring the stream
          this.localTrackManagerService.closeAudioTrack();
          await this.acquireAudioStream();
          if (!this.micState.hasError) {
            this.getDevices();
          }
        });
      };
      this.micPermissionStatus.addEventListener('change', this.micPermissionStatusChangeListener);
    }
  }

  troubleShootDevice(deviceErrorType?: DeviceErrorType, troubleshootArticleId?: string) {
    switch (deviceErrorType) {
      case DeviceErrorType.PERMISSION_DENIED:
        this.openHelperGuide(GUIDES.PERMISSION_DENIED);
        break;
      case DeviceErrorType.PERMISSION_DENIED_BY_SYSTEM:
        this.openHelperGuide(GUIDES.PERMISSION_DENIED_BY_SYSTEM);
        break;
      case DeviceErrorType.NO_INPUT_DETECTED:
        this.openHelperGuide(GUIDES.NO_INPUT);
        break;
      case DeviceErrorType.NOT_FOUND:
        this.openHelperGuide(GUIDES.NOT_FOUND);
        break;
      default:
        if (troubleshootArticleId) {
          (window as any).Intercom('showArticle', troubleshootArticleId);
        }
    }
  }

  troubleshootMic() {
    this.troubleShootDevice(this.micState.errorType, this.micTroubleshootId);
  }

  troubleshootCam() {
    this.troubleShootDevice(this.camState.errorType, this.camTroubleshootId);
  }

  troubleshootPermissionDenied(errorType: DeviceErrorType) {
    if (errorType === DeviceErrorType.PERMISSION_DENIED_BY_SYSTEM) {
      this.helperGuide = this.getHelper(GUIDES.PERMISSION_DENIED_BY_SYSTEM);
    } else {
      this.helperGuide = this.getHelper(GUIDES.PERMISSION_DENIED);
    }
    this.currentPrompt = PROMPTS.HELPER_GUIDE;
  }

  onCloseGuide() {
    this.helperGuide?.dismissErrorNotification();
    this.currentPrompt = this.getComponentPromptLayout();
  }

  onDoneGuide() {
    this.guideLoading = true;
    this.reEstablishFailedStreams()
      .pipe(defaultIfEmpty(null))
      .pipe(take(1))
      .subscribe(() => {
        if (this.helperGuide?.hasRelatedError(this.camState, this.micState)) {
          this.helperGuide?.showErrorNotification(this.camState, this.micState);
        } else {
          this.onCloseGuide();
        }

        this.guideLoading = false;
      });
  }

  private reEstablishFailedStreams(): Observable<void[]> {
    const streams: Observable<void>[] = [];
    if (this.camState.hasError) {
      DevicesHandleUtil.onGettingStream(this.camState);
      const playVideo = async () => {
        await this.acquireVideoStream();
        this.getDevices();
      };
      streams.push(from(playVideo()));
    }
    if (this.micState.hasError) {
      DevicesHandleUtil.onGettingStream(this.micState);
      const playAudio = async () => {
        await this.acquireAudioStream();
        this.getDevices();
      };
      streams.push(from(playAudio()));
    }
    return forkJoin(streams);
  }

  async setCameraPreview(id: string) {
    await this.localTrackManagerService.changeVideoDeviceId(id);
  }

  async setMicPreview(id: string) {
    if (this.volumeInterval) {
      clearInterval(this.volumeInterval);
    }
    await this.localTrackManagerService.changeAudioDeviceId(id);
  }

  async setSpeakerPreview(id: string | null) {
    if (id === null) {
      return;
    } else {
      localStorage.setItem('defaultSpeakerId', id);
      this.devices.activeSpeakerId = id;
      this.audioService.setActiveSpeakerId(id);
      if (this.rtcServiceController.service.isConnected()) {
        // as we already set the speaker when joining the call
        const setDevicesObj: any = { speakerId: id };
        await this.rtcServiceController.service.setDevices(setDevicesObj);
      }
    }
  }

  requestPermission() {
    // To show the exact join call modal after granting the permissions
    this.currentPrompt = this.getComponentPromptLayout();
    this.getAvailableStreams();
  }

  hideRequestPermissionGuideLayout() {
    this.currentPrompt = this.getComponentPromptLayout();
  }

  getDeviceNameById(id: string, type: any): string | undefined {
    let deviceName: string | undefined;
    switch (type) {
      case DevicesType.CAMS:
        deviceName = this.devices.cameras.find((el) => el.deviceId === id)?.label;
        break;
      case DevicesType.MICS:
        deviceName = this.devices.mics.find((el) => el.deviceId === id)?.label;
        break;
      case DevicesType.SPEAKERS:
        deviceName = this.devices.speakers.find((el) => el.deviceId === id)?.label;
        break;
      default:
        deviceName = 'Default';
    }
    return deviceName ? deviceName : 'Default';
  }

  troubleshoot() {
    (window as any).Intercom('showArticle', this.troubleshootArticleId);
  }

  liveSupport() {
    this.intercom.show();
  }

  openHelperGuide(guide: GUIDES) {
    this.helperGuide = this.getHelper(guide);
    this.currentPrompt = PROMPTS.HELPER_GUIDE;
  }

  private getHelper(guide: GUIDES | undefined): DeviceErrorHelperModelBase {
    switch (guide) {
      case GUIDES.NO_INPUT:
        return new NoInputHelper(
          this.deviceAndBrowserDetectorService,
          this.translateService,
          this.deviceErrorsNotificationsService,
          this.getDeviceWithError(DeviceErrorType.NO_INPUT_DETECTED),
        );
      case GUIDES.NOT_FOUND:
        return new NotFoundHelper(
          this.deviceAndBrowserDetectorService,
          this.translateService,
          this.deviceErrorsNotificationsService,
          this.getDeviceWithError(DeviceErrorType.NOT_FOUND),
        );
      case GUIDES.PERMISSION_DENIED_BY_SYSTEM:
        return new BlockByOSHelper(
          this.deviceAndBrowserDetectorService,
          this.translateService,
          this.deviceErrorsNotificationsService,
          this.getDeviceWithError(DeviceErrorType.PERMISSION_DENIED_BY_SYSTEM),
        );
      default:
        return new PermissionHelper(
          this.deviceAndBrowserDetectorService,
          this.translateService,
          this.deviceErrorsNotificationsService,
          this.getDeviceWithError(DeviceErrorType.PERMISSION_DENIED),
        );
    }
  }

  private getDeviceWithError(deviceError: DeviceErrorType): DeviceType {
    return this.camState.errorType === deviceError ? DeviceType.VIDEO : DeviceType.AUDIO;
  }

  private handleSpecialDevicesErrorState() {
    // this is to handle a special case on firefox
    // when another app acquire the video
    // it issues a default error
    if (this.camState.hasError && this.camState.errorType === DeviceErrorType.DEFAULT_ERROR) {
      this.devicesManagerService.logDeviceStateWithDefaultErrorToFullStory(DEVICE.CAM);

      if (this.devices.cameras.length > 0 && this.deviceAndBrowserDetectorService.isFirefox()) {
        const newCamState: DeviceState = cloneDeep(this.camState);
        newCamState.errorType = DeviceErrorType.NO_INPUT_DETECTED;
        this.localTrackManagerService.updateCamDeviceState(newCamState);
      }
    }
    if (this.micState.hasError && this.micState.errorType === DeviceErrorType.DEFAULT_ERROR) {
      this.devicesManagerService.logDeviceStateWithDefaultErrorToFullStory(DEVICE.MIC);

      if (this.devices.mics.length > 0 && this.deviceAndBrowserDetectorService.isFirefox()) {
        const newMicState: DeviceState = cloneDeep(this.micState);
        newMicState.errorType = DeviceErrorType.NO_INPUT_DETECTED;
        this.localTrackManagerService.updateMicDeviceState(newMicState);
      }
    }
  }
}

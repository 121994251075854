import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { getProperty } from '../utilities/TypesUtils';
import { getBrowserVisibilityProperties } from '../utilities/page-visibility.utils';
import { DomListener } from '../utilities/DomListener';
import { DomListenerFactoryService } from './dom-listener-factory.service';

@Injectable({
  providedIn: 'root',
})
export class ForegroundActivityService {
  /*
   * this is emited out side of angular zone , so if the subscriber will execute a logic that needs
   * to run inside angular , you should run the handler logic inside angular zone again.
   */
  private _isForegroundInactive$ = new BehaviorSubject<boolean>(false);
  public isForegroundInactive$ = this._isForegroundInactive$.asObservable();

  private pageVisibilityChangeHandler;
  private pageActivityProperties = getBrowserVisibilityProperties(document);
  private domListener: DomListener;

  constructor(private domListenerFactoryService: DomListenerFactoryService) {
    this.domListener = this.domListenerFactoryService.createInstance();
    this.pageVisibilityChangeHandler = this.handlePageVisibilityChange.bind(this);
    this.startListeningToPageActivity();
  }

  handlePageVisibilityChange() {
    this._isForegroundInactive$.next(
      Boolean(
        this.pageActivityProperties &&
          getProperty(document, this.pageActivityProperties.hiddenPropertyName as keyof Document),
      ),
    );
  }

  public startListeningToPageActivity(): void {
    if (this.pageActivityProperties?.visibilityChangePropertyName) {
      this.domListener.add(
        document,
        this.pageActivityProperties.visibilityChangePropertyName,
        this.pageVisibilityChangeHandler,
        true,
      );
    }
  }

  public stopListeningToPageActivity(): void {
    this.domListener.clear();
  }
  public get isForegroundInactive() {
    return this._isForegroundInactive$.getValue();
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'svg-icon',
  styleUrls: ['./svg-icon.component.scss'],
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      [style.max-width]="maxWidth"
      [style.filter]="filter"
    >
      <use attr.xlink:href="assets/icons/svg-sprite/{{ svgSpriteName }}#{{ icon }}"></use>
    </svg>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent {
  public svgSpriteName = environment.svgSpriteName;
  @Input() icon?: string;
  @Input() width?: string;
  @Input() height?: string;
  @Input() maxWidth?: string = '100%';
  // filter will be black by default
  @Input() filter?: string =
    'invert(0%) sepia(44%) saturate(6%) hue-rotate(145deg) brightness(84%) contrast(101%);';
}

<div
  *ngIf="tableData?.length"
  cdkDropList
  class="w-100 table position-relative"
  [style.min-height]="tableData.length * 30 + 'px'"
  #table
  (cdkDropListDropped)="reorderRows($event)"
>
  <div
    cdkDrag
    cdkDropList
    class="tr d-flex w-100 align-items-stretch"
    *ngFor="let tr of tableData; let trIndex = index"
    [class.header]="fragment.fragment.metadata?.is_row_header && trIndex === 0"
    (cdkDropListDropped)="reorderCols($event)"
  >
    <button
      cdkDragHandle
      class="btn p-0 btn-more-vert"
      [matMenuTriggerFor]="rowMenu"
      (click)="selectedTrIndex = trIndex"
    >
      <i class="material-icons">more_vert</i>
    </button>
    <div
      cdkDrag
      class="td flex-1 d-flex flex-column"
      *ngFor="let td of tr; let tdIndex = index"
      [class.header]="fragment.fragment.metadata?.is_col_header && tdIndex === 0"
      [style.min-width]="td[0]?.metadata?.width + 'px'"
      [style.max-width]="td[0]?.metadata?.width + 'px'"
      (mouseover)="handleMouseOver($event, tdIndex)"
      (mousedown)="handleMouseDown($event, tdIndex)"
      (mouseup)="handleMouseUp()"
      (mousemove)="handleMouseMove($event)"
    >
      <button
        cdkDragHandle
        class="btn p-0 btn-more-horiz"
        [matMenuTriggerFor]="colMenu"
        (click)="selectedTdIndex = tdIndex"
      >
        <i class="material-icons">more_horiz</i>
      </button>
      <div
        class="flex-1 d-flex align-items-center"
        cdkDropList
        (cdkDropListDropped)="reorderFragment($event, trIndex, tdIndex)"
      >
        <span
          class="header-sign"
          *ngIf="
            (fragment.fragment.metadata?.is_col_header && tdIndex === 0) ||
            (fragment.fragment.metadata?.is_row_header && trIndex === 0)
          "
          >H</span
        >
        <div class="flex-1">
          <div
            *ngIf="
              selectedTableIndexData?.trIndex === trIndex &&
                selectedTableIndexData?.tdIndex === tdIndex &&
                (uploadProgress | async);
              let uploading
            "
            class="
              d-flex
              align-items-center
              justify-content-center
              position-relative
              progress-container
            "
          >
            <div
              class="
                d-inline-flex
                flex-column
                align-items-center
                justify-content-center
                position-absolute
                file-info-container
              "
            >
              <i class="material-icons-outlined upload-icon" *ngIf="uploadType === 'image'"
                >photo</i
              >
              <i class="material-icons-outlined upload-icon" *ngIf="uploadType === 'video'"
                >ondemand_video</i
              >
              <i class="material-icons-outlined upload-icon" *ngIf="uploadType === 'phet'"
                >picture_in_picture</i
              >
              <i class="material-icons-outlined upload-icon" *ngIf="uploadType === 'pdf'"
                >picture_as_pdf</i
              >
            </div>
            <div class="progress position-absolute" *ngIf="uploading !== 100">
              <div
                class="progress-bar bg-primary progress-bar-striped progress-bar-animated"
                [style.width]="uploading + '%'"
              ></div>
            </div>
          </div>
          <div
            cdkDrag
            class="d-flex justify-content-between align-items-center fragment"
            *ngFor="let fragment of td; let fragIndex = index"
          >
            <div
              *ngIf="fragment.type === 'TEXT'"
              class="flex-1"
              advanced-text-fragment
              [fromTable]="true"
              [fragment]="{fragment}"
              [coll]="collection"
              placeholder=""
              [selected]="selectedTableIndexData?.trIndex === trIndex && selectedTableIndexData?.tdIndex === tdIndex && selectedTableIndexData?.fragIndex === fragIndex"
              (saveImage)="saveImage($event)"
              (saveEditor)="saveChanges(trIndex, tdIndex, fragIndex, $event)"
              (focused)="focused.emit({trIndex, tdIndex, fragIndex})"
              (blurred)="blurred.emit({trIndex, tdIndex, fragIndex})"
            ></div>
            <img *ngIf="fragment.type === 'IMAGE'" class="flex-1" [src]="fragment.data" />
            <i cdkDragHandle class="btn-drag material-icons">drag_indicator</i>
            <i
              *ngIf="td.length > 1"
              class="btn-delete material-icons-outlined"
              (click)="deleteFragment(trIndex, tdIndex, fragIndex)"
              >delete</i
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-menu #colMenu="matMenu">
  <button mat-menu-item (click)="addCol('LEFT')">
    {{ 'Insert column left' | translate }}
  </button>
  <button mat-menu-item (click)="addCol('RIGHT')">
    {{ 'Insert column right' | translate }}
  </button>
  <button
    *ngIf="!fragment.fragment.metadata?.is_col_header"
    mat-menu-item
    [disabled]="selectedTdIndex !== 0"
    (click)="setHeader('COL')"
  >
    {{ 'Turn into header' | translate }}
  </button>
  <button
    *ngIf="fragment.fragment.metadata?.is_col_header"
    mat-menu-item
    [disabled]="selectedTdIndex !== 0"
    (click)="disableHeader('COL')"
  >
    {{ 'Disable header' | translate }}
  </button>
  <button
    mat-menu-item
    class="text-danger"
    [disabled]="tableData[0]?.length === 1"
    (click)="deleteCol()"
  >
    {{ 'Delete column' | translate }}
  </button>
</mat-menu>
<mat-menu #rowMenu="matMenu">
  <button mat-menu-item (click)="addRow('ABOVE')">
    {{ 'Insert row above' | translate }}
  </button>
  <button mat-menu-item (click)="addRow('BELOW')">
    {{ 'Insert row below' | translate }}
  </button>
  <button
    *ngIf="!fragment.fragment.metadata?.is_row_header"
    mat-menu-item
    [disabled]="selectedTrIndex !== 0"
    (click)="setHeader('ROW')"
  >
    {{ 'Turn into header' | translate }}
  </button>
  <button
    *ngIf="fragment.fragment.metadata?.is_row_header"
    mat-menu-item
    [disabled]="selectedTrIndex !== 0"
    (click)="disableHeader('ROW')"
  >
    {{ 'Disable header' | translate }}
  </button>
  <button
    mat-menu-item
    class="text-danger"
    [disabled]="tableData.length === 1"
    (click)="deleteRow()"
  >
    {{ 'Delete row' | translate }}
  </button>
</mat-menu>

<i *ngIf="!tableData?.length" style="opacity: 0.5; font-size: 14px">{{
  'Table data incorrectly formatted. Please remove and recreate this table.' | translate
}}</i>

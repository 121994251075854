import { Directive, ElementRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FlagsService, FLAGS } from '../../services/flags.service';
import { ForegroundActivityService } from '../../services/foreground-activity.service';

@UntilDestroy()
@Directive({
  selector: '[appChromeCanvasBugWorkaround]',
  exportAs: 'appChromeCanvasBugWorkaround',
  standalone: true,
})
export class ChromeCanvasBugWorkaroundDirective {
  private foregroundActivitySubscription?: Subscription;

  constructor(
    private foregroundInactivityService: ForegroundActivityService,
    private el: ElementRef,
    private flagsService: FlagsService,
  ) {
    this.flagsService
      .featureFlagChanged(FLAGS.CHROME_CANVAS_BUG_FIX)
      .pipe(untilDestroyed(this))
      .subscribe((fixEnabled) => {
        if (fixEnabled) {
          this.foregroundActivitySubscription?.unsubscribe();
          this.foregroundActivitySubscription =
            this.foregroundInactivityService.isForegroundInactive$
              .pipe(
                untilDestroyed(this),
                distinctUntilChanged(),
                filter((pageVisible) => pageVisible),
              )
              .subscribe(() => {
                if ((window as any).disableChromeCanvasBugFix) {
                  return;
                }
                const canvas = this.el.nativeElement;
                if (canvas.getContext) {
                  const context: CanvasRenderingContext2D = canvas?.getContext('2d');
                  context?.save();
                  context?.clearRect(0, 0, 1, 1);
                  context?.restore();
                }
              });
        } else {
          this.foregroundActivitySubscription?.unsubscribe();
        }
      });
  }
}

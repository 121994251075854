<div class="upload-panel-container">
  <div class="upload-panel-header">
    <p class="upload-panel-header-text">{{ 'Upload' | translate }}</p>
    <app-pencil-icon
      class="upload-panel-header-close-icon"
      [name]="'close'"
      [type]="IconTypes.materialIcon"
      (click)="closeSubmit.emit()"
    >
    </app-pencil-icon>
  </div>
  <app-upload
    class="upload-panel-content"
    [data]="uploadData"
    [handleDataInternally]="true"
    (closeSubmit)="closeSubmit.emit()"
  >
  </app-upload>
</div>

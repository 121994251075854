import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService, LOGIN_TYPE } from '../services/auth.service';
import { UserService } from '../services/user.service';
@Injectable({
  providedIn: 'root',
})
export class ThirdPartyLoginGuard implements CanActivate {
  constructor(private authService: AuthService, private userService: UserService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    this.userService.appLoading.next(true);
    const { loginType, token } = route.queryParams;
    await this.authService.signOutForAPILogin();
    await this.handleLogin(loginType, token);
    return false;
  }

  private handleLogin(loginType: string, token: string) {
    if (loginType === LOGIN_TYPE.APISignIn && token) {
      return this.authService.loginWithToken(token);
    } else {
      return this.authService.handleTokenLoginError();
    }
  }
}

import { Component, Output, EventEmitter, Input, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';

@Component({
  selector: 'app-editor-additional-toolbox',
  templateUrl: './editor-additional-toolbox.component.html',
  styleUrls: ['./editor-additional-toolbox.component.scss'],
})
export class EditorAdditionalToolboxComponent implements AfterViewChecked {
  @Input() disableResponse = false;
  @Input() disablePoints = false;
  @Input() disableHint = false;
  @Input() showHint = false;
  @Input() disableAnswer = false;
  @Input() disableExplanation = false;
  @Input() showOthers = false;
  @Input() showChoice = false;
  @Input() showSub = false;
  @Input() hideAddText = false;
  @Input() enableAddSub = true;
  @Output() add = new EventEmitter();

  @ViewChild('toolbox') toolbox!: ElementRef;

  showMore = true;
  closePanel = false;
  toolboxWidth = 1000;

  ngAfterViewChecked() {
    if (this.toolbox) {
      this.toolboxWidth = this.toolbox.nativeElement.clientWidth;
    }
  }

  handleClose() {
    this.showMore = !this.showMore;
    if (!this.closePanel) {
      this.closePanel = true;
    }
  }
}

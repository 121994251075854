import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ControlContainer, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { map } from 'rxjs';
import { UiService } from 'src/app/services/ui.service';
import { ToggleOptions } from '../user-settings-permissions-toggle/user-settings-permissions-toggle.component';

interface SelectionOptions {
  value: string; // value of the selection
  title: string; // Title text for the selection
  description: string; // Description text for the selection
  subToggleInputs?: ToggleOptions;

  // Controls the selection behavior of disabled selections
  disableOptions?: {
    disabled: boolean; // is the option disabled
    reason: string; // why its disabled
  };
}
interface SelectOptions {
  setting: string; // setting to mutate in the form
  selections: SelectionOptions[]; // the selection options
}

@Component({
  selector: 'app-user-settings-permissions-select',
  templateUrl: './user-settings-permissions-select.component.html',
  styleUrls: ['./user-settings-permissions-select.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsPermissionsSelectComponent implements OnInit {
  @Input() options!: SelectOptions;
  @Input() formGroupName: string = 'settings';
  form!: UntypedFormGroup;

  tooltipClasses$ = this.uiService.isMobile
    .asObservable()
    .pipe(map((isMobile) => (isMobile ? ['mobile', 'disabled-tooltip'] : ['disabled-tooltip'])));

  constructor(private controlContainer: ControlContainer, private uiService: UiService) {}

  ngOnInit(): void {
    this.form = <UntypedFormGroup>this.controlContainer.control;
  }
}

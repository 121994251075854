import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { KatexModule } from 'ng-katex';
import { ColorChromeModule } from 'ngx-color/chrome'; // <color-chrome></color-chrome>
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { SafePipeModule } from 'safe-pipe';

import { ShareModule } from 'ngx-sharebuttons';
import { MatDividerModule } from '@angular/material/divider';
import { SVGIconComponentsModule } from '../common/svg-icon/svg-icon-components.module';
import { PastExamsComponent } from '../content/course/create/past-exams/past-exams.component';
import { ResourceCardComponent } from '../content/course/resource-card/resource-card.component';
import { DirectivesModule } from '../directives/directives.module';
import { PipeModule } from '../pipes/pipes.module';
import { PdfPageComponent } from '../standalones/components/pdf-viewer/pdf-page/pdf-page.component';
import { TooltipDirective } from '../standalones/directives/pncl-tooltip.directive';
import { ElementRenderedDirective } from '../standalones/directives/element-rendered.directive';
import { PnclProgressBarComponent } from '../standalones/components/pncl-progress-bar/pncl-progress-bar.component';
import { TruncateStringPipe } from '../standalones/pipes/truncate-string.pipe';
import { DropDownButtonComponent } from '../standalones/components/drop-down-button/drop-down-button.component';
import { PencilButtonComponent } from '../standalones/components/pencil-button/pencil-button.component';
import { PencilIconComponent } from '../standalones/components/pencil-icon/pencil-icon.component';
import { SpacesTemplatesListItemComponent } from '../sessions/spaces-templates-list-item/spaces-templates-list-item.component';
import { PencilPillComponent } from '../standalones/components/pencil-pill/pencil-pill.component';
import { AdvancedEditorComponent } from './advanced-editor/advanced-editor.component';
import { AdvancedTextFragmentDirective } from './advanced-editor/advanced-text-fragment.component';
import { AdvancedVideoFragmentDirective } from './advanced-editor/advanced-video-fragment.component';
import { AuthorProfilePhotoComponent } from './author-profile-photo/author-profile-photo.component';
import { ButtonComponent } from './button/button.component';
import { CardComponent } from './card/card.component';
import { ChipComponent } from './chip/chip.component';
import { ClipTextComponent } from './clip-text/clip-text.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ContentLoaderComponent } from './content-loader/content-loader.component';
import { CustomAttributePillComponent } from './custom-attribute-pill/custom-attribute-pill.component';
import { DifficultySelectorComponent } from './difficulty-selector/difficulty-selector.component';
import { DifficultyComponent } from './difficulty/difficulty.component';
import { EditorAdditionalToolboxComponent } from './editor-additional-toolbox/editor-additional-toolbox.component';
import { EditorToolboxComponent } from './editor-toolbox/editor-toolbox.component';
import { EmailSearchComponent } from './email-search/email-search.component';
import { ExtendedTextboxComponent } from './extended-textbox/extended-textbox.component';
import { FilterComponent } from './filter/filter.component';
import {
  FragmentComponent,
  FragmentContainerComponent,
} from './fragments/fragment-container.component';
import { HeaderActionsComponent, HeaderComponent } from './header/header.component';
import { ImageFragmentDialogComponent } from './image-fragment-dialog/image-fragment-dialog.component';
import { ImageResizeNewComponent } from './image-resize-new/image-resize-new.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { LightweightUserAvatarComponent } from './lightweight-user-avatar/lightweight-user-avatar/lightweight-user-avatar.component';
import { LoadingComponent } from './loading/loading.component';
import { LockSnackbarComponent } from './lock-snackbar/lock-snackbar.component';
import { MyscriptComponent } from './myscript/myscript.component';
import { ButtonToasterElementComponent } from './notification-toaster/button-toaster-element/button-toaster-element.component';
import { CustomNotificationToastrComponent } from './notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { ErrorToasterElementComponent } from './notification-toaster/error-toaster-element/error-toaster-element.component';
import { IconMessageToasterElementComponent } from './notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { ToasterElementComponent } from './notification-toaster/toaster-element/toaster-element.component';
import { ToasterIconComponent } from './notification-toaster/toaster-icon/toaster-icon.component';
import { NotificationComponent } from './notification/notification.component';
import { NotificationMenuItemComponent } from './notifications-menu/notification-menu-item/notification-menu-item.component';
import { NotificationsMenuComponent } from './notifications-menu/notifications-menu.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { PdfFragmentContainerComponent } from './pdf-fragment-container/pdf-fragment-container.component';
import { PhetEmbedComponent } from './phet-embed/phet-embed.component';
import { PillComponent } from './pill/pill.component';
import { PointsComponent } from './points/points.component';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { ProfilePhotoComponent } from './profile-photo/profile-photo.component';
import { RoleChipComponent } from './role-chip/role-chip.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SidebarComponent, SidebarSectionComponent } from './sidebar/sidebar.component';
import { SnackbarComponent } from './snackbar.component';
import { SocialShareComponent } from './social-share/social-share.component';
import { SpeechBubbleComponent } from './speech-bubble/speech-bubble.component';
import { TableEditorComponent } from './table-editor/table-editor.component';
import { TableFragmentComponent } from './table-fragment/table-fragment.component';
import { TimeSelectorComponent } from './time-selector/time-selector.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { UserProfileCardComponent } from './user-profile-card/user-profile-card.component';
import { UsersPresenceComponent } from './users-presence/users-presence.component';
import { VisibilityIconComponent } from './visibility-icon/visibility-icon.component';
import { VisibilitySelectorComponent } from './visibility-selector/visibility-selector.component';
import { VisibilitySliderComponent } from './visibility-slider/visibility-slider.component';
import { SpinnerComponent } from './loading/spinner/spinner.component';
import { PencilSpinnerComponent } from './pencil-spinner/pencil-spinner.component';
import { ProfilePhotoWithAttachmentsComponent } from './profile-photo-with-attachments/profile-photo-with-attachments.component';
import { MultiValuePillsComponent } from './multi-value-pills/multi-value-pills.component';
import { TutorAvailabilityStatusComponent } from './tutor-availability-status/tutor-availability-status.component';

@NgModule({
  imports: [
    ElementRenderedDirective,
    CommonModule,
    SVGIconComponentsModule,
    KatexModule,
    SafePipeModule,
    NgSelectModule,
    RouterModule,
    MatIconModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    MatRadioModule,
    MatCheckboxModule,
    NgbTooltipModule,
    MatDialogModule,
    PipeModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatButtonModule,
    MatTabsModule,
    MatChipsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    NgxQRCodeModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          breaks: true,
        },
      },
    }),
    DirectivesModule,
    MatAutocompleteModule,
    MatExpansionModule,
    OverlayModule,
    ToastNoAnimationModule.forRoot({
      maxOpened: 5,
      preventDuplicates: true,
      toastClass: 'ngx-toastr notification-popup',
      positionClass: 'toaster-top-right',
    }),
    SVGIconComponentsModule,
    ColorChromeModule,
    ShareModule,
    TooltipDirective,
    PnclProgressBarComponent,
    PdfPageComponent,
    MatProgressBarModule,
    TruncateStringPipe,
    DropDownButtonComponent,
    PencilButtonComponent,
    MatDividerModule,
    PencilIconComponent,
    PencilPillComponent,
  ],
  exports: [
    ButtonComponent,
    CardComponent,
    ChipComponent,
    HeaderComponent,
    HeaderActionsComponent,
    SidebarComponent,
    SidebarSectionComponent,
    FragmentContainerComponent,
    FragmentComponent,
    PhetEmbedComponent,
    MyscriptComponent,
    ProfilePhotoComponent,
    ProfilePhotoWithAttachmentsComponent,
    NotificationComponent,
    LoadingComponent,
    TranslateModule,
    ContentLoaderComponent,
    NotificationsMenuComponent,
    NotificationMenuItemComponent,
    AdvancedTextFragmentDirective,
    AdvancedVideoFragmentDirective,
    AdvancedEditorComponent,
    EditorToolboxComponent,
    ProfileCardComponent,
    RoleChipComponent,
    EditorAdditionalToolboxComponent,
    PointsComponent,
    ResourceCardComponent,
    DifficultyComponent,
    PastExamsComponent,
    UserProfileCardComponent,
    SnackbarComponent,
    SearchBarComponent,
    AuthorProfilePhotoComponent,
    FilterComponent,
    VisibilitySliderComponent,
    ExtendedTextboxComponent,
    PillComponent,
    TooltipComponent,
    TooltipDirective,
    VisibilitySelectorComponent,
    DifficultySelectorComponent,
    VisibilityIconComponent,
    MultiValuePillsComponent,
    UsersPresenceComponent,
    TimeSelectorComponent,
    NumberInputComponent,
    SpeechBubbleComponent,
    LanguageSelectorComponent,
    ColorPickerComponent,
    EmailSearchComponent,
    ClipTextComponent,
    SocialShareComponent,
    CustomAttributePillComponent,
    LightweightUserAvatarComponent,
    SpinnerComponent,
    PdfFragmentContainerComponent,
    PencilSpinnerComponent,
    TutorAvailabilityStatusComponent,
    SpacesTemplatesListItemComponent,
  ],
  declarations: [
    // TODO(dt): Move this component into the UI directory.
    PhetEmbedComponent,
    CardComponent,
    ChipComponent,
    HeaderComponent,
    HeaderActionsComponent,
    ButtonComponent,
    SidebarComponent,
    SidebarSectionComponent,
    FragmentContainerComponent,
    FragmentComponent,
    MyscriptComponent,
    ProfilePhotoComponent,
    ProfilePhotoWithAttachmentsComponent,
    NotificationComponent,
    LoadingComponent,
    ContentLoaderComponent,
    NotificationsMenuComponent,
    NotificationMenuItemComponent,
    AdvancedTextFragmentDirective,
    AdvancedVideoFragmentDirective,
    AdvancedEditorComponent,
    EditorToolboxComponent,
    ProfileCardComponent,
    RoleChipComponent,
    EditorAdditionalToolboxComponent,
    PointsComponent,
    ResourceCardComponent,
    DifficultyComponent,
    PastExamsComponent,
    UserProfileCardComponent,
    ImageFragmentDialogComponent,
    SnackbarComponent,
    SearchBarComponent,
    AuthorProfilePhotoComponent,
    FilterComponent,
    VisibilitySliderComponent,
    ExtendedTextboxComponent,
    PillComponent,
    TooltipComponent,
    ImageResizeNewComponent,
    TableEditorComponent,
    TableFragmentComponent,
    VisibilitySelectorComponent,
    DifficultySelectorComponent,
    VisibilityIconComponent,
    CustomNotificationToastrComponent,
    ToasterIconComponent,
    IconMessageToasterElementComponent,
    ErrorToasterElementComponent,
    ButtonToasterElementComponent,
    ToasterElementComponent,
    MultiValuePillsComponent,
    UsersPresenceComponent,
    TimeSelectorComponent,
    NumberInputComponent,
    SpeechBubbleComponent,
    PdfFragmentContainerComponent,
    LanguageSelectorComponent,
    ColorPickerComponent,
    EmailSearchComponent,
    ClipTextComponent,
    SocialShareComponent,
    LockSnackbarComponent,
    CustomAttributePillComponent,
    LightweightUserAvatarComponent,
    SpinnerComponent,
    PencilSpinnerComponent,
    TutorAvailabilityStatusComponent,
    SpacesTemplatesListItemComponent,
  ],
  entryComponents: [CustomNotificationToastrComponent],
})
export class UiModule {}

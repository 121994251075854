import { Injectable } from '@angular/core';
import {
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  ReplaySubject,
  startWith,
} from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { ItemModel } from '../models/session';
import { SessionSharedDataService, SessionView } from './session-shared-data.service';
import { SessionsVptService } from './sessions-vpt.service';
import { ISpaceLeaderModeUI, SpaceLeaderModeService } from './space-leader-mode.service';
import { FLAGS, FlagsService } from './flags.service';
import { TelemetryService } from './telemetry.service';

@Injectable({
  providedIn: 'root',
})
export class SpaceZoomCutoffService {
  /**
   * based on the Rules we define for cut off ( destroy ) it will emit boolean value
   * we define rules now based on the zooming level on different window sizes ( with )
   */
  public shouldCutOff$: Observable<boolean>;
  private _cutOffThreshold = new ReplaySubject<number>();
  public cutOffThreshold$ = this._cutOffThreshold.asObservable();
  private _zoomLevel = new ReplaySubject<number>();
  public zoomLevel$ = this._zoomLevel.asObservable();

  constructor(
    private sharedDataService: SessionSharedDataService,
    private sessionsVptService: SessionsVptService,
    private spaceLeaderModeService: SpaceLeaderModeService,
    private flagsService: FlagsService,
    private telemetry: TelemetryService,
  ) {
    this.shouldCutOff$ = combineLatest([
      this.sessionsVptService.viewportTransform.pipe(
        map(() => this.zoomLevel),
        distinctUntilChanged(),
      ),
      this.spaceLeaderModeService.activeSpaceLeaderModeState$,
      this.sharedDataService.sessionView.current$,
      this.flagsService.featureFlagChanged(FLAGS.ZOOMED_OUT_APPS_OVERLAY),
    ]).pipe(
      filter(() => this.flagsService.isFlagEnabled(FLAGS.ZOOMED_OUT_APPS_OVERLAY)),
      map(([currentZoom, leaderModeState, sessionView]) => {
        const zoomCutoffThreshold = this.calculateZoomCutoffThreshold(
          window.innerWidth,
          leaderModeState,
        );

        this._cutOffThreshold.next(zoomCutoffThreshold);
        this._zoomLevel.next(this.zoomLevel);

        // cut off should happen on zooming level and also on not full screen app
        return (
          zoomCutoffThreshold >= currentZoom && sessionView.view !== SessionView.FULLSCREEN_APP
        );
      }),
      startWith(false),
      distinctUntilChanged(),
      tap((zoomCutoff) => {
        this.telemetry.setSessionVars({
          count_webviewers: zoomCutoff
            ? 0
            : (this.sharedDataService.itemsCanvas?.canvasItemsArray$.getValue() || []).filter(
                (item) => item.type === ItemModel.WebViewer,
              ).length,
        });
      }),
    );
  }

  get zoomLevel(): number {
    return this.sharedDataService.fabricCanvas?.getZoom() || 1;
  }

  /**
   * this method will calculate the minimum zoom level we need to cut off and destroy the application
   * it checks the viewport width and based on some conditions we return the minimum value
   * Also the threshold can be overridden to a 0.25 if leader mode is one
   * @param viewportWidth
   * @param leaderModeState
   */
  calculateZoomCutoffThreshold(viewportWidth: number, leaderModeState: ISpaceLeaderModeUI): number {
    let zoomCutoffThreshold;
    // if width of the viewport is more than 1600, minimum zoom is 25%
    if (viewportWidth > 1600) {
      zoomCutoffThreshold = 0.25;
      //   if width of the viewport is more than 400, minimum zoom is 40%
    } else if (viewportWidth < 400) {
      zoomCutoffThreshold = 0.4;
      //  for anything else we calculate the zoom based on the method below
    } else {
      zoomCutoffThreshold = 0.4 + ((0.25 - 0.4) / (1600 - 400)) * (viewportWidth - 400);
    }

    // if leader mode is on, zoom cutoff should be 25% for all
    if (leaderModeState.leaderModeEnabled) {
      zoomCutoffThreshold = 0.25;
    }

    return zoomCutoffThreshold;
  }
}

import { NgModule } from '@angular/core';
import { TruncateStringPipe } from '../standalones/pipes/truncate-string.pipe';
import { CopyArrayPipe } from '../pipes/copy-array.pipe';
import { AnswerCheckPipe } from './answer-check.pipe';
import { AuthorPipe } from './author.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { CheckNewPipe } from './check-new.pipe';
import { CheckedTopicNumPipe } from './checked-topic-num.pipe';
import { DurationFormatPipe } from './duration-format.pipe';
import { EmptyCheckPipe } from './empty-check.pipe';
import { EventDatePipe } from './event-date.pipe';
import { ExplanationCheckPipe } from './explanation-check.pipe';
import { ExplanationPipe } from './explanation.pipe';
import { FragmentUploadInprogressCheckPipe } from './fragment-upload-inprogress-check.pipe';
import { FragmentPipe } from './fragment.pipe';
import { IncludesMathPipe } from './includes-math.pipe';
import { IncludesVideoPipe } from './includes-video.pipe';
import { IsInSetPipe } from './is-in-set.pipe';
import { LikeContextPipe } from './like-context.pipe';
import { MarkdownHtmlPipe } from './markdown-html.pipe';
import { NewLinePipe } from './new-line.pipe';
import { NoteErrorsPipe } from './note-errors.pipe';
import { NotificationTimePipe } from './notification-time.pipe';
import { NumberListPipe } from './number-list.pipe';
import { QrcodePipe } from './qrcode.pipe';
import { QuestionTypePipe } from './question-type.pipe';
import { SafeHtmlPipe, SafeURL } from './safe-html.pipe';
import { SegmentsPipe } from './segments.pipe';
import { ShortNamePipe } from './short-name.pipe';
import { SubQuestionPrintMarginPipe } from './sub-question-print-margin.pipe';
import { TimeFormatPipe } from './time-format.pipe';
import { TopicChipPipe } from './topic-chip.pipe';
import { TopicNamePipe } from './topic-name.pipe';
import { UserRolePipe } from './user-role.pipe';
import { WordCountPipe } from './word-count.pipe';
import { WorksheetTooltipPipe } from './worksheet-tooltip.pipe';
import { YoutubePipe } from './youtube.pipe';
import { AllInVisibilityPipe, SelectedInDifficultyPipe } from './all-in-selection.pipe';
import { AllSuspendedPipe } from './all-suspended.pipe';
import { BreakoutRoomNamePipe } from './breakout-room-name.pipe';
import { CheckIndeterminatePermissionPipe } from './check-indeterminate-permission.pipe';
import { CourseTooltipPipe } from './course-tooltip.pipe';
import { DateLocalePipe } from './dateLocale.pipe';
import { DateUserLocalePipe } from './dateUserLocale.pipe';
import { DisplayFilterPipe } from './display-filter.pipe';
import { DisplaySuggestionPipe } from './display-suggestion.pipe';
import { EmbedVimeoVideoPipe } from './embed-vimeo-video.pipe';
import { EventIsAcrossDayPipe } from './event-is-across-day.pipe';
import { EventTimeFormatPipe } from './event-time.pipe';
import {
  HasPipe,
  IsAdminPipe,
  IsStudentPipe,
  IsSuperAdminPipe,
  IsTeacherPipe,
} from './features.pipe';
import { FileNameFormatPipe } from './file-name-format.pipe';
import { FileViewCheckPipe } from './file-view-check.pipe';
import { FormatBytesPipe } from './format-bytes.pipe';
import { HiddenEmailPipe } from './hidden-email.pipe';
import { HighlighterPipe } from './highlighter.pipe';
import { HyphenatePipe } from './hyphenate.pipe';
import { IncludesPipe } from './includes.pipe';
import { IndividualUserPermissionPipe } from './individual-user-permission.pipe';
import { IsInSetsPipe } from './is-in-sets.pipe';
import { ListHostsPipe } from './list-users.pipe';
import { MessageAuthorPipe } from './message-author.pipe';
import { MessageGroupsByDatePipe } from './message-groups-by-date.pipe';
import { MessageGroupsSuccessiveUserChatPipe } from './message-groups-successive-user-chat.pipe';
import { MessageGroupsPipe } from './message-groups.pipe';
import { MessagePreviewTypePipe } from './message-preview-type.pipe';
import { MiddleTruncatePipe } from './middle-truncate.pipe';
import { PrefixPipe } from './prefix.pipe';
import { PresenceSortPipe } from './presence-sort.pipe';
import { RecordingEndingTimePipe } from './recording-ending-time.pipe';
import { ReferralTrialPeriodTextPipe } from './referral-trial-period-text.pipe';
import { RelativeClockPipe } from './relative-clock.pipe';
import { RelativeDatePipe } from './relative-date.pipe';
import { SchedulesRepeatPipe } from './schedules-repeat.pipe';
import { SessionCallVideoIdPipe } from './session-call-video-id.pipe';
import { SessionUserTypePipe } from './session-user-type.pipe';
import { ShortEmailPipe } from './short-email.pipe';
import { ShowBillingStatusIndicatorPipe } from './show-billing-status-indicator.pipe';
import { TranscriptGroupsByDatePipe } from './transcript-groups-by-date.pipe';
import { TruncatePipe } from './truncate.pipe';
import { UrlHostPipe } from './url-host.pipe';
import { UserSummaryPipe } from './user-summary.pipe';
import { ValuesPipe } from './values.pipe';
import { VisibilityTextPipe } from './visibility-text.pipe';
import { DateRangePickerSelectionPipe } from './date-range-picker-selection.pipe';
import { FormatChatPreviewPipe } from './format-chat-preview.pipe';
import { MomentFormatPipe } from './moment-format.pipe';
import { PaginatorDescriptionPipe } from './paginator-description.pipe';
import { GetEnabledDaysAvailabilityPipe } from './availability-get-enabled-days.pipe';
import { StripWordFromStringPipe } from './strip-word-from-string';
import { MapObjectsToFieldPipe } from './map-objects-to-field.pipe';
import { DateRangePickerSelectionNoLabelPipe } from './data-range-picker-selection-no-label.pipe';
import { SiteFromSiteId } from './site-from-site-id.pipe';
import { UserInviteInfoPipe } from './userInviteInfo.pipe';

@NgModule({
  imports: [TruncateStringPipe],
  declarations: [
    CapitalizePipe,
    IncludesVideoPipe,
    MarkdownHtmlPipe,
    QuestionTypePipe,
    WorksheetTooltipPipe,
    WordCountPipe,
    FragmentPipe,
    AuthorPipe,
    ExplanationPipe,
    EmptyCheckPipe,
    FragmentUploadInprogressCheckPipe,
    CheckNewPipe,
    ShortNamePipe,
    TopicNamePipe,
    NoteErrorsPipe,
    LikeContextPipe,
    UserRolePipe,
    ExplanationCheckPipe,
    AnswerCheckPipe,
    EventDatePipe,
    YoutubePipe,
    TopicChipPipe,
    SubQuestionPrintMarginPipe,
    SegmentsPipe,
    IncludesMathPipe,
    IsInSetPipe,
    TimeFormatPipe,
    EventTimeFormatPipe,
    SafeHtmlPipe,
    NewLinePipe,
    NotificationTimePipe,
    QrcodePipe,
    CheckedTopicNumPipe,
    DurationFormatPipe,
    NumberListPipe,
    HasPipe,
    IsStudentPipe,
    IsTeacherPipe,
    IsAdminPipe,
    IsSuperAdminPipe,
    ListHostsPipe,
    PrefixPipe,
    ValuesPipe,
    VisibilityTextPipe,
    SessionCallVideoIdPipe,
    FormatBytesPipe,
    FormatChatPreviewPipe,
    MessagePreviewTypePipe,
    RelativeDatePipe,
    UserSummaryPipe,
    FileNameFormatPipe,
    MessageGroupsPipe,
    MessageGroupsByDatePipe,
    MessageGroupsSuccessiveUserChatPipe,
    TranscriptGroupsByDatePipe,
    MessageAuthorPipe,
    TruncatePipe,
    DisplayFilterPipe,
    MiddleTruncatePipe,
    DisplaySuggestionPipe,
    HyphenatePipe,
    CourseTooltipPipe,
    FileViewCheckPipe,
    DateLocalePipe,
    DateUserLocalePipe,
    AllInVisibilityPipe,
    SelectedInDifficultyPipe,
    IsInSetsPipe,
    AllSuspendedPipe,
    IncludesPipe,
    SafeURL,
    PresenceSortPipe,
    SchedulesRepeatPipe,
    HighlighterPipe,
    ShortEmailPipe,
    RelativeClockPipe,
    SessionUserTypePipe,
    ReferralTrialPeriodTextPipe,
    UrlHostPipe,
    BreakoutRoomNamePipe,
    RecordingEndingTimePipe,
    HiddenEmailPipe,
    EmbedVimeoVideoPipe,
    CheckIndeterminatePermissionPipe,
    IndividualUserPermissionPipe,
    EventIsAcrossDayPipe,
    DateRangePickerSelectionPipe,
    DateRangePickerSelectionNoLabelPipe,
    ShowBillingStatusIndicatorPipe,
    MomentFormatPipe,
    PaginatorDescriptionPipe,
    GetEnabledDaysAvailabilityPipe,
    StripWordFromStringPipe,
    MapObjectsToFieldPipe,
    SiteFromSiteId,
    UserInviteInfoPipe,
    CopyArrayPipe,
  ],
  exports: [
    CapitalizePipe,
    IncludesVideoPipe,
    MarkdownHtmlPipe,
    QuestionTypePipe,
    WorksheetTooltipPipe,
    WordCountPipe,
    FragmentPipe,
    AuthorPipe,
    ExplanationPipe,
    EmptyCheckPipe,
    FragmentUploadInprogressCheckPipe,
    CheckNewPipe,
    ShortNamePipe,
    TopicNamePipe,
    NoteErrorsPipe,
    LikeContextPipe,
    UserRolePipe,
    ExplanationCheckPipe,
    AnswerCheckPipe,
    EventDatePipe,
    YoutubePipe,
    TopicChipPipe,
    SubQuestionPrintMarginPipe,
    SegmentsPipe,
    IncludesMathPipe,
    IsInSetPipe,
    TimeFormatPipe,
    EventTimeFormatPipe,
    SafeHtmlPipe,
    NewLinePipe,
    NotificationTimePipe,
    QrcodePipe,
    CheckedTopicNumPipe,
    DurationFormatPipe,
    NumberListPipe,
    HasPipe,
    IsStudentPipe,
    IsTeacherPipe,
    IsAdminPipe,
    IsSuperAdminPipe,
    ListHostsPipe,
    PrefixPipe,
    ValuesPipe,
    VisibilityTextPipe,
    SessionCallVideoIdPipe,
    FormatBytesPipe,
    FormatChatPreviewPipe,
    MessagePreviewTypePipe,
    RelativeDatePipe,
    UserSummaryPipe,
    FileNameFormatPipe,
    MessageGroupsPipe,
    MessageGroupsByDatePipe,
    MessageGroupsSuccessiveUserChatPipe,
    TranscriptGroupsByDatePipe,
    MessageAuthorPipe,
    TruncatePipe,
    DisplayFilterPipe,
    MiddleTruncatePipe,
    DisplaySuggestionPipe,
    HyphenatePipe,
    CourseTooltipPipe,
    FileViewCheckPipe,
    DateLocalePipe,
    DateUserLocalePipe,
    AllInVisibilityPipe,
    SelectedInDifficultyPipe,
    IsInSetsPipe,
    AllSuspendedPipe,
    IncludesPipe,
    TruncateStringPipe,
    SafeURL,
    PresenceSortPipe,
    SchedulesRepeatPipe,
    HighlighterPipe,
    ShortEmailPipe,
    RelativeClockPipe,
    SessionUserTypePipe,
    ReferralTrialPeriodTextPipe,
    UrlHostPipe,
    BreakoutRoomNamePipe,
    RecordingEndingTimePipe,
    HiddenEmailPipe,
    EmbedVimeoVideoPipe,
    CheckIndeterminatePermissionPipe,
    IndividualUserPermissionPipe,
    DateRangePickerSelectionPipe,
    DateRangePickerSelectionNoLabelPipe,
    EventIsAcrossDayPipe,
    ShowBillingStatusIndicatorPipe,
    MomentFormatPipe,
    PaginatorDescriptionPipe,
    GetEnabledDaysAvailabilityPipe,
    StripWordFromStringPipe,
    MapObjectsToFieldPipe,
    SiteFromSiteId,
    UserInviteInfoPipe,
    CopyArrayPipe,
  ],
  providers: [ShowBillingStatusIndicatorPipe],
})
export class PipeModule {}

import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
  Inject,
  OnDestroy,
} from '@angular/core';
import * as iink from 'iink-js';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

declare const MathQuill;

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SessionSharedDataService } from 'src/app/services/session-shared-data.service';
import { WbDialogService } from 'src/app/services/wb-dialog.service';
import { UiService } from 'src/app/services/ui.service';

@UntilDestroy()
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-myscript',
  templateUrl: './myscript.component.html',
  styleUrls: ['./myscript.component.scss'],
})
export class MyscriptComponent implements AfterViewInit, OnDestroy {
  @ViewChild('tref', { read: ElementRef, static: true }) domEditor!: ElementRef;
  @ViewChild('katexRenderer', { read: ElementRef, static: true }) katexRenderer!: ElementRef;
  @ViewChild('katextcontainer', { read: ElementRef, static: true }) katextContainer!: ElementRef;

  katex;
  Modes = {
    Write: 'Write',
    Paste: 'Paste',
    Draw: 'Draw',
  };
  mode = this.Modes.Write;
  mathField;
  myscript;
  snackBarOpened = false;
  isKatexError = false;
  isMyscriptDisconnected = false;
  isMobile = false;

  constructor(
    public dialogRef: MatDialogRef<MyscriptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
    private translateService: TranslateService,
    private sharedDataService: SessionSharedDataService,
    wbDialogService: WbDialogService,
    private uiService: UiService,
  ) {
    this.uiService.isMobile.pipe(untilDestroyed(this)).subscribe((res) => {
      this.isMobile = res;
      if (this.isMobile) {
        this.mode = this.Modes.Paste;
      }
    });
    this.katex = data.katex;
    this.sharedDataService.isDisableKeyEvents = true;
    wbDialogService.pushNewDialogRef(dialogRef);
  }

  handleExported(evt) {
    const exports = evt.detail.exports;
    if (exports && exports['application/x-latex']) {
      this.katex = exports['application/x-latex'];
    } else if (exports && exports['text/plain']) {
      this.katex = exports['text/plain'];
    } else {
      this.katex = '';
    }
    if (this.mathField) {
      this.mathField.latex(this.katex);
    }
  }

  doneClick() {
    this.checkKatexError();
    if (this.isKatexError) {
      return;
    }
    this.dialogRef.close(this.katex.replace(/(\r\n|\n|\r)/gm, ''));
  }

  ngAfterViewInit(): void {
    if (!this.isMobile) {
      const MQ = MathQuill.getInterface(2);
      /*
        This is a hacky way to disable all auto conversions.
        Because empty string causes an issue in library itself, we do this way.
      */
      MQ.config({
        autoOperatorNames: 'xxxxxxxxxxxxxx',
        autoCommands: 'xxxxxxxxxxxxxx',
      });
      this.mathField = MQ.MathField(document.getElementById('math-field'), {
        spaceBehavesLikeTab: true,
        handlers: {
          edit: () => {
            this.katex = this.mathField.latex() || this.mathField.html();
          },
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.sharedDataService.isDisableKeyEvents = false;
    if (this.myscript) {
      this.myscript.close();
    }
  }

  checkKatexError() {
    this.isKatexError = false;
    if (this.katexRenderer) {
      const allChildren = this.katexRenderer.nativeElement.firstChild.getElementsByTagName('*');
      for (let i = 0; i < allChildren.length; i++) {
        if (
          (typeof allChildren[i].className === 'string' &&
            allChildren[i].className?.includes('katex-error')) ||
          allChildren[i].style?.color === 'rgb(204, 0, 0)'
        ) {
          this.isKatexError = true;
          break;
        }
      }
      if (!this.snackBarOpened && this.isKatexError) {
        this.openSnackBar(
          this.translateService.instant(
            'Latex could not be correctly rendered. Please resolve any errors',
          ),
        );
        this.snackBarOpened = true;
      }
    }
  }

  escapeLatexDelimeter() {
    /*
      Whenever $ is typed inside the latex, we should escape it because it affects to render latex.
      Because we add backslashes whenever editor is updated, unlimited backslashes will be added.
      To prevent it, replaced multiple backslashes with only 1.
      Same for several special characters like %, &, #, etc.
    */
    if (this.katex) {
      this.katex = this.katex
        .replace(/\$/g, '\\$')
        .replace(/\\\\\$/g, '\\$')
        .replace(/%/g, '\\%')
        .replace(/\\\\%/g, '\\%')
        .replace(/#/g, '\\#')
        .replace(/\\\\#/g, '\\#');
    }
  }

  updateMathField() {
    this.escapeLatexDelimeter();
    if (this.mathField) {
      this.mathField.latex(this.katex);
    }
    this.checkKatexError();
  }

  openSnackBar(message: string) {
    const ref = this._snackBar.open(message, '✕', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'latex-error',
      duration: 5000,
    });
    ref
      .afterDismissed()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.snackBarOpened = false;
      });
  }

  handleMyscriptError(error): void {
    console.error('Myscript error', error);
    this.myscript.close();
    this.isMyscriptDisconnected = true;
  }

  activeDrawingMode(): void {
    this.mode = this.Modes.Draw;
    if (this.myscript) {
      return;
    }
    this.registerMyscript();
  }

  registerMyscript(): void {
    const clonedMyscriptNode = this.domEditor.nativeElement.children[0].cloneNode(false);
    this.domEditor.nativeElement.replaceChild(
      clonedMyscriptNode,
      this.domEditor.nativeElement.children[0],
    );
    clonedMyscriptNode.addEventListener('exported', (evt) => this.handleExported(evt));
    clonedMyscriptNode.addEventListener('error', (evt) => this.handleMyscriptError(evt));
    this.myscript = iink.register(clonedMyscriptNode, {
      recognitionParams: {
        type: 'MATH',
        iink: {
          math: {
            solver: {
              enable: false,
            },
          },
        },
        protocol: 'WEBSOCKET',
        apiVersion: 'V4',
        server: {
          scheme: 'https',
          host: 'cloud.myscript.com',
          applicationKey: '2ef52587-df6f-43ed-ace5-4a81593780aa',
          hmacKey: '625dcb06-79ac-494d-9668-7d473a78f3a1',
        },
      },
    });
    this.isMyscriptDisconnected = false;
  }

  reconnectMyscript(): void {
    if (this.myscript) {
      this.myscript.close().then(() => this.registerMyscript());
    } else {
      this.registerMyscript();
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IFolder, IResource } from '../models/resource';
import { URLService } from './dynamic-url.service';
import { ResourcesService } from './resources.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  tagAttributeId = '';
  tags: BehaviorSubject<any>;

  constructor(
    private resourceService: ResourcesService,
    private userService: UserService,
    private urlService: URLService,
    private http: HttpClient,
  ) {
    this.tags = new BehaviorSubject(null);
    this.userService.getCustomAttributes('').subscribe((res: any) => {
      this.tags.next(res.custom_attributes.find(attribute => attribute.attribute_key.value == 'Tags'));
    });
  }

  getTags() {
    const getUrl = `${this.urlService.getDynamicUrl()}/tutor/attributes/tags`;
    return this.http.get(getUrl);
  }

  createTag(tagName: string) {
    return this.http.post<any>(`${this.urlService.getDynamicUrl()}/tutor/attributes/tags`, {
      'name': tagName
    });
  }

  createCourseTag(tagName: string, courseId: string) {
    return this.http.post<any>(`${this.urlService.getDynamicUrl()}/tutor/attributes/tags`, {
      'name': tagName,
      courseId
    });
  }

  addTagToResources(tag, selectedFiles: IResource[] = [], selectedFolders: IFolder[] = []) {
    const changedResources: IResource[] = selectedFiles.reduce((list: IResource[], file: IResource) => {
      // If the file is updated, then add it to the list.
      if (this.updateResource(file, tag)) {
        list.push(file);
      }
      return list;
    }, []);
    // Same logic for folders.
    const changedFolders:IFolder[] = selectedFolders.reduce((list: IFolder[], folder: IFolder) => {
      if (this.updateFolder(folder, tag)) {
        list.push(folder);
      }
      return list;
    }, []);
    this.resourceService.editResources(changedResources).subscribe();
    this.resourceService.editFolders(changedFolders).subscribe();
  }

  removeTagFromResources(tag, selectedFiles: IResource[] = [], selectedFolders: IFolder[] = []) {
    const changedResources: IResource[] = [];
    const changedFolders: IFolder[] = [];
    if (selectedFiles.length > 0) {
      selectedFiles.forEach(file => {
        if (file.custom_attributes) {
          const index = file.custom_attributes.findIndex(id => id == this.tagToString(tag));
          if (index >= 0) {
            file.custom_attributes.splice(index, 1);
            changedResources.push(file);
          }
        }
      });
    }
    if (selectedFolders.length > 0) {
      selectedFolders.forEach(folder => {
        if (folder.custom_attributes) {
          const index = folder.custom_attributes.findIndex(id => id == this.tagToString(tag));
          if (index >= 0) {
            folder.custom_attributes.splice(index, 1);
            changedFolders.push(folder);
          }
        }
      })
    }
    this.resourceService.editResources(changedResources).subscribe();
    this.resourceService.editFolders(changedFolders).subscribe();
  }

  getTagsForResources(files: IResource[]): any[] {
    const selectedTags = new Set();
    if (files.length > 0) {
      files.forEach(file => {
        const tagsAttribute = file.custom_attributes?.filter(attribute => this.stringToTag(attribute).key == this.tags.value?.attribute_key._id)
        tagsAttribute?.forEach(tag => {
          tag = this.stringToTag(tag).value;
          if (tag) {selectedTags.add(tag);}
        })
      })
    }
    return Array.from(selectedTags);
  }

  getTagsForFolders(folders: IFolder[]) {
    const selectedTags = new Set();
    if (folders.length > 0) {
      folders.forEach(folder => {
        const tagsAttribute = folder.custom_attributes?.filter(attribute => this.stringToTag(attribute).key == this.tags.value.attribute_key._id)
        tagsAttribute?.forEach(tag => {
          tag = this.stringToTag(tag).value;
          if (tag) {selectedTags.add(tag);}
        })
      })
    }

    return Array.from(selectedTags);
  }

  /**
   * Used to split tags into Institution tags and course tags.
   */
  splitTags(tags) {
    const instTags = tags.filter(tag => !tag.metadata?.courseID?.length);
    const courseTags = tags.filter(tag => tag.metadata?.courseID?.length);
    return {
      institution: instTags,
      course: courseTags
    }
  }

  stringToTag(tagId) {
    const tagIds = tagId.split(':');
    return {
      key: tagIds[0],
      value: this.tags.value?.attribute_value.find(tag => tag._id == tagIds[1])
    }
  }

  tagToString(tag) {
    return `${this.tags.value.attribute_key._id  }:${  tag._id}`
  }

  /**
   * Update Courses/Tags for given resource
   * @param file
   * @param tag
   * @returns true if there's at least single update, false otherwise.
   */
  updateResource(file : IResource, tag: any): boolean {
    const newTag = this.addTagToSingleRecord(file, tag);
    const newCourse = this.addCourseIDToSingleRecord(file, tag);
    return newCourse || newTag;
  }

    /**
   * Update Tags(for now) for given folder
   * @param folder
   * @param tag
   * @returns true if there's at least single update, false otherwise.
   */
  updateFolder(folder: IFolder, tag: any): boolean {
    return this.addTagToSingleRecord(folder, tag);
  }

  /**
   * Adds course ID associated with the tag (if that tag is a course tag) to the given record(files/folders).
   * @param item
   * @param tag
   * @returns true if the record changed (updated successfully), false otherwise.
   * the update can fail if the course id is already associated to the item
   */
  private addCourseIDToSingleRecord(item: IResource, tag: any): boolean {
    // if it's not a course tag
    if (!tag?.metadata?.courseID?.length) {
      return false;
    }
    if (!item.courses) {item.courses = [];}
    const index = item.courses.findIndex((id: string) => id === tag.metadata.courseID);
    if (index < 0) {
      item.courses.push(tag.metadata.courseID);
      return true;
    }
    return false;
  }


  /**
   * Adds Tag id to the given record(files/folders).
   * @param item
   * @param tag
   * @returns true if the record changed (updated successfully), false otherwise.
   * the update can fail if it's a duplicate tag.
   */
  private addTagToSingleRecord(item: IResource | IFolder, tag: any): boolean {
    if (!item.custom_attributes) {item.custom_attributes = [];}
    const index = item.custom_attributes.findIndex(id => id == this.tagToString(tag));
    if (index < 0) {
      item.custom_attributes.push(this.tagToString(tag));
      return true;
    }
    return false;
  }
}

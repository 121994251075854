export class Finger {
  landmarks;
  handedness;
  options: any = {};
  allFingers = [0, 1, 2, 3, 4];
  constructor(landmarks, handedness: Handedness) {
    this.landmarks = landmarks;
    this.handedness = handedness;
    this.options = {
      // curl estimation
      HALF_CURL_START_LIMIT: 60.0,
      NO_CURL_START_LIMIT: 130.0,

      // direction estimation
      DISTANCE_VOTE_POWER: 1.1,
      SINGLE_ANGLE_VOTE_POWER: 0.9,
      TOTAL_ANGLE_VOTE_POWER: 1.6,
    };
  }

  // [0]     Palm
  // [1-4]   Thumb
  // [5-8]   Index
  // [9-12]  Middle
  // [13-16] Ring
  // [17-20] Pinky
  getPointMapping(finger) {
    const fingerPointMapping = {
      0: [
        [0, 1],
        [1, 2],
        [2, 3],
        [3, 4],
      ],
      1: [
        [0, 5],
        [5, 6],
        [6, 7],
        [7, 8],
      ],
      2: [
        [0, 9],
        [9, 10],
        [10, 11],
        [11, 12],
      ],
      3: [
        [0, 13],
        [13, 14],
        [14, 15],
        [15, 16],
      ],
      4: [
        [0, 17],
        [17, 18],
        [18, 19],
        [19, 20],
      ],
    };
    return fingerPointMapping[finger];
  }

  getLinearPoints(finger) {
    const linearPoints = {
      0: this.landmarks.slice(1, 5),
      1: this.landmarks.slice(5, 9),
      2: this.landmarks.slice(9, 13),
      3: this.landmarks.slice(13, 17),
      4: this.landmarks.slice(17, 21),
    };
    return linearPoints[finger];
  }

  getPalmPoint() {
    return this.landmarks.at(0);
  }

  getSlope(point1, point2) {
    const slopeXY = this.calculateSlope(point1.x, point1.y, point2.x, point2.y);
    const slopeYZ = this.calculateSlope(point1.y, point1.z, point2.y, point2.z);
    return [slopeXY, slopeYZ];
  }

  calculateSlope(point1x, point1y, point2x, point2y) {
    const value = (point1y - point2y) / (point1x - point2x);
    let slope = (Math.atan(value) * 180) / Math.PI;

    if (slope <= 0) {
      slope = -slope;
    } else if (slope > 0) {
      slope = 180 - slope;
    }
    return slope;
  }

  angleOrientationAt(angle, weightageAt = 1.0) {
    let isVertical = 0;
    let isDiagonal = 0;
    let isHorizontal = 0;

    if (angle >= 75.0 && angle <= 105.0) {
      isVertical = 1 * weightageAt;
    } else if (angle >= 25.0 && angle <= 155.0) {
      isDiagonal = 1 * weightageAt;
    } else {
      isHorizontal = 1 * weightageAt;
    }

    return [isVertical, isDiagonal, isHorizontal];
  }

  estimateHorizontalDirection(start_end_x_dist, start_mid_x_dist, mid_end_x_dist, max_dist_x) {
    let estimatedDirection;
    if (max_dist_x == Math.abs(start_end_x_dist)) {
      if (start_end_x_dist > 0) {
        estimatedDirection = FingerDirection.HorizontalLeft;
      } else {
        estimatedDirection = FingerDirection.HorizontalRight;
      }
    } else if (max_dist_x == Math.abs(start_mid_x_dist)) {
      if (start_mid_x_dist > 0) {
        estimatedDirection = FingerDirection.HorizontalLeft;
      } else {
        estimatedDirection = FingerDirection.HorizontalRight;
      }
    } else {
      if (mid_end_x_dist > 0) {
        estimatedDirection = FingerDirection.HorizontalLeft;
      } else {
        estimatedDirection = FingerDirection.HorizontalRight;
      }
    }

    return estimatedDirection;
  }

  estimateVerticalDirection(start_end_y_dist, start_mid_y_dist, mid_end_y_dist, max_dist_y) {
    let estimatedDirection;
    if (max_dist_y == Math.abs(start_end_y_dist)) {
      if (start_end_y_dist < 0) {
        estimatedDirection = FingerDirection.VerticalDown;
      } else {
        estimatedDirection = FingerDirection.VerticalUp;
      }
    } else if (max_dist_y == Math.abs(start_mid_y_dist)) {
      if (start_mid_y_dist < 0) {
        estimatedDirection = FingerDirection.VerticalDown;
      } else {
        estimatedDirection = FingerDirection.VerticalUp;
      }
    } else {
      if (mid_end_y_dist < 0) {
        estimatedDirection = FingerDirection.VerticalDown;
      } else {
        estimatedDirection = FingerDirection.VerticalUp;
      }
    }

    return estimatedDirection;
  }

  estimateDiagonalDirection(
    start_end_y_dist,
    start_mid_y_dist,
    mid_end_y_dist,
    max_dist_y,
    start_end_x_dist,
    start_mid_x_dist,
    mid_end_x_dist,
    max_dist_x,
  ) {
    let estimatedDirection;
    const reqd_vertical_direction = this.estimateVerticalDirection(
      start_end_y_dist,
      start_mid_y_dist,
      mid_end_y_dist,
      max_dist_y,
    );
    const reqd_horizontal_direction = this.estimateHorizontalDirection(
      start_end_x_dist,
      start_mid_x_dist,
      mid_end_x_dist,
      max_dist_x,
    );

    if (reqd_vertical_direction == FingerDirection.VerticalUp) {
      if (reqd_horizontal_direction == FingerDirection.HorizontalLeft) {
        estimatedDirection = FingerDirection.DiagonalUpLeft;
      } else {
        estimatedDirection = FingerDirection.DiagonalUpRight;
      }
    } else {
      if (reqd_horizontal_direction == FingerDirection.HorizontalLeft) {
        estimatedDirection = FingerDirection.DiagonalDownLeft;
      } else {
        estimatedDirection = FingerDirection.DiagonalDownRight;
      }
    }

    return estimatedDirection;
  }

  estimateFingerCurl(startPoint, midPoint, endPoint): FingerCurl {
    const start_mid_x_dist = startPoint.x - midPoint.x;
    const start_end_x_dist = startPoint.x - endPoint.x;
    const mid_end_x_dist = midPoint.x - endPoint.x;

    const start_mid_y_dist = startPoint.y - midPoint.y;
    const start_end_y_dist = startPoint.y - endPoint.y;
    const mid_end_y_dist = midPoint.y - endPoint.y;

    const start_mid_z_dist = startPoint.z - midPoint.z;
    const start_end_z_dist = startPoint.z - endPoint.z;
    const mid_end_z_dist = midPoint.z - endPoint.z;

    const start_mid_dist = Math.sqrt(
      start_mid_x_dist * start_mid_x_dist +
        start_mid_y_dist * start_mid_y_dist +
        start_mid_z_dist * start_mid_z_dist,
    );
    const start_end_dist = Math.sqrt(
      start_end_x_dist * start_end_x_dist +
        start_end_y_dist * start_end_y_dist +
        start_end_z_dist * start_end_z_dist,
    );
    const mid_end_dist = Math.sqrt(
      mid_end_x_dist * mid_end_x_dist +
        mid_end_y_dist * mid_end_y_dist +
        mid_end_z_dist * mid_end_z_dist,
    );

    let cos_in =
      (mid_end_dist * mid_end_dist +
        start_mid_dist * start_mid_dist -
        start_end_dist * start_end_dist) /
      (2 * mid_end_dist * start_mid_dist);

    if (cos_in > 1.0) {
      cos_in = 1.0;
    } else if (cos_in < -1.0) {
      cos_in = -1.0;
    }

    let angleOfCurve = Math.acos(cos_in);
    angleOfCurve = (57.2958 * angleOfCurve) % 180;

    let fingerCurl;
    if (angleOfCurve > this.options.NO_CURL_START_LIMIT) {
      fingerCurl = FingerCurl.NoCurl;
    } else if (angleOfCurve > this.options.HALF_CURL_START_LIMIT) {
      fingerCurl = FingerCurl.HalfCurl;
    } else {
      fingerCurl = FingerCurl.FullCurl;
    }

    return fingerCurl;
  }

  calculateFingerDirection(startPoint, midPoint, endPoint, fingerSlopes) {
    const start_mid_x_dist = startPoint.x - midPoint.x;
    const start_end_x_dist = startPoint.x - endPoint.x;
    const mid_end_x_dist = midPoint.x - endPoint.x;

    const start_mid_y_dist = startPoint.y - midPoint.y;
    const start_end_y_dist = startPoint.y - endPoint.y;
    const mid_end_y_dist = midPoint.y - endPoint.y;

    const max_dist_x = Math.max(
      Math.abs(start_mid_x_dist),
      Math.abs(start_end_x_dist),
      Math.abs(mid_end_x_dist),
    );
    const max_dist_y = Math.max(
      Math.abs(start_mid_y_dist),
      Math.abs(start_end_y_dist),
      Math.abs(mid_end_y_dist),
    );

    let voteVertical = 0.0;
    let voteDiagonal = 0.0;
    let voteHorizontal = 0.0;

    const start_end_x_y_dist_ratio = max_dist_y / (max_dist_x + 0.00001);
    if (start_end_x_y_dist_ratio > 1.5) {
      voteVertical += this.options.DISTANCE_VOTE_POWER;
    } else if (start_end_x_y_dist_ratio > 0.66) {
      voteDiagonal += this.options.DISTANCE_VOTE_POWER;
    } else {
      voteHorizontal += this.options.DISTANCE_VOTE_POWER;
    }

    const start_mid_dist = Math.sqrt(
      start_mid_x_dist * start_mid_x_dist + start_mid_y_dist * start_mid_y_dist,
    );
    const start_end_dist = Math.sqrt(
      start_end_x_dist * start_end_x_dist + start_end_y_dist * start_end_y_dist,
    );
    const mid_end_dist = Math.sqrt(
      mid_end_x_dist * mid_end_x_dist + mid_end_y_dist * mid_end_y_dist,
    );

    const max_dist = Math.max(start_mid_dist, start_end_dist, mid_end_dist);
    let calc_start_point_x = startPoint.x;
    let calc_start_point_y = startPoint.y;
    let calc_end_point_x = endPoint.x;
    let calc_end_point_y = endPoint.y;

    if (max_dist == start_mid_dist) {
      (calc_end_point_x = endPoint.x), (calc_end_point_y = endPoint.y);
    } else if (max_dist == mid_end_dist) {
      (calc_start_point_x = midPoint.x), (calc_start_point_y = midPoint.y);
    }

    const calcStartPoint = [calc_start_point_x, calc_start_point_y];
    const calcEndPoint = [calc_end_point_x, calc_end_point_y];

    const totalAngle = this.getSlope(calcStartPoint, calcEndPoint);
    const totalAngleVotes = this.angleOrientationAt(
      totalAngle,
      this.options.TOTAL_ANGLE_VOTE_POWER,
    );
    voteVertical += totalAngleVotes[0];
    voteDiagonal += totalAngleVotes[1];
    voteHorizontal += totalAngleVotes[2];

    for (const fingerSlope of fingerSlopes) {
      const fingerSlopeVotes = this.angleOrientationAt(
        fingerSlope,
        this.options.SINGLE_ANGLE_VOTE_POWER,
      );
      voteVertical += fingerSlopeVotes[0];
      voteDiagonal += fingerSlopeVotes[1];
      voteHorizontal += fingerSlopeVotes[2];
    }

    // in case of tie, highest preference goes to Vertical,
    // followed by horizontal and then diagonal
    let estimatedDirection;
    if (voteVertical == Math.max(voteVertical, voteDiagonal, voteHorizontal)) {
      estimatedDirection = this.estimateVerticalDirection(
        start_end_y_dist,
        start_mid_y_dist,
        mid_end_y_dist,
        max_dist_y,
      );
    } else if (voteHorizontal == Math.max(voteDiagonal, voteHorizontal)) {
      estimatedDirection = this.estimateHorizontalDirection(
        start_end_x_dist,
        start_mid_x_dist,
        mid_end_x_dist,
        max_dist_x,
      );
    } else {
      estimatedDirection = this.estimateDiagonalDirection(
        start_end_y_dist,
        start_mid_y_dist,
        mid_end_y_dist,
        max_dist_y,
        start_end_x_dist,
        start_mid_x_dist,
        mid_end_x_dist,
        max_dist_x,
      );
    }

    return estimatedDirection;
  }

  estimate(): HandInfo {
    const output: Array<FingerInfo> = [];

    // Part 1: Calculate Slopes of each finger
    const slopesAtXY: Array<number[]> = [];
    const slopesAtYZ: Array<number[]> = [];
    this.allFingers.forEach((finger) => {
      const points = this.getPointMapping(finger);
      const slopeAtXY: number[] = [];
      const slopeAtYZ: number[] = [];
      points.forEach((point) => {
        const point1 = this.landmarks[point[0]];
        const point2 = this.landmarks[point[1]];
        const slopes = this.getSlope(point1, point2); // returns [XYSlope, YZSlope]

        slopeAtXY.push(slopes[0]);
        slopeAtYZ.push(slopes[1]);
      });

      slopesAtXY.push(slopeAtXY);
      slopesAtYZ.push(slopeAtYZ);
    });

    // Part 2: Calculate orientations of each finger
    this.allFingers.forEach((finger) => {
      let direction = SortDirection.DESCENDING;
      // start finger predictions from palm - except for thumb
      const pointIndexAt = finger == FingerType.Thumb ? 1 : 0;
      const fingerPointsAt = this.getPointMapping(finger);
      const coordinates = this.getLinearPoints(finger);

      const startPoint = this.landmarks[fingerPointsAt[pointIndexAt][0]];
      const midPoint = this.landmarks[fingerPointsAt[pointIndexAt + 1][1]];
      const endPoint = this.landmarks[fingerPointsAt[3][1]];

      const fingerCurled = this.estimateFingerCurl(startPoint, midPoint, endPoint);

      const fingerPosition = this.calculateFingerDirection(
        startPoint,
        midPoint,
        endPoint,
        slopesAtXY[finger].slice(pointIndexAt),
      );

      // let coords = (finger === FingerType.Thumb) ? (coordinates.map((coordinate) => coordinate.x)) : coordinates.map((coordinate) => coordinate.y);
      const coordsY = coordinates.map((coordinate) => coordinate.y);
      const isLinearOpenY = Finger.isSorted(coordsY, direction);

      const coordsX = coordinates.map((coordinate) => coordinate.x);
      direction =
        this.handedness === Handedness.LEFT ? SortDirection.ASCENDING : SortDirection.DESCENDING;
      const isLinearOpenX = Finger.isSorted(coordsX, direction);

      output.push(
        new FingerInfo(
          finger as FingerType,
          fingerCurled,
          fingerPosition,
          isLinearOpenY,
          isLinearOpenX,
          coordinates,
        ),
      );
    });

    return new HandInfo(output, this.handedness);
  }

  static isSorted(arr: Array<any>, direction: SortDirection): boolean {
    arr = arr.slice(1, arr.length);
    if (direction === SortDirection.ASCENDING) {
      return !!arr.reduce((n, item) => n !== false && item > n && item);
    } else {
      return !!arr.reduce((n, item) => n !== false && item < n && item);
    }
  }
}

export enum FingerType {
  Thumb = 0,
  Index = 1,
  Middle = 2,
  Ring = 3,
  Pinky = 4,
}

export enum FingerCurl {
  NoCurl = 0,
  HalfCurl = 1,
  FullCurl = 2,
}

export enum FingerDirection {
  VerticalUp = 0,
  VerticalDown = 1,
  HorizontalLeft = 2,
  HorizontalRight = 3,
  DiagonalUpRight = 4,
  DiagonalUpLeft = 5,
  DiagonalDownRight = 6,
  DiagonalDownLeft = 7,
}

export enum Handedness {
  LEFT = 'Left',
  RIGHT = 'Right',
}

export enum SortDirection {
  ASCENDING,
  DESCENDING,
}

export class FingerInfo {
  fingerType!: FingerType; // Type of Finger
  fingerCurl!: FingerCurl; // Curl level of Finger
  fingerDirection!: FingerDirection; // Direction of Finger
  isLinearOpenY: boolean; // Rule based method to check if finger is open
  isLinearOpenX: boolean;
  coordinates: Array<any>; // All coordinates of finger

  constructor(
    fingerType: FingerType,
    fingerCurl: FingerCurl,
    fingerDirection: FingerDirection,
    isLinearOpenY: boolean,
    isLinearOpenX: boolean,
    coordinates: Array<any>,
  ) {
    (this.fingerType = fingerType),
      (this.fingerCurl = fingerCurl),
      (this.fingerDirection = fingerDirection),
      (this.isLinearOpenY = isLinearOpenY),
      (this.isLinearOpenX = isLinearOpenX),
      (this.coordinates = coordinates);
  }
}

export class HandInfo {
  allFingers!: Array<FingerInfo>;
  isLinearX!: boolean;
  handedness!: Handedness;

  constructor(fingerInfo: Array<FingerInfo>, handedness: Handedness) {
    this.allFingers = fingerInfo;
    this.handedness = handedness;
    const xCoords = this.allFingers.map((e: FingerInfo, i: number) => {
      if (i === FingerType.Thumb) {
        return e.coordinates[3].x;
      }
      return e.coordinates[1].x;
    });
    const sortDir =
      handedness === Handedness.RIGHT ? SortDirection.ASCENDING : SortDirection.DESCENDING;
    this.isLinearX = Finger.isSorted(xCoords, sortDir);
  }
}

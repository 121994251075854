import { Component, Inject } from '@angular/core';
import { MatSnackBarConfig, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snackbar',
  styles: [
    `
      #profiles-snackbar {
        font-size: 14px;
        display: flex;
        align-items: center;
      }
    `,
  ],
  template: `
    <div id="profiles-snackbar" [ngClass]="{ error: !!data.error }">
      <i *ngIf="data.error" class="material-icons snack-alert-icon">error</i>
      <div *ngIf="data.context === 'courses'">
        Updated <b>{{ data.name }}'s</b> courses
      </div>
      <div *ngIf="data.context === 'profile'">
        Updated <b>{{ data.name }}'s</b> profile
      </div>
      <div *ngIf="data.context === 'users'">
        Disabled <b>{{ data.total }} user(s)</b>
      </div>
      <div *ngIf="data.context === 'permission-denied'">You don't have sufficient permissions</div>
      <div *ngIf="data.context === 'password'">Password successfully changed!</div>
      <div *ngIf="data.context === 'incorrect-password'">Incorrect password!</div>
      <div *ngIf="data.context === 'file-not-deleted'">{{ data.msg }}</div>
      <div *ngIf="data.context === 'folder-creation'">{{ data.msg }}</div>
      <div *ngIf="data.context === 'file-moved'">{{ data.msg }}</div>
      <div *ngIf="data.context === 'general'">{{ data.msg }}</div>
      <div *ngIf="data.context === 'users-updated'">Updated {{ data.total }} user(s)</div>
    </div>
  `,
})
export class SnackbarComponent {
  message = '';
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}

export const snackBarConfig = (options): MatSnackBarConfig => {
  const config = new MatSnackBarConfig();
  config.verticalPosition = options.verticalPosition || 'top';
  config.horizontalPosition = options.horizontalPosition || 'center';
  config.duration = options.duration || 3000;
  config.data = options;
  config.panelClass = options.error ? 'profiles-snackbar-panel-error' : 'profiles-snackbar-panel';

  return config;
};

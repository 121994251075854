<div
  class="color-picker"
  [ngClass]="{ 'object-color-picker': currentActiveButtonForColorPicker }"
  [ngStyle]="customParentStyle"
>
  <div class="color-picker-header">
    <p class="main-header">
      <svg-icon icon="color_lens" width="24" height="24"></svg-icon>
      <span>{{ 'Pick Color' | translate }}</span>
    </p>

    <div class="icon-right">
      <mat-icon
        class="colorize-icon"
        [class.selected]="isEyeDropperToolSelected"
        (click)="handleEyeDropperToolClicked()"
        *ngIf="isEyeDropperSupported"
      >
        colorize
      </mat-icon>
      <mat-icon class="material-icons-outlined close-icon" (click)="closeColorPicker(true)">
        close
      </mat-icon>
    </div>
  </div>

  <div class="color-picker-body">
    <color-chrome
      [color]="currentActiveColor"
      [disableAlpha]="disableAlpha"
      (onChangeComplete)="handleColorPickerChangeComplete($event)"
    ></color-chrome>
  </div>

  <div class="d-flex justify-content-end cp-16 color-picker-footer">
    <button
      class="btn btn-light fw-6 d-flex align-items-center"
      (click)="handleCancelColorPicker()"
    >
      <em class="material-icons cmr-8 font-18">close</em> {{ 'Cancel' | translate }}
    </button>
    <button
      class="btn btn-primary fw-6 d-flex align-items-center text-white"
      (click)="handleDoneSelectingColorFromPicker()"
    >
      <em class="material-icons cmr-8 font-18">done</em>
      {{ 'Confirm' | translate }}
    </button>
  </div>
</div>

import { Injectable } from '@angular/core';
import { createStore, distinctUntilArrayItemChanged, emitOnce, filterNil } from '@ngneat/elf';
import {
  UIEntitiesRef,
  deleteEntities,
  entitiesPropsFactory,
  getActiveEntity,
  getActiveId,
  getAllEntities,
  getEntity,
  resetActiveId,
  selectActiveId,
  selectAllEntities,
  selectEntities,
  selectEntity,
  setActiveId,
  unionEntities,
  updateEntities,
  upsertEntities,
  upsertEntitiesById,
  withActiveId,
  withEntities,
  withUIEntities,
} from '@ngneat/elf-entities';
import { isEqual } from 'lodash-es';
import {
  EMPTY,
  Observable,
  OperatorFunction,
  auditTime,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
  pairwise,
  skip,
  startWith,
  switchMap,
  tap,
  zip,
} from 'rxjs';
import { isPermissionEnabled } from '../common/utils/common-util';
import { SUBSCRIPTION_TYPES } from '../models/payment';
import {
  BoardFolder,
  Frame,
  ISession as ISpace,
  Room,
  Session,
  SessionUser,
  Permissions,
  ItemModel,
  Visibility,
  ISession,
} from '../models/session';
import { User } from '../models/user';
import { UserService } from '../services/user.service';
import { Metadata, YCanvasItemObject, YObject } from '../sessions/common/y-session';
import { TelemetryService } from '../services/telemetry.service';
import { unionEntity } from '../utilities/TypesUtils';
import { AclService } from '../services/acl.service';
import { TemporaryUserMetadataRepositoryService } from './temporary-user-metadata-repository.service';

export interface ISpaceUI {
  _id: ISpace['_id'];
  selectedBoardUid?: string;
  selectedFolderBoardUid?: string;
  currentRoomUid?: string;
  currUser?: User;
  breakoutRoomsEnabled?: boolean;
  hasSpaceSynced?: boolean;
  isInitialSpaceStateLoaded?: boolean;
  aiAssistantOpen?: boolean;
}

const { yjsEntitiesRef, withYjsEntities } = entitiesPropsFactory('yjs');

export interface ISpaceYjs {
  _id: string;
  rooms: Room[];
  // Maps from room id to a list of frames
  frames: Record<string, Frame[]>;

  boardFolders: BoardFolder[];
  // Maps from frame uid to list of fabric objects
  fabricItems: Record<string, YObject[]>;
  // Maps from frame uid to list of cavnas items
  canvasItems: Record<string, YCanvasItemObject[]>;
  metadata: Metadata;

  lastUpdateLocal: boolean;
  lastActionIsLocal: boolean;
}

@Injectable({ providedIn: 'root' })
export class SpaceRepository {
  constructor(
    private userService: UserService,
    private telemetry: TelemetryService,
    private temporaryUserMetadataRepositoryService: TemporaryUserMetadataRepositoryService,
    private aclService: AclService,
  ) {}

  private readonly store = createStore(
    { name: 'spaces' },
    withEntities<ISpace, '_id'>({ idKey: '_id' }),
    withUIEntities<ISpaceUI, '_id'>({ idKey: '_id' }),
    withYjsEntities<ISpaceYjs, '_id'>({ idKey: '_id' }),
    withActiveId(),
  );

  public readonly spaces: (ISpace & ISpaceUI)[] = this.store
    .query(getAllEntities())
    .map((entity) => ({
      ...entity,
      ...this.store.state.UIEntities[entity._id],
    }));

  public readonly spaces$ = this.store
    .combine({
      entities: this.store.pipe(selectAllEntities()),
      UIEntities: this.store.pipe(selectEntities({ ref: UIEntitiesRef })),
    })
    .pipe(unionEntities('_id')); // join on '_id'

  public get activeSpaceId(): string | undefined {
    return this.store.query(getActiveId);
  }

  public readonly activeSpaceId$: Observable<string | undefined> = this.store.pipe(
    selectActiveId(),
  );

  public readonly currentUserLeftSpace$ = this.activeSpaceId$.pipe(
    pairwise(),
    filter(([prev, cur]) => !!prev && !cur),
    map(() => null),
  );

  public get activeSpace(): (ISpace & ISpaceUI) | undefined {
    const entity = this.store.query(getActiveEntity());
    if (entity) {
      return {
        ...entity,
        ...this.store.state.UIEntities[entity._id],
      };
    }

    return undefined;
  }

  public readonly activeSpace$ = this.store
    .pipe(selectActiveId())
    .pipe(switchMap((activeId) => this.getSpace$(activeId)))
    .pipe(
      distinctUntilChanged(isEqual),
      tap((space) => {
        if (space?.institution?._id) {
          this.telemetry.setSessionVars({
            space_institution_id: space.institution._id,
          });
        }
      }),
    );

  public activeSpaceTitle$ = this.activeSpace$.pipe(
    filterNil(),
    map((space) => space.title),
    distinctUntilChanged(),
  );

  public readonly spaceVisibility$: Observable<Visibility> = this.activeSpace$.pipe(
    filterNil(),
    map((space) => space.visibility),
    distinctUntilChanged(),
  );

  public readonly activeSpaceUsers$ = this.activeSpace$.pipe(
    map((activeSpace) => activeSpace?.users ?? []),
  );

  public readonly activeSpacePopulatedUsers$ = this.activeSpace$.pipe(
    map((activeSpace) => activeSpace?.populatedUsers ?? []),
  );

  public readonly currentSessionUser$ = this.activeSpaceUsers$.pipe(
    map((users) => users.find((u) => u._id === this.activeSpace?.currUser?._id)),
    filterNil(),
    distinctUntilChanged(isEqual),
  );

  public readonly activeSpaceUsersMap$ = this.activeSpaceUsers$.pipe(distinctUntilChanged()).pipe(
    map((activeSpaceUsers) => {
      const users: Map<string, SessionUser> = new Map();
      for (const user of activeSpaceUsers) {
        users.set(user._id, user);
      }
      return users;
    }),
  );

  public readonly hasActiveSpaceSynced$ = this.activeSpace$.pipe(
    map((space) => space?.hasSpaceSynced ?? false),
  );

  public activeSyncedOrLeftSpaceId$ = this.activeSpace$.pipe(
    map((space) => (space?.hasSpaceSynced ? space?._id : undefined)),
    distinctUntilChanged(),
    skip(1),
  );

  public get activeSpaceSelectedBoardUid(): string | undefined {
    return this.activeSpace?.selectedBoardUid;
  }

  public readonly activeSpaceSelectedBoardUid$ = this.activeSpace$
    .pipe(auditTime(0))
    .pipe(map((space) => space?.selectedBoardUid))
    .pipe(distinctUntilChanged());

  public get activeSpaceCurrentRoomUid(): string | undefined {
    return this.activeSpace?.currentRoomUid;
  }

  public readonly activeSpaceRooms$ = this.activeSpaceId$
    .pipe(
      switchMap((spaceId) => {
        if (!spaceId) {
          return EMPTY;
        }
        return this.spaceRooms$(spaceId);
      }),
    )
    .pipe(distinctUntilArrayItemChanged());

  public readonly isCurrentUserHost$ = combineLatest([
    this.userService.user.pipe(
      filterNil(),
      map((u) => u.user),
    ),
    this.activeSpace$.pipe(filterNil()),
  ]).pipe(
    map(([user, space]) => Session.isOwnedByUser(space, user)),
    distinctUntilChanged((prev, curr) => prev === curr),
  );

  public isUserHost$ = (userId: string) =>
    this.activeSpace$.pipe(
      filterNil(),
      map((space) => Session.isOwnedByUser(space, userId)),
      distinctUntilChanged(),
    );

  public spaceHostUserIds$ = () =>
    this.activeSpace$.pipe(
      filterNil(),
      map((space) => Session.getSpaceHostsIds(space)),
      distinctUntilChanged(isEqual),
    );

  public isCurrentUserHost(): boolean {
    return Session.isOwnedByUser(this.activeSpace, this.userService.user.getValue()?.user);
  }

  public get activeSpaceRooms(): Room[] {
    if (!this.activeSpaceId) {
      return [];
    }

    return this.getSpaceRooms(this.activeSpaceId);
  }

  public getSpaceRooms(spaceId: string): Room[] {
    return this.store.state.yjsEntities?.[spaceId]?.rooms ?? [];
  }

  public getSpaceRoomById(id: string): Room | undefined {
    if (!this.activeSpaceId) {
      return undefined;
    }

    return this.getSpaceRooms(this.activeSpaceId).find((r) => r.uid === id);
  }

  public readonly activeSpaceCurrentRoomUid$ = this.activeSpace$
    .pipe(map((space) => space?.currentRoomUid))
    .pipe(distinctUntilChanged());

  public get activeSpaceCurrentRoom(): Room | undefined {
    return this.getRoomInSpace(this.activeSpace?.currentRoomUid, this.activeSpace);
  }

  public readonly activeSpaceCurrentRoom$: Observable<Room | undefined> = this.activeSpaceId$.pipe(
    filterNil(),
    switchMap((id) => combineLatest([this.activeSpaceCurrentRoomUid$, this.spaceRooms$(id)])),
    map(([roomUid, rooms]) => rooms.find((r) => r.uid === roomUid)),
    tap((room) => {
      this.telemetry.setSessionVars({
        breakouts_is_in_breakout_room: room?.name !== 'Main Room',
      });
    }),
  );

  public sessionPermissions$ = combineLatest([
    this.activeSpace$.pipe(startWith(null)),
    this.activeSpaceCurrentRoom$.pipe(startWith(null)),
  ]).pipe(
    map(([space, room]) => {
      if (room?.permissions) {
        return { ...room.permissions };
      } else if (space?.sessionPermissions) {
        return { ...space.sessionPermissions };
      }
    }),
  );

  public get activeSpaceCurrentRoomIndex(): number {
    return this.getIndexOfRoomInSpace(this.activeSpace?.currentRoomUid, this.activeSpace);
  }

  public readonly activeSpaceCurrentRoomIndex$ = this.activeSpace$
    .pipe(map((space) => this.getIndexOfRoomInSpace(space?.currentRoomUid, space)))
    .pipe(distinctUntilChanged());

  public getSpace(id: string): (ISpace & ISpaceUI) | undefined {
    const space = this.store.query(getEntity(id));
    if (space) {
      return {
        ...space,
        ...this.store.state.UIEntities[space._id],
      };
    }
  }

  public getSpace$(id: string): Observable<(ISpace & ISpaceUI) | undefined> {
    return this.store
      .combine({
        entity: this.store.pipe(selectEntity(id)),
        UIEntity: this.store.pipe(selectEntity(id, { ref: UIEntitiesRef })),
      })
      .pipe(unionEntity());
  }

  public addSpace(space: ISpace, currUser?: User, breakoutRoomsEnabled?: boolean): void {
    this.store.update(
      // add Space
      upsertEntities(space),
      upsertEntitiesById(space._id, {
        updater: (e) => e,
        creator: (_id) => ({
          _id,
          currentRoomUid: '',
          currUser: currUser,
          breakoutRoomsEnabled: !!breakoutRoomsEnabled,
        }), // @TODO (stoor): Remove default roomId once breakout rooms is rolled out
        ref: UIEntitiesRef,
      }),
    );
    this.setSpaceCurrentRoom(space._id);
  }

  public deleteSpace(spaceId: string) {
    this.store.update(deleteEntities(spaceId));
  }

  public setActiveSpace(spaceId: string): void {
    this.store.update(setActiveId(spaceId));
  }

  public resetActiveSpace(): void {
    this.store.update(resetActiveId());
  }

  public updateSpace(spaceId: string, updatedSpace: Partial<ISpace>): void {
    const space = this.store.query(getEntity(spaceId));
    if (space) {
      emitOnce(() => {
        // Emit a single/bulk update after all updates
        this.store.update(
          // update Space
          updateEntities(spaceId, updatedSpace),
          upsertEntitiesById(spaceId, {
            updater: (e) => e,

            creator: (_id) => ({ _id, currentRoomUid: '' }), // @TODO (stoor): Remove default roomId once breakout rooms is rolled out
            ref: UIEntitiesRef,
          }),
        );

        // If the users have changed, check the current room are present in the update
        if (Object.prototype.hasOwnProperty.call(updatedSpace, 'users')) {
          this.setSpaceCurrentRoom(spaceId);
        }
      });
    } else {
      console.warn('not able to update a non existing space');
    }
  }

  public updateSpaceUI(spaceId: string, updatedSpaceUI: Partial<ISpaceUI>): void {
    if (Object.prototype.hasOwnProperty.call(updatedSpaceUI, 'selectedBoardUid')) {
      // if selectedBoardUid is present in the update
      throw new Error(
        'selectedBoardUid should only be set using the space-boards.service. This may bypass neccessary validation',
      );
    }

    emitOnce(() => {
      // Emit a single/bulk update after all updates
      this.store.update(updateEntities(spaceId, updatedSpaceUI, { ref: UIEntitiesRef }));

      if (Object.prototype.hasOwnProperty.call(updatedSpaceUI, 'currentRoomUid')) {
        // if currentRoomUid is present in the update
        this.setSpaceCurrentRoom(spaceId);
      }
    });
  }

  public updateSpaceYjs(spaceId: string, updatedSpaceYJS: Partial<ISpaceYjs>): void {
    emitOnce(() => {
      const _updatedFrames = deduplicateFrames(updatedSpaceYJS.frames ?? {});

      const _updatedFabricItems = deduplicateFabricItems(updatedSpaceYJS.fabricItems ?? {});

      const _updatedRooms = updatedSpaceYJS.rooms?.map(this.insertDefaultPermissions);
      this.store.update(
        upsertEntitiesById(spaceId, {
          ref: yjsEntitiesRef,
          creator: (_id) => ({
            _id,
            rooms: [],
            frames: {},
            boardFolders: [],
            canvasItems: {},
            fabricItems: {},
            metadata: {
              temporaryUserMetadata: {},
              sessionMetadata: {},
              userMetadata: {},
            },
            lastUpdateLocal: false,
            lastActionIsLocal: false,
          }),
          updater: (spaceYjs) => ({
            _id: spaceYjs._id,
            rooms: _updatedRooms ?? spaceYjs.rooms,
            frames: { ...spaceYjs.frames, ..._updatedFrames },
            canvasItems: { ...spaceYjs.canvasItems, ...(updatedSpaceYJS.canvasItems ?? {}) },
            fabricItems: { ...spaceYjs.fabricItems, ..._updatedFabricItems },
            metadata: updatedSpaceYJS.metadata ?? spaceYjs.metadata,
            lastUpdateLocal: updatedSpaceYJS.lastUpdateLocal ?? spaceYjs.lastUpdateLocal,
            lastActionIsLocal: updatedSpaceYJS.lastActionIsLocal ?? spaceYjs.lastActionIsLocal,
            boardFolders: updatedSpaceYJS.boardFolders ?? spaceYjs.boardFolders,
          }),
        }),
      );

      if (updatedSpaceYJS.metadata?.temporaryUserMetadata) {
        this.temporaryUserMetadataRepositoryService.setFullStateFromYjs(
          this.store.state.yjsEntities[spaceId].metadata?.temporaryUserMetadata,
        );
      }

      if (updatedSpaceYJS.rooms) {
        this.setSpaceCurrentRoom(spaceId);
      }
    });
  }

  private insertDefaultPermissions(room: Room): Room {
    const defaultPermissions = new Permissions();
    room.permissions = { ...defaultPermissions, ...room.permissions };
    return room;
  }

  public spaceMetadata$(spaceId: string): Observable<Metadata> {
    return this.store.pipe(
      selectEntity(spaceId, { ref: yjsEntitiesRef }),
      filterNil(),
      auditTime(0),
      map((o) => o?.metadata),
      distinctUntilChanged(),
    );
  }

  public spaceRooms$(spaceId: string): Observable<Room[]> {
    return this.store.pipe(
      selectEntity(spaceId, { ref: yjsEntitiesRef }),
      map((o) => o?.rooms),
      filterNil(),
      distinctUntilArrayItemChanged(),
    );
  }

  public readonly activeSpaceLocked$ = combineLatest([
    this.activeSpace$.pipe(filterNil()),
    this.activeSpaceCurrentRoom$.pipe(filterNil()),
    this.userService.user.asObservable(),
  ]).pipe(
    map(
      ([space, room, currentUser]) =>
        space.isLocked ||
        !space.sessionPermissions.editSpace ||
        // all participants don't have an edit space permission
        room.permissions?.editSpace === false ||
        // this condition should apply to participants only because hosts don't have any entry in the permissions dictionary
        (this.isCurrentUserSpaceParticipant &&
          !isPermissionEnabled(room.permissions, 'editSpace', currentUser?.user._id as string)),
    ),
    distinctUntilChanged(),
  );

  public spaceFrames$(spaceId: string, roomUid: string): Observable<Frame[]> {
    return this.hasSpaceSynced$(spaceId).pipe(
      switchMap((hasSpaceSynced) => {
        if (hasSpaceSynced) {
          return this._spaceFrames$(spaceId, roomUid);
        } else {
          return EMPTY;
        }
      }),
    );
  }

  private _spaceFrames$(spaceId: string, roomUid: string): Observable<Frame[]> {
    return this.store.pipe(
      selectEntity(spaceId, { ref: yjsEntitiesRef }),
      map((o) => {
        // @TODO (mfmansoo) depricate this once breakout rooms is in prod
        // leave the else condition block
        if (roomUid === '') {
          return Object.values(o?.frames ?? {}).flat(1);
        } else {
          return o?.frames?.[roomUid];
        }
      }),
      auditTime(0),
      filterNil(),
      distinctUntilArrayItemChanged(),
    );
  }

  public spaceBoardFolders$(spaceId: string, roomUid: string): Observable<BoardFolder[]> {
    return this.store.pipe(
      selectEntity(spaceId, { ref: yjsEntitiesRef }),
      map((o) => {
        if (roomUid === '') {
          return Object.values(o?.boardFolders ?? {}).flat(1);
        } else {
          return o?.boardFolders?.filter((boardFolder) => boardFolder.roomUid === roomUid);
        }
      }),
      auditTime(0),
      filterNil(),
      distinctUntilArrayItemChanged(),
    );
  }

  public getSpaceBoardFolders(spaceId: string, roomUid: string): BoardFolder[] {
    if (roomUid === '') {
      return Object.values(this.store.state['yjsEntities'][spaceId].boardFolders).flat(1);
    } else {
      return this.store.state['yjsEntities'][spaceId].boardFolders?.filter(
        (boardFolder) => boardFolder.roomUid === roomUid,
      );
    }
  }

  public getSpaceFrames(spaceId: string, roomUid: string): Frame[] {
    // @TODO (mfmansoo) depricate this once breakout rooms is in prod
    // leave the else condition block
    if (roomUid === '') {
      return Object.values(this.store.state['yjsEntities'][spaceId].frames).flat(1);
    } else {
      return this.store.state['yjsEntities'][spaceId].frames[roomUid];
    }
  }

  public boardFabricItems$(
    spaceId: string,
    frameUid: string,
  ): Observable<{ objects: YObject[]; lastUpdateLocal: boolean; lastActionIsLocal: boolean }> {
    return this.hasSpaceSynced$(spaceId).pipe(
      switchMap((hasSpaceSynced) => {
        if (hasSpaceSynced) {
          return this._boardFabricItems$(spaceId, frameUid);
        } else {
          return EMPTY;
        }
      }),
    );
  }

  private _boardFabricItems$(
    spaceId: string,
    frameUid: string,
  ): Observable<{
    objects: YObject[];
    lastUpdateLocal: boolean;
    lastActionIsLocal: boolean;
  }> {
    return this.store.pipe(
      selectEntity(spaceId, { ref: yjsEntitiesRef }),
      map((o) => ({
        objects: o?.fabricItems[frameUid],
        lastUpdateLocal: !!o?.lastUpdateLocal,
        lastActionIsLocal: o?.lastActionIsLocal,
      })),
      filter(({ objects }) => objects !== undefined),
      // Check to ensure its not undefined or nil above with filter
      map((o) => o as { objects: YObject[]; lastUpdateLocal: boolean; lastActionIsLocal: boolean }),
      distinctUntilChanged((prev, cur) => this.shallowArrayEqual(prev.objects, cur.objects)),
    );
  }

  public boardCanvasItems$(
    spaceId: string,
    frameUid: string,
  ): Observable<{
    objects: YCanvasItemObject[];
    lastUpdateLocal: boolean;
    lastActionIsLocal: boolean;
  }> {
    return this.hasSpaceSynced$(spaceId).pipe(
      switchMap((hasSpaceSynced) => {
        if (hasSpaceSynced) {
          return this._boardCanvasItems$(spaceId, frameUid);
        } else {
          return EMPTY;
        }
      }),
    );
  }

  private hasSpaceSynced$(spaceId: string): Observable<boolean> {
    return this.getSpace$(spaceId).pipe(
      map((s) => s?.hasSpaceSynced ?? false),
      distinctUntilChanged(),
    );
  }

  private _boardCanvasItems$(
    spaceId: string,
    frameUid: string,
  ): Observable<{
    objects: YCanvasItemObject[];
    lastUpdateLocal: boolean;
    lastActionIsLocal: boolean;
  }> {
    return this.store.pipe(
      selectEntity(spaceId, { ref: yjsEntitiesRef }),
      map((o) => ({
        objects: o?.canvasItems[frameUid],
        lastUpdateLocal: !!o?.lastUpdateLocal,
        lastActionIsLocal: !!o?.lastActionIsLocal,
      })),
      filter(({ objects }) => objects !== undefined),
      map(
        (o) =>
          o as {
            objects: YCanvasItemObject[];
            lastUpdateLocal: boolean;
            lastActionIsLocal: boolean;
          },
      ),
      distinctUntilChanged((prev, cur) => this.shallowArrayEqual(prev.objects, cur.objects)),
    );
  }

  private shallowArrayEqual<T>(a: T[], b: T[]): boolean {
    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }

    return true;
  }

  /**
   * Returns initial items on the current board
   * Fires only on board Change, doesn't fire on any item updates within the same board
   */

  public readonly InitialBoardCanvasAndFabricItems$ = combineLatest([
    this.activeSpaceId$.pipe(filterNil()),
    this.activeSpaceSelectedBoardUid$.pipe(filterNil()),
  ]).pipe(
    filterNil(),
    switchMap(([activeSpaceId, boardId]) =>
      zip([
        this.boardCanvasItems$(activeSpaceId, boardId),
        this.boardFabricItems$(activeSpaceId, boardId),
        of(boardId),
      ]),
    ),
  );

  /**
   * Returns Current items on the current board
   * Fires on board change or any item updates within the same board
   */
  public readonly CurrentBoardCanvasAndFabricItems$ = this.activeSpaceSelectedBoardUid$
    .pipe(filterNil(), distinctUntilChanged())
    .pipe(
      switchMap((boardId) =>
        combineLatest([
          this.boardCanvasItems$(this.activeSpaceId ?? '', boardId).pipe(),
          this.boardFabricItems$(this.activeSpaceId ?? '', boardId).pipe(),
          of(boardId),
        ]),
      ),
    );

  public readonly CanvasIsEmpty$: Observable<boolean> = this.activeSpace$.pipe(
    filterNil(),
    switchMap((activeSpace) =>
      combineLatest([
        this.boardCanvasItems$(activeSpace._id, activeSpace.selectedBoardUid ?? ''),
        this.boardFabricItems$(activeSpace._id, activeSpace.selectedBoardUid ?? ''),
      ]),
    ),
    map(([canvasItems, fabricItems]) => !canvasItems.objects.length && !fabricItems.objects.length),
    distinctUntilChanged(),
  );

  public getBoardItemsByIds(
    boardId: string,
    ids: string[],
  ): {
    canvasItems: YCanvasItemObject[];
    fabricItems: YObject[];
  } {
    const entity = this.store.query(getActiveEntity());
    const frameItems = entity ? this.store.state.yjsEntities[entity._id] : undefined;
    if (entity && frameItems) {
      const itemIds = new Set<string>(ids);
      let canvasItems = frameItems.canvasItems[boardId];
      let fabricItems = frameItems.fabricItems[boardId];

      canvasItems = canvasItems.filter((item) => item._id && itemIds.has(item._id));
      fabricItems = fabricItems.filter((item) => item.uid && itemIds.has(item.uid));

      return {
        canvasItems: canvasItems,
        fabricItems: fabricItems,
      };
    }

    return {
      canvasItems: [],
      fabricItems: [],
    };
  }

  // WARNING:
  // this should only be called by the space-boards.service.ts
  public _setSpaceSelectedBoard(spaceId: string, selectedBoardUid: string | undefined): void {
    const space = this.store.query(getEntity(spaceId));
    if (space) {
      this.store.update(updateEntities(spaceId, { selectedBoardUid }, { ref: UIEntitiesRef }));
    }
  }

  /**
   * set selected board folder, used by board folders service
   * @param spaceId
   * @param selectedFolderBoardUid
   */
  public _setSpaceSelectedBoardFolder(
    spaceId: string,
    selectedFolderBoardUid: string | undefined,
  ): void {
    const space = this.store.query(getEntity(spaceId));
    if (space) {
      this.store.update(
        updateEntities(spaceId, { selectedFolderBoardUid }, { ref: UIEntitiesRef }),
      );
    }
  }

  /**
   * check if the current room is the main room
   * @param isBreakOutRoomsEnabled
   */
  public isCurrentRoomMainRoom(isBreakOutRoomsEnabled: boolean): boolean {
    return Session.isMainRoom(
      this.activeSpaceCurrentRoom?.uid ?? '',
      this.activeSpaceId ?? '',
      isBreakOutRoomsEnabled,
    );
  }
  
  public activeSpaceSelectedBoardFolderUid$ = this.activeSpace$.pipe(
    map((space) => space?.selectedFolderBoardUid),
    distinctUntilChanged(),
  );

  public setSpaceCurrentRoom(spaceId: string, currentRoomUid?: string): void {
    const space = this.getSpace(spaceId);
    currentRoomUid =
      currentRoomUid ??
      space?.users?.filter((user) => user._id === space?.currUser?._id)[0]?.roomId;
    currentRoomUid = space?.breakoutRoomsEnabled
      ? currentRoomUid ?? Session.getMainRoomId(spaceId, space.breakoutRoomsEnabled)
      : '';
    const roomFound = this.getIndexOfRoomInSpace(currentRoomUid, space) !== -1;
    if (space) {
      this.store.update(
        updateEntities(
          spaceId,
          { currentRoomUid: roomFound ? currentRoomUid : Session.getMainRoomId(spaceId) },
          { ref: UIEntitiesRef },
        ),
      );
    }
  }

  public setActiveSpaceCurrentRoom(currentRoomUid: string): void {
    if (this.activeSpace) {
      this.setSpaceCurrentRoom(this.activeSpace._id, currentRoomUid);
    }
  }

  public getCurrentRoom(spaceId: string): Room | undefined {
    const space = this.getSpace(spaceId);
    return this.getRoomInSpace(space?.currentRoomUid, space);
  }

  private getIndexOfRoomInSpace(roomUid?: string, space?: ISpace) {
    if (!space?._id) {
      return -1;
    }

    const rooms = this.getSpaceRooms(space?._id);
    const index = rooms.findIndex((room) => room.uid === roomUid);
    if (index !== undefined) {
      return index;
    }
    return -1;
  }

  private getRoomInSpace(roomUid?: string, space?: ISpace): Room | undefined {
    if (!space?._id) {
      return;
    }

    const index = this.getIndexOfRoomInSpace(roomUid, space);
    if (index !== -1) {
      return this.getSpaceRooms(space._id)[index];
    }
  }

  // returns the user who created this space (based on space.owner property)
  spaceOwner$: Observable<User | undefined> = this.activeSpace$.pipe(
    filterNil(),
    map(getSpaceOwner),
  );

  // determine either the logged user is the owner of the space or not
  isCurrentUserOwnerOfSpace$: Observable<boolean> = combineLatest([
    this.spaceOwner$,
    this.userService.user.asObservable(),
  ]).pipe(
    map(([spaceOwner, currentUser]) => spaceOwner?._id === currentUser?.user._id),
    distinctUntilChanged(),
  );

  // returns subscriptionType of the space owner
  spaceOwnerSubscriptionType$: Observable<SUBSCRIPTION_TYPES | undefined> = this.spaceOwner$.pipe(
    map((owner) => owner?.subscriptionType),
    distinctUntilChanged(),
  );

  get currentUserRole(): { owner: boolean } {
    const currUser = this.activeSpace?.users.find(
      (user) => user._id === this.activeSpace?.currUser?._id,
    );

    return {
      owner: Boolean(currUser?.isOwner),
    };
  }

  readonly currentUserRole$: Observable<{ owner: boolean }> = this.activeSpace$
    .pipe(
      map((activeSpace) => {
        const currentUser = activeSpace?.users?.find(
          (u) => activeSpace.currUser && activeSpace.currUser._id === u._id && u.isOwner,
        );

        return {
          owner: Boolean(currentUser),
        };
      }),
    )
    .pipe(distinctUntilChanged((prev, curr) => prev.owner === curr.owner));

  get isCurrentUserSpaceHost(): boolean {
    return Boolean(
      this.activeSpace?.users?.find(
        (user) => user.isOwner && this.activeSpace?.currUser?._id === user._id,
      ),
    );
  }

  readonly isCurrentUserSpaceHost$ = this.currentUserRole$.pipe(map((val) => val.owner));

  get isCurrentUserSpaceParticipant(): boolean {
    return !this.isCurrentUserSpaceHost;
  }

  public selectedBoardCanvasItemsCountByItemModalType$(itemModel: ItemModel) {
    return this.activeSpaceSelectedBoardUid$.pipe(filterNil(), distinctUntilChanged()).pipe(
      switchMap((boardId) =>
        this.boardCanvasItems$(this.activeSpaceId ?? '', boardId).pipe(
          map((items) => items.objects.filter((item) => item.model === itemModel).length),
        ),
      ),
      distinctUntilChanged(),
    );
  }

  isInstitutionAdminInSpace(user: User, space: ISession & ISpaceUI) {
    const isInstitutionAdmin = user?.institution && this.aclService.isAdmin(user);

    return (
      isInstitutionAdmin &&
      user?.institution?._id === (space?.institutionID ?? space?.institution?._id)
    );
  }

  isSiteAdminInSpace(user: User, space: ISession & ISpaceUI): boolean {
    const isSiteAdmin: boolean =
      !!user.institution &&
      !!user.sites &&
      user.sites.length > 0 &&
      this.aclService.isSiteAdmin(user);

    return isSiteAdmin && !!space.site && !!user.sites?.find((site) => site._id === space.site);
  }

  hasSpaceAdminPermissions(user?: User, space?: ISession & ISpaceUI) {
    if (!user || !space) {
      return false;
    }

    return (
      !!user.institution?.settings?.addAdminsAsHostsInSpace &&
      (this.isInstitutionAdminInSpace(user, space) || this.isSiteAdminInSpace(user, space))
    );
  }

  hasSpaceHostPermissions(user?: User, space?: ISession & ISpaceUI) {
    if (!user || !space) {
      return false;
    }

    return this.isSpaceOwnerOrHost(user, space) || this.hasSpaceAdminPermissions(user, space);
  }

  isSpaceOwnerOrHost(user: User, space?: Session) {
    if (!space) {
      space = this.activeSpace;
    }
    if (!space) {
      return false;
    }
    return (
      space.owner === user?._id ||
      !!(space.users || []).find((spaceUser) => spaceUser?._id === user?._id && spaceUser?.isOwner)
    );
  }

  public isAiAssistantOpen$ = this.activeSpace$.pipe(
    filterNil(),
    map((space) => space.aiAssistantOpen),
    distinctUntilChanged(),
  );
}

/**
 * Utility to remove duplicate frames from the frames data
 * @param framesMapping
 * @returns
 */
const deduplicateFrames = (framesMapping: Record<string, Frame[]>): Record<string, Frame[]> => {
  for (const frames of Object.values(framesMapping)) {
    deduplicateUniqueArray(
      frames,
      (frame) => frame.uid,
      (mFrames, lastIndex, duplicatedValue) => {
        const lastFrame = mFrames[lastIndex];
        const combinedFrameProps = new Frame({
          ...duplicatedValue,
          ...lastFrame,
        });
        mFrames[lastIndex] = combinedFrameProps;
      },
    );
  }
  return framesMapping;
};

const deduplicateFabricItems = (
  fabricItemsMapping: Record<string, YObject[]>,
): Record<string, YObject[]> => {
  for (const fabricItems of Object.values(fabricItemsMapping)) {
    deduplicateUniqueArray(fabricItems, (item) => item.uid ?? '');
  }

  return fabricItemsMapping;
};

/**
 * Deduplicates an array, mutating the reference being passed in
 */
const deduplicateUniqueArray = <T>(
  array: Array<T>,
  keyFn: (item: T) => string,
  setterFn?: (array: Array<T>, firstOccurrence: number, duplicatedValue: T) => void,
) => {
  const seenItems: Map<string, number> = new Map();
  const itemsToDelete: number[] = [];

  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    const key = keyFn(item);

    if (seenItems.has(key)) {
      // Mark this item for deletion
      itemsToDelete.push(i);

      // If a setter function is provided, use it to update the previously seen item
      // This assumes setterFn can handle merging or updating items as necessary
      if (setterFn) {
        const seenIndex = seenItems.get(key);
        if (!seenIndex) {
          throw new Error('Seen index is undefined');
        }
        setterFn(array, seenIndex, item);
      }
    } else {
      // Store the index of the first occurrence of this item
      seenItems.set(key, i);
    }
  }

  // Delete marked items in reverse order to avoid shifting indices affecting the deletion
  for (let i = itemsToDelete.length - 1; i >= 0; i--) {
    array.splice(itemsToDelete[i], 1);
  }

  return itemsToDelete.length > 0;
};

export function filterNotSynced<T extends ISpaceUI>(): OperatorFunction<T | undefined, T> {
  return (source) =>
    source.pipe(
      filterNil(),
      filter((session) => !!session?.hasSpaceSynced),
    );
}

export function getSpaceOwner(space: ISpace | undefined): User | undefined {
  return space?.populatedUsers.find((u) => space.owner === u._id);
}

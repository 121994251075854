import { Pipe, PipeTransform } from '@angular/core';
import { LikeContext } from '../models/user';

@Pipe({
  name: 'likeContext',
})
export class LikeContextPipe implements PipeTransform {
  transform(explanation): any {
    const likeContext: LikeContext = {
      explanation: explanation._id,
    };
    return likeContext;
  }
}

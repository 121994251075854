<div class="tag-menu" (click)="$event.stopPropagation()">
  <app-search-bar
    class="mb-2"
    [searchTitle]="'Search tags' | translate"
    [searchContinuously]="true"
    (filter)="setSearchValue($event)"
  >
  </app-search-bar>
  <ng-container *ngIf="selectedTags.length > 0 || showTagEditor">
    <div class="tag-header d-inline-block" (click)="$event.stopPropagation()">
      {{ 'Current Tags' | translate }}
    </div>
    <i
      class="material-icons align-middle small-icon ms-1"
      tooltipClass="text-center"
      [appTooltip]="
        'You can filter by these tags when importing from Files across Pencil' | translate
      "
      appTooltipArrow="bottom"
    >
      info
    </i>
    <cdk-virtual-scroll-viewport
      class="tag-scroll"
      appendOnly
      itemSize="30"
      [ngStyle]="{ 'height.px': selectedTags.length * 50 }"
    >
      <div
        class="tag-item"
        *cdkVirtualFor="let tag of selectedTags; let i = index"
        (click)="$event.stopPropagation()"
      >
        <mat-checkbox
          class="tag-checkbox d-inline-block"
          (click)="
            $event.preventDefault();
            tagOnAllSelected[tag._id]
              ? openTagConfirmationDialog($event, tagAction.REMOVE, tag)
              : openTagConfirmationDialog($event, tagAction.ADD, tag)
          "
          [checked]="tagOnAllSelected[tag._id]"
          [indeterminate]="!tagOnAllSelected[tag._id]"
        ></mat-checkbox>
        <app-tags class="d-inline-block" [customTags]="[tag]" [user]="user"></app-tags>
      </div>
    </cdk-virtual-scroll-viewport>
  </ng-container>
  <div id="add-tag" class="tag-header" (click)="$event.stopPropagation()">
    {{ 'Add Tag' | translate }}
  </div>
  <ng-container *ngIf="unselectedTags.length > 0">
    <cdk-virtual-scroll-viewport
      class="tag-scroll"
      appendOnly
      itemSize="30"
      [ngStyle]="{ 'height.px': unselectedTags.length * 50 }"
    >
      <div class="tag-item" *cdkVirtualFor="let tag of unselectedTags">
        <mat-checkbox
          class="tag-checkbox d-inline-block"
          (click)="$event.preventDefault(); openTagConfirmationDialog($event, tagAction.ADD, tag)"
        ></mat-checkbox>
        <app-tags class="d-inline-block" [customTags]="[tag]" [user]="user"></app-tags>
      </div>
    </cdk-virtual-scroll-viewport>
  </ng-container>
  <ng-container *ngIf="unselectedTags?.length == 0">
    <div class="tag-status-text" (click)="$event.stopPropagation()">
      {{ 'There are no tags yet to add' | translate }}
    </div>
  </ng-container>
</div>
<button
  class="w-100 tag-button pcl-tag-selection-create-new-tag"
  mat-button
  (click)="openTagEditor()"
  (click)="$event.stopPropagation()"
>
  <i class="material-icons">add</i>
  {{ 'Create New Tag' | translate }}
</button>

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TrackType, VirtualBackgroundType } from 'src/app/common/interfaces/rtc-interface';
import { map } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalTracksManagerService } from '../local-tracks-manager.service';
import { UploadFileService } from '../upload-file.service';
import { ResourcesService } from '../resources.service';
import { UserService } from '../user.service';
import { AclService } from '../acl.service';
import { VirtualBackgroundInsertableStreamService } from '../virtual-background-insertable-stream.service';
import { VideoCallTracksStateService } from '../video-call-tracks-state.service';
import { ProviderStateService } from '../provider-state.service';
import { VBClickEvent, VirtualBackgroundPanelBase } from './virtual-background-panel-base';

@UntilDestroy()
@Injectable()
export class VirtualBackgroundPanelRTCService extends VirtualBackgroundPanelBase {
  constructor(
    dialog: MatDialog,
    translate: TranslateService,
    uploadFileService: UploadFileService,
    resourcesService: ResourcesService,
    userService: UserService,
    aclService: AclService,
    virtualBackgroundInsertableStreamService: VirtualBackgroundInsertableStreamService,
    private localTracksManagerService: LocalTracksManagerService,
    private videoCallTracksStateService: VideoCallTracksStateService,
    private providerState: ProviderStateService,
  ) {
    super(
      dialog,
      translate,
      uploadFileService,
      resourcesService,
      userService,
      aclService,
      virtualBackgroundInsertableStreamService,
    );
  }

  async setImageBackground(vbClickEvent: VBClickEvent) {
    this.localTracksManagerService.changeVbEffect({
      type: VirtualBackgroundType.BACKGROUND_IMAGE,
      backgroundImg: vbClickEvent.image.src,
    });
  }

  async removeBackground() {
    this.localTracksManagerService.changeVbEffect({
      type: VirtualBackgroundType.NONE,
    });
  }

  async blur() {
    this.localTracksManagerService.changeVbEffect({
      type: VirtualBackgroundType.BACKGROUND_BLUR,
    });
  }

  isVideoPlaying() {
    return this.videoCallTracksStateService.getPariticpantTrackPlayingObservable(
      this.providerState.localParticipantIdValue,
      TrackType.VIDEO,
      this,
    );
  }

  getVideoStream() {
    return this.videoCallTracksStateService
      .getPariticpantTrackObservable(
        this.providerState.localParticipantIdValue,
        TrackType.VIDEO,
        this,
      )
      .pipe(map((track) => (track ? new MediaStream([track as MediaStreamTrack]) : null)));
  }
}

import { Injectable } from '@angular/core';
import { createStore, filterNil } from '@ngneat/elf';
import {
  deleteEntities,
  entitiesPropsFactory,
  getEntity,
  selectEntity,
  updateEntities,
  upsertEntities,
  upsertEntitiesById,
  withEntities,
} from '@ngneat/elf-entities';
import * as _ from 'lodash';
import { clone, isEqual } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  pairwise,
  skip,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';
import { getHash } from '../common/utils/presence.util';
import { Session } from '../models/session';
import {
  ContextTypeEnum,
  PresenceData,
  PresenceRoom,
  PresenceType,
} from '../services/presence_v2.service';
import { PresenceRoomInfo } from '../services/presence-provider';
import { SpaceRepository } from './space.repository';

export interface IPresenceState {
  hash: string;
  // maps breakout room id to a array of presence Data
  presenceData: Map<string, Array<PresenceData>>;
  timestamp: number;
}

export interface IPresenceStateArray {
  hash: string;
  presenceData: Array<PresenceData>;
  timestamp: number;
}

export interface HeartbeatEventDetails {
  hash: string;
  events: Array<number>;
}

const { heartbeatEntitiesRef, withHeartbeatEntities } = entitiesPropsFactory('heartbeat');
@Injectable({ providedIn: 'root' })
export class PresenceRepository {
  private readonly store = createStore(
    { name: 'presence' },
    withEntities<IPresenceState, 'hash'>({ idKey: 'hash' }),
    withHeartbeatEntities<HeartbeatEventDetails, 'hash'>({ idKey: 'hash' }),
  );

  private _currentlyObservedRooms = new Map<string, Observable<any>>();
  private _currentlyObservedRoomsTakeUntil = new Map<string, Subject<void>>();
  private _currentlyObservedRooms$ = new Subject<Map<string, Observable<any>>>();
  private _activeUsersInSpaceSubs?: Subscription;
  private _activeUsersInSpaceSubject$ = new BehaviorSubject<Set<string>>(new Set<string>());
  public activeUsersInSpace$ = this._activeUsersInSpaceSubject$.asObservable();
  private _gotPresenceUpdate$ = new Subject<void>();

  constructor(private spaceRepo: SpaceRepository) {
    this.initRepoWithSpaceRooms();
    this.initActiveSpaceUsersObservable();
  }

  private initActiveSpaceUsersObservable() {
    this._activeUsersInSpaceSubs = this._activeUsersInSpace$.subscribe((Val) => {
      this._activeUsersInSpaceSubject$.next(Val);
    });
  }

  private initRepoWithSpaceRooms() {
    this.spaceRepo.activeSpaceId$
      .pipe(
        filterNil(),
        switchMap((spaceId) => this.spaceRepo.spaceRooms$(spaceId)),
      )
      .pipe(
        pairwise(),
        startWith([]),
        filter(([prevRooms, currRooms]) => Boolean(prevRooms) && Boolean(currRooms)),
      )
      .subscribe(([prevRooms, rooms]) => {
        const hash = getHash(this.spaceRepo.activeSpaceId as string, PresenceType.SPACE_PRESENCE);
        const prevRoomIds = prevRooms.map((r) => r.uid);
        const roomIds = rooms.map((r) => r.uid);
        const currentHashPresenceMap =
          this.store.query(getEntity(hash))?.presenceData ?? new Map<string, Array<PresenceData>>();
        roomIds.forEach((uid) => {
          if (!currentHashPresenceMap.get(uid)) {
            currentHashPresenceMap.set(uid, new Array<PresenceData>());
          }
        });
        this.store.update(
          upsertEntitiesById(hash, {
            updater: (entity) => ({
              hash: hash,
              presenceData: currentHashPresenceMap,
              timestamp: entity.timestamp,
            }),
            creator: (_id) => ({
              hash: hash,
              presenceData: currentHashPresenceMap,
              timestamp: 0,
            }),
          }),
        );
        this.setUsersActiveInSpaceObservable(
          this.spaceRepo.activeSpaceId as string,
          prevRoomIds,
          roomIds,
        );
      });
  }

  private setUsersActiveInSpaceObservable(
    spaceId: string,
    prevRoomIds: string[],
    roomIds: string[],
  ) {
    const deletedRoomsIds = new Set(
      [...new Set(prevRoomIds)].filter((element) => !new Set(roomIds).has(element)),
    );
    for (const roomId of roomIds) {
      if (!this._currentlyObservedRooms.has(roomId)) {
        this._currentlyObservedRoomsTakeUntil.set(roomId, new Subject());

        this._currentlyObservedRooms.set(
          roomId,
          this.getPresenceData(
            spaceId,
            PresenceType.SPACE_PRESENCE,
            ContextTypeEnum.SPACE,
            roomId,
          ).pipe(takeUntil(this._currentlyObservedRoomsTakeUntil.get(roomId)!)),
        );
      }
    }

    if (deletedRoomsIds.size) {
      const mainRoomId = Session.getMainRoomId(spaceId, true);
      const waitUntilDeletedRoomUsersJoinMainRoom = this.getPresenceData(
        spaceId,
        PresenceType.SPACE_PRESENCE,
        ContextTypeEnum.SPACE,
        mainRoomId,
      )
        .pipe(skip(1), first())
        .subscribe((val) => {
          for (const deletedRoomId of deletedRoomsIds) {
            this._currentlyObservedRoomsTakeUntil.get(deletedRoomId)?.complete();
            this._currentlyObservedRooms.delete(deletedRoomId);
            this._currentlyObservedRoomsTakeUntil.delete(deletedRoomId);
          }

          waitUntilDeletedRoomUsersJoinMainRoom?.unsubscribe();
        });
    }

    this._currentlyObservedRooms$.next(this._currentlyObservedRooms);
  }

  resetRepo() {
    if (this.spaceRepo.activeSpaceId) {
      this.store.update(deleteEntities(this.spaceRepo.activeSpaceId));
    }
    this._activeUsersInSpaceSubs?.unsubscribe();
  }

  setPresenceState(update: IPresenceStateArray) {
    const currentValue = this.store.query(getEntity(update.hash));

    if (currentValue) {
      if (currentValue.timestamp > update.timestamp) {
        return;
      }
    }

    let currentPresenceStateMap = new Map<string, Array<PresenceData>>();
    if (currentValue?.presenceData) {
      currentPresenceStateMap = _.cloneDeep(currentValue.presenceData);
    }

    // reset room presence
    const toResetRoomIds = new Set(currentPresenceStateMap.keys());
    toResetRoomIds.forEach(
      (roomUid) =>
        roomUid &&
        currentPresenceStateMap
          .get(roomUid)
          ?.splice(0, currentPresenceStateMap.get(roomUid)?.length),
    );

    // set new room presence
    for (const presenceDataUpdate of update.presenceData) {
      if (!presenceDataUpdate.breakoutRoomId) {
        return;
      }
      if (!currentPresenceStateMap.has(presenceDataUpdate.breakoutRoomId)) {
        currentPresenceStateMap.set(presenceDataUpdate.breakoutRoomId, []);
      }
      currentPresenceStateMap
        .get(presenceDataUpdate.breakoutRoomId)
        ?.push(clone(presenceDataUpdate!));
    }

    this.store.update(
      upsertEntitiesById(update.hash, {
        updater: (entity) => ({
          hash: update.hash,
          presenceData: currentPresenceStateMap,
          timestamp: update.timestamp,
        }),
        creator: (_id) => ({
          hash: update.hash,
          presenceData: currentPresenceStateMap,
          timestamp: update.timestamp,
        }),
      }),
    );

    this._gotPresenceUpdate$.next();
  }

  getPresenceData(
    spaceId: string,
    context: PresenceType,
    contextType: ContextTypeEnum,
    breakoutRoomId: string | undefined,
  ): Observable<Set<string>> {
    const hash = getHash(spaceId, context);
    if (!this.store.query(getEntity(hash))) {
      this.store.update(
        upsertEntities({
          hash: hash,
          presenceData: new Map(),
          timestamp: Date.now() - 1,
        }),
      );
    }
    if (!breakoutRoomId) {
      return this.store.pipe(selectEntity(hash)).pipe(
        map((entity) => {
          const presence = new Set<string>();
          if (entity?.presenceData) {
            for (const data of entity.presenceData.values()) {
              data
                .filter((presenceDataValue) => presenceDataValue.contextType === contextType)
                .forEach((user) => presence.add(user.userId));
            }
          }
          return presence;
        }),
      );
    }
    return this.store
      .pipe(
        selectEntity(hash, {
          pluck: (e) =>
            e.presenceData.get(breakoutRoomId!)?.filter((x) => x.contextType === contextType),
        }),
      )
      .pipe(
        map((data) => new Set<string>(data?.map((x) => x.userId))),
        distinctUntilChanged((a, b) => isEqual(a, b)),
      );
  }

  getPresenceDataValue(
    spaceId: string,
    type: PresenceType,
    contextType: ContextTypeEnum,
    breakoutRoomId: string | undefined,
  ): Array<PresenceData> | undefined {
    if (!breakoutRoomId) {
      return;
    }
    const hash = getHash(spaceId, type);
    const currentPresentMap = this.store.query(getEntity(hash))?.presenceData.get(breakoutRoomId);
    if (!currentPresentMap) {
      return;
    }

    return currentPresentMap.filter((data) => data.contextType === contextType);
  }

  updatePresence(presence: PresenceData): void {
    if (!presence.breakoutRoomId) {
      return;
    }
    const hash = getHash(presence.spaceId, presence.context);
    const currentHashPresenceMap =
      this.store.query(getEntity(hash))?.presenceData ?? new Map<string, Array<PresenceData>>();
    if (currentHashPresenceMap.get(presence.breakoutRoomId)) {
      currentHashPresenceMap.get(presence.breakoutRoomId)?.push(presence);
    } else {
      currentHashPresenceMap.set(presence.breakoutRoomId, [presence]);
    }

    this.store.update(
      updateEntities(hash, (entity) => ({
        ...entity,
        presenceData: currentHashPresenceMap,
      })),
    );
  }

  removePresence(presence: PresenceData): void {
    const hash = getHash(presence.spaceId, presence.context);
    const currentPresentMap = this.store.query(getEntity(hash))?.presenceData;
    if (!currentPresentMap) {
      return;
    }
    if (presence.breakoutRoomId) {
      const filteredPresence =
        currentPresentMap
          .get(presence.breakoutRoomId)
          ?.filter(
            (x) => x.uniqueHash !== presence.uniqueHash || x.contextType !== presence.contextType,
          ) || [];
      currentPresentMap.set(presence.breakoutRoomId, filteredPresence);
    }

    this.store.update(
      updateEntities(hash, (entity) => ({
        ...entity,
        presenceData: currentPresentMap,
      })),
    );
  }

  clearPresence(hashList: Array<string>, contextType: ContextTypeEnum) {
    const fn = (spacePresenceMap: IPresenceState) => {
      for (const [roomId, roomPresence] of spacePresenceMap.presenceData) {
        spacePresenceMap.presenceData.set(
          roomId,
          Array.from(roomPresence.values()).filter((x) => x.contextType !== contextType) || [],
        );
      }

      return spacePresenceMap.presenceData;
    };
    this.store.update(
      updateEntities(hashList, (e) => ({
        ...e,
        presenceData: fn(e),
      })),
    );
  }

  clearPresenceRooms(presenceRooms: Array<PresenceRoomInfo>): void {
    const toClearSpaceToRoomsMap = new Map<string, string[]>();
    presenceRooms.forEach((presenceRoom) => {
      if (toClearSpaceToRoomsMap.has(presenceRoom.spaceId)) {
        toClearSpaceToRoomsMap.get(presenceRoom.spaceId)?.push(presenceRoom.breakoutRoomId);
      } else {
        toClearSpaceToRoomsMap.set(presenceRoom.spaceId, [presenceRoom.breakoutRoomId]);
      }
    });

    for (const [spaceId, roomIds] of toClearSpaceToRoomsMap) {
      const hash = getHash(spaceId, PresenceType.SPACE_PRESENCE);
      const currentPresenceStateMap = this.store.query(getEntity(hash));
      const currentEntity = currentPresenceStateMap?.presenceData;
      if (!currentPresenceStateMap || !currentEntity) {
        return;
      }
      roomIds.forEach((roomId) => {
        currentEntity.set(roomId, []);
      });
      this.store.update(updateEntities(hash, (entity) => currentPresenceStateMap));
    }
  }

  addPresenceHeartbeatEvent(hash: string): void {
    this.store.update(
      updateEntities(
        hash,
        (e) => ({ ...e, events: e?.events ? [...e.events, Date.now()] : [Date.now()] }),
        { ref: heartbeatEntitiesRef },
      ),
    );
  }

  getUserPresenceHeartbeatEvents(hash: string): HeartbeatEventDetails | undefined {
    return this.store.query(getEntity(hash, { ref: heartbeatEntitiesRef }));
  }

  removeUserPresenceHeartbeatEvents(hash: string): void {
    this.store.update(deleteEntities([hash], { ref: heartbeatEntitiesRef }));
  }

  private readonly _activeUsersInSpace$: Observable<Set<string>> =
    this._currentlyObservedRooms$.pipe(
      switchMap((_currentlyObservedRooms) =>
        combineLatest(Array.from(_currentlyObservedRooms.values())).pipe(
          map((roomPresenceSets) => {
            const combinedPresence = roomPresenceSets.reduce(
              (prev, curr) => new Set([...prev, ...curr]),
              new Set<string>(),
            );
            return combinedPresence;
          }),
          distinctUntilChanged((a, b) => isEqual(a, b)),
          debounceTime(200),
        ),
      ),
    );

  getCurrentSpacePresenceData(spaceId: string): Array<PresenceData> {
    const hash = getHash(spaceId, PresenceType.SPACE_PRESENCE);
    const currentPresenceState = this.store.query(getEntity(hash));
    if (!currentPresenceState) {
      return [];
    }
    return Array.from(currentPresenceState.presenceData.values())
      .flat()
      .filter((presenceData) => presenceData.contextType === ContextTypeEnum.SPACE);
  }

  getActiveUsersInSpaceValue(): Set<string> {
    return this._activeUsersInSpaceSubject$.value;
  }

  isLatestUpdate(update: PresenceRoom) {
    const currentDataTimestamp = this.store.query(
      getEntity(getHash(update.spaceId, update.type)),
    )?.timestamp;
    if (!currentDataTimestamp || currentDataTimestamp < update.timestamp) {
      return true;
    }
    return false;
  }

  isFirstUpdate(update: PresenceRoom): boolean {
    const data = this.store.query(getEntity(getHash(update.spaceId, update.type)));
    return data === undefined;
  }

  getSpacePresenceData(spaceId: string): Observable<Set<string>> {
    const hash = getHash(spaceId, PresenceType.SPACE_PRESENCE);
    return this.store
      .pipe(
        selectEntity(hash, {
          pluck: (e) =>
            Array.from(e.presenceData.values())
              .flat()
              .filter((x) => x.contextType === ContextTypeEnum.SPACE),
        }),
      )
      .pipe(
        map((data) => new Set<string>(data?.map((x) => x.userId))),
        distinctUntilChanged((a, b) => isEqual(a, b)),
      );
  }

  getRecordingPresence(spaceId: string, breakoutRoomId: string): Observable<boolean> {
    const hash = getHash(spaceId, PresenceType.SPACE_PRESENCE);
    return this.store.pipe(
      selectEntity(hash, {
        pluck: (data) => {
          const presences: PresenceData[] = Array.from(data.presenceData.values()).flat();
          return presences.some(
            (p) =>
              p.contextType === ContextTypeEnum.RECORDING && p.breakoutRoomId === breakoutRoomId,
          );
        },
      }),
      filterNil(),
    );
  }

  get gotPresenceUpdate(): Observable<void> {
    return this._gotPresenceUpdate$.asObservable();
  }
}

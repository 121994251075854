import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs';
import { ScreenShareOptions } from '../common/interfaces/rtc-interface';
import { DevicesManagerService } from './devices-manager.service';
import { ProviderStateService } from './provider-state.service';
import { RtcServiceController } from './rtc.service';
import { DeviceAndBrowserDetectorService } from './device-and-browser-detector.service';

export enum SCREEN_SHARE_MODE {
  CURRENT_TAB = 'current_tab',
  OTHER_TAB = 'other_tab',
}

@Injectable({
  providedIn: 'root',
})
export class SpaceScreenshareService {
  private _localScreenShareMode$ = new BehaviorSubject<SCREEN_SHARE_MODE | undefined>(undefined);

  constructor(
    private devicesManagerService: DevicesManagerService,
    private providerStateService: ProviderStateService,
    private rtcServiceController: RtcServiceController,
    private deviceAndBrowserDetectorService: DeviceAndBrowserDetectorService,
  ) {}

  public numberOfScreenshares$ = this.providerStateService.participants$.pipe(
    map((participants) => Object.values(participants)),
    map(
      (participants) =>
        participants.filter((p) => this.rtcServiceController.trackExists(p.screen)).length,
    ),
    distinctUntilChanged(),
  );

  public localScreenShareMode$ = this.providerStateService.localParticipantEvents$.pipe(
    tap((localParticipant) => {
      if (this.rtcServiceController.trackExists(localParticipant.screen)) {
        const track = localParticipant.screenVideoTrack!;
        this._localScreenShareMode$.next(
          this.rtcServiceController.service.isScreenshareLocal(track)
            ? SCREEN_SHARE_MODE.CURRENT_TAB
            : SCREEN_SHARE_MODE.OTHER_TAB,
        );
        track.oncapturehandlechange = () => {
          this._localScreenShareMode$.next(
            this.rtcServiceController.service.isScreenshareLocal(track)
              ? SCREEN_SHARE_MODE.CURRENT_TAB
              : SCREEN_SHARE_MODE.OTHER_TAB,
          );
        };
      } else {
        this._localScreenShareMode$.next(undefined);
      }
    }),
    switchMap(() => this._localScreenShareMode$.asObservable()),
    distinctUntilChanged(),
    shareReplay(1),
  );

  shareScreen(opts?: ScreenShareOptions) {
    const _opts = opts || {};
    _opts.preferCurrentTab &&= this.isSharingCurrentScreenSupported();
    if (_opts.preferCurrentTab) {
      _opts.restrictToWhiteboard = true;
    }

    // Allow the user to override the default options
    this.devicesManagerService.shareScreen(_opts);
  }

  public isSharingCurrentScreenSupported() {
    return (
      this.deviceAndBrowserDetectorService.isChrome() ||
      this.deviceAndBrowserDetectorService.isEdge() ||
      this.deviceAndBrowserDetectorService.isOpera()
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { last } from 'lodash-es';
import * as moment from 'moment-timezone';
import { SpaceTranscriptItems, TranscriptGroup } from '../state/transcript.repository';

@Pipe({
  name: 'transcriptGroupsByDate',
})
export class TranscriptGroupsByDatePipe implements PipeTransform {
  private timezone = moment.tz.guess();

  transform(transcripts: SpaceTranscriptItems): TranscriptGroup[] {
    return transcripts.reduce((accumulator: TranscriptGroup[], transcript) => {
      const currentGroup = last(accumulator);
      const transcriptDate = moment(transcript.timestamp).tz(this.timezone).format('MMM Do, YYYY');
      if (currentGroup && currentGroup.date === transcriptDate) {
        currentGroup.transcripts.push(transcript);
      } else {
        accumulator.push({
          date: transcriptDate,
          transcripts: [transcript],
          mostRecentTimestamp: <string>(<unknown>(transcript.lastModified || transcript.timestamp)),
        });
      }
      return accumulator;
    }, []);
  }
}

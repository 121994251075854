import { IEvent } from 'fabric/fabric-impl';
import { Observable } from 'rxjs';
import { PresenceData, PresenceRoom } from 'src/app/services/presence_v2.service';
import { BaseCustomFabric } from 'src/app/sessions/session/custom-fabric-objects/base-custom-fabric';
import {
  TemporaryUserMetadataEntry,
  TemporaryUserMetadataEntryRedis,
} from 'src/app/state/temporary-user-metadata-repository.service';
import { LessonProcessor } from 'src/app/services/lesson-generator.service';
import { BoardFolder, Frame, Room } from '../../models/session';
import {
  FrameEvent,
  FrameObservableData,
  Mouse,
  SessionAuth,
  UserEvent,
} from '../../models/session-sync';
import { SessionMetadata, YCanvasItemObject, YObject } from '../../sessions/common/y-session';
import {
  EventCategory,
  SessionEvent,
  SessionEventController,
  SessionEventSubscriber,
} from '../../sessions/session/SessionEvent';
import { AppCustomPolygon } from '../../sessions/session/wb-toolbar/toolbars/fabric-polygons';
import { PencilSocket } from '../../socket-utilities/pencil-socket';

export enum WhiteboardServerErrors {
  UPDATE_FAILED = 'update_failed',
}

// uid: the id of this object
// vuid: a version id of the object for updating
// userId: the user that created the object
// fresh: if the object was just created locally
export const customFabricFields = [
  'userId',
  'uid',
  'vuid',
  'fresh',
  'excludeFromExport',
  '_controlsVisibility',
  'hasControls',
  'remoteDelete',
  'ScribbleType', // used only for fabric object is 'path' to distinguish pen vs highlighter strokes/scribbles
  'locked',
  AppCustomPolygon.RESIZE_UNIFORM_KEY,
  AppCustomPolygon.POLYGON_TYPE_KEY,
  AppCustomPolygon.POLYGON_SCALE_RATE,
  BaseCustomFabric.ATTR_FIELD_NAME,
  BaseCustomFabric.CUSTOM_FABRIC_DATA,
];

/*
itemId: string -> canvasItemId.
itemType: string -> 'TEXT', 'IMAGE' or 'YOUTUBE'.
itemData: string -> url or equation string.
*/
export const customCanvsItemsFields = [
  'itemId',
  'itemType',
  'itemData',
  'settings',
  'customSelectable',
];

export interface MovedBoardsPlacement {
  fromRoomId: string;
  toRoomId: string;
  framesIds: Set<string>;
}

export enum WebSocketEventType {
  BROADCAST = 'broadcast',
  GET_SPACE_CHUNK = 'getSpaceChunk',
  JOIN_FRAME_ROOM = 'joinFrameRoom',
  LEAVE_FRAME_ROOM = 'leaveFrameRoom',
  JOIN_USER_ROOM = 'joinUserRoom',
  LEAVE_USER_ROOM = 'leaveUserRoom',
  USER_EVENT = 'userEvent',
  LEAVE_ROOMS = 'leaveRooms',
  UPDATE = 'update',
  FRAME_EVENT = 'frameEvent',
  POINTER_USER_EVENT = 'pointerUserEvent',
  HEARTBEAT = 'heartbeat',
  CURRENT_SESSION_HEARTBEAT = 'currentSessionHeartbeat',
  SYNC_WITH_SERVER = 'syncWithServer',
  JOIN_SOCKET_ROOMS = 'joinSocketRooms',
  SERVER_STATE_VECTOR = 'serverStateVector',
  SPACE_DOWNLOAD_START = 'spaceDownloadStart',
  SPACE_CHUNK = 'spaceChunk',
  SPACE_DOWNLOAD_END = 'spaceDownloadEnd',
  SPACE_DOWNLOAD_ERROR = 'spaceDownloadError',
  SYNC = 'sync',
  JOIN_ROOMS_RES = 'joinRoomsRes',
  CURRENT_SESSION_ID = 'currentSessionId',
  JOIN_PRESENCE_ROOMS = 'joinPresenceRooms',
  LEAVE_PRESENCE_ROOMS = 'leavePresenceRooms',
  SET_PRESENCE = 'setPresence',
  REMOVE_PRESENCE = 'removePresence',
  UPDATE_FRAME_PRESENCE = 'updateFramePresence',
  MULTI_TAPS = 'MULTI_TAPS',
  HEARTBEAT_V2 = 'heartbeat-v2',
  GET_CURRENT_SESSION = 'getCurrentSession',
  PRESENCE_UPDATE = 'presenceUpdate',
  USER_DISCONNECTED = 'userDisconnected',
  USER_JOINED = 'userJoined',
  USER_LEFT = 'userLeft',
  QUERY_PRESENCE_DATA = 'queryPresenceData',
  USER_TEMPORARY_METADATA_UPDATE = 'userTemporaryMetadataUpdate',
  SPACE_DOWNLOAD_URL = 'spaceDownloadUrl',
  ANALYTICS_SESSION_ENDED_MANUALLY = 'analyticsSessionEndedManually',
}

export enum HeartbeatSource {
  USER_PRESENCE = 'user-presence', // for lms, space, and call user presence
  SESSION_SYNC = 'session-sync',
  CALL_HEARTBEAT = 'call-heartbeat',
  SPACE_HEARTBEAT = 'space-heartbeat',
  RECORDING_HEARTBEAT = 'recording-heartbeat',
  LMS_HEARTBEAT = 'lms-heartbeat',
  WAITING_ROOM_HEARTBEAT = 'waiting-room-heartbeat',
}

export abstract class SyncService implements SessionEventController {
  // The rate at which socket events are processed
  protected socketEventProcessRate = 1000; // 1000 ms
  protected realtimeEventProcessRate = 1000 / 30; // 30 fps

  // ---- Observables ----
  public abstract isOffline: boolean;
  public abstract socket?: PencilSocket<WebSocketEventType>;

  /**
   * @deprecated backward compatibility
   */
  public abstract userJoinedEvent$: Observable<Array<PresenceData>>;
  /**
   * @deprecated backward compatibility
   */
  public abstract userLeftEvent$: Observable<Array<PresenceData>>;
  /**
   * @deprecated backward compatibility
   */
  public abstract userDisconnectedEvent$: Observable<Array<PresenceData>>;

  public abstract presenceUpdate$: Observable<Array<PresenceRoom>>;

  public abstract userTemporaryMetadataUpdate$: Observable<Array<TemporaryUserMetadataEntryRedis>>;

  public abstract reconnectAttempt: number;

  public abstract redoStack$: Observable<number>;
  public abstract undoStack$: Observable<number>;

  public abstract usersLeftFrame$: Observable<string[]>;
  public abstract usersJoinedFrame$: Observable<string[]>;

  public abstract userEvents$: Observable<UserEvent | undefined>;
  public abstract updateUserPresence$: Observable<'reconnected' | 'disconnected'>;
  public abstract realTimeEvent$: Observable<{ mouse: Mouse; user: string }[]>;

  // ---- Methods ----

  // Lifecycle
  public abstract setupSocket(): void;
  public abstract setActiveSession(sessionId: string): void;
  public abstract stopService(): void;
  public abstract populateSessionAuth(sessionIds: string[]): SessionAuth[] | undefined;
  public abstract getSpaceDownloadProgressObservable(): Observable<number>;
  public abstract isOfflineMode(): Promise<boolean>;
  public abstract getSocketId(): string;

  // Events
  public abstract subscribeToEvent(
    subscriber: SessionEventSubscriber,
    category: EventCategory,
  ): void;
  public abstract unSubscribeFromEvent(
    subscriber: SessionEventSubscriber,
    category: EventCategory,
  ): void;

  // Space Scoped Events
  public abstract joinRooms(sessionIds: string[], callback?: () => void): void;
  public abstract leaveRooms(sessionIds: string[]): void;
  public abstract sendEvent(sender: any, event: SessionEvent): void;

  // Frame Scoped Events
  public abstract leaveFrameRoom(): void;
  public abstract sendFrameEvent(frameEvent: FrameEvent, volatile: boolean): void;
  public abstract sendMouseFrameEvent(
    type: string,
    events: (IEvent | undefined)[],
    metadata: any,
    volatile?: boolean,
  ): void;

  // Uesr Scoped Events
  public abstract joinUserRoom(userId: string): Promise<void>;
  public abstract leaveUserRoom(): Promise<void>;
  public abstract sendUserEvent(event: UserEvent): void;

  // Undo and Redo
  public abstract undo(): boolean;
  public abstract redo(): boolean;

  // Canvas Items
  public abstract handleCanvasItemsAdded(
    items: YCanvasItemObject[],
    sessionId: string,
    frameUID: string,
    trackUndoRedo?: boolean,
  ): void;
  public abstract handleCanvasItemsModified(
    items: YCanvasItemObject[],
    sessionId: string,
    frameUID: string,
  ): void;
  public abstract handleCanvasItemRemoved(
    itemID: string,
    sessionId: string,
    frameUID: string,
  ): void;
  public abstract clearFrameContent(frame: Frame, sessionId: string, roomId: string): void;

  // Fabric Items

  public abstract createCanvasListeners(canvas: fabric.Canvas): void;
  public abstract handleObjectsAdd(
    sessionId: string,
    frameUID: string,
    fabricObjects: YObject[],
    trackUndoRedo: boolean,
  ): void;
  public abstract updateObjectIndex(uid: string, index: number): void;
  public abstract applyFabricMultTransform(
    obj: fabric.Object,
    activeTransform: any[],
    relationship: any[],
  ): fabric.Object;

  // Rooms
  public abstract isConvertedToRooms(sessionId?: string): boolean;
  public abstract addRooms(sessionId: string, rooms: Room[]): void;
  public abstract addBoardFolder(sessionId: string, boardFolder: BoardFolder): void;
  public abstract removeAllBoardFolders(sessionId: string | undefined): void;
  public abstract modifyBoardFolder(
    boardFolder: BoardFolder,
    sessionId: string,
    roomId: string,
  ): void;
  public abstract removeBoardFolder(
    boardFolder: BoardFolder,
    sessionId: string,
    roomId: string,
  ): void;
  public abstract deleteRooms(sessionId: string, roomIds: string[]): void;
  public abstract modifyRoom(room: Room, sessionId: string): void;
  public abstract moveFrameBetweenRooms(
    sessionId: string,
    movedBoardsPlacement: MovedBoardsPlacement[],
  ): void;
  // Frames
  public abstract addFrame(frame: Frame, sessionId: string, roomId: string): void;
  public abstract addFrames(frames: Frame[], sessionId: string, roomId: string): void;
  public abstract modifyFrame(frame: Frame, sessionId: string, roomId: string): void;
  public abstract removeFrame(frame: Frame, sessionId: string, roomId: string): void;
  public abstract moveFrame(
    frame: Frame,
    fromIndex: number,
    toIndex: number,
    sessionId: string,
    roomId: string,
  ): void;
  public abstract updateFramePrivacy(frame: Frame, sessionId: string, roomId: string): void;
  public abstract updateFrameLockState(frame: Frame, sessionId: string, roomId: string): void;
  public abstract updateFrameBoardFolder(frame: Frame, sessionId: string, roomId: string): void;

  public abstract updateFrameBackground(frame: Frame, sessionId: string, roomId: string): void;

  public abstract removeAllFrames(): void;
  public abstract getMostRecentFrameIndex(frames: FrameObservableData[]): number;

  // Metadata
  public abstract modifyTemporaryUserMetadata(
    temporaryUserMetadata: Partial<TemporaryUserMetadataEntry>,
  ): Promise<void>;
  public abstract clearTemporaryUserMetadata(sessionId: string): Promise<void>;
  public abstract modifySessionMetadata(sessionMetadata: Partial<SessionMetadata>): void;

  // Emote
  public abstract emote(emojiId: number): void;
  public abstract celebrate(celebrationId: number): void;

  // Other
  public abstract logSpaceLoadingTimeToFS(userIsRefreshing: boolean): void;
  public abstract downloadBase64String(): void;
  public abstract downloadJSON(): void;
  public abstract downloadBinary(): void;
  public abstract logSpace(): void;
  public abstract logSize(): void;

  // Analytics Sessions
  public abstract getCurrentAnalyticsSession(): void;
  public abstract addAiLessonGeneratedContent(lesson: LessonProcessor): void;
}

// Description for Thumbs Down gesture
import { Gesture, GestureName } from '../models/gesture';
import { FingerType, FingerDirection, FingerCurl } from '../models/finger';

const thumbsDownGesture = new Gesture(GestureName.THUMBS_DOWN);

// Add curls
thumbsDownGesture.addCurl(FingerType.Thumb, FingerCurl.NoCurl);
thumbsDownGesture.addCurls(FingerType.Index, [FingerCurl.FullCurl, FingerCurl.HalfCurl]);
thumbsDownGesture.addCurls(FingerType.Middle, [FingerCurl.FullCurl, FingerCurl.HalfCurl]);
thumbsDownGesture.addCurls(FingerType.Ring, [FingerCurl.FullCurl, FingerCurl.HalfCurl]);
thumbsDownGesture.addCurls(FingerType.Pinky, [FingerCurl.FullCurl, FingerCurl.HalfCurl]);

// Add Directions
thumbsDownGesture.addDirections(FingerType.Thumb, [
  FingerDirection.VerticalDown,
  FingerDirection.DiagonalDownLeft,
  FingerDirection.DiagonalDownRight,
]);

export default thumbsDownGesture;

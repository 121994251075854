import {
  Component,
  Host,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { Message, MessageList, PosterType, User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';

import { sortByDate } from 'src/app/common/utils/common-util';
import { ActivatedRoute } from '@angular/router';
import { QuestionsService } from 'src/app/services/questions.service';
import { FragmentCollection } from 'src/app/content/course/create/create.model';
import { EditorTypes } from 'src/app/ui/advanced-editor/advanced-text-fragment.component';
import { DateFormatEnum } from 'src/app/pipes/dateLocale.pipe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommentWrapperComponent } from '../comment-wrapper/comment-wrapper.component';
import { Context } from '../../models/context';

@UntilDestroy()
@Component({
  selector: 'app-list-comments',
  templateUrl: './list-comments.component.html',
  styleUrls: ['./list-comments.component.scss'],
})
export class ListCommentsComponent implements OnChanges {
  @Input() messages?: MessageList;
  @Input() type = 'comment';
  @Input() context?: Context;
  @Input() isPopup?: boolean = false;
  @Output() messagesSorted = new EventEmitter<boolean>();

  public DateFormatEnum = DateFormatEnum;

  user?: User;
  messageBeingEdited?: string;
  messageCollectionBeingEdited?: FragmentCollection;
  page = 1;
  remainingMessageCount = 0;
  history: Message[] = [];
  isMenuOpened = false;
  foundTargetComment = false;
  allCommentsLoaded = false;
  editorTypes = EditorTypes;

  constructor(
    private userService: UserService,
    @Host() private parent: CommentWrapperComponent,
    private activatedRoute: ActivatedRoute,
    private questionsService: QuestionsService,
  ) {
    this.userService.user.pipe(untilDestroyed(this)).subscribe((data: any) => {
      if (data && data.user) {
        this.user = data.user;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.messages) {
      if (this.messages && this.messages.tutor_edits) {
        // first we need to clear history so we don't get double values
        this.history = [];
        this.messages.tutor_edits.forEach((e) => {
          e.logs.forEach((log) => {
            /* TODO: Create a separate interface for outbound messages, since
             * they don't have all of the same properties as messages stored in
             * the database.
             */
            const message: Partial<Message> = {};
            message.author = log.tutor;
            message.poster = log.tutor;
            message.posterType = PosterType.User;
            message.createdAt = log.time;
            message.log = this.getLogText(log.action);
            /* This will never be a full Message type, only a Partial<Message>
             * type. See TODO item above.
             */
            this.history.push(message as Message);
          });
        });

        if (this.history.length) {
          this.history.sort((a, b) => sortByDate(a, b, 'createdAt'));
        }
      }
      if (this.messages?.messages && this.messages.total_messages) {
        this.remainingMessageCount = this.messages.total_messages - this.messages.messages.length;
        this.messages.messages.sort((a, b) => sortByDate(a, b, 'createdAt'));
        this.messagesSorted.emit(true);
        requestAnimationFrame(() => {
          this.findTargetComment();
        });
      } else {
        this.remainingMessageCount = 0;
      }
    }
  }

  findTargetComment(): void {
    /**
     * first we take fragment from url and check have we already scrolled to comment
     * if not we find comment from our messages array
     */
    const commentFragment = this.activatedRoute.snapshot.fragment;
    if (commentFragment && !this.foundTargetComment) {
      const notifiedComment = this.messages?.messages.find((msg) => msg._id === commentFragment);
      this.foundTargetComment = !!notifiedComment;
      /**
       * if we found our comment and we found element in html document
       * we emit to service and in rhs it gets catched in parent component and fires scroll
       * if we haven't found element in html document we fetch rest of the comments
       * and try to look up there
       */
      if (notifiedComment) {
        const commentElRef = document.getElementById(notifiedComment._id);
        if (commentElRef) {
          this.questionsService.scrollToComment.next(commentElRef);
          return;
        }
      }

      if (!this.allCommentsLoaded) {
        this.fetchMore();
      }
    }
  }

  trackById(index: number, item: Message): string {
    return item._id;
  }

  getLogText(text: string): string {
    if (text === 'update_question') {
      return ' updated this question';
    } else if (text === 'create_question') {
      return ' created this question';
    } else if (text === 'create_explanation') {
      return ' created this explanation';
    } else if (text === 'update_explanation') {
      return ' updated this explanation';
    } else if (text === 'create_worksheet') {
      return ' created this set';
    } else if (text === 'update_worksheet') {
      return ' updated this set';
    } else if (text === 'create_note') {
      return ' created this note';
    } else if (text === 'update_note') {
      return ' updated this note';
    } else if (text === 'view_question') {
      return ' viewed this question';
    }
    return '';
  }

  deleteComment(message: Message): void {
    this.userService
      .deleteComment(message)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.parent.fetchLatestComments();
      });
  }

  editComment(message: Message): void {
    this.messageBeingEdited = message._id;
    this.messageCollectionBeingEdited = FragmentCollection.FromComment(this, message);
  }

  cancelEditing(): void {
    this.messageBeingEdited = undefined;
    this.messageCollectionBeingEdited = undefined;
  }

  saveEditedComment(message: Message): void {
    if (!this.messageCollectionBeingEdited) {
      return;
    }
    message.content = [];
    for (const typed_fragment of this.messageCollectionBeingEdited.fragments) {
      message.content.push(typed_fragment.fragment);
    }
    this.userService
      .updateComment(message)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.parent.fetchLatestComments();
        this.cancelEditing();
      });
  }

  fetchMore(): void {
    if (this.messages) {
      this.allCommentsLoaded = true;
      this.parent.fetchLatestComments(false, 1, this.messages.total_messages);
    }
  }
}

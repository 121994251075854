import { Component, Input, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';

@Component({
  selector: 'app-clip-text[text]',
  templateUrl: './clip-text.component.html',
  styleUrls: ['./clip-text.component.scss'],
})
export class ClipTextComponent implements AfterViewChecked {
  @Input() text!: string;
  @ViewChild('element') element!: ElementRef;

  isClipped = false;

  ngAfterViewChecked(): void {
    this.isClipped =
      this.element.nativeElement.scrollWidth > this.element.nativeElement.clientWidth;
  }
}

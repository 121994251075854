<div id="user-settings-panel" [ngClass]="{ subPanelOpen: !!activePanel, rtl: isRTL }">
  <div class="side">
    <div class="user">
      <app-profile-photo
        [isClickEnabled]="false"
        [fromQuestionList]="false"
        [showName]="true"
        [width]="30"
      ></app-profile-photo>
    </div>
    <div class="settings-nav">
      <div class="nav-header">
        {{ 'account' | translate }}
      </div>
      <ul>
        <li
          [class.active]="activePanel === PANELS.ACCOUNT.id"
          role="button"
          (click)="switchSubPanel(PANELS.ACCOUNT.id)"
          data-name="spaces-manager-user-avatar-menu-settings-account"
        >
          <span class="icon material-icons-outlined">person</span>
          <span class="name">{{ PANELS.ACCOUNT.name }}</span>
          <svg-icon icon="selected-item-overlay"></svg-icon>
        </li>
        <li
          *ngIf="!user?.institution && isBillingEnabled"
          id="btn-settings-billing"
          class="white-space-nowrap"
          [class.active]="activePanel === PANELS.PAYMENT.id"
          role="button"
          (click)="switchSubPanel(PANELS.PAYMENT.id)"
          data-name="spaces-manager-user-avatar-menu-settings-payment"
        >
          <span class="icon material-icons-outlined">payment</span>
          <span class="name">{{ PANELS.PAYMENT.name }}</span>
          <span
            class="billing-status-badge cml-8"
            [class.trial]="currentBilling === BillingStatus.TRIAL"
            [class.free]="currentBilling === BillingStatus.FREE"
            [class.pro]="currentBilling === BillingStatus.PAID"
            >{{
              currentBilling === BillingStatus.PAID
                ? ('PRO' | translate)
                : (currentBilling | translate)
            }}</span
          >
          <svg-icon icon="selected-item-overlay"></svg-icon>
        </li>
        <li
          [class.active]="activePanel === PANELS.LANGUAGE.id"
          role="button"
          (click)="switchSubPanel(PANELS.LANGUAGE.id)"
          data-name="spaces-manager-user-avatar-menu-settings-language"
        >
          <span class="icon material-icons-outlined">language</span>
          <span class="name">{{ PANELS.LANGUAGE.name }}</span>
          <svg-icon icon="selected-item-overlay"></svg-icon>
        </li>
        <li
          *ngIf="!isInstitutionUser && inactivityNotificationsEnabled"
          [class.active]="activePanel === PANELS.ADVANCED.id"
          role="button"
          (click)="switchSubPanel(PANELS.ADVANCED.id)"
          data-name="spaces-manager-user-avatar-menu-settings-account"
        >
          <span class="icon material-icons-outlined">tune</span>
          <span class="name">{{ PANELS.ADVANCED.name }}</span>
          <svg-icon icon="selected-item-overlay"></svg-icon>
        </li>
      </ul>
      <ng-container *ngIf="showInstitutionSettings">
        <div class="nav-header">
          {{ 'institution' | translate }}
        </div>
        <ul>
          <li
            [class.active]="activePanel === PANELS.BILLABLE_USAGE.id"
            role="button"
            (click)="switchSubPanel(PANELS.BILLABLE_USAGE.id)"
            data-name="user-settings-community-billable_usage"
            *ngIf="!isMobile && isBillableUsageSettingEnabled"
          >
            <app-pencil-icon
              class="icon"
              [name]="activePanel === PANELS.BILLABLE_USAGE.id ? 'credit_card_blue' : 'credit_card'"
              [type]="IconTypes.svgIcon"
            ></app-pencil-icon>
            <span class="name">{{ PANELS.BILLABLE_USAGE.name }}</span>
            <svg-icon icon="selected-item-overlay"></svg-icon>
          </li>
          <li
            [class.active]="activePanel === PANELS.PERMISSIONS.id"
            role="button"
            (click)="switchSubPanel(PANELS.PERMISSIONS.id)"
            data-name="user-settings-community-permissions"
          >
            <span class="icon material-icons-outlined">lock_open</span>
            <span class="name">{{ PANELS.PERMISSIONS.name }}</span>
            <svg-icon icon="selected-item-overlay"></svg-icon>
          </li>
          <li
            [class.active]="activePanel === PANELS.SITES_MANAGEMENT.id"
            role="button"
            (click)="switchSubPanel(PANELS.SITES_MANAGEMENT.id)"
            data-name="user-settings-community-sites-management"
            *ngIf="!isMobile"
          >
            <app-pencil-icon
              class="pencil-icon"
              [name]="
                activePanel === PANELS.SITES_MANAGEMENT.id
                  ? 'site_management_blue'
                  : 'site_management'
              "
              [type]="IconTypes.svgIcon"
              style="--pencil-icon-size: 24px"
            ></app-pencil-icon>
            <span class="name">{{ PANELS.SITES_MANAGEMENT.name }}</span>
          </li>
          <li
            [class.active]="activePanel === PANELS.CUSTOM_ATTRIBUTES.id"
            role="button"
            *ngIf="institutionCustomAttributesEnabled && !isMobile"
            (click)="switchSubPanel(PANELS.CUSTOM_ATTRIBUTES.id)"
            data-name="user-settings-community-custom-attributes"
          >
            <app-pencil-icon
              class="icon"
              [name]="
                activePanel === PANELS.CUSTOM_ATTRIBUTES.id ? 'person_play_blue' : 'person_play'
              "
              [type]="IconTypes.svgIcon"
            ></app-pencil-icon>
            <span class="name">{{ PANELS.CUSTOM_ATTRIBUTES.name }}</span>
            <svg-icon icon="selected-item-overlay"></svg-icon>
          </li>
          <li
            [class.active]="activePanel === PANELS.AI_ASSIST.id"
            role="button"
            (click)="switchSubPanel(PANELS.AI_ASSIST.id)"
            data-name="user-settings-ai-assist"
          >
            <span class="icon material-icons">auto_fix_high</span>
            <span class="name">{{ PANELS.AI_ASSIST.name }}</span>
            <svg-icon icon="selected-item-overlay"></svg-icon>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>
  <div class="main">
    <!-- Settings Sub Panels -->
    <!-- Only show slideUp animation in mobile -->
    <app-user-settings-account
      @slideUp
      [@.disabled]="!isMobile"
      *ngIf="activePanel === PANELS.ACCOUNT.id"
      [form]="accountForm"
      [isMobile]="isMobile"
      [PANELS]="PANELS"
      (goBack)="switchSubPanel()"
      (formChangedEvent)="handleFormChanged($event)"
    ></app-user-settings-account>
    <app-user-settings-payment
      @slideUp
      [@.disabled]="!isMobile"
      *ngIf="activePanel === PANELS.PAYMENT.id && isBillingEnabled"
      [isMobile]="isMobile"
      [PANELS]="PANELS"
      [currentBilling]="currentBilling"
      (goBack)="switchSubPanel()"
      (closePanel)="closePanel()"
    ></app-user-settings-payment>
    <app-user-settings-language
      @slideUp
      [@.disabled]="!isMobile"
      *ngIf="activePanel === PANELS.LANGUAGE.id"
      [isMobile]="isMobile"
      [PANELS]="PANELS"
      (goBack)="switchSubPanel()"
    ></app-user-settings-language>
    <app-user-settings-advanced
      @slideUp
      [@.disabled]="!isMobile"
      *ngIf="activePanel === PANELS.ADVANCED.id"
      [isMobile]="isMobile"
      [PANELS]="PANELS"
      [form]="advancedSettingsForm"
      (goBack)="switchSubPanel()"
      (formChangedEvent)="handleFormChanged($event)"
    ></app-user-settings-advanced>
    <app-user-settings-permissions
      @slideUp
      *ngIf="activePanel === PANELS.PERMISSIONS.id"
      [@.disabled]="!isMobile"
      [isMobile]="isMobile"
      [PANELS]="PANELS"
      [form]="institutionForm"
      (goBack)="switchSubPanel()"
      (formChangedEvent)="handleFormChanged($event)"
    ></app-user-settings-permissions>
    <app-user-settings-sites-management
      *ngIf="activePanel === PANELS.SITES_MANAGEMENT.id"
      [panelData]="PANELS.SITES_MANAGEMENT"
    ></app-user-settings-sites-management>
    <app-user-settings-institution-custom-attributes
      *ngIf="activePanel === PANELS.CUSTOM_ATTRIBUTES.id && !isMobile"
    ></app-user-settings-institution-custom-attributes>
    <app-user-settings-ai-assist
      @slideUp
      *ngIf="activePanel === PANELS.AI_ASSIST.id"
      [@.disabled]="!isMobile"
      [isMobile]="isMobile"
      [PANELS]="PANELS"
      [form]="aiAssistForm"
      (goBack)="switchSubPanel()"
      (formChangedEvent)="handleFormChanged($event)"
    ></app-user-settings-ai-assist>
    <app-user-settings-billable-usage
      *ngIf="activePanel === PANELS.BILLABLE_USAGE.id"
      [panelData]="PANELS.BILLABLE_USAGE"
    ></app-user-settings-billable-usage>
  </div>
  <app-pencil-button
    class="pencil-button close-panel"
    (clickEvent)="closePanel()"
    data-name="spaces-manager-user-avatar-menu-settings-close"
    icon="close"
    [iconConfig]="{ type: 'mat-icon' }"
  >
  </app-pencil-button>
</div>

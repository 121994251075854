<div id="full-logo">
  <!-- TODO: Implement dark/light themes in SPAC-5268 -->
  <div class="logo-menu-container">
    <div class="logo-title-container">
      <div
        #logoTextElem
        class="logo-text"
        [appTooltip]="logoText"
        [appTooltipDisabled]="!isNameClipped"
        [appTooltipPlacement]="(userService.rtl | async) ? 'center-start' : 'center-end'"
      >
        {{ logoText }}
      </div>
      <div
        *ngIf="!user?.isAnonymous"
        #statusTextElem
        class="status-text"
        [class.student]="isStudentGuest && subscriptionType !== SubscriptionType.PAID"
        [class.trial]="
          (user | showBillingStatusIndicator) && subscriptionType === SubscriptionType.TRIAL
        "
        [class.free]="
          (user | showBillingStatusIndicator) && subscriptionType === SubscriptionType.FREE
        "
        [class.pro]="subscriptionType === SubscriptionType.PAID"
      >
        {{ billingStatus }}
      </div>
    </div>
    <app-pencil-button
      id="btn-home-sidebar-menu"
      class="pencil-button options"
      role="button"
      [matMenuTriggerFor]="optionsMenu"
      #menuOptionsTrigger="matMenuTrigger"
      [selected]="menuOptionsTrigger.menuOpen"
      *ngIf="!user?.isAnonymous"
    >
      <mat-icon>more_horiz</mat-icon>
      <div
        class="red-dot"
        *ngIf="user | showBillingStatusIndicator"
        [class.trial]="subscriptionType === SubscriptionType.TRIAL"
        [class.free]="subscriptionType === SubscriptionType.FREE"
      ></div>
    </app-pencil-button>
  </div>
  <div
    class="font-style trial-end-days"
    *ngIf="
      subscriptionType === SubscriptionType.TRIAL &&
      remainingTrialDays < 30 &&
      remainingTrialDays >= 0 &&
      (user | showBillingStatusIndicator)
    "
  >
    {{ user | referralTrialPeriodText }}
  </div>
  <div class="upgrade-button-container" *ngIf="showUpgradeButton">
    <button
      mat-stroked-button
      class="upgrade-button"
      color="white"
      (click)="goToBillingSetting()"
      data-name="banner-upgrade"
    >
      <div class="button-icon-container">
        <svg-icon
          class="button-icon svg-icon"
          [icon]="'rocket'"
          [width]="16"
          [height]="16"
        ></svg-icon>
      </div>
      <span class="upgrade-button-text">{{ 'Upgrade ' | translate }}</span>
    </button>
  </div>
</div>
<mat-menu
  #optionsMenu="matMenu"
  class="institution-settings-menu"
  [xPosition]="isRTL ? 'before' : 'after'"
>
  <div class="menu-content-wrap" [class.rtl]="isRTL">
    <app-pencil-button
      id="btn-home-sidebar-menu-settings"
      (clickEvent)="settingsService.showSettingsPanel()"
      icon="settings"
      class="pencil-button menu-item options-menu-item"
      [iconConfig]="{ type: IconTypes.materialIcon, outlined: true }"
      [label]="'Settings' | translate"
    >
    </app-pencil-button>
    <app-pencil-button
      (clickEvent)="authService.logout()"
      icon="logout"
      class="pencil-button menu-item options-menu-item"
      [iconConfig]="{ type: IconTypes.materialIcon }"
      [label]="'Sign-out' | translate"
    >
    </app-pencil-button>
  </div>
</mat-menu>

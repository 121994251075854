import { Injectable } from '@angular/core';
import { KeyScenariosOnSpaces, TelemetryService } from 'src/app/services/telemetry.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CallContext, CallError, ScreenShareOptions } from '../common/interfaces/rtc-interface';
import { ERRORS } from '../common/utils/notification-constants';
import { User } from '../models/user';
import { DevicesNotifications } from '../popup-notifications/devices-notifications';
import { SpaceDeviceModalComponent } from '../sessions/session/wb-video-controls/wb-video-controls-buttons/space-device-modal/space-device-modal.component';
import { SpaceRepository } from '../state/space.repository';
import { ToasterPopupStyle } from '../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { IconMessageToasterElement } from '../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { VirtualBackgroundPanelComponent } from '../dialogs/virtual-background-panel/virtual-background-panel.component';
import {
  CallRelatedModalAction,
  DeviceErrorType,
  JoinCallDevicesState,
  OpenDeviceSelectionParams,
} from '../models/device-manger';
import { DevicesHandleUtil, DeviceType } from '../common/utils/devices-handle-util';
import { endCallKey } from '../sessions/common/y-session';
import { DEVICE } from '../sessions/panel/participants-manager/participants-manager.component';
import { FLAGS, FlagsService } from './flags.service';
import { ModalManagerService } from './modal-manager.service';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from './notification-toaster.service';
import { RtcServiceController } from './rtc.service';
import { RealtimeSpaceService } from './realtime-space.service';
import { SpacePermissionsManagerService } from './space-permissions-manager.service';
import { UserService } from './user.service';
import { LocalTracksManagerService } from './local-tracks-manager.service';
import { DeviceAndBrowserDetectorService } from './device-and-browser-detector.service';
import { ProviderStateService } from './provider-state.service';

export enum DEVICE_STATE {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface DeviceState {
  state: boolean;
  isToggable: boolean;
  isGettingStream: boolean;
  hasError: boolean;
  errorType?: DeviceErrorType;
}

export const DEFAULT_DEVICE_STATE: DeviceState = {
  state: false,
  isToggable: false,
  isGettingStream: true,
  hasError: false,
};

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DevicesManagerService {
  isSharingScreen = new BehaviorSubject(false);
  hasScreenshareError = false;
  user?: User;
  deviceSelectionOpenedOnJoin = false;
  messageJoinCallFailedNotification?: IconMessageToasterElement;
  private _isCallActive = false;
  deviceNotification = new DevicesNotifications(
    this.translateService,
    this.notificationToasterService,
    this,
  );

  isChimesEnabled = this.flagsService.isFlagEnabled(FLAGS.SPACES_USER_CHIMES);
  private isDeviceModalOpening: Subject<boolean> = new Subject<boolean>();

  returnBackToJoinCallModal: Subject<boolean> = new Subject();

  readonly camErrorsMappings = new Map<string, string>([
    [
      DeviceErrorType.PERMISSION_DENIED,
      this.translateService.instant('Camera permissions not granted'),
    ],
    [
      DeviceErrorType.PERMISSION_DENIED_BY_SYSTEM,
      this.translateService.instant('Camera access denied by your OS'),
    ],
    [DeviceErrorType.NOT_FOUND, this.translateService.instant('No camera available')],
    [DeviceErrorType.NO_INPUT_DETECTED, this.translateService.instant('No input from camera')],
    [
      DeviceErrorType.GET_USER_MEDIA_TIMEOUT,
      this.translateService.instant('No input from camera. Click to fix'),
    ],
    [
      DeviceErrorType.DEFAULT_ERROR,
      this.translateService.instant('Verify that your camera is set-up correctly and try again'),
    ],
  ]);

  readonly micErrorsMappings = new Map<string, string>([
    [
      DeviceErrorType.PERMISSION_DENIED,
      this.translateService.instant('Microphone permissions not granted'),
    ],
    [
      DeviceErrorType.PERMISSION_DENIED_BY_SYSTEM,
      this.translateService.instant('Microphone access denied by your OS'),
    ],
    [DeviceErrorType.NOT_FOUND, this.translateService.instant('No microphone available')],
    [DeviceErrorType.NO_INPUT_DETECTED, this.translateService.instant('No input from microphone')],
    [
      DeviceErrorType.GET_USER_MEDIA_TIMEOUT,
      this.translateService.instant('No input from microphone. Click to fix'),
    ],
    [
      DeviceErrorType.DEFAULT_ERROR,
      this.translateService.instant(
        'Verify that your microphone is set-up correctly and try again',
      ),
    ],
  ]);
  isLoadingProviderAuthDone = false;
  loadingProviderAuth?: Promise<void>;
  currentMic: string | null | undefined = null;

  // Cover the case that the user enabling his devices from the device modal before starting/joining the call,
  // and the joined event isn't fired yet
  isExistPendingCamToggling = false;
  isExistPendingMicToggling = false;

  hostEndedCallBeforeAnyoneJoined: Subject<boolean> = new Subject<boolean>();

  constructor(
    private modalManagerService: ModalManagerService,
    private rtcServiceController: RtcServiceController,
    private notificationToasterService: NotificationToasterService,
    private translateService: TranslateService,
    private spacePermissionsManager: SpacePermissionsManagerService,
    private userService: UserService,
    private flagsService: FlagsService,
    private realtimeSpaceService: RealtimeSpaceService,
    private spaceRepo: SpaceRepository,
    private telemetry: TelemetryService,
    private localTracksManager: LocalTracksManagerService,
    private deviceAndBrowserDetectorService: DeviceAndBrowserDetectorService,
    private providerStateService: ProviderStateService,
  ) {
    this.spacePermissionsManager.canShareScreen$.subscribe((canShareScreen) => {
      if (!canShareScreen) {
        this.stopSharing();
      }
    });

    this.providerStateService.localParticipantEvents$
      .pipe(map((p) => this.rtcServiceController.trackExists(p.screen)))
      .subscribe(this.isSharingScreen);

    this.spacePermissionsManager.canOpenMic$.subscribe((canOpenMic) => {
      if (!canOpenMic) {
        this.localTracksManager.mute(DeviceType.AUDIO);
      }
    });

    this.spacePermissionsManager.canOpenCam$.subscribe((canOpenCam) => {
      if (!canOpenCam) {
        this.localTracksManager.mute(DeviceType.VIDEO);
      }
    });

    this.userService.user.pipe(untilDestroyed(this)).subscribe((userData) => {
      if (userData?.user) {
        this.user = userData.user;
      }
    });

    this.localTracksManager.openDeviceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((openDeviceSelectionParams) => {
        this.openDeviceSelection(openDeviceSelectionParams);
      });
  }

  logPermissionsModalShownToFullStory() {
    this.telemetry.event('v1_permissions_modal_shown', {});
  }

  logJoinCallModalShownToFullStory() {
    this.telemetry.event('v1_join_call_modal_shown', {});
  }

  logDeviceStateWithDefaultErrorToFullStory(device: DEVICE) {
    this.telemetry.event('device_state_with_default_error', { device: device });
  }

  logJoinCallButtonClickedToFullStory(insideJoinCallModal = false) {
    this.telemetry.startPerfScenario(KeyScenariosOnSpaces.JOIN_CALL);

    const isFirstJoinCallButtonClick = localStorage.getItem('firstJoinCallButtonClick') ?? 'true';
    const isFirstJoinCallAttempt = localStorage.getItem('firstJoinCallAttempt') ?? 'true';

    if (JSON.parse(isFirstJoinCallButtonClick)) {
      localStorage.setItem('firstJoinCallButtonClick', JSON.stringify(false));
    }

    this.telemetry.event(
      insideJoinCallModal
        ? 'v1_start_join_call_modal_button_clicked'
        : 'v1_start_join_call_button_clicked',
      {
        past_click_attempt: !JSON.parse(isFirstJoinCallButtonClick),
        past_successful_join_start: !JSON.parse(isFirstJoinCallAttempt),
      },
    );
  }

  set isCallActive(isCallActive: boolean) {
    this._isCallActive = isCallActive;
  }

  get isCallActive(): boolean {
    return this._isCallActive;
  }

  public subscribeToDeviceChange(getDevices) {
    const deviceChangeListener = () => {
      getDevices();
    };
    navigator.mediaDevices.addEventListener('devicechange', deviceChangeListener);

    return deviceChangeListener;
  }

  unsubscribeFromDeviceChange(deviceChangeListener) {
    if (deviceChangeListener) {
      navigator.mediaDevices.removeEventListener('devicechange', deviceChangeListener);
    }
  }

  openVirtualBackgroundModal(returnBackToJoinCallModal?: boolean): void {
    const virtualBackgroundsParams = {
      panelClass: 'virtual-background-dialog',
      autoFocus: false,
      disableClose: true,
    };
    const modalOptions = {
      afterClosed: () => {
        if (returnBackToJoinCallModal) {
          this.returnBackToJoinCallModal.next(true);
        }
      },
    };
    this.modalManagerService.showModal(
      VirtualBackgroundPanelComponent,
      virtualBackgroundsParams,
      modalOptions,
    );
  }

  openDeviceSelection(openDeviceSelectionParams?: OpenDeviceSelectionParams): void {
    this.modalManagerService.showModal(
      SpaceDeviceModalComponent,
      {
        panelClass: 'wb-device-dialog',
        data: {
          returnBackToJoinCallModal: openDeviceSelectionParams?.returnBackToJoinCallModal,
          deviceErrorType: openDeviceSelectionParams?.deviceErrorType,
        },
        autoFocus: false,
        disableClose: true,
      },
      {
        afterClosed: async () => {
          // call callback after modal is closed
          this.localTracksManager.closeUnneededStreamsOnCallModalClose(
            CallRelatedModalAction.CLOSE_SPACE_DEVICE_MODAL,
          );
          if (openDeviceSelectionParams?.callbackAfterModalClosed) {
            openDeviceSelectionParams?.callbackAfterModalClosed();
          }

          if (openDeviceSelectionParams?.returnBackToJoinCallModal) {
            this.returnBackToJoinCallModal.next(true);
          }
        },
      },
    );
  }

  // force join call when the user room changed and the user was already in a call
  // in the previous room
  async joinCallWithDeviceState(
    joinCallDevicesState: JoinCallDevicesState,
    resetDevicesErrorStates = false,
    skipNoActiveCallOnJoiningCheck = false,
    forceSettingInputDevices = true,
    forceJoinCall = false,
  ) {
    this.logJoinCallButtonClickedToFullStory(true);
    joinCallDevicesState.unmuteCam &&= this.spacePermissionsManager.canOpenCam();
    joinCallDevicesState.unmuteMic &&= this.spacePermissionsManager.canOpenMic();
    if (
      (forceJoinCall || this._isCallActive || this.spacePermissionsManager.canStartCall()) &&
      !this.rtcServiceController.service.isConnected() &&
      !this.rtcServiceController.service.isConnecting()
    ) {
      this.localTracksManager.applyDevicesStatesOnJoinCall(joinCallDevicesState);
      return this.joinCall(
        resetDevicesErrorStates,
        skipNoActiveCallOnJoiningCheck,
        forceSettingInputDevices,
      );
    } else if (!this._isCallActive && !this.spacePermissionsManager.canStartCall()) {
      this.handleHostEndedCallBeforeAnyoneJoined();
    }
    return false;
  }

  private handleHostEndedCallBeforeAnyoneJoined() {
    this.hostEndedCallBeforeAnyoneJoined.next(true);

    this.displayHostEndedCallNotification();
  }

  private displayHostEndedCallNotification() {
    const title = new IconMessageToasterElement(
      { icon: 'phone_disabled', size: 16 },
      this.translateService.instant('Could not join call'),
    );
    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(
        'We could not connect you to the call as the host has left the call.',
      ),
    );
    const hostHasEndedCallNotificationData = new NotificationDataBuilder(ERRORS.HOST_HAS_ENDED_CALL)
      .style(ToasterPopupStyle.ERROR)
      .type(NotificationType.ERROR)
      .timeOut(5)
      .topElements([title])
      .middleElements([messageElement])
      .dismissable(true)
      .build();
    this.notificationToasterService.showNotification(hostHasEndedCallNotificationData);
  }

  async joinCall(
    resetDevicesErrorStates = false,
    skipNoActiveCallOnJoiningCheck = false,
    forceSettingInputDevices = false,
  ): Promise<boolean> {
    if (this.messageJoinCallFailedNotification) {
      this.messageJoinCallFailedNotification.clearCountDown();
    }
    // Checking if retrieving RTC auth token is still in progess to avoid making another API request to load RTC token
    if (!this.isLoadingProviderAuthDone && this.loadingProviderAuth) {
      try {
        await this.loadingProviderAuth;
        this.isLoadingProviderAuthDone = true;
        return this.joinCall(resetDevicesErrorStates, skipNoActiveCallOnJoiningCheck);
      } catch (err) {
        this.isLoadingProviderAuthDone = true;
        return this.joinCall(resetDevicesErrorStates, skipNoActiveCallOnJoiningCheck);
      }
    }

    // Handle RTC auth token is loaded, but we can't set it
    if (!this.rtcServiceController.service.doesAuthTokenExist() && this.spaceRepo.activeSpace) {
      try {
        await this.rtcServiceController.service.loadAuth(
          this.spaceRepo.activeSpace?._id,
          this.spaceRepo.activeSpaceCurrentRoom?.uid,
          CallContext.SESSION,
        );
      } catch (err) {
        this.logJoinCallFailed(err);
        this.providerStateService.callErrors = CallError.TIME_OUT;
        return false;
      }
    } else if (!this.rtcServiceController.service.doesAuthTokenExist()) {
      // this case should not happen
      this.logJoinCallFailed('No available provider auth, and undefined session object');
      return false;
    }

    // Actual join call trial
    const isJoinCallSuccessed = await this.tryJoinCall(
      skipNoActiveCallOnJoiningCheck,
      forceSettingInputDevices,
    );
    return isJoinCallSuccessed;
  }

  private async tryJoinCall(
    skipNoActiveCallOnJoiningCheck = false,
    forceSettingInputDevices = false,
  ): Promise<boolean> {
    if (
      this.rtcServiceController.service.isConnected() ||
      this.rtcServiceController.service.isConnecting()
    ) {
      return false;
    }

    try {
      await this.rtcServiceController.service.join(forceSettingInputDevices);
      const isUserEligible = this.checkUserEligibilityToContinueCall(
        skipNoActiveCallOnJoiningCheck,
      );

      if (!isUserEligible) {
        return false;
      }

      this.resetCallMetadataOnSuccessfulJoin();
      return true;
    } catch (error) {
      this.logJoinCallFailed(error);
      return false;
    }
  }

  private resetCallMetadataOnSuccessfulJoin() {
    if (this.spacePermissionsManager.canStartCall()) {
      this.realtimeSpaceService.service.modifySessionMetadata({
        [endCallKey(this.spaceRepo.activeSpaceCurrentRoom?.uid)]: false,
      });
    }
  }

  // Used to cover all cases that are out-of-sync between call presence system & actual join call using RTC provider
  private checkUserEligibilityToContinueCall(skipNoActiveCallOnJoiningCheck = false): boolean {
    if (
      skipNoActiveCallOnJoiningCheck ||
      this._isCallActive ||
      this.spacePermissionsManager.canStartCall()
    ) {
      return true;
    }

    return false;
  }

  logJoinCallFailed(payload: any) {
    this.telemetry.event('Join Call Failed', {
      error: payload,
    });
  }

  handleLoadingRTCAuthToken(spaceId: string, breakoutRoomId: string | undefined) {
    this.rtcServiceController.service.clearRoomAuthToken();
    this.isLoadingProviderAuthDone = false;
    this.loadingProviderAuth = undefined;

    this.loadingProviderAuth = this.rtcServiceController.service
      .loadAuth(spaceId, breakoutRoomId, CallContext.SESSION)
      .then(() => {
        this.isLoadingProviderAuthDone = true;
      })
      .catch((error) => {
        this.isLoadingProviderAuthDone = true;
        if (!environment.production) {
          console.log('Loading RTC Auth Token Error:', error);
        }
      });
  }

  isiOS() {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        navigator.userAgent,
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  async shareScreen(options?: ScreenShareOptions): Promise<void> {
    this.notificationToasterService.dismissNotificationsByCode([ERRORS.GRANT_SCREENSHARE_ACCESS]);
    this.hasScreenshareError = false;
    if (this.rtcServiceController.service.isConnected()) {
      try {
        await this.rtcServiceController.service.startScreenShare(
          options,
          async () => {
            // onEnd
            this.hasScreenshareError = false;
          },
          async (error: any) => {
            // onError
            this.hasScreenshareError = true;
            const errorMsg = Object.prototype.hasOwnProperty.call(error, 'details')
              ? `${error.details.browserError.name}:${error.details.browserError.message}`
              : error;
            this.captureStartShareScreenError(errorMsg.toString());
          },
        );
      } catch (err) {
        this.deviceNotification.showGrantScreenshareAccessNotification();
        return;
      }
    }
  }

  /**
   * check if the browser supported sharing the screen
   */
  isSharingScreenSupported(): boolean {
    return !!navigator.mediaDevices?.getDisplayMedia;
  }
  private captureStartShareScreenError(error: string) {
    // 1st check: To capture the permission is disabled at OS level on Firefox only, and
    // to capture the permission is disabled at OS level on Chrome, Edge and at browser level on Safari
    // 2nd check: To capture all un-discovered errors, and keeping not showing any error notification once canceling the share screen popup on Chrome, and Edge
    // 3rd check: To handle the permission is disabled at OS level in case of Daily as they added a layer to the original error
    // 4th check: To show the error notification in case of Firefox, and Safari if the user blocked the first request
    if (
      this.isNotFoundError(error) ||
      (this.isNotAllowedError(error) &&
        (DevicesHandleUtil.isDeniedBySystem(error) || this.isNotAllowedByUserAgent(error))) ||
      this.isBlockedByOS(error) ||
      ((this.deviceAndBrowserDetectorService.isFirefox() ||
        this.deviceAndBrowserDetectorService.isSafari()) &&
        this.isBlockedByBrowser(error))
    ) {
      this.deviceNotification.showGrantScreenshareAccessNotification();
      this.logStartScreenErrorsToFullStory('Screen share: Permission Denied');
    } else if (
      !(
        this.isNotAllowedError(error) &&
        !(DevicesHandleUtil.isDeniedBySystem(error) && this.isNotAllowedByUserAgent(error))
      ) &&
      !this.isBlockedByBrowser(error)
    ) {
      this.deviceNotification.showScreenshareFailedNotification();
      this.logStartScreenErrorsToFullStory('Screen share: Failed to start');
    }
  }

  // @TODO [Elwakeel]: Move all the following static functions to DevicesHandleUtil
  isNotFoundError(error: string): boolean {
    return error.toLocaleLowerCase().includes('notfounderror');
  }

  isNotAllowedError(error: string): boolean {
    return error.toLocaleLowerCase().includes('notallowederror');
  }

  isNotAllowedByUserAgent(error: string): boolean {
    return error.toLocaleLowerCase().includes('not allowed by the user agent');
  }

  isBlockedByOS(error: string): boolean {
    return error.toLocaleLowerCase().includes('blocked-by-os');
  }

  isBlockedByBrowser(error: string): boolean {
    return error.toLocaleLowerCase().includes('blocked-by-browser');
  }

  logStartScreenErrorsToFullStory(type: string) {
    // log to FullStory
    try {
      this.telemetry.event(type, {});
    } catch (e) {
      console.log('fullstory event failed', e);
    }
  }

  async stopSharing(): Promise<void> {
    await this.rtcServiceController.service.stopScreenShare();
  }

  async getDevicesAccess() {
    try {
      const streams = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      streams?.getTracks().forEach((track) => track.stop());
    } catch (getUserMediaError) {
      throw Error(getUserMediaError);
    }
  }

  get openingDeviceModal(): Observable<boolean> {
    return this.isDeviceModalOpening.asObservable();
  }

  setOpeningDeviceModal(value: boolean) {
    this.isDeviceModalOpening.next(value);
  }
}

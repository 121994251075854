import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { URLService } from './dynamic-url.service';

export interface IdValue {
  _id: string;
  value: string;
}

export interface InstitutionCustomAttribute {
  key: IdValue;
  values: IdValue[];
}

export interface EditCustomAttribute {
  id: string;
  addValues?: string[];
  renameValues?: {
    id: string;
    value: string;
  }[];
  attribute?: {
    key?: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class InstitutionCustomAttributesService {
  constructor(private readonly urlService: URLService, private readonly http: HttpClient) {}

  private API_URL = `${this.urlService.getDynamicUrl()}/attributes`;

  /**
   * Fetches custom attributes from the server by sending a GET request.
   * The response contains an array of custom attributes.
   * Each custom attribute contains a key and an array of values.
   * The key is an object with an id and a value.
   * The values are an array of objects with an id and a value.
   *
   * @returns Observable<any> - The response from the server.
   */
  public getCustomAttributes(): Observable<{
    custom_attributes: {
      attribute_key: IdValue;
      attribute_value: IdValue[];
    }[];
  }> {
    return this.http.get<{
      custom_attributes: {
        attribute_key: IdValue;
        attribute_value: IdValue[];
      }[];
    }>(this.API_URL);
  }

  /**
   * Adds a custom attribute by sending a POST request to the server.
   * The custom attribute object contains a key and an array of values.
   * The key is a string and the values are an array of strings.
   *
   * @param customAttribute: { key: string, values: string[] } - The custom attribute object containing a key and values.
   * @returns Observable<InstitutionCustomAttribute> - The response from the server.
   */

  public addCustomAttribute(customAttribute: {
    key: string;
    values: string[];
  }): Observable<InstitutionCustomAttribute> {
    return this.http.post<InstitutionCustomAttribute>(this.API_URL, customAttribute);
  }

  /**
   * Updates a custom attribute by sending a PUT request to the server.
   * If the attribute key is provided, it updates the attribute name at /attributes/<key>.
   * If addValues or renameValues are provided, it updates the attribute values at /attributes/updateAttributes/<key>.
   *
   * @param customAttribute: EditCustomAttribute - The custom attribute object containing id, addValues, renameValues, and attribute.
   * @returns Observable<any> - The response from the server.
   **/
  public updateCustomAttribute(
    customAttribute: EditCustomAttribute,
  ): Observable<InstitutionCustomAttribute | null> {
    if (customAttribute.attribute?.key) {
      if (customAttribute.addValues || customAttribute.renameValues) {
        return this.renameCustomAttribute(customAttribute).pipe(
          switchMap(() => this.updateCustomAttributeValues(customAttribute)),
        );
      } else {
        return this.renameCustomAttribute(customAttribute);
      }
    } else {
      return this.updateCustomAttributeValues(customAttribute);
    }
  }

  public convertEditCustomAttributeToInstitutionCustomAttribute(
    customAttribute: EditCustomAttribute,
  ): InstitutionCustomAttribute {
    // Extract the key from the custom attribute
    const key = {
      _id: customAttribute.id,
      value: customAttribute.attribute?.key || '',
    };

    // Determine the values based on the custom attribute properties
    let values: IdValue[] = [];
    if (customAttribute.addValues) {
      values = customAttribute.addValues.map((value) => ({ _id: '', value }));
    } else if (customAttribute.renameValues) {
      values = customAttribute.renameValues.map((value) => ({ _id: value.id, value: value.value }));
    }

    // Return the new InstitutionCustomAttribute
    return {
      key,
      values,
    };
  }

  private renameCustomAttribute(
    customAttribute: EditCustomAttribute,
  ): Observable<InstitutionCustomAttribute> {
    return this.http.patch<InstitutionCustomAttribute>(`${this.API_URL}/${customAttribute.id}`, {
      attribute: customAttribute.attribute,
    });
  }

  private updateCustomAttributeValues(
    customAttribute: EditCustomAttribute,
  ): Observable<InstitutionCustomAttribute> {
    return this.http.patch<InstitutionCustomAttribute>(
      `${this.API_URL}/updateAttributes/${customAttribute.id}`,
      {
        addValues: customAttribute.addValues,
        renameValues: customAttribute.renameValues,
      },
    );
  }
}

import { Component, Input } from '@angular/core';

import { User } from '../../models/user';

@Component({
  selector: 'app-role-chip',
  templateUrl: './role-chip.component.html',
  styleUrls: ['./role-chip.component.scss'],
})
export class RoleChipComponent {
  @Input() user?: User;

  getAccentColor(): any {
    if (!this.user?.institution?.personas) {
      return {
        color: '#f8f8f8',
        name: this.user?.personas?.[0],
      };
    }

    const institutionPersonas = Object.keys(this.user.institution?.personas);
    const userPersona = institutionPersonas.find((persona) => persona === this.user?.personas?.[0]);

    if (!userPersona) {
      return {
        color: '#f8f8f8',
        name: this.user.personas?.[0],
      };
    }

    return {
      color: this.user.institution?.personas[userPersona]?.accentColor ?? '#f8f8f8',
      name: this.user.institution?.personas[userPersona]?.name,
    };
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripWordFromString',
})
export class StripWordFromStringPipe implements PipeTransform {
  transform(value: string, word: string): string {
    return value.replace(new RegExp(word, 'g'), '');
  }
}

<div id="archive-course-dialog">
  <div class="title-section text-center">
    {{ 'Archive Course' | translate }}
  </div>

  <div class="main-section">
    <div class="archive-sign">
      <i class="material-icons-outlined">archive</i>
    </div>
    <div>
      <p>
        <span>{{ 'Are you sure you want to archive ' | translate }}</span>
        <b>"{{ courseName }}" </b>
        <span>{{ 'course' | translate }}?</span>
      </p>
      <p class="_light">
        {{ 'To recover this course, you will need to contact support' | translate }}
      </p>
    </div>
    <div>
      <p>{{ 'Please type in the course name to confirm archiving.' | translate }}</p>
      <input
        type="text"
        [(ngModel)]="courseNameCheck"
        [placeholder]="'Type course name...' | translate"
      />
    </div>
  </div>

  <div class="buttons text-center cpt-32 cpb-24">
    <button mat-flat-button class="btn-cancel cmr-16" (click)="close()">
      <i class="material-icons">close</i>
      <span class="text-capitalize">{{ 'cancel' | translate }}</span>
    </button>

    <button
      mat-flat-button
      class="btn-publish"
      (click)="submit()"
      [disabled]="courseName !== courseNameCheck"
    >
      <i class="material-icons-outlined">done</i>
      <span class="text-capitalize">{{ 'confirm' | translate }}</span>
    </button>
  </div>
</div>

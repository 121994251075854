import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-multi-value-pills',
  templateUrl: './multi-value-pills.component.html',
  styleUrls: ['./multi-value-pills.component.scss'],
})
export class MultiValuePillsComponent {
  @Input()
  values: string[] = [];

  @Input()
  pillsTypeName!: string;

  @Input()
  isCollapsed = true;

  @Input()
  tooltipMode = true;

  @Input()
  showLimit = 5;
}

import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Session, Visibility } from '../models/session';
import { User } from '../models/user';
import { URL_CONSTANTS } from '../common/utils/url';

const USER_HAS_ACCESS_TO_SESSION_ID = 'userHasAccessToSession';
const USER_HAS_NO_ACCESS_TO_SESSION_ID = 'userHasNoAccessToSession';
const USER_HAS_ACCESS_TO_WAITING_ROOM_ID = 'userHasAccessToWaitingRoom';
const SKIP_WAITING_ROOM_ID = 'skipWaitingRoom';

export function canUserAccessSpace(session: Session, user?: User): boolean {
  if (!user) {
    return false;
  }

  const userHasAccessToSession = session?.context?.users?.find((userId) => userId === user._id);

  const isOwner = session.owner === user._id;
  const isAnonymousUser = user?.isAnonymous;

  return Boolean(userHasAccessToSession || isOwner || isAnonymousUser);
}

export function anonymousUserTryingToAccessPrivateSpace(session: Session, user?: User): boolean {
  if (!user) {
    return false;
  }

  const isPrivateSpace = session.visibility === Visibility.PRIVATE;
  const isAnonymousUser = user?.isAnonymous;

  return Boolean(isPrivateSpace && isAnonymousUser);
}

export function userShouldSkipWaitingRoom(
  session: Session,
  waitingRoomEnabled: boolean,
  user?: User,
): boolean {
  if (!user) {
    return false;
  }

  const isOwner = session.owner === user._id;
  const sessionUser = session.users.find((currUser) => currUser._id === user._id);
  const isHost = sessionUser?.isOwner;
  const isAnonymousUser = user?.isAnonymous;

  return (isOwner || isHost || !waitingRoomEnabled) && !isAnonymousUser;
}

export function checkUserHasAccessToSessionState(router: Router) {
  const routerState = router.getCurrentNavigation()?.extras.state;
  return routerState && routerState[USER_HAS_ACCESS_TO_SESSION_ID];
}

export function navigateToSessionAndSkipReloading(router: Router, route: ActivatedRouteSnapshot) {
  router.navigate([`/${URL_CONSTANTS.SPACES}/${route.params.id}`], {
    state: { [USER_HAS_ACCESS_TO_SESSION_ID]: true },
  });
}

export function checkUserHasNoAccessToSessionState(router: Router) {
  const routerState = router.getCurrentNavigation()?.extras.state;
  return routerState && routerState[USER_HAS_NO_ACCESS_TO_SESSION_ID];
}

export function navigateToRequestAccessAndSkipReloading(
  router: Router,
  route: ActivatedRouteSnapshot,
) {
  router.navigate([`/${URL_CONSTANTS.SPACES}/${URL_CONSTANTS.REQUEST_ACCESS}/${route.params.id}`], {
    state: { [USER_HAS_NO_ACCESS_TO_SESSION_ID]: true },
  });
}

export function checkUserHasAccessToWaitingRoomState(router: Router) {
  const routerState = router.getCurrentNavigation()?.extras.state;
  return routerState && routerState[USER_HAS_ACCESS_TO_WAITING_ROOM_ID];
}

export function navigateToWaitingRoomAndSkipReloading(
  router: Router,
  route: ActivatedRouteSnapshot,
) {
  router.navigate([`/${URL_CONSTANTS.SPACES}/${URL_CONSTANTS.WAITING_ROOM}/${route.params.id}`], {
    state: { [USER_HAS_ACCESS_TO_WAITING_ROOM_ID]: true },
  });
}

export function checkSkipWaitingRoomState(router: Router) {
  const routerState = router.getCurrentNavigation()?.extras.state;
  return routerState && routerState[SKIP_WAITING_ROOM_ID];
}

export function navigateSessionAndSkipWaitingRoom(router: Router, spaceID: string) {
  router.navigate([`spaces/${spaceID}`], { state: { [SKIP_WAITING_ROOM_ID]: true } });
}

// used to solve an issue with navigation state being persisted after using the back button
export function checkIfCodeTriggeredNavigation(router: Router) {
  const currentNavigation = router.getCurrentNavigation();
  return currentNavigation && currentNavigation.trigger === 'imperative';
}

// replace current navigation state to remove the state after used once.
export function clearCurrentNavigationState() {
  history.replaceState(null, '', window.location.href);
}

<ng-container [ngSwitch]="type">
  <div class="image-container">
    <svg-icon *ngSwitchCase="'svg-icon'" [icon]="name"></svg-icon>
    <mat-icon *ngSwitchCase="'mat-icon'">{{ name }}</mat-icon>
    <img *ngSwitchCase="'img'" [src]="name" [alt]="name" />
    <span
      *ngSwitchCase="'material-icon'"
      [ngClass]="{ 'material-icons-outlined': outlined, 'material-icons': !outlined }"
      >{{ name }}</span
    >
  </div>
</ng-container>

import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fabric } from 'fabric';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs';
import { filterNil } from '@ngneat/elf';
import { DomListenerFactoryService } from 'src/app/services/dom-listener-factory.service';
import {
  SessionSharedDataService,
  WhiteboardBackground,
} from 'src/app/services/session-shared-data.service';
import { SpaceRepository } from 'src/app/state/space.repository';
import { SessionsVptService } from '../../../../services/sessions-vpt.service';
import { SpaceBoardsService } from '../../../../services/space-boards.service';
import { Frame } from '../../../../models/session';

export const DEFAULT_BACKGROUND_COLOR = '#F6F7F9';
// this value was added to provide a hotfix for SPAC-8175
const MIN_Z_INDEX_VALUE = -99999;

@UntilDestroy()
@Component({
  selector: 'app-background-canvas',
  templateUrl: './background-canvas.component.html',
  styleUrls: ['./background-canvas.component.scss'],
})
export class GridCanvasComponent implements OnInit, OnDestroy {
  frameBackgroundColor = DEFAULT_BACKGROUND_COLOR;
  frameCurrentPattern = WhiteboardBackground.DOT;
  private gridCanvas?: fabric.StaticCanvas;

  private readonly HIDE_PATTERN_ZOOM_LEVEL = 35;

  currentZoomLevel = 100;
  strokeWidthMultiplier = 1;
  isInHideZoomLevel = false;
  strokeValuesMapBasedOnZoom = {
    DEFAULT_MULTIPLIER: 1,
  };
  domListener = this.domListenerFactoryService.createInstance();

  zIndex$ = this.spaceRepo.activeSpace$.pipe(
    filterNil(),
    switchMap((space) =>
      this.spaceRepo.boardCanvasItems$(space._id, space.selectedBoardUid as string),
    ),
    map((data) => data.objects),
    distinctUntilChanged((prev, curr) => prev.length !== curr.length),
    map((objects) => Math.min(-(objects.length + 1), MIN_Z_INDEX_VALUE)),
    startWith(MIN_Z_INDEX_VALUE),
  );

  constructor(
    private sharedDataService: SessionSharedDataService,
    private sessionsVptService: SessionsVptService,
    private domListenerFactoryService: DomListenerFactoryService,
    private spaceBoardsService: SpaceBoardsService,
    private spaceRepo: SpaceRepository,
    private staticCanvasBackgroundSetter: StaticCanvasBackgroundSetter,
  ) {}

  ngOnInit(): void {
    this.setupCanvas();
    this.setupFrameChangeHandler();
    this.setupViewportHandler();
  }

  ngOnDestroy(): void {
    this.domListener.clear();
    this.gridCanvas?.clear();
    this.gridCanvas?.dispose();
  }

  private setupCanvas(): void {
    this.gridCanvas = new fabric.StaticCanvas('grid-fabric-canvas', {
      width: window.innerWidth,
      height: window.innerHeight,
    });

    this.domListener.add(window, 'resize', (event: UIEvent) => {
      this.gridCanvas?.setDimensions({
        width: (event.currentTarget as Window).innerWidth,
        height: (event.currentTarget as Window).innerHeight,
      });
    });
  }

  /**
   * listen for the new active frame to set the background as the current active frame
   * @private
   */
  private setupFrameChangeHandler(): void {
    this.spaceBoardsService.activeSpaceSelectedBoard$
      .pipe(untilDestroyed(this))
      .subscribe((frame) => {
        if (!frame) {
          return;
        }

        const newColor = frame?.backgroundColor || DEFAULT_BACKGROUND_COLOR;
        const newPattern = frame?.backgroundPattern || WhiteboardBackground.DOT;
        this.frameBackgroundColor = newColor;
        this.frameCurrentPattern = newPattern;

        this.staticCanvasBackgroundSetter.setBackgroundPattern(
          this.gridCanvas,
          this.strokeWidthMultiplier,
          this.frameCurrentPattern,
        );
      });
  }

  private setupViewportHandler(): void {
    this.sessionsVptService.viewportTransform.pipe(untilDestroyed(this)).subscribe((vpt) => {
      if (vpt) {
        this.handleViewportTransform(vpt);
      }
    });
  }

  // Handle the viewport transform and adjust the canvas
  private handleViewportTransform(vpt: number[]): void {
    const currentZoomLevel =
      +(<number>this.sharedDataService.fabricCanvas?.getZoom() * 100).toPrecision(3) || 100;

    this.gridCanvas?.setViewportTransform(vpt);

    const currentActiveBoard = this.spaceBoardsService.activeSpaceSelectedBoard;
    // if pattern already none, do nothing
    if (currentActiveBoard?.backgroundPattern === WhiteboardBackground.NONE) {
      return;
    }
    // remove background pattern ( less than 40% )
    if (currentZoomLevel < this.HIDE_PATTERN_ZOOM_LEVEL && !this.isInHideZoomLevel) {
      this.isInHideZoomLevel = true;
      this.staticCanvasBackgroundSetter.setBackgroundPattern(
        this.gridCanvas,
        this.strokeWidthMultiplier,
        WhiteboardBackground.NONE,
      );
    }

    this.updateBackgroundOnZoomLevelChanges(currentZoomLevel, currentActiveBoard);
  }

  private updateBackgroundOnZoomLevelChanges(
    currentZoomLevel: number,
    currentActiveBoard: Frame | undefined,
  ) {
    if (
      this.currentZoomLevel <= this.HIDE_PATTERN_ZOOM_LEVEL &&
      currentZoomLevel > this.HIDE_PATTERN_ZOOM_LEVEL
    ) {
      // Redraw background
      this.strokeWidthMultiplier = this.getStrokeWidthMultiplier(currentZoomLevel);
      this.isInHideZoomLevel = false;
      this.staticCanvasBackgroundSetter.setBackgroundPattern(
        this.gridCanvas,
        this.strokeWidthMultiplier,
        currentActiveBoard?.backgroundPattern || WhiteboardBackground.DOT,
      );
    }
    // finally update current zoom level
    this.currentZoomLevel = currentZoomLevel;
  }

  private getStrokeWidthMultiplier(currentZoomLevel: number) {
    return this.strokeValuesMapBasedOnZoom['DEFAULT_MULTIPLIER'];
  }
}

@Injectable({
  providedIn: 'root',
})
export class StaticCanvasBackgroundSetter {
  private readonly scalingFactor = 0.1;
  private readonly grid = 50;
  private readonly DEFAULT_STROKE_COLOR = '#D0D5DD';

  public async setBackgroundPattern(
    gridCanvas: fabric.StaticCanvas | undefined,
    strokeWidthMultiplier: number,
    background: WhiteboardBackground,
    color?: string,
  ) {
    let backgroundSettings: { svg: string; size: number } | undefined;
    switch (background) {
      case WhiteboardBackground.NONE:
        backgroundSettings = this.getNoBackgroundSettings(color);
        break;

      case WhiteboardBackground.DOT:
        backgroundSettings = this.getDotGridSettings(color);
        break;

      case WhiteboardBackground.SQUARE:
        backgroundSettings = this.getSquareGridSettings(color);
        break;

      case WhiteboardBackground.SMALL_SQUARE:
        backgroundSettings = this.getSmallSquareGridSettings(color);
        break;

      case WhiteboardBackground.LINE:
        backgroundSettings = this.getLineGridSettings(color);
        break;

      case WhiteboardBackground.NARROW_LINE:
        backgroundSettings = this.getSmallLineGridSettings(color);
        break;

      case WhiteboardBackground.TRIANGLE:
        backgroundSettings = this.getTriangleGridSettings(color);
        break;

      case WhiteboardBackground.WRITING_RULE_MEDIUM:
        backgroundSettings = this.getMediumWriteRuleGridSettings(strokeWidthMultiplier, color);
        break;

      case WhiteboardBackground.WRITING_RULE_SMALL:
        backgroundSettings = this.getSmallWriteRuleGridSettings(strokeWidthMultiplier, color);
        break;

      case WhiteboardBackground.WRITING_RULE_LARGE:
        backgroundSettings = this.getLargeWriteRuleGridSettings(strokeWidthMultiplier, color);
        break;
    }

    if (backgroundSettings && color) {
      const svgString = backgroundSettings.svg;
      const rectElementString = `<rect width="9999" height="9999" fill="${color}" />`;
      const insertPosition = svgString.indexOf('>') + 1;
      backgroundSettings.svg =
        svgString.slice(0, insertPosition) + rectElementString + svgString.slice(insertPosition);
    }

    if (backgroundSettings) {
      const pattern = this.getBackgroundFabricPattern(
        backgroundSettings.size,
        backgroundSettings.svg,
      );
      return this.setCanvasBackgroundWithPattern(gridCanvas, pattern);
    }
  }

  // Settings methods

  private getLineGridSettings(color?: string) {
    const square_len = this.grid / 2 / this.scalingFactor;
    const svg = this.getHorizontalLineSvg(square_len, 1.5 / this.scalingFactor / 2, color);
    return { svg, size: square_len };
  }

  private getSquareGridSettings(color?: string) {
    const square_len = this.grid / 2 / this.scalingFactor;
    const svg = this.getCrossSvg(square_len, 1.5 / this.scalingFactor / 2, color);
    return { svg, size: square_len };
  }

  private getSmallSquareGridSettings(color?: string) {
    const square_len = this.grid / 4 / this.scalingFactor;
    const svg = this.getCrossSvg(square_len, 1.5 / this.scalingFactor / 2, color);
    return { svg, size: square_len };
  }

  private getSmallLineGridSettings(color?: string) {
    const square_len = this.grid / 4 / this.scalingFactor;
    const svg = this.getHorizontalLineSvg(square_len, 1.5 / this.scalingFactor / 2, color);
    return { svg, size: square_len };
  }

  private getTriangleGridSettings(color?: string) {
    const square_len = this.grid / 2 / this.scalingFactor;
    const svg = this.getTriangleSvg(square_len, 1.5 / this.scalingFactor / 2, color);
    return { svg, size: square_len };
  }

  private getSmallWriteRuleGridSettings(strokeWidthMultiplier: number, color?: string) {
    const square_len = this.grid / 2 / this.scalingFactor;
    const height = square_len * 5;
    const width = square_len * 6.75;
    const svg = this.getWriteRuleSvg(
      height,
      width,
      (1.5 / this.scalingFactor) * strokeWidthMultiplier * 2,
      '30, 20',
      color,
    );
    const tempCanvasWidthAngHeight = square_len * 6.5;
    return { svg, size: tempCanvasWidthAngHeight };
  }

  private getMediumWriteRuleGridSettings(strokeWidthMultiplier: number, color?: string) {
    const square_len = this.grid / 2 / this.scalingFactor;

    const height = square_len * 10;
    const width = square_len * 13.5;
    const svg = this.getWriteRuleSvg(
      height,
      width,
      (1.5 / this.scalingFactor) * strokeWidthMultiplier * 2,
      '30, 40',
      color,
    );

    const tempCanvasWidthAngHeight = square_len * 12.5;
    return { svg, size: tempCanvasWidthAngHeight };
  }

  private getLargeWriteRuleGridSettings(strokeWidthMultiplier: number, color?: string) {
    const square_len = this.grid / 2 / this.scalingFactor;
    const height = square_len * 20;
    const width = square_len * 26;
    const svg = this.getWriteRuleSvg(
      height,
      width,
      (1.5 / this.scalingFactor) * strokeWidthMultiplier * 2,
      '30, 50',
      color,
    );

    const tempCanvasWidthAngHeight = square_len * 25;
    return { svg, size: tempCanvasWidthAngHeight };
  }

  // SVG Methods

  /*
   * Creates a square svg with side length len
   * with a circle in the middle with the radius specified
   */
  private getDotSvg(len: number, radius: number, color?: string): string {
    return `<svg height="${len}" width="${len}">
        <circle cx="${len / 2}" cy="${len / 2}" r="${radius}" stroke="${
      this.DEFAULT_STROKE_COLOR
    }" fill="${this.DEFAULT_STROKE_COLOR}" stroke-width="${radius}" />
      </svg>`;
  }

  /*
   * Creates a square svg with a cross in the middle
   */
  private getCrossSvg(len: number, stroke: number, color?: string): string {
    return `<svg height="${len}" width="${len}">
        <line x1="0" y1="${len / 2}" x2="${len}" y2="${len / 2}"
        stroke="${this.DEFAULT_STROKE_COLOR}" stroke-width="${stroke}}" />
        <line y1="0" x1="${len / 2}" y2="${len}" x2="${len / 2}"
        stroke="${this.DEFAULT_STROKE_COLOR}" stroke-width="${stroke}}" />
      </svg>`;
  }

  private getWriteRuleSvg(
    height: number,
    width: number,
    stroke: number,
    strokeDasharray: string,
    color?: string,
  ): string {
    return `<svg height="${height}" width="${width}">
        <line x1="0" y1="${height / 4}" x2="${width}" y2="${height / 4}"
          stroke="#ADD8E6" stroke-width="${stroke}" />
        <line x1="0" y1="${height / 2}" x2="${width}" y2="${
      height / 2
    }" stroke-dasharray="${strokeDasharray}"
          stroke="#ADD8E6" stroke-width="${stroke}" />
        <line x1="0" y1="${(height * 3) / 4}" x2="${width}" y2="${(height * 3) / 4}"
        stroke="#5E9AD0" stroke-width="${stroke}" />
         <line x1="0" y1="${height}" x2="${width}" y2="${height}"
        stroke="#ADD8E6" stroke-width="${stroke}" />
      </svg>`;
  }

  private getTriangleSvg(len: number, stroke: number, color?: string): string {
    return `<svg height="${len / 2}" width="${len / 2}">
       <line y1="0" x1="0" y2="${len}" x2="${len}"
       stroke="${this.DEFAULT_STROKE_COLOR}" stroke-width="${stroke}}"  />
        <line y1="0" x1="${len / 2}" y2="${len}" x2="${len / 2}"
        stroke="${this.DEFAULT_STROKE_COLOR}" stroke-width="${stroke}}"  />

       <line y1="0" x1="${len}" y2="${len}" x2="${0}"
        stroke="${this.DEFAULT_STROKE_COLOR}" stroke-width="${stroke}}"  />

      </svg>`;
  }

  private getHorizontalLineSvg(len: number, stroke: number, color?: string) {
    return `<svg height="${len}" width="${len}">
        <line x1="0" y1="${len / 2}" x2="${len}" y2="${len / 2}"
        stroke="${this.DEFAULT_STROKE_COLOR}" stroke-width="${stroke}}" />
      </svg>`;
  }

  private getNoBackgroundSettings(color?: string) {
    const size = 50;
    return {
      svg: `<svg height="${size}" width="${size}"></svg>`,
      size,
    };
  }

  private getDotGridSettings(color?: string) {
    const square_len = this.grid / 4 / this.scalingFactor;
    //  1 / this.scalingFactor is equivalent to 1 px
    const svg = this.getDotSvg(square_len, 1 / this.scalingFactor, color);
    return { svg, size: square_len * 2 };
  }

  /**
   * create the fabric Pattern based on the svg,
   * pattern will be used as the background for the gridCanvas
   * @param canvasWidthAndHeight
   * @param svg
   * @private
   */
  private getBackgroundFabricPattern(canvasWidthAndHeight: number, svg: string) {
    const tempCanvas = new fabric.StaticCanvas(null, {
      width: canvasWidthAndHeight,
      height: canvasWidthAndHeight,
    });
    fabric.loadSVGFromString(svg, (objs) => tempCanvas.add(...objs));
    const patternTransformScaling = this.scalingFactor / window.devicePixelRatio;
    return new fabric.Pattern({
      source: <any>tempCanvas.getElement(),
      repeat: 'repeat',
      // We use the transformation matrix to render at a higher resolution, then downscale
      patternTransform: [patternTransformScaling, 0, 0, patternTransformScaling, 0, 0],
    });
  }

  /**
   * set the pattern on the GridCanvas
   * we're using setBackgroundColor and add the pattern as param
   * @param pattern
   * @param gridCanvas
   * @private
   */
  private setCanvasBackgroundWithPattern(
    gridCanvas: fabric.StaticCanvas | undefined,
    pattern: fabric.Pattern,
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      gridCanvas?.setBackgroundColor('red', () => {
        gridCanvas?.setBackgroundColor(pattern, () => {
          // Does not render after otherwise
          requestAnimationFrame(() => {
            gridCanvas?.renderAll();
            resolve();
          });
        });
      });
    });
  }
}

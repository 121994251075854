<div class="prompt-outline diabled-outside-modal-click">
  <div class="header">
    <span [style.margin]="'auto'">{{ dialogInputData.title }}</span>
  </div>
  <div class="content">
    <div class="body">
      <div class="body-header">
        {{ (_bodyHeader$ | async) ?? dialogInputData.body.header }}
      </div>
      <div class="body-main">
        {{ dialogInputData.body.main }}
      </div>
    </div>

    <div class="control-buttons">
      <div *ngFor="let control of dialogInputData.controls">
        <app-pencil-button
          class="pencil-button primary medium control-btn"
          (clickEvent)="close(true)"
          [icon]="control.icon"
          [label]="control.text"
          [iconConfig]="{ type: control.icon.isSvg ? IconTypes.svgIcon : IconTypes.matIcon }"
        >
        </app-pencil-button>
      </div>
    </div>
  </div>
</div>

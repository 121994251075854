<main
  [ngClass]="{
    'single-chat':
      (!isPendingMessage && sourceLength === 1) ||
      (isPendingMessage && pendingMessageSourceLength === 1),
    'group-chat-start':
      (!isPendingMessage && sourceLength > 1 && index === sourceLength - 1) ||
      (isPendingMessage && pendingMessageSourceLength > 1 && pendingMessageIndex === 0),
    'group-chat-mid':
      (!isPendingMessage && sourceLength > 1 && index < sourceLength - 1 && index > 0) ||
      (isPendingMessage &&
        pendingMessageSourceLength > 1 &&
        pendingMessageIndex < pendingMessageSourceLength - 1 &&
        pendingMessageIndex > 0),
    'group-chat-end':
      (!isPendingMessage && index === 0 && sourceLength > 1) ||
      (isPendingMessage &&
        pendingMessageIndex === pendingMessageSourceLength - 1 &&
        pendingMessageSourceLength > 1) ||
      (message.parent && !isPendingMessage && sourceLength === 1) ||
      (message.parent && isPendingMessage && pendingMessageSourceLength === 1)
  }"
  class="content-wrapper"
  [class.content-wrapper-failed]="message.failed"
>
  <div class="profile-image-container">
    <app-profile-photo
      [hidden]="
        (!isPendingMessage && index !== sourceLength - 1) ||
        (isPendingMessage && pendingMessageIndex > 0)
      "
      class="profile-photo"
      width="28"
      [user]="message.author"
      [isClickEnabled]="false"
    ></app-profile-photo>
    <p
      [hidden]="
        (!isPendingMessage && index === sourceLength - 1) ||
        (isPendingMessage && pendingMessageIndex === 0)
      "
      class="chat_time"
    >
      {{
        (message?.createdAt | dateLocale: DateFormatEnum.MICROTIME) ||
          (message?.firstAttemptTimestamp | dateLocale: DateFormatEnum.MICROTIME)
      }}
    </p>
  </div>

  <div class="content-wrapper--chat">
    <div
      [hidden]="
        (!isPendingMessage && index !== sourceLength - 1) ||
        (isPendingMessage && pendingMessageIndex > 0)
      "
      class="content-wrapper--chat__header"
    >
      <div class="content-wrapper--chat__username--box">
        <h2 class="content-wrapper--chat__username">
          {{ message?.author.name || message?.author.email }}
        </h2>
      </div>
      <p
        [appTooltip]="dateTooltip"
        [appTooltipPlacement]="'bottom-center'"
        class="content-wrapper--chat__date"
      >
        {{
          (message?.createdAt | dateLocale: DateFormatEnum.SHORTTIME) ||
            (message?.firstAttemptTimestamp | dateLocale: DateFormatEnum.SHORTTIME)
        }}
      </p>
      <p
        [appTooltip]="editDateTooltip"
        [appTooltipCloseDelay]="10"
        [appTooltipPlacement]="'bottom-center'"
        *ngIf="message?.isEdited"
        class="content-wrapper--chat__status"
      >
        (Edited)
      </p>
    </div>
    <section class="received-message-content-wrapper">
      <section [hidden]="message.deleted" #messageOptions class="message-options">
        <div class="message-options-btns">
          <div
            [appTooltip]="editMessageTooltip"
            [appTooltipCloseDelay]="10"
            [appTooltipPlacement]="'top-center'"
            *ngIf="sender && enableEditing && (isHost | async)"
            (click)="emitEditMessage(message)"
            class="message-options-btn"
            data-name="chat-tab-message-options-edit"
          >
            <div class="message-options-btn-icon">
              <svg-icon [icon]="'rename-icon'" [width]="12" [height]="12"></svg-icon>
            </div>
          </div>
          <div
            *ngIf="!hideReply"
            [appTooltip]="replyMessageTooltip"
            [appTooltipCloseDelay]="10"
            [appTooltipPlacement]="'top-center'"
            (click)="emitReplyMessage(message)"
            class="message-options-btn"
            data-name="chat-tab-message-options-reply"
          >
            <div class="message-options-btn-icon">
              <svg-icon [icon]="'reply-icon'" [width]="11.73" [height]="9.06"></svg-icon>
            </div>
          </div>
          <div
            [appTooltip]="deleteMessageTooltip"
            [appTooltipPlacement]="'top-center'"
            *ngIf="
              (isPendingMessage && message.failed) ||
              (sender && (isHost | async)) ||
              ((isHost | async) && message.context.session)
            "
            appThrottleClick
            [throttleTime]="5000"
            (throttleClick)="emitDeleteMessage(message)"
            class="message-options-btn"
            data-name="chat-sent-message-options-delete"
          >
            <div class="message-options-btn-icon">
              <svg-icon
                class="delete-svg"
                [icon]="'delete-icon'"
                [width]="9.33"
                [height]="12"
              ></svg-icon>
            </div>
          </div>
        </div>
      </section>
      <ng-container *ngIf="message.parent">
        <ng-template #deleteParentMessage>
          <section class="replied-to-prview">
            <h6 class="replied-to-prview-header-text deleted-chat">Message deleted.</h6>
          </section>
        </ng-template>
        <section
          (click)="scrollToReply(message.parent)"
          *ngIf="parentMessage$ | async as parentMessage; else deleteParentMessage"
          class="replied-to-prview"
          data-name="chat-tab-replied-message-scroll-to"
        >
          <div class="replied-to-prview-header">
            <div class="replied-to-prview-icon">
              <svg-icon [icon]="'reply-icon_white'" [width]="11.73" [height]="9.06"></svg-icon>
            </div>
            <h6 class="replied-to-prview-header-text">
              Replied to
              <span *ngIf="currentUserId !== parentMessage?.author._id" class="bold-text">
                {{ parentMessage?.author.name || parentMessage?.author.email }}
              </span>
              <span
                *ngIf="
                  currentUserId === parentMessage?.author._id &&
                  message.author._id !== currentUserId
                "
                class="bold-text"
                >you</span
              >
              <span
                *ngIf="
                  currentUserId === parentMessage?.author._id &&
                  message.author._id === currentUserId
                "
                class="bold-text"
                >yourself</span
              >
            </h6>
          </div>
          <p *ngIf="!parentImageMessage" class="replied-to-prview-content">
            {{ parentMessage?.content[0]?.data.trim() }}
          </p>
          <div *ngIf="parentImageMessage" class="image-chat">
            <div class="image-chat__box">
              <img [src]="parentImageMessage.image" alt="preview" />
            </div>
            <div class="image-text__chat-box">
              <p *ngIf="parentImageMessage.text" class="image-text__chat-box--header">Photo</p>
              <p *ngIf="!parentImageMessage.text" class="image-text__chat-box--header">
                Sent a photo
              </p>
              <p class="image-text__chat-box--content">
                {{ parentImageMessage.text }}
              </p>
            </div>
          </div>
        </section>
      </ng-container>

      <ng-content></ng-content>
    </section>
  </div>
</main>

<ng-template #editMessageTooltip>
  <app-tooltip [text]="'Edit' | translate" [arrow]="'bottom'"></app-tooltip>
</ng-template>
<ng-template #replyMessageTooltip>
  <app-tooltip [text]="'Reply to message' | translate" [arrow]="'bottom'"></app-tooltip>
</ng-template>

<ng-template #deleteMessageTooltip>
  <app-tooltip [text]="'Delete' | translate" [arrow]="'bottom'"></app-tooltip>
</ng-template>

<ng-template #dateTooltip>
  <app-tooltip
    [text]="
      (message?.createdAt | dateLocale: DateFormatEnum.LONGDATE_TIME) ||
      (message?.firstAttemptTimestamp | dateLocale: DateFormatEnum.LONGDATE_TIME)
    "
    [arrow]="'top'"
  ></app-tooltip>
</ng-template>

<ng-template #editDateTooltip>
  <app-tooltip
    [text]="message?.updatedAt | dateLocale: DateFormatEnum.LONGDATE_TIME"
    [arrow]="'top'"
  ></app-tooltip>
</ng-template>

import { Pipe, PipeTransform } from '@angular/core';

import { ISession, Session, SessionUser } from '../models/session';
import { AclService } from '../services/acl.service';

@Pipe({
  name: 'sessionUserType',
})
export class SessionUserTypePipe implements PipeTransform {
  constructor(private aclService: AclService) {}

  transform(sessionUser: SessionUser, hostsIDs: Set<string>, space?: ISession): string {
    if (!space) {
      return '';
    }

    if (sessionUser._id === space.owner) {
      return 'Owner';
    }

    if (hostsIDs.has(sessionUser._id)) {
      if (Session.isUserEnabledAdminHost(sessionUser._id, space, this.aclService)) {
        return 'Admin';
      }

      return 'Host';
    }

    return 'Participant';
  }
}

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { extractMath } from 'extract-math';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, isEqual } from 'lodash-es';

import { TableIndexData } from 'src/app/models/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SessionSharedDataService } from 'src/app/services/session-shared-data.service';
import {
  Libraries,
  UploadedFile,
  UPLOAD_METHOD,
  ComputerFile,
} from 'src/app/dialogs/upload/upload/upload.component';
import { Fragment, FragmentMetadata } from '../../models/question';
import { VimeoUploadService } from '../../services/vimeo-upload.service';
import { UserService } from '../../services/user.service';
import { UploadDialogComponent } from '../../dialogs/upload/upload-dialog.component';
import { UploadFileService } from '../../services/upload-file.service';
import { Context } from '../../models/context';
import { FragmentCollection } from '../../content/course/create/create.model';
import { IResource, ResourceItemModel } from '../../models/resource';
import { TypedFragment, FragmentType } from '../../common/typed-fragment/typed-fragment';
import { QuestionsService } from '../../services/questions.service';
import { MyscriptComponent } from '../myscript/myscript.component';
import { truncateTextFragment } from '../../utilities/fragment.utils';
import { ResourcesService } from '../../services/resources.service';
import { EditorTypes } from './advanced-text-fragment.component';

interface ResponseRow {
  label: string;
  value: number;
}

@UntilDestroy()
@Component({
  selector: 'app-advanced-editor[collection]',
  templateUrl: './advanced-editor.component.html',
  styleUrls: ['./advanced-editor.component.scss'],
})
export class AdvancedEditorComponent implements OnInit {
  @Input() collection!: FragmentCollection;
  @Input() placeholder?: string;
  @Input() isOpenEnded = false;
  @Input() hideBorder = false;
  @Input() uiState?: string;
  @Input() emitTyping = false;
  @Input() componentUsedFromChatBox = false;
  @Input() showDeleteButton = false;
  @Input() saving = false;
  @Input() selected = false;
  @Input() enableShiftEnter = false;
  @Input() isFocused = false;
  @Input() hideToolbar = false;
  @Input() showNavigation = false;
  @Input() isMcq = false;
  @Input() showHint = false;
  @Input() disableHint = false;
  @Input() disableAnswer = false;
  @Input() disableExplanation = false;
  @Input() type: EditorTypes = EditorTypes.NORMAL;
  @Input() showUploadDialog = false;
  @Input() messageContext?: Context;
  @Input() popUpMessage = false;
  @Input() maxHeight = null;
  @Input() chatIdentifier?: string;
  @Input() disableNewFragment = false;
  @Input() enableList = true;
  @Input() enableFloatingToolbar = false;
  @Input() enableAddSub = true;
  @Input() enableFragmentHover = true;
  @Output() saveState = new EventEmitter();
  @Output() saveEvent = new EventEmitter();
  @Output() focused = new EventEmitter();
  @Output() blurred = new EventEmitter();
  @Output() showToolBox = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() addExplanation = new EventEmitter();
  @Output() removeOption = new EventEmitter();
  @Output() changeExpl = new EventEmitter();
  @Output() fragmentHover = new EventEmitter();

  @ViewChild('advancedEditor') editor;
  @ViewChildren('advanced-text-fragment') viewChildren!: QueryList<any>;

  answerFormat = ['Lined', 'Blank', 'Ruled', 'Graph'];
  showControls = false;
  selectedFragmentIndex = -1;
  editorCursorPosition = 0;
  fileName = '';
  uploadType = '';
  focusToEnd = 0;
  showFocus: boolean[] = [];
  focusPosition = 0;
  rows: ResponseRow[] = [];
  selectedRange = null;
  isResizeStarted = false;
  dragSortedIndex = -1;
  public FragmentType = FragmentType;
  public selectedImage: TypedFragment | null = null;
  public boundInfiniteScroll!: (event: KeyboardEvent) => void;
  selectedDragFragment: FragmentCollection | null = null;
  selectedTableIndexData: TableIndexData | null = null;
  showTableImageDialog = false;
  showTableFormulaDialog = false;
  editorTypes = EditorTypes;

  constructor(
    public vimeoStorage: VimeoUploadService,
    public toastrService: ToastrService,
    public dialog: MatDialog,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private questionsService: QuestionsService,
    private translateService: TranslateService,
    public uploadService: UploadFileService,
    public resourceService: ResourcesService,
    private spaceSharedDataService: SessionSharedDataService,
    private ngZone: NgZone,
  ) {
    const rows = [1, 2, 3, 4, 5, 10, 20];
    rows.forEach((row) => {
      this.rows.push({
        label: `${row} ${this.translateService.instant(row === 1 ? 'Row' : 'Rows')}`,
        value: row,
      });
    });

    this.questionsService.editorCursorPosition.pipe(untilDestroyed(this)).subscribe((res) => {
      this.editorCursorPosition = res;
    });

    this.questionsService.operation.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res?.type && this.selected) {
        switch (res?.type) {
          case 'EQUATION':
            this.openKatexDialog(null, -1);
            break;
          case 'ALIGN_LEFT':
            this.changeAlignment('flex-start');
            break;
          case 'ALIGN_RIGHT':
            this.changeAlignment('flex-end');
            break;
          case 'ALIGN_CENTER':
            this.changeAlignment('center');
            break;
          case 'TABLE':
            this.collection.addTableFragment(this.selectedFragmentIndex);
            break;
          case ResourceItemModel.IMAGE:
            this.showImageUploadDialog();
            break;
          case ResourceItemModel.VIDEO:
            this.showVideoUploadDialog();
            break;
          case ResourceItemModel.DOCUMENT:
            this.showPdfUploadDialog();
            break;
          case ResourceItemModel.SIMULATION:
            this.showPhetUploadDialog();
            break;
          case 'DELETE':
            this.removeCollection();
            break;
        }
      }
    });
  }

  ngOnInit(): void {
    if (this.isFocused && this.type !== EditorTypes.NORMAL) {
      this.showFocus[0] = true;
    }
  }

  public dragOver(event: DragEvent, self: HTMLDivElement): void {
    if (event.preventDefault) {
      event.preventDefault();
    }
    self.classList.add('drag-over');
  }

  public removeDragOverStyle(event: DragEvent, self: HTMLDivElement): void {
    if (event.preventDefault) {
      event.preventDefault();
    }
    self.classList.remove('drag-over');
  }

  public drop(event: DragEvent, self: HTMLDivElement): void {
    if (event.preventDefault) {
      event.preventDefault();
    }
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    const image_data = event.dataTransfer;
    if (image_data && image_data.files) {
      for (const image_file of <any>image_data.files) {
        if (image_file.type.includes('image')) {
          this.collection.addFile(image_file, this.uploadService, this.resourceService);
        }
      }
    }
    self.classList.remove('drag-over');
  }

  reorderFragment(event: CdkDragDrop<string[]>): void {
    this.dragSortedIndex = -1;
    this.selectedDragFragment = null;
    if (event.previousIndex === event.currentIndex) {
      return;
    }
    this.collection.updated = true;
    moveItemInArray(this.collection.fragments, event.previousIndex, event.currentIndex);
    this.saveEvent.emit(this.collection);
  }

  changeAlignment(alignment): void {
    const fragment = this.collection.fragments[this.selectedFragmentIndex];
    if (fragment) {
      if (typeof fragment.fragment.metadata === 'undefined') {
        fragment.fragment.metadata = new FragmentMetadata();
      }
      fragment.fragment.metadata.content_alignment = alignment;
      this.saveEvent.emit(this.collection);
    }
  }

  getAlignment(fragment: Fragment): string {
    if (typeof fragment.metadata === 'undefined' || !fragment.metadata.content_alignment) {
      return 'flex-start';
    }
    return fragment.metadata.content_alignment;
  }

  getTextAlignment(fragment: Fragment): string {
    if (
      typeof fragment.metadata === 'undefined' ||
      fragment.metadata.content_alignment === '' ||
      fragment.metadata.content_alignment === 'flex-start'
    ) {
      return 'left';
    }
    if (fragment.metadata.content_alignment === 'flex-end') {
      return 'right';
    }
    return 'center';
  }

  public selectImageClicked(fragment: TypedFragment | null, selected: boolean, event?: Event) {
    this.ngZone.runOutsideAngular(() => {
      if (!fragment) {
        return;
      }
      if (event) {
        event.stopPropagation();
      }
      fragment['imageSelected'] = selected;
      if (selected) {
        this.selectedImage = fragment;
        window.removeEventListener('keydown', this.boundInfiniteScroll);
        this.boundInfiniteScroll = this.isKeyPressed.bind(this);
        window.addEventListener('keydown', this.boundInfiniteScroll);
      } else {
        this.selectedImage = null;
        window.removeEventListener('keydown', this.boundInfiniteScroll);
      }
    });
  }

  public isKeyPressed(event: KeyboardEvent) {
    const pressedKey = event?.code || event?.key;
    if (pressedKey === 'Backspace' || pressedKey === 'Delete') {
      this.deleteFragment(<TypedFragment>this.selectedImage);
      window.removeEventListener('keydown', this.boundInfiniteScroll);
      this.selectedImage = null;
    }
  }

  deleteFragment(fragment: TypedFragment): void {
    this.collection.updated = true;
    this.collection.deleteFragment(fragment);
    if (this.checkEmpty()) {
      this.collection.fragments.forEach((frag) => {
        if (frag.type === 'ANSWERTEXT') {
          this.deleteFragment(frag);
        }
      });
    }
    this.saveEvent.emit(this.collection);
  }

  switchTextMode(fragment: TypedFragment): void {
    fragment.myscript = !fragment.myscript;
  }

  deleteAnswerFragment(fragment: TypedFragment): void {
    this.collection.updated = true;
    this.collection.deleteAnswerFragment(fragment);
  }

  addSubQuestion(): void {
    this.add.emit(this.collection);
  }

  removeCollection(): void {
    if (this.isOpenEnded) {
      this.remove.emit(this.collection['id']);
    } else {
      this.removeOption.emit(this.collection.option_data);
    }
  }

  saveChanges(): void {
    this.saveEvent.emit(this.collection);
  }

  handleDragStarted(): void {
    this.questionsService.textSelected.next(false);
    this.questionsService.selectedFormat.next(null);
    this.questionsService.formulaSelected.next(false);
  }

  checkAnswerText(): boolean {
    let isExist = false;
    for (let i = 0; i < this.collection.fragments.length; i++) {
      if (this.collection.fragments[i].type === 'ANSWERTEXT') {
        isExist = true;
        break;
      } else {
        isExist = false;
      }
    }
    return isExist;
  }

  dropListSorted(event) {
    this.dragSortedIndex = event.currentIndex;
  }

  setDragItem(fragment) {
    this.selectedDragFragment = fragment;
  }

  checkEmpty(): boolean {
    if (!this.collection.fragments || this.collection.fragments.length === 0) {
      return true;
    }
    let empty = true;
    this.collection.fragments.forEach((f) => {
      if (f.fragment['data']) {
        empty = false;
      }
    });
    return empty;
  }

  checkFragmentValid(): boolean {
    let flag = false;
    this.collection.fragments.forEach((f) => {
      if (f.fragment['data']) {
        flag = true;
      }
    });
    if (!flag) {
      this.collection['checked'] = false;
    }
    return flag;
  }

  checkForComplete(collection): void {
    if (!this.checkFragmentValid()) {
      return;
    }
    collection.updated = true;
    collection.checked = !collection.checked;
    this.saveEvent.emit(this.collection);
  }

  changeExplanation(collection, event): void {
    if (event === 1) {
      if (collection.explLater && collection.noExpl) {
        collection.noExpl = false;
      }
    } else if (event === 2) {
      if (collection.explLater && collection.noExpl) {
        collection.explLater = false;
      }
    }
    this.saveEvent.emit(collection);
  }

  uploadVideo(fileInput: File, fragmentIndex = -1): void {
    const course = this.questionsService.selectedCourse.getValue();
    const uploading = this.collection.addVideoFile(
      fileInput,
      this.vimeoStorage,
      this.resourceService,
      course ? [course._id] : [],
      fragmentIndex,
      () => this.splitTextFragment(),
    );
    if (uploading) {
      uploading.pipe(untilDestroyed(this)).subscribe((res) => {
        if (res) {
          this.userService.isUploading.next(true);
        } else {
          this.userService.isUploading.next(false);
        }
      });
      if (uploading) {
        uploading.pipe(untilDestroyed(this)).subscribe((res) => {
          if (res) {
            this.userService.isUploading.next(true);
          } else {
            this.userService.isUploading.next(false);
          }
        });
      }
    }
  }

  showImageUploadDialog(): void {
    if (this.selectedTableIndexData) {
      this.showTableImageDialog = true;
      return;
    }
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      width: '80%',
      height: '80%',
      panelClass: 'upload-dialog-container',
      data: {
        title: this.translateService.instant('Upload Image'),
        libraries: [Libraries.COMPUTER, Libraries.CAMERA, Libraries.PENCIL_FILES],
        type: [ResourceItemModel.IMAGE],
      },
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(async (result: UploadedFile) => {
        if (!result) {
          return;
        }
        const selectedFragment = this.collection.fragments[this.selectedFragmentIndex];
        const isLastAndEmpty =
          !selectedFragment.fragment.data &&
          this.selectedFragmentIndex === this.collection.fragments.length - 1;
        const isLastFragment = this.selectedFragmentIndex === this.collection.fragments.length - 1;
        if (result.uploadMethod === UPLOAD_METHOD.PENCIL_LIBRARY) {
          for (const file of result.files as IResource[]) {
            this.collection.addImage(file.url, this.selectedFragmentIndex);
          }
          // Update uploaded resources with the course id, assign the collection course to each one of them.
          this.collection
            .addCourseToResource(
              result.files as IResource[],
              this.resourceService,
              this.questionsService.selectedCourse.getValue()?._id,
            )
            ?.pipe(untilDestroyed(this))
            .subscribe();
          if (this.editorCursorPosition > -1) {
            this.splitTextFragment(result.files.length);
          }
          if (
            isLastAndEmpty ||
            (isLastFragment &&
              selectedFragment.type === FragmentType.Text &&
              !selectedFragment.fragment.data?.substr(this.editorCursorPosition)?.trim())
          ) {
            this.collection.addTextFragment();
            if (isLastAndEmpty) {
              this.showFocus[this.selectedFragmentIndex + result.files.length] = true;
            } else {
              this.showFocus[this.selectedFragmentIndex + result.files.length + 1] = true;
            }
          }
        } else if (result.uploadMethod === UPLOAD_METHOD.COMPUTER) {
          let isFragmentAdded = false;
          for (const file of result.files as ComputerFile[]) {
            this.userService.isUploading.next(true);
            const course = this.questionsService.selectedCourse.getValue();
            this.collection.addFile(
              file.file,
              this.uploadService,
              this.resourceService,
              course ? [course?._id] : [],
              this.selectedFragmentIndex,
              () => {
                this.userService.isUploading.next(false);
                this.splitTextFragment();
                if (
                  (isLastAndEmpty ||
                    (isLastFragment &&
                      selectedFragment.type === FragmentType.Text &&
                      !selectedFragment.fragment.data
                        ?.substr(this.editorCursorPosition)
                        ?.trim())) &&
                  !isFragmentAdded
                ) {
                  isFragmentAdded = true;
                  this.collection.addTextFragment();
                  if (isLastAndEmpty) {
                    this.showFocus[this.selectedFragmentIndex + result.files.length] = true;
                  } else {
                    this.showFocus[this.selectedFragmentIndex + result.files.length + 1] = true;
                  }
                }
              },
            );
            this.fileName = file.file.name;
          }
          this.uploadType = 'image';
        }
      });
  }

  showVideoUploadDialog(): void {
    if (this.selectedTableIndexData) {
      return;
    }
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      width: '80%',
      height: '80%',
      panelClass: 'upload-dialog-container',
      data: {
        title: this.translateService.instant('Upload Video'),
        libraries: [Libraries.COMPUTER, Libraries.YOUTUBE, Libraries.VIMEO, Libraries.PENCIL_FILES],
        type: [ResourceItemModel.VIDEO],
      },
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(async (result: UploadedFile) => {
        if (!result) {
          return;
        }
        if (result.uploadMethod === UPLOAD_METHOD.COMPUTER) {
          this.uploadVideo((result.files as ComputerFile[])[0]?.file, this.selectedFragmentIndex);
          this.fileName = (result.files as ComputerFile[])[0]?.file?.name;
          this.uploadType = 'video';
        } else if (result.uploadMethod === UPLOAD_METHOD.PENCIL_LIBRARY) {
          this.collection.addVideo((result.files as IResource[])[0].url, this.toastrService);
          // Assign collection course to the uploaded video.
          this.collection
            .addCourseToResource(
              (result.files as IResource[])[0],
              this.resourceService,
              this.questionsService.selectedCourse.getValue()?._id,
            )
            ?.pipe(untilDestroyed(this))
            .subscribe();
        } else {
          this.collection.addVideo(
            (result.files as string[])[0],
            this.toastrService,
            this.selectedFragmentIndex,
            () => this.splitTextFragment(),
          );
        }
      });
  }

  showPdfUploadDialog(): void {
    if (this.selectedTableIndexData) {
      return;
    }
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      width: '80%',
      height: '80%',
      panelClass: 'upload-dialog-container',
      data: {
        title: this.translateService.instant('Upload PDF'),
        libraries: [Libraries.COMPUTER, Libraries.PENCIL_FILES],
        type: [ResourceItemModel.DOCUMENT],
      },
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(async (result: UploadedFile) => {
        if (!result) {
          return;
        }
        if (result.uploadMethod === UPLOAD_METHOD.COMPUTER) {
          const file = (result.files as ComputerFile[])[0].file;
          this.userService.isUploading.next(true);
          const course = this.questionsService.selectedCourse.getValue();
          this.collection.addFile(
            file,
            this.uploadService,
            this.resourceService,
            course ? [course._id] : [],
            this.selectedFragmentIndex,
            () => {
              this.userService.isUploading.next(false);
              this.splitTextFragment();
            },
            FragmentType.PDF,
          );
          this.fileName = file.name;
          this.uploadType = 'pdf';
        } else if (result.uploadMethod === UPLOAD_METHOD.PENCIL_LIBRARY) {
          this.collection.addPdf((result.files as IResource[])[0].url);
          // Assign collection course to the uploaded PDF.
          this.collection
            .addCourseToResource(
              (result.files as IResource[])[0],
              this.resourceService,
              this.questionsService.selectedCourse.getValue()?._id,
            )
            ?.pipe(untilDestroyed(this))
            .subscribe();
        }
      });
  }

  showPhetUploadDialog(): void {
    if (this.selectedTableIndexData) {
      return;
    }
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      width: '80%',
      height: '80%',
      panelClass: 'upload-dialog-container',
      data: {
        title: this.translateService.instant('Upload PHET'),
        libraries: [Libraries.COMPUTER, Libraries.PHET],
        type: [ResourceItemModel.SIMULATION],
      },
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(async (result: UploadedFile) => {
        if (!result) {
          return;
        }
        // TODO: Add a corresponding document for new uploaded PHET.
        if (result.uploadMethod === UPLOAD_METHOD.COMPUTER) {
          this.fileName = (result.files as ComputerFile[])[0].file.name;
          this.uploadType = 'phet';
        } else if (result.uploadMethod === UPLOAD_METHOD.PENCIL_LIBRARY) {
          this.collection.addPhet((result.files as IResource[])[0]?.url);
          // Assign collection course to the uploaded Phet.
          this.collection
            .addCourseToResource(
              (result.files as IResource[])[0],
              this.resourceService,
              this.questionsService.selectedCourse.getValue()?._id,
            )
            ?.pipe(untilDestroyed(this))
            .subscribe();
        } else {
          this.collection.addPhet((result.files as string[])[0], this.selectedFragmentIndex, () =>
            this.splitTextFragment(),
          );
        }
      });
  }

  @HostListener('document:click', ['$event.target'])
  onMouseEnter(targetElement: HTMLDivElement): void {
    if (this.editor) {
      const clickedInside = this.editor.nativeElement.contains(targetElement);
      if (
        !clickedInside &&
        (!targetElement.className ||
          typeof targetElement.className !== 'string' ||
          !targetElement.className.includes('add-fragment-button'))
      ) {
        this.showControls = false;
        this.showToolBox.emit(false);
      }
    }
  }

  cancelUpload(): void {
    this.collection.cancelUpload();
    this.userService.isUploading.next(false);
  }

  openKatexDialog(katexData = null, index = -1): void {
    if (this.selectedTableIndexData) {
      this.showTableFormulaDialog = true;
      return;
    }
    this.questionsService.isKatexDialogOpened.next(true);
    const dialogRef = this.dialog.open(MyscriptComponent, {
      width: '800px',
      panelClass: 'math-quill-dialog-container',
      data: { katex: katexData },
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.questionsService.isKatexDialogOpened.next(false);
        if (result && this.selectedFragmentIndex > -1) {
          const fragments = this.collection.fragments;
          if (
            fragments[this.selectedFragmentIndex] &&
            fragments[this.selectedFragmentIndex].fragment
          ) {
            this.collection.updated = true;
            const segments = extractMath(
              fragments[this.selectedFragmentIndex].fragment?.data || '',
            );
            if (index > -1) {
              segments[index].value = result;
              let str = '';
              segments.forEach((s) => {
                str += s.math ? `$$${s.value.trim()}$$` : s.value;
              });
              fragments[this.selectedFragmentIndex].fragment.data = str;
            } else {
              // need to calculate latex counts because we should focus after adding a latex
              // quill editor considers latex length as 1
              let latexCounts = 0;
              if (fragments[this.selectedFragmentIndex].fragment.data) {
                let segmentsLen = 0;
                let latexLen = 0;
                segments.forEach((se) => {
                  if (segmentsLen < this.editorCursorPosition) {
                    if (se.math) {
                      latexCounts++;
                      segmentsLen += 1;
                      latexLen += se.value.length;
                    } else {
                      segmentsLen += se.value.length;
                    }
                  }
                });
                const selectedFragment = fragments[this.selectedFragmentIndex];
                // we need to add 2*latexCounts-1 since one latex has one following empty space
                const cursorPosition =
                  this.editorCursorPosition + 2 * latexCounts - (latexCounts > 0 ? 1 : 0);
                selectedFragment.fragment.data = `${selectedFragment.fragment.data.slice(
                  0,
                  cursorPosition,
                )} $$${result.trim()}$$ ${selectedFragment.fragment.data.slice(cursorPosition)}`;
                this.focusPosition = this.editorCursorPosition - latexLen - latexCounts * 2 + 3;
              } else {
                fragments[this.selectedFragmentIndex].fragment.data = `$$${result.trim()}$$`;
              }
            }
            this.selected = false;
            this.collection.fragments = cloneDeep(fragments);
            this.saveEvent.emit(this.collection);
            this.changeDetectorRef.markForCheck();
            this.showFocus[this.selectedFragmentIndex] = true;
          }
        }
      });
  }

  addTextFragment(texts: string[], index: number): void {
    if (this.disableNewFragment) {
      return;
    }
    this.showFocus = [];
    texts.forEach((t, i) => {
      this.collection.addTextFragment(false, t, index + i);
    });
    requestAnimationFrame(() => {
      const fragments = this.collection.fragments;
      this.collection.fragments = cloneDeep(fragments);
      this.focusPosition = 0;
      this.showFocus[index + texts.length] = true;
      this.selectedFragmentIndex++;
    });
  }

  splitTextFragment(fragmentsCount = 1): void {
    if (!this.collection.fragments[this.selectedFragmentIndex]) {
      return;
    }
    const selectedFragmentText =
      this.collection.fragments[this.selectedFragmentIndex].fragment.data;
    if (
      !selectedFragmentText ||
      this.collection.fragments[this.selectedFragmentIndex].type !== FragmentType.Text
    ) {
      return;
    }
    if (selectedFragmentText.substring(this.editorCursorPosition)) {
      this.collection.addTextFragment(
        false,
        selectedFragmentText.substring(this.editorCursorPosition),
        this.selectedFragmentIndex + fragmentsCount,
      );
    }
    const fragments = this.collection.fragments;
    fragments[this.selectedFragmentIndex].fragment.data = selectedFragmentText.substring(
      0,
      this.editorCursorPosition,
    );
    this.collection.fragments = cloneDeep(fragments);
  }

  removeTextFragment(event: string, index: number): void {
    this.showFocus = [];
    if (
      index > 0 &&
      this.collection.fragments[index - 1].type === 'TEXT' &&
      event === 'Backspace'
    ) {
      const fragments = cloneDeep(this.collection.fragments);
      this.focusPosition = (fragments[index - 1].fragment.data || '').length;
      fragments[index - 1].fragment['data'] = `${fragments[index - 1].fragment.data || ''} ${
        fragments[index].fragment.data || ''
      }`;
      fragments.splice(index, 1);
      this.collection.fragments = fragments;
      this.showFocus[index - 1] = true;
      this.selectedFragmentIndex--;
      this.changeDetectorRef.markForCheck();
    } else if (this.collection.fragments[index + 1]?.type === 'TEXT' && event === 'Delete') {
      const fragments = cloneDeep(this.collection.fragments);
      this.focusPosition = (fragments[index].fragment.data || '').length;
      fragments[index].fragment['data'] = `${fragments[index].fragment.data || ''} ${
        fragments[index + 1].fragment.data || ''
      }`;
      fragments.splice(index + 1, 1);
      this.collection.fragments = fragments;
      this.showFocus[index] = true;
      this.selectedFragmentIndex--;
      this.changeDetectorRef.markForCheck();
    }
  }

  addFragment(event: string): void {
    switch (event) {
      case 'TEXT':
        this.collection.addTextFragment();
        this.selectedFragmentIndex = this.collection.fragments.length - 1;
        this.showFocus = [];
        this.showFocus[this.selectedFragmentIndex] = true;
        this.focusPosition = 0;
        break;
      case 'RESPONSE':
        this.collection['answerFormat'] = 'Lined';
        this.collection['lines'] = 4;
        this.collection.addAnswerTextFragment();
        this.selectedFragmentIndex = this.collection.fragments.length - 1;
        this.saveChanges();
        break;
      case 'POINTS':
        this.collection.marks = 1;
        this.saveChanges();
        break;
      default:
        this.addExplanation.emit({
          collection: this.collection,
          type: event,
        });
        break;
    }
  }

  getResponseBoxIndex(fragments: TypedFragment[]): number {
    return fragments.findIndex((frag) => frag.type === FragmentType.AnswerText);
  }

  handleFocus(index, isResource = false, tableIndexData: TableIndexData | null = null): void {
    if (tableIndexData) {
      this.selectedTableIndexData = tableIndexData;
    } else {
      this.selectedTableIndexData = null;
    }
    this.selectedFragmentIndex = index;
    this.questionsService.focusedCollection.next(this.collection);
    this.questionsService.enableListFormat.next(this.enableList);
    this.focused.emit(this.collection);
    this.spaceSharedDataService.isDisableKeyEvents = true;

    if (isResource) {
      requestAnimationFrame(() => {
        this.questionsService.selectedFormat.next({ resource: 'true' });
      });
    } else {
      this.questionsService.selectedFormat.next(null);
    }
    this.changeDetectorRef.detectChanges();
  }

  handleBlur(index): void {
    if (
      this.selectedFragmentIndex === index &&
      isEqual(this.questionsService.focusedCollection.value, this.collection)
    ) {
      this.blurred.emit(this.collection);
      this.spaceSharedDataService.isDisableKeyEvents = false;
    }
  }

  handleAdd(value: string) {
    if (value === 'SUB' || value === 'OPTION') {
      this.add.emit(this.collection);
    } else {
      this.addFragment(value);
    }
  }

  textFragmentDragPreview(typedFragment: TypedFragment): TypedFragment | null {
    return truncateTextFragment(typedFragment);
  }

  @HostListener('window:click', ['$event'])
  onClick(event: Event): void {
    const eventPath = event.composedPath();
    if (eventPath && eventPath.length > 0) {
      const eventTarget = eventPath[0] as HTMLElement;

      if (
        typeof eventTarget.className === 'string' &&
        (eventTarget.className?.includes('fragment-content') ||
          eventTarget.className?.includes('cdk-drag'))
      ) {
        this.blurred.emit(this.collection);
        this.questionsService.selectedFormat.next(null);
      }
    }
  }

  backspaceToRemoveFragment(focusOnIndex: number): void {
    if (focusOnIndex < 0) {
      return;
    }

    this.showFocus = [];

    const fragments = [...this.collection.fragments];
    fragments.splice(focusOnIndex + 1, 1);
    this.collection.fragments = fragments;

    // const textFragments = fragments.filter(f => f.type === FragmentType.Text);
    for (let i = focusOnIndex; i >= 0; i--) {
      const frag = fragments[i];
      if (frag.type === FragmentType.Text) {
        this.showFocus[i] = true;
        this.focusPosition = frag.fragment.data ? frag.fragment.data.length : 0;
        break;
      }
    }
  }

  handleArrowKeysPress({ key, position }: { key: string; position: number }, index: number): void {
    let newFocusIndex;
    if (key === 'ArrowUp') {
      if (index === 0) {
        return;
      }

      for (let i = index - 1; i >= 0; i--) {
        const frag = this.collection.fragments[i];
        if (frag.type === FragmentType.Text) {
          newFocusIndex = i;
          break;
        }
      }
    }

    if (key === 'ArrowDown') {
      if (index === this.collection.fragments.length - 1) {
        return;
      }

      for (let i = index + 1; i < this.collection.fragments.length; i++) {
        const frag = this.collection.fragments[i];
        if (frag.type === FragmentType.Text) {
          newFocusIndex = i;
          break;
        }
      }
    }

    this.showFocus = [];
    this.showFocus[newFocusIndex] = true;
    this.focusPosition = position;
  }
}

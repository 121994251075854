import { convertToParamMap, ParamMap, Params } from '@angular/router';

export const URL_CONSTANTS = {
  SPACES: 'spaces',
  HOME: 'content',
  ACCOUNT: 'account',
  VERIFY: 'account/verify',
  LOGIN: 'account/login',
  REQUEST_ACCESS: 'request-access',
  WAITING_ROOM: 'waiting-room',
};

export const LANDING_URL = URL_CONSTANTS.SPACES;

export const appendUTMParams = (url: URL, params: ParamMap): URL => {
  params.keys
    .filter((key) => key.startsWith('utm_'))
    .forEach((key) => {
      url.searchParams.append(key, params.get(key) ?? '');
    });
  return url;
};

export const appendReferralParams = (url: URL, params: ParamMap): URL => {
  params.keys
    .filter((key) => key.startsWith('referral_'))
    .forEach((key) => {
      url.searchParams.append(key, params.get(key) ?? '');
    });
  return url;
};

export const extractUTMParams = (params: ParamMap): Record<string, string> => {
  const urlParams: Record<string, string> = {};
  params.keys
    .filter((key) => key.startsWith('utm_'))
    .forEach((key) => {
      urlParams[key] = params.get(key) ?? '';
    });
  return urlParams;
};

export const extractReferralParams = (params: ParamMap): Record<string, string> => {
  const urlParams: Record<string, string> = {};
  params.keys
    .filter((key) => key.startsWith('referral_'))
    .forEach((key) => {
      urlParams[key] = params.get(key) ?? '';
    });
  return urlParams;
};

export const extractTemplateIdParam = (params: ParamMap): Record<string, string> => {
  const urlParams: Record<string, string> = {};
  params.keys
    .filter((key) => key.startsWith('templateid'))
    .forEach((key) => {
      urlParams[key] = params.get(key) ?? '';
    });
  return urlParams;
};

export const queryStringToParamMap = (queryString: string): ParamMap => {
  const paramList = queryString.slice(1).split('&');
  const params: Params = {};
  paramList.forEach((param) => {
    const paramPair = param.split('=');
    params[paramPair[0]] = paramPair[1];
  });
  return convertToParamMap(params);
};

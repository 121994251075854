<app-profile-photo
  [user]="user"
  width="48"
  fontSize="18"
  [isClickEnabled]="false"
></app-profile-photo>
<div *ngIf="user" class="details" [ngClass]="{ compact: compact }">
  <div class="info">
    <div class="name">{{ user.name }}</div>
    <div class="other">
      <div class="profile-roles" *ngIf="user.personas?.length > 0">
        <app-role-chip [user]="user"></app-role-chip>
      </div>
      <div *ngIf="user.isApiManaged" class="isApiManaged-chip">
        <app-pill
          [text]="'Managed via API'"
          [type]="'user'"
          [color]="'#ffffff'"
          [svgIcon]="'api'"
          [showXButton]="false"
        ></app-pill>
      </div>
      <span *ngIf="!user.isApiManaged" class="profile-email">{{ user.email }}</span>
    </div>
  </div>
  <div
    class="suspended-tag"
    *ngIf="user && !compact && user.revoked && user.revoked.type === REVOKED_TYPES.SUSPENDED"
  >
    <i class="material-icons">error</i>
    {{ 'Suspended' | translate }}
  </div>
</div>

import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class DeviceAndBrowserDetectorService {
  constructor(private deviceDetectorService: DeviceDetectorService, private platform: Platform) {}

  get browser(): string {
    return this.deviceDetectorService.browser;
  }

  isMobile(): boolean {
    return this.deviceDetectorService.isMobile() && !this.isTabletDevice();
  }

  // Browsers detection
  isFirefox(): boolean {
    return !!navigator.userAgent.match('firefox|fxios|Firefox|Fxios/i');
  }

  isChrome(): boolean {
    return (
      window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1 ||
      /CriOS/i.test(navigator.userAgent)
    );
  }

  isEdge(): boolean {
    return window.navigator.userAgent.toLowerCase().indexOf('edg') > -1;
  }

  isOpera(): boolean {
    return window.navigator.userAgent.toLowerCase().indexOf('OP') > -1;
  }

  isSafari(): boolean {
    // All below checks are added to detect real Safari as user agent contains Safari for all browsers
    return (
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
      !/CriOS/i.test(navigator.userAgent) &&
      !/firefox|fxios|Firefox|Fxios/i.test(navigator.userAgent) &&
      !/EdgiOS/i.test(navigator.userAgent)
    );
  }

  // Device & OS detection
  isDesktop(): boolean {
    return this.deviceDetectorService.isDesktop() && !this.isTabletDevice();
  }

  isiOSiPhone(): boolean {
    return this.platform.IOS && this.deviceDetectorService.isMobile();
  }

  isiOSiPad(): boolean {
    return (
      (this.platform.IOS ||
        // iPad on > iOS 13 detection
        this.isIPadWithIOS13OrMore()) &&
      (this.deviceDetectorService.isTablet() || this.isTabletDevice())
    );
  }

  isAndroidPhone(): boolean {
    return this.platform.ANDROID && this.deviceDetectorService.isMobile();
  }

  isAndroidTablet(): boolean {
    return (
      this.platform.ANDROID && (this.deviceDetectorService.isTablet() || this.isTabletDevice())
    );
  }

  isiOS(): boolean {
    return this.platform.IOS || this.isIPadWithIOS13OrMore();
  }

  isAndroid(): boolean {
    return this.platform.ANDROID;
  }

  isWindows(): boolean {
    return !!navigator.userAgent.match(/(Windows)/i);
  }

  isMacOS(): boolean {
    return !!navigator.userAgent.match(/(Mac)/i) && this.isDesktop();
  }

  isTabletDevice(): boolean {
    const regex =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
    return (
      regex.test(navigator.userAgent.toLowerCase()) ||
      // iPad on > iOS 13 detection
      this.isIPadWithIOS13OrMore()
    );
  }

  private isIPadWithIOS13OrMore(): boolean {
    return (
      navigator.userAgent.includes('Macintosh') &&
      // Filter non-touch devices (Mac)
      'ontouchend' in document
    );
  }

  // ---- Browser Feature Support ----

  public supportElementCapture() {
    return window.RestrictionTarget !== undefined;
  }

  public supportCaptureHandleConfig() {
    return navigator.mediaDevices.setCaptureHandleConfig !== undefined;
  }
}

/**
 * Note:
 * Types for some of the newer chrome APIs are not included in the standard @types/chrome-types
 * so we have to add them here. Ensure that any extension of existing browser apis are all made optional
 * so that the callee must check to see if they exist before using them.
 */
declare global {
  interface Window {
    RestrictionTarget?: {
      fromElement: (element: HTMLElement) => Promise<RestrictionTarget>;
    };
  }

  interface MediaStreamTrack {
    getCaptureHandle?: () => { handle: string } | null;
    restrictTo?: (target: RestrictionTarget | null) => Promise<void>;
    oncapturehandlechange?: () => void;
  }

  interface RestrictionTarget {}

  interface MediaDevices {
    setCaptureHandleConfig?: (config: { handle: string; permittedOrigins: string[] }) => void;
  }
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// Used for asynchronous http request handling
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';

// Required to set custom headers and make http requests
import { HttpHeaders, HttpClient } from '@angular/common/http';

import * as tus from 'tus-js-client';
import { catchError, first, map, retry, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { filterNil } from '@ngneat/elf';
import { InterceptorSkipHeader } from '../auth.interceptor';
import { detectVimeo } from '../utilities/video.utils';
import { URLService } from './dynamic-url.service';

// Maximum allowed size upload - 6000 MBs
const MAX_FILE_SIZE_ALLOWED_MB = 6000;

enum VimeoVideoStatus {
  AVAILABLE = 'available',
  QUOTA_EXCEEDED = 'quota_exceeded',
  TOTAL_CAP_EXCEEDED = 'total_cap_exceeded',
  TRANSCODE_STARTING = 'transcode_starting',
  TRANSCODING = 'transcoding',
  TRANSCODING_ERROR = 'transcoding_error',
  UNAVAILABLE = 'unavailable',
  UPLOADING = 'uploading',
  UPLOADING_ERROR = 'uploading_error',
}

@Injectable({
  providedIn: 'root',
})
export class VimeoUploadService {
  private api = 'https://api.vimeo.com/me/videos';
  private _accessToken$ = new BehaviorSubject<{ token: string } | undefined>(undefined);

  constructor(
    private http: HttpClient,
    private urlService: URLService,
    private toastrService: ToastrService,
    private translate: TranslateService,
  ) {
    this.fetchAccessToken$.pipe(retry(3)).subscribe((accessToken) => {
      this._accessToken$.next(accessToken);
    });
  }

  public isVideoAvailable(url: URL): Observable<boolean> {
    const id = detectVimeo(url);

    if (!id) {
      return of(false);
    }

    return this.getVimeoHeaders$.pipe(
      switchMap((headers) =>
        this.http
          .get<{ status: VimeoVideoStatus }>(`${this.api}/${id}?fields=status`, { headers })
          .pipe(
            map((video) => video.status === VimeoVideoStatus.AVAILABLE),
            catchError((err) => {
              // Vimeo API return 404 if the video is not owned by the user,
              // which means that it wasn't just uploaded by the user, so it must be available
              if (err?.status === 404 && err?.error?.error_code === 5001) {
                return of(true);
              }
              throw err;
            }),
          ),
      ),
    );
  }

  createVideo(file: File): Observable<any> {
    const body = {
      name: file.name,
      upload: {
        approach: 'tus',
        size: file.size,
      },
    };

    return this.getVimeoHeaders$.pipe(
      switchMap((header) => {
        header.set('Accept', 'application/vnd.vimeo.*+json;version=3.4');
        return this.http.post(this.api, body, {
          headers: header,
          observe: 'response',
        });
      }),
    );
  }

  public tusUpload(
    file: File,
    fileURI: string,
    progress: (value: number) => void,
    success: () => void,
    errorCb?: (e: Error) => any,
  ): tus.Upload | undefined {
    if (file.size > MAX_FILE_SIZE_ALLOWED_MB * 1024 * 1024) {
      this.toastrService.error(
        `${this.translate.instant(
          'File exceeds maximum upload limit',
        )} ${MAX_FILE_SIZE_ALLOWED_MB} MBs`,
      );
      if (errorCb) {
        errorCb(new Error(this.translate.instant('File exceeds maximum upload limit')));
      }
      return undefined;
    }

    const upload = new tus.Upload(file, {
      uploadUrl: fileURI,
      endpoint: fileURI,
      retryDelays: [0, 1000, 3000, 5000],
      chunkSize: 1000000,
      onError: (error) => {
        if (errorCb) {
          errorCb(error);
        } else {
          this.toastrService.error(`${this.translate.instant('Failed')}: ${file.name}${error}`);
        }
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        const percentage = Number.parseFloat(((bytesUploaded / bytesTotal) * 100).toFixed(2));
        progress(percentage);
      },
      onSuccess: () => {
        success();
      },
    });
    return upload;
  }

  tusUploadPromise(
    file: File,
    fileURI: string,
  ): {
    progress: Observable<number>;
    uploadTask?: tus.Upload;
    uploadCompleted: Promise<void>;
  } {
    const subject = new Subject<number>();
    // @todo fix this any type
    let uploadTask: tus.Upload | undefined;

    const promise = new Promise<void>((resolve, reject) => {
      uploadTask = this.tusUpload(
        file,
        fileURI,
        (value: number) => subject.next(value),
        () => resolve(),
        (error: Error) => reject(error),
      );
    });

    return { progress: subject.asObservable(), uploadTask, uploadCompleted: promise };
  }

  private fetchAccessToken$: Observable<{ token: string }> = this.http.get<any>(
    `${this.urlService.getDynamicUrl()}/tutor/vat`,
  );

  private getVimeoHeaders$: Observable<HttpHeaders> = this._accessToken$.pipe(
    filterNil(),
    first(),
    map(({ token }) =>
      new HttpHeaders()
        .set('Authorization', `bearer ${token}`)
        .set(InterceptorSkipHeader, '')
        .set('Content-Type', 'application/json'),
    ),
  );
}

import { ItemModel, MarioResource } from '../../../models/session';
import { ResourceItemModel } from '../../../models/resource';
import { FragmentType } from '../../../common/typed-fragment/typed-fragment';

export const IFRAME_APP_DEFAULT_WIDTH = 1280;
export const IFRAME_APP_DEFAULT_HEIGHT = 720;

export const INTERACTIONS_BAR_HEIGHT = 46;
export const INTERACTIONS_BAR_PADDING = 1;

export const WEB_VIEWER_PADDING = 4;

// those limits are currently applied to iframes, web viewer and chat-GPT
export const APPS_MIN_WIDTH = 480;
export const APPS_MIN_HEIGHT = 480;
export const APPS_MAX_WIDTH = 1920;
export const APPS_MAX_HEIGHT = 1080;

export const CHAT_GPT_APP_DEFAULT_WIDTH = 549;
export const CHAT_GPT_APP_DEFAULT_HEIGHT = 620;

export const MARIO_DEFAULT_WIDTH = 700;

export const MARIO_DEFAULT_HEIGHT =
  394 /* maintain aspect ratio for height = 700 */ + INTERACTIONS_BAR_HEIGHT;

export const REMOTE_VIDEO_DEFAULT_WIDTH = 1280;
export const REMOTE_VIDEO_DEFAULT_HEIGHT = 720;

export const VIDEO_DEFAULT_WIDTH = 1280;
export const VIDEO_DEFAULT_HEIGHT = 720;
export const VIDEO_MINIMUM_HEIGHT = 480;
export const VIDEO_MAXIMUM_HEIGHT = 1080;
export const VIDEO_ASPECT_RATIO = VIDEO_DEFAULT_WIDTH / VIDEO_DEFAULT_HEIGHT;

export const DOCUMENTS_DEFAULT_WIDTH = 800;
export const DOCUMENTS_DEFAULT_HEIGHT = 600;

export const OTHER_ITEMS_DEFAULT_WIDTH = 700;
export const OTHER_ITEMS_DEFAULT_HEIGHT = 700;

export const IMAGES_DEFAULT_WIDTH = 600;
export const IMAGES_DEFAULT_HEIGHT = 600;

export const FORMULAS_DEFAULT_HEIGHT = 200;
export const FORMULAS_DEFAULT_WIDTH = 200;

// Apps that participants can not drag and resize (on mobile or desktop)
export const lockedForParticipantsApps = [
  ResourceItemModel.MARIO,
  ResourceItemModel.WEB_VIEWER,
  ResourceItemModel.IFRAME,
  ResourceItemModel.CHAT_GPT,
  FragmentType.Chat_GPT, // because it differs from the previous one in capitalization
];

/**
 * item types that should cut off on a specific zooming level
 */
export const isOverlaidAppOnCertainZoomLevel = (type: undefined | ItemModel | ResourceItemModel) =>
  [
    ItemModel.Chat_GPT,
    ResourceItemModel.CHAT_GPT,
    ItemModel.IFrame,
    ResourceItemModel.IFRAME,
    ItemModel.WebViewer,
    ResourceItemModel.WEB_VIEWER,
    ItemModel.Mario,
    ResourceItemModel.MARIO,
  ].includes(type as any);

export const isApp = (type: undefined | ItemModel) => {
  if (!type) {
    return false;
  }
  return [ItemModel.Mario, ItemModel.Chat_GPT, ItemModel.WebViewer, ItemModel.IFrame].includes(
    type,
  );
};

export enum AppSections {
  CORE = 'Core',
  PRODUCTIVITY = 'Productivity',
  AI = 'AI-Enabled Apps',
  EDUCATION = 'General Learning Tools',
  MATH = 'Numeracy and Math Tools',
  LITERACY = 'Literacy and Language Tools',
  SCIENCE = 'Science Tools',
  MUSIC = 'Music Tools',
  LMS = 'Learning Portals - Account Required',
  DESIGN = 'Design',
  GAMES = 'Games',
  ADVANCED = 'Advanced',
  APPS_ONLY_VISIBLE_TO_YOU = 'Apps only visible to you',
  REQUEST_AN_APP = 'Request an app',
  HIDDEN_APPS = 'Hidden Apps',
}

export enum CollaborativeApps {
  // Core apps
  COMMENTS = 'COMMENTS',
  ANALYTICS = 'ANALYTICS',
  BREAKOUT_ROOMS = 'BREAKOUT_ROOMS',
  RESOURCE_LIBRARY = 'RESOURCE_LIBRARY',

  // Google apps
  GOOGLE_DOCS = 'GOOGLE_DOCS',
  GOOGLE_SHEETS = 'GOOGLE_SHEETS',
  GOOGLE_SLIDES = 'GOOGLE_SLIDES',
  GOOGLE_FORMS = 'GOOGLE_FORMS',
  GOOGLE_DRIVE = 'GOOGLE_DRIVE',

  // Microsoft apps
  ONEDRIVE = 'ONEDRIVE',

  // IFrame apps
  IFRAME = 'IFRAME',

  AVALON = 'AVALON',
  CODE_WORDS = 'CODE_WORDS',
  COLONIST = 'COLONIST',
  DOWN_FOR_A_CROSS = 'DOWN_FOR_A_CROSS',
  DRAW_BATTLE = 'DRAW_BATTLE',
  LI_POKER = 'LI_POKER',
  SCATTEGORIES = 'SCATTEGORIES',
  SKRIBBLE_IO = 'SKRIBBLE_IO',
  TOP_FOUR = 'TOP_FOUR',
  WEREWOLF = 'WEREWOLF',
  WORDLE = 'WORDLE',

  // Mario apps
  POLLS = 'ia-polls',
  STOPWATCH = 'ia-stopwatch',
  TIMER = 'ia-timer',
  ERASER = 'ia-eraser',

  // Webviewer bookmarks
  NEARPOD = 'NEARPOD',
  QUIZLET = 'QUIZLET',
  QUIZIZZ = 'QUIZIZZ',
  MARIO = 'MARIO',
  WEBVIEWER = 'WEBVIEWER',

  // AI Enabled Apps
  CHAT_GPT = 'CHAT_GPT',
  PENCIL_AI = 'PENCIL_AI',
  PENCIL_TEMPLATE = 'PENCIL_TEMPLATE',
  PENCIL_AI_ASSISTANT = 'PENCIL_AI_ASSISTANT',

  // LMS
  ABC_READING_EGGS = 'ABC_READING_EGGS',
  ACTIVELEARN = 'ACTIVELEARN',
  BLACKBOARD = 'BLACKBOARD',
  CANVAS_LMS = 'CANVAS_LMS',
  GOOGLE_CLASSROOM_LMS = 'GOOGLE_CLASSROOM_LMS',
  IREADY = 'IREADY',
  IXL = 'IXL',
  OFF2CLASS = 'OFF2CLASS',
  MATHIA = 'MATHIA',
  MYMATHS = 'MYMATHS',
  XCELLATE = 'XCELLATE',

  // Math tools
  CLASSPAD = 'CLASSPAD',
  DESMOS_GRAPHING_CALCULATOR = 'DESMOS_GRAPHING_CALCULATOR',
  DESMOS_GEOMETRY = 'DESMOS_GEOMETRY',
  DESMOS_SCIENTIFIC_CALCULATOR = 'DESMOS_SCIENTIFIC_CALCULATOR',
  DICE = 'DICE',
  FRACTIONS = 'FRACTIONS',
  MATHIGON = 'MATHIGON',
  MATHWAY = 'MATHWAY',
  NUMWORKS = 'NUMWORKS',
  REKNEREK = 'REKNEREK',
  SPINNER = 'SPINNER',
  TEN_FRAMES = 'TEN_FRAMES',
  WOLFRAM_ALPHA = 'WOLFRAM_ALPHA',
  MORE_APPS = 'MORE_APPS',

  // General Apps
  BBC_BITESIZE = 'BBC_BITESIZE',
  CURIPOD = 'CURIPOD',
  GOOGLE_MAPS = 'GOOGLE_MAPS',
  SCRATCH = 'SCRATCH',
  WIKIPEDIA = 'WIKIPEDIA',
  YOUTUBE = 'YOUTUBE',
  AI_YOUTUBE = 'AI_YOUTUBE',

  // Science Apps
  HUMAN_ANATOMICAL_MODELS = 'HUMAN_ANATOMICAL_MODELS',
  BIODIGITAL = 'BIODIGITAL',
  CHEMIX = 'CHEMIX',
  PHET_SIMULATION = 'PHET_SIMULATION',
  PERIODIC_TABLE = 'PERIODIC_TABLE',
  AI_PHET_SIMULATION = 'AI_PHET_SIMULATION',

  // Literacy and Languages
  UFLI_BLENDING_BOARD = 'UFLI_BLENDING_BOARD',
  UFLI_WORD_WORK_MAT = 'UFLI_WORD_WORK_MAT',
  WORD_REFERENCE = 'WORD_REFERENCE',
  WIKTIONARY = 'WIKTIONARY',
  DICTIONARY = 'DICTIONARY',
  INTERVENTION_CARDS = 'INTERVENTION_CARDS',
  THESAURUS = 'THESAURUS',
  MINDFLOW = 'MINDFLOW',

  // Music Apps
  PIANO = 'PIANO',
  SONG_MAKER = 'SONG_MAKER',
  SOUNDTRAP = 'SOUNDTRAP',
  SPROUTBEAT = 'SPOUTBEAT',
  TRANSITIONSDJ = 'TRANSITIONSDJ',
  XYLOPHONE = 'XYLOPHONE',

  // Design
  FORMIT = 'FORMIT',
  SKETCHUP = 'SKETCHUP',

  // Advanced
  ONEDRIVE_PPT = 'ONEDRIVE_PPT',
}

export enum AppInstanceType {
  IFRAME = 'IFRAME',
  WEBVIEWER = 'WEBVIEWER',
  MARIO = 'MARIO',
  GOOGLE_DRIVE = 'GOOGLE_DRIVE',
  ONEDRIVE = 'ONEDRIVE',
  ONEDRIVE_PPT = 'ONEDRIVE_PPT',
  CHAT_GPT = 'CHAT_GPT',
  PENCIL_AI = 'PENCIL_AI',
  PENCIL_AI_ASSISTANT = 'PENCIL_AI_ASSISTANT',
}

export const PremiumCollaborativeApps: { [key: string]: { exceptions: CollaborativeApps[] } } = {
  [AppInstanceType.WEBVIEWER]: {
    exceptions: [],
  },
  [AppInstanceType.ONEDRIVE_PPT]: {
    exceptions: [],
  },
};

export interface IAppsSelectorInformation {
  icon: string;
  disabledIcon?: string;
  title: string;
  description: string;
  section: string;
  isLibraryAppParent?: boolean;
}

export interface IAppInformation {
  icon: string;
  title: string;
  instanceType: string;
  flag?: string;
  defaultUrl?: string;
  width?: string;
  height?: string;
  mario_resource?: MarioResource;
  shareablePin?: boolean;
  sharedUrl?: string;
  cursorsHidden?: boolean;
  webgl?: boolean;
  permissionName?: string;
  forceAvailableOnMobile?: boolean;
  allowFullscreen?: boolean;
  initialZoom?: number;
  country?: string;
  adblock?: boolean;
}

export interface IAppModalInformation {
  title: string;
  icon?: string;
  description?: string;
  stringIncludesValidation?: string;
  errorMessage?: string;
  buttonTitle?: string;
  queryParams?: Map<string, string>;
}

export interface IAppsConfiguration {
  modal?: IAppModalInformation; // modal is optional for webviewer and applicable to iframe
  app: IAppInformation;
  selector: IAppsSelectorInformation;
}

export class IAppConfiguration implements IAppsConfiguration {
  modal?: IAppModalInformation;
  app: IAppInformation;
  selector: IAppsSelectorInformation;

  constructor(
    app: IAppInformation,
    selector: IAppsSelectorInformation,
    modal: IAppModalInformation | null = null,
  ) {
    this.app = app;
    this.selector = selector;
    if (modal) {
      this.modal = modal;
    }
  }
}

export const GptAppsConfiguration = new IAppConfiguration(
  {
    icon: 'app_chat_gpt',
    title: 'Chat GPT',
    width: `${CHAT_GPT_APP_DEFAULT_WIDTH}`,
    height: `${CHAT_GPT_APP_DEFAULT_HEIGHT}`,
    instanceType: AppInstanceType.CHAT_GPT,
    flag: 'chat_gpt',
  },
  {
    icon: 'app_chat_gpt',
    title: 'Chat with GPT',
    description: 'Ask AI anything, together!',
    section: AppSections.AI,
  },
);

export const AppsConfiguration: { [K in CollaborativeApps as string]: IAppConfiguration } = {
  [CollaborativeApps.IFRAME]: new IAppConfiguration(
    {
      icon: 'code_iframe',
      title: 'iFrame',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.IFRAME,
      flag: 'enable_iframe',
    },
    {
      icon: 'code_iframe',
      title: 'Import an iFrame',
      description: 'Import an iFrame into Spaces',
      section: AppSections.ADVANCED,
    },
    { title: 'Add iFrame', buttonTitle: 'Add iFrame' },
  ),
  [CollaborativeApps.MARIO]: new IAppConfiguration(
    {
      icon: 'code_iframe',
      title: 'Mario App',
      instanceType: AppInstanceType.MARIO,
      flag: 'enable_mario',
    },
    {
      icon: 'code_iframe',
      title: 'Import a Mario App',
      description: 'Import a Mario App into Spaces',
      section: AppSections.ADVANCED,
    },
    { title: 'Add Mario App', buttonTitle: 'Add Mario App' },
  ),
  [CollaborativeApps.ONEDRIVE]: new IAppConfiguration(
    {
      title: 'OneDrive',
      icon: 'apps_onedrive',
      instanceType: AppInstanceType.ONEDRIVE,
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      flag: 'enable_onedrive_import',
    },
    {
      icon: 'apps_onedrive',
      title: 'OneDrive',
      description: 'Import from Microsoft OneDrive',
      section: AppSections.PRODUCTIVITY,
    },
  ),
  [CollaborativeApps.GOOGLE_DRIVE]: new IAppConfiguration(
    {
      title: 'Google Drive',
      icon: 'google_drive',
      instanceType: AppInstanceType.GOOGLE_DRIVE,
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      flag: 'enable_gdrive_import',
    },
    {
      icon: 'google_drive',
      title: 'Google Drive',
      description: 'Import from Google Drive',
      section: AppSections.PRODUCTIVITY,
    },
  ),
  [CollaborativeApps.GOOGLE_DOCS]: new IAppConfiguration(
    {
      title: 'Google Docs',
      instanceType: AppInstanceType.IFRAME,
      icon: 'apps_google_docs',
      width: '1000',
      height: '1000',
    },
    {
      icon: 'apps_google_docs',
      title: 'Google Docs',
      description: 'Import a Google Docs document',
      section: AppSections.PRODUCTIVITY,
    },
    {
      title: 'Import from Google Docs',
      description:
        'Paste a link to a document from Google Docs here. This document will only be visible to those with access.',
      icon: 'google_docs',
      stringIncludesValidation: 'docs.google.com/document',
      errorMessage: 'Please enter a valid Google Docs link - e.g., docs.google.com/document/XYZ',
      buttonTitle: 'Import',
    },
  ),
  [CollaborativeApps.GOOGLE_FORMS]: new IAppConfiguration(
    {
      title: 'Google Forms',
      icon: 'apps_google_forms',
      instanceType: AppInstanceType.IFRAME,
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
    },
    {
      icon: 'apps_google_forms',
      title: 'Google Forms',
      description: 'Import a Google Forms document',
      section: AppSections.PRODUCTIVITY,
    },
    {
      title: 'Import from Google Forms',
      description:
        'Please paste a link to a form from Google Forms below. This form will only be visible to those with access.',
      icon: 'google_forms',
      stringIncludesValidation: 'docs.google.com/forms',
      errorMessage: 'Please enter a valid Google Forms link - e.g., docs.google.com/forms/XYZ',
      buttonTitle: 'Import',
      queryParams: new Map([['embedded', 'true']]),
    },
  ),
  [CollaborativeApps.GOOGLE_SHEETS]: new IAppConfiguration(
    {
      title: 'Google Sheets',
      icon: 'apps_google_sheets',
      instanceType: AppInstanceType.IFRAME,
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
    },
    {
      icon: 'apps_google_sheets',
      title: 'Google Sheets',
      description: 'Import a Google Sheets document',
      section: AppSections.PRODUCTIVITY,
    },
    {
      title: 'Import from Google Sheets',
      description:
        'Please paste a link to a spreadsheet from Google Sheets here. This spreadsheet will only be visible to those with access.',
      icon: 'google_sheets',
      stringIncludesValidation: 'docs.google.com/spreadsheets',
      errorMessage:
        'Please enter a valid Google Sheets link - e.g., docs.google.com/spreadsheets/XYZ',
      buttonTitle: 'Import',
    },
  ),
  [CollaborativeApps.GOOGLE_SLIDES]: new IAppConfiguration(
    {
      title: 'Google Slides',
      icon: 'apps_google_slides',
      instanceType: AppInstanceType.IFRAME,
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
    },
    {
      icon: 'apps_google_slides',
      title: 'Google Slides',
      description: 'Import a Google Slides document',
      section: AppSections.PRODUCTIVITY,
    },
    {
      title: 'Import from Google Slides',
      description:
        'Please paste a link to a presentation from Google Slides below. This presentation will only be visible to those with access.',
      icon: 'google_slides',
      stringIncludesValidation: 'docs.google.com/presentation',
      errorMessage:
        'Please enter a valid Google Slides link - e.g., docs.google.com/presentation/XYZ',
      buttonTitle: 'Import',
    },
  ),
  [CollaborativeApps.POLLS]: new IAppConfiguration(
    {
      title: 'Poll',
      icon: 'mario_polls',
      instanceType: AppInstanceType.MARIO,
      mario_resource: {
        _type: 'DEPLOYED',
        name: CollaborativeApps.POLLS,
        major_version: 2,
      },
      width: '500',
      height: '550',
      flag: 'enabled_mario_apps',
      allowFullscreen: true,
    },
    {
      icon: 'mario_polls',
      title: 'Poll',
      description: 'Run an interactive poll in Spaces',
      section: AppSections.PRODUCTIVITY,
    },
  ),
  [CollaborativeApps.STOPWATCH]: new IAppConfiguration(
    {
      title: 'Stopwatch',
      icon: 'stopwatch-mario',
      instanceType: AppInstanceType.MARIO,
      mario_resource: {
        _type: 'DEPLOYED',
        name: CollaborativeApps.STOPWATCH,
        major_version: 1,
      },
      width: '400',
      height: '220',
      flag: 'enabled_mario_apps',
    },
    {
      icon: 'stopwatch-mario',
      title: 'Stopwatch',
      description: 'Shared stopwatch in Spaces',
      section: AppSections.PRODUCTIVITY,
    },
  ),
  [CollaborativeApps.TIMER]: new IAppConfiguration(
    {
      title: 'Timer',
      icon: 'timer-mario',
      instanceType: AppInstanceType.MARIO,
      mario_resource: {
        _type: 'DEPLOYED',
        name: CollaborativeApps.TIMER,
        major_version: 1,
      },
      width: '400',
      height: '275',
      flag: 'enabled_mario_apps',
    },
    {
      icon: 'timer-mario',
      title: 'Timer',
      description: 'Shared timer in Spaces',
      section: AppSections.PRODUCTIVITY,
    },
  ),
  [CollaborativeApps.ERASER]: new IAppConfiguration(
    {
      title: 'Eraser.io',
      icon: 'apps_eraser.png',
      instanceType: AppInstanceType.MARIO,
      mario_resource: {
        _type: 'DEPLOYED',
        name: CollaborativeApps.ERASER,
        major_version: 1,
      },
      width: '1280',
      height: '720',
      flag: 'enabled_mario_apps',
      allowFullscreen: true,
    },
    {
      icon: 'apps_eraser.png',
      title: 'Eraser.io',
      description: 'Interactive flowcharts in Spaces',
      section: AppSections.PRODUCTIVITY,
    },
  ),
  [CollaborativeApps.ONEDRIVE_PPT]: new IAppConfiguration(
    {
      title: 'PowerPoint Presentation',
      icon: 'apps_powerpoint.png',
      instanceType: AppInstanceType.ONEDRIVE_PPT,
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      flag: 'enable_onedrive_import',
    },
    {
      icon: 'apps_powerpoint.png',
      title: 'PowerPoint Presentation',
      description: 'Present interactive PowerPoints',
      section: AppSections.PRODUCTIVITY,
    },
  ),
  [CollaborativeApps.WEBVIEWER]: new IAppConfiguration(
    {
      title: 'Web Viewer',
      icon: 'collaborative_browser',
      instanceType: AppInstanceType.WEBVIEWER,
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      flag: 'enable_browser',
      webgl: false,
      permissionName: 'insertWebViewer',
    },
    {
      icon: 'collaborative_browser',
      disabledIcon: 'collaborative_browser_disabled',
      title: 'Web Viewer',
      description: 'Collaborate over a shared browser',
      section: AppSections.CORE,
    },
  ),
  [CollaborativeApps.HUMAN_ANATOMICAL_MODELS]: new IAppConfiguration(
    {
      title: 'Human anatomical models',
      icon: 'human_anatomical_models',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://human.biodigital.com/login?returnUrl=/explore',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: true,
    },
    {
      title: 'Human anatomical models',
      icon: 'human_anatomical_models',
      description: '3D models for medical instruction',
      section: AppSections.SCIENCE,
      isLibraryAppParent: true,
    },
  ),
  [CollaborativeApps.BIODIGITAL]: new IAppConfiguration(
    {
      title: 'BioDigital',
      icon: 'apps_biodigital.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://human.biodigital.com/login?returnUrl=/explore',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: true,
    },
    {
      icon: 'apps_biodigital.png',
      title: 'BioDigital',
      description: '3D anatomical models',
      section: AppSections.EDUCATION,
    },
  ),
  [CollaborativeApps.BBC_BITESIZE]: new IAppConfiguration(
    {
      title: 'BBC Bitesize',
      icon: 'apps_bbc_bitesize.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.bbc.co.uk/bitesize',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
      country: 'GB',
      adblock: false,
    },
    {
      icon: 'apps_bbc_bitesize.png',
      title: 'BBC Bitesize',
      description: 'Open BBC Bitesize in Spaces',
      section: AppSections.EDUCATION,
    },
  ),
  [CollaborativeApps.CURIPOD]: new IAppConfiguration(
    {
      title: 'Curipod',
      icon: 'apps_curipod.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://curipod.com/lessons',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_curipod.png',
      title: 'Curipod',
      description: 'Share Curipod presentations',
      section: AppSections.EDUCATION,
    },
  ),
  [CollaborativeApps.CHEMIX]: new IAppConfiguration(
    {
      title: 'Chemix',
      icon: 'apps_chemix.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://chemix.org/',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_chemix.png',
      title: 'Chemix',
      description: 'Intractive STEM diagramming tool',
      section: AppSections.SCIENCE,
    },
  ),
  [CollaborativeApps.ABC_READING_EGGS]: new IAppConfiguration(
    {
      title: 'ABC Reading Eggs',
      icon: 'apps_abc-reading-eggs.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://app.readingeggs.com/login',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_abc-reading-eggs.png',
      title: 'ABC Reading Eggs',
      description: 'Open ABC Reading Eggs in Spaces',
      section: AppSections.LMS,
    },
  ),
  [CollaborativeApps.ACTIVELEARN]: new IAppConfiguration(
    {
      title: 'ActiveLearn',
      icon: 'apps_active_learn.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.pearsonactivelearn.com/app/login',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_active_learn.png',
      title: 'ActiveLearn',
      description: 'Open ActiveLearn in Spaces',
      section: AppSections.LMS,
    },
  ),
  [CollaborativeApps.BLACKBOARD]: new IAppConfiguration(
    {
      title: 'Blackboard',
      icon: 'apps_blackboard.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.blackboardconnect.com/',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_blackboard.png',
      title: 'Blackboard',
      description: 'Open Blackboard in Spaces',
      section: AppSections.LMS,
    },
  ),
  [CollaborativeApps.CANVAS_LMS]: new IAppConfiguration(
    {
      title: 'Canvas LMS',
      icon: 'apps_canvas_lms.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.instructure.com/canvas/login/free-for-teacher',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_canvas_lms.png',
      title: 'Canvas LMS',
      description: 'Open Canvas in Spaces',
      section: AppSections.LMS,
    },
  ),
  [CollaborativeApps.CLASSPAD]: new IAppConfiguration(
    {
      title: 'ClassPad',
      icon: 'apps_classpad.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://classpad.net/note/#/tools/math',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_classpad.png',
      title: 'ClassPad',
      description: 'Collaborative Math canvas',
      section: AppSections.MATH,
    },
  ),
  [CollaborativeApps.DESMOS_GRAPHING_CALCULATOR]: new IAppConfiguration(
    {
      title: 'Desmos Graphing Calculator',
      icon: 'apps_desmos_calculator',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.desmos.com/calculator',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_desmos_calculator',
      title: 'Desmos Graphing Calculator',
      description: 'Collaborative graphing calculator',
      section: AppSections.MATH,
    },
  ),
  [CollaborativeApps.DESMOS_GEOMETRY]: new IAppConfiguration(
    {
      title: 'Desmos Geometry',
      icon: 'apps_desmos_geometry',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.desmos.com/geometry',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_desmos_geometry',
      title: 'Desmos Geometry',
      description: 'Collaborative geometry / polygon tools',
      section: AppSections.MATH,
    },
  ),
  [CollaborativeApps.DESMOS_SCIENTIFIC_CALCULATOR]: new IAppConfiguration(
    {
      title: 'Desmos Scientific Calculator',
      icon: 'apps_desmos_scientific',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.desmos.com/scientific',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_desmos_scientific',
      title: 'Desmos Scientific Calculator',
      description: 'Collaborative scientific calculator',
      section: AppSections.MATH,
    },
  ),
  [CollaborativeApps.GOOGLE_CLASSROOM_LMS]: new IAppConfiguration(
    {
      title: 'Google Classroom',
      icon: 'apps_google_classroom.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://classroom.google.com/',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_google_classroom.png',
      title: 'Google Classroom',
      description: 'Open Classroom in Spaces',
      section: AppSections.LMS,
    },
  ),
  [CollaborativeApps.IREADY]: new IAppConfiguration(
    {
      title: 'i-Ready',
      icon: 'apps_iready.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://login.i-ready.com/',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_iready.png',
      title: 'i-Ready',
      description: 'Open i-Ready in Spaces',
      section: AppSections.LMS,
    },
  ),
  [CollaborativeApps.IXL]: new IAppConfiguration(
    {
      title: 'IXL',
      icon: 'apps_ixl.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.ixl.com/signin',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_ixl.png',
      title: 'IXL',
      description: 'Open IXL in Spaces',
      section: AppSections.LMS,
    },
  ),
  [CollaborativeApps.MATHIGON]: new IAppConfiguration(
    {
      title: 'Mathigon Polypad',
      icon: 'apps_mathigon.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://mathigon.org/polypad',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_mathigon.png',
      title: 'Mathigon Polypad',
      description: 'Interactive math widgets',
      section: AppSections.MATH,
    },
  ),
  [CollaborativeApps.MATHWAY]: new IAppConfiguration(
    {
      title: 'Mathway',
      icon: 'apps_mathway.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.mathway.com/Algebra',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_mathway.png',
      title: 'Mathway',
      description: 'Collaborative algebra problem solver',
      section: AppSections.MATH,
    },
  ),
  [CollaborativeApps.PERIODIC_TABLE]: new IAppConfiguration(
    {
      title: 'Periodic Table',
      icon: 'apps_periodic',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.elmnt.info',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_periodic',
      title: 'Periodic Table',
      description: 'Collaborative periodic table',
      section: AppSections.SCIENCE,
    },
  ),
  [CollaborativeApps.PHET_SIMULATION]: new IAppConfiguration(
    {
      title: 'PhET Simulations',
      icon: 'apps_phet.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://phet.colorado.edu/en/simulations/filter?type=html,prototype',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_phet.png',
      title: 'PhET Simulations',
      description: 'Interactive STEM simulations',
      section: AppSections.SCIENCE,
      isLibraryAppParent: true,
    },
  ),
  [CollaborativeApps.AI_PHET_SIMULATION]: new IAppConfiguration(
    {
      title: 'PhET Interactive Simulations',
      icon: 'apps_phet.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://phet.colorado.edu/en/simulations/filter?type=html,prototype',
      width: `${IFRAME_APP_DEFAULT_WIDTH * 0.75}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT * 0.75}`,
      webgl: false,
    },
    {
      icon: 'apps_phet.png',
      title: 'PhET Interactive Simulations',
      description: 'Interactive STEM simulations',
      section: AppSections.HIDDEN_APPS,
    },
  ),
  [CollaborativeApps.GOOGLE_MAPS]: new IAppConfiguration(
    {
      title: 'Google Maps',
      icon: 'apps_google_maps.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://maps.google.com/',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_google_maps.png',
      title: 'Google Maps',
      description: 'Collaboratively browse Google Maps',
      section: AppSections.EDUCATION,
    },
  ),
  [CollaborativeApps.NEARPOD]: new IAppConfiguration(
    {
      icon: 'apps_nearpod.png',
      title: 'Nearpod',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.IFRAME,
      defaultUrl: 'https://app.nearpod.com/',
      flag: 'enable_iframe',
      shareablePin: true,
      sharedUrl: 'https://app.nearpod.com/?pin=PENCIL_PIN_CODE',
      cursorsHidden: false,
    },
    {
      icon: 'apps_nearpod.png',
      title: 'Nearpod',
      description: 'Run Nearpod quizzes in Spaces',
      section: AppSections.EDUCATION,
    },
  ),
  [CollaborativeApps.QUIZLET]: new IAppConfiguration(
    {
      title: 'Quizlet',
      icon: 'apps_quizlet.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://quizlet.com/latest',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_quizlet.png',
      title: 'Quizlet',
      description: 'Interactive learning tools and flashcards',
      section: AppSections.EDUCATION,
    },
  ),
  [CollaborativeApps.QUIZIZZ]: new IAppConfiguration(
    {
      icon: 'apps_quizizz.png',
      title: 'Quizizz',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.IFRAME,
      defaultUrl: 'https://quizizz.com/',
      flag: 'enable_iframe',
      shareablePin: true,
      sharedUrl: 'https://quizizz.com/join?gc=PENCIL_PIN_CODE',
      cursorsHidden: false,
    },
    {
      icon: 'apps_quizizz.png',
      title: 'Quizizz',
      description: 'Run Quizizz quizzes in Spaces',
      section: AppSections.EDUCATION,
    },
  ),
  [CollaborativeApps.SCRATCH]: new IAppConfiguration(
    {
      title: 'Scratch',
      icon: 'apps_scratch.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://scratch.mit.edu/projects/editor/',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: true,
    },
    {
      icon: 'apps_scratch.png',
      title: 'Scratch',
      description: 'Learn programming the fun way!',
      section: AppSections.EDUCATION,
    },
  ),
  [CollaborativeApps.MINDFLOW]: new IAppConfiguration(
    {
      title: 'MindFlow Speed Reading',
      icon: 'apps_mindflow.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://app.mindflowspeedreading.com/pencilSpacesLogin',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_mindflow.png',
      title: 'MindFlow Speed Reading',
      description: 'Accelerate reading speeds with MindFlow',
      section: AppSections.LITERACY,
    },
  ),
  [CollaborativeApps.DICTIONARY]: new IAppConfiguration(
    {
      title: 'Dictionary.com',
      icon: 'apps_dictionary.com.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.dictionary.com/',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_dictionary.com.png',
      title: 'Dictionary.com',
      description: 'Search words on Dictionary.com',
      section: AppSections.LITERACY,
    },
  ),
  [CollaborativeApps.INTERVENTION_CARDS]: new IAppConfiguration(
    {
      title: 'Intervention Cards',
      icon: 'app_intervention_cards.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://interventioncards.amplify.com/',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
      initialZoom: 0.8,
    },
    {
      icon: 'app_intervention_cards.png',
      title: 'Intervention Cards',
      description: 'Amplify Intervention Cards for literacy',
      section: AppSections.LITERACY,
    },
  ),
  [CollaborativeApps.THESAURUS]: new IAppConfiguration(
    {
      title: 'Thesaurus.com',
      icon: 'apps_thesaurus.com.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.thesaurus.com',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_thesaurus.com.png',
      title: 'Thesaurus.com',
      description: 'Search words on Thesaurus.com',
      section: AppSections.LITERACY,
    },
  ),
  [CollaborativeApps.UFLI_BLENDING_BOARD]: new IAppConfiguration(
    {
      title: 'UFLI Blending Board',
      icon: 'apps_ufli.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://research.dwi.ufl.edu/op.n/file/bca9ju45kvvrvoan',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_ufli.png',
      title: 'UFLI Blending Board',
      description: 'Interactive tool to improve reading skills',
      section: AppSections.LITERACY,
    },
  ),
  [CollaborativeApps.UFLI_WORD_WORK_MAT]: new IAppConfiguration(
    {
      title: 'UFLI Word Work Mat',
      icon: 'apps_ufli.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://research.dwi.ufl.edu/op.n/file/gc8nkxns914enc7d',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_ufli.png',
      title: 'UFLI Word Work Mat',
      description: 'Interactive tool to improve reading skills',
      section: AppSections.LITERACY,
    },
  ),
  [CollaborativeApps.WIKIPEDIA]: new IAppConfiguration(
    {
      title: 'Wikipedia',
      icon: 'apps_wikipedia.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.wikipedia.org/',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_wikipedia.png',
      title: 'Wikipedia',
      description: 'The world’s largest free encyclopedia',
      section: AppSections.EDUCATION,
    },
  ),
  [CollaborativeApps.YOUTUBE]: new IAppConfiguration(
    {
      title: 'YouTube',
      icon: 'apps_youtube.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.youtube.com/',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_youtube.png',
      title: 'YouTube',
      description: 'Watch videos together on YouTube',
      section: AppSections.EDUCATION,
    },
  ),
  [CollaborativeApps.AI_YOUTUBE]: new IAppConfiguration(
    {
      title: 'YouTube',
      icon: 'apps_youtube.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.youtube.com/',
      width: `${IFRAME_APP_DEFAULT_WIDTH * 0.75}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT * 0.75}`,
      webgl: false,
    },
    {
      icon: 'apps_youtube.png',
      title: 'YouTube',
      description: 'Watch videos together on YouTube',
      section: AppSections.HIDDEN_APPS,
    },
  ),
  [CollaborativeApps.WIKTIONARY]: new IAppConfiguration(
    {
      title: 'Wiktionary',
      icon: 'apps_wiktionary.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.wiktionary.org/',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_wiktionary.png',
      title: 'Wiktionary',
      description: 'Multilingual free dictionary',
      section: AppSections.LITERACY,
    },
  ),
  [CollaborativeApps.WORD_REFERENCE]: new IAppConfiguration(
    {
      title: 'Word Reference',
      icon: 'apps_word_reference.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.wordreference.com/',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_word_reference.png',
      title: 'Word Reference',
      description: 'Multilingual dictionary',
      section: AppSections.LITERACY,
    },
  ),
  [CollaborativeApps.MATHIA]: new IAppConfiguration(
    {
      title: 'MATHia',
      icon: 'apps_mathia.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://online.carnegielearning.com',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_mathia.png',
      title: 'MATHia',
      description: "Open Carnegie Learning's MATHia",
      section: AppSections.LMS,
    },
  ),
  [CollaborativeApps.MYMATHS]: new IAppConfiguration(
    {
      title: 'MyMaths',
      icon: 'apps_mymaths.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://login.mymaths.co.uk/login',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_mymaths.png',
      title: 'MyMaths',
      description: 'Open MyMaths in Spaces',
      section: AppSections.LMS,
    },
  ),
  [CollaborativeApps.OFF2CLASS]: new IAppConfiguration(
    {
      title: 'Off2Class',
      icon: 'apps_off2class.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://app.off2class.com/login',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_off2class.png',
      title: 'Off2Class',
      description: 'Open Off2Class in Spaces',
      section: AppSections.LMS,
    },
  ),
  [CollaborativeApps.XCELLATE]: new IAppConfiguration(
    {
      title: 'Xcellate',
      icon: 'apps_xcellate.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.xcellate.co.za/',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_xcellate.png',
      title: 'Xcellate',
      description: 'Open Xcellate in Spaces',
      section: AppSections.LMS,
    },
  ),
  // IFrame apps
  [CollaborativeApps.AVALON]: new IAppConfiguration(
    {
      icon: 'apps_avalon.png',
      title: 'Avalon',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.IFRAME,
      defaultUrl: 'https://netgames.io/games/avalon/',
      flag: 'enable_iframe',
      shareablePin: true,
      sharedUrl: 'https://netgames.io/games/avalon/rooms/PENCIL_PIN_CODE',
      cursorsHidden: true,
    },
    {
      icon: 'apps_avalon.png',
      title: 'Avalon',
      description: 'Play Avalon directly in Spaces',
      section: AppSections.GAMES,
    },
  ),
  [CollaborativeApps.CODE_WORDS]: new IAppConfiguration(
    {
      icon: 'apps_code_words.png',
      title: 'Codewords',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.IFRAME,
      defaultUrl: 'https://netgames.io/games/codewords/',
      flag: 'enable_iframe',
      shareablePin: true,
      sharedUrl: 'https://netgames.io/games/codewords/rooms/PENCIL_PIN_CODE',
      cursorsHidden: false,
    },
    {
      icon: 'apps_code_words.png',
      title: 'Codewords',
      description: 'Play Codewords directly in Spaces',
      section: AppSections.GAMES,
    },
  ),
  [CollaborativeApps.COLONIST]: new IAppConfiguration(
    {
      icon: 'apps_colonist.png',
      title: 'Colonist.io',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.IFRAME,
      defaultUrl: 'https://colonist.io/',
      flag: 'enable_iframe',
      shareablePin: false,
      sharedUrl: 'https://colonist.io/#PENCIL_PIN_CODE',
      cursorsHidden: false,
    },
    {
      icon: 'apps_colonist.png',
      title: 'Colonist.io',
      description: 'Play Catan directly in Spaces',
      section: AppSections.GAMES,
    },
  ),
  [CollaborativeApps.DOWN_FOR_A_CROSS]: new IAppConfiguration(
    {
      icon: 'apps_down_for_a_cross',
      title: 'DownForACross',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.IFRAME,
      defaultUrl: 'https://downforacross.com/',
      flag: 'enable_iframe',
      shareablePin: false,
      sharedUrl: 'https://downforacross.com/beta/game/PENCIL_PIN_CODE',
      cursorsHidden: false,
    },
    {
      icon: 'apps_down_for_a_cross',
      title: 'DownForACross',
      description: 'Solve Crosswords directly in Spaces',
      section: AppSections.GAMES,
    },
  ),
  [CollaborativeApps.DRAW_BATTLE]: new IAppConfiguration(
    {
      icon: 'apps_draw_battle.png',
      title: 'DrawBattle.io',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.IFRAME,
      defaultUrl: 'https://drawbattle.io/',
      flag: 'enable_iframe',
      shareablePin: true,
      sharedUrl: 'https://drawbattle.io/PENCIL_PIN_CODE',
      cursorsHidden: false,
    },
    {
      icon: 'apps_draw_battle.png',
      title: 'DrawBattle.io',
      description: 'Play Draw Battle directly in Spaces',
      section: AppSections.GAMES,
    },
  ),
  [CollaborativeApps.LI_POKER]: new IAppConfiguration(
    {
      icon: 'apps_li_poker.png',
      title: 'LiPoker',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.IFRAME,
      defaultUrl: 'https://lipoker.io/game/',
      flag: 'enable_iframe',
      shareablePin: false,
      sharedUrl: 'https://lipoker.io/game/PENCIL_PIN_CODE',
      cursorsHidden: false,
    },
    {
      icon: 'apps_li_poker.png',
      title: 'LiPoker',
      description: 'Play Poker directly in Spaces',
      section: AppSections.GAMES,
    },
  ),
  [CollaborativeApps.SCATTEGORIES]: new IAppConfiguration(
    {
      icon: 'apps_scattegories',
      title: 'Scattegories',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.IFRAME,
      defaultUrl: 'https://really.boring.website/',
      flag: 'enable_iframe',
      shareablePin: true,
      sharedUrl: 'https://really.boring.website/room/PENCIL_PIN_CODE',
      cursorsHidden: true,
    },
    {
      icon: 'apps_scattegories',
      title: 'Scattegories',
      description: 'Play Scattegories directly in Spaces',
      section: AppSections.GAMES,
    },
  ),
  [CollaborativeApps.SKRIBBLE_IO]: new IAppConfiguration(
    {
      icon: 'apps_skribbleio.png',
      title: 'Skribbl.io',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.IFRAME,
      defaultUrl: 'https://skribbl.io/',
      flag: 'enable_iframe',
      shareablePin: false,
      sharedUrl: 'https://skribbl.io/?PENCIL_PIN_CODE',
      cursorsHidden: false,
    },
    {
      icon: 'apps_skribbleio.png',
      title: 'Skribbl.io',
      description: 'Play Skribbl directly in Spaces',
      section: AppSections.GAMES,
    },
  ),
  [CollaborativeApps.TOP_FOUR]: new IAppConfiguration(
    {
      icon: 'apps_top_four.png',
      title: 'TopFour.io',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.IFRAME,
      defaultUrl: 'https://topfour.io/',
      flag: 'enable_iframe',
      shareablePin: true,
      sharedUrl: 'https://topfour.io/PENCIL_PIN_CODE/players',
      cursorsHidden: false,
    },
    {
      icon: 'apps_top_four.png',
      title: 'TopFour.io',
      description: 'Play Top Four directly in Spaces',
      section: AppSections.GAMES,
    },
  ),
  [CollaborativeApps.WEREWOLF]: new IAppConfiguration(
    {
      icon: 'apps_werewolf.png',
      title: 'Werewolf',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.IFRAME,
      defaultUrl: 'https://onmuga.com/werewolf/',
      flag: 'enable_iframe',
      shareablePin: true,
      sharedUrl: 'https://onmuga.com/werewolf/PENCIL_PIN_CODE',
      cursorsHidden: false,
    },
    {
      icon: 'apps_werewolf.png',
      title: 'Werewolf',
      description: 'Play Werewolf directly in Spaces',
      section: AppSections.GAMES,
    },
  ),
  [CollaborativeApps.WORDLE]: new IAppConfiguration(
    {
      icon: 'apps_wordle.png',
      title: 'Wordle',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.IFRAME,
      defaultUrl: 'https://onmuga.com/wordle/',
      flag: 'enable_iframe',
      shareablePin: false,
      sharedUrl: 'https://onmuga.com/wordle/PENCIL_PIN_CODE',
      cursorsHidden: false,
    },
    {
      icon: 'apps_wordle.png',
      title: 'Wordle',
      description: 'Play collaborative Wordle in Spaces',
      section: AppSections.GAMES,
    },
  ),
  [CollaborativeApps.SONG_MAKER]: new IAppConfiguration(
    {
      title: 'Song Maker',
      icon: 'apps_songmaker.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://musiclab.chromeexperiments.com/Song-Maker/',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_songmaker.png',
      title: 'Song Maker',
      description: 'Create simple tunes in Spaces!',
      section: AppSections.MUSIC,
    },
  ),
  [CollaborativeApps.SOUNDTRAP]: new IAppConfiguration(
    {
      icon: 'apps_soundtrap.png',
      title: 'Soundtrap',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.soundtrap.com/home/',
      webgl: false,
    },
    {
      icon: 'apps_soundtrap.png',
      title: 'Soundtrap',
      description: 'Make music together online',
      section: AppSections.MUSIC,
    },
  ),
  [CollaborativeApps.SPROUTBEAT]: new IAppConfiguration(
    {
      icon: 'apps_sproutbeat.png',
      title: 'SproutBeat',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://app.sproutbeat.com/login',
      webgl: false,
    },
    {
      icon: 'apps_sproutbeat.png',
      title: 'SproutBeat',
      description: 'Collaborative music resources',
      section: AppSections.MUSIC,
    },
  ),
  [CollaborativeApps.TRANSITIONSDJ]: new IAppConfiguration(
    {
      title: 'Transitions DJ',
      icon: 'apps_transitions_dj.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://app.transitions.dj/',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_transitions_dj.png',
      title: 'Transitions DJ',
      description: 'Mix DJ mixes collaboratively!',
      section: AppSections.MUSIC,
    },
  ),
  [CollaborativeApps.XYLOPHONE]: new IAppConfiguration(
    {
      icon: 'apps_xylophone.png',
      title: 'Xylophone',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://playxylo.com/',
      webgl: false,
    },
    {
      icon: 'apps_xylophone.png',
      title: 'Xylophone',
      description: 'Shared xylophone in Spaces',
      section: AppSections.MUSIC,
    },
  ),
  [CollaborativeApps.PIANO]: new IAppConfiguration(
    {
      icon: 'apps_piano_icon.png',
      title: 'Piano',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://musiclab.chromeexperiments.com/Shared-Piano/',
      webgl: false,
    },
    {
      icon: 'apps_piano_icon.png',
      title: 'Piano',
      description: 'Shared piano in Spaces',
      section: AppSections.MUSIC,
    },
  ),
  [CollaborativeApps.DICE]: new IAppConfiguration(
    {
      icon: 'apps_dice.jpeg',
      title: 'Dice',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.didax.com/apps/dice/',
      webgl: false,
    },
    {
      icon: 'apps_dice.jpeg',
      title: 'Dice',
      description: 'Shared dice in Spaces',
      section: AppSections.MATH,
    },
  ),
  [CollaborativeApps.FRACTIONS]: new IAppConfiguration(
    {
      icon: 'apps_fractions.jpeg',
      title: 'Fractions',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.didax.com/apps/fraction-number-line/',
      webgl: false,
    },
    {
      icon: 'apps_fractions.jpeg',
      title: 'Fractions',
      description: 'Teach fractions interactively',
      section: AppSections.MATH,
    },
  ),
  [CollaborativeApps.NUMWORKS]: new IAppConfiguration(
    {
      title: 'NumWorks',
      icon: 'apps_numworks.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.numworks.com/simulator/',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_numworks.png',
      title: 'NumWorks',
      description: 'College Board approved calculator',
      section: AppSections.MATH,
    },
  ),
  [CollaborativeApps.REKNEREK]: new IAppConfiguration(
    {
      icon: 'apps_reknerek.jpeg',
      title: 'Reknerek',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.didax.com/apps/rekenrek/',
      webgl: false,
    },
    {
      icon: 'apps_reknerek.jpeg',
      title: 'Reknerek',
      description: 'Shared reknerek in Spaces',
      section: AppSections.MATH,
    },
  ),
  [CollaborativeApps.SPINNER]: new IAppConfiguration(
    {
      icon: 'apps_spinner.jpeg',
      title: 'Spinner',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.didax.com/apps/spinners/',
      webgl: false,
    },
    {
      icon: 'apps_spinner.jpeg',
      title: 'Spinner',
      description: 'Shared spinner in Spaces',
      section: AppSections.MATH,
    },
  ),
  [CollaborativeApps.TEN_FRAMES]: new IAppConfiguration(
    {
      icon: 'apps_tenframes.jpeg',
      title: 'Ten Frames',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.didax.com/apps/ten-frames/',
      webgl: false,
    },
    {
      icon: 'apps_tenframes.jpeg',
      title: 'Ten Frames',
      description: 'Teach numeracy with Ten Frames',
      section: AppSections.MATH,
    },
  ),
  [CollaborativeApps.WOLFRAM_ALPHA]: new IAppConfiguration(
    {
      title: 'Wolfram Alpha',
      icon: 'apps_wolfram_alpha.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.wolframalpha.com/',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_wolfram_alpha.png',
      title: 'Wolfram Alpha',
      description: 'Interactive knowledge engine',
      section: AppSections.MATH,
    },
  ),
  [CollaborativeApps.MORE_APPS]: new IAppConfiguration(
    {
      title: 'Math Learning Center',
      icon: 'apps_mathcenter.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://www.mathlearningcenter.org/apps',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: false,
    },
    {
      icon: 'apps_mathcenter.png',
      title: 'Math Learning Center',
      description: 'A collection of interactive numeracy tools',
      section: AppSections.MATH,
    },
  ),
  [CollaborativeApps.FORMIT]: new IAppConfiguration(
    {
      title: 'Autodesk FormIt',
      icon: 'apps_formit.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://app.formit.autodesk.com/',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: true,
    },
    {
      icon: 'apps_formit.png',
      title: 'Autodesk FormIt',
      description: 'Open Autodesk FormIt in Spaces',
      section: AppSections.DESIGN,
    },
  ),
  [CollaborativeApps.SKETCHUP]: new IAppConfiguration(
    {
      title: 'SketchUp',
      icon: 'apps_sketchup.png',
      instanceType: AppInstanceType.WEBVIEWER,
      defaultUrl: 'https://app.sketchup.com/app?hl=en',
      width: `${IFRAME_APP_DEFAULT_WIDTH}`,
      height: `${IFRAME_APP_DEFAULT_HEIGHT}`,
      webgl: true,
    },
    {
      icon: 'apps_sketchup.png',
      title: 'SketchUp',
      description: 'Open SketchUp in Spaces',
      section: AppSections.DESIGN,
    },
  ),
};

export const LegacyApps = [CollaborativeApps.BIODIGITAL];

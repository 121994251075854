<div
  class="upgrade-plan-blocker-container"
  [@fadeIn]
  [ngClass]="{
    'cml-8': arrow === 'left',
    'cmr-8': arrow === 'right',
    'cmt-8': arrow === 'top',
    'cmb-8': arrow === 'bottom'
  }"
>
  <div class="upgrade-plan-tip">
    <div class="_inner">
      <div class="top d-flex align-items-center justify-content-center">
        <svg-icon
          class="pro-badge cmr-4"
          [width]="20"
          [height]="20"
          [icon]="'pro_badge'"
        ></svg-icon>
        <span>
          <strong>{{ 'Paid feature' | translate }}</strong>
        </span>
      </div>
      <div class="body">
        <span>{{ 'This feature is only available for ' | translate }}</span>
        <ng-container *ngFor="let tier of ctaText; let last = last">
          <strong>{{ tier + ' ' }}</strong>
          <span *ngIf="!last">{{ 'or ' | translate }}</span>
        </ng-container>
        <span>
          {{ 'users' | translate }}
        </span>
      </div>
      <app-pencil-button
        class="pencil-button medium upgrade-btn"
        data-name="updgrade-plan-blocker-proceed"
        (clickEvent)="openSettingsPayment()"
        icon="arrow_circle_up"
        [iconConfig]="{ type: IconTypes.materialIcon }"
        [label]="'Upgrade' | translate"
      >
      </app-pencil-button>
    </div>
  </div>
  <div *ngIf="arrow" [class]="'custom-' + arrow + '-tip-after'"></div>
</div>

import { ManagerOptions, SocketOptions } from 'socket.io-client';

// ---- Generic Socket Interface ----

export abstract class PencilSocket<Ev> {
  protected readonly url: string;
  protected readonly opts?: SocketIOOptions;

  constructor(socketUrl: string, opts?: Partial<ManagerOptions & SocketOptions>) {
    this.url = socketUrl;
    this.opts = opts;
  }

  private _emitOptions: EmitOptions = {};

  /**
   * Register a callback to be fired when an event is received
   * @param ev event
   * @param cb callback
   */
  public abstract on(ev: Ev | SocketConnectionEvent | SocketManagerEvent, cb: Callback): void;

  /**
   * Deregisters callback for event
   * @param ev event
   * @param cb callback
   */
  public abstract off(ev: Ev | SocketConnectionEvent | SocketManagerEvent, cb: Callback): void;

  /**
   * Send an event through the socket
   * @param ev event
   * @param args arguments sent for the event
   *             note: callbacks can be registered via that last argument
   *                  e.g.  ```this.socket.emit('event', {req: 'test'}, (response) => console.log)```
   */
  public abstract emit(ev: Ev, ...args: unknown[]): void;

  /**
   * clears the options for an emit
   * @returns the options before `clearEmitOptions()` was called
   */
  protected clearEmitOptions(): EmitOptions {
    const options = Object.assign({}, this._emitOptions);
    this._emitOptions = {};
    return options;
  }

  /**
   * Sets the volatile emit option
   * @returns this
   */
  public volatile(): this {
    this._emitOptions.volatile = true;
    return this;
  }

  /**
   * Sets the timeout emit option
   * @param timeout
   * @returns this
   */
  public timeout(timeout: number): this {
    this._emitOptions.timeout = timeout;
    return this;
  }

  /**
   * Connects the socket to the server
   */
  public abstract connect(): Promise<void>;

  /**
   * Disconnects the socket from the server
   */
  public abstract disconnect(): Promise<void>;

  /**
   * Returns the connect status of the socket
   */
  public abstract connected(): Promise<boolean>;

  /**
   * Returns a unique id for the socket
   */
  public abstract getSocketId(): Promise<string>;

  /**
   * Utility function to allow implementations of the generic socket
   * to parse the arguments for callbacks
   * @param args
   * @returns
   */
  protected _parseArgs(...args: unknown[]): [unknown[], Callback | undefined] {
    if (this._hasCallback(...args)) {
      return [args.slice(0, args.length - 1), args[args.length - 1] as Callback];
    } else {
      return [args, undefined];
    }
  }

  /**
   * checks if a list has a callback as the last parameter
   * @param args
   * @returns
   */
  private _hasCallback(...args: unknown[]): boolean {
    return args.length > 0 && typeof args[args.length - 1] === 'function';
  }
}

export type Callback = (...args: unknown[]) => void;

export interface EmitOptions {
  volatile?: boolean;
  timeout?: number;
}

export enum SocketConnectionEvent {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  CONNECT_ERROR = 'connectError',
}

export enum SocketManagerEvent {
  RECONNECT_ATTEMPT = 'reconnect_attempt',
  RECONNECT = 'reconnect',
  RECONNECT_ERROR = 'reconnect_error',
  RECONNECT_FAILED = 'reconnect_failed',
}

export enum BrowserLifecycleEvent {
  ONLINE = 'online',
  OFFLINE = 'offline',
  BEFOREUNLOAD = 'beforeunload',
}

export type SocketIOOptions = Partial<ManagerOptions & SocketOptions>;

/**
 * Used to indicate that a callback has been registered during an emit
 */
export const CallbackIdentifier = '_socket_callback_marker';

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PhetEmbedDialogComponent } from 'src/app/dialogs/phet-embed/phet-embed.component';
import { Fragment } from 'src/app/models/question';

@Component({
  selector: 'app-phet-embed',
  templateUrl: './phet-embed.component.html',
  styleUrls: ['./phet-embed.component.scss'],
})
export class PhetEmbedComponent implements OnInit {
  @Input() frag!: Fragment;

  imageUrl?: string;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    if (this.frag) {
      this.imageUrl = this.frag.data.replace('_en.html', '-600.png');
    }
  }

  openDialog(): void {
    this.dialog.open(PhetEmbedDialogComponent, { data: { phetUrl: this.frag.data } });
  }
}

import { Component, OnInit } from '@angular/core';
import { map, mergeMap, take } from 'rxjs';

import { LangCode } from '../../sessions/session/wb-top-controls/wb-top-controls.component';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  langCodeMap: LangCode[] = [];
  langCode = 'en';
  lang?: LangCode;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService.langCodes
      .pipe(
        mergeMap((langCodeMap) =>
          this.userService.selectedLangCode.pipe(
            map((langCode) => ({
              langCodeMap,
              langCode,
            })),
          ),
        ),
        take(1),
      )
      .subscribe(({ langCode, langCodeMap }) => {
        this.langCodeMap = langCodeMap;
        this.langCode = langCode;
        this.lang = langCodeMap.find((l) => l.code === langCode);
      });
  }

  changeLang(langCode: LangCode): void {
    if (!this.langCode) {
      return;
    }

    this.userService.confirmLanguageChange(this.langCode, langCode);
  }
}

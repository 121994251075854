import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../environments/environment';

export enum CustomUrlStorageKeys {
  ApiServerUrlKey = 'serverurl',
  WbServerUrlKey = 'wbserverurl',
}

@Injectable({
  providedIn: 'root',
})
export class URLService {
  private localStorageKeys = [
    CustomUrlStorageKeys.ApiServerUrlKey,
    CustomUrlStorageKeys.WbServerUrlKey,
  ];

  private defaulUrls = {
    serverurl: environment.serverURL,
    wbserverurl: environment.whiteboardServer,
  };

  private SOCKET_IO_DEFAULT_PATH = '/socket.io/';

  public customServerUrls = new BehaviorSubject<{ [key: string]: string }>(this.getAllCustomUrls());

  resetUrls() {
    this.localStorageKeys.forEach((key) => localStorage.removeItem(key));
  }

  setCustomUrl(url: string, key: string) {
    this.storeCustomUrlInStorage(url, key);
    this.customServerUrls.next(this.getAllCustomUrls());
  }

  getDynamicUrl(): string {
    return this.getDynamicUrlByKey(CustomUrlStorageKeys.ApiServerUrlKey);
  }

  getDynamicWhiteboardUrl(): string {
    const urlString = this.getDynamicUrlByKey(CustomUrlStorageKeys.WbServerUrlKey);
    const url = new URL(urlString);
    return `${url.protocol}//${url.hostname}`;
  }

  getDynamicWhiteboardPath(): string {
    const urlString = this.getDynamicUrlByKey(CustomUrlStorageKeys.WbServerUrlKey);
    const url = new URL(urlString);
    let wbServerPath = url.pathname;
    if (wbServerPath === '' || wbServerPath === '/') {
      wbServerPath = this.SOCKET_IO_DEFAULT_PATH;
    } else if (!wbServerPath.endsWith(this.SOCKET_IO_DEFAULT_PATH)) {
      wbServerPath += this.SOCKET_IO_DEFAULT_PATH;
    }
    return wbServerPath;
  }

  private getDynamicUrlByKey(key: string): string {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(key)) {
      const url = urlParams.get(key);
      if (!url?.trim()) {
        // This is a way to manually reset url.
        this.resetUrls();
        return this.defaulUrls[key];
      }
      // Make urls added to the address bar sticky
      this.storeCustomUrlInStorage(url, key);
    }
    const savedUrl = localStorage.getItem(key)?.toString();
    return savedUrl ?? this.defaulUrls[key];
  }

  private storeCustomUrlInStorage(url: string, key: string) {
    const strippedTrailingSlashUrl = url.replace(/\/+$/, '');
    const httpCheckedUrl =
      strippedTrailingSlashUrl.search(/http/) !== -1
        ? strippedTrailingSlashUrl
        : `https://${strippedTrailingSlashUrl}`;
    localStorage.setItem(key, httpCheckedUrl);
  }

  private getAllCustomUrls(): { [id: string]: string } {
    const customUrls = {};
    this.localStorageKeys.forEach((key) => {
      const url = this.getDynamicUrlByKey(key);
      if (url != this.defaulUrls[key]) {
        customUrls[key] = url;
      }
    });
    return customUrls;
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep, isEqual } from 'lodash';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  first,
  map,
  Observable,
  pairwise,
  startWith,
  EMPTY,
  Subscription,
  switchMap,
  tap,
  timeout,
  take,
} from 'rxjs';
import { ParticipantsManagerService } from 'src/app/services/participants-manager.service';
import { PresenceProvider } from 'src/app/services/presence-provider';
import { ProviderStateService } from 'src/app/services/provider-state.service';
import { RtcServiceController } from 'src/app/services/rtc.service';
import { VideoCallTracksStateService } from 'src/app/services/video-call-tracks-state.service';
import { SpaceRepository } from 'src/app/state/space.repository';
import { TranslateService } from '@ngx-translate/core';
import { PermissionStatus } from 'src/app/consts';
import { getUpdatedPermissions } from 'src/app/common/utils/common-util';
import {
  IconBackground,
  IconMessageToasterElement,
} from 'src/app/ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from 'src/app/services/notification-toaster.service';
import { SUCCESSES, WARNINGS } from 'src/app/common/utils/notification-constants';
import { LocalTracksManagerService } from 'src/app/services/local-tracks-manager.service';
import { DeviceType } from 'src/app/common/utils/devices-handle-util';
import { AttendanceManagerService } from 'src/app/services/attendance-manager.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IconTypes } from 'src/app/standalones/components/pencil-icon/pencil-icon.component';
import { SpacesService } from 'src/app/services/spaces.service';
import { DomListenerFactoryService } from 'src/app/services/dom-listener-factory.service';
import { GlobalPermissionsService } from 'src/app/services/global-permissions.service';
import {
  ISession,
  Permissions,
  Room,
  Session,
  SessionUser,
  SpaceSettings,
  Visibility,
} from '../../../models/session';
import { Institution, User, UserData } from '../../../models/user';
import { DevicesManagerService } from '../../../services/devices-manager.service';
import { FLAGS, FlagsService } from '../../../services/flags.service';
import { RealtimeSpaceService } from '../../../services/realtime-space.service';
import { SessionCallTracksService } from '../../../services/session-call-tracks.service';
import { SessionSharedDataService } from '../../../services/session-shared-data.service';
import { UserService } from '../../../services/user.service';
import { EventCategory, SessionEvent } from '../../session/SessionEvent';
import { PaywallIndicatorService } from '../../../services/paywall-indicator.service';
import { ToasterPopupStyle } from '../../../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { RequestAccessService } from '../../session/request-access/request-access.service';
import { WaitingRoomService } from '../../session/request-access/waiting-room/waiting-room.service';

export enum Section {
  CONTROLS = 'CONTROLS',
  PERMISSIONS = 'PERMISSIONS',
  ATTENDANCE = 'ATTENDANCE',
  ACCESS_REQUESTS = 'ACCESS_REQUESTS',
}

enum Global_State {
  ENABLED = 'ENABLED', // enabled for all participants
  DISABLED = 'DISABLED', // disabled for all participants
  INDETERMINATE = 'INDETERMINATE', // enabled for some and disabled for others
}

export enum ParticipantsManagerEvent {
  TURN_ON_REQUEST = 'turn_on_request',
  TURNOFF = 'turnoff',
  TURN_ON_REQUEST_ALL = 'turn_on_request_all',
  TURNOFF_ALL = 'turnoff_all',
  TURNON_RECORD = 'turnon_record',
  TURNOFF_RECORD = 'turnoff_record',
}

export enum DEVICE {
  MIC = 'mic',
  CAM = 'cam',
}

interface IPermission {
  fieldName:
    | 'shareAudio'
    | 'shareVideo'
    | 'screenShare'
    | 'editSpace'
    | 'messageEveryone'
    | 'messageOtherParticipants'
    | 'createBoards'
    | 'insertWebViewer';
  icon?: string;
  svg?: string;
  name: string;
  dataName: string;
}

@UntilDestroy()
@Component({
  selector: 'app-participants-manager',
  templateUrl: './participants-manager.component.html',
  styleUrls: ['./participants-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantsManagerComponent implements OnInit, AfterViewInit, OnDestroy {
  IconTypes = IconTypes;
  Visibility = Visibility;
  section = Section;
  rolesMap: { [key: string]: SessionUser } = {};
  subscribers: Subscription[] = [];
  activeHostUsers: User[] = [];
  activeParticipantsUsers: User[] = [];
  inactiveUsers: User[] = [];
  currentUser?: User;
  sessionId = '';
  deviceEnum = DEVICE;
  isHost = false;
  loadingDeviceModal = false;
  // represents if the permission is enabled or not
  currentPermissions: any = new Permissions();
  originalPermissions = new Permissions();

  // represents the state of the permission.
  permissionsState = {
    editSpace: Global_State.ENABLED,
    shareAudio: Global_State.ENABLED,
    shareVideo: Global_State.ENABLED,
    screenShare: Global_State.ENABLED,
  };
  permissionStatus = PermissionStatus;
  globalState = Global_State;
  permissionsChanged = false;
  securitySectionChanged = false;
  selectedVisibility$: BehaviorSubject<Visibility | undefined> = new BehaviorSubject<
    Visibility | undefined
  >(this.spaceRepo.activeSpace?.visibility);
  selectedWaitingRoom$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this.globalPermissionsService.isWaitingRoomEnabled,
  );
  isWaitingRoomEnforced$: Observable<boolean>;
  persistentPermissionEnabled$ = this.flagsService
    .featureFlagChanged(FLAGS.BREAKOUT_ROOMS)
    .pipe(untilDestroyed(this));

  currentPresentUsers: Set<string> = new Set();
  inCallUsers: Set<string> = new Set<string>();
  inCallParticipants = 0;
  presenceSub?: Subscription;
  isCallConnected = false;
  isCallReady = false;
  startOrJoinCall: 'Start Call' | 'Join Call' = 'Start Call';
  currentRoom?: Room;

  currentBreakoutRoomId?: string;

  canUseProFeature$ = this.paywallIndicatorService.canUseProFeature$;
  isCurrentUserOwnerOfSpace$ = this.spaceRepo.isCurrentUserOwnerOfSpace$;
  activeRoomUsers: SessionUser[] = [];
  selectedPermission: IPermission | undefined;
  usersMap: { [key: string]: User } = {};
  permissionsGroupMetadata = [
    {
      name: this.translateService.instant('Video / Call controls'),
      icon: 'videocam',
      permissions: [
        {
          fieldName: 'shareAudio',
          name: this.translateService.instant('Turn on microphone'),
          dataName: 'microphone-checkbox',
        },
        {
          fieldName: 'shareVideo',
          name: this.translateService.instant('Turn on camera'),
          dataName: 'camera-checkbox',
        },
        {
          fieldName: 'screenShare',
          name: this.translateService.instant('Screenshare'),
          dataName: 'screenshare-checkbox',
        },
      ],
    },
    {
      name: this.translateService.instant('Whiteboard controls'),
      icon: 'edit',
      permissions: [
        {
          fieldName: 'createBoards',
          name: this.translateService.instant('Create boards'),
          dataName: 'create-board-checkbox',
        },
        {
          fieldName: 'editSpace',
          name: this.translateService.instant('Edit boards'),
          dataName: 'edit-boards-checkbox',
        },
      ],
    },
    {
      name: this.translateService.instant('Chat controls'),
      icon: 'chat',
      permissions: [
        {
          fieldName: 'messageEveryone',
          name: this.translateService.instant('Message everyone in this Space'),
          dataName: 'message-everyone-checkbox',
        },
        {
          fieldName: 'messageOtherParticipants',
          name: this.translateService.instant('Message other participants'),
          dataName: 'message-participants-checkbox',
        },
      ],
    },
    {
      name: this.translateService.instant('App controls'),
      svg: 'web-bottom-left-toolbar',
      hideForFreeUsers: true,
      permissions: [
        {
          fieldName: 'insertWebViewer',
          name: this.translateService.instant('Insert Web Viewer'),
          dataName: 'insert-web-viewer-checkbox',
          hidden: !this.paywallIndicatorService.isWebViewerEnabled(),
        },
      ],
    },
  ];

  isRequestAccessEnabled: boolean;
  visibilityMenuOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  permissionsEnforcedByInstitution: { [key: string]: Observable<boolean> };

  private readonly DEBOUNCE_TIME = 5000;
  private domListener = this.domListenerFactoryService.createInstance();

  constructor(
    public attendanceManagerService: AttendanceManagerService,
    public sessionCallTracksService: SessionCallTracksService,
    public participantsManagerService: ParticipantsManagerService,
    public sessionSharedDataService: SessionSharedDataService,
    private realtimeSpaceService: RealtimeSpaceService,
    private userService: UserService,
    private flagsService: FlagsService,
    private deviceManagerService: DevicesManagerService,
    private cd: ChangeDetectorRef,
    private providerState: ProviderStateService,
    private presenceProvider: PresenceProvider,
    public spaceRepo: SpaceRepository,
    public rtcServiceController: RtcServiceController,
    public videoCallTracksStateService: VideoCallTracksStateService,
    private devicesManagerService: DevicesManagerService,
    private translateService: TranslateService,
    private notificationToasterService: NotificationToasterService,
    private localTracksManager: LocalTracksManagerService,
    private spacesService: SpacesService,
    public paywallIndicatorService: PaywallIndicatorService,
    public requestAccessService: RequestAccessService,
    public waitingRoomService: WaitingRoomService,
    private domListenerFactoryService: DomListenerFactoryService,
    private globalPermissionsService: GlobalPermissionsService,
  ) {
    this.isRequestAccessEnabled = this.flagsService.isFlagEnabled(FLAGS.REQUEST_ACCESS_ENABLED);

    this.providerState.callConnected$.pipe(untilDestroyed(this)).subscribe((val) => {
      this.isCallConnected = val;
    });

    this.providerState.callReady$.pipe(untilDestroyed(this)).subscribe((val) => {
      this.isCallReady = val;
    });

    combineLatest([
      this.spaceRepo.spaceVisibility$,
      this.globalPermissionsService.isWaitingRoomEnabled$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([visibility, isWaitingRoomEnabled]) => {
        this.selectedVisibility$.next(visibility);
        this.selectedWaitingRoom$.next(isWaitingRoomEnabled);
        this.checkSecuritySectionChange();
      });
    this.isWaitingRoomEnforced$ = this.globalPermissionsService.isWaitingRoomEnforced$;
    this.permissionsEnforcedByInstitution =
      this.globalPermissionsService.permissionsEnforcedByInstitution;
  }

  ngAfterViewInit(): void {
    const tabsContainer = document.getElementById('tabsDiv'); // replace with your container selector
    this.domListener.add(
      tabsContainer,
      'wheel',
      (event: any) => {
        event.preventDefault();
        tabsContainer && (tabsContainer.scrollLeft += event.deltaY + event.deltaX);
      },
      true,
    );
  }

  userDevicesStates$ = this.flagsService
    .featureFlagChanged(FLAGS.CALL_TRACKS_V2)
    .pipe(
      switchMap((enabled) =>
        enabled
          ? this.videoCallTracksStateService.usersDevicesStates$
          : this.sessionCallTracksService.usersDevicesStates$,
      ),
    );

  inCallUsers$ = combineLatest([
    this.spaceRepo.activeSpace$,
    this.spaceRepo.activeSpaceCurrentRoomUid$,
  ]).pipe(
    switchMap(([activeSpace, activeSpaceCurrentRoomUid]) =>
      this.presenceProvider.getCallPresenceActivity(
        activeSpace?._id as string,
        activeSpaceCurrentRoomUid,
      ),
    ),
    map((presentUsers) => presentUsers ?? new Set()),
    tap((presentUsers) => {
      this.startOrJoinCall = presentUsers?.size > 0 ? 'Join Call' : 'Start Call';
      this.inCallUsers = presentUsers ?? new Set();
      this.calcInCallParticipants();
    }),
  );

  viewModel$ = combineLatest([this.userDevicesStates$, this.inCallUsers$]).pipe(
    map(([userDevicesStates, inCallUsers]) => ({ userDevicesStates, inCallUsers })),
  );

  totalAccessRequestsSize$ = combineLatest([
    this.requestAccessService.accessRequestersSize$,
    this.waitingRoomService.accessRequestersSize$,
  ]).pipe(
    untilDestroyed(this),
    map(([accessRequestersSize, waitingRoomSize]) => accessRequestersSize + waitingRoomSize),
  );

  closePanel(): void {
    this.sessionSharedDataService.changeRightPanelView.next(undefined);
  }

  openSection(section: Section): void {
    // added so that listeners to this observable be updated
    this.sessionSharedDataService.changeParticipantsManagerSection.next(section);
  }

  ngOnInit(): void {
    if (!this.sessionSharedDataService.changeParticipantsManagerSection.value) {
      this.sessionSharedDataService.changeParticipantsManagerSection.next(Section.CONTROLS);
    }
    this.deviceManagerService.openingDeviceModal.pipe(untilDestroyed(this)).subscribe((value) => {
      this.loadingDeviceModal = value;
    });

    if (!this.currentUser) {
      this.subscribers.push(
        this.userService.user.pipe(untilDestroyed(this)).subscribe((res: UserData | null) => {
          if (res?.user) {
            this.currentUser = res.user;
          }
        }),
      );
    }
    combineLatest([this.spaceRepo.activeSpace$, this.spaceRepo.activeSpaceCurrentRoomUid$])
      .pipe(untilDestroyed(this))
      .subscribe(([activeSpace, activeSpaceCurrentRoomUid]) => {
        if (!activeSpace) {
          return;
        }

        if (!activeSpaceCurrentRoomUid) {
          return;
        }

        if (
          activeSpace._id !== this.sessionId ||
          activeSpaceCurrentRoomUid !== this.currentBreakoutRoomId
        ) {
          this.sessionId = activeSpace._id;
          this.currentBreakoutRoomId = activeSpaceCurrentRoomUid;
          this.handleSpaceOrBreakoutRoomChange();
        }

        this.isHost = Session.isOwnedByUser(activeSpace, this.userService.user?.value?.user);

        if (!this.isHost) {
          this.sessionSharedDataService.changeParticipantsManagerSection.next(Section.CONTROLS);
          this.selectedPermission = undefined;
          this.closePanel();
        }

        activeSpace.users?.forEach((user) => {
          this.rolesMap[user._id] = user;
        });

        activeSpace.populatedUsers?.forEach((user) => {
          this.usersMap[user._id] = user;
        });
        this.activeRoomUsers = activeSpace.users?.filter(
          (user) =>
            !!this.usersMap[user._id] &&
            this.currentUser?._id !== user._id &&
            user.roomId === activeSpaceCurrentRoomUid &&
            !user.isOwner,
        );
        this.calcInCallParticipants();
        this.updatePresentUsers();
      });
    this.listenToCurrentRoom();

    this.totalAccessRequestsSize$
      .pipe(untilDestroyed(this), distinctUntilChanged(), startWith(0), pairwise())
      .subscribe(([oldSize, newSize]) => {
        if (
          oldSize !== 0 &&
          newSize === 0 &&
          this.sessionSharedDataService.changeParticipantsManagerSection.value ===
            Section.ACCESS_REQUESTS
        ) {
          // size dropped to 0 --> requests tab will disappear and go to controls tab
          this.sessionSharedDataService.changeParticipantsManagerSection.next(Section.CONTROLS);
        }
      });
  }

  private handleSpaceOrBreakoutRoomChange() {
    this.listenForSpacePresence();
  }

  listenForSpacePresence(): void {
    this.presenceSub?.unsubscribe();
    this.presenceSub = this.presenceProvider
      .getRoomPresenceActivity(this.sessionId, this.currentBreakoutRoomId)
      .pipe(untilDestroyed(this))
      .subscribe((presentUsers) => {
        this.currentPresentUsers = presentUsers ?? new Set();
        this.updatePresentUsers();
      });
  }

  listenToCurrentRoom(): void {
    combineLatest([
      this.spaceRepo?.activeSpaceCurrentRoom$,
      this.globalPermissionsService.spacePermissions$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([room, spacePermissions]) => {
        if (room) {
          this.currentRoom = cloneDeep(room);
          if (this.currentRoom.permissions) {
            // set currentPermissions from the room and override the permissions that we get from DB
            this.currentPermissions = this.globalPermissionsService.mergeRoomAndSpacePermissions(
              this.currentRoom.permissions,
              spacePermissions,
            );
            const defaultPermissions = new Permissions();
            Object.keys(new Permissions()).forEach((permission) => {
              if (typeof this.currentPermissions[permission] === 'undefined') {
                this.currentPermissions[permission] =
                  defaultPermissions[permission as keyof Permissions];
              }
            });
            this.originalPermissions = {
              ...defaultPermissions,
              ...cloneDeep(this.currentPermissions),
            };
          }
          this.permissionsChanged = false;
          this.cd.detectChanges();
        }
      });
  }

  updateParticipantsPermissions(session: Session | ISession, newPermissions: Permissions): void {
    session?.users.forEach((user) => {
      if (!user.isOwner) {
        user.userPermissions = { ...newPermissions };
      }
    });
    this.spaceRepo.updateSpace(session._id, session);
  }

  updatePresentUsers(): void {
    this.activeHostUsers = [];
    this.activeParticipantsUsers = [];
    this.inactiveUsers = [];
    this.spaceRepo.activeSpace?.populatedUsers?.forEach((userObject) => {
      if (this.currentPresentUsers.has(userObject._id)) {
        if (this.rolesMap[userObject._id]?.isOwner) {
          this.activeHostUsers.push(userObject);
        } else {
          this.activeParticipantsUsers.push(userObject);
        }
      } else {
        this.inactiveUsers.push(userObject);
      }
    });
    this.activeHostUsers.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else {
        return 1;
      }
    });
    this.activeParticipantsUsers.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else {
        return 1;
      }
    });
    this.cd.markForCheck();
  }

  toggleDevice(device: DEVICE): void {
    switch (device) {
      case DEVICE.CAM:
        this.localTracksManager.toggleDeviceState(DeviceType.VIDEO, true);
        break;
      case DEVICE.MIC:
        this.localTracksManager.toggleDeviceState(DeviceType.AUDIO, true);
        break;
    }
  }

  calcInCallParticipants(): void {
    let inCallParticipants = 0;
    for (const userId of this.inCallUsers) {
      if (!this.rolesMap[userId]?.isOwner) {
        inCallParticipants += 1;
      }
    }
    this.inCallParticipants = inCallParticipants;
  }

  ngOnDestroy(): void {
    this.subscribers.forEach((sub) => sub.unsubscribe());
  }

  turnOffDeviceForAllParticipants(device: string): void {
    if (!this.isHost || !this.isCallConnected) {
      return;
    }

    const event = new SessionEvent(
      EventCategory.ParticipantsManager,
      ParticipantsManagerEvent.TURNOFF_ALL,
      { device },
    );
    this.realtimeSpaceService.service.sendEvent(this, event);
  }

  requestAllParticipantsToTurnOnDevice(device: DEVICE): void {
    if (!this.isHost || !this.isCallConnected) {
      return;
    }

    const event = new SessionEvent(
      EventCategory.ParticipantsManager,
      ParticipantsManagerEvent.TURN_ON_REQUEST_ALL,
      { device },
    );
    this.realtimeSpaceService.service.sendEvent(this, event);

    this.showRequestAllParticipantsToTurnOnDeviceSentNotification(device);
  }

  showRequestAllParticipantsToTurnOnDeviceSentNotification(device: DEVICE): void {
    if (![DEVICE.MIC, DEVICE.CAM].includes(device)) {
      return;
    }
    const topElement = new IconMessageToasterElement(
      { icon: 'check', size: 16 },
      this.translateService.instant('Request sent'),
    );

    const messages = {
      [DEVICE.CAM]: this.translateService.instant(
        'We’ve notified all participants to turn on their cameras',
      ),
      [DEVICE.MIC]: this.translateService.instant(
        'We’ve notified all participants to turn on their microphones',
      ),
    };
    const middleElement = new IconMessageToasterElement(undefined, messages[device]);

    const codes = {
      [DEVICE.CAM]: SUCCESSES.REQUEST_ALL_TO_TURN_ON_CAMERA_SENT,
      [DEVICE.MIC]: SUCCESSES.REQUEST_ALL_TO_UNMUTE_SENT,
    };

    const notificationDataBuilder = new NotificationDataBuilder(codes[device])
      .type(NotificationType.SUCCESS)
      .style(ToasterPopupStyle.SUCCESS)
      .showProgressBar(true)
      .priority(380)
      .timeOut(5)
      .topElements([topElement])
      .middleElements([middleElement]);

    this.notificationToasterService.showNotification(notificationDataBuilder.build());
  }

  sendPermissionUpdates(): void {
    if (this.securitySectionChanged) {
      this.applyChangeVisibility();
      this.applyToggleWaitingRoom();
    }
    if (!this.permissionsChanged) {
      return;
    }
    if (this.currentRoom) {
      this.updateSessionPermissions(this.currentRoom.permissions);
      this.realtimeSpaceService.service.modifyRoom(this.currentRoom, this.sessionId);
    }
    this.permissionsChanged = false;
  }

  /**
   * update space permissions in DB
   * the filter get the modified permissions stored in DB only to avoid unnecessary back end calls
   */
  updateSessionPermissions(newPermissions: Permissions): void {
    const filteredPermissions = this.globalPermissionsService.filterPermissionsFromDB(
      newPermissions,
      this.originalPermissions,
    );
    if (Object.keys(filteredPermissions).length === 0) {
      this.showNotification();
      return;
    }
    const spaceID = this.spaceRepo.activeSpace?._id;
    if (!spaceID) {
      return;
    }
    this.spacesService
      .updateSessionPermissions(spaceID, newPermissions, true)
      .pipe(
        take(1),
        catchError((error) => this.handleInstitutionPermissionsUpdateError()),
      )
      .subscribe({
        next: (res) => {
          this.spaceRepo.updateSpace(spaceID, { sessionPermissions: newPermissions });
          this.checkPermissionChange();
          this.showNotification();
        },
        error: (err) => {},
      });
  }

  cancelPermissionUpdate() {
    if (this.securitySectionChanged) {
      this.selectedVisibility$.next(this.spaceRepo.activeSpace?.visibility);
      this.selectedWaitingRoom$.next(this.globalPermissionsService.isWaitingRoomEnabled);
      this.securitySectionChanged = false;
    }
    if (!this.permissionsChanged) {
      return;
    }
    this.permissionsChanged = false;
    this.currentPermissions = cloneDeep(this.originalPermissions);
    this.permissionsGroupMetadata = cloneDeep(this.permissionsGroupMetadata);
  }

  joinCall(): void {
    this.devicesManagerService.logJoinCallButtonClickedToFullStory();
    this.sessionSharedDataService.dispatchUserStartsCallRequest();
  }

  updateIndividualPermission(event: MatCheckboxChange, user: SessionUser) {
    if (this.selectedPermission) {
      if (typeof this.currentPermissions[this.selectedPermission.fieldName] === 'boolean') {
        const value = this.currentPermissions[this.selectedPermission.fieldName];
        this.currentPermissions[this.selectedPermission.fieldName] = {};
        this.activeRoomUsers.forEach((roomUser) => {
          if (this.selectedPermission) {
            this.currentPermissions[this.selectedPermission.fieldName][roomUser._id] = value;
          }
        });
      }
      this.currentPermissions[this.selectedPermission.fieldName][user._id] = event.checked;
      this.currentPermissions = getUpdatedPermissions(
        this.currentPermissions,
        this.selectedPermission.fieldName,
      );
      this.checkPermissionChange();
    }
  }

  updateEveryonePermission(event: MatCheckboxChange, selectedPermission: string) {
    this.currentPermissions[selectedPermission] = event.checked;
    this.checkPermissionChange();
  }

  checkPermissionChange() {
    this.permissionsChanged = !isEqual(this.originalPermissions, this.currentPermissions);
  }

  checkSecuritySectionChange() {
    this.securitySectionChanged =
      this.selectedVisibility$.value !== this.spaceRepo.activeSpace?.visibility ||
      this.selectedWaitingRoom$.value !== this.globalPermissionsService.isWaitingRoomEnabled;
  }

  private showNotification() {
    const message = this.translateService.instant('Permissions updated');
    const messageIcon = { svgIcon: 'check', size: 18 };
    const topElement = new IconMessageToasterElement(
      messageIcon,
      message,
      undefined,
      undefined,
      undefined,
      IconBackground.SUCCESS,
      true,
      true,
      'flex-shrink: 0;',
    );
    const notificationData = new NotificationDataBuilder(SUCCESSES.PERMISSIONS_UPDATED)
      .type(NotificationType.SUCCESS)
      .style(ToasterPopupStyle.SUCCESS)
      .topElements([topElement])
      .dismissable(false)
      .priority(560)
      .timeOut(5)
      .width(304)
      .onActivateTick(true)
      .version2Notification(true)
      .showProgressBar(true)
      .progressBarColor('#BBD8FF')
      .build();
    this.notificationToasterService.showNotification(notificationData);
  }

  changeVisibility(visibility: Visibility) {
    this.selectedVisibility$.next(visibility);
    this.checkSecuritySectionChange();
  }

  toggleWaitingRoom(event: MatCheckboxChange) {
    this.selectedWaitingRoom$.next(event.checked);
    this.checkSecuritySectionChange();
  }

  applyChangeVisibility() {
    const visibility = this.selectedVisibility$.value;
    if (!visibility || visibility === this.spaceRepo.activeSpace?.visibility) {
      return;
    }
    const spaceID = this.spaceRepo.activeSpace?._id;
    if (!spaceID) {
      return;
    }

    this.spacesService
      .updateVisibility(spaceID, visibility)
      .pipe(timeout(this.DEBOUNCE_TIME), first())
      .subscribe({
        next: (res) => {
          this.spaceRepo.updateSpace(spaceID, { visibility: visibility });
          this.checkSecuritySectionChange();
          this.showNotification();
        },
        error: (err) => {
          this.checkSecuritySectionChange();
        },
      });
  }

  applyToggleWaitingRoom() {
    const newIsWaitingRoomEnabled = this.selectedWaitingRoom$.value;
    if (newIsWaitingRoomEnabled === this.globalPermissionsService.isWaitingRoomEnabled) {
      return;
    }
    const spaceID = this.spaceRepo.activeSpace?._id;
    if (!spaceID) {
      return;
    }

    const settings: SpaceSettings = {
      enableWaitingRoom: newIsWaitingRoomEnabled,
    };
    this.spacesService
      .updateSettings(spaceID, settings, true)
      .pipe(
        timeout(this.DEBOUNCE_TIME),
        first(),
        catchError((error) => this.handleWaitingRoomInstitutionPermissionsError(error)),
      )
      .subscribe({
        next: (res) => {
          const newSettings = this.buildCompleteSettinsgObj(settings);
          this.spaceRepo.updateSpace(spaceID, { settings: newSettings });
          this.checkSecuritySectionChange();
          this.showNotification();
        },
        error: (err) => {},
      });
  }

  buildCompleteSettinsgObj(settings: SpaceSettings): SpaceSettings {
    const space = this.spaceRepo.activeSpace;
    if (!space || !space.settings) {
      return settings;
    }

    const settingsWithOnlyDefinedVals: Partial<SpaceSettings> = Object.fromEntries(
      Object.entries(settings).filter(([_, val]) => val != null),
    );

    return { ...space.settings, ...settingsWithOnlyDefinedVals };
  }

  /**
   * handle unauthorized waiting room update error due to changes in institution permissions.
   * update the current session with latest version of institution permissions.
   */
  private handleWaitingRoomInstitutionPermissionsError(error: any) {
    if (error.error.institutionPermissions) {
      const activeSpace = this.spaceRepo.activeSpace;
      if (activeSpace?.institution) {
        const updatedInstitution: Institution = {
          ...activeSpace.institution,
          permissions: { ...(error.error.institutionPermissions ?? {}) },
        };
        this.spaceRepo.updateSpace(activeSpace._id, { institution: updatedInstitution });
        this.selectedWaitingRoom$.next(this.globalPermissionsService.isWaitingRoomEnabled);
      }
      this.showInstitutionPermissionsUpdateNotification();
    }
    this.checkSecuritySectionChange();
    return EMPTY;
  }

  /**
   * for now we are not handling edge cases for session permissions and just
   * show the warning notification to the host
   */
  private handleInstitutionPermissionsUpdateError() {
    this.showInstitutionPermissionsUpdateNotification();
    this.checkPermissionChange();
    return EMPTY;
  }

  private showInstitutionPermissionsUpdateNotification() {
    const title = this.translateService.instant('Permissions update failed');
    const titleIcon = { svgIcon: 'warning_amber_outline', size: 18 };
    const message = this.translateService.instant(
      'You cannot update this permission because it has now been set by your institution and cannot be modified',
    );
    const warningTitle = new IconMessageToasterElement(
      titleIcon,
      title,
      undefined,
      undefined,
      undefined,
      IconBackground.WARNING,
      true,
      true,
      'flex-shrink: 0;',
    );
    const warningMessage = new IconMessageToasterElement(undefined, message);

    const notificationData = new NotificationDataBuilder(WARNINGS.INSTITUTION_PERMISSIONS_UPDATED)
      .type(NotificationType.WARNING)
      .style(ToasterPopupStyle.WARN)
      .topElements([warningTitle])
      .middleElements([warningMessage])
      .width(304)
      .dismissable(true)
      .priority(560)
      .timeOut(5)
      .onActivateTick(true)
      .version2Notification(true)
      .showProgressBar(true)
      .progressBarColor('#BBD8FF')
      .build();
    this.notificationToasterService.showNotification(notificationData);
  }

  onPermissionClick(permission: any) {
    // if the permission in not controlled by the institution
    if (!this.globalPermissionsService.isPermissionEnforcedByInstitution(permission.fieldName)) {
      this.selectedPermission = permission;
    }
  }
}

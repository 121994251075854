import { Injectable } from '@angular/core';
import Konva from 'konva';
import { Image } from 'konva/lib/shapes/Image';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { TrackEvent, TrackEventAction, TrackType } from '../common/interfaces/rtc-interface';
import { VideoLayout } from '../sessions/session/wb-video-call-participant-id/wb-video-call-participant-id.component';
import { User } from '../models/user';
import { ShortNamePipe } from '../pipes/short-name.pipe';
import { environment } from '../../environments/environment';
import { DeviceType } from '../common/utils/devices-handle-util';
import { SessionCallTracksService, VideoTrackType } from './session-call-tracks.service';
import { ProviderStateService } from './provider-state.service';
import { SessionSharedDataService } from './session-shared-data.service';
import { VideoCallTracksStateService } from './video-call-tracks-state.service';
import { FLAGS, FlagsService } from './flags.service';
import { SpacePermissionsManagerService } from './space-permissions-manager.service';
import { LocalTracksManagerService } from './local-tracks-manager.service';
import { TelemetryService } from './telemetry.service';

// Each call participant can have two popout tiles (video and screen)
interface PopoutParticipantTiles {
  videoTile: PopoutTile;
  screenTile: PopoutTile;
}

interface PopoutTile {
  user: User;
  streamElement?: HTMLVideoElement; // video DOM element of the tile stream it is set when the video is active or screen is shared but not for not active video.
  trackType: VideoTrackType;
  localUser: boolean; // Used to mirror local participant's video.
  visible: boolean; // true if the tile is currently visible in the popout.
  active: boolean; // true if this tile is active but not necessarily visible used specially for screen tiles.
  // Location of the tile in the layout canvas.
  x?: number;
  y?: number;
  // Used to show mute icon.
  isMuted: boolean;
  muteIconRef?: Konva.Path;
  // Used to show blue border for currently speaking participants.
  blueBorderRef?: Konva.Rect;
}

export enum PopoutLayout {
  GRID = 'grid',
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum PopoutSupportStatus {
  FULL_SUPPORT = 'full_supported',
  NO_CONTROLS = 'no_controls',
  NOT_SUPPORTED = 'not_supported',
}

abstract class PopoutLayoutBuilder {
  protected konvaService: KonvaService;
  protected numberOfUserTilesPages: number;
  protected abstract maxNumOfTilesPerPage: number;
  protected currentTilesPageIndex: number;
  protected numOfTilesInCurrentPage: number;

  constructor(konvaService: KonvaService) {
    this.konvaService = konvaService;
    this.numberOfUserTilesPages = 1;
    this.currentTilesPageIndex = 0;
    this.numOfTilesInCurrentPage = 0;
  }

  // build the layout by putting each tile in its absolute position on the canvas.
  abstract buildLayout(): void;
  // handle PIP resize event to change the number of tiles dynamically in order to achieve each tile at least 100x100 px.
  abstract onPopoutWindowResize(pipWindow): boolean;

  hasNextPage(): boolean {
    return this.currentTilesPageIndex < this.numberOfUserTilesPages - 1;
  }

  hasPreviousPage(): boolean {
    return this.currentTilesPageIndex > 0;
  }

  nextPage(): void {
    this.currentTilesPageIndex += 1;
    this.konvaService.updateCallSubscriptions();
    this.konvaService._rerenderPopout();
  }

  previousPage(): void {
    this.currentTilesPageIndex -= 1;
    this.konvaService.updateCallSubscriptions();
    this.konvaService._rerenderPopout();
  }

  gotToFirstPage(): void {
    this.currentTilesPageIndex = 0;
    this.konvaService.updateCallSubscriptions();
    this.konvaService._rerenderPopout();
  }

  getPageStartAndEndIndex(totalNumberOfTiles: number): [number, number] {
    const startIndex = this.currentTilesPageIndex * this.maxNumOfTilesPerPage;
    const endIndex = Math.min(startIndex + this.maxNumOfTilesPerPage, totalNumberOfTiles);
    this.numberOfUserTilesPages = Math.ceil(totalNumberOfTiles / this.maxNumOfTilesPerPage);
    this.numOfTilesInCurrentPage = endIndex - startIndex;
    return [startIndex, endIndex];
  }
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class KonvaService {
  private stage: Konva.Stage | undefined;
  private layer: Konva.Layer | undefined;
  private anim: Konva.Animation | undefined;
  private allTiles: { [key: string]: PopoutParticipantTiles } = {};

  private readonly FPS = 15;
  // delta variable is used to accumulate the time difference between every two consecutive konva animation frames.
  // we update the canvas only when the delta is greater than the draw period to get 15 FPS.
  private delta = 0;
  private readonly drawPeriod = 1000 / this.FPS;

  /*
  We cannot control the height and width of the PIP and we cannot know what they will be before requesting entering PIP
  from the browser we can only control the aspect ratio of the video shown in the PIP.
  Depending on the aspect ratio and user's screen size the browser decide what will be the dimensions of the PIP.
  So the solution is to assume that the pip is a square of 600 x 600 (this number can be anything I just used 600 because
  it has many divisors which make it easy for hand calculations).
  Examples:
  1 tile: aspect ratio = 1 -> height = width = 600 and each tile is 600x600.
  ......
  .    .
  ......
  2 tiles: aspect ratio = 2:1 -> width = 600, height = 300 and each tile is 300x300.
  ............
  .    .     .
  ............
  4 tiles: aspect ratio = 1:1 -> width = 600, height = 600 and each tile is 300x300.
  ............
  .    .     .
  ............
  .    .     .
  ............

  ...
   */
  private readonly popoutLen = 600;
  private tileLen;

  // This is the current builder depending on the layout (grid, horizontal, vertical, ....)
  private layoutBuilder?: PopoutLayoutBuilder;

  private readonly micSVGMuteIcon =
    'm34.3 29.95-2.15-2.15q1.05-1.3 1.55-2.925.5-1.625.5-3.325h3q0 2.3-.75 4.45-.75 2.15-2.15 3.95ZM23.05 18.7Zm4.85 4.85-2.65-2.6V9.05q0-.85-.6-1.45T23.2 7q-.85 0-1.45.6t-.6 1.45v7.75l-3-3V9.05q0-2.1 1.475-3.575T23.2 4q2.1 0 3.575 1.475T28.25 9.05v12.5q0 .4-.075 1t-.275 1ZM21.7 42v-6.8q-5.3-.55-8.9-4.45-3.6-3.9-3.6-9.2h3q0 4.55 3.225 7.65 3.225 3.1 7.775 3.1 1.9 0 3.65-.625t3.2-1.725l2.15 2.15q-1.55 1.3-3.45 2.075-1.9.775-4.05 1.025V42Zm19.85 3.25L1.8 5.5l1.9-1.9 39.75 39.75Z';
  private readonly screenSVGMuteIcon =
    'm40.65 45.2-6.6-6.6q-1.4 1-3.025 1.725-1.625.725-3.375 1.125v-3.1q1.15-.35 2.225-.775 1.075-.425 2.025-1.125l-8.25-8.3V40l-10-10h-8V18h7.8l-11-11L4.6 4.85 42.8 43Zm-1.8-11.6-2.15-2.15q1-1.7 1.475-3.6.475-1.9.475-3.9 0-5.15-3-9.225-3-4.075-8-5.175v-3.1q6.2 1.4 10.1 6.275 3.9 4.875 3.9 11.225 0 2.55-.7 5t-2.1 4.65Zm-6.7-6.7-4.5-4.5v-6.5Q30 17 31.325 19.2q1.325 2.2 1.325 4.8 0 .75-.125 1.475-.125.725-.375 1.425Zm-8.5-8.5-5.2-5.2 5.2-5.2Zm-3 14.3v-7.5l-4.2-4.2h-7.8v6h6.3Zm-2.1-9.6Z';

  private returnVideoLayout: VideoLayout = VideoLayout.RIGHT; // The return layout to go to after the pop-out closes.
  private returnToOldVideoLayout = true; // If true then return to the returnVideoLayout after pop-out closes.

  private readonly _popoutSupportStatus$: BehaviorSubject<PopoutSupportStatus>;
  public popoutSupportStatus$: Observable<PopoutSupportStatus>;

  private _camControlEnabled = false;
  private _micControlEnabled = false;

  // Used to prevent pausing the video element while another request to play it is executing
  private staticVideoElementPlayPromise?: Promise<void>;
  isVideoElementListenersSetup = false;

  isCallTracksV2Enabled = false;

  private readonly _isPopoutVideoActive$ = new BehaviorSubject(false);

  public isPopoutVideoActive$ = this._isPopoutVideoActive$.asObservable().pipe(
    tap((value) => {
      if (!value) {
        this.setPopoutSessionVars();
      }
    }),
  );

  public get isPopoutVideoActive(): boolean {
    return this._isPopoutVideoActive$.getValue();
  }

  constructor(
    private flagsService: FlagsService,
    private sessionSharedDataService: SessionSharedDataService,
    providerState: ProviderStateService,
    private sessionCallTracksService: SessionCallTracksService,
    private videoCallTracksStateService: VideoCallTracksStateService,
    private localTracksManager: LocalTracksManagerService,
    private permissionsService: SpacePermissionsManagerService,
    private telemetry: TelemetryService,
  ) {
    this.isCallTracksV2Enabled = this.flagsService.isFlagEnabled(FLAGS.CALL_TRACKS_V2);
    providerState.trackEvents$.pipe(untilDestroyed(this)).subscribe((e) => {
      if (e) {
        this.handleTrackEvent(e);
      }
    });
    this._popoutSupportStatus$ = new BehaviorSubject(KonvaService.getPopoutSupportStatus());
    this.popoutSupportStatus$ = this._popoutSupportStatus$.asObservable();

    // Reset on stop video call RTC
    providerState.callConnected$.subscribe((connected) => {
      if (!connected) {
        this.leaveCall();
      }
    });

    this.permissionsService.canOpenMic$.subscribe((canOpenMic) => {
      this.micControlEnabled = canOpenMic;
    });

    this.permissionsService.canOpenCam$.subscribe((canOpenCam) => {
      this.camControlEnabled = canOpenCam;
    });

    this.setPopoutSessionVars();
  }

  private setupVideoElementListeners() {
    const videoElement = document.getElementById('popout-video') as HTMLVideoElement;
    if (videoElement && !this.isVideoElementListenersSetup) {
      this.isVideoElementListenersSetup = true;
      // Handle when the popout video is closed.
      videoElement.addEventListener('leavepictureinpicture', () => this.onLeavePIP());
      videoElement.addEventListener('enterpictureinpicture', (e) => this.onEnterPIP(e));
    }
  }

  get popoutLayoutPreference(): PopoutLayout {
    const layoutPreference = localStorage.getItem('popout-layout-preference');
    if (!layoutPreference) {
      return PopoutLayout.GRID; // Default preference
    }
    return layoutPreference as PopoutLayout;
  }

  set popoutLayoutPreference(popoutLayout: PopoutLayout) {
    localStorage.setItem('popout-layout-preference', popoutLayout);
  }

  set micControlEnabled(micEnabled: boolean) {
    this._micControlEnabled = micEnabled;
    this.refreshControls();
  }

  set camControlEnabled(camEnabled: boolean) {
    this._camControlEnabled = camEnabled;
    this.refreshControls();
  }

  // Remove audio and video streams when the track stops
  // Note that those tracks are added in session-call-user-video-container to make sure that those tracks are played successfully.
  // Note also that we keep track of all participants even if the popout is not active.
  private handleTrackEvent(trackEvent: TrackEvent) {
    if (trackEvent.type === TrackType.AUDIO && trackEvent.action === TrackEventAction.STOP) {
      this.updateParticipantMuteIcon(
        this.allTiles[trackEvent.participant.participantId]?.videoTile,
        true,
      );
    } else if (trackEvent.action === TrackEventAction.STOP) {
      this.removeVideoStream(
        trackEvent.participant.participantId,
        trackEvent.type as VideoTrackType,
      );
    }
  }

  private static getPopoutSupportStatus(): PopoutSupportStatus {
    const pipSupported = (document as any).pictureInPictureEnabled ?? false;
    if (pipSupported) {
      const mediaSessionSupported = 'mediaSession' in navigator;
      const setCameraSupported =
        mediaSessionSupported && 'setCameraActive' in (navigator as any).mediaSession;
      const setMicSupported =
        mediaSessionSupported && 'setMicrophoneActive' in (navigator as any).mediaSession;
      if (setMicSupported && setCameraSupported) {
        return PopoutSupportStatus.FULL_SUPPORT;
      }
      return PopoutSupportStatus.NO_CONTROLS;
    } else {
      return PopoutSupportStatus.NOT_SUPPORTED;
    }
  }

  /**
   * Inserts the video/screenshare stream to the corresponding participant and rerender the popout video
   * if the popout video is active.
   * @param participantId
   * @param videoRef the reference of the of the html video element of the video stream.
   * @param trackType
   */
  insertVideoStream(
    participantId: string,
    videoRef: HTMLVideoElement,
    trackType: VideoTrackType,
  ): void {
    if (this._popoutSupportStatus$.getValue() === PopoutSupportStatus.NOT_SUPPORTED) {
      return;
    }
    if (participantId in this.allTiles) {
      if (trackType === TrackType.VIDEO) {
        this.allTiles[participantId].videoTile.streamElement = videoRef;
      } else {
        // Don't add user's screen tile to the local user.
        if (this.allTiles[participantId].screenTile.localUser) {
          return;
        }
        this.allTiles[participantId].screenTile.streamElement = videoRef;
        this.allTiles[participantId].screenTile.trackType = trackType;
      }
    }
    this._rerenderPopout();
  }

  /**
   * Remove the stream of the user using its participant id and rerender the popout video
   * if the popout video is active.
   * @param participantId
   * @param trackType
   */
  removeVideoStream(participantId: string, trackType: VideoTrackType): void {
    if (this._popoutSupportStatus$.getValue() === PopoutSupportStatus.NOT_SUPPORTED) {
      return;
    }
    if (participantId in this.allTiles) {
      if (trackType === TrackType.VIDEO) {
        this.allTiles[participantId].videoTile.streamElement = undefined;
      } else {
        this.allTiles[participantId].screenTile.streamElement = undefined;
      }
      this._rerenderPopout();
    }
  }

  /**
   * When a new participant joins the call add his tiles and rerender the popout.
   * @param user
   * @param participantId
   * @param localUser
   */
  participantJoined(user: User, participantId: string, localUser: boolean): void {
    if (this._popoutSupportStatus$.getValue() === PopoutSupportStatus.NOT_SUPPORTED) {
      return;
    }
    this.allTiles[participantId] = {
      videoTile: {
        user: user,
        localUser: localUser,
        trackType: TrackType.VIDEO,
        isMuted: true,
        visible: false,
        active: true,
      },
      screenTile: {
        user: user,
        localUser: localUser,
        trackType: TrackType.SCREEN,
        isMuted: true,
        visible: false,
        active: false,
      },
    };
    this._rerenderPopout();
  }

  /**
   * When a participant leaves the call removes its entry and rerender the popout.
   * @param participantId
   */
  participantLeft(participantId: string): void {
    if (this._popoutSupportStatus$.getValue() === PopoutSupportStatus.NOT_SUPPORTED) {
      return;
    }
    delete this.allTiles[participantId];
    this._rerenderPopout();
  }

  // add tile to the location x, y.
  // trackType = video, stream active => show video stream.
  // trackType = video, stream not active => show non-active tile (draw using konva).
  // trackType = screen, stream active => show screen stream.
  // trackType = screen, stream not active => ignore.
  private addTile(
    popoutTile: PopoutTile,
    x: number,
    y: number,
    width: number,
    height: number,
  ): void {
    popoutTile.x = x;
    popoutTile.y = y;
    if (
      popoutTile.localUser &&
      popoutTile.trackType === TrackType.VIDEO &&
      this._popoutSupportStatus$.getValue() === PopoutSupportStatus.FULL_SUPPORT
    ) {
      // set the camera status in media session to change the icon state in the pop-out for the local user.
      // camera on <-> camera off
      (navigator as any).mediaSession.setCameraActive(!!popoutTile.streamElement);
    }
    if (popoutTile.trackType === TrackType.VIDEO && !popoutTile.streamElement) {
      const background = new Konva.Rect({
        draggable: false,
        listening: false,
        x: x,
        y: y,
        fill: '#043160',
        width: width,
        height: height,
      });
      const circle = new Konva.Circle({
        radius: width / 4,
        draggable: false,
        listening: false,
        x: x + width / 2,
        y: y + height / 2,
        fill: 'rgba(255, 255, 255, 0.25)',
      });
      const shortNamePipe = new ShortNamePipe();
      const shortNameText = new Konva.Text({
        text: shortNamePipe.transform(popoutTile.user?.name),
        draggable: false,
        listening: false,
        x: x,
        y: y,
        fontFamily: 'Source Sans Pro',
        fontSize: (height * 0.45) / 2,
        fontStyle: '600',
        fill: '#FFFFFF',
        align: 'center',
        verticalAlign: 'middle',
        width: width,
        height: height,
        ellipsis: true,
      });
      this.layer?.add(background);
      this.layer?.add(circle);
      this.layer?.add(shortNameText);
      popoutTile.visible = true;
      this.updateParticipantMuteIcon(popoutTile, popoutTile.isMuted);
    } else if (popoutTile.streamElement) {
      const video = popoutTile.streamElement;
      const mirror = popoutTile.localUser;
      const videoAspectRatio = video.videoWidth / video.videoHeight;
      const wrappingGroup = new Konva.Group({
        draggable: false,
        listening: false,
        x: x - (height * videoAspectRatio - width) / 2,
        y: y,
        clipFunc: (ctx) => {
          ctx.rect((height * videoAspectRatio - width) / 2, 0, width, height);
        },
      });

      const image = new Image({
        image: video,
        draggable: false,
        listening: false,
        x: 0,
        y: 0,
        width: height * videoAspectRatio,
        height: height,
      });
      if (mirror) {
        wrappingGroup.scaleX(-1 * wrappingGroup.scaleX());
        wrappingGroup.offsetX(height * videoAspectRatio);
      }
      wrappingGroup.add(image);
      this.layer?.add(wrappingGroup);
      popoutTile.visible = true;
      this.updateParticipantMuteIcon(popoutTile, popoutTile.isMuted);
    }
  }

  /**
   *
   * @param layout
   * @param forceSubscribeToVideoTracksOnStart Used to subscribe video tracks that are in the first page once enabling the popout
   * @param returnVideoLayout
   */
  popoutVideo(
    layout: PopoutLayout,
    forceSubscribeToVideoTracksOnStart: boolean,
    returnVideoLayout?: VideoLayout,
  ): void {
    switch (layout) {
      case PopoutLayout.GRID:
        this.layoutBuilder = new this.GridLayoutBuilder(this);
        break;
      case PopoutLayout.HORIZONTAL:
        this.layoutBuilder = new this.HorizontalLayoutBuilder(this);
        break;
      case PopoutLayout.VERTICAL:
        this.layoutBuilder = new this.VerticalLayoutBuilder(this);
        break;
    }
    this.popoutLayoutPreference = layout;
    this._isPopoutVideoActive$.next(true);

    if (returnVideoLayout) {
      this.returnToOldVideoLayout = true;
      this.returnVideoLayout = returnVideoLayout;
    }
    if (forceSubscribeToVideoTracksOnStart) {
      this.updateCallSubscriptions();
    }
    // Used to setup the listeners for the static video element
    this.setupVideoElementListeners();
    this._rerenderPopout(true);

    this.setPopoutSessionVars(layout);
  }

  private setPopoutSessionVars(layout: PopoutLayout | null = null): void {
    this.telemetry.setSessionVars({
      pop_out_mode_active: layout,
    });
  }

  // Used to initialize all required parameters
  private initPopoutParams() {
    // Create new konva stage and give it the div container id (Hidden div element in the background)
    this.stage = new Konva.Stage({
      container: 'konva-container',
      listening: false,
    });
    // Add a new layer to the stage (a layer is a canvas element konva use to render its objects)
    this.layer = new Konva.Layer({
      listening: false,
    });
    this.stage.add(this.layer);
    // This function is executed in each animation frame. for performance we only draw the canvas
    // when the time since last draw is more than the draw period -> to achieve 15 FPS.
    this.anim = new Konva.Animation((frame) => {
      if (!frame) {
        return false;
      }
      if (frame.timeDiff + this.delta > this.drawPeriod) {
        this.delta = 0;
        return true;
      } else {
        this.delta += frame.timeDiff;
        return false;
      }
    }, this.layer);
    this.insertDefinedParticipantsDevicesStates();
  }

  // Used to insert all defined icons (Mute/blue border) that were added before activating popout
  private insertDefinedParticipantsDevicesStates() {
    Object.values(this.allTiles).forEach((popoutParticipantTiles) => {
      if (popoutParticipantTiles.videoTile.muteIconRef) {
        this.layer?.add(popoutParticipantTiles.videoTile.muteIconRef);
      }
      if (popoutParticipantTiles.screenTile.muteIconRef) {
        this.layer?.add(popoutParticipantTiles.screenTile.muteIconRef);
      }
      if (popoutParticipantTiles.videoTile.blueBorderRef) {
        this.layer?.add(popoutParticipantTiles.videoTile.blueBorderRef);
      }
      if (popoutParticipantTiles.screenTile.blueBorderRef) {
        this.layer?.add(popoutParticipantTiles.screenTile.blueBorderRef);
      }
    });
  }

  async _rerenderPopout(reinitKnova = false) {
    if (!this.isPopoutVideoActive) {
      return;
    }
    // Remove the old konva layer
    this.cleanKonva(reinitKnova);
    if (reinitKnova) {
      // Init all requried parameters for popout
      this.initPopoutParams();
    }
    // build the new konva layer
    this.layoutBuilder?.buildLayout();
    // Convert the canvas that konva is rendering the videos on to a single stream (All the video streams now is a single stream)
    const videoStream = this.requestVideoStream();
    // Play this videoStream in an HTMLVideoElement that is hidden in the background.
    const videoElement = document.getElementById('popout-video') as HTMLVideoElement;
    if (this.staticVideoElementPlayPromise) {
      await this.staticVideoElementPlayPromise;
    }
    videoElement.srcObject = videoStream;
    this.staticVideoElementPlayPromise = videoElement.play();
    if (this._popoutSupportStatus$.getValue() === PopoutSupportStatus.FULL_SUPPORT) {
      this.refreshControls();
    }
    // After the video is loaded request this video to be viewed as picture in picture.
    videoElement.onloadedmetadata = () => {
      (videoElement as any).requestPictureInPicture().catch(() => {
        if (this.isPopoutVideoActive) {
          this._popoutSupportStatus$.next(PopoutSupportStatus.NOT_SUPPORTED);
        }
        this.closePopoutVideo(true);
      });
    };
  }

  private addBackgroundRect(width: number, height: number): void {
    const background = new Konva.Rect({
      draggable: false,
      listening: false,
      x: 0,
      y: 0,
      fill: '#333333',
      width: width,
      height: height,
    });
    this.layer?.add(background);
  }

  private requestVideoStream(): MediaStream {
    return (this.layer?.getNativeCanvasElement() as any).captureStream(this.FPS);
  }

  closePopoutVideo(returnToOldVideoLayout: boolean): void {
    // This function force the pop-out to close and after that the calls disconnects so we don't
    // want to go to the right view after the pop-out closes so that we set returnToOldVideoLayout to false.
    this.returnToOldVideoLayout = returnToOldVideoLayout;
    if ((document as any).pictureInPictureElement) {
      (document as any).pictureInPictureElement.removeEventListener('resize');
      (document as any).exitPictureInPicture();
    }

    this.setPopoutSessionVars();
  }

  leaveCall(): void {
    this.closePopoutVideo(true);
    this.allTiles = {};
  }

  private onEnterPIP(pipEvent): void {
    if (!this._isPopoutVideoActive$.getValue()) {
      this.closePopoutVideo(false);
    }
    const pipWindow = pipEvent.pictureInPictureWindow;
    if (pipWindow) {
      pipWindow.addEventListener('resize', (pipResizeEvent) => {
        this.layoutBuilder?.onPopoutWindowResize(pipResizeEvent.currentTarget);
      });
    }
  }

  private refreshControls(): void {
    this.removeCallControls();
    this.setUpCallControls();
  }

  private setUpCallControls(): void {
    const actionHandlers: [string, () => void][] = [];
    if (this._micControlEnabled) {
      actionHandlers.push(
        // toggle microphone
        [
          'togglemicrophone',
          () => {
            this.localTracksManager.toggleDeviceState(DeviceType.AUDIO, true);
          },
        ],
      );
    }
    if (this._camControlEnabled) {
      actionHandlers.push(
        // toggle camera
        [
          'togglecamera',
          () => {
            this.localTracksManager.toggleDeviceState(DeviceType.VIDEO, true);
          },
        ],
      );
    }
    if (this.layoutBuilder?.hasPreviousPage()) {
      actionHandlers.push(
        // previous track
        [
          'previoustrack',
          () => {
            this.layoutBuilder?.previousPage();
          },
        ],
      );
    }
    if (this.layoutBuilder?.hasNextPage()) {
      actionHandlers.push(
        // next track
        [
          'nexttrack',
          () => {
            this.layoutBuilder?.nextPage();
          },
        ],
      );
    }
    for (const [action, handler] of actionHandlers) {
      try {
        (navigator as any).mediaSession.setActionHandler(action, handler);
      } catch (error) {
        if (!environment.production) {
          // Media session and some of its functions is not supported yet for all browsers ex: firefox
          // Please refer for this link for more info https://developer.mozilla.org/en-US/docs/Web/API/MediaSession
          console.log(`The media session action "${action}" is not supported yet.`);
        }
      }
    }
  }

  private removeCallControls(): void {
    const actions = ['togglemicrophone', 'togglecamera', 'nexttrack', 'previoustrack'];
    for (const action of actions) {
      try {
        (navigator as any).mediaSession.setActionHandler(action, undefined);
      } catch (error) {
        if (!environment.production) {
          // Media session and some of its functions is not supported yet for all browsers ex: firefox
          // Please refer for this link for more info https://developer.mozilla.org/en-US/docs/Web/API/MediaSession
          console.log(`The media session action "${action}" is not supported yet.`);
        }
      }
    }
  }

  private async onLeavePIP() {
    this._isPopoutVideoActive$.next(false);
    this.cleanKonva(true);
    const videoElement = document.getElementById('popout-video') as HTMLVideoElement;
    if (this.staticVideoElementPlayPromise) {
      await this.staticVideoElementPlayPromise;
    }
    videoElement.pause();
    this.staticVideoElementPlayPromise = undefined;
    videoElement.removeAttribute('src'); // empty source
    if (this.returnToOldVideoLayout) {
      this.sessionSharedDataService.controlsLayout.next(this.returnVideoLayout);
    }
  }

  private cleanKonva(disposeKonva = false): void {
    this.anim?.stop();
    Object.values(this.allTiles).forEach((popoutParticipantTiles) => {
      popoutParticipantTiles.videoTile.visible = false;
      popoutParticipantTiles.screenTile.visible = false;
      // Reset all participants devices states on deactivating popout
      // Otherwise keep them to be added immediately
      if (disposeKonva) {
        popoutParticipantTiles.videoTile.muteIconRef = undefined;
        popoutParticipantTiles.screenTile.muteIconRef = undefined;
        popoutParticipantTiles.videoTile.blueBorderRef = undefined;
        popoutParticipantTiles.screenTile.blueBorderRef = undefined;
      }
    });
    this.layer?.destroyChildren();
    // Reset popout parameters on deactivating popout
    if (disposeKonva) {
      this.layer?.destroy();
      this.stage?.destroyChildren();
      this.stage?.destroy();
      this.anim = undefined;
      this.layer = undefined;
      this.stage = undefined;
      this.removeVideoElementListeners();
      if (this._popoutSupportStatus$.getValue() === PopoutSupportStatus.FULL_SUPPORT) {
        this.removeCallControls();
      }
    }
  }

  private removeVideoElementListeners() {
    const videoElement = document.getElementById('popout-video') as HTMLVideoElement;
    if (videoElement) {
      videoElement.removeEventListener('leavepictureinpicture', this.onLeavePIP);
      videoElement.removeEventListener('enterpictureinpicture', this.onEnterPIP);
      this.isVideoElementListenersSetup = false;
    }
  }

  // show/hide mute icon this is done on the same canvas without redrawing the canvas each time.
  updateParticipantMuteIcon(popoutTile: PopoutTile | undefined, showMuteIcon: boolean): void {
    if (
      this._popoutSupportStatus$.getValue() === PopoutSupportStatus.NOT_SUPPORTED ||
      !popoutTile
    ) {
      return;
    }

    popoutTile.isMuted = showMuteIcon;
    if (
      popoutTile.localUser &&
      popoutTile.trackType === TrackType.VIDEO &&
      this._popoutSupportStatus$.getValue() === PopoutSupportStatus.FULL_SUPPORT
    ) {
      // set the mic status in media session to change the icon state in the pop-out for the local user
      // mic on <-> mic off
      (navigator as any).mediaSession.setMicrophoneActive(!popoutTile.isMuted);
    }

    if (!popoutTile.visible) {
      return;
    }
    if (showMuteIcon) {
      if (popoutTile.muteIconRef) {
        // The mute icon is defined in this canvas so show it.
        popoutTile.muteIconRef.visible(true);
      } else {
        // first time to draw the mute icon on this canvas so load it and show it.

        // Two svgs one for video tile and the other one for screen tile.
        const svgData =
          popoutTile.trackType === TrackType.VIDEO ? this.micSVGMuteIcon : this.screenSVGMuteIcon;
        // Icon location is 5% of length from left and top.
        const muteIcon = new Konva.Path({
          data: svgData,
          draggable: false,
          listening: false,
          x: (popoutTile.x || 0) + 0.95 * this.tileLen,
          y: (popoutTile.y || 0) + 0.05 * this.tileLen,
          scaleX: 0.5,
          scaleY: 0.5,
          fill: '#FFFFFF',
        });
        muteIcon.offsetX(muteIcon.getSelfRect().width); // change x offset to put the icon in the top right corner 5% from top and right.
        // Store the ref for future use on the same canvas.
        popoutTile.muteIconRef = muteIcon;
        this.layer?.add(muteIcon);
      }
    } else {
      // Hide the mute icon if it is defined.
      popoutTile.muteIconRef?.visible(false);
    }
  }

  getPopOutTile(participantId: string, trackType: VideoTrackType): PopoutTile {
    if (trackType === TrackType.VIDEO) {
      return this.allTiles[participantId]?.videoTile;
    } else {
      return this.allTiles[participantId]?.screenTile;
    }
  }

  // show/hide blue border depending on user's speaking state this is done on the same canvas without redrawing the canvas each time.
  updateParticipantBlueBorder(
    participantId: string,
    trackType: VideoTrackType,
    showBlueBorder: boolean,
  ): void {
    if (
      this._popoutSupportStatus$.getValue() === PopoutSupportStatus.NOT_SUPPORTED ||
      !this.allTiles[participantId]
    ) {
      return;
    }
    if (showBlueBorder) {
      let curTile: PopoutTile;
      if (trackType === TrackType.VIDEO) {
        curTile = this.allTiles[participantId].videoTile;
      } else {
        curTile = this.allTiles[participantId].screenTile;
      }
      if (!curTile.visible) {
        return;
      }
      if (curTile.blueBorderRef) {
        // The blue border is defined in this canvas so show it.
        curTile.blueBorderRef.visible(true);
      } else if (Number.isFinite(curTile.x) && Number.isFinite(curTile.y)) {
        // first time to draw the blue border on this canvas so load it and show it.

        // draw blue frame with stroke width 3% of the tile len.
        const borderRect = new Konva.Rect({
          stroke: '#2F80ED',
          strokeWidth: 0.03 * this.tileLen,
          x: (curTile.x || 0) + 0.015 * this.tileLen,
          y: (curTile.y || 0) + 0.015 * this.tileLen,
          width: 0.97 * this.tileLen,
          height: 0.97 * this.tileLen,
        });
        // Store the ref for future use on the same canvas.
        curTile.blueBorderRef = borderRect;
        this.layer?.add(borderRect);
      }
    } else {
      // Hide the blue border if it is defined.
      if (trackType === TrackType.VIDEO) {
        this.allTiles[participantId].videoTile.blueBorderRef?.visible(false);
      } else {
        this.allTiles[participantId].screenTile.blueBorderRef?.visible(false);
      }
    }
  }

  updateCallSubscriptions(): void {
    if (!this.layoutBuilder) {
      return;
    }
    const videos: PopoutTile[] = [];
    const screens: PopoutTile[] = [];
    // Get all tiles sorted (screen tiles then video tiles)
    Object.values(this.allTiles).forEach((popoutParticipantTiles) => {
      if (popoutParticipantTiles.videoTile.active) {
        videos.push(popoutParticipantTiles.videoTile);
      }
      if (popoutParticipantTiles.screenTile.active) {
        screens.push(popoutParticipantTiles.screenTile);
      }
    });
    const allTilesSorted = screens.concat(videos);
    const [startIndex, endIndex] = this.layoutBuilder.getPageStartAndEndIndex(
      allTilesSorted.length,
    );
    let idx = 0;
    // Update visibility for all the tiles in current page (between start index and end index)
    allTilesSorted.forEach((popoutTile) => {
      popoutTile.visible = idx >= startIndex && idx < endIndex;
      idx += 1;
    });
    // Update call subscription for all the tiles.
    Object.keys(this.allTiles).forEach((participantId) => {
      const videoTile = this.allTiles[participantId].videoTile;
      const screenTile = this.allTiles[participantId].screenTile;
      if (!videoTile.localUser) {
        this.handleVideoTrackSubscription(participantId, videoTile, false);
      }
      if (!screenTile.localUser) {
        this.handleVideoTrackSubscription(participantId, screenTile, false);
      }
    });
  }

  private handleVideoTrackSubscription(participantId: string, tile: PopoutTile, isLocal: boolean) {
    if (this.isCallTracksV2Enabled) {
      this.videoCallTracksStateService.updateTrackSubscription(
        participantId,
        tile.trackType,
        tile.visible,
        this.isPopoutVideoActive,
        isLocal,
      );
    } else {
      this.sessionCallTracksService.updateCallSubscriptions(
        participantId,
        tile.trackType,
        tile.visible,
      );
    }
  }

  updateScreenActiveState(participantId: string, active: boolean): void {
    if (!this.allTiles[participantId]) {
      return;
    }
    const oldState = this.allTiles[participantId].screenTile.active;
    this.allTiles[participantId].screenTile.active = active;
    if (oldState !== active) {
      this.updateCallSubscriptions();
    }
  }

  getAllTilesSorted(): PopoutTile[] {
    const videos: PopoutTile[] = [];
    const screens: PopoutTile[] = [];
    Object.values(this.allTiles).forEach((popoutParticipantTiles) => {
      if (popoutParticipantTiles.videoTile.active) {
        videos.push(popoutParticipantTiles.videoTile);
      }
      if (
        popoutParticipantTiles.screenTile.active &&
        !popoutParticipantTiles.screenTile.localUser
      ) {
        screens.push(popoutParticipantTiles.screenTile);
      }
    });
    return screens.concat(videos);
  }

  // Grid Layout.
  GridLayoutBuilder = class extends PopoutLayoutBuilder {
    protected maxNumOfTilesPerPage: number;

    constructor(konvaService: KonvaService) {
      super(konvaService);
      this.maxNumOfTilesPerPage = 16;
    }

    buildLayout(): void {
      const allTilesSorted = this.konvaService.getAllTilesSorted();
      const [startIndex, endIndex] = this.getPageStartAndEndIndex(allTilesSorted.length);
      const numberOfTiles = this.numOfTilesInCurrentPage;
      let height;
      let width;
      let tileLen;
      // Show the tiles in a nice way depending on the number of tiles
      if (numberOfTiles === 1) {
        height = width = tileLen = this.konvaService.popoutLen;
      } else if (numberOfTiles <= 2) {
        width = this.konvaService.popoutLen;
        height = this.konvaService.popoutLen / 2;
        tileLen = this.konvaService.popoutLen / 2;
      } else if (numberOfTiles <= 4) {
        width = this.konvaService.popoutLen;
        height = this.konvaService.popoutLen;
        tileLen = this.konvaService.popoutLen / 2;
      } else if (numberOfTiles <= 6) {
        width = this.konvaService.popoutLen;
        height = (this.konvaService.popoutLen * 2) / 3;
        tileLen = this.konvaService.popoutLen / 3;
      } else if (numberOfTiles <= 9) {
        width = this.konvaService.popoutLen;
        height = this.konvaService.popoutLen;
        tileLen = this.konvaService.popoutLen / 3;
      } else if (numberOfTiles <= 12) {
        width = this.konvaService.popoutLen;
        height = (this.konvaService.popoutLen * 3) / 4;
        tileLen = this.konvaService.popoutLen / 4;
      } else {
        width = this.konvaService.popoutLen;
        height = this.konvaService.popoutLen;
        tileLen = this.konvaService.popoutLen / 4;
      }
      /*
        To build the pop-out layout we need to add gaps between tiles those gaps are 3% of tile width
        so for each tile we remove 1.5% of each of its sides to build the frame.
        Two tiles next to each other will have total of 3% of gap
        The total height will be sum of tiles heights + 3% of tile height to handle the boundary frame.
        The total width will be sum of tiles widths + 3% of tile width to handle the boundary frame.
       */
      this.konvaService.stage?.height(height + 0.03 * tileLen);
      this.konvaService.stage?.width(width + 0.03 * tileLen);
      this.konvaService.tileLen = tileLen * 0.97; // The length of the stream tile is 97% of the full tile with gaps.
      this.konvaService.addBackgroundRect(width + 0.03 * tileLen, height + 0.03 * tileLen);
      allTilesSorted.forEach((popoutTile) => (popoutTile.visible = false));
      let itr = startIndex;
      // x, y represents the position of the tile including the gaps in the pop-out
      for (let x = 0.015 * tileLen; x < width; x += tileLen) {
        for (let y = 0.015 * tileLen; y < height; y += tileLen) {
          if (itr === endIndex) {
            break;
          }
          // Tile is added 1.5% from x and 1.5% from the center y to skip the gap and then from the new center the stream is drawn
          this.konvaService.addTile(
            allTilesSorted[itr],
            x + 0.015 * tileLen,
            y + 0.015 * tileLen,
            0.97 * tileLen,
            0.97 * tileLen,
          );
          itr++;
        }
      }
      this.konvaService.anim?.start();
    }

    onPopoutWindowResize(pipWindow): boolean {
      if (pipWindow) {
        const maxVerticalTiles = Math.floor(pipWindow.height / 100);
        const maxHorizontalTiles = Math.floor(pipWindow.width / 100);
        const oldMaxNumOfTilesPerPage = this.maxNumOfTilesPerPage;
        this.maxNumOfTilesPerPage = maxHorizontalTiles * maxVerticalTiles;
        if (
          this.numOfTilesInCurrentPage > this.maxNumOfTilesPerPage ||
          this.maxNumOfTilesPerPage > oldMaxNumOfTilesPerPage
        ) {
          this.gotToFirstPage();
          return true;
        }
      }
      return false;
    }
  };

  // Horizontal Layout
  HorizontalLayoutBuilder = class extends PopoutLayoutBuilder {
    protected maxNumOfTilesPerPage: number;
    private MAX_NUM_OF_HORIZONTAL_TILES = 6;

    constructor(konvaService: KonvaService) {
      super(konvaService);
      this.maxNumOfTilesPerPage = 6;
    }

    buildLayout(): void {
      const allTilesSorted = this.konvaService.getAllTilesSorted();
      const [startIndex, endIndex] = this.getPageStartAndEndIndex(allTilesSorted.length);
      const numberOfTiles = endIndex - startIndex;
      const height = this.konvaService.popoutLen / numberOfTiles;
      const width = this.konvaService.popoutLen;
      const tileLen = this.konvaService.popoutLen / numberOfTiles;
      /*
        To build the pop-out layout we need to add gaps between tiles those gaps are 3% of tile width
        so for each tile we remove 1.5% of each of its sides to build the frame.
        Two tiles next to each other will have total of 3% of gap
        The total height will be sum of tiles heights + 3% of tile height to handle the boundary frame.
        The total width will be sum of tiles widths + 3% of tile width to handle the boundary frame.
       */
      this.konvaService.stage?.height(height + 0.03 * tileLen);
      this.konvaService.stage?.width(width + 0.03 * tileLen);
      this.konvaService.tileLen = tileLen * 0.97; // The length of the stream tile is 97% of the full tile with gaps.
      this.konvaService.addBackgroundRect(width + 0.03 * tileLen, height + 0.03 * tileLen);
      let itr = startIndex;
      // x, y represents the position of the tile including the gaps in the pop-out
      for (let x = 0.015 * tileLen; x < width; x += tileLen) {
        for (let y = 0.015 * tileLen; y < height; y += tileLen) {
          if (itr === endIndex) {
            break;
          }
          // Tile is added 1.5% from x and 1.5% from the center y to skip the gap and then from the new center the stream is drawn
          this.konvaService.addTile(
            allTilesSorted[itr],
            x + 0.015 * tileLen,
            y + 0.015 * tileLen,
            0.97 * tileLen,
            0.97 * tileLen,
          );
          itr++;
        }
      }
      this.konvaService.anim?.start();
    }

    onPopoutWindowResize(pipWindow): boolean {
      if (pipWindow) {
        // TODO extend it dynamically to more tiles depending on user's screen and set the aspect ratio
        // 100 is the min width
        const maxHorizontalTiles = Math.floor(pipWindow.width / 100);
        const oldMaxNumOfTilesPerPage = this.maxNumOfTilesPerPage;
        this.maxNumOfTilesPerPage = Math.min(maxHorizontalTiles, this.MAX_NUM_OF_HORIZONTAL_TILES);
        if (
          this.numOfTilesInCurrentPage > this.maxNumOfTilesPerPage ||
          this.maxNumOfTilesPerPage > oldMaxNumOfTilesPerPage
        ) {
          this.gotToFirstPage();
          return true;
        }
      }
      return false;
    }
  };

  // Vertical Layout
  VerticalLayoutBuilder = class extends PopoutLayoutBuilder {
    protected maxNumOfTilesPerPage: number;
    private MAX_NUM_OF_VERTICAL_TILES = 2;
    constructor(konvaService: KonvaService) {
      super(konvaService);
      this.maxNumOfTilesPerPage = 2;
    }

    buildLayout(): void {
      const allTilesSorted = this.konvaService.getAllTilesSorted();
      const [startIndex, endIndex] = this.getPageStartAndEndIndex(allTilesSorted.length);
      const numberOfTiles = endIndex - startIndex;
      const height = this.konvaService.popoutLen;
      const width = this.konvaService.popoutLen / numberOfTiles;
      const tileLen = this.konvaService.popoutLen / numberOfTiles;
      /*
        To build the pop-out layout we need to add gaps between tiles those gaps are 3% of tile width
        so for each tile we remove 1.5% of each of its sides to build the frame.
        Two tiles next to each other will have total of 3% of gap
        The total height will be sum of tiles heights + 3% of tile height to handle the boundary frame.
        The total width will be sum of tiles widths + 3% of tile width to handle the boundary frame.
       */
      this.konvaService.stage?.height(height + 0.03 * tileLen);
      this.konvaService.stage?.width(width + 0.03 * tileLen);
      this.konvaService.tileLen = tileLen * 0.97; // The length of the stream tile is 97% of the full tile with gaps.
      this.konvaService.addBackgroundRect(width + 0.03 * tileLen, height + 0.03 * tileLen);
      let itr = startIndex;
      // x, y represents the position of the tile including the gaps in the pop-out
      for (let x = 0.015 * tileLen; x < width; x += tileLen) {
        for (let y = 0.015 * tileLen; y < height; y += tileLen) {
          if (itr === endIndex) {
            break;
          }
          // Tile is added 1.5% from x and 1.5% from the center y to skip the gap and then from the new center the stream is drawn
          this.konvaService.addTile(
            allTilesSorted[itr],
            x + 0.015 * tileLen,
            y + 0.015 * tileLen,
            0.97 * tileLen,
            0.97 * tileLen,
          );
          itr++;
        }
      }
      this.konvaService.anim?.start();
    }

    onPopoutWindowResize(pipWindow): boolean {
      if (pipWindow) {
        // TODO extend it dynamically to more tiles depending on user's screen and set the aspect ratio
        // 100 is the tile min height
        const maxVerticalTiles = Math.floor(pipWindow.height / 100);
        const oldMaxNumOfTilesPerPage = this.maxNumOfTilesPerPage;
        this.maxNumOfTilesPerPage = Math.min(maxVerticalTiles, this.MAX_NUM_OF_VERTICAL_TILES);
        if (
          this.numOfTilesInCurrentPage > this.maxNumOfTilesPerPage ||
          this.maxNumOfTilesPerPage > oldMaxNumOfTilesPerPage
        ) {
          this.gotToFirstPage();
          return true;
        }
      }
      return false;
    }
  };
}

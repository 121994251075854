import { Injectable } from '@angular/core';
import {
  auditTime,
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  map,
  Observable,
  switchMap,
} from 'rxjs';
import { filterNil } from '@ngneat/elf';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Frame, FramePrivacy, ISession as ISpace, Session as Space } from '../models/session';
import { User } from '../models/user';
import { SpaceRepository } from '../state/space.repository';
import { UserService } from './user.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SpaceBoardsService {
  constructor(private spaceRepo: SpaceRepository, private userService: UserService) {}

  public get activeSpaceSelectedBoard(): Frame | undefined {
    if (!this.spaceRepo.activeSpace?.selectedBoardUid) {
      return;
    }

    return getBoard(
      this.spaceRepo.activeSpace?.selectedBoardUid,
      this.getActiveSpaceCurrentRoomAccessibleBoards(),
    );
  }

  public readonly activeSpaceSelectedBoard$: Observable<Frame | undefined> = combineLatest([
    this.getActiveSpaceCurrentRoomAccessibleBoards$(),
    this.spaceRepo.activeSpace$,
  ])
    .pipe(
      map(([boards, activeSpace]) => {
        if (!activeSpace?.selectedBoardUid) {
          return;
        }

        return getBoard(activeSpace.selectedBoardUid, boards);
      }),
    )
    .pipe(distinctUntilChanged());

  public readonly currentBoardLocked$ = this.activeSpaceSelectedBoard$.pipe(
    map((activeSpaceSelectedBoard) => activeSpaceSelectedBoard?.locked ?? false),
  );
  public readonly activeSpaceSelectedBoardLocked$ = this.activeSpaceSelectedBoard$.pipe(
    filterNil(),
    map((board) => Boolean(board.locked)),
    distinctUntilChanged(),
  );

  public get activeSpaceSelectedBoardIndex(): number {
    if (!this.spaceRepo.activeSpace?.selectedBoardUid) {
      return -1;
    }
    return getIndexOfBoard(
      this.spaceRepo.activeSpace.selectedBoardUid,
      this.getActiveSpaceCurrentRoomAccessibleBoards(),
    );
  }

  public readonly activeSpaceSelectedBoardIndex$: Observable<number> = combineLatest([
    this.getActiveSpaceCurrentRoomAccessibleBoards$(),
    this.spaceRepo.activeSpace$,
  ])
    .pipe(auditTime(0))
    .pipe(
      map(([boards, space]) => {
        if (!space?.selectedBoardUid) {
          return -1;
        }
        return getIndexOfBoard(space.selectedBoardUid, boards);
      }),
    )
    .pipe(distinctUntilChanged());

  /**
   * Sets the selected board for a space
   * @param spaceId
   * @param selectedBoardUid
   * @returns
   */
  private setSpaceSelectedBoard(spaceId: string, selectedBoardUid: string | undefined): void {
    const space = this.spaceRepo.getSpace(spaceId);
    const boards = this.getCurrentRoomAccessibleBoards(spaceId);
    const boardFound = getIndexOfBoard(selectedBoardUid, boards) !== -1;

    if (space) {
      this.spaceRepo._setSpaceSelectedBoard(space._id, boardFound ? selectedBoardUid : undefined);
    }

    return;
  }

  /**
   * Sets the boardUID for the active space
   * @param selectedBoardUid
   */
  public setActiveSpaceSelectedBoard(selectedBoardUid: string | undefined): void {
    if (this.spaceRepo.activeSpace) {
      this.setSpaceSelectedBoard(this.spaceRepo.activeSpace._id, selectedBoardUid);
    }
  }

  public getActiveSpaceSelectedBoard(): Frame | undefined {
    if (this.spaceRepo.activeSpaceId) {
      return this.getCurrentRoomAccessibleBoards(this.spaceRepo.activeSpaceId).find(
        (r) => r.uid === this.spaceRepo.activeSpaceSelectedBoardUid,
      );
    }

    return undefined;
  }

  public get activeSpaceCurrentRoomFrames$(): Observable<Frame[]> {
    return combineLatest([
      this.spaceRepo.activeSpaceId$,
      this.spaceRepo.activeSpaceCurrentRoom$,
    ]).pipe(
      switchMap(([activeSpaceId, currentRoom]) => {
        if (!activeSpaceId || currentRoom?.uid === undefined) {
          return EMPTY;
        }
        return this.spaceRepo.spaceFrames$(activeSpaceId, currentRoom.uid);
      }),
    );
  }

  public get activeSpaceCurrentRoomFrames(): Frame[] {
    const activeSpaceId = this.spaceRepo.activeSpaceId;
    const currentRoomUID = this.spaceRepo.activeSpaceCurrentRoomUid;

    if (!activeSpaceId || currentRoomUID === undefined) {
      return [];
    }

    // Optional access is in case any of the keys don't exist
    return this.spaceRepo.getSpaceFrames(activeSpaceId, currentRoomUID);
  }

  /**
   * check if we have only one public frame
   * and that frame is the frame parameter
   * used to prevent changing board access of the last public board or deleting it
   * @param frame
   */
  isFrameLastPublicFrame(frame: Frame): boolean {
    const frames = this.getActiveSpaceCurrentRoomAccessibleBoards();
    if (!frames) {
      return false;
    }
    const publicFrames = frames.filter((f) => !f.privacy || f.privacy === FramePrivacy.PUBLIC);
    return publicFrames.length === 1 && publicFrames[0].uid === frame.uid;
  }

  public getSpaceCurrentRoomFrames(spaceId: string): Frame[] {
    const currentRoomUID = this.spaceRepo.getSpace(spaceId)?.currentRoomUid;

    if (currentRoomUID === undefined) {
      return [];
    }

    // Optional access is in case any of the keys don't exist

    return this.spaceRepo.getSpaceFrames(spaceId, currentRoomUID);
  }

  /**
   * Creates an observable that returns the boards a user can
   * currently access
   * assuming:
   *  the active space
   *  the current room
   *
   * @returns Observable<Board[]>
   */
  public getActiveSpaceCurrentRoomAccessibleBoards$(): Observable<Frame[]> {
    return combineLatest([
      this.userService.user,
      this.spaceRepo.activeSpace$,
      this.activeSpaceCurrentRoomFrames$,
    ]).pipe(
      map(([userData, activeSpace, boards]) => {
        if (!activeSpace || !userData?.user) {
          return [];
        }
        return getAccessibleBoards(userData.user, activeSpace, boards);
      }),
    );
  }

  /**
   * Gets the boards that a user can currently access
   * assuming:
   *  the active space
   *  the current room
   *
   * @returns Board[]
   */
  public getActiveSpaceCurrentRoomAccessibleBoards(): Frame[] {
    const { _id } = this.spaceRepo.activeSpace ?? {};
    if (!_id) {
      return [];
    }

    return this.getCurrentRoomAccessibleBoards(_id);
  }

  /**
   * check if a board with specific uid exists in the current boards for the current room
   * and returns the frame
   * @param uid
   */
  public getBoardInCurrentRoomById(uid: string | undefined): Frame | undefined {
    if (uid) {
      return this.getActiveSpaceCurrentRoomAccessibleBoards()
        .find((frame) => frame.uid === uid);
    }

    return undefined;
  }

  /**
   * Gets the boards that a user can currently access
   * assuming:
   *  the current room for the space
   * @param spaceId
   * @returns
   */
  public getCurrentRoomAccessibleBoards(spaceId: string): Frame[] {
    const space = this.spaceRepo.getSpace(spaceId);
    const user = this.userService.user.getValue()?.user;

    // remove the ones that the user does not have access to
    if (!user || !space) {
      return [];
    }

    return getAccessibleBoards(user, space, this.activeSpaceCurrentRoomFrames);
  }
}

export const getAccessibleBoards = (user: User, space: ISpace, boards: Frame[]): Frame[] =>
  boards.filter((board) => Space.doesUserHaveAccessToBoard(space, board, user));

const getIndexOfBoard = (boardUid: string | undefined, boards: Frame[]): number =>
  boards.findIndex((board) => board.uid === boardUid);

const getBoard = (boardUID: string, boards: Frame[]): Frame | undefined =>
  boards.find((b) => b.uid === boardUID);

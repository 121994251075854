<button
  [class.selected]="selected"
  [class.disabled]="disabled"
  [disabled]="disabled"
  [type]="buttonType"
  (click)="handleClick($event)"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</button>

<ng-template #content>
  <ng-content></ng-content>
  <ng-container *ngIf="isContentEmpty">
    <span *ngIf="label && !iconOnLeft" class="label">{{ label }}</span>

    <app-pencil-icon
      *ngIf="icon"
      [name]="icon"
      [type]="iconConfig.type"
      [outlined]="iconConfig.outlined"
      [wrapperStyle]="iconConfig.wrapperStyle"
      class="pencil-icon"
    ></app-pencil-icon>

    <span *ngIf="label && iconOnLeft" class="label">{{ label }}</span>
  </ng-container>
</ng-template>

import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IFilterAPI } from 'src/app/standalones/components/generic-filters-view/filters.interface';
import { AclService } from 'src/app/services/acl.service';
import { Site, User } from '../../models/user';
import { SpacesService } from '../../services/spaces.service';
import { UiService } from '../../services/ui.service';
import { UserService } from '../../services/user.service';

interface SpaceItem {
  _id: string;
  title: string;
  owner: User;
}

@UntilDestroy()
@Component({
  selector: 'app-select-space-dialog',
  templateUrl: './select-space-dialog.component.html',
  styleUrls: ['./select-space-dialog.component.scss'],
})
export class SelectSpaceDialogComponent implements OnInit {
  spaces: SpaceItem[] = [];
  isMobile = false;
  isRTL = false;
  user?: User;

  currentSpacesPage = 1;
  spacesLimit = 10;
  totalPages = 1;
  isFetchingSpaces = false;
  searchValue = '';
  canCreateSpace = false;
  sites = this.data?.sites;
  @ViewChild('spacesList') spacesList!: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<SelectSpaceDialogComponent>,
    private spacesService: SpacesService,
    private UIService: UiService,
    private userService: UserService,
    private aclService: AclService,
    @Inject(MAT_DIALOG_DATA)
    private data?: { sites?: Site[] },
  ) {}

  ngOnInit(): void {
    this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
      this.user = res?.user;
      if (this.user) {
        this.canCreateSpace = this.aclService.checkUserCanCreateSpace(this.user);
      }
      this.fetchSpaces();
    });
    this.UIService.isMobile.pipe(untilDestroyed(this)).subscribe((res) => (this.isMobile = res));
    this.userService.rtl.pipe(untilDestroyed(this)).subscribe((res) => {
      this.isRTL = res;
    });
  }

  fetchSpaces(): void {
    const filters: IFilterAPI = {
      query: this.searchValue,
      ...(this.sites?.length ? { sites: this.sites.map((site) => site._id) } : {}),
      ...(this.user?.institution ? { institution: this.user.institution._id } : {}),
      ...(this.user && !this.userShouldSeeAllSpacesEvenIfNotHost()
        ? { hosts: [this.user._id] }
        : {}),
    };

    this.isFetchingSpaces = true;
    this.spacesService
      .getSpacesList(filters, this.currentSpacesPage, this.spacesLimit, undefined, true)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.spaces = [
          ...this.spaces,
          ...res.sessions.map((session) => ({
            ...session,
            owner: session.populatedUsers.find((user) => user?._id === session.owner),
          })),
        ];
        this.totalPages = res.totalPages;
        this.isFetchingSpaces = false;
      });
  }

  private userShouldSeeAllSpacesEvenIfNotHost(): boolean {
    return (
      !!this.user &&
      !!this.user.institution?.settings?.addAdminsAsHostsInSpace &&
      (this.aclService.isAdmin(this.user) || this.aclService.isSiteAdmin(this.user))
    );
  }

  filterSpaces(event): void {
    const searchValue = event.target.value.toLowerCase().trim();

    // prevent doing search for the same search value
    if (searchValue === this.searchValue) {
      return;
    }

    this.searchValue = searchValue;
    this.currentSpacesPage = 1;
    this.spaces = [];
    this.fetchSpaces();
  }

  selectItem(spaceItem: SpaceItem): void {
    if (this.isMobile) {
      this.save(spaceItem);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(spaceItem: SpaceItem): void {
    const space = this.spaces.find((s) => s._id === spaceItem._id);
    this.dialogRef.close(space);
  }

  showCreateSpaceDialog(): void {
    this.dialogRef.close('showCreateSpaceDialog');
  }

  /**
   * once user scrolled to the bottom, we fetch spaces
   */
  onScroll(): void {
    const spacesListElement = this.spacesList?.nativeElement;

    const atBottom =
      spacesListElement.scrollHeight -
        spacesListElement.scrollTop -
        spacesListElement.clientHeight <
      10;

    if (atBottom && this.totalPages > this.currentSpacesPage && !this.isFetchingSpaces) {
      this.currentSpacesPage++;
      this.fetchSpaces();
    }
  }
}

import { AfterViewInit, Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { AudioService } from 'src/app/services/audio.service';
import { DevicesManagerService } from 'src/app/services/devices-manager.service';
import { RtcServiceController } from 'src/app/services/rtc.service';
import {
  DisableIncomingVideosSource,
  SessionSharedDataService,
} from 'src/app/services/session-shared-data.service';
import { UiService } from 'src/app/services/ui.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Intercom } from 'ng-intercom';
import { DomListenerFactoryService } from 'src/app/services/dom-listener-factory.service';
import { DeviceErrorsNotificationsService } from 'src/app/services/device-errors-notifications.service';
import { PROMPTS } from 'src/app/common/utils/call-states-constants';
import { DeviceAndBrowserDetectorService } from 'src/app/services/device-and-browser-detector.service';
import { DeviceErrorType } from 'src/app/models/device-manger';
import { VirtualBackgroundInsertableStreamService } from 'src/app/services/virtual-background-insertable-stream.service';
import { LocalTracksManagerService } from 'src/app/services/local-tracks-manager.service';
import { TelemetryService } from 'src/app/services/telemetry.service';
import {
  DevicesSettingsBaseComponent,
  DevicesType,
} from '../devices-settings/devices-settings-base.component';

@UntilDestroy()
@Component({
  selector: 'app-space-device-modal',
  templateUrl: './space-device-modal.component.html',
  styleUrls: ['./space-device-modal.component.scss'],
})
export class SpaceDeviceModalComponent
  extends DevicesSettingsBaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  readonly NO_INPUT = ' (No input detected)';

  deviceErrorType = DeviceErrorType;
  deviceType = DevicesType;

  isPlayingSpeakerTest = false;

  isSettingDevices = false;

  disableVideos = false;

  disableVideoStreamsSubMsg = '';
  disabledThroughNotification = false;

  isComponentDestroyed = false;

  prompts = PROMPTS;

  constructor(
    private dialogRef: MatDialogRef<SpaceDeviceModalComponent>,
    sessionSharedDataService: SessionSharedDataService,
    ngZone: NgZone,
    rtcServiceController: RtcServiceController,
    audioService: AudioService,
    devicesManagerService: DevicesManagerService,
    uiService: UiService,
    deviceDetectorService: DeviceDetectorService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      returnBackToJoinCallModal: boolean;
      deviceErrorType?: DeviceErrorType;
    },
    intercom: Intercom,
    translateService: TranslateService,
    domListenerFactoryService: DomListenerFactoryService,
    deviceAndBrowserDetectorService: DeviceAndBrowserDetectorService,
    deviceErrorsNotificationsService: DeviceErrorsNotificationsService,
    virtualBackgroundInsertableStreamService: VirtualBackgroundInsertableStreamService,
    localTrackManagerService: LocalTracksManagerService,
    telemetryService: TelemetryService,
  ) {
    super(
      domListenerFactoryService,
      deviceDetectorService,
      uiService,
      rtcServiceController,
      devicesManagerService,
      ngZone,
      deviceAndBrowserDetectorService,
      deviceErrorsNotificationsService,
      audioService,
      intercom,
      PROMPTS.SPACE_DEVICE_MODAL,
      translateService,
      virtualBackgroundInsertableStreamService,
      sessionSharedDataService,
      localTrackManagerService,
      telemetryService,
      true,
    );

    this.disabledThroughNotification =
      this.sessionSharedDataService.disabledThroughWeakNotification;
    this.sessionSharedDataService.disableVideoStreamChanged
      .pipe(untilDestroyed(this))
      .subscribe((newDisableVideoStreams) => {
        this.disableVideos = newDisableVideoStreams.disabled;
      });

    this.handleDisableVideoStreamsSubMsg();

    if (data.deviceErrorType) {
      this.troubleShootDevice(data.deviceErrorType);
    }
    this.localTrackManagerService.isCallTracksNeededByCallModal = true;
  }

  async ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    this.localTrackManagerService.isCallTracksNeededByCallModal = false;
    super.ngOnDestroy();
  }

  // override
  getComponentPromptLayout(): PROMPTS {
    return PROMPTS.SPACE_DEVICE_MODAL;
  }

  // override
  closeDialog() {
    this.done();
  }

  async done() {
    this.sessionSharedDataService.disableVideoStreamChanged.next({
      disabled: this.disableVideos,
      source: DisableIncomingVideosSource.USER,
    });
    this.rtcServiceController.service.isNoiseCancellationEnabled = this.enableNoiseCancellation;
    this.sessionSharedDataService.disabledThroughWeakNotification = false;
    this.dialogRef.close();
  }

  onChangeDisableVideoStreams() {
    this.handleDisableVideoStreamsSubMsg();
  }

  async playTestSound() {
    if (!this.isPlayingSpeakerTest) {
      const audioSrcPath = '../../../../../../assets/sounds/test-speaker.mp3';
      const sink = Audio.prototype.setSinkId;
      let audio: any;

      if (sink) {
        audio = <HTMLAudioElement & { setSinkId(deviceId: string): void }>new Audio(audioSrcPath);
      } else {
        audio = new Audio(audioSrcPath);
      }

      audio.addEventListener('canplaythrough', (param: Event) => this.handleTestAudioEvents(param));
      audio.addEventListener('ended', (param: Event) => this.handleTestAudioEvents(param));
      audio.addEventListener('error', (param: Event) => this.handleTestAudioEvents(param));

      if (sink) {
        if (this.devices.activeSpeakerId) {
          await audio.setSinkId(this.devices.activeSpeakerId);
          audio.play();
        }
      } else {
        audio.play();
      }
    }
  }

  private handleTestAudioEvents(event: Event) {
    switch (event.type) {
      case 'canplaythrough':
        this.ngZone.run(() => (this.isPlayingSpeakerTest = true));
        break;
      case 'ended':
      case 'error':
        this.ngZone.run(() => (this.isPlayingSpeakerTest = false));
        break;
      default:
        this.ngZone.run(() => (this.isPlayingSpeakerTest = false));
    }
  }

  private handleDisableVideoStreamsSubMsg() {
    if (this.disableVideos) {
      this.disableVideoStreamsSubMsg = this.sessionSharedDataService.disabledThroughWeakNotification
        ? this.translateService.instant(
            'You opted to turn this on after we detected degraded call performance. You can turn this off at any time',
          )
        : this.translateService.instant(
            'You opted to turn this on. Participant screenshares always stay visible',
          );
    } else {
      this.disableVideoStreamsSubMsg = this.translateService.instant(
        'Participant screenshares always stay visible',
      );
    }
  }
}

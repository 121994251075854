<div class="searchBoxContainer">
  <i
    class="material-icons cursor-pointer user-select-none"
    (click)="search()"
    data-name="pencil-searchbar-search"
    >search</i
  >
  <div class="searchInputContainer">
    <app-pill
      *ngFor="let tag of tags; let i = index"
      [text]="tag.secondary[0].name"
      [subtext]="tag.main?.name"
      [icon]="tag.metaData?.icon"
      [type]="tag.metaData?.tagType ?? 'topics'"
      (close)="deleteFilter(i)"
    ></app-pill>
    <input
      class="searchBox"
      type="text"
      [placeholder]="searchTitle | translate"
      #searchInput
      (keydown.enter)="searchInput.blur(); search()"
      [formControl]="searchControl"
      [matAutocomplete]="auto"
      (input)="refreshOnEmpty()"
    />
    <mat-autocomplete #auto="matAutocomplete" class="autoComplete">
      <ng-container *ngIf="filteredOptions | async as items">
        <div class="suggestionHeader" *ngIf="HybridFilterMode && !lastSearch">
          <mat-option
            class="eachSuggestion flipping"
            [disabled]="true"
            (click)="lastSearch = true"
            *ngIf="searchControl?.value.length"
          >
            <span class="autoCompleteOptions"
              >{{ 'Press' | translate }}
              <mat-icon style="padding-left: 3px"> keyboard_return </mat-icon>
              {{ 'to search' | translate }}
              {{ (searchForLabel ? 'for '.concat(searchForLabel) : '') | translate }}
              {{ 'by name' | translate }}</span
            >
          </mat-option>
          <mat-option class="eachSuggestion SuggestTitle" [disabled]="true" *ngIf="items.length">
            <span class="autoCompleteOptions"
              >{{ 'Suggested' | translate }} {{ suggestionTypeLabel ?? '' | translate }}</span
            >
          </mat-option>
        </div>
        <ng-container *ngIf="!lastSearch">
          <div class="eachSuggestion" *ngFor="let suggestion of items">
            <mat-option
              *ngFor="let title of suggestion.secondary | slice: 0:10"
              [value]="
                title.name.concat(suggestion.main?.name.length ? ' ' + suggestion.main.name : '')
              "
              (click)="selectingSuggestion(suggestion, title)"
              data-name="apps-search-selecting-app"
            >
              <span
                class="autoCompleteOptions"
                ngbTooltip="{{
                  title.name.concat(
                    suggestion.main?.name.length ? ' in '.concat(suggestion.main.name) : ''
                  )
                }}"
              >
                <strong>{{ title.name | truncate: [30] }}</strong>
                {{
                  suggestion.main?.name.length
                    ? ' in '.concat(suggestion.main.name | truncate: [15])
                    : ''
                }}
              </span>
              <div
                class="chip {{
                  'filter-' + (suggestion.metaData?.tagType ?? 'topics') | hyphenate
                }} filterTypes"
              >
                <mat-icon *ngIf="suggestion.metaData?.icon" class="icons-chips">
                  {{ suggestion.metaData.icon }}
                </mat-icon>
              </div>
            </mat-option>
          </div>
        </ng-container>
      </ng-container>
    </mat-autocomplete>
  </div>
  <em
    class="material-icons btn-clear cursor-pointer closeButton user-select-none"
    *ngIf="searchControl?.value"
    (click)="reset()"
    data-name="apps-search-close"
    >close</em
  >
</div>

import { NgModule } from '@angular/core';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { GadgetHeaderModule } from '../gadget-header/gadget-header.module';
import { GraphComponent } from './graph.component';

@NgModule({
  declarations: [GraphComponent],
  exports: [GraphComponent],
  imports: [DragDropModule, GadgetHeaderModule, TranslateModule],
})
export class GraphModule {}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-common-dialog',
  templateUrl: 'common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss'],
})
export class CommonDialogComponent {
  checked = false;
  constructor(
    public dialogRef: MatDialogRef<CommonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    close(cancel: boolean) {
      if (this.data.checkboxText) {
        this.dialogRef.close({ canceled: cancel, checked: this.checked })
      } else {
        this.dialogRef.close({ canceled: cancel })
      }
    }
}

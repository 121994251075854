import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { WbDialogService } from 'src/app/services/wb-dialog.service';
import { PencilButtonComponent } from 'src/app/standalones/components/pencil-button/pencil-button.component';
import { NgIf } from '@angular/common';
import { DeviceAndBrowserDetectorService } from 'src/app/services/device-and-browser-detector.service';
import { IconTypes } from 'src/app/standalones/components/pencil-icon/pencil-icon.component';
import { SpaceRecordingService } from 'src/app/services/space-recording.service';
import { ForceScreenShareAction } from '../../services/force-screen-share-blocking-modal.service';
import { SpaceScreenshareService } from '../../../../services/space-screenshare.service';

enum WarningType {
  PrivacyUnavailable = 'privacy-not-supported',
  NotChromium = 'not-chromium',
  None = 'none',
}
@Component({
  selector: 'app-force-screen-share-blocking-modal',
  templateUrl: './force-screen-share-blocking-modal.component.html',
  styleUrls: ['./force-screen-share-blocking-modal.component.scss'],
  standalone: true,
  imports: [TranslateModule, PencilButtonComponent, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForceScreenShareBlockingModalComponent {
  protected readonly WarningType = WarningType;

  readonly forceScreenShare: boolean;
  readonly allowShareAnotherScreen: boolean;
  readonly shareWhiteboardAgain: boolean;
  readonly IconTypes = IconTypes;
  readonly isPrivacyCloudRecordingEnabled: boolean;
  readonly privacyCloudRecordingUnavailable: boolean;
  readonly warning: WarningType;

  constructor(
    private dialogRef: MatDialogRef<ForceScreenShareBlockingModalComponent>,
    protected shareScreenService: SpaceScreenshareService,
    private deviceAndBrowserDetectorService: DeviceAndBrowserDetectorService,
    spaceRecordingService: SpaceRecordingService,
    wbDialogService: WbDialogService,
    @Inject(MAT_DIALOG_DATA)
    data: { allowShareAnotherScreen: boolean; shareWhiteboardAgain: boolean },
  ) {
    wbDialogService.pushNewDialogRef(dialogRef);
    this.isPrivacyCloudRecordingEnabled = spaceRecordingService.isPrivacyCloudRecordingEnabled;
    this.forceScreenShare = spaceRecordingService.forceShareScreen;
    this.allowShareAnotherScreen = data?.allowShareAnotherScreen ?? false;
    this.shareWhiteboardAgain = data?.shareWhiteboardAgain ?? false;
    this.privacyCloudRecordingUnavailable =
      this.isPrivacyCloudRecordingEnabled && !spaceRecordingService.supportPrivacyCloudRecording();
    this.warning = this.getWarnings();
  }

  getWarnings(): typeof this.warning {
    if (this.privacyCloudRecordingUnavailable) {
      return WarningType.PrivacyUnavailable;
    } else if (!this.deviceAndBrowserDetectorService.isChrome() && !this.deviceAndBrowserDetectorService.isEdge()) {
      return WarningType.NotChromium;
    } else {
      return WarningType.None;
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  moreOptions() {
    this.dialogRef.close(ForceScreenShareAction.MORE_OPTIONS);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { last } from 'lodash-es';
import { areDatesWithinFiveMinutes, isContentMessage } from '../common/utils/messaging';
import { MessageGroupByDate, TypedMessage } from '../models/messaging';

@Pipe({
  name: 'messageGroupsSuccessiveUserChat',
})
export class MessageGroupsSuccessiveUserChatPipe implements PipeTransform {
  transform(messages: TypedMessage[]): MessageGroupByDate[] {
    return messages.reduce((accumulator: MessageGroupByDate[], message) => {
      if (isContentMessage(message)) {
        const currentGroup = last(accumulator);
        if (
          currentGroup &&
          isContentMessage(currentGroup) &&
          currentGroup.userId === message.author._id &&
          !message.isEdited &&
          !currentGroup.messages.find((data) => data.isEdited) &&
          !currentGroup.messages.find((data) => data.parent) &&
          areDatesWithinFiveMinutes(currentGroup.date, message.createdAt)
        ) {
          currentGroup.messages.push(message);
          currentGroup.date = message.createdAt;
        } else {
          accumulator.push({
            date: message.createdAt,
            userId: message.author._id,
            messages: [message],
            mostRecentTimestamp: message.firstAttemptTimestamp || message.createdAt,
          });
        }
        return accumulator;
      } else {
        accumulator.push(message);
        return accumulator;
      }
    }, []);
  }
}

<div
  #element
  [appTooltip]="tooltip"
  [appTooltipDisabled]="!isClipped"
  [appTooltipPlacement]="'bottom-center'"
>
  {{ text }}
</div>
<ng-template #tooltip>
  <app-tooltip arrow="top" [text]="text" [wrapText]="true"></app-tooltip>
</ng-template>

import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SUBSCRIPTION_TYPES } from '../../../../models/payment';
import {
  PaywallIndicatorService,
  UpgradePlanEvaluationType,
} from '../../../../services/paywall-indicator.service';

export interface ToggleOptions {
  setting: string; // name of the setting
  titleText: string; // title of the options
  activeText?: string; // text to show when setting is enabled
  inactiveText?: string; // text to show when the setting is disabled

  // Controls the selection behavior of disabled selections
  disableOptions?: {
    disabled: boolean; // is the option disabled
    reason: string; // why its disabled
  };

  upgradeOptions?: {
    requiredSubscriptions: SUBSCRIPTION_TYPES[];
    evaluationType: UpgradePlanEvaluationType;
  };
}

@UntilDestroy()
@Component({
  selector: 'app-user-settings-permissions-toggle',
  templateUrl: './user-settings-permissions-toggle.component.html',
  styleUrls: ['./user-settings-permissions-toggle.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsPermissionsToggleComponent implements OnInit {
  form!: UntypedFormGroup;

  @Input() options!: ToggleOptions;

  public isUpgradePlanBlockerEnabled: boolean = false;

  constructor(
    private controlContainer: ControlContainer,
    public paywallIndicatorService: PaywallIndicatorService,
  ) {}

  ngOnInit(): void {
    this.form = <UntypedFormGroup>this.controlContainer.control;
  }

  handleUpgradePlanBlocker(isUpgradePlanBlockerEnabled: boolean) {
    this.isUpgradePlanBlockerEnabled = isUpgradePlanBlockerEnabled;
  }
}

export { ToggleOptions as UserSettingsPermissionsToggleOptions };

export const DifficultyLevels = [
  'veryEasyQuestions',
  'easyQuestions',
  'difficultQuestions',
  'veryDifficultQuestions',
];

export const QualityLevels = [
  'badQuestions',
  'averageQuestions',
  'goodQuestions',
  'excellentQuestions',
];

export enum KeyboardArrows {
  LEFT = 'ArrowLeft',
  RIGHT = 'ArrowRight',
  UP = 'ArrowUp',
  DOWN = 'ArrowDown',
}

export enum MoveObjectOnArrowKey {
  smallStep = 5,
  bigStep = 20,
}

export enum MoveVPTOnArrowKey {
  smallStep = 20,
  bigStep = 50,
}

export const MoveKeyDownHoldTimeLimit = 250;

export enum PermissionStatus {
  ENABLED_FOR_ALL = 'ENABLED_FOR_ALL',
  PARTIAL_ENABLED = 'PARTIAL_ENABLED',
  DISABLED_FOR_ALL = 'DISABLED_FOR_ALL',
}

export const keyboardArrowsArray: string[] = [
  KeyboardArrows.LEFT,
  KeyboardArrows.RIGHT,
  KeyboardArrows.UP,
  KeyboardArrows.DOWN,
];

export const DEFAULT_OBJECT_MOVEMENT = 10;

export const LangCodeMap = [
  { code: 'en', label: 'English', rtl: false, originalLabel: '🇬🇧 English' },
  { code: 'af', label: 'Afrikaans', rtl: false },
  { code: 'ar', label: 'Arabic', rtl: true, originalLabel: '🇦🇪 عربى' },
  { code: 'ar-sa', label: 'Arabic (Saudi Arabia)', rtl: true, originalLabel: '🇸🇦 عربي سعودي' },
  { code: 'bn', label: 'Bengali', rtl: false, originalLabel: '🇧🇩 বাংলা' },
  { code: 'bg', label: 'Bulgarian', rtl: false, originalLabel: '🇧🇬 български' },
  { code: 'zh-Hans', label: 'Chinese (simplified)', rtl: false, originalLabel: '🇨🇳 中文（简体)' },
  { code: 'zh-Hant', label: 'Chinese (traditional)', rtl: false, originalLabel: '🇨🇳 中文（繁體)' },
  { code: 'da', label: 'Danish', rtl: false },
  { code: 'nl', label: 'Dutch', rtl: false },
  { code: 'fil', label: 'Filipino', rtl: false },
  { code: 'fi', label: 'Finnish', rtl: false },
  { code: 'fr', label: 'French', rtl: false, originalLabel: '🇫🇷 Français' },
  { code: 'de', label: 'German', rtl: false },
  { code: 'gu', label: 'Gujarati', rtl: false },
  { code: 'he', label: 'Hebrew', rtl: true, originalLabel: '🇮🇱 עִבְרִית' },
  { code: 'hi', label: 'Hindi', rtl: false, originalLabel: '🇮🇳 हिन्दी' },
  { code: 'it', label: 'Italian', rtl: false, originalLabel: '🇮🇹 Italiano' },
  { code: 'id', label: 'Indonesian', rtl: false, originalLabel: '🇮🇩 Bahasa Indonesia' },
  { code: 'ja', label: 'Japanese', rtl: false, originalLabel: '日本語' },
  { code: 'kn', label: 'Kannada', rtl: false },
  { code: 'ko', label: 'Korean', rtl: false, originalLabel: '조선말, 한국어' },
  { code: 'ms', label: 'Malay', rtl: false },
  { code: 'ml', label: 'Malayalam', rtl: false },
  { code: 'mi', label: 'Maori', rtl: false },
  { code: 'mr', label: 'Marathi', rtl: false },
  { code: 'ne', label: 'Nepali', rtl: false },
  { code: 'no', label: 'Norwegian', rtl: false, originalLabel: '🇳🇴 Norsk' },
  { code: 'pa', label: 'Panjabi', rtl: false },
  { code: 'pt', label: 'Portuguese', rtl: false, originalLabel: '🇵🇹 Português' },
  { code: 'ru', label: 'Russian', rtl: false, originalLabel: '🇷🇺 Русский язык' },
  { code: 'sr', label: 'Serbian', rtl: false },
  { code: 'es', label: 'Spanish', rtl: false, originalLabel: '🇪🇸 Español' },
  { code: 'sw', label: 'Swahili', rtl: false },
  { code: 'sv', label: 'Swedish', rtl: false },
  { code: 'tl', label: 'Tagalog', rtl: false },
  { code: 'ta', label: 'Tamil', rtl: false },
  { code: 'th', label: 'Thai', rtl: false, originalLabel: '🇹🇭 คำเมือง' },
  { code: 'uk', label: 'Ukrainian', rtl: false, originalLabel: '🇺🇦 українська мова' },
  { code: 'vi', label: 'Vietnamese', rtl: false, originalLabel: '🇻🇳 Tiếng Việt' },
];

export const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

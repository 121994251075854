<div
  class="tags d-flex"
  [ngClass]="{
    expanded: expanded,
    selected: selected
  }"
  (mouseenter)="handleMouseEnter()"
  (mouseleave)="handleMouseLeave()"
  (mousewheel)="mouseWheel($event)"
  (touchstart)="touchStart($event)"
  (touchend)="touchEnd($event)"
  (touchmove)="touchMove($event)"
>
  <div class="tags-container" #tagsScrollContainer>
    <ng-container *ngIf="customTags?.length == 0">
      <span *ngIf="question?.state === 'DRAFT'" class="draft tags-pill">
        {{ 'Draft' | translate }}
      </span>
      <span *ngIf="question?.state === 'DISABLED'" class="draft tags-pill">
        {{ 'Disabled' | translate }}
      </span>
      <span
        *ngIf="question?.state === 'INGESTED' || question?.ingestion_metadata?.ingested"
        class="autotyped tags-pill"
      >
        {{ 'PD' | translate }}
      </span>
      <span class="type tags-pill" *ngIf="pageType === 'questions'">
        {{ question?.type | questionType | translate }}
      </span>
      <span class="difficulty tags-pill" *ngIf="question?.metadata?.difficulty">
        {{ DIFFICULTY_TO_LABEL[question?.metadata?.difficulty] | lowercase | translate }}
      </span>
      <span class="quality tags-pill" *ngIf="question?.quality"> Q:{{ question?.quality }} </span>
      <span class="pastexam tags-pill" *ngIf="question?.source_info?.from">
        {{ 'Past Exam' | translate }}
      </span>
      <span *ngIf="topics?.length === 0" class="annotated tags-pill">
        {{ 'Not Annotated' | translate }}
      </span>
      <span class="topics tags-pill" *ngFor="let topic of topics">
        {{ topic.name }}
      </span>
      <ng-container *ngIf="user && (user | has: Features.view_set)">
        <span *ngFor="let worksheetName of worksheetNames" class="worksheet tags-pill">
          {{ worksheetName }}
        </span>
      </ng-container>
    </ng-container>
    <app-pill
      *ngFor="let tag of customTags"
      [text]="tag.value"
      [subtext]="courseMap.get(tag.metadata?.courseID)"
      [type]="tag.metadata?.courseID?.length ? 'Course Tag' : 'Institutional Tag'"
      [icon]="tag.metadata?.courseID?.length ? 'article' : 'school'"
      [showXButton]="tagRemoveButton"
      [maxTextLength]="maxTagNameLength"
      [maxSubtextLength]="maxCourseNameLength"
      [color]="tag.metadata?.color"
      (close)="tagRemoved.emit()"
    ></app-pill>
  </div>
  <div *ngIf="question?.author && from === 'rhs' && !hideUserProfile" class="tags-author-box">
    <div class="divider"></div>
    <app-author-profile-photo
      class="cmr-4"
      [user]="question?.author._id | author"
      width="18"
      [fromQuestionList]="true"
      [showName]="true"
    ></app-author-profile-photo>
  </div>
</div>

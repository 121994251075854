<app-message-list
  *ngIf="conversationContent$ | async as conversationContent; else chatLoading"
  [conversationContent]="conversationContent"
  [displayParticipantName]="displayParticipantName"
  [attr.typingUserIds]="typingUserIds$ | async"
  [enableEditing]="enableEditing"
  (updateMessage)="onUpdateMessage($event)"
  (deleteMessage)="onDeleteMessage($event)"
  (fetchMoreMessages)="onFetchMoreMessages($event)"
  (replyMessage)="emitReplyMessage($event)"
  [clickAvatars]="clickAvatars"
>
</app-message-list>
<ng-template #chatLoading>
  <app-loading [showTipsIndicator]="true" [loading]="true"></app-loading>
</ng-template>

import { fabric } from 'fabric';
import { ITextboxOptions } from 'fabric/fabric-impl';
import { FlagsService } from 'src/app/services/flags.service';
import { Subscription } from 'rxjs';
import { getTruncatedName } from 'src/app/common/utils/common-util';
import { SessionRtdService } from 'src/app/services/session-rtd.service';
import { modifiedSetTimeout } from 'src/app/utilities/ZoneUtils';
import { Point } from '../../../../models/session-sync';
import { SessionsVptService } from '../../../../services/sessions-vpt.service';
import {
  SessionSharedDataService,
  SessionView,
} from '../../../../services/session-shared-data.service';
import {
  OverlayObject,
  OverlayObjectType,
  RtEvent,
} from '../../overlay-canvas/overlay-canvas.component';

// The rate at which real time pointer updates are sent
export const pointerCaptureRate = 1000 / 12; // 12 fps

// Determined experimentally Offsets to get the mouse to align
// perfectly for the pointer
export const pointerOffsetLeft = 10;
export const pointerOffsetTop = 5;
export const customPointerOffsetLeft = 0;
export const customPointerOffsetTop = 20;

export const customPointerOffset = ['draw', 'highlight'];

// cursor tooltip
export const cursorTooltipHeight = 40;
export const cursorTooltipWidth = 400;

// Font size for the pointer
const fontSize = 14;

/*
 * Implements a preemptable timer
 */
export class WatchDogTimer {
  private timer?: NodeJS.Timer;
  private deferredFunc?: () => void;
  private timeout = 500; // default to 500ms
  private runPreemptedFunc = true;

  constructor(timeout?: number, preemptRunFunc?: boolean) {
    if (timeout) {
      this.timeout = timeout;
    }

    if (preemptRunFunc !== undefined) {
      this.runPreemptedFunc = preemptRunFunc;
    }
  }

  // Sets the current function for the timer and restarts the timer
  // Note: it will preempt the current waiting function (if this.runPreemptedFunc == true)
  public setFunc(func: () => void) {
    if (this.runPreemptedFunc) {
      this.preempt();
    }
    this.deferredFunc = func;
    this.restartTimer();
  }

  // Immediately runs the deferred function and clears the timer
  public preempt() {
    if (this.deferredFunc) {
      this.deferredFunc();
    }
    this.clearTimer();
  }

  // Restart the timer clock without running the deferred function
  public restartTimer() {
    this.clearTimer();
    this.timer = modifiedSetTimeout(() => {
      if (this.deferredFunc) {
        this.deferredFunc();
      }
      this.timer = undefined;
    }, this.timeout);
  }

  // Clears the watchdog timer without running the deferred function
  public clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = undefined;
  }
}

abstract class RealTimeHandler {
  subscriptions: Subscription[] = [];
  protected cleanupUsers: Set<string> = new Set<string>();

  // Find the realtime fabric objects based on type and optionally userId and uid
  public findObjects(
    type: OverlayObjectType,
    userId?: string,
    uid?: string,
  ): fabric.Object[] | undefined {
    return this.sessionRtdService.overlayCanvas?.findOverlayObjects(type, userId, uid);
  }

  // Remove the realtime fabric objects based on type and optionally userId and uid
  public removeObjects(type: OverlayObjectType, userId?: string, uid?: string): void {
    const objects = this.findObjects(type, userId, uid);
    if (!objects) {
      return;
    }
    for (const object of objects) {
      this.sessionRtdService.overlayCanvas?.remove(object);
    }
  }

  public removeRealtimeObjects(addedObjects: any[]): void {
    // remove only paths corresponding to added objects
    for (const obj of addedObjects) {
      this.removeObjects(OverlayObjectType.PATH, obj.userId, obj.uid);
    }
  }

  public removeAllRealtimeObjects(): void {
    // remove all remaining paths for users
    for (const userId of this.cleanupUsers) {
      this.removeObjects(OverlayObjectType.PATH, userId);
    }
    this.cleanupUsers.clear();
  }

  // Sets the necessary metadata to make the fabric object searchable in the canvas
  public setMetadata(obj: OverlayObject, rtEvent: RtEvent, type: OverlayObjectType) {
    const canvas = this.sessionRtdService.overlayCanvas;
    const timestamp = rtEvent.event.mouse.metadata.timestamp;
    const uid = rtEvent.event.mouse.metadata.uid;
    canvas?.setMetadata(obj, rtEvent.event.user, type, timestamp, uid);
  }

  constructor(
    public flagsService: FlagsService,
    public sharedDataService: SessionSharedDataService,
    public sessionsVptService: SessionsVptService,
    public sessionRtdService: SessionRtdService,
  ) {}

  onDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  abstract setupEventSubscriptions(): void;
}

export class RealTimeDrawingHandler extends RealTimeHandler {
  // Watchdog timer to clear the real time drawing after an update has been received
  private readonly watchdogTimerInterval = 2000;
  private readonly watchdogTimerRTD = new WatchDogTimer(this.watchdogTimerInterval, false);

  constructor(
    flagsService: FlagsService,
    sharedDataService: SessionSharedDataService,
    sessionsVptService: SessionsVptService,
    sessionRtdService: SessionRtdService,
  ) {
    super(flagsService, sharedDataService, sessionsVptService, sessionRtdService);
    this.setupEventSubscriptions();
  }

  setupEventSubscriptions() {
    const scibblesAddedSub = this.sessionRtdService.scribblesAdded.subscribe((scribblesAdded) => {
      this.handleYUpdate(scribblesAdded);
    });
    this.subscriptions.push(scibblesAddedSub);
  }

  public handleRTUpdate(rtEvent: RtEvent) {
    if (rtEvent.event.mouse.type !== 'draw' && rtEvent.event.mouse.type !== 'highlight') {
      return;
    }

    const currentLine = rtEvent.metadata.currentLine;
    const { mouse } = rtEvent.event;

    if (!currentLine?.length && mouse.metadata?.state !== 'start') {
      return;
    }

    if (rtEvent.event.mouse.metadata?.state === 'start') {
      this.handleDrawingStart(rtEvent);
    } else if (rtEvent.event.mouse.metadata?.state === 'done') {
      this.handleDrawingDone(rtEvent);
    } else {
      this.handleDrawing(rtEvent);
    }
  }

  public handleRTUpdates(rtEvents: RtEvent[]) {
    for (const rtEvent of rtEvents) {
      if (rtEvent.event.mouse.type !== 'draw' && rtEvent.event.mouse.type !== 'highlight') {
        continue;
      }

      const currentLine = rtEvent.metadata.currentLine;
      const { mouse } = rtEvent.event;

      if (!currentLine?.length && mouse.metadata?.state !== 'start') {
        continue;
      }

      if (rtEvent.event.mouse.metadata?.state === 'start') {
        this.handleDrawingStart(rtEvent);
      } else if (rtEvent.event.mouse.metadata?.state === 'done') {
        this.handleDrawingDone(rtEvent);
      } else {
        this.handleDrawing(rtEvent);
      }
    }
  }

  private setWatchdogForCleanup(userId: string) {
    // Add this user to the list of cleanup users (after a timeout, if we don't get a YUpdate in the meantime)
    this.cleanupUsers.add(userId);
    this.watchdogTimerRTD.setFunc(() => this.removeAllRealtimeObjects());
  }

  private resetWatchdogForCleanup() {
    // Reset the watchdop timer
    this.watchdogTimerRTD.restartTimer();
  }

  private handleDrawingStart(rtEvent: RtEvent) {
    this.setWatchdogForCleanup(rtEvent.event.user);

    rtEvent.metadata.currentLine = [];
    rtEvent.metadata.currentLine.push(rtEvent.event.mouse.point);
  }

  private handleYUpdate(scribblesAdded: any[]) {
    // Remove realtime paths corresponding to the persistent scribbles that were just received
    this.removeRealtimeObjects(scribblesAdded);

    // Reset the cleanup timer
    this.resetWatchdogForCleanup();
  }

  // Delete the realtime paths for this user and prep for next drawing
  private handleDrawingDone(rtEvent: RtEvent) {
    this.setWatchdogForCleanup(rtEvent.event.user);
  }

  private handleDrawing(rtEvent: RtEvent) {
    const canvas = this.sessionRtdService.overlayCanvas;
    const { point, metadata } = rtEvent.event.mouse;

    // Fallback in case events are out of order
    if (!rtEvent.metadata.currentLine) {
      rtEvent.metadata.currentLine = [];
    }

    const currentLine: Point[] = rtEvent.metadata.currentLine;
    currentLine.push(point);

    if (currentLine.length < 2) {
      return;
    } else if (currentLine.length === 2) {
      const strokeWidth = metadata.strokeWidth ? metadata.strokeWidth : 3;
      const currentLineFixed = this.fixStrokeOffset(currentLine, strokeWidth);
      const path = this.createNewPath(currentLineFixed, metadata);
      this.setMetadata(path as OverlayObject, rtEvent, OverlayObjectType.PATH);
      canvas?.insertAt(path, 0, false);
      this.sessionRtdService.renderOverlayCanvas();
    } else {
      const strokeWidth = metadata.strokeWidth ? metadata.strokeWidth : 3;
      const currentLineFixed = this.fixStrokeOffset(currentLine, strokeWidth);
      const paths = this.findObjects(OverlayObjectType.PATH, rtEvent.event.user) as (fabric.Path &
        OverlayObject)[];
      if (paths.length == 0) {
        return;
      }

      // Append to the last path (by timestamp)
      paths.sort((a, b) => (a.timestamp ?? 0) - (b.timestamp ?? 0));
      const path = paths[paths.length - 1];
      path.path = this.convertPointsToSVGPath(currentLineFixed);
      path.dirty = true;
      path.setCoords();
      this.sessionRtdService.renderOverlayCanvas();
    }

    this.setWatchdogForCleanup(rtEvent.event.user);
  }

  /*
   * Creates a new path and sets the metadata from the mouse event
   */
  private createNewPath(currentLine: Point[], mouseMetadata: any): fabric.Path {
    const path = new fabric.Path(this.convertPointsToSVGPath(currentLine));

    if (!mouseMetadata) {
      mouseMetadata = { color: 'black', strokeWidth: 3 };
    }

    const { strokeColor, strokeWidth } = mouseMetadata;
    path.set({
      fill: undefined,
      stroke: strokeColor,
      strokeWidth: strokeWidth,
      strokeLineCap: 'round',
      selectable: false,
      objectCaching: false,
    });

    return path;
  }

  /*
   * Corrects for an offset that is created when creating a line
   * with a large stroke
   */
  private fixStrokeOffset(currentLine: Point[], strokeWidth: number): Point[] {
    if (!currentLine) {
      return [];
    }

    const points: Point[] = currentLine.map((point: Point) => {
      let offset = strokeWidth;
      offset = offset ? offset : 1;
      offset = offset / 2;
      const bias = 1 / 2;
      const newPoint: Point = {
        x: point.x - offset + bias,
        y: point.y - offset + bias,
      };
      return newPoint;
    });

    return points;
  }

  /*
   * Converts an array of points into a bezier curve
   */
  private convertPointsToSVGPath(points: Point[]) {
    const path: any[] = [];
    if (!points[0] || !points[1]) {
      return;
    }

    let p1 = new fabric.Point(points[0].x, points[0].y);
    let p2 = new fabric.Point(points[1].x, points[1].y);

    path.push(['M', points[0].x, points[0].y]);
    for (let i = 1, len = points.length; i < len; i++) {
      const midPoint = p1.midPointFrom(p2);
      // p1 is our bezier control point
      // midpoint is our endpoint
      // start point is p(i-1) value.
      path.push(['Q', p1.x, p1.y, midPoint.x, midPoint.y]);
      p1 = new fabric.Point(points[i].x, points[i].y);
      if (i + 1 < points.length) {
        p2 = new fabric.Point(points[i + 1].x, points[i + 1].y);
      }
    }

    path.push(['L', p1.x, p1.y]);
    return path;
  }
}

class PointerState {
  oldPoint: Point = { x: 0, y: 0 };
  animation?: Animation;
}

export class RealTimePointerHandler extends RealTimeHandler {
  private readonly pointerStates: { [key: string]: PointerState } = {};
  private currentSessionView?: SessionView;

  constructor(
    flagsService: FlagsService,
    sharedDataService: SessionSharedDataService,
    sessionsVptService: SessionsVptService,
    sessionRtdService: SessionRtdService,
    private readonly captureRate: number,
  ) {
    super(flagsService, sharedDataService, sessionsVptService, sessionRtdService);
    this.setupEventSubscriptions();
  }

  setupEventSubscriptions() {
    const sessionViewSub = this.sharedDataService.sessionView.current$.subscribe(
      (sessionViewState) => this.sessionViewHandler(sessionViewState.view),
    );
    this.subscriptions.push(sessionViewSub);
  }

  public async handleRTUpdate(rtEvent: RtEvent) {
    // only handle pointer updates for whiteboard view
    if (this.currentSessionView != SessionView.WHITEBOARD) {
      return;
    }

    if (!this.getPointer(rtEvent)) {
      this.createPointer(rtEvent).then(() => this.setTranslate(rtEvent));
    } else {
      this.setTranslate(rtEvent);
    }
  }

  public async handleRTUpdates(rtEvents: RtEvent[]) {
    // only handle pointer updates for whiteboard view
    if (this.currentSessionView != SessionView.WHITEBOARD) {
      return;
    }
    // segment events by user
    const eventsByUser: { [x: string]: RtEvent[] } = {};
    rtEvents.forEach((rtEvent) => {
      if (eventsByUser[rtEvent.event.user]) {
        eventsByUser[rtEvent.event.user].push(rtEvent);
      } else {
        eventsByUser[rtEvent.event.user] = [rtEvent];
      }
    });

    for (const [_, events] of Object.entries(eventsByUser)) {
      // for each user, process the last event
      const rtEventToProcess = events[events.length - 1];
      // process only events for the users in the current frame
      // sometimes an event is happening while the user is switching between board
      // so, we need to prevent  creating pointers in that case
      if (this.sharedDataService.currentFrameHasUser(rtEventToProcess.event.user)) {
        const pointerHtmlElement = this.getPointer(rtEventToProcess);
        const activeToolbar = rtEventToProcess.event.activeToolbar ?? 'pointer';
        const attrActiveToolbar = pointerHtmlElement?.getAttribute('activeToolbar') ?? 'pointer';
        if (!pointerHtmlElement || activeToolbar !== attrActiveToolbar) {
          await this.createPointer(rtEventToProcess);
        }
        this.setTranslate(rtEventToProcess, activeToolbar !== attrActiveToolbar);
      }
    }
  }

  private getInvZoomScale(vpt: number[]): { scaleX: number; scaleY: number } {
    const invVpt = fabric.util.invertTransform(vpt);
    const scale = (({ scaleX, scaleY }) => ({ scaleX, scaleY }))(fabric.util.qrDecompose(invVpt));

    return scale;
  }

  public createPointer(rtEvent: RtEvent): Promise<boolean> {
    this.pointerStates[rtEvent.event.user] = new PointerState();
    const activeToolbar = rtEvent.event.activeToolbar || 'pointer';
    return this.createPointerGroup(rtEvent, activeToolbar).then((pointerGroup) => {
      // ensure only one pointer
      const existingPointer = this.getPointer(rtEvent);
      rtEvent.event.toolbarChanged =
        activeToolbar !== (existingPointer?.getAttribute('activeToolbar') || 'pointer');

      existingPointer?.parentElement?.remove();

      let vpt = this.sessionsVptService.viewportTransform.value;
      if (!vpt) {
        // Set the default VPT
        vpt = [0, 0, 1, 0, 0, 0, 0, 1];
      }
      const { scaleX } = this.getInvZoomScale(vpt);

      rtEvent.metadata.svgPointer = pointerGroup.toSVG();

      const container = document.createElement('div');
      container.id = `${this.getSVGId(rtEvent)}-container`;
      // IMPORTANT: Do not remove this class. Due to a bug in FullStory, not excluding this causes
      // js-listeners on the pointer svg animations to leak, which degrades app performance over time.
      container.className = 'fs-exclude';

      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

      svg.style.position = 'absolute';
      const topOffset = customPointerOffset.includes(activeToolbar)
        ? customPointerOffsetTop
        : pointerOffsetTop;
      const letOffset = customPointerOffset.includes(activeToolbar)
        ? customPointerOffsetLeft
        : pointerOffsetLeft;

      svg.style.top = `${-topOffset * scaleX}`;
      svg.style.left = `${-letOffset * scaleX}`;
      svg.style.width = `${cursorTooltipWidth * scaleX}px`;
      svg.style.height = `${cursorTooltipHeight * scaleX}px`;
      // Extra <g> tag to set the transform for the inverse zoom scaling
      svg.innerHTML = `<g transform="scale(${scaleX})">${rtEvent.metadata.svgPointer}</g>`;
      svg.id = this.getSVGId(rtEvent);
      svg.setAttribute('activeToolbar', activeToolbar);

      container.appendChild(svg);
      container.setAttribute('activeToolbar', activeToolbar);

      this.getPointerContainer()?.appendChild(container);

      return true;
    });
  }

  public getSVGId(rtEvent: RtEvent) {
    return RealTimePointerHandler.SVGIdFormat(rtEvent.event.user);
  }

  public static SVGIdFormat(id: string) {
    return `${id}-rt-pointer`;
  }

  private getPointerContainer() {
    return document.getElementById('overlay-canvas-svg-pointer');
  }

  private getPointerState(rtEvent: RtEvent): PointerState {
    return this.pointerStates[rtEvent.event.user];
  }

  // moves the pointer to the point from the mouse event
  public setTranslate(rtEvent: RtEvent, toolbarChanged = false) {
    const { point } = rtEvent.event.mouse;

    // once toolbar changed, oldPoint is reset to (0, 0) which will cause transition issue
    // solution: set old point to new point
    const oldPoint = toolbarChanged ? point : this.getPointerState(rtEvent).oldPoint;
    this.setAnimation(rtEvent, oldPoint, point);

    this.getPointerState(rtEvent).oldPoint = point;
  }

  // Sets the animation for the pointer from the oldPoint to the newPoint
  private setAnimation(rtEvent: RtEvent, newPoint: Point, oldPoint: Point) {
    const pointer = this.getPointer(rtEvent);

    if (!pointer || !newPoint || !oldPoint) {
      return;
    }

    // If there is an animation then cancel it
    this.getPointerState(rtEvent).animation?.cancel();

    // fill: both - used to persist the animation of the pointer after its finished
    this.getPointerState(rtEvent).animation = pointer.animate(
      [this.createKeyframe(newPoint), this.createKeyframe(oldPoint)],
      { duration: this.captureRate, fill: 'both' },
    );
  }

  private createKeyframe(point: Point) {
    const keyframe: Keyframe = {
      composite: 'replace',
      easing: 'linear',
      transform: `translate(${point.x}px, ${point.y}px)`,
    };
    return keyframe;
  }

  // Get the pointer element
  private getPointer(rtEvent: RtEvent): HTMLElement | null {
    const id = this.getSVGId(rtEvent);
    return document.getElementById(id);
  }

  private getSvgFile(activateToolbar?: string): string {
    let fileName = activateToolbar || 'pointer';
    if (['sticky-note', 'math'].includes(activateToolbar as string)) {
      fileName = 'text';
    }

    return fileName;
  }

  private modifySvgColor(svgName: string, objects: fabric.Object[], color: string): void {
    if (['pan', 'erase', 'highlight', 'text'].includes(svgName)) {
      objects[0].set({ fill: 'transparent' });
      objects[1].set({ fill: color });
      return;
    }

    if (svgName === 'shapes') {
      objects[0].set({ stroke: color });
      objects[1].set({ stroke: color });
      objects[2].set({ stroke: color });
      objects[3].set({ stroke: color });
      return;
    }

    objects[0].set({ fill: color });
  }

  // Creates the pointer SVG dynamically using fabric
  public createPointerGroup(rtEvent: RtEvent, activeToolbar: string): Promise<fabric.Group> {
    return new Promise((resolve, reject) => {
      try {
        const svgFileName = this.getSvgFile(activeToolbar);
        fabric.loadSVGFromURL(`/assets/icons/wb-cursors/${svgFileName}.svg`, (objects, options) => {
          if (!objects?.length) {
            return;
          }

          if (!rtEvent.metadata.pointerColor) {
            rtEvent.metadata.pointerColor = this.pickPointerColor();
          }

          const color = rtEvent.metadata.pointerColor;

          this.modifySvgColor(svgFileName, objects, color);
          const pointer = fabric.util.groupSVGElements(objects, options);
          const shadow = new fabric.Shadow();
          shadow.offsetY = 4;
          shadow.color = 'rgba(0, 0, 0, 0.30)';
          shadow.blur = 4;
          pointer.set({ shadow: shadow });
          const name = ` ${getTruncatedName(
            rtEvent.event.mouse.metadata.userName || rtEvent.event.mouse.metadata.email,
            30,
          )} `;

          const opts: ITextboxOptions = {
            fontSize: fontSize,
            top: 12,
            left: 34,
            backgroundColor: 'transparent',
            fontFamily: 'Source Sans Pro',
            fill: 'white',
            textAlign: 'center',
            width: name.length * (fontSize / 2),
          };
          const textBox = new fabric.Textbox(name, opts);
          textBox.setCoords();
          const boundingRect = textBox.getBoundingRect();
          const rect = new fabric.Rect({
            top: boundingRect.top - 4,
            left: boundingRect.left - 4,
            width: boundingRect.width + 8,
            height: boundingRect.height + 8,
            fill: color,
            backgroundColor: color,
            rx: 4,
            ry: 4,
          });

          const group = new fabric.Group([pointer, rect, textBox]);

          this.sharedDataService.userPointerDataEvent.next({
            pointerColor: color,
            user: rtEvent.event.user,
          });
          this.sharedDataService.usersPointerData.set(rtEvent.event.user, color);
          resolve(group);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  public pickPointerColor(): string {
    const colors = [
      '#f2994a',
      '#e5616d',
      '#a36bb2',
      '#33a787',
      '#0082f5',
      '#40CA45',
      '#19854C',
      '#6A2580',
    ];

    let filteredColors = colors;

    // if less users than colors make sure colors are unique
    const usersPointerData = this.sharedDataService.usersPointerData;
    if (usersPointerData.size < colors.length) {
      const pointerDataColors = Array.from(usersPointerData.values());
      filteredColors = colors.filter((item) => pointerDataColors.indexOf(item) < 0);
    }

    const color = filteredColors[Math.floor(Math.random() * filteredColors.length)];
    return color;
  }

  private sessionViewHandler(sessionView: SessionView) {
    this.currentSessionView = sessionView;
  }

  public togglePointers(cursorsHidden: boolean): void {
    const container = this.getPointerContainer();
    if (container) {
      container.hidden = cursorsHidden;
    }
  }
}

import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  EMPTY,
  filter,
  Observable,
  switchMap,
  tap,
} from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  CallParticipant,
  ParticipantEvent,
  ParticipantEventAction,
  TrackEvent,
  TrackEventAction,
  TrackType,
} from '../common/interfaces/rtc-interface';
import { SubjectMap } from '../sessions/common/session-data-structures';
import { FLAGS, FlagsService } from './flags.service';
import { RtcServiceController } from './rtc.service';
import { ProviderStateService } from './provider-state.service';
import { VideoTrackType } from './session-call-tracks.service';
import { TelemetryService } from './telemetry.service';
import { SessionSharedDataService } from './session-shared-data.service';

export type trackSubject = MediaStreamTrack | null | undefined;

// To put the audio element at a top level with the DOM
export const TOP_LEVEL_CONTAINER_FOR_AUDIO_ELEMENTS = 'audio-elements';

export class ParticipantTracksState {
  // Broadcasts tracks status. Isolate tracks by using different subjects to listen for a specific track while not caring for others
  // undefined -> Reset value
  // null -> track is stopped from RTC provider perspective
  // MediaStreamTrack -> track is started from RTC provider perspective, also broadcasts the track itself
  private videoTrackSubject = new BehaviorSubject<trackSubject>(undefined);
  private audioTrackSubject = new BehaviorSubject<trackSubject>(undefined);
  private screenVideoTrackSubject = new BehaviorSubject<trackSubject>(undefined);
  private screenAudioTrackSubject = new BehaviorSubject<trackSubject>(undefined);

  // Broadcasts tracks status from our perspective.
  // true -> Track is received, also we are able to play it successfully
  // false -> Track is stopped, also we stopped it/Reset value
  private videoTrackPlayingSubject = new BehaviorSubject<boolean>(false);
  private audioTrackPlayingSubject = new BehaviorSubject<boolean>(false);
  private screenVideoTrackPlayingSubject = new BehaviorSubject<boolean>(false);
  private screenAudioTrackPlayingSubject = new BehaviorSubject<boolean>(false);

  constructor() {
    /* empty constructor */
  }

  get videoTrack$(): Observable<trackSubject> {
    return this.videoTrackSubject.asObservable();
  }

  get videoTrackPlaying$(): Observable<boolean> {
    return this.videoTrackPlayingSubject.asObservable();
  }

  get audioTrack$(): Observable<trackSubject> {
    return this.audioTrackSubject.asObservable();
  }

  get audioTrackPlaying$(): Observable<boolean> {
    return this.audioTrackPlayingSubject.asObservable();
  }

  get screenVideo$(): Observable<trackSubject> {
    return this.screenVideoTrackSubject.asObservable();
  }

  get screenVideoPlaying$(): Observable<boolean> {
    return this.screenVideoTrackPlayingSubject.asObservable();
  }

  get screenAudio$(): Observable<trackSubject> {
    return this.screenAudioTrackSubject.asObservable();
  }

  get screenAudioPlaying$(): Observable<boolean> {
    return this.screenAudioTrackPlayingSubject.asObservable();
  }

  set videoTrack(val: trackSubject) {
    if (this.videoTrackSubject.value !== val) {
      this.videoTrackSubject.next(val);
    }
  }

  set videoTrackPlaying(val: boolean) {
    if (this.videoTrackPlayingSubject.value !== val) {
      this.videoTrackPlayingSubject.next(val);
    }
  }

  set audioTrack(val: trackSubject) {
    if (this.audioTrackSubject.value !== val) {
      this.audioTrackSubject.next(val);
    }
  }

  set audioTrackPlaying(val: boolean) {
    if (this.audioTrackPlayingSubject.value !== val) {
      this.audioTrackPlayingSubject.next(val);
    }
  }

  set screenVideoTrack(val: trackSubject) {
    if (this.screenVideoTrackSubject.value !== val) {
      this.screenVideoTrackSubject.next(val);
    }
  }

  set screenVideoTrackPlaying(val: boolean) {
    if (this.screenVideoTrackPlayingSubject.value !== val) {
      this.screenVideoTrackPlayingSubject.next(val);
    }
  }

  set screenAudioTrack(val: trackSubject) {
    if (this.screenAudioTrackSubject.value !== val) {
      this.screenAudioTrackSubject.next(val);
    }
  }

  set screenAudioTrackPlaying(val: boolean) {
    if (this.screenAudioTrackPlayingSubject.value !== val) {
      this.screenAudioTrackPlayingSubject.next(val);
    }
  }

  // Used to return the suitable observable for video tracks based on the track type (Video/Screen)
  // As we will need to differentiate between video, and screen sharing as we had the same component which is used for the two cases
  getGeneralVideoTrack(trackType: TrackType): Observable<trackSubject> {
    if (trackType === TrackType.VIDEO) {
      return this.videoTrack$;
    } else {
      return this.screenVideo$;
    }
  }

  getGeneralVideoTrackPlaying(trackType: TrackType): Observable<boolean> {
    if (trackType === TrackType.VIDEO) {
      return this.videoTrackPlaying$;
    } else {
      return this.screenVideoPlaying$;
    }
  }

  // Used to return the suitable observable for audio tracks based on the track type (Video/Screen)
  // As we will need to differentiate between video, and screen sharing as we had the same component which is used for the two cases
  getGeneralAudioTrack(trackType: TrackType): Observable<trackSubject> {
    if (trackType === TrackType.VIDEO) {
      return this.audioTrack$;
    } else {
      return this.screenAudio$;
    }
  }

  getGeneralAudioTrackPlaying(trackType: TrackType): Observable<boolean> {
    if (trackType === TrackType.VIDEO) {
      return this.audioTrackPlaying$;
    } else {
      return this.screenAudioPlaying$;
    }
  }

  getCurrentValueVideoTrackSubject(): trackSubject {
    return this.videoTrackSubject.getValue();
  }

  getCurrentValueScreenVideoTrackSubject(): trackSubject {
    return this.screenVideoTrackSubject.getValue();
  }

  resetTracksState() {
    this.videoTrack = undefined;
    this.audioTrack = undefined;
    this.screenVideoTrack = undefined;
    this.screenAudioTrack = undefined;

    this.videoTrackPlaying = false;
    this.audioTrackPlaying = false;
    this.screenVideoTrackPlaying = false;
    this.screenAudioTrackPlaying = false;
  }
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class VideoCallTracksStateService {
  public participantTracksStateMap: SubjectMap<ParticipantTracksState> = new SubjectMap();
  private usersDevicesStates: BehaviorSubject<{ [key: string]: { mic: boolean; cam: boolean } }> =
    new BehaviorSubject({});
  usersDevicesStates$ = this.usersDevicesStates.asObservable();

  // Corresponds to setting up the connections for a track but not transmitting data across that connection
  // This lets you "stage" tracks that you know you'll need soon, for later rapid transition into the fully "subscribed" state, without using any extra bandwidth.
  useStaged = false;

  constructor(
    private rtcService: RtcServiceController,
    private flagsService: FlagsService,
    private providerState: ProviderStateService,
    private telemetry: TelemetryService,
    private sessionSharedDataService: SessionSharedDataService,
  ) {
    this.setupEventsListeners();
    if (this.flagsService.isFlagEnabled(FLAGS.USE_STAGED_INSTEAD_OF_UNSUBSCRIBE)) {
      this.useStaged = true;
    }
  }

  // Used to listen for participants, and tracks events that received from RTC provider
  private setupEventsListeners() {
    this.providerState.participantEvents$.pipe(untilDestroyed(this)).subscribe((e) => {
      if (e) {
        this.handleParticipantEvent(e);
      }
    });

    this.providerState.trackEvents$.pipe(untilDestroyed(this)).subscribe((e) => {
      if (e) {
        this.handleTrackEvent(e);
      }
    });
  }

  private handleParticipantEvent(participantEvent: ParticipantEvent) {
    const { participant, action } = participantEvent;

    switch (action) {
      case ParticipantEventAction.JOINED:
        this.createParticipantTracksState(participant.participantId);
        // Always subscribe to audio
        if (!participant.local) {
          this.subscribe(participant.participantId, TrackType.AUDIO);
        }
        break;
      case ParticipantEventAction.UPDATED:
        // To update mic/cam states for participants manager
        this.setUserIdDevicesStatesMap(participant);
        break;
      case ParticipantEventAction.LEFT:
        this.participantTracksStateMap.get(participant.participantId).value?.resetTracksState();
        const state = this.usersDevicesStates.getValue();
        delete state[participant.participantId];
        this.usersDevicesStates.next(state);
        // Reset service states after leaving the call
        if (participant.local) {
          this.clearParticipantTracksStateMap();
          this.removeAllHtmlAudioElements();
          this.usersDevicesStates.next({});
        }
        break;
      default:
        return;
    }
  }

  private clearParticipantTracksStateMap() {
    this.participantTracksStateMap.managerKeys.getValue()?.forEach((participantId) => {
      this.participantTracksStateMap.get(participantId).next(null);
    });
  }

  private removeAllHtmlAudioElements() {
    document.getElementById(TOP_LEVEL_CONTAINER_FOR_AUDIO_ELEMENTS)?.replaceChildren();
  }

  private handleTrackEvent(trackEvent: TrackEvent) {
    const { participant, action, type } = trackEvent;

    // To check if the current started screen sharing has a playable audio or not and subscribe only for this track
    // Then un-subscribe from screenShareAuido after the screen sharing is stopped
    this.handleScreenShareAudio(participant, action, type);

    switch (action) {
      case TrackEventAction.START:
        this.handleTrackStarted(participant, type);
        break;
      case TrackEventAction.STOP:
        this.handleTrackStopped(participant, type);
        break;
      default:
        return;
    }

    // To update mic/cam states for participants manager
    this.setUserIdDevicesStatesMap(participant);
  }

  private handleScreenShareAudio(
    participant: CallParticipant,
    action: TrackEventAction,
    type: TrackType,
  ) {
    if (type === TrackType.SCREEN) {
      switch (action) {
        case TrackEventAction.START:
          this.switchScreenShareAudioSub(participant, true);
          break;
        case TrackEventAction.STOP:
          this.switchScreenShareAudioSub(participant, false);
          break;
        default:
          return;
      }
    }
  }

  private switchScreenShareAudioSub(participant: CallParticipant, enable: boolean) {
    if (this.checkIfScreenShareAudioActive(participant) && enable) {
      if (!participant.local) {
        this.subscribe(participant.participantId, TrackType.SCREEN_AUDIO);
      }
    } else if (!enable) {
      if (!participant.local) {
        this.unsubscribe(participant.participantId, TrackType.SCREEN_AUDIO);
      }
    }
  }

  private checkIfScreenShareAudioActive(participant: CallParticipant): boolean {
    return !!(participant.screenAudio && this.rtcService.trackIsShared(participant.screenAudio));
  }

  private handleTrackStarted(participant: CallParticipant, trackType: TrackType) {
    const track = this.rtcService.getTrack(participant, trackType);
    if (track) {
      this.handleBroadcastingTracks(participant.participantId, trackType, track);
    }
  }

  private handleTrackStopped(participant: CallParticipant, trackType: TrackType) {
    this.handleBroadcastingTracks(participant.participantId, trackType, null);
  }

  private handleBroadcastingTracks(
    participantId: string,
    trackType: TrackType,
    track: MediaStreamTrack | null,
  ) {
    const participantTracksStateMap = this.participantTracksStateMap.get(participantId).value;
    if (!participantTracksStateMap) {
      return;
    }
    switch (trackType) {
      case TrackType.VIDEO:
        participantTracksStateMap.videoTrack = track;
        break;
      case TrackType.AUDIO:
        participantTracksStateMap.audioTrack = track;
        break;
      case TrackType.SCREEN:
        participantTracksStateMap.screenVideoTrack = track;
        break;
      case TrackType.SCREEN_AUDIO:
        participantTracksStateMap.screenAudioTrack = track;
        break;
      default:
        break;
    }
  }

  createParticipantTracksState(participantId: string) {
    if (!this.participantTracksStateMap.get(participantId).value) {
      const participantTracksState = new ParticipantTracksState();
      this.participantTracksStateMap.get(participantId).next(participantTracksState);
    }
  }

  private setUserIdDevicesStatesMap(participant: CallParticipant): void {
    const userId = participant.userId;
    const mic = this.rtcService.trackIsShared(participant.audio);
    const cam = this.rtcService.trackIsShared(participant.video);
    const state = this.usersDevicesStates.getValue();
    state[userId] = { mic, cam };
    this.usersDevicesStates.next(state);
  }

  updateTrackSubscription(
    participantId: string,
    trackType: VideoTrackType,
    newSubscriptionState: boolean,
    bypassForceUnsubscribeInMinimizedView: boolean,
    isLocal = false,
    canChangeSubscriptionState = true,
  ) {
    if (canChangeSubscriptionState) {
      // Only skip subscribing/unsubscribing if the participant is local
      if (isLocal) {
        return;
      }

      if (newSubscriptionState) {
        if (this.canSubscribeToVideo(trackType, bypassForceUnsubscribeInMinimizedView)) {
          this.subscribe(participantId, trackType);
        }
      } else {
        if (this.useStaged) {
          this.stage(participantId, trackType);
        } else {
          this.unsubscribe(participantId, trackType);
        }
      }
    }
  }

  /**
   * return false if there are any global
   * settings to prevent subscribing to videos
   */
  private canSubscribeToVideo(
    videoTrackType: VideoTrackType,
    bypassForceUnsubscribeInMinimizedView: boolean,
  ) {
    if (
      this.sessionSharedDataService.isAppInMinimizeView() &&
      !bypassForceUnsubscribeInMinimizedView
    ) {
      return false;
    }
    const isIncomingVideoStreamsDisabled =
      this.sessionSharedDataService.disableVideoStreamChanged.getValue().disabled;
    return videoTrackType !== TrackType.VIDEO || !isIncomingVideoStreamsDisabled;
  }

  private subscribe(participantId: string, trackType: TrackType) {
    this.rtcService.service.subscribe(participantId, trackType);
  }

  private stage(participantId: string, trackType: TrackType) {
    this.rtcService.service.stage?.(participantId, trackType);
  }

  private unsubscribe(participantId: string, trackType: TrackType) {
    this.rtcService.service.unsubscribe?.(participantId, trackType);
  }

  updateTrackPlayingState(participantId: string, trackType: TrackType, isPlaying: boolean) {
    const participantTracksStateMap = this.participantTracksStateMap.get(participantId).value;
    if (!participantTracksStateMap) {
      return;
    }
    switch (trackType) {
      case TrackType.VIDEO:
        participantTracksStateMap.videoTrackPlaying = isPlaying;
        break;
      case TrackType.AUDIO:
        participantTracksStateMap.audioTrackPlaying = isPlaying;
        break;
      case TrackType.SCREEN:
        participantTracksStateMap.screenVideoTrackPlaying = isPlaying;
        break;
      case TrackType.SCREEN_AUDIO:
        participantTracksStateMap.screenAudioTrackPlaying = isPlaying;
        break;
      default:
        break;
    }
  }

  // We will pass only VIDEO/SCREEN_VIDEO as a TrackType
  // In case of audio, we will use the following mapping
  // VIDEO -> AUDIO
  // SCREEN_VIDEO -> SCREEN_AUDIO
  getPariticpantTrackObservable(
    participantId: string,
    trackType: TrackType,
    context: any,
    isAudio = false,
  ): Observable<trackSubject> {
    return this.participantTracksStateMap.get(participantId).pipe(
      untilDestroyed(context),
      filter(
        (participantTracksStateMap) =>
          participantTracksStateMap !== null && participantTracksStateMap !== undefined,
      ),
      switchMap((participantTracksStateMap) => {
        if (!isAudio) {
          return participantTracksStateMap?.getGeneralVideoTrack(trackType) ?? EMPTY;
        }
        return participantTracksStateMap?.getGeneralAudioTrack(trackType) ?? EMPTY;
      }),
    );
  }

  // We will pass only VIDEO/SCREEN_VIDEO as a TrackType
  // In case of audio, we will use the following mapping
  // VIDEO -> AUDIO
  // SCREEN_VIDEO -> SCREEN_AUDIO
  getPariticpantTrackPlayingObservable(
    participantId: string,
    trackType: TrackType,
    context: any,
    isAudio = false,
  ): Observable<boolean> {
    return this.participantTracksStateMap.get(participantId).pipe(
      untilDestroyed(context),
      filter(
        (participantTracksStateMap) =>
          participantTracksStateMap !== null && participantTracksStateMap !== undefined,
      ),
      switchMap((participantTracksStateMap) => {
        if (!isAudio) {
          return participantTracksStateMap?.getGeneralVideoTrackPlaying(trackType) ?? EMPTY;
        }
        return participantTracksStateMap?.getGeneralAudioTrackPlaying(trackType) ?? EMPTY;
      }),
      distinctUntilChanged(),
      tap((state) => {
        if (state) {
          this.renderedIncomingVideos.add(participantId);
          if (trackType === TrackType.SCREEN) {
            this.renderedIncomingScreenShares.add(participantId);
          }
        } else {
          this.renderedIncomingVideos.delete(participantId);
          if (trackType === TrackType.SCREEN) {
            this.renderedIncomingScreenShares.delete(participantId);
          }
        }

        this.setVideoCallSessionVars();
      }),
    );
  }

  private renderedIncomingVideos = new Set<string>();
  private renderedIncomingScreenShares = new Set<string>();
  private setVideoCallSessionVars(): void {
    this.telemetry.setSessionVars({
      count_incoming_videos:
        this.renderedIncomingVideos.size + this.renderedIncomingScreenShares.size,
      count_incoming_screenshares: this.renderedIncomingScreenShares.size,
    });
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { environment } from '../../environments/environment';

interface ConvertedFileResponse {
  url: string;
  size: number;
}

@Injectable({
  providedIn: 'root',
})
export class PDFConverterService {
  constructor(private http: HttpClient) {}

  convertToPDF(fileURL: string): Observable<ConvertedFileResponse> {
    const postBody = new URLSearchParams();
    postBody.set('url', fileURL);

    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
    };

    const urlToPost = `${environment.pdfConverterServer}`;
    return this.http.post<any>(urlToPost, postBody.toString(), options).pipe(retry(3));
  }
}

import { EventEmitter, Injectable, Optional } from '@angular/core';
import {
  Auth,
  signOut,
  onAuthStateChanged,
  verifyPasswordResetCode,
  confirmPasswordReset,
  linkWithCredential,
  fetchSignInMethodsForEmail,
  createUserWithEmailAndPassword,
  OAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  User as IFBUser,
  AuthCredential,
  UserCredential,
  checkActionCode,
  applyActionCode,
  signInWithEmailAndPassword,
  SAMLAuthProvider,
  signInWithEmailLink,
  AuthErrorCodes,
  signInWithCustomToken,
  signInWithRedirect,
  signInWithCredential,
  signInAnonymously,
  updateProfile,
  updateEmail,
} from '@angular/fire/auth';
import * as Sentry from '@sentry/browser';
import { BehaviorSubject, filter, from, Observable, of, take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CustomErrorCodes } from 'src/custom_error_codes.constants';
import { isEmpty } from 'lodash-es';
import { environment } from 'src/environments/environment';
import { LogoutDialogComponent } from '../dialogs/logout/logout.component';
import {
  extractReferralParams,
  extractTemplateIdParam,
  extractUTMParams,
  LANDING_URL,
} from '../common/utils/url';
import { User as IUser } from '../models/user';
import { ERRORS } from '../common/utils/notification-constants';
import { ToasterPopupStyle } from '../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { IconMessageToasterElement } from '../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { EmbeddedGoogleLoginComponent } from '../dialogs/embedded-google-login/embedded-google-login.component';
import { AccountsConstantsTransformer, PROVIDERS_ALL } from '../account/account.constants';
import { modifiedSetTimeout } from '../utilities/ZoneUtils';
import { UserService } from './user.service';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from './notification-toaster.service';
import { TelemetryService } from './telemetry.service';

export enum LOGIN_TYPE {
  SignIn = 'SignIn',
  SignUp = 'SignUp',
  APISignIn = 'api',
  TutorCruncherSignIn = 'tutorcrunchersignin',
  Clever = 'clever',
  Classlink = 'classlink',
}

export interface SignInParameters {
  login_hint?: string;
  type: LOGIN_TYPE;
  authString?: string;
  doRedirect?: boolean;
  token?: string;
  continueUrl?: string;
  redirectUrl?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  fbUser: BehaviorSubject<IFBUser | null | undefined> = new BehaviorSubject<
    IFBUser | null | undefined
  >(undefined);
  loading: Promise<void>;
  providerMap = {
    'google.com': this.doGoogleLogin,
    'facebook.com': this.doFacebookLogin,
    'microsoft.com': this.doMicrosoftLogin,
    oidc: this.doOIDCLogin,
    saml: this.doSAMLLogin,
  };
  continueUrl!: string;
  justSignedOut = false;
  emailVerified = false;
  logOutEmitter = new EventEmitter();
  PROVIDERS_TO_NAMES;

  constructor(
    @Optional() private auth: Auth,
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private notificationsService: NotificationToasterService,
    private telemetry: TelemetryService,
  ) {
    let hash = window.location.hash?.substring(1);
    if (hash === LOGIN_TYPE.SignUp || hash === LOGIN_TYPE.SignIn) {
      this.userService.appLoading.next(true);
    }

    const accountsConstants = new AccountsConstantsTransformer(this.translate);
    this.PROVIDERS_TO_NAMES = accountsConstants.providersToNames;

    // hide after 10 seconds if still on login and no change took place
    modifiedSetTimeout(() => {
      if (hash === LOGIN_TYPE.SignUp || hash === LOGIN_TYPE.SignIn) {
        this.userService.appLoading.next(false);
      }
    }, 10000);
    this.loading = new Promise((resolve, reject) => {
      onAuthStateChanged(this.auth, async (user) => {
        if (user) {
          const urlParams = new URLSearchParams(location.search);
          if (!(urlParams.has('loginMethod') && urlParams.get('loginMethod') == 'token')) {
            try {
              await this.handleFirebaseUser(user, hash as LOGIN_TYPE);
              // If the hash isn't set to empty, when the auth changes (token expiry etc)
              // it will try to handle the user as a full login again instead of simply
              // refreshing the token
              hash = '';
            } catch (error) {
              reject(error);
            }
          }
        } else {
          // otherwise design breaks for a split second before being redirected to login
          if (!this.justSignedOut) {
            this.fbUser.next(null);
          }
          this.justSignedOut = true;
          this.logOutEmitter.emit();
        }
        resolve();
      });
    });
  }

  public getRedirectUrl(): string {
    // If the continueUrl provided is provided in the current URL, use it. Otherwise, redirect to appropriate
    // destination based on user type
    let continueUrl = this.activatedRoute.snapshot.queryParams['continueUrl'];
    if (!continueUrl) {
      const secondaryContinueUrl = this.router.parseUrl(location.pathname + location.search)
        .queryParams?.continueUrl;
      continueUrl = secondaryContinueUrl ? secondaryContinueUrl : LANDING_URL;
    }

    // append any remaining params from original url to continueUrl
    const continueUrlObj = new URL(continueUrl, window.location.origin);

    const params = Object.assign(
      extractUTMParams(this.activatedRoute.snapshot.queryParamMap),
      extractReferralParams(this.activatedRoute.snapshot.queryParamMap),
      extractTemplateIdParam(this.activatedRoute.snapshot.queryParamMap),
      continueUrlObj.searchParams,
    );

    const pathname = continueUrlObj.pathname;
    if (!isEmpty(params)) {
      const paramsObj = new URLSearchParams(params);
      return `${pathname}?${paramsObj.toString()}`;
    }
    return pathname;
  }

  public getAbsoluteRedirectUrl(): URL {
    return new URL(this.getRedirectUrl(), window.location.origin);
  }

  async handleFirebaseUser(user: IFBUser, loginType: LOGIN_TYPE, redirectUrl?: string) {
    this.fbUser.next(user);

    return new Promise<IUser | undefined>((resolve, reject) => {
      if (loginType === LOGIN_TYPE.SignIn || loginType === LOGIN_TYPE.SignUp) {
        // If the user just signed in, we need to handle sign up and redirect to the requested URL.
        this.userService.createUser(user.providerData[0]?.providerId !== 'password').subscribe({
          next: (res) => {
            if (redirectUrl) {
              this.router.navigateByUrl(redirectUrl);
            } else {
              this.continueUrl = this.getRedirectUrl();
              this.router.navigateByUrl(this.continueUrl);
            }
            resolve(res);
          },
          error: (err) => {
            reject(null);
          },
        });
      } else {
        resolve(undefined);
      }
    });
  }

  get firebaseUser(): Observable<IFBUser | null | undefined> {
    return this.fbUser.asObservable();
  }

  async refreshUser() {
    return this.auth?.currentUser?.reload();
  }

  async userName(): Promise<string> {
    const displayName = this.auth.currentUser?.displayName;
    if (displayName) {
      return displayName;
    }
    return 'None';
  }

  photo(): string {
    const photoURL = this.auth.currentUser?.photoURL;
    if (photoURL) {
      return photoURL;
    }
    return '';
  }

  getToken(): Observable<string> {
    const idToken = this.auth.currentUser?.getIdToken();
    if (idToken) {
      return from(idToken);
      // Return from(this.fireuser.getIdToken(true));
    } else {
      return of('');
    }
  }

  getRefreshToken(): string {
    return this.auth.currentUser?.refreshToken ?? '';
  }

  async currentToken(): Promise<string | undefined> {
    const idToken = await this.auth.currentUser?.getIdToken();
    return idToken;
  }

  verifyPasswordResetCode(code: string): Promise<string> {
    return verifyPasswordResetCode(this.auth, code);
  }

  passwordReset(code: string, newPassword: string) {
    return confirmPasswordReset(this.auth, code, newPassword);
  }

  public async anonymousLogin(displayName: string) {
    const { user: fbUser } = await signInAnonymously(this.auth);
    if (!fbUser) {
      throw new Error('Unable to create user');
    }
    await updateProfile(fbUser, { displayName });
    await updateEmail(fbUser, `${fbUser.uid}@anonymous.pencilspaces.com`);
    await this.handleFirebaseUser(fbUser, LOGIN_TYPE.SignUp);
  }

  async doEmailSignup(
    {
      email,
      password,
    }: {
      email: string;
      password: string;
    },
    verifyToken = '',
  ): Promise<void> {
    const { user: fbUser } = await createUserWithEmailAndPassword(this.auth, email, password);
    if (!fbUser) {
      throw new Error('Unable to create user');
    }

    if (verifyToken) {
      this.userService
        .verifyToken(verifyToken, email)
        .pipe(take(1))
        .subscribe({
          next: async () => {
            await this.doLogin({ email, password }, true);
            this.userService.sendWelcomeEmail().pipe(take(1)).subscribe();
          },
          error: () => {
            // In case of invalid token, proceed normally
            this.handleFirebaseUser(fbUser, LOGIN_TYPE.SignUp);
          },
        });
    } else {
      await this.handleFirebaseUser(fbUser, LOGIN_TYPE.SignUp);
    }
  }

  async doLogout() {
    const user = this.getCurrentUser();
    if (user) {
      await this.signout();
      await this.fbUser.next(null);
    }
  }

  async doTokenLogin(params: SignInParameters) {
    if (params.token) {
      await signInWithCustomToken(this.auth, params.token)
        .then(async (result) => {
          this.handleFirebaseUser(result.user, params.type, params.continueUrl);
        })
        .catch((e) => {
          console.log(e);
          this.handlePopupError(e);
          throw new Error(e);
        });
    }
  }

  async loginWithToken(token: string) {
    try {
      await signInWithCustomToken(this.auth, token);
      location.assign(this.getRedirectUrl());
      return true;
    } catch (error) {
      error.code = 'INVALID_TOKEN';
      console.log(error);
      return this.handleTokenLoginError();
    }
  }

  async doGoogleLogin(params: SignInParameters) {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');

    let customParams = {
      prompt: 'select_account',
    };
    const hint = params.login_hint;
    if (hint) {
      customParams = {
        ...customParams,
        ...{ hint },
      };
    }

    provider.setCustomParameters(customParams);

    // detect embedded webview
    // http://jsfiddle.net/ThinkingStiff/6qrbn/
    const iosStandalone = window.navigator['standalone'];
    const userAgent = window.navigator.userAgent.toLowerCase();
    // The line below evaluates to true for all ios browsers, not just safari
    const iosBrowser = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);

    if ((ios && !iosStandalone && !iosBrowser) || (!ios && userAgent.includes('wv'))) {
      this.dialog.open(EmbeddedGoogleLoginComponent, {
        width: '330px',
      });
    } else {
      try {
        const result = await signInWithPopup(this.auth, provider);
        if (result.user) {
          this.handleFirebaseUser(result.user, params.type);
        }
      } catch (e) {
        e.customData = { provider: PROVIDERS_ALL.GOOGLE };
        this.handlePopupError(e);
        throw new Error(e);
      }
    }
  }

  handleClassLinkLogin() {
    window.location.href = `https://launchpad.classlink.com/oauth2/v2/auth?scope=profile&response_type=code&redirect_uri=${encodeURIComponent(
      environment.classlink.redirectURI,
    )}&client_id=${environment.classlink.clientId}`;
  }

  handleCleverLogin() {
    window.location.href = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${encodeURIComponent(
      environment.clever.redirectURI,
    )}&client_id=${environment.clever.clientId}`;
  }

  async doOIDCLogin(params: SignInParameters) {
    if (!params.authString) {
      return;
    }
    const provider = new OAuthProvider(`oidc.${params.authString}`);
    try {
      if (params.token) {
        const rawCredential = {
          idToken: params.token,
        };
        const credential = provider.credential(rawCredential);
        const result = await signInWithCredential(this.auth, credential);
        this.handleFirebaseUser(result.user, params.type);
      } else if (params.doRedirect) {
        await signInWithRedirect(this.auth, provider);
      } else {
        const result = await signInWithPopup(this.auth, provider);
        this.handleFirebaseUser(result.user, params.type);
      }
    } catch (e) {
      e.customData = { provider: `oidc.${params.authString}` };
      // Token error is handled separately
      if (!params.token) {
        console.log(e);
        this.handlePopupError(e);
      }
      throw e;
    }
  }

  async doMicrosoftLogin(params: SignInParameters) {
    const provider = new OAuthProvider('microsoft.com');
    provider.addScope('User.Read');

    try {
      const result = await signInWithPopup(this.auth, provider);
      if (result.user) {
        this.handleFirebaseUser(result.user, params.type);
      }
    } catch (e) {
      e.customData = { provider: PROVIDERS_ALL.MICROSOFT };
      this.handlePopupError(e);
      throw new Error(e);
    }
  }

  async doFacebookLogin(params: SignInParameters) {
    const provider = new FacebookAuthProvider();
    provider.addScope('public_profile');
    provider.addScope('email');
    let customParams = {
      prompt: 'select_account',
    };
    const hint = params.login_hint;
    if (hint) {
      customParams = {
        ...customParams,
        ...{ hint },
      };
    }
    provider.setCustomParameters(customParams);
    try {
      const result = await signInWithPopup(this.auth, provider);
      if (result.user) {
        this.handleFirebaseUser(result.user, params.type);
      }
    } catch (e) {
      e.customData = {
        ...e.customData,
        ...{ provider: PROVIDERS_ALL.FACEBOOK },
      };
      this.handlePopupError(e);
      throw e;
    }
  }

  async doSAMLLogin(params: SignInParameters) {
    if (params.authString) {
      const provider = new SAMLAuthProvider(`saml.${params.authString}`);
      this.userService.appLoading.next(false);
      await signInWithPopup(this.auth, provider)
        .then((result) => {
          if (result.user) {
            this.handleFirebaseUser(result.user, params.type);
          }
        })
        .catch((e) => {
          e.customData = { provider: `saml.${params.authString}` };
          this.handlePopupError(e);
          throw new Error(e);
        });
    }
  }

  /** Sign in with firebase, create Pencil account if !exists */
  async doLogin(value, verify = false): Promise<void> {
    this.userService.appLoading.next(true);
    await signInWithEmailAndPassword(this.auth, value.email, value.password);
    this.userService.getUser().subscribe({
      // if !pencil_user
      //    try createUser() -> new space
      //    catch -> redirect to login
      next: (userInfo) => {
        this.userService.appLoading.next(false);
        if (verify || !userInfo.status_code) {
          if (verify) {
            const updatedUser = this.getCurrentUser();
            this.fbUser.next(updatedUser);
          }

          const redirectUrl = this.getRedirectUrl();
          this.router.navigateByUrl(redirectUrl);
        } else if (userInfo.status_code === CustomErrorCodes.ACCOUNT_NOT_SIGNED_UP) {
          this.userService.createUser(false).subscribe({
            next: (user) => {
              const redirectUrl = this.getRedirectUrl();
              this.router.navigateByUrl(redirectUrl);
            },
            error: (e) => {
              Sentry.captureException(new Error('Error creating user'), {
                extra: {
                  error: e,
                },
              });
              this.router.navigate(['account', 'login']);
            },
          });
        }
      },
      error: () => {
        this.userService.appLoading.next(false);
      },
    });
  }

  async verifyFirebaseAccount(code: string) {
    return applyActionCode(this.auth, code);
  }

  checkFirebaseToken(code: string) {
    return checkActionCode(this.auth, code);
  }

  getProviders(email: string): Promise<string[]> {
    return fetchSignInMethodsForEmail(this.auth, email);
  }

  /**
   * It links existing account with new provider
   * @param newCredential new Credential from a trial to sign in using the new Provider.
   * @param oldProvider the name of the old provider to log in through it
   * @param(Auth, email)to set a Email hint in the pop-up window.
   * @returns Promise of User credential.
   */
  async link(newCredential: AuthCredential, oldProvider: string, email: string) {
    this.continueUrl = this.activatedRoute.snapshot.queryParams['continueUrl'] || 'content';

    if (oldProvider.includes('saml')) {
      oldProvider = 'saml';
    }
    if (oldProvider.includes('oidc')) {
      oldProvider = 'oidc';
    }

    // In case of unsupported provider
    if (!this.providerMap[oldProvider]) {
      throw new Error(`Provider ${oldProvider} is not supported`);
    }
    // Login using the old provider.
    const cred = await this.providerMap[oldProvider](email);
    const currentUser = cred.user;

    if (!currentUser) {
      throw new Error('Login Fails.');
    }
    await this.linkWithCredential(currentUser, newCredential);
    location.href = this.continueUrl;
  }

  linkWithCredential(user: IFBUser, credential: AuthCredential): Promise<UserCredential> {
    return linkWithCredential(user, credential);
  }

  logout(afterLogOutPath: string = '/account'): void {
    const dialogRef = this.dialog.open(LogoutDialogComponent, {
      width: '340px',
      panelClass: 'logout-dialog-container',
    });

    dialogRef
      .afterClosed()
      .pipe(filter((result) => result))
      .subscribe(async () => {
        this.justSignedOut = true;
        await signOut(this.auth);
        this.fbUser.next(null);
        location.href = afterLogOutPath;
      });
  }

  /**  is email used (includes all providers) */
  async isEmailUsed(email: string): Promise<boolean> {
    const roles = await fetchSignInMethodsForEmail(this.auth, email);
    return !!roles.length;
  }

  requestPasswordReset(email: string) {
    return new Promise((resolve, reject) => {
      this.userService
        .sendPasswordResetRequest({
          email: email,
        })
        .subscribe({
          next: () => {
            resolve(true);
          },
          error: (e) => {
            reject(e);
          },
        });
    });
  }

  signInWithEmailLink(email: string, emailLink: string) {
    return signInWithEmailLink(this.auth, email, emailLink);
  }

  signInWithCustomToken(customToken: string) {
    return signInWithCustomToken(this.auth, customToken);
  }

  getCurrentUser() {
    return this.auth.currentUser;
  }

  async signout() {
    this.auth?.signOut();
    await this.auth?.currentUser?.reload();
  }

  async signOutForAPILogin() {
    await this.auth?.signOut();
  }

  showSuccessToast(message?: { title: string; description: string }): void {
    if (!message) {
      return;
    }

    const titleElement = new IconMessageToasterElement(
      undefined,
      this.translate.instant(message.title),
    );
    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translate.instant(message.description),
    );

    const successNotification = new NotificationDataBuilder(ERRORS.UNSUPPORTED_SAML_PROVIDER)
      .style(ToasterPopupStyle.SUCCESS)
      .type(NotificationType.SUCCESS)
      .timeOut(5)
      .topElements([titleElement])
      .middleElements([messageElement])
      .dismissable(true)
      .build();
    this.notificationsService.showNotification(successNotification);
  }

  handleError(error): void {
    if (error.code === AuthErrorCodes.NETWORK_REQUEST_FAILED) {
      const titleElement = new IconMessageToasterElement(
        undefined,
        this.translate.instant('Network disconnected'),
      );
      const messageElement = new IconMessageToasterElement(
        undefined,
        this.translate.instant('Please check your internet connection and try again.'),
      );

      const networkDisconnectedError = new NotificationDataBuilder(ERRORS.NETWORK_DISCONNECTED)
        .style(ToasterPopupStyle.ERROR)
        .type(NotificationType.ERROR)
        .timeOut(10)
        .topElements([titleElement])
        .middleElements([messageElement])
        .dismissable(true)
        .build();
      this.notificationsService.showNotification(networkDisconnectedError);
    } else if (error.code === 'INVALID_TOKEN') {
      const titleElement = new IconMessageToasterElement(
        undefined,
        this.translate.instant('Invalid credentials'),
      );
      const messageElement = new IconMessageToasterElement(
        undefined,
        this.translate.instant(
          "Sorry! We weren't able to log you in. Please try another log-in option.",
        ),
      );
      const invalidCredentialsError = new NotificationDataBuilder(ERRORS.AUTH_FAILED)
        .style(ToasterPopupStyle.ERROR)
        .type(NotificationType.ERROR)
        .timeOut(10)
        .topElements([titleElement])
        .middleElements([messageElement])
        .dismissable(true)
        .build();
      this.notificationsService.showNotification(invalidCredentialsError);
      this.userService.appLoading.next(false);
      this.router.navigate(['account', 'login'], {
        queryParams: {
          reset: true,
          continueUrl: this.activatedRoute.snapshot.queryParams['continueUrl'], // Preserve continue URL
        },
        skipLocationChange: true,
      });
    }
  }

  handleTokenLoginError(): Promise<boolean> {
    this.telemetry.log('error', 'INVALID_TOKEN: API user sign in failed');
    const titleMessage = this.translate.instant('Invalid credentials');
    const contentMessage = this.translate.instant(
      "Sorry! We weren't able to log you in. Please try another log-in option.",
    );
    return new Promise((resolve) => {
      this.notificationsService.showDefaultErrorNotificationV2(
        titleMessage,
        contentMessage,
        ERRORS.CSV_UPLOAD_FAILED,
        undefined,
        [],
        5,
        true,
        () => {
          location.assign(`/account/login?continueUrl=${this.getRedirectUrl()}`);
          resolve(false);
        },
      );
    });
  }

  handlePopupError(err) {
    window.location.hash = '';
    this.fbUser.next(null);
    this.justSignedOut = true;

    switch (err.code) {
      case AuthErrorCodes.USER_CANCELLED:
      case AuthErrorCodes.POPUP_CLOSED_BY_USER:
        // User cancelled on purpose,
        // Display no error and go back to the page they started from
        break;
      case AuthErrorCodes.NEED_CONFIRMATION:
        // This error is handled in the account entry service
        break;
      case AuthErrorCodes.POPUP_BLOCKED:
        const popupBlockedTitle = new IconMessageToasterElement(
          undefined,
          this.translate.instant('Popup blocked'),
        );
        const popupBlockedMessage = new IconMessageToasterElement(
          undefined,
          this.translate.instant(
            'The login popup was blocked! Please enable popups and try again.',
          ),
        );

        const popupBlockedError = new NotificationDataBuilder(ERRORS.UNSUPPORTED_FB_ACCOUNT)
          .style(ToasterPopupStyle.ERROR)
          .type(NotificationType.ERROR)
          .timeOut(10)
          .topElements([popupBlockedTitle])
          .middleElements([popupBlockedMessage])
          .dismissable(true)
          .build();
        this.notificationsService.showNotification(popupBlockedError);
        break;
      case AuthErrorCodes.TIMEOUT:
        const titleElement = new IconMessageToasterElement(
          undefined,
          this.translate.instant('Login attempt timed out'),
        );
        const messageElement = new IconMessageToasterElement(
          undefined,
          this.translate.instant('The server took too long to respond. Please try again later.'),
        );

        const timeoutError = new NotificationDataBuilder(ERRORS.UNSUPPORTED_FB_ACCOUNT)
          .style(ToasterPopupStyle.ERROR)
          .type(NotificationType.ERROR)
          .timeOut(10)
          .topElements([titleElement])
          .middleElements([messageElement])
          .dismissable(true)
          .build();
        this.notificationsService.showNotification(timeoutError);

        break;
      default:
        let provider = err.customData.provider;
        if (Object.values(PROVIDERS_ALL).includes(err.customData.provider)) {
          provider = this.PROVIDERS_TO_NAMES[err.customData.provider];
        }
        if (provider.startsWith('saml.') || provider.startsWith('oidc.')) {
          provider = provider.slice(5);
        }

        const genericTitleElement = new IconMessageToasterElement(
          undefined,
          this.translate.instant('Error with Login'),
        );
        const genericMessageElement = new IconMessageToasterElement(
          undefined,
          this.translate.instant("We're having trouble logging you in with ") +
            provider +
            this.translate.instant('. Please try again later or use another login method.'),
        );

        const genericRedirectError = new NotificationDataBuilder(ERRORS.UNSUPPORTED_FB_ACCOUNT)
          .style(ToasterPopupStyle.ERROR)
          .type(NotificationType.ERROR)
          .timeOut(10)
          .topElements([genericTitleElement])
          .middleElements([genericMessageElement])
          .dismissable(true)
          .build();
        this.notificationsService.showNotification(genericRedirectError);
        break;
    }
    Sentry.captureException(err);
    this.userService.appLoading.next(false);
  }
}

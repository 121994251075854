import { Component, Inject, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import * as RecordRTC from 'recordrtc';
import { lastValueFrom, takeWhile } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../../services/user.service';
import {
  GISError,
  GISErrorReason,
  YoutubeUploaderService,
} from '../../wb-video-controls/YoutubeApi';

@UntilDestroy()
@Component({
  selector: 'app-wb-recorder-youtube-upload',
  templateUrl: './wb-recorder-youtube-upload.component.html',
  styleUrls: ['./wb-recorder-youtube-upload.component.scss'],
})
export class WbRecorderYoutubeUploadComponent {
  public form: UntypedFormGroup;
  public signedIn = false;
  public privacyStatus = 'public';
  signingIn = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,
    private youtubeUploaderService: YoutubeUploaderService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { file: File },
    @Optional() public dialogRef: MatDialogRef<WbRecorderYoutubeUploadComponent>,
  ) {
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      description: [''],
      privacyStatus: ['public', Validators.required],
    });
    this.form.patchValue({ title: this.data.file.name });

    this.youtubeUploaderService.gisClient.signedIn
      .pipe(untilDestroyed(this))
      .subscribe((signedIn) => (this.signedIn = signedIn));
  }

  dismiss() {
    this.dialogRef.close();
  }

  confirm() {
    const { title, description, privacyStatus } = this.form.value;
    this.userService.isUploading.next(true);
    this.youtubeUploaderService
      .uploadVideo(title, description, privacyStatus, this.data.file, true)
      .catch((err: GISError) => {
        const msg =
          err.reason === GISErrorReason.YOUTUBE_SIGNUP_REQUIRED
            ? this.translate.instant('Please create a Youtube channel before uploading.')
            : this.translate.instant('Error uploading Youtube video');
        this.toastrService.error(msg, '', {
          toastClass: 'custom-toast',
          positionClass: 'toast-top-center',
          closeButton: true,
        });

        // Ensure the recording is not lost by saving it as a file
        RecordRTC.invokeSaveAsDialog(this.data.file, this.data.file.name);
        this.userService.isUploading.next(false);
        throw err;
      })
      .then(() => {
        this.toastrService.success(this.translate.instant('Youtube video uploaded'), '', {
          toastClass: 'custom-toast',
          positionClass: 'toast-top-center',
          closeButton: true,
        });
        this.userService.isUploading.next(false);
      });
    this.dialogRef.close();
  }

  async signIn() {
    this.signingIn = true;
    try {
      await lastValueFrom(
        this.youtubeUploaderService.gisClient.initialized.pipe(takeWhile((x) => x !== true, true)),
      );

      if (this.youtubeUploaderService.gisClient.signedIn.getValue()) {
        return;
      }

      this.youtubeUploaderService.gisClient.signIn();
    } catch (err) {
      console.log(err);
    }
    this.signingIn = false;
  }

  async signOut() {
    await lastValueFrom(
      this.youtubeUploaderService.gisClient.initialized.pipe(takeWhile((x) => x !== true, true)),
    );
    if (!this.youtubeUploaderService.gisClient.signedIn.getValue()) {
      return;
    }

    this.youtubeUploaderService.gisClient.signOut();
  }

  changePrivacyStatus(e) {
    this.privacyStatus = e.value;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { SpaceRecording } from '../models/space-recording';

@Pipe({
  name: 'recordingEndingTime',
})
export class RecordingEndingTimePipe implements PipeTransform {
  transform(recording: SpaceRecording): Date {
    const endingTime = new Date(recording.startingTime);
    endingTime.setSeconds(endingTime.getSeconds() + recording.duration);
    return endingTime;
  }
}

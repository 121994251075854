import { Pipe, PipeTransform } from '@angular/core';

const Explanations = [
  { type: 'SOLUTION', name: 'Explanation', icon: 'sort' },
  { type: 'HINT', name: 'Hints', icon: 'lightbulb' },
  { type: 'TRICKS', name: 'Speed tricks', icon: 'av_timer' },
  { type: 'MORE', name: 'Bonus Content', icon: 'lightbulb' },
  { type: 'HOWTO', name: 'How to teach?', icon: 'school' },
  { type: 'RUBRIC', name: 'Scoring rubric', icon: 'lightbulb' },
];

@Pipe({
  name: 'explanation',
})
export class ExplanationPipe implements PipeTransform {
  transform(type: string): any {
    return Explanations.find((e) => e.type === type);
  }
}

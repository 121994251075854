export interface Theme {
  sidebar?: {
    logo?: {
      logoType?: SidebarLogoType;
      // This is the color for the solid logo
      color?: string;
    };
  };
  colors?: { [key: string]: string };
}

export enum SidebarLogoType {
  light,
  dark,
  solid,
}

export const Themes = {
  default: {
    colors: {
      '--switcher-background': '#FFF',
      '--sidebar-background': '#FFF',
      '--sidebar-active-background': '#2f80ed',
    },
  },
};

export const setThemeColors = (theme: Theme): void => {
  if (!theme?.colors) {
    return;
  }

  for (const [key, value] of Object.entries(theme?.colors)) {
    document.documentElement.style.setProperty(key, value);
  }
};

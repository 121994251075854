import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { Angulartics2Module } from 'angulartics2';

import { LoginActivate } from './login.guard';
import { UserActivate } from './user.guard';
import { ThirdPartyLoginGuard } from './guards/third-party-login.guard';
import { CustomTokenProviderComponent } from './account/account-entry/custom-token-provider/custom-token-provider.component';

const routes: Routes = [
  {
    path: 'token-login',
    canActivate: [ThirdPartyLoginGuard],
    pathMatch: 'full',
    // CustomTokenProviderComponent serves solely as a placeholder and will never be instantiated, since ThirdPartyLoginGuard always returns false for this route.
    // Angular requires a component or module to define a route, so this component satisfies that requirement.
    // We cannot use redirectTo here because it does not work with canActivate guards.
    // In the future, engineers can safely remove or modify CustomTokenProviderComponent without considering its use in this context, as it has no impact on the application's behavior.
    component: CustomTokenProviderComponent,
  },
  {
    path: 'account',
    canLoad: [LoginActivate],
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
  },
  {
    path: '',
    canLoad: [LoginActivate],
    canActivate: [UserActivate],
    pathMatch: 'full',
    loadChildren: () => import('./sessions/sessions.module').then((m) => m.SessionsModule),
  },
  {
    path: 'users',
    canLoad: [LoginActivate],
    canActivate: [UserActivate],
    loadChildren: () => import('./users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'content',
    canLoad: [LoginActivate],
    canActivate: [UserActivate],
    loadChildren: () => import('./content/content.module').then((m) => m.ContentModule),
  },
  {
    path: 'pencilcam',
    canLoad: [LoginActivate],
    canActivate: [UserActivate],
    loadChildren: () => import('./pencil-cam/pencil-cam.module').then((m) => m.PencilCamModule),
  },
  {
    path: 'notifications',
    canLoad: [LoginActivate],
    canActivate: [UserActivate],
    loadChildren: () =>
      import('./notifications/notifications.module').then((m) => m.NotificationsModule),
  },
  {
    path: 'settings',
    canLoad: [LoginActivate],
    canActivate: [UserActivate],
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'messages',
    canLoad: [LoginActivate],
    canActivate: [UserActivate],
    loadChildren: () => import('./messages/messages.module').then((m) => m.MessagesModule),
  },
  {
    path: 'schedule',
    canLoad: [LoginActivate],
    canActivate: [UserActivate],
    loadChildren: () => import('./schedule/schedule.module').then((m) => m.ScheduleModule),
  },
  {
    path: 'analytics',
    canLoad: [LoginActivate],
    canActivate: [UserActivate],
    loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'admin',
    canLoad: [LoginActivate],
    canActivate: [UserActivate],
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'profiles',
    canLoad: [LoginActivate],
    canActivate: [UserActivate],
    loadChildren: () => import('./profiles/profiles.module').then((m) => m.ProfilesModule),
  },
  {
    path: 'practice',
    canLoad: [LoginActivate],
    canActivate: [UserActivate],
    loadChildren: () => import('./practice/practice.module').then((m) => m.PracticeModule),
  },
  {
    path: 'spaces',
    canLoad: [LoginActivate],
    canActivate: [UserActivate],
    loadChildren: () => import('./sessions/sessions.module').then((m) => m.SessionsModule),
  },
  {
    path: 'techcheck',
    canLoad: [LoginActivate],
    canActivate: [UserActivate],
    loadChildren: () => import('./techcheck/techcheck.module').then((m) => m.TechcheckModule),
  },
  {
    path: 'recording',
    canLoad: [LoginActivate],
    canActivate: [UserActivate],
    loadChildren: () =>
      import('./recording-viewer/recording-viewer.module').then((m) => m.RecordingViewerModule),
  },
  // Redirect older "sessions" urls to "spaces"
  {
    path: 'sessions',
    redirectTo: 'spaces',
  },
  {
    path: 'sessions/:id',
    redirectTo: 'spaces/:id',
  },
  {
    path: 'sessions/new',
    redirectTo: 'spaces/new',
  },
  {
    path: '**',
    redirectTo: '/spaces',
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: NoPreloading,
      relativeLinkResolution: 'legacy',
    }),
    Angulartics2Module.forRoot(),
  ],
})
export class AppRoutingModule {}

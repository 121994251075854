import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSliderModule } from '@angular/material/slider';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';

import { ChartsComponent } from '../charts/charts.component';
import { UiModule } from '../ui/ui.module';
import { PipeModule } from '../pipes/pipes.module';
import { TooltipDirective } from '../standalones/directives/pncl-tooltip.directive';
import { PencilButtonComponent } from '../standalones/components/pencil-button/pencil-button.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { InformationDialogComponent } from './information-dialog/information-dialog.component';
import { LikeComponent } from './like/like.component';
import { TagsComponent } from './tags/tags.component';
import { TopicsListComponent } from './topics-list/topics-list.component';
import { FullLogoComponent } from './full-logo/full-logo.component';
import { LogoComponent } from './logo/logo.component';
import { TagSelectionMenuComponent } from './tags/tag-selection-menu/tag-selection-menu.component';
import { TagConfirmationComponent } from './tags/tag-confirmation/tag-confirmation.component';
import { CourseDetailsFormComponent } from './course-details-form/details-form.component';
import { CourseArchiveConfirmDialogComponent } from './course-archive-confirm-dialog/course-archive-confirm-dialog.component';
import { SVGIconComponentsModule } from './svg-icon/svg-icon-components.module';
import { TrimValueAccessorDirective } from './utils/forms.directive';
import { LoginHeaderComponent } from './login-header/login-header.component';
import { CodeInputComponent } from './code-input/code-input.component';
import { UpgradePlanBlockerComponent } from './upgrade-plan-blocker/upgrade-plan-blocker.component';
import { UpgradePlanBlockerDirective } from './upgrade-plan-blocker/upgrade-plan-blocker.directive';
import { ReferralAcceptedDialogComponent } from './referral-accepted-dialog/referral-accepted-dialog.component';
import { UserIntersectionWrapperComponent } from './user-intersection-wrapper/user-intersection-wrapper.component';
import { SimpleSearchBoxComponent } from './simple-search-box/simple-search-box.component';

@NgModule({
  declarations: [
    ChartsComponent,
    TagsComponent,
    LikeComponent,
    ConfirmDialogComponent,
    TopicsListComponent,
    FullLogoComponent,
    LogoComponent,
    InformationDialogComponent,
    TagSelectionMenuComponent,
    TagConfirmationComponent,
    CourseDetailsFormComponent,
    CourseArchiveConfirmDialogComponent,
    TrimValueAccessorDirective,
    LoginHeaderComponent,
    CodeInputComponent,
    UpgradePlanBlockerComponent,
    UpgradePlanBlockerDirective,
    ReferralAcceptedDialogComponent,
    UserIntersectionWrapperComponent,
    SimpleSearchBoxComponent,
  ],
  imports: [
    MatDialogModule,
    CommonModule,
    MatCheckboxModule,
    MatSliderModule,
    NgbTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          breaks: true,
        },
      },
    }),
    MatButtonModule,
    UiModule,
    PipeModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    TranslateModule,
    ScrollingModule,
    SVGIconComponentsModule,
    RouterModule,
    MatMenuModule,
    TooltipDirective,
    PencilButtonComponent,
  ],
  exports: [
    ChartsComponent,
    TagsComponent,
    TagSelectionMenuComponent,
    LikeComponent,
    TopicsListComponent,
    FullLogoComponent,
    LogoComponent,
    CourseDetailsFormComponent,
    SVGIconComponentsModule,
    TrimValueAccessorDirective,
    LoginHeaderComponent,
    CodeInputComponent,
    UpgradePlanBlockerComponent,
    UpgradePlanBlockerDirective,
    UserIntersectionWrapperComponent,
    SimpleSearchBoxComponent,
  ],
})
export class CommonComponentsModule {}

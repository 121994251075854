import {
  DEFAULT_DEVICE_ID,
  DeviceType,
  DevicesHandleUtil,
} from 'src/app/common/utils/devices-handle-util';
import { cloneDeep } from 'lodash';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Mutex } from 'async-mutex';
import { BehaviorSubject, Observable } from 'rxjs';
import { DEFAULT_DEVICE_STATE, DeviceState } from '../devices-manager.service';

export interface LocalTrack {
  acquireStream(): Promise<MediaStream | null>;
  changeDevice(deviceID: string): Promise<MediaStream | null>;
  closeExistingTrack(): void;
  isTrackAcquired(): boolean;
  getMediaStream(): MediaStream | null;
  getMediaStreamTrack(): MediaStreamTrack | undefined;
  getDeviceIdFromActualStream(): string | undefined;
  updateDeviceState(newState: Partial<DeviceState>): void;
  getSettings(): MediaTrackSettings | undefined;
  enableExistingTrack(): void;
  get deviceId$(): Observable<string>;
  get stream$(): Observable<MediaStream | null>;
  get deviceState$(): Observable<DeviceState>;
  get deviceState(): DeviceState;
  get deviceType(): DeviceType;
  get deviceId(): string;
  set onended(cb: ((this: MediaStreamTrack, ev: Event) => any) | null);
  set onmute(cb: ((this: MediaStreamTrack, ev: Event) => any) | null);
  set onunmute(cb: ((this: MediaStreamTrack, ev: Event) => any) | null);
  set beforeAcquireStreamCallback(cb: () => void);
  set afterAcquireStreamCallback(cb: () => void);
}

@UntilDestroy()
export abstract class BaseLocalTrack implements LocalTrack {
  private _mediaStreamSubject = new BehaviorSubject<MediaStream | null>(null);
  private _deviceStateSubject = new BehaviorSubject<DeviceState>(cloneDeep(DEFAULT_DEVICE_STATE));
  private _deviceType: DeviceType;
  private _deviceIdSubject = new BehaviorSubject<string>(DEFAULT_DEVICE_ID);
  private _acquireStreamMutex = new Mutex();
  private _beforeAcquireStreamCallback?: () => void;
  private _afterAcquireStreamCallback?: () => void;

  constructor(deviceType: DeviceType, deviceId: string) {
    this._deviceType = deviceType;
    this._deviceIdSubject.next(deviceId);
  }

  get stream$() {
    return this._mediaStreamSubject.asObservable();
  }

  get deviceState$(): Observable<DeviceState> {
    return this._deviceStateSubject.asObservable();
  }

  get deviceId$() {
    return this._deviceIdSubject.asObservable();
  }

  get deviceState() {
    return this._deviceStateSubject.value;
  }

  get deviceId() {
    return this._deviceIdSubject.value;
  }

  set onmute(cb: ((this: MediaStreamTrack, ev: Event) => any) | null) {
    const track = this.getMediaStreamTrack();
    if (track) {
      track.onmute = cb;
    }
  }

  set onunmute(cb: ((this: MediaStreamTrack, ev: Event) => any) | null) {
    const track = this.getMediaStreamTrack();
    if (track) {
      track.onunmute = cb;
    }
  }

  set onended(cb: ((this: MediaStreamTrack, ev: Event) => any) | null) {
    const track = this.getMediaStreamTrack();
    if (track) {
      track.onended = cb;
    }
  }

  set beforeAcquireStreamCallback(cb: () => void) {
    this._beforeAcquireStreamCallback = cb;
  }

  set afterAcquireStreamCallback(cb: () => void) {
    this._afterAcquireStreamCallback = cb;
  }

  getSettings() {
    return this.getMediaStreamTrack()?.getSettings();
  }

  updateDeviceState(newState: Partial<DeviceState>) {
    const curState = this.deviceState;
    Object.assign(curState, newState);
    this._deviceStateSubject.next(curState);
  }

  enableExistingTrack() {
    const curTrack = this.getMediaStreamTrack();
    if (curTrack) {
      curTrack.enabled = true;
    }
  }

  async acquireStream(): Promise<MediaStream> {
    return this._acquireStreamMutex.runExclusive(async () => {
      if (this.isTrackAcquired()) {
        return this.getMediaStream()!;
      }
      if (this._beforeAcquireStreamCallback) {
        this._beforeAcquireStreamCallback();
      }
      const stream = await DevicesHandleUtil.gUMInputDevice(
        this,
        this._deviceType,
        this.deviceId,
        this._deviceStateSubject.value,
        false,
        this.getStreamConstrains(),
      );
      this._mediaStreamSubject.next(stream);
      if (this._afterAcquireStreamCallback) {
        this._afterAcquireStreamCallback();
      }
      // Update device ID in local storage if its value isn't the same after acquiring the stream
      // This can happen for many reasons ex: requested device ID isn't available, so browser automatically switches to another device (Default device)
      this.updatePublicDeviceIdFromActualStream();
      return stream;
    });
  }

  getDeviceIdFromActualStream() {
    return this.getMediaStreamTrack()?.getSettings().deviceId;
  }

  async changeDevice(deviceID: string): Promise<MediaStream | null> {
    if (deviceID === this.deviceId) {
      return this.acquireStream();
    }
    this.closeExistingTrack();
    this._deviceIdSubject.next(deviceID);
    this.setDeviceIdIntoLocalStorage(deviceID);
    return this.acquireStream();
  }

  closeExistingTrack() {
    const track = this._mediaStreamSubject.value
      ?.getTracks()
      .filter((mediaStreamTrack) => mediaStreamTrack.kind === this._deviceType)[0];
    if (track) {
      track.onended = null;
      track.onmute = null;
      this.onunmute = null;
      track.stop();
    }
    this._mediaStreamSubject.next(null);
  }

  isTrackAcquired(): boolean {
    return this.getMediaStreamTrack()?.readyState === 'live';
  }

  getMediaStream() {
    return this._mediaStreamSubject.value;
  }

  getMediaStreamTrack(): MediaStreamTrack | undefined {
    const track = this._mediaStreamSubject.value
      ?.getTracks()
      .filter((mediaStreamTrack) => mediaStreamTrack.kind === this._deviceType)[0];
    return track;
  }

  get deviceType() {
    return this._deviceType;
  }

  protected abstract getStreamConstrains(): MediaTrackConstraints | undefined;
  protected abstract setDeviceIdIntoLocalStorage(deviceId: string): void;

  private updatePublicDeviceIdFromActualStream() {
    const settings = this.getMediaStreamTrack()?.getSettings();

    if (!settings?.deviceId) {
      return;
    }

    if (settings.deviceId !== this.deviceId) {
      this._deviceIdSubject.next(settings.deviceId);
      this.setDeviceIdIntoLocalStorage(settings.deviceId);
    }
  }
}

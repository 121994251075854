import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, Observable, startWith } from 'rxjs';
import { Site } from 'src/app/models/user';
import { noWhitespaceValidator } from 'src/app/settings/shared/form-validators/form-validators';
import { IconTypes } from 'src/app/standalones/components/pencil-icon/pencil-icon.component';

@UntilDestroy()
@Component({
  selector: 'app-create-and-edit-site-dialog',
  templateUrl: './create-and-edit-site-dialog.component.html',
  styleUrls: ['./create-and-edit-site-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAndEditSiteDialogComponent {
  updateMode = false;
  siteForm: UntypedFormGroup;
  updateButtonDisabled$: Observable<boolean>;
  protected readonly IconTypes = IconTypes;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CreateAndEditSiteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: Site,
  ) {
    this.updateMode = !!this.data?._id;

    this.siteForm = this.formBuilder.group({
      name: [data?.name, [Validators.required, noWhitespaceValidator]],
    });

    this.updateButtonDisabled$ = this.siteForm.statusChanges.pipe(
      untilDestroyed(this),
      startWith('INVALID'),
      map((status) => status === 'INVALID'),
    );
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    const siteData = {
      ...this.siteForm.value,
      _id: this.data?._id,
    };
    this.dialogRef.close(siteData);
  }
}

import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { captureException } from '@sentry/browser';
import { LangCodeMap } from '../consts';

declare let require;

@Injectable({
  providedIn: 'root',
})
export class SetTimeLocaleService {
  private langCodeMap = LangCodeMap;
  private specialLocaleMap = [
    { code: 'cn', momentCode: 'zh-cn' },
    { code: 'pa', momentCode: 'pa-in' },
  ];

  public setMomentLocale(locale?: string): void {
    /**
     * require.ensure make the list of locale in the provided array avaliable for use in the code.
     * However if more locale is to be added it might be better to just use the import() function as explained here  https://webpack.js.org/api/module-methods/#import-1 and https://webpack.js.org/api/module-methods/#dynamic-expressions-in-import
     * Since there might not be significant difference in size between what will be downloaded then and downloading all the locale in moment/locale/*
     */
    require.ensure(
      [
        'moment/locale/af',
        'moment/locale/ar',
        'moment/locale/bg',
        'moment/locale/bn',
        'moment/locale/da',
        'moment/locale/de',
        'moment/locale/en-gb',
        'moment/locale/es',
        'moment/locale/fi',
        'moment/locale/fil',
        'moment/locale/fr',
        'moment/locale/gu',
        'moment/locale/hi',
        'moment/locale/id',
        'moment/locale/it',
        'moment/locale/ja',
        'moment/locale/kn',
        'moment/locale/ko',
        'moment/locale/mi',
        'moment/locale/ml',
        'moment/locale/mr',
        'moment/locale/ms',
        'moment/locale/ne',
        'moment/locale/nl',
        'moment/locale/nn',
        'moment/locale/pt',
        'moment/locale/ru',
        'moment/locale/sr',
        'moment/locale/sv',
        'moment/locale/sw',
        'moment/locale/ta',
        'moment/locale/th',
        'moment/locale/vi',
      ],
      () => {
        let userLang = locale || navigator.languages[0];
        if (
          !this.langCodeMap.find((lang) => lang.code === userLang) ||
          userLang === 'en' ||
          userLang === 'en-US'
        ) {
          return moment.locale('en');
        }

        if (this.langCodeMap.find((lang) => lang.code === userLang)) {
          if (this.specialLocaleMap.find((lang) => lang.code === userLang)) {
            userLang = <string>(
              this.specialLocaleMap.find((lang) => lang.code === userLang)?.momentCode
            );
          }

          try {
            require(`moment/locale/${userLang}`);
            return moment.locale(userLang);
          } catch (error) {
            captureException(new Error(`moment/locale/${userLang}  not found`), {
              extra: { error },
            });
            return moment.locale('en');
          }
        }

        return moment.locale('en');
      },
    );
  }
}

<div class="tutor-availability-status" [ngClass]="[status.toLowerCase()]">
  <div class="status" *ngIf="status === availabilityStatuses.UNKNOWN">
    <app-pencil-icon name="error_outline" [type]="IconTypes.matIcon"></app-pencil-icon>
    <span class="label">{{ 'Availability not known' | translate }}</span>
  </div>
  <div class="status" *ngIf="status === availabilityStatuses.AVAILABLE">
    <app-pencil-icon name="check_circle_outline" [type]="IconTypes.matIcon"></app-pencil-icon>
    <span class="label">{{ 'Available' | translate }}</span>
  </div>
  <div class="status" *ngIf="status === availabilityStatuses.UNAVAILABLE">
    <app-pencil-icon name="error_outline" [type]="IconTypes.matIcon"></app-pencil-icon>
    <span class="label">{{ 'Not available' | translate }}</span>
  </div>
</div>

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rating-dialog',
  templateUrl: './rating-dialog.component.html',
  styleUrls: ['./rating-dialog.component.scss'],
})
export class RatingDialogComponent {
  readonly ratings = [1, 2, 3, 4];
  readonly ratingMessages = ["I'm very unhappy", "I'm unhappy", "I'm satisfied", "I'm very happy"];
  rating = 0;
  mouseOverIndex: number | null = null;
  feedback = '';
  disableSubmit = false;

  constructor(
    private dialogRef: MatDialogRef<RatingDialogComponent>,
    private translateService: TranslateService,
  ) {}

  handleHover(index: number): void {
    this.mouseOverIndex = index;
  }

  handleMouseOut(): void {
    this.mouseOverIndex = null;
  }

  handleSelectRating(index: number): void {
    this.rating = index;
  }

  handleFeedbackChange(value: string): void {
    this.feedback = value;
  }

  handleSubmit(): void {
    if (this.rating === 0) {
      this.disableSubmit = true;
      return;
    }
    this.dialogRef.close({
      rating: this.rating,
      feedback: this.feedback,
      ratingDone: true,
    });
  }

  handleNotNow(): void {
    this.dialogRef.close({
      ratingDone: false,
    });
  }

  get ratingMessage(): string {
    if (this.rating === 0 && !this.mouseOverIndex) {
      return this.translateService.instant(
        this.disableSubmit ? 'Select a rating above to submit' : 'Select a star rating above',
      );
    }
    const index = this.mouseOverIndex || this.rating;
    return this.translateService.instant(this.ratingMessages[index - 1]);
  }
}

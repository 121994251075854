import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { User } from '../models/user';
import { SUBSCRIPTION_TYPES } from '../models/payment';

@Pipe({
  name: 'referralTrialPeriodText',
})
export class ReferralTrialPeriodTextPipe implements PipeTransform {
  lastReferralAcceptedDays = 100;

  constructor(private translateService: TranslateService) {}

  transform(user: User | undefined, isTooltip = false): string {
    if (!user?.feature_flags?.enable_billing || !user?.subscriptionType) {
      return '';
    }
    let text = '';
    if (user.subscriptionType === SUBSCRIPTION_TYPES.FREE) {
      text = this.translateService.instant('Your trial has expired');
    } else if (user.subscriptionType === SUBSCRIPTION_TYPES.TRIAL) {
      if (user.billingStatus.forceBillDate) {
        const remainingFreeDays = moment(user.billingStatus.forceBillDate).diff(Date.now(), 'd');
        if (remainingFreeDays === 0) {
          text = this.translateService.instant('Free trial ends today');
        } else if (remainingFreeDays === 1) {
          text = this.translateService.instant('Your trial ends in 1 day');
        } else {
          text = `${this.translateService.instant(
            'Your trial ends in',
          )} ${remainingFreeDays} ${this.translateService.instant('days')}`;
        }
      }
    }
    if (isTooltip) {
      text += ` \n${this.translateService.instant('Click to upgrade to Pro.')}`;
    }
    return text;
  }
}

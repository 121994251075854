<div class="header d-flex align-items-center justify-content-between">
  <p class="w-100">{{ 'Change language' | translate }}</p>
  <app-pencil-button
    matDialogClose
    icon="close"
    class="pencil-button close-btn"
    [iconConfig]="{ type: 'material-icon' }"
  >
  </app-pencil-button>
</div>
<div class="content" [innerHtml]="message"></div>
<div class="actions d-flex justify-content-between align-items-stretch">
  <app-pencil-button
    matDialogClose
    class="pencil-button cancel"
    icon="close"
    [iconConfig]="{ type: 'material-icon' }"
    [label]="'Cancel' | translate"
  >
  </app-pencil-button>
  <app-pencil-button
    class="pencil-button confirm"
    (clickEvent)="userService.changeLang(data?.target?.code); dialogRef.close()"
    icon="check"
    [iconConfig]="{ type: 'material-icon' }"
    [label]="'Refresh to change language' | translate"
  >
  </app-pencil-button>
</div>

<div class="lds-ring" [style.width.px]="diameter" [style.height.px]="diameter">
  <div
    [style.width.px]="diameter"
    [style.height.px]="diameter"
    [style.border-width.px]="strokeWidth"
  ></div>
  <div
    [style.width.px]="diameter"
    [style.height.px]="diameter"
    [style.border-width.px]="strokeWidth"
  ></div>
  <div
    [style.width.px]="diameter"
    [style.height.px]="diameter"
    [style.border-width.px]="strokeWidth"
  ></div>
  <div
    [style.width.px]="diameter"
    [style.height.px]="diameter"
    [style.border-width.px]="strokeWidth"
  ></div>
</div>

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSelect } from '@angular/material/select';

import { UserConstantsTransformer, roleValueIDs } from '../../models/user';
import { UserService } from '../../services/user.service';
import { noWhitespaceValidator } from '../../common/utils/form-validators';

@UntilDestroy()
@Component({
  selector: 'app-user-info-input-dialog',
  templateUrl: './user-info-input-dialog.component.html',
  styleUrls: ['./user-info-input-dialog.component.scss'],
})
export class UserInfoInputDialogComponent implements AfterViewInit {
  form = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, noWhitespaceValidator]),
  });
  infoForm?: UntypedFormGroup;
  ROLE_TYPES;
  ROLES;
  ROLE_TYPE_IDS;
  ROLE_VALUE_IDS;

  @ViewChild('roleSelect') roleSelect?: MatSelect;

  constructor(
    private dialogRef: MatDialogRef<UserInfoInputDialogComponent>,
    private userService: UserService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    const userConstants = new UserConstantsTransformer(this.translateService);
    this.ROLE_TYPES = userConstants.roleTypes;
    this.ROLES = userConstants.roles;
    this.ROLE_TYPE_IDS = userConstants.roleTypeIDs;
    this.ROLE_VALUE_IDS = roleValueIDs;

    if (data.showQuestionnaire) {
      this.infoForm = new UntypedFormGroup({
        roleType: new UntypedFormControl(this.ROLE_TYPE_IDS.education, [Validators.required]),
        role: new UntypedFormControl('', [Validators.required]),
      });
      this.form.addControl('info', this.infoForm);

      this.infoForm.controls.roleType.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
        this.infoForm?.controls.role.setValue('');
      });

      this.infoForm.controls.role.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
        if (val === this.ROLE_VALUE_IDS.other) {
          this.infoForm?.addControl(
            'roleOther',
            new UntypedFormControl('', [Validators.required, noWhitespaceValidator]),
          );
        } else {
          this.infoForm?.removeControl('roleOther');
        }
      });
    }
  }

  ngAfterViewInit(): void {
    const user = this.userService.user.getValue();
    if (user?.user.name) {
      this.roleSelect?.focus();
      this.form.controls.name.setValue(user.user?.name);
    }
  }

  handleSubmit(): void {
    const formData = this.form.value;
    if (this.data.showQuestionnaire) {
      formData.info.questionnaireComplete = true;
    }
    this.dialogRef.close(formData);
  }

  get showRoleOtherInput(): boolean {
    return !!this.infoForm && this.infoForm.contains('roleOther');
  }
}

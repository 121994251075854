import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilterSet, Filter, ISortStrategy } from '../../models/filters';


@UntilDestroy()
@Component({
  selector: 'app-filter[filterSet]',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnChanges {

  private dropdownItem?: NgSelectComponent;
  @ViewChild('dropdownSelector', {static: false}) set item(item: NgSelectComponent) {
     if(item) { // initially setter gets called with undefined
         this.dropdownItem = item;
     }
  }

  // Filter Set which is represented by that filter component.
  @Input() filterSet!: FilterSet;
  @Input() panelOpenState = false;

  // Some options to have.
  @Input() checkbox = false;
  @Input() dropdown = false;
  @Input() searchbar = false;
  @Input() sort = false;
  // Show courses names (for Courses related filters).
  @Input() showCourses = false;
  // number of filters to render initially.
  @Input() limitToShow = 10;
  // Update filter notifier
  @Input() filtersUpdated: Subject<any> = new Subject();

  @Input() placeholderForDropdown = 'All Filters';

  @Output() toggleFilter = new EventEmitter<{filter: Filter, resetView?: boolean}>();

  // the list that we do operation on (sort, search, etc...).
  filteredList: Filter[];
  // current limit of filters to render. Since we are changing the limit dynamically, we are using it to keep limitToShow unchanged (just to remember it).
  currentLimit: number;
  // Search query
  search: string;
  currentSortStrategy?: ISortStrategy;
  // 'Frequent usage' is used to keep track of the usage frequent of each filter, to sort filters by 'Last used'.
  frequentUsage: Map<Filter, number>;

  // Current Selected Filter (for dropdown filters).
  selectedFilter?: Filter

  // previous Selected Filter Object (for dropdown filters).
  // the difference between it and 'selectedFilter' is that it's not binding with the the select component.
  // So we are using it to get access to previous selected filter, once we change the selection.
  cachedSelectedFilter?: Filter;

  sortTypes: ISortStrategy[] = [
    {
      name: 'Last used',
      sortFunction: () => {
        this.filteredList.sort((a, b) => (this.frequentUsage.get(b) ?? 0) - (this.frequentUsage.get(a) ?? 0));
      }
    },
    {
      name: 'Last Created',
      sortFunction: () => {
        this.filteredList = this.matchSearchQuery();
      }
    },
    {
      name: 'Alphabetical A-Z',
      sortFunction: () => {
        this.filteredList.sort((a, b) => a.displayFunc(a.item).localeCompare(b.displayFunc(b.item)));
      }},
    {
      name: 'Alphabetical Z-A',
      sortFunction: () => {
        this.filteredList.sort((a, b) => b.displayFunc(b.item).localeCompare(a.displayFunc(a.item)));
      }
    }
  ]

  constructor() {
    this.filteredList = [];
    this.currentLimit = 0;
    this.search = '';
    // For now.
    this.currentSortStrategy = this.sortTypes[1];
    this.frequentUsage = new Map<Filter, number>();
  }

  ngOnInit(): void {
    // filteredList should be indep of filters.
    this.filteredList = [...this.filterSet.filters];
    this.currentLimit = this.limitToShow;
    // init frequencies.
    for (const filter of this.filterSet.filters) {
      this.frequentUsage.set(filter, 0);
      // For dropdown filters, if any filter is selected.
      if (filter.value) {
        this.selectedFilter = filter;
      }
    }
    this.filtersUpdated.pipe(untilDestroyed(this)).subscribe(() => {
      this.selectedFilter = this.filterSet.hasActiveFilter() ? this.filterSet.filters.find(filter => filter.value) : undefined;
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedFilter = this.filterSet.hasActiveFilter() ? this.filterSet.filters.find(filter => filter.value) : undefined;
    // if it's set by true from the parent component, then focus on the dropdown (if it exists).
    if (changes.panelOpenState?.currentValue) {
      // Angular takes time to initialize dropdownItem.
      requestAnimationFrame(() => {
        this.dropdownItem?.focus();
      });
    }
  }

  setSearchValue(search: string) {
    if (search === this.search) {
      return;
    }
    this.search = search;
    if (!search.length) {
      this.filteredList = [...this.filterSet.filters];
    } else {
      this.filteredList = this.matchSearchQuery();
    }
    this.currentSortStrategy?.sortFunction();
  }

  matchSearchQuery(): Filter[] {
    const regex = new RegExp(this.search, 'i');
    return this.filterSet.filters.filter(filter => filter.displayFunc(filter.item).match(regex));
  }

  setSortStrategy(sortStrategy: ISortStrategy) {
    this.currentSortStrategy = sortStrategy;
    this.currentSortStrategy.sortFunction();
  }

  chooseFilter(filter: Filter) {
    if (this.dropdown) {
      // Turn off all filters.
      if (this.cachedSelectedFilter) {
        this.cachedSelectedFilter.value = false;
        this.toggleFilter.emit({filter: this.cachedSelectedFilter, resetView: false});
      } else {
        // to indicate 'clear all filters'
        this.toggleFilter.emit();
      }
      this.cachedSelectedFilter = filter;
      if (filter) {filter.value = true;}
    }
    if (!filter) {
      return;
    }
    this.toggleFilter.emit({filter});
    // Increase the freq whenever it's selected.
    if (filter.value) {
      this.frequentUsage.set(filter, (this.frequentUsage.get(filter) ?? 0) + 1);
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'eventTimeFormat',
})
export class EventTimeFormatPipe implements PipeTransform {
  // sets time in HH:MM format (does not show day/date)
  transform(date): any {
    return moment(date).format('LT');
  }
}

<div class="phetContainer">
  <a target="_blank" (click)="openDialog(); $event.stopPropagation()" style="text-decoration: none">
    <img *ngIf="imageUrl" [src]="imageUrl" style="border: none" width="450" height="300" />
    <div class="phetEmbed"></div>
    <table class="phetEmbedButton">
      <tr>
        <td
          style="text-align: center; color: #000; font-size: 24px; font-family: Arial, sans-serif"
        >
          {{ 'Click to Run' | translate }}
        </td>
      </tr>
    </table>
  </a>
</div>

<app-upload-pdf-preparing-document
  *ngIf="isLoading$ | async"
  [value]="progressValue$ | async"
  [fileName]="fileName$ | async"
>
</app-upload-pdf-preparing-document>
<app-pdf-preview-selector
  *ngIf="(isLoading$ | async) === false"
  [pageItems]="pageItems"
  [fileName]="fileName$ | async"
  (selectedIndicesSubmitted)="selectedIndicesSubmitted($event)"
  (cancelClicked)="cancelClicked()"
>
</app-pdf-preview-selector>

import { PresenceData, PresenceType } from 'src/app/services/presence_v2.service';

export const getHash = (spaceId: string, context: PresenceType) => `${context}-${spaceId}`;

export const generateUserPresenceHeartbeatHash = (presence: PresenceData) =>
  `${presence.context}-${presence.spaceId}-${presence.userId}-${presence.uniqueHash}`;

export enum UserAction {
  JOIN_SPACE = 'JOIN_SPACE',
  LEAVE_SPACE = 'LEAVE_SPACE',
  JOIN_ROOM = 'JOIN_ROOM',
  LEAVE_ROOM = 'LEAVE_ROOM',
}

export interface ParticipantPresenceStatus {
  userAction: UserAction;
  userId: string;
  breakoutRoomId: string;
  spaceId: string;
}

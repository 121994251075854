import { Pipe, PipeTransform } from '@angular/core';
import { ISuggestion } from '../models/searching';

/**
 * Display the first suggestion in the given suggestion set.
 */
@Pipe({name: 'displaySuggestion'})
export class DisplaySuggestionPipe implements PipeTransform {
  transform(suggestion : ISuggestion, args: any[]): string {
    if (suggestion.secondary.length === 0) {return '';}
    const secondaryLimit = args.length > 0 ? parseInt(args[0], 10) : 40;
    const mainLimit = args.length > 1 ? parseInt(args[1], 10) : 20;
    const secondSegment = suggestion.main?.name.length ? ' in '.concat(this.truncate(suggestion.main.name, mainLimit)) : '';
    return this.truncate(suggestion.secondary[0].name, secondaryLimit) + secondSegment;
  }

  truncate(name: string, limit = 20): string {
    return name.substring(0, limit) + (name.length > limit ? '...' : '')
  }
}

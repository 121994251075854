import { Component } from '@angular/core';

@Component({
  selector: '[ui-header-actions]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./header-actions.component.scss'],
})
export class HeaderActionsComponent {
}

@Component({
  selector: 'ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
}

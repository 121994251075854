<ng-container
  *ngIf="sessionSharedDataService.changeParticipantsManagerSection | async as activeSection"
>
  <div class="parent-div">
    <div class="title">
      <ng-container *ngIf="!selectedPermission; else selectedPermissionHeader">
        <div class="d-flex align-items-center">
          <mat-icon class="icon">people_alt</mat-icon>
          <span class="cmr-4 fw-6">
            {{ 'Participants' | translate }}
          </span>
          <span class="fw-6">
            {{ ' (' + (activeParticipantsUsers.length + activeHostUsers.length) + ')' }}
          </span>
        </div>
      </ng-container>

      <ng-template #selectedPermissionHeader>
        <div class="d-flex align-items-center">
          <mat-icon class="icon cursor-pointer" (click)="selectedPermission = undefined">
            arrow_back
          </mat-icon>
          <span class="cmr-4 fw-6">
            {{ selectedPermission?.name }}
          </span>
        </div>
      </ng-template>

      <button mat-icon-button (click)="closePanel()" data-name="participants-manager-close">
        <span class="material-icons">close</span>
      </button>
    </div>

    <ng-container
      *ngTemplateOutlet="selectedPermission ? selectedPermissionTemplate : noPermissionTemplate"
    ></ng-container>

    <!--  Buttons sections to cancel or send and update permissions-->
    <ng-container
      *ngIf="
        activeSection === section.PERMISSIONS && (permissionsChanged || securitySectionChanged)
      "
    >
      <div class="buttons-section">
        <button
          class="cancel-btn"
          data-name="participants-manager-permissions-cancel"
          (click)="cancelPermissionUpdate()"
        >
          <mat-icon class="material-icons-outlined btn-icon">close</mat-icon>
          <span class="btn-text"> {{ 'Cancel changes' | translate }}</span>
        </button>
        <button
          class="update-btn"
          (click)="sendPermissionUpdates()"
          data-name="participants-manager-permissions-update"
        >
          <mat-icon class="material-icons-outlined btn-icon">check</mat-icon>
          <span class="btn-text"> {{ 'Update changes' | translate }}</span>
        </button>
      </div>
    </ng-container>
  </div>

  <ng-template #selectedPermissionTemplate>
    <div class="participants-list">
      <div class="d-flex align-items-center cpx-16 cpy-12 bg-light">
        <div class="photos">
          <ng-container *ngFor="let user of activeRoomUsers; let i = index">
            <app-profile-photo
              *ngIf="i < 3"
              [user]="usersMap[user._id]"
              [width]="22"
            ></app-profile-photo>
          </ng-container>
          <span class="more-icon" *ngIf="activeRoomUsers.length > 3"
            >+{{ activeRoomUsers.length - 3 }}</span
          >
        </div>
        <span class="flex-1 font-12 cml-8 fw-6"
          >{{ 'Room participants' | translate | uppercase }} ({{ activeRoomUsers.length }})</span
        >
        <mat-checkbox
          class="check-box"
          [checked]="
            (currentPermissions | checkIndeterminatePermission: selectedPermission?.fieldName) ===
            permissionStatus.ENABLED_FOR_ALL
          "
          [indeterminate]="
            (currentPermissions | checkIndeterminatePermission: selectedPermission?.fieldName) ===
            permissionStatus.PARTIAL_ENABLED
          "
          [color]="'primary'"
          (change)="updateEveryonePermission($event, selectedPermission?.fieldName)"
        ></mat-checkbox>
      </div>
      <div *ngFor="let user of activeRoomUsers" class="d-flex align-items-center cpx-16 cpy-12">
        <app-profile-photo [user]="usersMap[user._id]" [width]="34"></app-profile-photo>
        <span class="flex-1 font-14 cml-8 fw-6">{{ usersMap[user._id]?.name }}</span>
        <mat-checkbox
          class="check-box"
          [color]="'primary'"
          [checked]="
            currentPermissions[selectedPermission?.fieldName] | individualUserPermission: user._id
          "
          (change)="updateIndividualPermission($event, user)"
        >
        </mat-checkbox>
      </div>
      <div *ngIf="!activeRoomUsers.length" class="font-12 text-gray text-center cmt-12 fw-6">
        {{ 'This room has no participants' | translate | uppercase }}
      </div>
    </div>
  </ng-template>

  <ng-template #noPermissionTemplate>
    <div class="tabs" id="tabsDiv">
      <nav class="participants-navbar navbar navbar-expand-lg cp-0 d-none d-sm-block">
        <ul class="navbar-nav flex-row">
          <app-participants-manager-nav-item
            attributeDataIntercomTarget="Participants Manager Control"
            attributeDataName="participants-manager-controls"
            [activeSection]="activeSection"
            [relatedSection]="section.CONTROLS"
            [label]="isHost ? ('Controls' | translate) : ('Roster' | translate)"
            (handleNavItemClick)="openSection(section.CONTROLS)"
          >
          </app-participants-manager-nav-item>
          <ng-container *ngIf="totalAccessRequestsSize$ | async as requestsSzie">
            <app-participants-manager-nav-item
              *ngIf="
                ((requestAccessService.shouldShowAccessRequests$ | async) ||
                  (waitingRoomService.shouldShowAccessRequests$ | async)) &&
                requestsSzie > 0
              "
              attributeDataIntercomTarget="Participants Manager ACCESS REQUESTS"
              attributeDataName="participants-manager-access-requests"
              [activeSection]="activeSection"
              [relatedSection]="section.ACCESS_REQUESTS"
              [label]="'Requests' | translate"
              (handleNavItemClick)="openSection(section.ACCESS_REQUESTS)"
            >
              <div class="requests-num-container" *ngIf="(requestsSzie ?? 0) > 0">
                <div class="requests-num">
                  {{ requestsSzie }}
                </div>
              </div>
            </app-participants-manager-nav-item>
          </ng-container>
          <app-participants-manager-nav-item
            *ngIf="isHost && (persistentPermissionEnabled$ | async)"
            attributeDataIntercomTarget="Participants Manager Permissions"
            attributeDataName="participants-manager-permissions"
            [activeSection]="activeSection"
            [relatedSection]="section.PERMISSIONS"
            [label]="'Permissions' | translate"
            (handleNavItemClick)="openSection(section.PERMISSIONS)"
          >
          </app-participants-manager-nav-item>

          <app-participants-manager-nav-item
            *ngIf="(attendanceManagerService.attendanceFlags$ | async)?.attendanceList && isHost"
            attributeDataIntercomTarget="Participants Manager Attendance"
            attributeDataName="participants-manager-attendance"
            [activeSection]="activeSection"
            [relatedSection]="section.ATTENDANCE"
            [label]="'Attendance' | translate"
            (handleNavItemClick)="openSection(section.ATTENDANCE)"
            [class.active-event-red-dot]="attendanceManagerService.onGoingEvent$ | async"
            [isLastNavItem]="true"
          >
          </app-participants-manager-nav-item>
        </ul>
      </nav>
    </div>

    <div *ngIf="isHost && !isCallConnected && activeSection === section.CONTROLS" class="section">
      <!-- host-join-call -->
      <div class="join-call-box">
        <div class="info-icon">
          <svg-icon [icon]="'info-outline'" [height]="20" [width]="20"></svg-icon>
        </div>

        <div class="info">
          <div class="info-text">
            {{
              'You need to be in a call to control any participant microphone or video.' | translate
            }}
          </div>

          <div class="btn-container" [ngbTooltip]="startCallNotAvailable" placement="bottom">
            <button
              class="join-call"
              [disabled]="
                loadingDeviceModal ||
                rtcServiceController.service.isConnecting() ||
                (canUseProFeature$ | async) === false
              "
              appThrottleClick
              (throttleClick)="!rtcServiceController.service.isConnecting() && joinCall()"
              [throttleTime]="2000"
              data-name="participants-manager-start-call"
            >
              <svg-icon class="phone-icon" [icon]="'phone'" [height]="16" [width]="15"></svg-icon>
              <span class="btn-text">{{ startOrJoinCall | translate }}</span>
              <ng-template #startCallNotAvailable>
                <app-tooltip
                  *ngIf="(canUseProFeature$ | async) === false"
                  [text]="
                    (isCurrentUserOwnerOfSpace$ | async) === true
                      ? ('This feature is only available\nto Pro or Enterprise users' | translate)
                      : ('The owner of this Space must\nbe a Pro or Enterprise user\nto use this feature'
                        | translate)
                  "
                  [arrow]="'top'"
                  [textStyle]="{ 'white-space': 'pre !important', 'text-align': 'center' }"
                >
                </app-tooltip>
              </ng-template>
            </button>
          </div>
        </div>
      </div>
      <div class="divider"></div>
    </div>
    <div class="controls" [hidden]="activeSection !== section.CONTROLS">
      <div *ngIf="activeHostUsers.length" class="hosts-section controls-child cpb-12">
        <div class="section-title cmt-20 cmb-12">
          <span class="text">
            {{ 'HOSTS (' + activeHostUsers.length + ')' }}
          </span>
        </div>
        <div class="hosts">
          <ng-container *ngIf="viewModel$ | async as viewModel">
            <div *ngFor="let user of activeHostUsers">
              <ng-container
                *ngTemplateOutlet="
                  participantTemplate;
                  context: {
                    user: user,
                    userIsHost: true,
                    userDevicesStates: viewModel.userDevicesStates,
                    inCallUsers: viewModel.inCallUsers
                  }
                "
              >
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="divider"></div>
      <div *ngIf="activeParticipantsUsers.length" class="controls-child">
        <div class="section-title cmt-20 cmb-12">
          <span class="text">
            {{ 'PARTICIPANTS (' + activeParticipantsUsers.length + ')' }}
          </span>
        </div>
        <div class="mute-all" *ngIf="isHost && inCallParticipants > 1">
          <app-users-presence [users]="activeParticipantsUsers" [maxUsers]="2"></app-users-presence>
          <span class="txt" [style.margin-left.px]="4"> {{ 'Everyone' | translate }}</span>
          <button
            [ngbTooltip]="outOfCalltooltip"
            placement="{{ 'left' }}"
            [disableTooltip]="!(isHost && !isCallConnected)"
            [ngClass]="{ 'disable-mute-button': isHost && !isCallConnected }"
            class="btn"
            (click)="turnOffDeviceForAllParticipants(deviceEnum.MIC)"
          >
            <mat-icon class="material-icons-outlined btn-icon">mic_off</mat-icon>
            <span class="btn-text"> {{ 'Mute all' | translate }}</span>
          </button>
          <button
            class="all-settings"
            [matMenuTriggerFor]="allSettings"
            #trigger="matMenuTrigger"
            [class.active]="trigger.menuOpen"
          >
            <mat-icon class="material-icons-outlined">more_horiz</mat-icon>
          </button>
        </div>
        <ng-container *ngIf="viewModel$ | async as viewModel">
          <div *ngFor="let user of activeParticipantsUsers">
            <ng-container
              *ngTemplateOutlet="
                participantTemplate;
                context: {
                  user: user,
                  userIsHost: false,
                  userDevicesStates: viewModel.userDevicesStates,
                  inCallUsers: viewModel.inCallUsers
                }
              "
            >
            </ng-container>
          </div>
          <div [style.height.px]="32"></div>
        </ng-container>
      </div>
    </div>
    <div
      *ngIf="
        (attendanceManagerService.attendanceFlags$ | async)?.attendanceList &&
        activeSection === section.ATTENDANCE &&
        (sessionSharedDataService.rightPanelView | async)?.expanded
      "
    >
      <app-attendance></app-attendance>
    </div>
    <div
      *ngIf="
        activeSection === section.ACCESS_REQUESTS &&
        isRequestAccessEnabled &&
        (sessionSharedDataService.rightPanelView | async)?.expanded
      "
      class="access-requests-body"
    >
      <app-access-requests></app-access-requests>
    </div>
    <div [hidden]="activeSection !== section.PERMISSIONS" *ngIf="isHost" class="permissions">
      <div class="list">
        <div class="list-title">
          {{ 'ALLOW PARTICIPANTS TO...' | translate }}
        </div>
        <div class="items">
          <!-- security section as it is very different from other sections-->
          <ng-container
            *ngIf="
              isRequestAccessEnabled && {
                currentVisibility: selectedVisibility$ | async,
                currentIsWaitingRoomEnabled: selectedWaitingRoom$ | async
              } as viewModel
            "
          >
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <app-pencil-icon
                    name="security-shield"
                    [type]="IconTypes.svgIcon"
                    class="cmr-12"
                    style="--pencil-icon-size: 24px"
                  >
                  </app-pencil-icon>

                  <div
                    class="custom-text"
                    style="
                      --text-size: 12px;
                      --text-weight: 600;
                      letter-spacing: 0.6px;
                      text-transform: uppercase;
                    "
                  >
                    {{ 'security' | translate }}
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="security-item">
                <div class="security-item-content">
                  <div class="custom-text">
                    {{ 'Space visibility' | translate }}
                  </div>

                  <div class="visibility-info">
                    <app-pencil-button
                      [matMenuTriggerFor]="visibilityMenu"
                      #menuTriggerRef="matMenuTrigger"
                      (menuOpened)="visibilityMenuOpen$.next(true)"
                      (menuClosed)="visibilityMenuOpen$.next(false)"
                      class="pencil-button visibility-info-button"
                      data-name="participant-maanger-visibility-button"
                      [label]="viewModel.currentVisibility | titlecase | translate"
                      [icon]="(visibilityMenuOpen$ | async) ? 'arrow_drop_up' : 'arrow_drop_down'"
                      [iconConfig]="{
                        type: IconTypes.matIcon
                      }"
                      [iconOnLeft]="false"
                    >
                    </app-pencil-button>

                    <div class="custom-text" style="--text-color: #4f4f4f; --text-size: 12px">
                      {{
                        viewModel.currentVisibility === Visibility.PUBLIC
                          ? ('Anyone with link can join' | translate)
                          : ('Only invitees can join' | translate)
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="security-item">
                <div class="security-item-content">
                  <div class="waiting-room-info">
                    <div class="waiting-room-info-text custom-text">
                      {{ 'Waiting Room' | translate }}
                    </div>
                    <div
                      class="waiting-room-info-text custom-text"
                      style="--text-color: #4f4f4f; --text-size: 12px"
                    >
                      {{
                        'Participants will need host approval every time to enter this Space'
                          | translate
                      }}
                    </div>
                  </div>

                  <mat-checkbox
                    class="check-box"
                    [checked]="viewModel.currentIsWaitingRoomEnabled"
                    [color]="'primary'"
                    (change)="toggleWaitingRoom($event)"
                    data-name="participants-manager-permissions-enable-waiting-room"
                    [disabled]="isWaitingRoomEnforced$ | async"
                    [appTooltip]="disabledInteractionTooltip"
                    [appTooltipPlacement]="'center-start'"
                    [appTooltipDisabled]="(isWaitingRoomEnforced$ | async) === false"
                  >
                  </mat-checkbox>
                </div>
              </div>
            </mat-expansion-panel>
          </ng-container>
          <!---->
          <ng-container *ngFor="let group of permissionsGroupMetadata">
            <mat-accordion
              class="w-100"
              *ngIf="
                group.hideForFreeUsers
                  ? (paywallIndicatorService.canUseProFeature$ | async) &&
                    paywallIndicatorService.isWebViewerEnabled()
                  : true
              "
            >
              <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon class="cmr-12" *ngIf="group.icon">{{ group.icon }}</mat-icon>
                    <svg-icon
                      class="cmr-12"
                      *ngIf="group.svg"
                      [width]="24"
                      [height]="24"
                      [icon]="group.svg"
                    ></svg-icon>
                    {{ group.name }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div
                  class="item"
                  *ngFor="let permission of group.permissions"
                  [hidden]="permission.hidden"
                  [class.disabledPermission]="
                    permissionsEnforcedByInstitution[permission?.fieldName] | async
                  "
                  (click)="onPermissionClick(permission)"
                >
                  <span class="txt"> {{ permission.name }} </span>
                  <mat-checkbox
                    class="check-box"
                    [checked]="
                      (currentPermissions | checkIndeterminatePermission: permission.fieldName) ===
                      permissionStatus.ENABLED_FOR_ALL
                    "
                    [disabled]="permissionsEnforcedByInstitution[permission?.fieldName] | async"
                    [appTooltip]="disabledInteractionTooltip"
                    [appTooltipPlacement]="'center-start'"
                    [appTooltipDisabled]="
                      (permissionsEnforcedByInstitution[permission?.fieldName] | async) === null ||
                      (permissionsEnforcedByInstitution[permission?.fieldName] | async) === false
                    "
                    [color]="'primary'"
                    [indeterminate]="
                      (currentPermissions | checkIndeterminatePermission: permission.fieldName) ===
                      permissionStatus.PARTIAL_ENABLED
                    "
                    (change)="updateEveryonePermission($event, permission.fieldName)"
                    (click)="$event.stopPropagation()"
                    data-name="participants-manager-permissions-enable-{{ permission.dataName }}"
                  >
                  </mat-checkbox>
                  <mat-icon class="cml-16">chevron_right</mat-icon>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template
    #participantTemplate
    let-user="user"
    let-userIsHost="userIsHost"
    let-userDevicesStates="userDevicesStates"
    let-inCallUsers="inCallUsers"
  >
    <div class="user-row">
      <app-profile-photo-with-attachments
        class="name"
        [ngClass]="{ 'max-profile-width': isHost && isCallReady && inCallUsers.has(user._id) }"
        [user]="user"
        [isClickEnabled]="false"
        [showName]="true"
        [fontSize]="14"
        [width]="34"
        [showYouWord]="user?._id === currentUser?._id"
        [enableInactivityIndicator]="!userIsHost && isHost"
        [enableTooltips]="true"
      ></app-profile-photo-with-attachments>
      <div class="devices" *ngIf="isHost && isCallReady && inCallUsers.has(user._id)">
        <div>
          <button
            [disabled]="participantsManagerService.turnOffMicSent[user._id]"
            *ngIf="userDevicesStates[user._id]?.mic"
            class="state-btn"
            (click)="
              user._id === currentUser?._id
                ? toggleDevice(deviceEnum.MIC)
                : participantsManagerService.turnOffDeviceForParticipant(deviceEnum.MIC, user._id)
            "
          >
            <mat-icon class="material-icons-outlined"> mic_none </mat-icon>
          </button>
          <div *ngIf="!userDevicesStates[user._id]?.mic">
            <button
              *ngIf="user._id === currentUser?._id"
              [disabled]="
                !(originalPermissions.shareAudio | individualUserPermission: user._id) &&
                !userIsHost
              "
              class="state-btn"
              (click)="toggleDevice(deviceEnum.MIC)"
            >
              <svg-icon
                *ngIf="
                  userIsHost ||
                  (originalPermissions.shareAudio | individualUserPermission: user._id)
                "
                [width]="34"
                [height]="34"
                [icon]="'mic-mute-dark'"
              ></svg-icon>
            </button>
            <button
              *ngIf="user._id !== currentUser?._id"
              [disabled]="
                (!(originalPermissions.shareAudio | individualUserPermission: user._id) &&
                  !userIsHost) ||
                !isCallConnected
              "
              class="state-btn"
              [matMenuTriggerFor]="requestMatmenu"
              [matMenuTriggerData]="{ device: deviceEnum.MIC, userId: user._id }"
            >
              <ng-container
                *ngIf="
                  ((originalPermissions.shareAudio | individualUserPermission: user._id) ||
                    userIsHost) &&
                    isCallConnected;
                  then enabledMic;
                  else disabledMic
                "
              ></ng-container>

              <ng-template #enabledMic>
                <svg-icon [width]="34" [height]="34" [icon]="'mic-mute-dark'"></svg-icon>
              </ng-template>

              <ng-template #disabledMic>
                <ng-container
                  *ngIf="
                    !isCallConnected &&
                      !(originalPermissions.shareAudio | individualUserPermission: user._id);
                    then disabledMicCallNotConnected;
                    else callConnectedOrAudioPermission
                  "
                ></ng-container>
              </ng-template>

              <ng-template #callConnectedOrAudioPermission>
                <ng-container
                  *ngIf="!isCallConnected"
                  [ngTemplateOutlet]="disabledMicCallNotConnected"
                ></ng-container>
                <ng-container
                  *ngIf="!(originalPermissions.shareAudio | individualUserPermission: user._id)"
                  [ngTemplateOutlet]="disabledMicCallAudioPermission"
                ></ng-container>
              </ng-template>

              <ng-template #disabledMicCallNotConnected>
                <mat-icon
                  class="material-icons-outlined text-gray"
                  [ngbTooltip]="micTooltipCallNotConnected"
                >
                  mic_none
                </mat-icon>
              </ng-template>

              <ng-template #disabledMicCallAudioPermission>
                <mat-icon
                  class="material-icons-outlined text-gray"
                  [ngbTooltip]="micTooltipAudioPermission"
                >
                  mic_none
                </mat-icon>
              </ng-template>

              <ng-template #micTooltipAudioPermission>
                <app-tooltip
                  [text]="'Permission to turn on mic is disabled.' | translate"
                  [arrow]="'right'"
                >
                </app-tooltip>
              </ng-template>

              <ng-template #micTooltipCallNotConnected>
                <app-tooltip
                  [text]="'You need to be in a call to use this function.' | translate"
                  [arrow]="'right'"
                >
                </app-tooltip>
              </ng-template>
            </button>
          </div>
        </div>
        <div>
          <button
            *ngIf="userDevicesStates[user._id]?.cam"
            class="state-btn"
            [disabled]="participantsManagerService.turnOffCamSent[user._id]"
            (click)="
              user._id === currentUser?._id
                ? toggleDevice(deviceEnum.CAM)
                : participantsManagerService.turnOffDeviceForParticipant(deviceEnum.CAM, user._id)
            "
          >
            <mat-icon class="material-icons-outlined"> videocam </mat-icon>
          </button>
          <div *ngIf="!userDevicesStates[user._id]?.cam">
            <button
              *ngIf="user._id === currentUser?._id"
              [disabled]="
                !(originalPermissions.shareVideo | individualUserPermission: user._id) &&
                !userIsHost
              "
              class="state-btn"
              (click)="toggleDevice(deviceEnum.CAM)"
            >
              <svg-icon
                *ngIf="
                  userIsHost ||
                  (originalPermissions.shareVideo | individualUserPermission: user._id)
                "
                [icon]="'vid-no-cam-dark'"
                [width]="34"
                [height]="34"
              ></svg-icon>
            </button>
            <button
              *ngIf="user._id !== currentUser?._id"
              class="state-btn"
              [disabled]="
                (!(originalPermissions.shareVideo | individualUserPermission: user._id) &&
                  !userIsHost) ||
                !isCallConnected
              "
              [matMenuTriggerFor]="requestMatmenu"
              [matMenuTriggerData]="{ device: deviceEnum.CAM, userId: user._id }"
            >
              <ng-container
                *ngIf="
                  ((originalPermissions.shareVideo | individualUserPermission: user._id) ||
                    userIsHost) &&
                    isCallConnected;
                  then enabledCam;
                  else disabledCam
                "
              ></ng-container>
              <ng-template #enabledCam>
                <svg-icon [icon]="'vid-no-cam-dark'" [width]="34" [height]="34"></svg-icon>
              </ng-template>

              <ng-template #disabledCam>
                <ng-container
                  *ngIf="
                    !isCallConnected &&
                      !(originalPermissions.shareVideo | individualUserPermission: user._id);
                    then disabledCamCallNotConnected;
                    else callConnectedOrCamPermission
                  "
                ></ng-container>
              </ng-template>

              <ng-template #callConnectedOrCamPermission>
                <ng-container
                  *ngIf="!isCallConnected"
                  [ngTemplateOutlet]="disabledCamCallNotConnected"
                ></ng-container>
                <ng-container
                  *ngIf="!(originalPermissions.shareVideo | individualUserPermission: user._id)"
                  [ngTemplateOutlet]="disabledCamCallAudioPermission"
                ></ng-container>
              </ng-template>

              <ng-template #disabledCamCallNotConnected>
                <mat-icon
                  class="material-icons-outlined text-gray"
                  [ngbTooltip]="micTooltipCallNotConnected"
                >
                  videocam
                </mat-icon>
              </ng-template>

              <ng-template #disabledCamCallAudioPermission>
                <mat-icon
                  class="material-icons-outlined text-gray"
                  [ngbTooltip]="camPermissionTooltip"
                >
                  videocam
                </mat-icon>
              </ng-template>

              <ng-template #camPermissionTooltip>
                <app-tooltip
                  [text]="'Permission to turn on cam is disabled.' | translate"
                  [arrow]="'right'"
                >
                </app-tooltip>
              </ng-template>

              <ng-template #micTooltipCallNotConnected>
                <app-tooltip
                  [text]="'You need to be in a call to use this function.' | translate"
                  [arrow]="'right'"
                >
                </app-tooltip>
              </ng-template>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <mat-menu #requestMatmenu="matMenu" class="request-menu" (click)="$event.stopPropagation()">
    <ng-template matMenuContent let-device="device" let-userId="userId">
      <div class="title">
        <span class="txt">
          {{ device === 'mic' ? 'Request to unmute' : ('Request to enable camera' | translate) }}
        </span>
        <mat-icon class="material-icons-outlined close-btn">close</mat-icon>
      </div>
      <div class="msg">
        {{
          device === 'mic'
            ? 'Send this participant a request to unmute their microphone'
            : ('Send this participant a request to turn on their video camera.' | translate)
        }}
      </div>
      <button
        *ngIf="
          (device === deviceEnum.MIC && !participantsManagerService.micRequestSent[userId]) ||
          (device === deviceEnum.CAM && !participantsManagerService.camRequestSent[userId])
        "
        class="overflow-btns"
        (click)="$event.stopPropagation(); participantsManagerService.sendRequest(device, userId)"
      >
        <mat-icon class="send-icon">send</mat-icon>
        <span class="text fw-6"> {{ 'Request' | translate }} </span>
      </button>
      <div
        *ngIf="
          !(device === deviceEnum.MIC && !participantsManagerService.micRequestSent[userId]) &&
          !(device === deviceEnum.CAM && !participantsManagerService.camRequestSent[userId])
        "
        class="overflow-btns"
        [class.active]="true"
      >
        <mat-icon class="send-icon">send</mat-icon>
        <span class="text fw-6"> {{ 'Request sent!' | translate }} </span>
      </div>
    </ng-template>
  </mat-menu>

  <mat-menu #allSettings="matMenu" class="all-settings-menu" xPosition="before" yPosition="above">
    <div class="title">
      {{ 'Everyone' | translate }}
    </div>
    <div class="options">
      <button
        [appTooltip]="'You need to be in a call to use this function.' | translate"
        [appTooltipPlacement]="'center-start'"
        [appTooltipDisabled]="!(isHost && !isCallConnected)"
        [ngClass]="{ 'disable-menu-button': isHost && !isCallConnected }"
        class="button"
        (click)="requestAllParticipantsToTurnOnDevice(deviceEnum.MIC)"
      >
        <mat-icon class="material-icons-outlined icon">mic_none</mat-icon>
        <span class="txt"> {{ 'Request all to unmute' | translate }} </span>
      </button>
      <button
        [appTooltip]="'You need to be in a call to use this function.' | translate"
        [appTooltipPlacement]="'center-start'"
        [appTooltipDisabled]="!(isHost && !isCallConnected)"
        [ngClass]="{ 'disable-menu-button': isHost && !isCallConnected }"
        class="button"
        (click)="turnOffDeviceForAllParticipants(deviceEnum.CAM)"
      >
        <mat-icon class="material-icons-outlined icon">videocam_off</mat-icon>
        <span class="txt"> {{ 'Turn off all video' | translate }} </span>
      </button>
      <button
        [appTooltip]="'You need to be in a call to use this function.' | translate"
        [appTooltipPlacement]="'center-start'"
        [appTooltipDisabled]="!(isHost && !isCallConnected)"
        [ngClass]="{ 'disable-menu-button': isHost && !isCallConnected }"
        class="button"
        (click)="requestAllParticipantsToTurnOnDevice(deviceEnum.CAM)"
      >
        <mat-icon class="material-icons-outlined icon">videocam</mat-icon>
        <span class="txt"> {{ 'Request all to turn on video' | translate }} </span>
      </button>
    </div>
  </mat-menu>

  <ng-template #outOfCalltooltip>
    <app-tooltip
      [text]="'You need to be in a call to use this function.' | translate"
      [arrow]="'right'"
    >
    </app-tooltip>
  </ng-template>

  <!-- TODO : create a shared component for this menu to be used here and in the invite modal-->

  <mat-menu
    #visibilityMenu="matMenu"
    xPosition="before"
    yPosition="below"
    class="invite-modal-visibility-menu"
  >
    <ng-container
      *ngIf="{
        isCurrentVisibilityPrivate: (selectedVisibility$ | async) === Visibility.PRIVATE
      } as menuViewModel"
    >
      <app-pencil-button
        (clickEvent)="changeVisibility(Visibility.PRIVATE)"
        [selected]="menuViewModel.isCurrentVisibilityPrivate"
        class="pencil-button menu-item role-menu-item small-gap"
        data-name="participants-manager-permissions-private"
      >
        <div class="choice-title">
          <div class="title-with-icon">
            <img
              [src]="
                '/assets/svg-icons/person-with-lock-' +
                (menuViewModel.isCurrentVisibilityPrivate ? 'blue' : 'gray') +
                '.svg'
              "
              alt=""
            />
            <div>
              {{ Visibility.PRIVATE | titlecase | translate }}
            </div>
          </div>
          <mat-icon *ngIf="menuViewModel.isCurrentVisibilityPrivate"> check </mat-icon>
        </div>
        <div class="choice-explanation">Users must be invited to join this Space</div>
      </app-pencil-button>

      <app-pencil-button
        (clickEvent)="changeVisibility(Visibility.PUBLIC)"
        [selected]="!menuViewModel.isCurrentVisibilityPrivate"
        data-name="participants-manager-permissions-public"
        class="pencil-button menu-item role-menu-item small-gap"
      >
        <div class="choice-title">
          <div class="title-with-icon">
            <img
              [src]="
                '/assets/svg-icons/public-' +
                (!menuViewModel.isCurrentVisibilityPrivate ? 'blue' : 'gray') +
                '.svg'
              "
              alt=""
            />
            <div>
              {{ Visibility.PUBLIC | titlecase | translate }}
            </div>
          </div>
          <mat-icon *ngIf="!menuViewModel.isCurrentVisibilityPrivate">check</mat-icon>
        </div>
        <div class="choice-explanation">Anyone with this link can access this Space</div>
      </app-pencil-button>
    </ng-container>
  </mat-menu>
</ng-container>

<ng-template #disabledInteractionTooltip>
  <app-tooltip
    class="wr-tooltip"
    [text]="'You cannot modify this permission because it is set by your institution' | translate"
    [arrow]="'right'"
    [wrapText]="true"
  >
  </app-tooltip>
</ng-template>

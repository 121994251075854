import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Course } from 'src/app/models/user';


enum TagTypesEnum {
  COURSE = 'Course',
  INSTITUTION = 'Institution'
}

@Component({
  selector: 'app-create-tags',
  templateUrl: './create-tags.component.html',
  styleUrls: ['./create-tags.component.scss']
})
export class CreateTagsComponent {

  tag_name = ''; // default name of a tag
  selectedCourse?: Course; // selected course reference.
  enforcingCourse = false;
  selectedType?: {name: TagTypesEnum, icon: string}; // (Course, Institution) for now.

  tag_exist = false;
  tagTypes = [
    {
      name: TagTypesEnum.COURSE,
      icon: 'article'

    },
    {
      name: TagTypesEnum.INSTITUTION,
      icon: 'school'
    }
  ];

  // To be used in html.
  TagTypes = TagTypesEnum;

  constructor(
    public dialogRef: MatDialogRef<CreateTagsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {courses: Course[], currentTags: any[], currentCourse: Course, enforcingCourse: boolean}) {
      if (data.currentCourse) {
        // to auto-populate course name.
        this.selectedCourse = data.currentCourse;
        this.enforcingCourse = data.enforcingCourse;
        this.selectedType = this.tagTypes.find(tag => tag.name === TagTypesEnum.COURSE);
      }
    }

  /**
    Get Called whenever user change the tag name in the dialog window.
   */
  checkTagName() {
    // We are using empty string to avoid the equality of two undefined.
    const currentCourseID = this.selectedCourse?._id ?? '';
    const courseLevel = this.selectedType?.name === TagTypesEnum.COURSE;

    // We can have the same tag name but with different levels (inst & course levels).
    this.tag_exist = this.data.currentTags.find(tag => (!courseLevel || tag.metadata?.courseID === currentCourseID) && tag.value === this.tag_name);
  }

  /**
    Called when user clicks on 'Create' button
   */
  createTag() {
    this.dialogRef.close({
      tagName: this.tag_name,
      selectedCourse: this.selectedCourse
    });
  }

  /**
    Cancelling Creation of a new tag.
   */
  closeDialog() {
    this.dialogRef.close(false);
  }

  handleTypeChange() {
    // To reset course dropdown.
    if (this.selectedType?.name !== TagTypesEnum.COURSE) {
      this.selectedCourse = undefined;
    }
    this.checkTagName();
  }
}

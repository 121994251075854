export enum FileUploadError {
  SizeLimitExceed = 'SizeLimitExceed',
  Corrupt = 'Corrupt',
  NotSupportedType = 'NotSupportedType',
  TooManyPages = 'TooManyPages',
  OneFileOnly = 'OneFileOnly',
  TooLongFileName = 'TooLongFileName',
  Other = 'Other',
}

export enum PDFBoardStrategy {
  CurrentBoard = 'CurrentBoard',
  NewBoard = 'NewBoard',
  OneBoardPerPage = 'OneBoardPerPage',
}

export const MAX_PAGES_FOR_ONE_PAGE_BER_BOARD = 30;
export const MAX_FILE_NAME_CHARS = 65;
export const MAX_FILE_SIZE_IN_MB = 15;
export const MAX_PDF_PAGES = 70;

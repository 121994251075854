import { extractMath } from 'extract-math';
import katex from 'katex';
import { uniqBy } from 'lodash-es';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as CryptoJS from 'crypto-js';
import { Permissions } from 'src/app/models/session';
import { TelemetryService } from 'src/app/services/telemetry.service';
import { environment } from '../../../environments/environment';
import { getEmailValidationErrors } from './form-validators';

const hasKey = (obj: object, property: string) =>
  Object.prototype.hasOwnProperty.call(obj, property);

export function sortByNum(first: object, second: object, property: string) {
  if (property && hasKey(first, property) && hasKey(second, property) && property) {
    if (first[property] > second[property]) {
      return 1;
    } else if (first[property] < second[property]) {
      return -1;
    }
  }
  return 0;
}

export function sortByDate(first: object, second: object, property: string, order = 1) {
  if (property && hasKey(first, property) && hasKey(second, property) && property) {
    if (new Date(first[property]).getTime() > new Date(second[property]).getTime()) {
      return order * 1;
    } else if (new Date(first[property]).getTime() < new Date(second[property]).getTime()) {
      return order * -1;
    }
  }
  return 0;
}

export function sortByLength(first: Array<any>, second: Array<any>) {
  if (first.length > second.length) {
    return -1;
  } else if (first.length < second.length) {
    return 1;
  }
  return 0;
}

export function sortByString(first: object, second: object, property: string) {
  if (property && hasKey(first, property) && hasKey(second, property) && property) {
    if (first[property].toLowerCase() > second[property].toLowerCase()) {
      return 1;
    } else if (first[property].toLowerCase() < second[property].toLowerCase()) {
      return -1;
    }
  }
  return 0;
}

export function addFullScreenListener(callback) {
  document.addEventListener(
    'fullscreenchange',
    () => {
      callback();
    },
    false,
  );

  document.addEventListener(
    'mozfullscreenchange',
    () => {
      callback();
    },
    false,
  );

  document.addEventListener(
    'webkitfullscreenchange',
    () => {
      callback();
    },
    false,
  );

  document.addEventListener(
    'msfullscreenchange',
    () => {
      callback();
    },
    false,
  );
}

export function exitFullScreen() {
  const methodToBeInvoked =
    document['exitFullscreen'] ||
    document['mozCancelFullscreen'] ||
    document['webkitExitFullscreen'] ||
    document['msExitFullscreen'];
  if (
    methodToBeInvoked &&
    (document['fullscreen'] ||
      document['msFullscreenElement'] ||
      document['mozFullScreen'] ||
      document['webkitIsFullScreen'])
  ) {
    methodToBeInvoked.call(document);
  }
}

export function goFullScreen(element) {
  const methodToBeInvoked =
    element['requestFullscreen'] ||
    element['mozRequestFullscreen'] ||
    element['webkitRequestFullscreen'] ||
    element['msRequestFullscreen'];
  if (methodToBeInvoked) {
    methodToBeInvoked.call(element);
  }
}

export function checkFullScreen() {
  return (
    document['fullscreen'] ||
    document['msFullscreenElement'] ||
    document['mozFullScreen'] ||
    document['webkitIsFullScreen']
  );
}

export function b64toBlob(b64Data, contentType = '', sliceSize = 512): Blob | null {
  const byteCharacters = atob(b64Data);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function getHtmlFromFormattedText(text: string, fromTable = false): string {
  let str = text
    .replace(/\*\*\*(.*?)\*\*\*/g, function (outer, inner) {
      return `<strong><em>${inner}</em></strong>`;
    })
    .replace(/\*\*(.*?)\*\*/g, function (outer, inner) {
      return `<strong>${inner}</strong>`;
    })
    .replace(/\*(.*?)\*/g, function (outer, inner) {
      return `<em>${inner}</em>`;
    })
    .replace('<pre><code>', '<p>')
    .replace('</code></pre>', '</p>');
  if (fromTable) {
    str = str.replace(/\n/g, '<br>');
  }
  return str;
}

const userMentionReplacementString = `
  <span class="mention" data-denotation-char="@" data-id="$1" data-value="$2">
    <span contenteditable="false"><span class="ql-mention-denotation-char">@</span>$2</span>
  </span>
`;

export const renderUserMentions = (markdown: string): string =>
  markdown.replace(
    /<user-mention data-id="(.+?)" data-name="(.+?)" \/>/g,
    userMentionReplacementString,
  );

export function getConvertedLatex(htmlStr: string): string {
  let convertedLatex = htmlStr
    .replace(/&amp;/g, '& ')
    .replace(/&lt;/g, 'lt ')
    .replace(/&gt;/g, 'gt ')
    .replace(/&quot;/g, "''");
  if (!convertedLatex.includes('!\\')) {
    convertedLatex = convertedLatex.replace(/\\!/g, '!');
  }
  return convertedLatex;
}

export function getConvertedHtml(htmlStr: string): string {
  let convertedHtml = '';
  extractMath(htmlStr, {
    delimiters: {
      display: ['$$', '$$'],
    },
  }).forEach((html) => {
    if (html.math) {
      const convertedLatex = getConvertedLatex(html.raw);
      convertedHtml += `<span class="ql-formula" data-value="${convertedLatex}">
        <span contenteditable="false">${katex.renderToString(convertedLatex)}</span></span>`;
    } else {
      convertedHtml += html.raw.replace(/\\\$/g, '$').replace(/\\\)/g, ')').replace(/\\\./g, '.');
    }
  });
  convertedHtml = renderUserMentions(convertedHtml);
  return convertedHtml;
}

export function isFacebookAppUAString(): boolean {
  // returns true iff the app is loaded in FB's in-app-browser (FB App or Msgr app)
  const ua = navigator.userAgent || navigator.vendor;
  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
}

export async function firebaseRedirectLoginEnabled(
  deviceService: DeviceDetectorService,
): Promise<boolean> {
  // Use this function to decide if Firebase login redirect will break under Incognito mode for the current browser
  // At the time of writing, this breaks in Chrome + incognito + desktop (windows, linux, mac) where quota < 5 GB,
  // so we'll treat that as redirect login is disabled
  // Returns a promise which reolves to `true` if redirect login should work on this browser, `false` otherwise

  const isDesktopDevice = deviceService.isDesktop();
  const deviceBrowser = deviceService.getDeviceInfo().browser;
  if (isDesktopDevice && deviceBrowser.toLowerCase() === 'chrome') {
    // this is chrome on desktop
    if ('storage' in navigator && 'estimate' in navigator.storage) {
      const { quota } = await navigator.storage.estimate();
      if (quota && quota < 5000000000) {
        // quota returned less than 5 GB (incognito mode), redirect login will not work;
        return false;
      } else {
        // quota returned > 5 GB, redirect login should work.
        if (navigator.cookieEnabled) {
          // check if 3rd party cookies are also enabled. If not, facebook redirect login runs into strange issues.
          return true;
        }
        return false;
      }
    }
  }
  // check was inconclusive, redirect login *may* work;
  return true;
}

export const getTruncatedName = (name: string, length = 20): string =>
  name && name.length > length ? `${name.substring(0, length)}...` : name;

/**
 * get unique elements of array of object T
 * that contains only the property prop
 * @param obj
 * @param prop
 */
export function getUniqueBy<T>(obj: T[], prop): T[] {
  return uniqBy(obj, prop).filter((e) => e[prop]);
}

export const redirectToAppScript = (
  authMethod: string,
  username: string | null,
  password: string | null,
  fbUser: any,
) => {
  const authObject = {
    authMethod,
    username,
    password,
    refreshToken: fbUser?.refreshToken,
  };
  const encryption = CryptoJS.AES.encrypt(
    JSON.stringify(authObject),
    environment.thirdPartyEncryptionSalt,
  ).toString();
  const encryptionArr = CryptoJS.enc.Base64.parse(encryption);
  const encryptedHex = encryptionArr.toString(CryptoJS.enc.Hex);
  const url = new URL(`${environment.appScriptURL}?page=auth&param=${encryptedHex}`);
  window.location.replace(url.toString());
};

export const extractEmails = (
  input: string,
  telemetry: TelemetryService,
): {
  emails: string[];
  invalidEmails: string[];
  isMultiple: boolean;
} => {
  const emails = new Set<string>();
  const invalidEmails = new Set<string>();
  const splittedInputs = input
    .split(/[,;\r\n]+/)
    .filter(Boolean)
    .map((split) => split.trim());
  splittedInputs.forEach((splittedInput) => {
    const matchedEntries = splittedInput.match(
      /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/,
    );
    if (matchedEntries && matchedEntries.length === 1) {
      const possibleEmail = matchedEntries[0];
      const hasErrors = getEmailValidationErrors(possibleEmail);
      if (hasErrors) {
        invalidEmails.add(possibleEmail);
      } else {
        emails.add(possibleEmail);
      }
    } else {
      invalidEmails.add(splittedInput);
    }
  });
  if (invalidEmails.size) {
    telemetry.event('[Error] Invalid format for parsing emails', {
      input,
      validExtractedEmails: Array.from(emails),
      invalidExtractedEmails: Array.from(invalidEmails),
    });
  }
  return {
    emails: Array.from(emails),
    isMultiple: splittedInputs.length > 1,
    invalidEmails: Array.from(invalidEmails),
  };
};

export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue =>
  value !== null && value !== undefined;

export const getUpdatedPermissions = (permissions: Permissions, selectedPermission: string) => {
  if (Object.values(permissions[selectedPermission]).every((val) => !!val)) {
    permissions[selectedPermission] = true;
  } else if (Object.values(permissions[selectedPermission]).every((val) => !val)) {
    permissions[selectedPermission] = false;
  }
  return permissions;
};

export const isPermissionEnabled = (
  permissions: Permissions,
  permissionName: string,
  userId: string,
): boolean => {
  if (!permissions) {
    return false;
  }
  if (typeof permissions[permissionName] === 'boolean' || !permissions[permissionName]) {
    return !!permissions[permissionName];
  } else {
    return permissions[permissionName][userId];
  }
};

export const parseTextLikeCsv = (text: string) => {
  const items = text.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g);

  if (!items) {
    return null;
  }
  const cleanedItems = items.map((item) => item.replace(/"/g, '').trim());

  return cleanedItems;
};

export enum CustomErrorCodes {
  NOT_IN_COURSE = 1000,
  CANT_ACCESS_QUESTION = 1001,
  ACCOUNT_NOT_SIGNED_UP = 1002,
  EMAIL_NOT_IN_TOKEN = 1003,
  FB_EMAIL_NOT_IN_TOKEN = 1004,
  CLIENT_VERSION_UPDATE_NEEDED = 1005,
  CANNOT_CLONE_SPACE_TEMPLATE = 1006,
  SPACE_IS_NOT_TEMPLATE = 1007,
  BYPASS_ERROR_INTERCEPTOR = 9999,
  USERS_EXIST_IN_OTHER_INSTITUTIONS = 1014,
}

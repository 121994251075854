<div id="chatPanel" class="chat-panel d-flex flex-column h-100">
  <div style="position: relative">
    <div class="top-section border-bottom d-flex align-items-center">
      <div class="header-icon">
        <svg-icon
          [width]="20"
          [height]="20"
          [icon]="
            (unreadPrivateChat &&
              (messengerService.activeChatOptions | async) === ChatOptions.chat) ||
            (unreadChat && (messengerService.activeChatOptions | async))
              ? 'unread-message'
              : 'read-message'
          "
        ></svg-icon>
        <p class="chat-panel-title">{{ 'Chat' | translate }}</p>
      </div>
      <em
        *ngIf="
          (isSpaceOwner$ | async) &&
          (messengerService.activeChatOptions | async) === ChatOptions.chat &&
          totalMessages > 0
        "
        [matMenuTriggerFor]="chatMenu"
        class="more-icon material-icons-outlined cursor-pointer cml-16"
        style="font-size: 20px"
        data-name="chat-tab-header-options"
        >more_horiz</em
      >
      <mat-menu #chatMenu="matMenu">
        <button
          mat-menu-item
          class="d-flex align-items-center"
          (click)="onDeleteAllChat()"
          data-name="chat-tab-header-options-delete-chat"
        >
          <em class="material-icons-outlined cursor-pointer cmr-16" style="font-size: 20px"
            >delete_forever
          </em>
          {{ 'Clear Chat' | translate }}
        </button>
      </mat-menu>
      <button mat-icon-button class="button-close" (click)="onClose()" data-name="chat-tab-close">
        <em class="material-icons">close</em>
      </button>
    </div>
    <ui-header *ngIf="!isSpaceDirectMessagingEnabled" class="border-bottom header chat-selection">
      <div class="position-relative flex-1 h-100">
        <nav class="navbar navbar-expand-lg cp-0">
          <ul class="navbar-nav flex-row">
            <li
              class="nav-item hover"
              [class.active]="(messengerService.activeChatOptions | async) === 0"
              (click)="messengerService.activeChatOptions.next(0)"
            >
              <a
                class="nav-link"
                style="font-family: Source Sans Pro; font-size: 14px; cursor: pointer"
              >
                {{ 'Everyone in this Space' | translate }}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </ui-header>
  </div>

  <div class="header-section" *ngIf="isActiveChat && isSpaceDirectMessagingEnabled">
    <app-chat-menu-header (closeChat)="closeChat()"></app-chat-menu-header>
  </div>

  <section
    class="flex-1 overflow-hidden position-relative"
    *ngIf="
      (messengerService.activeChatOptions | async) === ChatOptions.chat &&
      ((messagingService.openedPrivateChatUser$ | async) === null || !isSpaceDirectMessagingEnabled)
    "
  >
    <div *ngFor="let chat of activeChats" class="chat-box">
      <app-chat-window
        [state]="chat"
        [userLookup]="userLookup"
        [clickAvatars]="false"
        [messagingEnabled]="spacePermissionsManagerService.isPermissionEnabled('messageEveryone')"
      ></app-chat-window>
    </div>
  </section>

  <section
    *ngIf="
      (messengerService.activeChatOptions | async) === ChatOptions.transcript &&
      ((messagingService.openedPrivateChatUser$ | async) === null || !isSpaceDirectMessagingEnabled)
    "
  >
    <app-chat-transcript-window></app-chat-transcript-window>
  </section>

  <section
    class="user-chat-box"
    *ngIf="
      (messengerService.activeChatOptions | async) === ChatOptions.private &&
      (messagingService.openedPrivateChatUser$ | async)
    "
  >
    <app-conversation-container
      [ignoreHeaderInfo]="true"
      [enableControls]="false"
      [isClickEnabled]="false"
      [enableMessaging]="enableMessaging"
      [emitTyping]="!!user?.institution"
    >
    </app-conversation-container>
  </section>

  <section
    class="new chat-list"
    *ngIf="(messengerService.activeChatOptions | async) === null && isSpaceDirectMessagingEnabled"
  >
    <app-chat-menu
      [spaceTranscriptsEnabled]="spaceTranscriptsEnabled"
      (openChat)="onOpenChatMenuOption($event)"
      [user]="user"
      [unreadGeneralMessageCount]="messengerService.unreadSpaceLevelMessageCount$ | async"
    ></app-chat-menu>
  </section>
</div>

import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, pluck } from 'rxjs/operators';

import { FlagsService, FLAGS } from 'src/app/services/flags.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessagingService } from '../services/messaging.service';
import { ConversationPreview } from '../models/messaging';
import { isUserPreview, previewEquals } from '../common/utils/messaging';
import { UiService } from '../services/ui.service';


@UntilDestroy()
@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit {
  searchQuery = '';
  wideScreen$: Observable<boolean>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    public messagingService: MessagingService,
    public flagsService: FlagsService,
    private uiService: UiService
  ) {
    this.uiService.setTabTitle('Messages');
    this.wideScreen$ = this.breakpointObserver.observe('(min-width: 500px)').pipe(pluck('matches'));
  }

  ngOnInit(): void {
    this.messagingService.setSearchQuery('');
    if (this.flagsService.isFlagEnabled(FLAGS.DISABLE_MESSAGING)) {
      this.router.navigate(['content']);
    }
    /* As the user searches, reset the currently selected chat if the
     * previously selected chat is not part of the narrowed search results.
     */
      this.messagingService.selectedConversationPreview$
        .pipe(filter((selectedConversationPreview) => selectedConversationPreview === null))
        .pipe(untilDestroyed(this)).subscribe(() => {
          this.router.navigate(['messages', 'home'], { queryParamsHandling: 'merge' });
        });

    // Redirect from /messages to /messages/home
    if (!this.activatedRoute.snapshot.firstChild) {
      this.router.navigate(['messages', 'home'], { queryParamsHandling: 'merge' });
    }
  }

  executeSearch(searchVal: string): void {
    this.messagingService.setSearchQuery(searchVal);
  }

  isPreviewSelected(
    preview: ConversationPreview,
    selectedPreview: ConversationPreview | null,
  ): boolean {
    return Boolean(selectedPreview && previewEquals(preview)(selectedPreview));
  }

  selectPreview(preview: ConversationPreview): void {
    if (isUserPreview(preview)) {
      this.router.navigate(['messages', 'user', preview.userInfo._id], {
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate(['messages', 'group', preview.group._id], {
        queryParamsHandling: 'merge',
      });
    }
  }


}

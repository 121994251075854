import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { filterNil } from '@ngneat/elf';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { BehaviorSubject, map, take, tap } from 'rxjs';
import { SUBSCRIPTION_TYPES } from '../../../models/payment';
import { User } from '../../../models/user';
import { FLAGS, FlagsService } from '../../../services/flags.service';
import { ForegroundActivityNotificationsService } from '../../../services/foreground-activity-notifications.service';
import { UpgradePlanEvaluationType } from '../../../services/paywall-indicator.service';
import { SettingsService } from '../../../services/settings.service';
import { UserService } from '../../../services/user.service';

@UntilDestroy()
@Component({
  selector: 'app-user-settings-ai-assist',
  templateUrl: './user-settings-ai-assist.component.html',
  styleUrls: ['./user-settings-ai-assist.component.scss'],
})
export class UserSettingsAiAssistComponent implements OnInit {
  private user$ = this.userService.user.pipe(
    map((x) => x?.user),
    filterNil(),
  );

  @Input() isMobile = false;
  @Input() PANELS: any;
  @Input() form!: UntypedFormGroup;
  @Output() goBack = new EventEmitter<any>();
  @Output() formChangedEvent: EventEmitter<boolean> = new EventEmitter();

  settingsForm?: UntypedFormGroup;
  user?: User;
  isLoading = false;

  cloudRecordingEnabled: boolean = false;
  liveCallAlertsEnabled: boolean = false;
  public initialFormValue: unknown;
  public formChanged$ = new BehaviorSubject<boolean>(false);

  public canUseAISummarization$ = this.user$.pipe(
    map((user) => !!user.institution.settings?.enableCloudRecordingAI),
  );
  public canUseCloudRecording$ = this.user$.pipe(
    map((user) => user.subscriptionType === SUBSCRIPTION_TYPES.ENTERPRISE),
  );
  public canUseLiveCallAlerts$ = this.user$.pipe(
    map((user) => !!user.institution.settings?.enableLiveCallAlerts),
  );
  inactivityNotificationsEnabled = false;
  inactivityNotificationsPaid = false;
  public readonly UpgradePlanEvaluationType = UpgradePlanEvaluationType;

  constructor(
    private userService: UserService,
    private settingsService: SettingsService,
    private translateService: TranslateService,
    private flagsService: FlagsService,
    public foregroundInactivityNotificationsService: ForegroundActivityNotificationsService,
  ) {
    this.flagsService
      .featureFlagChanged(FLAGS.CLOUD_RECORDING)
      .pipe(untilDestroyed(this))
      .subscribe((cloudRecordingEnabled) => (this.cloudRecordingEnabled = cloudRecordingEnabled));
    this.flagsService
      .featureFlagChanged(FLAGS.LIVE_NOTIFICATION_SPEAKING_DURATION)
      .pipe(untilDestroyed(this))
      .subscribe((liveCallAlertsEnabled) => (this.liveCallAlertsEnabled = liveCallAlertsEnabled));
  }

  ngOnInit(): void {
    this.settingsForm = this.form?.controls.settings as UntypedFormGroup;
    this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
      this.user = res?.user;
      this.inactivityNotificationsPaid =
        this.foregroundInactivityNotificationsService.userPaidForFeature(this.user);
      this.initForm();
    });

    this.flagsService
      .featureFlagChanged(FLAGS.INACTIVITY_NOTIFICATIONS)
      .pipe(untilDestroyed(this))
      .subscribe(
        (inactivityNotificationsEnabled) =>
          (this.inactivityNotificationsEnabled = inactivityNotificationsEnabled),
      );
  }

  initForm(): void {
    this.form.patchValue({
      ...this.user?.institution,
      settings: {
        ...(this.user?.institution?.settings ?? {}),
        // display it as off when it is not paid
        inactivityNotifications:
          this.inactivityNotificationsPaid &&
          (this.user?.institution?.settings?.inactivityNotifications ?? false),
      },
    });

    this.initialFormValue = this.form.value;
    this.form.valueChanges
      .pipe(
        map(() => !isEqual(this.initialFormValue, this.form.value)),
        tap((x) => this.formChangedEvent.emit(x)),
        untilDestroyed(this),
      )
      .subscribe(this.formChanged$);
  }

  submitChanges(): void {
    if (!this.user || this.isLoading) {
      return;
    }

    this.isLoading = true;
    const updatedUser = {
      ...this.user,
      institution: {
        ...this.user?.institution,
        ...this.form.value,
      },
    };

    updatedUser.institution.settings = {
      ...this.user?.institution.settings,
      ...this.form.value.settings,
    };

    // if the option was turned off because it wasn't paid, we don't want to change its value.
    // because this will create a bug where if the user is not subscribed and changed something in the settings
    // the inactivityNotifications options will be turned off.
    if (
      !this.inactivityNotificationsPaid &&
      this.user?.institution?.settings?.inactivityNotifications
    ) {
      updatedUser.institution.settings.inactivityNotifications =
        this.user?.institution.settings.inactivityNotifications;
    }

    this.userService
      .updateInstitution(updatedUser)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.refresh(updatedUser);
          if (this.isMobile) {
            this.goBack.emit();
          }
        },
        error: () => {
          this.settingsService.showToast(
            {
              title: this.translateService.instant('Update failed'),
              description: this.translateService.instant('An error occurred. Please try again.'),
            },
            true,
          );
        },
        complete: () => {
          this.isLoading = false;
          this.formChanged$.next(false);
          this.formChangedEvent.emit(false);
        },
      });
  }

  refresh(userPayload: User): void {
    const userFromService = this.userService.user.getValue();
    this.userService.user.next({
      ...userFromService,
      user: userPayload,
    });
    this.settingsService.showToast({
      title: this.translateService.instant('Institution updated'),
      description: this.translateService.instant('Your institution has been successfully updated.'),
    });
  }
}

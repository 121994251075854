import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, map, combineLatest } from 'rxjs';
import {
  AttributesSearchFilter,
  DateRangeFilter,
  DefaultFilterKey,
  FilterData,
  FilterType,
  OwnerFilter,
  SiteSearchFilter,
  UserSearchFilter,
} from '../standalones/components/generic-filters-view/filters.interface';
import { CustomAttribute } from '../models/user';
import { UserService } from './user.service';
import { AclService } from './acl.service';
import { FLAGS, FlagsService } from './flags.service';

@Injectable({
  providedIn: 'root',
})
export class FiltersFactoryService {
  constructor(
    private translateService: TranslateService,
    private userService: UserService,
    private aclService: AclService,
    private flagsService: FlagsService,
  ) {}

  createSpacesFilters(): Observable<FilterData[]> {
    return combineLatest([
      this.createCreatedDateFilter(),
      this.createUpdatedDateFilter(),
      this.createOwnerFilter('Only show Spaces you own'),
      this.createHostSearchFilter(),
      this.createParticipantSearchFilter(),
      this.createSiteSearchFilter(),
    ]);
  }

  createEventsFilters(): Observable<FilterData[]> {
    return combineLatest([
      this.flagsService.featureFlagChanged(FLAGS.ENABLE_EVENTS_CA),
      this.createSiteSearchFilter(),
      this.createAttributesSearchFilters(),
    ]).pipe(
      map(([isCAFlagEnabled, siteFilter, attributesFilters]) =>
        isCAFlagEnabled ? [siteFilter, ...attributesFilters] : [siteFilter],
      ),
    );
  }

  createAttendanceReportsFilters(): Observable<FilterData[]> {
    return combineLatest([this.createSiteSearchFilter()]);
  }

  createAnalyticsReportsFilters(): Observable<FilterData[]> {
    return combineLatest([this.createSiteSearchFilter()]);
  }

  //
  // Private Functions
  //

  private createCreatedDateFilter(): Observable<DateRangeFilter> {
    return this.createDateFilter(DefaultFilterKey.DateCreatedFilter, 'DATE CREATED');
  }

  private createUpdatedDateFilter(): Observable<DateRangeFilter> {
    return this.createDateFilter(DefaultFilterKey.DateUpdatedFilter, 'DATE LAST UPDATED');
  }

  private createOwnerFilter(label: string): Observable<OwnerFilter> {
    const userData = this.userService.user.getValue();
    if (!userData?.user) {
      throw new Error('User data is missing');
    }

    return of(new OwnerFilter(this.translateService, 'IS OWNER', userData.user._id, label));
  }

  private createHostSearchFilter(): Observable<UserSearchFilter> {
    return this.createUserSearchFilter(
      DefaultFilterKey.HostsFilter,
      FilterType.UserSearch,
      'HOSTS',
    );
  }

  private createParticipantSearchFilter(): Observable<UserSearchFilter> {
    return this.createUserSearchFilter(
      DefaultFilterKey.ParticipantsFilter,
      FilterType.UserSearch,
      'PARTICIPANTS',
    );
  }

  private createDateFilter(
    key: DefaultFilterKey.DateCreatedFilter | DefaultFilterKey.DateUpdatedFilter,
    title: string,
  ) {
    return of(new DateRangeFilter(key, this.translateService, title));
  }

  private createUserSearchFilter(
    key: DefaultFilterKey.HostsFilter | DefaultFilterKey.ParticipantsFilter,
    type: FilterType,
    title: string,
  ) {
    return of(new UserSearchFilter(key, type, this.translateService, title));
  }

  private createSiteSearchFilter(): Observable<SiteSearchFilter> {
    return this.userService.user.pipe(
      map((res) => (res?.user ? this.aclService.getUserSites(res.user) : [])),
      map((addableSites) => new SiteSearchFilter(this.translateService, 'SITES', [], addableSites)),
    );
  }

  private createAttributesSearchFilters(): Observable<AttributesSearchFilter[]> {
    return this.userService.allInstitutionCustomAttributes.pipe(
      map((custom_attributes: CustomAttribute[]) =>
        custom_attributes.map(
          (attribute) => new AttributesSearchFilter(this.translateService, attribute),
        ),
      ),
    );
  }
}

<ng-container *ngIf="viewModel$ | async as viewModel">
  <section class="zoom-control">
    <section
      [hidden]="!viewModel.zoomNavigatorEnabled"
      class="zoom-control__navigator top-left-radius top-right-radius bottom-left-radius"
    >
      <header class="zoom-control__header">
        <p>{{ 'Navigator' | translate }}</p>

        <button
          mat-icon-button
          class="btn-close"
          style="box-shadow: none !important"
          (click)="handleCloseNavigator()"
          data-name="zoom-toolbar-navigator-close"
        >
          <span class="material-icons">close</span>
        </button>
      </header>

      <section class="zoom-control__main-view">
        <section class="zoom-control__zoom-controls zoom-control__btn-box">
          <button
            [disabled]="!canDecrementZoom"
            [appTooltip]="zoomOutTooltip"
            [appTooltipPlacement]="'bottom-center'"
            class="zoom-control__btn mr-1"
            (click)="handleDecrementZoom()"
            [ngClass]="{ disabled: !canDecrementZoom }"
            data-name="zoom-toolbar-navigator-zoom-out"
          >
            <ng-template #zoomOutTooltip>
              <app-tooltip [text]="'Zoom out' | translate" [arrow]="'top'"></app-tooltip>
            </ng-template>
            <svg-icon [icon]="'minus-sign'" [height]="3" [width]="14"></svg-icon>
          </button>

          <button
            [disabled]="!canIncrementZoom"
            [appTooltip]="zoomInTooltip"
            [appTooltipPlacement]="'bottom-center'"
            class="zoom-control__btn"
            (click)="handleIncrementZoom()"
            [ngClass]="{ disabled: !canIncrementZoom }"
            data-name="zoom-toolbar-navigator-zoom-in"
          >
            <ng-template #zoomInTooltip>
              <app-tooltip [text]="'Zoom in' | translate" [arrow]="'top'"></app-tooltip>
            </ng-template>
            <svg-icon [icon]="'plus-sign'" [height]="15" [width]="14"></svg-icon>
          </button>
        </section>

        <section class="zoom-control__btn-box stacked-buttons">
          <button
            (click)="handleResetCanvas()"
            class="zoom-control__btn back-to-center w-100"
            data-name="zoom-toolbar-navigator-center"
          >
            <i class="margin-icon material-icons">center_focus_strong</i>
            {{ 'Back to center' | translate }}
          </button>
          <button
            (click)="handleFitContent()"
            class="zoom-control__btn back-to-center w-100"
            data-name="zoom-toolbar-navigator-content"
          >
            <i class="margin-icon material-icons">fit_screen</i>
            {{ 'Fit to view' | translate }}
          </button>
        </section>
      </section>
    </section>

    <section class="d-flex justify-content-end">
      <wb-zoom-toolbar
        (toggleZoomNavigator)="handleZoomNavigatorChange()"
        [zoomNavigatorEnabled]="viewModel.zoomNavigatorEnabled"
        [currentZoomLevel]="viewModel.currentZoomLevel"
        class="border-bottom-radius ms-auto"
      >
      </wb-zoom-toolbar>
    </section>
  </section>
</ng-container>

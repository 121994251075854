import { Component, Input } from '@angular/core';
import { modifiedSetInterval } from 'src/app/utilities/ZoneUtils';
import { IconMessageToasterElement } from '../icon-message-toaster-element/icon-message-toaster-element.component';
import { ToasterElement, ToasterElementType } from '../toaster-element/toaster-element.component';

export enum ButtonToasterElementStyle {
  RAISED = 'notification-button-raised',
  RAISED_ACTIVE = 'notification-button-raised-active',
  FLAT = 'notification-button-flat',
  DISABLED = 'notification-button-disabled',
  LINK = 'notification-button-link',
  NAVIGATE = 'notification-button-navigate',
  NAVIGATE_REVERSE = 'notification-button-navigate-reverse',
  PRIMARY = 'notification-button-primary',
  SECONDARY = 'notification-button-secondary',
}

interface ClickHandler {
  // handler to be pressed on click
  handler: () => void;

  // should the notification be closed when pressed
  close?: boolean;

  // count down for retry
  countDown?: number;

  // message to display while counting down
  countDownMsg?: string;
}
export class ButtonToasterElement implements ToasterElement {
  readonly type = ToasterElementType.BUTTON;

  // string to be placed within the button
  title: IconMessageToasterElement;

  onClick?: ClickHandler;

  style?: ButtonToasterElementStyle = ButtonToasterElementStyle.FLAT;

  interval?: NodeJS.Timer;

  constructor(
    title: ConstructorParameters<typeof IconMessageToasterElement>,
    onClick?: ClickHandler,
    style?: ButtonToasterElementStyle,
  ) {
    this.title = new IconMessageToasterElement(...title);
    this.onClick = onClick;
    this.style = style;
    if (this.onClick?.countDown) {
      const originalOnClickHandler = this.onClick.handler;
      this.onClick!.handler = () => {
        originalOnClickHandler.call(this);
        this.countDownHandler();
      };
      this.countDownHandler();
    }
  }

  private countDownHandler() {
    const originalMsg = this.title.msg;
    const originalStyle = this.style;
    let counter = this.onClick?.countDown;
    this.style = ButtonToasterElementStyle.DISABLED;
    this.title.msg = `${this.onClick?.countDownMsg} (${counter})`;
    const originalClickHandler = this.onClick?.handler;
    // disable click hanlder while counting down
    this.onClick!.handler = () => undefined;
    this.interval = modifiedSetInterval(() => {
      counter = counter! - 1;
      this.title.msg = `${this.onClick?.countDownMsg} (${counter})`;
      if (counter === 0) {
        clearInterval(this.interval!);
        this.style = originalStyle;
        this.title.msg = originalMsg;
        this.onClick!.handler = () => {
          originalClickHandler?.call(this);
        };
      }
    }, 1000); // update counter every second
  }
}

@Component({
  selector: 'app-button-toaster-element',
  templateUrl: './button-toaster-element.component.html',
  styleUrls: ['./button-toaster-element.component.scss'],
})
export class ButtonToasterElementComponent {
  @Input() notificationButtonElement?: ButtonToasterElement;
}

<div class="prompt-outline">
  <div class="header">
    <div>{{ data.header | translate }}</div>
    <button mat-icon-button (click)="close(false)"><mat-icon>close</mat-icon></button>
  </div>
  <div class="content">
    <div class="content__body">{{ data.body | translate }}</div>
    <div class="content__control-buttons">
      <div *ngFor="let option of data.options">
        <app-pencil-button
          mat-button
          class="pencil-button medium breakout-room-dialog-btn"
          [ngClass]="
            option.style === 'danger' ? 'danger' : option.style === 'confirm' ? 'primary' : 'light'
          "
          (clickEvent)="close(option.callback())"
          [icon]="option.icon ? option.icon : option.svgIcon"
          [iconConfig]="{ type: option.icon ? IconTypes.matIcon : IconTypes.svgIcon }"
          [label]="option.text | translate"
        >
        </app-pencil-button>
      </div>
    </div>
  </div>
</div>

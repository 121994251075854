<div class="ui-number-input">
  <input
    type="number"
    [min]="min"
    [max]="max"
    [value]="value"
    (change)="onChange($event)"
    (blur)="onClickArrows(value)"
  />
  <div class="arrows">
    <span class="material-icons" (click)="onClickArrows(+value + step)"> arrow_drop_up </span>
    <span class="material-icons" (click)="onClickArrows(+value - step)"> arrow_drop_down </span>
  </div>
</div>

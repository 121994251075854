<div class="d-flex flex-column card-content" *ngIf="user">
  <img [src]="heroImage || 'assets/icons/profile-bg.png'" class="profile-bg" />
  <img [src]="user.profile_image" class="rounded-circle profile-image" width="80" height="80" />
  <div class="d-sm-flex d-md-flex d-xl-block w-100">
    <div class="d-flex flex-column flex-1 left-section">
      <p class="cmt-12">{{ user.name }}</p>
      <p class="mainText2 cmb-24 cmb-sm-16 d-sm-block d-md-block d-xl-none d-none">
        {{ user.description }}
      </p>
      <button
        class="cmt-12 cpx-32 cmx-0 btn-contact pcl-user-profile-chat_bubble"
        ui-primary
        color="blue"
        [style.height]="'40px'"
        (click)="sendMessage()"
      >
        <i class="material-icons-outlined cmr-8 font-18">chat_bubble_outline</i>
        {{ 'Contact me' | translate }}
      </button>
      <!-- This should be enabled later -->
      <!-- <button
        class="cmt-12 cpx-32 cmx-0 cmb-16 cmb-sm-0 btn-contact"
        ui-primary
        color="blue"
        [style.height]="'40px'"
        [matMenuTriggerFor]="contactMenu"
      >
        <i class="material-icons-outlined cmr-8 font-18">chat_bubble_outline</i>
        {{ 'Contact me' | translate }}
      </button> -->
      <mat-menu #contactMenu="matMenu" class="contact-menu">
        <div class="contact-menu-container" (click)="$event.stopPropagation()">
          <p class="title">{{ 'Send a message to' | translate }} {{ user.name }}</p>
          <textarea
            class="message cmb-8 w-100"
            [class.empty]="!message"
            rows="7"
            [(ngModel)]="message"
            [placeholder]="'Write your message here.' | translate"
          ></textarea>
          <p class="description cmb-16">
            {{
              'By pressing Send Message, you agree that ' +
                user.name +
                " and its affiliates, may call/text you about your inquiry, which may involve use of automated means and prerecorded/artificial voices. You don't need to consent as a condition of buying any property, goods or services. Message/data rates may apply. You also agree to our Terms of Use. We may share information about your recent and future site activity with your agent to help them understand what you're looking for in a home."
                | translate
            }}
          </p>
          <button
            class="round"
            ui-primary
            color="blue"
            [disabled]="!message"
            (click)="sendMessage()"
          >
            <i class="material-icons font-18 cmr-8">arrow_forward</i>
            {{ 'Send message' | translate }}
          </button>
        </div>
        <i class="material-icons btn-close">close</i>
      </mat-menu>
    </div>
    <div class="cpb-24 w-100 cmt-24 flex-1 cmt-sm-32 right-section d-none d-sm-block">
      <p class="mainText2 cmb-24 border-top cpt-20 d-xl-block d-none">{{ user.description }}</p>
      <!-- <div
        *ngFor="let detail of profileDetails"
        class="d-flex align-items-center justify-content-between cmt-8 text-gray"
      >
        <div class="d-flex align-items-center">
          <i class="material-icons-outlined font-18 cmr-4">{{ detail.icon }}</i>
          <span class="text-uppercase font-12">{{ detail.title }}</span>
        </div>
        <span class="font-12">{{ detail.data }}</span>
      </div> -->
    </div>
  </div>
</div>

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Subject } from 'rxjs';
import { DomListenerFactoryService } from 'src/app/services/dom-listener-factory.service';

@UntilDestroy()
@Component({
  selector: 'app-extended-textbox',
  templateUrl: './extended-textbox.component.html',
  styleUrls: ['./extended-textbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtendedTextboxComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() mainText = '';
  @Input() disabled = false;
  @Input() disableWhenMaxLengthReached = false;
  @Input() errorLocation = 'below';
  @Input() activateOnBlur = true;
  @Input() maxAllowedCharacters = 100;
  @Input() trucateStringBy;
  @Input() enableValidation = true;
  @Input() spanId = 'spanElement';
  @Input() adjustingTextDisplay$ = new Subject<boolean>();
  @Input() customStyles?;
  @Input() spanEditingInlineFlex?: boolean; // on true, sets span style to 'inline'flex' to prevent span text jumping upp on edit
  @Input() hoverBackground?;
  @ViewChild('textField') textField?: ElementRef<HTMLSpanElement>;
  @Output() editFinished = new EventEmitter<string>();
  @Output() editStarted = new EventEmitter();
  @Output() hoverStart = new EventEmitter();
  @Output() hoverEnd = new EventEmitter();

  isTitleValid$ = new BehaviorSubject<boolean>(true);
  isEditing$ = new BehaviorSubject<boolean>(false);
  isAdjustingTextDisplay$ = new BehaviorSubject<boolean>(false);

  domListener = this.domListenerFactoryService.createInstance();

  constructor(
    private domListenerFactoryService: DomListenerFactoryService,
    public cdRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    const spanElement = document.getElementById(this.spanId);
    this.domListener.add(spanElement, 'mouseenter', this.onMouseEnter.bind(this), true);
    this.domListener.add(spanElement, 'mouseleave', this.onMouseLeave.bind(this), true);
  }

  ngOnInit() {
    this.adjustingTextDisplay$.pipe(untilDestroyed(this)).subscribe(this.isAdjustingTextDisplay$);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mainText?.currentValue !== changes.mainText?.previousValue && this.textField) {
      this.textField.nativeElement.innerText = this.mainText;
    }
  }

  ngOnDestroy(): void {
    this.domListener.clear();
  }

  setText(value: string): void {
    if (!this.textField) {
      return;
    }

    this.textField.nativeElement.innerText = value;
  }

  checkValidTitle($event: KeyboardEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    if (!this.textField) {
      return;
    }

    if (!this.textField.nativeElement.innerText) {
      this.isTitleValid$.next(this.enableValidation);
      return;
    }

    const isTitleValid =
      this.textField.nativeElement.innerText.length <= this.maxAllowedCharacters ||
      !this.enableValidation;

    this.isTitleValid$.next(isTitleValid);
  }

  handleTitlePaste($event: ClipboardEvent): void {
    $event.preventDefault();
    if (!this.textField) {
      return;
    }
    const title = $event.clipboardData?.getData('text');
    this.textField.nativeElement.innerText = (title || '').trim().split('\n')[0].trim();
  }

  preventNewLine($event: KeyboardEvent): void {
    // 13 is the keycode for Enter
    if ($event.shiftKey && $event.keyCode === 13) {
      $event.preventDefault();
    }
  }

  /**
   * prevent editing the content of the text-input if max chars reached
   * @param $event
   */
  preventExceedMaxAllowedCharsLength($event): void {
    if (this.disableWhenMaxLengthReached) {
      const isMaxLengthReached =
        ($event.target as any)?.innerText.length >= this.maxAllowedCharacters;
      if (isMaxLengthReached) {
        $event.preventDefault();
      }
    }
  }

  onBlur(input: string): void {
    if (
      this.disabled ||
      !this.activateOnBlur ||
      this.isAdjustingTextDisplay$.getValue() ||
      !this.isEditing$.getValue()
    ) {
      return;
    }

    if (this.isTitleValid$.getValue()) {
      this.editFinished.emit(input);
    } else {
      if (!this.textField) {
        return;
      }

      this.textField.nativeElement.innerText = this.mainText;
      this.editFinished.emit('');
    }
    this.isEditing$.next(false);
    this.isTitleValid$.next(true);
    // Reset the text field text position to the front so the beginning of the title is visible
    this.resetTextboxSelection();
  }

  resetTextboxSelection(): void {
    // This function moves the window selection to the beginning of the textField (span) element.

    const range = document.createRange();
    const windowSelection = window.getSelection();
    if (!windowSelection) {
      return;
    }

    // remove any previously created ranges
    windowSelection.removeAllRanges();

    if (!this.textField || !this.textField.nativeElement.childNodes) {
      return;
    }

    const textFieldNodes = Array.from(this.textField.nativeElement.childNodes);
    this.textField.nativeElement.focus();
    const firstNode = textFieldNodes[0] as Node;
    const lastNode = textFieldNodes[textFieldNodes.length - 1] as Node;
    if (!firstNode || !lastNode) {
      return;
    }

    range.setStartBefore(firstNode);
    range.setEndAfter(lastNode);
    range.collapse(false);

    // add the range to a window selection object.
    windowSelection.addRange(range);
    windowSelection.collapseToEnd();

    // remove focus from the text field (as we called focus above). This will cause `onBlur()` to
    // be called again, but this time `isRenamingSpace` is set to false so this will be a no-op
    this.textField.nativeElement.blur();
  }

  onSelect($event?: Event | null): void {
    if (this.textField && (this.disabled || this.isAdjustingTextDisplay$.getValue())) {
      this.textField.nativeElement.contentEditable = 'false';
      $event?.preventDefault();
      return;
    }
    this.isEditing$.next(true);
    this.editStarted.emit($event);
    $event?.stopPropagation();
  }

  forceSelect(): void {
    requestAnimationFrame(() => {
      if (!this.textField) {
        return;
      }
      this.textField.nativeElement.focus();
      const selection = document.getSelection();
      const range = document.createRange();
      range.setStart(
        this.textField.nativeElement.childNodes[0],
        this.textField.nativeElement.innerText.length,
      );
      range.collapse(true);
      selection?.removeAllRanges();
      selection?.addRange(range);
      this.onSelect(null);
    });
  }

  isFirefox(): boolean {
    return !!navigator.userAgent.match('firefox|fxios|Firefox|Fxios/i');
  }

  /**
   * in firFox the selectionStart event isn't called for the span element,
   * so we need another way to handle the selectionStart event for FireFox
   * we can use the onClick to the call the onSelect Method
   * @param $event
   */
  onClick($event): void {
    if (!this.disabled) {
      $event.stopPropagation();
    }
    if (this.isFirefox()) {
      this.onSelect($event);
    }
  }

  onMouseEnter() {
    this.hoverStart.emit();
    if (this.textField && this.hoverBackground) {
      this.textField.nativeElement.style.backgroundColor = this.hoverBackground;
    }
  }

  onMouseLeave() {
    this.hoverEnd.emit();
    if (this.textField && this.hoverBackground) {
      this.textField.nativeElement.style.backgroundColor = 'transparent';
    }
  }
}

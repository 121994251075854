import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  map,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { filterNil } from '@ngneat/elf';
import { TranslateService } from '@ngx-translate/core';
import { refinement } from 'ts-adt';
import {
  PauseableRecordingService,
  RECORDING_ERROR,
  RECORDING_STATE,
  RECORD_STATUS,
  RecordingOutput,
  RecordingService,
} from '../models/recording';
import { ISpaceUI, SpaceRepository } from '../state/space.repository';
import { RecordingMode, User } from '../models/user';
import { ISession, Session } from '../models/session';
import { PresenceRepository } from '../state/presence.repository';
import { SpaceLocalRecordingService } from './space-local-recording.service';
import { SpaceCloudRecordingService } from './space-cloud-recording.service';
import { UserService } from './user.service';
import { ProviderStateService } from './provider-state.service';
import { SessionCallParticipantsService } from './session-call-participants.service';
import { PresenceProvider } from './presence-provider';
import { TelemetryService } from './telemetry.service';
import { DeviceAndBrowserDetectorService } from './device-and-browser-detector.service';
import { NavService } from './nav.service';

export enum RECORDING_SERVICES {
  LOCAL,
  CLOUD,
}

@Injectable({
  providedIn: 'root',
})
export class SpaceRecordingService {
  private _didCurrentUserEndRecording$ = new Subject();
  private _recordingOutput$: BehaviorSubject<RecordingOutput> =
    new BehaviorSubject<RecordingOutput>({ _type: 'none' });
  private currentService$: Observable<RecordingService>;
  private recordingEndedReason: string | undefined;
  private recordingInitiator$: Observable<string>;
  private services: Map<RECORDING_SERVICES, RecordingService> = new Map();
  private status$ = new BehaviorSubject<RECORDING_STATE>({ _type: RECORD_STATUS.READY });
  public readonly currentRecorder$ = new BehaviorSubject<RECORDING_SERVICES>(
    RECORDING_SERVICES.LOCAL,
  );
  public readonly didCurrentUserEndRecording$ = this._didCurrentUserEndRecording$.asObservable();
  public readonly didCurrentUserStartRecording$ = new BehaviorSubject<boolean>(false);
  public readonly isAnyoneRecording$: Observable<boolean>;
  public readonly isCurrentUserRecordingInProgress$: Observable<boolean>;
  public readonly isCurrentUserRecordingPauseable$: Observable<boolean>;
  public readonly isCurrentUserRecordingPaused$: Observable<boolean>;
  public readonly isRecordingInitiatorPresent$: Observable<boolean>;
  public shouldShowRecordingNotification$: Observable<boolean>;
  public readonly recordingOutput$ = this._recordingOutput$.asObservable().pipe(
    map((output) => {
      switch (output._type) {
        case 'file':
          output.reason = this.recordingEndedReason;
          this.recordingEndedReason = undefined;
      }
      return output;
    }),
  );
  public readonly isAutomaticCloudRecordingEnabled$ = this.spaceRepo.activeSpace$.pipe(
    map((space) => space?.institution?.settings?.crRecordingMode === RecordingMode.AUTOMATIC),
    distinctUntilChanged(),
  );

  public cloudRecordingActive$ = this.cloudRecordingService.state$.pipe(
    map((recordingState) => recordingState._type === RECORD_STATUS.ACTIVE),
  );

  private setSpaceRecordingVars(recording: boolean): void {
    const cloud_recording_active = recording && !this.isCurrentRecordingLocal();
    const local_recording_active = recording && this.isCurrentRecordingLocal();

    this.telemetry.setSessionVars({
      cloud_recording_active,
      local_recording_active,
      local_recording_user_is_recording: recording && this.didCurrentUserStartRecording$.getValue(),
    });
  }

  public get isAutomaticCloudRecordingEnabled(): boolean {
    return (
      this.spaceRepo.activeSpace?.institution?.settings?.crRecordingMode ===
        RecordingMode.AUTOMATIC ?? false
    );
  }

  public get forceShareScreen(): boolean {
    return (
      this.isAutomaticCloudRecordingEnabled &&
      !!this.spaceRepo.activeSpace?.institution?.settings?.forceScreenShareInAutomaticCr
    );
  }

  public readonly canRecord$: Observable<boolean> = combineLatest([
    this.spaceRepo.activeSpace$.pipe(filterNil()),
    this.userService.user.pipe(
      filterNil(),
      map(({ user }) => user),
    ),
  ]).pipe(map(([s, u]) => this._canRecord(s, u)));

  public readonly didCurrentRecordingTimeOut$: Observable<boolean> = this.status$.pipe(
    filter(refinement(RECORD_STATUS.FINISHED)),
    map((s) => s.err?.type === RECORDING_ERROR.IDLE_TIMEOUT),
    distinctUntilChanged(),
  );

  public readonly isPrivacyCloudRecordingEnabled$ = this.spaceRepo.activeSpace$.pipe(
    map((space) => space?.institution?.settings?.crEnablePrivacyAwareCloudRecording),
    distinctUntilChanged(),
  );

  private readonly _enabledPermissionsOnRecordings = new BehaviorSubject<{
    download: boolean;
    delete: boolean;
  }>({ download: false, delete: false });
  readonly enabledPermissionsOnRecordings$ = this._enabledPermissionsOnRecordings.asObservable();

  public get enabledPermissionsOnRecordings() {
    return this._enabledPermissionsOnRecordings.getValue();
  }

  public get isPrivacyCloudRecordingEnabled(): boolean {
    return (
      this.spaceRepo.activeSpace?.institution?.settings?.crEnablePrivacyAwareCloudRecording ?? false
    );
  }

  constructor(
    private cloudRecordingService: SpaceCloudRecordingService,
    private localRecordingService: SpaceLocalRecordingService,
    private presenceProvider: PresenceProvider,
    private presenceRepository: PresenceRepository,
    private providerStateService: ProviderStateService,
    private sessionCallParticipantsService: SessionCallParticipantsService,
    private spaceRepo: SpaceRepository,
    private translateService: TranslateService,
    private userService: UserService,
    private telemetry: TelemetryService,
    private browserDetectionService: DeviceAndBrowserDetectorService,
    private navService: NavService,
  ) {
    // ---- Private Initializers ----
    this.services.set(RECORDING_SERVICES.LOCAL, this.localRecordingService);
    this.services.set(RECORDING_SERVICES.CLOUD, this.cloudRecordingService);

    this.currentService$ = this.currentRecorder$.pipe(
      map((recordingService) => {
        const service = this.services.get(recordingService);
        if (!service) {
          throw new Error('Expected the service from currentRecorder$ to be in the services map');
        }
        return service;
      }),
    );

    this.recordingInitiator$ = this.status$.pipe(
      filter(refinement(RECORD_STATUS.ACTIVE, RECORD_STATUS.PAUSED)),
      map((x) => x.initiatorParticipantId),
      filterNil(),
    );

    // ---- Public Initializers ----
    this.currentService$
      .pipe(
        switchMap((service) => service.state$),
        distinctUntilChanged(),
        tap(this.handleRecordingStatusChange.bind(this)),
      )
      .subscribe(this.status$);

    combineLatest([this.recordingInitiator$, this.providerStateService.localParticipantId$])
      .pipe(map(([initiator, currentParticipant]) => initiator === currentParticipant))
      .subscribe(this.didCurrentUserStartRecording$);

    this.isRecordingInitiatorPresent$ = combineLatest([
      this.recordingInitiator$,
      this.sessionCallParticipantsService.participants$,
    ]).pipe(map(([initiator, participants]) => initiator in participants));

    this.isCurrentUserRecordingInProgress$ = this.status$.pipe(map(this._isRecordingInProgress));
    this.isCurrentUserRecordingPaused$ = this.status$.pipe(
      map((status) => status._type === RECORD_STATUS.PAUSED),
    );
    this.isCurrentUserRecordingPauseable$ = this.currentService$.pipe(
      map((service) => PauseableRecordingService.isPauseable(service)),
    );

    // ---- Business Logic ----

    // If a cloud recording is enabled we should stop any local recording
    // Note: we also switch the service to be cloud recording so that the
    //       observables will emit data from the correct output
    this.cloudRecordingService.state$.subscribe(async (crStatus) => {
      if (
        crStatus._type === RECORD_STATUS.ACTIVE &&
        this.currentRecorder$.getValue() !== RECORDING_SERVICES.CLOUD
      ) {
        // If there is a recording active for a provider that is not cloud, then we should end it
        if (this.isRecordingInProgress()) {
          this.recordingEndedReason = this.translateService.instant(
            'Your recording was completed because cloud recording was enabled',
          );
          await this.stopRecording();
        }
        // Then we switch the service to be the cloud recording service
        this.switchService(RECORDING_SERVICES.CLOUD);
      }
    });

    this.currentService$.pipe(switchMap((x) => x.output$)).subscribe(this._recordingOutput$);

    // Listen to see if any remote user starts a recording

    this.isAnyoneRecording$ = combineLatest([
      this.spaceRepo.activeSpaceId$.pipe(filterNil()),
      this.spaceRepo.activeSpaceCurrentRoomUid$.pipe(filterNil()),
      this.providerStateService.callConnected$,
    ]).pipe(
      switchMap(([spaceId, roomUid, connected]) => {
        if (connected) {
          return combineLatest([
            this.isCurrentUserRecordingInProgress$,
            this.presenceRepository.getRecordingPresence(spaceId, roomUid),
          ]).pipe(map((x) => x.includes(true)));
        }
        return of(false);
      }),
      distinctUntilChanged(),
      tap((recording) => this.setSpaceRecordingVars(recording)),
    );

    // If a cloud recording times out, automatically restart it
    this.currentRecorder$
      .pipe(
        switchMap((service) =>
          service === RECORDING_SERVICES.CLOUD ? this.didCurrentRecordingTimeOut$ : EMPTY,
        ),
        filter((didRecordingTimeout) => didRecordingTimeout === true),
      )
      .subscribe(() => {
        // @TODO (mfmansoo) - there is a minor issue with this solution. Currently we only allow
        //                    the initiator of the cloud recording to start / stop the cloud recording.
        //                    There is no good way to figure out if the initiator is still in the call
        //                    when we recover the cloud recording.
        console.warn(
          'Cloud recording unexpectedly timed out, automatically restarting the recording',
        );
        if (this.currentRecorder$.getValue() !== RECORDING_SERVICES.CLOUD) {
          this.switchService(RECORDING_SERVICES.CLOUD);
        }
        this.startRecording();
      });

    // If automatic recording is enabled, we should only show the notification the first
    // time the user joins the call. If the user switches rooms we should NOT show the
    // notification again
    this.shouldShowRecordingNotification$ = this.spaceRepo.activeSpaceId$.pipe(
      distinctUntilChanged(), // Restart the observable chain if the space id changes
      switchMap(() => this.isAutomaticCloudRecordingEnabled$),
      switchMap((automaticCloudRecordingEnabled) => {
        if (automaticCloudRecordingEnabled) {
          return this.isAnyoneRecording$.pipe(
            // Emit only the first true value, after that complete the observable chain
            filter((isAnyoneRecording) => isAnyoneRecording === true),
            first(),
            startWith(false),
          );
        } else {
          return this.isAnyoneRecording$;
        }
      }),
    );

    // Handling both cases where either:
    // - Analytics opened from inside the space using the apps selector => spaceRepo.activeSpace should be defined
    // or
    // - Analytics opened from the spaces manager using the three dots menu => navService.sessionAnalyticsSpace should be defined
    combineLatest([
      this.userService.user.pipe(filterNil()),
      this.spaceRepo.activeSpaceId$.pipe(distinctUntilChanged()),
      this.navService.sessionAnalyticsSpace,
    ])
      .pipe(
        map(([userResult, activeSpaceId, sessionAnalyticsSpace]) => {
          let space: ISession | (ISession & ISpaceUI) | undefined;
          if (activeSpaceId) {
            space = this.spaceRepo.getSpace(activeSpaceId);
          } else {
            space = sessionAnalyticsSpace;
          }

          const defaultPermissions = { download: false, delete: false };
          if (!space) {
            return defaultPermissions;
          }

          const user = userResult?.user;
          const userIsHost = this.spaceRepo.isSpaceOwnerOrHost(user, space);
          const userHasAdminPermissions = this.spaceRepo.hasSpaceAdminPermissions(user, space);

          const spaceBelongsToInstitution = !!(space.institutionID ?? space.institution);
          if (!spaceBelongsToInstitution) {
            return {
              download: userIsHost,
              delete: userIsHost,
            };
          }
          const institutionSettings = user.institution?.settings;
          if (!institutionSettings) {
            return defaultPermissions;
          }

          return {
            download: userHasAdminPermissions || institutionSettings.crEnableDownloads,
            delete:
              userHasAdminPermissions || (userIsHost && institutionSettings.crEnableHostDeletion),
          };
        }),
      )
      .subscribe((permissions) => this._enabledPermissionsOnRecordings.next(permissions));
  }

  // ---- Public Methods ----
  public async startRecording(): Promise<void> {
    this.currentService.startRecording();
  }

  public async stopRecording(): Promise<void> {
    await this.currentService.stopRecording();
    this._didCurrentUserEndRecording$.next(true);
  }

  public pauseRecording(): void {
    if (PauseableRecordingService.isPauseable(this.currentService)) {
      this.currentService.pauseRecording();
    } else {
      throw new Error('Current service does not support pausing');
    }
  }

  public resumeRecording(): void {
    if (PauseableRecordingService.isPauseable(this.currentService)) {
      this.currentService.resumeRecording();
    } else {
      throw new Error('Current service does not support resuming');
    }
  }

  public isRecordingInProgress(): boolean {
    return this._isRecordingInProgress(this.status);
  }

  public clearRecordingOutput(): void {
    this._recordingOutput$.next({ _type: 'none' });
  }

  public switchService(service: RECORDING_SERVICES): void {
    if (this.isRecordingInProgress()) {
      throw new Error('Cannot switch recording service while recording is in progress');
    }

    this.currentRecorder$.next(service);
  }

  public isCurrentRecordingLocal(): boolean {
    return this.currentRecorder$.getValue() === RECORDING_SERVICES.LOCAL;
  }

  public cloudRecordingActive(): boolean {
    return this.cloudRecordingService.state$.getValue()._type === RECORD_STATUS.ACTIVE;
  }

  public supportPrivacyCloudRecording(): boolean {
    return (
      this.browserDetectionService.supportCaptureHandleConfig() &&
      this.browserDetectionService.supportElementCapture()
    );
  }

  // ---- Private Methods ---- //
  private _isRecordingInProgress(status: RECORDING_STATE) {
    return status._type === RECORD_STATUS.ACTIVE || status._type === RECORD_STATUS.PAUSED;
  }

  private handleRecordingStatusChange(status: RECORDING_STATE) {
    if (
      this.currentRecorder$.getValue() === RECORDING_SERVICES.LOCAL &&
      this.spaceRepo.activeSpaceId &&
      this.spaceRepo.activeSpaceCurrentRoomUid
    ) {
      switch (status._type) {
        case RECORD_STATUS.ACTIVE:
          this.presenceProvider.setRecordingPresence(
            this.spaceRepo.activeSpaceId,
            this.spaceRepo.activeSpaceCurrentRoomUid,
          );
          break;
        case RECORD_STATUS.FINISHED:
          this.presenceProvider.removeRecordingPresence(
            this.spaceRepo.activeSpaceId,
            this.spaceRepo.activeSpaceCurrentRoomUid,
          );
          break;
        default:
          break;
      }
    }
  }

  private get status() {
    return this.status$.getValue();
  }

  private get currentService() {
    const service = this.services.get(this.currentRecorder$.getValue());
    if (!service) {
      throw new Error(`Expected ${this.currentRecorder$.getValue()} to be in the services map`);
    }
    return service;
  }

  private _canRecord(space: ISession, user: User) {
    const { event } = space;
    if (event) {
      return Boolean(event.host_ids?.includes(user._id));
    }

    return Session.isOwnedByUser(space, user);
  }
}

import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { INFOS } from '../common/utils/notification-constants';
import { FEEDBACK_FEATURE } from '../dialogs/spaces-experience-dialog/spaces-experience-dialog.component';
import { BoardFolder, Frame, FrameItem } from '../models/session';
import {
  NotificationData,
  NotificationDataBuilder,
  NotificationType,
} from '../services/notification-toaster.service';
import { YCanvasItemObject, YObject } from '../sessions/common/y-session';
import { PanelView } from '../sessions/panel/panel.component';
import {
  ButtonToasterElement,
  ButtonToasterElementStyle,
} from '../ui/notification-toaster/button-toaster-element/button-toaster-element.component';
import { ToasterPopupStyle } from '../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import {
  IconBackground,
  IconMessageToasterElement,
} from '../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { modifiedSetTimeout } from '../utilities/ZoneUtils';
import { SessionSharedDataService } from './session-shared-data.service';
import { SessionsSyncService } from './sessions-sync.service';
import { SpaceBoardFoldersService } from './space-board-folders.service';
import { SpacesExperienceDialogService } from './spaces-experience-dialog.service';

export class LessonProcessor {
  private boardFolder: BoardFolder;
  private frameItems: Map<string, YCanvasItemObject[]> = new Map();
  private fabricObjects: Map<string, YObject[]> = new Map();
  private frames: Array<Frame> = [];
  private currentFrameUid = '';
  private currentTop = 0;
  private currentLeft = 0;
  private options?: {
    paddingTop?: number;
    paddingLeft?: number;
  };

  constructor(
    boardFolder: BoardFolder,
    private sessionsSyncService: SessionsSyncService,
    options?: {
      paddingTop?: number;
      paddingLeft?: number;
    },
  ) {
    this.boardFolder = boardFolder;
    this.options = options;
    this.currentTop = options?.paddingTop ?? 0;
    this.currentLeft = options?.paddingLeft ?? 0;
  }

  setCurrentBoardFolder(boardFolder: BoardFolder): void {
    this.boardFolder = boardFolder;
  }

  setCurrentFrame(frame: Frame): void {
    this.currentFrameUid = frame.uid;
    this.frames.push(frame);
    this.resetCoords();
  }

  addFrameItem(frameItem: FrameItem): void {
    const frameUid = this.currentFrameUid;
    if (!this.frameItems.has(frameUid)) {
      this.frameItems.set(frameUid, []);
    }
    const yCanvasItem = this.sessionsSyncService.convertFrameItemToYCanvasItem(frameItem);
    this.frameItems.get(frameUid)?.push(yCanvasItem);
  }

  addFabricObject(fabricObject: fabric.Object): void {
    const frameUid = this.currentFrameUid;
    if (!this.fabricObjects.has(frameUid)) {
      this.fabricObjects.set(frameUid, []);
    }
    const objToAdd = this.sessionsSyncService.convertFabricObjectToYObject(fabricObject);
    this.fabricObjects.get(frameUid)?.push(objToAdd);
  }

  getCurrentTop(): number {
    return this.currentTop;
  }

  addToCurrentTop(top: number): void {
    this.currentTop += top;
  }

  getCurrentLeft(): number {
    return this.currentLeft;
  }

  getBoardFolder(): BoardFolder {
    return this.boardFolder;
  }

  getFrameItems(): Map<string, YCanvasItemObject[]> {
    return this.frameItems;
  }

  getFabricObjects(): Map<string, YObject[]> {
    return this.fabricObjects;
  }

  getFrames(): Array<Frame> {
    return this.frames;
  }

  resetCoords(): void {
    this.currentTop = this.options?.paddingTop ?? 0;
    this.currentLeft = this.options?.paddingLeft ?? 0;
  }
}

@Injectable({
  providedIn: 'root',
})
export class LessonGeneratorService {
  lessonProcessor?: LessonProcessor;
  lessonTitle?: string;
  constructor(
    private translate: TranslateService,
    private spacesExperienceDialogService: SpacesExperienceDialogService,
    private sharedDataService: SessionSharedDataService,
    private spaceBoardFoldersService: SpaceBoardFoldersService,
    private sessionsSyncService: SessionsSyncService,
  ) {}

  generateLesson(boardFolder: BoardFolder): LessonProcessor {
    if (this.lessonProcessor) {
      throw new Error('Lesson Generation In Progress');
    }
    // Padding added so that it is visible in the center viewport of user
    const paddingLeft = (this.sharedDataService.fabricCanvas?.getWidth() ?? 0) * 0.05;
    const paddingTop = (this.sharedDataService.fabricCanvas?.getHeight() ?? 0) * 0.1;
    this.lessonProcessor = new LessonProcessor(boardFolder, this.sessionsSyncService, {
      paddingLeft,
      paddingTop,
    });
    this.lessonTitle = boardFolder.name;
    return this.lessonProcessor;
  }

  generateInProgressNotification(): NotificationData {
    const title = new IconMessageToasterElement(
      undefined,
      this.translate.instant('Generating your lesson...'),
      undefined,
      undefined,
      true,
      IconBackground.INFO,
      true,
      false,
      undefined,
    );
    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translate.instant('This may take a minute.'),
      undefined,
      undefined,
      false,
      undefined,
      true,
      undefined,
      undefined,
      'margin: 0',
    );
    const notification = new NotificationDataBuilder(this.getInProgressNotificationCode())
      .style(ToasterPopupStyle.WARN)
      .type(NotificationType.WARNING)
      .topElements([title])
      .middleElements([messageElement])
      .showProgressBar(false)
      .dismissable(false)
      .version2Notification(true)
      .width(304)
      .height('87px')
      .toastClass('lesson-generation-toast')
      .build();
    return notification;
  }

  getInProgressNotificationCode(): string {
    return INFOS.LESSON_GENERATION_IN_PROGRESS;
  }

  getLessonReadyNotificationCode(): string {
    return INFOS.LESSON_READY;
  }

  getLessonReadyNotification(): NotificationData {
    const firstFrameUid = this.lessonProcessor?.getFrames()[0].uid;
    const boardFolderUid = this.lessonProcessor?.getBoardFolder().uid;
    this.lessonProcessor = undefined;
    const approveButton = new ButtonToasterElement(
      [{ icon: 'chevron_right', size: 18 }, this.translate.instant('View')],
      {
        handler: () => {
          if (!firstFrameUid || !boardFolderUid) {
            return;
          }
          this.sharedDataService.changeLeftPanelView.next(PanelView.tabs);
          this.spaceBoardFoldersService.setActiveSpaceSelectedBoardFolder(boardFolderUid);
          this.sharedDataService.selectFrameSubject.next(firstFrameUid);
          modifiedSetTimeout(() => {
            this.spacesExperienceDialogService.triggerDialog(
              undefined,
              FEEDBACK_FEATURE.LESSON_GENERATOR,
            );
          }, 30_000);
        },
        close: true,
      },
      ButtonToasterElementStyle.RAISED_ACTIVE,
    );

    const rejectButton = new ButtonToasterElement(
      [{ icon: 'close', size: 18 }, this.translate.instant('Not Now')],
      {
        handler: () => false,
        close: true,
      },
      ButtonToasterElementStyle.SECONDARY,
    );

    const title = new IconMessageToasterElement(
      { icon: 'check', size: 18 },
      this.translate.instant('Your lesson is ready!'),
      undefined,
      undefined,
      false,
      IconBackground.SUCCESS,
      true,
      false,
      undefined,
      'margin: 0',
    );
    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translate.instant(
        `We’ve generated your lesson${
          this.lessonTitle ? ` on ${this.lessonTitle}` : ''
        }! Click to view.`,
      ),
      undefined,
      undefined,
      false,
      undefined,
      true,
      false,
      undefined,
      'margin: 0',
    );

    const incomingAccessRequestsNotificationData = new NotificationDataBuilder(
      this.getLessonReadyNotificationCode(),
    )
      .style(ToasterPopupStyle.WARN)
      .type(NotificationType.WARNING)
      .width(304)
      .height('152px')
      .topElements([title])
      .middleElements([messageElement])
      .bottomElements([rejectButton, approveButton])
      .showProgressBar(false)
      .dismissable(true)
      .priority(600)
      .version2Notification(true)
      .toastClass('lesson-generated-toast')
      .build();
    this.lessonTitle = undefined;
    return incomingAccessRequestsNotificationData;
  }

  lessonGenerationInProgress(): boolean {
    return !!this.lessonProcessor;
  }
}

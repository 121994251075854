import { ActivatedRoute } from '@angular/router';
import { AuthError, AuthErrorCodes } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User as IFBUser } from '@angular/fire/auth';

import { TelemetryService } from 'src/app/services/telemetry.service';
import { redirectToAppScript } from 'src/app/common/utils/common-util';
import { EMAIL_CODE_ACTION } from 'src/app/models/user';
import { IconMessageToasterElement } from 'src/app/ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from 'src/app/services/notification-toaster.service';
import { ERRORS } from 'src/app/common/utils/notification-constants';
import { ToasterPopupStyle } from 'src/app/ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { environment } from '../../../environments/environment';
import { UserService } from '../../services/user.service';
import { AuthService, LOGIN_TYPE } from '../../services/auth.service';
import { PROVIDERS_ALL } from '../account.constants';

export enum LoginState {
  START = 'start',
  EMAIL = 'email',
  SSO_PAGE = 'sso_page',
  OIDC_PAGE = 'oidc_page',
  EMAIL_SIGNUP = 'email_signup',
  EMAIL_LOGIN = 'email_login',
  EMAIL_PASSWORD_LOGIN = 'email_password_login',
  EMAIL_CODE = 'email_code',
  PASSWORD_RESET = 'password_reset',
  CUSTOM_TOKEN_PROVIDER = 'custom_token_provider',
  CONTINUE_AS_GUEST = 'continue_as_guest',
}

export interface LoginInfo {
  loginState: LoginState;
  email?: string;
  userProviders?: string[];
  action?: EMAIL_CODE_ACTION;
  oobCode?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AccountEntryService {
  loginInfoChanges = new BehaviorSubject<LoginInfo>({ loginState: LoginState.START });
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private telemetry: TelemetryService,
    private translate: TranslateService,
    private notificationsService: NotificationToasterService,
  ) {}

  isAddOnLogin(): boolean {
    return this.activatedRoute.snapshot.queryParams['redirect'] === 'addOnLoginMode';
  }

  async tryGoogleLogin(): Promise<void> {
    await this.tryLoginWrapper(this.authService.doGoogleLogin.bind(this.authService), 'google');
  }

  async tryMicrosoftLogin(): Promise<void> {
    await this.tryLoginWrapper(
      this.authService.doMicrosoftLogin.bind(this.authService),
      'Microsoft',
    );
  }

  async tryFacebookLogin(): Promise<void> {
    await this.tryLoginWrapper(this.authService.doFacebookLogin.bind(this.authService), 'Facebook');
  }

  async tryEmailCodeLogin(): Promise<void> {
    const loginInfo = this.loginInfoChanges.value;
    if (loginInfo.email) {
      window.localStorage.setItem('pencilMagicEmail', loginInfo.email);
      try {
        this.userService.appLoading.next(true);
        this.loginInfoChanges.next({
          loginState: LoginState.EMAIL_CODE,
          email: loginInfo.email,
          action: EMAIL_CODE_ACTION.SIGN_IN,
        });
      } catch (err) {
        if (err.code === AuthErrorCodes.USER_DELETED) {
          return await this.recover(err, 'EmailLink');
        }
        await this.recover(err, 'EmailLink');
      } finally {
        this.userService.appLoading.next(false);
      }
    }
  }

  async tryLoginWrapper(
    loginFunc: (param: { type: LOGIN_TYPE }) => Promise<void>,
    idpService: string,
  ): Promise<void> {
    this.userService.appLoading.next(true);
    loginFunc({ type: LOGIN_TYPE.SignIn })
      .then(() => {
        if (this.isAddOnLogin()) {
          this.telemetry.event('[Calendar Add-On] url: ', { script: environment.appScriptURL });
          redirectToAppScript(idpService, null, null, null);
        }
      })
      .catch((err) => {
        this.recover(err, idpService);
      })
      .finally(() => {
        this.userService.appLoading.next(false);
      });
  }

  /**
   * If normal login failed, then We should reset all login form fields,
   * and see if that email is associated to another existing account. If so
   * then we should ask the user if he wants to link to the existing account.
   */
  async recover(err: AuthError, loginMethod: string): Promise<void> {
    // Check if the email is associated to another existing account.
    if (err.code === AuthErrorCodes.NEED_CONFIRMATION) {
      if (loginMethod === 'Facebook') {
        await this.handleFacebookLoginError(err);
      }
    }
    // Stop loading.
    this.userService.appLoading.next(false);
  }

  async handleFacebookLoginError(err: AuthError): Promise<void> {
    const email = err.customData.email;
    const providers = email ? await this.authService.getProviders(email) : null;

    const validPasswordProvider =
      email && providers?.length && providers?.includes(PROVIDERS_ALL.PASSWORD);

    const message = {
      title: 'Error with login',
      description: validPasswordProvider
        ? 'We could not log you in with Facebook because you already have an account with us. Enter your \
    password below, or try another login option.'
        : "We're sorry, but we could not log you in with Facebook. Please try another login option.",
    };

    this.showErrorToast(ERRORS.UNSUPPORTED_FB_ACCOUNT, message);

    this.loginInfoChanges.next({
      loginState: validPasswordProvider ? LoginState.EMAIL_PASSWORD_LOGIN : LoginState.EMAIL_LOGIN,
      email,
      userProviders: validPasswordProvider ? providers! : Object.values(PROVIDERS_ALL),
    });

    this.telemetry.event('Facebook Existing Account Error');
  }

  showErrorToast(
    errorCode: ERRORS,
    message: { title: string; description: string },
    timeout?: number,
  ): void {
    if (!message) {
      return;
    }

    const titleElement = new IconMessageToasterElement(
      undefined,
      this.translate.instant(message.title),
    );
    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translate.instant(message.description),
    );

    const notificationError = new NotificationDataBuilder(errorCode)
      .style(ToasterPopupStyle.ERROR)
      .type(NotificationType.ERROR)
      .timeOut(timeout ?? 10)
      .topElements([titleElement])
      .middleElements([messageElement])
      .dismissable(true)
      .build();
    this.notificationsService.showNotification(notificationError);
  }

  isOnlyPasswordProviderAndEmailUnverified(fbUser?: IFBUser | null): boolean {
    if (!fbUser) {
      return false;
    }

    const providers = fbUser?.providerData;
    const onlyPasswordProvider =
      providers?.[0]?.providerId === 'password' && providers?.length === 1;
    return onlyPasswordProvider && !fbUser?.emailVerified;
  }

  goToVerificationPage(email?: string | null) {
    this.loginInfoChanges.next({
      loginState: LoginState.EMAIL_CODE,
      email: email || '',
      action: EMAIL_CODE_ACTION.VERIFY_EMAIL,
    });
  }
}

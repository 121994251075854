import { Pipe, PipeTransform } from '@angular/core';

import { User } from '../models/user';

@Pipe({
  name: 'allSuspended',
  pure: false
})
export class AllSuspendedPipe implements PipeTransform {

  transform(users: User[]): boolean {
    return users.every(user => !!user.revoked);
  }
}

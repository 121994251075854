interface Context {
  // Id of the question.
  question?: string;
  // Id of the note.
  note?: string;
  // Id of the worksheet.
  worksheet?: string;
  // If of the course.
  course?: string;
  // Id of the institution.
  institution?: string;
  // Id of the session.
  session?: string;
  // If DM chat, then the users part of this.
  users?: string[];
  // Id of group.
  group?: string;
  // Id of canvas item (in case of comments)
  itemId?: string;
  // (deprecated -- frame id is unique) Id of the room (in case of comments)
  breakoutRoomId?: string;
  // board Id (in case of comments)
  boardId?: string;
  // app id (ex: chat with gpt)
  appId?: string;
}

export type SpaceCommentContext = Required<Pick<Context, 'session' | 'itemId' | 'boardId'>>;

// Set fields to be in sorted order and sort any array values.
const formatContext = (context: Context): Context =>
  Object.keys(context)
    .sort()
    .reduce((acc: Partial<Context>, key: string) => {
      if (Array.isArray((context as { [key: string]: Context })[key])) {
        ((acc as { [key: string]: Partial<Context> })[key] as string[]) = (
          (context as { [key: string]: Context })[key] as string[]
        ).sort();
      } else if (key !== 'institution') {
        /* For full backwards compatibility, institution was previously ignored
         * when ordering/encoding context. TODO: [CPR-33] Do we want to do this?
         */
        (acc as { [key: string]: Partial<Context> })[key] = (context as { [key: string]: Context })[
          key
        ];
      }

      return acc;
    }, {});

const encodeContext = (context: Context): string => btoa(JSON.stringify(formatContext(context)));

const setContextUsers = (context: Context, users: string[]): Context => {
  context.users = users.sort();
  return context;
};

export { Context, encodeContext, setContextUsers };

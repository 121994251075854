<div class="input-wrapper">
  <div class="d-flex" *ngFor="let i of [0, 1, 2, 3, 4, 5]">
    <input
      [ngClass]="{ error: error, disabled: disabled }"
      [class.left-input]="i === 0 || i === 3"
      [class.center-input]="i === 1 || i === 4"
      [class.right-input]="i === 2 || i === 5"
      id="{{ 'digit-' + i }}"
      type="number"
      min="0"
      max="9"
      [disabled]="disabled"
      class="input-field"
      (beforeinput)="handleInputEvent($event, i)"
      (keydown)="handleKeydownEvent($event, i)"
      (paste)="handlePasteEvent($event, i)"
    />
    <div *ngIf="i === 2" [class.error]="error" class="center-divider"></div>
  </div>
</div>

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IAppModalInformation } from 'src/app/sessions/session/iframe/additional-apps.utils';
import { IFrameUtils, UrlValidationError } from 'src/app/utilities/iframe.utils';

interface IFrameDialogData {
  url: string;
}

@UntilDestroy()
@Component({
  selector: 'app-add-iframe',
  templateUrl: './add-iframe.component.html',
  styleUrls: ['./add-iframe.component.scss'],
})
export class AddIframeComponent {
  url = '';
  modifiedUrl = '';
  isValidating = false;
  UrlValidationError = UrlValidationError;
  error: UrlValidationError = UrlValidationError.NoError;

  constructor(
    private dialogRef: MatDialogRef<AddIframeComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      modalConfig: IAppModalInformation;
    },
    private iframeUtils: IFrameUtils,
  ) {}

  async addIFrame(submit = false): Promise<void> {
    this.isValidating = true;

    if (!submit) {
      this.dialogRef.close(null);
      return;
    }

    const urlIsValid = await this.validateUrl();
    if (submit && !urlIsValid) {
      this.isValidating = false;
    } else {
      const data: IFrameDialogData = { url: this.modifiedUrl };
      this.isValidating = false;
      this.dialogRef.close(data);
    }
  }

  async validateUrl(): Promise<boolean> {
    const validateData = await this.iframeUtils.validateUrl(
      this.url,
      this.data.modalConfig.stringIncludesValidation,
    );

    this.error = validateData.error;
    this.modifiedUrl = validateData.modifiedUrl;

    return this.error === UrlValidationError.NoError;
  }
}

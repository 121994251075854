import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortName',
})
export class ShortNamePipe implements PipeTransform {
  transform(name: string): string {
    if (!name?.trim()) {
      return '';
    }

    // short name with alphabetic chars only ( A - Z ), (max 2 )
    let shortNameWithCharsOnly = '';
    // the short name with the first letters of each name ( maximum 2 )
    let shortName = '';
    // reduce spaces to single space and trim the name
    let nameArray = name
      .replace(/\s{2,}/g, ' ')
      .trim()
      .split(' ');
    // name array will one hold the first two names
    nameArray = nameArray.slice(0, 2);
    nameArray.forEach((n) => {
      const char = n.substring(0, 1).toUpperCase();
      // allow only A-Z chars
      if (/^[A-Z]$/.test(char)) {
        shortNameWithCharsOnly += char;
      }

      shortName += char;
    });

    return shortNameWithCharsOnly ? shortNameWithCharsOnly : shortName;
  }
}

export enum NetworkQuality {
  UNKNOWN,
  WEAK,
  MEDIUM,
  STRONG,
}

export enum ConnectionStatus {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  UNKNOWN = 'unknown',
  UNSTABLE = 'unstable' // TODO implement
}

import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { MarkdownService } from 'ngx-markdown';
import { DomSanitizer } from '@angular/platform-browser';
import { getHtmlFromFormattedText, renderUserMentions } from '../common/utils/common-util';

/* Rendered links should open in a new tab, and should not have escaped
 * underscores.
 */
const updateLinks = (html: string): string => {
  const document = new DOMParser().parseFromString(html, 'text/html');
  const links = document.getElementsByTagName('a');

  for (let ii = 0; ii < links.length; ii++) {
    const link = links[ii];
    link.target = '_blank';
    link.rel = 'noopener noreferrer';

    if (link.textContent !== null) {
      link.href = link.href.replace(/%5C_/g, '_');
      link.textContent = link.textContent.replace(/\\_/g, '_');
    }
  }

  return document.body.innerHTML;
};

@Pipe({
  name: 'markdownHtml',
})
export class MarkdownHtmlPipe implements PipeTransform {
  constructor(private markdownService: MarkdownService, private domSanitizer: DomSanitizer) {}

  transform(markdown: string, compressedRendering = false, renderMention = false): any {
    const arr = updateLinks(
      this.markdownService.compile(
        this.escapeSpecialCharacters(getHtmlFromFormattedText(markdown)),
      ),
    ).split(/<\/*p\s*\/?>/);

    if (compressedRendering) {
      return getHtmlFromFormattedText(arr.join(' '));
    } else {
      let str: string;
      if (arr[0] === '') {
        str = arr[0] + arr.slice(1, -1).join('<p>') + arr.slice(-1);
      } else {
        str = arr[0] + arr.slice(1, -1).join('<p>');
      }

      str = str.replace(/<p>\n/g, '<p class="new-line">');
      if (renderMention) {
        str = renderUserMentions(str);
      }
      return this.domSanitizer.sanitize(SecurityContext.HTML, getHtmlFromFormattedText(str));
    }
  }

  escapeSpecialCharacters(str: string) {
    /** Replace the period (.) if the string matches the pattern:
     * starts with a number, contains only numbers and a period, and ends with a period or .\n\n */
    str = str.replace(/^(\d+)\.\s*\n*$/, '$1\\.');

    // Escape underscores because string with multiple uderscores are not rendered properly by markdownService
    str = str.replace(/_/g, '\\_');
    return str;
  }
}

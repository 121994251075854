<div id="user-settings-ai-assist" class="settings-panel" [class.mobile]="isMobile">
  <div class="head">
    <span class="material-icons go-back" (click)="goBack.emit()"> arrow_back </span>
    <div class="description">
      <div class="title">{{ PANELS.AI_ASSIST.name }}</div>
      <div class="subtitle">{{ PANELS.AI_ASSIST.description }}</div>
    </div>
    <span class="material-icons close" (click)="goBack.emit()"> close </span>
  </div>
  <div class="sub-head" *ngIf="isMobile">
    {{ PANELS.AI_ASSIST.description }}
  </div>
  <div class="panel-body">
    <form [formGroup]="form" (submit)="submitChanges()" class="settings-panel-form">
      <div class="body">
        <div class="toggle-group">
          <app-user-settings-permissions-toggle
            *ngIf="inactivityNotificationsEnabled"
            [options]="{
              setting: 'inactivityNotifications',
              titleText: ('Notify me when participants get distracted in a Space' | translate),
              disabledOptions: {
                disabled: !inactivityNotificationsPaid,
                reason: 'This feature is only available for Pencil Spaces Pro users' | translate
              },
              upgradeOptions: {
                requiredSubscriptions:
                  foregroundInactivityNotificationsService.requiredSubscriptionTypes,
                evaluationType: UpgradePlanEvaluationType.SELF
              },
              activeText:
                ('Send hosts notifications if a participant does not have the browser in their main tab and / or are inactive on the call'
                | translate),
              inactiveText:
                ('Send hosts notifications if a participant does not have the browser in their main tab and / or are inactive on the call'
                | translate)
            }"
          >
          </app-user-settings-permissions-toggle>
          <app-user-settings-permissions-toggle
            *ngIf="cloudRecordingEnabled && (canUseCloudRecording$ | async) === true"
            [formGroup]="form"
            [options]="{
              setting: 'crEnableAISummarization',
              titleText: 'Automatically summarize cloud recordings' | translate,
              activeText: 'Disable AI-generated summaries of cloud-recorded calls.' | translate,
              inactiveText:
                'An AI-generated summary will be generated for all cloud-recorded calls. Summaries will be sent to Space hosts.'
                | translate,
              disableOptions: {
                disabled: (canUseAISummarization$ | async) === false,
                reason:
                  'This feature is not available for your institution. Please contact Pencil Spaces Support'
                  | translate
              }
            }"
          ></app-user-settings-permissions-toggle>
          <app-user-settings-permissions-toggle
            *ngIf="liveCallAlertsEnabled"
            [formGroup]="form"
            [options]="{
              setting: 'liveCallAlerts',
              titleText:
                (form.value.settings['liveCallAlerts'] ? 'Disable' : 'Enable') + ' live call alerts'
                | translate,
              activeText:
                'Disable call alerts to hosts about their speaking time / student engagement'
                | translate,
              inactiveText:
                'Send hosts alerts during on calls about their speaking time / student engagement'
                | translate,
              disableOptions: {
                disabled: (canUseLiveCallAlerts$ | async) === false,
                reason: 'Live call alerts are not enabled for your institution' | translate
              }
            }"
          ></app-user-settings-permissions-toggle>
        </div>
      </div>

      <div class="foot" [class.show]="formChanged$ | async">
        <app-pencil-button
          class="pencil-button btn-ok"
          [buttonType]="'submit'"
          [disabled]="form.invalid"
        >
          <span *ngIf="!isLoading" class="material-icons">check</span>
          <mat-spinner *ngIf="isLoading" [diameter]="16" color="#FFF"></mat-spinner>
          <span>{{ 'Update' | translate }}</span>
        </app-pencil-button>
        <app-pencil-button
          [buttonType]="'button'"
          class="pencil-button btn-cancel"
          (clickEvent)="initForm()"
          type="button"
          icon="close"
          [iconConfig]="{ type: 'material-icon' }"
          [label]="'Cancel' | translate"
        >
        </app-pencil-button>
      </div>
    </form>
  </div>
</div>

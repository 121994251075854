import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IActiveFolder } from '../../content/files/files.component';


@Component({
  selector: 'app-create-folder-dialog',
  templateUrl: './create-folder-dialog.component.html',
  styleUrls: ['./create-folder-dialog.component.scss']
})
export class CreateFolderDialogComponent implements OnInit {

  folder_name = ''; // default name of a folder
  folder_exist = false;
  currentSubdirectories: string[];

  constructor(
    public dialogRef: MatDialogRef<CreateFolderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {parent: IActiveFolder, title?: string}) {
      // Get the name of all sub-folders in the current folder.
      this.currentSubdirectories = this.data.parent ? this.data.parent.subdirectories.map(item => item.name) : [];
    }

  ngOnInit(): void {
    // if it's taken before, then let's try to add some numbers.
    while (this.currentSubdirectories.includes(this.folder_name)) {
      this.folder_name.concat((Math.floor(Math.random() * (11))).toString());
    }
  }

  /**
    Get Called whenever user change the folder name in the dialog window.
   */
  checkFolderName() {
    this.folder_exist = this.currentSubdirectories.includes(this.folder_name);
  }

  /**
    Called when user clicks on 'Create' button
   */
  createFolder() {
    this.dialogRef.close(this.folder_name);
  }

  /**
    Cancelling Creation of a new folder.
   */
  closeDialog() {
    this.dialogRef.close(false);
  }

}

import { ApplicationRef, Component, HostBinding } from '@angular/core';
import { ToastNoAnimation, ToastPackage, ToastRef, ToastrService } from 'ngx-toastr';
import { ToasterElement, ToasterElementType } from '../toaster-element/toaster-element.component';

export enum ToasterPopupStyle {
  ERROR,
  WARN,
  INFO,
  SUCCESS,
}

export interface ToasterPopup {
  style: ToasterPopupStyle;

  // top elements of the error, elements will be displayed in a row
  topElements?: ToasterElement[];

  // middle elements of the error notification, elements will be displayed in a column
  middleElements?: ToasterElement[];

  // bottom elements of the error notification, elements will be displayed in a row
  bottomElements?: ToasterElement[];

  // after this time elapses close the component
  // this is specified in milliseconds
  timeout?: number;

  // if the user can dismiss the notification or not.
  dismissible?: boolean;

  // modal width
  width?: number;

  // modal height
  height?: string;
}

@Component({
  selector: 'app-custom-notification-toastr',
  templateUrl: './custom-notification-toastr.component.html',
  styleUrls: ['./custom-notification-toastr.component.scss'],
})
export class CustomNotificationToastrComponent extends ToastNoAnimation {
  @HostBinding('class.notification-popup-v2-host-container') applyNotificationV2Styles: boolean =
    this.options.payload.version2Notification;
  public NotificationElementType = ToasterElementType;

  toastRef: ToastRef<any>;
  isMenuOpened = false;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    protected appRef: ApplicationRef,
  ) {
    super(toastrService, toastPackage, appRef);
    this.toastRef = toastPackage.toastRef;
  }

  onClose() {
    this.toastRef.manualClose();
  }
}

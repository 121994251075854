import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, take } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { TelemetryService } from 'src/app/services/telemetry.service';
import {
  PostPayment,
  CheckoutInfo,
  HubspotContactProperties,
  PortalInfo,
  SubscriptionInfo,
  ChargebeeSubscriptionStatus,
} from '../models/payment';
import { IconMessageToasterElement } from '../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { ERRORS, SUCCESSES } from '../common/utils/notification-constants';
import { ToasterPopupStyle } from '../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { modifiedSetInterval, modifiedSetTimeout } from '../utilities/ZoneUtils';
import { URLService } from './dynamic-url.service';
import { SettingsService } from './settings.service';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from './notification-toaster.service';
export enum PopUpPageType {
  CreateSubscribtion = 'CreateSubscribtion',
  SwitchSubscription = 'SwitchSubscription',
  CancelSubscription = 'CancelSubscription',
}

interface PayWallNotificationConfigurtion {
  icon: string;
  notificationTopElement: string;
  notificationType: NotificationType;
  toasterPopupStyle: ToasterPopupStyle;
  notificationMiddleElement: string;
  telemetryEventData?: string;
}

const notificationConfigurationMap: Record<
  PopUpPageType | 'Failure',
  PayWallNotificationConfigurtion
> = {
  [PopUpPageType.CreateSubscribtion]: {
    icon: 'check',
    notificationTopElement: "You're all set!",
    notificationType: NotificationType.SUCCESS,
    toasterPopupStyle: ToasterPopupStyle.SUCCESS,
    notificationMiddleElement:
      'Thank you for upgrading to Pro! This page will auto-refresh shortly as we get everything ready for you.',
    telemetryEventData: 'user_did_upgrade',
  },
  [PopUpPageType.SwitchSubscription]: {
    icon: 'check',
    notificationTopElement: "We've changed your plan",
    notificationType: NotificationType.SUCCESS,
    toasterPopupStyle: ToasterPopupStyle.SUCCESS,
    notificationMiddleElement: 'This page will refresh momentarily to reflect your new plan',
    telemetryEventData: 'user_did_switch',
  },
  [PopUpPageType.CancelSubscription]: {
    icon: 'arrow_downward',
    notificationTopElement: 'Plan downgraded',
    notificationType: NotificationType.INFO,
    toasterPopupStyle: ToasterPopupStyle.INFO,
    notificationMiddleElement:
      'We’re sorry to see you go! This page will refresh shortly with your new plan details',
    telemetryEventData: 'user_did_cancel',
  },
  ['Failure']: {
    icon: 'warning',
    notificationTopElement: 'Something went wrong',
    notificationType: NotificationType.ERROR,
    toasterPopupStyle: ToasterPopupStyle.ERROR,
    notificationMiddleElement: "Sorry, but we weren't able to adjust your plan. Please try again.",
  },
};

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  isLoggingEnabled = true;
  popoutWindow: Window | null = null;
  intervalId?: NodeJS.Timer;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(
    private http: HttpClient,
    private urlService: URLService,
    private settingsService: SettingsService,
    private translateService: TranslateService,
    private notificationToasterService: NotificationToasterService,
    private telemetry: TelemetryService,
  ) {}
  /**
   * This function is used to open a new browser window.
   * The window is initially opened with no URL (blank) during a direct user interaction event to avoid being blocked by popup blockers.
   * The URL of this window can be later set to load the desired content.
   * If a pop-out window is already open, it closes the existing window before opening a new one.
   *
   * @param windowId - A string representing the window name.
   * @param width - The width of the window in pixels.
   * @param height - The height of the window in pixels.
   */
  openBufferWindow(windowId: string, width: number, height: number): void {
    this.closeWindowAndClearInterval();
    const left = (screen.width - width) / 2;
    const top = (screen.height - height) / 2;
    this.popoutWindow = window.open(
      '',
      `${windowId}`,
      `resizable=yes, scrollbars=no, toolbar=no, menubar=no, width=${width}, height=${height}, top=${top}, left=${left}`,
    );
    if (!this.popoutWindow) {
      this.logMessages('Issue: the window was blocked and could not be opened.');
    }
  }

  handleError(error: any): void {
    this.showToastError();
    this.logMessages(error.message);
  }

  async handleCheckout(checkoutType: PopUpPageType, planID: string): Promise<void> {
    try {
      const checkout: CheckoutInfo = await firstValueFrom(
        this.checkoutCallHandler(checkoutType, planID),
      );

      if (!checkout) {
        throw new Error('Checkout not available');
      }

      if (!this.popoutWindow) {
        this.showPopUpBlockedError();
        return;
      }

      this.popoutWindow.location.href = checkout.checkout_url;
      this.checkoutStateHandler(checkout, planID, checkoutType);
    } catch (error) {
      this.closeWindowAndClearInterval();
      this.handleError(error);
    }
  }

  logMessages(message: string) {
    if (this.isLoggingEnabled) {
      console.log(`Paywall Popup Log: ${message}`);
    }
  }

  checkoutCallHandler(checkoutType: PopUpPageType, planID: string): Observable<CheckoutInfo> {
    if (checkoutType === PopUpPageType.CreateSubscribtion) {
      return this.createCheckout(planID);
    } else {
      return this.switchPlanCheckout(planID);
    }
  }

  checkoutStateHandler(checkout: CheckoutInfo, planID: string, checkoutType: PopUpPageType): void {
    const checkPaymentStatus = () => {
      this.logMessages('popup listener running');
      if (this.popoutWindow?.closed) {
        this.logMessages('popup closed');
        this.closeWindowAndClearInterval();
        return;
      }
      try {
        if (this.popoutWindow?.location.search.includes('state=succeeded')) {
          this.logMessages('popup succeed state caught');
          this.closeWindowAndClearInterval();
          this.checkoutSucceed(checkout.hosted_page_id)
            .pipe(take(1))
            .subscribe((succeed: string) => {
              if (succeed) {
                this.showNotification(true, checkoutType, planID);
              } else {
                this.showNotification(false, checkoutType, planID);
              }
            });
        } else if (this.popoutWindow?.location.search.includes('state=cancelled')) {
          this.closeWindowAndClearInterval();
          this.settingsService.showToast({
            title: this.translateService.instant('Payment canceled'),
            description: this.translateService.instant('Checkout flow has been canceled.'),
          });
        }
      } catch (error) {
        // While we continue search for succeed or cancelled we will getting error that we could not
      }
    };
    this.intervalId = modifiedSetInterval(checkPaymentStatus, 1000);
  }

  getBillingDetails(id: string): Observable<PostPayment> {
    return this.http.get<PostPayment>(
      `${this.urlService.getDynamicUrl()}/tutor/billing/billingDetails/${id}`,
    );
  }

  createPortalSession(id: string): Observable<PortalInfo> {
    return this.http.get<PortalInfo>(
      `${this.urlService.getDynamicUrl()}/tutor/billing/createPortalSession/${id}`,
    );
  }

  createCheckout(planID: string): Observable<CheckoutInfo> {
    return this.http.get<CheckoutInfo>(
      `${this.urlService.getDynamicUrl()}/tutor/billing/createCheckout/${planID}`,
    );
  }

  switchPlanCheckout(planID: string): Observable<CheckoutInfo> {
    return this.http.get<CheckoutInfo>(
      `${this.urlService.getDynamicUrl()}/tutor/billing/switchPlanCheckout/${planID}`,
    );
  }

  checkoutSucceed(hosted_page_id: string): Observable<string> {
    return this.http.get<string>(
      `${this.urlService.getDynamicUrl()}/tutor/billing/checkoutSucceed/${hosted_page_id}`,
    );
  }

  showNotification(isSuccess: boolean, popupPageType: PopUpPageType, planID?: string) {
    const notificationConfiguration: PayWallNotificationConfigurtion =
      this.getNotificationConfiguration(isSuccess, popupPageType);
    const topElement = new IconMessageToasterElement(
      { icon: notificationConfiguration.icon, size: 16 },
      this.translateService.instant(notificationConfiguration.notificationTopElement),
    );

    const notificationDataBuilder = new NotificationDataBuilder(
      isSuccess ? SUCCESSES.ACTION_SUCCESSFUL : ERRORS.ACTION_FAILED,
    )
      .type(notificationConfiguration.notificationType)
      .style(notificationConfiguration.toasterPopupStyle)
      .priority(380)
      .showProgressBar(true)
      .timeOut(5)
      .topElements([topElement]);

    const middleElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(notificationConfiguration.notificationMiddleElement),
    );

    const notificationData = notificationDataBuilder.middleElements([middleElement]).build();
    this.notificationToasterService.showNotification(notificationData).then(() => {
      if (isSuccess) {
        this.telemetry.event(notificationConfiguration.telemetryEventData as string, {
          plan_id: planID,
        });
        modifiedSetTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    });
  }

  private getNotificationConfiguration(
    isSuccess: boolean,
    checkoutType: PopUpPageType,
  ): PayWallNotificationConfigurtion {
    if (isSuccess) {
      return notificationConfigurationMap[checkoutType];
    } else {
      return notificationConfigurationMap['Failure'];
    }
  }

  updateHubspotContactProperties(propertyToUpdate: HubspotContactProperties): Observable<any> {
    return this.http.post<any>(
      `${this.urlService.getDynamicUrl()}/tutor/billing/updateHubspotContactProperties`,
      {
        propertyToUpdate,
      },
    );
  }

  getSubscription(subscriptionId: string): Observable<SubscriptionInfo> {
    return this.http.get<SubscriptionInfo>(
      `${this.urlService.getDynamicUrl()}/tutor/billing/subscription/${subscriptionId}`,
    );
  }

  async HandlePortalSession(userID: string, planID: string): Promise<void> {
    try {
      const portalSession: PortalInfo = await firstValueFrom(this.createPortalSession(userID));

      if (!portalSession) {
        throw new Error('Portal session not available');
      }

      if (!this.popoutWindow) {
        this.showPopUpBlockedError();
        return;
      }

      this.popoutWindow.location.href = portalSession.access_url;
      this.portalStateHandler(portalSession.subscriptionId, planID);
    } catch (error) {
      this.closeWindowAndClearInterval();
      this.handleError(error);
    }
  }

  private portalStateHandler(subscriptionId: string, planID: string) {
    const checkPortalState = () => {
      this.logMessages('portal session listener running');
      if (this.popoutWindow?.closed) {
        this.logMessages('portal session closed');
        this.closeWindowAndClearInterval();
        this.getSubscription(subscriptionId)
          .pipe(take(1))
          .subscribe((subscriptionInfo: SubscriptionInfo) => {
            if (
              subscriptionInfo &&
              subscriptionInfo.status === ChargebeeSubscriptionStatus.CANCELLED
            ) {
              this.showNotification(true, PopUpPageType.CancelSubscription, planID);
            }
          });
      }
    };
    this.intervalId = modifiedSetInterval(checkPortalState, 1000);
  }

  private showToastError(): void {
    this.logMessages('failed to open pop-up because an error occured');
    this.settingsService.showToast(
      {
        title: this.translateService.instant('Failed to open'),
        description: this.translateService.instant('An error occurred. Please try again.'),
      },
      true,
    );
  }

  private showPopUpBlockedError(): void {
    this.logMessages('failed to open pop-up due to blockage');
    this.settingsService.showToast(
      {
        title: this.translateService.instant('Pop-up blocked by browser'),
        description: this.translateService.instant(
          'Your browser settings are preventing us from loading our payment portal. Please re-enable browser pop-ups and try again.',
        ),
      },
      true,
    );
  }

  private closeWindowAndClearInterval(): void {
    if (this.popoutWindow && !this.popoutWindow.closed) {
      this.popoutWindow?.close();
      this.popoutWindow = null;
    }
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  }
}

export const getVideoThumbnail = (url: string): string | undefined => {
  const parsedUrl = new URL(url);
  const vimeoId = detectVimeo(parsedUrl);
  if (vimeoId) {
    return `https://vumbnail.com/${vimeoId}.jpg`;
  }

  const youtubeId = detectYoutube(parsedUrl);
  if (youtubeId) {
    return `https://img.youtube.com/vi/${youtubeId}/0.jpg`;
  }
};

export const detectVimeo = (url: URL): string | null =>
  url.hostname === 'vimeo.com' ? url.pathname.split('/')[1] : null;

export const detectYoutube = (url: URL): string => {
  if (url.hostname.indexOf('youtube.com') > -1) {
    return url.search.split('=')[1];
  }

  if (url.hostname === 'youtu.be') {
    return url.pathname.split('/')[1];
  }

  return '';
};

export const detectDailymotion = (url: any): string => {
  if (url.hostname.indexOf('dailymotion.com') > -1) {
    return url.pathname.split('/')[2].split('_')[0];
  }

  if (url.hostname === 'dai.ly') {
    return url.pathname.split('/')[1];
  }

  return '';
};

import { SessionUser } from './session';

export enum FSInviteUpdateType {
  SPACE_USERS_INVITED = 'spaces_users_invited',
  SPACE_USERS_REINVITED = 'spaces_users_reinvited',
}

export class InviteSessionUser {
  sessionUser: SessionUser | undefined;
  email: string;
  constructor(email: string, sessionUser?: SessionUser) {
    if (sessionUser) {
      this.sessionUser = new SessionUser(sessionUser);
    }
    this.email = email;
  }
  isValid(): boolean {
    return !!this.sessionUser;
  }
}

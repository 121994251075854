import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isFileView',
})
export class FileViewCheckPipe implements PipeTransform {
  // eslint-disable-next-line no-useless-escape
  filePreviewRegex = new RegExp('\/content\/files\/([^\/]+)$', 'i');
  transform(url: string): any {
    return url.startsWith('/content/files') && !url.match(this.filePreviewRegex);
  }
}

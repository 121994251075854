import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User as IFBUser } from '@angular/fire/auth';

import { CustomErrorCodes } from 'src/custom_error_codes.constants';
import { firstValueFrom, from, switchMap } from 'rxjs';
import * as Sentry from '@sentry/browser';
import { Location } from '@angular/common';
import { AuthService, LOGIN_TYPE } from './services/auth.service';
import { UserService } from './services/user.service';
import { RealtimeService } from './services/realtime.service';
import { FlagsService, FLAGS } from './services/flags.service';
import { IUserInfo, User } from './models/user';
import { NotSupportedBrowsersComponent } from './dialogs/not-supported-browsers/not-supported-browsers.component';
import { ProfilesService } from './services/profiles.service';
import { ModalManagerService } from './services/modal-manager.service';
import { UserInfoInputDialogService } from './services/user-info-input-dialog.service';
import { redirectToAppScript } from './common/utils/common-util';
import { LANDING_URL } from './common/utils/url';
import { AccountEntryService } from './account/account-entry/account-entry.service';
import { TelemetryService } from './services/telemetry.service';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from './services/notification-toaster.service';
import { ToasterPopupStyle } from './ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { IconMessageToasterElement } from './ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';

@UntilDestroy()
@Injectable()
export class LoginActivate implements CanActivate, CanLoad {
  isUploading = false;
  user: User | null = null;
  firebaseUser?: IFBUser | null;
  redirectDetected = false;
  enableUserQuestionnaire = false;
  showUserInfoDialog = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private translate: TranslateService,
    private realtimeService: RealtimeService,
    private flagsService: FlagsService,
    private deviceService: DeviceDetectorService,
    private profilesService: ProfilesService,
    private modalManagerService: ModalManagerService,
    private userInfoInputDialogService: UserInfoInputDialogService,
    private location: Location,
    private accountEntryService: AccountEntryService,
    private telemetry: TelemetryService,
    private notificationToasterService: NotificationToasterService,
  ) {
    this.userService.isUploading.pipe(untilDestroyed(this)).subscribe((res) => {
      this.isUploading = res;
    });

    this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
      this.user = res && res.user;
    });

    this.authService.firebaseUser.pipe(untilDestroyed(this)).subscribe((res) => {
      this.firebaseUser = res;
    });
  }

  async loadTutor(route?: Route, segments?: UrlSegment[]): Promise<boolean> {
    this.userService.appLoading.next(true);
    return new Promise<boolean>((resolve, reject) => {
      this.userService
        .getUser()
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (res) => {
            if (res?.status_code === CustomErrorCodes.ACCOUNT_NOT_SIGNED_UP) {
              this.createUser(segments);
            }
            this.subTorealtimeEvents(res);
            this.userService.user.next(res);
            // Update timezone if not already set, or is set to "auto"
            if (res.user && (!res.user?.timezone || res.user?.timezone?.autoDetect)) {
              this.userService.updateTimezone();
            }
            from(this.flagsService.getFlags())
              .pipe(switchMap(() => this.profilesService.getAllCourses()))
              .pipe(untilDestroyed(this))
              .subscribe((coursesRes) => {
                this.handleCoursesResolve(coursesRes);
                if (route && segments) {
                  resolve(this.redirectOnLoad(route, segments));
                } else {
                  resolve(true);
                }
              });
          },
          error: (error) => {
            this.handleLoadTutorError(segments);
            reject(error);
          },
        });
      this.userService
        .getUsers()
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          this.userService.allUsers.next(res);
        });

      this.userService
        .getCustomAttributes('')
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          this.userService.allInstitutionCustomAttributes.next(res.custom_attributes);
        });
    });
  }

  handleCoursesResolve(coursesRes) {
    if (coursesRes && coursesRes.courses) {
      this.profilesService.courses.next(coursesRes.courses);
    }
    if (this.flagsService.isFlagEnabled(FLAGS.ENABLE_USER_QUESTIONNAIRE)) {
      this.enableUserQuestionnaire = true;
    }
    if (this.flagsService.isFlagEnabled(FLAGS.USER_INFO_V2)) {
      this.showUserInfoDialog = true;
    }
    if (!sessionStorage.getItem('modalShown')) {
      this.checkSupportedBrowser();
    }
  }

  handleLoadTutorError(segments?: UrlSegment[]) {
    // if we fail to get user redirect to login page
    this.userService.appLoading.next(false);
    if (segments) {
      this.router.navigate(['/account/login'], {
        queryParams: { continueUrl: segments.join('/') },
        skipLocationChange: false,
      });
    }
  }

  createUser(segments?: UrlSegment[]) {
    this.userService
      .createUser(this.firebaseUser?.providerId != 'password')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          if (segments) {
            location.href = segments.join('/');
          }
        },
        error: () => {
          if (segments) {
            location.href = segments.join('/');
          }
        },
      });
  }

  subTorealtimeEvents(res: IUserInfo) {
    if (res && res.user && res.user._id) {
      this.realtimeService.subscribeNotification(
        res.user._id,
        res.realtime?.realtime_user_notification,
      );
      this.realtimeService.subscribeUserNotification(
        res.user._id,
        res.realtime?.realtime_user_event_notification,
      );
      if (this.flagsService.isFlagEnabled(FLAGS.ENABLE_REALTIME_RTM)) {
        this.realtimeService.subscribeSignalingRTM(
          res.user._id,
          res.realtime?.realtime_rtm_channel,
        );
      }
    }
  }

  checkToShowUserInfoDialog(): void {
    const providers = this.firebaseUser?.providerData;
    const onlyPasswordProvider = providers?.length === 1 && providers[0]?.providerId === 'password';
    // firebase is pretty slow when it comes to updating its users
    // so we cant rely on emailVerified being updated in our backend
    const showQuestionnaire =
      !this.user?.institution &&
      this.showUserInfoDialog &&
      !this.user?.info?.questionnaireComplete &&
      (!onlyPasswordProvider || this.firebaseUser?.emailVerified);
    const showEnterName =
      this.user &&
      this.firebaseUser &&
      (this.firebaseUser?.emailVerified ||
        !onlyPasswordProvider ||
        this.authService.emailVerified) &&
      (!this.user?.name || this.user?.name === 'Anonymous User');

    if ((showQuestionnaire || showEnterName) && !this.user?.isAnonymous) {
      this.userInfoInputDialogService.openDialog(!!showQuestionnaire);
    }
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.userService.appLoading.next(false);
    this.checkToShowUserInfoDialog();
    const firebaseUser = this.authService.getCurrentUser();
    const appUser = this.userService.user.getValue();
    if (firebaseUser) {
      if (appUser && appUser.user.email !== firebaseUser.email) {
        return this.loadTutor();
      }

      return this.getAvailable();
    } else {
      if (
        !(
          state.url.includes('content/present') ||
          state.url.includes('content/view') ||
          state.url.includes('content/notes/view')
        )
      ) {
        if (state.url) {
          this.router.navigate(['/account'], {
            queryParams: { continueUrl: state.url },
            skipLocationChange: true,
          });
          return false;
        } else {
          this.router.navigate(['/account'], {
            skipLocationChange: true,
          });
          return false;
        }
      }
      return this.getAvailable();
    }
  }

  async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    try {
      await this.authService.loading;
    } catch (error) {
      Sentry.captureException(error);
    }
    if (!this.user && this.firebaseUser) {
      return this.loadTutor(route, segments);
    } else {
      await this.flagsService.getFlags();
      if (this.flagsService.isFlagEnabled(FLAGS.ENABLE_USER_QUESTIONNAIRE)) {
        this.enableUserQuestionnaire = true;
      }
      if (this.flagsService.isFlagEnabled(FLAGS.USER_INFO_V2)) {
        this.showUserInfoDialog = true;
      }
      return this.redirectOnLoad(route, segments);
    }
  }

  async redirectOnLoad(route: Route, segments: UrlSegment[]) {
    if (this.flagsService.isFlagEnabled(FLAGS.ENABLE_USER_QUESTIONNAIRE)) {
      this.enableUserQuestionnaire = true;
    }
    if (this.flagsService.isFlagEnabled(FLAGS.USER_INFO_V2)) {
      this.showUserInfoDialog = true;
    }
    if (
      (segments[0] && segments[0].path === 'techcheck') ||
      (segments[0] &&
        segments[0].path === 'content' &&
        segments[1] &&
        (segments[1].path === 'view' ||
          segments[1].path === 'present' ||
          segments[1].path + segments[2]?.path === 'notesview'))
    ) {
      return this.getAvailable();
    }

    // If we have a user and url is calendar add-on, reirect to calendar auth page
    if (
      this.firebaseUser &&
      this.router.getCurrentNavigation()?.extractedUrl.queryParams['redirect'] === 'addOnLoginMode'
    ) {
      redirectToAppScript(this.firebaseUser.providerId, null, null, this.firebaseUser);
      return true;
    }
    // If we have a user, redirect away from signup/login pages
    if (
      this.user &&
      segments[0] &&
      segments[0].path === 'account' &&
      (!segments[1] || segments[1].path === 'login')
    ) {
      // If a user enters via loginMethod = token, force log them in
      const urlParams = this.router.getCurrentNavigation()?.extractedUrl.queryParams;
      if (urlParams && urlParams.loginMethod === 'token') {
        return true;
      }

      // If we are already redirecting, our pathname is empty.
      // We don't need to redirect if we're already going somewhere
      if (location.pathname !== '/') {
        if (this.accountEntryService.isOnlyPasswordProviderAndEmailUnverified(this.firebaseUser)) {
          this.accountEntryService.goToVerificationPage(this.firebaseUser?.email);
          return true;
        }

        return this.router.navigateByUrl(this.authService.getRedirectUrl());
      }
    }
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');
    const company_id = urlParams.get('company_id');
    if (company_id && token) {
      return this.checkTutorCruncherSignInAttemp(company_id, token, urlParams, segments);
    }

    if (this.firebaseUser) {
      return this.getAvailable();
    } else {
      if (!route.path?.includes('account')) {
        return this.routeToAccount(urlParams, segments);
      }
      return true;
    }
  }

  public getContinueURL(urlParams: URLSearchParams, segments: UrlSegment[]) {
    if (urlParams.get('continueUrl')) {
      return location.search.slice(1);
    } else {
      return `continueUrl=${segments.join('/')}&${location.search.slice(1)}`;
    }
  }

  getAvailable(): boolean {
    if (
      this.isUploading &&
      confirm(
        this.translate.instant('Your file is still uploading. Are you sure you want to leave?'),
      )
    ) {
      return true;
    } else if (!this.isUploading) {
      return true;
    } else {
      return false;
    }
  }

  checkSupportedBrowser(): void {
    //  For now, we're just Hiding the modal without removing the code
    const shouldDisplayModal = false;
    if (!shouldDisplayModal) {
      return;
    }
    const isDesktopDevice = this.deviceService.isDesktop();
    const deviceBrowser = this.deviceService.getDeviceInfo().browser;
    if (isDesktopDevice && deviceBrowser.toLowerCase() !== 'chrome') {
      this.modalManagerService.showModal(
        NotSupportedBrowsersComponent,
        {
          width: '450px',
          panelClass: 'reset-fragment-dialog',
          height: '370px',
        },
        {
          afterClosed: (res) => {
            if (res) {
              window.open('https://www.google.com/chrome/');
            }
            sessionStorage.setItem('modalShown', 'true');
          },
        },
      );
    } else {
      sessionStorage.setItem('modalShown', 'true');
    }
  }

  public routeToAccount(urlParams: URLSearchParams, segments: UrlSegment[]) {
    const continueUrl = this.getContinueURL(urlParams, segments);
    const rerouteURL = `/account?${continueUrl}`;
    return this.router.navigateByUrl(rerouteURL, { skipLocationChange: false });
  }

  private displayTCErrorToasterAndRoute(message: string, title: string): Promise<boolean> {
    const titleElement = new IconMessageToasterElement({ icon: 'warning', size: 16 }, title);
    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translate.instant(message),
    );
    const TCSignInErrorNotificationData = new NotificationDataBuilder('TC Signin Error')
      .type(NotificationType.ERROR)
      .style(ToasterPopupStyle.ERROR)
      .timeOut(10)
      .topElements([titleElement])
      .middleElements([messageElement])
      .build();

    return new Promise((resolve) => {
      this.notificationToasterService.showNotification(TCSignInErrorNotificationData, () => {
        this.userService.appLoading.next(false);
        location.search = '';
        this.router.navigateByUrl('/account', { skipLocationChange: false });
        resolve(true);
      });
    });
  }

  private getTCErrorMessage(error: any) {
    switch (error.error.error_code) {
      case 'TUTORCRUNCHER_SIGNIN_USER_ALREADY_EXIST':
        return {
          message:
            'Sorry! You already have an account on Pencil Spaces. Please contact support to link your account to your institution',
          title: 'Account Already Exists',
        };
      case 'TUTORCRUNCHER_SIGNIN_INVALID_TOKEN':
        return {
          message:
            'Sorry! it looks like you may have reset your integration. Please contact support to re-link your TutorCruncher account with Pencil Spaces.',
          title: 'Integration Reset Detected',
        };
      default:
        return {
          message: `Sorry, you don't have an account, and we couldn't create one. Please contact your institution,
          Pencil Spaces support, or email support@pencilspaces.com. If your institution hasn't added Pencil Spaces, please request the integration.`,
          title: 'Account Sign In Failed',
        };
    }
  }

  private logTCErrorToFullStory(error: any, company_id: string) {
    this.telemetry.event('tutorcruncher_sso_attempted', {
      target_institution_id: company_id,
      target_institution_name: error.error.institutionName || '',
      error,
    });
  }

  private logTCErrors(error: any, company_id: string) {
    this.logTCErrorToFullStory(error, company_id);
    Sentry.captureException(error, {
      extra: {
        company_id,
        meesage: 'Tutor Cruncher sign in failed.',
      },
    });
  }

  public handleTCErrors(error: any, company_id: string): Promise<boolean> {
    const { message, title } = this.getTCErrorMessage(error);
    this.logTCErrors(error, company_id);
    return this.displayTCErrorToasterAndRoute(message, title);
  }

  public async checkTutorCruncherSignInAttemp(
    company_id: string,
    token: string,
    urlParams: URLSearchParams,
    segments: UrlSegment[],
    retry = 0,
  ): Promise<boolean> {
    this.userService.appLoading.next(true);
    try {
      const tutorCruncherlogin = await firstValueFrom(
        this.userService.loginTutorCruncherUser(company_id, token),
      );

      if (tutorCruncherlogin.token) {
        const continueUrl = tutorCruncherlogin.space
          ? `${LANDING_URL}/${tutorCruncherlogin.space}`
          : LANDING_URL;
        const queryg = `loginMethod=token&loginType=${LOGIN_TYPE.TutorCruncherSignIn}&token=${tutorCruncherlogin.token}&continueUrl=${continueUrl}`;
        console.log(queryg);

        this.location.go('', queryg);
        location.reload();
        return true;
      }
      this.routeToAccount(urlParams, segments);
      this.userService.appLoading.next(false);
      return false;
    } catch (error) {
      if (retry < 2) {
        return this.checkTutorCruncherSignInAttemp(
          company_id,
          token,
          urlParams,
          segments,
          retry + 1,
        );
      }

      return this.handleTCErrors(error, company_id);
    }
  }
}

import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from 'src/app/services/user.service';
import { defaultQuestionFilterParams, encodeQuestionFilterParams } from 'src/app/models/params';
import { Router } from '@angular/router';
import { RecommendedTopic } from 'src/app/admin/types.interface';
import { DifficultyLevels } from '../../consts';
import { TopicList } from '../../models/user';

export class StatsHelper {
  num = 0;
  multipleChoiceQuestions = 0;
  openEndedQuestions = 0;
  badQuestions = 0;
  averageQuestions = 0;
  goodQuestions = 0;
  excellentQuestions = 0;
  veryEasyQuestions = 0;
  easyQuestions = 0;
  difficultQuestions = 0;
  veryDifficultQuestions = 0;
  isRootLevel = false;
  isLeafNode = false;
  topicIndex = 0;
  distanceFromRoot = 0;
}


@UntilDestroy()
@Component({
  selector: 'app-topics-list',
  templateUrl: './topics-list.component.html',
  styleUrls: ['./topics-list.component.scss'],
})
export class TopicsListComponent implements OnInit, OnChanges {
  @Input() syllabusCode = '';
  @Input() mode = 'read_only';
  @Input() fromQuestionList = false;
  @Input() selectedTopicIds?: string[];
  @Input() selectedRootIds?: string[];
  @Input() topicsDistribution: any[] = [];
  @Input() fromPractice = false;
  @Input() fromFiles = false;
  // To Change the content of the topic list based only on SyllabusCode input.
  @Input() dependOnlyOnSyllabusCode = false
  @Input() recommendedTopics: RecommendedTopic[] = [];
  // View Message when no syllabus.
  @Input() showMessageForNoCourse = false;
  @Input() enableLoading = false;
  @Output() selectedIdsChanged = new EventEmitter();
  @Output() topicsChanged = new EventEmitter();
  @Output() selectedRootIdsChanged = new EventEmitter();
  @Output() closed = new EventEmitter();

  chartConfig = {
    type: 'column',
    width: '150',
    height: '80',
  };
  syllabusTopics?: TopicList;
  difficultyLevels = DifficultyLevels;
  topicStatsMap: Map<string, StatsHelper> = new Map();
  topics: any[] = [];
  filteredTopics: any[] = [];
  searchVal = '';
  isAllCollapsed = true;
  topicsSet = new Set();
  recommendedTopicIds: string[] = [];
  // To provide a loading screen.
  loading = false;

  constructor(
    public userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.syllabusCode?.length) {
      this.userService.getTopics(this.syllabusCode).pipe(untilDestroyed(this)).subscribe((topics) => {
        this.syllabusTopics = topics;
        this.initTopics();
      });
    }

    if (!this.dependOnlyOnSyllabusCode) {
      
        this.userService.topics.pipe(untilDestroyed(this)).subscribe((res) => {
          this.loading = true;
          if (res) {
            this.syllabusTopics = res;
            this.initTopics();
          }
          this.loading = false;
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.syllabusCode) {
      this.loading = true;
      this.userService.getTopics(this.syllabusCode).pipe(untilDestroyed(this)).subscribe((topics) => {
        this.syllabusTopics = topics;
        this.initTopics();
        this.loading = false;
      });
    }
    if (changes.recommendedTopics && changes.recommendedTopics.currentValue) {
      this.recommendedTopicIds = this.recommendedTopics.map((t: RecommendedTopic) => t.topic_id);
    }
    if (
      changes.selectedTopicIds &&
      changes.selectedTopicIds.currentValue !== changes.selectedTopicIds.previousValue
    ) {
      this.selectTopics();
    } else if (
      changes.selectedRootIds &&
      changes.selectedRootIds.currentValue !== changes.selectedRootIds.previousValue
    ) {
      this.topics.forEach((topic) => {
        topic.checked = false;
        topic.isChildChecked = false;
      });
      this.selectedRootIds?.forEach((rootId) => {
        this.topics
          .filter((t) => t.rootId === rootId)
          .forEach((t) => {
            if (t.child) {
              t.isChildChecked = true;
            } else {
              t.checked = true;
              if (t.parent) {
                t.parent.forEach((pId) => {
                  this.topics.find((tt) => tt._id === pId).isChildChecked = true;
                });
              }
            }
          });
      });
    }
  }



  initTopics() {
    this.topics = [];
    if (this.mode === 'read_only') {
      this.generateStatsFromResponse(this.topicsDistribution);
    }
    this.syllabusTopics?.topic_roots?.forEach((topic) => {
      this.createTopic(topic.topic_node.child, topic._id);
    });

    this.selectTopics();
    if (this.selectedRootIds && this.selectedRootIds?.length) {
      this.selectedRootIds.forEach((rootId) => {
        this.topics
          .filter((t) => t.rootId === rootId)
          .forEach((t) => {
            if (t.child) {
              t.isChildChecked = true;
            } else {
              t.checked = true;
              if (t.parent) {
                t.parent.forEach((pId) => {
                  this.topics.find((tt) => tt._id === pId).isChildChecked = true;
                });
              }
            }
          });
      });
    }

    this.topicsSet.clear();
    this.topicsChanged.emit(this.topics);
    this.filteredTopics = this.topics;
  }

  createTopic(topic, rootId: string, parent: any[] = [], level = 1) {

    if (!topic) {return;}

    if (!this.topicsSet.has(topic._id)) {
      this.topics.push({
        ...topic,
        level: level,
        rootId: rootId,
        parent: parent,
        class: level === 1 ? 'd-flex' : 'd-none',
        stats: this.getStats(topic._id),
      });
      this.topicsSet.add(topic._id);
    }

    const parentArr: any[] = [...parent, topic._id];
    this.createTopic(topic.child, rootId, parentArr, level + 1);
  }

  selectTopics() {
    if (this.selectedTopicIds) {
      this.topics.forEach((topic) => {
        topic.checked = false;
        topic.isChildChecked = false;
        if (this.selectedTopicIds?.includes(topic._id)) {
          topic.checked = true;
          if (topic.child) {
            topic.isChildChecked = true;
          }
        }
        if (topic.parent) {
          let isParentChecked = false;
          topic.parent.forEach((pId) => {
            if (this.selectedTopicIds?.includes(pId)) {
              isParentChecked = true;
            }
            if (topic.checked) {
              this.topics.find((t) => t._id === pId).isChildChecked = true;
            }
          });
          if (isParentChecked) {
            topic.checked = true;
            if (topic.child) {
              topic.isChildChecked = true;
            }
          }
        }
      });
      this.filterTopics();
    }
  }

  getStats(id) {
    if (this.mode === 'read_only') {
      if (this.topicStatsMap.has(id)) {
        return this.topicStatsMap.get(id);
      } else {
        return new StatsHelper();
      }
    }
  }

  expand(topic, event) {
    if (
      !topic.child ||
      (typeof event.target.className === 'string' &&
        event.target.className.includes('mat-checkbox'))
    ) {
      return;
    }
    if (topic.level === 1) {
      topic.class = topic.class === 'd-flex expanded' ? 'd-flex' : 'd-flex expanded';
    }
    let level = 0;
    for (let i = 0; i < this.filteredTopics.length; i++) {
      if (this.filteredTopics[i]._id === topic._id) {
        level = topic.level;
        topic.expanded = !topic.expanded;
        continue;
      }
      if (level !== 0 && this.filteredTopics[i].level - 1 === level) {
        this.filteredTopics[i].expanded = false;
        this.filteredTopics[i].class =
          this.filteredTopics[i].class === 'd-flex expanded' ? 'd-none' : 'd-flex expanded';
      }
      if (level !== 0 && this.filteredTopics[i].level - 1 > level && !topic.expanded) {
        this.filteredTopics[i].expanded = false;
        this.filteredTopics[i].class = 'd-none';
      }
      if (level !== 0 && this.filteredTopics[i].level === level) {
        break;
      }
    }

    // scroll to topic
    // if expanded topics is clipped by viewport
    // set timeout for DOM render and take new div height into effect
    const containerEl = document.querySelector('.topic-list-container');
    const headerHeight = document.querySelector('ui-header')?.clientHeight || 0;
    const containerHeight = window.innerHeight - headerHeight - 40;
    const allTopicsHeightBefore = containerEl?.scrollHeight || 0;
    const el = document.getElementById(`topic-item-${topic._id}`);
    requestAnimationFrame(() => {
      if (el) {
        const elemScrolltop = el.offsetTop - 40;
        const parent = <Element> el.parentNode;
        if (parent) {
          const allTopicsHeightAfter = containerEl?.scrollHeight || 0;
          const expandedTopicsHeight = allTopicsHeightAfter - allTopicsHeightBefore;

          if (elemScrolltop + 40 + expandedTopicsHeight > containerHeight) {
            // Expanded topics is clipped
            const scrollTopVal = elemScrolltop + expandedTopicsHeight + 40 - containerHeight;
            parent.scrollTop = scrollTopVal;
          }
        }
      }
    });
  }

  expandAll() {
    this.isAllCollapsed = false;
    this.filteredTopics.forEach((t) => {
      t.class = 'd-flex expanded';
      if (t.child) {
        t.expanded = true;
      }
    });
  }

  collapseAll() {
    this.isAllCollapsed = true;
    this.filteredTopics.forEach((t) => {
      if (t.level === 1) {
        t.class = 'd-flex';
      } else {
        t.class = 'd-none';
      }
      t.expanded = false;
    });
  }

  checkTopic(topic, $event) {
    topic.checked = !topic.checked;
    if (topic.child && this.mode !== 'selection') {
      topic.isChildChecked = topic.checked;
      let level = 0;
      for (let i = 0; i < this.filteredTopics.length; i++) {
        if (this.filteredTopics[i]._id === topic._id) {
          level = topic.level;
          continue;
        }
        if (level !== 0 && this.filteredTopics[i].level - 1 >= level) {
          this.filteredTopics[i].checked = topic.checked;
          if (this.filteredTopics[i].child) {
            this.filteredTopics[i].isChildChecked = topic.checked;
          }
        }
        if (level !== 0 && this.filteredTopics[i].level === level) {
          break;
        }
      }
    }
    if (topic.parent && this.mode !== 'selection' && !this.searchVal) {
      topic.parent.forEach((id) => {
        this.checkParent(id, topic.checked);
      });
    }
    const checkedTopicIds: string[] = [];
    const checkedRootIds: string[] = [];
    this.topics.forEach((t) => {
      if (t.parent) {
        let isParentChecked = false;
        t.parent.forEach((pId) => {
          if (checkedTopicIds.includes(pId)) {
            isParentChecked = true;
          }
        });
        if (!isParentChecked && t.checked) {
          if (!checkedRootIds.includes(t.rootId)) {
            checkedRootIds.push(t.rootId);
          }
          if (!checkedTopicIds.includes(t._id)) {
            checkedTopicIds.push(t._id);
          }
        }
      } else {
        if (t.checked && !checkedTopicIds.includes(t._id)) {
          checkedTopicIds.push(t._id);
        }
        if (t.checked && !checkedRootIds.includes(t.rootId) && this.mode === 'selection') {
          checkedRootIds.push(t.rootId);
        }
      }
    });
    this.filteredTopics.forEach((t) => {
      if (t.parent) {
        let isParentChecked = false;
        t.parent.forEach((pId) => {
          if (checkedTopicIds.includes(pId)) {
            isParentChecked = true;
          }
        });
        if (!isParentChecked && t.checked) {
          if (!checkedRootIds.includes(t.rootId)) {
            checkedRootIds.push(t.rootId);
          }
          if (!checkedTopicIds.includes(t._id)) {
            checkedTopicIds.push(t._id);
          }
        }
      } else {
        if (t.checked && !checkedTopicIds.includes(t._id)) {
          checkedTopicIds.push(t._id);
        }
        if (t.checked && !checkedRootIds.includes(t.rootId) && this.mode === 'selection') {
          checkedRootIds.push(t.rootId);
        }
      }
    });
    this.selectedIdsChanged.emit(checkedTopicIds);
    this.selectedRootIdsChanged.emit(checkedRootIds);
    this.changeDetectorRef.markForCheck();
  }

  checkParent(id, checked) {
    const parent = this.filteredTopics.find((t) => t._id === id);
    if (parent) {
      if (!checked) {
        parent.checked = false;
        let level = 0;
        let allChecked = false;
        for (let i = 0; i < this.filteredTopics.length; i++) {
          if (this.filteredTopics[i].parent && this.filteredTopics[i].parent.includes(id)) {
            level = this.filteredTopics[i].level;
            if (this.filteredTopics[i].checked) {
              allChecked = true;
            }
          }
          if (
            level !== 0 &&
            (!this.filteredTopics[i].parent || !this.filteredTopics[i].parent.includes(id))
          ) {
            break;
          }
        }
        if (!allChecked) {
          parent.isChildChecked = false;
        }
      } else {
        parent.isChildChecked = true;
        let level = 0;
        let allChecked = true;
        for (let i = 0; i < this.filteredTopics.length; i++) {
          if (this.filteredTopics[i].parent && this.filteredTopics[i].parent.includes(id)) {
            level = this.filteredTopics[i].level;
            if (!this.filteredTopics[i].checked) {
              allChecked = false;
            }
          }
          if (
            level !== 0 &&
            (!this.filteredTopics[i].parent || !this.filteredTopics[i].parent.includes(id))
          ) {
            break;
          }
        }
        if (allChecked) {
          parent.checked = true;
        }
      }
    }
  }

  setSearchValue(searchVal : string) : void {
    this.searchVal = searchVal;
    this.filterTopics();
  }

  filterTopics() {
    if (!this.searchVal) {
      this.filteredTopics = this.topics;
    } else {
      const ids: string[] = [];
      this.topics
        .filter((t) => t.name.toLowerCase().includes(this.searchVal.toLowerCase()))
        .forEach((t) => {
          if (t.parent) {
            t.parent.forEach((id) => {
              if (!ids.includes(id)) {
                ids.push(id);
              }
            });
          }
          if (!ids.includes(t._id)) {
            ids.push(t._id);
          }
        });
      this.topics.forEach((t) => {
        if (t.parent) {
          t.parent.forEach((pId) => {
            if (
              ids.includes(pId) &&
              this.topics
                .find((tt) => tt._id === pId)
                .name.toLowerCase()
                .includes(this.searchVal.toLowerCase())
            ) {
              ids.push(t._id);
            }
          });
        }
      });
      this.filteredTopics = this.topics.filter((t) => ids.includes(t._id));
      this.filteredTopics.forEach((t) => {
        t.expanded = true;
        t.class = 'd-flex expanded';
      });
      this.isAllCollapsed = false;
    }
  }

  generateStatsFromResponse(distribution: any[]) {
    for (const topic of distribution) {
      const statsHelper = new StatsHelper();
      statsHelper.num = topic.data.stats.numQuestions || topic.data.stats.numNotes;
      statsHelper.multipleChoiceQuestions = topic.data.stats.multipleChoiceQuestions;
      statsHelper.openEndedQuestions = topic.data.stats.openEndedQuestions;
      statsHelper.badQuestions = topic.data.stats.badQuestions;
      statsHelper.averageQuestions = topic.data.stats.averageQuestions;
      statsHelper.goodQuestions = topic.data.stats.goodQuestions;
      statsHelper.excellentQuestions = topic.data.stats.excellentQuestions;
      statsHelper.veryEasyQuestions = topic.data.stats.veryEasyQuestions;
      statsHelper.easyQuestions = topic.data.stats.easyQuestions;
      statsHelper.difficultQuestions = topic.data.stats.difficultQuestions;
      statsHelper.veryDifficultQuestions = topic.data.stats.veryDifficultQuestions;

      this.topicStatsMap.set(topic.data.id, statsHelper);
      if (topic.children) {
        this.generateStatsFromResponse(topic.children);
      }
    }
  }

  triggerTopicSearch(id: any, type: string) {
    const params = defaultQuestionFilterParams();
    params.topics = [id];
    switch (type) {
      case 'mcq':
        params.multipleChoice = true;
        break;
      case 'oeq':
        params.openEnded = true;
        break;
    }
    this.router.navigate([this.router.url.split('?')[0].replace('/syllabus', '')], {
      queryParams: { filters: encodeQuestionFilterParams(params) },
    });
  }
}

<div id="user-settings-advanced" class="settings-panel" [class.mobile]="isMobile">
  <div class="head">
    <span class="material-icons go-back" (click)="goBack.emit()"> arrow_back </span>
    <div class="description">
      <div class="title">{{ PANELS.ADVANCED.name }}</div>
      <div class="subtitle">{{ PANELS.ADVANCED.description }}</div>
    </div>
    <span class="material-icons close" (click)="goBack.emit()"> close </span>
  </div>
  <div class="sub-head" *ngIf="isMobile">
    {{ PANELS.ADVANCED.description }}
  </div>
  <div class="panel-body">
    <form [formGroup]="form" (submit)="submitChanges()" class="settings-panel-form">
      <div class="body">
        <div class="action-bars">
          <div class="toggle-group">
            <app-user-settings-permissions-toggle
              [formGroup]="form"
              [options]="{
                setting: 'inactivityNotifications',
                titleText: ('Notify me when participants get distracted in a Space' | translate),
                disabledOptions: {
                  disabled: (inactivityNotificationsPaid$ | async) === false,
                  reason: 'This feature is only available for Pencil Spaces Pro users' | translate
                },
                upgradeOptions: {
                  requiredSubscriptions:
                    foregroundInactivityNotificationsService.requiredSubscriptionTypes,
                  evaluationType: UpgradePlanEvaluationType.SELF
                },
                activeText:
                  ('Send hosts notifications if a participant does not have the browser in their main tab and / or are inactive on the call'
                  | translate),
                inactiveText:
                  ('Send hosts notifications if a participant does not have the browser in their main tab and / or are inactive on the call'
                  | translate)
              }"
            >
            </app-user-settings-permissions-toggle>
          </div>
        </div>
      </div>
      <div class="foot" [class.show]="formChanged$ | async">
        <app-pencil-button
          class="pencil-button btn-ok"
          [buttonType]="'submit'"
          [disabled]="form.invalid"
        >
          <span *ngIf="(isLoading$ | async) == false" class="material-icons">check</span>
          <mat-spinner *ngIf="isLoading$ | async" [diameter]="16" color="#FFF"></mat-spinner>
          <span>{{ 'Update' | translate }}</span>
        </app-pencil-button>
        <app-pencil-button
          [buttonType]="'button'"
          class="pencil-button btn-cancel"
          (clickEvent)="initForm()"
          type="button"
          icon="close"
          [iconConfig]="{ type: 'material-icon' }"
          [label]="'Cancel' | translate"
        >
        </app-pencil-button>
      </div>
    </form>
  </div>
</div>

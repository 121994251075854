import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subscription, lastValueFrom, of } from 'rxjs';
import { distinctUntilChanged, map, retry, take, tap } from 'rxjs/operators';
import { TelemetryService } from 'src/app/services/telemetry.service';
import * as Sentry from '@sentry/browser';

import * as shortid from 'shortid';
import { sortByString } from '../common/utils/common-util';
import {
  extractReferralParams,
  extractUTMParams,
  queryStringToParamMap,
} from '../common/utils/url';
import { LangCodeMap } from '../consts';
import { ChangeLanguageConfirmationDialogComponent } from '../dialogs/change-language-confirmation-dialog/change-language-confirmation-dialog.component';
import { Content } from '../models/content';
import { Context, SpaceCommentContext } from '../models/context';
import { MessageAction } from '../models/messaging';
import { QuestionFilterParams, encodeMessageFilterParams } from '../models/params';
import { Question } from '../models/question';
import {
  CustomAttribute,
  CustomAttributes,
  EMAIL_CODE_ACTION,
  ISyllabus,
  IUserInfo,
  IndividualUserOverview,
  Institution,
  Message,
  MessageCreateResponse,
  MessageList,
  TopicList,
  TopicNode,
  User,
  UserData,
  UserInfo,
  UserOverview,
} from '../models/user';
import { LangCode } from '../sessions/session/wb-top-controls/wb-top-controls.component';
import { ErrorInterceptorSkipHeader } from '../error.interceptor';
import { URLService } from './dynamic-url.service';
import { NetworkCacheService } from './network-cache.service';
import { Feature } from './acl.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  user = new BehaviorSubject<UserData | null>(null);
  callReceiver = new BehaviorSubject<UserInfo | null>(null);
  isUploading = new BehaviorSubject(false);
  allUsers = new BehaviorSubject<any | null>(null);
  participantUser = new BehaviorSubject(null);
  callReceiverUID = new BehaviorSubject<string | null>(null);
  appLoading = new BehaviorSubject(false);
  topics = new BehaviorSubject<TopicList>({ topic_roots: [] });
  siblings = new BehaviorSubject<User[] | null>(null);
  rtl = new BehaviorSubject(false);
  topicsMap = new BehaviorSubject<Map<string, any>>(new Map());
  hashmap = new Map<string, any>();
  sessionSearch = new BehaviorSubject<string>(''); // used when search sessions in analytics tab
  isUpdateButtonClicked = new BehaviorSubject(false);
  langCodes = new BehaviorSubject<LangCode[]>([]);
  selectedLangCode = new BehaviorSubject<string>('en');
  private _userUniqueHash = '';

  subscribers: Subscription[] = [];

  allInstitutionCustomAttributes = new BehaviorSubject<any | null>(null);

  get currentInstitutionCustomAttributes(): CustomAttribute[] {
    return this.allInstitutionCustomAttributes.getValue() || [];
  }

  constructor(
    public activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private urlService: URLService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private telemetry: TelemetryService,
    private networkCache: NetworkCacheService,
  ) {
    this.subscribers.push(
      this.topics.subscribe((res) => {
        this.hashmap = new Map();
        if (res && res.topic_roots) {
          for (const topic of res.topic_roots) {
            this.updateTopicsMap(topic.topic_node, topic.position, 0);
          }
        }
        this.topicsMap.next(this.hashmap);
      }),
    );
    this.user.pipe(untilDestroyed(this)).subscribe((res) => {
      if (!res) {
        return;
      }
      Sentry.setUser({ email: res?.user.email });
      const enabledLangCodesList = (
        res?.user?.feature_flag_variables?.spaces_localized_languages?.langcodes as string
      )?.split(',');
      const langCodes = LangCodeMap.filter((codeMap) => {
        const isEnabled = enabledLangCodesList.includes(codeMap.code);
        if (isEnabled && !codeMap.originalLabel) {
          console.error(
            `The following lang is enabled in Optimizerly but not have originalLabel: ${codeMap.code}`,
          );
        }
        return isEnabled && codeMap.originalLabel;
      }).sort((a: any, b: any) => sortByString(a, b, 'label'));

      this.langCodes.next(langCodes);
      const localStorageLangCode = localStorage.getItem('selected_lang_code');
      const userLangCode = res?.user?.settings?.lang_interface;
      if (localStorageLangCode && userLangCode && localStorageLangCode !== userLangCode) {
        this.changeLang(localStorageLangCode);
      } else if (userLangCode) {
        const langCode = userLangCode;
        const code = langCodes.find((lang) => lang.code === langCode);
        if (code) {
          this.rtl.next(code.rtl);
        }
        this.translateService.use(langCode);
        this.selectedLangCode.next(langCode);
      }
    });

    this.user
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged((a, b) => a?.user._id === b?.user._id),
      )
      .subscribe((data) => {
        if (data && data?.user) {
          const instance_id = shortid.generate();
          this._userUniqueHash = `${data.user._id}_${instance_id}`;
        }
      });
  }

  ngOnDestroy() {
    this.subscribers.forEach((sub) => sub.unsubscribe());
  }

  updateTopicsMap(topic: TopicNode, position: number, level: number) {
    if (!this.hashmap.get(topic._id)) {
      this.hashmap.set(topic._id, {
        ...topic,
        position,
        level,
      });
    }
    if (topic.child) {
      this.updateTopicsMap(topic.child, position, level + 1);
    }
  }

  createUser(sendWelcomeEmail = true): Observable<User> {
    const paramMap = queryStringToParamMap(location.search);
    const utmParams = extractUTMParams(paramMap);
    const body = {
      // Indicates that the request is coming from the pencil app not the extension.
      src: 'app',
      verify_token: paramMap.get('verify_token'),
      referral_id: paramMap.get('referral_id'),
      utmParams,
      sendWelcomeEmail,
    };
    return this.http.post<User>(`${this.urlService.getDynamicUrl()}/tutor/create`, body).pipe(
      tap(() => {
        this.logFSReferralAccepted();
      }),
    );
  }

  inviteUser(email: string): Observable<any> {
    const body = {
      // Indicates that the request is coming from the pencil app not the extension.
      src: 'app',
      email: email,
    };
    return this.http.post<User>(`${this.urlService.getDynamicUrl()}/tutor/invite`, body);
  }

  inviteUsers(emails: string[]): Observable<any> {
    const body = {
      // Indicates that the request is coming from the pencil app not the extension.
      src: 'app',
      emails,
    };
    return this.http.post<User>(`${this.urlService.getDynamicUrl()}/tutor/invite-multiple`, body);
  }

  getUser(): Observable<IUserInfo> {
    return this.http.get<IUserInfo>(`${this.urlService.getDynamicUrl()}/tutor`).pipe(
      map((res) => {
        if (res?.user) {
          res.user.isAnonymous = !!res?.user?.enabled_features?.includes(Feature.isAnonymous);
        }
        return res;
      }),
    );
  }

  getSyllabus(id: string): Observable<ISyllabus> {
    return this.http.get<ISyllabus>(`${this.urlService.getDynamicUrl()}/tutor/syllabus/${id}`);
  }

  updateUser(userUpdate: any): Observable<any> {
    return this.http
      .patch<User>(
        `${this.urlService.getDynamicUrl()}/tutor/tutors/${userUpdate._id}/edit`,
        userUpdate,
      )
      .pipe(
        tap(async (response) => {
          // if update is successful, update this.user so the UI clients get the latest state
          if (response) {
            const updatedUser = this.user.getValue();
            if (updatedUser) {
              updatedUser.user = { ...updatedUser.user, ...userUpdate }; // merge the updates
              this.user.next(updatedUser);
            }
          }
        }),
      );
  }

  updateInstitution(tutor: User): Observable<any> {
    return this.http.patch<User>(`${this.urlService.getDynamicUrl()}/tutor/institution`, tutor);
  }

  getUsers(): Observable<UserOverview> {
    return this.http.get<UserOverview>(`${this.urlService.getDynamicUrl()}/tutor/tutors`);
  }

  async clearCacheAndReFetchAllUsers(): Promise<UserOverview> {
    await this.networkCache.clearUsersCache();
    return new Promise((resolve, reject) => {
      this.getUsers()
        .pipe(take(1))
        .subscribe({
          next: (users) => {
            this.allUsers.next(users);
            resolve(users); // Resolve with the fetched users
          },
          error: (err) => reject(err), // Reject with the encountered error
        });
    });
  }

  getUsersByCourseId(courseId: string): Observable<User[]> {
    return this.http.get<User[]>(
      `${this.urlService.getDynamicUrl()}/tutor/tutors/course/${courseId}`,
    );
  }
  getInstitutionUsersByCourseId(courseId: string): Observable<any> {
    return this.http.get(
      `${this.urlService.getDynamicUrl()}/tutor/institution/users/course/${courseId}`,
    );
  }

  getInstitutionDetails(institutionId: string) {
    return this.http.get<Institution>(
      `${this.urlService.getDynamicUrl()}/tutor/institution/${institutionId}`,
    );
  }

  getIndividualUserStats(tutor_id: string): Observable<IndividualUserOverview> {
    return this.http.get<IndividualUserOverview>(
      `${this.urlService.getDynamicUrl()}/tutor/tutors/${tutor_id}`,
    );
  }

  getSpaceComments(context: SpaceCommentContext, page = 1, num = 100) {
    const messageFilter = encodeMessageFilterParams({
      sessionId: context.session,
      itemId: context.itemId,
      boardId: context.boardId,
    });
    return this.http.get<MessageList>(
      `${this.urlService.getDynamicUrl()}/tutor/messages/search?filters=${messageFilter}&page=${page}&num=${num}`,
    );
  }

  getComments(context: Context, page = 1, num = 3): Observable<MessageList> {
    const messageFilter = encodeMessageFilterParams({
      questionId: context.question,
      worksheetId: context.worksheet,
      noteId: context.note,
      userIds: context.users,
      sessionId: context.session,
    });

    return this.http.get<MessageList>(
      `${this.urlService.getDynamicUrl()}/tutor/messages/search?filters=${messageFilter}&page=${page}&num=${num}`,
    );
  }

  createComment(
    message: Message,
    spaceId?: string,
  ): Observable<HttpResponse<MessageCreateResponse>> {
    // Added ErrorInterceptorSkipHeader to the request to allow us catch error and inform the user to retry sending again

    const headers = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }).set(
        ErrorInterceptorSkipHeader,
        '',
      ),
    };

    const commentURL = `${this.urlService.getDynamicUrl()}/tutor/messages/add`;

    return this.http
      .post<MessageCreateResponse>(commentURL, JSON.stringify({ message, spaceId }), {
        ...headers,
        observe: 'response',
      })
      .pipe(retry(3));
  }

  getComment(id: string): Observable<Message> {
    const commentURL = `${this.urlService.getDynamicUrl()}/tutor/messages/${id}`;
    return this.http.get<{ status: string; message: Message }>(commentURL, httpOptions).pipe(
      retry(3),
      map((data) => data.message),
    );
  }

  updateComment(message: Message, type?: MessageAction): Observable<any> {
    const id = message._id;
    let commentURL = `${this.urlService.getDynamicUrl()}/tutor/messages/edit/${id}`;
    if (type) {
      commentURL += `?type=${type}`;
    }

    return this.http
      .patch<any>(commentURL, JSON.stringify({ message }), {
        ...httpOptions,
        observe: 'response',
      })
      .pipe(retry(3));
  }

  deleteComment(message: Message): Observable<any> {
    const id = message._id;
    const deleteURL = `${this.urlService.getDynamicUrl()}/tutor/messages/${id}`;
    return this.http.delete<any>(deleteURL);
  }

  deleteSpaceComment(id: string): Observable<any> {
    const deleteURL = `${this.urlService.getDynamicUrl()}/tutor/messages/${id}?isComment=true`;
    return this.http.delete<any>(deleteURL, { observe: 'response' });
  }

  /**
   * Given no request params, deletes both comments and messages of a space
   * @param sessionId space id
   * @param action MessageAction to specify what should be deleted space comments or messages
   * @returns
   */
  deleteAllComments(sessionId: string, action?: MessageAction): Observable<any> {
    let deleteURL = `${this.urlService.getDynamicUrl()}/tutor/messages/deleteAll/${sessionId}`;
    if (action) {
      deleteURL += `?action=${action}`;
    }
    return this.http.delete<any>(deleteURL, { observe: 'response' });
  }

  /**
   * Deletes the space comments of a specific board
   * @param sessionId space id
   * @param boardId frame id whose
   * @returns
   */
  deleteAllBoardComments(sessionId: string, boardId: string): Observable<any> {
    const deleteURL = `${this.urlService.getDynamicUrl()}/tutor/messages/deleteAll/${sessionId}?boardId=${boardId}`;
    return this.http.delete<any>(deleteURL, { observe: 'response' });
  }

  getContentWithParamsJson(
    params?: QuestionFilterParams,
    page?: number,
    num?: number,
    isNotes?: boolean,
  ): Observable<Content> {
    // eslint-disable-next-line frontend-rules/ngx-translate-service
    let path = '/tutor/search';
    let pg = 1;
    let nm = 20;
    let notes = 0;
    if (page) {
      pg = page;
    }
    if (num) {
      nm = num;
    }
    if (isNotes) {
      notes = 1;
    }
    path = `${path}?page=${pg}&num=${nm}&notes=${notes}`;
    if (params) {
      path = `${path}&filters=${btoa(JSON.stringify(params))}`;
    }
    return this.http.get<Content>(`${this.urlService.getDynamicUrl()}${path}`);
  }

  getPracticeContent(index: number, params?: QuestionFilterParams): Observable<any> {
    // eslint-disable-next-line frontend-rules/ngx-translate-service
    let path = '/tutor/practice/temp/next';
    if (params) {
      path = `${path}/${index}?filters=${btoa(JSON.stringify(params))}`;
    }
    return this.http.get<Content>(`${this.urlService.getDynamicUrl()}${path}`);
  }

  getPracticeSession(num_sessions?: number) {
    // eslint-disable-next-line frontend-rules/ngx-translate-service
    let path = '/tutor/practice/session';
    if (num_sessions) {
      path += `?num_sessions=${num_sessions}`;
    }
    return this.http.get<any>(`${this.urlService.getDynamicUrl()}${path}`);
  }

  getPreviousQuestion(question_index: number, session_id: string) {
    // eslint-disable-next-line frontend-rules/ngx-translate-service
    let path = `/tutor/practice/temp/previous/${question_index}`;
    path += `?session_id=${session_id}`;
    return this.http.get<any>(`${this.urlService.getDynamicUrl()}${path}`);
  }

  getAllPracticeSessions(courseId: string, userId?: string) {
    // eslint-disable-next-line frontend-rules/ngx-translate-service
    let path = `/tutor/practice/sessions?courseId=${courseId}`;
    if (userId) {
      path += `&userId=${userId}`;
    }
    return this.http.get<any>(`${this.urlService.getDynamicUrl()}${path}`);
  }

  getPracticeSessionById(session_id: string) {
    // eslint-disable-next-line frontend-rules/ngx-translate-service
    const path = `/tutor/practice/session/${session_id}`;
    return this.http.get<any>(`${this.urlService.getDynamicUrl()}${path}`);
  }

  getStudentsStat(courseId: string) {
    // eslint-disable-next-line frontend-rules/ngx-translate-service
    const path = `/tutor/practice/students?courseId=${courseId}`;
    return this.http.get<any>(`${this.urlService.getDynamicUrl()}${path}`);
  }

  saveAnswer(
    question: Question,
    answer: string,
    answerTime: number,
    courseId: string,
    sessionId: string,
    skipped = false,
    old_index?: number,
  ) {
    // old_index should be set only when answer a previously skipped question, and old_index is the index
    // of the skipped question in the practice session
    const path = `/tutor/answer/${question._id}`;
    const body = {
      answer: answer,
      answer_time: answerTime,
      course_id: courseId,
      session_id: sessionId,
      skipped: skipped,
    };
    if (old_index) {
      body['old_index'] = old_index;
    }

    return this.http.post<any>(`${this.urlService.getDynamicUrl()}${path}`, body);
  }

  getHomepage(): Observable<Content> {
    return this.http.get<Content>(`${this.urlService.getDynamicUrl()}/tutor/home`);
  }

  getTopics(syllabusCode: string): Observable<TopicList> {
    if (!syllabusCode) {
      return of({ topic_roots: [] });
    }
    return this.http.get<TopicList>(`${this.urlService.getDynamicUrl()}/topics/${syllabusCode}`);
  }

  /**
   * get custom attributes based on the course id or if user requesting profiles custom attributes
   * @param course
   * @param type
   */
  getCustomAttributes(course: string, type?: string): Observable<any> {
    const queryParams: any = {};
    if (type) {
      queryParams.type = type;
    }

    return this.http.get<CustomAttributes>(
      `${this.urlService.getDynamicUrl()}/tutor/attributes/${course}`,
      { params: queryParams },
    );
  }

  saveNotification(subscription: any): Observable<any> {
    const notificationUrl = `${this.urlService.getDynamicUrl()}/tutor/saveSubscription`;
    return this.http
      .post<any>(notificationUrl, JSON.stringify({ subscription }), httpOptions)
      .pipe(retry(3));
  }

  impersonate(email: string): Observable<any> {
    return this.http.get(`${this.urlService.getDynamicUrl()}/tutor/impersonate/${email}`);
  }

  updateUserProfile(id: string, directory: any): Observable<any> {
    return this.http.patch(
      `${this.urlService.getDynamicUrl()}/tutor/tutors/${id}/directory/edit`,
      directory,
    );
  }

  async refreshUser(): Promise<void> {
    // clear the browser cached user so the next call to getUser() gets the latest value
    await this.networkCache.clearUserCache();
    this.getUser()
      .pipe(take(1))
      .subscribe({
        next: (user) => {
          this.user.next(user);
          return Promise.resolve(user);
        },
        error: (err) => Promise.reject(err),
      });
  }

  sendEmailVerification(params: { email: string }) {
    params.email = encodeURIComponent(params.email);
    return this.http.get<any>(`${this.urlService.getDynamicUrl()}/tutor/auth/verify`, {
      params: params,
    });
  }

  sendPasswordResetRequest(params: { email: string }) {
    params.email = encodeURIComponent(params.email);
    return this.http.get<any>(`${this.urlService.getDynamicUrl()}/tutor/auth/password_reset`, {
      params: params,
    });
  }

  sendEmailCodeLogin(params: { email: string }) {
    params.email = encodeURIComponent(params.email);
    return this.http.get<any>(`${this.urlService.getDynamicUrl()}/tutor/auth/email_link`, {
      params: params,
    });
  }

  sendWelcomeEmail() {
    return this.http.get<any>(`${this.urlService.getDynamicUrl()}/tutor/welcome`);
  }

  revokeRefreshTokens(uid: string) {
    return this.http.get<any>(
      `${this.urlService.getDynamicUrl()}/tutor/auth/revoke_refresh_tokens/${uid}`,
    );
  }

  get userId(): string | undefined {
    return this.user.value?.user._id;
  }

  async changeLang(langCode: string) {
    localStorage.setItem('selected_lang_code', langCode);
    const user = this.user.getValue()?.user;
    if (user) {
      if (user.settings) {
        user.settings['lang_interface'] = langCode;
      } else {
        user['settings'] = {
          lang_interface: langCode,
        };
      }
      await lastValueFrom(
        this.updateUser({
          _id: user._id,
          settings: {
            lang_interface: langCode,
          },
        }),
      );
    }
    /*
      this is required because some Angular Materials components don't update the content after initialized,
      meaning that after language is changed, those components don't render the translated strings
    */
    location.reload();
  }

  async confirmLanguageChange(prevLangCode: string, langCode: LangCode): Promise<void> {
    if (langCode.code === prevLangCode) {
      return;
    }
    this.dialog.open(ChangeLanguageConfirmationDialogComponent, {
      width: '465px',
      height: '230px',
      panelClass: ['no-padding-dialog', 'language-confirm-dialog'],
      autoFocus: false,
      data: {
        target: langCode,
        current: this.langCodes.getValue()?.find((lang) => lang.code === prevLangCode),
      },
    });
  }

  verifyToken(inviteToken: string, email: string): Observable<any> {
    return this.http.post<any>(`${this.urlService.getDynamicUrl()}/tutor/auth/verify_token`, {
      inviteToken,
      email,
    });
  }

  getAuthProfile(email: string): Observable<any> {
    return this.http.get<any>(
      `${this.urlService.getDynamicUrl()}/tutor/auth/basic_profile?email=${encodeURIComponent(
        email,
      )}`,
    );
  }

  async updateTimezone(timezone?: string): Promise<any> {
    let updateUser: User;
    const currentUser = this.user.getValue()?.user;
    if (!currentUser) {
      return;
    }

    if (timezone) {
      // Explicitly set timezone
      updateUser = {
        ...currentUser,
        timezone: {
          autoDetect: false,
          value: timezone,
        },
      };
    } else {
      // Set timezone based on user client
      const userTimezone = moment.tz.guess();
      updateUser = {
        ...currentUser,
        timezone: {
          autoDetect: true,
          value: userTimezone,
        },
      };
    }

    return new Promise((resolve, reject) => {
      this.updateUser(updateUser)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          },
          error: (err) => reject(err),
        });
    });
  }

  logFSReferralAccepted(): void {
    const paramMap = queryStringToParamMap(location.search);
    const referralParams = extractReferralParams(paramMap);
    if (referralParams && referralParams.referral_id) {
      this.telemetry.event('referral_accepted', { referral_id: referralParams.referral_id });
    }
  }

  generateAndSendCode(email: string, action: EMAIL_CODE_ACTION) {
    switch (action) {
      case EMAIL_CODE_ACTION.VERIFY_EMAIL:
        return this.sendEmailVerification({ email });
      case EMAIL_CODE_ACTION.RESET_PASSWORD:
        return this.sendPasswordResetRequest({ email });
      case EMAIL_CODE_ACTION.SIGN_IN:
        return this.sendEmailCodeLogin({ email });
    }
  }

  verifyCode(email: string, code: string, action: EMAIL_CODE_ACTION): Observable<any> {
    return this.http.get<any>(`${this.urlService.getDynamicUrl()}/tutor/code/${code}`, {
      params: {
        email: encodeURIComponent(email),
        action,
      },
    });
  }

  loginTutorCruncherUser(
    company_id: string,
    token: string,
  ): Observable<{ token: string; space: string; user: any }> {
    const headers = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }).set(
        ErrorInterceptorSkipHeader,
        '',
      ),
    };
    const url = `${this.urlService.getDynamicUrl()}/tutorcruncher/company-login`;
    const query = { company_id, token };
    return this.http.post<any>(url, {}, { ...headers, params: query });
  }

  get userUniqueHash(): string {
    return this._userUniqueHash;
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { sortByString } from 'src/app/common/utils/common-util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IndividualUserOverview, UserInfo, UserOverview } from '../models/user';
import { UserService } from '../services/user.service';

@UntilDestroy()
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  isFullInfoMode = false;
  isFetchingComplete = false;
  fullInfoModeProfile?: UserInfo;
  fullInfoModeProfileExtendedInfo?: IndividualUserOverview;
  userOverview?: UserOverview;
  preSelectedUserId = '';
  searchText = '';
  headers = [''];
  filteredList: any[] = [];
  queryParams: any;
  showList = true;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      this.preSelectedUserId = params['id'];
      this.queryParams = JSON.parse(JSON.stringify(params));

      if (params['id']) {
        this.selectUserId(this.preSelectedUserId);
      } else {
        this.showList = true;
        this.selectUserId('');
      }
    });
    this.userService.allUsers.pipe(untilDestroyed(this)).subscribe((data: UserOverview) => {
      if (data) {
        this.userOverview = data;
        this.isFetchingComplete = true;
        if (this.userOverview?.profiles && this.userOverview.profiles.length > 0) {
          // Open the first profile or the profile from URL param in
          // Full view by default.
          this.filteredList = this.userOverview.profiles;
          this.filteredList.sort((a, b) => sortByString(a, b, 'name'));
          this.selectUserId(this.preSelectedUserId);
        }
      }
    });
  }

  selectUserId(id: string): void {
    // The tutor stats are not yet returned from tutor service.
    if (!this.userOverview) {
      return;
    }
    if (!id) {
      this.showFullInfo(this.userOverview.profiles[0]);
      return;
    }
    this.queryParams['id'] = id;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
    });
    for (const profile of this.userOverview.profiles) {
      if (profile._id === id) {
        this.showFullInfo(profile);
      }
    }
  }

  showFullInfo(profile: UserInfo): void {
    if (!profile) {
      return;
    }
    this.queryParams['id'] = profile._id;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
    });
    this.preSelectedUserId = profile._id;

    this.fullInfoModeProfile = profile;
    this.isFullInfoMode = false;
    this.userService.appLoading.next(true);
    // This check here is entirely unnecessary but typescript compiler
    // complains on fullInfoModeProfile potentially being undefined on the
    // next line even know profile has been tested at the top of the method.
    if (!this.fullInfoModeProfile) {
      return;
    }
    // Fetching full fledged information for the given tutor
    this.userService
      .getIndividualUserStats(this.fullInfoModeProfile._id)
      .pipe(untilDestroyed(this))
      .subscribe(
        (user_data) => {
          this.userService.appLoading.next(false);
          this.fullInfoModeProfileExtendedInfo = user_data;
          this.isFullInfoMode = true;
        },
        () => {
          this.userService.appLoading.next(false);
        },
      );
  }

  getHeader(profile: UserInfo, index: number): string {
    if (profile.name) {
      this.headers.push(profile.name.substr(0, 1).toUpperCase());
      if (profile.name.substr(0, 1).toUpperCase() !== this.headers[index]) {
        return profile.name.substr(0, 1).toUpperCase();
      }
    }
    return '';
  }

  search(): void {
    this.headers = [''];
    if (!this.userOverview) {
      return;
    }
    this.filteredList = this.userOverview.profiles.filter((p) => {
      if (!p.name) {
        return false;
      }

      return p.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
    });
    this.filteredList.sort((a, b) => sortByString(a, b, 'name'));
  }
}

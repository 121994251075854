import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Observable, concat, distinctUntilChanged, map, mergeMap, of, takeWhile } from 'rxjs';
import { EmbedVideoService } from '../services/embed-video.service';
import { VimeoUploadService } from '../services/vimeo-upload.service';
import { modifiedTimer } from '../utilities/ZoneUtils';

export enum VIMEO_EMBEDDED_CONTENT_STATUS {
  LOADING = 'loading',
  PROCESSING = 'processing',
  READY = 'ready',
}
interface VimeoEmbeddedData {
  innerHTML?: SafeHtml;
  status: VIMEO_EMBEDDED_CONTENT_STATUS;
}

@Pipe({
  name: 'embedVimeoVideo',
})
export class EmbedVimeoVideoPipe implements PipeTransform {
  constructor(private embedService: EmbedVideoService, private vimeoService: VimeoUploadService) {}

  transform(
    url: string,
    options?: { attr?: Record<string, unknown>; query?: string },
  ): Observable<VimeoEmbeddedData> {
    const pollVimeoUntilAvailable$ = concat(
      of({ status: VIMEO_EMBEDDED_CONTENT_STATUS.LOADING }), // Emit the initial loading status immediately
      modifiedTimer(0, 5000).pipe(
        mergeMap<number, Observable<VimeoEmbeddedData>>(() =>
          this.vimeoService.isVideoAvailable(new URL(url)).pipe(
            map((available) => {
              if (available) {
                return {
                  innerHTML: this.embedService.embed(url, options),
                  status: VIMEO_EMBEDDED_CONTENT_STATUS.READY,
                };
              } else {
                return { status: VIMEO_EMBEDDED_CONTENT_STATUS.PROCESSING };
              }
            }),
          ),
        ),
        takeWhile(
          (embeddedVideoData) => embeddedVideoData.status !== VIMEO_EMBEDDED_CONTENT_STATUS.READY,
          true,
        ),
        distinctUntilChanged(),
      ),
    );

    return pollVimeoUntilAvailable$;
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-pencil-spinner',
  templateUrl: './pencil-spinner.component.html',
  styleUrls: ['./pencil-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PencilSpinnerComponent {
  @Input() diameter!: number;
  @Input() strokeWidth!: number;
}

import { Component, Input } from '@angular/core';
import { modifiedSetInterval, modifiedSetTimeout } from 'src/app/utilities/ZoneUtils';
import { ToasterElement, ToasterElementType } from '../toaster-element/toaster-element.component';
import { Icon } from '../toaster-icon/toaster-icon.component';

export type IconMessageToasterElementParams = ConstructorParameters<
  typeof IconMessageToasterElement
>;

export enum IconBackground {
  NONE,
  WARNING,
  INFO,
  ERROR,
  SUCCESS,
}

export class IconMessageToasterElement implements ToasterElement {
  public readonly type = ToasterElementType.ICON_STR;
  icon?: Icon;
  spinner?: boolean;
  msg?: string;
  originalMsg?: string;
  iconBackground?: IconBackground;
  removeMargin?: boolean;
  outlined?: boolean;
  style?: string;
  textStyle?: string;
  spinnerStyle?: Record<string, string>;

  // count down for retry
  countDown?: number;

  // callback function to be called immediately after countdown
  callback?: () => void;

  interval?: NodeJS.Timer;

  constructor(
    icon?: Icon,
    str?: string,
    countDown?: number,
    callback?: () => void,
    spinner?: boolean,
    iconBackground?: IconBackground,
    removeMargin?: boolean,
    outlined?: boolean,
    style?: string,
    textStyle?: string,
    spinnerStyle?: Record<string, string>,
  ) {
    this.icon = icon;
    this.spinner = !this.icon ? spinner : undefined;
    this.msg = str;
    this.originalMsg = str;
    this.iconBackground = iconBackground ?? IconBackground.NONE;
    this.removeMargin = removeMargin ?? false;
    this.outlined = outlined ?? false;
    this.style = style ?? '';
    this.textStyle = textStyle ?? '';
    this.spinnerStyle = spinnerStyle ?? {};

    if (countDown) {
      this.countDown = countDown;
      this.callback = callback;
      this.msg = `${this.originalMsg} ${countDown} seconds.`;
      this.countDownHandler();
    }
  }

  private countDownHandler() {
    let counter = this.countDown;
    this.interval = modifiedSetInterval(() => {
      counter = counter! - 1;
      this.msg = `${this.originalMsg} ${counter} seconds.`;
      if (counter === 0) {
        clearInterval(this.interval!);
        modifiedSetTimeout(() => {
          this.callback?.call(this);
        }, 500);
      }
    }, 1000); // update counter every second
  }

  clearCountDown() {
    if (this.interval) {
      clearInterval(this.interval!);
    }
  }
  startCountDown() {
    if (this.interval) {
      clearInterval(this.interval!);
    }
    this.msg = `${this.originalMsg} ${this.countDown} seconds.`;
    this.countDownHandler();
  }
}

@Component({
  selector: 'app-icon-message-toaster-element',
  templateUrl: './icon-message-toaster-element.component.html',
  styleUrls: ['./icon-message-toaster-element.component.scss'],
})
export class IconMessageToasterElementComponent {
  @Input() iconString?: IconMessageToasterElement;
  IconBackground = IconBackground;
}

<div class="header">
  <p *ngIf="forceScreenShare">{{ 'Record whiteboard to continue' | translate }}</p>
  <ng-container *ngIf="!forceScreenShare">
    <p *ngIf="!shareWhiteboardAgain">
      {{ 'Cloud recording enabled' | translate }}
    </p>
    <p *ngIf="shareWhiteboardAgain">
      {{ 'Record whiteboard?' | translate }}
    </p>
  </ng-container>
  <p *ngIf="!forceScreenShare"></p>
</div>

<div class="body">
  <p>
    <ng-container *ngIf="!forceScreenShare">
      <span *ngIf="!shareWhiteboardAgain">
        {{ 'Would you like to record the whiteboard?' | translate }}
      </span>
      <span *ngIf="shareWhiteboardAgain">
        {{
          'Whiteboard recording was paused while you were sharing another screen. Would you like to continue recording your whiteboard?'
            | translate
        }}
      </span>
    </ng-container>

    <ng-container *ngIf="forceScreenShare">
      <span *ngIf="allowShareAnotherScreen">
        {{ 'Do you need to share another screen?' | translate }}
      </span>

      <span *ngIf="!allowShareAnotherScreen">
        {{
          'For safety & compliance, this institution mandates you record your whiteboard.'
            | translate
        }}
      </span>
    </ng-container>

    <a
      rel="noopener"
      class="link"
      href="https://helpdesk.pencilspaces.com/en/articles/9078352-why-does-my-institution-require-that-i-share-my-current-tab"
      target="_blank"
      >{{ ' Learn More' | translate }}</a
    >

    <ng-container *ngIf="warning !== WarningType.None">
      <div class="warnings">
        <span class="error" *ngIf="warning === WarningType.PrivacyUnavailable">
          <strong>{{ 'Note: ' | translate }}</strong
          >{{
            'Due to browser limitations, chat and the video tiles will still be visible in your recording if they appear on your screen. Please contact support for more information. '
              | translate
          }}
        </span>

        <span *ngIf="warning === WarningType.NotChromium">
          <strong>{{ 'Note: ' | translate }}</strong
          >{{
            'For the best recording experience of the whiteboard, we recommend you use Chrome or Edge browser.'
              | translate
          }}
        </span>
      </div>
    </ng-container>
  </p>

  <div class="actions">
    <app-pencil-button
      *ngIf="!forceScreenShare"
      class="pencil-button white"
      [label]="'Not Now' | translate"
      [icon]="'close'"
      [iconConfig]="{
        type: IconTypes.matIcon
      }"
      (click)="closeModal()"
    >
    </app-pencil-button>

    <ng-container *ngIf="forceScreenShare">
      <app-pencil-button
        *ngIf="!allowShareAnotherScreen"
        class="pencil-button white"
        [label]="'More Options' | translate"
        (click)="moreOptions()"
      >
      </app-pencil-button>
      <app-pencil-button
        *ngIf="allowShareAnotherScreen"
        class="pencil-button white"
        [label]="'Share another screen' | translate"
        (click)="shareScreenService.shareScreen()"
      >
      </app-pencil-button>
    </ng-container>

    <app-pencil-button
      class="pencil-button primary medium"
      (click)="shareScreenService.shareScreen({ preferCurrentTab: true })"
      [label]="
        forceScreenShare
          ? ('Record Whiteboard' | translate)
          : ('Yes, Record Whiteboard' | translate)
      "
      [icon]="'stop-circle'"
    >
    </app-pencil-button>
  </div>
</div>

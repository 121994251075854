<div class="wrapper">
  <div class="header">
    {{ 'Are you sure?' | translate }}
  </div>
  <div class="confirm-dialog">
    <div class="msg">
      {{
        'Are you sure you want to end this session? A new session will be created to track activity for users still in this Space'
          | translate
      }}
    </div>
    <div class="confirm-buttons">
      <button class="btn cancel" (click)="finish(false)">
        <svg-icon class="mr-2" [icon]="'close_24px_grey'" [width]="10.5" [height]="10.5"></svg-icon>
        {{ 'Cancel' | translate }}
      </button>
      <button class="btn yes" (click)="finish(true)">
        <svg-icon class="mr-2" [icon]="'stop-circle-white'" [width]="18" [height]="18"></svg-icon>
        {{ 'Confirm & End' | translate }}
      </button>
    </div>
  </div>
</div>

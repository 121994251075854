import { fabric } from 'fabric';
import { filter, first, fromEvent, Subscription } from 'rxjs';
import { AppIText } from './app-i-text';
import {
  BaseCustomFabric,
  BaseCustomFabricData,
  CustomFabricTypes,
  Position,
} from './base-custom-fabric';
import { calculateLines } from './text-utils';

export const STICKY_TEMP_TEXT = 'temp-related-text';
const defaultText = 'Start typing…';

const RECT_MARGIN = 10;
const TEXTBOX_MARGIN = 15;

export interface StickyNoteCustomData extends BaseCustomFabricData {
  left: number;
  top: number;
  height: number;
  width: number;
  text: string;
  remoteDelete: boolean;
  locked: boolean;
}

export class StickyNote extends fabric.Group implements BaseCustomFabric {
  static readonly HEIGHT = 180;
  static readonly WIDTH = 180;
  static readonly TB_WIDTH = 160; // WIDTH - (padding left + padding right)
  static readonly TB_HEIGHT = 160; // WIDTH - (padding top + padding bottom)

  ['custom-fabric-data']?: StickyNoteCustomData;
  canDeleteWithEraser = false;
  customObjectType: string = CustomFabricTypes.STICKY_NOTE;
  text = defaultText;
  remoteDelete = false;
  locked = false;
  isActive = false;
  fresh: boolean | undefined = undefined;
  isRealtimeTextUpdateFlagEnabled = false;
  private eventSubscriptions: Subscription[] = [];

  constructor(options: fabric.IGroupOptions = {}) {
    super(
      [
        new fabric.Rect({
          height: StickyNote.HEIGHT,
          width: StickyNote.WIDTH,
          fill: '#ffe9a7',
          shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        }),
      ],
      {
        ...options,
        hasControls: false,
        hasRotatingPoint: false,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
      },
    );

    this.addWithUpdate(
      new StickyIText(this, {
        width: StickyNote.TB_WIDTH,
        height: StickyNote.TB_HEIGHT,
        top: (options.top as number) + RECT_MARGIN,
        left: (options.left as number) + RECT_MARGIN,
        fontFamily: 'Source Sans Pro',
        fontSize: 12,
        padding: 20,
        backgroundColor: 'transparent',
        shadow: '',
        fill: 'black',
        fontWeight: '400',
        editable: true,
        selectable: true,
        hasBorders: false,
        hasControls: false,
        splitByGrapheme: true,
      }),
    );
  }

  lockObject(): void {
    if (this.relatedTextBox) {
      this.relatedTextBox.exitEditing();
      this.relatedTextBox.remove(this.canvas as fabric.Canvas);
    }

    this.lockMovementX = true;
    this.lockMovementY = true;
  }

  unlockObject(): void {
    this.lockMovementX = false;
    this.lockMovementY = false;
  }

  fromObject(object: fabric.Object): fabric.Object {
    // object doesn't have the field or field value is null/undefined
    if (
      !(BaseCustomFabric.CUSTOM_FABRIC_DATA in object) ||
      !object[BaseCustomFabric.CUSTOM_FABRIC_DATA]
    ) {
      return object;
    }

    const { left, top, height, width, text, remoteDelete, locked } = object[
      BaseCustomFabric.CUSTOM_FABRIC_DATA
    ] as StickyNoteCustomData;
    const note = new StickyNote({
      left: object.left && object.left > 0 ? object.left : left,
      top: object.top && object.top > 0 ? object.top : top,
      selectable: true,
      subTargetCheck: true,
      padding: 0,
      hasBorders: true,
    });
    note.text = text;
    note.remoteDelete = remoteDelete;
    note.locked = locked;

    Object.entries(object).forEach(([key, value]) => {
      if (!['top', 'left', 'text'].includes(key)) {
        note.textBox.set(key as keyof StickyIText, value);
      }
      if (key === 'text') {
        note.textBox.set(key as keyof StickyIText, text);
      }
    });

    note.textBox.visible = true;
    note.textBox._textLines = note.textBox._wrapText([], StickyNote.TB_WIDTH);

    const calculatedHeight = note.textBox.calculateHeightFromLines();
    note.rect.set(
      'height',
      calculatedHeight + RECT_MARGIN > StickyNote.HEIGHT
        ? height + TEXTBOX_MARGIN
        : StickyNote.HEIGHT,
    );
    note.rect.set('width', width);

    note.addWithUpdate();
    return note;
  }

  toObject(args: string[] = []): fabric.Object {
    const object: any = this.textBox;
    const { height, width } = this.rect;
    object[BaseCustomFabric.CUSTOM_FABRIC_DATA] = {
      top: this.top,
      left: this.left,
      height,
      width,
      text: this.text,
      remoteDelete: this.remoteDelete,
      locked: this.locked,
    };

    object[BaseCustomFabric.ATTR_FIELD_NAME] = this.customObjectType;
    object['text'] = this.text;
    return object;
  }

  setTopLeftPosition(
    object: fabric.Object,
    mouseLocation: { x: number; y: number },
    selectionCenter: { x: number; y: number },
  ): fabric.Object {
    if (
      BaseCustomFabric.CUSTOM_FABRIC_DATA in object &&
      object[BaseCustomFabric.CUSTOM_FABRIC_DATA]
    ) {
      const customData = object[BaseCustomFabric.CUSTOM_FABRIC_DATA] as StickyNoteCustomData;

      const diffToCenter = {
        x: customData.left - selectionCenter.x,
        y: customData.top - selectionCenter.y,
      };
      customData.left = (mouseLocation?.x || 0) + diffToCenter.x;
      customData.top = (mouseLocation?.y || 0) + diffToCenter.y;
      object[BaseCustomFabric.CUSTOM_FABRIC_DATA] = customData;
    }

    return object;
  }
  getObjectAbsolutePosition(): Position {
    if (this[BaseCustomFabric.CUSTOM_FABRIC_DATA]) {
      const stickyNoteCustomData = this[
        BaseCustomFabric.CUSTOM_FABRIC_DATA
      ] as StickyNoteCustomData;
      return {
        top: stickyNoteCustomData['top'],
        left: stickyNoteCustomData['left'],
        height: stickyNoteCustomData['height'],
        width: stickyNoteCustomData['width'],
      };
    }
    return {
      top: this.top as number,
      left: this.left as number,
      height: this.height as number,
      width: this.width as number,
    };
  }

  onRemoteModify(object: fabric.Object): fabric.Object {
    const { left, top, height, width, text, remoteDelete, locked } = (object as StickyNote)[
      BaseCustomFabric.CUSTOM_FABRIC_DATA
    ] as StickyNoteCustomData;
    this.left = object.left && object.left > 0 ? object.left : left;
    this.top = object.top && object.top > 0 ? object.top : top;
    this.text = text;
    this.locked = locked;
    this.remoteDelete = remoteDelete;

    this.rect.set('height', height);
    this.rect.set('width', width);

    Object.entries(object).forEach(([key, value]) => {
      if (!['top', 'left', 'text', 'visible'].includes(key)) {
        this.textBox.set(key as keyof StickyIText, value);
      }
      if (key === 'text') {
        this.textBox.set(key as keyof StickyIText, text);
      }
    });

    this.textBox._textLines = this.textBox._wrapText([], StickyNote.TB_WIDTH);

    this.relatedTextBox?.set('text', text);
    this.relatedTextBox?.cancelEditing(this.canvas as fabric.Canvas);
    return this;
  }

  public static removeAllRelatedTextBoxes(canvas: fabric.Canvas): void {
    canvas
      .getObjects()
      .filter((obj) => obj instanceof RelatedIText)
      .forEach((obj) => {
        (obj as RelatedIText).exitEditing();
        (obj as RelatedIText).remove(canvas);
      });
  }

  createRelatedTextBox(canvas: fabric.Canvas, isRealtimeTextUpdateFlagEnabled: boolean) {
    this.isRealtimeTextUpdateFlagEnabled = isRealtimeTextUpdateFlagEnabled;
    if (this.locked) {
      this.relatedTextBox?.remove(canvas);
      return;
    }

    if (this.relatedTextBox && canvas.getObjects().includes(this.relatedTextBox)) {
      if (canvas.getActiveObject() === this.relatedTextBox && this.relatedTextBox.isEditing) {
        return;
      }
      this.isActive = true;
      this.relatedTextBox.enterEditing();
      return;
    }

    canvas.bringToFront(this);

    StickyNote.removeAllRelatedTextBoxes(canvas);
    this.textBox.relatedTextBox = new RelatedIText(this.textBox);
    canvas.add(this.textBox.relatedTextBox);
    canvas.bringToFront(this.textBox.relatedTextBox);
    canvas.discardActiveObject();
    canvas.setActiveObject(this.textBox.relatedTextBox);

    this.textBox.relatedTextBox.enterEditing();
    // the following code will put the cursor at the end of the text
    this.textBox.relatedTextBox.setSelectionStart(
      (this.textBox.relatedTextBox.text as string).length,
    );
    this.textBox.relatedTextBox.setSelectionEnd(
      (this.textBox.relatedTextBox.text as string).length,
    );

    this.textBox.visible = false;
    this.rect.stroke = '#F0F4FF';
    this.rect.strokeWidth = 1;

    this.subscribeToEvents(canvas);

    if (isRealtimeTextUpdateFlagEnabled) {
      this.relatedTextBox?.on('changed', () => {
        this.textBox.visible = true;
        this.relatedTextBox?.updateText(canvas);
      });
    }

    canvas.requestRenderAll();
  }

  subscribeToEvents(canvas: fabric.Canvas): void {
    this.eventSubscriptions.push(
      fromEvent(canvas, 'object:moving')
        .pipe(
          filter((e) => e.target === this),
          first(),
        )
        .subscribe(() => {
          this.relatedTextBox?.remove(canvas);
          canvas.setActiveObject(this);
          canvas.requestRenderAll();
        }),
      fromEvent(canvas, 'selection:updated').subscribe((event) => {
        if (event.target !== this.relatedTextBox) {
          this.relatedTextBox?.remove(canvas);
          canvas.requestRenderAll();
        }
      }),
      fromEvent(canvas, 'selection:cleared').subscribe((event) => {
        if (event.target !== this.relatedTextBox) {
          this.relatedTextBox?.remove(canvas);
          canvas.requestRenderAll();
        }
      }),
    );
  }

  unsubscribeFromEvents(): void {
    this.eventSubscriptions.forEach((subscription) => subscription.unsubscribe());
    this.eventSubscriptions = [];
  }

  equals(stickyNote: StickyNote): boolean {
    return this.uid === stickyNote.uid;
  }

  get textBox(): StickyIText {
    return this._objects.find((item) => item.type === 'textbox') as StickyIText;
  }

  get rect(): fabric.Rect {
    return this._objects.find((item) => item.type === 'rect') as fabric.Rect;
  }

  get relatedTextBox(): RelatedIText | undefined {
    return this.textBox.relatedTextBox;
  }

  get uid(): string {
    return (this.textBox as StickyIText & { uid: string })['uid'];
  }
}
export class StickyIText extends fabric.Textbox {
  relatedTextBox?: RelatedIText;
  fresh: boolean | undefined = undefined;

  constructor(public parent: StickyNote, options?: fabric.ITextboxOptions) {
    super(parent.text, options);
  }

  _splitText(): { _unwrappedLines: any; lines: any; graphemeText: any; graphemeLines: any } {
    const context = this.getMeasuringContext();
    context.font = `${this.fontSize}px ${this.fontFamily}`;
    const result = calculateLines(this.text as string, context, StickyNote.TB_WIDTH);
    const lines = result.map(({ line }) => line);
    const newLines = {
      _unwrappedLines: lines.map((line) => line.split('')),
      lines,
      graphemeText: this.text?.split(''),
      graphemeLines: lines.map((line) => line.split('')),
    };
    this.textLines = newLines.lines;
    this._textLines = newLines.graphemeLines;
    this._unwrappedTextLines = newLines._unwrappedLines;
    this._text = newLines.graphemeText as string[];
    return newLines;
  }

  calculateHeightFromLines(): number {
    let height = 0;
    this.textLines.forEach((line, index) => {
      height += this.getHeightOfLine(index);
    });

    this.set('height', height);

    return height;
  }
}
export class RelatedIText extends AppIText {
  [STICKY_TEMP_TEXT]: boolean;
  constructor(public textBox: StickyIText) {
    super(textBox.text === defaultText ? '' : (textBox.text as string), {
      width: StickyNote.TB_WIDTH,
      height: StickyNote.TB_HEIGHT,
      top: (textBox.parent.top as number) + RECT_MARGIN,
      left: (textBox.parent.left as number) + RECT_MARGIN,
      padding: 20,
      backgroundColor: 'transparent',
      fontFamily: 'Source Sans Pro',
      fontSize: 12,
      fontWeight: '400',
      fill: 'black',
      textAlign: 'left',
      editable: true,
      selectable: false,
      hasBorders: false,
      hasControls: false,
      hasRotatingPoint: false,
      cornerStrokeColor: 'red',
      excludeFromExport: true,
      lockMovementX: true,
      lockMovementY: true,
    });

    this[STICKY_TEMP_TEXT] = true;
    this.controls = {};
    this.wrapText(StickyNote.TB_WIDTH);
    this.calculateHeightFromLines();
  }

  calculateHeightFromLines(): number {
    let height = 0;
    this.textLines.forEach((line, index) => {
      height += this.getHeightOfLine(index);
    });

    this.set('height', height);

    return height;
  }

  refresh(canvas: fabric.Canvas): void {
    this.wrapText(StickyNote.TB_WIDTH);
    const height = this.calculateHeightFromLines();
    this.textBox.parent.rect.set(
      'height',
      height + RECT_MARGIN > StickyNote.HEIGHT ? height + TEXTBOX_MARGIN : StickyNote.HEIGHT,
    );

    this.textBox.set('height', height);
    this.textBox.parent.addWithUpdate();
    canvas.requestRenderAll();
  }

  updateText(canvas: fabric.Canvas): void {
    const fireUpdate = this.text !== this.textBox.text;
    this.refresh(canvas);
    const text = this.text || defaultText;
    this.textBox.text = text;
    this.textBox._wrapText([], StickyNote.TB_WIDTH);
    this.textBox.parent.text = text;
    if (fireUpdate) {
      canvas.fire('object:modified', { target: this.textBox.parent });
    }
  }

  unlockObject(): void {
    this.textBox.visible = true;
    this.textBox.parent.isActive = false;
    this.textBox.parent.rect.strokeWidth = 0;
    this.textBox.parent.unsubscribeFromEvents();
  }

  removeFromCanvas(canvas: fabric.Canvas): void {
    this.off('changed');
    canvas.remove(this);
    this.textBox.relatedTextBox = undefined;
    this.textBox.parent.addWithUpdate();
    canvas.requestRenderAll();
  }

  cancelEditing(canvas: fabric.Canvas): void {
    this.unlockObject();

    this.removeFromCanvas(canvas);
  }

  remove(canvas: fabric.Canvas): void {
    this.unlockObject();
    this.updateText(canvas);
    this.removeFromCanvas(canvas);
  }
}
StickyNote.prototype.lockRotation = true;
StickyIText.prototype.lockRotation = true;
RelatedIText.prototype.lockRotation = true;

StickyIText.prototype._wrapText = function (_, desiredWidth) {
  const context = this.getMeasuringContext();
  context.font = `${this.fontSize}px ${this.fontFamily}`;
  const lines = calculateLines(this.text as string, context, desiredWidth);
  return lines.map((l) => l.line.split(''));
};

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDrawerContent } from '@angular/material/sidenav';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { lastValueFrom, take } from 'rxjs';
import { IAnalyticsInsight, SessionStatus } from '../models/analytics';
import { SpacesService } from '../services/spaces.service';
import { ISpaceUI, SpaceRepository } from '../state/space.repository';
import { FLAGS, FlagsService } from '../services/flags.service';
import { ISession } from '../models/session';
import { modifiedSetTimeout } from '../utilities/ZoneUtils';
import { UserService } from '../services/user.service';
import {
  IAnalyticsInsightParsed,
  IHandleEndSession,
  formatAndAugmentSessionFields,
} from './session_analytics_utils';

interface AllSessionsStats {
  totalPages: number;
  pageSize: number;
  currentPageNumber: number;
}

enum AnalyticTab {
  ANALYTICS = 'Analytics',
  EVENTS = 'Events',
  PLAYBACK = 'Playback',
}

@UntilDestroy()
@Component({
  selector: 'app-session-analytics',
  templateUrl: './session-analytics.component.html',
  styleUrls: ['./session-analytics.component.scss'],
})
export class SessionAnalyticsComponent implements OnInit {
  @Input() currentSpace?: ISession & ISpaceUI;
  @ViewChild('sidebarContent') sidebarContent?: MatDrawerContent;
  @Output() closed = new EventEmitter<void>();
  AnalyticTab = AnalyticTab;
  currentSession?: IAnalyticsInsightParsed;
  allSessions: Array<IAnalyticsInsightParsed> = [];
  currentTab = AnalyticTab.ANALYTICS;
  firstRequest = true;
  fetchingMoreSessions = false;
  cloudRecordingEnabled: boolean;
  sessionPaginationData: AllSessionsStats = {
    totalPages: 0,
    currentPageNumber: 0,
    pageSize: 25,
  };
  currentSpaceName?: string;
  totalRecords?: number;
  constructor(
    private spacesService: SpacesService,
    private spaceRepository: SpaceRepository,
    private flagsService: FlagsService,
    private userService: UserService,
  ) {
    this.cloudRecordingEnabled = this.flagsService.isFlagEnabled(FLAGS.CLOUD_RECORDING);
  }

  async ngOnInit() {
    if (this.spaceRepository.activeSpace) {
      this.currentSpace = this.spaceRepository.activeSpace;
      /* On opening analytics from space details
         Institution object isn't populated for current space
         like when opened from inside the spaces using avtive
        space , a call is made to get institution for enterprise
        spaces to check on paywall setting for host flag
      **/
    } else if (this.currentSpace?.institutionID) {
      this.userService
        .getInstitutionDetails(this.currentSpace.institutionID)
        .pipe(take(1))
        .subscribe((institution) => {
          this.currentSpace!.institution = institution;
        });
    }
    this.currentSpaceName = this.currentSpace?.title;
    if (!this.currentSpace) {
      return;
    }
    await this.fetchMoreSessions(this.currentSpace._id);
    modifiedSetTimeout(async () => {
      this.currentSession = this.allSessions[0];
      if (!this.sidebarContent) {
        return;
      }
      this.sidebarContent
        .elementScrolled()
        .pipe(untilDestroyed(this))
        .subscribe(async () => {
          if (!this.sidebarContent || !this.currentSpace || this.fetchingMoreSessions) {
            return;
          }
          const windowHeight = window.innerHeight;
          const drawerContentHeight =
            this.sidebarContent.getElementRef().nativeElement.scrollHeight;
          const isAtBottom =
            this.sidebarContent.getElementRef().nativeElement.scrollTop + windowHeight >=
            drawerContentHeight;
          if (isAtBottom) {
            await this.fetchMoreSessions(this.currentSpace._id);
          }
        });
    });
  }

  async fetchMoreSessions(spaceId: string) {
    if (
      this.sessionPaginationData.totalPages === this.sessionPaginationData.currentPageNumber &&
      this.sessionPaginationData.totalPages !== 0
    ) {
      return;
    }
    this.fetchingMoreSessions = true;
    const res = await lastValueFrom(
      this.spacesService.getSessionBySpace(
        spaceId,
        this.sessionPaginationData.pageSize,
        ++this.sessionPaginationData.currentPageNumber,
      ),
    );
    if (this.firstRequest) {
      this.sessionPaginationData.totalPages = res.totalPages;
      this.firstRequest = false;
    }
    this.allSessions = this.filterSessions([
      ...this.allSessions,
      ...res.sessions.map((session) => this.parseSession(session)),
    ]);
    this.totalRecords = res.totalRecords;
    this.fetchingMoreSessions = false;
  }

  handleCurrentSession(id: string): void {
    this.currentSession = this.allSessions.find((session) => session.sessionId === id);
    this.currentTab = AnalyticTab.ANALYTICS;
  }

  async handleEndSession(res: IHandleEndSession): Promise<void> {
    if (!this.currentSpace) {
      return;
    }
    const { endedSessionId, newSessionId, newSession, endedSession } = res;
    if (!endedSession.sessionEndTime) {
      endedSession.status = SessionStatus.PROCESSING;
    }
    this.allSessions = [
      ...(newSession ? [this.parseSession(newSession)] : []),
      this.parseSession(endedSession),
      ...this.allSessions.filter(
        (session) =>
          session.sessionId !== newSessionId &&
          session.sessionId !== endedSessionId &&
          session.status === SessionStatus.PROCESSED,
      ),
    ];
    this.updateCurrentSession();
    this.fetchSessionUntilProcessed(endedSessionId);
  }

  fetchSessionUntilProcessed(sessionId: string) {
    const fetchSessionsInterval = modifiedSetTimeout(async () => {
      const endedSession = await lastValueFrom(
        this.spacesService.getAnalyticsSessionById(sessionId),
      );
      if (endedSession.sessionEndTime) {
        const endedSessionIndex = this.allSessions.findIndex(
          (session) => session.sessionId === endedSession.sessionId,
        );
        this.allSessions[endedSessionIndex] = this.parseSession(endedSession);
        this.allSessions = [...this.allSessions];
        this.updateCurrentSession();
        clearInterval(fetchSessionsInterval);
      }
    }, 3000);
  }

  handleSessionTitleChanged(event: any) {
    const { sessionId, sessionTitle } = event;
    this.allSessions = [...this.allSessions];
    const sessionIdx = this.allSessions.findIndex((session) => session.sessionId === sessionId);
    this.allSessions[sessionIdx].sessionTitle = sessionTitle;
    this.updateCurrentSession();
  }

  updateCurrentSession() {
    this.currentSession = this.allSessions.find(
      (session) => session.sessionId === this.currentSession?.sessionId,
    );
  }

  fetchSession(sessionId: string) {
    this.spacesService
      .getAnalyticsSessionById(sessionId)
      .pipe(untilDestroyed(this))
      .subscribe((sessionRes) => {
        const idx = this.allSessions.findIndex((session) => session.sessionId === sessionId);
        if (idx > -1) {
          this.allSessions[idx] = this.parseSession(sessionRes);
        }
        this.allSessions = [...this.allSessions];
        if (this.currentSession && this.currentSession.sessionId === sessionRes.sessionId) {
          this.updateCurrentSession();
        }
      });
  }

  filterSessions(sessions: Array<IAnalyticsInsightParsed>): IAnalyticsInsightParsed[] {
    return sessions.filter(
      (session, i) =>
        (session.status === SessionStatus.IN_PROGRESS && i === 0) ||
        session.sessionEndTime ||
        (session.status === SessionStatus.PROCESSING && i <= 1),
    );
  }

  parseSession(session: IAnalyticsInsight) {
    return formatAndAugmentSessionFields(session, this.hostDiscountEnabled());
  }
  hostDiscountEnabled(): boolean {
    return !!this.currentSpace?.institution?.paywallConfig?.hostDiscountFlag;
  }
}

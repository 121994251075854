export const intercomArticles = {
  HowToEnableScreenSharingPermissions:
    'https://intercom.help/pencil/en/articles/5949195-how-to-enable-screen-sharing-permissions-on-macos',
  SettingUpYourVideoAndMicrophonePermissionsInSpaces:
    'https://intercom.help/pencil/en/articles/5663303-setting-up-your-video-and-microphone-permissions-in-pencil-spaces',
  TroubleshootingCameraPermissions:
    'https://intercom.help/pencil/en/articles/6308254-troubleshooting-camera-permissions',
  TroubleshootingMicrophoneSpeakerPermissions:
    'https://intercom.help/pencil/en/articles/6153318-troubleshooting-microphone-speaker-permissions',
  WhatToDoWhenYourScreenShareFails:
    'https://intercom.help/pencil/en/articles/6244824-what-to-do-when-your-screen-share-fails',
  HowToChangeYourCameraAndMicrophoneInSpaces:
    'https://intercom.help/pencil/en/articles/5887554-how-to-change-your-camera-and-microphone-in-spaces',
  TroubleLoadingSpacesDueToYourConnectionOrDevicePerformance:
    'https://intercom.help/pencil/en/articles/5886185-trouble-loading-spaces-due-to-your-connection-or-your-device-s-performance',
  CanIJoinASpaceFromMoreThanOneDevice:
    'https://intercom.help/pencil/en/articles/6036786-can-i-join-a-space-from-more-than-one-device',
  BrowsersCompatibleWithPopoutVideo:
    'https://intercom.help/pencil/en/articles/6618040-browsers-compatible-with-pop-out-video',
  GetPreviewStreamsStuckJoinCallPanel:
    'https://intercom.help/pencil/en/articles/6899614-troubleshooting-no-input-from-camera-or-microphone',
  WhatToDoIfYouCantJoinCall:
    'https://intercom.help/pencil/en/articles/6226259-what-to-do-if-you-can-t-join-a-call-in-pencil-spaces',
  WhatToDoIfYourDeviceIsLowOnMemoryOrCpuUsageIsHigh:
    'https://intercom.help/pencil/en/articles/8017002-what-to-if-your-device-is-low-on-memory-if-your-device-s-cpu-usage-is-high',
  WhatDoesItMeanIfTranscriptionIsActiveOnMyCall:
    'https://intercom.help/pencil/en/articles/7967680-what-does-it-mean-if-transcription-is-active-on-my-call',
  WhatDoesItMeanIfRecordingIsActiveInMyCall:
    'https://intercom.help/pencil/en/articles/8272253-what-does-it-mean-if-recording-is-active-in-my-call',
  VirtualBackgroundSupport:
    'https://intercom.help/pencil/en/articles/8392624-which-browsers-devices-support-virtual-background',
  ScreenSharingSupport:
    'https://intercom.help/pencil/en/articles/5695894-screen-sharing-in-pencil-spaces',
  CSVProfileUploadSupport:
    'https://intercom.help/pencil/en/articles/8729639-how-to-upload-users-to-your-institution-via-csv',
  ResetPassword: 'https://intercom.help/pencil/en/articles/6346390-how-to-reset-your-password',
  RecommendedSpecs:
    'https://intercom.help/pencil/en/articles/6152618-recommended-system-requirements-for-pencil-spaces',
  HowManyObjectsICanHaveOnBoard:
    'https://intercom.help/pencil/en/articles/8891097-how-many-objects-can-i-have-on-a-board',
};

export const intercomArticlesIds = {
  SettingUpYourVideoAndMicrophonePermissionsInSpacesId: '5663303',
  TroubleshootingCameraPermissionsId: '6308254',
  TroubleshootingMicrophoneSpeakerPermissionsId: '6153318',
};

<div [ngStyle]="{ height: height + 'px', width: width + 'px' }">
  <canvas
    class="pdf-page-canvas"
    appChromeCanvasBugWorkaround
    [ngStyle]="{ height: height + 'px', width: width + 'px' }"
    [hidden]="(hideCanvas$ | async) || hidePage"
    #canvas
  ></canvas>
  <app-pdf-hidden-page *ngIf="hidePage" [height]="height" [width]="width">
    <p>{{ pdfName | truncateString: 10:true }}</p>
    <p *ngIf="totalPage > 1">Page {{ page?.pageNumber }} of {{ totalPage }}</p>
  </app-pdf-hidden-page>
</div>

import { Component, Input } from '@angular/core';
import { ToastRef } from 'ngx-toastr';
import { ButtonToasterElement } from '../button-toaster-element/button-toaster-element.component';

export enum ToasterElementType {
  BUTTON,
  ICON_STR,
  ERROR,
}

export interface ToasterElement {
  type: ToasterElementType;
}

@Component({
  selector: 'app-toaster-element',
  templateUrl: './toaster-element.component.html',
  styleUrls: ['./toaster-element.component.scss'],
})
export class ToasterElementComponent {
  public notificationElementType = ToasterElementType;
  @Input() notificationElement?: ToasterElement;
  @Input() toastRef?: ToastRef<any>;

  onButtonClick(handler: ButtonToasterElement['onClick']) {
    handler?.handler?.();
    if (handler?.close) {
      this.toastRef?.manualClose();
    }
  }
}

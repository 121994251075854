<div class="settings-panel">
  <header class="sites-management-header">
    <h1 class="title">{{ panelData.name }}</h1>
    <p class="description">
      {{ panelData.description }}
    </p>
  </header>
  <main class="sites-management-main">
    <ng-container *ngIf="isSitesEnabled; else sitesDisabled">
      <app-pencil-button
        class="pencil-button medium white add-site-btn"
        [icon]="'add'"
        [iconConfig]="{ type: IconTypes.matIcon }"
        [label]="'Add site' | translate"
        (clickEvent)="openAddSiteDialog()"
      ></app-pencil-button>
      <table class="sites-table">
        <thead>
          <tr>
            <th>{{ 'Name' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="isLoading$ | async; else dataContent">
            <ng-container *ngTemplateOutlet="loadingContent"></ng-container>
          </ng-container>
        </tbody>
      </table>
    </ng-container>

    <ng-template #loadingContent>
      <tr *ngFor="let i of skeletonBars">
        <td><div class="skeleton-bar"></div></td>
      </tr>
    </ng-template>

    <ng-template #dataContent>
      <tr class="site-row" *ngFor="let site of sites">
        <td>
          <span class="site-name">{{ site.name }}</span>
          <div class="site-actions">
            <app-pencil-icon
              class="site-action"
              name="edit"
              [type]="IconTypes.materialIcon"
              [outlined]="true"
              (click)="openEditSiteDialog(site)"
              [appTooltip]="editAttributeTooltip"
              [appTooltipArrow]="'bottom'"
              [appTooltipPlacement]="'top-center'"
            ></app-pencil-icon>
            <ng-template #editAttributeTooltip>
              <app-tooltip [text]="'Edit site' | translate" [arrow]="'bottom'"></app-tooltip>
            </ng-template>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template #sitesDisabled>
      <div class="sites-disabled-content">
        <p class="site-disabled-message">
          {{
            'Sites are not enabled for this institution. Please contact 24/7 Live Support to enable sites.'
              | translate
          }}
        </p>
        <app-pencil-button
          class="pencil-button medium primary contact-support-btn"
          [icon]="'chat_bubble_outline'"
          [iconConfig]="{ type: IconTypes.matIcon }"
          [label]="'Contact Support' | translate"
          (clickEvent)="openIntercom()"
        ></app-pencil-button>
      </div>
    </ng-template>
  </main>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkedTopicNum',
})
export class CheckedTopicNumPipe implements PipeTransform {
  transform(value: any, topic): string {
    if (!topic.child || !topic.isChildChecked) {
      return '';
    }
    let isChildStarted = false;
    let childCheckedNum = 0;
    for (let i = 0 ; i < value.topics.length ; i ++) {
      const t = value.topics[i];
      if (t.parent.includes(topic._id)) {
        if (!isChildStarted) {
          isChildStarted = true;
        }
        if (value.selectedIds && value.selectedIds.includes(t._id)) {
          childCheckedNum ++;
        }
      }
      if (isChildStarted && !t.parent.includes(topic._id)) {
        break;
      }
    }
    return childCheckedNum > 0 ? `(${childCheckedNum})` : '';
  }
}

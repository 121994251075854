import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IconTypes } from 'src/app/standalones/components/pencil-icon/pencil-icon.component';

@Component({
  selector: 'app-rename-space',
  templateUrl: './rename-space.component.html',
  styleUrls: ['./rename-space.component.scss'],
})
export class RenameSpaceComponent {
  disabled = true;
  maxAllowedCharacters = 100;

  constructor(
    private dialogRef: MatDialogRef<RenameSpaceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  finish(title = this.data.title): void {
    this.dialogRef.close({ title: title });
  }

  protected readonly IconTypes = IconTypes;
}

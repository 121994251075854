import { Component } from '@angular/core';

@Component({
  selector: 'ui-chip',
  template: '<ng-content></ng-content>',
  styles: [
    `:host {
        border-radius: 4px;
        margin-right: 5px;
        padding: 8px;
        min-height: 24px;
        color: #5A6A73;
        display: inline-flex;
        align-items: center;
        border: thin solid #ddd;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
    }`,
  ],
})
export class ChipComponent {
}

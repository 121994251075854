import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, pluck } from 'rxjs/operators';
import { Context } from '../models/context';
import { UserInfo, UserOverview } from '../models/user';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class MentionService {
  constructor(private userService: UserService) {}

  enableMentions(context: Context): boolean {
    return Boolean(context.question || context.note || context.worksheet);
  }

  /* Eventually this will need information about context and ACL, in order to
   * determine which users should be included in the list.
   */
  findMatchingUsers(searchTerm: string): Observable<UserInfo[]> {
    return this.userService.allUsers.pipe(
      filter((userOverview): userOverview is UserOverview => userOverview !== null),
      pluck('profiles'),
      map((allUserInfo) =>
        allUserInfo.filter((userInfo) =>
          userInfo.name.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      ),
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '@sentry/browser';
import { Notification, NotificationExtended } from '../models/global.interface';
import { Months } from '../consts';
import { URLService } from './dynamic-url.service';

export enum MessageContentType {
  Text = 'TEXT',
  Image = 'IMAGE',
  Video = 'VIDEO',
  Equation = 'EQUATION',
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  notificationCount = new BehaviorSubject(0);

  constructor(private http: HttpClient, private urlService: URLService) {}

  getNotificationsCount() {
    return this.http.get(`${this.urlService.getDynamicUrl()}/tutor/notifications/count`);
  }

  getNotifications(page = 1) {
    return this.http.get(`${this.urlService.getDynamicUrl()}/tutor/notifications?page=${page}`);
  }

  archiveNotification(id) {
    return this.http.patch(
      `${this.urlService.getDynamicUrl()}/tutor/notifications/archive/${id}`,
      {},
    );
  }

  unarchiveNotification(id) {
    return this.http.patch(
      `${this.urlService.getDynamicUrl()}/tutor/notifications/unarchive/${id}`,
      {},
    );
  }

  mapNotificationsByDate(
    _notifications: Notification[],
    currentDate?: string,
    loadMore = false,
    attachDatesToAll = false,
  ): {
      notifications: NotificationExtended[];
      currentDate?: string;
    } {
    if (!_notifications) {
      return { notifications: [], currentDate };
    }
    let earlier = false;

    const notifications = _notifications.map((notification) => {
      const genDate = this.generateDate(notification, loadMore);
      /**
       * attach time label to notification object only when day changes
       * so only May 25, than May 26 etc. And if flag to passing dates
       * to all objects is passed (e.x. from notifications menu )
       */
      if (!currentDate || currentDate !== genDate) {
        if (!loadMore) {
          if ((currentDate === 'Today' || !currentDate) && genDate !== 'Today') {
            earlier = true;
          } else {
            earlier = false;
          }
        }

        currentDate = genDate;
        return { ...notification, timeLabel: genDate, earlier };
      } else if (attachDatesToAll) {
        return { ...notification, timeLabel: genDate };
      }

      return notification;
    });
    return { notifications, currentDate };
  }

  getUnreadNotifications(notifications: Notification[], user: User): Notification[] {
    /**
     * here we filter unread notifications from all as we don't have separate endpoint
     * so only if user viewed time is less then notifications it means user hasn't seen it
     */
    return notifications.filter(
      (notification) =>
        new Date(user.last_notifications_viewed_timestamp).getTime() <
        new Date(notification.createdAt).getTime(),
    );
  }

  generateDate(notification: Notification, loadMore: boolean): string {
    /**
     * this function formats data depending depending on how new notification is
     * so we can have today, yesterday or exact date after that point
     */
    const yesterday = this.getYesterday(new Date());
    const genTimestamp = new Date(notification.createdAt);
    const formatedGenTimestamp = `${genTimestamp.getMonth()} ${genTimestamp.getDate()} ${genTimestamp.getFullYear()}`;
    const newDate = new Date();
    const today = `${newDate.getMonth()} ${newDate.getDate()} ${newDate.getFullYear()}`;
    let genDate = `${Months[genTimestamp.getMonth()]} ${genTimestamp.getDate()}`;

    if (!loadMore) {
      if (today === formatedGenTimestamp) {
        // eslint-disable-next-line frontend-rules/ngx-translate-service
        genDate = 'Today';
      } else if (yesterday === formatedGenTimestamp) {
        // eslint-disable-next-line frontend-rules/ngx-translate-service
        genDate = 'Yesterday';
      }
    }

    return genDate;
  }

  getYesterday(targetDate: Date | string): string {
    const date = new Date(targetDate);
    const yesterday = new Date(date.setDate(date.getDate() - 1));
    return `${yesterday.getMonth()} ${yesterday.getDate()} ${yesterday.getFullYear()}`;
  }
}

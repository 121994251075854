export class pastExamData {
  static schools = [
    'ADMIRALTY PRIMARY SCHOOL',
    'AHMAD IBRAHIM PRIMARY SCHOOL',
    'AI TONG SCHOOL',
    'ALEXANDRA PRIMARY SCHOOL',
    'ANCHOR GREEN PRIMARY SCHOOL',
    'ANDERSON PRIMARY SCHOOL',
    'ANG MO KIO PRIMARY SCHOOL',
    'ANGLO-CHINESE SCHOOL (JUNIOR)',
    'ANGLO-CHINESE SCHOOL (PRIMARY)',
    'ANGSANA PRIMARY SCHOOL',
    'BALESTIER HILL PRIMARY SCHOOL',
    'BEACON PRIMARY SCHOOL',
    'BEDOK GREEN PRIMARY SCHOOL',
    'BENDEMEER PRIMARY SCHOOL',
    'BLANGAH RISE PRIMARY SCHOOL',
    'BOON LAY GARDEN PRIMARY SCHOOL',
    'BUKIT PANJANG PRIMARY SCHOOL',
    'BUKIT TIMAH PRIMARY SCHOOL',
    'BUKIT VIEW PRIMARY SCHOOL',
    'CANBERRA PRIMARY SCHOOL',
    'CANOSSA CONVENT PRIMARY SCHOOL',
    'CANTONMENT PRIMARY SCHOOL',
    'CASUARINA PRIMARY SCHOOL',
    'CEDAR PRIMARY SCHOOL',
    'CHANGKAT PRIMARY SCHOOL',
    'CHIJ (KATONG) PRIMARY',
    'CHIJ (KELLOCK)',
    'CHIJ OUR LADY OF GOOD COUNSEL',
    'CHIJ OUR LADY OF THE NATIVITY',
    'CHIJ OUR LADY QUEEN OF PEACE',
    'CHIJ PRIMARY (TOA PAYOH)',
    'CHONGFU SCHOOL',
    'CHONGZHENG PRIMARY SCHOOL',
    'CHUA CHU KANG PRIMARY SCHOOL',
    'CLEMENTI PRIMARY SCHOOL',
    'COMPASSVALE PRIMARY SCHOOL',
    'CONCORD PRIMARY SCHOOL',
    'CORAL PRIMARY SCHOOL',
    'CORPORATION PRIMARY SCHOOL',
    'DA QIAO PRIMARY SCHOOL',
    'DAMAI PRIMARY SCHOOL',
    'DAZHONG PRIMARY SCHOOL',
    'DE LA SALLE SCHOOL',
    'EAST COAST PRIMARY SCHOOL',
    'EAST SPRING PRIMARY SCHOOL',
    'EAST VIEW PRIMARY SCHOOL',
    'EDGEFIELD PRIMARY SCHOOL',
    'ELIAS PARK PRIMARY SCHOOL',
    'ENDEAVOUR PRIMARY SCHOOL',
    'EUNOIA JUNIOR COLLEGE',
    'EUNOS PRIMARY SCHOOL',
    'EVERGREEN PRIMARY SCHOOL',
    'FAIRFIELD METHODIST SCHOOL (PRIMARY)',
    'FARRER PARK PRIMARY SCHOOL',
    'FENGSHAN PRIMARY SCHOOL',
    'FERNVALE PRIMARY SCHOOL',
    'FIRST TOA PAYOH PRIMARY SCHOOL',
    'FRONTIER PRIMARY SCHOOL',
    'FUCHUN PRIMARY SCHOOL',
    'FUHUA PRIMARY SCHOOL',
    'GAN ENG SENG PRIMARY SCHOOL',
    'GEYLANG METHODIST SCHOOL (PRIMARY)',
    'GONGSHANG PRIMARY SCHOOL',
    'GREENDALE PRIMARY SCHOOL',
    'GREENRIDGE PRIMARY SCHOOL',
    'GREENWOOD PRIMARY SCHOOL',
    'GUANGYANG PRIMARY SCHOOL',
    "HAIG GIRLS' SCHOOL",
    'HENRY PARK PRIMARY SCHOOL',
    "HOLY INNOCENTS' PRIMARY SCHOOL",
    'HONG WEN SCHOOL',
    'HORIZON PRIMARY SCHOOL',
    'HOUGANG PRIMARY SCHOOL',
    'HUAMIN PRIMARY SCHOOL',
    'INNOVA PRIMARY SCHOOL',
    'JIEMIN PRIMARY SCHOOL',
    'JING SHAN PRIMARY SCHOOL',
    'JUNYUAN PRIMARY SCHOOL',
    'JURONG PRIMARY SCHOOL',
    'JURONG WEST PRIMARY SCHOOL',
    'JUYING PRIMARY SCHOOL',
    'KEMING PRIMARY SCHOOL',
    'KHENG CHENG SCHOOL',
    'KONG HWA SCHOOL',
    'KRANJI PRIMARY SCHOOL',
    'KUO CHUAN PRESBYTERIAN PRIMARY SCHOOL',
    'LAKESIDE PRIMARY SCHOOL',
    'LIANHUA PRIMARY SCHOOL',
    'LOYANG PRIMARY SCHOOL',
    'MACPHERSON PRIMARY SCHOOL',
    'MAHA BODHI SCHOOL',
    'MARSILING PRIMARY SCHOOL',
    'MARYMOUNT CONVENT SCHOOL',
    'MAYFLOWER PRIMARY SCHOOL',
    'MEE TOH SCHOOL',
    'MERIDIAN PRIMARY SCHOOL',
    'MONTFORT JUNIOR SCHOOL',
    'NAN CHIAU PRIMARY SCHOOL',
    'NAN HUA PRIMARY SCHOOL',
    'NANYANG PRIMARY SCHOOL',
    'NAVAL BASE PRIMARY SCHOOL',
    'NEW TOWN PRIMARY SCHOOL',
    'NGEE ANN PRIMARY SCHOOL',
    'NORTH SPRING PRIMARY SCHOOL',
    'NORTH VIEW PRIMARY SCHOOL',
    'NORTH VISTA PRIMARY SCHOOL',
    'NORTHLAND PRIMARY SCHOOL',
    'NORTHOAKS PRIMARY SCHOOL',
    'OASIS PRIMARY SCHOOL',
    'OPERA ESTATE PRIMARY SCHOOL',
    'PALM VIEW PRIMARY SCHOOL',
    'PARK VIEW PRIMARY SCHOOL',
    'PASIR RIS PRIMARY SCHOOL',
    "PAYA LEBAR METHODIST GIRLS' SCHOOL (PRIMARY)",
    'PEI CHUN PUBLIC SCHOOL',
    'PEI HWA PRESBYTERIAN PRIMARY SCHOOL',
    'PEI TONG PRIMARY SCHOOL',
    'PEIYING PRIMARY SCHOOL',
    'PIONEER PRIMARY SCHOOL',
    'POI CHING SCHOOL',
    'PRINCESS ELIZABETH PRIMARY SCHOOL',
    'PUNGGOL COVE PRIMARY SCHOOL',
    'PUNGGOL GREEN PRIMARY SCHOOL',
    'PUNGGOL PRIMARY SCHOOL',
    'PUNGGOL VIEW PRIMARY SCHOOL',
    'QIFA PRIMARY SCHOOL',
    'QIHUA PRIMARY SCHOOL',
    'QUEENSTOWN PRIMARY SCHOOL',
    'RADIN MAS PRIMARY SCHOOL',
    "RAFFLES GIRLS' PRIMARY SCHOOL",
    'RED SWASTIKA SCHOOL',
    'RIVER VALLEY PRIMARY SCHOOL',
    'RIVERSIDE PRIMARY SCHOOL',
    'RIVERVALE PRIMARY SCHOOL',
    'ROSYTH SCHOOL',
    'RULANG PRIMARY SCHOOL',
    'SEMBAWANG PRIMARY SCHOOL',
    'SENG KANG PRIMARY SCHOOL',
    'SENGKANG GREEN PRIMARY SCHOOL',
    'SHUQUN PRIMARY SCHOOL',
    'SI LING PRIMARY SCHOOL',
    'SOUTH VIEW PRIMARY SCHOOL',
    'SPRINGDALE PRIMARY SCHOOL',
    "ST. ANDREW'S JUNIOR SCHOOL",
    "ST. ANTHONY'S CANOSSIAN PRIMARY SCHOOL",
    "ST. ANTHONY'S PRIMARY SCHOOL",
    "ST. GABRIEL'S PRIMARY SCHOOL",
    "ST. HILDA'S PRIMARY SCHOOL",
    "ST. JOSEPH'S INSTITUTION JUNIOR",
    "ST. MARGARET'S PRIMARY SCHOOL",
    "ST. STEPHEN'S SCHOOL",
    'STAMFORD PRIMARY SCHOOL',
    'TAMPINES NORTH PRIMARY SCHOOL',
    'TAMPINES PRIMARY SCHOOL',
    'TANJONG KATONG PRIMARY SCHOOL',
    'TAO NAN SCHOOL',
    'TECK GHEE PRIMARY SCHOOL',
    'TECK WHYE PRIMARY SCHOOL',
    'TELOK KURAU PRIMARY SCHOOL',
    'TEMASEK PRIMARY SCHOOL',
    'TOWNSVILLE PRIMARY SCHOOL',
    'UNITY PRIMARY SCHOOL',
    'WATERWAY PRIMARY SCHOOL',
    'WELLINGTON PRIMARY SCHOOL',
    'WEST GROVE PRIMARY SCHOOL',
    'WEST SPRING PRIMARY SCHOOL',
    'WEST VIEW PRIMARY SCHOOL',
    'WESTWOOD PRIMARY SCHOOL',
    'WHITE SANDS PRIMARY SCHOOL',
    'WOODGROVE PRIMARY SCHOOL',
    'WOODLANDS PRIMARY SCHOOL',
    'WOODLANDS RING PRIMARY SCHOOL',
    'XINGHUA PRIMARY SCHOOL',
    'XINGNAN PRIMARY SCHOOL',
    'XINMIN PRIMARY SCHOOL',
    'XISHAN PRIMARY SCHOOL',
    'YANGZHENG PRIMARY SCHOOL',
    'YEW TEE PRIMARY SCHOOL',
    'YIO CHU KANG PRIMARY SCHOOL',
    'YISHUN PRIMARY SCHOOL',
    'YU NENG PRIMARY SCHOOL',
    'YUHUA PRIMARY SCHOOL',
    'YUMIN PRIMARY SCHOOL',
    'ZHANGDE PRIMARY SCHOOL',
    'ZHENGHUA PRIMARY SCHOOL',
    'ZHONGHUA PRIMARY SCHOOL',
    'ADMIRALTY SECONDARY SCHOOL',
    'AHMAD IBRAHIM SECONDARY SCHOOL',
    'ANDERSON SECONDARY SCHOOL',
    'ANG MO KIO SECONDARY SCHOOL',
    'ANGLICAN HIGH SCHOOL',
    'ANGLO-CHINESE SCHOOL (BARKER ROAD)',
    'ASSUMPTION ENGLISH SCHOOL',
    'ASSUMPTION PATHWAY SCHOOL',
    'BALESTIER HILL SECONDARY SCHOOL',
    'BARTLEY SECONDARY SCHOOL',
    'BEATTY SECONDARY SCHOOL',
    'BEDOK GREEN SECONDARY SCHOOL',
    'BEDOK NORTH SECONDARY SCHOOL',
    'BEDOK SOUTH SECONDARY SCHOOL',
    'BEDOK VIEW SECONDARY SCHOOL',
    'BENDEMEER SECONDARY SCHOOL',
    'BISHAN PARK SECONDARY SCHOOL',
    'BOON LAY SECONDARY SCHOOL',
    'BOWEN SECONDARY SCHOOL',
    'BROADRICK SECONDARY SCHOOL',
    'BUKIT BATOK SECONDARY SCHOOL',
    'BUKIT MERAH SECONDARY SCHOOL',
    'BUKIT PANJANG GOVT. HIGH SCHOOL',
    'BUKIT VIEW SECONDARY SCHOOL',
    'CANBERRA SECONDARY SCHOOL',
    "CEDAR GIRLS' SECONDARY SCHOOL",
    'CHANGKAT CHANGI SECONDARY SCHOOL',
    'CHIJ KATONG CONVENT',
    'CHIJ SECONDARY (TOA PAYOH)',
    "CHIJ ST. JOSEPH'S CONVENT",
    "CHIJ ST. THERESA'S CONVENT",
    'CHONG BOON SECONDARY SCHOOL',
    'CHRIST CHURCH SECONDARY SCHOOL',
    'CHUA CHU KANG SECONDARY SCHOOL',
    'CHUNG CHENG HIGH SCHOOL (MAIN)',
    'CHUNG CHENG HIGH SCHOOL (YISHUN)',
    'CLEMENTI TOWN SECONDARY SCHOOL',
    'COMMONWEALTH SECONDARY SCHOOL',
    'COMPASSVALE SECONDARY SCHOOL',
    'CORAL SECONDARY SCHOOL',
    "CRESCENT GIRLS' SCHOOL",
    'CREST SECONDARY SCHOOL',
    'DAMAI SECONDARY SCHOOL',
    'DEYI SECONDARY SCHOOL',
    'DUNEARN SECONDARY SCHOOL',
    'DUNMAN SECONDARY SCHOOL',
    'EAST SPRING SECONDARY SCHOOL',
    'EAST VIEW SECONDARY SCHOOL',
    'EDGEFIELD SECONDARY SCHOOL',
    'EVERGREEN SECONDARY SCHOOL',
    'FAIRFIELD METHODIST SCHOOL (SECONDARY)',
    'FAJAR SECONDARY SCHOOL',
    'FUCHUN SECONDARY SCHOOL',
    'FUHUA SECONDARY SCHOOL',
    'GAN ENG SENG SCHOOL',
    'GEYLANG METHODIST SCHOOL (SECONDARY)',
    'GREENDALE SECONDARY SCHOOL',
    'GREENRIDGE SECONDARY SCHOOL',
    'GREENVIEW SECONDARY SCHOOL',
    'GUANGYANG SECONDARY SCHOOL',
    'HAI SING CATHOLIC SCHOOL',
    'HENDERSON SECONDARY SCHOOL',
    'HILLGROVE SECONDARY SCHOOL',
    "HOLY INNOCENTS' HIGH SCHOOL",
    'HONG KAH SECONDARY SCHOOL',
    'HOUGANG SECONDARY SCHOOL',
    'HUA YI SECONDARY SCHOOL',
    'JUNYUAN SECONDARY SCHOOL',
    'JURONG SECONDARY SCHOOL',
    'JURONG WEST SECONDARY SCHOOL',
    'JURONGVILLE SECONDARY SCHOOL',
    'JUYING SECONDARY SCHOOL',
    'KENT RIDGE SECONDARY SCHOOL',
    'KRANJI SECONDARY SCHOOL',
    'KUO CHUAN PRESBYTERIAN SECONDARY SCHOOL',
    'LOYANG SECONDARY SCHOOL',
    'MACPHERSON SECONDARY SCHOOL',
    'MANJUSRI SECONDARY SCHOOL',
    'MARSILING SECONDARY SCHOOL',
    'MAYFLOWER SECONDARY SCHOOL',
    'MONTFORT SECONDARY SCHOOL',
    'NAN CHIAU HIGH SCHOOL',
    'NAN HUA HIGH SCHOOL',
    "NANYANG GIRLS' HIGH SCHOOL",
    'NAVAL BASE SECONDARY SCHOOL',
    'NEW TOWN SECONDARY SCHOOL',
    'NGEE ANN SECONDARY SCHOOL',
    'NORTH VIEW SECONDARY SCHOOL',
    'NORTH VISTA SECONDARY SCHOOL',
    'NORTHBROOKS SECONDARY SCHOOL',
    'NORTHLAND SECONDARY SCHOOL',
    'NORTHLIGHT SCHOOL',
    'ORCHID PARK SECONDARY SCHOOL',
    'OUTRAM SECONDARY SCHOOL',
    'PASIR RIS CREST SECONDARY SCHOOL',
    'PASIR RIS SECONDARY SCHOOL',
    "PAYA LEBAR METHODIST GIRLS' SCHOOL (SECONDARY)",
    'PEI HWA SECONDARY SCHOOL',
    'PEICAI SECONDARY SCHOOL',
    'PEIRCE SECONDARY SCHOOL',
    'PING YI SECONDARY SCHOOL',
    'PIONEER SECONDARY SCHOOL',
    'PRESBYTERIAN HIGH SCHOOL',
    'PUNGGOL SECONDARY SCHOOL',
    'QUEENSTOWN SECONDARY SCHOOL',
    'QUEENSWAY SECONDARY SCHOOL',
    "RAFFLES GIRLS' SCHOOL (SECONDARY)",
    'REGENT SECONDARY SCHOOL',
    'RIVERSIDE SECONDARY SCHOOL',
    'SCHOOL OF SCIENCE AND TECHNOLOGY, SINGAPORE',
    'SEMBAWANG SECONDARY SCHOOL',
    'SENG KANG SECONDARY SCHOOL',
    'SERANGOON GARDEN SECONDARY SCHOOL',
    'SERANGOON SECONDARY SCHOOL',
    'SHUQUN SECONDARY SCHOOL',
    'SI LING SECONDARY SCHOOL',
    'SIGLAP SECONDARY SCHOOL',
    'SPECTRA SECONDARY SCHOOL',
    'SPRINGFIELD SECONDARY SCHOOL',
    "ST. ANDREW'S SECONDARY SCHOOL",
    "ST. ANTHONY'S CANOSSIAN SECONDARY SCHOOL",
    "ST. GABRIEL'S SECONDARY SCHOOL",
    "ST. HILDA'S SECONDARY SCHOOL",
    "ST. MARGARET'S SECONDARY SCHOOL",
    "ST. PATRICK'S SCHOOL",
    'SWISS COTTAGE SECONDARY SCHOOL',
    'TAMPINES SECONDARY SCHOOL',
    'TANGLIN SECONDARY SCHOOL',
    "TANJONG KATONG GIRLS' SCHOOL",
    'TANJONG KATONG SECONDARY SCHOOL',
    'TECK WHYE SECONDARY SCHOOL',
    'TEMASEK SECONDARY SCHOOL',
    'UNITY SECONDARY SCHOOL',
    'VICTORIA SCHOOL',
    'WEST SPRING SECONDARY SCHOOL',
    'WESTWOOD SECONDARY SCHOOL',
    'WHITLEY SECONDARY SCHOOL',
    'WOODGROVE SECONDARY SCHOOL',
    'WOODLANDS RING SECONDARY SCHOOL',
    'WOODLANDS SECONDARY SCHOOL',
    'XINMIN SECONDARY SCHOOL',
    'YIO CHU KANG SECONDARY SCHOOL',
    'YISHUN SECONDARY SCHOOL',
    'YISHUN TOWN SECONDARY SCHOOL',
    'YUAN CHING SECONDARY SCHOOL',
    'YUHUA SECONDARY SCHOOL',
    'YUSOF ISHAK SECONDARY SCHOOL',
    'YUYING SECONDARY SCHOOL',
    'ZHENGHUA SECONDARY SCHOOL',
    'ZHONGHUA SECONDARY SCHOOL',
    'ANDERSON JUNIOR COLLEGE',
    'ANGLO-CHINESE JUNIOR COLLEGE',
    'CATHOLIC JUNIOR COLLEGE',
    'INNOVA JUNIOR COLLEGE',
    'JURONG JUNIOR COLLEGE',
    'MERIDIAN JUNIOR COLLEGE',
    'MILLENNIA INSTITUTE',
    'NANYANG JUNIOR COLLEGE',
    'PIONEER JUNIOR COLLEGE',
    'SERANGOON JUNIOR COLLEGE',
    "ST. ANDREW'S JUNIOR COLLEGE",
    'TAMPINES JUNIOR COLLEGE',
    'VICTORIA JUNIOR COLLEGE',
    'YISHUN JUNIOR COLLEGE',
    'ANGLO-CHINESE SCHOOL (INDEPENDENT)',
    'CATHOLIC HIGH SCHOOL',
    "CHIJ ST. NICHOLAS GIRLS' SCHOOL",
    'DUNMAN HIGH SCHOOL',
    'HWA CHONG INSTITUTION',
    'MARIS STELLA HIGH SCHOOL',
    "METHODIST GIRLS' SCHOOL (PRIMARY)",
    "METHODIST GIRLS' SCHOOL (SECONDARY)",
    'NATIONAL JUNIOR COLLEGE',
    'NUS HIGH SCHOOL OF MATHEMATICS AND SCIENCE',
    'RAFFLES INSTITUTION',
    'RIVER VALLEY HIGH SCHOOL',
    'SCHOOL OF THE ARTS, SINGAPORE',
    "SINGAPORE CHINESE GIRLS' PRIMARY SCHOOL",
    "SINGAPORE CHINESE GIRLS' SCHOOL",
    'SINGAPORE SPORTS SCHOOL',
    "ST. JOSEPH'S INSTITUTION",
    'TEMASEK JUNIOR COLLEGE',
    'YISHUN INNOVA JUNIOR COLLEGE',
    'JURONG PIONEER JUNIOR COLLEGE',
    'ANDERSON SERANGOON JUNIOR COLLEGE',
    'TAMPINES MERIDIAN JUNIOR COLLEGE',
    'Ahmand Ibrahim Secondary School',
    'Brodrick Secondary School',
    'CHIJ Secondary',
    "Christian Brothers' Schools",
    'Clementi Woods Secondary School',
    'First Toa Payoh Secondary School',
    'Gan Eng Seng Secondary School',
    'Gan Seng Eng School',
    'Millennia Institute Junior College',
    'Millennia Junior College',
    'Paris Ris Crest School',
    'Pei Chun Primary School',
    "Peya Lebar Methodist Girls' School(Primary)",
    'Rosyth Primary School'
  ];

  static grades = [
    'PRIMARY 1',
    'PRIMARY 2',
    'PRIMARY 3',
    'PRIMARY 4',
    'PRIMARY 5',
    'PRIMARY 6',
    'SECONDARY 1',
    'SECONDARY 2',
    'SECONDARY 3',
    'SECONDARY 4',
    'SECONDARY 5',
    'JUNIOR COLLEGE 1',
    'JUNIOR COLLEGE 2',
  ];

  static examTypes = [
    'MID-YEAR EXAMINATION',
    'PRELIMINARY EXAMINATION',
    'FINAL EXAMINATION',
    'SEMESTRAL ASSESSMENT 1',
    'SEMESTRAL ASSESSMENT 2',
    'CONTINUAL ASSESSMENT 1',
    'CONTINUAL ASSESSMENT 2',
    'DIAGNOSTIC TEST',
    'Bite-sized Assessment 1',
    'Bite-sized Assessment 2',
    'Class Test',
    'Class Test 1',
    'Class Test 2',
    'Checkpoint 1',
    'Combined Topical Test',
    'Combined Topical Test 1',
    'Combined Topical Test 2',
    'Combined Topical Test 3',
    'Continual Assessment',
    'End-of-year Examination',
    'End of year Assessment',
    'End-Of-Year Review',
    'English Trial',
    'English Trial Paper',
    'English Language Quiz',
    'English Language Test 1',
    'English Language Test 2',
    'English Practice 2',
    'First Semestral Examination',
    'Formative Assessment',
    'Grammar and Vocabulary Test',
    'Grammar and Vocabulary Practice 2',
    'Holistic Assessment',
    'Holistic Assessment 1',
    'Holistic Assessment 2',
    'Holistic Assessment 3',
    'Language Use Test Term 2',
    'Language Use Test Term 3',
    'Language Use Test Term 4',
    'Language Use and Comprehension',
    'Language Use and Comprehension Review',
    'Language Review 1',
    'Mathematics Quiz 1',
    'Mathematics Quiz 2',
    'Mathematics Quiz 3',
    'Mathematics Worksheet',
    'Mid Year Assessment',
    'Mid-year Examination',
    'Mid-Year Express',
    'Milestone Check 1',
    'Milestone Check 2',
    'Mini Test 1 and 2',
    'Mini Test 1',
    'Mini Test 2',
    'Mini Test 3',
    'Mini Test 4',
    'Mock Test',
    'Modular Assessment 1',
    'Modular Assessment 2',
    'Practice Paper 1',
    'Practice Paper 2',
    'Practice Paper 3',
    'Practice Paper Term 4',
    'Preliminary Assessment',
    'Preliminary Two Examination',
    'Preliminary Examination 1',
    'Reading Comprehension Task',
    'Revision',
    'Review Paper',
    'Review Paper 1',
    'Review Assessment 1',
    'Review Assessment 2',
    'Revision Paper',
    'Revision Paper 2',
    'Semestral Assessment',
    'Semestral Examination 1',
    'Semestral Examination 2',
    'Short Language Paper',
    'Summative Assessment',
    'Term Review 1',
    'Term Review 2',
    'Term 1 Topical Review',
    'Term 2 Revision',
    'Term 3 Revision',
    'Term 4 Revision',
    'Term Assessment',
    'Termly Assessment',
    'Termly Assessment 2',
    'Term 1 Weighted Assessment',
    'Term 3 Weighted Assessment',
    'Tropical Test 1',
    'Topical Review 1',
    'Topical Review 2',
    'Topical Review 3',
    'Topical Assessment 1',
    'Topical Assessment 2',
    'Topical Assessment 3',
    'Topical Test',
    'Topical Quiz 1',
    'Topical Quiz 2',
    'Topical Quiz 3',
    'Unit Review 1',
    'Weighted Assessment',
    'Weighted Assessment 1',
    'Weighted Assessment 2',
    'Weighted Assessment 3',
    'Worksheet 2'
  ];

  static papers = [
    'Booklet A and B',
    'Booklet A, B & C',
    'Booklet A',
    'Booklet B',
    'Paper 1 & 2',
    'Paper 1, 2 and 3',
    'Paper 4',
    'Paper 1 (Writing)',
    'Review 1',
    'Review 2',
    'Revision A and B',
    'Section A,B,C and D',
    'Section A, B & C',
    'Section A and B',
    'Section A',
    'Section B',
    'Section C',
    'Set A',
    'Set B',
    'Test 1',
    'Test 2',
    'Test 3',
    'Test 1 and 2',
    'Term 1',
    'Term 2',
    'Term 3',
    'Term 1 and 2',
    'Term 3 and 4',
    'Units 1 and 2'
  ];

  
};
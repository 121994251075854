/**
 * A set where each element has a time-to-live (TTL).
 * After the specified TTL, the element is considered expired
 * and is no longer a member of the set.
 */
export class ExpiringSet<T> {
  private readonly data: Map<T, number> = new Map();
  private static readonly CLEANUP_INTERVAL_MS = 5 * 60 * 1_000; // 5 minutes
  private _interval: NodeJS.Timer;

  constructor() {
    // Schedule regular cleanup of expired keys to ensure that
    // old entries don't indefinitely consume memory.
    this._interval = setInterval(() => this._cleanupExpiredKeys(), ExpiringSet.CLEANUP_INTERVAL_MS);
  }

  /**
   * Adds a value to the set with a specified TTL.
   *
   * @param value - The value to be added.
   * @param ttl - The time (in milliseconds) the value will live in the set.
   */
  add(value: T, ttl: number): void {
    const expirationTime = Date.now() + ttl;
    this.data.set(value, expirationTime);
  }

  /**
   * Checks if a value is in the set and has not yet expired.
   *
   * @param value - The value to check for.
   * @returns True if the value is in the set and has not expired, otherwise false.
   */
  has(value: T): boolean {
    const expirationTime = this.data.get(value);
    if (!expirationTime || Date.now() > expirationTime) {
      this.data.delete(value);
      return false;
    }
    return true;
  }

  /**
   * Removes a value from the set, irrespective of its expiration status.
   *
   * @param value - The value to remove.
   * @returns True if the value was removed, otherwise false.
   */
  remove(value: T): boolean {
    return this.data.delete(value);
  }

  /**
   * Frees any resources that could create memory leaks
   */
  cleanup(): void {
    clearInterval(this._interval);
  }

  /**
   * Removes all expired entries from the set.
   *
   * This is periodically called automatically to free up memory.
   *
   * @private
   */
  private _cleanupExpiredKeys(): void {
    const now = Date.now();
    for (const [key, expirationTime] of this.data.entries()) {
      if (now > expirationTime) {
        this.data.delete(key);
      }
    }
  }
}

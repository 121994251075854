import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { setThemeColors, Theme, Themes } from '../common/themes/themes';
import { Institution, User } from '../models/user';
import { UserService } from './user.service';

interface SelectedTheme {
  name: string;
  theme: Theme;
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private user?: User;
  public theme: BehaviorSubject<SelectedTheme | null> = new BehaviorSubject<SelectedTheme | null>(
    null,
  );

  constructor(private userService: UserService) {
    this.userService.user.pipe(takeWhile((d) => !d?.user, true)).subscribe((userData) => {
      if (userData && userData.user) {
        const userLoaded = !!this.user;
        this.user = userData.user;
        if (!userLoaded) {
          this.loadUserTheme();
        }
      }
    });
  }

  private loadUserTheme() {
    this.setTheme(this.user?.institution);
  }

  public setTheme(institution?: Institution): void {
    const theme = institution?.theme || Themes['default'];
    setThemeColors(theme);
  }
}

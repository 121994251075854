<div class="dialog-container">
  <div class="prompt-panel" [class.custom_prompt_panel]="notFitAllSteps | async">
    <!-- header that appear for mobile or when device settings button exist -->
    <div *ngIf="!isPhysicalDesktop || showDeviceSettingsButton" class="header">
      <span *ngIf="!isPhysicalDesktop">
        {{ isPhysicalDesktop ? desktopTitle : mobileTitle }}
      </span>
      <button mat-icon-button (click)="onClose()" class="btn-close btn-close-white"></button>
    </div>
    <!-- steps body -->
    <div class="prompt_content" [class.custom_prompt_content]="notFitAllSteps | async">
      <div
        *ngIf="isPhysicalDesktop"
        class="prompt_text"
        [class.custom_prompt_text]="notFitAllSteps | async"
      >
        {{ desktopTitle }}
      </div>
      <div
        (touchstart)="onTouchStart($event)"
        (touchmove)="onTouchMove($event)"
        (touchend)="onTouchEnd($event)"
      >
        <div class="prompt_steps" [class.custom_prompt_steps]="notFitAllSteps | async" #slider>
          <div
            class="step_container"
            [class.custom_step_container]="notFitAllSteps | async"
            *ngFor="let step of steps; let i = index"
          >
            <ng-container
              *ngTemplateOutlet="
                stepTemplate;
                context: {
                  step: step,
                  index: i,
                  widthStyle: 'calc(100% / ' + steps.length + ' - 15px);',
                  isLastStep: i === steps.length - 1
                }
              "
            ></ng-container>
          </div>
        </div>
      </div>
      <div *ngIf="(isMobile | async) || (notFitAllSteps | async)" class="dots-container">
        <span
          *ngFor="let step of steps; let i = index"
          class="dot"
          [class.active]="i === (sliderActiveIndex | async)"
          (click)="showSpecificHelperLayout(i)"
        ></span>
      </div>
    </div>
    <div class="divider"></div>
    <!-- bottom buttons -->
    <div class="prompt-buttons">
      <button class="support-button" id="intercomHelpLink" (click)="liveSupport()">
        <em class="material-icons-outlined mat-icon-support"> help_outline </em>
        <span class="support-text">{{ 'Help' | translate }}</span>
      </button>
      <div class="action-buttons">
        <button
          *ngIf="isPhysicalDesktop && !showDeviceSettingsButton"
          class="next-button secondary-action-btn ml-auto"
          [style.background]="'#222222'"
          (click)="onMaybeLater()"
        >
          <span class="support-text">{{ 'Maybe later' | translate }}</span>
        </button>
        <button
          *ngIf="
            ((((notFitAllSteps | async) || (isMobile | async)) &&
              (sliderActiveIndex | async) === steps.length - 1) ||
              ((notFitAllSteps | async) == false && (isMobile | async) == false)) &&
            showDeviceSettingsButton
          "
          class="next-button secondary-action-btn ml-auto"
          [style.background]="'#222222'"
          (click)="openDeviceSettings()"
        >
          <span class="support-text" [innerHTML]="deviceSettingButtonInerHTML"> </span>
        </button>
        <button
          [disabled]="
            !isPhysicalDesktop &&
            (isMobile | async) &&
            !isiOSiPhone &&
            (sliderActiveIndex | async) !== steps.length - 1
          "
          [style.width]="
            !isPhysicalDesktop &&
            (isMobile | async) &&
            ((sliderActiveIndex | async) === steps.length - 1 || !isiOSiPhone) &&
            '90px !important'
          "
          [style.margin-left]="isPhysicalDesktop && (isMobile | async) ? 'unset !important' : ''"
          [ngClass]="isPhysicalDesktop && (isMobile | async) === false && 'cml-8'"
          class="next-button helper-button"
          (click)="execuateHelperAction(helperAction.innerText)"
        >
          <em *ngIf="!isPhysicalDesktop || isFirefox" class="allow-access-action-icons">
            <mat-icon
              class="mat-icon-support"
              *ngIf="
                (isMobile | async) &&
                (sliderActiveIndex | async) !== steps.length - 1 &&
                (isiOSiPhone || isPhysicalDesktop)
              "
              >arrow_forward</mat-icon
            >
            <mat-icon
              class="mat-icon-support"
              *ngIf="
                showRefreshButton &&
                ((!isPhysicalDesktop &&
                  ((sliderActiveIndex | async) === steps.length - 1 ||
                    !isiOSiPhone ||
                    (isMobile | async) === false)) ||
                  (isPhysicalDesktop && isFirefox))
              "
              >refresh</mat-icon
            >
          </em>
          <mat-spinner *ngIf="loading" [diameter]="16" class="font-16 cmr-6"></mat-spinner>
          <span class="support-text" #helperAction>{{
            (isMobile | async) &&
            (sliderActiveIndex | async) !== steps.length - 1 &&
            (isiOSiPhone || isPhysicalDesktop)
              ? ('Next' | translate)
              : showRefreshButton &&
                !isPhysicalDesktop &&
                ((sliderActiveIndex | async) === steps.length - 1 ||
                  !isiOSiPhone ||
                  ((isMobile | async) === false && (notFitAllSteps | async) === false) ||
                  (isPhysicalDesktop && isFirefox))
              ? ('Refresh' | translate)
              : ('Done' | translate)
          }}</span>
        </button>
      </div>
    </div>
    <!-- arrow left -->
    <ng-container *ngIf="(notFitAllSteps | async) && (isMobile | async) === false">
      <div
        class="nav-btn-container"
        [ngStyle]="{ position: 'absolute' }"
        [style.top.px]="'245'"
        [style.left.px]="'32'"
      >
        <button (click)="backHelperLayout()" [disabled]="(sliderActiveIndex | async) === 0">
          <span class="material-icons">arrow_left</span>
        </button>
      </div>
    </ng-container>

    <!-- arrow right -->
    <ng-container *ngIf="(notFitAllSteps | async) && (isMobile | async) === false">
      <div
        class="nav-btn-container"
        [ngStyle]="{ position: 'absolute' }"
        [style.top.px]="'245'"
        [style.right.px]="'32'"
      >
        <button
          (click)="nextHelperLayout()"
          [disabled]="(sliderActiveIndex | async) === steps.length - 1"
        >
          <span class="material-icons">arrow_right</span>
        </button>
      </div>
    </ng-container>
  </div>

  <!-- step view template -->
  <ng-template
    #stepTemplate
    let-step="step"
    let-index="index"
    let-widthStyle="widthStyle"
    let-isLastStep="isLastStep"
  >
    <div
      class="step"
      [class.custom_step]="notFitAllSteps | async"
      [ngStyle]="{
        width: widthStyle
      }"
    >
      <div *ngIf="step.showStepLocation" class="step-location">
        <img
          class="step-location-img"
          [width]="20"
          [height]="20"
          [src]="'assets/imgs/' + step?.stepLocationImgSrc"
          alt="{{ step?.stepLocationText | translate }}"
        />
        <span class="step-location-text">{{ step?.stepLocationText }}</span>
      </div>
      <div
        *ngIf="!isLastStep && (isMobile | async) === false && (notFitAllSteps | async) === false"
        class="divider-step"
      ></div>
      <div
        class="step_circle"
        [class.custom_step_circle]="notFitAllSteps | async"
        [style.margin-top]="isLastStep && '-10px !important'"
      >
        {{ index + 1 }}
      </div>
      <div
        class="step_text"
        [class.custom_step_text]="notFitAllSteps | async"
        [innerHTML]="step.stepText | safeHtml"
      ></div>
      <img
        *ngIf="step.stepImgSrc"
        [class]="step.stepImgCustomStyle"
        [class.custom_img]="notFitAllSteps | async"
        [width]="(isMobile | async) ? 327 : 256"
        [height]="(isMobile | async) ? 152 : 136"
        [src]="'assets/imgs/' + step.stepImgSrc"
        alt="{{ 'helper step' | translate }}"
      />
    </div>
  </ng-template>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes',
})
export class IncludesPipe implements PipeTransform {
  transform(value: any, items: any[]): unknown {
    return items.includes(value);
  }
}

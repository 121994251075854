<div
  id="overlay-parent-container"
  class="parent-container"
  #videoTowerParentContainer
  [class.full-screen]="(currentSessionView$ | async) === sessionView.FULLSCREEN"
  [class.wb]="
    (currentSessionView$ | async) === SessionView.WHITEBOARD ||
    (currentSessionView$ | async) === SessionView.FULLSCREEN_APP
  "
  [class.layout-top]="(sessionSharedDataService.controlsLayout | async) === VideoLayout.TOP"
  [class.layout-right-new]="(sessionSharedDataService.controlsLayout | async) === VideoLayout.RIGHT"
  [class.layout-minimized-new]="
    (sessionSharedDataService.controlsLayout | async) === VideoLayout.MINIMIZED
  "
  [class.layout-floating]="
    (sessionSharedDataService.controlsLayout | async) === VideoLayout.FLOATING
  "
  [ngStyle]="
    (currentSessionView$ | async) === SessionView.GALLERY_VIEW ||
    (currentSessionView$ | async) === SessionView.EXPANDED
      ? { width: '100%' }
      : (sessionSharedDataService.controlsLayout | async) === VideoLayout.RIGHT &&
        ((currentSessionView$ | async) === SessionView.WHITEBOARD ||
          (currentSessionView$ | async) === SessionView.FULLSCREEN_APP)
      ? {
          minWidth: (resizedRightViewMinWidth$ | async) + 'px',
          minHeight: (rightViewHeight$ | async) + 'px',
          maxHeight: (rightViewHeight$ | async) + 'px',
          width: (rightViewWidth$ | async) + 'px',
          height: (rightViewHeight$ | async) + 'px',
          resize: flagsService.featureFlagsVariables.dynamic_right_view?.resizable_tower
            ? 'horizontal'
            : 'none',
          direction:
            flagsService.featureFlagsVariables.dynamic_right_view?.resizable_tower &&
            (directionForLocale$ | async) === LocalDirection.LTR
              ? LocalDirection.RTL
              : LocalDirection.LTR
        }
      : {}
  "
  [@openClose]="{
    value:
      (sessionSharedDataService.controlsLayout | async) === VideoLayout.FLOATING ||
      (startAnimation$ | async)
        ? 'closed'
        : 'open'
  }"
  [style.right]="
    (currentSessionView$ | async) === SessionView.FULLSCREEN_APP &&
    (isMobileView$ | async) === false
      ? 0
      : ''
  "
  [style.marginTop]="
    (currentSessionView$ | async) === SessionView.FULLSCREEN_APP &&
    (isMobileView$ | async) === false
      ? '-22px'
      : 0
  "
>
  <div
    id="parent-container"
    [ngClass]="
      (currentSessionView$ | async) === sessionView.GALLERY_VIEW &&
      spaceRepo.activeSpace?.institution?.name === 'Voglio'
        ? 'voglio-institution'
        : ''
    "
    [style.width]="'100%'"
    [style.height]="'100%'"
    [style.direction]="
      (directionForLocale$ | async) === LocalDirection.LTR ? LocalDirection.LTR : LocalDirection.RTL
    "
    appTilesContainerObserver
    [numberOfTiles]="numberOfPresentParticipants$ | async"
    [currentSessionView]="currentSessionView$ | async"
    [isMobileView]="isMobileView$ | async"
    [clearLatestEmittedChange]="rtcServiceController.service.isConnected()"
    (changeTilesContainerParameters)="updateTilesContainerParameters($event)"
  >
    <ng-container>
      <div
        *ngFor="let tile of participantsArray$ | async; trackBy: trackById; let i = index"
        class="video-tile"
        [class.is-mobile]="isMobileDevice || (isMobileView$ | async)"
        [ngStyle]="{
          position: 'absolute',
          display:
            ((currentSessionView$ | async) === SessionView.WHITEBOARD ||
              (currentSessionView$ | async) === SessionView.FULLSCREEN_APP) &&
            (sessionSharedDataService.controlsLayout | async) === VideoLayout.MINIMIZED
              ? 'none'
              : 'unset'
        }"
        [style.width.px]="tile.width$ | async"
        [style.height.px]="tile.height$ | async"
        [style.top.px]="tile.top$ | async"
        [style.left.px]="tile.left$ | async"
        [hidden]="(tile.visible$ | async) === false"
        appFirstElementVisible
        [shouldElementBeVisible]="tile.visible$ | async"
        [topPosition]="tile.top$ | async"
        [leftPosition]="tile.left$ | async"
      >
        <app-session-call-user
          class="d-flex w-100 h-100"
          *ngIf="tile.user$ | async"
          [id]="
            ((tile.track$ | async) == TrackType.SCREEN ? 'screen-tile-' : 'video-tile-') +
            ((tile.participant$ | async)
              ? (tile.participant$ | async)?.participantId
              : (tile.user$ | async)?._id)
          "
          [scaleSize]="
            (tile.width$ | async) ? $any(tile.width$ | async) / rightViewMinTileNeededWidth : 1
          "
          [user]="tile.user$ | async"
          [participant]="tile.participant$ | async"
          [currentUser]="(tile.user$ | async) && (tile.user$ | async)?._id === user?._id"
          [rtcConnecting]="rtcServiceController.service.isConnecting()"
          [userIsSpeaking]="
            (this.participantsSpeaking.has((tile.participant$ | async)?.participantId) &&
              (tile.track$ | async) !== TrackType.SCREEN) ||
            (this.screenSharingHasAudio.has((tile.participant$ | async)?.participantId) &&
              (tile.track$ | async) !== TrackType.VIDEO)
              ? true
              : false
          "
          [raiseHand]="
            (tile.track$ | async) === TrackType.VIDEO ? (tile.raiseHand$ | async) : false
          "
          [emojiId]="(tile.track$ | async) === TrackType.VIDEO ? (tile.emojiId$ | async) : -1"
          [videoTrackType]="tile.track$ | async"
          [showExpand]="
            (numberOfPresentParticipants$ | async) !== 1 &&
            ![SessionView.FULLSCREEN, SessionView.WHITEBOARD].includes(
              (currentSessionView$ | async) ?? SessionView.WHITEBOARD
            )
          "
          [isCurrentExpandedTile]="
            (expandedScreenUser$ | async)?.participant &&
            (expandedScreenUser$ | async)?.participant?.participantId ===
              (tile.participant$ | async)?.participantId &&
            (expandedScreenUser$ | async)?.track === (tile.track$ | async) &&
            (currentSessionView$ | async) === SessionView.EXPANDED
          "
          [sessionView]="currentSessionView$ | async"
          (expand)="!isLeaderModeActive$.getValue() && handleExpand(tile, $event)"
          (fullscreen)="!isLeaderModeActive$.getValue() && handleFullscreen(tile, $event)"
          (insertRemoteVideo)="
            sessionSharedDataService.itemsCanvas?.addRemoteVideo(
              tile.participant$.getValue()?.participantId + ':' + tile.track$.getValue()
            )
          "
          (currentlySpeaking)="
            handleSpeakingParticipant(tile.participant$.getValue()?.participantId, $event)
          "
          [hideSelfViewActive]="
            (tile.participant$ | async)?.local &&
            (hideSelfViewActive$ | async) &&
            (tile.track$ | async) === TrackType.VIDEO
          "
          (changeHideSelfViewState)="handleHideSelfViewChange($event)"
          [ngbTooltip]="selfViewIsHiddentooltip"
          placement="top"
          [disableTooltip]="
            (tile.participant$ | async)?.local === false ||
            (hideSelfViewActive$ | async) == false ||
            [SessionView.FULLSCREEN, SessionView.WHITEBOARD, SessionView.FULLSCREEN_APP].includes(
              (currentSessionView$ | async) ?? SessionView.WHITEBOARD
            )
          "
          [isActiveLeaderMode]="isLeaderModeActive$ | async"
        >
        </app-session-call-user>
      </div>
    </ng-container>

    <ng-template #selfViewIsHiddentooltip>
      <div class="tip-container">
        <div class="tip">
          <div>{{ 'Self view is hidden' | translate }}</div>
        </div>
      </div>
    </ng-template>

    <ng-container
      *ngIf="
        ((currentSessionView$ | async) === sessionView.GALLERY_VIEW ||
          (currentSessionView$ | async) === SessionView.EXPANDED ||
          (((currentSessionView$ | async) === SessionView.WHITEBOARD ||
            (currentSessionView$ | async) === SessionView.FULLSCREEN_APP) &&
            (sessionSharedDataService.controlsLayout | async) !== VideoLayout.MINIMIZED &&
            (sessionSharedDataService.isControlsCollapsed | async) === false)) &&
        $any(numberOfUserTilesPages$ | async) > 1 &&
        (navigationLeftButtonPosition$ | async)
      "
    >
      <div
        class="nav-btn-container"
        [ngStyle]="{ position: 'absolute' }"
        [style.top.px]="(navigationLeftButtonPosition$ | async)?.top"
        [style.left.px]="(navigationLeftButtonPosition$ | async)?.left"
      >
        <button
          id="left-button"
          class="left-button"
          [disabled]="(currentUserTilesPageIndex$ | async) === 0"
          (click)="navigateTiles(currentUserTilesPageIndex$.getValue() - 1)"
        >
          <span class="material-icons">chevron_left</span>
        </button>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        ((currentSessionView$ | async) === sessionView.GALLERY_VIEW ||
          (currentSessionView$ | async) === SessionView.EXPANDED ||
          (((currentSessionView$ | async) === SessionView.WHITEBOARD ||
            (currentSessionView$ | async) === SessionView.FULLSCREEN_APP) &&
            (sessionSharedDataService.controlsLayout | async) !== VideoLayout.MINIMIZED &&
            (sessionSharedDataService.isControlsCollapsed | async) === false)) &&
        $any(numberOfUserTilesPages$ | async) > 1 &&
        (navigationRightButtonPosition$ | async)
      "
    >
      <div
        class="nav-btn-container"
        [ngStyle]="{ position: 'absolute' }"
        [style.top.px]="(navigationRightButtonPosition$ | async)?.top"
        [style.left.px]="(navigationRightButtonPosition$ | async)?.left"
      >
        <button
          id="right-button"
          class="right-button"
          [disabled]="
            (currentUserTilesPageIndex$ | async) === $any(numberOfUserTilesPages$ | async) - 1
          "
          (click)="navigateTiles(currentUserTilesPageIndex$.getValue() + 1)"
        >
          <span class="material-icons">chevron_right</span>
        </button>
      </div>
    </ng-container>
    <div
      id="video-controls"
      class="video-controls"
      [ngStyle]="{ 'bottom.px': videoControlsBottom$ | async }"
      [class.wb-view]="
        (currentSessionView$ | async) === SessionView.WHITEBOARD ||
        (currentSessionView$ | async) === SessionView.FULLSCREEN_APP
      "
      [class.gallery-view]="(currentSessionView$ | async) === SessionView.GALLERY_VIEW"
      [class.expand-view]="(currentSessionView$ | async) === SessionView.EXPANDED"
      [class.full-screen]="(currentSessionView$ | async) === SessionView.FULLSCREEN"
      [class.is-mobile]="isMobileDevice || (isMobileView$ | async)"
    >
      <div
        *ngIf="
          (sessionSharedDataService.controlsLayout | async) === VideoLayout.MINIMIZED &&
          ((currentSessionView$ | async) === SessionView.WHITEBOARD ||
            (currentSessionView$ | async) === SessionView.FULLSCREEN_APP)
        "
        class="cpx-12 cpy-8 d-flex speaking-container align-items-center justify-content-between"
        [style.opacity]="participantsSpeaking.size === 0 ? 0.6 : 1"
      >
        <div class="d-flex align-items-center flex-1 overflow-hidden cmr-8">
          <span *ngIf="participantsSpeaking.size === 0" class="no-speaker-icon"></span>
          <app-profile-photo
            *ngIf="participantsSpeaking.size === 1"
            [user]="speakingUser$ | async"
            width="20"
            [isClickEnabled]="false"
          >
          </app-profile-photo>
          <svg-icon
            *ngIf="participantsSpeaking.size > 1"
            [width]="18"
            [height]="18"
            icon="groups"
          ></svg-icon>
          <span
            class="cml-8 font-16 flex-1 overflow-hidden white-space-nowrap"
            style="text-overflow: ellipsis"
            [style.color]="participantsSpeaking.size === 0 ? '#828282' : 'white'"
            >{{
              participantsSpeaking.size === 0
                ? ('No one is speaking' | translate)
                : participantsSpeaking.size > 1
                ? ('Multiple people' | translate)
                : (speakingUser$ | async)?.name
            }}</span
          >
        </div>
        <svg-icon
          *ngIf="participantsSpeaking.size !== 0"
          [width]="18"
          [height]="14"
          icon="speaking"
        ></svg-icon>
      </div>
      <app-wb-video-controls
        [localParticipantId]="localParticipantId$ | async"
        [isFloatingVideo]="
          (sessionSharedDataService.controlsLayout | async) === VideoLayout.FLOATING
        "
        [hideSelfViewActive]="hideSelfViewActive$ | async"
        (returnToWhiteboard)="returnToWhiteboard()"
        (exitCurrentScreen)="
          !isLeaderModeActive$.getValue() &&
            handleFullscreen(expandedScreenUser$.getValue(), $event)
        "
        (popView)="popView($event)"
        (changeHideSelfViewState)="handleHideSelfViewChange($event)"
      >
      </app-wb-video-controls>
    </div>
  </div>

  <div class="resizer-icon" *ngIf="(isMobileView$ | async) === false">
    <svg-icon [width]="11" [height]="11" icon="resizer"></svg-icon>
  </div>
  <div
    id="overlay-parent-container-resizer"
    [hidden]="
      (currentSessionView$ | async) !== SessionView.WHITEBOARD &&
      (currentSessionView$ | async) !== SessionView.FULLSCREEN_APP
    "
    *ngIf="!!flagsService.featureFlagsVariables.dynamic_right_view?.resizable_tower"
    [ngClass]="{
      'resizer-l': (directionForLocale$ | async) !== LocalDirection.LTR,
      'resizer-r': (directionForLocale$ | async) === LocalDirection.LTR
    }"
    class="resizer"
    (mousedown)="activateVideoTowerResizer(videoTowerParentContainer)"
  ></div>
</div>

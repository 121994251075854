import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-analytics-header',
  templateUrl: './analytics-header.component.html',
  styleUrls: ['./analytics-header.component.scss'],
})
export class AnalyticsHeaderComponent {
  @Input() spaceTitle?: string;
  @Input() noOfSessions?: number;
  @Output() closed = new EventEmitter<void>();

  handleCloseClick(): void {
    this.closed.emit();
  }
}

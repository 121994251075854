import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { isDraftPreview, isUserPreview } from 'src/app/common/utils/messaging';
import { ConversationPreview, MessagePreviewType } from 'src/app/models/messaging';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-conversation-preview[conversationPreview]',
  templateUrl: './conversation-preview.component.html',
  styleUrls: ['./conversation-preview.component.scss'],
})
export class ConversationPreviewComponent implements OnInit {
  @Input() conversationPreview!: ConversationPreview;
  @Input() selected = false;

  @Output() selectConversationPreview = new EventEmitter();

  MessagePreviewType = MessagePreviewType;

  isDraftPreview = isDraftPreview;
  isUserPreview = isUserPreview;

  userLookup: {[id: string]: User} = {};
  conversationGroupName = '';

  constructor(private userService: UserService){}

  ngOnInit() : void {
    this.userService.allUsers.pipe(untilDestroyed(this)).subscribe((res) => {
      if (!res || isUserPreview(this.conversationPreview)) {
        return;
      }
      const reducer = (dict: any = {}, currUser: User) => ({ ...dict, [currUser._id]: currUser });
      this.userLookup = res.profiles?.reduce(reducer, {});
      // dynamically create group name
      this.conversationPreview.group.users.forEach(userId => {
        if(userId !== this.conversationPreview.currentUser._id && this.userLookup[userId]){
          this.conversationGroupName += `${this.userLookup[userId].name  }, `;
        }
      });
      this.conversationGroupName = this.conversationGroupName.slice(0,-2);
    });
  }
}

import { Injectable } from '@angular/core';
import { filterNil } from '@ngneat/elf';
import { isEqual } from 'lodash-es';
import {
  Observable,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  combineLatest,
  startWith,
  pairwise,
  tap,
  shareReplay,
} from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionStatus } from '../models/network';
import { Session } from '../models/session';
import { UserEvent, UserEventType } from '../models/session-sync';
import { SpaceRepository } from '../state/space.repository';
import {
  ISpaceLeaderMode,
  LeaderModeRepositoryService,
} from '../state/leader-mode-repository.service';
import { NetworkService } from './network.service';
import { SessionSharedDataService } from './session-shared-data.service';
import { SessionsSyncService } from './sessions-sync.service';
import { UserService } from './user.service';
import { UiService } from './ui.service';
import { TelemetryService } from './telemetry.service';

export interface ISpaceLeaderModeUI extends ISpaceLeaderMode {
  currentUserIsLeader: boolean;
  currentUserIsLeaderInAnotherTab: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SpaceLeaderModeService {
  // used to log when leader mode state or view changed
  private _lastLeaderModeState?: ISpaceLeaderMode;

  currentUserWasLeader = false;

  constructor(
    private spaceRepo: SpaceRepository,
    private userService: UserService,
    private sessionSyncService: SessionsSyncService,
    private networkService: NetworkService,
    private leaderModeRepositoryService: LeaderModeRepositoryService,
    private uiService: UiService,
    public sessionSharedDataService: SessionSharedDataService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private telemetry: TelemetryService,
  ) {
    this.listenForNetworkDisconnection();
  }

  public readonly activeSpaceLeaderModeState$: Observable<ISpaceLeaderModeUI> =
    this.leaderModeRepositoryService.spaceLeaderMode$.pipe(
      distinctUntilChanged(isEqual),
      tap((state: ISpaceLeaderMode) => {
        this.telemetry.setSessionVars({
          leader_mode_active: state.leaderModeEnabled,
          leader_mode_active_is_leader: this.isCurrentUserLeader(state),
        });
      }),
      map(this.constructSpaceLeaderModeStateUi.bind(this)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

  public readonly leaderModeLeaderUserChange$: Observable<ISpaceLeaderModeUI> =
    this.activeSpaceLeaderModeState$.pipe(distinctUntilKeyChanged('currentLeaderTabId'));

  public readonly currentUserIsBeingLed$: Observable<boolean> =
    this.activeSpaceLeaderModeState$.pipe(
      map((leaderModeState: ISpaceLeaderModeUI) => this.isCurrentUserBeingLed(leaderModeState)),
    );

  public readonly currentUserIsLeader$: Observable<boolean> = this.activeSpaceLeaderModeState$.pipe(
    map((leaderModeState: ISpaceLeaderModeUI) => this.isCurrentUserLeader(leaderModeState)),
  );

  public readonly currentHostIsBeingLed$: Observable<boolean> =
    this.activeSpaceLeaderModeState$.pipe(
      map((leaderModeState: ISpaceLeaderModeUI) => this.isCurrentHostBeingLed(leaderModeState)),
    );

  public readonly activeSpaceLeaderModeLocation$: Observable<UserEvent | undefined> =
    this.sessionSyncService.userEvents$.pipe(
      filterNil(),
      filter((userEvent) => {
        if (!this.spaceRepo.activeSpace) {
          return false;
        }
        const leaderId = this.getSpaceLeaderModeStateUi().currentLeaderUid;
        return (
          leaderId === userEvent?.userId &&
          userEvent?.type === UserEventType.LOCATION &&
          userEvent.location?.vpt !== undefined
        );
      }),
    );

  leaderModeViewModel$ = combineLatest([
    this.activeSpaceLeaderModeState$,
    this.leaderModeLeaderUserChange$.pipe(startWith(null), pairwise()),
    this.uiService.isMobile,
    // adding this observable to change the colors once we have the cursor data of the user
    this.sessionSharedDataService.userPointerDataEvent,
  ]).pipe(
    map(
      ([
        activeSpaceLeaderModeState,
        [previousLeaderModeState, currentLeaderModeState],
        isMobile,
        userPointer,
      ]) => {
        const leaderName = this.spaceRepo.activeSpace?.populatedUsers.find(
          (user) => user._id === currentLeaderModeState?.currentLeaderUid,
        )?.name;

        const leaderChanged =
          currentLeaderModeState?.currentLeaderUid !== previousLeaderModeState?.currentLeaderUid;

        if (leaderChanged && leaderName && isMobile) {
          this.toastrService.show(
            `${leaderName} ${this.translateService.instant('is leading')}`,
            '',
            {
              timeOut: 3000,
              toastClass: 'leading-toast',
              positionClass: 'toast-bottom-center',
              easeTime: 500,
            },
          );
        }

        const userPointerData =
          userPointer?.user === currentLeaderModeState?.currentLeaderUid
            ? userPointer?.pointerColor
            : this.sessionSharedDataService.usersPointerData.get(
                currentLeaderModeState?.currentLeaderUid,
              );

        return {
          activeSpaceLeaderModeState,
          leaderName,
          borderColor: userPointerData || '#2F80ED',
          backgroundColor: userPointerData || '#2F80ED',
        };
      },
    ),
  );

  public currentUserIsBeingLed(): boolean {
    return this.isCurrentUserBeingLed(this.leaderModeRepositoryService.getCurrentState());
  }

  public getSpaceLeaderModeStateUi(): ISpaceLeaderModeUI {
    return this.constructSpaceLeaderModeStateUi(this.leaderModeRepositoryService.getCurrentState());
  }

  public isCurrentUserLeader(spaceLeaderModeState: ISpaceLeaderMode): boolean {
    if (!spaceLeaderModeState.currentLeaderTabId) {
      return false;
    }
    return (
      spaceLeaderModeState.currentLeaderTabId === this.userService.userUniqueHash ||
      spaceLeaderModeState.currentLeaderTabId === this.sessionSyncService.getSocketId()
    );
  }

  private isCurrentUserBeingLed(leaderModeState: ISpaceLeaderMode): boolean {
    return (
      leaderModeState.currentLeaderUid !== undefined && !this.isCurrentUserLeader(leaderModeState)
    );
  }

  private isCurrentHostBeingLed(leaderModeState: ISpaceLeaderMode): boolean {
    return (
      Session.isOwnedByUser(this.spaceRepo.activeSpace, this.userService.user.value?.user) &&
      this.isCurrentUserBeingLed(leaderModeState)
    );
  }

  private constructSpaceLeaderModeStateUi(
    spaceLeaderModeState: ISpaceLeaderMode,
  ): ISpaceLeaderModeUI {
    if (
      !this._lastLeaderModeState ||
      this._lastLeaderModeState.currentLeaderUid !== spaceLeaderModeState.currentLeaderUid ||
      this._lastLeaderModeState.leaderSpaceView !== spaceLeaderModeState.leaderSpaceView
    ) {
      this.telemetry.event('[leaderMode State Change]', {
        spaceLeaderModeState,
      });
    }
    this._lastLeaderModeState = spaceLeaderModeState;
    return {
      ...spaceLeaderModeState,
      currentUserIsLeader: this.isCurrentUserLeader(spaceLeaderModeState),
      currentUserIsLeaderInAnotherTab: this.isUserLeaderInAnotherTab(spaceLeaderModeState),
    };
  }

  private isUserLeaderInAnotherTab(spaceLeaderModeState: ISpaceLeaderMode): boolean {
    return (
      spaceLeaderModeState.currentLeaderUid === this.userService.user.value?.user?._id &&
      !this.isCurrentUserLeader(spaceLeaderModeState)
    );
  }

  private listenForNetworkDisconnection() {
    this.networkService.wbServer.subscribe((status) => {
      if (status === ConnectionStatus.DISCONNECTED && this.spaceRepo.activeSpace) {
        const leaderModeState = this.getSpaceLeaderModeStateUi();
        this.currentUserWasLeader =
          leaderModeState.currentLeaderTabId !== undefined &&
          this.isCurrentUserLeader(leaderModeState);
      }
    });
  }
}

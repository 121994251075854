<div
  class="dialog-container"
  [ngClass]="(isMobile$ | async) === true ? 'isMobile' : ''"
  *ngIf="(selectedRating$ | async) || {}"
>
  <div class="dialog-top-bar">
    <button mat-icon-button (click)="onClose()" class="dialog-top-bar-dismiss">
      <em class="material-icons font-18">close</em>
    </button>
  </div>
  <div
    class="dialog-bottom-bar"
    [ngClass]="
      (selectedRating$ | async) === RATING_SELECTION.UNSELECTED
        ? 'section-unselected'
        : 'section-selected'
    "
  >
    <span class="dialog-header-text">
      {{ headerText }}
    </span>
    <div class="dialog-rating-container">
      <div class="dialog-rating-button-container">
        <button
          class="dialog-rating-button"
          (click)="ratingToggled(true)"
          [ngClass]="(selectedRating$ | async) === RATING_SELECTION.NEGATIVE ? 'unselected' : ''"
        >
          <svg-icon [width]="24" [height]="24" [icon]="'thumbs_up'" class="selected"></svg-icon>
        </button>
        <button class="dialog-rating-button" [ngClass]="" (click)="ratingToggled(false)">
          <svg-icon
            *ngIf="(selectedRating$ | async) === RATING_SELECTION.NEGATIVE"
            [width]="24"
            [height]="24"
            [icon]="'thumbs_down_selected'"
          ></svg-icon>
          <svg-icon
            *ngIf="(selectedRating$ | async) !== RATING_SELECTION.NEGATIVE"
            [width]="24"
            [height]="24"
            [icon]="'thumbs_down'"
          ></svg-icon>
        </button>
      </div>
      <div
        [@fadeInOutHeight]
        *ngIf="RATING_REASONS[(selectedRating$ | async)!].length"
        class="dialog-rating-add-details-container"
      >
        <form [formGroup]="ratingForm">
          <div class="dialog-rating-add-details">
            <span class="dialog-details-text">
              {{ feedbackText }}
            </span>
            <div class="dialog-rating-pill-section">
              <ng-container *ngFor="let item of RATING_REASONS[(selectedRating$ | async)!]">
                <button
                  class="dialog-pill"
                  [ngClass]="(selectedItems$ | async)![item] === true ? 'selected' : ''"
                  (click)="pillToggled(item)"
                >
                  <div class="dialog-pill-text">{{ item }}</div>
                  <div
                    [@fadeInOutWidth]
                    *ngIf="(selectedItems$ | async)![item] === true"
                    class="dialog-pill-close"
                  >
                    <em class="material-icons font-16">close</em>
                  </div>
                </button>
              </ng-container>
            </div>
            <div class="dialog-rating-add-note">
              <div class="dialog-rating-add-note-container">
                <span class="dialog-rating-add-note-title">
                  {{ 'Add Note (Optional)' | translate }}
                </span>
                <mat-form-field class="dialog-rating-add-note-textbox" appearance="outline">
                  <input
                    matInput
                    formControlName="feedback"
                    [placeholder]="'Please describe your issue...' | translate"
                    (keydown.enter)="$event.preventDefault()"
                  />
                </mat-form-field>
              </div>
              <div class="dialog-rating-add-note-label">
                {{ 'Need help? Explore our ' | translate
                }}<a href="https://intercom.help/pencil/en/" target="_blank">{{
                  'help docs' | translate
                }}</a
                >{{ ' or ' | translate
                }}<a (click)="openIntercom()">{{ 'contact support' | translate }}</a
                >.
              </div>
            </div>
            <div class="dialog-rating-divider"></div>
            <div class="dialog-rating-send-feedback-container">
              <button
                mat-flat-button
                color="primary"
                (click)="submitFeedback()"
                class="dialog-rating-send-feedback"
                data-name="dialog-rating-send-feedback"
              >
                <i class="material-icons-outlined">send</i>
                <span class="text-capitalize">{{ 'Submit Feedback' | translate }}</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

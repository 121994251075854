import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-space-comment',
  templateUrl: './delete-space-comment.component.html',
  styleUrls: ['./delete-space-comment.component.scss'],
})
export class DeleteSpaceCommentComponent {
  constructor(private dialogRef: MatDialogRef<DeleteSpaceCommentComponent>) {}

  close(confirmDelete: boolean) {
    this.dialogRef.close(confirmDelete);
  }
}

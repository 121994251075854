import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Comlink from 'comlink';
import { DeviceDetectorService } from 'ngx-device-detector';
import { VirtualBackgroundType } from '../common/interfaces/rtc-interface';
import { VBWorker } from '../web-workers/vb.worker';
import { VBWebGLWorker } from '../web-workers/vb_webgl.worker';

import {
  VB_FRAME_RATE,
  VB_HEIGHT,
  VB_WIDTH,
  VIDEO_STREAM_CAPTURE_HEIGHT,
  VIDEO_STREAM_CAPTURE_WIDTH,
} from '../models/device-manger';
import {
  IconBackground,
  IconMessageToasterElement,
} from '../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { ToasterPopupStyle } from '../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { ERRORS } from '../common/utils/notification-constants';
import {
  ButtonToasterElement,
  ButtonToasterElementStyle,
} from '../ui/notification-toaster/button-toaster-element/button-toaster-element.component';
import { intercomArticles } from '../intercom-articles';
import { DeviceAndBrowserDetectorService } from './device-and-browser-detector.service';
import { TelemetryService } from './telemetry.service';
import {
  DEFAULT_NOTIFICAION_TIMEOUT,
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from './notification-toaster.service';
import { GpuDetectorService } from './gpu-detector.service';
import { FlagsService } from './flags.service';

declare let MediaStreamTrackGenerator: any;
declare let MediaStreamTrackProcessor: any;

@Injectable({
  providedIn: 'root',
})
export class VirtualBackgroundInsertableStreamService {
  private readonly VIRTUAL_BACKGROUND_TYPE = 'VIRTUAL_BACKGROUND_TYPE';
  private readonly VIRTUAL_BACKGROUND_IMAGE = 'VIRTUAL_BACKGROUND_IMAGE';
  private readonly DEFAULT_MAX_TIME_TO_APPLY_EFFECT_IN_SECS = 15;
  private readonly APPLY_VB_TIMEOUT_ERROR = 'apply virtual background timeout';

  private _virtualBackgroundType: VirtualBackgroundType = VirtualBackgroundType.NONE;
  private _lastBackgroundImageSource: string | undefined;
  private _vbWorkerWrapper?: Comlink.Remote<VBWorker | VBWebGLWorker>;
  private _worker?: Worker;
  private _sourceVideoTrack: MediaStreamTrack | null = null;
  private _generatedVideoTrack: MediaStreamTrack | null = null;
  private _onVbProcessingError?: () => void;
  private _maxTimeToApplyEffectInSecs =
    (this.flagsService.featureFlagsVariables.spaces_virtual_backgrounds
      ?.maxTimeToApplyEffectInSecs as number) || this.DEFAULT_MAX_TIME_TO_APPLY_EFFECT_IN_SECS;

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private deviceAndBrowserDetectorService: DeviceAndBrowserDetectorService,
    private telemetry: TelemetryService,
    private notificationToasterService: NotificationToasterService,
    private translateService: TranslateService,
    private gpuDetectorService: GpuDetectorService,
    private flagsService: FlagsService,
  ) {
    this.loadVirtualBackgroundStateFromLocalStorage();
    this.logVirtualBackgroundState();
  }

  set virtualBackgroundType(virtualBackgroundType: VirtualBackgroundType) {
    this._virtualBackgroundType = virtualBackgroundType;
    localStorage.setItem(this.VIRTUAL_BACKGROUND_TYPE, this._virtualBackgroundType);
    this.logVirtualBackgroundState();
  }

  set lastBackgroundImageSource(backgroundImageSource: string | undefined) {
    if (backgroundImageSource) {
      this._lastBackgroundImageSource = backgroundImageSource;
      localStorage.setItem(this.VIRTUAL_BACKGROUND_IMAGE, this._lastBackgroundImageSource);
    }
  }

  get lastBackgroundImageSource(): string | undefined {
    return this._lastBackgroundImageSource;
  }

  get virtualBackgroundType(): VirtualBackgroundType {
    return this._virtualBackgroundType;
  }

  get sourceTrack(): MediaStreamTrack | null {
    return this._sourceVideoTrack;
  }

  async applyVirtualBackgroundEffect(videoStream: MediaStream) {
    this.stopVirtualBackgroundProcessing();
    try {
      let timeoutId: NodeJS.Timer | undefined;
      const timeoutPromise = new Promise<never>((_, reject) => {
        timeoutId = setTimeout(() => {
          reject(new Error(this.APPLY_VB_TIMEOUT_ERROR));
        }, this._maxTimeToApplyEffectInSecs * 1000); // Convert seconds to milliseconds
      });

      const applyEffect = this.applyVbEffectThrowWebWorker(videoStream);

      const result = await Promise.race([applyEffect, timeoutPromise]);
      if (timeoutId) {
        clearTimeout(timeoutId); // Clear the timeout if applyEffect finishes first
      }
      return result;
    } catch (e) {
      this.stopVirtualBackgroundProcessing(); // stop the track being used to apply VB to not consume cpu/gpu
      const errorMessage = e instanceof Error ? e.message : JSON.stringify(e);
      if (errorMessage === this.APPLY_VB_TIMEOUT_ERROR) {
        // if a timeout happen then most probably the worker terminated so we will create a new one when needed again
        this._worker?.terminate();
        this._vbWorkerWrapper = undefined;
      }
      this.handleVirtualBackgroundError(errorMessage);
      throw e;
    }
  }

  stopVirtualBackgroundProcessing() {
    this._generatedVideoTrack?.stop();
    this._sourceVideoTrack?.stop();
  }

  isVirtualBackgroundMode() {
    return this._virtualBackgroundType !== VirtualBackgroundType.NONE;
  }

  isBrowserSupported() {
    return 'MediaStreamTrackProcessor' in window && 'MediaStreamTrackGenerator' in window;
  }

  isDeviceSupported() {
    return (
      !this.gpuDetectorService.hasMajorPerformanceCaveat() &&
      this.deviceDetectorService.isDesktop() &&
      !this.deviceAndBrowserDetectorService.isTabletDevice()
    );
  }

  isVirtualBackgroundSupported() {
    return this.isBrowserSupported() && this.isDeviceSupported();
  }

  private async applyVbEffectThrowWebWorker(videoStream: MediaStream) {
    if (this.virtualBackgroundType == VirtualBackgroundType.NONE) {
      return videoStream;
    }
    this._sourceVideoTrack = videoStream.getVideoTracks()[0].clone();
    this.applyNeededConstraints(this._sourceVideoTrack);

    const processedStream = new MediaStream();

    const trackProcessor = new MediaStreamTrackProcessor({ track: this._sourceVideoTrack });
    const trackGenerator = new MediaStreamTrackGenerator({ kind: 'video' });
    this._generatedVideoTrack = trackGenerator;

    const readable = trackProcessor.readable as any;
    const writable = trackGenerator.writable as any;

    if (!this._vbWorkerWrapper) {
      if (this.flagsService.featureFlagsVariables.spaces_virtual_backgrounds?.use_webgl) {
        // lazy load the webgl worker only when needed
        this._worker = new Worker(new URL('../web-workers/vb_webgl.worker', import.meta.url));
        const vbWebGLWorker = Comlink.wrap<typeof VBWebGLWorker>(this._worker);
        this._vbWorkerWrapper = await new vbWebGLWorker();
      } else {
        // lazy load the worker only when needed
        this._worker = new Worker(new URL('../web-workers/vb.worker', import.meta.url));
        const vbWorker = Comlink.wrap<typeof VBWorker>(this._worker);
        this._vbWorkerWrapper = await new vbWorker();
      }
    }
    this._vbWorkerWrapper.setCallerErrorCallback(
      Comlink.proxy((errorMessage: string) => {
        if (this._onVbProcessingError) {
          this._onVbProcessingError();
        }
        this.handleVirtualBackgroundError(
          `failture in the transform stream function: ${errorMessage}`,
        );
      }),
    );
    await this._vbWorkerWrapper.applyBackgroundEffect(
      Comlink.transfer(readable, [readable]),
      Comlink.transfer(writable, [writable]),
      this._virtualBackgroundType == 'background-image' ? this._lastBackgroundImageSource : null,
    );
    processedStream.addTrack(trackGenerator);
    return processedStream;
  }

  private handleVirtualBackgroundError(errorMessage: string) {
    this.telemetry.event('[Error Applying Virtual Background]', {
      virtualBackgroundError: errorMessage,
    });
    this.virtualBackgroundType = VirtualBackgroundType.NONE;
    this.showErrorMessageForApplyingVB();
  }

  set onVbProcessingError(cb: () => void) {
    this._onVbProcessingError = cb;
  }

  private loadVirtualBackgroundStateFromLocalStorage() {
    if (!this.isVirtualBackgroundSupported()) {
      // to not apply VB type on localstorage if not supported
      // this is mainly valuable when we add more constrains on who can use VB
      return;
    }
    const storedVirtualBackgroundType = localStorage.getItem(
      this.VIRTUAL_BACKGROUND_TYPE,
    ) as VirtualBackgroundType;
    if (storedVirtualBackgroundType) {
      this._virtualBackgroundType = storedVirtualBackgroundType;
    }
    const storedLastBackgroundImageSource = localStorage.getItem(this.VIRTUAL_BACKGROUND_IMAGE);
    if (storedLastBackgroundImageSource) {
      this._lastBackgroundImageSource = storedLastBackgroundImageSource;
    }
  }

  private applyNeededConstraints(videoTrack: MediaStreamTrack) {
    if (this._virtualBackgroundType == VirtualBackgroundType.NONE) {
      if (videoTrack.getConstraints().width != VIDEO_STREAM_CAPTURE_WIDTH) {
        videoTrack.applyConstraints({
          width: VIDEO_STREAM_CAPTURE_WIDTH,
          height: VIDEO_STREAM_CAPTURE_HEIGHT,
        });
      }
    } else {
      if (videoTrack.getConstraints().width != VB_WIDTH) {
        videoTrack.applyConstraints({
          width: VB_WIDTH,
          height: VB_HEIGHT,
          frameRate: VB_FRAME_RATE,
        });
      }
    }
  }

  private showErrorMessageForApplyingVB() {
    const titleElement = new IconMessageToasterElement(
      { svgIcon: 'cam_off_notification_icon' },
      this.translateService.instant('Could not start virtual background'),
      undefined,
      undefined,
      undefined,
      IconBackground.ERROR,
      true,
      true,
    );
    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(
        'We’ve turned off your camera for now. Please refresh the page and re-enable virtual background in settings.',
      ),
    );

    const actionButton = new ButtonToasterElement(
      [undefined, this.translateService.instant('Learn More')],
      {
        handler: () => {
          window.open(intercomArticles.VirtualBackgroundSupport);
        },
        close: false,
      },
      ButtonToasterElementStyle.LINK,
    );

    const notificationData = new NotificationDataBuilder(ERRORS.FAILED_APPLYING_VB)
      .style(ToasterPopupStyle.WARN)
      .type(NotificationType.WARNING)
      .width(304)
      .topElements([titleElement])
      .middleElements([messageElement])
      .bottomElements([actionButton])
      .version2Notification(true)
      .timeOut(DEFAULT_NOTIFICAION_TIMEOUT)
      .dismissable(true)
      .progressBarColor('#BBD8FF')
      .onActivateTick(true)
      .build();

    this.notificationToasterService.showNotification(notificationData);
  }

  private logVirtualBackgroundState() {
    this.telemetry.setSessionVars({
      virtual_background_chosen: this.isVirtualBackgroundMode(),
    });
  }
}

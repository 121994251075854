import * as moment from 'moment';
import { SUBSCRIPTION_TYPES } from '../models/payment';
import { User, roleValueIDs } from '../models/user';

export const userBannerTrailPeriodText = (
  subscriptionType: SUBSCRIPTION_TYPES,
  forceBillDate: Date,
) => {
  if (subscriptionType === SUBSCRIPTION_TYPES.FREE) {
    return 'your trial has expired. Click here to become a Pro again.';
  } else if (subscriptionType === SUBSCRIPTION_TYPES.TRIAL) {
    if (forceBillDate) {
      const remainingTrialDays = moment(forceBillDate).diff(Date.now(), 'd');
      if (remainingTrialDays === 0) {
        return 'free trial ends today. Click here to keep being a Pro.';
      }

      if (remainingTrialDays === 1) {
        return 'your trial ends in 1 day.';
      }
      return `your trial ends in ${remainingTrialDays} days. Click here to keep being a Pro.`;
    }
  }
  return '';
};

export const shouldShowBillingStatusIndicator = (user: User): boolean =>
  // Ignore students
  user.info?.role !== roleValueIDs.student && !user?.isAnonymous;

export const SHOULD_SHOW_BANNER_PERIOD = 10;

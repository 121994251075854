import { fabric } from 'fabric';
import { fromUint8Array } from 'js-base64';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, combineLatest, defer, EMPTY, merge, Observable, Subject } from 'rxjs';
import {
  filter,
  finalize,
  map,
  mergeMap,
  mergeWith,
  share,
  shareReplay,
  startWith,
  timestamp,
} from 'rxjs/operators';
import { MovedBoardsPlacement } from 'src/app/common/interfaces/sync-service-interface';
import { ScribbleType } from 'src/app/services/session-shared-data.service';
import { v4 as uuidv4 } from 'uuid';
import * as Y from 'yjs';
import {
  TemporaryUserMetadataEntryBase,
  TemporaryUserMetadataEntryYJS,
} from 'src/app/state/temporary-user-metadata-repository.service';
import { LessonProcessor } from 'src/app/services/lesson-generator.service';
import { BoardFolder, Frame, FrameItem, FramePrivacy, FrameType, Room } from '../../models/session';
import { SessionUpdate } from '../../models/session-sync';
import { ISpaceYjs } from '../../state/space.repository';
import { Manager } from './session-data-structures';

export enum UndoEnum {
  UNDO,
  REDO,
}

export interface YObject extends fabric.Object {
  // The user that created the object
  userId?: string;

  // The current version of the object
  // This is used to find the diff for two arrays of fabric elements
  vuid?: string;

  // Unique identifier for the object
  uid?: string;

  // Controls the state of this object, it takes 3 values
  // true | false | undefined
  // to prevent cyclic adding of objects we only process objects
  // whose fresh value is initially undefined
  fresh?: boolean;
  ScribbleType: ScribbleType;
}

export interface YCanvasItemObject extends FrameItem {
  // The user that created the object
  userId?: string;

  // The current version of the object
  // This is used to find the diff for two arrays of fabric elements
  vuid?: string;

  // Unique identifier for the object
  uid?: string;

  // Controls the state of this object, it takes 3 values
  // true | false | undefined
  // to prevent cyclic adding of objects we only process objects
  // whose fresh value is initially undefined
  fresh?: boolean;
}

enum SessionMetadataKey {
  CALL_ENDED = 'callEnded',
}

export function endCallKey(roomId?: string): string {
  return `${roomId}-${SessionMetadataKey.CALL_ENDED}`;
}

export interface SessionMetadata {
  [key: string]: boolean;
}

export interface Metadata {
  // Metadata per user
  // Key: userId
  userMetadata: UserMetadata;

  // Session level metadata, it can be used for any persisted state
  sessionMetadata: SessionMetadata;

  // Contains data with a lifetime the same as the socket connection
  // to the server
  // Key_1: socketId
  //      Key_2 userId
  temporaryUserMetadata: TemporaryUserMetadata;
}

export interface TemporaryUserMetadata {
  [key: string]: { [key: string]: TemporaryUserMetadataEntryYJS };
}
export interface UserMetadata {
  [key: string]: UserMetadataEntry;
}

export interface UserMetadataEntry {} // eslint-disable-line

/*
YJS Document Description
-------------------------
Plain text is constant
Text between <> is variable
-------------------------
ROOT (Y.Doc)
├─  <frame_uid> (Y.Arr<Y.Map>) # this is the array that holds the fabric Y.Maps
│   ├─ [entry] # A Y.Map containing the fabric data
├─  canvas-items-<frame_uid> (Y.Arr<Y.Map>) # this is the array that holds the canvas items Y.Maps
│   ├─ [entry] # A Y.Map containing the canvas item data
├─  frames<room_uid> (Y.Arr<Y.Map>) # this is the array that holds the frames of a particular room
│   ├─ [entry] # A Y.Map containing the canvas item data
├─  rooms (Y.arr<Y.Map>) # this is the array that holds the room Y.Maps
│   ├─ [entry] (Y.Map) # every entry in the frames Y.Arr is a Y.Map holds the value of the frame object
├─  userMetadata (Y.Map<UserMetadata>) # maps userId to the users metadata
│   ├─ [entry] (UserMetadata) # a plain object containing the metadata for that user
├─  sessionMetadata (Y.Map<unknown>) # session level metadata
├─  temporaryUserMetadata (Y.Map<TemporaryUserMetadata>) # maps socket.id to the users temporary metadata
│   ├─ [entry] (TemporaryUserMetadata) # an object that contains data whose lifetime is the same as the connection
---------------------------
ex: A space with 1 room, the room has two frames, each frame has two fabric items and 2 canvas items and there are two users in this space.
ROOT (Y.Doc)
{
  rooms: [
    {
      uid: room1_uid,
      name: room1
    },
  ],

  framesroom1_uid: [
    {
      uid: frame1_uid,
      name: frame 1
    },
    {
      uid: frame2_uid,
      name: frame 2
    }
  ]

  frame1_uid: [{fabricObject_1}, {fabricObject_2}]
  canvas-items-frame1_uid: [{canvasItem_1}, {canvasItem_2}]

  frame2_uid: [{fabricObject_1}, {fabricObject_2}]
  canvas-items-frame2_uid: [{canvasItem_1}, {canvasItem_2}]

  temporaryUserMetadata: {
    socketConnection_id1: {user_1 metadata}
    socketConnection_id2: {user_2 metadata}
  },
}
*/

export class YSessionBase {
  private readonly yDoc = new Y.Doc();
  private _cached = false;

  // Origin for all changes made to element arrays, used for undo / redo and sender if frameElementEvents
  protected readonly elementOrigin: string = uuidv4();

  // Origin for all changes made to frames, used to determine sender in frameEvents
  protected readonly frameOrigin: string = uuidv4();

  // Origin for all changes made to rooms, used to determine sender in roomEvents
  protected readonly roomOrigin: string = uuidv4();

  // origin for all changes made to board Folders, used to determine sender in foldersEvents
  protected readonly boardFoldersOrigin: string = uuidv4();

  private serverState?: Uint8Array;

  protected readonly undoManagers: Manager<Y.UndoManager>;

  public readonly yDocPendingChangesSubject: Subject<boolean> = new Subject<boolean>();

  constructor(private useFabricJson: boolean) {
    // Create a manager that gets a new undo manager for a given frameUID when get is called, only track your own origin
    this.undoManagers = new Manager((frameUID) => {
      const yFrameElements = this.getYFrameElements(frameUID);
      const yCanvasItemsElements = this.getYFrameCanvasItems(frameUID);
      return new Y.UndoManager([yFrameElements, yCanvasItemsElements], {
        trackedOrigins: new Set([this.elementOrigin]),
        captureTimeout: 150,
      });
    });

    this.yDoc.on('afterTransaction', (trans): void => {
      if (trans.doc.store.pendingStructs) {
        this.yDocPendingChangesSubject.next(true);
      } else {
        this.yDocPendingChangesSubject.next(false);
      }
    });
  }

  // Apply the Y.js update and update the sever state
  public applyUpdate(update: SessionUpdate): void {
    if (update.documentUpdate?.yjs_update) {
      const updateU8 = new Uint8Array(update.documentUpdate.yjs_update);
      Y.applyUpdate(this.yDoc, updateU8);
    }
    if (update.serverStateVector) {
      this.updateServerState(update.serverStateVector);
    }
  }

  /**
   * @returns true if there is apending update that is removed after applied the update
   */
  public applyUpdateAndRemovePendingUpdates(update: SessionUpdate): boolean {
    return this.yDoc.transact(() => {
      this.applyUpdate(update);
      return this.clearPendingUpdates();
    });
  }

  private hasPendingUpdates() {
    return !!this.yDoc.store.pendingStructs;
  }

  private clearPendingUpdates() {
    if (this.hasPendingUpdates()) {
      this.yDoc.store.pendingStructs = null;
      this.yDoc.store.pendingDs = null;
      return true;
    }
    return false;
  }

  public updateServerState(serverState: ArrayBuffer): void {
    this.serverState = new Uint8Array(serverState);
  }

  // Get the Y.Arr for the fabric elements
  protected getYFrameElements(frameUID: string): Y.Array<Y.Map<unknown> | YObject> {
    return this.yDoc.getArray(frameUID);
  }

  // Get the fabric elements as regular javascript objects
  protected getFrameElements(frameUID: string): YObject[] {
    return (
      this.getYFrameElements(frameUID)
        .toArray()
        // Because the entries in the YFrameElements may be a Map or a
        // JSON object, check to see if the
        .map(this.convertYFrameElementToObject)
    );
  }

  // Get the Y.Arr for the canvas items
  protected getYFrameCanvasItems(frameUid: string): Y.Array<Y.Map<any>> {
    const key = `canvas-items-${frameUid}`;
    return this.yDoc.getArray(key);
  }

  // Get the canvas items as regular javascript objects
  protected getFrameCanvasItems(frameUID: string): YCanvasItemObject[] {
    const canvasItems = this.getYFrameCanvasItems(frameUID)
      .toArray()
      .map((o) => o.toJSON() as YCanvasItemObject);
    for (const item of canvasItems) {
      if (item.position) {
        const positionMap = new Map();
        for (const [key, val] of Object.entries(item.position)) {
          positionMap.set(key, val);
        }
        item.position = positionMap;
      }
    }
    return canvasItems;
  }

  // Get the Y.Arr for the frames
  protected getYFrames(roomId: string): Y.Array<Y.Map<unknown>> {
    const key = `frames${roomId}`;
    return this.yDoc.getArray(key);
  }

  protected getYRooms(): Y.Array<Y.Map<unknown>> {
    return this.yDoc.getArray('rooms');
  }

  protected getYBoardFolders(): Y.Array<Y.Map<unknown>> {
    return this.yDoc.getArray('boardFolders');
  }

  protected getBoardFolders(): BoardFolder[] {
    return this.getYBoardFolders()
      .toArray()
      .map((f) => f.toJSON() as BoardFolder);
  }
  // Get the frames as regular javascript objects
  protected getFrames(roomId: string): Frame[] {
    return this.getYFrames(roomId)
      .toArray()
      .map((f) => f.toJSON() as Frame);
  }

  // Get the rooms as regular javascript objects
  protected getRooms(): Room[] {
    return this.getYRooms()
      .toArray()
      .map((f) => f.toJSON() as Room);
  }

  // Get the Y.Map for the session level metadata
  protected getYSessionMetadata(): Y.Map<unknown> {
    return this.yDoc.getMap('sessionMetadata');
  }

  // Get the session level metadata as a regular javascript object
  protected getSessionMetadata(): SessionMetadata {
    return this.getYSessionMetadata().toJSON();
  }

  // Get the Y.Map for the user level metadata
  // key: userId
  protected getYUserMetadata(): Y.Map<UserMetadataEntry> {
    return this.yDoc.getMap('userMetadata');
  }

  // Get the user level metadata as a regular javascript object
  protected getUserMetadata(): UserMetadata {
    return this.getYUserMetadata().toJSON();
  }

  // key: socket.id
  protected getYTemporaryUserMetadata(): Y.Map<TemporaryUserMetadataEntryYJS> {
    return this.yDoc.getMap('temporaryUserMetadata');
  }

  // Get the user level metadata as a regular javascript object
  protected getTemporaryUserMetadata(): { [key: string]: TemporaryUserMetadataEntryYJS } {
    return this.getYTemporaryUserMetadata().toJSON();
  }

  // Get the user socket level metadata as a regular javascript object
  public getTemporaryUserSocketMetadata(
    socketId: string,
  ): TemporaryUserMetadataEntryYJS | undefined {
    return this.getYTemporaryUserMetadata().get(socketId);
  }

  // Updates are always encoded against the last known server state
  // this is to ensure that we do not potentially drop data
  public getUpdate(): Uint8Array {
    return Y.encodeStateAsUpdate(this.yDoc, this.serverState);
  }

  /*
  Converts simple one level of depth objects to yMap
  TODO: (Mostafa) Should be deprecated and use objectToYMap_v2 instead
  */
  private objectToYMap<T extends object>(object: T): Y.Map<T> {
    const yMap = new Y.Map<T>();

    for (const [key, value] of Object.entries(object)) {
      yMap.set(key, value);
    }

    return yMap;
  }

  private convertYFrameElementToObject(yFrameElement: Y.Map<unknown> | YObject): YObject {
    if (yFrameElement instanceof Y.Map) {
      return yFrameElement.toJSON() as YObject;
    } else {
      return yFrameElement;
    }
  }

  /* Converts complex objects to yMap*/
  private objectToYMap_v2<T extends object>(object: T): Y.Map<T> {
    const yMap = new Y.Map<T>();
    for (const [key, value] of Object.entries(object)) {
      this.setYMapItem(yMap, key, value);
    }

    return yMap;
  }

  private setYMapItem(yMap: Y.Map<any>, key: string, val: any): void {
    // yjs accepts only these constructors as value in the yMap.
    const constructors = [Number, Object, Boolean, Array, String, Uint8Array, Y.Doc];
    if (val) {
      if (val?.constructor === Map) {
        // convert to object;
        const tmp: { [key: string]: any } = {};
        val.forEach((v, k) => {
          tmp[k] = v;
        });
        val = tmp;
      }
      if (!constructors.includes(val.constructor)) {
        val.constructor = Object;
      }
    }
    yMap.set(key, val);
  }

  private updateYMapFromObject<T extends object>(original: Y.Map<unknown>, object: T): void {
    // first remove any keys that are missing from `object`
    const objectKeys = Object.keys(object);
    for (const key of original.keys()) {
      if (!objectKeys.includes(key)) {
        original.delete(key);
      }
    }
    // now update remaining keys + add any new ones from `object`
    for (const [key, value] of Object.entries(object)) {
      if (!original.has(key) || !isEqual(original.get(key), value)) {
        (original as any).set(key, value);
      }
    }
  }

  public addObject(frameUID: string, object: YObject): Uint8Array {
    return this.addObjects(frameUID, [object]);
  }

  // add objects to the same board
  public addObjects(frameUID: string, objects: YObject[], trackUndoRedo = true): Uint8Array {
    const yFrameElements = this.getYFrameElements(frameUID);
    this.yDoc.transact(
      () => {
        if (this.useFabricJson) {
          yFrameElements.push(objects);
        } else {
          yFrameElements.push(objects.map((o) => this.objectToYMap<any>(o)));
        }
      },
      trackUndoRedo ? this.elementOrigin : null,
    );
    return this.getUpdate();
  }

  /*
   * Add each object to its board of creation
   */
  public addObjectsToDifferentBoards(
    objectsToBoardMap: Map<string | undefined, YObject[]>,
    trackUndoRedo = true,
  ): Uint8Array {
    let yFrameElements: Y.Array<Y.Map<unknown> | YObject>;
    this.yDoc.transact(
      () => {
        for (const [boardUid, objects] of objectsToBoardMap) {
          yFrameElements = this.getYFrameElements(<string>boardUid);
          if (this.useFabricJson) {
            for (const objectAdded of objects) {
              yFrameElements.push([objectAdded]);
            }
          } else {
            for (const objectAdded of objects) {
              yFrameElements.push([objectAdded].map((o) => this.objectToYMap<any>(o)));
            }
          }
        }
      },
      trackUndoRedo ? this.elementOrigin : null,
    );
    return this.getUpdate();
  }

  public addCanvasItems(
    frameUID: string,
    objects: YCanvasItemObject[],
    trackUndoRedo = true,
  ): Uint8Array {
    const yFrameCanvasItems = this.getYFrameCanvasItems(frameUID);

    this.yDoc.transact(
      () => {
        objects.forEach((object) => {
          yFrameCanvasItems.push([this.objectToYMap_v2(object)]);
        });
      },
      trackUndoRedo ? this.elementOrigin : null,
    );
    return this.getUpdate();
  }

  public modifyObjects(objectsToBoardMap: Map<string | undefined, YObject[]>): Uint8Array {
    for (const [boardUid, objects] of objectsToBoardMap) {
      this.yDoc.transact(() => {
        // get yFrame for each board
        const yFrameElements = this.getYFrameElements(<string>boardUid);
        const elements = this.getFrameElements(<string>boardUid);
        for (const object of objects) {
          const uid = object.uid;
          const index = elements.findIndex((o) => o.uid === uid);
          if (index < 0) {
            continue;
          }
          const yFrameElement = yFrameElements.get(index);
          if (yFrameElement instanceof Y.Map) {
            this.updateYMapFromObject(yFrameElement, object);
          } else {
            yFrameElements.delete(index);
            yFrameElements.insert(index, [object]);
          }
        }
      }, this.elementOrigin);
    }
    return this.getUpdate();
  }

  public updateObjectsOrder(
    frameUID: string,
    objectUid: string,
    newObjectIndex: number,
  ): Uint8Array {
    const yElements = this.getYFrameElements(frameUID);
    const elements = this.getFrameElements(frameUID);
    this.yDoc.transact(() => {
      for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        if (element['uid'] === objectUid) {
          yElements.delete(index);
          if (this.useFabricJson) {
            yElements.insert(newObjectIndex, [element]);
          } else {
            yElements.insert(newObjectIndex, [this.objectToYMap<any>(element)]);
          }
          break;
        }
      }
    }, this.elementOrigin);

    return this.getUpdate();
  }

  public modifyCanvasItems(frameUID: string, objects: YCanvasItemObject[]): Uint8Array {
    const yFrameElements = this.getYFrameCanvasItems(frameUID);
    const elements = this.getFrameCanvasItems(frameUID);

    this.yDoc.transact(() => {
      objects.forEach((object) => {
        const uid = object.uid;
        const index = elements.findIndex((o) => o.uid === uid);
        if (index < 0) {
          return;
        }
        const yFrameElement = yFrameElements.get(index);
        const objectKeys = Object.keys(object);
        for (const key of yFrameElement.keys()) {
          if (!objectKeys.includes(key)) {
            yFrameElement.delete(key);
          }
        }
        // now update remaining keys + add any new ones from `object`
        for (const [key, value] of Object.entries(object)) {
          if (!yFrameElement.has(key) || !isEqual(yFrameElement.get(key), value)) {
            this.setYMapItem(yFrameElement, key, value);
          }
        }
      });
    }, this.elementOrigin);

    return this.getUpdate();
  }

  public removeObject(frameUID: string, object: YObject): Uint8Array {
    return this.removeObjects(new Map([[frameUID, [object]]]));
  }

  /*
   * When an object is removed find its index and remove it from the yjs state
   */
  public removeObjects(objectsToBoardMap: Map<string | undefined, YObject[]>): Uint8Array {
    const uidSet = this.getUidsSet(objectsToBoardMap);
    const boardUids = objectsToBoardMap.keys();
    // for each Frame we have, check the existing uids to be removed
    for (const boardUid of boardUids) {
      const yFrameElements = this.getYFrameElements(<string>boardUid);
      const elements = this.getFrameElements(<string>boardUid);

      let indices: number[] = [];

      for (const [index, element] of elements.entries()) {
        if (element.uid && uidSet.has(element.uid)) {
          indices.push(index);
        }
      }

      // removing multiple elements by index can cause "index out of bounds" issues. In order to avoid this,
      // we sort the indices in reverse numerical order so we remove "from the back", thus removed indices will not cause issues
      // for the elements "in front of" the removed element
      indices = indices.sort((a, b) => b - a);

      this.yDoc.transact(() => {
        for (const index of indices) {
          if (index < 0) {
            continue;
          }
          yFrameElements.delete(index);
        }
      }, this.elementOrigin);
    }

    return this.getUpdate();
  }

  public removeCanvasItem(frameUID: string, id: string): Uint8Array {
    const yFrameElements = this.getYFrameCanvasItems(frameUID);
    const elements = this.getFrameCanvasItems(frameUID);

    const index = elements.findIndex((o) => o._id === id);

    this.yDoc.transact(() => {
      if (index < 0) {
        return;
      }
      yFrameElements.delete(index);
    }, this.elementOrigin);

    return this.getUpdate();
  }

  public isConvertedToRooms(): boolean {
    return this.getYDoc().getArray('rooms').length !== 0;
  }

  public addBoardFolder(boardFolder: BoardFolder) {
    const yBoardFolders = this.getYBoardFolders();
    this.yDoc.transact(() => {
      yBoardFolders.push([this.objectToYMap<any>(boardFolder)]);
    }, this.boardFoldersOrigin);
    return this.getUpdate();
  }

  public removeAllBoardFolders(roomUid: string) {
    const yBoardFolders = this.getYBoardFolders();
    const idxToDelete: number[] = [];

    for (let i = yBoardFolders.length - 1; i >= 0; i--) {
      if (yBoardFolders.get(i).get('roomUid') === roomUid) {
        idxToDelete.push(i);
      }
    }

    this.yDoc.transact(() => {
      for (const index of idxToDelete) {
        yBoardFolders.delete(index);
      }
    }, this.boardFoldersOrigin);

    return this.getUpdate();
  }

  public addRooms(rooms: Room[]): Uint8Array {
    const yRooms = this.getYRooms();
    const newYRooms: Y.Map<any>[] = [];
    for (const room of rooms) {
      newYRooms.push(this.objectToYMap_v2<any>(room));

      const frame = new Frame({
        name: 'Board 1',
        frameType: FrameType.WHITEBOARD,
      });
      const yFrames = this.getYFrames(room.uid);
      this.yDoc.transact(() => {
        yFrames.push([this.objectToYMap<any>(frame)]);
      });
    }
    this.yDoc.transact(() => {
      yRooms.push(newYRooms);
    }, this.roomOrigin);

    return this.getUpdate();
  }

  public deleteRooms(uids: string[]): Uint8Array {
    const yRooms = this.getYRooms();
    const indices: number[] = [];
    const roomUIDs = new Set<string>(uids);
    for (let i = 0; i < yRooms.length; ++i) {
      console.log('msg', yRooms.get(i).get('uid'), roomUIDs);
      if (yRooms.get(i).get('uid') && roomUIDs.has(yRooms.get(i).get('uid') as string)) {
        indices.push(i);
      }
    }

    if (indices.length > 0) {
      for (let i = indices.length - 1; i >= 0; --i) {
        this.yDoc.transact(() => {
          yRooms.delete(indices[i]);
        }, this.roomOrigin);
      }
    }

    return this.getUpdate();
  }

  public modifyRoom(room: Room): Uint8Array {
    const yRooms = this.getYRooms();

    this.yDoc.transact(() => {
      yRooms.forEach((yRoom, idx) => {
        if (yRoom.get('uid') === room.uid) {
          for (const [key, value] of Object.entries(room)) {
            if (!isEqual(yRoom.get(key), value)) {
              this.setYMapItem(yRoom, key, value);
            }
          }
        }
      });
    }, this.roomOrigin);

    return this.getUpdate();
  }

  public moveFrameBetweenRooms(movedBoardsPlacement: MovedBoardsPlacement[]): Uint8Array {
    const transactionData: {
      fromRoomYFrames: Y.Array<Y.Map<any>>;
      toRoomYFrames: Y.Array<Y.Map<any>>;
      removeAtIndices: number[];
      toMoveYFrames: Y.Map<any>[];
    }[] = [];

    movedBoardsPlacement.forEach(({ fromRoomId, toRoomId, framesIds }) => {
      const fromRoomFrames = this.getFrames(fromRoomId);
      const toRoomYFrames = this.getYFrames(toRoomId);
      const removeAtIndices: number[] = [];
      const toMoveFrames: Y.Map<any>[] = [];

      fromRoomFrames.forEach((frame, idx) => {
        if (frame.uid && framesIds.has(frame.uid)) {
          // reset board folder to frame if we're moving it to another room
          frame.boardFolderUid = undefined;
          toMoveFrames.push(this.objectToYMap_v2(frame));
          removeAtIndices.push(idx);
        }
      });

      transactionData.push({
        fromRoomYFrames: this.getYFrames(fromRoomId),
        toRoomYFrames: toRoomYFrames,
        removeAtIndices: removeAtIndices,
        toMoveYFrames: toMoveFrames,
      });
    });

    this.yDoc.transact(() => {
      transactionData.forEach(
        ({ fromRoomYFrames, toRoomYFrames, removeAtIndices, toMoveYFrames }) => {
          toRoomYFrames.push(toMoveYFrames);
          for (let i = removeAtIndices.length - 1; i >= 0; --i) {
            fromRoomYFrames.delete(removeAtIndices[i]);
          }
        },
      );
    }, this.frameOrigin);

    return this.getUpdate();
  }

  public addFrame(roomId: string, frame: Frame): Uint8Array {
    // Ensure that this is a valid object
    const yFrames = this.getYFrames(roomId);

    this.yDoc.transact(() => {
      yFrames.push([this.objectToYMap<any>(frame)]);
    }, this.frameOrigin);

    return this.getUpdate();
  }

  public addFrames(roomId: string, frames: Frame[]): Uint8Array {
    const yFrames = this.getYFrames(roomId);

    const newYFrames = frames.map((frame) => this.objectToYMap<any>(frame));

    if (newYFrames.length) {
      this.yDoc.transact(() => {
        yFrames.push(newYFrames);
      }, this.frameOrigin);
    }
    return this.getUpdate();
  }

  public modifyFrame(roomId: string, frame: Frame): Uint8Array {
    const yFrames = this.getYFrames(roomId);

    this.yDoc.transact(() => {
      yFrames.forEach((yFrame, idx) => {
        if (yFrame.get('uid') === frame.uid) {
          for (const [key, value] of Object.entries(frame)) {
            if (!isEqual(yFrame.get(key), value)) {
              this.setYMapItem(yFrame, key, value);
            }
          }
        }
      });
    }, this.frameOrigin);

    return this.getUpdate();
  }

  public modifyBoardFolder(boardFolder: BoardFolder): Uint8Array | undefined {
    let yBoardFolder: Y.Map<unknown> | undefined;
    const yBoardFolders = this.getYBoardFolders();

    for (let i = 0; i < yBoardFolders.length; i++) {
      if (yBoardFolders.get(i).get('uid') === boardFolder.uid) {
        yBoardFolder = yBoardFolders.get(i);
        break;
      }
    }

    if (!yBoardFolder) {
      return;
    }

    this.yDoc.transact(() => {
      for (const [key, value] of Object.entries(boardFolder)) {
        if (!isEqual(yBoardFolder?.get(key), value)) {
          this.setYMapItem(yBoardFolder as Y.Map<unknown>, key, value);
        }
      }
    }, this.boardFoldersOrigin);

    return this.getUpdate();
  }

  /**
   * Move the Frame to the new position
   * old position is removed and frames will be reordered
   * the new frame will be position in the new toIndex
   * @param roomId
   * @param frame
   * @param fromIndex
   * @param toIndex
   */
  public moveFrame(
    roomId: string,
    frame: Frame,
    fromIndex: number,
    toIndex: number,
  ): Uint8Array | null {
    const yFrames = this.getYFrames(roomId);
    // prevent the move if the frames changed just before applying the move
    if (yFrames.get(fromIndex).get('uid') !== frame.uid) {
      return null;
    }
    this.yDoc.transact(() => {
      yFrames.delete(fromIndex);
      yFrames.insert(toIndex, [this.objectToYMap<any>(frame)]);
    }, this.frameOrigin);
    return this.getUpdate();
  }

  updateFramePrivacy(roomId: string, frame: Frame): Uint8Array {
    const yFrames = this.getYFrames(roomId);
    this.yDoc.transact(() => {
      yFrames.forEach((yFrame) => {
        if (yFrame.get('uid') === frame.uid) {
          yFrame.set('privacy', frame.privacy);
          if (frame.privacy === FramePrivacy.PUBLIC) {
            yFrame.set('privateUsers', []);
          }
          if (frame.privacy === FramePrivacy.PRIVATE) {
            yFrame.set('privateUsers', frame.privateUsers);
          }
        }
      });
    }, this.frameOrigin);
    return this.getUpdate();
  }

  updateFrameBoardFolder(roomId: string, frame: Frame): Uint8Array | undefined {
    let yFrame: Y.Map<unknown> | undefined;
    const yFrames = this.getYFrames(roomId);

    for (let i = 0; i < yFrames.length; i++) {
      if (yFrames.get(i).get('uid') === frame.uid) {
        yFrame = yFrames.get(i);
        break;
      }
    }

    if (!yFrame) {
      return;
    }

    this.yDoc.transact(() => {
      yFrame?.set('boardFolderUid', frame.boardFolderUid);
    }, this.frameOrigin);

    return this.getUpdate();
  }

  /**
   * change the frame lock state inside the yjs document
   * if it is unlocked, lock it else unlock it
   * locked frames can be only edited with hosts/owners
   * @param roomId
   * @param frame
   */
  updateFrameLockState(roomId: string, frame: Frame): Uint8Array {
    const yFrames = this.getYFrames(roomId);
    this.yDoc.transact(() => {
      yFrames.forEach((yFrame) => {
        if (yFrame.get('uid') === frame.uid) {
          yFrame.set('locked', frame.locked);
        }
      });
    }, this.frameOrigin);
    return this.getUpdate();
  }

  /**
   * change the frame background pattern and color inside the yjs document
   * @param roomId
   * @param frame
   */
  updateFrameBackground(roomId: string, frame: Frame): Uint8Array {
    const yFrames = this.getYFrames(roomId);
    this.yDoc.transact(() => {
      yFrames.forEach((yFrame) => {
        if (yFrame.get('uid') === frame.uid) {
          yFrame.set('backgroundColor', frame.backgroundColor);
          yFrame.set('backgroundPattern', frame.backgroundPattern);
        }
      });
    }, this.frameOrigin);
    return this.getUpdate();
  }

  public clearFrameContent(roomId: string, frameUid: string): Uint8Array {
    const fabricItems = this.getYFrameElements(frameUid);
    const canvasItems = this.getYFrameCanvasItems(frameUid);
    this.undoManagers.get(frameUid).clear(true, true);

    this.yDoc.transact(() => {
      fabricItems.delete(0, fabricItems.length);
      canvasItems.delete(0, canvasItems.length);
    }, this.frameOrigin);

    return this.getUpdate();
  }

  removeBoardFolder(boardFolderUid: string): Uint8Array {
    const yBoardFolder = this.getYBoardFolders();
    const idxToDelete: number[] = [];
    for (let i = yBoardFolder.length - 1; i >= 0; i--) {
      if (yBoardFolder.get(i).get('uid') === boardFolderUid) {
        idxToDelete.push(i);
        break;
      }
    }

    this.yDoc.transact(() => {
      for (const index of idxToDelete) {
        yBoardFolder.delete(index);
      }
    }, this.boardFoldersOrigin);

    return this.getUpdate();
  }

  public removeFrame(roomId: string, frameUid: string): Uint8Array {
    const yFrames = this.getYFrames(roomId);
    const fabricItems = this.getYFrameElements(frameUid);
    const canvasItems = this.getYFrameCanvasItems(frameUid);
    const idxToDelete: number[] = [];
    // since we can't avoid frame duplication there may be multiple frames with the same frame id
    // removing multiple elements by index can cause "index out of bounds" issues. In order to avoid this,
    // we sort the indices in reverse numerical order so we remove "from the back", thus removed indices will not cause issues
    // for the elements "in front of" the removed element
    for (let i = yFrames.length - 1; i >= 0; i--) {
      if (yFrames.get(i).get('uid') === frameUid) {
        idxToDelete.push(i);
      }
    }
    this.yDoc.transact(() => {
      for (const index of idxToDelete) {
        yFrames.delete(index);
      }
      fabricItems.delete(0, fabricItems.length);
      canvasItems.delete(0, canvasItems.length);
    }, this.frameOrigin);

    return this.getUpdate();
  }

  // Returns an update if there was a change, otherwise it returns undefined
  public undoRedo(
    frameUID: string,
    type: UndoEnum,
  ): { updates: Uint8Array; updatedIds: string[] } | undefined {
    const undoManager = this.undoManagers.get(frameUID);

    const fabricElementsBeforeUndo = this.getFrameElements(frameUID);
    const canvasItemsBeforeUndo = this.getFrameCanvasItems(frameUID);

    switch (type) {
      case UndoEnum.UNDO:
        undoManager.undo();
        break;
      case UndoEnum.REDO:
        undoManager.redo();
        break;
      default:
        return;
    }

    const fabricElementsAfterUndo = this.getFrameElements(frameUID);
    const canvasItemsAfterUndo = this.getFrameCanvasItems(frameUID);

    const changedFabricItemsUIDs = this.getUIDDiff(
      fabricElementsBeforeUndo,
      fabricElementsAfterUndo,
    );
    const changedCanvasItemsUIDs = this.getUIDDiff(canvasItemsBeforeUndo, canvasItemsAfterUndo);

    // If no objects have changed then there was no op
    if (!changedFabricItemsUIDs.length && !changedCanvasItemsUIDs.length) {
      return;
    }

    return {
      updates: this.getUpdate(),
      updatedIds: changedFabricItemsUIDs,
    };
  }

  // Get a set of uids for all objects in the map
  private getUidsSet(objectsMap: Map<string | undefined, YObject[]>): Set<any> {
    const resultSet = new Set<any>();

    objectsMap.forEach((values) => {
      values.forEach((value) => {
        resultSet.add(value.uid);
      });
    });
    return resultSet;
  }

  // Gets the different uids from two arrays of fabric objects
  private getUIDDiff(s1: any[], s2: any[]): string[] {
    // Holds the mapping from uniqueID to versionID
    // O(n,m) way to check for object deltas
    const uidMap1: { [key: string]: string } = {};
    const uidMap2: { [key: string]: string } = {};
    const changedUIDs: Set<string> = new Set();

    s1.forEach((s1Obj) => {
      uidMap1[s1Obj.uid] = s1Obj.vuid;
    });

    s2.forEach((s2Obj) => {
      const uid = s2Obj.uid;
      uidMap2[uid] = s2Obj.vuid;

      if (!(uid in uidMap1) || uidMap1[uid] !== s2Obj.vuid) {
        changedUIDs.add(uid);
      }
    });

    s1.forEach((s1Obj) => {
      const uid = s1Obj.uid;
      if (!(uid in uidMap2)) {
        changedUIDs.add(uid);
      }
    });

    return Array.from(changedUIDs);
  }

  public modifySessionMetadata(metadata: Partial<SessionMetadata>): Uint8Array {
    const ySessionMetadata = this.getYSessionMetadata();

    for (const [key, value] of Object.entries(metadata)) {
      ySessionMetadata.set(key, value);
    }

    return this.getUpdate();
  }

  public modifyTemporaryUserMetadata(
    socketId: string,
    userId: string,
    temporaryUserMetadata: Partial<TemporaryUserMetadataEntryBase>,
  ): Uint8Array {
    const yUserMetadata = this.getYTemporaryUserMetadata();

    const currentTemporaryUserMetadata =
      yUserMetadata.get(socketId) || ({} as TemporaryUserMetadataEntryYJS);

    // Only update the changed properties
    for (const [key, value] of Object.entries(temporaryUserMetadata)) {
      currentTemporaryUserMetadata[key as keyof TemporaryUserMetadataEntryBase] = value;
    }

    currentTemporaryUserMetadata.userId = userId;
    yUserMetadata.set(socketId, currentTemporaryUserMetadata);

    return this.getUpdate();
  }

  public getYDoc(): Y.Doc {
    return this.yDoc;
  }

  public get cached(): boolean {
    return this._cached;
  }

  public set cached(cached: boolean) {
    this._cached = cached;
  }

  public getCreationId(): string {
    return this.yDoc.getText('creationId').toString();
  }

  public deduplicateYFrames(frames?: Record<string, Frame[]>): boolean {
    return Object.keys(frames ?? {})
      .map((roomUid) => this.deduplicateYFramesForRoom(roomUid))
      .some((didFrameDuplicationOccur) => didFrameDuplicationOccur);
  }

  private deduplicateYFramesForRoom(roomId: string) {
    const yFrames = this.getYFrames(roomId);

    return deduplicateUniqueYArray(
      yFrames,
      (yFrame) => yFrame.get('uid') as string,
      (yArray, lastIndex, duplicatedValue) => {
        const lastFrame = yArray.get(lastIndex);
        const combinedProps = new Frame({
          ...duplicatedValue.toJSON(),
          ...lastFrame.toJSON(),
        });

        for (const [key, value] of Object.entries(combinedProps)) {
          lastFrame.set(key, value);
        }
      },
    );
  }
}

export class YSession extends YSessionBase {
  public updateObservable$: Observable<Partial<ISpaceYjs>>;

  // These sets are used to ensure that we only subscribe to frames/rooms once
  // when creating the updateObservable$
  private readonly _roomSubscriptions: Set<string> = new Set();
  private readonly _frameSubscriptions: Set<string> = new Set();

  constructor(useFabricJson: boolean, private breakoutRoomsEnabled: boolean = false) {
    super(useFabricJson);
    this.updateObservable$ = this.createUpdateObservable();
  }

  /**
   * Creates an observables that emits when the store should be updated from a Y.Doc
   */
  private createUpdateObservable(): Observable<Partial<ISpaceYjs>> {
    type UpdateObservable = Observable<Partial<ISpaceYjs>>;

    // Gets an observable for a frame if it has not already been created
    const getFramesObservable = (roomUID: string): UpdateObservable => {
      if (this._roomSubscriptions.has(roomUID)) {
        return EMPTY;
      }
      this._roomSubscriptions.add(roomUID);

      return this.frames$(roomUID).pipe(
        map(({ frames, local, lastActionIsLocal }) => ({
          frames: { [roomUID]: frames },
          lastUpdateLocal: local,
          lastActionIsLocal: lastActionIsLocal,
        })),
      );
    };

    // Get an observable for the canvas objects if it has not already been created
    // note: this deals with both canvas items and fabric items
    const getCanvasObjectsObservable = (frameUID: string): UpdateObservable => {
      if (this._frameSubscriptions.has(frameUID)) {
        return EMPTY;
      }
      this._frameSubscriptions.add(frameUID);

      return this.canvasItems$(frameUID).pipe(
        map(({ canvasItems, local, lastActionIsLocal }) => ({
          canvasItems: { [frameUID]: canvasItems },
          lastUpdateLocal: local,
          lastActionIsLocal: lastActionIsLocal,
        })),
        mergeWith(
          this.fabricItems$(frameUID).pipe(
            map(({ fabricItems, local, lastActionIsLocal }) => ({
              fabricItems: { [frameUID]: fabricItems },
              lastUpdateLocal: local,
              lastActionIsLocal: lastActionIsLocal,
            })),
          ),
        ),
      );
    };

    const metadata$: UpdateObservable = this.metadata$().pipe(
      map(({ metadata, local }) => ({ metadata, lastUpdateLocal: local })),
    );

    const boardFolders$: UpdateObservable = this.boardFolders$().pipe(
      map(({ boardFolders, local }) => ({ boardFolders, lastUpdateLocal: local })),
    );

    // If breakout rooms is disabled we need to add a "dummy" room to start off the
    // observable chain.
    // note: uid = "" is the UID used when breakout rooms is disabled
    const defaultRoom = new Room({ uid: '' });

    const rooms$: UpdateObservable = (
      this.breakoutRoomsEnabled
        ? this.rooms$()
        : new BehaviorSubject({ rooms: [defaultRoom], local: false })
    ).pipe(
      map(({ rooms, local }) => ({ rooms, lastUpdateLocal: local })),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    const frames$ = rooms$.pipe(
      mergeMap(({ rooms }) => {
        const observables = rooms?.map((room) => getFramesObservable(room.uid)) ?? [];
        return merge(...observables);
      }),
      // We need to share rooms because it is subscribed to twice
      // once for canvasObjects$ and once for the final merge
      share(),
    );

    const canvasObjects$ = frames$.pipe(
      mergeMap(({ frames }) => {
        if (!frames) {
          return EMPTY;
        }
        const observables = Object.values(frames)
          .flat(1)
          .map((board) => getCanvasObjectsObservable(board.uid));

        return merge(...observables);
      }),
    );

    return merge(metadata$, canvasObjects$, frames$, rooms$, boardFolders$);
  }

  // createYObservable takes in any y.js type and then creates an observable from any changes made on that object
  private createYObservable<E, Y>(
    yType: Y.AbstractType<E>,
    func: (local: boolean, lastActionIsLocal: boolean) => Y,
  ): Observable<Y> {
    const subject: Subject<Y> = new Subject();

    // When a change is made we call this function, which runs the callers func and uses that result
    // as the next output to the subject
    const f = (ev: Array<Y.YEvent<any>>, transaction: Y.Transaction) => {
      // Undo / Redo are local events, but should be processed like remote ones
      const local =
        transaction.local && !(transaction?.origin?.undoing || transaction?.origin?.redoing);
      const lastActionIsLocal =
        transaction.local || !!transaction?.origin?.undoing || !!transaction?.origin?.redoing;
      const res = func(local, lastActionIsLocal);
      subject.next(res);
    };

    // Observe changes to the Y.Type and its children
    yType.observeDeep(f);

    const observable = subject.pipe(
      // When the observable completes or errors. In our case because share() does
      // multicasting and recounting this will be called when all subscribers unsubscribe
      finalize(() => {
        // Remove the event listener on the Y.Type
        yType.unobserveDeep(f);
      }),
      share(),
    );

    return observable;
  }

  private fabricItems$(
    frameUID: string,
  ): Observable<{ fabricItems: YObject[]; local: boolean; lastActionIsLocal: boolean }> {
    this.undoManagers.get(frameUID);

    const yFrameElements = this.getYFrameElements(frameUID);

    const func = (
      local: boolean,
      lastActionIsLocal: boolean,
    ): { fabricItems: YObject[]; local: boolean; lastActionIsLocal: boolean } =>
      // Depending on what the origin was we can determine if it was made locally or remote
      ({ fabricItems: this.getFrameElements(frameUID), local, lastActionIsLocal });
    // Defer will call the callback when there is a subscriber to this function, this ensures that
    // the startWith value is always fresh
    return defer(() =>
      this.createYObservable(yFrameElements, func).pipe(
        startWith({
          fabricItems: this.getFrameElements(frameUID),
          local: false,
          lastActionIsLocal: false,
        }),
      ),
    );
  }

  private canvasItems$(
    frameUID: string,
  ): Observable<{ canvasItems: YCanvasItemObject[]; local: boolean; lastActionIsLocal: boolean }> {
    this.undoManagers.get(frameUID);

    const yFrameElements = this.getYFrameCanvasItems(frameUID);
    const func = (
      local: boolean,
      lastActionIsLocal: boolean,
    ): { canvasItems: YCanvasItemObject[]; local: boolean; lastActionIsLocal: boolean } =>
      // Depending on what the origin was we can determine if it was made locally or remote
      ({ canvasItems: this.getFrameCanvasItems(frameUID), local, lastActionIsLocal });

    // Defer will call the callback when there is a subscriber to this function, this ensures that
    // the startWith value is always fresh
    return defer(() =>
      this.createYObservable(yFrameElements, func).pipe(
        startWith({
          canvasItems: this.getFrameCanvasItems(frameUID),
          local: false,
          lastActionIsLocal: false,
        }),
      ),
    );
  }

  private frames$(
    roomId: string,
  ): Observable<{ frames: Frame[]; local: boolean; lastActionIsLocal: boolean }> {
    const yFrames = this.getYFrames(roomId);

    const func = (
      local: boolean,
      lastActionIsLocal: boolean,
    ): { frames: Frame[]; local: boolean; lastActionIsLocal: boolean } => ({
      frames: this.getFrames(roomId),
      local,
      lastActionIsLocal,
    });

    // Use defer tp ensure that the value of startWith is fresh
    return defer(() =>
      this.createYObservable(yFrames, func).pipe(
        startWith({ frames: this.getFrames(roomId), local: false, lastActionIsLocal: false }),
        filter(({ frames }) => frames.length > 0),
      ),
    );
  }

  private rooms$(): Observable<{ rooms: Room[]; local: boolean }> {
    const yRooms = this.getYRooms();

    const func = (local: boolean) => ({ rooms: this.getRooms(), local });

    // Use defer tp ensure that the value of startWith is fresh
    return defer(() =>
      this.createYObservable(yRooms, func).pipe(
        startWith({ rooms: this.getRooms(), local: false }),
        filter(({ rooms }) => rooms.length > 0),
      ),
    );
  }

  private boardFolders$(): Observable<{ boardFolders: BoardFolder[]; local: boolean }> {
    const yBoardFolders = this.getYBoardFolders();

    const func = (local: boolean) => ({ boardFolders: this.getBoardFolders(), local });

    // Use defer tp ensure that the value of startWith is fresh
    return defer(() =>
      this.createYObservable(yBoardFolders, func).pipe(
        startWith({ boardFolders: this.getBoardFolders(), local: false }),
      ),
    );
  }

  private metadata$(): Observable<{ metadata: Metadata; local: boolean }> {
    const yUserMetadata = this.getYUserMetadata();
    const yTemporaryUserMetadata = this.getYTemporaryUserMetadata();
    const ySessionMetadata = this.getYSessionMetadata();

    const func1 = (local: boolean) => ({ metadata: this.getUserMetadata(), local });
    const func2 = (local: boolean) => ({ metadata: this.getSessionMetadata(), local });
    const func3 = (local: boolean) => ({ metadata: this.getTemporaryUserMetadata(), local });

    const o1 = this.createYObservable(yUserMetadata, func1).pipe(timestamp());
    const o2 = this.createYObservable(ySessionMetadata, func2).pipe(timestamp());
    const o3 = this.createYObservable(yTemporaryUserMetadata, func3).pipe(timestamp());

    return defer(() =>
      combineLatest(
        [
          o1.pipe(startWith({ value: func1(false), timestamp: Date.now() })),
          o2.pipe(startWith({ value: func2(false), timestamp: Date.now() })),
          o3.pipe(startWith({ value: func3(false), timestamp: Date.now() })),
        ],
        (userMetadata, sessionMetadata, temporaryUserMetadata) => {
          const temporaryUserMetadataFormatted: {
            [key: string]: { [key: string]: TemporaryUserMetadataEntryYJS };
          } = {};

          for (const [socketId, tempData] of Object.entries(temporaryUserMetadata.value.metadata)) {
            const userId = tempData.userId;
            if (!userId) {
              continue;
            }

            if (!(userId in temporaryUserMetadataFormatted)) {
              temporaryUserMetadataFormatted[userId] = {};
            }
            temporaryUserMetadataFormatted[userId][socketId] = tempData;
          }

          // Get the local from the largest timestampt
          const local = [userMetadata, sessionMetadata, temporaryUserMetadata].sort(
            (a, b) => b.timestamp - a.timestamp,
          )[0].value.local;

          return {
            metadata: {
              userMetadata: userMetadata.value.metadata,
              sessionMetadata: sessionMetadata.value.metadata,
              temporaryUserMetadata: temporaryUserMetadataFormatted,
            },
            local,
          };
        },
      ),
    );
  }

  public getUndoStackObservable(
    frameUID: string,
  ): Observable<{ undoStack: number; redoStack: number }> {
    const undoManager = this.undoManagers.get(frameUID);

    const subject: Subject<{ undoStack: number; redoStack: number }> = new Subject();

    const func1 = () => ({
      undoStack: undoManager.undoStack.length || 0,
      redoStack: undoManager.redoStack.length || 0,
    });

    const func2 = () => {
      subject.next(func1());
    };

    undoManager.on('stack-item-added', func2);
    undoManager.on('stack-item-popped', func2);

    const observable = subject.pipe(
      finalize(() => {
        undoManager.off('stack-item-added', func2);
        undoManager.off('stack-item-popped', func2);
      }),
      share(),
      startWith(func1()),
    );

    return observable;
  }

  public clearTemporaryUserMetadata = (socketId: string): Uint8Array => {
    const yTemporaryUserMetadata = this.getYTemporaryUserMetadata();
    yTemporaryUserMetadata.set(socketId, {} as TemporaryUserMetadataEntryYJS);

    return this.getUpdate();
  };

  public removeTemporaryUserMetadata = (socketId: string): Uint8Array => {
    const yTemporaryUserMetadata = this.getYTemporaryUserMetadata();
    yTemporaryUserMetadata.delete(socketId);

    return this.getUpdate();
  };

  public getClientStateVector(): Uint8Array {
    return Y.encodeStateVector(this.getYDoc());
  }

  public getYDocSize(): number {
    return Y.encodeStateAsUpdate(this.getYDoc()).byteLength;
  }

  public getYDocAsJSON(): { [key: string]: any } {
    const YDocKeys = this.getYDoc().share.keys();
    const YDocJSON: { [key: string]: any } = {};
    for (const key of YDocKeys) {
      YDocJSON[key] = this.getYDoc().get(key).toJSON();
    }
    return YDocJSON;
  }

  public getYDocAsBase64(): string {
    const documentState = this.getYDocAsBinary();
    return fromUint8Array(documentState); // Transform Uint8Array to a Base64-String
  }

  public getYDocAsBinary(): Uint8Array {
    const newYDoc = new Y.Doc();
    const documentState = Y.encodeStateAsUpdate(this.getYDoc());
    const blackListedKeys = ['userMetadata', 'temporaryUserMetadata', 'sessionMetadata'];

    Y.applyUpdate(newYDoc, documentState);

    for (const blackListedKey of blackListedKeys) {
      const yMap = newYDoc.getMap(blackListedKey);
      const yMapKey = yMap.keys();

      for (const key of yMapKey) {
        yMap.delete(key);
      }
    }

    const newDocumentState = Y.encodeStateAsUpdate(newYDoc);
    return newDocumentState;
  }

  insertAiGeneratedLesson(lesson: LessonProcessor) {
    // Create board folder
    this.getYDoc().transact(() => {
      const boardFolder = lesson.getBoardFolder();
      this.addBoardFolder(boardFolder);
      const room = this.getRooms()[0];
      const frames = lesson.getFrames();

      // Add frames to the room
      for (const frame of frames) {
        this.addFrame(room.uid, frame);
      }

      // Add items to the frames
      const fabricObjects = lesson.getFabricObjects();
      for (const [frameUid, objects] of fabricObjects.entries()) {
        this.addObjects(frameUid, objects, false);
      }

      // Add canvas items
      const frameItems = lesson.getFrameItems();
      for (const [frameUid, items] of frameItems.entries()) {
        this.addCanvasItems(frameUid, items, false);
      }
    });
    return this.getUpdate();
  }
}

/**
 * setterFn: typically this will be used to update the index at `lastIndex` to merge values with
 *           the duplicated value. If unset duplicated values will just be deleted
 */
const deduplicateUniqueYArray = <T>(
  yArray: Y.Array<T>,
  keyFn: (item: T) => string,
  mergeFn?: (yArray: Y.Array<T>, lastIndex: number, duplicatedValue: T) => void,
) => {
  try {
    const seenItems: Record<string, number> = {};
    const itemsToDelete: number[] = [];

    for (let i = yArray.length - 1; i >= 0; i--) {
      const item = yArray.get(i);
      const key = keyFn(item);

      if (key in seenItems) {
        // Mark this item for deletion
        itemsToDelete.push(i);

        // If a setter function is provided, use it to update the previously seen item
        // This assumes setterFn can handle merging or updating items as necessary
        if (mergeFn) {
          mergeFn(yArray, seenItems[key], item);
        }
      } else {
        // Store the index of the first occurrence of this item
        seenItems[key] = i;
      }
    }

    // Delete marked items in reverse order to avoid shifting indices affecting the deletion
    for (const itemToDelete of itemsToDelete) {
      yArray.delete(itemToDelete);
    }

    return itemsToDelete.length > 0;
  } catch (err) {
    console.warn('Failed to deduplicate unique yArray', err);
  }
};

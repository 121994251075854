import { Pipe, PipeTransform } from '@angular/core';
import { SpaceRepository } from '../state/space.repository';

@Pipe({
  name: 'breakoutRoomName',
})
export class BreakoutRoomNamePipe implements PipeTransform {
  constructor(private spaceRepo: SpaceRepository) {}

  transform(roomId: string, spaceId: string): string {
    roomId = roomId === 'MainRoom' ? `${spaceId}:MainRoom` : roomId;
    const roomName = this.spaceRepo.getSpaceRooms(spaceId).find((r) => r.uid === roomId)?.name;

    if (!roomName) {
      return 'Deleted Breakout Room';
    }

    return roomName;
  }
}

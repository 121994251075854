import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import pdfjsLib from 'src/assets/pdfjs-config';
import { IconTypes } from '../../../../../../standalones/components/pencil-icon/pencil-icon.component';

@Component({
  selector: 'app-preview-page-item',
  templateUrl: './preview-page-item.component.html',
  styleUrls: ['./preview-page-item.component.scss'],
})
export class PreviewPageItemComponent implements OnDestroy, AfterViewInit {
  @Input() isSelected = false;
  @Input() pageNumber = -1;
  @Input() page?: pdfjsLib.PDFPageProxy;
  @Input() viewport?: pdfjsLib.PageViewport;
  @Output() toggleSelection = new EventEmitter();

  @ViewChild('canvas') canvasElm?: ElementRef;

  protected readonly IconTypes = IconTypes;

  ngAfterViewInit() {
    this.renderPdfPageToCanvas();
  }

  renderPdfPageToCanvas(): void {
    if (!this.page || !this.canvasElm?.nativeElement) {
      return;
    }

    const canvas = this.canvasElm?.nativeElement as HTMLCanvasElement;
    const viewport = this.page.getViewport({ scale: 1.0 });
    canvas.style.height = '100%';
    canvas.style.width = '100%';
    canvas.width = viewport.width;
    canvas.height = viewport.height;

    const context = canvas.getContext('2d', { alpha: false });
    if (context) {
      this.page.render({
        canvasContext: context,
        viewport,
      });
    }
  }

  ngOnDestroy(): void {
    this.page?.cleanup();
  }
}

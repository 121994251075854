import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { defaultQuestionFilterParams, getQuestionFilterParamsBase64 } from 'src/app/models/params';
import {
  IndividualUserOverview,
  UserDirectoryService,
  UserDirectoryEvent,
  UserDirectoryExperience,
} from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Feature, AclService } from 'src/app/services/acl.service';
import { UploadFileService } from 'src/app/services/upload-file.service';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { percentage } from '@angular/fire/storage';

@UntilDestroy()
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  @Input() fullProfile?: IndividualUserOverview;
  @Output() showList = new EventEmitter();

  currentEmail = '';
  editable = false;
  uploadPercent?: Observable<number | undefined>;
  services: UserDirectoryService[] = [];
  events: UserDirectoryEvent[] = [];
  experiences: UserDirectoryExperience[] = [];
  currentLocations: any[] = [];
  teachingLocations: any[] = [];
  langCodes: any[] = [];
  phones: any[] = [];
  video = '';
  tagline = '';
  banner = '';
  isValid = true;
  trainerUrl = '';
  subjects: any[] = [];
  subjectIds: string[] = [];
  Features = Feature;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    public uploadService: UploadFileService,
    private toastrService: ToastrService,
    private translate: TranslateService,
    public aclService: AclService,
  ) {
    this.authService.firebaseUser.pipe(untilDestroyed(this)).subscribe((res) => {
      this.currentEmail = res?.email || '';
    });
  }

  ngOnInit(): void {
    if (this.currentEmail === this.fullProfile?.email) {
      this.showList.emit(false);
      if (this.fullProfile.subjects) {
        this.subjects = this.fullProfile.subjects;
      }
      if (this.fullProfile.directory_profile) {
        if (this.fullProfile.directory_profile.username) {
          this.trainerUrl = `${environment.trainerUrl}/profile/${this.fullProfile.directory_profile.username}`;
        } else {
          this.trainerUrl = `${environment.trainerUrl}/profile/?id=${this.fullProfile.directory_profile._id}`;
        }
        if (this.fullProfile.directory_profile.services) {
          this.services = this.fullProfile.directory_profile.services;
        }
        if (this.fullProfile.directory_profile.events) {
          this.events = this.fullProfile.directory_profile.events;
        }
        if (this.fullProfile.directory_profile.subjects) {
          this.subjectIds = this.fullProfile.directory_profile.subjects;
        }
        if (this.fullProfile.directory_profile.phone) {
          this.fullProfile.directory_profile.phone.forEach((phone: string) => {
            this.phones.push({ phone: phone });
          });
        }
        if (this.fullProfile.directory_profile.langCodes) {
          this.fullProfile.directory_profile.langCodes.forEach((lang: string) => {
            this.langCodes.push({ language: lang });
          });
        }
        if (this.fullProfile.directory_profile.currentLocations) {
          this.fullProfile.directory_profile.currentLocations.forEach((loc: string) => {
            this.currentLocations.push({ location: loc });
          });
        }
        if (this.fullProfile.directory_profile.teachingLocations) {
          this.fullProfile.directory_profile.teachingLocations.forEach((loc: string) => {
            this.teachingLocations.push({ location: loc });
          });
        }
        if (this.fullProfile.directory_profile.experience) {
          this.experiences = this.fullProfile.directory_profile.experience;
        }
        this.video = this.fullProfile.directory_profile.video;
        this.tagline = this.fullProfile.directory_profile.tagline;
        this.banner = this.fullProfile.directory_profile.bannerPic;
      }
    }
  }

  navigateToNewUser(): void {
    const urlParams = defaultQuestionFilterParams();
    urlParams.author = this.fullProfile?._id;
    this.router.navigate(['/content'], {
      queryParams: getQuestionFilterParamsBase64(urlParams),
    });
  }

  async update(): Promise<void> {
    if (!this.fullProfile) {
      return;
    }
    if (this.aclService.isAllowed(this.fullProfile, this.Features.PDProfile)) {
      this.checkEventValid();
      if (!this.isValid) {
        this.toastrService.error(this.translate.instant('Fill out required fields'));
        return;
      }
      this.userService.appLoading.next(true);
      const directory: { [key: string]: any } = {};
      if (this.services && this.services.length > 0) {
        directory['services'] = this.services;
      }
      if (this.events && this.events.length > 0) {
        directory['events'] = this.events;
      }
      if (this.experiences && this.experiences.length > 0) {
        directory['experience'] = this.experiences;
      }
      if (this.currentLocations && this.currentLocations.length > 0) {
        directory['currentLocations'] = this.currentLocations.map((loc) => loc.location);
      }
      if (this.teachingLocations && this.teachingLocations.length > 0) {
        directory['teachingLocations'] = this.teachingLocations.map((loc) => loc.location);
      }
      if (this.langCodes && this.langCodes.length > 0) {
        directory['langCodes'] = this.langCodes.map((lang) => lang.language);
      }
      if (this.phones && this.phones.length > 0) {
        directory['phone'] = this.phones.map((phone) => phone.phone);
      }
      if (this.subjectIds && this.subjectIds.length > 0) {
        directory['subjects'] = this.subjectIds;
      }
      directory['video'] = this.video;
      directory['tagline'] = this.tagline;
      directory['bio'] = this.fullProfile.description;
      directory['bannerPic'] = this.banner;
      if (this.fullProfile.profile_image) {
        directory['profilePic'] = this.fullProfile.profile_image;
      }
      if (this.fullProfile.name) {
        directory['name'] = this.fullProfile.name;
      }
      try {
        const res = await this.userService
          .updateUserProfile(this.fullProfile._id, directory)
          .toPromise();
        if (res.tutor_profile) {
          if (res.tutor_profile.username) {
            this.trainerUrl = `${environment.trainerUrl}/profile/${res.tutor_profile.username}`;
          } else {
            this.trainerUrl = `${environment.trainerUrl}/profile/?id=${res.tutor_profile._id}`;
          }
        }
        this.userService.appLoading.next(false);
      } catch (error) {
        this.userService.appLoading.next(false);
      }
    }

    this.userService.appLoading.next(true);

    try {
      await this.userService.updateUser(this.fullProfile).toPromise();
      this.userService.appLoading.next(false);
      this.editable = false;
    } catch (error) {
      this.userService.appLoading.next(false);
      this.editable = false;
    }
  }

  upload(event: Event): void {
    const file = (event.target as HTMLInputElement)?.files?.[0];
    if (file) {
      const reader = new FileReader();
      const task = this.uploadService.uploadToFireStorage(file, (data) => {
        if (this.fullProfile) {
          this.fullProfile.profile_image = data;
        }
      });
      if (task) {
        percentage(task)
          .pipe(untilDestroyed(this))
          .subscribe((data) => {
            this.uploadPercent = of(data.progress);
          });
        reader.readAsDataURL(file);
      }
    }
  }

  uploadBannerImg(event: Event): void {
    const file = (event.target as HTMLInputElement)?.files?.[0];
    if (file) {
      const reader = new FileReader();
      this.uploadService.uploadToFireStorage(file, (data) => {
        this.banner = data;
      });
      reader.readAsDataURL(file);
    }
  }

  checkEventValid(): void {
    this.isValid = true;
    this.events.forEach((e) => {
      if (!e.date || !e.month || !e.name || !e.location || !e.description) {
        this.isValid = false;
      }
    });
  }

  addLanguage(): void {
    this.langCodes.push({ language: '' });
  }

  removeLanguage(index: number): void {
    this.langCodes.splice(index, 1);
  }

  addPhone(): void {
    this.phones.push({ phone: '' });
  }

  removePhone(index: number): void {
    this.phones.splice(index, 1);
  }

  addService(): void {
    this.services.push(new UserDirectoryService());
  }

  removeService(index: number): void {
    this.services.splice(index, 1);
  }

  addExperience(): void {
    this.experiences.push(new UserDirectoryExperience());
  }

  removeExperience(index: number): void {
    this.experiences.splice(index, 1);
  }

  addEvent(): void {
    this.events.push(new UserDirectoryEvent());
    this.checkEventValid();
  }

  removeEvent(index: number): void {
    this.events.splice(index, 1);
    this.checkEventValid();
  }

  addCurrentLocation(): void {
    this.currentLocations.push({ location: '' });
  }

  removeCurrentLocation(index: number): void {
    this.currentLocations.splice(index, 1);
  }

  addTeachingLocation(): void {
    this.teachingLocations.push({ location: '' });
  }

  removeTeachingLocation(index: number): void {
    this.teachingLocations.splice(index, 1);
  }

  changeEventDate(index: number, event: Event): void {
    const element = event.target as HTMLInputElement;
    this.events[index].month = new Date(element.value).toDateString().substr(4, 3);
    this.events[index].date = element.value.split('-')[2];
  }
}

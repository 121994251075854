<ng-container *ngIf="data$ | async as data; else loading">
  <div class="pdf-viewer-container">
    <app-pdf-page
      *ngFor="let item of data"
      [page]="item.page"
      [viewport]="item.viewport"
      [scaleFactor]="scaleFactor"
      [height]="item.height"
      [width]="item.width"
    ></app-pdf-page>
  </div>
</ng-container>
<ng-template #loading>
  <div class="pdf-viewer-placeholder">
    <mat-spinner [strokeWidth]="2" [diameter]="27"></mat-spinner>
  </div>
</ng-template>

<div [hidden]="loading" class="wrapper">
  <div id="events-table-wrapper">
    <table
      mat-table
      matTableExporter
      [dataSource]="dataSource"
      id="events-table"
      class="full-width-table w-100 pt-5"
      aria-label="Events"
      matSort
      #exporter="matTableExporter"
    >
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
      </ng-container>

      <!-- Id Column -->
      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Time</th>
        <td mat-cell *matCellDef="let row">{{ row.time }}</td>
      </ng-container>

      <!-- Event Type Column -->
      <ng-container matColumnDef="eventType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Event Type</th>
        <td mat-cell *matCellDef="let row">{{ row.eventType }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div class="d-flex flex-column flex-md-row">
      <button
        class="downloadButton"
        (click)="exporter.exportTable('csv', { fileName: 'space_raw_events.csv' })"
      >
        <svg-icon [icon]="'download'" [width]="18" [height]="18"></svg-icon>
      </button>
      <span class="example-spacer"></span>
      <mat-paginator
        #paginator
        id="paginator"
        [length]="dataSource?.data?.length"
        [pageIndex]="0"
        [pageSize]="10"
        [pageSizeOptions]="[10, 50, 100]"
        aria-label="Select page"
      ></mat-paginator>
    </div>
  </div>
</div>
<div *ngIf="loading" class="d-flex justify-content-center align-items-center h-100 w-100">
  <mat-spinner [strokeWidth]="6"></mat-spinner>
</div>

<div id="user-settings-permissions" class="settings-panel" [class.mobile]="isMobile">
  <div class="head">
    <span class="material-icons go-back" (click)="goBack.emit()"> arrow_back </span>
    <div class="description">
      <div class="title">{{ PANELS.PERMISSIONS.name }}</div>
      <div class="subtitle">
        {{ PANELS.PERMISSIONS.description }}
      </div>
    </div>
    <span class="material-icons close" (click)="goBack.emit()"> close </span>
  </div>
  <div class="panel-body">
    <form [formGroup]="form" (submit)="submitChanges()" class="settings-panel-form">
      <mat-tab-group color="primary" class="permissions-settings-tabs" [disableRipple]="true">
        <mat-tab [label]="'General' | translate">
          <div class="toggle-group">
            <div class="toggle-group-fields">
              <app-user-settings-permissions-toggle
                [formGroup]="form"
                [options]="{
                  setting: 'addAdminsAsHostsInSpace',
                  titleText: 'Grant admins access to all Spaces' | translate,
                  activeText:
                    'Disabling this feature will not remove admins from any Spaces they may have already joined.'
                    | translate,
                  inactiveText:
                    'Enabling will allow admins to join any Space in the institution as a host.'
                    | translate
                }"
              >
              </app-user-settings-permissions-toggle>
              <app-user-settings-permissions-toggle
                [formGroup]="form"
                [options]="{
                  setting: 'autoDesignateTeachersAsHosts',
                  titleText:
                    'Automatically make teachers / institution admins hosts when they join a public Space in your institution'
                    | translate,
                  activeText:
                    'Disabling this feature will not change the roles of these users in any of their existing Spaces'
                    | translate,
                  inactiveText:
                    'Enabling this feature will not change the role of these users in any of their existing Spaces'
                    | translate
                }"
              >
              </app-user-settings-permissions-toggle>
              <app-user-settings-permissions-toggle
                [formGroup]="form"
                [options]="{
                  setting: 'preventTeachersfromCreatingSpaces',
                  titleText:
                    'Prevent teachers from creating their own Spaces in your institution'
                    | translate,
                  activeText:
                    'Disabling this feature means teachers can create their own Spaces in your institution'
                    | translate,
                  inactiveText:
                    'Enabling this feature will not remove a teacher\'s access to any of their existing Spaces'
                    | translate
                }"
              >
              </app-user-settings-permissions-toggle>
            </div>
          </div>
        </mat-tab>
        <mat-tab
          [label]="'Recording'"
          *ngIf="cloudRecordingEnabled && (canUseCloudRecording$ | async) === true"
        >
          <div class="recording-warning">
            <div class="title-container">
              <i class="material-icons">warning_amber</i>
              <span class="title">
                {{ 'Before enabling, please consult with your lawyer' | translate }}</span
              >
            </div>

            <span class="description">
              {{
                'The laws governing consent to record may vary depending on the jurisdiction of your institution. It is highly advisable to seek legal counsel before enabling this feature in your institution.'
                  | translate
              }}
            </span>
          </div>
          <div class="recording-options-selector">
            <app-user-settings-permissions-select
              [formGroup]="form"
              [formGroupName]="'settings'"
              [options]="{
                setting: 'crRecordingMode',
                selections: [
                  {
                    value: RecordingMode.AUTOMATIC,
                    title: 'Always on recording' | translate,
                    description:
                      'Record every call automatically. Stopping or pausing a recording is disabled inside a Space'
                      | translate,
                    disableOptions: {
                      disabled: (canUseAutomaticRecording$ | async) === false,
                      reason:
                        'This feature has not been enabled for your institution. Please contact Pencil Spaces Support.'
                        | translate
                    },
                    subToggleInputs: [
                      {
                        setting: 'forceScreenShareInAutomaticCr',
                        titleText:
                          'Require hosts to record their whiteboard on all calls' | translate,
                        disableOptions: {
                          disabled:
                            (canUseAutomaticRecording$ | async) === false ||
                            form?.value?.settings['crRecordingMode'] !== RecordingMode.AUTOMATIC
                        }
                      }
                    ]
                  },
                  {
                    value: RecordingMode.MANUAL,
                    title: 'Host recording' | translate,
                    description:
                      'Hosts can choose to enable recording in their Spaces. Starting and Stopping a recording is possible.'
                      | translate
                  },
                  {
                    value: RecordingMode.DISABLED,
                    title: 'No recording' | translate,
                    description:
                      'Recording options are hidden for all users in your Space' | translate
                  }
                ]
              }"
            >
            </app-user-settings-permissions-select>
          </div>
          <span class="preference-title"> Permissions: </span>
          <div>
            <div style="padding-top: 20px; padding-left: 24px">
              <app-user-settings-permissions-toggle
                [formGroup]="form"
                [options]="{
                  setting: 'crEnableDownloads',
                  titleText:
                    'Allow non-admin hosts to download recordings from Session Analytics or Pencil Files'
                    | translate
                }"
              ></app-user-settings-permissions-toggle>
            </div>
            <div style="padding-top: 20px; padding-left: 24px">
              <app-user-settings-permissions-toggle
                [formGroup]="form"
                [options]="{
                  setting: 'crEnableHostDeletion',
                  titleText:
                    'Allow non-admin hosts to delete recordings from Session Analytics' | translate
                }"
              ></app-user-settings-permissions-toggle>
            </div>
            <div style="padding-top: 20px; padding-left: 24px">
              <app-user-settings-permissions-toggle
                [formGroup]="form"
                [options]="{
                  setting: 'crEnablePrivacyAwareCloudRecording',
                  titleText: 'Prevent participant video from being recorded' | translate,
                  disableOptions: {
                    disabled: (canUsePrivacyAwareCloudRecording$ | async) === false,
                    reason:
                      'This feature is not available for your institution. Please contact Pencil Spaces Support'
                      | translate
                  }
                }"
              ></app-user-settings-permissions-toggle>
            </div>
          </div>
        </mat-tab>
        <mat-tab [label]="'Inside Spaces' | translate">
          <app-custom-expansion-panel
            [title]="'Waiting Room' | translate"
            [description]="'Adjust default Waiting Room settings in a Space' | translate"
          >
            <app-user-settings-permissions-select
              [formGroup]="form"
              [formGroupName]="'permissions'"
              [options]="waitingRoomOptions"
            >
            </app-user-settings-permissions-select>
          </app-custom-expansion-panel>
          <app-custom-expansion-panel
            [title]="'Participant-to-participant messaging' | translate"
            [description]="
              'Adjust whether participants can message other participants in a Space. Participants can always message hosts directly.'
                | translate
            "
          >
            <app-user-settings-permissions-select
              [formGroup]="form"
              [formGroupName]="'permissions'"
              [options]="messageOtherParticipantsOptions"
            >
            </app-user-settings-permissions-select>
          </app-custom-expansion-panel>
        </mat-tab>
      </mat-tab-group>
      <div class="foot" [class.show]="formChanged$ | async">
        <app-pencil-button
          class="pencil-button btn-ok"
          [buttonType]="'submit'"
          [disabled]="form.invalid"
        >
          <span *ngIf="!isLoading" class="material-icons">check</span>
          <mat-spinner *ngIf="isLoading" [diameter]="16" color="#FFF"></mat-spinner>
          <span>{{ 'Update' | translate }}</span>
        </app-pencil-button>
        <app-pencil-button
          [buttonType]="'button'"
          class="pencil-button btn-cancel"
          (clickEvent)="initForm()"
          type="button"
          icon="close"
          [iconConfig]="{ type: 'material-icon' }"
          [label]="'Cancel' | translate"
        >
        </app-pencil-button>
      </div>
    </form>
  </div>
</div>

<ng-container [ngSwitch]="notificationElement?.type">
  <app-button-toaster-element
    *ngSwitchCase="notificationElementType.BUTTON"
    [notificationButtonElement]="notificationElement"
    (click)="onButtonClick(notificationElement?.['onClick'])"
  >
  </app-button-toaster-element>
  <app-error-toaster-element
    *ngSwitchCase="notificationElementType.ERROR"
    [errorElement]="notificationElement"
  >
  </app-error-toaster-element>
  <app-icon-message-toaster-element
    *ngSwitchCase="notificationElementType.ICON_STR"
    [iconString]="notificationElement"
  ></app-icon-message-toaster-element>
</ng-container>

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-gadget-header',
  templateUrl: './gadget-header.component.html',
  styleUrls: ['./gadget-header.component.scss'],
})
export class GadgetHeaderComponent {
  @Input() isExpanded = true;
  @Input() height = 18;
  @Input() width = 18;
  @Input() title = '';
  @Input() icon = '';

  @Output() closed = new EventEmitter<void>();
  @Output() toggleIsExpanded = new EventEmitter<void>();

  handleCloseClick(): void {
    this.closed.emit();
  }
}

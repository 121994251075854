import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-sidebar',
  template: '<ng-content></ng-content>',
  styles: [
    `:host {
      display: block;
      padding: 16px 12px;
    }`,
  ],
})
export class SidebarComponent {
}

@Component({
  selector: 'ui-sidebar-section',
  template: `
    <div class="title">{{ title }}</div>
    <ng-content></ng-content>
  `,
  styles: [
    `:host {
      display: block;
      padding: 12px 0 16px 0;
    }`,
    `:host:first-child {
      padding-top: 0;
    }`,
    `.title {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.05em;
      color: #828282;
    }`,
  ],
})
export class SidebarSectionComponent {
  @Input() title?: string;
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { ObjectId } from 'bson';
import { fabric } from 'fabric';
import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  filter,
  first,
  firstValueFrom,
  fromEvent,
  map,
  Observable,
  of,
  pairwise,
  Subject,
  Subscription,
  take,
} from 'rxjs';
import { CustomWebViewerConfiguration } from 'src/app/common/library-app-subcategory/library-app-subcategory.component';
import { ERRORS, INFOS } from 'src/app/common/utils/notification-constants';
import { AddIframeComponent } from 'src/app/dialogs/add-iframe/add-iframe.component';
import { AddPencilAiComponent } from 'src/app/dialogs/add-pencil-ai/add-pencil-ai.component';
import { DomListenerFactoryService } from 'src/app/services/dom-listener-factory.service';
import {
  DownloadFileService,
  DownloadStatus,
  DownloadStatusFlag,
} from 'src/app/services/download-file.service';
import { FLAGS, FlagsService } from 'src/app/services/flags.service';
import { HB_VM_STATE, WebViewerConfig } from 'src/app/services/hyperbeam.service';
import {
  CanvasItemInteractionBar,
  ItemsCanvasService,
  OverlayApp,
} from 'src/app/services/items-canvas.service';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from 'src/app/services/notification-toaster.service';
import { PDFConverterService } from 'src/app/services/pdfconverter.service';
import { GPTApp, GptAppConfig } from 'src/app/services/space-apps.service';
import { SpacesService } from 'src/app/services/spaces.service';
import {
  ACCEPTED_ONEDRIVE_TYPES,
  checkIfValidGoogleDriveDocType,
  checkIfValidLibreOfficeType,
  resolveDocumentMimeType,
  SUPPORTED_GDRIVE_TYPES,
  UploadFailedNotificationStatus,
  UploadFileService,
} from 'src/app/services/upload-file.service';
import { SpaceRepository } from 'src/app/state/space.repository';
import {
  ButtonToasterElement,
  ButtonToasterElementStyle,
} from 'src/app/ui/notification-toaster/button-toaster-element/button-toaster-element.component';
import { ToasterPopupStyle } from 'src/app/ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { IconMessageToasterElement } from 'src/app/ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { environment } from 'src/environments/environment';
import { matchI } from 'ts-adt';
import { Upload } from 'tus-js-client';
import { v4 as uuidv4 } from 'uuid';
import { modifiedSetTimeout } from 'src/app/utilities/ZoneUtils';
import { percentage } from '@angular/fire/storage';
import * as katex from 'katex';
import { LessonGeneratorService, LessonProcessor } from 'src/app/services/lesson-generator.service';
import { CustomErrorCodes } from '../../../../custom_error_codes.constants';
import { FragmentType, TypedFragment } from '../../../common/typed-fragment/typed-fragment';
import { RealtimeDataUpdate } from '../../../models/realtime';
import { ResourceItemModel } from '../../../models/resource';
import {
  BoardFolder,
  EquationState,
  Frame,
  FrameItem,
  IFrameState,
  ItemModel,
  MarioResource,
  MarioState,
  QuestionState,
  ResourceState,
  WebViewerState,
} from '../../../models/session';
import { User } from '../../../models/user';
import { AclService } from '../../../services/acl.service';
import { QuestionsService } from '../../../services/questions.service';
import { RealtimeSpaceService } from '../../../services/realtime-space.service';
import { RealtimeService } from '../../../services/realtime.service';
import { ResourcesService } from '../../../services/resources.service';
import {
  LockObjectAction,
  LockObjectTarget,
  SessionSharedDataService,
  SessionView,
  WhiteboardBackground,
} from '../../../services/session-shared-data.service';
import { SessionsVptService } from '../../../services/sessions-vpt.service';
import { SpaceBoardsService } from '../../../services/space-boards.service';
import { KeyScenariosOnSpaces, TelemetryService } from '../../../services/telemetry.service';
import { UiService } from '../../../services/ui.service';
import { UserService } from '../../../services/user.service';
import { VimeoUploadService } from '../../../services/vimeo-upload.service';
import { YCanvasItemObject } from '../../common/y-session';
import {
  AppsConfiguration,
  CHAT_GPT_APP_DEFAULT_HEIGHT,
  CHAT_GPT_APP_DEFAULT_WIDTH,
  CollaborativeApps,
  DOCUMENTS_DEFAULT_HEIGHT,
  DOCUMENTS_DEFAULT_WIDTH,
  FORMULAS_DEFAULT_HEIGHT,
  FORMULAS_DEFAULT_WIDTH,
  IFRAME_APP_DEFAULT_HEIGHT,
  IFRAME_APP_DEFAULT_WIDTH,
  IMAGES_DEFAULT_HEIGHT,
  IMAGES_DEFAULT_WIDTH,
  isOverlaidAppOnCertainZoomLevel,
  MARIO_DEFAULT_HEIGHT,
  MARIO_DEFAULT_WIDTH,
  OTHER_ITEMS_DEFAULT_HEIGHT,
  OTHER_ITEMS_DEFAULT_WIDTH,
  REMOTE_VIDEO_DEFAULT_HEIGHT,
  REMOTE_VIDEO_DEFAULT_WIDTH,
  VIDEO_DEFAULT_HEIGHT,
  VIDEO_DEFAULT_WIDTH,
} from '../iframe/additional-apps.utils';
import { WrappedComponent, WrappedComponentSettings } from '../item-wrapper/item-wrapper.component';
import { YStaticCanvas } from '../wb-canvas/fabric-utils';

import { PanelView } from '../../panel/panel.component';
import { UPLOAD_LIMITS } from '../../../dialogs/upload/upload/upload.component';
import { PDF_PAGE_DEFAULT_HEIGHT } from '../pdf-canvas-item/pdf-viewer/pdf-viewer.component';
import { CanvasItemsDataObserverService } from './canvas-Items-data-observer.service';
import { BoardItemsStackingOrderManager } from './board-items-stacking-order-manager';
import { BoardItemsInteractionsManager } from './board-items-interactions-manager';

declare let google: any;
declare let gapi: any;
declare let OneDrive: any;

export enum AccessState {
  CanAccess,
  NotInCourse,
  NotInQuestion,
}

export class ItemData {
  contentId?: string;
  sessionId = '';
  frameUid = '';
  model: ItemModel;
  resourceType?: ResourceItemModel;
  resourceUrl?: string;
  resourceName?: string;
  equationString?: string;
  initItemState?: ItemState;
  preview?: boolean;
  relatedFabricItem: any;
  publicUrl?: boolean;
  options?: any;
  addToNewBoard = false;
  locked?: boolean;
  isPrivate: boolean;
  constructor(
    itemId: string,
    model: ItemModel,
    isPrivate: boolean,
    resourceType?: ResourceItemModel,
    resourceUrl?: string,
    resourceName?: string,
    relatedFabricItem?: any,
    equationString?: string,
    preview?: boolean,
    publicUrl?: boolean,
    options?: any,
    locked?: boolean,
  ) {
    this.contentId = itemId;
    this.model = model;
    this.isPrivate = isPrivate;
    this.resourceType = resourceType;
    this.resourceUrl = resourceUrl;
    this.resourceName = resourceName;
    this.equationString = equationString;
    this.preview = preview;
    this.relatedFabricItem = relatedFabricItem;
    this.publicUrl = publicUrl;
    this.options = options;
    this.locked = locked;
  }
}

class CopiedItemData {
  data: string;
  type: FragmentType;
  settings: any;
  relatedFabricItem: any;
  isPrivate: boolean;

  constructor(
    data: string,
    type: FragmentType,
    settings: any,
    relatedFabricItem: any,
    isPrivate: boolean,
  ) {
    this.data = data;
    this.type = type;
    this.settings = settings;
    this.relatedFabricItem = relatedFabricItem;
    this.isPrivate = isPrivate;
  }
}

export class UploadFile {
  file: File | string;
  name: string;
  size: number;
  type: ResourceItemModel;
  sessionId: string;
  frameUid: string;
  options?: any;
  publicFile?: boolean;

  constructor(
    file: File | string,
    name: string,
    size: number,
    type: ResourceItemModel,
    sessionId: string,
    frameUid: string,
    options: any = null,
    publicFile = false,
  ) {
    this.file = file;
    this.name = name;
    this.size = size;
    this.type = type;
    this.sessionId = sessionId;
    this.frameUid = frameUid;
    this.options = options;
    this.publicFile = publicFile;
  }
}

export class ItemState {
  width = 0;
  height = 0;
  left = 0;
  top = 0;
  index = 0;
  fontSize = 24;
  fontColor = '#333333';
  backgroundColor = 'transparent';
  rotation = 0;
}

export enum CanvasItemAction {
  NONE,
  REFRESH,
  RESET_APP,
}

export class CanvasItem {
  id = '';
  contentId = '';
  sessionId = '';
  frameUid = '';
  inPreviewMode = false;
  type?: ItemModel;
  itemState: ItemState = new ItemState();
  itemSettings: WrappedComponentSettings | undefined;
  // This preview property is only used for formulas
  // To show them with attached to the mouse cursor with half opacity before inserting them to the board
  preview = false;
  // Beware that when syncing this between users, this can have stale values that might need to be updated before the sync
  relatedItem: FrameItem;
  relatedFabricItem?: fabric.Object;
  movable = true;
  resizable = true;
  private _actionEvent = new BehaviorSubject<CanvasItemAction>(CanvasItemAction.NONE);
  actionEvent$ = this._actionEvent.asObservable();

  interactionBar$?: Observable<CanvasItemInteractionBar>;
  overlayApp?: OverlayApp;

  constructor(relatedItem: FrameItem) {
    this.relatedItem = relatedItem;
  }

  performAction(action: CanvasItemAction) {
    this._actionEvent.next(action);
  }
}

export interface IframeDimensions {
  width: number;
  height: number;
}
export interface IframePosition {
  top: number;
  left: number;
}

interface FileOptions {
  file: File;
  options: any;
}

export interface Position {
  top: number;
  left: number;
}
export type ExternalFile = File | FileOptions;

const STAGGERED_ITEM_GAP = 50;
const STAGGERED_RESOURCE_GAP = 200;

type LLMObjectType =
  | 'resource_library'
  | 'simulation'
  | 'heading'
  | 'content'
  | 'sub_heading'
  | 'latex_equation'
  | 'youtube_query';

interface ParsedLLMOutput {
  topic: string;
  objects: Array<LLMObject>;
  youtubeObjects: Array<{
    url: string;
  }>;
}

export interface LLMAPIResponse {
  title: string;
  boards: Array<ParsedLLMOutput>;
}

interface LLMObject {
  type: LLMObjectType;
  text: string;
  url?: string;
}

const getLLMFontSize: Record<LLMObjectType, number> = {
  heading: 36,
  sub_heading: 24,
  content: 18,
  latex_equation: 18,
  resource_library: 18,
  simulation: 18,
  youtube_query: 18,
};

const getLLMFontWeight: Record<LLMObjectType, string> = {
  heading: 'bold',
  sub_heading: 'bold',
  content: 'normal',
  latex_equation: 'normal',
  resource_library: 'normal',
  simulation: 'normal',
  youtube_query: 'normal',
};

@UntilDestroy()
@Component({
  selector: 'app-items-canvas',
  templateUrl: './items-canvas.component.html',
  styleUrls: ['./items-canvas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemsCanvasComponent implements OnDestroy, OnInit, OnChanges, AfterViewInit {
  readonly canvasItemsArray$ = new BehaviorSubject<CanvasItem[]>([]);
  readonly clearing$ = new BehaviorSubject<boolean>(false);
  readonly objectMoving$ = new BehaviorSubject<boolean>(false);
  readonly fullscreenId$ = new BehaviorSubject<string | null>(null);
  readonly transformationMatrix$ = new BehaviorSubject<number[]>([0, 0, 1, 0, 0, 0, 0, 1]); // Default transformation matrix that does nothing
  readonly insertingItem$ = new BehaviorSubject<boolean>(false);
  readonly zIndexObservables$ = new Map<string, Observable<number>>();
  readonly invisibilityObservables$ = new Map<string, Observable<boolean>>();
  readonly isAppSelected$ = new BehaviorSubject<boolean>(false);

  sessionId = '';
  canvasItems: { [id: string]: CanvasItem } = {};
  frameSubscription?: Subscription;
  viewportSubscription?: Subscription;
  itemDataSubscriptions: { [id: string]: Subscription } = {};
  user?: User;
  uploadingService?: Upload;
  isLocked = true;
  objectLockFeatureEnabled = this.flagsService.isFlagEnabled(FLAGS.SPACES_OBJECT_LOCK);
  currentFrameUid = '';
  @Input() prevFrame?: Frame;
  @Input() prevSessionId?: string;
  @Input() static = false;
  @Input() staticCanvas?: YStaticCanvas;
  @Input() viewPortTransform: number[] = [0, 0, 1, 0, 0, 0, 0, 1];
  @Input() isOffline = false;
  @Input() objectMoving = false;
  @Output() setPreviewZoom = new EventEmitter<void>();

  private insertSubscription?: Subscription;
  realtimeSubscription?: Subscription;
  readonly deletableItems = [
    ItemModel.Formula,
    ItemModel.Resource,
    ItemModel.IFrame,
    ItemModel.WebViewer,
    ItemModel.Mario,
    ItemModel.Chat_GPT,
    ItemModel.Question,
    ItemModel.RemoteVideo,
  ];
  domListener = this.domListenerFactoryService.createInstance();
  mobileUI = false;
  addingItemToFrame = false;
  cachedRelatedFabricItems: { [key: string]: any } = {};
  currentFrameItems: YCanvasItemObject[] = [];
  @ViewChildren('items')
  private itemsQuery?: QueryList<ElementRef>;
  isDisableKeyEvents = false;
  gTokenResponse: any | null = null;
  dataCleared: Subject<boolean> = new Subject<boolean>();
  staggeredItems: Map<string, Position> = new Map();

  lastVPT: number[] = [];
  private unlistenKeyUpDelete!: () => void;
  private unlistenKeyUpBackSpace!: () => void;
  private itemsParentContainerObserver?: MutationObserver;

  private FIXED_HEIGHT_PADDING_BETWEEN_OBJECTS = 50;
  private FIXED_COLUMN_WIDTH = 1000;

  private lessonProcessor?: LessonProcessor;
  private lessonGeneratorRequestInProgress = false;

  constructor(
    private sharedDataService: SessionSharedDataService,
    private realtimeSpaceService: RealtimeSpaceService,
    private questionsService: QuestionsService,
    private zone: NgZone,
    private userService: UserService,
    private uploadService: UploadFileService,
    private downloadService: DownloadFileService,
    private pdfConverterService: PDFConverterService,
    private vimeoStorage: VimeoUploadService,
    private resourcesService: ResourcesService,
    private aclService: AclService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private realtimeService: RealtimeService,
    private uiService: UiService,
    private sessionsVptService: SessionsVptService,
    private cdRef: ChangeDetectorRef,
    private notificationToasterService: NotificationToasterService,
    public spaceRepo: SpaceRepository,
    private spaceBoardsService: SpaceBoardsService,
    public dialog: MatDialog,
    private spacesService: SpacesService,
    private flagsService: FlagsService,
    private renderer2: Renderer2,
    private domListenerFactoryService: DomListenerFactoryService,
    private telemetry: TelemetryService,
    private itemsCanvasService: ItemsCanvasService,
    private canvasItemsDataObserverService: CanvasItemsDataObserverService,
    private boardItemsStackingOrderManager: BoardItemsStackingOrderManager,
    private lessonGeneratorService: LessonGeneratorService,
    private boardItemsInteractionsManager: BoardItemsInteractionsManager,
  ) {
    this.sharedDataService.sessionView.current$
      .pipe(untilDestroyed(this), pairwise())
      .subscribe(([previousSessionView, sessionView]) => {
        this.fullscreenId$.next(sessionView.sessionViewMetaData?.fullscreenId);
        if (previousSessionView.view === SessionView.FULLSCREEN_APP) {
          this.setTransformation(this.lastVPT ?? this.transformationMatrix$.getValue());
        }
      });

    this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res) {
        this.user = res.user;
      }
    });

    this.sharedDataService.requestCollectingLogs.pipe(untilDestroyed(this)).subscribe((state) => {
      if (state) {
        this.sendLogData();
      }
    });

    const eraseCanvasItem = (removedCanvasItemId: string): void => {
      const frameItem = this.currentFrameItems.find((item) => item._id === removedCanvasItemId);

      if (frameItem?.model && this.deletableItems.includes(frameItem.model)) {
        this.sentDeleteItemEvent(removedCanvasItemId);
      }
    };

    this.sharedDataService.removeCanvasItem.pipe(untilDestroyed(this)).subscribe(eraseCanvasItem);

    this.uiService.isMobile.pipe(untilDestroyed(this)).subscribe((res) => {
      this.mobileUI = res;
    });

    this.sharedDataService.isDisableKeyEvents$
      .pipe(untilDestroyed(this))
      .subscribe((val) => (this.isDisableKeyEvents = val));

    this.sharedDataService.toolbarChange
      .pipe(
        untilDestroyed(this),
        filter(
          (data) =>
            data.action === LockObjectAction.LOCK_OBJECT &&
            data.target === LockObjectTarget.CANVAS_ITEM,
        ),
      )
      .subscribe((data) => {
        const [canvasItem, locked] = data.args;
        this.changeLockItemState((canvasItem as CanvasItem).id, locked as boolean);
      });

    this.zone.runOutsideAngular(() => {
      this.unlistenKeyUpDelete = this.renderer2.listen('document', 'keyup.delete', () =>
        this.handleDeleteKeyboardEvent(),
      );
      this.unlistenKeyUpBackSpace = this.renderer2.listen('document', 'keyup.backspace', () =>
        this.handleDeleteKeyboardEvent(),
      );
    });
  }

  webViewerDisabled =
    !this.flagsService.isFlagEnabled(FLAGS.MARIO) ||
    !(this.flagsService.featureFlagsVariables.mario.enable_browser as boolean);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.viewPortTransform?.currentValue) {
      this.setTransformation(changes.viewPortTransform?.currentValue);
    }
    if (changes.static?.currentValue === false) {
      this.sharedDataService.itemsCanvas = this;
    }
    if (changes.objectMoving) {
      this.objectMoving$.next(changes.objectMoving.currentValue);
    }
  }

  ngOnInit(): void {
    this.setupViewportHandler();
    this.setupAppSelectedListener();
    if (!this.prevFrame) {
      this.setupActiveSpace();
      this.handleUploadedItems();
      this.handleInsertingItems();
      this.addClipboardPasteHandler();
      this.addDropHandler();
    } else {
      this.subscribeToCurrentFrame(this.prevFrame.uid);
    }

    this.flagsService.featureFlagsChanged
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.objectLockFeatureEnabled = this.flagsService.isFlagEnabled(FLAGS.SPACES_OBJECT_LOCK);
      });

    this.sharedDataService.changeCanvasItemsDict
      .pipe(untilDestroyed(this))
      .subscribe(({ itemId, item }) => {
        this.changeCanvasItemsDict(itemId, item);
      });
  }

  ngAfterViewInit() {
    this.setTransformation(this.transformationMatrix$.getValue());

    this.itemsQuery?.changes.pipe(untilDestroyed(this)).subscribe(() => {
      this.setTransformation(this.transformationMatrix$.getValue());
    });
    // Listening for changes that happens for items-parent-container including its children
    const itemsParentContainer = document.getElementById('items-parent-container');
    if (itemsParentContainer && !this.static) {
      const config = { attributes: true, childList: true, subtree: false };
      this.itemsParentContainerObserver = new MutationObserver((mutationList) => {
        for (const mutation of mutationList) {
          mutation.removedNodes.forEach((node) => {
            // if a change happens to items-parent-container and
            // its child items-container isn't in the dom, it means that the canvas has been cleared
            if (node['id'] === 'items-container') {
              this.dataCleared.next(true);
              this.clearing$.next(false);
            }
          });
        }
      });
      this.itemsParentContainerObserver.observe(itemsParentContainer, config);
    }
  }

  setupActiveSpace(): void {
    this.spaceRepo.activeSpace$
      .pipe(debounceTime(0)) // prevent duplicates updates by "grouping" updates in the same event cycle
      .pipe(untilDestroyed(this))
      .subscribe((activeSpace) => {
        if (activeSpace) {
          this.setuprealtime(activeSpace._id, this.sessionId);
          if (activeSpace._id !== this.sessionId) {
            if (this.frameSubscription) {
              this.frameSubscription.unsubscribe();
            }
            this.sessionId = activeSpace._id;
          }
          this.isLocked = activeSpace.isLocked;
        }
      });
    this.spaceBoardsService.activeSpaceSelectedBoard$
      .pipe(debounceTime(0)) // prevent duplicates updates by "grouping" updates in the same event cycle
      .pipe(untilDestroyed(this))
      .subscribe((selectedBoard) => {
        // update to board changes
        if (selectedBoard && selectedBoard.uid !== this.currentFrameUid) {
          // clear stagger item array (start staggering from default position once board changes)
          this.staggeredItems = new Map();

          this.currentFrameUid = selectedBoard.uid;
          this.subscribeToCurrentFrame(this.currentFrameUid);
        }
      });

    this.sessionsVptService.lastMousePosition$.pipe(untilDestroyed(this)).subscribe(() => {
      // clear stagger item array (start staggering from default position once board is panned)
      this.staggeredItems = new Map();
    });
  }

  subscribeToCurrentFrame(frameUid: string): void {
    this.frameSubscription?.unsubscribe();
    const sessionId = this.static && this.prevSessionId ? this.prevSessionId : this.sessionId;
    const elementsObservable = this.static
      ? this.spaceRepo.boardCanvasItems$(sessionId, frameUid)
      : combineLatest([
          this.spaceRepo.boardCanvasItems$(sessionId, frameUid),
          this.itemsCanvasService.loadingResourcesItemsByFrame$(frameUid),
        ]).pipe(
          map(([canvasItems, resourceLoadingItems]) => ({
            ...canvasItems,
            objects: [...canvasItems.objects, ...resourceLoadingItems],
          })),
        );

    if (!elementsObservable) {
      return;
    }
    let firstLoad = true;

    // Reinitialize the service whenever we are re-subscribing (board change..)
    this.boardItemsStackingOrderManager.clearState();
    this.frameSubscription = elementsObservable
      .pipe(untilDestroyed(this))
      .subscribe(({ objects, lastUpdateLocal }) => {
        this.currentFrameItems = objects;
        // We update the canvas if the update is remote (!local) or if the canvas is static.
        //  static canvases process all updates because they don't listen to changes from the
        //  clients fabric canvas
        const shouldUpdateCanvas =
          !lastUpdateLocal ||
          this.static ||
          this.canvasItemsArray$.getValue().length !== objects.length;

        if (!this.static) {
          this.itemsCanvasService.setItemsCanvasSessionVars(objects);
        }

        let addedItemsCountPromise: Promise<number> | undefined;
        const isFirstLoad = firstLoad;
        if (firstLoad) {
          addedItemsCountPromise = this.loadItemsToCanvas(objects);
          firstLoad = false;
        } else if (shouldUpdateCanvas) {
          addedItemsCountPromise = this.updateItemsInCanvas(objects);
        }

        addedItemsCountPromise?.then(
          () =>
            !this.static &&
            this.boardItemsStackingOrderManager.canvasItemsUpdated(
              this.canvasItemsArray$.getValue(),
              !lastUpdateLocal,
              isFirstLoad,
            ),
        );
      });
  }

  handleUploadedItems(): void {
    this.sharedDataService.uploadItem
      .pipe(
        untilDestroyed(this),
        filter((files) => Boolean(files) && Array.isArray(files)),
        map((files) => (files as UploadFile[]).filter((file) => Boolean(file.file))),
        map((files) => files as UploadFile[]),
      )
      .subscribe((files: UploadFile[]) => {
        for (const file of files) {
          switch (file.type) {
            case ResourceItemModel.IMAGE:
              if (typeof file.file === 'string') {
                this.createResource(
                  file.file,
                  file.name,
                  file.size,
                  file.type,
                  file.sessionId,
                  file.frameUid,
                  undefined,
                  file.options,
                  file.publicFile,
                );
              } else {
                this.addImageFile(file);
              }
              break;
            case ResourceItemModel.VIDEO:
              if (typeof file.file === 'string') {
                this.createResource(
                  file.file,
                  file.name,
                  file.size,
                  file.type,
                  file.sessionId,
                  file.frameUid,
                );
              } else {
                this.addVideoFile(file);
              }
              break;
            case ResourceItemModel.PDF:
            case ResourceItemModel.DOCUMENT:
              if (typeof file.file === 'string') {
                this.createResource(
                  file.file,
                  file.name,
                  file.size,
                  file.type,
                  file.sessionId,
                  file.frameUid,
                  undefined,
                  file.options,
                );
              } else {
                if (checkIfValidLibreOfficeType(file.file as File)) {
                  this.addDocument(file, file.sessionId, file.frameUid, file.options);
                } else {
                  this.addPdfFile(file, file.sessionId, file.frameUid, file.options);
                }
              }
              break;
          }
        }
      });
  }

  handlePreviewEnded(canvasItem: CanvasItem): void {
    const frameItem = canvasItem.relatedItem;
    if (!frameItem?.position) {
      return;
    }
    if (canvasItem.relatedFabricItem) {
      const width = canvasItem.relatedFabricItem.width ?? 0;
      const height = canvasItem.relatedFabricItem.height ?? 0;
      const top = canvasItem.relatedFabricItem.top ?? 0;
      const left = canvasItem.relatedFabricItem.left ?? 0;
      frameItem.position.set('top', `${top}`);
      frameItem.position.set('left', `${left}`);
      frameItem.position.set('width', `${width}`);
      frameItem.position.set('height', `${height}`);
    }

    this.addItemToFrame(frameItem, this.sessionId, this.currentFrameUid);
    this.sharedDataService.mainToolbar?.activateToolbar('pointer');
    this.insertingItem$.next(false);
    this.cdRef.detectChanges();
  }

  handleInsertingItems(): void {
    this.insertSubscription?.unsubscribe();
    this.insertSubscription = this.sharedDataService.selectedItemsToInsert
      .pipe(
        untilDestroyed(this),
        map((items) => items as ItemData[]),
      )
      .subscribe(async (newItems) => this.insertItems(newItems));
  }

  private getStaggeredPosition(
    position: Position,
    itemType?: string,
    itemId?: string,
    frameUID?: string,
  ): Position {
    if (itemId) {
      const relatedPosition = this.itemsCanvasService.getRelatedResourceLoadingPosition(
        itemId,
        this.currentFrameUid,
      );
      /**
       * some items of type resource have a related resource loading item each
       * if the related item exists, we should position the item at the same position of
       * the related one
       */
      if (relatedPosition) {
        return {
          left: +relatedPosition.left,
          top: +relatedPosition.top,
        };
      }
    }

    // The frameUID !== this.currentFrameUid check aims to eliminate unnecessary pans when adding items to a new board,
    // this was added to prevent the extra pans when adding a PDF as per the one-page-per-board strategy
    if (!itemType || frameUID !== this.currentFrameUid) {
      return position;
    }
    const savedPosition = this.staggeredItems.get(itemType);
    const { left: previousItemLeft, top: previousItemTop } = savedPosition ?? {};
    let { top, left } = position;

    if (previousItemTop === top && previousItemLeft === left) {
      const gap = itemType === FragmentType.PDF ? STAGGERED_RESOURCE_GAP : STAGGERED_ITEM_GAP;
      left = left + gap;
      top = top + gap;
      this.sessionsVptService.panBy(-gap, -gap);
    }
    return { left, top };
  }

  async insertItems(newItems: ItemData[]): Promise<void> {
    if (!this.sharedDataService.canModifySession() || this.isOffline) {
      return;
    }

    for (let i = 0; i < newItems.length; i++) {
      const item = newItems[i];
      if ([ItemModel.Formula, ItemModel.Resource, ItemModel.ResourceLoading].includes(item.model)) {
        await this.insertFormulaOrResource(item);
      } else if ([ItemModel.IFrame, ItemModel.WebViewer, ItemModel.Mario].includes(item.model)) {
        await this.insertIframeWebViewOrMario(item, i === 0);
      } else if (item.model === ItemModel.Chat_GPT) {
        await this.insertChatGPTItem(item, i === 0);
      } else if (item.model === ItemModel.Question) {
        await this.insertQuestion(item);
      } else if (item.model === ItemModel.RemoteVideo) {
        await this.insertRemoteVideo(item);
      } else {
        this.addFrame([await this.createFrameItem(item)], item.sessionId, { goTo: i === 0 });
      }
    }
  }

  async insertQuestion(item: ItemData): Promise<void> {
    item.frameUid = this.currentFrameUid;
    this.addItemToFrame(await this.createFrameItem(item), item.sessionId, item.frameUid);
  }

  async insertFormulaOrResource(item: ItemData): Promise<void> {
    const { backgroundColor, fontSize, fontColor } = item.initItemState || {};
    item.initItemState = item.initItemState ?? new ItemState();
    item.initItemState.backgroundColor = backgroundColor ?? item.initItemState.backgroundColor;
    item.initItemState.fontSize = fontSize ?? item.initItemState.fontSize;
    item.initItemState.fontColor = fontColor ?? item.initItemState.fontColor;
    const frameItem = await this.createFrameItem(item);

    if (frameItem.model === ItemModel.ResourceLoading) {
      frameItem.resource_state = {
        resource_item_model: item.resourceType,
      };
      this.itemsCanvasService.addResourceLoadingItem(frameItem, item.frameUid);
      return;
    }

    if (item.preview) {
      this.insertingItem$.next(true);
      await this.loadItem(frameItem, item.isPrivate, -1, true);
    } else {
      await this.addItemToFrame(frameItem, item.sessionId, item.frameUid);
    }
    this.notificationToasterService.dismissLoadingNotification(INFOS.INSERTING_RESOURCE);

    if (
      item.resourceType === ResourceItemModel.DOCUMENT ||
      item.resourceType === ResourceItemModel.PDF
    ) {
      this.telemetry.endPerfScenario(KeyScenariosOnSpaces.RENDERING_PDF, {
        name: item.resourceName,
      });
    }
  }

  async insertIframeWebViewOrMario(item: ItemData, isFirstItem: boolean) {
    item.initItemState = new ItemState();

    item.options.left = item.options.left ?? (item.addToNewBoard ? 500 : undefined);
    item.options.top = item.options.top ?? (item.addToNewBoard ? 500 : undefined);

    const frameItem = await this.createFrameItem(item);
    if (item.addToNewBoard) {
      this.addFrame([frameItem], item.sessionId, { goTo: isFirstItem });
    } else {
      await this.addItemToFrame(frameItem, item.sessionId, item.frameUid);
    }
  }

  async insertChatGPTItem(item: ItemData, isFirstItem: boolean) {
    const frameItem = await this.createFrameItem(item);
    if (item.addToNewBoard) {
      this.addFrame([frameItem], item.sessionId, { goTo: isFirstItem });
    } else {
      await this.addItemToFrame(frameItem, item.sessionId, item.frameUid);
    }
  }

  async insertRemoteVideo(item: ItemData) {
    const frameItem = await this.createFrameItem(item);
    await this.addItemToFrame(frameItem, item.sessionId, item.frameUid);
  }

  ngOnDestroy(): void {
    this.unlistenKeyUpDelete();
    this.unlistenKeyUpBackSpace();
    this.frameSubscription?.unsubscribe();
    this.viewportSubscription?.unsubscribe();
    this.insertSubscription?.unsubscribe();
    this.cleanuprealtime(this.sessionId);
    this.domListener.clear();
    if (!this.static) {
      this.sharedDataService.itemsCanvas = undefined;
      this.itemsCanvasService.clearResourceLoadingItems(this.currentFrameUid);
    }
    this.itemsParentContainerObserver?.disconnect();
  }

  clearCanvas(): Promise<void> {
    if (this.canvasItemsArray$.getValue().length) {
      this.clearing$.next(true);
    }
    const promise: Promise<void> = new Promise((resolve) => {
      this.currentFrameUid = '';
      if (!this.clearing$.getValue()) {
        resolve();
      }
      this.dataCleared.pipe(untilDestroyed(this), first()).subscribe((cleared) => {
        if (cleared) {
          resolve();
        }
      });
      // fallback mechanism
      modifiedSetTimeout(() => {
        resolve();
      }, 100);
    });
    this.canvasItems = {};
    this.canvasItemsArray$.next([]);
    this.cdRef.detectChanges();
    return promise;
  }

  createQuestionContainer(item: FrameItem): WrappedComponentSettings {
    const questionWrapperSettings = new WrappedComponentSettings(
      WrappedComponent.questionComponent,
    );
    if (this.sharedDataService.itemsData$[item.content_id]) {
      this.loadItemFromCache(item.content_id, questionWrapperSettings);
    } else {
      this.sharedDataService.itemsData$[item.content_id] = new BehaviorSubject(null);
      this.questionsService
        .getQuestionData(item.content_id, 'questions')
        .pipe(untilDestroyed(this))
        .subscribe((data) => {
          if (data) {
            this.loadItemFromDb(item.content_id, data, questionWrapperSettings, ItemModel.Question);
          }
        });
    }
    if (questionWrapperSettings.componentInputs) {
      questionWrapperSettings.componentInputs.questionState = item.question_state;
      questionWrapperSettings.componentInputs.courseId = '';
      questionWrapperSettings.componentInputs.questionAnswer = this.questionAnswer.bind(this);
      questionWrapperSettings.componentInputs.showDetails = this.showQuestionDetails.bind(this);
    }
    return questionWrapperSettings;
  }

  createNoteContainer(noteId: string): WrappedComponentSettings {
    const noteWrapperSettings = new WrappedComponentSettings(WrappedComponent.noteComponent);
    if (this.sharedDataService.itemsData$[noteId]) {
      this.loadItemFromCache(noteId, noteWrapperSettings);
    } else {
      this.sharedDataService.itemsData$[noteId] = new BehaviorSubject(null);
      this.questionsService
        .getQuestionData(noteId, 'notes')
        .pipe(untilDestroyed(this))
        .subscribe((data) => {
          if (data) {
            this.loadItemFromDb(noteId, data, noteWrapperSettings, ItemModel.Note);
          }
        });
    }
    return noteWrapperSettings;
  }

  createPdfContainer(url: string, id: string, fragment: TypedFragment): WrappedComponentSettings {
    const pdfWrapperSettings = new WrappedComponentSettings(WrappedComponent.fragment);
    if (pdfWrapperSettings.resourceComponentInputs) {
      pdfWrapperSettings.resourceComponentInputs._id = id;
      pdfWrapperSettings.resourceComponentInputs.fragment = fragment;
    }

    if (this.sharedDataService.itemsData$[id]) {
      this.loadItemFromCache(id, pdfWrapperSettings);
    }

    return pdfWrapperSettings;
  }

  private setupViewportHandler() {
    this.viewportSubscription?.unsubscribe();
    this.viewportSubscription = this.sessionsVptService.viewportTransform
      .pipe(untilDestroyed(this))
      .subscribe((vpt) => {
        if (vpt) {
          this.lastVPT = vpt;
          this.setTransformation(vpt);
        }
      });
  }

  private setupAppSelectedListener() {
    this.boardItemsInteractionsManager.selection$
      .pipe(untilDestroyed(this))
      .subscribe((selection) => {
        if (!selection) {
          return this.isAppSelected$.next(false);
        }
        if (
          !selection.isMultiSelection &&
          selection.isCanvasItemSelected &&
          (selection.object as any).itemId
        ) {
          return this.isAppSelected$.next(
            [ItemModel.WebViewer, ItemModel.IFrame, ItemModel.Mario].includes(
              this.canvasItems[(selection.object as any).itemId]?.type as ItemModel,
            ),
          );
        } else {
          return this.isAppSelected$.next(false);
        }
      });
  }

  private setTransformation(vpt: number[]) {
    this.transformationMatrix$.next(vpt);
  }

  questionAnswer(answer: { choice: string; itemId: string }): void {
    if (!this.spaceRepo.activeSpace || !this.user) {
      return;
    }
    const choice = answer.choice;
    const itemId = answer.itemId;
    const sessionId = this.spaceRepo.activeSpace._id;
    // TODO refactor to use sendChangeStateEvent instead, to have a one method of updating remote objects
    const item = this.currentFrameItems.filter((x) => x.content_id === itemId)[0];
    const clonedItem = this.cloneItem(item);
    if (clonedItem?.question_state && !clonedItem?.question_state.answers) {
      clonedItem.question_state['answers'] = new Map<string, string>();
    } else if (clonedItem?.question_state?.answers) {
      clonedItem.question_state.answers[this.user._id] = choice;
    }

    this.realtimeSpaceService.service.handleCanvasItemsModified(
      [clonedItem],
      sessionId,
      this.currentFrameUid,
    );
  }

  showQuestionDetails(): void {
    console.log('show details');
  }

  loadItemFromCache(contentId: string, itemWrapperSettings: WrappedComponentSettings): void {
    if (this.itemDataSubscriptions[contentId]) {
      this.itemDataSubscriptions[contentId].unsubscribe();
    }
    this.itemDataSubscriptions[contentId] = this.sharedDataService.itemsData$[contentId]
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (!data) {
          return;
        }

        switch (data.accessState) {
          case AccessState.CanAccess:
            itemWrapperSettings.canAccess = true;
            if (itemWrapperSettings.componentInputs) {
              itemWrapperSettings.componentInputs.item = data.item;
            }
            if (itemWrapperSettings.resourceComponentInputs?.fragment?.type === FragmentType.PDF) {
              itemWrapperSettings.resourceComponentInputs.pdf = data.item;
            }
            break;
          case AccessState.NotInCourse:
            itemWrapperSettings.canAccess = false;
            itemWrapperSettings.notInvitedToCourse = true;
            break;
          case AccessState.NotInQuestion:
            itemWrapperSettings.canAccess = false;
            itemWrapperSettings.cannotAccessQuestion = true;
            break;
        }
      });
  }

  loadItemFromDb(
    contentId: string,
    data,
    itemWrapperSettings: WrappedComponentSettings,
    itemType: ItemModel,
  ): void {
    if (data?.status_code) {
      itemWrapperSettings.canAccess = false;
      switch (data.status_code) {
        case CustomErrorCodes.CANT_ACCESS_QUESTION:
          itemWrapperSettings.cannotAccessQuestion = true;
          this.sharedDataService.itemsData$[contentId].next({
            accessState: AccessState.NotInQuestion,
          });
          break;
        case CustomErrorCodes.NOT_IN_COURSE:
          itemWrapperSettings.notInvitedToCourse = true;
          this.sharedDataService.itemsData$[contentId].next({
            accessState: AccessState.NotInCourse,
          });
      }
    } else {
      const item = itemType === ItemModel.Question ? data.questions : data.note;
      itemWrapperSettings.canAccess = true;
      this.sharedDataService.itemsData$[contentId].next({
        accessState: AccessState.CanAccess,
        item: item,
      });
      if (itemWrapperSettings.componentInputs) {
        itemWrapperSettings.componentInputs.item = item;
      }
    }
  }

  updateItemsInCanvas(items: FrameItem[]): Promise<number> {
    // returns the number of added items
    return this.zone.run(async () => {
      if (!items.length) {
        this.canvasItems = {};
        this.canvasItemsArray$.next([]);
        this.cdRef.detectChanges();
        return 0;
      }

      const allPromises: Promise<void>[] = [];
      for (const item of items) {
        if (item._id in this.canvasItems) {
          this.updateCanvasItemWithFrameItem(item);
        } else if (item._id) {
          allPromises.push(this.loadItem(item, item.isPrivate));
        }
      }

      const localIds = Object.keys(this.canvasItems);
      const remoteIds = items.map((remoteItem) => remoteItem._id);
      const deletedItemsIds = localIds.filter((id) => !remoteIds.includes(id));
      deletedItemsIds.forEach((id) => this.changeCanvasItemsDict(id));
      await Promise.all(allPromises);
      this.setPreviewZoom?.emit();
      return allPromises.length;
    });
  }

  updateCanvasItemWithFrameItem(item: FrameItem) {
    const metaData = item.position;
    this.setItemState(item._id, metaData);
    const itemCanvas = this.canvasItems[item._id];

    itemCanvas.relatedItem = item;
    // TODO eliminate the need for this, by ensuring that the MarioState is a plain data object that contains no methods.
    // We are doing this because mario_state is not a plain data object,
    // it have getters, so when this is serialized and sent, the getters are not sent
    // So we need to recover the getters here
    if (item.model === ItemModel.Mario) {
      itemCanvas.relatedItem.mario_state = new MarioState(item.mario_state);
    }

    if (itemCanvas.relatedFabricItem && item.locked !== itemCanvas.relatedFabricItem['locked']) {
      itemCanvas.relatedFabricItem['locked'] = item.locked;
      itemCanvas.relatedFabricItem.onSelect({});
      this.sharedDataService.fabricCanvas?.requestRenderAll();

      this.sharedDataService.toolbarChange.next({
        action: LockObjectAction.TOGGLE_LOCK,
        args: [item, itemCanvas],
        target: LockObjectTarget.CANVAS_ITEM,
      });
    }

    if (item.model === ItemModel.Question) {
      this.setQuestionAnswers(item);
      if (itemCanvas.relatedItem?.question_state) {
        itemCanvas.relatedItem.question_state.show_hint =
          item.question_state?.show_hint ?? itemCanvas.relatedItem.question_state.show_hint;
        itemCanvas.relatedItem.question_state.show_answer =
          item.question_state?.show_answer ?? itemCanvas.relatedItem.question_state.show_answer;
        itemCanvas.relatedItem.question_state.answers =
          item.question_state?.answers ?? itemCanvas.relatedItem.question_state.answers;
      }
      // for setting the initial value for them
      if (itemCanvas?.itemSettings?.componentInputs?.questionState) {
        itemCanvas.itemSettings.componentInputs.questionState['show_answer'] =
          item.question_state?.show_answer || false;
        itemCanvas.itemSettings.componentInputs.questionState['show_hint'] =
          item.question_state?.show_hint || false;
      }
    }

    if (
      item.model === ItemModel.Formula &&
      itemCanvas?.itemSettings?.resourceComponentInputs?.fragment
    ) {
      const fragment = new TypedFragment(FragmentType.Text);
      fragment.fragment.data = item.equation_state?.equation_data;
      itemCanvas.itemSettings.resourceComponentInputs.fragment = fragment;
      if (itemCanvas.relatedFabricItem) {
        itemCanvas.relatedFabricItem['itemData'] = item.equation_state?.equation_data;
      }
    }

    this.canvasItemsDataObserverService.notifyCanvasItemListeners(itemCanvas);
  }

  setQuestionAnswers(item: FrameItem): void {
    const itemCanvas = this.canvasItems[item._id];
    if (!itemCanvas || item.model !== ItemModel.Question || !item.question_state?.answers) {
      return;
    }
    if (itemCanvas.itemSettings?.componentInputs) {
      itemCanvas.itemSettings.componentInputs.usersAnswers = new Map(
        Object.entries(item.question_state.answers),
      );
    }
  }

  loadItemsToCanvas(items: FrameItem[]): Promise<number> {
    // returns the number of added items
    if (!items) {
      return Promise.resolve(0);
    }
    return this.zone.run(async () => {
      this.canvasItems = {};
      this.canvasItemsArray$.next([]);
      const allPromises: Promise<void>[] = [];
      for (let i = 0; i < items.length; i++) {
        const promise = this.loadItem(items[i], items[i].isPrivate, i);
        allPromises.push(promise);
      }
      await Promise.all(allPromises);
      this.setPreviewZoom.emit();
      return allPromises.length;
    });
  }

  async loadItem(item: FrameItem, isPrivate: boolean, index = -1, preview = false): Promise<void> {
    if (!item.content_id) {
      item.content_id = item._id;
    }
    const canvasItem = new CanvasItem(item);
    canvasItem.id = item._id;
    canvasItem.contentId = item.content_id;
    canvasItem.type = item.model;
    canvasItem.preview = preview;
    canvasItem.frameUid = this.currentFrameUid;
    canvasItem.sessionId = this.sessionId;
    canvasItem.inPreviewMode = Boolean(this.prevFrame);
    canvasItem.relatedFabricItem =
      this.cachedRelatedFabricItems[canvasItem.id] ?? this.createRelatedFabricItem(item);
    this.changeCanvasItemsDict(item._id, canvasItem, false);

    let fabricItemResourceData: string | undefined = '';
    switch (item.model) {
      case ItemModel.Question:
        const questionMetaData = item.position;
        this.setItemState(item._id, questionMetaData, index);
        canvasItem.itemSettings = this.createQuestionContainer(item);
        this.setQuestionAnswers(item);
        canvasItem.movable = true;
        canvasItem.resizable = false;
        break;
      case ItemModel.Note:
        const noteMetaData = item.position;
        this.setItemState(item._id, noteMetaData, index);
        canvasItem.itemSettings = this.createNoteContainer(item.content_id);
        canvasItem.movable = true;
        canvasItem.resizable = false;
        break;
      case ItemModel.ResourceLoading:
        canvasItem.movable = false;
        canvasItem.resizable = false;
        canvasItem.itemSettings = new WrappedComponentSettings(WrappedComponent.resourceProgress);
        this.setItemState(item._id, item.position, index);
        break;
      case ItemModel.Resource:
        if (item.resource_state?.resource_item_model) {
          const resUrl = item.resource_state.resource_url;
          const resourceState = item.position;
          if (item.resource_state.public_url) {
            resourceState!.set('publicUrl', item.resource_state.public_url.toString());
          }
          this.setItemState(item._id, resourceState, index);
          const fragment = new TypedFragment(
            this.getResourceType(item.resource_state.resource_item_model),
          );
          fabricItemResourceData = resUrl;
          fragment.fragment.data = resUrl;
          if (resUrl && item.resource_state.resource_item_model === ResourceItemModel.IMAGE) {
            fragment.fragment.data = await this.itemsCanvasService.getTrustedUrl(
              resUrl,
              item.resource_state.public_url,
            );
          }

          if (
            resUrl &&
            (item.resource_state.resource_item_model === ResourceItemModel.DOCUMENT ||
              item.resource_state.resource_item_model === ResourceItemModel.PDF)
          ) {
            canvasItem.itemSettings = this.createPdfContainer(resUrl, item.content_id, fragment);
            canvasItem.movable = true;
            canvasItem.resizable = false;
            if (this.static) {
              item.position?.set('height', `${PDF_PAGE_DEFAULT_HEIGHT}`);
              canvasItem.relatedFabricItem?.set('height', PDF_PAGE_DEFAULT_HEIGHT);
            }
          } else {
            canvasItem.itemSettings = new WrappedComponentSettings(WrappedComponent.fragment);
            if (canvasItem.itemSettings.resourceComponentInputs) {
              canvasItem.itemSettings.resourceComponentInputs._id = item.content_id;
              canvasItem.itemSettings.resourceComponentInputs.fragment = fragment;
            }
          }
        }
        break;
      case ItemModel.Formula:
        canvasItem.resizable = false;
        const equationPosition = item.position;
        this.setItemState(item._id, equationPosition, index);
        const fragment = new TypedFragment(FragmentType.Text);
        fragment.fragment.data = item.equation_state?.equation_data;
        canvasItem.itemSettings = new WrappedComponentSettings(WrappedComponent.fragment);
        if (canvasItem.itemSettings.resourceComponentInputs) {
          canvasItem.itemSettings.resourceComponentInputs._id = item._id;
          canvasItem.itemSettings.resourceComponentInputs.fragment = fragment;
        }
        fabricItemResourceData =
          canvasItem.itemSettings?.resourceComponentInputs?.fragment?.fragment.data;
        break;
      case ItemModel.IFrame:
        const iframePosition = item.position;
        this.setItemState(item._id, iframePosition, index);
        canvasItem.itemSettings = new WrappedComponentSettings(WrappedComponent.iframe);
        fabricItemResourceData =
          canvasItem.itemSettings?.resourceComponentInputs?.fragment?.fragment.data;
        canvasItem.movable = true;

        if (canvasItem.relatedFabricItem) {
          canvasItem.relatedFabricItem.minScaleLimit = 0.85;
          canvasItem.relatedFabricItem.lockScalingFlip = true;
        }

        break;
      case ItemModel.WebViewer:
        const webViewerPosition = item.position;
        this.setItemState(item._id, webViewerPosition, index);
        canvasItem.itemSettings = new WrappedComponentSettings(WrappedComponent.browser);
        fabricItemResourceData =
          canvasItem.itemSettings?.resourceComponentInputs?.fragment?.fragment.data;
        canvasItem.movable = true;

        if (canvasItem.relatedFabricItem) {
          canvasItem.relatedFabricItem.minScaleLimit = 0.85;
          canvasItem.relatedFabricItem.lockScalingFlip = true;
        }

        break;
      case ItemModel.Mario:
        const marioPosition = item.position;
        this.setItemState(item._id, marioPosition, index);
        item.mario_state = new MarioState(item.mario_state);
        canvasItem.itemSettings = new WrappedComponentSettings(WrappedComponent.browser);
        fabricItemResourceData =
          canvasItem.itemSettings?.resourceComponentInputs?.fragment?.fragment.data;
        canvasItem.movable = true;

        let marioWidth = 700;
        if (marioPosition) {
          marioWidth = Number(marioPosition.get('width')) ?? marioWidth;
        }

        if (canvasItem.relatedFabricItem) {
          canvasItem.relatedFabricItem.minScaleLimit = 450 / marioWidth;
          canvasItem.relatedFabricItem.lockScalingFlip = true;
        }

        break;
      case ItemModel.Chat_GPT:
        canvasItem.itemSettings = new WrappedComponentSettings(WrappedComponent.chat_gpt);
        canvasItem.itemState.height = CHAT_GPT_APP_DEFAULT_HEIGHT;
        canvasItem.itemState.width = CHAT_GPT_APP_DEFAULT_WIDTH;
        canvasItem.movable = true;
        break;
      case ItemModel.RemoteVideo:
        canvasItem.itemSettings = new WrappedComponentSettings(WrappedComponent.remoteVideo);
        canvasItem.movable = true;
        break;
    }

    this.setItemConfiguration(canvasItem, item);

    if (canvasItem.relatedFabricItem) {
      canvasItem.relatedFabricItem['itemId'] = item._id;
      canvasItem.relatedFabricItem['itemType'] =
        canvasItem.itemSettings?.resourceComponentInputs?.fragment?.fragment.type;
      canvasItem.relatedFabricItem['itemData'] = fabricItemResourceData;
      canvasItem.relatedFabricItem['settings'] = item.position;
      canvasItem.relatedFabricItem['appName'] =
        canvasItem.relatedItem && this.itemsCanvasService.getAppName(canvasItem.relatedItem);

      if (
        this.addingItemToFrame &&
        canvasItem.relatedFabricItem['itemType'] === FragmentType.Youtube
      ) {
        this.addingItemToFrame = false;
      }
    }

    if (item.model && item.model !== ItemModel.ResourceLoading && !this.static) {
      this.zIndexObservables$[item._id] =
        this.boardItemsStackingOrderManager.createZIndexObservable(canvasItem);
      this.invisibilityObservables$[item._id] = this.canvasItemsDataObserverService
        .appUpdated$(canvasItem)
        .pipe(map((appState) => appState.isHidden));
    }
    this.canvasItemsArray$.next(Object.values(this.canvasItems));
  }

  setItemConfiguration(canvasItem: CanvasItem, item: FrameItem): void {
    if (this.canCreateOverlayApp(canvasItem)) {
      canvasItem.overlayApp = this.itemsCanvasService.createOverlayApp(item);
    }

    if (this.canCreateInteractionsBar(canvasItem)) {
      const interactionBar = this.itemsCanvasService.createInteractionBar(item);
      if (interactionBar) {
        canvasItem.interactionBar$ = of(interactionBar);
      }
    }
  }

  canCreateOverlayApp(canvasItem: CanvasItem): boolean {
    return (
      this.static ||
      (canvasItem.type === ItemModel.WebViewer && this.webViewerDisabled) ||
      isOverlaidAppOnCertainZoomLevel(canvasItem.type)
    );
  }

  canCreateInteractionsBar(canvasItem: CanvasItem): boolean {
    return !this.static && (canvasItem.type !== ItemModel.WebViewer || !this.webViewerDisabled);
  }

  createRelatedFabricItem(item: FrameItem): fabric.Object {
    const relatedFabricItem = new fabric.Rect({
      stroke: 'transparent',
      strokeWidth: 1,
      fill: '#ffffff',
      opacity: 0.01,
      centeredRotation: true,
      hasControls: true,
      excludeFromExport: true,
    });
    relatedFabricItem.hasControls = false;
    relatedFabricItem.set({ perPixelTargetFind: false });
    relatedFabricItem['customSelectable'] = true;
    relatedFabricItem['locked'] = item.locked;
    if (item.model === ItemModel.Resource) {
      switch (item.resource_state?.resource_item_model) {
        case ResourceItemModel.IMAGE:
          relatedFabricItem.hasControls = true;
          this.setControlsVisibility(relatedFabricItem, ResourceItemModel.IMAGE);
          break;
        case ResourceItemModel.VIDEO:
          relatedFabricItem.hasControls = true;
          relatedFabricItem.hasRotatingPoint = false;
          relatedFabricItem['editMode'] = true;
          this.setControlsVisibility(relatedFabricItem, ResourceItemModel.VIDEO);
          break;
        case ResourceItemModel.PDF:
        case ResourceItemModel.DOCUMENT:
          if (this.sharedDataService.fabricCanvas) {
            relatedFabricItem.hoverCursor = this.sharedDataService.fabricCanvas.defaultCursor;
          }
          relatedFabricItem.selectable = true;
          relatedFabricItem['customSelectable'] = true;
          relatedFabricItem.lockRotation = true;
          this.setControlsVisibility(relatedFabricItem, ResourceItemModel.IMAGE);
      }
    } else if (item.model === ItemModel.ResourceLoading) {
      relatedFabricItem.hasControls = false;
      relatedFabricItem.selectable = false;
      relatedFabricItem['customSelectable'] = false;
      this.setControlsVisibility(relatedFabricItem, ResourceItemModel.IMAGE);
    } else if (item.model === ItemModel.Note || item.model === ItemModel.Question) {
      relatedFabricItem.selectable = true;
      relatedFabricItem['customSelectable'] = true;
    } else if (item.model === ItemModel.IFrame) {
      this.populateRelatedFabricItem(relatedFabricItem);
      relatedFabricItem.type = FragmentType.Iframe;
      this.setControlsVisibility(relatedFabricItem, ResourceItemModel.IFRAME);
    } else if (item.model === ItemModel.WebViewer) {
      this.populateRelatedFabricItem(relatedFabricItem);
      relatedFabricItem.type = FragmentType.WebViewer;
      this.setControlsVisibility(relatedFabricItem, ResourceItemModel.WEB_VIEWER);
    } else if (item.model === ItemModel.Mario) {
      this.populateRelatedFabricItem(relatedFabricItem);
      relatedFabricItem.type = FragmentType.Mario;
      this.setControlsVisibility(relatedFabricItem, ResourceItemModel.MARIO);
    } else if (item.model === ItemModel.Chat_GPT) {
      this.populateRelatedFabricItem(relatedFabricItem);
      relatedFabricItem.type = FragmentType.Chat_GPT;
      relatedFabricItem.lockScalingFlip = true;
      relatedFabricItem.lockScalingX = false;
      relatedFabricItem.lockScalingY = false;
      relatedFabricItem.lockUniScaling = false;
      relatedFabricItem.minScaleLimit = 0.85;
      relatedFabricItem.top = Number(item.position?.get('top'));
      relatedFabricItem.left = Number(item.position?.get('left'));
      relatedFabricItem.height = Number(item.position?.get('height'));
      relatedFabricItem.width = Number(item.position?.get('width'));
      this.setControlsVisibility(relatedFabricItem, ResourceItemModel.CHAT_GPT);
    } else if (item.model === ItemModel.RemoteVideo) {
      relatedFabricItem.type = FragmentType.RemoteVideo;
      this.populateRelatedFabricItem(relatedFabricItem);
      relatedFabricItem.hasControls = true;
      relatedFabricItem.selectable = true;
      relatedFabricItem.hasBorders = true;
      relatedFabricItem['editMode'] = true;
      relatedFabricItem.top = Number(item.position?.get('top'));
      relatedFabricItem.left = Number(item.position?.get('left'));
      relatedFabricItem.height = Number(item.position?.get('height'));
      relatedFabricItem.width = Number(item.position?.get('width'));
      this.setControlsVisibility(relatedFabricItem, ResourceItemModel.RemoteVideo);

      this.activateItemWhenAdded(relatedFabricItem);
    }
    this.validateRelatedFabricItemOnMobile(relatedFabricItem);

    return relatedFabricItem;
  }

  private activateItemWhenAdded(relatedFabricItem: fabric.Rect & { itemId?: string }): void {
    fromEvent(this.sharedDataService.fabricCanvas as fabric.Canvas, 'object:added')
      .pipe(
        filter((event) => {
          const target = event.target as fabric.Object & { itemId: string };
          return target.itemId === relatedFabricItem.itemId;
        }),
        take(1),
      )
      .subscribe((event) => {
        this.sharedDataService.fabricCanvas?.setActiveObject(event?.target as fabric.Object);
        this.boardItemsInteractionsManager.setItemAsInteractable(
          (event.target as any)?.itemId,
          (event.target as any)?.itemType ?? event.target?.type,
        );
      });
  }

  private populateRelatedFabricItem(relatedFabricItem: fabric.Rect) {
    relatedFabricItem.hasControls = true;
    relatedFabricItem.cornerSize = 30;
    relatedFabricItem.transparentCorners = true;
    relatedFabricItem.cornerStrokeColor = 'transparent';
    relatedFabricItem.hasRotatingPoint = false;
    relatedFabricItem.hasBorders = false;
    relatedFabricItem.selectable = true;
    relatedFabricItem['customSelectable'] = true;
    relatedFabricItem.lockRotation = true;
  }

  validateRelatedFabricItemOnMobile(relatedFabricItem: fabric.Object): void {
    if (this.mobileUI) {
      relatedFabricItem.lockScalingX = true;
      relatedFabricItem.lockScalingY = true;
      relatedFabricItem.lockUniScaling = true;
      if (relatedFabricItem.controls.mobRot) {
        delete relatedFabricItem.controls.mobRot;
      }
    }
  }

  setControlsVisibility(relatedFabricItem: fabric.Object, resourceType: ResourceItemModel): void {
    relatedFabricItem.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
    });

    const disableRotating = [
      ResourceItemModel.VIDEO,
      ResourceItemModel.IFRAME,
      ResourceItemModel.WEB_VIEWER,
      ResourceItemModel.MARIO,
      ResourceItemModel.CHAT_GPT,
    ];
    if (disableRotating.includes(resourceType)) {
      const rotControls = [
        'rotBL1',
        'rotBL2',
        'rotBR1',
        'rotBR2',
        'rotTL1',
        'rotTL2',
        'rotTR1',
        'rotTR2',
      ];
      for (const control of rotControls) {
        relatedFabricItem.setControlVisible(control, false);
      }
    }
  }
  getResourceType(resourceType: ResourceItemModel): FragmentType {
    switch (resourceType) {
      case ResourceItemModel.IMAGE:
        return FragmentType.Image;
      case ResourceItemModel.VIDEO:
        return FragmentType.Youtube;
      case ResourceItemModel.SIMULATION:
        return FragmentType.Phet;
      case ResourceItemModel.DOCUMENT:
      case ResourceItemModel.PDF:
        return FragmentType.PDF;
      default:
        return FragmentType.Image;
    }
  }

  setItemState(itemId: string, metaData?: Map<string, string>, index = 0): void {
    this.itemsCanvasService.setItemState(
      itemId,
      {
        index,
        canvas: this.static ? this.staticCanvas : this.sharedDataService.fabricCanvas,
        canvasItems: this.canvasItems,
      },
      metaData,
    );
  }

  async getPositionMap(item: ItemData): Promise<Map<string, string>> {
    let width: number;
    let height: number;
    switch (item.model) {
      case ItemModel.IFrame: {
        const iframeAppConfiguration = AppsConfiguration[item.options.iframeType];
        height = Number(iframeAppConfiguration.app.height);
        width = Number(iframeAppConfiguration.app.width);
        break;
      }
      case ItemModel.Chat_GPT:
        height = CHAT_GPT_APP_DEFAULT_HEIGHT;
        width = CHAT_GPT_APP_DEFAULT_WIDTH;
        break;
      case ItemModel.WebViewer:
        height = IFRAME_APP_DEFAULT_HEIGHT;
        width = IFRAME_APP_DEFAULT_WIDTH;
        break;
      case ItemModel.RemoteVideo:
        height = REMOTE_VIDEO_DEFAULT_HEIGHT;
        width = REMOTE_VIDEO_DEFAULT_WIDTH;
        break;
      case ItemModel.Mario: {
        const resource = item.options.resource as MarioResource;
        const marioInstanceType = matchI(resource)({
          DEPLOYED: (r) => r.name,
          DEV_URL: () => CollaborativeApps.MARIO,
        });
        const marioAppConfiguration = AppsConfiguration[marioInstanceType];
        const appHeight = Number(marioAppConfiguration?.app.height);
        height = Number.isNaN(appHeight) ? MARIO_DEFAULT_HEIGHT : appHeight;
        const appWidth = Number(marioAppConfiguration?.app.width);
        width = Number.isNaN(appWidth) ? MARIO_DEFAULT_WIDTH : appWidth;
        break;
      }
      case ItemModel.ResourceLoading: {
        switch (item.resourceType) {
          case ResourceItemModel.IMAGE: {
            const dimensions = await this.itemsCanvasService.getImageAndDimensionFromFile(
              item.contentId as string,
            );
            height = dimensions.height;
            width = dimensions.width;
            break;
          }
          case ResourceItemModel.PDF:
          case ResourceItemModel.DOCUMENT:
            height = DOCUMENTS_DEFAULT_HEIGHT;
            width = DOCUMENTS_DEFAULT_WIDTH;
            break;
          case ResourceItemModel.VIDEO:
            height = VIDEO_DEFAULT_HEIGHT;
            width = VIDEO_DEFAULT_WIDTH;
            break;
          default:
            height = OTHER_ITEMS_DEFAULT_HEIGHT;
            width = OTHER_ITEMS_DEFAULT_WIDTH;
            break;
        }
        break;
      }
      case ItemModel.Resource:
        switch (item.resourceType) {
          case ResourceItemModel.IMAGE:
            if (item.resourceUrl) {
              const dimensions = await this.itemsCanvasService.getImageDimension(item);
              height = dimensions.height;
              width = dimensions.width;
            } else {
              height = IMAGES_DEFAULT_HEIGHT;
              width = IMAGES_DEFAULT_WIDTH;
            }
            break;
          case ResourceItemModel.PDF:
          case ResourceItemModel.DOCUMENT:
            height = DOCUMENTS_DEFAULT_HEIGHT;
            width = DOCUMENTS_DEFAULT_WIDTH;
            break;
          case ResourceItemModel.VIDEO:
            height = VIDEO_DEFAULT_HEIGHT;
            width = VIDEO_DEFAULT_WIDTH;
            break;
          default:
            height = OTHER_ITEMS_DEFAULT_HEIGHT;
            width = OTHER_ITEMS_DEFAULT_WIDTH;
            break;
        }
        break;
      case ItemModel.Formula:
        height = FORMULAS_DEFAULT_HEIGHT;
        width = FORMULAS_DEFAULT_WIDTH;
        break;
      default:
        height = OTHER_ITEMS_DEFAULT_HEIGHT;
        width = OTHER_ITEMS_DEFAULT_WIDTH;
        break;
    }

    const centerCoords = this.sessionsVptService.getCenterCoords();
    let top = centerCoords.y;
    let left = centerCoords.x;

    if (item.options?.left !== undefined && item.options?.top !== undefined) {
      top = item.options.top;
      left = item.options.left;
    }

    if (item.initItemState) {
      top = item.initItemState.top ? item.initItemState.top : top;
      left = item.initItemState.left ? item.initItemState.left : left;
      width = item.initItemState.width ? item.initItemState.width : width;
      height = item.initItemState.height ? item.initItemState.height : height;
    }

    top = top - height / 2;
    left = left - width / 2;

    // resource loading items are related to resource items, should be considered the sae
    const itemType = item.model === ItemModel.ResourceLoading ? ItemModel.Resource : item.model;

    const { top: staggeredTop, left: staggeredLeft } = this.getStaggeredPosition(
      { top, left },
      itemType,
      item.contentId,
      item.frameUid,
    );

    top = staggeredTop;
    left = staggeredLeft;

    this.staggeredItems.set(itemType, { top, left });

    if (item.relatedFabricItem) {
      top = item.relatedFabricItem.top;
      left = item.relatedFabricItem.left;
      width = item.relatedFabricItem.width;
      height = item.relatedFabricItem.height;
    }

    const position = new Map<string, string>([
      ['top', `${top}`],
      ['left', `${left}`],
      ['width', `${width}`],
      ['height', `${height}`],
    ]);

    position.set('index', `${this.boardItemsStackingOrderManager.generateMaxZIndex()}`);
    if (item.relatedFabricItem) {
      position.set('rotation', `${item.relatedFabricItem.angle}`);
    }

    return position;
  }

  async createFrameItem(item: ItemData): Promise<FrameItem> {
    const id = new ObjectId().toHexString();
    const sessionItem = {
      _id: id,
      content_id: item.contentId,
      model: item.model,
      position: await this.getPositionMap(item),
      ownerId: this.userService.userId ?? this.user?._id,
    };
    switch (item.model) {
      case ItemModel.Question:
        sessionItem['locked'] = item.locked ?? true;
        sessionItem['question_state'] = new QuestionState({ show_answer: false });
        break;
      case ItemModel.Note:
      case ItemModel.Worksheet:
        const questionState = new QuestionState({ show_answer: false });
        sessionItem['question_state'] = questionState;
        break;
      case ItemModel.IFrame:
        const iframeState = new IFrameState({
          url: item.resourceUrl,
          title: AppsConfiguration[item.options.iframeType].app.title,
          type: item.options.iframeType,
          ownerId: this.user?._id,
        });
        sessionItem['iframe_state'] = iframeState;
        break;
      case ItemModel.Chat_GPT:
        sessionItem['gpt_state'] = item.options;
        break;
      case ItemModel.WebViewer:
        const instanceType = item.options.instanceType;
        const snapshotHbSessionId = item.options.snapshotHbSessionId;
        const title = item.options.title;
        const webgl = item.options.webgl;
        const defaultUrl = item.options.defaultUrl;
        const initialZoom = item.options.initialZoom;
        const country = item.options.country;
        const adblock = item.options.adblock;
        const webViewerState = new WebViewerState({
          url: item.resourceUrl,
          hbSessionId: item.resourceName,
          type: instanceType,
          title,
          defaultUrl,
          webgl,
          snapshotHbSessionId,
          initialZoom,
          country,
          adblock,
        });
        sessionItem['browser_state'] = webViewerState;
        break;
      case ItemModel.Mario:
        const resource = item.options.resource as MarioResource;
        sessionItem['mario_state'] = new MarioState({
          resource: resource,
          id: uuidv4(),
          ownerId: this.user?._id,
        });
        break;
      case ItemModel.Formula:
        const equationState = new EquationState({ equation_data: item.equationString });
        sessionItem['equation_state'] = equationState;
        if (item.initItemState) {
          const fontSize = item.initItemState.fontSize ? `${item.initItemState.fontSize}` : '24';
          const fontColor = item.initItemState.fontColor
            ? `${item.initItemState.fontColor}`
            : '#333333';
          const backGroundColor = item.initItemState.backgroundColor
            ? `${item.initItemState.backgroundColor}`
            : 'transparent';
          sessionItem.position.set('font:size', fontSize);
          sessionItem.position.set('font:color', fontColor);
          sessionItem.position.set('background:color', backGroundColor);
        }
        break;
      case ItemModel.ResourceLoading:
      case ItemModel.Resource:
        if (item.model === ItemModel.Resource) {
          const resourceState = new ResourceState({
            resource_item_model: item.resourceType,
            resource_url: item.resourceUrl,
            resource_name: item.resourceName,
            public_url: item.publicUrl,
          });
          sessionItem['resource_state'] = resourceState;
        }
        switch (item.resourceType) {
          case ResourceItemModel.PDF:
          case ResourceItemModel.DOCUMENT:
            sessionItem['locked'] = item.locked ?? true;
            break;
        }
        break;
    }

    if (item.relatedFabricItem) {
      item.relatedFabricItem.itemId = id;
      this.cachedRelatedFabricItems[id] = item.relatedFabricItem;
    }

    return new FrameItem(sessionItem, item.isPrivate);
  }

  addFrame(
    items: FrameItem[],
    sessionId: string,
    options?: {
      goTo?: boolean;
      name?: string;
      boardFolderUid?: string;
      backgroundPattern?: WhiteboardBackground;
    },
  ): string | undefined {
    if (!this.spaceRepo.activeSpace || this.spaceRepo.activeSpace.currentRoomUid === undefined) {
      return;
    }
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.position) {
        item.position.set('index', `${i}`);
      }
    }
    const frame = new Frame({
      name:
        options?.name ??
        `Board ${this.spaceBoardsService.getActiveSpaceCurrentRoomAccessibleBoards().length + 1}`,
      backgroundPattern:
        options?.backgroundPattern ??
        this.spaceRepo.activeSpace.sessionDefaultFramesBackground?.pattern,
      backgroundColor: this.spaceRepo.activeSpace.sessionDefaultFramesBackground?.color,
    });
    if (options?.boardFolderUid) {
      frame.boardFolderUid = options.boardFolderUid;
    }
    this.realtimeSpaceService.service.addFrame(
      frame,
      sessionId,
      this.spaceRepo.activeSpace.currentRoomUid,
    );
    this.realtimeSpaceService.service.handleCanvasItemsAdded(items, sessionId, frame.uid);
    if (options?.goTo) {
      this.sharedDataService.selectFrameSubject.next(frame.uid);
    }

    const item = items[0];
    if (
      item &&
      (item.resource_state?.resource_item_model === ResourceItemModel.DOCUMENT ||
        item.resource_state?.resource_item_model === ResourceItemModel.PDF)
    ) {
      this.telemetry.endPerfScenario(KeyScenariosOnSpaces.RENDERING_PDF);
    }
    return frame.uid;
  }

  async addItemToFrame(item: FrameItem, sessionId: string, frameUid: string): Promise<void> {
    if (!this.spaceRepo.activeSpace) {
      return;
    }
    if (sessionId === this.sessionId) {
      if (this.currentFrameUid === frameUid) {
        this.addingItemToFrame = true;
        await this.loadItem(item, item.isPrivate);
        this.currentFrameItems.push(item);
      }

      this.realtimeSpaceService.service.handleCanvasItemsAdded([item], sessionId, frameUid);
    } else {
      this.addFrame([item], sessionId);
    }
  }

  // Please note that when using this method, and spaceId and frameId is not specified,
  // the current spaceId and frameId will be the one that will receive the update
  // and if you have any async operations that runs before this method, be aware of the possibility that the spaceId and/or the frameId can change before the async operation finishes
  sendChangeStateEvent(canvasItem: CanvasItem, spaceId?: string, frameId?: string): void {
    this.sendChangeStateEvents([canvasItem], spaceId, frameId);
  }

  sendChangeStateEvents(canvasItems: CanvasItem[], spaceId?: string, frameId?: string): void {
    spaceId = spaceId ?? this.spaceRepo.activeSpace?._id;
    frameId = frameId ?? this.currentFrameUid;
    if (!spaceId || !frameId) {
      return;
    }

    const clonedItems: YCanvasItemObject[] = [];
    for (const canvasItem of canvasItems) {
      const clonedItem = this.cloneItem(canvasItem.relatedItem);

      this.updateFrameItemWithCanvasItemState(clonedItem, canvasItem);

      if (canvasItem.relatedFabricItem) {
        canvasItem.relatedFabricItem['settings'] = clonedItem.position;
      }

      clonedItems.push(clonedItem);
      canvasItem.relatedItem = clonedItem;
    }

    this.realtimeSpaceService.service.handleCanvasItemsModified(clonedItems, spaceId, frameId);
  }

  cloneItem(item: YCanvasItemObject): YCanvasItemObject {
    const clonedItem: YCanvasItemObject = FrameItem.deepClone(item);
    clonedItem.userId = item.userId;
    clonedItem.vuid = item.vuid;
    clonedItem.uid = item.uid;
    clonedItem.fresh = item.fresh;
    return clonedItem;
  }

  updateFrameItemWithCanvasItemState(frameItem: FrameItem, canvasItem: CanvasItem) {
    // height, width... in the frameItem doesn't get the updates in realtime,
    // so we should be sure that when we are syncing them between users that we are not syncing an outdated data
    if (frameItem?.position) {
      frameItem.position.set('top', `${canvasItem.itemState.top}`);
      frameItem.position.set('left', `${canvasItem.itemState.left}`);
      if (
        canvasItem.type !== ItemModel.Question &&
        !canvasItem.itemSettings?.resourceComponentInputs?.pdf
      ) {
        // dont sync width and height for questions, notes and pdfs as they depend on local client.
        frameItem.position.set('height', `${canvasItem.itemState.height}`);
        frameItem.position.set('width', `${canvasItem.itemState.width}`);
      }
      frameItem.position.set('index', `${canvasItem.itemState.index}`);
      frameItem.position.set('rotation', `${canvasItem.itemState.rotation}`);
    }

    if (
      canvasItem?.itemSettings?.resourceComponentInputs?.fragment?.type === FragmentType.Text &&
      frameItem?.equation_state
    ) {
      frameItem.equation_state.equation_data =
        canvasItem.itemSettings.resourceComponentInputs.fragment.fragment.data;
      if (frameItem?.position) {
        frameItem.position.set('font:size', `${canvasItem.itemState.fontSize}`);
        frameItem.position.set('font:color', `${canvasItem.itemState.fontColor}`);
        frameItem.position.set('background:color', `${canvasItem.itemState.backgroundColor}`);
      }
    }
  }

  sentDeleteItemEvent(itemId: string): void {
    if (!this.spaceRepo.activeSpace) {
      return;
    }
    const sessionId = this.spaceRepo.activeSpace._id;
    const frameUID = this.currentFrameUid;
    this.currentFrameItems = this.currentFrameItems.filter((item) => item._id !== itemId);
    this.changeCanvasItemsDict(itemId);

    this.realtimeSpaceService.service.handleCanvasItemRemoved(itemId, sessionId, frameUID);
  }

  changeLockItemState(id: string, isLocked: boolean): void {
    if (!this.spaceRepo.activeSpace) {
      return;
    }

    if (this.spaceRepo.isCurrentUserSpaceParticipant) {
      return;
    }
    const sessionId = this.spaceRepo.activeSpace._id;
    const [item] = this.currentFrameItems.filter((x) => x._id === id);
    if (!item) {
      return;
    }

    const clonedItem = this.cloneItem(item);

    clonedItem.locked = isLocked;
    item.locked = isLocked;

    const itemCanvas = this.canvasItems[id];
    if (itemCanvas && itemCanvas.relatedFabricItem) {
      itemCanvas.relatedFabricItem['locked'] = clonedItem.locked;
      itemCanvas.relatedFabricItem.onSelect({});
      this.sharedDataService.fabricCanvas?.requestRenderAll();
    }

    this.sharedDataService.toolbarChange.next({
      action: LockObjectAction.TOGGLE_LOCK,
      args: [item, itemCanvas],
      target: LockObjectTarget.CANVAS_ITEM,
    });

    // TODO refactor to use sendChangeStateEvent instead, to have a one method of updating remote objects
    this.realtimeSpaceService.service.handleCanvasItemsModified(
      [clonedItem],
      sessionId,
      this.currentFrameUid,
    );
  }

  addImageFile(uploadFile: UploadFile): void {
    const fileInput: File = uploadFile.file as File;
    if (!fileInput) {
      return;
    }
    const uploadId = uuidv4();
    this.itemsCanvasService.insertResourceLoadingItem({
      id: uploadId,
      ...uploadFile,
      file: fileInput,
    });
    const task = this.uploadService.uploadToFireStorage(
      fileInput,
      (url) => {
        this.createResource(
          url,
          fileInput.name,
          fileInput.size,
          ResourceItemModel.IMAGE,
          uploadFile.sessionId,
          uploadFile.frameUid,
          uploadId,
          uploadFile.options,
        );
      },
      () => {
        this.itemsCanvasService.removeResourceLoadingItem(uploadId, this.currentFrameUid);
      },
    );
    if (task) {
      this.uploadService.createUploadTaskObjects(
        uploadId,
        fileInput,
        percentage(task),
        task.cancel,
      );
    }
  }

  async prepareToPasteItems(relatedFabricItems: any[]): Promise<void> {
    const copiedItems: CopiedItemData[] = [];
    for (const item of relatedFabricItems) {
      if ((item.itemData as string).includes('base64')) {
        this.telemetry.errorEvent('corrupted_image', {
          fileName: item.name,
          userId: this.userService.userId,
          reason: 'copy_paste',
          spaceId: this.spaceRepo.activeSpaceId,
        });
        continue;
      }
      const copiedItem = new CopiedItemData(
        item.itemData,
        item.itemType,
        item.settings,
        item,
        item.isPrivate,
      );
      copiedItems.push(copiedItem);
    }
    await this.handlePencilPasteEvent(copiedItems);
    this.cdRef.detectChanges();
  }

  addVideoFile(uploadFile: UploadFile): void {
    const fileInput: File = uploadFile.file as File;
    const percentageSubject = new BehaviorSubject({ progress: 0.01 });
    const uploadId = uuidv4();
    this.itemsCanvasService.insertResourceLoadingItem({
      id: uploadId,
      ...uploadFile,
      file: fileInput,
    });

    this.vimeoStorage
      .createVideo(fileInput)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          this.uploadingService = this.vimeoStorage.tusUpload(
            fileInput,
            response.body.upload.upload_link,
            (percentageValue) => {
              percentageSubject.next({ progress: percentageValue });
            },
            () => {
              this.createResource(
                response.body.link,
                fileInput.name,
                fileInput.size,
                ResourceItemModel.VIDEO,
                uploadFile.sessionId,
                uploadFile.frameUid,
                uploadId,
                uploadFile.options,
              );
            },
            () => {
              this.itemsCanvasService.removeResourceLoadingItem(uploadId, this.currentFrameUid);
              this.uploadService.cancelUpload(uploadId);
              this.cdRef.detectChanges();
              this.uploadService.showUploadFailedNotification(
                UploadFailedNotificationStatus.VIMEO_UPLOAD_FAILED,
              );
            },
          );
          if (this.uploadingService) {
            const task = {
              cancel: () => this.uploadingService?.abort,
            };
            this.uploadService.createUploadTaskObjects(
              uploadId,
              fileInput,
              percentageSubject.asObservable(),
              task.cancel,
            );
            this.uploadingService.start();
          }
        },
        error: () => {
          this.itemsCanvasService.removeResourceLoadingItem(uploadId, this.currentFrameUid);
          this.uploadService.showUploadFailedNotification(
            UploadFailedNotificationStatus.VIMEO_UPLOAD_FAILED,
          );
          this.uploadService.cancelUpload(uploadId);
          this.cdRef.detectChanges();
        },
      });
  }

  addPdfFile(fileInput: UploadFile, sessionId: string, frameUid: string, options = null): void {
    const file = fileInput.file as File;
    // file size is bigger than 5MB which isn't allowed
    if (fileInput.size / (1024 * 1024) > UPLOAD_LIMITS.DOCUMENT.SIZE) {
      this.uploadService.showUploadFailedNotification(UploadFailedNotificationStatus.SIZE_LIMIT);
      return;
    }

    if (!fileInput) {
      return;
    }
    // start performance scenario for pdf uploading
    this.telemetry.startPerfScenario(KeyScenariosOnSpaces.RENDERING_PDF, {
      size: fileInput.size,
      name: fileInput.name,
    });
    const uploadId = uuidv4();
    this.itemsCanvasService.insertResourceLoadingItem({
      ...fileInput,
      file,
      frameUid,
      sessionId,
      id: uploadId,
      type: ResourceItemModel.PDF,
    });
    const task = this.uploadService.uploadToFireStorage(
      file,
      (url) => {
        this.createResource(
          url,
          fileInput.name,
          fileInput.size,
          ResourceItemModel.DOCUMENT,
          sessionId,
          frameUid,
          uploadId,
          options,
        );
      },
      () => {
        this.itemsCanvasService.removeResourceLoadingItem(uploadId, this.currentFrameUid);
      },
    );
    if (!task) {
      return;
    }
    this.uploadService.createUploadTaskObjects(uploadId, fileInput, percentage(task), task.cancel);
  }

  addDocument(fileInput: UploadFile, sessionId: string, frameUid: string, options = null): void {
    if (!fileInput.file) {
      return;
    }
    // Ensure all files have a valid mime type before upload to Firebase Storage
    const file = resolveDocumentMimeType(fileInput.file as File);
    const uploadId = uuidv4();
    this.itemsCanvasService.insertResourceLoadingItem({
      id: uploadId,
      ...fileInput,
      file,
      frameUid,
      sessionId,
      type: ResourceItemModel.DOCUMENT,
    });
    const task = this.uploadService.uploadToFireStorage(
      file,
      (url) => {
        try {
          firstValueFrom(this.pdfConverterService.convertToPDF(url))
            .then((fileOutput) => {
              this.createResource(
                fileOutput.url,
                fileInput.name,
                fileOutput.size,
                ResourceItemModel.DOCUMENT,
                sessionId,
                frameUid,
                uploadId,
                options,
              );
            })
            .catch((error) => {
              Sentry.captureException(error);
              this.uploadService.cancelUpload(uploadId);
              this.uploadService.showUploadFailedNotification(
                UploadFailedNotificationStatus.SYSTEM_FAILURE,
              );
              // remove the loading item for loading resources
              this.itemsCanvasService.removeResourceLoadingItem(uploadId, this.currentFrameUid);
            });
        } catch (error) {
          Sentry.captureException(error);
          this.uploadService.cancelUpload(uploadId);
          this.uploadService.showUploadFailedNotification(
            UploadFailedNotificationStatus.SYSTEM_FAILURE,
          );
        }
      },
      () => {
        this.itemsCanvasService.removeResourceLoadingItem(uploadId, this.currentFrameUid);
      },
    );
    if (task) {
      this.uploadService.createUploadTaskObjects(
        uploadId,
        fileInput,
        percentage(task),
        task.cancel,
      );
    }
  }

  createResource(
    url: string,
    name: string,
    size: number,
    type: ResourceItemModel,
    sessionId: string,
    frameUid: string,
    uploadId?: string,
    options: any = null,
    publicResource = false,
  ): void {
    new Promise<string>((resolve) => {
      if (options?.isResourceLibraryObject) {
        // shouldn't create a new resource as it is already exist in our storage
        resolve(uuidv4());
      } else {
        const resource = {
          url: url,
          name: name,
          size: size,
          metadata: {
            topics_ids: [],
            minGrade: 0,
            maxGrade: 10,
          },
          type: type,
          acl: { public: false, visibility: 'CLASS' },
          path: 'Other',
          protected: true,
        };
        if (url.includes('base64')) {
          alert('Corrupted file upload through local files/drag & drop');
          console.log(url);
          return;
        }
        this.resourcesService
          .createResource(resource)
          .pipe(untilDestroyed(this))
          .subscribe((res) => {
            if (res?.body) {
              resolve(res.body.resource._id);
            }
          });
      }
    }).then((itemId) => {
      const item = new ItemData(
        itemId,
        ItemModel.Resource,
        false,
        type,
        url,
        name,
        undefined,
        undefined,
        undefined,
        publicResource,
        options,
      );
      item.sessionId = sessionId;
      item.frameUid = frameUid;

      // remove resource loading item
      this.itemsCanvasService.addResourceLoadingItemToRemoveList(uploadId as string, itemId);

      this.uploadService.updateGroupUploadProgress(options);

      this.sharedDataService.selectedItemsToInsert.next([item]);
      if (item.contentId && uploadId) {
        this.uploadService.onResourceCreated(item.resourceType, item.contentId, uploadId);
      }
    });
  }

  handleDeleteKeyboardEvent(): void {
    // Don't delete certain elements
    if (
      document.activeElement?.nodeName === 'TEXTAREA' ||
      document.activeElement?.nodeName === 'DIV' ||
      document.activeElement?.nodeName === 'INPUT'
    ) {
      return;
    }

    const itemToDelete = this.boardItemsInteractionsManager.selectedCanvasItem;
    if (!itemToDelete) {
      return;
    }
    // Don't delete item if it's locked
    if (
      (itemToDelete.relatedFabricItem as { locked: boolean } | undefined)?.locked ||
      itemToDelete.relatedItem?.locked
    ) {
      return;
    }

    // Don't delete Browser or Iframe elements
    if (
      !itemToDelete ||
      itemToDelete.type === ItemModel.WebViewer ||
      itemToDelete.type === ItemModel.IFrame ||
      itemToDelete.type === ItemModel.Chat_GPT ||
      itemToDelete.type === ItemModel.Mario
    ) {
      return;
    }

    // proceed with the delete
    this.sentDeleteItemEvent(itemToDelete.id);
  }

  private addDropHandler(): void {
    this.domListener.add(document, 'drop', ($event) => {
      const dropPositionOnCanvas = this.itemsCanvasService.getDropPositionOnCanvas($event);
      if ($event.dataTransfer?.files.length) {
        this.uploadFiles($event.dataTransfer?.files, $event, dropPositionOnCanvas);
      } else if ($event.dataTransfer?.getData('text/uri-list')) {
        this.insertImageFromFirebase(
          $event.dataTransfer?.getData('text/uri-list'),
          $event,
          dropPositionOnCanvas,
        );
      }
    });
  }

  addClipboardPasteHandler(): void {
    this.domListener.add(window, 'paste', (event) => this.handleClipboardPasteEvent(event));
  }
  // Clipboard paste event has the priority over other copied items
  handleClipboardPasteEvent(event): void {
    if (
      this.sharedDataService.isBoardLockedByHost() &&
      event.target.className.split(' ') === 'app-root-class'
    ) {
      this.showBoardLockedNotification(true);
      return;
    }

    if (
      (document.activeElement?.nodeName === 'DIV' &&
        document.activeElement?.parentElement?.id === 'advanced-text-fragment') ||
      this.sharedDataService.sessionView.getSessionView() !== SessionView.WHITEBOARD ||
      this.isDisableKeyEvents
    ) {
      return;
    }
    if (!this.spaceRepo.activeSpace) {
      throw new Error("Can't paste, session is not defined");
    }
    this.uploadFiles(event?.clipboardData?.files, event);
  }

  uploadFiles(files: File[], event, position?: Position): void {
    if (this.sharedDataService.isBoardLockedByHost()) {
      event.preventDefault();
      this.showBoardLockedNotification();
      return;
    }

    if (files?.length) {
      this.addExternalFile(Array.from(files), position);
      event.preventDefault();
    }
  }

  insertImageFromFirebase(imageUrl: string, event, position?: Position, size = 400): void {
    if (this.sharedDataService.isBoardLockedByHost()) {
      event.preventDefault();
      this.showBoardLockedNotification();
      return;
    }
    if (this.spaceRepo.activeSpace && this.spaceBoardsService.activeSpaceSelectedBoard) {
      const options: any = {
        width: size,
        ...(position ?? {}),
      };

      this.sharedDataService.uploadItem.next([
        new UploadFile(
          imageUrl,
          imageUrl,
          size,
          ResourceItemModel.IMAGE,
          this.spaceRepo.activeSpace._id,
          this.spaceBoardsService.activeSpaceSelectedBoard.uid,
          options,
          true,
        ),
      ]);
    }
    event.preventDefault();
  }

  public addExternalFile(files: ExternalFile[], position?: Position): void {
    if (!this.spaceRepo.activeSpace) {
      return;
    }

    const filesToUpload: UploadFile[] = [];

    // Show upload modal for first pdf only and ignore the rest
    if (this.uploadService.containsMoreThanOneType(files, 'application/pdf')) {
      files = this.uploadService.AllowOneFileFromType(files, 'application/pdf');
    }

    files.forEach((fileOrObject) => {
      let file;
      let options;
      if (fileOrObject instanceof File) {
        file = fileOrObject;
      } else {
        file = fileOrObject.file;
        options = fileOrObject.options;
      }
      let itemModel = '';
      if (file.type.includes('pdf')) {
        this.handlePdfFileDrop(file);
        return;
      } else if (file.type.includes('video')) {
        itemModel = ResourceItemModel.VIDEO;
      } else if (file.type.includes('image')) {
        itemModel = ResourceItemModel.IMAGE;
      } else if (checkIfValidLibreOfficeType(file)) {
        itemModel = ResourceItemModel.DOCUMENT;
      }

      options = {
        ...(options ?? {}),
        ...position,
      };

      if (itemModel) {
        const uploadFile = new UploadFile(
          file,
          file.name,
          file.size,
          itemModel as ResourceItemModel,
          this.sessionId,
          this.currentFrameUid,
          options,
        );

        filesToUpload.push(uploadFile);
      } else {
        const title = new IconMessageToasterElement(
          { icon: 'cloud_upload', size: 16 },
          this.translate.instant('Upload file type error'),
        );
        const message = new IconMessageToasterElement(
          undefined,
          this.translate.instant('This file type is not yet supported'),
        );
        const okButton = new ButtonToasterElement(
          [undefined, this.translate.instant('Ok')],
          {
            handler: () => undefined,
            close: true,
          },
          ButtonToasterElementStyle.RAISED,
        );
        const uploadFailedNotificationData = new NotificationDataBuilder(ERRORS.UPLOAD_FAILED)
          .type(NotificationType.ERROR)
          .style(ToasterPopupStyle.ERROR)
          .topElements([title])
          .middleElements([message])
          .bottomElements([okButton])
          .priority(1030)
          .build();
        this.notificationToasterService.showNotification(uploadFailedNotificationData);
      }
    });

    this.sharedDataService.uploadItem.next(filesToUpload);
  }

  private handlePdfFileDrop(file: File): void {
    this.uploadService.setPendingLeftPanelFile(file);
    if (this.sharedDataService.leftPanelView.getValue()?.panelView !== PanelView.uploadFile) {
      // only call this when the value is not already PanelView.uploadFile because if so, the panel will close
      this.sharedDataService.changeLeftPanelView.next(PanelView.uploadFile);
    }
  }

  async handlePencilPasteEvent(copiedItems: CopiedItemData[]): Promise<void> {
    if (this.isOffline) {
      return;
    }
    const itemsData: ItemData[] = [];
    for (const copiedItem of copiedItems) {
      const id = new ObjectId().toHexString();
      let itemData;

      if (!copiedItem.type) {
        const canvasItem = this.canvasItems[copiedItem.relatedFabricItem.itemId];
        itemData = new ItemData(
          canvasItem.contentId,
          canvasItem.type as ItemModel,
          Boolean(canvasItem.relatedItem?.isPrivate),
          undefined,
          undefined,
          undefined,
          copiedItem.relatedFabricItem,
          undefined,
          canvasItem.preview,
          undefined,
          undefined,
          Boolean(copiedItem.relatedFabricItem.locked),
        );
        if (this.spaceRepo.activeSpace) {
          itemData.sessionId = this.spaceRepo.activeSpace._id;
          itemData.frameUid = this.currentFrameUid;
        }
        itemsData.push(itemData);
        continue;
      }
      switch (copiedItem.type) {
        case FragmentType.Text:
          itemData = new ItemData(
            id,
            ItemModel.Formula,
            copiedItem.isPrivate,
            undefined,
            undefined,
            undefined,
            copiedItem.relatedFabricItem,
            copiedItem.data,
          );
          if (!itemData.initItemState) {
            itemData.initItemState = {};
          }
          itemData.initItemState.fontColor = copiedItem?.settings?.get('font:color');
          itemData.initItemState.fontSize = copiedItem?.settings?.get('font:size');
          itemData.initItemState.backgroundColor = copiedItem?.settings?.get('background:color');
          break;
        case FragmentType.PDF:
          const canvasItem = this.canvasItems[copiedItem.relatedFabricItem.itemId];
          itemData = new ItemData(
            id,
            ItemModel.Resource,
            Boolean(copiedItem.isPrivate),
            ResourceItemModel.DOCUMENT,
            copiedItem.relatedFabricItem.itemData,
            id,
            copiedItem.relatedFabricItem,
            undefined,
            canvasItem ? canvasItem.preview : false,
            copiedItem.relatedFabricItem.itemData,
            undefined,
            Boolean(copiedItem.relatedFabricItem.locked),
          );
          break;
        case FragmentType.Image:
          if (copiedItem.data) {
            itemData = new ItemData(
              id,
              ItemModel.Resource,
              copiedItem.isPrivate,
              ResourceItemModel.IMAGE,
              copiedItem.data,
              undefined,
              copiedItem.relatedFabricItem,
              undefined,
              undefined,
              !!copiedItem.settings.get('publicUrl'),
            );
          }
          break;
        case FragmentType.Youtube:
          itemData = new ItemData(
            id,
            ItemModel.Resource,
            copiedItem.isPrivate,
            ResourceItemModel.VIDEO,
            copiedItem.data,
            undefined,
            copiedItem.relatedFabricItem,
          );
          break;
        case FragmentType.Iframe:
          itemData = new ItemData(
            id,
            ItemModel.IFrame,
            copiedItem.isPrivate,
            ResourceItemModel.IFRAME,
            copiedItem.data,
            copiedItem.relatedFabricItem,
          );
          break;
        case FragmentType.WebViewer:
          itemData = new ItemData(
            id,
            ItemModel.WebViewer,
            copiedItem.isPrivate,
            ResourceItemModel.WEB_VIEWER,
            copiedItem.data,
            copiedItem.relatedFabricItem,
          );
          break;
        case FragmentType.Mario:
          itemData = new ItemData(
            id,
            ItemModel.Mario,
            copiedItem.isPrivate,
            ResourceItemModel.MARIO,
            copiedItem.data,
            copiedItem.relatedFabricItem,
          );
          break;
      }
      if (this.spaceRepo.activeSpace) {
        itemData.sessionId = this.spaceRepo.activeSpace._id;
        itemData.frameUid = this.currentFrameUid;
      }
      itemsData.push(itemData);
    }
    await this.insertItems(itemsData);
  }

  private setuprealtime(newSessionId: string, oldSessionId?: string) {
    if (!newSessionId || newSessionId === oldSessionId) {
      return;
    }

    this.cleanuprealtime(oldSessionId);

    const authData = this.sharedDataService.sessionAuthData[newSessionId];
    const realtimeToken = authData?.realtimeToken;

    if (!realtimeToken) {
      return;
    }

    const realtimeObservable = this.realtimeService.subscribeSession(newSessionId, realtimeToken);
    this.realtimeSubscription = realtimeObservable
      .pipe(untilDestroyed(this))
      .subscribe((message) => {
        if (message) {
          this.handlerealtimeMessage(message);
        }
      });
  }

  private cleanuprealtime(sessionId?: string) {
    if (sessionId) {
      this.realtimeService.unsubscribeSession(sessionId);
    }

    if (this.realtimeSubscription) {
      this.realtimeSubscription.unsubscribe();
    }
  }

  private handlerealtimeMessage(update: RealtimeDataUpdate) {
    const sessionId = update.sessionId || update.id;
    const isThisSession = sessionId === this.sessionId;

    if (isThisSession && update.action === 'reloadItem') {
      const frameSelectedUid = this.spaceRepo.activeSpace?.selectedBoardUid;
      if (frameSelectedUid == undefined) {
        return;
      }
      let needToReloadItems: YCanvasItemObject[] | FrameItem[] | undefined = [];
      needToReloadItems = this.currentFrameItems.filter(
        (item) => item.content_id === update.data.questionId,
      );
      needToReloadItems?.forEach((item) => {
        if (this.needToReload(item._id, update.data.state)) {
          this.reloadItem(item._id, update.data.questionId);
        }
      });
    }
  }

  needToReload(itemId: string, visibility: string): boolean {
    const canvasItem = this.canvasItems[itemId];
    const canAccess = !!canvasItem?.itemSettings?.canAccess;
    if (!canvasItem || !this.user) {
      return true;
    }
    let isStudent: boolean;

    switch (visibility) {
      case 'ME':
        return true;
      case 'TEACHERS':
        isStudent = this.aclService.isStudent(this.user);
        return (isStudent && canAccess) || (!isStudent && canAccess);
      case 'CLASS':
        isStudent = this.aclService.isStudent(this.user);
        return (isStudent && !canAccess) || (!isStudent && canAccess);
      case 'PUBLIC':
        return !canAccess;
    }
    return true;
  }

  reloadItem(itemId: string, contentId: string): void {
    this.changeCanvasItemsDict(itemId);
    delete this.sharedDataService.itemsData$[contentId];
    this.updateItemsInCanvas(this.currentFrameItems);
  }

  /*
   *  Please when using this function, make sure that the component that calls it isn't destroyed yet,
   *    to prevent adding items to the canvas when they shouldn't be added
   *
   *  emitChanges parameter is introduced if the user wanted to delay the changes after a certain operation,
   *    to make sure that changes made to the content of `canvasItems` are reflected properly upon insertion, given that now the component is OnPush.
   * */
  changeCanvasItemsDict(itemId: string, item?: CanvasItem, emitChanges = true): void {
    if (item) {
      this.canvasItems[itemId] = item;
    } else {
      delete this.canvasItems[itemId];
    }
    if (emitChanges) {
      this.canvasItemsArray$.next(Object.values(this.canvasItems));
    }
  }

  sendLogData(): void {
    const itemsCanvasLogData = {};
    itemsCanvasLogData['sessionId'] = this.sessionId;
    itemsCanvasLogData['frameUid'] = this.currentFrameUid;
    itemsCanvasLogData['items'] = this.canvasItems;
    itemsCanvasLogData['selectedItem'] =
      this.boardItemsInteractionsManager.selectedCanvasItem?.id ?? '';
    this.sharedDataService.sendLogData.next({ itemsCanvasLogData });
  }

  insertMarioApp(resource: MarioResource, isPrivate: boolean, addToNewBoard = false) {
    if (!resource || !this.spaceRepo.activeSpace) {
      return;
    }
    const uid = uuidv4();
    const item = new ItemData(uid, ItemModel.Mario, isPrivate);
    item.sessionId = this.spaceRepo.activeSpace._id;
    item.frameUid =
      this.spaceRepo.activeSpaceSelectedBoardUid ??
      this.spaceBoardsService.getActiveSpaceCurrentRoomAccessibleBoards()[0].uid;
    if (!item.options) {
      item.options = {};
    }
    item.options['resource'] = resource;
    item.addToNewBoard = addToNewBoard;
    this.sharedDataService.selectedItemsToInsert.next([item]);
  }

  insertIframe(
    url: string,
    addToNewBoard: boolean,
    isPrivate: boolean,
    iframeType: string = CollaborativeApps.IFRAME,
    initialSize?: IframeDimensions,
    initialPosition?: IframePosition,
  ) {
    if (!url || !this.spaceRepo.activeSpace) {
      return;
    }
    const uid = uuidv4();
    const item = new ItemData(uid, ItemModel.IFrame, isPrivate);
    item.sessionId = this.spaceRepo.activeSpace._id;
    item.frameUid =
      this.spaceRepo.activeSpaceSelectedBoardUid ??
      this.spaceBoardsService.getActiveSpaceCurrentRoomAccessibleBoards()[0].uid;
    item.resourceUrl = url;
    if (!item.options) {
      item.options = {};
    }
    item.options['iframeType'] = iframeType;
    item.options.width = initialSize?.width ?? 0;
    item.options.height = initialSize?.height ?? 0;
    item.options.top = initialPosition?.top;
    item.options.left = initialPosition?.left;
    item.addToNewBoard = addToNewBoard;
    this.sharedDataService.selectedItemsToInsert.next([item]);
  }

  async insertWebViewer(
    isPrivate: boolean,
    opts: {
      options?: any;
      instanceType?: string | CustomWebViewerConfiguration | CollaborativeApps;
      hbSessionId?: string;
      addToNewBoard?: boolean;
    },
    addItem = true,
  ): Promise<ItemData | undefined> {
    // Add default values (if any) & destructure passed options
    const { options, instanceType, hbSessionId, addToNewBoard } = { addToNewBoard: false, ...opts };
    if (!this.spaceRepo.activeSpace) {
      return;
    }
    this.sharedDataService.sessionView.switchToSessionView(SessionView.WHITEBOARD);

    const webViewerConfig: WebViewerConfig = {
      webgl: false,
      adblock: true,
    };
    let title;
    let type;
    let initialZoom;
    let otherProps;

    if (typeof instanceType === 'string') {
      const app = CollaborativeApps[instanceType as keyof typeof CollaborativeApps];
      this.sharedDataService.appDisabled$.next(app);
      webViewerConfig.webgl = AppsConfiguration[instanceType]?.app?.webgl ?? false;
      webViewerConfig.defaultUrl = AppsConfiguration[instanceType]?.app?.defaultUrl;
      title = AppsConfiguration[instanceType]?.app?.title ?? 'Web Viewer';
      type = instanceType;
      initialZoom = AppsConfiguration[instanceType]?.app?.initialZoom;
      const { url, ...propertiesExceptUrl } = options ?? {};
      otherProps = propertiesExceptUrl;
      if (instanceType) {
        webViewerConfig.webgl = AppsConfiguration[instanceType]?.app.webgl ?? false;
        webViewerConfig.defaultUrl = url ?? AppsConfiguration[instanceType]?.app.defaultUrl;
      }
      webViewerConfig.country = AppsConfiguration[instanceType]?.app?.country;
      webViewerConfig.adblock = AppsConfiguration[instanceType]?.app?.adblock ?? true;
    } else if (instanceType) {
      this.sharedDataService.appDisabled$.next(instanceType.type);
      webViewerConfig.webgl = instanceType.webgl;
      webViewerConfig.defaultUrl = instanceType.defaultUrl;
      title = instanceType.title;
      type = instanceType.type;
    }

    const hbSession = await this.spacesService.createWebViewerSession(hbSessionId, webViewerConfig);
    if (hbSession && hbSession.state === HB_VM_STATE.Alive && hbSession.data) {
      const uid = uuidv4();
      const item = new ItemData(uid, ItemModel.WebViewer, isPrivate);
      item.sessionId = this.spaceRepo.activeSpace._id;
      item.frameUid =
        options?.frameUid ??
        this.spaceRepo.activeSpace.selectedBoardUid ??
        this.spaceBoardsService.getActiveSpaceCurrentRoomAccessibleBoards()?.[0].uid;
      item.resourceUrl = hbSession.data.embed_url;
      item.resourceName = hbSession.data.session_id;
      item.addToNewBoard = addToNewBoard;
      item.options = {
        ...otherProps,
        instanceType: type,
        snapshotHbSessionId: hbSession.data.snapshot_session_id,
        title,
        initialZoom,
        ...webViewerConfig,
      };
      if (addItem) {
        this.sharedDataService.selectedItemsToInsert.next([item]);
      }
      return item;
    }
  }

  async openPencilAssistantModal() {
    if (
      this.lessonGeneratorService.lessonGenerationInProgress() ||
      this.lessonGeneratorRequestInProgress
    ) {
      this.toastr.info(
        this.translate.instant(
          'Lesson Generation in progress, please wait for the previous request to complete',
        ),
      );
      return;
    }
    this.sharedDataService.sessionView.switchToSessionView(SessionView.WHITEBOARD);
    const width = this.mobileUI ? '90vw' : 'auto';
    this.sharedDataService.changeLeftPanelView.next(undefined);
    const confirmDialog = this.dialog.open(AddPencilAiComponent, {
      data: { type: 'promptInput' },
      disableClose: false,
      panelClass: 'pencil-ai-dialog',
      width: width,
      minWidth: width,
      maxWidth: width,
    });
    const data = await firstValueFrom(confirmDialog.afterClosed());
    if (!data) {
      return;
    }
    const notification = this.lessonGeneratorService.generateInProgressNotification();
    this.notificationToasterService.showNotification(notification);

    const config = {
      model:
        this.flagsService.featureFlagsVariables[FLAGS.PENCIL_AI_ASSISTANT].ai_assistant_model ??
        'gpt-4o-mini',
      include_worksheet:
        this.flagsService.featureFlagsVariables[FLAGS.PENCIL_AI_ASSISTANT].include_worksheet ??
        true,
    };
    this.lessonGeneratorRequestInProgress = true;
    this.spacesService
      .queryPencilAssistant(data, config)
      .pipe(untilDestroyed(this), first())
      .subscribe({
        next: async (res: any) => {
          if (res && res.error) {
            this.notificationToasterService.dismissNotificationsByCode([
              this.lessonGeneratorService.getInProgressNotificationCode(),
            ]);
            this.toastr.error(this.translate.instant('Please enter a valid query'), undefined);
            return;
          }
          await this._llmParseResponse(res.response);
          const successNotfication = this.lessonGeneratorService.getLessonReadyNotification();
          this.notificationToasterService.dismissNotificationsByCode([
            this.lessonGeneratorService.getInProgressNotificationCode(),
          ]);

          this.notificationToasterService.showNotification(successNotfication);
        },
        error: () => {
          this.notificationToasterService.dismissNotificationsByCode([
            this.lessonGeneratorService.getInProgressNotificationCode(),
          ]);
          this.toastr.error(this.translate.instant('Something went wrong, please try again'));
        },
        complete: () => {
          this.lessonGeneratorRequestInProgress = false;
        },
      });
  }

  private async _generateBoardFromLLMResponse(response: ParsedLLMOutput, frameUid: string) {
    if (!this.sharedDataService.fabricCanvas) {
      return;
    }

    const options = {
      isResourceLibraryObject: true,
    };
    for (const object of response.objects) {
      if (object.text && object.type !== 'latex_equation') {
        this._llmAddTextBox(object);
      }
      switch (object.type) {
        case 'resource_library': {
          await this._llmAddResourceLibraryObject(object, frameUid, options);
          break;
        }
        case 'simulation': {
          if (!object.url) {
            this.telemetry.errorEvent('missing_url_llm_simulation', { object });
            break;
          }
          await this._llmAddWebViewerApp(object.url, frameUid, CollaborativeApps.PHET_SIMULATION);
          break;
        }
        case 'latex_equation': {
          await this._llmAddLatexEquation(object, frameUid);
          break;
        }
      }
    }

    if (!response.youtubeObjects || response.youtubeObjects.length === 0) {
      return;
    }

    this._llmAddTextBox({ text: 'Related Videos', type: 'sub_heading' });

    for (const object of response.youtubeObjects) {
      await this._llmAddWebViewerApp(object.url, frameUid, CollaborativeApps.AI_YOUTUBE);
    }
  }

  private async _llmParseResponse(response: LLMAPIResponse) {
    if (!this.spaceRepo.activeSpaceId || !this.spaceRepo.activeSpaceCurrentRoomUid || !this.user) {
      return;
    }

    // Create board folder for all AI generated boards
    const boardFolder = await this._createBoardFolder(response.title);
    if (!boardFolder) {
      return;
    }
    this.lessonProcessor = this.lessonGeneratorService.generateLesson(boardFolder);

    // Iterate through list of AI generated boards, create them & add it to created board folder
    for (let i = 0; i < response.boards.length; i++) {
      const board = response.boards[i];
      if (!this.spaceRepo.activeSpace?.currentRoomUid) {
        continue;
      }
      const frame = new Frame({
        name: board.topic,
        boardFolderUid: boardFolder.uid,
        backgroundPattern: WhiteboardBackground.NONE,
      });
      const frameUid = frame.uid;
      this.lessonProcessor.setCurrentFrame(frame);
      if (!frameUid) {
        continue;
      }
      await this._generateBoardFromLLMResponse(board, frameUid);
    }
    this.realtimeSpaceService.service.addAiLessonGeneratedContent(this.lessonProcessor);
  }

  private _llmAddTextBox(object: LLMObject): void {
    if (!this.lessonProcessor) {
      return;
    }
    const { modifiedText, boldPositions } = this._llmFindBoldContentPositions(object.text);
    const textbox = new fabric.Textbox(modifiedText, {
      top: this.lessonProcessor.getCurrentTop(),
      left: this.lessonProcessor.getCurrentLeft(),
      padding: 10,
      isWrapping: true,
      width: this.FIXED_COLUMN_WIDTH,
      fontSize: getLLMFontSize[object.type],
      fontWeight: getLLMFontWeight[object.type],
    });
    for (const position of boldPositions) {
      textbox.setSelectionStyles({ fontWeight: 'bold' }, position[0], position[1]);
    }
    this.lessonProcessor.addFabricObject(textbox);
    this.lessonProcessor.addToCurrentTop(
      textbox.getScaledHeight() + this.FIXED_HEIGHT_PADDING_BETWEEN_OBJECTS,
    );
  }

  private async _llmAddResourceLibraryObject(
    object: LLMObject,
    frameUid: string,
    options: any,
  ): Promise<void> {
    if (
      !object.url ||
      !this.spaceRepo.activeSpace?._id ||
      !this.spaceBoardsService.activeSpaceSelectedBoard ||
      !this.lessonProcessor
    ) {
      return;
    }
    this.lessonProcessor.addToCurrentTop(this.FIXED_HEIGHT_PADDING_BETWEEN_OBJECTS);
    const fileUrl = `${
      environment.firebaseAssetsPublicUrl + environment.resourceLibraryFolderName + object.url
    }.svg`;
    const { width, height } = await this.getImageDimensions(fileUrl);
    if (!width || !height) {
      return;
    }
    const item = new ItemData(
      uuidv4(),
      ItemModel.Resource,
      false,
      ResourceItemModel.IMAGE,
      fileUrl,
      'Resource',
      undefined,
      undefined,
      undefined,
      true,
      {
        ...options,
        top: this.lessonProcessor.getCurrentTop() + height / 2,
        left: this.lessonProcessor.getCurrentLeft() + width / 2,
      },
    );
    item.sessionId = this.spaceRepo.activeSpace?._id;
    item.frameUid = frameUid;
    const frameItem = await this.createFrameItem(item);
    this.lessonProcessor.addFrameItem(frameItem);
    this.lessonProcessor.addToCurrentTop(height + this.FIXED_HEIGHT_PADDING_BETWEEN_OBJECTS);
  }

  private async _llmAddWebViewerApp(
    url: string,
    frameUid: string,
    appType: CollaborativeApps,
  ): Promise<void> {
    if (!this.lessonProcessor) {
      return;
    }
    const webViewerItem = await this.insertWebViewer(
      false,
      {
        options: {
          top: this.lessonProcessor.getCurrentTop() + IFRAME_APP_DEFAULT_HEIGHT / 2,
          left: this.lessonProcessor.getCurrentLeft() + IFRAME_APP_DEFAULT_WIDTH / 2,
          url,
          frameUid,
        },
        instanceType: appType,
      },
      false,
    );
    if (!webViewerItem) {
      return;
    }
    const frameItem = await this.createFrameItem(webViewerItem);
    this.lessonProcessor.addFrameItem(frameItem);
    this.lessonProcessor.addToCurrentTop(
      IFRAME_APP_DEFAULT_HEIGHT + this.FIXED_HEIGHT_PADDING_BETWEEN_OBJECTS,
    );
  }

  private async _llmAddLatexEquation(object: LLMObject, frameUid: string): Promise<void> {
    if (!this.lessonProcessor) {
      return;
    }
    // Render the LaTeX expression in the temporary div
    // Get the height of the rendered LaTeX expression
    const tempDiv = document.createElement('div');
    tempDiv.style.visibility = 'hidden';
    tempDiv.style.padding = '11.5px';
    tempDiv.style.fontSize = '24px';
    tempDiv.classList.add('break-inside-avoid');
    tempDiv.style.display = 'inline-block';
    document.body.appendChild(tempDiv);
    tempDiv.id = 'tempDiv';
    katex.render(object.text, tempDiv, { throwOnError: false, displayMode: true });
    const height = tempDiv.getBoundingClientRect().height;
    const width = tempDiv.getBoundingClientRect().width;
    const itemData = new ItemData(
      new ObjectId().toHexString(),
      ItemModel.Formula,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      `$$${object.text}$$`,
      false,
      undefined,
      false,
    );
    itemData.initItemState = new ItemState();
    // Initialise in an area outside the viewport to compute height
    itemData.initItemState.top = this.lessonProcessor.getCurrentTop();
    itemData.initItemState.left =
      this.lessonProcessor.getCurrentLeft() + this.FIXED_COLUMN_WIDTH / 2 - (width ?? 0) / 2;
    itemData.frameUid = frameUid;
    if (this.spaceRepo.activeSpace) {
      itemData.sessionId = this.spaceRepo.activeSpace._id;
    }
    const latexEqnFrameItem = await this.createFrameItem(itemData);
    latexEqnFrameItem.position?.set('top', this.lessonProcessor.getCurrentTop().toString());
    latexEqnFrameItem.position?.set(
      'left',
      (
        this.lessonProcessor.getCurrentLeft() +
        this.FIXED_COLUMN_WIDTH / 2 -
        (width ?? 0) / 2
      ).toString(),
    );
    latexEqnFrameItem.position?.set('width', width.toString());
    latexEqnFrameItem.position?.set('height', height.toString());
    this.lessonProcessor.addFrameItem(latexEqnFrameItem);
    this.lessonProcessor.addToCurrentTop(height + this.FIXED_HEIGHT_PADDING_BETWEEN_OBJECTS);
    tempDiv.remove();
  }

  private async _createBoardFolder(name: string): Promise<BoardFolder | undefined> {
    if (!this.spaceRepo.activeSpaceId || !this.spaceRepo.activeSpaceCurrentRoomUid) {
      return;
    }

    const boardFolder = new BoardFolder({
      name,
      roomUid: this.spaceRepo.activeSpaceCurrentRoomUid,
    });
    return boardFolder;
  }

  async getImageDimensions(src: string): Promise<{ height: number; width: number }> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({ height: img.height, width: img.width });
      };
      img.onerror = (err) => {
        resolve({ height: 0, width: 0 });
      };
      img.src = src;
    });
  }

  private _llmFindBoldContentPositions(text: string): {
    modifiedText: string;
    boldPositions: number[][];
  } {
    // Replace all matches of "...**BOLD_TEXT**..."" with "...BOLD_TEXT..."" within a string
    const boldPatternMatcher = /\*\*(.*?)\*\*/g;
    const modifiedText = text.replace(boldPatternMatcher, '$1');
    const boldPositions: number[][] = [];

    let match;
    let offset = 0;
    while ((match = boldPatternMatcher.exec(text)) !== null) {
      const start = match.index - 2 * offset; // Adjust start position based on removed asterisks
      const end = start + match[1].length;
      boldPositions.push([start, end]);
      offset += 2; // Increment offset for each pair of asterisks removed
    }

    return { modifiedText, boldPositions };
  }

  /* Add iframe to space */
  async addIframeToSpace(
    newBoard: boolean,
    isPrivate: boolean,
    iframeType = CollaborativeApps.IFRAME,
  ): Promise<void> {
    const iframeAppConfiguration = AppsConfiguration[iframeType];

    this.sharedDataService.sessionView.switchToSessionView(SessionView.WHITEBOARD);

    const handleInsertIframe = (url: string) => {
      this.insertIframe(url, newBoard, isPrivate, iframeType);
    };

    if (!iframeAppConfiguration?.app.defaultUrl) {
      const width = this.mobileUI ? '90vw' : 'auto';
      const confirmDialog = this.dialog.open(AddIframeComponent, {
        disableClose: false,
        data: { modalConfig: iframeAppConfiguration?.modal },
        panelClass: 'add-iframe-dialog',
        width: width,
        minWidth: width,
        maxWidth: width,
      });
      const data = await firstValueFrom(confirmDialog.afterClosed());

      // If user cancelled return
      if (!data) {
        return;
      } else {
        handleInsertIframe(data.url);
      }
    } else {
      handleInsertIframe(iframeAppConfiguration.app.defaultUrl);
    }
  }

  async addAnimatedPowerpointToSpace(isPrivate: boolean, newBoard: boolean): Promise<void> {
    const odOptions = {
      clientId: environment.onedrive.clientId,
      action: 'query',
      multiSelect: false,
      viewType: 'files',
      accountSwitchEnabled: true,
      advanced: {
        queryParameters: 'select=id,name,size,file,folder,photo,@microsoft.graph.downloadUrl',
        filter: '.pptx,.ppt',
        redirectUri: location.origin,
        scopes: ['User.Read.All', 'Files.ReadWrite'],
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      success: (response: any) =>
        this.onOneDriveAnimationPickerSuccess(response, isPrivate, newBoard),
      error: () => this.onOneDrivePickerError(),
    };
    OneDrive.open(odOptions);
  }

  async addOneDriveFiletoSpace(): Promise<void> {
    const filterExtensions = ACCEPTED_ONEDRIVE_TYPES.join(',');
    const odOptions = {
      clientId: environment.onedrive.clientId,
      action: 'query',
      multiSelect: false,
      viewType: 'files',
      accountSwitchEnabled: true,
      advanced: {
        queryParameters: 'select=id,name,size,file,folder,photo,@microsoft.graph.downloadUrl',
        filter: filterExtensions,
        redirectUri: location.origin,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      success: (response: any) => this.onOneDrivePickerSuccess(response),
      error: () => this.onOneDrivePickerError(),
    };
    OneDrive.open(odOptions);
  }

  addChatGptToSpace(app: GPTApp, config: GptAppConfig, isPrivate: boolean, newBoard = false): void {
    const item = new ItemData(app._id, ItemModel.Chat_GPT, isPrivate);
    item.options = {
      type: CollaborativeApps.CHAT_GPT,
    };
    item.addToNewBoard = newBoard;
    item.sessionId = this.sessionId;
    item.frameUid = this.currentFrameUid;
    item.options = config;
    this.sharedDataService.selectedItemsToInsert.next([item]);
  }

  addRemoteVideo(participantId: string): void {
    const item = new ItemData(participantId, ItemModel.RemoteVideo, false);
    item.sessionId = this.sessionId;
    item.frameUid = this.currentFrameUid;
    this.sharedDataService.selectedItemsToInsert.next([item]);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async onOneDrivePickerSuccess(response: any) {
    const fileToImport = response.value[0];
    if ('size' in fileToImport && '@microsoft.graph.downloadUrl' in fileToImport) {
      if (fileToImport.size / (1024 * 1024) > UPLOAD_LIMITS.DOCUMENT.SIZE) {
        this.uploadService.showUploadFailedNotification(UploadFailedNotificationStatus.SIZE_LIMIT);
        return;
      }
      this.handleFileDownloadImport(
        fileToImport['@microsoft.graph.downloadUrl'],
        {},
        fileToImport['name'],
        fileToImport['file']['mimeType'],
      );
    } else {
      this.onOneDrivePickerError();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async onOneDriveAnimationPickerSuccess(response: any, isPrivate: boolean, newBoard: boolean) {
    try {
      const fileId = response.value[0].id;
      const fileName = response.value[0].name;
      const accessToken = response.accessToken;
      if (!fileId || !fileName || !accessToken) {
        throw new Error('Invalid picker response received');
      }
      const linkResponse = await fetch(
        `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/createLink`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            type: 'embed',
          }),
        },
      );
      const linkData = await linkResponse.json();
      const linkUrl = linkData.link.webUrl;
      if (!linkUrl) {
        throw new Error('Invalid embed link response received');
      }
      this.insertWebViewer(isPrivate, {
        instanceType: {
          webgl: false,
          defaultUrl: linkUrl,
          title: fileName,
          type: CollaborativeApps.ONEDRIVE_PPT,
        },
        addToNewBoard: newBoard,
      });
    } catch {
      this.onOneDrivePickerError();
    }
  }

  onFileDownloadError() {
    const notificationData = new NotificationDataBuilder(INFOS.ONEDRIVE_IMPORT_ERROR)
      .type(NotificationType.INFO)
      .style(ToasterPopupStyle.INFO)
      .topElements([
        new IconMessageToasterElement(
          { icon: 'warning', size: 16 },
          'File could not be downloaded',
        ),
      ])
      .middleElements([
        new IconMessageToasterElement(
          undefined,
          'Sorry, we could not download your file. Please check you are connected to the internet.',
        ),
      ])
      .bottomElements([])
      .dismissable(false)
      .timeOut(5)
      .build();
    this.notificationToasterService.showNotification(notificationData);
  }

  onOneDrivePickerError() {
    const notificationData = new NotificationDataBuilder(INFOS.ONEDRIVE_IMPORT_ERROR)
      .type(NotificationType.INFO)
      .style(ToasterPopupStyle.INFO)
      .topElements([
        new IconMessageToasterElement({ icon: 'warning', size: 16 }, 'File could not be imported'),
      ])
      .middleElements([
        new IconMessageToasterElement(
          undefined,
          'Sorry, we could not import your file. Please check you have the right access permissions to this file.',
        ),
      ])
      .bottomElements([])
      .dismissable(false)
      .timeOut(5)
      .build();
    this.notificationToasterService.showNotification(notificationData);
  }

  async addGoogleDriveFileToSpace(isPrivate: boolean, newBoard = false): Promise<void> {
    try {
      const SCOPE = 'https://www.googleapis.com/auth/drive.readonly';
      const client = google.accounts.oauth2.initTokenClient({
        client_id: environment.drive.clientId,
        scope: SCOPE,
        callback: (response) => {
          if (response.error !== undefined) {
            this.gTokenResponse = null;
            throw response;
          }
          this.gTokenResponse = response;
          gapi.load('picker', () => {
            this.createPicker(this.gTokenResponse.access_token, newBoard, isPrivate);
          });
        },
      });
      if (this.gTokenResponse) {
        const response = await fetch(
          `https://oauth2.googleapis.com/tokeninfo?access_token=${this.gTokenResponse.access_token}`,
        );
        const jsonData = await response.json();
        if (jsonData.scope && jsonData.scope.includes(SCOPE)) {
          this.createPicker(this.gTokenResponse.access_token, newBoard, isPrivate);
        } else {
          this.gTokenResponse = null;
          client.requestAccessToken({ prompt: 'consent' });
        }
      } else {
        this.gTokenResponse = null;
        client.requestAccessToken({ prompt: 'consent' });
      }
    } catch (error) {
      console.log(error);
      this.telemetry.errorEvent('Google Drive Picker import failed', {
        error: error,
      });
    }
  }

  clearGoogleToken() {
    this.gTokenResponse = null;
  }

  createPicker(oauthAccessToken, newBoard, isPrivate: boolean): void {
    const docsView = new google.picker.DocsView(google.picker.ViewId.DOCS)
      .setIncludeFolders(true)
      .setParent('root');
    const picker = new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.NAV_HIDDEN)
      .setOAuthToken(oauthAccessToken)
      .addView(docsView)
      .setTitle('Import from Google Drive')
      .setLocale(this.translate.getDefaultLang())
      .setDeveloperKey(environment.drive.developerKey)
      .setCallback(this.pickerCallback.bind(this, newBoard, isPrivate))
      .build();
    picker.setVisible(true);
  }

  pickerCallback(newBoard: boolean, isPrivate: boolean, data): void {
    let doc: undefined | any;
    if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
      doc = data[google.picker.Response.DOCUMENTS][0];
    } else {
      // User is still picking but callback was triggered for a different action.
      return;
    }
    // If user cancelled, return
    if (!data || !doc) {
      return;
    } else {
      if (!doc.url) {
        return;
      }
      if (Object.values(SUPPORTED_GDRIVE_TYPES).includes(doc.mimeType)) {
        const docType = doc.mimeType as SUPPORTED_GDRIVE_TYPES;
        if (docType === SUPPORTED_GDRIVE_TYPES.GDOCS) {
          this.insertIframe(doc.url, newBoard, isPrivate, CollaborativeApps.GOOGLE_DOCS);
        } else if (docType === SUPPORTED_GDRIVE_TYPES.GSHEETS) {
          this.insertIframe(doc.url, newBoard, isPrivate, CollaborativeApps.GOOGLE_SHEETS);
        } else if (docType === SUPPORTED_GDRIVE_TYPES.GSLIDES) {
          this.insertIframe(doc.url, newBoard, isPrivate, CollaborativeApps.GOOGLE_SLIDES);
        }
      } else if (checkIfValidGoogleDriveDocType(doc.mimeType, doc.name)) {
        this.onGoogleDriveDownloadableFilePicked(doc);
      } else {
        this.insertIframe(doc.embedUrl, newBoard, isPrivate, CollaborativeApps.GOOGLE_DRIVE);
      }
    }
  }

  async onGoogleDriveDownloadableFilePicked(response) {
    if (response.sizeBytes / (1024 * 1024) > UPLOAD_LIMITS.DOCUMENT.SIZE) {
      this.uploadService.showUploadFailedNotification(UploadFailedNotificationStatus.SIZE_LIMIT);
      return;
    }
    this.handleFileDownloadImport(
      `https://www.googleapis.com/drive/v3/files/${response.id}?alt=media`,
      {
        Authorization: `Bearer ${this.gTokenResponse.access_token}`,
      },
      response.name,
      response.mimeType,
    );
  }

  async handleFileDownloadImport(url: string, headers: any, name: string, mime: string) {
    this.downloadService
      .downloadFromURL(url, headers, name, mime)
      .status.pipe(untilDestroyed(this))
      .subscribe({
        next: (value: DownloadStatus) => {
          if (value.downloadStatus === DownloadStatusFlag.COMPLETED) {
            this.notificationToasterService.dismissLoadingNotification(
              INFOS.FILE_DOWNLOADING_INDICATOR,
            );
            this.addExternalFile([value.response!]);
          } else {
            if (
              !this.notificationToasterService.checkIfNotificationActiveByCode(
                INFOS.FILE_DOWNLOADING_INDICATOR,
              )
            ) {
              this.notificationToasterService.showLoadingNotification(
                this.translate.instant('Downloading file...'),
                INFOS.FILE_DOWNLOADING_INDICATOR,
                true,
              );
            }
          }
        },
        error: () => {
          this.notificationToasterService.dismissLoadingNotification(
            INFOS.FILE_DOWNLOADING_INDICATOR,
          );
          this.onFileDownloadError();
        },
      });
  }

  /* Add Mario App to space */
  async addMarioAppToSpace(
    appType: CollaborativeApps,
    isPrivate: boolean,
    addToNewBoard = false,
  ): Promise<void> {
    const resource = AppsConfiguration[appType]?.app?.mario_resource;
    if (appType === CollaborativeApps.MARIO) {
      const iframeAppConfiguration = AppsConfiguration[CollaborativeApps.MARIO];

      this.sharedDataService.sessionView.switchToSessionView(SessionView.WHITEBOARD);

      const width = this.mobileUI ? '90vw' : 'auto';
      const confirmDialog = this.dialog.open(AddIframeComponent, {
        disableClose: false,
        data: { modalConfig: iframeAppConfiguration?.modal },
        panelClass: 'add-iframe-dialog',
        width: width,
        minWidth: width,
        maxWidth: width,
      });
      const url: string = (await firstValueFrom(confirmDialog.afterClosed()))?.url;

      // If user cancelled return
      if (url) {
        this.insertMarioApp({ _type: 'DEV_URL', url }, isPrivate, addToNewBoard);
      } else {
        return;
      }
    } else if (resource) {
      this.insertMarioApp(resource, isPrivate, addToNewBoard);
    }
  }

  /* Add web viewer to space */
  async addWebViewerToSpace(isPrivate: boolean, appType?: string, newBoard = false): Promise<void> {
    this.telemetry.startPerfScenario(KeyScenariosOnSpaces.RENDERING_WEBVIEWER, {
      isPrivate: isPrivate,
      inNewBoard: newBoard,
      type: appType,
    });

    this.sharedDataService.sessionView.switchToSessionView(SessionView.WHITEBOARD);

    await this.insertWebViewer(isPrivate, {
      instanceType: appType,
      addToNewBoard: newBoard,
    });
  }

  private showBoardLockedNotification(isPaste?) {
    const action = isPaste ? 'paste' : 'upload';
    const title = new IconMessageToasterElement(
      { icon: 'lock_outline', size: 16 },
      this.translate.instant(`${action} not allowed`),
    );
    const message = new IconMessageToasterElement(
      undefined,
      this.translate.instant(`You cannot ${action} objects to a Space when it is locked by a host`),
    );
    const notificationData = new NotificationDataBuilder(INFOS.SPACE_LOCKED)
      .type(NotificationType.INFO)
      .style(ToasterPopupStyle.INFO)
      .topElements([title])
      .middleElements([message])
      .bottomElements([])
      .dismissable(false)
      .timeOut(5)
      .build();
    this.notificationToasterService.showNotification(notificationData);
  }
}

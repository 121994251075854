import { Injectable, NgZone } from '@angular/core';
import { Mutex } from 'async-mutex';
import {
  combineLatest,
  distinctUntilChanged,
  firstValueFrom,
  fromEvent,
  map,
  Observable,
  switchMap,
  takeWhile,
} from 'rxjs';
import { cloneDeep, isEmpty } from 'lodash';
import * as Sentry from '@sentry/browser';
import { filterNil } from '@ngneat/elf';
import { KrispNoiseFilter, isKrispNoiseFilterSupported } from '@livekit/krisp-noise-filter';
import { KrispNoiseFilterProcessor } from '@livekit/krisp-noise-filter/dist/NoiseFilterProcessor';
import {
  Room,
  LocalParticipant,
  RoomEvent,
  ConnectionState,
  DisconnectReason,
  Track,
  RemoteTrackPublication,
  VideoQuality,
  Participant,
  RemoteParticipant,
  ConnectionQuality,
  LocalTrackPublication,
  LocalAudioTrack,
  TrackPublication,
  VideoCodec,
} from 'livekit-client';
import { TranslateService } from '@ngx-translate/core';
import {
  ActiveRTCDevices,
  CallContext,
  CallError,
  CallParticipant,
  CallProvider,
  HIDE_TRACK_SYMBOL,
  ParticipantEventAction,
  RTCCloudRecording,
  RTCInterface,
  RTCRoomCreationOpts,
  RoomAuth,
  ScreenShareOptions,
  SubscribeCallQuality,
  TrackEventAction,
  TrackState,
  TrackType,
} from '../common/interfaces/rtc-interface';
import { SpaceRepository } from '../state/space.repository';
import { Session } from '../models/session';
import { DEFAULT_DEVICE_ID } from '../common/utils/devices-handle-util';
import {
  LocalNetworkStatsScore,
  NetworkStatsReportScore,
} from '../utilities/webrtc-stats-reporter/webrtc-stats-reporter-types';
import { UserService } from './user.service';
import { ProviderStateService } from './provider-state.service';
import { SpacesService } from './spaces.service';
import { FlagsService } from './flags.service';
import { NoiseCancellationConfigurationService } from './noise-cancellation-configuration.service';
import { TelemetryService } from './telemetry.service';
import { NotificationToasterService } from './notification-toaster.service';

export enum PendingSubscription {
  SUBSCRIBE,
  UNSUBSCRIBE,
}

const SERVERURL = 'wss://pencil.livekit.cloud';

@Injectable({
  providedIn: 'root',
})
export class LivekitService extends RTCInterface implements RTCCloudRecording {
  readonly provider = CallProvider.LIVEKIT;

  private room!: Room;
  private roomAuth: RoomAuth | null = null;
  private joinLeaveMutex = new Mutex();
  private startOrStopRecordingMutex = new Mutex();
  private currentActiveEgressRequest?: string;
  private krispProcessor?: KrispNoiseFilterProcessor;

  public currentLocalParticipant?: LocalParticipant;
  isScreenShareSimulcastEnabled = true;

  onEndScreenSharing?: () => Promise<void>;

  // Hold any early subscription is made before remote track publication is sent
  // participantIdWithTrackSource = participantId (sid on LiveKit) + Source (Camera, Mic, ....)
  private pendingSubscriptions: { [participantIdWithTrackSource: string]: PendingSubscription } =
    {};

  constructor(
    private userService: UserService,
    private providerState: ProviderStateService,
    private spacesService: SpacesService,
    private ngZone: NgZone,
    private spaceRepo: SpaceRepository,
    public flagsService: FlagsService,
    noiseCancellationConfigurationService: NoiseCancellationConfigurationService,
    telemetry: TelemetryService,
    translateService: TranslateService,
    notificationToasterService: NotificationToasterService,
  ) {
    super(
      flagsService,
      noiseCancellationConfigurationService,
      telemetry,
      translateService,
      notificationToasterService,
    );
    ngZone.runOutsideAngular(() => {
      // creates a new room with options
      this.room = new Room({
        // Manage updating stream quality based on current video width on our end
        // As LiveKit is super optimized & handling if the video element is being PIP, they will stop the stream
        adaptiveStream: false,

        // optimize publishing bandwidth and CPU for simulcasted tracks
        dynacast: true,
        disconnectOnPageLeave: false,
      });

      // Will be remove once having a stable LiveKit on prod
      Object.values(RoomEvent).forEach((state) => {
        if (state === RoomEvent.ActiveSpeakersChanged) {
          return;
        }
        this.room.on(state, (...args: any[]) => {
          console.log('Room Event', state, args);
        });
      });

      this.updateMetadata$().subscribe((shouldRecordParticipantVideo) => {
        this.setMetadata({ shouldRecordParticipantVideo });
      });

      /**
       * Ignored TrackStreamStatedChanged event as our logic doesn't depend on handling this event
       * Ignored TrackPublished event as it just fires when a remote participant enables his cam or mic or starts a screensharing
       * but it doesn't have any information about the tracks itself which will come after with TrackSubscribed event. We can use
       * this event if we have a loading before showing the video (For example)
       *  */
      this.room
        // Local participant events
        .on(RoomEvent.ConnectionStateChanged, this.handleConnectionStateChanged.bind(this))
        .on(RoomEvent.Connected, this.handleCallIsConnected.bind(this))
        .on(RoomEvent.Reconnecting, this.handleReconnectingEvent.bind(this))
        .on(RoomEvent.Reconnected, this.handleReconnectedEvent.bind(this))
        .on(RoomEvent.ConnectionQualityChanged, this.handleConnectionQualityChanged.bind(this))
        .on(RoomEvent.Disconnected, this.handleDisconnectedEvent.bind(this))
        .on(RoomEvent.LocalTrackPublished, this.handleLocalTrackEvent.bind(this))
        .on(RoomEvent.LocalTrackUnpublished, this.handleLocalTrackUnpubEvent.bind(this))

        // Remote participants events
        .on(RoomEvent.ParticipantDisconnected, this.handleParticipantLeaveEvent.bind(this))
        .on(RoomEvent.ParticipantConnected, this.handleParticipantJoinEvent.bind(this))

        // Track events
        // 1st stage: Publication events
        .on(RoomEvent.TrackPublished, this.handleTrackPubEvent.bind(this))
        .on(RoomEvent.TrackUnpublished, this.handleTrackUnpubEvent.bind(this))
        // 2nd stage: Subscription events
        .on(RoomEvent.TrackUnsubscribed, this.handleTrackUnsubEvent.bind(this))
        .on(RoomEvent.TrackSubscribed, this.handleTrackSubscribedEvent.bind(this))
        // 3rd stage: Muted/Unmuted events
        .on(RoomEvent.TrackMuted, this.handleTrackMutedEvent.bind(this))
        .on(RoomEvent.TrackUnmuted, this.handleTrackUnMutedEvent.bind(this))

        // Recording handling events
        .on(RoomEvent.ParticipantMetadataChanged, this.handleParticipantMetadataChange.bind(this))
        .on(RoomEvent.RecordingStatusChanged, this.handleRecordingStatusChange.bind(this));
    });
  }

  // Added Reconnecting & SignalReconnecting state to the check as it still means call is connected
  // Also video call component is depending on its value for showing/hiding the entire component
  isConnected(): boolean {
    return [
      ConnectionState.Connected,
      ConnectionState.Reconnecting,
      ConnectionState.SignalReconnecting,
    ].includes(this.room.state);
  }

  isConnecting(): boolean {
    return this.room.state === ConnectionState.Connecting;
  }

  isDisconnecting(): boolean {
    return this.providerState.callDisconnecting;
  }

  async loadAuth(
    spaceId: string,
    breakoutRoomId: string | undefined,
    context: CallContext,
    isExtendExp = false,
    opts: RTCRoomCreationOpts = {
      skipRoomCreation: true,
      skipGenerateToken: false,
    },
  ): Promise<void> {
    // if we already have auth data for this room, do nothing
    if (!isExtendExp && this.isAuthValid(this.roomAuth, spaceId, breakoutRoomId)) {
      return;
    }

    // Generate a unique request ID for the current request
    const requestId = ++this.loadAuthCurrentRequestId;

    if (!opts?.skipGenerateToken) {
      // reset roomAuth to make sure that it will not be used under any race condition
      // before the new one is created
      this.roomAuth = null;
    }

    const userId: string | undefined = this.userService.user.getValue()?.user._id;
    if (userId) {
      const res = await firstValueFrom(
        this.spacesService.getVideoCallAuth(
          spaceId,
          breakoutRoomId,
          context,
          CallProvider.LIVEKIT,
          opts,
        ),
      );

      if (!opts?.skipGenerateToken && requestId === this.loadAuthCurrentRequestId) {
        // set the room auth only if the request not being cancelled by another request

        this.roomAuth = (res?.body as any)?.data || null;
      }
    }
    if (!this.roomAuth || !this.roomAuth.token) {
      throw new Error('Could not get room data for LiveKit RTC');
    } else {
      this.providerState.callReady = true;
    }
  }

  protected constructRoomIdInRoomAuth(spaceId: string, breakoutRoomId?: string): string {
    if (!breakoutRoomId || Session.isMainRoom(breakoutRoomId, spaceId, true)) {
      return `${spaceId}-MainRoom`;
    } else {
      return `${spaceId}-${breakoutRoomId}`;
    }
  }

  getAuthData(): RoomAuth | null {
    return this.roomAuth;
  }

  setAuthData(data: RoomAuth | null): void {
    this.roomAuth = data;
    this.providerState.callReady = this.roomAuth ? true : false;
  }

  clearRoomAuthToken(): void {
    this.roomAuth = null;
  }

  doesAuthTokenExist(): boolean {
    return !!this.roomAuth && !!this.roomAuth.token;
  }

  async join(): Promise<void> {
    await this.ngZone.runOutsideAngular(async () => {
      await this.joinLeaveMutex.runExclusive(async () => {
        try {
          if (this.isConnected() || !this.roomAuth || !this.roomAuth.token) {
            return;
          }
          await this.room.connect(SERVERURL, this.roomAuth.token, {
            autoSubscribe: false,
          });
        } catch (joinCallError) {
          const joinCallReqStatusCode = ({ joinCallError } as any)?.joinCallError?.status;

          const isTokenExpired = joinCallReqStatusCode === 401;
          const isRoomNotExist = joinCallReqStatusCode === 404;

          if (!isTokenExpired && !isRoomNotExist) {
            Sentry.captureException('[LiveKit] Connect to call failed', {
              extra: {
                error: joinCallError,
              },
            });
            this.handleDisconnectedEvent(DisconnectReason.JOIN_FAILURE);
            return;
          }

          if (!this.spaceRepo.activeSpaceId) {
            Sentry.captureException('[LiveKit] No active space ID for room/auth creation');
            return;
          }

          await this.loadAuth(
            this.spaceRepo.activeSpaceId,
            this.spaceRepo.activeSpaceCurrentRoomUid,
            CallContext.SESSION,
            true,
            {
              skipRoomCreation: false,
              skipGenerateToken: isTokenExpired ? false : true,
            },
          );

          this.joinLeaveMutex.cancel();
          this.join();
        }
      });
    });
  }

  async leave(): Promise<void> {
    await this.joinLeaveMutex.runExclusive(async () => {
      super.leave();
      if (!this.isConnected()) {
        return;
      }
      this.providerState.callDisconnecting = true;
      try {
        await this.room.disconnect();
      } catch (error) {
        /* empty catch block */
      } finally {
        this.providerState.callDisconnecting = false;
      }
    });
  }

  async publishAndStartBroadcastingVideoStream(mediaStreamTrack: MediaStreamTrack): Promise<void> {
    if (await this.reuseExistingLocalTrackPub(Track.Source.Camera, mediaStreamTrack)) {
      return;
    }

    await this.room.localParticipant.publishTrack(mediaStreamTrack, {
      simulcast: true,
      source: Track.Source.Camera,
      videoCodec: 'vp9',
    });
  }

  private async publishAndEnableBroadcastingAudioStream(
    mediaStreamTrack: MediaStreamTrack,
  ): Promise<void> {
    if (await this.reuseExistingLocalTrackPub(Track.Source.Microphone, mediaStreamTrack)) {
      return;
    }

    await this.room.localParticipant.publishTrack(mediaStreamTrack, {
      source: Track.Source.Microphone,
    });
  }

  async enableNoiseCancellation(): Promise<void> {
    const localAudioTrackPub = this.room.localParticipant.getTrackPublication(
      Track.Source.Microphone,
    );
    const localAudioTrack = localAudioTrackPub?.track;
    if (!this.isNoiseCancellationSupported() || !localAudioTrack) {
      return;
    }
    if (localAudioTrack.getProcessor()) {
      console.log('noise cancellation processor is already set');
      return;
    }
    try {
      this.krispProcessor = KrispNoiseFilter();

      await localAudioTrack.setProcessor(this.krispProcessor as any);
    } catch (error) {
      console.log('Error', error);
      Sentry.captureException(new Error('Could not enable noise cancellation'), {
        extra: error,
      });
      return;
    }

    if (!this.room.localParticipant.isMicrophoneEnabled) {
      return;
    }

    this.rebroadcastLocalTrack(localAudioTrackPub);
  }

  async disableNoiseCancellation(): Promise<void> {
    const localAudioTrackPub = this.room.localParticipant.getTrackPublication(
      Track.Source.Microphone,
    );
    const localAudioTrack = localAudioTrackPub?.track;
    if (!this.isNoiseCancellationSupported() || !localAudioTrackPub || !localAudioTrack) {
      return;
    }

    await localAudioTrack.stopProcessor();

    if (!this.room.localParticipant.isMicrophoneEnabled) {
      return;
    }

    this.rebroadcastLocalTrack(localAudioTrackPub);
  }

  async startBroadcastingVideoStream(): Promise<void> {
    const localTrackPublication = this.room.localParticipant.getTrackPublication(
      Track.Source.Camera,
    );

    if (!localTrackPublication) {
      return;
    }

    await localTrackPublication.unmute();
  }

  async startBroadcastingAudioStream(mediaStreamTrack: MediaStreamTrack): Promise<void> {
    const localTrackPublication = this.room.localParticipant.getTrackPublication(
      Track.Source.Microphone,
    );

    if (!localTrackPublication || localTrackPublication.track?.id !== mediaStreamTrack.id) {
      this.publishAndEnableBroadcastingAudioStream(mediaStreamTrack);
      return;
    }

    await localTrackPublication.unmute();
  }

  async stopBroadcastingVideoStream(): Promise<void> {
    const localTrackPublication = this.room.localParticipant.getTrackPublication(
      Track.Source.Camera,
    );

    if (!localTrackPublication) {
      return;
    }

    await localTrackPublication.mute();
  }

  async stopBroadcastingAudioStream(): Promise<void> {
    const localTrackPublication = this.room.localParticipant.getTrackPublication(
      Track.Source.Microphone,
    );

    if (!localTrackPublication) {
      return;
    }

    await localTrackPublication.mute();
  }

  async setStreamQuality(
    participantId: string,
    type: TrackType,
    quality: SubscribeCallQuality,
  ): Promise<void> {
    const remoteTrackPublication = this.room
      .getParticipantByIdentity(participantId)
      ?.getTrackPublication(
        type === TrackType.VIDEO ? Track.Source.Camera : Track.Source.ScreenShare,
      ) as RemoteTrackPublication;

    if (!remoteTrackPublication) {
      return;
    }

    remoteTrackPublication.setVideoQuality(quality as unknown as VideoQuality);
  }

  isCameraEnabled(): boolean {
    return this.room.localParticipant.isCameraEnabled;
  }

  isMicEnabled(): boolean {
    return this.room.localParticipant.isMicrophoneEnabled;
  }

  public subscribe(participantId: string, trackType: TrackType): void {
    this._subscribe(participantId, trackType, true);
  }

  public unsubscribe(participantId: string, trackType: TrackType): void {
    this._subscribe(participantId, trackType, false);
  }

  public stage(participantId: string, trackType: TrackType): void {
    this._subscribe(participantId, trackType, false);
  }

  async startScreenShare(
    options?: ScreenShareOptions,
    onEnd?: () => Promise<void>,
    onError?: (error: any) => Promise<void>,
  ): Promise<any> {
    if (!this.room) {
      return;
    }
    if (onEnd) {
      this.onEndScreenSharing = onEnd;
    }
    const preferCurrentTab = options?.preferCurrentTab ?? false;
    const track = await this.room.localParticipant
      // Used featue flag variables directly as we're sure at this moment, user should have the correct values
      .setScreenShareEnabled(
        true,
        {
          audio: !preferCurrentTab,
          preferCurrentTab,
          // note: There is a bug in the element capture API that does not let you "unrestrict" the screenshare
          // once the screenshare is not the current tab so we will disable moving switching from current tab
          surfaceSwitching: options?.preferCurrentTab ? 'exclude' : 'include',
          resolution: {
            width: this.screenShareCaptureWidth,
            height: this.screenShareCaptureHeight,
          },
        },
        {
          videoCodec: this.screenShareEncodingCodec as VideoCodec,
          name: options?.preferCurrentTab ? HIDE_TRACK_SYMBOL : undefined,
          screenShareEncoding: {
            maxBitrate: this.screenShareEncodingMaxBitrate,
            maxFramerate: this.screenShareEncodingMaxFPS,
            priority: 'medium',
          },
        },
      )
      .catch((error) => {
        if (onError) {
          onError(error);
        }
      });

    if (track?.track?.mediaStreamTrack && options?.restrictToWhiteboard) {
      await this.setupWhiteboardRestrictionListener(track.track.mediaStreamTrack);
    }

    return track;
  }

  attachAudioElement(
    participant: CallParticipant,
    trackType: TrackType,
    element: HTMLAudioElement,
  ): void {
    const liveKitParticipant = this.room.getParticipantByIdentity(participant.participantId);
    if (trackType === TrackType.VIDEO) {
      liveKitParticipant?.getTrackPublication(Track.Source.Microphone)?.audioTrack?.attach(element);
    } else if (trackType === TrackType.SCREEN) {
      liveKitParticipant
        ?.getTrackPublication(Track.Source.ScreenShareAudio)
        ?.audioTrack?.attach(element);
    }
  }

  async stopScreenShare(): Promise<any> {
    if (!this.room) {
      return;
    }
    return this.room.localParticipant.setScreenShareEnabled(false);
  }

  async startRecording(): Promise<void> {
    this.startOrStopRecordingMutex.runExclusive(async () => {
      if (!this.spaceRepo.activeSpaceId || !this.spaceRepo.activeSpaceCurrentRoomUid) {
        return;
      }

      try {
        const startEgressRequest = await firstValueFrom(
          this.spacesService.changeCloudRecordingState(
            CallProvider.LIVEKIT,
            this.spaceRepo.activeSpaceId,
            this.room.name,
            true,
          ),
        );

        if (startEgressRequest?.data?.success) {
          this.currentActiveEgressRequest = startEgressRequest?.data?.recordingId;
        }

        // This is to cover a quick start recording request was done while stop recording request is in progress
        // It will result in LiveKit won't emit RecordingStatusChanged again given recording starts again. So, this condition to update the initiator side
        if (this.room.isRecording) {
          this.setMetadata({ recordingIitiator: true });
          this.providerState.recordingEvent = {
            _type: 'STARTED',
            initiatorParticipantId: this.currentActiveEgressRequest
              ? this.room.localParticipant.identity
              : undefined,
          };
        }
      } catch (startRecordingError) {
        // @TODO [Elwakeel] add UX for failing start recording requests, also the same for stop recording requests
        Sentry.captureException(startRecordingError);
      }
    });
  }

  async stopRecording(): Promise<void> {
    this.startOrStopRecordingMutex.runExclusive(async () => {
      if (
        !this.spaceRepo.activeSpaceId ||
        !this.spaceRepo.activeSpaceCurrentRoomUid ||
        !this.room.isRecording
      ) {
        return;
      }

      try {
        await firstValueFrom(
          this.spacesService.changeCloudRecordingState(
            CallProvider.LIVEKIT,
            this.spaceRepo.activeSpaceId,
            this.room.name,
            false,
            this.currentActiveEgressRequest,
          ),
        );
        // Make sure recordingIitiator is empty to cover the case of multiple CR toggles within the same call session
        this.setMetadata({ recordingIitiator: undefined });
        this.currentActiveEgressRequest = undefined;
      } catch (stopRecordingError) {
        Sentry.captureException(stopRecordingError);
      }
    });
  }

  setLocalVideoCallStatsMetadata(localNetworkStatsScore: LocalNetworkStatsScore): void {
    this.setMetadata(localNetworkStatsScore);
  }

  toCallParticipant(participant: Participant): CallParticipant {
    return {
      participantId: participant.identity,
      userId: participant.identity.split('$')[0],
      video: this.getTrackState(participant.getTrackPublication(Track.Source.Camera)),
      audio: this.getTrackState(participant.getTrackPublication(Track.Source.Microphone)),
      screen: this.getTrackState(participant.getTrackPublication(Track.Source.ScreenShare)),
      screenAudio: this.getTrackState(
        participant.getTrackPublication(Track.Source.ScreenShareAudio),
      ),
      videoTrack: participant.getTrackPublication(Track.Source.Camera)?.videoTrack
        ?.mediaStreamTrack,
      audioTrack: participant.getTrackPublication(Track.Source.Microphone)?.audioTrack
        ?.mediaStreamTrack,
      screenVideoTrack: participant.getTrackPublication(Track.Source.ScreenShare)?.videoTrack
        ?.mediaStreamTrack,
      screenAudioTrack: participant.getTrackPublication(Track.Source.ScreenShareAudio)?.audioTrack
        ?.mediaStreamTrack,
      local: participant.isLocal,
      joinedAt: participant.joinedAt,
      networkStatsScore: this.parseMetadata(participant.metadata)?.networkStatsScore,
      name: participant.name,
    };
  }

  private getTrackState(track: TrackPublication | undefined): TrackState {
    if (!track || track.isMuted) {
      return 'off';
    } else if (track.trackName === HIDE_TRACK_SYMBOL) {
      return 'hidden';
    } else {
      return 'playable';
    }
  }

  async setDevices(devices: ActiveRTCDevices): Promise<void> {
    const devicePromises: Promise<boolean>[] = [];
    if (devices.micId) {
      devicePromises.push(this.room.switchActiveDevice('audioinput', devices.micId));
    }
    if (devices.cameraId) {
      devicePromises.push(this.room.switchActiveDevice('videoinput', devices.cameraId));
    }
    if (devices.speakerId) {
      devicePromises.push(this.room.switchActiveDevice('audiooutput', devices.speakerId));
    }
    await Promise.all(devicePromises);
  }

  // No need for cam/mic as we are using our local tracks manager for broadcasting tracks
  getCurrentInputDevices(): Promise<ActiveRTCDevices> {
    const activeRTCDevices: ActiveRTCDevices = {
      speakerId: '',
    };

    activeRTCDevices.speakerId = this.room.getActiveDevice('audiooutput') || '';

    return Promise.resolve(activeRTCDevices);
  }

  doesProviderSupportNoiseCancellation(): boolean {
    return isKrispNoiseFilterSupported();
  }

  // Note: this does not include the local participant
  getParticipants(): { [key: string]: CallParticipant } {
    const livekitParticipants = this.room.remoteParticipants;
    const callParticipants: { [key: string]: CallParticipant } = {};
    livekitParticipants.forEach((value: RemoteParticipant, key: string) => {
      callParticipants[key] = this.toCallParticipant(value);
    });
    return callParticipants;
  }

  getNumberOfParticipants(): number {
    return Object.values(this.getParticipants()).length + 1;
  }

  // Events handlers

  // === Local participant events handlers ===

  // Handle only connecting event as other events are handled as explicit RoomEvent
  private handleConnectionStateChanged(connectionState: ConnectionState) {
    if (connectionState === ConnectionState.Connecting) {
      this.providerState.callConnecting = true;
    }
  }

  private handleCallIsConnected() {
    // setting the speaker id before joining the call not working if the speaker not the default
    this.forceSettingSpeakerDevice();
    this.providerState.callConnecting = false;
    this.providerState.callConnected = true;
    this.currentLocalParticipant = cloneDeep(this.room.localParticipant);
    this.broadcastLocalParticipant(true);
    this.broadcastLocalParticipantId(this.room.localParticipant);
    this.broadcastExistingParticipantEvents();
    this.handleRecordingStatusChange(this.room.isRecording);
  }

  private handleReconnectingEvent() {
    this.providerState.callReconnecting = true;
  }

  private handleReconnectedEvent() {
    this.providerState.callReconnecting = false;
  }

  private handleConnectionQualityChanged(
    connectionQuality: ConnectionQuality,
    participant: Participant,
  ) {
    if (
      !participant.isLocal &&
      this.providerState.callReconnectingValue &&
      ![ConnectionQuality.Excellent, ConnectionQuality.Good].includes(connectionQuality)
    ) {
      return;
    }
    // This is as a backup for reconnecting notification as we figured some cases that Reconnected event didn't be fired correctly
    this.providerState.callReconnecting = false;
  }

  private handleDisconnectedEvent(reason?: DisconnectReason | undefined) {
    // Only return if the disconnection happened while connecting to a call & there is no reason
    // This case will be covered in the catch block at join()
    if (!reason && this.providerState.callConnecting) {
      return;
    }

    this.providerState.callConnecting = false;
    this.providerState.callDisconnecting = false;
    this.providerState.callReconnecting = false;
    this.providerState.participantEvents = null;
    this.providerState.trackEvents = null;
    this.providerState.callConnected = false;
    this.broadcastLocalParticipant(false);
    this.currentLocalParticipant = undefined;
    this.pendingSubscriptions = {};
    this.currentActiveEgressRequest = undefined;
    this.dismissVideoCallNotifications();

    switch (reason) {
      // We are handling Disconnected event using its explicit event (Participant left)
      case DisconnectReason.CLIENT_INITIATED:
        break;
      case DisconnectReason.SERVER_SHUTDOWN:
        this.providerState.callErrors = CallError.SERVICEDOWN;
        break;
      case DisconnectReason.JOIN_FAILURE:
        this.providerState.callErrors = CallError.TIME_OUT;
        break;
      default:
        this.providerState.callErrors = CallError.DISCONNECTED;
    }
  }

  private async handleLocalTrackEvent(
    pub: LocalTrackPublication,
    participant: Participant,
  ): Promise<void> {
    // Reflect the correct Noise Cancellation setting once local track is published as per LiveKit documentation
    // Link: https://docs.livekit.io/guides/enhanced-noise-cancellation/
    if (pub.source === Track.Source.Microphone && pub.track instanceof LocalAudioTrack) {
      if (this.isNoiseCancellationEnabled) {
        await this.enableNoiseCancellation();
      } else {
        await this.disableNoiseCancellation();
      }
    }

    // Don't play local tracks if publication is muted
    if (pub.isMuted) {
      return;
    }

    this.publishParticipantEvent(ParticipantEventAction.UPDATED, participant);
    this.publishTrackEvent(TrackEventAction.START, participant, pub);
  }

  private handleLocalTrackUnpubEvent(pub: TrackPublication, participant: Participant): void {
    this.publishParticipantEvent(ParticipantEventAction.UPDATED, participant);
    this.publishTrackEvent(TrackEventAction.STOP, participant, pub);
  }

  // === Remote participants events handlers ===

  private handleParticipantJoinEvent(participant: Participant): void {
    this.handleParticipantMetadataChange(participant.metadata, participant);
    this.publishParticipantEvent(ParticipantEventAction.JOINED, participant);
  }

  private handleParticipantLeaveEvent(participant: Participant): void {
    this.publishParticipantEvent(ParticipantEventAction.LEFT, participant);
  }

  // === Track events handlers ===

  private handleTrackPubEvent(pub: RemoteTrackPublication, participant: RemoteParticipant): void {
    // Broadcast participant updated event on each new publication to make participants manager list up-to-date
    this.publishParticipantEvent(ParticipantEventAction.UPDATED, participant);
    if (isEmpty(this.pendingSubscriptions)) {
      return;
    }

    const pendingSubscriptionId = participant.identity + pub.source;

    if (
      pub.isSubscribed ||
      this.pendingSubscriptions[pendingSubscriptionId] === undefined ||
      this.pendingSubscriptions[pendingSubscriptionId] !== PendingSubscription.SUBSCRIBE
    ) {
      return;
    }

    pub.setSubscribed(true);
  }

  private handleTrackUnpubEvent(pub: TrackPublication, participant: Participant): void {
    this.publishTrackEvent(TrackEventAction.STOP, participant, pub);
  }

  private handleTrackUnsubEvent(
    event: Track,
    pub: TrackPublication,
    participant: Participant,
  ): void {
    this.publishTrackEvent(TrackEventAction.STOP, participant, pub);
  }

  private handleTrackSubscribedEvent(
    event: Track,
    pub: TrackPublication,
    participant: Participant,
  ): void {
    this.broadcastUnMutedSubscribedEvents(pub, participant);
  }

  private handleTrackMutedEvent(pub: TrackPublication, participant: Participant): void {
    this.publishTrackEvent(TrackEventAction.STOP, participant, pub);
  }

  private handleTrackUnMutedEvent(pub: TrackPublication, participant: Participant): void {
    this.publishTrackEvent(TrackEventAction.START, participant, pub);
  }

  // We are just using Participant metadata for processing cloud recording start
  // We needed to handle it using this way as LiveKit doesn't support an explicit way to broadcast recording initiator
  private handleParticipantMetadataChange(metadata: string | undefined, participant: Participant) {
    try {
      const newParticipantMetadata = this.parseMetadata(participant.metadata);

      // Emit a metadata change for a certain participant
      this.providerState.participantMetadataChange = this.toCallParticipant(participant);

      // Check if metadata update is related to cloud recording
      // Local client will be updated in RecordingStatusChanged callback
      if (
        this.providerState.recordingEvent?._type !== 'STARTED' &&
        newParticipantMetadata.recordingIitiator === true &&
        !participant.isLocal
      ) {
        this.providerState.recordingEvent = {
          _type: 'STARTED',
          initiatorParticipantId: participant.identity,
        };
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  private handleRecordingStatusChange(recording: boolean) {
    // Update Room metadata to let other users know there is an active recording
    // Decided to do it on the client side as receving a recording status change with true means recording actually starts
    if (recording && this.currentActiveEgressRequest) {
      this.setMetadata({ recordingIitiator: true });
    }

    if (recording) {
      this.providerState.recordingEvent = {
        _type: 'STARTED',
        initiatorParticipantId: this.currentActiveEgressRequest
          ? this.room.localParticipant.identity
          : undefined,
      };
      return;
    }

    this.currentActiveEgressRequest = undefined;
    this.providerState.recordingEvent = {
      _type: 'STOPPED',
    };
  }

  // Helper functions

  private async reuseExistingLocalTrackPub(
    source: Track.Source,
    mediaStreamTrack: MediaStreamTrack,
  ): Promise<boolean> {
    const localTrackPub = this.room.localParticipant.getTrackPublication(source);
    const localTrack =
      source === Track.Source.Camera ? localTrackPub?.videoTrack : localTrackPub?.audioTrack;

    if (!localTrack || !localTrackPub) {
      return false;
    }

    await localTrack.replaceTrack(mediaStreamTrack, {
      userProvidedTrack: true,
    });
    localTrack.unmute();

    // Simulate stop & start on local side as local track publication isn't working like the remote one
    this.rebroadcastLocalTrack(localTrackPub);

    return true;
  }

  // It's mainly used to reflect the local track if it's changed/processed
  private rebroadcastLocalTrack(localTrackPublication: LocalTrackPublication) {
    this.publishTrackEvent(
      TrackEventAction.STOP,
      this.room.localParticipant,
      localTrackPublication,
    );
    this.publishTrackEvent(
      TrackEventAction.START,
      this.room.localParticipant,
      localTrackPublication,
    );
  }

  private broadcastUnMutedSubscribedEvents(pub: TrackPublication, participant: Participant) {
    /**
     * To cover all already active tracks after joining the call
     * Also it's used to start any track event
     * */
    if (!pub.isMuted) {
      this.publishTrackEvent(TrackEventAction.START, participant, pub);
    }
  }

  private broadcastLocalParticipantId(participant: Participant) {
    this.providerState.localParticipantId = participant.identity;
  }

  private publishParticipantEvent(action: ParticipantEventAction, participant: Participant) {
    const participantEvent = {
      action: action,
      participant: this.toCallParticipant(participant),
    };
    this.providerState.participantEvents = participantEvent;
  }

  private publishTrackEvent(
    action: TrackEventAction,
    liveKitParticipant: Participant,
    publication: TrackPublication,
  ): void {
    if (this.getTrackType(publication.source) === TrackType.SCREEN) {
      this.publishParticipantEvent(ParticipantEventAction.UPDATED, liveKitParticipant);

      // Handle reflecting the correct UI after stopping sharing from the bottom toolbar
      // Only for local participant if he owns this track stopped event for screen sharing
      if (
        liveKitParticipant.isLocal &&
        action === TrackEventAction.STOP &&
        this.onEndScreenSharing
      ) {
        this.onEndScreenSharing();
      }
    }
    const trackEvent = {
      action: action,
      participant: this.toCallParticipant(liveKitParticipant),
      type: this.getTrackType(publication.source),
    };
    this.providerState.trackEvents = trackEvent;
    if (liveKitParticipant.isLocal) {
      this.publishParticipantEvent(ParticipantEventAction.UPDATED, liveKitParticipant);
    }
  }

  private getTrackType(source: Track.Source): TrackType {
    switch (source) {
      case Track.Source.Camera:
        return TrackType.VIDEO;
      case Track.Source.Microphone:
        return TrackType.AUDIO;
      case Track.Source.ScreenShare:
        return TrackType.SCREEN;
      case Track.Source.ScreenShareAudio:
        return TrackType.SCREEN_AUDIO;
      default:
        return TrackType.VIDEO;
    }
  }

  private async forceSettingSpeakerDevice() {
    const speakerId = this.getSelectedSpeakerDeviceId() || DEFAULT_DEVICE_ID;
    try {
      await this.room.switchActiveDevice('audiooutput', speakerId);
    } catch (error) {
      console.log('failed to switch audio output when joining the call', error);
    }
  }

  private broadcastExistingParticipantEvents(): void {
    const participants = this.getParticipants();
    Object.entries(participants).forEach(([_, participant]) => {
      this.providerState.participantEvents = {
        action: ParticipantEventAction.JOINED,
        participant,
      };
      // To cover the case of a CR is already started before local user's join as LiveKit isn't broadcasting any event happened before your join
      const liveKitParticipant = this.room.getParticipantByIdentity(participant.participantId);
      if (liveKitParticipant) {
        this.handleParticipantMetadataChange(liveKitParticipant.metadata, liveKitParticipant);
      }
    });
  }

  private broadcastLocalParticipant(isJoined: boolean) {
    this.providerState.participantEvents = {
      action: isJoined ? ParticipantEventAction.JOINED : ParticipantEventAction.LEFT,
      participant: this.toCallParticipant(
        isJoined
          ? this.room.localParticipant
          : this.currentLocalParticipant ?? this.room.localParticipant,
      ),
    };
  }

  private _subscribe(id: string, trackType: TrackType, enabled: boolean): void {
    let source: Track.Source;
    switch (trackType) {
      case TrackType.AUDIO:
        source = Track.Source.Microphone;
        break;
      case TrackType.VIDEO:
        source = Track.Source.Camera;
        break;
      case TrackType.SCREEN:
        source = Track.Source.ScreenShare;
        break;
      case TrackType.SCREEN_AUDIO:
        source = Track.Source.ScreenShareAudio;
        break;
      default:
        throw new Error('Track type not supported');
    }
    const remoteTrackPublication = this.room
      .getParticipantByIdentity(id)
      ?.getTrackPublication(source) as RemoteTrackPublication;
    if (remoteTrackPublication) {
      remoteTrackPublication?.setSubscribed(enabled);
    }

    // Sometimes remote publications take some time to be sent to remote participants
    // In such case we will maintain a map for all requested subscriptions that can't find available remote publication
    // Then do the subscription on receving the remote track publication
    if (enabled) {
      this.pendingSubscriptions[id + source] = PendingSubscription.SUBSCRIBE;
    } else {
      delete this.pendingSubscriptions[id + source];
    }
  }

  // ---- Metadata ----

  private setMetadata(nextMetadata: LivekitParticipantMetadata) {
    const currentMetadataString = this.room.localParticipant.metadata;
    if (!currentMetadataString) {
      this.room.localParticipant.setMetadata(JSON.stringify(nextMetadata));
    } else {
      const currentMetadata: LivekitParticipantMetadata = JSON.parse(currentMetadataString);
      const updatedMetadata = JSON.stringify({ ...currentMetadata, ...nextMetadata });
      this.room.localParticipant.setMetadata(updatedMetadata);
    }
  }

  private parseMetadata(metadata: string | undefined): LivekitParticipantMetadata {
    return metadata ? JSON.parse(metadata) : {};
  }

  private updateMetadata$(): Observable<boolean> {
    return fromEvent(this.room, RoomEvent.Connected).pipe(
      switchMap(() => this.shouldRecordParticipantVideo$()),
    );
  }

  private shouldRecordParticipantVideo$(): Observable<boolean> {
    return combineLatest([
      this.spaceRepo.activeSpace$.pipe(filterNil()),
      this.spaceRepo.isCurrentUserHost$,
    ]).pipe(
      map(([space, isUserHost]) => {
        if (!space.institution?.settings?.crEnablePrivacyAwareCloudRecording) {
          return true;
        } else {
          return isUserHost;
        }
      }),
      distinctUntilChanged(),
      takeWhile(() => this.isConnected()),
    );
  }

  // Not supported methods

  // Deprecated: It was used before with the old service for managing tracks
  isSubscribed!: (participantId: string, trackType: TrackType) => string | boolean;

  async preJoin(): Promise<void> {
    return;
  }
  startTranscription() {
    console.warn('transcription is not supported with livekit');
  }
  stopTranscription() {
    console.warn('transcription is not supported with livekit');
  }
  lowerOutgoingVideoQuality(): void {
    console.warn('[TO BE IMPLEMENTED] :lowerIncomingVideoQuality');
  }
  resetOutgoingVideoQuality(): void {
    console.warn('[TO BE IMPLEMENTED] :resetIncomingVideoQuality');
  }
}

type LivekitParticipantMetadata = Partial<{
  recordingIitiator: boolean;
  shouldRecordParticipantVideo: boolean;
  networkStatsScore: NetworkStatsReportScore;
}>;

import * as Comlink from 'comlink';

export enum TimerType {
  interval = 'interval',
  timeout = 'timeout',
}

export interface ITimerMessage {
  id: NodeJS.Timer;
  source: TimerType;
}

export class TimerClass {
  setTimeout(timeoutDurationInMilliseconds: number): NodeJS.Timer {
    const timeoutId = setTimeout(() => {
      postMessage({ source: TimerType.timeout, id: timeoutId } as ITimerMessage);
    }, timeoutDurationInMilliseconds);
    return timeoutId;
  }

  clearTimeout(timeoutId: NodeJS.Timer): void {
    clearTimeout(timeoutId);
  }

  setInterval(intervalDurationInMilliseconds: number): NodeJS.Timer {
    const timeoutId = setInterval(() => {
      postMessage({ source: TimerType.interval, id: timeoutId } as ITimerMessage);
    }, intervalDurationInMilliseconds);
    return timeoutId;
  }

  clearInterval(intervalId: NodeJS.Timer): void {
    clearInterval(intervalId);
  }
}

Comlink.expose(TimerClass);

<form [formGroup]="form" style="margin: 24px">
  <mat-form-field class="w-100">
    <mat-label>{{ 'Video Title' | translate }}</mat-label>
    <input matInput formControlName="title" />
  </mat-form-field>
  <mat-form-field class="w-100">
    <mat-label>{{ 'Description' | translate }}</mat-label>
    <textarea matInput formControlName="description"></textarea>
  </mat-form-field>
  <span class="txt"> {{ 'Video Privacy' | translate }} </span>
  <mat-radio-group class="radio-group" formControlName="privacyStatus">
    <mat-radio-button
      class="radio-button"
      [value]="'public'"
      (change)="changePrivacyStatus($event)"
    >
      <span class="txt" [class.selected]="privacyStatus === 'public'">
        {{ 'Public' | translate }}
      </span></mat-radio-button
    >
    <mat-radio-button
      class="radio-button"
      [value]="'private'"
      (change)="changePrivacyStatus($event)"
    >
      <span class="txt" [class.selected]="privacyStatus === 'private'">
        {{ 'Private' | translate }}
      </span></mat-radio-button
    >
    <mat-radio-button
      class="radio-button"
      [value]="'unlisted'"
      (change)="changePrivacyStatus($event)"
    >
      <span class="txt" [class.selected]="privacyStatus === 'unlisted'">
        {{ 'Unlisted' | translate }}
      </span></mat-radio-button
    >
  </mat-radio-group>
</form>

<div class="bottom-buttons">
  <button class="save" (click)="dismiss()">
    <mat-icon>close</mat-icon>
    <span>{{ 'Cancel' | translate }}</span>
  </button>

  <button
    [ngClass]="form.valid && signedIn ? 'upload' : 'save'"
    (click)="confirm()"
    [disabled]="!form.valid || !signedIn"
    *ngIf="signedIn"
  >
    <mat-icon>check</mat-icon>
    <span>{{ 'Upload' | translate }}</span>
  </button>

  <button *ngIf="!signedIn" [disabled]="signingIn" class="upload" (click)="signIn()">
    <ng-container *ngIf="!signingIn; else signingInTemp">
      <span>{{ 'Sign In' | translate }}</span>
    </ng-container>
    <ng-template #signingInTemp>
      <mat-spinner [diameter]="20" style="margin-right: 10px"></mat-spinner>
      <span>{{ 'Signing In ...' | translate }}</span>
    </ng-template>
  </button>
  <button *ngIf="signedIn" class="save" (click)="signOut()">
    <span>{{ 'Sign Out' | translate }}</span>
  </button>
</div>

import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { Mutex } from 'async-mutex';
import { isEqual } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, startWith } from 'rxjs';
import {
  ASPECT_RATIO_EXPANDED,
  BUTTONS_CONTAINER_HEIGHT,
  calculateMaxNumRowsOrCols,
  checkStyleValueIsValid,
  extractNumberValueFromStyle,
  GALLERY_VIEW_MIN_TILE_HEIGHT_16_9,
  GALLERY_VIEW_MIN_TILE_WIDTH_16_9,
  GALLERY_VIEW_MIN_TILE_WIDTH_HEIGHT_1_1,
  GALLERY_VIEW_SPACING_16_9,
  GALLERY_VIEW_SPACING_1_1,
  getCurrentStyleFromHTMLElement,
  HEADER_HEIGHT_MOBILE,
  isRightView,
  MARGIN_BOTTOM_GALLERY_VIEW,
  MARGIN_LEFT_RIGHT_GALLERY_VIEW,
  MARGIN_TOP_GALLERY_VIEW,
  MARGIN_TOP_RIGHT_VIEW,
  MIN_TILE_HEIGHT_IN_CAROUSEL_EXPANDED,
  RESIZABLE_RIGHT_VIEW_MIN_WIDTH,
  RIGHT_VIEW_HOST_MAX_NUMBER_OF_COLUMNS,
  RIGHT_VIEW_PARTICIPANT_MAX_NUMBER_OF_COLUMNS,
  RIGHT_VIEW_MAX_TILE_HEIGHT,
  RIGHT_VIEW_MIN_TILE_HEIGHT,
  RIGHT_VIEW_NAVIGATION_BUTTONS_HEIGHT,
  RIGHT_VIEW_SPACING,
  SPACE_BETWEEN_CAROUSEL_TILES,
  SPACE_BOTTOM_RIGHT_VIEW_TOWER,
  SPLIT_RATIO_EXPANDED,
  TilesContainerParameters,
  TOP_BAR_LEFT_SECTION_HEIGHT_MOBILE,
  TOP_NAV_BAR_HEIGHT_DESKTOP,
  TOP_VIEW_HEIGHT,
  TOP_VIEW_MAX_TILE_WIDTH,
  TOP_VIEW_MIN_TILE_WIDTH,
  TOP_VIEW_SPACING,
  VIDEO_CONTROLS_HEIGHT_DESKTOP,
  VIDEO_CONTROLS_HEIGHT_RIGHT_VIEW,
  SPACE_BETWEEN_EXPANDED_AND_CAROUSEL,
} from '../common/utils/tiles-packing-util';
import { FlagsService } from '../services/flags.service';
import { SessionView } from '../services/session-shared-data.service';
import { SpaceRepository } from '../state/space.repository';

@UntilDestroy()
@Directive({
  selector: '[appTilesContainerObserver]',
})
export class TilesContainerObserverDirective
  implements OnChanges, OnInit, AfterViewInit, OnDestroy
{
  private _isCallConnected = false;
  @Input() currentSessionView: SessionView = SessionView.WHITEBOARD;
  @Input() isMobileView = false;
  @Input() numberOfTiles = 1;
  @Input() set clearLatestEmittedChange(isCallConnected: boolean) {
    this._isCallConnected = isCallConnected;
    if (!isCallConnected) {
      this.latestEmittedChange = {};
    }
  }
  @Output() changeTilesContainerParameters: EventEmitter<TilesContainerParameters> =
    new EventEmitter();

  detectTilesContainerChangeMutex = new Mutex();

  containerResizeObserver?: ResizeObserver;

  latestEmittedChange?: any;

  // Used to debounce resizing change
  resizeChangeDetectedTimer: any;
  readonly APPLY_RESIZE_CHANGE_DELTA = 150;

  private unlistenMouseDown!: () => void;
  private unlistenMouseUp!: () => void;
  private unlistenWindowResize!: () => void;

  isResizing = false;
  resizedWidthRightView?: number;
  resizedHeightRightView?: number;
  viewsAllowVideoCallComponentResize = [SessionView.WHITEBOARD, SessionView.FULLSCREEN_APP];

  private rightViewMinTileHeight = RIGHT_VIEW_MIN_TILE_HEIGHT;
  private resizedRightViewMinWidth = RESIZABLE_RIGHT_VIEW_MIN_WIDTH;

  // this value is used to set the maximum number of columns in the right view
  // it is changable because we need to support more columns for hosts
  private maxNumberOfColsSetting = RIGHT_VIEW_PARTICIPANT_MAX_NUMBER_OF_COLUMNS;

  constructor(
    private elRef: ElementRef,
    private zone: NgZone,
    private renderer2: Renderer2,
    private flagsService: FlagsService,
    private spaceRepo: SpaceRepository,
  ) {}
  ngOnDestroy(): void {
    if (this.flagsService.featureFlagsVariables.dynamic_right_view?.resizable_tower) {
      this.unlistenMouseDown();
    }
    this.unlistenWindowResize();
    this.containerResizeObserver?.disconnect();
  }
  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      // Handle window size change for right view, and top view
      this.unlistenWindowResize = this.renderer2.listen('window', 'resize', () => {
        if (this.viewsAllowVideoCallComponentResize.includes(this.currentSessionView)) {
          this.detectTilesContainerChange();
        }
      });
    });
    if (this.flagsService.featureFlagsVariables.dynamic_right_view?.resizable_tower) {
      this.handleResizingDetection();
    }
    if (this.viewsAllowVideoCallComponentResize.includes(this.currentSessionView)) {
      this.publishNewTilesContainerParameters(this.calculateRightViewParameters());
    }
  }

  private handleResizingDetection() {
    // Used Renderer2 to be able to run these listeners outside angular
    // as HostListener will cause CD cycle regardless its body will be executed or not.
    // Also starting listening for mouse up event when it's necessary
    this.zone.runOutsideAngular(() => {
      this.unlistenMouseDown = this.renderer2.listen('window', 'mousedown', (e) => {
        if (
          this.viewsAllowVideoCallComponentResize.includes(this.currentSessionView) &&
          this._isCallConnected &&
          (e.target.id === 'overlay-parent-container' ||
            e.target.id === 'overlay-parent-container-resizer') &&
          !this.isResizing
        ) {
          this.isResizing = true;
          this.unlistenMouseUp = this.renderer2.listen('window', 'mouseup', () => {
            if (
              this.viewsAllowVideoCallComponentResize.includes(this.currentSessionView) &&
              this._isCallConnected &&
              this.isResizing
            ) {
              this.unlistenMouseUp();
              this.isResizing = false;
              this.detectTilesContainerChange(false, true);
            }
          });
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.numberOfTiles && isRightView(this.currentSessionView, this.isMobileView)) ||
      changes.currentSessionView ||
      changes.isMobileView
    ) {
      this.detectTilesContainerChange();
    }
  }

  detectTilesContainerChange(isResizingRightView = false, forcePublishing = false) {
    this.detectTilesContainerChangeMutex.runExclusive(() => {
      const screenWidth = this.elRef.nativeElement.offsetWidth ?? window.innerWidth;
      const screenHeight = this.elRef.nativeElement.offsetHeight ?? window.innerHeight;
      const [availableWidth, marginRightAndLeft] = this.calculateAvailableWidth();
      const [availableHeight, topNavBarHeight] = this.calculateAvailableHeight();

      switch (this.currentSessionView) {
        // Has different ways to calculate available width, and height
        case SessionView.WHITEBOARD:
        case SessionView.BREAKOUT_ROOMS:
        case SessionView.FULLSCREEN_APP:
          if (this.isMobileView) {
            this.publishNewTilesContainerParameters(this.calculateTopViewParameters());
          } else {
            this.publishNewTilesContainerParameters(
              this.calculateRightViewParameters(isResizingRightView),
              forcePublishing,
            );
          }
          break;
        case SessionView.GALLERY_VIEW:
          this.publishNewTilesContainerParameters(
            this.calculateGalleryViewParameters(
              availableWidth,
              availableHeight,
              topNavBarHeight,
              marginRightAndLeft,
            ),
          );
          break;
        case SessionView.EXPANDED:
          this.publishNewTilesContainerParameters(
            this.calculateExpandedViewParameters(
              availableWidth,
              availableHeight,
              topNavBarHeight,
              marginRightAndLeft,
            ),
          );
          break;
        case SessionView.FULLSCREEN:
          this.publishNewTilesContainerParameters(
            this.calculateFullScreenViewParameters(screenWidth, screenHeight),
          );
          break;
        default:
          return;
      }
    });
  }

  private calculateAvailableHeight(): number[] {
    const screenHeight = this.elRef.nativeElement.offsetHeight ?? window.innerHeight;
    const topNavBarHeight = this.calculateTopNavBarHeight();
    const videoControlsGalleryViewHeight = this.calculatevideoControlsGalleryViewHeight();

    return [
      screenHeight -
        topNavBarHeight -
        videoControlsGalleryViewHeight -
        MARGIN_TOP_GALLERY_VIEW -
        MARGIN_BOTTOM_GALLERY_VIEW,
      topNavBarHeight,
    ];
  }

  private calculateAvailableWidth(): number[] {
    const screenWidth = this.elRef.nativeElement.offsetWidth ?? window.innerWidth;
    const marginRightAndLeft = this.calculateMarginRigthAndLeft(screenWidth);

    return [screenWidth - marginRightAndLeft * 2, marginRightAndLeft];
  }

  // Mobile top view layout
  // Top view has a fixed height, so we are looking for maximum number for cols that can fit the available width
  private calculateTopViewParameters(): any {
    const availableWidth = window.innerWidth;

    const maxColsNum = calculateMaxNumRowsOrCols(
      availableWidth,
      TOP_VIEW_MIN_TILE_WIDTH,
      TOP_VIEW_SPACING,
    );

    const newTilesContainerParameters: TilesContainerParameters = {
      availableWidth: availableWidth,
      availableHeight: TOP_VIEW_HEIGHT,
      maxColsNum: maxColsNum,
      maxRowsNum: 1,
      minTileWidth: TOP_VIEW_MIN_TILE_WIDTH,
      minTileHeight: TOP_VIEW_HEIGHT, // Minimum tile height must be the same for the available height
      aspectRatio: TOP_VIEW_MIN_TILE_WIDTH / TOP_VIEW_HEIGHT,
      maxTileWidth: TOP_VIEW_MAX_TILE_WIDTH, // To handle the case of there is only 1 user in the call, so it won't take the whole width
      spaceBetweenTiles: TOP_VIEW_SPACING,
      anchorTopWithoutVerticallyCentered: 0,
      anchorLeftWithoutHorizontallyCentered: 0,
    };

    return newTilesContainerParameters;
  }

  // Right view has a fixed cols number = 1, so we are looking for how many tiles can fit the available height
  private calculateRightViewParameters(isResizing = false): any {
    // Using this pattern for getting margin top, width, or height to handle the case of showing the right tower once you started/joined the call
    // This way lets us to get the written values from SCSS style sheet

    // Div that holds mic, cam, share screen, ... etc buttons
    const videoControlsFirstDivHeight = this.getDivHeightRightView(
      'video-controls-first-div',
      VIDEO_CONTROLS_HEIGHT_RIGHT_VIEW,
    );

    // Div holds end call, expand, minimize, ... etc buttons
    const videoControlsSecondDivHeight = this.getDivHeightRightView(
      'video-controls-second-div',
      VIDEO_CONTROLS_HEIGHT_RIGHT_VIEW,
    );

    let rightViewMarginTop = MARGIN_TOP_RIGHT_VIEW;
    const parentContainerStyle = getCurrentStyleFromHTMLElement(this.elRef.nativeElement);
    const top = parentContainerStyle.top;
    if (checkStyleValueIsValid(top)) {
      rightViewMarginTop = extractNumberValueFromStyle(top);
    }

    // Setting current available width based on resizing
    if (isResizing) {
      this.resizedWidthRightView = this.elRef.nativeElement.offsetWidth;
    }

    // Handle hitting our limits while resizing
    if (!isResizing && this.resizedWidthRightView) {
      if (this.checkResizingUpperBound()) {
        this.resizedWidthRightView = window.innerWidth / 2;
      }

      if (this.checkResizingLowerBound()) {
        this.resizedWidthRightView = this.resizedRightViewMinWidth;
      }
    }

    // Calculate the maximum available height that the tower can take on the client screen
    const rightViewHeight = window.innerHeight - rightViewMarginTop - SPACE_BOTTOM_RIGHT_VIEW_TOWER;

    const availableHeight =
      rightViewHeight -
      videoControlsFirstDivHeight -
      videoControlsSecondDivHeight -
      RIGHT_VIEW_NAVIGATION_BUTTONS_HEIGHT;

    // Non-resizable tower
    let maxRowsNum1Panel = calculateMaxNumRowsOrCols(
      availableHeight,
      this.rightViewMinTileHeight,
      0,
    );
    let aspectRatio1Panel = 1;
    let maxColsNum = Math.min(
      this.maxNumberOfColsSetting,
      Math.ceil(this.numberOfTiles / maxRowsNum1Panel),
    );
    // If we resized the tower, we would check our requirements to set the appropriate aspect ratio
    // as we had 2 different aspect ratios while reszining
    // If we can't fit any tile with 1:1 aspect ratio, we will go with 16:9 as aspect ratio
    if (this.resizedWidthRightView) {
      [maxRowsNum1Panel, aspectRatio1Panel, maxColsNum] = this.calculatePanelResizedTowerParameters(
        availableHeight,
        this.resizedWidthRightView,
        maxRowsNum1Panel,
        this.maxNumberOfColsSetting,
      );
    }

    const availableWidth = this.getAvailableWidthRightView(maxColsNum);
    const newTilesContainerParameters: TilesContainerParameters = {
      isResized: !!this.resizedWidthRightView,
      availableWidth: availableWidth,
      availableHeight: availableHeight,
      maxColsNum: maxColsNum,
      maxRowsNum: maxRowsNum1Panel,
      minTileWidth: this.rightViewMinTileHeight,
      minTileHeight: this.rightViewMinTileHeight,
      aspectRatio: aspectRatio1Panel,
      spaceBetweenTiles: RIGHT_VIEW_SPACING,
      skipSettingComponentDimsWidth: isResizing,
      anchorTopWithoutVerticallyCentered: 0,
      anchorLeftWithoutHorizontallyCentered: 0,
      videoControlsFirstDivHeight: videoControlsFirstDivHeight,
      videoControlsSecondDivHeight: videoControlsSecondDivHeight,
    };

    return newTilesContainerParameters;
  }

  private getAvailableWidthRightView(maxColsNumber: number) {
    // if the user resizing the tower, return the same width that the user set
    if (this.resizedWidthRightView) {
      return this.resizedWidthRightView;
    }
    // calculate the needed width as this is not the resize case so we can expand the width as we need
    // if the number of cols is 1, we will return the max tile height as the width to make the tiles look big
    return maxColsNumber === 1
      ? RIGHT_VIEW_MAX_TILE_HEIGHT
      : maxColsNumber * this.rightViewMinTileHeight;
  }

  private getDivHeightRightView(divId: string, defaultValue: number): number {
    let divHeight = defaultValue;
    const div = document.getElementById(divId);
    if (div) {
      const divStyle = getCurrentStyleFromHTMLElement(div);
      const height = divStyle.height;
      if (checkStyleValueIsValid(height)) {
        divHeight = extractNumberValueFromStyle(height);
      }
    }
    return divHeight;
  }

  private checkResizingUpperBound(): boolean {
    return !!this.resizedWidthRightView && this.resizedWidthRightView > window.innerWidth / 2;
  }

  private checkResizingLowerBound(): boolean {
    return (
      !!this.resizedWidthRightView && this.resizedWidthRightView < this.resizedRightViewMinWidth
    );
  }

  private calculatePanelResizedTowerParameters(
    availableHeight: number,
    resizedWidthRightView: number,
    maxRowsNum1PanelNonResizable: number,
    maxNumberOfColsSetting: number,
  ): number[] {
    const maxRows = maxRowsNum1PanelNonResizable;
    let aspectRatio = 1;
    // we greedly fill column by column with maxRows before moving to the next column
    const maxColumns = Math.max(
      1,
      Math.min(
        maxNumberOfColsSetting,
        Math.floor(resizedWidthRightView / this.rightViewMinTileHeight),
        Math.ceil(this.numberOfTiles / maxRows),
      ),
    );
    // this is the number of rows that will be displayed per column
    const perColunmTilesCount = Math.min(
      Math.ceil(this.numberOfTiles / maxColumns),
      maxRowsNum1PanelNonResizable,
    );
    const maximumTileHeight = availableHeight / perColunmTilesCount;
    // tile width in the range [minTileHeight, maximumTileHeight*16/9]
    const tileWidth = Math.min((maximumTileHeight * 16) / 9, resizedWidthRightView / maxColumns);
    // aspect ratio =  widht / height
    aspectRatio = Math.max(1, tileWidth / maximumTileHeight);
    return [maxRows, aspectRatio, maxColumns];
  }

  // We are looking for calculating the maximum number for cols and rows
  private calculateGalleryViewParameters(
    availableWidth: number,
    availableHeight: number,
    topNavBarHeight: number,
    marginRightAndLeft: number,
  ): any {
    let minTileWidth = GALLERY_VIEW_MIN_TILE_WIDTH_16_9;
    let minTileHeight = GALLERY_VIEW_MIN_TILE_HEIGHT_16_9;
    let spaceBetweenTiles = GALLERY_VIEW_SPACING_16_9;
    let maxColsNumWithSpace = calculateMaxNumRowsOrCols(
      availableWidth,
      GALLERY_VIEW_MIN_TILE_WIDTH_16_9,
      GALLERY_VIEW_SPACING_16_9,
    );
    let maxRowsNumWithSpace = calculateMaxNumRowsOrCols(
      availableHeight,
      GALLERY_VIEW_MIN_TILE_HEIGHT_16_9,
      GALLERY_VIEW_SPACING_16_9,
    );

    // Handle switching to aspect ratio 1:1
    if (maxColsNumWithSpace === 1) {
      maxColsNumWithSpace = calculateMaxNumRowsOrCols(
        availableWidth,
        GALLERY_VIEW_MIN_TILE_WIDTH_HEIGHT_1_1,
        GALLERY_VIEW_SPACING_1_1,
      );

      maxRowsNumWithSpace = calculateMaxNumRowsOrCols(
        availableHeight,
        GALLERY_VIEW_MIN_TILE_WIDTH_HEIGHT_1_1,
        GALLERY_VIEW_SPACING_1_1,
      );

      // Only apply new aspect ratio 1:1 if I will have more than 1 col
      if (maxColsNumWithSpace > 1) {
        minTileWidth = GALLERY_VIEW_MIN_TILE_WIDTH_HEIGHT_1_1;
        minTileHeight = GALLERY_VIEW_MIN_TILE_WIDTH_HEIGHT_1_1;
        spaceBetweenTiles = GALLERY_VIEW_SPACING_1_1;
      }
    }

    const newTilesContainerParameters: TilesContainerParameters = {
      availableWidth: availableWidth,
      availableHeight: availableHeight,
      maxColsNum: maxColsNumWithSpace,
      maxRowsNum: maxRowsNumWithSpace,
      minTileWidth: minTileWidth,
      minTileHeight: minTileHeight,
      aspectRatio: minTileWidth / minTileHeight,
      spaceBetweenTiles: spaceBetweenTiles,
      anchorTopWithoutVerticallyCentered: topNavBarHeight + MARGIN_TOP_GALLERY_VIEW,
      anchorLeftWithoutHorizontallyCentered: marginRightAndLeft,
    };

    return newTilesContainerParameters;
  }

  // For expanded tile, all its calculations are done below as all its parameters are known
  // For carousel tiles, we are looking for calculating the maximum number of cols to fit the available width
  private calculateExpandedViewParameters(
    availableWidth: number,
    availableHeight: number,
    topNavBarHeight: number,
    marginRightAndLeft: number,
  ): any {
    const carouselHeight = Math.max(
      (1 - SPLIT_RATIO_EXPANDED) * availableHeight,
      MIN_TILE_HEIGHT_IN_CAROUSEL_EXPANDED,
    );

    const expandedTileHeight =
      availableHeight - carouselHeight - SPACE_BETWEEN_EXPANDED_AND_CAROUSEL;
    let expandedTileWidth = expandedTileHeight * ASPECT_RATIO_EXPANDED;
    // Handle can't maintain 16:9 ratio. So, let expanded tile width to be the whole available space except the margin right, and left
    if (expandedTileWidth > availableWidth) {
      expandedTileWidth = availableWidth - marginRightAndLeft * 2;
    }
    const spaceToCenterExpandedTile = Math.max((availableWidth - expandedTileWidth) / 2, 0);

    const carouselTileWidth = carouselHeight * ASPECT_RATIO_EXPANDED;

    const newTilesContainerParameters: TilesContainerParameters = {
      availableWidth: availableWidth,
      availableHeight: availableHeight,
      expandedTileHeight: expandedTileHeight,
      expandedTileWidth: expandedTileWidth,
      anchorTopExpanded: topNavBarHeight + MARGIN_TOP_GALLERY_VIEW,
      anchorLeftExpanded: marginRightAndLeft + spaceToCenterExpandedTile,
      minTileWidth: carouselTileWidth,
      minTileHeight: carouselHeight,
      aspectRatio: carouselTileWidth / carouselHeight,
      maxColsNum: calculateMaxNumRowsOrCols(
        availableWidth,
        carouselTileWidth,
        SPACE_BETWEEN_CAROUSEL_TILES,
      ),
      maxRowsNum: 1,
      spaceBetweenTiles: SPACE_BETWEEN_CAROUSEL_TILES,
      anchorTopCarousel:
        topNavBarHeight +
        MARGIN_TOP_GALLERY_VIEW +
        expandedTileHeight +
        SPACE_BETWEEN_CAROUSEL_TILES,
      anchorLeftWithoutHorizontallyCentered: marginRightAndLeft,
    };

    return newTilesContainerParameters;
  }

  private calculateFullScreenViewParameters(availableWidth: number, availableHeight: number): any {
    const newTilesContainerParameters: TilesContainerParameters = {
      availableWidth: availableWidth,
      availableHeight: availableHeight - this.calculateVideoControlsFullScreenHeight(),
      maxColsNum: 1,
      maxRowsNum: 1,
      minTileWidth: availableWidth,
      minTileHeight: availableHeight - this.calculateVideoControlsFullScreenHeight(),
      aspectRatio:
        availableWidth / (availableHeight - this.calculateVideoControlsFullScreenHeight()),
      spaceBetweenTiles: 0,
      anchorTopWithoutVerticallyCentered: 0,
      anchorLeftWithoutHorizontallyCentered: 0,
    };
    return newTilesContainerParameters;
  }

  private calculateTopNavBarHeight(): number {
    if (this.isMobileView) {
      return (
        (document.getElementById('mobile-header')?.offsetHeight ?? HEADER_HEIGHT_MOBILE) +
        (document.getElementById('top-bar-left-section')?.offsetHeight ??
          TOP_BAR_LEFT_SECTION_HEIGHT_MOBILE)
      );
    }
    return document.getElementById('top-nav-bar')?.offsetHeight ?? TOP_NAV_BAR_HEIGHT_DESKTOP;
  }

  private calculatevideoControlsGalleryViewHeight(): number {
    if (this.isMobileView) {
      return document.getElementById('buttons-container')?.offsetHeight ?? BUTTONS_CONTAINER_HEIGHT;
    }
    return document.getElementById('video-controls')?.offsetHeight ?? VIDEO_CONTROLS_HEIGHT_DESKTOP;
  }

  private calculateMarginRigthAndLeft(screenWidth: number): number {
    if ([SessionView.GALLERY_VIEW, SessionView.EXPANDED].includes(this.currentSessionView)) {
      return Math.ceil(screenWidth * MARGIN_LEFT_RIGHT_GALLERY_VIEW);
    }
    return 0;
  }

  private calculateVideoControlsFullScreenHeight(): number {
    if (this.isMobileView) {
      return document.getElementById('buttons-container')?.clientHeight ?? BUTTONS_CONTAINER_HEIGHT;
    }
    return document.getElementById('video-controls')?.clientHeight ?? VIDEO_CONTROLS_HEIGHT_DESKTOP;
  }

  private publishNewTilesContainerParameters(
    newTilesContainerParameters: any,
    forcePublishing = false,
  ) {
    if (!isEqual(this.latestEmittedChange, newTilesContainerParameters) || forcePublishing) {
      this.changeTilesContainerParameters.emit(newTilesContainerParameters);
      this.latestEmittedChange = newTilesContainerParameters;
    }
  }

  ngOnInit(): void {
    combineLatest([
      this.flagsService.featureFlagsChanged.pipe(startWith(undefined)),
      this.spaceRepo.isCurrentUserHost$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([_, isHost]) => {
        let needsRecalculateContainerParams = false;
        // generic variables
        const dynamicRightWiewFlagVariables =
          this.flagsService.featureFlagsVariables.dynamic_right_view;
        const newMinTileHeight =
          (dynamicRightWiewFlagVariables?.minimum_tile_height as number) ||
          RIGHT_VIEW_MIN_TILE_HEIGHT;
        const newMinResizedRightTowerWidth =
          (dynamicRightWiewFlagVariables?.resized_right_view_min_width as number) ||
          RESIZABLE_RIGHT_VIEW_MIN_WIDTH;
        if (
          newMinTileHeight !== this.rightViewMinTileHeight ||
          newMinResizedRightTowerWidth !== this.resizedRightViewMinWidth
        ) {
          this.rightViewMinTileHeight = newMinTileHeight;
          this.resizedRightViewMinWidth = newMinResizedRightTowerWidth;
          needsRecalculateContainerParams = true;
        }

        if (isHost) {
          const newMaxNumberOfCols =
            (dynamicRightWiewFlagVariables?.host_max_columns as number) ||
            RIGHT_VIEW_HOST_MAX_NUMBER_OF_COLUMNS;
          if (this.maxNumberOfColsSetting !== newMaxNumberOfCols) {
            this.maxNumberOfColsSetting = newMaxNumberOfCols;
            needsRecalculateContainerParams = true;
          }
        } else {
          const newMaxNumberOfCols =
            (dynamicRightWiewFlagVariables?.participant_max_columns as number) ||
            RIGHT_VIEW_PARTICIPANT_MAX_NUMBER_OF_COLUMNS;
          if (this.maxNumberOfColsSetting !== newMaxNumberOfCols) {
            this.maxNumberOfColsSetting = newMaxNumberOfCols;
            needsRecalculateContainerParams = true;
          }
        }

        if (needsRecalculateContainerParams) {
          this.detectTilesContainerChange();
        }
      });

    this.zone.runOutsideAngular(() => {
      this.containerResizeObserver = new ResizeObserver(() => {
        this.changeInSizeDetected();
      });
      this.containerResizeObserver.observe(this.elRef.nativeElement);
    });
  }

  changeInSizeDetected() {
    if (!this.viewsAllowVideoCallComponentResize.includes(this.currentSessionView)) {
      this.detectTilesContainerChange();
    }
    if (
      this.viewsAllowVideoCallComponentResize.includes(this.currentSessionView) &&
      this.isResizing
    ) {
      this.detectTilesContainerChange(true);
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { Observable, animationFrameScheduler, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { modifiedTimer } from '../utilities/ZoneUtils';

@Pipe({
  name: 'relativeDate',
})
export class RelativeDatePipe implements PipeTransform {
  // Optionally specify an update interval in seconds.
  transform(date: string, interval = 60): Observable<string> {
    /* Not 100% sure if animationFrameScheduler is the best choice here, but the
     * default asyncScheduler delays the date from appearing until after the
     * first render.
     */
    const initialRelativeDate = moment(date).fromNow();
    const intervalObservable = modifiedTimer(0, 1000 * interval, animationFrameScheduler).pipe(
      map(() => moment(date).fromNow()),
    );
    return merge(of(initialRelativeDate), intervalObservable);
  }
}

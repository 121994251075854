<div class="login-header" id="account-pencil-header">
  <a (click)="goToLogin()">
    <svg-icon
      *ngIf="showLogo"
      class="top-left-logo"
      width="185"
      height="32"
      [icon]="'pencil-spaces-2'"
    ></svg-icon>
  </a>
  <div class="float-right link-right d-flex align-items-center">
    <a *ngIf="showLogout" (click)="signOut()" class="button-link">
      {{ 'Sign out' | translate }}
    </a>
    <button mat-button [matMenuTriggerFor]="langMenu" class="btn-lang-select cml-16 pr-1">
      <span *ngIf="displayLangName">
        {{ this.displayLangName | translate }}
      </span>
      <span *ngIf="!displayLangName">
        {{ langCode?.originalLabel | translate }}
      </span>
      <mat-icon class="cml-16">expand_more</mat-icon>
    </button>
  </div>
</div>

<mat-menu #langMenu="matMenu" class="lang-menu">
  <div *ngFor="let lang of langCodeMap">
    <button
      mat-menu-item
      [ngStyle]="
        langCode?.code === lang.code ? { 'background-color': '#cce7ff', color: '#0f42c8' } : ''
      "
      (click)="changeLang(lang)"
    >
      {{ lang.originalLabel }} / {{ lang.label | translate }}
      <mat-icon *ngIf="langCode?.code === lang.code" class="check-icon">check</mat-icon>
    </button>
  </div>
</mat-menu>

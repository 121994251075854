import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  AccountEntryService,
  LoginState,
} from 'src/app/account/account-entry/account-entry.service';
import { LangCodeMap } from 'src/app/consts';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { LangCode } from 'src/app/sessions/session/wb-top-controls/wb-top-controls.component';
import { sortByString } from '../utils/common-util';

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss'],
})
export class LoginHeaderComponent implements OnInit {
  @Input() showLogo = true;
  @Input() redirectWithoutParams = false;
  @Input() showLogout = false;

  displayLangName;

  langCodeMap: LangCode[] = LangCodeMap.filter((code) => code.originalLabel).sort(
    (a: any, b: any) => sortByString(a, b, 'label'),
  );
  langCode = LangCodeMap.find(
    (code) =>
      code.code ===
      (localStorage.getItem('selected_lang_code') || navigator.language.split('-')[0]),
  );

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private userService: UserService,
    private router: Router,
    private accountEntryService: AccountEntryService,
  ) {}

  ngOnInit(): void {
    if (this.langCode) {
      this.changeLang(this.langCode);
      if (this.langCode.originalLabel) {
        this.displayLangName = this.langCode.originalLabel.substring(4);
      }
    }
  }

  signOut() {
    this.authService.logout();
  }

  goToLogin() {
    this.accountEntryService.loginInfoChanges.next({ loginState: LoginState.START });
    this.router.navigate(['account', 'login'], {
      queryParamsHandling: this.redirectWithoutParams ? '' : 'preserve',
    });
  }

  changeLang(langCode: LangCode) {
    this.langCode = langCode;
    this.translateService.currentLang = '';
    this.translateService.use(langCode.code);
    this.userService.rtl.next(langCode.rtl);
    this.displayLangName = this.langCode?.originalLabel?.substring(4);
    localStorage.setItem('selected_lang_code', langCode.code);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TypedFragment } from '../common/typed-fragment/typed-fragment';

@Pipe({
  name: 'answerCheck',
})
export class AnswerCheckPipe implements PipeTransform {
  transform(fragments: TypedFragment[]): any {
    let isExist = false;
    for (const frag of fragments) {
      if (frag.type === 'ANSWERTEXT') {
        isExist = true;
        break;
      } else {
        continue;
      }
    }
    return isExist;
  }
}

<div class="icon-message-notification-element">
  <div
    [ngClass]="{
      'warning-icon-background': iconString?.iconBackground === IconBackground.WARNING,
      'info-icon-background': iconString?.iconBackground === IconBackground.INFO,
      'error-icon-background': iconString?.iconBackground === IconBackground.ERROR,
      'success-icon-background': iconString?.iconBackground === IconBackground.SUCCESS
    }"
    [style]="iconString?.style"
  >
    <ng-container *ngIf="iconString?.spinner">
      <app-pencil-spinner
        [diameter]="18"
        class="spinner"
        [ngStyle]="iconString?.spinnerStyle"
        [strokeWidth]="2.25"
      ></app-pencil-spinner>
    </ng-container>
    <ng-container *ngIf="iconString?.icon">
      <div
        notification-icon
        [icon]="iconString?.icon"
        [removeMargin]="iconString?.removeMargin"
        [outlined]="iconString?.outlined"
      ></div>
    </ng-container>
  </div>
  <p class="description" [style]="iconString?.textStyle" [innerHtml]="iconString?.msg"></p>
</div>

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IconMessageToasterElement } from '../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { ERRORS } from '../common/utils/notification-constants';
import { ToasterPopupStyle } from '../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { DevicesHandleUtil } from '../common/utils/devices-handle-util';
import { DeviceErrorType, DeviceState } from '../models/device-manger';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from './notification-toaster.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceErrorsNotificationsService {
  constructor(
    private notificationToasterService: NotificationToasterService,
    private translateService: TranslateService,
  ) {}

  showPermissionErrorNotification(cam: DeviceState, mic: DeviceState): void {
    if (this.anyDeviceHasPermissionError(cam, mic)) {
      this.showPermissionErrorNotificationMessage();
    }
  }

  dismissPermissionErrorNotification() {
    this.notificationToasterService.dismissNotificationsByCode([
      ERRORS.GRANT_CAMERA_AND_OR_MICROPHONE_ACCESS,
    ]);
  }

  showNotFoundErrorNotification(cam: DeviceState, mic: DeviceState): void {
    if (
      !this.hasErrorOfType(cam, DeviceErrorType.NOT_FOUND) &&
      !this.hasErrorOfType(mic, DeviceErrorType.NOT_FOUND)
    ) {
      return;
    }
    if (
      this.hasErrorOfType(cam, DeviceErrorType.NOT_FOUND) &&
      this.hasErrorOfType(mic, DeviceErrorType.NOT_FOUND)
    ) {
      this.showErrorNotificationMessage(
        this.translateService.instant('Devices not found'),
        this.translateService.instant(
          'We were not able to find your microphone and camera. Please  follow the steps again or try again later.',
        ),
        ERRORS.CAMERA_AND_MICROPHONE_NOT_FOUND,
      );
    } else if (this.hasErrorOfType(cam, DeviceErrorType.NOT_FOUND)) {
      this.showErrorNotificationMessage(
        this.translateService.instant('Camera not found'),
        this.translateService.instant(
          'We were not able to find your camera. Please  follow the steps again or try again later.',
        ),
        ERRORS.CAMERA_NOT_FOUND,
      );
    } else {
      this.showErrorNotificationMessage(
        this.translateService.instant('Microphone not found'),
        this.translateService.instant(
          'We were not able to find your microphone. Please  follow the steps again or try again later.',
        ),
        ERRORS.MICROPHONE_NOT_FOUND,
      );
    }
  }

  dismissNotFoundErrorNotification() {
    this.notificationToasterService.dismissNotificationsByCode([
      ERRORS.CAMERA_AND_MICROPHONE_NOT_FOUND,
      ERRORS.CAMERA_NOT_FOUND,
      ERRORS.MICROPHONE_NOT_FOUND,
    ]);
  }

  showNoInputDetectedErrorNotification(cam: DeviceState, mic: DeviceState): void {
    if (
      !this.hasErrorOfType(cam, DeviceErrorType.NO_INPUT_DETECTED) &&
      !this.hasErrorOfType(mic, DeviceErrorType.NO_INPUT_DETECTED)
    ) {
      return;
    }
    if (
      this.hasErrorOfType(cam, DeviceErrorType.NO_INPUT_DETECTED) &&
      this.hasErrorOfType(mic, DeviceErrorType.NO_INPUT_DETECTED)
    ) {
      this.showErrorNotificationMessage(
        this.translateService.instant('No input detected'),
        this.translateService.instant(
          'We were not able to detect input from your microphone and camera. Please  follow the steps again or try again later.',
        ),
        ERRORS.NO_CAMERA_AND_MICROPHONE_INPUT_DETECTED,
      );
    } else if (this.hasErrorOfType(cam, DeviceErrorType.NO_INPUT_DETECTED)) {
      this.showErrorNotificationMessage(
        this.translateService.instant('No input from camera'),
        this.translateService.instant(
          'We were not able to detect input from your camera. Please  follow the steps again or try again later.',
        ),
        ERRORS.NO_CAMERA_INPUT_DETECTED,
      );
    } else {
      this.showErrorNotificationMessage(
        this.translateService.instant('No input from microphone'),
        this.translateService.instant(
          'We were not able to detect input from your microphone. Please  follow the steps again or try again later.',
        ),
        ERRORS.NO_MICROPHONE_INPUT_DETECTED,
      );
    }
  }

  dismissNoInputDetectedErrorNotification() {
    this.notificationToasterService.dismissNotificationsByCode([
      ERRORS.NO_CAMERA_AND_MICROPHONE_INPUT_DETECTED,
      ERRORS.NO_CAMERA_INPUT_DETECTED,
      ERRORS.NO_MICROPHONE_INPUT_DETECTED,
    ]);
  }

  anyDeviceHasPermissionError(cam: DeviceState, mic: DeviceState): boolean {
    return (
      (cam.hasError && DevicesHandleUtil.isPermissionDenied(cam.errorType)) ||
      (mic.hasError && DevicesHandleUtil.isPermissionDenied(mic.errorType))
    );
  }

  hasErrorOfType(deviceState: DeviceState, errorType: DeviceErrorType): boolean {
    if (!deviceState.hasError || deviceState.errorType !== errorType) {
      return false;
    }

    return true;
  }

  showPermissionErrorNotificationMessage() {
    this.showErrorNotificationMessage(
      this.translateService.instant('Permissions not granted'),
      this.translateService.instant(
        'We were not able to access your microphone and/or camera. Please  follow the steps again or try again later.',
      ),
      ERRORS.GRANT_CAMERA_AND_OR_MICROPHONE_ACCESS,
    );
  }

  private showErrorNotificationMessage(title: string, bodyMsg: string, error: ERRORS) {
    const topElement = new IconMessageToasterElement({ icon: 'warning', size: 18 }, title);
    const middleElement = new IconMessageToasterElement(undefined, bodyMsg);

    const notificationData = new NotificationDataBuilder(error)
      .type(NotificationType.ERROR)
      .style(ToasterPopupStyle.ERROR)
      .timeOut(10)
      .dismissable(false)
      .showProgressBar(true)
      .topElements([topElement])
      .middleElements([middleElement])
      .onActivateTick(true)
      .toastClass('device-helper-error-notification')
      .build();

    this.notificationToasterService.showNotification(notificationData);
  }
}

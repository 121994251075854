import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { excludeKeys, persistState } from '@ngneat/elf-persist-state';
import * as localForage from 'localforage';
import { distinctUntilChanged, map } from 'rxjs/operators';

// Configure LocalForage to write this store to IndexedDB
localForage.config({
  driver: localForage.INDEXEDDB,
  name: 'pencilapp',
  version: 1.0,
  storeName: 'uiprops',
});

export interface IUIProps {
  upgradeBannerClosedDate: Date | null;
  spacesVisitedUniqueDays: number;
  spacesVisitedLastDate: Date | null;
  showUpgradeBanner: boolean;
  spaceFontFamily: string;
}

@Injectable({ providedIn: 'root' })
export class UIPropsRepository {
  private readonly store = createStore(
    { name: 'uiprops' },
    withProps<IUIProps>({
      upgradeBannerClosedDate: null,
      spacesVisitedUniqueDays: 0,
      spacesVisitedLastDate: null,
      showUpgradeBanner: false,
      spaceFontFamily: '',
    }),
  );
  private readonly persistState = persistState(this.store, {
    key: 'uiprops',
    storage: localForage as any,
    source: () => this.store.pipe(excludeKeys(['showUpgradeBanner'])),
  });

  constructor() {
    this.persistState.initialized$.subscribe((result) => {
      console.log(`UIProps store initialized = ${result}`);
    });
  }

  get UIProps$() {
    return this.store.pipe(select((state) => state));
  }

  get uiProps(): IUIProps {
    return this.store.getValue();
  }

  get upgradeBannerClosedDate(): Date | null {
    return this.store.getValue().upgradeBannerClosedDate;
  }

  get showUpgradeBanner(): boolean {
    return this.store.getValue().showUpgradeBanner;
  }

  public readonly showUpgradeBanner$ = this.UIProps$.pipe(
    map((UIProps) => UIProps.showUpgradeBanner),
    distinctUntilChanged(),
  );

  updateUIProps(updatedProps: Partial<IUIProps>) {
    this.store.update((state) => ({
      ...state,
      ...updatedProps,
    }));
  }

  setUpgradeBannerClosedDate() {
    const currentDate = new Date(Date.now());
    this.updateUIProps({ upgradeBannerClosedDate: currentDate });
  }

  setShowUpgradeBanner(show: boolean) {
    this.updateUIProps({ showUpgradeBanner: show });
  }
}

<div class="icon-right">
  <mat-icon class="material-icons-outlined close-icon" (click)="close(false)">close</mat-icon>
</div>
<div class="images">
  <svg-icon [width]="25" [height]="70" [icon]="'PencilLogo'"></svg-icon>
  <svg-icon [icon]="'heart'" [width]="52" [height]="46"></svg-icon>
  <svg-icon [icon]="'Chrome'" [width]="70" [height]="70"></svg-icon>
</div>

<div class="header">{{ 'Pencil works best in Chrome.' | translate }}</div>

<div class="message">
  {{ 'We recommend using Pencil in Google Chrome for the optimal experience.' | translate }}
</div>

<mat-dialog-actions class="options">
  <button class="maybe-later-button" (click)="close(false)">
    {{ 'Maybe later' | translate }}
  </button>
  <button mat-button class="chrome-button btn-primary" (click)="close(true)">
    <svg-icon [width]="18" [height]="18" [icon]="'to-ext-link'"></svg-icon>
    {{ 'Get Chrome' | translate }}
  </button>
</mat-dialog-actions>

import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  fromEvent,
  map,
  Observable,
  of,
  startWith,
  take,
} from 'rxjs';
import { fabric } from 'fabric';
import { Injectable } from '@angular/core';
import { SessionSharedDataService } from '../../../services/session-shared-data.service';
import { RealtimeSpaceService } from '../../../services/realtime-space.service';
import { SpaceRepository } from '../../../state/space.repository';
import { isItemWithSpecialZIndex } from '../../../services/items-canvas.service';
import { CanvasItemsDataObserverService } from './canvas-Items-data-observer.service';
import { CanvasItem } from './items-canvas.component';
import { BoardItemsInteractionsManager } from './board-items-interactions-manager';

const MAX_CANVAS_ITEMS_COUNT = 8192;
export enum StackingPosition {
  BACK = 'back',
  FRONT = 'front',
}

@Injectable({
  providedIn: 'root',
})
export class BoardItemsStackingOrderManager {
  private readonly _maxZIndexOnTheBoard$ = new BehaviorSubject<number>(-1);
  private readonly _minZIndexOnTheBoard$ = new BehaviorSubject<number>(0);

  constructor(
    private sharedDataService: SessionSharedDataService,
    private realtimeSpaceService: RealtimeSpaceService,
    private spaceRepo: SpaceRepository,
    private canvasItemsDataObserverService: CanvasItemsDataObserverService,
    private boardItemsInteractionsManager: BoardItemsInteractionsManager,
  ) {}

  public clearState() {
    this._maxZIndexOnTheBoard$.next(-1);
    this._minZIndexOnTheBoard$.next(0);
  }

  public generateMaxZIndex() {
    this._maxZIndexOnTheBoard$.next(this.maxZIndexOnTheBoard + 1);
    return this.maxZIndexOnTheBoard;
  }

  private generateMinZIndex() {
    this._minZIndexOnTheBoard$.next(this.minZIndexOnTheBoard$ - 1);
    return this.minZIndexOnTheBoard$;
  }

  get maxZIndexOnTheBoard() {
    return this._maxZIndexOnTheBoard$.getValue();
  }
  get minZIndexOnTheBoard$() {
    return this._minZIndexOnTheBoard$.getValue();
  }

  sendFabricObjectToBack(itemSelected: fabric.Object): void {
    const itemsDepth = this.sharedDataService.fabricCanvas?._objects.filter(
      (item) => item.excludeFromExport,
    ).length;

    if (typeof itemsDepth !== 'undefined') {
      this.sharedDataService.fabricCanvas?.moveTo(itemSelected, itemsDepth);
      this.sharedDataService.fabricCanvas?.requestRenderAll();
      this.sharedDataService.fabricCanvas?.fire('object:modified', { target: itemSelected });

      this.realtimeSpaceService.service.updateObjectIndex(itemSelected['uid'], 0);
    }
  }

  bringFabricObjectToFront(itemSelected: fabric.Object): void {
    this.sharedDataService.fabricCanvas?.bringToFront(itemSelected);
    this.sharedDataService.fabricCanvas?.requestRenderAll();

    const similarObjects =
      this.sharedDataService.fabricCanvas
        ?.getObjects()
        .filter((obj) => obj.excludeFromExport === itemSelected.excludeFromExport) || [];

    this.realtimeSpaceService.service.updateObjectIndex(
      itemSelected['uid'],
      similarObjects.length - 1,
    );
  }

  bringCanvasItemToFront(itemFrameId: string) {
    this.changeIndex(itemFrameId, StackingPosition.FRONT);
  }

  sendCanvasItemToBack(itemFrameId: string) {
    this.changeIndex(itemFrameId, StackingPosition.BACK);
  }

  private changeIndex(itemFrameId: string, newState: StackingPosition): void {
    if (!this.spaceRepo.activeSpace || !this.sharedDataService.itemsCanvas) {
      return;
    }

    // TODO make this a parameter that is sent from the caller
    const canvasItemsMap = this.sharedDataService.itemsCanvas.canvasItems;
    const canvasItem = canvasItemsMap[itemFrameId];
    if (!canvasItemsMap || !canvasItem) {
      return;
    }

    canvasItem.itemState.index =
      newState === StackingPosition.FRONT ? this.generateMaxZIndex() : this.generateMinZIndex();

    canvasItem.relatedFabricItem?.moveTo(
      newState === StackingPosition.FRONT ? Object.values(canvasItemsMap).length - 1 : 0,
    );

    // Update local state
    this.canvasItemsDataObserverService.notifyCanvasItemListeners(canvasItem);

    // Update remote state
    this.sharedDataService.itemsCanvas?.sendChangeStateEvent(canvasItem);
  }

  createZIndexObservable(canvasItem: CanvasItem): Observable<number> {
    const itemId = canvasItem.relatedItem?._id;
    const itemType = canvasItem.relatedItem?.model;
    if (
      !this.sharedDataService.fabricCanvas ||
      !this.sharedDataService.itemsCanvas ||
      !itemType ||
      !itemId
    ) {
      return of(-1);
    }

    return combineLatest([
      this.sharedDataService.itemsCanvas.objectMoving$,
      this.boardItemsInteractionsManager.interactionsEnabled$(canvasItem),
      this.canvasItemsDataObserverService
        .zIndexUpdated$(itemId)
        .pipe(startWith(canvasItem.itemState.index)),
      this.sharedDataService.itemsCanvas.fullscreenId$.pipe(
        map((fullScreenedId) => fullScreenedId === itemId),
      ),
    ]).pipe(
      map(([objectMoving, interactionsEnabled, storedZIndex, isFullScreened]) => {
        if (isItemWithSpecialZIndex(itemType)) {
          if (objectMoving) {
            return -1; // prevent contents from getting mouse move events
          }
          if (interactionsEnabled || isFullScreened) {
            return MAX_CANVAS_ITEMS_COUNT + 1;
          }
        } else if (interactionsEnabled) {
          return 1;
        }
        const index = storedZIndex - MAX_CANVAS_ITEMS_COUNT - 1;
        return Math.min(-1, index);
      }),
      distinctUntilChanged(),
    );
  }

  canvasItemsUpdated(canvasItems: CanvasItem[], remoteUpdate: boolean, isFirstLoad: boolean) {
    if (canvasItems.length === 0) {
      this.clearState();
      return;
    }

    this.setMaxAndMinIndexOnBoard(canvasItems);

    if (isFirstLoad) {
      // because the whole container that holds canvas items is behind the fabric canvas,
      // so the related fabric item of canvas items, which is the thing that takes the user interaction,
      // should also be behind all other types of fabric objects to match the visibility with the interactions expectations for the user
      // .i.e avoiding the case where an image appear below a shape but when clicking the shape the image will be the one selected

      // This action shouldn't be done to static canvases because this might result in inconsistencies that are caused by the review (static) canvas state affecting the real canvas state
      // to repo the meaning of that issue,
      // after initial load
      // open boards manager
      // immediately move to another board
      // => the items in the board not draggable
      this.putAllRelatedFabricObjectBehindRealFabricObjects(canvasItems);
    } else if (remoteUpdate && this.sharedDataService.fabricCanvas) {
      // TODO it is better to do this only if there is an element added, deleted or a zIndex changed? (but it is not too expensive currently)

      // performing this after render to ensure that all canvas items changes have been applied,
      // and now they are reflected to fabric objects
      fromEvent(this.sharedDataService.fabricCanvas, 'after:render')
        .pipe(take(1))
        .subscribe((x) => this.configureRelatedFabricItemsIndexes(canvasItems));
    }
  }

  setMaxAndMinIndexOnBoard(canvasItems: CanvasItem[]) {
    canvasItems.forEach((canvasItem, index) => {
      this._minZIndexOnTheBoard$.next(
        Math.min(this._minZIndexOnTheBoard$.getValue(), canvasItem.itemState.index),
      );

      this._maxZIndexOnTheBoard$.next(
        Math.max(this._maxZIndexOnTheBoard$.getValue(), canvasItem.itemState.index),
      );
    });
  }

  putAllRelatedFabricObjectBehindRealFabricObjects(canvasItems: CanvasItem[]) {
    if (!this.sharedDataService.fabricCanvas) {
      return;
    }

    const sortedRelatedFabricObjects = canvasItems
      .sort((a, b) => a.itemState.index - b.itemState.index)
      .filter((canvasItem) => !!canvasItem.relatedFabricItem)
      .map((canvasItem) => {
        // to differentiate it from real ones (scribbles, shapes..) later
        (canvasItem.relatedFabricItem as any).isRelatedFabricItem = true;

        return canvasItem.relatedFabricItem as fabric.Object;
      });

    const fabricObjects = this.sharedDataService.fabricCanvas.getObjects();
    if (fabricObjects.length < canvasItems.length) {
      // make sure they are rendered
      // TODO instead, execute this code after being sure that they were rendered
      return;
    }

    const realFabricObjects = fabricObjects.filter(
      (fabricObject) => !(fabricObject as any).isRelatedFabricItem,
    );

    this.sharedDataService.fabricCanvas.clear();
    this.sharedDataService.fabricCanvas.add(...sortedRelatedFabricObjects);
    this.sharedDataService.fabricCanvas.add(...realFabricObjects);

    this.sharedDataService.fabricCanvas.requestRenderAll();
  }

  private configureRelatedFabricItemsIndexes(canvasItems: CanvasItem[]) {
    if (!canvasItems.length || !this.sharedDataService.fabricCanvas) {
      return;
    }
    const fabricObjects = this.sharedDataService.fabricCanvas._objects;
    if (fabricObjects.length < canvasItems.length) {
      // make sure they are rendered
      return;
    }
    canvasItems
      .sort((a, b) => a.itemState.index - b.itemState.index)
      .forEach((canvasItem, index) => {
        if (!this.sharedDataService.fabricCanvas || !canvasItem.relatedFabricItem) {
          return;
        }

        if (index !== 0 && canvasItem.relatedFabricItem === fabricObjects[index - 1]) {
          this.swapInPlace(fabricObjects, index, index - 1);
          this.sharedDataService.fabricCanvas.requestRenderAll();
          return;
        }

        if (
          index !== fabricObjects.length - 1 &&
          canvasItem.relatedFabricItem === fabricObjects[index + 1]
        ) {
          this.swapInPlace(fabricObjects, index, index + 1);
          this.sharedDataService.fabricCanvas.requestRenderAll();
          return;
        }

        if (canvasItem.relatedFabricItem === fabricObjects[index]) {
          return;
        }

        canvasItem.relatedFabricItem.moveTo(index);
      });
  }

  private swapInPlace(array: any[], i: number, j: number) {
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

import { AbstractControl, Validators } from '@angular/forms';
import { Institution, Site, User } from '../models/user';
import { Personas } from '../services/acl.service';

export const LEARN_MORE_ABOUT_SITES =
  'https://helpdesk.pencilspaces.com/en/articles/9247273-why-do-i-need-to-specify-a-site';

const personasWithNoSites = [
  Personas.Guest,
  Personas.Anonymous,
  Personas.Administrator,
  Personas.InstitutionAdmin,
];

export function getSiteFromSiteId(
  siteId?: string | undefined,
  sites?: Site[] | undefined,
): Site | undefined {
  return sites?.find((site) => site._id === siteId);
}

export function institutionHasSites(institution?: Institution): boolean {
  return !!institution?.sites?.length;
}

export function userHasSites(user?: User): boolean {
  return !!user?.sites?.length;
}

export function updateSitesControl(
  isSitesEnabled: boolean,
  selectedPersona: Personas,
  sitesControl: AbstractControl | null,
) {
  if (!isSitesEnabled || !sitesControl) {
    return;
  }

  if (!selectedPersonaAllowsSites(selectedPersona)) {
    sitesControl.clearValidators();
    sitesControl.setValue(null);
  } else {
    sitesControl.addValidators([Validators.required]);
  }

  sitesControl.updateValueAndValidity();
}

export function selectedPersonaAllowsSites(selectedPersona?: Personas): boolean {
  return !selectedPersona || !personasWithNoSites.includes(selectedPersona);
}
export function shouldShowSiteName(user: User, siteId?: string): boolean {
  const userIsNotGuestAndBelongsToSameInstitutionAsSite = !!user.institution?.sites?.find(
    (site) => site._id === siteId,
  );
  return (
    !!siteId &&
    institutionHasSites(user.institution) &&
    userIsNotGuestAndBelongsToSameInstitutionAsSite
  );
}

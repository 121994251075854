import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ObjectId } from 'bson';
import { Context, encodeContext } from 'src/app/models/context';
import { Message, User, UserInfo } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';

import { cloneDeep } from 'lodash-es';
import { Observable, merge } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TypedFragment } from 'src/app/common/typed-fragment/typed-fragment';
import { contextEquals } from 'src/app/common/utils/messaging';
import { FragmentCollection } from 'src/app/content/course/create/create.model';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageAction } from 'src/app/models/messaging';
import { EmptyCheckPipe } from 'src/app/pipes/empty-check.pipe';
import { FragmentUploadInprogressCheckPipe } from 'src/app/pipes/fragment-upload-inprogress-check.pipe';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { TelemetryService } from 'src/app/services/telemetry.service';
import { SpaceRepository } from 'src/app/state/space.repository';
import { EditorTypes } from 'src/app/ui/advanced-editor/advanced-text-fragment.component';
import { DeviceAndBrowserDetectorService } from 'src/app/services/device-and-browser-detector.service';

@UntilDestroy()
@Component({
  selector: 'app-create-comment',
  templateUrl: './create-comment.component.html',
  styleUrls: ['./create-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateCommentComponent implements OnInit, OnChanges {
  @Input() context?: Context;
  @Input() type = 'comment';
  @Input() enableControls = true;
  @Input() emitTyping = true;
  @Input() maxHeight;
  @ViewChild('advancedEditor') editor;
  @Output() focused = new EventEmitter();
  @Output() blurred = new EventEmitter();
  @Input() autoFocus = true;
  @Input() privateChat = false;
  @Input() popUpMessage = false;
  @Input() isClickEnabled = true;
  @Input() public parentMessage?: Message;

  public isMobileView: boolean;
  public focusToEnd = 0;
  public maxPreviewLength = 80;

  userEmailIdMap?: Map<string, string>;
  collection?: FragmentCollection;
  fragments: TypedFragment[] = [];
  chatIdentifier = '';
  user?: User;
  allUserInfo$?: Observable<UserInfo[]>;
  // Maps encoded context to unsent editor content.
  messageDrafts = new Map<string, FragmentCollection>();
  editorTypes = EditorTypes;

  constructor(
    private userService: UserService,
    private messagingService: MessagingService,
    private analyticsService: AnalyticsService,
    private spaceRepo: SpaceRepository,
    private telemetry: TelemetryService,
    private messengerService: MessengerService,
    private deviceAndBrowserDetectorService: DeviceAndBrowserDetectorService,
  ) {
    this.allUserInfo$ = this.userService.allUsers.pipe(
      filter((allUserInfo): allUserInfo is UserInfo[] => allUserInfo !== null),
    );
    this.isMobileView = this.deviceAndBrowserDetectorService.isMobile();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.context?.currentValue && this.user && this.context) {
      this.chatIdentifier = encodeContext(this.context);
    }

    if (changes.parentMessage && changes.parentMessage.currentValue) {
      this.focusToEnd += 1;
      this.editor.focusToEnd = this.focusToEnd;
    }

    /* Update the current editor content when the user switches between
     * different conversations, saving and restoring unsent content if it
     * exists. Content only persists for the lifespan of the component.
     */
    if (
      this.collection &&
      changes.context &&
      !changes.context.firstChange &&
      !contextEquals({ context: changes.context.previousValue })({
        context: changes.context.currentValue,
      })
    ) {
      // Save editor content for the previous conversation.
      this.messageDrafts.set(encodeContext(changes.context.previousValue), this.collection);

      // Retrieve saved editor content for the current conversation.
      const existingDraft = this.messageDrafts.get(encodeContext(changes.context.currentValue));

      if (existingDraft !== undefined) {
        // Restore saved editor content if it exists.
        this.collection = existingDraft;
        this.fragments = this.collection.fragments;
      } else {
        // Otherwise, clear editor content.
        this.initializeContent();
      }
    }
  }

  ngOnInit(): void {
    this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res && this.context) {
        this.user = res.user;
        this.chatIdentifier = encodeContext(this.context);
      }
    });

    this.initializeContent();

    merge(
      this.messengerService.deletedPrivateMessageId$,
      this.messengerService.deletedSpaceLevelMessageId$,
      this.messengerService.allSpaceLevelMessageDeleted$,
    )
      .pipe(untilDestroyed(this))
      .subscribe((deleteMessageEvent) => {
        if (this.parentMessage) {
          if (
            deleteMessageEvent === MessageAction.DeleteAll ||
            deleteMessageEvent === this.parentMessage._id
          ) {
            this.cancelReply();
          }
        }
      });
  }

  private initializeContent(): void {
    this.collection = FragmentCollection.FromComment(this);
    this.fragments = this.collection.fragments;
  }

  updateCollection(coll: FragmentCollection): void {
    this.fragments = cloneDeep(coll.fragments);
  }

  public setMessageContentAndMentions(message: Partial<Message>): Partial<Message> {
    if (!this.collection) {
      return message;
    }
    for (const fragment of this.collection.fragments) {
      if (fragment.fragment?.data) {
        // if comments has link url that contains \_ then replace \_ with _
        if (/https?:\/\//.test(fragment.fragment.data)) {
          fragment.fragment.data = fragment.fragment.data.replace(/\\_/g, '_');
        }
        fragment.fragment.data = fragment.fragment.data.trim();
        message?.content?.push(fragment.fragment);
      }
      /**
       * if comments has user-mention tag create dummy div and assign pass comment as inner html
       * then just take data-id attribute which stands for user id and push to mentions arrray
       */
      if (
        fragment.fragment &&
        fragment.fragment.data &&
        fragment.fragment.data.includes('<user-mention')
      ) {
        const fragmentDocument = new DOMParser().parseFromString(
          fragment.fragment.data,
          'text/html',
        );
        const userMentions = fragmentDocument.getElementsByTagName('user-mention');
        const userMentionIds = Array.from(userMentions).reduce(
          (accumulator: string[], userMention) => {
            const userId = userMention.getAttribute('data-id');
            return userId ? [userId, ...accumulator] : accumulator;
          },
          [],
        );
        message.mentions = userMentionIds;
      }
    }
    return message;
  }

  async addComment(): Promise<void> {
    this.focusToEnd += 0;
    this.editor.focusToEnd = this.focusToEnd;
    if (this.isEmpty()) {
      return;
    }
    /* TODO: Create a separate interface for outbound messages, since they don't
     * have all of the same properties as messages stored in the database.
     */
    let message: Partial<Message> = {};
    message.content = [];
    if (this.collection) {
      message = this.setMessageContentAndMentions(message);
    }

    if (this.context && this.collection) {
      message.context = this.context;
      if (this.analyticsService.getCurrentSessionId()) {
        message.currentSession = this.analyticsService.getCurrentSessionId();
      }
      if (this.spaceRepo.activeSpaceCurrentRoomUid) {
        message.currentBreakoutRoomUid = this.spaceRepo.activeSpaceCurrentRoomUid;
      }
      if (this.spaceRepo.activeSpace && !this.privateChat) {
        message.context.session = this.spaceRepo.activeSpace._id;
      } else {
        message.context.session = undefined;
      }
      const contentLength = message?.content?.length;
      if ((contentLength && contentLength > 0) || this.type === 'message') {
        this.collection.fragments = [];
        this.collection.addTextFragment();
        /* This will never be a full Message type, only a Partial<Message> type.
         * See TODO item above.
         */
        const firstAttemptTimestamp = `${new Date(Date.now())}`;
        const firstAttemptTimestampISO = `${new Date(Date.now()).toISOString()}`;
        message._id = new ObjectId().toHexString();
        message.content = message?.content?.filter((data) => !data.data.match(/^(\n|\s)*$/));
        message.firstAttemptTimestamp = firstAttemptTimestampISO;
        message.createdAt = message.updatedAt = firstAttemptTimestampISO;
        message.author = this.userService.user.value?.user as unknown as UserInfo;
        if (this.parentMessage) {
          message.parent = this.parentMessage._id;
        }
        this.updatePendingMessage(<Message>message, firstAttemptTimestamp, false);
        this.scrollToRecent();
        this.userService
          .createComment(message as Message, this.spaceRepo.activeSpace?._id)
          .pipe(untilDestroyed(this))
          .subscribe({
            next: () => {
              this.messagingService.handleLocalMessageSentSuccessfully(message as Message);
              this.logChatSentToFullStory();
              this.scrollToRecent();
            },
            error: () => {
              this.updatePendingMessage(<Message>message, firstAttemptTimestamp, true);
            },
          });
        this.cancelReply();
      }
      if (this.type === 'message') {
        this.editor.showFocus.push(true);
      }
    }
  }

  public cancelReply() {
    this.parentMessage = undefined;
  }

  public updatePendingMessage(message: Message, timestamp: string, failed: boolean) {
    const firstAttemptMessage = {
      ...message,
      firstAttemptTimestamp: timestamp,
      failed,
      author: { ...this.user } as unknown as UserInfo,
    };

    this.messagingService.addMessageToPendingMessagesArray(firstAttemptMessage);
  }

  isEmpty(): boolean {
    const hasValidData = (tf: TypedFragment) =>
      tf.fragment?.data && tf.fragment?.data !== '' && tf.fragment?.data !== '\n';
    const hasData = this.collection?.fragments?.some((tf) => hasValidData(tf));
    return !hasData;
  }

  sendMessageFromEnterKey(event: KeyboardEvent): void {
    const CheckUploadInprogress = new FragmentUploadInprogressCheckPipe();
    const EmptyCheck = new EmptyCheckPipe();
    if (document.activeElement?.id === 'editor-url-box') {
      return;
    }
    if (!event.shiftKey) {
      event.stopPropagation();
      event.preventDefault();
      if (
        CheckUploadInprogress.transform(this.collection?.fragments) ||
        EmptyCheck.transform(this.collection?.fragments)
      ) {
        return;
      }
      this.addComment();
    }
  }

  public scrollToRecent(): void {
    const chatList = document.getElementById('message-list-container');
    if (chatList) {
      chatList?.scrollTo({ behavior: 'smooth', top: 500 });
    }
  }

  logChatSentToFullStory() {
    this.telemetry.event('chat_message_sent', {});
  }
}

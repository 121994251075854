<div class="pdf-board-strategy-container">
  <p class="pdf-board-strategy-header">
    {{
      canSplitPDF
        ? selectedPagesCount === 1
          ? 'You are importing ' + selectedPagesCount + ' page.'
          : ('You are importing ' + selectedPagesCount + ' pages.' | translate)
        : ('Import file' | translate)
    }}
  </p>
  <p class="pdf-board-strategy-body mt-2">
    {{ 'How should we import your file?' | translate }}
  </p>

  <div *ngIf="!canSplitPDF" class="can-not-split-pages-error">
    <mat-icon class="material-icons">error</mat-icon>
    <span>
      {{
        'Sorry, this file does not support page splitting. You can not select pages or split pages across multiple boards.'
          | translate
      }}
      <a
        target="_blank"
        href="https://helpdesk.pencilspaces.com/en/articles/9170069-why-can-i-not-import-pages-from-my-document"
        >{{ 'Learn more' | translate }}</a
      >
    </span>
  </div>

  <app-radio-selection
    class="pdf-board-strategy-radio-selection"
    [initialSelectedItemIndex]="Object.keys(PDFBoardStrategy).indexOf(selectedStrategy)"
    [selectionItems]="strategiesSelectionItems"
    (itemSelected)="itemSelected($event)"
  >
  </app-radio-selection>
  <div class="upload-file-bottom-bar">
    <app-pencil-button
      class="pencil-button bottom-bar-button bottom-bar-button-bordered light medium"
      [label]="'Back'"
      [icon]="'arrow_back'"
      [iconConfig]="{ type: IconTypes.matIcon }"
      (clickEvent)="goBackToPreview()"
      data-name="upload-pdf-back"
    >
    </app-pencil-button>
    <div style="flex-grow: 1"></div>
    <app-pencil-button
      class="pencil-button bottom-bar-button primary medium"
      [label]="'Import Pages (' + selectedPagesCount + ')' | translate"
      [icon]="isImportButtonHovered ? 'import_blue' : 'import_light'"
      [iconConfig]="{ type: IconTypes.svgIcon }"
      (mouseover)="isImportButtonHovered = true"
      (mouseleave)="isImportButtonHovered = false"
      (clickEvent)="submitStrategy()"
      data-name="upload-pdf-import-pages"
    >
    </app-pencil-button>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlHost',
})
export class UrlHostPipe implements PipeTransform {
  transform(value: string): unknown {
    return new URL(value).hostname;
  }
}

<div *ngIf="direction === PanelDirectionType.LEFT" style="height: 100%">
  <div
    id="lhsQuestions"
    class="h-100"
    *ngIf="
      currentPanelView === panelViewType.lhsQuestions ||
      (alreadyLoadedPanel$[panelViewType.lhsQuestions] | async)
    "
    [hidden]="currentPanelView !== panelViewType.lhsQuestions"
  >
    <app-lhs-view
      *ngIf="courses"
      [courses]="courses"
      [user]="user"
      [isHiddenAtSpaces]="currentPanelView !== panelViewType.lhsQuestions"
      (changePanelView)="changeView($event)"
    >
    </app-lhs-view>
  </div>
  <div
    id="upload-file"
    class="h-100"
    *ngIf="
      currentPanelView === panelViewType.uploadFile ||
      (alreadyLoadedPanel$[panelViewType.uploadFile] | async)
    "
    [hidden]="currentPanelView !== panelViewType.uploadFile"
  >
    <app-upload-panel (closeSubmit)="changeView()"> </app-upload-panel>
  </div>
  <div
    id="chat"
    class="h-100"
    *ngIf="
      currentPanelView === panelViewType.chat || (alreadyLoadedPanel$[panelViewType.chat] | async)
    "
    [hidden]="currentPanelView !== panelViewType.chat"
  >
    <app-chat-view
      [enableMessaging]="canSendMessage$ | async"
      (changePanelView)="changeView($event)"
    ></app-chat-view>
  </div>
  <div
    *ngIf="
      !isMobile &&
      (currentPanelView === panelViewType.appSelector ||
        (alreadyLoadedPanel$[panelViewType.appSelector] | async))
    "
    id="appSelector"
    class="h-100"
    [hidden]="currentPanelView !== panelViewType.appSelector"
  >
    <app-app-selector (changePanelView)="changeView($event)" [isHost]="isHost"></app-app-selector>
  </div>
  <div
    id="mathManipulatives"
    class="h-100"
    *ngIf="
      currentPanelView === panelViewType.mathManipulatives ||
      (alreadyLoadedPanel$[panelViewType.mathManipulatives] | async)
    "
    [hidden]="currentPanelView !== panelViewType.mathManipulatives"
  >
    <app-math-manipulatives (changePanelView)="changeView($event)"></app-math-manipulatives>
  </div>

  <div
    id="simulations"
    class="h-100"
    *ngIf="
      currentPanelView === panelViewType.phetSimulations ||
      (alreadyLoadedPanel$[panelViewType.phetSimulations] | async)
    "
    [hidden]="currentPanelView !== panelViewType.phetSimulations"
  >
    <app-library-app-subcategory
      (changePanelView)="changeView($event)"
      [parentCategory]="CollaborativeApps.PHET_SIMULATION"
      [isHostOnly]="true"
      [isHost]="isHost"
      [isVisible]="currentPanelView === panelViewType.phetSimulations"
    ></app-library-app-subcategory>
  </div>

  <div
    id="bioDigitalModels"
    class="h-100"
    *ngIf="
      currentPanelView === panelViewType.bioDigitalModels ||
      (alreadyLoadedPanel$[panelViewType.bioDigitalModels] | async)
    "
    [hidden]="currentPanelView !== panelViewType.bioDigitalModels"
  >
    <app-library-app-subcategory
      (changePanelView)="changeView($event)"
      [parentCategory]="CollaborativeApps.HUMAN_ANATOMICAL_MODELS"
      [isHostOnly]="true"
      [isHost]="isHost"
      [isVisible]="currentPanelView === panelViewType.bioDigitalModels"
    ></app-library-app-subcategory>
  </div>

  <div
    class="h-100 tabs w-100"
    *ngIf="
      currentPanelView === panelViewType.tabs || (alreadyLoadedPanel$[panelViewType.tabs] | async)
    "
    [hidden]="currentPanelView !== panelViewType.tabs"
  >
    <app-wb-tabs
      [hidden]="(tabsVisible$ | async) === false"
      [hasParallelSessions]="true"
      [visible]="(tabsVisible$ | async) === true"
      (closeTabs)="changeView(undefined)"
    >
    </app-wb-tabs>
  </div>
  <div
    class="h-100"
    *ngIf="
      currentPanelView === panelViewType.comments ||
      (alreadyLoadedPanel$[panelViewType.comments] | async)
    "
    [hidden]="currentPanelView !== panelViewType.comments"
  >
    <app-space-comments-view> </app-space-comments-view>
  </div>
</div>

<div *ngIf="direction === PanelDirectionType.RIGHT" style="height: 100%">
  <div
    class="participants-panel"
    [hidden]="currentPanelView !== panelViewType.participantsManager"
    *ngIf="
      currentPanelView === panelViewType.participantsManager ||
      (alreadyLoadedPanel$[panelViewType.participantsManager] | async)
    "
  >
    <app-participants-manager></app-participants-manager>
  </div>

  <div
    class="h-100"
    [hidden]="currentPanelView !== panelViewType.sparky"
    *ngIf="
      currentPanelView === panelViewType.sparky ||
      (alreadyLoadedPanel$[panelViewType.sparky] | async)
    "
  >
    <app-astra-chat></app-astra-chat>
  </div>
</div>

<span class="position-relative notifications-icon-cont d-sm-flex d-none align-items-center">
  <i
    class="notifications-icon"
    [ngClass]="{
      'notifications-icon--open': isMenuOpened,
      'notifications-new': notificationsCount > 0,
      'material-icons-outlined': notificationsCount === 0,
      'material-icons': notificationsCount > 0,
      'pcl-notifications-notifications-menu': true
    }"
    [class.active]="isMenuOpened"
    (click)="toggleMenu(true)"
    >notifications</i
  >
  <svg
    *ngIf="isMenuOpened"
    class="notifications-menu-caret"
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="17"
    viewBox="0 0 26 17"
    fill="none"
  >
    <g clip-path="url(#clip0)">
      <path
        [ngClass]="{ 'notifications-menu-caret--loading': loading }"
        d="M15.9123 4.52872L24.4372 15.8184C26.1784 18.1243 24.5335 21.4275 21.644 21.4275H4.5942C1.70478 21.4275 0.0598779 18.1243 1.80106 15.8184L10.326 4.52872C11.7263 2.67421 14.5119 2.67421 15.9123 4.52872Z"
        fill="white"
        stroke="#E0E0E0"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="26" height="17" fill="white" />
      </clipPath>
    </defs>
  </svg>
</span>
<span
  class="position-relative notifications-icon-cont--mobile d-flex d-sm-none align-items-center"
  (click)="toggleMenu(true)"
>
  <i
    *ngIf="isMobileView"
    class="cpl-sm-24 cpl-8 cpr-8 notifications-icon"
    [ngClass]="{
      'notifications-icon--open': isMenuOpened,
      'notifications-new': notificationsCount > 0,
      'material-icons-outlined': notificationsCount === 0,
      'material-icons': notificationsCount > 0
    }"
    [class.active]="isMenuOpened"
    >notifications</i
  >
  <i
    *ngIf="!isMobileView"
    class="cpl-24 cpr-8 notifications-icon"
    [ngClass]="{
      'notifications-icon--open': isMenuOpened,
      'notifications-new': notificationsCount > 0,
      'material-icons-outlined': notificationsCount === 0,
      'material-icons': notificationsCount > 0
    }"
    [class.active]="isMenuOpened"
    [routerLink]="['/notifications']"
    >notifications</i
  >
</span>
<div
  *ngIf="isMenuOpened"
  class="notifications-menu-cont"
  (mousewheel)="mouseWheel($event)"
  (touchstart)="touchStart($event)"
  (touchend)="touchEnd($event)"
  (touchmove)="touchMove($event)"
>
  <div
    class="notifications-menu"
    [style.maxHeight]="
      isMobileView ? 'calc(100vh - 45px)' : 'calc(100vh - 70px - ' + marginBottom + 'px)'
    "
    #notificationsMenu
  >
    <header>
      <div class="d-flex align-items-center justify-content-between">
        <h2 class="notifications-menu-title cmb-8">{{ 'Notifications' | translate }}</h2>
        <span
          class="material-icons notifications-menu-more"
          [matMenuTriggerFor]="notificationSubMenu"
          [class.active]="isSubmenuOpened"
          (menuOpened)="isSubmenuOpened = true"
          (menuClosed)="isSubmenuOpened = false"
          >more_horiz</span
        >
      </div>
    </header>
    <mat-tab-group
      [disablePagination]="true"
      [animationDuration]="0"
      (selectedTabChange)="tabChange($event)"
    >
      <mat-tab>
        <ng-template mat-tab-label>
          <button
            class="notifications-tab-btn"
            [ngClass]="{ 'notifications-tab-btn--active': tab === 'all' }"
            mat-button
          >
            All
          </button>
        </ng-template>
        <div *ngIf="filteredNotifications.all.length">
          <h3
            *ngIf="
              filteredNotifications.all[0]?.timeLabel &&
              filteredNotifications.all[0]?.timeLabel === 'Today'
            "
            class="notifications-cat"
          >
            {{ 'New' | translate }}
          </h3>
          <ng-container *ngFor="let n of filteredNotifications.all; let i = index">
            <h3 *ngIf="n.timeLabel && n.earlier" class="notifications-cat notifications-cat--gray">
              {{ 'Earlier' | translate }}
            </h3>
            <app-notification-menu-item
              [notification]="n"
              [lastViewed]="user?.last_notifications_viewed_timestamp"
              [isClickEnabled]="isClickEnabled"
              class="d-block"
              (itemClicked)="toggleMenu()"
            ></app-notification-menu-item>
          </ng-container>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <button
            class="notifications-tab-btn"
            [ngClass]="{ 'notifications-tab-btn--active': tab === 'unread' }"
            mat-button
          >
            Unread
          </button>
        </ng-template>
        <div *ngIf="filteredNotifications.unread.length">
          <h3
            *ngIf="
              filteredNotifications.unread[0]?.timeLabel &&
              filteredNotifications.unread[0]?.timeLabel === 'Today'
            "
            class="notifications-cat"
          >
            {{ 'New' | translate }}
          </h3>
          <ng-container *ngFor="let n of filteredNotifications.unread; let i = index">
            <h3 *ngIf="n.timeLabel && n.earlier" class="notifications-cat notifications-cat--gray">
              {{ 'Earlier' | translate }}
            </h3>
            <app-notification-menu-item
              [notification]="n"
              [lastViewed]="user?.last_notifications_viewed_timestamp"
              [isClickEnabled]="isClickEnabled"
              class="d-block"
              (closeNotification)="close($event)"
            ></app-notification-menu-item>
          </ng-container>
        </div>
        <p class="cpy-8 cpx-8" *ngIf="!filteredNotifications.unread.length">
          {{ 'No new notifications.' | translate }}
        </p>
      </mat-tab>
    </mat-tab-group>
    <app-loading [loading]="loading"></app-loading>
  </div>
</div>
<div *ngIf="isMenuOpened" class="notifications-backdrop" (click)="toggleMenu()"></div>
<mat-menu #notificationSubMenu="matMenu">
  <button mat-menu-item (click)="openNotificationTab()">
    {{ 'Open notification tab' | translate }}
  </button>
</mat-menu>

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

import { extractEmails } from 'src/app/common/utils/common-util';
import { getEmailValidationErrors } from 'src/app/common/utils/form-validators';
import { User } from 'src/app/models/user';
import { TelemetryService } from 'src/app/services/telemetry.service';
import { UserService } from 'src/app/services/user.service';

@UntilDestroy()
@Component({
  selector: 'app-email-search',
  templateUrl: './email-search.component.html',
  styleUrls: ['./email-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailSearchComponent implements OnInit {
  @Input() isInvalid = false;
  @Input() emails: string[] = [];
  @Input() suggestUsers = false;
  @Input() showParsedEmailChips = true;
  @Input() placeholder = '';
  @Input() addableUsersSubject?: BehaviorSubject<User[]>;

  @Output() emitEmails = new EventEmitter();
  @Output() emitUserselected = new EventEmitter();
  @Output() emitBlur = new EventEmitter();

  @ViewChild('userSearch') userSearch!: ElementRef;

  searchQuery = '';
  isSearchFocused = false;
  isSearchHovered = false;
  validEmailInput = false;
  invalidEmailMap: { [key: string]: boolean } = {};
  currentUser?: User;
  selectedEmailFromSuggestion?: string;

  // cached users to search for when adding to event
  filteredUsers: User[] = [];
  addableUsers: User[] = [];

  constructor(private userService: UserService, private telemetry: TelemetryService) {}

  ngOnInit(): void {
    this.userService.user.pipe(untilDestroyed(this)).subscribe((userData) => {
      this.currentUser = userData?.user;
    });
    if (this.addableUsersSubject) {
      this.addableUsersSubject.pipe(untilDestroyed(this)).subscribe((users) => {
        this.addableUsers = users;
        this.onInputChange();
      });
    }
  }

  onFocusSearch(): void {
    this.isSearchFocused = true;
  }

  onBlurSearch(): void {
    this.isSearchFocused = false;
    this.emitBlur.emit();
  }

  onPaste($event: ClipboardEvent): void {
    const pastedText = $event.clipboardData?.getData('text') || '';
    this.searchQuery += pastedText;
    this.onInputChange();
    if (!this.processTypedEmail(this.searchQuery)) {
      this.searchQuery = '';
    }

    $event.preventDefault();
  }

  onInputChange(): void {
    this.validEmailInput = !getEmailValidationErrors(this.searchQuery);
    this.filteredUsers = this.addableUsers.filter(
      (u) =>
        u?.name?.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        u?.email?.toLowerCase().includes(this.searchQuery.toLowerCase()),
    );
  }

  onKeyPress($event: KeyboardEvent): void {
    const pressedKey = $event.key;
    if (pressedKey === 'Enter' || pressedKey === ';' || pressedKey === ',') {
      if (!this.processTypedEmail(this.searchQuery)) {
        this.searchQuery = '';
      }
      $event.preventDefault();
    }
  }

  emitChanges() {
    this.emitEmails.emit({
      emails: this.emails,
      invalidEmails: Object.keys(this.invalidEmailMap),
    });
  }

  processTypedEmail(inputEmail: string): boolean {
    if (inputEmail.length && !this.emails.includes(inputEmail)) {
      const { emails, isMultiple, invalidEmails } = extractEmails(inputEmail, this.telemetry);
      if (isMultiple) {
        invalidEmails.forEach((email) => (this.invalidEmailMap[email] = true));
        this.emails = [...new Set([...this.emails, ...emails, ...invalidEmails])];
      } else {
        if (!this.validEmailInput) {
          this.invalidEmailMap[inputEmail] = true;
        }
        this.emails = [...new Set([...this.emails, inputEmail])];
      }
      this.emitChanges();
      this.userSearch.nativeElement.focus();
      return false;
    }
    return true;
  }

  onRemoveUser(emailToRemove: string): void {
    this.emails = this.emails.filter((email) => email !== emailToRemove);
    delete this.invalidEmailMap[emailToRemove];
    this.emitChanges();
  }

  addUser(email: string) {
    this.searchQuery = '';
    this.emails.push(email);
    this.emitChanges();
  }

  selectUser(user: User): void {
    this.searchQuery = '';
    this.emitUserselected.emit(user);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import * as shortid from 'shortid';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Observable } from 'rxjs/internal/Observable';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  pairwise,
} from 'rxjs';
import * as Sentry from '@sentry/browser';
import { IconTypes } from 'src/app/standalones/components/pencil-icon/pencil-icon.component';
import { filterNil } from '@ngneat/elf';
import { User } from '../../../models/user';
import { IResource, ResourceItemModel } from '../../../models/resource';
import { ResourceFilterParamsEnum } from '../../../models/params';
import { UserService } from '../../../services/user.service';
import { FLAGS, FlagsService } from '../../../services/flags.service';
import { PicassoService } from '../../../services/picasso.service';
import { ImageSearchService } from '../../../services/image-search.service';
import {
  getAcceptedLibreOfficePickerTypes,
  UploadFileService,
} from '../../../services/upload-file.service';
import { UploadFileComputerService } from '../../../services/upload-file-computer.service';
import { b64toBlob } from '../../../common/utils/common-util';
import { Feature } from '../../../services/acl.service';
import { PanelView } from '../../../sessions/panel/panel.component';
import {
  SessionSharedDataService,
  SessionView,
} from '../../../services/session-shared-data.service';
import { CapabilityTierService } from '../../../services/capability-tier.service';
import { Capability } from '../../../models/capability';

interface ResourceTab {
  icon: string;
  title: string;
}

export enum UPLOAD_METHOD {
  PENCIL_LIBRARY,
  URL,
  COMPUTER,
}

export interface UploadedFile {
  uploadMethod: UPLOAD_METHOD;
  files: (ComputerFile | IResource | string)[];
}

export interface ComputerFile {
  file: File;
  type: ResourceItemModel;
}

export enum Libraries {
  COMPUTER = 'Computer',
  CAMERA = 'Camera',
  PENCIL_FILES = 'Pencil Files',
  YOUTUBE = 'Youtube',
  VIMEO = 'Vimeo',
  PHET = 'PHET URL',
  PICASSO = 'Picasso',
  IMAGES = 'Search Images',
}

export const UPLOAD_LIMITS = {
  DOCUMENT: {
    //  Size Limit in MB
    SIZE: 15,
  },
};

enum UploadComputerStage {
  SelectFile,
  PDFFlow,
}

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UploadFileComputerService],
})
@UntilDestroy()
export class UploadComponent implements OnInit {
  @ViewChild('addUrlInput') addUrlInput!: ElementRef;
  @ViewChild('contextBox') contextBox!: ElementRef;
  @ViewChild('imagesList') searchedImagesList!: ElementRef;
  @ViewChild('libContentContainer') libContentContainer?: ElementRef;

  @Input() data!: {
    title: string;
    libraries: Libraries[];
    type: ResourceItemModel[];
    newPdfFlow?: boolean;
  };
  @Input() handleDataInternally = true;
  @Output() closeSubmit = new EventEmitter<UploadedFile | undefined>();

  readonly file$ = new BehaviorSubject<UploadedFile | undefined>(undefined);
  readonly isPdfFilesSelected$ = new BehaviorSubject<boolean>(false);
  readonly isLoadingPdfFromPencilLib$ = new BehaviorSubject<boolean>(false);
  readonly currentUploadStage$ = new BehaviorSubject<UploadComputerStage>(
    UploadComputerStage.SelectFile,
  );

  libraries: ResourceTab[] = [];
  selectedLib = '';
  LibrariesTitles = Libraries;
  originalSupportedDocumentTypes = '';
  uploadDescriptor = 'pdf';
  computerUploadLibraryItem = { icon: 'laptop_upload', title: Libraries.COMPUTER };
  imageSrc?: string | ArrayBuffer;
  private trigger: Subject<void> = new Subject<void>();
  public multipleWebcamsAvailable = false;
  transform: ImageTransform = {};
  showCropper = false;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  containWithinAspectRatio = false;
  loading = false;
  videoOptions = {
    width: {
      min: 1280,
    },
    height: {
      min: 720,
    },
  };
  cameraActive = false;
  user?: User;

  // Enum of Query types For html templates.
  ResourceFilterParamsType = ResourceFilterParamsEnum;

  pdfUploadMaxSizeTrigger = false;
  autoUpload = false;
  pdfUploadSizeLimitTooltip = '';
  pdfUploadSizeLimitError = '';

  searchImagesInput = '';
  currentImagesList$ = new BehaviorSubject<any[]>([]);
  isNoImagesFoundForSearch = false;
  currentSelectedImage = '';

  selectFileText?: string;

  supportedDocumentTypes: string = '';

  protected readonly UploadComputerStage = UploadComputerStage;
  protected readonly IconTypes = IconTypes;
  protected readonly PanelView = PanelView;

  constructor(
    private userService: UserService,
    private flagsService: FlagsService,
    private toastrService: ToastrService,
    private picassoService: PicassoService,
    private imageSearchService: ImageSearchService,
    private translateService: TranslateService,
    private uploadFileService: UploadFileService,
    private uploadFileComputerService: UploadFileComputerService,
    public sharedDataService: SessionSharedDataService,
    private cdr: ChangeDetectorRef,
    private capabilityTierService: CapabilityTierService,
  ) {
    this.originalSupportedDocumentTypes = flagsService.isFlagEnabled(
      FLAGS.ENABLE_FILE_PDF_CONVERSION,
    )
      ? `application/pdf, ${getAcceptedLibreOfficePickerTypes()}`
      : 'application/pdf';
    this.uploadDescriptor = `Choose an image, video, or ${
      flagsService.isFlagEnabled(FLAGS.ENABLE_FILE_PDF_CONVERSION) ? 'document' : 'PDF'
    }`;

    this.pdfUploadSizeLimitTooltip = [
      this.translateService.instant('Cannot upload PDF with more than'),
      UPLOAD_LIMITS.DOCUMENT.SIZE,
      this.translateService.instant('MB'),
    ].join(' ');
    this.pdfUploadSizeLimitError = [
      this.translateService.instant(
        'To ensure you have the best experience on Spaces, you cannot import PDFs with more than',
      ),
      UPLOAD_LIMITS.DOCUMENT.SIZE,
      this.translateService.instant('MB'),
    ].join(' ');
  }

  async ngOnInit(): Promise<void> {
    WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
      this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
    });

    this.supportedDocumentTypes = this.getSupportedDocumentTypes();

    this.selectFileText = this.getSelectFileText(this.data.type);

    this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res && res.user) {
        this.user = res.user;
        this.setupLibraries();
        this.selectedLib = this.data.type && this.libraries.length ? this.libraries[0].title : '';
      }
    });

    if (this.data['pdfFile']) {
      await this.readFile(this.data['pdfFile']);
    }

    // on panel close, reset the panel to the first item
    // this also helps in destroying the webcam element when the panel closes
    this.sharedDataService.leftPanelView
      .pipe(
        untilDestroyed(this),
        pairwise(),
        filter(
          ([previous, current]) =>
            !!previous &&
            previous?.panelView === PanelView.uploadFile &&
            current?.panelView === undefined,
        ),
      )
      .subscribe(() => this.selectLib(this.computerUploadLibraryItem));

    // The upload panel doesn't make sense to be opened when in gallery view
    // This also helps with UI responsiveness, since the upload panel is too large
    combineLatest([
      this.sharedDataService.leftPanelView,
      this.sharedDataService.sessionView.current$,
    ])
      .pipe(
        untilDestroyed(this),
        filter(
          ([leftPanelView, sessionView]) =>
            (leftPanelView?.panelView === PanelView.uploadFile &&
              sessionView.view === SessionView.GALLERY_VIEW) ||
            sessionView.view === SessionView.EXPANDED,
        ),
      )
      .subscribe(() => this.closeUpload());

    this.uploadFileService.pendingLeftPanelFile$
      .pipe(untilDestroyed(this), distinctUntilChanged(), filterNil())
      .subscribe((pendingFile) => this.readFile(pendingFile));
  }

  setupLibraries(): void {
    if (!this.data) {
      return;
    }
    for (const library of this.data.libraries) {
      switch (library) {
        case Libraries.COMPUTER:
          if (this.computerUploadEnabled()) {
            this.libraries.push(this.computerUploadLibraryItem);
          }
          break;
        case Libraries.CAMERA:
          if (
            this.flagsService.isFlagEnabled(FLAGS.CAPTURE_CAMERA_IMAGES) &&
            this.user?.enabled_features?.includes(Feature.upload_resource)
          ) {
            this.libraries.push({ icon: 'videocam_icon_upload', title: Libraries.CAMERA });
          }
          break;
        case Libraries.PENCIL_FILES:
          if (
            this.user?.enabled_features?.includes(Feature.view_resource) &&
            this.capabilityTierService.isCapabilityEnabled(Capability.PENCIL_FILES)
          ) {
            this.libraries.push({ icon: 'pencil_files_upload', title: Libraries.PENCIL_FILES });
          }
          break;
        case Libraries.YOUTUBE:
          this.libraries.push({ icon: 'youtube_upload', title: Libraries.YOUTUBE });
          break;
        case Libraries.VIMEO:
          this.libraries.push({ icon: 'vimeo_upload', title: Libraries.VIMEO });
          break;
        case Libraries.PHET:
          this.libraries.push({ icon: 'phet', title: Libraries.PHET });
          break;
        case Libraries.PICASSO:
          if (this.flagsService.isFlagEnabled(FLAGS.PICASSO)) {
            this.libraries.push({ icon: 'picasso_upload', title: Libraries.PICASSO });
          }
          break;
        case Libraries.IMAGES:
          this.libraries.push({ icon: 'search_images_upload', title: Libraries.IMAGES });
          break;
      }
    }
  }

  computerUploadEnabled() {
    if (!this.data || !this.user?.enabled_features) {
      return;
    }
    const imageFlag = this.flagsService.isFlagEnabled(FLAGS.WB_IMAGE_UPLOAD);
    const videoFlag = this.flagsService.isFlagEnabled(FLAGS.WB_VIDEO_UPLOAD);
    const pdfFlag = this.flagsService.isFlagEnabled(FLAGS.WB_PDF_UPLOAD);
    const canUpload =
      (this.data.type.includes(ResourceItemModel.IMAGE) && imageFlag) ||
      (this.data.type.includes(ResourceItemModel.VIDEO) && videoFlag) ||
      (this.data.type.includes(ResourceItemModel.DOCUMENT) && pdfFlag) ||
      (this.data.type.includes(ResourceItemModel.ANY) && imageFlag && videoFlag && pdfFlag);
    return this.user?.enabled_features?.includes(Feature.upload_resource) && canUpload;
  }

  selectLib(lib: ResourceTab): void {
    this.selectedLib = lib.title;
    this.resetComputerFlow();
  }

  imageCropped(event: ImageCroppedEvent): void {
    const regex = /data:image\/[^;]+;base64,([^ ]+)/;
    const found = event.base64?.match(regex);
    if (found && found.length === 2) {
      const blob = b64toBlob(found[1], 'image/jpeg');
      if (blob) {
        this.file = {
          uploadMethod: UPLOAD_METHOD.COMPUTER,
          files: [
            {
              file: new File([blob], `${shortid.generate()}.jpg`, { type: blob.type }),
              type: ResourceItemModel.IMAGE,
            },
          ],
        };
      }
    }
  }

  imageLoaded(): void {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions): void {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed(): void {
    throw new Error('Image Load failed');
  }

  rotateLeft(): void {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight(): void {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  /**
   * search for the image with the specified key/input
   * the service will make an API call to get the image
   */
  searchForImages(shouldScrollToTop = true): void {
    if (!this.searchImagesInput.trim()) {
      return;
    }

    this.isNoImagesFoundForSearch = false;

    this.imageSearchService
      .searchImages(this.searchImagesInput)
      .pipe(untilDestroyed(this))
      .subscribe((photos: any) => {
        // user searched for a word and not results found
        if (!photos?.length && shouldScrollToTop) {
          this.isNoImagesFoundForSearch = true;
          this.currentImagesList$.next([]);
          return;
        }

        if (photos) {
          if (shouldScrollToTop) {
            // scroll to the top
            this.searchedImagesList?.nativeElement.scrollIntoView({
              behavior: 'smooth',
            });

            this.currentImagesList$.next(photos);
          } else {
            // append photos to the end of the current list
            this.currentImagesList$.next([...this.currentImagesList$.getValue(), ...photos]);
          }
        }
      });
  }

  /**
   * will fetch the image and get the blob
   * and use the data for the current file to use it for loading the image
   * @param stickerImg
   */
  async loadSelectedImage(stickerImg: string): Promise<void> {
    this.currentSelectedImage = stickerImg;
    const response = await fetch(this.currentSelectedImage);
    const data = await response.blob();
    if (data) {
      this.file = {
        uploadMethod: UPLOAD_METHOD.COMPUTER,
        files: [
          {
            file: new File([data], `${shortid.generate()}.png`, { type: data.type }),
            type: ResourceItemModel.IMAGE,
          },
        ],
      };
    }
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal(): void {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical(): void {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  resetImage(): void {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut(): void {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn(): void {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  toggleContainWithinAspectRatio(): void {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation(): void {
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public handleImage(webcamImage: WebcamImage): void {
    const blob = b64toBlob(webcamImage.imageAsBase64, 'image/jpeg');
    if (blob) {
      this.imageSrc = webcamImage.imageAsDataUrl;
      this.file = {
        uploadMethod: UPLOAD_METHOD.COMPUTER,
        files: [
          {
            file: new File([blob], `${shortid.generate()}.jpg`, { type: blob.type }),
            type: ResourceItemModel.IMAGE,
          },
        ],
      };
    }
  }

  public setCameraActive(): void {
    this.cameraActive = true;
  }

  async readFile(file: File): Promise<void> {
    this.uploadFileComputerService.resetFileUploadErrorState();
    // if a file was uploaded (via drag-n-drop) and the user wasn't on the computer tap (on search images, camera or pencil file)
    // we should open the correct tap in the upload panel for them
    if (this.selectedLib !== this.computerUploadLibraryItem.title) {
      this.selectLib(this.computerUploadLibraryItem);
    }
    const fileUploadError = this.uploadFileComputerService.getFileErrorIfExists(
      file,
      this.supportedDocumentTypes,
    );
    if (fileUploadError !== undefined) {
      this.uploadFileComputerService.fileUploadError$.next({
        fileName: file.name,
        error: fileUploadError,
      });
      this.returnToSelectFileStage();
      return;
    }

    this.uploadFileComputerService.rawFile = file;

    // Go with the PDF Flow
    if (file.type.startsWith('application/pdf') && this.data.newPdfFlow === true) {
      this.currentUploadStage$.next(UploadComputerStage.PDFFlow);
    }

    this.file = {
      uploadMethod: UPLOAD_METHOD.COMPUTER,
      files: [],
    };

    const type = this.uploadFileService.extractFileType(file);
    if (!this.data.type.includes(type) && !this.data.type.includes(ResourceItemModel.ANY)) {
      this.toastrService.error(
        this.translateService.instant('Uploaded file type is wrong!'),
        this.translateService.instant("Can't upload the file"),
      );
      this.file = undefined;
      return;
    }

    this.file.files.push({ file: file, type: type });

    if (!this.file.files.length) {
      this.file = undefined;
    }
  }

  selectedFileFromPencilLibrary(resources: IResource[]): void {
    if (!resources?.length) {
      return (this.file = undefined);
    }
    this.file = {
      uploadMethod: UPLOAD_METHOD.PENCIL_LIBRARY,
      files: [],
    };
    this.file.files.push(...resources);
    if (resources.length === 1 && resources[0]?.name?.endsWith('.pdf')) {
      this.isPdfFilesSelected$.next(true);
    } else {
      this.isPdfFilesSelected$.next(false);
    }
  }

  loadFileFromURL($event: { target: { value: string } }): void {
    this.file = {
      uploadMethod: UPLOAD_METHOD.URL,
      files: [$event.target?.value],
    };
  }

  // TODO Remove this function when vimeo problem is solved.
  handlePencilLibraryType(): ResourceItemModel | ResourceItemModel[] {
    if (this.data.type.includes(ResourceItemModel.ANY)) {
      return [ResourceItemModel.IMAGE, ResourceItemModel.DOCUMENT, ResourceItemModel.SIMULATION];
    }
    return this.data.type;
  }

  async loadFileFromPicasso(): Promise<void> {
    this.loading = true;
    const context = this.contextBox.nativeElement.value;

    try {
      const blob = await firstValueFrom(this.picassoService.generateAndFetch(context));
      if (blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.addEventListener('load', (e) => {
          this.loading = false;
          if (e.target?.result) {
            this.imageSrc = e.target.result;
          }
        });
        this.file = {
          uploadMethod: UPLOAD_METHOD.COMPUTER,
          files: [
            {
              file: new File([blob], `${shortid.generate()}.png`, { type: blob.type }),
              type: ResourceItemModel.IMAGE,
            },
          ],
        };
      }
    } catch (error) {
      this.loading = false;
      Sentry.captureException(error);
    }
  }

  handleAutoUpload(): void {
    if (this.autoUpload) {
      if (this.file) {
        this.file.files = this.file?.files.slice(-1);
      }

      this.closeUploadSubmit(this.file);
    }
  }

  closeUploadSubmit(uploadedFile?: UploadedFile) {
    this.uploadFileComputerService.resetFileUploadErrorState();
    this.closeSubmit.emit(uploadedFile);
  }

  /**
   * handle when user ended scrolled and reach 70% of the search results
   * it will check if there is a next page and it will make a request to get the next results
   */
  handleImageListScrollEnded(): void {
    if (this.imageSearchService.nextPage) {
      this.searchForImages(false);
    }
  }

  returnToSelectFileStage() {
    this.currentUploadStage$.next(UploadComputerStage.SelectFile);
  }

  resetComputerFlow() {
    this.imageSrc = undefined;
    this.returnToSelectFileStage();
    this.uploadFileComputerService.resetFileUploadErrorState();
    this.file = undefined;
    this.uploadFileComputerService.rawFile = undefined;
    this.searchImagesInput = '';
    this.currentImagesList$.next([]);
    this.isPdfFilesSelected$.next(false);

    if (this.addUrlInput) {
      // clear the value of the input when switching between libs
      this.addUrlInput.nativeElement.value = '';
    }
    this.cdr.detectChanges();

    // Scroll to top after switching libs
    requestAnimationFrame(() => {
      if (this.libContentContainer) {
        this.libContentContainer.nativeElement.scrollTop = 0;
      }
    });
  }

  private getSelectFileText(type: ResourceItemModel[]): string | undefined {
    if (type.length === 1) {
      if (type.includes(ResourceItemModel.IMAGE)) {
        return this.translateService.instant('Choose an image');
      }

      if (type.includes(ResourceItemModel.VIDEO)) {
        return this.translateService.instant('Select a video');
      }

      if (type.includes(ResourceItemModel.SIMULATION)) {
        return this.translateService.instant('Select a PHET');
      }

      if (type.includes(ResourceItemModel.DOCUMENT)) {
        return this.translateService.instant('Choose a PDF');
      }
    }
  }

  closeUpload() {
    this.sharedDataService.changeLeftPanelView.next(undefined);

    this.selectLib(this.computerUploadLibraryItem);

    this.closeUploadSubmit();
  }

  uploadClicked() {
    if (this.handleDataInternally) {
      if (this.file) {
        this.uploadFileService.handleUpload(this.file);
      }
      this.closeUploadSubmit();
    } else {
      this.closeUploadSubmit(this.file);
    }
  }

  async uploadOrNextClicked() {
    // next clicked
    if (await this.downloadedPdfFromPencilLibrary()) {
      return;
    }

    this.uploadClicked();
  }

  /*
   * PDFs from pencil library should be downloaded locally
   * to allow the user to select pages from them before adding them to the board
   * */
  async downloadedPdfFromPencilLibrary() {
    if (this.file?.files?.length !== 1) {
      return false;
    }
    const resource = this.file.files[0];
    const resourceName = (resource as IResource).name;
    const resourceUrl = (resource as IResource).url;
    if (!resourceUrl || !resourceName?.endsWith('.pdf')) {
      return false;
    }

    this.isLoadingPdfFromPencilLib$.next(true);
    this.selectLib(this.computerUploadLibraryItem);

    let file;
    try {
      const response = await fetch(resourceUrl);
      const uint8Array = await response.arrayBuffer();
      const blob = new Blob([uint8Array], { type: 'application/pdf' });
      file = new File([blob], resourceName, { type: blob.type, lastModified: Date.now() });
    } catch (e) {
      Sentry.captureException(e);
      this.isLoadingPdfFromPencilLib$.next(false);
      await this.uploadFileComputerService.showFailedToProcessPDFNotification();
    }

    if (file) {
      await this.readFile(file);
    }
    this.isLoadingPdfFromPencilLib$.next(false);
    return true;
  }

  private getSupportedDocumentTypes(): string {
    let result = '*';

    if (this.data.type.length === 1) {
      if (this.data.type.includes(ResourceItemModel.IMAGE)) {
        result = 'image/*';
      } else if (this.data.type.includes(ResourceItemModel.VIDEO)) {
        result = 'video/*';
      } else if (this.data.type.includes(ResourceItemModel.DOCUMENT)) {
        result = this.originalSupportedDocumentTypes;
      }
      return result;
    }
    if (
      this.data.type.includes(ResourceItemModel.IMAGE) &&
      this.data.type.includes(ResourceItemModel.VIDEO) &&
      this.data.type.includes(ResourceItemModel.DOCUMENT)
    ) {
      result = 'image/*, video/*, '.concat(this.originalSupportedDocumentTypes);
    }
    return result;
  }

  set file(fileToSet: UploadedFile | undefined) {
    this.file$.next(fileToSet);
  }

  get file(): UploadedFile | undefined {
    return this.file$.getValue();
  }
}

<div class="visibility-icon">
  <i
    class="material-icons-outlined"
    *ngIf="visibility === VISIBILITY.PUBLIC"
    [ngbTooltip]="'Publicly visible' | translate"
    >public</i
  >
  <i
    class="material-icons-outlined"
    *ngIf="visibility === VISIBILITY.ME"
    [ngbTooltip]="'Visible only to you' | translate"
    >visibility</i
  >
  <i
    class="material-icons-outlined"
    *ngIf="visibility === VISIBILITY.TEACHERS"
    [ngbTooltip]="'Visible to teachers only' | translate"
    >perm_identity</i
  >
  <i
    class="material-icons-outlined"
    *ngIf="visibility === VISIBILITY.CLASS"
    [ngbTooltip]="'Visible to teachers and students' | translate"
    >supervisor_account</i
  >
</div>

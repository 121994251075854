import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { FileParam, Filter, FilterSet } from '../models/filters';
import { ResourceFilterParams, ResourceFilterParamsEnum } from '../models/params';
import { ResourceItemModel } from '../models/resource';
import { Course, TopicNode } from '../models/user';
import { AclService } from './acl.service';
import { TagsService } from './tags.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class FileFilterService {

  // Keep track of how many filters are applied.
  filters: ResourceFilterParams = {};
  appliedFilters = 0;
  search = '';
  hiddenFilterSets: FilterSet[] = [];
  enforcingFilter?: ResourceFilterParamsEnum;
  subscribers: Subscription[] = [];
  user;
  visibility = ['ME', 'TEACHERS', 'CLASS', 'PUBLIC'];
  selectedCourse?: Course;

  // Subject for notifying filters of selection updates
  filtersUpdated: Subject<any> = new Subject();

  // Mapping each type to its filterSet
  filterSetsByType: Map<FileParam, FilterSet> = new Map();

  // Used to hold the topics from topic panel, Keys are ids for fast lookup.
  topics: Map<string, TopicNode>;

  // Used as Input to topics panel to update it as needed.
  topicsSelectionIDs: string[];

  resetView: Subject<void>;

  previousUrl = '';
  currentUrl = '';
  courseLookup: Map<string, string> = new Map();

  constructor(
    private tagService: TagsService,
    private userService: UserService,
    private aclService: AclService,
    private router: Router,
  ) {
    this.resetView = new Subject();
    this.topics = new Map();
    this.topicsSelectionIDs = [];
    this.initFilters();

    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationEnd) {
          this.previousUrl = this.currentUrl;
          this.currentUrl = event.url;
        }
      }
    )
  }

  get filterSets() {
    return Array.from(this.filterSetsByType.values());
  }

  updateFilters() {
    if (!this.previousUrl.includes('/content/files/')) {
      this.initFilters();
    }
  }

  buildFilters(): ResourceFilterParams {
    let resourceFilters: ResourceFilterParams = {};
    this.appliedFilters = 0;
    this.filterSets.forEach(filterSet => {
      filterSet.filters.forEach(filter => {
        if (filter.value) {
          this.appliedFilters++;
          resourceFilters = filterSet.applyFilters(filter.item, resourceFilters);
        }
      });
    });

    this.hiddenFilterSets.forEach(filterSet => {
      filterSet.filters.forEach(filter => {
        if (filter.value) {
          this.appliedFilters++;
          resourceFilters = filterSet.applyFilters(filter.item, resourceFilters);
        }
      });
    });

    // to make the query totally empty whenever no filtering needed.
    if (this.search.length) {
      resourceFilters.query = this.search;
      this.appliedFilters++;
    }

    // To Enforce input filter.
    if (this.enforcingFilter) {
      resourceFilters[ResourceFilterParamsEnum.EnforcingFilter] = this.enforcingFilter;
      this.appliedFilters = Math.max(this.appliedFilters - 1, 0);
    }

    this.filters = resourceFilters;

    this.filtersUpdated.next('');

    return resourceFilters;
  }

  setSearchValue(searchVal : string) : void {
    if (this.search !== searchVal) {
      this.search = searchVal;
    }
  }

  updateEnforcedFilters() {
    if (this.enforcingFilter == ResourceFilterParamsEnum.Course) {
      const courseFilterSet = this.filterSets.find(filterSet => filterSet.name === FileParam.Course)
      courseFilterSet?.clearFilters();
      const selectedCourseFilter = courseFilterSet?.filters.find(filter => filter.item._id == this.selectedCourse?._id);
      if (selectedCourseFilter) {
        selectedCourseFilter.value = true;
      }
    }
  }

  initFilters(): void {
    this.filterSetsByType = new Map();

    this.subscribers.push(
      // Load Course Filter.
      this.userService.user.subscribe((res: any) => {
        if (!res?.user) {
          return;
        }
        this.user = res.user;
        this.user.courses.forEach(course => {
          this.courseLookup.set(course._id, course.name);
        })
        // Getting Courses filter.
        const coursesFilters = new FilterSet(FileParam.Course, ResourceFilterParamsEnum.Course,
          res.user.courses.map((course => new Filter(course, () => course.name, course._id === this.selectedCourse?._id))),
          {getID: (item: { _id: any; }) => item._id, isList: false}
        );
        this.addFilterSet(coursesFilters);
      }),
      // Load tags.
      this.tagService.tags.subscribe(tags => {
        if (!tags) {
          return;
        }
        const splitTags = this.tagService.splitTags(tags.attribute_value);
        const InstTagFilterSet = new FilterSet(FileParam.InstTags, ResourceFilterParamsEnum.CustomAttributes,
          splitTags.institution.map((tag) => new Filter(tag, (item) => item.value)),
          {getID: (item: any) => this.tagService.tagToString(item), icon: 'school'}
        );

        const courseTagFilterSet = new FilterSet(FileParam.CourseTags, ResourceFilterParamsEnum.CustomAttributes,
          splitTags.course.reduce((filtered: Filter[], tag) => {
            const course = this.user?.courses.find(userCourse => userCourse._id === tag.metadata.courseID);
            if (course && (this.enforcingFilter !== ResourceFilterParamsEnum.Course || course._id === this.selectedCourse?._id)) {
              filtered.push(new Filter(tag, (item) => item.value, false, course));
            }
            return filtered;
          }, [])
          ,
          {getID: (item: any) => this.tagService.tagToString(item), icon: 'article'}
        );

        // To make sure that no duplicates in filterSets array.
        this.addFilterSet(InstTagFilterSet);
        this.addFilterSet(courseTagFilterSet);
      }),
      // Load Upload By filters.
      this.userService.getUsers().subscribe((res) => {
        const userFilterSet = new FilterSet(FileParam.UploadedBy, ResourceFilterParamsEnum.Author,
          res.profiles.filter((profile) => profile.name && !this.aclService.isStudent(profile as any)).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map((profile) => new Filter(profile, (item) => item.name)),
          {getID: (item: any) => item['_id']}
        );
        this.addFilterSet(userFilterSet);
      })
    );

    // Static filter Sets.
    // Adding Type filters.
    const fileTypeFilter = [
      new Filter(ResourceItemModel.IMAGE),
      new Filter(ResourceItemModel.DOCUMENT),
      new Filter(ResourceItemModel.VIDEO),
      new Filter(ResourceItemModel.SIMULATION),
    ];
    this.addFilterSet(new FilterSet(FileParam.Type, ResourceFilterParamsEnum.Type,
      fileTypeFilter
    ));

    const visibilityFilter = this.visibility.map((vis) => new Filter(vis));

    this.hiddenFilterSets.push(new FilterSet(FileParam.Visibility, ResourceFilterParamsEnum.Visibility,
      visibilityFilter
    ));
  }

  toggleFilter(filter: Filter, filterSet: FilterSet, reset = true) {
    if (!filter) {
      this.turnOffFilters(filterSet);
    }
    if (filterSet.name === FileParam.Course) {
      // Clear old topics once we changing the course.
      this.clearTopics();

      if (filter) {
        this.selectedCourse = filter.value ? filter.item : undefined;
      } else {
        this.selectedCourse = undefined;
      }

    }
    this.filtersUpdated.next('')
  }

  turnOffFilters(...filterSets: FilterSet[]) {
    filterSets.forEach(fs => {
      fs.clearFilters();
    });
  }

  turnOffFilterByType(...filterTypes: FileParam[]) {
    const filterSets = filterTypes
      .map(ft => this.filterSetsByType.get(ft))
      .filter((fs): fs is FilterSet => !!fs);
    this.turnOffFilters(...filterSets);
  }

  /**
   * Clear topics by removing all
   */
   clearTopics() {
    const topicFilterSet = this.filterSetsByType.get(FileParam.Topic);
    if (topicFilterSet) {
      // Remove all old topic filters
      this.turnOffFilters(topicFilterSet);
      topicFilterSet.filters.splice(0, topicFilterSet.filters.length);
      this.topicsSelectionIDs.splice(0, this.topicsSelectionIDs.length);;
    }
    this.filtersUpdated.next('')
  }

  addTopicFilter(selectedTopicsIDs: string[]): void {

    // Getting topic Nodes, from given ids.
    const selectedTopics = selectedTopicsIDs.map(id => this.topics.get(id));

    const course = this.filterSetsByType.get(FileParam.Course)?.filters.find(f => f.value)?.item;

    // No course is selected.
    if (!course?._id) {
      return;
    }

    // Get the corresponding filter set.
    const topicFilter = this.filterSetsByType.get(FileParam.Topic);

    if (!topicFilter) {
      this.addFilterSet(new FilterSet(
        FileParam.Topic, ResourceFilterParamsEnum.Topics,

        // Get rid of undefined topics, in case something goes wrong.
        selectedTopics.reduce((result: Filter[], topic) => {
          if (topic) {
            result.push(new Filter(topic._id,
              () => topic.name,
              true));
          }
          return result;
        }, [])
      ));
    } else {
      // Get rid of old filters.
      topicFilter.filters.splice(0, topicFilter.filters.length);
      selectedTopics.forEach(topic => {
        if (topic) {
          topicFilter?.filters.push(new Filter(topic._id,
            () => topic.name.concat(' in ').concat(course.name),
            true));
        }
      });
    }
  }

  /**
  * Get topics from topic panel, and Converts it to a map to improve looks up.
  */
  setTopics(topics: (TopicNode & {parent:any []})[]): void {
    this.topics.clear();
    for (const topic of topics) {
      this.topics.set(topic._id, topic);
    }
  }

  clearAllFilters(): void {
    this.turnOffFilters(...this.filterSets.filter(fs => fs.queryParam !== this.enforcingFilter));
    this.search = '';
    if (this.enforcingFilter !== ResourceFilterParamsEnum.Course) {
      // Reset selected Course (drop-down menu).
      this.selectedCourse = undefined;
    }
    this.topicsSelectionIDs = [];
  }

  /**
   * Used to remove a filter from pills view.
   */
  removeFilter(filter: Filter, filterSet: FilterSet, index: number): void {
    // Disable the filter.
    filterSet.clearFilters(index);
    if (filterSet.name === FileParam.Topic) {
      const topicFilters = this.filterSetsByType.get(filterSet.name);
      if (topicFilters) {
        // If it's a topic filter, We need to remove it from the list of filters,
        // Also we need to inform the topic panel via topicsSelectionIDs.
        topicFilters.filters.splice(index, 1);
        this.topicsSelectionIDs = topicFilters.filters.map(f => f.item);
      }
    } else if (filterSet.name === FileParam.Course) {
      this.selectedCourse = undefined;
      // this.highlightingCourseFilter = this.topicFilterPanelOpen && this.filterPanelOpen;
      this.clearTopics();
    }
  }

  addFilterSet(filterSet: FilterSet) {
    this.filterSetsByType.set(filterSet.name, filterSet);
  }

  /**
   * Edit filter to the existing current FilterSets.
   * @param FilterType The name of the filterSet
   * @param value Whether the filter must be enabled.
   * @param reset reset the view after applying the filter (default is false).
   */
  editFilter(FilterType: FileParam, queryParam: ResourceFilterParamsEnum, filter: Filter, value: boolean): void {

    // Checking whether the current filterSets has that filterType
    const filterSetResult = this.filterSetsByType.get(FilterType);

    // If it's a new filter Type, create one.
    if (!filterSetResult) {
      const isList = ['visibility', 'topics', 'type', 'difficulties', 'customAttributes'].includes(FilterType);
      this.addFilterSet(new FilterSet(FilterType, queryParam, [filter], {isList}));
    }
    else {
      // See if the same filter is applied before.
      const filterResult = filterSetResult.filters.find(f => f.displayFunc(f.item) === filter.displayFunc(filter.item));
      if (!filterResult)
        {filterSetResult.filters.push(filter);}
      else
        {filterResult.value = value;}
    }
  }
}

<!--suppress TypeScriptUnresolvedFunction, AngularUndefinedBinding, AngularUndefinedTag -->
<ng-template #notSupportedVirtualBackground>
  <app-tooltip
    [style.width.px]="205"
    [showTemplate]="true"
    [arrow]="isMobile ? 'bottom' : 'right'"
    [theme]="'grey'"
  >
    <app-not-supported-vb></app-not-supported-vb>
  </app-tooltip>
</ng-template>

<app-first-time-request-devices-permission-guide
  (requestPermission)="requestPermission()"
  (skipRequestPermission)="hideRequestPermissionGuideLayout()"
  *ngIf="currentPrompt === prompts.FIRST_TIME_JOINING"
></app-first-time-request-devices-permission-guide>

<app-steps-guide
  *ngIf="currentPrompt === prompts.HELPER_GUIDE"
  [loading]="guideLoading"
  [guide]="helperGuide"
  (closeModal)="onCloseGuide()"
  (done)="onDoneGuide()"
>
</app-steps-guide>

<div class="join-call-container" [hidden]="currentPrompt !== prompts.JOIN_CALL">
  <div class="join-call-panel">
    <div class="left-part">
      <div class="device-video-preview-container">
        <span class="camera-status-position camera-status-text" *ngIf="camState.isGettingStream">{{
          'Camera starting...' | translate
        }}</span>
        <div
          class="camera-status-position"
          *ngIf="!camState.hasError && !camState.isGettingStream && !camState.state"
        >
          <span class="camera-status-text">{{ 'Camera is off' | translate }}</span>
          <span class="camera-status-tip-text" [ngClass]="screenType.MOBILE">{{
            'Turn on your camera below.' | translate
          }}</span>
        </div>

        <div
          class="camera-status-position"
          *ngIf="camState.hasError && !camState.isGettingStream && !camState.state"
        >
          <div
            class="cam-troubleshoot-buttons-area"
            *ngIf="
              camState.errorType === deviceErrorType.PERMISSION_DENIED ||
              camState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM
            "
          >
            <span class="camera-status-text cpb-16"
              >{{
                camState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM
                  ? ('Camera access denied by your OS' | translate)
                  : ('Camera permissions not granted' | translate)
              }}
            </span>

            <div class="no-camera-input-buttons">
              <button
                (click)="troubleshootPermissionDenied(camState.errorType)"
                class="device-settings"
                mat-flat-button
              >
                <span class="material-icons">open_in_new</span>
                <span>{{ 'Grant Permissions' | translate }}</span>
              </button>
            </div>
          </div>

          <div
            *ngIf="
              camState.errorType === deviceErrorType.NO_INPUT_DETECTED ||
              camState.errorType === deviceErrorType.NOT_FOUND
            "
            class="cam-troubleshoot-buttons-area"
          >
            <span
              class="camera-status-text cpb-16"
              *ngIf="camState.errorType === deviceErrorType.NOT_FOUND"
              >{{ 'No camera detected' | translate }}
            </span>
            <div
              class="camera-status-text cpb-16"
              *ngIf="camState.errorType === deviceErrorType.NO_INPUT_DETECTED"
            >
              {{ 'No input detected from camera' | translate }}
            </div>
            <div class="no-camera-input-buttons">
              <button
                (click)="
                  openHelperGuide(
                    camState.errorType === deviceErrorType.NOT_FOUND
                      ? guides.NOT_FOUND
                      : guides.NO_INPUT
                  )
                "
                class="troubleshoot"
                mat-flat-button
              >
                <span class="material-icons">open_in_new</span>
                <span>{{ 'Troubleshoot' | translate }}</span>
              </button>

              <button (click)="openDeviceModal()" class="device-settings" mat-flat-button>
                <span class="material-icons">settings</span>
                <span>{{ 'Device Settings' | translate }}</span>
              </button>
            </div>
          </div>

          <span
            class="camera-status-text"
            *ngIf="camState.errorType === deviceErrorType.GET_USER_MEDIA_TIMEOUT"
            >{{ "We're having trouble starting your camera" | translate }}</span
          >
          <span
            class="camera-status-tip-text"
            *ngIf="camState.errorType === deviceErrorType.GET_USER_MEDIA_TIMEOUT"
            >{{
              'Please reload this page. If issues persist, please restart your browser.' | translate
            }}
            <a [attr.href]="intercomArticles.GetPreviewStreamsStuckJoinCallPanel" target="_blank">
              {{ 'Need help?' | translate }}
            </a></span
          >
          <span
            class="camera-status-text"
            *ngIf="camState.errorType === deviceErrorType.DEFAULT_ERROR"
            >{{ 'Camera is not set up correctly' | translate }}</span
          >
          <span
            class="camera-status-tip-text"
            *ngIf="camState.hasError && camState.errorType === deviceErrorType.DEFAULT_ERROR"
            >{{ 'Please adjust your audio and video to resolve this.' | translate }}</span
          >
        </div>
        <video
          #deviceVideoPreview
          id="deviceVideoPreview"
          [hidden]="(camState.hasError && !camState.state) || camState.isGettingStream"
          muted
          playsinline
          autoplay="autoplay"
          [style.object-fit]="'contain'"
        ></video>
        <div
          class="device-is-on"
          [style.bottom]="'110px'"
          *ngIf="showMicIsOnToast"
          @fadeIn
          @fadeOut
        >
          <em class="material-icons-outlined mat-icon-small-size"> mic_none </em>
          <span class="device-is-on-text">
            {{ 'Microphone on' | translate }}
          </span>
        </div>
        <div class="device-is-on" *ngIf="showCamIsOnToast" @fadeIn @fadeOut>
          <em class="material-icons-outlined mat-icon-small-size"> videocam </em>
          <span class="device-is-on-text">
            {{ 'Camera on' | translate }}
          </span>
        </div>
        <div
          class="device-preview-controls-container"
          [ngClass]="{
            'no-input-camera-device-preview-controls-container':
              camState.hasError && camState.errorType === deviceErrorType.NO_INPUT_DETECTED
          }"
        >
          <div
            class="mic-hover-area"
            (mouseover)="
              showMicNoInputPopUp =
                micState.hasError && micState.errorType === deviceErrorType.NO_INPUT_DETECTED
            "
            (mouseleave)="showMicNoInputPopUp = false"
          >
            <div
              *ngIf="
                (uiService.isMobile | async) === false &&
                showMicNoInputPopUp &&
                micState.hasError &&
                micState.errorType === deviceErrorType.NO_INPUT_DETECTED
              "
              class="no-mic-input-container"
            >
              <app-no-input-from-mic
                (settingsNoMicInput)="openDeviceModal()"
                (troubleshootNoMicInput)="openHelperGuide(guides.NO_INPUT)"
              >
              </app-no-input-from-mic>
            </div>
            <button
              id="call-mic-toggle-modal"
              (click)="toggleMicActions()"
              [disabled]="!micState.isToggable"
              [appTooltip]="micState.hasError ? micErrorTooltip : micTooltip"
              [appTooltipDisabled]="(uiService.isMobile | async) === false && showMicNoInputPopUp"
              data-name="start-call-button-toggle-mic"
            >
              <svg-icon
                class="svg-icon"
                [width]="24"
                [height]="24"
                *ngIf="micState.hasError"
                [icon]="'mic_off_with_error'"
                [maxWidth]="'fit-content'"
              >
              </svg-icon>
              <svg-icon
                [width]="34"
                [height]="34"
                *ngIf="!micState.hasError && !micState.state"
                [icon]="'mic-mute'"
                [maxWidth]="'fit-content'"
              ></svg-icon>
              <em *ngIf="!micState.hasError && micState.state" class="material-icons-outlined">
                mic_none
              </em>
              <ng-template #micTooltip>
                <app-tooltip
                  [text]="micState.state ? ('Mute' | translate) : ('Unmute' | translate)"
                  [arrow]="'bottom'"
                >
                </app-tooltip>
              </ng-template>
              <ng-template #micErrorTooltip>
                <app-tooltip
                  [error]="'true'"
                  [text]="
                    devicesManagerService.micErrorsMappings?.get(micState?.errorType) | translate
                  "
                  [arrow]="'bottom'"
                >
                </app-tooltip>
              </ng-template>
            </button>
          </div>
          <button
            id="call-camera-toggle-modal"
            (click)="toggleCamActions()"
            [disabled]="!camState.isToggable"
            [appTooltip]="camState.hasError ? camErrorTooltip : camTooltip"
            data-name="start-call-button-toggle-camera"
          >
            <svg-icon
              class="svg-icon"
              [width]="24"
              [height]="24"
              *ngIf="camState.hasError"
              [icon]="'videocam_off_with_error'"
              [maxWidth]="'fit-content'"
            ></svg-icon>
            <svg-icon
              [width]="34"
              [height]="34"
              *ngIf="!camState.hasError && !camState.state"
              [icon]="'vid-no-cam'"
              [maxWidth]="'fit-content'"
            ></svg-icon>
            <em *ngIf="!camState.hasError && camState.state" class="material-icons-outlined">
              videocam
            </em>
            <ng-template #camTooltip>
              <app-tooltip
                [text]="
                  camState.state ? ('Turn off video' | translate) : ('Turn on video' | translate)
                "
                [arrow]="'bottom'"
              >
              </app-tooltip>
            </ng-template>
            <ng-template #camErrorTooltip>
              <app-tooltip
                [error]="'true'"
                [text]="
                  devicesManagerService.camErrorsMappings?.get(camState?.errorType) | translate
                "
                [arrow]="'bottom'"
              >
              </app-tooltip>
            </ng-template>
          </button>
          <button
            class="apps-more-menu"
            [matMenuTriggerFor]="actionsMenu"
            #menuTrigger="matMenuTrigger"
            [class.menu-opened]="menuTrigger.menuOpen"
            [appTooltip]="'Advanced Settings' | translate"
            [appTooltipArrow]="'right'"
            [appTooltipPanelClass]="'join-call-advanced-settings-tooltip'"
            [appTooltipPlacement]="'center-start'"
            [appTooltipTheme]="'grey'"
          >
            <mat-icon class="material-icons-outlined">more_horiz</mat-icon>
          </button>

          <mat-menu
            [yPosition]="'above'"
            [xPosition]="'before'"
            #actionsMenu="matMenu"
            class="more-actions-menu-join-call"
          >
            <div class="title">
              <span> Advanced settings </span>
              <mat-icon *ngIf="isMobile" class="close-icn" (click)="menuTrigger.closeMenu()"
                >close</mat-icon
              >
            </div>
            <hr style="margin: unset" />
            <button
              *ngIf="virtualBackgroundEnabled"
              [appTooltip]="notSupportedVirtualBackground"
              [appTooltipPlacement]="'center-start'"
              [appTooltipCloseDelay]="isRealDesktop ? 200 : 3000"
              [appTooltipStayWhenTooltipHover]="true"
              [appTooltipDisabled]="virtualBackgroundSupported || isMobile"
              mat-menu-item
              (click)="
                virtualBackgroundSupported
                  ? showVirtualBackgroundsModal()
                  : $event.stopPropagation()
              "
            >
              <span class="item-text-content">
                <svg-icon
                  class="icn"
                  [width]="'18'"
                  [height]="'18'"
                  [icon]="'virtual_backgrounds_icon'"
                  [maxWidth]="'fit-content'"
                  [ngClass]="{ 'opacity-50': !virtualBackgroundSupported }"
                ></svg-icon>
                <span
                  [ngClass]="{ 'opacity-50': !virtualBackgroundSupported }"
                  *ngIf="virtualBackgroundSupported || !isMobile"
                  >Virtual background</span
                >
                <app-not-supported-vb
                  [disabledMode]="true"
                  *ngIf="!virtualBackgroundSupported && isMobile"
                ></app-not-supported-vb>
              </span>
            </button>
            <button
              (click)="$event.stopPropagation(); musicModeToggle.toggle()"
              mat-menu-item
              *ngIf="musicModeFeatureFlag"
            >
              <span class="item-text-content">
                <mat-icon class="icn">music_note</mat-icon>
                <span>Music mode</span>
              </span>
              <mat-slide-toggle
                (click)="$event.stopPropagation()"
                #musicModeToggle
                [(ngModel)]="enableMusicMode"
              ></mat-slide-toggle>
            </button>

            <button
              *ngIf="isNoiseCancellationSupported"
              (click)="$event.stopPropagation(); noiseCancellationToggle.toggle()"
              mat-menu-item
            >
              <span class="item-text-content">
                <mat-icon class="icn">hearing_disabled</mat-icon>
                <span>Noise cancellation</span>
              </span>
              <mat-slide-toggle
                (click)="$event.stopPropagation()"
                [(ngModel)]="enableNoiseCancellation"
                #noiseCancellationToggle
              ></mat-slide-toggle>
            </button>
          </mat-menu>
        </div>
      </div>
      <ng-container *ngIf="(uiService.isMobile | async) === true">
        <ng-container
          *ngTemplateOutlet="adjustVideoAudioTemplate; context: { screenType: screenType.MOBILE }"
        >
        </ng-container>
      </ng-container>

      <div class="devices-select-container">
        <div class="device-container">
          <span
            class="material-icons-outlined device-icon"
            [ngClass]="{
              'device-icon-error':
                micState.hasError &&
                (micState.errorType === deviceErrorType.PERMISSION_DENIED ||
                  micState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM)
            }"
          >
            volume_up
            <span
              *ngIf="
                micState.hasError &&
                (micState.errorType === deviceErrorType.PERMISSION_DENIED ||
                  micState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM)
              "
              class="material-icons device-info-error-icon"
              >info</span
            >
          </span>

          <mat-select
            (click)="
              !isLoadingDevices &&
                !micState.isGettingStream &&
                micState.hasError &&
                (micState.errorType === deviceErrorType.PERMISSION_DENIED ||
                  micState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM) &&
                troubleshootMic()
            "
            [ngClass]="
              micState.hasError &&
              (micState.errorType === deviceErrorType.PERMISSION_DENIED ||
                micState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM)
                ? 'mat-select-arrow-orange'
                : 'mat-select-arrow-white'
            "
            [(value)]="selectedSpeakerMatSelect"
            [panelClass]="['custom-mat-select-panel', 'custom-mat-select-panel-top']"
            [disableOptionCentering]="true"
            (selectionChange)="setSpeakerPreview($event.value)"
            [disabled]="
              isLoadingDevices ||
              micState.isGettingStream ||
              (micState.hasError &&
                (micState.errorType === deviceErrorType.PERMISSION_DENIED ||
                  micState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM))
            "
            data-name="video-audio-options-change-speakers"
          >
            <mat-select-trigger
              *ngIf="
                micState.hasError &&
                (micState.errorType === deviceErrorType.PERMISSION_DENIED ||
                  micState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM ||
                  (micState.errorType === deviceErrorType.NOT_FOUND && devices.mics.length > 0))
              "
            >
              <span>
                {{ 'Need Permissions' | translate }}
              </span>
            </mat-select-trigger>
            <mat-option [value]="LOADING" disabled selected hidden>
              {{ 'Loading...' | translate }}
            </mat-option>
            <ng-container>
              <mat-option [value]="'default'" *ngIf="devices?.speakers.length === 0">
                System Default
              </mat-option>
              <mat-option [value]="speaker.deviceId" *ngFor="let speaker of devices?.speakers">
                {{ speaker.label }}
              </mat-option>
            </ng-container>
          </mat-select>
        </div>

        <div class="device-container">
          <span
            class="material-icons-outlined device-icon"
            [ngClass]="{ 'device-icon-error': micState.hasError }"
          >
            mic
            <span *ngIf="micState.hasError" class="material-icons device-info-error-icon"
              >info</span
            >
          </span>
          <mat-select
            (click)="
              !isLoadingDevices &&
                !micState.isGettingStream &&
                micState.hasError &&
                (micState.errorType === deviceErrorType.PERMISSION_DENIED ||
                  micState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM ||
                  micState.errorType === deviceErrorType.NOT_FOUND ||
                  (micState.errorType === deviceErrorType.NO_INPUT_DETECTED &&
                    devices.mics.length < 2)) &&
                troubleshootMic()
            "
            [ngClass]="micState.hasError ? 'mat-select-arrow-orange' : 'mat-select-arrow-white'"
            [(value)]="selectedMicMatSelect"
            panelClass="custom-mat-select-panel"
            (selectionChange)="setMicPreview($event.value)"
            [disabled]="
              isLoadingDevices ||
              micState.isGettingStream ||
              (micState.hasError &&
                (micState.errorType === deviceErrorType.PERMISSION_DENIED ||
                  micState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM ||
                  micState.errorType === deviceErrorType.NOT_FOUND ||
                  (micState.errorType === deviceErrorType.NO_INPUT_DETECTED &&
                    devices.mics.length < 2)))
            "
            [panelClass]="['custom-mat-select-panel', 'custom-mat-select-panel-top']"
            [disableOptionCentering]="true"
            data-name="video-audio-options-change-mic"
          >
            <mat-select-trigger
              *ngIf="
                micState.hasError &&
                (micState.errorType === deviceErrorType.PERMISSION_DENIED ||
                  micState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM ||
                  (micState.errorType === deviceErrorType.NOT_FOUND && devices.mics.length > 0))
              "
            >
              <span>
                {{ 'Need Permissions' | translate }}
              </span>
            </mat-select-trigger>
            <mat-select-trigger
              *ngIf="
                micState.hasError &&
                (micState.errorType === deviceErrorType.NO_INPUT_DETECTED ||
                  micState.errorType === deviceErrorType.GET_USER_MEDIA_TIMEOUT ||
                  (micState.errorType === deviceErrorType.NOT_FOUND && devices.mics.length === 0))
              "
            >
              <span>
                {{ 'No input detected' | translate }}
              </span>
            </mat-select-trigger>
            <mat-option [value]="LOADING" disabled selected hidden>
              {{ 'Loading...' | translate }}
            </mat-option>
            <ng-container>
              <mat-option [value]="mic.deviceId" *ngFor="let mic of devices?.mics">
                {{ mic.label }}
              </mat-option>
            </ng-container>
          </mat-select>
        </div>

        <div class="device-container">
          <span
            class="material-icons-outlined device-icon"
            [ngClass]="{ 'device-icon-error': camState.hasError }"
          >
            videocam
            <span *ngIf="camState.hasError" class="material-icons device-info-error-icon"
              >info</span
            >
          </span>
          <mat-select
            (click)="
              !isLoadingDevices &&
                !camState.isGettingStream &&
                camState.hasError &&
                (camState.errorType === deviceErrorType.PERMISSION_DENIED ||
                  camState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM ||
                  camState.errorType === deviceErrorType.NOT_FOUND ||
                  (camState.errorType === deviceErrorType.NO_INPUT_DETECTED &&
                    devices.cameras.length < 2)) &&
                troubleshootCam()
            "
            [ngClass]="camState.hasError ? 'mat-select-arrow-orange' : 'mat-select-arrow-white'"
            [(value)]="selectedCamMatSelect"
            (selectionChange)="setCameraPreview($event.value)"
            panelClass="custom-mat-select-panel"
            [disabled]="
              isLoadingDevices ||
              camState.isGettingStream ||
              (camState.hasError &&
                (camState.errorType === deviceErrorType.PERMISSION_DENIED ||
                  camState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM ||
                  camState.errorType === deviceErrorType.NOT_FOUND ||
                  (camState.errorType === deviceErrorType.NO_INPUT_DETECTED &&
                    devices.cameras.length < 2)))
            "
            [panelClass]="['custom-mat-select-panel', 'custom-mat-select-panel-top']"
            [disableOptionCentering]="true"
            data-name="video-audio-options-change-camera"
          >
            <mat-select-trigger
              *ngIf="
                camState.hasError &&
                (camState.errorType === deviceErrorType.PERMISSION_DENIED ||
                  camState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM ||
                  (camState.errorType === deviceErrorType.NOT_FOUND && devices.cameras.length > 0))
              "
            >
              <span>
                {{ 'Need Permissions' | translate }}
              </span>
            </mat-select-trigger>
            <mat-select-trigger
              *ngIf="
                camState.hasError &&
                (camState.errorType === deviceErrorType.NO_INPUT_DETECTED ||
                  camState.errorType === deviceErrorType.GET_USER_MEDIA_TIMEOUT ||
                  (camState.errorType === deviceErrorType.NOT_FOUND &&
                    devices.cameras.length === 0))
              "
            >
              <span>
                {{ 'No input detected' | translate }}
              </span>
            </mat-select-trigger>
            <mat-option [value]="LOADING" disabled selected hidden>
              {{ 'Loading...' | translate }}
            </mat-option>
            <ng-container>
              <mat-option [value]="cam.deviceId" *ngFor="let cam of devices?.cameras">
                {{ cam.label }}
              </mat-option>
            </ng-container>
          </mat-select>
        </div>
      </div>
    </div>
    <div class="join-call-details-continer">
      <span class="title text-padding-small-screen-sizes">
        {{
          data.isStartCall
            ? ('Ready to start call?' | translate)
            : ('Ready to join call?' | translate)
        }}
      </span>
      <span class="host-started-call text-padding-small-screen-sizes" *ngIf="!data.isStartCall">
        {{ hostStartedCall | translate }}
      </span>
      <div class="people-in-call" id="people-in-call" *ngIf="users.length > 0">
        <app-users-presence
          [users]="users"
          [maxUsers]="3"
          [avatarWidth]="32"
          [avatarFontSize]="16"
          [avaterTooltipPosition]="'bottom'"
          [tooltipsBackgroundColor]="'#111111'"
          [othersIconHeight]="'32px'"
          [othersIconWidth]="'32px'"
          [useShowOthersListV2]="true"
          [parentClassNameShowOthersListV2]="'people-in-call'"
        >
        </app-users-presence>
      </div>
      <div
        class="txt text-padding-small-screen-sizes"
        [style.margin-top]="users.length === 0 ? '16px' : ''"
      >
        <span *ngIf="users.length === 0">
          {{ 'You’re the first one here.' | translate }}
        </span>
        <span *ngIf="users.length === 1">
          {{
            data.isStartCall
              ? users[0].name + (' will be notified.' | translate)
              : users[0].name + (' is already in the call.' | translate)
          }}
        </span>
        <span *ngIf="users.length > 1">
          {{
            data.isStartCall
              ? (users.length + ' people will be notified.' | translate)
              : (users.length + ' people are already in the call.' | translate)
          }}
        </span>
      </div>
      <ng-container
        *ngTemplateOutlet="buttonsTemplate; context: { screenType: screenType.DESKTOP }"
      >
      </ng-container>
      <ng-container
        *ngTemplateOutlet="adjustVideoAudioTemplate; context: { screenType: screenType.DESKTOP }"
      >
      </ng-container>
      <ng-container
        *ngTemplateOutlet="noticedErrorTemplate; context: { screenType: screenType.DESKTOP }"
      >
      </ng-container>
    </div>
    <ng-container
      *ngTemplateOutlet="noticedErrorTemplate; context: { screenType: screenType.MOBILE }"
    >
    </ng-container>
    <div
      class="people-in-call-list"
      [ngClass]="screenType.MOBILE"
      *ngIf="users.length > 0 && (uiService.isMobile | async) === false"
    >
      <ng-container *ngFor="let user of users; let i = index">
        <div class="user-row">
          <app-profile-photo
            [user]="user"
            [width]="26"
            [isClickEnabled]="false"
            style="margin-right: 24px; margin-right: 24px"
          >
          </app-profile-photo>
          <span class="user-name"> {{ user.name }} </span>
        </div>
      </ng-container>
    </div>
    <ng-container *ngTemplateOutlet="buttonsTemplate; context: { screenType: screenType.MOBILE }">
    </ng-container>
  </div>
  <ng-template #adjustVideoAudioTemplate let-screenType="screenType">
    <button
      class="adjust-video-audio"
      [ngClass]="screenType"
      (click)="openDeviceModal()"
      data-name="start-call-button-video-audio-options"
    >
      <span style="font-size: 18px" class="material-icons cpr-5">settings</span>
      {{ 'Adjust video and audio' | translate }}
    </button>
  </ng-template>
  <ng-template #noticedErrorTemplate let-screenType="screenType">
    <div
      class="noticed-error"
      [ngClass]="screenType"
      *ngIf="camState.hasError || micState.hasError"
    >
      <span
        *ngIf="
          ((camState.hasError && camState.errorType === deviceErrorType.NO_INPUT_DETECTED) ||
            (micState.hasError && micState.errorType === deviceErrorType.NO_INPUT_DETECTED)) &&
            (!camState.hasError || !micState.hasError || camState.errorType === micState.errorType);
          else notFoundError
        "
        class="noticed-error-text text-padding-small-screen-sizes"
        >{{ 'No camera and/or microphone input detected.' | translate }}
        <span (click)="openHelperGuide(guides.NO_INPUT)" class="fix-now-text">
          {{ 'Fix now' | translate }}
        </span>
      </span>

      <ng-template #notFoundError>
        <span
          *ngIf="
            ((camState.hasError && camState.errorType === deviceErrorType.NOT_FOUND) ||
              (micState.hasError && micState.errorType === deviceErrorType.NOT_FOUND)) &&
              (!camState.hasError ||
                !micState.hasError ||
                camState.errorType === micState.errorType);
            else permissionError
          "
          class="noticed-error-text text-padding-small-screen-sizes"
          >{{ 'No camera and/or microphone detected.' | translate }}
          <span (click)="openHelperGuide(guides.NOT_FOUND)" class="fix-now-text">
            {{ 'Fix now' | translate }}
          </span>
        </span>
      </ng-template>

      <ng-template #permissionError>
        <span
          *ngIf="
            (([
              deviceErrorType.PERMISSION_DENIED,
              deviceErrorType.PERMISSION_DENIED_BY_SYSTEM,
              undefined
            ].includes(camState.errorType) ||
              !camState.hasError) &&
              ([
                deviceErrorType.PERMISSION_DENIED,
                deviceErrorType.PERMISSION_DENIED_BY_SYSTEM,
                undefined
              ].includes(micState.errorType) ||
                !camState.hasError) &&
              camState.hasError) ||
              micState.hasError;
            else generalError
          "
          class="noticed-error-text text-padding-small-screen-sizes"
          >{{ 'We noticed error with your camera and/or microphone.' | translate }}
          <span (click)="openHelperGuide(guides.PERMISSION_DENIED)" class="fix-now-text">
            {{ 'Fix now' | translate }}
          </span>
        </span>
      </ng-template>

      <ng-template #generalError>
        <span class="noticed-error-text text-padding-small-screen-sizes">{{
          'We noticed issues with your video or audio' | translate
        }}</span>
      </ng-template>
    </div>
  </ng-template>
  <ng-template #buttonsTemplate let-screenType="screenType">
    <div class="confirm-buttons" [ngClass]="screenType">
      <button
        [attr.id]="data.isStartCall ? 'call-start-call-modal' : 'call-join-call-modal'"
        class="btn confirm"
        (click)="finish(CallRelatedModalActionEnum.START_JOINING_THE_CALL)"
        [disabled]="micState.isGettingStream"
        data-name="start-call-button-confirm"
      >
        <mat-icon class="icn">call</mat-icon>
        {{
          micState.isGettingStream || camState.isGettingStream || isLoadingDevices
            ? ('Loading...' | translate)
            : data.isStartCall
            ? ('Start Call' | translate)
            : ('Join Call' | translate)
        }}
      </button>
      <button
        id="call-declined-modal"
        class="btn not-now"
        (click)="closeDialog()"
        data-name="start-call-button-cancel"
      >
        <mat-icon class="icn">close</mat-icon>
        {{ 'Not now' | translate }}
      </button>
    </div>
  </ng-template>
</div>

import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EmbedVideoService } from 'src/app/services/embed-video.service';
import { IResource } from 'src/app/models/resource';
import { ResourcesService } from 'src/app/services/resources.service';
import { ThumbnailService } from 'src/app/services/thumbnail.service';
import { FileGridComponent } from '../../file-view/file-grid/file-grid.component';

@Component({
  selector: 'app-extended-grid-view',
  templateUrl: './extended-grid-view.component.html',
  styleUrls: [
    './extended-grid-view.component.scss',
    '../files-extension.component.scss',
    '../../files.component.scss',
  ],
})
export class ExtendedGridViewComponent extends FileGridComponent {
  @Output() toggledAll: EventEmitter<any> = new EventEmitter();

  constructor(
    _router: Router,
    _embedService: EmbedVideoService,
    _thumbnail: ThumbnailService,
    _resourceService: ResourcesService,
    _translate: TranslateService,
    _dialog: MatDialog,
  ) {
    super(_router, _embedService, _thumbnail, _resourceService, _translate, _dialog);
  }
  selectFile(index: number, single: boolean): void {
    const file = this.currentFiles[index];
    // If a single file is selected
    if (single) {
      // De-select already selected file
      let alreadySelected = false;
      if (this.selectedFiles.indexOf(index) !== -1 && this.selectedFiles.length === 1) {
        alreadySelected = true;
      }
      // Clear existing selected files
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const selectedFile = this.currentFiles[this.selectedFiles[i]];
        if (selectedFile.xMeta) {
          selectedFile.xMeta.display.selected = false;
        }
      }
      this.selectedFiles = [];
      if (!alreadySelected && file.xMeta) {
        file.xMeta.display.selected = true;
        this.selectedFiles.push(index);
      }
    } else {
      // De-select file if it is already selected
      const selectedFilesIndex = this.selectedFiles.indexOf(index);
      if (file.xMeta && file.xMeta.display.selected) {
        file.xMeta.display.selected = false;
        this.selectedFiles.splice(selectedFilesIndex, 1);
      } else if (file.xMeta && !file.xMeta.display.selected) {
        file.xMeta.display.selected = true;
        this.selectedFiles.push(index);
      }
    }
  }

  /**
   * Allows only one file to be selected at a time.
   * @param index the index of file in the currentFiles list.
   */
  toggleFileSelection(index: number, event?: any): void {
    if (event && event.shiftKey) {
      // If shift key is held, select all files between last file and clicked file
      const [lastIndex] = this.selectedFiles.slice(-1);
      const lowerIndex = Math.min(lastIndex, index);
      const upperIndex = lowerIndex === index ? lastIndex : index;
      for (let i = lowerIndex; i <= upperIndex; i++) {
        const file = this.currentFiles[i];
        const selectedFilesIndex = this.selectedFiles.indexOf(i);
        if (selectedFilesIndex === -1) {
          if (file.xMeta) {
            file.xMeta.display.selected = true;
          }
          this.selectedFiles.push(i);
        }
      }
    } else if (event && navigator.platform.match('Mac') && event.metaKey) {
      this.selectFile(index, false);
    } else {
      this.selectFile(index, true);
    }
    const selectedFiles: IResource[] = [];
    for (const selectedFile of this.selectedFiles) {
      selectedFiles.push(this.currentFiles[selectedFile]);
    }
    const autoInsertFile: any = event.type === 'dblclick' ? true : false;
    this.toggleSelectionEmitter.emit(autoInsertFile);
  }

  toggleAll(check: boolean) {
    this.selectedFiles = [];
    this.selectedFolders = [];
    this.allSelected = check;
    this.currentFiles.forEach((file, i) => {
      if (check) {
        this.selectedFiles.push(i);
      }
      if (file.xMeta?.display) {
        file.xMeta.display.selected = check;
      }
    });

    this.currentFolders.forEach((folder, i) => {
      if (check) {
        this.selectedFolders.push(i);
      }
      if (folder.xMeta?.display) {
        folder.xMeta.display.selected = check;
      }
    });
    this.toggledAll.emit();
  }
}

import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  getEntity,
  resetActiveId,
  selectActiveEntity,
  selectActiveId,
  selectAllEntities,
  selectEntity,
  setActiveId,
  updateEntities,
  upsertEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import { Observable } from 'rxjs';

export interface IToolbarState {
  name: string;
  active: boolean;
  visible: boolean;
  activeToolName?: string;
}

@Injectable({ providedIn: 'root' })
export class ToolbarStateRepository {
  private readonly store = createStore(
    { name: 'toolbarStates' },
    withEntities<IToolbarState, 'name'>({ idKey: 'name' }), // name is the id for toolbar state i.e. there is exactly 1 toolbar of each type
    withActiveId(),
  );

  public readonly toolbarStates$ = this.store.pipe(selectAllEntities());

  public readonly activeToolbarName$: Observable<string | undefined> = this.store.pipe(
    selectActiveId(),
  );

  public readonly activeToolbarState$: Observable<IToolbarState | undefined> = this.store.pipe(
    selectActiveEntity(),
  );

  public getToolbarState(name: string) {
    return this.store.query(getEntity(name));
  }

  public getToolbarState$(name: string) {
    return this.store.pipe(selectEntity(name));
  }

  public addToolbarState(toolbarState: IToolbarState): void {
    this.store.update(upsertEntities(toolbarState));
  }

  public setActiveToolbar(name: string): void {
    this.store.update(setActiveId(name));
  }

  public resetActiveToolbar(): void {
    this.store.update(resetActiveId());
  }

  public updateToolbarState(name: string, updatedState: Partial<IToolbarState>): void {
    this.store.update(updateEntities(name, updatedState));
  }
}

import { Component, OnInit, ChangeDetectionStrategy, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReferralService } from 'src/app/services/referral.service';
import { ModalManagerService } from 'src/app/services/modal-manager.service';
import { lastValueFrom } from 'rxjs';
import {
  ReferralDialogComponent,
  ReferralSource,
} from '../referral-dialog/referral-dialog.component';

@Component({
  selector: 'app-referral-accepted-dialog',
  templateUrl: './referral-accepted-dialog.component.html',
  styleUrls: ['./referral-accepted-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferralAcceptedDialogComponent implements OnInit {
  constructor(
    @Optional() private dialogRef: MatDialogRef<ReferralAcceptedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private referralService: ReferralService,
    private modalManagerService: ModalManagerService,
  ) {}

  async ngOnInit() {
    if (this.data.referralId) {
      await lastValueFrom(this.referralService.updateUpgradedReferralViewed(this.data.referralId));
    }
  }

  shareReferral(): void {
    this.close();
    const shareSessionReferralParams = {
      panelClass: 'referral-wb-dialog',
      autoFocus: false,
      data: {
        referralSource: ReferralSource.INVITE,
      },
    };

    this.modalManagerService.showModal(ReferralDialogComponent, shareSessionReferralParams);
  }

  close() {
    this.dialogRef.close();
  }
}

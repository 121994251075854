import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService, LOGIN_TYPE } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

export interface ParamsError {
  code?: string;
}

@UntilDestroy()
@Component({
  selector: 'app-custom-token-provider',
  templateUrl: './custom-token-provider.component.html',
  styleUrls: ['./custom-token-provider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTokenProviderComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.userService.appLoading.next(true);
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      if (
        params.loginType === LOGIN_TYPE.Clever ||
        params.loginType === LOGIN_TYPE.TutorCruncherSignIn
      ) {
        this.handleFirebaseTokenLogin(params);
      } else if (params.loginType === LOGIN_TYPE.Classlink) {
        this.handleFirebaseTokenLogin(params);
      } else {
        this.handleParamsError();
      }
    });
  }

  async handleFirebaseTokenLogin(params: Params): Promise<void> {
    try {
      const continueUrl = params.continueUrl;
      await this.authService.doLogout();
      await this.authService.doTokenLogin({
        type: LOGIN_TYPE.SignUp,
        token: params.token,
        continueUrl,
      });
    } catch (error) {
      this.handleParamsError(error);
    }
  }

  handleParamsError(error?: ParamsError): void {
    error = error ?? ({ code: '' } as ParamsError);
    error.code = 'INVALID_TOKEN';
    console.log(error);
    this.authService.handleError(error);
  }
}

import { Injectable, NgZone } from '@angular/core';
import { distinctUntilArrayItemChanged } from '@ngneat/elf';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Mutex } from 'async-mutex';
import { Subject, combineLatest, distinctUntilChanged } from 'rxjs';
import { INFOS } from '../common/utils/notification-constants';
import { AnalyticsEventType, AnalyticsInsightType } from '../models/analytics';
import { SpaceRepository } from '../state/space.repository';
import {
  ButtonToasterElement,
  ButtonToasterElementStyle,
} from '../ui/notification-toaster/button-toaster-element/button-toaster-element.component';
import { ToasterPopupStyle } from '../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import {
  IconBackground,
  IconMessageToasterElement,
} from '../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { UserSpeakingLiveAnalyticsManager } from '../utilities/LiveAnalytics';
import { modifiedSetTimeout } from '../utilities/ZoneUtils';
import { CurrentlyDetectingAudio } from '../sessions/common/volume-detector';
import { FLAGS, FlagsService } from './flags.service';
import { ForegroundActivityService } from './foreground-activity.service';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from './notification-toaster.service';
import { ProviderStateService } from './provider-state.service';
import { SpacesService } from './spaces.service';
import { TelemetryService } from './telemetry.service';
import { TimerService } from './timer.service';
import { UserIdleService } from './user-idle.service';
import { UserService } from './user.service';
import { RtcServiceController } from './rtc.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private analyticsEvents: Array<{
    eventType: AnalyticsEventType;
    timestamp: Date;
    roomUid?: string;
    userTabId: string;
    eventProperties?: Record<string, unknown>;
  }> = [];
  private currentSessionId?: string;
  private readonly analyticsMutex = new Mutex();
  batchingInterval = 10000; // default value
  spacesAnalyticsEnabled?: boolean;
  spaceAnalyticsInterval?: NodeJS.Timer;

  hiddenPropertyName?: string;
  visibilityChangePropertyName!: string;

  currentSessionId$: Subject<string> = new Subject<string>();
  getCurrentSessionId$: Subject<void> = new Subject<void>();

  // Saves insights on a room level
  realTimeInsights = new Map<string, Map<AnalyticsInsightType, number>>();

  private readonly CURRENT_SESSION_IDLE_THRESHOLD_SECONDS = 300; // 5 minutes

  private userSpeechDetectionManager?: UserSpeakingLiveAnalyticsManager;
  private userSpeechNotificationTimer?: NodeJS.Timer;
  private USER_SPEECH_NOTIFICATION_TIMER_DURATION = 30 * 60 * 1000; // 30 minutes
  private USER_SPEECH_LOWER_THRESHOLD = 0.3;
  private USER_SPEECH_UPPER_THRESHOLD = 0.8;
  private isSpeakingLowerThresholdNotificationEnabled?: boolean;
  private isSpeakingMidThresholdNotificationEnabled?: boolean;
  private isSpeakingUpperThresholdNotificationEnabled?: boolean;
  private readonly SPEECH_TOASTR_NOTIFICATION_DURATION = 30;

  constructor(
    private flagsService: FlagsService,
    private zone: NgZone,
    private spacesService: SpacesService,
    private spaceRepo: SpaceRepository,
    private userIdleService: UserIdleService,
    private timerService: TimerService,
    private userService: UserService,
    private telemetry: TelemetryService,
    private foregroundInactivityService: ForegroundActivityService,
    private ngZone: NgZone,
    private providerStateService: ProviderStateService,
    private translateService: TranslateService,
    private notificationToasterService: NotificationToasterService,
    private rtcServiceController: RtcServiceController,
  ) {
    // Ensures events are captured only when user is in a space
    combineLatest([
      this.spaceRepo.activeSpaceId$,
      this.flagsService.featureFlagChanged(FLAGS.SPACES_ANALYTICS_RECORDING), // in case feature flags are not initialised
    ])
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(([spaceId, _]) => {
        this.analyticsMutex.runExclusive(async () => {
          if (spaceId) {
            if (this.spaceAnalyticsInterval) {
              return;
            }
            this.enableSpacesAnalytics();
          } else {
            await this.disableSpaceAnalytics();
          }
        });
      });

    this.flagsService
      .featureFlagChanged(FLAGS.LIVE_NOTIFICATION_SPEAKING_DURATION)
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        if (this.isSpeakingTimeLiveNotificationEnabled()) {
          this.initLiveSpeechTime();
        }
      });

    this.userSpeechDetectionManager = new UserSpeakingLiveAnalyticsManager(
      this.ngZone,
      this.telemetry,
      this,
      this.userService,
    );

    this.foregroundInactivityService.isForegroundInactive$
      .pipe(untilDestroyed(this))
      .subscribe((isInactive) => this.handleTabChange(isInactive));

    this.userIdleService
      .getUserIdleSubject(this.CURRENT_SESSION_IDLE_THRESHOLD_SECONDS)
      .then((isUserActiveSubject) => {
        isUserActiveSubject.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe({
          next: (isUserActive) => {
            if (isUserActive) {
              // User is active (after inactivity)
              this.getCurrentSessionId$.next();
              this.addToAnalyticsEventsBatch(AnalyticsEventType.JOIN_SPACE);
            } else {
              this.endSessionForUser();
              this.addToAnalyticsEventsBatch(AnalyticsEventType.LEAVE_SPACE);
            }
          },
          error: (err) => console.error(err),
        });
      });
  }

  /**
   * Starts analytics interval which saves events
   */
  enableSpacesAnalytics(): void {
    if (this.spaceAnalyticsInterval) {
      return;
    }

    this.spacesAnalyticsEnabled = this.flagsService.isFlagEnabled(FLAGS.SPACES_ANALYTICS_RECORDING);
    if (!this.spacesAnalyticsEnabled) {
      return;
    }
    this.batchingInterval = this.flagsService.featureFlagsVariables[
      FLAGS.SPACES_ANALYTICS_RECORDING
    ].batching_interval_msecs as number;

    if (this.spacesAnalyticsEnabled) {
      this.zone.runOutsideAngular(async () => {
        // Do not log events if running FE locally
        if (window.location.hostname === 'localhost') {
          return;
        }
        this.spaceAnalyticsInterval = await this.timerService.setInterval(() => {
          this._saveRealTimeEvents();
        }, this.batchingInterval);
      });
    }
  }

  /**
   * Stops analytics interval which saves events
   */
  async disableSpaceAnalytics() {
    if (this.spaceAnalyticsInterval) {
      this._saveRealTimeEvents();
      await this.timerService.clearInterval(this.spaceAnalyticsInterval);
      this.spaceAnalyticsInterval = undefined;
    }
  }

  addToAnalyticsEventsBatch(
    eventType: AnalyticsEventType,
    timestamp?: Date,
    eventProperties?: Record<string, unknown>,
  ): void {
    if (!this.spacesAnalyticsEnabled || !this.spaceAnalyticsInterval) {
      return;
    }
    if (!timestamp) {
      timestamp = new Date();
    }
    const currentRoomUid = this.spaceRepo.activeSpaceCurrentRoom?.uid;
    if (!currentRoomUid) {
      this.telemetry.debugEvent('missing_roomId_analytics_event', {
        eventType,
      });
      return;
    }
    this.analyticsEvents.push({
      eventType,
      timestamp,
      roomUid: currentRoomUid,
      userTabId: this.userService.userUniqueHash,
      eventProperties,
    });
  }

  private _saveRealTimeEvents() {
    if (
      (this.analyticsEvents.length > 0 || this.realTimeInsights.size > 0) &&
      this.currentSessionId
    ) {
      const events = this.analyticsEvents.splice(0, this.analyticsEvents.length);
      const insights = [...this.realTimeInsights.entries()].map(([roomUid, roomInsights]) => ({
        roomUid,
        roomInsights: Object.fromEntries(roomInsights),
      }));
      this.spacesService.sendAnalyticsEvents(this.currentSessionId, events, insights)?.subscribe();
      this.realTimeInsights.clear();
    }
  }

  getCurrentSessionId(): string | undefined {
    return this.currentSessionId;
  }

  setCurrentSessionId(id: string): void {
    if (id === this.currentSessionId) {
      return;
    }
    this._saveRealTimeEvents();
    this.currentSessionId = id;
    this.currentSessionId$.next(id);
  }

  private handleTabChange(isInactive) {
    if (isInactive) {
      this.addToAnalyticsEventsBatch(AnalyticsEventType.HIDE_TAB);
    } else {
      this.addToAnalyticsEventsBatch(AnalyticsEventType.UNHIDE_TAB);
    }
  }

  endSessionForUser() {
    this.setCurrentSessionId('');
  }

  getSpeechDetectionSubject(userId: string): Subject<CurrentlyDetectingAudio> | undefined {
    // Only return the subject if the user is the current user (for speech logging)
    // or if the live notification is enabled
    if (this.isSpeakingTimeLiveNotificationEnabled() || userId === this.userService.userId) {
      return this.userSpeechDetectionManager?.getUserSubject(userId);
    }
  }

  private promptUserSpeakingNotifications() {
    if (!this.userService.userId || !this.userSpeechDetectionManager) {
      return;
    }
    this.telemetry.debugEvent('live_notification', {
      eventType: 'prompt_notification',
    });
    const userSpeechRatio = this.userSpeechDetectionManager.getUserSpeakingRatio(
      this.userService.userId,
    );
    let notificationTitle: string;
    let notificationBody = `In the last ${
      this.USER_SPEECH_NOTIFICATION_TIMER_DURATION / 60000
    } mins, you’ve ${
      userSpeechRatio < this.USER_SPEECH_LOWER_THRESHOLD ? 'only' : ''
    } spoken for <strong>${Math.round(userSpeechRatio * 100)}%</strong> of the time. `;
    let isNotificationEnabled;
    if (userSpeechRatio >= this.USER_SPEECH_UPPER_THRESHOLD) {
      notificationTitle = 'Why not let others speak a bit?';
      notificationBody += 'Consider letting others speak more.';
      isNotificationEnabled = this.isSpeakingLowerThresholdNotificationEnabled;
    } else if (
      userSpeechRatio > this.USER_SPEECH_LOWER_THRESHOLD &&
      userSpeechRatio < this.USER_SPEECH_UPPER_THRESHOLD
    ) {
      notificationTitle = 'You’re a team player!';
      notificationBody += 'Nice work letting others speak! ';
      isNotificationEnabled = this.isSpeakingMidThresholdNotificationEnabled;
    } else {
      notificationTitle = 'Why not speak more?';
      notificationBody += 'Try speaking a bit more!';
      isNotificationEnabled = this.isSpeakingUpperThresholdNotificationEnabled;
    }
    if (!isNotificationEnabled) {
      return;
    }

    const titleElement = new IconMessageToasterElement(
      {
        icon: 'record_voice_over',
        size: 16,
      },
      this.translateService.instant(notificationTitle),
      undefined,
      undefined,
      undefined,
      IconBackground.INFO,
      true,
      true,
    );
    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(notificationBody),
    );
    const actionButton = new ButtonToasterElement(
      [undefined, this.translateService.instant('Learn More')],
      {
        handler: () =>
          window.open(
            'https://helpdesk.pencilspaces.com/en/articles/9073480-how-do-live-call-alerts-work',
            '_blank',
            'noopener',
          ),
        close: true,
      },
      ButtonToasterElementStyle.LINK,
    );

    this.notificationToasterService.showNotification(
      new NotificationDataBuilder(INFOS.LIVE_NOTIFICATION_SPEECH)
        .style(ToasterPopupStyle.WARN)
        .type(NotificationType.WARNING)
        .topElements([titleElement])
        .middleElements([messageElement])
        .bottomElements([actionButton])
        .width(304)
        .timeOut(this.SPEECH_TOASTR_NOTIFICATION_DURATION)
        .extendedTimeout(this.SPEECH_TOASTR_NOTIFICATION_DURATION)
        .dismissable(true)
        .priority(790)
        .version2Notification(true)
        .build(),
    );
  }

  isSpeakingTimeLiveNotificationEnabled(): boolean {
    return this.flagsService.isFlagEnabled(FLAGS.LIVE_NOTIFICATION_SPEAKING_DURATION);
  }

  initLiveSpeechTime() {
    const speakingTimeNotificationVariables =
      this.flagsService.featureFlagsVariables[FLAGS.LIVE_NOTIFICATION_SPEAKING_DURATION];
    this.USER_SPEECH_LOWER_THRESHOLD =
      speakingTimeNotificationVariables.speaking_lower_threshold as number;
    this.USER_SPEECH_UPPER_THRESHOLD =
      speakingTimeNotificationVariables.speaking_upper_threshold as number;
    this.isSpeakingLowerThresholdNotificationEnabled =
      speakingTimeNotificationVariables.speaking_lower_threshold_notification_enabled as boolean;
    this.isSpeakingMidThresholdNotificationEnabled =
      speakingTimeNotificationVariables.speaking_mid_threshold_notification_enabled as boolean;
    this.isSpeakingUpperThresholdNotificationEnabled =
      speakingTimeNotificationVariables.speaking_upper_threshold_notification_enabled as boolean;
    if (speakingTimeNotificationVariables.call_duration_trigger_mins) {
      this.USER_SPEECH_NOTIFICATION_TIMER_DURATION =
        (speakingTimeNotificationVariables.call_duration_trigger_mins as number) * 60 * 1000;
    }
    combineLatest([this.providerStateService.callConnected$, this.spaceRepo.isCurrentUserHost$])
      .pipe(untilDestroyed(this), distinctUntilArrayItemChanged())
      .subscribe(([callConnected, isUserHost]) => {
        if (callConnected && isUserHost) {
          this.telemetry.debugEvent('live_notification', { eventType: 'starting_timer' });
          this.userSpeechDetectionManager?.resetSpeechTime();
          this.userSpeechNotificationTimer = modifiedSetTimeout(() => {
            this.promptUserSpeakingNotifications();
          }, this.USER_SPEECH_NOTIFICATION_TIMER_DURATION);
        } else {
          if (this.userSpeechNotificationTimer) {
            this.telemetry.debugEvent('live_notification', { eventType: 'clear_timer' });
            clearTimeout(this.userSpeechNotificationTimer);
          }
          this.userSpeechDetectionManager?.unsubscribe();
        }
      });
  }

  setRealTimeInsight(insight: AnalyticsInsightType, value: number) {
    const roomUid = this.spaceRepo.activeSpaceCurrentRoom?.uid;
    if (!roomUid) {
      return;
    }
    if (!this.realTimeInsights.get(roomUid)) {
      this.realTimeInsights.set(roomUid, new Map());
    }
    const roomInsights = this.realTimeInsights.get(roomUid)!;
    roomInsights.set(insight, (roomInsights.get(insight) ?? 0) + value);
  }

  handleManualEndSession(data: { newSessionId: string; oldSessionId: string }): void {
    if (data.newSessionId) {
      this.setCurrentSessionId(data.newSessionId);
    }
    // If the session was manually ended by user/API, we need to check if the user was muted/unmuted
    // If the user was unmuted, we need to log these events so the analytics computation is accurate
    if (data.oldSessionId) {
      if (this.rtcServiceController.service.isCameraEnabled()) {
        this.addToAnalyticsEventsBatch(AnalyticsEventType.UNMUTE_VIDEO);
      }
      if (this.rtcServiceController.service.isMicEnabled()) {
        this.addToAnalyticsEventsBatch(AnalyticsEventType.UNMUTE_AUDIO);
      }
    }
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SpaceDataLevel } from '../session_analytics_utils';

@Component({
  selector: 'app-rooms-selection-dropdown',
  templateUrl: './rooms-selection-dropdown.component.html',
  styleUrls: ['./rooms-selection-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomsSelectionDropdownComponent {
  @Input() activeRooms!: Array<{ roomUid: string; roomName: string }>;
  @Input() archivedRooms!: Array<{ roomUid: string; roomName: string }>;

  // Emits SpaceDataLevel.SPACE when 'All Rooms' is selected, the roomUid otherwise
  @Output() selectedRoom = new EventEmitter<SpaceDataLevel | string>();
  selectedRoom$ = this.selectedRoom.asObservable();
  SpaceDataLevel = SpaceDataLevel;
}

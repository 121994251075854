import { Component, Input } from '@angular/core';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-user-profile-card',
  templateUrl: './user-profile-card.component.html',
  styleUrls: ['./user-profile-card.component.scss'],
})
export class UserProfileCardComponent {
  @Input() user?: User;
  @Input() heroImage?: string;

  profileDetails = [
    {
      icon: 'public',
      title: 'Website',
      data: 'www.kainetik.com',
    },
    {
      icon: 'grading',
      title: 'Grades',
      data: 'Primary 4 to Secondary 4',
    },
    {
      icon: 'school',
      title: 'Subjects',
      data: 'Mathematics',
    },
    {
      icon: 'event_available',
      title: 'Availability',
      data: 'Weekdays',
    },
    {
      icon: 'place',
      title: 'Location',
      data: 'Online, Tutoring centre',
    },
  ];

  message = '';

  sendMessage(): void {
    window.location.href = `mailto:${this.user?.email}?body=${this.message}`;
    this.message = '';
  }
}

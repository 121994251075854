import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PicassoService {
  constructor(private http: HttpClient) {}

  generateAndFetch(prompt: string): Observable<any> {
    const urlToFetch = `${environment.picassoServer}/?prompt=${prompt}`;
    return this.http.get<any>(urlToFetch, { responseType: 'blob' as 'json' });
  }
}

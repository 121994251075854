<div class="d-flex users-component" *ngIf="isFetchingComplete">
  <div
    class="users-list d-flex flex-column"
    [class.mobile-show]="showList"
    [class.mobile-hide]="!showList"
  >
    <mat-form-field class="w-100 cpx-16 border-bottom">
      <input
        matInput
        [placeholder]="'Search people' | translate"
        type="text"
        [(ngModel)]="searchText"
        (keyup)="search()"
      />
      <button mat-icon-button matSuffix>
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
    <div class="profile-list">
      <ng-container *ngFor="let profile of filteredList; let i = index">
        <div class="list-header cpy-16 cpx-24 border-bottom" *ngIf="getHeader(profile, i)">
          {{ headers[i + 1] }}
        </div>
        <div
          style="cursor: pointer"
          class="media user-item border-bottom cpy-16 cpx-24 align-items-center"
          [class.selected]="profile._id === queryParams['id']"
          (click)="selectUserId(profile._id); showList = false"
        >
        <svg-icon class="svg-icon" [icon]="'expanded_list_item'"></svg-icon>
          <app-profile-photo
            [user]="profile"
            [fontSize]="18"
            [width]="50"
            class="cmr-16"
          ></app-profile-photo>
          <div class="media-body">
            <h6 class="cmb-0">{{ profile.name }}</h6>
            <p>{{ profile.role ? profile.role + ', ' : '' }}{{ profile.email }}</p>
            <p class="cmt-0">{{ profile.org_name }}</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div
    class="py-lg-4 cpx-16 py-md-3 border-left user-detail flex-1"
    [class.mobile-show]="!showList"
    [class.mobile-hide]="showList"
  >
    <app-user-profile
      *ngIf="isFullInfoMode"
      (showList)="showList = $event"
      [fullProfile]="fullInfoModeProfileExtendedInfo"
    ></app-user-profile>
  </div>
</div>

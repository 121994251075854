import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { User, Course } from 'src/app/models/user';
import { QuestionsService } from 'src/app/services/questions.service';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Feature, AclService } from '../../services/acl.service';
import { DIFFICULTY_TO_LABEL, Question } from '../../models/question';

@UntilDestroy()
@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent implements OnInit {
  @Input() question?: Question;
  @Input() selected?: boolean;
  @Input() expanded?: boolean;
  @Input() user?: User;
  @Input() from?: string;
  @Input() pageType = 'questions';
  @Input() hideUserProfile?: boolean;
  @Input() customTags? = [];
  @Input() tagRemoveButton = false;
  @Output() hoveringTags = new Subject();
  @Output() tagRemoved: EventEmitter<void> = new EventEmitter();
  @ViewChild('tagsScrollContainer') tagsScrollContainer!: ElementRef;

  touchPoint = 0;
  worksheetNames: string[] = [];
  course?: Course;
  topics: any[] = [];
  topicsMap = new Map();
  Features = Feature;
  courseMap = new Map();
  maxTagNameLength = 14;
  maxCourseNameLength = 6;
  DIFFICULTY_TO_LABEL = DIFFICULTY_TO_LABEL;

  constructor(
    public userService: UserService,
    private questionsService: QuestionsService,
    public aclService: AclService,
  ) {
    this.questionsService.selectedCourse.pipe(untilDestroyed(this)).subscribe((res) => {
      this.course = res ?? undefined;
    });
  }

  ngOnInit(): void {
    if (this.question && this.question.worksheets) {
      this.question.worksheets.forEach((worksheet) => {
        this.worksheetNames.push(worksheet.title);
      });
    }
    this.user?.courses.forEach((course) => {
      this.courseMap = this.courseMap.set(course._id, course.name);
    });

    this.userService.topicsMap.pipe(untilDestroyed(this)).subscribe((res) => {
      this.topicsMap = res ?? new Map<any, any>();
      this.topics = [];
      if (this.question?.metadata?.topics_ids?.length) {
        for (const id of this.question.metadata.topics_ids) {
          if (
            this.topicsMap.get(id) &&
            this.topicsMap.get(id).numbering &&
            this.topicsMap.get(id).level === 1
          ) {
            this.topics.push(this.topicsMap.get(id));
          }
        }
      } else if (
        this.question &&
        (!this.question.metadata ||
          !this.question.metadata.topics_ids ||
          this.question.metadata.topics_ids.length === 0) &&
        this.question.sub_questions
      ) {
        this.question.sub_questions.forEach((sub) => {
          if (sub.metadata && sub.metadata.topics_ids) {
            for (const id of sub.metadata.topics_ids) {
              if (
                this.topicsMap.get(id) &&
                this.topicsMap.get(id).numbering &&
                this.topicsMap.get(id).level === 1 &&
                this.topics.findIndex((t) => t._id === id) < 0
              ) {
                this.topics.push(this.topicsMap.get(id));
              }
            }
          }
        });
      }
      this.topics.sort((a, b) => a.position - b.position);
    });
  }

  isExist(topicIds, topic): boolean {
    if (topic.child) {
      if (topicIds.includes(topic.child._id)) {
        return true;
      } else {
        return this.isExist(topicIds, topic.child);
      }
    } else {
      return false;
    }
  }

  mouseWheel(event: WheelEvent): void {
    if (this.tagsScrollContainer) {
      this.tagsScrollContainer.nativeElement.scrollLeft += event.deltaY;
    }
  }

  touchStart(e: TouchEvent): void {
    this.touchPoint = e.changedTouches[0].clientX;
  }

  touchEnd(e: TouchEvent): void {
    this.touchPoint = e.changedTouches[0].clientX;
  }

  touchMove(e: TouchEvent): void {
    if (this.tagsScrollContainer) {
      this.tagsScrollContainer.nativeElement.scrollLeft +=
        this.touchPoint - e.changedTouches[0].clientX;
    }
    this.touchPoint = e.changedTouches[0].clientX;
  }

  handleMouseEnter(): void {
    this.hoveringTags.next(true);
  }

  handleMouseLeave(): void {
    this.hoveringTags.next(false);
  }
}

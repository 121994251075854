import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Auth, signInWithCustomToken } from '@angular/fire/auth';
import { ToastrService } from 'ngx-toastr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalManagerService } from 'src/app/services/modal-manager.service';
import { SpaceRepository } from 'src/app/state/space.repository';

import { filter } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { NavService } from '../services/nav.service';
import { PwaService } from '../services/pwa-service.service';
import { Course, User, roleIDs, roleValueIDs } from '../models/user';
import { UserService } from '../services/user.service';
import { QuestionsService } from '../services/questions.service';
import { FlagsService, FLAGS } from '../services/flags.service';
import { Feature, AclService } from '../services/acl.service';
import { URL_CONSTANTS } from '../common/utils/url';
import { UiService } from '../services/ui.service';
import { pencilLogoText } from '../app.constants';
import { environment } from '../../environments/environment';
import {
  ReferralSource,
  ReferralDialogComponent,
} from '../common/referral-dialog/referral-dialog.component';
import { UIPropsRepository } from '../state/ui-props.repository';
import { CapabilityTierService } from '../services/capability-tier.service';
import { Capability } from '../models/capability';
import { FeatureFlagsComponent } from './feature-flags/feature-flags.component';

@UntilDestroy()
@Component({
  selector: 'app-impersonate-dialog',
  templateUrl: 'impersonate-dialog.html',
  styleUrls: ['./impersonate.component.scss'],
})
export class ImpersonateDialogComponent {
  email = '';

  constructor(
    private auth: Auth,
    public dialogRef: MatDialogRef<ImpersonateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private flagsService: FlagsService,
    private toastrService: ToastrService,
  ) {}

  impersonate(): void {
    if (!this.email) {
      return;
    }
    this.userService
      .impersonate(this.email)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res && res.token) {
          this.flagsService.resetFlags();
          signInWithCustomToken(this.auth, res.token).then(() => {
            location.reload();
          });
        } else {
          this.toastrService.error(res.message);
        }
      });
  }
}

@UntilDestroy()
@Component({
  selector: 'ui-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
  isReferFriendEnabled = false;
  active = '';
  courses: Course[] = [];
  userId = '';
  expanded = '';
  user?: User;
  innerWidth = window.innerWidth;
  isCalcOpened = false;
  isGraphOpened = false;
  isPeriodicTableOpened = false;
  isImpersonateClicked = false;
  isFeatureFlagsClicked = false;
  isUsingCustomFlags = false;
  selectedCourseId = '';
  isIntercomEnabled = true;
  isDoorbellEnabled = false;
  isProfilesPageEnabled = false;
  Features = Feature;
  isComprehensionEnabled = false;
  isMessagesEnabled = false;
  isFilesMenuEnabled = false;
  public activeRoute = '';
  public onCoursePage = false;
  isGuestUser = false;
  marketingURL = environment.marketingUrl;
  hidePoweredByPencil = false;
  pencilLogoText = pencilLogoText;
  isScheduleEnabled = false;
  public isAttendanceReportEanbled = false;
  public isAnalyticstReportEnabled = false;
  constructor(
    public authService: AuthService,
    public pwaService: PwaService,
    public navService: NavService,
    public dialog: MatDialog,
    public router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private questionsService: QuestionsService,
    private flagsService: FlagsService,
    public aclService: AclService,
    public uiService: UiService,
    public modalManagerService: ModalManagerService,
    public spaceRepo: SpaceRepository,
    private uiRepo: UIPropsRepository,
    private capabilityTierService: CapabilityTierService,
  ) {
    this.setActiveRoute();
    this.initNavservice();
    this.initUserService();
    this.initRouterService();

    this.flagsService
      .featureFlagChanged(FLAGS.ENABLE_SCHEDULE)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.isScheduleEnabled = res;
      });

    this.flagsService
      .featureFlagChanged(FLAGS.PROJECT_SAM)
      .pipe(
        untilDestroyed(this),
        filter((flagStatus) => !!flagStatus),
      )
      .subscribe(() => {
        this.isAttendanceReportEanbled =
          !!this.flagsService.featureFlagsVariables.project_sam.attendance_report;

        this.isAnalyticstReportEnabled =
          !!this.flagsService.featureFlagsVariables.project_sam.analytics_report;
      });

    this.questionsService.selectedCourseId.pipe(untilDestroyed(this)).subscribe((res) => {
      this.selectedCourseId = res;
      this.getActive();
    });

    if (window.innerWidth < 768) {
      this.navService.isMenuOpened.next(false);
    }
    this.isUsingCustomFlags = this.flagsService.customFeatureFlags != null;
    this.isDoorbellEnabled = this.flagsService.isFlagEnabled(FLAGS.ENABLE_DOORBELL);
    this.isProfilesPageEnabled = this.flagsService.isFlagEnabled(FLAGS.PROFILES_PAGE);
    this.isMessagesEnabled = !this.flagsService.isFlagEnabled(FLAGS.DISABLE_MESSAGING);
    this.isComprehensionEnabled = this.flagsService.isFlagEnabled(FLAGS.ENABLE_COMPREHENSION);
    this.isFilesMenuEnabled = this.capabilityTierService.isCapabilityEnabled(
      Capability.PENCIL_FILES,
    );
    if (this.user) {
      // Check if user is a self serve/guest user
      this.isGuestUser = this.aclService.isGuest(this.user);
    }
  }

  initNavservice(): void {
    this.navService.showCalculator.pipe(untilDestroyed(this)).subscribe((res) => {
      this.isCalcOpened = res;
    });

    this.navService.showGraph.pipe(untilDestroyed(this)).subscribe((res) => {
      this.isGraphOpened = res;
    });

    this.navService.showPeriodicTable.pipe(untilDestroyed(this)).subscribe((res) => {
      this.isPeriodicTableOpened = res;
    });
  }

  initUserService(): void {
    this.userService.user.pipe(untilDestroyed(this)).subscribe((res: any) => {
      if (res?.user as User) {
        this.userId = res.user._id;
        this.courses = res.user.courses;
        this.user = res.user;
        this.checkPoweredByPencilSetting();
        const uniqueDays = this.uiRepo.uiProps.spacesVisitedUniqueDays;
        if (
          this.flagsService.isFlagEnabled(FLAGS.SHOW_REFER_FRIEND) &&
          res.user.info?.roleType === roleIDs.education &&
          res.user.info?.role !== roleValueIDs.student &&
          !this.user?.institution &&
          uniqueDays > 3
        ) {
          this.isReferFriendEnabled = true;
        }
      }
    });
  }

  initRouterService(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe(() => {
      this.getActive();
    });

    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(() => {
      this.getActive();
    });

    this.router.events.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res instanceof NavigationEnd) {
        this.getActive();
        this.setActiveRoute();
      }
    });
  }

  public setActiveRoute() {
    this.activeRoute = this.router.url;
    this.onCoursePage =
      (!this.activeRoute.includes('/content/course/new') &&
        this.activeRoute.includes('/content/course')) ||
      this.activeRoute.includes('/content/worksheets');
  }

  getActive(): void {
    if (this.router.url === '/content') {
      this.active = 'Courses';
    } else if (this.router.url.startsWith('/users') && !this.router.url.includes(this.userId)) {
      this.active = 'Teachers';
      this.expanded = 'Profiles';
    } else if (this.router.url.startsWith('/users') && this.router.url.includes(this.userId)) {
      this.active = 'My profile';
      this.expanded = 'Profiles';
    } else if (this.router.url.startsWith('/messages')) {
      this.active = 'Messages';
    } else if (this.router.url.startsWith('/schedule')) {
      this.active = 'Schedule';
    } else if (this.router.url.startsWith('/settings/billing')) {
      this.active = 'Billing and Payment';
    } else if (this.router.url.startsWith('/admin')) {
      this.active = 'Admin Portal';
    } else if (this.router.url.startsWith('/profiles')) {
      this.active = 'Profiles';
    } else if (this.router.url.startsWith('/content/files')) {
      this.active = 'Files';
    } else if (this.router.url.startsWith('/analytics')) {
      this.active = 'Analytics';
    } else {
      this.active = '';
      this.courses.forEach((c) => {
        if (c._id === this.selectedCourseId) {
          this.active = c.name;
        }
      });
    }
    if (!this.router.url.includes('course/settings')) {
      this.navService.headerTitle.next(this.active.split('/')[0]);
    }
  }

  closeOpenGadgets(): void {
    /**
     * if any of gadgets is open close it on logout
     */
    if (this.navService.isCalculatorOpen) {
      this.navService.closeCalculator();
    }
    if (this.navService.isGraphOpen) {
      this.navService.closeGraph();
    }
    if (this.navService.isPeriodicTableOpen) {
      this.navService.closePeriodicTable();
    }
  }

  goTo(name: string): void {
    if (window.innerWidth < 768) {
      this.navService.isMenuOpened.next(false);
    }
    this.active = name;
    switch (name) {
      case 'Courses':
        this.router.navigate(['/content']);
        break;
      case 'Messages':
        this.router.navigate(['messages', 'home'], { queryParamsHandling: 'merge' });
        break;
      case 'Schedule':
        this.router.navigate(['/schedule']);
        break;
      case 'My profile':
        this.router.navigate(['/users'], { queryParams: { id: this.userId } });
        break;
      case 'Teachers':
        this.router.navigate(['/users']);
        break;
      case 'Admin Portal':
        this.router.navigate(['/admin']);
        break;
      case 'Profiles':
        this.router.navigate(['/profiles']);
        break;
      case 'Whiteboard':
        this.router.navigate([`/${URL_CONSTANTS.SPACES}`]);
        break;
      case 'Analytics/Attendance':
        this.router.navigate(['/analytics/attendance']);
        break;
      case 'Analytics/Engagement':
        this.router.navigate(['/analytics/engagement']);
        break;
      case 'Files':
        this.router.navigate(['/content/files']);
        break;
    }
  }

  @HostListener('window:resize') onResize(): void {
    if (window.innerWidth !== this.innerWidth) {
      if (window.innerWidth < 768) {
        this.navService.isMenuOpened.next(false);
      } else {
        this.navService.isMenuOpened.next(true);
      }
    }
    this.innerWidth = window.innerWidth;
  }

  toggleCalc(): void {
    this.navService.toggleCalculator();
  }

  toggleGraph(): void {
    this.navService.toggleGraph();
  }

  togglePeriodicTable(): void {
    this.navService.togglePeriodicTable();
  }

  impersonate(): void {
    this.isImpersonateClicked = true;
    const dialogRef = this.dialog.open(ImpersonateDialogComponent, {
      width: '340px',
      panelClass: 'impersonate-dialog-container',
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isImpersonateClicked = false;
      });
  }

  showFeatureFlags(): void {
    this.isFeatureFlagsClicked = true;
    const dialogRef = this.dialog.open(FeatureFlagsComponent, {
      width: '600px',
      panelClass: 'feature-flags-dialog-container',
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isFeatureFlagsClicked = false;
      });
  }

  closeMenu(): void {
    if (window.innerWidth < 576) {
      this.navService.isMenuOpened.next(false);
    }
  }

  reportProblem() {
    if (!this.user) {
      return;
    }
    const doorbell: any = window['doorbell'];
    if (doorbell && this.user) {
      doorbell.setOption('email', this.user.email);
      doorbell.setOption('name', this.user.name);
      doorbell.setOption('properties', {
        id: this.user._id,
      });
      doorbell.refresh();
      doorbell.show();
    }
  }

  moreMenuOpened() {
    this.expanded = 'more';
  }

  goToCourse(courseId: string): void {
    if (!this.user) {
      return;
    }
    const course = this.user?.courses.find((c) => c._id === courseId);
    if (this.aclService.isParent(this.user)) {
      if (course) {
        this.uiService.setTabTitle(`Analytics | ${course.name}`, false);
      } else {
        this.uiService.setTabTitle('Analytics');
      }
      this.router.navigate(['/content/course/questions', courseId, 'analytics']);
    } else if (this.aclService.isAllowed(this.user, Feature.view_lesson)) {
      if (course) {
        this.uiService.setTabTitle(`Lessons | ${course.name}`, false);
      } else {
        this.uiService.setTabTitle('Lessons');
      }
      this.router.navigate(['/content/course/lessons', courseId]);
    } else {
      if (course) {
        this.uiService.setTabTitle(`Questions | ${course.name}`, false);
      } else {
        this.uiService.setTabTitle('Questions');
      }
      this.router.navigate(['/content/course/questions', courseId]);
    }

    if (window.innerWidth < 576) {
      this.navService.isMenuOpened.next(false);
    }
  }

  shareReferral(): void {
    const shareSessionReferralParams = {
      panelClass: 'referral-wb-dialog',
      autoFocus: false,
      data: {
        referralSource: ReferralSource.INVITE,
        sessionId: this.spaceRepo.activeSpace?._id,
      },
    };

    this.modalManagerService.showModal(ReferralDialogComponent, shareSessionReferralParams);
  }

  checkPoweredByPencilSetting(): void {
    // Default is show, which will be the case for
    // institution which don't have settings or hidePoweredByPencil is false
    if (this.user?.institution?.settings) {
      this.hidePoweredByPencil = this.user.institution.settings.hidePoweredByPencil;
    }
  }
}

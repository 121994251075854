<div
  [ngClass]="{
    'loading-block--float': floatLoader,
    'no-border': !border,
    'd-flex flex-wrap  justify-content-start': file
  }"
  [ngStyle]="{ 'margin-top': top + 'px' }"
>
  <!-- top tags loading block -->
  <div *ngIf="topTags" class="loading-block">
    <div class="d-flex">
      <div class="loading-item loading-item--small"></div>
      <div class="loading-item loading-item--small"></div>
      <div class="loading-item loading-item--small"></div>
    </div>
  </div>

  <div
    class="loading-blocks"
    *ngFor="let i of blocks"
    [ngClass]="{
      'loading-block--flex': file,
      'loading-block': !file,
      'equal-lines': equalLines
    }"
  >
    <div [ngClass]="{ 'd-flex': list }">
      <div *ngIf="list" class="loading-item loading-item--li"></div>
      <div class="loading-item" [ngClass]="{ 'loading-item--large': file }"></div>
    </div>
    <div>
      <div [ngClass]="{ 'd-flex': list }" *ngIf="!singleLineList">
        <div *ngIf="list" class="loading-item loading-item--li"></div>
        <div *ngIf="!tags" class="loading-item loading-item--medium"></div>
      </div>
      <div class="d-flex" *ngIf="tags">
        <div class="loading-item loading-item--small"></div>
        <div class="loading-item loading-item--small"></div>
        <div class="loading-item loading-item--small"></div>
      </div>
    </div>
  </div>
</div>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { URLService } from './dynamic-url.service';

@Injectable({
  providedIn: 'root',
})
export class ReferralService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  upgradedReferrals = new BehaviorSubject([]);

  constructor(private http: HttpClient, private urlService: URLService) {}

  createReferral(user_id: string, space_id: string, is_for_link: boolean): Observable<any> {
    const body = {
      user_id,
      space_id,
      is_for_link,
    };

    return this.http.post(
      `${this.urlService.getDynamicUrl()}/tutor/referral`,
      JSON.stringify(body),
      this.httpOptions,
    );
  }

  getAcceptedReferrals(user_id: string): Observable<any> {
    return this.http.get(
      `${this.urlService.getDynamicUrl()}/tutor/acceptedReferrals/${user_id}`,
      this.httpOptions,
    );
  }

  updateUpgradedReferralViewed(referralId: string): Observable<any> {
    return this.http.post(
      `${this.urlService.getDynamicUrl()}/tutor/updateUpgradedReferralViewed`,
      JSON.stringify({ referralId }),
      this.httpOptions,
    );
  }

  buildInviteLink(params: URLSearchParams) {
    const queryIndex = location.href.indexOf(location.search);
    let link = location.href.slice(0, queryIndex ? queryIndex : location.href.length);
    link += `?${params.toString()}`;
    return link;
  }

  buildInviteLinkFromSpaceId(spaceId: string, params?: URLSearchParams) {
    let paramsString = params?.toString();
    paramsString = paramsString ? `?${paramsString}` : '';
    return `${location.origin}/spaces/${spaceId}${paramsString}`;
  }

  sendReferralEmails(referralId: string, referredEmails: string, spaceId: string) {
    const body = {
      referral_id: referralId,
      referred_emails: referredEmails,
    };

    return this.http.post(
      `${this.urlService.getDynamicUrl()}/spaces/sendReferralEmail/${spaceId}`,
      body,
      this.httpOptions,
    );
  }
}

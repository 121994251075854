import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subQuestionPrintMargin',
})
export class SubQuestionPrintMarginPipe implements PipeTransform {
  transform(printOptions): any {
    return `${parseInt(printOptions.fontSize, 10) * printOptions.lineHeight * 2  }px`;
  }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'simple-search-box',
  templateUrl: './simple-search-box.component.html',
  styleUrls: ['./simple-search-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleSearchBoxComponent {
  @Input() public boxHeight = 'auto';
  @Input() public placeHolder = 'Search';
  @Input() public searchControl!: FormControl;
}

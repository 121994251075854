import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncateString', standalone: true })
export class TruncateStringPipe implements PipeTransform {
  transform(inputString: string, length = 20, fileName = false): string {
    if (!inputString) {
      return '';
    }
    if (inputString?.length <= length) {
      return inputString;
    }
    if (fileName) {
      const split = inputString.split('.');
      const format = split.pop() || ''; // ex: .pdf
      const joinedText = split.join('');
      // regex tests if file name ends with copy, copy(number), copy (number)
      const regex = /^(.+)(copy\s*(?:\(\s*\d+\s*\))?)/i;
      const replaced = joinedText.replace(regex, '$1').trim();

      if (replaced.length - format.length <= length) {
        return `${replaced}.${format}`;
      }

      const lastChar = replaced.charAt(replaced.length - 1);
      return `${replaced.substring(0, length - (format || '').length)}...${lastChar}.${format}`;
    }

    return `${inputString.substring(0, length)}... `;
  }
}

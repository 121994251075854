import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, delay, takeWhile, tap } from 'rxjs';
import { create } from 'canvas-confetti';
import { SpaceRepository } from '../state/space.repository';
import { modifiedTimer } from '../utilities/ZoneUtils';

export enum CELEBRATIONS_ITEMS {
  GLOWING_STARS,
  PARTY_POPPERS,
  FIREWORKS,
  CONFETTI_SALUTE,
}

export const CELEBRATIONS_LIST = [
  {
    name: CELEBRATIONS_ITEMS.GLOWING_STARS,
    asset: '/assets/icons/celebrations/glowing-star.svg',
    tooltip: 'Starburst',
  },
  {
    name: CELEBRATIONS_ITEMS.PARTY_POPPERS,
    asset: '/assets/icons/celebrations/party-popper.svg',
    tooltip: 'Party poppers',
  },
  {
    name: CELEBRATIONS_ITEMS.FIREWORKS,
    asset: '/assets/icons/celebrations/sparkles.svg',
    tooltip: 'Fireworks',
  },
  {
    name: CELEBRATIONS_ITEMS.CONFETTI_SALUTE,
    asset: '/assets/icons/celebrations/partying-face.svg',
    tooltip: 'Special entrance',
  },
];

@UntilDestroy()
@Injectable()
export class CelebrationsService {
  private DEBOUNCE_TIME_MS: number = 1000;
  private confettiRunner?: confetti.CreateTypes;
  private isDebounced$ = new BehaviorSubject<boolean>(false);

  constructor(private spaceRepo: SpaceRepository) {
    this.confettiRunner = create(undefined, { useWorker: true, resize: true });
    this.isDebounced$
      .pipe(
        untilDestroyed(this),
        delay(this.DEBOUNCE_TIME_MS),
        tap((x) => {
          if (x) {
            this.isDebounced$.next(false);
          }
        }),
      )
      .subscribe();
  }

  private _fireStars(inConf: confetti.CreateTypes) {
    const defaults = {
      spread: 360,
      ticks: 50,
      gravity: 0,
      decay: 0.94,
      startVelocity: 30,
      shapes: ['star'],
      colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8'],
    };
    modifiedTimer(0, 100)
      .pipe(
        takeWhile((i) => i < 3),
        tap(() => {
          inConf({
            ...defaults,
            particleCount: 40,
            scalar: 1.2,
            shapes: ['star'],
          });

          inConf({
            ...defaults,
            particleCount: 10,
            scalar: 0.75,
            shapes: ['circle'],
          });
        }),
      )
      .subscribe();
  }

  private _firePopperCelebration(inConf: confetti.CreateTypes) {
    modifiedTimer(0, 500)
      .pipe(
        takeWhile((i) => i < 3),
        tap((i) => {
          let config: object = {
            origin: { y: 0.8 },
            spread: 70,
            particleCount: 50,
            startVelocity: 60,
            scalar: 1.2,
          };
          switch (i) {
            case 0:
              config = { ...config, angle: 45 }; // Angle top left
              break;
            case 1:
              config = { ...config, angle: 135 }; // Angle top right
              break;
            case 2:
              config = { ...config, angle: 90 }; // Angle top
              break;
          }
          inConf(config);
        }),
      )
      .subscribe();
  }

  private _fireFireworks(inConf: confetti.CreateTypes) {
    const defaults = {
      startVelocity: 20,
      spread: 360,
      ticks: 50,
      zIndex: 0,
      particleCount: 30,
      colors: ['#DC143C', '#FF4500', '#FF00FF', '#800080', '#FFFF00'],
    };
    const interval = 300;
    const maxTime = 3000;
    modifiedTimer(0, interval)
      .pipe(
        takeWhile((i) => i < maxTime / interval),
        tap(() => {
          inConf({
            ...defaults,
            origin: { x: Math.random() * (0.3 - 0.1) + 0.1, y: Math.random() - 0.2 }, // Show randomly between x = [0.1 - 0.3] and with mean y = 0.3
          });
          inConf({
            ...defaults,
            origin: { x: Math.random() * (0.9 - 0.7) + 0.7, y: Math.random() - 0.2 }, // Show randomly between x = [0.7 - 0.9] and with mean y = 0.3
          });
        }),
      )
      .subscribe();
  }

  private _fireConfettiSalute(inConf: confetti.CreateTypes) {
    const interval = 300;
    const maxTime = 3000;
    modifiedTimer(0, interval)
      .pipe(
        takeWhile((i) => i < maxTime / interval),
        tap(() => {
          const colors = ['#2F80ED', '#E5616D'];
          inConf({
            particleCount: 50,
            angle: 60,
            spread: 55,
            origin: { x: 0, y: 0.7 }, // Start at y = 0.7 on far left
            colors: colors,
          });
          inConf({
            particleCount: 50,
            angle: 120,
            spread: 55,
            origin: { x: 1, y: 0.7 }, // Start at y = 0.7 on far right
            colors: colors,
          });
        }),
      )
      .subscribe();
  }

  public fireCelebration(celebrationId: number) {
    if (!this.confettiRunner || this.isDebounced$.value) {
      return;
    }
    this.isDebounced$.next(true); // Debounce celebration

    switch (CELEBRATIONS_LIST[celebrationId].name) {
      case CELEBRATIONS_ITEMS.PARTY_POPPERS:
        this._firePopperCelebration(this.confettiRunner);
        break;
      case CELEBRATIONS_ITEMS.GLOWING_STARS:
        this._fireStars(this.confettiRunner);
        break;
      case CELEBRATIONS_ITEMS.CONFETTI_SALUTE:
        this._fireConfettiSalute(this.confettiRunner);
        break;
      case CELEBRATIONS_ITEMS.FIREWORKS:
        this._fireFireworks(this.confettiRunner);
        break;
    }
  }

  public canCelebrate$: Observable<boolean> = this.spaceRepo.isCurrentUserHost$;

  public checkIfDisabled$(): Observable<boolean> {
    return this.isDebounced$.asObservable();
  }
}

// Enumerate all event categories
export enum EventCategory {
  Draw = 'Draw',
  Frames = 'Frames',
  Text = 'Text',
  ItemCanvas = 'ItemCanvas',
  Users = 'Users',
  Shapes = 'Shapes',
  CallReliability = 'CallReliability',
  ParticipantsManager = 'ParticipantsManager',
  CallManager = 'CallManager',
  LocalRecordingEvent = 'RecordingEvent',
  UsersForegroundActivity = 'UsersForegroundActivity',
}

// An event class that is used to sync session state
// across participants using the RTM channel.
export class SessionEvent {
  // The category represents the function area that generated
  // the event, for example "draw" , "shapes", "users"...etc
  category: EventCategory;
  // The type represents the type of the event, for example the
  // shapes toolbar can define the following "Create", "Update", "delete".
  type: string;
  // The data represents the object, it's needed by the session participants
  // in order to sync the session on their sides.
  data: any;

  constructor(category: EventCategory, type: string, data: any) {
    this.category = category;
    this.type = type;
    this.data = data;
  }
}

// An event subscriber
export interface SessionEventSubscriber {
  sessionEventReceived(event: SessionEvent, userId: string): void;
}

// Handles subscription to events
export interface SessionEventController {
  // Sends an event.
  sendEvent(sender: any, event: SessionEvent): void;

  // Subscribe to receive an event. If the subscriber is the same object that
  // sent the event, the callback will not be called.
  subscribeToEvent(subscriber: SessionEventSubscriber, category: EventCategory): void;

  unSubscribeFromEvent(subscriber: SessionEventSubscriber, category: EventCategory): void;
}

import { isArray } from 'util';
import { ChangeDetectorRef, Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ResourceFilterParams, ResourceFilterParamsEnum } from 'src/app/models/params';
import { IFolderResponse, IResource, ResourceItemModel } from 'src/app/models/resource';
import { ISuggestionsGroup, ISuggestion } from 'src/app/models/searching';
import { TopicList, TopicNode, TopicRootNode } from 'src/app/models/user';
import { ResourcesService } from 'src/app/services/resources.service';
import { ThumbnailService } from 'src/app/services/thumbnail.service';
import { TagsService } from 'src/app/services/tags.service';
import { UserService } from 'src/app/services/user.service';
import { FileFilterService } from 'src/app/services/file-filter.service';
import { EmbedVideoService } from '../../../services/embed-video.service';
import { FilesComponent } from '../files.component';
import { FileParam, Filter, FilterSet } from '../../../models/filters';

/**
 * To display navigation
 */
interface IDisplayPath {
  _id: string;
  name: string;
}

@UntilDestroy()
@Component({
  selector: 'app-files-extension[enforcingFilter][mandatoryType]',
  templateUrl: './files-extension.component.html',
  styleUrls: ['./files-extension.component.scss', '../files.component.scss'],
})
export class FilesExtensionComponent extends FilesComponent implements OnInit {
  path: IDisplayPath[] = [];

  // The enforced type of that dialog, it can be 'Image', 'Video', etc.
  @Input() mandatoryType!: ResourceItemModel | ResourceItemModel[];

  @Input() showResultsNumber = true;

  // To emit the selected file.
  @Output() fetchFile = new EventEmitter<IResource[]>();

  // auto upload file on double click
  @Output() autoUpload = new EventEmitter<boolean>();

  // To emit events when filters changes to display it in the search bar.
  filterEmitter = new Subject<ISuggestion>();

  filterSetsToSuggestionGroup: Map<FilterSet, ISuggestionsGroup> = new Map<
    FilterSet,
    ISuggestionsGroup
  >();

  suggestionGroupsWithoutFilters: ISuggestionsGroup[] = [];

  gridView = true;

  // To use the Enum in Html template.
  FilterTypes = FileParam;

  constructor(
    _resourceService: ResourcesService,
    _router: Router,
    _embedService: EmbedVideoService,
    _dialog: MatDialog,
    _translate: TranslateService,
    _userService: UserService,
    _snackBar: MatSnackBar,
    _activatedRoute: ActivatedRoute,
    _changeDetection: ChangeDetectorRef,
    _thumbnailService: ThumbnailService,
    _tagService: TagsService,
    _fileFilterService: FileFilterService,
  ) {
    super(
      _resourceService,
      _router,
      _dialog,
      _translate,
      _userService,
      _snackBar,
      _activatedRoute,
      _changeDetection,
      _tagService,
      _fileFilterService,
    );
  }

  get suggestionsGroup() {
    return Array.from(this.filterSetsToSuggestionGroup.values()).concat(
      this.suggestionGroupsWithoutFilters,
    );
  }

  ngOnInit(): void {
    // Enable multiple course filters
    const courseFilterSet = this.fileFilterService.filterSetsByType.get(FileParam.Course);
    if (courseFilterSet) {
      courseFilterSet.isList = true;
    }

    // load Topic Suggestions.
    this.getTopicSuggestion();

    // init with root
    this.rootId = 'root';

    // Always display in GRID
    this.switchView(true);

    this.fileFilterService.updateFilters();

    this.fileFilterService.filterSets.forEach((filterSet) => {
      filterSet.filters.forEach((filter) => {
        if (filter.value) {
          this.filterEmitter.next(this.convertFilterToSuggestion(filter, filterSet));
        }
      });
    });

    this.getFolder(this.rootId, this.buildFilters()).then((folder) => {
      if (folder) {
        this.appendFilesAndFolders(folder, false, true);
        this.institutionId = this.rootId === 'root' ? folder.name : folder.institution;
        if (this.rootId === 'root') {
          this.rootId = folder._id;
        }
      }
    });
  }

  buildFilters(): ResourceFilterParams {
    const param: ResourceFilterParams = this.fileFilterService.buildFilters();
    // enforcing a single type
    if (this.mandatoryType !== this.resourceTypes.ANY) {
      if (!this.isEnforcedVisible()) {
        param.type = isArray(this.mandatoryType) ? this.mandatoryType : [this.mandatoryType];
        // We decrement it in the parent method for enforcing Filter, but since it can't be selected here, we did undo for that step.
        this.appliedFilters++;
      }
      param[ResourceFilterParamsEnum.EnforcingFilter] = this.enforcingFilter;
    }
    return param;
  }

  modifyFilter(filter: Filter, filterSet: FilterSet): void {
    filter.value = !filter.value;
    this.filterEmitter.next(this.convertFilterToSuggestion(filter, filterSet));
    this.resetView();
  }

  openFolder(folder: IFolderResponse | string, append = false): void {
    // Clear all selections (files / folders).
    this.clearSelections();
    this.clearAllFilters(false);
    this.clearSearch();
    // Close filters overlay whenever a folder is opened.
    this.filterPanelOpen = false;
    this.filePage = 1;
    this.folderPage = 1;
    // We are using build filters although we are clearing filters, but to enforce type.
    this.getFolder(typeof folder === 'string' ? folder : folder._id, this.buildFilters()).then(
      (fetchedFolder) => {
        if (fetchedFolder) {
          this.appendFilesAndFolders(fetchedFolder, true, append);
        }
      },
    );
  }

  appendFilesAndFolders(folder: IFolderResponse, replace = false, append = false): void {
    // To edit the path view without reloading.
    if (this.activeFolder && append) {
      this.path.push({
        _id: this.activeFolder._id,
        name: this.activeFolder.name,
      });
    }
    super.appendFilesAndFolders(folder, replace);
  }

  backInPath(index: number): void {
    const id = this.path[index]._id;
    this.path.splice(index);
    this.openFolder(id);
  }

  /**
   * To reflect when selection.
   */
  handleToggleSelection(autoUpload = undefined): void {
    // close the panel whenever a file is selected
    this.filterPanelOpen = false;
    this.autoUpload.emit(autoUpload);
    this.fetchFile.emit(this.selectedFiles);
  }

  onScrollDown(): void {
    if (this.filesLoading$.getValue()) {
      return;
    }
    this.scrolling = true;
    this.getFolder(this.activeFolder?._id as string, this.buildFilters()).then((folder) => {
      if (folder) {
        this.appendFilesAndFolders(folder);
      }
      this.scrolling = false;
    });
  }

  editFilterFromSearchBar(data: ISuggestion): void {
    // Invalid data, then ignore
    if (!data.secondary[0]) {
      return;
    }

    // Default is topic, and since topic doesn't have corresponding filter, then We need to create filters for it.
    if (!data.metaData?.tagType || data.metaData.tagType === FileParam.Topic) {
      data.secondary[0].ids?.forEach((id) => {
        // creating the new filter.
        const newFilter = new Filter(id, (item: string) => data.secondary[0].name || item);
        newFilter.value = data.metaData?.enable;
        newFilter.xMeta = {
          course: data.main,
          topic: data.secondary[0].name,
        };

        this.fileFilterService.editFilter(
          FileParam.Topic,
          ResourceFilterParamsEnum.Topics,
          newFilter,
          data.metaData?.enable,
        );
      });
    } else {
      data.metaData.filter.value = !!data.metaData?.enable;
    }

    // reset view to apply filters.
    this.resetView();
  }

  /**
   * Used to convert given filter set to Suggestions group, and place the result in filterSetsToSuggestionGroup map
   */
  convertFilterSetsToSuggestions(filterSet: FilterSet): void {
    const suggestionGroup: ISuggestionsGroup = {
      type: filterSet.name,
      suggestions: filterSet.filters.map((filter) =>
        this.convertFilterToSuggestion(filter, filterSet),
      ),
    };
    this.filterSetsToSuggestionGroup.set(filterSet, suggestionGroup);
  }

  /**
   * Used to convert given filter to Suggestion.
   */
  convertFilterToSuggestion(filter: Filter, filterSet: FilterSet): ISuggestion {
    return {
      secondary: [
        {
          name: filter.displayFunc(filter.item),
        },
      ],
      metaData: {
        filter,
        tagType: filterSet.name,
        icon: filterSet.icon,
      },
      // if it's course level, then we should add both the name and id of the course.
      ...(filter.isCourseLevel() && {
        main: {
          name: filter.getCourseName() ?? '',
          id: filter.getCourseID() ?? '',
        },
      }),
    };
  }

  getTopicSuggestion(): void {
    const topicSuggestions: ISuggestion[] = [];
    // Get the current User.
    this.userService.user.pipe(untilDestroyed(this)).subscribe(async (res) => {
      // No courses assigned to that user.
      if (!res?.user?.courses?.length) {
        return;
      }
      // Get all course associated with the current user.
      for (const course of res?.user.courses) {
        // Using set to store all distinct Topics for a given course.
        const topicList: TopicList | undefined = await this.userService
          .getTopics(course.syllabus_code)
          .toPromise();
        if (!topicList) {
          return;
        }
        topicSuggestions.push({
          main: {
            name: course.name,
            id: course._id,
          },
          secondary: this.getAllTopics(topicList?.topic_roots),
        });
      }
      this.suggestionGroupsWithoutFilters.push({
        type: FileParam.Topic,
        suggestions: topicSuggestions,
      });
    });
  }

  private getAllTopics(roots: TopicRootNode[]): { ids: Set<string>; name: string }[] {
    // To get duplicate node with different IDS
    const map = new Map<string, number>();
    // return variable, contains distinct names with diff ids.
    const ans: { ids: Set<string>; name: string }[] = [];

    // BFS algorithm.
    const topicNodes: TopicNode[] = roots.map((root) => root.topic_node);
    while (topicNodes.length) {
      // Get the top of the nodes.
      const topicNode: TopicNode | undefined = topicNodes.shift();

      if (!topicNode) {
        break;
      }

      // If we encounter the same name before, we can just add the new id, otherwise add new one.
      if (!map.has(topicNode.name)) {
        ans.push({ ids: new Set([topicNode._id]), name: topicNode.name });
        map.set(topicNode.name, ans.length - 1);
      } else {
        const index = map.get(topicNode.name);
        if (index !== undefined) {
          ans[index].ids.add(topicNode._id);
        }
      }

      // Push the child of the node.
      if (topicNode.child) {
        topicNodes.push(topicNode.child);
      }
    }
    return ans;
  }

  setSearchValue(searchVal: string): void {
    // Same search value, do nothing
    if (searchVal === this.fileFilterService.search) {
      return;
    }
    this.fileFilterService.search = searchVal;
    // Applying different search value, should return it to the root, so we need to clear the path.
    this.path.splice(0, this.path.length);

    // if it's in filter mode, then we can use resetView.
    // We can't use open folder since it will clear all filters.
    if (this.isFiltersApplied()) {
      this.resetView();
    } else {
      // otherwise we can use open folder
      this.openFolder(this.rootId);
    }
  }

  /**
   * It removes All selection files and folders, which at most one selection at a time.
   */
  clearSelections(): void {
    this.toggleAll(false);

    // To inform the dialog parent.
    this.fetchFile.emit();
  }

  clearAllFilters(resetView: boolean) {
    this.fileFilterService.clearAllFilters();
    if (resetView) {
      this.resetView();
    }
    const typeFilters = this.fileFilterService.filterSetsByType.get(FileParam.Type);
    if (this.isEnforcedVisible() && typeFilters) {
      this.fileFilterService.turnOffFilters(typeFilters);
    }
  }

  isEnforcedVisible(): boolean {
    return isArray(this.mandatoryType) && this.mandatoryType.length > 1;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Session } from '../models/session';
import { SessionSharedDataService, SessionView } from './session-shared-data.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  isMenuOpened = new BehaviorSubject(true);
  headerTitle = new BehaviorSubject('');
  showCalculator = new BehaviorSubject(false);
  showGraph = new BehaviorSubject(false);
  showPeriodicTable = new BehaviorSubject(false);
  showSessionAnalytics = new BehaviorSubject(false);
  sessionAnalyticsSpace = new BehaviorSubject<Session | undefined>(undefined);
  isCalculatorOpen = false;
  isGraphOpen = false;
  isPeriodicTableOpen = false;
  isSessionAnalyticsOpen = false;

  loading: any = new Promise((resolve) => {
    this.userService.user.subscribe((res) => {
      resolve(res?.user);
    });
  });

  constructor(
    private userService: UserService,
    private sharedDataService: SessionSharedDataService,
  ) {}

  closeAllGadgets() {
    if (this.isGraphOpen) {
      this.closeGraph();
    }
    if (this.isCalculatorOpen) {
      this.closeCalculator();
    }
    if (this.isPeriodicTableOpen) {
      this.closePeriodicTable();
    }
    if (this.isSessionAnalyticsOpen) {
      this.closeSessionAnalytics();
    }
  }

  closeGraph() {
    this.showGraph.next(false);
    this.isGraphOpen = false;
  }

  toggleGraph() {
    this.isGraphOpen = !this.isGraphOpen;
    this.showGraph.next(this.isGraphOpen);
  }

  closeCalculator() {
    this.showCalculator.next(false);
    this.isCalculatorOpen = false;
  }

  toggleCalculator() {
    this.isCalculatorOpen = !this.isCalculatorOpen;
    this.showCalculator.next(this.isCalculatorOpen);
  }

  closePeriodicTable() {
    this.showPeriodicTable.next(false);
    this.isPeriodicTableOpen = false;
  }

  togglePeriodicTable() {
    this.isPeriodicTableOpen = !this.isPeriodicTableOpen;
    this.showPeriodicTable.next(this.isPeriodicTableOpen);
  }

  closeSessionAnalytics() {
    this.showSessionAnalytics.next(false);
    this.isSessionAnalyticsOpen = false;
  }

  toggleSessionAnalytics() {
    this.isSessionAnalyticsOpen = !this.isSessionAnalyticsOpen;
    this.showSessionAnalytics.next(this.isSessionAnalyticsOpen);
  }

  toggleBreakoutRoomsManagement() {
    this.sharedDataService.sessionView.switchToSessionView(SessionView.BREAKOUT_ROOMS);
  }
}

import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  Output,
} from '@angular/core';
import { pastExamData } from './past-exams-data';

const range = (start: number, end: number) =>
  Array.from({ length: end - start }, (v, k) => k + start);

export class Model {
  isPastExam = false;
  day?: number;
  month?: number;
  year?: number;
  from?: string; // Exam
  school?: string;
  grade?: string;
  paper?: string;
  question_number?: string;
  exam?: string; // School exam type
  booklet?: string;
  timezone?: string;
  session?: string;
  set?: string;
  series?: string;
}

@Component({
  selector: 'app-past-exams',
  templateUrl: './past-exams.component.html',
  styleUrls: ['./past-exams.component.css'],
})
export class PastExamsComponent implements DoCheck {
  @Output() changed = new EventEmitter<Model>();
  @Input() model!: Model;

  originalModel = new Model();

  day = 0;
  month = 0;
  year = 0;
  years = range(1970, new Date().getFullYear() + 1).sort((a, b) => (a < b ? 1 : -1));
  monthNames: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  months: { index: number; name: string }[] = [];
  days = range(1, 32);
  exams = [
    { name: 'School Exam', exam: 'School' },
    { name: 'Singapore GCE O-Level', exam: 'GCE O-Level' },
    { name: 'Singapore GCE A-Level', exam: 'GCE A-Level' },
    { name: 'NEET', exam: 'NEET' },
    { name: 'IB Exam', exam: 'IB Exam' },
    { name: 'PSLE', exam: 'PSLE' },
    { name: 'IGCSE', exam: 'IGCSE' },
    { name: 'IIT-JEE Main', exam: 'IIT-JEE Main' },
    { name: 'UPSC IAS Prelims', exam: 'UPSC IAS Prelims' },
    { name: 'CBSE', exam: 'CBSE' },
    {
      name: 'Royal College Physician Licensing Exam',
      exam: 'Royal College Physician Licensing Exam',
    },
  ];
  schools: string[] = pastExamData.schools.sort();
  schoolExamType = pastExamData.examTypes.sort();
  schoolGrades = pastExamData.grades;
  school = '';
  papers = pastExamData.papers;
  ibTimezone = [
    { name: 'Timezone 0', tz: '0' },
    { name: 'Timezone 1', tz: '1' },
    { name: 'Timezone 2', tz: '2' },
  ];
  psleBooklet = [
    { name: 'Booklet A', booklet: 'A' },
    { name: 'Booklet B', booklet: 'B' },
  ];
  iitJeeSession = [
    { name: 'Morning Session', session: 'morning' },
    { name: 'Afternoon Session', session: 'afternoon' },
  ];
  upscIasPrelimsPapers = [
    { name: 'Paper 1: General Studies (GS)', paper: '1' },
    { name: 'Paper 2: Civil Services Aptitude Test (CSAT)', paper: '2' },
  ];
  upscIasPrelimsBooklet = [
    { name: 'Booklet A', booklet: 'A' },
    { name: 'Booklet B', booklet: 'B' },
    { name: 'Booklet C', booklet: 'C' },
    { name: 'Booklet D', booklet: 'D' },
  ];
  royalCollegeExamPapers = [
    { name: 'Paper 1 (MCQ)', paper: '1' },
    { name: 'Paper 2 (SAQ)', paper: '2' },
  ];
  differ: KeyValueDiffer<string, any>;

  constructor(private differs: KeyValueDiffers) {
    this.differ = this.differs.find({}).create();
    // TODO: check if necessary or can hard code ////////
    this.monthNames.forEach((month: string, i: number) => {
      this.months.push({ index: i, name: month });
      // ///////////////////////////////////////////////////
    });
  }

  ngDoCheck(): void {
    if (!this.differ) {
      return;
    }
    const change = this.differ.diff(this.model);
    if (change) {
      this.changed.emit(this.model);
    }
  }
}

import { Injectable } from '@angular/core';
import { PDFDocument } from 'pdf-lib';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { UploadFile } from '../sessions/session/items-canvas/items-canvas.component';
import { ResourceItemModel } from '../models/resource';
import { SpaceRepository } from '../state/space.repository';
import { IconMessageToasterElement } from '../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { ERRORS } from '../common/utils/notification-constants';
import { ToasterPopupStyle } from '../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { BoardFolder, Frame } from '../models/session';
import {
  FileUploadError,
  MAX_FILE_NAME_CHARS,
  MAX_FILE_SIZE_IN_MB,
  PDFBoardStrategy,
} from '../models/upload';
import { PanelView } from '../sessions/panel/panel.component';
import {
  PDF_PAGE_DEFAULT_HEIGHT,
  PDF_PAGE_DEFAULT_WIDTH,
} from '../sessions/session/pdf-canvas-item/pdf-viewer/pdf-viewer.component';
import { SessionSharedDataService } from './session-shared-data.service';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from './notification-toaster.service';
import { RealtimeSpaceService } from './realtime-space.service';
import { GroupUploadData } from './upload-file.service';
import { SpaceBoardFoldersService } from './space-board-folders.service';
import { SessionsVptService } from './sessions-vpt.service';

@Injectable()
export class UploadFileComputerService {
  fileUploadError$ = new BehaviorSubject<{ fileName?: string; error: FileUploadError } | undefined>(
    undefined,
  );

  private readonly _pdfToUpload$ = new BehaviorSubject<PDFDocumentProxy | undefined>(undefined);
  pdfToUpload$ = this._pdfToUpload$.asObservable();

  private readonly _rawFile$ = new BehaviorSubject<File | undefined>(undefined);
  rawFile$ = this._rawFile$.asObservable();

  constructor(
    private sharedDataService: SessionSharedDataService,
    private spaceRepo: SpaceRepository,
    private notificationToasterService: NotificationToasterService,
    private translateService: TranslateService,
    private realtimeSpaceService: RealtimeSpaceService,
    private spaceBoardFoldersService: SpaceBoardFoldersService,
    private sessionsVptService: SessionsVptService,
  ) {}

  async submitImportedPages(
    boardStrategy: PDFBoardStrategy,
    mimeType: string,
    selectedIndices: number[],
    canSplitPdf: boolean,
  ) {
    const originalFile = this._rawFile$.getValue();
    const originalPDF = this._pdfToUpload$.getValue();
    if (!originalFile || !originalPDF) {
      return;
    }
    switch (boardStrategy) {
      case PDFBoardStrategy.CurrentBoard:
        await this.addToCurrentFrame(
          originalFile,
          mimeType,
          originalPDF,
          selectedIndices,
          canSplitPdf,
        );
        break;
      case PDFBoardStrategy.NewBoard:
        await this.addFileToNewFrame(
          originalFile,
          mimeType,
          originalPDF,
          selectedIndices,
          canSplitPdf,
        );
        break;
      case PDFBoardStrategy.OneBoardPerPage:
        await this.uploadPDFForEachSelectedPage(
          originalPDF,
          selectedIndices,
          originalFile.name,
          mimeType,
        );
    }
  }

  private async addToCurrentFrame(
    originalFile: File,
    mimeType: string,
    originalPDF: PDFDocumentProxy,
    selectedIndices: number[],
    canSplitPdf: boolean,
  ) {
    const extractedPdfFile = canSplitPdf
      ? await this.generateExtractedPDFFile(
          originalPDF,
          selectedIndices,
          originalFile.name,
          mimeType,
        )
      : originalFile;

    const uploadFile = this.createUploadFile(
      extractedPdfFile,
      this.spaceRepo.activeSpaceSelectedBoardUid ?? '',
    );
    this.initiateFilesUpload([uploadFile]);
  }

  private async addFileToNewFrame(
    originalFile: File,
    mimeType: string,
    originalPDF: PDFDocumentProxy,
    selectedIndices: number[],
    canSplitPdf: boolean,
  ) {
    const file = canSplitPdf
      ? await this.generateExtractedPDFFile(
          originalPDF,
          selectedIndices,
          originalFile.name,
          mimeType,
        )
      : originalFile;
    const frame = this.createPageFrame(file.name);
    if (frame && this.spaceRepo.activeSpace && this.spaceRepo.activeSpace.currentRoomUid) {
      this.realtimeSpaceService.service.addFrame(
        frame,
        this.spaceRepo.activeSpace._id,
        this.spaceRepo.activeSpace.currentRoomUid,
      );

      this.sharedDataService.selectFrameSubject.next(frame.uid);

      // so that inserted object in the middle will be visible
      this.sessionsVptService.resetCanvasToOrigin();

      const uploadFile = this.createUploadFile(file, frame.uid);
      this.initiateFilesUpload([uploadFile]);
    }
  }

  private async generateExtractedPDFFile(
    originalPDF: PDFDocumentProxy,
    selectedIndices: number[],
    fileName: string,
    mimeType: string,
  ) {
    const documentAsBytes = await originalPDF.saveDocument();
    const pdfDoc = await PDFDocument.load(documentAsBytes);

    const newFilteredDocument = await PDFDocument.create();
    const copiedPages = await newFilteredDocument.copyPages(pdfDoc, selectedIndices);

    copiedPages.forEach((page) => newFilteredDocument.addPage(page));

    const extractedFileBytes = await newFilteredDocument.save();
    return this.createFileFromUint8Array(extractedFileBytes, fileName, mimeType);
  }

  private createFileFromUint8Array(uint8Array: Uint8Array, fileName: string, mimeType: string) {
    const blob = new Blob([uint8Array], { type: mimeType });
    return new File([blob], fileName);
  }

  async showFailedToProcessPDFNotification() {
    const titleElement = new IconMessageToasterElement(
      { icon: 'file_upload_off', size: 16 },
      this.translateService.instant('PDF Processing failed'),
    );

    const middleElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(
        'An Error happened while processing your PDF file, please try again',
      ),
    );

    const notificationData = new NotificationDataBuilder(ERRORS.PDF_PROCESSING_FAILED)
      .type(NotificationType.ERROR)
      .style(ToasterPopupStyle.ERROR)
      .priority(380)
      .timeOut(10)
      .dismissable(true)
      .topElements([titleElement])
      .middleElements([middleElement])
      .build();

    await this.notificationToasterService.showNotification(notificationData);
  }

  private async uploadPDFForEachSelectedPage(
    originalPDF: PDFDocumentProxy,
    selectedIndices: number[],
    fileName: string,
    mimeType: string,
  ) {
    const files = await this.generatePDFForEachSelectedPage(
      originalPDF,
      selectedIndices,
      fileName,
      mimeType,
    );

    // Create board folder
    const folder = new BoardFolder({
      name: fileName,
      roomUid: this.spaceRepo.activeSpaceCurrentRoomUid ?? '',
    });

    this.realtimeSpaceService.service.addBoardFolder(this.spaceRepo.activeSpaceId ?? '', folder);

    // Create Frames
    const frames: Frame[] = files.map((_, index) => {
      const pageNumber = index + 1;
      return this.createPageFrame(`Page ${pageNumber}`, folder.uid);
    });

    // Add them to the board folder
    this.realtimeSpaceService.service.addFrames(
      frames,
      this.spaceRepo.activeSpaceId ?? '',
      this.spaceRepo.activeSpaceCurrentRoomUid ?? '',
    );

    // Open the boards panel
    if (this.sharedDataService.leftPanelView.getValue()?.panelView !== PanelView.tabs) {
      this.sharedDataService.changeLeftPanelView.next(PanelView.tabs);
    }
    // Select the board folder and the first board in it
    this.spaceBoardFoldersService.setActiveSpaceSelectedBoardFolder(folder.uid);
    if (frames.length) {
      this.sharedDataService.selectFrameSubject.next(frames[0].uid);
    }
    // so that inserted object in the middle will be visible
    this.sessionsVptService.resetCanvasToOrigin();

    // Start uploading one page per frame
    const uploadId = uuidv4();
    const uploadFiles = files.map((file, index) =>
      this.createUploadFile(file, frames[index].uid, {
        uploadId,
        totalFiles: files.length,
        uploadedFilesCount: 0,
        top: PDF_PAGE_DEFAULT_HEIGHT / 2,
        left: PDF_PAGE_DEFAULT_WIDTH,
      }),
    );
    this.initiateFilesUpload(uploadFiles);
  }

  private async generatePDFForEachSelectedPage(
    originalPDF: PDFDocumentProxy,
    selectedIndices: number[],
    fileName: string,
    mimeType: string,
  ): Promise<File[]> {
    const documentAsBytes = await originalPDF.saveDocument();
    const pdfDoc = await PDFDocument.load(documentAsBytes);

    const promises = selectedIndices.map(async (selectedIndex, countIndex) => {
      const newFilteredDocument = await PDFDocument.create();
      const [copiedPage] = await newFilteredDocument.copyPages(pdfDoc, [selectedIndex]);
      newFilteredDocument.addPage(copiedPage);
      const extractedFileBytes = await newFilteredDocument.save();
      const fileNameWithoutExtension = fileName.split('.')[0];
      const pageNumber = countIndex + 1;
      return this.createFileFromUint8Array(
        extractedFileBytes,
        `${fileNameWithoutExtension}_page_${pageNumber}`,
        mimeType,
      );
    });

    return Promise.all(promises);
  }

  private createPageFrame(fileName: string, boardFolderUid?: string): Frame {
    return new Frame({
      name: fileName,
      backgroundPattern: this.spaceRepo.activeSpace?.sessionDefaultFramesBackground?.pattern,
      backgroundColor: this.spaceRepo.activeSpace?.sessionDefaultFramesBackground?.color,
      boardFolderUid,
    });
  }

  private createUploadFile(
    file: File,
    frameId: string,
    groupUploadData?: GroupUploadData & { top?: number; left?: number },
  ): UploadFile {
    return new UploadFile(
      file,
      file.name,
      file.size,
      ResourceItemModel.PDF,
      this.spaceRepo.activeSpaceId ?? '',
      frameId,
      groupUploadData
        ? {
            groupUploadData,
            top: groupUploadData.top,
            left: groupUploadData.left,
          }
        : undefined,
    );
  }

  private initiateFilesUpload(files: UploadFile[]) {
    this.sharedDataService.uploadItem.next(files);
  }

  public getFileErrorIfExists(
    file: File,
    supportedDocumentTypes: string,
  ): undefined | FileUploadError {
    const possibleErrors = [
      { condition: this.checkFileExceedSizeLimit(file), error: FileUploadError.SizeLimitExceed },
      {
        condition:
          !this.isFileMimeTypeAccepted(supportedDocumentTypes, file.type) &&
          !this.isFileExtensionAccepted(supportedDocumentTypes, file.name),
        error: FileUploadError.NotSupportedType,
      },
      { condition: file.name.length > MAX_FILE_NAME_CHARS, error: FileUploadError.TooLongFileName },
    ];

    for (const possibleError of possibleErrors) {
      if (possibleError.condition) {
        return possibleError.error;
      }
    }
  }

  private isFileExtensionAccepted(
    supportedDocumentTypes: string,
    fileName: string,
    delimiter = ', ',
  ) {
    return supportedDocumentTypes.split(delimiter).some((accepted) => fileName.toLowerCase().endsWith(accepted.toLowerCase()));
  }

  private isFileMimeTypeAccepted(
    supportedDocumentTypes: string,
    fileMimeType: string,
    delimiter = ', ',
  ): boolean {
    return supportedDocumentTypes.split(delimiter).some((accepted) => {
      // Convert the accepted pattern to a regular expression
      const regexPattern = new RegExp(`^${accepted.replace('*', '.*')}$`, 'i');

      // Check if the file's type matches the accepted pattern
      return regexPattern.test(fileMimeType);
    });
  }

  private checkFileExceedSizeLimit(file: File) {
    const pdfSizeInMB = file.size / (1024 * 1024);
    return pdfSizeInMB > MAX_FILE_SIZE_IN_MB;
  }

  /*
   *  This is because the pdf-lib fails to load some pdfs,
   *  We disable splitting PDFs since it can only be done using this library
   *  Some trials has been made to fix the error in the library but no luck
   *  Another solution is to convert the selected pages to images then convert them back into a PDF using jsPDF
   *  This can make us lose a bit of quality, so we resorted to this solution.
   * */
  async canSplitPDFPages(pdf: PDFDocumentProxy): Promise<boolean> {
    try {
      const documentAsBytes = await pdf.saveDocument();
      await PDFDocument.load(documentAsBytes);
    } catch (e) {
      return false;
    }
    return true;
  }

  set pdfToUpload(pdf: PDFDocumentProxy | undefined) {
    this._pdfToUpload$.next(pdf);
  }
  get pdfToUpload(): PDFDocumentProxy | undefined {
    return this._pdfToUpload$.getValue();
  }

  set rawFile(file: File | undefined) {
    this._rawFile$.next(file);
  }

  resetFiles() {
    this.pdfToUpload = undefined;
    this.rawFile = undefined;
  }

  resetFileUploadErrorState() {
    this.fileUploadError$.next(undefined);
  }
}

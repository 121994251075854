import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isInSet',
})
export class IsInSetPipe implements PipeTransform {
  transform(id, items?: any): any {
    if (!items) {
      return false;
    }
    return items.findIndex(item => id === item._id) > -1;
  }
}

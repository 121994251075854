import { Pipe, PipeTransform } from '@angular/core';
import { QuestionTypes } from '../content/course/create/create.component.utils';

@Pipe({
  name: 'questionType',
})
export class QuestionTypePipe implements PipeTransform {
  transform(type): any {
    if (!type || type === QuestionTypes.MCQ) {
      return 'MCQ';
    } else if (type === QuestionTypes.OEQ) {
      return 'OEQ';
    } else if (type === QuestionTypes.RCQ) {
      return 'RCQ';
    } else {
      return 'UNKNOWN';
    }
  }
}

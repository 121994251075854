import { Component, Input } from '@angular/core';

import { VISIBILITY } from '../../models/question';

@Component({
  selector: 'app-visibility-icon',
  templateUrl: './visibility-icon.component.html',
  styleUrls: ['./visibility-icon.component.scss']
})
export class VisibilityIconComponent {
  @Input() visibility?: VISIBILITY;

  VISIBILITY = VISIBILITY;
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Context } from 'src/app/models/context';
import { ConversationContent } from 'src/app/models/messaging';
import { Message } from 'src/app/models/user';
import { ConversationService } from 'src/app/services/conversation.service';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-conversation-history[context]',
  templateUrl: './conversation-history.component.html',
  styleUrls: ['./conversation-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationHistoryComponent implements OnInit {
  @Input() enableEditing = true;
  @Input() displayParticipantName = false;
  @Input() clickAvatars = true;
  @Input() context!: Context;
  @Output() private replyMessage = new EventEmitter<Message>();

  conversationContent$?: Observable<ConversationContent>;
  typingUserIds$?: Observable<string[]>;

  constructor(private conversationService: ConversationService) {}

  ngOnInit(): void {
    this.conversationContent$ = this.conversationService.getConversationContent(this.context);
  }

  public emitReplyMessage(message: Message): void {
    this.replyMessage.emit(message);
  }

  onUpdateMessage(message: Message): void {
    this.conversationService.updateMessage(message).pipe(untilDestroyed(this)).subscribe();
  }

  onDeleteMessage(message: Message): void {
    this.conversationService.deleteMessage(message).pipe(untilDestroyed(this)).subscribe();
  }

  onFetchMoreMessages(oldestMessage: Message): void {
    this.conversationService.fetchMoreMessages(oldestMessage);
  }
}

<div
  infiniteScroll
  [infiniteScrollDistance]="2"
  (scrolled)="onScrollDown()"
  [scrollWindow]="false"
  [infiniteScrollContainer]="'.files-container'"
  [fromRoot]="true"
  class="d-flex flex-row flex-wrap justify-content-start"
  *ngIf="currentFolders.length !== 0 || currentFiles.length !== 0"
>
  <div class="file-list-container d-flex flex-column w-100">
    <div class="w-100 file-list-title">
      <div class="d-flex flex-grow-1">
        <div class="flex-4 name-cont">
          <span>{{ 'Name' | translate }}</span>
        </div>
        <div class="flex-2">{{ 'Owner' | translate }}</div>
        <div class="flex-1">{{ 'Type' | translate }}</div>
        <div *ngIf="enforcingFilter !== ResourceFilterParamsEnum.Course" class="flex-1">
          {{ 'Course' | translate }}
        </div>
        <div class="flex-1">{{ 'Date Uploaded' | translate }}</div>
      </div>
    </div>
  </div>
  <div class="file-list-container d-flex flex-column w-100">
    <!-- Using drag over/leave for hovering effect while dropping files -->
    <div
      *ngFor="let folder of currentFolders; let i = index"
      [@anim]="folder.xMeta?.display.animationState"
      (dragover)="allowDrop($event); folder.xMeta?.display.hover = true"
      (dragleave)="folder.xMeta?.display.hover = false"
      (drop)="drop($event, folder)"
      (click)="openFolder.emit({ folder: folder._id, append: true })"
      style="order: -2"
    >
      <div
        class="w-100 d-flex file-list-item"
        [ngClass]="{ dragFolders: dragging && folder.xMeta?.display.hover }"
      >
        <div class="d-flex flex-grow-1">
          <div class="flex-4 name-cont">
            <div [ngStyle]="{ marginLeft: 20 + 'px' }" class="name-cont-wrap">
              <mat-checkbox
                color="primary"
                style="vertical-align: bottom"
                [disabled]="!canSelect"
                [ngClass]="{ checked: folder.xMeta?.display.selected }"
                [(ngModel)]="folder.xMeta?.display.selected"
                (click)="$event.stopPropagation()"
                (ngModelChange)="toggleFolderSelection(i)"
              >
              </mat-checkbox>
              <div class="d-inline-block" (click)="onOpenFolder(folder._id)">
                <span><i class="material-icons file-list-icon">folder</i>&nbsp;</span>
                <span [appTooltip]="folder.name || null" class="img-label file-name">{{
                  folder.name | truncate: [maxNameLength]
                }}</span>
              </div>
            </div>
          </div>
          <div class="flex-2 file-desc flex-row">
            <span>
              <i
                *ngIf="!folder.owner?.profile_image; else profile_img"
                class="material-icons file-list-icon me-2"
                >account_circle</i
              >
            </span>
            <ng-template #profile_img
              ><img class="profile-icon me-2" [src]="folder.owner.profile_image"
            /></ng-template>
            <span>{{
              user?.email === folder.owner.email
                ? ('Me' | translate)
                : (folder.owner.name | titlecase)
            }}</span>
          </div>
          <div class="flex-1 file-desc">{{ 'Folder' | titlecase | translate }}</div>
          <div
            *ngIf="enforcingFilter !== ResourceFilterParamsEnum.Course"
            class="flex-1 file-desc"
          ></div>
          <div class="flex-1 file-desc">
            {{ folder.createdAt | dateLocale: 'mediumDate' }}
          </div>
        </div>
      </div>
    </div>
    <div
      *ngFor="let file of currentFiles; let i = index"
      class="w-100 d-flex file-list-item"
      [@anim]="file.xMeta?.display.animationState"
      (click)="toggleFileSelection(i, $event)"
      [ngClass]="{
        'file-dragged': file.xMeta?.display.selected && dragging,
        'file-not-dragged': !file.xMeta?.display.selected && dragging,
        'file-selected': file.xMeta?.display.selected
      }"
      [ngStyle]="file.xMeta?.display.style"
    >
      <div
        class="d-flex flex-grow-1"
        [draggable]="file.xMeta?.display.selected || draggingWithoutSelect"
        (dragstart)="drag($event, true, i)"
        (dragend)="drag($event, false)"
      >
        <div class="flex-4 name-cont">
          <div class="name-cont-wrap" [ngStyle]="{ marginLeft: 20 + 'px' }">
            <mat-checkbox
              color="primary"
              [(ngModel)]="file.xMeta?.display.selected"
              (click)="$event.stopPropagation()"
              (ngModelChange)="toggleSelection($event, i)"
              [disabled]="!canSelect"
              [ngClass]="{ checked: file.xMeta?.display.selected }"
            >
            </mat-checkbox>
            <span
              ><i
                class="material-icons-outlined file-list-icon"
                [ngClass]="{ 'file-text-selected': file.xMeta?.display.selected }"
                >{{
                  file.type === 'IMAGE'
                    ? 'image'
                    : file.type === 'VIDEO'
                    ? 'videocam'
                    : 'text_snippet'
                }}</i
              >&nbsp;</span
            >
            <span
              [appTooltip]="
                (file.name ? file.name.length : file.altName.length) > maxNameLength
                  ? file.name || file.altName
                  : null
              "
              appTooltipPlacement="bottom-center"
              appTooltipOffsetY="10"
              class="img-label file-name"
              [ngClass]="{ 'file-text-selected': file.xMeta?.display.selected }"
              >{{ file.name || file.altName | fileNameFormat: [maxNameLength] }}</span
            >
          </div>
        </div>
        <div class="flex-2 file-desc flex-row">
          <span>
            <i
              *ngIf="!file.owner.profile_image; else profile_img"
              class="material-icons file-list-icon me-2"
              >account_circle</i
            >
          </span>
          <ng-template #profile_img
            ><img class="profile-icon me-2" [src]="file.owner.profile_image"
          /></ng-template>
          <span [ngClass]="{ 'file-text-selected': file.xMeta?.display.selected }">{{
            user?.email === file.owner.email ? ('Me' | translate) : (file.owner.name | titlecase)
          }}</span>
        </div>
        <div
          class="flex-1 file-desc"
          [ngClass]="{ 'file-text-selected': file.xMeta?.display.selected }"
        >
          {{ file.type | titlecase | translate }}
        </div>
        <div *ngIf="enforcingFilter !== ResourceFilterParamsEnum.Course" class="flex-1 file-desc">
          <div
            *ngIf="file.courses.length"
            class="file-tag"
            [ngClass]="{ 'file-text-selected': file.xMeta?.display.selected }"
            #tooltip="ngbTooltip"
            [ngbTooltip]="file.courses | courseTooltip: courseLookup"
          >
            {{
              (file.courses.length > 1
                ? file.courses.length + ' courses'
                : (file.courses | courseTooltip: courseLookup:true)
              ) | truncate: [12]
            }}
          </div>
          <!--No courses associated, just place '-' instead-->
          <div *ngIf="!file?.courses?.length" class="empty-course-tag">-</div>
        </div>
        <div
          class="flex-1 file-desc"
          [ngClass]="{ 'file-text-selected': file.xMeta?.display.selected }"
        >
          {{ file.createdAt | dateLocale: 'mediumDate' }}
        </div>
      </div>
    </div>
  </div>
</div>

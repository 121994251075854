import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, filter, map, pairwise, scan } from 'rxjs';
import { filterNil } from '@ngneat/elf';
import { CallParticipant, ParticipantEventAction } from '../common/interfaces/rtc-interface';
import { ProviderStateService } from './provider-state.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SessionCallParticipantsService {
  private _participants$: BehaviorSubject<Record<string, CallParticipant>> = new BehaviorSubject(
    {},
  );
  private _participantUserIds$ = new BehaviorSubject<Set<string>>(new Set());

  public readonly participantEvents$ = this.providerState.participantEvents$.pipe(filterNil());
  public readonly participants$ = this._participants$.asObservable();
  public readonly participantUserIds$ = this._participantUserIds$.asObservable();

  constructor(private providerState: ProviderStateService) {
    this.providerState.participantEvents$
      .pipe(
        filterNil(),
        scan((participants, cur) => {
          const participant = cur.participant;
          switch (cur.action) {
            case ParticipantEventAction.JOINED:
              participants[participant.participantId] = participant;
              break;
            case ParticipantEventAction.LEFT:
              delete participants[participant.participantId];
              if (participant.local) {
                return {};
              }
          }
          return participants;
        }, {}),
      )
      .subscribe(this._participants$);

    this._participants$
      .pipe(
        map((participants) => Object.values(participants).map((p) => p.userId)),
        pairwise(),
        filter(([prev, cur]) => prev.length !== cur.length),
        map(([, cur]) => new Set(cur)),
      )
      .subscribe(this._participantUserIds$);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Event } from '../models/event';

@Pipe({
  name: 'listHosts',
})
export class ListHostsPipe implements PipeTransform {
  transform(events: Event[]): any {
    const addedSet = new Set();
    const users: any[] = [];
    events.forEach(event => {
      if (event.host_ids) {
        event.host_ids.forEach(host => {
          if (!addedSet.has(host._id)) {
            users.push(host);
            addedSet.add(host._id);
          }
        });
      }
    });
    if (users.length === 1) {
      return users[0].name;
    }
    const listWithoutLastUser = users.map(user => user.name);
    const lastUser = listWithoutLastUser.pop();
    return `${listWithoutLastUser.join(', ')} and ${lastUser}`;
  }
}

import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Combines a single entity with its corresponding UIEntity.
 * (Like unionEntities() from Elf but for a single entity)
 */
export function unionEntity<
  T extends {
    entity: Record<string, any> | undefined;
    UIEntity: Record<string, any> | undefined;
  },
>(): OperatorFunction<T, T['entity'] & T['UIEntity']> {
  return map((state: T) => {
    if (state.entity && state.UIEntity) {
      return {
        ...state.entity,
        ...state.UIEntity,
      };
    }
  });
}

// Access properties with string keys in a type-safe way
export function getProperty<P, T extends keyof P>(obj: P, key: T): P[T] {
  return obj[key];
}

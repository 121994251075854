import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
})
export class UploadDialogComponent {
  files: any[] = [];
  acceptFiles = 'image/*,video/*,audio/*,.doc,.docx,.docm,.pdf';
  isFileHovering = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private ngZone: NgZone) {}

  readFile($event: Event) {
    if (($event.target as HTMLInputElement).files) {
      /*
       * $event.target as any is used instead of $event.target as HTMLInputElement
       * to avoid the error "__ must have a '[Symbol.iterator]()' method that returns an iterator"
       */
      this.files.push(...($event.target as any).files);
    }
  }

  onDrop(entries: NgxFileDropEntry[]) {
    this.isFileHovering = false;
    entries.forEach((entry) => {
      if (entry.fileEntry.isFile) {
        (entry.fileEntry as FileSystemFileEntry).file((file) => {
          // the function in the library use timers internally which run outside zone
          // as we move all timers to run outside zone so we need to move it back inside zone
          // for the UI to be updated
          this.ngZone.run(() => {
            this.files.push(file);
          });
        });
      }
    });
  }

  onFileOver() {
    this.isFileHovering = true;
  }

  onFileLeave() {
    this.isFileHovering = false;
  }
}

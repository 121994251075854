<div class="header">
  {{ 'How happy are you with Pencil Spaces?' | translate }}
</div>
<div class="form">
  <div class="d-flex justify-content-center">
    <button
      *ngFor="let item of ratings"
      class="control-button"
      [ngClass]="{ selected: item <= rating || (mouseOverIndex && mouseOverIndex >= item) }"
      (mouseover)="handleHover(item)"
      (mouseout)="handleMouseOut()"
      (click)="handleSelectRating(item)"
    >
      <svg-icon
        [width]="17"
        [height]="17"
        [icon]="
          item <= rating || (mouseOverIndex && mouseOverIndex >= item)
            ? 'rating_star'
            : 'rating_empty_star'
        "
      >
      </svg-icon>
    </button>
  </div>
  <div
    class="rating-message"
    [ngClass]="{
      selected: rating !== 0 || (mouseOverIndex && mouseOverIndex !== 0),
      danger: rating === 0 && !mouseOverIndex && disableSubmit
    }"
  >
    {{ ratingMessage }}
  </div>
  <div class="feedback-container">
    <span>Comments (optional)</span>
    <textarea
      #feedbackTextArea
      (input)="handleFeedbackChange(feedbackTextArea.value)"
      rows="3"
      [value]="feedback"
      [placeholder]="'Share any additional feedback here ...' | translate"
    ></textarea>
  </div>
  <div class="controls d-flex justify-content-between">
    <button (click)="handleNotNow()" class="not-now">
      <em class="material-icons-outlined">close</em> {{ 'Not Now' | translate }}
    </button>
    <button (click)="handleSubmit()" [disabled]="disableSubmit && rating === 0" class="submit">
      <em class="material-icons-outlined">check</em> {{ 'Submit' | translate }}
    </button>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'paginatorDescription',
})
export class PaginatorDescriptionPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(pageIndex: number, pageSize: number, length: number): string {
    const start = pageSize * pageIndex + 1;
    const end = pageSize * (pageIndex + 1) < length ? pageSize * (pageIndex + 1) : length;
    const description = `Showing ${start} - ${end} out of ${length}`;

    return this.translateService.instant(description);
  }
}

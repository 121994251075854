import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpaceRecording } from 'src/app/models/space-recording';
import { VIMEO_EMBEDDED_CONTENT_STATUS } from 'src/app/pipes/embed-vimeo-video.pipe';

@Component({
  selector: 'app-recording-preview-modal',
  templateUrl: './recording-preview-modal.component.html',
  styleUrls: ['./recording-preview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordingPreviewModalComponent {
  readonly RECORDING_STATUS = VIMEO_EMBEDDED_CONTENT_STATUS;
  constructor(
    public dialogRef: MatDialogRef<RecordingPreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { recording: SpaceRecording },
  ) {}

  closeDialog() {
    this.dialogRef.close(false);
  }
}

import { Injectable } from '@angular/core';
import { User } from 'src/app/models/user';
import { TelemetryService } from 'src/app/services/telemetry.service';
import { SessionUser } from 'src/app/models/session';
import { FSInviteUpdateType } from 'src/app/models/session-share';
import * as moment from 'moment-timezone';

export enum JoinedSpaceOrigin {
  SPACES_MANAGER = 'spaces_manager',
}
export interface JoinedSpaceOriginInfo {
  origin: JoinedSpaceOrigin;
  spaceId: string;
}
@Injectable({
  providedIn: 'root',
})
export class SpaceSharingService {
  readonly REINVITE_TIME_IN_MILLIS = 5 * 60000;
  private joinedSpaceOriginInfo: JoinedSpaceOriginInfo | undefined;

  constructor(private telemetry: TelemetryService) {}
  public splitOnSignUpStatus(
    users: SessionUser[],
    lookupTable: { [id: string]: User } | undefined = undefined,
  ): number[] {
    if (lookupTable === undefined) {
      return [0, 0];
    }
    return users.reduce(
      ([signedUpCount, newCount], user) =>
        lookupTable[user._id]?.name ? [signedUpCount + 1, newCount] : [signedUpCount, newCount + 1],
      [0, 0],
    );
  }

  public fireFSInviteCountEvent(
    signedUpCount: number,
    newCount: number,
    type: FSInviteUpdateType,
  ): void {
    if (signedUpCount === 0 && newCount === 0) {
      return;
    }
    const suffix = type === FSInviteUpdateType.SPACE_USERS_INVITED ? 'invited' : 'reinvited';
    this.telemetry.event(`[${type}]`, {
      [`n_users_${suffix}`]: signedUpCount,
      [`n_new_users_${suffix}`]: newCount,
    });
  }

  public buildLookupTable(profiles: any): any {
    if (!profiles) {
      return {};
    }
    return profiles.reduce(
      (dict: any, currUser: User) => ({
        ...dict,
        [currUser._id]: currUser,
      }),
      {},
    );
  }

  getTimeInLargestUnit(diffInMillis: number, fractionHandler = Math.floor) {
    const duration = moment.duration(diffInMillis);
    if (duration.asSeconds() < 60) {
      const seconds = fractionHandler(duration.asSeconds());
      return `${seconds} ${this.handlePluralization(seconds, 'second')}`;
    } else if (duration.asMinutes() < 60) {
      const minutes = fractionHandler(duration.asMinutes());
      return `${minutes} ${this.handlePluralization(minutes, 'min')}`;
    } else if (duration.asHours() < 24) {
      const hours = fractionHandler(duration.asHours());
      return `${hours} ${this.handlePluralization(hours, 'hour')}`;
    } else if (duration.asDays() < 30) {
      const days = fractionHandler(duration.asDays());
      return `${days} ${this.handlePluralization(days, 'day')}`;
    } else if (duration.asMonths() < 12) {
      const months = fractionHandler(duration.asMonths());
      return `${months} ${this.handlePluralization(months, 'month')}`;
    }
    const years = fractionHandler(duration.asYears());
    return `${years} ${this.handlePluralization(years, 'year')}`;
  }

  private handlePluralization(quantity: number, word: string) {
    return quantity === 1 ? word : `${word}s`;
  }

  public fireFSEventAcceptInvite(isInvitedByEmail: boolean, spaceId: string) {
    const urlParams = new URLSearchParams(window.location.search);
    let inviteSource = 'link';
    let referralSource = 'link';
    if (isInvitedByEmail) {
      inviteSource = 'email';
      if (
        this.joinedSpaceOriginInfo?.origin === JoinedSpaceOrigin.SPACES_MANAGER &&
        this.joinedSpaceOriginInfo.spaceId === spaceId
      ) {
        referralSource = 'space_manager';
      } else if (urlParams.get('utm_source') === 'email') {
        referralSource = 'email';
      }
    }
    this.telemetry.event('[user_accepted_invite]', {
      invite_source: inviteSource,
      referral_source: referralSource,
    });
  }
  public setJoinedSpaceOriginInfo(origin: JoinedSpaceOrigin, spaceId: string) {
    this.joinedSpaceOriginInfo = { origin, spaceId };
  }
}

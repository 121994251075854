<div class="multi-value-pills-wrap">
  <div
    class="multi-value-tag"
    *ngIf="tooltipMode && values.length === 1"
    appTooltipPlacement="bottom-center"
    [appTooltip]="values[0]"
  >
    {{ values[0] }}
  </div>
  <div
    class="multi-value-tag"
    *ngIf="tooltipMode && values.length > 1"
    appTooltipPlacement="bottom-center"
    [appTooltip]="pillsTypeName + ': ' + values.join(', ')"
  >
    {{ pillsTypeName + ' (' + values.length + ')' | translate }}
  </div>
  <div class="multi-value-tags" *ngIf="!tooltipMode">
    <ng-container *ngFor="let value of values; index as i">
      <div class="multi-value-tag" *ngIf="isCollapsed ? i < showLimit : true">
        {{ value }}
      </div>
    </ng-container>
  </div>
  <span
    class="more-btn"
    role="button"
    *ngIf="!tooltipMode && values.length > showLimit"
    (click)="isCollapsed = !isCollapsed"
  >
    {{ (isCollapsed ? 'View All' : 'View Less') | translate }}
  </span>
</div>

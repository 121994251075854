import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Availability, TimeSlot, UserAvailabilityData } from '../models/availability';
import { User } from '../models/user';
import { Event } from '../models/event';
import { URLService } from './dynamic-url.service';

export interface GetSlotsResponse {
  availability: {
    user: string;
    slots: TimeSlot[];
  };
}

export interface GetAvailabilityResponse {
  users: UserAvailabilityData[];
}

@Injectable({
  providedIn: 'root',
})
export class AvailabilityService {
  constructor(private urlService: URLService, private http: HttpClient) {}
  createUpdateSlots(availability: Availability, user: User): Observable<object> {
    return this.http.post(
      `${this.urlService.getDynamicUrl()}/availability/slots/${availability.userId}`,
      {
        ...availability,
        timezone: user.timezone.value,
      },
    );
  }

  getSlots(user: User): Observable<GetSlotsResponse> {
    return this.http.get<GetSlotsResponse>(
      `${this.urlService.getDynamicUrl()}/availability/slots/${user._id}?timezone=${
        user.timezone.value
      }`,
    );
  }

  getAvailableTutors(eventToCheck: Event): Observable<GetAvailabilityResponse> {
    const encodedFilters = btoa(
      JSON.stringify({
        event: {
          start_time: eventToCheck.start_time,
          end_time: eventToCheck.end_time,
        },
      }),
    );
    return this.http.get<GetAvailabilityResponse>(
      `${this.urlService.getDynamicUrl()}/availability?filters=${encodedFilters}`,
    );
  }
}

import { Injectable } from '@angular/core';
import { fabric } from 'fabric';
import { SessionSharedDataService } from 'src/app/services/session-shared-data.service';
import { SessionsVptService } from 'src/app/services/sessions-vpt.service';

@Injectable({
  providedIn: 'root',
})
export class ZoomUtil {
  constructor(
    private sharedDataService: SessionSharedDataService,
    private spacesVptService: SessionsVptService,
  ) {}

  // Zoom canvas to fit viewport accordingly
  adjustCanvasZoom(factor: number): void {
    const canvas = this.sharedDataService.fabricCanvas;
    if (!canvas) {
      return;
    }

    const centerPoint = new fabric.Point(0, 0);

    const zoomLevel = +(<number>canvas.getZoom()).toPrecision(3);
    const portion = factor * zoomLevel;

    this.spacesVptService.zoomToPoint(centerPoint, Number(portion.toFixed(3)));
  }

  getCenterCoord(): { x: number; y: number } {
    const canvas = this.sharedDataService.fabricCanvas;
    const zoom = this.sharedDataService.fabricCanvas?.getZoom();
    if (!canvas || !zoom || !canvas.viewportTransform) {
      return { x: 100, y: 100 };
    }
    return {
      x:
        fabric.util.invertTransform(canvas.viewportTransform)[4] +
        (canvas.width ?? window.outerWidth) / zoom / 2,
      y:
        fabric.util.invertTransform(canvas.viewportTransform)[5] +
        (canvas.height ?? window.outerHeight) / zoom / 2,
    };
  }
}

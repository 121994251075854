import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NgForOf } from '@angular/common';
import { IconTypes } from '../pencil-icon/pencil-icon.component';
import { RadioSelectionItemComponent } from './radio-selection-item/radio-selection-item.component';

export interface SelectionItem {
  text: string;
  icon: {
    name: string;
    type: IconTypes;
  };
  disabled?: boolean;
  tooltipText?: string;
  disabledToolTipText?: string;
  hidden?: boolean;
}

@Component({
  selector: 'app-radio-selection',
  templateUrl: './radio-selection.component.html',
  styleUrls: ['./radio-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RadioSelectionItemComponent, NgForOf],
})
export class RadioSelectionComponent implements OnInit {
  @Input() selectionItems: SelectionItem[] = [];
  @Input() initialSelectedItemIndex?: number;
  @Output() itemSelected = new EventEmitter<number>();

  selectedIndex = -1;

  ngOnInit(): void {
    this.selectedIndex = this.initialSelectedItemIndex ?? this.selectedIndex;
  }

  selectItem(index: number) {
    this.selectedIndex = index;
    this.itemSelected.emit(index);
  }
}

import { Component, Input, OnChanges } from '@angular/core';
import { SidebarLogoType, Theme } from '../themes/themes';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnChanges {
  @Input() theme?: Theme;
  public sidebarLogoType = SidebarLogoType;
  logo?: { logoType?: SidebarLogoType; color?: string };

  ngOnChanges(): void {
    this.logo = this.theme?.sidebar?.logo;
  }
}

import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MessagingService } from 'src/app/services/messaging.service';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  wideScreen$: Observable<boolean>;

  constructor(
    private messagingService: MessagingService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.wideScreen$ = this.breakpointObserver.observe('(min-width: 500px)').pipe(pluck('matches'));
  }

  ngOnInit(): void {
    /* This service method is called asynchronously in order to prevent an
     * NG100: ExpressionChangedAfterItHasBeenCheckedError
     */
    requestAnimationFrame(() => this.messagingService.setNoSelectedConversation());
  }
}

import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-link-login',
  templateUrl: './link-login.component.html',
  styleUrls: ['./link-login.component.scss']
})
export class LinkLoginComponent {

  constructor(
    public dialogRef: MatDialogRef<LinkLoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  link(): void {
    this.dialogRef.close(true);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}

import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import * as FullStory from '@fullstory/browser';
import SentryFullStory from '@sentry/fullstory';

import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from '../environments/environment';
import { versions } from '../environments/versions';

Sentry.init({
  dsn: `${environment.sentryKey}`,
  environment: environment.production ? 'production' : 'staging',
  release: versions.version,
  denyUrls: [/.*0\.0\.0\.*/, /.*localhost.*/, /.*127\.0\.0\.1.*/],
  integrations: [
    Sentry.browserTracingIntegration({
      tracePropagationTargets: ['localhost', `/^${environment.serverURL}/`],
    }),
    new SentryFullStory(environment.sentryOrganisationSlug, { client: FullStory }),
  ],
  tracesSampleRate: 0.25,
});

@UntilDestroy()
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (error.status !== 401) {
      Sentry.captureException(error.originalError || error);
      console.error(error);
    }
    // Enable below once we have a better understanding of what
    // Errors to ask user for.
    // Sentry.showReportDialog({ eventId });
  }
}

import { Injectable } from '@angular/core';
import * as FS from '@fullstory/browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { generateUserPresenceHeartbeatHash } from '../common/utils/presence.util';

import { PresenceRepository } from '../state/presence.repository';

import { User } from '../models/user';

import { SpaceRepository } from '../state/space.repository';
import { ContextTypeEnum, PresenceData, PresenceRoom, PresenceType } from './presence_v2.service';
import { TelemetryService } from './telemetry.service';

const stringifyCollection = (collection: object | Set<any> | any[]): string => {
  if (collection instanceof Set || Array.isArray(collection)) {
    if (Array.isArray(collection) && collection.length > 0 && typeof collection[0] === 'object') {
      return collection.map((item) => JSON.stringify(item)).join(', ');
    }
    return Array.from(collection).join(', ');
  }
  return JSON.stringify(collection);
};

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PresenceMonitoringService {
  private heartBeatEventsIntervalThreshold = 30000; // Threshold in milliseconds
  private heartbeatEventsPollingThreshold = 10; // Threshold number of items event occurs
  private userMap: Map<string, string> = new Map();

  constructor(
    private presenceRepo: PresenceRepository,
    private telemetryService: TelemetryService,
    private spaceRepo: SpaceRepository,
  ) {
    this.spaceRepo.activeSpacePopulatedUsers$
      .pipe(untilDestroyed(this))
      .subscribe((populatedUsers: User[]) => {
        populatedUsers.forEach((user) => this.userMap.set(user._id, user.name ?? user.email));
      });
  }

  public notifyPresenceUpdate(
    currentSpaceId: string | undefined,
    currentBreakoutRoomId: string | undefined,
    update: PresenceRoom,
    usersLeftPresence: PresenceData[],
    usersJoinedPresence: PresenceData[],
  ) {
    const updateLog = {
      ...update,
      presence: this.mapPresenceDataToLog(update.presence),
    };
    this.logEvent(
      'info',
      `Presence update coming from wb server. ${stringifyCollection(updateLog)}`,
      '',
      'presence_update',
    );
    if (!this.isValidUpdate(currentSpaceId, update)) {
      this.logEvent(
        'error',
        `[presence-monitoring] Invalid state detected: Mismatch between presence update spaceId & clientSpaceId: ${stringifyCollection(
          {
            updateSpaceId: update.spaceId,
            clientSpaceId: currentSpaceId,
          },
        )}`,
        'mismatch_space_or_breakoutroom',
      );
    }

    if (!currentSpaceId) {
      return;
    }

    const expectedSpacePresence = this.getPresenceByContextType(
      update.presence,
      ContextTypeEnum.SPACE,
    ).filter(
      (presenceData) => presenceData.breakoutRoomId === this.spaceRepo.activeSpaceCurrentRoomUid,
    );
    const expectedCallPresence = this.getPresenceByContextType(
      update.presence,
      ContextTypeEnum.CALL,
    ).filter(
      (presenceData) => presenceData.breakoutRoomId === this.spaceRepo.activeSpaceCurrentRoomUid,
    );

    const updatedSpacePresence = this.getUpdatedPresenceData(
      currentSpaceId,
      PresenceType.SPACE_PRESENCE,
      ContextTypeEnum.SPACE,
      currentBreakoutRoomId,
    );

    const updatedCallPresence = this.getUpdatedPresenceData(
      currentSpaceId,
      PresenceType.SPACE_PRESENCE,
      ContextTypeEnum.CALL,
      currentBreakoutRoomId,
    );

    this.comparePresence(expectedSpacePresence, updatedSpacePresence, 'space');
    this.comparePresence(expectedCallPresence, updatedCallPresence, 'call');
    this.compareUsersLeftPresence(usersLeftPresence, updatedSpacePresence);
    this.compareUsersJoinedPresence(usersJoinedPresence, updatedSpacePresence);
  }
  public notifyPresenceActivityChange(
    spaceId: string,
    breakoutRoomId: string | undefined,
    presence: Set<string>,
    inCallUsers: User[],
    outCallUsers: User[],
    contextType: ContextTypeEnum,
  ) {
    const currentSpacePresence = this.presenceRepo.getPresenceDataValue(
      spaceId,
      PresenceType.SPACE_PRESENCE,
      ContextTypeEnum.SPACE,
      breakoutRoomId,
    );

    const currentCallPresence = this.presenceRepo.getPresenceDataValue(
      spaceId,
      PresenceType.SPACE_PRESENCE,
      ContextTypeEnum.CALL,
      breakoutRoomId,
    );

    // Check for invalid state
    if (contextType === ContextTypeEnum.SPACE && currentSpacePresence) {
      const expectedSpacePresence = new Set<string>(
        currentSpacePresence.map((data) => data.userId),
      );

      const diff = this.symmetricDifference(expectedSpacePresence, presence);

      if (diff.size > 0) {
        this.logEvent(
          'error',
          `Invalid state detected: Mismatch between input presence and store SPACE presence {Expected Space Presence} ${stringifyCollection(
            expectedSpacePresence,
          )}, {Input Presence:} ${stringifyCollection(
            presence,
          )}, {Mismatched User IDs} , ${stringifyCollection(diff)}`,
          'mismatch_space_presence',
        );
      }
    } else if (contextType === ContextTypeEnum.CALL && currentCallPresence) {
      const expectedCallPresence = new Set<string>(currentCallPresence.map((data) => data.userId));
      const diff = this.symmetricDifference(expectedCallPresence, presence);

      if (diff.size > 0) {
        this.logEvent(
          'error',
          `Invalid state detected: Mismatch between input presence and store presence (CALL) {Expected Call Presence} ${stringifyCollection(
            expectedCallPresence,
          )}, {Input Presence} ${stringifyCollection(
            presence,
          )}, {Mismatched User IDs} ${stringifyCollection(diff)}`,
          'mismatch_call_presence',
        );
      }

      const inCallUsersSet = new Set<string>(inCallUsers.map((data) => data._id));

      const inCallUsersDif = this.symmetricDifference(inCallUsersSet, expectedCallPresence);

      if (inCallUsersDif.size > 0) {
        this.logEvent(
          'error',
          `Invalid state detected: Mismatch between input in call users and store presence (CALL) {Expected Call Presence} ${stringifyCollection(
            expectedCallPresence,
          )}, {Input in call users} ${stringifyCollection(
            inCallUsersSet,
          )}, {Mismatched User IDs} ${stringifyCollection(inCallUsersDif)}`,
          'mismatch_call_presence',
        );
      }
    }
  }

  public notifyUserLeftSpace(spaceId: string, userId: string, breakoutRoomId?: string): void {
    const currentPresence = this.presenceRepo.getPresenceDataValue(
      spaceId,
      PresenceType.SPACE_PRESENCE,
      ContextTypeEnum.SPACE,
      breakoutRoomId,
    );

    if (currentPresence) {
      const userPresenceCount = currentPresence.filter((x) => x.userId === userId).length;
      if (userPresenceCount > 0) {
        this.logEvent(
          'error',
          `User left space notifcation appears out of context. ${stringifyCollection({
            userId,
            spaceId,
            breakoutRoomId,
          })}`,
          'out_of_context_left_space',
        );
      }
    }
  }

  public notifyUserJoinedSpace(spaceId: string, userId: string, breakoutRoomId?: string): void {
    const currentPresence = this.presenceRepo.getPresenceDataValue(
      spaceId,
      PresenceType.SPACE_PRESENCE,
      ContextTypeEnum.SPACE,
      breakoutRoomId,
    );

    if (currentPresence) {
      const userPresenceCount = currentPresence.filter((x) => x.userId === userId).length;
      if (userPresenceCount !== 1) {
        this.logEvent(
          'error',
          `User joined space notifcation appears out of context. ${stringifyCollection({
            userId,
            spaceId,
            breakoutRoomId,
          })}`,
          'out_of_context_join_space',
        );
      }
    }
  }

  public notifyHeartbeatEvent(presenceData: PresenceData) {
    const hash = generateUserPresenceHeartbeatHash(presenceData);
    this.presenceRepo.addPresenceHeartbeatEvent(hash);
    this.analyzeHeartbeatIntervals(presenceData);
  }

  private analyzeHeartbeatIntervals(data: PresenceData) {
    const hash = generateUserPresenceHeartbeatHash(data);
    const userPresenceHeartbeat = this.presenceRepo.getUserPresenceHeartbeatEvents(hash);

    if (
      userPresenceHeartbeat &&
      userPresenceHeartbeat.events.length >= this.heartbeatEventsPollingThreshold
    ) {
      const latestEvents = userPresenceHeartbeat.events.slice(
        -this.heartbeatEventsPollingThreshold,
      );

      const exceedingThreshold = latestEvents.some((event, index) => {
        if (index < latestEvents.length - 1) {
          const nextEvent = latestEvents[index + 1];
          const timeDifference = nextEvent - event;
          return timeDifference > this.heartBeatEventsIntervalThreshold; // Check if the time difference is more than 30000 milliseconds (30 seconds)
        }
        return false; // Return false for the last event since there's no event to compare with
      });

      if (exceedingThreshold) {
        // handling logic for user exceeding threshold
        this.logEvent(
          'warn',
          `$User presence heartbeat event exceeded 30s. ${JSON.stringify(data)}`,
          'heartbeat_exceeded',
        );
      }

      this.presenceRepo.removeUserPresenceHeartbeatEvents(hash);
    }
  }

  // Function to calculate the symmetric difference between two sets
  private symmetricDifference(setA: Set<any>, setB: Set<any>): Set<any> {
    const diff = new Set(setA);

    for (const elem of setB) {
      if (diff.has(elem)) {
        diff.delete(elem);
      } else {
        diff.add(elem);
      }
    }

    return diff;
  }

  private isValidUpdate(currentSpaceId: string | undefined, update: PresenceRoom): boolean {
    return !currentSpaceId || currentSpaceId === update.spaceId;
  }

  private getPresenceByContextType(
    presence: PresenceData[],
    contextType: ContextTypeEnum,
  ): PresenceData[] {
    return presence.filter((data) => data.contextType === contextType);
  }

  private getUpdatedPresenceData(
    currentSpaceId: string,
    presenceType: PresenceType,
    contextType: ContextTypeEnum,
    currentBreakoutRoomId: string | undefined,
  ): PresenceData[] {
    return (
      this.presenceRepo.getPresenceDataValue(
        currentSpaceId,
        presenceType,
        contextType,
        currentBreakoutRoomId,
      ) || []
    );
  }

  private comparePresence(
    expectedPresence: PresenceData[],
    updatedPresence: PresenceData[],
    type: string,
  ) {
    const expectedPresenceHashes = expectedPresence.map((data) => data.uniqueHash);
    const updatedPresenceHashes = updatedPresence?.map((data) => data.uniqueHash);

    const missingUsers = expectedPresence.filter(
      (data) => !updatedPresenceHashes?.includes(data.uniqueHash),
    );
    if (missingUsers.length > 0) {
      this.logEvent(
        'error',
        `Invalid state detected: Users missing from updated ${type} presence ${stringifyCollection(
          this.mapPresenceDataToLog(missingUsers),
        )}`,
        'missing_users_in_presence',
      );
    }

    const extraUsers = updatedPresence?.filter(
      (data) => !expectedPresenceHashes.includes(data.uniqueHash),
    );
    if (extraUsers && extraUsers.length > 0) {
      this.logEvent(
        'error',
        `Invalid state detected: Extra users in updated ${type} presence ${stringifyCollection(
          this.mapPresenceDataToLog(extraUsers),
        )}`,
        'extra_users_in_presence',
      );
    }
  }

  private compareUsersLeftPresence(
    usersLeftPresence: PresenceData[],
    updatedSpacePresence: PresenceData[] | undefined,
  ) {
    if (usersLeftPresence.length > 0 && updatedSpacePresence) {
      const updatedSpacePresenceHashes = updatedSpacePresence.map((data) => data.uniqueHash);

      const missingUsers = usersLeftPresence.filter((data) =>
        updatedSpacePresenceHashes?.includes(data.uniqueHash),
      );
      if (missingUsers.length > 0) {
        this.logEvent(
          'error',
          `Invalid state detected: Users left presence still present in updated space presence ${stringifyCollection(
            this.mapPresenceDataToLog(missingUsers),
          )}`,
          'left_users_in_presence',
        );
      }
    }
  }

  private compareUsersJoinedPresence(
    usersJoinedPresence: PresenceData[],
    updatedSpacePresence: PresenceData[] | undefined,
  ) {
    if (usersJoinedPresence.length > 0 && updatedSpacePresence) {
      const updatedSpacePresenceHashes = updatedSpacePresence.map((data) => data.uniqueHash);

      const extraUsers = usersJoinedPresence.filter(
        (data) => !updatedSpacePresenceHashes.includes(data.uniqueHash),
      );
      if (extraUsers.length > 0) {
        this.logEvent(
          'error',
          `Invalid state detected: Users joined presence not present in updated space presence ${stringifyCollection(
            this.mapPresenceDataToLog(extraUsers),
          )}`,
          'joined_users_not_in_presence',
        );
      }
    }
  }

  private mapPresenceDataToLog(presenceData: PresenceData[]) {
    const presenceLog: Record<ContextTypeEnum, Array<any>> = {
      [ContextTypeEnum.SPACE]: [],
      [ContextTypeEnum.CALL]: [],
      [ContextTypeEnum.RECORDING]: [],
      [ContextTypeEnum.LMS]: [],
      [ContextTypeEnum.WAITING_ROOM]: [],
    };
    presenceData.forEach((userPresence) => {
      presenceLog[userPresence.contextType].push({
        name: this.userMap.get(userPresence.userId) ?? userPresence.userId,
        frameId: userPresence.frameId,
        uniqueHash: userPresence.uniqueHash,
        timestamp: userPresence.timestamp,
      });
    });
    return presenceLog;
  }

  private logEvent(
    level: FS.LogLevel,
    msg: string,
    inconsistency_type: string,
    telemetryEvent = 'presence_inconsistency',
  ): void {
    const logMessage = `[presence-monitoring]: ${msg}`;
    FS.log(level, logMessage);

    const eventProperties = { msg };
    if (inconsistency_type) {
      eventProperties['inconsistency_type'] = inconsistency_type;
    }
    this.telemetryService.errorEvent(telemetryEvent, eventProperties);
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fabric } from 'fabric';
import { BehaviorSubject, filter, fromEvent, of, switchMap } from 'rxjs';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { SpaceRepository } from 'src/app/state/space.repository';
import { LockSnackbarComponent } from 'src/app/ui/lock-snackbar/lock-snackbar.component';
import { FragmentType, TypedFragment } from '../../../common/typed-fragment/typed-fragment';
import { Note } from '../../../models/note';
import { Question } from '../../../models/question';
import { ItemModel, QuestionState } from '../../../models/session';
import { User } from '../../../models/user';
import {
  SessionSharedDataService,
  SessionView,
} from '../../../services/session-shared-data.service';
import { BoundingRect } from '../../../services/sessions-vpt.service';
import { UserService } from '../../../services/user.service';
import { CanvasItem } from '../items-canvas/items-canvas.component';
import { YCanvas, YStaticCanvas } from '../wb-canvas/fabric-utils';
import { FLAGS, FlagsService } from '../../../services/flags.service';
import { INTERACTIONS_BAR_HEIGHT } from '../iframe/additional-apps.utils';
import { SpaceZoomCutoffService } from '../../../services/space-zoom-cutoff.service';
import { DomListenerFactoryService } from '../../../services/dom-listener-factory.service';
import { DomListener } from '../../../utilities/DomListener';
import { BoardItemsInteractionsManager } from '../items-canvas/board-items-interactions-manager';

export enum Status {
  OFF = 'OFF',
  Edit = 'EDIT',
}

export enum WrappedComponent {
  questionComponent,
  noteComponent,
  fragment,
  iframe,
  browser,
  mario,
  chat_gpt,
  remoteVideo,
  resourceProgress,
}

export class QuestionViewInputs {
  item?: Question | Note;
  courseId = '';
  questionState?: QuestionState;
  usersAnswers?: Map<string, string>;
  questionAnswer?: ($event: any) => any;
  showDetails?: () => any;
}

export class PdfContext {
  documentProxy: PDFDocumentProxy;
  // An array of subjects containing the canvases used for each pdf page.
  pages$: { [id: number]: BehaviorSubject<HTMLCanvasElement | null> };
  height?: number;
  width?: number;

  constructor(
    documentProxy: PDFDocumentProxy,
    pages$: { [id: number]: BehaviorSubject<HTMLCanvasElement | null> },
  ) {
    this.documentProxy = documentProxy;
    this.pages$ = pages$;
  }
}

export class ResourceViewInputs {
  _id = '';
  fragment?: TypedFragment;
  pdf?: PdfContext;
}

export class WrappedComponentSettings {
  canAccess = false;
  cannotAccessQuestion = false;
  notInvitedToCourse = false;
  wrappedComponent: WrappedComponent;
  componentInputs?: QuestionViewInputs;
  resourceComponentInputs?: ResourceViewInputs;

  constructor(wrappedComponent: WrappedComponent) {
    this.wrappedComponent = wrappedComponent;
    switch (wrappedComponent) {
      case WrappedComponent.noteComponent:
      case WrappedComponent.questionComponent:
        this.componentInputs = new QuestionViewInputs();
        break;
      case WrappedComponent.fragment:
        this.resourceComponentInputs = new ResourceViewInputs();
        this.canAccess = true;
    }
  }
}

@UntilDestroy()
@Component({
  selector: 'app-item-wrapper',
  templateUrl: './item-wrapper.component.html',
  styleUrls: ['./item-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemWrapperComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('box') public box!: ElementRef;
  @Output() itemStateChanged = new EventEmitter<CanvasItem>();
  @Output() sentDeleteItemEvent = new EventEmitter<string>();
  @Input() canvasItem?: CanvasItem;
  @Input() static = false;
  @Input() staticCanvas?: YStaticCanvas;
  @Input() relatedFabricObject?: fabric.Object & {
    itemData: any;
    selectedUsingInteractionsBar?: boolean;
  };
  @Input() loadForBoardPreview = false;

  public interactionsEnabled$ = of(false);

  user?: User;
  boundOnObjectModified = this.onObjectModified.bind(this);
  domListener: DomListener;

  Status = Status;
  WrappedComponent = WrappedComponent;
  ItemModel = ItemModel;
  sessionView = SessionView;
  fragmentType = FragmentType;
  INTERACTIONS_BAR_HEIGHT = INTERACTIONS_BAR_HEIGHT;

  constructor(
    public sharedDataService: SessionSharedDataService,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    public spaceRepo: SpaceRepository,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private flagsService: FlagsService,
    public spaceZoomCutoff: SpaceZoomCutoffService,
    private domListenerFactoryService: DomListenerFactoryService,
    private boardItemsInteractionsManager: BoardItemsInteractionsManager,
  ) {
    this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res) {
        this.user = res.user;
      }
    });
    this.domListener = this.domListenerFactoryService.createInstance();
  }

  webViewerDisabled =
    !this.flagsService.isFlagEnabled(FLAGS.MARIO) ||
    !(this.flagsService.featureFlagsVariables.mario.enable_browser as boolean);

  ngOnInit(): void {
    const itemFragment = this.canvasItem?.itemSettings?.resourceComponentInputs?.fragment;

    if (itemFragment?.type === FragmentType.Image) {
      // just to make sure that the data is a string
      // if it is a svg base64, we need to tell Angular to trust the resource and load the image as expected
      if (itemFragment?.fragment.data.includes('data:image/svg+xml;')) {
        itemFragment.fragment.data = this.sanitizer.bypassSecurityTrustResourceUrl(
          itemFragment.fragment.data,
        );
      }
    }

    this.ngZone.runOutsideAngular(() => {
      let snackBarRef: MatSnackBarRef<LockSnackbarComponent> | null = null;
      fromEvent(this.sharedDataService.fabricCanvas as fabric.Canvas, 'mouse:down')
        .pipe(
          untilDestroyed(this),
          filter(
            (event) =>
              event.target === this.canvasItem?.relatedFabricItem &&
              Boolean((event.target as any).locked) &&
              snackBarRef === null &&
              this.sharedDataService.mainToolbar?.activeToolName === 'pointer',
          ),
          switchMap(() => {
            if (this.snackBar._openedSnackBarRef) {
              this.snackBar.dismiss();

              return this.snackBar._openedSnackBarRef?.afterDismissed();
            }

            return of(true);
          }),
          switchMap(() => {
            snackBarRef = this.snackBar.openFromComponent(LockSnackbarComponent, {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              panelClass: 'lock-snack-bar',
              data: this.canvasItem,
            });

            return snackBarRef.afterDismissed();
          }),
        )
        .subscribe(() => {
          snackBarRef = null;
        });
    });

    if (this.canvasItem) {
      this.interactionsEnabled$ = this.boardItemsInteractionsManager.interactionsEnabled$(
        this.canvasItem,
      );
    }
  }

  ngAfterViewInit(): void {
    if (this.relatedFabricObject && this.canvasItem) {
      const relatedCanvas = this.getRelatedCanvas();
      if (!relatedCanvas?.contains(this.relatedFabricObject)) {
        relatedCanvas?.add(this.relatedFabricObject);
      }
      this.addRelatedFabricObjectListeners();
      relatedCanvas?.requestRenderAll();
      this.changeItemState();
    }
    if (this.static) {
      return;
    }
    // passing events to fabric even if the mouse is on the item (fixing: SPAC-4942)
    const canvasElm = document.getElementsByClassName('upper-canvas').item(0);
    this.domListener.add(
      this.box.nativeElement,
      'pointermove',
      (e) => {
        const event = new PointerEvent('pointermove', { clientX: e.clientX, clientY: e.clientY });
        canvasElm?.dispatchEvent(event);
      },
      true,
    );
  }

  addRelatedFabricObjectListeners(): void {
    if (!this.canvasItem?.movable) {
      return;
    }
    // subscribing on the fabric level instead of the fabric object level to handle the case where the current object is part of a multi-selection
    this.sharedDataService.fabricCanvas?.on('object:modified', this.boundOnObjectModified);
    this.relatedFabricObject?.on('remoteChange', this.changeItemState.bind(this));
    this.relatedFabricObject?.on('scaling', this.sendLocalChanges.bind(this));
  }

  getRelatedCanvas(): YCanvas | YStaticCanvas | undefined {
    return this.static ? this.staticCanvas : this.sharedDataService.fabricCanvas;
  }

  ngOnDestroy() {
    if (this.relatedFabricObject) {
      const canvas = this.static ? this.staticCanvas : this.sharedDataService.fabricCanvas;

      if (!this.static && this.sharedDataService.fabricCanvas) {
        this.sharedDataService.fabricCanvas.discardActiveObject();
      }
      canvas?.remove(this.relatedFabricObject);
      canvas?.requestRenderAll();
    }
    this.domListener.clear();
    this.sharedDataService.fabricCanvas?.off('object:modified', this.boundOnObjectModified);
  }

  changeItemState(): void {
    if (!this.canvasItem) {
      return;
    }

    const { top, left, height, width, angle } = this.getRelatedFabricItemState();
    this.canvasItem.itemState.left = left;
    this.canvasItem.itemState.top = top;
    this.canvasItem.itemState.width = width;
    this.canvasItem.itemState.height = height;
    this.canvasItem.itemState.rotation = angle;
  }

  sendLocalChanges(): void {
    this.changeItemState();
    this.itemStateChanged.emit(this.canvasItem);
  }

  onObjectModified(e) {
    if (
      e.target === this.relatedFabricObject ||
      (this.relatedFabricObject?.group && e.target?.type === 'activeSelection')
    ) {
      // item is selected alone or part of a multi-selection
      this.sendLocalChanges();
    }
  }

  // Converts point to the transformed space
  // Full Explanation: https://docs.google.com/document/d/1EhfANJTD1153K3RejO6Pb2piUp716AIoe3ZVwe39k2g/edit?usp=sharing
  private toInvViewportSpace(point: { x: number; y: number }): fabric.Point {
    const fabricCanvas = this.sharedDataService.fabricCanvas;
    if (!fabricCanvas?.viewportTransform) {
      return new fabric.Point(point.x, point.y);
    }
    // Convert the points to the same space as the zoomed out absolute space
    const viewportTransform = fabricCanvas.viewportTransform;
    const invViewportTransform = fabric.util.invertTransform(viewportTransform);

    const fabricPoint = new fabric.Point(point.x, point.y);
    return fabric.util.transformPoint(fabricPoint, invViewportTransform);
  }

  // This is used when you have a component that don't have a pre-determined size
  // So you call this function when you know the exact rendered size of your component
  async updateRelatedFabricObjectSize(rect: BoundingRect) {
    requestAnimationFrame(() => {
      if (!this.canvasItem || !rect || !this.relatedFabricObject) {
        return;
      }
      const topLeft = this.toInvViewportSpace(new fabric.Point(rect.left, rect.top));
      const bottomRight = this.toInvViewportSpace(
        new fabric.Point(rect.left + rect.width, rect.top + rect.height),
      );
      const size = bottomRight.subtract(topLeft);
      // Add 3px to count for padding to center the object
      const width = Number(size.x > 0) ? size.x + 3 : 0;
      const height = Number(size.y > 0) ? size.y + 3 : 0;
      if (width) {
        this.relatedFabricObject.width = width;
      }
      if (height) {
        this.relatedFabricObject.height = height;
      }
      this.relatedFabricObject.setCoords();
      this.relatedFabricObject.fire('remoteChange');
      this.sharedDataService.fabricCanvas?.requestRenderAll();
    });
  }

  getRelatedFabricItemState(): {
    top: number;
    left: number;
    height: number;
    width: number;
    angle: number;
  } {
    if (!this.relatedFabricObject) {
      return { top: 0, left: 0, height: 0, width: 0, angle: 0 };
    }
    const group = this.relatedFabricObject.group;
    let top: number;
    let left: number;
    const height =
      (this.relatedFabricObject.height ?? 0) *
      (this.relatedFabricObject.group?.scaleY ?? 1) *
      (this.relatedFabricObject.scaleY ?? 1);

    const width =
      (this.relatedFabricObject.width ?? 0) *
      (this.relatedFabricObject.group?.scaleX ?? 1) *
      (this.relatedFabricObject.scaleX ?? 1);
    let angle = this.relatedFabricObject['angle'] ?? 0;
    if (group) {
      const matrix = this.relatedFabricObject.calcTransformMatrix();
      angle = Math.atan2(matrix[1], matrix[0]) * (180 / Math.PI);
      const point = {
        x: -(this.relatedFabricObject.width ?? 0) / 2,
        y: -(this.relatedFabricObject.height ?? 0) / 2,
      };
      const pointOnCanvas = fabric.util.transformPoint(point as fabric.Point, matrix);
      top = pointOnCanvas.y;
      left = pointOnCanvas.x;
    } else {
      top = this.relatedFabricObject.top ?? 0;
      left = this.relatedFabricObject.left ?? 0;
    }
    return { top, left, height, width, angle };
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { User } from './models/user';
import { FLAGS, FlagsService } from './services/flags.service';
import { NavService } from './services/nav.service';
import { UserService } from './services/user.service';

@Injectable()
export class BillingActivate implements CanActivate {
  user?: User;

  constructor(
    private router: Router,
    private navService: NavService,
    private userService: UserService,
    private flagService: FlagsService
  ) {}

  async canActivate(): Promise<boolean> {
    this.user = this.userService.user.value?.user || await this.navService.loading;
    if (this.user && this.flagService.isFlagEnabled(FLAGS.ENABLE_BILLING)) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}

<div class="messages-box d-flex flex-column h-100">
  <div
    *ngIf="!ignoreHeaderInfo"
    class="top-section border-bottom d-flex align-items-center justify-content-center"
  >
    <i
      *ngIf="(wideScreen$ | async) === false"
      [routerLink]="['/messages/home']"
      [queryParamsHandling]="'merge'"
      class="material-icons btn-close cursor-pointer"
      >close</i
    >
    <ng-container *ngIf="headerInfo$ | async as headerInfo">
      <p>{{ 'Chat with' | translate }} {{ headerInfo.name }}</p>
    </ng-container>
  </div>
  <ng-container
    *ngIf="
      messagingService.selectedConversationPreview$ | async as selectedConversationPreview;
      else chatPreviewLoading
    "
  >
    <div class="flex-1 message-list-container">
      <app-message-list
        *ngIf="
          messagingService.selectedConversationContent$ | async as conversationContent;
          else chatLoading
        "
        [conversationContent]="conversationContent"
        [typingUserIds]="(messagingService.selectedConversationTypingUserIds$ | async) ?? []"
        [displayLoading]="messageListLoading(selectedConversationPreview, conversationContent)"
        [enableEditing]="enableMessaging"
        (updateMessage)="onUpdateMessage($event)"
        (deleteMessage)="onDeleteMessage($event)"
        (fetchMoreMessages)="onFetchMoreMessages($event)"
        (replyMessage)="setReplyMessage($event)"
      ></app-message-list>
      <ng-template #chatLoading>
        <app-loading [showTipsIndicator]="true" [loading]="true"></app-loading>
      </ng-template>
    </div>
    <div>
      <ng-container *ngIf="enableMessaging; else messageDisabled">
        <app-create-comment
          class="position-relative create-comment"
          type="message"
          maxHeight="300"
          [context]="selectedConversationPreview.context"
          [autoFocus]="true"
          [privateChat]="true"
          [emitTyping]="emitTyping"
          [enableControls]="enableControls"
          [isClickEnabled]="isClickEnabled"
          [parentMessage]="replyMessage"
        ></app-create-comment>
      </ng-container>
      <ng-template #messageDisabled>
        <div class="message-disabled">
          {{ "You don't have permission to chat with participants." | translate }}
        </div>
      </ng-template>
    </div>
  </ng-container>
  <ng-template #chatPreviewLoading>
    <app-loading [showTipsIndicator]="true" [loading]="true"></app-loading>
  </ng-template>
</div>

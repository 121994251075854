import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Visibility } from '../models/session';

@Pipe({
  name: 'visibilityText',
})
export class VisibilityTextPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private translate: TranslateService) {}

  transform(visibility: Visibility): string {
    switch (visibility) {
      case Visibility.PRIVATE:
        return this.translate.instant('PRIVATE');
      case Visibility.PUBLIC:
        return this.translate.instant('PUBLIC');

      default:
        return this.translate.instant('PRIVATE');
    }
  }
}

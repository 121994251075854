<div class="sidenav w-100">
  <div class="sidebar-top">
    <ul class="list-group w-100">
      <li
        *ngIf="user"
        class="list-group-item list-group-item-action item-spaces"
        (click)="goTo('Whiteboard')"
        data-intercom-target="Whiteboard"
        [class.active]="router.url === '/spaces'"
      >
        <em class="material-icons cmr-8">home</em>
        <span>{{ 'Home' | translate }}</span>
      </li>
      <li
        *ngIf="(user | has: Features.view_schedule_page) && isScheduleEnabled"
        class="list-group-item list-group-item-action"
        (click)="goTo('Schedule')"
        [class.active]="active === 'Schedule'"
        data-intercom-target="Schedule"
      >
        <em class="material-icons-outlined cmr-8"> calendar_today </em
        ><span>{{ 'Schedule' | translate }}</span>
      </li>
    </ul>
    <mat-divider></mat-divider>
    <ul class="list-group w-100">
      <li
        *ngIf="isFilesMenuEnabled && (user | has: Features.view_resource)"
        class="list-group-item list-group-item-action"
        (click)="goTo('Files')"
        [class.active]="active === 'Files'"
        data-intercom-target="Files-Sidebar"
      >
        <em class="material-icons-outlined cmr-8"> folder_open </em
        ><span>{{ 'Files' | translate }}</span>
      </li>
      <li
        *ngIf="isMessagesEnabled && (user | has: Features.chat)"
        class="list-group-item list-group-item-action"
        (click)="goTo('Messages')"
        [class.active]="active === 'Messages'"
        data-intercom-target="Messages"
      >
        <em class="material-icons-outlined cmr-8 pcl-teacher-nav-bar-chat_bubble">message</em>
        <span>{{ 'Messages' | translate }}</span>
      </li>
      <li
        class="list-group-item list-group-item-action"
        data-intercom-target="Profiles"
        [class.active]="active === 'Profiles'"
        (click)="goTo('Profiles')"
        *ngIf="(user | has: Features.ProfilesPage) && isProfilesPageEnabled"
      >
        <span class="material-icons-outlined cmr-8"> people </span>
        <span>{{ 'Profiles' | translate }}</span>
      </li>
    </ul>
    <mat-expansion-panel
      [@.disabled]="true"
      [expanded]="expanded === 'Profiles'"
      *ngIf="(user | has: Features.ProfilesPage) && !isProfilesPageEnabled"
    >
      <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
        <span>{{ 'Profiles' | translate }}</span>
        <em class="material-icons expand-indicator">keyboard_arrow_up</em>
      </mat-expansion-panel-header>
      <ul class="list-group">
        <a
          class="list-group-item list-group-item-action"
          (click)="goTo('My profile')"
          [class.active]="active === 'My profile'"
          >{{ 'My profile' | translate }}</a
        >
        <a
          class="list-group-item list-group-item-action"
          (click)="goTo('Teachers')"
          [class.active]="active === 'Teachers'"
          >{{ 'Teachers' | translate }}</a
        >
      </ul>
    </mat-expansion-panel>
    <div class="courses-menu-item">
      <ul class="list-group w-100">
        <li
          class="list-group-item list-group-item-action"
          (click)="goTo('Courses')"
          [class.active]="router.url === '/content'"
          [hidden]="isGuestUser"
        >
          <em class="material-icons-outlined cmr-8">school</em>
          <span>{{ 'Courses' | translate }}</span>
        </li>
      </ul>
      <span
        class="new-course-btn"
        [class.active]="router.url === '/content'"
        routerLink="/content/course/new"
        *ngIf="user | has: Features.create_course"
      >
        <em class="material-icons">add</em>
      </span>
    </div>

    <div
      *ngIf="
        (isAttendanceReportEanbled || isAnalyticstReportEnabled) &&
        user &&
        aclService.isAdminOrSiteAdmin(user) &&
        (uiService.isMobile | async) === false
      "
      class="analytics-options"
    >
      <ul class="list-group w-100">
        <li
          (click)="expanded = expanded === 'analytics' ? '' : 'analytics'"
          class="list-group-item list-group-item-action panel-header"
        >
          <svg-icon
            class="cmr-8"
            [icon]="'spaces_list_navbar_analytics'"
            [width]="18"
            [height]="18"
          ></svg-icon>
          <span>{{ 'Analytics' | translate }}</span>
          <mat-icon class="expand-icon"
            >{{ expanded === 'analytics' ? 'expand_less' : 'expand_more' }}
          </mat-icon>
        </li>
      </ul>
      <div *ngIf="expanded === 'analytics'">
        <ul class="list-group w-100">
          <li
            (click)="goTo('Analytics/Attendance')"
            [class.active]="router.url === '/analytics/attendance'"
            class="list-group-item list-group-item-action"
            data-intercom-target="Attendance"
            *ngIf="isAttendanceReportEanbled"
          >
            <svg-icon
              class="cmr-8 cml-20"
              [icon]="
                'spaces_list_navbar_attendance' +
                (router.url === '/analytics/attendance' ? '_active' : '')
              "
              [width]="18"
              [height]="18"
            ></svg-icon>
            <span>{{ 'Attendance' | translate }}</span>
          </li>
          <li
            (click)="goTo('Analytics/Engagement')"
            [class.active]="router.url === '/analytics/engagement'"
            class="list-group-item list-group-item-action"
            data-intercom-target="Engagement"
            *ngIf="isAnalyticstReportEnabled"
          >
            <svg-icon
              class="cmr-8 cml-20"
              [icon]="'engagement'"
              [width]="18"
              [height]="18"
            ></svg-icon>
            <span>{{ 'Engagement' | translate }}</span>
          </li>
        </ul>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div
      class="more-options"
      *ngIf="
        (user | has: Features.AdminPage) ||
        (user | has: Features.Impersonate) ||
        (user | has: Features.CustomFeatures)
      "
    >
      <ul class="list-group w-100" *ngIf="expanded !== 'more'">
        <li (click)="expanded = 'more'" class="list-group-item list-group-item-action panel-header">
          <em class="material-icons cmr-8">more_horiz</em>
          <span>{{ 'More' | translate }}</span>
        </li>
      </ul>
      <mat-expansion-panel
        *ngIf="expanded === 'more'"
        [@.disabled]="true"
        [expanded]="expanded === 'more'"
        (opened)="moreMenuOpened()"
        (closed)="expanded = ''"
      >
        <ul class="list-group w-100">
          <li
            class="list-group-item list-group-item-action"
            *ngIf="user | has: Features.AdminPage"
            (click)="goTo('Admin Portal')"
            [class.active]="active === 'Admin Portal'"
          >
            <span>{{ 'Admin Portal' | translate }}</span>
          </li>
          <li
            class="list-group-item list-group-item-action"
            *ngIf="user | has: Features.Impersonate"
            (click)="impersonate()"
            [class.active]="isImpersonateClicked"
          >
            <span>{{ 'Impersonate' | translate }}</span>
          </li>
          <li
            class="list-group-item list-group-item-action"
            *ngIf="user | has: Features.CustomFeatures"
            (click)="showFeatureFlags()"
            [class.active]="isFeatureFlagsClicked"
            [class.hasCustomFlags]="isUsingCustomFlags"
          >
            <span>{{ 'Feature Flags' | translate }}</span>
          </li>
          <li
            *ngIf="expanded === 'more'"
            (click)="expanded = ''"
            class="list-group-item list-group-item-action panel-header"
          >
            <em class="material-icons cmr-8">more_horiz</em>
            <span>{{ 'Less' | translate }}</span>
          </li>
        </ul>
      </mat-expansion-panel>
    </div>
  </div>
  <div class="sidebar-bottom">
    <div *ngIf="isReferFriendEnabled" class="d-flex flex-column align-items-center" id="referral">
      <div class="d-flex flex-column cp-16">
        <div class="font-12 fw-6 w-100 cmb-4">
          {{ 'Get Pro for Free!' | translate }}
        </div>
        <div class="font-12 w-100 cmb-16">
          {{ "Get your friends to sign up for Pro, and you'll get 1 month free." | translate }}
        </div>
        <button
          mat-stroked-button
          id="btn-referral"
          class="d-flex align-items-center justify-content-center"
          (click)="shareReferral()"
        >
          <em class="font-18 material-icons justify-content-center cmr-8">card_giftcard</em>
          <span>{{ 'Refer a friend' | translate }}</span>
        </button>
      </div>
    </div>
    <div id="powered-by-pencil" *ngIf="user?.institution && !hidePoweredByPencil">
      <a [href]="marketingURL" target="_blank" rel="noopener noreferrer">
        {{ 'Powered by ' | translate }}
        <strong>{{ pencilLogoText }}</strong>
      </a>
    </div>
    <ul class="list-group w-100 list-group-inline tools-icons">
      <li
        class="list-group-item list-group-item-action"
        *ngIf="isDoorbellEnabled"
        [ngbTooltip]="reportProblemTooltip"
        (click)="reportProblem()"
      >
        <span class="material-icons-outlined"> report_problem </span>
      </li>
      <li
        class="list-group-item list-group-item-action"
        id="intercomHelpLink"
        [ngbTooltip]="helpTooltip"
        *ngIf="isIntercomEnabled"
      >
        <span class="material-icons"> help_outline </span>
      </li>
    </ul>

    <ng-template #reportProblemTooltip>
      <app-tooltip [arrow]="'bottom'" [text]="'Report a problem' | translate"></app-tooltip>
    </ng-template>
    <ng-template #helpTooltip>
      <app-tooltip [arrow]="'bottom'" [text]="'Help' | translate"></app-tooltip>
    </ng-template>
  </div>
</div>

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Subscription, distinctUntilChanged } from 'rxjs';
import { RoomAttendees, SessionStatus } from 'src/app/models/analytics';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { FLAGS, FlagsService } from 'src/app/services/flags.service';
import { PresenceProvider } from 'src/app/services/presence-provider';
import { SpaceRepository } from 'src/app/state/space.repository';
import {
  IAnalyticsInsightParsed,
  SpaceDataLevel,
  getTimeString,
  hasCall,
} from '../session_analytics_utils';
import {
  SessionInsightsTableDataSource,
  SessionInsightsTableItem,
} from './session-insights-table-datasource';

@UntilDestroy()
@Component({
  selector: 'app-session-insights-table',
  templateUrl: './session-insights-table.component.html',
  styleUrls: ['./session-insights-table.component.scss'],
})
export class SessionInsightsTableComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild('sessionTitleElement') sessionTitleElement;
  @Input() currentSession!: IAnalyticsInsightParsed;
  @Output() refetchSession = new EventEmitter<void>();

  isEditingSessionName = false;

  tableData?: SessionInsightsTableItem[];
  dataSource!: SessionInsightsTableDataSource;
  SpaceDataLevel = SpaceDataLevel;
  isSessionTitleInvalid = false;

  spacePresenceSubsciption?: Subscription;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name', 'timeJoined', 'timeOnCall'];
  analyticsRooms: RoomAttendees[] = [];
  archivedAnalyticsRooms: RoomAttendees[] = [];
  selectedRoom$: BehaviorSubject<string> = new BehaviorSubject<string>(SpaceDataLevel.SPACE);

  isBreakoutRoomActiveInSession = false;

  // Creating these fixed values since order of array is whats shown in the table
  private readonly ONGOING_SESSION_COLUMNS = ['name', 'timeJoined'];
  private readonly SESSION_WITH_CALLS_COLUMNS = [
    'name',
    'timeJoined',
    'timeLeft',
    'timeOnCall',
    'userSpaceDuration',
    'backgroundDuration',
    'audioDuration',
    'videoDuration',
    'raisedHandCount',
    'reactionsCount',
    'spaceCountPublicMessagesSent',
    'spaceCountPrivateMessagesSent',
  ];
  private readonly SESSION_WITHOUT_CALLS_COLUMNS = [
    'name',
    'timeJoined',
    'timeLeft',
    'userSpaceDuration',
    'backgroundDuration',
    'spaceCountPublicMessagesSent',
    'spaceCountPrivateMessagesSent',
  ];

  constructor(
    public dialog: MatDialog,
    private analyticsService: AnalyticsService,
    private presenceProvider: PresenceProvider,
    private spaceRepo: SpaceRepository,
    private flagsService: FlagsService,
  ) {
    if (this.flagsService.isFlagEnabled(FLAGS.SPEECH_DURATION_COLUMN)) {
      this.SESSION_WITH_CALLS_COLUMNS.splice(6, 0, 'speechDuration');
    }
  }

  ngOnInit(): void {
    this.selectedRoom$.pipe(untilDestroyed(this)).subscribe((roomId) => {
      if (!this.currentSession) {
        return;
      }
      if (roomId === SpaceDataLevel.SPACE) {
        const data = this.currentSession.attendees;
        this.dataSource = new SessionInsightsTableDataSource(data, SpaceDataLevel.SPACE);
      } else {
        const data = this.currentSession.roomAttendees.find(
          (room) => room.roomUid === roomId,
        )?.attendees;
        this.dataSource = new SessionInsightsTableDataSource(data as any, SpaceDataLevel.ROOM);
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ngOnChanges(): void {
    if (!this.currentSession) {
      return;
    }
    if (this.spacePresenceSubsciption) {
      this.spacePresenceSubsciption.unsubscribe();
    }
    this.dataSource = new SessionInsightsTableDataSource(
      this.currentSession.attendees,
      this.selectedRoom$.value === SpaceDataLevel.SPACE
        ? SpaceDataLevel.SPACE
        : SpaceDataLevel.ROOM,
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.analyticsRooms = [];
    this.archivedAnalyticsRooms = [];
    this.selectedRoom$.next(SpaceDataLevel.SPACE);
    this.displayedColumns = this._getDisplayedColumns();

    // Subscribe to space presence
    if (
      this.currentSession.sessionId === this.analyticsService.getCurrentSessionId() &&
      this.spaceRepo.activeSpace?._id
    ) {
      this.subscribeToSpacePresence(this.spaceRepo.activeSpace._id);
    }

    for (const room of this.currentSession.roomAttendees) {
      if (!room.roomUid || room.roomUid === 'undefined') {
        continue;
      }
      room.isActive ? this.analyticsRooms.push(room) : this.archivedAnalyticsRooms.push(room);
    }
    this.isBreakoutRoomActiveInSession =
      this.archivedAnalyticsRooms.length > 0 || this.analyticsRooms.length > 1;
  }

  private _getDisplayedColumns(): string[] {
    if (this.currentSession.status === SessionStatus.PROCESSED) {
      if (hasCall(this.currentSession)) {
        return this.SESSION_WITH_CALLS_COLUMNS;
      } else {
        return this.SESSION_WITHOUT_CALLS_COLUMNS;
      }
    } else {
      return this.ONGOING_SESSION_COLUMNS;
    }
  }

  private subscribeToSpacePresence(spaceId: string) {
    this.spacePresenceSubsciption = this.presenceProvider
      .getSpacePresenceActivity(spaceId)
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(async (userIds) => {
        const currentUsers = new Set(this.dataSource.data.map((attendee) => attendee.userId));
        const newUsers = [...userIds].filter((userId) => !currentUsers.has(userId));
        if (newUsers.length > 0) {
          this.refetchSession.emit();
        }
      });
  }

  public getTimeString(dateObj: Date) {
    return getTimeString(dateObj);
  }

  isRoomArchived(roomUid: string | null): boolean {
    return !!roomUid && this.archivedAnalyticsRooms.map((room) => room.roomUid).includes(roomUid);
  }
}

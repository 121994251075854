import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxSliderModule } from '@m0t0r/ngx-slider';

import { SafePipeModule } from 'safe-pipe';
import { UiModule } from '../ui/ui.module';

import { PipeModule } from '../pipes/pipes.module';
import { SVGIconComponentsModule } from '../common/svg-icon/svg-icon-components.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './users.component';

@NgModule({
  declarations: [UsersComponent, UserProfileComponent],
  imports: [
    CommonModule,
    UiModule,
    UsersRoutingModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressBarModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SafePipeModule,
    MatChipsModule,
    PipeModule,
    MatSelectModule,
    ColorPickerModule,
    MatMenuModule,
    NgxSliderModule,
    SVGIconComponentsModule,
  ],
  exports: [],
})
export class UsersModule {}

import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter, map } from 'rxjs';
import { IEvent, IText } from 'fabric/fabric-impl';
import { fabric } from 'fabric';
import { ItemModel } from '../../../models/session';
import { FragmentType } from '../../../common/typed-fragment/typed-fragment';
import {
  SessionSharedDataService,
  SessionView,
} from '../../../services/session-shared-data.service';
import { DomListenerFactoryService } from '../../../services/dom-listener-factory.service';
import { FLAGS, FlagsService } from '../../../services/flags.service';
import { TelemetryService } from '../../../services/telemetry.service';
import {
  fabricObjectsControlsNames,
  itemIsCanvasItem,
  itemIsStickyNoteRelatedText,
} from '../wb-canvas/fabric-utils';
import { CanvasItem } from './items-canvas.component';

export interface Selection {
  object: fabric.Object;
  isMultiSelection: boolean;
  isCanvasItemSelected: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class BoardItemsInteractionsManager {
  private interactableItemsTypes = [
    ItemModel.IFrame,
    FragmentType.Iframe,
    ItemModel.WebViewer,
    FragmentType.WebViewer,
    ItemModel.Mario,
    FragmentType.Mario,
    ItemModel.Chat_GPT,
    FragmentType.Chat_GPT,
    FragmentType.Youtube,

    // TODO this was added to make the remote video above everything once added [SPAC-8815]
    // instead add a general listener for fabric insertion in the stacking order service
    ItemModel.RemoteVideo,
  ]; // items that users need to interact with its content not just move, rotate, or resize it

  private NO_ITEM = 'NO_ITEM'; // placeholder used when no item is interactable

  private objectsPreventedFromMultiSelection = [
    FragmentType.Chat_GPT,
    FragmentType.Mario,
    FragmentType.Iframe,
    FragmentType.WebViewer,
  ];

  private _interactableItemId$ = new BehaviorSubject<string>(this.NO_ITEM);
  private interactableItemId$ = this._interactableItemId$.asObservable();

  // This holds a value only when a canvas item is selected alone
  private _selectedCanvasItem$ = new BehaviorSubject<CanvasItem | undefined>(undefined);
  public selectedCanvasItem$ = this._selectedCanvasItem$.asObservable();

  private domListener = this.domListenerFactoryService.createInstance();

  private boundFabricSelectionChange = this.fabricSelectionChanged.bind(this);
  private boundFabricSelectionCleared = this.deselectAll.bind(this);
  private boundRemoteModified = this.triggerDeselectionOnRemoteObjectChanged.bind(this);

  // emits undefined when there is no selection active
  private _selection$ = new BehaviorSubject<Selection | undefined>(undefined);
  public selection$ = this._selection$.asObservable();

  constructor(
    private sharedDataService: SessionSharedDataService,
    private domListenerFactoryService: DomListenerFactoryService,
    private flagsService: FlagsService,
    private telemetryService: TelemetryService,
  ) {}

  public init() {
    this.setUpFabricSelectionListeners();
    this.triggerSelectionWhenAppFullScreened();
    this.exitTextEditingModeWhenClickingOutsideFabric();
  }

  public destroyService(): void {
    this.sharedDataService.fabricCanvas?.off('selection:updated', this.boundFabricSelectionChange);
    this.sharedDataService.fabricCanvas?.off('selection:created', this.boundFabricSelectionChange);
    this.sharedDataService.fabricCanvas?.off('selection:cleared', this.boundFabricSelectionCleared);
    this.sharedDataService.fabricCanvas?.off('remote:modified', this.boundRemoteModified);
    this.deselectAll();
    this.domListener.clear();
  }

  // Items that its content need to receive user input, such as the ones mentioned in `interactableItemsTypes`
  public setItemAsInteractable(
    canvasItemId: string | undefined,
    type: FragmentType | ItemModel | undefined,
  ) {
    if (!type || !canvasItemId) {
      return;
    }
    if (this.isCanvasItemInteractable(type)) {
      // This assignment should be called before emitting to the subject
      // as it gets executed outside zone,
      // which might miss up the listeners that should be triggered after it
      this.sharedDataService.isDisableKeyEvents = true;
      this._interactableItemId$.next(canvasItemId);
    }
  }

  private disableInteractability() {
    this._interactableItemId$.next(this.NO_ITEM);
    // key events means toolbar shortcuts
    this.sharedDataService.isDisableKeyEvents = false;
  }

  private isCanvasItemInteractable(type: FragmentType | ItemModel) {
    return this.interactableItemsTypes.includes(type);
  }

  public interactionsEnabled$(canvasItem: CanvasItem) {
    return this.interactableItemId$.pipe(
      map((itemId) => canvasItem.id === itemId),
      distinctUntilChanged(),
    );
  }

  public triggerDeselectAll() {
    // This will trigger the selection:cleared fabric event which will trigger deselectAll method
    this.sharedDataService.fabricCanvas?.discardActiveObject().requestRenderAll();
  }

  private triggerSelectionWhenAppFullScreened() {
    this.sharedDataService.sessionView.current$
      .pipe(filter((sessionView) => sessionView.view === SessionView.FULLSCREEN_APP))
      .subscribe((sessionView) => {
        const fullScreenedAppCanvasItemId = sessionView.sessionViewMetaData?.fullscreenId;
        if (!fullScreenedAppCanvasItemId) {
          return;
        }
        const fullScreenedAppCanvasItem =
          this.sharedDataService.itemsCanvas?.canvasItems?.[fullScreenedAppCanvasItemId];
        if (!fullScreenedAppCanvasItem) {
          return;
        }
        const relatedFabricItem = fullScreenedAppCanvasItem.relatedFabricItem;
        if (!relatedFabricItem || !relatedFabricItem.selectable) {
          return;
        }
        this.sharedDataService.fabricCanvas?.discardActiveObject();
        this.sharedDataService.fabricCanvas?.setActiveObject(relatedFabricItem);
        this.sharedDataService.fabricCanvas?.requestRenderAll();
      });
  }

  private exitTextEditingModeWhenClickingOutsideFabric() {
    // un focus fabric text when clicking outside fabric a fix for SPAC-969
    this.domListener.add(
      document,
      'click',
      (event) => {
        if (!this.sharedDataService.fabricCanvas) {
          return;
        }
        if (
          event.target &&
          event.target !== (this.sharedDataService.fabricCanvas as any)?.upperCanvasEl
        ) {
          const activeText = this.sharedDataService.fabricCanvas.getActiveObject() as IText | null;
          if (activeText?.isEditing && activeText.exitEditing) {
            activeText.exitEditing();
          }
        }
      },
      true,
    );
  }

  private setUpFabricSelectionListeners() {
    if (!this.sharedDataService.fabricCanvas) {
      return;
    }
    // called when adding an item to the selection or when switching the selection from an object to another
    this.sharedDataService.fabricCanvas.on('selection:updated', this.boundFabricSelectionChange);
    // called when selecting item/items and there wasn't any active selection before
    this.sharedDataService.fabricCanvas.on('selection:created', this.boundFabricSelectionChange);
    // called when user clicks on an empty space in the canvas or when we call discardActiveObject
    this.sharedDataService.fabricCanvas.on('selection:cleared', this.boundFabricSelectionCleared);
    // called when receiving a remote change into fabric to remove the current selection if its content changed
    this.sharedDataService.fabricCanvas.on('remote:modified', this.boundRemoteModified);
  }

  private fabricSelectionChanged(event: IEvent): void {
    if (!this.sharedDataService.fabricCanvas) {
      return;
    }

    if (event.deselected?.length) {
      this.deselectAll(event);
    }

    const selectedObjects = event.selected;
    if (!selectedObjects?.length) {
      return;
    }

    this.sharedDataService.mainToolbar?.setVisible(true);
    this.reportSelectionStateToTelemetry(true);

    // The user created a multi-selection by adding an object to the previous selection, without discarding it
    if (this.isSelectionActive) {
      // We are sure that the user can only add one object at a time to the previous selection (Using CTRL + click)
      const newlySelectedObject = selectedObjects[0];

      // if either the previous object or the object added to the selection is prevented from multiSelection,
      // create a new selection that consists only of the last object the user clicked on
      if (
        this.isPreventedFromMultiSelection(newlySelectedObject) ||
        this.isPreventedFromMultiSelection(this.selectedObject)
      ) {
        this.replaceCurrentSelectionWithSelectionOf([newlySelectedObject]);
        return;
      }
    } else if (selectedObjects.length > 1) {
      // The user created a normal multi-selection using mouse drag

      const multiSelectableObjects = selectedObjects.filter(
        (object) => !this.isPreventedFromMultiSelection(object),
      );
      // selection contains an object that is not multi-selectable
      if (selectedObjects.length !== multiSelectableObjects.length) {
        this.replaceCurrentSelectionWithSelectionOf(multiSelectableObjects);
        return;
      }
    }

    this.handleObjectsSelected(selectedObjects);
  }

  private handleObjectsSelected(selectedObjects: fabric.Object[]) {
    if (selectedObjects.length === 0 || !this.sharedDataService.fabricCanvas) {
      return;
    }

    // Making the selected objects interactable
    for (const fabricObject of selectedObjects) {
      const canvasItemId = (fabricObject as any).itemId;
      const itemType = (fabricObject as any).itemType ?? (fabricObject as any).type;
      if (
        typeof itemType === 'string' &&
        typeof canvasItemId === 'string' &&
        fabricObject?.selectable
      ) {
        this.setItemAsInteractable(canvasItemId, itemType as FragmentType);
      }
    }

    // The selection consists only of one canvas item
    // TODO remove once we move the formulas menu to fabric-context-menu component
    if (
      !this.selectedCanvasItem && // To make sure that it is not a selection using CTRL + click, hence a multi-selection
      selectedObjects.length === 1 &&
      selectedObjects[0].selectable &&
      itemIsCanvasItem(selectedObjects[0])
    ) {
      this.sharedDataService.mainToolbar?.setVisible(true);
      this._selectedCanvasItem$.next(
        this.sharedDataService.itemsCanvas?.canvasItems?.[(selectedObjects[0] as any).itemId],
      );
    }

    this.modifySelectionPropertiesBasedOnItsContent();

    const currentlyActiveObject = this.sharedDataService.fabricCanvas.getActiveObject();
    if (currentlyActiveObject) {
      this._selection$.next({
        object: currentlyActiveObject,
        isMultiSelection: !!this.selectedObject || selectedObjects.length > 1,
        isCanvasItemSelected: itemIsCanvasItem(currentlyActiveObject),
      });
    }
  }

  private deselectAll(event?: IEvent): void {
    if (this.sharedDataService.sessionView.getSessionView() === SessionView.FULLSCREEN_APP) {
      return;
    }

    // This class is added when an object was moving to prevent hover events from reaching to any HTML element
    if (document.body.classList.contains('canvas-object-moving')) {
      document.body.classList.remove('canvas-object-moving');
    }

    this.removeDeselectedStickyNoteTexts(event);
    this.reportSelectionStateToTelemetry(false);
    this.disableInteractability();
    this.sharedDataService.fabricCanvas?.requestRenderAll();
    this._selectedCanvasItem$.next(undefined);
    this._selection$.next(undefined);
  }

  private triggerDeselectionOnRemoteObjectChanged(data: any) {
    const itemSelected: any = this.sharedDataService.fabricCanvas?.getActiveObject();
    if (!itemSelected) {
      return;
    }
    const modifiedObjects: any[] = data.objectsModified;
    for (const modifiedObject of modifiedObjects) {
      const selectedObjects = itemSelected._objects ? itemSelected._objects : [itemSelected];
      for (const selectedObject of selectedObjects) {
        if (
          modifiedObject &&
          selectedObject &&
          modifiedObject.uid === (selectedObject as any).uid
        ) {
          this.triggerDeselectAll();
          return;
        }
      }
    }
  }

  private replaceCurrentSelectionWithSelectionOf(objects: fabric.Object[]) {
    this.triggerDeselectAll();
    if (objects.length) {
      this.sharedDataService.fabricCanvas
        ?.setActiveObject(
          objects.length === 1
            ? objects[0]
            : new fabric.ActiveSelection(objects, {
                canvas: this.sharedDataService.fabricCanvas,
              }),
        )
        .requestRenderAll();
    }
  }

  private isPreventedFromMultiSelection(object?: fabric.Object) {
    if (!object) {
      return false;
    }
    const isObjectLocked =
      this.flagsService.isFlagEnabled(FLAGS.SPACES_OBJECT_LOCK) && object['locked'] === true;
    return (
      isObjectLocked ||
      (itemIsCanvasItem(object) &&
        this.objectsPreventedFromMultiSelection.includes(object.type as FragmentType))
    );
  }

  private removeDeselectedStickyNoteTexts(selectionEvent?: fabric.IEvent<Event>) {
    // Remove all sticky notes related text boxes
    selectionEvent?.deselected?.forEach((relatedText) => {
      if (itemIsStickyNoteRelatedText(relatedText) && this.sharedDataService?.fabricCanvas) {
        relatedText.remove && relatedText.remove(this.sharedDataService.fabricCanvas);
      }
    });
  }

  private modifySelectionPropertiesBasedOnItsContent(): void {
    if (!this.sharedDataService.fabricCanvas) {
      return;
    }
    const activeObjects = this.sharedDataService.fabricCanvas.getActiveObjects();
    if (activeObjects.length <= 1) {
      return;
    }
    const activeObject = this.sharedDataService.fabricCanvas.getActiveObject();
    if (!activeObject) {
      return;
    }

    // To prevent the objects from being deselected when clicking on the empty space in the selection
    activeObject.set('perPixelTargetFind', false);

    let selectedObjectsHaveControls = activeObject.hasControls;
    for (const object of activeObjects) {
      if (!object.hasControls) {
        selectedObjectsHaveControls = false;
      }

      // if the object has a locked property: lockRotation, lockScalingX..., the group should also lock these properties
      Object.keys(object)
        .filter((key) => key.startsWith('lock'))
        .forEach((key) => {
          if (object[key] === true) {
            activeObject[key] = true;
          }
        });
    }
    if (!selectedObjectsHaveControls) {
      activeObject.setControlsVisibility(
        fabricObjectsControlsNames.reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {}),
      );
    }
  }

  private reportSelectionStateToTelemetry(isSelectionActive: boolean): void {
    this.telemetryService.setSessionVars({ is_selecting: isSelectionActive });
  }

  public get selectedObject() {
    return this._selection$.getValue()?.object;
  }

  public get isSelectionActive() {
    return !!this._selection$.getValue();
  }

  public get selectedCanvasItem() {
    return this._selectedCanvasItem$.getValue();
  }
}

import { TypedFragment, FragmentType } from 'src/app/common/typed-fragment/typed-fragment';
import {
  Question,
  MultipleChoice,
  Metadata,
  SourceInfo,
  Fragment,
  ExplanationBuilder,
  defaultSourceInfo,
  Explanation,
} from 'src/app/models/question';
import { getNumberType } from '../settings/topics/topics.utils';
import { CollectionType, FragmentCollection, NUMBER_TYPES } from './create.model';
import { CreateComponent, QuestionEditModel } from './create.component';

export enum QuestionTypes {
  MCQ = 'multiple_choice_question',
  OEQ = 'open_ended_question',
  RCQ = 'comprehension',
  NOTE = 'note',
}

const RomanNums = [
  'i',
  'ii',
  'iii',
  'iv',
  'v',
  'vi',
  'vii',
  'viii',
  'ix',
  'x',
  'xi',
  'xii',
  'xiii',
  'xiv',
  'xv',
  'xvi',
  'xvii',
  'xviii',
];
const RomanUpperNums = [
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
  'XI',
  'XII',
  'XIII',
  'XIV',
  'XV',
  'XVI',
  'XVII',
  'XVIII',
];
const Numbers = ['1', 'A', 'a', 'i', ''];
export const ExtraTypeMap = {
  HINT: 'Hints',
  TRICKS: 'Speed tricks',
  MORE: 'Bonus Content',
  HOWTO: 'How to teach?',
  SOLUTION: 'Explanation',
  ANSWER: 'Answer',
  RUBRIC: 'Scoring rubric',
  NOTES: 'Notes',
};
export const Descriptions = {
  ANSWER: 'Model answer',
  SOLUTION: 'Explanation',
  HINT: 'Hint',
};

export function explanationTypeFromID(id: string): string {
  return `EXPL_${id}`;
}

export function getNewModelFragments(
  component: CreateComponent,
  model: QuestionEditModel,
): Map<string, FragmentCollection> | undefined {
  let fragmentData;
  let explanationMap;
  switch (model.question.type) {
    case QuestionTypes.MCQ:
      fragmentData = [
        [CollectionType.Screenshot, 'Question Screenshot'],
        [CollectionType.Question, 'Question'],
        [CollectionType.Option, 'Option A', 'A'],
        [CollectionType.Option, 'Option B', 'B'],
        [CollectionType.Option, 'Option C', 'C'],
        [CollectionType.Option, 'Option D', 'D'],
        [CollectionType.Tricks, 'Speed Tricks'],
        [CollectionType.More, 'Bonus Content'],
        [CollectionType.HowTo, 'How to teach?'],
      ];
      const optionIds = ['A', 'B', 'C', 'D'];
      explanationMap = new Map();
      for (const op of optionIds) {
        const coll = new FragmentCollection(
          component,
          CollectionType.Explanation,
          `Option ${op}`,
          op,
        );
        if (coll.fragments.length === 0) {
          coll.addTextFragment();
        }
        explanationMap.set(explanationTypeFromID(op), coll);
      }
      break;
    case QuestionTypes.OEQ:
      fragmentData = [
        [CollectionType.Screenshot, 'Question Screenshot'],
        [CollectionType.Question, 'Question'],
        [CollectionType.Tricks, 'Speed tricks'],
        [CollectionType.More, 'Bonus Content'],
        [CollectionType.HowTo, 'How to teach?'],
        [CollectionType.Rubric, 'Scoring rubric'],
        [CollectionType.Notes, 'Notes'],
      ];
      explanationMap = new Map();
      break;
    case QuestionTypes.RCQ:
      fragmentData = [
        [CollectionType.Screenshot, 'Question Screenshot'],
        [CollectionType.Notes, 'Title (optional)'],
        [CollectionType.Question, 'Passage'],
      ];
      break;
    case QuestionTypes.NOTE:
      fragmentData = [
        [CollectionType.Screenshot, 'Note Screenshot'],
        [CollectionType.Title, 'Title'],
        [CollectionType.Note, 'Note'],
        [CollectionType.Notes, 'Author notes'],
      ];
      break;
  }
  model.fragments = [];
  fragmentData!.forEach((f) => {
    const coll = new FragmentCollection(component, f[0], f[1], f[2]);
    if (coll.fragments.length === 0) {
      coll.addTextFragment();
    }
    model.fragments.push(coll);
  });
  return explanationMap;
}

export function getFragmentsFromNote(
  component: CreateComponent,
  question: any,
): FragmentCollection[] {
  const fragments: FragmentCollection[] = [];
  fragments.push(
    FragmentCollection.FromScreenshotFragments(component, question.screenshot_fragments),
  );
  fragments.push(FragmentCollection.FromTitle(component, question));
  fragments.push(FragmentCollection.FromNote(component, question));
  fragments.push(
    FragmentCollection.FromNotesFragments(component, question.authors_notes_fragments),
  );
  question.type = QuestionTypes.NOTE;
  return fragments;
}

export function getExplanationCollection(
  component: CreateComponent,
  type: string,
  questionId: string,
  fragments: Fragment[],
  explId = '',
): FragmentCollection {
  const coll = new FragmentCollection(component, type);
  coll.id = questionId;
  coll.explId = explId;
  for (const frag of fragments) {
    const typedFrag = TypedFragment.fromFragment(frag);
    coll.fragments.push(typedFrag);
  }
  if (coll.fragments.length === 0) {
    coll.addTextFragment();
  }
  return coll;
}

export function getFragmentsAndExplanationsFromQuestion(
  component: CreateComponent,
  question: Question,
): {
  fragments: FragmentCollection[];
  explanationMap: Map<string, FragmentCollection>;
} {
  const fragments: FragmentCollection[] = [];
  fragments.push(
    FragmentCollection.FromScreenshotFragments(component, question.screenshot_fragments),
  );
  if (question.type === QuestionTypes.RCQ) {
    fragments.push(
      FragmentCollection.FromNotesFragments(
        component,
        question.notes_fragments,
        'Title (optional)',
      ),
    );
  }
  const fragmentTitle = question.type === QuestionTypes.RCQ ? 'Passage' : 'Question';
  fragments.push(FragmentCollection.FromQuestion(component, question, fragmentTitle));
  if (question.type !== QuestionTypes.RCQ) {
    fragments.push(FragmentCollection.FromNotesFragments(component, question.notes_fragments));
  }
  if (question.answer?.choice_fragments?.length > 0) {
    fragments.push(FragmentCollection.FromAnswer(component, question));
  }

  const explanationMap = new Map();
  if (question.extra_set) {
    for (const expl of question.extra_set) {
      explanationMap.set(
        expl.type,
        FragmentCollection.FromExplanation(
          component,
          question,
          expl,
          Descriptions[expl.type as keyof typeof Descriptions],
        ),
      );
      if (expl.type === CollectionType.Hint || expl.type === CollectionType.Explanation) {
        fragments.push(explanationMap.get(expl.type));
      }
    }
  }
  if (question.sub_questions) {
    question.sub_questions.forEach((sub) => {
      if (sub.answer?.choice_fragments?.length > 0) {
        fragments.push(
          getExplanationCollection(
            component,
            CollectionType.Answer,
            sub._id,
            sub.answer?.choice_fragments,
          ),
        );
      }
      if (sub.extra_set) {
        sub.extra_set.forEach((ex: Explanation) => {
          if (ex.type === CollectionType.Hint || ex.type === CollectionType.Explanation) {
            fragments.push(
              getExplanationCollection(component, ex.type, sub._id, ex.remark, ex._id),
            );
          }
        });
      }
    });
  }
  for (const mc of question.multiple_choice) {
    fragments.push(FragmentCollection.FromOption(component, question, mc));
    if (explanationMap.get(explanationTypeFromID(mc.option_id))) {
      continue;
    }
    const coll = new FragmentCollection(
      component,
      CollectionType.Explanation,
      `Option ${mc.option_id}`,
      mc.option_id,
      mc,
    );
    if (coll.fragments.length === 0) {
      coll.addTextFragment();
    }
    explanationMap.set(explanationTypeFromID(mc.option_id), coll);
  }
  for (const type of component.extraTypes) {
    if (explanationMap.get(type)) {
      continue;
    }
    const coll = new FragmentCollection(
      component,
      type,
      ExtraTypeMap[type as keyof typeof ExtraTypeMap],
      Descriptions[type as keyof typeof Descriptions],
    );
    if (coll.fragments.length === 0) {
      coll.addTextFragment();
    }
    explanationMap.set(type, coll);
  }
  if (question.type === QuestionTypes.OEQ) {
    for (const frag of FragmentCollection.FromSubQuestion(component, question)) {
      fragments.push(frag);
    }
    fragments.push(explanationMap.get(CollectionType.Rubric));
    fragments.push(explanationMap.get(CollectionType.Notes));
  }
  if (question.type !== QuestionTypes.RCQ) {
    fragments.push(explanationMap.get(CollectionType.Tricks));
    fragments.push(explanationMap.get(CollectionType.More));
    fragments.push(explanationMap.get(CollectionType.HowTo));
  }

  // Fragments with numbers but no numberType will have to be
  // updated
  const fragmentsWithMissingNumberTypes = fragments.filter(
    (fragment) => !!fragment.number && !fragment.numberType,
  );
  if (fragmentsWithMissingNumberTypes.length) {
    const questionFragments = fragments.filter(
      (fragment) => fragment.type === CollectionType.Question && !!fragment.number,
    );

    // Check for number type at the start of a new level
    // Repeat the type for that level
    const levelsMap = new Map();
    questionFragments.forEach((fragment, index) => {
      if (index === 0) {
        const newType = getNumberType(fragment.number);
        fragment.numberType = newType;
        if (newType === NUMBER_TYPES.BLANK) {
          levelsMap.set(fragment.level, NUMBER_TYPES.ALPHABET_LOWERCASE);
        } else {
          levelsMap.set(fragment.level, newType);
        }
      } else {
        const prevFragment = questionFragments[index - 1];
        if (fragment.level > prevFragment.level) {
          const newType = getNumberType(fragment.number);
          fragment.numberType = newType;
          if (newType === NUMBER_TYPES.BLANK) {
            levelsMap.set(fragment.level, NUMBER_TYPES.ALPHABET_LOWERCASE);
          } else {
            levelsMap.set(fragment.level, newType);
          }
        } else if (fragment.level < prevFragment.level) {
          levelsMap.delete(prevFragment.level);
          fragment.numberType = levelsMap.get(fragment.level);
        } else {
          fragment.numberType = levelsMap.get(fragment.level);
        }
      }
    });
  }

  return {
    fragments,
    explanationMap,
  };
}

export function getNewQuestionModel(component: CreateComponent): QuestionEditModel {
  const getNewQuestion = () => {
    const baseQuestion = {
      courses: [component.selectedCourse!._id],
      metadata: new Metadata(),
      state: 'DRAFT',
      acl: {
        visibility: 'ME',
      },
    };

    if (component.pageType === 'questions') {
      return {
        ...baseQuestion,
        source_info: defaultSourceInfo(),
        multiple_choice: [],
        answer: new MultipleChoice(),
        type: QuestionTypes.MCQ,
      };
    } else {
      return {
        ...baseQuestion,
        source_info: {
          class: '',
          topics_covered: [],
        },
        title_fragments: [],
        note_fragments: [],
        authors_notes_fragments: [],
        type: 'note',
      };
    }
  };

  const coll = new FragmentCollection(component, CollectionType.Notes, 'Notes');
  if (coll.fragments.length === 0) {
    coll.addTextFragment();
  }
  return {
    selectedCourse: component.selectedCourse!,
    question: getNewQuestion() as never as Question, // TODO: Create a proper type for this...
    fragments: [],
    notes: coll,
    expanded: true,
  };
}

export function getNewExplanation(
  coll: FragmentCollection,
  subQuestionId: string,
): ExplanationBuilder {
  const type = coll.type?.toString() || '';
  const remark: Fragment[] = [];
  for (const fragment of coll.fragments) {
    if (fragment.fragment && fragment.fragment.data) {
      remark.push(fragment.fragment);
    }
  }
  const extraContent: ExplanationBuilder = {
    type,
    remark,
  };
  if (subQuestionId) {
    extraContent.sub_question_id = subQuestionId;
  }
  return extraContent;
}

export function updateQuestionFragment(
  question: any,
  fragmentName: string,
  collection: FragmentCollection,
): void {
  question[fragmentName] = [];
  for (const fragment of collection.fragments) {
    if (fragment.fragment?.data || fragment.fragment?.type === FragmentType.AnswerText) {
      question[fragmentName].push(fragment.fragment);
    }
  }
}

export function updateQuestionModel(component: CreateComponent, model: any, isRcq = false): void {
  if (!model) {
    return;
  }
  if (model.selectedCourse) {
    model.question.courses = [model.selectedCourse._id];
    if (model.question.source_info) {
      model.question.source_info.class = model.selectedCourse.name;
    }
  }
  model.question.multiple_choice = [];
  for (const collection of model.fragments) {
    if (collection?.type === CollectionType.Question && !collection['id']) {
      updateQuestionFragment(model.question, 'question_fragments', collection);
    } else if (
      collection?.type === CollectionType.Question &&
      collection['id'] &&
      collection.level &&
      isRcq
    ) {
      model.question.sub_questions.forEach((sub: any) => {
        if (sub._id === collection.id) {
          updateQuestionFragment(sub, 'question_fragments', collection);
        }
      });
    } else if (collection?.type === CollectionType.Option) {
      const mc = new MultipleChoice();
      mc.option_id = collection.option_data;
      updateQuestionFragment(mc, 'choice_fragments', collection);
      model.question.multiple_choice.push(mc);
    } else if (collection?.type === CollectionType.Screenshot) {
      updateQuestionFragment(model.question, 'screenshot_fragments', collection);
    } else if (collection?.type === CollectionType.Title && !component.lesson) {
      updateQuestionFragment(model.question, 'title_fragments', collection);
    } else if (collection?.type === CollectionType.Note) {
      updateQuestionFragment(model.question, 'note_fragments', collection);
    } else if (
      collection?.type === CollectionType.Notes &&
      component.selectedQuestionType === QuestionTypes.RCQ
    ) {
      updateQuestionFragment(model.question, 'notes_fragments', collection);
    }
  }
}

// Get OEQ sub question number
export function getNumber(number?: string, direction = 1, isRoman = true): string {
  if (!number) {
    return '';
  }
  if (parseInt(number, 10) >= 0) {
    return (parseInt(number, 10) + direction).toString();
  } else if (RomanNums.indexOf(number) > -1) {
    return RomanNums[RomanNums.indexOf(number) + direction];
  } else if (RomanUpperNums.indexOf(number) > -1 && isRoman) {
    return RomanUpperNums[RomanUpperNums.indexOf(number) + direction];
  } else if (
    (number.charCodeAt(0) >= 65 && number.charCodeAt(0) <= 90) ||
    (number.charCodeAt(0) >= 97 && number.charCodeAt(0) <= 122)
  ) {
    return String.fromCharCode(number.charCodeAt(0) + direction);
  }
  return '';
}

export function setPastExams(sourceInfo: SourceInfo, event: SourceInfo): void {
  sourceInfo = { ...event } as SourceInfo;
}

export function updateSubQuestionFragment(
  component: CreateComponent,
  subQuestion: { sub_questionId: string } | undefined,
  model: QuestionEditModel,
  number: string | undefined = undefined,
): FragmentCollection {
  const collection = new FragmentCollection(component, CollectionType.Question, 'Question', '');
  collection['level'] = 1;
  collection['id'] = subQuestion?.sub_questionId;
  collection['number'] = number ? getNumber(number) : '1';
  if (collection.fragments.length === 0) {
    collection.addTextFragment();
  }
  model.question['number'] = collection['number'];
  model.question['level'] = 1;
  model.fragments.push(collection);
  return collection;
}

export function getFirstNumber(number: string): string {
  if (Numbers.includes(number)) {
    return number;
  }
  return getFirstNumber(getNumber(number, -1));
}

export function reorderFragments(fragments: FragmentCollection[]): void {
  const levels = [0];
  const numbers: string[] = [];
  fragments.forEach((f) => {
    if (f.type === 'QUESTION' && f['number']) {
      if (f['level'] > levels[levels.length - 1]) {
        numbers.push(getFirstNumber(f['number']));
        levels.push(f['level']);
      } else if (f['level'] < levels[levels.length - 1]) {
        levels.pop();
        numbers.pop();
        levels.push(f['level']);
        if (numbers.length === 0) {
          numbers.push(getFirstNumber(f['number']));
        } else {
          numbers[numbers.length - 1] = getNumber(numbers[numbers.length - 1]);
        }
      } else {
        numbers[numbers.length - 1] = getNumber(numbers[numbers.length - 1]);
      }
      f['number'] = numbers[numbers.length - 1];
    }
  });
}

export function getRcqSelectedQuestion(component: CreateComponent): Question | null {
  let question: Question | null = null;
  if (component?.model?.question) {
    component.model.question.sub_questions.forEach((sub) => {
      if (sub._id === component.selectedRcqQuestionId) {
        question = sub;
      }
      if (sub.type === QuestionTypes.OEQ && !question) {
        question = sub.sub_questions?.find(
          (subSub: any) => subSub._id === component.selectedRcqQuestionId,
        );
      }
    });
  }
  return question;
}

export function updateQuestionMetaData(
  component: CreateComponent,
  collection: FragmentCollection | null,
  modelIndex: number,
  question: Question,
): void {
  if (collection && component?.model?.question) {
    component.model.question.sub_questions?.forEach((sub, i) => {
      if (collection.id) {
        sub?.sub_questions?.forEach((subSub: any) => {
          if (collection.type === CollectionType.Answer && subSub._id === collection.id) {
            if (!subSub?.answer) {
              subSub['answer'] = {
                choice_fragments: [],
              };
            }
            updateQuestionFragment(subSub.answer, 'choice_fragments', collection);
          } else if (collection.type === CollectionType.Question && subSub._id === collection.id) {
            subSub['metadata'] = {
              ...subSub['metadata'],
              answerFormat: collection.answerFormat,
              lines: collection.lines,
              marks: collection.marks,
            };
          }
        });
      } else {
        if (collection.type === CollectionType.Answer && modelIndex === i) {
          if (!sub?.answer) {
            sub['answer'] = {
              choice_fragments: [],
            };
          }
          updateQuestionFragment(sub.answer, 'choice_fragments', collection);
        } else if (collection.type === CollectionType.Question && modelIndex === i) {
          sub['metadata'] = {
            ...sub['metadata'],
            answerFormat: collection.answerFormat,
            lines: collection.lines,
            marks: collection.marks,
          };
        }
      }
    });
  }
  if (collection?.id && collection?.level) {
    question?.sub_questions.forEach((sub) => {
      if (sub._id === collection.id) {
        sub.level = collection.level;
        sub.number = collection.number;
      }
    });
  }
}

export function getIconByKey(
  key: CollectionType | string,
  icons: { [key: CollectionType | string]: string },
): string | undefined {
  if (key in icons) {
    return icons[key as keyof typeof icons];
  }
  return undefined;
}

<div class="parent">
  <div class="title">
    <span class="txt"> {{ 'Save recording' | translate }} </span>
    <mat-icon class="icon" (click)="dismiss()"> close </mat-icon>
  </div>
  <div class="text-center mx-5 cmt-20" *ngIf="data.reason">
    <span class="txt">
      {{ data.reason }}
    </span>
  </div>
  <div class="loading-placeholder" [hidden]="!isLoadingMetadata">
    <app-pencil-spinner [diameter]="32"> </app-pencil-spinner>
  </div>
  <div
    style="display: flex; justify-content: center; margin-top: 20px; height: 200px"
    [hidden]="isLoadingMetadata"
  >
    <video style="border-radius: 8px" width="90%" #previewRecording></video>
  </div>
  <div class="choices">
    <div class="save-type">
      <mat-icon class="icon"> folder_open </mat-icon>
      <span class="txt"> {{ 'Save to...' | translate }} </span>
    </div>
    <mat-radio-group class="radio-group" [(ngModel)]="savingType">
      <mat-radio-button class="radio-button" [value]="VideoDestionation.LOCAL">
        <span class="txt" [class.selected]="savingType === VideoDestionation.LOCAL">
          {{ 'My computer' | translate }}
        </span>
      </mat-radio-button>
      <mat-radio-button *ngIf="!isGuestUser" class="radio-button" [value]="VideoDestionation.CLOUD">
        <div class="cloud">
          <span class="txt" [class.selected]="savingType === VideoDestionation.CLOUD">
            {{ 'Pencil Files' | translate }}
          </span>
        </div>
      </mat-radio-button>
      <mat-radio-button
        *ngIf="youtubeUpload"
        class="radio-button"
        [value]="VideoDestionation.YOUTUBE"
      >
        <div class="cloud">
          <span class="txt" [class.selected]="savingType === VideoDestionation.YOUTUBE">
            {{ 'YouTube' | translate }}
          </span>
        </div>
      </mat-radio-button>
    </mat-radio-group>
    <div
      style="display: flex; flex-direction: row; margin-left: 4px"
      *ngIf="savingType === VideoDestionation.CLOUD || savingType === VideoDestionation.YOUTUBE"
    >
      <mat-checkbox [(ngModel)]="saveLocalBackup">
        <span style="margin-left: 8px">
          {{ 'Save a copy to my computer' | translate }}
        </span>
      </mat-checkbox>
    </div>
  </div>
  <div class="bottom-buttons">
    <button class="save" (click)="dismiss()">
      <mat-icon>close</mat-icon>
      <span>{{ 'Cancel' | translate }}</span>
    </button>
    <button
      [disabled]="isLoadingMetadata"
      style="color: #fff"
      class="btn btn-primary"
      (click)="confirm()"
    >
      <mat-icon>check</mat-icon>
      <span>{{ 'Save' | translate }}</span>
    </button>
  </div>
</div>

import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TelemetryService } from 'src/app/services/telemetry.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  Observable,
  pairwise,
  startWith,
  Subscription,
} from 'rxjs';
import { distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { ERRORS, INFOS, SUCCESSES } from 'src/app/common/utils/notification-constants';
import { ModalManagerService } from 'src/app/services/modal-manager.service';
import {
  NotificationData,
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from 'src/app/services/notification-toaster.service';
import { PresenceProvider } from 'src/app/services/presence-provider';
import { SessionCallParticipantsService } from 'src/app/services/session-call-participants.service';
import { UserService } from 'src/app/services/user.service';
import { VideoAIService } from 'src/app/services/video-ai.service';
import {
  ButtonToasterElement,
  ButtonToasterElementStyle,
} from 'src/app/ui/notification-toaster/button-toaster-element/button-toaster-element.component';
import { ToasterPopupStyle } from 'src/app/ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { IconMessageToasterElement } from 'src/app/ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { environment } from 'src/environments/environment';
import { ProviderStateService } from 'src/app/services/provider-state.service';
import { v4 as uuidv4 } from 'uuid';
import { ISession, ItemModel, Session } from 'src/app/models/session';
import { SpaceRepository } from 'src/app/state/space.repository';
import { LocalDirection, UiService } from 'src/app/services/ui.service';
import {
  SessionSharedDataService,
  SessionView,
} from 'src/app/services/session-shared-data.service';
import { RealtimeSpaceService } from 'src/app/services/realtime-space.service';
import { VideoCallTracksStateService } from 'src/app/services/video-call-tracks-state.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SpacePermissionsManagerService } from 'src/app/services/space-permissions-manager.service';
import { WbDialogService } from 'src/app/services/wb-dialog.service';
import {
  RESIZABLE_RIGHT_VIEW_MIN_WIDTH,
  RIGHT_VIEW_MAX_NUM_TILES_PER_PAGE,
  setTileParameters,
  TilePositionParameters,
  TilesContainerParameters,
} from 'src/app/common/utils/tiles-packing-util';
import { clone, isEqual } from 'lodash';
import { AudioChimes, AudioService } from 'src/app/services/audio.service';
import { DomListenerFactoryService } from 'src/app/services/dom-listener-factory.service';
import { LocalTracksManagerService } from 'src/app/services/local-tracks-manager.service';
import { DeviceType } from 'src/app/common/utils/devices-handle-util';
import { matchI } from 'ts-adt';
import { CelebrationsService } from 'src/app/services/celebrations.service';
import { NavService } from 'src/app/services/nav.service';
import { ForegroundActivityService } from 'src/app/services/foreground-activity.service';
import { modifiedDebounceTime, modifiedSetTimeout } from 'src/app/utilities/ZoneUtils';
import { DeviceAndBrowserDetectorService } from 'src/app/services/device-and-browser-detector.service';
import { TemporaryUserMetadataRepositoryService } from 'src/app/state/temporary-user-metadata-repository.service';
import { filterNil } from '@ngneat/elf';
import { HideSelfViewState, RtcServiceController } from '../../../services/rtc.service';
import {
  CallParticipant,
  ParticipantEvent,
  ParticipantEventAction,
  SubscribeCallQuality,
  TrackType,
} from '../../../common/interfaces/rtc-interface';
import { NetworkQuality } from '../../../models/network';
import { FlagsService } from '../../../services/flags.service';
import { KonvaService, PopoutLayout } from '../../../services/konva.service';
import { VideoTrackType, VideoTypes } from '../../../services/session-call-tracks.service';
import { CurrentlyDetectingAudio } from '../../common/volume-detector';
import { User } from '../../../models/user';
import { DevicesManagerService } from '../../../services/devices-manager.service';
import { UserTile } from '../../common/user-tile';
import { EventCategory, SessionEvent } from '../SessionEvent';
import { SpaceLeaderModeService } from '../../../services/space-leader-mode.service';
import { PanelView } from '../../panel/panel.component';
import { Section } from '../../panel/participants-manager/participants-manager.component';
import { SpaceRecordingService } from '../../../services/space-recording.service';
import { FileDetails } from '../../../content/upload-task/upload-task.component';
import { ResourceItemModel } from '../../../models/resource';
import { SpacesService } from '../../../services/spaces.service';
import { ResourcesService } from '../../../services/resources.service';
import { RecordingOutput } from '../../../models/recording';
import { WbRecorderComponent } from '../wb-recorder/wb-recorder.component';
import { ForceScreenShareBlockingModalService } from '../services/force-screen-share-blocking-modal.service';
import { SpaceScreenshareService } from '../../../services/space-screenshare.service';
import { VimeoUploadService } from '../../../services/vimeo-upload.service';
import {
  UploadFailedNotificationStatus,
  UploadFileService,
} from '../../../services/upload-file.service';

export enum VideoLayout {
  TOP,
  RIGHT,
  MINIMIZED,
  POPOUT, // This is the pop out state in the parent window
  FLOATING, // This is the pop out state in the floating window
}

export enum PopDirection {
  OUT,
  IN,
}

interface ButtonPosition {
  top: number;
  left: number;
}

@UntilDestroy()
@Component({
  selector: 'app-wb-video-call-participant-id',
  templateUrl: './wb-video-call-participant-id.component.html',
  styleUrls: ['./wb-video-call-participant-id.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({ opacity: '0' })),
      state('closed', style({ opacity: '1' })),
      transition('closed => open', [animate('0ms')]),
      transition('open => closed', [animate('100ms')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WbVideoCallParticipantIdComponent implements OnInit, OnDestroy, OnChanges {
  @Input() users: User[] = [];

  readonly user?: User | null;
  readonly sessionView = SessionView;
  readonly SessionView = SessionView;
  readonly TrackType = TrackType;
  readonly SubscribeCallQuality = SubscribeCallQuality;
  readonly VideoTypes = VideoTypes;
  readonly VideoLayout = VideoLayout;
  readonly TIMEOUT_PERIOD_DETECT_PAGE_NOT_ACTIVE = 60000;
  readonly LocalDirection = LocalDirection;
  readonly isMobileDevice = this.deviceAndBrowserDetectorService.isMobile();

  // this is the needed width to make the video controls appear correctly
  // otherwise we need to scale the tower
  readonly rightViewControllerMinNeededWidth = 150.0;
  // this is the needed width to make the tile appear correctly
  // otherwise we need to scale the tile controls inside it
  readonly rightViewMinTileNeededWidth = 120.0;

  private callConnected = false;
  private participantLookup: { [key: string]: UserTile[] } = {}; // map of user ids to rtc participant
  private userLookup: { [key: string]: User } = {}; // Map of the user ids to pencil user object
  // Specify how many tiles per page.
  private numberOfUserTilesPerPage = 4;
  private rtcConnected: boolean | undefined = undefined;
  private presentUsersUIDs: Set<string> = new Set(); // holds the ids of the present pencil users from firebase
  private spaceId = '';
  private domListener = this.domListenerFactoryService.createInstance();
  private showOtherParticipantsEmotes = true;
  private lastVideoLayoutPosition: VideoLayout;
  private disableVideoStreams = false;
  private isDisableKeyEvents = false;
  private deviceChangeListener: EventListener | null = null;
  private currentLayout = VideoLayout.RIGHT;
  // Used for listening the page is active or not
  private unsubcribeAllVideoFeedsTimeout?: any;
  private pageNotActiveTimoutIsExceed = false;

  private currentActiveScreenSharing: { [participantId: string]: CallParticipant } = {};
  private currentTilesContainerParameters?: TilesContainerParameters;
  private emotesExpiryTimerMap: Map<string, NodeJS.Timer> = new Map();
  private containerResizeObserver?: ResizeObserver;
  private rightSessionsPanelResizeObserver?: ResizeObserver;
  // Used to keep tracking of joined events that its participants don't have an entry inside populated users
  private unhandledJoinedEvents: { [participantId: string]: ParticipantEvent } = {};

  private maxNumOfTilesPerPageRightView = RIGHT_VIEW_MAX_NUM_TILES_PER_PAGE;
  private currentBreakoutRoomId?: string;
  private presenceSubscription?: Subscription;
  private spaceMetadataSubscription?: Subscription;

  videoControlsBottom$ = combineLatest([
    this.sessionSharedDataService.topControlsHeight$,
    this.sessionSharedDataService.sessionView.current$.pipe(
      map((sessionView) =>
        [SessionView.EXPANDED, SessionView.GALLERY_VIEW].includes(sessionView.view),
      ),
    ),
  ]).pipe(map(([height, adjustHeight]) => (adjustHeight ? height : 0)));
  private _screenShareTileToBePinedByNotification?: UserTile;

  isMobileView$ = this.uiService.isMobile.asObservable();
  currentSessionView$ = new BehaviorSubject(SessionView.WHITEBOARD);
  localParticipantId$ = new BehaviorSubject('');
  numberOfPresentParticipants$ = new BehaviorSubject(0);
  expandedScreenUser$ = new BehaviorSubject<UserTile | undefined>(undefined);
  // The total number of pages of video tiles we have
  numberOfUserTilesPages$ = new BehaviorSubject(1);
  // Holds the index of the current page of user tiles
  currentUserTilesPageIndex$ = new BehaviorSubject(0);
  isLeaderModeActive$ = new BehaviorSubject(false);
  speakingUser$ = new BehaviorSubject<User | undefined>(undefined);
  startAnimation$ = new BehaviorSubject(false);
  navigationLeftButtonPosition$ = new BehaviorSubject<ButtonPosition | undefined>(undefined);
  navigationRightButtonPosition$ = new BehaviorSubject<ButtonPosition | undefined>(undefined);
  rightViewHeight$ = new BehaviorSubject(0);
  rightViewWidth$ = new BehaviorSubject(0);
  directionForLocale$ = new BehaviorSubject<LocalDirection>(LocalDirection.LTR);
  hideSelfViewActive$ = new BehaviorSubject(false);
  resizedRightViewMinWidth$ = new BehaviorSubject(RESIZABLE_RIGHT_VIEW_MIN_WIDTH);
  participantsArray$ = new BehaviorSubject<readonly UserTile[]>([]);

  // these two sets updated using events so it is safe to not being subjects
  participantsSpeaking: Set<string> = new Set(); // holds the ids of the participants who are speaking
  screenSharingHasAudio: Set<string> = new Set();

  constructor(
    public sessionSharedDataService: SessionSharedDataService,
    private realtimeSpaceService: RealtimeSpaceService,
    private userService: UserService,
    public rtcServiceController: RtcServiceController,
    public uiService: UiService,
    private modalManagerService: ModalManagerService,
    private notificationToasterService: NotificationToasterService,
    private translateService: TranslateService,
    public devicesManagerService: DevicesManagerService,
    private sessionCallParticipantsService: SessionCallParticipantsService,
    protected flagsService: FlagsService,
    private videoAiService: VideoAIService,
    private providerState: ProviderStateService,
    private spacePermissionsManagerService: SpacePermissionsManagerService,
    private konvaService: KonvaService,
    private presenceProvider: PresenceProvider,
    public spaceRepo: SpaceRepository,
    private videoCallTracksStateService: VideoCallTracksStateService,
    private deviceDetectorService: DeviceDetectorService,
    private spaceLeaderModeService: SpaceLeaderModeService,
    private wbDialogService: WbDialogService,
    private audioService: AudioService,
    private domListenerFactoryService: DomListenerFactoryService,
    private telemetry: TelemetryService,
    private localTracksManager: LocalTracksManagerService,
    private spaceRecordingService: SpaceRecordingService,
    private spacesService: SpacesService,
    private resourcesService: ResourcesService,
    public navService: NavService,
    private celebrationsService: CelebrationsService,
    private foregroundActivityService: ForegroundActivityService,
    private forceScreenShareBlockingModalService: ForceScreenShareBlockingModalService,
    private zone: NgZone,
    private deviceAndBrowserDetectorService: DeviceAndBrowserDetectorService,
    private temporaryUserMetadataRepositoryService: TemporaryUserMetadataRepositoryService,
    private spaceScreenshareService: SpaceScreenshareService,
    private vimeoUploadService: VimeoUploadService,
    private uploadService: UploadFileService,
  ) {
    this.user = this.userService.user.getValue()!.user;
    this.userService.rtl.pipe(untilDestroyed(this)).subscribe((isRtl) => {
      this.directionForLocale$.next(isRtl ? LocalDirection.RTL : LocalDirection.LTR);
    });

    this.subscribeToResizableRightViewParams();
    this.subscribeToDisableVideoStreamChanged();

    // Default value
    this.lastVideoLayoutPosition = !this.isMobileView() ? VideoLayout.RIGHT : VideoLayout.TOP;

    this.isMobileView$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(() => {
      this.updateVideoLayoutPosition();
    });

    this.listenToEscapeEventToCloseFullScreen();

    this.subscribeToOpenGalleryView();

    this.sessionSharedDataService.showOthersEmotes.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value !== undefined) {
        this.showOtherParticipantsEmotes = value;
      }
    });

    this.sessionSharedDataService.startVideoAnimation.pipe(untilDestroyed(this)).subscribe(() => {
      this.startAnimation$.next(true);
    });

    this.sessionSharedDataService.isDisableKeyEvents$
      .pipe(untilDestroyed(this))
      .subscribe((val) => (this.isDisableKeyEvents = val));

    this.detectPageIsActive();

    // Reset on stop video call RTC
    this.rtcServiceController.resetOnStopVideoCallRTC
      .pipe(untilDestroyed(this))
      .subscribe((reset) => {
        if (reset) {
          this.returnToWhiteboard();
        }
      });

    // ---- Recording Upload ---- //
    this.spaceRecordingService.recordingOutput$
      .pipe(
        switchMap(this.displayFinishedRecordingModal.bind(this)),
        filterNil(),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe((file) => {
        this.uploadRecordingToVimeo(file);
      });

    this.handleNotificationsVerticalPosition();
    this.handleBlockingModelWhenNoScreenShare();
  }

  private handleBlockingModelWhenNoScreenShare() {
    if (!this.forceScreenShareBlockingModalService.featureEnabled()) {
      return;
    }
    // start subscribing after cam/mic states are ready to make sure that device error helper will be shown firstly
    // as the modals will be shown on a tap operation
    this.localTracksManager.firstDevicesStateAfterJoiningCall
      .pipe(
        switchMap(() =>
          combineLatest([
            this.spaceScreenshareService.numberOfScreenshares$.pipe(map((cnt) => cnt > 0)),
            this.spaceRecordingService.cloudRecordingActive$,
            this.spaceRepo.isCurrentUserHost$,
            this.spaceScreenshareService.localScreenShareMode$,
          ]),
        ),
      )
      .pipe(untilDestroyed(this), modifiedDebounceTime(500), distinctUntilChanged(isEqual))
      .subscribe(
        ([callHasActiveScreenTiles, CloudRecordingActive, isHost, localScreenShareMode]) => {
          this.forceScreenShareBlockingModalService.stateChanged({
            callHasActiveScreenTiles: callHasActiveScreenTiles,
            cloudRecordingActive: CloudRecordingActive,
            isUserHost: isHost,
            localScreenShareMode: localScreenShareMode,
          });
        },
      );
  }

  private handleNotificationsVerticalPosition() {
    combineLatest([
      this.isMobileView$,
      this.providerState.callConnected$,
      this.sessionSharedDataService.changeLeftPanelView.pipe(startWith(null)),
      this.sessionSharedDataService.controlsLayout,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([isMobileView, callConnected, panel, controlsLayout]) => {
        if (isMobileView && callConnected) {
          const root = document.documentElement;
          // 8px padding
          root.style.setProperty(
            '--toaster-vertical-position',
            `calc(${
              controlsLayout === VideoLayout.MINIMIZED
                ? 'var(--mobile-speaking-indicator-height)'
                : 'var(--mobile-video-box-height)'
            } + var(--mobile-video-controls-box-height) + 8px + ${
              panel ? 'var(--mobile-header-height)' : '0px'
            })`,
          );
        } else {
          const root = document.documentElement;
          root.style.removeProperty('--toaster-vertical-position');
        }
      });
  }

  private subscribeToDisableVideoStreamChanged() {
    this.sessionSharedDataService.disableVideoStreamChanged
      .pipe(map((disableVideoStreamChanged) => disableVideoStreamChanged.disabled))
      .pipe(startWith(null), pairwise(), untilDestroyed(this))
      .subscribe(([prevVal, newVal]) => {
        if (prevVal !== newVal && newVal !== null) {
          this.disableVideoStreams = newVal;
          if (this.disableVideoStreams) {
            this.disableIncomingVideoStreams();
          } else {
            this.enableIncomingVideoStreams();
          }
        }
      });
  }

  private subscribeToResizableRightViewParams() {
    this.spaceRepo.isCurrentUserHost$.pipe(untilDestroyed(this)).subscribe((isHost) => {
      // update VCT minimum width
      const dynamicRightWiewFlagVariables =
        this.flagsService.featureFlagsVariables.dynamic_right_view;
      const promotedRightViewWidth =
        (dynamicRightWiewFlagVariables?.resized_right_view_min_width as number) ||
        RESIZABLE_RIGHT_VIEW_MIN_WIDTH;
      this.resizedRightViewMinWidth$.next(promotedRightViewWidth);

      if (isHost) {
        // update maximum VCT tiles per page for hosts
        const promotedMaxNumOfTilesRightViewPerPage =
          dynamicRightWiewFlagVariables?.host_max_page_tiles;
        if (promotedMaxNumOfTilesRightViewPerPage) {
          this.maxNumOfTilesPerPageRightView = promotedMaxNumOfTilesRightViewPerPage as number;
        }
      } else {
        // update maximum VCT tiles per page for non hosts
        const promotedMaxNumOfTilesRightViewPerPage =
          dynamicRightWiewFlagVariables?.participant_max_page_tiles;
        if (promotedMaxNumOfTilesRightViewPerPage) {
          this.maxNumOfTilesPerPageRightView = promotedMaxNumOfTilesRightViewPerPage as number;
        }
      }
    });
  }

  private listenToEscapeEventToCloseFullScreen() {
    this.domListener.add(
      window,
      'keydown',
      (event: KeyboardEvent) => {
        if (this.isDisableKeyEvents) {
          return;
        }
        if (
          this.expandedScreenUser$.getValue() &&
          this.currentSessionView$.getValue() === SessionView.FULLSCREEN &&
          event.key === 'Escape' &&
          !this.isLeaderModeActive$.getValue()
        ) {
          this.handleFullscreen(this.expandedScreenUser$.getValue(), true);
        }
      },
      true,
    );
  }

  private subscribeToOpenGalleryView() {
    this.sessionSharedDataService.openMobileGalleryView
      .pipe(untilDestroyed(this))
      .subscribe((showGalleryView) => {
        if (showGalleryView) {
          this.openGalleryView();
        }
      });
  }

  private detectPageIsActive() {
    this.foregroundActivityService.isForegroundInactive$
      .pipe(untilDestroyed(this))
      .subscribe((isInActive) => {
        this.handlePageActivityChange(isInActive);
      });
  }

  private handlePageActivityChange(isInActive: boolean) {
    if (!this.callConnected || this.konvaService.isPopoutVideoActive) {
      return;
    }
    if (isInActive) {
      this.unsubcribeAllVideoFeedsTimeout = modifiedSetTimeout(() => {
        this.pageNotActiveTimoutIsExceed = true;
        this.unsubscribeAllVideos();
      }, this.TIMEOUT_PERIOD_DETECT_PAGE_NOT_ACTIVE);
    } else {
      if (this.unsubcribeAllVideoFeedsTimeout) {
        clearTimeout(this.unsubcribeAllVideoFeedsTimeout);
        this.unsubcribeAllVideoFeedsTimeout = undefined;
        if (this.pageNotActiveTimoutIsExceed) {
          this.zone.run(() => {
            this.pageNotActiveTimoutIsExceed = false;
            this.resetVideosSubscribtionState();
          });
        }
      }
    }
  }

  // After resize
  private updateVideoLayoutPosition() {
    if (this.sessionSharedDataService.controlsLayout.value != VideoLayout.TOP) {
      // this is the last layout before moving to mobile view
      this.lastVideoLayoutPosition = this.sessionSharedDataService.controlsLayout.value;
    }
    this.sessionSharedDataService.controlsLayout.next(
      !this.isMobileView() ? this.handleSwitchingToLastVideoLayoutPosition() : VideoLayout.TOP,
    );
  }

  // Used to handle the case of entering the space with mobile view width range, then moving to the normal desktop view width range
  private handleSwitchingToLastVideoLayoutPosition(): VideoLayout {
    if (this.lastVideoLayoutPosition === VideoLayout.TOP) {
      return VideoLayout.RIGHT;
    }
    return this.lastVideoLayoutPosition;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.users) {
      this.setUserLookup();
      // If there is a change for populated users & there are unhandled joined events (Due to no entry inside populated users),
      // We will loop through all unhandled joined events to add them as the change (Populated users) may contain an entry for it
      this.addAllUnhandledJoinedEvents();
      this.updateParticipantsArray();
    }
  }

  setUserLookup(): void {
    this.userLookup = this.users?.reduce(
      (dict = {}, currUser: User) => ({ ...dict, [currUser._id]: currUser }),
      {},
    );
  }

  private addAllUnhandledJoinedEvents() {
    if (Object.keys(this.unhandledJoinedEvents).length) {
      for (const unhandledJoinedParticipantEvent of Object.values(this.unhandledJoinedEvents)) {
        this.handleParticipantJoined(unhandledJoinedParticipantEvent);
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.realtimeSpaceService.service.subscribeToEvent(this, EventCategory.Users);

    this.sessionSharedDataService.sessionView.current$
      .pipe(pairwise(), untilDestroyed(this))
      .subscribe(([previousSessionView, currentSessionView]) => {
        this.currentSessionView$.next(currentSessionView.view);
        this.currentUserTilesPageIndex$.next(0);
        // Force updating tiles positions
        // 1) if we are still on expanded view as it won't be triggered by observer directive
        // 2) if we are on fullscreen view and the id changed
        if (
          (previousSessionView.view === SessionView.EXPANDED &&
            this.currentSessionView$.getValue() === SessionView.EXPANDED) ||
          this.currentSessionView$.getValue() === SessionView.FULLSCREEN_APP ||
          (currentSessionView.view === SessionView.FULLSCREEN &&
            previousSessionView.view === currentSessionView.view &&
            previousSessionView.expandedFullScreenChange?.id !==
              currentSessionView.expandedFullScreenChange?.id)
        ) {
          this.updateTilesPosition();
        }
        // In case of returning back to whiteboard view and the current controls layout is minimized, no need for update current user tiles
        // Just unsubscribe from the previous video streams (Gallery/expanded view)
        if (
          this.currentSessionView$.getValue() === SessionView.WHITEBOARD &&
          this.sessionSharedDataService.controlsLayout.getValue() === VideoLayout.MINIMIZED &&
          !this.konvaService.isPopoutVideoActive
        ) {
          this.unsubscribeAllVideos();
        }
      });

    combineLatest([this.spaceRepo.activeSpace$, this.spaceRepo.activeSpaceCurrentRoomUid$])
      .pipe(untilDestroyed(this))
      .subscribe(([activeSpace, activeSpaceCurrentRoomUid]) => {
        if (!activeSpace) {
          return;
        }

        if (!activeSpaceCurrentRoomUid) {
          return;
        }

        if (
          activeSpace._id !== this.spaceId ||
          activeSpaceCurrentRoomUid !== this.currentBreakoutRoomId
        ) {
          this.handleSpaceOrBreakoutRoomChange(activeSpace, activeSpaceCurrentRoomUid);
        }
      });

    this.sessionSharedDataService.controlsLayout.pipe(untilDestroyed(this)).subscribe((layout) => {
      this.currentLayout = layout;
      if (
        (layout === VideoLayout.RIGHT || layout === VideoLayout.TOP) &&
        this.rtcServiceController.service.isConnected()
      ) {
        this.updateTilesPosition();
      }
      // Optimize the performence by unsubcribing from all video streams once entering minimized view
      if (layout === VideoLayout.MINIMIZED && !this.konvaService.isPopoutVideoActive) {
        this.unsubscribeAllVideos();
      }
    });

    this.providerState.callConnected$.pipe(untilDestroyed(this)).subscribe((connected) => {
      this.handleRTCConnectionChange(connected);
      if (connected && !this.deviceChangeListener) {
        this.detectNoMic();
      }
      this.resetSessionViewAfterCallDisconnect(connected);

      this.callConnected = connected;
      this.setupVideoPanelSizeObservable();
      this.setupRightSessionsPanelSizeObservable();
    });

    this.providerState.localParticipantId$
      .pipe(untilDestroyed(this))
      .subscribe((localParticipantId) => {
        if (
          localParticipantId.length !== 0 &&
          this.localParticipantId$.getValue() !== localParticipantId
        ) {
          this.localParticipantId$.next(localParticipantId);
        }
      });

    this.sessionCallParticipantsService.participantEvents$
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        if (event && event.participant) {
          switch (event.action) {
            case ParticipantEventAction.JOINED:
              // Close join call panel, and A/V settings once the local user is joined if exist
              this.handleClosingAllActiveJoinCallRelatedModals(event.participant.local);
              this.handleParticipantJoined(event);
              break;
            case ParticipantEventAction.UPDATED: // Used to update the expanded user if exist
              this.handleParticipantUpdated(event);
              break;
            case ParticipantEventAction.LEFT:
              this.handleParticipantLeft(event);
          }
          this.handleParticipantChange(event.participant);
          if (this.isMobileView()) {
            this.updateNumberOfTiles();
          }
        }
      });
    this.spaceLeaderModeService.currentUserIsBeingLed$
      .pipe(untilDestroyed(this))
      .subscribe((currentUserIsBeingLed) => {
        this.isLeaderModeActive$.next(currentUserIsBeingLed);
        this.resetSessionViewAfterLeaderModeIsDisabled(currentUserIsBeingLed);
      });

    this.sessionSharedDataService.sessionView.current$
      .pipe(untilDestroyed(this))
      .subscribe((sessionViewState) => {
        if (
          this.konvaService.isPopoutVideoActive &&
          [SessionView.GALLERY_VIEW, SessionView.EXPANDED, SessionView.FULLSCREEN].includes(
            sessionViewState.view,
          )
        ) {
          this.konvaService.closePopoutVideo(false);
        }

        if (
          this.currentSessionView$.getValue() === SessionView.GALLERY_VIEW &&
          this.isMobileView()
        ) {
          this.returnToWhiteboard();
          return;
        }

        const expandedFullScreenChange = sessionViewState.expandedFullScreenChange;
        if (!expandedFullScreenChange) {
          if (
            [SessionView.EXPANDED, SessionView.FULLSCREEN].includes(
              this.currentSessionView$.getValue(),
            )
          ) {
            this.openGalleryView();
          }
          return;
        }

        const { tileData, fullscreen } = expandedFullScreenChange;

        const participantTile = this.participantsArray$
          .getValue()
          .find(
            (tile) =>
              tile.participant$.getValue()?.participantId === tileData.participantId &&
              tile.track$.getValue() === tileData.trackType &&
              tile.user$.getValue()._id === tileData.userId,
          );

        if (participantTile) {
          if (fullscreen) {
            this.handleFullscreenFromLeaderMode(participantTile, fullscreen);
            return;
          }
          if (!fullscreen) {
            this.handleShowingAutoPinNotifRemoteLeaderOn(participantTile);
            this.handleAutoExpand(participantTile, true);
            return;
          }
        }

        this.openGalleryView();
      });

    this.rtcServiceController.hideSelfViewState
      .pipe(untilDestroyed(this))
      .subscribe((hideSelfViewState: HideSelfViewState) => {
        this.hideSelfViewActive$.next(hideSelfViewState.active);
      });

    this.navService.showSessionAnalytics.pipe(untilDestroyed(this)).subscribe((showAnalytics) => {
      this.getVideoSectionWidth(showAnalytics);
    });
  }

  // Used to show auto-pin notification for remote participants if the host enabled leader mode then shared his screen
  // And there is no other screen shares
  // It's done separately to depened on leader mode events not Daily. This is for remote participants ONLY
  private handleShowingAutoPinNotifRemoteLeaderOn(participantTile: UserTile) {
    const leaderModeState = this.spaceLeaderModeService.getSpaceLeaderModeStateUi();
    const currentLeaderOwnsScreenShareTile =
      leaderModeState.currentLeaderUid === participantTile.participant$.getValue()?.userId;
    const noOtherScreenSharingsAreAvailable =
      Object.keys(this.currentActiveScreenSharing).length === 1;
    if (
      !noOtherScreenSharingsAreAvailable ||
      !currentLeaderOwnsScreenShareTile ||
      leaderModeState.currentUserIsLeader
    ) {
      return;
    }
    this.buildScreenSharingStartedNotification(
      this.constructScreenSharingStartedNotificationTitle(
        Session.isOwnedByUser(this.spaceRepo.activeSpace, participantTile.user$.getValue()),
      ),
      this.constructScreenSharingStartedNotificationBody(participantTile.user$.getValue().name),
      undefined,
    );
    this._screenShareTileToBePinedByNotification = undefined;
  }

  handleHideSelfViewChange(hideSelfViewActive: boolean) {
    if (!hideSelfViewActive) {
      this.rtcServiceController.resetHideSelfViewState();
      return;
    }
    const participant = this.participantsArray$
      .getValue()
      .find(
        (participantTile) =>
          participantTile.participant$.getValue()?.local &&
          participantTile.track$.getValue() === TrackType.VIDEO,
      )
      ?.participant$.getValue();
    if (!participant) {
      return;
    }
    this.rtcServiceController.activateHideSelfView(participant);
    this.hideSelfViewActive$.next(true);
  }

  updateTilesContainerParameters(newTilesContainerParameters: any) {
    this.currentTilesContainerParameters = newTilesContainerParameters;
    this.updateTilesPosition();
  }

  updateTilesPosition() {
    if (!this.currentTilesContainerParameters) {
      return;
    }
    let currentSessionView = this.currentSessionView$.getValue();
    if (currentSessionView === SessionView.BREAKOUT_ROOMS) {
      currentSessionView = SessionView.WHITEBOARD;
    }
    const tilesContainerParameters = clone(this.currentTilesContainerParameters);
    // Calculate maximum number of tiles that page can hold
    const numberOfUserTilesPerPage = this.setNumberOfUserTilesPerPage(
      tilesContainerParameters,
      currentSessionView,
    );

    // Set the number of tiles that can be visible
    const numOfActiveTiles = this.calculateCurrentActiveTilesNum(currentSessionView);

    this.numberOfUserTilesPerPage = numberOfUserTilesPerPage;
    this.numberOfUserTilesPages$.next(this.calculateNumberOfUserTilesPages(numOfActiveTiles));

    const numberOfTilesInCurrentPage =
      this.currentUserTilesPageIndex$.getValue() === this.numberOfUserTilesPages$.getValue() - 1
        ? numOfActiveTiles - this.calculateNumOfAlreadyViewedTiles()
        : this.numberOfUserTilesPerPage;

    const tilePositionParameters = setTileParameters(
      tilesContainerParameters,
      numberOfTilesInCurrentPage,
      currentSessionView,
      this.isMobileView(),
    );
    if (!tilePositionParameters) {
      return;
    }

    // Set navigation buttons position
    this.navigationLeftButtonPosition$.next({
      top: tilePositionParameters.navigationButtonsCoordinates.topLeftButton,
      left: tilePositionParameters.navigationButtonsCoordinates.leftLeftButton,
    });
    this.navigationRightButtonPosition$.next({
      top: tilePositionParameters.navigationButtonsCoordinates.topRightButton,
      left: tilePositionParameters.navigationButtonsCoordinates.leftRightButton,
    });

    // Set component width & height only if the user is on right view to achieve it's exactly fit the tiles without any extra space
    this.setComponetDimensionsWithRightView(
      currentSessionView,
      tilePositionParameters,
      this.currentTilesContainerParameters?.videoControlsFirstDivHeight,
      this.currentTilesContainerParameters?.videoControlsSecondDivHeight,
    );

    if (this.currentUserTilesPageIndex$.getValue() >= this.numberOfUserTilesPages$.getValue()) {
      this.currentUserTilesPageIndex$.next(
        Math.max(this.numberOfUserTilesPages$.getValue() - 1, 0),
      );
    }
    // Handle visibility state for each active tile
    this.updateTilesState(tilePositionParameters, currentSessionView);
  }

  private calculateCurrentActiveTilesNum(currentSessionView: SessionView): number {
    // Force the current active tiles to be 1 in case of full screen view to use the same logic as gallery view
    if (currentSessionView === SessionView.FULLSCREEN) {
      return 1;
    }
    // Current active tiles (Tiles that can be visible)
    let numOfActiveTiles = this.numberOfPresentParticipants$.getValue();
    if (currentSessionView === SessionView.EXPANDED && this.expandedScreenUser$.getValue()) {
      numOfActiveTiles--;
    }
    return numOfActiveTiles;
  }

  private calculateNumberOfUserTilesPages(numOfActiveTiles: number): number {
    return Math.ceil(numOfActiveTiles / this.numberOfUserTilesPerPage);
  }

  private calculateNumOfAlreadyViewedTiles(): number {
    // the number of tiles that can be visible
    const numOfActiveTiles = this.calculateCurrentActiveTilesNum(
      this.currentSessionView$.getValue(),
    );
    const lastPageTilesCount = numOfActiveTiles % this.numberOfUserTilesPerPage;
    if (
      lastPageTilesCount > 0 &&
      this.numberOfUserTilesPages$.getValue() > 1 &&
      this.currentUserTilesPageIndex$.getValue() == this.numberOfUserTilesPages$.getValue() - 1
    ) {
      // incase last page does not have the maximum page size, we will show remaining items from previous page.
      return (
        this.currentUserTilesPageIndex$.getValue() * this.numberOfUserTilesPerPage -
        (this.numberOfUserTilesPerPage - lastPageTilesCount)
      );
    }
    return this.currentUserTilesPageIndex$.getValue() * this.numberOfUserTilesPerPage;
  }

  private setComponetDimensionsWithRightView(
    currentSessionView: SessionView,
    tilePositionParameters: TilePositionParameters,
    videoControlsFirstDivHeight: number | undefined,
    videoControlsSecondDivHeight: number | undefined,
  ) {
    if (
      this.isRightView(currentSessionView) &&
      videoControlsFirstDivHeight &&
      videoControlsSecondDivHeight
    ) {
      const newRightViewWidth =
        tilePositionParameters.currentColsNum * tilePositionParameters.currentTileWidth;
      if (!this.currentTilesContainerParameters?.skipSettingComponentDimsWidth) {
        if (newRightViewWidth === this.rightViewWidth$.getValue()) {
          this.rightViewWidth$.next(newRightViewWidth + 0.0000001);
        } else {
          this.rightViewWidth$.next(newRightViewWidth);
        }
      }
      const newRightViewHeight =
        videoControlsFirstDivHeight +
        videoControlsSecondDivHeight +
        tilePositionParameters.currentRowsNum * tilePositionParameters.currentTileHeight;
      if (newRightViewHeight !== this.rightViewHeight$.getValue()) {
        this.rightViewHeight$.next(newRightViewHeight);
      }

      // If there is a pagination, add navigation buttons height
      if (this.numberOfUserTilesPages$.getValue() > 1) {
        this.rightViewHeight$.next(this.rightViewHeight$.getValue() + 40);
      }
    }
  }

  private isRightView(currentSessionView: SessionView): boolean {
    return (
      (currentSessionView === SessionView.WHITEBOARD ||
        currentSessionView === SessionView.FULLSCREEN_APP) &&
      this.sessionSharedDataService.controlsLayout.getValue() === VideoLayout.RIGHT
    );
  }

  private updateTilesState(
    tilePositionParameters: TilePositionParameters,
    currentSessionView: SessionView,
  ) {
    let activeTileIndex = -1;
    let visibleTileIndex = -1;

    const startIndex = this.calculateNumOfAlreadyViewedTiles();
    const endIndex = startIndex + this.numberOfUserTilesPerPage;

    const currentActiveRemoteVideoOnCanvas = this.checkRemoteVideoStatusOnCanvas();

    for (const tile of this.participantsArray$.getValue()) {
      if (tile.active$.getValue()) {
        if (
          this.handleUpdateTilePositionFullScreen(tile, tilePositionParameters, currentSessionView)
        ) {
          continue;
        }

        if (
          this.handleUpdateTilePositionExpanded(tile, tilePositionParameters, currentSessionView)
        ) {
          continue;
        }

        activeTileIndex++;

        const isVisible = activeTileIndex >= startIndex && activeTileIndex < endIndex;
        if (isVisible) {
          visibleTileIndex++;
          const [top, left] = this.calculateTopAndLeftValues(
            visibleTileIndex,
            tilePositionParameters,
          );
          this.updateTilePosition(
            tile,
            tilePositionParameters.currentTileWidth,
            tilePositionParameters.currentTileHeight,
            top,
            left,
          );
        } else {
          tile.visible$.next(false);
        }
      } else {
        tile.visible$.next(false);
      }

      this.updateTileVisibility(tile, currentActiveRemoteVideoOnCanvas);
    }
  }

  private handleUpdateTilePositionFullScreen(
    userTile: UserTile,
    tilePositionParameters: TilePositionParameters,
    currentSessionView: SessionView,
  ): boolean {
    if (currentSessionView === SessionView.FULLSCREEN) {
      if (this.isParticipantExpanded(userTile)) {
        this.updateTilePosition(
          userTile,
          tilePositionParameters.currentTileWidth,
          tilePositionParameters.currentTileHeight,
          tilePositionParameters.anchorTop,
          tilePositionParameters.anchorLeft,
        );
      } else {
        userTile.visible$.next(false);
      }
      this.updateTileVisibility(userTile);
      return true;
    }
    return false;
  }

  private handleUpdateTilePositionExpanded(
    userTile: UserTile,
    tilePositionParameters: TilePositionParameters,
    currentSessionView: SessionView,
  ): boolean {
    if (currentSessionView === SessionView.EXPANDED && this.isParticipantExpanded(userTile)) {
      this.updateTilePosition(
        userTile,
        tilePositionParameters.expandedTileWidth,
        tilePositionParameters.expandedTileHeight,
        tilePositionParameters.anchorTopExpanded,
        tilePositionParameters.anchorLeftExpanded,
      );
      this.updateTileVisibility(userTile);
      return true;
    }
    return false;
  }

  private calculateTopAndLeftValues(
    visibleTileIndex: number,
    tilePositionParameters: TilePositionParameters,
  ): number[] {
    const colPosition = visibleTileIndex % tilePositionParameters.currentColsNum;
    const rawPosition = Math.floor(visibleTileIndex / tilePositionParameters.currentColsNum);

    let left: number;
    const top =
      rawPosition * tilePositionParameters.currentTileHeight +
      rawPosition * tilePositionParameters.spaceBetweenTiles +
      tilePositionParameters.anchorTop;

    if (
      rawPosition === tilePositionParameters.currentRowsNum - 1 &&
      tilePositionParameters.anchorLeftLastRow
    ) {
      left =
        colPosition * tilePositionParameters.currentTileWidth +
        colPosition * tilePositionParameters.spaceBetweenTiles +
        tilePositionParameters.anchorLeftLastRow;
    } else {
      left =
        colPosition * tilePositionParameters.currentTileWidth +
        colPosition * tilePositionParameters.spaceBetweenTiles +
        tilePositionParameters.anchorLeft;
    }
    return [top, left];
  }

  private isParticipantExpanded(userTile: UserTile): boolean {
    return !!(
      this.expandedScreenUser$.getValue()?.participant$.getValue() &&
      this.expandedScreenUser$.getValue()?.participant$.getValue()?.participantId ===
        userTile.participant$.getValue()?.participantId &&
      this.expandedScreenUser$.getValue()?.track$.getValue() === userTile.track$.getValue()
    );
  }

  private updateTilePosition(
    userTile: UserTile,
    newWidth: number | undefined,
    newHeight: number | undefined,
    newTop: number | undefined,
    newLeft: number | undefined,
  ) {
    userTile.width$.next(newWidth);
    userTile.height$.next(newHeight);
    let top = newTop;
    if (
      [SessionView.GALLERY_VIEW, SessionView.EXPANDED].includes(
        this.sessionSharedDataService.sessionView.getSessionView(),
      )
    ) {
      top = (newTop as number) - this.sessionSharedDataService.topControlsHeight;
    }
    userTile.top$.next(top);
    userTile.left$.next(newLeft);
    userTile.visible$.next(true);
  }

  private setNumberOfUserTilesPerPage(
    tilesContainerParameters: TilesContainerParameters,
    currentSessionView: SessionView,
  ): number {
    switch (currentSessionView) {
      case SessionView.WHITEBOARD:
      case SessionView.FULLSCREEN_APP:
        return Math.min(
          tilesContainerParameters.maxColsNum * tilesContainerParameters.maxRowsNum,
          this.maxNumOfTilesPerPageRightView,
        );
      case SessionView.GALLERY_VIEW:
      case SessionView.FULLSCREEN:
        return tilesContainerParameters.maxColsNum * tilesContainerParameters.maxRowsNum;
      case SessionView.EXPANDED:
        return tilesContainerParameters.maxColsNum;
      default:
        return 1;
    }
  }

  // Used for optimizations -> Unsubcribe for all video feeds once:
  // 1. Entering the minimized view
  // 2. Page isn't active for more than 1 minute
  private unsubscribeAllVideos() {
    const currentActiveRemoteVideoOnCanvas = this.checkRemoteVideoStatusOnCanvas();
    for (const tile of this.participantsArray$.getValue()) {
      // If this video feed is inserted into canvas, then skip unsubscribing from it
      if (
        currentActiveRemoteVideoOnCanvas.has(
          `${tile.participant$.getValue()?.participantId}:${tile.track$.getValue()}`,
        )
      ) {
        continue;
      }
      if (
        tile.active$.getValue() &&
        tile.participant$.getValue()?.participantId &&
        tile.visible$.getValue()
      ) {
        this.videoCallTracksStateService.updateTrackSubscription(
          tile.participant$.getValue()?.participantId,
          tile.track$.getValue(),
          false,
          this.konvaService.isPopoutVideoActive,
          tile.participant$.getValue().local,
        );
      }
    }
  }

  private checkRemoteVideoStatusOnCanvas(): Set<string> {
    return new Set(
      this.sessionSharedDataService.itemsCanvas?.canvasItemsArray$
        .getValue()
        .filter((canvasItem) => canvasItem.type === ItemModel.RemoteVideo)
        .map((canvasItem) => canvasItem.contentId),
    );
  }

  private resetVideosSubscribtionState() {
    for (const tile of this.participantsArray$.getValue()) {
      if (
        tile.active$.getValue() &&
        tile.participant$.getValue()?.participantId &&
        tile.visible$.getValue()
      ) {
        this.videoCallTracksStateService.updateTrackSubscription(
          tile.participant$.getValue()?.participantId,
          tile.track$.getValue(),
          true,
          this.konvaService.isPopoutVideoActive,
          tile.participant$.getValue().local,
        );
      }
    }
  }

  ngOnDestroy(): void {
    if (this.expandedScreenUser$.getValue()) {
      this.unsetFullScreen(this.expandedScreenUser$.getValue()!);
    }
    this.domListener.clear();
    this.realtimeSpaceService.service.unSubscribeFromEvent(this, EventCategory.Users);
    this.devicesManagerService.unsubscribeFromDeviceChange(this.deviceChangeListener);
    // Reset Konva state regradless the popout is active or not as we are keeping updating Konva state without entering popout
    this.konvaService.leaveCall();
    this.containerResizeObserver?.disconnect();
    this.rightSessionsPanelResizeObserver?.disconnect();
    this.spaceRecordingService.clearRecordingOutput();
  }

  // participant state functions
  handleParticipantJoined(event: ParticipantEvent): void {
    const participant = event.participant;
    if (this.skipAddingJoinedParticipant(participant)) {
      if (!this.userLookup[participant.userId]) {
        // If we will skip due to no entry inside populated users, we will keep track of this unhandled joined event till syncing latest space state
        this.unhandledJoinedEvents[participant.participantId] = event;
        this.telemetry.event(
          '[Populated users issue] A User is not found on participant joined event',
          {
            event,
          },
        );
      }
      return;
    }

    if (!(participant.participantId in this.participantLookup)) {
      this.konvaService.participantJoined(
        this.userLookup[event.participant.userId],
        event.participant.participantId,
        !!participant.local,
      );

      const videoTile: UserTile = {
        user$: new BehaviorSubject(this.userLookup[participant.userId]),
        participant$: new BehaviorSubject(participant),
        track$: new BehaviorSubject<VideoTrackType>(TrackType.VIDEO),
        networkQuality$: new BehaviorSubject<NetworkQuality>(NetworkQuality.UNKNOWN),
        visible$: new BehaviorSubject<boolean>(false),
        active$: new BehaviorSubject<boolean>(true),
        raiseHand$: new BehaviorSubject<boolean>(false),
        emojiId$: new BehaviorSubject(-1),
        top$: new BehaviorSubject<number | undefined>(undefined),
        left$: new BehaviorSubject<number | undefined>(undefined),
        width$: new BehaviorSubject<number | undefined>(undefined),
        height$: new BehaviorSubject<number | undefined>(undefined),
      };
      const screenTile: UserTile = {
        user$: new BehaviorSubject(this.userLookup[participant.userId]),
        participant$: new BehaviorSubject(participant),
        track$: new BehaviorSubject<VideoTrackType>(TrackType.SCREEN),
        networkQuality$: new BehaviorSubject<NetworkQuality>(NetworkQuality.UNKNOWN),
        visible$: new BehaviorSubject<boolean>(false),
        active$: new BehaviorSubject<boolean>(false),
        raiseHand$: new BehaviorSubject<boolean>(false),
        emojiId$: new BehaviorSubject(-1),
        top$: new BehaviorSubject<number | undefined>(undefined),
        left$: new BehaviorSubject<number | undefined>(undefined),
        width$: new BehaviorSubject<number | undefined>(undefined),
        height$: new BehaviorSubject<number | undefined>(undefined),
      };

      this.participantLookup[participant.participantId] = [videoTile, screenTile];
      // log to console for non-prod builds
      if (!environment.production) {
        console.log(participant.participantId, ' is added');
      }
      // To cover any possible race conditions if the tiles are created before ParticipantTracksState object is created successfully
      this.videoCallTracksStateService.createParticipantTracksState(participant.participantId);
      this.updateParticipantsArray();
    }
  }

  private skipAddingJoinedParticipant(participant: CallParticipant): boolean {
    return (
      !participant?.participantId ||
      participant.userId.includes('-') ||
      !this.userLookup[participant.userId]
    );
  }

  handleClosingAllActiveJoinCallRelatedModals(isJoinedParticipantLocal: boolean | undefined) {
    if (isJoinedParticipantLocal) {
      const joinCallPanelRef = this.wbDialogService.joinCallDialogRef;
      const spaceDeviceModalRef = this.wbDialogService.spaceDeviceModalRef;

      if (joinCallPanelRef) {
        joinCallPanelRef.close();
      }
      if (spaceDeviceModalRef) {
        spaceDeviceModalRef.close();
      }
    }
  }

  handleParticipantUpdated(event: ParticipantEvent) {
    if (
      this.expandedScreenUser$.getValue()?.participant$.getValue()?.participantId ===
      event.participant.participantId
    ) {
      this.expandedScreenUser$.getValue()!.participant$.next(event.participant);
    }
  }

  async handleParticipantLeft(event: ParticipantEvent): Promise<void> {
    if (event.participant.participantId) {
      // If the participant who linked to an unhandled joined event left, no need to keep it
      delete this.unhandledJoinedEvents[event.participant.participantId];
      // In case of the local user left the call, we will reset the component state to avoid any regressions
      if (event.participant.local) {
        this.rtcServiceController.resetHideSelfViewState(true);
        this.resetValuesAfterLocalUserDisconnected();
      } else {
        this.konvaService.participantLeft(event.participant.participantId);
        delete this.participantLookup[event.participant.participantId];
        if (
          this.expandedScreenUser$.getValue()?.participant$.getValue()?.participantId ===
          event.participant.participantId
        ) {
          if (this.expandedScreenUser$.getValue()) {
            this.unsetFullScreen(this.expandedScreenUser$.getValue()!);
            this.expandedScreenUser$.next(undefined);
          }
          this.sessionSharedDataService.sessionView.switchToSessionView(SessionView.GALLERY_VIEW);
        }
        this.updateParticipantsArray();
      }
    }
  }

  private resetValuesAfterLocalUserDisconnected() {
    this.participantLookup = {};
    this.localParticipantId$.next('');
    // Reseting the last local participant ID
    this.providerState.resetLocalParticipantIdBehaviorSub();
    this.participantsArray$.next([]);
    this.numberOfUserTilesPages$.next(1);
    this.numberOfUserTilesPerPage = 4;
    this.currentUserTilesPageIndex$.next(0);
    this.participantsSpeaking = new Set();
    this.screenSharingHasAudio = new Set();
    this.numberOfPresentParticipants$.next(0);
    this.expandedScreenUser$.next(undefined);
    this.currentActiveScreenSharing = {};
    this.unhandledJoinedEvents = {};

    // to make the modal appear that suggest share screen if screen share not forced
    // when joining a call again
    this.forceScreenShareBlockingModalService.resetStatusOnLeaveCall();
  }

  public handleParticipantChange(participant: CallParticipant): void {
    const tiles = this.participantLookup?.[participant?.participantId ?? ''];
    if (tiles) {
      const screenTile = tiles[1];
      const oldScreenState = screenTile.active$.getValue();
      this.updateScreenSharingTileState(tiles, participant);
      if (oldScreenState !== screenTile.active$.getValue()) {
        this.fireAudioChimeScreenSharingIfActive(oldScreenState, screenTile);
        if (
          oldScreenState &&
          this.expandedScreenUser$.getValue()?.track$.getValue() === TrackType.SCREEN &&
          screenTile?.participant$.getValue()?.participantId ===
            this.expandedScreenUser$.getValue()?.participant$.getValue()?.participantId
        ) {
          this.unsetFullScreen(screenTile);
          this.expandedScreenUser$.next(undefined);
          if (
            [SessionView.EXPANDED, SessionView.FULLSCREEN].includes(
              this.currentSessionView$.getValue(),
            )
          ) {
            this.sessionSharedDataService.sessionView.switchToSessionView(SessionView.GALLERY_VIEW);
          }
        }
        // Only supported on Desktop
        if (this.deviceDetectorService.isDesktop()) {
          this.handleAutoPinScreenSharing(oldScreenState, screenTile);
        } else {
          this.updateNumberOfTiles();
        }
      }
    }
    this.dismissOrphanPinScreenShareNotification(participant);
  }

  private dismissOrphanPinScreenShareNotification(participant: CallParticipant) {
    if (
      !this._screenShareTileToBePinedByNotification ||
      participant.participantId !==
        this._screenShareTileToBePinedByNotification.participant$.value.participantId
    ) {
      return;
    }
    const participantHasTiles = this.participantLookup[participant.participantId];
    if (!participantHasTiles || !this.rtcServiceController.trackIsShared(participant.screen)) {
      this.notificationToasterService.dismissNotificationsByCode([INFOS.SCREEN_SHARING_STARTED]);
    }
  }

  private updateScreenSharingTileState(tiles: UserTile[], participant: CallParticipant) {
    tiles.forEach((tile) => {
      tile.participant$.next(participant);
      if (tile.track$.getValue() === TrackType.SCREEN) {
        if (this.rtcServiceController.trackIsShared(tile.participant$.getValue().screen)) {
          if (this.disableCurrentActiveScreenSharing(participant)) {
            this.rtcServiceController.service.stopScreenShare();
            tile.active$.next(false);
          } else {
            this.currentActiveScreenSharing[tile.participant$.getValue().participantId] =
              tile.participant$.getValue();
            tile.active$.next(true);
            // this is to handle a case where the leader expand/fullscreen the tile before the tile
            // become active for the leaded person so we will follow the leader state once the tile become active
            this.followLeaderModeStateOfTheTile(tile);
          }
        } else {
          delete this.currentActiveScreenSharing[tile.participant$.getValue().participantId];
          tile.active$.next(false);
        }
        this.konvaService.updateScreenActiveState(
          participant.participantId,
          tile.active$.getValue(),
        );
      }
    });
  }

  private followLeaderModeStateOfTheTile(tile: UserTile) {
    const expandedFullScreenChange =
      this.spaceLeaderModeService.getSpaceLeaderModeStateUi().expandAndFullScreenChange;
    if (!expandedFullScreenChange || !this.spaceLeaderModeService.currentUserIsBeingLed()) {
      return;
    }
    const { tileData, fullscreen } = expandedFullScreenChange;
    if (
      tile.participant$.getValue()?.participantId === tileData.participantId &&
      tile.track$.getValue() === tileData.trackType &&
      tile.user$.getValue()._id === tileData.userId
    ) {
      this.sessionSharedDataService.sessionView.switchToSessionView(
        fullscreen ? SessionView.FULLSCREEN : SessionView.EXPANDED,
        {
          tileData,
          id: uuidv4(),
          fullscreen,
        },
      );
    }
  }

  // Used to detect a participant pressed on share screen button, and while choosing which window/tab to share, a host disabled the screen sharing permission
  private disableCurrentActiveScreenSharing(participant: CallParticipant): boolean {
    return !!participant.local && !this.spacePermissionsManagerService.canShareScreen();
  }

  private fireAudioChimeScreenSharingIfActive(oldScreenState: boolean, screenTile: UserTile) {
    if (screenTile.active$.getValue() && !oldScreenState) {
      this.audioService.playAudio(AudioChimes.screenShare);
    }
  }

  private handleAutoPinScreenSharing(oldScreenState: boolean, screenTile: UserTile) {
    if (!this.spaceRepo.activeSpace) {
      return;
    }
    const isScreenTileActive = screenTile.active$.getValue() && !oldScreenState;
    const isLocalUserOwnsCurrentScreenSharing =
      screenTile.participant$.getValue()?.userId === this.user?._id;
    const noOtherScreenSharingsAreAvailable =
      Object.keys(this.currentActiveScreenSharing).length === 1;
    const leaderModeStateUi = this.spaceLeaderModeService.getSpaceLeaderModeStateUi();
    const isCurrentUserHost = Session.isOwnedByUser(this.spaceRepo.activeSpace, this.user);
    const isUserHost = Session.isOwnedByUser(
      this.spaceRepo.activeSpace,
      screenTile.user$.getValue(),
    );

    // Detect if it's possible to auto-pin the current screen sharing while the leader mode is off
    const autoPinScreenSharingExistLeaderModeOff =
      !leaderModeStateUi.currentLeaderUid &&
      isScreenTileActive &&
      !isLocalUserOwnsCurrentScreenSharing &&
      noOtherScreenSharingsAreAvailable &&
      !this.konvaService.isPopoutVideoActive;

    // Detect if it's possible to auto-pin the current screen sharing while the leader mode is on
    // Last condition is introduced to make the current screen sharing auto-pinned for both sides (Sender[Current leader who shared his screen], and recevier[Other hosts, participants]).
    // To have a consistancy across all cases by depending on Daily events for auto-pinning feature
    const autoPinScreenSharingExistLeaderModeOn =
      leaderModeStateUi.currentLeaderUid &&
      isScreenTileActive &&
      noOtherScreenSharingsAreAvailable &&
      isLocalUserOwnsCurrentScreenSharing &&
      leaderModeStateUi.currentUserIsLeader;

    // Used to suggest auto-pinning with the current leader if another HOST shares his screen
    const suggestPinAnotherHostScreenShareLeaderModeOn =
      leaderModeStateUi.currentLeaderUid !== undefined &&
      isScreenTileActive &&
      !isLocalUserOwnsCurrentScreenSharing &&
      leaderModeStateUi.currentUserIsLeader &&
      isUserHost;

    // Used for auto-pin action
    this.updateNumberOfTiles(
      (autoPinScreenSharingExistLeaderModeOff || autoPinScreenSharingExistLeaderModeOn) &&
        !this.dismissShowingOpenPermissionsPanelNotification(isUserHost, isCurrentUserHost)
        ? screenTile
        : undefined,
    );

    if (
      this.notShowingAutopinNotification(
        isUserHost,
        isCurrentUserHost,
        isLocalUserOwnsCurrentScreenSharing,
        isScreenTileActive,
        leaderModeStateUi.currentLeaderUid,
        autoPinScreenSharingExistLeaderModeOn,
        suggestPinAnotherHostScreenShareLeaderModeOn,
      )
    ) {
      return;
    }

    if (!isLocalUserOwnsCurrentScreenSharing && isScreenTileActive) {
      this.handleShowingScreenSharingStartedNotification(
        isCurrentUserHost,
        isUserHost,
        screenTile,
        autoPinScreenSharingExistLeaderModeOff,
        suggestPinAnotherHostScreenShareLeaderModeOn,
        leaderModeStateUi.currentLeaderUid !== undefined,
      );
    }
  }

  // Used to avoid showing the notification if:
  // 1. Local user who shares his screen
  // 2. Screen sharing is gone
  // 3. If the leader mode is active and the leader/another HOST isn't sharing his screen
  // 4. not showing "Open permissions panel" notification in case of
  //    a participant pressed on share screen button, and while choosing which window/tab to share, a host disabled the screen sharing permission
  private notShowingAutopinNotification(
    isUserHost: boolean,
    isCurrentUserHost: boolean,
    isLocalUserOwnsCurrentScreenSharing: boolean,
    isScreenTileActive: boolean,
    currentLeaderUid: string | undefined,
    autoPinScreenSharingExistLeaderModeOn: boolean | '' | undefined,
    suggestPinAnotherHostScreenShareLeaderModeOn: boolean,
  ): boolean {
    return (
      isLocalUserOwnsCurrentScreenSharing ||
      !isScreenTileActive ||
      (currentLeaderUid &&
        !autoPinScreenSharingExistLeaderModeOn &&
        !suggestPinAnotherHostScreenShareLeaderModeOn) ||
      this.dismissShowingOpenPermissionsPanelNotification(isUserHost, isCurrentUserHost)
    );
  }

  private dismissShowingOpenPermissionsPanelNotification(
    isUserHost: boolean,
    isCurrentUserHost: boolean,
  ): boolean {
    return !isUserHost && isCurrentUserHost && !this.getCurrentScreenSharePermissionStatus();
  }

  private getCurrentScreenSharePermissionStatus(): boolean {
    return !!this.spaceRepo.activeSpaceCurrentRoom?.permissions.screenShare;
  }

  private handleShowingScreenSharingStartedNotification(
    isCurrentUserHost: boolean,
    isUserHost: boolean,
    screenTile: UserTile,
    autoPinScreenSharingExistLeaderModeOff: boolean,
    suggestPinAnotherHostScreenShareLeaderModeOn: boolean,
    isLeaderModeActive: boolean,
  ) {
    const title = this.constructScreenSharingStartedNotificationTitle(isUserHost);

    const body = this.constructScreenSharingStartedNotificationBody(
      screenTile.user$.getValue().name,
    );

    const actionButton = this.constructScreenSharingStartedNotificationActionButton(
      isCurrentUserHost,
      isUserHost,
      screenTile,
      autoPinScreenSharingExistLeaderModeOff,
      suggestPinAnotherHostScreenShareLeaderModeOn,
      isLeaderModeActive,
    );

    this._screenShareTileToBePinedByNotification = screenTile;
    this.buildScreenSharingStartedNotification(title, body, actionButton);
  }

  private constructScreenSharingStartedNotificationTitle(
    isUserHost: boolean,
  ): IconMessageToasterElement {
    return new IconMessageToasterElement(
      { icon: 'screen_share', size: 16 },
      this.translateService.instant(`${isUserHost ? 'Host' : 'Participant'} is sharing screen`),
    );
  }

  private constructScreenSharingStartedNotificationBody(
    screenSharerName: string,
  ): IconMessageToasterElement {
    return new IconMessageToasterElement(
      undefined,
      this.translateService.instant(`${screenSharerName} is sharing their screen.`),
    );
  }

  private constructScreenSharingStartedNotificationActionButton(
    isCurrentUserHost: boolean,
    isUserHost: boolean,
    screenTile: UserTile,
    autoPinScreenSharingExistLeaderModeOff: boolean,
    suggestPinAnotherHostScreenShareLeaderModeOn: boolean,
    isLeaderModeActive: boolean,
  ): ButtonToasterElement | undefined {
    // actionButton will have a value if:
    // 1. No available screen share currently, and the user who shares his screen isn't a host (isCurrentUserHost && !isUserHost)
    // 2. The call has other screen shares (!autoPinScreenSharingExistLeaderModeOff)
    // Both must be happened while leader mode is OFF
    // 3. There is a suggestion to pin another host's screen sharing while the local user is the host
    if (
      (((isCurrentUserHost && !isUserHost) || !autoPinScreenSharingExistLeaderModeOff) &&
        !isLeaderModeActive) ||
      suggestPinAnotherHostScreenShareLeaderModeOn
    ) {
      return new ButtonToasterElement(
        [
          autoPinScreenSharingExistLeaderModeOff ? undefined : { icon: 'push_pin', size: 16 },
          this.translateService.instant(
            autoPinScreenSharingExistLeaderModeOff ? 'Manage permissions' : 'Pin screen share',
          ),
        ],
        {
          handler: () => {
            if (autoPinScreenSharingExistLeaderModeOff) {
              if (
                this.sessionSharedDataService.changeRightPanelView.getValue() !==
                PanelView.participantsManager
              ) {
                this.sessionSharedDataService.changeRightPanelView.next(
                  PanelView.participantsManager,
                );
              }
              this.sessionSharedDataService.changeParticipantsManagerSection.next(
                Section.PERMISSIONS,
              );
            } else {
              this.handleAutoExpand(screenTile, true);
            }
          },
          close: true,
        },
        ButtonToasterElementStyle.RAISED,
      );
    }

    return undefined;
  }

  private buildScreenSharingStartedNotification(
    title: IconMessageToasterElement,
    body: IconMessageToasterElement,
    actionButton: ButtonToasterElement | undefined,
  ) {
    this.notificationToasterService.dismissNotificationsByCode([INFOS.SCREEN_SHARING_STARTED]);
    const screenSharingIsStartedNotificationBuilder = new NotificationDataBuilder(
      INFOS.SCREEN_SHARING_STARTED,
    )
      .type(NotificationType.INFO)
      .style(ToasterPopupStyle.INFO)
      .topElements([title])
      .middleElements([body])
      .priority(500);

    // actionButton will have a value if:
    // 1. The call has other screen shares
    // 2. No available screen share currently, and the user who shares his screen isn't a host
    // 3. There is a suggestion to pin another host's screen sharing while the local user is the host
    if (actionButton) {
      screenSharingIsStartedNotificationBuilder.bottomElements([actionButton]);
    }

    // All notifications are dismissable, with 8 second time-out, even if they have actions
    const screenSharingIsStartedNotification: NotificationData =
      screenSharingIsStartedNotificationBuilder.timeOut(8).dismissable(true).build();

    this.notificationToasterService.showNotification(screenSharingIsStartedNotification);
  }

  // update view functions
  async updateParticipantsArray(): Promise<void> {
    const usersTiles = Object.values(this.participantLookup);
    let allTiles: UserTile[] = [];
    const screenTiles: UserTile[] = [];
    usersTiles.forEach((tiles) => {
      tiles.forEach((tile) => {
        if (tile.track$.getValue() === TrackType.SCREEN) {
          screenTiles.push(tiles[1]);
        } else {
          // Used to put the local user firstly on every video call view(Gallery, right view, ...)
          // Second condition is used to force the current local user to be at the first even if there are multiple duplications of the same user
          if (
            tile.user$.getValue()._id === this.user?._id &&
            allTiles[0]?.user$.getValue()._id !== this.user._id
          ) {
            allTiles.unshift(tiles[0]);
          } else {
            allTiles.push(tiles[0]);
          }
        }
      });
    });
    allTiles = screenTiles.concat(allTiles);
    this.participantsArray$.next(allTiles);
    this.updateNumberOfTiles();
    this.checkEmoteOnReconnect();
  }

  private updateTileVisibility(tile: UserTile, currentActiveRemoteVideoOnCanvas?: Set<string>) {
    // If this video feed is inserted into canvas, then skip unsubscribing from it while paginating tiles
    if (
      !tile.visible$.getValue() &&
      currentActiveRemoteVideoOnCanvas?.has(
        `${tile.participant$.getValue()?.participantId}:${tile.track$.getValue()}`,
      )
    ) {
      return;
    }
    if (tile.participant$.getValue()?.participantId) {
      this.videoCallTracksStateService.updateTrackSubscription(
        tile.participant$.getValue()?.participantId,
        tile.track$.getValue(),
        tile.visible$.getValue(),
        this.konvaService.isPopoutVideoActive,
        tile.participant$.getValue().local,
        this.disableVideoStreams && tile.track$.getValue() === 'video' ? false : true,
      );
    }
  }

  disableIncomingVideoStreams() {
    // Disable the current expanded user video feed who is full screened/pinned
    // We are doing this if we pinned the participant's tile not his screen sharing tile
    // Only caring about the other participants not the local one as we diabling his video feed by turning off his cam not unsubcribing
    if (
      this.expandedScreenUser$.getValue()?.participant$.getValue()?.participantId &&
      this.expandedScreenUser$.getValue()?.user$.getValue()?._id !== this.user?._id &&
      this.expandedScreenUser$.getValue()?.track$.getValue() === TrackType.VIDEO
    ) {
      this.videoCallTracksStateService.updateTrackSubscription(
        this.expandedScreenUser$.getValue()!.participant$.getValue()?.participantId,
        this.expandedScreenUser$.getValue()!.track$.getValue(),
        false,
        this.konvaService.isPopoutVideoActive,
        this.expandedScreenUser$.getValue()!.participant$.getValue().local,
      );
    }

    for (const tile of this.participantsArray$.getValue()) {
      if (
        tile.active$.getValue() &&
        tile.visible$.getValue() &&
        tile.track$.getValue() === 'video' &&
        tile.participant$.getValue()?.participantId
      ) {
        this.videoCallTracksStateService.updateTrackSubscription(
          tile.participant$.getValue()?.participantId,
          tile.track$.getValue(),
          false,
          this.konvaService.isPopoutVideoActive,
          tile.participant$.getValue().local,
        );
      }
    }
  }

  enableIncomingVideoStreams() {
    // Enable the current expanded user video feed who is full screened/pinned
    // We are doing this if we pinned the participant's tile not his screen sharing tile
    // Only caring about the other participants not the local one
    if (
      this.expandedScreenUser$.getValue()?.participant$.getValue()?.participantId &&
      this.expandedScreenUser$.getValue()?.user$.getValue()?._id !== this.user?._id &&
      this.expandedScreenUser$.getValue()?.track$.getValue() === TrackType.VIDEO
    ) {
      this.videoCallTracksStateService.updateTrackSubscription(
        this.expandedScreenUser$.getValue()!.participant$.getValue()?.participantId,
        this.expandedScreenUser$.getValue()!.track$.getValue(),
        this.expandedScreenUser$.getValue()!.visible$.getValue(),
        this.konvaService.isPopoutVideoActive,
        this.expandedScreenUser$.getValue()!.participant$.getValue().local,
      );
    }

    for (const tile of this.participantsArray$.getValue()) {
      if (
        tile.active$.getValue() &&
        tile.track$.getValue() === 'video' &&
        tile.participant$.getValue()?.participantId &&
        tile.visible$.getValue()
      ) {
        this.videoCallTracksStateService.updateTrackSubscription(
          tile.participant$.getValue()?.participantId,
          tile.track$.getValue(),
          tile.visible$.getValue(),
          this.konvaService.isPopoutVideoActive,
          tile.participant$.getValue().local,
        );
      }
    }
  }

  async updateNumberOfTiles(tileToBeAutoPinned?: UserTile): Promise<void> {
    let numOfTiles = 0;
    this.participantsArray$.getValue().forEach((tile) => {
      if (tile.active$.getValue()) {
        numOfTiles += 1;
      }
    });
    this.numberOfPresentParticipants$.next(numOfTiles);
    if (tileToBeAutoPinned) {
      this.handleAutoExpand(tileToBeAutoPinned, true);
      return;
    }
    this.updateTilesPosition();
  }

  trackById(_: number, item: UserTile): string {
    const id =
      (item.participant$.getValue()
        ? item.participant$.getValue().participantId
        : item.user$.getValue()._id) + item.track$.getValue();
    return id;
  }

  getRole(user: User): string {
    if (user?.personas && user.institution?.personas) {
      return user.institution.personas[user.personas[0]].name;
    } else if (user?.personas?.length) {
      return user.personas[0];
    }
    return '';
  }

  private handleRTCConnectionChange(connected: boolean): void {
    if (this.rtcConnected === undefined && connected) {
      this.displayFirstConnectionToCallNotification();
    } else if (!this.rtcConnected && connected) {
      if (!this.callConnected) {
        this.displayReconnectedToCallNotification();
      }
    } else if (this.rtcConnected === undefined && !connected) {
      return;
    }

    // We want to reset the maps when the client is closed
    this.rtcConnected = connected;

    if (!this.rtcConnected) {
      if (
        this.expandedScreenUser$.getValue() ||
        this.currentSessionView$.getValue() === SessionView.EXPANDED
      ) {
        this.expandedScreenUser$.next(undefined);
        this.returnToWhiteboard();
      }
      this.participantLookup = {};
      this.updateParticipantsArray();
    }
  }

  private handleSpaceOrBreakoutRoomChange(space: ISession, breakoutRoomId?: string) {
    const spaceId = space._id;
    this.setupPresenceListeners(space._id, breakoutRoomId);

    this.spaceId = spaceId;
    this.currentBreakoutRoomId = breakoutRoomId;
    this.users = space.populatedUsers;
    this.setUserLookup();
    this.updateParticipantsArray();
  }

  private setupPresenceListeners(spaceId: string, breakoutRoomId?: string) {
    if (this.spaceId === spaceId && this.currentBreakoutRoomId === breakoutRoomId) {
      return;
    }
    this.presenceSubscription?.unsubscribe();
    this.presenceSubscription = this.presenceProvider
      .getRoomPresenceActivity(spaceId, breakoutRoomId)
      .pipe(untilDestroyed(this))
      .subscribe((presenceResponse) => {
        presenceResponse = presenceResponse ?? new Set();
        // Remember `participant count` before update
        const oldParticipantCount = this.presentUsersUIDs.size;
        this.presentUsersUIDs = presenceResponse;
        const participantCount = this.presentUsersUIDs.size;

        // check to see if presence info has changed, if so, let Fullstory know
        if (participantCount !== oldParticipantCount) {
          const count = {
            'Old count': oldParticipantCount,
            'New count': participantCount,
          };
          // Ignore if freshpaint not enabled.
          this.telemetry.event('Space participant count changed', { count: count });
        }
      });
  }

  // Handle views functions
  returnToWhiteboard(): void {
    if (this.sessionSharedDataService.sessionView.getSessionView() === SessionView.FULLSCREEN_APP) {
      return;
    }

    if (this.expandedScreenUser$.getValue()) {
      this.unsetFullScreen(this.expandedScreenUser$.getValue()!);
      this.expandedScreenUser$.next(undefined);
    }

    if (
      this.sessionSharedDataService.sessionView.getPreviousSessionView() ===
      SessionView.FULLSCREEN_APP
    ) {
      this.sessionSharedDataService.sessionView.switchToSessionView(
        SessionView.FULLSCREEN_APP,
        undefined,
        undefined,
        this.sessionSharedDataService.sessionView.getPreviousSessionViewMetaData(),
      );
    } else {
      this.sessionSharedDataService.sessionView.switchToSessionView(SessionView.WHITEBOARD);
    }

    this.sessionSharedDataService.openMobileGalleryView.next(false);
    if (this.currentLayout === VideoLayout.TOP) {
      this.sessionSharedDataService.controlsLayout.next(VideoLayout.TOP);
    }
  }

  popView(popoutViewEvent: { layout: PopoutLayout; returnToWhiteboard: boolean }) {
    // Used to force subscribe video tracks inside Konva service if the user opened the popout after having a minimized view
    const isCurrentLayoutMinimized =
      this.sessionSharedDataService.controlsLayout.getValue() === VideoLayout.MINIMIZED;
    if (this.konvaService.isPopoutVideoActive) {
      this.konvaService.popoutVideo(popoutViewEvent.layout, isCurrentLayoutMinimized);
    } else {
      this.konvaService.popoutVideo(
        popoutViewEvent.layout,
        isCurrentLayoutMinimized,
        this.currentLayout,
      );
    }
    if (popoutViewEvent.returnToWhiteboard) {
      this.returnToWhiteboard();
    }
    this.sessionSharedDataService.controlsLayout.next(VideoLayout.MINIMIZED);
  }

  reportProblem() {
    if (!this.user) {
      return;
    }
    const doorbell = (window as Window & typeof globalThis & { doorbell: any })['doorbell'];
    if (doorbell && this.user) {
      doorbell.setOption('email', this.user.email);
      doorbell.setOption('name', this.user.name);
      doorbell.setOption('properties', {
        id: this.user._id,
      });
      doorbell.refresh();
      doorbell.show();
    }
  }

  public toggleTransform(axis: string): void {
    const el = document.getElementById('fullScreenContainer');
    const videoEl = el?.getElementsByTagName('video')[0];

    if (!videoEl) {
      return;
    }

    if (axis === 'x') {
      videoEl.style.transform += 'rotateX(180deg)';
    }

    if (axis === 'y') {
      if (el?.classList.contains('mirror-video')) {
        el.classList.remove('mirror-video');
      } else {
        videoEl.style.transform += 'rotateY(180deg)';
      }
    }
  }

  private detectNoMic() {
    this.deviceChangeListener = this.devicesManagerService.subscribeToDeviceChange(() => {
      this.rtcServiceController.getDevices().then((devices) => {
        if (devices.mics.length === 0) {
          this.localTracksManager.mute(DeviceType.AUDIO);
          this.localTracksManager.updateMicNotFound(true);
        } else {
          this.localTracksManager.updateMicNotFound(false);
        }
      });
    });
  }

  navigateTiles(pageIndex: number): void {
    this.currentUserTilesPageIndex$.next(pageIndex);
    if (this.currentUserTilesPageIndex$.getValue() < 0) {
      this.currentUserTilesPageIndex$.next(0);
    }
    if (this.currentUserTilesPageIndex$.getValue() >= this.numberOfUserTilesPages$.getValue()) {
      this.currentUserTilesPageIndex$.next(this.numberOfUserTilesPages$.getValue() - 1);
    }
    this.updateTilesPosition();
  }

  openGalleryView(): void {
    if (this.expandedScreenUser$.getValue()) {
      this.unsetFullScreen(this.expandedScreenUser$.getValue()!);
      this.expandedScreenUser$.next(undefined);
    }
    this.sessionSharedDataService.sessionView.switchToSessionView(SessionView.GALLERY_VIEW);
  }

  navigateToPreviousView(): void {
    if (this.expandedScreenUser$.getValue()) {
      this.unsetFullScreen(this.expandedScreenUser$.getValue()!);
      this.expandedScreenUser$.next(undefined);
    }
    this.sessionSharedDataService.sessionView.switchBackToPreviousView();
  }

  // handle users view state changes functions
  private unsetFullScreen(userTile: UserTile): boolean {
    const participant = userTile.participant$.getValue();

    if (!participant) {
      return false;
    }
    if (userTile.user$.getValue()._id === this.user?._id) {
      this.videoAiService.unsetFullScreen();
    }
    return true;
  }

  public handleSpeakingParticipant(
    uid: string,
    currentlyDetectingAudio: CurrentlyDetectingAudio,
  ): void {
    if (currentlyDetectingAudio) {
      switch (currentlyDetectingAudio.trackType) {
        case TrackType.AUDIO:
          this.handleAddParticipantSpeaking(uid, currentlyDetectingAudio.isCurrentlyDetected);
          break;
        case TrackType.SCREEN_AUDIO:
          this.handleAddScreenSharingHasAudio(uid, currentlyDetectingAudio.isCurrentlyDetected);
      }
    }
  }

  private handleAddParticipantSpeaking(uid: string, speaking: boolean) {
    if (speaking) {
      this.participantsSpeaking.add(uid);
      this.speakingUser$.next(this.participantLookup[uid][0]?.user$.getValue());
      this.sessionSharedDataService.updateBlueBorderActiveSpeaker(uid);
    } else {
      if (this.sessionSharedDataService.getBlueBorderActiveSpeakerVal() === uid) {
        this.sessionSharedDataService.updateBlueBorderActiveSpeaker('');
      }
      this.participantsSpeaking.delete(uid);
    }
    this.konvaService.updateParticipantBlueBorder(uid, TrackType.VIDEO, speaking);
  }

  private handleAddScreenSharingHasAudio(uid: string, hasAudio: boolean) {
    if (hasAudio) {
      this.screenSharingHasAudio.add(uid);
    } else {
      this.screenSharingHasAudio.delete(uid);
    }
    this.konvaService.updateParticipantBlueBorder(uid, TrackType.SCREEN_AUDIO, hasAudio);
  }

  sessionEventReceived(event: SessionEvent, userId: string): void {
    switch (event.type) {
      case 'users:network-quality':
        this.participantsArray$
          .getValue()
          .filter((tiles) => tiles.user$.getValue()?._id === userId)
          .forEach((tile) => {
            tile.networkQuality$.next(event.data);
          });

        if (
          this.expandedScreenUser$.getValue() &&
          this.expandedScreenUser$.getValue()?.user$.getValue()._id === userId
        ) {
          this.expandedScreenUser$.getValue()!.networkQuality$.next(event.data);
        }
        break;
      case 'users:raise-hand':
        this.participantsArray$
          .getValue()
          .filter((tiles) => tiles.user$.getValue()?._id === userId)
          .forEach((tile) => {
            tile.raiseHand$.next(event.data);
          });

        if (
          this.expandedScreenUser$.getValue() &&
          this.expandedScreenUser$.getValue()?.user$.getValue()._id === userId
        ) {
          this.expandedScreenUser$.getValue()!.raiseHand$.next(event.data);
        }
        break;
      case 'users:emote':
        this.participantsArray$
          .getValue()
          .filter((tiles) => tiles.user$.getValue()?._id === userId)
          .forEach((tile) => {
            tile.emojiId$.next(this.showOtherParticipantsEmotes ? event.data.emojiId : -1);
            // Clear emote in case sender loses network connection
            this.setEmoteExpiry(tile, false);
          });

        if (
          this.expandedScreenUser$.getValue() &&
          this.expandedScreenUser$.getValue()?.user$.getValue()._id === userId
        ) {
          this.expandedScreenUser$
            .getValue()!
            .emojiId$.next(this.showOtherParticipantsEmotes ? event.data.emojiId : -1);
          this.setEmoteExpiry(this.expandedScreenUser$.getValue()!, true);
        }
        break;
      case 'users:celebrate':
        this.toggleCelebration(event.data.celebrationId);
    }
  }

  public toggleCelebration(celebrationId: number) {
    if (!this.callConnected) {
      return;
    }
    this.celebrationsService.fireCelebration(celebrationId);
  }

  public handleExpand(userTile: UserTile, expand: boolean): void {
    if (!expand) {
      return;
    }
    this.tryHandleExpand(userTile);
  }

  public tryHandleExpand(userTile: UserTile): void {
    if (
      !this.fullScreenTileIsIdentical(userTile) &&
      this.turnOnFullScreen(userTile) &&
      userTile.participant$.getValue()
    ) {
      this.sessionSharedDataService.sessionView.switchToSessionView(SessionView.EXPANDED, {
        tileData: {
          trackType: userTile.track$.getValue(),
          participantId: userTile.participant$.getValue()?.participantId,
          userId: userTile.user$.getValue()._id,
        },
        id: uuidv4(),
      });
    } else {
      this.openGalleryView();
    }
  }

  // Used to handle auto expand from
  // 1. Leader mode
  // 2. Auto-pin screen sharing
  public handleAutoExpand(userTile: UserTile, expand: boolean): void {
    if (this.fullScreenTileIsIdentical(userTile) || !expand) {
      return;
    }

    if (this.turnOnFullScreen(userTile)) {
      // Make sure we already subscribed to the pinned tile
      if (userTile.participant$.getValue() && !this.disableVideoStreams) {
        this.videoCallTracksStateService.updateTrackSubscription(
          userTile.participant$.getValue()?.participantId,
          userTile.track$.getValue(),
          true,
          this.konvaService.isPopoutVideoActive,
          userTile.participant$.getValue().local,
        );
        this.sessionSharedDataService.sessionView.switchToSessionView(SessionView.EXPANDED, {
          tileData: {
            trackType: userTile.track$.getValue(),
            participantId: userTile.participant$.getValue()?.participantId,
            userId: userTile.user$.getValue()._id,
          },
          id: uuidv4(),
        });
      }
    }
  }

  private fullScreenTileIsIdentical(userTile: UserTile, fullscreen = false) {
    // There is no change in who is expanded or which size
    const sameUser =
      this.expandedScreenUser$.getValue()?.participant$.getValue()?.participantId ===
      userTile.participant$.getValue()?.participantId;
    const sameTrack =
      this.expandedScreenUser$.getValue()?.track$.getValue() === userTile.track$.getValue();
    const currentSize = this.sessionSharedDataService.sessionView.getSessionView();
    const sameSize = fullscreen
      ? currentSize === this.sessionView.FULLSCREEN
      : currentSize === this.sessionView.EXPANDED;
    if (sameSize && sameTrack && sameUser) {
      return true;
    }
    return false;
  }

  private turnOnFullScreen(userTile: UserTile): boolean {
    if (
      !userTile.participant$.getValue() ||
      !userTile.user$.getValue() ||
      !userTile.active$.value
    ) {
      return false;
    }

    if (this.expandedScreenUser$.getValue()) {
      this.unsetFullScreen(this.expandedScreenUser$.getValue()!);
    }

    this.expandedScreenUser$.next({ ...userTile });
    return true;
  }

  public handleFullscreen(userTile: UserTile | undefined, fullscreen: boolean): void {
    if (!fullscreen) {
      return;
    }

    if (userTile) {
      if (!this.fullScreenTileIsIdentical(userTile, fullscreen)) {
        if (this.turnOnFullScreen(userTile) && userTile.participant$.getValue()) {
          this.sessionSharedDataService.changeRightPanelView.next(undefined);
          this.sessionSharedDataService.changeLeftPanelView.next(undefined);
          this.sessionSharedDataService.sessionView.switchToSessionView(SessionView.FULLSCREEN, {
            tileData: {
              trackType: userTile.track$.getValue(),
              participantId: userTile.participant$.getValue()?.participantId,
              userId: userTile.user$.getValue()._id,
            },
            fullscreen,
            id: uuidv4(),
          });
        }
      } else {
        this.navigateToPreviousView();
      }
    }
  }

  public handleFullscreenFromLeaderMode(userTile: UserTile | undefined, fullscreen: boolean): void {
    if ((userTile && this.fullScreenTileIsIdentical(userTile, fullscreen)) || !fullscreen) {
      return;
    }

    if (userTile) {
      if (this.turnOnFullScreen(userTile)) {
        // Make sure we already subscribed to the full screen tile
        if (userTile.participant$.getValue()) {
          this.videoCallTracksStateService.updateTrackSubscription(
            userTile.participant$.getValue()?.participantId,
            userTile.track$.getValue(),
            true,
            this.konvaService.isPopoutVideoActive,
            userTile.participant$.getValue().local,
          );
        }
        this.sessionSharedDataService.changeRightPanelView.next(undefined);
        this.sessionSharedDataService.changeLeftPanelView.next(undefined);
      }
      if (userTile.participant$.getValue()) {
        this.sessionSharedDataService.sessionView.switchToSessionView(SessionView.FULLSCREEN, {
          tileData: {
            trackType: userTile.track$.getValue(),
            participantId: userTile.participant$.getValue()?.participantId,
            userId: userTile.user$.getValue()._id,
          },
          fullscreen,
          id: uuidv4(),
        });
      }
    }
  }

  private displayReconnectedToCallNotification() {
    if (
      this.notificationToasterService.checkIfNotificationActiveByCode(ERRORS.RECONNECTING_TO_CALL)
    ) {
      this.notificationToasterService.dismissNotificationsByCode([ERRORS.RECONNECTING_TO_CALL]);
      const title = [undefined, this.translateService.instant('Reconnected to call')];
      const titleElement = new IconMessageToasterElement(...title);
      const messageElement = new IconMessageToasterElement(
        undefined,
        this.translateService.instant('Your connection has been restored!'),
      );
      const successNotificationData = new NotificationDataBuilder(SUCCESSES.RECONNECTED_TO_CALL)
        .style(ToasterPopupStyle.SUCCESS)
        .type(NotificationType.SUCCESS)
        .timeOut(5)
        .topElements([titleElement])
        .middleElements([messageElement])
        .dismissable(true)
        .build();
      this.notificationToasterService.showNotification(successNotificationData);
    }
  }

  private displayFirstConnectionToCallNotification() {
    // TODO to be implmented when mocks are ready
  }

  // logic to access the temporaryUserMetadata for the raise hand state
  checkEmoteOnReconnect() {
    this.spaceMetadataSubscription?.unsubscribe();
    const metadataObservable = this.temporaryUserMetadataRepositoryService.spaceTemporaryMetadata$;
    this.spaceMetadataSubscription = metadataObservable
      ?.pipe(untilDestroyed(this))
      .subscribe(async (metadata) => {
        if (!metadata) {
          return;
        }

        // Iterate through all users
        metadata.forEach((tempData) => {
          if (tempData.raiseHand === true && tempData.userId === this.user?._id) {
            const event = new SessionEvent(EventCategory.Users, 'users:raise-hand', true);
            this.sessionSharedDataService.sessionEventController?.sendEvent(this, event);
            return;
          }
        });
      });
  }

  resetSessionViewAfterCallDisconnect(callConnected: boolean) {
    if (
      !callConnected &&
      !this.isLeaderModeActive$.getValue() &&
      this.sessionSharedDataService.sessionView.getSessionView() !== SessionView.FULLSCREEN_APP
    ) {
      this.sessionSharedDataService.sessionView.switchToSessionView(
        SessionView.WHITEBOARD,
        undefined,
      );
    }
  }

  resetSessionViewAfterLeaderModeIsDisabled(otherLeader: boolean) {
    if (
      !otherLeader &&
      !this.rtcConnected &&
      this.sessionSharedDataService.sessionView.getSessionView() !== SessionView.FULLSCREEN_APP &&
      this.sessionSharedDataService.sessionView.getSessionView() !== SessionView.BREAKOUT_ROOMS
    ) {
      this.sessionSharedDataService.sessionView.switchToSessionView(
        SessionView.WHITEBOARD,
        undefined,
      );
    }
  }

  setEmoteExpiry(userTile: UserTile, isExpandedScreen: boolean) {
    // Clear emote in case sender loses network connection
    if (!this.showOtherParticipantsEmotes) {
      return;
    }
    const userId = userTile.user$.getValue()._id;
    if (this.emotesExpiryTimerMap.has(userId)) {
      clearTimeout(this.emotesExpiryTimerMap.get(userId)!);
    }

    this.emotesExpiryTimerMap.set(
      userId,
      modifiedSetTimeout(() => {
        if (
          isExpandedScreen &&
          this.expandedScreenUser$.getValue()?.user$.getValue()._id !== userId
        ) {
          return;
        }
        userTile.emojiId$.next(-1);
      }, 5000),
    );
  }

  private setupVideoPanelSizeObservable() {
    this.containerResizeObserver = new ResizeObserver(() => {
      this.getVideoSectionWidth();
    });
    const videoSectionWidth = document.getElementById('parent-container');
    if (videoSectionWidth) {
      this.containerResizeObserver.observe(videoSectionWidth);
    }
  }

  private setupRightSessionsPanelSizeObservable() {
    this.rightSessionsPanelResizeObserver = new ResizeObserver(() => {
      this.getVideoSectionWidth();
    });
    const videoSectionWidth = document.getElementById('right-sessions-panel');
    if (videoSectionWidth) {
      this.rightSessionsPanelResizeObserver.observe(videoSectionWidth);
    }
  }

  private getVideoSectionWidth(showAnalytics?: boolean) {
    const videoSectionWidth = document.getElementById('parent-container')?.offsetWidth || 0;
    const rightSessionsPanelWidth =
      document.getElementById('right-sessions-panel')?.offsetWidth || 0;
    const toarsterPossition = videoSectionWidth + rightSessionsPanelWidth;

    if (
      !this.callConnected ||
      (this.callConnected &&
        (this.currentSessionView$.getValue() === SessionView.GALLERY_VIEW ||
          this.currentSessionView$.getValue() === SessionView.EXPANDED))
    ) {
      return this.updateToasterHorizontalPosition(rightSessionsPanelWidth + 8);
    }

    if (
      !this.isMobileView() &&
      this.callConnected &&
      (this.currentSessionView$.getValue() === SessionView.WHITEBOARD ||
        this.currentSessionView$.getValue() === SessionView.FULLSCREEN_APP) &&
      (this.sessionSharedDataService.controlsLayout.value === VideoLayout.RIGHT ||
        this.sessionSharedDataService.controlsLayout.value === VideoLayout.MINIMIZED) &&
      !showAnalytics
    ) {
      return this.updateToasterHorizontalPosition(toarsterPossition + 16);
    } else {
      return this.updateToasterHorizontalPosition(8);
    }
  }

  private updateToasterHorizontalPosition(value: number) {
    document.documentElement.style.setProperty('--toaster-horizontal-position', `${value}px`);
  }

  public activateVideoTowerResizer(el: HTMLElement) {
    if (
      this.currentSessionView$.getValue() !== SessionView.WHITEBOARD &&
      this.currentSessionView$.getValue() !== SessionView.FULLSCREEN_APP
    ) {
      return;
    }
    this.domListener.resumeListeningToEvent(
      window,
      'mousemove',
      (event) => this.mouseMoveHandler(event, el),
      true,
    );
    this.domListener.resumeListeningToEvent(window, 'mouseup', () => this.mouseUpHandler(), true);
  }

  mouseUpHandler = () => {
    document.body.style.cursor = 'auto';
    document.body.style.userSelect = 'auto';
    this.domListener.pauseListeningToEvent(window, 'mousemove');
    this.domListener.pauseListeningToEvent(window, 'mouseup');
  };

  mouseMoveHandler = (e: MouseEvent, el: HTMLElement) => {
    if (this.isMobileView() || !this.sessionSharedDataService.isAppInWhiteboardView()) {
      return;
    }
    document.body.style.cursor = 'ew-resize';
    document.body.style.userSelect = 'none';
    const maxWidthPercentage = window.innerWidth * 0.5;
    let mousePositionPercentage = e.clientX + 8;

    if (this.directionForLocale$.getValue() === 'ltr') {
      mousePositionPercentage = window.innerWidth - mousePositionPercentage;
    }

    if (mousePositionPercentage > maxWidthPercentage) {
      mousePositionPercentage = maxWidthPercentage;
    }
    if (mousePositionPercentage < this.resizedRightViewMinWidth$.getValue()) {
      mousePositionPercentage = this.resizedRightViewMinWidth$.getValue();
    }
    el.style.width = `${mousePositionPercentage}px`;
  };

  private displayFinishedRecordingModal(
    recordingOutput: RecordingOutput,
  ): Observable<File | undefined> {
    return new Observable((subscriber) => {
      matchI(recordingOutput)({
        file: ({ file }) => {
          // If the user is a guest assume save the file
          const recorderDialogParams = {
            width: '400px',
            minHeight: '306px',
            panelClass: 'record-wb-dialog',
            disableClose: true,
            data: {
              file,
              user: this.user,
              title: this.spaceRepo.activeSpace?.title ?? '',
              reason: recordingOutput.reason,
            },
          };

          this.modalManagerService.showModal(WbRecorderComponent, recorderDialogParams, {
            afterClosed: (pencilFilesUpload: File | undefined) => {
              if (!pencilFilesUpload) {
                this.spaceRecordingService.clearRecordingOutput();
                subscriber.next(undefined);
                subscriber.complete();
              } else {
                subscriber.next(pencilFilesUpload);
                subscriber.complete();
              }
            },
          });
        },
        none: () => {
          subscriber.next(undefined);
          subscriber.complete();
        },
      });
    });
  }

  uploadRecordingToVimeo(file: File): void {
    this.uploadService.incrementUploadCounter();
    const uploadId = uuidv4();

    this.vimeoUploadService
      .createVideo(file)
      .pipe(untilDestroyed(this))
      .subscribe(async (response) => {
        try {
          const { progress, uploadTask, uploadCompleted } =
            this.vimeoUploadService.tusUploadPromise(file, response.body.upload.upload_link);

          if (!uploadTask) {
            throw new Error('No upload task received');
          }

          // This just creates the UI for the upload
          this.uploadService.createUploadTaskObjects(
            uploadId,
            file,
            progress.pipe(map((x) => ({ progress: x }))),
            () => uploadTask.abort(),
          );

          uploadTask.start();

          await uploadCompleted;

          this.createRecordingResource({
            url: response.body.link,
            name: file.name,
            size: file.size,
          });
        } catch (error) {
          this.uploadService.showUploadFailedNotification(
            UploadFailedNotificationStatus.VIMEO_UPLOAD_FAILED,
          );
          this.uploadService.cancelUpload(uploadId);
        } finally {
          this.uploadService.decrementUploadCounter();
        }
      });
  }

  private createRecordingResource(fileDetails: FileDetails) {
    const resource = {
      url: fileDetails.url,
      name: fileDetails.name,
      size: fileDetails.size,
      metadata: {
        topics_ids: [],
        minGrade: 0,
        maxGrade: 10,
      },
      type: ResourceItemModel.VIDEO,
      acl: { public: false, visibility: 'CLASS' },
    };
    this.resourcesService
      .createResource(resource)
      .pipe(untilDestroyed(this))
      .subscribe(async (res) => {
        if (res.ok && res.body && this.user && this.spaceRepo.activeSpace) {
          await firstValueFrom(
            this.spacesService.sendRecordingURL(
              this.spaceRepo.activeSpace._id,
              this.user._id,
              res.body.resource._id,
            ),
          );

          // remove the file to indicate the upload is finished
          this.spaceRecordingService.clearRecordingOutput();
        }
      });
  }

  isMobileView() {
    return this.uiService.isMobile.value;
  }
}

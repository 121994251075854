import { Injectable, NgZone, Renderer2, RendererFactory2 } from '@angular/core';
import { DomListener } from '../utilities/DomListener';

@Injectable({
  providedIn: 'root',
})
export class DomListenerFactoryService {
  private renderer2: Renderer2;

  // A workaround to inject Renderer2 inside a service according to the SO answer
  // https://stackoverflow.com/questions/34710408/renderer-cannot-be-used-in-service
  constructor(private zone: NgZone, rendererFactory2: RendererFactory2) {
    this.renderer2 = rendererFactory2.createRenderer(null, null);
  }

  createInstance(): DomListener {
    return new DomListener(this.renderer2, this.zone);
  }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Site, User } from 'src/app/models/user';
import { AclService } from 'src/app/services/acl.service';
import { LEARN_MORE_ABOUT_SITES } from 'src/app/utilities/sites.utils';

interface SelectSiteDialogData {
  user: User;
}

@Component({
  selector: 'app-select-site-dialog',
  templateUrl: './select-site-dialog.component.html',
  styleUrls: ['./select-site-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSiteDialogComponent {
  LEARN_MORE_ABOUT_SITES = LEARN_MORE_ABOUT_SITES;

  form: UntypedFormGroup;
  availableSites?: Site[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private aclService: AclService,
    private dialogRef: MatDialogRef<SelectSiteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: SelectSiteDialogData,
  ) {
    this.form = this.formBuilder.group({
      siteId: [null, Validators.required],
    });
    this.availableSites = this.aclService.getUserSites(this.data.user);
  }

  submit() {
    this.dialogRef.close({ siteId: this.form.value?.siteId });
  }
}

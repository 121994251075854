import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { animationFrameScheduler, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { modifiedTimer } from '../utilities/ZoneUtils';

@Pipe({
  name: 'relativeClock',
})
export class RelativeClockPipe implements PipeTransform {
  // Optionally specify an update interval in seconds.
  transform(date: string, interval = 1): Observable<string> {
    /* Not 100% sure if animationFrameScheduler is the best choice here, but the
     * default asyncScheduler delays the date from appearing until after the
     * first render.
     */
    return modifiedTimer(0, 1000 * interval, animationFrameScheduler).pipe(
      map(
        () =>
          `${moment(date).diff(moment(), 'minutes')}:${String(
            moment(date).diff(moment(), 'seconds') % 60,
          ).padStart(2, '0')}`,
      ),
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { last } from 'lodash-es';
import { idEquals, isContentMessage } from '../common/utils/messaging';
import { MessageGroup, MessageType, TypedMessage } from '../models/messaging';

/* Transforms a list of messages for rendering. Consecutive content messages
 * sent by the same author are combined into a single group. The author profile
 * photo is displayed once for the entire group, and only the timestamp of the
 * most recent message in the group is displayed. Metadata messages get their
 * own group and are rendered differently from content messages.
 */

@Pipe({
  name: 'messageGroups',
})
export class MessageGroupsPipe implements PipeTransform {
  transform(messages: TypedMessage[]): MessageGroup[] {
    return messages.reduce((accumulator: MessageGroup[], message) => {
      if (isContentMessage(message)) {
        const currentGroup = last(accumulator);
        if (
          currentGroup &&
          isContentMessage(currentGroup) &&
          idEquals(currentGroup.author)(message.author)
        ) {
          currentGroup.messages.push(message);
        } else {
          accumulator.push({
            messageType: MessageType.Content,
            author: message.author,
            messages: [message],
            mostRecentTimestamp: message.firstAttemptTimestamp || message.createdAt,
          });
        }
        return accumulator;
      } else {
        accumulator.push(message);
        return accumulator;
      }
    }, []);
  }
}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { percentage } from '@angular/fire/storage';
import { Course } from '../../models/user';
import { UploadFileService } from '../../services/upload-file.service';
import { CourseArchiveConfirmDialogComponent } from '../course-archive-confirm-dialog/course-archive-confirm-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-course-details-form',
  templateUrl: './details-form.component.html',
  styleUrls: ['./details-form.component.scss'],
})
export class CourseDetailsFormComponent implements OnInit {
  form?: UntypedFormGroup;
  uploadingImage = false;
  uploadImageName = '';
  uploadPercentage?: number | undefined;

  @Input()
  course?: Course;

  @Input()
  isLoading?: false;

  @Input()
  allowArchive?: false;

  @Output()
  handleSubmit = new EventEmitter<any>();

  @Output()
  handleArchiveCourse = new EventEmitter<any>();

  constructor(
    private uploadService: UploadFileService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
  ) {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
      image_url: [''],
    });
  }

  ngOnInit(): void {
    if (this.course) {
      this.form?.patchValue({
        name: this.course.name,
        description: this.course.description,
        image_url: this.course.image_url,
      });
    }
  }

  uploadImage(event): void {
    if (event.target.files && event.target.files[0]) {
      this.uploadingImage = true;
      this.uploadImageName = event.target.files[0].name;
      const reader = new FileReader();
      const task = this.uploadService.uploadToFireStorage(event.target.files[0], (data) => {
        this.form?.patchValue({ image_url: data });
        this.form?.markAsDirty();
        this.uploadingImage = false;
      });
      if (task) {
        percentage(task)
          .pipe(untilDestroyed(this))
          .subscribe((data) => {
            this.uploadPercentage = data.progress;
          });
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }

  onSubmit(): void {
    if (!this.form) {
      return;
    }

    this.handleSubmit.emit(this.form.value);

    this.form.markAsUntouched();
    this.form.markAsPristine();
  }

  onArchiveCourse(): void {
    const dialogRef = this.dialog.open(CourseArchiveConfirmDialogComponent, {
      width: '600px',
      maxWidth: 'calc(100% - 130px)',
      panelClass: 'course-archive-confirm-dialog',
      data: {
        name: this.course?.name,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(async (res) => {
        if (res) {
          this.handleArchiveCourse.emit();
        }
      });
  }
}

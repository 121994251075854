import { Injectable } from '@angular/core';
import { CallProvider } from '../common/interfaces/rtc-interface';
import { FlagsService } from './flags.service';
import { DeviceAndBrowserDetectorService } from './device-and-browser-detector.service';

const DEFAULT_CONFIG = {
  livekit: {
    mobile: true,
    tablet: true,
    desktop: true,
  },
  daily: {
    mobile: true,
    tablet: true,
    desktop: true,
  },
};

type ConfigType = typeof DEFAULT_CONFIG;

@Injectable({
  providedIn: 'root',
})
export class NoiseCancellationConfigurationService {
  constructor(
    private flagsService: FlagsService,
    private deviceAndBrowserDetectorService: DeviceAndBrowserDetectorService,
  ) {}

  isEnabled(provider: CallProvider): boolean {
    const config: ConfigType =
      (this.flagsService.featureFlagsVariables?.enable_noise_cancellation?.noise_cancellation_config as any as
        | ConfigType
        | undefined) || DEFAULT_CONFIG;
    const providerConfig = config[provider];
    let deviceEnabled: boolean;
    if (this.deviceAndBrowserDetectorService.isMobile()) {
      deviceEnabled = providerConfig.mobile;
    } else if (this.deviceAndBrowserDetectorService.isTabletDevice()) {
      deviceEnabled = providerConfig.tablet;
    } else {
      deviceEnabled = providerConfig.desktop;
    }

    return deviceEnabled;
  }
}

import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[focus]',
})
export class FocusDirective implements AfterContentInit {
  @Input() focus = true;

  constructor(private el: ElementRef) {}

  ngAfterContentInit(): void {
    if (this.focus) {
      this.el.nativeElement.focus();
    }
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Subscription, throwError, timeout, TimeoutError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { v4 as uuid } from 'uuid';

export enum DownloadStatusFlag {
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETED,
  CANCELLED,
  ERROR,
  TIMEOUT,
}

export interface DownloadStatus {
  progress: undefined | number;
  response: undefined | File;
  downloadStatus: DownloadStatusFlag;
}

const DOWNLOAD_TASK_TIMEOUT_MS = 10000;

export class DownloadTask {
  _currentTask: Subscription;
  status = new BehaviorSubject<DownloadStatus>({
    progress: undefined,
    response: undefined,
    downloadStatus: DownloadStatusFlag.NOT_STARTED,
  });
  id: string;

  constructor(
    remoteURL: string,
    requestHeaders: object,
    fileName: string,
    fileMime: string,
    fileUUID: string,
  ) {
    this.id = fileUUID;
    this._currentTask = ajax({
      url: remoteURL,
      method: 'GET',
      responseType: 'blob',
      withCredentials: false,
      crossDomain: true,
      headers: requestHeaders,
    })
      .pipe(
        timeout(DOWNLOAD_TASK_TIMEOUT_MS),
        catchError((error) => {
          this.status.error({
            downloadStatus:
              error instanceof TimeoutError ? DownloadStatusFlag.TIMEOUT : DownloadStatusFlag.ERROR,
            progress: undefined,
            response: undefined,
          });
          return throwError(() => {
            // thrown an error
          });
        }),
      )
      .subscribe((event) => {
        if (event.type === 'download_progress') {
          this.status.next({
            downloadStatus: DownloadStatusFlag.IN_PROGRESS,
            progress: event.loaded / event.total,
            response: undefined,
          });
        } else if (event.type === 'download_load') {
          this.status.next({
            downloadStatus: DownloadStatusFlag.COMPLETED,
            progress: event.loaded / event.total,
            response: new File([event.response as Blob], fileName, { type: fileMime }),
          });
          this.status.complete();
        }
      });
  }

  cancel() {
    this._currentTask.unsubscribe();
    this.status.error({
      downloadStatus: DownloadStatusFlag.CANCELLED,
      progress: undefined,
      response: undefined,
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class DownloadFileService {
  downloadFromURL(
    remoteURL: string,
    requestHeaders: object,
    fileName: string,
    fileMime: string,
  ): DownloadTask {
    return new DownloadTask(remoteURL, requestHeaders, fileName, fileMime, uuid());
  }
}

import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import {
  addEntities,
  selectAllEntitiesApply,
  withEntities,
  withUIEntities,
  updateEntitiesByPredicate,
  deleteEntitiesByPredicate,
  getEntity,
  setEntities,
} from '@ngneat/elf-entities';
import { combineLatest, map, Observable } from 'rxjs';

import { Collaboration } from '@pncl/common-models';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {
  ButtonToasterElement,
  ButtonToasterElementStyle,
} from '../ui/notification-toaster/button-toaster-element/button-toaster-element.component';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from '../services/notification-toaster.service';
import { ToasterPopupStyle } from '../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { intercomArticles } from '../intercom-articles';
import { ISession, SpeechService } from '../models/session';
import { FLAGS, FlagsService } from '../services/flags.service';

import { INFOS } from '../common/utils/notification-constants';
import {
  IconBackground,
  IconMessageToasterElement,
} from '../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { ISpaceUI } from './space.repository';

import Transcription = Collaboration.Transcription;

export type SpaceTranscriptItems = Array<Transcription.SpaceTranscriptItem>;
export type SpaceTranscriptItem = Transcription.SpaceTranscriptItem;
export type ChatContextType = Transcription.ChatContextType;

export interface TranscriptGroup {
  date?: string;
  transcripts: SpaceTranscriptItems;
  mostRecentTimestamp: string;
}

export interface SpaceTranscriptProps {
  spaceTranscript: boolean;
  spaceCallConnected: boolean;
  unreadSpacesTranscripts: boolean;
  speechService: SpeechService;
}

export interface TranscriptUiEntity {
  _id: string;
  total: number;
}

@Injectable({
  providedIn: 'root',
})
export class TranscriptRepository {
  private readonly store = createStore(
    { name: 'transcripts' },
    withEntities<Transcription.SpaceTranscriptItem, '_id'>({ idKey: '_id' }),
    withUIEntities<TranscriptUiEntity, '_id'>({ idKey: '_id' }),
    withProps<SpaceTranscriptProps>({
      spaceTranscript: false,
      spaceCallConnected: false,
      unreadSpacesTranscripts: false,
      speechService: SpeechService.WEB_SPEECH_API,
    }),
  );

  private activeSpace?: ISession & ISpaceUI;

  constructor(
    private flagsService: FlagsService,
    private translateService: TranslateService,
    private notificationToasterService: NotificationToasterService,
  ) {}

  setupSpaceTranscripts(space: ISession & ISpaceUI): void {
    if (
      this.activeSpace?._id !== space._id ||
      space.sessionPermissions.spaceTranscript !==
        this.activeSpace.sessionPermissions.spaceTranscript ||
      this.activeSpace.speechService !== space.speechService
    ) {
      this.activeSpace = space;
      this.updateSpaceTranscriptionStatus(this.activeSpace.sessionPermissions.spaceTranscript);
      this.updateTranscriptionSpeechService(this.activeSpace.speechService);
    }
  }

  updateSpaceTranscriptionStatus(val: boolean): void {
    this.store.update((store) => ({
      ...store,
      spaceTranscript: val,
    }));
  }

  updateSpaceCallConnected(val: boolean): void {
    this.store.update((store) => ({
      ...store,
      spaceCallConnected: val,
    }));
  }

  updateTranscriptionSpeechService(val: SpeechService): void {
    this.store.update((store) => ({
      ...store,
      speechService: val,
    }));
  }

  getTranscripts(
    filter?: (data: Transcription.SpaceTranscriptItem) => boolean,
  ): Observable<SpaceTranscriptItems> {
    return this.store
      .pipe(
        selectAllEntitiesApply({
          filterEntity: filter,
        }),
      )
      .pipe(
        map((x) =>
          x.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()),
        ),
      );
  }

  setSpaceTranscripts(res: Transcription.TranscriptionsResponse): void {
    this.store.update(setEntities(res.transcriptions));
  }

  getTranscriptItem(id: string): SpaceTranscriptItem | undefined {
    return this.store.query(getEntity(id));
  }

  addSpaceTranscriptItem(transcriptItem: Transcription.SpaceTranscriptItem): void {
    this.store.update(addEntities(transcriptItem));
    this.updateSpaceTranscriptUnreadStatus(true);
  }

  addSpaceTranscriptItems(transcriptItem: SpaceTranscriptItems): void {
    this.store.update(addEntities(transcriptItem));
  }

  updateSpaceTranscriptUnreadStatus(val: boolean): void {
    this.store.update((store) => ({ ...store, unreadSpacesTranscripts: val }));
  }

  getSpaceUnreadTranscripts(): Observable<boolean> {
    return this.store.pipe(select((state) => state.unreadSpacesTranscripts));
  }

  canShowTranscriptionUI(): Observable<boolean> {
    const spaceTranscriptionFlag$ = this.flagsService.featureFlagChanged(
      FLAGS.SPACES_TRANSCRIPTION,
    );

    return combineLatest({
      spaceTranscriptActive: this.store.pipe(select((state) => state.spaceTranscript)),
      spaceTranscriptionFlag: spaceTranscriptionFlag$,
    }).pipe(map((res) => res.spaceTranscriptActive || res.spaceTranscriptionFlag));
  }

  canStartTranscription(): Observable<SpeechService | null> {
    const featureFlagSpeechServiceType = this.flagsService.featureFlagsVariables[
      FLAGS.SPACES_TRANSCRIPTION
    ]?.transcription_service as SpeechService;

    const currentSpeechServiceType = this.store.getValue().speechService;

    return combineLatest({
      spaceTranscript: this.store.pipe(select((state) => state.spaceTranscript)),
      spaceCallConnected: this.store.pipe(select((state) => state.spaceCallConnected)),
      currentSpeechServiceType: this.store.pipe(select((state) => state.speechService)),
    }).pipe(
      map((res) => {
        if (res.spaceTranscript && res.spaceCallConnected) {
          return featureFlagSpeechServiceType === SpeechService.DEEPGRAM
            ? featureFlagSpeechServiceType
            : currentSpeechServiceType;
        } else {
          return null;
        }
      }),
    );
  }

  get spaceTranscriptActive$(): Observable<boolean> {
    return combineLatest({
      spaceCallConnected: this.store.pipe(select((state) => state.spaceCallConnected)),
      spaceTranscript: this.store.pipe(select((state) => state.spaceTranscript)),
    }).pipe(
      map(({ spaceCallConnected, spaceTranscript }) => spaceCallConnected && spaceTranscript),
      tap(this.handleTranscriptionNotification.bind(this)),
    );
  }

  get transcriptionsSpeechService$(): Observable<SpeechService> {
    return this.store.pipe(select((state) => state.speechService));
  }

  get transcriptionsStatus$(): Observable<boolean> {
    return this.store.pipe(select((state) => state.spaceTranscript));
  }

  editTranscriptItem(
    predicate: (data: SpaceTranscriptItem) => boolean,
    data: Partial<SpaceTranscriptItem>,
  ): void {
    this.store.update(updateEntitiesByPredicate(predicate, { ...data, lastModified: new Date() }));
  }

  deleteTranscriptItem(predicate: (data: SpaceTranscriptItem) => boolean): void {
    this.store.update(deleteEntitiesByPredicate(predicate));
  }

  private handleTranscriptionNotification(isTranscriptionActive: boolean) {
    if (isTranscriptionActive) {
      this.showCallTranscriptionNotification();
    } else {
      this.notificationToasterService.dismissNotificationsByCode([INFOS.TRANSCRIPTION_ACTIVE]);
    }
  }

  private showCallTranscriptionNotification(): void {
    const titleElement = new IconMessageToasterElement(
      {
        icon: 'record_voice_over',
        size: 16,
      },
      this.translateService.instant('Transcription enabled'),
      undefined,
      undefined,
      undefined,
      IconBackground.INFO,
      true,
      true,
    );

    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(
        'By staying in this call, you consent to this call being automatically transcribed.',
      ),
    );

    const actionButton = new ButtonToasterElement(
      [undefined, this.translateService.instant('Learn more')],
      {
        handler: () => {
          window.open(intercomArticles.WhatDoesItMeanIfTranscriptionIsActiveOnMyCall);
        },
        close: false,
      },
      ButtonToasterElementStyle.LINK,
    );

    this.notificationToasterService.showNotification(
      new NotificationDataBuilder(INFOS.TRANSCRIPTION_ACTIVE)
        .style(ToasterPopupStyle.WARN)
        .type(NotificationType.WARNING)
        .topElements([titleElement])
        .middleElements([messageElement])
        .bottomElements([actionButton])
        .width(304)
        .dismissable(true)
        .timeOut(15)
        .priority(790)
        .version2Notification(true)
        .build(),
    );
  }
}

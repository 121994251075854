<div class="bg-light border-bottom cpt-16 cpb-12">
  <p class="text-center fw-6">{{ 'Formula editor' | translate }}</p>
</div>
<div #katextcontainer class="katex-container d-flex flex-column align-items-center cpx-16 flex-1">
  <div class="w-75 border-bottom katex-value" style="height: 70px">
    <ng-katex
      [equation]="katex"
      [options]="{ throwOnError: false, displayMode: true }"
      #katexRenderer
    ></ng-katex>
  </div>
  <div class="buttons d-flex justify-content-center w-75 cmt-12 cmb-24">
    <button
      *ngIf="!isMobile"
      mat-button
      class="latex-btn"
      [class.selected]="mode === Modes.Write"
      (click)="mode = Modes.Write"
      data-name="math-tool-option-keyboard"
    >
      <i class="material-icons">keyboard</i>
      {{ 'Keyboard' | translate }}
    </button>
    <button
      mat-button
      class="latex-btn"
      [class.selected]="mode === Modes.Paste"
      (click)="mode = Modes.Paste"
      data-name="math-tool-option-latex"
    >
      <i class="material-icons">code</i>
      {{ 'LaTeX' | translate }}
    </button>
    <button
      mat-button
      class="latex-btn"
      [class.selected]="mode === Modes.Draw"
      (click)="activeDrawingMode()"
      data-name="math-tool-option-drawing"
    >
      <i class="material-icons">gesture</i>
      {{ 'Draw' | translate }}
    </button>
  </div>
  <div class="flex-1 position-relative h-100 drawing-container" [class.error]="isKatexError">
    <p class="placeholder cmb-4" *ngIf="mode === Modes.Write && !katex">
      {{ 'Type your equation...' | translate }}
    </p>
    <ng-container *ngIf="mode === Modes.Draw">
      <p class="placeholder cmb-4" *ngIf="!katex">
        {{ 'Draw anywhere...' | translate }}
      </p>
      <p class="placeholder myscript-sign">
        {{ 'Powered by MyScript®' | translate }}
      </p>
    </ng-container>

    <div
      id="math-field"
      class="w-100"
      [style.visibility]="mode === Modes.Write ? 'visible' : 'hidden'"
    >
      {{ katex }}
    </div>
    <div
      id="editor"
      [style.visibility]="mode === Modes.Draw ? 'visible' : 'hidden'"
      touch-action="none"
      #tref
    >
      <div></div>
    </div>
    <div *ngIf="isMyscriptDisconnected" class="myscript-overlay">
      <button ui-primary (click)="reconnectMyscript()">Reconnect to draw formula</button>
    </div>
    <textarea
      *ngIf="mode === Modes.Paste"
      [placeholder]="'Type your Latex code ...' | translate"
      [(ngModel)]="katex"
      (keyup)="escapeLatexDelimeter()"
      (change)="updateMathField()"
    ></textarea>
    <button
      class="btn-clean"
      [class.disabled]="!myscript?.canClear"
      [disabled]="!myscript?.canClear"
      (click)="myscript.clear()"
      *ngIf="mode === Modes.Draw"
      data-name="math-tool-option-drawing-clear"
    >
      {{ 'Clear' | translate }}
    </button>
    <button
      class="btn-clean"
      [class.disabled]="!myscript?.canRedo"
      [disabled]="!myscript?.canRedo"
      style="margin-right: 50px"
      (click)="myscript.redo()"
      *ngIf="mode === Modes.Draw"
      data-name="math-tool-option-drawing-redo"
    >
      {{ 'Redo' | translate }}
    </button>
    <button
      class="btn-clean"
      [class.disabled]="!myscript?.canUndo"
      [disabled]="!myscript?.canUndo"
      style="margin-right: 100px"
      (click)="myscript.undo()"
      *ngIf="mode === Modes.Draw"
      data-name="math-tool-option-drawing-undo"
    >
      {{ 'Undo' | translate }}
    </button>
  </div>
</div>

<div class="d-flex justify-content-end cp-16 footer-buttons">
  <button
    class="btn btn-light cmr-sm-16 cmx-0 cmt-16 cmt-sm-0 fw-6 d-flex align-items-center"
    (click)="dialogRef.close()"
    data-name="math-tool-close"
  >
    <i class="material-icons cmr-8 font-18">close</i> {{ 'Cancel' | translate }}
  </button>
  <button
    class="btn btn-primary fw-6 d-flex align-items-center text-white"
    [disabled]="!katex"
    (click)="doneClick()"
    cdkFocusInitial
    data-name="math-tool-done"
  >
    <i class="material-icons cmr-8 font-18">done</i>
    {{ 'Done' | translate }}
  </button>
</div>

<div class="confirm-dialog">
  <div class="clone-dialog-title">
    {{ 'Duplicate Space' | translate }}
  </div>
  <div class="msg">
    <div>{{ 'Are you sure you want to duplicate this Space?' | translate }}</div>
    <br />
    <div>
      {{
        'To ensure apps are copied correctly, please make sure that no one is present in your Space, and that your Space has been inactive for at least 15 minutes.'
          | translate
      }}
    </div>
  </div>
  <div class="confirm-buttons">
    <app-pencil-button
      class="pencil-button light medium"
      (clickEvent)="finish(false)"
      icon="close"
      [iconConfig]="{ type: IconTypes.matIcon }"
      [label]="'Cancel' | translate"
    >
    </app-pencil-button>
    <app-pencil-button
      class="pencil-button primary medium"
      (click)="finish(true)"
      icon="check"
      [iconConfig]="{ type: IconTypes.matIcon }"
      [label]="'Confirm' | translate"
    >
    </app-pencil-button>
  </div>
</div>

<mat-icon
  *ngIf="icon?.icon"
  class="notification-mat-icon"
  [ngStyle]="{
    'font-size': icon?.size + 'px',
    width: icon?.size + 'px',
    height: icon?.size + 'px',
    'line-height': icon?.size + 'px'
  }"
  [ngClass]="{ 'no-margin': removeMargin, 'material-icons-outlined': outlined || icon?.outlined }"
  >{{ icon?.icon }}
</mat-icon>
<svg-icon
  class="notification-svg-icon"
  *ngIf="icon?.svgIcon"
  [icon]="icon?.svgIcon"
  [height]="icon?.size"
  [width]="icon?.size"
  [ngClass]="{ 'no-margin': removeMargin }"
></svg-icon>
<app-profile-photo
  class="notification-pfp-icon"
  *ngIf="icon?.user"
  [user]="icon?.user"
  [width]="icon?.size"
  class="cmr-8"
  [isClickEnabled]="false"
  [ngClass]="{ 'no-margin': removeMargin }"
></app-profile-photo>

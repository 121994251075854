import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[DynamicHoverStyle]',
})
export class DynamicHoverStyleDirective implements AfterViewInit {
  @Input() customStyle?: string;
  @Input() className?: string;
  @Input() apply?: boolean;
  style: any;
  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.customStyle) {
      this.style = document.createElement('style');
      this.style.type = 'text/css';
      this.style.innerHTML = this.customStyle;
      this.el.nativeElement.appendChild(this.style);
      this.el.nativeElement.addEventListener('mouseenter', () => {
        if (this.apply) {
          this.el.nativeElement.classList.add(this.className);
        } else {
          this.el.nativeElement.classList.remove(this.className);
        }
      });
      this.el.nativeElement.addEventListener('mouseleave', () => {
        this.el.nativeElement.classList.remove(this.className);
      });
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';
import { WebcamModule } from 'ngx-webcam';
import { MatIconModule } from '@angular/material/icon';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { DragDropSelectFileComponent } from '../sessions/session/upload/drag-drop-select-file/drag-drop-select-file.component';
import { UploadPDFComputerComponent } from '../sessions/session/upload/upload-pdf-computer/upload-pdf-computer.component';
import { UploadPdfPreparingDocumentComponent } from '../sessions/session/upload/upload-pdf-computer/upload-pdf-preparing-document/upload-pdf-preparing-document.component';
import { PreviewPageItemComponent } from '../sessions/session/upload/upload-pdf-computer/pdf-preview-selector/preview-page-item/preview-page-item.component';
import { PdfPreviewSelectorComponent } from '../sessions/session/upload/upload-pdf-computer/pdf-preview-selector/pdf-preview-selector.component';
import { UploadPdfSelectBoardStrategyComponent } from '../sessions/session/upload/upload-pdf-computer/upload-pdf-select-board-strategy/upload-pdf-select-board-strategy.component';
import { LoadPdfAndPreviewComponent } from '../sessions/session/upload/upload-pdf-computer/load-pdf-and-preview/load-pdf-and-preview.component';
import { UploadComponent } from '../dialogs/upload/upload/upload.component';
import { RadioSelectionComponent } from '../standalones/components/radio-selection/radio-selection.component';
import { PencilIconComponent } from '../standalones/components/pencil-icon/pencil-icon.component';
import { PencilButtonComponent } from '../standalones/components/pencil-button/pencil-button.component';
import { SVGIconComponentsModule } from '../common/svg-icon/svg-icon-components.module';
import { FilesExtensionComponent } from '../content/files/files-extension/files-extension.component';
import { ExtendedGridViewComponent } from '../content/files/files-extension/extended-grid-view/extended-grid-view.component';
import { TooltipDirective } from '../standalones/directives/pncl-tooltip.directive';
import { UiModule } from '../ui/ui.module';
import { PipeModule } from '../pipes/pipes.module';
import { ExtendedListViewComponent } from '../content/files/files-extension/extended-list-view/extended-list-view.component';
import { DirectivesModule } from '../directives/directives.module';
import { UploadPanelComponent } from '../sessions/session/upload/upload-panel/upload-panel.component';
import { ChromeCanvasBugWorkaroundDirective } from '../standalones/directives/chrom-canvas-bug-workaround.directive';

@NgModule({
  declarations: [
    DragDropSelectFileComponent,
    UploadPDFComputerComponent,
    UploadPdfPreparingDocumentComponent,
    PreviewPageItemComponent,
    PdfPreviewSelectorComponent,
    UploadPdfSelectBoardStrategyComponent,
    LoadPdfAndPreviewComponent,
    UploadComponent,
    UploadPanelComponent,
    FilesExtensionComponent,
    ExtendedGridViewComponent,
    ExtendedListViewComponent,
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatIconModule,
    TranslateModule,
    NgbTooltipModule,
    ImageCropperModule,
    WebcamModule,
    InfiniteScrollModule,
    RadioSelectionComponent,
    PencilIconComponent,
    PencilButtonComponent,
    SVGIconComponentsModule,
    TooltipDirective,
    UiModule,
    PipeModule,
    OverlayModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    DirectivesModule,
    ChromeCanvasBugWorkaroundDirective,
  ],
  exports: [UploadComponent, UploadPanelComponent],
})
export class UploadModule {}

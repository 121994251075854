import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DatepickerRange } from '../standalones/components/date-range-picker/date-range-picker.component';
import { DatepickerRangeName } from '../standalones/components/date-range-picker/date-range-picker.constants';

@Pipe({
  name: 'dateRangePickerSelection',
})
export class DateRangePickerSelectionPipe implements PipeTransform {
  transform(datepickerRange: DatepickerRange): string {
    let activeRangeText = datepickerRange.name;

    if (datepickerRange.name === DatepickerRangeName.CUSTOM) {
      activeRangeText += `: ${moment(datepickerRange.range.start).format('DD-MM-YYYY')} - ${moment(
        datepickerRange.range.end,
      ).format('DD-MM-YYYY')}`;
    }
    return activeRangeText;
  }
}

<div class="drop-down-btn-container">
  <button
    mat-flat-button
    [disableRipple]="true"
    class="drop-down-primary-button"
    (click)="onPrimaryButtonClick()"
  >
    <span class="drop-down-primary-button-text">{{ buttonText }}</span>
  </button>
  <button
    class="drop-down-expand-button"
    [ngClass]="{ 'menu-open': isMenuOpened }"
    mat-icon-button
    [disableRipple]="true"
    [matMenuTriggerFor]="menu"
    (click)="isMenuOpened = true"
  >
    <mat-icon class="drop-down-expand-button-icon">{{
      isMenuOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    }}</mat-icon>
  </button>

  <mat-menu
    (closed)="isMenuOpened = false"
    #menu="matMenu"
    class="drop-down-menu"
    xPosition="before"
  >
    <p class="menu-title">{{ dropDownTitle }}</p>

    <button
      mat-flat-button
      class="menu-item"
      *ngFor="let option of items"
      (click)="onMenuItemClick(option)"
    >
      <p class="menu-item-text">{{ option.text }}</p>
    </button>
  </mat-menu>
</div>

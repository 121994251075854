import { VirtualBackgroundType } from 'src/app/common/interfaces/rtc-interface';
import { UploadDialogComponent } from 'src/app/dialogs/upload/upload-dialog.component';
import { ResourceItemModel } from 'src/app/models/resource';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/models/user';
import { Observable, Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { VirtualBackgroundInsertableStreamService } from '../virtual-background-insertable-stream.service';
import { AclService } from '../acl.service';
import { UserService } from '../user.service';
import { ResourcesService } from '../resources.service';
import { UploadFileService } from '../upload-file.service';
import { Libraries } from '../../dialogs/upload/upload/upload.component';

export interface VBImage {
  src: string;
  icon?: string;
  onClick: (vbClickEvent: VBClickEvent) => void;
  active?: boolean;
}

enum CategoryVisibility {
  ME = 'ME',
  CLASS = 'CLASS',
}

export interface VBImageCategory {
  title: string;
  images: VBImage[];
  isDeletable: boolean;
  maxNumberOfImages: number;
  folderId: string;
  visibility: CategoryVisibility;
}

export interface VBClickEvent {
  image: VBImage;
  category: VBImageCategory;
}

@UntilDestroy()
export abstract class VirtualBackgroundPanelBase {
  private readonly MAX_CUSTOM_IMAGE_SIZE_IN_MB = 3;
  private static readonly MAX_NUMBER_OF_IMAGES_PER_CATEGORY = 20;
  private readonly addImageButton = {
    src: '',
    icon: 'add_circle',
    onClick: this.uploadCustomVB.bind(this),
  };
  private vBSubscribers: Subscription[] = [];
  private user?: User;
  private isInstitutionVBEnabled = false;
  private isLoadingResourcesFromBEEnabled = false;

  public vbImagesCategories: VBImageCategory[] = [];

  private readonly VB_IMAGES_KEY = 'VB_PRIVATE_UPLOADED_IMAGES_KEY';
  private readonly PRIVATE_VIRTUAL_BACKGROUND_TITLE = 'OTHER BACKGROUNDS';

  protected _lastVBImage?: VBImage;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private uploadFileService: UploadFileService,
    private resourcesService: ResourcesService,
    private userService: UserService,
    private aclService: AclService,
    protected virtualBackgroundInsertableStreamService: VirtualBackgroundInsertableStreamService,
  ) {
    // Institutions virtual backgrounds is not enabled.
    if (this.isInstitutionVBEnabled) {
      this.setupVBInstitutionCategory();
    }
    this.setupVBPrivateCategory();
  }

  ngOnDestroy(): void {
    this.clearVBSubscribers();
  }

  get virtualBackgroundType(): VirtualBackgroundType {
    return this.virtualBackgroundInsertableStreamService.virtualBackgroundType;
  }

  async setupVBInstitutionCategory(): Promise<void> {
    if (this.user?.institution.virtualBackgroundsFolder === null) {
      const resourcesSub = this.resourcesService
        .createFolder({
          name: "Your Organization's Virtual Backgrounds",
          acl: {
            visibility: CategoryVisibility.CLASS,
            public: false,
          },
        })
        .subscribe((created) => {
          if (created && created.body && this.user) {
            this.user.institution.virtualBackgroundsFolder = created.body._id;
            this.userService.updateInstitution(this.user);
          }
        });
      this.vBSubscribers.push(resourcesSub);
    }

    this.vbImagesCategories.push({
      title: 'FROM YOUR ORGANIZATION',
      images: [],
      isDeletable: false,
      maxNumberOfImages: VirtualBackgroundPanelBase.MAX_NUMBER_OF_IMAGES_PER_CATEGORY,
      folderId: this.user?.institution.virtualBackgroundsFolder ?? '',
      visibility: CategoryVisibility.CLASS,
    });
    this.loadCategoryImages(this.vbImagesCategories[this.vbImagesCategories.length - 1]);
  }

  subscribeCurrUserAdminInstitutionChange() {
    if (!this.isInstitutionVBEnabled) {
      return;
    }

    const userSub = this.userService.user.subscribe((userData) => {
      if (userData?.user) {
        this.user = userData.user;
        const isAdmin = this.aclService.isAdmin(this.user);
        const addButtonExist =
          this.vbImagesCategories[0].images[this.vbImagesCategories[0].images.length - 1] ===
          this.addImageButton;
        if (isAdmin && !addButtonExist) {
          this.vbImagesCategories[0].images.push(this.addImageButton);
        } else if (!isAdmin && addButtonExist) {
          this.vbImagesCategories[0].images.pop();
        }
      }
    });
    this.vBSubscribers.push(userSub);
  }

  async setupVBPrivateCategory(): Promise<void> {
    if (this.user?.virtualBackgroundsFolder === null) {
      const resourcesSub = this.resourcesService
        .createFolder({
          name: 'Your Virtual Backgrounds',
          acl: {
            visibility: CategoryVisibility.ME,
            public: false,
          },
        })
        .subscribe((created) => {
          if (created && created.body && this.user) {
            this.user.virtualBackgroundsFolder = created.body._id;
            this.userService.updateUser(this.user);
          }
        });
      this.vBSubscribers.push(resourcesSub);
    }

    this.vbImagesCategories.push({
      title: this.PRIVATE_VIRTUAL_BACKGROUND_TITLE,
      images: [
        {
          src: '',
          icon: 'add_circle',
          onClick: this.uploadCustomVB.bind(this),
        },
      ],
      isDeletable: false,
      maxNumberOfImages: VirtualBackgroundPanelBase.MAX_NUMBER_OF_IMAGES_PER_CATEGORY,
      folderId: this.user?.virtualBackgroundsFolder ?? '',
      visibility: CategoryVisibility.ME,
    });
    this.loadLocalStorageImages(this.vbImagesCategories[this.vbImagesCategories.length - 1]);
  }

  deleteImage(category: VBImageCategory, src: string) {
    category.images = category.images.filter((img) => img.src != src);

    if (this.isPrivateCategory(category)) {
      this.removeImageFromLocalStorage(src);
    }
  }

  private loadLocalStorageImages(category: VBImageCategory) {
    const list: string[] = JSON.parse(localStorage.getItem(this.VB_IMAGES_KEY) || '[]');
    list.forEach((img) => {
      category.images.push(this.createVbImage(img));
    });
  }

  private removeImageFromLocalStorage(src: string) {
    let list: string[] = JSON.parse(localStorage.getItem(this.VB_IMAGES_KEY) || '[]');
    list = list.filter((it) => it != src);
    localStorage.setItem(this.VB_IMAGES_KEY, JSON.stringify(list));
  }

  private isImageSelected(src: string) {
    return (
      this.virtualBackgroundInsertableStreamService.isVirtualBackgroundMode() &&
      this.virtualBackgroundType == VirtualBackgroundType.BACKGROUND_IMAGE &&
      this.virtualBackgroundInsertableStreamService.lastBackgroundImageSource === src
    );
  }

  private loadCategoryImages(category: VBImageCategory): void {
    if (!this.isLoadingResourcesFromBEEnabled) {
      return;
    }
    const resourcesSub = this.resourcesService.getFolder(category.folderId).subscribe((folder) => {
      const resources = folder.resources.files;
      resources.forEach((resource) => {
        const folderResourcesSub = this.resourcesService
          .getResource(resource._id)
          .subscribe((res) => {
            if (res && res['resource']) {
              category.images.push(this.createVbImage(res['resource'].src));
            }
          });
        this.vBSubscribers.push(folderResourcesSub);
      });
    });
    this.vBSubscribers.push(resourcesSub);
  }

  private isPrivateCategory(category: VBImageCategory) {
    return category.title === this.PRIVATE_VIRTUAL_BACKGROUND_TITLE;
  }

  private addImageSrcToLocalStorage(src: string) {
    const list: string[] = JSON.parse(localStorage.getItem(this.VB_IMAGES_KEY) || '[]');
    list.push(src);
    localStorage.setItem(this.VB_IMAGES_KEY, JSON.stringify(list));
  }

  uploadCustomVB(vbClickEvent: VBClickEvent): void {
    const uploadDialogRef = this.dialog.open(UploadDialogComponent, {
      width: '80%',
      height: '80%',
      panelClass: 'upload-dialog-container',
      data: {
        title: this.translate.instant('Upload Virtual Background'),
        libraries: [Libraries.COMPUTER],
        type: [ResourceItemModel.IMAGE],
      },
    });

    const uploadSub = uploadDialogRef.afterClosed().subscribe((result) => {
      if (result?.files?.[0]?.file) {
        const file = result.files[0].file;
        this.uploadFileService.uploadToFireStorage(
          file,
          (url) => {
            if (this.isPrivateCategory(vbClickEvent.category)) {
              this.addImageSrcToLocalStorage(url);
            }
            vbClickEvent.category.images.push(this.createVbImage(url));
            this.resourcesService.createResource({
              url: url,
              name: file.name,
              size: file.size,
              acl: {
                visibility: vbClickEvent.category.visibility,
              },
              type: ResourceItemModel.IMAGE,
              metadata: {},
              folder: vbClickEvent.category.folderId,
            });
          },
          () => {},
          true,
          this.MAX_CUSTOM_IMAGE_SIZE_IN_MB,
        );
      }
    });
    this.vBSubscribers.push(uploadSub);
  }

  private createVbImage(src: string) {
    const vbImage = {
      src: src,
      onClick: (vbClickEvent: VBClickEvent) => {
        this.handleChangingChoosenImageActiveStatus(vbImage);
        this.setImageBackground(vbClickEvent);
      },
      active: this.isImageSelected(src)
    }
    if (vbImage.active) {
      this._lastVBImage = vbImage;
    }
    return vbImage;
  }

  private clearVBSubscribers() {
    this.vBSubscribers.forEach((subscriber) => subscriber.unsubscribe());
  }

  private handleChangingChoosenImageActiveStatus(vbImage: VBImage) {
    if (this._lastVBImage) {
      this._lastVBImage.active = false;
    }

    vbImage.active = true;
    this._lastVBImage = vbImage;
  }

  abstract setImageBackground(vbClickEvent: VBClickEvent): Promise<void>;

  abstract removeBackground(): Promise<void>;

  abstract blur(): Promise<void>;

  abstract getVideoStream(): Observable<MediaStream | null>;

  abstract isVideoPlaying(): Observable<boolean>;
}

export interface Plan {
  addon_applicability: string;
  applicable_addons?: Array<any>;
  charge_model: string;
  currency_code: string;
  enabled_in_hosted_pages: boolean;
  enabled_in_portal: boolean;
  free_quantity: number;
  giftable: boolean;
  id: string;
  is_shippable: boolean;
  name: string;
  object: string;
  period: number;
  period_unit: string;
  price: number;
  pricing_model: string;
  status: string;
  taxable: boolean;
  description?: string;
}

export interface Addon {
  charge_type: string;
  currency_code: string;
  enabled_in_portal: boolean;
  id: string;
  is_shippable: boolean;
  name: string;
  object: string;
  period: number;
  period_unit: string;
  price: number;
  pricing_model: string;
  resource_version: number;
  show_description_in_invoices: boolean;
  show_description_in_quotes: boolean;
  status: string;
  taxable: boolean;
  type: string;
  unit: string;
  updated_at: number;
}

export interface BillingAddress {
  first_name: string;
  last_name: string;
  line1: string;
  line2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  countryOb?: object;
}

export interface Customer {
  first_name: string;
  last_name: string;
  email: string;
}

export interface PlanSubscription {
  user_id: string;
  plan_id: string;
  currency_code: string;
  period_unit: string;
  auto_renewal: boolean;
  teacherQuantity: number;
  studentQuantity: number;
  billing_address: BillingAddress;
  customer: Customer;
  token_id: string;
  autofill: boolean;
}

export const PlanSubscriptionDefaults: PlanSubscription = {
  user_id: '',
  plan_id: '',
  currency_code: '',
  period_unit: '',
  auto_renewal: true,
  teacherQuantity: 1,
  studentQuantity: 0,
  billing_address: {
    first_name: '',
    last_name: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  customer: {
    first_name: '',
    last_name: '',
    email: '',
  },
  token_id: '',
  autofill: false,
};

export interface PostPayment {
  plan: string;
  amount: number;
  teacherQuantity: number;
  studentQuantity: number;
  currency_code: string;
  billing_period_unit: string;
  next_billing_at: number;
  maskedNumber: string;
  card_type: string;
  first_name: string;
  email: string;
  last_name: string;
  full_name: string;
  line1: string;
  line2: string;
  city: string;
  state_code: string;
  zip: string;
  country: string;
  unbilled_charges: number;
}

export interface Estimate {
  total: number;
  tax_amount: number;
  description: string;
  currency_code: string;
}

export interface Card {
  status: string;
  iin: string;
  last4: string;
  maskedCvv?: string;
  card_type: string;
  expiry: string;
  masked_number: string;
}

export interface CardDetails {
  first_name: string;
  last_name: string;
  billing_address: BillingAddress;
  card: Card;
}

export enum ChargebeeSubscriptionStatus {
  FUTURE = 'future',
  IN_TRIAL = 'in_trial',
  ACTIVE = 'active',
  NON_RENEWING = 'non_renewing',
  PAUSED = 'paused',
  CANCELLED = 'cancelled',
}

export enum SubscriptionStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
}

export enum SUBSCRIPTION_TYPES {
  TRIAL = 'TRIAL',
  PAID = 'PAID',
  FREE = 'FREE',
  ENTERPRISE = 'ENTERPRISE',
}

export enum InstitutionTiers {
  Pro = 'Pro',
  Premium = 'Premium',
  Custom = 'Custom',
}

export enum SubscriptionEvent {
  subscription_updated_manually = 'subscription_updated_manually',
}

export interface BillingStatus {
  subscription_status?: SubscriptionStatus;
  subscription?: string | Subscription;
  customerId?: string;
  forceBillDate?: Date;
}

export interface Subscription {
  _id: string;
  chargebeeId?: string;
  email?: string;
  plan?: string;
}

export interface CheckoutInfo {
  checkout_url: string;
  hosted_page_id: string;
}

export interface PortalInfo {
  access_url: string;
  subscriptionId: string;
}

export interface SubscriptionInfo {
  status: ChargebeeSubscriptionStatus;
}

export enum HubspotContactProperties {
  APP_LAST_VISIT = 'APP_LAST_VISIT',
  APP_PLANS_LAST_VISIT = 'APP_PLANS_LAST_VISIT ',
}

/* Subscription types that can use "pro" features */
export const enterpriseFeatureSubscriptionTypes = [SUBSCRIPTION_TYPES.ENTERPRISE];

export const proFeatureSubscriptionTypes = [
  SUBSCRIPTION_TYPES.ENTERPRISE,
  SUBSCRIPTION_TYPES.PAID,
  SUBSCRIPTION_TYPES.TRIAL,
];

export const allFeatureSubscriptionTypes = [
  SUBSCRIPTION_TYPES.ENTERPRISE,
  SUBSCRIPTION_TYPES.PAID,
  SUBSCRIPTION_TYPES.TRIAL,
  SUBSCRIPTION_TYPES.FREE,
];

export enum BillableFeature {
  SpaceDuration = 'SpaceDuration',
  CloudRecordingDuration = 'CloudRecordingDuration',
}

export const ReadableBillableFeature: Record<BillableFeature, string> = {
  [BillableFeature.SpaceDuration]: 'Space Duration',
  [BillableFeature.CloudRecordingDuration]: 'Cloud Recording Duration',
};

export enum BillingType {
  Automatic = 'Automatic',
  Manual = 'Manual',
}

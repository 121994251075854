<h2 mat-dialog-title class="text-center">{{ 'Log out of Pencil?' | translate }}</h2>
<div mat-dialog-actions class="d-flex justify-content-between">
  <app-pencil-button
    class="pencil-button btn-light flex-1"
    [mat-dialog-close]="false"
    data-name="spaces-logout-cancel"
    [label]="'Cancel' | translate"
  >
  </app-pencil-button>
  <app-pencil-button
    class="pencil-button btn-primary flex-1 cml-16"
    [mat-dialog-close]="true"
    cdkFocusInitial
    data-name="spaces-logout-confirm"
    [label]="'Logout' | translate"
  >
  </app-pencil-button>
</div>
<app-pencil-button
  class="pencil-button btn-cancel"
  [mat-dialog-close]="false"
  data-name="spaces-logout-close"
  icon="close"
  [iconConfig]="{ type: IconTypes.materialIcon }"
>
</app-pencil-button>

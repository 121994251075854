import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';

import { ERRORS } from '../common/utils/notification-constants';
import { UserSettingsPanelComponent } from '../settings/user-settings-panel/user-settings-panel.component';
import { ToasterPopupStyle } from '../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { IconMessageToasterElement } from '../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { panelID } from '../settings/settings.constants';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from './notification-toaster.service';
import { UiService } from './ui.service';

export interface UserSettingsPanelData {
  openTab: panelID;
}

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  dialogRef?: MatDialogRef<UserSettingsPanelComponent>;
  bottomSheetRef?: MatBottomSheetRef<UserSettingsPanelComponent>;

  constructor(
    private translateService: TranslateService,
    private notificationsService: NotificationToasterService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private uiService: UiService,
  ) {}

  showSettingsPanel(openTab?: panelID): void {
    if (this.uiService.isMobile.getValue()) {
      this.bottomSheetRef = this.bottomSheet.open(UserSettingsPanelComponent, {
        panelClass: 'user-settings-bottomsheet-panel',
        disableClose: true,
        autoFocus: false,
        data: {
          openTab,
        },
      });
    } else {
      this.dialogRef = this.dialog.open(UserSettingsPanelComponent, {
        panelClass: 'user-settings-dialog-panel',
        disableClose: true,
        autoFocus: false,
        data: {
          openTab,
        },
      });
    }
  }

  showToast(message?: { title: string; description: string }, error = false): void {
    if (!message) {
      return;
    }

    const titleElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(message.title),
    );
    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(message.description),
    );

    const notification = new NotificationDataBuilder(ERRORS.UNSUPPORTED_SAML_PROVIDER)
      .style(error ? ToasterPopupStyle.ERROR : ToasterPopupStyle.SUCCESS)
      .type(error ? NotificationType.ERROR : NotificationType.SUCCESS)
      .timeOut(5)
      .topElements([titleElement])
      .middleElements([messageElement])
      .dismissable(true)
      .build();
    this.notificationsService.showNotification(notification);
  }
}

<div class="settings-panel">
  <header class="custom-attributes-header">
    <h1 class="title">{{ 'Custom Attributes' | translate }}</h1>
    <p class="description">
      {{ 'Categorizes Spaces, events and users through a custom tag.' | translate }}
    </p>
  </header>
  <main class="custom-attributes-main">
    <app-pencil-button
      class="pencil-button medium white add-attr-btn"
      icon="add"
      [iconConfig]="{ type: IconTypes.matIcon }"
      [label]="'Add attribute' | translate"
      (clickEvent)="openAddCustomAttributeDialog()"
    ></app-pencil-button>
    <table class="custom-attributes-table">
      <thead>
        <tr>
          <th class="no-border-left w-200">{{ 'Name' | translate }}</th>
          <th class="w-200">{{ 'Entities' | translate }}</th>
          <th class="no-border-right w-600">{{ 'Values' | translate }}</th>
        </tr>
      </thead>
      <tbody
        appIntersectionObserveElement
        #intersection="intersection"
        (isLastElementInView)="loadMoreAttributes($event)"
      >
        <ng-container *ngIf="isLoading$ | async; else dataContent">
          <ng-container *ngTemplateOutlet="loadingContent"></ng-container>
        </ng-container>
      </tbody>
    </table>
  </main>
</div>
<ng-template #loadingContent>
  <tr *ngFor="let i of skeletonBars">
    <td class="no-border-left w-200"><div class="skeleton-bar"></div></td>
    <td><div class="skeleton-bar w-200"></div></td>
    <td class="no-border-right w-600"><div class="skeleton-bar"></div></td>
  </tr>
</ng-template>

<ng-template #dataContent>
  <tr
    class="custom-attr-row"
    *ngFor="let attribute of shownInstitutionCustomAttributes$ | async; let i = index"
  >
    <td class="no-border-left name-data w-200">
      <span class="attr-name">{{ attribute.key.value }}</span>
      <div class="custom-attr-actions">
        <app-pencil-icon
          class="custom-attr-action"
          name="edit"
          [type]="IconTypes.materialIcon"
          [outlined]="true"
          (click)="openEditCustomAttributeDialog(attribute)"
          [appTooltip]="editAttributeTooltip"
          [appTooltipArrow]="'bottom'"
          [appTooltipPlacement]="'top-center'"
        ></app-pencil-icon>
        <ng-template #editAttributeTooltip>
          <app-tooltip [text]="'Edit attribute' | translate" [arrow]="'bottom'"></app-tooltip>
        </ng-template>
        <!-- TODO: add this icon when deleting is implemented -->
        <app-pencil-icon
          class="custom-attr-action"
          name="delete"
          [type]="IconTypes.materialIcon"
          [outlined]="true"
          *ngIf="false"
        ></app-pencil-icon>
      </div>
    </td>
    <td class="w-200">
      <!-- TODO: Update this when it is implemented in the BE -->
      <div class="entities">
        <app-pencil-pill class="entity" [isRemovable]="false" text="Space"></app-pencil-pill>
        <app-pencil-pill class="entity" [isRemovable]="false" text="Event"></app-pencil-pill>
        <app-pencil-pill class="entity" [isRemovable]="false" text="User"></app-pencil-pill>
      </div>
    </td>
    <td class="no-border-right">
      <div class="values" #pillContainer>
        <app-pencil-pill
          *ngFor="let value of attribute.values.slice(0, 3)"
          #pills
          class="value"
          [isRemovable]="false"
          [text]="value.value"
        >
        </app-pencil-pill>
        <app-pencil-pill
          class="value"
          [isRemovable]="false"
          *ngIf="attribute.values.length > 3"
          [text]="'+' + (attribute.values.length - 3)"
          [appTooltip]="'Edit attribute to view all values' | translate"
          [appTooltipArrow]="'bottom'"
          [appTooltipPlacement]="'top-center'"
        >
        </app-pencil-pill>
      </div>
    </td>
  </tr>
</ng-template>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, combineLatest, map, Observable, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ISpaceTemplate } from '../models/session';
import { SpacesHomePageView } from '../sessions/spaces-view/spaces-view.component';
import { URLService } from './dynamic-url.service';

/**
 * list of the available icons that can be used for templates
 */
export const availableIcons: string[] = [
  'br-t',
  'cl-t',
  'ci-t',
  'dsb-t',
  'dlb-t',
  'gl-t',
  'gc-t',
  'gmc-t',
  'ob-t',
  'ow-t',
  'sd-t',
  'st-t',
  'tl-t',
  'wd-t',
  'aace-t',
  'as-t',
  'bs-t',
  'cat-t',
  'cf-t',
  'cwas-t',
  'c-t',
  'dwaeg-t',
  'd-t',
  'ef-t',
  'f-t',
  'g1-t',
  'g2-t',
  'ge-t',
  'ghawb-t',
  'iiai-t',
  'l-t',
  'np-t',
  'os-t',
  'ps-t',
  'qe-t',
  'pft-t',
  'r-t',
  'lo-t',
  'r1-t',
  'ri-t',
  'ros-t',
  'she-t',
  'sr-t',
  'ss-t',
  'te-t',
  'tt-t',
  'tw-t',
  't2-t',
  't3-t',
  'v-t',
  'w-t',
  'zh-t',
];

export enum SpacesTemplatesCategories {
  GENERAL = 'general',
  INSTITUTION = 'Institution',
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SpacesTemplatesService {
  private allSpacesTemplates$ = new BehaviorSubject<ISpaceTemplate[]>([]);

  createSpaceFromTemplate$ = new Subject<ISpaceTemplate | undefined>();
  newUpdatedSpaceTemplate$ = new Subject<ISpaceTemplate | undefined>();
  currentSelectedSpaceTemplatesCategory$ = new BehaviorSubject<SpacesTemplatesCategories>(
    SpacesTemplatesCategories.INSTITUTION,
  );
  currentPageView = SpacesHomePageView.SPACES_LIST_VIEW;
  currentPageView$ = new BehaviorSubject<SpacesHomePageView>(SpacesHomePageView.SPACES_LIST_VIEW);

  currentMaxNumberOfTemplates$ = new BehaviorSubject<number>(7);

  currentDisplayedSpacesTemplates$ = combineLatest([
    this.allSpacesTemplates$,
    this.currentSelectedSpaceTemplatesCategory$,
    this.currentPageView$,
    this.currentMaxNumberOfTemplates$,
  ]).pipe(map((value) => this.filterTemplates(...value)));

  private readonly baseURL = `${this.urlService.getDynamicUrl()}/spaces/templates/`;
  constructor(private http: HttpClient, private urlService: URLService) {}

  filterTemplates(
    allSpacesTemplates: ISpaceTemplate[],
    currentCategory: SpacesTemplatesCategories,
    currentPageView: SpacesHomePageView,
    currentMaxNumberOfTemplates: number,
  ): ISpaceTemplate[] {
    if (currentPageView === SpacesHomePageView.SPACES_LIST_VIEW) {
      const institutionTemplates = allSpacesTemplates.filter(
        (template) => template.institution && !template.isGeneral,
      );
      if (institutionTemplates.length >= currentMaxNumberOfTemplates) {
        return institutionTemplates.slice(0, currentMaxNumberOfTemplates);
      } else {
        const generalTemplates = allSpacesTemplates.filter((template) => template.isGeneral);
        return [...institutionTemplates, ...generalTemplates].slice(0, currentMaxNumberOfTemplates);
      }
    } else {
      return this.filterTemplatesBasedOnCategory(allSpacesTemplates, currentCategory);
    }
  }

  filterTemplatesBasedOnCategory(
    allTemplates: ISpaceTemplate[],
    category: SpacesTemplatesCategories,
  ): ISpaceTemplate[] {
    let templatesToDisplay: ISpaceTemplate[] = [];
    // institution templates
    if (category === SpacesTemplatesCategories.INSTITUTION) {
      templatesToDisplay = allTemplates.filter(
        (template) => template.institution && !template.isGeneral,
      );
    } else {
      // general are selected
      templatesToDisplay = allTemplates.filter((template) => template.isGeneral);
    }

    return templatesToDisplay;
  }

  pushSpaceTemplateToCurrentTemplates(spaceTemplate: ISpaceTemplate) {
    const currentTemplates = this.getAllLocalSpacesTemplates();
    this.allSpacesTemplates$.next([spaceTemplate, ...currentTemplates]);
  }

  getAllLocalSpacesTemplates(): ISpaceTemplate[] {
    return this.allSpacesTemplates$.getValue();
  }

  loadAllTemplates() {
    this.getAllSpacesTemplates()
      .pipe(untilDestroyed(this))
      .subscribe((spacesTemplates) => {
        this.allSpacesTemplates$.next(spacesTemplates);
      });
  }

  getAllSpacesTemplates(): Observable<ISpaceTemplate[]> {
    return this.http.get(this.baseURL, httpOptions) as Observable<ISpaceTemplate[]>;
  }

  createSpaceTemplate(spaceTemplate: ISpaceTemplate) {
    return this.http.post(
      this.baseURL,
      JSON.stringify({
        ...spaceTemplate,
        originalSpaceId: spaceTemplate.spaceId,
      }),
      httpOptions,
    ) as Observable<ISpaceTemplate>;
  }

  updateSpaceTemplate(spaceTemplate: ISpaceTemplate) {
    const url = `${this.baseURL}${spaceTemplate._id}`;
    return this.http.patch(
      url,
      JSON.stringify({
        name: spaceTemplate.name,
        icon: spaceTemplate.icon,
        originalSpaceId: spaceTemplate.spaceId,
      }),
      httpOptions,
    );
  }

  deleteSpaceTemplate(spaceTemplate: ISpaceTemplate) {
    const url = `${this.baseURL}${spaceTemplate._id}`;
    return this.http.delete(url, {
      ...httpOptions,
      body: {
        originalSpaceId: spaceTemplate.spaceId,
      },
    });
  }
}

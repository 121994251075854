<div id="user-settings-account" class="settings-panel" [class.mobile]="isMobile">
  <form [formGroup]="form" (submit)="submitChanges()" class="settings-panel-form">
    <div class="head">
      <span class="material-icons go-back" (click)="goBack.emit()"> arrow_back </span>
      <div class="description">
        <div class="title">{{ PANELS.ACCOUNT.name }}</div>
        <div class="subtitle">{{ PANELS.ACCOUNT.description }}</div>
      </div>
      <span class="material-icons close" (click)="goBack.emit()"> close </span>
    </div>
    <div class="sub-head" *ngIf="isMobile">
      {{ PANELS.ACCOUNT.description }}
    </div>
    <div class="body">
      <div class="image-upload-wrap" [ngClass]="{ noImage: !form.value.profile_image }">
        <div class="image-placeholder">
          <span class="material-icons">portrait</span>
          <div>{{ 'Upload' | translate }}</div>
          <div>{{ 'Profile Picture' | translate }}</div>
        </div>
        <div class="image-upload-img">
          <img [src]="form.value.profile_image" *ngIf="form.value.profile_image" alt="" />
        </div>
        <div class="upload-btn" (click)="file.value = ''; file.click()" *ngIf="!isUploadingImage">
          <span class="material-icons">edit</span>
        </div>
        <div *ngIf="isUploadingImage" class="image-uploading-overlay">
          <mat-progress-bar
            *ngIf="uploadPercentage"
            mode="determinate"
            [value]="uploadPercentage | async"
          ></mat-progress-bar>
        </div>
        <input type="file" accept="image/*" #file class="d-none" (change)="upload($event)" />
      </div>
      <div class="fields">
        <div class="form-field">
          <div class="value">
            <label for="user-name">{{ 'FULL NAME' | translate }}</label>
            <input id="user-name" formControlName="name" autocomplete="off" />
          </div>
          <div class="error" *ngIf="form.controls.name.invalid">
            <div class="message" *ngIf="form.controls.name.errors?.required">
              {{ 'Name is required' | translate }}
            </div>
          </div>
        </div>
        <ng-container *ngIf="infoForm" formGroupName="info">
          <div class="form-field">
            <div class="value">
              <label for="role-type">
                {{ 'ARE YOU IN EDUCATION OR BUSINESS?' | translate }}
              </label>
              <mat-radio-group
                color="primary"
                formControlName="roleType"
                (change)="removeRoleOtherInput()"
              >
                <mat-radio-button [value]="ROLE_TYPE_IDS.education" [disableRipple]="true">
                  {{ ROLES.education }}
                </mat-radio-button>
                <mat-radio-button [value]="ROLE_TYPE_IDS.business" [disableRipple]="true">
                  {{ ROLES.business }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="form-field">
            <div class="value">
              <label *ngIf="infoForm?.value.roleType === ROLE_TYPE_IDS.education">
                {{ "What's your role?" | translate }}
              </label>
              <label *ngIf="infoForm?.value.roleType === ROLE_TYPE_IDS.business">
                {{ "What's your industry?" | translate }}
              </label>
              <ng-select
                formControlName="role"
                bindLabel="value"
                bindValue="name"
                dropdownPosition="top"
                [items]="ROLE_TYPES[infoForm?.value.roleType]"
                [clearable]="false"
                [searchable]="false"
              ></ng-select>
            </div>
          </div>
          <div class="form-field" *ngIf="infoForm.contains('roleOther')">
            <div class="value">
              <label for="role-other" *ngIf="infoForm?.value.roleType === ROLE_TYPE_IDS.education">
                {{ 'Please specify your role' | translate }}
              </label>
              <label for="role-other" *ngIf="infoForm?.value.roleType === ROLE_TYPE_IDS.business">
                {{ 'Please specify your industry' | translate }}
              </label>
              <input id="role-other" formControlName="roleOther" />
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="foot" [class.show]="formChanged$ | async">
      <app-pencil-button class="pencil-button btn-ok" type="submit" [disabled]="form.invalid">
        <span *ngIf="!isLoading" class="material-icons">check</span>
        <mat-spinner *ngIf="isLoading" [diameter]="16" color="#FFF"></mat-spinner>
        <span>{{ 'Update' | translate }}</span>
      </app-pencil-button>
      <app-pencil-button
        class="pencil-button btn-cancel"
        (click)="resetForm()"
        type="button"
        icon="close"
        [iconConfig]="{ type: 'material-icon' }"
        [label]="'Cancel' | translate"
      >
      </app-pencil-button>
    </div>
  </form>
</div>

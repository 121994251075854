import {
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { distinctUntilChanged, filter, fromEvent, switchMap, take, tap } from 'rxjs';
import { ComponentPortal } from '@angular/cdk/portal';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UpgradePlanBlockerComponent } from 'src/app/common/upgrade-plan-blocker/upgrade-plan-blocker.component';
import { SUBSCRIPTION_TYPES, proFeatureSubscriptionTypes } from 'src/app/models/payment';
import {
  PaywallIndicatorService,
  UpgradePlanEvaluationType,
} from 'src/app/services/paywall-indicator.service';
import { modifiedSetTimeout } from 'src/app/utilities/ZoneUtils';

@UntilDestroy()
@Directive({
  selector: '[appUpgradePlanBlocker]',
})
export class UpgradePlanBlockerDirective implements OnInit {
  @Input() options?: {
    placement: 'top' | 'bottom' | 'left' | 'right';
    offsetX?: number;
    offsetY?: number;
    arrow?: 'top' | 'bottom' | 'left' | 'right';
    requiredSubscriptions?: SUBSCRIPTION_TYPES[];
  };

  @Input() enableUpgradeBlocker = true;
  @Input() upgradePlanEvaluationType = UpgradePlanEvaluationType.SPACE;
  @Output() isUpgradePlanBlockerEnabled = new EventEmitter<boolean>();

  private overlayRef?: OverlayRef;
  private portal = new ComponentPortal(UpgradePlanBlockerComponent);
  private hideTimeoutId?: NodeJS.Timer;

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef,
    private paywallIndicatorService: PaywallIndicatorService,
  ) {}

  ngOnInit(): void {
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions(this.parsePlacement()),
      hasBackdrop: false,
    });

    this.paywallIndicatorService
      .hasValidSubscription(
        this.options?.requiredSubscriptions || proFeatureSubscriptionTypes,
        this.upgradePlanEvaluationType,
      )
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        tap((hasSubscription) => this.isUpgradePlanBlockerEnabled.next(!hasSubscription)),
        filter((hasSubscription) => !hasSubscription),
        switchMap(() => fromEvent(this.elementRef.nativeElement, 'mouseenter')),
      )
      .subscribe(() => {
        this.show();
      });

    fromEvent(this.elementRef.nativeElement, 'mouseleave')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.hide();
      });
  }

  private show(): void {
    if (!this.enableUpgradeBlocker) {
      return;
    }

    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId);
      this.hideTimeoutId = undefined;
    }

    if (this.overlayRef?.hasAttached()) {
      this.overlayRef.detach();
    }

    const ref = this.overlayRef?.attach(this.portal) as ComponentRef<UpgradePlanBlockerComponent>;

    ref.instance.arrow = this.options?.arrow;
    ref.instance.requiredSubscriptions = this.options?.requiredSubscriptions || [];

    fromEvent(ref.location.nativeElement, 'mouseleave')
      .pipe(untilDestroyed(this), take(1))
      .subscribe(() => {
        this.hide();
      });

    fromEvent(ref.location.nativeElement, 'mouseenter')
      .pipe(untilDestroyed(this), take(1))
      .subscribe(() => {
        this.cancelHide();
      });
  }

  private hide() {
    this.hideTimeoutId = modifiedSetTimeout(() => {
      this.overlayRef?.detach();
    }, 100);
  }

  private cancelHide(): void {
    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId);
      this.hideTimeoutId = undefined;
    }
  }

  private parsePlacement(): ConnectedPosition[] {
    switch (this.options?.placement) {
      case 'left':
        return [
          {
            originX: 'start',
            originY: 'center',
            overlayX: 'end',
            overlayY: 'center',
            offsetX: this.options?.offsetX,
            offsetY: this.options?.offsetY,
          },
        ];
      case 'right':
        return [
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'top',
            offsetX: this.options?.offsetX,
            offsetY: this.options?.offsetY,
          },
        ];
      case 'top':
        return [
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
            offsetX: this.options?.offsetX,
            offsetY: this.options?.offsetY,
          },
        ];
      // bottom placement is the default placement
      case 'bottom':
      default:
        return [
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetX: this.options?.offsetX,
            offsetY: this.options?.offsetY,
          },
        ];
    }
  }
}

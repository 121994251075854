import { Pipe, PipeTransform } from '@angular/core';
import { extractMath } from 'extract-math';
import { FragmentType } from '../common/typed-fragment/typed-fragment';
import { isContentMessage } from '../common/utils/messaging';
import { UpdateType, MessagePreviewType, TypedMessage } from '../models/messaging';
import { Fragment } from '../models/question';

const textFragmentPreviewType = (content: string): MessagePreviewType => {
  if (content === '\n') {
    // Returns MessagePreviewType.None for an empty text fragment.
    return MessagePreviewType.None;
  } else if (extractMath(content.replace(/\n/g, '')).some(({ math }) => !math)) {
    return MessagePreviewType.Text;
  } else {
    /* Returns MessagePreviewType.Equation if entire content of text fragment
     * (excluding newlines) is equations.
     */
    return MessagePreviewType.Equation;
  }
};

const fragmentPreviewType = (fragment: Fragment): MessagePreviewType => {
  switch (fragment.type) {
    case FragmentType.Text:
      return textFragmentPreviewType(fragment.data);
    case FragmentType.Katex:
      return MessagePreviewType.Equation;
    case FragmentType.Image:
    case FragmentType.Youtube:
    case FragmentType.Phet:
      return MessagePreviewType.Attachment;
    default:
      return MessagePreviewType.None;
  }
};

@Pipe({
  name: 'messagePreviewType',
})
export class MessagePreviewTypePipe implements PipeTransform {
  transform(message: TypedMessage): MessagePreviewType {
    const { content } = message;

    if (isContentMessage(message)) {
      if (content.length === 0) {
        return MessagePreviewType.None;
      } else {
        return fragmentPreviewType(content[0]);
      }
    } else {
      // It's a group update message (for now).
      if (message.updateType === UpdateType.CreateGroup) {
        return MessagePreviewType.CreateGroup;
      } else {
        return MessagePreviewType.None;
      }
    }
  }
}

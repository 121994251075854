import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { User } from '../models/user';
import { UserService } from '../services/user.service';

@UntilDestroy()
@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
})
export class SwitcherComponent implements OnInit {
  user?: User;

  constructor(public userService: UserService) {}

  ngOnInit(): void {
    this.userService.user.pipe(untilDestroyed(this)).subscribe({
      next: (res) => {
        this.user = res?.user;
      },
    });
  }
}

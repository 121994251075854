<ng-template #picture>
  <div
    class="d-inline position-relative"
    [class.img-border]="enableImageBorder"
    [class.pending]="userStatus === OnlineStatusEnum.PENDING"
  >
    <ng-container *ngIf="profileImage">
      <img
        [src]="profileImage"
        class="profile-icon"
        [width]="width || 16"
        [height]="width || 16"
        [style]="{
          'min-width': width + 'px',
          'min-height': width + 'px',
          transform: 'scale(' + imgScale + ')'
        }"
        alt="{{ 'profile icon' | translate }}"
        data-name="user-profile-image"
      />
      <div *ngIf="opaqueOverlay" class="profile-image-overlay"></div>
    </ng-container>
    <svg-icon
      *ngIf="!isUser && !isInstitution"
      class="profile-icon"
      [appTooltip]="'This user does not exist anymore' | translate"
      alt=" user-profile-image"
      [width]="width || 16"
      [height]="width || 16"
      [icon]="'user'"
      [style]="{ 'min-width': width + 'px', 'min-height': width + 'px' }"
    >
    </svg-icon>
    <span
      *ngIf="isUser && !profileImage"
      class="profile-icon"
      [ngClass]="imgBorder ? 'white-border' : ''"
      [style.width]="(width || 16) + 'px'"
      [style.height]="(width || 16) + 'px'"
      [style.font-size]="fontSize + 'px'"
      [style.font-weight]="fontWeight"
      >{{ userName ? (userName | shortName) : (userEmail! | shortName) }}</span
    >
    <img
      class="profile-icon-institution"
      *ngIf="isInstitution && !isUser && institutionLogo"
      [src]="institutionLogo"
      [appTooltip]="institutionName"
      [width]="width || 16"
      [style]="{ 'min-width': width + 'px', 'min-height': width + 'px' }"
      alt="{{ 'institution icon' | translate }}"
    />
    <svg-icon
      class="profile-icon-institution"
      *ngIf="isInstitution && !isUser && !institutionLogo"
      [icon]="'user'"
      [width]="width || 16"
    ></svg-icon>
    <span *ngIf="isUser && showStatus" class="status"></span>
    <span
      *ngIf="
        isUser &&
        userStatus &&
        ![OnlineStatusEnum.OFFLINE, OnlineStatusEnum.PENDING].includes(userStatus)
      "
      [ngClass]="{ status: !userStatus, status_v2: !!userStatus }"
      [class.online]="userStatus === OnlineStatusEnum.ONLINE"
      [class.in_call]="userStatus === OnlineStatusEnum.IN_CALL"
      ><mat-icon *ngIf="userStatus === OnlineStatusEnum.IN_CALL" class="material-icons call-icon"
        >call</mat-icon
      ></span
    >
  </div>
</ng-template>

<div
  id="btn-home-avatar-menu"
  class="profile-container"
  data-name="spaces-manager-user-avatar-menu"
  [style.display]="parentDisplay ?? 'flex'"
  [ngClass]="{ 'user-chip': showAsChip }"
  [class.in-call]="userStatus === OnlineStatusEnum.IN_CALL"
  [appTooltipDisabled]="userStatus !== OnlineStatusEnum.PENDING"
  [appTooltip]="'User is currently moving to this room' | translate"
  [appTooltipArrow]="'top'"
  [appTooltipPlacement]="'bottom-center'"
>
  <ng-template *ngTemplateOutlet="picture"></ng-template>
  <div class="cml-8 d-inline-flex flex-column display-name" *ngIf="showName">
    <div
      class="profile-username truncate"
      [style.font-weight]="fontWeight"
      [style.font-size]="fontSize + 'px'"
      [style.color]="fontColor"
    >
      <strong *ngIf="showHostTag && userRole === UserRoleEnum.HOST">
        {{ 'Host: ' | translate }}</strong
      >
      <span
        *ngIf="highlightedText"
        [innerHTML]="
          (userName | highlighter: highlightedText) || (userEmail | highlighter: highlightedText)
        "
      ></span>
      <span *ngIf="!highlightedText">{{ userName || userEmail }}</span>
      <span [hidden]="!showYouWord">{{ ' (You)' | translate }}</span>
    </div>
  </div>
  <app-pencil-spinner
    *ngIf="userStatus === OnlineStatusEnum.PENDING"
    [diameter]="25"
    [style.margin-left]="'10px'"
  ></app-pencil-spinner>
</div>

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, map, take, tap } from 'rxjs';

import { isEqual } from 'lodash';
import { filterNil } from '@ngneat/elf';
import { UpgradePlanEvaluationType } from 'src/app/services/paywall-indicator.service';
import {
  IconBackground,
  IconMessageToasterElement,
} from 'src/app/ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from 'src/app/services/notification-toaster.service';
import { ToasterPopupStyle } from 'src/app/ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { SUCCESSES } from 'src/app/common/utils/notification-constants';
import { UserService } from '../../../services/user.service';
import { RecordingMode, User, InstitutionPermissionState } from '../../../models/user';
import { SettingsService } from '../../../services/settings.service';
import { FLAGS, FlagsService } from '../../../services/flags.service';
import { SUBSCRIPTION_TYPES } from '../../../models/payment';

@UntilDestroy()
@Component({
  selector: 'app-user-settings-permissions[form]',
  templateUrl: './user-settings-permissions.component.html',
  styleUrls: ['./user-settings-permissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsPermissionsComponent implements OnInit {
  private user$ = this.userService.user.pipe(
    map((x) => x?.user),
    filterNil(),
  );

  @Input() isMobile = false;
  @Input() PANELS;
  @Input() form!: UntypedFormGroup;
  @Output() goBack = new EventEmitter<any>();
  @Output() formChangedEvent: EventEmitter<boolean> = new EventEmitter();

  settingsForm?: UntypedFormGroup;
  user?: User;
  isLoading = false;
  public readonly RecordingMode = RecordingMode;
  public readonly InstitutionPermissionState = InstitutionPermissionState;
  cloudRecordingEnabled: boolean;
  public initialFormValue: unknown;
  public formChanged$ = new BehaviorSubject<boolean>(false);
  public canUseCloudRecording$ = this.user$.pipe(
    map((user) => user.subscriptionType === SUBSCRIPTION_TYPES.ENTERPRISE),
  );
  public canUseAutomaticRecording$ = this.user$.pipe(
    map((user) => !!user.institution.settings?.enableAutomaticCloudRecording),
  );
  public canUsePrivacyAwareCloudRecording$ = this.user$.pipe(
    map((user) => !!user.institution.settings?.enablePrivacyAwareCloudRecording),
  );
  public readonly UpgradePlanEvaluationType = UpgradePlanEvaluationType;

  readonly waitingRoomOptions = {
    setting: 'enableWaitingRoom',
    selections: [
      {
        value: InstitutionPermissionState.DEFAULT_ON,
        title: this.translateService.instant('Default On (Hosts Can Modify)'),
        description: this.translateService.instant(
          'New Spaces will have a Waiting Room by default. Hosts can turn it off in settings. Existing Spaces remain unchanged',
        ),
      },
      {
        value: InstitutionPermissionState.DEFAULT_OFF,
        title: this.translateService.instant('Default Off (Hosts Can Modify)'),
        description: this.translateService.instant(
          "New Spaces won't have a Waiting Room by default. Hosts can turn it on in settings. Existing Spaces remain unchanged",
        ),
      },
      {
        value: InstitutionPermissionState.ALWAYS_OFF,
        title: this.translateService.instant('Always Off (Hosts Cannot Modify)'),
        description: this.translateService.instant(
          'No Spaces will have a Waiting Room. Hosts cannot turn it on',
        ),
      },
      {
        value: InstitutionPermissionState.ALWAYS_ON,
        title: this.translateService.instant('Always On (Hosts Cannot Modify)'),
        description: this.translateService.instant(
          'Every Space will have a Waiting Room. Hosts cannot turn it off',
        ),
      },
    ],
  };

  readonly messageOtherParticipantsOptions = {
    setting: 'messageOtherParticipants',
    selections: [
      {
        value: InstitutionPermissionState.DEFAULT_ON,
        title: this.translateService.instant('Default On (Hosts Can Modify)'),
        description: this.translateService.instant(
          'Participants can message other participants by default. Hosts can turn this off in settings. Existing Spaces remain unchanged.',
        ),
      },
      {
        value: InstitutionPermissionState.DEFAULT_OFF,
        title: this.translateService.instant('Default Off (Hosts Can Modify)'),
        description: this.translateService.instant(
          'Participants cannot message other participants by default. Hosts can turn this on in settings. Existing Spaces remain unchanged.',
        ),
      },
      {
        value: InstitutionPermissionState.ALWAYS_OFF,
        title: this.translateService.instant('Always Off (Hosts Cannot Modify)'),
        description: this.translateService.instant(
          'For all Spaces, participants cannot message other participants. Hosts cannot change this setting in a Space.',
        ),
      },
      {
        value: InstitutionPermissionState.ALWAYS_ON,
        title: this.translateService.instant('Always On (Hosts Cannot Modify)'),
        description: this.translateService.instant(
          'For all Spaces, participants can message other participants. Hosts cannot change this setting in a Space.',
        ),
      },
    ],
  };

  constructor(
    private userService: UserService,
    private settingsService: SettingsService,
    private translateService: TranslateService,
    private flagsService: FlagsService,
    private notificationToasterService: NotificationToasterService,
  ) {
    this.cloudRecordingEnabled = this.flagsService.isFlagEnabled(FLAGS.CLOUD_RECORDING);
  }

  ngOnInit(): void {
    this.settingsForm = this.form?.controls.settings as UntypedFormGroup;
    this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
      this.user = res?.user;
      this.initForm();
    });
  }

  initForm(): void {
    this.form.patchValue({
      ...this.user?.institution,
      settings: {
        ...(this.user?.institution?.settings ?? {}),
      },
      permissions: {
        ...(this.user?.institution?.permissions ?? {}),
      },
    });

    this.initialFormValue = this.form.value;
    this.form.valueChanges
      .pipe(
        map(() => !isEqual(this.initialFormValue, this.form.value)),
        tap((x) => this.formChangedEvent.emit(x)),
        untilDestroyed(this),
      )
      .subscribe(this.formChanged$);
  }

  submitChanges(): void {
    if (!this.user || this.isLoading) {
      return;
    }

    this.isLoading = true;
    const updatedUser = {
      ...this.user,
    };

    updatedUser.institution.settings = {
      ...this.user?.institution.settings,
      ...this.form.value.settings,
    };

    updatedUser.institution.permissions = {
      ...this.user?.institution.permissions,
      ...this.form.value.permissions,
    };

    this.userService
      .updateInstitution(updatedUser)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.refresh(updatedUser);
          if (this.isMobile) {
            this.goBack.emit();
          }
        },
        error: () => {
          this.settingsService.showToast(
            {
              title: this.translateService.instant('Update failed'),
              description: this.translateService.instant('An error occurred. Please try again.'),
            },
            true,
          );
        },
        complete: () => {
          this.isLoading = false;
          this.formChanged$.next(false);
          this.formChangedEvent.emit(false);
        },
      });
  }

  refresh(userPayload: User): void {
    const userFromService = this.userService.user.getValue();
    this.userService.user.next({
      ...userFromService,
      user: userPayload,
    });
    this.showSuccessInstitutionUpdateNotification();
  }

  private showSuccessInstitutionUpdateNotification() {
    const title = this.translateService.instant('Institution updated');
    const titleIcon = { svgIcon: 'check', size: 18 };
    const description = this.translateService.instant(
      'Your institution has been successfully updated.',
    );
    const topElement = new IconMessageToasterElement(
      titleIcon,
      title,
      undefined,
      undefined,
      undefined,
      IconBackground.SUCCESS,
      true,
      true,
      'flex-shrink: 0;',
    );
    const middleElement = new IconMessageToasterElement(undefined, description);

    const notificationData = new NotificationDataBuilder(SUCCESSES.PERMISSIONS_UPDATED)
      .type(NotificationType.SUCCESS)
      .style(ToasterPopupStyle.SUCCESS)
      .topElements([topElement])
      .middleElements([middleElement])
      .width(304)
      .dismissable(false)
      .priority(560)
      .timeOut(5)
      .onActivateTick(true)
      .version2Notification(true)
      .showProgressBar(true)
      .progressBarColor('#BBD8FF')
      .build();
    this.notificationToasterService.showNotification(notificationData);
  }
}

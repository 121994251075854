import { Component, Input } from '@angular/core';
import { ToasterElement, ToasterElementType } from '../toaster-element/toaster-element.component';

export class ErrorToasterElement implements ToasterElement {
  readonly type = ToasterElementType.ERROR;
  readonly err: Error;

  constructor(err: Error) {
    this.err = err;
  }
}

@Component({
  selector: 'app-error-toaster-element',
  templateUrl: './error-toaster-element.component.html',
  styleUrls: ['./error-toaster-element.component.scss'],
})
export class ErrorToasterElementComponent {
  @Input() errorElement?: ErrorToasterElement;
}

import { Pipe, PipeTransform } from '@angular/core';
import { FragmentType } from '../common/typed-fragment/typed-fragment';

@Pipe({
  name: 'fragment',
})
export class FragmentPipe implements PipeTransform {
  transform(question, type: any = 'questions'): any {
    let fragments;
    switch (type) {
      case 'questions':
        fragments = question?.question_fragments;
        break;
      case 'notes':
        fragments = question?.title_fragments;
        break;
      default:
        fragments = question?.question_fragments;
        break;
    }

    const fragment = fragments?.find(
      (frag) => frag.type === FragmentType.Text,
    );
    if (fragment?.data) {
      return fragment;
    }

    if (question?.sub_questions) {
      let subFragment;
      for (const s of question.sub_questions) {
        if (s.question_fragments.length > 0) {
          subFragment = s.question_fragments[0];
        }
        break;
      }
      return subFragment?.data ? subFragment : 'Click to see more details.';
    } else {
      return 'Click to see more details.';
    }
  }
}

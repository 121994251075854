import { TranslateService } from '@ngx-translate/core';
import { DeviceAndBrowserDetectorService } from 'src/app/services/device-and-browser-detector.service';
import { DeviceErrorsNotificationsService } from 'src/app/services/device-errors-notifications.service';
import { DeviceErrorType, DeviceState } from 'src/app/models/device-manger';
import { DeviceType } from '../devices-handle-util';
import {
  Browser,
  DeviceErrorHelperModelBase,
  DeviceErrorHelperStep,
} from './device-error-helper-model-base';
import { PermissionHelper } from './permission-helper';

export class BlockByOSHelper extends DeviceErrorHelperModelBase {
  private _permissionHelper: PermissionHelper;
  private _steps: DeviceErrorHelperStep[] = [];
  protected helperSupportedBrowsers: string[] = [
    Browser.CHROME,
    Browser.EDGE,
    Browser.SAFARI,
    Browser.FIREFOX,
    Browser.OPERA,
  ];

  constructor(
    deviceDetector: DeviceAndBrowserDetectorService,
    translateService: TranslateService,
    private deviceErrorsNotificationsService: DeviceErrorsNotificationsService,
    deviceType: DeviceType,
  ) {
    super(translateService, deviceDetector, deviceType);
    this.guideWidth = 1198;
    this._permissionHelper = new PermissionHelper(
      deviceDetector,
      translateService,
      deviceErrorsNotificationsService,
      deviceType,
    );
  }

  getSteps(): DeviceErrorHelperStep[] {
    this._steps = [];
    if (!this.isSupportedOS()) {
      return this._permissionHelper.getSteps();
    }
    this.buildHelperFlow();
    return this._steps;
  }

  getMobileTitle(): string {
    if (!this.isSupportedOS()) {
      return this._permissionHelper.getMobileTitle();
    }
    return this.translateService.instant('Allow Mic / Camera Access');
  }

  getDesktopTitle(): string {
    if (!this.isSupportedOS()) {
      return this._permissionHelper.getDesktopTitle();
    }
    if (!this.canFitAllSteps()) {
      return this.translateService.instant('Allow Mic / Camera Access');
    }
    return (
      this.translateService.instant('Please allow your browser to access your camera and mic in ') +
      this.currentOSName()
    );
  }

  dismissErrorNotification() {
    this.deviceErrorsNotificationsService.dismissPermissionErrorNotification();
  }

  hasRelatedError(cam: DeviceState, mic: DeviceState) {
    return (
      this.deviceErrorsNotificationsService.hasErrorOfType(
        cam,
        DeviceErrorType.PERMISSION_DENIED_BY_SYSTEM,
      ) ||
      this.deviceErrorsNotificationsService.hasErrorOfType(
        mic,
        DeviceErrorType.PERMISSION_DENIED_BY_SYSTEM,
      )
    );
  }

  showErrorNotification(cam: DeviceState, mic: DeviceState) {
    this.deviceErrorsNotificationsService.showPermissionErrorNotification(cam, mic);
  }

  deviceSettingButtonSrc(): string {
    return this.deviceDetector.isMacOS()
      ? `x-apple.systempreferences:com.apple.preference.security?${
          this.deviceType == DeviceType.VIDEO ? 'Privacy_Camera' : 'Privacy_Microphone'
        }`
      : `ms-settings:privacy-${this.deviceType == DeviceType.VIDEO ? 'webcam' : 'microphone'}`;
  }

  protected showRealDeviceSettingsButtonByGuide() {
    return true;
  }

  // in case not supported we
  // will fall back to the permissions helper
  private isSupportedOS() {
    return (
      this.deviceDetector.isAndroid() ||
      this.deviceDetector.isWindows() ||
      this.deviceDetector.isMacOS()
    );
  }

  private capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  private buildHelperFlow() {
    if (this.deviceDetector.isMacOS()) {
      this.buildStepsMacOS();
    } else if (this.deviceDetector.isWindows()) {
      this.buildStepsWindows();
    } else if (this.deviceDetector.isAndroid()) {
      this.buildStepsAndroid();
    }
  }

  private currentOSName() {
    if (this.deviceDetector.isMacOS()) {
      return 'MacOS';
    } else if (this.deviceDetector.isWindows()) {
      return 'Windows';
    } else if (this.deviceDetector.isAndroid()) {
      return 'Android';
    }
  }

  private buildStepsMacOS() {
    this._steps.push(
      {
        showStepLocation: true,
        stepText: this.translateService.instant(
          'Go to your system settings and<br>select <b>Privacy & Security</b>',
        ),
        stepImgSrc: 'blockByOSSteps/macOS/step-1.png',
        stepWidth: 200,
        stepLocationText: 'Settings',
        stepLocationImgSrc: 'apple_settings_icon.png',
      },
      {
        showStepLocation: true,
        stepText: this.translateService.instant('Select <b>Microphone & Camera<b>'),
        stepImgSrc: 'blockByOSSteps/macOS/step-2.png',
        stepWidth: 183,
        stepLocationText: 'Settings',
        stepLocationImgSrc: 'apple_settings_icon.png',
      },
      {
        showStepLocation: true,
        stepText: this.translateService.instant(
          `Toggle <b>Allow</b> for ${this.capitalizeFirstLetter(this.currentBrowserName())}`,
        ),
        stepImgSrc: `blockByOSSteps/${this.currentBrowserName()}-allow.png`,
        stepWidth: 201,
        stepLocationText: 'Settings',
        stepLocationImgSrc: 'apple_settings_icon.png',
      },
      {
        showStepLocation: true,
        stepText: this.translateService.instant(
          `Restart ${this.capitalizeFirstLetter(
            this.currentBrowserName(),
          )}<br><span style="color: #E5616D;">You will have to leave<br>your call. You can rejoin<br>after restarting.</span>`,
        ),
        stepWidth: 128,
        stepLocationText: this.capitalizeFirstLetter(this.currentBrowserName()),
        stepLocationImgSrc: `${this.currentBrowserName()}.png`,
      },
    );
  }

  private buildStepsWindows() {
    this._steps.push(
      {
        showStepLocation: true,
        stepText: this.translateService.instant(
          'Select <b>Start</b> > <b>Settings</b> ><br><b>Privacy</b>',
        ),
        stepImgSrc: 'blockByOSSteps/windows/step-1.png',
        stepWidth: 183,
        stepLocationText: 'Settings',
        stepLocationImgSrc: 'general-settings-icon.png',
      },
      {
        showStepLocation: true,
        stepText: this.translateService.instant(
          'Select <b>Microphone</b> & <b>Camera</b><br>on the left under app<br>permissions',
        ),
        stepImgSrc: 'blockByOSSteps/windows/step-2.png',
        stepWidth: 183,
        stepLocationText: 'Settings',
        stepLocationImgSrc: 'general-settings-icon.png',
      },
      {
        showStepLocation: true,
        stepText: this.translateService.instant(
          'Toggle <b>Allow</b> for both camera and<br>microphone',
        ),
        stepImgSrc: 'blockByOSSteps/windows/step-3.png',
        stepWidth: 200,
        stepLocationText: 'Settings',
        stepLocationImgSrc: 'general-settings-icon.png',
      },
      {
        showStepLocation: true,
        stepText: this.translateService.instant(
          'Choose apps that can use your<br>camera and microphone',
        ),
        stepImgSrc: `blockByOSSteps/${this.currentBrowserName()}-allow.png`,
        stepWidth: 201,
        stepLocationText: 'Settings',
        stepLocationImgSrc: 'general-settings-icon.png',
      },
      {
        showStepLocation: true,
        stepText: this.translateService.instant(
          `Restart ${this.capitalizeFirstLetter(
            this.currentBrowserName(),
          )}<br><br><span style="color: #E5616D;">You will have to leave<br>your call. You can rejoin<br>after restarting.</span>`,
        ),
        stepWidth: 128,
        stepLocationText: this.capitalizeFirstLetter(this.currentBrowserName()),
        stepLocationImgSrc: `${this.currentBrowserName()}.png`,
      },
    );
  }

  private buildStepsAndroid() {
    // 908px is the width needed to show all android steps
    const isWideScreen = window.innerWidth * 0.8 >= 908;
    if (isWideScreen) {
      this.buildStepsAndroidWideScreen();
    } else {
      this.buildStepsAndroidSmallScreen();
    }
  }

  private buildStepsAndroidWideScreen() {
    this._steps.push(
      {
        showStepLocation: true,
        stepText: this.translateService.instant('Go to <b>Privacy</b> in Android<br>settings'),
        stepImgSrc: 'blockByOSSteps/android/wide/step-1.png',
        stepWidth: 183,
        stepLocationText: 'Settings',
        stepLocationImgSrc: 'general-settings-icon.png',
      },
      {
        showStepLocation: true,
        stepText: this.translateService.instant(
          'Toggle <b>Allow</b> for both camera and<br>microphone',
        ),
        stepImgSrc: 'blockByOSSteps/android/wide/step-2.png',
        stepWidth: 216,
        stepLocationText: 'Settings',
        stepLocationImgSrc: 'general-settings-icon.png',
      },
      {
        showStepLocation: true,
        stepText: this.translateService.instant(
          'Go to <b>Permissions Manager</b> and<br>allow your browser camera and<br>microphone access',
        ),
        stepImgSrc: `blockByOSSteps/${this.currentBrowserName()}-allow.png`,
        stepWidth: 200,
        stepLocationText: 'Settings',
        stepLocationImgSrc: 'general-settings-icon.png',
      },
      {
        showStepLocation: true,
        stepText: this.translateService.instant(
          `Restart ${this.capitalizeFirstLetter(
            this.currentBrowserName(),
          )}<br><span style="color: #E5616D;">You will have to leave<br>your call. You can rejoin<br>after restarting.</span>`,
        ),
        stepWidth: 128,
        stepLocationText: this.capitalizeFirstLetter(this.currentBrowserName()),
        stepLocationImgSrc: `${this.currentBrowserName()}.png`,
      },
    );
  }

  private buildStepsAndroidSmallScreen() {
    this._steps.push(
      {
        showStepLocation: false,
        stepText: this.translateService.instant('Go to <b>Privacy</b> in Android settings'),
        stepImgSrc: 'blockByOSSteps/android/small/step-1.png',
        stepWidth: 375,
      },
      {
        showStepLocation: false,
        stepText: this.translateService.instant(
          'Toggle <b>Allow</b> for both camera and microphone',
        ),
        stepImgSrc: 'blockByOSSteps/android/small/step-2.png',
        stepWidth: 375,
      },
      {
        showStepLocation: false,
        stepText: this.translateService.instant(
          'Go to <b>Permissions Manager</b> and allow your browser camera<br>and microphone access',
        ),
        stepImgSrc: 'blockByOSSteps/android/small/step-3.png',
        stepWidth: 375,
      },
      {
        showStepLocation: false,
        stepText: this.translateService.instant(
          `Restart ${this.capitalizeFirstLetter(
            this.currentBrowserName(),
          )}<br><span style="color: #E5616D;">You will have to leave your call. You can rejoin after restarting.</span>`,
        ),
        stepImgSrc: 'blockByOSSteps/android/small/step-4.png',
        stepWidth: 375,
      },
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wordCount',
})
export class WordCountPipe implements PipeTransform {
  transform(question, type = 'questions'): any {
    let wordCount = 0;
    if (type === 'questions') {
      wordCount += this.fragmentWordCount(question.question_fragments);
      if (question.answer && question.answer.choice_fragments) {
        wordCount += this.fragmentWordCount(question.answer.choice_fragments);
      }
      if (question.sub_questions) {
        for (const subQuestion of question.sub_questions) {
          wordCount += this.fragmentWordCount(subQuestion.question_fragments);
          if (subQuestion.answer && subQuestion.answer.choice_fragments) {
            wordCount += this.fragmentWordCount(subQuestion.answer.choice_fragments);
          }
        }
      }
      for (const choice of question.multiple_choice) {
        wordCount += this.fragmentWordCount(choice.choice_fragments);
      }
    } else {
      wordCount += this.fragmentWordCount(question.note_fragments);
    }
    return wordCount;
  }

  fragmentWordCount(fragments) {
    let wordCount = 0;
    if (fragments && fragments.length > 0) {
      for (const fragment of fragments) {
        if (fragment.type !== 'TEXT' || !fragment.data) {
          continue;
        }
        wordCount += fragment.data.split(' ').length;
      }
    }
    return wordCount;
  }
}

import { animate, sequence, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EmbedVideoService } from 'src/app/services/embed-video.service';
import { IDisplayFile } from 'src/app/models/resource';
import { ResourcesService } from 'src/app/services/resources.service';
import { ThumbnailService } from 'src/app/services/thumbnail.service';
import { animationStates, FileViewComponent } from '../base-file-view.component';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss', '../../files.component.scss'],
  animations: [
    trigger('openCloseFolder', [
      state(
        'open',
        style({
          transform: 'rotate(90deg)',
        }),
      ),
      state(
        'closed',
        style({
          transform: 'rotate(0deg)',
        }),
      ),
      transition('open => closed', [animate('0.3s')]),
      transition('closed => open', [animate('0.3s')]),
    ]),
    trigger('anim', [
      transition('* => void', [
        style({
          height: '*',
          opacity: '1',
          transform: 'translateX(0)',
          'box-shadow': '0 1px 4px 0 rgba(0, 0, 0, 0.3)',
        }),
        sequence([
          animate(
            '.3s ease',
            style({
              height: '*',
              opacity: '.4',
              transform: 'translateX(50px)',
              'box-shadow': 'none',
            }),
          ),
          animate(
            '.1s ease',
            style({
              height: '*',
              opacity: '.2',
              transform: 'translateX(50px)',
              'box-shadow': 'none',
            }),
          ),
          animate(
            '.1s ease',
            style({ height: '0', opacity: 0, transform: 'translateX(50px)', 'box-shadow': 'none' }),
          ),
        ]),
      ]),
      transition('void => '.concat(animationStates.VISIBLE), [
        style({ height: '0', opacity: '0', transform: 'translateX(20px)', 'box-shadow': 'none' }),
        sequence([
          animate(
            '.1s ease',
            style({
              height: '*',
              opacity: '.2',
              transform: 'translateX(20px)',
              'box-shadow': 'none',
            }),
          ),
          animate(
            '.1s ease',
            style({
              height: '*',
              opacity: '.6',
              transform: 'translateX(15px)',
              'box-shadow': 'none',
            }),
          ),
          animate(
            '.3s ease',
            style({ height: '*', opacity: 1, transform: 'translateX(0)', 'box-shadow': 'none' }),
          ),
        ]),
      ]),
      transition(animationStates.HIDDEN.concat(' => ').concat(animationStates.VISIBLE), [
        style({ height: '0', opacity: '0', transform: 'translateX(20px)', 'box-shadow': 'none' }),
        sequence([
          animate(
            '.1s ease',
            style({
              height: '*',
              opacity: '.2',
              transform: 'translateX(20px)',
              'box-shadow': 'none',
            }),
          ),
          animate(
            '.1s ease',
            style({
              height: '*',
              opacity: '.6',
              transform: 'translateX(15px)',
              'box-shadow': 'none',
            }),
          ),
          animate(
            '.3s ease',
            style({ height: '*', opacity: 1, transform: 'translateX(0)', 'box-shadow': 'none' }),
          ),
        ]),
      ]),
    ]),
  ],
})
export class FileListComponent extends FileViewComponent implements OnInit {
  constructor(
    _router: Router,
    _embedService: EmbedVideoService,
    _thumbnail: ThumbnailService,
    _resourceService: ResourcesService,
    _translate: TranslateService,
    _dialog: MatDialog,
  ) {
    super(_dialog, _router, _embedService, _thumbnail, _resourceService, _translate);
  }

  ngOnInit(): void {
    this.maxNameLength = 40;
  }

  handleDrag(files: IDisplayFile[], elem: HTMLDivElement) {
    const fileElem = this.createDragImageList(files[0]);
    elem.appendChild(fileElem);
    if (this.selectedFiles.length > 1) {
      const fileElem2 = this.createDragImageList(files[1]);
      fileElem2.style.top = '-8px';
      fileElem2.style.right = '-8px';
      fileElem2.style.zIndex = '-1';
      fileElem2.classList.add('position-absolute');
      const numElem = this.createDragImageCount();
      numElem.style.bottom = '13px';
      numElem.style.right = '-10px';
      elem.appendChild(fileElem2);
      elem.appendChild(numElem);
    }
  }
}

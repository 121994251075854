import { CommonModule } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { IconConfig, IconTypes, PencilIconComponent } from '../pencil-icon/pencil-icon.component';

export type ButtonIconConfig = Omit<IconConfig, 'name' | 'onClick'>;

@Component({
  selector: 'app-pencil-button',
  templateUrl: './pencil-button.component.html',
  styleUrls: ['./pencil-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, PencilIconComponent],
})
export class PencilButtonComponent implements AfterContentChecked {
  @Input() selected: boolean = false;
  @Input() disabled = false;
  @Input() icon?: string;
  @Input() iconConfig: ButtonIconConfig = {
    type: IconTypes.svgIcon,
  };
  @Input() label?: string;
  @Input() iconOnLeft: boolean = true;
  @Input() buttonType: 'button' | 'submit' | 'reset' = 'submit';
  @Output() clickEvent = new EventEmitter<Event>();
  @ContentChild('ng-content') ngContent?: ElementRef;

  @HostBinding('class.selected') get applySelectedClass() {
    return this.selected;
  }
  @HostBinding('class.disabled') get applyDisabledClass() {
    return this.disabled;
  }
  isContentEmpty: boolean = true;

  ngAfterContentChecked() {
    this.isContentEmpty = this.ngContent === undefined;
  }

  handleClick(event: Event): void {
    this.clickEvent.emit(event);
  }
}

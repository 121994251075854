import { Component, OnInit, Input } from '@angular/core';
import { Fragment } from 'src/app/models/question';

@Component({
  selector: 'app-table-fragment',
  templateUrl: './table-fragment.component.html',
  styleUrls: ['./table-fragment.component.scss']
})
export class TableFragmentComponent implements OnInit {
  @Input() fragment!: Fragment;

  tableData = [];

  constructor() { 
    // do nothing
  }

  ngOnInit(): void {
    try {
      if (this.fragment?.data) {
        this.tableData = JSON.parse(this.fragment?.data);
      }
    } catch (e) {
      // continue regardless of error
    }
  }

}

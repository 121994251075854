<div *ngIf="currentFolders.length !== 0">
  <span class="context-header font-weight-bold"> {{ 'Folders' | translate }}</span>
</div>
<div class="folder-container d-flex flex-row flex-wrap justify-content-start">
  <!-- Using drag over/leave for hovering effect while dropping files -->
  <div
    *ngFor="let folder of currentFolders; let i = index"
    class="folder-btn-container"
    [ngClass]="{ hoveringThrough: dragging }"
    (dragover)="allowDrop($event); folder.xMeta?.display.hover = true"
    (dragleave)="folder.xMeta?.display.hover = false"
    (drop)="drop($event, folder)"
  >
    <button
      [ngClass]="{
        'btn-folder-selected': folder.xMeta?.display.selected,
        'btn-folder': !folder.xMeta?.display.selected,
        dragFolders: dragging && folder.xMeta?.display.hover
      }"
      class="d-flex justify-content-start align-items-center cpr-16 cpl-16 cpt-4 cpb-4"
      appTooltip="{{ folder.name }}"
      appTooltipPlacement="bottom-center"
      appTooltipOffsetY="15"
      (click)="onOpenFolder(folder._id)"
    >
      <span class="material-icons"> folder_open </span>
      <span class="text">&nbsp;&nbsp;{{ folder.name | truncate: [maxNameLength] }}</span>
    </button>
  </div>
</div>

<div *ngIf="currentFiles.length !== 0">
  <span class="context-header font-weight-bold">{{ 'Files' | translate }}</span>
</div>

<div
  infiniteScroll
  [infiniteScrollDistance]="2"
  (scrolled)="onScrollDown()"
  [scrollWindow]="false"
  [infiniteScrollContainer]="'.files-container'"
  [fromRoot]="true"
  class="d-flex flex-row flex-wrap justify-content-start"
  *ngIf="currentFolders.length !== 0 || currentFiles.length !== 0"
>
  <div
    class="file-item"
    *ngFor="let file of currentFiles; let i = index"
    [ngStyle]="file.xMeta?.display.style"
    (click)="openPreview($event, file, i)"
    [draggable]="file.xMeta?.display.selected || !selectedFiles.length"
    (dragstart)="drag($event, true, i)"
    (dragend)="drag($event, false)"
  >
    <div
      class="img-container align-items-center justify-content-center overflow-hidden d-flex"
      [ngClass]="{
        'img-container-selected': file.xMeta?.display.selected
      }"
      appTooltip="{{ file.name }}"
      appTooltipPlacement="bottom-center"
      appTooltipOffsetY="15"
    >
      <mat-checkbox
        color="primary"
        [(ngModel)]="file.xMeta?.display.selected"
        (click)="$event.stopPropagation()"
        (ngModelChange)="toggleSelection($event, i)"
        [disabled]="!canSelect"
        [ngClass]="{ checked: file.xMeta?.display.selected }"
      ></mat-checkbox>
      <div
        *ngIf="file.courses?.length"
        class="course-tag"
        #tooltip="ngbTooltip"
        [ngbTooltip]="file.courses | courseTooltip: courseLookup"
      >
        {{
          (file.courses.length > 1
            ? file.courses.length + ' courses'
            : (file.courses | courseTooltip: courseLookup:true)
          ) | truncate: [12]
        }}
      </div>
      <img
        *ngIf="file.type === resourceTypes.IMAGE"
        class=""
        [src]="file.xMeta?.thumbnailURL ?? file.url"
        loading="lazy"
      />
      <div
        *ngIf="file.type === resourceTypes.VIDEO"
        [innerHtml]="file.embedding"
        class="w-100 video-draggable"
      ></div>
      <app-pdf-fragment-container
        *ngIf="
          file.type === resourceTypes.DOCUMENT && (file.name || file.altName)?.endsWith('.pdf')
        "
        class="pdf-viewer"
        [src]="file.url"
        [page]="1"
      >
      </app-pdf-fragment-container>
      <div
        *ngIf="
          file.type === resourceTypes.DOCUMENT && !(file.name || file.altName)?.endsWith('.pdf')
        "
      >
        <i class="material-icons-outlined thumbnail">text_snippet</i>
      </div>
    </div>
    <!--@es-->
    <span class="img-label">{{ file.name || file.altName | fileNameFormat: [maxNameLength] }}</span>
  </div>
</div>

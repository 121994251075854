import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkNew',
})
export class CheckNewPipe implements PipeTransform {
  transform(timestamp: Date, lastViewed?: any): any {
    return new Date(lastViewed).getTime() < new Date(timestamp).getTime();
  }
}

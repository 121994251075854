<div
  class="language-selector"
  role="button"
  [matMenuTriggerFor]="langMenuV0"
  data-name="spaces-manager-user-avatar-menu-settings-langauge-selection"
>
  <span class="current-language">{{ lang?.label | translate }}</span>
  <span class="material-icons">keyboard_arrow_down</span>
</div>
<mat-menu #langMenuSettings="matMenu" class="language-selector-menu">
  <div *ngFor="let language of langCodeMap">
    <button
      mat-menu-item
      (click)="changeLang(language)"
      class="lang-menu-btn"
      [class.active]="langCode === language.code"
    >
      <span
        >{{ language.label }} <span>({{ language.label | translate }})</span></span
      >
      <mat-icon *ngIf="langCode === language.code" class="check-icon">check</mat-icon>
    </button>
  </div>
</mat-menu>
<mat-menu #langMenuV0="matMenu" class="lang-menu">
  <div *ngFor="let lang of langCodeMap">
    <app-pencil-button
      (clickEvent)="changeLang(lang)"
      [attr.data-name]="
        'spaces-manager-user-avatar-menu-settings-langauge-selection-' + lang.label.toLowerCase()
      "
      class="pencil-button menu-item language-menu-item"
      [selected]="langCode === lang.code"
    >
      {{ lang.originalLabel }} / {{ lang.label | translate }}
      <mat-icon *ngIf="langCode === lang.code" class="check-icon">check</mat-icon>
    </app-pencil-button>
  </div>
</mat-menu>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { negate } from 'lodash-es';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  combineLatest,
  firstValueFrom,
  merge,
  of,
} from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  scan,
  share,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { sortByDate } from '../common/utils/common-util';
import {
  buildConversationMessageUpdate,
  contextEquals,
  filterNonNullable,
  filterOutCurrentUserAddedMessage,
  getChatType,
  idEquals,
  isDraftPreview,
  isUserConversation,
  isUserPreview,
  mapSpaceOwnershipToUser,
  previewEquals,
  sentMessageToMessageUpdate,
  tagGroupConversation,
  tagUserConversation,
  updateConversationContent,
} from '../common/utils/messaging';
import { ChatOptions } from '../communication/chat/chat.component';
import { Context, encodeContext } from '../models/context';
import {
  ContentMessage,
  ConversationContent,
  ConversationHistoryUpdate,
  ConversationPreview,
  ConversationPreviewType,
  ConversationType,
  Group,
  GroupConversationPreview,
  GroupDraftConversationPreview,
  MessageAction,
  MessageUpdate,
  NewGroupConversationRequest,
  NewGroupConversationResponse,
  NewUserConversationRequest,
  RealtimeToken,
  TypedMessage,
  UserConversationPreview,
  UserDraftConversationPreview,
} from '../models/messaging';
import { SessionUser } from '../models/session';
import { Message, OnlineStatus, User, UserDataAndOnlineStatus, UserInfo } from '../models/user';
import { PanelView } from '../sessions/panel/panel.component';
import { PresenceRepository } from '../state/presence.repository';
import { SpaceRepository } from '../state/space.repository';
import { ConversationService } from './conversation.service';
import { URLService } from './dynamic-url.service';
import { MessengerService } from './messenger.service';
import { RealtimeService } from './realtime.service';
import { SessionSharedDataService } from './session-shared-data.service';
import { UserService } from './user.service';

// Convert a draft preview to a full preview that contains a message.
const convertDraftPreview = (
  draft: UserDraftConversationPreview | GroupDraftConversationPreview,
  message: TypedMessage,
): UserConversationPreview | GroupConversationPreview => {
  if (isUserPreview(draft)) {
    return { ...draft, previewType: ConversationPreviewType.UserPreviewType, message };
  } else {
    return { ...draft, previewType: ConversationPreviewType.GroupPreviewType, message };
  }
};

type InitialUserMessage = TypedMessage & {
  conversationType: ConversationType.UserConversation;
  otherUser: UserInfo;
};

type InitialGroupMessage = TypedMessage & {
  conversationType: ConversationType.GroupConversation;
  group: Group;
};

/* A message object returned by the `/messages/recent` endpoint that seeds the
 * conversation list.
 */
type InitialMessage = InitialUserMessage | InitialGroupMessage;

interface InitialMessagesResponse {
  recentMessages: InitialMessage[];
  channelToken: RealtimeToken;
}

type NewUserConversation = NewUserConversationRequest;

interface NewGroupConversation {
  conversationType: ConversationType.GroupConversation;
  group: Group;
}

/* A new conversation that gets sent to the top of the conversation list after
 * being selected by the user through the new message dialog. May correspond to
 * an existing conversation in the conversation list, or may be initialized as a
 * "draft" conversation.
 */
type NewConversation = NewUserConversation | NewGroupConversation;

/* An event that can trigger a change in the current conversation list, which
 * can be a new conversation added through the new message dialog
 * (NewConversationUpdate), or a message update received from realtime
 * (NewMessageUpdate).
 */
enum ConversationListUpdateType {
  NewConversationUpdate,
  NewMessageUpdate,
}

type ConversationListUpdate =
  | (NewConversation & { listUpdateType: ConversationListUpdateType.NewConversationUpdate })
  | (MessageUpdate & { listUpdateType: ConversationListUpdateType.NewMessageUpdate });

// Helper for type narrowing based on ConversationListUpdateType literal.
const isMessageUpdate = <T extends { listUpdateType: ConversationListUpdateType }>(
  update: T,
): update is T & { listUpdateType: ConversationListUpdateType.NewMessageUpdate } =>
  update.listUpdateType === ConversationListUpdateType.NewMessageUpdate;

interface UserConversationIdentifier {
  conversationType: ConversationType.UserConversation;
  otherUserId: string;
}

interface GroupConversationIdentifier {
  conversationType: ConversationType.GroupConversation;
  groupId: string;
}

export interface IOpenChat {
  user?: User | UserInfo;
  chatOptions: ChatOptions;
}

export interface ICustomChatPreview {
  user: UserDataAndOnlineStatus;
  message?: ContentMessage;
  /**
   * The set hold the ids for unread messages and clears them when the user opens the chat.
   * When the sender deletes any of the chat, it ID is removed.
   * The lenght of the set is used to know the number of unread chats.
   */
  unreadChatIds?: Set<string>;
}

export const removeDuplicateMessages = <T extends { context: { users?: string[] } }[]>(
  objects: T,
): T => {
  const ids = objects.map((o) => o.context.users?.toString());
  return objects.filter(
    ({ context }, index) => !ids.includes(context.users?.toString(), index + 1),
  ) as T;
};

/* A primitive identifier for the currently selected conversation. This is a
 * user or group ID string that gets read from the active router URL.
 */
type ConversationIdentifier = UserConversationIdentifier | GroupConversationIdentifier;

// Returns a predicate based on the given search value.
const matchesSearchQuery = (searchQuery: string) => (preview: ConversationPreview) => {
  if (searchQuery == null) {
    return false;
  }
  if (isUserPreview(preview)) {
    return (
      preview.userInfo.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      preview.userInfo.email?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  } else {
    return preview.group.name?.toLowerCase().includes(searchQuery.toLowerCase());
  }
};

/* We make the assumption here that any added message is the absolute most
 * recent message for this user, both within the added message context as well
 * as across all other contexts that the user is a part of. Based on this
 * assumption, the added message becomes the preview message for its
 * conversation, and that conversation is moved to the top of the list.
 */
const handleAddedMessage = (
  currentUser: User,
  currentPreviews: ConversationPreview[],
  addedMessage: MessageUpdate,
): ConversationPreview[] => {
  const existingPreview = currentPreviews.find(contextEquals(addedMessage));
  if (existingPreview === undefined) {
    /* Slightly subtle logic here that if a 1:1 message is received and that
     * message does not have an existing conversation preview, then we know that
     * the message was sent by the other user in the conversation (not the
     * current user).
     */
    const initialMessage = isUserConversation(addedMessage)
      ? { ...addedMessage, otherUser: addedMessage.author }
      : addedMessage;
    return [buildInitialPreview(currentUser)(initialMessage), ...currentPreviews];
  } else {
    const otherPreviews = currentPreviews.filter(negate(contextEquals(addedMessage)));
    const newTopPreview = isDraftPreview(existingPreview)
      ? convertDraftPreview(existingPreview, addedMessage)
      : { ...existingPreview, message: addedMessage };
    return [newTopPreview, ...otherPreviews];
  }
};

/* If an older version of the edited message exists in the current message list,
 * replace the older version with the new version.
 */
const handleEditedMessage = (
  currentPreviews: ConversationPreview[],
  editedMessage: MessageUpdate,
): ConversationPreview[] =>
  currentPreviews.map((currentPreview) => {
    if (!isDraftPreview(currentPreview) && idEquals(currentPreview.message)(editedMessage)) {
      return {
        ...currentPreview,
        message: editedMessage,
      };
    } else {
      return currentPreview;
    }
  });

/* Set a flag on the deleted message so that the component can display a custom
 * message preview.
 */
const handleDeletedMessage = (
  currentPreviews: ConversationPreview[],
  deletedMessage: MessageUpdate,
): ConversationPreview[] =>
  currentPreviews.map((currentPreview) => {
    if (!isDraftPreview(currentPreview) && idEquals(currentPreview.message)(deletedMessage)) {
      return {
        ...currentPreview,
        message: {
          ...deletedMessage,
          deleted: true,
        },
      };
    } else {
      return currentPreview;
    }
  });

const buildUserContext = (currentUser: User, otherUser: UserInfo): Context => ({
  institution: currentUser?.institution?._id || undefined,
  users: [currentUser._id, otherUser._id],
});

const buildGroupContext = (currentUser: User, group: Group): Context => ({
  institution: currentUser.institution._id,
  group: group._id,
});

// Convert MessageUpdate to ConversationListUpdate by adding literal member.
const tagMessageUpdate = (messageUpdate: MessageUpdate): ConversationListUpdate => ({
  ...messageUpdate,
  listUpdateType: ConversationListUpdateType.NewMessageUpdate,
});

// Convert NewConversation to ConversationListUpdate by adding literal member.
const tagNewConversation = (newConversation: NewConversation): ConversationListUpdate => ({
  ...newConversation,
  listUpdateType: ConversationListUpdateType.NewConversationUpdate,
});

// Convert a message returned by the API into a full preview object.
const buildInitialPreview =
  (currentUser: User) =>
  (initialMessage: InitialMessage): ConversationPreview => {
    const basePreview = { currentUser, context: initialMessage.context, message: initialMessage };
    if (isUserConversation(initialMessage)) {
      return {
        ...basePreview,
        previewType: ConversationPreviewType.UserPreviewType,
        userInfo: initialMessage.otherUser,
      };
    } else {
      return {
        ...basePreview,
        previewType: ConversationPreviewType.GroupPreviewType,
        group: initialMessage.group,
      };
    }
  };

// Delegate update to corresponding helper.
const handleMessageUpdate = (
  currentUser: User,
  currentConversationPreviews: ConversationPreview[],
  messageUpdate: MessageUpdate,
): ConversationPreview[] => {
  switch (messageUpdate.messageAction) {
    case MessageAction.Add:
      return handleAddedMessage(currentUser, currentConversationPreviews, messageUpdate);
    case MessageAction.Edit:
      return handleEditedMessage(currentConversationPreviews, messageUpdate);
    case MessageAction.Delete:
      return handleDeletedMessage(currentConversationPreviews, messageUpdate);
    case MessageAction.DeleteAll:
    default:
      // todo..deleteall enum was added to remove all chat, which is handled in messaging.ts so for now,
      // this piece of code wouldnt be executed so we need to later figure out how to handle deleteall for
      // whatever this piece of code is doing..
      return currentConversationPreviews;
  }
};

/* Adjust conversation list after user adds a new conversation through the new
 * message dialog.
 */
const handleNewConversation = (
  currentUser: User,
  currentPreviews: ConversationPreview[],
  newConversation: NewConversation,
): ConversationPreview[] => {
  const context = isUserConversation(newConversation)
    ? buildUserContext(currentUser, newConversation.user)
    : buildGroupContext(currentUser, newConversation.group);

  const existingPreview = currentPreviews.find(contextEquals({ context }));

  if (existingPreview !== undefined) {
    // Bump existing preview to top of conversation list.
    return [existingPreview, ...currentPreviews.filter(negate(contextEquals(existingPreview)))];
  } else {
    /* Create new preview entry for this brand new conversation and insert at
     * the top of the conversation list.
     */
    const basePreview = { currentUser, context };
    // Need explicit type to prevent widening.
    const newPreview: ConversationPreview = isUserConversation(newConversation)
      ? {
          ...basePreview,
          previewType: ConversationPreviewType.UserDraftPreviewType,
          userInfo: newConversation.user,
        }
      : {
          ...basePreview,
          previewType: ConversationPreviewType.GroupDraftPreviewType,
          group: newConversation.group,
        };

    return [newPreview, ...currentPreviews];
  }
};

// Delegate update to corresponding helper.
const updateConversationPreviews =
  (currentUser: User) =>
  (
    currentPreviews: ConversationPreview[],
    listUpdate: ConversationListUpdate,
  ): ConversationPreview[] => {
    if (isMessageUpdate(listUpdate)) {
      return handleMessageUpdate(currentUser, currentPreviews, listUpdate);
    } else {
      return handleNewConversation(currentUser, currentPreviews, listUpdate);
    }
  };

// Returns a predicate based on the given identifier.
const matchesIdentifier =
  (identifier: ConversationIdentifier) =>
  (preview: ConversationPreview): boolean =>
    (isUserConversation(identifier) &&
      isUserPreview(preview) &&
      identifier.otherUserId === preview.userInfo._id) ||
    (!isUserConversation(identifier) &&
      !isUserPreview(preview) &&
      identifier.groupId === preview.group._id);

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  private _openChat = new BehaviorSubject<IOpenChat | null>(null);
  public openChat$: Observable<IOpenChat | null> = this._openChat.asObservable();

  public newPrivateMessage$ = new Subject<TypedMessage>();
  private conversationPreviewsSubscription?: Subscription;
  public openedPrivateChatUser$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(
    null,
  );
  public existingChatList$: BehaviorSubject<Set<string>> = new BehaviorSubject<Set<string>>(
    new Set(),
  );

  conversationPreviews$: Observable<ConversationPreview[]>;

  public customChatPreviews$: BehaviorSubject<ICustomChatPreview[]> = new BehaviorSubject<
    ICustomChatPreview[]
  >([]);

  selectedConversationPreview$: Observable<ConversationPreview | null>;
  selectedConversationContent$: Observable<ConversationContent>;
  selectedConversationTypingUserIds$: Observable<string[]>;

  private fetchMoreMessagesRequests$ = new Subject<TypedMessage>();
  public pendingMessages: Map<string, BehaviorSubject<Message[] | null>> = new Map();

  private selectedConversationIdentifier$ = new Subject<ConversationIdentifier | null>();
  private searchQuery$ = new BehaviorSubject<string>('');

  currentUser$: Observable<User>;
  otherUserInfo$: Observable<UserInfo[]>;

  private initialMessages$: Observable<InitialMessage[]>;
  private channelToken$: Observable<RealtimeToken>;

  private messageUpdates$: Observable<MessageUpdate>;
  private currentUserSentPrivateMessage$ = new Subject<MessageUpdate>();
  private newConversations$ = new Subject<NewConversation>();
  private newConversationHistory$ = new BehaviorSubject<NewConversation | null>(null);
  private allConversationPreviews$: Observable<ConversationPreview[]>;

  constructor(
    private httpClient: HttpClient,
    private urlService: URLService,
    private userService: UserService,
    private realtimeService: RealtimeService,
    private messengerService: MessengerService,
    private sharedDataService: SessionSharedDataService,
    private conversationService: ConversationService,
    private spaceRepo: SpaceRepository,
    private presenceRepo: PresenceRepository,
  ) {
    this.currentUser$ = this.conversationService.currentUser$;
    this.otherUserInfo$ = this.conversationService.otherUserInfo$;

    /* Make sure we reset the replay buffer when the ref count reaches zero, so
     * that we remake this request when the /messages page is loaded for the
     * second time.
     *
     * NOT the same as shareReplay({ bufferSize: 1, refCount: true })
     */
    const initialMessagesResponse$ = this.getInitialMessages().pipe(
      share({ connector: () => new ReplaySubject(1), resetOnRefCountZero: true }),
    );

    this.initialMessages$ = initialMessagesResponse$.pipe(pluck('recentMessages'));
    this.channelToken$ = initialMessagesResponse$.pipe(pluck('channelToken'));

    this.messageUpdates$ = combineLatest([this.currentUser$, this.channelToken$]).pipe(
      switchMap(([currentUser, channelToken]) =>
        merge(
          this.realtimeService
            .getUserMessageUpdates(currentUser._id, channelToken)
            .pipe(
              filter((update) =>
                filterOutCurrentUserAddedMessage(
                  update,
                  this.userService.user.value?.user._id || '',
                ),
              ),
            ),
          this.currentUserSentPrivateMessage$,
        ),
      ),

      tap((message) => {
        if (message.messageAction === MessageAction.Delete) {
          this.messengerService.deletedPrivateMessageId$.next(message._id);
          this.updateDeletedUnreadChat(message);
        }
      }),
      share(),
    );

    /* Seed previews with messages returned by `/messages/recent` endpoint, and
     * then run though reducer connected to merged update stream of new
     * conversations and message updates.
     */
    this.allConversationPreviews$ = combineLatest([this.currentUser$, this.initialMessages$]).pipe(
      switchMap(([currentUser, initialMessages]) => {
        const initialConversationPreviews = initialMessages.map(buildInitialPreview(currentUser));
        return merge(
          this.messageUpdates$.pipe(map(tagMessageUpdate)),
          this.newConversations$.pipe(map(tagNewConversation)),
        ).pipe(
          scan(updateConversationPreviews(currentUser), initialConversationPreviews),
          startWith(initialConversationPreviews),
        );
      }),
    );

    this.conversationPreviews$ = combineLatest([
      this.allConversationPreviews$,
      this.searchQuery$.pipe(distinctUntilChanged(), debounceTime(100)),
    ]).pipe(
      map(([allConversationPreviews, searchQuery]) => {
        const conversations = allConversationPreviews
          .filter(matchesSearchQuery(searchQuery))
          .sort((a: any, b: any) => this.sortMessages(a, b));
        return removeDuplicateMessages(conversations).reverse();
      }),
      shareReplay({ bufferSize: 1, refCount: false /* false for root service */ }),
    );

    this.selectedConversationPreview$ = combineLatest([
      this.selectedConversationIdentifier$,
      this.conversationPreviews$,
    ]).pipe(
      map(
        ([selectedIdentifier, conversationPreviews]) =>
          (selectedIdentifier &&
            conversationPreviews.find(matchesIdentifier(selectedIdentifier))) ||
          null,
      ),
      tap(
        (preview) =>
          preview && isUserPreview(preview) && this.userService.callReceiver.next(preview.userInfo),
      ),
      // Only emit when selection changes (regardless of search term change).
      distinctUntilChanged((previous, current) =>
        Boolean(previous && current && previewEquals(previous)(current)),
      ),
      tap(async (data) => {
        if (!data) {
          const newConversationHistory = await firstValueFrom(this.newConversationHistory$);
          if (newConversationHistory) {
            this.newConversations$.next(newConversationHistory);
            this.newConversationHistory$.next(null);
          }
        } else {
          this.newConversationHistory$.next(null);
        }
      }),
      shareReplay({ bufferSize: 1, refCount: false /* false for root service */ }),
    );

    /* Conversation content depends on three different sources:
     *  1. Initial message fetch (eg. fetch the 25 most recent messages)
     *  2. realtime updates (eg. add/update/delete individual messages)
     *  3. History updates (page-based, eg. on infinite scroll)
     *
     * Conversation content starts with the initial message fetch, and gets run
     * through a reducer whenever a realtime or history update arrives through a
     * merged update stream.
     */
    this.selectedConversationContent$ = this.selectedConversationPreview$.pipe(
      filterNonNullable(),
      switchMap(this.getInitialConversationContent.bind(this)),
      switchMap((initialContent) => {
        this.updateFailedMessagesFromThisChat(initialContent.messages);
        return merge(
          this.messageUpdates$.pipe(
            filter(contextEquals(initialContent)),
            map(buildConversationMessageUpdate),
          ),
          this.fetchMoreMessagesRequests$.pipe(
            filter(contextEquals(initialContent)),
            distinctUntilChanged((previous, current) => idEquals(previous)(current)),
            concatMap(this.getConversationHistoryUpdate.bind(this)),
          ),
        ).pipe(scan(updateConversationContent, initialContent), startWith(initialContent), share());
      }),
    );

    this.selectedConversationTypingUserIds$ = of([]);

    this.conversationService.pendingGroupChatDeleteEvent$.subscribe((event) =>
      this.updateFailedMessagesFromThisChat(event),
    );

    this.spaceRepo.currentUserLeftSpace$.subscribe(this.unsubscribePrivateChatList.bind(this));
  }

  /**
   * Manually adds successfully sent user message to the messages list
   * after removing the message from list of pending messages.
   * this is done to handle cases where current user message is sucessfully sent
   * but the realtime update is missed and the message is stuck in pending state
   * Depends on chat type: general or private chat
   * @param message
   * @
   */

  public handleLocalMessageSentSuccessfully(message: Message) {
    if (message?.context) {
      this.removeMessageFromPendingMessagesArray(encodeContext(message.context), message._id!);

      this.triggerLocalMessageUpdate(message as Message);
    }
  }

  /**
   * Triggers the correct observable based on the chat type general or private chat
   * @param message
   * @
   */
  private triggerLocalMessageUpdate(message: Message) {
    if (!message?.context) {
      return;
    }

    const chatType = getChatType(message.context);
    const messageUpdate = sentMessageToMessageUpdate(message);

    if (chatType === undefined) {
      return;
    }

    if (chatType === ChatOptions.chat) {
      this.conversationService.currentUserSentPublicMessage$.next(messageUpdate);
    } else {
      this.currentUserSentPrivateMessage$.next(messageUpdate);
    }
  }

  public updateFailedMessagesFromThisChat(messages: TypedMessage[]) {
    for (const message of messages) {
      this.removeMessageFromPendingMessagesArray(encodeContext(message.context), message._id);
    }
  }

  public openChatFromOtherComponent(data: IOpenChat): void {
    this._openChat.next(data);
  }

  public clearOpenedPrivateChat(): void {
    this._openChat.next(null);
  }

  private getInitialMessages(): Observable<InitialMessagesResponse> {
    return this.httpClient.get<InitialMessagesResponse>(
      `${this.urlService.getDynamicUrl()}/tutor/messages/recent`,
    );
  }

  public addMessageToPendingMessagesArray(message: Message) {
    const context = encodeContext(message.context);

    const currentPendingMesagesSubject = this.pendingMessages.get(context);
    const pendingMessages = currentPendingMesagesSubject?.value;
    if (pendingMessages?.length) {
      const identicalIndex = pendingMessages.findIndex((value) => value._id === message._id);
      if (message.failed && identicalIndex > -1) {
        pendingMessages[identicalIndex] = { ...message };
        currentPendingMesagesSubject?.next(pendingMessages);
      } else {
        currentPendingMesagesSubject?.next([...pendingMessages, message]);
      }
    } else {
      currentPendingMesagesSubject?.next([message]);
    }
  }

  public removeMessageFromPendingMessagesArray(context: string, messageUid: string) {
    const currentPendingMessagesSubject = this.pendingMessages.get(context);
    const pendingMessage = currentPendingMessagesSubject?.value?.filter(
      (item) => item._id !== messageUid,
    );

    if (pendingMessage && pendingMessage.length) {
      currentPendingMessagesSubject?.next(pendingMessage);
    } else {
      currentPendingMessagesSubject?.next(null);
    }
  }

  private getInitialConversationContent(
    preview: ConversationPreview,
  ): Observable<ConversationContent> {
    const { currentUser, context } = preview;
    if (isDraftPreview(preview)) {
      /* Avoid unnecessary network request since we know that there is no
       * content to fetch.
       */
      return of({
        currentUser,
        context,
        messages: [],
        totalMessages: 0,
      });
    } else {
      return this.realtimeService.realtimeInitOrReconnect$.pipe(
        switchMap(() =>
          this.conversationService.getInitialConversationContent(context).pipe(
            catchError(() => of({ user: currentUser, messages: [], total_messages: 0 })),
            map(({ user, messages, total_messages }) => ({
              currentUser: user,
              context,
              messages,
              totalMessages: total_messages,
            })),
          ),
        ),
      );
    }
  }

  private getConversationHistoryUpdate(
    oldestMessage: TypedMessage,
  ): Observable<ConversationHistoryUpdate> {
    return this.conversationService.getConversationHistoryUpdate(oldestMessage);
  }

  setNoSelectedConversation(): void {
    this.selectedConversationIdentifier$.next(null);
  }

  setSelectedUserId(selectedUserId: string): void {
    this.selectedConversationIdentifier$.next(tagUserConversation({ otherUserId: selectedUserId }));
  }

  resetSelectedUserId(): void {
    this.selectedConversationIdentifier$.next(null);
  }

  setSelectedGroupId(selectedGroupId: string): void {
    this.selectedConversationIdentifier$.next(tagGroupConversation({ groupId: selectedGroupId }));
  }

  setSearchQuery(searchQuery: string): void {
    this.searchQuery$.next(searchQuery);
  }

  addNewUserConversation(newUserConversation: NewUserConversationRequest): void {
    this.newConversations$.next(newUserConversation);
    this.newConversationHistory$.next(newUserConversation);
  }

  addNewGroupConversation({ users }: NewGroupConversationRequest): Observable<string> {
    const initialUsers = users.map(({ _id }) => _id);
    const initialName = users.map(({ name }) => name.trim()).join(', ');

    const response$ = this.httpClient
      .post<NewGroupConversationResponse>(`${this.urlService.getDynamicUrl()}/tutor/groups`, {
        name: initialName,
        users: initialUsers,
      })
      .pipe(share());

    /* Need a group ID sent back from the BE before we can push this
     * conversation onto the conversation list. Otherwise, it will have an
     * incomplete context, and can't be matched to incoming messages.
     *
     * TODO: Add optimistic update logic so that we don't need to wait for this
     * initial request before updating conversation list. Would most likely need
     * to match groups based on user array.
     */
    response$
      .pipe(
        pluck('group'),
        map((group) => tagGroupConversation({ group })),
      )
      .subscribe(this.newConversations$);

    return response$.pipe(pluck('group'), pluck('_id'));
  }

  updateMessage(message: ContentMessage): Observable<void> {
    return this.userService.updateComment(message);
  }

  deleteMessage(message: ContentMessage): Observable<void> {
    return this.userService.deleteComment(message);
  }

  fetchMoreMessages(oldestMessage: TypedMessage): void {
    this.fetchMoreMessagesRequests$.next(oldestMessage);
  }

  public unsubscribePrivateChatList() {
    if (this.conversationPreviewsSubscription) {
      this.conversationPreviewsSubscription.unsubscribe();
    }
  }

  public initializePrivateChatList(): void {
    if (this.conversationPreviewsSubscription) {
      this.conversationPreviewsSubscription.unsubscribe();
    }

    this.conversationPreviewsSubscription = combineLatest([
      this.conversationPreviews$,
      this.spaceRepo.activeSpaceUsersMap$,
      this.spaceRepo.activeSpacePopulatedUsers$,
      this.presenceRepo.activeUsersInSpace$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([previewsData, spaceUsers, users, activeUsersInSpace]) => {
        const chatPreviews = <UserConversationPreview[]>previewsData;
        const customPreview = this.syncSessionUsersWithChat(
          users,
          spaceUsers,
          chatPreviews,
          this.customChatPreviews$.value,
          activeUsersInSpace,
        );

        this.customChatPreviews$.next(customPreview as ICustomChatPreview[]);

        this.calUnreadPrivateChat();
        this.existingChatList$.next(new Set(chatPreviews.map((p) => p.userInfo?._id)));
      });
  }

  public syncSessionUsersWithChat(
    users: User[],
    spaceUsers: Map<string, SessionUser>,
    chatPreviews: UserConversationPreview[],
    customChatPreviews: ICustomChatPreview[],
    activeUsersInSpace: Set<string>,
  ): Partial<ICustomChatPreview>[] {
    return users
      .filter((user) => user)
      .map((item) => {
        const userItem: UserDataAndOnlineStatus = {
          ...item,
          role: mapSpaceOwnershipToUser(item, spaceUsers),
          presence: activeUsersInSpace.has(item._id) ? OnlineStatus.ONLINE : OnlineStatus.OFFLINE,
        };

        let message;
        let unreadChatIds = new Set<string>();

        // We need to look at the newest messages last
        [...chatPreviews]
          .sort((a: any, b: any) => this.sortMessages(a, b, -1))
          .reverse()
          .forEach((newPreview) => {
            if (newPreview.userInfo?._id === userItem?._id) {
              message = newPreview.message;

              // Set unread messages status and count for user with exiting chat history.
              // TODO: Will be best to handle in api server
              if (customChatPreviews) {
                unreadChatIds = this.getUnreadMessageCount(
                  customChatPreviews,
                  newPreview,
                  userItem,
                );
              }
            }
          });

        return { user: userItem, message, unreadChatIds };
      });
  }

  /**
   * isChatOpened
   */
  public isChatOpened() {
    const currentPanelView = this.sharedDataService.leftPanelView.getValue();
    return (
      currentPanelView?.panelView === PanelView.chat &&
      currentPanelView &&
      currentPanelView.expanded
    );
  }

  public getUnreadMessageCount(
    customChatPreviews: ICustomChatPreview[],
    newPreview: UserConversationPreview,
    item: User,
  ) {
    const unreadChatIds =
      customChatPreviews.find((el) => el.user?._id === item._id)?.unreadChatIds ||
      new Set<string>();
    for (const oldPreview of customChatPreviews) {
      if (
        oldPreview.user?._id === item._id &&
        (item._id !== this.openedPrivateChatUser$.value?._id || !this.isChatOpened())
      ) {
        this.calculateUnreadMessage(newPreview, oldPreview, item, unreadChatIds);
      }
    }

    return unreadChatIds;
  }

  public calculateUnreadMessage(
    newPreview: UserConversationPreview,
    oldPreview: ICustomChatPreview,
    item: User,
    unreadChatIds: Set<string>,
  ) {
    if (
      newPreview.message?.author._id === item._id &&
      newPreview.message.messageAction === MessageAction.Add &&
      newPreview.message._id !== oldPreview.message?._id
    ) {
      unreadChatIds.add(newPreview.message._id);
      this.newPrivateMessage$.next(newPreview.message);
    } else if (
      newPreview.message &&
      newPreview.message.messageAction === MessageAction.Delete &&
      unreadChatIds.has(newPreview.message._id)
    ) {
      unreadChatIds.delete(newPreview.message._id);
    }
  }

  public sortMessages(a: any, b: any, order = 1): number {
    if (a?.message && b?.message) {
      return sortByDate(a.message, b.message, 'createdAt', order);
    }
    return 0;
  }

  public calUnreadPrivateChat() {
    this.messengerService.unreadPrivateMessageCount.next(
      this.customChatPreviews$.value.reduce(
        (cum, cur) => (cur.unreadChatIds?.size ? cum + cur.unreadChatIds.size : cum),
        0,
      ),
    );
  }

  public updateDeletedUnreadChat(deletedChat: MessageUpdate) {
    const newCustomChatPreviews = this.customChatPreviews$.value.map((chatPreview) => {
      if (
        chatPreview.user._id === deletedChat.author._id &&
        chatPreview.unreadChatIds?.has(deletedChat._id)
      ) {
        chatPreview.unreadChatIds.delete(deletedChat._id);
      }
      return chatPreview;
    });
    this.customChatPreviews$.next([...newCustomChatPreviews]);
  }
}

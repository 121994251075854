import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { SessionStatus } from 'src/app/models/analytics';
import { first } from 'rxjs';
import { SpacesService } from 'src/app/services/spaces.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SUCCESSES } from 'src/app/common/utils/notification-constants';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from 'src/app/services/notification-toaster.service';
import { ToasterPopupStyle } from 'src/app/ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { IconMessageToasterElement } from 'src/app/ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { EndOngoingSessionComponent } from '../end-ongoing-session/end-ongoing-session.component';
import {
  IAnalyticsInsightParsed,
  IHandleEndSession,
  parseSessionTitle,
} from '../session_analytics_utils';
import { SpaceRecordingService } from '../../services/space-recording.service';

@UntilDestroy()
@Component({
  selector: 'app-session-insights-header',
  templateUrl: './session-insights-header.component.html',
  styleUrls: ['./session-insights-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionInsightsHeaderComponent {
  @Input() currentSession!: IAnalyticsInsightParsed;
  @Input() enterpriseSpace: boolean = false;
  @ViewChild('sessionTitleElement') sessionTitleElement;
  @Output() sessionTitleChanged = new EventEmitter<any>();
  @Output() endSession = new EventEmitter<IHandleEndSession>();
  SessionStatus = SessionStatus;
  isSessionTitleInvalid = false;
  isEditingSessionName = false;
  NO_CALLS_TEXT = 'No Calls';
  NO_BILLABLE_TIME_TEXT = '0 min';

  constructor(
    private spacesService: SpacesService,
    private translateService: TranslateService,
    private notificationToasterService: NotificationToasterService,
    private dialog: MatDialog,
    public spaceRecordingService: SpaceRecordingService,
  ) {}

  onEditSessionName() {
    this.isEditingSessionName = true;
    this.sessionTitleElement.nativeElement.contentEditable = true;
    this.moveCursorAtTheEnd();
  }

  cancelEditSessionName() {
    this.isEditingSessionName = false;
    this.isSessionTitleInvalid = false;
    this.sessionTitleElement.nativeElement.contentEditable = false;
    this.sessionTitleElement.nativeElement.innerText = this.currentSession.parsedSessionTitle;
  }

  async editSessionName() {
    if (this.isSessionTitleInvalid) {
      return;
    }
    this.isEditingSessionName = false;
    this.sessionTitleElement.nativeElement.contentEditable = false;
    const newSessionTitle = this.sessionTitleElement.nativeElement.innerText;
    if (newSessionTitle === this.currentSession.parsedSessionTitle) {
      return;
    }
    this.spacesService
      .renameSessionTitle(this.currentSession?.sessionId, newSessionTitle)
      .pipe(first())
      .subscribe({
        next: (res) => {
          if (res.newSessionTitle) {
            this.sessionTitleChanged.emit({
              sessionId: this.currentSession.sessionId,
              sessionTitle: newSessionTitle,
            });
          }
          this.currentSession.sessionTitle = newSessionTitle;
          this.currentSession.parsedSessionTitle = parseSessionTitle(newSessionTitle);
        },
        error: (_err) => {
          this.sessionTitleElement.nativeElement.innerText = this.currentSession.parsedSessionTitle;
        },
      });
  }

  private moveCursorAtTheEnd() {
    const selection = document.getSelection();
    const range = document.createRange();
    const contenteditable = this.sessionTitleElement.nativeElement;

    if (contenteditable.lastChild.nodeType === 3) {
      range.setStart(contenteditable.lastChild, contenteditable.lastChild.length);
    } else {
      range.setStart(contenteditable, contenteditable.childNodes.length);
    }
    selection?.removeAllRanges();
    selection?.addRange(range);
  }

  preventNewLine($event: KeyboardEvent): void {
    // 13 is the keycode for Enter
    if ($event.shiftKey && $event.key === 'Enter') {
      $event.preventDefault();
    }
  }

  isSessionTitleValid($event: KeyboardEvent): void {
    $event.preventDefault();

    this.isSessionTitleInvalid = this.sessionTitleElement.nativeElement.innerText.length >= 50;
  }

  displayEndSessionNotification() {
    const title = new IconMessageToasterElement(
      undefined,
      this.translateService.instant('Session succesfully ended'),
    );
    const endSessionNotificationData = new NotificationDataBuilder(SUCCESSES.END_SESSION)
      .type(NotificationType.SUCCESS)
      .style(ToasterPopupStyle.SUCCESS)
      .topElements([title])
      .middleElements([])
      .priority(380)
      .timeOut(3)
      .showProgressBar(true)
      .dismissable(false)
      .build();

    this.notificationToasterService.showNotification(endSessionNotificationData);
  }

  async handleEndOngoingSession(event: MouseEvent) {
    event.stopPropagation();
    const confirmEndOngoingSession = (): Promise<boolean> => {
      const confirmDialog = this.dialog.open(EndOngoingSessionComponent, {
        panelClass: 'zero-padding-dialog',
      });
      return new Promise((resolve) =>
        confirmDialog.afterClosed().pipe(untilDestroyed(this)).subscribe(resolve),
      );
    };
    if (await confirmEndOngoingSession()) {
      this.spacesService
        .endOngoingSession(this.currentSession.spaceId)
        .pipe(untilDestroyed(this))
        .subscribe((res: IHandleEndSession) => {
          if (res.newSessionId) {
            this.displayEndSessionNotification();
            this.endSession.emit(res);
          }
        });
    }
  }
}

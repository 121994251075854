import { FingerType, FingerCurl, FingerDirection, HandInfo } from './finger';

// Gesture number based on emojiId in src/app/services/ui.service.ts
export enum GestureName {
  THUMBS_UP = 'THUMBS_UP',
  THUMBS_DOWN = 'THUMBS_DOWN',
  VICTORY_HANDS = 'VICTORY_HANDS',
  HAND_RAISED = 'HAND_RAISED',
}

export class Gesture {
  private gestureName: GestureName;
  private curls: Record<FingerType, Array<FingerCurl>>;
  private directions: Record<FingerType, Array<FingerDirection>>;
  private linearOpenY: Record<FingerType, boolean>;
  private linearOpenX: Record<FingerType, boolean>;
  private linearX?: boolean;

  constructor(gestureName: GestureName) {
    this.gestureName = gestureName;
    this.curls = {} as Record<FingerType, Array<FingerCurl>>;
    this.directions = {} as Record<FingerType, Array<FingerDirection>>;
    this.linearOpenY = {} as Record<FingerType, boolean>;
    this.linearOpenX = {} as Record<FingerType, boolean>;
  }

  addCurl(finger: FingerType, curl: FingerCurl): void {
    if (!Object.prototype.hasOwnProperty.call(this.curls, finger)) {
      this.curls[finger] = [];
    }
    this.curls[finger].push(curl);
  }

  addCurls(finger: FingerType, curl: Array<FingerCurl>): void {
    if (!Object.prototype.hasOwnProperty.call(this.curls, finger)) {
      this.curls[finger] = [];
    }
    this.curls[finger] = this.curls[finger].concat(curl);
  }

  addDirection(finger: FingerType, direction: FingerDirection): void {
    if (!Object.prototype.hasOwnProperty.call(this.directions, finger)) {
      this.directions[finger] = [];
    }
    this.directions[finger].push(direction);
  }

  addDirections(finger: FingerType, direction: Array<FingerDirection>): void {
    if (!Object.prototype.hasOwnProperty.call(this.directions, finger)) {
      this.directions[finger] = [];
    }
    this.directions[finger] = this.directions[finger].concat(direction);
  }

  addLinearOpenY(finger: FingerType, isLinearOpenY: boolean): void {
    this.linearOpenY[finger] = isLinearOpenY;
  }

  addLinearOpenX(finger: FingerType, isLinearOpenX: boolean): void {
    this.linearOpenX[finger] = isLinearOpenX;
  }

  addLinearX(isLinearX: boolean) {
    this.linearX = isLinearX;
  }

  getFingersWithCurls(): Array<number> {
    return Object.keys(this.curls).map((e) => parseInt(e, 10));
  }

  getFingersWithDirection(): Array<number> {
    return Object.keys(this.directions).map((e) => parseInt(e, 10));
  }

  getFingersWithLinearOpenY(): Array<number> {
    return Object.keys(this.linearOpenY).map((e) => parseInt(e, 10));
  }

  getFingersWithLinearOpenX(): Array<number> {
    return Object.keys(this.linearOpenX).map((e) => parseInt(e, 10));
  }

  getFingerCurl(finger: FingerType): Array<FingerCurl> {
    return this.curls[finger];
  }

  getFingerDirection(finger: FingerType): Array<FingerDirection> {
    return this.directions[finger];
  }

  getFingerLinearOpenY(finger: FingerType): boolean {
    return this.linearOpenY[finger];
  }

  getFingerLinearOpenX(finger: FingerType): boolean {
    return this.linearOpenX[finger];
  }

  getName(): GestureName {
    return this.gestureName;
  }

  getLinearX() {
    return this.linearX;
  }
}

export class GestureDetector {
  static detect(hand: HandInfo, gesture: Gesture): boolean {
    const handInfo = hand.allFingers;
    // Part 1 - Check if all required curls are present
    const gestureCurlFingers = gesture.getFingersWithCurls();
    for (const finger of gestureCurlFingers) {
      const actualCurl = handInfo[finger].fingerCurl;
      const expectedCurls = gesture.getFingerCurl(finger);
      if (!expectedCurls.includes(actualCurl)) {
        return false;
      }
    }

    // Part 2 - Check if all required curls are present
    const gestureDirectionFingers = gesture.getFingersWithDirection();
    for (const finger of gestureDirectionFingers) {
      const actualDirection = handInfo[finger].fingerDirection;
      const expectedDirections = gesture.getFingerDirection(finger);

      if (!expectedDirections.includes(actualDirection)) {
        return false;
      }
    }

    // Part 3 - Check for linear curl
    const gestureLinearOpenY = gesture.getFingersWithLinearOpenY();
    for (const finger of gestureLinearOpenY) {
      const actualLinearOpen =
        gesture.getName() === GestureName.HAND_RAISED && finger === FingerType.Thumb
          ? handInfo[finger].isLinearOpenY || handInfo[finger].isLinearOpenX
          : handInfo[finger].isLinearOpenY;
      const expectedLinearOpen = gesture.getFingerLinearOpenY(finger);
      if (actualLinearOpen !== expectedLinearOpen) {
        return false;
      }
    }

    const gestureLinearOpenX = gesture.getFingersWithLinearOpenX();
    for (const finger of gestureLinearOpenX) {
      const actualLinearOpen = handInfo[finger].isLinearOpenX;
      const expectedLinearOpen = gesture.getFingerLinearOpenX(finger);
      if (actualLinearOpen !== expectedLinearOpen) {
        return false;
      }
    }

    // Part 4 - Check for linear X
    const expectedLinearX = gesture.getLinearX();
    if (expectedLinearX !== undefined) {
      const actualLinearX = hand.isLinearX;
      if (expectedLinearX !== actualLinearX) {
        return false;
      }
    }
    return true;
  }
}

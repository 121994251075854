<header class="modal-header">
  <h2>{{ (updateMode ? 'Rename ' : 'Add ') + 'Site' | translate }}</h2>
</header>
<form [formGroup]="siteForm" class="modal-body">
  <div class="edit-option">
    <label class="subtitle" for="name">{{ 'Name' | translate }}</label>
    <input
      autocomplete="off"
      class="name-input"
      type="text"
      id="name"
      placeholder="e.g. Literacy"
      formControlName="name"
      [class.invalid-input]="siteForm.controls.name.invalid && siteForm.controls.name.touched"
    />
    <p class="error-text">
      <span *ngIf="siteForm.controls.name.errors?.required && siteForm.controls.name.touched">
        {{ 'This field is required.' | translate }}
      </span>
      <span *ngIf="siteForm.controls.name.errors?.whitespace">
        {{ 'This field cannot contain only whitespace.' | translate }}
      </span>
    </p>
  </div>
</form>
<footer class="modal-footer">
  <app-pencil-button
    class="pencil-button medium light"
    [label]="'Cancel' | translate"
    [icon]="'close'"
    [iconConfig]="{ type: IconTypes.matIcon }"
    (clickEvent)="onCancel()"
    buttonType="button"
  ></app-pencil-button>
  <app-pencil-button
    class="pencil-button medium primary"
    [label]="(updateMode ? 'Update' : 'Add site') | translate"
    [icon]="updateMode ? 'check' : 'add'"
    [iconConfig]="{ type: IconTypes.matIcon }"
    buttonType="submit"
    [disabled]="updateButtonDisabled$ | async"
    (clickEvent)="onSubmit()"
  ></app-pencil-button>
</footer>

import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, filter, map, merge, of, switchMap } from 'rxjs';
import { RTCCloudRecording } from '../common/interfaces/rtc-interface';
import { RECORDING_STATE, RECORD_STATUS, RecordingService } from '../models/recording';
import { ProviderStateService } from './provider-state.service';
import { RtcServiceController } from './rtc.service';

@Injectable({
  providedIn: 'root',
})
export class SpaceCloudRecordingService implements RecordingService {
  public state$: BehaviorSubject<RECORDING_STATE> = new BehaviorSubject<RECORDING_STATE>({
    _type: RECORD_STATUS.READY,
  });
  public output$ = EMPTY;

  constructor(
    private providerStateService: ProviderStateService,
    private rtcService: RtcServiceController,
  ) {
    const callConnectedRecordingState$ = this.providerStateService.callConnected$.pipe(
      filter((callConnected) => !callConnected),
      map((): RECORDING_STATE => ({ _type: RECORD_STATUS.READY })),
    );

    const recordingState$ = this.providerStateService.recordingEvent$.pipe(
      switchMap((recordingEvent): Observable<RECORDING_STATE> => {
        switch (recordingEvent._type) {
          case 'STARTED':
            return of({
              _type: RECORD_STATUS.ACTIVE,
              initiatorParticipantId: recordingEvent.initiatorParticipantId,
            });
          case 'STOPPED':
            if (recordingEvent.err) {
              return of<RECORDING_STATE[]>(
                { _type: RECORD_STATUS.FINISHED, err: recordingEvent.err },
                { _type: RECORD_STATUS.READY },
              );
            } else {
              return of({ _type: RECORD_STATUS.READY });
            }
        }
      }),
    );

    merge(callConnectedRecordingState$, recordingState$).subscribe(this.state$);
  }

  async startRecording(): Promise<void> {
    const service = this.rtcService.service;
    if (RTCCloudRecording.isRecordable(service)) {
      service.startRecording();
    } else {
      throw new Error('Expected the RTC provider to implement RTCCloudRecording');
    }
  }

  async stopRecording(): Promise<void> {
    const service = this.rtcService.service;
    if (RTCCloudRecording.isRecordable(service)) {
      service.stopRecording();
      this.state$.next({ _type: RECORD_STATUS.FINISHED });
      this.state$.next({ _type: RECORD_STATUS.READY });
    } else {
      throw new Error('Expected the RTC provider to implement RTCCloudRecording');
    }
  }
}

import { TranslateService } from '@ngx-translate/core';

import { Feature, Personas } from '../services/acl.service';
import { PaywallConfig } from '../admin/types.interface';
import { Context } from './context';
import { SUBSCRIPTION_TYPES } from './payment';
import { Fragment, Question } from './question';

export enum CONNECTION_TYPES {
  CHILD = 'child',
  SESSION_USER = 'session_user',
}

export enum INVITATION_STATE {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
}

export enum REVOKED_TYPES {
  SUSPENDED = 'SUSPENDED',
  DELETED = 'DELETED',
}

export enum CREATE_ACCOUNT_STATUS {
  SUSPENDED = 'SUSPENDED',
  DELETED = 'DELETED',
  IN_INSTITUTION = 'IN_INSTITUTION',
}

export enum EMAIL_CODE_ACTION {
  RESET_PASSWORD = 'resetPassword',
  VERIFY_EMAIL = 'verifyEmail',
  SIGN_IN = 'signIn',
}

export enum EMAIL_CODE_ERROR {
  LOCKOUT = 'LOCKOUT',
  INVALID_CODE = 'INVALID_CODE',
  EXPIRED = 'EXPIRED',
}

export interface Message {
  _id: string;
  // TODO(dt): Add mentions and likes.
  content: Fragment[];
  context: Context;

  // @TODO mfmansoo deprecate the timestamps https://pncl.atlassian.net/browse/BUGS-130
  published_timestamp: string;
  edited_timestamp: string;
  author: UserInfo;
  poster: UserInfo | BotInfo;
  posterType: PosterType;
  mentions: string[];
  log?: string;

  firstAttemptTimestamp?: string;
  parent?: string;
  createdAt: string;
  updatedAt: string;
  clicked?: boolean;

  deleted?: boolean;
  deletedByHost?: boolean;
  failed: boolean;
  isEdited?: boolean;

  currentSession?: string;
  currentBreakoutRoomUid?: string;
}

export interface MessageCreateResponse {
  message: string;
  message_id: string;
}

export interface MessageList {
  messages: Message[];
  total_messages: number;
  siblings?: UserInfo[];
  tutor_edits?: any[];
  user: User;
}

export interface LikeContext {
  question?: string;
  explanation?: string;
  worksheet?: string;
}

export interface LikeState {
  state: boolean;
  num_likes: number;
}

export interface Employment {
  startDate: string;
  endDate: string;
  employerName: string;
}

export interface Location {
  coordinates: number[];
  // The possiblities are:
  // 1) Point
  type: string;
}

export interface UserInfoBase {
  _id: string;
  courses: string[];
  dateOfBirth: string;
  experience: Employment[];
  location: Location;
  profile_image: string;
  name: string;
  org_name: string;
  email: string;
  role: string;
  questionAuthored: number;
  explanationAuthored: number;
  startDate: string;
  tenure: number;
  title: string;
}

export interface BotInfoBase {
  _id: string;
  name: string;
}

export interface UserInfo extends UserInfoBase {
  institution: string;
}

export interface BotInfo extends BotInfoBase {
  institution: string;
}

export enum PosterType {
  User = 'User',
  BotUser = 'BotUser',
}

export interface InstitutionTheme {
  colors: { [key: string]: string };
}

export enum RecordingMode {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
  DISABLED = 'DISABLED',
}

export enum InstitutionPermissionState {
  DEFAULT_ON = 'DEFAULT_ON',
  DEFAULT_OFF = 'DEFAULT_OFF',
  ALWAYS_ON = 'ALWAYS_ON',
  ALWAYS_OFF = 'ALWAYS_OFF',
}

export interface InstitutionSettings {
  // ---- Admin Settings ----
  hidePoweredByPencil: boolean;
  enableAutomaticCloudRecording: boolean;
  enableCloudRecordingAI?: boolean;
  enablePrivacyAwareCloudRecording: boolean;
  enableLiveCallAlerts: boolean;
  enableWebViewer?: boolean;
  // ---- User Settings ----
  addAdminsAsHostsInSpace: boolean;
  autoDesignateTeachersAsHosts: boolean;
  preventTeachersfromCreatingSpaces: boolean;
  inactivityNotifications: boolean;
  crRecordingMode: RecordingMode;
  crEnableHostDeletion: boolean;
  crEnableDownloads: boolean;
  crEnableAISummarization?: boolean;
  forceScreenShareInAutomaticCr: boolean;
  crEnablePrivacyAwareCloudRecording: boolean;
  liveCallAlerts: boolean;
  disablePencilFiles: boolean;
}

export interface InstitutionPermissions {
  enableWaitingRoom: InstitutionPermissionState;
  messageOtherParticipants: InstitutionPermissionState;
}

export interface Site {
  _id: string;
  institution: Institution;
  name: string;
  description?: string;
}

export interface Institution {
  _id: string;
  description: string;
  email: string;
  name: string;
  phone: string;
  logo: string;
  location: string[];
  personas: Record<string, IInstitutionPersona>;
  hero_image?: string;
  default_theme?: string;
  theme?: InstitutionTheme;
  virtualBackgroundsFolder?: string;
  settings?: InstitutionSettings;
  permissions?: InstitutionPermissions;
  brandChiron?: string;
  sites?: Site[];
  paywallConfig?: PaywallConfig;
}

export interface IInstitutionPersona {
  name: string;
  features: Feature[];
  accentColor: string;
}

export interface UserOverview {
  profiles: UserInfo[];
  institution: Institution;
}

export interface IndividualUserOverview extends UserInfoBase {
  questions: Question[];
  institution: Institution;
  description: string;
  directory_profile: any;
  subjects: any[];
  enabled_features: string[];
  isAnonymous?: boolean;
  personas?: string[];
}

export interface Connection {
  user: string;
  connectionType: CONNECTION_TYPES;
}

export interface Invitation {
  invitedBy: string;
  invitationState: INVITATION_STATE;
}

export interface IUserInfo {
  user: User;
  realtime: {
    realtime_user_notification: any;
    realtime_user_event_notification: any;
    realtime_rtm_channel: any;
  };
  intercom: { intercomToken: any };
  status_code: any;
}

export interface ISyllabus {
  user: User;
  total_questions: number;
  topics: TopicRootNode[];
  topics_coverage: CoverageStats[];
  topics_distribution: DistributionData[];
  tutor_stats: TutorStats[];
}
export interface DistributionData {
  data: {
    id: string;
    stats: DistributionStats;
  };
  children: DistributionData[];
}
export interface DistributionStats {
  numQuestions: number;
  numNotes: number;
  multipleChoiceQuestions: number;
  openEndedQuestions: number;
  badQuestions: number;
  averageQuestions: number;
  goodQuestions: number;
  excellentQuestions: number;
  veryEasyQuestions: number;
  easyQuestions: number;
  difficultQuestions: number;
  veryDifficultQuestions: number;
  topicIndex: number;
  distanceFromRoot: number;
}

export interface TutorStats {
  courseId: string;
  stats: Stats[];
}

export interface Stats {
  date: string;
  count: number;
}

export interface TimeZone {
  autoDetect: boolean;
  value: string;
}

export interface User {
  courses: Course[];
  qualifications: any[];
  certificates: any[];
  _id: string;
  externalId?: string;
  profile_image: string;
  name: string;
  org_name: string;
  email: string;
  role: string;
  institution: Institution;
  enabled_features?: string[];
  personas?: Personas[];
  settings?: Settings;
  billingStatus?: any;
  freeTrial?: boolean;
  isApiManaged?: boolean;
  isAnonymous?: boolean;
  feature_flags?: Record<string, boolean>;
  feature_flag_variables?: Record<string, Record<string, string | number>>;
  last_notifications_viewed_timestamp?: Date;
  phone?: string;
  description?: string;
  questionsAuthored?: number;
  explanationsAuthored?: number;
  lastLogin: string | null;
  location: string;
  createdDate: string;
  sites?: Site[];
  password?: string;
  connections?: Connection[];
  invitation?: Invitation;
  info?: Info;
  createdAt: string;
  updatedAt: string;
  spacesFreeTimeLeft?: Date;
  revoked?: Revoked;
  custom_attributes?: string[];
  // this is the key to be used for public api call, this is refresh token for now
  generatedApiKey: boolean;
  subscriptionType?: SUBSCRIPTION_TYPES;
  apiKeyGeneratedOn: Date;
  defaultSpaceCreated: boolean;
  timezone: TimeZone;
  virtualBackgroundsFolder?: string;
}

export enum OnlineStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  IN_CALL = 'IN_CALL',
  PENDING = 'PENDING',
}

export enum UserRole {
  HOST = 'host',
  PARTICIPANT = 'participant',
}

export type UserDataAndOnlineStatus = User & { presence?: OnlineStatus; role: UserRole };

export interface SpacePopulatedUser extends Omit<User, 'institution'> {
  institution: string;
}

export interface NewUser {
  name: string;
  email: string;
  personas: string[];
  password: string;
  sites?: string[];
  location?: string;
}

export interface Settings {
  lang_interface?: string;
  ui_theme?: string;
  inactivityNotifications?: string;
}

export interface Course {
  _id: string;
  grade: string;
  subject: string;
  syllabus_code: string;
  name: string;
  description?: string;
  image_url?: string;
  institution_name?: string;
  institution_logo?: string;
  institution?: Institution;
}

export interface CoverageStats {
  courseId: string;
  coverage: number;
  syllabus_code: string;
  question_count: number;
  note_count: number;
}

export interface Topic {
  topic: string;
  score: number;
}

export interface StudentStatistics {
  striveScore: number;
  totalPracticed: number;
  topicScores: Topic[];
}

export interface Student {
  fullName: string;
  profilePic: string;
  statistics: StudentStatistics;
}

export interface Syllabus {
  code: string;
  year: string;
  board: string;
  examination: string;
}

export interface TopicNode {
  _id: string;
  name: string;
  child: TopicNode;
  numbering: string;
}

export interface TopicRootNode {
  _id: string;
  syllabus: Syllabus;
  position: number;
  topic_node: TopicNode;
  name?: string;
}

export interface TopicList {
  topic_roots: TopicRootNode[];
}

export interface AttributeValue {
  _id: string;
  value: string;
}

export interface CustomAttribute {
  attribute_key: AttributeKey;
  attribute_value: AttributeValue[];
  type?: string;
  isMulti?: boolean;
}

export interface AttributeKey {
  _id: string;
  value: string;
}

export interface CustomAttributes {
  custom_attributes: CustomAttribute[];
}

export class UserDirectoryService {
  label = '';
  content = '';
  contact = '';
  link = '';
}

export class UserDirectoryEvent {
  month = '';
  date = '';
  name = '';
  location = '';
  description = '';
}

export class UserDirectoryExperience {
  institution = '';
  role = '';
  period = '';
  location = '';
  logo = '';
}

export const PERSONAS_TO_ROLES = {
  Administrator: 'Admin',
  CoachManager: 'Coach Manager',
  Teacher: 'Tutor',
  Student: 'Student',
  DataentryReviewer: 'Data Entry Reviewer',
  DataentryAgent: 'Data Entry Agent',
  InstitutionAdmin: 'Institution Admin',
  TeachingAssistant: 'Teaching Assistant',
};

export interface UserData {
  user: User;
  intercom?: any;
}

export enum USER_SCHOOL_ROLE {
  YES_PRIVATE = 'yes_private',
  YES_TA = 'yes_TA',
  NO_FUTURE = 'no_future',
  NO = 'no',
}

export enum USER_WORK_ROLE {
  TEACHER = 'teacher',
  TUTOR = 'tutor',
  ADMIN = 'admin',
  COACH = 'coach',
  OTHER = 'other',
}

export interface Info {
  role?: string;
  userInSchool?: boolean;
  userSchoolRole?: USER_SCHOOL_ROLE;
  userWorkRole?: USER_WORK_ROLE;
  userWorkRoleOther?: string;
  roleType?: string;
  roleOther?: string;
  questionnaireComplete: boolean;
}

export interface Revoked {
  type: REVOKED_TYPES;
  date: Date;
  note?: string;
  showRevokedModal: boolean;
  institution?: Institution; // set to optional for backwards compatibility
}

export enum SIGNUP_ROLES {
  SCHOOL_TEACHER = 'School Teacher',
  STUDENT = 'Student',
  OTHER = 'Other',
  TUTOR = 'Teacher / Tutor',
  TUTOR_ADMIN = 'School / Tutoring Administrator',
  TECH = 'Tech company',
  CREATOR = 'Construction, architecture & design',
  REAL_ESTATE = 'Real-estate',
  LEARNING = 'Learning & Development',
}

export const roleValueIDs = {
  student: 'student',
  teacher: 'teacher',
  admin: 'admin',
  other: 'other',
  techCompany: 'techCompany',
  construction: 'contruction',
  realEstate: 'realEstate',
  development: 'development',
};

export const roleIDs = {
  education: 'education',
  business: 'business',
};

export class UserConstantsTransformer {
  roleTypesDef: any;
  constructor(private translateService: TranslateService) {
    this.roleTypesDef = {
      [roleIDs.education]: this.translateService.instant('Education'),
      [roleIDs.business]: this.translateService.instant('Business'),
    };
  }

  get roleTypeIDs() {
    return roleIDs;
  }

  get roles() {
    return this.roleTypesDef;
  }

  get roleValueIds() {
    return roleValueIDs;
  }

  get roleTypes() {
    return {
      [roleIDs.education]: [
        {
          name: roleValueIDs.student,
          value: this.translateService.instant('Student'),
        },
        {
          name: roleValueIDs.teacher,
          value: this.translateService.instant('Teacher / Tutor'),
        },
        {
          name: roleValueIDs.admin,
          value: this.translateService.instant('School / Tutoring Administrator'),
        },
        {
          name: roleValueIDs.other,
          value: this.translateService.instant('Other (please specify)'),
        },
      ],
      [roleIDs.business]: [
        {
          name: roleValueIDs.techCompany,
          value: this.translateService.instant('Tech company'),
        },
        {
          name: roleValueIDs.construction,
          value: this.translateService.instant('Construction, architecture & design'),
        },
        {
          name: roleValueIDs.realEstate,
          value: this.translateService.instant('Real-estate'),
        },
        {
          name: roleValueIDs.development,
          value: this.translateService.instant('Learning & Development'),
        },
        {
          name: roleValueIDs.other,
          value: this.translateService.instant('Other (please specify)'),
        },
      ],
    };
  }
}

export type UserTypes = User | IndividualUserOverview | SpacePopulatedUser;

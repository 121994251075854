import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AvailabilityStatus } from '../../models/availability';
import { IconTypes } from '../../standalones/components/pencil-icon/pencil-icon.component';

@Component({
  selector: 'app-tutor-availability-status[status]',
  templateUrl: './tutor-availability-status.component.html',
  styleUrls: ['./tutor-availability-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TutorAvailabilityStatusComponent {
  availabilityStatuses = AvailabilityStatus;
  IconTypes = IconTypes;

  @Input() status!: AvailabilityStatus;
}

import { Injectable } from '@angular/core';
import { distinctUntilChanged, filter, map, Observable, startWith, Subject } from 'rxjs';
import { isEqual } from 'lodash';
import { ItemModel } from '../../../models/session';
import { isApp } from '../iframe/additional-apps.utils';
import { FLAGS, FlagsService } from '../../../services/flags.service';
import { UserService } from '../../../services/user.service';
import { CanvasItem } from './items-canvas.component';

export interface AppState {
  interactionsLocked?: boolean;
  available?: boolean;
  isPrivate?: boolean;
  isHidden?: boolean;
}

export interface WebViewerState {
  webViewerHbSessionId?: string;
  webViewerUrl?: string;
  webViewerSnapshotHbSessionId?: string;
}
export interface IframeState {
  sharedUrl?: string;
}

export interface QuestionState {
  showHint?: boolean;
  showAnswer?: boolean;
  answers?: Map<string, string>;
}

export interface FormulaState {
  formula?: string;
  backgroundColor?: string;
  fontColor?: string;
  fontSize?: number;
}

@Injectable({
  providedIn: 'root',
})
export class CanvasItemsDataObserverService {
  private _canvasItemUpdated = new Subject<CanvasItem>();

  constructor(private userService: UserService, private flagsService: FlagsService) {}

  public notifyCanvasItemListeners(canvasItem: CanvasItem) {
    this._canvasItemUpdated.next(canvasItem);
  }

  formulaUpdated$(canvasItemId: string): Observable<FormulaState> {
    return this._canvasItemUpdated.pipe(
      filter(
        (canvasItem) => canvasItem.type === ItemModel.Formula && canvasItem.id === canvasItemId,
      ),
      map(
        (canvasItem): FormulaState => ({
          formula: canvasItem.itemSettings?.resourceComponentInputs?.fragment?.fragment.data,
          fontColor: canvasItem.itemState.fontColor,
          backgroundColor: canvasItem.itemState.backgroundColor,
          fontSize: canvasItem.itemState.fontSize,
        }),
      ),
      distinctUntilChanged(isEqual),
    );
  }

  questionUpdated$(canvasItemId: string): Observable<QuestionState> {
    return this._canvasItemUpdated.pipe(
      filter(
        (canvasItem) => canvasItem.type === ItemModel.Question && canvasItem.id === canvasItemId,
      ),
      map(
        (canvasItem): QuestionState => ({
          showHint: canvasItem.relatedItem?.question_state?.show_hint,
          showAnswer: canvasItem.relatedItem?.question_state?.show_answer,
          answers: canvasItem.itemSettings?.componentInputs?.usersAnswers,
        }),
      ),
      distinctUntilChanged(isEqual),
    );
  }

  appUpdated$(canvasItem: CanvasItem): Observable<AppState> {
    return this._canvasItemUpdated.pipe(
      filter(
        (currentCanvasItem) =>
          currentCanvasItem.id === canvasItem.id && isApp(currentCanvasItem.type),
      ),
      startWith(canvasItem),
      map(
        (currentCanvasItem): AppState => ({
          available: currentCanvasItem.relatedItem?.available,
          isPrivate: currentCanvasItem.relatedItem?.isPrivate,
          interactionsLocked: currentCanvasItem.relatedItem?.interactionsLocked,
          isHidden: this.shouldHideApp(currentCanvasItem),
        }),
      ),
      distinctUntilChanged(isEqual),
    );
  }

  webViewerUpdated$(canvasItem: CanvasItem): Observable<WebViewerState> {
    return this._canvasItemUpdated.pipe(
      filter(
        (currentCanvasItem) =>
          currentCanvasItem.id === canvasItem.id && currentCanvasItem.type === ItemModel.WebViewer,
      ),
      map(
        (currentCanvasItem): WebViewerState => ({
          webViewerHbSessionId: currentCanvasItem.relatedItem?.browser_state?.hbSessionId,
          webViewerUrl: currentCanvasItem.relatedItem?.browser_state?.url,
          webViewerSnapshotHbSessionId:
            currentCanvasItem.relatedItem?.browser_state?.snapshotHbSessionId,
        }),
      ),
      distinctUntilChanged(isEqual),
    );
  }

  iFrameUpdated$(canvasItem: CanvasItem): Observable<IframeState> {
    return this._canvasItemUpdated.pipe(
      startWith(canvasItem),
      filter(
        (currentCanvasItem) =>
          currentCanvasItem.id === canvasItem.id && currentCanvasItem.type === ItemModel.IFrame,
      ),
      map(
        (currentCanvasItem): IframeState => ({
          sharedUrl: currentCanvasItem.relatedItem?.iframe_state?.sharedUrl,
        }),
      ),
      distinctUntilChanged(isEqual),
    );
  }

  zIndexUpdated$(canvasItemId: string): Observable<number> {
    return this._canvasItemUpdated.pipe(
      filter((canvasItem) => canvasItemId === canvasItem.id),
      map((canvasItem): number => canvasItem.itemState.index),
      distinctUntilChanged(),
    );
  }

  private shouldHideApp(item: CanvasItem): boolean {
    const appCreatorId = item.relatedItem?.iframe_state?.ownerId ?? item.relatedItem?.ownerId ?? '';
    const isAppOwner = this.userService.userId === appCreatorId;
    const privateSwitchEnabled = this.flagsService.isFlagEnabled(FLAGS.APPS_PRIVACY_SWITCH);
    const appPrivate = item.relatedItem?.isPrivate ?? false;
    return privateSwitchEnabled && appPrivate && !isAppOwner;
  }
}

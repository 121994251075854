import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  firstValueFrom,
  map,
  shareReplay,
  switchMap,
  takeWhile,
} from 'rxjs';
import { SessionStatus } from 'src/app/models/analytics';
import { TelemetryService } from 'src/app/services/telemetry.service';
import { SpaceRepository } from 'src/app/state/space.repository';
import { modifiedTimer } from 'src/app/utilities/ZoneUtils';
import { ComponentChanges } from '../../common/utils/component-change';
import { SUCCESSES } from '../../common/utils/notification-constants';
import { ConfirmationModalComponent } from '../../dialogs/confirmation-modal/confirmation-modal.component';
import { RecordingAccess, SpaceRecording } from '../../models/space-recording';
import { ModalManagerService } from '../../services/modal-manager.service';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from '../../services/notification-toaster.service';
import { SpacesService } from '../../services/spaces.service';
import { ToasterPopupStyle } from '../../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { IconMessageToasterElement } from '../../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { IAnalyticsInsightParsed, SpaceDataLevel } from '../session_analytics_utils';
import { SpaceRecordingService } from '../../services/space-recording.service';
import { RecordingPreviewModalComponent } from './recording-preview-modal/recording-preview-modal.component';

interface PartialRoom {
  roomUid: string;
  roomName: string;
}

@UntilDestroy()
@Component({
  selector: 'app-analytics-recording-table',
  templateUrl: './analytics-recording-table.component.html',
  styleUrls: ['./analytics-recording-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsRecordingTableComponent implements OnChanges {
  @Input() currentSession?: IAnalyticsInsightParsed;
  readonly SessionStatus = SessionStatus;
  readonly Math = Math;
  private currentSession$: Subject<IAnalyticsInsightParsed> = new Subject();

  // All of the recordings for the currentSession
  private recordings$ = new BehaviorSubject<SpaceRecording[]>([]);

  // Store the id of the room that we are viewing recordings for
  selectedRoom$ = new BehaviorSubject<string | SpaceDataLevel.SPACE>(SpaceDataLevel.SPACE);

  // Rooms which are active & have recording for this session
  activeRooms$ = new BehaviorSubject<Array<PartialRoom>>([]);
  // Rooms which are deleted & have recording for this session
  archivedRooms$ = new BehaviorSubject<Array<PartialRoom>>([]);

  // List of recordings for the selected room
  recordingsForSelectedRoom$: Observable<SpaceRecording[]> = combineLatest([
    this.selectedRoom$,
    this.recordings$,
  ]).pipe(
    map(([selectedRoom, recordings]) =>
      selectedRoom === SpaceDataLevel.SPACE
        ? recordings
        : recordings.filter((r) => r.roomId === selectedRoom),
    ),
  );

  RecordingAccessEnumToTextMapping = {
    [RecordingAccess.HOSTS]: this.translateService.instant('Hosts only'),
    [RecordingAccess.HOSTS_AND_PARTICIPANTS]:
      this.translateService.instant('Hosts and participants'),
  };
  RecordingAccessEnumKeys = Object.keys(this.RecordingAccessEnumToTextMapping);
  RecordingAccessEnums = RecordingAccess;

  constructor(
    private spacesService: SpacesService,
    private notificationToasterService: NotificationToasterService,
    private translateService: TranslateService,
    private modalManagerService: ModalManagerService,
    public spaceRepo: SpaceRepository,
    private telemetry: TelemetryService,
    public spaceRecordingService: SpaceRecordingService,
  ) {
    // Create a subscription to hydrate the behavior subject containing the recordings for the entire session
    this.currentSession$
      .pipe(
        // When the current session changes, fetch the new recordings
        switchMap(this.getRecordings.bind(this)),
        map((r) => r.recordings),
        shareReplay({ bufferSize: 1, refCount: true }), // ensure that the result is multicasted
        untilDestroyed(this),
      )
      .subscribe((recordings) => {
        this.recordings$.next(recordings);
        const seenUids = new Set<string>();
        const activeRooms: Array<PartialRoom> = [];
        const archivedRooms: Array<PartialRoom> = [];
        for (const recording of recordings) {
          if (seenUids.has(recording.roomId)) {
            continue;
          }
          seenUids.add(recording.roomId);
          const analyticsRoomObj = this.currentSession?.roomAttendees.find(
            // roomId for Main Room is stored as 'MainRoom' in recordings collection
            (room) =>
              recording.roomId === 'MainRoom'
                ? room.roomUid === `${recording.spaceId}:${recording.roomId}`
                : room.roomUid === recording.roomId,
          );
          const roomObj = {
            roomUid: recording.roomId,
            roomName: analyticsRoomObj ? analyticsRoomObj.roomName : 'Breakout Room',
          };
          if (analyticsRoomObj?.isActive) {
            activeRooms.push(roomObj);
          } else {
            archivedRooms.push(roomObj);
          }
          this.activeRooms$.next(activeRooms);
          this.archivedRooms$.next(archivedRooms);
        }
      });

    // Refresh recordings in regular intervals for current room if the room is live
    this.currentSession$
      .pipe(
        distinctUntilChanged(), // Only fire if current session is different to previous
        switchMap((session) =>
          modifiedTimer(10000, 10000).pipe(
            takeWhile(() => this.currentSession?.status !== SessionStatus.PROCESSED),
            map(() => this.currentSession$.next(session)), // Force a refresh
          ),
        ),
        untilDestroyed(this),
      )
      .subscribe();
  }

  ngOnChanges(changes: ComponentChanges<AnalyticsRecordingTableComponent>): void {
    if (this.currentSession && changes.currentSession) {
      this.currentSession$.next(this.currentSession);
      if (
        changes.currentSession.previousValue?.sessionId !==
        changes.currentSession.currentValue?.sessionId
      ) {
        this.onChangeSession();
      }
    }
  }

  getRecordings(session: IAnalyticsInsightParsed): Observable<{ recordings: SpaceRecording[] }> {
    const startTime: string | null = new Date(session.sessionStartTime).toISOString();
    const endTime: string | Date = session.sessionEndTime
      ? new Date(session.sessionEndTime).toISOString()
      : new Date().toISOString();

    if (!startTime) {
      throw new Error('expected the start time to be defined');
    }
    return this.spacesService.getRecordings(session.spaceId, startTime, endTime);
  }

  viewRecording(recording: SpaceRecording): void {
    this.modalManagerService.showModal(RecordingPreviewModalComponent, {
      data: { recording },
      panelClass: 'custom-dialog-container-recording',
    });
  }

  async changeRecordingAccess(recording: SpaceRecording, access: RecordingAccess) {
    if (recording.access === access || (!recording && access === RecordingAccess.HOSTS)) {
      return; // Do nothing if selecting the current item
    } else {
      try {
        await firstValueFrom(
          this.spacesService.updateRecordingAccess(recording.recordingId, access),
        );
        this.recordings$.next(
          this.recordings$.getValue().map((r) => {
            if (r.recordingId !== recording.recordingId) {
              return r;
            } else {
              return { ...r, access: access };
            }
          }),
        );
        this.recordingAccessChangedNotification();
      } catch (error) {
        this.telemetry.errorEvent('recording_access_changed_failed', {
          err: error,
          message: error.message,
          name: error.name,
        });
      }
    }
  }

  recordingAccessChangedNotification(): void {
    const topElement = new IconMessageToasterElement(
      { icon: 'check', size: 16 },
      this.translateService.instant('Recording access changed'),
    );

    const notificationDataBuilder = new NotificationDataBuilder(SUCCESSES.COPY_LINK_TO_CLIPBOARD)
      .type(NotificationType.SUCCESS)
      .style(ToasterPopupStyle.SUCCESS)
      .priority(380)
      .timeOut(5)
      .topElements([topElement]);

    const notificationData = notificationDataBuilder.topElements([topElement]).build();
    this.notificationToasterService.showNotification(notificationData);
  }

  copyLink(recording: SpaceRecording): void {
    const link = `${location.origin}/recording/${recording.recordingId}`;
    navigator.clipboard.writeText(link);
    const topElement = new IconMessageToasterElement(
      { icon: 'check', size: 16 },
      this.translateService.instant('Link copied to clipboard'),
    );

    const notificationDataBuilder = new NotificationDataBuilder(SUCCESSES.COPY_LINK_TO_CLIPBOARD)
      .type(NotificationType.SUCCESS)
      .style(ToasterPopupStyle.SUCCESS)
      .priority(380)
      .timeOut(5)
      .topElements([topElement]);

    const notificationData = notificationDataBuilder.topElements([topElement]).build();
    this.notificationToasterService.showNotification(notificationData);
  }

  async deleteRecording(spaceId: string, recordingId: string): Promise<void> {
    if (!this.spaceRecordingService.enabledPermissionsOnRecordings.delete) {
      return;
    }
    this.modalManagerService.showModal(
      ConfirmationModalComponent,
      {
        data: {
          title: this.translateService.instant('Delete recording?'),
          body: this.translateService.instant(
            'Are you sure you want to delete this recording? This action cannot be undone',
          ),
          positiveBtn: this.translateService.instant('Delete'),
          negativeBtn: this.translateService.instant('Cancel'),
          revertButtonsFunctionality: false,
        },
      },
      {
        afterClosed: async (res) => {
          if (res) {
            firstValueFrom(this.spacesService.deleteRecording(spaceId, recordingId))
              .then(() => {
                this.recordings$.next(
                  this.recordings$.getValue().filter((r) => r.recordingId !== recordingId),
                );
              })
              .catch((err) => {
                console.log('Failed to delete recording with error: ', err);
              });
          }
        },
      },
    );
  }

  getTimeString(time: Date): string {
    const convert = (n: number) => String(n).padStart(2, '0'); // '0009'
    const hours = convert(time.getHours() % 12 || 12);
    const minutes = convert(time.getMinutes());
    const seconds = convert(time.getSeconds());

    return `${hours}-${minutes}-${seconds}`;
  }

  downloadRecording(downloadLink: string): void {
    if (!this.spaceRecordingService.enabledPermissionsOnRecordings.download || !downloadLink) {
      return;
    }

    const downloadLinkElement = document.createElement('a');
    downloadLinkElement.href = downloadLink;
    document.body.appendChild(downloadLinkElement);
    downloadLinkElement.click();
    document.body.removeChild(downloadLinkElement);

    const notificationData = new NotificationDataBuilder(SUCCESSES.RECORDING_DOWNLOADED)
      .type(NotificationType.SUCCESS)
      .style(ToasterPopupStyle.SUCCESS)
      .priority(380)
      .timeOut(5)
      .topElements([
        new IconMessageToasterElement(
          { icon: 'check', size: 16 },
          this.translateService.instant('Recording Downloaded'),
        ),
      ])
      .build();

    this.notificationToasterService.showNotification(notificationData);
  }

  onChangeSession() {
    this.activeRooms$.next([]);
    this.archivedRooms$.next([]);
    this.selectedRoom$.next(SpaceDataLevel.SPACE);
    this.recordings$.next([]);
  }
}

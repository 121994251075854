import { Injectable, Injector } from '@angular/core';
import { ProviderStateService } from '../provider-state.service';
import { VirtualBackgroundPanelRTCService } from './virtual-background-panel-rtc.service';
import { VirtualBackgroundPanelLocalStreamService } from './virtual-background-panel-local-stream.service';
import { VirtualBackgroundPanelBase } from './virtual-background-panel-base';

@Injectable()
export class VirtualBackgroundPanelService {
  private _service?: VirtualBackgroundPanelBase;

  constructor(private providerStateService: ProviderStateService, private injector: Injector) {}

  getService() {
    // caching as the service will not change
    // once the panel opened
    if (this._service) {
      return this._service;
    }

    if (this.providerStateService.callConnectedValue) {
      this._service = this.injector.get<VirtualBackgroundPanelBase>(
        VirtualBackgroundPanelRTCService,
      );
    } else {
      this._service = this.injector.get<VirtualBackgroundPanelBase>(
        VirtualBackgroundPanelLocalStreamService,
      );
    }

    return this._service;
  }
}

<div class="pencilai-dialog">
  <div class="pencilai-dialog-header">
    <div class="pencilai-dialog-title">
      {{ data.type === 'promptInput' ? 'AI Lesson Generator' : ('Generating Content' | translate) }}
    </div>
    <div *ngIf="data.type === 'promptInput'" class="pencilai-dialog-close" (click)="close()">
      <mat-icon>close</mat-icon>
    </div>
  </div>

  <!-- Content Prompt -->
  <div *ngIf="data.type === 'promptInput'">
    <div class="pencilai-dialog-body">
      <div class="input-icons">
        <textarea
          class="pencilai-dialog-input"
          placeholder="{{ placeholder }}"
          matInput
          [(ngModel)]="userQuery"
          (keydown)="$event.stopPropagation()"
        ></textarea>
        <div class="ai-warning">
          <svg-icon [icon]="'info-outline'" [height]="18" [width]="18"></svg-icon>
          <span class="m-1">{{ 'AI outputs can be misleading or wrong' | translate }}</span>
        </div>
      </div>
    </div>
    <div class="pencilai-dialog-footer">
      <button
        [disabled]="userQuery?.trim().length < 1"
        class="pencilai-dialog-confirm"
        (click)="query()"
      >
        <svg-icon class="svg-icon" [icon]="'generator'" [width]="18" [height]="18"></svg-icon>
        {{ 'Generate Content' | translate }}
      </button>
    </div>
  </div>

  <!-- Processing -->
  <div *ngIf="data.type === 'processing'">
    <div class="pencilai-dialog-body">
      <app-spinner
        [size]="100"
        [icon]="'pencil_loading_black'"
        [style]="'processing'"
      ></app-spinner>
      <br />
      <p class="text-center">
        <span>{{ 'Pencil AI is generating your content.' | translate }}</span> <br />
        <span>{{ 'This may take a few moments.' | translate }}</span> <br />
        <br />
        <app-pencil-button
          class="pencil-button primary cancel-button-customisations"
          [icon]="'close'"
          [iconConfig]="{
            type: IconTypes.matIcon
          }"
          [label]="'Cancel' | translate"
          (click)="cancel()"
        ></app-pencil-button>
      </p>
    </div>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { UiModule } from '../ui/ui.module';
import { TooltipDirective } from '../standalones/directives/pncl-tooltip.directive';

import { PipeModule } from '../pipes/pipes.module';
import { SVGIconComponentsModule } from '../common/svg-icon/svg-icon-components.module';
import { CommentWrapperComponent } from './comment-wrapper/comment-wrapper.component';
import { CreateCommentComponent } from './create-comment/create-comment.component';
import { ListCommentsComponent } from './list-comments/list-comments.component';

@NgModule({
  declarations: [ListCommentsComponent, CreateCommentComponent, CommentWrapperComponent],
  imports: [
    CommonModule,
    UiModule,
    MatButtonModule,
    NgbTooltipModule,
    PipeModule,
    MatExpansionModule,
    MatMenuModule,
    SVGIconComponentsModule,
    TooltipDirective,
  ],
  exports: [ListCommentsComponent, CreateCommentComponent, CommentWrapperComponent],
})
export class CommentingModule {}

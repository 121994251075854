import { Component, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { TranslateService } from '@ngx-translate/core';
import { IChartConfig } from '../models/global.interface';
import { StatsHelper } from '../common/topics-list/topics-list.component';

interface ChartData {
  label: string;
  value: number | null;
}

interface AreaData {
  date: string;
  count: number;
}

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
})
export class ChartsComponent implements OnInit {
  @Input() topicStatsMap?: StatsHelper;
  @Input() levels: [] = [];
  @Input() levelString = 'Difficulty Level';
  @Input() chartConfig?: IChartConfig;
  @Input() barColor = '#59B0FF';
  @Input() areaData: AreaData[] = [];
  @Input() chartId = '';
  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.initChart();
  }

  initChart(): void {
    requestAnimationFrame(() => {
      if (this.chartConfig) {
        const svg = d3.select(`#${this.chartId} svg`);
        const margin = { top: 10, right: 0, bottom: 10, left: 0 };
        const width = this.chartConfig.width - margin.left - margin.right;
        const height = this.chartConfig.height - margin.top - margin.bottom;
        const tooltip = d3.select('body').append('div').attr('class', 'chart-tooltip');
        const y = d3.scaleLinear().rangeRound([height, 0]);
        const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);
        if (this.chartConfig.type === 'column') {
          const x = d3.scaleBand().rangeRound([0, width]).padding(0.3);
          const data = this.levels.map<ChartData>((l: string) => ({
            label: l,
            value: this.topicStatsMap
              ? (this.topicStatsMap[l as keyof StatsHelper] as number | null)
              : null,
          }));

          x.domain(data.map((d: ChartData) => d.label));
          y.domain([0, d3.max(data.map((d: ChartData) => d.value)) || 1]);

          g.selectAll('.bar')
            .data(data)
            .enter()
            .append('rect')
            .attr('x', (d: ChartData) => x(d.label))
            .attr('y', (d: ChartData) => (y(d.value) === height ? y(d.value) - 2 : y(d.value)))
            .attr('width', x.bandwidth())
            .attr('height', (d: ChartData) => height - y(d.value) || 2)
            .attr('fill', this.barColor)
            .on('mouseover', (d: ChartData, index: number) => {
              tooltip
                .style('left', `${d3.event.pageX - 50}px`)
                .style('top', `${d3.event.pageY - 85}px`)
                .style('display', 'inline-block')
                .html(
                  `<span>${this.translate.instant(this.levelString)} ${index + 1}</span>
                  <div>${this.translate.instant('Questions added')}: ${d.value}</div>`,
                );
            })
            .on('mouseout', () => {
              tooltip.style('display', 'none');
            });
        } else {
          const x = d3.scaleTime().range([0, width]);
          x.domain(d3.extent(this.areaData, (d: AreaData) => new Date(d.date)));
          y.domain([
            d3.min(this.areaData, (d: AreaData) => d.count) / 1.005,
            d3.max(this.areaData, (d: AreaData) => d.count) * 1.005,
          ]);
          const line = d3
            .line()
            .x((d: AreaData) => x(new Date(d.date)))
            .y((d: AreaData) => y(d.count));
          g.append('path')
            .datum(this.areaData)
            .attr('class', 'line')
            .attr('d', line)
            .attr('stroke', 'rgb(157, 241, 182)')
            .attr('fill', 'none');
        }
      }
    });
  }
}

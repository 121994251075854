import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  EditCustomAttribute,
  InstitutionCustomAttribute,
  InstitutionCustomAttributesService,
} from 'src/app/services/institution-custom-attributes.service';
import { BehaviorSubject, of, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { IconTypes } from './../../../standalones/components/pencil-icon/pencil-icon.component';
import { EditInstitutionCustomAttributesComponent } from './edit-institution-custom-attributes/edit-institution-custom-attributes.component';

@UntilDestroy()
@Component({
  selector: 'app-user-settings-institution-custom-attributes',
  templateUrl: './user-settings-institution-custom-attributes.component.html',
  styleUrls: ['./user-settings-institution-custom-attributes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsInstitutionCustomAttributesComponent implements OnInit {
  private _isLoading$ = new BehaviorSubject(false);
  isLoading$ = this._isLoading$.asObservable();
  private _institutionCustomAttributes$ = new BehaviorSubject<InstitutionCustomAttribute[]>([]);
  private _shownInstitutionCustomAttributes$ = new BehaviorSubject<InstitutionCustomAttribute[]>(
    [],
  );

  shownInstitutionCustomAttributes$ = this._shownInstitutionCustomAttributes$.asObservable();

  private readonly ATTRIBUTES_PER_PAGE = 10;
  protected readonly IconTypes = IconTypes;

  protected skeletonBars = Array.from({ length: 5 });

  constructor(
    private institutionCustomAttributeService: InstitutionCustomAttributesService,
    private userService: UserService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.refreshCustomAttributes();
  }

  loadMoreAttributes(intersecting: boolean) {
    if (!intersecting) {
      return;
    }
    const currentAttributesCount = this._shownInstitutionCustomAttributes$.value.length;
    const newAttributes = this._institutionCustomAttributes$.value.slice(
      currentAttributesCount,
      currentAttributesCount + this.ATTRIBUTES_PER_PAGE,
    );

    this._shownInstitutionCustomAttributes$.next([
      ...this._shownInstitutionCustomAttributes$.value,
      ...newAttributes,
    ]);
  }

  openDialog(attribute: InstitutionCustomAttribute | null, action: (result: any) => void) {
    const dialogRef = this.dialog.open(EditInstitutionCustomAttributesComponent, {
      data: attribute,
      disableClose: true,
      panelClass: 'zero-padding-dialog',
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (result) {
          this._isLoading$.next(true);
          action(result);
        }
      });
  }

  openEditCustomAttributeDialog(attribute: InstitutionCustomAttribute) {
    this.openDialog(attribute, this.updateCustomAttribute.bind(this));
  }

  openAddCustomAttributeDialog() {
    this.openDialog(null, this.addCustomAttribute.bind(this));
  }

  updateCustomAttribute(result: EditCustomAttribute) {
    let hasResponse = false;
    this.institutionCustomAttributeService
      .updateCustomAttribute(result)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          this._isLoading$.next(false);
          if (response) {
            hasResponse = true;
            this.refreshCustomAttributes();
          }
        },
        error: (error) => {
          this._isLoading$.next(false);
          return of(null);
        },
        complete: () => {
          if (!hasResponse) {
            this._isLoading$.next(false);
          }
        },
      });
  }

  addCustomAttribute(result: EditCustomAttribute) {
    this.institutionCustomAttributeService
      .addCustomAttribute({
        key: result.attribute?.key || '',
        values: result.addValues ?? [],
      })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          if (response) {
            this.refreshCustomAttributes();
          }
        },
        error: (error) => {
          this._isLoading$.next(false);
        },
        complete: () => {
          this._isLoading$.next(false);
        },
      });
  }

  private refreshCustomAttributes() {
    this._isLoading$.next(true);
    this.institutionCustomAttributeService
      .getCustomAttributes()
      .pipe(take(1))
      .subscribe((response) => {
        if (response['custom_attributes']) {
          this._isLoading$.next(false);
          this._shownInstitutionCustomAttributes$.next([]);
          this.userService.allInstitutionCustomAttributes.next(response['custom_attributes']);

          let customAttributes = response['custom_attributes'].map((attribute: any) => ({
            key: attribute['attribute_key'],
            values: attribute['attribute_value'],
          }));

          // Sort customAttributes based on the key property alphabetically
          customAttributes = customAttributes.sort(
            (a: { key: { value: string } }, b: { key: { value: string } }) =>
              a.key.value.localeCompare(b.key.value),
          );

          this._institutionCustomAttributes$.next(customAttributes);
          this.loadMoreAttributes(true);
        }
      });
  }
}

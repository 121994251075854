<div class="dev-warning" [class.in-space]="router.url.includes('/spaces/')">
  <div class="dynamic-url-warning">
    <div class="text _flags" *ngIf="isCustomFlagsEnabled$ | async">custom flags active</div>
    <div class="text _servers" *ngIf="(this.customServeUrls | keyvalue)?.length">
      <div>custom servers active:</div>
      <div
        *ngFor="let customServerUrlItem of customServeUrls | keyvalue; let i = index"
        class="tooltips"
        [appTooltip]="customServerUrlItem.value"
      >
        <a target="_blank" rel="noopener noreferrer" [href]="customServerUrlItem.value">{{
          customServerUrlItem.key
        }}</a>
      </div>
    </div>
    <div class="end">
      <button *ngIf="(this.customServeUrls | keyvalue)?.length" (click)="resetServerUrl()">
        Reset Servers
      </button>
      <button *ngIf="isCustomFlagsEnabled$ | async" (click)="resetFlags()">Reset Flags</button>
    </div>
  </div>
</div>
<div id="audio-elements" hidden></div>
<div class="banner" *ngIf="!isMobileView && showUpgradeBanner">
  <div class="text-container">
    <span class="banner-top-text">
      {{ 'Hi ' | translate }}
      {{ user?.name }}
      {{ ', how are you enjoying Pencil Spaces? ' | translate }}
    </span>
    <br />
    <span class="banner-bottom-text">
      {{ 'Just so you know,  ' | translate }}
      {{ getBillingStatus() }}
    </span>
  </div>
  <app-pencil-button
    class="pencil-button white medium upgrade-button"
    (clickEvent)="goToBillingSetting()"
    data-name="banner-upgrade"
    icon="rocket"
    [iconConfig]="{ type: 'svg-icon' }"
    [label]="getUpgradeButtonText()"
  >
  </app-pencil-button>
  <app-pencil-button
    class="pencil-button close-button"
    (clickEvent)="hideBanner()"
    icon="close"
    [iconConfig]="{ type: 'mat-icon' }"
  >
  </app-pencil-button>
</div>
<section #applicationContent>
  <div
    class="d-flex overflow-hidden app-content"
    [class.presentation-content]="router.url.includes('/present')"
    [class.authenticated-content]="authService.firebaseUser | async"
    [class.unauthenticated-content]="(authService.firebaseUser | async) === null"
    [class.practice-content]="router.url.includes('/practice')"
    [style.padding-top.px]="showUpgradeBanner ? 60 : 0"
  >
    <div
      class="sidebar collapsed d-flex flex-column justify-content-between align-items-center"
      *ngIf="(authService.firebaseUser | async) && router.url.includes('/present')"
    >
      <div class="d-flex flex-column align-items-center">
        <button class="logo" routerLink="/">
          <app-logo [theme]="theme"> </app-logo>
        </button>
        <button routerLink="/content">
          <span class="material-icons-outlined">home</span>
        </button>
        <button
          *ngIf="user && (user | has: Features.view_schedule_page)"
          routerLink="/schedule"
          [ngClass]="{ active: router.url.includes('/schedule') }"
        >
          <span class="material-icons">calendar_today</span>
        </button>
        <button routerLink="/users">
          <span class="material-icons">person_outline</span>
        </button>
        <button>
          <span class="material-icons">more_horiz</span>
        </button>
      </div>
      <button [matMenuTriggerFor]="widgetMenu" class="btn-widgets">
        <span class="material-icons">apps</span>
      </button>
    </div>
    <mat-menu #widgetMenu="matMenu">
      <button
        mat-menu-item
        class="d-flex align-items-center border-bottom"
        (click)="navService.toggleCalculator()"
      >
        <span class="material-icons-outlined cmr-8">calculate</span>
        {{ 'Calculator' | translate }}
      </button>
      <button
        mat-menu-item
        class="d-flex align-items-center border-bottom"
        (click)="navService.toggleGraph()"
      >
        <span class="material-icons-outlined cmr-8">show_chart</span>
        {{ 'Graph' | translate }}
      </button>
      <button
        mat-menu-item
        class="d-flex align-items-center"
        (click)="navService.togglePeriodicTable()"
      >
        <span class="material-icons-outlined cmr-8">table_chart</span>
        {{ 'Periodic Table' | translate }}
      </button>
    </mat-menu>
    <div
      class="app-aside"
      [class.closed]="!menuOpened"
      *ngIf="(authService.firebaseUser | async) && this.showSideNavBar(router.url)"
    >
      <div class="mobile-menu-backdrop" [class.open]="menuOpened" (click)="toggleMenu()"></div>
      <div class="sidebar-wrap">
        <app-switcher></app-switcher>
        <div class="sidebar" (click)="closeMobileMenu($event)">
          <div class="logo-container">
            <app-full-logo
              [theme]="theme"
              [logoText]="user?.institution?.name || pencilLogoText"
              class="logo"
            ></app-full-logo>
          </div>
          <ui-nav-bar class="w-100"></ui-nav-bar>
        </div>
      </div>
    </div>
    <div
      class="content-container flex-1 d-flex flex-column"
      [class.sidebar-opened]="navService.isMenuOpened | async"
      [class.public-landing-view]="questionsService.isPublicQuestionView | async"
      [style.height]="showUpgradeBanner ? 'calc(100dvh - 60px)' : '100dvh'"
    >
      <ui-header
        class="border-bottom"
        [class.sidebar-closed]="(navService.isMenuOpened | async) === false"
        *ngIf="
          (authService.firebaseUser | async) &&
          !router.url.includes('/account') &&
          router.url !== '/' &&
          !router.url.includes('/content/course/questions') &&
          !router.url.includes('/content/course/notes') &&
          !router.url.includes('/content/course/lessons') &&
          !router.url.includes('/content/worksheets/list') &&
          !router.url.includes('/content/course/files') &&
          !router.url.includes('/create') &&
          !router.url.includes('/present') &&
          !router.url.includes('/view') &&
          !router.url.includes('/practice') &&
          !router.url.includes('/' + URL_CONSTANTS.SPACES) &&
          !router.url.includes('/pencilcam') &&
          !router.url.includes('/techcheck') &&
          !router.url.includes('/recording')
        "
      >
        <div
          class="position-relative flex-1"
          [ngClass]="{
            'h-100':
              router.url.includes('/content/worksheets') ||
              router.url.includes('/notifications') ||
              router.url.includes('/course/settings')
          }"
        >
          <div
            class="m-0 font-24 d-flex align-items-center"
            [ngClass]="{
              marginTop:
                router.url.includes('/content/worksheets') || router.url.includes('/notifications')
            }"
          >
            <span
              class="material-icons-outlined title-icon"
              *ngIf="router.url.includes('/content/files')"
            >
              folder_open
            </span>
            <span class="material-icons font-18 cmr-8" *ngIf="router.url.includes('/schedule')">
              calendar_today
            </span>
            <svg-icon
              class="cmr-8"
              *ngIf="router.url.includes('/analytics')"
              [icon]="'analytics_header'"
              [width]="38"
              [height]="38"
            ></svg-icon>
            <span
              class="material-icons go-back-arrow"
              *ngIf="router.url.includes('/course/settings')"
              routerLink="/content/course/questions/{{ questionsService.selectedCourseId | async }}"
            >
              arrow_back
            </span>
            <span>{{ (navService.headerTitle | async) || '' | translate }}</span>
          </div>
          <nav
            class="navbar navbar-expand-lg cp-0 d-none d-sm-block"
            *ngIf="router.url.includes('/notifications')"
            [attr.aria-label]="'notifications page navbar' | translate"
          >
            <ul class="navbar-nav flex-row">
              <li
                class="nav-item"
                [class.active]="!router.url.includes('tab') || router.url.includes('all')"
              >
                <a class="nav-link" [queryParams]="{ tab: 'all' }" routerLink="/notifications">{{
                  'All' | translate
                }}</a>
              </li>
              <li class="nav-item" [class.active]="router.url.includes('unread')">
                <a class="nav-link" routerLink="/notifications" [queryParams]="{ tab: 'unread' }">{{
                  'Unread' | translate
                }}</a>
              </li>
            </ul>
          </nav>
          <nav
            class="navbar navbar-expand-lg cp-0 d-none d-sm-block"
            *ngIf="router.url.includes('/course/settings')"
            [attr.aria-label]="'course settings page navbar' | translate"
          >
            <ul class="navbar-nav flex-row">
              <li class="nav-item" [class.active]="router.url.includes('details')">
                <a
                  routerLink="content/course/settings/{{
                    questionsService.selectedCourseId | async
                  }}/details"
                  class="nav-link"
                  >{{ 'Details' | translate }}</a
                >
              </li>
              <li class="nav-item" [class.active]="router.url.includes('people')">
                <a
                  routerLink="content/course/settings/{{
                    questionsService.selectedCourseId | async
                  }}/people"
                  class="nav-link"
                  >{{ 'People' | translate }}</a
                >
              </li>
              <li class="nav-item" [class.active]="router.url.includes('topics')">
                <a
                  routerLink="content/course/settings/{{
                    questionsService.selectedCourseId | async
                  }}/topics"
                  class="nav-link"
                  >{{ 'Topics' | translate }}</a
                >
              </li>
            </ul>
          </nav>
        </div>
        <div ui-header-actions>
          <button
            mat-flat-button
            class="btn-practice d-sm-inline-block d-none"
            color="primary"
            *ngIf="router.url.includes('/content/worksheets/list') && user && !(user | isStudent)"
            (click)="questionsService.addSet.next(true)"
          >
            <span class="material-icons">add</span>
            <span>{{ 'Create set' | translate }}</span>
          </button>
          <button
            mat-flat-button
            class="btn-practice d-sm-inline-block d-none cpx-12"
            color="primary"
            *ngIf="
              router.url.includes('/profiles') &&
              ((userService.user | async)?.user | has: Features.create_user)
            "
            (click)="profilesService.inviteUser.next(true)"
          >
            <span class="material-icons">person_add</span>
            <span class="text-capitalize">{{ 'invite user' | translate }}</span>
          </button>
          <button
            mat-flat-button
            class="btn-practice d-sm-inline-block d-none cpx-12"
            color="primary"
            *ngIf="
              router.url.includes('/course/settings') &&
              router.url.includes('/people') &&
              ((userService.user | async)?.user | has: Features.add_user_to_course)
            "
            routerLink="/content/course/settings/{{
              questionsService.selectedCourseId | async
            }}/people"
            [queryParams]="{ add: true }"
          >
            <span class="material-icons">add</span>
            <span class="text-capitalize">{{ 'Add People' | translate }}</span>
          </button>
          <button
            mat-flat-button
            appUpgradePlanBlocker
            class="btn-create-event d-sm-inline-block d-none cpx-12"
            color="primary"
            data-name="header-create-event"
            *ngIf="
              router.url.includes('/schedule') &&
              ((userService.user | async)?.user | has: Features.schedule_event)
            "
            (click)="this.eventService.openCreateNewEvent()"
            [options]="{
              arrow: 'top',
              placement: 'bottom'
            }"
            [upgradePlanEvaluationType]="planEvaluationTypes.SELF"
            [disabled]="isFreeUser"
          >
            <span class="material-icons font-18 cmr-4">add</span>
            <span>{{ 'Create Event' | translate }}</span>
          </button>
          <button
            mat-flat-button
            class="btn-practice d-sm-inline-block d-none"
            color="primary"
            *ngIf="router.url.includes('/messages')"
            (click)="openNewMessageDialog()"
          >
            <span class="material-icons">add</span>
            <span>{{ 'New message' | translate }}</span>
          </button>
          <div
            appTooltip="Your role does not allow this operation"
            [appTooltipDisabled]="(userService.user | async)?.user | has: Features.upload_resource"
          >
            <button
              mat-flat-button
              class="btn-practice d-sm-inline-block"
              color="primary"
              *ngIf="router.url | isFileView"
              (click)="resourceService.createResourceIntent.next(true)"
              [disabled]="!((userService.user | async)?.user | has: Features.upload_resource)"
            >
              <span class="material-icons">file_upload</span>
              <span>{{ 'Upload' | translate }}</span>
            </button>
          </div>
          <button
            mat-flat-button
            class="btn-create-course"
            color="primary"
            routerLink="/content/course/new"
            *ngIf="router.url === '/content' && (user | has: Features.create_course)"
          >
            <span class="material-icons">add</span>
            <span>{{ 'Create Course' | translate }}</span>
          </button>
          <app-notifications-menu
            *ngIf="user && showNotificationBell"
            [user]="user"
          ></app-notifications-menu>
          <app-profile-photo
            class="cml-16 cursor-pointer"
            width="26"
            [class.has-nav]="router.url.includes('/content/worksheets')"
            [showStatus]="true"
            [hasMenu]="true"
            [isClickEnabled]="false"
          ></app-profile-photo>
        </div>
      </ui-header>
      <div class="content flex-1">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <div class="position-fixed cp-16 shadow update-version" *ngIf="updateAvailable">
    <p class="cmt-8 cmb-16 text-center">{{ 'Refresh to get the latest version.' | translate }}</p>
    <div class="d-flex justify-content-between align-items-center">
      <button ui-primary (click)="refresh()">{{ 'Refresh' | translate }}</button>
    </div>
  </div>

  <app-video-chat
    [class.showEditor]="showEditor"
    cdkDrag
    [cdkDragDisabled]="showEditor || fullScreen"
    (cdkDragStarted)="dragStarted($event)"
    (setShowEditor)="showEditor = $event"
    (setFullScreen)="fullScreen = $event"
    (resetPosition)="reset()"
  >
  </app-video-chat>

  <app-calculator
    *ngIf="navService.showCalculator | async"
    (closeCalculator)="closeCalc()"
  ></app-calculator>

  <app-graph *ngIf="navService.showGraph | async" (closed)="closeGraph()"></app-graph>

  <app-periodic-table
    *ngIf="navService.showPeriodicTable | async"
    (closed)="closePeriodicTable()"
  ></app-periodic-table>
  <app-session-analytics
    [currentSpace]="navService.sessionAnalyticsSpace | async"
    *ngIf="navService.showSessionAnalytics | async"
    (closed)="closeSessionAnalytics()"
  ></app-session-analytics>
  <app-loading [showTipsIndicator]="true" [loading]="appLoading"></app-loading>
</section>

import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IConfirmationDialogData {
  title: string;
  description: string;
  cancelText: string;
  cancelIcon: string;
  confirmText: string;
  confirmIcon: string;
  isConfirmIconSvg?: boolean;
  action: (...args: any) => void;
  withoutCloseIcon?: boolean;

}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
  confirmationDialogData?: IConfirmationDialogData;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: IConfirmationDialogData,
  ) {
    this.confirmationDialogData = data;
  }

  closeDialog(): void {
    this.dialogRef.close(null);
  }

  confirm(): void {
    this.confirmationDialogData?.action();
    this.closeDialog();
  }
}

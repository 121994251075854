import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-loader',
  templateUrl: './content-loader.component.html',
  styleUrls: ['./content-loader.component.scss'],
})
export class ContentLoaderComponent implements OnInit {
  @Input() border = true;
  @Input() top = 0;
  @Input() blockCount = 10;
  @Input() singleLineList = false;
  @Input() floatLoader?: boolean;
  @Input() tags?: boolean;
  @Input() topTags?: boolean;
  @Input() list?: boolean;
  @Input() file?: boolean;
  @Input() equalLines? = false;

  blocks: number[] = [];

  ngOnInit(): void {
    for (let i = 0 ; i < this.blockCount ; i ++) {
      this.blocks.push(i);
    }
  }
}

import { Component, Input, OnChanges } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { User } from '../../models/user';

export enum Position {
  ABOVE = 'top',
  BELOW = 'bottom',
}

@Component({
  selector: 'app-users-presence',
  templateUrl: './users-presence.component.html',
  styleUrls: ['./users-presence.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':leave', [
        style({ height: 18, opacity: 1 }),
        animate('0.2s ease-out', style({ height: 0, opacity: 0 })),
      ]),
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('0.2s ease-in', style({ height: 18, opacity: 1 })),
      ]),
    ]),
  ],
})
export class UsersPresenceComponent implements OnChanges {
  @Input() maxUsers = 4;
  @Input() users: User[] = [];
  @Input() avatarWidth = 30;
  @Input() avatarFontSize = 10;
  @Input() avaterTooltipPosition = 'top';
  @Input() tooltipsBackgroundColor = '#333333';
  @Input() othersIconHeight = '30px';
  @Input() othersIconWidth = '30px';
  @Input() useShowOthersListV2 = false;
  @Input() parentClassNameShowOthersListV2?: string;

  shownUsers?: User[];
  otherUsers: User[] = [];
  otherUsersString = '';
  showOthersTooltip = false;
  isOthersTooltipHovered = false;

  position = Position;

  ngOnChanges() {
    this.splitUsers();
  }

  splitUsers() {
    if (!this.users) {
      return;
    }
    this.shownUsers = this.users;
    this.otherUsers = [];
    if (this.users.length >= this.maxUsers + 1) {
      this.shownUsers = this.users.slice(0, this.maxUsers - 1);
      this.otherUsers = this.users.slice(this.maxUsers - 1, this.users.length);
      const otherUsersNames = this.otherUsers.map((u) => u.name);
      this.otherUsersString = otherUsersNames.join(', ');
    }
  }

  onOtherProfilesMouseEnter(): void {
    this.showOthersTooltip = true;
  }

  onOtherProfilesMouseLeave(): void {
    requestAnimationFrame(() => {
      if (!this.isOthersTooltipHovered) {
        this.showOthersTooltip = false;
      }
    });
  }

  calculateBoardUsersPhotosWidth(): number {
    return (
      document.getElementById(this.parentClassNameShowOthersListV2 || 'other-users-tooltip')
        ?.clientWidth ?? 0
    );
  }

  handleTooltipMouseout(event: any): void {
    const classNameTo = event?.toElement?.className;
    const classNameFrom = event?.fromElement?.className;
    requestAnimationFrame(() => {
      if (
        !classNameTo.includes(this.parentClassNameShowOthersListV2) ||
        (classNameTo.includes(this.parentClassNameShowOthersListV2) &&
          classNameFrom.includes('other-users-tooltip'))
      ) {
        this.showOthersTooltip = false;
      }
    });
  }
}

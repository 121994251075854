import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { percentage } from '@angular/fire/storage';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, map, of, take, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { isEqual } from 'lodash';
import { User, UserConstantsTransformer, roleValueIDs } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import { UploadFileService } from '../../../services/upload-file.service';
import { SettingsService } from '../../../services/settings.service';
import { FLAGS, FlagsService } from '../../../services/flags.service';
import { TelemetryService } from '../../../services/telemetry.service';

@UntilDestroy()
@Component({
  selector: 'app-user-settings-account[form]',
  templateUrl: './user-settings-account.component.html',
  styleUrls: ['./user-settings-account.component.scss'],
})
export class UserSettingsAccountComponent implements OnInit {
  @Input() form!: UntypedFormGroup;
  @Input() isMobile = false;
  @Input() PANELS;
  @Output() goBack = new EventEmitter<any>();
  @Output() formChangedEvent: EventEmitter<boolean> = new EventEmitter();

  isUploadingImage = false;
  uploadImageName = '';
  uploadPercentage?: Observable<number | undefined>;
  user?: User;
  isLoading = false;
  infoForm?: UntypedFormGroup;
  ROLES;
  ROLE_TYPE_IDS;
  ROLE_TYPES;
  ROLE_VALUE_IDS;
  showUserInfoForm = false;
  public initialFormValue: unknown;
  public formChanged$ = new BehaviorSubject<boolean>(false);

  constructor(
    private uploadService: UploadFileService,
    private userService: UserService,
    private settingsService: SettingsService,
    private translateService: TranslateService,
    private flagsService: FlagsService,
    private telemetry: TelemetryService,
  ) {
    const userConstants = new UserConstantsTransformer(this.translateService);
    this.ROLE_TYPES = userConstants.roleTypes;
    this.ROLES = userConstants.roles;
    this.ROLE_TYPE_IDS = userConstants.roleTypeIDs;
    this.ROLE_VALUE_IDS = roleValueIDs;
    this.showUserInfoForm = this.flagsService.isFlagEnabled(FLAGS.USER_INFO_V2);
  }

  ngOnInit(): void {
    this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res) {
        this.user = res.user;
        this.initForm();
      }
    });
  }

  initForm(): void {
    this.form.removeControl('info');
    this.infoForm = undefined;
    if (!this.user?.institution && this.showUserInfoForm) {
      this.infoForm = new UntypedFormGroup({
        roleType: new UntypedFormControl('', [Validators.required]),
        role: new UntypedFormControl('', [Validators.required]),
        questionnaireComplete: new UntypedFormControl(true),
      });
      this.form.addControl('info', this.infoForm);

      if (this.user?.info?.roleOther) {
        this.addRoleOtherInput();
      }

      this.infoForm.controls.roleType.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
        if (!this.ROLE_TYPES[val].includes(this.infoForm?.value.role)) {
          this.infoForm?.controls.role.setValue('');
        }
      });
      this.infoForm.controls.role.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
        if (!val) {
          return;
        }

        if (val === this.ROLE_VALUE_IDS.other) {
          this.addRoleOtherInput();
        } else {
          this.removeRoleOtherInput();
        }
      });
    }

    this.resetForm();
  }

  resetForm(): void {
    this.form.patchValue({
      ...this.user,
    });
    this.initialFormValue = this.form.value;
    this.form.valueChanges
      .pipe(
        map(() => !isEqual(this.initialFormValue, this.form.value)),
        tap((x) => this.formChangedEvent.emit(x)),
        untilDestroyed(this),
      )
      .subscribe(this.formChanged$);
  }

  addRoleOtherInput(): void {
    if (!this.infoForm?.contains('roleOther')) {
      this.infoForm?.addControl(
        'roleOther',
        new UntypedFormControl('', [Validators.required, this.noWhitespaceValidator]),
      );
    }
  }

  removeRoleOtherInput(): void {
    if (this.infoForm?.contains('roleOther')) {
      this.infoForm?.removeControl('roleOther');
    }
  }

  upload(event): void {
    if (event.target.files && event.target.files[0]) {
      this.isUploadingImage = true;
      this.uploadImageName = event.target.files[0].name;
      const reader = new FileReader();
      const task = this.uploadService.uploadToFireStorage(event.target.files[0], (data) => {
        this.form.patchValue({ profile_image: data });
        this.form.markAsDirty();
        this.isUploadingImage = false;
      });
      if (task) {
        percentage(task)
          .pipe(untilDestroyed(this))
          .subscribe((data) => {
            this.uploadPercentage = of(data.progress);
          });
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }

  submitChanges(): void {
    if (!this.user || this.isLoading) {
      return;
    }

    this.isLoading = true;
    const updatedUser = {
      ...this.user,
      ...this.form.value,
    };

    this.userService
      .updateUser(updatedUser)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.telemetry.event('user_role_data_provided', {
            roleType: updatedUser.info?.roleType,
            role: updatedUser.info?.role,
            roleOther: updatedUser.info?.roleOther,
          });
          this.settingsService.showToast({
            title: this.translateService.instant('Account updated'),
            description: this.translateService.instant(
              'Your account has been successfully updated.',
            ),
          });
          if (this.isMobile) {
            this.goBack.emit();
          }
        },
        error: () => {
          this.settingsService.showToast(
            {
              title: this.translateService.instant('Update failed'),
              description: this.translateService.instant('An error occurred. Please try again.'),
            },
            true,
          );
        },
        complete: () => {
          this.isLoading = false;
          this.formChanged$.next(false);
          this.formChangedEvent.emit(false);
        },
      });
  }

  noWhitespaceValidator(control: UntypedFormControl): ValidationErrors | null {
    return (control.value || '').trim().length ? null : { whitespace: true };
  }
}

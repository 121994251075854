import { DEFAULT_DEVICE_ID, DeviceType } from 'src/app/common/utils/devices-handle-util';
import {
  DEFAULT_CAM_ID_KEY,
  VIDEO_STREAM_CAPTURE_HEIGHT,
  VIDEO_STREAM_CAPTURE_WIDTH,
} from 'src/app/models/device-manger';
import { BaseLocalTrack } from './local-track';

export class LocalVideoTrackService extends BaseLocalTrack {
  constructor() {
    const deviceId = localStorage.getItem(DEFAULT_CAM_ID_KEY) ?? DEFAULT_DEVICE_ID;
    super(DeviceType.VIDEO, deviceId);
  }

  getStreamConstrains(): MediaTrackConstraints | undefined {
    return {
      width: { ideal: VIDEO_STREAM_CAPTURE_WIDTH },
      height: { ideal: VIDEO_STREAM_CAPTURE_HEIGHT },
    };
  }

  setDeviceIdIntoLocalStorage(deviceId: string) {
    localStorage.setItem(DEFAULT_CAM_ID_KEY, deviceId);
  }
}

<div class="d-flex bg-light border-bottom cpt-16 cpb-12 align-items-center justify-content-center">
  <div style="flex-grow: 1"></div>
  <p class="text-center fw-6">{{ data?.title ?? '' }}</p>
  <div style="flex-grow: 1"></div>
  <app-pencil-icon
    class="close-button"
    [name]="'close'"
    [type]="IconTypes.materialIcon"
    (click)="closeSubmit()"
  >
  </app-pencil-icon>
</div>
<app-upload [data]="data" [handleDataInternally]="false" (closeSubmit)="closeSubmit($event)">
</app-upload>

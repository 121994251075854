/**
 * changes to this file will affect both request access and waiting room.
 */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/user';
import { AclService } from 'src/app/services/acl.service';
import {
  NotificationData,
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from 'src/app/services/notification-toaster.service';
import { SessionSharedDataService } from 'src/app/services/session-shared-data.service';
import { PanelView } from 'src/app/sessions/panel/panel.component';
import { Section } from 'src/app/sessions/panel/participants-manager/participants-manager.component';
import {
  ButtonToasterElement,
  ButtonToasterElementStyle,
} from 'src/app/ui/notification-toaster/button-toaster-element/button-toaster-element.component';
import { ToasterPopupStyle } from 'src/app/ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import {
  IconBackground,
  IconMessageToasterElement,
} from 'src/app/ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';

@Injectable()
export abstract class RequestAccessNotificationBaseService {
  protected _requesterApproved$: Subject<User> = new Subject();
  protected _requesterRejected$: Subject<User> = new Subject();
  protected _approveAll$: Subject<void> = new Subject<void>();

  protected activeNotificationData?: NotificationData;
  protected isNotificationActive = false;

  protected abstract notificationCode: string;
  protected abstract warningCode: string;
  protected abstract errorCode: string;
  protected abstract acceptButtontext: string;
  protected abstract acceptAllButtontext: string;
  protected abstract singleRequestNotificationText: string;
  protected abstract multiRequestsNotificationText: string;
  protected abstract msg: string;

  protected _startShowingNotifications: boolean = false;

  protected _lastRequestsNum: number = -1;
  protected _lastRequesterID: string = '';

  private _approveButton!: ButtonToasterElement;
  private _rejectButton!: ButtonToasterElement;
  private _approveAllButton!: ButtonToasterElement;
  private _reviewButton!: ButtonToasterElement;
  private _msgElement!: IconMessageToasterElement;
  private _multipleStyleTitle!: IconMessageToasterElement;
  private _singleStyleUserTitle!: IconMessageToasterElement;
  private _singleStyleGuestTitle!: IconMessageToasterElement;
  private _warningElement!: IconMessageToasterElement;

  protected constructor(
    protected sessionSharedDataService: SessionSharedDataService,
    protected translateService: TranslateService,
    protected notificationToasterService: NotificationToasterService,
    protected toastr: ToastrService,
    protected aclService: AclService,
  ) {}

  public showNotificationIfThereAreRequests(requesters: User[]) {
    const accessRequestsNum = requesters.length;
    if (accessRequestsNum === 0 || !this._startShowingNotifications) {
      this.dismissRequestsNotification();
      return;
    }
    const onlyRequester = accessRequestsNum === 1 ? requesters[0] : undefined;

    if (this.isNotificationActive) {
      this.updateExistingNotificationIfThereAreRequests(requesters);
    } else {
      this.createNewNotification(accessRequestsNum, onlyRequester);
    }
  }

  protected createNewNotification(accessRequestsNum: number, requester?: User) {
    this.activeNotificationData = this.buildNotification(accessRequestsNum, requester);

    this.isNotificationActive = true;

    this.notificationToasterService.showNotification(
      this.activeNotificationData,
      () => (this.isNotificationActive = false),
    );
  }

  protected buildNotification(accessRequestsNum: number, requester?: User): NotificationData {
    const { firstBottomItem, secondBottomItem, title, message } = this.getNotificationItems(
      accessRequestsNum,
      requester,
    );

    const notificationData = new NotificationDataBuilder(this.notificationCode)
      .type(NotificationType.WARNING)
      .style(ToasterPopupStyle.WARN)
      .version2Notification(true)
      .width(304)
      .topElements([title])
      .bottomElements([firstBottomItem, secondBottomItem])
      .showProgressBar(false)
      .dismissable(true)
      .priority(600);

    if (message) {
      notificationData.middleElements([message]);
    }

    return notificationData.build();
  }

  public updateExistingNotificationIfThereAreRequests(requesters: User[]) {
    const accessRequestsNum = requesters.length;
    if (accessRequestsNum === 0 || !this._startShowingNotifications) {
      this.dismissRequestsNotification();
      return;
    }

    const onlyRequester = requesters.length === 1 ? requesters[0] : undefined;

    if (
      !this.activeNotificationData ||
      !this.isNotificationActive ||
      this.isSameNotificationShown(accessRequestsNum, onlyRequester)
    ) {
      return;
    }
    const { firstBottomItem, secondBottomItem, title, message } = this.getNotificationItems(
      accessRequestsNum,
      onlyRequester,
    );

    this.activeNotificationData.topElements = [title];
    this.activeNotificationData.middleElements = message ? [message] : [];
    this.activeNotificationData.bottomElements = [firstBottomItem, secondBottomItem];
  }

  private isSameNotificationShown(accessRequestsNum: number, requester?: User): boolean {
    return (
      accessRequestsNum === this._lastRequestsNum &&
      (accessRequestsNum > 1 || this._lastRequesterID === requester?._id)
    );
  }

  protected getNotificationItems(
    accessRequestsNum: number,
    requester?: User,
  ): AccessRequestsNotificationItems {
    this._lastRequestsNum = accessRequestsNum;
    this._lastRequesterID = requester?._id ?? '';

    if (accessRequestsNum == 1 && requester) {
      return this.getSingleRequestsNotificationItems(requester);
    }

    return this.getMultipleRequestsNotificationItems(accessRequestsNum);
  }

  private getMultipleRequestsNotificationItems(
    accessRequestsNum: number,
  ): AccessRequestsNotificationItems {
    this.adjustReviewButton(accessRequestsNum);
    this.adjustApproveAllButton();
    this.adjustMultipleTitleElement(accessRequestsNum);
    this.adjustMsgElement(this.msg);

    return {
      firstBottomItem: this._reviewButton,
      secondBottomItem: this._approveAllButton,
      title: this._multipleStyleTitle,
      message: this._msgElement,
    };
  }

  private adjustReviewButton(accessRequestsNum: number) {
    if (!this._reviewButton) {
      this._reviewButton = new ButtonToasterElement(
        [
          { svgIcon: 'access_requests_forward_arrow_black', size: 18 },
          `${this.translateService.instant('Review')} (${accessRequestsNum})`,
        ],
        {
          handler: () => {
            this.goToAccessRequestsSection();
          },
          close: true,
        },
        ButtonToasterElementStyle.SECONDARY,
      );
    } else {
      this._reviewButton.title.msg = `${this.translateService.instant(
        'Review',
      )} (${accessRequestsNum})`;
    }
  }

  private adjustApproveAllButton() {
    if (!this._approveAllButton) {
      this._approveAllButton = new ButtonToasterElement(
        [{ svgIcon: 'access_requests_approve_white', size: 18 }, this.acceptAllButtontext],
        {
          handler: () => {
            this.approveAll();
          },
          close: true,
        },
        ButtonToasterElementStyle.PRIMARY,
      );
    }
  }

  private adjustMultipleTitleElement(accessRequestsNum: number) {
    if (!this._multipleStyleTitle) {
      this._multipleStyleTitle = new IconMessageToasterElement(
        { svgIcon: 'access_requests_clock_black', size: 18 },
        `${accessRequestsNum} ${this.multiRequestsNotificationText}`,
        undefined,
        undefined,
        undefined,
        IconBackground.INFO,
        true,
        true,
        'flex-shrink: 0;',
      );
    } else {
      this._multipleStyleTitle.msg = `${accessRequestsNum} ${this.multiRequestsNotificationText}`;
    }
  }

  private adjustMsgElement(msgStr: string) {
    if (!this._msgElement) {
      this._msgElement = new IconMessageToasterElement(undefined, msgStr);
    } else {
      this._msgElement.msg = msgStr;
    }
  }

  private getSingleRequestsNotificationItems(requester: User): AccessRequestsNotificationItems {
    this.adjustRejectButton(requester);
    this.adjustApproveButton(requester);

    const isGuestUser = requester.isAnonymous || this.aclService.isAnonymous(requester);
    if (isGuestUser) {
      this.adjustSingleGuestTitle(requester);
    } else {
      this.adjustSingleUserTitle(requester);
    }

    return {
      firstBottomItem: this._rejectButton,
      secondBottomItem: this._approveButton,
      title: isGuestUser ? this._singleStyleGuestTitle : this._singleStyleUserTitle,
    };
  }

  private adjustRejectButton(requester: User) {
    if (!this._rejectButton) {
      this._rejectButton = new ButtonToasterElement(
        [
          { svgIcon: 'access_requests_reject_black', size: 18 },
          this.translateService.instant('Reject'),
        ],
        {
          handler: () => {
            this.rejectRequesterFromNotification(requester);
          },
          close: true,
        },
        ButtonToasterElementStyle.SECONDARY,
      );
    } else {
      this._rejectButton.onClick = {
        handler: () => {
          this.rejectRequesterFromNotification(requester);
        },
        close: true,
      };
    }
  }

  private adjustApproveButton(requester: User) {
    if (!this._approveButton) {
      this._approveButton = new ButtonToasterElement(
        [{ svgIcon: 'access_requests_approve_white', size: 18 }, this.acceptButtontext],
        {
          handler: () => {
            this.approveRequesterFromNotification(requester);
          },
          close: true,
        },
        ButtonToasterElementStyle.PRIMARY,
      );
    } else {
      this._approveButton.onClick = {
        handler: () => {
          this.approveRequesterFromNotification(requester);
        },
        close: true,
      };
    }
  }

  private adjustSingleGuestTitle(requester: User) {
    if (!this._singleStyleGuestTitle) {
      this._singleStyleGuestTitle = new IconMessageToasterElement(
        { svgIcon: 'access_requests_guest_icon', size: 18 },
        `${requester.name} ${this.singleRequestNotificationText}`,
        undefined,
        undefined,
        undefined,
        IconBackground.INFO,
        true,
        true,
        'flex-shrink: 0;',
      );
    } else {
      this._singleStyleGuestTitle.msg = `${requester.name} ${this.singleRequestNotificationText}`;
    }
  }

  private adjustSingleUserTitle(requester: User) {
    if (!this._singleStyleUserTitle) {
      this._singleStyleUserTitle = new IconMessageToasterElement(
        { user: requester, size: 32 },
        `${requester.name} ${this.singleRequestNotificationText}`,
      );
    } else {
      this._singleStyleUserTitle.icon = { user: requester, size: 32 };
      this._singleStyleUserTitle.msg = `${requester.name} ${this.singleRequestNotificationText}`;
    }
  }

  private approveAll() {
    this._approveAll$.next();
  }

  public get approveAll$() {
    return this._approveAll$.asObservable();
  }

  public dismissRequestsNotification() {
    this.notificationToasterService.dismissNotificationsByCode([this.notificationCode]);
    this.isNotificationActive = false;
  }

  protected approveRequesterFromNotification(requester: User) {
    this._requesterApproved$.next(requester);
  }

  protected rejectRequesterFromNotification(requester: User) {
    this._requesterRejected$.next(requester);
  }

  protected goToAccessRequestsSection() {
    this.sessionSharedDataService.changeParticipantsManagerSection.next(Section.ACCESS_REQUESTS);
    if (
      this.sessionSharedDataService.changeRightPanelView.value !== PanelView.participantsManager
    ) {
      this.sessionSharedDataService.changeRightPanelView.next(PanelView.participantsManager);
    }
  }

  public warning(msg?: string, title?: string) {
    if (!msg && !title) {
      return;
    }

    this._adjustWarningElement(title ?? msg);
    this.showBasicWarning(this.warningCode, !!(msg && title), msg);
  }

  private _adjustWarningElement(title?: string) {
    if (!this._warningElement) {
      this._warningElement = new IconMessageToasterElement(
        { svgIcon: 'access_requests_lock_black', size: 18 },
        title,
        undefined,
        undefined,
        undefined,
        IconBackground.INFO,
        true,
        true,
        'flex-shrink: 0;',
      );
    } else {
      this._warningElement.msg = title;
    }
  }

  private showBasicWarning(code: string, withMsg: boolean, msg?: string) {
    const notificationData = new NotificationDataBuilder(code)
      .type(NotificationType.WARNING)
      .style(ToasterPopupStyle.WARN)
      .version2Notification(true)
      .width(304)
      .topElements([this._warningElement])
      .showProgressBar(false)
      .dismissable(true)
      .timeOut(15)
      .priority(600);

    if (withMsg) {
      this.adjustMsgElement(msg!);
      notificationData.middleElements([this._msgElement]);
    }

    this.notificationToasterService.showNotification(notificationData.build());
  }

  public get requesterApproved$() {
    return this._requesterApproved$.asObservable();
  }

  public get requesterRejected$() {
    return this._requesterRejected$.asObservable();
  }

  public startShowingNotifications() {
    this._startShowingNotifications = true;
  }

  public stopShowingNotifications() {
    this._startShowingNotifications = false;
    this._lastRequestsNum = -1;
    this._lastRequesterID = '';
  }
}

export interface AccessRequestsNotificationItems {
  firstBottomItem: ButtonToasterElement;
  secondBottomItem: ButtonToasterElement;
  title: IconMessageToasterElement;
  message?: IconMessageToasterElement;
}

<div class="menu-wrapper">
  <div class="menu-title" *ngIf="title">{{ title | translate }}</div>
  <div class="menu-items">
    <mat-radio-group color="primary" (change)="handleUpdate.emit($event.value)">
      <mat-radio-button
        [value]="VISIBILITY.PUBLIC"
        [checked]="
          (containers | allInVisibility: VISIBILITY.PUBLIC) ||
          defaultValue === VISIBILITY.PUBLIC"
      >
        <i class="material-icons">public</i>
        {{ 'Public' | translate }}
      </mat-radio-button>
      <mat-radio-button
        [value]="VISIBILITY.ME"
        [checked]="
          (containers | allInVisibility: VISIBILITY.ME) ||
          defaultValue === VISIBILITY.ME"
      >
        <i class="material-icons">visibility</i>
        {{ 'Private' | translate }}
      </mat-radio-button>
      <mat-radio-button
        [value]="VISIBILITY.TEACHERS"
        [checked]="
          (containers | allInVisibility: VISIBILITY.TEACHERS) ||
          defaultValue === VISIBILITY.TEACHERS"
      >
        <i class="material-icons">perm_identity</i>
        {{ 'Teachers Only' | translate }}
      </mat-radio-button>
      <mat-radio-button
        [value]="VISIBILITY.CLASS"
        [checked]="
          (containers | allInVisibility: VISIBILITY.CLASS) ||
          defaultValue === VISIBILITY.CLASS"
      >
        <i class="material-icons">supervisor_account</i>
        {{ 'Teachers & Students' | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>

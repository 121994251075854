import { Component, Output, EventEmitter, Input } from '@angular/core';

import { VISIBILITY } from '../../models/question';

@Component({
  selector: 'app-visibility-selector',
  templateUrl: './visibility-selector.component.html',
  styleUrls: ['./visibility-selector.component.scss']
})
export class VisibilitySelectorComponent {
  @Input() defaultValue?: string;
  @Input() title?: string;
  @Input() isDraft = false;
  @Input() containers: any;
  
  @Output()
  handleUpdate: EventEmitter<string> = new EventEmitter();

  VISIBILITY = VISIBILITY;
}

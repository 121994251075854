import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IconTypes } from 'src/app/standalones/components/pencil-icon/pencil-icon.component';
export enum BreakoutRoomDialogButtonStyles {
  CONFIRM = 'confirm',
  DANGER = 'danger',
  NEUTRAL = 'neutral',
}
export interface BreakoutRoomDialogControl {
  icon?: string;
  svgIcon?: string;
  text: string;
  style: BreakoutRoomDialogButtonStyles;
  callback: () => boolean;
}

export interface BreakoutRoomDialogData {
  header: string;
  body: string;
  options: Array<BreakoutRoomDialogControl>;
}

@Component({
  selector: 'app-breakout-room-dialog',
  templateUrl: './breakout-room-dialog.component.html',
  styleUrls: ['./breakout-room-dialog.component.scss'],
})
export class BreakoutRoomDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<BreakoutRoomDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: BreakoutRoomDialogData,
  ) {}

  close(confirm: boolean) {
    this.dialogRef.close(confirm);
  }

  protected IconTypes = IconTypes;
}

import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user';

@Pipe({
  name: 'presenceSort',
})
export class PresenceSortPipe implements PipeTransform {
  transform(users: User[], presence: Set<string>): User[] {
    return users.sort((a, b) => {
      if (presence?.has(a?._id) && !presence?.has(b?._id)) {
        return -1;
      } else if (!presence?.has(a?._id) && presence?.has(b?._id)) {
        return 1;
      }
      return 0;
    });
  }
}

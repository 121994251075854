import { Injectable, NgZone } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fabric } from 'fabric';
import { cloneDeep } from 'lodash';
import { clamp } from 'lodash-es';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  Subject,
  filter,
  fromEvent,
  merge,
} from 'rxjs';
import { VPT } from '../state/temporary-user-metadata-repository.service';
import { ObjectAbsoluteCoords } from '../sessions/session/wb-canvas/fabric-utils';
import { WatchDogTimer } from '../sessions/session/wb-toolbar/toolbars/realtime-common';
import { SpaceRepository } from '../state/space.repository';
import { ObservableUtils } from '../utilities/ObservableUtils';
import { MoveKeyDownHoldTimeLimit, MoveVPTOnArrowKey, KeyboardArrows } from '../consts';
import { WheelZoomEventData } from '../sessions/session/wb-canvas/wb-canvas.component';
import {
  MAX_ZOOM,
  MIN_ZOOM,
} from '../sessions/session/wb-zoom-controls/wb-zoom-controls.component';
import { YCanvasItemObject } from '../sessions/common/y-session';
import { RealtimeSpaceService } from './realtime-space.service';
import { SessionSharedDataService } from './session-shared-data.service';
import { FLAGS, FlagsService } from './flags.service';

export enum FloatingItemPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  HIDDEN = 'hidden',
}

export interface BoundingRect {
  top: number;
  height: number;
  left: number;
  width: number;
}

export enum ObjectInViewportPosition {
  FULLY_CONTAINED = 'FULLY_CONTAINED',
  FULLY_OUTSIDE = 'FULLY_OUTSIDE',
  INTERSECT = 'INTERSECT',
  UNKNOWN = 'UNKNOWN',
}

const FIT_TO_VIEW_MIN_ZOOM = 1;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SessionsVptService {
  // The current viewportTransform for the frame
  public readonly viewportTransform = new BehaviorSubject<VPT | null>(null);

  // the viewports for all frames, so we can restore vpt when switching frames
  public readonly viewportTransformMap: { [key: string]: VPT } = {};

  // Stores the last mouse position used for pan events
  private lastMousePosition = new BehaviorSubject(new fabric.Point(0, 0));
  public readonly lastMousePosition$ = this.lastMousePosition.asObservable();

  // Observable that fires (at most once every 2 secs) if there is an activity (mouse, keyboard, viewport transform) in the current viewport
  public userActivity$!: Observable<any>;

  private vptWatchDogTimer = new WatchDogTimer(1000, false);
  readonly zoomComplete = new BehaviorSubject<boolean>(false);

  // sudo pan is a way to know the center of the viewport after the pan has been applied
  // without waiting for the actual pan to happen
  private sudoPan = { x: 0, y: 0 };

  // Observable that fires whenever the user zooms/pans
  private userInitiatedZoomOrPan = new Subject<boolean>();
  userInitiatedZoomOrPan$ = this.userInitiatedZoomOrPan.asObservable();

  constructor(
    private sharedDataService: SessionSharedDataService,
    private realtimeSpaceService: RealtimeSpaceService,
    private spaceRepo: SpaceRepository,
    private observerUtils: ObservableUtils,
    private flagsService: FlagsService,
    private zone: NgZone,
  ) {
    // create throttled observer out of angular zone to prevent unncessary CDs
    this.zone.runOutsideAngular(() => {
      this.userActivity$ = this.observerUtils.throttledObservableOf(
        merge(
          fromEvent(document, 'mousemove'),
          fromEvent(document, 'mousedown'),
          fromEvent(document, 'keydown'),
          this.lastMousePosition$,
          this.viewportTransform.pipe(filter((viewportTransform) => !!viewportTransform)),
        ),
        2000,
        { leading: true, trailing: true }, // fire both at the start and end of throttle interval
      );
    });

    combineLatest([
      this.sharedDataService.socketReconnected,
      this.spaceRepo.activeSpaceSelectedBoardUid$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const fabricCanvas = this.sharedDataService.fabricCanvas;
        if (fabricCanvas?.viewportTransform) {
          this.updatePersistentLocationWatchdog(fabricCanvas.viewportTransform);
        }
      });

    this.sharedDataService.trackUndoRedo
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe((data) =>
        this.focusUndoneRedoneObjects(data.beforeUndoRedoObjects, data.changedUIDs),
      );

    this.zone.runOutsideAngular(() => {
      this.viewportTransform.pipe(this.observerUtils.debounceTime(250)).subscribe((x) => {
        const canvas = this.sharedDataService.fabricCanvas;
        if (canvas) {
          canvas.forEachObject((obj) => {
            obj.setCoords(true);
          });
        }
      });
    });
  }

  // Needed to compute a delta in some cases
  public setLastMousePosition(point: fabric.Point) {
    this.lastMousePosition.next(point);
  }

  // Computes the delta for a pan and updates all necessary data
  // The custom handler should mutate the vpt variable directly instead of returning a new value
  public handlePan<T extends Event>(
    event: T,
    customHandler?: (vpt: VPT, delta: [number, number], event: T) => void,
  ) {
    if (this.sharedDataService.vptLocked.getValue()) {
      return;
    }

    this.userInitiatedZoomOrPan.next(true);

    const fabricCanvas = this.sharedDataService.fabricCanvas;

    if (!fabricCanvas?.viewportTransform) {
      return;
    }
    const vpt = fabricCanvas.viewportTransform;
    const { clientX, clientY } = this.getCoords(event);

    let deltaX = clientX - this.lastMousePosition.value.x;
    let deltaY = clientY - this.lastMousePosition.value.y;

    // If there is a wheel event we use that deltaX, otherwise compute it
    const wE = <WheelEvent>(<unknown>event);

    if (wE.deltaX !== undefined) {
      deltaX = this.calculateCappedDelta(-wE.deltaX);
    }
    if (wE.deltaY !== undefined) {
      deltaY = this.calculateCappedDelta(-wE.deltaY);
    }

    if (customHandler) {
      customHandler(vpt, [deltaX, deltaY], event);
    } else {
      vpt[4] += deltaX;
      vpt[5] += deltaY;
    }
    this.setLastMousePosition(new fabric.Point(clientX, clientY));
    this.viewportTransform.next(vpt);
    this.updatePersistentLocationWatchdog(vpt);
    fabricCanvas.requestRenderAll();
  }

  // Sets the vpt manually
  // overrideLock is used so remote changes can modify the vpt
  public setVPT(vpt: VPT, overrideLock?: boolean) {
    if (this.sharedDataService.vptLocked.getValue() && !overrideLock) {
      return;
    }

    const fabricCanvas = this.sharedDataService.fabricCanvas;

    fabricCanvas?.setViewportTransform(vpt);
    fabricCanvas?.requestRenderAll();
    this.viewportTransform.next(vpt);
  }

  // Transforms the different types of events data into a uniform set of (x,y)
  public getCoords(event: Event): { clientX: number; clientY: number } {
    switch (event.type) {
      case 'pointermove':
      case 'pointerdown':
      case 'mousemove':
      case 'mousedown':
        const mE = <PointerEvent | MouseEvent>event;
        return { clientX: mE.clientX, clientY: mE.clientY };

      case 'touchmove':
      case 'touchstart':
        const tE = <TouchEvent>event;
        return { clientX: tE.touches?.[0]?.clientX, clientY: tE.touches?.[0]?.clientY };
    }

    return { clientX: 0, clientY: 0 };
  }

  // Zooms using the mouseWheelEvent
  public handleZoom(event: WheelZoomEventData, multiplier = 1): void {
    if (this.sharedDataService.vptLocked.getValue()) {
      return;
    }

    this.userInitiatedZoomOrPan.next(true);

    const fabricCanvas = this.sharedDataService.fabricCanvas;

    if (!fabricCanvas?.viewportTransform) {
      return;
    }

    const delta = event.deltaY * multiplier;
    const { offsetX, offsetY } = event;
    let zoom = fabricCanvas.getZoom();
    zoom *= 0.9975 ** delta;

    zoom = clamp(zoom, MIN_ZOOM / 100, MAX_ZOOM / 100);
    const point = new fabric.Point(offsetX, offsetY);
    this.zoomToPoint(point, zoom);
  }

  // Zooms to a point for a given zoom level
  // Updates the persistent location and updates the viewportTransform
  public zoomToPoint(point: fabric.Point, zoom: number, fireZoomComplete = false): void {
    if (this.sharedDataService.vptLocked.getValue()) {
      return;
    }

    const fabricCanvas = this.sharedDataService.fabricCanvas;

    if (!fabricCanvas?.viewportTransform) {
      return;
    }

    fabricCanvas.zoomToPoint(point, zoom);

    if (fabricCanvas.viewportTransform) {
      this.viewportTransform.next(fabricCanvas.viewportTransform);
      this.updatePersistentLocationWatchdog(fabricCanvas.viewportTransform);
    }
    fabricCanvas?.requestRenderAll();
    if (fireZoomComplete) {
      this.zoomComplete.next(true);
    }
  }

  // Reset zoom to 100 % and back to startup point
  public resetCanvasToOrigin(): void {
    if (this.sharedDataService.vptLocked.getValue()) {
      return;
    }

    const fabricCanvas = this.sharedDataService.fabricCanvas;

    if (!fabricCanvas?.viewportTransform) {
      return;
    }

    // reset zoom to 100%
    const centerPoint = new fabric.Point(
      <number>fabricCanvas?.getCenter().left,
      <number>fabricCanvas?.getCenter().top,
    );
    fabricCanvas.zoomToPoint(centerPoint, 1);

    // reset view port
    fabricCanvas?.setViewportTransform([1, 0, 0, 1, 0, 0]);

    this.updateLocalVpt();
    fabricCanvas?.requestRenderAll();
  }

  makeCanvasFitItsContent(): void {
    if (this.sharedDataService.vptLocked.getValue()) {
      return;
    }

    const fabricCanvas = this.sharedDataService.fabricCanvas;
    if (!fabricCanvas?.viewportTransform) {
      return;
    }

    const boardItems = fabricCanvas.getObjects();
    if (boardItems.length === 0) {
      this.resetCanvasToOrigin();
      return;
    }

    // Before creating a new group, we should remove the existing group (meaning discarding the selection)
    // to avoid issues when applying the transformation to the selected objects
    fabricCanvas.discardActiveObject();

    // Getting content bounding box
    const group = new fabric.Group(boardItems);
    group.setCoords();
    const contentBoundingBox = group.getBoundingRect();
    group?.destroy();

    const canvasWidth =
      fabricCanvas.getWidth() -
      this.sharedDataService.getPanelWidth().leftPanelWidth -
      this.sharedDataService.getPanelWidth().rightPanelWidth;
    const topToolbarHeight = this.flagsService.isFlagEnabled(FLAGS.WB_TOP_TOOLBAR_V2) ? 42 : 0;
    const canvasHeight = fabricCanvas.getHeight() - topToolbarHeight;

    // Setting zoom value
    const contentWidth = contentBoundingBox.width;
    const contentHeight = contentBoundingBox.height;
    const scaleX = canvasWidth / contentWidth;
    const scaleY = canvasHeight / contentHeight;
    const plainScale = Math.min(scaleX, scaleY) * 0.9; // multiplying with 0.9 acts like a padding here
    const scale = clamp(plainScale, FIT_TO_VIEW_MIN_ZOOM / 100, MAX_ZOOM / 100);
    fabricCanvas.setZoom(scale);

    // Setting the center of the viewport to the center of the content
    const contentCenterX = contentBoundingBox.left + contentBoundingBox.width / 2;
    const contentCenterY = contentBoundingBox.top + contentBoundingBox.height / 2;
    const translateX = contentCenterX * scale - canvasWidth / 2;
    const translateY = contentCenterY * scale - canvasHeight / 2;
    fabricCanvas.absolutePan({ x: translateX, y: translateY });

    fabricCanvas?.requestRenderAll();

    this.updateLocalVpt();
  }

  private updateLocalVpt() {
    if (this.sharedDataService.fabricCanvas?.viewportTransform) {
      this.viewportTransform.next(this.sharedDataService.fabricCanvas?.viewportTransform);
      this.updatePersistentLocationWatchdog(this.sharedDataService.fabricCanvas?.viewportTransform);
    }
  }

  // Updates the persistentLocationWatchdogTimer with the new vpt.
  // This stores a vpt for a given frame for a userID if it has not
  // changed for 1 second
  public updatePersistentLocationWatchdog(vpt: VPT): void {
    const frameUID = this.sharedDataService.getCurrentFrame()?.uid;

    if (!frameUID) {
      return;
    }

    this.vptWatchDogTimer.setFunc(() => {
      this.updatePersistentLocation(vpt, frameUID);
    });
  }

  // Stores the location of this user in the userMetadata
  public updatePersistentLocation(vpt: VPT, frameUID: string): void {
    const fabricCanvas = this.sharedDataService.fabricCanvas;
    if (!fabricCanvas || !frameUID) {
      return;
    }
    this.realtimeSpaceService.service.modifyTemporaryUserMetadata({
      location: { vpt, canvasSize: fabricCanvas.getCanvasSize(), frameUID },
    });
  }

  private calculateCanvasItemsCoords(
    canvasItemsObjects: YCanvasItemObject[],
  ): ObjectAbsoluteCoords[] {
    const canvasItemsPositions = canvasItemsObjects.map((item) => ({
      top: +(item.position?.get('top') || 0),
      left: +(item.position?.get('left') || 0),
      width: +(item.position?.get('width') || 0),
      height: +(item.position?.get('height') || 0),
    }));

    const absoluteCoords: ObjectAbsoluteCoords[] = [...canvasItemsPositions].map((position) => ({
      tl_x: position.left,
      tl_y: position.top,
      br_x: position.left + position.width,
      br_y: position.top + position.height,
    }));

    return absoluteCoords;
  }

  public areAnyObjectsVisibleInViewport(canvasItemsObjects: YCanvasItemObject[]): boolean {
    if (!this.sharedDataService.fabricCanvas) {
      return false;
    }

    const canvasItemsCoords = this.calculateCanvasItemsCoords(canvasItemsObjects);

    const objects = this.sharedDataService.fabricCanvas.getObjects() ?? [];

    const fabricObjectsCoords = objects.map((obj) => ({
      br_x: obj.aCoords?.br?.x || 0,
      br_y: obj.aCoords?.br?.y || 0,
      tl_x: obj.aCoords?.tl?.x || 0,
      tl_y: obj.aCoords?.tl?.y || 0,
    }));


    const absoluteCoords = [...fabricObjectsCoords, ...canvasItemsCoords];
    for (const coords of absoluteCoords) {
      if (this.isObjectInViewport(coords)) {
        return true;
      }
    }

    return false;
  }

  /**
   * Finds if object with given aCoords is fully or partially out of viewport
   * @param objCoords: object's absolute coords (tl, br)
   * @returns boolean
   * */
  public isObjectOutOfViewport(objCoords: ObjectAbsoluteCoords): boolean {
    return [ObjectInViewportPosition.FULLY_OUTSIDE, ObjectInViewportPosition.INTERSECT].includes(
      this.getObjectToViewPortPosition(objCoords),
    );
  }

  /**
   * Finds if object with given aCoords is fully or partially in the current viewport
   * @param objCoords: object's absolute coords (tl, br)
   * @returns boolean
   */
  public isObjectInViewport(objCoords: ObjectAbsoluteCoords): boolean {
    return [ObjectInViewportPosition.FULLY_CONTAINED, ObjectInViewportPosition.INTERSECT].includes(
      this.getObjectToViewPortPosition(objCoords),
    );
  }

  /**
   * Determines the position of an object within the viewport.
   *
   * @param objCoords: object's absolute coords (tl, br).
   * @returns The position of the object in relation to the viewport:
   * - ObjectViewportPosition.FULLY_CONTAINED: The object is fully contained within the viewport.
   * - ObjectViewportPosition.FULLY_OUTSIDE: The object is fully outside the viewport.
   * - ObjectViewportPosition.INTERSECTING: The object intersects with the viewport.
   * - Returns undefined if the viewport bounding rect or necessary data is missing.
   */
  public getObjectToViewPortPosition(objCoords: ObjectAbsoluteCoords): ObjectInViewportPosition {
    const viewPortBoundingRect = this.getViewPortBoundingRect();

    if (
      !viewPortBoundingRect ||
      !this.sharedDataService.fabricCanvas?.width ||
      !this.sharedDataService.fabricCanvas?.vptCoords
    ) {
      return ObjectInViewportPosition.UNKNOWN;
    }

    const { top, bottom, left, right } = viewPortBoundingRect;

    const objectBoundingRect = {
      bottom: objCoords.br_y,
      left: objCoords.tl_x,
      top: objCoords.tl_y,
      right: objCoords.br_x,
    };

    // Check if the object is fully contained in the viewport
    if (
      objectBoundingRect.top >= top &&
      objectBoundingRect.bottom <= bottom &&
      objectBoundingRect.left >= left &&
      objectBoundingRect.right <= right
    ) {
      return ObjectInViewportPosition.FULLY_CONTAINED;
    }

    // Check if the object is fully outside the viewport
    if (
      objectBoundingRect.top > bottom ||
      objectBoundingRect.bottom < top ||
      objectBoundingRect.left > right ||
      objectBoundingRect.right < left
    ) {
      return ObjectInViewportPosition.FULLY_OUTSIDE;
    }

    // If the object is neither fully contained nor fully outside, it must intersect with the viewport
    return ObjectInViewportPosition.INTERSECT;
  }

  public panToObject(
    objWidth: number,
    objHeight: number,
    tl_x: number | undefined,
    tl_y: number | undefined,
  ): void {
    const canvasWidth =
      (this.sharedDataService.fabricCanvas as any).wrapperEl?.offsetWidth ??
      (this.sharedDataService.fabricCanvas as any).getWidth();

    const zoom = (this.sharedDataService.fabricCanvas as any).getZoom();
    let panX = 0;
    let panY = 0;
    panX = (canvasWidth / zoom / 2 - (tl_x ?? 0) - objWidth / 2) * zoom;
    panY =
      ((this.sharedDataService.fabricCanvas as any).getHeight() / zoom / 2 -
        (tl_y ?? 0) -
        objHeight / 2) *
      zoom;
    this.setVPT([zoom, 0, 0, zoom, panX, panY]);
  }

  focusUndoneRedoneObjects(beforeUndoRedoObjects: fabric.Object[], updatedIds: Set<string>): void {
    const beforeUndoRedoIds = beforeUndoRedoObjects.map((item) => item['uid']);
    const afterUndoRedoIds: string[] = this.sharedDataService.fabricCanvas
      ?.getObjects()
      .map((item) => item['uid']) as string[];

    let objects: fabric.Object[] = [];
    const [id] = [...updatedIds];
    // if it's an update operation
    if (afterUndoRedoIds.includes(id) && beforeUndoRedoIds.includes(id)) {
      objects = cloneDeep(this.sharedDataService.fabricCanvas?.getObjects() || []).filter(
        (object) => updatedIds.has(object['uid']),
      ) as fabric.Object[];
    }
    // if it's add / remove operation
    else if (beforeUndoRedoIds.includes(id)) {
      objects = beforeUndoRedoObjects.filter((object) =>
        updatedIds.has(object['uid']),
      ) as fabric.Object[];
    } else {
      return;
    }

    if (!objects.length) {
      return;
    }

    const selection: fabric.ActiveSelection = new fabric.ActiveSelection(objects);

    const rect = selection.getBoundingRect(true);
    const position: ObjectAbsoluteCoords = {
      tl_x: rect.left,
      tl_y: rect.top,
      br_x: rect.left + rect.width,
      br_y: rect.top + rect.height,
    };

    const isOutVPT = this.isObjectOutOfViewport(position);
    if (!isOutVPT) {
      return;
    }

    this.panToObject(rect.width, rect.height, position.tl_x, position.tl_y);
  }

  /**
   * Calculates the ideal position for a floating item relative to a canvas item on a canvas.
   *
   * @param canvasItemBoundingRect - The bounding rectangle of the canvas item.
   * @param positionPreferences - An array of position preferences for the floating item.
   * @param neededHeightForFloatingItem - The needed height for the floating item (default: 45).
   * @param neededWidthForFloatingItem - The needed width for the floating item (default: 45).
   * @param scaleNeededSize - Flag indicating whether the needed size should be scaled based on the zoom level (default: false).
   * @returns The position for the floating item or undefined if required data is unavailable.
   */
  public getCanvasItemFloatingItemPosition(
    canvasItemBoundingRect: BoundingRect,
    positionPreferences: FloatingItemPosition[],
    neededHeightForFloatingItem = 45,
    neededWidthForFloatingItem = 45,
    scaleNeededSize = false,
  ): FloatingItemPosition | undefined {
    const zoom = this.sharedDataService.fabricCanvas?.getZoom();
    const viewPortBoundingRect = this.getViewPortBoundingRect();

    // Check if required data is available
    if (!viewPortBoundingRect || !zoom) {
      return undefined;
    }

    // Scale the needed size based on the zoom level if required
    if (scaleNeededSize) {
      neededWidthForFloatingItem /= zoom;
      neededHeightForFloatingItem /= zoom;
    }

    // Getting the borders of the canvas item
    const { top, height, left, width } = canvasItemBoundingRect;
    const floatingItemTop = top - neededHeightForFloatingItem;
    const floatingItemBottom = top + height + neededHeightForFloatingItem;
    const floatingItemLeft = left - neededWidthForFloatingItem;
    const floatingItemRight = left + width + neededWidthForFloatingItem;

    // Calculate positions and determine visibility
    const {
      top: viewportTop,
      bottom: viewportBottom,
      left: viewportLeft,
      right: viewportRight,
    } = viewPortBoundingRect;
    const isTopObscured = floatingItemTop < viewportTop;
    const isBottomObscured = floatingItemBottom > viewportBottom;
    const isLeftObscured = floatingItemLeft < viewportLeft;
    const isRightObscured = floatingItemRight > viewportRight;

    // Determine the new position for the floating item
    for (const preference of positionPreferences) {
      if (preference === FloatingItemPosition.TOP && !isTopObscured) {
        return FloatingItemPosition.TOP;
      } else if (preference === FloatingItemPosition.RIGHT && !isRightObscured) {
        return FloatingItemPosition.RIGHT;
      } else if (preference === FloatingItemPosition.BOTTOM && !isBottomObscured) {
        return FloatingItemPosition.BOTTOM;
      } else if (preference === FloatingItemPosition.LEFT && !isLeftObscured) {
        return FloatingItemPosition.LEFT;
      }
    }

    return FloatingItemPosition.HIDDEN;
  }

  handleArrowKeysMoveVPT(key: string, holdTime: number): void {
    let movementX = 0;
    let movementY = 0;
    const moveGap =
      holdTime <= MoveKeyDownHoldTimeLimit
        ? MoveVPTOnArrowKey.smallStep
        : MoveVPTOnArrowKey.bigStep;

    switch (key) {
      case KeyboardArrows.LEFT:
        movementX = +moveGap;
        break;
      case KeyboardArrows.RIGHT:
        movementX = -moveGap;
        break;
      case KeyboardArrows.UP:
        movementY = moveGap;
        break;
      case KeyboardArrows.DOWN:
        movementY = -moveGap;
        break;
    }

    this.panBy(movementX, movementY);
  }

  public getViewPortBoundingRect() {
    const panelsWidth = this.sharedDataService.getPanelWidth();
    const zoom = this.sharedDataService.fabricCanvas?.getZoom();
    const vptCoords = this.sharedDataService?.fabricCanvas?.vptCoords;
    const canvasWidth = this.sharedDataService.fabricCanvas?.width;
    if (!zoom || !canvasWidth || !vptCoords) {
      return undefined;
    }
    return {
      bottom: vptCoords.bl.y,
      left: vptCoords.bl.x,
      top: vptCoords.tr.y,
      right:
        vptCoords.bl.x +
        (canvasWidth - panelsWidth.leftPanelWidth - panelsWidth.rightPanelWidth) / zoom,
    };
  }

  public getCenterCoords(): { x: number; y: number } {
    const boundingRect = this.getViewPortBoundingRect();
    let x: number;
    let y: number;
    if (boundingRect) {
      x = boundingRect.left + (boundingRect.right - boundingRect.left) / 2;
      y = boundingRect.top + (boundingRect.bottom - boundingRect.top) / 2;
    } else if (this.sharedDataService.fabricCanvas) {
      return this.sharedDataService.fabricCanvas.getVpCenter();
    } else {
      x = 100;
      y = 100;
    }
    return { x: x + this.sudoPan.x, y: y + this.sudoPan.y };
  }

  public panBy(movementX: number, movementY: number): void {
    this.sudoPan.x += movementX;
    this.sudoPan.y += movementY;
    const canvas = this.sharedDataService.fabricCanvas as fabric.Canvas;
    const currentTransform = (canvas.viewportTransform || []).slice();

    // Apply the movement to the current transform matrix
    currentTransform[4] += movementX;
    currentTransform[5] += movementY;

    const centerPoint = new fabric.Point(
      <number>this.sharedDataService.fabricCanvas?.getCenter().left,
      <number>this.sharedDataService.fabricCanvas?.getCenter().top,
    );
    this.sharedDataService.fabricCanvas?.zoomToPoint(centerPoint, 1);

    // Set the updated transform matrix to the canvas
    canvas.setViewportTransform(currentTransform);
    canvas.requestRenderAll();
    this.viewportTransform.next(canvas.viewportTransform || []);
    canvas.on('after:render', () => (this.sudoPan = { x: 0, y: 0 }));
  }

  public calculateCappedDelta(delta: number) {
    return clamp(delta, -500, 500);
  }

  private _getCenterVptCoordsOfObject(
    objWidth: number,
    objHeight: number,
    tl_x: number | undefined,
    tl_y: number | undefined,
  ): { zoom: number; xCoord: number; yCoord: number } {
    const canvasWidth =
      (this.sharedDataService.fabricCanvas as any).wrapperEl?.offsetWidth ??
      (this.sharedDataService.fabricCanvas as any).getWidth();

    const zoom = (this.sharedDataService.fabricCanvas as any).getZoom();
    let xCoord = 0;
    let yCoord = 0;
    xCoord = (canvasWidth / zoom / 2 - (tl_x ?? 0) - objWidth / 2) * zoom;
    yCoord =
      ((this.sharedDataService.fabricCanvas as any).getHeight() / zoom / 2 -
        (tl_y ?? 0) -
        objHeight / 2) *
      zoom;

    return { zoom, xCoord, yCoord };
  }

  private async animateToObject(
    endValueX: number,
    endValueY: number,
    duration: number,
  ): Promise<void> {
    const canvas = this.sharedDataService.fabricCanvas;
    if (!canvas) {
      throw new Error('Expected canvas to be defined');
    }
    const origZoom = canvas.getZoom();
    const origX = canvas.viewportTransform?.[4] ?? 0;
    const origY = canvas.viewportTransform?.[5] ?? 0;

    const panAnim = {
      x: origX,
      y: origY,
    };
    const done = {
      x: false,
      y: false,
    };
    return new Promise<void>((resolve) => {
      fabric.util.animate({
        startValue: origX,
        endValue: endValueX,
        duration: duration,
        easing: fabric.util.ease['easeOutSine'],
        onChange: function (value) {
          panAnim.x = value;
        },

        onComplete: () => {
          canvas.renderAll();
          done.x = true;

          if (done.y && done.x) {
            resolve();
          }
        },
      });

      fabric.util.animate({
        startValue: origY,
        endValue: endValueY,
        duration: duration,
        easing: fabric.util.ease['easeOutSine'],
        onChange: (value) => {
          panAnim.y = value;
          const viewportTransform = [origZoom, 0, 0, origZoom, panAnim.x, panAnim.y];
          this.setVPT(viewportTransform);
        },
        onComplete: () => {
          if (canvas.viewportTransform) {
            this.setVPT(canvas.viewportTransform);
          }
          done.y = true;
          if (done.y && done.x) {
            resolve();
          }
        },
      });
    });
  }

  public getCanvasSize(fabricCanvas?: fabric.Canvas): [number, number] {
    return [fabricCanvas?.getWidth() || 0, fabricCanvas?.getHeight() || 0];
  }

  public scrollToObject(
    objWidth: number,
    objHeight: number,
    tl_x: number | undefined,
    tl_y: number | undefined,
    duration: number,
  ) {
    const { xCoord, yCoord } = this._getCenterVptCoordsOfObject(objWidth, objHeight, tl_x, tl_y);
    return this.animateToObject(xCoord, yCoord, duration);
  }

  public scrollToCenter(duration: number) {
    return this.animateToObject(0, 0, duration);
  }

  // Loads the stored viewport transform if there
  // is one, else it uses the default transformation
  public loadViewportTransform(frameUID: string) {
    const viewportTransform = this.viewportTransformMap[frameUID] || [1, 0, 0, 1, 0, 0];
    if (viewportTransform) {
      this.setVPT(viewportTransform);
    }
  }

  /**
   * change the viewport value in the vpt map for a specific frame
   * @param frameUid
   * @param vpt
   */
  public setVPTForFrame(frameUid: string, vpt: VPT) {
    this.viewportTransformMap[frameUid] = vpt;
  }

  // Stores the current viewport transform in a map
  // so when the user returns the board we can restore it
  public storeViewportTransform(frameUID: string | undefined) {
    if (!frameUID) {
      return;
    }

    const viewportTransform = this.viewportTransform.getValue() || [1, 0, 0, 1, 0, 0];
    this.viewportTransformMap[frameUID] = viewportTransform;
  }
}

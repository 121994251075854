import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationFormat',
})
export class DurationFormatPipe implements PipeTransform {
  transform(seconds: number): any {
    if (seconds === 0) {
      return '0s';
    }
    const d = Math.round(seconds);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    const hDisplay = h > 0 ? `${h}h` : '';
    const mDisplay = (hDisplay ? ' ' : '') + (m > 0 ? `${m}m` : '');
    const sDisplay = (mDisplay ? ' ' : '') + (s > 0 ? `${s}s` : '');
    // 1h 2m 3s
    return hDisplay + mDisplay + sDisplay;
  }
}

<div *ngIf="user" class="role-chip">
  <div
    *ngIf="!user.institution?.personas && user.personas?.length"
    [ngClass]="'role-inner role-' + user.personas?.[0].toLowerCase().trim()"
  >
    <div class="icon"></div>
    <span>{{ user.personas && user.personas[0] }}</span>
  </div>
  <div
    class="role-inner"
    *ngIf="user.institution?.personas"
    [style]="{
      backgroundColor: getAccentColor().color
    }"
  >
    <span>{{ getAccentColor().name }}</span>
  </div>
</div>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISpaceTemplate } from '../../models/session';
import { User } from '../../models/user';
import { AclService } from '../../services/acl.service';
import { UserService } from '../../services/user.service';
import { SpacesHomePageView } from '../spaces-view/spaces-view.component';

@Component({
  selector: 'app-spaces-templates-list-item',
  templateUrl: './spaces-templates-list-item.component.html',
  styleUrls: ['./spaces-templates-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpacesTemplatesListItemComponent implements OnInit {
  @Input() spaceTemplate: ISpaceTemplate | undefined;
  @Input() isBlankTemplate = false;
  @Input() currentPageView = SpacesHomePageView.SPACES_LIST_VIEW;
  @Input() currentUser?: User;

  @Output() handleTemplateClicked = new EventEmitter<any>();
  @Output() handleUpdateTemplateProps = new EventEmitter<ISpaceTemplate>();
  @Output() handleDeleteTemplateClicked = new EventEmitter<ISpaceTemplate>();

  isInstitutionAdmin = false;
  actionMenuOpened$ = new BehaviorSubject(false);
  protected readonly SpacesHomePageView = SpacesHomePageView;
  deleteIcon = 'delete_menu_icon';

  constructor(
    private aclService: AclService,
    protected userService: UserService
  ) { }

  ngOnInit() {
    this.isInstitutionAdmin = !!this.currentUser && this.currentUser.institution && this.aclService.isAdmin(this.currentUser);
  }

  onEditButton() {
    this.handleUpdateTemplateProps.emit(this.spaceTemplate)
  }

  onDeleteButton() {
    this.handleDeleteTemplateClicked.emit(this.spaceTemplate)
  }
}

import { Component, Output, EventEmitter, HostListener } from '@angular/core';

declare const ggbApplet;
declare const Desmos;

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
})
export class GraphComponent {
  @Output() closed = new EventEmitter();

  isResizeStarted = false;
  disableDrag = false;
  dragPosition = {
    x: 0,
    y: 0,
  };
  ratio = 1.5;
  style = {
    width: 600,
    height: 400,
  };
  originalStyle = {
    width: 600,
    height: 400,
  };

  constructor() {
    const promise = this.loadScript();
    promise.then(() => {
      const addGraphScript = document.createElement('script');
      addGraphScript.src = '../../../assets/libs/addgraph.js';
      document.body.appendChild(addGraphScript);
    });
  }

  loadScript() {
    return new Promise((resolve) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = '../../../assets/libs/deployggb.js';
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
  }

  onMouseDown(event) {
    event.preventDefault();
    this.isResizeStarted = true;
    this.dragPosition = {
      x: event.clientX,
      y: event.clientY,
    };
  }

  @HostListener('window:mouseup', ['$event'])
  onMouseUp(event): void {
    event.preventDefault();
    if (this.isResizeStarted) {
      this.originalStyle = {
        width: this.style.width,
        height: this.style.height,
      };
    }
    this.isResizeStarted = false;
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event): void {
    event.preventDefault();
    if (this.isResizeStarted) {
      const dragDiff = {
        x: event.clientX - this.dragPosition.x,
        y: (event.clientX - this.dragPosition.x) / this.ratio,
      };
      this.style = {
        width: this.originalStyle.width + dragDiff.x,
        height: this.originalStyle.height + dragDiff.y,
      };
      ggbApplet.setWidth(this.style.width);
      ggbApplet.setHeight(this.style.height);
    }
  }
}

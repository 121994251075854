import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Recurrence } from '../models/event';
import { getRepeatLanguage } from '../utilities/event.utils';

@Pipe({
  name: 'repeatLanguage',
  pure: false,
})
export class SchedulesRepeatPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(recurrence: Recurrence, recurrenceEnd?: string | Date): string {
    if (!recurrence) {
      return '';
    }

    return this.translateService.instant(getRepeatLanguage(recurrence, recurrenceEnd));
  }
}

import { DateRange } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { Site, User, AttributeValue, CustomAttribute } from 'src/app/models/user';
import { DateRangePickerSelectionNoLabelPipe } from 'src/app/pipes/data-range-picker-selection-no-label.pipe';
import { DatepickerRange } from '../date-range-picker/date-range-picker.component';

export interface IFilterPill {
  key: FilterKey;
  tag: string;
  displayText: string;
}

export enum FilterType {
  SiteSearch = 'site-search',
  UserSearch = 'user-search',
  Owner = 'owner',
  Date = 'date',
  MultiSelect = 'multi-select',
}

// these keys should be the keys used for calling backend endpoint with filters
export enum DefaultFilterKey {
  SitesFilter = 'sites',
  ParticipantsFilter = 'participants',
  HostsFilter = 'hosts',
  OwnerFilter = 'owner',
  DateCreatedFilter = 'createdRange',
  DateUpdatedFilter = 'dateRange',
  AttributesFilter = 'customAttributes',
}

export type FilterKey = DefaultFilterKey | string;

export interface IFilterAPI {
  query?: string;
  sites?: string[];
  hosts?: string[];
  participants?: string[];
  owner?: string;
  dateRange?: DateRange<Date | null>;
  createdRange?: DateRange<Date | null>;
  customAttributes?: string[];
  institution?: string;
}

export interface BaseFilter {
  isApplied(): boolean;
  // returns true iff the filter has values after updating it
  handleFilterSelection(filterValue?: any): boolean;
  handleClearFilter(): void;
  convertToAPIFormat(apiFilters: IFilterAPI): void;
  getFilterPill(translateService: TranslateService): IFilterPill;
}

export function getMultiSelectFilterPill(
  filter: FilterData,
  selectedItemsCount: number,
  firstItemName: string,
  translateService: TranslateService,
): IFilterPill {
  const anyOfText = translateService.instant('Any of');
  const andText = translateService.instant('and');
  const otherText = translateService.instant(selectedItemsCount === 2 ? 'other' : 'others');

  const displayText =
    selectedItemsCount === 1
      ? `${firstItemName}`
      : `${anyOfText} "${firstItemName}" ${andText} ${selectedItemsCount - 1} ${otherText}`;

  return {
    key: filter.key,
    tag: filter.title,
    displayText,
  };
}

export class SiteSearchFilter implements BaseFilter {
  key: DefaultFilterKey.SitesFilter = DefaultFilterKey.SitesFilter;
  type = FilterType.SiteSearch;
  title: string;
  translateService: TranslateService;

  private _addableSites: Site[];
  private _selectedSites: Site[];

  constructor(
    translateService: TranslateService,
    title: string,
    sites?: Site[],
    addableSites?: Site[],
  ) {
    this.translateService = translateService;
    this.title = this.translateService.instant(title);
    this._selectedSites = sites ?? [];
    this._addableSites = addableSites ?? [];
  }

  get addableSites(): Site[] {
    return this._addableSites;
  }

  get selectedSites(): Site[] {
    return this._selectedSites;
  }

  isApplied(): boolean {
    return !!this._selectedSites.length;
  }

  handleFilterSelection(filterValue?: Site[]): boolean {
    this._selectedSites = filterValue ?? [];
    return this.isApplied();
  }

  handleClearFilter(): void {
    this._selectedSites = [];
  }

  convertToAPIFormat(apiFilters: IFilterAPI): void {
    apiFilters[this.key] = this._selectedSites.map((site: Site) => site._id);
  }

  getFilterPill(): IFilterPill {
    return getMultiSelectFilterPill(
      this,
      this._selectedSites.length,
      this._selectedSites[0].name,
      this.translateService,
    );
  }
}

export class UserSearchFilter implements BaseFilter {
  key: DefaultFilterKey.HostsFilter | DefaultFilterKey.ParticipantsFilter;
  type: FilterType;
  title: string;
  translateService: TranslateService;

  private _selectedUsers: User[];

  constructor(
    key: DefaultFilterKey.HostsFilter | DefaultFilterKey.ParticipantsFilter,
    type: FilterType,
    translateService: TranslateService,
    title: string,
  ) {
    this.key = key;
    this.type = type;
    this.translateService = translateService;
    this.title = this.translateService.instant(title);
    this._selectedUsers = [];
  }

  get selectedUsers(): User[] {
    return this._selectedUsers;
  }

  isApplied(): boolean {
    return !!this._selectedUsers.length;
  }

  handleFilterSelection(filterValue?: User[]): boolean {
    this._selectedUsers = filterValue ?? [];
    return this.isApplied();
  }

  handleClearFilter(): void {
    this._selectedUsers = [];
  }

  convertToAPIFormat(apiFilters: IFilterAPI): void {
    apiFilters[this.key] = this._selectedUsers.map((user: User) => user._id ?? user.email);
  }

  getFilterPill(): IFilterPill {
    return getMultiSelectFilterPill(
      this,
      this._selectedUsers.length,
      this._selectedUsers[0].name ?? this._selectedUsers[0].email,
      this.translateService,
    );
  }
}

export class OwnerFilter implements BaseFilter {
  key: DefaultFilterKey.OwnerFilter = DefaultFilterKey.OwnerFilter;
  type = FilterType.Owner;
  title: string;
  translateService: TranslateService;

  private _label: string;
  private _ownerId: string;
  private _isChecked: boolean;

  constructor(translateService: TranslateService, title: string, ownerId: string, label: string) {
    this.translateService = translateService;
    this.title = this.translateService.instant(title);
    this._ownerId = ownerId;
    this._label = this.translateService.instant(label);
    this._isChecked = false;
  }

  get label(): string {
    return this._label;
  }

  get isChecked(): boolean {
    return this._isChecked;
  }

  set isChecked(isChecked: boolean) {
    this._isChecked = isChecked;
  }

  isApplied(): boolean {
    return this._isChecked;
  }

  handleFilterSelection(filterValue: boolean): boolean {
    this._isChecked = filterValue;
    return this.isApplied();
  }

  handleClearFilter(): void {
    this._isChecked = false;
  }

  convertToAPIFormat(apiFilters: IFilterAPI): void {
    apiFilters[this.key] = this._ownerId;
  }

  getFilterPill(): IFilterPill {
    return {
      key: this.key,
      tag: this.translateService.instant('IS OWNER'),
      displayText: this.translateService.instant('Yes'),
    };
  }
}

export class DateRangeFilter implements BaseFilter {
  key: DefaultFilterKey.DateCreatedFilter | DefaultFilterKey.DateUpdatedFilter;
  title: string;
  translateService: TranslateService;

  type = FilterType.Date;

  private _dateRange?: DateRange<Date | null>;

  constructor(
    key: DefaultFilterKey.DateCreatedFilter | DefaultFilterKey.DateUpdatedFilter,
    translateService: TranslateService,
    title: string,
  ) {
    this.key = key;
    this.translateService = translateService;
    this.title = this.translateService.instant(title);
  }

  get dateRange(): DateRange<Date | null> | undefined {
    return this._dateRange;
  }

  isApplied(): boolean {
    return !!this._dateRange;
  }

  handleFilterSelection(filterValue: DatepickerRange): boolean {
    this._dateRange = filterValue.range;
    return this.isApplied();
  }

  handleClearFilter(): void {
    this._dateRange = undefined;
  }

  convertToAPIFormat(apiFilters: IFilterAPI): void {
    apiFilters[this.key] = this._dateRange;
  }

  getFilterPill(): IFilterPill {
    return {
      key: this.key,
      tag: this.translateService.instant(this.title),
      displayText: new DateRangePickerSelectionNoLabelPipe(this.translateService).transform(
        this._dateRange,
      ),
    };
  }
}

export class AttributesSearchFilter implements BaseFilter {
  key: FilterKey;
  type: FilterType;
  title: string;
  translateService: TranslateService;
  _customAttribute: CustomAttribute;
  _selectedFilterAttributeValues: AttributeValue[];

  constructor(translateService: TranslateService, customAttribute: CustomAttribute) {
    this.translateService = translateService;
    this.key = customAttribute.attribute_key._id;
    this.type = FilterType.MultiSelect;
    this.title = customAttribute.attribute_key.value.toUpperCase();
    this._selectedFilterAttributeValues = [];
    this._customAttribute = customAttribute;
  }

  get addableList(): AttributeValue[] {
    return this._customAttribute.attribute_value;
  }

  get selectedList(): AttributeValue[] {
    return this._selectedFilterAttributeValues;
  }

  isApplied(): boolean {
    return !!this._selectedFilterAttributeValues.length;
  }

  handleFilterSelection(filterValue: AttributeValue[]): boolean {
    this._selectedFilterAttributeValues = filterValue;
    return !!this._selectedFilterAttributeValues.length;
  }

  handleClearFilter(): void {
    this._selectedFilterAttributeValues = [];
  }

  convertToAPIFormat(apiFilters: IFilterAPI): void {
    (apiFilters.customAttributes ??= []).push(
      ...this._selectedFilterAttributeValues.map((attribute) => `${this.key}:${attribute._id}`),
    );
  }

  getFilterPill(): IFilterPill {
    return getMultiSelectFilterPill(
      this,
      this._selectedFilterAttributeValues.length,
      this._selectedFilterAttributeValues[0].value,
      this.translateService,
    );
  }
}

export type FilterData =
  | SiteSearchFilter
  | UserSearchFilter
  | OwnerFilter
  | DateRangeFilter
  | AttributesSearchFilter;

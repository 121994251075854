<div class="rename-dialog">
  <div class="rename-dialog-header">
    <div class="rename-dialog-title">Rename Space</div>
    <div class="rename-dialog-close" (click)="finish(null)">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="rename-dialog-body">
    <input
      autofocus="autofocus"
      #input
      onfocus="this.select()"
      class="rename-dialog-input"
      matInput
      [(ngModel)]="data.title"
      (ngModelChange)="disabled = false"
      [ngClass]="{ danger: data.title?.length > maxAllowedCharacters }"
    />
    <div class="title-err-msg-container" [hidden]="data.title?.length <= maxAllowedCharacters">
      <div class="title-err-msg top-tip">
        Titles must be {{ maxAllowedCharacters }} characters max
      </div>
    </div>
  </div>
  <div class="rename-dialog-footer">
    <app-pencil-button
      class="pencil-button light medium"
      (clickEvent)="finish(null)"
      icon="close"
      [iconConfig]="{ type: IconTypes.matIcon }"
      [label]="'Cancel' | translate"
    >
    </app-pencil-button>
    <app-pencil-button
      [disabled]="disabled || data.title?.length > maxAllowedCharacters || !data.title?.trim()"
      class="pencil-button medium primary"
      (clickEvent)="finish()"
      icon="check"
      [iconConfig]="{ type: IconTypes.matIcon }"
      [label]="'Rename' | translate"
    >
    </app-pencil-button>
  </div>
</div>

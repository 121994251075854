import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Course, NewUser, User, UserInfo } from '../models/user';
import { ErrorInterceptorSkipHeader } from '../error.interceptor';
import { URLService } from './dynamic-url.service';
import { NetworkCacheService } from './network-cache.service';

@Injectable({
  providedIn: 'root',
})
export class ProfilesService {
  courses = new BehaviorSubject<Course[]>([]); // courses with populated institution
  inviteUser = new BehaviorSubject<boolean>(false);

  private skipErrorInterceptorOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      [ErrorInterceptorSkipHeader]: '',
    }),
  };

  constructor(
    private http: HttpClient,
    private urlService: URLService,
    private networkCache: NetworkCacheService,
  ) {}

  async getInstitutionUsers(
    page: number = 1,
    limit: number = 500,
    filters: any = null,
    fetchFreshData: boolean = true,
  ): Promise<User[]> {
    const encodedFilters = filters ? btoa(JSON.stringify(filters)) : '';
    const url = `${this.urlService.getDynamicUrl()}/tutor/institution/users?filters=${encodedFilters}&page=${page}&limit=${limit}`;

    if (fetchFreshData) {
      await this.networkCache.clearFilteredUsersCache();
    }

    return firstValueFrom(this.http.get<User[]>(url));
  }

  postUsers(users: NewUser[], shouldNotify: boolean) {
    return this.http.post(
      `${this.urlService.getDynamicUrl()}/tutor/institution/users`,
      {
        users: users,
        shouldNotify: shouldNotify,
      },
      this.skipErrorInterceptorOptions,
    );
  }

  updateUser(user: User) {
    return this.http.put(`${this.urlService.getDynamicUrl()}/tutor/institution/users/${user._id}`, {
      user,
    });
  }

  updateCurrentUserGeneralInfo(user: User): Observable<any> {
    return this.http.patch(`${this.urlService.getDynamicUrl()}/tutor/institution/users/me`, {
      user,
    });
  }
  getCurrentUserGeneralInfo(): Observable<any> {
    return this.http.get(`${this.urlService.getDynamicUrl()}/tutor/institution/users/me`);
  }

  getUser(id: string): Observable<any> {
    return this.http.get(`${this.urlService.getDynamicUrl()}/tutor/institution/users/${id}`);
  }

  archiveUser(ids: string[], note: string) {
    return this.http.post(`${this.urlService.getDynamicUrl()}/tutor/institution/users/delete`, {
      ids: ids,
      note,
    });
  }

  getAllCourses(): Observable<any> {
    return this.http.get(`${this.urlService.getDynamicUrl()}/tutor/institution/courses`);
  }

  postCourse(course: Course) {
    return this.http.post(`${this.urlService.getDynamicUrl()}/tutor/institution/courses`, {
      course,
    });
  }

  updateCourse(course: Course) {
    return this.http.put(
      `${this.urlService.getDynamicUrl()}/tutor/institution/courses/${course._id}`,
      { course },
    );
  }

  getUsersByCourse(courseId: string) {
    return this.http.get(
      `${this.urlService.getDynamicUrl()}/tutor/institution/users/course/${courseId}`,
    );
  }

  addUsersToCourse(users: string[], courseId): Observable<any> {
    return this.http.post(
      `${this.urlService.getDynamicUrl()}/tutor/institution/users/course/${courseId}`,
      {
        users,
      },
    );
  }

  removeUsersFromCourse(users: string[], courseId): Observable<any> {
    return this.http.post(
      `${this.urlService.getDynamicUrl()}/tutor/institution/users/course/remove/${courseId}`,
      {
        users,
      },
    );
  }

  addUsersToCourses(users: string[], courses: string[]): Observable<any> {
    return this.http.post(`${this.urlService.getDynamicUrl()}/tutor/institution/users/courses`, {
      users,
      courses,
    });
  }

  archiveCourse(courseId: string): Observable<any> {
    return this.http.delete(
      `${this.urlService.getDynamicUrl()}/tutor/institution/courses/${courseId}`,
    );
  }

  refreshCourses(): void {
    this.getAllCourses().subscribe((res) => {
      if (res && res.courses) {
        this.courses.next(res.courses);
      }
    });
  }

  suspendUsers(users: string[], note?: string): Observable<any> {
    return this.http.post(`${this.urlService.getDynamicUrl()}/tutor/institution/users/suspend`, {
      ids: users,
      note,
    });
  }

  unSuspendUsers(users: any): Observable<any> {
    return this.http.post(`${this.urlService.getDynamicUrl()}/tutor/institution/users/suspend`, {
      ids: users,
      unsuspend: true,
    });
  }

  hideRevokedInfo(): Observable<any> {
    return this.http.post(
      `${this.urlService.getDynamicUrl()}/tutor/institution/users/hiderevokedinfo`,
      {},
    );
  }

  updateUserCourses(users: UserInfo[]): Observable<any> {
    return this.http.post(`${this.urlService.getDynamicUrl()}/tutor/institution/users/courses`, {
      users,
    });
  }

  searchInstitution(searchTerm: string): Observable<any> {
    return this.http.get(
      `${this.urlService.getDynamicUrl()}/tutor/institution/search/${searchTerm}`,
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentFormat',
  pure: true,
})
export class MomentFormatPipe implements PipeTransform {
  transform(date: any, format: string): string {
    return moment(date).format(format);
  }
}

import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  firstValueFrom,
  fromEvent,
  pairwise,
  Subject,
  Subscription,
  throwError,
} from 'rxjs';
import { ERRORS, INFOS } from 'src/app/common/utils/notification-constants';
import { URL_CONSTANTS } from 'src/app/common/utils/url';
import { ISession, ISession as ISpace, Session, SessionUser } from 'src/app/models/session';
import { User } from 'src/app/models/user';
import { AclService, Feature } from 'src/app/services/acl.service';
import { FLAGS, FlagsService } from 'src/app/services/flags.service';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from 'src/app/services/notification-toaster.service';
import { PresenceProvider, PresenceRoomInfo } from 'src/app/services/presence-provider';
import { RealtimeSpaceService } from 'src/app/services/realtime-space.service';
import { FiltersFactoryService } from 'src/app/services/filters-factory.service';
import { FilterData } from 'src/app/standalones/components/generic-filters-view/filters.interface';
import { changeFiltersToAPIFormat, FiltersService } from 'src/app/services/filters.service';
import {
  SessionSharedDataService,
  SessionView,
} from 'src/app/services/session-shared-data.service';
import { SpacesService } from 'src/app/services/spaces.service';
import { TelemetryService } from 'src/app/services/telemetry.service';
import { UiService } from 'src/app/services/ui.service';
import { UserService } from 'src/app/services/user.service';
import { ISpaceUI, SpaceRepository } from 'src/app/state/space.repository';
import {
  ButtonToasterElement,
  ButtonToasterElementStyle,
} from 'src/app/ui/notification-toaster/button-toaster-element/button-toaster-element.component';
import { ToasterPopupStyle } from 'src/app/ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { IconMessageToasterElement } from 'src/app/ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { IconTypes } from 'src/app/standalones/components/pencil-icon/pencil-icon.component';
import { MatDialog } from '@angular/material/dialog';
import { institutionHasSites } from 'src/app/utilities/sites.utils';
import { Mutex } from 'async-mutex';
import { modifiedSetTimeout } from 'src/app/utilities/ZoneUtils';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NetworkStatus, SessionsSyncService } from '../../services/sessions-sync.service';
import { WaitingRoomService } from '../session/request-access/waiting-room/waiting-room.service';
import {
  SpacesTemplatesCategories,
  SpacesTemplatesService,
} from '../../services/spaces-templates.service';
import { SelectSiteDialogComponent } from './select-site-dialog/select-site-dialog.component';
import { SelectSiteBottomSheetComponent } from './select-site-bottom-sheet/select-site-bottom-sheet.component';

type SessionTemplate = Session & ViewTemplateData;

interface SpaceCloningOptions {
  spaceToCloneId: string | undefined;
  isFromTemplate: boolean;
}

export enum SpacesHomePageView {
  SPACES_LIST_VIEW = 'spaces list view',
  ALL_TEMPLATES_VIEW = 'spaces templates view',
}

interface ViewTemplateData {
  presenceUsers?: User[];
  waitingRoomUsers?: User[];
  hosts?: SessionUser[];
  ownerUser?: User | null;
  usersInDifferentRooms?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-spaces-view',
  templateUrl: './spaces-view.component.html',
  styleUrls: ['./spaces-view.component.scss'],
})
export class SpacesViewComponent implements OnInit, OnDestroy {
  @ViewChild('spacesList') private spacesListElement!: ElementRef;
  user?: User;
  spaces: Session[] = [];
  loadedSpaces: Session[] = [];
  userLookup: { [id: string]: User } = {};
  openedSessionId = '';
  usersInCallIds = new Map<string, Set<string>>();
  recentFrames: { [key: string]: any } = {};

  // UI
  spacesLoadingSnackbar?: MatSnackBarRef<TextOnlySnackBar>;
  disableCreateSpace = false;
  numberOfSpacesPerLoad = 10;

  // Loading
  isLoadingSpaces = false;
  isLoadingSearch = false;
  // if we're fetching spaces from the API
  isFetchingSpaces = false;
  // if we should use skip instead of page number
  shouldUseSkip = false;
  // number of pages were fetched, we start with 1
  fetchedPages = 1;
  // Flag values
  langSelectionEnabled = false;
  keepNewUserInSpacesListEnabled = false;
  shouldLoadSpaces = false;
  breakoutRoomsEnabled = false;
  canCreateSpace = false;

  // Subscription vals
  spacesPresenceSubscribers: { [key: string]: Subscription } = {};
  waitingRoomPresenceSubscribers: { [key: string]: Subscription } = {};
  spacePresenceObject: { [key: string]: Set<string> } = {};
  spacesInCallSubscribers: { [key: string]: Subscription } = {};
  presenceSpaceIdList: Set<string> = new Set<string>();

  isScrolling: Subject<boolean> = new Subject<boolean>();

  presenceInfoBreakoutRoomsId?: PresenceRoomInfo[];
  currentBreakoutRoomIds: { [spaceId: string]: string | undefined } = {};

  // total number of avaliable spaces
  totalPages = 1;
  // current fetched spaces page, we're starting from one
  currentPage = 1;
  // map of the spaces pages in the API that was fetched
  loadedSpacesPageMap = new Map<number, boolean>();
  spacesLoadedMap = new Map<string, Session>();
  private _syncSessionMutex = new Map<string, Mutex>();

  filtersPanelOpen = false;

  filtersEnabled$ = this.flagsService.featureFlagChanged(FLAGS.ENABLE_SPACES_FILTERS);
  private _currentlyOpenedSpaceDetails$ = new BehaviorSubject<Session | undefined>(undefined);
  currentlyOpenedSpaceDetails$ = this._currentlyOpenedSpaceDetails$.asObservable();

  isSpaceDetailsOpen = false;
  isSitesEnabled = false;

  SpacesHomePageView = SpacesHomePageView;
  currentPageView = SpacesHomePageView.SPACES_LIST_VIEW;
  isSpacesTemplatesEnabled = false;
  appliedFilters: FilterData[] = [];
  spaceSearchQuery?: string;

  protected IconTypes = IconTypes;

  private resizeSubscription!: Subscription;

  constructor(
    public uiService: UiService,
    public filtersService: FiltersService,
    private router: Router,
    private telemetry: TelemetryService,
    private spacesService: SpacesService,
    private flagsService: FlagsService,
    private sharedDataService: SessionSharedDataService,
    private userService: UserService,
    private translateService: TranslateService,
    private _snackBar: MatSnackBar,
    private zone: NgZone,
    private realtimeSpaceService: RealtimeSpaceService,
    private notificationToasterService: NotificationToasterService,
    private presenceProvider: PresenceProvider,
    private aclService: AclService,
    private sessionsSyncService: SessionsSyncService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private spaceRepository: SpaceRepository,
    private waitingRoomService: WaitingRoomService,
    private spacesTemplatesService: SpacesTemplatesService,
    private filtersFactoryService: FiltersFactoryService,
  ) {
    this.uiService.setTabTitle('Spaces', false);

    // Set flag values
    this.setFlagValues();
  }

  private calculateNumberOfSpacesPerLoad(): number {
    // a space row height is 112px
    const rowHeight = 112;
    //  88px for ui header
    //  45px for search section
    // need to load 2 more to handle scroll on mobile
    return Math.floor((window.innerHeight - 88 - 45) / rowHeight) + 2;
  }

  private setupResizeListener(): void {
    this.resizeSubscription = fromEvent(window, 'resize')
      .pipe(debounceTime(250), untilDestroyed(this))
      .subscribe(() => {
        const newSpacesPerLoad = this.calculateNumberOfSpacesPerLoad();

        if (newSpacesPerLoad > this.numberOfSpacesPerLoad) {
          const additionalSpacesToLoad = newSpacesPerLoad - this.loadedSpaces.length;
          if (additionalSpacesToLoad > 0) {
            this.numberOfSpacesPerLoad = newSpacesPerLoad;
            this.resetSearchState();
            this.getSpaces();
          }
        }
      });
  }

  ngOnInit(): void {
    this.numberOfSpacesPerLoad = this.calculateNumberOfSpacesPerLoad();
    this.setupResizeListener();
    // Load user
    this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
      if (!res?.user) {
        return;
      }

      // it is the same user, so we prevent duplicated requests and listeners
      if (this.user?._id === res.user._id) {
        return;
      }

      this.user = res.user;
      this.canCreateSpace = this.aclService.checkUserCanCreateSpace(this.user);

      this.sharedDataService.sessionView.switchToSessionView(SessionView.SPACES_LANDING_VIEW);
      this.isLoadingSpaces = true;
      this.setUserReconnectedSubscribe();

      this.filtersService.appliedFilters$
        .pipe(untilDestroyed(this), distinctUntilChanged())
        .subscribe((filters) => {
          this.appliedFilters = filters;
          this.resetSearchState();
          this.getSpaces();
        });

      this.isSitesEnabled = institutionHasSites(this.user?.institution);
    });

    // handler to listen for creating a space from templates
    this.spacesTemplatesService.createSpaceFromTemplate$
      .pipe(untilDestroyed(this))
      .subscribe((spaceTemplate) => {
        const spaceCloningOptions = {
          spaceToCloneId: spaceTemplate?.spaceId,
          isFromTemplate: !!spaceTemplate,
        };
        this.createNewSpace('', false, spaceCloningOptions);
      });

    this.filtersFactoryService
      .createSpacesFilters()
      .pipe(untilDestroyed(this))
      .subscribe((filters: FilterData[]) => {
        this.filtersService.initFilters(filters);
      });
  }

  ngOnDestroy(): void {
    if (this.spacesLoadingSnackbar) {
      this.spacesLoadingSnackbar.dismiss();
    }

    if (this.presenceSpaceIdList) {
      const currentPresenceSpaces = Array.from(this.presenceSpaceIdList);
      this.presenceProvider.leaveSpacePresenceRooms(
        currentPresenceSpaces,
        this.presenceInfoBreakoutRoomsId,
      );
    }

    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }

    this.filtersService.clearFilters();
  }

  /* Get and set flag values */
  setFlagValues(): void {
    this.langSelectionEnabled = this.flagsService.isFlagEnabled(FLAGS.SPACES_LANGUAGE_SELECTOR);
    this.keepNewUserInSpacesListEnabled = this.flagsService.isFlagEnabled(
      FLAGS.SPACES_KEEP_NEW_USERS_IN_SPACES_LIST,
    );
    this.shouldLoadSpaces = this.flagsService.isFlagEnabled(FLAGS.SPACES_LANDING_PREVIEWS);
    if (this.flagsService.isFlagEnabled(FLAGS.BREAKOUT_ROOMS)) {
      this.breakoutRoomsEnabled = true;
    }
    this.isSpacesTemplatesEnabled = this.flagsService.isFlagEnabled(FLAGS.ENABLE_SPACES_TEMPLATES);
  }

  setUserReconnectedSubscribe(): void {
    this.sessionsSyncService.networkStatusObservable$
      .pipe(untilDestroyed(this), distinctUntilChanged(), pairwise())
      .subscribe(([prev, current]) => {
        /**
         * if user was offline, and we tried to fetch new spaces
         * after use came back online we need to fetch spaces that wasn't fetched
         */
        if (
          prev === NetworkStatus.DISCONNECTED &&
          current === NetworkStatus.CONNECTED &&
          this.fetchedPages < this.currentPage
        ) {
          this.getSpaces();
        }
      });
  }

  /**
   * make the HTTP call to get the spaces
   */
  getSpaces(): void {
    // check if we should use skip instead of page number
    let skip: number | undefined;
    if (this.shouldUseSkip) {
      skip = this.spaces.length;
    }
    this.isFetchingSpaces = true;

    const apiFilters = {
      ...changeFiltersToAPIFormat(this.appliedFilters),
      query: this.spaceSearchQuery,
    };

    this.spacesService
      .getSpacesList(apiFilters, this.currentPage, this.numberOfSpacesPerLoad, skip, true)
      .pipe(untilDestroyed(this))
      .pipe(
        untilDestroyed(this),
        catchError((err) => {
          this.displayError();
          this.isLoadingSpaces = false;
          this.isFetchingSpaces = false;
          return throwError(() => err);
        }),
      )
      .subscribe((sessionListResult) => {
        this.isLoadingSpaces = false;
        this.isLoadingSearch = false;
        this.isFetchingSpaces = false;
        this.totalPages = sessionListResult.totalPages;

        // prevent for any reason duplication of the same pageNumber result
        if (this.loadedSpacesPageMap.get(sessionListResult.pageNumber)) {
          return;
        }
        // keep track of loaded and requests spaces page number
        this.loadedSpacesPageMap.set(sessionListResult.pageNumber, true);
        const { sessions: spacesList } = sessionListResult;
        if (spacesList?.length) {
          this.sharedDataService.populateData(spacesList);
          // add new spaces from the API to spaces list, and filter to allow unique spaces
          this.spaces = [
            ...this.spaces,
            ...spacesList
              .map((space: ISpace) => new Session(space))
              .filter((space: Session) => {
                if (this.spacesLoadedMap.get(space._id)) {
                  return false;
                }

                this.spacesLoadedMap.set(space._id, space);
                return true;
              }), // prevent duplicated spaces
          ];

          if (this.breakoutRoomsEnabled) {
            this.presenceInfoBreakoutRoomsId = this.extractCurrentBreakoutRoomIds(this.spaces);
          }
          if (this.spaces) {
            this.loadSpaces();
          }
          // once we loaded new spaces, we set the number of fetched pages to the current fetched pages
          this.fetchedPages = this.currentPage;
        } else {
          if (
            spacesList?.length === 0 &&
            !this.spaceSearchQuery &&
            this.user &&
            !this.user.defaultSpaceCreated &&
            this.aclService.isAllowed(this.user, Feature.create_whiteboard) &&
            !this.isSitesEnabled
          ) {
            // Create a new space if user has none
            this.createNewSpace(this.translateService.instant('My First Space'), true);

            // If it is the last space deleted, so that it doesn't create a new space
            this.user.defaultSpaceCreated = true;
            this.userService.user.next({ user: this.user });
          } else {
            this.telemetry.event('user-space-management-visit');
          }
        }

        if (this.user?.institution) {
          this.telemetry.event('space-manager-loaded');
        }
      });
  }

  displayError(): void {
    this.isLoadingSpaces = false;
    this.isLoadingSearch = false;
    const title = new IconMessageToasterElement(
      { icon: 'error_outline', size: 18 },
      this.translateService.instant('Error loading your Spaces'),
    );
    const message = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(
        'We’re having trouble loading your Spaces. Click refresh to try again.',
      ),
    );
    const retryButton = new ButtonToasterElement(
      [{ icon: 'refresh', size: 16 }, this.translateService.instant('Refresh')],
      {
        handler: () => {
          this.refreshPage();
        },
        close: false,
      },
      ButtonToasterElementStyle.RAISED,
    );
    const failedSpaceLoadingNotificationData = new NotificationDataBuilder(
      ERRORS.FAILED_TO_LOAD_SPACES,
    )
      .type(NotificationType.ERROR)
      .style(ToasterPopupStyle.ERROR)
      .topElements([title])
      .middleElements([message])
      .bottomElements([retryButton])
      .priority(380)
      .width(235)
      .toastClass('spacesNotification')
      .showProgressBar(false)
      .dismissable(true)
      .build();

    this.notificationToasterService.showNotification(failedSpaceLoadingNotificationData);
  }

  refreshPage(): void {
    window.location.reload();
  }

  cloneSpace(space: Session): void {
    const spaceCloningOptions = { isFromTemplate: false, spaceToCloneId: space._id };
    this.createNewSpace(space.title, false, spaceCloningOptions);
  }

  /* Creates a new space */
  createNewSpace(
    title = '',
    isFirstSpace = false,
    spaceCloningOptions?: SpaceCloningOptions,
  ): void {
    // if sites are enabled open the dialog first to choose a site before creating the space
    if (this.isSitesEnabled) {
      this.openSelectSiteDialog(title, isFirstSpace, spaceCloningOptions);
    } else {
      this.handleSpaceCreation(title, isFirstSpace, undefined, spaceCloningOptions);
    }
  }

  openSelectSiteDialog(
    title: string,
    isFirstSpace: boolean,
    spaceCloningOptions?: SpaceCloningOptions,
  ) {
    if (this.uiService.isMobile.getValue()) {
      const bottomSheetRef = this.bottomSheet.open(SelectSiteBottomSheetComponent, {
        panelClass: 'select-site-bottom-sheet-panel',
        disableClose: true,
        autoFocus: false,
        data: {
          user: this.user,
        },
      });

      bottomSheetRef
        .afterDismissed()
        .pipe(untilDestroyed(this))
        .subscribe((result) => {
          this.handleSiteSelectionModalResponse(result, title, isFirstSpace, spaceCloningOptions);
        });
    } else {
      const dialogRef = this.dialog.open(SelectSiteDialogComponent, {
        width: '40%',
        panelClass: 'select-site-dialog-container',
        data: {
          user: this.user,
        },
      });

      dialogRef
        .afterClosed()
        .pipe(untilDestroyed(this))
        .subscribe((result) => {
          this.handleSiteSelectionModalResponse(result, title, isFirstSpace, spaceCloningOptions);
        });
    }
  }

  handleSiteSelectionModalResponse(
    result: any,
    title: string,
    isFirstSpace: boolean,
    spaceCloningOptions?: SpaceCloningOptions,
  ) {
    if (!result) {
      return;
    }

    this.handleSpaceCreation(title, isFirstSpace, result.siteId, spaceCloningOptions);
  }

  handleSpaceCreation(
    title: string,
    isFirstSpace: boolean,
    siteId?: string,
    spaceCloningOptions?: SpaceCloningOptions,
  ) {
    // Avoid multiple spaces to be created while in space creation process
    if (this.disableCreateSpace) {
      return;
    }
    this.disableCreateSpace = true;

    if (spaceCloningOptions?.spaceToCloneId && !spaceCloningOptions?.isFromTemplate) {
      this.notificationToasterService.showLoadingNotification(
        this.translateService.instant(
          `${this.translateService.instant('Duplicating')} ${title}....`,
        ),
        INFOS.SPACE_CLONE_LOADING,
        true,
        undefined,
        2,
      );
      title = `${title} - Copy`;
    }

    this.spacesService
      .createSpace(title, { spaceToCloneId: spaceCloningOptions?.spaceToCloneId, siteId })
      .pipe(untilDestroyed(this))
      .subscribe((space) => {
        this.notificationToasterService.dismissLoadingNotification(INFOS.SPACE_CLONE_LOADING);
        this.sharedDataService.newSessionCreated.next(true);
        if (!(isFirstSpace && this.keepNewUserInSpacesListEnabled)) {
          this.sharedDataService.sessionView.switchToSessionView(SessionView.WHITEBOARD);
          this.router.navigate([`${URL_CONSTANTS.SPACES}/${space._id}`], {}).finally(() => {
            // if space was created using template and we are in the all templates view, change the view
            if (this.currentPageView === SpacesHomePageView.ALL_TEMPLATES_VIEW) {
              this.changeCurrentPageView(SpacesHomePageView.SPACES_LIST_VIEW);
            }
          });
        } else {
          this.sharedDataService.populateData([space]);
          space.populatedUsers = [this.user];
          this.spaces = [new Session(space)];
          if (this.breakoutRoomsEnabled) {
            this.presenceInfoBreakoutRoomsId = this.extractCurrentBreakoutRoomIds(this.spaces);
          }
          this.loadSpaces();
        }
        this.disableCreateSpace = false;
        this.isLoadingSpaces = false;
      });
  }

  /** Initialize space listeners*/
  loadSpaces(numberOfSpaces = this.numberOfSpacesPerLoad): void {
    this.updateUserLookup();
    this.loadSpacesSnackbar();

    // Prevent Angular from running change detection
    this.zone.runOutsideAngular(() => {
      const spacesToLoad = this.spaces.slice(
        this.loadedSpaces.length,
        this.loadedSpaces.length + numberOfSpaces,
      );
      const spacesToLoadIds = spacesToLoad.map((space) => space._id);
      this.loadedSpaces.push(...spacesToLoad);
      if (this.shouldLoadSpaces) {
        this.realtimeSpaceService.service.joinRooms(spacesToLoadIds, () => {
          this.initPresenceListeners(spacesToLoadIds, this.presenceInfoBreakoutRoomsId);
        });
      } else {
        this.initPresenceListeners(spacesToLoadIds, this.presenceInfoBreakoutRoomsId);
        this.spacesLoadingSnackbar?.dismiss();
      }
      this.initInCallListeners(spacesToLoadIds, this.presenceInfoBreakoutRoomsId);
    });
  }

  spacesListInviteUpdate(updatedSpace: ISpace & ISpaceUI): void {
    const indexInSpaces = this.spaces.findIndex((space) => space._id === updatedSpace._id);
    if (indexInSpaces !== -1) {
      this.spaces[indexInSpaces] = updatedSpace;
      if (this.breakoutRoomsEnabled) {
        this.presenceInfoBreakoutRoomsId = this.extractCurrentBreakoutRoomIds([
          this.spaces[indexInSpaces],
        ]);
      }
      this.updateSpaceUserLookup(this.spaces[indexInSpaces]);
      this.listenForSpacePresence(this.spaces[indexInSpaces]);
      this.listenToWaitingRoomPresence(this.spaces[indexInSpaces]);
      this.listenForSpaceInCall(this.spaces[indexInSpaces]);
      const indexInLoadedSpaces = this.loadedSpaces.findIndex(
        (space) => space._id === updatedSpace._id,
      );
      if (indexInLoadedSpaces !== -1) {
        this.loadedSpaces[indexInLoadedSpaces] = this.spaces[indexInSpaces];
      }
    }
  }

  updateSpaceUserLookup(space: Session): void {
    if (space.populatedUsers) {
      space.populatedUsers = space.populatedUsers.filter(Boolean);
      space.populatedUsers.forEach((user) => {
        if (user) {
          this.userLookup[user._id] = user;
        }
      });
    }
  }

  updateUserLookup(): void {
    this.spaces.forEach((space) => {
      this.updateSpaceUserLookup(space);
      this.setSpaceActions(space);
    });
  }

  setSpaceActions(space: ISession): void {
    (space as any).isSpaceHost = this.spaceRepository.hasSpaceHostPermissions(this.user, space);
  }

  listenForSpacePresence(space: SessionTemplate): void {
    const spaceId = space._id;
    this.spacesPresenceSubscribers[spaceId]?.unsubscribe();
    const breakoutRoomId = this.currentBreakoutRoomIds[spaceId];
    const hasHostPermissions = this.spaceRepository.hasSpaceHostPermissions(this.user, space);

    this.spacesPresenceSubscribers[spaceId] = this.presenceProvider
      .getRoomPresenceActivity(spaceId, hasHostPermissions ? undefined : breakoutRoomId)
      .pipe(untilDestroyed(this))
      .subscribe(async (presence) => {
        this.spacePresenceObject[spaceId] = presence ?? new Set();
        const spacePresentUsers = await this.getPresenceUsers(space);
        const areUsersDistributedInRooms =
          hasHostPermissions && this.presenceProvider.areBreakoutRoomsUsed(space._id);
        space['presenceUsers'] = spacePresentUsers;
        space['usersInDifferentRooms'] = areUsersDistributedInRooms;
        this.filterCallUsersFromSpaceUsers(space);
        space['hosts'] = space.users.filter((user) => user.isOwner);
        this.setOwner(space);
      });
    // TODO: [@JOAQRA] subscribe to diffUserSpacePresenceStores to combare between redis vs. firebase presence stores.
  }

  filterCallUsersFromSpaceUsers(space: SessionTemplate) {
    if (space['presenceUsers']) {
      const usersInCurrentRoomCall = this.usersInCallIds.get(space._id);
      space['inSpaceNotCallUsers'] = space['presenceUsers'].filter(
        (user: User) => !usersInCurrentRoomCall?.has(user._id),
      );
    }
  }

  private listenToWaitingRoomPresence(space: SessionTemplate) {
    const spaceId = space._id;
    this.waitingRoomPresenceSubscribers[spaceId]?.unsubscribe();
    this.waitingRoomPresenceSubscribers[spaceId] = this.waitingRoomService
      .getWaitingRoomPresenceActivity$(spaceId)
      .pipe(untilDestroyed(this))
      .subscribe((waitingRoomIDsSet: Set<string>) => {
        this.handleWaitingRoomPresenceChange(space, waitingRoomIDsSet);
      });
  }

  private handleWaitingRoomPresenceChange(space: SessionTemplate, waitingRoomIDsSet: Set<string>) {
    space['waitingRoomUsers'] =
      space.populatedUsers?.filter((user) => waitingRoomIDsSet.has(user._id)) ?? [];
  }

  /**
   * listen to presence for each space and update displayed users
   * @param spaceIds array of space ID strings
   */
  initPresenceListeners(
    spaceIds: string[],
    presenceInfoBreakoutRoomsId?: PresenceRoomInfo[],
  ): void {
    this.presenceSpaceIdList = new Set([...this.presenceSpaceIdList, ...spaceIds]);
    this.presenceProvider.joinAndQuerySpacePresenceRooms(spaceIds, presenceInfoBreakoutRoomsId);

    const spacesToSub = this.spaces.filter((x) => spaceIds.includes(x._id));
    spacesToSub?.forEach((space) => {
      this.listenForSpacePresence(space);
      this.listenToWaitingRoomPresence(space);
    });
  }

  listenForSpaceInCall(space: SessionTemplate): void {
    const spaceId = space._id;
    const breakoutRoomId = this.currentBreakoutRoomIds[spaceId];
    const hasHostPermissions = this.spaceRepository.hasSpaceHostPermissions(this.user, space);
    this.spacesInCallSubscribers[spaceId]?.unsubscribe();
    this.spacesInCallSubscribers[spaceId] = this.presenceProvider
      .getCallPresenceActivity(spaceId, hasHostPermissions ? undefined : breakoutRoomId)
      .pipe(untilDestroyed(this))
      .subscribe(async (presenceUsers) => {
        if (!hasHostPermissions || !this.presenceProvider.areBreakoutRoomsUsed(space._id)) {
          this.usersInCallIds.set(space._id, presenceUsers);
          const usersNotFound = [...presenceUsers].filter((userId) => !this.userLookup[userId]);
          if (usersNotFound.length > 0) {
            await this._syncSpace(space._id, usersNotFound);
          }
          space['inCallUsers'] = [...presenceUsers].map((userId) => this.userLookup[userId]);
          this.filterCallUsersFromSpaceUsers(space);
        } else {
          this.usersInCallIds.delete(space._id);
          space['inCallUsers'] = [];
        }
      });
  }

  /* listen to in call activity for each session and update in call users*/
  initInCallListeners(spacesIds: string[], presenceInfoBreakoutRoomsId?: PresenceRoomInfo[]): void {
    if (!spacesIds || spacesIds.length == 0) {
      return;
    } else {
      const spacesToSub = this.spaces.filter((x) => spacesIds.includes(x._id));
      spacesToSub.forEach((space) => {
        this.listenForSpaceInCall(space);
        // TODO: [@JOAQRA] subscribe to diffUserCallPresenceStores to combare between redis vs. firebase presence stores.
      });
    }
  }

  /* Set the owner of the space */
  setOwner(space: SessionTemplate): void {
    if (space.populatedUsers) {
      space['ownerUser'] = space.populatedUsers.find((user) => user._id === space.owner);
    } else {
      space['ownerUser'] = null;
    }
  }

  /* Get users who are currently in the space */
  async getPresenceUsers(space: Session): Promise<User[]> {
    const inSpaceNotCallUserIds = Array.from(this.spacePresenceObject[space._id]);
    const usersNotFound = inSpaceNotCallUserIds.filter((userId) => !this.userLookup[userId]);
    if (usersNotFound.length > 0) {
      await this._syncSpace(space._id, usersNotFound);
    }
    const onlineUsers =
      inSpaceNotCallUserIds &&
      inSpaceNotCallUserIds.map((userId) => this.userLookup[userId])?.filter((user) => user);

    return onlineUsers;
  }

  /* Opens spaces snackbar */
  loadSpacesSnackbar(): void {
    this.spacesLoadingSnackbar = this._snackBar.open(
      this.translateService.instant('Loading your Spaces'),
      undefined,
      { panelClass: ['snackbar'], verticalPosition: 'bottom', horizontalPosition: 'center' },
    );
  }

  /* Set openedSessionId and dismiss snackbar after space opened triggered */
  onOpenSpace(event: string): void {
    if (this.spacesLoadingSnackbar) {
      this.spacesLoadingSnackbar.dismiss();
    }
    this.openedSessionId = event;
  }

  onDeleteSpace(spaceId: string): void {
    if (!this.spaces) {
      return;
    }
    // update spaces to remove duplicate spaces loaded via scroll
    this.spaces = this.spaces.filter((spacePreview) => spacePreview._id !== spaceId);
    if (this.breakoutRoomsEnabled) {
      this.presenceInfoBreakoutRoomsId = this.extractCurrentBreakoutRoomIds(this.spaces);
    }
    // update loadedSpaces also to remove the space
    this.loadedSpaces = this.loadedSpaces.filter((spacePreview) => spacePreview._id !== spaceId);
    // to not miss any spaces on the next load, we should use skip once some spaces are deleted
    this.shouldUseSkip = true;
    // display a space when one is removed to maintain ability to scroll and load more spaces
    if (
      this.spaces.length > this.numberOfSpacesPerLoad &&
      this.loadedSpaces.length < this.numberOfSpacesPerLoad
    ) {
      this.loadSpaces(1);
    }

    /**
     * once user deleted space and number of spaces per the page is less than the number to load ( limit per page )
     * we request new spaces
     */
    if (
      this.spaces.length < this.numberOfSpacesPerLoad &&
      this.currentPage < this.totalPages &&
      !this.isFetchingSpaces
    ) {
      this.currentPage++;
      this.getSpaces();
    }
  }

  /* On scroll, when bottom is reached load more spaces */
  onScroll(): void {
    this.isScrolling.next(true);
    if (!this.spaces || this.currentPageView !== SpacesHomePageView.SPACES_LIST_VIEW) {
      return;
    }
    const spacesListElement = this.spacesListElement?.nativeElement;
    const atBottom =
      spacesListElement.scrollHeight -
        spacesListElement.scrollTop -
        spacesListElement.clientHeight <
      10;

    // fetch spaces here from the API, once user scroll to the bottom
    if (atBottom && this.currentPage < this.totalPages && !this.isFetchingSpaces) {
      // increase the current page
      this.currentPage++;
      this.getSpaces();
    }
  }

  resetSearchState() {
    // initialize variables for search
    this.isLoadingSearch = true;
    this.spaces = [];
    this.loadedSpaces = [];
    this.totalPages = 1;
    this.currentPage = 1;
    this.loadedSpacesPageMap.clear();
    this.spacesLoadedMap.clear();
    this._currentlyOpenedSpaceDetails$.next(undefined);
    this.isSpaceDetailsOpen = false;
  }

  /* On search, search for space by value */
  onSearch(searchValue: string): void {
    this.spaceSearchQuery = searchValue.trim() || undefined;

    this.resetSearchState();
    this.getSpaces();
  }

  private extractCurrentBreakoutRoomIds(spaces: Session[] | undefined): PresenceRoomInfo[] {
    if (!spaces) {
      return [];
    }

    const presenceInfos: PresenceRoomInfo[] = [];

    for (const spaceToLoad of spaces) {
      const spaceId = spaceToLoad._id;
      const currentUser = spaceToLoad.users.find((user) => user._id === this.user?._id);

      const hasAdminPermissions = this.spaceRepository.hasSpaceAdminPermissions(
        this.user,
        spaceToLoad,
      );
      let currentBreakoutRoomId;
      // assume the user is in main room if he is not part of the users yet but has admin permissions
      if (currentUser) {
        currentBreakoutRoomId = currentUser.roomId;
      } else if (hasAdminPermissions) {
        currentBreakoutRoomId = Session.getMainRoomId(spaceId);
      } else {
        continue;
      }

      presenceInfos.push(
        this.presenceProvider.constructPresenceInfoObject(spaceId, currentBreakoutRoomId),
      );

      this.currentBreakoutRoomIds[spaceId] = currentBreakoutRoomId;
    }

    return presenceInfos;
  }

  toggleFilterPanel(): void {
    this.filtersPanelOpen = !this.filtersPanelOpen;
  }

  onOpenSpaceDetails(space: Session): void {
    this._currentlyOpenedSpaceDetails$.next(space);
    this.isSpaceDetailsOpen = true;
  }

  onCloseSpaceDetails(): void {
    this.isSpaceDetailsOpen = false;
    modifiedSetTimeout(() => {
      this._currentlyOpenedSpaceDetails$.next(undefined);
    }, 200);
  }

  changeCurrentPageView(newPageView: SpacesHomePageView) {
    if (this.spacesListElement?.nativeElement?.scrollTop > 0) {
      this.spacesListElement.nativeElement.scrollTop = 0;
    }

    this.currentPageView = newPageView;
    this.spacesTemplatesService.currentPageView = newPageView;
    this.spacesTemplatesService.currentPageView$.next(this.currentPageView);
    // check if the new view is all templates view and make sure to select institution templates first
    const isInstitutionTemplatesAvailable = !!this.user?.institution?._id;
    if (
      isInstitutionTemplatesAvailable &&
      this.currentPageView === SpacesHomePageView.ALL_TEMPLATES_VIEW
    ) {
      this.spacesTemplatesService.currentSelectedSpaceTemplatesCategory$.next(
        SpacesTemplatesCategories.INSTITUTION,
      );
    } else {
      this.spacesTemplatesService.currentSelectedSpaceTemplatesCategory$.next(
        SpacesTemplatesCategories.GENERAL,
      );
    }
  }

  removeSpaceTemplateStatus(spaceId: string) {
    this.spaces = this.spaces.map((space) => {
      if (space._id === spaceId) {
        space.isTemplate = false;
      }
      return space;
    });
  }

  /**
   * Syncs space and updated its populated users list
   * This component does not use space repository, hence we are using the syncing directly
   */
  private async _syncSpace(spaceId: string, usersNotFound: Array<string>): Promise<void> {
    await this._getMutex(spaceId).runExclusive(async () => {
      const areUsersNotPopulated = usersNotFound.some((user) => !this.userLookup[user]);
      if (!areUsersNotPopulated) {
        return;
      }
      const space = await firstValueFrom(this.spacesService.getSession(spaceId));
      this.updateSpaceUserLookup(space.session);
    });
  }

  private _getMutex(spaceId: string): Mutex {
    if (!this._syncSessionMutex.has(spaceId)) {
      this._syncSessionMutex.set(spaceId, new Mutex());
    }
    return this._syncSessionMutex.get(spaceId) as Mutex;
  }

  updateSpace(updatedSpace: ISpace & ISpaceUI) {
    const indexInSpaces = this.spaces.findIndex((space) => space._id === updatedSpace._id);
    if (indexInSpaces !== -1) {
      this.spaces[indexInSpaces] = updatedSpace;
    }
    const indexInLoadedSpaces = this.loadedSpaces.findIndex(
      (space) => space._id === updatedSpace._id,
    );
    if (indexInLoadedSpaces !== -1) {
      this.loadedSpaces[indexInLoadedSpaces] = this.spaces[indexInSpaces];
    }
  }
}

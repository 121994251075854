import { Pipe, PipeTransform } from '@angular/core';

import { VISIBILITY, DIFFICULTY, DIFFICULTY_TO_LABEL } from '../models/question';

@Pipe({
  name: 'allInVisibility',
})
export class AllInVisibilityPipe implements PipeTransform {
  // Check if all items have same visibility
  transform(containers: any, visibility: VISIBILITY): boolean {
    if (!containers) {
      return false;
    }
    const checkedContainers = containers.filter(c => c.checked);
    return checkedContainers.every((container) =>
      container.item.acl && container.item.acl.visibility === visibility
    );
  }
}

@Pipe({
  name: 'selectedInDifficulty'
})
export class SelectedInDifficultyPipe implements PipeTransform {
  transform(containers: any, difficulty: DIFFICULTY): 'all' | 'partial' | 'none' {
    if (!containers) {
      return 'none';
    }
    // Check if all/partial/none of items have same difficulty
    const checkedContainers = containers.filter(c => c.checked);
    const every = checkedContainers.every((container) =>
      container.item.metadata && DIFFICULTY_TO_LABEL[container.item.metadata.difficulty] === difficulty
    );
    if (every) {
      return 'all';
    }

    const some = checkedContainers.some((container) =>
      container.item.metadata && DIFFICULTY_TO_LABEL[container.item.metadata.difficulty] === difficulty
    );
    if (some) {
      return 'partial';
    }

    return 'none';
  }
}

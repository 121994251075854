import { NgZone, Renderer2 } from '@angular/core';

interface Listener {
  target: any;
  event: string;
  handler: (event: any) => boolean | void;
  unListenToEvent: () => void;
  isOutSideAngularZone: boolean;
  isPaused: boolean;
}
export class DomListener {
  renderer2Listeners: Listener[] = [];

  constructor(private renderer2: Renderer2, private zone: NgZone) {}

  add(target: any, event: string, handler: (event: any) => boolean | void, runOutsideZone = false) {
    if (!runOutsideZone) {
      this.startListeningToEvent(target, event, handler, false, false);
      return;
    }
    this.startListeningToEvent(target, event, handler, true, false);
  }

  remove(target: any, event: string) {
    const queriedRenderer2Listener = this.getRenderer2Listener(target, event);
    if (queriedRenderer2Listener) {
      queriedRenderer2Listener.unListenToEvent();
      // remove listener from list
      this.renderer2Listeners = this.renderer2Listeners.filter(
        (obj) => obj !== queriedRenderer2Listener,
      );
    }
  }

  pauseListeningToEvent(target: any, event: string) {
    const queriedRenderer2Listener = this.getRenderer2Listener(target, event);
    if (queriedRenderer2Listener) {
      queriedRenderer2Listener.unListenToEvent();
      queriedRenderer2Listener.isPaused = true;
    }
  }

  canResumeListeningToEvent(target: any, event: string): boolean {
    return !!this.getRenderer2Listener(target, event);
  }

  resumeListeningToEvent(
    target: any,
    event: string,
    handler?: (event: any) => boolean | void,
    runOutsideZone?: boolean,
  ) {
    const queriedRenderer2Listener = this.getRenderer2Listener(target, event);
    if (queriedRenderer2Listener && queriedRenderer2Listener.isPaused) {
      this.startListeningToEvent(
        queriedRenderer2Listener.target,
        queriedRenderer2Listener.event,
        queriedRenderer2Listener.handler,
        queriedRenderer2Listener.isOutSideAngularZone,
        true,
      );
    }
    // create listener if it doesn't exist
    else if (!queriedRenderer2Listener && handler) {
      this.startListeningToEvent(target, event, handler, runOutsideZone ?? false, false);
    }
  }

  pauseAllListeners() {
    this.renderer2Listeners.forEach((listener) => {
      this.pauseListeningToEvent(listener.target, listener.event);
    });
  }

  resumeAllListeners() {
    this.renderer2Listeners.forEach((listener) => {
      if (listener.isPaused) {
        this.startListeningToEvent(
          listener.target,
          listener.event,
          listener.handler,
          listener.isOutSideAngularZone,
          true,
        );
      }
    });
  }

  clear() {
    this.renderer2Listeners.forEach((listener) => {
      listener.unListenToEvent();
    });
    this.renderer2Listeners = [];
  }

  listenersCount(): number {
    return this.renderer2Listeners.length;
  }

  private startListeningToEvent(
    target: any,
    event: string,
    handler: (event: any) => boolean | void,
    isOutSideAngularZone: boolean,
    isResume: boolean,
  ) {
    let unListenToEvent!: () => void;
    if (isOutSideAngularZone) {
      this.zone.runOutsideAngular(() => {
        unListenToEvent = this.renderer2.listen(target, event, handler);
      });
    } else {
      unListenToEvent = this.renderer2.listen(target, event, handler);
    }

    if (isResume) {
      const queriedRenderer2Listener = this.getRenderer2Listener(target, event);
      if (queriedRenderer2Listener) {
        queriedRenderer2Listener.unListenToEvent = unListenToEvent;
        queriedRenderer2Listener.isPaused = false;
      }
    } else {
      this.renderer2Listeners.push({
        target,
        event,
        handler,
        unListenToEvent,
        isOutSideAngularZone,
        isPaused: false,
      });
    }
  }

  private getRenderer2Listener(target: any, event: string): Listener | undefined {
    return this.renderer2Listeners.find(
      (listener) => listener.target === target && listener.event === event,
    );
  }
}

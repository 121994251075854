import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
})
export class NumberInputComponent {
  @Input() min = 0;
  @Input() max?: number;
  @Input() value = 1;
  @Input() step = 1;
  @Output() update: EventEmitter<any> = new EventEmitter();

  onChange(event: Event): void {
    this.update.emit((event.target as HTMLInputElement).value);
  }

  onClickArrows(val: number): void {
    if (val < this.min) {
      this.update.emit(this.min);
      return;
    }

    if (this.max && val > this.max) {
      this.update.emit(this.max);
      return;
    }

    this.update.emit(val);
  }
}

<header class="modal-header">
  <h2>{{ (data?.key.value ? 'Edit ' : 'Add ') + 'Attribute' | translate }}</h2>
</header>
<form [formGroup]="attributeForm" class="modal-body">
  <div class="edit-option">
    <label class="subtitle" for="name">{{ 'Name' | translate }}</label>
    <input
      class="name-input"
      type="text"
      id="name"
      [placeholder]="'e.g. Literacy' | translate"
      formControlName="key"
      [class.invalid-input]="
        attributeForm.controls.key.invalid && attributeForm.controls.key.touched
      "
      autocomplete="off"
    />
    <p class="error-text">
      <span
        *ngIf="attributeForm.controls.key.errors?.required && attributeForm.controls.key.touched"
      >
        {{ 'This field is required.' | translate }}
      </span>
      <span *ngIf="attributeForm.controls.key.errors?.whitespace">
        {{ 'This field cannot contain only whitespace.' | translate }}
      </span>
    </p>
  </div>

  <div class="edit-option">
    <label class="subtitle" for="entities">{{ 'Entities' | translate }}</label>
    <div class="entities">
      <app-pencil-pill
        class="entity"
        [text]="'Space' | translate"
        [isRemovable]="false"
      ></app-pencil-pill>
      <app-pencil-pill
        class="entity"
        [text]="'Event' | translate"
        [isRemovable]="false"
      ></app-pencil-pill>
      <app-pencil-pill
        class="entity"
        [text]="'User' | translate"
        [isRemovable]="false"
      ></app-pencil-pill>
    </div>
  </div>

  <div class="edit-option">
    <label class="subtitle" for="values">{{ 'Values' | translate }}</label>
    <div class="values" formArrayName="values">
      <div
        class="input-container"
        *ngFor="let value of values.controls; let i = index"
        [formGroupName]="i"
      >
        <input
          matInput
          #formField
          type="text"
          class="value-input"
          placeholder="e.g. Beginner"
          id="value-{{ i }}"
          [errorStateMatcher]="duplicateValuesMatcher"
          [value]="value.value.value"
          [attr.data-index]="i"
          formControlName="value"
          [class.invalid-input]="
            (value.controls.value.invalid || value.hasError('duplicateValues')) &&
            value.controls.value.touched
          "
          (paste)="onPaste($event, i)"
          autocomplete="off"
        />
        <!-- TODO: Add this icon when delete is implemented -->
        <app-pencil-icon
          class="delete-custom-attr"
          [name]="'close'"
          [type]="IconTypes.materialIcon"
          [outlined]="true"
          *ngIf="false"
        ></app-pencil-icon>
        <p class="error-text">
          <ng-container>
            <span *ngIf="value.controls.value.errors?.required && value.controls.value.touched">
              {{ 'This field is required.' | translate }}
            </span>
            <span *ngIf="value.controls.value.errors?.whitespace">
              {{ 'This field cannot contain only whitespace.' | translate }}
            </span>
            <span *ngIf="value.hasError('duplicateValues')">
              {{ 'Values should be unique.' | translate }}
            </span>
          </ng-container>
        </p>
      </div>
      <app-pencil-button
        class="add-option-btn"
        [label]="'Add an option' | translate"
        [icon]="'add'"
        [iconConfig]="{ type: IconTypes.matIcon }"
        (clickEvent)="addAttributeToValuesArray()"
        buttonType="button"
      ></app-pencil-button>
    </div>
  </div>
</form>
<footer class="modal-footer">
  <app-pencil-button
    class="pencil-button medium light"
    [label]="'Cancel' | translate"
    [icon]="'close'"
    [iconConfig]="{ type: IconTypes.matIcon }"
    (clickEvent)="onCancel()"
    buttonType="button"
  ></app-pencil-button>
  <app-pencil-button
    class="pencil-button medium primary"
    [label]="'Update' | translate"
    [icon]="'check'"
    [iconConfig]="{ type: IconTypes.matIcon }"
    buttonType="submit"
    [disabled]="updateButtonDisabled$ | async"
    (clickEvent)="onSubmit()"
  ></app-pencil-button>
</footer>

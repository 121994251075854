import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-end-ongoing-session',
  templateUrl: './end-ongoing-session.component.html',
  styleUrls: ['./end-ongoing-session.component.scss'],
})
export class EndOngoingSessionComponent {
  constructor(private dialogRef: MatDialogRef<EndOngoingSessionComponent>) {}

  finish(result: boolean): void {
    this.dialogRef.close(result);
  }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UiService } from 'src/app/services/ui.service';

@UntilDestroy()
@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialShareComponent {
  @Input() url = '';

  isMobile = false;

  constructor(private uiService: UiService) {
    this.uiService.isMobile.pipe(untilDestroyed(this)).subscribe((res) => (this.isMobile = res));
  }
}

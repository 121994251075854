<div class="menu-wrapper" (click)="$event.stopPropagation()">
  <div class="menu-title" *ngIf="title">{{ title | translate }}</div>
  <div class="menu-items">
    <div>
      <mat-checkbox
        color="primary"
        [checked]="(containers | selectedInDifficulty: DIFFICULTY.BASIC) === 'all'"
        [indeterminate]="(containers | selectedInDifficulty: DIFFICULTY.BASIC) === 'partial'"
        [disabled]="disabled"
        (change)="handleUpdate.emit([DIFFICULTY.BASIC, $event])"
      >
        {{ 'Basic' | translate }}
      </mat-checkbox>
    </div>
    <div>
      <mat-checkbox
        color="primary"
        [checked]="(containers | selectedInDifficulty: DIFFICULTY.AVERAGE) === 'all'"
        [indeterminate]="(containers | selectedInDifficulty: DIFFICULTY.AVERAGE) === 'partial'"
        [disabled]="disabled"
        (change)="handleUpdate.emit([DIFFICULTY.AVERAGE, $event])"
      >
        {{ 'Average' | translate }}
      </mat-checkbox>
    </div>
    <div>
      <mat-checkbox
        color="primary"
        [checked]="(containers | selectedInDifficulty: DIFFICULTY.ADVANCED) === 'all'"
        [indeterminate]="(containers | selectedInDifficulty: DIFFICULTY.ADVANCED) === 'partial'"
        [disabled]="disabled"
        (change)="handleUpdate.emit([DIFFICULTY.ADVANCED, $event])"
      >
        {{ 'Advanced' | translate }}
      </mat-checkbox>
    </div>
    <div>
      <mat-checkbox
        color="primary"
        [checked]="(containers | selectedInDifficulty: DIFFICULTY.CHALLENGE) === 'all'"
        [indeterminate]="(containers | selectedInDifficulty: DIFFICULTY.CHALLENGE) === 'partial'"
        [disabled]="disabled"
        (change)="handleUpdate.emit([DIFFICULTY.CHALLENGE, $event])"
      >
        {{ 'Challenge' | translate }}
      </mat-checkbox>
    </div>
  </div>
</div>

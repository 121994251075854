import { Injectable } from '@angular/core';
import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import { ZoomUtil } from '../common/utils/zoom.util';
import {
  customCanvsItemsFields,
  customFabricFields,
} from '../common/interfaces/sync-service-interface';
import { BaseCustomFabric } from '../sessions/session/custom-fabric-objects/base-custom-fabric';
import { SessionSharedDataService } from '../services/session-shared-data.service';

@Injectable()
export class ObjectDuplicationUtilsService {
  constructor(private zoomUtil: ZoomUtil, private sharedDataService: SessionSharedDataService) {}

  /**
   * get the location where the new object (duplicated object) should be placed into
   * @param copiedObject
   */
  getPasteLocation(copiedObject: fabric.Object, canvas?: fabric.Canvas): { x: number; y: number } {
    if (this.sharedDataService.lastMouseLocation?.pointer && copiedObject.type === 'textbox') {
      return {
        x: this.sharedDataService.lastMouseLocation?.pointer.x,
        y: this.sharedDataService.lastMouseLocation?.pointer.y,
      };
    }

    if (!copiedObject.left || !copiedObject.top || !copiedObject.width || !copiedObject.height) {
      return this.zoomUtil.getCenterCoord();
    }

    // for large objects such as PDFs, isPartiallyOnScreen will always evaluated to truthy
    // solution is to check also if the canvas can fit the object
    if (
      copiedObject.isOnScreen() &&
      (!copiedObject.isPartiallyOnScreen() ||
        !this.canCanvasFitObject(canvas as fabric.Canvas, copiedObject))
    ) {
      return {
        x: copiedObject.left + 40 + copiedObject.width / 2,
        y: copiedObject.top + 40 + copiedObject.height / 2,
      };
    } else {
      return this.zoomUtil.getCenterCoord();
    }
  }

  /**
   * check if the canvas at the current state (considering zoom level) can fit a given object
   */
  canCanvasFitObject(canvas: fabric.Canvas, object: fabric.Object): boolean {
    const objHeight = object.getScaledHeight();
    const objWidth = object.getScaledWidth();
    const zoomLevel = canvas.getZoom();
    const canvasHeight = canvas.getHeight() / zoomLevel;
    const canvasWidth = canvas.getHeight() / zoomLevel;

    return canvasHeight >= objHeight && canvasWidth >= objWidth;
  }

  /**
   * map the copied items to an object with specific fields
   * @param transformedActiveObjects
   */
  getCopiedObjectsWithCustomFields(
    transformedActiveObjects: fabric.Object[],
  ): { [key: string]: any }[] {
    const customFields = customFabricFields.concat(customCanvsItemsFields);
    return transformedActiveObjects.map((x) => x.toObject(customFields));
  }

  getNewDuplicatedObjectsWithNewPositions(
    copiedObjects: { [key: string]: any }[],
    pasteLocation: { x: number; y: number },
    selectionCenter: { x: number; y: number },
  ): { [key: string]: any }[] {
    return copiedObjects.map((obj) =>
      this.placeAndResetObject(obj, pasteLocation, selectionCenter),
    );
  }
  private placeAndResetObject(
    obj: { [key: string]: any },
    pasteLocation: { x: number; y: number } | null,
    selectionCenter: { x: number; y: number },
  ): { [key: string]: any } {
    obj.uid = uuidv4();
    delete obj.fresh; // delete `fresh` so this appears as a new object added to the canvas

    if (!pasteLocation) {
      return obj;
    }

    if (BaseCustomFabric.isCustomFabricObject(obj)) {
      return BaseCustomFabric.updateObjectPosition(
        obj as fabric.Object,
        pasteLocation,
        selectionCenter,
      );
    }

    const diffToCenter = {
      x: obj.left - selectionCenter.x,
      y: obj.top - selectionCenter.y,
    };
    obj.left = (pasteLocation?.x || 0) + diffToCenter.x;
    obj.top = (pasteLocation?.y || 0) + diffToCenter.y;

    return obj;
  }
}

import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroupDirective,
  NgForm,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

// REF: https://www.rfc-editor.org/errata_search.php?rfc=3696
const EMAIL_INPUT_LENGTH_THRESHOLD = 256;

function noWhitespaceValidator(control: UntypedFormControl): ValidationErrors {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? {} : { whitespace: true };
}

function uniqueValuesValidator(control: AbstractControl): ValidationErrors | null {
  const formArray = control as FormArray;
  const formValues = formArray.controls.map((controller) => controller.get('value')?.value);

  const valueOccurrences = formValues.reduce((occurrences, value) => {
    occurrences[value] = (occurrences[value] || 0) + 1;
    return occurrences;
  }, {} as Record<string, number>);

  let hasDuplicates = false;

  formArray.controls.forEach((ctrl, index) => {
    hasDuplicates = hasDuplicates || valueOccurrences[ctrl.get('value')?.value] > 1;
    ctrl.setErrors(
      valueOccurrences[ctrl.get('value')?.value] > 1 ? { duplicateValues: true } : null,
    );
  });

  return hasDuplicates ? { duplicateValues: true } : null;
}

function atLeastOneValueValidator(control: AbstractControl): ValidationErrors | null {
  const formArray = control as FormArray;
  return formArray.length > 0 ? null : { noValues: true };
}

/*
 * Firebase only accepts emails that have a top domain, which is not required by
 * the regular email validator.
 * Regex generated using information from https://www.regular-expressions.info/email.html
 */
function firebaseEmailValidator(control: UntypedFormControl): ValidationErrors | null {
  const testEmail = control.value;
  return getEmailValidationErrors(testEmail);
}

function getEmailValidationErrors(email: string): ValidationErrors | null {
  if (email.length >= EMAIL_INPUT_LENGTH_THRESHOLD) {
    return { email: true };
  }

  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,125}[a-zA-Z]{2,63}$/;
  try {
    if (email.match(emailRegex)) {
      return null;
    } else {
      return { email: true };
    }
  } catch (err) {
    return { email: true };
  }
}

function isValidFirebaseEmail(email: string): boolean {
  return getEmailValidationErrors(email) === null;
}

class DuplicateValuesErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, _form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.parent && control.invalid && control.parent.dirty);
  }
}

export {
  noWhitespaceValidator,
  uniqueValuesValidator,
  atLeastOneValueValidator,
  firebaseEmailValidator,
  getEmailValidationErrors,
  isValidFirebaseEmail,
  DuplicateValuesErrorStateMatcher,
};

import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs';
import { User } from './models/user';
import { NavService } from './services/nav.service';
import { Feature, AclService } from './services/acl.service';
import { FLAGS, FlagsService } from './services/flags.service';
import { UserService } from './services/user.service';
import { LANDING_URL, URL_CONSTANTS } from './common/utils/url';
import { AuthService } from './services/auth.service';
import { AccountEntryService } from './account/account-entry/account-entry.service';

@UntilDestroy()
@Injectable()
export class UserActivate implements CanActivate {
  user?: User;
  Features = Feature;

  constructor(
    private router: Router,
    private navService: NavService,
    private userService: UserService,
    public aclService: AclService,
    private flagsService: FlagsService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private accountEntryService: AccountEntryService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.userService.appLoading.next(false);
    // Only allow public question view for non-signed in users.
    if (state.url.includes('content/view/') || state.url.includes('techcheck')) {
      return true;
    }

    this.user = this.userService.user.value?.user || (await this.navService.loading);

    if (!this.user) {
      return false;
    }

    // Check guest (institution-less) users for self serve.
    if (this.user && this.aclService.isGuest(this.user)) {
      // if path != account
      // redirect verifed only email/password self serve users to /space/new
      // else show verify page
      if (!state.url.includes('account') || state.url.includes('user_info')) {
        let result = true;
        this.authService.firebaseUser
          .pipe(untilDestroyed(this))
          .pipe(first())
          .subscribe((user) => {
            // block accounts with only one provider: email-password from accessing spaces until verified
            // otherwise this would mark facebook and possibly other providers as unverified
            if (this.accountEntryService.isOnlyPasswordProviderAndEmailUnverified(user)) {
              result = false;
              if (!state.url.includes('login')) {
                this.router.navigate([URL_CONSTANTS.LOGIN], {
                  queryParams: {
                    continueUrl: state.url,
                  },
                  queryParamsHandling: 'merge',
                  fragment: this.activatedRoute.snapshot.fragment || '',
                });
              }
              this.accountEntryService.goToVerificationPage(user?.email);
            }
            return result;
          });
      }
    }

    // Enable analytics only for parent users
    if (this.user && this.aclService.isParent(this.user)) {
      if (
        (state.url.includes('/content/course') || state.url.includes('/content/worksheets')) &&
        !state.url.includes('analytics')
      ) {
        const { id } = route.params;
        if (id) {
          this.router.navigate([`/content/course/questions/${id}/analytics`]);
        } else {
          this.router.navigate(['/content']);
        }
        return false;
      }
    }

    if (state.url.includes('content/create')) {
      if (
        state.url.includes('content/create/0/') &&
        this.aclService.isAllowed(this.user, this.Features.create_question)
      ) {
        return true;
      } else if (this.aclService.isAllowed(this.user, this.Features.edit_question)) {
        return true;
      }
    } else if (state.url.includes('content/notes/create')) {
      if (
        state.url.includes('/content/create/notes/0/') &&
        this.aclService.isAllowed(this.user, this.Features.create_note)
      ) {
        return true;
      } else if (this.aclService.isAllowed(this.user, this.Features.edit_note)) {
        return true;
      }
    } else if (state.url.includes('admin')) {
      if (this.aclService.isAllowed(this.user, this.Features.AdminPage)) {
        return true;
      }
    } else if (state.url.includes('practice')) {
      if (state.url.includes('summary')) {
        if (
          this.aclService.isAllowed(this.user, this.Features.Practice) ||
          this.aclService.isAllowed(this.user, this.Features.ContentAnalytics) ||
          this.aclService.isAllowed(this.user, this.Features.PracticeAnalytics)
        ) {
          return true;
        }
      }
      if (this.aclService.isAllowed(this.user, this.Features.Practice)) {
        return true;
      }
    } else if (state.url.includes('profiles')) {
      if (this.aclService.isAllowed(this.user, this.Features.ProfilesPage)) {
        return true;
      }
    } else if (state.url.startsWith('/content/files')) {
      if (this.aclService.isAllowed(this.user, this.Features.view_resource)) {
        return true;
      }
    } else if (state.url.includes('worksheets')) {
      if (this.aclService.isAllowed(this.user, this.Features.view_set)) {
        return true;
      }
    } else if (state.url.includes('/course/settings')) {
      if (this.aclService.isAllowed(this.user, this.Features.edit_course)) {
        return true;
      }
    } else if (state.url.includes('/content/course/new')) {
      if (this.aclService.isAllowed(this.user, this.Features.create_course)) {
        return true;
      }
    } else if (state.url.startsWith('/schedule')) {
      if (
        this.aclService.isAllowed(this.user, this.Features.view_schedule_page) &&
        this.flagsService.isFlagEnabled(FLAGS.ENABLE_SCHEDULE)
      ) {
        return true;
      }
    } else if (state.url.startsWith('/messages')) {
      if (this.aclService.isAllowed(this.user, this.Features.chat)) {
        return true;
      }
    } else if (
      state.url.startsWith('/settings') ||
      state.url.startsWith('/notifications') ||
      state.url.startsWith('/content') ||
      state.url.startsWith('/users') ||
      state.url.startsWith('/pencilcam') ||
      state.url.startsWith('/recording') ||
      state.url.startsWith(`/${URL_CONSTANTS.SPACES}`)
    ) {
      return true;
    } else if (
      state.url.startsWith('/analytics') &&
      this.aclService.isAdminOrSiteAdmin(this.user) &&
      this.flagsService.isFlagEnabled(FLAGS.PROJECT_SAM)
    ) {
      return (
        (!!this.flagsService.featureFlagsVariables.project_sam.attendance_report &&
          state.url.includes('/attendance')) ||
        (state.url.includes('/engagement') &&
          !!this.flagsService.featureFlagsVariables.project_sam.analytics_report)
      );
    }

    if ((this.user && this.aclService.isGuest(this.user)) || this.router.url === '/') {
      this.router.navigate([`/${LANDING_URL}`]);
    } else {
      return true;
    }

    return false;
  }
}

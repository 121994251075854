import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class NetworkCacheService {
  // These keys are related to the cache config set in ngsw-config.json and may need to be
  // adjusted if the config values are changed.
  ALL_ITEMS_REGEX = /.*/;
  private allDataCachesRegex = /^(ngsw).*data.*api-performance.*$/i; // e.g. 'ngsw:/:1:data:api-performance:*';
  private dataCacheRegex = /^(ngsw).*data.*api-performance.*cache.*$/i; // e.g. 'ngsw:/:1:data:api-performance:cache';

  async clearAllCaches() {
    await this.clearCachesByNamePattern(this.allDataCachesRegex, this.ALL_ITEMS_REGEX);
  }

  async clearDataCacheForUrl(urlRegex: RegExp) {
    await this.clearCachesByNamePattern(this.dataCacheRegex, urlRegex);
  }

  async clearSpaceCache(spaceId: string) {
    const regexStr = `^.*\\/spaces\\/${spaceId}\\/$`; // match 'http(s)://{serverurl}/spaces/{spaceId}/'
    await this.clearDataCacheForUrl(new RegExp(regexStr, 'i'));
  }

  async clearSpacesListCache() {
    const regexStr = '^.*\\/spaces\\?filters=.*$'; // match 'http(s)://{serverurl}/spacess?filters=xxx&pageNumber=yyy&...'
    await this.clearDataCacheForUrl(new RegExp(regexStr, 'i'));
  }

  async clearUserCache() {
    const regexStr = '^.*\\/tutor$'; // match 'http(s)://{serverurl}/tutor'
    await this.clearDataCacheForUrl(new RegExp(regexStr, 'i'));
  }

  async clearUsersCache() {
    const regexStr = '^.*\\/tutor\\/tutors$'; // match 'http(s)://{serverurl}/tutor/tutors'
    await this.clearDataCacheForUrl(new RegExp(regexStr, 'i'));
  }

  async clearFilteredUsersCache() {
    const regexStr = '^.*\\/tutor\\/institution\\/users\\?filters=.*$'; // match 'http(s)://{serverurl}/tutor/institution/users?filters=...'
    await this.clearDataCacheForUrl(new RegExp(regexStr, 'i'));
  }

  /**
   * Delete all keys matching urlRegex across all caches matching cacheNameRegex
   */
  private async clearCachesByNamePattern(cacheNameRegex: RegExp, urlRegex: RegExp) {
    const cacheNames = await caches.keys();
    const matchedCaches = cacheNames.filter((cacheName) => cacheNameRegex.test(cacheName));
    await Promise.all(
      matchedCaches.map((cacheName) => this.clearCacheItemsByUrlPattern(cacheName, urlRegex)),
    );
  }

  /**
   * Delete keys matching urlRegex inside a cache with the given cacheName
   */
  private async clearCacheItemsByUrlPattern(cacheName: string, urlRegex: RegExp) {
    const c = await caches.open(cacheName);
    const keys = await c.keys();
    const matchedKeys = keys.filter((p) => urlRegex.test(p.url));
    await Promise.all(
      matchedKeys.map(async (keySearched) => {
        const res = await c.delete(keySearched.url);
        this.logDelete(res, keySearched.url);
      }),
    );
  }

  private logDelete(result: boolean, cache: string) {
    if (!environment.production) {
      console.log(`Deleting cache item for ${cache} =>`, result ? 'Success' : 'Fail');
    }
  }
}

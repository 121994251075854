import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, combineLatest, map, startWith, take } from 'rxjs';
import { ContentMessage, TypedMessage } from 'src/app/models/messaging';
import { Fragment } from 'src/app/models/question';
import { Session } from 'src/app/models/session';
import { Message } from 'src/app/models/user';
import { DateFormatEnum } from 'src/app/pipes/dateLocale.pipe';
import { MessengerService } from 'src/app/services/messenger.service';
import { UserService } from 'src/app/services/user.service';
import { SpaceRepository } from 'src/app/state/space.repository';
import { modifiedSetTimeout } from 'src/app/utilities/ZoneUtils';

@UntilDestroy()
@Component({
  selector: 'message-content-wrapper',
  templateUrl: './message-content-wrapper.component.html',
  styleUrls: ['./message-content-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageContentWrapperComponent implements OnInit, OnChanges {
  @Input() public sender = false;
  @Input() public hideReply = false;
  @Input() public enableEditing = false;
  @Input() public isPendingMessage = false;
  @Input() public message!: ContentMessage;
  @Input() public sourceLength = 1;
  @Input() public index = 0;
  @Input() public pendingMessageSourceLength = 1;
  @Input() public pendingMessageIndex = 0;
  @Input() public messageGroups!: TypedMessage[];
  @Output() private editMessage = new EventEmitter<ContentMessage>();
  @Output() private replyMessage = new EventEmitter<ContentMessage>();
  @Output() private deleteMessage = new EventEmitter<ContentMessage>();

  public isHost: Observable<boolean | undefined>;
  public DateFormatEnum = DateFormatEnum;

  public parentImageMessage: { image: string; text?: string } | null = null;

  public maxPreviewLength = 60;
  public tooltipPosition = 'top';
  public tooltipPositionBelow = 'bottom';
  public parentMessageSubject = new BehaviorSubject<Message | undefined>(undefined);
  public parentMessage$: Observable<Message | undefined>;
  public currentUserId = '';

  constructor(
    private userService: UserService,
    public spaceRepo: SpaceRepository,
    private changeDetectorRef: ChangeDetectorRef,
    private messengerService: MessengerService,
  ) {
    this.isHost = combineLatest([this.spaceRepo.activeSpace$, this.userService.user]).pipe(
      untilDestroyed(this),
      map(([activeSpace, res]) => {
        if (res) {
          return Session.isOwnedByUser(activeSpace, res.user);
        }
      }),
    );

    this.parentMessage$ = combineLatest([
      this.messengerService.deletedSpaceLevelMessageId$.pipe(startWith('')),
      this.parentMessageSubject,
    ]).pipe(
      untilDestroyed(this),
      map(([deletedMessageId, parentMessage]) => {
        if (
          (deletedMessageId && deletedMessageId === parentMessage?._id) ||
          parentMessage?.deleted ||
          parentMessage?.deletedByHost
        ) {
          return undefined;
        } else {
          return parentMessage;
        }
      }),
    );
  }

  ngOnChanges(): void {
    this.setReplyMessages();
  }

  ngOnInit(): void {
    this.setReplyMessages();
  }

  public setReplyMessages() {
    if (this.userService.userId) {
      this.currentUserId = this.userService.userId;
    }

    if (this.message.parent) {
      let parentMessage: Message | null = null;
      if (this.messageGroups) {
        for (const message of this.messageGroups) {
          if (this.message.parent === message._id) {
            parentMessage = message;
            this.setImageMessage(message);
            this.parentMessageSubject.next(message);
            this.changeDetectorRef.detectChanges();
          }
        }
      }

      if (!parentMessage) {
        this.fetchReplyMessage(this.message.parent, parentMessage);
      }
    }
  }

  public fetchReplyMessage(id: string, parentMessage: Message | null) {
    this.userService
      .getComment(id)
      .pipe(take(1))
      .subscribe({
        next: (msg) => {
          this.setImageMessage(msg);
          this.parentMessageSubject.next(msg);
          this.changeDetectorRef.detectChanges();
        },
        complete: () => {
          if (!parentMessage) {
            this.parentMessageSubject.next(undefined);
            this.changeDetectorRef.detectChanges();
          }
        },
      });
  }

  public scrollToReply(message: string): void {
    const wrapperElement = document.getElementById(message);
    if (wrapperElement) {
      wrapperElement.style.opacity = '0.5';
      wrapperElement.style.transition = 'unset';
      wrapperElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      wrapperElement.style.transition = 'opacity  1.5s linear';
      wrapperElement.style.opacity = '1';
    }

    const chatElement = document.getElementById(`chat${message}`);
    if (chatElement) {
      chatElement.style.border = '2px solid #4F4F4F';
      chatElement.style.padding = '1px';
      modifiedSetTimeout(() => {
        chatElement.style.borderWidth = '0px';
        chatElement.style.padding = '0px';
      }, 2000);
    }
  }

  public emitEditMessage(message: ContentMessage): void {
    this.editMessage.emit(message);
  }
  public emitReplyMessage(message: ContentMessage): void {
    this.replyMessage.emit({ ...message });
  }
  public emitDeleteMessage(message: ContentMessage): void {
    this.deleteMessage.emit(message);
  }

  public setImageMessage(msg: Message) {
    const image = this.findChatType(msg?.content, 'IMAGE')?.data;
    if (image) {
      this.parentImageMessage = {
        image: image,
        text: this.findChatType(msg?.content, 'TEXT')?.data || '',
      };
    }
  }

  public findChatType(content: Fragment[], type: string) {
    return content.find((item) => item.type === type);
  }
}

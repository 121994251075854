<div class="border-bottom title-container mainText2">
  <p class="mainText1 m0">{{ 'New message' | translate }}</p>
</div>

<div class="d-flex justify-content-between border-bottom filter-container">
  <app-search-bar
    [searchTitle]="'Search People'"
    [searchContinuously]="true"
    (filter)="executeSearch($event)"
  >
  </app-search-bar>
  <i
    *ngIf="groupMessagingEnabled"
    class="material-icons cursor-pointer group-toggle"
    [class.selected]="groupSelection"
    (click)="toggleGroupSelection()"
    >group_add</i
  >
</div>

<div class="selection-container">
  <mat-chip-list *ngIf="groupSelection" class="group-list border-bottom">
    <div class="group-label">{{ 'Group:' | translate }}</div>
    <mat-chip class="user-chip" *ngFor="let user of selectedGroupUsers">
      {{ user.name }}
    </mat-chip>
  </mat-chip-list>

  <div class="preview-container">
    <div
      *ngFor="let userInfo of filteredUserInfo$ | async"
      class="user-container border-bottom"
      [class.selected]="selectedUser?._id === userInfo._id || checkedUsers[userInfo._id]"
      (click)="toggleUserSelection(userInfo)"
    >
      <mat-checkbox
        *ngIf="groupSelection"
        [(ngModel)]="checkedUsers[userInfo._id]"
        (click)="$event.stopPropagation()"
        (change)="checkedUsersChange(userInfo, $event)"
      ></mat-checkbox>
      <app-profile-photo
        [user]="userInfo"
        [fontSize]="18"
        [width]="38"
        [opaqueOverlay]="selectedUser?._id === userInfo._id || checkedUsers[userInfo._id]"
      ></app-profile-photo>
      <div>
        <p class="mainText1 name">{{ userInfo.name }}</p>
        <p class="mainText5 m-0 summary">{{ userInfo | userSummary }}</p>
      </div>
    </div>
  </div>
</div>

<div class="d-flex border-top button-container">
  <button mat-flat-button mat-dialog-close class="cancel-button">
    <i class="material-icons">close</i>
    <span class="text-capitalize">{{ 'cancel' | translate }}</span>
  </button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="!selectedUser && selectedGroupUsers.length === 0"
    (click)="onComplete()"
    class="pcl-new-message-chat"
  >
    <i class="material-icons-outlined">send</i>
    <span class="text-capitalize">{{ 'chat' | translate }}</span>
  </button>
</div>

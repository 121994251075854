<mat-form-field class="custom-form-field" appearance="outline">
  <mat-select
    [value]="selectedRoom$ | async"
    (valueChange)="selectedRoom.emit($event)"
    [disableRipple]="true"
    [placeholder]="'All Rooms'"
    [disableOptionCentering]="true"
    panelClass="custom-mat-select-analytics"
  >
    <mat-option [value]="SpaceDataLevel.SPACE">
      <div class="dropdown">
        <span>All Rooms</span>
        <svg-icon
          *ngIf="(selectedRoom$ | async) === SpaceDataLevel.SPACE"
          [icon]="'tick_blue'"
          [width]="18"
          [height]="18"
        ></svg-icon>
      </div>
    </mat-option>
    <ng-template #roomSelection let-rooms="rooms" let-label="label">
      <mat-optgroup *ngIf="rooms.length > 0" label="{{ label }}">
        <ng-container *ngFor="let room of rooms">
          <mat-option [value]="room.roomUid">
            <div class="dropdown">
              <span>{{ room.roomName }}</span>
              <svg-icon
                *ngIf="(selectedRoom$ | async) === room.roomUid"
                [icon]="'tick_blue'"
                [width]="18"
                [height]="18"
              ></svg-icon>
            </div>
          </mat-option>
        </ng-container>
      </mat-optgroup>
    </ng-template>
    <ng-container
      *ngTemplateOutlet="roomSelection; context: { rooms: activeRooms, label: 'Active Rooms' }"
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="roomSelection; context: { rooms: archivedRooms, label: 'Archived Rooms' }"
    >
    </ng-container>
    <div class="dropdown-footer" *ngIf="activeRooms.length > 1 || archivedRooms.length > 0">
      {{ 'Only showing rooms with activity in this session' | translate }}
    </div>
  </mat-select>
</mat-form-field>

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Feature } from 'src/app/services/acl.service';
import { AuthService } from 'src/app/services/auth.service';
import { FLAGS, FlagsService } from 'src/app/services/flags.service';
import { QuestionsService } from 'src/app/services/questions.service';
import { LangCode } from 'src/app/sessions/session/wb-top-controls/wb-top-controls.component';
import { IconTypes } from 'src/app/standalones/components/pencil-icon/pencil-icon.component';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  Course,
  IndividualUserOverview,
  Institution,
  OnlineStatus,
  UserRole,
} from '../../models/user';
import { SettingsService } from '../../services/settings.service';
import { UiService } from '../../services/ui.service';
import { UserService } from '../../services/user.service';

@UntilDestroy()
@Component({
  selector: 'app-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePhotoComponent implements OnInit {
  @Input() user: IndividualUserOverview | undefined;
  @Input() width?: number;
  @Input() fontSize?: number;
  @Input() fontWeight?: number;
  @Input() fontColor?: string;
  @Input() showStatus?: boolean;
  @Input() userStatus?: OnlineStatus;
  @Input() fromQuestionList?: boolean;
  @Input() isClickEnabled = true;
  @Input() opaqueOverlay = false;
  @Input() institution?: Institution; // When provided, institution name and image will be shown if !user
  @Input() showName = false;
  @Input() showHostTag = false;
  @Input() showYouWord = false;
  @Input() nameMaxWidth?: number;
  @Input() hideMatTooltip = false;
  @Input() hasMenu = false;
  @Input() imgScale = 1;
  @Input() enableImageBorder = false;
  @Input() parentDisplay?: string;
  @Input() showAsChip = false;
  @Input() imgBorder = false;
  @HostBinding('class.is-header')
  @ViewChild('langMenuTrigger')
  langMenuTrigger!: MatMenuTrigger;
  OnlineStatusEnum = OnlineStatus;
  UserRoleEnum = UserRole;
  courses: Course[] = [];
  langSelectionEnabled = false;
  langCodeMap: LangCode[] = [];
  langCode = 'en';
  Features = Feature;
  isMobile = false;
  isRTL = false;

  constructor(
    public router: Router,
    public userService: UserService,
    public questionsService: QuestionsService,
    public authService: AuthService,
    private uiService: UiService,
    private flagsService: FlagsService,
    private settingsService: SettingsService,
  ) {
    if (this.flagsService.isFlagEnabled(FLAGS.SPACES_LANGUAGE_SELECTOR)) {
      this.langSelectionEnabled = true;
    }
    this.uiService.isMobile.pipe(untilDestroyed(this)).subscribe((res) => {
      this.isMobile = res;
    });
    this.userService.langCodes.pipe(untilDestroyed(this)).subscribe((res) => {
      this.langCodeMap = res;
    });
    this.userService.selectedLangCode.pipe(untilDestroyed(this)).subscribe((res) => {
      this.langCode = res;
    });
    this.userService.rtl.pipe(untilDestroyed(this)).subscribe((res) => {
      this.isRTL = res;
    });
  }

  ngOnInit(): void {
    if (typeof this.user === 'string') {
      this.userService
        .getIndividualUserStats(this.user)
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          this.user = res;
        });
    }

    if (!this.user && !this.fromQuestionList) {
      this.userService.user.pipe(untilDestroyed(this)).subscribe((res: any) => {
        this.user = res && res.user;
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.langMenuTrigger) {
      if (this.langMenuTrigger.menu) {
        this.langMenuTrigger.menu.overlayPanelClass = 'lang-menu-overlay';
      }
    }
  }
  profileClicked(): void {
    if (this.user && this.isClickEnabled) {
      this.router.navigate(['profiles', this.user._id]);
    }
  }

  openSettingsPanel(): void {
    this.settingsService.showSettingsPanel();
  }

  async changeLang(langCode: LangCode): Promise<void> {
    this.userService.confirmLanguageChange(this.langCode, langCode);
  }

  protected readonly IconTypes = IconTypes;
}

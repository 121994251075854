<div id="user-settings-language" class="settings-panel" [class.mobile]="isMobile">
  <div class="head">
    <span class="material-icons go-back" (click)="goBack.emit()"> arrow_back </span>
    <div class="description">
      <div class="title">{{ PANELS.LANGUAGE.name }}</div>
      <div class="subtitle">{{ PANELS.LANGUAGE.description }}</div>
    </div>
    <span class="material-icons close" (click)="goBack.emit()"> close </span>
  </div>
  <div class="sub-head" *ngIf="isMobile">
    {{ PANELS.LANGUAGE.description }}
  </div>
  <div class="body">
    <div class="action-bars">
      <div class="bar">
        <div class="icon">
          <span class="material-icons">language</span>
        </div>
        <div class="description">
          <div class="title">{{ 'Language' | translate }}</div>
          <div class="subtitle">
            {{ 'Change the language used in the user interface' | translate }}
          </div>
        </div>
        <div class="action">
          <app-language-selector></app-language-selector>
        </div>
      </div>
      <div class="bar">
        <div class="icon">
          <span class="material-icons-outlined">wb_sunny</span>
        </div>
        <div class="description">
          <div class="title">{{ 'Region' | translate }}</div>
          <div class="subtitle">{{ "Set your account's timezone" | translate }}</div>
        </div>
        <div class="action">
          <mat-checkbox
            [checked]="isTimezoneAuto"
            [(ngModel)]="isTimezoneAuto"
            (change)="toggleAuto($event.checked)"
            data-name="spaces-manager-user-avatar-menu-settings-langauge-auto-timezone"
          >
            {{ 'Set timezone automatically' | translate }}
          </mat-checkbox>
          <ng-moment-timezone-picker
            [(ngModel)]="timezoneObject"
            (ngModelChange)="handleTimezoneSelect()"
            data-name="spaces-manager-user-avatar-menu-settings-langauge-select-timezone"
          ></ng-moment-timezone-picker>
        </div>
      </div>
    </div>
  </div>
</div>

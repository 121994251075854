import { Component, Input, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Course, Institution } from '../../models/user';
import { ProfilesService } from '../../services/profiles.service';
import { QuestionsService } from '../../services/questions.service';

@UntilDestroy()
@Component({
  selector: 'app-author-profile-photo',
  templateUrl: './author-profile-photo.component.html'
})
export class AuthorProfilePhotoComponent implements OnInit {
  @Input() user: any;
  @Input() width?: number;
  @Input() fromQuestionList?: boolean;
  @Input() showName = false;
  @Input() isClickEnabled = true;

  institution?: Institution;
  courses: Course[] = [];

  constructor(
    private questionService: QuestionsService,
    private profilesService: ProfilesService
  ) {}

  ngOnInit(): void {
    
      this.profilesService.courses.pipe(untilDestroyed(this)).subscribe((res) => {
        if (res) {
          this.courses = res;
          this.questionService.selectedCourse.pipe(untilDestroyed(this)).subscribe((courseRes) => {
            if (courseRes) {
              const course = this.courses.find((c) => c._id === courseRes._id);
              this.institution = course && course.institution;
            }
          });
        }
      })
    ;
  }
}

<div id="select-space-dialog" [class.isMobile]="isMobile" [class.rtl]="isRTL">
  <div class="head">
    <div class="title">{{ 'Select Space' | translate }}</div>
    <div class="close-icon" role="button" (click)="cancel()">
      <span class="material-icons">close</span>
    </div>
  </div>
  <div class="body">
    <div class="spaces-list-top">
      <span class="material-icons">search</span>
      <input
        type="text"
        [placeholder]="'Search for Spaces' | translate"
        (keyup.enter)="filterSpaces($event)"
      />
    </div>
    <div class="warning-msg-container" *ngIf="!!user?.institution && spaces.length">
      <mat-icon>info_outline</mat-icon>
      <div class="warning-msg-text">
        {{ 'Only showing Spaces in your institution.' | translate }}
      </div>
    </div>
    <div class="spaces-list-main">
      <div
        class="spaces-list"
        [ngClass]="{ 'space-list-loading': isFetchingSpaces }"
        *ngIf="spaces.length"
        (scroll)="onScroll()"
        #spacesList
      >
        <div class="list-header">
          <div class="header-cell">{{ 'Name' | translate }}</div>
          <div class="header-cell">{{ 'Owner' | translate }}</div>
        </div>
        <div class="list-item" *ngFor="let space of spaces" (click)="selectItem(space)">
          <div class="item-cell space-name">
            {{ space.title }}
          </div>
          <div class="item-cell space-owner-wrap">
            <app-profile-photo
              [user]="space.owner"
              [width]="26"
              [showName]="true"
            ></app-profile-photo>
            <app-pencil-button
              class="pencil-button light flat-white"
              *ngIf="!isMobile"
              (clickEvent)="save(space)"
            >
              {{ 'Select' | translate }}
            </app-pencil-button>
          </div>
        </div>
      </div>

      <div class="list-loader" *ngIf="isFetchingSpaces">
        <app-content-loader [blockCount]="1" [floatLoader]="false"></app-content-loader>
      </div>
    </div>
  </div>
  <div class="foot">
    <div class="content">
      <span>{{ 'Select a Space from above or' | translate }}</span>
      <button
        mat-flat-button
        [disabled]="!canCreateSpace"
        [appTooltip]="'You do not have permission to create a Space' | translate"
        [appTooltipArrow]="'bottom'"
        [appTooltipDisabled]="canCreateSpace"
        (click)="showCreateSpaceDialog()"
      >
        <span class="material-icons">add</span>
        <span>{{ 'Create Space' | translate }}</span>
      </button>
    </div>
  </div>
</div>

<mat-dialog-content>
  <iframe
    width="1280"
    height="720"
    [src]="data.phetUrl | safe: 'resourceUrl'"
    frameborder="0"
    allowfullscreen
  ></iframe>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'Close' | translate }}</button>
</mat-dialog-actions>

import { Component, Input } from '@angular/core';
import { User } from 'src/app/models/user';

export interface Icon {
  icon?: string;
  svgIcon?: string;
  size?: number;
  user?: User;
  outlined?: boolean;
}

@Component({
  selector: 'div[notification-icon]',
  templateUrl: './toaster-icon.component.html',
  styleUrls: ['./toaster-icon.component.scss'],
})
export class ToasterIconComponent {
  @Input() icon?: Icon;
  @Input() removeMargin?: boolean;
  @Input() outlined?: boolean;
}

import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs';
import { UserInfoInputDialogComponent } from '../dialogs/user-info-input-dialog/user-info-input-dialog.component';
import { User } from '../models/user';
import {
  EventCategory,
  SessionEvent,
  SessionEventSubscriber,
} from '../sessions/session/SessionEvent';
import { SpaceRepository } from '../state/space.repository';
import { ProfilesService } from './profiles.service';
import { RealtimeSpaceService } from './realtime-space.service';
import { SessionSharedDataService } from './session-shared-data.service';
import { UserService } from './user.service';
import { TelemetryService } from './telemetry.service';
import { WbDialogService } from './wb-dialog.service';
import { ModalManagerService } from './modal-manager.service';
@Injectable({
  providedIn: 'root',
})
export class UserInfoInputDialogService implements SessionEventSubscriber {

  constructor(
    private userService: UserService,
    private profileService: ProfilesService,
    private spaceRepo: SpaceRepository,
    private realtimeSpaceService: RealtimeSpaceService,
    private sharedDataService: SessionSharedDataService,
    private telemetry: TelemetryService,
    private wbDialogService: WbDialogService,
    private modalManagerService: ModalManagerService,
  ) {
    this.sharedDataService.sessionEventController?.subscribeToEvent(this, EventCategory.Users);
  }

  sessionEventReceived(event: SessionEvent, userId: string): void {
    if (event.category === EventCategory.Users && event.type === 'rename-user') {
      // update populate users in space repo
      const updatedSession = this.spaceRepo.activeSpace!;
      if (updatedSession) {
        updatedSession.populatedUsers.forEach((user) => {
          if (user._id === event.data._id) {
            user.name = event.data.name;
          }
        });

        this.spaceRepo.updateSpace(this.spaceRepo.activeSpaceId!, updatedSession);
      }
    }
  }

  openDialog(showQuestionnaire: boolean): void {
    if (this.wbDialogService.getDialogRefOf(UserInfoInputDialogComponent)) {
      return;
    }
      
    this.modalManagerService.showModal(UserInfoInputDialogComponent, {
      width: '388px',
      panelClass: 'user-info-dialog-panel',
      disableClose: true,
      autoFocus: false,
      data: {
        showQuestionnaire,
      }
    },
      {
        afterClosed: (data) => {
          const currentUserWithUpdatedData: User = {
            ...this.userService.user.getValue()!.user,
            ...data,
          }
          this.profileService.updateCurrentUserGeneralInfo(currentUserWithUpdatedData).pipe(switchMap(() => this.profileService.getCurrentUserGeneralInfo())).subscribe({
            next: (response: { user: User }) => {
              this.userService.appLoading.next(false);
              // update current user
              const currentUser = this.userService.user.getValue()!;
              currentUser.user.name = response.user.name;
              currentUser.user.info = response.user.info;
              // emit the changes to all components
              this.userService.user.next(currentUser);
              this.telemetry.event('user_role_data_provided', {
                roleType: currentUser.user.info?.roleType,
                role: currentUser.user.info?.role,
                roleOther: currentUser.user.info?.roleOther,
              });
              // update populate users in space repo
              const updatedSession = this.spaceRepo.activeSpace!;
              if (updatedSession) {
                updatedSession.populatedUsers.forEach((user) => {
                  if (user._id === currentUser.user._id) {
                    user.name = currentUser.user.name;
                  }
                });
  
                this.spaceRepo.updateSpace(this.spaceRepo.activeSpaceId!, updatedSession);
              }
  
              // send event to all users
              this.realtimeSpaceService.service.sendEvent(
                this,
                new SessionEvent(EventCategory.Users, 'rename-user', {
                  _id: currentUser.user._id,
                  name: currentUser.user.name,
                }),
              );
  
              // send intercom event
              this.telemetry.event('spaces_manager_setup_completed');
  
              // send intercom event space manager loaded for guest users (no institution)
              if (!currentUser.user.institution) {
                this.telemetry.event('space-manager-loaded');
              }
            },
          });
        }
      }
    )
  }
}

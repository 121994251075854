<div class="modal-top">
  <button mat-icon-button (click)="close()" class="float-right">
    <mat-icon> close </mat-icon>
  </button>
</div>
<span class="modal-header">
  {{ 'You need to use your system browser to sign-in with Google' | translate }}
</span>
<svg-icon
  class="d-flex justify-content-center mb-3"
  [width]="173"
  [height]="168"
  [icon]="'embedded_google_login'"
></svg-icon>
<div class="mb-3 text-center content">
  <strong>{{ 'Sign-in with Google' | translate }}</strong>
  {{
    'does not work with embedded browsers in apps. Please visit Spaces on your device’s system browser.'
      | translate
  }}
</div>
<div class="mb-5 text-center content">
  {{
    'Copy the link below and paste it in your browser to sign-in with Google, or consider an alternate sign-in method.'
      | translate
  }}
</div>
<div class="d-flex link-container justify-content-center mt-5">
  <span class="link-text">{{ loginLink }}</span>
  <button mat-icon-button class="d-inline-block text-center" (click)="copyLink()">
    <mat-icon class="material-symbols-outlined link-button"> content_copy </mat-icon>
  </button>
</div>
<div class="link-copied text-center pt-2">
  <span *ngIf="linkCopied">{{ 'Link copied!' | translate }}</span>
</div>

<div class="panel-container">
  <div class="title-section">
    <span class="title">{{ 'Assign Site' | translate }}</span>
    <i class="material-icons close" (click)="close()" aria-label="Close"> close </i>
  </div>
  <div class="body">
    <div class="site-selection-note">
      <i class="material-icons-outlined info-icon" aria-label="Info"> info </i>
      <span class="text">
        {{ 'Once a site has been selected, it cannot be changed.' | translate }}
      </span>
    </div>
    <form class="site-selection-form" [formGroup]="form">
      <ul class="site-list">
        <ng-container *ngFor="let site of availableSites">
          <li class="site-list-element" (click)="setSelectedSiteId(site._id)">
            <span class="site" [class.selected-site]="site._id === form.value?.siteId">
              {{ site.name }}
            </span>
            <i
              *ngIf="site._id === form.value?.siteId"
              class="icon material-icons-outlined check-icon"
              >check</i
            >
          </li>
        </ng-container>
      </ul>
    </form>
  </div>
  <footer>
    <div class="footer">
      <app-pencil-button
        class="pencil-button medium primary btn-submit"
        [label]="'Done' | translate"
        buttonType="button"
        icon="done"
        [iconConfig]="{
          type: 'material-icon'
        }"
        [disabled]="!form.valid"
        (click)="submit()"
      >
      </app-pencil-button>
    </div>
  </footer>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="filterPanelOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'fileExtensionFilterOverlays'"
>
  <div class="overlayFilters" tabindex="0">
    <div class="float-left filterHeader">
      <h5>
        <strong>{{ 'Filters' | translate }}</strong>
      </h5>
    </div>
    <ng-container *ngFor="let filterSet of fileFilterService.filterSets">
      <!-- Topics Filter are not required to render here -->
      <!-- Hiding tag filters until new UX is ready -->
      <div
        class="float-left filterContent"
        *ngIf="filterSet.name !== FilterTypes.Topic && filterSet.name !== FilterTypes.Tag"
      >
        <div class="filter-panel-header">
          <h6>{{ filterSet.name | translate | truncate: [maxNameLength] }}</h6>
        </div>
        <app-pill
          *ngFor="let filter of filterSet.filters"
          [text]="filter | displayFilter"
          [type]="filterSet.name"
          [subtext]="filter.course?.name"
          [icon]="filterSet.icon"
          [showXButton]="false"
          [optionalFilters]="true"
          (onClick)="modifyFilter(filter, filterSet)"
        ></app-pill>
      </div>
    </ng-container>
  </div>
</ng-template>

<div
  class="container-fluid d-inline-block float-right files-container"
  tabindex="1"
  (focus)="filterPanelOpen = false"
>
  <div
    class="
      file-nav
      top-section
      border-bottom
      d-flex
      align-items-center
      justify-content-between
      w-100
    "
    style="height: auto"
  >
    <div class="d-flex justify-content-start search-bar-div">
      <app-search-bar
        [searchTitle]="'Search Files, Folders, Filters, or Topics' | translate"
        [suggestionGroups]="suggestionsGroup"
        [TagInput]="true"
        [HybridFilterMode]="true"
        [editSearchValue]="fileFilterService.search"
        [editFilterEvent]="filterEmitter.asObservable()"
        (filter)="setSearchValue($event)"
        (editFilter)="editFilterFromSearchBar($event)"
      >
      </app-search-bar>
      <span
        *ngIf="
          showResultsNumber &&
          activeFolder &&
          (filesLoading$ | async) === false &&
          (appliedFilters || fileFilterService.search.length)
        "
        class="searchResult"
      >
        {{
          activeFolder.resources.total + activeFolder.total === 0
            ? 'No'
            : activeFolder.resources.total + activeFolder.total
        }}
        results found
      </span>
    </div>
    <div class="d-flex justify-content-end filterButton">
      <button
        mat-icon-button
        (click)="filterPanelOpen = !filterPanelOpen"
        type="button"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        class="pcl-files-extension-tune-filter"
        data-name="upload-tool-selection-pencil-files-filter"
      >
        <mat-icon> tune </mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="gridView = !gridView"
        class="pcl-files-ext-list-alt"
        type="button"
        data-name="upload-tool-selection-pencil-files-grid-list"
      >
        <mat-icon *ngIf="!gridView">view_module</mat-icon>
        <mat-icon *ngIf="gridView">list_alt</mat-icon>
      </button>
    </div>
  </div>
  <div
    *ngIf="activeFolder && (filesLoading$ | async) === false && path?.length > 0"
    class="cmt-8 cmb-8 folder-breadcrumb d-flex flex-row diplay-paths"
  >
    <div *ngFor="let p of path; let i = index" class="d-flex flex-row">
      <div class="folder" (click)="backInPath(i)">
        {{
          p.name === institutionId
            ? ('Pencil Cloud' | translate)
            : (p.name | truncate: [maxNameLength])
        }}
      </div>
      <i class="material-icons">chevron_right</i>
    </div>
    <div class="active">
      {{
        activeFolder.name === institutionId
          ? ('Pencil Cloud' | translate)
          : (activeFolder.name | truncate: [maxNameLength])
      }}
    </div>
  </div>
  <div
    *ngIf="
      currentFolders.length === 0 && currentFiles.length === 0 && (filesLoading$ | async) === false
    "
    class="no-res-background"
  >
    <!-- Any search/filter applied, then show different message-->
    <ng-container *ngIf="appliedFilters || fileFilterService.search.length">
      {{ 'No matching items found' | translate }} <br />
      <span class="sub-message">
        <br />
        {{ 'No Results found' | translate }}
        <br />
        <small>{{ 'Please review your filter settings' | translate }}</small></span
      >
    </ng-container>
    <!--No search/filter applied -->
    <ng-container *ngIf="!appliedFilters && !fileFilterService.search.length"
      >{{ 'No items found' | translate }}<br />
    </ng-container>
  </div>
  <app-extended-grid-view
    *ngIf="gridView"
    #filesList
    [ngClass]="{ 'top-margin': !path?.length }"
    [activeFolder]="activeFolder"
    [courseLookup]="fileFilterService.courseLookup"
    [draggingWithoutSelect]="true"
    [enforcingFilter]="enforcingFilter"
    [isCreatingFolder]="false"
    [canSelect]="true"
    [maxNameLength]="maxNameLength"
    [maxCourseNameLength]="maxCourseNameLength"
    [user]="user"
    [loading]="(filesLoading$ | async) && !scrolling"
    (toggledAll)="fetchFile.emit(this.selectedFiles)"
    (toggleSelectionEmitter)="handleToggleSelection($event)"
    (openFolder)="openFolder($event.folder, $event.append)"
    (scroll)="onScrollDown()"
    (snackbar)="showSnackbar($event)"
  >
  </app-extended-grid-view>
  <app-extended-list-view
    *ngIf="!gridView"
    #filesList
    [ngClass]="{ 'top-margin': !path?.length }"
    [activeFolder]="activeFolder"
    [courseLookup]="fileFilterService.courseLookup"
    [draggingWithoutSelect]="true"
    [enforcingFilter]="enforcingFilter"
    [isCreatingFolder]="false"
    [canSelect]="true"
    [maxNameLength]="maxNameLength"
    [maxCourseNameLength]="maxCourseNameLength"
    [user]="user"
    [loading]="(filesLoading$ | async) && !scrolling"
    (toggledAll)="fetchFile.emit(this.selectedFiles)"
    (toggleSelectionEmitter)="handleToggleSelection()"
    (openFolder)="openFolder($event.folder, $event.append)"
    (scroll)="onScrollDown()"
    (snackbar)="showSnackbar($event)"
  >
  </app-extended-list-view>

  <app-content-loader
    *ngIf="filesLoading$ | async"
    [blockCount]="25"
    [file]="true"
    [tags]="true"
    [singleLineList]="false"
    [border]="false"
    [list]="false"
  >
  </app-content-loader>
</div>

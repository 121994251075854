import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {
  EMPTY,
  Observable,
  Subscription,
  combineLatest,
  distinctUntilChanged,
  skip,
  take,
} from 'rxjs';
import { FLAGS, FlagsService } from 'src/app/services/flags.service';
import { RealtimeService } from 'src/app/services/realtime.service';
import { SessionSharedDataService } from 'src/app/services/session-shared-data.service';
import { UserService } from 'src/app/services/user.service';
import { SpaceRepository } from 'src/app/state/space.repository';
import { AdmissionStatus, RealTimeUpdatesDataTypes, Visibility } from 'src/app/models/session';
import {
  ContextTypeEnum,
  PresenceData,
  PresenceType,
  RedisPresenceV2,
  WAITING_ROOM_SUFFIX,
} from 'src/app/services/presence_v2.service';
import { URL_CONSTANTS } from 'src/app/common/utils/url';
import { PresenceRepository } from 'src/app/state/presence.repository';
import { User } from 'src/app/models/user';
import { AclService } from 'src/app/services/acl.service';
import { GlobalPermissionsService } from 'src/app/services/global-permissions.service';
import {
  AccessResponseBody,
  INVALID_SPACE_ID_ERR,
  RequestAccessBaseService,
  SPACE_NOT_FOUND_ERR,
} from '../base/request-access-base.service';
import { WaitingRoomNotificationService } from './waiting-room-notification.service';
import { WaitingRoomClientService } from './waiting-room-client.service';
import { WaitingRoomRepoService } from './waiting-room-repo.service';

const NO_WAITING_ROOM_SPACE_ERR = 'Waiting room is not enabled for this space.';
const GUEST_ACCESSING_PRIVATE_SPACE_ERR = 'Guest Users are not allowed in private space.';
const USER_HAS_NO_ACCESS_ERR = 'User has no access to this waiting room.';
const USER_IS_HOST_ERR = 'You are a host and have no access to this waiting room';

@Injectable({
  providedIn: 'root',
})
export class WaitingRoomService extends RequestAccessBaseService {
  private presenceSub: Subscription = new Subscription();
  private acceptAllUsersSub: Subscription = new Subscription();
  private removedUsersSub: Subscription = new Subscription();

  constructor(
    protected userService: UserService,
    protected flagsService: FlagsService,
    protected realtimeService: RealtimeService,
    protected spaceRepo: SpaceRepository,
    protected aclService: AclService,
    protected sessionSharedDataService: SessionSharedDataService,
    protected waitingRoomClientService: WaitingRoomClientService,
    protected waitingRoomNotificationService: WaitingRoomNotificationService,
    protected router: Router,
    private presenceService: RedisPresenceV2,
    private presenceRepository: PresenceRepository,
    private ngZone: NgZone,
    private waitingRoomRepoService: WaitingRoomRepoService,
    private globalPermissionsService: GlobalPermissionsService,
  ) {
    super(
      userService,
      flagsService,
      realtimeService,
      spaceRepo,
      aclService,
      sessionSharedDataService,
      waitingRoomClientService,
      waitingRoomNotificationService,
      router,
    );

    combineLatest([
      this.flagsService.featureFlagChanged(FLAGS.REQUEST_ACCESS_ENABLED),
      this.flagsService.featureFlagChanged(FLAGS.ENABLE_ANONYMOUS_LOGIN),
      this.spaceRepo.isCurrentUserHost$.pipe(distinctUntilChanged()),
      this.globalPermissionsService.isWaitingRoomEnabled$,
      this.spaceRepo.activeSpaceId$.pipe(distinctUntilChanged()),
      this.spaceRepo.spaceVisibility$.pipe(distinctUntilChanged()),
    ]).subscribe(
      ([
        isRequestAccessEnabled,
        isAnonymousLoginEnabled,
        isHost,
        isWaitingRoomEnabled,
        spaceID,
        visibility,
      ]) => {
        const isEnteringNewSpace: boolean = !!spaceID && this.spaceID !== spaceID;
        this.spaceID = spaceID ?? '';
        const isPublicSpace = visibility === Visibility.PUBLIC;

        const shouldShowAccessRequests = !!(
          spaceID &&
          isHost &&
          ((isRequestAccessEnabled && isWaitingRoomEnabled) ||
            (isPublicSpace && isAnonymousLoginEnabled))
        );

        if (shouldShowAccessRequests) {
          this.setupSpaceForShowingAccessRequests(isEnteringNewSpace);
        } else {
          this.cleanupSubs();
        }
      },
    );
  }

  protected handleSessionMessage(message: any) {
    if (
      message.action !== 'updateData' ||
      message.id !== this.spaceID ||
      !message.data ||
      message.dataType !== RealTimeUpdatesDataTypes.SESSION_WAITING_ROOM_REQUEST
    ) {
      return;
    }

    this.handleSessionUpdates(message);
  }

  protected handleSessionUpdates(message: any) {
    const requesterID = message?.data?.requesterId;
    const activeSpace = this.spaceRepo.activeSpace;

    const populatedRequester = activeSpace?.populatedUsers.find(
      (requester) => requester._id === requesterID,
    );
    if (!populatedRequester) {
      return;
    }
    if (this.requesterAlreadyInWaitingRoom(requesterID)) {
      this.waitingRoomNotificationService.showNotificationIfThereAreRequests(
        this._accessRequesters$.value,
      );
    }
  }

  private requesterAlreadyInWaitingRoom(requesterID: string): boolean {
    const waitingRoomRequestersIDsSet = new Set<string>(
      this._accessRequesters$.value.map((requester) => requester._id),
    );
    return waitingRoomRequestersIDsSet.has(requesterID);
  }

  private setupSpaceForShowingAccessRequests(isEnteringNewSpace: boolean) {
    this.setSessionChannelSub();
    this.setRepoSubs();
    if (isEnteringNewSpace) {
      // wait for receiving first presence update before initializing
      this.presenceRepository.gotPresenceUpdate.pipe(take(1)).subscribe(() => {
        this.initWaitingRoomPresence();
        this.fireNotificationAfterSpaceSynced();
      });
    } else {
      this.requestAccessNotificationService.startShowingNotifications();
      this.initWaitingRoomPresence();
    }
    this._shouldShowAccessRequests$.next(true);
    this.setNotificationsSubs();
  }

  private setRepoSubs() {
    this.acceptAllUsersSub.unsubscribe();
    this.acceptAllUsersSub = this.waitingRoomRepoService.acceptAllInWaitingRoom$.subscribe((_) => {
      this.approveAllNonAnonymousRequests();
    });

    this.removedUsersSub.unsubscribe();
    this.removedUsersSub = this.waitingRoomRepoService.usersRemovedFromSpace.subscribe(
      (userIDs: string[]) => {
        this.reject(userIDs);
      },
    );
  }

  private initWaitingRoomPresence() {
    this.initPresence();
    this.setPresenceSub();
  }

  protected cleanupSubs() {
    super.cleanupSubs(true);

    this.presenceSub.unsubscribe();
    this.acceptAllUsersSub.unsubscribe();
    this.removedUsersSub.unsubscribe();
  }

  /* methods called from outside this service */

  // NOTE : in the request access scenario , the active session is not set yet , so this is used to set the space id from
  //        the request access component in this case.

  public setCurrentSpaceID(spaceID: string) {
    this.spaceID = spaceID;
  }

  protected handleRequestAccessErrors(error: any) {
    // behavior from the error interceptor
    if (error?.status === 401) {
      this.waitingRoomNotificationService.warning(
        error?.error && error.error.err,
        error.status.toString(),
      );
    }
    // if waiting room got disabled or user got removed or promoted to host
    // redirect to space when tring to request access again
    if (
      error.error?.err === NO_WAITING_ROOM_SPACE_ERR ||
      error.error?.err === USER_HAS_NO_ACCESS_ERR ||
      error.error?.err === USER_IS_HOST_ERR
    ) {
      this.waitingRoomNotificationService.warning("You can't access this waiting room.");
      this.router.navigate([`${URL_CONSTANTS.SPACES}/${this.spaceID}`]);
    } else if (error.error?.err === GUEST_ACCESSING_PRIVATE_SPACE_ERR) {
      this.waitingRoomNotificationService.warning(error.error.err);
      this.router.navigate([`${URL_CONSTANTS.SPACES}`]);
    } else if (
      error.error?.err?.includes(SPACE_NOT_FOUND_ERR) ||
      error.error?.err?.includes(INVALID_SPACE_ID_ERR)
    ) {
      this.waitingRoomNotificationService.warning(
        'The space you were previously on has been deleted.',
      );
      this.router.navigate([`${URL_CONSTANTS.SPACES}`]);
    }

    this._requestAccessSent$.next(false);

    // return a noop so that the subscribe function is not called in case of errors occuring
    // also not throwing an error so that it won't be captured by sentry
    return EMPTY;
  }

  protected accessRespond(requestersIDs: string[], admissionStatus: AdmissionStatus) {
    const body: AccessResponseBody = {
      requestersIDs,
      admissionStatus,
    };

    this.waitingRoomClientService.respondToAccessRequest(this.spaceID, body).subscribe((res) => {
      // if the request succeeded , then disconnect users from waiting room presence
      this.removeFromWaitingRoom(requestersIDs, true);
    });
  }

  protected handleUserMessage(message: any) {
    if (
      !message ||
      message.action !== 'updateData' ||
      message.dataType !== RealTimeUpdatesDataTypes.USER_WAITING_ROOM_RESPONSE ||
      message.dataId !== this.spaceID
    ) {
      return;
    }

    this.handleAccessRequestUserUpdates(message);
  }

  public unSetUserChannelSub() {
    this.userChannelSub.unsubscribe();
  }

  setSpaceAndRoomForPresence() {
    this.waitingRoomRepoService.emitWaitingRoom(this.spaceID, this.getWaitingRoomID());
  }

  exitWaitingRoom() {
    this.waitingRoomRepoService.exitWaitingRoom();
  }

  setWaitingRoomPresence() {
    const presenceData: PresenceData = this.buildWaitingRoomPresenceData(this.user._id);
    this.presenceService.setWithCustomData(presenceData, 3, true);
  }

  startHeartBeat() {
    this.ngZone.runOutsideAngular(async () => {
      await this.presenceService.startHeartbeat(PresenceType.SPACE_PRESENCE);
    });
  }

  async stopHeartBeat() {
    await this.presenceService.terminateHeartbeat();
  }

  removeFromWaitingRoom(userIDs: string[], isRemovingOtherUsers: boolean) {
    userIDs.forEach((id) => {
      const presenceData: PresenceData = this.buildWaitingRoomPresenceData(id);
      this.presenceService.removeWithCustomData(presenceData, 3, true, isRemovingOtherUsers);
    });
  }

  buildWaitingRoomPresenceData(userId: string): PresenceData {
    // the assumption here is to have the unique hash as the user id so that each user will have only one instance
    // in the waiting room presence , even if the user opened the same waiting room form multiple instances
    // they will all correspond to only one instance
    const presenceData: PresenceData = this.presenceService.createPresenceData(
      this.spaceID,
      PresenceType.SPACE_PRESENCE,
      ContextTypeEnum.WAITING_ROOM,
      this.getWaitingRoomID(),
      userId,
    );

    return presenceData;
  }

  isWaitingRoomID(id: string): boolean {
    return id.includes(WAITING_ROOM_SUFFIX);
  }

  getWaitingRoomID(id?: string) {
    if (!id || id === '') {
      id = this.spaceID;
    }

    return `${id}:${WAITING_ROOM_SUFFIX}`;
  }

  initPresence() {
    const usersIDs: string[] = this.getWaitingRoomPresenceCurrentValue();
    const usersIDsSet: Set<string> = new Set<string>(usersIDs);

    const populatedUsersInWaitingRoom = this.getPopulatedUsers(usersIDsSet);

    this._accessRequesters$.next(populatedUsersInWaitingRoom);
    this._accessRequestersSize$.next(populatedUsersInWaitingRoom.length);
  }

  setPresenceSub() {
    this.presenceSub.unsubscribe();
    this.presenceSub = this.getWaitingRoomPresenceActivity$()
      .pipe(skip(1))
      .subscribe((newIDsSet: Set<string>) => this.handlePresenceUpdate(newIDsSet));
  }

  handlePresenceUpdate(newIDsSet: Set<string>) {
    const currentAccessRequesters: User[] = this._accessRequesters$.value ?? [];
    const currentAccessRequestersIDs: string[] = currentAccessRequesters.map((user) => user._id);
    const currentIDsSet: Set<string> = new Set<string>(currentAccessRequestersIDs);

    const addedIDs: string[] = [...newIDsSet].filter((id) => !currentIDsSet.has(id));
    const addedIDsSet: Set<string> = new Set<string>(addedIDs);
    const removedIDs: string[] = currentAccessRequestersIDs.filter((id) => !newIDsSet.has(id));
    const removedIDsSet: Set<string> = new Set<string>(removedIDs);

    const addedPopulatedRequesters = this.getPopulatedUsers(addedIDsSet);

    // handle emitting new access requesters
    const newAccessRequesters = currentAccessRequesters.filter(
      (user) => !removedIDsSet.has(user._id),
    );
    newAccessRequesters.push(...addedPopulatedRequesters);

    this._accessRequesters$.next(newAccessRequesters);
    this._accessRequestersSize$.next(newAccessRequesters.length);

    if (addedIDs.length > 0) {
      this.waitingRoomNotificationService.showNotificationIfThereAreRequests(newAccessRequesters);
    } else if (removedIDs.length > 0) {
      this.waitingRoomNotificationService.updateExistingNotificationIfThereAreRequests(
        newAccessRequesters,
      );
    }
  }

  getPopulatedUsers(idsSet: Set<string>): User[] {
    const activeSpace = this.spaceRepo.activeSpace;
    if (!activeSpace) {
      return [];
    }

    const populatedUsersInWaitingRoom: User[] = activeSpace.populatedUsers.filter((user) =>
      idsSet.has(user._id),
    );
    return populatedUsersInWaitingRoom;
  }

  getWaitingRoomPresenceCurrentValue(): Array<string> {
    return (
      this.presenceRepository
        .getPresenceDataValue(
          this.spaceID,
          PresenceType.SPACE_PRESENCE,
          ContextTypeEnum.WAITING_ROOM,
          this.getWaitingRoomID(),
        )
        ?.map((data) => data.userId) ?? []
    );
  }

  getWaitingRoomPresenceActivity$(spaceID?: string): Observable<Set<string>> {
    return this.presenceRepository.getPresenceData(
      spaceID ?? this.spaceID,
      PresenceType.SPACE_PRESENCE,
      ContextTypeEnum.WAITING_ROOM,
      this.getWaitingRoomID(spaceID),
    );
  }
}

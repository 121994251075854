import { Observable } from 'rxjs';
import { ADT } from 'ts-adt';

export type RecordingOutput = ADT<{
  none: Record<string, unknown>;
  file: {
    reason?: string;
    file: File;
  };
}>;

export abstract class RecordingService {
  abstract state$: Observable<RECORDING_STATE>;
  abstract output$: Observable<RecordingOutput>;
  abstract startRecording(): Promise<void>;
  abstract stopRecording(): Promise<void>;
}

export abstract class PauseableRecordingService extends RecordingService {
  abstract pauseRecording(): void;
  abstract resumeRecording(): void;

  static isPauseable(
    service: RecordingService | PauseableRecordingService,
  ): service is PauseableRecordingService {
    return 'pauseRecording' in service && 'resumeRecording' in service;
  }
}

export enum RECORDING_ERROR {
  IDLE_TIMEOUT,
}

export type RECORDING_STATE = ADT<{
  [RECORD_STATUS.READY]: {};
  [RECORD_STATUS.ACTIVE]: {
    initiatorParticipantId: string | undefined;
  };
  [RECORD_STATUS.PAUSED]: {
    initiatorParticipantId: string | undefined;
  };
  [RECORD_STATUS.FINISHED]: {
    err?: {
      type: RECORDING_ERROR;
      msg: string;
    };
  };
}>;

export enum RECORD_STATUS {
  READY = 'READY',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  FINISHED = 'FINISHED',
}

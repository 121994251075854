import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NotificationsService } from 'src/app/services/notifications.service';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UiService } from 'src/app/services/ui.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Notification, NotificationExtended } from '../../models/global.interface';

@UntilDestroy()
@Component({
  selector: 'app-notifications-menu',
  templateUrl: './notifications-menu.component.html',
  styleUrls: ['./notifications-menu.component.scss'],
})
export class NotificationsMenuComponent implements OnInit {
  isMenuOpened = false;
  isSubmenuOpened = false;
  notificationsCount = 0;
  tab = 'all';
  currentDate?: string;
  notifications?: Notification[];
  loading = false;
  page = 1;
  showMore = true;
  filteredNotifications: {
    all: NotificationExtended[];
    unread: NotificationExtended[];
  } = { all: [], unread: [] };
  touchPoint = 0;
  isMobileView = false;

  @Input() user: any;
  @Input() marginBottom = 0;
  @Input() isClickEnabled = true;
  @ViewChild('notificationsMenu') notificationsMenu!: ElementRef;

  constructor(
    private notificationsService: NotificationsService,
    public userService: UserService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private uiService: UiService,
  ) {
    this.uiService.isMobile.pipe(untilDestroyed(this)).subscribe((res) => {
      this.isMobileView = res;
    });
  }

  ngOnInit(): void {
    this.getCount();

    this.notificationsService.notificationCount.pipe(untilDestroyed(this)).subscribe((res) => {
      this.notificationsCount = res;
      this.fetch(false);
    });
  }

  getCount(): void {
    this.notificationsService
      .getNotificationsCount()
      .pipe(untilDestroyed(this))
      .subscribe((res: any) => {
        this.notificationsCount = res.notification_count;
      });
  }

  fetch(showLoader = true): void {
    /**
     * after fetching we first map notifications and attach date labels to it
     * then we pass mapped arrays to all and unread as we display those on two
     * separate tabs
     */
    this.loading = showLoader;

    this.notificationsService
      .getNotifications()
      .pipe(untilDestroyed(this))
      .subscribe(
        (res: any) => {
          if (!res) {
            return;
          }
          const mappedRes = this.notificationsService.mapNotificationsByDate(
            res.notifications,
            this.currentDate,
            false,
            true,
          );
          this.notifications = mappedRes.notifications;
          this.currentDate = mappedRes.currentDate;

          this.filteredNotifications.all = this.notifications;
          const mappedUnreadRes = this.notificationsService.mapNotificationsByDate(
            this.notificationsService.getUnreadNotifications(res.notifications, this.user),
            this.currentDate,
            false,
            true,
          );

          this.filteredNotifications.unread = mappedUnreadRes.notifications;
          this.currentDate = mappedUnreadRes.currentDate;
          this.loading = false;
        },
        () => {
          this.loading = false;
        },
      );
  }

  tabChange(tab: MatTabChangeEvent): void {
    this.tab = tab.index === 0 ? 'all' : 'unread';
  }

  openNotificationTab(): void {
    this.toggleMenu();
    this.router.navigate(['/notifications']);
  }

  toggleMenu(fetchNotification = false): void {
    this.isMenuOpened = !this.isMenuOpened;
    if (!this.isMenuOpened) {
      this.tab = 'all';
      if (this.user && this.user.user) {
        this.user.user['last_notifications_viewed_timestamp'] = new Date();
        this.userService.user.next(this.user);
        this.notificationsService.notificationCount.next(0);
      }
    }
    /**
     * only if we are opening menu we should fetch for data
     */
    if (fetchNotification && this.isMenuOpened) {
      this.page = 1;
      this.fetch();
      this.getCount();
    }
  }

  async close(id: string): Promise<void> {
    this.userService.appLoading.next(true);
    try {
      const res: any = await this.notificationsService.archiveNotification(id).toPromise();
      if (res._id) {
        this.toastr.success(this.translate.instant('Archived successfully'));
      }
      this.currentDate = undefined;
      this.fetch();
      this.userService.appLoading.next(false);
    } catch (error) {
      this.userService.appLoading.next(false);
    }
  }

  fetchMore(): void {
    this.page++;
    this.loading = true;
    this.notificationsService
      .getNotifications(this.page)
      .pipe(untilDestroyed(this))
      .subscribe((res: any) => {
        this.loading = false;
        if (res.notifications) {
          /**
           * when we are loading more map new notifications by data and push that to new
           * array along with previous data. Do same for all and for unread notifications
           */
          const mappedRes = this.notificationsService.mapNotificationsByDate(
            res.notifications,
            this.currentDate,
            true,
          );

          this.currentDate = mappedRes.currentDate;

          if (this.notifications) {
            this.notifications = [
              ...this.notifications,
              ...(mappedRes.notifications as NotificationExtended[]),
            ];
          }

          const mappedUnreadRes = this.notificationsService.mapNotificationsByDate(
            this.notificationsService.getUnreadNotifications(res.notifications, this.user),
            this.currentDate,
            true,
          );
          this.currentDate = mappedUnreadRes.currentDate;

          this.filteredNotifications.unread = [
            ...this.filteredNotifications.unread,
            ...mappedUnreadRes.notifications,
          ];
          if (this.notifications) {
            this.filteredNotifications.all = this.notifications;
          }

          if (res.notifications.length === 0) {
            this.showMore = false;
          }
        }
      });
  }

  mouseWheel(event: WheelEvent): void {
    this.notificationsMenu.nativeElement.scrollTop += event.deltaY;
    this.handleScrollEnd();
  }

  handleScrollEnd(): void {
    /**
     * we check have we reached the end of scroll. If we have fetch more data
     * and in case that we are not fetching at the moment or that we have
     * fetched all data already
     */
    const elOffset =
      this.notificationsMenu.nativeElement.scrollHeight -
      this.notificationsMenu.nativeElement.offsetHeight;
    if (
      this.notificationsMenu.nativeElement.scrollTop > elOffset &&
      this.showMore &&
      !this.loading &&
      this.tab === 'all'
    ) {
      this.fetchMore();
    }
  }

  touchStart(e: TouchEvent): void {
    this.touchPoint = e.changedTouches[0].clientY;
  }

  touchEnd(e: TouchEvent): void {
    this.touchPoint = e.changedTouches[0].clientY;
  }

  touchMove(e: TouchEvent): void {
    this.notificationsMenu.nativeElement.scrollTop += this.touchPoint - e.changedTouches[0].clientY;
    this.touchPoint = e.changedTouches[0].clientY;
    this.handleScrollEnd();
  }
}

export interface SpaceRecording {
  recordingId: string;
  spaceId: string;
  roomId: string;
  startingTime: string;
  duration: number;
  downloadLink: string;
  streamingLink: string;
  access?: string;
}

export enum RecordingAccess {
  HOSTS = 'hosts',
  HOSTS_AND_PARTICIPANTS = 'hosts-and-participants',
}

<div
  id="image-resize-new"
  [class.fit-image-resize]="componentUsedFromChatBox"
  [style.justify-content]="posStyle['justify-content']"
>
  <div #imageContainer id="image-container" (click)="startResize()">
    <div class="resizer-wrap" *ngIf="showResizeHandle">
      <div class="resize-handle top-left" (mousedown)="onMouseDown($event, 'top-left')"></div>
      <div class="resize-handle top-right" (mousedown)="onMouseDown($event, 'top-right')"></div>
      <div class="resize-handle bottom-left" (mousedown)="onMouseDown($event, 'bottom-left')"></div>
      <div
        class="resize-handle bottom-right"
        (mousedown)="onMouseDown($event, 'bottom-right')"
      ></div>
    </div>
    <img class="image-content" [src]="frag.data" [ngStyle]="{ width: frag.metadata?.img_width }" />
  </div>
</div>

import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[contenteditable]',
})
export class ContentEditableDirective {
  @Input() contenteditable = false;
  @Input() autoFocus = true;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes) {
    if (changes.contenteditable) {
      this.el.nativeElement.contentEditable = this.contenteditable;
      if (this.autoFocus) {
        this.el.nativeElement.focus();
      }
    }
  }
}

<div class="dialog-content d-flex">
  <div class="libraries">
    <div
      *ngFor="let l of libraries"
      class="d-flex position-relative cpx-16 cpy-12 cursor-pointer lib-item library-item"
      [class.selected]="l.title === selectedLib"
      (click)="selectLib(l)"
      [attr.data-name]="'upload-tool-selection-' + l.icon.toLowerCase()"
    >
      <svg-icon
        class="library-item-icon cmr-8"
        [icon]="l.icon"
        [width]="16"
        [height]="16"
        [filter]="
          l.title === selectedLib
            ? 'invert(41%) sepia(39%) saturate(3085%) hue-rotate(200deg) brightness(96%) contrast(93%)'
            : null
        "
      ></svg-icon>
      <span>{{ l.title | translate }}</span>
      <svg-icon class="expanded-list-item" [icon]="'expanded_list_item'"></svg-icon>
    </div>
  </div>
  <div class="flex-1 border-start cp-16" [style.height.%]="100" #libContentContainer>
    <!--    Upload Computer-->
    <div
      class="d-flex align-items-center justify-content-center upload-content"
      *ngIf="selectedLib === LibrariesTitles.COMPUTER"
    >
      <!--      This component is for the case where we are downloading a PDF from pencil files
To allow splitting it locally and then re upload it to the board-->
      <app-upload-pdf-preparing-document
        *ngIf="isLoadingPdfFromPencilLib$ | async; else selectFileContainer"
        class="pdf-loading-indicator"
      >
      </app-upload-pdf-preparing-document>
    </div>
    <!--    Pencil files-->
    <div class="load-overlay-container" *ngIf="selectedLib === LibrariesTitles.PENCIL_FILES">
      <div class="d-flex flex-row flex-wrap align-items-center justify-content-center h-100">
        <app-files-extension
          [enforcingFilter]="ResourceFilterParamsType.Type"
          [mandatoryType]="data.type"
          [showResultsNumber]="false"
          (fetchFile)="selectedFileFromPencilLibrary($event); handleAutoUpload()"
          (autoUpload)="autoUpload = $event"
          style="width: 100% !important; height: 100% !important"
        ></app-files-extension>
      </div>
    </div>
    <!--    Camera-->
    <div class="w-100 load-overlay-container" *ngIf="selectedLib === LibrariesTitles.CAMERA">
      <div class="buttons-container">
        <button
          *ngIf="imageSrc"
          mat-icon-button
          (click)="flipVertical()"
          [ngbTooltip]="'Rotate Vertically' | translate"
        >
          <mat-icon>swap_vert</mat-icon>
        </button>
        <button
          *ngIf="imageSrc"
          mat-icon-button
          (click)="flipHorizontal()"
          [ngbTooltip]="'Rotate Horizontally' | translate"
        >
          <mat-icon>swap_horiz</mat-icon>
        </button>
        <button
          *ngIf="imageSrc"
          mat-icon-button
          (click)="rotateLeft()"
          [ngbTooltip]="'Rotate Left' | translate"
        >
          <mat-icon>rotate_90_degrees_ccw</mat-icon>
        </button>
        <button
          *ngIf="imageSrc"
          mat-icon-button
          (click)="rotateRight()"
          class="rotate-right-btn"
          [ngbTooltip]="'Rotate Right' | translate"
        >
          <mat-icon>rotate_90_degrees_ccw</mat-icon>
        </button>
        <button
          *ngIf="imageSrc"
          mat-icon-button
          (click)="zoomIn()"
          [ngbTooltip]="'Zoom In' | translate"
        >
          <mat-icon>zoom_in</mat-icon>
        </button>
        <button
          *ngIf="imageSrc"
          mat-icon-button
          (click)="zoomOut()"
          [ngbTooltip]="'Zoom Out' | translate"
        >
          <mat-icon>zoom_out</mat-icon>
        </button>
      </div>
      <image-cropper
        *ngIf="imageSrc"
        [imageURL]="imageSrc"
        [maintainAspectRatio]="false"
        [containWithinAspectRatio]="containWithinAspectRatio"
        [aspectRatio]="4 / 3"
        [cropperMinWidth]="128"
        [onlyScaleDown]="true"
        [roundCropper]="false"
        [canvasRotation]="canvasRotation"
        [transform]="transform"
        [alignImage]="'left'"
        [style.display]="showCropper ? null : 'none'"
        format="jpeg"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
      <webcam
        class="webcam-wrapper"
        *ngIf="!imageSrc"
        [height]="720"
        [width]="1280"
        [videoOptions]="videoOptions"
        [trigger]="triggerObservable"
        [allowCameraSwitch]="true"
        [imageQuality]="1"
        (imageCapture)="handleImage($event)"
        (cameraSwitched)="setCameraActive()"
      >
      </webcam>
      <app-pencil-button
        class="pencil-button primary medium buttons-container snapshot-button"
        *ngIf="!imageSrc && cameraActive"
        [label]="'Take a Snapshot' | translate"
        [icon]="'photo_camera'"
        [iconConfig]="{ type: IconTypes.materialIcon }"
        (clickEvent)="triggerSnapshot()"
        data-name="upload-tool-selection-camera-snapshot"
      >
      </app-pencil-button>
    </div>
    <!--    Youtube, vimeo and PhET-->
    <div
      *ngIf="
        selectedLib === LibrariesTitles.YOUTUBE ||
        selectedLib === LibrariesTitles.VIMEO ||
        selectedLib === LibrariesTitles.PHET
      "
    >
      <div class="search-box cm-16">
        <input
          type="text"
          [placeholder]="
            selectedLib === LibrariesTitles.YOUTUBE
              ? ('Paste a Youtube URL' | translate)
              : selectedLib === LibrariesTitles.VIMEO
              ? ('Paste a Vimeo URL' | translate)
              : ('Add an url' | translate)
          "
          (input)="loadFileFromURL($event)"
          #addUrlInput
        />
      </div>
    </div>
    <!--    Picasso-->
    <div *ngIf="selectedLib === LibrariesTitles.PICASSO">
      <div class="search-box cm-16 d-flex text-center">
        <textarea
          style="width: 800px"
          [placeholder]="
            'Describe your image. e.g. beautiful large house in the alps in switzerland' | translate
          "
          (keyup.enter)="loadFileFromPicasso()"
          #contextBox
        ></textarea>
        <button
          *ngIf="!loading"
          [disabled]="!contextBox.value.trim()"
          mat-icon-button
          class="header-button"
          (click)="loadFileFromPicasso()"
          ngbTooltip="Generate image"
          data-name="upload-tool-selection-picasso-generate-image"
        >
          <mat-icon class="material-icons color-white">auto_fix_normal</mat-icon>
        </button>
        <mat-spinner
          class="header-button"
          diameter="25"
          color="white"
          *ngIf="loading"
        ></mat-spinner>
      </div>
      <div class="d-flex flex-column align-items-center justify-content-center">
        <img *ngIf="imageSrc" class="cmb-16 w-100 preview-image" [src]="imageSrc" alt="preview" />
      </div>
    </div>

    <!--    The lib Image Search-->
    <div class="images-lib" #imagesList *ngIf="selectedLib === 'Search Images'">
      <!--      Search Form-->
      <div class="d-flex images-search-container cp-16">
        <input
          type="text"
          class="input search-box"
          [placeholder]="'Search Images' | translate"
          [(ngModel)]="searchImagesInput"
          (keyup.enter)="searchForImages()"
        />

        <div class="image-powered-by">
          <span
            >{{ 'Image search powered by ' | translate }}
            <a href="https://www.pexels.com/" target="_blank" rel="noopener"> Pexels </a>
          </span>
        </div>
      </div>

      <!--      Displaying the list of images-->
      <div class="images-list d-flex flex-wrap" *ngIf="(currentImagesList$ | async)?.length">
        <img
          *ngFor="let image of currentImagesList$ | async"
          [src]="image.src.medium"
          class="pexels-img"
          [class.selected]="currentSelectedImage === image?.src?.medium"
          (click)="loadSelectedImage(image.src?.medium)"
          [alt]="image.alt"
        />
      </div>

      <div class="not-found-images" *ngIf="isNoImagesFoundForSearch">
        <p>
          {{ 'No results found' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>

<div [hidden]="selectedLib === LibrariesTitles.COMPUTER">
  <div class="d-flex justify-content-end cp-16 border-top bottom-tool-bar-align">
    <app-pencil-button
      [style.margin-right.px]="8"
      class="pencil-button bottom-bar-button light medium"
      [label]="'Cancel' | translate"
      [icon]="'close'"
      [iconConfig]="{ type: IconTypes.matIcon }"
      (clickEvent)="closeUpload()"
      data-name="upload-tool-cancel"
    >
    </app-pencil-button>
    <div
      class="d-inline"
      [ngbTooltip]="pdfUploadSizeLimitTooltip"
      [disableTooltip]="!pdfUploadMaxSizeTrigger"
    >
      <app-pencil-button
        #uploadButton
        class="pencil-button bottom-bar-button primary medium"
        [label]="
          (isPdfFilesSelected$ | async)
            ? ('Next' | translate)
            : ((selectedLib === LibrariesTitles.PENCIL_FILES ? 'Insert' : 'Upload') | translate)
        "
        [icon]="(isPdfFilesSelected$ | async) ? 'arrow_forward' : 'done'"
        [iconConfig]="{ type: IconTypes.matIcon }"
        [disabled]="(file$ | async) === undefined || pdfUploadMaxSizeTrigger"
        (clickEvent)="uploadOrNextClicked()"
        data-name="upload-tool-upload-insert"
      >
      </app-pencil-button>
    </div>
  </div>
</div>

<ng-template #selectFileContainer>
  <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
    <app-drag-drop-select-file
      *ngIf="(currentUploadStage$ | async) === UploadComputerStage.SelectFile"
      class="w-100 h-100"
      [supportedDocumentTypes]="supportedDocumentTypes"
      [customButtonText]="selectFileText"
      (userSelectedFile)="readFile($event)"
      (importFileSubmit)="uploadButton.clickEvent.emit()"
    >
    </app-drag-drop-select-file>

    <app-upload-pdf-computer
      *ngIf="(currentUploadStage$ | async) === UploadComputerStage.PDFFlow"
      (cancelFlow)="resetComputerFlow()"
      (uploadSubmitted)="closeUpload()"
      (returnToSelectFileStage)="returnToSelectFileStage()"
      class="w-100 h-100"
    ></app-upload-pdf-computer>
  </div>
</ng-template>

<span id="session-table-wrapper">
  <div class="top-bar" [hidden]="!isBreakoutRoomActiveInSession">
    <span class="room-selection-label">
      {{ 'Room' | translate }}
    </span>
    <app-rooms-selection-dropdown
      [activeRooms]="analyticsRooms"
      [archivedRooms]="archivedAnalyticsRooms"
      (selectedRoom)="selectedRoom$.next($event)"
    ></app-rooms-selection-dropdown>
    <div
      class="top-bar-text"
      *ngIf="analyticsRooms.length > 1 || archivedAnalyticsRooms.length > 0"
    >
      <span *ngIf="isRoomArchived(selectedRoom$ | async); else archivedRoomOpen">
        {{ 'This breakout room has been archived' | translate }}
      </span>
      <ng-template #archivedRoomOpen>
        {{ 'This session has activity across several rooms' | translate }}
      </ng-template>
    </div>
  </div>
  <table
    aria-hidden="true"
    mat-table
    matSort
    class="full-width-table w-100"
    id="session-analytics-table"
    [dataSource]="dataSource"
    [ngClass]="{ 'conditional-border': isBreakoutRoomActiveInSession }"
  >
    >
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-name">
        {{ 'Name' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" class="td-name">
        <span class="user-profile-photo">
          <app-lightweight-user-avatar
            [isUser]="!!row.userImgObject"
            [profileImage]="row.userImgObject?.profile_image"
            [userRole]="row.userImgObject?.role"
            [userEmail]="row.userImgObject?.email"
            [userName]="row.userImgObject?.name"
            [width]="30"
          ></app-lightweight-user-avatar>
        </span>
        <p class="user-details">
          {{ row.name }} <br />
          <span class="user-email"> {{ row.email }} </span>
        </p>
      </td>
    </ng-container>

    <!-- Time Joined Column -->
    <ng-container matColumnDef="timeJoined">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Time Joined' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ getTimeString(row.timeJoined) }}</td>
    </ng-container>

    <!-- Time On Call Column -->
    <ng-container matColumnDef="timeOnCall">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Time On Call' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.timeOnCall | translate }}</td>
    </ng-container>

    <!-- Time Left Column -->
    <ng-container matColumnDef="timeLeft">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Time Left' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ getTimeString(row.timeLeft) }}</td>
    </ng-container>

    <!-- Audio Duration Column -->
    <ng-container matColumnDef="audioDuration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'Time with' | translate }} <br />
        {{ 'microphone on' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.audioDuration }}</td>
    </ng-container>

    <!-- Video Duration Column -->
    <ng-container matColumnDef="videoDuration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'Time with' | translate }} <br />
        {{ 'camera on' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.videoDuration }}</td>
    </ng-container>

    <!-- Speech Duration Column -->
    <ng-container matColumnDef="speechDuration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'Speaking Time' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.speechDuration }}</td>
    </ng-container>

    <!-- Raised Hands Count Column -->
    <ng-container matColumnDef="raisedHandCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'Raised Hands Count' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.raisedHandCount }}</td>
    </ng-container>

    <!-- Reactions Count Column -->
    <ng-container matColumnDef="reactionsCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'Reactions Count' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.reactionsCount }}</td>
    </ng-container>

    <!-- Public Message Count Column -->
    <ng-container matColumnDef="spaceCountPublicMessagesSent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'Public Message Count' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.publicMsgCount }}</td>
    </ng-container>

    <!-- Private Message Count Column -->
    <ng-container matColumnDef="spaceCountPrivateMessagesSent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'Private Message Count' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.privateMsgCount }}</td>
    </ng-container>

    <!-- Space Duration Column -->
    <ng-container matColumnDef="userSpaceDuration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'Space Duration' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.userSpaceDuration }}</td>
    </ng-container>

    <!-- Space Background Duration  Column -->
    <ng-container matColumnDef="backgroundDuration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'Space Duration In Background' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.backgroundDuration }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [length]="dataSource?.data?.length"
    [pageIndex]="0"
    [pageSize]="25"
    [hidePageSize]="true"
    aria-label="Select page"
  >
  </mat-paginator>
</span>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, map, take, tap } from 'rxjs';
import { isEqual } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import { SettingsService } from '../../../services/settings.service';
import { ForegroundActivityNotificationsService } from '../../../services/foreground-activity-notifications.service';
import { UpgradePlanEvaluationType } from '../../../services/paywall-indicator.service';

@UntilDestroy()
@Component({
  selector: 'app-user-settings-advanced',
  templateUrl: './user-settings-advanced.component.html',
  styleUrls: ['./user-settings-advanced.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsAdvancedComponent {
  @Input() isMobile = false;
  @Input() PANELS: any;
  @Input() form!: UntypedFormGroup;
  @Output() goBack = new EventEmitter<any>();
  @Output() formChangedEvent: EventEmitter<boolean> = new EventEmitter();

  user?: User;
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  public isLoading$ = this._isLoading$.asObservable();

  settingsForm?: UntypedFormGroup;
  public initialFormValue: unknown;
  public formChanged$ = new BehaviorSubject<boolean>(false);

  private _inactivityNotificationsPaid$ = new BehaviorSubject<boolean>(false);
  public inactivityNotificationsPaid$ = this._inactivityNotificationsPaid$.asObservable();

  public readonly UpgradePlanEvaluationType = UpgradePlanEvaluationType;

  constructor(
    private userService: UserService,
    private settingsService: SettingsService,
    private translateService: TranslateService,
    public foregroundInactivityNotificationsService: ForegroundActivityNotificationsService,
  ) {
    this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
      this.user = res?.user;
      if (res?.user?.subscriptionType) {
        this._inactivityNotificationsPaid$.next(
          this.foregroundInactivityNotificationsService.userPaidForFeature(res.user),
        );
      }
    });
  }

  ngOnInit(): void {
    this.settingsForm = this.form?.controls.settings as UntypedFormGroup;
    this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
      this.user = res?.user;
      this.initForm();
    });
  }

  initForm(): void {
    this.form.patchValue({
      settings: {
        ...(this.user?.settings ?? {}),
        inactivityNotifications:
          // display it as off when it is not paid
          this._inactivityNotificationsPaid$.getValue() &&
          this.user?.settings?.inactivityNotifications,
      },
    });

    this.initialFormValue = this.form.value;

    this.form.valueChanges
      .pipe(
        map(() => !isEqual(this.initialFormValue, this.form.value)),
        tap((x) => this.formChangedEvent.emit(x)),
        untilDestroyed(this),
      )
      .subscribe(this.formChanged$);
  }

  submitChanges(): void {
    if (!this.user || this._isLoading$.getValue()) {
      return;
    }

    this._isLoading$.next(true);

    const updatedUser = {
      ...this.user,
      settings: {
        ...this.user?.settings,
        ...this.form.value.settings,
      },
    };

    // if the option was turned off because it wasn't paid, we don't want to change its value.
    // because this will create a bug where if the user is not subscribed and changed something in the settings
    // the inactivityNotifications options will be turned off.
    if (
      !this._inactivityNotificationsPaid$.getValue() &&
      this.user?.settings?.inactivityNotifications
    ) {
      updatedUser.settings.inactivityNotifications = this.user?.settings.inactivityNotifications;
    }

    this.userService
      .updateUser(updatedUser)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.refresh(updatedUser);
          if (this.isMobile) {
            this.goBack.emit();
          }
        },
        error: () => {
          this.settingsService.showToast(
            {
              title: this.translateService.instant('Update failed'),
              description: this.translateService.instant('An error occurred. Please try again.'),
            },
            true,
          );
        },
        complete: () => {
          this._isLoading$.next(false);
          this.formChangedEvent.emit(false);
        },
      });
  }

  refresh(userPayload: User): void {
    const userFromService = this.userService.user.getValue();
    this.userService.user.next({
      ...userFromService,
      user: userPayload,
    });
    this.settingsService.showToast({
      title: this.translateService.instant('Settings updated'),
      description: this.translateService.instant('Your settings has been successfully updated.'),
    });
  }
}

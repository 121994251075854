<div class="head">
  <div class="title">{{ 'Welcome to Pencil Spaces!' | translate }}</div>
  <div class="subtitle">{{ 'Please tell us a little bit about yourself' | translate }}</div>
</div>
<form [formGroup]="form" class="form-container mat-form-custom" (submit)="handleSubmit()">
  <div class="form-control-row">
    <mat-form-field class="flex-fill" appearance="outline">
      <mat-label>{{ 'Name' | translate }}</mat-label>
      <input
        matInput
        formControlName="name"
        [focus]="!form.value.name"
        [placeholder]="'Please enter your name' | translate"
      />
      <mat-error>
        {{ 'Name cannot be empty' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div
    class="form-control-group questionnaire-form"
    *ngIf="data.showQuestionnaire"
    formGroupName="info"
  >
    <div class="control-group-title">
      {{ 'Are you in Education or Business?' | translate }}
    </div>
    <div class="form-control-row">
      <mat-radio-group color="primary" formControlName="roleType">
        <mat-radio-button [value]="ROLE_TYPE_IDS.education" [disableRipple]="true">
          {{ ROLES.education }}
        </mat-radio-button>
        <mat-radio-button [value]="ROLE_TYPE_IDS.business" [disableRipple]="true">
          {{ ROLES.business }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="form-control-row">
      <mat-form-field appearance="outline">
        <mat-label *ngIf="infoForm?.value.roleType === ROLE_TYPE_IDS.education">
          {{ "What's your role?" | translate }}
        </mat-label>
        <mat-label *ngIf="infoForm?.value.roleType === ROLE_TYPE_IDS.business">
          {{ "What's your industry?" | translate }}
        </mat-label>
        <mat-select
          formControlName="role"
          panelClass="custom-mat-select"
          [disableOptionCentering]="true"
          #roleSelect
        >
          <mat-option *ngFor="let role of ROLE_TYPES[infoForm?.value.roleType]" [value]="role.name">
            {{ role.value }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ 'Please select an option' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-control-row" *ngIf="showRoleOtherInput">
      <mat-form-field appearance="outline">
        <mat-label *ngIf="infoForm?.value.roleType === ROLE_TYPE_IDS.education">
          {{ 'Please specify your role' | translate }}
        </mat-label>
        <mat-label *ngIf="infoForm?.value.roleType === ROLE_TYPE_IDS.business">
          {{ 'Please specify your industry' | translate }}
        </mat-label>
        <input matInput formControlName="roleOther" />
        <mat-error>
          {{ 'Role cannot be empty' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="submit-btn-wrap">
    <button type="submit" [disabled]="form.invalid">
      {{ 'Confirm' | translate }}
      <mat-icon class="material-icons btn-icon" height="20" width="20"> arrow_right_alt </mat-icon>
    </button>
    <div
      class="invalid-tooltip-trigger"
      *ngIf="form.invalid"
      [ngbTooltip]="cantSubmitTooltip"
    ></div>
  </div>
</form>

<ng-template #cantSubmitTooltip>
  <app-tooltip
    *ngIf="form.invalid"
    [text]="'Please complete all required fields' | translate"
    arrow="top"
  ></app-tooltip>
</ng-template>

import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { ColorEvent } from 'ngx-color';
import { Toolbar } from '../../sessions/session/wb-toolbar/containers/toolbar';
import { DrawToolbar } from '../../sessions/session/wb-toolbar/toolbars/draw-toolbar';
import { ToolbarButton } from '../../sessions/session/wb-toolbar/containers/toolbar-button';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPickerComponent {
  @Output() closePicker = new EventEmitter<boolean>();
  @Output() selectPickerComplete = new EventEmitter<string>();
  @Input() currentActiveButtonForColorPicker: ToolbarButton | null = null;
  @Input() disableAlpha = false;
  @Input() toolbar?: Toolbar;
  @Input() currentActiveColor = '#fff';
  @Input() customParentStyle = {};
  @Input() colorFormat: 'hex' | 'rgb' = 'rgb';
  newSelectedColor = '';

  isEyeDropperToolSelected = false;
  isEyeDropperSupported = false;

  constructor() {
    this.isEyeDropperSupported = (window as any).EyeDropper;
  }
  /**
   * handle the color picker color change
   * set the new color as the current active color for object or tool
   * @param $event
   */
  handleColorPickerChangeComplete($event: ColorEvent): void {
    const { r, g, b } = $event.color.rgb;
    let { a } = $event.color.rgb;
    // for highlight tool use only 0.5 opacity ( alpha )
    if (this.toolbar?.name === 'highlight' && !this.currentActiveButtonForColorPicker) {
      a = 0.5;
    }

    // save the color to be used later on confirm
    if (this.colorFormat === 'hex') {
      this.newSelectedColor = $event.color.hex;
    } else {
      this.newSelectedColor = `rgba(${r},${g},${b},${a})`;
    }
  }

  /**
   * if user cancel the new color
   * we just the color to the old color and close the picker
   */
  handleCancelColorPicker(): void {
    this.closePicker.emit(true);
  }

  /**
   * once user done selecting the new color,
   * we just close the picker as the new color will be emitted
   */
  handleDoneSelectingColorFromPicker(): void {
    this.selectPickerComplete.emit(this.newSelectedColor);
  }

  /**
   * event run once user click on the eyeDropper icon
   */
  handleEyeDropperToolClicked(): void {
    this.isEyeDropperToolSelected = !this.isEyeDropperToolSelected;
    if (this.isEyeDropperToolSelected && this.isEyeDropperSupported) {
      const eyeDropper = new (window as any).EyeDropper();
      // open the eyeDropper and listen for changes
      eyeDropper
        .open()
        // res is typed as "any" because the eyedropper api is very new and does not yet have type bindings.
        .then((res: any) => {
          // replace the color alpha value with 1 instead of 0
          this.currentActiveColor = res.sRGBHex.replace(/0\)/g, '1)');
          // reduce opacity by 0.5, for highlighter draw tool
          if (this.toolbar?.name === 'highlight' && !this.currentActiveButtonForColorPicker) {
            // value is hexadecimal start with #, we will add the alpha 50
            this.currentActiveColor = this.currentActiveColor.startsWith('#')
              ? `${this.currentActiveColor}50`
              : this.currentActiveColor.replace(/1\)/g, '0.5)');
          }

          this.newSelectedColor = this.currentActiveColor;

          this.setNewColorForObjectOrTool(this.currentActiveColor);
          this.isEyeDropperToolSelected = false;
        })
        .catch(() => {
          // user could unselect the eyedropper with the `esc` key
          this.isEyeDropperToolSelected = false;
        });
    }
  }

  setNewColorForObjectOrTool(color: string): void {
    if (this.currentActiveButtonForColorPicker) {
      this.currentActiveButtonForColorPicker.popupData?.data.onClick(color);
    } else if (this.toolbar) {
      (this.toolbar as DrawToolbar).changeColor(color);
    }
  }

  closeColorPicker(shouldSetOldColor: boolean): void {
    this.closePicker.emit(shouldSetOldColor);
  }
}

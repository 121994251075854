import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs';

import { noWhitespaceValidator } from '../../common/utils/form-validators';
import { SpacesService } from '../../services/spaces.service';

@Component({
  selector: 'app-create-space-dialog',
  templateUrl: './create-space-dialog.component.html',
  styleUrls: ['./create-space-dialog.component.scss'],
})
export class CreateSpaceDialogComponent {
  form: UntypedFormGroup;
  isLoading = false;

  constructor(
    private dialogRef: MatDialogRef<CreateSpaceDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private spaceService: SpacesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.form = this.formBuilder.group(
      {
        name: [
          data.title || '',
          [Validators.required, noWhitespaceValidator, Validators.maxLength(100)],
        ],
      },
      { updateOn: 'change' },
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.spaceService
      .createSpace(this.form.value.name, { siteId: this.data.siteId,  spaceToCloneId: this.data?.spaceToCloneId })
      .pipe(take(1))
      .subscribe((res) => {
        this.isLoading = false;
        this.dialogRef.close(res);
      });
  }
}

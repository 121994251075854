<div class="failed-message-error-message">
  <div class="failed-message-note">
    <svg-icon
      [hidden]="sending | async"
      [icon]="'error-note'"
      [width]="16"
      [height]="16"
    ></svg-icon>
    <svg-icon
      [hidden]="(sending | async) === false"
      class="spinner__icon"
      [icon]="'simple_spinner'"
      [width]="16"
      [height]="16"
    ></svg-icon>
    {{ 'Not delivered.' | translate }}

    <button
      appThrottleClick
      (throttleClick)="retryAddMessage()"
      [throttleTime]="4000"
      class="send-message-retry"
    >
      {{ 'Try again?' | translate }}
    </button>
  </div>
</div>

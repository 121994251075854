import { Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { DomListenerFactoryService } from '../services/dom-listener-factory.service';
import { DomListener } from '../utilities/DomListener';

@Directive({
  selector: '[appPreventEnter]',
})
export class PreventEnterDirective implements OnInit, OnDestroy {
  domListener: DomListener;

  constructor(
    private el: ElementRef,
    private domListenerFactoryService: DomListenerFactoryService,
  ) {
    this.domListener = this.domListenerFactoryService.createInstance();
  }

  ngOnInit() {
    this.domListener.add(
      this.el.nativeElement,
      'keydown',
      (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      },
      true,
    );
  }

  ngOnDestroy() {
    this.domListener.clear();
  }
}

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IconTypes } from 'src/app/standalones/components/pencil-icon/pencil-icon.component';

@Component({
  selector: 'app-duplicate-space',
  templateUrl: './duplicate-space.component.html',
  styleUrls: ['./duplicate-space.component.scss'],
})
export class DuplicateSpaceComponent {
  constructor(private dialogRef: MatDialogRef<DuplicateSpaceComponent>) {}

  finish(result: boolean): void {
    this.dialogRef.close(result);
  }

  protected readonly IconTypes = IconTypes;
}

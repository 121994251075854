import { User } from './user';

export interface Resource {
  _id: string;
  url: string;
  embedding: string;
  type?: string;
  name?: string;
}

export enum ResourceItemModel {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  SIMULATION = 'SIMULATION',
  DOCUMENT = 'DOCUMENT',
  PDF = 'PDF',
  IFRAME = 'IFRAME',
  WEB_VIEWER = 'BROWSER',
  MARIO = 'MARIO',
  CHAT_GPT = 'CHAT_GPT',
  RemoteVideo = 'REMOTEVIDEO',
  ANY = '*',
}

enum FolderStateEnum {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  HIDDEN = 'HIDDEN',
}
export interface IResource {
  _id: string;
  url: string;
  source: Record<string, any>;
  metadata: Record<string, any>;
  courses: string[];
  owner: User;
  type: ResourceItemModel;
  name: string;
  description: string;
  size: number; // size of file in bytes
  acl: Record<string, any>;
  state: string;
  createdAt: string;
  updatedAt: string;
  folder: string;
  custom_attributes: Record<string, any>;
}

export interface IFolder {
  _id: string;
  parentFolder?: string;
  path: string[];
  name: string;
  owner: User;
  acl: Record<string, any>;
  state: FolderStateEnum;
  institution: string;
  custom_attributes: Record<string, any>;
  protected: boolean;
}

export interface IFolderResponse extends IFolder {
  subdirectories: IFolder[];
  resources: { files: IResource[]; page: number; pages: number; total: number };
  page: number;
  pages: number;
  total: number;
}

export interface IDisplayFile extends IResource {
  xMeta?: Record<string, any>; // any metadata to help in display etc
  altName?: string; // alternative name if no name
  embedding?: any;
}

export interface IDisplayFolder extends IFolder {
  xMeta?: Record<string, any>; // any metadata to help in display etc
}

import { Injectable, NgZone } from '@angular/core';

/*
 * this is a hack to access ngZone without injection
 */
@Injectable({
  providedIn: 'root',
})
export class NgZoneHolderService {
  static ngZone: NgZone;

  constructor(ngZone: NgZone) {
    NgZoneHolderService.ngZone = ngZone;
  }
}

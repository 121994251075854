import { Component, Output, EventEmitter, Input } from '@angular/core';

import { DIFFICULTY } from '../../models/question';

@Component({
  selector: 'app-difficulty-selector',
  templateUrl: './difficulty-selector.component.html',
  styleUrls: ['./difficulty-selector.component.scss']
})
export class DifficultySelectorComponent {
  @Input() title?: string;
  @Input() containers: any;
  @Input() disabled = false;
  
  @Output()
  handleUpdate: EventEmitter<any> = new EventEmitter();

  DIFFICULTY = DIFFICULTY;
}

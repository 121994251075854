/**
 * changes to this file will affect both request access and waiting room.
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorInterceptorSkipHeader } from 'src/app/error.interceptor';
import { URLService } from 'src/app/services/dynamic-url.service';
import { AccessResponseBody } from './request-access-base.service';

export const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
export const headersWithSkipErrorInterceptor = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }).set(
    ErrorInterceptorSkipHeader,
    '',
  ),
};
export const SPACES_URL: string = 'spaces';

@Injectable()
export abstract class RequestAccessClientBaseService {
  protected constructor(protected http: HttpClient, protected urlService: URLService) {}

  abstract sendAccessRequest(sessionID: string): Observable<any>;
  abstract respondToAccessRequest(sessionID: string, body: AccessResponseBody): Observable<any>;
}

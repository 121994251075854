import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum Roles {
  ME = 'ME',
  TEACHERS = 'TEACHERS',
  CLASS = 'CLASS',
  PUBLIC = 'PUBLIC',
}

@Component({
  selector: 'app-visibility-slider',
  templateUrl: './visibility-slider.component.html',
  styleUrls: ['./visibility-slider.component.scss'],
})
export class VisibilitySliderComponent implements OnInit {
  @Input() visibility: string[] = [];
  visibilityIndex = -1;
  roleIndexMapping: { [key: string]: number } = {};
  @Input() selected = '';
  @Input() meDisabled = false;
  @Input() multiSelect = true;
  @Input() deselectable = false;
  @Input() draft = false;

  @Output() selectedEmitter: EventEmitter<string> = new EventEmitter();

  roles = Roles;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  ngOnInit(): void {
    if (!this.visibility.length) {
      this.visibility = ['ME', 'TEACHERS', 'CLASS', 'PUBLIC'];
    }
    this.visibility.forEach((role, index) => {
      this.roleIndexMapping[role] = index;
    });
    this.visibilityIndex = this.roleIndexMapping[this.selected];
  }

  setVisibility(selected: string) {
    if (this.selected === selected) {
      if (this.deselectable) {
        this.selected = '';
        this.visibilityIndex = -1;
        this.selectedEmitter.emit('');
      }
    } else {
      this.selected = selected;
      this.visibilityIndex = this.roleIndexMapping[selected];
      this.selectedEmitter.emit(selected);
    }
  }
}

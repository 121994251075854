import { Injectable } from '@angular/core';
import { ParticipantsManagerEvent } from '../sessions/panel/participants-manager/participants-manager.component';
import { EventCategory, SessionEvent } from '../sessions/session/SessionEvent';
import { modifiedSetTimeout } from '../utilities/ZoneUtils';
import { RealtimeSpaceService } from './realtime-space.service';

@Injectable({
  providedIn: 'root',
})
export class ParticipantsManagerService {
  micRequestSent: { [key: string]: boolean } = {};
  camRequestSent: { [key: string]: boolean } = {};
  turnOffMicSent: { [key: string]: boolean } = {};
  turnOffCamSent: { [key: string]: boolean } = {};

  constructor(private realtimeSpaceService: RealtimeSpaceService) {}

  sendRequest(device: string, userId: string): void {
    const event = new SessionEvent(
      EventCategory.ParticipantsManager,
      ParticipantsManagerEvent.TURN_ON_REQUEST,
      { device, userId },
    );
    this.realtimeSpaceService.service.sendEvent(this, event);
    if (device === 'mic') {
      this.micRequestSent[userId] = true;
      modifiedSetTimeout(() => {
        this.micRequestSent[userId] = false;
      }, 5000);
    } else {
      this.camRequestSent[userId] = true;
      modifiedSetTimeout(() => {
        this.camRequestSent[userId] = false;
      }, 5000);
    }
  }

  turnOffDeviceForParticipant(device: string, userId: string): void {
    const event = new SessionEvent(
      EventCategory.ParticipantsManager,
      ParticipantsManagerEvent.TURNOFF,
      { device, userId },
    );
    this.realtimeSpaceService.service.sendEvent(this, event);
    if (device === 'mic') {
      this.turnOffMicSent[userId] = true;
      modifiedSetTimeout(() => {
        this.turnOffMicSent[userId] = false;
      }, 5000);
    } else {
      this.turnOffCamSent[userId] = true;
      modifiedSetTimeout(() => {
        this.turnOffCamSent[userId] = false;
      }, 5000);
    }
  }
}

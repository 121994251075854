import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { TelemetryService } from 'src/app/services/telemetry.service';
import { SpaceRepository } from 'src/app/state/space.repository';
import { GlobalPermissionsService } from 'src/app/services/global-permissions.service';
import { Course, User } from '../../models/user';
import { CollaborativeApps } from '../session/iframe/additional-apps.utils';

export enum PanelView {
  parallelSessions = 'parallelSessions',
  lhsQuestions = 'lhsQuestions',
  chat = 'chat',
  participantsManager = 'participantsManager',
  tabs = 'tabs',
  iFrame = 'iframe',
  browser = 'browser',
  appSelector = 'appSelector',
  mathManipulatives = 'mathManipulatives',
  comments = 'comments',
  phetSimulations = 'phetSimulations',
  bioDigitalModels = 'bioDigitalModels',
  uploadFile = 'uploadFile',
  sparky = 'sparky',
}

export enum PanelDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

const panelsOpenedByDefault = [PanelView.tabs];

@UntilDestroy()
@Component({
  selector: 'app-sessions-panel[panelView][user]',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelComponent {
  currentPanelView?: PanelView;
  @Input() set panelView(value: { panelView: PanelView; panelExpanded: boolean }) {
    value = value ?? {};
    this.currentPanelView = value.panelView;
    this.updateAlreadyOpenedMap(value.panelView);
    this.tabsVisible$.next(value.panelView === PanelView.tabs && value.panelExpanded);
    this.setSessionVars(value.panelView);
  }
  @Input() direction?: PanelDirection;
  @Input() user?: User;
  @Input() isMobile = false;
  @Output() changePanelView = new EventEmitter<PanelView>();

  isHost: boolean = false;

  PanelDirectionType = PanelDirection;
  panelViewType = PanelView;
  CollaborativeApps = CollaborativeApps;

  alreadyLoadedPanel = new BehaviorSubject(
    Object.keys(PanelView).reduce(
      (prev, curr) => ({ ...prev, [curr]: panelsOpenedByDefault.includes(curr as PanelView) }),
      {} as { [key in PanelView]: boolean },
    ),
  );

  getAlreadyLoadedPanel$(value: PanelView): Observable<boolean> {
    return this.alreadyLoadedPanel.asObservable().pipe(map((object) => object[value]));
  }
  alreadyLoadedPanel$ = Object.keys(PanelView).reduce(
    (prev, curr) => ({ ...prev, [curr]: this.getAlreadyLoadedPanel$(curr as PanelView) }),
    {} as { [key in PanelView]: Observable<boolean> },
  );

  canSendMessage$ = this.globalPermissionsService.canSendMessage$;
  readonly tabsVisible$ = new BehaviorSubject<boolean>(false);

  constructor(
    private globalPermissionsService: GlobalPermissionsService,
    private telemetry: TelemetryService,
    spaceRepo: SpaceRepository,
  ) {
    spaceRepo.isCurrentUserHost$.pipe(untilDestroyed(this)).subscribe((val) => {
      this.isHost = val;
    });
  }

  changeView(panelView?: PanelView): void {
    this.changePanelView.emit(panelView);
  }

  get courses(): Course[] {
    return this.user?.courses || [];
  }

  updateAlreadyOpenedMap(value: PanelView): void {
    const panelsMap = this.alreadyLoadedPanel.getValue();

    if (!panelsMap[value]) {
      this.alreadyLoadedPanel.next({
        ...panelsMap,
        [value]: true,
      });
    }
  }

  private setSessionVars(value: PanelView): void {
    if (this.direction === PanelDirection.LEFT) {
      this.telemetry.setSessionVars({
        app_panel_open: value === PanelView.appSelector,
        board_panel_open: value === PanelView.tabs,
        chat_panel_chat_is_open: value === PanelView.chat,
      });

      if (!value || value !== PanelView.chat) {
        this.telemetry.setSessionVars({
          chat_panel_all_chats_open: false,
        });
      }

      return;
    }

    if (this.direction === PanelDirection.RIGHT) {
      this.telemetry.setSessionVars({
        participant_manager_panel_open: value === PanelView.participantsManager,
      });
    }
  }
}

import { NgModule } from '@angular/core';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GadgetHeaderModule } from '../gadget-header/gadget-header.module';
import { PeriodicTableComponent } from './periodic-table.component';

@NgModule({
  declarations: [PeriodicTableComponent],
  exports: [PeriodicTableComponent],
  imports: [DragDropModule, CommonModule, TranslateModule, GadgetHeaderModule],
})
export class PeriodicTableModule {}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OnlineStatus, UserRole } from 'src/app/models/user';
@UntilDestroy()
@Component({
  selector: 'app-lightweight-user-avatar',
  templateUrl: './lightweight-user-avatar.component.html',
  styleUrls: ['./lightweight-user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightweightUserAvatarComponent {
  @Input() width?: number;
  @Input() fontSize?: number;
  @Input() fontWeight?: number;
  @Input() fontColor?: string;
  @Input() highlightedText = '';
  @Input() showStatus?: boolean;
  @Input() userStatus?: OnlineStatus;
  @Input() opaqueOverlay = false;
  @Input() showName = false;
  @Input() showYouWord = false;
  @Input() nameMaxWidth?: number;
  @Input() imgScale = 1;
  @Input() enableImageBorder = false;
  @Input() parentDisplay?: string;
  @Input() showAsChip = false;
  @Input() imgBorder = false;
  @Input() showHostTag = false;
  @Input() isUser: boolean = false;
  @Input() profileImage?: string;
  @Input() userRole?: string;
  @Input() userEmail?: string;
  @Input() userName?: string;
  @Input() isInstitution: boolean = false; // When true, institution name and image will be shown if !user
  @Input() institutionLogo?: string;
  @Input() institutionName?: string;

  OnlineStatusEnum = OnlineStatus;
  UserRoleEnum = UserRole;

  /** Keep this component lightweight without service injections
   * It only shows user data with no added functionality
   * For more functions use `ProfilePhotoComponent`
   */
}

enum DailyMeetingState {
  LOADING = 'loading',
  LOADED = 'loaded',
  JOINING = 'joining-meeting',
  JOINED = 'joined-meeting',
  LEFT = 'left-meeting',
  ERROR = 'error',
  NON_FATAL_ERROR = 'nonfatal-error',
}

enum DailyParticipantEvents {
  JOINED = 'participant-joined',
  UPDATED = 'participant-updated',
  LEFT = 'participant-left',
}

enum DailyParticipantMessageEvents {
  APP_MESSAGE = 'app-message',
}

enum DailyAppMessageFromIdTypes {
  TRANSCRIPTION = 'transcription',
}

enum DailyLocalParticipantEvents {
  JOINED = 'joined-meeting',
  LEFT = 'left-meeting',
}

enum DailyMeetingEvents {
  ACTIVE_SPEAKER_CHANGE = 'active-speaker-change',
  LOCAL_SCREEN_SHARE_STOPPED = 'local-screen-share-stopped',
}

enum DailyTrackEvents {
  START = 'track-started',
  STOP = 'track-stopped',
}

enum DailyNetworkEvents {
  QUALITY_CHANGED = 'network-quality-change',
  NETWORK_CONNECTION = 'network-connection',
}

enum DailyLocalParticipantKey {
  LOCAL = 'local',
}

enum DailyErrorMsgs {
  SINGALING_CONNECTION_FAILED = 'signaling connection failed',
  MEETING_DOES_NOT_EXIST = "The meeting you're trying to join does not exist.",
}

enum DailyErrorsTypes {
  ERROR_EXP_ROOM_TYPE = 'exp-room',
  TIMED_OUT = 'timed out',
  FAILED_LOADING_BUNDLE = 'failed to load call object bundle',
}

enum DailyNonFatalErrors {
  SCREEN_SHARE_ERROR = 'screen-share-error',
  AUDIO_PROCESSOR_ERROR = 'audio-processor-error',
}

enum DailyTranscriptionEvents {
  TRANSCRIPTION_STARTED = 'transcription-started',
  TRANSCRIPTION_STOPPED = 'transcription-stopped',
  TRANSCRIPTION_ERROR = 'transcription-error',
}

enum DailyRecordingEvents {
  RECORDING_STARTED = 'recording-started',
  RECORDING_STOPPED = 'recording-stopped',
  RECORDING_ERROR = 'recording-error',
  RECORDING_DATA = 'recording-data',
  RECORDING_UPLOAD_COMPLETED = 'recording-upload-completed',
}

enum DailyRecordingErrors {
  IDLE_TIMEOUT = 'idle-timeout',
}

export {
  DailyMeetingState,
  DailyParticipantEvents,
  DailyTrackEvents,
  DailyNetworkEvents,
  DailyLocalParticipantKey,
  DailyErrorMsgs,
  DailyNonFatalErrors,
  DailyLocalParticipantEvents,
  DailyErrorsTypes,
  DailyMeetingEvents,
  DailyTranscriptionEvents,
  DailyParticipantMessageEvents,
  DailyAppMessageFromIdTypes,
  DailyRecordingEvents,
  DailyRecordingErrors,
};

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-change-language-confirmation-dialog',
  templateUrl: './change-language-confirmation-dialog.component.html',
  styleUrls: ['./change-language-confirmation-dialog.component.scss'],
})
export class ChangeLanguageConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ChangeLanguageConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      current: {
        code: string;
        originalLabel?: string;
        label: string;
        rtl: boolean;
      };
      target: {
        code: string;
        originalLabel?: string;
        label: string;
        rtl: boolean;
      };
    },
    public userService: UserService,
    private translate: TranslateService,
  ) {}

  get message(): string {
    return this.translate.instant(
      `Are you sure you want to change your language on Pencil from <strong>${this.data.current.label} / ${this.data.current.originalLabel}</strong> to <strong>${this.data.target.label} / ${this.data.target.originalLabel}</strong> ?`,
    );
  }
}

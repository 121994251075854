import { fabric } from 'fabric';
import { Control } from 'fabric/fabric-impl';
import { chunk } from 'lodash-es';
import { Observable, fromEvent } from 'rxjs';
import { TelemetryService } from 'src/app/services/telemetry.service';
import * as _ from 'lodash';
import { BaseCustomFabric } from '../custom-fabric-objects/base-custom-fabric';
import { RelatedIText, STICKY_TEMP_TEXT } from '../custom-fabric-objects/sticky-note';

interface fabricInitializeOptions {
  objectLockFeatureEnabled?: boolean;
  telemetryService?: TelemetryService;
}

export interface ObjectAbsoluteCoords {
  tl_x: number;
  tl_y: number;
  br_x: number;
  br_y: number;
}

export const fabricObjectsControlsNames = ['tr', 'tl', 'br', 'bl', 'mt', 'mb', 'ml', 'mr', 'mtr'];

let telemetry: TelemetryService | null = null;

export function initialize(mobileView: boolean, initOptions: fabricInitializeOptions = {}): void {
  // ---- Fabric JS Performance Optimizations -----
  (fabric.Canvas.prototype as any)._renderObjects = function (
    ctx: CanvasRenderingContext2D,
    objects: fabric.Object[],
  ) {
    for (let i = 0; i < objects.length; i++) {
      objects[i]?.render(ctx);
    }
  };

  // Optimization: Remove the call to save / restore before and after these functions run
  //               as the loop that runs render will call that before any particular object is rendered
  fabric.Object.prototype._renderFill = function (ctx: CanvasRenderingContext2D) {
    if (!this.fill) {
      return;
    }
    (this as any)._setFillStyles(ctx, this);
    if (this.fillRule === 'evenodd') {
      ctx.fill('evenodd');
    } else {
      ctx.fill();
    }
  };

  fabric.Object.prototype._renderStroke = function (ctx: CanvasRenderingContext2D) {
    if (!this.stroke || this.strokeWidth === 0) {
      return;
    }
    if (this.shadow && !(this.shadow as any).affectStroke) {
      this._removeShadow(ctx);
    }
    if (this.strokeUniform && this.group) {
      const scaling = this.getObjectScaling();
      ctx.scale(1 / scaling.scaleX, 1 / scaling.scaleY);
    } else if (this.strokeUniform) {
      ctx.scale(1 / this.scaleX!, 1 / this.scaleY!);
    }
    this._setLineDash(ctx, this.strokeDashArray!);
    (this as any)._setStrokeStyles(ctx, this);
    ctx.stroke();
  };

  // Optimization: If the two objects being checked for intersection are rectangles (common-case) then we can highly optimize the
  //               intersection calculation by doing a simple rectangle intersection check
  (fabric.Object.prototype.intersectsWithRect = function (
    pointTL: fabric.Point,
    pointBR: fabric.Point,
    absolute: boolean,
    calculate: boolean,
  ) {
    const coords = this.getCoords(absolute, calculate);
    if (isRectangle(coords[0], coords[1], coords[2], coords[3])) {
      return doRectanglesIntersectOnly(pointTL, pointBR, coords[0], coords[2]);
    }

    const intersection = fabric.Intersection.intersectPolygonRectangle(coords, pointTL, pointBR);
    return intersection.status === 'Intersection';
  }),
    // Optimization: Remove the call to setCoords for all objects because the sessions-vpt service will do it once the VPT value becomes stable
    (fabric.StaticCanvas.prototype.setViewportTransform = function (vpt: number[]) {
      const activeObject = this._activeObject;
      const backgroundObject = this.backgroundImage as any;
      const overlayObject = this.overlayImage;

      this.viewportTransform = vpt;
      if (activeObject) {
        activeObject.setCoords();
      }
      if (backgroundObject) {
        backgroundObject.setCoords(true);
      }
      if (overlayObject) {
        overlayObject.setCoords(true);
      }
      this.calcViewportBoundaries();
      this.renderOnAddRemove && this.requestRenderAll();
      return this as any;
    });

  // Optimization: Remove the caching logic for calcTransformMatrix as its slower than doing the math
  (fabric.Object.prototype.calcTransformMatrix = function (skipGroup: boolean) {
    let matrix = this.calcOwnMatrix();
    if (skipGroup || !this.group) {
      return matrix;
    }
    if (this.group) {
      matrix = fabric.util.multiplyTransformMatrices(this.group.calcTransformMatrix(false), matrix);
    }

    return matrix;
  }),
    (fabric.Object.prototype.calcOwnMatrix = function (this, ...args) {
      const calcTranslateMatrix = (fabric.Object.prototype as any)
        ._calcTranslateMatrix as () => number[];
      const tMatrix = calcTranslateMatrix.bind(this)();
      const options = {
        angle: this.angle,
        translateX: tMatrix[4],
        translateY: tMatrix[5],
        scaleX: this.scaleX,
        scaleY: this.scaleY,
        skewX: this.skewX,
        skewY: this.skewY,
        flipX: this.flipX,
        flipY: this.flipY,
      };
      return fabric.util.composeMatrix(options as any);
    });

  telemetry = initOptions.telemetryService ?? null;
  // don't include default values to reduce the size of serialized Fabric object
  fabric.Object.prototype.includeDefaultValues = false;
  fabric.Object.prototype.hasBorders = true;
  fabric.Object.prototype.cornerColor = '#bbd8ff';
  fabric.Object.prototype.borderColor = '#0082f5';
  fabric.Object.prototype.cornerStrokeColor = '#0082f5';
  fabric.Object.prototype.transparentCorners = false;
  fabric.Object.prototype.objectCaching = false;
  fabric.Object.prototype.cornerSize = 8;
  fabric.Object.prototype.cornerStyle = 'circle';
  fabric.Object.prototype.perPixelTargetFind = true;
  // disable the rotation.
  fabric.Object.prototype.rotatingPointOffset = 4;
  fabric.Textbox.prototype.setControlsVisibility({
    tr: false,
    bl: false,
    ml: true,
    mt: false,
    mr: true,
    mb: false,
    mtr: false,
    tl: false,
    br: false,
  });
  fabric.IText.prototype.setControlsVisibility({
    tr: false,
    bl: false,
    ml: false,
    mt: false,
    mr: false,
    mb: false,
    mtr: false,
    tl: false,
    br: false,
  });
  fabric.Line.prototype.setControlsVisibility({
    tr: true,
    bl: true,
    ml: false,
    mt: false,
    mr: false,
    mb: false,
    mtr: false,
    tl: false,
    br: false,
  });
  fabric.Line.prototype.borderColor = 'transparent';

  fabric.Object.prototype.onSelect = function (options): boolean {
    if (
      (this.canvas as YCanvas)?.isCanvasLockedForUser &&
      !(this.canvas as YCanvas)?.canvasItemsInteractionsEnabled
    ) {
      return true;
    }
    if (!initOptions.objectLockFeatureEnabled) {
      return false;
    }

    const isLocked = Boolean((this as any).get('locked'));

    if (BaseCustomFabric.isCustomFabricObject(this)) {
      const customObject = this as BaseCustomFabric;
      isLocked ? customObject.lockObject() : customObject.unlockObject();
      return false;
    }

    toggleLockObject(this, isLocked);

    return false;
  };

  /**
   * override Textbox toObject for now,
   * there are some bugs and issues with stylesToArray function that is used in Fabric 5
   * it doesn't compare styles as expected, also there are some bugs on it when using undefined object
   * check the issues here for reference
   * https://github.com/fabricjs/fabric.js/pull/8357
   * https://github.com/fabricjs/fabric.js/pull/8365
   * @param propertiesToInclude
   */
  fabric.Text.prototype.toObject = function (propertiesToInclude: string[]) {
    const additionalProps = (
      'fontFamily fontWeight fontSize text underline minWidth overline linethrough' +
      ' textAlign fontStyle lineHeight textBackgroundColor charSpacing styles' +
      ' direction path pathStartOffset pathSide pathAlign splitByGrapheme'
    ).split(' ');
    const allProperties = additionalProps.concat(propertiesToInclude);
    const obj = fabric.Object.prototype.toObject.call(this, allProperties);
    obj.styles = (fabric.util.object as any).clone((this as any).styles, true);
    return obj;
  };

  const textBoxSplitTextIntoLines = (fabric.Textbox.prototype as any)._splitTextIntoLines;
  (fabric.Textbox.prototype as any)._splitTextIntoLines = function (text: string): any {
    return textBoxSplitTextIntoLines.call(this, text || '');
  };

  fabric.Line.prototype.drawBorders = function (ctx: CanvasRenderingContext2D): any {
    const { flipX, flipY, y1, y2, x1, x2 } = this;
    const zoom = this.canvas!.getZoom();

    const isLeftToRight = x1! <= x2!;
    const isBottomToTop = y1! >= y2!;
    const directionCondition =
      (isLeftToRight === isBottomToTop && flipX === flipY) ||
      (isLeftToRight !== isBottomToTop && flipX !== flipY);
    const origin = this.getCenterPoint();
    const pointA = directionCondition
      ? this.getPointByOrigin('left', 'bottom')
      : this.getPointByOrigin('left', 'top');
    const pointB = directionCondition
      ? this.getPointByOrigin('right', 'top')
      : this.getPointByOrigin('right', 'bottom');

    ctx.strokeStyle = '#0082f5';
    ctx.beginPath();
    ctx.moveTo((origin.x - pointA.x) * zoom, (origin.y - pointA.y) * zoom);
    ctx.lineTo((origin.x - pointB.x) * zoom, (origin.y - pointB.y) * zoom);
    ctx.closePath();
    ctx.stroke();

    this.setControlsVisibility({
      tr: directionCondition,
      tl: !directionCondition,
      bl: directionCondition,
      br: !directionCondition,
    });
  };

  const toggleLockObject = function (object: fabric.Object, isLocked: boolean): void {
    const isTextBox = object.type === 'textbox';
    // preserve the state of the object in unlocked state in 'on_unlocked_state' attribute
    // so when we unlock the object later
    let onUnlockedState = (object as any).get('on_unlocked_state');
    if (!onUnlockedState) {
      onUnlockedState = {
        lockMovementX: object.lockMovementX,
        lockMovementY: object.lockMovementY,
        lockRotation: object.lockRotation,
        lockScalingFlip: object.lockScalingFlip,
        lockScalingX: object.lockScalingX,
        lockScalingY: object.lockScalingY,
        lockSkewingX: object.lockSkewingX,
        lockSkewingY: object.lockSkewingY,
        lockUniScaling: object.lockUniScaling,
        controls: object.controls,
      };

      if (isTextBox) {
        onUnlockedState.editable = (object as fabric.Textbox).editable;
      }
      (object as any).set('on_unlocked_state', onUnlockedState);
    }

    if (isLocked) {
      object.lockMovementX = true;
      object.lockMovementY = true;
      object.lockRotation = true;
      object.lockScalingFlip = true;
      object.lockScalingX = true;
      object.lockMovementY = true;
      object.lockSkewingX = true;
      object.lockSkewingY = true;
      object.lockUniScaling = true;
      object.controls = {};
      object.hoverCursor = 'pointer';
    } else {
      // unlocking the object
      object.set(onUnlockedState);
      object.hoverCursor = undefined;
    }

    // prevent editing if it's a textbox
    if (isTextBox) {
      (object as fabric.Textbox).editable = !isLocked;
    }
  };

  // enable only corner controls
  fabric.Line.prototype.controls = Object.keys(fabric.Line.prototype.controls)
    .filter((key) => ['tr', 'tl', 'br', 'bl'].includes(key))
    .map((controlKey) => {
      const control = fabric.Line.prototype.controls[controlKey];
      // change control style
      control.cursorStyleHandler = (
        event: MouseEvent,
        ctrl: fabric.Control,
        object: fabric.Object,
      ): string => {
        if (object.type === 'line') {
          return 'crosshair';
        }
        return ['tl', 'br'].includes(controlKey) ? 'nwse-resize' : 'nesw-resize';
      };

      return { [controlKey]: control };
    })
    .reduce((prev, curr) => ({ ...prev, ...curr }), {});

  initializeRotationControllers();
  setControlsVisibility(mobileView);
  initializeScalingControllers();
}

export function setControlsVisibility(mobileView: boolean): void {
  const controls = ['rotBL1', 'rotBL2', 'rotBR1', 'rotBR2', 'rotTL1', 'rotTL2', 'rotTR1', 'rotTR2'];
  if (mobileView) {
    if (fabric.Object.prototype.controls.mobRot) {
      fabric.Object.prototype.controls.mobRot.visible = true;
    }
    if (fabric.IText.prototype.controls.mobRot) {
      fabric.IText.prototype.controls.mobRot.visible = true;
    }
    if (fabric.Textbox.prototype.controls.mobRot) {
      fabric.Textbox.prototype.controls.mobRot.visible = true;
    }
    controls.forEach((control) => {
      if (fabric.Object.prototype.controls[control]) {
        fabric.Object.prototype.controls[control].visible = false;
      }
      if (fabric.IText.prototype.controls[control]) {
        fabric.IText.prototype.controls[control].visible = false;
      }
      if (fabric.Textbox.prototype.controls[control]) {
        fabric.Textbox.prototype.controls[control].visible = false;
      }
    });
  } else {
    if (fabric.Object.prototype.controls.mobRot) {
      fabric.Object.prototype.controls.mobRot.visible = false;
    }
    if (fabric.IText.prototype.controls.mobRot) {
      fabric.IText.prototype.controls.mobRot.visible = false;
    }
    controls.forEach((control) => {
      fabric.Object.prototype.controls[control].visible = true;
      fabric.IText.prototype.controls[control].visible = true;
      fabric.Textbox.prototype.controls[control].visible = true;
    });
  }
}
function initializeRotationControllers(): void {
  const rotateImg = document.createElement('img');
  rotateImg.src = '../../assets/icons/rotation_cursor_0.svg';
  // Rotation custom Controls.
  /**
   * Used to draw a rectangle for new custom rotation corners
   * each corner needs two perpendicular rectangles.
   */
  function renderRectControl(this, ctx, left, top, styleOverride, fabricObject) {
    const xSize = this.sizeX || 0;
    const ySize = this.sizeY || 0;
    ctx.save();
    ctx.fillStyle = 'transparent';
    ctx.strokeStyle = 'transparent';
    ctx.lineWidth = 1;
    ctx.translate(left, top);
    ctx.rotate(((fabricObject.angle ?? 0) * Math.PI) / 180);
    ctx.strokeRect(-xSize / 2, -ySize / 2, xSize, ySize);
    ctx.restore();
  }

  function renderIcon(icon) {
    return function renderIconImg(this, ctx, left, top, styleOverride, fabricObject) {
      const size = this.sizeX;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(icon, -size / 2, -size / 2, size, size);
      ctx.restore();
    };
  }

  /**
   * Used to set custom style to hover cursor of controls depending on object rotation.
   */
  function cursorStyleHandler(eventData: MouseEvent, control: Control, fabricObject: any): string {
    let controlAngle = 0;
    if (control.y === -0.5 && control.x === -0.5) {
      controlAngle = 90;
    } else if (control.y === -0.5 && control.x === 0.5) {
      controlAngle = 180;
    } else if (control.y === 0.5 && control.x === 0.5) {
      controlAngle = 270;
    }
    const quarter = Math.floor(((fabricObject.angle + controlAngle + 360 + 45) % 360) / 90) * 90;
    return `url(../../assets/icons/rotation_cursor_${quarter}.svg) 10.5 11, crosshair`;
  }

  // disable middle top rotation control.
  fabric.Object.prototype.controls.mtr.setVisibility(false);

  // Bottom left rotation controller.
  fabric.Object.prototype.controls.mobRot =
    fabric.IText.prototype.controls.mobRot =
    fabric.Textbox.prototype.controls.mobRot =
      new fabric.Control({
        x: -0.5,
        y: 0.5,
        offsetX: -20,
        offsetY: 20,
        actionHandler: fabric.Object.prototype.controls.mtr.actionHandler,
        cursorStyleHandler: cursorStyleHandler,
        withConnection: false,
        actionName: 'rotate',
        sizeX: 24,
        sizeY: 32,
        render: renderIcon(rotateImg),
      });

  fabric.Object.prototype.controls.rotBL1 =
    fabric.IText.prototype.controls.rotBL1 =
    fabric.Textbox.prototype.controls.rotBL1 =
      new fabric.Control({
        x: -0.5,
        y: 0.5,
        offsetX: -12,
        actionHandler: fabric.Object.prototype.controls.mtr.actionHandler,
        cursorStyleHandler: cursorStyleHandler,
        withConnection: false,
        actionName: 'rotate',
        sizeX: 8,
        sizeY: 32,
        render: renderRectControl,
      });

  fabric.Object.prototype.controls.rotBL2 =
    fabric.IText.prototype.controls.rotBL2 =
    fabric.Textbox.prototype.controls.rotBL2 =
      new fabric.Control({
        x: -0.5,
        y: 0.5,
        offsetY: 12,
        actionHandler: fabric.Object.prototype.controls.mtr.actionHandler,
        cursorStyleHandler: cursorStyleHandler,
        withConnection: false,
        actionName: 'rotate',
        sizeX: 32,
        sizeY: 8,
        render: renderRectControl,
      });

  // Bottom right rotation controller.
  fabric.Object.prototype.controls.rotBR1 =
    fabric.IText.prototype.controls.rotBR1 =
    fabric.Textbox.prototype.controls.rotBR1 =
      new fabric.Control({
        x: 0.5,
        y: 0.5,
        offsetX: 12,
        actionHandler: fabric.Object.prototype.controls.mtr.actionHandler,
        cursorStyleHandler: cursorStyleHandler,
        withConnection: false,
        actionName: 'rotate',
        sizeX: 8,
        sizeY: 32,
        render: renderRectControl,
      });

  fabric.Object.prototype.controls.rotBR2 =
    fabric.IText.prototype.controls.rotBR2 =
    fabric.Textbox.prototype.controls.rotBR2 =
      new fabric.Control({
        x: 0.5,
        y: 0.5,
        offsetY: 12,
        actionHandler: fabric.Object.prototype.controls.mtr.actionHandler,
        cursorStyleHandler: cursorStyleHandler,
        withConnection: false,
        actionName: 'rotate',
        sizeX: 32,
        sizeY: 8,
        render: renderRectControl,
      });

  // Top left rotation controller.
  fabric.Object.prototype.controls.rotTL1 =
    fabric.IText.prototype.controls.rotTL1 =
    fabric.Textbox.prototype.controls.rotTL1 =
      new fabric.Control({
        x: -0.5,
        y: -0.5,
        offsetX: -12,
        actionHandler: fabric.Object.prototype.controls.mtr.actionHandler,
        cursorStyleHandler: cursorStyleHandler,
        withConnection: false,
        actionName: 'rotate',
        sizeX: 8,
        sizeY: 32,
        render: renderRectControl,
      });

  fabric.Object.prototype.controls.rotTL2 =
    fabric.IText.prototype.controls.rotTL2 =
    fabric.Textbox.prototype.controls.rotTL2 =
      new fabric.Control({
        x: -0.5,
        y: -0.5,
        offsetY: -12,
        actionHandler: fabric.Object.prototype.controls.mtr.actionHandler,
        cursorStyleHandler: cursorStyleHandler,
        withConnection: false,
        actionName: 'rotate',
        sizeX: 32,
        sizeY: 8,
        render: renderRectControl,
      });

  // Top right rotation controller.
  fabric.Object.prototype.controls.rotTR1 =
    fabric.IText.prototype.controls.rotTR1 =
    fabric.Textbox.prototype.controls.rotTR1 =
      new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetX: 12,
        actionHandler: fabric.Object.prototype.controls.mtr.actionHandler,
        cursorStyleHandler: cursorStyleHandler,
        withConnection: false,
        actionName: 'rotate',
        sizeX: 8,
        sizeY: 32,
        render: renderRectControl,
      });

  fabric.Object.prototype.controls.rotTR2 =
    fabric.IText.prototype.controls.rotTR2 =
    fabric.Textbox.prototype.controls.rotTR2 =
      new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: -12,
        actionHandler: fabric.Object.prototype.controls.mtr.actionHandler,
        cursorStyleHandler: cursorStyleHandler,
        withConnection: false,
        actionName: 'rotate',
        sizeX: 32,
        sizeY: 8,
        render: renderRectControl,
      });
}

function initializeScalingControllers(): void {
  function renderRectControl(this, ctx, left, top, styleOverride, fabricObject: fabric.Object) {
    let xSize = 8;
    let ySize = 8;
    if (this.x === 0) {
      xSize = fabricObject.getBoundingRect().width;
    } else {
      ySize = fabricObject.getBoundingRect().height;
    }
    this.sizeX = xSize;
    this.sizeY = ySize;
    ctx.save();
    ctx.fillStyle = 'transparent';
    ctx.strokeStyle = 'transparent';
    ctx.lineWidth = 1;
    ctx.translate(left, top);
    ctx.rotate(((fabricObject.angle ?? 0) * Math.PI) / 180);
    ctx.strokeRect(-xSize / 2, -ySize / 2, xSize, ySize);
    ctx.restore();

    if (fabricObject) {
      ctx.beginPath();
      ctx.fillStyle = '#bbd8ff';
      ctx.strokeStyle = '#0082f5';
      ctx.lineWidth = 1;
      ctx.arc(left - this.offsetX, top, 4, 0, 2 * Math.PI);
      ctx.fill();
      ctx.stroke();
    }
  }

  // left side
  fabric.IText.prototype.controls.ml = new fabric.Control({
    x: -0.5,
    y: 0,
    offsetX: -4,
    actionHandler: fabric.IText.prototype.controls.ml.actionHandler,
    cursorStyleHandler: fabric.IText.prototype.controls.ml.cursorStyleHandler,
    withConnection: false,
    render: renderRectControl,
  });
  // right side
  fabric.IText.prototype.controls.mr = new fabric.Control({
    x: 0.5,
    y: 0,
    offsetX: 4,
    actionHandler: fabric.IText.prototype.controls.mr.actionHandler,
    cursorStyleHandler: fabric.IText.prototype.controls.mr.cursorStyleHandler,
    withConnection: false,
    render: renderRectControl,
  });

  /*
    Currently only commented out incase UX wants to's to revert this change
  */
  // top side
  /* fabric.Object.prototype.controls.mt = new fabric.Control({
    x: 0,
    y: -0.5,
    offsetY: -4,
    actionHandler: fabric.Object.prototype.controls.mt.actionHandler,
    cursorStyleHandler: fabric.Object.prototype.controls.mt.cursorStyleHandler,
    withConnection: false,
    render: renderRectControl,
  });*/
  // bottom side
  /* fabric.Object.prototype.controls.mb = new fabric.Control({
    x: 0,
    y: 0.5,
    offsetY: 4,
    actionHandler: fabric.Object.prototype.controls.mb.actionHandler,
    cursorStyleHandler: fabric.Object.prototype.controls.mb.cursorStyleHandler,
    withConnection: false,
    render: renderRectControl,
  });*/
}

// General constructor for mixins with specified classes
type GConstructor<T> = new (...args: any[]) => T;
// Constructor for mixin
type Canvasable = GConstructor<fabric.StaticCanvas>;

// Function to use as decorator for the mixin
const Updatable = <TBase extends Canvasable>(target: TBase) =>
  class YCanvasMixin extends target {
    OBJECTS_BATCH_MAX_SIZE = 20000;

    public updateObjects(uids: Set<string>, localObjects: fabric.Object[], remoteObjects: any[]) {
      const objectsModifiedWithIdx: { localIdx: number; remoteObject: any }[] = [];
      const objectsRemoved: any[] = [];
      const objectsAdded: any[] = [];

      const uidSet = new Set(uids);

      const localIdxs = this.createUidMap(uidSet, localObjects);
      const remoteIdxs = this.createUidMap(uidSet, remoteObjects);

      for (const uid of uids) {
        const localIdx = localIdxs[uid] ?? -1;
        const remoteIdx = remoteIdxs[uid] ?? -1;

        const remoteObject = remoteObjects[remoteIdx];

        if (localIdx > -1 && remoteIdx > -1) {
          // If the uid is in both local and remote then modify the object
          objectsModifiedWithIdx.push({ localIdx: localIdx, remoteObject: remoteObject });
        } else if (localIdx > -1 && remoteIdx === -1) {
          // If the uid is local and not in remote remove the object, remove it.
          // Ignore objects just added to the canvas that haven't been sent to the server yet (fresh === undefined).
          const localObject: any = localObjects[localIdx];
          if (localObject.fresh !== undefined) {
            objectsRemoved.push(localObject);
          }
        } else if (localIdx === -1 && remoteIdx > -1) {
          // If the uid is remote but not in local then add the object
          objectsAdded.push(remoteObject);
        }
      }

      // please note the order of the called functions in bellow is important
      // we should always update -> remove -> add objects
      this.modifyObjects(objectsModifiedWithIdx);
      this.removeObjects(objectsRemoved);
      this.addObjects(objectsAdded);
      this.stackObjects(remoteObjects);

      const objectsModified = objectsModifiedWithIdx.map((obj) => obj.remoteObject);
      return {
        objectsModified,
        objectsRemoved,
        objectsAdded,
      };
    }

    private createUidMap(
      uids: Set<string>,
      objects: fabric.Object[],
    ): Record<string, number | undefined> {
      const uidMap: Record<string, number> = {};

      for (const [index, object] of objects.entries()) {
        const uid = this.getUid(object);
        if (!uid) {
          continue;
        }
        if (uids.has(uid)) {
          uidMap[uid] = index;
        }
      }

      return uidMap;
    }

    private getUid(object: unknown): string | undefined {
      if (Reflect.has(object as object, 'uid')) {
        return (object as any).uid;
      }
      return undefined;
    }

    private getVuid(object: unknown): string | undefined {
      if (Reflect.has(object as object, 'vuid')) {
        return (object as any).vuid;
      }
      return undefined;
    }

    stackObjects(remoteObjects: any[]): void {
      const localObjects: any[] = this.getObjects();
      const moveToBackRemoteObject = remoteObjects[0];
      const bringToFrontRemoteObject = remoteObjects[remoteObjects.length - 1];

      if (!moveToBackRemoteObject && !bringToFrontRemoteObject) {
        return;
      }

      const excludeFromExportCount = localObjects.filter((obj) => obj.excludeFromExport).length;

      for (const object of localObjects) {
        if (!object) {
          continue;
        }
        if (object.uid === moveToBackRemoteObject.uid) {
          object.excludeFromExport
            ? object.sendToBack()
            : this.moveTo(object, excludeFromExportCount);
        }
        if (object.uid === bringToFrontRemoteObject.uid) {
          object.excludeFromExport
            ? this.moveTo(object, excludeFromExportCount)
            : object.bringToFront();
        }
      }
    }

    private modifyObjects(objectsModified: { localIdx: number; remoteObject: any }[]) {
      if (objectsModified.length === 0) {
        return;
      }

      const localObjects = this.getObjects();
      const remoteObjects = objectsModified.map(
        (value: { localIdx: number; remoteObject: any }) => value.remoteObject,
      );
      const localIndices = objectsModified.map(
        (value: { localIdx: number; remoteObject: any }) => value.localIdx,
      );
      fabric.util.enlivenObjects(
        remoteObjects,
        (newObjectsModified: any[]) => {
          for (let i = 0; i < remoteObjects.length; i++) {
            let obj = newObjectsModified[i];
            this.setSelectabilityFromLocalObject(obj, localObjects[localIndices[i]]);
            if (BaseCustomFabric.isCustomFabricObject(obj)) {
              obj = (localObjects[localIndices[i]] as BaseCustomFabric).onRemoteModify(obj);
            }
            this.insertAt(obj, localIndices[i], true);
          }
          this.fire('remote:modified', { objectsModified: newObjectsModified });
        },
        '',
      );
    }

    // set selectability properties for remote object from its corresponding local one
    private setSelectabilityFromLocalObject(remoteObject: any, localObject: any) {
      // in the case of text in edit mode, hence it is not selectable,
      // we shouldn't override the remote object state with the local one as the local one is a temporary one
      if (!itemIsText(localObject) || !(localObject as fabric.Textbox)?.isEditing) {
        remoteObject.selectable = localObject.selectable;
        remoteObject.hoverCursor = localObject.hoverCursor;
      }
    }

    private addObjects(remoteObjects: fabric.Object[]) {
      if (remoteObjects.length === 0) {
        return;
      }
      const objectsBatches = chunk(remoteObjects, this.OBJECTS_BATCH_MAX_SIZE);
      for (const objectsBatch of objectsBatches) {
        this.addObjectsBatch(objectsBatch);
      }
      this.fire('remote:added', { objectsAdded: remoteObjects });
    }

    private addObjectsBatch(objectsBatch: fabric.Object[]): void {
      fabric.util.enlivenObjects(
        objectsBatch,
        (objectsAdded: any[]) => {
          this.add(
            ...objectsAdded.map((obj) => {
              if (BaseCustomFabric.isCustomFabricObject(obj)) {
                return BaseCustomFabric.parseObject(obj);
              }

              /**
               * this block of code was added to determine what are the conditions that can cause adding fabric textbox
               * without missing text property
               * related ticket : SPAC-10090
               * should be removed later when problem is resolved
               */
              if (obj.type === 'textbox' && obj.text === undefined) {
                obj.text = '';
                telemetry?.event('fabric-textbox-no-text', {
                  local: false,
                });
              }
              return obj;
            }),
          );
        },
        '',
      );
    }

    private removeObjects(localObjects: fabric.Object[]) {
      if (localObjects.length === 0) {
        return;
      }

      localObjects = localObjects
        .filter((obj) => !obj.excludeFromExport)
        .map((object) => {
          (object as any).remoteDelete = true;
          if (BaseCustomFabric.isCustomFabricObject(object)) {
            return (
              this.getObjects().find((item) => this.getUid(item) === this.getUid(object)) || object
            );
          }
          return object;
        });
      this.remove(...localObjects);
    }

    public findChangedObjects(localObjects: fabric.Object[], remoteObjects: any[]): Set<string> {
      // Here we have two "sets" of objects
      // 1. localObjects - the objects that are currently on the canvas
      // 2. remoteObjects - the objects that are currently in the Y.Doc
      // We want to find the objects that are *NOT* in the union of the two sets VUIDs - I.e it has been modified, added, or removed

      // Store all local Vuids in a Set for fast lookup
      const localVuids = new Set<string>();

      // Store UIDs of objects that are modified, added, or removed
      const changedUids = new Set<string>();

      for (let i = 0; i < localObjects.length; i++) {
        const vuid = this.getVuid(localObjects[i]);
        const uid = this.getUid(localObjects[i]);
        if (!uid || !vuid) {
          continue;
        }
        localVuids.add(vuid);

        // Add the uid to the modifiedUids set, this may get removed later if we noticed that the Vuid in the remote objects
        // is a part of the union
        changedUids.add(uid);
      }

      for (const { uid: remoteUid, vuid: remoteVUid } of remoteObjects) {
        // The VUID is part of the union of the local and remote object UIDs remove them from the modifiedUids set
        if (localVuids.has(remoteVUid)) {
          // If VUID exists in local set, it means the object is not modified (part of the union)
          changedUids.delete(remoteUid);
        } else {
          // If VUID does not exist in local set, mark the UID as modified
          changedUids.add(remoteUid);
        }
      }

      return changedUids;
    }

    public getCanvasSize(): [number, number] {
      return [this.getWidth(), this.getHeight()];
    }
  };

const mapFabricObjects = (objects: fabric.Object[]): fabric.Object[] =>
  objects.map((obj) => {
    if (BaseCustomFabric.isCustomFabricObject(obj)) {
      return obj.toObject();
    }

    return obj;
  });

export class YCanvas extends Updatable(fabric.Canvas) {
  private subscribedToDisableSelectionForAddedObjects = false;
  isCanvasLockedForUser = false;
  canvasItemsInteractionsEnabled = false;
  private disableSelection?: (e: fabric.IEvent) => void;

  // Fabric event observables
  public readonly onMouseMove$: Observable<fabric.IEvent> = fromEvent(this, 'mouse:move');
  public readonly onMouseUp$: Observable<fabric.IEvent> = fromEvent(this, 'mouse:up');
  public readonly onMouseDown$: Observable<fabric.IEvent> = fromEvent(this, 'mouse:down');

  constructor(element: HTMLCanvasElement | string | null, options?: fabric.ICanvasOptions) {
    super(element, options);
    this.renderOnAddRemove = false;
    this.skipOffscreen = true;
    this.skipTargetFind = true; // improve mouse tracking performance when object selection is not needed
  }

  updateCanvas(
    objects: any[],
    clearCanvas = false,
  ): {
    objectsModified: any[];
    objectsAdded: any[];
    objectsRemoved: any[];
  } {
    const activeObjects = this.getActiveObjects();
    if (clearCanvas) {
      this.clear();
    }

    const localObjects = mapFabricObjects(this.getObjects());
    const remoteObjects = objects.filter(Boolean);

    const changedObjectsUid = this.findChangedObjects(localObjects, remoteObjects);

    const { objectsModified, objectsAdded, objectsRemoved } = this.updateObjects(
      changedObjectsUid,
      localObjects,
      remoteObjects,
    );

    // check if at least one selected object has been removed
    const selectedObjectRemoved = activeObjects.find((obj) => objectsRemoved.includes(obj));
    if (selectedObjectRemoved) {
      // unselect all objects
      this.discardActiveObject();

      if (activeObjects.length > 0) {
        // filter objects selected and not been deleted
        const activeNonDeletedObjects = activeObjects.filter(
          (obj) => !objectsRemoved.includes(obj),
        );
        if (activeNonDeletedObjects) {
          // create new active selection object from activeNonDeletedObjects
          const selection = new fabric.ActiveSelection(activeNonDeletedObjects, {
            canvas: this,
          });
          // set the selection as selected
          this.setActiveObject(selection);
        }
      }
    }

    this.requestRenderAll();
    this.renderTop();

    return { objectsModified, objectsAdded, objectsRemoved };
  }

  /**
   * the clear function is the function will run once we call fabricCanvas.clear()
   * it will set current objects for the canvas to be 0
   * also it will delete any background image or color
   * it will also clear the context for that canvas and rerender
   * here we override the function from Fabric v5 to prevent the object to be removed
   * as in Fabric v5 clear method they're calling
   * this.remove(_objects) which will cause our objected to be deleted from the Y.doc
   */
  clear() {
    this._objects.length = 0;
    this.backgroundImage = undefined;
    this.overlayImage = undefined;
    this.backgroundColor = '';
    this.overlayColor = '';
    if ((this as any)._hasITextHandlers) {
      this.off('mouse:up', (this as any)._mouseUpITextHandler);
      (this as any)._iTextInstances = null;
      (this as any)._hasITextHandlers = false;
    }
    if ((this as any).contextContainer) {
      this.clearContext((this as any).contextContainer);
    }
    this.renderOnAddRemove && this.requestRenderAll();
    return this;
  }

  changeCanvasSelection(selectable: boolean, hoverCursor = 'default') {
    this.forEachObject((obj) => {
      this.changeSelection(obj, selectable, hoverCursor);
    });

    if (!this.disableSelection) {
      this.disableSelection = (e) => {
        this.changeSelection(e.target, false, hoverCursor);
      };
    }

    // Preventing subscribing multiple times,
    // and the imbalance between the number of call to subscribe and the ones to unsubscribe
    if (selectable && this.subscribedToDisableSelectionForAddedObjects) {
      this.off('object:added', this.disableSelection);
    } else if (!selectable && !this.subscribedToDisableSelectionForAddedObjects) {
      this.on('object:added', this.disableSelection);
      this.subscribedToDisableSelectionForAddedObjects = true;
    }
    this.canvasItemsInteractionsEnabled = selectable;
  }

  private changeSelection(obj: fabric.Object | undefined, selectable: boolean, hoverCursor): void {
    if (!obj) {
      return;
    }
    if (obj['itemId'] && !obj['customSelectable']) {
      obj.selectable = false;
    } else {
      obj.selectable = selectable;
    }

    obj.hoverCursor = hoverCursor;

    if (obj.selectable) {
      obj.hoverCursor = 'move';
    }
  }

  // this function checks if ctrl or shift keys are hold while clicking
  _isSelectionKeyPressed(e) {
    if (!this.selectionKey) {
      return false;
    }

    if (Array.isArray(this.selectionKey)) {
      return Boolean(
        this.selectionKey.find(function (key) {
          return e[key] === true;
        }),
      );
    }

    return Boolean(e[this.selectionKey]);
  }

  _shouldClearSelection(e, target?: fabric.Object): boolean {
    // the following code is our customizing to fix the weird behavior when user
    // hold ctrl key and db click an object, fabric draw controls even
    // event if hasControls is set to false
    if (
      (target?.hasControls === false ||
        !target?.controls ||
        (target?.controls && Object.keys(target?.controls).length === 0)) &&
      this._isSelectionKeyPressed(e)
    ) {
      return true;
    }

    // the following code is the default function code in fabric base
    const activeObjects = this.getActiveObjects();
    const activeObject = this._activeObject;

    return (
      !target ||
      (target &&
        activeObject &&
        activeObjects.length > 1 &&
        activeObjects.indexOf(target) === -1 &&
        activeObject !== target &&
        !this._isSelectionKeyPressed(e)) ||
      (target && !target.evented) ||
      (target && !target.selectable && activeObject && activeObject !== target)
    );
  }
}

export class YStaticCanvas extends Updatable(fabric.StaticCanvas) {
  constructor(element: HTMLCanvasElement | string | null, options?: fabric.ICanvasOptions) {
    super(element, options);
    this.renderOnAddRemove = false;
  }

  updateCanvas(objects: any[], clearCanvas = false) {
    if (clearCanvas) {
      this.clear();
    }
    const localObjects = mapFabricObjects(this.getObjects());
    const remoteObjects = objects;

    const changedObjectsUid = this.findChangedObjects(localObjects, remoteObjects);

    const result = this.updateObjects(changedObjectsUid, localObjects, remoteObjects);

    this.requestRenderAll();
    return result;
  }
}

export function itemIsText(item: fabric.Object): boolean {
  return item.type === 'i-text' || item.type === 'textbox';
}

export function itemIsCanvasItem(item: fabric.Object) {
  return !!(item as any)?.itemId;
}

export function itemIsStickyNoteRelatedText(item: fabric.Object): item is RelatedIText {
  return !!item[STICKY_TEMP_TEXT];
}

export function setObjectCaching(objectCaching: boolean) {
  fabric.Object.prototype.objectCaching = objectCaching;
}

function doRectanglesIntersectOnly(
  pointTL_A: fabric.Point,
  pointBR_A: fabric.Point,
  pointTL_B: fabric.Point,
  pointBR_B: fabric.Point,
): boolean {
  // Check if one rectangle is to the left of the other
  if (
    pointBR_A.x < pointTL_B.x ||
    pointBR_B.x < pointTL_A.x ||
    pointTL_A.y > pointBR_B.y ||
    pointTL_B.y > pointBR_A.y
  ) {
    return false;
  }

  // Check if rectangle A is completely inside rectangle B
  if (
    pointTL_A.x >= pointTL_B.x &&
    pointBR_A.x <= pointBR_B.x &&
    pointTL_A.y >= pointTL_B.y &&
    pointBR_A.y <= pointBR_B.y
  ) {
    return false;
  }

  // Check if rectangle B is completely inside rectangle A
  if (
    pointTL_B.x >= pointTL_A.x &&
    pointBR_B.x <= pointBR_A.x &&
    pointTL_B.y >= pointTL_A.y &&
    pointBR_B.y <= pointBR_A.y
  ) {
    return false;
  }

  return true;
}

function isRectangle(
  tl: fabric.Point,
  tr: fabric.Point,
  br: fabric.Point,
  bl: fabric.Point,
): boolean {
  return isOrthogonal(tl, tr, br) && isOrthogonal(tr, br, bl) && isOrthogonal(br, bl, tl);
}

function isOrthogonal(a: fabric.Point, b: fabric.Point, c: fabric.Point): boolean {
  return (b.x - a.x) * (b.x - c.x) + (b.y - a.y) * (b.y - c.y) === 0;
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { catchError, first, of } from 'rxjs';
import {
  ConfirmationDialogComponent,
  IConfirmationDialogData,
} from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { SessionSharedDataService } from '../services/session-shared-data.service';
import { SpacesService } from '../services/spaces.service';
import { UiService } from '../services/ui.service';
import { CollaborativeApps } from '../sessions/session/iframe/additional-apps.utils';
import { FlagsService } from '../services/flags.service';

export enum UrlValidationError {
  NoError,
  MalformedUrl, // Url does not pass the local validation Regex
  InvalidUrlResponse, // Url response returned an error code
  NotEmbeddable, // Url embedding is explicitly disallowed
  CustomError, // Custom error message
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class IFrameUtils {
  constructor(
    private uiService: UiService,
    public dialog: MatDialog,
    private sharedDataService: SessionSharedDataService,
    private spacesService: SpacesService,
    private featureFlagService: FlagsService,
  ) {}

  showIframeRemoveDialog(itemIds: string[]): void {
    const width = this.uiService.isMobile.getValue() ? '90vw' : 'auto';

    const description =
      itemIds.length > 1
        ? 'Closing these apps will also close them for all users in this Space. Are you sure you want to proceed?'
        : 'Closing this app will also close it for all users in this Space. Are you sure you want to proceed?';

    const confirmationDialogData: IConfirmationDialogData = {
      title: 'Close app?',
      description: description,
      cancelText: 'Cancel',
      cancelIcon: 'close',
      confirmText: 'Delete',
      confirmIcon: 'delete',
      action: this.deleteIframes.bind(this, itemIds),
    };

    this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      data: confirmationDialogData,
      panelClass: 'delete-iframe-dialog',
      width: width,
      minWidth: width,
      maxWidth: width,
    });
  }

  deleteIframes(itemIds: string[]): void {
    itemIds.forEach((itemId) => {
      this.sharedDataService.removeCanvasItem.next(itemId);
    });

    // Set cursor back to default cursor
    const fabricCanvas = this.sharedDataService.fabricCanvas;
    if (fabricCanvas) {
      fabricCanvas.setCursor('url(../../../../../assets/icons/main-cursor.svg), default');
    }
  }

  async validateUrl(
    url: string,
    validationString: string | null = null,
  ): Promise<{ error: UrlValidationError; modifiedUrl: string }> {
    let error = UrlValidationError.NoError;

    const handleResponse = (validationError: UrlValidationError, modifiedUrl: string = url) => ({
      error: validationError,
      modifiedUrl,
    });
    const isMarioDeveloper = this.featureFlagService.featureFlagsVariables.mario
      .enable_mario_developer_mode
      ? true
      : false;

    if (isMarioDeveloper) {
      return handleResponse(UrlValidationError.NoError, url); // Pass through all URLs without validation
    }

    if (!this.isValidUrl(url)) {
      return handleResponse(UrlValidationError.MalformedUrl);
    } else if (validationString && !url.includes(validationString)) {
      return handleResponse(UrlValidationError.CustomError);
    } else {
      const modifiedUrl = this.addHttpsIfItDoesNotExist(url);

      return new Promise((resolve, reject) => {
        this.spacesService
          .validateIframeUrl(modifiedUrl)
          .pipe(catchError(() => of(false)))
          .pipe(first())
          .subscribe((res: any) => {
            if (!res) {
              return UrlValidationError.InvalidUrlResponse;
            } else if (!res.valid) {
              error =
                res.reason === 'INVALID_SITE'
                  ? UrlValidationError.InvalidUrlResponse
                  : UrlValidationError.NotEmbeddable;
              resolve(handleResponse(error));
            } else {
              resolve(handleResponse(UrlValidationError.NoError, modifiedUrl));
            }
          });
      });
    }
  }

  isValidUrl(url: string): boolean {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#.*)?$', // validate fragment/hash
      'i',
    ); // validate fragment locator
    return !!urlPattern.test(url);
  }

  addHttpsIfItDoesNotExist(url: string): string {
    if (!/^(?:f|ht)tps?:\/\//.test(url)) {
      url = `https://${url}`;
    } else if (/^(?:f|ht)tp?:\/\//.test(url)) {
      url = url.replace('http', 'https');
    }
    return url;
  }

  isValidPinCode(pinCode: string): boolean {
    const pinCodePattern = /^[0-9a-zA-Z]+$/;
    return !!pinCodePattern.test(pinCode);
  }

  appTypeFromPastedLink(textClipboard: string): CollaborativeApps | undefined {
    const docsLinkRegex =
      /^(https?:\/\/)?docs\.google\.com\/document\/d\/[a-zA-Z0-9_-]+(\/(edit|preview)?)?(\?.*)?(#.*)?$/g;
    const sheetsLinkRegex =
      /^(https?:\/\/)?docs\.google\.com\/spreadsheets\/d\/[a-zA-Z0-9_-]+(\/(edit|preview)?)?(\?.*)?(#.*)?$/g;
    const slidesLinkRegex =
      /^(https?:\/\/)?docs\.google\.com\/presentation\/d\/[a-zA-Z0-9_-]+(\/(edit|preview)?)?(\?.*)?(#.*)?$/g;
    if (docsLinkRegex.test(textClipboard)) {
      return CollaborativeApps.GOOGLE_DOCS;
    }
    if (slidesLinkRegex.test(textClipboard)) {
      return CollaborativeApps.GOOGLE_SLIDES;
    }
    if (sheetsLinkRegex.test(textClipboard)) {
      return CollaborativeApps.GOOGLE_SHEETS;
    }
  }
}

import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { intercomArticles } from 'src/app/intercom-articles';
import { VirtualBackgroundPanelService } from 'src/app/services/virtual-background/virtual-background-panel.service';
import { VirtualBackgroundPanelLocalStreamService } from 'src/app/services/virtual-background/virtual-background-panel-local-stream.service';
import { VirtualBackgroundPanelRTCService } from 'src/app/services/virtual-background/virtual-background-panel-rtc.service';
import {
  VBImageCategory,
  VirtualBackgroundPanelBase,
} from 'src/app/services/virtual-background/virtual-background-panel-base';
import { VirtualBackgroundType } from 'src/app/common/interfaces/rtc-interface';
import { Observable } from 'rxjs';

const enterTransition = transition(':enter', [
  style({
    opacity: 0,
  }),
  animate(
    '0.3s ease-in',
    style({
      opacity: 1,
    }),
  ),
]);

const leaveTrans = transition(':leave', [
  style({
    opacity: 1,
  }),
  animate(
    '0.3s ease-out',
    style({
      opacity: 0,
    }),
  ),
]);

const fadeIn = trigger('fadeIn', [enterTransition]);
const fadeOut = trigger('fadeOut', [leaveTrans]);

@UntilDestroy()
@Component({
  selector: 'app-virtual-background-panel',
  templateUrl: './virtual-background-panel.component.html',
  styleUrls: ['./virtual-background-panel.component.scss'],
  animations: [fadeIn, fadeOut],
  // as these services are tightly coupled with this component,
  // we can provide them here to get destroyed with the component.
  providers: [
    VirtualBackgroundPanelService,
    VirtualBackgroundPanelLocalStreamService,
    VirtualBackgroundPanelRTCService,
  ],
})
export class VirtualBackgroundPanelComponent implements AfterViewInit {
  isVideoPlaying = false;
  intercomArticles = intercomArticles;
  virtualBackgroundType = VirtualBackgroundType;
  virtualBackgroundServiceBase: VirtualBackgroundPanelBase;

  videoStream$: Observable<MediaStream | null>;

  @ViewChild('virtualBackgroundPreview') videoElement!: { nativeElement: HTMLVideoElement };

  constructor(
    private dialogRef: MatDialogRef<VirtualBackgroundPanelComponent>,
    public virtualBackgroundPanelService: VirtualBackgroundPanelService,
  ) {
    this.virtualBackgroundServiceBase = this.virtualBackgroundPanelService.getService();
    this.virtualBackgroundServiceBase.subscribeCurrUserAdminInstitutionChange();

    this.videoStream$ = this.virtualBackgroundServiceBase.getVideoStream();

    this.virtualBackgroundServiceBase
      .isVideoPlaying()
      .pipe(untilDestroyed(this))
      .subscribe((next) => {
        this.isVideoPlaying = next;
      });
  }

  ngAfterViewInit(): void {
    this.videoStream$.pipe(untilDestroyed(this)).subscribe((videoStream) => {
      if (videoStream) {
        this.videoElement.nativeElement.srcObject = videoStream;
      }
    });
  }

  removeBackground(): void {
    this.isVideoPlaying = false;
    this.virtualBackgroundServiceBase.removeBackground();
  }

  blur(): void {
    this.isVideoPlaying = false;
    this.virtualBackgroundServiceBase.blur();
  }

  close(): void {
    this.dialogRef.close();
  }

  apply(): void {
    this.close();
  }

  deleteImage(category: VBImageCategory, src: string) {
    this.virtualBackgroundServiceBase.deleteImage(category, src);
  }
}

import { ADT } from 'ts-adt';
import { SpaceAuth as SpaceAuth } from '../../auth/models.ts/models';
import { BaseADT, ResponseADT } from '../../utils/adt_utils/adt_utils';

/**
 * Event Name for all YJS Sync communication
 */
export const EventName = 'SyncProvider';

// ---- TYPES ----

/**
 * Events that can be emitted by the SyncProvider
 */
export type Event = 'Subscribe' | 'Unsubscribe' | 'Update' | 'Sync';

/**
 * Base Event that more complex events can extend
 */
export type BaseEvent = {
  scope: Scope;
};

/**
 * Requests
 * Messages generated by the client destined for the server
 * note: some requests are synchronous and require a callback for response
 */
export type Request = BaseADT<
  BaseEvent,
  {
    // ---- SYNC REQUESTS ----
    Subscribe: {
      scope: Scope;
    };
    Unsubscribe: {
      scope: Scope;
    };

    // ---- ASYNC REQUESTS ----
    Update: {
      scope: Scope;
      update: ArrayBuffer;
    };

    Sync: {
      scope: Scope;
      // Client state is used to compute missing updates held on the server
      clientState: ArrayBuffer;
    };
  }
>;

/**
 * Responses
 * Messages generated by the server destined for the client
 */
export type Response = BaseADT<
  BaseEvent,
  {
    // ---- SYNC RESPONSES ----
    Subscribe: {
      Response: ResponseADT<{}, Error>;
    };
    Unsubscribe: {
      Response: ResponseADT<{}, Error>;
    };

    // ---- ASYNC RESPONSES ----
    Update: {
      Response: ResponseADT<
        {
          update: ArrayBuffer;
        },
        Error
      >;
    };
    Sync: {
      Response: ResponseADT<
        {
          // Data that the client is missing
          update: ArrayBuffer;
          // The client will use this and determine if it needs to send
          // any missing deltas, if so it will use the Update request
          serverState: ArrayBuffer;
        },
        Error
      >;
    };
  }
>;

/**
 * Scopes model the contexts in which YJS documents can be created
 * and also include the data need to authenticate a user for that context
 */
export type Scope = ADT<{
  Space: {
    id: string;

    // Optional, only needed for Subscribing
    auth?: SpaceAuth;
  };
  Mario: {
    spaceId: string; // uid of the space
    marioId: string; // uid of the mario instance
    auth?: SpaceAuth;
  };
}>;

/**
 * Generic error type from the sync provider
 */
export type Error = { msg: string };

export type ResponseCallback = (response: Response) => void;

/**
 * Indicates the string representation of a scope
 */
export type DocName = string;

import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, scan } from 'rxjs';
import { TipsService } from 'src/app/tips.service';
import { trigger, style, transition, animate } from '@angular/animations';

interface ICustomLoadingConfig {
  text: string;
  icon: string;
  processing: boolean;
}
@UntilDestroy()
@Component({
  selector: 'app-loading',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  animations: [
    trigger('fadeDivChange', [
      transition(':increment', [
        style({ opacity: 0 }),
        animate('750ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':decrement', [
        style({ opacity: 1 }),
        animate('750ms ease-out', style({ opacity: 0 })),
      ]),
    ]),
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('500ms ease-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class LoadingComponent {
  @Input() loading: boolean = false;
  @Input() layoutType:
    | 'default'
    | 'image-loading'
    | 'image'
    | 'video'
    | 'file'
    | 'processing'
    | 'custom'
    | 'tests-in-progress' = 'default';
  @Input() hasDarkBg = false;
  @Input() size = 170;
  @Input() icon = 'pencil_loading';
  @Input() iconSize = 64;
  @Input() showTipsIndicator = false;
  @Input() progress?: number;
  @Input() smallContainerControlEnabled = false;
  @Input() smallContainer = true;
  @Input() customLayoutConfig?: ICustomLoadingConfig;

  currentTip$: Observable<string>;
  currentTipCounter$: Observable<number>;
  defaultCustomLayout: ICustomLoadingConfig = {
    text: 'Loading...',
    icon: 'pencil_loading',
    processing: true,
  };

  constructor(public tipsService: TipsService, private elementRef: ElementRef) {
    this.currentTip$ = this.tipsService.generateTip$().pipe(untilDestroyed(this));
    this.currentTipCounter$ = this.currentTip$.pipe(scan((a) => a + 1, 0));
  }

  get nativeElement(): HTMLElement {
    return this.elementRef.nativeElement as HTMLElement;
  }
}

export { ICustomLoadingConfig };

import { Injectable } from '@angular/core';
import { filter, map, switchMap } from 'rxjs';
import { IExpandAndFullScreenChange, SessionView } from '../services/session-shared-data.service';
import { SpaceRepository } from './space.repository';
import {
  Location,
  TemporaryUserMetadataEntry,
  TemporaryUserMetadataRepositoryService,
} from './temporary-user-metadata-repository.service';

export interface ISpaceLeaderMode {
  // this is the SpaceId
  _id: string;
  leaderModeEnabled: boolean;
  currentLeaderUid: string | undefined;
  // Used to uniquely identify which tab the user is using the leader mode on
  currentLeaderTabId: string | undefined;
  leaderFrameUid: string | undefined;
  leaderInitLocation: Location | undefined;
  leaderSpaceView: SessionView | undefined;
  sessionViewMetaData: any;
  expandAndFullScreenChange: IExpandAndFullScreenChange | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class LeaderModeRepositoryService {
  constructor(
    private temporaryUserMetadatapositoryService: TemporaryUserMetadataRepositoryService,
    private spaceRepository: SpaceRepository,
  ) {}

  spaceLeaderMode$ = this.spaceRepository.activeSpaceCurrentRoomUid$.pipe(
    filter(Boolean),
    switchMap(() =>
      this.temporaryUserMetadatapositoryService.spaceTemporaryMetadata$.pipe(
        map(this.mapCurrentMetadataToLeaderMode.bind(this)),
      ),
    ),
  );

  getCurrentState() {
    return this.mapCurrentMetadataToLeaderMode(
      this.temporaryUserMetadatapositoryService.getSpaceMetadata(),
    );
  }

  private mapCurrentMetadataToLeaderMode(
    metadata: Map<string, TemporaryUserMetadataEntry>,
  ): ISpaceLeaderMode {
    let curState = this.getDefaultState();

    for (const [k, v] of metadata) {
      if (!v.leader) {
        continue;
      }
      // leader mode work only for the same breakout room
      if (
        !this.spaceRepository.activeSpace?.breakoutRoomsEnabled ||
        v.breakoutRoomUid === this.spaceRepository.activeSpaceCurrentRoom?.uid
      ) {
        // in case of multiple leaders exist we will respect the one with the lowest hash order
        // to make sure that all users follow the same leader
        // note: used userId + tabId for backward compatibility
        if (
          !curState.leaderModeEnabled ||
          curState.currentLeaderUid! + curState.currentLeaderTabId > v.userId + k
        ) {
          curState = {
            _id: this.spaceRepository.activeSpaceId!,
            leaderModeEnabled: v.leader ?? false,
            currentLeaderUid: v.userId,
            currentLeaderTabId: k,
            leaderFrameUid: v.frameUID,
            leaderInitLocation: v.location,
            leaderSpaceView: v.sessionView,
            sessionViewMetaData: v.sessionViewMetaData,
            expandAndFullScreenChange: v.expandAndFullScreenChange,
          };
        }
      }
    }

    return curState;
  }

  private getDefaultState(): ISpaceLeaderMode {
    return {
      _id: this.spaceRepository.activeSpaceId!,
      leaderModeEnabled: false,
      currentLeaderUid: undefined,
      currentLeaderTabId: undefined,
      leaderFrameUid: undefined,
      leaderInitLocation: undefined,
      leaderSpaceView: undefined,
      sessionViewMetaData: undefined,
      expandAndFullScreenChange: undefined,
    };
  }
}

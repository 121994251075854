<div id="confirmation-dialog">
  <div class="head">
    <div class="title">{{ title }}</div>
  </div>
  <div class="body" [innerHTML]="body"></div>
  <div class="buttons">
    <app-pencil-button
      class="pencil-button light medium cancel"
      [icon]="cancelButtonIcon.name"
      [iconConfig]="{ type: cancelButtonIcon.type }"
      [label]="cancelButtonText"
      (clickEvent)="cancel()"
    >
    </app-pencil-button>
    <app-pencil-button
      mat-flat-button
      class="pencil-button medium confirm"
      [class.primary]="!isDanger"
      [class.danger]="isDanger"
      [icon]="confirmButtonIcon.name"
      [iconConfig]="{ type: confirmButtonIcon.type }"
      [label]="confirmButtonText"
      (clickEvent)="confirm()"
    >
    </app-pencil-button>
  </div>
</div>

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommonDialogComponent } from 'src/app/dialogs/common-dialog/common-dialog.component';
import { ResourceFilterParamsEnum } from 'src/app/models/params';
import {
  IDisplayFile,
  IDisplayFolder,
  IFolderResponse,
  IResource,
  ResourceItemModel,
} from 'src/app/models/resource';
import { User } from 'src/app/models/user';
import { ResourcesService } from 'src/app/services/resources.service';
import { ThumbnailService } from 'src/app/services/thumbnail.service';
import { EmbedVideoService } from '../../../services/embed-video.service';
import { IActiveFolder } from '../files.component';
import { incrementBy, deleteItemsFrom } from '../utils/common';

export enum animationStates {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
}

@UntilDestroy()
@Component({
  template: '',
})
export abstract class FileViewComponent implements OnInit, OnChanges {
  @Input() activeFolder!: IActiveFolder;
  @Input() courseLookup!: Map<string, string>;
  @Input() draggingWithoutSelect = true; // Used to Allow dragging a single file without pre-selection.
  @Input() enforcingFilter: ResourceFilterParamsEnum = ResourceFilterParamsEnum.EnforcingFilter;
  @Input() isCreatingFolder = false;
  @Input() canSelect = true;
  @Input() maxNameLength = 40;
  @Input() maxCourseNameLength = 12;
  @Input() user?: User;
  @Input() loading = false;

  // To notify if a toggle occurs.
  @Output() toggleSelectionEmitter: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() scroll: EventEmitter<any> = new EventEmitter();
  // Second option is for the extended version of Files, since we are using append param to determine whether to add it to the breadcrumb.
  @Output() openFolder: EventEmitter<
    string | IFolderResponse | { folder: string | IFolderResponse; append: boolean }
  > = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() snackbar: EventEmitter<{
    context: string;
    msg: string;
    duration?: number;
    error: boolean;
  }> = new EventEmitter();
  // TODO: I think it's better to have a service for that internal cache.
  @Output() updateCache: EventEmitter<{
    folderId: string;
    resources: IDisplayFile[];
    folders: IDisplayFolder[];
  }> = new EventEmitter();
  @Output() deletedResources: EventEmitter<IResource[]> = new EventEmitter();

  selectedFiles: number[] = [];
  selectedFolders: number[] = [];

  dragging = false;
  ResourceFilterParamsEnum = ResourceFilterParamsEnum;

  // Enum of resource types For html templates.
  resourceTypes = ResourceItemModel;
  allSelected = false;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private embedService: EmbedVideoService,
    private thumbnail: ThumbnailService,
    public resourceService: ResourcesService,
    public translate: TranslateService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeFolder) {
      this.initFolder();
    }
  }
  ngOnInit() {
    this.initFolder();
  }

  // Use this for selections
  get currentFiles() {
    return (
      !this.loading && this.activeFolder?.resources.files.length
        ? this.activeFolder.resources.files
        : []
    ) as IDisplayFile[];
  }

  // Use this for selections
  get currentFolders() {
    return (
      !this.loading && this.activeFolder?.subdirectories.length
        ? this.activeFolder.subdirectories
        : []
    ) as IDisplayFolder[];
  }

  get selectedFilesObj() {
    return this.selectedFiles.map((index) => this.currentFiles[index]);
  }

  get selectedFoldersObj() {
    return this.selectedFolders.map((index) => this.currentFolders[index]);
  }

  initFolder(): void {
    this.selectedFolders = [];
    this.selectedFiles = [];
    this.activeFolder?.resources.files.forEach((file) => {
      this.prepareFileForView(file);
    });

    this.activeFolder?.subdirectories.forEach((folder) => {
      this.prepareFolderForView(folder);
    });
  }

  openPreview(event?: KeyboardEvent, file?: Record<string, any>, index = -1) {
    // if already in selection mode, select the file
    if (this.selectedFiles.length > 0 && index >= 0) {
      const fileInIndex = this.currentFiles[index];
      if (fileInIndex && fileInIndex.xMeta?.display) {
        fileInIndex.xMeta.display.selected = !this.currentFiles[index].xMeta?.display.selected;
      }
      if (event) {
        this.toggleSelection(event, index);
      }
      return;
    }

    this.previewResources(file as IDisplayFile);
  }

  previewResources(file?: IDisplayFile) {
    const files = file ? [file] : this.selectedFiles.map((i) => this.currentFiles[i]);
    if (files.length === 0) {
      return;
    }
    // construct path based on file id
    let path = files[0]._id;
    for (let i = 1; i < files.length; i++) {
      path += `&${files[i]._id}`;
    }
    this.router.navigate([`/content/files/${path}`], {
      state: {
        resource: files[0],
        // it determines whether we should go back to the previous url or to go directly to files when the user exit the preview.
        backToLastURL: true,
        ...(files.length > 1 && { files: files }),
      },
    });
  }

  toggleSelection(event: KeyboardEvent, fileIndex: number) {
    if (event?.shiftKey) {
      this.shiftKeyDown(fileIndex);
    } else {
      if (this.currentFiles[fileIndex] && this.currentFiles[fileIndex].xMeta?.display.selected) {
        this.selectedFiles.push(fileIndex);
      } else {
        this.selectedFiles.splice(this.selectedFiles.indexOf(fileIndex), 1);
      }
    }
    this.toggleSelectionEmitter.emit();
  }

  toggleFolderSelection(index: number) {
    if (this.currentFolders[index].xMeta?.display.selected) {
      this.selectedFolders.push(index);
      this.selectedFoldersObj.push(this.currentFolders[index]);
    } else {
      this.selectedFoldersObj.splice(this.selectedFolders.indexOf(index), 1);
      this.selectedFolders.splice(this.selectedFolders.indexOf(index), 1);
    }
    this.toggleSelectionEmitter.emit();
  }

  onOpenFolder(folder: string | IFolderResponse) {
    this.openFolder.emit(folder);
  }

  prepareFileForView(resource: IDisplayFile, newFile = false) {
    let ext = ''; // TODO: Save extension in model
    const uploadTime = new Date(resource.createdAt);

    // New created file, so we need to assign the Owner info to render it.
    if (newFile && this.user) {
      resource.owner = this.user;
    }
    if (resource.type === ResourceItemModel.VIDEO) {
      resource.embedding = this.embedService.embed(resource.url, {
        attr: { width: '100%' },
      });
      // eslint-disable-next-line frontend-rules/ngx-translate-service
      ext = 'vid';
    }
    if (resource.type === ResourceItemModel.IMAGE) {
      // eslint-disable-next-line frontend-rules/ngx-translate-service
      ext = 'img';
    }
    // eslint-disable-next-line frontend-rules/ngx-translate-service
    resource.altName = `${uploadTime.toLocaleString()}.${ext}`;
    // add display meta
    resource.xMeta = {
      display: {
        selected: false,
        animationState: animationStates.VISIBLE, // for animating the files.
        style: {
          order: newFile ? -1 : 1, // change order in view but keep array order
        },
      },
    };
    // Add thumbnail Url to the current xMeta.
    this.addThumbnailUrl(resource);
  }

  // TODO: Change "any" to "IDisplayFile" whenever we merge filename property in the interface.
  private async addThumbnailUrl(resource: any) {
    if (
      resource.type !== ResourceItemModel.IMAGE ||
      (!resource.url && !resource.filename) ||
      resource.xMeta?.thumbnailURL?.length
    ) {
      return;
    }
    // Width and height are specified in extension configuration.
    this.thumbnail
      .getThumbnailUrl(resource.filename, resource.url, 200, 200)
      .then((url) => {
        if (resource.xMeta) {
          resource.xMeta['thumbnailURL'] = url;
        }
      })
      .catch(() => {
        if (resource.xMeta) {
          resource.xMeta['thumbnailURL'] = resource.url;
        }
      });
  }

  prepareFolderForView(folder: IDisplayFolder, newFolder = false) {
    // New created file, so we need to assign the Owner info to render it.
    if (newFolder && this.user) {
      folder.owner = this.user;
    }

    folder.xMeta = {
      display: {
        animationState: animationStates.VISIBLE, // for animating the folders.
        selected: false,
        hover: false,
      },
    };
  }

  abstract handleDrag(files: IDisplayFile[], elem: HTMLDivElement): void;

  drag($event: any, drag: boolean, index?: number) {
    this.dragging = drag;
    if (!drag) {
      const ghost = document.getElementById('drag-ghost');
      if (ghost && ghost.parentNode) {
        ghost.childNodes.forEach((c) => ghost.removeChild(c));
        ghost.parentNode.removeChild(ghost);
      }
      return drag;
    }

    // To allow dragging a single file, without selecting it at first.
    if (index !== undefined && !this.selectedFiles.length) {
      const file = this.currentFiles[index];
      this.selectedFiles.push(index);
      this.selectedFilesObj.push(file);
      if (file.xMeta?.display) {
        file.xMeta.display.selected = true;
      }
    }

    // create ghost image
    const files = this.selectedFiles.map((i) => this.currentFiles[i]);
    const elem = document.createElement('div');
    elem.id = 'drag-ghost';
    elem.style.position = 'absolute';
    elem.style.top = '-1000px';
    if (files.length === 0) {
      return drag;
    }
    this.handleDrag(files, elem);
    // set drag image
    document.body.appendChild(elem);
    $event.dataTransfer.setDragImage(elem, 0, 0);
    return drag;
  }

  allowDrop($event: Event) {
    $event.preventDefault();
  }

  drop($event: DragEvent, folder: IDisplayFolder) {
    this.dragging = false;

    // Stopping hovering effect.
    if (folder.xMeta && folder.xMeta.display) {
      folder.xMeta.display.hover = false;
    }

    this.drag($event, false);
    this.moveToFolder($event, folder);
  }

  moveToFolder(event: any, folder: IDisplayFolder) {
    if (!folder._id || this.selectedFiles.length <= 0) {
      return;
    }

    // Some feedback to show that his request is on progress.
    this.snackbar.emit({
      error: false,
      context: 'file-moved',
      msg: this.translate.instant('Working on Moving your files.'),
      duration: 1000,
    });

    // To be used instead of strings.
    const success = 'success';

    const resources: any[] = [];
    const selectedFiles = this.selectedFiles.map((i) => this.currentFiles[i]._id);
    selectedFiles.forEach((fileId) => {
      resources.push({ _id: fileId, folder: folder._id });
    });
    this.resourceService
      .editResources(resources)
      .pipe(untilDestroyed(this))
      .subscribe((res: any) => {
        if (!res[success] || !res[success].length) {
          return this.snackbar.emit({
            error: true,
            context: 'file-moved',
            msg: this.translate.instant('Something is wrong, Try again!'),
            duration: 3000,
          });
        }
        // Reflecting Completion of moving on the screen.
        // TODO: adding Folder Movement by drag/drop.
        this.movingSelectionView(res[success], [], folder);
      });
  }

  createDragImageCount() {
    const numElem = document.createElement('div');
    numElem.innerText = this.selectedFiles.length.toString();
    numElem.classList.add('drag-file-count');
    numElem.style.fontSize = this.selectedFiles.length > 9 ? '12px' : '14px';
    return numElem;
  }

  createDragImageList(file: IDisplayFile) {
    const fileElem = document.createElement('div');
    const fileName = file.name || file.altName || 'Untitled';
    fileElem.innerText = fileName;
    if (fileName.length > this.maxNameLength) {
      fileElem.innerText += '...';
    }
    fileElem.classList.add('drag-file-list');
    return fileElem;
  }

  createDragImageTile(file: IDisplayFile) {
    const fileElem = document.createElement('div');
    const iElem = document.createElement('i');
    iElem.classList.add('material-icons-outlined');
    iElem.classList.add('drag-thumbnail');
    if (file.type === 'IMAGE') {
      iElem.innerHTML = 'image';
    } else if (file.type === 'VIDEO') {
      iElem.innerHTML = 'videocam';
    } else if (file.type === 'DOCUMENT') {
      iElem.innerHTML = 'text_snippet';
    }
    fileElem.appendChild(iElem);
    // create file name element
    const nameElem = document.createElement('div');
    const fileName = file.name || file.altName || 'Untitled';
    nameElem.innerText = fileName.substr(0, this.maxNameLength);
    if (fileName.length > this.maxNameLength) {
      nameElem.innerText += '...';
    }
    nameElem.classList.add('drag-filename');
    nameElem.classList.add('cmt-8');
    fileElem.appendChild(nameElem);
    fileElem.classList.add('drag-ghost-img');
    fileElem.classList.add('flex-column');
    return fileElem;
  }

  viewCourses(courses: string[]) {
    const courseNames: string[] = [];
    courses.forEach((course) => {
      courseNames.push(this.courseLookup.get(course)!);
    });
    return courseNames;
  }

  shiftKeyDown(lastIndex: number) {
    this.selectedFiles.sort(function (a, b) {
      return a - b;
    });
    if (!this.currentFiles[lastIndex].xMeta?.display.selected) {
      this.selectedFiles = this.selectedFiles.filter((i) => i < lastIndex);
      for (let i = lastIndex + 1; i < this.currentFiles.length; i++) {
        const contextFile = this.currentFiles[i];
        if (contextFile.xMeta) {
          contextFile.xMeta.display.selected = false;
        }
      }
    } else {
      if (this.selectedFiles.length === 0) {
        if (this.currentFiles[lastIndex].xMeta) {
          const contextFile = this.currentFiles[lastIndex];
          if (contextFile.xMeta) {
            contextFile.xMeta.display.selected = true;
          }
        }
        this.selectedFiles.push(lastIndex);
      } else {
        const latestIndex = this.selectedFiles[this.selectedFiles.length - 1];
        if (latestIndex < lastIndex) {
          for (let i = latestIndex + 1; i <= lastIndex; i++) {
            const contextFile = this.currentFiles[i];
            if (contextFile.xMeta) {
              contextFile.xMeta.display.selected = true;
            }
            this.selectedFiles.push(i);
          }
        } else {
          this.selectedFiles = this.selectedFiles.filter((i) => i <= lastIndex);
          for (let i = lastIndex + 1; i < this.currentFiles.length; i++) {
            const contextFile = this.currentFiles[i];
            if (contextFile.xMeta) {
              contextFile.xMeta.display.selected = false;
            }
          }
          const contextFile = this.currentFiles[lastIndex];
          if (contextFile.xMeta) {
            contextFile.xMeta.display.selected = true;
          }
          this.selectedFiles.push(lastIndex);
        }
      }
    }
  }

  removeSelectionView(files = true, folders = true) {
    if (files) {
      // To keep the indices valid, as long as we deleting from (resources/ folders) list.
      this.selectedFiles.sort((a, b) => b - a);

      // Now, We need to remove the selected files from the view.
      this.selectedFiles.forEach((index) => {
        // Since files list and files in active folder resources,
        // must be identical in case of main view 'shouldUpdateMainView()'
        // Then we can use the index on both of them.
        this.activeFolder?.resources.files.splice(index, 1);
      });
      if (this.activeFolder) {
        this.activeFolder.resources.total -= this.selectedFiles.length;
      }
      this.selectedFiles.splice(0, this.selectedFiles.length);
      this.selectedFilesObj.splice(0, this.selectedFilesObj.length);
    }

    if (folders) {
      // To keep the indices valid, as long as we deleting from (resources/ folders) list.
      this.selectedFolders.sort((a, b) => b - a);

      // Now, We need to remove the selected Folders from the view as well.
      this.selectedFolders.forEach((index) => {
        // Since folders list and folders in active folder,
        // must be identical in case of main view 'shouldUpdateMainView()'
        // Then we can use the index on both of them.
        // incrementing by one due to adding new folder on the top of the folders.
        this.activeFolder?.subdirectories.splice(index, 1);
      });
      if (this.activeFolder) {
        this.activeFolder.total -= this.selectedFolders.length;
      }
      this.selectedFolders.splice(0, this.selectedFolders.length);
      this.selectedFoldersObj.splice(0, this.selectedFoldersObj.length);
    }
  }

  movingSelectionView(
    movedFiles: string[],
    movedFolders: string[],
    destFolder: { _id: string; name: string },
  ) {
    // Feedback that Files/Folders has been moved successfully.
    // Constructing feedback message.
    let msg = '';
    if (movedFolders.length || movedFiles.length > 1) {
      msg = this.translate.instant(
        `${movedFolders.length + movedFiles.length} items moved into ${destFolder.name}`,
      );
    } else if (movedFolders.length + movedFiles.length === 1) {
      if (this.selectedFilesObj.length) {
        msg = this.translate.instant(
          `${this.selectedFilesObj[0].name} moved into ${destFolder.name}`,
        );
      }
      if (this.selectedFoldersObj.length) {
        msg = this.translate.instant(
          `${this.selectedFoldersObj[0].name} moved into ${destFolder.name}`,
        );
      }
    }

    this.snackbar.emit({
      error: false,
      context: 'file-moved',
      msg,
    });

    // Changing the parent folder of files/ parent for folders.
    this.selectedFilesObj.forEach((file) => (file.folder = destFolder._id));
    this.selectedFoldersObj.forEach((folder) => (folder.parentFolder = destFolder._id));

    // No changes.
    if (!this.selectedFilesObj.length && !this.selectedFoldersObj.length) {
      return;
    }

    // Updating cache
    this.updateCache.emit({
      folderId: destFolder._id,
      resources: this.selectedFilesObj,
      folders: this.selectedFoldersObj,
    });
    // Remove files/folders from current View.
    this.removeSelectionView();
  }

  deleteSelected() {
    this.canSelect = false;
    this.dialog
      .open(CommonDialogComponent, {
        width: '500px',
        data: {
          title: this.translate.instant('Delete'),
          content:
            // eslint-disable-next-line max-len
            this.translate.instant(
              'Are you sure you want to delete the selected items? If these items have also been used in other parts of Pencil, they will not be deleted from those surfaces.',
            ),

          okButtonText: this.translate.instant('Delete'),
        },
        panelClass: 'file-dialog',
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (!result.canceled) {
          const resources = this.selectedFiles.map((i) => this.currentFiles[i]._id);
          const folders = this.selectedFolders
            .filter((i) => !this.currentFolders[i].protected)
            .map((i) => this.currentFolders[i]._id);
          if (folders.length != this.selectedFolders.length) {
            const protectedFolders = this.selectedFolders
              .filter((i) => this.currentFolders[i].protected)
              .map((i) => this.currentFolders[i].name);
            this.snackbar.emit({
              error: false,
              context: 'file-not-deleted',
              msg:
                this.translate.instant(
                  'The following item(s) are protected and cannot be moved or deleted: ',
                ) + protectedFolders.join(', '),
            });
          }

          const toWatch: Observable<any>[] = [];
          if (resources.length) {
            toWatch.push(this.resourceService.deleteResources(resources));
          }
          if (folders.length) {
            toWatch.push(this.resourceService.deleteFolders(folders));
          }

          forkJoin(toWatch)
            .pipe(untilDestroyed(this))
            .subscribe((res) => {
              if (resources.length) {
                if (res[0]?.resources.length != resources.length) {
                  this.snackbar.emit({
                    error: true,
                    context: 'file-not-deleted',
                    msg: res[0]?.msg,
                  });
                }
                if (res[0]?.['err']) {
                  // for multiple files
                  this.snackbar.emit({
                    error: true,
                    context: 'file-not-deleted',
                    msg: res[0]['err'],
                  });
                } else {
                  const toBeDeleted: Set<string> = new Set(res[0]['resources']);
                  // Send the deleted resources upwards
                  if (res[0]?.resources.length) {
                    const deletedResources = res[0].resources.map((id: string) =>
                      this.currentFiles.find((file) => file._id == id),
                    );
                    this.deletedResources.emit(deletedResources);
                  }
                  // If there's active folder, then delete those files.
                  if (this.activeFolder) {
                    this.activeFolder.resources.total -= deleteItemsFrom(
                      toBeDeleted,
                      this.activeFolder.resources.files,
                    );
                  }
                }
              }

              if (folders.length) {
                const folderIndex = resources.length ? 1 : 0;
                if (res[folderIndex]?.['err']) {
                  this.snackbar.emit({
                    error: true,
                    context: 'file-not-deleted',
                    msg: res[folderIndex]['err'],
                  });
                } else {
                  this.snackbar.emit({
                    error: false,
                    context: 'folder-creation',
                    msg: this.translate.instant(
                      `${folders.length + resources.length} item${
                        folders.length + resources.length > 1 ? 's' : ''
                      } deleted`,
                    ),
                  });
                  const toBeDeleted = new Set(folders);
                  // If there's active folder, then delete those sub-folders.
                  // This will update the cache, since The cache has just a reference.
                  if (this.activeFolder) {
                    this.activeFolder.total -= deleteItemsFrom(
                      toBeDeleted,
                      this.activeFolder?.subdirectories,
                    );
                  }
                }
              }
              this.toggleAll(false);
            });
        }
        this.canSelect = true;
      });
  }

  toggleAll(check: boolean) {
    this.selectedFiles = [];
    this.selectedFolders = [];
    this.allSelected = check;
    this.currentFiles.forEach((file, i) => {
      if (check) {
        this.selectedFiles.push(i);
      }
      if (file.xMeta?.display) {
        file.xMeta.display.selected = check;
      }
    });

    this.currentFolders.forEach((folder, i) => {
      if (check) {
        this.selectedFolders.push(i);
      }
      if (folder.xMeta?.display) {
        folder.xMeta.display.selected = check;
      }
    });
    this.toggleSelectionEmitter.emit();
  }

  addFiles(files: IResource[], order: number, newlyCreated = false) {
    files.forEach((file) => {
      this.prepareFileForView(file, newlyCreated);
      if (order === 1) {
        this.activeFolder.resources.files.unshift(file);
      } else {
        this.activeFolder.resources.files.push(file);
      }
    });
    this.activeFolder.resources.total += files.length;
    if (order === 1) {
      // Since we are unshifting the current list of files, we need to adjust the indices of all selected files.
      incrementBy(this.selectedFiles, files.length);
    }
  }

  addFolders(
    folders: IDisplayFolder[],
    order: number,
    removeSelection = true,
    newlyCreated = false,
  ) {
    if (removeSelection) {
      // To remove all selected folders/files.
      this.removeSelectionView();
    }
    folders.forEach((folder) => {
      this.prepareFolderForView(folder, newlyCreated);
      order === 1
        ? this.activeFolder.subdirectories.unshift(folder)
        : this.activeFolder.subdirectories.push(folder);
    });
    this.activeFolder.total += folders.length;
    if (order === 1) {
      // Since we are unshifting the current list of files, we need to adjust the indices of all selected files.
      incrementBy(this.selectedFiles, folders.length);
    }
  }

  onScrollDown() {
    this.scroll.emit();
  }
}

import { ADT } from 'ts-adt';

// Messages generated by the client destined for the server
export type ClientMessages = ADT<{
  RequestSpaceContext: Record<string, unknown>; // request the space context from the server
  Ready: Record<string, unknown>; // indicate that the application is ready
  Subscribe: { eventId: EventId }; // subscribe to an event channel
  Unsubscribe: { eventId: EventId }; // unsubscribe from an event channel
  UserEvent: { userEvent: Event }; // send a user event to the server
  YJSUpdate: { update: Uint8Array }; // send the YJS delta to the server
}>;

// Messages generated by the server destined for the client
export type ServerMessages = ADT<{
  UserEvent: { userEvent: Event }; // notify the client of a new event
  YJSUpdate: { update: Uint8Array }; // send the new YJS delta to the client
  SpaceContext: { spaceContext: SpaceContext }; // send the spaces context to the client
}>;

// Events created by Mario developers
export interface Event {
  // The id that is used to identify the event, this should be used when subscribing
  eventId: EventId;
  // data to be forwarded
  data: unknown;
}

// ---- Context Information ----
export interface SpaceContext {
  marioId: MarioId;
  spaceUsersInfo: SpaceUsersInfo;
}

// ---- User Attribution ----
export interface SpaceUsersInfo {
  currentUserId: UserId; // id for the current user
  ownerId: UserId; // id of the user that owns the Mario app
  users: User[]; // list of all users
}

export interface User {
  _id: UserId; // unique id for the user
  name: string; // name of the user
  role: UserRole; // a role for the user
  profilePicture: string; // url for the users profile picture
}

export enum UserRole {
  HOST = 'HOST',
  PARTICIPANT = 'PARTICIPANT'
}

// ---- Type Aliases ----
export type EventId = string;
export type MarioId = string;
export type UserId = string;

import { SessionEvent } from '../sessions/session/SessionEvent';
import { Location } from '../state/temporary-user-metadata-repository.service';
import { Session } from './session';

export interface SessionsUpdate {
  sessionsUpdates: SessionUpdate[];
  userId: string;
  timestamp: Date;
}

export interface SessionUpdate {
  sessionId: string;
  session?: Session;
  events?: SessionEvent[];
  documentUpdate?: DocumentUpdate;
  serverStateVector?: ArrayBuffer;
  clientStateVector?: ArrayBuffer;
  isFirstSpaceSync?: boolean;
}
export interface FramePresence {
  [key: string]: string[];
}

export interface ServerStateUpdate {
  sessionId: string;
  serverStateVector: ArrayBuffer;
}

export interface DocumentUpdate {
  yjs_update?: ArrayBuffer;
}

export interface FrameUpdate {
  frameUID: string;

  // Array of event in case that we need to catch up a client
  mouse?: Mouse[];
}

export interface FrameObservableData {
  items: any[];
  uid: string;
  updatedAt?: number;
}

export interface Mouse {
  // type: What the user is doing with the mouse (draw, pointer, ...)
  type: string;
  // The mouse event emitted from the browser
  event?: string;
  point: Point;
  metadata: {
    userName: string;
    email: string;
    strokeWidth: number;
    state: string;
    uid: string;
    timestamp: number;
  };
}

export interface Point {
  x: number;
  y: number;
}

export interface JoinRoomsResponse {
  update: SessionsUpdate;
  joinedRooms: string[];
  sessions: Session[];
}

export interface SessionAuth {
  id: string;
  token: string;
  userId?: string;
  breakoutRoomId?: string;
}

// Used for frame events that are not persisted
export interface FrameEvent {
  userId?: string;
  type: FrameEventType;
  mouse?: Mouse[];
  receiverSocketId?: string;
  activeToolbar?: string;
}

export interface UserEvent {
  // The userId that this UserEvent is related to
  // typically the source of the event
  userId?: string;

  // The frame in which this event was created
  frameUID: string;

  // The type of event
  type: UserEventType;

  // The location on the canvas of this UserEvent
  location?: Location;
}

export enum UserEventType {
  LOCATION = 'location',
}

export enum FrameEventType {
  UserLeft = 'user-left',
  UserJoined = 'user-joined',
  Realtime = 'realtime',
  RequestFrame = 'request-frame',
}

export interface SpaceChunkInfo {
  spaceId: string;
  receivedBytes: number;
  chunkSize: number;
}

export interface SpaceDownloadStart {
  spaceSize: number;
  spaceId: string;
  session: any;
}

export interface GcsSpaceDownload {
  sessionId: string;
  gcsUrl?: string;
  exists: boolean;
}

export interface SpaceChunk {
  spaceId: string;
  chunk: ArrayBuffer;
}

export interface SpaceDownloadEnd {
  spaceId: string;
  spaceUpdate: SessionsUpdate;
}

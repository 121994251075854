import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  FileUploadError,
  MAX_FILE_NAME_CHARS,
  MAX_PDF_PAGES,
  MAX_FILE_SIZE_IN_MB,
} from 'src/app/models/upload';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filterNil } from '@ngneat/elf';
import { IconTypes } from '../../../../standalones/components/pencil-icon/pencil-icon.component';
import { UploadFileComputerService } from '../../../../services/upload-file-computer.service';

const PDF_MIME_TYPE = 'application/pdf';

@UntilDestroy()
@Component({
  selector: 'app-drag-drop-select-file',
  templateUrl: './drag-drop-select-file.component.html',
  styleUrls: ['./drag-drop-select-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragDropSelectFileComponent {
  @Input() supportedDocumentTypes = PDF_MIME_TYPE;
  @Input() customButtonText?: string;
  @Output() userSelectedFile = new EventEmitter<File>();
  @Output() importFileSubmit = new EventEmitter<void>();

  readonly file$ = new BehaviorSubject<File | undefined>(undefined);
  readonly errorMessage$: Observable<string>;

  constructor(
    private translateService: TranslateService,
    public uploadFileComputerService: UploadFileComputerService,
  ) {
    this.uploadFileComputerService.rawFile$.pipe(untilDestroyed(this)).subscribe((file) => {
      this.file$.next(file);
    });
    this.errorMessage$ = this.uploadFileComputerService.fileUploadError$.pipe(
      untilDestroyed(this),
      filterNil(),
      map((fileUploadError) => {
        const { fileName, error } = fileUploadError;
        switch (error) {
          case FileUploadError.SizeLimitExceed:
            return this.translateService.instant(
              `${fileName} exceeds file limit of ${MAX_FILE_SIZE_IN_MB} MB`,
            );
          case FileUploadError.NotSupportedType:
            if (fileName) {
              return this.translateService.instant(
                `Sorry, files of type ${this.getFileExtensionBasedOnLastDot(
                  fileName,
                )} are not supported.`,
              );
            } else {
              return this.translateService.instant('Sorry, This file type is not supported.');
            }
          case FileUploadError.TooManyPages:
            return this.translateService.instant(
              `${fileName} has more than ${MAX_PDF_PAGES} pages and cannot be imported.`,
            );
          case FileUploadError.Corrupt:
            return this.translateService.instant(`${fileName} is corrupt and cannot be imported`);
          case FileUploadError.Other:
            return this.translateService.instant(`Failed to upload file ${fileName}`);
          case FileUploadError.OneFileOnly:
            return this.translateService.instant('Sorry, we only allow single file uploads');
          case FileUploadError.TooLongFileName:
            return this.translateService.instant(
              `File name should not exceed ${MAX_FILE_NAME_CHARS} characters`,
            );
          default:
            return '';
        }
      }),
    );
  }

  onFileDropped(files: FileList) {
    this.file$.next(undefined);

    if (files.length > 1) {
      this.uploadFileComputerService.fileUploadError$.next({
        error: FileUploadError.OneFileOnly,
      });
      return;
    }

    const file = files.item(0);
    if (file) {
      this.processFile(file);
    }
  }

  selectFile(event: { target: { files: File[]; value } }) {
    this.file$.next(undefined);

    const file: File | undefined = event?.target.files[0];

    if (file) {
      this.processFile(file);
    }
    // to trigger the change event even if the user selected the same file.
    event.target.value = null;
  }

  private processFile(file: File) {
    this.file$.next(file);
    this.userSelectedFile.emit(file);
  }

  importFile() {
    this.importFileSubmit.emit();
  }

  private getFileExtensionBasedOnLastDot(fileName: string) {
    return fileName.split('.').pop();
  }

  protected readonly IconTypes = IconTypes;
  protected readonly MAX_FILE_SIZE_IN_MB = MAX_FILE_SIZE_IN_MB;
}

<div
  class="graph-wrapper"
  cdkDrag
  [cdkDragDisabled]="disableDrag"
  [style.width]="style.width + 'px'"
  [style.height]="style.height + 'px'"
>
  <app-gadget-header
    class="grabbable"
    icon="graph"
    CdkDragHandle
    [title]="'Graphing Calculator' | translate"
    (closed)="closed.emit()"
  >
  </app-gadget-header>
  <em class="pe-auto material-icons resize-icon" (mousedown)="onMouseDown($event)"
    >signal_cellular_4_bar</em
  >
  <div id="ggb-element" (mouseover)="disableDrag = true" (mouseout)="disableDrag = false"></div>
</div>

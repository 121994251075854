import * as moment from 'moment-timezone';

import { RECURRENCE_FREQUENCY_TYPE, DAYS, Recurrence } from '../models/event';
import { ordinalSuffix } from './string.utils';

const getRepeatLanguage = (recurrence: Recurrence, recurrenceEnd?: string | Date): string => {
  if (!recurrence) {
    return '';
  }

  const NUMBER_TO_DEGREE = {
    1: 'first',
    2: 'second',
    3: 'third',
    4: 'fourth',
    5: 'fifth',
  };

  const totalOccurrences = recurrence.totalOccurrences
    ? `, ${recurrence.totalOccurrences} times`
    : '';

  let text = '';
  switch (recurrence?.frequencyType) {
    case RECURRENCE_FREQUENCY_TYPE.DAYS: {
      text =
        recurrence.frequency === 1
          ? `Repeats daily${totalOccurrences}`
          : `Every ${recurrence.frequency} days${totalOccurrences}`;
      break;
    }

    case RECURRENCE_FREQUENCY_TYPE.MONTHS: {
      if (recurrence.times) {
        text =
          recurrence.frequency === 1
            ? `Repeats monthly on the ${ordinalSuffix(recurrence.times[0])}${totalOccurrences}`
            : `Every ${recurrence.frequency} months on the ${ordinalSuffix(
                recurrence.times[0],
              )}${totalOccurrences}`;
      } else {
        const monthlyTimeValues = recurrence.timesMonthly?.split(':');
        if (!monthlyTimeValues) {
          return '';
        }

        text =
          recurrence.frequency === 1
            ? `Repeats monthly on the ${NUMBER_TO_DEGREE[monthlyTimeValues[0]]} ${moment()
                .day(monthlyTimeValues[1])
                .format('dddd')}${totalOccurrences}`
            : `Every ${recurrence.frequency} months on the ${
                NUMBER_TO_DEGREE[monthlyTimeValues[0]]
              } ${moment().day(monthlyTimeValues[1]).format('dddd')}${totalOccurrences}`;
      }
      break;
    }

    case RECURRENCE_FREQUENCY_TYPE.WEEKS: {
      if (!recurrence.times) {
        return '';
      }

      recurrence.times.sort();
      const days = recurrence.times.map((time) => DAYS[time]);
      const lastDay = days.length > 1 && days.pop();
      const daysString = lastDay ? `${days.join(', ')} and ${lastDay}` : `${days[0]}s`;

      const onWeekdays = [1, 2, 3, 4, 5].every((time) => recurrence.times.includes(time));

      if (onWeekdays) {
        text = `Repeats on weekdays${totalOccurrences}`;
      } else {
        text =
          recurrence.frequency === 1
            ? `Repeats weekly on ${daysString}${totalOccurrences}`
            : `Every ${recurrence.frequency} weeks on ${daysString}${totalOccurrences}`;
      }

      break;
    }

    case RECURRENCE_FREQUENCY_TYPE.YEARS: {
      text =
        recurrence.frequency === 1
          ? `Repeats yearly${totalOccurrences}`
          : `Every ${recurrence.frequency} years${totalOccurrences}`;
      break;
    }

    default: {
      text = '';
    }
  }

  if (recurrenceEnd && !recurrence.totalOccurrences) {
    text = `${text}, until ${moment(recurrenceEnd).format('MMMM Do, YYYY')}`;
  }

  return text;
};

export { getRepeatLanguage };

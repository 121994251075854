import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fragmentUploadInprogressCheck',
  pure: false,
})
export class FragmentUploadInprogressCheckPipe implements PipeTransform {
  transform(fragments): any {
    let inProgress = false;
    fragments.forEach((f) => {
      if (f.type === 'IMAGE' && f.fragment?.uploadProgress) {
        inProgress = true;
      }
    });

    return inProgress;
  }
}

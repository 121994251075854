import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'topicName',
})
export class TopicNamePipe implements PipeTransform {
  transform(topic): any {
    return this.topicNameHelper(topic, '');
  }

  topicNameHelper(topic: any, topicString: string): any {
    if (!topic.child) {
      return `${topicString}(${topic.numbering}) ${topic.name}`;
    }
    const numbering = topic.numbering ? topic.numbering : '';
    return this.topicNameHelper(topic.child, `${topicString} ${numbering}`);
  }
}

<div [formGroupName]="formGroupName">
  <mat-radio-group
    [formControlName]="options.setting"
    aria-label="Select an option"
    class="form-group"
  >
    <div
      *ngFor="let selection of options.selections; index as i"
      #container
      [appTooltip]="selection?.disableOptions?.reason"
      [appTooltipDisabled]="!selection?.disableOptions?.disabled"
      [appTooltipCreateNewLine]="true"
      [appTooltipOffsetX]="150"
      appTooltipPlacement="center-top"
      [appTooltipPanelClass]="tooltipClasses$ | async"
      #tooltip="appTooltip"
      (click)="tooltip.toggle()"
    >
      <div
        class="radio-container"
        [class.selected]="form?.get(formGroupName)?.get(options.setting)?.value === selection.value"
        [class.disabled]="selection?.disableOptions?.disabled"
      >
        <mat-radio-button [value]="selection.value">
          <div class="text-container">
            <span
              class="title"
              [class.selected]="
                form?.get(formGroupName)?.get(options.setting)?.value === selection.value
              "
            >
              {{ selection.title }}
            </span>
            <span
              class="description"
              [class.selected]="
                form?.get(formGroupName)?.get(options.setting)?.value === selection.value
              "
            >
              {{ selection.description }}
            </span>
          </div>
        </mat-radio-button>

        <div
          class="extra-toggle-inputs"
          *ngIf="
            selection.subToggleInputs &&
            form?.get(formGroupName)?.get(options.setting)?.value === selection.value
          "
          [class.section-selected]="
            form?.get(formGroupName)?.get(options.setting)?.value === selection.value
          "
        >
          <app-user-settings-permissions-toggle
            *ngFor="let inputToggleOption of selection.subToggleInputs"
            [formGroup]="form"
            [options]="inputToggleOption"
          ></app-user-settings-permissions-toggle>
        </div>
      </div>
    </div>
  </mat-radio-group>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortEmail',
})
export class ShortEmailPipe implements PipeTransform {
  transform(email: string, ...args: unknown[]): string {
    return email.substring(0, 25) + (email.length > 25 ? '…' : '');
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { UserInfo } from '@angular/fire/auth';

@Component({
  selector: 'app-speech-bubble',
  templateUrl: './speech-bubble.component.html',
  styleUrls: ['./speech-bubble.component.scss'],
})
export class SpeechBubbleComponent implements OnInit {
  @Input() user: UserInfo | undefined;
  @Input() opacity = 1;
  constructor() {
    return;
  }

  ngOnInit(): void {
    return;
  }
}

import { BehaviorSubject } from 'rxjs';

export class Manager<T> {

  constructor(private generator: (key: string) => T){}

  private data: {[key: string]: T} = {};

  // Observable that publishes the keys in the data;
  public managerKeys = new BehaviorSubject<string[] | null>(null);


  private createIfNotExist(key: string): void {
    if (!this.data[key]) {
      this.data[key] = this.generator(key);
      this.publishKeys();
    }

  }


  public get(key: string): T {
    this.createIfNotExist(key);
    return this.data[key]

  }

  public remove(key: string): void {
    delete this.data[key];
    this.publishKeys();
  }

  private publishKeys() {
    const keys = Object.keys(this.data);
    this.managerKeys.next(keys);
  }

}


export class UniqueRefCounter extends Manager<Set<string>> {

  constructor() {
    super(() => new Set());
  }


  public incrementRef(ref: string, uniqueId: string): number {
    const set = this.get(ref);
    set.add(uniqueId);
    return set.size
  }


  public decrementRef(ref: string, uniqueId: string): number {
    const set = this.get(ref);

    if (set.size === 0) {
      return -1;
    }

    set.delete(uniqueId);
    return set.size;

  }

  public getRefs(ref: string): number {
    return this.get(ref).size;
  }
}



export class SubjectMap<T> extends Manager<BehaviorSubject<T | null>> {
  constructor() {
    super(() => new BehaviorSubject<T | null>(null))
  }



}

import { Pipe, PipeTransform } from '@angular/core';
import { FormArray } from '@angular/forms';

@Pipe({
  name: 'getEnabledDaysAvailability',
  pure: false,
})
export class GetEnabledDaysAvailabilityPipe implements PipeTransform {
  transform(slots: FormArray): number[] {
    return slots.controls.map((slotControl) => slotControl.get('day')?.value);
  }
}

import {
  Component,
  OnInit,
  QueryList,
  ViewChildren,
  ChangeDetectorRef,
  ViewChild,
  Input,
  HostBinding,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { HttpResponse } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TagsService } from 'src/app/services/tags.service';
import { FileFilterService } from 'src/app/services/file-filter.service';
import { modifiedSetTimeout } from 'src/app/utilities/ZoneUtils';
import { ResourcesService } from '../../services/resources.service';
import { UploadDialogComponent } from '../../dialogs/media-upload/upload-dialog.component';
import { FileDetails, UploadTaskComponent } from '../upload-task/upload-task.component';
import { UploadFileService } from '../../services/upload-file.service';
import { ResourceFilterParams, ResourceFilterParamsEnum } from '../../models/params';
import { FilterSet, FileParam } from '../../models/filters';
import {
  IResource,
  ResourceItemModel,
  IFolder,
  IFolderResponse,
  IDisplayFile,
  IDisplayFolder,
} from '../../models/resource';
import { MoveToFolderComponent } from '../../dialogs/move-to-folder/move-to-folder.component';
import { SnackbarComponent } from '../../ui/snackbar.component';
import { Feature } from '../../services/acl.service';
import { User, TopicNode, Course } from '../../models/user';
import { UserService } from '../../services/user.service';
import { CreateFolderDialogComponent } from '../../dialogs/create-folder-dialog/create-folder-dialog.component';
import { RhsDetailsComponent } from '../course/rhs-details/rhs-details.component';
import { FileViewComponent } from './file-view/base-file-view.component';

export enum animationStates {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
}

export interface IActiveFolder extends IFolderResponse {
  resources: {
    files: IDisplayFile[] | IResource[];
    page: number;
    pages: number;
    total: number;
  };
}

@UntilDestroy()
@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
})
export class FilesComponent implements OnInit {
  // Selected course syllabus.
  @Input() selectedCourse?: Course;

  @Input() enforcingFilter?: ResourceFilterParamsEnum;

  @Input() user?: User;

  @HostBinding('class') classes = this.userService.rtl.getValue()
    ? 'border-start flex-row-reverse'
    : 'border-end';

  isRtl$ = this.userService.rtl.asObservable();

  filterPanelOpen = false;
  topicFilterPanelOpen = false;
  // Used to expand and highligh Course filter portion.
  highlightingCourseFilter = false;
  filesLoading$ = new BehaviorSubject<boolean>(true);
  scrolling = false;
  filePage = 1;
  folderPage = 1;
  allSelected = false;
  canSelect = true;
  foldersPerPage = 1000; // show all folders
  filesPerPage = 25;
  isUploading = false;
  uploadFiles: Pick<IResource, 'url' | 'name' | 'size' | 'type'>[] = [];
  finalizedFiles = 0;
  actionText = 'Uploading...';
  uploadPanelRef: ReturnType<typeof setTimeout> | null = null;
  // opened folder in list view
  activeFolder?: IActiveFolder;
  @ViewChildren('uploadTask') UploadTasks!: QueryList<UploadTaskComponent>;
  maxNameLength = 40;
  maxCourseNameLength = 12;
  views = {
    LIST: 'LIST',
    GRID: 'GRID',
  };
  view: string;
  folderCache: Record<string, Record<string, IFolderResponse>> = {};
  rootId = '';
  institutionId = '';
  isMenuOpened = false;
  Features = Feature;
  @ViewChild('filesList') fileList!: FileViewComponent;
  filters: ResourceFilterParams = {};
  hiddenFilterSets: FilterSet[] = [];
  visibility = ['ME', 'TEACHERS', 'CLASS', 'PUBLIC'];

  // Used to hold the topics from topic panel, Keys are ids for fast lookup.
  topics: Map<string, TopicNode>;

  // Used as Input to topics panel to update it as needed.
  topicsSelectionIDs: string[];

  // Enum of resource types For html templates.
  resourceTypes = ResourceItemModel;

  // Enum of filter Types for html templates.
  filterTypes = FileParam;

  // Keep track of how many filters are applied.
  appliedFilters = 0;

  // Enum access for html
  ResourceFilterParamsEnum = ResourceFilterParamsEnum;

  // Subject for notifying filters of selection updates
  filtersUpdated: Subject<any>;

  // File summary menu
  showFileSummary = false;
  @ViewChild('fileSummary') fileSummary!: RhsDetailsComponent;

  constructor(
    public resourceService: ResourcesService,
    private router: Router,
    public dialog: MatDialog,
    public translate: TranslateService,
    public userService: UserService,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private changeDetection: ChangeDetectorRef,
    private tagService: TagsService,
    public fileFilterService: FileFilterService,
  ) {
    this.topicsSelectionIDs = [];
    this.topics = new Map();
    this.view = this.views.LIST;

    this.resourceService.createResourceIntent.pipe(untilDestroyed(this)).subscribe((show) => {
      if (show) {
        this.showUploadDialog();
      }
    });
    this.filtersUpdated = this.fileFilterService.filtersUpdated;
  }

  // Use this for selections
  get currentFiles(): IDisplayFile[] {
    return (this.activeFolder?.resources.files ?? []) as IDisplayFile[];
  }

  get selectedFiles(): IDisplayFile[] {
    return this.fileList?.selectedFilesObj ?? [];
  }

  // Use this for selections
  get currentFolders(): IDisplayFolder[] {
    return (this.activeFolder?.subdirectories ?? []) as IDisplayFolder[];
  }

  get selectedFolders(): IDisplayFolder[] {
    return this.fileList?.selectedFoldersObj ?? [];
  }

  private shouldSwitchView(view: string): boolean {
    return Object.values(this.views).includes(view) && view !== this.view;
  }

  ngOnInit(): void {
    this.fileFilterService.updateFilters();
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams) => {
      if (this.shouldSwitchView(queryParams.view)) {
        this.switchView();
      }
    });
    this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe((params) => {
      this.rootId = params.folderId || 'root';
      this.filePage = 1;
      this.folderPage = 1;
      this.fileFilterService.selectedCourse = this.selectedCourse;
      this.fileFilterService.enforcingFilter = this.enforcingFilter;
      this.fileFilterService.updateEnforcedFilters();
      const filter = this.fileFilterService.buildFilters();
      this.getFolder(this.rootId, filter).then((folder) => {
        if (!folder) {
          return;
        }
        this.appendFilesAndFolders(folder);
        this.institutionId = this.rootId === 'root' ? folder.name : folder.institution;
        if (this.rootId === 'root') {
          this.rootId = folder._id;
        }
      });
    });

    if (!this.user) {
      this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => (this.user = res?.user));
    }
  }

  async getFolder(
    folderId: string | undefined,
    filters: ResourceFilterParams = {},
  ): Promise<IFolderResponse | undefined> {
    if (folderId) {
      // check 'cache' if filters haven't changed
      // TODO: find a more suitable way such as hashing.
      const cachedFolder = this.folderCache[folderId]?.[JSON.stringify(filters)];
      if (cachedFolder?.page >= this.folderPage && cachedFolder?.resources.page >= this.filePage) {
        return cachedFolder;
      } else {
        this.filesLoading$.next(true);
        this.filters = filters;

        const folder = filters
          ? await this.resourceService
              .getFolder(folderId, {
                folder_num: this.foldersPerPage,
                folder_page: this.folderPage,
                resource_num: this.filesPerPage,
                resource_page: this.filePage,
                filters: filters,
              })
              .toPromise()
          : await this.resourceService
              .getFolder(folderId, {
                folder_num: this.foldersPerPage,
                folder_page: this.folderPage,
                resource_num: this.filesPerPage,
                resource_page: this.filePage,
              })
              .toPromise();
        if (folder) {
          this.saveFolder(folder);
        }
        this.filesLoading$.next(false);
        return folder;
      }
    }
  }

  appendFilesAndFolders(folder: IFolderResponse, replace = false): void {
    if (folder) {
      if (replace || folder._id !== this.activeFolder?._id) {
        this.activeFolder = folder;
      } else if (this.activeFolder?.page < folder.page) {
        this.activeFolder.page = folder.page;
        this.fileList.addFiles(folder.resources.files, -1);
      }
      // if not last page
      if (this.filePage <= folder.resources.page) {
        this.filePage++;
      }

      // if not last page
      if (this.folderPage <= folder.page) {
        this.folderPage++;
      }
    }
  }

  updateCacheFor(
    folderId: string,
    newResources: IDisplayFile[],
    newFolders: IDisplayFolder[],
  ): void {
    const emptyFilter = JSON.stringify({});
    // For now, We will need to invalidate cache for this folder, but we can keep the original list(without filters).
    const cachedFolder = this.folderCache[folderId]?.[emptyFilter];
    // Invalidating
    this.folderCache[folderId] = {};
    if (cachedFolder) {
      cachedFolder.resources.files.unshift(...newResources);
      cachedFolder.subdirectories.unshift(...newFolders);
      cachedFolder.total += newFolders.length;
      cachedFolder.resources.total += newResources.length;
    }
    this.folderCache[folderId][emptyFilter] = cachedFolder;
  }

  saveFolder(folder: IFolderResponse): void {
    const stringifiedFilters = JSON.stringify(this.filters);
    const cachedFolder = this.folderCache[folder._id]?.[stringifiedFilters];
    if (cachedFolder) {
      cachedFolder.resources.page = folder.resources.page;
      cachedFolder.page = folder.page;
      if (this.activeFolder?._id === folder._id) {
        this.fileList.addFiles(folder.resources.files, -1);
        this.fileList.addFolders(folder.subdirectories, -1, false);
      } else {
        cachedFolder.resources.files = cachedFolder.resources.files.concat(folder.resources.files);
        cachedFolder.subdirectories = cachedFolder.subdirectories.concat(folder.subdirectories);
      }
    } else {
      if (!this.folderCache[folder._id]) {
        this.folderCache[folder._id] = {};
      }
      this.folderCache[folder._id][stringifiedFilters] = folder;
    }
  }

  saveResource(fileDetails: FileDetails): void {
    if (this.finalizedFiles === this.uploadFiles.length - 1) {
      this.actionText = 'Finalizing...';
    }
    this.finalizedFiles++;
    const courses = this.selectedCourse?._id ? [this.selectedCourse._id] : [];
    if (fileDetails.name && fileDetails.size && this.activeFolder?._id) {
      const tempIResource: Partial<IResource> = {
        url: fileDetails.url,
        name: fileDetails.name,
        size: fileDetails.size,
        acl: {
          visibility: 'CLASS',
        },
        type: ((fDetails) => UploadFileService.checkFileType(fDetails))(fileDetails),
        metadata: {},
        courses: courses,
        folder: this.activeFolder?._id,
      };

      this.resourceService
        .createResource(tempIResource as IResource)
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          // insert to view
          if (res?.body) {
            if (this.activeFolder) {
              this.fileList.addFiles([res.body.resource], 1, true);
              this.fileSummary?.updateFileSummary([res.body.resource]);
            }
          }
          if (this.finalizedFiles === this.uploadFiles.length) {
            this.actionText = 'Finished upload';
            this.dismissUploadMonitor();
          }
        });
    }
  }

  createFolderDialog(): void {
    // Create a dialog asking folder name.
    const dialogRef = this.dialog.open(CreateFolderDialogComponent, {
      width: '500px',
      height: '255px',
      panelClass: 'folder-creating-dialog',
      data: {
        parent: this.activeFolder,
        title: this.translate.instant('Create new Folder from Selection'),
      },
    });

    // When it's closed, we check if the user wants ACTUALLY to create one.
    // if Yes, then we Call createFolder procedure.
    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((name) => {
        if (name) {
          this.createFolder(name);
        }
      });
  }

  createFolder(name: string): void {
    const parent = this.activeFolder?._id;
    const onSuccess = (res: HttpResponse<IFolder>) => {
      const body = <IFolder>res.body;
      if (this.activeFolder) {
        this.fileList.addFolders([body], 1, true, true);
      }

      this.changeDetection.detectChanges();
      this.showSnackbar({
        error: false,
        context: 'folder-creation',
        msg: this.translate.instant(
          `Folder ${body.name} created${
            this.selectedFiles.length + this.selectedFolders.length > 0
              ? ' with selected items'
              : ''
          }`,
        ),
      });
    };

    const onFailure = () =>
      this.showSnackbar({
        error: true,
        data: {
          context: 'folder-creation',
          msg: this.translate.instant('Failed to create folder'),
        },
      });

    this.resourceService
      .createFolder({
        name: name.trim(),
        acl: {
          visibility: 'CLASS',
          public: false,
        },
        parentFolder: parent,
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        (created) => {
          if (created && created.body) {
            this.movingSelectedFolderAndFilesTo({
              _id: created.body._id,
              name: created.body.name,
            })
              .pipe(untilDestroyed(this))
              .subscribe(
                () => onSuccess(created),
                () => onFailure(),
              );
          }
        },
        () => onFailure(),
      );
  }

  showUploadDialog(): void {
    const uploadRef = this.dialog.open(UploadDialogComponent, {
      width: '556px',
      panelClass: 'file-upload-dialog',
      data: {
        title: this.translate.instant('Upload File'),
        type: [this.resourceTypes.ANY],
      },
    });

    uploadRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((files) => {
        if (files) {
          this.keepUploadMonitor();
          this.uploadFiles = this.uploadFiles.concat(Array.from(files));
          this.isUploading = true;
          this.actionText = 'Uploading...';
        }
        this.resourceService.createResourceIntent.next(false);
      });
  }

  showMoveToFolderDialog() {
    const uploadRef = this.dialog.open(MoveToFolderComponent, {
      width: '418px',
      height: '501px',
      panelClass: 'move-to-folder-dialog',
      position: {
        top: '145px',
        right: '27px',
      },
      data: {
        selectedFiles: this.selectedFiles,
        selectedFolders: this.selectedFolders,
        activeFolderID: this.activeFolder?._id,
      },
    });

    uploadRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        // user cancel or closed the dialog, then do nothing.
        if (!data) {
          return;
        }

        if (data.selectedFolder._id === this.activeFolder?._id) {
          return this.showSnackbar({
            context: 'file-moved',
            msg: this.translate.instant(
              "You can't move your files to their current folder, Please Select another folder.",
            ),
          });
        }

        // Some feedback to show that his request is on progress.
        this.showSnackbar({
          context: 'file-moved',
          msg: this.translate.instant('Working on Moving your files.'),
          duration: 1000,
        });

        this.movingSelectedFolderAndFilesTo(data.selectedFolder)
          .pipe(untilDestroyed(this))
          .subscribe((res) => {
            // patching folders doesn't return the edited folders.
            const folders = this.selectedFolders.map((f) => f._id);
            const files = (res.resources as any)?.success || [];
            if (this.selectedFiles.length && !files.length) {
              return this.showSnackbar({
                context: 'file-moved',
                msg: this.translate.instant('Something is wrong, Try again!'),
                duration: 3000,
              });
            }
            // Reflecting Completion of moving on the screen.
            this.fileList.movingSelectionView(files, folders, data.selectedFolder);
          });
      });
  }

  movingSelectedFolderAndFilesTo(destFolder: {
    _id: string;
    name: string;
  }): Observable<{ resources: object | null; folders: object | null }> {
    const resources = this.selectedFiles.map((file) => ({ _id: file._id, folder: destFolder._id }));
    const folders = this.selectedFolders.map((folder) => ({
      _id: folder._id,
      parentFolder: destFolder._id,
    }));

    return forkJoin({
      resources: resources.length ? this.resourceService.editResources(resources) : of(null),
      folders: folders.length ? this.resourceService.editFolders(folders) : of(null),
    });
  }

  onScrollDown(): void {
    if (this.filesLoading$.getValue()) {
      return;
    }
    this.scrolling = true;
    this.getFolder(this.activeFolder?._id as string, this.fileFilterService.buildFilters()).then(
      (folder) => {
        if (folder) {
          this.appendFilesAndFolders(folder);
        }
        this.scrolling = false;
      },
    );
  }

  setSearchValue(searchVal: string): void {
    if (this.fileFilterService.search !== searchVal) {
      this.fileFilterService.search = searchVal;
      this.resetView();
    }
  }

  handleToggleFilter(event: any, filterSet: FilterSet): void {
    this.fileFilterService.toggleFilter(event ? event.filter : undefined, filterSet);
    this.resetView();
  }

  resetView(): void {
    this.toggleAll(false);
    this.filePage = 1;
    this.folderPage = 1;
    let folderId = this.activeFolder?._id as string;
    const filters = this.fileFilterService.buildFilters();
    if (!this.isFiltersApplied() && this.activeFolder?._id !== this.rootId) {
      folderId = this.rootId;
    }
    this.getFolder(folderId, filters).then((folder) => {
      if (folder) {
        this.appendFilesAndFolders(folder, true);
      }
    });
    this.showFileSummary = false;
  }

  openFolder(folder: string | IFolderResponse): void {
    this.toggleAll(false);
    this.clearSearch();
    this.fileFilterService.clearAllFilters();
    this.router.navigate([`/content/files/${this.institutionId}/${folder}`], {
      queryParams: { view: this.view },
    });
  }

  handleToggleSelection(): void {
    this.setAllSelected();
  }

  setAllSelected(): void {
    this.allSelected =
      this.currentFiles.length + this.currentFolders.length ===
      this.selectedFiles.length + this.selectedFolders.length;
  }

  toggleAll(check: boolean): void {
    this.fileList?.toggleAll(check);
  }

  onCancel(): void {
    this.UploadTasks?.forEach((child) => child.cancelUpload());
    this.actionText = 'Upload cancelled';
    this.finalizedFiles = this.uploadFiles.length;
    this.dismissUploadMonitor();
  }

  dismissUploadMonitor(): void {
    this.uploadPanelRef = modifiedSetTimeout(this.resetUpload.bind(this), 3000);
  }

  keepUploadMonitor(): void {
    if (this.uploadPanelRef) {
      clearTimeout(this.uploadPanelRef);
    }
    this.uploadPanelRef = null;
  }

  resetUpload(): void {
    this.uploadPanelRef = null;
    this.isUploading = false;
    this.uploadFiles = [];
    this.finalizedFiles = 0;
    this.actionText = 'Uploading...';
  }

  switchView(initialization = false): void {
    if (this.view === this.views.GRID) {
      this.view = this.views.LIST;
      this.maxNameLength = 40;
      this.filesPerPage = 1000;
    } else {
      this.view = this.views.GRID;
      this.maxNameLength = 20;
      this.filesPerPage = 25;
      // clear selection if a subfolder was selected
      if (!initialization && this.activeFolder?._id.toString() !== this.rootId) {
        this.toggleAll(false);
      }
    }
    // To push a new entry with new view param to browser history.
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { view: this.view },
      queryParamsHandling: 'merge',
    });
  }

  showSnackbar(options: any): void {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'center';
    config.duration = options.duration ?? 3000;
    config.data = options;
    config.panelClass = options.error ? 'profiles-snackbar-panel-error' : 'profiles-snackbar-panel';

    this.snackBar.openFromComponent(SnackbarComponent, config);
  }

  allowDrop($event: { preventDefault: () => void }): void {
    $event.preventDefault();
  }

  isTagOnAllSelected(tag: { _id: string }): boolean {
    let isOnAllSelected = true;
    let tagCount = 0;
    this.selectedFiles.forEach((selectedFile) => {
      const tagsAttribute = selectedFile.custom_attributes?.filter(
        (attribute: any) =>
          this.tagService.stringToTag(attribute).key ==
          this.tagService.tags.value.attribute_key._id,
      );
      if (
        tagsAttribute &&
        !tagsAttribute.find(
          (tagId: string) => tagId === `${this.tagService.tags.value.attribute_key._id}:${tag._id}`,
        )
      ) {
        isOnAllSelected = false;
      } else if (tagsAttribute) {
        tagCount++;
      }
    });
    this.selectedFolders.forEach((selectedFolder) => {
      const tagsAttribute = selectedFolder.custom_attributes?.filter(
        (attribute: any) =>
          this.tagService.stringToTag(attribute).key ==
          this.tagService.tags.value.attribute_key._id,
      );
      if (
        tagsAttribute &&
        !tagsAttribute.find(
          (tagId: string) => tagId === `${this.tagService.tags.value.attribute_key._id}:${tag._id}`,
        )
      ) {
        isOnAllSelected = false;
      } else if (tagsAttribute) {
        tagCount++;
      }
    });
    if (tagCount !== 0 && tagCount !== this.selectedFolders.length + this.selectedFiles.length) {
      isOnAllSelected = false;
    }
    return isOnAllSelected;
  }

  clearSearch(): void {
    this.fileFilterService.search = '';
  }

  /**
   * Returns true if there's any filter applied OR any search query.
   */
  isFiltersApplied(): boolean {
    return this.appliedFilters + this.fileFilterService.search.length > 0;
  }

  visibilitySelected(event: any): void {
    const visibilityFilterSet = this.hiddenFilterSets.find(
      (filterSet) => filterSet.name === FileParam.Visibility,
    );
    visibilityFilterSet?.filters.forEach((filter) => (filter.value = filter.item === event));
    this.resetView();
  }

  toggleTopicFilterPanel(): void {
    this.topicFilterPanelOpen = !this.topicFilterPanelOpen;
    if (!this.fileFilterService.selectedCourse) {
      this.highlightingCourseFilter = this.topicFilterPanelOpen;
      if (this.topicFilterPanelOpen) {
        this.filterPanelOpen = true;
      }
    } else {
      this.highlightingCourseFilter = false;
    }
  }

  openFileSummary(): void {
    this.showFileSummary = !this.showFileSummary;
  }
}

import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { extractMath, Segment } from 'extract-math';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, first } from 'rxjs';
import { User } from 'src/app/models/user';
import { AclService, Feature } from 'src/app/services/acl.service';
import { QuestionsService } from 'src/app/services/questions.service';
import { SpacesService } from 'src/app/services/spaces.service';
import { UserService } from 'src/app/services/user.service';
import { Fragment, FragmentMetadata } from '../../models/question';
import { EmbedVideoService } from '../../services/embed-video.service';
import { ImageFragmentDialogComponent } from '../image-fragment-dialog/image-fragment-dialog.component';
import { getVideoThumbnail } from '../../utilities/video.utils';

@UntilDestroy()
@Component({
  selector: 'ui-fragment-container',
  templateUrl: './fragment-container.component.html',
  styleUrls: ['./fragments.scss'],
})
export class FragmentContainerComponent implements OnInit {
  @Input() fragments?: Fragment[];
  @Input() styleOverride?: boolean;
  // If true, removes all line breaks from the individual fragment.
  @Input() compressedRendering?: boolean;
  @Input() level;
  @Input() number;
  @Input() metadata;
  @Input() print;
  @Input() question;
  @Input() note;
  @Input() explType;
  @Input() worksheet;
  @Input() enableCopy = false;
  @Input() signImage = false;
  @Input() showLoadingIndicator = false;
  @Input() fragmentNumber: number | string | undefined;

  @HostBinding('class.no-data-fragment') noData = false;

  ngOnInit() {
    this.noData = !this.fragments || this.fragments.length === 0;
  }
}

@UntilDestroy()
@Component({
  selector: 'ui-fragment',
  templateUrl: './fragment.component.html',
  styleUrls: ['./fragments.scss'],
})
export class FragmentComponent implements OnInit, OnChanges {
  segments: Segment[] = [];
  @Input() fragment?: Fragment;
  @Input() adaptiveWidth = true;
  @Input() styleOverride?: boolean;
  @Input() metadata;
  // If true, removes all line breaks from the individual fragment.
  @Input() compressedRendering?: boolean;
  @Input() question;
  @Input() note;
  @Input() explType;
  @Input() worksheet;
  @Input() print;
  @Input() signImage = false;
  @Input() showLoadingIndicator = false;
  @Input() replaceEquations = false;
  @Input() enableCopy = false;
  @Input() number: number | string | boolean = false;
  @Input() marks: number | boolean = false;
  @Input() showVideoThumbnail = false;
  _videoElementLoaded = false;

  @HostBinding('class.show-number') get showNumber(): boolean {
    return !!this.number;
  }

  lines: number[] = [];
  style?: Record<string, string> = {};
  videoUrl: any;
  videoThumbnail? = '';
  newFragment?: Fragment;
  user?: User;
  Features = Feature;
  imageIsSigned$ = new BehaviorSubject<boolean>(!this.signImage);
  constructor(
    private embedService: EmbedVideoService,
    public router: Router,
    private userService: UserService,
    private questionsService: QuestionsService,
    private toastrService: ToastrService,
    private translate: TranslateService,
    public aclService: AclService,
    private dialog: MatDialog,
    private spacesService: SpacesService,
  ) {
    this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res && res.user) {
        this.user = res && res.user;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const shouldSignImageOnFirstLoad =
      this.signImage &&
      changes.fragment?.isFirstChange() &&
      changes.fragment?.currentValue?.type === 'IMAGE' &&
      !!changes.fragment?.currentValue?.data;

    if (shouldSignImageOnFirstLoad) {
      this.spacesService
        .getSignedUrl(changes.fragment.currentValue.data)
        .pipe(first())
        .subscribe({
          next: ([signedUrl]) => {
            // change original image fragment to the signed url
            this.fragment!.data = signedUrl;
            this.imageIsSigned$.next(true);
          },
        });
    }

    if (changes.fragment?.previousValue !== changes.fragment?.currentValue) {
      const newFragment = changes.fragment.currentValue;
      if (newFragment?.type === 'TEXT' && newFragment.data) {
        this.segments = extractMath(newFragment.data);
      }
    }
  }

  ngOnInit() {
    this.imageIsSigned$.next(this.fragment?.type === 'IMAGE' && !this.signImage);

    if (
      this.router.url.includes('/view') ||
      this.router.url.includes('/present') ||
      this.router.url.includes('/print') ||
      this.router.url.includes('/worksheets/list')
    ) {
      this.newFragment = cloneDeep(this.fragment);
    }
    if (this.fragment) {
      this.style = this.setStyle();
      this.styleOverride = true;
    }
    if (this.metadata && this.metadata.lines) {
      for (let i = 0; i < this.metadata.lines; i++) {
        this.lines.push(i);
      }
    }
    if (this.fragment && this.fragment.type === 'TEXT' && this.fragment.data) {
      this.style = this.setStyle();
      // This.segments = extractMath(this.fragment.data, this.compressedRendering);

      // escape greater than symbol to prevent extractMath from removing it from string starting with >
      const messageData =
        this.fragment.data.charAt(0) === '>'
          ? this.fragment.data.replace('>', '\\>')
          : this.fragment.data;
      this.segments = extractMath(messageData);
    } else if (this.fragment && this.fragment.type === 'YOUTUBE' && this.fragment.data) {
      this.videoThumbnail = getVideoThumbnail(this.fragment.data);
      this.videoUrl = this.embedService.embed(this.fragment.data, {
        attr: { width: 600, height: 400 },
      });
    }
  }

  openImageDialog(fragment: Fragment): void {
    this.dialog.open(ImageFragmentDialogComponent, {
      data: {
        fragment,
      },
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'zero-padding-dialog',
    });
  }

  setStyle(): Record<string, string> | undefined {
    if (!this.fragment) {
      return;
    }

    const style = {};
    if (this.fragment.metadata && this.fragment.metadata.img_width) {
      style['width'] = this.fragment.metadata.img_width;
      style['max-width'] = 'initial';
    }
    if (this.fragment.metadata && this.fragment.metadata.content_alignment) {
      style['display'] = 'flex';
      style['justify-content'] = this.fragment.metadata.content_alignment;
    }
    return style;
  }

  onCopy(): boolean {
    return this.enableCopy;
  }

  saveSize(): void {
    if (
      !this.newFragment ||
      !this.user ||
      !this.aclService.isAllowed(this.user, this.Features.edit_question)
    ) {
      return;
    }
    if (!this.worksheet) {
      if (this.note) {
        this.questionsService.pageType.next('notes');
      } else if (this.question) {
        this.questionsService.pageType.next('questions');
      }
    }
    if (!this.newFragment.metadata) {
      this.newFragment.metadata = new FragmentMetadata();
    }
    if (
      this.router.url.includes('/content/notes/view') ||
      this.router.url.includes('/content/view')
    ) {
      this.newFragment.metadata.interface = 'landing';
    }
    if (
      this.router.url.includes('/content/notes/present') ||
      this.router.url.includes('/content/present')
    ) {
      this.newFragment.metadata.interface = 'presentation';
    }
    if (this.router.url.includes('/worksheets/present')) {
      this.newFragment.metadata.interface = 'worksheet';
    }
    if (this.router.url.includes('/worksheets/print')) {
      this.newFragment.metadata.interface = 'print';
    }
    if (this.router.url.includes('/worksheets/list')) {
      this.newFragment.metadata.interface = 'worksheet_list';
    }
    if (this.explType) {
      this.question.extra_set.forEach((ex) => {
        if (this.explType === ex.type) {
          ex.remark.forEach((f) => {
            this.updateFragment(f);
          });
          this.questionsService
            .saveExplanationData(ex, ex)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
              this.toastrService.success(
                this.translate.instant('Changed image size successfully!'),
              );
            });
        }
      });
      return;
    }
    if (this.note) {
      this.note.note_fragments.forEach((frag: Fragment) => {
        this.updateFragment(frag);
      });
      this.note.title_fragments.forEach((frag: Fragment) => {
        this.updateFragment(frag);
      });
      this.questionsService
        .saveQuestionData(this.note, this.note._id)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.toastrService.success(this.translate.instant('Changed image size successfully!'));
        });
    } else if (this.question) {
      this.question.question_fragments.forEach((frag: Fragment) => {
        this.updateFragment(frag);
      });
      if (this.question.multiple_choice) {
        this.question.multiple_choice.forEach((choice) => {
          choice.choice_fragments.forEach((frag: Fragment) => {
            this.updateFragment(frag);
          });
        });
      }
      if (this.question.notes_fragments) {
        this.question.notes_fragments.forEach((frag: Fragment) => {
          this.updateFragment(frag);
        });
      }
      if (this.question.answer.choice_fragments) {
        this.question.answer.choice_fragments.forEach((frag: Fragment) => {
          this.updateFragment(frag);
        });
      }
      if (this.question.sub_questions) {
        this.question.sub_questions.forEach((sub) => {
          sub.question_fragments.forEach((frag: Fragment) => {
            this.updateFragment(frag);
          });
          if (sub.notes_fragments) {
            sub.notes_fragments.forEach((frag: Fragment) => {
              this.updateFragment(frag);
            });
          }
          if (sub.answer && sub.answer.choice_fragments) {
            sub.answer.choice_fragments.forEach((frag: Fragment) => {
              this.updateFragment(frag);
            });
          }
        });
      }

      this.questionsService
        .saveQuestionData(this.question, this.question._id)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.toastrService.success(this.translate.instant('Changed image size successfully!'));
        });
    }
  }

  updateFragment(frag: Fragment): void {
    if (!this.newFragment) {
      return;
    }
    if (frag.data === this.newFragment.data) {
      if (frag.interface_metadata && this.newFragment.metadata) {
        if (
          frag.interface_metadata.findIndex(
            (m) => m.interface === this.newFragment?.metadata?.interface,
          ) > -1
        ) {
          frag.interface_metadata[
            frag.interface_metadata.findIndex(
              (m) => m.interface === this.newFragment?.metadata?.interface,
            )
          ] = this.newFragment.metadata;
        } else {
          frag.interface_metadata.push(this.newFragment.metadata);
        }
      } else if (this.newFragment.metadata) {
        frag.interface_metadata = [this.newFragment.metadata];
      }
    }
  }

  handleVideoRendered(): void {
    this._videoElementLoaded = true;
  }
}

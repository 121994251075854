import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Intercom } from 'ng-intercom';
import { BehaviorSubject } from 'rxjs';
import { TelemetryService } from 'src/app/services/telemetry.service';
import { UiService } from 'src/app/services/ui.service';

export enum RATING_SELECTION {
  UNSELECTED,
  POSITIVE,
  NEGATIVE,
}

export enum FEEDBACK_FEATURE {
  SPACES_EXPERIENCE,
  LESSON_GENERATOR,
}

enum TELEMETRY_EVENTS {
  RATING_SHOWN,
  RATING_INDICATION,
  RATING_RESPONSE,
}

const RATING_REASONS_LESSON_GENERATOR: Record<RATING_SELECTION, string[]> = {
  [RATING_SELECTION.NEGATIVE]: [
    'Content too easy',
    'Content too hard',
    'Content lacked depth',
    'Worksheets not useful',
    'Missing explanations',
    'Unhelpful resources',
    'Other',
  ],
  [RATING_SELECTION.POSITIVE]: [],
  [RATING_SELECTION.UNSELECTED]: [],
};

const RATING_REASONS: Record<RATING_SELECTION, string[]> = {
  [RATING_SELECTION.NEGATIVE]: [
    'Glitchy video',
    'Audio cutting out',
    'Echoes',
    'Background noise',
    'Connection issues',
    'Laggy',
    'Camera issues',
    'Microphone issues',
    'Bugs',
    'Missing features',
    'Other',
  ],
  [RATING_SELECTION.POSITIVE]: [],
  [RATING_SELECTION.UNSELECTED]: [],
};

const RATING_REASONS_BY_FEATURE: Record<FEEDBACK_FEATURE, Record<RATING_SELECTION, string[]>> = {
  [FEEDBACK_FEATURE.SPACES_EXPERIENCE]: RATING_REASONS,
  [FEEDBACK_FEATURE.LESSON_GENERATOR]: RATING_REASONS_LESSON_GENERATOR,
};

const TELEMETRY_EVENTS_BY_FEATURE: Record<FEEDBACK_FEATURE, Record<TELEMETRY_EVENTS, string>> = {
  [FEEDBACK_FEATURE.SPACES_EXPERIENCE]: {
    [TELEMETRY_EVENTS.RATING_SHOWN]: 'sessionQualityRatingShown',
    [TELEMETRY_EVENTS.RATING_INDICATION]: 'sessionQualityRatingIndication',
    [TELEMETRY_EVENTS.RATING_RESPONSE]: 'sessionQualityRatingResponse',
  },
  [FEEDBACK_FEATURE.LESSON_GENERATOR]: {
    [TELEMETRY_EVENTS.RATING_SHOWN]: 'lessonQualityRatingShown',
    [TELEMETRY_EVENTS.RATING_INDICATION]: 'lessonQualityRatingIndication',
    [TELEMETRY_EVENTS.RATING_RESPONSE]: 'lessonQualityRatingResponse',
  },
};

@UntilDestroy()
@Component({
  selector: 'app-spaces-experience-dialog',
  templateUrl: './spaces-experience-dialog.component.html',
  styleUrls: ['./spaces-experience-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOutHeight', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate(250, style({ opacity: 1, height: '*' })),
      ]),
      transition(':leave', [animate(250, style({ opacity: 0, height: 0 }))]),
    ]),
    trigger('fadeInOutWidth', [
      transition(':enter', [
        style({ opacity: 0, width: 0 }),
        animate(250, style({ opacity: 1, width: '*' })),
      ]),
      transition(':leave', [animate(250, style({ opacity: 0, width: 0 }))]),
    ]),
  ],
})
export class SpacesExperienceDialogComponent {
  @Output() closed: EventEmitter<RATING_SELECTION> = new EventEmitter();

  isSessionCompleted: boolean = false;
  isMobile: boolean = false;
  isMobile$ = new BehaviorSubject<boolean>(false);
  selectedRating$ = new BehaviorSubject<RATING_SELECTION>(RATING_SELECTION.UNSELECTED);
  selectedItems$ = new BehaviorSubject<Record<string, boolean>>({});
  RATING_SELECTION = RATING_SELECTION;
  RATING_REASONS: Record<RATING_SELECTION, string[]>;
  ratingForm: UntypedFormGroup;
  feedbackFeature: FEEDBACK_FEATURE;
  FEEDBACK_FEATURE = FEEDBACK_FEATURE;
  headerText!: string;
  feedbackText!: string;

  constructor(
    private uiService: UiService,
    private intercom: Intercom,
    private formBuilder: FormBuilder,
    private telemetryService: TelemetryService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    dialogData?: { isSessionCompleted: boolean; feedbackFeature: FEEDBACK_FEATURE },
    @Inject(MAT_BOTTOM_SHEET_DATA) sheetData?: { isSessionCompleted: boolean },
  ) {
    this.isSessionCompleted =
      (dialogData && dialogData.isSessionCompleted) ??
      (sheetData && sheetData.isSessionCompleted) ??
      false;
    this.ratingForm = this.formBuilder.group({
      feedback: [''],
    });
    this.uiService.isMobile.pipe(untilDestroyed(this)).subscribe(this.isMobile$); // Get latest mobile info
    this.isMobile$.pipe(untilDestroyed(this)).subscribe((v) => (this.isMobile = v));
    this.selectedRating$.pipe(untilDestroyed(this)).subscribe(() => {
      this.selectedItems$.next({});
      this.ratingForm.reset();
    });
    this.feedbackFeature = dialogData?.feedbackFeature ?? FEEDBACK_FEATURE.SPACES_EXPERIENCE;
    this.RATING_REASONS = RATING_REASONS_BY_FEATURE[this.feedbackFeature];
    this.telemetryService.event(
      TELEMETRY_EVENTS_BY_FEATURE[this.feedbackFeature][TELEMETRY_EVENTS.RATING_SHOWN],
    );
    this._populateText();
  }

  pillToggled(item: string): void {
    if (this.selectedItems$.value[item]) {
      const currentItems = this.selectedItems$.value;
      delete currentItems[item];
      this.selectedItems$.next(currentItems);
    } else {
      this.selectedItems$.next({
        ...this.selectedItems$.value,
        [item]: true,
      });
    }
  }

  ratingToggled(positive: boolean): void {
    if (!positive) {
      if (this.selectedRating$.value === RATING_SELECTION.NEGATIVE) {
        this.selectedRating$.next(RATING_SELECTION.UNSELECTED);
      } else {
        this.selectedRating$.next(RATING_SELECTION.NEGATIVE);
        this.telemetryService.event(
          TELEMETRY_EVENTS_BY_FEATURE[this.feedbackFeature][TELEMETRY_EVENTS.RATING_INDICATION],
          {
            score: 0,
          },
        );
      }
    } else {
      this.selectedRating$.next(RATING_SELECTION.POSITIVE);
      this.submitFeedback();
    }
  }

  openIntercom(): void {
    try {
      this.intercom.show();
    } catch (e) {
      window.open('https://www.pencilspaces.com/support', '_blank');
    }
  }

  onClose(): void {
    this.closed.emit(RATING_SELECTION.UNSELECTED);
  }

  submitFeedback(): void {
    const data = {
      score: this.selectedRating$.value === RATING_SELECTION.POSITIVE ? 1 : 0,
      reasonItems: Object.keys(this.selectedItems$.value),
      reasonItemsString: Object.keys(this.selectedItems$.value).join(' | '),
      reasonNote: this.ratingForm.controls.feedback.value,
      isMobile: this.isMobile,
    };
    this.telemetryService.event(
      TELEMETRY_EVENTS_BY_FEATURE[this.feedbackFeature][TELEMETRY_EVENTS.RATING_RESPONSE],
      data,
    );
    this.closed.emit(this.selectedRating$.value);
  }

  private _populateText(): void {
    if (this.feedbackFeature === FEEDBACK_FEATURE.LESSON_GENERATOR) {
      this.headerText = this.translate.instant('How was the quality of the AI generated lesson?');
      this.feedbackText = this.translate.instant('Sorry to hear that. What happened?');
    } else {
      if (this.isSessionCompleted) {
        this.headerText = this.translate.instant(
          'How was Pencil Spaces for you today? Your feedback helps us improve your experience.',
        );
        this.feedbackText = this.translate.instant('Sorry to hear that. What happened?');
      } else {
        this.headerText = this.translate.instant(
          'How has Pencil Spaces been for you today? Your feedback helps us improve your experience.',
        );
        this.feedbackText = this.translate.instant('Sorry to hear that. What is happening?');
      }
    }
  }
}

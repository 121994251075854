import { Pipe, PipeTransform } from '@angular/core';
import { last } from 'lodash-es';
import * as moment from 'moment-timezone';
import { isContentMessage } from '../common/utils/messaging';
import { MessageGroupByDate, TypedMessage } from '../models/messaging';

@Pipe({
  name: 'messageGroupsByDate',
})
export class MessageGroupsByDatePipe implements PipeTransform {
  private timezone = moment.tz.guess();
  transform(messages: TypedMessage[]): MessageGroupByDate[] {
    return messages.reduce((accumulator: MessageGroupByDate[], message) => {
      if (isContentMessage(message)) {
        const currentGroup = last(accumulator);
        const messageDate = moment(message.createdAt).tz(this.timezone).format('MMM Do, YYYY');
        if (currentGroup && isContentMessage(currentGroup) && currentGroup.date === messageDate) {
          currentGroup.messages.push(message);
        } else {
          accumulator.push({
            date: messageDate,
            messages: [message],
            mostRecentTimestamp: message.firstAttemptTimestamp || message.createdAt,
          });
        }
        return accumulator;
      } else {
        accumulator.push(message);
        return accumulator;
      }
    }, []);
  }
}

import { Injectable, Injector } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SyncService } from '../common/interfaces/sync-service-interface';
import { SessionsSyncService } from './sessions-sync.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class RealtimeSpaceService {
  service: SyncService;
  constructor(private injector: Injector) {
    this.service = this.injector.get(SessionsSyncService);
  }
}

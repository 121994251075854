import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

import { Worksheet } from '../content/worksheets/worksheet';
import { DIFFICULTY, Explanation, Question, VISIBILITY } from '../models/question';
import { WorksheetFilters } from '../models/worksheet';
import { Course } from '../models/user';
import { QuestionContainer } from '../content/lhs/lhs.component';
import { FragmentCollection } from '../content/course/create/create.model';
import { URLService } from './dynamic-url.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class QuestionsService {
  selectedCourseId = new BehaviorSubject('');
  selectedCourse = new BehaviorSubject<Course | null>(null);
  isDetailViewOpened = new BehaviorSubject(false);
  pageType = new BehaviorSubject('questions');
  removedQuestionId = new BehaviorSubject('');
  relatedQuestions = new BehaviorSubject<QuestionContainer[]>([]);
  relatedQuestionTypes = new BehaviorSubject<string[]>([]);
  relatedQuestionIds = new BehaviorSubject<string[]>([]);
  worksheetsUpdated = new BehaviorSubject(false);
  editorCursorPosition = new BehaviorSubject(0);
  isTyping = new BehaviorSubject(false);
  scrollToComment = new BehaviorSubject<HTMLElement | null>(null);
  showPracticeButton = new BehaviorSubject(true);
  addSet = new BehaviorSubject(false);
  operation = new BehaviorSubject<Record<string, string | undefined> | null>(null);
  isComprehensionReferring = new BehaviorSubject(false);
  shiftEnterClicked = new BehaviorSubject('');
  isKatexDialogOpened = new BehaviorSubject(false);
  isPublicQuestionView = new BehaviorSubject(false);
  focusedCollection = new BehaviorSubject<FragmentCollection | null>(null);
  textSelected = new BehaviorSubject(false);
  formulaSelected = new BehaviorSubject(false);
  selectedFormat = new BehaviorSubject<Record<string, string | boolean | undefined> | null>(null);
  enableListFormat = new BehaviorSubject(true);

  constructor(private http: HttpClient, private urlService: URLService) {}

  getImageFromURL(imageUrl: string): Observable<Blob> {
    return this.http.get(imageUrl, { responseType: 'blob' });
  }

  saveQuestionData(
    question: Question,
    id?: string,
    type?: string,
    spaceId?: string,
  ): Observable<any> {
    let saveURL;
    if (type) {
      saveURL = `${this.urlService.getDynamicUrl()}/tutor/${type}`;
    } else {
      saveURL = `${this.urlService.getDynamicUrl()}/tutor/${this.pageType.value}`;
    }
    if (id) {
      saveURL += `/${id}`;
    }
    if (type) {
      type = type === 'questions' ? 'question' : 'note';
    }
    const body = {
      [type ? type : this.pageType.value === 'questions' ? 'question' : 'note']: question,
      sessionId: spaceId,
    };
    return this.http.post(saveURL, JSON.stringify(body), httpOptions).pipe(retry(3));
  }

  getViewedQuestions(page = 1) {
    return this.http.get(
      `${this.urlService.getDynamicUrl()}/tutor/${this.pageType.value}/viewed?page=${page}`,
    );
  }

  getRelatedQuestions(id: string, from: string, to: string) {
    return this.http.get(
      `${this.urlService.getDynamicUrl()}/tutor/related/${id}?from=${from}&to=${to}`,
    );
  }

  createSubQuestion(id: string, subId?: string): Observable<any> {
    const saveURL = `${this.urlService.getDynamicUrl()}/tutor/${this.pageType.value}/${id}/sub/${
      subId || id
    }`;
    return this.http.post(saveURL, null, httpOptions).pipe(retry(3));
  }

  cloneQuestion(id: string): Observable<any> {
    const url = `${this.urlService.getDynamicUrl()}/tutor/${this.pageType.value}/${id}/clone`;
    return this.http.post(url, '', httpOptions);
  }

  getExplanationData(questionId: string) {
    const url = `${this.urlService.getDynamicUrl()}/tutor/explanation/${questionId}`;
    return this.http.get(url, httpOptions).pipe(retry(3));
  }

  saveExplanationData(res: any, explanation: Explanation) {
    let explURL = `${this.urlService.getDynamicUrl()}/tutor/explanation/${res.question_id}`;
    if (res.explanation_id) {
      explURL = `${this.urlService.getDynamicUrl()}/tutor/explanation/${res.question_id}/${
        res.explanation_id
      }`;
    } else if (explanation._id) {
      explURL = `${this.urlService.getDynamicUrl()}/tutor/explanation/${res.question_id}/${
        explanation._id
      }`;
    }
    return this.http.post(explURL, JSON.stringify({ explanation }), httpOptions).pipe(retry(3));
  }

  getQuestionData(id: string, type?: string): Observable<any> {
    let questionURL;
    if (type) {
      questionURL = `${this.urlService.getDynamicUrl()}/tutor/${type}/`;
    } else {
      questionURL = `${this.urlService.getDynamicUrl()}/tutor/${this.pageType.value}/`;
    }
    return this.http.get(questionURL + id);
  }

  deleteQuestion(id: string, type?: string): Observable<any> {
    let questionURL;
    if (type) {
      questionURL = `${this.urlService.getDynamicUrl()}/tutor/${type}/${id}`;
    } else {
      questionURL = `${this.urlService.getDynamicUrl()}/tutor/${this.pageType.value}/${id}`;
    }
    return this.http.delete(questionURL);
  }

  deleteExplanation(questionID: string, explanationID: string): Observable<any> {
    const url = `${this.urlService.getDynamicUrl()}/tutor/explanation/${questionID}/${explanationID}`;
    return this.http.delete(url);
  }

  deleteSubQuestion(id: string, subId: string): Observable<any> {
    const questionURL = `${this.urlService.getDynamicUrl()}/tutor/${
      this.pageType.value
    }/${id}/sub/${subId}`;
    return this.http.delete(questionURL);
  }

  createWorksheet(wsBuilder: any): Observable<any> {
    const wsURL = `${this.urlService.getDynamicUrl()}/tutor/worksheet`;
    return this.http.post(wsURL, JSON.stringify(wsBuilder), httpOptions);
  }

  addToWorksheet(wsBuilder: any, id: string): Observable<any> {
    const wsURL = `${this.urlService.getDynamicUrl()}/tutor/worksheet/${id}/add`;
    return this.http.post(wsURL, JSON.stringify(wsBuilder), httpOptions);
  }

  getWorksheetsByCourse(
    courseId: string,
    page = 1,
    num = 20,
    filters?: WorksheetFilters,
  ): Observable<any> {
    let queryString = `${courseId}?page=${page}&num=${num}`;

    if (filters) {
      const filtersStr = btoa(JSON.stringify(filters));
      queryString += `&filters=${filtersStr}`;
    }

    return this.http.get(
      `${this.urlService.getDynamicUrl()}/tutor/worksheet/course/${queryString}`,
    );
  }

  getWorksheets(): Observable<any> {
    return this.http.get(`${this.urlService.getDynamicUrl()}/tutor/worksheet`);
  }

  getWorksheetsByQuery(query: string, page = 1, num = 20): Observable<any> {
    const queryString = `page=${page}&num=${num}`;
    return this.http.get(
      `${this.urlService.getDynamicUrl()}/tutor/worksheet/search/${query}?${queryString}`,
    );
  }

  getWorksheet(id: string): Observable<any> {
    return this.http.get(`${this.urlService.getDynamicUrl()}/tutor/worksheet/${id}`);
  }

  deleteWorksheet(id: string): Observable<any> {
    return this.http.delete(`${this.urlService.getDynamicUrl()}/tutor/worksheet/${id}`);
  }

  deleteWorksheetQuestion(worksheetId: string, questionId: string) {
    return this.http.delete(
      `${this.urlService.getDynamicUrl()}/tutor/worksheet/${worksheetId}/remove/${questionId}`,
    );
  }

  cloneSet(setId: string) {
    return this.http.post(
      `${this.urlService.getDynamicUrl()}/tutor/worksheet/${setId}/clone`,
      null,
    );
  }

  updateWorksheet(ws: Worksheet): Observable<any> {
    const wsURL = `${this.urlService.getDynamicUrl()}/tutor/worksheet/${ws._id}/update`;
    const worksheet = Object.assign({}, ws);
    return this.http.post(wsURL, JSON.stringify({ worksheet }), httpOptions);
  }

  migrateResources(migrationBuilder: any): Observable<any> {
    const migrateURL = `${this.urlService.getDynamicUrl()}/tutor/ingest`;
    return this.http.post(migrateURL, JSON.stringify(migrationBuilder), httpOptions);
  }

  enablePractice(courseId: string) {
    return this.http.get(
      `${this.urlService.getDynamicUrl()}/tutor/practice/enable?courseId=${courseId}`,
    );
  }

  getRecommendedTopics(id: string, from = 'question'): Observable<any> {
    return this.http.get(
      `${this.urlService.getDynamicUrl()}/tutor/recommended/topic/${id}?from=${from}`,
    );
  }

  migrateWorksheet(
    worksheetId: string,
    data: {
      institution: string;
      author: string;
      course: string;
    },
  ): Observable<any> {
    return this.http.post(
      `${this.urlService.getDynamicUrl()}/tutor/ingest/worksheet/${worksheetId}`,
      {
        institution: data.institution,
        author: data.author,
        course: data.course,
        courses: [data.course],
      },
    );
  }

  deleteQuestions(ids: (string | undefined)[]): Observable<any> {
    const filteredIDs = ids.filter((id) => !!id);
    return this.http.post(
      `${this.urlService.getDynamicUrl()}/tutor/institution/content/bulk/delete`,
      {
        model: this.pageType.value === 'questions' ? 'Question' : 'Note',
        questions: filteredIDs,
      },
    );
  }

  bulkUpdateVisibility(
    ids: (string | undefined)[],
    value: VISIBILITY,
    filters?: string,
  ): Observable<any> {
    const filteredIDs = ids.filter((id) => !!id);
    return this.http.post(`${this.urlService.getDynamicUrl()}/tutor/institution/content/bulk`, {
      model: this.pageType.value === 'questions' ? 'Question' : 'Note',
      questions: filteredIDs,
      visibility: value,
      filters,
    });
  }

  bulkUpdateDifficulty(
    ids: (string | undefined)[],
    value: DIFFICULTY | null,
    filters?: string,
  ): Observable<any> {
    const filteredIDs = ids.filter((id) => !!id);
    return this.http.post(`${this.urlService.getDynamicUrl()}/tutor/institution/content/bulk`, {
      model: this.pageType.value === 'questions' ? 'Question' : 'Note',
      questions: filteredIDs,
      difficulty: value,
      filters,
    });
  }
}

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { LikeContext } from 'src/app/models/user';
import { Observable } from 'rxjs';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-resource-card',
  templateUrl: './resource-card.component.html',
  styleUrls: ['./resource-card.component.scss'],
})
export class ResourceCardComponent implements OnInit, AfterViewInit {
  hidden = false;
  likeContext?: LikeContext;

  @Input() title = 'test';
  @Input() titleIcon = '';
  @Input() titleIconClass = '';
  @Input() content = ['test'];
  @Input() selected = false;
  @Input() collapsible = false;
  @Input() showHeaderRightSection = false;
  @Input() showAddButton = false;
  @Input() updateHidden?: Observable<void>;
  @Output() addEvent = new EventEmitter();

  @Output() deleteContent = new EventEmitter();

  @ViewChild('card', { read: ElementRef }) elementRef!: ElementRef;

  ngOnInit(): void {
    if (this.updateHidden) {
      this.updateHidden.pipe(untilDestroyed(this)).subscribe(() => {
        if (!this.hidden) {
          this.checkHeight();
        }
      });
    }
  }

  ngAfterViewInit(): void {
    // requestAnimationFrame used to avoid angular ExpressionChangedAfterItHasBeenCheckedError
    requestAnimationFrame(() => {
      this.checkHeight();
    });
  }

  checkHeight(): void {
    if (this.collapsible) {
      this.hidden = this.elementRef?.nativeElement.offsetHeight > 265;
    }
  }
}

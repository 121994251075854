<div class="upgrade-version-modal">
  <div class="d-flex justify-content-center top-img">
    <img src="../../../assets/icons/box-update.svg" />
  </div>
  <div class="header">{{ 'Update to continue' | translate }}</div>
  <div class="message">
    {{
      'The latest version of Pencil Spaces is loading. We apologize for interrupting your flow.'
        | translate
    }}
  </div>
  <div *ngIf="!weakNetwork" class="message">
    {{
      'Thank you for accommodating us as we work to improve your experience on Pencil Spaces.'
        | translate
    }}
  </div>
  <div *ngIf="weakNetwork" class="message message-bold">
    {{
      'It looks like your internet connection may be unstable. Please wait a moment as we download this update.'
        | translate
    }}
  </div>
  <mat-dialog-actions class="justify-content-end align-items-end">
    <button disabled mat-button class="ok-button btn-clear">
      <app-pencil-spinner
        class="upgrade-notification-spinner cmr-8"
        [diameter]="21"
        [strokeWidth]="2"
      >
      </app-pencil-spinner>
      <span>{{ 'Updating...' | translate }}</span>
    </button>
  </mat-dialog-actions>
  <div class="version-info" *ngIf="versions?.version">
    <span>Current version: {{ versions.version }}</span>
  </div>
</div>

import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { startCase } from 'lodash';
import { lastValueFrom } from 'rxjs';
import { SpacesService } from 'src/app/services/spaces.service';
import { getTimeString } from '../session_analytics_utils';
import {
  AnalyticsEventsTableDataSource,
  AnalyticsEventsTableItem,
} from './analytics-events-table-datasource';

@Component({
  selector: 'app-analytics-events-table',
  templateUrl: './analytics-events-table.component.html',
  styleUrls: ['./analytics-events-table.component.scss'],
})
export class AnalyticsEventsTableComponent implements OnChanges {
  @Input() currentSessionId;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild('exporter') exporter;
  dataSource?: AnalyticsEventsTableDataSource;
  allEvents;
  loading = false;
  eventsBySessionCache: Map<string, Array<any>> = new Map();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name', 'time', 'eventType'];

  constructor(private spacesService: SpacesService) {}

  async ngOnChanges(): Promise<void> {
    if (!this.currentSessionId) {
      return;
    }
    this.loading = true;
    if (this.eventsBySessionCache.has(this.currentSessionId)) {
      this.allEvents = this.eventsBySessionCache.get(this.currentSessionId);
    } else {
      this.allEvents = await lastValueFrom(
        this.spacesService.getEventsBySession(this.currentSessionId),
      );
      this.eventsBySessionCache.set(this.currentSessionId, this.allEvents);
    }
    this.loading = false;
    const data = this.allEvents.map(
      (analyticsEvent): AnalyticsEventsTableItem => ({
        name: analyticsEvent.user.name,
        time: getTimeString(analyticsEvent.timestamp),
        eventType: startCase(analyticsEvent.eventType),
      }),
    );
    this.dataSource = new AnalyticsEventsTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}

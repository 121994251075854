import {
  Component,
  HostListener,
  HostBinding,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  AfterViewChecked,
  AfterViewInit,
  ViewChild,
  ElementRef,
  NgZone,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { Intercom } from 'ng-intercom';
import { MatDialog } from '@angular/material/dialog';

import { KeyScenariosOnSpaces, TelemetryService } from 'src/app/services/telemetry.service';

import { lastValueFrom, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, first, map, take, takeWhile } from 'rxjs/operators';
import { CdkDragStart } from '@angular/cdk/drag-drop';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { DeviceDetectorService } from 'ngx-device-detector';
import { SettingsService } from 'src/app/services/settings.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

import { AuthService } from './services/auth.service';
import { PwaService } from './services/pwa-service.service';
import { UserService } from './services/user.service';
import { NavService } from './services/nav.service';
import { QuestionsService } from './services/questions.service';
import { ResourcesService } from './services/resources.service';
import { SetTimeLocaleService } from './services/set-time-locale.service';
import { AclService, Feature } from './services/acl.service';
import { UiService } from './services/ui.service';

import { Theme } from './common/themes/themes';
import { FlagsService, FLAGS } from './services/flags.service';
import { EventService } from './services/event.service';
import { User, UserData, UserInfo } from './models/user';
import { ThemeService } from './services/theme.service';
import { NewMessageDialogComponent } from './messages/new-message-dialog/new-message-dialog.component';
import { MessagingService } from './services/messaging.service';
import {
  NewConversationRequest,
  NewGroupConversationRequest,
  NewUserConversationRequest,
} from './models/messaging';
import { isUserConversation } from './common/utils/messaging';
import { URL_CONSTANTS } from './common/utils/url';
import { URLService } from './services/dynamic-url.service';
import { RevokedInfoDialogComponent } from './dialogs/revoked-info-dialog/revoke-info-dialog.component';
import { ProfilesService } from './services/profiles.service';
import { SessionSharedDataService, SessionView } from './services/session-shared-data.service';
import { AppRatingService } from './services/app-rating.service';
import { DomListenerFactoryService } from './services/dom-listener-factory.service';
import { DomListener } from './utilities/DomListener';
import { pencilLogoText } from './app.constants';
import { panelID } from './settings/settings.constants';
import { SUBSCRIPTION_TYPES, SubscriptionStatus } from './models/payment';
import {
  userBannerTrailPeriodText,
  SHOULD_SHOW_BANNER_PERIOD,
  shouldShowBillingStatusIndicator,
} from './utilities/payment.utils';
import { SpaceRepository } from './state/space.repository';
import { UIPropsRepository } from './state/ui-props.repository';
import { ReferralService } from './services/referral.service';
import { ModalManagerService } from './services/modal-manager.service';
import { ReferralAcceptedDialogComponent } from './common/referral-accepted-dialog/referral-accepted-dialog.component';
import { DeviceAndBrowserDetectorService } from './services/device-and-browser-detector.service';
import {
  PaywallIndicatorService,
  UpgradePlanEvaluationType,
} from './services/paywall-indicator.service';
import { CountedMetric } from './services/performance.logger.service';
import { ForegroundActivityService } from './services/foreground-activity.service';
import { NgZoneHolderService } from './services/ng-zone-holder.service';
import { modifiedSetTimeout } from './utilities/ZoneUtils';
import { UploadFileService } from './services/upload-file.service';
import { NsfwDetectorService } from './nsfw-detector.service';
const MIN_KEYBOARD_HEIGHT = 300; // This might not always be correct

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
  @HostBinding('class.app-mobile-view') isMobileView = false;
  @HostBinding('class.is-keyboard-open') isKeyboardOpen = false;

  @ViewChild('applicationContent')
  public applicationContentElement!: ElementRef<HTMLElement>;

  public appLoading = false;
  updateAvailable = false;
  showUpgradeBanner = false;
  isUploading = false;
  showEditor = false;
  fullScreen = false;
  dragElement: any;
  menuOpened = true;
  env = environment;
  user?: User;
  Features = Feature;
  theme?: Theme;
  URL_CONSTANTS = URL_CONSTANTS;

  showNotificationBell = false;
  updateTimeout;
  isRevokedModalShown = false;
  isListeningToUserLogout = false;
  pencilLogoText = pencilLogoText;
  customServeUrls: { [key: string]: string } = {};
  domListener: DomListener;
  isCustomFlagsEnabled$ = this.flagsService.isCustomFlagsEnabled;
  planEvaluationTypes = UpgradePlanEvaluationType;
  isFreeUser = false;

  constructor(
    public authService: AuthService,
    public pwaService: PwaService,
    public router: Router,
    public userService: UserService,
    public navService: NavService,
    public questionsService: QuestionsService,
    public resourceService: ResourcesService,
    public deviceService: DeviceDetectorService,
    private swPush: SwPush,
    private updates: SwUpdate,
    public intercom: Intercom,
    private flagsService: FlagsService,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    public eventService: EventService,
    private themeService: ThemeService,
    private gtmService: GoogleTagManagerService,
    private aclService: AclService,
    private messagingService: MessagingService,
    private dialog: MatDialog,
    private setTimeLocaleService: SetTimeLocaleService,
    public uRLService: URLService,
    private cd: ChangeDetectorRef,
    public profilesService: ProfilesService,
    private spaceSharedDataService: SessionSharedDataService,
    private appRatingService: AppRatingService,
    private uiService: UiService,
    private telemetry: TelemetryService,
    private deviceAndBrowserDetectorService: DeviceAndBrowserDetectorService,
    private settingsService: SettingsService,
    private spaceRepo: SpaceRepository,
    private uiRepo: UIPropsRepository,
    private translateService: TranslateService,
    private referralService: ReferralService,
    private modalManagerService: ModalManagerService,
    public activatedRoute: ActivatedRoute,
    private domListenerFactoryService: DomListenerFactoryService,
    private paywallIndicatorService: PaywallIndicatorService,
    private foregroundActivityService: ForegroundActivityService,
    nsfwService: NsfwDetectorService,
    private zone: NgZone,
    private uploadService: UploadFileService,
    // this is to initialize ngZone static variable to be used anywhere on the app
    ngZoneHolderService: NgZoneHolderService,
  ) {
    this.domListener = this.domListenerFactoryService.createInstance();
    this.isMobileView = this.deviceAndBrowserDetectorService.isMobile();
    if (!this.isMobileView) {
      this.domListener.add(window, 'resize', this.onResize.bind(this));
      // Emit first value based on current width till hitting the first resize event
      this.uiService.isMobile.next(window.innerWidth <= this.uiService.MOBILE_MAX_WIDTH);
    } else {
      this.uiService.isMobile.next(this.isMobileView);
    }
    this.isKeyboardOpen =
      this.isMobileView &&
      window.screen.height - MIN_KEYBOARD_HEIGHT > (window.visualViewport?.height as number);
    this.domListener.add(window.visualViewport, 'resize', () => {
      this.isKeyboardOpen =
        this.isMobileView &&
        window.screen.height - MIN_KEYBOARD_HEIGHT > (window.visualViewport?.height as number);
    });

    this.userService.rtl.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res) {
        document.body.classList.add('rtl');
      } else {
        document.body.classList.remove('rtl');
      }
    });
    this.userService.user
      .pipe(
        filter((val) => !!val),
        take(1),
        untilDestroyed(this),
      )
      .subscribe((res) => {
        if (res?.user?._id) {
          this.isFreeUser = this.paywallIndicatorService.isUserInSubscription(
            SUBSCRIPTION_TYPES.FREE,
          );
          this.referralService
            .getAcceptedReferrals(res?.user?._id)
            .pipe(untilDestroyed(this))
            .subscribe((referralsRes) => {
              const referrals = referralsRes?.referrals?.filter(
                (r) =>
                  r.referred_user?.billingStatus?.subscription_status === SubscriptionStatus.ACTIVE,
              );
              if (referrals?.length) {
                const unviewedReferrals = referrals.filter((r) => !r.viewed);
                if (unviewedReferrals.length) {
                  this.showReferralAcceptedDialog(
                    unviewedReferrals[0]._id,
                    res?.user,
                    unviewedReferrals[0].referred_user,
                  );
                }
                this.referralService.upgradedReferrals.next(referrals);
              }
            });
        }
      });
    this.initRouterEvents();

    // show / hide dev server url bar

    this.uRLService.customServerUrls.pipe(untilDestroyed(this)).subscribe((customUrls) => {
      this.customServeUrls = customUrls;
    });
    this.themeService.theme
      .pipe(untilDestroyed(this))
      .subscribe((selectedTheme) => (this.theme = selectedTheme?.theme));

    this.navService.isMenuOpened
      .pipe(untilDestroyed(this))
      .subscribe((res) => (this.menuOpened = res));
    this.angulartics2GoogleAnalytics.startTracking();

    updates.available.pipe(untilDestroyed(this)).subscribe(() => {
      clearTimeout(this.updateTimeout);
      this.updateTimeout = modifiedSetTimeout(() => {
        this.updateAvailable = true;
      }, 24 * 60 * 60 * 1000 /* 24 hrs */);
    });

    // eslint-disable-next-line frontend-rules/ngx-translate-service
    window.addEventListener('beforeinstallprompt', (e) => {
      this.pwaService.deferredPrompt = e;
    });

    this.userService.isUploading.pipe(untilDestroyed(this)).subscribe((res) => {
      this.isUploading = res;
    });

    this.authService.firebaseUser.pipe(untilDestroyed(this)).subscribe((user) => {
      if (!user) {
        return;
      }
      this.swPush
        .requestSubscription({
          serverPublicKey: `${environment.vapidPublicKey}`,
        })
        .then((sub) => {
          this.userService.saveNotification(sub).pipe(untilDestroyed(this)).subscribe();
        })
        .catch(() => console.error('Could not subscribe to notifications'));

      // load app rating information if user is authenticated
      this.appRatingService.loadAppRatingData();
    });

    // When user lands on a new page (or spaces view changes), update the FS session
    combineLatest([this.router.events, this.spaceSharedDataService.sessionView.current$])
      .pipe(untilDestroyed(this))
      .subscribe(([routerEvent, spaceViewState]) => {
        if (this.forceHideStaticLoader(this.router.url)) {
          this.hideStaticLoader(); // Hide loader for cases where userLoading never shows
        }
        let spacesView = 'NOT_SPACES';
        // if current url is for spaces, add View Info (Whiteboard/Gallery/FullScreen etc.)
        if (this.router.url?.toLowerCase().includes('/spaces/')) {
          spacesView = spaceViewState.view;
          if (spacesView === SessionView.WHITEBOARD) {
            this.telemetry.endPerfScenario(KeyScenariosOnSpaces.SWITCHING_VIEW, {
              currentSessionView: spacesView,
            });
          }
        }
        this.telemetry.setSessionVars({ spacesView: spacesView });
      });

    this.showNotificationBell = this.flagsService.isFlagEnabled(FLAGS.NOTIFICATIONS_BELL);

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.uploadService.resetUploadingCounter();
      });
  }
  ngAfterViewInit(): void {
    this.domListener.add(
      this.applicationContentElement.nativeElement,
      'wheel',
      this.preventWindowZoom.bind(this),
      true,
    );
    this.domListener.add(
      this.applicationContentElement.nativeElement,
      'mousewheel',
      this.preventWindowZoom.bind(this),
      true,
    );
    this.hideStaticLoader();
  }

  hideStaticLoader(): void {
    const loadingDiv = document.getElementById('initloadingtxt');
    loadingDiv?.parentNode?.removeChild(loadingDiv);
  }

  initRouterEvents(): void {
    this.router.events.forEach((item) => {
      if (!this.user) {
        this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
          if (res && res.user) {
            const firebaseUser = this.authService.getCurrentUser();
            if (firebaseUser && (firebaseUser.emailVerified || firebaseUser.isAnonymous)) {
              this.isListeningToUserLogout = true;
            }
            this.user = res?.user;
            this.setTimeLocaleService.setMomentLocale(<string>res.user.settings?.lang_interface);
            // Update timezone in moment globally
            moment.tz.setDefault(res.user.timezone.value);
            this.setupTelemetry(res);
            // a workaround to make sure setup doorbell is called after flags are fetched
            this.flagsService.featureFlagsChanged.pipe(first()).subscribe(() => {
              this.setupDoorbell();
            });

            if (
              !this.isRevokedModalShown &&
              this.user.revoked &&
              this.user.revoked.showRevokedModal
            ) {
              this.isRevokedModalShown = true;
              this.showRevokedInfo();
            }
          }
        });
      }
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };
        this.pushGTMData(gtmTag);
        this.navService.closeAllGadgets();

        if (!this.isMobileView && this.user?.subscriptionType !== SUBSCRIPTION_TYPES.ENTERPRISE) {
          this.initUpgradeBanner();
        }
      }
    });
  }

  ngOnInit(): void {
    this.userService.appLoading.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        this.hideStaticLoader(); // Force hide static loader the moment the loading indicator loads for first time
      }
      this.appLoading = value;
      this.cd.detectChanges();
    });
    this.foregroundActivityService.isForegroundInactive$
      .pipe(untilDestroyed(this))
      .subscribe((isInActive) => {
        this.setupLogoutListener();
      });
  }

  ngOnDestroy(): void {
    this.domListener.clear();
  }

  ngAfterViewChecked(): void {
    // increment change detection cycle
    this.telemetry.incrementMetric(CountedMetric.cd);
  }

  setupLogoutListener(): void {
    const currentFBUser = this.authService.getCurrentUser();
    if (
      (currentFBUser && this.userService.user.value?.user.email !== currentFBUser.email) ||
      (this.isListeningToUserLogout && !currentFBUser)
    ) {
      this.zone.run(() => {
        this.userService.appLoading.next(true);
        window.location.reload();
      });
    }
  }

  public preventWindowZoom(e) {
    if (!e.target) {
      return;
    }

    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
    }

    // On Safari left swipe will trigger back action - This means panning left will exit the space
    // We will prevent defaults if the broswer is Safari (Avoid regressions as much as possible) and there is a detected swipe left
    if (
      this.deviceAndBrowserDetectorService.isSafari() &&
      Math.abs(e.deltaX) > Math.abs(e.deltaY)
    ) {
      e.preventDefault();
    }
  }

  refresh(): void {
    this.userService.isUpdateButtonClicked.next(true);
    this.updateAvailable = false;
    this.updates.activateUpdate().then((res) => {
      window.location.reload();
    });
  }

  initUpgradeBanner(): void {
    if (!this.user || !this.user.billingStatus) {
      return;
    }

    this.showUpgradeBanner = false;

    const remainingTrialDays = moment(this.user.billingStatus.forceBillDate).diff(Date.now(), 'd');

    const currentDate = moment().startOf('day');

    let showUpgradeBannerNotInSpace =
      shouldShowBillingStatusIndicator(this.user) &&
      (this.user.subscriptionType === SUBSCRIPTION_TYPES.FREE ||
        this.user.subscriptionType === SUBSCRIPTION_TYPES.TRIAL) &&
      SHOULD_SHOW_BANNER_PERIOD >= remainingTrialDays &&
      !!this.user.name;

    const bannerClosedDate = this.uiRepo.upgradeBannerClosedDate;
    if (bannerClosedDate) {
      const closedToday = moment(bannerClosedDate).isSame(currentDate, 'day');
      showUpgradeBannerNotInSpace = showUpgradeBannerNotInSpace && !closedToday;
    }

    if (this.showSideNavBar(this.router.url)) {
      this.showUpgradeBanner = showUpgradeBannerNotInSpace;
      this.uiRepo.setShowUpgradeBanner(showUpgradeBannerNotInSpace);
    }
    combineLatest([
      this.spaceRepo.activeSpaceId$,
      this.paywallIndicatorService.canUseProFeature$.pipe(distinctUntilChanged()),
    ])
      .pipe(
        map(([spaceId, spaceOnSubsription]) => ({ spaceId, spaceOnSubsription })),
        takeWhile(({ spaceId }) => spaceId !== undefined && !this.showSideNavBar(this.router.url)),
        untilDestroyed(this),
      )
      .subscribe(({ spaceOnSubsription }) => {
        const isUserHost = this.spaceRepo.isCurrentUserHost();
        const shouldOpenBanner = showUpgradeBannerNotInSpace && isUserHost && !spaceOnSubsription;
        this.showUpgradeBanner = !!shouldOpenBanner;
        this.uiRepo.setShowUpgradeBanner(!!shouldOpenBanner);
      });
  }

  pushGTMData(gtmTag: any): void {
    if (this.user) {
      gtmTag = {
        ...gtmTag,
        isStudent: this.aclService.isStudent(this.user),
        isTeacher: this.aclService.isTeacher(this.user),
        isAdmin: this.aclService.isAdmin(this.user),
        isSuperAdmin: this.aclService.isSuperAdmin(this.user),
        personas: this.user.personas?.toString(),
      };
    }
    this.gtmService.pushTag(gtmTag);
  }

  setupTelemetry(userData?: UserData) {
    this.telemetry.identifyUser(userData);
  }

  setupDoorbell() {
    if (this.user && this.flagsService.isFlagEnabled(FLAGS.ENABLE_DOORBELL)) {
      window['doorbellOptions'] = {
        hideButton: true,
        id: this.env.doorbellProjectId,
        appKey: this.env.doorbellKey,
      };

      window['doorbellOptions'].windowLoaded = true;
      const script = document.createElement('script');
      script.id = 'doorbellScript';
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://embed.doorbell.io/button/${
        this.env.doorbellProjectId
      }?t=${new Date().getTime()}`;
      document.head.appendChild(script);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  public onPageUnload($event: BeforeUnloadEvent): string | undefined {
    if (this.isUploading || this.uploadService.isFileUploadInProgress()) {
      // eslint-disable-next-line frontend-rules/ngx-translate-service
      $event.returnValue = 'Leave site? Changes you made may not be saved.';
      return 'Leave site? Changes you made may not be saved.';
    }
  }

  reset(): void {
    if (this.dragElement) {
      this.dragElement.reset();
    }
  }

  dragStarted(event: CdkDragStart): void {
    this.dragElement = event.source._dragRef;
  }

  closeCalc(): void {
    this.navService.closeCalculator();
  }

  closeGraph(): void {
    this.navService.closeGraph();
  }

  closePeriodicTable(): void {
    this.navService.closePeriodicTable();
  }

  closeSessionAnalytics(): void {
    this.navService.closeSessionAnalytics();
  }

  toggleMenu(): void {
    this.navService.isMenuOpened.next(!this.menuOpened);
  }

  closeMobileMenu(event: MouseEvent): void {
    if (window.innerWidth < 576 && (event.target as Element).className.includes('sidebar')) {
      this.navService.isMenuOpened.next(false);
    }
  }

  openNewMessageDialog(): void {
    const dialogRef = this.dialog.open(NewMessageDialogComponent, {
      panelClass: 'zero-padding-dialog',
      autoFocus: false,
      restoreFocus: false,
    });
    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((newConversation: NewConversationRequest | null | undefined) => {
        if (newConversation) {
          if (isUserConversation(newConversation)) {
            return this.handleNewUserConversation(newConversation);
          } else {
            return this.handleNewGroupConversation(newConversation);
          }
        }
      });
  }

  private handleNewUserConversation(
    newUserConversation: NewUserConversationRequest,
  ): Promise<boolean> {
    this.messagingService.addNewUserConversation(newUserConversation);
    return this.router.navigate(['messages', 'user', newUserConversation.user._id]);
  }

  private async handleNewGroupConversation(
    newGroupConversation: NewGroupConversationRequest,
  ): Promise<boolean> {
    try {
      const groupId = await lastValueFrom(
        this.messagingService.addNewGroupConversation(newGroupConversation),
      );
      return this.router.navigate(['messages', 'group', groupId]);
    } catch {
      return this.router.navigate(['messages', 'home']);
    }
  }

  isParent(): boolean {
    if (this.user) {
      return this.aclService.isParent(this.user);
    }

    return false;
  }

  resetServerUrl() {
    this.uRLService.resetUrls();
    this.customServeUrls = {};
    document.body.style.paddingTop = '0';
  }

  showRevokedInfo(): void {
    const dialogRef = this.dialog.open(RevokedInfoDialogComponent, {
      panelClass: 'revoked-info-dialog-container',
      data: {
        user: this.user,
      },
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.profilesService.hideRevokedInfo().pipe(untilDestroyed(this)).subscribe();
      });
  }

  onResize() {
    this.isMobileView = window.innerWidth <= this.uiService.MOBILE_MAX_WIDTH;
    if (this.uiService.isMobile.getValue() !== this.isMobileView) {
      this.uiService.isMobile.next(this.isMobileView);
    }
  }

  goToBillingSetting() {
    this.settingsService.showSettingsPanel(panelID.payment);
  }

  hideBanner() {
    this.showUpgradeBanner = false;
    this.uiRepo.setUpgradeBannerClosedDate();
    this.uiRepo.setShowUpgradeBanner(false);
  }

  getBillingStatus(): string {
    if (this.user?.subscriptionType != null) {
      return this.translateService.instant(
        userBannerTrailPeriodText(
          this.user?.subscriptionType,
          this.user?.billingStatus.forceBillDate,
        ),
      );
    }
    return '';
  }

  getUpgradeButtonText(): string {
    if (this.user?.subscriptionType == SUBSCRIPTION_TYPES.FREE) {
      return this.translateService.instant('Upgrade to Pro');
    } else if (this.user?.subscriptionType == SUBSCRIPTION_TYPES.TRIAL) {
      return this.translateService.instant('Stay a Pro');
    }
    return '';
  }

  forceHideStaticLoader(url: string): boolean {
    // The loader spinner doesn't show for a set number of cases. In these cases, we need to force kill the static loader
    // The main case for this is if the sidebar does not show for the route and if the url is not a /spaces/XYZ link
    // Note: We need to filter out routes to '/', as these can automatically redirect to new routes which may have loader spinners
    return url !== '/' && !this.showSideNavBar(url) && !url.includes(`/${URL_CONSTANTS.SPACES}/`);
  }

  showSideNavBar(url: string): boolean {
    return (
      url !== '/' &&
      !url.includes('/account') &&
      !url.includes('/present') &&
      !url.includes('/view') &&
      !url.includes('/practice') &&
      !url.includes(`/${URL_CONSTANTS.SPACES}/`) &&
      !url.includes('/pencilcam') &&
      !url.includes('/techcheck') &&
      !url.includes('/recording')
    );
  }

  showReferralAcceptedDialog(referralId: string, user: User, referredUser: UserInfo): void {
    const referralAcceptedParams = {
      panelClass: 'referral-wb-dialog',
      autoFocus: false,
      data: {
        referralId,
        user,
        referredUser,
      },
    };

    this.modalManagerService.showModal(ReferralAcceptedDialogComponent, referralAcceptedParams);
  }

  resetFlags(): void {
    this.flagsService.resetFlags();
    window.location.reload();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'topicChip',
})
export class TopicChipPipe implements PipeTransform {
  transform(topics, id?: string): any {
    let val = '';
    const topic = topics.find(t => t._id === id);
    if (!topic) {
      return '';
    }
    if (!topic.parent) {
      return `${topic.numbering}. ${topic.name}`;
    } else {
      topic.parent.reverse().forEach(pID => {
        val += `${topics.find(t => t._id === pID).numbering  } > `;
      });
      val += `${topic.numbering}. ${topic.name}`;
      return val;
    }
  }
}

<div
  [class.editor__no-border]="hideBorder"
  class="editor position-relative"
  (click)="selectImageClicked(selectedImage, false)"
  (dragleave)="removeDragOverStyle($event, advancedEditor)"
  (dragover)="dragOver($event, advancedEditor)"
  #advancedEditor
  *ngIf="collection"
>
  <app-points
    [points]="collection.marks"
    *ngIf="collection.marks"
    (changePoints)="collection.marks = $event; saveChanges()"
  ></app-points>
  <div
    cdkDropList
    class="fragments"
    [class.messages]="type !== editorTypes.NORMAL"
    [class.show-drag-position]="dragSortedIndex > -1"
    (cdkDropListDropped)="reorderFragment($event)"
    (cdkDropListSorted)="dropListSorted($event)"
  >
    <div
      cdkDrag
      (cdkDragStarted)="handleDragStarted()"
      [cdkDragDisabled]="
        type !== editorTypes.NORMAL || fragment.type === 'ANSWERTEXT' || !!fragment['imageSelected']
      "
      class="fragment ugcFont"
      [class.disable-drag]="
        (fragment.type === 'TEXT' || fragment.type === 'IMAGE') &&
        (collection.type === 'OPTION' ||
          collection.type === 'SOLUTION' ||
          collection.type === 'HINT' ||
          collection.type === 'ANSWER' ||
          (collection.type === 'QUESTION' && i === 0 && collection.id))
      "
      [class.image-selected]="fragment['imageSelected']"
      [class.disable-hover]="!enableFragmentHover || showFocus[i] || i === selectedFragmentIndex"
      [class.no-text-fragment]="
        selectedDragFragment?.type === 'IMAGE' ||
        selectedDragFragment?.type === 'YOUTUBE' ||
        selectedDragFragment?.type === 'PHET' ||
        selectedDragFragment?.type === 'PDF'
      "
      *ngFor="
        let fragment of collection.fragments;
        let i = index;
        let first = first;
        let last = last
      "
      [style.width]="
        fragment.type === 'ANSWERTEXT' || (i > 0 && collection.type === 'QUESTION' && collection.id)
          ? 'calc(100% + 64px)'
          : '100%'
      "
      (click)="showControls = true; selectedFragmentIndex = i; showToolBox.emit(true)"
      (mouseover)="i > 0 ? fragmentHover.emit(true) : null"
    >
      <div
        class="fragment-content cp-0 d-flex"
        [class.align-items-center]="
          fragment.type === 'IMAGE' ||
          fragment.type === 'YOUTUBE' ||
          fragment.type === 'PHET' ||
          fragment.type === 'PDF'
        "
      >
        <div *ngIf="fragment.type === 'ANSWERTEXT'" class="cmb-16 flex-1 answer-container">
          <div class="answer-heading">
            <label class="subtitle1">{{ 'Response Box' | translate }}</label>
          </div>
          <div class="answer-box">
            <div class="d-flex">
              <ng-select
                class="course-select systemFont cmr-16"
                [searchable]="false"
                [clearable]="false"
                [items]="answerFormat"
                [placeholder]="'Format' | translate"
                [(ngModel)]="collection.answerFormat"
                (change)="saveChanges()"
              >
              </ng-select>
              <ng-select
                #lines
                class="systemFont course-select cmr-16"
                [searchable]="false"
                [clearable]="false"
                [items]="rows"
                bindLabel="label"
                bindValue="value"
                [(ngModel)]="collection.lines"
                (change)="saveChanges()"
                [style.width]="'90px'"
              >
              </ng-select>
            </div>
            <div
              *ngIf="collection.lines > 0"
              [class.ruled]="
                collection.answerFormat === 'Ruled' || collection.answerFormat === 'RULED'
              "
              [class.blank]="
                collection.answerFormat === 'Blank' || collection.answerFormat === 'BLANK'
              "
              [class.graph]="
                collection.answerFormat === 'Graph' || collection.answerFormat === 'GRAPH'
              "
              [class.lined]="
                collection.answerFormat === 'Lined' || collection.answerFormat === 'LINED'
              "
              class="position-relative cmt-8 cmb-16"
            >
              <div class="answerText" *ngFor="let l of collection.lines | numberList"></div>
            </div>
          </div>
        </div>
        <div class="fragment-row w-100 position-relative" *ngIf="fragment.type === 'TABLE'">
          <app-table-editor
            class="d-block w-100"
            [fragment]="fragment"
            [collection]="collection"
            [showTableImageDialog]="selectedFragmentIndex === i && showTableImageDialog"
            [showTableFormulaDialog]="showTableFormulaDialog && selectedFragmentIndex === i"
            [selectedTableIndexData]="selectedTableIndexData"
            (save)="saveChanges()"
            (focused)="handleFocus(i, false, $event)"
            (blurred)="handleBlur(i)"
            (imageDialogClosed)="showTableImageDialog = false"
            (formulaDialogClosed)="showTableFormulaDialog = false"
          ></app-table-editor>
        </div>
        <div
          *ngIf="fragment.type === 'TEXT'"
          class="fragment-row position-relative flex-1"
          [style.justify-content]="getAlignment(fragment.fragment)"
          [style.text-align]="getTextAlignment(fragment.fragment)"
        >
          <div
            id="advanced-text-fragment"
            advanced-text-fragment
            [class.scrollable]="type !== editorTypes.NORMAL"
            [style.max-height.px]="maxHeight"
            [selected]="selected && i === selectedFragmentIndex"
            [showFocus]="showFocus[i] || isFocused"
            [focusToEnd]="focusToEnd"
            [focusPosition]="focusPosition"
            [placeholder]="placeholder"
            [fragment]="fragment"
            [coll]="collection"
            [type]="type"
            [messageContext]="messageContext"
            [componentUsedFromChatBox]="componentUsedFromChatBox"
            [chatIdentifier]="chatIdentifier"
            [disableNewFragment]="disableNewFragment"
            [selectedRange]="selectedRange"
            [enableList]="enableList"
            [enableShiftEnter]="enableShiftEnter"
            [enableFloatingToolbar]="enableFloatingToolbar"
            [emitTyping]="emitTyping"
            [style.max-width]="'600px'"
            [first]="first"
            [last]="last"
            (saveState)="saveState.emit($event)"
            (saveEvent)="saveChanges()"
            (focused)="handleFocus(i)"
            (blurred)="handleBlur(i)"
            (clickEnter)="addTextFragment($event, i)"
            (clickBackspace)="removeTextFragment('Backspace', i)"
            (clickDelete)="removeTextFragment('Delete', i)"
            (hideFocus)="showFocus = []; isFocused = false"
            (dragover)="(false)"
            (setSelectionRange)="selectedRange = $event"
            (saveState)="saveState.emit($event)"
            (saveEvent)="saveChanges()"
            (focused)="handleFocus(i)"
            (blurred)="handleBlur(i)"
            (backspaceToRemoveFragment)="backspaceToRemoveFragment(i - 1)"
            (handleArrowKeys)="handleArrowKeysPress($event, i)"
            (dropStart)="removeDragOverStyle($event, advancedEditor)"
          ></div>
        </div>
        <ng-container *ngIf="selectedDragFragment?.type === 'TEXT'">
          <div *cdkDragPreview class="text-fragment">
            <div
              id="advanced-text-fragment"
              advanced-text-fragment
              [placeholder]="placeholder"
              [fragment]="textFragmentDragPreview(fragment)"
              (dropStart)="removeDragOverStyle($event, advancedEditor)"
              [coll]="collection"
              [enableList]="enableList"
            ></div>
            <i class="drag-handle material-icons">drag_indicator</i>
          </div>
        </ng-container>
        <div
          *ngIf="fragment.type === 'PDF'"
          class="fragment-row flex-column flex-1 p-0"
          [class.selected-border]="selected && selectedFragmentIndex === i"
          (click)="handleFocus(i, true)"
        >
          <app-pdf-fragment-container class="pdf-viewer" [src]="fragment.fragment.data">
          </app-pdf-fragment-container>
        </div>
        <div
          *ngIf="fragment.type === 'IMAGE' && !componentUsedFromChatBox"
          class="fragment-row flex-1 image-fragment d-flex"
          [class.image-selected]="fragment['imageSelected']"
          [style.justify-content]="getAlignment(fragment.fragment)"
          (click)="handleFocus(i)"
        >
          <div
            class="d-inline-flex align-items-center image-resizer-wrap"
            [class.w-100]="isResizeStarted"
            [class.resizer-selected]="fragment['imageSelected']"
          >
            <app-image-resize-new
              class="main-image"
              [componentUsedFromChatBox]="componentUsedFromChatBox"
              [frag]="fragment.fragment"
              [posStyle]="{ 'justify-content': getAlignment(fragment.fragment) }"
              (saveData)="saveEvent.emit(collection)"
              (selectImage)="selectImageClicked(fragment, $event)"
              (resizeStart)="isResizeStarted = $event"
            ></app-image-resize-new>
            <div class="image-extra">
              <i cdkDragHandle class="drag-handle material-icons">drag_indicator</i>
              <i class="delete-button material-icons-outlined" (click)="deleteFragment(fragment)"
                >delete</i
              >
              <!-- <i class="drag-handle material-icons _disabled">drag_indicator</i>
            <i class="delete-button material-icons-outlined _disabled">delete</i> -->
            </div>
          </div>
        </div>
        <ng-container
          *ngIf="
            selectedDragFragment?.type === 'IMAGE' ||
            selectedDragFragment?.type === 'YOUTUBE' ||
            selectedDragFragment?.type === 'PHET' ||
            selectedDragFragment?.type === 'PDF'
          "
        >
          <div *cdkDragPreview class="no-text-fragment">
            <app-image-resize-new
              *ngIf="selectedDragFragment?.type === 'IMAGE'"
              [componentUsedFromChatBox]="true"
              [frag]="fragment.fragment"
              [posStyle]="{ 'justify-content': getAlignment(fragment.fragment) }"
              (saveData)="saveEvent.emit(collection)"
              (selectImage)="fragment['imageSelected'] = $event"
              (resizeStart)="isResizeStarted = $event"
            ></app-image-resize-new>
            <div
              advanced-video-fragment
              *ngIf="selectedDragFragment?.type === 'YOUTUBE'"
              [fragment]="fragment"
              [coll]="collection"
              [innerHtml]="fragment.videoUrl"
            ></div>
            <app-phet-embed
              [frag]="fragment.fragment"
              *ngIf="selectedDragFragment?.type === 'PHET'"
            ></app-phet-embed>
            <i class="drag-handle material-icons">drag_indicator</i>
          </div>
        </ng-container>
        <div *ngIf="fragment.type === 'KATEX'" class="fragment-row flex-column flex-1">
          <div
            katex-fragment
            class="cmt-24 d-flex w-100"
            [style.justify-content]="getAlignment(fragment.fragment)"
          >
            <ng-katex
              equation="{{ fragment.fragment.data }}"
              [options]="{ displayMode: true }"
            ></ng-katex>
          </div>
        </div>
        <div
          advanced-video-fragment
          *ngIf="fragment.type === 'YOUTUBE'"
          class="fragment-row flex-1 d-flex"
          [style.justify-content]="getAlignment(fragment.fragment)"
          (saveState)="saveState.emit($event)"
          (saveData)="saveEvent.emit(collection)"
          [fragment]="fragment"
          [coll]="collection"
          [class.selected-border]="selected && selectedFragmentIndex === i"
          (click)="handleFocus(i, true)"
          [innerHtml]="fragment.videoUrl"
        ></div>
        <div
          *ngIf="fragment.type === 'PHET'"
          class="fragment-row flex-1 d-flex"
          [style.justify-content]="getAlignment(fragment.fragment)"
          [class.selected-border]="selected && selectedFragmentIndex === i"
          (click)="handleFocus(i, true)"
        >
          <app-phet-embed [frag]="fragment.fragment"></app-phet-embed>
        </div>
        <i
          cdkDragHandle
          class="drag-handle material-icons"
          *ngIf="
            !fragment['imageSelected'] &&
            fragment.type !== 'ANSWERTEXT' &&
            type === editorTypes.NORMAL
          "
          (mouseover)="setDragItem(fragment)"
          >drag_indicator</i
        >
        <span
          class="m-icon delete-button material-icons-outlined"
          *ngIf="
            !componentUsedFromChatBox &&
            fragment.type !== FragmentType.Image &&
            !fragment['imageSelected'] &&
            (collection?.fragments?.length > 1 ||
              (collection?.fragments?.length === 1 && fragment?.fragment?.data)) &&
            !popUpMessage
          "
          (click)="deleteFragment(fragment)"
          >delete</span
        >
      </div>
      <app-editor-additional-toolbox
        *ngIf="
          showNavigation &&
          (collection?.type !== 'OPTION' || (collection?.type === 'OPTION' && selected)) &&
          i === getResponseBoxIndex(collection.fragments) - 1
        "
        class="d-block cmy-16 systemFont"
        [disableResponse]="checkAnswerText()"
        [disablePoints]="collection.marks"
        [disableHint]="disableHint"
        [showHint]="showHint"
        [disableAnswer]="disableAnswer"
        [disableExplanation]="disableExplanation"
        [enableAddSub]="enableAddSub"
        (add)="handleAdd($event)"
      ></app-editor-additional-toolbox>
    </div>

    <section
      (drop)="drop($event, advancedEditor)"
      *ngIf="componentUsedFromChatBox"
      class="image-chat"
    >
      <div
        class="image-chat-item"
        [hidden]="fragment.type !== 'IMAGE'"
        *ngFor="let fragment of collection.fragments"
      >
        <div *ngIf="fragment.type === 'IMAGE'">
          <section
            class="image-chat-item-wrapper"
            (click)="selectImageClicked(fragment, true, $event)"
            [class.image-fragment-selected]="selectedImage && selectedImage.uid === fragment['uid']"
          >
            <div
              [hidden]="fragment.fragment.uploadProgress || !fragment.fragment.data"
              class="delete-image-btn"
              [ngbTooltip]="'Delete' | translate"
              (click)="deleteFragment(fragment)"
            >
              <span class="m-icon material-icons-outlined">delete</span>
            </div>
            <div class="image-chat-box">
              <img *ngIf="fragment.fragment.data" [src]="fragment.fragment.data" />
              <progress
                *ngIf="fragment.fragment.uploadProgress || !fragment.fragment.data"
                max="100"
                [value]="fragment.fragment.uploadProgress"
              >
                {{ fragment.fragment.uploadProgress }}
              </progress>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>

  <div *ngIf="collection.type === 'QUESTION'">
    <div cdkDrag class="fragment ugcFont" *ngFor="let fragment of collection.answersFragments">
      <div class="fragment-content answer">
        <div *ngIf="fragment.type === 'TEXT'">
          <div
            *ngIf="!fragment.myscript"
            advanced-text-fragment
            (dropStart)="removeDragOverStyle($event, advancedEditor)"
            [selected]="selected"
            [placeholder]="placeholder"
            [fragment]="fragment"
            [coll]="collection"
          ></div>
        </div>
        <i cdkDragHandle class="drag-handle material-icons">drag_indicator</i>
        <i class="delete-button material-icons" (click)="deleteAnswerFragment(fragment)">delete</i>
      </div>
    </div>
  </div>

  <div
    *ngIf="hideToolbar && fileName && (collection.uploadProgress | async); let uploading"
    class="d-flex align-items-center justify-content-center position-relative progress-container"
  >
    <div
      class="
        d-inline-flex
        flex-column
        align-items-center
        justify-content-center
        position-absolute
        file-info-container
      "
    >
      <i class="material-icons-outlined upload-icon" *ngIf="uploadType === 'image'">photo</i>
      <i class="material-icons-outlined upload-icon" *ngIf="uploadType === 'video'"
        >ondemand_video</i
      >
      <i class="material-icons-outlined upload-icon" *ngIf="uploadType === 'phet'"
        >picture_in_picture</i
      >
      <i class="material-icons-outlined upload-icon" *ngIf="uploadType === 'pdf'">picture_as_pdf</i>
      <span class="file-name">{{ fileName }}</span>
    </div>
    <div class="progress position-absolute" *ngIf="uploading !== 100">
      <div
        class="progress-bar bg-primary progress-bar-striped progress-bar-animated"
        [style.width]="uploading + '%'"
      ></div>
    </div>
    <i *ngIf="uploading > 0.01" class="material-icons-outlined btn-close" (click)="cancelUpload()"
      >close</i
    >
  </div>

  <div class="controls" *ngIf="showControls && !hideToolbar">
    <div class="d-flex align-items-center">
      <i
        class="material-icons add-fragment-button"
        ngbTooltip="{{ 'Add text' | translate }}"
        *ngIf="
          !collection.showImage &&
          !collection.showVideo &&
          !collection.showUploadVideo &&
          !collection.showPhet &&
          !collection.uploadProgress &&
          type === editorTypes.NORMAL
        "
        (click)="
          collection.addTextFragment(); selectedFragmentIndex = collection.fragments.length - 1
        "
      >
        text_fields
      </i>
      <i
        class="material-icons add-fragment-button"
        ngbTooltip="{{ 'Insert equation' | translate }}"
        *ngIf="
          !collection.showImage &&
          !collection.showVideo &&
          !collection.showUploadVideo &&
          !collection.showPhet &&
          !collection.uploadProgress
        "
        (click)="openKatexDialog(null, -1)"
      >
        functions
      </i>
      <i
        class="material-icons add-fragment-button"
        ngbTooltip="{{ 'Add image' | translate }}"
        *ngIf="
          !collection.showImage &&
          !collection.showVideo &&
          !collection.showUploadVideo &&
          !collection.showPhet &&
          !collection.uploadProgress
        "
        (click)="showUploadDialog ? showImageUploadDialog() : (collection.showImage = true)"
      >
        photo
      </i>
      <i
        class="material-icons add-fragment-button"
        ngbTooltip="{{ 'Add video URL' | translate }}"
        *ngIf="
          !collection.showImage &&
          !collection.showVideo &&
          !collection.showUploadVideo &&
          !collection.showPhet &&
          !collection.uploadProgress
        "
        (click)="showUploadDialog ? showVideoUploadDialog() : (collection.showVideo = true)"
      >
        ondemand_video
      </i>
      <i
        class="material-icons add-fragment-button"
        ngbTooltip="{{ 'Upload video' | translate }}"
        *ngIf="
          !collection.showImage &&
          !collection.showVideo &&
          !collection.showUploadVideo &&
          !collection.showPhet &&
          !collection.uploadProgress &&
          !showUploadDialog
        "
        (click)="collection.showUploadVideo = true"
      >
        personal_video
      </i>
      <i
        class="material-icons add-fragment-button"
        ngbTooltip="{{ 'Add Phet simulation URL' | translate }}"
        *ngIf="
          !collection.showImage &&
          !collection.showVideo &&
          !collection.showUploadVideo &&
          !collection.showPhet &&
          !collection.uploadProgress
        "
        (click)="showUploadDialog ? showPhetUploadDialog() : (collection.showPhet = true)"
      >
        picture_in_picture
      </i>
      <i
        class="material-icons add-fragment-button"
        *ngIf="
          collection.showImage ||
          collection.showVideo ||
          collection.showUploadVideo ||
          collection.showPhet
        "
        (click)="
          collection.showImage = false;
          collection.showUploadVideo = false;
          collection.showVideo = false;
          collection.showPhet = false
        "
      >
        clear
      </i>
      <div
        class="answer-section"
        [class.disabled]="
          (collection.fragments | answerCheck) || (collection.fragments | emptyCheck)
        "
        (click)="!checkAnswerText() && !checkEmpty() ? collection.addAnswerTextFragment() : null"
        *ngIf="
          uiState === 'QUESTION' &&
          !collection.showImage &&
          !collection.showVideo &&
          !collection.showUploadVideo &&
          !collection.showPhet &&
          isOpenEnded &&
          !collection.uploadProgress
        "
      >
        <i class="material-icons">playlist_add</i>
        {{ 'Add student response section' | translate }}
      </div>
      <span *ngIf="collection.showImage && !collection.uploadProgress">
        <input
          #imageInput
          type="file"
          accept="image/*"
          value="Add Image File"
          (change)="collection.addFile($event.target.files[0], uploadService, resourceService)"
        />
      </span>
      <span *ngIf="collection.showUploadVideo && !collection.uploadProgress">
        <input
          #imageInput
          type="file"
          accept="video/*"
          value="Upload Video File"
          (change)="uploadVideo($event.target.files[0])"
        />
      </span>
      <span *ngIf="collection.showVideo && !collection.uploadProgress">
        <input
          #box
          id="editor-url-box"
          type="text"
          [placeholder]="'Add Youtube/Vimeo url' | translate"
          (keyup.enter)="collection.addVideo(box.value, toastrService)"
        />
      </span>
      <span *ngIf="collection.showPhet && !collection.uploadProgress">
        <input
          #box
          id="editor-url-box"
          type="text"
          [placeholder]="'Add Phet url' | translate"
          (keyup.enter)="collection.addPhet(box.value)"
        />
      </span>
      <div
        *ngIf="collection.uploadProgress | async; let uploading"
        class="cmy-8 cml-16 d-flex align-items-center"
      >
        <span class="cmr-8" *ngIf="uploading === 0.01">{{ 'Preparing upload' | translate }}</span>
        <span class="cmr-8" *ngIf="uploading > 0.01">{{ 'Uploading' | translate }}</span>
        <div class="progress" *ngIf="uploading !== 100 && uploading !== 0.01">
          <div
            class="progress-bar bg-success progress-bar-striped progress-bar-animated"
            [style.width]="uploading + '%'"
          ></div>
          <span class="percent">{{ uploading | number: '.0-0' }}%</span>
        </div>
        <button *ngIf="uploading > 0.01" class="cml-8" ui-primary (click)="cancelUpload()">
          {{ 'Cancel' | translate }}
        </button>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <mat-checkbox
        color="primary"
        *ngIf="uiState === 'EXPLANATION' && collection.type === 'SOLUTION'"
        [(ngModel)]="collection.explLater"
        class="cmr-8"
        (change)="changeExplanation(collection, 1)"
        [style.display]="collection.fragments?.length > 0 ? 'none' : 'block'"
        >{{ 'Add later' | translate }}</mat-checkbox
      >
      <mat-checkbox
        color="primary"
        *ngIf="uiState === 'EXPLANATION' && collection.type === 'SOLUTION'"
        [(ngModel)]="collection.noExpl"
        class="cmr-8"
        (change)="changeExplanation(collection, 2)"
        [style.display]="collection.fragments?.length > 0 ? 'none' : 'block'"
        >{{ 'NA' | translate }}</mat-checkbox
      >
      <div
        class="word-count systemFont"
        *ngIf="collection.wordCount() > 0 && type === editorTypes.NORMAL"
      >
        {{ collection.wordCount() }}
        <span *ngIf="collection.wordCount() === 1">{{ 'word' | translate }}</span>
        <span *ngIf="collection.wordCount() > 1">{{ 'words' | translate }}</span>
      </div>
      <i
        class="material-icons delete-collection"
        *ngIf="
          (isOpenEnded && collection.level) ||
          (!isOpenEnded && showDeleteButton && collection.type === 'OPTION')
        "
        (click)="removeCollection()"
      >
        delete
      </i>
      <mat-icon
        *ngIf="
          uiState === 'EXPLANATION' && isOpenEnded && (!checkFragmentValid() || !collection.checked)
        "
        class="cmr-8"
        [style.opacity]="checkFragmentValid() ? 0.7 : 0.3"
        [style.cursor]="'pointer'"
        (click)="checkForComplete(collection)"
        >radio_button_unchecked</mat-icon
      >
      <mat-icon
        *ngIf="uiState === 'EXPLANATION' && isOpenEnded && collection.checked"
        class="cmr-8"
        [style.color]="'rgb(99, 150, 242)'"
        [style.cursor]="'pointer'"
        (click)="checkForComplete(collection)"
        >check_circle_outline</mat-icon
      >
    </div>
    <div
      class="btn-sub-question"
      (click)="addSubQuestion()"
      *ngIf="isOpenEnded && collection.number"
    >
      {{ 'Add sub-question' | translate }}
    </div>
  </div>

  <app-editor-additional-toolbox
    *ngIf="
      selected &&
      showNavigation &&
      (collection?.type !== 'OPTION' || (collection?.type === 'OPTION' && selected))
    "
    class="d-block cmy-16"
    [showOthers]="isOpenEnded && collection?.type === 'QUESTION'"
    [showChoice]="isMcq && collection?.type === 'QUESTION'"
    [showSub]="isOpenEnded && collection?.type === 'QUESTION'"
    [disableResponse]="checkAnswerText()"
    [disablePoints]="collection.marks"
    [disableHint]="disableHint"
    [hideAddText]="getResponseBoxIndex(collection.fragments) >= 0"
    [showHint]="showHint"
    [disableAnswer]="disableAnswer"
    [disableExplanation]="disableExplanation"
    [enableAddSub]="enableAddSub"
    (add)="handleAdd($event)"
  ></app-editor-additional-toolbox>
</div>

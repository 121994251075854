import { Injectable } from '@angular/core';
import {
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  Observable,
  switchMap,
} from 'rxjs';
import { BoardFolder, Frame, ROOT_BOARD_FOLDER_NAME } from '../models/session';
import { SpaceRepository } from '../state/space.repository';
import { FLAGS, FlagsService } from './flags.service';

@Injectable({
  providedIn: 'root',
})
export class SpaceBoardFoldersService {
  constructor(private spaceRepo: SpaceRepository, private flagsService: FlagsService) {}

  public readonly activeSpaceSelectedBoardFolder$: Observable<BoardFolder | undefined> =
    combineLatest([
      this.activeSpaceBoardFolders$,
      this.spaceRepo.activeSpaceSelectedBoardFolderUid$,
    ])
      .pipe(
        // filter out the selected board folder if it is the root folder or if it is in the active board folders
        // This is to avoid race condition where the selected board folder is not yet in the active board folders
        filter(
          ([activeBoardFolders, selectedBoardUid]) =>
            selectedBoardUid === ROOT_BOARD_FOLDER_NAME ||
            !!activeBoardFolders?.find((folder) => folder.uid === selectedBoardUid),
        ),
        map(([activeBoardFolders, selectedBoardUid]) =>
          activeBoardFolders?.find((folder) => folder.uid === selectedBoardUid),
        ),
      )
      .pipe(distinctUntilChanged());

  /**
   * get all board folders observable on for the current active space
   */
  public get activeSpaceBoardFolders$(): Observable<BoardFolder[]> {
    return combineLatest([
      this.spaceRepo.activeSpaceId$,
      this.spaceRepo.activeSpaceCurrentRoom$,
    ]).pipe(
      switchMap(([activeSpaceId, currentRoom]) => {
        if (!activeSpaceId || currentRoom?.uid === undefined) {
          return EMPTY;
        }
        return this.spaceRepo.spaceBoardFolders$(activeSpaceId, currentRoom.uid);
      }),
    );
  }

  /**
   * return the current board folders without observable
   */
  public get activeSpaceCurrentRoomBoardFolders(): BoardFolder[] {
    const activeSpaceId = this.spaceRepo.activeSpaceId;
    const currentRoomUID = this.spaceRepo.activeSpaceCurrentRoomUid;

    if (!activeSpaceId || currentRoomUID === undefined) {
      return [];
    }
    return this.spaceRepo.getSpaceBoardFolders(activeSpaceId, currentRoomUID);
  }

  public get activeSpaceSelectedBoardFolderUid(): string | undefined {
    return this.spaceRepo.activeSpace?.selectedFolderBoardUid;
  }

  /**
   * Sets the selected boardFolder for the active space
   * @param selectedBoardFolderUid
   */
  public setActiveSpaceSelectedBoardFolder(selectedBoardFolderUid: string): void {
    if (this.spaceRepo.activeSpace) {
      this.setSpaceSelectedBoardFolder(this.spaceRepo.activeSpace._id, selectedBoardFolderUid);
    }
  }

  /**
   * get the new name that will be used for creating a folder
   */
  public get nextNewBoardFolderName(): string {
    const base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const allBoardFoldersLength = this.activeSpaceCurrentRoomBoardFolders?.length;
    return `New Folder ${base[allBoardFoldersLength % base.length]}`;
  }

  /**
   * check for the current board folder and activate the folder for the frame if not active
   * @param currentFrame
   */
  public updateActiveBoardFolderForSelectedFrame(currentFrame: Frame): void {
    if (!currentFrame || !this.flagsService.isFlagEnabled(FLAGS.SPACES_BOARD_FOLDERS)) {
      return;
    }

    const currentActiveFolderUid = this.activeSpaceSelectedBoardFolderUid;

    // if current board is in the root folder and root folder not active, make it active
    if (
      (!currentFrame.boardFolderUid || currentFrame.boardFolderUid === ROOT_BOARD_FOLDER_NAME) &&
      currentActiveFolderUid !== ROOT_BOARD_FOLDER_NAME
    ) {
      this.setActiveSpaceSelectedBoardFolder(ROOT_BOARD_FOLDER_NAME);
    }

    // if current board is inside a child folder and root folder active, make the child folder active
    if (
      currentFrame.boardFolderUid &&
      currentFrame.boardFolderUid !== ROOT_BOARD_FOLDER_NAME &&
      currentActiveFolderUid !== currentFrame.boardFolderUid
    ) {
      this.setActiveSpaceSelectedBoardFolder(currentFrame.boardFolderUid);
    }
  }

  /**
   * Set the new selected folder when doing navigation and update the space repo store
   * @param spaceId
   * @param selectedBoardFolderUid
   * @returns
   */
  private setSpaceSelectedBoardFolder(spaceId: string, selectedBoardFolderUid: string): void {
    const space = this.spaceRepo.getSpace(spaceId);

    if (space) {
      this.spaceRepo._setSpaceSelectedBoardFolder(space._id, selectedBoardFolderUid);
    }
  }

  /**
   * checks if user cant create more board folders in the room
   */
  isBoardFoldersLimitReached(): boolean {
    return (
      this.activeSpaceCurrentRoomBoardFolders.length >=
      this.flagsService.featureFlagsVariables[FLAGS.SPACES_BOARD_FOLDERS]?.max_folder_limit
    );
  }
}

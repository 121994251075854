<div
  id="user-settings-payment"
  class="settings-panel"
  [class.mobile]="isMobile"
  [ngClass]="currentBilling"
>
  <div class="head">
    <span class="material-icons go-back" (click)="goBack.emit()"> arrow_back </span>
    <div class="description">
      <div class="title">{{ PANELS?.PAYMENT?.name }}</div>
      <div class="subtitle">{{ PANELS?.PAYMENT?.description }}</div>
    </div>
    <span class="material-icons close" (click)="goBack.emit()"> close </span>
  </div>
  <div class="body overflow-auto" *ngIf="!isMobile">
    <div class="text-uppercase font-12 cmb-16 section-title">{{ 'Your plan' | translate }}</div>
    <div class="plan-title font-18 fw-6 text-capitalize" *ngIf="referralAccepted !== undefined">
      {{
        currentBilling === BillingStatus.TRIAL
          ? ('Pro ' | translate) +
            (referralAccepted ? ('(30 day referral bonus)' | translate) : ('(Trial)' | translate))
          : currentBilling === BillingStatus.PAID
          ? isUserOnAnnualPlan
            ? ('Pro (Annual plan)' | translate)
            : ('Pro (Monthly plan)' | translate) +
              (billingStatus === SubscriptionStatus.CANCELLED
                ? ' (' + ('Cancelled' | translate) + ')'
                : '')
          : (currentBilling | translate)
      }}
    </div>
    <div *ngIf="this.user?.billingStatus && referralAccepted !== undefined">
      <div class="plan-status-text text-alert">
        {{ user | referralTrialPeriodText }}
      </div>
      <div
        *ngIf="
          currentBilling === BillingStatus.PAID &&
          billingStatus === SubscriptionStatus.CANCELLED &&
          remainingSubscriptionDays > 0
        "
        class="plan-status-text text-alert"
      >
        {{ 'Subscription ends in' | translate }} {{ remainingSubscriptionDays }}
        {{ 'days' | translate }}
      </div>
      <div
        *ngIf="
          currentBilling === BillingStatus.PAID &&
          billingStatus === SubscriptionStatus.CANCELLED &&
          remainingSubscriptionDays === 0
        "
        class="plan-status-text text-alert"
      >
        {{ 'Subscription ends today' | translate }}
      </div>
      <div
        *ngIf="currentBilling === BillingStatus.PAID && remainingBillingDays > 0"
        class="plan-status-text"
      >
        {{ 'Next payment in' | translate }} {{ remainingBillingDays }} {{ 'days' | translate }}
      </div>
      <div
        *ngIf="currentBilling === BillingStatus.PAID && remainingBillingDays === 0"
        class="plan-status-text"
      >
        {{ 'Next payment today' | translate }}
      </div>
    </div>
    <div class="cmt-12 d-inline-block">
      <app-pencil-button
        *ngIf="currentBilling === BillingStatus.PAID"
        class="pencil-button medium btn-contact"
        (clickEvent)="createPortalSession()"
        data-name="settings-payment-portal-session-btn"
        icon="edit"
        [iconConfig]="{ type: 'material-icon' }"
        [label]="'Manage' | translate"
      >
      </app-pencil-button>
      <ng-container *ngTemplateOutlet="upgradeButtonTemplate"></ng-container>
    </div>
    <div class="divider"></div>
    <div class="text-uppercase font-12 cmb-16 section-title">
      {{ 'Available plans' | translate }}
    </div>
    <div class="table-details d-flex" [ngClass]="{ 'annual-plan': isViewingAnnualPlan }">
      <div class="col">
        <div class="cell toggle">
          <div class="toggle-container">
            <div class="left-section">
              <div class="save-banner">SAVE 10%!</div>
              <div
                class="text left-text"
                [ngClass]="{ on: isViewingAnnualPlan, off: !isViewingAnnualPlan }"
              >
                Pay annually
              </div>
            </div>
            <div class="toggle-section">
              <mat-slide-toggle
                [checked]="!isViewingAnnualPlan"
                (change)="handleChange($event)"
                class="custom-mat-toggle"
              ></mat-slide-toggle>
            </div>
            <div class="right-section">
              <div
                class="text right-text"
                [ngClass]="{ on: !isViewingAnnualPlan, off: isViewingAnnualPlan }"
              >
                Pay monthly
              </div>
            </div>
          </div>
        </div>
        <div class="cell">{{ 'Number of Spaces' | translate }}</div>
        <div class="cell">{{ 'Number of boards per Space' | translate }}</div>
        <div class="cell">{{ 'Active users in Space at any time' | translate }}</div>
        <div class="cell">{{ 'Video and audio calls' | translate }}</div>
        <div class="cell">{{ 'Cloud storage' | translate }}</div>
        <div class="cell">{{ 'Number of teachers / tutors' | translate }}</div>
        <div class="fw-6 cell" style="color: black">{{ 'Other features' | translate }}</div>
        <div class="cell">{{ 'Participant manager' | translate }}</div>
        <div class="cell">{{ 'Local recording' | translate }}</div>
        <div class="cell">{{ 'Google Calendar extension' | translate }}</div>
        <div class="cell">{{ 'Google Drive integration' | translate }}</div>
        <div class="cell">{{ 'Microsoft OneDrive integration' | translate }}</div>
        <div class="cell">{{ 'Apps' | translate }}</div>
        <div class="cell">{{ 'Scheduling' | translate }}</div>
        <div class="cell">{{ 'Breakout rooms' | translate }}</div>
        <div class="cell">{{ 'Private boards' | translate }}</div>
        <div class="cell">{{ 'Board backgrounds' | translate }}</div>
        <div class="cell">{{ 'Duplicate Spaces' | translate }}</div>
        <div class="cell">{{ 'Export Spaces' | translate }}</div>
        <div class="cell">{{ 'Cloud recording' | translate }}</div>
        <div class="cell">{{ 'Always-on cloud recording' | translate }}</div>
        <div class="cell">{{ 'Analytics' | translate }}</div>
        <div class="cell">{{ 'Custom branding' | translate }}</div>
        <div class="cell">{{ 'Custom fields / tags' | translate }}</div>
        <div class="cell">{{ 'API' | translate }}</div>
      </div>
      <div class="col">
        <div class="cell header">
          <div class="d-flex flex-column">
            <div class="font-18 fw-6 cmb-16">{{ 'Free' | translate }}</div>
            <div class="font-30 cmb-16 fw-4">$0</div>
            <app-pencil-button
              *ngIf="currentBilling === BillingStatus.FREE"
              class="pencil-button medium btn-current"
              [disabled]="true"
              [style.margin-top.px]="isViewingAnnualPlan ? 34 : 0"
              icon="done"
              [iconConfig]="{ type: 'material-icon' }"
              [label]="'Current plan' | translate"
            >
            </app-pencil-button>
          </div>
        </div>
        <div class="cell">{{ 'Unlimited' | translate }}</div>
        <div class="cell">{{ 'Unlimited' | translate }}</div>
        <div class="cell">100</div>
        <div class="cell">
          <span
            [ngbTooltip]="joinCallsTooltip"
            placement="bottom"
            style="text-decoration: underline"
          >
            {{ 'Join calls only' | translate }}
          </span>
          <ng-template #joinCallsTooltip>
            <app-tooltip
              [createNewLine]="true"
              [text]="
                'Calls can\'t be started in any Space that you own. You can still join calls in other Spaces'
                  | translate
              "
              [arrow]="'top'"
              [textStyle]="{ 'line-height': 1.3 }"
            >
            </app-tooltip>
          </ng-template>
        </div>
        <div class="cell">5 GB</div>
        <div class="cell">1</div>
        <div class="cell"></div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="cell">
          <span
            [ngbTooltip]="joinCallLimitedTooltip"
            placement="bottom"
            style="text-decoration: underline"
            >{{ 'Limited' | translate }}</span
          >
          <ng-template #joinCallLimitedTooltip>
            <app-tooltip
              [createNewLine]="true"
              [text]="
                'Any apps that use web viewers can\'t be added or used. You will continue to have access to all other apps.'
                  | translate
              "
              [arrow]="'top'"
              [textStyle]="{ 'line-height': 1.3 }"
            >
            </app-tooltip>
          </ng-template>
        </div>
        <div class="cell">
          <span
            [ngbTooltip]="viewEventsLimitedTooltip"
            placement="bottom"
            style="text-decoration: underline"
            >{{ 'View Only' | translate }}</span
          >
          <ng-template #viewEventsLimitedTooltip>
            <app-tooltip
              [createNewLine]="true"
              [text]="'Events in Schedule can only be viewed, not created or edited.' | translate"
              [arrow]="'top'"
              [textStyle]="{ 'line-height': 1.3 }"
            >
            </app-tooltip>
          </ng-template>
        </div>
        <div class="cell"></div>
        <div class="cell"></div>
        <div class="cell"></div>
        <div class="cell"></div>
        <div class="cell"></div>
        <div class="cell"></div>
        <div class="cell"></div>
        <div class="cell"></div>
        <div class="cell"></div>
        <div class="cell"></div>
        <div class="cell"></div>
      </div>
      <div class="col">
        <div class="cell header">
          <div class="d-flex flex-column">
            <div class="font-18 fw-6 cmb-16">{{ 'Pro' | translate }}</div>
            <div class="font-30 cmb-16 d-flex align-items-end fw-4">
              <div *ngIf="!isViewingAnnualPlan">$29.99</div>
              <div *ngIf="isViewingAnnualPlan">$26.99</div>
              <sub class="font-12 cmb-8 cml-6">{{ 'PER MONTH' | translate }}</sub>
            </div>
            <div *ngIf="isViewingAnnualPlan" class="cmb-16 annual-note">
              {{ 'when billed annually' | translate }}
            </div>
            <app-pencil-button
              *ngIf="
                currentBilling === BillingStatus.PAID && isUserOnAnnualPlan == isViewingAnnualPlan
              "
              class="pencil-button medium btn-current"
              [disabled]="true"
              icon="done"
              [iconConfig]="{ type: 'material-icon' }"
              [label]="'Current plan' | translate"
            >
            </app-pencil-button>
            <app-pencil-button
              *ngIf="
                currentBilling === BillingStatus.PAID && isUserOnAnnualPlan !== isViewingAnnualPlan
              "
              class="pencil-button medium btn-switch"
              data-name="settings-payment-switch-plan-btn"
              (clickEvent)="SwitchPlan()"
            >
              <span *ngIf="!loading" class="material-icons font-16">edit</span>
              <mat-spinner *ngIf="loading" [diameter]="16" class="font-16"></mat-spinner>
              {{ 'Switch' | translate }}
            </app-pencil-button>
            <ng-container *ngTemplateOutlet="upgradeButtonTemplate"></ng-container>
          </div>
        </div>
        <div class="cell">{{ 'Unlimited' | translate }}</div>
        <div class="cell">{{ 'Unlimited' | translate }}</div>
        <div class="cell">300</div>
        <div class="cell">{{ 'Unlimited' | translate }}</div>
        <div class="cell">50 GB</div>
        <div class="cell">1</div>
        <div class="cell"></div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell"></div>
        <div class="d-flex align-items-center cell"></div>
        <div class="d-flex align-items-center cell"></div>
        <div class="d-flex align-items-center cell"></div>
        <div class="d-flex align-items-center cell"></div>
        <div class="d-flex align-items-center cell"></div>
      </div>
      <div class="col">
        <div class="cell header">
          <div class="d-flex flex-column">
            <div class="font-18 fw-6 cmb-16">{{ 'Enterprise' | translate }}</div>
            <div class="font-30 cmb-16 fw-4">{{ 'Contact us' | translate }}</div>
            <app-pencil-button
              class="pencil-button medium btn-contact"
              [style.margin-top.px]="isViewingAnnualPlan ? 34 : 0"
              icon="email"
              [iconConfig]="{ type: 'material-icon', outlined: true }"
              [label]="'Contact Sales' | translate"
              (clickEvent)="openContactSales()"
            >
            </app-pencil-button>
          </div>
        </div>
        <div class="cell">{{ 'Unlimited' | translate }}</div>
        <div class="cell">{{ 'Unlimited' | translate }}</div>
        <div class="cell">{{ 'Unlimited' | translate }}</div>
        <div class="cell">{{ 'Unlimited' | translate }}</div>
        <div class="cell">{{ 'Unlimited' | translate }}</div>
        <div class="cell">{{ 'Unlimited' | translate }}</div>
        <div class="cell"></div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
        <div class="d-flex align-items-center cell">
          <span class="material-icons cmr-12">done</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isMobile" class="payment-mobile-disclaimer text-dark cmy-24">
    {{
      'Billing & Payment options are not available on mobile. To access these options, please visit settings from a
    desktop or laptop.'
        | translate
    }}
  </div>
</div>
<ng-template #upgradeButtonTemplate>
  <app-pencil-button
    *ngIf="currentBilling !== BillingStatus.PAID && currentBilling !== BillingStatus.ENTERPRISE"
    id="btn-settings-your-plan-upgrade"
    class="pencil-button medium primary btn-upgrade"
    (clickEvent)="startCheckout()"
    data-name="settings-payment-checkout-btn"
  >
    <span *ngIf="!loading" class="material-icons font-16">arrow_circle_up</span>
    <mat-spinner *ngIf="loading" [diameter]="16" color="grey" class="font-16"></mat-spinner>
    <span>{{ 'Upgrade' | translate }}</span>
  </app-pencil-button>
</ng-template>

import { fabric } from 'fabric';

export enum AppCustomPolygonType {
  RHOMBUS = 'rhombus',
  SPECIAL_RIGHT_TRIANGLE = 'special_right_triangle',
  RIGHT_TRIANGLE = 'right_triangle',
  INCLINED_RHOMBUS = 'inclined_rhombus',
  TRAPEZOID = 'trapezoid',
  STAR = 'star',
  PENTAGON = 'pentagon',
  HEXAGON = 'hexagon',
  OCTAGON = 'octagon',
}

export const activePolygons = [
  AppCustomPolygonType.RHOMBUS,
  AppCustomPolygonType.SPECIAL_RIGHT_TRIANGLE,
  AppCustomPolygonType.RIGHT_TRIANGLE,
  AppCustomPolygonType.INCLINED_RHOMBUS,
  AppCustomPolygonType.TRAPEZOID,
  AppCustomPolygonType.STAR,
  AppCustomPolygonType.PENTAGON,
  AppCustomPolygonType.HEXAGON,
  AppCustomPolygonType.OCTAGON,
];

export abstract class AppCustomPolygon extends fabric.Polygon {
  static readonly RESIZE_UNIFORM_KEY = 'resize-uniform';
  static readonly POLYGON_TYPE_KEY = 'polygon-type';
  static readonly POLYGON_SCALE_RATE = 'resize-scale-rate';

  constructor(
    name: AppCustomPolygonType,
    options: fabric.IPolylineOptions,
    resizeShouldBeUniform = false,
  ) {
    super([], options);
    this[AppCustomPolygon.POLYGON_TYPE_KEY] = name;
    this[AppCustomPolygon.RESIZE_UNIFORM_KEY] = resizeShouldBeUniform;
    this.set('perPixelTargetFind', true);
  }

  public refresh(
    startPoint: { x: number; y: number },
    endPoint: { x: number; y: number },
    holdControlKey = false,
  ): void {
    const height = this.getHeight(startPoint.y, endPoint.y);
    const width = holdControlKey ? height : this.getWidth(startPoint.x, endPoint.x);

    const points = this.calculatePoints(width, height);

    this.set('points', points);
    const dimensions = this._calcDimensions();

    this.set('left', startPoint.x < endPoint.x ? startPoint.x : startPoint.x - dimensions.width);
    this.set('top', startPoint.y < endPoint.y ? startPoint.y : startPoint.y - dimensions.height);
    this.set('height', dimensions.height);
    this.set('width', dimensions.width);
    this.set(
      'pathOffset',
      new fabric.Point(
        dimensions.left + dimensions.width / 2,
        dimensions.top + dimensions.height / 2,
      ),
    );

    this[AppCustomPolygon.POLYGON_SCALE_RATE] = dimensions.width / dimensions.height;

    this.setCoords();
  }

  static rescale(event: fabric.IEvent<Event>): void {
    const shape = event.target as AppCustomPolygon;
    if (!shape[AppCustomPolygon.RESIZE_UNIFORM_KEY]) {
      return;
    }
    const { corner } = event.transform as { corner: string };
    const { scaleY, scaleX } = shape.getObjectScaling();
    if (['mr', 'ml'].includes(corner)) {
      shape.set('scaleY', scaleX);
    } else {
      shape.set('scaleX', scaleY);
    }
  }

  abstract calculatePoints(width: number, height: number): fabric.Point[];

  getWidth(startX: number, endX: number): number {
    return Math.abs(endX - startX);
  }

  getHeight(startY: number, endY: number): number {
    return Math.abs(endY - startY);
  }
}

export class ArrowLine extends fabric.Polyline {
  static readonly POLYGON_TYPE_KEY = 'polygon-type';

  constructor(
    points: {
      x: number;
      y: number;
    }[],
    options: fabric.IPolylineOptions,
  ) {
    super(points, options);
    this[AppCustomPolygon.POLYGON_TYPE_KEY] = 'arrow-line';
  }
}

export class StarPolygon extends AppCustomPolygon {
  constructor(options: fabric.IPolylineOptions) {
    super(AppCustomPolygonType.STAR, options);
  }
  calculatePoints(width: number, height: number): fabric.Point[] {
    const APoint = new fabric.Point(width * 0.5, height * 0);
    const BPoint = new fabric.Point(width * 0.62, height * 0.34);
    const CPoint = new fabric.Point(width * 0.97, height * 0.34);
    const DPoint = new fabric.Point(width * 0.68, height * 0.56);
    const EPoint = new fabric.Point(width * 0.8, height * 0.9);
    const FPoint = new fabric.Point(width * 0.5, height * 0.69);
    const GPoint = new fabric.Point(width * 0.2, height * 0.9);
    const IPoint = new fabric.Point(width * 0.03, height * 0.34);
    const HPoint = new fabric.Point(width * 0.32, height * 0.56);
    const JPoint = new fabric.Point(width * 0.38, height * 0.34);

    return [APoint, BPoint, CPoint, DPoint, EPoint, FPoint, GPoint, HPoint, IPoint, JPoint];
  }
}

export class SpecialRightTrianglePolygon extends AppCustomPolygon {
  constructor(options: fabric.IPolylineOptions) {
    super(AppCustomPolygonType.SPECIAL_RIGHT_TRIANGLE, options, true);
  }

  calculatePoints(width: number, height: number): fabric.Point[] {
    const side = Math.min(width, height);

    const APoint = new fabric.Point(0, side * 0.45);
    const BPoint = new fabric.Point(side, side);
    const CPoint = new fabric.Point(0, side);
    return [APoint, BPoint, CPoint];
  }
}

export class RightTrianglePolygon extends AppCustomPolygon {
  constructor(options: fabric.IPolylineOptions) {
    super(AppCustomPolygonType.RIGHT_TRIANGLE, options, true);
  }

  calculatePoints(width: number, height: number): fabric.Point[] {
    const side = Math.min(width, height);

    const APoint = new fabric.Point(side * 0.5, 0);
    const BPoint = new fabric.Point(0, side * 0.5);
    const CPoint = new fabric.Point(side, side * 0.5);
    return [APoint, BPoint, CPoint];
  }
}

export class RhombusPolygon extends AppCustomPolygon {
  constructor(options: fabric.IPolylineOptions) {
    super(AppCustomPolygonType.RHOMBUS, options);
  }

  calculatePoints(width: number, height: number): fabric.Point[] {
    const APoint = new fabric.Point(width * 0.5, 0);
    const BPoint = new fabric.Point(width, height * 0.5);
    const CPoint = new fabric.Point(width * 0.5, height);
    const DPoint = new fabric.Point(0, height * 0.5);
    return [APoint, BPoint, CPoint, DPoint];
  }
}

export class InclinedRhombusPolygon extends AppCustomPolygon {
  constructor(options: fabric.IPolylineOptions) {
    super(AppCustomPolygonType.INCLINED_RHOMBUS, options);
  }

  calculatePoints(width: number, height: number): fabric.Point[] {
    const APoint = new fabric.Point(width * 0.5, 0);
    const BPoint = new fabric.Point(width, 0);
    const CPoint = new fabric.Point(width * 0.5, height);
    const DPoint = new fabric.Point(0, height);
    return [APoint, BPoint, CPoint, DPoint];
  }
}

export class TrapezoidPolygon extends AppCustomPolygon {
  constructor(options: fabric.IPolylineOptions) {
    super(AppCustomPolygonType.TRAPEZOID, options);
  }

  calculatePoints(width: number, height: number): fabric.Point[] {
    const APoint = new fabric.Point(width * 0.15, 0);
    const BPoint = new fabric.Point(width * 0.8, 0);
    const CPoint = new fabric.Point(width, height);
    const DPoint = new fabric.Point(0, height);
    return [APoint, BPoint, CPoint, DPoint];
  }
}

export class PentagonPolygon extends AppCustomPolygon {
  constructor(options: fabric.IPolylineOptions) {
    super(AppCustomPolygonType.PENTAGON, options);
  }

  calculatePoints(width: number, height: number): fabric.Point[] {
    const APoint = new fabric.Point(width * 0.51, 0);
    const BPoint = new fabric.Point(width * 0.975, height * 0.35);
    const CPoint = new fabric.Point(width * 0.8, height * 0.9);
    const DPoint = new fabric.Point(width * 0.2, height * 0.9);
    const EPoint = new fabric.Point(width * 0.04, height * 0.33);
    return [APoint, BPoint, CPoint, DPoint, EPoint];
  }
}

export class HexagonPolygon extends AppCustomPolygon {
  constructor(options: fabric.IPolylineOptions) {
    super(AppCustomPolygonType.HEXAGON, options);
  }

  calculatePoints(width: number, height: number): fabric.Point[] {
    const APoint = new fabric.Point(width * 0.25, height * 0.06);
    const BPoint = new fabric.Point(width * 0.75, height * 0.06);
    const CPoint = new fabric.Point(width * 1, height * 0.5);
    const DPoint = new fabric.Point(width * 0.75, height * 0.94);
    const EPoint = new fabric.Point(width * 0.25, height * 0.94);
    const FPoint = new fabric.Point(width * 0, height * 0.5);
    return [APoint, BPoint, CPoint, DPoint, EPoint, FPoint];
  }
}

export class OctagonPolygon extends AppCustomPolygon {
  constructor(options: fabric.IPolylineOptions) {
    super(AppCustomPolygonType.OCTAGON, options);
  }

  calculatePoints(width: number, height: number): fabric.Point[] {
    const APoint = new fabric.Point(width * 0, height * 0.5);
    const BPoint = new fabric.Point(width * 0.14, height * 0.14);
    const CPoint = new fabric.Point(width * 0.5, height * 0);
    const DPoint = new fabric.Point(width * 0.86, height * 0.14);
    const EPoint = new fabric.Point(width * 1, height * 0.5);
    const FPoint = new fabric.Point(width * 0.86, height * 0.86);
    const GPoint = new fabric.Point(width * 0.5, height * 1);
    const HPoint = new fabric.Point(width * 0.14, height * 0.86);
    return [APoint, BPoint, CPoint, DPoint, EPoint, FPoint, GPoint, HPoint];
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileNameFormat',
})
export class FileNameFormatPipe implements PipeTransform {

  defaultExtensionLength = 5;
  transform(name: string, args?: any[]): string {
    if (!name) {
      return 'N/A';
    }
    const index = name.lastIndexOf('.');
    const extLength = name.length - index;
    const cutoffLength = Math.max(args?.length ? parseInt(args[0], 10) : 20, 0)
    // if extension is REALLY large or missing - the file name is a link or something similar.
    if (extLength >= cutoffLength || (name.length > cutoffLength && index === -1)) {
      return `${name.substr(0, cutoffLength)}...`;
    }
    if (index === -1) {
      return name;
    }
    // if extension is quite large.
    const limit = Math.max(cutoffLength - Math.max(extLength - this.defaultExtensionLength, 0), 0);
    let fileName = '';
    if (index - 1 > limit) {
      fileName += `${name.substr(0, limit)}...`;
    } else {
      fileName += name.substr(0, index - 1);
    }
    fileName += name.substr(index - 1)
    return fileName;
  }
}

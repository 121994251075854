import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notificationTime',
})
export class NotificationTimePipe implements PipeTransform {
  transform(dateParam: Date): string {
    if (!dateParam) {
      return '';
    }

    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const today = new Date();
    const seconds = Math.round(((today as any) - (date as any)) / 1000);
    const minutes = Math.round(seconds / 60);
    const hours = Math.round(minutes / 60);

    if (seconds < 5) {
      return 'now';
    } else if (seconds < 60) {
      return `${seconds} sec ago`;
    } else if (seconds < 90) {
      return '1 min ago';
    } else if (minutes < 60) {
      return `${minutes} min ago`;
    } else if (hours === 1) {
      return 'An hour ago';
    } else if (hours <= 12) {
      return `${hours} hours ago`;
    }

    return this.getFormattedDate(date);
  }

  getFormattedDate(date, prefomattedDate = '') {
    let hours = date.getHours();
    // eslint-disable-next-line frontend-rules/ngx-translate-service
    let b = 'a.m.';

    if (hours > 12) {
      hours = hours - 12;
      // eslint-disable-next-line frontend-rules/ngx-translate-service
      b = 'p.m.';
    }
    let minutes = date.getMinutes();

    if (minutes < 10) {
      // Adding leading zero to minutes
      minutes = `0${minutes}`;
    }

    if (prefomattedDate !== '') {
      // Today at 10:20
      return `${prefomattedDate} at ${hours}:${minutes} ${b}`;
    }

    // 10:20 a.m.
    return `${hours}:${minutes} ${b}`;
  }
}

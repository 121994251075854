export interface TimeSlot {
  day: number; // 0-indexed, Sunday-Saturday
  start: string; // e.g. '09:00'
  end: string; // e.g. '17:00'
}

export enum AvailabilityStatus {
  UNKNOWN = 'UNKNOWN',
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
}

export interface UserAvailabilityData {
  _id: string;
  email: string;
  name: string;
  profile_image?: string;
  availabilityStatus?: AvailabilityStatus;
}

export class Availability {
  userId: string;
  slots: TimeSlot[];

  constructor(res: any) {
    this.userId = res.userId;
    this.slots = res.slots || [];
  }
}

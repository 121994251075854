<div *ngIf="showLinkCopied" class="link-copy-snackbar">
  <em class="material-icons font-18 cmr-4">done</em>
  {{ 'Link copied' | translate }}
</div>

<div class="top-section">
  <div class="header-section">
    <button mat-icon-button class="header-button-right header-button" (click)="close()">
      <div class="material-icons">close</div>
    </button>
    <div class="text-center header-text">{{ 'Refer a friend' | translate }}</div>
  </div>
  <div class="d-block text-center body-margin">
    <div class="font-18 fw-6 text-center">
      {{ 'Thank you for using Pencil Spaces!' | translate }}
    </div>
    <div class="text-dark text-center font-14 cmb-16 cmt-8">
      {{
        ' Why not share Pencil Spaces with colleagues and friends? If they sign-up to Pro, we will give you 1 month of Pro for free!'
          | translate
      }}
    </div>
    <div class="d-flex flex-column">
      <app-email-search
        [emails]="emails"
        (emitEmails)="handleEmailsInput($event)"
      ></app-email-search>
      <button
        mat-button
        class="bottom-margin referral-email-button cmt-20"
        data-name="referral-send-emails-btn"
        (click)="sendReferralEmails()"
        [disabled]="!emails.length || !!invalidEmails.length"
      >
        <span class="material-icons-outlined mr-1">person_add</span>
        <span>{{ 'Send' | translate }}</span>
      </button>
    </div>
  </div>
</div>

<div class="bottom-section bg-white border-radius-8 cmt-8">
  <div class="copy-link-container cmy-20">
    <div class="d-flex align-items-center">
      <mat-icon class="lock-icon"> link </mat-icon>
      <div class="link-column font-12 cml-8">
        <div class="transparent-button">
          {{ 'Your unique referral link' | translate }}
        </div>
        <div class="visibility-explanation">
          {{ 'Copy this URL and share it with your friends' | translate }}
        </div>
      </div>
    </div>
    <div class="copy-social-share-container">
      <button
        mat-flat-button
        class="btn-copy"
        data-name="referral-copy-link-btn"
        (click)="copyLink()"
      >
        {{ 'Copy' | translate }}
      </button>
      <div class="vertical-divider"></div>
      <app-social-share [url]="referralUrl"></app-social-share>
    </div>
  </div>
</div>

<div class="email-search-container">
  <div class="d-flex">
    <span class="material-icons-outlined add-icon cmr-8">person_add</span>
    <div class="search-box">
      <div
        class="search-bar"
        [class.active]="isSearchFocused"
        [class.hover]="isSearchHovered"
        [class.has-email]="emails.length > 0"
        [class.invalid]="isInvalid"
        [class.show-email-chips]="showParsedEmailChips"
      >
        <div class="email-chip-list" *ngIf="showParsedEmailChips">
          <div
            *ngFor="let email of emails"
            class="email-chip ingestion"
            [ngbTooltip]="errorTooltip"
            [disableTooltip]="true"
            placement="top"
          >
            <ng-template #errorTooltip>
              <app-tooltip [text]="" [arrow]="'bottom'"></app-tooltip>
            </ng-template>
            <mat-icon *ngIf="invalidEmailMap[email]">error_outline</mat-icon>
            {{ email | shortEmail }}
            <span
              (click)="onRemoveUser(email)"
              class="closebtn"
              [class.error]="invalidEmailMap[email]"
            >
              &times;
            </span>
          </div>
        </div>
        <input
          #userSearch
          type="search"
          [(ngModel)]="searchQuery"
          placeholder="{{ emails.length ? '' : placeholder }}"
          autofocus="true"
          autocomplete="nope"
          (focus)="onFocusSearch()"
          (blur)="onBlurSearch()"
          (input)="onInputChange()"
          (mouseover)="isSearchHovered = true"
          (mouseout)="isSearchHovered = false"
          (keypress)="onKeyPress($event)"
          (paste)="onPaste($event)"
        />
      </div>
    </div>
  </div>
  <div
    class="search-results-container"
    *ngIf="!suggestUsers && searchQuery.length && !emails.includes(searchQuery) && validEmailInput"
    [style.max-height]="emails.length ? '130px' : '185px'"
  >
    <div class="search-result-option" (click)="addUser(searchQuery)">
      <svg-icon class="user-photo" [icon]="'user'" [width]="30" [height]="30"></svg-icon>
      <div class="cml-8 d-inline-flex flex-column user-info-cont">
        <span class="name">{{ searchQuery }}</span>
      </div>
    </div>
  </div>
  <div
    *ngIf="suggestUsers && isSearchFocused && searchQuery.length"
    class="suggest-users-container"
  >
    <div class="invite-email-indicator" *ngIf="validEmailInput && !filteredUsers.length">
      <div class="search-result-option" role="button" (mousedown)="processTypedEmail(searchQuery)">
        <div class="d-flex align-items-center position-relative">
          <svg-icon class="user-photo" [icon]="'user'" [width]="45" [height]="45"></svg-icon>
          <div class="cml-8 d-inline-flex flex-column user-info-cont">
            <span class="name">{{ searchQuery }}</span>
            <span class="more-info">
              {{ 'Outside of institution' | translate }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngFor="let user of filteredUsers; let i = index"
      class="search-result-option"
      role="button"
      (mousedown)="selectUser(user)"
    >
      <div class="d-flex align-items-center position-relative">
        <img [src]="user?.profile_image" *ngIf="user?.profile_image" class="user-photo" />
        <svg-icon
          *ngIf="!user"
          class="user-photo"
          [icon]="'user'"
          [width]="45"
          [height]="45"
          [ngbTooltip]="'This user doesn\'t exist anymore' | translate"
        ></svg-icon>
        <div *ngIf="user && !user.profile_image" class="user-photo" [style.font-size]="'16px'">
          {{ user?.name | shortName }}
        </div>
        <div class="cml-8 d-inline-flex flex-column user-info-cont">
          <span class="name">{{ user?.name }}</span>
          <span class="more-info">
            {{ user?.personas[0] ? user?.personas[0] + ' | ' : '' }}
            {{ user?.email }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>

<div class="dialog-container">
  <div
    class="prompt-panel"
    [class.custom_prompt_panel]="notFitAllSteps | async"
    [style.height]="'100%'"
  >
    <div class="header">
      <button mat-icon-button (click)="onMaybeLater()" class="btn-close">
        <em class="material-icons">close</em>
      </button>
    </div>
    <div class="prompt-content">
      <div class="prompt-text">
        <span class="prompt-text-title">{{ 'Join the conversation!' | translate }}</span>
        <span class="prompt-text-body"
          >{{ 'Allow your browser to access your camera and microphone so' | translate }}
          <br class="d-none d-md-block" />
          {{
            'that others can see and hear you. You can always turn them off at any time.'
              | translate
          }}
        </span>
      </div>
      <ng-container *ngIf="showDefaultGraphics | async; then defaultGraphics; else newGraphics">
      </ng-container>
      <ng-template #defaultGraphics>
        <svg-icon
          class="prompt-svg"
          [width]="440"
          [height]="360"
          [icon]="'prompt'"
          [maxWidth]="'fit-content'"
        >
        </svg-icon>
      </ng-template>
      <ng-template #newGraphics>
        <img
          [src]="allowAccessGraphicsSrc"
          class="allow-permissions-img"
          alt="{{ 'allow permissions' | translate }}"
        />
      </ng-template>
    </div>
    <div class="divider"></div>
    <div class="prompt-buttons">
      <button class="support-button" id="intercomHelpLink" (click)="liveSupport()">
        <em class="material-icons-outlined mat-icon-support"> help_outline </em>
        <span class="support-text">{{ 'Help' | translate }}</span>
      </button>
      <button
        id="request-devices-permissions"
        class="next-button"
        [style.width]="'unset'"
        (click)="onRequestPermissions()"
      >
        <em class="material-icons-outlined mat-icon-support"> arrow_forward </em>
        <span class="support-text">{{ 'Request permissions' | translate }}</span>
      </button>
    </div>
  </div>
</div>

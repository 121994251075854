<div class="modal-header">
  <span class="d-inline-block" *ngIf="action == 'add'">{{ 'Add tag' | translate }}</span>
  <span class="d-inline-block" *ngIf="action == 'remove'">{{ 'Remove tag' | translate }}</span>
  <button class="d-inline-block float-right" mat-icon-button (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="modal-body">
  <span class="text-center d-block mb-2" *ngIf="action == 'add'">{{
    'Are you sure you want to add the ' + tag.value + ' tag to all selected items?' | translate
  }}</span>
  <span class="text-center d-block mb-2" *ngIf="action == 'remove'">{{
    'Are you sure you want to remove the ' + tag.value + ' tag from all selected items?' | translate
  }}</span>
  <div class="text-center">
    <button class="me-4" mat-raised-button (click)="cancel()">
      <mat-icon>close</mat-icon>
      <span>{{ 'Cancel' | translate }}</span>
    </button>
    <button mat-raised-button (click)="confirm()">
      <mat-icon>done</mat-icon>
      <span>{{ "Yes, I'm sure" | translate }}</span>
    </button>
  </div>
</div>

<div id="available-hosts-dialog" class="common-dialog">
  <div class="dialog-header">
    <div class="dialog-title">{{ 'Find organizers' | translate }}</div>
    <div class="dialog-close">
      <mat-icon class="material-icons-outlined close-icon" role="button" (click)="cancel()"
        >close</mat-icon
      >
    </div>
  </div>
  <div class="dialog-body">
    <div class="user-search">
      <app-search-bar
        [searchTitle]="'Search for host' | translate"
        (filter)="handleSearch($event)"
      ></app-search-bar>
    </div>
    <div class="data-info">
      <div class="time-label">
        {{ event.start_time | dateUserLocale: DateFormatEnum.FULLDATETIME }} -
        {{ event.end_time | dateUserLocale: DateFormatEnum.SHORTTIME }}
      </div>
      <div class="paginator-wrap">
        <div class="paginator-label" *ngIf="!isLoading && dataSource?.filteredData.length">
          <span>{{
            paginator.pageIndex | paginatorDescription: paginator.pageSize:paginator.length
          }}</span>
          <span>{{ 'organizers' | translate }}</span>
        </div>
        <mat-paginator
          #paginator
          hidePageSize
          class="mat-paginator"
          [length]="dataSource?.data.length"
          [pageSize]="pageSize"
          [disabled]="isLoading"
          (page)="userListContent.nativeElement.scroll({ top: 0, behavior: 'smooth' })"
        ></mat-paginator>
      </div>
    </div>
    <div class="user-list">
      <div class="user-list-header">
        <div class="first-col">{{ 'Name' | translate }}</div>
        <div class="second-col">{{ 'Availability' | translate }}</div>
        <div class="space-col"></div>
      </div>
      <div class="user-list-content" *ngIf="isLoading">
        <div class="availability-loader">
          <app-content-loader [floatLoader]="true" [blockCount]="5"></app-content-loader>
        </div>
      </div>
      <div #userListContent class="user-list-content" *ngIf="!isLoading">
        <div class="blank-state" *ngIf="!dataSource?.filteredData.length">
          <span>{{ 'No organizers found' | translate }}</span>
        </div>
        <table mat-table [dataSource]="dataSource">
          <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
          <ng-container matColumnDef="user">
            <td mat-cell *matCellDef="let user">
              <app-lightweight-user-avatar
                width="32"
                [showName]="true"
                [isUser]="!!user"
                [profileImage]="user?.profile_image"
                [userRole]="user?.role"
                [userEmail]="user?.email"
                [userName]="user?.name"
              ></app-lightweight-user-avatar>
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <td mat-cell *matCellDef="let user">
              <app-tutor-availability-status
                [status]="user.availabilityStatus"
              ></app-tutor-availability-status>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <td mat-cell *matCellDef="let user">
              <app-pencil-button
                *ngIf="!hostsToAdd.includes(user._id)"
                class="pencil-button light flat-white"
                data-name="events-availability-add-host-to-event"
                [label]="'Add to event' | translate"
                (clickEvent)="selectHost(user._id)"
              ></app-pencil-button>
              <div class="added-host" *ngIf="hostsToAdd.includes(user._id)">
                <span>{{ 'Added' | translate }}</span>
                <app-pencil-icon
                  role="button"
                  data-name="events-availability-remove-host-from-event"
                  name="close"
                  [type]="IconTypes.matIcon"
                  (click)="removeHost(user._id)"
                ></app-pencil-icon>
              </div>
            </td>
          </ng-container>
        </table>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <div class="host-to-add-number" *ngIf="hostsToAdd.length">
      {{ hostsToAdd.length }}
      {{ (hostsToAdd.length > 1 ? 'organizers' : 'organizer') | translate }}
      {{ 'will be added' | translate }}
    </div>
    <app-pencil-button
      class="btn-submit pencil-button light medium"
      data-name="schedule-availability-cancel"
      icon="close"
      buttonType="button"
      [iconConfig]="{
        type: 'material-icon'
      }"
      [label]="'Cancel' | translate"
      (clickEvent)="cancel()"
    >
    </app-pencil-button>
    <app-pencil-button
      class="btn-submit pencil-button primary medium"
      data-name="schedule-availability-submit"
      icon="done"
      [iconConfig]="{
        type: 'material-icon'
      }"
      [label]="'Done' | translate"
      (clickEvent)="close()"
    >
    </app-pencil-button>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconComponent } from './svg-icon.component';

@NgModule({
  declarations: [
    SvgIconComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    SvgIconComponent,
  ],
})
export class SVGIconComponentsModule {}

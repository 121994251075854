import { Injectable } from '@angular/core';
import { getDownloadURL, ref, Storage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root',
})
export class ThumbnailService {
  constructor(private storage: Storage) {}

  async getThumbnailUrl(
    filename: string,
    imgDownloadURL: string,
    width: number,
    height: number,
  ): Promise<any> {
    const thumbnailPath = `${
      filename ?? this.getLastSegmentUrl(imgDownloadURL)
    }_${width}x${height}`;
    const thumbnailsRef = ref(this.storage, `thumbnails/${thumbnailPath}`);

    return getDownloadURL(thumbnailsRef);
  }

  private getLastSegmentUrl(url: string): string {
    const withoutQueries = url.split('?')[0];
    const segments = withoutQueries.split('/');
    return segments[segments.length - 1];
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
} from '@angular/core';
import { filterNil } from '@ngneat/elf';
import pdfjsLib from 'src/assets/pdfjs-config';

import { from, map, switchMap, forkJoin, Observable, BehaviorSubject, of } from 'rxjs';
import { FLAGS, FlagsService } from 'src/app/services/flags.service';

const pageOperator$ = (
  pdfProxy: pdfjsLib.PDFDocumentProxy,
  pageNumber: number,
  scaleFactor: number,
  width: number,
) =>
  from(pdfProxy.getPage(pageNumber)).pipe(
    map((page) => {
      const viewport = page.getViewport({ scale: scaleFactor * 1.4 });

      const height = Math.floor((width / viewport.width) * viewport.height);

      return {
        page,
        viewport,
        height,
        width,
      };
    }),
  );

interface PDFPageViewModel {
  page: pdfjsLib.PDFPageProxy;
  viewport: pdfjsLib.PageViewport;
  height: number;
  width: number;
}

@Component({
  selector: 'app-pdf-fragment-container',
  templateUrl: './pdf-fragment-container.component.html',
  styleUrls: ['./pdf-fragment-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfFragmentContainerComponent implements AfterViewInit {
  @Input() set src(value: string | undefined) {
    this._srcSubject.next(value);
  }
  @Input() page?: number;

  private _srcSubject = new BehaviorSubject<string | undefined>(undefined);
  scaleFactor: number;

  constructor(
    private flagsService: FlagsService,
    private elementRef: ElementRef,
    private cdRef: ChangeDetectorRef,
  ) {
    this.scaleFactor = this.flagsService.isFlagEnabled(FLAGS.ENABLE_PDF_UPSCALE)
      ? (this.flagsService.featureFlagsVariables.enable_pdf_upscale.pdf_scale_factor as number)
      : 1.0;
  }

  data$: Observable<PDFPageViewModel[]> = of<PDFPageViewModel[]>([]);

  ngAfterViewInit(): void {
    this.data$ = this._srcSubject.asObservable().pipe(
      filterNil(),
      switchMap((src) => from(pdfjsLib.getDocument(src).promise)),
      switchMap((pdf) =>
        this.page && this.page > 0
          ? pageOperator$(pdf, this.page, this.scaleFactor, this.hostWidth).pipe(
              map((page) => [page]),
            )
          : forkJoin(
              Array.from({
                length: pdf.numPages,
              }).map((_, index) => pageOperator$(pdf, index + 1, this.scaleFactor, this.hostWidth)),
            ),
      ),
    );

    this.cdRef.detectChanges();
  }

  get hostWidth(): number {
    return (this.elementRef.nativeElement as HTMLElement).offsetWidth;
  }
}

<div class="prepare-document-container">
  <div class="prepare-document-header">
    <p class="prepare-document-header-text">
      {{ 'Import PDF' | translate }}
    </p>
  </div>
  <mat-divider class="prepare-document-header-divider"></mat-divider>
  <p class="prepare-document-filename-text" *ngIf="fileName">{{ fileName }}</p>

  <p class="prepare-document-text">{{ 'Preparing document for import...' | translate }}</p>
  <div class="prepare-document-progress-bar progress">
    <div
      class="prepare-document-progress-bar-value progress-bar progress-bar-animated"
      [style.width]="value + '%'"
    ></div>
  </div>
</div>

<div id="create-space-dialog">
  <div class="head">
    <div class="go-back" role="button" (click)="close()">
      <span class="material-icons">keyboard_arrow_left</span>
      <span class="back-text">{{ 'Back' | translate}}</span>
    </div>
    <div class="title">{{ 'Name your new Space' | translate }}</div>
  </div>
  <main>
    <form [formGroup]="form" (submit)="save()">
      <div
        class="form-control-row"
        [class.form-error]="!!(form.controls.name.errors?.required || form.controls.name.errors?.whitespace) && form.controls.name.touched"
      >
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'Type name' | translate }}
          </mat-label>
          <input matInput formControlName="name" />
          <mat-error
            *ngIf="form.controls.name.errors?.required || form.controls.name.errors?.whitespace"
          >
            {{ 'Space name cannot be empty' | translate }}
          </mat-error>
          <mat-error *ngIf="form.controls.name.errors?.maxlength">
            {{ 'Space name must be less than 100 characters' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <button mat-flat-button color="primary" type="submit" class="submit-button" [disabled]="form.invalid">
        <span class="material-icons" *ngIf="!isLoading">keyboard_arrow_right</span>
        <span class="btn-label">{{ 'Continue' | translate }} </span>
        <mat-spinner *ngIf="isLoading" [diameter]="16"></mat-spinner>
      </button>
    </form>
  </main>
</div>

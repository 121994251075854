import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import pdfjsLib from 'src/assets/pdfjs-config';
import { BehaviorSubject, map } from 'rxjs';
import { TruncateStringPipe } from 'src/app/standalones/pipes/truncate-string.pipe';
import { HiddenPageComponent } from '../hidden-page/hidden-page.component';
import { ChromeCanvasBugWorkaroundDirective } from '../../../directives/chrom-canvas-bug-workaround.directive';
@Component({
  selector: 'app-pdf-page[page][viewport]',
  templateUrl: './pdf-page.component.html',
  styleUrls: ['./pdf-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TruncateStringPipe,
    HiddenPageComponent,
    ChromeCanvasBugWorkaroundDirective,
  ],
})
export class PdfPageComponent implements OnInit, OnChanges, OnDestroy {
  @Input() page?: pdfjsLib.PDFPageProxy;
  @Input() viewport?: pdfjsLib.PageViewport;
  @Input() pdfName = '';
  @Input() scaleFactor = 1;
  @Input() height?: number;
  @Input() width?: number;
  @Input() hidePage = false;
  @Input() totalPage = 1;
  @Output() pageRendered = new EventEmitter<void>();

  @ViewChild('canvas') canvasElm?: ElementRef;
  renderCanvas = new BehaviorSubject(true);
  hideCanvas$ = this.renderCanvas.asObservable().pipe(map((value) => !value));
  private observer?: IntersectionObserver;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.renderCanvas.next(true);
          this.renderPdfPageToCanvas();
        } else {
          if (this.renderCanvas.getValue()) {
            this.renderCanvas.next(false);
            this.cleanupCanvas();
          }
        }
      });
    });
    this.observer.observe(this.elementRef.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.hidePage?.currentValue && changes?.hidePage?.previousValue) {
      this.renderPdfPageToCanvas();
    }
  }

  renderPdfPageToCanvas(): void {
    if (!this.page || !this.renderCanvas.getValue() || this.hidePage) {
      return;
    }

    const viewport = this.viewport as pdfjsLib.PageViewport;
    const canvas = this.canvasElm?.nativeElement as HTMLCanvasElement;
    canvas.className = 'page';
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    canvas.style.height = `${this.height}px`;
    canvas.style.width = `${this.width}px`;
    const context = canvas.getContext('2d', { alpha: false });
    if (context) {
      this.page.render({
        canvasContext: context,
        viewport,
      });

      this.pageRendered.emit();
    }
  }

  cleanupCanvas(): void {
    this.page?.cleanup(true);
    this.context?.clearRect(0, 0, this.canvas?.height as number, this.canvas?.width as number);
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
    this.page?.cleanup();
  }

  get canvas(): HTMLCanvasElement | undefined {
    return this.canvasElm?.nativeElement as HTMLCanvasElement;
  }

  get context(): CanvasRenderingContext2D | undefined | null {
    return this.canvas?.getContext('2d', { alpha: false });
  }
}

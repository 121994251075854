import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[appShowSubmenuOnClick]',
  exportAs: 'appShowSubmenuOnClick',
})
export class ShowSubmenuOnClickDirective {
  /*
    The default way to open submenu in mat-menu is to hover, attaching this directive to MatMenuTrigger element
    for the corresponding submenu will allow submenu to open on click not just hover.

    The steps to use this directive are: 
      1. Attach directive to same element on which MatMenuTrigger is attached.
      2. Create reference on appShowSubmenuOnClick directive.
      3. Use [class]="<reference>.submenuClass$|async" on mat-menu element.
  */
  public submenuClass$ = new BehaviorSubject<string>('d-none');

  constructor(
    private menuTrigger: MatMenuTrigger,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.menuTrigger.menuClosed.subscribe(() => {
      this.submenuClass$.next('d-none');
    });

    this.renderer.listen(this.elementRef.nativeElement, 'click', () => {
      const newClass = this.submenuClass$.value === 'd-none' ? '' : 'd-none';
      this.submenuClass$.next(newClass);
    });
  }
}

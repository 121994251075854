import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-close-frame',
  templateUrl: './close-frame.component.html',
  styleUrls: ['./close-frame.component.scss']
})
export class CloseFrameComponent {

  constructor(
    private dialogRef: MatDialogRef<CloseFrameComponent>,
  ) { }


  finish(result: boolean): void {
    this.dialogRef.close(result)
  }

}

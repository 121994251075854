import { Injectable } from '@angular/core';
import * as mp from '@mediapipe/hands';
import * as Sentry from '@sentry/browser';
import * as handPoseDetection from '@tensorflow-models/hand-pose-detection';
import '@tensorflow/tfjs-backend-webgl';
import * as Comlink from 'comlink';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { getGesture } from '../hand-gestures/gestureInference';
import { GestureWorker } from '../hand-gestures/gestures.worker';
import { GestureName } from '../hand-gestures/models/gesture';
import { modifiedSetInterval, modifiedSetTimeout } from '../utilities/ZoneUtils';
import { FLAGS, FlagsService } from './flags.service';

export class videoAnalyticsData {
  videoAttentionScore = 0.0;
  speakingTime?: number = 0;
  numberOfQuestionsAsked = 0;
  missingTime = 0;
  videoAttentionCount = 0;

  updateVideoAttention(score: number): void {
    this.videoAttentionScore += (score - this.videoAttentionScore) / this.videoAttentionCount;
  }

  tick(): void {
    this.videoAttentionCount += 1;
  }
}

@Injectable({
  providedIn: 'root',
})
export class VideoAIService {
  attentionScores!: videoAnalyticsData;
  private userVideoElement?: HTMLVideoElement;
  private isFaceDetectedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private isHandsRaisedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private emoticonSubject: Subject<GestureName> = new Subject<GestureName>();
  private handDetetctionOptions = {
    maxNumHands: 2,
    minDetectionConfidence: 0.8,
    minTrackingConfidence: 0.8,
    selfieMode: true,
  };
  private faceDetetctionOptions = { minDetectionConfidence: 0.5, selfieMode: true };
  private userMissingSince?: number;
  private numberOfLoadModelErrors = 0;
  private numberOfInferenceErrors = 0;
  private handDetectionEnabled = true;
  private isFaceDetected = true;
  private isHandDetected = false;
  private handDetectionInterval?: NodeJS.Timer;
  private isHandsModelRunning = false;
  private mediapipeHandsModel?: handPoseDetection.HandDetector;
  private handsModelConfig: handPoseDetection.MediaPipeHandsMediaPipeModelConfig;
  private estimationConfig: handPoseDetection.MediaPipeHandsMediaPipeEstimationConfig;
  private thresholds: any = {
    HANDS_RAISED: undefined,
    THUMBS_UP: undefined,
    THUMBS_DOWN: undefined,
    VICTORY_HANDS: undefined,
  };
  private gestureThreshold = 0.9;
  private gestureFound = false;
  private gestureDetectionEnabled = false;
  private config = {
    handDetectionEnabled: true,
    faceDetectionEnabled: false,
    handsThreshold: 0.8,
    handsFlipHorizontal: true,
    handsModelType: 'full',
    gestureThreshold: 0.99,
    fps: 2,
  };
  private gestureWebWorkerEnabled;
  private gesturesWebWorker?: Comlink.Remote<GestureWorker>;

  constructor(private flagsService: FlagsService) {
    this.attentionScores = new videoAnalyticsData();
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.forEach((param) => {
      this.config[urlParams.get(param) as string] = urlParams.get(param);
    });
    this.handsModelConfig = {
      runtime: 'mediapipe',
      solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/hands@${mp.VERSION}`,
      modelType: this.config.handsModelType,
    } as handPoseDetection.MediaPipeHandsMediaPipeModelConfig;
    this.estimationConfig = {
      flipHorizontal: this.config.handsFlipHorizontal,
    };
    this.gestureThreshold = this.config.gestureThreshold;

    // Custom config from URL Parameters for testing

    if (urlParams.has('faceThreshold')) {
      const faceThreshold = parseFloat(urlParams.get('faceThreshold') as any);
      if (faceThreshold <= 1) {
        this.faceDetetctionOptions.minDetectionConfidence = faceThreshold;
      } else {
        console.warn('Enter faceThreshold less than one');
      }
    }

    if (urlParams.has('handTrackingThreshold')) {
      const handTrackingThreshold = parseFloat(urlParams.get('handTrackingThreshold') as any);
      if (handTrackingThreshold <= 1) {
        this.handDetetctionOptions.minTrackingConfidence = handTrackingThreshold;
      } else {
        console.warn('Enter handTrackingThreshold less than one');
      }
    }

    if (urlParams.has('handDetectionThreshold')) {
      const handDetectionThreshold = parseFloat(urlParams.get('handDetectionThreshold') as any);
      if (handDetectionThreshold <= 1) {
        this.handDetetctionOptions.minDetectionConfidence = handDetectionThreshold;
      } else {
        console.warn('Enter handDetectionThreshold less than one');
      }
    }

    if (urlParams.has('handsModelType')) {
      const handsModelType = urlParams.get('handsModelType');
      if (handsModelType === 'lite' || handsModelType === 'full') {
        this.handsModelConfig.modelType = handsModelType;
      } else {
        console.warn('Enter valid handsModelType -> lite or full');
      }
    }

    if (urlParams.has('gestureThreshold')) {
      this.gestureThreshold = parseFloat(urlParams.get('gestureThreshold') as any);
    }
    if (urlParams.has('fps')) {
      this.config.fps = parseFloat(urlParams.get('fps') as any);
    }
  }

  faceObservable(): Observable<boolean> {
    return this.isFaceDetectedSubject.asObservable();
  }

  handRaisedObservable(): Observable<boolean> {
    return this.isHandsRaisedSubject.asObservable();
  }

  emoticonObservable(): Observable<GestureName> {
    return this.emoticonSubject.asObservable();
  }

  userFaceMissingTime(): number {
    if (this.userMissingSince) {
      return (Date.now() - this.userMissingSince) / 1000;
    }
    return 0;
  }

  userFaceMissing(): void {
    this.userMissingSince = Date.now();
  }

  onResultsFace(results): void {
    this.attentionScores.tick();
    if (results.detections.length > 0) {
      if (!this.isFaceDetected) {
        this.isFaceDetectedSubject.next(true);
        this.isFaceDetected = true;
      }

      const left_eye = results.detections[0].landmarks[0].x;
      const right_eye = results.detections[0].landmarks[1].x;

      const left_ear = results.detections[0].landmarks[4].x;
      const right_ear = results.detections[0].landmarks[5].x;

      const eyer = results.detections[0].landmarks[0].x;
      const eyel = results.detections[0].landmarks[1].x;
      const nose = results.detections[0].landmarks[2].x;

      const gapLength = eyer - eyel;
      const towardsLeft = nose - eyel;
      const towardsRight = eyer - nose;

      const towardsLeftnorm = towardsLeft / gapLength;
      const towardsRighttnorm = towardsRight / gapLength;

      if (towardsLeftnorm > towardsRighttnorm) {
        // User is looking to the left
        if (left_ear > nose && left_ear > right_eye) {
          this.attentionScores.updateVideoAttention(0);
        } else if (left_ear > left_eye && left_ear > nose) {
          this.attentionScores.updateVideoAttention(0.5);
        } else {
          this.attentionScores.updateVideoAttention(1);
        }
      } else {
        // User is looking to the right
        if (left_eye > right_ear && nose > right_ear) {
          this.attentionScores.updateVideoAttention(0);
        } else if (right_eye > right_ear && nose > right_ear) {
          this.attentionScores.updateVideoAttention(0.5);
        } else {
          this.attentionScores.updateVideoAttention(1);
        }
      }
    } else {
      // Face not detected
      if (this.isFaceDetected) {
        this.isFaceDetected = false;
        this.isFaceDetectedSubject.next(false);
      }

      if (this.userMissingSince) {
        if (this.userFaceMissingTime() > 2) {
          this.attentionScores.missingTime += this.userFaceMissingTime();
          this.userMissingSince = Date.now();
        }
      } else {
        this.userMissingSince = Date.now();
      }
      this.attentionScores.updateVideoAttention(0);
    }
  }

  async onResultsHands(results): Promise<void> {
    if (results.length === 0) {
      this.handleNoHandDetected();
      return;
    }
    for (const classification of results) {
      if (classification.score < this.handDetetctionOptions.minDetectionConfidence) {
        continue;
      }
      const { keypoints3D, handedness } = classification;
      let detectedGesture;
      if (this.gestureWebWorkerEnabled) {
        detectedGesture = await this.gesturesWebWorker?.getHandGesture(keypoints3D, handedness);
      } else {
        detectedGesture = getGesture(keypoints3D, handedness);
      }
      if (!detectedGesture) {
        this.clearEmote();
        return;
      }
      if (detectedGesture === GestureName.HAND_RAISED) {
        this.handleHandRaisedGesture(true);
        return;
      } else {
        // User hands are not raised
        this.handleHandRaisedGesture(false);
        // Send other gestures
        this.sendGesture(detectedGesture);
      }
    }
  }

  handleNoHandDetected(): void {
    if (this.isHandDetected) {
      this.isHandsRaisedSubject.next(false);
      this.isHandDetected = false;

      if (this.thresholds.userHandsRaisedSince) {
        if ((Date.now() - this.thresholds.userHandsRaisedSince) / 1000 > 1.5) {
          this.attentionScores.numberOfQuestionsAsked += 1;
        }
        this.thresholds.userHandsRaisedSince = undefined;
      }
    }
    if (this.gestureFound) {
      this.clearEmote();
    }
  }

  handleHandRaisedGesture(isHandRaised: boolean): void {
    if (isHandRaised) {
      if (!this.isHandDetected) {
        this.isHandsRaisedSubject.next(true);
        this.isHandDetected = true;
      }

      if (this.thresholds.userHandsRaisedSince === undefined) {
        this.thresholds.userHandsRaisedSince = Date.now();
      }
    } else {
      if (this.isHandDetected) {
        this.isHandsRaisedSubject.next(false);
        this.isHandDetected = false;
      }
      if (this.thresholds.userHandsRaisedSince) {
        if ((Date.now() - this.thresholds.userHandsRaisedSince) / 1000 > 1.5) {
          this.attentionScores.numberOfQuestionsAsked += 1;
        }
        this.thresholds.userHandsRaisedSince = undefined;
      }
    }
  }

  sendGesture(gesture: GestureName) {
    this.gestureFound = true;
    if (this.thresholds[gesture] === undefined) {
      this.thresholds[gesture] = Date.now();
    } else {
      const timeSince = (Date.now() - this.thresholds[gesture]) / 1000;
      if (timeSince >= this.gestureThreshold) {
        this.emoticonSubject.next(gesture);
        Object.keys(this.thresholds).forEach((gestureType) => {
          if (gestureType !== gesture) {
            this.thresholds[gestureType] = undefined;
          }
        });
        if (gesture === GestureName.HAND_RAISED) {
          this.attentionScores.numberOfQuestionsAsked += 1;
        }
      }
    }
  }

  clearEmote() {
    Object.keys(this.thresholds).forEach((gestureType) => {
      this.thresholds[gestureType] = undefined;
    });
    this.gestureFound = false;
  }

  public async loadMediapipeHandsModel() {
    try {
      this.mediapipeHandsModel = await handPoseDetection.createDetector(
        handPoseDetection.SupportedModels.MediaPipeHands,
        this.handsModelConfig,
      );
      this.gestureWebWorkerEnabled = this.flagsService.isFlagEnabled(FLAGS.GESTURES_WEB_WORKER);
      if (this.gestureWebWorkerEnabled) {
        const remoteGestureWorker = Comlink.wrap<typeof GestureWorker>(
          new Worker(new URL('../hand-gestures/gestures.worker.ts', import.meta.url)),
        );
        this.gesturesWebWorker = await new remoteGestureWorker();
      }
    } catch (e) {
      this.numberOfLoadModelErrors += 1;
      Sentry.captureException(new Error('Mediapipe load model error'), {
        extra: {
          error: e,
          tries: this.numberOfLoadModelErrors,
        },
      });
      await this.reloadMediapipeHandsModel();
    }
  }

  private async reloadMediapipeHandsModel() {
    this.mediapipeHandsModel?.dispose();
    this.mediapipeHandsModel = undefined;
    if (this.numberOfLoadModelErrors <= 3) {
      await new Promise((resolve) => {
        resolve(
          modifiedSetTimeout(async () => {
            await this.loadMediapipeHandsModel;
          }, 2000),
        );
      });
    }
  }

  private async startMediapipeHandsInference() {
    this.handDetectionInterval = modifiedSetInterval(async () => {
      try {
        const hands = await this.mediapipeHandsModel?.estimateHands(
          this.userVideoElement!,
          this.estimationConfig,
        );
        this.onResultsHands(hands);
      } catch (e) {
        this.numberOfInferenceErrors += 1;
        Sentry.captureException(new Error('Mediapipe inference error'), {
          extra: {
            error: e,
            videoReadyState: this.userVideoElement?.readyState,
            numberOfInferenceErrors: this.numberOfInferenceErrors,
          },
        });
        if (this.numberOfInferenceErrors > 2) {
          this.stop();
          await new Promise((resolve) => {
            resolve(
              modifiedSetTimeout(async () => {
                await this.reloadMediapipeHandsModel();
                this.numberOfInferenceErrors = 0;
                this.start();
              }, 2000),
            );
          });
        }
      }
    }, 1000 / this.config.fps);
  }

  public async start(): Promise<void> {
    if (
      !this.userVideoElement ||
      !this.flagsService.isFlagEnabled(FLAGS.ENABLE_SPACE_AI_PRESENCE) ||
      this.isHandsModelRunning ||
      !this.gestureDetectionEnabled
    ) {
      return;
    }

    // Load mediapipe hands model
    if (this.handDetectionEnabled && !this.mediapipeHandsModel) {
      await this.loadMediapipeHandsModel();
    }

    // Wait for video to have sufficient data to avoid inference errors
    if (this.userVideoElement?.readyState < 2) {
      await new Promise<void>((resolve) => {
        this.userVideoElement!.onloadeddata = () => {
          resolve();
        };
      });
    }

    // Start inference
    this.startMediapipeHandsInference();
    this.isHandsModelRunning = true;
    return;
  }

  public stop(): void {
    if (this.handDetectionInterval) {
      clearInterval(this.handDetectionInterval);
      this.isFaceDetectedSubject.next(true);
      this.isHandsRaisedSubject.next(false);
      this.isHandsModelRunning = false;
    }
    this.handDetectionInterval = undefined;
  }

  public setUserVideoElement(userVideoElement: HTMLVideoElement) {
    this.userVideoElement = userVideoElement;
  }

  public unsetFullScreen() {
    if (this.userVideoElement?.id.startsWith('fullscreen-')) {
      const id = this.userVideoElement.id.replace('fullscreen-', '');
      this.userVideoElement = document.getElementById(id) as HTMLVideoElement;
    }
  }

  public setGestureDetectionEnabled(value): void {
    this.gestureDetectionEnabled = value;

    if (!this.gestureDetectionEnabled) {
      this.stop();
    }
  }
}

import { Injectable } from '@angular/core';
import { ModalManagerService } from 'src/app/services/modal-manager.service';
import { WbDialogService } from 'src/app/services/wb-dialog.service';
import { DeviceAndBrowserDetectorService } from 'src/app/services/device-and-browser-detector.service';
import { SpaceRepository } from 'src/app/state/space.repository';
import { SpaceRecordingService } from 'src/app/services/space-recording.service';
import { SCREEN_SHARE_MODE } from 'src/app/services/space-screenshare.service';
import {
  IconBackground,
  IconMessageToasterElement,
} from 'src/app/ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { TranslateService } from '@ngx-translate/core';
import {
  DEFAULT_NOTIFICAION_TIMEOUT,
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from 'src/app/services/notification-toaster.service';
import { WARNINGS } from 'src/app/common/utils/notification-constants';
import { ToasterPopupStyle } from 'src/app/ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { ForceScreenShareBlockingModalComponent } from '../dialogs/force-screen-share-blocking-modal/force-screen-share-blocking-modal.component';

export enum ForceScreenShareAction {
  NONE,
  MORE_OPTIONS,
}

interface ForceScreenshareState {
  callHasActiveScreenTiles: boolean;
  cloudRecordingActive: boolean;
  isUserHost: boolean;
  localScreenShareMode: SCREEN_SHARE_MODE | undefined;
}

@Injectable()
export class ForceScreenShareBlockingModalService {
  private modalShowedBeforeAfterRecording = false;
  private userSharedWhiteboardBefore = false;
  private previousState?: ForceScreenshareState;

  constructor(
    private modalManagerService: ModalManagerService,
    private wbDialogService: WbDialogService,
    private deviceAndBrowserDetectorService: DeviceAndBrowserDetectorService,
    private spaceRepo: SpaceRepository,
    private spaceRecordingService: SpaceRecordingService,
    private translateService: TranslateService,
    private notificationToasterService: NotificationToasterService,
  ) {}

  stateChanged(newState: ForceScreenshareState) {
    if (
      !newState.isUserHost ||
      !newState.cloudRecordingActive ||
      newState.callHasActiveScreenTiles
    ) {
      this.handleClosingModal(newState);
    } else if (this.isModalRequired()) {
      this.modalShowedBeforeAfterRecording = true;
      this.openModal();
    } else if (
      this.modalShowedBeforeAfterRecording &&
      this.userSharedWhiteboardBefore &&
      this.previousState?.localScreenShareMode === SCREEN_SHARE_MODE.OTHER_TAB &&
      !newState.localScreenShareMode
    ) {
      this.openModal(false, true);
    } else if (
      this.previousState?.localScreenShareMode &&
      this.previousState?.localScreenShareMode === SCREEN_SHARE_MODE.CURRENT_TAB &&
      !newState.localScreenShareMode
    ) {
      this.handleScreenShareClosedNotification();
    }

    if (newState.localScreenShareMode === SCREEN_SHARE_MODE.CURRENT_TAB) {
      this.userSharedWhiteboardBefore = true;
    }
    this.previousState = newState;
  }

  private handleClosingModal(newState: ForceScreenshareState) {
    this.closeModal();
    this.notificationToasterService.dismissNotificationsByCode([WARNINGS.STOPPED_RECORDING_WHITEBOARD])
    if (
      this.previousState?.localScreenShareMode &&
      newState.localScreenShareMode &&
      this.previousState.localScreenShareMode !== newState.localScreenShareMode &&
      newState.localScreenShareMode === SCREEN_SHARE_MODE.OTHER_TAB
    ) {
      this.handleChangeScreenShareTabNotification();
    }
    if (!newState.cloudRecordingActive) {
      // to allow the modal to appear again if the user enabled CR again
      this.modalShowedBeforeAfterRecording = false;
    }
  }

  resetStatusOnLeaveCall() {
    this.modalShowedBeforeAfterRecording = false;
  }

  featureEnabled(): boolean {
    return this.deviceAndBrowserDetectorService.isDesktop();
  }

  willModalAppear(): boolean {
    return (
      this.featureEnabled() &&
      this.spaceRepo.isCurrentUserHost() &&
      this.spaceRecordingService.cloudRecordingActive() &&
      this.isModalRequired()
    );
  }

  private handleScreenShareClosedNotification() {
    const titleElement = new IconMessageToasterElement(
      { icon: 'info' },
      this.translateService.instant('Whiteboard recording paused'),
      undefined,
      undefined,
      undefined,
      IconBackground.INFO,
      true,
      true,
    );
    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(
        'You paused whiteboard recording. You can restart whiteboard recording in the recording panel.',
      ),
    );

    const notificationData = new NotificationDataBuilder(WARNINGS.STOPPED_RECORDING_WHITEBOARD)
      .style(ToasterPopupStyle.WARN)
      .type(NotificationType.WARNING)
      .width(304)
      .topElements([titleElement])
      .middleElements([messageElement])
      .version2Notification(true)
      .timeOut(DEFAULT_NOTIFICAION_TIMEOUT)
      .dismissable(true)
      .onActivateTick(true)
      .progressBarColor('#BBD8FF')
      .build();

    this.notificationToasterService.showNotification(notificationData);
  }

  private handleChangeScreenShareTabNotification() {
    const titleElement = new IconMessageToasterElement(
      { icon: 'info' },
      this.translateService.instant('Whiteboard recording paused'),
      undefined,
      undefined,
      undefined,
      IconBackground.INFO,
      true,
      true,
    );
    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(
        'Your screen share will now be recorded instead. You can continue recording your whiteboard when you stop sharing your screen.',
      ),
    );

    const notificationData = new NotificationDataBuilder(WARNINGS.STOPPED_RECORDING_WHITEBOARD)
      .style(ToasterPopupStyle.WARN)
      .type(NotificationType.WARNING)
      .width(304)
      .topElements([titleElement])
      .middleElements([messageElement])
      .version2Notification(true)
      .timeOut(DEFAULT_NOTIFICAION_TIMEOUT)
      .dismissable(true)
      .onActivateTick(true)
      .progressBarColor('#BBD8FF')
      .build();

    this.notificationToasterService.showNotification(notificationData);
  }

  private isModalRequired() {
    return (
      (!this.modalShowedBeforeAfterRecording &&
        this.isCloudRecordingAutomaticOrStartedByCurrentUser()) ||
      this.spaceRecordingService.forceShareScreen
    );
  }

  private isCloudRecordingAutomaticOrStartedByCurrentUser() {
    return (
      this.spaceRecordingService.isAutomaticCloudRecordingEnabled ||
      this.spaceRecordingService.didCurrentUserStartRecording$.getValue()
    );
  }

  private closeModal() {
    this.wbDialogService.getDialogRefOf(ForceScreenShareBlockingModalComponent)?.close();
  }

  private openModal(allowShareAnotherScreen = false, shareWhiteboardAgain = false) {
    if (this.wbDialogService.getDialogRefOf(ForceScreenShareBlockingModalComponent)) {
      // if modal already opened
      return;
    }

    this.modalManagerService.showModal(
      ForceScreenShareBlockingModalComponent,
      {
        disableClose: true,
        panelClass: 'force-screenshare-dialog-container',
        data: {
          allowShareAnotherScreen: allowShareAnotherScreen,
          shareWhiteboardAgain: shareWhiteboardAgain,
        },
      },
      {
        afterClosed: (action: ForceScreenShareAction) => {
          if (action === ForceScreenShareAction.MORE_OPTIONS) {
            this.openModal(true);
          }
        },
      },
    );
  }
}

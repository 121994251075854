<div class="dialog-tag-container">
  <div class="title-container">
    <h5 mat-dialog-title>{{ 'Create new tag' | translate }}</h5>
    <button mat-icon-button class="close-icon" (click)="closeDialog()" aria-label="Close">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="content" mat-dialog-content>
    <div class="row margin-row">
      <div class="form-group col-md-6">
        <mat-form-field appearance="outline" class="full-width adjust-height">
          <mat-label class="name-label">{{ 'Tag type' | translate }}</mat-label>
          <mat-select
            [(value)]="selectedType"
            [placeholder]="'Select a Type' | translate"
            [required]="true"
            [disabled]="enforcingCourse"
            (selectionChange)="handleTypeChange()"
          >
            <mat-select-trigger>
              <div class="type-with-icons" *ngIf="selectedType">
                <span class="material-icons-outlined">
                  {{ selectedType.icon }}
                </span>
                {{ selectedType.name | translate }}
              </div>
            </mat-select-trigger>
            <mat-option *ngFor="let type of tagTypes" [value]="type">
              <div class="type-with-icons">
                <span class="material-icons-outlined">
                  {{ type.icon }}
                </span>
                {{ type.name | translate }}
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row margin-row" style="padding-top: 12px">
      <div class="form-group col-md-6">
        <mat-form-field appearance="outline" class="full-width adjust-height">
          <mat-label class="name-label">{{ 'Tag name' | translate }}</mat-label>
          <input
            matInput
            type="text"
            cdkFocusInitial
            id="tag_name_input"
            (input)="checkTagName()"
            [(ngModel)]="tag_name"
            placeholder="New Tag"
            name="tag"
            class="{{
              !tag_exist || !selectedType?.name.length
                ? 'tag-name-field'
                : 'tag-name-field is-invalid'
            }}"
            id="tag_name_input"
            aria-describedby="tag_Name_feedback"
          />
        </mat-form-field>
        <div
          id="tag_Name_feedback"
          class="invalid-feedback"
          [ngClass]="{ visible: tag_exist && selectedType?.name.length }"
        >
          {{ 'Duplicate tag name' | translate }}
        </div>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field appearance="outline" class="full-width adjust-height">
          <mat-label class="name-label">{{ 'Course name' | translate }}</mat-label>
          <mat-select
            id="course_name_input"
            [(value)]="selectedCourse"
            [disabled]="selectedType?.name !== 'Course' || enforcingCourse"
            [placeholder]="'Select a Course' | translate"
            (selectionChange)="checkTagName()"
          >
            <mat-option *ngFor="let course of data.courses" [value]="course">
              {{ course.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="buttons">
    <button class="cancel-button" mat-raised-button (click)="closeDialog()">
      <span class="material-icons"> clear </span> {{ 'Cancel' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="
        tag_exist ||
        tag_name?.length === 0 ||
        !selectedType?.name.length ||
        (selectedType?.name === TagTypes.COURSE && !selectedCourse?._id.length)
      "
      (click)="createTag()"
    >
      <span class="material-icons"> check </span> {{ 'Create' | translate }}
    </button>
  </div>
</div>

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { versions } from 'src/environments/versions';

import { AuthService } from './services/auth.service';

// eslint-disable-next-line frontend-rules/ngx-translate-service
export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private route: ActivatedRoute) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(InterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }
    return this.authService.getToken().pipe(
      mergeMap((token) => {
        if (
          !token &&
          (req.url.includes('/tutor/questions/') ||
            req.url.includes('/tutor/notes/') ||
            req.url.includes('/tutor/feature_flags') ||
            req.url.includes('/tutor/auth') ||
            req.url.includes('/tutor/code') ||
            req.url.includes('/tutorcruncher/company-login') ||
            req.url.includes('/spaces/public-details'))
        ) {
          req = req.clone({
            setHeaders: {
              'Content-Type': 'application/json; charset=utf-8',
              Accept: 'application/json',
            },
          });
        } else if (token) {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        } else {
          this.authService.fbUser.next(null);
          if (!req.url.includes('assets/i18n')) {
            return EMPTY;
          }
        }

        /* Append the client version to the header for every request */
        if (versions.version) {
          req = req.clone({
            setHeaders: {
              'Release-Version':
                this.route.snapshot.queryParamMap.get('version') ?? versions.version,
            },
          });
        }

        return next.handle(req);
      }),
    );
  }
}

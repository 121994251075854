import { Injectable } from '@angular/core';
import { RealtimeService } from 'src/app/services/realtime.service';
import { EMPTY, Subscription, combineLatest, distinctUntilChanged } from 'rxjs';
import { SpaceRepository } from 'src/app/state/space.repository';
import { SessionSharedDataService } from 'src/app/services/session-shared-data.service';
import {
  AdmissionStatus,
  RealTimeUpdatesDataTypes,
  SessionUser,
  Visibility,
} from 'src/app/models/session';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
import { URL_CONSTANTS } from 'src/app/common/utils/url';
import { LocalStorageItemIDs, LocalStorageService } from 'src/app/services/local-storage.service';
import { UserService } from 'src/app/services/user.service';
import { AclService } from 'src/app/services/acl.service';
import { FLAGS, FlagsService } from '../../../services/flags.service';
import { RequestAccessClientService } from './request-access-client.service';
import { RequestAccessNotificationService } from './request-access-notification.service';
import {
  AccessResponseBody,
  INVALID_SPACE_ID_ERR,
  RequestAccessBaseService,
  SPACE_NOT_FOUND_ERR,
} from './base/request-access-base.service';

const NOT_PRIVATE_SPACE_ERR = 'this space is not a private space';
const HAVE_ACCESS_TO_SPACE_ERR = 'you already have access to this session';
const REQUEST_APPROVED_ERR = 'Your request has been approved already';

@Injectable({
  providedIn: 'root',
})
export class RequestAccessService extends RequestAccessBaseService {
  private reconnectionSub: Subscription = new Subscription();

  constructor(
    protected userService: UserService,
    protected flagsService: FlagsService,
    protected realtimeService: RealtimeService,
    protected spaceRepo: SpaceRepository,
    protected aclService: AclService,
    protected sessionSharedDataService: SessionSharedDataService,
    protected requestAccessClientService: RequestAccessClientService,
    protected requestAccessNotificationService: RequestAccessNotificationService,
    protected router: Router,
    protected localStorageService: LocalStorageService,
  ) {
    super(
      userService,
      flagsService,
      realtimeService,
      spaceRepo,
      aclService,
      sessionSharedDataService,
      requestAccessClientService,
      requestAccessNotificationService,
      router,
    );

    this.flagsService
      .featureFlagChanged(FLAGS.REQUEST_ACCESS_ENABLED)
      .pipe(distinctUntilChanged())
      .subscribe((isRequestAccessEnabled) => {
        // if the flag is enabled this will be set once and won't be unsubscribed unless the flag is off again.
        if (isRequestAccessEnabled) {
          this.setUserChannelSub();
        } else {
          this.userChannelSub.unsubscribe();
        }
      });

    // used to toggle subs whenever the space is changed , the user role or the space visibility changes
    combineLatest([
      this.flagsService.featureFlagChanged(FLAGS.REQUEST_ACCESS_ENABLED),
      this.spaceRepo.isCurrentUserHost$.pipe(distinctUntilChanged()),
      this.spaceRepo.spaceVisibility$,
      this.spaceRepo.activeSpaceId$.pipe(distinctUntilChanged()),
    ]).subscribe(([isRequestAccessEnabled, isHost, visibility, spaceID]) => {
      const isEnteringNewSpace: boolean = !!spaceID && this.spaceID !== spaceID;
      this.spaceID = spaceID ?? '';

      const isSpacePrivate = visibility === Visibility.PRIVATE;
      const shouldListenToAccessRequestsUpdates =
        isRequestAccessEnabled && spaceID && isSpacePrivate;
      const shouldShowAccessRequests = shouldListenToAccessRequestsUpdates && isHost;

      // handles the case when current user had a pending request access then granted access to session as host
      // he will have the space for the first time only with his request in it
      if (isEnteringNewSpace) {
        this.removeCurrentUserRequest();
      }

      if (shouldListenToAccessRequestsUpdates) {
        this.setSessionChannelSub();
        this.setReconnectionSub();
      }

      if (shouldShowAccessRequests) {
        this.setupSpaceForShowingAccessRequests(isEnteringNewSpace);
      }

      if (!shouldListenToAccessRequestsUpdates) {
        this.cleanupSubs(true);
      } else if (!isHost) {
        this.cleanupSubs(false);
      }
    });
  }

  protected handleUserMessage(message: any) {
    if (
      !message ||
      message.action !== 'updateData' ||
      message.dataType !== RealTimeUpdatesDataTypes.USER_ACCESS_RESPONSE
    ) {
      return;
    }

    if (message.dataId !== this.spaceID) {
      this.handleDifferentSpaceTimer(message);
      return;
    }

    this.handleAccessRequestUserUpdates(message);
  }

  private handleDifferentSpaceTimer(message: any) {
    if (!message.data || !message.dataId || message.id !== this.user._id) {
      return;
    }
    const admissionStatus: AdmissionStatus = message.data;
    const otherSpaceID = message.dataId;
    if (
      this.localStorageService.hasItem(
        LocalStorageItemIDs.REQUEST_ACCESS_ENABLED_TIME,
        otherSpaceID,
        message.id,
      )
    ) {
      if (admissionStatus === AdmissionStatus.ADMITTED) {
        this.localStorageService.removeItem(
          LocalStorageItemIDs.REQUEST_ACCESS_ENABLED_TIME,
          otherSpaceID,
          message.id,
        );
      } else if (admissionStatus === AdmissionStatus.DENIED) {
        const instantTime: number = new Date().getTime();
        this.localStorageService.setItem(
          LocalStorageItemIDs.REQUEST_ACCESS_ENABLED_TIME,
          String(instantTime),
          otherSpaceID,
          message.id,
        );
      }
    }
  }

  private removeCurrentUserRequest() {
    const activeSpace = this.spaceRepo.activeSpace;
    if (!activeSpace) {
      return;
    }

    let userWasRequesting = false;
    const accessRequesters = activeSpace.accessRequesters ?? [];
    for (const requester of accessRequesters) {
      if (requester._id === this.user._id) {
        userWasRequesting = true;
        requester.admitted = AdmissionStatus.ADMITTED;
        break;
      }
    }

    if (userWasRequesting) {
      activeSpace.accessRequesters = accessRequesters;
      this.spaceRepo.updateSpace(this.spaceID, activeSpace);
    }
  }

  protected handleSessionMessage(message: any) {
    if (message.action !== 'updateData' || message.id !== this.spaceID || !message.dataType) {
      return;
    }

    this.handleSessionUpdates(message);
  }

  protected handleSessionUpdates(message: any) {
    const activeSpace = this.spaceRepo.activeSpace;

    if (!message.data || !activeSpace || activeSpace?._id !== this.spaceID) {
      return;
    }

    if (message.dataType === RealTimeUpdatesDataTypes.SESSION_ACCESS_REQUEST) {
      this.handleAccessRequestUpdates(message);
    } else if (message.dataType === RealTimeUpdatesDataTypes.SESSION_ACCESS_RESPONSE) {
      this.handleAccessResponseUpdates(message);
    }
  }

  private handleAccessRequestUpdates(message: any) {
    const activeSpace = this.spaceRepo.activeSpace;
    if (!activeSpace) {
      return;
    }

    const accessRrequester = message.data.accessRequester;
    const populatedRequester = message.data.populatedAccessRequester;

    if (!accessRrequester || !populatedRequester) {
      return;
    }

    // emit new value & show notification
    const updatedPopulatedRequestersWithRequest = this._accessRequesters$.value.filter(
      (requester) => requester._id !== populatedRequester._id,
    );
    updatedPopulatedRequestersWithRequest.push(populatedRequester);

    this._accessRequesters$.next(updatedPopulatedRequestersWithRequest);
    this._accessRequestersSize$.next(updatedPopulatedRequestersWithRequest.length);

    if (this._shouldShowAccessRequests$.value) {
      this.requestAccessNotificationService.showNotificationIfThereAreRequests(
        this._accessRequesters$.value,
      );
    }

    // update the space repo
    const updatedRequesters =
      activeSpace?.accessRequesters?.filter((user) => user._id !== accessRrequester._id) ?? [];
    const updatedPopulatedRequesters =
      activeSpace?.populatedAccessRequesters?.filter(
        (user: User) => user._id !== populatedRequester._id,
      ) ?? [];

    updatedRequesters.push(accessRrequester);
    updatedPopulatedRequesters.push(populatedRequester);

    activeSpace.accessRequesters = updatedRequesters;
    activeSpace.populatedAccessRequesters = updatedPopulatedRequesters;
    this.spaceRepo.updateSpace(this.spaceID, activeSpace);
  }

  private handleAccessResponseUpdates(message: any) {
    const requestersIDs: string[] = message.data.requestersIDs;
    const admissionStatus: AdmissionStatus = message.data.admissionStatus;

    if (!requestersIDs || requestersIDs.length === 0 || !admissionStatus) {
      return;
    }

    this.applySessionAccessResponseUpdates(requestersIDs, admissionStatus);
  }

  private applySessionAccessResponseUpdates(
    requestersIDs: string[],
    admissionStatus: AdmissionStatus,
  ) {
    const requestersIDsSet = new Set<string>(requestersIDs);
    // emit the new access requesters & dismiss notifications
    const updatedPopulatedRequestersWithRequest = this._accessRequesters$.value.filter(
      (requester) => !requestersIDsSet.has(requester._id),
    );

    this._accessRequesters$.next(updatedPopulatedRequestersWithRequest);
    this._accessRequestersSize$.next(updatedPopulatedRequestersWithRequest.length);

    this.requestAccessNotificationService.updateExistingNotificationIfThereAreRequests(
      this._accessRequesters$.value,
    );

    const activeSpace = this.spaceRepo.activeSpace;
    if (!activeSpace) {
      return;
    }

    const updatedRequesters = activeSpace?.accessRequesters ?? [];
    for (const requester of updatedRequesters) {
      if (requestersIDsSet.has(requester._id)) {
        requester.admitted = admissionStatus;
      }
    }

    activeSpace.accessRequesters = updatedRequesters;
    this.spaceRepo.updateSpace(this.spaceID, activeSpace);
  }

  private setReconnectionSub() {
    // active space is over firing but since i will run a logic here to determine if i should emit new values
    // or not , listening to active space changes is a must anyways.
    this.reconnectionSub = this.spaceRepo.activeSpace$.subscribe((_) => this.handleReconnection());
  }

  private handleReconnection() {
    let isEmitNeeded = false;
    let isRequestsAdded = false;
    let isRequestsRemoved = false;

    const newPopulatedRequesters = this.getFilteredPopulatedAccessRequesters();
    const newPopulatedRequestersIDsSet = new Set<string>(
      newPopulatedRequesters.map((requester) => requester._id),
    );
    const oldPopulatedRequesters = this._accessRequesters$.value ?? [];
    const oldPopulatedRequestersIDsSet = new Set<string>(
      oldPopulatedRequesters.map((requester) => requester._id),
    );

    // handle added requesters
    newPopulatedRequesters.forEach((requester) => {
      if (!oldPopulatedRequestersIDsSet.has(requester._id)) {
        isEmitNeeded = true;
        isRequestsAdded = true;
      }
    });

    // handle removed requesters
    oldPopulatedRequesters.forEach((requester) => {
      if (!newPopulatedRequestersIDsSet.has(requester._id)) {
        isEmitNeeded = true;
        isRequestsRemoved = true;
      }
    });

    if (isEmitNeeded && this._shouldShowAccessRequests$.value) {
      if (isRequestsAdded) {
        this.requestAccessNotificationService.showNotificationIfThereAreRequests(
          newPopulatedRequesters,
        );
      } else if (isRequestsRemoved) {
        this.requestAccessNotificationService.updateExistingNotificationIfThereAreRequests(
          newPopulatedRequesters,
        );
      }
    }

    if (isEmitNeeded) {
      this._accessRequesters$.next(newPopulatedRequesters);
      this._accessRequestersSize$.next(newPopulatedRequesters.length);
    }
  }

  private getFilteredPopulatedAccessRequesters() {
    const accessRequestersWithRequestStatus =
      this.spaceRepo.activeSpace?.accessRequesters?.filter(
        (requester) => requester.admitted === AdmissionStatus.REQUESTED,
      ) ?? [];

    const requestersIDs = new Set(
      accessRequestersWithRequestStatus.map((requester) => requester._id),
    );

    const populatedAccessRequestersWithRequestStatus =
      this.spaceRepo.activeSpace?.populatedAccessRequesters?.filter((requester) =>
        requestersIDs.has(requester._id),
      ) ?? [];

    return populatedAccessRequestersWithRequestStatus;
  }

  private setupSpaceForShowingAccessRequests(isEnteringNewSpace: boolean) {
    this._shouldShowAccessRequests$.next(true);
    this.setNotificationsSubs();
    this.emitPopulatedRequesters();
    if (isEnteringNewSpace) {
      this.fireNotificationAfterSpaceSynced();
    } else {
      this.requestAccessNotificationService.startShowingNotifications();
    }
  }

  private emitPopulatedRequesters() {
    const populatedAccessRequestersWithRequestStatus = this.getFilteredPopulatedAccessRequesters();

    this._accessRequesters$.next(populatedAccessRequestersWithRequestStatus);
    this._accessRequestersSize$.next(populatedAccessRequestersWithRequestStatus.length);
  }

  protected cleanupSubs(unsubscribeFromAllSubs: boolean) {
    super.cleanupSubs(unsubscribeFromAllSubs);

    if (unsubscribeFromAllSubs) {
      this.reconnectionSub.unsubscribe();
    }
  }

  /* methods called from outside this service */

  // NOTE : in the request access scenario , the active session is not set yet , so this is used to set the space id from
  //        the request access component in this case.

  public setCurrentSpaceID(spaceID: string) {
    this.spaceID = spaceID;
  }

  protected handleRequestAccessErrors(error: any) {
    // behavior from the error interceptor
    if (error?.status === 401) {
      this.requestAccessNotificationService.warning(
        error?.error && error.error.err,
        error.status.toString(),
      );
    }
    // if space changed to public or user got accepted while disconnected
    // redirect to space when tring to request access again
    if (
      error.error?.err === NOT_PRIVATE_SPACE_ERR ||
      error.error?.err === HAVE_ACCESS_TO_SPACE_ERR ||
      error.error?.err === REQUEST_APPROVED_ERR
    ) {
      this.requestAccessNotificationService.warning('You already have access to this space.');
      this.router.navigate([`${URL_CONSTANTS.SPACES}/${this.spaceID}`]);
    } else if (
      error.error?.err?.includes(SPACE_NOT_FOUND_ERR) ||
      error.error?.err?.includes(INVALID_SPACE_ID_ERR)
    ) {
      this.requestAccessNotificationService.warning(
        'The space you were previously on has been deleted.',
      );
      this.router.navigate([`${URL_CONSTANTS.SPACES}`]);
    }

    this._requestAccessSent$.next(false);

    // return a noop so that the subscribe function is not called in case of errors occuring
    // also not throwing an error so that it won't be captured by sentry
    return EMPTY;
  }

  protected accessRespond(requestersIDs: string[], admissionStatus: AdmissionStatus) {
    const body: AccessResponseBody = {
      requestersIDs,
      admissionStatus,
    };
    this.requestAccessClientService.respondToAccessRequest(this.spaceID, body).subscribe((res) => {
      // if the request succeeded , then perform the updates locally not to wait on the realtime update to perform it
      const IDsToBeUpdated: string[] = res.IDsToBeUpdated;
      if (!IDsToBeUpdated || IDsToBeUpdated.length === 0) {
        return;
      }

      this.applySessionAccessResponseUpdates(IDsToBeUpdated, admissionStatus);
    });
  }

  public onRequesterReconnection(userID: string) {
    this.requestAccessClientService.getRequestAccessState(this.spaceID, userID).subscribe((res) => {
      const admissionStatus: AdmissionStatus = res.admissionStatus;
      this.emitResponse(admissionStatus);
    });
  }

  public handleUsersEnteredSpaceWithoutBeingApproved(usersEntered: SessionUser[]) {
    const activeSpace = this.spaceRepo.activeSpace;
    if (!activeSpace) {
      return;
    }
    const requesters = activeSpace?.accessRequesters ?? [];
    const requestersIDsSet = new Set<string>(requesters.map((requester) => requester._id));

    const admittedUsersHavingRequest = usersEntered.filter((user) =>
      requestersIDsSet.has(user._id),
    );
    const admittedUsersHavingRequestIDs = admittedUsersHavingRequest.map((user) => user._id);

    if (!admittedUsersHavingRequest.length) {
      return;
    }

    this.applySessionAccessResponseUpdates(admittedUsersHavingRequestIDs, AdmissionStatus.ADMITTED);
  }
}

<app-first-time-request-devices-permission-guide
  (requestPermission)="requestPermission()"
  (skipRequestPermission)="hideRequestPermissionGuideLayout()"
  *ngIf="currentPrompt === prompts.FIRST_TIME_JOINING"
></app-first-time-request-devices-permission-guide>

<app-steps-guide
  *ngIf="currentPrompt === prompts.HELPER_GUIDE"
  [loading]="guideLoading"
  [guide]="helperGuide"
  (closeModal)="onCloseGuide()"
  (done)="onDoneGuide()"
>
</app-steps-guide>

<div class="container-dialog" [hidden]="currentPrompt !== prompts.SPACE_DEVICE_MODAL">
  <div class="device-modal-panel">
    <div class="header">
      <button
        mat-icon-button
        (click)="closeDialog()"
        class="btn-close-arrow"
        [style.display]="!isMobile ? 'none' : 'flex'"
      >
        <span class="material-icons">arrow_back</span>
      </button>
      <span class="title">{{ 'Adjust video and audio' | translate }}</span>
      <button
        mat-icon-button
        (click)="closeDialog()"
        class="btn-cancel"
        [style.display]="isMobile ? 'none' : 'flex'"
        data-name="video-audio-options-close"
      >
        <span class="material-icons">close</span>
      </button>
    </div>

    <div class="devices-selection-container">
      <div class="col-container" [style.padding]="'16px 28px 16px 16px'">
        <div class="device-container" [style.height]="'100%'">
          <div class="device-header">
            <span class="material-icons device-icon">videocam</span>
            <span class="device-title">{{ 'CAMERA' | translate }}</span>
            <div
              class="device-troubleshoot"
              *ngIf="camState.hasError"
              (click)="troubleshootCam()"
              data-name="video-audio-options-camera-troubleshoot"
            >
              <span class="material-icons">open_in_new</span>
              <span class="device-title-troubleshoot">
                {{
                  camState.errorType === deviceErrorType.PERMISSION_DENIED ||
                  camState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM ||
                  camState.errorType === deviceErrorType.GET_USER_MEDIA_TIMEOUT
                    ? ('Enable permissions' | translate)
                    : ('Troubleshoot' | translate)
                }}
              </span>
            </div>
          </div>
          <div
            class="no-permissions-enabled"
            (click)="troubleshootPermissionDenied(camState.errorType)"
            *ngIf="
              camState.hasError &&
              (camState.errorType === deviceErrorType.PERMISSION_DENIED ||
                camState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM ||
                (camState.errorType === deviceErrorType.NOT_FOUND && devices.cameras.length > 0))
            "
          >
            <span class="material-icons">info</span>
            <span class="no-permissions-enabled-text">
              {{
                camState.errorType === deviceErrorType.PERMISSION_DENIED
                  ? ('You need to enable camera permissions.' | translate)
                  : ('Camera access denied by your OS' | translate)
              }}
            </span>
          </div>
          <div
            class="no-devices"
            *ngIf="
              camState.hasError &&
              camState.errorType === deviceErrorType.NOT_FOUND &&
              devices.cameras.length === 0 &&
              !isLoadingDevices
            "
          >
            <span class="material-icons">info</span>
            <span class="no-devices-text"> {{ 'No camera detected' | translate }} </span>
          </div>
          <mat-select
            [ngClass]="
              camState.hasError &&
              (camState.errorType === deviceErrorType.NO_INPUT_DETECTED ||
                camState.errorType === deviceErrorType.GET_USER_MEDIA_TIMEOUT)
                ? 'mat-select-arrow-orange'
                : 'mat-select-arrow-white'
            "
            [(value)]="selectedCamMatSelect"
            (selectionChange)="setCameraPreview($event.value)"
            panelClass="custom-mat-select-panel"
            *ngIf="
              !camState.hasError ||
              (camState.hasError &&
                (camState.errorType === deviceErrorType.NO_INPUT_DETECTED ||
                  camState.errorType === deviceErrorType.GET_USER_MEDIA_TIMEOUT))
            "
            [disabled]="isLoadingDevices || camState.isGettingStream"
            [disableOptionCentering]="true"
            data-name="video-audio-options-change-camera"
          >
            <mat-select-trigger
              *ngIf="
                camState.hasError &&
                (camState.errorType === deviceErrorType.NO_INPUT_DETECTED ||
                  camState.errorType === deviceErrorType.GET_USER_MEDIA_TIMEOUT)
              "
            >
              <span class="material-icons no-input-detected-icon">info</span>
              <span>
                {{ getDeviceNameById(selectedCamMatSelect, deviceType.CAMS) + NO_INPUT }}
              </span>
            </mat-select-trigger>
            <mat-option [value]="LOADING" disabled selected hidden>
              {{ 'Loading...' | translate }}
            </mat-option>
            <ng-container>
              <mat-option [value]="cam.deviceId" *ngFor="let cam of devices?.cameras">
                {{ cam.label }}
              </mat-option>
            </ng-container>
          </mat-select>
          <div class="device-video-preview-container">
            <span class="camera-starting-text" *ngIf="camState.isGettingStream">{{
              'Camera starting...' | translate
            }}</span>
            <div
              class="error-preview"
              *ngIf="camState.hasError && !camState.isGettingStream && !camState.state"
            >
              <span class="material-icons">warning</span>
              <span class="error-preview-text">{{
                camState.errorType === deviceErrorType.PERMISSION_DENIED ||
                camState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM ||
                camState.errorType === deviceErrorType.GET_USER_MEDIA_TIMEOUT
                  ? ('We could not start your camera.' | translate)
                  : camState.errorType === deviceErrorType.NO_INPUT_DETECTED
                  ? ('No camera input detected' | translate)
                  : ('No camera' | translate)
              }}</span>
            </div>
            <video
              #deviceVideoPreview
              id="deviceVideoPreview"
              [hidden]="(camState.hasError && !camState.state) || camState.isGettingStream"
              muted
              playsinline
              autoplay="autoplay"
              [style.object-fit]="'contain'"
            ></video>
          </div>
        </div>
      </div>
      <div class="divider-vertical"></div>
      <div class="col-container" [style.padding]="'16px 16px 16px 28px'">
        <div class="device-container">
          <div class="device-header">
            <span class="material-icons device-icon">mic</span>
            <span class="device-title">{{ 'MICROPHONE' | translate }}</span>
            <div
              class="device-troubleshoot"
              *ngIf="micState.hasError"
              (click)="troubleshootMic()"
              data-name="video-audio-options-mic-error-troubleshoot"
            >
              <span class="material-icons">open_in_new</span>
              <span class="device-title-troubleshoot">
                {{
                  micState.errorType === deviceErrorType.PERMISSION_DENIED ||
                  micState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM ||
                  micState.errorType === deviceErrorType.GET_USER_MEDIA_TIMEOUT
                    ? ('Enable permissions' | translate)
                    : ('Troubleshoot' | translate)
                }}
              </span>
            </div>
          </div>
          <div
            class="no-permissions-enabled"
            (click)="troubleshootPermissionDenied(micState.errorType)"
            *ngIf="
              micState.hasError &&
              (micState.errorType === deviceErrorType.PERMISSION_DENIED ||
                micState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM ||
                (micState.errorType === deviceErrorType.NOT_FOUND && devices.mics.length > 0))
            "
          >
            <span class="material-icons">info</span>
            <span class="no-permissions-enabled-text">
              {{
                micState.errorType === deviceErrorType.PERMISSION_DENIED
                  ? ('You need to enable microphone permissions.' | translate)
                  : ('Microphone access denied by your OS' | translate)
              }}
            </span>
          </div>
          <div
            class="no-devices"
            *ngIf="
              micState.hasError &&
              micState.errorType === deviceErrorType.NOT_FOUND &&
              devices.mics.length === 0 &&
              !isLoadingDevices
            "
          >
            <span class="material-icons">info</span>
            <span class="no-devices-text"> {{ 'No microphone detected' | translate }} </span>
          </div>
          <mat-select
            [ngClass]="
              micState.hasError &&
              (micState.errorType === deviceErrorType.NO_INPUT_DETECTED ||
                micState.errorType === deviceErrorType.GET_USER_MEDIA_TIMEOUT)
                ? 'mat-select-arrow-orange'
                : 'mat-select-arrow-white'
            "
            [(value)]="selectedMicMatSelect"
            panelClass="custom-mat-select-panel"
            (selectionChange)="setMicPreview($event.value)"
            [disabled]="isLoadingDevices || micState.isGettingStream"
            *ngIf="
              !micState.hasError ||
              (micState.hasError &&
                (micState.errorType === deviceErrorType.NO_INPUT_DETECTED ||
                  micState.errorType === deviceErrorType.GET_USER_MEDIA_TIMEOUT))
            "
            [disableOptionCentering]="true"
            data-name="video-audio-options-change-mic"
          >
            <mat-select-trigger
              *ngIf="
                micState.hasError &&
                (micState.errorType === deviceErrorType.NO_INPUT_DETECTED ||
                  micState.errorType === deviceErrorType.GET_USER_MEDIA_TIMEOUT)
              "
            >
              <span class="material-icons no-input-detected-icon">info</span>
              <span>
                {{ getDeviceNameById(selectedMicMatSelect, deviceType.MICS) + NO_INPUT }}
              </span>
            </mat-select-trigger>
            <mat-option [value]="LOADING" disabled selected hidden>
              {{ 'Loading...' | translate }}
            </mat-option>
            <ng-container>
              <mat-option [value]="mic.deviceId" *ngFor="let mic of devices?.mics">
                {{ mic.label }}
              </mat-option>
            </ng-container>
          </mat-select>
          <div
            class="device-status"
            *ngIf="
              !micState.hasError ||
              (micState.hasError && micState.errorType === deviceErrorType.NO_INPUT_DETECTED)
            "
          >
            <span
              class="status"
              [ngClass]="micState.state && !micState.isGettingStream ? 'audio-detected' : ''"
              >{{
                micState.isGettingStream
                  ? ('Detecting sound...' | translate)
                  : micState.state
                  ? ('Sound detected!' | translate)
                  : ('No sound detected' | translate)
              }}</span
            >
            <span
              class="troubleshoot"
              *ngIf="micState.errorType === deviceErrorType.NO_INPUT_DETECTED"
              (click)="troubleshootMic()"
              data-name="video-audio-options-no-mic-troubleshoot"
              >{{ 'Troubleshoot?' | translate }}</span
            >
          </div>
        </div>
        <div class="device-container" [style.margin-top]="isMobile ? '16px' : '36px'">
          <div class="device-header">
            <span class="material-icons device-icon">volume_up</span>
            <span class="device-title">{{ 'SPEAKERS' | translate }}</span>
            <div
              class="device-troubleshoot"
              *ngIf="
                micState.hasError &&
                (micState.errorType === deviceErrorType.PERMISSION_DENIED ||
                  micState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM)
              "
              (click)="troubleshootMic()"
              data-name="video-audio-options-speakers-troubleshoot"
            >
              <span class="material-icons">open_in_new</span>
              <span class="device-title-troubleshoot">
                {{
                  micState.errorType === deviceErrorType.PERMISSION_DENIED ||
                  micState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM ||
                  micState.errorType === deviceErrorType.GET_USER_MEDIA_TIMEOUT
                    ? ('Enable permissions' | translate)
                    : ('Troubleshoot' | translate)
                }}
              </span>
            </div>
          </div>
          <div
            class="no-permissions-enabled"
            (click)="troubleshootPermissionDenied(micState.errorType)"
            *ngIf="
              micState.hasError &&
              (micState.errorType === deviceErrorType.PERMISSION_DENIED ||
                micState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM ||
                (micState.errorType === deviceErrorType.NOT_FOUND && devices.mics.length > 0))
            "
          >
            <span class="material-icons">info</span>
            <span class="no-permissions-enabled-text">
              {{
                micState.errorType === deviceErrorType.PERMISSION_DENIED
                  ? ('Enable microphone permissions to adjust speaker settings.' | translate)
                  : ('Microphone access denied by your OS' | translate)
              }}
            </span>
          </div>
          <!-- For now MediaDevices don't guarantee if it doesn’t return any speaker, this means the user doesn’t have a speaker.
            So, I kept this div till we have an exact way to be sure 100% the user doesn’t have a speaker at all. -->
          <div class="no-devices" *ngIf="false">
            <span class="material-icons">info</span>
            <span class="no-devices-text"> {{ 'No speaker detected' | translate }} </span>
          </div>
          <mat-select
            class="mat-select-arrow-white"
            [(value)]="selectedSpeakerMatSelect"
            panelClass="custom-mat-select-panel"
            (selectionChange)="setSpeakerPreview($event.value)"
            [disabled]="isLoadingDevices || micState.isGettingStream"
            *ngIf="
              !micState.hasError ||
              !(
                micState.errorType === deviceErrorType.PERMISSION_DENIED ||
                micState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM
              )
            "
            [disableOptionCentering]="true"
            data-name="video-audio-options-change-speakers"
          >
            <mat-option [value]="LOADING" disabled selected hidden>
              {{ 'Loading...' | translate }}
            </mat-option>
            <ng-container>
              <mat-option [value]="'default'" *ngIf="devices?.speakers.length === 0">
                System Default
              </mat-option>
              <mat-option [value]="speaker.deviceId" *ngFor="let speaker of devices?.speakers">
                {{ speaker.label }}
              </mat-option>
            </ng-container>
          </mat-select>
          <div
            class="device-status"
            [style.cursor]="'pointer'"
            (click)="playTestSound()"
            *ngIf="
              !micState.hasError ||
              !(
                micState.errorType === deviceErrorType.PERMISSION_DENIED ||
                micState.errorType === deviceErrorType.PERMISSION_DENIED_BY_SYSTEM
              )
            "
            data-name="video-audio-options-test-speakers"
          >
            <span class="status" [style.color]="isPlayingSpeakerTest ? '#40CA45' : '#BBD8FF'">{{
              isPlayingSpeakerTest
                ? ('Playing sound...' | translate)
                : ('Test Speakers' | translate)
            }}</span>
          </div>
        </div>

        <section class="advanced">
          <div class="device-title device-header">{{ 'ADVANCED' | translate }}</div>
          <div class="disable-videos-checkbox">
            <input
              type="checkbox"
              id="disableVideos"
              [(ngModel)]="disableVideos"
              (ngModelChange)="onChangeDisableVideoStreams()"
              data-name="video-audio-options-advanced-improve-preformance"
            />
            <span>
              {{ 'Turn off incoming video to improve performance' | translate }}
            </span>
          </div>
          <div
            class="disable-videos-submsg"
            [style.color]="disabledThroughNotification && '#F2994A'"
          >
            {{ disableVideoStreamsSubMsg }}
          </div>
          <div class="disable-videos-checkbox" *ngIf="isNoiseCancellationSupported">
            <input
              type="checkbox"
              id="enableNoiseCancellation"
              [(ngModel)]="enableNoiseCancellation"
            />
            <span>
              {{ 'Enable advanced noise cancellation' | translate }}
            </span>
          </div>
        </section>
      </div>
    </div>

    <div class="divider-horizontal"></div>

    <div class="device-modal-buttons">
      <button class="support-button" id="intercomHelpLink" data-name="video-audio-options-support">
        <span class="material-icons-outlined mat-icon-support">
          {{ 'help_outline' }}
        </span>
        <span class="support-text" [style.font-size]="isMobile ? '60%' : ''">{{
          'Live support' | translate
        }}</span>
      </button>
      <button
        class="support-button"
        [style.margin-left]="!isMobile ? '8px' : ''"
        (click)="troubleshoot()"
        data-name="video-audio-options-troubleshoot"
      >
        <span class="material-icons-outlined mat-icon-support">
          {{ 'open_in_new' }}
        </span>
        <span class="support-text" [style.font-size]="isMobile ? '60%' : ''">{{
          'Troubleshooting' | translate
        }}</span>
      </button>
      <button
        class="next-button"
        (click)="done()"
        [disabled]="isLoadingDevices || isSettingDevices"
        data-name="video-audio-options-done"
      >
        <span
          class="material-icons-outlined mat-icon-support fa fa-spinner fa-spin"
          *ngIf="isSettingDevices"
        >
        </span>
        <span class="material-icons-outlined mat-icon-support" *ngIf="!isSettingDevices">
          {{ 'check' }}
        </span>
        <span class="support-text">{{ 'Done' | translate }}</span>
      </button>
    </div>
  </div>
</div>

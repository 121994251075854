import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user';
import { shouldShowBillingStatusIndicator } from '../utilities/payment.utils';

@Pipe({
  name: 'showBillingStatusIndicator',
})
export class ShowBillingStatusIndicatorPipe implements PipeTransform {
  transform(user: User | undefined): boolean {
    return !!user && shouldShowBillingStatusIndicator(user);
  }
}

import { TranslateService } from '@ngx-translate/core';
import { DeviceAndBrowserDetectorService } from 'src/app/services/device-and-browser-detector.service';
import { DeviceErrorsNotificationsService } from 'src/app/services/device-errors-notifications.service';
import { DeviceErrorType, DeviceState } from 'src/app/models/device-manger';
import { DeviceType } from '../devices-handle-util';
import {
  DeviceErrorHelperModelBase,
  DeviceErrorHelperStep,
} from './device-error-helper-model-base';

export class NoInputHelper extends DeviceErrorHelperModelBase {
  private _steps: DeviceErrorHelperStep[] = [];

  constructor(
    deviceDetector: DeviceAndBrowserDetectorService,
    translateService: TranslateService,
    private deviceErrorsNotificationsService: DeviceErrorsNotificationsService,
    deviceType: DeviceType,
  ) {
    super(translateService, deviceDetector, deviceType);
  }

  getSteps(): DeviceErrorHelperStep[] {
    this._steps = [];
    if (this.deviceDetector.isDesktop()) {
      this.desktopSteps();
    } else {
      this.mobileSteps();
    }

    return this._steps;
  }

  getMobileTitle(): string {
    return this.translateService.instant('Fix “No Input” from Camera / Mic');
  }

  getDesktopTitle(): string {
    return this.translateService.instant(
      'Fix “No input” errors from your camera and/or microphone',
    );
  }

  dismissErrorNotification() {
    this.deviceErrorsNotificationsService.dismissNoInputDetectedErrorNotification();
  }

  hasRelatedError(cam: DeviceState, mic: DeviceState) {
    return (
      this.deviceErrorsNotificationsService.hasErrorOfType(
        cam,
        DeviceErrorType.NO_INPUT_DETECTED,
      ) ||
      this.deviceErrorsNotificationsService.hasErrorOfType(mic, DeviceErrorType.NO_INPUT_DETECTED)
    );
  }

  showErrorNotification(cam: DeviceState, mic: DeviceState) {
    this.deviceErrorsNotificationsService.showNoInputDetectedErrorNotification(cam, mic);
  }

  desktopSteps() {
    this._steps = [
      {
        showStepLocation: false,
        stepText: `\
${this.translateService.instant('Refresh your browser and try to join or\n')}\
${this.translateService.instant('start your call again\n')}`,
        stepImgSrc: 'noInputDetectedSteps/desktop/step-1.png',
        stepWidth: 256,
      },
      {
        showStepLocation: false,
        stepText: `\
${this.translateService.instant('If you still see errors, close any intensive\n')}\
${this.translateService.instant('apps or any apps using your camera or mic')}`,
        stepImgSrc: 'noInputDetectedSteps/desktop/step-2.png',
        stepWidth: 256,
      },
      {
        showStepLocation: false,
        stepText: `\
${this.translateService.instant('If issues persist, ensure your browser is\n')}\
${this.translateService.instant('up-to-date and then restart your device')}`,
        stepImgSrc: 'noInputDetectedSteps/desktop/step-3.png',
        stepWidth: 256,
      },
    ];
  }

  mobileSteps() {
    this._steps = [
      {
        showStepLocation: false,
        stepText: `\
${this.translateService.instant('Refresh your browser and try to join or start\n')}\
${this.translateService.instant('your call again\n')}`,
        stepImgSrc: 'noInputDetectedSteps/mobile/step-1.png',
        stepWidth: 295,
      },
      {
        showStepLocation: false,
        stepText: `\
${this.translateService.instant('If you still see errors, close any intensive\n')}\
${this.translateService.instant('apps or any apps using your camera or mic')}`,
        stepImgSrc: 'noInputDetectedSteps/mobile/step-2.png',
        stepWidth: 295,
      },
      {
        showStepLocation: false,
        stepText: `\
${this.translateService.instant('If issues persist, ensure your browser is up-\n')}\
${this.translateService.instant('to-date and then restart your device')}`,
        stepImgSrc: 'noInputDetectedSteps/mobile/step-3.png',
        stepWidth: 295,
      },
    ];
  }
}

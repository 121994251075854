import * as moment from 'moment';
import * as RecordRTC from 'recordrtc';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { FLAGS, FlagsService } from 'src/app/services/flags.service';
import { IconMessageToasterElement } from 'src/app/ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import {
  ButtonToasterElement,
  ButtonToasterElementStyle,
} from 'src/app/ui/notification-toaster/button-toaster-element/button-toaster-element.component';
import { ERRORS } from 'src/app/common/utils/notification-constants';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from 'src/app/services/notification-toaster.service';
import { ToasterPopupStyle } from 'src/app/ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { TranslateService } from '@ngx-translate/core';
import { DevicesManagerService } from 'src/app/services/devices-manager.service';
import {
  trackSubject,
  VideoCallTracksStateService,
} from 'src/app/services/video-call-tracks-state.service';
import { isNil } from 'lodash';
import { TrackType } from 'src/app/common/interfaces/rtc-interface';
import { DevicesHandleUtil } from 'src/app/common/utils/devices-handle-util';
import { modifiedSetTimeout } from 'src/app/utilities/ZoneUtils';
import {
  SessionCallTracksService,
  UserTracksState,
} from '../../../services/session-call-tracks.service';
import { intercomArticles } from '../../../intercom-articles';
import { RECORD_STATUS } from '../../../models/recording';

declare global {
  interface MediaDevices {
    getDisplayMedia(constraints?: MediaStreamConstraints): Promise<MediaStream>;
  }
}

type supportedMimeType = 'video/webm' | 'video/mp4';

const recordOptions: RecordRTC.Options = {
  type: 'video',
  mimeType: currentSupportedMimeType() as supportedMimeType, // or video/webm\;codecs=h264 or video/webm\;codecs=vp9
  audioBitsPerSecond: 128000,
  videoBitsPerSecond: 1280000,
  bitsPerSecond: 1280000, // if this line is provided, skip above two
  // Doing this explicitly right now, since while the recordrtc library has been patched to increase
  // default recording time to 24hrs from 1hr, it hasn't been released yet.
  timeSlice: 24 * 60 * 60 * 1000,
};

const mediaConstraints: MediaStreamConstraints = {
  video: true,
};

function currentSupportedMimeType(): string {
  // Local recording is broken on Safari as webm isn't supported
  // If browser doesn't support webm, we will use mp4 instead
  if (!MediaRecorder.isTypeSupported('video/webm')) {
    return 'video/mp4';
  }
  return 'video/webm';
}

// Class that automatically calls callbacks when a set of keys has changed
class KeyManager<T> {
  constructor(private createFunc: (key: string) => T, private destroyFunc: (key: string) => void) {}

  private keySet: Set<string> = new Set();

  public updateKeys(keys: string[]): T[] {
    const newKeys = new Set(keys);

    /*
     * oldKeys: [a,b,c]
     * newKeys: [a,b]
     * oldKeys - newKeys = [c]
     */
    const removedKeys = this.setDifference(this.keySet, newKeys);

    /*
     * oldKeys: [a,b]
     * newKeys: [a,b,c]
     * newKeys - oldKeys = [c]
     */
    const addedKeys = this.setDifference(newKeys, this.keySet);

    removedKeys.forEach((key) => {
      // Call the destroyFunc when a key is removed from the set
      this.destroyFunc(key);
    });

    const ret: T[] = [];
    // Call the createFunc when a key is added to the set,
    // store the result so it can be returned
    addedKeys.forEach((key) => {
      ret.push(this.createFunc(key));
    });

    // Update the set of keys
    this.keySet = new Set(keys);
    return ret;
  }

  // adapted from: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
  private setDifference(a: Set<string>, b: Set<string>) {
    const _difference = new Set(a);
    for (const elem of b) {
      _difference.delete(elem);
    }
    return _difference;
  }
}

// Handles getting the trackStates for each participant
// This is non trivial because the states are held in observables
// this means that we need to keep track of which participants are in the call
// and subscribe / unsubscribe when they are added / removed
class TrackHandler {
  private keyManager = new KeyManager(
    this.subscribeUserTrack.bind(this),
    this.unsubscribeUserTrack.bind(this),
  );

  private keySubscription?: Subscription;
  private trackSubscriptions: { [key: string]: Subscription | undefined } = {};
  private trackStates: { [key: string]: UserTracksState | null } = {};
  private trackStates_v2: { [key: string]: trackSubject } = {};
  public trackStatesObservable: BehaviorSubject<{ [key: string]: UserTracksState | null }> =
    new BehaviorSubject<{ [key: string]: UserTracksState | null }>({});
  public trackStatesObservable_v2: BehaviorSubject<{ [key: string]: trackSubject }> =
    new BehaviorSubject<{ [key: string]: trackSubject }>({});

  constructor(
    private tracksService: SessionCallTracksService,
    private videoCallTracksStateService: VideoCallTracksStateService,
    private devicesManagerService: DevicesManagerService,
    private isCallTracksV2Enabled: boolean,
  ) {}

  public subscribeToTracks(): void {
    const managerKeys = this.isCallTracksV2Enabled
      ? this.videoCallTracksStateService.participantTracksStateMap.managerKeys
      : this.tracksService.userTracksStates.managerKeys;

    this.keySubscription = managerKeys.subscribe((participantIds) => {
      if (!participantIds) {
        return;
      }
      this.keyManager.updateKeys(participantIds);
    });
  }

  public unsubscribeFromTracks(): void {
    Object.values(this.trackSubscriptions).forEach((x) => x?.unsubscribe());
    this.keySubscription?.unsubscribe();
  }

  private subscribeUserTrack(participantId: string) {
    if (this.isCallTracksV2Enabled) {
      this.trackSubscriptions[participantId] = this.videoCallTracksStateService
        .getPariticpantTrackObservable(
          participantId,
          TrackType.VIDEO,
          this.devicesManagerService,
          true,
        )
        .subscribe((audioTrack) => {
          this.trackStates_v2[participantId] = audioTrack;
          this.trackStatesObservable_v2.next(this.trackStates_v2);
        });
    } else {
      this.trackSubscriptions[participantId] = this.tracksService.userTracksStates
        .get(participantId)
        .subscribe((trackState) => {
          this.trackStates[participantId] = trackState;
          this.trackStatesObservable.next(this.trackStates);
        });
    }
  }
  private unsubscribeUserTrack(participantId: string) {
    this.trackSubscriptions[participantId]?.unsubscribe();
    if (this.isCallTracksV2Enabled) {
      this.trackStates_v2[participantId] = null;
      this.trackStatesObservable_v2.next(this.trackStates_v2);
    } else {
      this.trackStates[participantId] = null;
      this.trackStatesObservable.next(this.trackStates);
    }
  }
}

export class Recorder {
  private trackHandler: TrackHandler;

  // Holds the status of the recorder
  // READY: the recorder is ready to record
  // ACTIVE: the recorder is currently recording
  private _recordingStatus = new BehaviorSubject(RECORD_STATUS.READY);

  public recording$ = this._recordingStatus.asObservable();

  public set recording(recordingStatus: RECORD_STATUS) {
    this._recordingStatus.next(recordingStatus);
  }
  public get recording(): RECORD_STATUS {
    return this._recordingStatus.getValue();
  }

  public RECORD_STATUS: typeof RECORD_STATUS = RECORD_STATUS;

  // This is the underlying recorder that records a MediaStream that is provided
  private recordRTC?: RecordRTC;

  // The screen capture mediaStream
  private captureStream?: MediaStream;

  // The stream that contains the desktop audio/video as well as the daily.co tracks
  private combinedStream: MediaStream = new MediaStream();

  private desktopAudioStreamSubject: BehaviorSubject<MediaStreamTrack | null> =
    new BehaviorSubject<MediaStreamTrack | null>(null);

  private trackStateSubscription?: Subscription;
  private desktopAudioStreamSubscription?: Subscription;

  currentAddedAudioTracksIDs: string[] = [];

  isCallTracksV2Enabled = this.flagsService.isFlagEnabled(FLAGS.CALL_TRACKS_V2);

  public output$: Subject<File> = new Subject();

  constructor(
    public tracksService: SessionCallTracksService,
    private title: string,
    private flagsService: FlagsService,
    private translateService: TranslateService,
    private notificationToasterService: NotificationToasterService,
    private devicesManagerService: DevicesManagerService,
    public videoCallTracksStateService: VideoCallTracksStateService,
  ) {
    this.trackHandler = new TrackHandler(
      tracksService,
      videoCallTracksStateService,
      devicesManagerService,
      this.isCallTracksV2Enabled,
    );
  }

  private async addVideoStreams(): Promise<void> {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia(mediaConstraints);
      // Note: if the user is also sharing their audio then we technically duplicate the
      //		 audio that is being recorded. From my testing this seems to work okay and
      //       they are in sync, but this is not definitively true
      stream.getTracks().forEach((track) => {
        if (track.kind === 'video') {
          this.combinedStream.addTrack(track);
        } else {
          this.desktopAudioStreamSubject.next(track);
        }
      });
      this.captureStream = stream;
      this.addStreamStopListener(stream);
    } catch (err) {
      this.captureStartScreenRecordingError(err.toString());
      throw new Error(err);
    }
  }

  private captureStartScreenRecordingError(error: string) {
    // 1st check: To capture the permission is disabled at OS level on Firefox only, and
    // to capture the permission is disabled at OS level on Chrome, Edge and at browser level on Safari
    // 2nd check: To capture all un-discovered errors, and keeping not showing any error notification once canceling the share screen popup on Chrome, and Edge
    if (
      this.devicesManagerService.isNotFoundError(error) ||
      (this.devicesManagerService.isNotAllowedError(error) &&
        (DevicesHandleUtil.isDeniedBySystem(error) ||
          this.devicesManagerService.isNotAllowedByUserAgent(error)))
    ) {
      this.showGrantScreenRecordingAccessNotification();
      this.devicesManagerService.logStartScreenErrorsToFullStory(
        'Screen recording: Permission Denied',
      );
    } else if (
      !(
        this.devicesManagerService.isNotAllowedError(error) &&
        !(
          DevicesHandleUtil.isDeniedBySystem(error) &&
          this.devicesManagerService.isNotAllowedByUserAgent(error)
        )
      )
    ) {
      this.showScreenRecordingFailedNotification();
      this.devicesManagerService.logStartScreenErrorsToFullStory(
        'Screen recording: Failed to start',
      );
    }
  }

  private showGrantScreenRecordingAccessNotification() {
    const title = new IconMessageToasterElement(
      { icon: 'screen_share', size: 16 },
      this.translateService.instant('Screen recording access denied'),
    );
    const message = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(
        'Please enable screen recording permissions for your browser in your computer’s System Preferences.',
      ),
    );
    const screenShareHelpButton = new ButtonToasterElement(
      [{ icon: 'help_outline', size: 16 }, this.translateService.instant('Need help?')],
      {
        handler: () => {
          window.open(intercomArticles.HowToEnableScreenSharingPermissions);
        },
        close: false,
      },
      ButtonToasterElementStyle.RAISED,
    );
    const grantShareScreenNotificationData = new NotificationDataBuilder(
      ERRORS.GRANT_SCREENSHARE_ACCESS,
    )
      .type(NotificationType.ERROR)
      .style(ToasterPopupStyle.ERROR)
      .topElements([title])
      .middleElements([message])
      .bottomElements([screenShareHelpButton])
      .priority(980)
      .dismissable(true)
      .build();
    this.notificationToasterService.showNotification(grantShareScreenNotificationData);
  }

  private showScreenRecordingFailedNotification() {
    const title = new IconMessageToasterElement(
      { icon: 'screen_share', size: 16 },
      this.translateService.instant('Screen recording failed'),
    );
    const message = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(
        'Something went wrong while trying to start the screen recording. Please try again. If you continue to run into issues, please restart your browser.',
      ),
    );
    const screenShareHelpButton = new ButtonToasterElement(
      [{ icon: 'help_outline', size: 16 }, this.translateService.instant('Need help?')],
      {
        handler: () => {
          window.open(intercomArticles.WhatToDoWhenYourScreenShareFails);
        },
        close: false,
      },
      ButtonToasterElementStyle.RAISED,
    );
    const grantShareScreenNotificationData = new NotificationDataBuilder(
      ERRORS.GRANT_SCREENSHARE_ACCESS,
    )
      .type(NotificationType.ERROR)
      .style(ToasterPopupStyle.ERROR)
      .topElements([title])
      .middleElements([message])
      .bottomElements([screenShareHelpButton])
      .priority(980)
      .dismissable(true)
      .build();
    this.notificationToasterService.showNotification(grantShareScreenNotificationData);
  }

  // If the stream from the capture ends this implies the user clicked
  // on the stop sharing popup. In this case stop recording
  private addStreamStopListener(stream: MediaStream): void {
    stream.addEventListener('ended', () => this.stopRecording());
    stream.addEventListener('inactive', () => this.stopRecording());
    stream.getTracks().forEach((track) => {
      track.addEventListener('ended', () => this.stopRecording());
      track.addEventListener('inactive', () => this.stopRecording());
    });
  }

  // For some reason the recorder does not record until there is a track in the combined
  // media stream destination. This is a dummy track to trick the recorder into starting
  private dummyAudioTrack() {
    const ctx = new AudioContext();
    const oscillator = ctx.createOscillator();
    const dst = ctx.createMediaStreamDestination();
    oscillator.connect(dst);
    return dst.stream.getTracks()[0];
  }

  // Adds the audio streams to the combined stream
  // Uses a different audio context to avoid edge cases
  // where the recorder fails when dynamically adding tracks
  // see: https://github.com/muaz-khan/RecordRTC/issues/509
  public addAudioStreams(): void {
    const audioContext = new AudioContext();
    const destination = audioContext.createMediaStreamDestination();

    this.desktopAudioStreamSubscription?.unsubscribe();
    this.desktopAudioStreamSubscription = this.desktopAudioStreamSubject.subscribe(
      (mediaStreamTrack) => {
        if (!mediaStreamTrack) {
          return;
        }
        const tempMediaStream = new MediaStream();
        tempMediaStream.addTrack(mediaStreamTrack);
        audioContext.createMediaStreamSource(tempMediaStream).connect(destination);
      },
    );

    this.trackStateSubscription?.unsubscribe();
    if (this.isCallTracksV2Enabled) {
      this.trackStateSubscription = this.trackHandler.trackStatesObservable_v2.subscribe(
        (trackStates) => {
          const audioTracks = Object.values(trackStates).filter((x) => !isNil(x));
          if (audioTracks) {
            this.handleRemoteAudioTracks(audioTracks, audioContext, destination);
          }
        },
      );
    } else {
      this.trackStateSubscription = this.trackHandler.trackStatesObservable.subscribe(
        (trackStates) => {
          const audioTracks = Object.values(trackStates)
            .filter((x) => x?.audio.track)
            .map((x) => x?.audio.track);
          if (audioTracks) {
            this.handleRemoteAudioTracks(audioTracks, audioContext, destination);
          }
        },
      );
    }

    // The recorder will not run until a track is in the destination node
    // So we add a dummy track with no sound to get the recorder to start
    const temp = new MediaStream();
    temp.addTrack(this.dummyAudioTrack());
    audioContext.createMediaStreamSource(temp).connect(destination);

    // This will produce a single track that contains the audio for all other
    // tracks. This avoids the issue of dynamically adding tracks while the
    // recorder is running
    const tracks = destination.stream.getTracks();
    tracks.forEach((track) => this.combinedStream.addTrack(track));
  }

  private handleRemoteAudioTracks(
    audioTracks: MediaStreamTrack[] | trackSubject[],
    audioContext: AudioContext,
    destination: MediaStreamAudioDestinationNode,
  ) {
    for (const audioTrack of audioTracks) {
      if (!audioTrack?.id) {
        continue;
      }

      // Add the new track
      if (!this.currentAddedAudioTracksIDs.includes(audioTrack.id)) {
        // Keeping the id for the original audio track
        this.currentAddedAudioTracksIDs.push(audioTrack.id);
        const tempMediaStream = new MediaStream();
        tempMediaStream.addTrack(audioTrack);
        audioContext.createMediaStreamSource(tempMediaStream).connect(destination);

        // When the track ends remove it from the stream
        audioTrack.addEventListener(
          'ended',
          () => {
            this.currentAddedAudioTracksIDs.filter((id) => id !== audioTrack.id);
            this.combinedStream.removeTrack(audioTrack);
          },
          { once: true },
        );
      }
    }
  }

  private async setupCombinedStream() {
    this.combinedStream = new MediaStream();
    await this.addVideoStreams();
    this.addAudioStreams();
  }

  public async startRecording(): Promise<boolean> {
    try {
      this.trackHandler.subscribeToTracks();
      await this.setupCombinedStream();
      const recordRTC = new RecordRTC(this.combinedStream, recordOptions);
      this.recordRTC = recordRTC;
      this.recording = RECORD_STATUS.ACTIVE;
      // Implement Soln from : https://github.com/muaz-khan/RecordRTC/issues/738
      return new Promise((resolve) => {
        modifiedSetTimeout(() => {
          recordRTC.startRecording();
          resolve(true);
        }, 2000);
      });
    } catch (err) {
      console.log(err);
      this.stopRecording();
      return false;
    }
  }

  public pauseRecording(): void {
    try {
      this.recordRTC?.pauseRecording();
      this.recording = RECORD_STATUS.PAUSED;
    } catch (err) {
      console.log(err);
      this.stopRecording();
    }
  }

  public resumeRecording(): void {
    try {
      this.recordRTC?.resumeRecording();
      this.recording = RECORD_STATUS.ACTIVE;
    } catch (err) {
      console.log(err);
      this.stopRecording();
    }
  }

  public async stopRecording(): Promise<void> {
    this.trackHandler.unsubscribeFromTracks();
    this.trackStateSubscription?.unsubscribe();
    this.currentAddedAudioTracksIDs = [];

    const blobPromise: Promise<Blob | undefined> = new Promise((resolve) => {
      this.recordRTC?.stopRecording(async () => {
        const blob = await this.recordRTC?.getBlob();
        resolve(blob);
      });
    });

    const blob = await blobPromise;
    if (!blob) {
      throw new Error('There is no blob for the recording');
    }

    const date = moment(new Date());
    const file = new File([blob], `${this.title}-${date.format('DD-MM-YYYY')}`, {
      type: currentSupportedMimeType(),
    });
    this.combinedStream = new MediaStream();
    this.captureStream?.getTracks().forEach((track) => track.stop());

    // This is here to make stopRecording idempotent, so if its called
    // multiple times the state remains the same
    if (this.recording !== RECORD_STATUS.READY) {
      this.recording = RECORD_STATUS.FINISHED;
      this.recording = RECORD_STATUS.READY;
      this.output$.next(file);
    }
  }
}

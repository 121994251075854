import { Component, ElementRef, Input, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SUBSCRIPTION_TYPES } from 'src/app/models/payment';
import { panelID } from 'src/app/settings/settings.constants';
import { User, roleValueIDs } from 'src/app/models/user';
import * as moment from 'moment';
import { shouldShowBillingStatusIndicator } from 'src/app/utilities/payment.utils';
import { IconTypes } from 'src/app/standalones/components/pencil-icon/pencil-icon.component';
import { SettingsService } from '../../services/settings.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { SidebarLogoType, Theme } from '../themes/themes';
@UntilDestroy()
@Component({
  selector: 'app-full-logo',
  templateUrl: './full-logo.component.html',
  styleUrls: ['./full-logo.component.scss'],
})
export class FullLogoComponent implements AfterViewInit, OnInit {
  @Input() theme?: Theme;
  @Input() logoText = '';

  @ViewChild('logoTextElem') logoTextElem?: ElementRef;
  sidebarLogoType = SidebarLogoType;
  isNameClipped = false;
  isRTL = false;
  isStudentGuest = false;
  billingStatus = '';
  showUpgradeButton = false;
  user?: User;
  remainingTrialDays = 0;
  SubscriptionType = SUBSCRIPTION_TYPES;
  subscriptionType;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public settingsService: SettingsService,
  ) {
    this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res && res.user) {
        this.user = res.user;
        this.subscriptionType = res.user.subscriptionType;

        // Only show student indicator if user is a student and not part of an institution
        this.isStudentGuest =
          this.user.info?.role === roleValueIDs.student && !this.user.institution;
        this.billingStatus = this.isStudentGuest
          ? 'STUDENT'
          : this.getBillingStatusText(this.subscriptionType);

        this.showUpgradeButton =
          shouldShowBillingStatusIndicator(res.user) &&
          (this.subscriptionType === SUBSCRIPTION_TYPES.FREE ||
            this.subscriptionType === SUBSCRIPTION_TYPES.TRIAL);

        if (this.subscriptionType === SUBSCRIPTION_TYPES.TRIAL) {
          this.remainingTrialDays = moment(res.user.billingStatus.forceBillDate).diff(
            Date.now(),
            'd',
          );
        }
      }
    });
  }

  ngOnInit(): void {
    this.userService.rtl.pipe(untilDestroyed(this)).subscribe((res) => (this.isRTL = res));
  }

  ngAfterViewInit(): void {
    if (!this.logoTextElem) {
      return;
    }

    this.isNameClipped =
      this.logoTextElem.nativeElement.scrollWidth > this.logoTextElem.nativeElement.clientWidth;
  }

  getBillingStatusText(subscriptionType: SUBSCRIPTION_TYPES): string {
    if (!subscriptionType) {
      return '';
    }
    switch (subscriptionType) {
      case SUBSCRIPTION_TYPES.FREE:
        return 'FREE';
      case SUBSCRIPTION_TYPES.PAID:
        return 'PRO';
      case SUBSCRIPTION_TYPES.TRIAL:
        return 'TRIAL';
      case SUBSCRIPTION_TYPES.ENTERPRISE:
        return '';
      default:
        return '';
    }
  }

  goToBillingSetting() {
    this.settingsService.showSettingsPanel(panelID.payment);
  }

  protected readonly IconTypes = IconTypes;
}

<div id="choose-space-template-dialog" class="dialog-container">
  <div class="head">
    <div class="title">{{ 'Create Space' | translate }}</div>
    <div class="close-icon" role="button" (click)="close()">
      <span class="material-icons">close</span>
    </div>
  </div>
  <main>

    <div class="dialog-form">
      <!--    Search icon-->
      <div class="templates-search">
        <svg-icon
          icon="search_icon"
          width="24"
          class="search_icon"
          height="24"
        >

        </svg-icon>
        <input
          #searchInput
          type="text"
          [placeholder]="'Search for templates' | translate"
          (keyup)="filterTemplates($event.target.value)"
          trimInput
        />
      </div>
      <!--    Navigation-->

      <div class="templates-dialog-navigation">
        <mat-tab-group
          class="h-100"
          color="primary"
          animationDuration="0ms"
          disableRipple="true"
          [disablePagination]="true"
          (selectedIndexChange)="selectedTabChanged($event); searchInput.value = '';"
        >
          <mat-tab [label]="'Your Institution' | translate" *ngIf="isInstitutionTemplatesAvailable">
          </mat-tab>
          <mat-tab [label]="'General' | translate">
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
<!--  Space Templates list here-->
    <div class="templates-list-container">
      <app-spaces-templates-list-item
        [currentUser]="(userService.user | async)?.user"
        [isBlankTemplate]="true"
        [hidden]="
          !(currentDisplayedSpacesTemplates$ | async)?.length &&
          searchInput.value
        "
        (handleTemplateClicked)="handleTemplateClicked()"
      >
      </app-spaces-templates-list-item>

      <app-spaces-templates-list-item
        *ngFor="let spaceTemplate of currentDisplayedSpacesTemplates$ | async"
        [spaceTemplate]="spaceTemplate"
        [currentUser]="(userService.user | async)?.user"
        (handleTemplateClicked)="handleTemplateClicked(spaceTemplate)"
      >
      </app-spaces-templates-list-item>

      <div
        class="empty-results"
        *ngIf="
          !(currentDisplayedSpacesTemplates$ | async)?.length &&
          searchInput.value
        "
      >
        {{ 'No Results' | translate }}
      </div>
    </div>
  </main>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TZone } from 'moment-timezone-picker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { UserService } from '../../../services/user.service';
import { SettingsService } from '../../../services/settings.service';

@UntilDestroy()
@Component({
  selector: 'app-user-settings-language',
  templateUrl: './user-settings-language.component.html',
  styleUrls: ['./user-settings-language.component.scss'],
})
export class UserSettingsLanguageComponent implements OnInit {
  @Input() isMobile = false;
  @Input() PANELS;
  @Output() goBack = new EventEmitter<any>();

  isTimezoneAuto?: boolean;
  timezoneName?: string;
  timezoneObject = new TZone();

  constructor(
    private translateService: TranslateService,
    private userService: UserService,
    private settingsService: SettingsService,
  ) {}

  ngOnInit(): void {
    this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
      const user = res?.user;
      if (user) {
        this.isTimezoneAuto = user.timezone.autoDetect;
        this.timezoneName = user.timezone.value;
        this.timezoneObject = new TZone();
        this.timezoneObject.nameValue = this.timezoneName;
      }
    });
  }

  toggleAuto(value: boolean): void {
    this.saveTimezone(value);
  }

  async saveTimezone(auto = true): Promise<void> {
    const udpateTimezoneArg = auto ? undefined : this.timezoneName;
    await this.userService.updateTimezone(udpateTimezoneArg);
    this.settingsService.showToast({
      title: this.translateService.instant('Timezone updated!'),
      description: this.translateService.instant(
        'Timezone has been successfully updated for your account.',
      ),
    });
  }

  handleTimezoneSelect(): void {
    const isTimezoneUpdated = this.timezoneName !== this.timezoneObject.nameValue;
    this.timezoneName = this.timezoneObject.nameValue;
    if (isTimezoneUpdated) {
      this.isTimezoneAuto = false;
      this.saveTimezone(false);
    }
  }
}

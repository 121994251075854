import { Subject, Subscription } from 'rxjs';
import { Injector } from '@angular/core';
import { RunOutsideAngular, modifiedSetInterval, modifiedSetTimeout } from '../utilities/ZoneUtils';
import { NetworkEvent } from '../common/interfaces/rtc-interface';
import { NavigatorConnectionTarget } from './network.service';
import { ForegroundActivityService } from './foreground-activity.service';

// Represents incremenatable Performance Metrics that will be used to compute per second values
export enum CountedMetric {
  cd = 'cd', // = 1 Angular's CD cycle
  yDocTx = 'yDocTx', // = 1 yDoc update sent to server
  yDocRx = 'yDocRx', // = 1 yDoc update received from server
  wsTx = 'wsTx', // = 1 web socket message sent to server
  wsRx = 'wsRx', // = 1 web socket message received from server
}
type CountedMetricKey = keyof typeof CountedMetric;

// Represents computed Performance Metrics that are generated by perf logger internally
export enum PerformanceMetric {
  fps = 'fps', // frames per second [0,60]
  cpu = 'cpu', // cpu load [0,100]
}

/**
 * Metrics that are generated by external sources (e.g. SessionsSyncService) and get logged as a summary statistics
 */
export enum ExternalMetric {
  yjsUpdateSize = 'yjsUpdateSize', // = byte length of yjs update
  yjsUpdateLatency = 'yjsUpdateLatency', // = latency in seconds of yjs update
}

export type MetricType = PerformanceMetric | CountedMetric | ExternalMetric;
type MetricTypeKey =
  | keyof typeof PerformanceMetric
  | keyof typeof CountedMetric
  | keyof typeof ExternalMetric;

export interface ScenarioRecord {
  scenarioName: string;
  perfMetrics: Record<string, any>;
  scenarioProperties: Record<string, any>;
  duration: number;
}

class ScenarioSamples {
  samplesMap: Record<MetricTypeKey, number[]> = {} as Record<MetricTypeKey, number[]>; // map from type of metric (e.g. fps) -> its sample values (number[])
  interrupted: boolean; // indicates that this scenario was interrupted (browser tab lost focus, for example)
  startHeapSize: number;
  endHeapSize: number;
  scenarioProperties: Record<string, any>;
  startTime: number;
  duration?: number;
  constructor() {
    this.interrupted = false;
    this.startHeapSize = 0;
    this.endHeapSize = 0;
    this.scenarioProperties = {};
    this.startTime = performance.now();
  }
}

// Define a mapped type for valid keys of type number[]
type NumberArrayKeys<T> = {
  [K in keyof T]: T[K] extends number[] ? K : never;
}[keyof T];

// Access number[] properties with string keys in a type-safe way
function getNumArrayProperty<P, T extends NumberArrayKeys<P>>(obj: P, key: T): P[T] {
  return obj[key];
}

// Disable Angular Change detection for events generated by this class (to reduce peformance
// overhead for measurement)
@RunOutsideAngular
export class PerformanceLogger {
  private readonly HALF_LIFE_FRAMES = 1000 / 60; // ~16 frames
  private readonly HALF_LIFE_CPU = 2; // 2 iterations
  private readonly HALF_LIFE_COUNTED_METRICS = 2; // 2 iterations

  private readonly CPU_TICK_INTERVAL = 30; // 30 ms
  private readonly CPU_COMPUTE_INTERVAL = 600; // 20 x 30 ms (5% sensitivity of CPU load)
  private readonly COUNTED_METRICS_COMPUTE_INTERVAL = 1000; // Calculate values per second for all counted metrics
  private readonly LOG_INTERVAL = 1000 * 10; // 10 secs
  private readonly MEMORY_SNAPSHOT_INTERVAL = 1000 * 30; // 30 secs
  private readonly PERCENTILES_TO_LOG = [50, 95];

  private readonly SAMPLE_COUNT_LIMIT = 2000; // don't capture more than this number per scenario
  private readonly SCENARIO_WATCHDOG_TIMEOUT = 1000 * 30; // kill running scenarios after this time

  private lastFrameTick = 0;
  private lastCpuComputeTick = 0;
  private lastCountedMetricsComputeTick = 0;
  private cpuTicks = 0;
  private countedMetrics: Record<CountedMetricKey, number> = {} as Record<CountedMetricKey, number>;
  private mAvgMap: Record<MetricTypeKey, number> = {} as Record<MetricTypeKey, number>; // map from type of metric (e.g. fps) -> its moving avg value (number)
  private started = false;
  private cpuTickTimer?: NodeJS.Timer = undefined;
  private cpuComputeTimer?: NodeJS.Timer = undefined;
  private countedMetricsComputeTimer?: NodeJS.Timer = undefined;
  private logTimer?: NodeJS.Timer = undefined;
  public networkEvent: NetworkEvent | null = null;
  public navigatorConnectionTarget?: NavigatorConnectionTarget;
  private pageActive = false;
  private lastMemorySnapshot: Record<string, number> = {};
  private watchdogTimer?: NodeJS.Timer = undefined;

  // Map of Perf Scenarios to samples
  private scenarioSamplesMap: Record<string, ScenarioSamples> = {};
  private completedScenarioSamplesMap: Record<string, ScenarioSamples> = {};

  private _scenarioCompleted: Subject<ScenarioRecord> = new Subject<ScenarioRecord>();
  public readonly scenarioCompleted$ = this._scenarioCompleted.asObservable();

  public readonly PERFORMANCE_SCENARIO = 'Performance';

  // Round to 1 decimal-place and ensure range between [min,max]
  private round(value: number, min = 0, max?: number) {
    let result = Math.round((value + Number.EPSILON) * 10) / 10;
    result = Math.max(result, min); // floor at min
    if (max) {
      result = Math.min(result, max); // cap at max
    }
    return result;
  }

  private formatFps = (frameTime?: number) => (frameTime ? this.round(1000 / frameTime, 0, 60) : 0); // cap at 60 fps
  private formatDefault = (cpu?: number) => (cpu ? this.round(cpu) : 0); // floor at 0

  private sampleFormatters: Record<string, (arg0: number) => number> = {
    fps: this.formatFps,
  };

  // Static memory fields
  private readonly jsHeapSizeLimit =
    'memory' in performance
      ? Math.round((performance as any).memory.jsHeapSizeLimit / (1024 * 1024)) // convert to MB
      : 0;
  private readonly deviceMemory = (navigator as any).deviceMemory || 0; // ranges from 0.25, 0.5, 1, 2, 4, 8 (to the nearest GB, lower and upper bounds are capped to prevent fingerprinting)

  private foregroundActivitySubscription?: Subscription;
  private foregroundInactivityService: ForegroundActivityService;

  constructor(injector: Injector) {
    this.resetValues(); // init values to start

    this.foregroundInactivityService = injector.get(ForegroundActivityService);
    this.listenToPageActivity();

    // Observe performance scenario events and log them as needed
    const perfObserver = new PerformanceObserver((items: PerformanceObserverEntryList) => {
      items.getEntries().forEach((entry) => {
        // Calculate Scenario metrics and signal observers
        const duration = Number((entry.duration * 0.001).toFixed(2));
        const scenarioSamples = this.completedScenarioSamplesMap[entry.name];
        // ignore scenarios that don't exist
        if (scenarioSamples) {
          scenarioSamples.duration = duration;
          const perfMetrics = {
            ...this.calcPerfMetrics(entry.name, true), // get metrics from completed scenario
            duration: duration,
          };
          delete this.completedScenarioSamplesMap[entry.name]; // remove the completed scenario samples
          this._scenarioCompleted.next({
            scenarioName: entry.name,
            perfMetrics: perfMetrics,
            scenarioProperties: scenarioSamples.scenarioProperties,
            duration: duration,
          });
        }
      });
    });
    perfObserver.observe({ entryTypes: ['measure'] });

    this.handlePageVisibilityChange(this.foregroundInactivityService.isForegroundInactive);
  }

  private resetValues() {
    this.lastFrameTick = performance.now();
    this.lastCpuComputeTick = performance.now();
    this.cpuTicks = 0;
    this.lastCountedMetricsComputeTick = performance.now();
    this.resetCountedMetrics();
    this.resetSamples();
  }

  private resetCountedMetrics() {
    for (const key in CountedMetric) {
      this.countedMetrics[key as CountedMetricKey] = 0;
    }
  }

  private resetSamples() {
    // clear all scenarios
    this.scenarioSamplesMap = {};
  }

  private markSamplesInterrupted() {
    // mark samples for all active scenarios as interrupted
    for (const scenarioName of Object.keys(this.scenarioSamplesMap)) {
      this.scenarioSamplesMap[scenarioName].interrupted = true;
    }
  }

  // Check and end rogue scenarios
  private endLongRunningScenarios() {
    for (const scenarioName of Object.keys(this.scenarioSamplesMap)) {
      const scenario = this.scenarioSamplesMap[scenarioName];
      // end scenarios that are running longer than max allowed time (this can happen if clients skip calling end, for example)
      const duration = performance.now() - scenario.startTime;
      if (duration > this.SCENARIO_WATCHDOG_TIMEOUT) {
        console.log(
          `Perf Logger: Ending long-running scenario: ${scenarioName} duration: ${(
            duration / 1000
          ).toFixed(0)} secs`,
        );
        scenario.interrupted = true; // mark samples for scenario as interrupted
        this.endPerfScenario(scenarioName);
      }
    }
  }

  public start() {
    // reset values
    this.resetValues();

    this.started = true;

    // start cpu timer loop
    this.cpuTickTimer = modifiedSetInterval(() => this.processCpuTick(), this.CPU_TICK_INTERVAL);
    this.cpuComputeTimer = modifiedSetInterval(() => this.calcCpu(), this.CPU_COMPUTE_INTERVAL);

    // start Counted Metrics loop
    this.countedMetricsComputeTimer = modifiedSetInterval(
      () => this.calcCountedMetrics(),
      this.COUNTED_METRICS_COMPUTE_INTERVAL,
    );

    // start logging timer
    this.logTimer = modifiedSetInterval(() => this.logPerfMetrics(), this.LOG_INTERVAL);

    // start watchdog timer
    this.watchdogTimer = modifiedSetInterval(
      () => this.endLongRunningScenarios(),
      this.SCENARIO_WATCHDOG_TIMEOUT,
    );

    // start memory snapshot loop
    this.scheduleMemorySnapshot();

    // start the Performance scenario
    this.startPerfScenario(this.PERFORMANCE_SCENARIO);
  }

  public stop() {
    this.started = false;

    if (this.cpuTickTimer) {
      clearInterval(this.cpuTickTimer);
      this.cpuTickTimer = undefined;
    }

    if (this.cpuComputeTimer) {
      clearInterval(this.cpuComputeTimer);
      this.cpuComputeTimer = undefined;
    }

    if (this.countedMetricsComputeTimer) {
      clearInterval(this.countedMetricsComputeTimer);
      this.countedMetricsComputeTimer = undefined;
    }

    if (this.logTimer) {
      clearInterval(this.logTimer);
      this.logTimer = undefined;
    }

    if (this.watchdogTimer) {
      clearInterval(this.watchdogTimer);
      this.watchdogTimer = undefined;
    }

    this.stopListeningToPageActivity();

    // stop the Performance scenario
    this.endPerfScenario(this.PERFORMANCE_SCENARIO);
  }

  public startPerfScenario(
    scenarioName: string,
    scenarioProperties: Record<string, any> = {},
  ): void {
    if (this.scenarioSamplesMap[scenarioName]) {
      // @TODO: stoor
      // Defensive fix to prevent overlapping scenario profiles which could lead to issues.
      // If this is an issue long term, we should a uuid to scenario names to make them unique
      console.log(`Perf Logger: Ignoring overlapping start for scenario: ${scenarioName}`);
      return;
    }
    const sample = new ScenarioSamples();
    sample.startHeapSize = this.getUsedHeapSize();
    this.scenarioSamplesMap[scenarioName] = sample;
    sample.scenarioProperties = scenarioProperties;
    performance.mark(`start-${scenarioName}`);
  }

  public endPerfScenario(scenarioName: string, scenarioProperties: Record<string, any> = {}): void {
    if (this.scenarioSamplesMap[scenarioName]) {
      this.scenarioSamplesMap[scenarioName].scenarioProperties = {
        ...this.scenarioSamplesMap[scenarioName].scenarioProperties,
        ...scenarioProperties,
      };
      this.scenarioSamplesMap[scenarioName].endHeapSize = this.getUsedHeapSize();
      performance.mark(`end-${scenarioName}`);
      this.calculateTime(scenarioName);
    }
  }

  private calculateTime(scenarioName: string): void {
    try {
      performance.measure(`${scenarioName}`, `start-${scenarioName}`, `end-${scenarioName}`);
      performance.clearMarks(`start-${scenarioName}`);
      performance.clearMarks(`end-${scenarioName}`);

      // Mark scenario as completed to preserve the samples.
      // A new scenario may start right after which will need new samples,
      // but the performance observer runs some time later so it needs the completed
      // scenario samples to compute the metrics
      this.completedScenarioSamplesMap[scenarioName] = this.scenarioSamplesMap[scenarioName];
      delete this.scenarioSamplesMap[scenarioName];
    } catch (err) {
      console.log(
        `Perf Logger: Failed to calculate metrics for scenario: ${scenarioName} err: ${err}`,
      );
      performance.mark(`end-${scenarioName}`);
    }
  }

  private logPerfMetrics() {
    // Stop and restart Performance scenario. This will generate the
    // perf metrics for the last elapsed period.
    this.endPerfScenario(this.PERFORMANCE_SCENARIO);

    // Only (restart) the scenario when the current tab is active.
    // This will get retried when the timer fires next.
    if (this.pageActive) {
      this.startPerfScenario(this.PERFORMANCE_SCENARIO);
    }
  }

  private async scheduleMemorySnapshot(): Promise<void> {
    let result = {};
    if (!window.crossOriginIsolated) {
      console.log('Perf Logger: detailed memory snapshot is not available');
    } else if (!(performance as any).measureUserAgentSpecificMemory) {
      console.log(
        'Perf Logger: performance.measureUserAgentSpecificMemory() is not available in this browser',
      );
    } else {
      result = await this.getUserAgentSpecificMemory();

      if (this.started) {
        // Schedule the (one-shot) timer for next snapshot
        modifiedSetTimeout(async () => {
          await this.scheduleMemorySnapshot();
        }, this.MEMORY_SNAPSHOT_INTERVAL);
      }
    }
    this.lastMemorySnapshot = result;
  }

  public updateMetric(metricType: ExternalMetric, sample: number) {
    this.pushSample(metricType, sample);
  }

  private pushSample(metricType: MetricTypeKey, sample: number) {
    // only add samples when the current tab is active
    if (!this.pageActive) {
      return;
    }
    // add the given sample to every scenario
    for (const [scenarioName, scenarioSamples] of Object.entries(this.scenarioSamplesMap)) {
      // find the corresponding sample array of the given type
      let sampleArray = scenarioSamples.samplesMap[metricType];
      if (!sampleArray) {
        sampleArray = [];
        scenarioSamples.samplesMap[metricType] = sampleArray;
      }
      if (sampleArray.length < this.SAMPLE_COUNT_LIMIT) {
        sampleArray.push(sample);
        if (sampleArray.length == this.SAMPLE_COUNT_LIMIT) {
          // only log once
          console.log(
            `Perf Logger: Sample limit reached for scenario: ${scenarioName} sample type: ${metricType}`,
          );
        }
      }
    }
  }

  private updateMAvg(metricType: MetricTypeKey, newValue: number, halfLife: number) {
    // update mavg for given metric type
    const curVal = this.mAvgMap[metricType] ?? 0;
    const mavg = this.calcDampedValue(curVal, newValue, halfLife);
    this.mAvgMap[metricType] = mavg;
  }

  /* Returns value by "moving towards" newValue from origValue, based on half-life provided */
  private calcDampedValue(origValue: number, newValue: number, halflife: number): number {
    // DAMP_FACTOR controls how quickly the value moves from an older value to new value.
    // The higher this value, the less the result will reflect temporary variations.
    // A value of 1 will only keep the last value.
    // The 'halflife' of this factor—the number of iterations needed to move halfway from the old value to a new, stable value—
    // is DAMP_FACTOR*Math.log(2) (roughly 70% of the strength).
    const dampFactor = halflife / Math.log(2);

    let result = 0;
    if (origValue === 0) {
      result = newValue; // if no previous value, use newValue
    } else {
      result = origValue + (newValue - origValue) / dampFactor; // move towards the new value
    }
    return result;
  }

  private calcFps() {
    const now = performance.now();
    const thisFrameTime = now - this.lastFrameTick;
    const fps = thisFrameTime;

    // record current sample
    this.pushSample(PerformanceMetric.fps, fps);

    // Update the Fps mAvg
    this.updateMAvg(PerformanceMetric.fps, fps, this.HALF_LIFE_FRAMES);

    this.lastFrameTick = now;

    if (this.started) {
      // request next frame
      requestAnimationFrame(() => this.calcFps());
    }
  }

  private calcCpu() {
    const now = performance.now();
    const elapsed = now - this.lastCpuComputeTick;
    const tickRateDrift = (this.cpuTicks * this.CPU_TICK_INTERVAL) / elapsed; // With idle CPU, this should should equal 1
    const cpuUsage = 100 - tickRateDrift * 100;

    // record current sample
    this.pushSample(PerformanceMetric.cpu, cpuUsage);

    // Update the cpu mAvg
    this.updateMAvg(PerformanceMetric.cpu, cpuUsage, this.HALF_LIFE_CPU);

    this.lastCpuComputeTick = now;
    this.cpuTicks = 0;
  }

  private calcCountedMetrics() {
    const now = performance.now();
    const elapsed = now - this.lastCountedMetricsComputeTick;

    for (const metric in CountedMetric) {
      const metricPS = (this.countedMetrics[metric as CountedMetricKey] * 1000) / elapsed; // convert to per sec

      // record current sample
      this.pushSample(metric as MetricTypeKey, metricPS);

      // Update the mAvg
      this.updateMAvg(metric as MetricTypeKey, metricPS, this.HALF_LIFE_COUNTED_METRICS);
    }

    this.lastCountedMetricsComputeTick = now;
    this.resetCountedMetrics();
  }

  private processCpuTick() {
    this.cpuTicks++;
  }

  public incrementCountedMetric(key: CountedMetricKey) {
    this.countedMetrics[key]++;
  }

  /* Returns the real-time performance metrics (from the currently running Performance Scenario) */
  public getRealtimePerfMetrics() {
    return this.calcPerfMetrics(this.PERFORMANCE_SCENARIO);
  }

  /**
   * Calculate the next set of perf metrics based on samples collected for a given scenario.
   * Normally this gets called whenever the function `endPerfScenario()` gets
   * executed, but this can be called externally to get the point in time
   * snapshot of the current metrics (useful to associate with a specific user event).
   */
  private calcPerfMetrics(scenarioName: string, completed = false) {
    // only log when the current tab is active
    if (!this.pageActive) {
      // reset values
      this.resetValues();
      return;
    }

    // use the completed or ongoing (real-time) Performance samples to compute percentiles
    const samples = completed
      ? this.completedScenarioSamplesMap[scenarioName]
      : this.scenarioSamplesMap[scenarioName];

    const memUsage = this.currentMemoryUsage(samples);
    const networkQuality = this.currentNetworkQuality();

    // Note: Percentiles are computed between samples collected within each LOG_INTERVAL.
    let perfMetrics: Record<string, any> = {};

    perfMetrics = Object.assign(
      perfMetrics,
      memUsage,
      networkQuality,
      this.getMetricValues(samples),
    );

    return perfMetrics;
  }

  private getMetricMAvg(key: MetricTypeKey) {
    const mavg = this.mAvgMap[key];
    const formatter = this.sampleFormatters[key] ?? this.formatDefault;
    return formatter(mavg);
  }

  private getMetricValues(scenarioSamples: ScenarioSamples) {
    const result: Record<string, any> = {};
    if (scenarioSamples) {
      const keys = [
        ...Object.keys(PerformanceMetric),
        ...Object.keys(CountedMetric),
        ...Object.keys(ExternalMetric),
      ];
      for (const key of keys) {
        result[key] = this.getMetricValue(scenarioSamples, key as MetricTypeKey);
      }
      if (scenarioSamples.interrupted) {
        result['interrupted'] = true;
      }
    }
    return result;
  }

  private getMetricValue(scenarioSamples: ScenarioSamples, key: MetricTypeKey) {
    const metricValues: Record<string, any> = {};

    if (key in ExternalMetric) {
      // For external metrics just take the average of the samples
      const samples = getNumArrayProperty(scenarioSamples.samplesMap, key) ?? [];
      metricValues['avg'] = this.average(samples);
      metricValues['median'] = this.median(samples);
    } else {
      // add metric m-avg
      metricValues['mavg'] = this.getMetricMAvg(key as MetricTypeKey);
    }
    // add metric percentiles
    const samples = getNumArrayProperty(scenarioSamples.samplesMap, key as MetricTypeKey) ?? [];
    const percentiles = this.percentile(samples, this.PERCENTILES_TO_LOG);
    for (const [index, value] of percentiles.entries()) {
      // const resultKey = `${key}_p${this.PERCENTILES_TO_LOG[index]}`;
      const pKey = `p${this.PERCENTILES_TO_LOG[index]}`;
      const formatter = this.sampleFormatters[key] ?? this.formatDefault;
      metricValues[pKey] = formatter(value);
    }
    return metricValues;
  }

  private getUsedHeapSize() {
    const usedJSHeapSize =
      'memory' in performance
        ? this.round((performance as any).memory.usedJSHeapSize / (1024 * 1024)) // convert to MB
        : 0;
    return usedJSHeapSize;
  }

  private async getUserAgentSpecificMemory() {
    let result = {};
    try {
      result = await (performance as any).measureUserAgentSpecificMemory();
      result = this.parseUserAgentSpecificMemory(result);
    } catch (error) {
      if (error instanceof DOMException && error.name === 'SecurityError') {
        console.log('Perf Logger: The context is not secure.');
      } else {
        throw error;
      }
    }
    return result;
  }

  private parseUserAgentSpecificMemory(rawResult: Record<string, any>) {
    const result: Record<string, number> = {
      total: rawResult.bytes,
      DOM: 0,
      Shared: 0,
      spacesJS: 0,
    };

    rawResult.breakdown.forEach((entry: any) => {
      if (entry.types.includes('DOM')) {
        result.DOM += entry.bytes;
      }
      if (entry.types.includes('Shared')) {
        result.Shared += entry.bytes;
      }
      if (
        entry.types.includes('JavaScript') &&
        entry.attribution.some(
          (attrib: any) => attrib.scope === 'Window' && attrib.url.includes('/spaces'),
        )
      ) {
        result.spacesJS += entry.bytes;
      }
    });

    // convert all values to MB (1 dp)
    Object.keys(result).forEach((key) => {
      result[key] = this.round(result[key] / (1024 * 1024));
    });

    return result;
  }

  private currentMemoryUsage(scenarioSamples: ScenarioSamples): Record<string, any> {
    const usedHeapSizeDelta = scenarioSamples
      ? this.round(scenarioSamples.endHeapSize - scenarioSamples.startHeapSize)
      : 0;
    return {
      memory: {
        jsHeapSizeLimit: this.jsHeapSizeLimit,
        deviceMemory: this.deviceMemory,
        usedJSHeapSize: this.getUsedHeapSize(),
        usedHeapSizeDelta: usedHeapSizeDelta,
        userAgentMemory: this.lastMemorySnapshot,
      },
    };
  }

  private currentNetworkQuality(): Record<string, any> {
    return {
      networkQuality: {
        EffectiveType: this.navigatorConnectionTarget?.effectiveType ?? 'unknown',
        RTT: this.navigatorConnectionTarget?.rtt ?? 'unknown',
        DailyThreshold: this.networkEvent?.threshold ?? 'unknown',
        DailyStat: this.networkEvent?.quality ?? 'unknown',
      },
    };
  }

  private average(samples: number[]) {
    if (samples.length === 0) {
      return 0;
    }
    return samples.reduce((acc, curr) => acc + curr, 0) / samples.length;
  }

  private median(samples: number[]) {
    const sorted = samples.slice().sort((a, b) => a - b); // sort a copy
    const middle = Math.floor(sorted.length / 2);

    if (sorted.length === 0) {
      return 0;
    } else if (sorted.length % 2 === 0) {
      return (sorted[middle - 1] + sorted[middle]) / 2;
    } else {
      return sorted[middle];
    }
  }

  // Returns the specified percentiles (p50, p=50; p75, p=75; etc) from an unsorted array of numeric values
  private percentile(arr: number[], percentilesToCompute: number[]) {
    const sorted = arr.slice().sort((a, b) => a - b); // sort a copy
    return percentilesToCompute.map((p) => this._percentile_sorted(sorted, p));
  }

  // Returns the specified percentile (p50, p=50) from a sorted array of numeric values
  // Losely adapted from: https://stackoverflow.com/questions/48719873/how-to-get-median-and-quartiles-percentiles-of-an-array-in-javascript-or-php
  private _percentile_sorted(sorted: number[], p: number) {
    const pos = ((sorted.length - 1) * p) / 100;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
      return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
      return sorted[base];
    }
  }

  private handlePageVisibilityChange(pageInactive: boolean) {
    if (pageInactive) {
      this.pageActive = false;
      this.markSamplesInterrupted();
    } else {
      this.pageActive = true;
    }
  }

  private listenToPageActivity() {
    this.foregroundActivitySubscription =
      this.foregroundInactivityService.isForegroundInactive$.subscribe((pageInactive) =>
        this.handlePageVisibilityChange(pageInactive),
      );
  }

  private stopListeningToPageActivity(): void {
    this.foregroundActivitySubscription?.unsubscribe();
  }

  public async runCpuPerfTest(numIters = 3) {
    let totalTime = 0;
    for (let i = 0; i < numIters; i++) {
      const startTime = performance.now();
      await this.findPrimes(1e7, 2e7, 1e4, 10); // Find prime numbers from 1e7 to 2e7 in chunks of 1e4, with a delay of 10ms between chunks
      const timeElapsed = performance.now() - startTime;
      totalTime += timeElapsed;
      console.log(`Primes calculation: Iteration ${i + 1} Completed in: ${timeElapsed} msec`);
    }
    console.log(
      `Primes calculation: Summary: Num Iterations: ${numIters}, Total Time: ${totalTime}, Avg/iter: ${
        totalTime / numIters
      }`,
    );
  }

  private async findPrimes(
    start: number,
    end: number,
    chunkSize: number,
    delay: number,
  ): Promise<number[]> {
    return new Promise((resolve) => {
      const isPrime = (num: number) => {
        // Handle special cases: 0, 1, and negative numbers are not prime
        if (num <= 1) {
          return false;
        }
        // Check for divisibility from 2 up to the square root of the number
        for (let i = 2; i <= Math.sqrt(num); i++) {
          if (num % i === 0) {
            return false; // It's divisible by i, so not prime
          }
        }
        return true; // It's not divisible by any integer, so it's prime
      };

      const primes: number[] = [];
      const findChunk = (_start: number, chunkNum = 0) => {
        const remaining = end - _start;
        const _chunkSize = Math.min(remaining, chunkSize);
        const chunkStartTime = performance.now();
        for (let i = _start; i < _start + _chunkSize; i++) {
          if (isPrime(i)) {
            primes.push(i);
          }
        }
        console.log(
          `Primes calculation: Chunk number ${chunkNum} completed in: ${
            performance.now() - chunkStartTime
          } msec`,
        );

        _start += _chunkSize;
        if (_start < end) {
          // continue to the next chunk after timeout
          modifiedSetTimeout(() => findChunk(_start, ++chunkNum), delay);
        } else {
          // Calculation complete
          resolve(primes);
        }
      };

      findChunk(start);
    });
  }
}

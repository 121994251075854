<div
  *ngIf="wideScreen$ | async"
  class="w-100 h-100 d-flex flex-column justify-content-center align-items-center container"
>
  <span class="material-icons-outlined icon"> question_answer </span>
  <p class="mainText1 title">{{ 'Your Messages' | translate }}</p>
  <p class="mainText2 subtitle m-0">
    {{ 'Click on a message to view your conversation, or start a new one!' | translate }}
  </p>
</div>

<div class="confirmation-dialog">
  <div class="confirmation-dialog-header">
    <div class="confirmation-dialog-title">{{ confirmationDialogData?.title }}</div>
    <div class="confirmation-dialog-close" (click)="closeDialog()" *ngIf="!confirmationDialogData?.withoutCloseIcon"><mat-icon>close</mat-icon></div>
  </div>
  <div class="confirmation-dialog-body">
    <p class="confirmation-description" [innerHTML]="confirmationDialogData?.description "></p>
  </div>
  <div class="confirmation-dialog-footer">
    <button
      mat-button
      class="confirmation-dialog-cancel"
      (click)="closeDialog()"
    >
      <mat-icon>{{ confirmationDialogData?.cancelIcon }}</mat-icon>
      <span>{{ confirmationDialogData?.cancelText | translate }}</span>
    </button>
    <button
      mat-button
      class="confirmation-dialog-confirm"
      (click)="confirm()"
    >
      <svg-icon
        *ngIf="confirmationDialogData?.isConfirmIconSvg"
        [icon]="confirmationDialogData?.confirmIcon"
        width="18"
        height="18"
      >
      </svg-icon>
      <mat-icon
        *ngIf="!confirmationDialogData?.isConfirmIconSvg"
      >
        {{ confirmationDialogData?.confirmIcon }}
      </mat-icon>
      <span>{{ confirmationDialogData?.confirmText | translate }}</span>
    </button>
  </div>
</div>

import {
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomListenerFactoryService } from 'src/app/services/dom-listener-factory.service';

class Calculator {
  stack: any[] = [];
  num = 0;
  res: any = 0;
  buff: any = [false, false];
  curr: any = true;
  rank = {
    '=': 0,
    '+': 1,
    '-': 1,
    '/': 2,
    '*': 2,
    yx: 3,
    'x√y': 3,
    EE: 3,
  };

  calc = (key, val) => {
    const rank = this.rank;

    if (key === '%') {
      this.curr = 'funk';
      return (this.stack[0] ? (this.stack[this.num - 1][0] / 100) * val : val / 100).toString();
    }
    key = key.replace('×', '*').replace('÷', '/').replace('–', '-');
    if (key !== '=') {
      this.buff[1] = key;
    } else if (this.buff[0] === false) {
      this.buff[0] = val; // Feed buffer for repeating '='
    }
    if (key === '=' && !this.stack[0] && this.curr && this.buff[1]) {
      // Repeating '='
      return (
        this.buff[1] === 'yx'
          ? Math.pow(val, this.buff[0])
          : this.buff[1] === 'x√y'
          ? Math.pow(val, 1 / this.buff[0])
          : this.buff[1] === 'EE'
          ? val * Math.pow(10, this.buff[0])
          : // eslint-disable-next-line no-eval
            eval(`(${val})${this.buff[1]}(${this.buff[0]})`)
      ).toString();
    }
    if (!this.stack[0] && key !== '=') {
      // First filling
      this.buff[0] = false;
      this.stack[this.num++] = [val, key];
      this.curr = true;
      return val.toString();
    }
    if (this.stack[0] && this.curr && this.curr !== 'funk' && key !== '=') {
      // Retyping / correcting operant
      this.stack[this.num - 1] = [val, key];
      return val.toString();
    }
    if (!this.stack[0]) {
      return val.toString();
    }
    if (rank[key] <= rank[this.stack[this.num - 1][1]]) {
      this.stack[this.num - 1] = [
        this.stack[this.num - 1][1] === 'yx'
          ? Math.pow(this.stack[this.num - 1][0], val)
          : this.stack[this.num - 1][1] === 'x√y'
          ? Math.pow(this.stack[this.num - 1][0], 1 / val)
          : this.stack[this.num - 1][1] === 'EE'
          ? this.stack[this.num - 1][0] * Math.pow(10, val)
          : // eslint-disable-next-line no-eval
            eval(`(${this.stack[this.num - 1][0]})${this.stack[this.num - 1][1]}(${val})`),
        key,
      ];
    }
    if (rank[key] > rank[this.stack[this.num - 1][1]]) {
      this.stack[this.num++] = [val, key];
    } else if (this.stack[this.num - 2] && rank[key] <= rank[this.stack[this.num - 2][1]]) {
      this.calc(key, this.stack[--this.num][0]);
    }
    this.res = (this.stack[this.num - 1] ? this.stack[this.num - 1][0] : this.res).toString();
    if (key === '=') {
      this.init('AX');
    }
    this.curr = true;
    return this.res;
  };

  init = (key) => {
    if (key.match(/A/)) {
      this.stack = [];
      this.num = 0;
    }
    if (key === 'AC') {
      this.buff = [false, false];
    }
    return '0';
  };
}

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent implements OnInit, OnDestroy {
  showCalculator = false;
  @Output() output = new EventEmitter();
  @Output() closeCalculator = new EventEmitter();

  @ViewChild('calcSS3', { static: true }) calcSS3;
  @ViewChild('calculatorContainer', { static: true }) calculatorContainer;
  // Display things
  @ViewChild('display', { static: true }) display;
  @ViewChild('radDeg', { static: true }) radDeg;
  @ViewChild('smallerButton', { static: true }) smallerButton;
  @ViewChild('hold', { static: true }) hold;
  @ViewChild('lnButton', { static: true }) lnButton;
  @ViewChild('helpButton', { static: true }) helpButton;
  @ViewChild('calcLeft', { static: true }) calcLeft;
  secondKeySet: any;
  @ViewChild('hiddenCopy', { static: true }) hiddenCopy;

  pressedKey;
  frozenKey; // Active calculation keys
  secondActive?: boolean = false; // 2nd key active?
  bracketKey;
  brackets = 0; // Count of current open brackets
  calculator: Calculator[] = []; // Instances of Calculator
  deg = false; // Deg mode or Rad
  memory = 0;
  resBuffer = '0';
  bigger = false; // App size
  ln = 0;
  buffStr: string[] = [];
  sav = ['secondActive', 'deg', 'memory', 'buffStr', 'resBuffer'];
  keyBoard: any = {};
  secondLayer = [
    ['sin', 'cos', 'tan', 'ln', 'sinh', 'cosh', 'tanh', 'e<sup>x</sup>'],
    [
      'sin<sup>-1</sup>',
      'cos<sup>-1</sup>',
      'tan<sup>-1</sup>',
      'log<sub>2</sub>',
      'sinh<sup>-1</sup>',
      'cosh<sup>-1</sup>',
      'tanh<sup>-1</sup>',
      '2<sup>x</sup>',
    ],
  ];
  isFocused = true;
  showAdvancedCalculation = false;
  domListener = this.domListenerFactoryService.createInstance();

  constructor(private domListenerFactoryService: DomListenerFactoryService) {}

  ngOnDestroy(): void {
    this.domListener.clear();
  }

  ngOnInit() {
    this.secondKeySet = [].slice.call(this.calcLeft.nativeElement.children, 12, 20);
    // Colloect all keys...
    let l;
    let m;
    let n;
    for (let k = 2; k--; ) {
      for (l = this.calcSS3.nativeElement.children[k + 1], m = l.children, n = m.length; n--; ) {
        this.keyBoard[l.children[n].textContent.replace(/\s*/g, '')] = l.children[n];
      }
    }
    this.keyBoard.C = this.keyBoard.AC;
    this.keyBoard.Rad = this.keyBoard.Deg;
    for (m = this.secondLayer[0], n = m.length; n--; ) {
      this.keyBoard[this.secondLayer[1][n].replace(/<\/*\w+>/g, '')] = this.keyBoard[m[n]];
    }
    this.keyBoard['2x'] = this.keyBoard.ex;

    // eslint-disable-next-line no-eval
    this.bigger = localStorage.bigger ? eval(localStorage.bigger) : true;
    this.toggleCalc();
    if (+localStorage.ln) {
      this.ln = localStorage.ln;
      this.switchGrouping();
    }
    try {
      if (localStorage.secondActive.match(/false|undefined/) ? false : true) {
        this.keyDown(false, this.keyBoard['2nd']);
        this.doKey('2nd', true);
      }
      // eslint-disable-next-line no-eval
      if (eval(localStorage.deg)) {
        this.doKey('Deg', true);
      }
      if (localStorage.memory) {
        this.render(localStorage.memory);
        this.doKey('m+', true);
      }
      this.render(localStorage.resBuffer);
      const buffStrX = localStorage.buffStr.split(',');
      for (n = 0, m = buffStrX.length; n < m; n++) {
        if (buffStrX[n]) {
          this.doKey(buffStrX[n], true);
        }
      }
      this.render(localStorage.resBuffer);
      this.resBuffer = localStorage.resBuffer;
    } catch (e) {
      for (n = this.sav.length; n--; ) {
        localStorage.removeItem(this.sav[n]);
      }
    }

    this.calculator.push(new Calculator());

    this.helpButton.nativeElement.addEventListener(
      'mouseover',
      () => {
        this.toggleOptions(true);
      },
      false,
    );

    this.display.nativeElement.parentElement.addEventListener(
      'dblclick',
      () => {
        if (!this.helpButton.active) {
          this.toggleCalc(true);
        }
      },
      false,
    );
  }

  private setupKeyListeners() {
    if (this.domListener.listenersCount() > 0) {
      this.domListener.resumeAllListeners();
      return;
    }
    this.domListener.add(
      'document',
      'keydown',
      (event) => {
        this.onKeydownHandler(event);
      },
      true,
    );
    this.domListener.add(
      'document',
      'keypress',
      (event) => {
        this.onKeypressHandler(event);
      },
      true,
    );
    this.domListener.add(
      'document',
      'keyup',
      () => {
        this.onKeyupHandler();
      },
      true,
    );
  }

  toggleAdvancedCalculation(): void {
    this.showAdvancedCalculation = !this.showAdvancedCalculation;
  }

  onMouseDown(e) {
    this.isFocused = true;
    this.setupKeyListeners();
    this.keyDown(e);
  }

  // ------------------- event related functions ------------------ //
  keyDown(e, obj = null) {
    // Works for mouse and key
    if (e && e.target) {
      const key = this.keyBoard[e.target.innerText];
      if (key) {
        this.keyUp(); // Recover key in case of a javaScript Error
        this.pressedKey = key;
        key.className = `${key.className} calc-press`;
      }
    } else if (obj) {
      this.keyUp();
      this.pressedKey = obj;
      this.pressedKey.className = `${this.pressedKey.className} calc-press`;
    }
  }

  keyUp() {
    if (this.pressedKey && this.pressedKey !== this.secondActive) {
      this.pressedKey.className = this.pressedKey.className.replace(' calc-press', '');
      this.pressedKey = null;
    }
  }

  freezeKey(key, del?: number | boolean) {
    const obj = !del || del !== 2 ? this.frozenKey : key;
    if (obj) {
      obj.className = obj.className.replace(' calc-active', '');
    }
    if (!del) {
      key.className = `${key.className} calc-active`;
      this.frozenKey = key;
    }
    return obj;
  }

  toggleOptions(doIt?: boolean) {
    this.helpButton.active = !!doIt;
  }

  toggleCalc(doIt: boolean | null = null) {
    const cName = this.calcSS3.nativeElement.className;

    if (doIt) {
      this.bigger = !this.bigger;
    }
    localStorage.bigger = this.bigger;
    this.calcSS3.nativeElement.className = this.bigger
      ? cName.replace(' calc-small', '')
      : `${cName} calc-small`;
    this.smallerButton.nativeElement.firstChild.data = this.bigger ? '>' : '<';
    this.render(this.resBuffer);
  }

  switchGrouping(doIt: boolean | null = null) {
    if (doIt) {
      this.ln = ++this.ln > 3 ? 0 : this.ln;
    }
    this.lnButton.nativeElement.firstChild.data = !this.ln
      ? '.'
      : this.ln === 1
      ? ','
      : this.ln === 2
      ? ',.'
      : '.,';
    this.render(this.resBuffer);
    localStorage.ln = this.ln;
  }

  render(val, inp: boolean | string | null = null) {
    const regx = /(\d+)(\d{3})/;
    const hasComma = val.match(/\./);
    let tmp;
    const valAbs = Math.abs(+val);
    let fontSize = 45;
    const displayStyle = this.display.nativeElement.style;
    const displayParentStyle = this.display.nativeElement.parentNode.style;

    if (val.match(/NaN|Inf|Error/)) {
      // eslint-disable-next-line frontend-rules/ngx-translate-service
      tmp = 'Error';
    } else {
      this.resBuffer = val;
      if (valAbs >= 1e16) {
        val = (+val).toExponential(13).toString();
      }
      if (!this.bigger && (!inp || inp === '+/–') && valAbs !== 0) {
        val = (+val).toPrecision(9);
      }
      tmp = val.toString().split('.');
      if (tmp[1]) {
        tmp[2] = tmp[1].split('e');
        if (tmp[2][1]) {
          tmp[1] = tmp[2][0];
        }
        if (!inp || inp === '+/–') {
          tmp[1] = (+`1.${tmp[1]}`).toPrecision(this.bigger ? 16 : tmp[2][1] ? 7 : 9).toString();
          if (tmp[1] >= 2) {
            tmp[0] = (+tmp[0] + 1).toString();
          }
          tmp[1] = tmp[1].substr(2).replace(/0+$/, '');
        }
      }
      while (regx.test(tmp[0])) {
        tmp[0] = tmp[0].replace(regx, '$1' + ' ' + '$2');
      }
      tmp =
        tmp[0] +
        (tmp[1] || hasComma ? `.${tmp[1]}` : '')
          .replace('.undefined', '')
          .replace(inp ? '' : /\.$/, '') +
        (tmp[2] && tmp[2][1] ? `e${tmp[2][1]}` : '');
    }
    if (this.ln) {
      tmp = tmp
        .replace(/\./g, '#')
        .replace(/\s/g, this.ln === 1 ? ' ' : this.ln === 2 ? ',' : '.')
        .replace(/#/g, this.ln === 2 ? '.' : ',');
    }
    this.display.nativeElement.firstChild.data = tmp;
    // For common use: get values of pixels dynamically to stay free from design (...but outside this function)
    displayStyle.fontSize = '45px';
    displayParentStyle.lineHeight = '61px';
    while (
      this.display.nativeElement.offsetWidth >
      this.display.nativeElement.parentNode.offsetWidth - (this.bigger ? 40 : 30)
    ) {
      displayStyle.fontSize = `${fontSize--}px`;
      displayParentStyle.lineHeight = `${fontSize + 18}px`;
    }
  }

  doKey(text, alt: boolean | null = null) {
    const key = this.keyBoard[text];

    if (text === '2nd') {
      this.secondActive = this.secondActive ? undefined : true;
      key.className = this.secondActive ? 'calc-press calc-second' : ''; // !!!
      for (let n = this.secondKeySet.length; n--; ) {
        this.secondKeySet[n].children[0].innerHTML = this.secondLayer[this.secondActive ? 1 : 0][n];
      }
    } else if (text.match(/^[+|–|÷|×|yx|x√y|E]+$/) && text !== '√') {
      this.freezeKey(key);
    } else if (text.match(/^[\d|.|π]$/)) {
      this.freezeKey(key, true);
      this.keyBoard.AC.children[0].firstChild.data = 'C';
    } else if (text === 'C') {
      key.children[0].firstChild.data = 'AC';
      if (this.frozenKey) {
        this.freezeKey(this.frozenKey);
      }
    } else if (text.match(/AC|=/)) {
      if (this.bracketKey) {
        this.freezeKey(this.bracketKey, 2);
      }
      this.freezeKey(key, true);
      this.frozenKey = null;
    } else if (text.match(/Deg|Rad/)) {
      this.radDeg.nativeElement.firstChild.data = this.deg ? 'Rad' : 'Deg';
      key.children[0].firstChild.data = this.deg ? 'Deg' : 'Rad';
      this.deg = !this.deg;
    } else if (text === '(') {
      this.bracketKey = key;
      this.freezeKey(this.bracketKey, 2).className += ' calc-active';
    } else if (text === ')' && this.brackets === 1 && this.bracketKey) {
      this.freezeKey(this.bracketKey, 2);
    } else if (text.match(/^mr$/) && this.memory) {
      this.keyBoard.AC.children[0].firstChild.data = 'C';
    }

    this.evalKey(text);

    if (!alt) {
      this.keyUp();
    }
    if (text.match(/^m[c|+|-]/)) {
      this.freezeKey(this.keyBoard.mr, 2).className += this.memory ? ' calc-active' : '';
    }
  }

  evalKey(key) {
    let dispVal: any = this.resBuffer
      .replace(/\s/g, '')
      .replace(/Error|∞|-∞/, '0')
      .toString();
    const _PI = Math.PI;
    if (!key.match(/2nd|Deg|Rad|m/)) {
      // +/- issue
      this.buffStr.push(key);
      if (
        (this.buffStr[this.buffStr.length - 2] === '=' &&
          key !== '=' &&
          this.calculator[this.brackets].curr) ||
        key === 'AC'
      ) {
        this.buffStr = [key];
      }
    }
    const lastKey = this.buffStr[this.buffStr.length - 2];
    if (key.match(/^[\d|.]$/) || key === '+/–') {
      if (
        (this.calculator[this.brackets].curr && key !== '+/–') ||
        (key === '+/–' && lastKey && lastKey.match(/^[+|–|÷|×|yx|x√y|E|^C]+$/))
      ) {
        // eslint-disable-next-line frontend-rules/ngx-translate-service
        dispVal = '0';
        this.calculator[this.brackets].curr = false;
      }
      if (
        (Math.abs(+(dispVal + key)) > (this.bigger ? 1e15 : 1e9) ||
          dispVal.replace(/^-/, '').length > 15 ||
          dispVal.replace('-', '').replace(/\./g, '').length > (this.bigger ? 14 : 8) ||
          (dispVal.match(/\.|e\+/) && key === '.')) &&
        key !== '+/–'
      ) {
        this.buffStr.pop();
        return;
      } else if (key === '+/–') {
        this.render(
          !dispVal.replace(/e[+|-]/, '').match('-') ? `-${dispVal}` : dispVal.replace(/^-/, ''),
          '+/–',
        );
      } else {
        this.render((dispVal + key).replace(/^(-)*?0(\d)$/, '$1' + '$2'), true);
      }
    } else if (key.match(/^C|AC/)) {
      this.render(this.calculator[this.brackets].init(key));
      this.hold.textContent = '';
    } else if (key.match(/^[+|–|÷|×|-|/|*|yx|x√y|%|E]+$/) && key !== '√') {
      this.render(this.calculator[this.brackets].calc(key, dispVal));
    } else {
      if (this.brackets > -1) {
        this.calculator[this.brackets].curr = 'funk';
      }
      switch (key) {
        case '=':
          this.output.emit(dispVal);
          while (this.brackets > -1) {
            this.render((dispVal = this.calculator[this.brackets--].calc('=', dispVal)));
          }
          this.brackets = 0;
          this.calculator[this.brackets].curr = true;
          break;
        case '(':
          this.calculator[++this.brackets] = new Calculator();
          this.calculator[this.brackets].curr = true;
          break;
        case ')':
          if (this.brackets) {
            this.render(this.calculator[this.brackets--].calc('=', dispVal));
          }
          if (this.brackets > -1) {
            this.calculator[this.brackets].curr = false;
          }
          break;
        case 'mc':
          this.memory = 0;
          break;
        case 'm+':
          this.memory += +dispVal;
          break;
        case 'm-':
          this.memory -= +dispVal;
          break;
        case 'mr':
          this.render(this.memory.toString());
          break;
        case '1/x':
          this.render((1 / +dispVal).toString());
          break;
        case 'x2':
          this.render(Math.pow(+dispVal, 2).toString());
          break;
        case 'x3':
          this.render(Math.pow(+dispVal, 3).toString());
          break;
        case 'x!':
          this.render(
            (function fak(n) {
              return n < 0 || n > 170 ? NaN : n <= 1 ? 1 : n * fak(n - 1);
            })(Math.round(+dispVal)).toString(),
          );
          break;
        case '√':
          this.render(Math.sqrt(+dispVal).toString());
          break;
        case 'log':
          this.render((Math.log(+dispVal) / Math.log(10)).toString());
          break;
        case 'sin':
          this.render(
            !this.deg && Math.abs(+dispVal) === _PI
              ? '0'
              : Math.sin(+dispVal * (this.deg ? _PI / 180 : 1)).toString(),
          );
          break;
        case 'sin-1':
          this.render((Math.asin(dispVal) * (this.deg ? 180 / _PI : 1)).toString());
          break;
        case 'cos':
          this.render(Math.cos(dispVal * (this.deg ? _PI / 180 : 1)).toString());
          break;
        case 'cos-1':
          this.render((Math.acos(dispVal) * (this.deg ? 180 / _PI : 1)).toString());
          break;
        case 'tan':
          this.render(
            !this.deg && Math.abs(dispVal) === _PI
              ? '0'
              : Math.tan(dispVal * (this.deg ? _PI / 180 : 1)).toString(),
          );
          break;
        case 'tan-1':
          this.render((Math.atan(dispVal) * (this.deg ? 180 / _PI : 1)).toString());
          break;
        case 'ln':
          this.render(Math.log(dispVal).toString());
          break;
        case 'log2':
          this.render((Math.log(dispVal) / Math.log(2)).toString());
          break;
        case 'sinh':
          this.render(((Math.pow(Math.E, dispVal) - Math.pow(Math.E, -dispVal)) / 2).toString());
          break;
        case 'sinh-1':
          this.render(Math.log(dispVal + Math.sqrt(1 + Math.pow(dispVal, 2))).toString());
          break;
        case 'cosh':
          this.render(((Math.pow(Math.E, dispVal) + Math.pow(Math.E, -dispVal)) / 2).toString());
          break;
        case 'cosh-1':
          this.render(
            (2 * Math.log(Math.sqrt((dispVal + 1) / 2) + Math.sqrt((dispVal - 1) / 2))).toString(),
          );
          break;
        case 'tanh':
          this.render(
            (
              (Math.pow(Math.E, dispVal) - Math.pow(Math.E, -dispVal)) /
              (Math.pow(Math.E, dispVal) + Math.pow(Math.E, -dispVal))
            ).toString(),
          );
          break;
        case 'tanh-1':
          this.render(((Math.log(+dispVal + 1) - Math.log(1 - dispVal)) / 2).toString());
          break;
        case 'ex':
          this.render(Math.exp(+dispVal).toString());
          break;
        case '2x':
          this.render(Math.pow(2, +dispVal).toString());
          break;
        case 'π':
          this.render(_PI.toString());
          break;
        case 'Rand':
          this.render(Math.random().toString());
          break;
        default:
          // BuffStr.pop();
          break;
      }
    }
  }

  private onKeypressHandler(e: KeyboardEvent) {
    if (!this.isFocused) {
      return;
    }
    let key: any = e.which;
    const holdKey = this.hold.textContent;
    const keyMatch = (
      ',|.|-|–|/|÷|*|×|#|+/–|x|x!|E|EE|e|ex| |2nd|r|x√y|R|√|p|π|^|yx|\'|yx|"|yx|m|mr|v|mc|b|m+|n|m-|' +
      's|sin|c|cos|t|tan|S|sin-1|C|cos-1|T|tan-1|d|Deg|°|Deg|l|ln|L|log|\\|1/x|X|2x'
    ).split('|');
    const keyMatchHold = (
      'sin|sinh|cos|cosh|tan|tanh|m-|Rand|Deg|Rand|sin-1|sinh-1|cos-1|cosh-1|tan-1|tanh-1|' +
      '1|1/x|2|x2|3|x3|x√y|√|ln|log2|ex|2x'
    ).split('|');

    if (key === 13) {
      key = 61;
    }
    key = String.fromCharCode(key);
    let n: number;
    let m: number;
    for (n = 0, m = keyMatch.length; n < m; n = n + 2) {
      if (key === +keyMatch[n]) {
        key = key.replace(key, keyMatch[n + 1]);
        break;
      }
    }
    if (holdKey) {
      for (n = 0, m = keyMatchHold.length; n < m; n = n + 2) {
        if (key === +keyMatchHold[n]) {
          key = key.replace(key, keyMatchHold[n + 1]);
          break;
        }
      }
      this.hold.textContent = '';
    }
    if (key === '-') {
      // eslint-disable-next-line frontend-rules/ngx-translate-service
      key = '–';
    } else if (key === '*') {
      // eslint-disable-next-line frontend-rules/ngx-translate-service
      key = '×';
    } else if (key === '/') {
      // eslint-disable-next-line frontend-rules/ngx-translate-service
      key = '÷';
    }
    if ((key === 'h' || key === 'H') && !holdKey) {
      this.hold.textContent = 'hold';
    }
    if (key === 'G' && holdKey) {
      this.switchGrouping(true);
    }
    if (!this.keyBoard[key]) {
      return false;
    }
    if (
      (key.match(/-1$|log2$|2x$/) && !this.secondActive) ||
      (key.match(/h$|n$|cos$|ex$/) && this.secondActive)
    ) {
      this.keyDown(false, this.keyBoard['2nd']);
      this.doKey('2nd', true);
    }
    this.keyDown(false, this.keyBoard[key]);
    this.doKey(key, true);
  }

  private onKeydownHandler(e: KeyboardEvent) {
    if (!this.isFocused) {
      return;
    }
    const str = this.resBuffer.replace(/\s/g, '');
    const strLen = str.split('').length - 1;

    this.toggleOptions();
    if (
      e.key === 'Backspace' &&
      this.calculator[this.brackets].curr !== true &&
      this.calculator[this.brackets].curr !== 'funk' &&
      str !== '0'
    ) {
      e.preventDefault();
      while (this.buffStr.length && !this.keyBoard[this.buffStr[this.buffStr.length - 1]]) {
        // Bull shit key(s)
        this.buffStr.pop();
      }
      if (this.buffStr[this.buffStr.length - 1] === '+/–') {
        this.doKey('+/–', true);
        this.buffStr.pop();
      } else if (this.resBuffer.match(/-\d$/) || this.resBuffer.match(/^\d$/)) {
        this.buffStr.pop();
        this.doKey('C', true);
        this.render('0');
      } else {
        this.render(str.substring(0, strLen), true);
      }
      this.buffStr.pop();
      if (this.buffStr[this.buffStr.length - 1] === '.') {
        this.render(str.substring(0, strLen - 1));
        this.buffStr.pop();
      }
    }
    if (e.key === '\\') {
      this.keyDown(false, this.keyBoard.xy);
    }
    if (e.key === 'Delete') {
      this.keyDown(false, this.keyBoard.AC);
      this.doKey(this.keyBoard.AC.textContent, true);
    }
    if (e.key === 'Tab') {
      this.toggleCalc(true);
      e.preventDefault();
    }
  }

  private onKeyupHandler() {
    if (!this.isFocused) {
      return;
    }
    this.keyUp();
  }

  @HostListener('document:copy', ['$event']) onCopyHandler() {
    if (!this.isFocused) {
      return;
    }
    this.hiddenCopy.textContent = this.resBuffer.replace(/\s/g, '');
    this.hiddenCopy.focus();
    this.hiddenCopy.select();
  }

  @HostListener('document:paste', ['$event']) onPasteHandler(e: any) {
    if (!this.isFocused) {
      return;
    }
    this.render(parseFloat(e.clipboardData.getData('Text').toString()).toString());
    this.calculator[this.brackets].curr = true;
    this.keyBoard.AC.children[0].firstChild.data = 'C';
    if (this.frozenKey) {
      this.freezeKey(this.frozenKey, true);
    }
    e.preventDefault();
  }

  @HostListener('document:mousedown', ['$event.target'])
  onMouseEnter(targetElement) {
    if (this.calculatorContainer) {
      this.isFocused = this.calculatorContainer.nativeElement.contains(targetElement);
      if (this.isFocused) {
        this.setupKeyListeners();
      } else {
        this.domListener.pauseAllListeners();
      }
    }
  }

  onMouseUp(e) {
    const key = this.keyBoard[e.target.innerText];

    if (e.target === this.smallerButton.nativeElement) {
      this.toggleCalc(true);
    }
    if (e.target === this.lnButton.nativeElement) {
      this.switchGrouping(true);
    }
    if (key) {
      this.doKey(e.target.innerText);
    }
  }
}

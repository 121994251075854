import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SessionSharedDataService } from 'src/app/services/session-shared-data.service';
import { fabric } from 'fabric';
import { SessionsVptService } from 'src/app/services/sessions-vpt.service';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map } from 'rxjs';

export const MAX_ZOOM = 400;
export const MIN_ZOOM = 5;
@Component({
  selector: 'wb-zoom-controls',
  templateUrl: './wb-zoom-controls.component.html',
  styleUrls: ['./wb-zoom-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WbZoomControlsComponent {
  readonly ZOOM_LEVELS = [5, 10, 15, 20, 33, 50, 75, 100, 125, 150, 200, 250, 300, 400];
  // regular expression accepts floats + '%' sign (optional) only example 100.5% or 100 or 100%
  readonly ZOOM_INPUT_REGEX = new RegExp(/^[0-9]+(\.[0-9]?)?%?$/, 'i');

  private zoomNavigatorEnabled = new BehaviorSubject(false);
  zoomInputValue = `${this.currentZoomLevel.toString()}%`;

  viewModel$ = combineLatest([
    this.zoomNavigatorEnabled.asObservable(),
    this.sessionsVptService.viewportTransform.asObservable().pipe(
      map(() => this.currentZoomLevel),
      distinctUntilChanged(),
    ),
  ]).pipe(
    map(([zoomNavigatorEnabled, currentZoomLevel]) => ({
      zoomNavigatorEnabled,
      currentZoomLevel,
    })),
  );
  constructor(
    private sharedDataService: SessionSharedDataService,
    private sessionsVptService: SessionsVptService,
  ) {}

  handleZoomNavigatorChange(): void {
    this.zoomNavigatorEnabled.next(!this.zoomNavigatorEnabled.getValue());
  }

  handleCloseNavigator(): void {
    this.zoomNavigatorEnabled.next(false);
  }

  handleZoomValueChange(input: HTMLInputElement): void {
    if (input.value === '') {
      return;
    }
    if (this.ZOOM_INPUT_REGEX.test(input.value)) {
      this.zoomInputValue = input.value;
      return;
    }
    input.value = this.zoomInputValue;
  }

  handleZoomValueSubmit(value: string, input: HTMLInputElement): void {
    if (!this.ZOOM_INPUT_REGEX.test(input.value)) {
      input.value = `${this.currentZoomLevel}`;
      return;
    }

    const zoomValue = Math.floor(Number.parseFloat(value));
    if (isNaN(zoomValue)) {
      return;
    }
    if (zoomValue > MAX_ZOOM) {
      this.changeZoomValue(MAX_ZOOM);
      input.value = `${MAX_ZOOM}%`;
      return;
    }
    if (zoomValue < MIN_ZOOM) {
      this.changeZoomValue(MIN_ZOOM);
      input.value = `${MIN_ZOOM}%`;
      return;
    }

    this.changeZoomValue(zoomValue);
  }

  changeZoomValue(zoomValue: number): void {
    if (zoomValue === this.currentZoomLevel) {
      return;
    }
    const centerPoint = new fabric.Point(
      <number>this.sharedDataService.fabricCanvas?.getCenter().left,
      <number>this.sharedDataService.fabricCanvas?.getCenter().top,
    );

    if (zoomValue !== this.currentZoomLevel) {
      this.sessionsVptService.zoomToPoint(centerPoint, zoomValue / 100, true);
    }
  }

  handleFitContent(): void {
    this.sessionsVptService.makeCanvasFitItsContent();
    this.handleCloseNavigator();
  }

  handleResetCanvas(): void {
    this.sessionsVptService.resetCanvasToOrigin();
    this.handleCloseNavigator();
  }

  handleIncrementZoom(): void {
    this.changeZoomValue(this.ZOOM_LEVELS.find((level) => level > this.currentZoomLevel) || 400);
  }

  handleDecrementZoom(): void {
    this.changeZoomValue(
      [...this.ZOOM_LEVELS].reverse().find((level) => level < this.currentZoomLevel) || 1,
    );
  }

  get currentZoomLevel(): number {
    return +(<number>this.sharedDataService.fabricCanvas?.getZoom() * 100).toPrecision(3);
  }

  get canIncrementZoom(): boolean {
    return this.currentZoomLevel < MAX_ZOOM;
  }
  get canDecrementZoom(): boolean {
    return this.currentZoomLevel > MIN_ZOOM;
  }
}

// Description for Victory Hands gesture
import { Gesture, GestureName } from '../models/gesture';
import { FingerType, FingerDirection, FingerCurl } from '../models/finger';

const victoryHandsGesture = new Gesture(GestureName.VICTORY_HANDS);

// Add curls
victoryHandsGesture.addCurl(FingerType.Index, FingerCurl.NoCurl);
victoryHandsGesture.addCurl(FingerType.Middle, FingerCurl.NoCurl);
victoryHandsGesture.addCurls(FingerType.Ring, [FingerCurl.FullCurl, FingerCurl.HalfCurl]);
victoryHandsGesture.addCurls(FingerType.Pinky, [FingerCurl.FullCurl, FingerCurl.HalfCurl]);

// Add Directions
victoryHandsGesture.addDirections(FingerType.Index, [
  FingerDirection.VerticalUp,
  FingerDirection.DiagonalUpLeft,
  FingerDirection.DiagonalUpRight,
]);
victoryHandsGesture.addDirections(FingerType.Middle, [
  FingerDirection.VerticalUp,
  FingerDirection.DiagonalUpLeft,
  FingerDirection.DiagonalUpRight,
]);

// Add Linear Open
victoryHandsGesture.addLinearOpenY(FingerType.Index, true);
victoryHandsGesture.addLinearOpenY(FingerType.Middle, true);

export default victoryHandsGesture;

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, map } from 'rxjs';
import { filterNil } from '@ngneat/elf';
import { UserData } from '../models/user';
import { SpaceRepository } from '../state/space.repository';
import { ISession, Permissions, Session, SpacePermissions } from '../models/session';
import { InstitutionPermissions, InstitutionPermissionState } from '../models/user';
import { filterNonNullable } from '../common/utils/messaging';
import { UserService } from './user.service';
import { MessagingService } from './messaging.service';

const ENFORCED_INSTITUTION_PEMISSIONS_STATES: InstitutionPermissionState[] = [
  InstitutionPermissionState.ALWAYS_ON,
  InstitutionPermissionState.ALWAYS_OFF,
];

@Injectable({
  providedIn: 'root',
})
export class GlobalPermissionsService {
  private user$: BehaviorSubject<UserData | null>;
  private _spacePermissions$: BehaviorSubject<Permissions> = new BehaviorSubject<Permissions>(
    new Permissions(),
  );
  public spacePermissions$ = this._spacePermissions$.asObservable();
  public permissionsEnforcedByInstitution: { [key: string]: Observable<boolean> } = {};
  // permissions stored in DB
  private SPACE_PERMISSIONS_FROM_DB = [SpacePermissions.MESSAGE_OTHER_PARTICIPANTS];

  constructor(
    private spaceRepo: SpaceRepository,
    private userService: UserService,
    private messagingService: MessagingService,
  ) {
    this.spaceRepo.activeSpace$.pipe(filterNil()).subscribe((space) => {
      if (space?.sessionPermissions) {
        const currentPermissions = this.currentSpacePermissions(space.sessionPermissions);
        this._spacePermissions$.next(currentPermissions);
      }
    });

    this.user$ = this.userService.user;
    this.initPermissionsEnforcedByInstitution();
  }

  private initPermissionsEnforcedByInstitution() {
    this.permissionsEnforcedByInstitution[SpacePermissions.MESSAGE_OTHER_PARTICIPANTS] =
      this.isMessageOtherParticipantsEnforced$;
  }

  public readonly isWaitingRoomEnabled$: Observable<boolean> = this.spaceRepo.activeSpace$.pipe(
    filterNil(),
    map(() => this.isWaitingRoomEnabled),
    distinctUntilChanged(),
  );

  public readonly isWaitingRoomEnforced$: Observable<boolean> = this.spaceRepo.activeSpace$.pipe(
    filterNil(),
    map(() => this.isWaitingRoomEnforced),
    distinctUntilChanged(),
  );

  public get isWaitingRoomEnabled(): boolean {
    const institutionPermissions = this.institutionPermissions();
    if (institutionPermissions && this.isWaitingRoomEnforced) {
      return institutionPermissions?.enableWaitingRoom === InstitutionPermissionState.ALWAYS_ON;
    }
    return !!this.spaceRepo?.activeSpace?.settings?.enableWaitingRoom;
  }

  public get isWaitingRoomEnforced(): boolean {
    const institutionPermissions = this.institutionPermissions();
    if (institutionPermissions) {
      return ENFORCED_INSTITUTION_PEMISSIONS_STATES.includes(
        institutionPermissions?.enableWaitingRoom,
      );
    }
    return false;
  }

  // should be used in case the active space is not defined for the user
  public isWaitingRoomEnabledForSpace(space: ISession): boolean {
    if (space?.institution?.permissions) {
      if (
        space.institution.permissions?.enableWaitingRoom === InstitutionPermissionState.ALWAYS_ON
      ) {
        return true;
      } else if (
        space.institution.permissions?.enableWaitingRoom === InstitutionPermissionState.ALWAYS_OFF
      ) {
        return false;
      }
    }
    return !!space?.settings?.enableWaitingRoom;
  }

  private institutionPermissions(): InstitutionPermissions | undefined {
    return this.spaceRepo?.activeSpace?.institution?.permissions;
  }

  public readonly canSendMessage$ = combineLatest([
    this.spaceRepo.activeSpace$,
    this.userService.user,
    this.messagingService.selectedConversationPreview$.pipe(filterNonNullable()),
    this.spacePermissions$,
  ]).pipe(
    map(([activeSpace, user, preview, spacePermissions]) => {
      // Host/owner is allowed to send a message to any participant directly
      if (Session.isOwnedByUser(activeSpace, user?.user._id)) {
        return true;
      }
      // User is always allowed to send a message to the host/owner
      if (Session.isOwnedByUser(activeSpace, preview['userInfo']._id)) {
        return true;
      }
      // check if user permission is enabled in this space
      return this.isPermissionEnabled(
        spacePermissions,
        SpacePermissions.MESSAGE_OTHER_PARTICIPANTS,
      );
    }),
    distinctUntilChanged(),
  );

  // TODO:
  // create a generic observable that could be used for any permission
  // instead of having one observable for each permission.
  private isMessageOtherParticipantsEnforced$: Observable<boolean> =
    this.spaceRepo.activeSpace$.pipe(
      filterNil(),
      map(() =>
        this.isPermissionEnforcedByInstitution(SpacePermissions.MESSAGE_OTHER_PARTICIPANTS),
      ),
      distinctUntilChanged(),
    );

  public isPermissionEnforcedByInstitution(permissionName: keyof InstitutionPermissions) {
    const institutionPermissions = this.institutionPermissions();
    if (institutionPermissions) {
      return ENFORCED_INSTITUTION_PEMISSIONS_STATES.includes(
        institutionPermissions[permissionName],
      );
    }
    return false;
  }

  // check if permission enabled in the Space, considering institution permissions rules
  private isPermissionEnabled(
    permissions: Permissions,
    permissionName: keyof Permissions,
  ): boolean {
    if (this.spaceRepo.isCurrentUserHost()) {
      return true;
    }

    // check if the permission is enforced by the institution
    const institutionPermissionFieldName = permissionName as keyof InstitutionPermissions;
    if (this.isPermissionEnforcedByInstitution(institutionPermissionFieldName)) {
      const institutionPermissions = this.institutionPermissions();
      if (institutionPermissions) {
        return (
          institutionPermissions[institutionPermissionFieldName] ===
          InstitutionPermissionState.ALWAYS_ON
        );
      }
    }

    if (typeof permissions[permissionName] === 'boolean' || !permissions[permissionName]) {
      return !!permissions[permissionName];
    } else {
      return permissions[permissionName][this.user$.getValue()?.user._id as string];
    }
  }

  /**
   * returns current space permissions from DB after checking if the space institution
   * enforce any permission to have specific value.
   * currently we get messageOtherParticipants only from DB
   */
  private currentSpacePermissions(spacePermissions: Permissions): Permissions {
    const institutionPermissions = this.institutionPermissions();
    const currentSpacePermissions: Permissions = new Permissions();
    for (const permissionName of this.SPACE_PERMISSIONS_FROM_DB) {
      const institutionPermission = permissionName as keyof InstitutionPermissions;
      if (institutionPermissions && this.isPermissionEnforcedByInstitution(institutionPermission)) {
        spacePermissions[permissionName] =
          institutionPermissions[institutionPermission] === InstitutionPermissionState.ALWAYS_ON;
      }
      currentSpacePermissions[permissionName] = spacePermissions[permissionName];
    }
    return currentSpacePermissions;
  }

  /**
   * filters permissions object by returning only the permissions from DB
   */
  public filterPermissionsFromDB(newPermissions: any, originalPermissions: any) {
    const filteredPermissions: any = {};
    for (const permissionName of this.SPACE_PERMISSIONS_FROM_DB) {
      if (newPermissions[permissionName] !== originalPermissions[permissionName]) {
        filteredPermissions[permissionName] = newPermissions[permissionName];
      }
    }
    return filteredPermissions;
  }

  /**
   * currently we have all permissions applied on room level stored in YJS except of
   * some permissions that are applied on Space level and stored in DB
   * so we need to merge them to get the correct currentPermissions state.
   * NB: This function should be removed once we migrate all permissions to DB
   */
  public mergeRoomAndSpacePermissions(roomPermissions: any, spacePermissions: any) {
    for (const permissionName of this.SPACE_PERMISSIONS_FROM_DB) {
      roomPermissions[permissionName] = spacePermissions[permissionName];
    }
    return roomPermissions;
  }
}

<div class="drag-container grabbable" cdkDrag>
  <div
    #calculatorContainer
    cdkDragHandle
    class="calculator-container"
    [class.isFocused]="isFocused"
  >
    <app-gadget-header
      icon="calculator"
      [title]="'Calculator' | translate"
      [isExpanded]="showAdvancedCalculation"
      (closed)="domListener.clear(); closeCalculator.emit()"
    >
      <svg-icon
        (click)="toggleAdvancedCalculation()"
        [filter]="
          showAdvancedCalculation
            ? 'invert(42%) sepia(37%) saturate(2214%) hue-rotate(192deg) brightness(96%) contrast(94%)'
            : ''
        "
        [ngClass]="{ isCollapsed: !showAdvancedCalculation }"
        class="highlight-fx me-2"
        icon="fx-sign"
        [width]="18"
        [height]="18"
      >
      </svg-icon>
    </app-gadget-header>
    <div #calcSS3 class="calc-main" (mousedown)="onMouseDown($event)" (mouseup)="onMouseUp($event)">
      <div class="calc-display">
        <span #display>0</span>
        <div #radDeg class="calc-rad">Rad</div>
        <div #hold class="calc-hold"></div>
        <div class="calc-buttons">
          <div #helpButton class="calc-info">?</div>
          <div #smallerButton class="calc-smaller">&gt;</div>
          <div #lnButton class="calc-ln">.</div>
        </div>
      </div>
      <div #calcLeft [hidden]="!showAdvancedCalculation" class="calc-left">
        <div>
          <div>2nd</div>
        </div>
        <div>
          <div>(</div>
        </div>
        <div>
          <div>)</div>
        </div>
        <div>
          <div>%</div>
        </div>
        <div>
          <div>1/x</div>
        </div>
        <div>
          <div>x<sup>2</sup></div>
        </div>
        <div>
          <div>x<sup>3</sup></div>
        </div>
        <div>
          <div>y<sup>x</sup></div>
        </div>
        <div>
          <div>x!</div>
        </div>
        <div>
          <div>&radic;</div>
        </div>
        <div>
          <div class="calc-radxy"><sup>x</sup><em>&radic;</em><span>y</span></div>
        </div>
        <div>
          <div>log</div>
        </div>
        <div>
          <div>sin</div>
        </div>
        <div>
          <div>cos</div>
        </div>
        <div>
          <div>tan</div>
        </div>
        <div>
          <div>ln</div>
        </div>
        <div>
          <div>sinh</div>
        </div>
        <div>
          <div>cosh</div>
        </div>
        <div>
          <div>tanh</div>
        </div>
        <div>
          <div>e<sup>x</sup></div>
        </div>
        <div>
          <div>Deg</div>
        </div>
        <div>
          <div>&pi;</div>
        </div>
        <div>
          <div>EE</div>
        </div>
        <div>
          <div>Rand</div>
        </div>
      </div>
      <div class="calc-right">
        <div>
          <div>mc</div>
        </div>
        <div>
          <div>m+</div>
        </div>
        <div>
          <div>m-</div>
        </div>
        <div>
          <div>mr</div>
        </div>
        <div class="calc-brown">
          <div>AC</div>
        </div>
        <div class="calc-brown">
          <div>+/&#8211;</div>
        </div>
        <div class="calc-orange calc-f19">
          <div>&divide;</div>
        </div>
        <div class="calc-orange calc-f21">
          <div>&times;</div>
        </div>
        <div class="calc-black">
          <div>7</div>
        </div>
        <div class="calc-black">
          <div>8</div>
        </div>
        <div class="calc-black">
          <div>9</div>
        </div>
        <div class="calc-orange calc-f18">
          <div>&#8211;</div>
        </div>
        <div class="calc-black">
          <div>4</div>
        </div>
        <div class="calc-black">
          <div>5</div>
        </div>
        <div class="calc-black">
          <div>6</div>
        </div>
        <div class="calc-orange calc-f18">
          <div>+</div>
        </div>
        <div class="calc-black">
          <div>1</div>
        </div>
        <div class="calc-black">
          <div>2</div>
        </div>
        <div class="calc-black">
          <div>3</div>
        </div>
        <div class="calc-blank"><textarea #hiddenCopy></textarea></div>
        <div class="calc-orange calc-eq calc-f17">
          <div>
            <div class="calc-down">=</div>
          </div>
        </div>
        <div class="calc-black calc-zero">
          <div>
            <span>0</span>
          </div>
        </div>
        <div class="calc-black calc-f21">
          <div>.</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="room-selection-top-bar">
  <span class="room-selection-label">
    {{ 'Room' | translate }}
  </span>
  <app-rooms-selection-dropdown
    [activeRooms]="activeRooms$ | async"
    [archivedRooms]="archivedRooms$ | async"
    (selectedRoom)="selectedRoom$.next($event)"
  ></app-rooms-selection-dropdown>
</div>

<div
  class="table-container"
  *ngIf="(recordingsForSelectedRoom$ | async)?.length; else noRecordings"
>
  <table class="recording-table" style="width: 100%">
    <tr>
      <th class="table-header table-header-name">Recording</th>
      <th class="table-header table-header-duration">Duration</th>
      <th class="table-header table-header-access">Access</th>
      <th class="table-header"></th>
    </tr>
    <ng-container *ngFor="let recording of recordingsForSelectedRoom$ | async">
      <tr class="table-row">
        <td class="table-cell">
          <div class="name-container">
            <button class="icon-container" (click)="viewRecording(recording)">
              <svg-icon [icon]="'play'" [width]="'12px'"> </svg-icon>
            </button>
            <div class="start-end-time-container">
              <span class="start-end-time">
                <!-- convert from seconds to ms -->
                {{ recording.startingTime | date: 'hh:mm a' }} to
                <ng-container *ngIf="recording.duration; else noDurationForEndTime">
                  {{ recording | recordingEndingTime | date: 'hh:mm a (zz)' }}
                </ng-container>
                <ng-template #noDurationForEndTime>-</ng-template>
              </span>
            </div>
          </div>
        </td>
        <td class="table-cell table-cell-duration">
          <span class="text-duration">
            <div *ngIf="recording.duration; else noDuration">
              {{
                Math.round(recording.duration / 60) < 1
                  ? '< 1 min'
                  : '  ' + Math.round(recording.duration / 60) + ' mins'
              }}
            </div>
            <ng-template #noDuration>{{ 'processing' | translate }}</ng-template>
          </span>
        </td>
        <td class="table-cell table-cell-access">
          <span class="text-access">
            {{
              recording.access
                ? RecordingAccessEnumToTextMapping[recording.access]
                : ('Hosts only' | translate)
            }}
          </span>
        </td>
        <td
          class="table-cell control-buttons"
          *ngIf="
            spaceRecordingService.enabledPermissionsOnRecordings$
              | async as enabledPermissionsOnRecordingsViewModel
          "
        >
          <div class="control-buttons-container">
            <!--Change Access Button-->
            <button
              class="
                btn
                top-controls-btn
                d-sm-flex d-none
                align-items-center
                justify-content-center
              "
              (click)="copyLink(recording)"
              data-name="copy-recording-link-button"
            >
              <span class="font-18 material-icons">link</span>
            </button>
            <button class="view-button" (click)="viewRecording(recording)">View</button>
            <button class="recording-more-menu" mat-icon-button [matMenuTriggerFor]="actionMenu">
              <mat-icon>more_horiz</mat-icon>
            </button>

            <mat-menu #actionMenu class="mat-menu">
              <div class="menu-buttons">
                <button
                  class="action-btn"
                  mat-button
                  mat-menu-item
                  [matMenuTriggerFor]="recordingAccessSubMenu"
                >
                  <div class="icon-text">
                    <mat-icon class="icon">visibility</mat-icon>
                    <span>
                      {{ 'Change Access' | translate }}
                    </span>
                  </div>
                </button>
                <!--Download Recording Button-->
                <div
                  [appTooltip]="
                    enabledPermissionsOnRecordingsViewModel.download
                      ? ('Download will be available after recording has been processed'
                        | translate)
                      : ('You do not have permission to download this recording' | translate)
                  "
                  [appTooltipDisabled]="
                    recording.downloadLink && enabledPermissionsOnRecordingsViewModel.download
                  "
                  [appTooltipPlacement]="'center-start'"
                  [appTooltipArrow]="'right'"
                  mat-menu-item
                >
                  <!--                  download is triggered here by creating an <a> element using the code in downloadRecording-->
                  <button
                    [disabled]="
                      !recording.downloadLink || !enabledPermissionsOnRecordingsViewModel.download
                    "
                    style="padding-left: 0px"
                    class="action-btn"
                    mat-button
                    (click)="downloadRecording(recording.downloadLink)"
                  >
                    <div class="icon-text download">
                      <mat-icon class="icon">file_download</mat-icon>
                      <span>
                        {{ 'Download Recording' | translate }}
                      </span>
                    </div>
                  </button>
                </div>
                <!--Delete Recording Button-->
                <div
                  [appTooltip]="'You do not have permission to delete this recording' | translate"
                  [appTooltipDisabled]="enabledPermissionsOnRecordingsViewModel.delete"
                  [appTooltipPlacement]="'center-start'"
                  [appTooltipArrow]="'right'"
                  mat-menu-item
                >
                  <button
                    [disabled]="!enabledPermissionsOnRecordingsViewModel.delete"
                    style="padding-left: 0px"
                    class="action-btn"
                    mat-button
                    (click)="deleteRecording(recording.spaceId, recording.recordingId)"
                  >
                    <div class="icon-text delete">
                      <mat-icon class="icon">delete_outline</mat-icon>
                      <span>
                        {{ 'Delete Recording' | translate }}
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </mat-menu>
            <mat-menu class="analytics-recording-access-sub-menu" #recordingAccessSubMenu="matMenu">
              <ng-container *ngFor="let option of RecordingAccessEnumKeys">
                <button
                  mat-menu-item
                  class="analytics-recording-access-sub-menu-item"
                  (click)="changeRecordingAccess(recording, option)"
                >
                  <span>{{ RecordingAccessEnumToTextMapping[option] }}</span>
                  <mat-icon
                    *ngIf="
                      recording.access === option ||
                      (!recording.access && option === RecordingAccessEnums.HOSTS)
                    "
                    class="icon"
                    >check</mat-icon
                  >
                </button>
              </ng-container>
            </mat-menu>
          </div>
        </td>
      </tr>
    </ng-container>
  </table>
</div>

<ng-template #noRecordings>
  <ng-container
    *ngIf="
      currentSession && currentSession.status === SessionStatus.PROCESSED;
      then noRecordingsProcessed;
      else noRecordingsInProgress
    "
  >
  </ng-container>

  <ng-template #noRecordingsProcessed>
    <div class="no-recordings-flex">
      <div class="no-recordings">{{ 'No recordings available for this room.' | translate }}</div>
    </div>
  </ng-template>
  <ng-template #noRecordingsInProgress>
    <div class="no-recordings-flex">
      <div class="no-recordings">
        {{
          'No recordings available for this room as yet. If you have just completed a recording, it may take a few moments
        to process.'
            | translate
        }}
      </div>
    </div>
  </ng-template>
</ng-template>

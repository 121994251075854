<div class="upload-computer-container">
  <ng-container>
    <app-load-pdf-and-preview
      class="upload-computer-stage-item"
      [hidden]="(stage$ | async) !== UploadComputerStage.selectPages"
      (submitSelectedIndices)="submitIndices($event)"
      (cancelSubmit)="cancelSubmit()"
    >
    </app-load-pdf-and-preview>
    <app-upload-pdf-select-board-strategy
      class="upload-computer-stage-item"
      [selectedPagesCount]="selectedIndices.length"
      (strategySelected)="importPages($event)"
      (backToPreviewSubmit)="goBackToPreview()"
      [canSplitPDF]="canSplitPDF$ | async"
      *ngIf="(stage$ | async) === UploadComputerStage.boardStrategy"
    >
    </app-upload-pdf-select-board-strategy>
  </ng-container>
</div>

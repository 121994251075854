import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pill[text][type]',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
})
export class PillComponent implements OnInit {
  @Input() text!: string;
  @Input() type!: string;
  @Input() subtext?: string;
  @Input() icon?: string;
  @Input() svgIcon?: string;
  @Input() showXButton = true;
  @Input() optionalFilters = false;
  @Input() maxTextLength = 25;
  @Input() maxSubtextLength = 15;
  @Input() color?: string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  selected = false;

  ngOnInit(): void {
    // Handling plural types.
    if (this.type?.endsWith('tags')) {
      // special case for inst tags.
      this.type =
        this.type === 'Institution tags'
          ? 'Institutional Tag'
          : `${this.type.substring(0, this.type.length - 4)}Tag`;
    }
  }
}

import { TranslateService } from '@ngx-translate/core';
import { IconTypes } from 'src/app/standalones/components/pencil-icon/pencil-icon.component';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  EditCustomAttribute,
  InstitutionCustomAttribute,
} from 'src/app/services/institution-custom-attributes.service';
import { BehaviorSubject, map, startWith } from 'rxjs';
import { parseTextLikeCsv } from 'src/app/common/utils/common-util';
import {
  IconBackground,
  IconMessageToasterElement,
} from 'src/app/ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { SUCCESSES } from 'src/app/common/utils/notification-constants';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from 'src/app/services/notification-toaster.service';
import { ToasterPopupStyle } from 'src/app/ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { noWhitespaceValidator } from 'src/app/settings/shared/form-validators/form-validators';
import {
  DuplicateValuesErrorStateMatcher,
  atLeastOneValueValidator,
  uniqueValuesValidator,
} from 'src/app/common/utils/form-validators';

@UntilDestroy()
@Component({
  selector: 'app-edit-institution-custom-attributes',
  templateUrl: './edit-institution-custom-attributes.component.html',
  styleUrls: ['./edit-institution-custom-attributes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditInstitutionCustomAttributesComponent {
  attributeForm: FormGroup;
  private _updateButtonDisabled$ = new BehaviorSubject<boolean>(true);
  updateButtonDisabled$ = this._updateButtonDisabled$.asObservable();

  protected readonly IconTypes = IconTypes;

  duplicateValuesMatcher = new DuplicateValuesErrorStateMatcher();

  @ViewChildren('formField') formFields!: QueryList<ElementRef>;

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private notificationsService: NotificationToasterService,
    public dialogRef: MatDialogRef<EditInstitutionCustomAttributesComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: InstitutionCustomAttribute,
  ) {
    this.attributeForm = this.fb.group({
      key: [data?.key.value, [Validators.required, noWhitespaceValidator]],
      values: this.fb.array(
        data?.values.map((value) =>
          this.fb.group({
            _id: [value._id],
            value: [
              value.value,
              {
                validators: [Validators.required, noWhitespaceValidator],
                updateOn: 'change',
              },
            ],
          }),
        ) ?? [],
        [uniqueValuesValidator, atLeastOneValueValidator],
      ),
    });

    this.updateButtonDisabled$ = this.attributeForm.statusChanges.pipe(
      untilDestroyed(this),
      startWith('INVALID'),
      map((status) => status === 'INVALID'),
    );
  }

  get values(): FormArray {
    return this.attributeForm.get('values') as FormArray;
  }

  addAttributeToValuesArray(item: string = ''): void {
    this.values.push(
      this.fb.group({
        _id: [''],
        value: [item, [Validators.required, noWhitespaceValidator]],
      }),
    );

    this.focusOnLastInput();
  }

  /**
   * Sets the focus on the last input field in the form.
   * SetTimeout is used to ensure that the focus is set after the form has been rendered.
   */
  private focusOnLastInput(): void {
    setTimeout(() => {
      const lastFormField = this.formFields.last;
      if (lastFormField) {
        lastFormField.nativeElement.focus();
      }
    });
  }

  onPaste(event: ClipboardEvent, index: number): void {
    const pastedText = event.clipboardData?.getData('text/plain');

    if (pastedText?.trim()) {
      this.showPastedFromClipboardNotification();
      const items = parseTextLikeCsv(pastedText);

      if (items) {
        this.values.removeAt(index);

        // Add the rest of the items to the values FormArray
        items.forEach((item) => {
          this.addAttributeToValuesArray(item);
        });
      }
    }
  }

  private showPastedFromClipboardNotification(): void {
    const titleIcon = { svgIcon: 'check', size: 18 };
    const titleMessage = this.translateService.instant('Pasted from Clipboard');

    const notificationType = SUCCESSES.ACTION_SUCCESSFUL;
    const titleElement = new IconMessageToasterElement(
      titleIcon,
      titleMessage,
      undefined,
      undefined,
      undefined,
      IconBackground.SUCCESS,
      true,
      true,
    );

    const notification = new NotificationDataBuilder(notificationType)
      .style(ToasterPopupStyle.WARN)
      .type(NotificationType.WARNING)
      .version2Notification(true)
      .width(304)
      .timeOut(5)
      .topElements([titleElement])
      .showProgressBar(true)
      .dismissable(true)
      .progressBarColor('#bbd8ff')
      .build();

    this.notificationsService.showNotification(notification);
  }

  onSubmit(): void {
    const formValues = this.attributeForm.value;
    const editCustomAttribute: EditCustomAttribute = {
      id: this.data?.key._id ?? '',
      addValues: formValues.values
        .filter((value: any) => !value._id)
        .map((value: any) => value.value),
      renameValues: formValues.values
        .filter(
          (value: any) =>
            value._id && value.value !== this.data?.values.find((v) => v._id === value._id)?.value,
        )
        .map((value: any) => ({ id: value._id, value: value.value })),
      attribute: formValues.key !== this.data?.key.value ? { key: formValues.key } : undefined,
    };
    this.dialogRef.close(editCustomAttribute);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}

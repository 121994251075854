import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[appThrottleClick]',
})
export class ThrottleClickDirective implements OnInit, OnDestroy {
  @Input() throttleTime = 1500;
  @Input() disabled = false;
  @Output() throttleClick = new EventEmitter();
  private clicks = new Subject();
  private clicksSubscription!: Subscription;

  ngOnInit() {
    this.clicksSubscription = this.clicks
      .pipe(throttleTime(this.throttleTime))
      .subscribe((event) => {
        this.throttleClick.emit(event);
      });
  }

  ngOnDestroy() {
    this.clicksSubscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.disabled) {
      return;
    }
    this.clicks.next(event);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MessagesComponent } from './messages.component';
import { LandingComponent } from './landing/landing.component';
import { ConversationContainerComponent } from './conversation-container/conversation-container.component';

const routes: Routes = [
  {
    path: '',
    component: MessagesComponent,
    children: [
      { path: 'home', component: LandingComponent },
      { path: 'user', redirectTo: '/messages/home', pathMatch: 'full' },
      { path: 'user/:userId', component: ConversationContainerComponent },
      { path: 'group', redirectTo: '/messages/home', pathMatch: 'full' },
      { path: 'group/:groupId', component: ConversationContainerComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MessagesRoutingModule {}

<div class="question-visibility-stepper" (click)="$event.stopPropagation()">
  <div
    class="step"
    [class.disabled]="meDisabled"
    [class.passed]="multiSelect && visibilityIndex >= roleIndexMapping[roles.ME]"
    [class.selected]="visibilityIndex == roleIndexMapping[roles.ME]"
    (click)="!meDisabled ? setVisibility(roles.ME) : null"
  >
    <span class="point"></span>
    <div class="label">
      <i class="material-icons">lock</i>
      <span>{{ 'Private' | translate }}</span>
    </div>
  </div>
  <div
    class="step"
    [class.passed]="multiSelect && visibilityIndex >= roleIndexMapping[roles.TEACHERS]"
    [class.selected]="visibilityIndex == roleIndexMapping[roles.TEACHERS]"
    (click)="setVisibility(roles.TEACHERS)"
  >
    <span class="point"></span>
    <div class="label">
      <i class="material-icons">people_outline</i>
      <span>{{ 'Teachers' | translate }}</span>
    </div>
  </div>
  <div
    class="step"
    *ngIf="visibility.includes(roles.CLASS) && !draft"
    [class.passed]="multiSelect && visibilityIndex >= roleIndexMapping[roles.CLASS]"
    [class.selected]="visibilityIndex == roleIndexMapping[roles.CLASS]"
    (click)="setVisibility(roles.CLASS)"
  >
    <span class="point"></span>
    <div class="label">
      <i class="material-icons">school</i>
      <span>{{ 'Students' | translate }}</span>
    </div>
  </div>
  <div
    class="step"
    *ngIf="visibility.includes(roles.PUBLIC) && !draft"
    [class.passed]="multiSelect && visibilityIndex >= roleIndexMapping[roles.PUBLIC]"
    [class.selected]="visibilityIndex == roleIndexMapping[roles.PUBLIC]"
    (click)="setVisibility(roles.PUBLIC)"
  >
    <span class="point"></span>
    <div class="label">
      <i class="material-icons">public</i>
      <span>{{ 'Public' | translate }}</span>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  promptIntercepted = false;
  deferredPrompt;
  userInstalled = false;
  customButtonClicked = false;
  deferredPromptShown = false;
  deferredPromptRejected = false;

  constructor(private toastr: ToastrService, private translate: TranslateService) {

  }

  trackInstalled () {
    this.userInstalled = true;
  }

  addToHomeScreen () {
    // Call on custom button click
    this.customButtonClicked = true;

    if (!this.deferredPrompt) {
      this.toastr.success(this.translate.instant('Pencil App has already been installed'));
      return;
    }

    // Show the prompt
    this.deferredPrompt.prompt();
    this.deferredPromptShown = true;

    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {

        if (choiceResult.outcome === 'accepted') {
          // No matter the outcome, the prompt cannot be reused ON MOBILE
          // For 3 months or until browser cache is cleared?
        } else {
          this.deferredPromptRejected = true;
        }

      });
  }

  showHide(checkWhat: boolean) {
    if (checkWhat) {
      return 'block';
    } else {
      return 'none';
    }
  }

  browserPromptBtn() {
    if (this.promptIntercepted && !this.userInstalled ) {
      return 'block';
    } else {
      return 'none';
    }
  }
}

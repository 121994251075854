import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { TypedFragment, FragmentType } from 'src/app/common/typed-fragment/typed-fragment';
import { FragmentCollection } from 'src/app/content/course/create/create.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { UploadDialogComponent } from 'src/app/dialogs/upload/upload-dialog.component';
import { IResource, ResourceItemModel } from 'src/app/models/resource';
import { ResourcesService } from 'src/app/services/resources.service';
import { QuestionsService } from 'src/app/services/questions.service';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { UserService } from 'src/app/services/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { percentage } from '@angular/fire/storage';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MyscriptComponent } from '../myscript/myscript.component';
import {
  ComputerFile,
  Libraries,
  UPLOAD_METHOD,
  UploadedFile,
} from '../../dialogs/upload/upload/upload.component';

@UntilDestroy()
@Component({
  selector: 'app-table-editor',
  templateUrl: './table-editor.component.html',
  styleUrls: ['./table-editor.component.scss'],
})
export class TableEditorComponent implements OnInit, OnChanges {
  @Input() fragment!: TypedFragment;
  @Input() collection!: FragmentCollection;
  @Input() showTableImageDialog = false;
  @Input() showTableFormulaDialog = false;
  @Input() selectedTableIndexData;

  @Output() save = new EventEmitter();
  @Output() focused = new EventEmitter();
  @Output() blurred = new EventEmitter();
  @Output() imageDialogClosed = new EventEmitter();
  @Output() formulaDialogClosed = new EventEmitter();

  @ViewChild('table') table;

  tableData: any = [];
  topPosition = 0;
  leftPosition = 0;
  selectedTrIndex = 0;
  selectedTdIndex = 0;
  uploadProgress;
  uploadType = '';
  selectedResizeIndex = -1;
  resizeEvent;
  selectedCellWidth = 0;

  constructor(
    public dialog: MatDialog,
    private resourceService: ResourcesService,
    private questionsService: QuestionsService,
    private uploadService: UploadFileService,
    private userService: UserService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    try {
      if (this.fragment?.fragment?.data) {
        this.tableData = JSON.parse(this.fragment?.fragment?.data);
      }
    } catch (e) {
      // continue regardless of error
    }
  }

  ngOnChanges(changes) {
    if (changes.showTableImageDialog?.currentValue) {
      this.showImageUploadDialog();
    } else if (changes.showTableFormulaDialog?.currentValue) {
      this.openKatexDialog();
    }
  }

  saveChanges(trIndex: number, tdIndex: number, fragIndex: number, event): void {
    this.tableData[trIndex][tdIndex][fragIndex]['data'] = event;
    this.fragment.fragment.data = JSON.stringify(this.tableData);
    this.save.emit(this.fragment);
  }

  addCol(position: string): void {
    this.tableData.forEach((tr) => {
      tr.splice(position === 'LEFT' ? this.selectedTdIndex : this.selectedTdIndex + 1, 0, [
        { type: FragmentType.Text, data: '' },
      ]);
    });
    this.fragment.fragment.data = JSON.stringify(this.tableData);
    this.save.emit(this.fragment);
  }

  addRow(position: string): void {
    const tr: any = [];
    for (let i = 0; i < this.tableData[0].length; i++) {
      tr.push([{ type: FragmentType.Text, data: '', metadata: this.tableData[0][i][0].metadata }]);
    }
    this.tableData.splice(
      position === 'ABOVE' ? this.selectedTrIndex : this.selectedTrIndex + 1,
      0,
      tr,
    );
    this.fragment.fragment.data = JSON.stringify(this.tableData);
    this.save.emit(this.fragment);
  }

  deleteCol(): void {
    this.tableData.forEach((tr) => {
      tr.splice(this.selectedTdIndex, 1);
    });
    this.fragment.fragment.data = JSON.stringify(this.tableData);
    this.save.emit(this.fragment);
  }

  deleteRow(): void {
    this.tableData.splice(this.selectedTrIndex, 1);
    this.fragment.fragment.data = JSON.stringify(this.tableData);
    this.save.emit(this.fragment);
  }

  setHeader(position: string): void {
    if (!this.fragment.fragment.metadata) {
      this.fragment.fragment.metadata = {};
    }
    this.fragment.fragment.metadata[position === 'ROW' ? 'is_row_header' : 'is_col_header'] = true;
    this.save.emit(this.fragment);
  }

  disableHeader(position: string): void {
    if (!this.fragment.fragment.metadata) {
      this.fragment.fragment.metadata = {};
    }
    delete this.fragment.fragment.metadata[position === 'ROW' ? 'is_row_header' : 'is_col_header'];
    this.save.emit(this.fragment);
  }

  reorderFragment(event: CdkDragDrop<string[]>, trIndex: number, tdIndex: number): void {
    if (event.previousIndex === event.currentIndex) {
      return;
    }
    moveItemInArray(this.tableData[trIndex][tdIndex], event.previousIndex, event.currentIndex);
    this.fragment.fragment.data = JSON.stringify(this.tableData);
    this.save.emit(this.fragment);
  }

  reorderRows(event: CdkDragDrop<string[]>): void {
    if (event.previousIndex === event.currentIndex) {
      return;
    }
    moveItemInArray(this.tableData, event.previousIndex, event.currentIndex);
    this.fragment.fragment.data = JSON.stringify(this.tableData);
    this.save.emit(this.fragment);
  }

  reorderCols(event: CdkDragDrop<string[]>): void {
    if (event.previousIndex === event.currentIndex) {
      return;
    }
    for (let i = 0; i < this.tableData.length; i++) {
      moveItemInArray(this.tableData[i], event.previousIndex, event.currentIndex);
    }
    this.fragment.fragment.data = JSON.stringify(this.tableData);
    this.save.emit(this.fragment);
  }

  deleteFragment(trIndex: number, tdIndex: number, fragIndex: number) {
    if (
      this.tableData[trIndex][tdIndex].length === 1 ||
      (this.tableData[trIndex][tdIndex][fragIndex].type === FragmentType.Text &&
        this.tableData[trIndex][tdIndex].filter((f) => f.type === FragmentType.Text).length === 1)
    ) {
      return;
    }
    this.tableData[trIndex][tdIndex].splice(fragIndex, 1);
    this.fragment.fragment.data = JSON.stringify(this.tableData);
    this.save.emit(this.fragment);
  }

  showImageUploadDialog(): void {
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      width: '80%',
      height: '80%',
      panelClass: 'upload-dialog-container',
      data: {
        title: this.translateService.instant('Upload Image'),
        libraries: [Libraries.COMPUTER, Libraries.CAMERA, Libraries.PENCIL_FILES],
        type: [ResourceItemModel.IMAGE],
      },
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(async (result: UploadedFile) => {
        this.imageDialogClosed.emit();
        if (!result) {
          return;
        }
        if (result.uploadMethod === UPLOAD_METHOD.PENCIL_LIBRARY) {
          if (this.selectedTableIndexData) {
            for (const file of result.files as IResource[]) {
              this.tableData[this.selectedTableIndexData.trIndex][
                this.selectedTableIndexData.tdIndex
              ].push({ type: FragmentType.Image, data: file.url });
            }
            this.fragment.fragment.data = JSON.stringify(this.tableData);
            this.save.emit(this.fragment);
            this.collection
              .addCourseToResource(
                result.files as IResource[],
                this.resourceService,
                this.questionsService.selectedCourse.getValue()?._id,
              )
              ?.pipe(untilDestroyed(this))
              .subscribe();
          }
        } else if (result.uploadMethod === UPLOAD_METHOD.COMPUTER) {
          const course = this.questionsService.selectedCourse.getValue();
          for (let i = 0; i < result.files.length; i++) {
            const file = (result.files as ComputerFile[])[i];
            const reader = new FileReader();
            const task = this.uploadService.uploadToFireStorage(file.file, (data) => {
              this.uploadProgress = undefined;
              this.tableData[this.selectedTableIndexData.trIndex][
                this.selectedTableIndexData.tdIndex
              ].push({ type: FragmentType.Image, data });
              this.createResource(data, file.file, FragmentType.Image, course ? [course?._id] : [])
                .pipe(untilDestroyed(this))
                .subscribe();
              this.fragment.fragment.data = JSON.stringify(this.tableData);
              this.save.emit(this.fragment);
              if (i === result.files.length - 1) {
                this.userService.isUploading.next(false);
              }
            });
            if (task) {
              percentage(task)
                .pipe(untilDestroyed(this))
                .subscribe((data) => {
                  this.uploadProgress = of(data.progress);
                });
              reader.readAsDataURL(file.file);
            }
          }
          this.userService.isUploading.next(true);
          this.uploadType = 'image';
        }
      });
  }

  openKatexDialog(katexData = null): void {
    const dialogRef = this.dialog.open(MyscriptComponent, {
      width: '800px',
      panelClass: 'math-quill-dialog-container',
      data: { katex: katexData },
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.formulaDialogClosed.emit();
        if (result) {
          this.tableData[this.selectedTableIndexData.trIndex][
            this.selectedTableIndexData.tdIndex
          ].push({ type: FragmentType.Text, data: `$$${result}$$` });
          this.fragment.fragment.data = JSON.stringify(this.tableData);
          this.save.emit(this.fragment);
        }
      });
  }

  saveImage(image): void {
    const course = this.questionsService.selectedCourse.getValue();
    const reader = new FileReader();
    const task = this.uploadService.uploadToFireStorage(image, (data) => {
      this.uploadProgress = undefined;
      this.tableData[this.selectedTableIndexData.trIndex][this.selectedTableIndexData.tdIndex].push(
        { type: FragmentType.Image, data },
      );
      this.createResource(data, image, FragmentType.Image, course ? [course?._id] : [])
        .pipe(untilDestroyed(this))
        .subscribe();
      this.fragment.fragment.data = JSON.stringify(this.tableData);
      this.save.emit(this.fragment);
    });
    if (task) {
      percentage(task)
        .pipe(untilDestroyed(this))
        .subscribe((data) => {
          this.uploadProgress = of(data.progress);
        });
      reader.readAsDataURL(image);
    }
  }

  createResource(
    url: string,
    fileInput: File,
    type: FragmentType | ResourceItemModel,
    courses?: string[],
  ) {
    const resource = {
      url,
      name: fileInput.name,
      size: fileInput.size,
      metadata: {
        topics_ids: [],
        minGrade: 0,
        maxGrade: 10,
      },
      type: type,
      acl: { public: false, visibility: 'CLASS' },
      path: 'Other',
      protected: true,
      courses: courses ?? [],
    };
    return this.resourceService.createResource(resource);
  }

  handleMouseOver(event, tdIndex: number) {
    if (event.offsetX + 5 > event.target.clientWidth && tdIndex < this.tableData[0].length - 1) {
      document.body.style.cursor = 'ew-resize';
    } else {
      document.body.style.cursor = '';
    }
  }

  handleMouseDown(event, tdIndex: number) {
    if (event.offsetX + 5 > event.target.clientWidth && tdIndex < this.tableData[0].length - 1) {
      this.selectedResizeIndex = tdIndex;
      this.resizeEvent = event;
      this.selectedCellWidth = this.resizeEvent.target.clientWidth;
    } else {
      this.selectedResizeIndex = -1;
    }
  }

  handleMouseUp() {
    document.body.style.cursor = '';
    this.selectedResizeIndex = -1;
    this.fragment.fragment.data = JSON.stringify(this.tableData);
    this.save.emit(this.fragment);
  }

  handleMouseMove(event) {
    if (this.selectedResizeIndex > -1) {
      const width = this.selectedCellWidth + event.clientX - this.resizeEvent.clientX;
      this.tableData.forEach((tr) => {
        tr[this.selectedResizeIndex].forEach((frag) => {
          if (frag.metadata) {
            frag.metadata['width'] = width;
          } else {
            frag['metadata'] = { width };
          }
        });
      });
    }
  }
}

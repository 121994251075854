import { AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { fabric } from 'fabric';
import { RealtimeSpaceService } from 'src/app/services/realtime-space.service';
import { Mouse, Point } from 'src/app/models/session-sync';
import { Subscription } from 'rxjs';
import { SessionRtdService } from 'src/app/services/session-rtd.service';
import { throttle, DebouncedFunc } from 'lodash-es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DomListenerFactoryService } from 'src/app/services/dom-listener-factory.service';
import { PresenceProvider } from 'src/app/services/presence-provider';
import { UserAction } from 'src/app/common/utils/presence.util';
import { modifiedSetTimeout } from 'src/app/utilities/ZoneUtils';
import { SessionSharedDataService } from '../../../services/session-shared-data.service';
import {
  cursorTooltipHeight,
  cursorTooltipWidth,
  customPointerOffset,
  customPointerOffsetLeft,
  customPointerOffsetTop,
  pointerCaptureRate,
  pointerOffsetLeft,
  pointerOffsetTop,
  RealTimePointerHandler,
} from '../wb-toolbar/toolbars/realtime-common';
import { SessionsVptService } from '../../../services/sessions-vpt.service';
import { SpaceBoardsService } from '../../../services/space-boards.service';

/*
TODO
- benchmarking
*/

export enum OverlayObjectType {
  PATH = 'path',
  POINTER = 'pointer',
}

export class OverlayObject extends fabric.Object {
  userId?: string;
  type?: OverlayObjectType;
  timestamp?: number;
  uid?: string;
}
export class OverlayCanvas extends fabric.StaticCanvas {
  private getOverlayObjects(): OverlayObject[] {
    const objects = this.getObjects();
    return objects as OverlayObject[];
  }

  public findOverlayObjects(
    type: OverlayObjectType,
    userId?: string,
    uid?: string,
  ): OverlayObject[] {
    const objects = this.getOverlayObjects();
    const matchingObjects = objects.filter(
      (obj) => obj.type === type && (!userId || obj.userId === userId) && (!uid || obj.uid === uid),
    );
    return matchingObjects;
  }

  public setMetadata(
    obj: OverlayObject,
    userId: string,
    type: OverlayObjectType,
    timestamp: number,
    uid: string,
  ): void {
    obj.type = type;
    obj.userId = userId;
    obj.timestamp = timestamp;
    obj.uid = uid;
  }
}

export class OverlayMetadata {
  // Current points for the RTD path
  currentLine: Point[];

  // Current color for the pointer
  pointerColor?: string;

  // svg pointer
  svgPointer?: string;

  constructor() {
    this.currentLine = [];
  }
}

export interface RtEvent {
  metadata: OverlayMetadata;
  event: {
    mouse: Mouse;
    user: string;
    activeToolbar?: string;
    toolbarChanged?: boolean;
  };
}

@UntilDestroy()
@Component({
  selector: 'app-overlay-canvas',
  templateUrl: './overlay-canvas.component.html',
  styleUrls: ['./overlay-canvas.component.scss'],
})
export class OverlayCanvasComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  userMetadata: { [key: string]: OverlayMetadata } = {};
  lastVptValue: number[] = [];
  public canvas?: OverlayCanvas;
  renderFunc?: DebouncedFunc<() => void>;

  private viewportSubscription?: Subscription;
  domListener = this.domListenerFactoryService.createInstance();
  constructor(
    private realtimeSpaceService: RealtimeSpaceService,
    private sessionSharedDataService: SessionSharedDataService,
    private sessionRtdService: SessionRtdService,
    private sessionsVptService: SessionsVptService,
    private spaceBoardsService: SpaceBoardsService,
    private domListenerFactoryService: DomListenerFactoryService,
    private presenceProvider: PresenceProvider,
  ) {}
  ngOnInit() {
    this.setupResetHandlers();
    this.domListener.add(window, 'resize', (event) => {
      this.resizeCanvases(event);
    });

    this.setupViewportHandler();
  }

  ngOnDestroy() {
    this.viewportSubscription?.unsubscribe();
    this.domListener.clear();
    this.canvas?.dispose();
    this.sessionRtdService.clearOverlayCanvas();
    this.renderFunc = undefined;
  }

  resizeCanvases(event: UIEvent) {
    if (!event.currentTarget) {
      return;
    }

    this.canvas?.setDimensions({
      width: event.currentTarget['innerWidth'],
      height: event.currentTarget['innerHeight'],
    });
  }

  private setupViewportHandler() {
    this.viewportSubscription?.unsubscribe();

    this.viewportSubscription = this.sessionsVptService.viewportTransform
      .pipe(untilDestroyed(this))
      .subscribe((vpt) => {
        if (!vpt) {
          return;
        }
        this.handleViewportTransformChange(vpt);
        this.changeSVGPointerTransform(vpt);
      });

    const vpt = this.sessionsVptService.viewportTransform.getValue();
    if (vpt) {
      this.handleViewportTransformChange(vpt);
    }
  }

  private getSvgContainer(): HTMLElement | null {
    const el = document.getElementById('overlay-canvas-svg-pointer');
    return el;
  }

  private changeSVGPointerTransform(vpt: number[] | null) {
    const el = this.getSvgContainer();
    if (!el || !vpt) {
      return;
    }

    el.style.transform = `matrix(${vpt.toString()})`;
  }

  // Gets the inverse of the zoom factor from the canvas
  private getInvZoomScale(vpt: number[]): { scaleX: number; scaleY: number } {
    const invVpt = fabric.util.invertTransform(vpt);
    const scale = (({ scaleX, scaleY }) => ({ scaleX, scaleY }))(fabric.util.qrDecompose(invVpt));

    return scale;
  }

  // scales the pointer and updates the offset
  private setScale(pointer: HTMLElement, scale: number) {
    const svg = pointer.firstElementChild as HTMLElement | null;
    const g = svg?.firstElementChild;

    if (!g || !svg) {
      return;
    }
    g.setAttribute('transform', `scale(${scale})`);

    const activeToolbar = pointer.getAttribute('activeToolbar') as string;
    const topOffset = customPointerOffset.includes(activeToolbar)
      ? customPointerOffsetTop
      : pointerOffsetTop;
    const letOffset = customPointerOffset.includes(activeToolbar)
      ? customPointerOffsetLeft
      : pointerOffsetLeft;

    svg.style.top = `${-topOffset * scale}`;
    svg.style.left = `${-letOffset * scale}`;
    svg.style.width = `${cursorTooltipWidth * scale}px`;
    svg.style.height = `${cursorTooltipHeight * scale}px`;
  }

  private handleViewportTransformChange(vpt: number[]) {
    // Handle the scaling
    const { scaleX } = this.getInvZoomScale(vpt);

    // in case last received VPT values and the containers doesn't contain the children cursors
    // we should save so that use it to set the correct style and scale && width values for the cursors
    if (!this.getPointerContainer().length) {
      this.lastVptValue = vpt;
    } else {
      this.lastVptValue = [];
      this.getPointerContainer().forEach((pointer) => {
        this.setScale(pointer, scaleX);
      });
    }

    this.setTransformation(this.canvas, vpt);
    this.changeSVGPointerTransform(vpt);
  }

  private setTransformation(canvas: undefined | fabric.StaticCanvas, vpt: number[]): void {
    canvas?.setViewportTransform(vpt);
    if (this.renderFunc) {
      this.renderFunc();
    }
  }

  // Gets the container for the pointers
  private getPointerContainer(): HTMLElement[] {
    const el = this.getSvgContainer();
    if (!el) {
      return [];
    }

    const children = el.children;
    return Array.from(children) as HTMLElement[];
  }

  // Gets the SVG for the pointer from the dom
  // Note: there is a container for each pointer
  getPointer(id: string): HTMLElement | null {
    const containerId = RealTimePointerHandler.SVGIdFormat(id);
    const container = document.getElementById(containerId);
    return container;
  }

  setupResetHandlers() {
    this.setupFrameChangeResetHandler();

    this.sessionSharedDataService.sessionChanged.pipe(untilDestroyed(this)).subscribe(() => {
      const users = Object.keys(this.userMetadata);
      modifiedSetTimeout(() => users.forEach((id) => this.cleanupUser(id)), 2 * pointerCaptureRate);
    });

    this.realtimeSpaceService.service.usersLeftFrame$
      .pipe(untilDestroyed(this))
      .subscribe((users) => {
        modifiedSetTimeout(
          () => users.forEach((userId) => this.cleanupUser(userId)),
          2 * pointerCaptureRate,
        );
      });

    this.presenceProvider
      .getParticipantPresenceStatus()
      .pipe(untilDestroyed(this))
      .subscribe((participantPresenceStatus) => {
        if (participantPresenceStatus.userAction === UserAction.LEAVE_SPACE) {
          modifiedSetTimeout(
            () => this.cleanupUser(participantPresenceStatus.userId),
            2 * pointerCaptureRate,
          );
        }
      });
  }

  setupFrameChangeResetHandler() {
    // cleanup user pointers when selected board changes
    this.spaceBoardsService.activeSpaceSelectedBoard$.pipe(untilDestroyed(this)).subscribe(() => {
      const users = Object.keys(this.userMetadata);
      modifiedSetTimeout(() => {
        users.forEach((id) => {
          // clean only users pointers not included in the current frame
          if (!this.sessionSharedDataService.currentFrameHasUser(id)) {
            this.cleanupUser(id);
          }
        });
      }, 2 * pointerCaptureRate);
    });
  }

  cleanupUser(userId: string) {
    if (!(userId in this.userMetadata)) {
      return;
    }

    // Remove the realtime path from the canvas
    const paths = this.canvas?.findOverlayObjects(OverlayObjectType.PATH, userId);

    if (paths) {
      for (const path of paths) {
        this.canvas?.remove(path);
      }
    }

    // Remove the pointer from the DOM
    const pointer = this.getPointer(userId);
    pointer?.parentElement?.remove();
  }

  private setupCanvas() {
    this.canvas = new OverlayCanvas('overlay-canvas', {
      width: window.innerWidth,
      height: window.innerHeight,
    });

    // Set upper limit on the number of renders / sec
    this.renderFunc = throttle(this.canvas.requestRenderAll.bind(this.canvas), pointerCaptureRate); // 12 fps

    // Pass references to the canvas and throttled renderFunc onto the sessionRtdService
    this.sessionRtdService.setOverlayCanvas(this.canvas, this.renderFunc);
  }

  ngAfterViewInit() {
    this.setupCanvas();

    this.realtimeSpaceService.service.realTimeEvent$
      .pipe(untilDestroyed(this))
      .subscribe((events) => {
        if (!this.canvas || !this.renderFunc) {
          return;
        }
        const rtEvents: RtEvent[] = [];
        for (const event of events) {
          const id = event.user;

          if (!this.userMetadata[id]) {
            // Create the metadata object for realtime
            this.userMetadata[id] = new OverlayMetadata();
          }
          const metadata = this.userMetadata[id];
          rtEvents.push({ metadata, event });
        }

        this.sessionRtdService.rtEventEmitter.emit(rtEvents);
      });
  }

  ngAfterViewChecked(): void {
    if (this.getPointerContainer().length && this.lastVptValue.length) {
      this.handleViewportTransformChange(this.lastVptValue);
      this.lastVptValue = [];
    }
  }
}

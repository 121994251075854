<div class="modal-body">
  <div class="title-section">
    <span class="title">{{ 'Assign Site' | translate }}</span>
  </div>
  <div class="body">
    <div class="site-selection-note">
      {{ 'Once a site has been selected, it cannot be changed.' | translate }}
      <a [href]="LEARN_MORE_ABOUT_SITES" target="_blank" class="learn-more">{{
        'Learn more' | translate
      }}</a>
    </div>
    <form [formGroup]="form">
      <div class="site-selection-section">
        <div class="site-title">{{ 'Site' | translate }}</div>
        <div class="ui-select-wrap">
          <mat-select
            formControlName="siteId"
            placeholder="{{ 'Select Site' | translate }}"
            class="full-height ui-select"
          >
            <ng-container *ngFor="let site of availableSites">
              <mat-option [value]="site._id"> {{ site.name }} </mat-option>
            </ng-container>
          </mat-select>
        </div>
      </div>
    </form>
  </div>
  <div class="footer">
    <app-pencil-button
      class="pencil-button light medium btn-cancel"
      [label]="'Cancel' | translate"
      [mat-dialog-close]="false"
      buttonType="button"
      icon="close"
      [iconConfig]="{
        type: 'material-icon'
      }"
    >
    </app-pencil-button>
    <app-pencil-button
      class="pencil-button medium primary btn-submit"
      [label]="'Create Space' | translate"
      buttonType="button"
      icon="keyboard_arrow_right"
      [iconConfig]="{
        type: 'material-icon'
      }"
      [disabled]="!form.valid"
      (click)="submit()"
    >
    </app-pencil-button>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'individualUserPermission',
})
export class IndividualUserPermissionPipe implements PipeTransform {
  transform(permissions: boolean | Record<string, boolean>, userId: string): boolean {
    if (typeof permissions === 'boolean' || !permissions) {
      return permissions;
    }
    return permissions[userId];
  }
}

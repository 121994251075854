import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-visibility-draft-alert-dialog',
  templateUrl: 'visibility-draft-alert.component.html',
  styleUrls: ['./visibility-draft-alert.component.scss'],
})
export class VisibilityDraftAlertDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<VisibilityDraftAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}
}

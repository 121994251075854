import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Intercom } from 'ng-intercom';
import { Site } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { PanelData } from 'src/app/settings/settings.constants';
import { IconTypes } from 'src/app/standalones/components/pencil-icon/pencil-icon.component';
import { institutionHasSites } from 'src/app/utilities/sites.utils';
import { InstitutionSitesService } from 'src/app/services/institution-sites.service';
import { BehaviorSubject, take } from 'rxjs';
import {
  IconBackground,
  IconMessageToasterElement,
} from 'src/app/ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from 'src/app/services/notification-toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterPopupStyle } from 'src/app/ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { ERRORS, SUCCESSES } from 'src/app/common/utils/notification-constants';
import { CreateAndEditSiteDialogComponent } from './create-and-edit-site-dialog/create-and-edit-site-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-user-settings-sites-management',
  templateUrl: './user-settings-sites-management.component.html',
  styleUrls: ['./user-settings-sites-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsSitesManagementComponent {
  @Input() panelData!: PanelData;
  private _isLoading$ = new BehaviorSubject(false);
  isLoading$ = this._isLoading$.asObservable();
  protected skeletonBars = Array.from({ length: 5 });
  protected readonly IconTypes = IconTypes;
  sites: Site[] = [];
  isSitesEnabled = false;
  constructor(
    private userService: UserService,
    private intercom: Intercom,
    private dialog: MatDialog,
    private institutionSitesService: InstitutionSitesService,
    private translateService: TranslateService,
    private notificationsService: NotificationToasterService,
  ) {
    this.subscribeToInstitutionSitesChanges();
  }

  private subscribeToInstitutionSitesChanges(): void {
    this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
      this.isSitesEnabled = institutionHasSites(res?.user.institution);
      this.sites = res?.user.institution.sites ?? [];
      this.sortSites();
    });
  }

  private sortSites() {
    this.sites.sort((a, b) => a.name.localeCompare(b.name));
  }

  openIntercom(): void {
    try {
      this.intercom.show();
    } catch (e) {
      window.open('https://www.pencilspaces.com/support', '_blank', 'noopener');
    }
  }

  openAddSiteDialog() {
    const dialogRef = this.dialog.open(CreateAndEditSiteDialogComponent, {
      disableClose: true,
      panelClass: 'zero-padding-dialog',
    });
    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (result) {
          this._isLoading$.next(true);
          this.addSite(result.name);
        }
      });
  }

  private addSite(siteName: string) {
    this.institutionSitesService
      .addSite(siteName)
      .pipe(take(1))
      .subscribe({
        next: (site: Site) => {
          // Successfully Adding site
          if (site) {
            this.sites.push(site); // Add the new site to the sites array
            this.updateCurrentUserSites();
            this.showToast(
              {
                title: 'Site added successfully',
              },
              false,
            );
            this._isLoading$.next(false);
          }
        },
        error: (error: any) => {
          this.handleError(error, 'add', siteName);
          this._isLoading$.next(false);
        },
      });
  }

  openEditSiteDialog(site: Site) {
    const dialogRef = this.dialog.open(CreateAndEditSiteDialogComponent, {
      data: site,
      disableClose: true,
      panelClass: 'zero-padding-dialog',
    });
    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (result) {
          this._isLoading$.next(true);
          this.updateSite(result);
        }
      });
  }

  private updateSite(siteData: Site) {
    this.institutionSitesService
      .updateSite(siteData._id, siteData.name)
      .pipe(take(1))
      .subscribe({
        next: (updatedSite: Site) => {
          // Successfully updating site
          if (updatedSite) {
            // update site in list
            this.sites = this.sites.map((site) =>
              site._id === updatedSite._id ? updatedSite : site,
            );
            this.updateCurrentUserSites();
            this.showToast(
              {
                title: 'Site updated successfully',
              },
              false,
            );
            this._isLoading$.next(false);
          }
        },
        error: (error: any) => {
          this.handleError(error, 'rename', siteData.name);
          this._isLoading$.next(false);
        },
      });
  }
  private updateCurrentUserSites() {
    const currentUser = this.userService.user.getValue()!;
    currentUser.user.institution.sites = this.sites;
    this.userService.user.next(currentUser);
  }

  private handleError(error: any, action: 'add' | 'rename', siteName: string) {
    // Extract error properties
    const backendError = error?.error?.error;
    const backendErrorCode = error?.error?.errorCode;

    // Check and log errors
    if (backendError && backendErrorCode) {
      if (backendErrorCode === 'EXISTING_RECORD') {
        this.showToast(
          {
            title: `Cannot ${action} this site.`,
            description: `"${siteName}" already exists. Please try a different name.`,
          },
          true,
        );
      }
    } else {
      this.showToast(
        {
          title: 'Error',
          description: 'An error happened , please referesh and try again.',
        },
        true,
      );
    }
  }

  private showToast(message?: { title: string; description?: string }, error = false): void {
    if (!message) {
      return;
    }

    const titleIcon = error ? undefined : { svgIcon: 'check', size: 18 };
    const titleMessage = this.translateService.instant(message.title);
    const iconBackground = error ? IconBackground.ERROR : IconBackground.SUCCESS;
    const notificationType = error ? ERRORS.ACTION_FAILED : SUCCESSES.ACTION_SUCCESSFUL;

    const titleElement = new IconMessageToasterElement(
      titleIcon,
      titleMessage,
      undefined,
      undefined,
      undefined,
      iconBackground,
      true,
      true,
    );

    const descriptionMessage = message.description
      ? this.translateService.instant(message.description)
      : undefined;
    const messageElement = new IconMessageToasterElement(undefined, descriptionMessage);

    const notification = new NotificationDataBuilder(notificationType)
      .style(ToasterPopupStyle.WARN)
      .type(NotificationType.WARNING)
      .version2Notification(true)
      .width(304)
      .timeOut(5)
      .topElements([titleElement])
      .middleElements(!descriptionMessage ? [] : [messageElement])
      .showProgressBar(false)
      .toastClass('custom_toast_class')
      .dismissable(true)
      .build();

    this.notificationsService.showNotification(notification);
  }
}

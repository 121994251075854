import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { IconTypes } from '../../standalones/components/pencil-icon/pencil-icon.component';

interface Icon {
  name: string;
  type: IconTypes;
}

@Component({
  selector: 'app-confirmation-modal-v2',
  templateUrl: './confirmation-modal-v2.component.html',
  styleUrls: ['./confirmation-modal-v2.component.scss'],
})
export class ConfirmationModalV2Component {
  title: string;
  cancelButtonText: string;
  confirmButtonText: string;
  cancelButtonIcon: Icon;
  confirmButtonIcon: Icon;
  body: string;
  isDanger = false;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationModalV2Component>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.title = data.title;
    this.cancelButtonText = data.cancelButtonText || this.translateService.instant('Cancel');
    this.confirmButtonText = data.confirmButtonText || this.translateService.instant('Yes');
    this.cancelButtonIcon = data.cancelButtonIcon || {
      name: 'close',
      type: IconTypes.matIcon,
    };
    this.confirmButtonIcon = data.confirmButtonIcon || {
      name: 'check',
      type: IconTypes.matIcon,
    };
    this.body = data.body;
    this.isDanger = data.isDanger || false;
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  confirm(): void {
    this.dialogRef.close(true);
  }
}

<div *ngIf="allSessions" class="sessions-list-table-wrapper">
  <table
    id="sessions-list-table"
    [dataSource]="allSessionsTable"
    mat-table
    class="full-width-table w-100"
  >
    <ng-container matColumnDef="sessions">
      <th mat-header-cell *matHeaderCellDef>{{ 'Session Analytics' | translate }}</th>
      <td mat-cell *matCellDef="let session">
        <span [ngClass]="session.sessionId === selectedSessionId ? 'selected-title' : ''">{{
          session.parsedSessionTitle
        }}</span>
        <br />
        <div class="row">
          <div class="col-12 row-content session-duration-text">
            <span *ngIf="session.sessionLength" class="session-duration-text">{{
              session.parsedSessionLength
            }}</span>
            <span
              *ngIf="!session.sessionLength && session.status !== SessionStatusType.PROCESSING"
              class="live"
            >
              <span class="dot" style="display: inline-block"></span>
              <span class="ml-2">{{ 'Live' | translate }}</span>
            </span>
            <span style="color: #2f80ed" *ngIf="session.status === SessionStatusType.PROCESSING">
              {{ 'Processing' | translate }}
            </span>
            <span class="d-flex align-items-center" *ngIf="session.callLength > 0">
              <span class="seperator">|</span>
              <span class="material-icons row-content-icon font-12">call</span>
            </span>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="row-content col-12">
            <svg-icon
              class="row-content-icon"
              [icon]="'people_outline'"
              [width]="16"
              [height]="16"
              [maxWidth]="'auto'"
            ></svg-icon>
            <ng-container
              *ngFor="
                let user of session.attendees?.slice(
                  0,
                  session.attendees.length > 3 ? 2 : session.attendees.length
                );
                let i = index
              "
            >
              <app-lightweight-user-avatar
                class="cursor-default cml-4"
                [isUser]="!!user.userImgObject"
                [profileImage]="user.userImgObject?.profile_image"
                [userRole]="user.userImgObject?.role"
                [userEmail]="user.userImgObject?.email"
                [userName]="user.userImgObject?.name"
                [width]="AVATAR_WIDTH"
                [fontSize]="14"
                [appTooltip]="profileTooltip"
                [appTooltipPlacement]="i === 0 ? 'bottom-start' : 'bottom-center'"
              ></app-lightweight-user-avatar>
              <ng-template #profileTooltip>
                <app-tooltip
                  [text]="user.name"
                  [arrow]="'top'"
                  [tipStyle]="
                    i === 0 ? { left: WIDTH_TO_AVATAR + (i + 1) * AVATAR_WIDTH + 'px' } : {}
                  "
                ></app-tooltip>
              </ng-template>
            </ng-container>
            <div class="extra-users-container">
              <div *ngIf="session.attendees?.length > 3" class="user-counts cml-4">
                {{ '+' + (session.attendees?.length - 2) }}
              </div>
              <div class="tooltip-grandparent-container">
                <div class="tooltip-parent-container">
                  <div class="tooltip-content">
                    <div
                      class="space-user"
                      *ngFor="let user of session.attendees | slice: 2:session.attendees?.length"
                    >
                      <app-lightweight-user-avatar
                        class="cursor-default cml-4"
                        [isUser]="!!user.userImgObject"
                        [profileImage]="user.userImgObject?.profile_image"
                        [userRole]="user.userImgObject?.role"
                        [userEmail]="user.userImgObject?.email"
                        [userName]="user.userImgObject?.name"
                        [width]="26"
                        [fontSize]="14"
                      ></app-lightweight-user-avatar>
                      <div class="space-user-name">{{ user.name ?? user.email }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </ng-container>
    <tr
      mat-row
      class="sessionRow"
      #currRow
      id="row_{{ session.sessionId }}"
      *matRowDef="let session; columns: displayedColumns"
      (click)="handleCurrentSession(session.sessionId)"
      [ngClass]="session.sessionId === selectedSessionId ? 'active_session' : null"
    ></tr>
  </table>
  <div class="spinner-container" *ngIf="fetchingMoreSessions$ | async">
    <mat-spinner
      id="fetchingSessionsSpinner"
      [color]="'#2f80ed'"
      [strokeWidth]="3"
      [diameter]="30"
    ></mat-spinner>
  </div>
</div>
<div class="sessions-list-table-wrapper" *ngIf="!allSessions">{{ 'Loading' | translate }}...</div>

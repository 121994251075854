import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import * as FontFaceObserver from 'fontfaceobserver';

export enum ViewType {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

export enum LocalDirection {
  LTR = 'ltr',
  RTL = 'rtl',
}

export const emojiLibraryIndex = {
  THUMBS_UP: 0,
  THUMBS_DOWN: 1,
  VICTORY_HANDS: 9,
  HAND_RAISED: 10,
};

export const emojiLibrary = [
  '/assets/icons/emojis/thumbs-up.png',
  '/assets/icons/emojis/thumbs-down.png',
  '/assets/icons/emojis/party-popper.png',
  '/assets/icons/emojis/fire.png',
  '/assets/icons/emojis/clapping-hands.png',
  '/assets/icons/emojis/red-heart.png',
  '/assets/icons/emojis/raising-hands.png',
  '/assets/icons/emojis/folded-hands.png',
  '/assets/icons/emojis/eyes.png',
  '/assets/icons/emojis/victory_hands.png',
  '/assets/icons/emojis/face_tears_of_joy.png',
];

@Injectable({
  providedIn: 'root',
})
export class UiService {
  MOBILE_MAX_WIDTH = 693;
  MOBILE_SINGLE_VIDEO_TILE_SIZE = 320;
  MOBILE_DOUBLE_VIDEO_TILE_SIZE = 280;
  MOBILE_MULTIPLE_VIDEO_TILE_SIZE = 180;
  TABLET_MIN_WIDTH = 694;
  BROWSER_MIN_WIDTH = 700;
  BROWSER_MIN_HEIGHT = 550;
  isMobile = new BehaviorSubject(false);

  constructor(private titleService: Title, private translateService: TranslateService) {}

  setTabTitle(tabName: string, translate = true): void {
    if (translate) {
      this.titleService.setTitle(`${this.translateService.instant(tabName)} | Pencil`);
    } else {
      this.titleService.setTitle(`${tabName} | Pencil`);
    }
  }

  async loadFonts() {
    // load fonts as space page is loaded ( custom fonts )
    const fonts = [
      new FontFaceObserver('Jost').load(),
      new FontFaceObserver('Poppins').load(),
      new FontFaceObserver('Virgil').load(),
      new FontFaceObserver('OpenDyslexic').load(),
    ];
    return Promise.all(fonts)
      .then(() => true)
      .catch(() => false);
  }
}

import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user';
import { ChatOptions } from '../chat/chat.component';

export interface ChatMenuOption {
  icon: string;
  title: string;
  type: ChatOptions;
  unreadNotifcation?: boolean;
  live?: boolean;
}

export enum TranscriptEvent {
  transcriptStatus = 'transcriptStatus',
}
export interface ChatTopProp {
  option: ChatOptions;
  user: User | undefined;
}
interface ChatProps {
  activeChatTopSection: ChatTopProp | null;
  activeChat: boolean;
  unreadPublicChat: boolean;
  transcriptionEnabled: boolean;
  isSpaceOwner: boolean;
}

const chatUiStore = createStore(
  { name: 'chat' },
  withProps<ChatProps>({
    activeChatTopSection: null,
    activeChat: false,
    unreadPublicChat: false,
    transcriptionEnabled: false,
    isSpaceOwner: false,
  }),
);

@Injectable()
export class ChatRepository {
  get publicChatUnreadMessage$(): Observable<boolean> {
    return chatUiStore.pipe(select((state) => state.unreadPublicChat));
  }

  get chatMenuTopHeader$(): Observable<ChatTopProp | null> {
    return chatUiStore.pipe(select((state) => state.activeChatTopSection));
  }

  get isActiveChat$(): Observable<boolean> {
    return chatUiStore.pipe(select((state) => state.activeChat));
  }

  get isSpaceOwner$(): Observable<boolean> {
    return chatUiStore.pipe(select((state) => state.isSpaceOwner));
  }

  setChatUnreadMessage(val: boolean) {
    chatUiStore.update((state) => ({ ...state, unreadPublicChat: val }));
  }

  updateChatMenuTopHeader(val: ChatTopProp | null) {
    chatUiStore.update((state) => ({ ...state, activeChatTopSection: val }));
  }

  resetChatMenuTopHeader() {
    chatUiStore.update((state) => ({ ...state, activeChatTopSection: null }));
  }

  setActiveChat(val: boolean) {
    chatUiStore.update((store) => ({ ...store, activeChat: val }));
  }
  resetActiveChat() {
    chatUiStore.update((store) => ({ ...store, activeChat: false }));
  }

  setSpaceOwner(val: boolean) {
    chatUiStore.update((store) => ({ ...store, isSpaceOwner: val }));
  }
}

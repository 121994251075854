import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionSharedDataService } from 'src/app/services/session-shared-data.service';
import { NotificationToasterService } from 'src/app/services/notification-toaster.service';
import { WAITING_ROOM } from 'src/app/common/utils/notification-constants';
import { ToastrService } from 'ngx-toastr';
import { AclService } from 'src/app/services/acl.service';
import { RequestAccessNotificationBaseService } from '../base/request-access-notifications-base.service';

@Injectable({
  providedIn: 'root',
})
export class WaitingRoomNotificationService extends RequestAccessNotificationBaseService {
  protected notificationCode: string;
  protected warningCode: string;
  protected errorCode: string;

  protected acceptButtontext: string;
  protected acceptAllButtontext: string;
  protected singleRequestNotificationText: string;
  protected multiRequestsNotificationText: string;
  protected msg: string;

  constructor(
    protected sessionSharedDataService: SessionSharedDataService,
    protected translateService: TranslateService,
    protected notificationToasterService: NotificationToasterService,
    protected toastr: ToastrService,
    protected aclService: AclService,
  ) {
    super(
      sessionSharedDataService,
      translateService,
      notificationToasterService,
      toastr,
      aclService,
    );

    this.notificationCode = WAITING_ROOM.WAITING_ROOM_REQUEST_NOTIFICATION;
    this.warningCode = WAITING_ROOM.WAITING_ROOM_WARNING;
    this.errorCode = WAITING_ROOM.WAITING_ROOM_ERROR;

    this.acceptButtontext = this.translateService.instant('Admit');
    this.acceptAllButtontext = this.translateService.instant('Admit All');
    this.singleRequestNotificationText = this.translateService.instant('is in the Waiting Room');
    this.multiRequestsNotificationText = this.translateService.instant(
      'users are in the Waiting Room',
    );
    this.msg = this.translateService.instant('You can review them in Participant Manager.');
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UiService } from 'src/app/services/ui.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs';
import { DomListenerFactoryService } from 'src/app/services/dom-listener-factory.service';
import { DeviceAndBrowserDetectorService } from 'src/app/services/device-and-browser-detector.service';
@UntilDestroy()
@Component({
  selector: 'app-first-time-request-devices-permission-guide',
  templateUrl: './first-time-request-devices-permission-guide.component.html',
  styleUrls: ['./first-time-request-devices-permission-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstTimeRequestDevicesPermissionGuideComponent implements OnDestroy {
  @Output() requestPermission: EventEmitter<void> = new EventEmitter();
  @Output() skipRequestPermission: EventEmitter<void> = new EventEmitter();

  isMobile = this.uiService.isMobile;
  showDefaultGraphics: BehaviorSubject<boolean>;
  allowAccessGraphicsSrc = `assets/imgs/${this.deviceDetectorService.browser.toLowerCase()}-allow-permissions.gif`;

  isiOSOS =
    this.deviceAndBrowserDetectorService.isiOSiPhone() ||
    this.deviceAndBrowserDetectorService.isiOSiPad();
  isAndroidOS =
    this.deviceAndBrowserDetectorService.isAndroidPhone() ||
    this.deviceAndBrowserDetectorService.isAndroidTablet();
  isPhysicalDesktop =
    this.deviceDetectorService.isDesktop() &&
    !this.deviceAndBrowserDetectorService.isTabletDevice();
  isTablet =
    this.deviceDetectorService.isTablet() || this.deviceAndBrowserDetectorService.isTabletDevice();
  isiOSiPhone = this.deviceAndBrowserDetectorService.isiOSiPhone();
  isFirefox = this.deviceAndBrowserDetectorService.isFirefox();
  domListener = this.domListenerFactoryService.createInstance();
  notFitAllSteps = new BehaviorSubject<boolean>(false);
  // Used to support steps scrolling on touch devices
  touchStartX = 0;
  distanceX = 0;

  constructor(
    private uiService: UiService,
    public deviceDetectorService: DeviceDetectorService,
    private domListenerFactoryService: DomListenerFactoryService,
    private deviceAndBrowserDetectorService: DeviceAndBrowserDetectorService,
  ) {
    this.setupAllowAccessGraphicsSrc();

    this.showDefaultGraphics = new BehaviorSubject<boolean>(this.isShowDefaultGraphics());
  }

  private setupAllowAccessGraphicsSrc() {
    if (this.isiOSOS) {
      this.allowAccessGraphicsSrc = 'assets/imgs/ios_allow_access.gif';
    } else if (this.isAndroidOS) {
      this.allowAccessGraphicsSrc = 'assets/imgs/android_allow_access.gif';
    }
  }

  ngOnDestroy(): void {
    this.domListener.clear();
  }

  onRequestPermissions() {
    this.requestPermission.emit();
  }

  onMaybeLater() {
    this.skipRequestPermission.emit();
  }

  liveSupport() {
    (window as any).Intercom('showSpace', 'home');
  }

  private isShowDefaultGraphics() {
    if (!this.isPhysicalDesktop) {
      return false;
    }
    const browsersNewGraphics = ['chrome', 'safari'];
    return !browsersNewGraphics.reduce(
      (accumlator, browser) =>
        accumlator || this.deviceDetectorService.browser.toLowerCase() === browser,
      false,
    );
  }
}

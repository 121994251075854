import { ADT } from 'ts-adt';
import * as Sentry from '@sentry/browser';
import { debounce } from 'ts-debounce';
import { v4 as uuidv4 } from 'uuid';
import { FLAGS, FlagsService } from 'src/app/services/flags.service';
import {
  DEFAULT_SCREEN_SHARE_CODEC,
  DEFAULT_SCREEN_SHARE_MAX_BITRATE,
  DEFAULT_SCREEN_SHARE_MAX_FPS,
  DEFAULT_SCREEN_SHARE_VIDEO_HEIGHT,
  DEFAULT_SCREEN_SHARE_VIDEO_WIDTH,
  OpenDeviceSelectionParams,
} from 'src/app/models/device-manger';
import { isFinite, isString } from 'lodash';
import {
  LocalNetworkStatsScore,
  NetworkStatsReportScore,
} from 'src/app/utilities/webrtc-stats-reporter/webrtc-stats-reporter-types';
import { NoiseCancellationConfigurationService } from 'src/app/services/noise-cancellation-configuration.service';
import { TranslateService } from '@ngx-translate/core';
import {
  IconBackground,
  IconMessageToasterElement,
} from 'src/app/ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from 'src/app/services/notification-toaster.service';
import { ToasterPopupStyle } from 'src/app/ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { TelemetryService } from 'src/app/services/telemetry.service';
import {
  ButtonToasterElement,
  ButtonToasterElementStyle,
} from 'src/app/ui/notification-toaster/button-toaster-element/button-toaster-element.component';
import { Subject } from 'rxjs';
import { RtcServiceController } from '../../services/rtc.service';
import { RECORDING_ERROR } from '../../models/recording';
import { ERRORS } from '../utils/notification-constants';
import { DEFAULT_DEVICE_ID } from '../utils/devices-handle-util';

// Should be in sync with BE CallProviders enum as it is passed in API calls to specify
// what call provider is being used.
export enum CallProvider {
  DAILY = 'daily',
  LIVEKIT = 'livekit',
}

export const DEFAULT_PROVIDER: CallProvider = CallProvider.DAILY;

export enum ParticipantEventAction {
  JOINED = 'joined',
  UPDATED = 'updated',
  LEFT = 'left',
}

export enum TrackEventAction {
  START = 'start',
  STOP = 'stop',
}

export enum TrackType {
  VIDEO = 'video',
  AUDIO = 'audio',
  SCREEN = 'screenVideo',
  SCREEN_AUDIO = 'screenAudio',
}

export enum NetworkEventAction {
  QUALITY_CHANGED = 'network-quality-change',
}

export enum NetworkThreshold {
  GOOD = 'Good',
  LOW = 'Low',
  VERY_LOW = 'Very low',
}

export enum SubscribeCallQuality {
  LOW,
  MEDIUM,
  HIGH,
}

export enum CallContext {
  // If the call is occurring within a whiteboard session
  SESSION,
}

// Granular track states that are used by daily.co for better handling of tracks
// Hidden: This is not a real track state, but it is something we are introducing to denote that a track exists
//         but it should be ignored in the UI and a tile should not be shown for it
export type TrackState =
  | 'blocked'
  | 'off'
  | 'sendable'
  | 'loading'
  | 'interrupted'
  | 'playable'
  | 'hidden';
/**
 * Generic participant object to be used across RTC services.
 */
export interface CallParticipant {
  participantId: string;
  userId: string;
  name?: string;
  local?: boolean;
  video: TrackState;
  audio: TrackState;
  screen: TrackState;
  screenAudio: TrackState;
  videoTrack: MediaStreamTrack | undefined;
  audioTrack: MediaStreamTrack | undefined;
  screenVideoTrack: MediaStreamTrack | undefined;
  screenAudioTrack: MediaStreamTrack | undefined;
  joinedAt: Date | undefined;
  networkStatsScore?: NetworkStatsReportScore;
}

export interface CallEvent {
  participant: CallParticipant;
}

/**
 * Generic participant event interface that defines the action taken by the participant
 * and the updated participant object.
 */
export interface ParticipantEvent extends CallEvent {
  action: ParticipantEventAction;
}

export type RecordingEvent = ADT<{
  STARTED: {
    // The daily participant id of the user who started the recording
    initiatorParticipantId: string | undefined;
  };
  STOPPED: {
    err?: {
      type: RECORDING_ERROR;
      msg: string;
    };
  };
}>;

export interface TranscriptResult {
  is_final: boolean;
  session_id: string;
  text: string;
  timestamp: Date;
  user_id: string;
}

/**
 * Generic track event interface that defines the action applied by the track, the
 * type of track being updated, and the updated participant object.
 */
export interface TrackEvent extends CallEvent {
  action: TrackEventAction;
  type: TrackType;
}

/**
 * Generic network event interface that defines the network status for participants
 */
export interface NetworkEvent {
  action: NetworkEventAction;
  // An assessment of the current network quality
  threshold?: NetworkThreshold;
  // Subjective calculation of the current network quality on a scale of 1-100
  quality?: number;
}

export interface RTCDevices {
  cameras: MediaDeviceInfo[];
  mics: MediaDeviceInfo[];
  speakers: MediaDeviceInfo[];
  activeCameraId: string | null;
  activeMicId: string | null;
  activeSpeakerId: string | null;
}

export interface ActiveRTCDevices {
  cameraId?: string;
  micId?: string;
  speakerId?: string;
}

export enum CallError {
  DISCONNECTED = 'disconnected',
  SERVICEDOWN = 'servicedown',
  TIME_OUT = 'timeout',
}

export enum VirtualBackgroundType {
  NONE = 'none',
  BACKGROUND_BLUR = 'background-blur',
  BACKGROUND_IMAGE = 'background-image',
}

export interface RoomAuth {
  roomId: string | null;
  token: string | null;
}

export interface ActiveSpeaker {
  speakerId: string;
  timestmap: number;
}

export interface RTCRoomCreationOpts {
  skipRoomCreation: boolean;
  skipGenerateToken: boolean;
}

export type ScreenShareOptions = Partial<{
  preferCurrentTab: boolean;
  restrictToWhiteboard: boolean;
}>;

interface ObservableMediaElement extends HTMLVideoElement {
  handleResize?: (entry: ResizeObserverEntry) => void;
}
const REACTION_DELAY = 100;
const DEFAULT_PLAY_LOCAL_VIDEO_TIMEOUT_IN_SECS = 15;
const PLAY_LOCAL_VIDEO_TIMEOUT_ERROR_MESSAGE = 'PlayLocalVideoTimeout';

export const HIDE_TRACK_SYMBOL = 'hide-track';

export abstract class RTCInterface {
  private _openDeviceSelection$ = new Subject<OpenDeviceSelectionParams>();
  private _playLocalVideoTimeout$ = new Subject<void>();

  abstract isScreenShareSimulcastEnabled: boolean;
  abstract readonly provider: CallProvider;

  protected loadAuthCurrentRequestId = 0;

  private _isMusicModeEnabled = false;

  private _playLocalVideoTimeout =
    (this.flagsService.featureFlagsVariables?.play_video_settings
      ?.play_local_video_timeout as number) || DEFAULT_PLAY_LOCAL_VIDEO_TIMEOUT_IN_SECS;

  set isMusicModeEnabled(isMusicModeEnabled: boolean) {
    this._isMusicModeEnabled = isMusicModeEnabled;
  }

  get isMusicModeEnabled(): boolean {
    return this._isMusicModeEnabled;
  }

  private _isNoiseCancellationEnabled = true;

  set isNoiseCancellationEnabled(isNoiseCancellationEnabled: boolean) {
    if (this.isNoiseCancellationEnabled === isNoiseCancellationEnabled) {
      return;
    }

    this._isNoiseCancellationEnabled = isNoiseCancellationEnabled;

    if (this._isNoiseCancellationEnabled) {
      this.enableNoiseCancellation();
    } else {
      this.disableNoiseCancellation();
    }
  }

  get isNoiseCancellationEnabled(): boolean {
    return this._isNoiseCancellationEnabled;
  }

  // to prevent changing the quality by any other event (resize, network changes...)
  private preventIncomingVideoQualityChange: boolean = false;

  private readonly captureHandle = uuidv4();
  // Used to detect updates on the inserted video elements that hold vidoe/screen share streams
  // to subscribe to the best resolution fits for the current width
  private resizeObserver?: ResizeObserver;
  private getResizeObserver(): ResizeObserver {
    if (!this.resizeObserver) {
      this.resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        for (const entry of entries) {
          (entry.target as ObservableMediaElement).handleResize?.(entry);
        }
      });
    }
    return this.resizeObserver;
  }

  constructor(
    public flagsService: FlagsService,
    private noiseCancellationConfigurationService: NoiseCancellationConfigurationService,
    protected telemetry: TelemetryService,
    private translateService: TranslateService,
    private notificationToasterService: NotificationToasterService,
  ) {
    this.setCaptureHandle();
  }

  /**
   * Returns auth data used to join the current room
   * Object type depends on the service that is being used
   */
  abstract getAuthData(): any;
  /**
   * Sets auth data used to join a room
   * Useful if we store authData somewhere so we don't need to fetch it with .loadAuth every time
   */
  abstract setAuthData(authData: any): void;
  /**
   * Clears latest fetched auth token
   * It's more used by breakout rooms scenarios
   */
  abstract clearRoomAuthToken(): void;
  /**
   * Returns true if we fetched a valid auth token
   */
  abstract doesAuthTokenExist(): boolean;
  /**
   * Joins the current call using the room token from .loadAuth or .setAuthData
   */
  abstract join(forceSettingInputDeviceOnJoining?: boolean): Promise<void>;
  /**
   * Leaves the existing call.
   * Disconnect the global resize observer once local user is leaving the call
   */
  async leave(): Promise<void> {
    this.getResizeObserver().disconnect();
  }
  /**
   * Gets auth data for the given call uid and stores it so we can loadAuth at any time
   */
  abstract loadAuth(
    spaceId: string,
    breakoutRoomId: string | undefined,
    context: CallContext,
    isExtendExp?: boolean,
    opts?: RTCRoomCreationOpts,
  ): Promise<void>;

  abstract publishAndStartBroadcastingVideoStream(
    mediaStreamTrack: MediaStreamTrack,
  ): Promise<void>;

  abstract startBroadcastingVideoStream(mediaStreamTrack: MediaStreamTrack): Promise<void>;
  abstract startBroadcastingAudioStream(mediaStreamTrack: MediaStreamTrack): Promise<void>;

  abstract enableNoiseCancellation(): Promise<void>;
  abstract disableNoiseCancellation(): Promise<void>;

  abstract stopBroadcastingVideoStream(): Promise<void>;
  abstract stopBroadcastingAudioStream(): Promise<void>;

  abstract setLocalVideoCallStatsMetadata(localNetworkStatsScore: LocalNetworkStatsScore): void;
  /**
   * Start broadcasting screen share and sets the participant's screenShare variable to be true.
   * Accepts an onEnd method that returns a Promise<void> to be called when the screen share is ended.
   * It will be called whether it is ended by service.stopScreenShare() or native browser interfaces.
   * Accepts an onError method to handle any error while starting screenShare
   */
  abstract startScreenShare(
    options?: ScreenShareOptions,
    onEnd?: () => Promise<void>,
    onError?: (error: any) => Promise<void>,
  ): Promise<void>;
  /**
   * Stop broadcasting screen share and sets the participant's screenShare variable to be false.
   */
  abstract stopScreenShare(): Promise<void>;
  /**
   * Returns true if we are connected to a call and false otherwise.
   */
  abstract isConnected(): boolean;
  /**
   * Returns true if we are connecting to a call and false otherwise.
   */
  abstract isConnecting(): boolean;
  /**
   * Returns true if we are disconnecting to a call and false otherwise.
   */
  abstract isDisconnecting(): boolean;
  /**
   * Returns all participants in a call in an object with IDs as keys. Local user should have ID 'local'
   * Can be used to get initial state but updated should be handled with trackEvents and participantEvents
   * since using getParticipants to get updated participants may cause race conditions (i.e. how RTC provider handles it)
   */
  abstract getParticipants(): { [key: string]: CallParticipant };

  /**
   * return the number of participants in the call (including local participant)
   */
  abstract getNumberOfParticipants(): number;

  /**
   * Transforms the RTC provider service's participant object to CallParticipant object
   */
  abstract toCallParticipant(participant: any): CallParticipant;
  /**
   * Sets quality of incoming streams (Video/screen share) to low, medium, or high.
   */
  abstract setStreamQuality(
    participantId: string,
    type: TrackType,
    quality: SubscribeCallQuality,
  ): Promise<void>;
  /**
   * Sets speakers for RTC using deviceId. Currently active media tracks also switch to using the new devices
   * Used only to set speaker device as we are handling local tracks management.
   * So no need to set cam/mic device IDs with the RTC provider
   * @TODO [Elwakeel] change method name to setSpeakerDevice
   */
  abstract setDevices(devices: ActiveRTCDevices): Promise<void>;
  /**
   * Get the current input devices that the service is currently using.
   */
  abstract getCurrentInputDevices(): Promise<ActiveRTCDevices>;
  /*
   * subscribe to tracks individually
   * note: the underlying assumption is that another event will be sent once subscribed
   */
  abstract subscribe(participantId: string, trackType: TrackType): void;
  /**
   * Used to subscribe to the current invisible tiles
   */
  abstract unsubscribe(participantId: string, trackType: TrackType): void;
  /**
   * Introduce an intermediate state for not fully unsubscribing from streams
   * It might not be supported by all RTC providers
   */
  abstract stage(participantId: string, trackType: TrackType): void;
  /**
   * @TODO [Elwakeel] deprecate this method as it's being used by the old service for handling tracks events
   */
  abstract isSubscribed(participantId: string, trackType: TrackType): boolean | string;
  /**
   * Used to get the current status of cam/mic
   */
  abstract isCameraEnabled(): boolean;
  abstract isMicEnabled(): boolean;
  /**
   * Used to control transcriptions status
   */
  abstract startTranscription(): void;
  abstract stopTranscription(): void;

  /**
   * Used to control the outgoing video (cam) encoding layers
   */
  abstract lowerOutgoingVideoQuality(): void;
  abstract resetOutgoingVideoQuality(): void;

  /**
   * Used to check if the provider support noise cancellation or not
   */
  protected abstract doesProviderSupportNoiseCancellation(): boolean;

  /**
   * Used to check if noise cancellation supported or not
   */
  isNoiseCancellationSupported(): boolean {
    return (
      this.noiseCancellationConfigurationService.isEnabled(this.provider) &&
      this.doesProviderSupportNoiseCancellation()
    );
  }

  /**
   * Attachs video to a html video element
   * Sets a listener on the video player element to detect dimensions updates to subscribe the correct layer
   */
  async attachVideo(
    videoPlayer: HTMLVideoElement,
    track: MediaStreamTrack,
    participant: CallParticipant,
    trackType: TrackType,
  ): Promise<void> {    
    this.playVideo(videoPlayer, track, participant, trackType);
  }

  private async playVideoWithTimeout(videoPlayer: HTMLVideoElement, participant: CallParticipant){
    const playPromise = videoPlayer.play();
    let isPromiseResolved = false;
    const timeoutPromise = new Promise<never>((_, reject) => {
      setTimeout(() => {
        if(isPromiseResolved) {
          return;
        }
        this.telemetry.event('[Playing Video Event]', {
          action: 'play video timeout',
          participantId: participant.participantId,
          isLocalParticipant: participant.local
        });
        if(participant.local) {
          // only throw error if we try to play the local video
          reject(new Error(PLAY_LOCAL_VIDEO_TIMEOUT_ERROR_MESSAGE));
        }
      }, this._playLocalVideoTimeout * 1000);
    });
    try {
      await Promise.race([playPromise, timeoutPromise]);
    } finally {
      isPromiseResolved = true;
    }
  }

  protected dismissVideoCallNotifications() {
    this.notificationToasterService.dismissNotificationsByCode([
      ERRORS.PLAY_LOCAL_VIDEO_TIMEOUT,
      ERRORS.FAILED_TOGGLING_DEVICE,
    ]);
  }

  private async playVideo(videoPlayer: HTMLVideoElement,
    track: MediaStreamTrack,
    participant: CallParticipant,
    trackType: TrackType,) {
    try {
      videoPlayer.srcObject = new MediaStream([track]);
      await this.playVideoWithTimeout(videoPlayer, participant);
      this.setupResizeObserverForPlayingVideoPlayer(videoPlayer, participant, trackType);
      if (participant.local) {
        this.notificationToasterService.dismissNotificationsByCode([
          ERRORS.PLAY_LOCAL_VIDEO_TIMEOUT,
        ]);
      }
    } catch (error) {
      // Used to avoid a bug on Safari using iPhone/iPad/Mac related to play a video while the video element is out of viewport
      // As video will be played normally even if there is a caught error
      // More context: https://bugs.webkit.org/show_bug.cgi?id=243519
      if (error?.name === 'AbortError') {
        this.setupResizeObserverForPlayingVideoPlayer(videoPlayer, participant, trackType);
      } else if (error.message === PLAY_LOCAL_VIDEO_TIMEOUT_ERROR_MESSAGE) {
        this._playLocalVideoTimeout$.next();
        this.showPlayLocalVideoTimeoutNotification();
      }
      Sentry.captureException(new Error('Error playing video'), {
        extra: {
          err: error,
          message: error.message,
          name: error.name,
        },
      });
    }
  }

  showPlayLocalVideoTimeoutNotification() {
    const titleElement = new IconMessageToasterElement(
      { svgIcon: 'cam_off_notification_icon' },
      this.translateService.instant('Could not start camera'),
      undefined,
      undefined,
      undefined,
      IconBackground.ERROR,
      true,
      true,
    );
    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(
        'Please try a different camera in A/V settings or contact Live Support.',
      ),
    );

    const confirmButtonElement = new ButtonToasterElement(
      [{ icon: 'settings', size: 16 }, this.translateService.instant('A/V Settings')],
      {
        handler: () => {
          if (this.isConnected()) {
            this._openDeviceSelection$.next({});
          }
        },
        close: true,
      },
      ButtonToasterElementStyle.PRIMARY,
    );

    const cancelButtonElement = new ButtonToasterElement(
      [{ icon: 'help_outline', size: 16 }, this.translateService.instant('Live Support')],
      {
        handler: () => {
          (window as any).Intercom('showSpace', 'home');
        },
        close: true,
      },
      ButtonToasterElementStyle.SECONDARY,
    );

    const notificationData = new NotificationDataBuilder(ERRORS.PLAY_LOCAL_VIDEO_TIMEOUT)
      .style(ToasterPopupStyle.WARN)
      .type(NotificationType.WARNING)
      .width(304)
      .topElements([titleElement])
      .middleElements([messageElement])
      .bottomElements([cancelButtonElement, confirmButtonElement])
      .version2Notification(true)
      .dismissable(true)
      .showProgressBar(false)
      .build();
    this.notificationToasterService.showNotification(notificationData);
  }

  openDeviceSelection$() {
    return this._openDeviceSelection$.asObservable();
  }

  playLocalVideoTimeout$() {
    return this._playLocalVideoTimeout$.asObservable();
  }

  /**
   * Attach the audio element to the call library to be able to manipulate the output device
   */
  abstract attachAudioElement(
    participant: CallParticipant,
    trackType: TrackType,
    element: HTMLAudioElement,
  ): void;

  /**
   * Detach this track from this video element
   */
  async detachVideo(videoPlayer: HTMLVideoElement): Promise<void> {
    this.getResizeObserver()?.unobserve(videoPlayer);
    videoPlayer.pause();
    videoPlayer.removeAttribute('src'); // empty source
    videoPlayer.load();
  }
  /**
   * Detach this track from this video element on participant is leaving.
   * So, it's safe to clear handleResize to avoid any possible memory leak
   */
  async detachVidoeOnParticipantLeave(videoPlayer: HTMLVideoElement): Promise<void> {
    this.detachVideo(videoPlayer);
    (videoPlayer as ObservableMediaElement).handleResize = undefined;
  }

  private setupResizeObserverForPlayingVideoPlayer(
    videoPlayer: HTMLVideoElement,
    participant: CallParticipant,
    trackType: TrackType,
  ) {
    // Increment the ref because one component needs the track
    // Specifically for video tracks, setup simulcast layer changes based on changes to size of the video player.
    if (
      trackType === TrackType.VIDEO ||
      (this.isScreenShareSimulcastEnabled && trackType === TrackType.SCREEN)
    ) {
      if (!(videoPlayer as ObservableMediaElement).handleResize) {
        (videoPlayer as ObservableMediaElement).handleResize = debounce(
          async (ro: ResizeObserverEntry) => {
            await this.updateCallQualityBasedOnContainerWidth(
              ro.contentRect.width,
              participant,
              trackType,
            );
          },
          REACTION_DELAY,
        );
      }
      this.getResizeObserver().observe(videoPlayer);
    }
  }

  protected async updateCallQualityBasedOnContainerWidth(
    width: number,
    participant: CallParticipant,
    type: TrackType,
  ): Promise<void> {
    if (this.preventIncomingVideoQualityChange || participant.local) {
      return;
    }
    if (width === 0) {
      return;
    }
    let quality = SubscribeCallQuality.LOW;
    if (width >= 640) {
      quality = SubscribeCallQuality.MEDIUM;
    }
    if (width >= 1080) {
      quality = SubscribeCallQuality.HIGH;
    }
    await this.setStreamQuality(participant.participantId, type, quality);
  }

  public lowerIncomingVideoQuality(forcedByPerformance = false): void {
    Object.values(this.getParticipants()).forEach((participant) =>
      this.setStreamQuality(participant.userId, TrackType.VIDEO, SubscribeCallQuality.LOW),
    );
    this.preventIncomingVideoQualityChange = forcedByPerformance;
  }

  public resetIncomingVideoQuality(): void {
    Object.values(this.getParticipants()).forEach((participant) => {
      const videoPlayer = RtcServiceController.getVideoElement(
        participant.participantId,
        TrackType.VIDEO,
      );
      if (!videoPlayer) {
        return false;
      }
      this.updateCallQualityBasedOnContainerWidth(
        videoPlayer.offsetWidth,
        participant,
        TrackType.VIDEO,
      );
    });
    this.preventIncomingVideoQualityChange = false;
  }

  protected getSelectedSpeakerDeviceId(): string | undefined {
    const speakerId = localStorage.getItem('defaultSpeakerId');
    if (speakerId && speakerId !== DEFAULT_DEVICE_ID) {
      return speakerId;
    }
  }

  private setCaptureHandle() {
    try {
      if (!navigator.mediaDevices.setCaptureHandleConfig) {
        console.warn('setCaptureHandleConfig is not supported');
        return;
      }
      navigator.mediaDevices.setCaptureHandleConfig({
        handle: this.captureHandle,
        permittedOrigins: ['*'],
      });
    } catch (err) {
      console.warn('Failed to set capture handle', err);
    }
  }

  protected async setupWhiteboardRestrictionListener(mediaStreamTrack: MediaStreamTrack) {
    if (!mediaStreamTrack) {
      return;
    }

    if (!mediaStreamTrack.getCaptureHandle) {
      console.warn('getCaptureHandle could not be found on the mediaStreamTrack');
      return;
    }

    // If the tab being shared is the current tab then restrict the screenshare
    if (this.isScreenshareLocal(mediaStreamTrack)) {
      await this.restrictTrack(mediaStreamTrack, document.getElementById('app-wb-canvas'));
    }
  }

  isScreenshareLocal(mediaStreamTrack: MediaStreamTrack) {
    return mediaStreamTrack.getCaptureHandle?.()?.handle === this.captureHandle;
  }

  protected isAuthValid(roomAuth: RoomAuth | null, spaceId: string, breakoutRoomId?: string) {
    return (
      roomAuth?.roomId &&
      roomAuth?.roomId === this.constructRoomIdInRoomAuth(spaceId, breakoutRoomId)
    );
  }

  /**
   * this pattern should match with how we define the room name in BE depending on the call provider
   */
  protected abstract constructRoomIdInRoomAuth(spaceId: string, breakoutRoomId?: string): string;

  private async restrictTrack(mediaStreamTrack: MediaStreamTrack, element: HTMLElement | null) {
    if (!mediaStreamTrack?.restrictTo) {
      console.warn('restrictTo could not be found on the mediaStreamTrack');
      return;
    }

    if (!window.RestrictionTarget) {
      console.warn('RestrictionTarget is not enabled');
      return;
    }

    const restrictionTarget = element ? await window.RestrictionTarget?.fromElement(element) : null;
    await mediaStreamTrack.restrictTo(restrictionTarget);
  }

  protected get screenShareCaptureWidth(): number {
    return this.getScreenShareSettingParam('captue_width', DEFAULT_SCREEN_SHARE_VIDEO_WIDTH);
  }

  protected get screenShareCaptureHeight(): number {
    return this.getScreenShareSettingParam('capture_height', DEFAULT_SCREEN_SHARE_VIDEO_HEIGHT);
  }

  protected get screenShareEncodingMaxBitrate(): number {
    return this.getScreenShareSettingParam('maxBitrate', DEFAULT_SCREEN_SHARE_MAX_BITRATE) * 1000;
  }

  protected get screenShareEncodingMaxFPS(): number {
    return this.getScreenShareSettingParam('maxFPS', DEFAULT_SCREEN_SHARE_MAX_FPS);
  }

  protected get screenShareEncodingCodec(): string {
    if (!this.flagsService.isFlagEnabled(FLAGS.SCREEN_SHARE_SETTINGS)) {
      return DEFAULT_SCREEN_SHARE_CODEC;
    }

    const codec = this.flagsService.featureFlagsVariables?.screen_share_settings?.codec as string;

    if (!codec || !isString(codec)) {
      return DEFAULT_SCREEN_SHARE_CODEC;
    }

    return codec;
  }

  private getScreenShareSettingParam(settingName: string, defaultValue: number): number {
    if (!this.flagsService.isFlagEnabled(FLAGS.SCREEN_SHARE_SETTINGS)) {
      return defaultValue;
    }

    const settingValueInOptimizely = this.flagsService.featureFlagsVariables
      ?.screen_share_settings?.[settingName] as number;

    if (!settingValueInOptimizely || !isFinite(settingValueInOptimizely)) {
      return defaultValue;
    }

    return settingValueInOptimizely;
  }
}

export abstract class RTCCloudRecording {
  abstract startRecording(): Promise<void>;
  abstract stopRecording(): Promise<void>;

  static isRecordable(service: RTCInterface | RTCCloudRecording): service is RTCCloudRecording {
    return 'startRecording' in service && 'stopRecording' in service;
  }
}

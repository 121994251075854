import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

export enum DateFormatEnum {
  MICROTIME = 'microTime',
  SHORTTIME = 'shortTime',
  MICRO = 'micro',
  MINI = 'mini',
  SHORT = 'short',
  MEDIUM = 'medium',
  LONG = 'long',
  FULL = 'full',
  MEDIUMDATE = 'mediumDate',
  LONGDATE = 'longDate',
  LONGDATE2 = 'longDate2',
  LONGDATE_TIME = 'longDateTime',
  FULLDATE = 'fullDate',
  SHORTDATE = 'shortDate',
  SHORTYEAR = 'shortYear',
}

@Pipe({
  name: 'dateLocale',
})
export class DateLocalePipe implements PipeTransform {
  transform(date: any, format?: string | DateFormatEnum): string {
    const timezone = moment.tz.guess();
    switch (format) {
      case DateFormatEnum.MICROTIME:
        return moment(date).tz(timezone).format('h:mm');
      case DateFormatEnum.SHORTTIME:
        return moment(date).tz(timezone).format('LT');
      case DateFormatEnum.MICRO:
        return moment(date).tz(timezone).format('DD');
      case DateFormatEnum.MINI:
        return moment(date).tz(timezone).format('MMMM D');
      case DateFormatEnum.SHORT:
        return moment(date).tz(timezone).format('DD MM YYYY, h:mm a');
      case DateFormatEnum.MEDIUM:
        return moment(date).tz(timezone).format('lll');
      case DateFormatEnum.LONG:
        return moment(date).tz(timezone).format('LLL');
      case DateFormatEnum.FULL:
        return moment(date).tz(timezone).format('LLLL');

      case DateFormatEnum.MEDIUMDATE:
        return moment(date).tz(timezone).format('ll');
      case DateFormatEnum.LONGDATE:
        return moment(date).tz(timezone).format('LL');
      case DateFormatEnum.LONGDATE2:
        return moment(date).tz(timezone).format('MMM Do, YYYY');
      case DateFormatEnum.LONGDATE_TIME:
        return moment(date).tz(timezone).format('MMM Do, YYYY, h:mm a');
      case DateFormatEnum.SHORTYEAR:
        return moment(date).tz(timezone).format('MMM. d, y');
      case DateFormatEnum.FULLDATE:
        return moment(date).tz(timezone).format('dddd, MMM DD, YYYY');
      case DateFormatEnum.SHORTDATE:
      default:
        return moment(date).tz(timezone).format('LL');
    }
  }
}

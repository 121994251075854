<div
  class="d-flex align-items-center justify-content-center toolbox"
  [class.selected]="
    (questionsService.textSelected | async) || selectedFormat?.image || selectedFormat?.resource
  "
>
  <ng-container *ngIf="!selectedFormat?.image && !selectedFormat?.resource">
    <button
      mat-icon-button
      [class.selected]="selectedFormat?.bold"
      [disabled]="!collection"
      (click)="onClick('BOLD')"
      class="pcl-editor-toolbox-bold"
    >
      <i class="material-icons-outlined">format_bold</i>
    </button>
    <button
      mat-icon-button
      [class.selected]="selectedFormat?.italic"
      [disabled]="!collection"
      (click)="onClick('ITALIC')"
      class="pcl-editor-toolbox-italic"
    >
      <i class="material-icons-outlined">format_italic</i>
    </button>
    <button
      mat-icon-button
      [class.selected]="selectedFormat?.underline"
      [disabled]="!collection"
      (click)="onClick('UNDERLINE')"
      class="pcl-editor-toolbox-underlined"
    >
      <i class="material-icons-outlined">format_underlined</i>
    </button>
    <!-- <button
      mat-icon-button
      [class.selected]="selectedFormat?.color"
      [disabled]="!collection"
      (click)="onClick('HIGHLIGHT')"
    >
      <i class="material-icons">format_color_text</i>
    </button>
    <button
      mat-icon-button
      [class.selected]="selectedFormat?.color"
      [disabled]="!collection"
      (click)="onClick('COLOR')"
    >
      <i class="material-icons">border_color</i>
    </button> -->
    <button
      mat-icon-button
      [class.selected]="selectedFormat?.script === 'sub'"
      [disabled]="!collection || formulaSelected"
      (click)="onClick('SUBSCRIPT')"
      class="pcl-editor-toolbox-subscript"
    >
      <i class="material-icons-outlined">subscript</i>
    </button>
    <button
      mat-icon-button
      [class.selected]="selectedFormat?.script === 'super'"
      [disabled]="!collection || formulaSelected"
      (click)="onClick('SUPERSCRIPT')"
    >
      <i class="material-icons-outlined">superscript</i>
    </button>
  </ng-container>

  <ng-container *ngIf="selectedFormat?.image">
    <button
      mat-icon-button
      (click)="onClick('IMAGE_SMALL', selectedFormat?.id)"
      class="pcl-editor-toolbox-image_small"
    >
      <i
        class="material-icons-outlined small"
        [class.image-selected]="
          selectedFormat?.sizePresetActive && selectedPreset === 'IMAGE_SMALL'
        "
      >
        panorama
      </i>
    </button>
    <button
      mat-icon-button
      (click)="onClick('IMAGE_MEDIUM', selectedFormat?.id)"
      class="pcl-editor-toolbox-image_medium"
    >
      <i
        class="material-icons-outlined medium"
        [class.image-selected]="
          selectedFormat?.sizePresetActive && selectedPreset === 'IMAGE_MEDIUM'
        "
      >
        panorama
      </i>
    </button>
    <button
      mat-icon-button
      (click)="onClick('IMAGE_LARGE', selectedFormat?.id)"
      class="pcl-editor-toolbox-image_large"
    >
      <i
        class="material-icons-outlined large"
        [class.image-selected]="
          selectedFormat?.sizePresetActive && selectedPreset === 'IMAGE_LARGE'
        "
      >
        panorama
      </i>
    </button>
  </ng-container>

  <div class="divider" *ngIf="!selectedFormat?.resource"></div>

  <button
    mat-icon-button
    [disabled]="!collection"
    (click)="onClick('ALIGN_LEFT')"
    class="pcl-editor-toolbox-align_left"
  >
    <i class="material-icons">format_align_left</i>
  </button>
  <button
    mat-icon-button
    [disabled]="!collection"
    (click)="onClick('ALIGN_CENTER')"
    class="pcl-editor-toolbox-align_center"
  >
    <i class="material-icons">format_align_center</i>
  </button>
  <button
    mat-icon-button
    [disabled]="!collection"
    (click)="onClick('ALIGN_RIGHT')"
    class="pcl-editor-toolbox-align_right"
  >
    <i class="material-icons">format_align_right</i>
  </button>
  <!-- <button
    mat-icon-button
    [disabled]="!collection"
    (click)="onClick('ALIGN_JUSTIFY')"
  >
    <i class="material-icons">format_align_justify</i>
  </button> -->
  <ng-container *ngIf="!selectedFormat?.image && !selectedFormat?.resource">
    <button
      mat-icon-button
      [class.selected]="selectedFormat?.list === 'bullet'"
      [disabled]="!collection || (questionsService.enableListFormat | async) === false"
      (click)="onClick('LIST_BULLETED')"
      class="pcl-editor-toolbox-list_bulleted"
    >
      <i class="material-icons-outlined">format_list_bulleted</i>
    </button>
    <button
      mat-icon-button
      [class.selected]="selectedFormat?.list === 'ordered'"
      [disabled]="!collection || (questionsService.enableListFormat | async) === false"
      (click)="onClick('LIST_NUMBERED')"
      class="pcl-editor-toolbox-list_numbered"
    >
      <i class="material-icons-outlined">format_list_numbered</i>
    </button>

    <div class="divider"></div>

    <button
      *ngIf="tableEnabled"
      mat-icon-button
      class="table-button pcl-editor-toolbox-table"
      [disabled]="!collection"
      (click)="onClick('TABLE')"
    >
      <i class="material-icons-outlined">grid_on</i>
    </button>
    <button
      mat-icon-button
      ngbTooltip="{{ 'Add image' | translate }}"
      [disabled]="!collection"
      (click)="onClick('IMAGE')"
      class="table-button pcl-editor-toolbox-image"
    >
      <i class="material-icons-outlined">photo</i>
    </button>
    <button
      mat-icon-button
      ngbTooltip="{{ 'Add video' | translate }}"
      [disabled]="!collection"
      (click)="onClick('VIDEO')"
      class="table-button pcl-editor-toolbox-video"
    >
      <i class="material-icons-outlined">ondemand_video</i>
    </button>
    <button
      mat-icon-button
      ngbTooltip="{{ 'Add Phet simulation URL' | translate }}"
      [disabled]="!collection"
      (click)="onClick('SIMULATION')"
      class="table-button pcl-editor-toolbox-in_picture"
    >
      <i class="material-icons-outlined">picture_in_picture</i>
    </button>
    <button
      mat-icon-button
      ngbTooltip="{{ 'Add PDF' | translate }}"
      [disabled]="!collection"
      (click)="onClick('DOCUMENT')"
      class="table-button pcl-editor-toolbox-as_pdf"
    >
      <i class="material-icons-outlined">picture_as_pdf</i>
    </button>
    <button
      mat-icon-button
      ngbTooltip="{{ 'Insert equation' | translate }}"
      [disabled]="!collection"
      (click)="onClick('EQUATION')"
      class="table-button pcl-editor-toolbox-functions"
    >
      <i class="material-icons-outlined">functions</i>
    </button>
    <!-- <button
      mat-icon-button
      ngbTooltip="{{ 'Add table' | translate }}"
      [disabled]="!collection"
      (click)="onClick('TABLE')"
    >
      <i class="material-icons-outlined">grid_on</i>
    </button> -->
    <button
      *ngIf="showRefer"
      mat-icon-button
      ngbTooltip="{{ 'Refer' | translate }}"
      [class.selected]="questionsService.isComprehensionReferring | async"
      [disabled]="!collection"
      (click)="questionsService.isComprehensionReferring.next(true)"
      class="table-button pcl-editor-toolbox-quote"
    >
      <i class="material-icons">format_quote</i>
    </button>
  </ng-container>
</div>

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-add-web-viewer',
  templateUrl: './add-web-viewer.component.html',
  styleUrls: ['./add-web-viewer.component.scss'],
})
export class AddWebViewerComponent {
  constructor(private dialogRef: MatDialogRef<AddWebViewerComponent>) {}

  async addWebViewer(proceed = false): Promise<void> {
    this.dialogRef.close(proceed);
  }
}

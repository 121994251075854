import { Directive, ElementRef, EventEmitter, Output, OnInit } from '@angular/core';
import { Subscription, debounceTime, fromEvent } from 'rxjs';

@Directive({
  selector: '[appScrollDetector]',
})
export class ScrollDetectorDirective implements OnInit {
  @Output() scrollReachedTop = new EventEmitter<boolean>();
  @Output() scrollReachedBottom = new EventEmitter<boolean>();

  private scrollSubscription?: Subscription;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.scrollSubscription = fromEvent(this.elementRef.nativeElement, 'scroll')
      .pipe(debounceTime(300))
      .subscribe(() => {
        const element = this.elementRef.nativeElement;
        const atTop = element.scrollTop === 0;
        const atBottom =
          Math.abs(element.scrollHeight - Math.abs(element.scrollTop) - element.clientHeight) < 5;

        if (atTop) {
          this.scrollReachedTop.emit(true);
        } else if (atBottom) {
          this.scrollReachedBottom.emit(true);
        }
      });
  }

  ngOnDestroy(): void {
    this.scrollSubscription?.unsubscribe();
  }
}

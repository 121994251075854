import { Context } from 'src/app/models/context';
import { ADT, matchI } from 'ts-adt';
import { z } from 'zod';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../environments/environment';
import { CallProvider, DEFAULT_PROVIDER } from '../common/interfaces/rtc-interface';
import { FrameBackground, WhiteboardBackground } from '../services/session-shared-data.service';
import { CollaborativeApps } from '../sessions/session/iframe/additional-apps.utils';

import { AclService } from '../services/acl.service';
import { GptAppConfig } from '../services/space-apps.service';
import { YCanvasItemObject } from '../sessions/common/y-session';
import { DEFAULT_BACKGROUND_COLOR } from '../sessions/session/wb-canvas/grid-canvas/background-canvas.component';
import { Event } from './event';
import { CHANNELS, DataUpdateType } from './realtime';
import { ResourceItemModel } from './resource';
import { Institution, User } from './user';

export enum SpeechService {
  DEEPGRAM = 'DEEPGRAM',
  WEB_SPEECH_API = 'WEB-SPEECH-API',
}

export enum ItemModel {
  Question = 'Question',
  Note = 'Note',
  Worksheet = 'Worksheet',
  Resource = 'Resource',
  Formula = 'Formula',
  IFrame = 'IFrame',
  WebViewer = 'Browser',
  Mario = 'Mario',
  Chat_GPT = 'Chat_GPT',
  RemoteVideo = 'REMOTEVIDEO',
  ResourceLoading = 'ResourceLoading',
}

export enum SessionState {
  TEACHER_LOGGED_IN,
  READY_FOR_STUDENT,
  UNSET,
  ENDED,
}

export enum Visibility {
  PUBLIC = 'PUBLIC', // Open to any pencil account
  PRIVATE = 'PRIVATE', // Open to any invited account
}

export class QuestionState {
  show_answer = false;
  show_hint = false;
  answers = new Map<string, string>();

  constructor(res) {
    this.show_answer = res.show_answer;
    this.show_hint = res.show_hint;
    this.answers = res.answers
      ? new Map(Object.entries(JSON.parse(JSON.stringify(res.answers))))
      : new Map<string, string>();
  }
}

export interface WorksheetState {
  // The current worksheet item presented in the frame.
  item_index: number;

  // The state of the current item if it was a question.
  question_state?: QuestionState;
}

export class ResourceState {
  resource_url?: string;
  resource_name?: string;
  resource_item_model?: ResourceItemModel;
  public_url?: boolean;

  constructor(res) {
    this.resource_url = res.resource_url;
    this.resource_name = res.resource_name;
    this.resource_item_model = res.resource_item_model;
    this.public_url = res.public_url;
  }
}

export class IFrameState {
  // Url of the iframe to embed
  public url?: string;
  public title?: string;
  public type?: string;
  public sharedUrl?: string; // Shared URL gets set when the owner shares a pin/url with other participants
  public ownerId?: string;

  constructor(res: any) {
    this.url = res.url;
    this.title = res.title;
    this.type = res.type;
    this.sharedUrl = res.sharedUrl;
    this.ownerId = res.ownerId;
  }
}
export class WebViewerState {
  // Url of the iframe to embed
  public url: string;
  public hbSessionId?: string;
  public terminationDate?: Date;
  public title?: string;
  public type?: string;
  public defaultUrl?: string;
  public webgl?: boolean;
  public snapshotHbSessionId?: string; // id of session whose state was loaded to start this session
  public lastVisitedUrl?: string;
  public initialZoom?: number;
  public country?: string;
  public adblock?: boolean;
  constructor(res: any) {
    this.url = res.url;
    this.hbSessionId = res.hbSessionId;
    this.terminationDate = res.terminationDate;
    this.title = res.title;
    this.type = res.type;
    this.defaultUrl = res.defaultUrl;
    this.webgl = res.webgl;
    this.snapshotHbSessionId = res.snapshotHbSessionId;
    this.lastVisitedUrl = res.lastVisitedUrl;
    this.initialZoom = res.initialZoom;
    this.country = res.country;
    this.adblock = res.adblock;
  }

  static isEqual(state?: WebViewerState, other?: WebViewerState) {
    // Two instances are the same if they share the same url, hbSessionId, and snapshotHbSessionId
    // (Used to know when to refresh the WebViewer state on a remote change)
    return (
      (!state && !other) ||
      (state?.url === other?.url &&
        state?.hbSessionId === other?.hbSessionId &&
        state?.snapshotHbSessionId === other?.snapshotHbSessionId)
    );
  }
}

/**
 * This type defines the subset of collaborative apps that are Mario apps
 */
type MarioApps =
  | CollaborativeApps.TIMER
  | CollaborativeApps.STOPWATCH
  | CollaborativeApps.POLLS
  | CollaborativeApps.ERASER;

export type MarioResource = ADT<{
  DEV_URL: {
    url: string; // dev url for testing of mario apps
  };
  DEPLOYED: {
    name: MarioApps; // name of the mario app
    major_version: number; // major_version of the mario app
  };
}>;

export class MarioState {
  public id: string; // id of the mario instance
  public ownerId: string; // id of the person who created the app
  public resource: MarioResource;

  constructor(res: any) {
    this.id = res.id;
    this.ownerId = res.ownerId;

    // ensure backwards compatibility with old versions
    if (res.url && !res.resource) {
      this.resource = { _type: 'DEV_URL', url: res.url };
    } else {
      this.resource = res.resource;
    }
  }

  get url(): string {
    return matchI(this.resource)({
      DEV_URL: ({ url }) => url,
      DEPLOYED: ({ name, major_version }) =>
        `https://pencil-${name}-${major_version}-${this.env}.web.app`,
    });
  }

  get env(): string {
    return environment.production ? 'PROD' : 'DEV';
  }

  get name(): string {
    return matchI(this.resource)({
      DEPLOYED: (deployed) => deployed.name,
      DEV_URL: () => CollaborativeApps.MARIO,
    });
  }
}

export class EquationState {
  // Stores the equation data.
  public equation_data?: string;
  constructor(res: any) {
    this.equation_data = res.equation_data;
  }
}

// Represents a library item that could be added to a whiteboard frame
// For example: Question, Note, ...etc.
export class FrameItem {
  _id: string;
  content_id: string;
  locked = false;
  isPrivate: boolean;
  interactionsLocked: boolean;
  available: boolean;
  hasAvailabilityNotification: boolean;
  model?: ItemModel;
  question_state?: QuestionState;
  worksheet_state?: WorksheetState;
  resource_state?: ResourceState;
  equation_state?: EquationState;
  iframe_state?: IFrameState;
  browser_state?: WebViewerState;
  mario_state?: MarioState;
  gpt_state?: GptAppConfig;
  // Store the position/rotation of the resource in a map
  // example: { x:100, y:100, width: 120, ...etc}
  position?: Map<string, string>;
  ownerId?: string;
  constructor(res: any, isPrivate: boolean) {
    this.isPrivate = isPrivate;
    this._id = res._id;
    this.content_id = res.content_id;
    this.model = res.model;
    this.mario_state = res.mario_state;
    this.question_state = res.question_state;
    this.worksheet_state = res.worksheet_state;
    this.resource_state = res.resource_state;
    this.equation_state = res.equation_state;
    this.iframe_state = res.iframe_state;
    this.browser_state = res.browser_state;
    this.position = res.position;
    this.gpt_state = res.gpt_state;
    this.ownerId = res.ownerId;
    this.locked = Boolean(res.locked);
    this.interactionsLocked = res.interactionsLocked ?? false;
    this.available = res.available ?? true;
    this.hasAvailabilityNotification = res.hasAvailabilityNotification ?? false;
  }

  static deepClone(item: YCanvasItemObject): FrameItem {
    const clonedItem = new FrameItem(
      {
        _id: item._id,
        content_id: item.content_id,
        model: item.model,
        worksheet_state: item.worksheet_state,
        question_state: item.question_state ? new QuestionState(item.question_state) : undefined,
        resource_state: item.resource_state ? new ResourceState(item.resource_state) : undefined,
        equation_state: item.equation_state ? new EquationState(item.equation_state) : undefined,
        iframe_state: item.iframe_state ? new IFrameState(item.iframe_state) : undefined,
        browser_state: item.browser_state ? new WebViewerState(item.browser_state) : undefined,
        mario_state: item.mario_state ? new MarioState(item.mario_state) : undefined,
        gpt_state: item.gpt_state || undefined,
        ownerId: item.userId,
        interactionsLocked: item.interactionsLocked ?? false,
        available: item.available ?? true,
        hasAvailabilityNotification: item.hasAvailabilityNotification ?? false,
      },
      item.isPrivate,
    );
    clonedItem.locked = item.locked;
    if (item.position) {
      if (item.position.constructor === Map) {
        clonedItem.position = new Map(item.position);
      } else {
        clonedItem.position = new Map(Object.entries(JSON.parse(JSON.stringify(item.position))));
      }
    }
    return clonedItem;
  }
}

export interface Presence {
  [key: string]: string[];
}

export enum FrameType {
  WHITEBOARD = 'whiteboard',
  TEXT_EDITOR = 'text_editor',
}

export class Room {
  uid: string;
  name: string;
  permissions: Permissions;

  constructor(res: any) {
    this.uid = res.uid ?? uuidv4();
    this.name = res.name ?? '';
    this.permissions = res.permissions ?? new Permissions();
  }
}

export class BoardFolder {
  uid: string;
  name: string;
  roomUid: string;
  isHidden = false; // used to hide board folder when board folder name doesn't match search word;
  constructor(res: { uid?: string; name?: string; roomUid: string }) {
    this.uid = res.uid ?? uuidv4();
    this.name = res.name ?? '';
    this.roomUid = res.roomUid;
  }
}

export const ROOT_BOARD_FOLDER_NAME = 'ROOT_FOLDER';

export enum FramePrivacy {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export enum RoomChanges {
  CREATE = 'create',
  DELETE = 'delete',
  MOVE_PARTICIPANTS = 'move_participants',
  MOVE_BOARDS = 'move_boards',
}

export class Frame {
  frameType?: FrameType;
  uid: string;
  name: string;
  canvas: string;
  items: FrameItem[];
  updatedAt?: number;
  privacy?: FramePrivacy;
  locked?: boolean; // whether frame is locked or not
  isHidden = false; // used to hide current Frame when frame name doesn't match search word
  privateUsers?: string[]; // ids of the private users who have access to the frame
  boardFolderUid?: string;

  backgroundPattern?: WhiteboardBackground;
  backgroundColor?: string;

  constructor(res: any) {
    this.uid = res.uid ? res.uid : uuidv4();
    this.canvas = res.canvas;
    this.items = [];
    res.items?.forEach((item) => {
      this.items.push(new FrameItem(item, item.isPrivate));
    });
    this.updatedAt = res.updatedAt ? res.updatedAt : Date.now();
    this.name = res.name;
    this.frameType = res.frameType ?? FrameType.WHITEBOARD;
    this.privacy = res.privacy ?? FramePrivacy.PUBLIC;
    this.privateUsers = res.privateUsers ?? [];
    this.locked = res.locked ?? false;
    this.backgroundPattern = res.backgroundPattern || WhiteboardBackground.DOT; // default to dot pattern
    this.backgroundColor = res.backgroundColor || DEFAULT_BACKGROUND_COLOR; // default color
    this.boardFolderUid = res.boardFolderUid ?? ROOT_BOARD_FOLDER_NAME;
  }

  toObject() {
    const properties = Object.getOwnPropertyNames(this);
    const obj = {};
    properties.forEach((prop) => (obj[prop] = this[prop]));
    return obj;
  }

  validate(): boolean {
    const requiredFelids = ['uid', 'items', 'name'];

    for (const field of requiredFelids) {
      if (!this[field]) {
        return false;
      }
    }

    return true;
  }
}

export enum SpacePermissions {
  SHARE_AUDIO = 'shareAudio',
  SHARE_VIDEO = 'shareVideo',
  SCREEN_SHARE = 'screenShare',
  EDIT_SPACE = 'editSpace',
  MESSAGE_EVERYONE = 'messageEveryone',
  MESSAGE_OTHER_PARTICIPANTS = 'messageOtherParticipants',
  CREATE_BOARDS = 'createBoards',
  SPACE_TRANSCRIPT = 'spaceTranscript',
  INSERT_WEB_VIEWER = 'insertWebViewer',
}

export enum UserPermission {
  CONTROL_WHITEBOARD = 'CONTROL_WHITEBOARD',
  SHARE_AUDIO = 'SHARE_AUDIO',
  SHARE_VIDEO = 'SHARE_VIDEO',
}

export enum AdmissionStatus {
  ADMITTED = 'ADMITTED',
  DENIED = 'DENIED',
  REQUESTED = 'REQUESTED',
}

export enum RealTimeUpdatesDataTypes {
  // TODO: should refactor all update data types to use an enum instead of hard coded strings
  SESSION_ACCESS_REQUEST = 'SESSION_ACCESS_REQUEST',
  SESSION_ACCESS_RESPONSE = 'SESSION_ACCESS_RESPONSE',
  USER_ACCESS_RESPONSE = 'USER_ACCESS_RESPONSE',
  SESSION_WAITING_ROOM_REQUEST = 'SESSION_WAITING_ROOM_REQUEST',
  USER_WAITING_ROOM_RESPONSE = 'USER_WAITING_ROOM_RESPONSE',
}

const permissionSchema = z.union([
  z.boolean(),
  z.map(z.string(), z.boolean()),
  z.record(z.string(), z.boolean()),
]);

export const permissionsSchema = z.object({
  shareAudio: permissionSchema,
  shareVideo: permissionSchema,
  screenShare: permissionSchema,
  editSpace: permissionSchema,
  messageEveryone: permissionSchema,
  messageOtherParticipants: permissionSchema,
  createBoards: permissionSchema,
  spaceTranscript: permissionSchema,
  insertWebViewer: permissionSchema,
});

type PermissionsType = z.infer<typeof permissionsSchema>;

export class Permissions implements PermissionsType {
  shareAudio = true;
  shareVideo = true;
  screenShare = true;
  editSpace = true;
  messageEveryone = true;
  messageOtherParticipants = true;
  createBoards = false;
  spaceTranscript = false;
  insertWebViewer = false;
}

export class SessionUser {
  _id: string;
  permissions: UserPermission[];
  userPermissions: Permissions;
  admitted?: AdmissionStatus;
  isOwner?: boolean;
  joined?: boolean;
  isAnonymous?: boolean;
  roomId?: string;
  lastInviteTime?: Date;
  constructor(res: any) {
    this._id = res._id;
    this.permissions = res.permissions;
    if (!res.userPermissions) {
      this.userPermissions = new Permissions();
    } else {
      this.userPermissions = res.userPermissions;
    }
    this.roomId = res.roomId;
    this.admitted = res.admitted;
    this.isOwner = res.isOwner;
    this.isAnonymous = false;
    this.joined = res.joined;
    this.lastInviteTime = new Date(res.lastInviteTime ?? 0);
  }
}

export interface ISpaceTemplate {
  _id?: string;
  name: string;
  icon: string;
  spaceId: string;
  isGeneral?: boolean;
  institution?: string;
  authorId?: string;
  authorName?: string;
}

export class SpaceSettings {
  disableAlwaysOnRecording?: boolean;
  enableWaitingRoom?: boolean;
}

export interface ISession {
  _id: string;
  owner: string;
  users: SessionUser[];
  accessRequesters?: SessionUser[];
  title: string;
  sessionPermissions: Permissions;
  // @TODO mfmansoo deprecate the timestamps https://pncl.atlassian.net/browse/BUGS-130
  last_update_date?: Date;
  context?: Context;
  metadata?: { [key: string]: any };
  site?: string;
  event?: Event;
  populatedUsers: User[];
  populatedAccessRequesters?: User[];
  sessionState: SessionState;
  displayedUsers?: User[];
  isLocked: boolean;
  visibility: Visibility;
  isTemplate?: boolean;
  enable_lobby?: boolean;
  createdAt: Date;
  updatedAt: Date;
  provider: CallProvider;
  speechService: SpeechService;
  inSpaceNotCallUsers?: User[];
  inCallUsers?: User[];
  sessionDefaultFramesBackground: FrameBackground;
  institution?: Institution;
  institutionID?: string;
  settings?: SpaceSettings;
  initialGCSSpaceSize?: number;
  custom_attributes?: string[];
}

export class Session implements ISession {
  _id: string;
  owner: string;
  users: SessionUser[];
  accessRequesters?: SessionUser[];
  title: string;
  sessionPermissions: Permissions;
  // @TODO mfmansoo deprecate the timestamps https://pncl.atlassian.net/browse/BUGS-130
  last_update_date?: Date;
  context?: Context;
  metadata?: { [key: string]: any };
  site?: string;
  event?: Event;
  populatedUsers: User[] = [];
  populatedAccessRequesters?: User[];
  sessionState: SessionState;
  displayedUsers?: User[];
  isLocked = false;
  visibility: Visibility;

  isTemplate?: boolean;
  enable_lobby?: boolean;

  createdAt: Date;
  updatedAt: Date;
  provider: CallProvider;
  speechService: SpeechService;
  inSpaceNotCallUsers?: User[];
  inCallUsers?: User[];

  sessionDefaultFramesBackground: FrameBackground;

  institutionID?: string;

  settings?: SpaceSettings;
  custom_attributes?: string[];

  constructor(sessionDesc: ISession) {
    this._id = sessionDesc._id;
    this.owner = sessionDesc.owner;
    this.users = [];
    sessionDesc.users?.forEach((user) => {
      this.users.push(new SessionUser(user));
    });
    sessionDesc.accessRequesters?.forEach((requester) => {
      this.accessRequesters?.push(new SessionUser(requester));
    });
    this.title = sessionDesc.title;
    this.last_update_date = sessionDesc.last_update_date;
    this.metadata = sessionDesc.metadata;
    // This is here for the user objects that come from the socket server
    this.populatedUsers = sessionDesc.populatedUsers;
    this.populatedAccessRequesters = sessionDesc.populatedAccessRequesters;
    this.sessionState = sessionDesc.sessionState ? sessionDesc.sessionState : SessionState.UNSET;
    this.site = sessionDesc.site;
    this.createdAt = new Date(sessionDesc.createdAt);
    this.updatedAt = new Date(sessionDesc.updatedAt);
    this.visibility = sessionDesc.visibility;
    this.isTemplate = sessionDesc.isTemplate;
    this.provider = sessionDesc.provider ?? DEFAULT_PROVIDER;
    this.speechService = sessionDesc.speechService ?? SpeechService.WEB_SPEECH_API;
    this.isLocked = !!sessionDesc.isLocked;
    this.sessionDefaultFramesBackground = sessionDesc.sessionDefaultFramesBackground;
    this.settings = sessionDesc.settings;

    if (sessionDesc.event) {
      this.event = new Event(sessionDesc.event);
    } else {
      this.event = undefined;
    }

    if (sessionDesc.sessionPermissions) {
      this.sessionPermissions = sessionDesc.sessionPermissions;
    } else {
      this.sessionPermissions = new Permissions();
    }
    if (sessionDesc.institutionID) {
      this.institutionID = sessionDesc.institutionID;
    }
    this.custom_attributes = sessionDesc.custom_attributes;
  }

  public static isOwnedByUser(session?: ISession, userOrUserId?: User | null | string): boolean {
    const userId = typeof userOrUserId === 'string' ? userOrUserId : userOrUserId?._id;
    return (
      !!session &&
      (session.owner === userId ||
        !!session.users?.find((sessionUser) => sessionUser._id === userId && sessionUser.isOwner))
    );
  }

  public static getSpaceHosts(session?: ISession): SessionUser[] {
    let hosts: SessionUser[] = [];
    if (session) {
      hosts = session.users?.filter((sessionUser) => sessionUser.isOwner);
    }
    return hosts;
  }

  public static getSpaceHostsIds(session?: ISession): Set<string> {
    const hosts = this.getSpaceHosts(session);
    return new Set(hosts.map((u) => u._id));
  }

  public static getMainRoomId(sessionId: string, breakOutRoomsEnabled: boolean = true): string {
    if (breakOutRoomsEnabled) {
      return `${sessionId}:MainRoom`;
    } else {
      return '';
    }
  }

  public static isMainRoom(
    roomId: string,
    sessionId: string,
    breakOutRoomsEnabled: boolean,
  ): boolean {
    return roomId === this.getMainRoomId(sessionId, breakOutRoomsEnabled);
  }

  public static getMainRoomId_v2(sessionId: string): string {
    return `${sessionId}-MainRoom`;
  }

  /**
   * check if the current user
   * has access to the frame passed to the function
   * @param frame
   *
   */
  public static doesUserHaveAccessToBoard(session: Session, frame: Frame, user: User): boolean {
    if (!frame) {
      return false;
    }

    const isOwner = Session.isOwnedByUser(session, user);
    return (
      isOwner ||
      !frame.privacy ||
      frame.privacy === FramePrivacy.PUBLIC ||
      (frame.privateUsers?.includes(<string>user?._id) ?? false)
    );
  }

  public static isUserEnabledAdminHost(
    userID: string,
    session: ISession,
    aclService: AclService,
  ): boolean {
    if (session?.institution?.settings?.addAdminsAsHostsInSpace) {
      const sessionUser = session.users.find((sUser) => sUser.isOwner && sUser._id === userID);
      if (!sessionUser) {
        return false;
      }

      const hostUser = session.populatedUsers.find((pUser) => pUser._id === sessionUser._id);
      if (
        hostUser &&
        // checks if user is inst admin or site admin
        (aclService.isAdmin(hostUser) ||
          aclService.isSuperAdmin(hostUser) ||
          aclService.isSiteAdmin(hostUser)) &&
        // @ts-expect-error populateuser.institution is id and not institution
        hostUser.institution === session.institution._id
      ) {
        return true;
      }
    }

    return false;
  }
}

export interface RealtimeSessionUpdate {
  id: string;
  channel: CHANNELS;
  updateType: DataUpdateType;
  dataType: string;
  data: {
    sessionId: string;
    /* TODO: Remove this. Redundant with addUpdateUsers, but included for
     * backwards compatibility with older FEs.
     */
    sessionUsers: SessionUser[];
    addUpdateUsers: SessionUser[];
    removeUsers: SessionUser[];
    userObjects: User[];
  };
}

export interface PublicSpaceDetails {
  spaceTitle?: string;
  visibility: Visibility;
}

export type MakeAllOptionalExcept<T, K extends keyof T> = Partial<T> & Pick<T, K>;

<div class="messaging-container" #messageRoot>
  <div
    *ngIf="
      (wideScreen$ | async) || (messagingService.selectedConversationPreview$ | async) === null
    "
    class="conversation-container"
  >
    <div class="filter-container d-flex align-items-center cpx-16 border-bottom border-end">
      <app-search-bar
        [searchTitle]="'Search People'"
        [searchContinuously]="true"
        (filter)="executeSearch($event)"
      >
      </app-search-bar>
    </div>
    <div #userList class="conversation-list border-end">
      <app-conversation-preview
        *ngFor="let conversationPreview of messagingService.conversationPreviews$ | async"
        [conversationPreview]="conversationPreview"
        [selected]="
          isPreviewSelected(
            conversationPreview,
            messagingService.selectedConversationPreview$ | async
          )
        "
        (selectConversationPreview)="selectPreview(conversationPreview)"
      >
      </app-conversation-preview>
    </div>
    <div class="flex-1 border-end"></div>
  </div>
  <div class="outlet-container">
    <router-outlet></router-outlet>
  </div>
</div>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PanelData } from '../../settings.constants';
@UntilDestroy()
@Component({
  selector: 'app-user-settings-billable-usage',
  templateUrl: './user-settings-billable-usage.component.html',
  styleUrls: ['./user-settings-billable-usage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsBillableUsageComponent {
  @Input() panelData!: PanelData;
  constructor() {}
}

import { VirtualBackgroundType } from 'src/app/common/interfaces/rtc-interface';
import { DeviceType } from 'src/app/common/utils/devices-handle-util';
import { BehaviorSubject, Observable } from 'rxjs';
import { Mutex } from 'async-mutex';
import { VirtualBackgroundInsertableStreamService } from '../virtual-background-insertable-stream.service';
import { DeviceState } from '../devices-manager.service';
import { LocalTrack } from './local-track';
export interface VirtualBackgroundState {
  type: VirtualBackgroundType;
  backgroundImg?: string;
}

export class VirtualBackgroundLocalVideoDecorator implements LocalTrack {
  readonly VIRTUAL_BACKGROUND_TYPE = 'VIRTUAL_BACKGROUND_TYPE';
  readonly VIRTUAL_BACKGROUND_IMAGE = 'VIRTUAL_BACKGROUND_IMAGE';

  private _streamSubject = new BehaviorSubject<MediaStream | null>(null);
  private _baseTrack: LocalTrack;
  private _applyEffectMutex = new Mutex();
  private acquireStreamMutex = new Mutex();
  // TODO: make virtualBackgroundInsertableStreamService private instance instead of service and add the VB functions here and call them throw the local track manager
  private _virtualBackgroundInsertableStreamService: VirtualBackgroundInsertableStreamService;

  constructor(
    baseTrack: LocalTrack,
    virtualBackgroundInsertableStreamService: VirtualBackgroundInsertableStreamService,
  ) {
    this._baseTrack = baseTrack;
    this._virtualBackgroundInsertableStreamService = virtualBackgroundInsertableStreamService;
  }

  get deviceId$() {
    return this._baseTrack.deviceId$;
  }

  get stream$(): Observable<MediaStream | null> {
    return this._streamSubject.asObservable();
  }

  get deviceState$() {
    return this._baseTrack.deviceState$;
  }

  get deviceState() {
    return this._baseTrack.deviceState;
  }

  get deviceId() {
    return this._baseTrack.deviceId;
  }

  set onmute(cb: ((this: MediaStreamTrack, ev: Event) => any) | null) {
    this._baseTrack.onmute = null;
    const track = this.getMediaStreamTrack();
    if (track) {
      track.onmute = cb;
    }
  }

  set onunmute(cb: ((this: MediaStreamTrack, ev: Event) => any) | null) {
    this._baseTrack.onunmute = null;
    const track = this.getMediaStreamTrack();
    if (track) {
      track.onunmute = cb;
    }
  }

  set onended(cb: ((this: MediaStreamTrack, ev: Event) => any) | null) {
    this._baseTrack.onended = null;
    const track = this.getMediaStreamTrack();
    if (track) {
      track.onended = cb;
    }
  }

  enableExistingTrack() {
    const curTrack = this.getMediaStreamTrack();
    if (curTrack) {
      curTrack.enabled = true;
    }
    this._baseTrack.enableExistingTrack();
  }

  set onVbProcessingError(cb: () => void) {
    this._virtualBackgroundInsertableStreamService.onVbProcessingError = cb;
  }

  set beforeAcquireStreamCallback(cb: () => void) {
    this._baseTrack.beforeAcquireStreamCallback = cb;
  }

  set afterAcquireStreamCallback(cb: () => void) {
    this._baseTrack.afterAcquireStreamCallback = cb;
  }

  getSettings(): MediaTrackSettings | undefined {
    return this._baseTrack.getSettings();
  }

  updateDeviceState(newState: Partial<DeviceState>): void {
    this._baseTrack.updateDeviceState(newState);
  }

  async acquireStream(): Promise<MediaStream> {
    return this.acquireStreamMutex.runExclusive(async () => {
      if (this.isTrackAcquired()) {
        return this.getMediaStream()!;
      } else {
        await this._baseTrack.acquireStream();
        const stream = await this.applyEffect();
        return stream!;
      }
    });
  }

  getDeviceIdFromActualStream() {
    return this._baseTrack.getDeviceIdFromActualStream();
  }

  async changeDevice(deviceID: string): Promise<MediaStream | null> {
    await this._baseTrack.changeDevice(deviceID);
    return this.applyEffect();
  }

  closeExistingTrack(): void {
    this._baseTrack.closeExistingTrack();
    this._virtualBackgroundInsertableStreamService.stopVirtualBackgroundProcessing();
    this._streamSubject.next(null);
  }

  isTrackAcquired(): boolean {
    return this.getMediaStreamTrack()?.readyState === 'live';
  }

  getMediaStream(): MediaStream | null {
    return this._streamSubject.value;
  }

  getMediaStreamTrack(): MediaStreamTrack | undefined {
    const track = this.getMediaStream()
      ?.getTracks()
      .filter((mediaStreamTrack) => mediaStreamTrack.kind === this.deviceType)[0];
    return track;
  }

  async changeVbEffect(state: VirtualBackgroundState) {
    // return if trying to do the same effect
    if (
      this._virtualBackgroundInsertableStreamService.virtualBackgroundType === state.type &&
      (state.type !== VirtualBackgroundType.BACKGROUND_IMAGE ||
        this._virtualBackgroundInsertableStreamService.lastBackgroundImageSource ===
          state.backgroundImg)
    ) {
      return;
    }

    this._virtualBackgroundInsertableStreamService.virtualBackgroundType = state.type;
    this._virtualBackgroundInsertableStreamService.lastBackgroundImageSource = state.backgroundImg;
    await this.applyEffect();
  }

  get deviceType(): DeviceType {
    return this._baseTrack.deviceType;
  }

  private async applyEffect(): Promise<MediaStream | null> {
    return this._applyEffectMutex.runExclusive(async () => {
      if (!this._baseTrack.isTrackAcquired()) {
        return null;
      }
      try {
        this.updateDeviceState({ isGettingStream: true, isToggable: false, state: false });
        this._streamSubject.next(
          await this._virtualBackgroundInsertableStreamService.applyVirtualBackgroundEffect(
            this._baseTrack.getMediaStream()!,
          ),
        );
        return this.getMediaStream();
      } catch(e) {
        // if failed to apply VB we will fallback to the base stream
        this._streamSubject.next(this._baseTrack.getMediaStream());

        throw e;
      } finally {
        this.updateDeviceState({ isGettingStream: false, isToggable: true, state: true });
      }
    });
  }
}

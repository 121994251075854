<div class="virtual-background-modal-container">
  <div class="header">
    {{ 'Virtual Background' | translate }}
    <mat-icon class="close-icn" (click)="close()">close</mat-icon>
  </div>
  <div class="body">
    <div class="preview-section">
      <span class="loading-text" *ngIf="(videoStream$ | async) === null">{{
        'Camera is off. Effects will apply upon activation.' | translate
      }}</span>
      <span class="loading-text" *ngIf="(videoStream$ | async) && !isVideoPlaying">{{
        'Applying virtual background effects...' | translate
      }}</span>
      <video
        [hidden]="!isVideoPlaying || (videoStream$ | async) === null"
        #virtualBackgroundPreview
        id="virtual-background-preview"
        muted
        playsinline
        autoplay="autoplay"
      ></video>
    </div>
    <div class="vector-border"></div>
    <div class="backgrounds-section">
      <span class="sub-title">{{ 'NO EFFECTS & BLUR' | translate }}</span>
      <div class="virtual-backgrounds-widgets-container">
        <div class="virtual-background-widget">
          <button
            class="widget-frame"
            [class.active]="
              virtualBackgroundServiceBase.virtualBackgroundType === virtualBackgroundType.NONE
            "
            (click)="removeBackground()"
            appPreventEnter
          >
            <mat-icon class="check-icon">check</mat-icon>
          </button>
          <span class="widget-title">No effect</span>
        </div>
        <div class="virtual-background-widget">
          <button
            class="widget-frame"
            [class.active]="
              virtualBackgroundServiceBase.virtualBackgroundType ===
              virtualBackgroundType.BACKGROUND_BLUR
            "
            (click)="blur()"
            appPreventEnter
          >
            <svg-icon
              [icon]="'blur_icon'"
              [width]="'24px'"
              [height]="'20px'"
              [maxWidth]="'fit-content'"
            ></svg-icon>
            <mat-icon class="check-icon">check</mat-icon>
          </button>
          <span class="widget-title">Blur</span>
        </div>
      </div>
      <div class="images-section">
        <div
          class="vb-category"
          *ngFor="let vbImageCategory of virtualBackgroundServiceBase.vbImagesCategories"
        >
          <span class="sub-title">{{ vbImageCategory.title | translate }}</span>
          <div class="virtual-backgrounds-widgets-container">
            <div
              class="virtual-background-widget"
              *ngFor="let vbImage of vbImageCategory.images; index as i"
            >
              <button
                class="widget-frame"
                [style.background-image]="'url(' + vbImage.src + ')'"
                (click)="vbImage.onClick({ image: vbImage, category: vbImageCategory })"
                [class.active]="
                  virtualBackgroundServiceBase.virtualBackgroundType ===
                    virtualBackgroundType.BACKGROUND_IMAGE && vbImage.active
                "
                [appTooltip]="'Your custom image.' | translate"
                [appTooltipPlacement]="'top-center'"
                [appTooltipArrow]="'bottom'"
                [appTooltipTheme]="'grey2'"
                [appTooltipDisabled]="i === 0"
                appPreventEnter
              >
                <svg-icon
                  *ngIf="vbImage.icon"
                  [icon]="vbImage.icon"
                  [width]="'24px'"
                  [height]="'20px'"
                  [maxWidth]="'fit-content'"
                ></svg-icon>
                <mat-icon class="check-icon">check</mat-icon>
              </button>
              <button
                *ngIf="i !== 0"
                class="delete-button"
                (click)="deleteImage(vbImageCategory, vbImage.src)"
              >
                <mat-icon class="delete-icon">delete_outline</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <!--Cancel button will be enabled when pre-call modal is added-->
    <!--<button [style.background]="'#4F4F4F'" (click)="close()">
      <mat-icon>close</mat-icon>
      {{ 'Cancel' | translate }}
    </button>-->
    <div
      class="noticed-error"
      *ngIf="virtualBackgroundServiceBase.virtualBackgroundType !== virtualBackgroundType.NONE"
    >
      <span class="material-icons">warning</span>
      <span class="noticed-error-text">
        {{ 'Please note: Enabling this feature may slow down your device. ' | translate }}
        <a rel="noopener" target="_blank" [href]="intercomArticles.VirtualBackgroundSupport">
          {{ 'Learn more' | translate }}
        </a>
      </span>
    </div>
    <button [style.background]="'#2F80ED'" (click)="apply()">
      <mat-icon>check</mat-icon>
      {{ 'Done' | translate }}
    </button>
  </div>
</div>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { User, UserInfo } from 'src/app/models/user';
import { MessagingService } from 'src/app/services/messaging.service';
import { contextEquals, filterNonNullable, isUserPreview } from 'src/app/common/utils/messaging';
import {
  ContentMessage,
  ConversationContent,
  ConversationPreview,
  TypedMessage,
} from 'src/app/models/messaging';
import { UserService } from 'src/app/services/user.service';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

interface HeaderInfo {
  name: string;
  callTarget: UserInfo | null;
}

@UntilDestroy()
@Component({
  selector: 'app-conversation-container',
  templateUrl: './conversation-container.component.html',
  styleUrls: ['./conversation-container.component.scss'],
})
export class ConversationContainerComponent implements OnInit, OnDestroy {
  @Input() public emitTyping = true;
  @Input() public ignoreHeaderInfo = false;
  @Input() public enableControls = false;
  @Input() public isClickEnabled = true;
  @Input() public enableMessaging = true;
  public replyMessage?: ContentMessage;
  headerInfo$: Observable<HeaderInfo>;
  wideScreen$: Observable<boolean>;

  userLookup: { [id: string]: User } = {};
  conversationGroupName = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    public messagingService: MessagingService,
    private userService: UserService,
  ) {
    this.wideScreen$ = this.breakpointObserver.observe('(min-width: 500px)').pipe(pluck('matches'));

    this.headerInfo$ = this.messagingService.selectedConversationPreview$.pipe(
      filterNonNullable(),
      map((preview) => {
        if (isUserPreview(preview)) {
          return {
            name: preview.userInfo.name,
            callTarget: null,
          };
        } else {
          this.userService.allUsers.pipe(untilDestroyed(this)).subscribe((res) => {
            const reducer = (dict: any = {}, currUser: User) => ({
              ...dict,
              [currUser._id]: currUser,
            });
            this.userLookup = res.profiles?.reduce(reducer, {});
            // dynamically create group name
            this.conversationGroupName = '';
            preview.group.users.forEach((userId) => {
              if (userId !== preview.currentUser._id && this.userLookup[userId]) {
                this.conversationGroupName += `${this.userLookup[userId].name}, `;
              }
            });
          });
          return {
            name: this.conversationGroupName.slice(0, -2),
            callTarget: null,
          };
        }
      }),
    );

    this.messagingService.openedPrivateChatUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      if (user?._id) {
        this.updateUser(user?._id);
      }
    });
  }

  ngOnInit(): void {
    this.updateUser();

    this.activatedRoute.paramMap
      .pipe(
        map((paramMap) => paramMap.get('groupId')),
        filterNonNullable(),
      )
      .pipe(untilDestroyed(this))
      .subscribe((targetGroupId) => {
        /* This service method is called asynchronously in order to prevent an
         * NG100: ExpressionChangedAfterItHasBeenCheckedError
         */
        requestAnimationFrame(() => this.messagingService.setSelectedGroupId(targetGroupId));
      });
  }

  public updateUser(userId?: string): void {
    if (userId) {
      requestAnimationFrame(() => this.messagingService.setSelectedUserId(userId));
    } else {
      this.activatedRoute.paramMap
        .pipe(
          map((paramMap) => paramMap.get('userId')),
          filterNonNullable(),
        )
        .pipe(untilDestroyed(this))
        .subscribe((targetUserId) => {
          /* This service method is called asynchronously in order to prevent an
           * NG100: ExpressionChangedAfterItHasBeenCheckedError
           */
          requestAnimationFrame(() => this.messagingService.setSelectedUserId(targetUserId));
        });
    }
  }

  ngOnDestroy(): void {
    this.messagingService.resetSelectedUserId();
  }

  public setReplyMessage(message: ContentMessage): void {
    this.replyMessage = message;
  }

  onUpdateMessage(message: ContentMessage): void {
    this.messagingService.updateMessage(message).pipe(untilDestroyed(this)).subscribe();
  }

  onDeleteMessage(message: ContentMessage): void {
    this.messagingService.deleteMessage(message).pipe(untilDestroyed(this)).subscribe();
  }

  onFetchMoreMessages(oldestMessage: TypedMessage): void {
    this.messagingService.fetchMoreMessages(oldestMessage);
  }

  messageListLoading(
    selectedConversationPreview: ConversationPreview,
    conversationContent: ConversationContent,
  ): boolean {
    return !contextEquals(selectedConversationPreview)(conversationContent);
  }
}

import { OperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ChatOptions } from 'src/app/communication/chat/chat.component';
import { Context, encodeContext } from 'src/app/models/context';
import {
  ContentMessage,
  ConversationContent,
  ConversationHistoryUpdate,
  ConversationMessageUpdate,
  ConversationPreview,
  ConversationPreviewType,
  ConversationType,
  ConversationUpdate,
  ConversationUpdateType,
  GroupDraftConversationPreview,
  MessageAction,
  MessageType,
  MessageUpdate,
  UserConversationPreview,
  UserDraftConversationPreview,
} from 'src/app/models/messaging';
import { encodeMessageFilterParams } from 'src/app/models/params';
import { SessionUser } from 'src/app/models/session';
import { Message, OnlineStatus, User, UserInfo, UserRole } from 'src/app/models/user';
import { ICustomChatPreview } from 'src/app/services/messaging.service';

// Returns a predicate based on the id of the given document.
export const idEquals =
  <T extends { _id: string }, S extends { _id: string }>(first: T) =>
  (second: S): boolean =>
    first._id === second._id;

// Returns a predicate based on the context value of the given document.
export const contextEquals =
  <T extends { context: Context }, S extends { context: Context }>(first: T) =>
  (second: S): boolean => {
    // TODO @amey delete next two lines once all currentSession is taken out of context object
    delete first.context['currentSession'];
    delete second.context['currentSession'];
    return encodeContext(first.context) === encodeContext(second.context);
  };

export const filterNonNullable = <T>(): OperatorFunction<T, NonNullable<T>> =>
  filter((value): value is NonNullable<T> => value !== null && value !== undefined);

export const buildConversationMessageUpdate = (
  messageUpdate: MessageUpdate,
): ConversationMessageUpdate => ({
  ...messageUpdate,
  type: ConversationUpdateType.MessageUpdateType,
});

export const filterOutCurrentUserAddedMessage = (messageUpdate: MessageUpdate, userId: string) =>
  !!userId &&
  !(messageUpdate.messageAction === MessageAction.Add && messageUpdate.author._id === userId);

/**
 * Returns the message type based on context
 * to denote private vs general chat message
 * @param context
 * @returns ChatOptions.private | ChatOptions.chat | undefined
 */
export const getChatType = (
  context: Context,
): undefined | ChatOptions.private | ChatOptions.chat => {
  if (context?.session) {
    return ChatOptions.chat;
  } else if (context?.users) {
    return ChatOptions.private;
  }

  return undefined;
};

export const sentMessageToMessageUpdate = (message: Message) =>
  ({
    ...message,
    messageAction: MessageAction.Add,
  } as MessageUpdate);

export const mapSpaceOwnershipToUser = (
  user: User | UserInfo,
  spaceUsers: Map<string, SessionUser>,
): UserRole => {
  const isHost = Boolean(spaceUsers.get(user._id)?.isOwner);
  return isHost ? UserRole.HOST : UserRole.PARTICIPANT;
};

export const encodeContextParam = (context: Context): string =>
  encodeMessageFilterParams({
    questionId: context.question,
    worksheetId: context.worksheet,
    noteId: context.note,
    userIds: context.users,
    sessionId: context.session,
    groupId: context.group,
  });

/* Update the message list, as well as the total message count. Note that the
 * total message count can be different from the length of the message list.
 */
const handleConversationMessageUpdate = (
  currentContent: ConversationContent,
  messageUpdate: ConversationMessageUpdate,
): ConversationContent => {
  switch (messageUpdate.messageAction) {
    case MessageAction.Add:
      return {
        ...currentContent,
        messages: [messageUpdate, ...currentContent.messages],
        totalMessages: currentContent.totalMessages + 1,
      };
    case MessageAction.Edit:
      return {
        ...currentContent,
        messages: currentContent.messages.map((currentMessage) =>
          idEquals(currentMessage)(messageUpdate) ? messageUpdate : currentMessage,
        ),
      };
    case MessageAction.Delete:
      return {
        ...currentContent,
        messages: currentContent.messages.map((currentMessage) => {
          if (idEquals(currentMessage)(messageUpdate)) {
            currentMessage.content = messageUpdate.content;
            currentMessage.deleted = messageUpdate.deleted;
            currentMessage.deletedByHost = messageUpdate.deletedByHost;
          }
          return currentMessage;
        }),
      };
    case MessageAction.DeleteAll:
    case MessageAction.DeleteAllMessagesAndComments:
      return {
        ...currentContent,
        messages: [],
        totalMessages: 0,
      };
    default:
      return {
        ...currentContent,
        messages: currentContent.messages,
        totalMessages: currentContent.totalMessages,
      };
  }
};

/* We are able to make the strong assumption here that the incoming list of
 * messages can simply be appended to the current list because:
 *  1. We make these requests in series using concatMap() so the responses will
 *     be received in the same order that they were requested
 *  2. Every response will have at least one overlapping message with the
 *     current list, and de-duplication is handled in advance by
 *     getConversationHistoryUpdate()
 */
const handleConversationHistoryUpdate = (
  currentContent: ConversationContent,
  historyUpdate: ConversationHistoryUpdate,
): ConversationContent => ({
  ...currentContent,
  messages: currentContent.messages.concat(historyUpdate.messages),
});

export const updateConversationContent = (
  currentContent: ConversationContent,
  conversationUpdate: ConversationUpdate,
): ConversationContent => {
  switch (conversationUpdate.type) {
    case ConversationUpdateType.MessageUpdateType:
      return handleConversationMessageUpdate(currentContent, conversationUpdate);
    case ConversationUpdateType.HistoryUpdateType:
      return handleConversationHistoryUpdate(currentContent, conversationUpdate);
  }
};

export const isUserConversation = <T extends { conversationType: ConversationType }>(
  value: T,
): value is T & { conversationType: ConversationType.UserConversation } =>
  value.conversationType === ConversationType.UserConversation;

export const tagUserConversation = <T>(
  value: T,
): T & { conversationType: ConversationType.UserConversation } => ({
  ...value,
  conversationType: ConversationType.UserConversation,
});

export const tagGroupConversation = <T>(
  value: T,
): T & { conversationType: ConversationType.GroupConversation } => ({
  ...value,
  conversationType: ConversationType.GroupConversation,
});

/* Helper for user-based type narrowing based on ConversationPreviewType
 * literal.
 */
export const isUserPreview = (
  preview: ConversationPreview,
): preview is UserConversationPreview | UserDraftConversationPreview =>
  preview.previewType === ConversationPreviewType.UserPreviewType ||
  preview.previewType === ConversationPreviewType.UserDraftPreviewType;

/* Helper for draft-based type narrowing based on ConversationPreviewType
 * literal.
 */
export const isDraftPreview = (
  preview: ConversationPreview,
): preview is UserDraftConversationPreview | GroupDraftConversationPreview =>
  preview.previewType === ConversationPreviewType.UserDraftPreviewType ||
  preview.previewType === ConversationPreviewType.GroupDraftPreviewType;

// Helper for type narrowing based on MessageType literal.
export const isContentMessage = <T extends { messageType?: MessageType }>(
  message: T,
): message is T & { messageType?: MessageType.Content } =>
  message.messageType === undefined || message.messageType === MessageType.Content;

// Returns a predicate base on the giver conversation preview.
export const previewEquals =
  (first: ConversationPreview) =>
  (second: ConversationPreview): boolean =>
    (isUserPreview(first) && isUserPreview(second) && idEquals(first.userInfo)(second.userInfo)) ||
    (!isUserPreview(first) && !isUserPreview(second) && idEquals(first.group)(second.group));

export const maxTextLength = 25;

export const sortCustomChatPreviews = (
  optionA: ICustomChatPreview,
  optionB: ICustomChatPreview,
) => {
  const getCreationTimestamp = (message?: ContentMessage) =>
    message?.createdAt ? new Date(message.createdAt).getTime() : 0;

  const unreadMessagesCountA = optionA.unreadChatIds?.size ?? 0;
  const unreadMessagesCountB = optionB.unreadChatIds?.size ?? 0;

  const creationTimestampA = getCreationTimestamp(optionA.message);
  const creationTimestampB = getCreationTimestamp(optionB.message);

  const isOnlineA = optionA.user.presence === OnlineStatus.ONLINE;
  const isOnlineB = optionB.user.presence === OnlineStatus.ONLINE;

  const nameA = optionA.user?.name?.trim() || optionA.user?.email?.trim();
  const nameB = optionB.user?.name?.trim() || optionB.user?.email?.trim();

  if (unreadMessagesCountA === 0 && unreadMessagesCountB === 0) {
    if (isOnlineA && !isOnlineB) {
      return -1;
    } else if (!isOnlineA && isOnlineB) {
      return 1;
    } else {
      return nameA.toLowerCase().localeCompare(nameB.toLowerCase());
    }
  } else {
    return unreadMessagesCountB - unreadMessagesCountA || creationTimestampB - creationTimestampA;
  }
};

// Sorting chat preview happen IN PLACE
export const sortCustomChatPreviewsInPlace = (chatPreviews: ICustomChatPreview[]): void => {
  chatPreviews.sort(sortCustomChatPreviews);
};

export const areDatesWithinFiveMinutes = (date1, date2) => {
  const timestamp1 = new Date(date1).getTime();
  const timestamp2 = new Date(date2).getTime();

  const timeDifferenceInMillis = Math.abs(timestamp2 - timestamp1);

  // Convert the time difference from milliseconds to minutes
  const timeDifferenceInMinutes = timeDifferenceInMillis / (1000 * 60);
  return timeDifferenceInMinutes < 1;
};

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberList',
})
export class NumberListPipe implements PipeTransform {
  transform(number): any {
    return new Array(number);
  }
}

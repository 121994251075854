import { Injectable } from '@angular/core';
import { random } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { modifiedSetTimeout } from 'src/app/utilities/ZoneUtils';
import { environment } from '../../../../environments/environment';

// This is here to ensure typescript does not complain about this
// the only way I can find to load the library is using a script
declare let google: any;

const scope =
  'https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/youtube.readonly';
// const discoveryDocs = ['https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest'];

export enum GISErrorReason {
  NOT_INITIALIZED,
  NOT_SIGNED_IN,
  YOUTUBE_SIGNUP_REQUIRED,
}

export class GISError extends Error {
  public reason?: GISErrorReason;

  constructor(msg: string, reason?: GISErrorReason) {
    super(msg);
    this.reason = reason;
  }
}

// see: https://github.com/google/google-api-javascript-client/blob/master/docs/start.md
export class GISClient {
  public initialized = new BehaviorSubject<boolean>(false);
  public signedIn = new BehaviorSubject<boolean>(false);
  public client;
  public token;

  constructor() {
    this.initGis();
  }

  private initGis() {
    this.client = google.accounts.oauth2.initTokenClient({
      client_id: environment.youtube.clientId,
      scope: scope,
      callback: (res) => {
        this.updateSignedInStatus(true);
        this.token = res.access_token;
        this.setSignedInStatus();
      },
    });
    this.initialized.next(true);
  }

  private setSignedInStatus() {
    const isAuthorized = google.accounts.oauth2.hasGrantedAllScopes();
    if (isAuthorized) {
      this.updateSignedInStatus(isAuthorized);
    }
  }

  private updateSignedInStatus(isSignedIn: boolean): void {
    if (isSignedIn) {
      this.signedIn.next(true);
    } else {
      this.signedIn.next(false);
    }
  }

  public signIn() {
    this.setSignedInStatus();
    // The user is already signed in, we dont need to sign in again
    if (this.signedIn.getValue()) {
      return;
    }

    // wait until the client is initialized
    if (!this.initialized.getValue()) {
      throw new GISError('GIS is not initialized yet', GISErrorReason.NOT_INITIALIZED);
    }

    this.client.requestAccessToken();
  }

  public signOut() {
    if (!this.initialized.getValue()) {
      throw new GISError('GIS is not initialized yet', GISErrorReason.NOT_INITIALIZED);
    }

    if (this.token) {
      google.accounts.oauth2.revoke(this.token, (result: { successful: boolean }) => {
        if (result.successful) {
          this.signedIn.next(false);
          this.token = null;
        }
      });
    }
  }
}

// see: https://developers.google.com/youtube/v3/guides/using_resumable_upload_protocol#Complete_Upload
@Injectable({
  providedIn: 'root',
})
export class YoutubeUploaderService {
  public gisClient = new GISClient();

  public async uploadVideo(
    title: string,
    description: string,
    privacyStatus: string,
    video: File,
    blockNavigation = false,
  ) {
    if (!this.gisClient.signedIn.getValue()) {
      throw new GISError('Sign in before using this method', GISErrorReason.NOT_SIGNED_IN);
    }

    const uploadURL = await this.getUploadURL(title, description, privacyStatus, video);
    await this.uploadWithExponentialBackoff(uploadURL, video);
  }

  private getAuthToken(): string {
    if (!this.gisClient.token) {
      throw new GISError('There is no user currently signed in', GISErrorReason.NOT_SIGNED_IN);
    }
    return this.gisClient.token;
  }

  /*
   *  To get the upload to be resumable, you need to get an uploadURL and then upload the video to that url
   *  This method gets that url and returns it, you specify the video metadata during this request
   */
  private async getUploadURL(
    title: string,
    description: string,
    privacyStatus: string,
    video: File,
  ): Promise<string> {
    const parts = 'snippet,status,contentDetails';
    const url = `https://www.googleapis.com/upload/youtube/v3/videos?uploadType=resumable&part=${parts}`;
    const videoSizeBytes = video.size;

    const req = new XMLHttpRequest();

    const body = {
      snippet: {
        title,
        description,
        // Hard code this to education for now
        // see: https://gist.github.com/dgp/1b24bf2961521bd75d6c
        categoryId: 27,
      },
      status: {
        privacyStatus: privacyStatus,
        embeddable: true,
        license: 'youtube',
      },
    };

    const promise = new Promise((resolve, reject) => {
      try {
        req.addEventListener('load', (evt) => {
          resolve(evt);
        });

        req.onerror = (err) => {
          reject(err);
        };
        req.open('POST', url);
        req.setRequestHeader('Authorization', `Bearer ${this.getAuthToken()}`);
        req.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        req.setRequestHeader('X-upload-content-length', videoSizeBytes.toString());
        req.setRequestHeader('X-Upload-Content-Type', 'video/webm');

        req.send(JSON.stringify(body));
      } catch (err) {
        reject(err);
      }
    });

    await promise;

    let response: { error?: { code?: number; errors?: { reason: string }[] } } = {};

    if (req.response) {
      response = JSON.parse(req.response);
    }

    if (
      response.error?.code === 401 &&
      response.error.errors?.find((e) => e.reason === 'youtubeSignupRequired')
    ) {
      throw new GISError(
        'Please create a youtube channel before uploading',
        GISErrorReason.YOUTUBE_SIGNUP_REQUIRED,
      );
    }

    if (req.status !== 200) {
      throw new GISError(`Getting Upload URL Failed\n ${JSON.stringify(req)}`);
    }

    const location = req.getResponseHeader('location');
    if (!location) {
      throw new GISError(`No Location in response headers\n ${JSON.stringify(req)}`);
    }

    return location;
  }

  /*
   * Using the uploadURL this method uploads that video
   * note: this can return 3 values
   * 		1. Uploaded - the video was uploaded
   * 		2. Resumable - the video upload can be resumed
   * 		3. Failed - uploading the video cannot be recovered
   *
   */
  private async uploadVideoFile(
    uploadUrl: string,
    video: Blob,
    resumeRange?: string,
  ): Promise<UPLOAD_RESULT> {
    const videoSizeBytes = video.size;
    const req = new XMLHttpRequest();
    const data = await video.arrayBuffer();

    const promise: Promise<void> = new Promise((resolve, reject) => {
      try {
        req.onerror = (err) => {
          reject(err);
        };
        req.open('PUT', uploadUrl, true);
        req.setRequestHeader('X-upload-content-length', videoSizeBytes.toString());
        req.setRequestHeader('Content-Type', 'video/webm');
        req.setRequestHeader('Authorization', `Bearer ${this.getAuthToken()}`);

        if (resumeRange) {
          // resumeRangeRange = bytes=0-999999
          // r = resumeRange
          // r.split('-')
          // -> [0,999999]
          const r = resumeRange.replace('bytes=', '');
          const lastByte = r.split('-').map((s) => Number(s))[1];
          video = video.slice(lastByte);
          req.setRequestHeader(
            'Content-Range',
            `bytes ${lastByte + 1}-${videoSizeBytes - 1}/${videoSizeBytes}`,
          );
        }

        req.send(data);
        req.onreadystatechange = () => {
          if (req.readyState === XMLHttpRequest.DONE) {
            resolve();
          }
        };
      } catch (err) {
        reject(err);
      }
    });

    await promise;

    const resumableState = [500, 502, 503, 504];
    if (req.status === 200) {
      return UPLOAD_RESULT.UPLOADED;
    } else if (resumableState.includes(req.status)) {
      return UPLOAD_RESULT.RESUMABLE;
    } else {
      return UPLOAD_RESULT.FAILED;
    }
  }

  /*
   * Implements reusable video upload using exponential backoff
   */
  private async uploadWithExponentialBackoff(uploadURL: string, video: File) {
    let i = 0;
    let resumeRange;

    while (i < 6) {
      const baseTime = 1000;
      const R = random(0, 2 ** i - 1);
      const waitTime = baseTime * R;

      await new Promise((resolve) => modifiedSetTimeout(resolve, waitTime));

      const res = await this.uploadVideoFile(uploadURL, video, resumeRange);

      if (res === UPLOAD_RESULT.UPLOADED) {
        return;
      } else if (res === UPLOAD_RESULT.RESUMABLE) {
        i++;
        resumeRange = await this.getUploadRemainingBytes(uploadURL, video);
      } else {
        throw new GISError('Upload to youtube failed');
      }
    }
  }

  /*
   * Returns the number of bytes that are remaining in the upload in the format of
   * bytes=0-99999 which indicates 100,000 bytes have been uploaded
   */
  private async getUploadRemainingBytes(uploadURL: string, video: File): Promise<string> {
    const req = new XMLHttpRequest();

    const promise: Promise<void> = new Promise((resolve, reject) => {
      req.addEventListener('load', () => {
        resolve();
      });
      req.open('PUT', uploadURL, true);
      req.setRequestHeader('Authorization', `Bearer ${this.getAuthToken()}`);
      req.setRequestHeader('Content-Length', '0');
      req.setRequestHeader('Content-Range', `bytes */${video.size}`);
      req.send();
    });

    await promise;

    const remainingBytes = req.getResponseHeader('Range');
    if (req.status === 308 && remainingBytes) {
      return remainingBytes;
    }

    return 'bytes=0-0';
  }
}

enum UPLOAD_RESULT {
  UPLOADED,
  RESUMABLE,
  FAILED,
}

/**
 * Generic function that do a deep copy of an object
 */
export function deepCopy(obj: any,
  options?: { skipDate?: boolean, skipArray?: boolean, skipObject?: boolean}) {
  let copy;

  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  // Handle Date
  if (obj instanceof Date) {
    if (options?.skipDate) {
      return obj;
    }
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handling Arrays.
  if (Array.isArray(obj)) {
    if (options?.skipArray) {
      return obj;
    }
    copy = [];
    for (const item of obj) {
      copy.push(deepCopy(item, options));
    }
    return copy
  }

  // Handling Objects.
  if (obj instanceof Object) {
    if (options?.skipObject) {
      return obj;
    }
    copy = {};
    for (const attr in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, attr)) {
        copy[attr] = deepCopy(obj[attr], options);
      }
    }
    return copy;
  }
  throw new Error("Unable to copy obj! Its type isn't supported.");
}

/**
 * Increment the given list of indices with the given offset.
 */
export function incrementBy(indices: number[], offset: number): void {
  for (let i = 0; i < indices.length; i++) {
    indices[i] += offset;
  }
}

/**
 * Generic Method to delete items from list whose IDs in idsToDelete.
 */
 export function deleteItemsFrom<T extends {_id: string}>(idsToDelete: Set<string>, list: T[]): number {
  let deletingCount = 0;
  for (let i = list.length - 1; i >= 0; i--) {
    if (idsToDelete.has(list[i]._id)) {
      list.splice(i, 1);
      deletingCount++;
    }
  }
  return deletingCount;
}

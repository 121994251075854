import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, pairwise } from 'rxjs';
import {
  DeviceErrorHelperModelBase,
  DeviceErrorHelperStep,
} from 'src/app/common/utils/device-error-helper/device-error-helper-model-base';
import { DeviceAndBrowserDetectorService } from 'src/app/services/device-and-browser-detector.service';
import { DomListenerFactoryService } from 'src/app/services/dom-listener-factory.service';
import { UiService } from 'src/app/services/ui.service';
import { modifiedSetTimeout } from 'src/app/utilities/ZoneUtils';

const DEVICE_ORIENTATION_DELAY = 200;

enum HelperAction {
  DONE = 'Done',
  NEXT = 'Next',
  REFRESH = 'Refresh',
}

export enum GUIDES {
  NO_INPUT = 'no-input',
  NOT_FOUND = 'not-found',
  PERMISSION_DENIED = 'permission-denied',
  PERMISSION_DENIED_BY_SYSTEM = 'permission-denied-by-system',
}

@UntilDestroy()
@Component({
  selector: 'app-steps-guide',
  templateUrl: './steps-guide.component.html',
  styleUrls: ['./steps-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepsGuideComponent implements OnInit {
  @Input() guide!: DeviceErrorHelperModelBase;
  @Input() loading = false;

  @Output() closeModal: EventEmitter<void> = new EventEmitter();
  @Output() done: EventEmitter<void> = new EventEmitter();

  steps: DeviceErrorHelperStep[] = [];
  mobileTitle = '';
  desktopTitle = '';

  @ViewChild('slider') slider: ElementRef | undefined;
  sliderActiveIndex = new BehaviorSubject<number>(0);

  isMobile = this.uiService.isMobile;
  isPhysicalDesktop = this.deviceAndBrowserDetectorService.isDesktop();
  isiOSiPhone = this.deviceAndBrowserDetectorService.isiOSiPhone();
  isFirefox = this.deviceAndBrowserDetectorService.isFirefox();

  domListener = this.domListenerFactoryService.createInstance();
  notFitAllSteps = new BehaviorSubject<boolean>(false);
  // Used to support steps scrolling on touch devices
  touchStartX = 0;
  distanceX = 0;

  // used to show refresh button only in permission denied guide
  // when:
  // is android
  // is iphone and in last step
  // Physical desktop and is firefox
  // tablet device and fit all steps
  showRefreshButton = false;

  showDeviceSettingsButton = false;
  deviceSettingButtonInerHTML = '';

  constructor(
    private uiService: UiService,
    private deviceAndBrowserDetectorService: DeviceAndBrowserDetectorService,
    private translateService: TranslateService,
    private domListenerFactoryService: DomListenerFactoryService,
  ) {
    this.uiService.isMobile.pipe(pairwise(), untilDestroyed(this)).subscribe(([prev, curr]) => {
      if (prev && !curr) {
        this.resetSliderState();
      }
    });
  }

  ngOnInit(): void {
    if (this.guide.constructor.name === 'PermissionHelper') {
      this.showRefreshButton = true;
    }

    this.steps = this.guide.getSteps();
    this.mobileTitle = this.guide.getMobileTitle();
    this.desktopTitle = this.guide.getDesktopTitle();
    this.showDeviceSettingsButton = this.guide.showDeviceSettingsButton();
    this.deviceSettingButtonInerHTML = this.guide.deviceSettingButtonInerHTML();

    this.showStepsAccordingToScreenSize();
    this.setupJoinCallModalResizeListener();
  }

  private setupJoinCallModalResizeListener() {
    this.domListener.add('window', 'resize', () => {
      this.resizeAllowedAccessModal();
    });
  }

  private resizeAllowedAccessModal() {
    // Used setTimeout as in case of iPad/Tablet, window.innerWidth won't reflect the new width after orienting
    // It's due to window width is updated after the orienation animation is done
    modifiedSetTimeout(() => {
      this.showStepsAccordingToScreenSize();
    }, DEVICE_ORIENTATION_DELAY);
  }

  private showStepsAccordingToScreenSize() {
    this.steps = this.guide.getSteps();
    this.mobileTitle = this.guide.getMobileTitle();
    this.desktopTitle = this.guide.getDesktopTitle();

    const root = document.documentElement;
    if (!this.guide.canFitAllSteps()) {
      this.notFitAllSteps.next(true);
      root.style.setProperty('--guide-width', '560px');
      return;
    }
    root.style.setProperty('--guide-width', `${this.guide.guideWidth}px`);
    if (this.notFitAllSteps.getValue()) {
      this.resetSliderState();
    }
    this.notFitAllSteps.next(false);
  }

  ngOnDestroy(): void {
    this.domListener.clear();
  }

  onTouchStart(event: TouchEvent) {
    this.touchStartX = event.changedTouches[0].clientX;
  }

  onTouchMove(event: TouchEvent) {
    const touchX = event.changedTouches[0].clientX;
    this.distanceX = touchX - this.touchStartX;
  }

  onTouchEnd(event: TouchEvent) {
    this.touchStartX = 0;
    if (Math.abs(this.distanceX) > 50) {
      event.preventDefault(); // Prevent vertical scrolling
    }

    if (this.distanceX > 0) {
      // Swipe right
      this.backHelperLayout();
    } else if (this.distanceX < 0) {
      // Swipe left
      this.nextHelperLayout();
    }
    this.distanceX = 0;
  }

  showSpecificHelperLayout(index: number) {
    if (this.slider) {
      this.sliderActiveIndex.next(index);
      const slideWidth = this.slider?.nativeElement.offsetWidth;
      const transform = `translateX(-${slideWidth * index}px)`;
      this.slider.nativeElement.scrollLeft = slideWidth * index;
      this.slider.nativeElement.style.transform = transform;
    }
  }

  nextHelperLayout() {
    if (this.sliderActiveIndex.getValue() < this.steps.length - 1) {
      this.showSpecificHelperLayout(this.sliderActiveIndex.getValue() + 1);
    }
  }

  backHelperLayout() {
    if (this.sliderActiveIndex.getValue() > 0) {
      this.showSpecificHelperLayout(this.sliderActiveIndex.getValue() - 1);
    }
  }

  execuateHelperAction(action: string) {
    switch (this.translateService.instant(action)) {
      case HelperAction.DONE:
        this.done.emit();
        break;
      case HelperAction.NEXT:
        this.nextHelperLayout();
        break;
      case HelperAction.REFRESH:
        this.refresh();
        break;
      default:
        this.done.emit();
    }
  }

  onClose() {
    this.closeModal.emit();
  }

  onMaybeLater() {
    this.closeModal.emit();
  }

  openDeviceSettings() {
    window.open(this.guide.deviceSettingButtonSrc(), '_blank');
  }

  liveSupport() {
    (window as any).Intercom('showSpace', 'home');
  }

  private resetSliderState() {
    if (this.slider) {
      this.slider.nativeElement.style.transform = 'unset';
      this.sliderActiveIndex.next(0);
    }
  }

  private refresh() {
    window.location.reload();
  }
}

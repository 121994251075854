import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'qrcode',
})
export class QrcodePipe implements PipeTransform {
  transform(qrValue, args?: any): any {
    if (args?.type === 'worksheet') {
      return `https://${window.location.host}/content/worksheets/list/${args.courseId}/${qrValue}`;
    }
    return `https://${window.location.host}/content/view/${qrValue._id || qrValue}`;
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { modifiedSetTimeout } from 'src/app/utilities/ZoneUtils';

@Component({
  selector: 'app-embedded-google-login',
  templateUrl: './embedded-google-login.component.html',
  styleUrls: ['./embedded-google-login.component.scss'],
})
export class EmbeddedGoogleLoginComponent implements OnInit {
  linkCopied = false;
  loginLink = '';

  constructor(private dialogRef: MatDialogRef<EmbeddedGoogleLoginComponent>) {}

  ngOnInit(): void {
    this.loginLink = location.origin + location.pathname;
  }

  copyLink() {
    this.linkCopied = true;
    modifiedSetTimeout(() => {
      this.linkCopied = false;
    }, 5000);
    navigator.clipboard.writeText(this.loginLink);
  }

  close() {
    this.dialogRef.close();
  }
}

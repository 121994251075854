import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-upload-pdf-preparing-document',
  templateUrl: './upload-pdf-preparing-document.component.html',
  styleUrls: ['./upload-pdf-preparing-document.component.scss'],
})
export class UploadPdfPreparingDocumentComponent {
  @Input() value: number = 0;
  @Input() fileName = '';
}

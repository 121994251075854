<table *ngIf="tableData?.length">
  <tr
    *ngFor="let tr of tableData; let trIndex = index"
    [class.header]="fragment?.metadata?.is_row_header && trIndex === 0"
  >
    <td
      *ngFor="let td of tr; let tdIndex = index"
      [class.header]="fragment?.metadata?.is_col_header && tdIndex === 0"
      [style.width]="td[0]?.metadata?.width + 'px'"
    >
      <ui-fragment-container [fragments]="td"></ui-fragment-container>
    </td>
  </tr>
</table>
<p class="invalid-table" *ngIf="!tableData.length">
  {{ 'Table data incorrectly formatted. Please remove and recreate this table.' | translate }}
</p>

<h2 mat-dialog-title>
  {{ 'Please publish all questions before changing set visibility.' | translate }}
</h2>
<div mat-dialog-content>
  <p>
    One or more questions in this set are still drafts. Please publish all questions in this set to
    change set visibility.
  </p>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button class="btn btn-primary cpx-24" [mat-dialog-close]="false">
    {{ 'Okay' | translate }}
  </button>
</div>

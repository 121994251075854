import handRaisedGesture from './gestures/handRaised';
import thumbsDownGesture from './gestures/thumbsDown';
import thumbsUpGesture from './gestures/thumbsUp';
import victoryHandsGesture from './gestures/victoryHands';
import { Finger } from './models/finger';
import { GestureDetector, GestureName } from './models/gesture';

export function getGesture(keypoints3D: any, handedness: any): GestureName | undefined {
  const f = new Finger(keypoints3D, handedness);
  const handInfo = f.estimate();

  // Detect if Hand is raised
  if (GestureDetector.detect(handInfo, handRaisedGesture)) {
    return GestureName.HAND_RAISED;
  } else if (GestureDetector.detect(handInfo, thumbsUpGesture)) {
    return GestureName.THUMBS_UP;
  } else if (GestureDetector.detect(handInfo, thumbsDownGesture)) {
    return GestureName.THUMBS_DOWN;
  } else if (GestureDetector.detect(handInfo, victoryHandsGesture)) {
    return GestureName.VICTORY_HANDS;
  }
  return undefined;
}

import { Injectable } from '@angular/core';
import { filterNil } from '@ngneat/elf';
import { Observable, map, of, shareReplay } from 'rxjs';
import { SUBSCRIPTION_TYPES, proFeatureSubscriptionTypes } from '../models/payment';
import { ISession } from '../models/session';
import { User } from '../models/user';
import { SpaceRepository, getSpaceOwner } from '../state/space.repository';
import { UserService } from './user.service';

export enum UpgradePlanEvaluationType {
  'SELF',
  'SPACE',
}

@Injectable({
  providedIn: 'root',
})
export class PaywallIndicatorService {
  private get user(): User | undefined {
    return this.userService.user.getValue()?.user;
  }

  constructor(private spaceRepo: SpaceRepository, private userService: UserService) {}

  // check either the space has an institution or the user who created a space has subscription type or not
  public isSpaceInSubscription$ = (
    subscriptions: SUBSCRIPTION_TYPES | SUBSCRIPTION_TYPES[],
  ): Observable<boolean> =>
    this.spaceRepo.activeSpace$.pipe(
      filterNil(),
      map((space) => this._isSpaceInSubscription(space, subscriptions)),
    );

  public hasValidSubscription(
    requiredSubscriptions: SUBSCRIPTION_TYPES[],
    evaluationType: UpgradePlanEvaluationType,
  ): Observable<boolean> {
    switch (evaluationType) {
      case UpgradePlanEvaluationType.SPACE:
        return this.isSpaceInSubscription$(requiredSubscriptions).pipe(
          shareReplay({ bufferSize: 1, refCount: true }),
        );

      case UpgradePlanEvaluationType.SELF:
        return of(this.isUserInSubscription(requiredSubscriptions)).pipe(
          shareReplay({ bufferSize: 1, refCount: true }),
        );
    }
  }

  public doesUserSatisfiesPaywallEvaluationCriteria(
    evaluationType: UpgradePlanEvaluationType,
  ): Observable<boolean> {
    switch (evaluationType) {
      case UpgradePlanEvaluationType.SPACE:
        return this.spaceRepo.isCurrentUserOwnerOfSpace$.pipe(
          shareReplay({ bufferSize: 1, refCount: true }),
        );
      case UpgradePlanEvaluationType.SELF:
        return of(true);
    }
  }

  public canUseProFeature$ = this.isSpaceInSubscription$(proFeatureSubscriptionTypes);

  // When provided a set of subscriptions, check if user has a valid subscription
  private _isUserInSubscription(
    user: User | undefined,
    subscriptions: SUBSCRIPTION_TYPES | SUBSCRIPTION_TYPES[],
  ) {
    if (user?.subscriptionType) {
      return Array.isArray(subscriptions)
        ? subscriptions.includes(user.subscriptionType)
        : subscriptions === user.subscriptionType;
    } else {
      return false;
    }
  }

  private _isSpaceInSubscription(
    space: ISession | undefined,
    subscriptions: SUBSCRIPTION_TYPES | SUBSCRIPTION_TYPES[],
  ) {
    if (space?.institution) {
      return true;
    } else {
      return this._isUserInSubscription(getSpaceOwner(space), subscriptions);
    }
  }

  // check if user has the right subscription type
  public isUserInSubscription = (
    subscriptions: SUBSCRIPTION_TYPES | SUBSCRIPTION_TYPES[],
  ): boolean => this._isUserInSubscription(this.user, subscriptions);

  public isWebViewerEnabled(): boolean {
    if (!this.spaceRepo.activeSpace?.institution) {
      return true;
    }
    // We're getting the "enableWebViewer" setting from the institution's settings.
    // we're treating it as if it's turned on.
    // This is done to ensure backward compatibility with existing institutions
    // that may not have this setting defined yet.
    // If the setting is not explicitly set (i.e., it's `null` or `undefined`),
    // Will get true in the following cases :
    // 1 - enableWebviewer setting is not set ( already existing institutions)
    // 2 - enableWebviewer setting is true
    // Will get false in the following case:
    // 1 - enableWebviewer setting is false
    const enableWebViewer = this.spaceRepo.activeSpace.institution.settings?.enableWebViewer;
    return enableWebViewer !== false;
  }
}

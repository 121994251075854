import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Fragment } from '../models/question';
import { FragmentType } from '../common/typed-fragment/typed-fragment';

@Pipe({
  name: 'formatChatPreview',
})
export class FormatChatPreviewPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(fragments: Fragment[]): string {
    const linksPrefix = ['https://', 'http://', 'www.'];
    let messageContent = '';
    if (fragments && fragments.length) {
      if (fragments[0].type === FragmentType.Image) {
        messageContent = this.translateService.instant('Sent an image');
      }
      if (fragments[0].type === FragmentType.Text) {
        if (linksPrefix.find((prefix) => fragments[0].data.startsWith(prefix))) {
          messageContent = this.translateService.instant('Shared a link');
        } else {
          messageContent = fragments[0].data;
        }
      }
    }
    return messageContent;
  }
}

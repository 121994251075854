import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLService } from 'src/app/services/dynamic-url.service';
import {
  RequestAccessClientBaseService,
  SPACES_URL,
  headersWithSkipErrorInterceptor,
  httpOptions,
} from './base/request-access-client-base.service';
import { AccessResponseBody } from './base/request-access-base.service';

const ACCESS_REQUEST_URL: string = 'accessRequest';
const ACCESS_REQUEST_STATE_URL: string = 'accessRequestState';
const ACCESS_RESPOND_URL: string = 'accessResponse';

@Injectable({
  providedIn: 'root',
})
export class RequestAccessClientService extends RequestAccessClientBaseService {
  constructor(protected http: HttpClient, protected urlService: URLService) {
    super(http, urlService);
  }

  sendAccessRequest(sessionID: string): Observable<any> {
    return this.http.patch<any>(
      `${this.urlService.getDynamicUrl()}/${SPACES_URL}/${sessionID}/${ACCESS_REQUEST_URL}`,
      JSON.stringify({}),
      headersWithSkipErrorInterceptor,
    );
  }

  respondToAccessRequest(sessionID: string, body: AccessResponseBody): Observable<any> {
    return this.http.patch<AccessResponseBody>(
      `${this.urlService.getDynamicUrl()}/${SPACES_URL}/${sessionID}/${ACCESS_RESPOND_URL}`,
      JSON.stringify(body),
      httpOptions,
    );
  }

  getRequestAccessState(sessionID: string, requesterID: string): Observable<any> {
    return this.http.get<any>(
      `${this.urlService.getDynamicUrl()}/${SPACES_URL}/${sessionID}/${ACCESS_REQUEST_STATE_URL}/${requesterID}`,
      httpOptions,
    );
  }
}

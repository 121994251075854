import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CustomAttribute } from '../../models/user';

@Component({
  selector: 'app-custom-attribute-pill',
  templateUrl: './custom-attribute-pill.component.html',
  styleUrls: ['./custom-attribute-pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomAttributePillComponent {

  @Input() userCustomAttributes: string[] = [];
  @Input() allCustomAttributes: CustomAttribute[] = [];
  @Input() withTooltip = true;
  @Input() isCollapsed = true;
  @Input() showLimit = 5;

  /**
   * map the users attributes IDs to values
   * for example from [id:id2] to [School:Name]
   */
  getUserCustomAttributeValues(): string[] {
    const customValues: string[] = [];
    this.userCustomAttributes.forEach((userCustomAttribute) => {
      const [attributeKeyId, attributeValueId] = userCustomAttribute.split(':');
      const customAttributeData = this.allCustomAttributes.find(c => c.attribute_key._id === attributeKeyId);
      if (customAttributeData) {
        // this is the custom attribute key value like School
        let s = customAttributeData.attribute_key.value;
        // this is the custom attribute available values like School Name
        const value = customAttributeData.attribute_value.find(customValue => customValue._id === attributeValueId )?.value ?? '';
        s+= `:${value}`;
        customValues.push(s);
      }
    });

    return customValues;
  }


}

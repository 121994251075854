import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'courseTooltip'
})

export class CourseTooltipPipe implements PipeTransform {

  // TODO: adding Type for courseLookup as Map<string, string>. need to make all Files component init it as a Map.
  transform(courseIds: string[], courseLookup = new Map(), pill = false): string {
    // Get courses accessed by user, and sorted alphabetically.
    const courses = (
      Array.from(courseIds.map(courseId => courseLookup.get(courseId)).values())
           .filter(a => a?.length) as string[]
      ).sort((a: string, b: string) => a.localeCompare(b));

    const diff = courseIds.length - courses.length;
    // If all courses are not visible to user, then Tooltip: X other courses
    if (!courses.length) {
      return `${courseIds.length}${!pill ? ' other' : ''} ${this.addPluralS(courseIds.length, 'course')}`;
    }
    // If some courses are not visible to user, then Tooltip: Course X, Course Y, and Z others (or 1 other, if only 1 course)
    else if (diff > 0) {
      return `${courses.join(', ')}, and ${diff} other ${this.addPluralS(diff, 'course')}`;
    }
    // If all course visible to user, then Tooltip: Course X, Course Y, Course Z
    return courses.join(', ');
  }

  addPluralS(count: number, s: string) {
    return s.concat(count > 1 ? 's' : '');
  }

}

<div class="d-flex cmb-16">
  <div
    class="level level1"
    [class.active]="difficulty === 1"
    (click)="changeDifficulty.emit(1)"
  ></div>
  <div
    class="level level2"
    [class.active]="difficulty === 2"
    (click)="changeDifficulty.emit(2)"
  ></div>
  <div
    class="level level3"
    [class.active]="difficulty === 3"
    (click)="changeDifficulty.emit(3)"
  ></div>
  <div
    class="level level4"
    [class.active]="difficulty === 4"
    (click)="changeDifficulty.emit(4)"
  ></div>
</div>
<label class="label" *ngIf="!difficulty">{{ 'No level set' | translate }}</label>
<label class="label label1" *ngIf="difficulty === 1">{{ 'Basic' | translate }}</label>
<label class="label label2" *ngIf="difficulty === 2">{{ 'Average' | translate }}</label>
<label class="label label3" *ngIf="difficulty === 3">{{ 'Advanced' | translate }}</label>
<label class="label label4" *ngIf="difficulty === 4">{{ 'Challenge' | translate }}</label>

<div>
  <div id="items-parent-container">
    <div
      *ngIf="(clearing$ | async) === false"
      id="items-container"
      [class.fabric-objects-visible]="
        (isAppSelected$ | async) && (fullscreenId$ | async) === undefined
      "
    >
      <div
        #items
        *ngFor="let item of canvasItemsArray$ | async; let i = index"
        style="position: absolute; z-index: -1; touch-action: none"
        [style.z-index]="zIndexObservables$[item.id] ? (zIndexObservables$[item.id] | async) : 1"
        [hidden]="(fullscreenId$ | async) && (fullscreenId$ | async) !== item.id"
        [style.transform]="
          (fullscreenId$ | async)
            ? 'none'
            : !this.static
            ? 'matrix(' + (transformationMatrix$ | async)?.toString() + ')'
            : 'matrix(' + viewPortTransform.toString() + ' )'
        "
      >
        <app-item-wrapper
          class="item-wrapper"
          *ngIf="item.itemSettings && (invisibilityObservables$[item.id] | async) !== true"
          [canvasItem]="item"
          [relatedFabricObject]="item.relatedFabricItem"
          [static]="static"
          [staticCanvas]="staticCanvas"
          [loadForBoardPreview]="!!prevFrame"
          (itemStateChanged)="sendChangeStateEvent($event)"
          (sentDeleteItemEvent)="sentDeleteItemEvent($event)"
        ></app-item-wrapper>
      </div>
    </div>
  </div>
  <div *ngIf="insertingItem$ | async" class="insert-message">
    <span class="text">
      {{ 'Click on an area on the board to insert a formula.' | translate }}
    </span>
  </div>
</div>

<div
  #messageListContainer
  *ngIf="!displayLoading"
  id="message-list-container"
  class="message-list-container"
>
  <div class="flex-1"></div>
  <p *ngIf="displayTyping()" class="typing-indicator">
    {{ 'Typing...' | translate }}
  </p>
  <section *ngIf="pendingMessages && pendingMessages?.length" class="failed-message-container">
    <div *ngIf="isFirstChatOfDay(pendingMessages[0])" class="message-group__partition">
      <hr class="message-group__partition--line" />
      <p class="message-group__partition--text">
        {{ getCurrentData() | dateLocale: DateFormatEnum.LONGDATE2 }}
      </p>
      <hr class="message-group__partition--line" />
    </div>

    <ng-template [ngIf]="addMessageFailed">
      <section
        *ngFor="let pendingMessage of pendingMessages; let i = index; trackBy: trackByCreateDate"
        class="failed-message"
      >
        <message-content-wrapper
          [id]="pendingMessage._id"
          [sender]="true"
          [hideReply]="true"
          [isPendingMessage]="true"
          [pendingMessageIndex]="i + checkConsecutiveChat(pendingMessage)"
          [pendingMessageSourceLength]="
            pendingMessages?.length + checkConsecutiveChat(pendingMessage)
          "
          [message]="pendingMessage"
          [messageGroups]="conversationContent.messages"
          (deleteMessage)="deletePendingMessage(pendingMessage)"
        >
          <div class="failed-message-wrapper">
            <section class="chat-content-wrapper" [id]="'chat' + pendingMessage._id">
              <div class="failed-message-content ng-content-styling">
                <ui-fragment-container
                  *ngIf="!pendingMessage.deleted"
                  class="nonQuestionFragment"
                  [fragments]="pendingMessage?.content"
                  [enableCopy]="true"
                  [showLoadingIndicator]="true"
                ></ui-fragment-container>
                <p class="deleted-chat-text" *ngIf="pendingMessage.deleted">
                  {{
                    pendingMessage.deletedByHost ? 'Message deleted by host.' : 'Message deleted.'
                  }}
                </p>
              </div>
            </section>
          </div>
        </message-content-wrapper>
        <failed-message-retry
          [pendingMessage]="pendingMessage"
          [hidden]="!pendingMessage?.failed"
        ></failed-message-retry>
      </section>
    </ng-template>
  </section>

  <span *ngIf="!conversationContent.messages.length && !pendingMessages?.length" class="empty-chat">
    {{ 'Start the conversation by sending a message!' | translate }}
  </span>

  <ng-container
    *ngFor="
      let messageGroup of conversationContent.messages | messageGroupsByDate;
      trackBy: trackByDate
    "
  >
    <ng-container *ngIf="isContentMessage(messageGroup); else groupUpdateMessage">
      <div class="sent-message-group">
        <div class="message-group__partition">
          <hr class="message-group__partition--line" />
          <p class="message-group__partition--text">
            {{ messageGroup.date }}
          </p>
          <hr class="message-group__partition--line" />
        </div>
        <div class="message-group-content">
          <ng-container
            *ngFor="
              let successiveMessageGroup of messageGroup.messages | messageGroupsSuccessiveUserChat;
              let groupIndex = index;
              trackBy: trackByDateAndUserId
            "
          >
            <ng-container
              *ngFor="
                let message of successiveMessageGroup.messages;
                trackBy: trackByIdAndUpdatedAt;
                let i = index
              "
            >
              <ng-container
                #sentMessageGroup
                *ngIf="message.author._id === conversationContent.currentUser._id"
                class="sent-chat-card"
              >
                <ng-container
                  #editorMessage
                  *ngIf="editorMessage?._id === message._id; else renderedMessage"
                >
                  <div #editorMessageControls class="d-flex justify-content-end">
                    <button
                      class="mutate-button material-icons"
                      type="button"
                      (click)="saveEditorMessage(message)"
                      data-name="chat-tab-sent-message-options-edit-save"
                    >
                      save
                    </button>
                    <button
                      class="mutate-button material-icons"
                      type="button"
                      (click)="closeEditor()"
                      data-name="chat-tab-sent-message-options-edit-close"
                    >
                      close
                    </button>
                    <button
                      class="mutate-button material-icons"
                      type="button"
                      (click)="emitDeleteMessage(message)"
                      data-name="chat-tab-sent-message-options-edit-delete"
                    >
                      delete
                    </button>
                  </div>
                  <div *ngIf="editorCollection?.fragments | emptyCheck" class="error">
                    Chat can not be empty
                  </div>
                  <app-advanced-editor
                    [collection]="editorCollection"
                    [hideToolbar]="true"
                    [type]="editorTypes.MESSAGE"
                    [placeholder]="'Type message here...' | translate"
                    class="nonQuestionFragment overflow-hidden"
                    style="font-size: 14px"
                  >
                  </app-advanced-editor>
                </ng-container>
                <ng-template #renderedMessage>
                  <message-content-wrapper
                    class="sent-message-content-wrapper"
                    [id]="message._id"
                    [sender]="true"
                    [message]="message"
                    [index]="i + checkSuccessiveChat(message, groupIndex)"
                    [sourceLength]="
                      successiveMessageGroup.messages.length +
                      checkSuccessiveChat(message, groupIndex)
                    "
                    [messageGroups]="conversationContent.messages"
                    [enableEditing]="enableEditing"
                    (editMessage)="setEditorMessage($event)"
                    (replyMessage)="emitReplyMessage($event)"
                    (deleteMessage)="emitDeleteMessage($event)"
                  >
                    <section class="chat-content-wrapper" [id]="'chat' + message._id">
                      <div
                        class="nonQuestionFragment ugcFont ng-content-styling"
                        [class.includes-video]="message.content | includesVideo"
                        [class.cursor-pointer]="editorMessage === null"
                        [ngClass]="{
                          'image-message-content': message.content[0]?.type === 'IMAGE',
                          'sent-message-content':
                            message.content[0]?.type !== 'IMAGE' && !message.deleted,
                          'deleted-chat': message.deleted
                        }"
                      >
                        <ui-fragment-container
                          *ngIf="!message.deleted"
                          class="nonQuestionFragment"
                          [fragments]="message.content"
                          [enableCopy]="true"
                          [signImage]="imageSigningEnabled"
                        ></ui-fragment-container>
                        <p class="deleted-chat-text" *ngIf="message.deleted">
                          {{
                            message.deletedByHost ? 'Message deleted by host.' : 'Message deleted.'
                          }}
                        </p>
                      </div>
                    </section>
                  </message-content-wrapper>
                </ng-template>
              </ng-container>

              <ng-container
                *ngIf="message.author._id !== conversationContent.currentUser._id"
                class="received-chat-card"
              >
                <message-content-wrapper
                  class="received-message-content-wrapper"
                  [sender]="false"
                  [id]="message._id"
                  [index]="i + checkSuccessiveChat(message, groupIndex)"
                  [sourceLength]="
                    successiveMessageGroup.messages.length +
                    checkSuccessiveChat(message, groupIndex)
                  "
                  [message]="message"
                  [messageGroups]="conversationContent.messages"
                  (replyMessage)="emitReplyMessage($event)"
                  (deleteMessage)="emitDeleteMessage($event)"
                >
                  <section class="chat-content-wrapper" [id]="'chat' + message._id">
                    <div
                      class="nonQuestionFragment ugcFont ng-content-styling"
                      [ngClass]="{
                        'image-message-content': message.content[0]?.type === 'IMAGE',
                        'received-message-content':
                          message.content[0]?.type !== 'IMAGE' && !message.deleted,
                        'deleted-chat': message.deleted
                      }"
                      [class.includes-video]="message.content | includesVideo"
                    >
                      <ui-fragment-container
                        *ngIf="!message.deleted"
                        class="nonQuestionFragment"
                        [fragments]="message.content"
                        [enableCopy]="true"
                        [signImage]="imageSigningEnabled"
                      ></ui-fragment-container>
                      <p class="deleted-chat-text" *ngIf="message.deleted">
                        {{
                          message.deletedByHost ? 'Message deleted by host.' : 'Message deleted.'
                        }}
                      </p>
                    </div>
                  </section>
                </message-content-wrapper>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <!-- TODO: Angular can't do type narrowing here because of any, strict
    template compliance will break build -->
    <ng-template #groupUpdateMessage>
      <div
        *ngIf="messageGroup.updateType === UpdateType.CreateGroup"
        class="create-group-message mainText2 m0"
      >
        {{ (addCurrentUser(messageGroup) | messageAuthor) + ' created the group.' | translate }}
      </div>
    </ng-template>
  </ng-container>

  <div *ngIf="loadingMoreMessages" class="message-loading-spinner">
    <app-spinner [size]="30"></app-spinner>
  </div>
</div>

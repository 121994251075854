import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IndividualUserOverview, User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { ForegroundActivityNotificationsService } from '../../services/foreground-activity-notifications.service';

@UntilDestroy()
@Component({
  selector: 'app-profile-photo-with-attachments',
  templateUrl: './profile-photo-with-attachments.component.html',
  styleUrls: ['./profile-photo-with-attachments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePhotoWithAttachmentsComponent implements OnInit {
  @Input() user: User | IndividualUserOverview | undefined;
  @Input() width?: number;
  @Input() fontSize?: number;
  @Input() isClickEnabled = true;
  @Input() showName = false;
  @Input() showYouWord = false;
  @Input() enableInactivityIndicator = false;
  @Input() small = false;
  @Input() enableTooltips = false;

  tooltip$ = new BehaviorSubject<string>('');

  foregroundInactivityObservable?: Observable<boolean>;

  constructor(
    public userService: UserService,
    public foregroundActivityNotificationsService: ForegroundActivityNotificationsService,
    public translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    if (this.user?._id) {
      this.foregroundInactivityObservable =
        this.foregroundActivityNotificationsService.getUserInactivityObservable(this.user._id);
      this.foregroundInactivityObservable?.pipe(untilDestroyed(this)).subscribe((inactive) => {
        this.tooltip$.next(inactive ? this.translateService.instant('Participant inactive') : '');
      });
    }
  }
}

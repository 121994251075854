import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-points',
  templateUrl: './points.component.html',
  styleUrls: ['./points.component.scss'],
})
export class PointsComponent {
  @Input() points = 0;
  @Output() changePoints = new EventEmitter();

  @ViewChild('pointContainer') container!: ElementRef;

  active = false;

  add(e: Event) {
    e.stopPropagation();
    this.points = this.points + 1;
    this.changePoints.emit(this.points);
  }

  remove(e: Event) {
    e.stopPropagation();
    this.points = this.points > 1 ? this.points - 1 : 1;
    this.changePoints.emit(this.points);
  }

  @HostListener('window:click', ['$event'])
  onClick(e: Event) {
    if (!e.composedPath().includes(this.container.nativeElement)) {
      this.active = false;
    }
  }
}

import { TranslateService } from '@ngx-translate/core';

const RESEND_COUNTDOWN_TIMEOUT = 15; // seconds to countdown

const PROVIDERS_SOCIAL = {
  GOOGLE: 'google.com',
  FACEBOOK: 'facebook.com',
  MICROSOFT: 'microsoft.com',
};

const PROVIDERS_ALL = {
  ...PROVIDERS_SOCIAL,
  PASSWORD: 'password',
  EMAIL_CODE: 'email_code',
  CLEVER: 'oidc.clever',
  GOOGLE_SSO: 'saml.gsuite',
  SAML: 'saml',
  OIDC: 'oidc',
  CLASSLINK: 'classlink',
};

const PROVIDERS_TO_FEATURE_FLAGS = {
  [PROVIDERS_SOCIAL.GOOGLE]: 'google_login',
  [PROVIDERS_SOCIAL.FACEBOOK]: 'facebook_login',
  [PROVIDERS_SOCIAL.MICROSOFT]: 'microsoft_login',
  [PROVIDERS_ALL.CLEVER]: 'clever_login',
  [PROVIDERS_ALL.CLASSLINK]: 'classlink_login',
  [PROVIDERS_ALL.SAML]: 'saml_login',
  [PROVIDERS_ALL.OIDC]: 'oidc_login',
};

export class AccountsConstantsTransformer {
  PROVIDERS_TO_NAMES: any = {};

  constructor(private translateService: TranslateService) {
    this.PROVIDERS_TO_NAMES = {
      [PROVIDERS_ALL.GOOGLE]: this.translateService.instant('Google'),
      [PROVIDERS_ALL.FACEBOOK]: this.translateService.instant('Facebook'),
      [PROVIDERS_ALL.MICROSOFT]: this.translateService.instant('Microsoft'),
      [PROVIDERS_ALL.PASSWORD]: this.translateService.instant('Email'),
      [PROVIDERS_ALL.EMAIL_CODE]: this.translateService.instant('Email Code'),
      [PROVIDERS_ALL.CLEVER]: this.translateService.instant('Clever'),
      [PROVIDERS_ALL.CLASSLINK]: this.translateService.instant('ClassLink'),
      [PROVIDERS_ALL.GOOGLE_SSO]: this.translateService.instant('Google SSO'),
    };
  }

  get providersToNames() {
    return this.PROVIDERS_TO_NAMES;
  }
}

export { RESEND_COUNTDOWN_TIMEOUT, PROVIDERS_SOCIAL, PROVIDERS_ALL, PROVIDERS_TO_FEATURE_FLAGS };

import { fabric } from 'fabric';
import { StickyNote } from './sticky-note';

export enum CustomFabricTypes {
  STICKY_NOTE = 'sticky-note',
}

export interface Position {
  top: number;
  left: number;
  width: number;
  height: number;
}
// Marker interface for custom fabric's extra data
export interface BaseCustomFabricData {}

export abstract class BaseCustomFabric extends fabric.Object {
  static readonly CUSTOM_FABRIC_DATA = 'custom-fabric-data';
  static readonly ATTR_FIELD_NAME = 'customObjectType';

  abstract ['custom-fabric-data']?: BaseCustomFabricData;
  abstract customObjectType: string;
  abstract remoteDelete: boolean;
  abstract locked: boolean;
  abstract canDeleteWithEraser: boolean;

  abstract toObject(args?: string[]): fabric.Object;
  abstract fromObject(object: fabric.Object): fabric.Object;
  abstract onRemoteModify(object: fabric.Object): fabric.Object;
  abstract setTopLeftPosition(
    object: fabric.Object,
    mouseLocation: { x: number; y: number },
    selectionCenter: { x: number; y: number },
  ): fabric.Object;
  abstract lockObject(): void;
  abstract unlockObject(): void;
  abstract getObjectAbsolutePosition(): Position;

  public static parseObject(object: fabric.Object): fabric.Object {
    if (BaseCustomFabric.ATTR_FIELD_NAME in object) {
      switch (object[BaseCustomFabric.ATTR_FIELD_NAME]) {
        case CustomFabricTypes.STICKY_NOTE:
          return new StickyNote().fromObject(object);
        default:
          return object;
      }
    }
    return object;
  }

  public static updateObjectPosition(
    object: fabric.Object,
    mouseLocation: { x: number; y: number },
    selectionCenter: { x: number; y: number },
  ) {
    if (
      BaseCustomFabric.ATTR_FIELD_NAME in object &&
      object[BaseCustomFabric.ATTR_FIELD_NAME] === CustomFabricTypes.STICKY_NOTE
    ) {
      return new StickyNote().setTopLeftPosition(object, mouseLocation, selectionCenter);
    }
    return object;
  }

  public static isCustomFabricObject(object: object): boolean {
    return (
      BaseCustomFabric.ATTR_FIELD_NAME in object &&
      Boolean(object[BaseCustomFabric.ATTR_FIELD_NAME])
    );
  }

  public static convertObjectToCustomFabricObject(
    object: fabric.Object,
  ): BaseCustomFabric | undefined {
    if (BaseCustomFabric.isCustomFabricObject(object)) {
      return object as BaseCustomFabric;
    }
    return undefined;
  }
}

import { Injectable, NgZone } from '@angular/core';
import * as Comlink from 'comlink';
import { TimerClass, TimerType } from '../web-workers/timer.worker';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  userTimerClass?: Comlink.Remote<TimerClass> | TimerClass;

  timeoutTimerMap: Map<NodeJS.Timer, Function> = new Map();
  intervalTimerMap: Map<NodeJS.Timer, Function> = new Map();

  constructor(private zone: NgZone) {}

  async setup(): Promise<void> {
    const worker = new Worker(new URL('../web-workers/timer.worker.ts', import.meta.url));
    let cb;

    // Register for onmessage out of zone to not trigger CDs
    this.zone.runOutsideAngular(() => {
      worker.onmessage = ({ data }) => {
        switch (data.source) {
          case TimerType.interval:
            cb = this.intervalTimerMap.get(data.id);
            break;
          case TimerType.timeout:
            cb = this.timeoutTimerMap.get(data.id);
            break;
          default:
            break;
        }
        if (cb) {
          cb();
        }
      };
    });
    const remoteTimerWorker = Comlink.wrap<typeof TimerClass>(worker);
    this.userTimerClass = await new remoteTimerWorker();
  }

  async setTimeout(cb: Function, timeoutDurationInMilliseconds: number): Promise<NodeJS.Timer> {
    if (!this.userTimerClass) {
      await this.setup();
    }
    const timeoutId = await this.userTimerClass!.setTimeout(timeoutDurationInMilliseconds);
    this.timeoutTimerMap.set(timeoutId, cb);
    return timeoutId;
  }

  async setInterval(cb: Function, intervalDurationInMilliseconds: number): Promise<NodeJS.Timer> {
    if (!this.userTimerClass) {
      await this.setup();
    }
    const timeoutId = await this.userTimerClass!.setInterval(intervalDurationInMilliseconds);
    this.intervalTimerMap.set(timeoutId, cb);
    return timeoutId;
  }

  async clearTimeout(timeoutId: NodeJS.Timer): Promise<void> {
    if (!this.timeoutTimerMap.has(timeoutId)) {
      return;
    }
    await this.userTimerClass?.clearTimeout(timeoutId);
    this.timeoutTimerMap.delete(timeoutId);
  }

  async clearInterval(intervalId: NodeJS.Timer): Promise<void> {
    if (!this.intervalTimerMap.has(intervalId)) {
      return;
    }
    await this.userTimerClass?.clearInterval(intervalId);
    this.intervalTimerMap.delete(intervalId);
  }
}

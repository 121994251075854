import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TelemetryService } from 'src/app/services/telemetry.service';
import { TranslateService } from '@ngx-translate/core';
import { SUCCESSES } from '../common/utils/notification-constants';
import { RatingDialogComponent } from '../dialogs/rating-dialog/rating-dialog.component';
import { ToasterPopupStyle } from '../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { IconMessageToasterElement } from '../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from './notification-toaster.service';

export interface IAppRatingData {
  // boolean flag indicates if user has already done rating
  rateDone: boolean;
  // last time user used the app
  lastActiveDate: Date;
  // indicates user active days count
  activeDaysCount: number;
  // indicates at which activeDaysCount should show rating dialog agon
  showAgainAt: number;
}

@Injectable({
  providedIn: 'root',
})
export class AppRatingService {
  private static readonly APP_RATING_KEY = 'pencil-rating';
  ratingData: IAppRatingData = {
    rateDone: false,
    lastActiveDate: new Date(),
    activeDaysCount: 1,
    showAgainAt: 3,
  };

  private dialogIsOpened = false;
  private dialogRef: MatDialogRef<RatingDialogComponent> | null = null;

  constructor(
    public dialog: MatDialog,
    private translateService: TranslateService,
    private notificationToasterService: NotificationToasterService,
    private telemetry: TelemetryService,
  ) {}

  public openDialog(): void {
    this.loadAppRatingData();

    if (!this.shouldShowRatingModal || this.dialogRef) {
      return;
    }

    this.dialogRef = this.dialog.open(RatingDialogComponent, {
      height: '365px',
      width: '400px',
      panelClass: 'no-padding-dialog-container',
      disableClose: true,
    });

    this.dialogRef.afterOpened().subscribe(() => {
      this.dialogIsOpened = true;
    });

    this.dialogRef
      .afterClosed()
      .subscribe((output: { rating: 1 | 2 | 3 | 4; feedback: string; ratingDone: boolean }) => {
        this.dialogIsOpened = false;
        if (output.ratingDone) {
          this.handleRatingDone(output.rating, output.feedback);
        } else {
          this.handleRateNextTime();
        }

        this.dialogRef = null;
      });
  }

  handleRatingDone(rating: 1 | 2 | 3 | 4, feedback: string): void {
    this.ratingData = {
      ...this.ratingData,
      rateDone: true,
    };

    this.telemetry.event('[rating_added]: ', { rating, feedback });

    this.updateLocalStorage();

    this.showSuccessNotification();
  }

  showSuccessNotification(): void {
    const title = [
      undefined,
      this.translateService.instant(this.translateService.instant('Thanks for the feedback!')),
    ];

    const notification = new NotificationDataBuilder(SUCCESSES.RATING_DONE)
      .type(NotificationType.SUCCESS)
      .style(ToasterPopupStyle.SUCCESS)
      .topElements([new IconMessageToasterElement(...title)])
      .middleElements([
        new IconMessageToasterElement(
          undefined,
          this.translateService.instant(
            'We appreciate you telling us about your experience on Pencil Spaces!',
          ),
        ),
      ])
      .priority(620)
      .timeOut(5)
      .width(250)
      .showProgressBar(false)
      .build();

    this.notificationToasterService.showNotification(notification);
  }

  handleRateNextTime(): void {
    this.ratingData = {
      ...this.ratingData,
      rateDone: false,
      showAgainAt: this.ratingData.activeDaysCount + 3,
    };

    this.updateLocalStorage();
  }

  loadAppRatingData(): void {
    try {
      const data: string | null = localStorage.getItem(AppRatingService.APP_RATING_KEY);
      if (data) {
        const parseData: IAppRatingData = JSON.parse(data);
        const { lastActiveDate } = parseData;
        this.ratingData = parseData;
        this.updateVisitsCount(new Date(lastActiveDate));
      } else {
        this.updateLocalStorage();
      }
    } catch (error) {
      this.updateLocalStorage();
    }
  }

  private updateVisitsCount(lastActiveDate: Date): void {
    const currentDate = new Date();
    if (
      !this.ratingData.rateDone &&
      (lastActiveDate.getDate() !== currentDate.getDate() ||
        lastActiveDate.getMonth() !== currentDate.getMonth() ||
        lastActiveDate.getFullYear() !== currentDate.getFullYear())
    ) {
      this.ratingData.activeDaysCount += 1;
    }
    this.ratingData.lastActiveDate = new Date();
    this.updateLocalStorage();
  }

  private updateLocalStorage(): void {
    localStorage.setItem(AppRatingService.APP_RATING_KEY, JSON.stringify(this.ratingData));
  }

  public get shouldShowRatingModal(): boolean {
    const { rateDone, activeDaysCount, showAgainAt } = this.ratingData;
    return (
      !this.dialogIsOpened && !rateDone && activeDaysCount >= 3 && showAgainAt === activeDaysCount
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Fragment } from '../models/question';

@Pipe({
  name: 'includesVideo',
})
export class IncludesVideoPipe implements PipeTransform {
  transform(content: Fragment[]): any {
    let flag = false;
    if (content) {
      content.forEach((c) => {
        if (c.type === 'YOUTUBE' && c.data) {
          flag = true;
        }
      });
    }
    return flag;
  }
}

<div class="board-user-photos" id="board-user-photos" *ngIf="users">
  <ng-container *ngFor="let user of shownUsers; let i = index">
    <app-profile-photo
      [user]="user"
      [width]="avatarWidth"
      [fontSize]="avatarFontSize"
      class="board-user-photo"
      [isClickEnabled]="false"
      [appTooltip]="profileTooltip"
      [appTooltipOffsetX]="[0, 1].includes(i) ? 10 : 0"
    >
    </app-profile-photo>
    <ng-template #profileTooltip>
      <app-tooltip
        [text]="user.name"
        [arrow]="'bottom'"
        [tipStyle]="[0, 1].includes(i) ? { left: (i + 1) * 25 + 'px' } : {}"
      ></app-tooltip>
    </ng-template>
  </ng-container>
  <div *ngIf="otherUsers.length">
    <span
      [ngStyle]="{ 'z-index': maxUsers + 1 }"
      class="board-user-photo profile-icon"
      [style.width]="othersIconWidth"
      [style.height]="othersIconHeight"
      [appTooltip]="otherTooltip"
      [appTooltipOffsetX]="5"
    >
      &nbsp;+{{ otherUsers.length }}
    </span>
  </div>
  <ng-template #otherTooltip>
    <div class="tip-container">
      <div class="tip">
        <ng-container *ngFor="let user of otherUsers; let i = index">
          <div class="user-row">
            <app-profile-photo
              [user]="user"
              [width]="20"
              [isClickEnabled]="false"
              style="margin-right: 10px"
            >
            </app-profile-photo>
            <span class="tip-text">
              {{ user.name }}
              <span *ngIf="user.multipleTabsText">
                {{ user.multipleTabsText }}
              </span>
            </span>
          </div>
        </ng-container>
      </div>
      <div class="tip-after"></div>
    </div>
  </ng-template>
</div>
<div
  class="other-users-tooltip"
  *ngIf="useShowOthersListV2 && showOthersTooltip"
  (mouseleave)="handleTooltipMouseout($event)"
>
  <div
    class="tooltip-before"
    [style.left]="
      calculateBoardUsersPhotosWidth() !== 0
        ? calculateBoardUsersPhotosWidth() - 22 + 'px'
        : 20 + '%'
    "
  ></div>
  <div class="tooltip-content">
    <ng-container *ngFor="let user of otherUsers">
      <app-profile-photo
        [user]="user"
        [width]="26"
        [fontSize]="14"
        [isClickEnabled]="false"
        [showName]="true"
      >
      </app-profile-photo>
    </ng-container>
  </div>
</div>

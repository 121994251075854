import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, NgZone, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User } from 'src/app/models/user';
import { DevicesManagerService } from 'src/app/services/devices-manager.service';
import { PresenceProvider } from 'src/app/services/presence-provider';
import { RtcServiceController } from 'src/app/services/rtc.service';
import { UiService, ViewType } from 'src/app/services/ui.service';
import { UserService } from 'src/app/services/user.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FLAGS, FlagsService } from 'src/app/services/flags.service';
import { PROMPTS } from 'src/app/common/utils/call-states-constants';
import { DeviceErrorsNotificationsService } from 'src/app/services/device-errors-notifications.service';
import { VirtualBackgroundInsertableStreamService } from 'src/app/services/virtual-background-insertable-stream.service';
import {
  DevicesSettingsBaseComponent,
  DevicesType,
} from 'src/app/sessions/session/wb-video-controls/wb-video-controls-buttons/devices-settings/devices-settings-base.component';
import { DomListenerFactoryService } from 'src/app/services/dom-listener-factory.service';
import { AudioService } from 'src/app/services/audio.service';
import { Intercom } from 'ng-intercom';
import { DeviceAndBrowserDetectorService } from 'src/app/services/device-and-browser-detector.service';
import {
  CallRelatedModalAction,
  DeviceErrorType,
  JoinCallDevicesState,
} from 'src/app/models/device-manger';
import { TranslateService } from '@ngx-translate/core';
import { SessionSharedDataService } from 'src/app/services/session-shared-data.service';
import { modifiedSetTimeout } from 'src/app/utilities/ZoneUtils';
import { LocalTracksManagerService } from 'src/app/services/local-tracks-manager.service';
import { TelemetryService } from 'src/app/services/telemetry.service';
import { GUIDES } from '../steps-guide/steps-guide.component';
import { intercomArticles } from '../../intercom-articles';
import { WbDialogService } from '../../services/wb-dialog.service';

const enterTransition = transition(':enter', [
  style({
    opacity: 0,
  }),
  animate(
    '0.3s ease-in',
    style({
      opacity: 1,
    }),
  ),
]);

const leaveTrans = transition(':leave', [
  style({
    opacity: 1,
  }),
  animate(
    '0.3s ease-out',
    style({
      opacity: 0,
    }),
  ),
]);

const fadeIn = trigger('fadeIn', [enterTransition]);

const fadeOut = trigger('fadeOut', [leaveTrans]);

@UntilDestroy()
@Component({
  selector: 'app-join-call-panel',
  templateUrl: './join-call-panel.component.html',
  styleUrls: ['./join-call-panel.component.scss'],
  animations: [fadeIn, fadeOut],
})
export class JoinCallPanelComponent extends DevicesSettingsBaseComponent implements OnDestroy {
  readonly DEFAULT_HOST_STARTED_CALL = 'A host has started call';
  readonly CallRelatedModalActionEnum = CallRelatedModalAction;

  readonly SHOW_DEVICE_IS_ON_TOAST_PERIOD = 500;
  deviceErrorType = DeviceErrorType;
  intercomArticles = intercomArticles;

  user?: User;

  users: User[] = [];
  hostStartedCall: string = this.DEFAULT_HOST_STARTED_CALL;

  showMicNoInputPopUp = false;
  showMicIsOnToast = false;
  showCamIsOnToast = false;

  deviceType = DevicesType;

  prompts = PROMPTS;

  guides = GUIDES;

  screenType = ViewType;
  musicModeFeatureFlag = false;
  enableMusicMode = false;

  virtualBackgroundEnabled = false;
  virtualBackgroundSupported = false;

  // this is means that the device not mobile and not tablet
  // we may need to unify this with isDesktop after carfully making sure
  // that is what meant by it
  isRealDesktop = true;

  constructor(
    private dialogRef: MatDialogRef<JoinCallPanelComponent>,
    ngZone: NgZone,
    devicesManagerService: DevicesManagerService,
    uiService: UiService,
    private userService: UserService,
    rtcServiceController: RtcServiceController,
    private presenceProvider: PresenceProvider,
    deviceDetectorService: DeviceDetectorService,
    private flagsService: FlagsService,
    deviceErrorsNotificationsService: DeviceErrorsNotificationsService,
    wbDialogService: WbDialogService,
    domListenerFactoryService: DomListenerFactoryService,
    deviceAndBrowserDetectorService: DeviceAndBrowserDetectorService,
    audioService: AudioService,
    intercom: Intercom,
    translateService: TranslateService,
    virtualBackgroundInsertableStreamService: VirtualBackgroundInsertableStreamService,
    sessionSharedDataService: SessionSharedDataService,
    localTrackManagerService: LocalTracksManagerService,
    telemetryService: TelemetryService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isStartCall: boolean;
      usersIds: Set<string>;
      usersLookUp: { [key: string]: User };
      sessionId: string;
      breakoutRoomId?: string;
      whoStartedCall?: string;
    },
  ) {
    super(
      domListenerFactoryService,
      deviceDetectorService,
      uiService,
      rtcServiceController,
      devicesManagerService,
      ngZone,
      deviceAndBrowserDetectorService,
      deviceErrorsNotificationsService,
      audioService,
      intercom,
      PROMPTS.JOIN_CALL,
      translateService,
      virtualBackgroundInsertableStreamService,
      sessionSharedDataService,
      localTrackManagerService,
      telemetryService,
      false,
    );
    wbDialogService.pushNewDialogRef<JoinCallPanelComponent>(dialogRef);

    this.userService.user.pipe(untilDestroyed(this)).subscribe((res: any) => {
      this.user = res && res.user;
    });

    this.isRealDesktop = deviceAndBrowserDetectorService.isDesktop();

    if (
      data.whoStartedCall &&
      data.whoStartedCall.length > 0 &&
      data.usersLookUp[data.whoStartedCall]
    ) {
      this.hostStartedCall = `${
        data.usersLookUp[data.whoStartedCall].name
      } (Host) has started call`;
    }

    for (const id of data.usersIds) {
      if (data.usersLookUp[id] && this.user?._id !== id) {
        this.users.push(data.usersLookUp[id]);
      }
    }

    if (data.isStartCall) {
      this.subscribeToPresenceActivity();
    } else {
      this.subscribeToCallActivity();
    }
    this.enableMusicMode = this.rtcServiceController.service.isMusicModeEnabled;
    this.virtualBackgroundEnabled = flagsService.isFlagEnabled(FLAGS.SPACES_VIRTUAL_BACKGROUNDS);
    this.virtualBackgroundSupported =
      this.virtualBackgroundInsertableStreamService.isVirtualBackgroundSupported();
  }

  async ngOnInit() {
    this.musicModeFeatureFlag = this.flagsService.isFlagEnabled(FLAGS.ENABLE_AUDIO_MUSIC_MODE);
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // ovverride
  closeDialog() {
    this.finish(CallRelatedModalAction.NOT_PROCEEDING_WITH_THE_CALL);
  }

  // ovverride
  getComponentPromptLayout(): PROMPTS {
    return this.prompts.JOIN_CALL;
  }

  private subscribeToPresenceActivity() {
    this.presenceProvider
      .getRoomPresenceActivity(this.data.sessionId, this.data.breakoutRoomId)
      .pipe(untilDestroyed(this))
      .subscribe((presence) => {
        presence = presence ?? new Set<string>();
        if (presence.size === 0) {
          this.finish(CallRelatedModalAction.NOT_PROCEEDING_WITH_THE_CALL);
        } else {
          const tmpUsers: User[] = [];
          presence.forEach((userId) => {
            if (this.data.usersLookUp[userId] && this.user?._id !== userId) {
              tmpUsers.push(this.data.usersLookUp[userId]);
            }
          });
          this.users = tmpUsers;
        }
      });
  }

  private subscribeToCallActivity() {
    this.presenceProvider
      .getCallPresenceActivity(this.data.sessionId, this.data.breakoutRoomId)
      .pipe(untilDestroyed(this))
      .subscribe((inCallUsers) => {
        inCallUsers = inCallUsers ?? new Set<string>();
        if (inCallUsers.size === 0) {
          this.finish(CallRelatedModalAction.NOT_PROCEEDING_WITH_THE_CALL);
        } else {
          const tmpUsers: User[] = [];
          inCallUsers.forEach((userId) => {
            if (this.data.usersLookUp[userId]) {
              tmpUsers.push(this.data.usersLookUp[userId]);
            }
          });
          this.users = tmpUsers;
        }
      });
  }

  async finish(action: CallRelatedModalAction) {
    const joinCallDevicesState: JoinCallDevicesState = {
      unmuteCam: false,
      unmuteMic: false,
    };
    // Checking isGettingStream to avoid joining the call with opened mic/cam
    // in case of the user clicks quickly on join call button while join call panel is trying to get the preview media streams
    if (!this.camState.hasError && !this.camState.isGettingStream && this.camState.state) {
      joinCallDevicesState.unmuteCam = true;
    }

    if (!this.micState.hasError && !this.micState.isGettingStream && this.micState.state) {
      joinCallDevicesState.unmuteMic = true;
    }

    this.rtcServiceController.service.isMusicModeEnabled = this.enableMusicMode;
    this.rtcServiceController.service.isNoiseCancellationEnabled = this.enableNoiseCancellation;
    this.dialogRef.close({ action: action, joinCallDevicesState: joinCallDevicesState });
  }

  private async toggleCam() {
    this.localTrackManagerService.updateCamDeviceState({
      ...this.camState,
      state: !this.camState.state,
    });
    if (!this.camState.state) {
      this.localTrackManagerService.closeVideoTrack();
      this.videoElement.nativeElement.srcObject = null;
    } else {
      await this.acquireVideoStream();
    }
  }

  private async toggleMic() {
    this.localTrackManagerService.updateMicDeviceState({
      ...this.micState,
      state: !this.micState.state,
    });
    if (this.micState.state) {
      await this.acquireAudioStream();
    }
  }

  async toggleCamActions() {
    if (!this.camState.state && !this.camState.hasError) {
      this.showCamIsOnToast = true;
      this.autoHideCamIsOnToast();
    }
    await this.toggleCam();
    if (this.camState.hasError) {
      this.troubleshootCam();
    }
  }

  async toggleMicActions() {
    if (!this.micState.state && !this.micState.hasError) {
      this.showMicIsOnToast = true;
      this.autoHideMicIsOnToast();
    }
    await this.toggleMic();
    if (this.micState.hasError) {
      this.troubleshootMic();
    }
  }

  openDeviceModal() {
    this.finish(CallRelatedModalAction.OPEN_SPACE_DEVICE_MODAL);
  }

  private autoHideMicIsOnToast() {
    modifiedSetTimeout(() => {
      if (this.showMicIsOnToast) {
        this.showMicIsOnToast = false;
      }
    }, this.SHOW_DEVICE_IS_ON_TOAST_PERIOD);
  }

  private autoHideCamIsOnToast() {
    modifiedSetTimeout(() => {
      if (this.showCamIsOnToast) {
        this.showCamIsOnToast = false;
      }
    }, this.SHOW_DEVICE_IS_ON_TOAST_PERIOD);
  }

  checkForCamErrorsTypes(
    errorsToCheck: DeviceErrorType[],
    error: DeviceErrorType | undefined,
  ): boolean {
    if (error) {
      return errorsToCheck.includes(error);
    }
    return false;
  }

  showVirtualBackgroundsModal(): void {
    this.finish(CallRelatedModalAction.OPEN_VIRTUAL_BACKGROUND_MODAL);
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { v4 } from 'uuid';

import { DomListenerFactoryService } from 'src/app/services/dom-listener-factory.service';
import { QuestionsService } from '../../services/questions.service';
import { Fragment, FragmentMetadata } from '../../models/question';

enum ImageSize {
  Small = 'IMAGE_SMALL',
  Medium = 'IMAGE_MEDIUM',
  Large = 'IMAGE_LARGE',
}
const IMAGE_MINIMUM_WIDTH = 75;

@UntilDestroy()
@Component({
  selector: 'app-image-resize-new[frag]',
  templateUrl: './image-resize-new.component.html',
  styleUrls: ['./image-resize-new.component.scss'],
})
export class ImageResizeNewComponent implements OnInit, OnDestroy {
  @Input() componentUsedFromChatBox = false;
  @Input() frag!: Fragment;
  @Input() posStyle: any = {};
  @Output() saveData = new EventEmitter();
  @Output() selectImage = new EventEmitter();
  @Output() resizeStart = new EventEmitter();

  @ViewChild('imageContainer') imageContainer!: ElementRef;

  public style: Record<string, string> = {};
  prevStyles: Record<string, string>[] = [];
  isResizeStarted = false;
  dragPosition = { x: 0, y: 0 };
  ratio = 0;
  dragDiff = { x: 0, y: 0 };
  originalStyle: any = {};
  direction = '';
  showResizeHandle = false;
  private mouseMoveRef = this.onMouseMove.bind(this);
  private mouseUpRef = this.onMouseUp.bind(this);
  private mouseClickRef = this.onMouseEnter.bind(this);
  imageId: string | undefined;
  naturalWidth = 0;
  naturalHeight = 0;
  newWidth = 0;
  domListener = this.domListenerFactoryService.createInstance();

  constructor(
    private router: Router,
    private questionService: QuestionsService,
    private domListenerFactoryService: DomListenerFactoryService,
  ) {
    this.setupUndoStyleOnKeyDownEvent();
    this.questionService.operation.pipe(untilDestroyed(this)).subscribe((res) => {
      if (
        res &&
        (res.type === ImageSize.Small ||
          res.type === ImageSize.Medium ||
          res.type === ImageSize.Large) &&
        this.frag?.metadata &&
        this.imageId === res.value
      ) {
        switch (res.type) {
          case ImageSize.Small:
            this.frag.metadata.img_width = `${0.35 * this.originalStyle.width}px`;
            break;
          case ImageSize.Medium:
            this.frag.metadata.img_width = `${0.7 * this.originalStyle.width}px`;
            break;
          case ImageSize.Large:
            this.frag.metadata.img_width = `${this.originalStyle.width}px`;
            break;
        }
        if (this.frag?.metadata.img_width) {
          this.style.width = this.frag.metadata.img_width;
          this.saveData.emit();
          this.questionService.selectedFormat.next({
            image: ((parseInt(this.style.width, 10) * 100) / this.originalStyle.width).toFixed(),
            id: this.imageId,
            sizePresetActive: false,
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.domListener.clear();
  }

  private setupUndoStyleOnKeyDownEvent() {
    this.domListener.add(
      'window',
      'keydown.control.z',
      () => {
        this.undoStyle();
      },
      true,
    );
    // Handle MacOS use case
    this.domListener.add(
      'window',
      'keydown.meta.z',
      () => {
        this.undoStyle();
      },
      true,
    );
  }

  ngOnInit(): void {
    const imageObject = new Image();
    imageObject.onload = () => {
      this.naturalWidth = imageObject.width;
      this.naturalHeight = imageObject.height;
      this.style['max-width'] = `${this.naturalWidth}px`;
      this.style['max-height'] = `${this.naturalHeight}px`;
    };
    imageObject.src = this.frag && this.frag.data;

    requestAnimationFrame(() => {
      this.style.width = `${this.imageContainer.nativeElement.clientWidth}px`;
      let width;
      let height;
      if (
        this.router.url.includes('/content/notes/view') ||
        this.router.url.includes('/content/view')
      ) {
        if (
          this.frag?.interface_metadata &&
          this.frag.interface_metadata.findIndex((m) => m.interface === 'landing') > -1
        ) {
          this.frag.metadata = this.frag.interface_metadata.find((m) => m.interface === 'landing');
        }
      }
      if (
        this.router.url.includes('/content/notes/present') ||
        this.router.url.includes('/content/present')
      ) {
        if (
          this.frag?.interface_metadata &&
          this.frag.interface_metadata.findIndex((m) => m.interface === 'presentation') > -1
        ) {
          this.frag.metadata = this.frag.interface_metadata.find(
            (m) => m.interface === 'presentation',
          );
        }
      }
      if (this.router.url.includes('/worksheets/present')) {
        if (
          this.frag?.interface_metadata &&
          this.frag.interface_metadata.findIndex((m) => m.interface === 'worksheet') > -1
        ) {
          this.frag.metadata = this.frag.interface_metadata.find(
            (m) => m.interface === 'worksheet',
          );
        }
      }
      if (this.router.url.includes('/worksheets/print')) {
        if (
          this.frag?.interface_metadata &&
          this.frag.interface_metadata.findIndex((m) => m.interface === 'print') > -1
        ) {
          this.frag.metadata = this.frag.interface_metadata.find((m) => m.interface === 'print');
        }
      }
      if (this.router.url.includes('/worksheets/list')) {
        if (
          this.frag?.interface_metadata &&
          this.frag.interface_metadata.findIndex((m) => m.interface === 'worksheet_list') > -1
        ) {
          this.frag.metadata = this.frag.interface_metadata.find(
            (m) => m.interface === 'worksheet_list',
          );
        }
      }
      if (this.frag?.metadata) {
        width = this.frag.metadata.img_width;
        height = this.frag.metadata.img_height;
        if (width) {
          this.style.width = width;
        }
        if (height) {
          this.style.height = height;
        }
        if (width && height && parseInt(width, 10)) {
          this.originalStyle = {
            width: parseInt(width, 10),
            height: parseInt(height, 10),
          };
          this.ratio = parseInt(width, 10) / parseInt(height, 10);
        }
        this.prevStyles.push(this.style);
      }
    });
  }

  undoStyle() {
    if (this.prevStyles.length > 0) {
      if (this.prevStyles.length > 1) {
        this.prevStyles.pop();
      }
      this.style = this.prevStyles[this.prevStyles.length - 1];
      this.originalStyle = {
        width:
          parseInt(this.style.width, 10) < this.imageContainer.nativeElement.clientWidth
            ? parseInt(this.style.width, 10)
            : this.imageContainer.nativeElement.clientWidth,
        height: parseInt(this.style.height, 10),
      };
      if (!this.frag?.metadata) {
        this.frag.metadata = new FragmentMetadata();
      }
      if (this.frag) {
        this.frag.metadata.img_height = this.style.height;
        this.frag.metadata.img_width = this.style.width;
      }
      this.saveData.emit();
    }
  }

  startResize() {
    if (!this.ratio) {
      this.originalStyle = {
        width: this.imageContainer.nativeElement.clientWidth,
        height: this.imageContainer.nativeElement.clientHeight,
      };
      this.ratio = this.originalStyle.width / this.originalStyle.height;
    }
    this.showResizeHandle = !this.showResizeHandle;
    this.selectImage.emit(this.showResizeHandle);
    this.imageId = v4();
    requestAnimationFrame(() => {
      this.questionService.selectedFormat.next({
        image: ((parseInt(this.style.width, 10) * 100) / this.originalStyle.width).toFixed(),
        id: this.imageId,
        sizePresetActive: false,
      });
    });
    if (this.showResizeHandle) {
      document.addEventListener('click', this.mouseClickRef);
    } else {
      document.removeEventListener('click', this.mouseClickRef);
    }
  }

  onMouseDown(event: MouseEvent, direction: string) {
    this.activateListeners();
    event.preventDefault();
    this.direction = direction;
    this.isResizeStarted = true;
    this.resizeStart.emit(true);
    this.dragPosition = {
      x: event.clientX,
      y: event.clientY,
    };
  }

  activateListeners() {
    document.addEventListener('mousemove', this.mouseMoveRef);
    document.addEventListener('mouseup', this.mouseUpRef);
  }

  deactivateListeners() {
    document.removeEventListener('mousemove', this.mouseMoveRef);
    document.removeEventListener('mouseup', this.mouseUpRef);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onMouseUp() {
    this.resizeStart.emit(false);
    if (this.isResizeStarted) {
      this.originalStyle = {
        width: this.newWidth,
        height: parseInt(this.style.height, 10),
      };
      this.prevStyles.push(this.style);
      this.saveData.emit();
      this.deactivateListeners();
    }
    this.isResizeStarted = false;
  }

  onMouseMove(event: MouseEvent) {
    if (!this.frag) {
      return;
    }

    if (!this.frag?.metadata) {
      this.frag.metadata = new FragmentMetadata();
    }

    if (this.isResizeStarted) {
      this.dragDiff = {
        x: event.clientX - this.dragPosition.x,
        y: (event.clientX - this.dragPosition.x) / this.ratio,
      };
      if (this.direction === 'bottom-left' || this.direction === 'top-left') {
        this.dragDiff.x = -this.dragDiff.x;
        this.dragDiff.y = -this.dragDiff.y;
      }

      const dragWidth = this.originalStyle.width + this.dragDiff.x;
      if (dragWidth > IMAGE_MINIMUM_WIDTH) {
        if (dragWidth > 800) {
          this.newWidth = 800;
        } else {
          this.newWidth = dragWidth;
        }
      } else {
        this.newWidth = IMAGE_MINIMUM_WIDTH;
      }
      this.frag.metadata.img_width = `${this.newWidth}px`;
    }
  }

  onMouseEnter(event: MouseEvent) {
    const targetElement = event.target;
    let isClickedInToolbox = false;
    event.composedPath().forEach((p) => {
      if ((p as HTMLElement).tagName === 'APP-EDITOR-TOOLBOX') {
        isClickedInToolbox = true;
      }
    });
    if (this.imageContainer) {
      const clickedInside = this.imageContainer.nativeElement.contains(targetElement);
      if (!clickedInside && !isClickedInToolbox) {
        this.showResizeHandle = false;
        this.selectImage.emit(this.showResizeHandle);
        this.questionService.selectedFormat.next(null);
        document.removeEventListener('click', this.mouseClickRef);
      }
    }
  }
}

<div
  class="notification-popup"
  [ngStyle]="{
    'width.px': options.payload.width,
    height: options.payload.height
  }"
  [ngClass]="{
    'notification-popup-v2': options.payload.version2Notification,
    'notification-progress-bar':
      options.payload.version2Notification && options.payload.showProgressBar
  }"
>
  <div class="top-elements" [style.margin-bottom]="options.payload.middleElements?.length ? 5 : 0">
    <ng-container *ngFor="let el of options.payload.topElements">
      <app-toaster-element [notificationElement]="el" [toastRef]="toastRef"></app-toaster-element>
    </ng-container>

    <button
      *ngIf="options.payload.topRightOptions?.items"
      class="drop-down-actions"
      [matMenuTriggerFor]="dropDownMatMenu"
      [class.active]="isMenuOpened"
      (menuOpened)="isMenuOpened = true"
      (menuClosed)="isMenuOpened = false"
    >
      <mat-icon class="material-icons-outlined">{{
        options.payload.topRightOptions.icon
      }}</mat-icon>
      <mat-icon class="material-icons-outlined">keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu
      [hidden]="!options.payload.topRightOptions?.items"
      #dropDownMatMenu="matMenu"
      class="notifications-more-menu"
      (click)="$event.stopPropagation()"
    >
      <ng-template matMenuContent>
        <button
          class="notifications-more-menu-button"
          *ngFor="let option of options.payload.topRightOptions.items"
          (click)="option.onClick()"
        >
          <span> {{ option.text }} </span>
        </button>
      </ng-template>
    </mat-menu>
    <div class="close-button-frame">
      <button
        id="close-notification"
        [hidden]="!options.payload.dismissible"
        mat-icon-button
        class="top-elements-close"
        data-name="notification-toast-close-button"
        (click)="onClose()"
      >
        <mat-icon class="material-icons" *ngIf="!options.payload.version2Notification"
          >close</mat-icon
        >
        <div class="x-close" *ngIf="options.payload.version2Notification">
          <svg-icon icon="close-notification-icon"></svg-icon>
        </div>
      </button>
    </div>
  </div>

  <div class="middle-elements-container" *ngIf="options.payload.middleElements.length">
    <ng-container *ngFor="let el of options.payload.middleElements">
      <app-toaster-element
        class="middle-elements"
        [notificationElement]="el"
        [toastRef]="toastRef"
      ></app-toaster-element>
    </ng-container>
  </div>

  <div
    class="bottom-elements"
    [ngClass]="{ 'stacked-bottom-elements': options.payload.bottomElementsStacked }"
    *ngIf="options.payload.bottomElements.length"
  >
    <ng-container *ngFor="let el of options.payload.bottomElements; let indexOfElement = index">
      <app-toaster-element
        class="bottom-elements-element"
        [notificationElement]="el"
        [toastRef]="toastRef"
      ></app-toaster-element>
      <div
        [style.padding.px]="6"
        *ngIf="
          options.payload.bottomElementsStacked &&
          indexOfElement !== options.payload.bottomElements.length - 1
        "
      ></div>
    </ng-container>

    <app-drop-down-button
      *ngIf="options.payload.bottomElementButtonOptions as state"
      [buttonText]="state.buttonText"
      [items]="state.items"
      [dropDownTitle]="state.menuTitle"
      [onClick]="state.onPrimaryButtonClick"
      (primaryButtonClick)="onClose()"
      (menuItemButtonClick)="onClose()"
    >
    </app-drop-down-button>
  </div>
</div>
<div
  *ngIf="options.payload.version2Notification && options.payload.showProgressBar"
  class="progress-bar-container"
>
  <div
    class="timer-animation"
    [style.background-color]="options.payload.progressBarColor + '!important'"
    [style.animation-duration]="options.payload.timeout + 'ms'"
  ></div>
</div>

<div *ngIf="options.progressBar && options.payload.showProgressBar">
  <div
    class="toast-progress"
    [style.width]="width + '%'"
    [style.background-color]="options.payload.progressBarColor + '!important'"
  ></div>
</div>

<div class="text-center">
  <p
    [innerHTML]="
      ('All' | translate) +
      ' ' +
      data?.checkedQuestions?.length +
      ' ' +
      ('questions on this page are selected.' | translate)
    "
  ></p>
  <a (click)="select()" class="link">
    {{
      ('Select all' | translate) +
        ' ' +
        data?.total +
        ' ' +
        ('questions that match this search' | translate)
    }}
  </a>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { User } from '../models/user';

@Pipe({
  name: 'userSummary',
})
export class UserSummaryPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService
  ) {}

  transform(user: User): string {
    const summary: string[] = [];
    if (!user?.institution.personas) {
      if (user.personas && user.personas.length) {
        summary.push(user.personas[0]);
      } else {
        summary.push(this.translateService.instant('No Role'));
      }
    } else {
      // Custom Personas
      const institutionPersonas = Object.keys(user.institution.personas);
      const userPersona = institutionPersonas.find((persona) => persona === user?.personas?.[0]);

      if (!userPersona) {
        summary.push(this.translateService.instant('No Role'));
      } else {
        summary.push(user.institution.personas[userPersona]?.name);
      }
    }

    summary.push(user.institution ? user.institution.name : this.translateService.instant('No Institution'));

    return summary.join(' | ');
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlighter',
})
export class HighlighterPipe implements PipeTransform {
  transform(value: any, args: any, type?: string): unknown {
    if (!args || !value) {
      return value;
    }

    if (typeof args === 'string') {
      args = args.replace(/[()]/g, (match) => `\\${match}`);
    }

    if (type === 'full') {
      const re = new RegExp(`\\b(${args}\\b)`, 'igm');
      value = value.replace(re, '<span class="highlighted-text">$1</span>');
    } else if (type === 'array') {
      args = args as string[];
      for (const arg of args) {
        const re = new RegExp(arg, 'igm');
        value = value.replace(re, '<span class="bold-highlighted-text">$&</span>');
      }
    } else {
      const re = new RegExp(args, 'igm');
      value = value.replace(re, '<span class="highlighted-text">$&</span>');
    }

    return value;
  }
}

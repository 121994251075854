import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SVGIconComponentsModule } from '../svg-icon/svg-icon-components.module';
import { GadgetHeaderComponent } from './gadget-header.component';

@NgModule({
  declarations: [GadgetHeaderComponent],
  exports: [GadgetHeaderComponent],
  imports: [CommonModule, SVGIconComponentsModule],
})
export class GadgetHeaderModule {}

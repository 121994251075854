<div
  class="radio-selection-item-container"
  [ngClass]="{
    'radio-selection-item-container-selected': isSelected,
    'radio-selection-item-container-disabled': selectionItem.disabled
  }"
  [appTooltip]="
    selectionItem.tooltipText || selectionItem.disabledToolTipText ? infoTooltip : undefined
  "
  (click)="itemSelected()"
  appTooltipPlacement="bottom-center"
>
  <app-pencil-icon
    class="radio-selection-item-icon"
    [name]="selectionItem.icon.name"
    [type]="selectionItem.icon.type"
  >
  </app-pencil-icon>
  <div class="radio-selection-item-text-container">
    <p
      class="radio-selection-item-text"
      [ngClass]="{ 'radio-selection-item-text-disabled': selectionItem.disabled }"
    >
      {{ selectionItem.text }}
    </p>
  </div>
</div>

<ng-template #infoTooltip>
  <app-tooltip
    [text]="selectionItem.disabled ? selectionItem.disabledToolTipText : selectionItem.tooltipText"
    [arrow]="'top'"
    [theme]="'grey'"
    [wrapText]="true"
  ></app-tooltip>
</ng-template>

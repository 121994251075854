import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { detectDailymotion, detectVimeo, detectYoutube } from '../utilities/video.utils';

@Injectable()
export class EmbedVideoService {
  private validYouTubeOptions = ['default', 'mqdefault', 'hqdefault', 'sddefault', 'maxresdefault'];
  private validVimeoOptions = ['thumbnail_small', 'thumbnail_medium', 'thumbnail_large'];
  private validDailyMotionOptions = [
    'thumbnail_60_url',
    'thumbnail_120_url',
    'thumbnail_180_url',
    'thumbnail_240_url',
    'thumbnail_360_url',
    'thumbnail_480_url',
    'thumbnail_720_url',
    'thumbnail_1080_url',
  ];

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  public embed(url: any, options?: any): any {
    let id;
    url = new URL(url);

    id = detectYoutube(url);
    if (id) {
      return this.embed_youtube(id, options);
    }

    id = detectVimeo(url);
    if (id) {
      const hash = this.getVimeoHash(url);
      return this.embed_vimeo(id, hash, options);
    }

    id = detectDailymotion(url);
    if (id) {
      return this.embed_dailymotion(id, options);
    }
  }

  public embed_youtube(id: string, options?: any): string {
    options = this.parseOptions(options);

    return this.sanitize_iframe(
      `<iframe src="https://www.youtube.com/embed/${id}${options.query}"${options.attr} frameborder="0" allowfullscreen></iframe>`,
    );
  }

  public embed_vimeo(id: string, hash: any, options?: any): string {
    options = this.parseOptions(options);

    return this.sanitize_iframe(
      `<iframe src="https://player.vimeo.com/video/${id}${hash}${options.query}"${options.attr} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`,
    );
  }

  public embed_dailymotion(id: string, options?: any): string {
    options = this.parseOptions(options);

    return this.sanitize_iframe(
      `<iframe src="https://www.dailymotion.com/embed/video/${id}${options.query}"${options.attr} frameborder="0" allowfullscreen></iframe>`,
    );
  }

  public embed_image(url: any, options?: any): any {
    let id;

    url = new URL(url);

    id = detectYoutube(url);
    if (id) {
      return this.embed_youtube_image(id, options);
    }

    id = detectVimeo(url);
    if (id) {
      return this.embed_vimeo_image(id, options);
    }

    id = detectDailymotion(url);
    if (id) {
      return this.embed_dailymotion_image(id, options);
    }
  }

  private embed_youtube_image(id: string, options?: any): any {
    if (typeof options === 'function') {
      options = {};
    }
    options = options || {};

    options.image = this.validYouTubeOptions.indexOf(options.image) > 0 ? options.image : 'default';

    const src = `https://img.youtube.com/vi/${id}/${options.image}.jpg`;

    const result = {
      link: src,
      html: `<img src="${src}"/>`,
    };

    return new Promise((resolve) => {
      resolve(result);
    });
  }

  private embed_vimeo_image(id: string, options?: any): any {
    if (typeof options === 'function') {
      options = {};
    }

    options = options || {};

    options.image =
      this.validVimeoOptions.indexOf(options.image) >= 0 ? options.image : 'thumbnail_large';

    return this.http
      .get(`https://vimeo.com/api/v2/video/${id}.json`)
      .pipe(
        map((res: any) => ({
          link: res[0][options.image],
          html: `<img src="${res[0][options.image]}"/>`,
        })),
      )
      .toPromise()
      .catch((error) => console.log(error));
  }

  private embed_dailymotion_image(id: string, options?: any): any {
    if (typeof options === 'function') {
      options = {};
    }

    options = options || {};

    options.image =
      this.validDailyMotionOptions.indexOf(options.image) >= 0
        ? options.image
        : 'thumbnail_480_url';

    return this.http
      .get(`https://api.dailymotion.com/video/${id}?fields=${options.image}`)
      .pipe(
        map((res: any) => ({
          link: res[options.image],
          html: `<img src="${res[options.image]}"/>`,
        })),
      )
      .toPromise()
      .catch((error) => console.log(error));
  }

  private parseOptions(options: any): any {
    let queryString = '';
    let attributes = '';

    if (options && Object.prototype.hasOwnProperty.call(options, 'query')) {
      queryString = `?${this.serializeQuery(options.query)}`;
    }

    if (options && Object.prototype.hasOwnProperty.call(options, 'attr')) {
      const temp = <any>[];

      Object.keys(options.attr).forEach(function (key) {
        temp.push(`${key}="${options.attr[key]}"`);
      });

      attributes = ` ${temp.join(' ')}`;
    }
    return {
      query: queryString,
      attr: attributes,
    };
  }

  private serializeQuery(query: any): any {
    const queryString: any = [];

    for (const p in query) {
      if (Object.prototype.hasOwnProperty.call(query, p)) {
        queryString.push(`${encodeURIComponent(p)}=${encodeURIComponent(query[p])}`);
      }
    }

    return queryString.join('&');
  }

  private sanitize_iframe(iframe: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(iframe);
  }

  private getVimeoHash(url: any): string {
    return url.pathname.split('/')[2] ? `?h=${url.pathname.split('/')[2]}` : '';
  }
}

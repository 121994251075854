<div
  class="ui-select-wrap"
  [ngClass]="{ menuShown }"
  [appTooltip]="labelTooltip"
  [appTooltipPlacement]="'top-center'"
  [appTooltipTriggerType]="'manual'"
  #timeInvalidTooltip="appTooltip"
>
  <div
    #menuTrigger="matMenuTrigger"
    class="ui-select"
    [class.invalid-time]="!!invalidTime"
    [matMenuTriggerFor]="selectorMenu"
    (menuOpened)="menuShown = true"
    (menuClosed)="menuShown = false"
  >
    <div class="value _input">
      <input
        #timeInput
        type="text"
        (focus)="saveCurrentTime()"
        (keyup)="validateTextInput()"
        (blur)="processInput()"
        (keydown.enter)="menuTrigger.closeMenu(); processInput()"
        (keydown.escape)="menuTrigger.closeMenu(); revertInput()"
      />
    </div>
    <i class="material-icons">expand_more</i>
  </div>
  <mat-menu #selectorMenu class="ui-select-options timeSelector">
    <div
      *ngFor="let time of times"
      class="ui-select-option"
      (click)="select(time)"
      [ngClass]="{ selected: value === time }"
    >
      <div>{{ time }}</div>
    </div>
  </mat-menu>
</div>
<ng-template #labelTooltip>
  <app-tooltip
    *ngIf="!!invalidTime"
    class="past-event-tooltip"
    arrow="bottom"
    theme="red"
    [text]="
      invalidTime === invalidErrorEnums.DATE_FORMAT
        ? ('Invalid time format' | translate)
        : ('End time before start time' | translate)
    "
  ></app-tooltip>
</ng-template>

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SVGIconComponentsModule } from 'src/app/common/svg-icon/svg-icon-components.module';

export enum IconTypes {
  matIcon = 'mat-icon',
  svgIcon = 'svg-icon',
  img = 'img',
  materialIcon = 'material-icon',
}

export interface IconConfig {
  name: string;
  type: IconTypes;
  outlined?: boolean;
  wrapperStyle?: { [key: string]: string | number };
  onClick?: () => void;
}

@Component({
  selector: 'app-pencil-icon[name][type]',
  standalone: true,
  imports: [CommonModule, MatIconModule, SVGIconComponentsModule],
  templateUrl: './pencil-icon.component.html',
  styleUrls: ['./pencil-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PencilIconComponent {
  @HostBinding('style') hostStyle = {};
  @HostListener('click') onClick() {
    if (this.clickAction) {
      this.clickAction();
    }
  }

  @Input() name?: string;
  @Input() type: string = IconTypes.svgIcon;
  @Input() outlined = false;
  @Input() clickAction?: () => void;
  @Input() set wrapperStyle(value: { [key: string]: string | number } | undefined) {
    this.hostStyle = value ?? {};
  }
}

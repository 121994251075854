import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MAX_PAGES_FOR_ONE_PAGE_BER_BOARD, PDFBoardStrategy } from 'src/app/models/upload';
import { SelectionItem } from '../../../../../standalones/components/radio-selection/radio-selection.component';
import { IconTypes } from '../../../../../standalones/components/pencil-icon/pencil-icon.component';
import { SpaceRepository } from '../../../../../state/space.repository';
import { FLAGS, FlagsService } from '../../../../../services/flags.service';
import { SpaceLeaderModeService } from '../../../../../services/space-leader-mode.service';

@Component({
  selector: 'app-upload-pdf-select-board-strategy',
  templateUrl: './upload-pdf-select-board-strategy.component.html',
  styleUrls: ['./upload-pdf-select-board-strategy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadPdfSelectBoardStrategyComponent implements OnChanges {
  @Input() selectedPagesCount = 0;
  @Input() selectedStrategy: PDFBoardStrategy = PDFBoardStrategy.CurrentBoard;
  @Input() canSplitPDF: boolean = true;
  @Output() strategySelected = new EventEmitter<PDFBoardStrategy>();
  @Output() backToPreviewSubmit = new EventEmitter<void>();

  strategiesSelectionItems: SelectionItem[] = this.generateSelectionItems();

  // TODO: ADD FUNCTIONALITY TO CHANGE ICON ON HOVER FOR PENCIL BUTTON
  isImportButtonHovered = false;
  constructor(
    private translateService: TranslateService,
    private spaceRepo: SpaceRepository,
    private spaceLeaderModeService: SpaceLeaderModeService,
    private flagsService: FlagsService,
  ) {}

  get pageCountText(): string {
    return this.selectedPagesCount === 1
      ? this.translateService.instant(`You are importing ${this.selectedPagesCount} page.`)
      : this.translateService.instant(`You are importing ${this.selectedPagesCount} pages.`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedPagesCount?.previousValue !== changes.selectedPagesCount?.currentValue) {
      this.strategiesSelectionItems = this.generateSelectionItems();
    }
  }

  private generateSelectionItems() {
    return Object.values(PDFBoardStrategy).map((strategy) =>
      this.getSelectionItemFromStrategy(strategy as PDFBoardStrategy),
    );
  }

  private getSelectionItemFromStrategy(strategy: PDFBoardStrategy): SelectionItem {
    const isParticipant = this.spaceRepo.isCurrentUserSpaceParticipant;
    const isFollower = this.spaceLeaderModeService.currentUserIsBeingLed();
    const isBoardFoldersFlagEnabled = this.flagsService.isFlagEnabled(FLAGS.SPACES_BOARD_FOLDERS);

    switch (strategy) {
      case PDFBoardStrategy.CurrentBoard:
        return {
          text: this.translateService.instant('Add to current board'),
          icon: {
            name: 'add_to_current_board',
            type: IconTypes.svgIcon,
          },
        };
      case PDFBoardStrategy.NewBoard:
        return {
          text: this.translateService.instant('Add to new board'),
          icon: {
            name: 'add_to_new_board',
            type: IconTypes.svgIcon,
          },
          disabledToolTipText: isParticipant
            ? this.translateService.instant('You should be a host to be able to use this option')
            : isFollower
            ? this.translateService.instant(
                'You cannot import to a different board while the host is leading',
              )
            : null,
          disabled: isParticipant || isFollower,
        };
      case PDFBoardStrategy.OneBoardPerPage:
        let disabledToolTipText;
        if (!this.canSplitPDF) {
          disabledToolTipText = this.translateService.instant(
            'This file cannot be split across multiple boards',
          );
        } else if (isParticipant) {
          disabledToolTipText = this.translateService.instant(
            'You should be a host to be able to use this option',
          );
        } else if (isFollower) {
          disabledToolTipText = this.translateService.instant(
            'You cannot import to a different board while the host is leading',
          );
        } else {
          disabledToolTipText = this.translateService.instant(
            `Your PDF must not be more than ${MAX_PAGES_FOR_ONE_PAGE_BER_BOARD} pages to use this option`,
          );
        }
        return {
          text: this.translateService.instant('One page per board'),
          icon: {
            name: 'one_page_per_board',
            type: IconTypes.svgIcon,
          },
          tooltipText: this.translateService.instant('Boards will be grouped \n in a folder'),
          disabledToolTipText: disabledToolTipText,
          disabled:
            isParticipant ||
            isFollower ||
            this.selectedPagesCount > MAX_PAGES_FOR_ONE_PAGE_BER_BOARD ||
            !this.canSplitPDF,
          hidden: !isBoardFoldersFlagEnabled,
        };
    }
  }

  itemSelected(selectedItemIndex: number) {
    this.selectedStrategy = Object.values(PDFBoardStrategy)[selectedItemIndex];
  }

  submitStrategy() {
    this.strategySelected.emit(this.selectedStrategy);
  }

  goBackToPreview() {
    this.backToPreviewSubmit.emit();
  }

  protected readonly PDFBoardStrategy = PDFBoardStrategy;
  protected readonly IconTypes = IconTypes;
  protected readonly Object = Object;
}

enum ERRORS {
  GRANT_MICROPHONE_ACCESS = 'E01',
  GRANT_CAMERA_ACCESS = 'E02',
  MICROPHONE_NOT_FOUND = 'E03',
  CAMERA_NOT_FOUND = 'E04',
  MICROPHONE_NOT_SENDING_AUDIO = 'E05',
  NO_CAMERA_INPUT_DETECTED = 'E06',
  RECONNECTING_TO_CALL = 'E07',
  RECONNECTING_TO_SERVER = 'E08',
  FAILED_TO_START_MICROPHONE = 'E09',
  FAILED_TO_START_CAMERA = 'E10',
  GRANT_SCREENSHARE_ACCESS = 'E11',
  UPLOAD_FAILED = 'E14',
  BOARD_CHANGES_NOT_SAVED = 'E15',
  JOIN_SAME_SPACE_FROM_MULIPLE_TABS = 'E16',
  JOIN_CALL_FAILED = 'E17',
  RECONNECT_TO_CALL_FAILED = 'E18',
  HOST_HAS_ENDED_CALL = 'E19',
  UNSUPPORTED_SAML_PROVIDER = 'E20',
  FAILED_TO_LOAD_SPACES = 'E21',
  UNSUPPORTED_FB_ACCOUNT = 'E22',
  NETWORK_DISCONNECTED = 'E23',
  ACTION_FAILED = 'E24',
  JOIN_ROOM_FAILED = 'E25',
  AUTH_FAILED = 'E26',
  RECORDING_DOWNLOAD_FAILED = 'E27',
  GRANT_CAMERA_AND_OR_MICROPHONE_ACCESS = 'E28',
  CAMERA_AND_MICROPHONE_NOT_FOUND = 'E29',
  NO_CAMERA_AND_MICROPHONE_INPUT_DETECTED = 'E30',
  NO_MICROPHONE_INPUT_DETECTED = 'E31',
  FAILED_APPLYING_VB = 'E32',
  ATTENDANCE_UPDATE_FAILED = 'E33',
  CSV_UPLOAD_FAILED = 'E34',
  FAILED_TO_FETCH_REPORTS_DATA = 'E35',
  FAILED_TO_ADD_RESOURCE = 'E36',
  PDF_PROCESSING_FAILED = 'E37',
  FAILED_TOGGLING_DEVICE = 'E38',
  FAILED_TO_ADD_CUSTOM_ATTRIBUTE = 'E39',
  PLAY_LOCAL_VIDEO_TIMEOUT = 'E40',
  USERS_EXISTS_IN_OTHER_INSTITUTION = 'E41',
}

enum WARNINGS {
  INSUFFICIENT_SYSTEM_RESOURCES = 'W01',
  WEAK_NETWORK_DETECTED = 'W02',
  UPDATE_SPACE = 'W03',
  COMPUTER_STRUGGLING_DETECTED = 'W04',
  COMPUTER_ON_TIER_THREE_WHILE_IN_CALL_DETECTED = 'W05',
  FABRIC_ITEMS_SOFT_LIMIT_EXCEEDED = 'W06',
  INSTITUTION_PERMISSIONS_UPDATED = 'W07',
  STOPPED_RECORDING_WHITEBOARD = 'W08',
}

enum REQUESTS {
  REQUEST_TO_UNMUTE = 'R01',
  REQUEST_TO_ENABLE_CAMERA = 'R02',
  OPEN_ROOM_MANAGER = 'R03',
  GO_TO_MAIN_ROOM = 'R04',
}

enum SUCCESSES {
  RECONNECTED_TO_CALL = 'S07',
  RECONNECTED_TO_SERVER = 'S08',
  DELETED_SPACE = 'S09',
  RENAMED_SPACE = 'S10',
  RATING_DONE = 'S11',
  DELETED_SESSION = 'S12',
  END_SESSION = 'S13',
  COPY_LINK_TO_CLIPBOARD = 'S14',
  VISIBILITY_CHANGED_TO_PUBLIC = 'S15',
  APP_RESET = 'S16',
  REFERRAL_SENT = 'S17',
  ACTION_SUCCESSFUL = 'S18',
  JOINED_ROOM = 'S19', // @Nour defined
  BREAKOUT_ROOMS_CHANGES_APPLIED = 'S20', // @Nour defined
  RECORDING_DOWNLOADED = 'S21',
  PERMISSIONS_UPDATED = 'S22',
  COMPUTER_SPEEDING_UP = 'S23',
  BREAKOUT_ROOMS_USERS_RECALLED = 'S24', // @Nour defined
  RATING_POSITIVE_GIVEN = 'S25',
  RATING_NEGATIVE_GIVEN = 'S26',
  REQUEST_ALL_TO_UNMUTE_SENT = 'S27',
  REQUEST_ALL_TO_TURN_ON_CAMERA_SENT = 'S28',
  BOARD_EXPORTED = 'S29',
  REMOVE_USER_FROM_SPACE = 'S30',
  UPDATED_SPACE_DETAILS = 'S31',
  SPACE_TEMPLATE_CREATED = 'S32',
  SPACE_TEMPLATE_DELETED = 'S33',
  USERS_INVITED_IN_PROFILES = 'S34',
}

enum INFOS {
  SPACE_LOCKED = 'I01',
  SPACE_JOIN_OR_LEAVE = 'I02',
  SPACE_CURSORS_HIDDEN = 'I03',
  SPACE_CURSORS_RESTORED = 'I04',
  SCREEN_SHARING_STARTED = 'I05',
  ITEM_DRAG_DISABLED = 'I06',
  GOOGLE_DRIVE_FILE_NOT_SUPPORTED = 'I07',
  ONEDRIVE_IMPORT_ERROR = 'I08',
  FILE_DOWNLOADING_INDICATOR = 'I09',
  JOIN_CALL_ON_ROOM_CHANGE = 'I10',
  RECORDING_ACTIVE = 'I12',
  TRANSCRIPTION_ACTIVE = 'I13',
  RECORDING_ENDED = 'I14',
  SPACE_CLONE_LOADING = 'I15',
  BREAKOUT_ROOMS_EMPTY_USERS_RECALL = 'I16', // @Nour defined
  RECONNECTING_TO_CALL = 'I17',
  PARTICIPANTS_INACTIVE = 'I18',
  TAKING_SCREENSHOT = 'I19',
  INSERTING_RESOURCE = 'I20',
  EVENT_STARTED_IN_SPACE = 'I21',
  LIVE_NOTIFICATION_SPEECH = 'I22',
  LESSON_GENERATION_IN_PROGRESS = 'I23',
  LESSON_READY = 'I24',
}

enum PROMPTS {
  MIC_OFF_BY_HOST = 'P05',
  CAMERA_OFF_BY_HOST = 'P06',
}

enum ACCESS_REQUESTS {
  ACCESS_REQUESTS_NOTIFICATION = 'AR01',
  ACCESS_REQUESTS_WARNING = 'AR02',
  ACCESS_REQUESTS_ERROR = 'AR03',
}

enum WAITING_ROOM {
  WAITING_ROOM_REQUEST_NOTIFICATION = 'WR01',
  WAITING_ROOM_WARNING = 'WR02',
  WAITING_ROOM_ERROR = 'WR03',
}

export { ERRORS, INFOS, PROMPTS, REQUESTS, SUCCESSES, WARNINGS, ACCESS_REQUESTS, WAITING_ROOM };

<div class="question-fragments" [style.margin-left]="number ? 25 * (level - 1) + 'px' : 0">
  <span *ngIf="number" class="number">{{ '(' + number + ')' }}</span>
  <div class="fragments-container position-relative w-100" *ngIf="fragments?.length > 0">
    <ui-fragment
      *ngFor="let fragment of fragments; index as i"
      class="ui-fragment break-inside-avoid"
      [explType]="explType"
      [worksheet]="worksheet"
      [question]="question"
      [note]="note"
      [class.cmy-4]="print"
      [class.cmy-8]="!print"
      [print]="print"
      [metadata]="metadata"
      [signImage]="signImage"
      [showLoadingIndicator]="showLoadingIndicator"
      [compressedRendering]="compressedRendering"
      [fragment]="fragment"
      [enableCopy]="enableCopy"
      [styleOverride]="styleOverride"
      [class.fragment-pdf]="fragment.type === 'PDF'"
      [number]="i === 0 && fragmentNumber"
      [marks]="i === 0 && metadata?.marks"
    ></ui-fragment>
  </div>
</div>

import * as uuid from 'uuid';

import { Course, User } from './user';

export enum RSVPStatusEnum {
  YES = 'YES',
  NO = 'NO',
  MAYBE = 'MAYBE',
}

export enum TypeEnum {
  SESSION = 'SESSION',
}

// TODO: deprecate
export enum RecurrencePattern {
  DNR = 'Does not repeat',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  CUSTOM = 'Custom',
  WEEKDAYS = 'Weekdays',
  WEEKLY_ON_DAY = 'WEEKLY_ON_DAY',
}

export enum RECURRENCE_FREQUENCY_TYPE {
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
  YEARS = 'years',
}

export enum RECURRENCE_ACTION_TYPE {
  ALL = 'ALL',
  FUTURE = 'FUTURE',
  CURRENT = 'CURRENT',
}

export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const HOURS_FORMAT = 'hh:mm a';

export class Participant {
  // TODO: make API consistent between getters and setters
  user_id: any;
  rsvp_status: RSVPStatusEnum;

  constructor(res: any) {
    this.user_id = res.user_id;
    this.rsvp_status = res.rsvp_status;
  }
}

export class Link {
  id: string;
  link_type: TypeEnum;
  recurring_instance_time?: Date;

  constructor(res: any) {
    this.id = res.id;
    this.link_type = res.link_type;
    this.recurring_instance_time = res.recurring_instance_time;
  }
}

export class Recurrence {
  id?: string;
  frequency: number;
  frequencyType: RECURRENCE_FREQUENCY_TYPE;
  times: number[]; // repeat on these dates
  totalOccurrences?: number; // end after this many times
  timesMonthly?: string; // repeat on day every nth week -> 2:1 (repeat on every monday of the second week)
  startDate?: Date;
  text?: string;

  constructor(res: any) {
    this.id = res.id || uuid.v4();
    this.frequency = res.frequency;
    this.frequencyType = res.frequencyType;
    this.times = res.times;
    this.totalOccurrences = res.totalOccurrences;
    this.timesMonthly = res.timesMonthly;
    this.startDate = res.startDate;
    this.text = res.text;
  }
}

export class Event {
  _id: string;
  institution_id: string;
  site?: string;
  creator: string;

  // @TODO mfmansoo deprecate the timestamps https://pncl.atlassian.net/browse/BUGS-130
  creation_time: Date;
  last_update_time: Date;
  title: string;
  description: string;
  start_time: Date;
  end_time?: Date;
  duration: number; // Duration of event in minutes
  participants: Participant[];
  location: string;
  reccurrence_pattern?: RecurrencePattern;
  reccurrence_end_time?: Date;
  // TODO: deprecate this field completely
  course?: Course;
  event_type: TypeEnum;
  // TODO: make API consistent between getters and setters
  host_ids?: any[];
  auto_added_ids?: User[];
  links: Link[];
  enable_lobby: boolean;
  recurrence?: Recurrence;
  createdAt: Date;
  updatedAt: Date;
  custom_attributes?: string[];

  constructor(res: any) {
    this._id = res._id;
    this.institution_id = res.institution_id;
    this.site = res.site;
    this.creator = res.creator;
    this.creation_time = res.creation_time;
    this.last_update_time = res.last_update_date;
    this.title = res.title;
    this.description = res.description;
    this.start_time = new Date(res.start_time);
    this.end_time = new Date(res.end_time ?? res.start_time);
    this.duration = res.duration;
    this.participants = [];
    res.participants?.forEach((user: any) => {
      this.participants.push(new Participant(user));
    });
    this.location = res.location;
    this.reccurrence_pattern = res.reccurrence_pattern;
    this.course = res.course;
    this.event_type = res.event_type;
    this.host_ids = res.host_ids;
    this.links = [];
    this.createdAt = new Date(res.createdAt);
    this.updatedAt = new Date(res.updatedAt);
    this.enable_lobby = res.enable_lobby;
    res.links?.forEach((link: any) => {
      this.links.push(new Link(link));
    });
    this.recurrence = res.recurrence;
    this.reccurrence_end_time = res.reccurrence_end_time;
    this.end_time = res.end_time;
    this.auto_added_ids = res.auto_added_ids ?? [];
    this.custom_attributes = res.custom_attributes;
  }
}

export interface EventAttendance {
  event_id: string;
  user_id: string;
  status: EventAttendanceStatus;
  notes: string;
  join_time?: Date;
  modifier_host_id?: string;
}

export enum EventAttendanceStatus {
  PRESENT = 'Present',
  LATE = 'Late',
  ABSENT = 'Absent',
  LEFT = 'Left',
  EXCUSED = 'Excused',
}

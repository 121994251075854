import { TranslateService } from '@ngx-translate/core';
import { DeviceAndBrowserDetectorService } from 'src/app/services/device-and-browser-detector.service';
import { DeviceErrorsNotificationsService } from 'src/app/services/device-errors-notifications.service';
import { DeviceErrorType, DeviceState } from 'src/app/models/device-manger';
import { DeviceType } from '../devices-handle-util';
import {
  Browser,
  DeviceErrorHelperModelBase,
  DeviceErrorHelperStep,
} from './device-error-helper-model-base';

export class PermissionHelper extends DeviceErrorHelperModelBase {
  private _steps: DeviceErrorHelperStep[] = [];
  protected helperSupportedBrowsers: string[] = [
    Browser.CHROME,
    Browser.EDGE,
    Browser.SAFARI,
    Browser.FIREFOX,
  ];

  constructor(
    deviceDetector: DeviceAndBrowserDetectorService,
    translateService: TranslateService,
    private deviceErrorsNotificationsService: DeviceErrorsNotificationsService,
    deviceType: DeviceType,
  ) {
    super(translateService, deviceDetector, deviceType);
  }

  getSteps(): DeviceErrorHelperStep[] {
    this._steps = [];
    this.buildHelperFlow();
    return this._steps;
  }

  getMobileTitle(): string {
    return this.translateService.instant('Please allow Camera & Mic');
  }

  getDesktopTitle(): string {
    return this.translateService.instant('Please allow access to your camera and / or microphone');
  }

  dismissErrorNotification() {
    this.deviceErrorsNotificationsService.dismissPermissionErrorNotification();
  }

  hasRelatedError(cam: DeviceState, mic: DeviceState) {
    return (
      this.deviceErrorsNotificationsService.hasErrorOfType(
        cam,
        DeviceErrorType.PERMISSION_DENIED,
      ) ||
      this.deviceErrorsNotificationsService.hasErrorOfType(mic, DeviceErrorType.PERMISSION_DENIED)
    );
  }

  showErrorNotification(cam: DeviceState, mic: DeviceState) {
    this.deviceErrorsNotificationsService.showPermissionErrorNotification(cam, mic);
  }

  private buildHelperFlow() {
    if (this.deviceDetector.isDesktop() && !this.deviceDetector.isTabletDevice()) {
      this.buildDesktopHelperFlow();
      return;
    }
    if (this.deviceDetector.isiOSiPhone()) {
      this.buildiOSHelperFlow();
      return;
    }
    if (this.deviceDetector.isAndroidPhone()) {
      this.buildAndroidHelperFlow();
      return;
    }
    if (this.deviceDetector.isAndroidTablet()) {
      this.buildAndroidHelperFlow(false);
      return;
    }
    if (this.deviceDetector.isiOSiPad()) {
      this.buildiOSHelperFlow(false);
    }
  }

  private buildDesktopHelperFlow() {
    this._steps = [];

    if (this.deviceDetector.isEdge()) {
      this.constructHelperStepsEdgeDesktop();
      return;
    }
    if (this.deviceDetector.isSafari()) {
      this.constructHelperStepsSafariDesktop();
      return;
    }
    if (this.deviceDetector.isChrome()) {
      this.constructHelperStepsChromeDesktop();
      return;
    }
    if (this.deviceDetector.isFirefox()) {
      this.constructHelperStepsFirefoxDesktop();
      return;
    }
    // Non supported browsers, we will use Chrome helper steps
    this.constructHelperStepsChromeDesktop();
  }

  private buildiOSHelperFlow(iPhone = true) {
    if (this.deviceDetector.isSafari()) {
      this.constructHelperStepsSafariiOS(iPhone);
      return;
    }
    // Handle the rest supported browsers
    // If the current browser isn't supported yet, images will be the same as chrome
    this.constructHelperStepsOtherBrowsersiOS(iPhone);
  }

  private constructHelperStepsEdgeDesktop() {
    this._steps.push(
      {
        showStepLocation: false,
        stepText: this.translateService.instant('Click the lock icon in the address bar'),
        stepImgSrc: 'edge_helper_step_one.png',
        stepWidth: 195,
      },
      {
        showStepLocation: false,
        stepText: `${this.translateService.instant('Select')} <b>${this.translateService.instant(
          'Allow',
        )}</b> ${this.translateService.instant('from the dropdown')}`,
        stepImgSrc: 'edge_helper_step_two.png',
        stepWidth: 195,
      },
    );
  }

  private constructHelperStepsSafariDesktop() {
    this._steps.push(
      {
        showStepLocation: false,
        stepText: this.translateService.instant('Click the lock icon in the address bar'),
        stepImgSrc: 'safari_helper_step_one.png',
        stepWidth: 195,
      },
      {
        showStepLocation: false,
        stepText: this.translateService.instant('Enable camera and microphone access'),
        stepImgSrc: 'safari_helper_step_two.png',
        stepWidth: 195,
      },
    );
  }

  private constructHelperStepsFirefoxDesktop() {
    this._steps.push(
      {
        showStepLocation: false,
        stepText: this.translateService.instant('Click preferences in the address bar\n'),
        stepImgSrc: 'firefox_helper_step_one.png',
        stepWidth: 195,
      },
      {
        showStepLocation: false,
        stepText: `${this.translateService.instant('Clear')} <b>${this.translateService.instant(
          'Blocked Temporarily\n',
        )}</b>`,
        stepImgSrc: 'firefox_helper_step_two.png',
        stepWidth: 195,
      },
      {
        showStepLocation: false,
        stepText: `${this.translateService.instant(
          'Click the blue',
        )} <b>${this.translateService.instant('Refresh\n')}</b>${this.translateService.instant(
          'button in the corner',
        )}`,
        stepImgSrc: 'firefox_helper_step_three.png',
        stepWidth: 195,
      },
      {
        showStepLocation: false,
        stepText: `${this.translateService.instant(
          'On refresh, click',
        )} <b>${this.translateService.instant('Allow')}</b> ${this.translateService.instant(
          'when prompted and\nthen check',
        )}  <b>${this.translateService.instant('Remeber this decision')}</b>`,
        stepImgSrc: 'firefox_helper_step_four.png',
        stepWidth: 195,
      },
    );
  }

  private constructHelperStepsChromeDesktop() {
    this._steps.push(
      {
        showStepLocation: false,
        stepText: `${this.translateService.instant('Click the ')}<b>${this.translateService.instant(
          'padlock',
        )}</b> or <b>${this.translateService.instant('information')}</b>\nicon on the URL bar`,
        stepImgSrc: 'chrome_helper_step_one.png',
        stepWidth: 195,
      },
      {
        showStepLocation: false,
        stepText: `${this.translateService.instant('Select')} <b>${this.translateService.instant(
          'Allow',
        )}</b> ${this.translateService.instant('from the dropdown')}`,
        stepImgSrc: 'chrome_helper_step_two.png',
        stepWidth: 195,
      },
    );
  }

  private constructHelperStepsSafariiOS(iPhone = true) {
    const firstStep: DeviceErrorHelperStep = {
      showStepLocation: true,
      stepLocationImgSrc: 'apple_settings_icon.png',
      stepLocationText: 'Settings',
      stepText: `${this.translateService.instant(
        'Go to your system settings and select',
      )} <b>${this.translateService.instant('Safari')}</b>`,
      stepImgSrc: `ios_safari_step_one${iPhone ? '' : '_ipad'}.png`,
      stepWidth: 195,
    };

    if (!iPhone) {
      firstStep.stepImgCustomStyle = 'custom_img_ipad';
    }

    this._steps.push(
      firstStep,
      {
        showStepLocation: true,
        stepLocationImgSrc: 'apple_settings_icon.png',
        stepLocationText: 'Settings',
        stepText: `${this.translateService.instant('Select')} <b>${this.translateService.instant(
          'Camera',
        )}</b> ${this.translateService.instant('and')} <b>${this.translateService.instant(
          'Microphone',
        )}</b>`,
        stepImgSrc: `ios_safari_step_two${iPhone ? '' : '_ipad'}.png`,
        stepWidth: 195,
      },
      {
        showStepLocation: true,
        stepLocationImgSrc: 'apple_settings_icon.png',
        stepLocationText: 'Settings',
        stepText: `${this.translateService.instant('Select')} <b>${this.translateService.instant(
          'Allow',
        )}</b> ${this.translateService.instant('in both sections')}`,
        stepImgSrc: `ios_safari_step_three${iPhone ? '' : '_ipad'}.png`,
        stepWidth: 195,
      },
      {
        showStepLocation: true,
        stepLocationImgSrc: 'safari.png',
        stepLocationText: 'Settings',
        stepText: `${this.translateService.instant(
          'Open Safari again, and tap',
        )} <b>${this.translateService.instant('Allow')}</b> ${this.translateService.instant(
          'refresh',
        )}  ${this.translateService.instant('below')}`,
        stepImgSrc: `ios_safari_step_four${iPhone ? '' : '_ipad'}.png`,
        stepWidth: 195,
      },
    );
  }

  private constructHelperStepsOtherBrowsersiOS(iPhone = true) {
    const currentBrowserName = this.currentBrowserName();
    const browerNameCamalCase =
      currentBrowserName.charAt(0).toUpperCase() + currentBrowserName.substring(1);

    const firstStep: DeviceErrorHelperStep = {
      showStepLocation: true,
      stepLocationImgSrc: 'apple_settings_icon.png',
      stepLocationText: 'Settings',
      stepText: this.translateService.instant(
        `Go to iOS settings and select ${browerNameCamalCase}`,
      ),
      stepImgSrc: `ios_non_safari_step_one_${currentBrowserName}${iPhone ? '' : '_ipad'}.png`,
      stepWidth: 195,
    };

    if (!iPhone) {
      firstStep.stepImgCustomStyle = 'custom_img_ipad';
    }

    this._steps.push(
      firstStep,
      {
        showStepLocation: true,
        stepLocationImgSrc: 'apple_settings_icon.png',
        stepLocationText: 'Settings',
        stepText: this.translateService.instant('Turn on microphone and camera'),
        stepImgSrc: `ios_non_safari_step_two${iPhone ? '' : '_ipad'}.png`,
        stepWidth: 195,
      },
      {
        showStepLocation: true,
        stepLocationImgSrc: `${currentBrowserName}.png`,
        stepLocationText: browerNameCamalCase,
        stepText: `${this.translateService.instant(
          'Refresh this page & press',
        )} <b>${this.translateService.instant('Allow')}</b>`,
        stepImgSrc: `ios_non_safari_step_three${iPhone ? '' : '_ipad'}.png`,
        stepWidth: 195,
      },
    );
  }

  private buildAndroidHelperFlow(isPhone = true) {
    this._steps.push(
      {
        showStepLocation: false,
        stepText: this.translateService.instant('Tap the lock icon in the address bar'),
        stepImgSrc: 'android_step_one_mobile.png',
        stepWidth: 195,
      },
      {
        showStepLocation: false,
        stepText: this.translateService.instant('Tap on Permissions'),
        stepImgSrc: 'android_step_two_mobile.png',
        stepWidth: 195,
      },
      {
        showStepLocation: false,
        stepText: this.translateService.instant(
          isPhone ? 'Turn on camera and microphone' : 'Enable camera and microphone access',
        ),
        stepImgSrc: 'android_step_three_mobile.png',
        stepWidth: 195,
      },
    );
  }
}

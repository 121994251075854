<div class="dialog-folder-container">
  <div class="title-container">
    <h5 mat-dialog-title>{{ data.title ?? 'Create new folder' | translate }}</h5>
    <button mat-icon-button class="close-icon" (click)="closeDialog()" aria-label="Close">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="content" mat-dialog-content>
    <form>
      <div class="mb-3">
        <label for="Folder_name_input" class="form-label folder-Name-label">{{
          'FOLDER NAME' | translate
        }}</label>
        <input
          type="text"
          cdkFocusInitial
          (input)="checkFolderName()"
          [(ngModel)]="folder_name"
          placeholder="New Folder"
          name="folder"
          class="{{
            !folder_exist
              ? 'form-control folder-name-field'
              : 'form-control folder-name-field is-invalid'
          }}"
          id="Folder_name_input"
          aria-describedby="Folder_Name_feedback"
          required
        />
        <div id="Folder_Name_feedback" class="invalid-feedback">
          A folder with this name already exists in this directory.
        </div>
      </div>
    </form>
  </div>
  <div class="buttons" mat-dialog-actions>
    <button class="cancel-button" mat-raised-button (click)="closeDialog()">
      {{ 'Cancel' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="folder_exist || folder_name.length === 0"
      (click)="createFolder()"
    >
      {{ 'Create' | translate }}
    </button>
  </div>
</div>

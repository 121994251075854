import { Pipe, PipeTransform } from '@angular/core';
import { extractMath } from 'extract-math';

@Pipe({
  name: 'segments',
})
export class SegmentsPipe implements PipeTransform {
  transform(fragment): any {
    return fragment.data && extractMath(fragment.data);
  }
}

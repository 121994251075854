<div class="card-padding">
  <mat-card #card class="w-100 cp-0">
    <mat-card-header class="title d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <i
          *ngIf="titleIconClass || titleIcon"
          class="cmr-4 d-flex align-items-center justify-content-center"
          [ngClass]="['title-icon', titleIconClass]"
          >{{ titleIcon }}</i
        >
        <span class="title-text" [class.no-icon]="!titleIcon && !titleIconClass">
          {{ title | translate }}
        </span>
      </div>
      <div *ngIf="showHeaderRightSection" class="d-flex align-items-center">
        <i class="material-icons cursor-pointer" [matMenuTriggerFor]="moreMenu">more_horiz</i>
        <mat-menu #moreMenu="matMenu">
          <button mat-menu-item (click)="deleteContent.emit()">
            <span>{{ 'Delete' | translate }}</span>
          </button>
        </mat-menu>
      </div>
      <div *ngIf="showAddButton" class="d-flex align-items-center">
        <i
          class="material-icons cursor-pointer delete-button-icon"
          (click)="addEvent.emit()"
          [matMenuTriggerFor]="addMenu"
          >add</i
        >
      </div>
      <mat-menu #addMenu="matMenu">
        <ng-content select="[addMenu]"></ng-content>
      </mat-menu>
    </mat-card-header>
    <mat-card-content>
      <div class="content" [class.selected]="selected" [class.hidden]="collapsible && hidden">
        <ng-content></ng-content>
      </div>
      <div class="hidden-button" (click)="hidden = false" *ngIf="collapsible && hidden">
        <mat-icon class="hidden-button-icon">keyboard_arrow_down</mat-icon>
        <span class="hidden-button-text">View More</span>
      </div>
    </mat-card-content>
  </mat-card>
</div>

import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { negate } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { idEquals } from 'src/app/common/utils/messaging';
import { ConversationType, NewConversationRequest } from 'src/app/models/messaging';
import { UserInfo } from 'src/app/models/user';
import { FLAGS, FlagsService } from 'src/app/services/flags.service';
import { MessagingService } from 'src/app/services/messaging.service';

enum ChangeType {
  Add,
  Remove,
}

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-new-message-dialog',
  templateUrl: './new-message-dialog.component.html',
  styleUrls: ['./new-message-dialog.component.scss'],
})
export class NewMessageDialogComponent implements OnInit {
  filteredUserInfo$: Observable<UserInfo[]>;

  groupSelection = false;
  checkedUsers: Record<string, boolean> = {};

  selectedUser: UserInfo | null = null;
  selectedGroupUsers: UserInfo[] = [];

  groupMessagingEnabled: boolean;

  private otherUserInfo$: Observable<UserInfo[]>;
  private searchQuery$ = new BehaviorSubject<string>('');

  constructor(
    public messagingService: MessagingService,
    private router: Router,
    private dialogRef: MatDialogRef<NewMessageDialogComponent>,
    private flagsService: FlagsService,
  ) {
    this.otherUserInfo$ = this.messagingService.otherUserInfo$.pipe(
      map((otherUserInfo) => {
        const shallowCopy = otherUserInfo.filter(({ name }) => name);
        shallowCopy.sort((firstUserInfo, secondUserInfo) =>
          firstUserInfo.name.localeCompare(secondUserInfo.name),
        );
        return shallowCopy;
      }),
    );

    this.filteredUserInfo$ = combineLatest([
      this.otherUserInfo$,
      this.searchQuery$.pipe(debounceTime(100)),
    ]).pipe(
      map(([otherUserInfo, searchQuery]) =>
        otherUserInfo.filter((userInfo) =>
          userInfo.name.toLowerCase().includes(searchQuery.toLowerCase()),
        ),
      ),
    );

    this.groupMessagingEnabled = this.flagsService.isFlagEnabled(FLAGS.ENABLE_GROUP_MESSAGING);
  }

  ngOnInit(): void {
    this.router.events.pipe(untilDestroyed(this)).subscribe(() => this.dialogRef.close());
  }

  executeSearch(searchVal: string): void {
    this.searchQuery$.next(searchVal);
  }

  toggleGroupSelection(): void {
    if (this.groupSelection) {
      this.checkedUsers = {};
      this.selectedGroupUsers = [];
    } else {
      this.selectedUser = null;
    }

    this.groupSelection = !this.groupSelection;
  }

  toggleUserSelection(user: UserInfo): void {
    if (this.groupSelection) {
      this.checkedUsers[user._id] = !this.checkedUsers[user._id];
      this.handleGroupUsersChange(
        user,
        this.checkedUsers[user._id] ? ChangeType.Add : ChangeType.Remove,
      );
    } else {
      this.selectedUser = this.selectedUser?._id === user._id ? null : user;
    }
  }

  checkedUsersChange(user: UserInfo, checkboxChange: MatCheckboxChange): void {
    this.handleGroupUsersChange(user, checkboxChange.checked ? ChangeType.Add : ChangeType.Remove);
  }

  private handleGroupUsersChange(user: UserInfo, changeType: ChangeType): void {
    if (changeType === ChangeType.Add) {
      if (!this.selectedGroupUsers.find(idEquals(user))) {
        this.selectedGroupUsers.push(user);
      }
    } else {
      this.selectedGroupUsers = this.selectedGroupUsers.filter(negate(idEquals(user)));
    }
  }

  private buildNewConversationRequest(): NewConversationRequest | null {
    if (!this.groupSelection && this.selectedUser !== null) {
      return { conversationType: ConversationType.UserConversation, user: this.selectedUser };
    } else if (this.groupSelection && this.selectedGroupUsers.length > 0) {
      return {
        conversationType: ConversationType.GroupConversation,
        users: this.selectedGroupUsers,
      };
    } else {
      return null;
    }
  }

  onComplete(): void {
    this.dialogRef.close(this.buildNewConversationRequest());
  }


}

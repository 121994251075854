import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { IconTypes, PencilIconComponent } from '../../pencil-icon/pencil-icon.component';
import { UiModule } from '../../../../ui/ui.module';
import { TooltipDirective } from '../../../directives/pncl-tooltip.directive';
import { SelectionItem } from '../radio-selection.component';

@Component({
  selector: 'app-radio-selection-item[selectionItem]',
  templateUrl: './radio-selection-item.component.html',
  styleUrls: ['./radio-selection-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PencilIconComponent, NgClass, UiModule, TooltipDirective],
})
export class RadioSelectionItemComponent {
  @Input() selectionItem: SelectionItem = {
    disabled: false,
    disabledToolTipText: '',
    icon: { name: '', type: IconTypes.matIcon },
    text: '',
    tooltipText: '',
  };
  @Input() isSelected: boolean = false;
  @Output() selectItem = new EventEmitter<void>();

  itemSelected(): void {
    if (!this.selectionItem.disabled) {
      this.selectItem.emit();
    }
  }
}

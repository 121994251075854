import { Component, Input, OnChanges } from '@angular/core';
import { LikeService } from 'src/app/services/like.service';
import { LikeContext } from 'src/app/models/user';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-like',
  templateUrl: './like.component.html',
  styleUrls: ['./like.component.scss'],
})
export class LikeComponent implements OnChanges {
  @Input() context?: LikeContext;
  likedState?: boolean;
  numLikes = 0;
  constructor(private likeService: LikeService) {}

  ngOnChanges(): void {
    // Fetch like details for this item.
    if (this.context) {
      this.likeService.getLikeState(this.context).pipe(untilDestroyed(this)).subscribe((data) => {
        this.likedState = data.state;
        this.numLikes = data.num_likes;
      });
    }
  }

  onClick() {
    this.likedState = !this.likedState;
    this.numLikes = this.numLikes + (this.likedState ? 1 : -1);
    // Call service to register new state for context.
    if (this.context) {
      this.likeService.setLikeState(this.context, this.likedState).pipe(untilDestroyed(this)).subscribe(
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
        () => {
          this.likedState = !this.likedState;
        },
      );
    }
  }
}

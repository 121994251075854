<div *ngIf="fullProfile" class="user-profile">
  <i class="material-icons cmb-16 btn-back cml-32" (click)="showList.emit(true)"
    >keyboard_backspace</i
  >
  <div class="d-flex align-items-center justify-content-between">
    <div class="position-relative">
      <app-profile-photo
        [fontSize]="40"
        [user]="fullProfile"
        [width]="120"
        class="cmr-16"
      ></app-profile-photo>
      <button
        mat-mini-fab
        *ngIf="editable"
        class="position-absolute btn-camera"
        (click)="file.click()"
      >
        <mat-icon>camera_alt</mat-icon>
      </button>
      <input type="file" accept="image/*" #file class="d-none" (change)="upload($event)" />
      <ng-container *ngIf="uploadPercent | async; let uploadPer">
        <mat-progress-bar
          class="cmt-8 cmb-16"
          mode="determinate"
          *ngIf="editable && uploadPer !== 100"
          [value]="uploadPer"
        ></mat-progress-bar>
      </ng-container>
    </div>
    <button
      *ngIf="currentEmail === fullProfile.email && !editable"
      mat-mini-fab
      color="primary"
      (click)="editable = true"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <div class="cml-8" *ngIf="currentEmail === fullProfile.email && editable">
      <button mat-raised-button type="submit" color="primary" class="cmr-16" (click)="update()">
        {{ 'Update' | translate }}
      </button>
      <button mat-mini-fab color="warn" (click)="editable = false">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div>
    <h5 class="cmt-16 cmb-0">{{ fullProfile.name }}</h5>
  </div>
  <div>{{ fullProfile.role }}</div>
  <div>
    <span *ngIf="!editable">{{ fullProfile.org_name }}</span>
    <mat-form-field *ngIf="editable">
      <input
        matInput
        [placeholder]="'Organization Name' | translate"
        [(ngModel)]="fullProfile.org_name"
      />
    </mat-form-field>
  </div>
  <div *ngIf="currentEmail !== fullProfile.email" class="cmt-8">
    <button class="m-0" ui-primary routerLink="/messages/user/{{ fullProfile._id }}">
      {{ 'Send message' | translate }}
    </button>
  </div>
  <div class="cmy-24 d-flex align-items-center flex-wrap" *ngIf="fullProfile.courses?.length > 0">
    <span (click)="navigateToNewUser()" class="cmr-24"
      ><strong class="cmr-4">{{ fullProfile.questionAuthored }}</strong
      >{{ 'questions' | translate }}</span
    >
    <span class="cmr-24"
      ><strong class="cmr-4">{{ fullProfile.explanationAuthored }}</strong
      >{{ 'explanations' | translate }}</span
    >
    <span
      ><strong class="cmr-4">{{ fullProfile.courses.length }}</strong
      >{{ 'courses' | translate }}</span
    >
  </div>
  <div class="border-bottom w-100">
    <span>{{ 'Basic details' | translate }}:</span>
  </div>
  <div class="cpl-16 cmb-24">
    <div class="cmy-8">
      <span>{{ 'Email' | translate }}: {{ fullProfile.email }}</span>
    </div>
  </div>
  <div class="border-bottom w-100">
    <span>{{ 'Institution details' | translate }}:</span>
  </div>
  <div class="cpl-16 cmb-24">
    <div class="cmy-8">
      <span>{{ 'Email' | translate }}: {{ fullProfile.institution.email }}</span>
    </div>
    <div class="cmy-8">
      <span>{{ 'Phone number' | translate }}: {{ fullProfile.institution.phone }}</span>
    </div>
    <div class="cmy-8">
      <span>{{ 'Name' | translate }}: {{ fullProfile.institution.name }}</span>
    </div>
    <div class="cmy-8">
      <span>{{ 'Description' | translate }}: {{ fullProfile.institution.description }}</span>
    </div>
  </div>
  <div class="border-bottom w-100">
    <span>{{ 'Bio' | translate }}:</span>
  </div>
  <div class="cpl-16 cmb-24 cpt-8">
    <span *ngIf="!editable">{{ fullProfile.description }}</span>
    <mat-form-field *ngIf="editable" class="w-100">
      <textarea
        matInput
        [placeholder]="'Description' | translate"
        [(ngModel)]="fullProfile.description"
        rows="5"
      ></textarea>
    </mat-form-field>
  </div>
  <div *ngIf="currentEmail === fullProfile.email && (fullProfile | has: Features.PDProfile)">
    <div class="border-bottom w-100 d-flex align-items-center cmt-32">
      <p>
        {{ 'PD profile' | translate }}:
        <a *ngIf="trainerUrl" [href]="trainerUrl" target="_blank">{{ trainerUrl }}</a>
      </p>
    </div>
    <div class="cpl-16 cmb-24 cpt-8">
      <div class="border-bottom w-100 d-flex justify-content-between align-items-center">
        <span>{{ 'Video' | translate }}:</span>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="!editable">
        <iframe
          *ngIf="
            video &&
            (!video.includes('youtube') || (video.includes('youtube') && (video | youtube)))
          "
          style="max-width: 100%"
          width="600"
          height="400"
          [src]="
            video.includes('youtube')
              ? ('https://www.youtube.com/embed/' + (video | youtube) | safe: 'resourceUrl')
              : (video | safe: 'resourceUrl')
          "
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="editable">
        <div class="d-flex align-items-end">
          <mat-form-field>
            <input matInput [placeholder]="'Add a link' | translate" [(ngModel)]="video" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="cpl-16 cmb-24 cpt-8">
      <div class="border-bottom w-100 d-flex justify-content-between align-items-center">
        <span>{{ 'Banner image' | translate }} (1200 &times; 400):</span>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="!editable">
        <img *ngIf="banner" [src]="banner" style="max-width: 100%" />
      </div>
      <div class="cpl-16 cmb-24 cpt-16" *ngIf="editable">
        <div class="d-flex">
          <div class="position-relative cmr-16" *ngIf="banner">
            <img [src]="banner" height="60" />
            <button
              mat-icon-button
              class="btn-remove position-absolute btn-remove-banner"
              (click)="banner = ''"
            >
              <i class="material-icons">close</i>
            </button>
          </div>
          <div class="form-group flex-1">
            <label>{{ 'Select a banner image' | translate }}</label>
            <input type="file" class="form-control-file" (change)="uploadBannerImg($event)" />
          </div>
        </div>
      </div>
    </div>
    <div class="cpl-16 cmb-24 cpt-8">
      <div class="border-bottom w-100 d-flex justify-content-between align-items-center">
        <span>{{ 'Tagline' | translate }}:</span>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="!editable">
        {{ tagline }}
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="editable">
        <div class="d-flex align-items-end">
          <mat-form-field>
            <input matInput [placeholder]="'Add a tagline' | translate" [(ngModel)]="tagline" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="cpl-16 cmb-24 cpt-8">
      <div class="border-bottom w-100 d-flex justify-content-between align-items-center">
        <span>{{ 'Subjects' | translate }}:</span>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="!editable">
        <mat-chip-list>
          <ng-container *ngFor="let s of subjects">
            <mat-chip *ngIf="subjectIds.includes(s._id)">{{ s.name }}</mat-chip>
          </ng-container>
        </mat-chip-list>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="editable">
        <div class="d-flex align-items-end">
          <mat-form-field class="cmr-8">
            <mat-label>{{ 'Select subjects' | translate }}</mat-label>
            <mat-select [(ngModel)]="subjectIds" multiple>
              <mat-option *ngFor="let s of subjects" [value]="s._id"> {{ s.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSe8U5peFQ4lokRhFn0tMIWAuu6XoX5ftgleuEY6mEmxccAenw/viewform"
          target="_blank"
          >+ {{ 'Add subject' | translate }}</a
        >
      </div>
    </div>
    <div class="cpl-16 cmb-24 cpt-8">
      <div class="border-bottom w-100 d-flex justify-content-between align-items-center">
        <span>{{ 'Languages' | translate }}:</span>
        <button ui-primary (click)="addLanguage()" *ngIf="editable">
          + {{ 'Add' | translate }}
        </button>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="!editable">
        <mat-chip-list>
          <mat-chip *ngFor="let s of langCodes">{{ s.language }}</mat-chip>
        </mat-chip-list>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="editable">
        <div class="d-flex align-items-end" *ngFor="let s of langCodes; let i = index">
          <mat-form-field class="cmr-8">
            <input matInput [placeholder]="'Add a language' | translate" [(ngModel)]="s.language" />
          </mat-form-field>
          <button mat-icon-button (click)="removeLanguage(i)" class="cmb-16 btn-remove">
            <i class="material-icons">close</i>
          </button>
        </div>
      </div>
    </div>
    <div class="cpl-16 cmb-24 cpt-8">
      <div class="border-bottom w-100 d-flex justify-content-between align-items-center">
        <span>{{ 'Phones' | translate }}:</span>
        <button ui-primary (click)="addPhone()" *ngIf="editable">+ {{ 'Add' | translate }}</button>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="!editable">
        <mat-chip-list>
          <mat-chip *ngFor="let s of phones">{{ s.phone }}</mat-chip>
        </mat-chip-list>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="editable">
        <div class="d-flex align-items-end" *ngFor="let s of phones; let i = index">
          <mat-form-field class="cmr-8">
            <input matInput [placeholder]="'Add a phone' | translate" [(ngModel)]="s.phone" />
          </mat-form-field>
          <button mat-icon-button (click)="removePhone(i)" class="cmb-16 btn-remove">
            <i class="material-icons">close</i>
          </button>
        </div>
      </div>
    </div>
    <div class="cpl-16 cmb-24 cpt-8">
      <div class="border-bottom w-100 d-flex justify-content-between align-items-center">
        <span>{{ 'Current locations' | translate }}:</span>
        <button ui-primary (click)="addCurrentLocation()" *ngIf="editable">
          + {{ 'Add' | translate }}
        </button>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="!editable">
        <p *ngFor="let s of currentLocations; let i = index">{{ i + 1 }}. {{ s.location }}</p>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="editable">
        <div class="d-flex align-items-end" *ngFor="let s of currentLocations; let i = index">
          <mat-form-field class="cmr-8">
            <input matInput [placeholder]="'Add a location' | translate" [(ngModel)]="s.location" />
          </mat-form-field>
          <button mat-icon-button (click)="removeCurrentLocation(i)" class="cmb-16 btn-remove">
            <i class="material-icons">close</i>
          </button>
        </div>
      </div>
    </div>
    <div class="cpl-16 cmb-24 cpt-8">
      <div class="border-bottom w-100 d-flex justify-content-between align-items-center">
        <span>{{ 'Teaching locations' | translate }}:</span>
        <button ui-primary (click)="addTeachingLocation()" *ngIf="editable">
          + {{ 'Add' | translate }}
        </button>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="!editable">
        <p *ngFor="let s of teachingLocations; let i = index">{{ i + 1 }}. {{ s.location }}</p>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="editable">
        <div class="d-flex align-items-end" *ngFor="let s of teachingLocations; let i = index">
          <mat-form-field class="cmr-8">
            <input matInput [placeholder]="'Add a location' | translate" [(ngModel)]="s.location" />
          </mat-form-field>
          <button mat-icon-button (click)="removeTeachingLocation(i)" class="cmb-16 btn-remove">
            <i class="material-icons">close</i>
          </button>
        </div>
      </div>
    </div>
    <div class="cpl-16 cmb-24 cpt-8">
      <div class="border-bottom w-100 d-flex justify-content-between align-items-center">
        <span>{{ 'Services' | translate }}:</span>
        <button ui-primary (click)="addService()" *ngIf="editable">
          + {{ 'Add' | translate }}
        </button>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="!editable">
        <div class="cmb-16 d-flex" *ngFor="let s of services; let i = index">
          <span>{{ i + 1 }}.</span>
          <div class="cml-4">
            <p>{{ 'Label' | translate }}: {{ s.label }}</p>
            <p>{{ 'Content' | translate }}: {{ s.content }}</p>
            <p>{{ 'Contact' | translate }}: {{ s.contact }}</p>
            <p>
              {{ 'Link' | translate }}: <a [href]="s.link" target="_blank">{{ s.link }}</a>
            </p>
          </div>
        </div>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="editable">
        <div class="d-flex align-items-end cmb-16" *ngFor="let s of services; let i = index">
          <div class="row flex-1">
            <div class="col-xl-3 col-lg-6 col-sm-12">
              <mat-form-field>
                <textarea
                  matInput
                  [placeholder]="'Label' | translate"
                  [(ngModel)]="s.label"
                ></textarea>
              </mat-form-field>
            </div>
            <div class="col-xl-3 col-lg-6 col-sm-12">
              <mat-form-field>
                <textarea
                  matInput
                  [placeholder]="'Content' | translate"
                  [(ngModel)]="s.content"
                ></textarea>
              </mat-form-field>
            </div>
            <div class="col-xl-3 col-lg-6 col-sm-12">
              <mat-form-field>
                <textarea
                  matInput
                  [placeholder]="'Contact' | translate"
                  [(ngModel)]="s.contact"
                ></textarea>
              </mat-form-field>
            </div>
            <div class="col-xl-3 col-lg-6 col-sm-12">
              <mat-form-field>
                <textarea
                  matInput
                  [placeholder]="'Link' | translate"
                  [(ngModel)]="s.link"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <button mat-icon-button (click)="removeService(i)" class="cmb-16 btn-remove">
            <i class="material-icons">close</i>
          </button>
        </div>
      </div>
    </div>
    <div class="cpl-16 cmb-24 cpt-8">
      <div
        class="border-bottom w-100 d-flex justify-content-between align-items-center"
        [class.invalid]="!isValid"
      >
        <span [class.text-danger]="!isValid">{{ 'Events' | translate }}:</span>
        <button ui-primary (click)="addEvent()" *ngIf="editable">+ {{ 'Add' | translate }}</button>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="!editable">
        <div class="cmb-16 d-flex" *ngFor="let s of events; let i = index">
          <span>{{ i + 1 }}.</span>
          <div class="cml-4">
            <p>{{ 'Date' | translate }}: {{ s.month }} {{ s.date }}</p>
            <p>{{ 'Name' | translate }}: {{ s.name }}</p>
            <p>{{ 'Location' | translate }}: {{ s.location }}</p>
            <p>{{ 'Description' | translate }}: {{ s.description }}</p>
          </div>
        </div>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="editable">
        <div class="d-flex align-items-end cmb-16" *ngFor="let s of events; let i = index">
          <div class="row flex-1">
            <div class="col-xl-3 col-lg-6 col-sm-12 d-flex align-items-end">
              <mat-form-field>
                <input
                  matInput
                  required
                  [placeholder]="'Choose a date' | translate"
                  type="date"
                  [value]="s | eventDate"
                  (change)="changeEventDate(i, $event)"
                />
                <mat-error *ngIf="!s.date || !s.month">
                  {{ 'Date is required' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xl-3 col-lg-6 col-sm-12">
              <mat-form-field>
                <textarea
                  matInput
                  required
                  [placeholder]="'Name' | translate"
                  [(ngModel)]="s.name"
                ></textarea>
                <mat-error *ngIf="!s.name">
                  {{ 'Name is required' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xl-3 col-lg-6 col-sm-12">
              <mat-form-field>
                <textarea
                  matInput
                  required
                  [placeholder]="'Location' | translate"
                  [(ngModel)]="s.location"
                ></textarea>
                <mat-error *ngIf="!s.location">
                  {{ 'Location is required' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xl-3 col-lg-6 col-sm-12">
              <mat-form-field>
                <textarea
                  matInput
                  required
                  [placeholder]="'Description' | translate"
                  [(ngModel)]="s.description"
                ></textarea>
                <mat-error *ngIf="!s.description">
                  {{ 'Description is required' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <button mat-icon-button (click)="removeEvent(i)" class="cmb-16 btn-remove">
            <i class="material-icons">close</i>
          </button>
        </div>
      </div>
    </div>
    <div class="cpl-16 cmb-24 cpt-8">
      <div class="border-bottom w-100 d-flex justify-content-between align-items-center">
        <span>{{ 'Experience' | translate }}:</span>
        <button ui-primary (click)="addExperience()" *ngIf="editable">
          + {{ 'Add' | translate }}
        </button>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="!editable">
        <div
          class="cmb-16 d-flex justify-content-between"
          *ngFor="let s of experiences; let i = index"
        >
          <div class="d-flex cmr-16">
            <span>{{ i + 1 }}.</span>
            <div class="cml-4">
              <p>{{ 'Institution' | translate }}: {{ s.institution }}</p>
              <p>{{ 'Role' | translate }}: {{ s.role }}</p>
              <p>{{ 'Period' | translate }}: {{ s.period }}</p>
              <p>{{ 'Location' | translate }}: {{ s.location }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="cpl-16 cmb-24 cpt-8" *ngIf="editable">
        <div class="d-flex align-items-end cmb-16" *ngFor="let s of experiences; let i = index">
          <div class="row flex-1">
            <div class="col-xl-4 col-lg-6 col-sm-12">
              <mat-form-field>
                <textarea
                  matInput
                  [placeholder]="'Institution' | translate"
                  [(ngModel)]="s.institution"
                ></textarea>
              </mat-form-field>
            </div>
            <div class="col-xl-4 col-lg-6 col-sm-12">
              <mat-form-field>
                <textarea
                  matInput
                  [placeholder]="'Role' | translate"
                  [(ngModel)]="s.role"
                ></textarea>
              </mat-form-field>
            </div>
            <div class="col-xl-4 col-lg-6 col-sm-12">
              <mat-form-field>
                <textarea
                  matInput
                  [placeholder]="'Period' | translate"
                  [(ngModel)]="s.period"
                ></textarea>
              </mat-form-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-12">
              <mat-form-field>
                <textarea
                  matInput
                  [placeholder]="'Location' | translate"
                  [(ngModel)]="s.location"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <button mat-icon-button (click)="removeExperience(i)" class="cmb-16 btn-remove">
            <i class="material-icons">close</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

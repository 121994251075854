<h2 mat-dialog-title>{{ 'Change visibility setting?' | translate }}</h2>
<div mat-dialog-content>
  <p>
    All questions' visibility will be changed to {{ data.visibility }} to match the visibility
    setting of this set.
  </p>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button class="btn btn-light flex-1" [mat-dialog-close]="false">
    {{ 'Cancel' | translate }}
  </button>
  <button
    class="btn btn-primary flex-1 cml-16"
    style="background-color: #0f42c8"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    {{ 'Proceed' | translate }}
  </button>
</div>

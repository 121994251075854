<h2 mat-dialog-title class="text-center">{{ 'Feature Flags' | translate }}</h2>
<div mat-dialog-body class="modal-body">
  <div *ngFor="let flag of customFeatureFlags | keyvalue">
    <input
      type="checkbox"
      id="'checkbox_' + flag.key"
      name="'checkbox_' + flag.key"
      [(ngModel)]="customFeatureFlags[flag.key]"
    />
    <label [attr.for]="'checkbox_' + (flag.key || '')">{{ flag.key }}</label>
  </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-between">
  <button class="btn btn-light flex-1" [mat-dialog-close]="false">
    {{ 'Cancel' | translate }}
  </button>
  <button class="btn btn-primary text-white btn-active flex-1 cml-16" (click)="resetFlags()">
    {{ 'Reset Flags' | translate }}
  </button>
  <button
    class="btn btn-primary text-white btn-active flex-1 cml-16"
    (click)="saveFlags()"
    cdkFocusInitial
  >
    {{ 'Save Flags' | translate }}
  </button>
</div>
<button mat-icon-button class="btn-cancel" [mat-dialog-close]="false">
  <i class="material-icons">close</i>
</button>

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

import { ResourceItemModel } from 'src/app/models/resource';
import { UploadFile } from '../../sessions/session/items-canvas/items-canvas.component';
import { IconTypes } from '../../standalones/components/pencil-icon/pencil-icon.component';
import { Libraries } from './upload/upload.component';

@UntilDestroy()
@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      libraries: Libraries[];
      type: ResourceItemModel[];
      newPdfFlow?: boolean;
    },
  ) {}

  closeSubmit(file?: UploadFile) {
    this.dialogRef.close(file);
  }

  protected readonly IconTypes = IconTypes;
}

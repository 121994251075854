<div class="top-bar d-flex align-items-center">
  <svg-icon
    [icon]="icon"
    [height]="height"
    [width]="width"
    filter="invert(0%) sepia(44%) saturate(6%) hue-rotate(145deg) brightness(84%) contrast(101%);"
    class="me-2"
  >
  </svg-icon>
  <span class="me-2 title">{{ title }}</span>
  <svg-icon icon="incognito-mode" [height]="12" [width]="12" class="me-2 private-color"></svg-icon>
  <span [hidden]="!isExpanded" class="me-2 private private-color">VISIBLE ONLY TO YOU</span>
  <div class="ms-auto d-flex align-items-center">
    <ng-content></ng-content>
    <i class="material-icons close-button" (click)="handleCloseClick()"> close </i>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventDate',
})
export class EventDatePipe implements PipeTransform {
  transform(event): any {
    if (!event.month || !event.date) {
      return '';
    }
    return new Date(`${event.month  } ${  event.date  } ${  new Date().getFullYear()}`).toISOString().substr(0, 10);
  }
}

import { ResourceFilterParams, ResourceFilterParamsEnum } from './params';
import { Course as ICourse } from './user';

/**
 * The corresponding strings should match The properties of ResourceFilterParams
 */
 export enum FileParam {
  Type = 'Type',
  Topic = 'topics',
  Course = 'Course',
  UploadedBy = 'Uploaded By',
  Tag = 'tags',
  Visibility = 'visibility',
  InstTags = 'Institution tags',
  CourseTags = 'Course tags'
  // add more filters
}

export interface ISortStrategy {
  name: string,
  sortFunction: (any?) => any
}

export class Filter {
  item: any;
  value = false;
  xMeta?: Record<string, any>;
  displayFunc: ((item: any) => string) = () => this.item;
  course?: ICourse;

  constructor(item, displayFunc?, value = false, course?: ICourse) {
    this.item = item;
    if (displayFunc) {
      this.displayFunc = displayFunc;
    }
    this.value = value;
    this.course = course
  }


  isCourseLevel() {
    return !!this.course;
  }

  getCourseName() {
    return this.course?.name;
  }

  getCourseID() {
    return this.course?._id;
  }
}

export class FilterSet {
  name: FileParam;
  queryParam: ResourceFilterParamsEnum;
  filters: Filter[];
  expand = false;
  // it's used to determine which function to apply the filters.
  _isList: boolean;
  icon?: string;

  applyFilters: (item: any, params: ResourceFilterParams) => ResourceFilterParams;
  getID: (item: any) => any;

  constructor(
    name: FileParam,
    queryParam: ResourceFilterParamsEnum,
    filters: Filter[],
    {
      getID = (item: any) => item,
      isList = true,
      icon = '',
    } = {}) {
    this.name = name;
    this.queryParam = queryParam;
    this.filters = filters;
    this._isList = isList;
    this.icon = icon.length ? icon : undefined;

    this.getID = getID;
    this.applyFilters = this._isList ? this.applyListFilter : this.applyFilter;
  }

  public set isList(value: boolean) {
    this._isList = value;
    this.applyFilters = this._isList ? this.applyListFilter : this.applyFilter;
  }

  applyListFilter(item: any, params: ResourceFilterParams = {}): ResourceFilterParams {
    if (!params[this.queryParam]) {
      params[this.queryParam] = [] as any;
    }
    (params[this.queryParam] as any[]).push(this.getID(item));
    return params;
  }

  applyFilter(item: any, params: ResourceFilterParams): ResourceFilterParams {
    const id = this.getID(item);
    if (id) {
      params[this.queryParam] = id;
    }
    return params;
  }

  hasActiveFilter() {
    return this.filters.findIndex(filter => filter.value) !== -1;
  }

  clearFilters(index = -1) {
    if (index != -1) {
      this.filters[index].value = false;
    }
    else {
      this.filters.forEach(filter => {
        filter.value = false;
      })
    }
  }
}

import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest, filter, switchMap } from 'rxjs';
import { ParticipantEventAction } from '../common/interfaces/rtc-interface';
import { ParticipantPresenceStatus } from '../common/utils/presence.util';
import { Session } from '../models/session';
import { PresenceRepository } from '../state/presence.repository';
import { SpaceRepository } from '../state/space.repository';
import { User } from '../models/user';
import { FLAGS, FlagsService } from './flags.service';
import {
  ContextTypeEnum,
  PresenceData,
  PresenceType,
  RedisPresenceV2,
} from './presence_v2.service';
import { ProviderStateService } from './provider-state.service';
import { SessionCallParticipantsService } from './session-call-participants.service';
import { SessionSharedDataService } from './session-shared-data.service';

export enum PresencsStoreType {
  firebase = 'firebase',
  redis = 'redis',
}

export interface PresenceRoomInfo {
  spaceId: string;
  breakoutRoomId: string;
}

export enum PresenceContext {
  SessionContext = 'session', // in-session presence and calling
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PresenceProvider {
  enablePresenceV2 = true;
  latestActiveSpaceId?: string;
  latestActiveRoomId?: string;

  constructor(
    private flagsService: FlagsService,
    private redisPresenceV2: RedisPresenceV2,
    private presenceRepo: PresenceRepository,
    private providerStateService: ProviderStateService,
    private spaceRepo: SpaceRepository,
    private sessionCallParticipantsService: SessionCallParticipantsService,
    private sessionSharedDataService: SessionSharedDataService,
  ) {
    combineLatest([this.spaceRepo.activeSpaceId$, this.spaceRepo.activeSpaceCurrentRoomUid$])
      .pipe(untilDestroyed(this))
      .subscribe(([spaceId, currentRoomUid]) => {
        // Either space of breakout room change
        if (spaceId !== this.latestActiveSpaceId || currentRoomUid !== this.latestActiveRoomId) {
          if (this.latestActiveSpaceId) {
            this.removeSpacePresence(this.latestActiveSpaceId, this.latestActiveRoomId);
            this.removeCallPresence(this.latestActiveSpaceId, this.latestActiveRoomId);
          }
          if (spaceId && currentRoomUid) {
            this.setSpacePresence(spaceId, currentRoomUid);
          }
        }

        // Space changed
        if (spaceId !== this.latestActiveSpaceId) {
          if (spaceId) {
            this.joinSpacePresenceRooms(
              [spaceId],
              [this.constructPresenceInfoObject(spaceId, currentRoomUid)],
            );
          } else {
            if (this.latestActiveSpaceId) {
              this.leaveSpacePresenceRooms(
                [this.latestActiveSpaceId],
                [
                  this.constructPresenceInfoObject(
                    this.latestActiveSpaceId,
                    this.latestActiveRoomId,
                  ),
                ],
              );
            }
          }
        }
        this.latestActiveSpaceId = spaceId;
        this.latestActiveRoomId = currentRoomUid;
      });

    this.providerStateService.participantEvents$
      .pipe(
        untilDestroyed(this),
        filter(
          (participantEvent) =>
            !!participantEvent?.participant.local &&
            participantEvent.action !== ParticipantEventAction.UPDATED,
        ),
      )
      .subscribe((rtcCallEvent) => {
        if (!rtcCallEvent) {
          return;
        }
        if (this.spaceRepo.activeSpaceId && rtcCallEvent.action === ParticipantEventAction.JOINED) {
          this.setCallPresence(
            this.spaceRepo.activeSpaceId,
            this.spaceRepo.activeSpaceCurrentRoomUid,
          );
        } else if (
          this.latestActiveSpaceId &&
          rtcCallEvent.action === ParticipantEventAction.LEFT
        ) {
          this.removeCallPresence(this.latestActiveSpaceId, this.latestActiveRoomId);
        }
      });

    this.sessionSharedDataService.sessionChanged
      .pipe(untilDestroyed(this))
      .subscribe((sessionChange) => {
        if (!sessionChange.oldSessionId) {
          return;
        }
        this.leaveSpacePresenceRooms(
          [sessionChange.oldSessionId],
          [
            this.constructPresenceInfoObject(
              sessionChange.oldSessionId,
              sessionChange.oldBreakoutRoomId,
            ),
          ],
        );
      });
  }

  // #region write presence
  // call both firebase and redis
  setSpacePresence(spaceId: string, breakoutRoomId?: string): void {
    if (this.flagsService.isFlagEnabled(FLAGS.BREAKOUT_ROOMS)) {
      this.redisPresenceV2.set(
        spaceId,
        PresenceType.SPACE_PRESENCE,
        ContextTypeEnum.SPACE,
        breakoutRoomId,
      );
    } else {
      this.redisPresenceV2.set(spaceId, PresenceType.SPACE_PRESENCE, ContextTypeEnum.SPACE);
    }
  }

  removeSpacePresence(spaceId: string, breakoutRoomId?: string, publish = true): void {
    if (this.flagsService.isFlagEnabled(FLAGS.BREAKOUT_ROOMS)) {
      this.redisPresenceV2.remove(
        spaceId,
        PresenceType.SPACE_PRESENCE,
        ContextTypeEnum.SPACE,
        breakoutRoomId,
        publish,
      );
    } else {
      this.redisPresenceV2.remove(spaceId, PresenceType.SPACE_PRESENCE, ContextTypeEnum.SPACE);
    }
  }

  setRecordingPresence(spaceId: string, breakoutRoomId: string): void {
    if (this.flagsService.isFlagEnabled(FLAGS.BREAKOUT_ROOMS)) {
      this.redisPresenceV2.set(
        spaceId,
        PresenceType.SPACE_PRESENCE,
        ContextTypeEnum.RECORDING,
        breakoutRoomId,
      );
    } else {
      this.redisPresenceV2.set(spaceId, PresenceType.SPACE_PRESENCE, ContextTypeEnum.RECORDING);
    }
  }

  removeRecordingPresence(spaceId: string, breakoutRoomId: string): void {
    if (this.flagsService.isFlagEnabled(FLAGS.BREAKOUT_ROOMS)) {
      this.redisPresenceV2.remove(
        spaceId,
        PresenceType.SPACE_PRESENCE,
        ContextTypeEnum.RECORDING,
        breakoutRoomId,
      );
    } else {
      this.redisPresenceV2.remove(spaceId, PresenceType.SPACE_PRESENCE, ContextTypeEnum.RECORDING);
    }
  }

  setCallPresence(spaceId: string, breakoutRoomId?: string): void {
    if (this.flagsService.isFlagEnabled(FLAGS.BREAKOUT_ROOMS)) {
      this.redisPresenceV2.set(
        spaceId,
        PresenceType.SPACE_PRESENCE,
        ContextTypeEnum.CALL,
        breakoutRoomId,
      );
    } else {
      this.redisPresenceV2.set(spaceId, PresenceType.SPACE_PRESENCE, ContextTypeEnum.CALL);
    }
  }

  removeCallPresence(spaceId: string, breakoutRoomId?: string): void {
    if (this.flagsService.isFlagEnabled(FLAGS.BREAKOUT_ROOMS)) {
      this.redisPresenceV2.remove(
        spaceId,
        PresenceType.SPACE_PRESENCE,
        ContextTypeEnum.CALL,
        breakoutRoomId,
      );
    } else {
      this.redisPresenceV2.remove(spaceId, PresenceType.SPACE_PRESENCE, ContextTypeEnum.CALL);
    }
  }

  // #endregion

  /**
   * Only execute joinSpacePresenceRooms if the presence version flag is true
   * if  presence_v2:
   * @param spacesIds
   * @param presenceInfos
   */
  joinSpacePresenceRooms(
    spacesIds: Array<string>,
    presenceInfos?: PresenceRoomInfo[],
  ): Promise<boolean> {
    if (this.flagsService.isFlagEnabled(FLAGS.BREAKOUT_ROOMS) && presenceInfos) {
      return this.redisPresenceV2.join(presenceInfos, PresenceType.SPACE_PRESENCE);
    } else {
      return this.redisPresenceV2.join_v0(spacesIds, PresenceType.SPACE_PRESENCE);
    }
  }

  /**
   * Only execute leaveSpacePresenceRooms if the presence version flag is true
   * if  presence_v2:
   * @param spacesIds
   * @param presenceInfos
   */
  leaveSpacePresenceRooms(spacesIds: Array<string>, presenceInfos?: PresenceRoomInfo[]): void {
    if (this.flagsService.isFlagEnabled(FLAGS.BREAKOUT_ROOMS) && presenceInfos) {
      this.redisPresenceV2.leave(presenceInfos, PresenceType.SPACE_PRESENCE);
    } else {
      this.redisPresenceV2.leave_v0(spacesIds, PresenceType.SPACE_PRESENCE);
    }
  }

  /**
   * Only execute querySpacePresenceRooms if the presence version flag is true
   * if  presence_v2:
   * @param spacesIds
   * @param presenceInfos
   */
  querySpacePresenceRooms(spacesIds: Array<string>, presenceInfos?: PresenceRoomInfo[]): void {
    if (this.flagsService.isFlagEnabled(FLAGS.BREAKOUT_ROOMS) && presenceInfos) {
      this.redisPresenceV2.query(presenceInfos, PresenceType.SPACE_PRESENCE);
    } else {
      this.redisPresenceV2.query_v0(spacesIds, PresenceType.SPACE_PRESENCE);
    }
  }

  joinAndQuerySpacePresenceRooms(
    spacesIds: Array<string>,
    presenceInfos?: PresenceRoomInfo[],
  ): void {
    if (this.flagsService.isFlagEnabled(FLAGS.BREAKOUT_ROOMS) && presenceInfos) {
      this.redisPresenceV2.join(presenceInfos, PresenceType.SPACE_PRESENCE).then((res) => {
        this.querySpacePresenceRooms(spacesIds, presenceInfos);
      });
    } else {
      this.redisPresenceV2.join_v0(spacesIds, PresenceType.SPACE_PRESENCE).then((res) => {
        this.querySpacePresenceRooms(spacesIds, presenceInfos);
      });
    }
  }

  getRoomPresenceActivity(spaceId: string, breakoutRoomId?: string): Observable<Set<string>> {
    return this.presenceRepo.getPresenceData(
      spaceId,
      PresenceType.SPACE_PRESENCE,
      ContextTypeEnum.SPACE,
      breakoutRoomId,
    );
  }

  getRoomPresenceData(spaceId: string, breakoutRoomId?: string): Set<string> {
    const data =
      this.presenceRepo.getPresenceDataValue(
        spaceId,
        PresenceType.SPACE_PRESENCE,
        ContextTypeEnum.SPACE,
        breakoutRoomId,
      ) || [];
    return new Set<string>(data.map((item) => item.userId));
  }

  getSpaceUsersActivityStatus(
    spaceId: string,
    breakoutRoomId?: string,
  ): { activeUsers: User[]; inactiveUsers: User[] } {
    const space = this.spaceRepo.getSpace(spaceId);
    const presence = this.getRoomPresenceData(spaceId, breakoutRoomId);

    const users = space?.populatedUsers || [];

    return {
      activeUsers: users.filter((u) => presence.has(u._id)),
      inactiveUsers: users.filter((u) => !presence.has(u._id)),
    };
  }

  getSpacePresenceActivity(spaceId: string): Observable<Set<string>> {
    return this.presenceRepo.getSpacePresenceData(spaceId);
  }

  getCallPresenceActivity(
    spaceId: string,
    breakoutRoomId?: string,
    getRtcPresence = true,
  ): Observable<Set<string>> {
    const redisCallPresence = this.presenceRepo.getPresenceData(
      spaceId,
      PresenceType.SPACE_PRESENCE,
      ContextTypeEnum.CALL,
      breakoutRoomId,
    );
    if (getRtcPresence) {
      return this.providerStateService.callConnected$.pipe(
        untilDestroyed(this),
        switchMap((callConnected) =>
          callConnected
            ? this.sessionCallParticipantsService.participantUserIds$
            : redisCallPresence,
        ),
      );
    } else {
      return redisCallPresence;
    }
  }

  getParticipantPresenceStatus(): Observable<ParticipantPresenceStatus> {
    return this.redisPresenceV2.participantPresenceStatus$;
  }

  disconnectPresence(spaceId: string, breakoutRoomId?: string): void {
    if (this.flagsService.isFlagEnabled(FLAGS.BREAKOUT_ROOMS)) {
      this.redisPresenceV2.disconnect(spaceId, PresenceType.SPACE_PRESENCE, breakoutRoomId);
    } else {
      this.redisPresenceV2.disconnect(spaceId, PresenceType.SPACE_PRESENCE);
    }
  }

  constructPresenceInfoObject(spaceId: string, breakoutRoomId?: string): PresenceRoomInfo {
    return {
      spaceId: spaceId,
      breakoutRoomId: breakoutRoomId ?? Session.getMainRoomId(spaceId, true),
    };
  }

  getCurrentBehaviorSubjectHash(spaceId: string, breakoutRoomId?: string): string {
    return this.flagsService.isFlagEnabled(FLAGS.BREAKOUT_ROOMS)
      ? `${PresenceContext.SessionContext}-${spaceId}-${
          breakoutRoomId ?? Session.getMainRoomId(spaceId, true)
        }`
      : `${PresenceContext.SessionContext}-${spaceId}`;
  }

  getCurrentSpacePresenceData(spaceId: string): Array<PresenceData> {
    return this.presenceRepo.getCurrentSpacePresenceData(spaceId);
  }

  gotWsPresenceUpdate(): Observable<void> {
    return this.presenceRepo.gotPresenceUpdate;
  }

  getCurrentlyActiveBreakoutRooms(spaceId: string) {
    const presenceDataArr = this.presenceRepo.getCurrentSpacePresenceData(spaceId);
    const uniqueRooms = new Set(presenceDataArr.map((presenceData) => presenceData.breakoutRoomId));

    return uniqueRooms;
  }

  areAllUsersInOneRoom(spaceId: string) {
    const uniqueRooms = this.getCurrentlyActiveBreakoutRooms(spaceId);

    return uniqueRooms.size === 1;
  }

  areBreakoutRoomsUsed(spaceId: string): boolean {
    const uniqueRooms = this.getCurrentlyActiveBreakoutRooms(spaceId);

    return !(
      uniqueRooms.size === 0 ||
      (uniqueRooms.size === 1 && [...uniqueRooms][0] === Session.getMainRoomId(spaceId))
    );
  }

  arePresentUsersAssignedDifferentRooms(spaceId: string): boolean {
    const presenceDataArr = this.presenceRepo.getCurrentSpacePresenceData(spaceId);
    const uniqueRooms = new Set(presenceDataArr.map((presenceData) => presenceData.breakoutRoomId));
    return uniqueRooms.size > 1;
  }

  areSpaceUsersAssignedRooms(space: Session): boolean {
    return space.users.some((u) => u.roomId !== Session.getMainRoomId(space._id));
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'copyArray',
})
export class CopyArrayPipe implements PipeTransform {
  transform<T>(value: T[]): T[] {
    return [...value];
  }
}

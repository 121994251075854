export enum EventType {
  percentageActivceSpeakerBlueBorder = '% of time where user was listed as active according to blue border logic',
  percentageActivceSpeaker = '% of time where user was listed as active according to daily active user',
  averageMicrophoneGain = 'Average microphone gain',
  AverageSpeakerGain = 'Average speaker gain',
  dailyActiveSpeaker = 'Daily active speaker change',
  webspeechSoundStart = 'Webspeech sound start',
  webspeechSoundEnd = 'Webspeech sound end',
  webspeechSpeechStart = 'Webspeech speech start',
  webspeechSpeechEnd = 'Webspeech speech end',
  webspeechAudioStart = 'Webspeech audio start',
  webspeechAudioEnd = 'Webspeech aduio end',
  webspeechStart = 'Webspeech start',
  webspeechEnd = 'Webspeech end',
  webspeechError = 'Webspeech error',
  webspeechNoMatch = 'Webspeech no match',
  webspeechFinalResult = 'Webspeech final result',
  webspeechResult = 'Webspeech result',
  successPublishTranscript = 'Publish Transcript',
  failurePublishTranscript = 'Failure Transcript',
  lrPredictionTrue = 'Logistic regression predicted true',
  lrPredictionFalse = 'Logistic regression predicted false',
}

export const logTranscription = (event: EventType, data: {}) => {
  console.log(
    new Date().getTime(),
    '[speech-api-event]: ',
    event,
    ' Payload: ',
    JSON.stringify(data),
  );
};

import { Injectable } from '@angular/core';
import { switchMap, EMPTY, BehaviorSubject, distinctUntilChanged, map, combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { filterNil } from '@ngneat/elf';
import { Recorder } from '../sessions/session/wb-video-controls/RecorderV2';
import { SpaceRepository } from '../state/space.repository';
import {
  PauseableRecordingService,
  RECORDING_STATE,
  RECORD_STATUS,
  RecordingOutput,
} from '../models/recording';
import { FlagsService } from './flags.service';
import { NotificationToasterService } from './notification-toaster.service';
import { SessionCallTracksService } from './session-call-tracks.service';
import { VideoCallTracksStateService } from './video-call-tracks-state.service';
import { DevicesManagerService } from './devices-manager.service';
import { ProviderStateService } from './provider-state.service';

@Injectable({
  providedIn: 'root',
})
export class SpaceLocalRecordingService implements PauseableRecordingService {
  private recorder$ = new BehaviorSubject<Recorder | undefined>(undefined);
  private _status$ = this.recorder$.pipe(
    switchMap((r) => r?.recording$ ?? EMPTY),
    distinctUntilChanged(),
  );

  public output$;
  private get recorder(): Recorder {
    const recorder = this.recorder$.getValue();
    if (recorder) {
      return recorder;
    }
    throw new Error('Expected a recorder to exist');
  }

  public state$ = combineLatest([
    this._status$,
    this.providerStateService.localParticipantId$.pipe(distinctUntilChanged()),
  ]).pipe(
    map(([status, initiatorParticipantId]): RECORDING_STATE => {
      switch (status) {
        case RECORD_STATUS.READY:
          return { _type: RECORD_STATUS.READY };
        case RECORD_STATUS.ACTIVE:
          return { _type: RECORD_STATUS.ACTIVE, initiatorParticipantId };
        case RECORD_STATUS.PAUSED:
          return { _type: RECORD_STATUS.PAUSED, initiatorParticipantId };
        case RECORD_STATUS.FINISHED:
          return { _type: RECORD_STATUS.FINISHED };
        default:
          return { _type: RECORD_STATUS.READY };
      }
    }),
  );

  constructor(
    private deviceManagerService: DevicesManagerService,
    private flagsService: FlagsService,
    private notificationToasterService: NotificationToasterService,
    private providerStateService: ProviderStateService,
    private sessionCallTracksService: SessionCallTracksService,
    private spaceRepo: SpaceRepository,
    private translateService: TranslateService,
    private videoCallTracksStateService: VideoCallTracksStateService,
  ) {
    this.output$ = this.recorder$.pipe(
      filterNil(),
      switchMap((recorder) =>
        recorder.output$.pipe(
          map((file) => {
            const output: RecordingOutput = { _type: 'file', file };
            return output;
          }),
        ),
      ),
    );
  }

  // ---- Public Methods ----
  public async startRecording(): Promise<void> {
    const recorder = new Recorder(
      this.sessionCallTracksService,
      this.spaceRepo.activeSpace?.title ?? '',
      this.flagsService,
      this.translateService,
      this.notificationToasterService,
      this.deviceManagerService,
      this.videoCallTracksStateService,
    );

    this.recorder$.next(recorder);
    recorder.startRecording();
  }

  public async stopRecording(): Promise<void> {
    await this.recorder.stopRecording();
  }

  public pauseRecording(): void {
    this.recorder.pauseRecording();
  }

  public resumeRecording(): void {
    this.recorder.resumeRecording();
  }
}

import { untilDestroyed } from '@ngneat/until-destroy';
import { Observable, take, timeout } from 'rxjs';
import { from } from 'rxjs/internal/observable/from';
import { DeviceState, DeviceErrorType } from '../../models/device-manger';

export enum DeviceType {
  VIDEO = 'video',
  AUDIO = 'audio',
}

export const DEFAULT_DEVICE_ID = 'default';
export const TIMEOUT_GET_USER_MEDIA_CALL = 15000;
export class DevicesHandleUtil {
  static onGettingStream(device: DeviceState) {
    device.isGettingStream = true;
    device.state = false;
    device.isToggable = false;
  }
  static onGettingStreamSuccessed(device: DeviceState, notChangeCurrentState = false) {
    if (!notChangeCurrentState) {
      device.state = true;
    }
    device.hasError = false;
  }
  static onGettingStreamFinished(device: DeviceState) {
    device.isGettingStream = false;
    device.isToggable = true;
  }
  static handleDevicesError(deviceState: DeviceState, error: any) {
    this.setDeviceStateError(deviceState);
    switch (error.name) {
      case 'NotAllowedError':
      case 'PermissionDeniedError':
        if (this.isDeniedBySystem(error.toString())) {
          deviceState.errorType = DeviceErrorType.PERMISSION_DENIED_BY_SYSTEM;
        } else {
          deviceState.errorType = DeviceErrorType.PERMISSION_DENIED;
        }
        break;
      case 'NotFoundError':
      case 'DevicesNotFoundError':
        deviceState.errorType = DeviceErrorType.NOT_FOUND;
        break;
      case 'NotReadableError':
      case 'TrackStartError':
        deviceState.errorType = DeviceErrorType.NO_INPUT_DETECTED;
        break;
      case 'TimeoutError':
        deviceState.errorType = DeviceErrorType.GET_USER_MEDIA_TIMEOUT;
        break;
      default:
        deviceState.errorType = DeviceErrorType.DEFAULT_ERROR;
    }
  }

  private static setDeviceStateError(deviceState: DeviceState) {
    deviceState.state = false;
    deviceState.isToggable = false;
    deviceState.hasError = true;
  }
  static gUMInputDevice(
    context: any,
    deviceType: DeviceType,
    deviceId: string | undefined,
    deviceState: DeviceState,
    handleShowVideoPreview = true,
    preferredConstraints?: MediaTrackConstraints,
  ): Promise<MediaStream> {
    const gUMConstraints: MediaStreamConstraints = this.constructGUMConstraints(
      deviceType,
      deviceId ?? DEFAULT_DEVICE_ID,
      preferredConstraints,
    );
    return new Promise((resolve, reject) => {
      DevicesHandleUtil.onGettingStream(deviceState);
      const gUMObservable = from(navigator.mediaDevices.getUserMedia(gUMConstraints)).pipe(
        timeout(TIMEOUT_GET_USER_MEDIA_CALL),
        take(1),
        untilDestroyed(context),
      );
      this.subscribeToGUMObservable(
        gUMObservable,
        resolve,
        reject,
        deviceType,
        deviceState,
        handleShowVideoPreview,
      );
    });
  }

  private static subscribeToGUMObservable(
    gUMObservable: Observable<MediaStream>,
    resolve: (value: MediaStream | PromiseLike<MediaStream>) => void,
    reject: { (reason?: any): void; (arg0: null): void },
    deviceType: DeviceType,
    deviceState: DeviceState,
    handleShowVideoPreview = true,
  ) {
    gUMObservable.subscribe({
      next: async (stream) => {
        if (handleShowVideoPreview && deviceType === DeviceType.VIDEO) {
          this.handlePreviewVideoStream(resolve, reject, stream, deviceState);
          return;
        }
        // If the device input is an audio device
        // Or just using this function to check devices status
        DevicesHandleUtil.onGettingStreamSuccessed(deviceState);
        DevicesHandleUtil.onGettingStreamFinished(deviceState);
        resolve(stream);
      },
      error: async (deviceError) => {
        // If timeout is detected, we will skip it if the permission status is still "prompt" which means the top left popup is still opened
        if (deviceError.name === 'TimeoutError' && this.browserSupportsPermissionsQuery()) {
          const permissionIsPrompt = await this.isDevicePermissionStatusPrompt(deviceType);
          if (permissionIsPrompt) {
            this.subscribeToGUMObservable(
              gUMObservable,
              resolve,
              reject,
              deviceType,
              deviceState,
              handleShowVideoPreview,
            );
            return;
          }
        }
        DevicesHandleUtil.handleDevicesError(deviceState, deviceError);
        DevicesHandleUtil.onGettingStreamFinished(deviceState);
        reject(null);
      },
    });
  }

  public static async isDevicePermissionStatusPrompt(deviceType: DeviceType) {
    try {
      if (!this.browserSupportsPermissionsQuery()) {
        return false;
      }
      const permissionStatus = await navigator.permissions.query({
        name: (deviceType === DeviceType.AUDIO ? 'microphone' : 'camera') as PermissionName,
      });
      return permissionStatus.state === 'prompt';
    } catch (e) {
      return false;
    }
  }

  private static handlePreviewVideoStream(
    resolve: (value: MediaStream | PromiseLike<MediaStream>) => void,
    reject: { (reason?: any): void; (arg0: null): void },
    videoStream: MediaStream,
    cam: DeviceState,
  ) {
    const videoElement = document.getElementById('deviceVideoPreview') as HTMLVideoElement;
    if (videoElement) {
      videoElement.srcObject = videoStream;
      videoElement
        .play()
        .then(() => {
          DevicesHandleUtil.onGettingStreamSuccessed(cam);
          DevicesHandleUtil.onGettingStreamFinished(cam);
          resolve(videoStream);
        })
        .catch(() => {
          // Handle play() is interrupted if the component is destroyed to avoid orphan streams
          videoStream?.getTracks().forEach((track) => track.stop());
          DevicesHandleUtil.onGettingStreamFinished(cam);
          reject(null);
        });
    }
  }

  private static constructGUMConstraints(
    deviceType: DeviceType,
    deviceId: string,
    preferredConstraints?: MediaTrackConstraints,
  ): any {
    const gUMConstraints: MediaTrackConstraints = {
      deviceId: deviceId,
    };

    if (!preferredConstraints) {
      return { [deviceType]: gUMConstraints };
    }

    return { [deviceType]: { ...gUMConstraints, ...preferredConstraints } };
  }

  private static browserSupportsPermissionsQuery() {
    return 'permissions' in navigator && 'query' in (navigator as any).permissions;
  }

  // Returns true if the permission is denied by user/system (Mac)
  static isPermissionDenied(error: DeviceErrorType | undefined): boolean {
    if (!error) {
      return false;
    }
    return [
      DeviceErrorType.PERMISSION_DENIED,
      DeviceErrorType.PERMISSION_DENIED_BY_SYSTEM,
    ].includes(error);
  }

  static isDeniedBySystem(error: string): boolean {
    return error.toLocaleLowerCase().includes('permission denied by system');
  }
}

import { Injectable, Injector, NgZone } from '@angular/core';
import Daily, {
  DailyAdvancedConfig,
  DailyCall,
  DailyCallOptions,
  DailyDisplayMediaStreamOptions,
  DailyEventObject,
  DailyEventObjectActiveSpeakerChange,
  DailyEventObjectAppMessage,
  DailyEventObjectFatalError,
  DailyEventObjectNetworkConnectionEvent,
  DailyEventObjectNetworkQualityEvent,
  DailyEventObjectNonFatalError,
  DailyNetworkStats,
  DailyParticipant,
  DailyReceiveSettingsUpdates,
} from '@daily-co/daily-js';
import { environment } from 'src/environments/environment';
import { Mutex } from 'async-mutex';
import * as Sentry from '@sentry/browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firstValueFrom, distinctUntilChanged, filter, Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import {
  DailyErrorMsgs,
  DailyMeetingState,
  DailyNetworkEvents,
  DailyParticipantEvents,
  DailyTrackEvents,
  DailyNonFatalErrors,
  DailyLocalParticipantEvents,
  DailyErrorsTypes,
  DailyMeetingEvents,
  DailyTranscriptionEvents,
  DailyParticipantMessageEvents,
  DailyAppMessageFromIdTypes,
  DailyRecordingEvents,
  DailyRecordingErrors,
} from '../common/utils/daily-constants';
import {
  RTCInterface,
  CallParticipant,
  ParticipantEventAction,
  CallContext,
  CallProvider,
  TrackEventAction,
  TrackType,
  SubscribeCallQuality,
  ActiveRTCDevices,
  NetworkEvent,
  NetworkThreshold,
  NetworkEventAction,
  CallError,
  RTCCloudRecording,
  ScreenShareOptions,
  RoomAuth,
} from '../common/interfaces/rtc-interface';
import { SpaceRepository } from '../state/space.repository';
import { EventType, logTranscription } from '../utilities/transcriptions.utils';
import { RECORDING_ERROR } from '../models/recording';
import { modifiedSetInterval } from '../utilities/ZoneUtils';
import {
  LocalNetworkStatsScore,
  NetworkStatsReportScore,
} from '../utilities/webrtc-stats-reporter/webrtc-stats-reporter-types';
import { UserService } from './user.service';
import { FLAGS, FlagsService } from './flags.service';
import { ProviderStateService } from './provider-state.service';
import { RealtimeSpaceService } from './realtime-space.service';
import { SpacesService } from './spaces.service';
import { TelemetryService } from './telemetry.service';
import { NoiseCancellationConfigurationService } from './noise-cancellation-configuration.service';
import { NotificationToasterService } from './notification-toaster.service';

const dailyConfig: DailyAdvancedConfig = {
  keepCamIndicatorLightOn: false,
};

// Poll network stats every minute.
const POLL_NETWORK_STATS_INTERVAL = 60 * 1000;

const THREE_HOURS = 60 * 60 * 3;

const LOW_RESOLUTION_SCALE_DOWN = 2;
const MEDIUM_RESOLUTION_SCALE_DOWN = 1;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DailyService extends RTCInterface implements RTCCloudRecording {
  readonly provider = CallProvider.DAILY;

  private callObject!: DailyCall;
  private pollNetworkTimer?: NodeJS.Timer;
  private roomAuth: RoomAuth | null = null;

  joinLeaveMutex = new Mutex();

  // Whether the user is joining this call from a mobile device.
  isMobileView = false;

  currentLocalParticipant?: DailyParticipant;

  isScreenShareSimulcastEnabled = false;

  private screenShareEncodingSettingFlag?: Subscription;

  constructor(
    private userService: UserService,
    private providerState: ProviderStateService,
    public deviceService: DeviceDetectorService,
    private injector: Injector,
    private ngZone: NgZone,
    private spaceRepo: SpaceRepository,
    private spacesService: SpacesService,
    public flagsService: FlagsService,
    noiseCancellationConfigurationService: NoiseCancellationConfigurationService,
    telemetry: TelemetryService,
    translateService: TranslateService,
    notificationToasterService: NotificationToasterService,
  ) {
    super(
      flagsService,
      noiseCancellationConfigurationService,
      telemetry,
      translateService,
      notificationToasterService,
    );
    this.setupDailyCallObject();
  }

  private setupDailyCallObject() {
    return this.ngZone.runOutsideAngular(() => {
      this.isMobileView = this.deviceService.isMobile();

      this.callObject = Daily.createCallObject({
        dailyConfig: dailyConfig,
        subscribeToTracksAutomatically: false,
        receiveSettings: {
          base: {
            video: {
              layer: SubscribeCallQuality.LOW,
            },
          },
        },
        sendSettings: {
          video: {
            maxQuality: this.isMobileView ? 'low' : 'medium',
            encodings: {
              low: {
                scaleResolutionDownBy: LOW_RESOLUTION_SCALE_DOWN,
                maxFramerate: 15,
              } as any,
              // If the user is not on mobile, then add the higher layer also to simulcast.
              ...(this.isMobileView
                ? {}
                : {
                    medium: {
                      scaleResolutionDownBy: MEDIUM_RESOLUTION_SCALE_DOWN,
                      maxFramerate: 20,
                    } as any,
                  }),
            },
          },
          ...(environment.useDailyScreenShareBalancedPreset
            ? { screenVideo: 'motion-and-detail-balanced' }
            : {}),
        },
      });

      this.setScreenshareSettings();
      this.setupLocalParticipantListeners();
      this.setupRemoteParticipantsListeners();
      this.setupParticipantsTrackListeners();
      this.setupActiveSpeakerListener();
      this.setupNetworkQualityListeners();
      this.setupErrorListener();
      this.setupTranscriptionListeners();

      this.callObject.on(
        DailyRecordingEvents.RECORDING_STARTED,
        (ev) =>
          (this.providerState.recordingEvent = {
            _type: 'STARTED',
            initiatorParticipantId: ev?.startedBy,
          }),
      );

      this.callObject.on(DailyRecordingEvents.RECORDING_ERROR, (err) => {
        if (err?.errorMsg.includes(DailyRecordingErrors.IDLE_TIMEOUT)) {
          this.providerState.recordingEvent = {
            _type: 'STOPPED',
            err: { type: RECORDING_ERROR.IDLE_TIMEOUT, msg: err.errorMsg },
          };
        }
      });

      this.callObject.on(
        DailyRecordingEvents.RECORDING_STOPPED,
        () => (this.providerState.recordingEvent = { _type: 'STOPPED' }),
      );
    });
  }

  setLocalVideoCallStatsMetadata(localNetworkStatsScore: LocalNetworkStatsScore): void {
    this.callObject.setUserData(localNetworkStatsScore);
  }

  async publishAndStartBroadcastingVideoStream(mediaStreamTrack: MediaStreamTrack): Promise<void> {
    await this.callObject.setInputDevicesAsync({
      videoSource: mediaStreamTrack,
    });
    mediaStreamTrack.enabled = false;
    this.callObject.setLocalVideo(true);
  }

  async enableNoiseCancellation(): Promise<void> {
    if (!this.isNoiseCancellationSupported() || !this.isConnected()) {
      return;
    }

    try {
      await this.callObject.updateInputSettings({
        audio: {
          processor: { type: 'noise-cancellation' },
        },
      });
    } catch (error) {
      Sentry.captureException(new Error('Could not enable noise cancellation'), {
        extra: error,
      });
    }
  }

  async disableNoiseCancellation(): Promise<void> {
    if (!this.isNoiseCancellationSupported() || !this.isConnected()) {
      return;
    }

    await this.callObject.updateInputSettings({
      audio: {
        processor: { type: 'none' },
      },
    });
  }

  attachAudioElement(
    participant: CallParticipant,
    trackType: TrackType,
    element: HTMLAudioElement,
  ): void {
    // daily package change the device id of all audio elements inside the window.document so no need to provide it to them
  }

  async startBroadcastingVideoStream(mediaStreamTrack: MediaStreamTrack): Promise<void> {
    // We found that sometimes the local track start event will not fire when the track is enabled,
    // so we will re-publish the track for safety.
    this.publishAndStartBroadcastingVideoStream(mediaStreamTrack);
  }

  async startBroadcastingAudioStream(mediaStreamTrack: MediaStreamTrack): Promise<void> {
    await this.callObject.setInputDevicesAsync({
      audioSource: mediaStreamTrack,
    });
    this.callObject.setLocalAudio(true);
  }

  async stopBroadcastingVideoStream(): Promise<void> {
    this.callObject.setLocalVideo(false);
  }

  async stopBroadcastingAudioStream(): Promise<void> {
    this.callObject.setLocalAudio(false);
  }

  doesProviderSupportNoiseCancellation(): boolean {
    return Daily.supportedBrowser().supportsAudioProcessing;
  }

  private setScreenshareSettings(): void {
    this.screenShareEncodingSettingFlag?.unsubscribe();
    this.screenShareEncodingSettingFlag = this.flagsService
      .featureFlagChanged(FLAGS.SCREEN_SHARE_SETTINGS)
      .pipe(
        untilDestroyed(this),
        filter((customSettingsEnabled) => customSettingsEnabled),
        distinctUntilChanged(),
      )
      .subscribe(async (_) => {
        await this.updateScreenSharingSendSettings();
      });
  }

  private async updateScreenSharingSendSettings() {
    await this.callObject.updateSendSettings({
      screenVideo: {
        encodings: {
          low: {
            scaleResolutionDownBy: 1,
            maxBitrate: this.screenShareEncodingMaxBitrate,
            maxFramerate: this.screenShareEncodingMaxFPS,
          } as RTCRtpEncodingParameters,
        },
      },
    });
  }

  // Start listening on joined & left events for the local participant
  private setupLocalParticipantListeners() {
    // liston to local participant joined event
    this.callObject.on(DailyLocalParticipantEvents.JOINED, async (event) => {
      if (event) {
        // Set the local values after receving local participant joined the meeting
        this.currentLocalParticipant = event.participants.local;
        this.broadcastLocalParticipantId(event.participants.local);

        this.providerState.callConnecting = false;
        this.providerState.callConnected = true;

        this.refreshNetworkStatsPolling();

        const participantEvent = {
          action: ParticipantEventAction['JOINED'],
          participant: this.toCallParticipant(event.participants.local),
        };
        this.providerState.participantEvents = participantEvent;
        this.logEvent(event);

        if (this.isNoiseCancellationEnabled) {
          await this.enableNoiseCancellation();
        } else {
          await this.disableNoiseCancellation();
        }
      }
    });

    this.callObject.on(DailyLocalParticipantEvents.LEFT, (event) => {
      if (event) {
        this.providerState.callDisconnecting = false;
        // Reset the local values after leaving the call
        this.providerState.participantEvents = null;
        this.providerState.trackEvents = null;
        this.providerState.callConnected = false;
        this.providerState.localParticipantId = '';
        this.dismissVideoCallNotifications();
        this.refreshNetworkStatsPolling();

        const participantEvent = {
          action: ParticipantEventAction['LEFT'],
          participant: this.toCallParticipant(
            this.currentLocalParticipant ?? this.callObject.participants().local,
          ),
        };
        this.providerState.participantEvents = participantEvent;
        this.callObject.destroy();
        this.currentLocalParticipant = undefined;
        this.logEvent(event);
      }
    });
  }

  // Start listening on joined & left events for remote participants
  private setupRemoteParticipantsListeners() {
    // listen to participants' events and make it observable in participantEvents
    Object.values(DailyParticipantEvents).forEach((state) => {
      this.callObject.on(state, (event) => {
        if (event) {
          const actionKey = Object.keys(DailyParticipantEvents).find(
            (key) =>
              DailyParticipantEvents[key as keyof typeof DailyParticipantEvents] === event.action,
          );
          if (actionKey) {
            const participantEvent = {
              action: ParticipantEventAction[actionKey as keyof typeof DailyParticipantEvents],
              participant: this.toCallParticipant(event.participant),
            };
            this.providerState.participantEvents = participantEvent;

            // Added for consistency with other WebRTC providers implementation (LiveKit)
            if (event.action === DailyParticipantEvents.UPDATED) {
              // Emit a metadata change for a certain participant
              this.providerState.participantMetadataChange = this.toCallParticipant(
                event.participant,
              );
            }

            this.logEvent(event);
          } else {
            Sentry.captureException(new Error('No daily action found'), {
              extra: { event: event },
            });
          }
        }
      });
    });
  }

  // Start listening on started & stopped events for participants' tracks
  private setupParticipantsTrackListeners() {
    // listen to track events and make it observable in trackEvents
    Object.values(DailyTrackEvents).forEach((state) => {
      this.callObject.on(state, async (event) => {
        if (event) {
          const actionKey = Object.keys(DailyTrackEvents).find(
            (key) => DailyTrackEvents[key as keyof typeof DailyTrackEvents] === event.action,
          );
          if (actionKey && event.participant) {
            const trackEvent = {
              action: TrackEventAction[actionKey as keyof typeof DailyTrackEvents],
              participant: this.toCallParticipant(event.participant),
              type: event.type as TrackType,
            };

            this.providerState.trackEvents = trackEvent;
            this.logEvent(event);
          }
        }
      });
    });
  }

  // Start listening to transcription events (Started, stopped, error & content[App message])
  private setupTranscriptionListeners() {
    this.callObject.on(DailyTranscriptionEvents.TRANSCRIPTION_STARTED, (event) => {
      this.logTranscriptEvent(DailyTranscriptionEvents.TRANSCRIPTION_STARTED, event);
    });

    this.callObject.on(DailyTranscriptionEvents.TRANSCRIPTION_STOPPED, (event) => {
      this.logTranscriptEvent(DailyTranscriptionEvents.TRANSCRIPTION_STOPPED, event);
    });

    this.callObject.on(DailyTranscriptionEvents.TRANSCRIPTION_ERROR, (event) => {
      this.logTranscriptEvent(DailyTranscriptionEvents.TRANSCRIPTION_ERROR, event.errorMsg);
      Sentry.captureException(new Error('Daily Deepgrm transcription error.'), {
        extra: {
          context: JSON.stringify(event?.errorMsg),
        },
      });
    });

    this.callObject.on(
      DailyParticipantMessageEvents.APP_MESSAGE,
      (event: DailyEventObjectAppMessage | undefined) => {
        if (event) {
          if (event.fromId === DailyAppMessageFromIdTypes.TRANSCRIPTION) {
            this.providerState.transcriptMessage = event.data;
          }
        }
      },
    );
  }

  private setupErrorListener() {
    this.callObject.on(DailyMeetingState.ERROR, (error: DailyEventObjectFatalError | undefined) => {
      this.telemetry.event('unrecoverable call error', {
        data: JSON.stringify(error),
      });
      this.providerState.callReconnecting = false;

      // in that case the meeting ends because the auth expired
      // so we load a new auth while showing the error notification
      // then when the user start a call again the auth will be available
      if (
        error?.errorMsg === DailyErrorMsgs.MEETING_DOES_NOT_EXIST ||
        error?.error?.type === DailyErrorsTypes.ERROR_EXP_ROOM_TYPE
      ) {
        this.loadAuth(
          this.spaceRepo.activeSpace?._id ?? '',
          this.spaceRepo.activeSpaceCurrentRoom?.uid,
          CallContext.SESSION,
          true,
        );
      }

      if (
        error?.errorMsg.toLowerCase().includes(DailyErrorsTypes.TIMED_OUT) ||
        error?.errorMsg.toLowerCase().includes(DailyErrorsTypes.FAILED_LOADING_BUNDLE)
      ) {
        this.setProviderErrorState(CallError.TIME_OUT);
      } else {
        this.setProviderErrorState(CallError.SERVICEDOWN);
      }
    });

    this.callObject.on(
      DailyMeetingState.NON_FATAL_ERROR,
      (error: DailyEventObjectNonFatalError) => {
        if (error.type === DailyNonFatalErrors.AUDIO_PROCESSOR_ERROR) {
          this.isNoiseCancellationEnabled = false;
          this.telemetry.event(DailyNonFatalErrors.AUDIO_PROCESSOR_ERROR, {
            data: JSON.stringify(error),
          });
        }
      },
    );
  }

  private setupNetworkQualityListeners() {
    // listen to network events and make it observable in networkEvents
    Object.values(DailyNetworkEvents).forEach((state) => {
      this.callObject.on(state, (event) => {
        if (event) {
          const actionKey = Object.keys(DailyNetworkEvents).find(
            (key) => DailyNetworkEvents[key as keyof typeof DailyNetworkEvents] === event.action,
          );
          if (actionKey) {
            const action = DailyNetworkEvents[actionKey as keyof typeof DailyNetworkEvents];
            if (action === DailyNetworkEvents.QUALITY_CHANGED) {
              this.handleNetworkQualityEvent(event as DailyEventObjectNetworkQualityEvent);
            } else {
              if (!environment.production) {
                console.log('[Daily network connection event]: ', event);
              }
              // Log all network-connection events on prod
              this.telemetry.event('Daily network connection event', {
                data: JSON.stringify(event),
              });
              this.handleNetworkConnectionEvent(event as DailyEventObjectNetworkConnectionEvent);
            }
          }
        }
      });
    });
  }

  private setupActiveSpeakerListener() {
    this.callObject.on(
      DailyMeetingEvents.ACTIVE_SPEAKER_CHANGE,
      (event: DailyEventObjectActiveSpeakerChange | undefined) => {
        if (event) {
          const eventTimeStamp = new Date().getTime();
          logTranscription(EventType.dailyActiveSpeaker, {
            timestamp: eventTimeStamp,
            event: event,
          });

          this.providerState.currentActiveSpeaker = {
            speakerId: event?.activeSpeaker.peerId,
            timestmap: eventTimeStamp,
          };
        } else {
          this.providerState.currentActiveSpeaker = undefined;
        }
      },
    );
  }

  startTranscription(): void {
    this.callObject.startTranscription({
      tier: 'nova',
    });
  }

  stopTranscription() {
    this.callObject.stopTranscription();
  }

  handleNetworkConnectionEvent(networkEvent: DailyEventObjectNetworkConnectionEvent) {
    if (networkEvent.type == 'signaling') {
      if (networkEvent.event == 'interrupted') {
        this.logEvent('Signalling socket interrupted');
        this.providerState.callReconnecting = true;
      } else if (networkEvent.event == 'connected') {
        this.providerState.callReconnecting = false;
        this.logEvent('Signalling socket connected');
      }
    }
  }

  private setProviderErrorState(callError: CallError) {
    this.injector
      .get(RealtimeSpaceService)
      .service.isOfflineMode()
      .then((offline) => {
        if (offline) {
          this.providerState.callErrors = CallError.DISCONNECTED;
        } else {
          this.providerState.callErrors = callError;
        }
      });
  }

  private broadcastLocalParticipantId(participant: DailyParticipant) {
    if (participant.local) {
      this.providerState.localParticipantId = participant.session_id;
    }
  }

  private logEvent(event: DailyEventObject) {
    if (this.flagsService.isFlagEnabled(FLAGS.SPACE_VIDEO_LOGGING)) {
      console.log(event);
    }
  }

  /**
   * Either start or stop network stats polling based
   * on connection status.
   */
  refreshNetworkStatsPolling() {
    if (this.pollNetworkTimer) {
      clearInterval(this.pollNetworkTimer);
    }

    if (this.isConnected()) {
      this.pollNetworkTimer = modifiedSetInterval(async () => {
        const networkStats = await this.callObject.getNetworkStats();
        if (networkStats) {
          this.handleNetworkQualityEvent(networkStats);
        }
      }, POLL_NETWORK_STATS_INTERVAL);
    }
  }

  /**
   * Parses network stats and fires a networkEvent based on
   * the network quality
   */
  handleNetworkQualityEvent(event: DailyEventObjectNetworkQualityEvent | DailyNetworkStats) {
    let threshold: NetworkThreshold | undefined;
    if (event.threshold === 'good') {
      threshold = NetworkThreshold.GOOD;
    } else if (event.threshold === 'low') {
      threshold = NetworkThreshold.LOW;
    } else if (event.threshold === 'very-low') {
      threshold = NetworkThreshold.VERY_LOW;
    }

    const networkEvent: NetworkEvent = {
      action: NetworkEventAction.QUALITY_CHANGED,
      threshold: threshold,
      quality: event.quality,
    };

    this.providerState.networkEvents = networkEvent;
  }

  getAuthData(): RoomAuth | null {
    return this.roomAuth;
  }

  setAuthData(data: RoomAuth | null): void {
    this.roomAuth = data;
    this.providerState.callReady = this.roomAuth ? true : false;
  }

  doesAuthTokenExist(): boolean {
    return !!this.roomAuth && !!this.roomAuth.token;
  }

  async join(forceSettingInputDeviceOnJoining = false): Promise<void> {
    await this.joinLeaveMutex.runExclusive(async () => {
      if (this.callObject.isDestroyed()) {
        this.setupDailyCallObject();
      }
      if (forceSettingInputDeviceOnJoining) {
        await this.forceSettingSpeakerDevice();
      }
      try {
        if (this.isConnected() || !this.roomAuth) {
          return;
        }
        this.providerState.callConnecting = true;
        const joinCallConfig: DailyCallOptions = {
          url: this.getRoomUrl(),
          token: this.roomAuth?.token ? this.roomAuth.token : undefined,
        };
        // Setting dailyConfig in all case will trigger a bug on Daily side
        if (this.isMusicModeEnabled) {
          joinCallConfig.dailyConfig = {
            micAudioMode: 'music',
          };
        }
        await this.callObject.join(joinCallConfig);
      } catch (err) {
        this.providerState.callConnecting = false;
      }
    });
  }

  private async forceSettingSpeakerDevice() {
    const speakerId = this.getSelectedSpeakerDeviceId();
    if (!speakerId) {
      return;
    }
    const setDevicesObj: any = {
      speakerId: speakerId,
    };

    await this.setDevices(setDevicesObj);
  }

  async leave(): Promise<void> {
    super.leave();
    await this.joinLeaveMutex.runExclusive(async () => {
      this.providerState.callDisconnecting = true;
      try {
        if (!this.isConnected()) {
          return;
        }
        this.providerState.callDisconnecting = false;
        await this.callObject.leave();
      } catch (err) {
        this.providerState.callDisconnecting = false;
        throw new Error(err);
      }
    });
  }

  async loadAuth(
    spaceId: string,
    breakoutRoomId: string | undefined,
    context: CallContext,
    isExtendExp = false,
  ): Promise<void> {
    // if we already have auth data for this room, do nothing in case of not extending the expiry time
    // in case of extending expiry time, function doesn't return and make a call to BE to extend the expiry time
    if (!isExtendExp && this.isAuthValid(this.roomAuth, spaceId, breakoutRoomId)) {
      return;
    }

    // Generate a unique request ID for the current request
    const requestId = ++this.loadAuthCurrentRequestId;

    // reset roomAuth to make sure that it will not be used under any race condition
    // before the new one is created
    this.roomAuth = null;

    const userId: string | undefined = this.userService.user.getValue()?.user._id;
    if (userId) {
      const res = await firstValueFrom(
        this.spacesService.getVideoCallAuth(spaceId, breakoutRoomId, context, CallProvider.DAILY),
      );

      if (requestId === this.loadAuthCurrentRequestId) {
        // set the room auth only if the request not being cancelled by another request
        this.roomAuth = (res?.body as any)?.data || null;
      }
    }
    if (!this.roomAuth || !this.roomAuth.token) {
      throw new Error('Could not get room data for Daily RTC');
    } else {
      this.providerState.callReady = true;
    }
  }

  protected constructRoomIdInRoomAuth(spaceId: string, breakoutRoomId?: string): string {
    if (breakoutRoomId) {
      return breakoutRoomId.replace(':', '-');
    } else {
      return `${spaceId}-MainRoom`;
    }
  }

  clearRoomAuthToken(): void {
    this.roomAuth = null;
  }

  // TODO: support screen audio as well
  async startScreenShare(
    options?: ScreenShareOptions,
    onEnd?: () => Promise<void>,
    onError?: (error: any) => Promise<void>,
  ): Promise<void> {
    this.callObject.once(DailyMeetingState.NON_FATAL_ERROR, async (error) => {
      if (onError && error?.type === DailyNonFatalErrors.SCREEN_SHARE_ERROR) {
        await onError(error);
      }
    });
    const preferCurrentTab = options?.preferCurrentTab ?? false;
    const displayMediaOptions: DailyDisplayMediaStreamOptions = {
      video: {
        width: {
          ideal: this.screenShareCaptureWidth,
        },
        height: {
          ideal: this.screenShareCaptureHeight,
        },
      },
      // @ts-expect-error Non-standard and not supported in all browsers
      preferCurrentTab: preferCurrentTab,
      selfBrowserSurface: 'include',
    };

    await this.callObject.startScreenShare({ displayMediaOptions });

    if (options?.restrictToWhiteboard) {
      console.warn('restrictToWhiteboard is not supported in Daily');
    }

    this.callObject.once(DailyMeetingEvents.LOCAL_SCREEN_SHARE_STOPPED, async () => {
      if (onEnd) {
        await onEnd();
      }
      await this.stopScreenShare();
    });
  }

  async stopScreenShare(): Promise<void> {
    this.callObject.stopScreenShare();
  }

  isConnected(): boolean {
    return this.providerState.callConnectedValue;
  }

  async setStreamQuality(participantId: string, type: TrackType, quality: SubscribeCallQuality) {
    const participant = Object.values(this.callObject.participants()).find(
      (p) => participantId === p.session_id,
    );
    if (!participant) {
      return;
    }
    const receiveSettings: DailyReceiveSettingsUpdates = {};
    if (type === TrackType.VIDEO) {
      receiveSettings[participant.session_id] = {
        video: {
          layer: quality,
        },
      };
    } else if (type === TrackType.SCREEN) {
      receiveSettings[participant.session_id] = {
        screenVideo: {
          layer: quality,
        },
      };
    }
    await this.callObject.updateReceiveSettings(receiveSettings);
  }

  getRoomUrl(): string {
    return `${environment.dailyDomain}/${this.roomAuth?.roomId}`;
  }

  toCallParticipant(participant: DailyParticipant): CallParticipant {
    return {
      participantId: participant.session_id,
      userId: participant.user_id,
      name: participant.user_name,
      local: participant.local,
      video: participant.tracks.video.state,
      audio: participant.tracks.audio.state,
      screen: participant.tracks.screenVideo.state,
      screenAudio: participant.tracks.screenAudio.state,
      videoTrack: participant.tracks.video.persistentTrack,
      audioTrack: participant.tracks.audio.persistentTrack,
      screenVideoTrack: participant.tracks.screenVideo.persistentTrack,
      screenAudioTrack: participant.tracks.screenAudio.persistentTrack,
      joinedAt: participant.joined_at,
      networkStatsScore: this.parseMetadata(participant?.userData)?.networkStatsScore,
    };
  }

  private parseMetadata(metadata: unknown): DailyParticipantMetadata {
    return (metadata as DailyParticipantMetadata) ?? {};
  }

  getParticipants(): { [key: string]: CallParticipant } {
    const dailyParticipants = this.callObject.participants();
    const callParticipants: { [key: string]: CallParticipant } = {};
    Object.entries(dailyParticipants).forEach(([key, value]) => {
      callParticipants[key] = this.toCallParticipant(value);
    });

    return callParticipants;
  }

  getNumberOfParticipants(): number {
    return Object.values(this.callObject.participants()).length;
  }

  async setDevices(devices: ActiveRTCDevices): Promise<void> {
    if (devices.speakerId) {
      // setOutputDeviceAsync does not always work. ex. Safari requires users to set it on an OS level
      await this.callObject.setOutputDeviceAsync({
        outputDeviceId: devices.speakerId,
      });
    }
  }

  async getCurrentInputDevices(): Promise<ActiveRTCDevices> {
    const activeRTCDevices: ActiveRTCDevices = {
      cameraId: '',
      micId: '',
      speakerId: '',
    };

    const inputDevices = await this.callObject.getInputDevices();
    activeRTCDevices.cameraId = (inputDevices.camera as any)?.deviceId || '';
    activeRTCDevices.micId = (inputDevices.mic as any)?.deviceId || '';
    activeRTCDevices.speakerId = (inputDevices.speaker as any)?.deviceId || '';

    return activeRTCDevices;
  }

  isCameraEnabled(): boolean {
    return this.callObject.localVideo();
  }

  isMicEnabled(): boolean {
    return this.callObject.localAudio();
  }

  public subscribe(participantId: string, trackType: TrackType): void {
    this._subscribe(participantId, trackType, true);
  }

  public unsubscribe(participantId: string, trackType: TrackType): void {
    this._subscribe(participantId, trackType, false);
  }
  public stage(participantId: string, trackType: TrackType): void {
    this.callObject.updateParticipant(participantId, {
      setSubscribedTracks: { [trackType]: 'staged' },
    });
  }

  public isSubscribed(participantId: string, trackType: TrackType): boolean | string {
    switch (trackType) {
      case TrackType.AUDIO:
        return this.callObject.participants()[participantId]?.tracks?.audio.subscribed;
      case TrackType.VIDEO:
        return this.callObject.participants()[participantId]?.tracks?.video.subscribed;
      case TrackType.SCREEN:
        return this.callObject.participants()[participantId]?.tracks?.screenVideo.subscribed;
      case TrackType.SCREEN_AUDIO:
        return this.callObject.participants()[participantId]?.tracks?.screenAudio.subscribed;
      default:
        return false;
    }
  }

  private _subscribe(id: string, trackType: TrackType, enabled: boolean): void {
    this.callObject.updateParticipant(id, {
      setSubscribedTracks: { [trackType]: enabled },
    });
  }

  isConnecting(): boolean {
    return this.providerState.callConnecting;
  }

  isDisconnecting(): boolean {
    return this.providerState.callDisconnecting;
  }

  private logTranscriptEvent(event: DailyTranscriptionEvents, data: any): void {
    if (!environment.production) {
      console.log('[daily transcription]: ', event, JSON.stringify(data));
    }
  }

  // ---- CLOUD RECORDING ---- //
  async startRecording(): Promise<void> {
    this.callObject.startRecording({
      minIdleTimeOut: THREE_HOURS, // 3 hour idle timeout
    });
  }

  async stopRecording(): Promise<void> {
    this.callObject.stopRecording();
  }

  public lowerOutgoingVideoQuality(): void {
    this.callObject.updateSendSettings({
      video: {
        maxQuality: 'low',
      },
    });
  }

  public resetOutgoingVideoQuality(): void {
    this.callObject.updateSendSettings({
      video: {
        maxQuality: 'medium',
      },
    });
  }
}

type DailyParticipantMetadata = Partial<{
  networkStatsScore: NetworkStatsReportScore;
}>;

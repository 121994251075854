import { Pipe, PipeTransform } from '@angular/core';
import { MessageWithUser } from '../models/messaging';

@Pipe({
  name: 'messageAuthor',
})
export class MessageAuthorPipe implements PipeTransform {
  transform({ currentUser, message }: MessageWithUser): string {
    if (currentUser._id === message.author._id) {
      return 'You';
    } else {
      return message.author.name.split(' ')[0] || '';
    }
  }
}

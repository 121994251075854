<h2 mat-dialog-title class="text-center">{{'Impersonate' | translate}}</h2>
<div mat-dialog-body>
  <mat-form-field class="w-100">
    <input matInput type="email" [placeholder]="'Email' | translate" [(ngModel)]="email" />
  </mat-form-field>
</div>
<div mat-dialog-actions class="d-flex justify-content-between">
  <button class="btn btn-light flex-1" [mat-dialog-close]="false">{{'Cancel' | translate}}</button>
  <button
    class="btn btn-primary flex-1 cml-16"
    style="background-color: #0f42c8; color: white"
    (click)="impersonate()"
    cdkFocusInitial
  >
    {{'Submit' | translate}}
  </button>
</div>
<button mat-icon-button class="btn-cancel" [mat-dialog-close]="false">
  <i class="material-icons">close</i>
</button>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {
  transform(seconds): any {
    if (Math.floor(seconds / 3600) > 0) {
      const hour = (`0${ Math.floor(seconds / 3600) }`).slice(-2);
      const min = (`0${ Math.floor((seconds % 3600) / 60) }`).slice(-2);
      const second = (`0${ seconds % 60 }`).slice(-2);
      return `${hour}:${min}:${second}`;
    } else {
      const min = (`0${ Math.floor(seconds / 60) }`).slice(-2);
      const second = (`0${ Math.floor(seconds % 60) }`).slice(-2);
      return `${min}:${second}`;
    }
  }
}

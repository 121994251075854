import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  OnDestroy,
  SimpleChanges,
  ElementRef,
  SimpleChange,
} from '@angular/core';
import { MessageList } from 'src/app/models/user';
import { Context, encodeContext } from 'src/app/models/context';
import { UserService } from 'src/app/services/user.service';
import { RealtimeService } from 'src/app/services/realtime.service';
import { Observable } from 'rxjs';
import { MessageScrollPosition, RealtimeToken } from 'src/app/models/messaging';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-comment-wrapper',
  templateUrl: './comment-wrapper.component.html',
  styleUrls: ['./comment-wrapper.component.scss'],
})
export class CommentWrapperComponent implements OnDestroy, OnChanges {
  @Input() context?: Context;
  @Input() type = 'comment';
  @Input() showLoading = true;
  @Input() isPopup?: boolean = false;
  @Input() realtimeToken!: RealtimeToken;
  @Input() scrollPosition?: MessageScrollPosition.Bottom;
  @Output()
  addComment = new EventEmitter();

  @ViewChild('commentWrapper') commentWrapper!: ElementRef;

  messages?: MessageList | any; // TODO: Not this...
  channel: any;
  loading = false;
  messageSubscribed = false;

  constructor(private userService: UserService, private realtimeService: RealtimeService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.context) {
      this.handleContextChange(changes.context);
    }

    if (changes.scrollPosition) {
      this.handleScrollPositionChange(changes.scrollPosition);
    }
  }

  handleContextChange(contextChange: SimpleChange): void {
    if (this.type === 'message' && this.context?.session) {
      this.messageSubscribed = false;
      if (contextChange.previousValue) {
        this.realtimeService.unsubscribeSession(
          encodeContext(contextChange.previousValue as Context),
        );
      }
    } else if (this.type === 'message' && this.context?.users) {
      this.messageSubscribed = false;
      if (contextChange.previousValue) {
        this.realtimeService.unsubscribeComments(
          encodeContext(contextChange.previousValue as Context),
        );
      }
    } else {
      if (contextChange.previousValue) {
        this.realtimeService.unsubscribeComments(this.getChannel(contextChange.previousValue));
      }
      if (this.realtimeToken) {
        if (contextChange.currentValue) {
          const commentObservable = this.realtimeService.subscribeComments(
            this.getChannel(contextChange.currentValue),
            this.realtimeToken,
          );
          if (commentObservable) {
            this.subscribeToComments(commentObservable);
          }
        }
      }
    }
    this.fetchLatestComments();
  }

  handleScrollPositionChange(scrollPositionChange: SimpleChange): void {
    if (scrollPositionChange.currentValue === MessageScrollPosition.Bottom) {
      this.scrollToBottom();
    }
  }

  subscribeToComments(commentObservable: Observable<any>): void {
    commentObservable.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res) {
        if (!this.messages?.messages) {
          this.messages = {
            messages: [],
          };
        }
        if (res.messageAction === 'message add') {
          this.messages.messages.push(res);
          this.handleMessagesSorted();
        } else if (res.messageAction === 'message delete') {
          this.messages.messages = this.messages.messages.filter((value) => value._id !== res._id);
        } else if (res.messageAction === 'message edit') {
          this.messages.messages = this.messages.messages.map((comment) =>
            res._id === comment._id ? res : comment,
          );
        }
      }
    });
  }

  getChannel(context?: Context): string {
    let channel = '';
    if (context?.note) {
      channel = `note/${encodeContext(context)}`;
    } else if (context?.worksheet) {
      channel = `worksheet/${encodeContext(context)}`;
    } else if (context?.session) {
      channel = `sessions/${encodeContext(context)}`;
    } else if (context) {
      channel = `${encodeContext(context)}`;
    }
    return channel;
  }

  handleMessagesSorted(): void {
    if (this.type === 'message') {
      requestAnimationFrame(() => {
        this.scrollToBottom();
      });
    }
  }

  scrollToBottom(): void {
    if (this.commentWrapper) {
      this.commentWrapper.nativeElement.scrollTop = this.commentWrapper.nativeElement.scrollHeight;
    }
  }

  ngOnDestroy(): void {
    // Also unsubscribe here.
    if (this.type === 'message' && this.context?.session) {
      this.realtimeService.unsubscribeSession(encodeContext(this.context));
    } else if (this.type === 'message' && this.context?.users) {
      this.realtimeService.unsubscribeComments(encodeContext(this.context));
    } else {
      this.realtimeService.unsubscribeComments(this.getChannel(this.context));
    }
  }

  async fetchLatestComments(
    added = false,
    page = 1,
    num = this.type === 'comment' ? 3 : 15,
  ): Promise<void> {
    if (!this.context) {
      return;
    }

    this.loading = true;
    const data: any = await this.userService.getComments(this.context, page, num).toPromise();
    if (this.type === 'message' && !this.messageSubscribed && this.context.session) {
      this.messageSubscribed = true;
      const commentObservable = this.realtimeService.subscribeSession(
        encodeContext(this.context),
        data?.realtime?.realtime_user_message,
      );
      this.subscribeToComments(commentObservable);
    } else if (this.type === 'message' && !this.messageSubscribed && this.context.users) {
      this.messageSubscribed = true;
      const commentObservable = this.realtimeService.subscribeComments(
        encodeContext(this.context),
        data?.realtime?.realtime_user_message,
      );
      if (commentObservable) {
        this.subscribeToComments(commentObservable);
      }
    }
    this.loading = false;
    if (page === 1) {
      this.messages = data;
    } else {
      data.messages.forEach((m) => {
        if (this.messages && this.messages.messages) {
          this.messages.messages.push(m);
        }
      });
    }

    if (added) {
      this.addComment.emit();
    }
  }
}

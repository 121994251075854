import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { union } from 'lodash-es';
import { CreateTagsComponent } from 'src/app/dialogs/create-tags/create-tags.component';
import { Course } from 'src/app/models/user';
import { TagsService } from 'src/app/services/tags.service';
import { TagConfirmationComponent } from '../tag-confirmation/tag-confirmation.component';

enum TagAction {
  ADD = 'add',
  REMOVE = 'remove',
}

@UntilDestroy()
@Component({
  selector: 'app-tag-selection-menu',
  templateUrl: './tag-selection-menu.component.html',
  styleUrls: ['./tag-selection-menu.component.scss'],
})
export class TagSelectionMenuComponent implements OnInit, OnChanges {
  tagAction = TagAction;
  showTagEditor = false;
  selectedTags: any[] = [];
  unselectedTags: any[] = [];
  filterString = '';
  showingSelectedTags: boolean[] = [];
  showingUnselectedTags: boolean[] = [];
  tagOnAllSelected: any[] = [];
  @Input() files: any[] = [];
  @Input() folders: any[] = [];
  @Input() courses: Course[] = [];
  @Input() user: any = {};
  // To auto-populate current course view.
  @Input() enforcingCourse = false;
  @Input() currentCourse?: Course;
  @Output() updatedTags = new EventEmitter<void>();

  constructor(private tagService: TagsService, private dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges) {
    // Something changed in the selected Files/Folders
    if (
      (changes.files?.currentValue?.length >= 0 &&
        changes.files.currentValue.length !== changes.files.previousValue?.length) ||
      (changes.folders?.currentValue?.length >= 0 &&
        changes.folders.currentValue.length !== changes.folders.previousValue?.length)
    ) {
      this.updateSelection();
    }
  }

  ngOnInit() {
    this.updateSelection();
  }

  updateSelection() {
    this.getSelectionTags();
    this.tagOnAllSelected = [];
    this.selectedTags.forEach((tag) => {
      this.tagOnAllSelected[tag._id] = this.isTagOnAllSelected(tag);
    });
    this.updatedTags.emit();
  }

  createNewTag(tagName: string, selectedCourse?: Course) {
    this.showTagEditor = false;
    (selectedCourse
      ? this.tagService.createCourseTag(tagName, selectedCourse._id)
      : this.tagService.createTag(tagName)
    )
      .pipe(untilDestroyed(this))
      .subscribe((tag) => {
        this.tagService.tags.next(tag);
        this.addTagToResources(
          tag.attribute_value.find(
            (attributeValue) =>
              attributeValue.value == tagName &&
              attributeValue.metadata.courseID === selectedCourse?._id,
          ),
        );
      });
  }

  addTagToResources(tag) {
    this.tagService.addTagToResources(tag, this.files, this.folders);
    if (this.unselectedTags?.includes(tag)) {
      this.unselectedTags.splice(
        this.unselectedTags.findIndex((removedTag) => tag == removedTag),
        1,
      );
    }
    if (!this.selectedTags?.includes(tag)) {
      this.selectedTags.push(tag);
    }
    this.updateSelection();
  }

  removeTagFromResources(tag) {
    this.tagService.removeTagFromResources(tag, this.files, this.folders);
    if (this.selectedTags?.includes(tag)) {
      this.selectedTags.splice(
        this.selectedTags.findIndex((removedTag) => tag == removedTag),
        1,
      );
    }
    if (!this.unselectedTags?.includes(tag)) {
      this.unselectedTags.push(tag);
    }
    this.updateSelection();
  }

  getSelectionTags() {
    this.selectedTags = union(
      this.tagService.getTagsForResources(this.files),
      this.tagService.getTagsForFolders(this.folders),
    );
    this.unselectedTags =
      this.tagService.tags.value?.attribute_value.filter(
        (tag) => !this.selectedTags.includes(tag),
      ) || [];
    this.showingSelectedTags = this.selectedTags.map((tag: any) =>
      tag.value.includes(this.filterString),
    );
    this.showingUnselectedTags = this.unselectedTags.map((tag: any) =>
      tag.value.includes(this.filterString),
    );
    if (this.filterString.length) {
      this.selectedTags = this.selectedTags.filter((tag) => tag.value.includes(this.filterString));
      this.unselectedTags = this.unselectedTags.filter((tag) =>
        tag.value.includes(this.filterString),
      );
    }
  }

  isTagOnAllSelected(tag) {
    let isOnAllSelected = true;
    let tagCount = 0;
    this.files.forEach((selectedFile) => {
      const tagsAttribute = selectedFile.custom_attributes?.filter(
        (attribute) =>
          this.tagService.stringToTag(attribute).key ==
          this.tagService.tags.value.attribute_key._id,
      );
      if (
        tagsAttribute &&
        !tagsAttribute.find(
          (tagId) => tagId == `${this.tagService.tags.value.attribute_key._id}:${tag._id}`,
        )
      ) {
        isOnAllSelected = false;
      } else if (tagsAttribute) {
        tagCount++;
      }
    });
    this.folders.forEach((selectedFolder) => {
      const tagsAttribute = selectedFolder.custom_attributes?.filter(
        (attribute) =>
          this.tagService.stringToTag(attribute).key ==
          this.tagService.tags.value.attribute_key._id,
      );
      if (
        tagsAttribute &&
        !tagsAttribute.find(
          (tagId) => tagId == `${this.tagService.tags.value.attribute_key._id}:${tag._id}`,
        )
      ) {
        isOnAllSelected = false;
      } else if (tagsAttribute) {
        tagCount++;
      }
    });
    if (tagCount != 0 && tagCount != this.files.length + this.folders.length) {
      isOnAllSelected = false;
    }
    return isOnAllSelected;
  }

  openTagEditor() {
    // TODO open showTagEditor item
    const dialogRef = this.dialog.open(CreateTagsComponent, {
      width: '636px',
      height: '321px',
      panelClass: 'tag-creating-dialog',
      data: {
        courses: this.courses,
        currentTags: this.tagService.tags.value.attribute_value,
        currentCourse: this.currentCourse,
        enforcingCourse: this.enforcingCourse,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (!data) {
          return;
        }
        const { tagName, selectedCourse } = data;
        this.createNewTag(tagName, selectedCourse);
      });
  }

  openTagConfirmationDialog(event, action: TagAction, tag: any) {
    event.stopPropagation();
    const confirmationDialog = this.dialog.open(TagConfirmationComponent, {
      width: '500px',
      data: {
        tag: tag,
        action: action,
      },
      panelClass: 'file-dialog',
    });
    confirmationDialog
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res) {
          switch (action) {
            case TagAction.ADD:
              this.addTagToResources(tag);
              break;
            case TagAction.REMOVE:
              this.removeTagFromResources(tag);
              break;
          }
        }
      });
  }

  setSearchValue(event) {
    this.filterString = event;
    this.updateSelection();
  }
}

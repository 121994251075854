import { Injectable } from '@angular/core';
import { FLAGS, FlagsService } from './flags.service';
import { UiService } from './ui.service';

export enum AudioChimes {
  error = 'error',
  mute = 'mute',
  newMessage = 'newMessage',
  record = 'record',
  screenShare = 'screenShare',
  stopRecord = 'stopRecord',
  testSpeaker = 'testSpeaker',
  unmute = 'unmute',
  userJoinsCall = 'userJoinsCall',
  userStartsCall = 'userStartCall',
  youJoinCall = 'youJoinCall',
  youLeaveCall = 'youLeaveCall',
  inActiveCallAlert = 'inActiveCallAlert',
}

const audioFilePathMap = {
  error: '/assets/sounds/error.mp3',
  mute: '/assets/sounds/mute.mp3',
  newMessage: '/assets/sounds/newmessage.mp3',
  record: '/assets/sounds/record.mp3',
  screenShare: '/assets/sounds/screenshare.mp3',
  stopRecord: '/assets/sounds/stoprecord.mp3',
  testSpeaker: '/assets/sounds/test-speaker.mp3',
  unmute: '/assets/sounds/unmute.mp3',
  userJoinsCall: '/assets/sounds/userjoinscall.mp3',
  userStartCall: '/assets/sounds/userstartscall.mp3',
  youJoinCall: '/assets/sounds/youjoincall.mp3',
  youLeaveCall: '/assets/sounds/youleavecall.mp3',
  inActiveCallAlert: '/assets/sounds/inactivealert.mp3',
};

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  private isPlayAudio = true;
  isChimesEnabled = this.flagsService.isFlagEnabled(FLAGS.SPACES_USER_CHIMES);
  activeSpeakerId: string | null = null;
  audioObjects: { [index: string]: HTMLAudioElement & { setSinkId(deviceId: string): void } } = {};

  constructor(private flagsService: FlagsService, private uiService: UiService) {
    this.setActiveSpeakerId('default');
    for (const [audioKey, filePath] of Object.entries(audioFilePathMap)) {
      // load the audio objects for each file path
      // to make sure browser load the files so audio files can be played in inactive tabs
      this.audioObjects[audioKey] = <HTMLAudioElement & { setSinkId(deviceId: string): void }>(
        new Audio(filePath)
      );
    }
  }

  public async playAudio(audioChime: AudioChimes, volume = 0.7) {
    const audio = this.audioObjects[audioChime];
    if (audio && this.activeSpeakerId && this.isPlayAudio && this.isChimesEnabled) {
      if (this.uiService.isMobile.getValue()) {
        const audioCtx = new window.AudioContext();
        const xhr = new XMLHttpRequest();
        const gainNode = audioCtx.createGain();
        gainNode.gain.value = volume;
        gainNode.connect(audioCtx.destination);
        xhr.open('GET', audioFilePathMap[audioChime]);
        xhr.responseType = 'arraybuffer';
        xhr.addEventListener('load', () => {
          const playsound = (audioBuffer) => {
            const source = audioCtx.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(gainNode);
            source.loop = false;
            source.start();
          };

          audioCtx.decodeAudioData(xhr.response).then(playsound);
        });
        xhr.send();
      } else {
        try {
          await audio.setSinkId(this.activeSpeakerId);
        } catch (error) {
          // As setSinkId isn't supported for all browsers yet
          return;
        }
        audio.volume = volume;
        audio.play();
      }
    }
  }

  public setIsPlayAudio(value: boolean): void {
    this.isPlayAudio = value;
  }

  public setActiveSpeakerId(speakerId: string): void {
    if (speakerId == 'default') {
      this.activeSpeakerId = localStorage.getItem('defaultSpeakerId');

      if (!this.activeSpeakerId) {
        this.activeSpeakerId = 'default';
      }
    } else {
      this.activeSpeakerId = speakerId;
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noteErrors',
})
export class NoteErrorsPipe implements PipeTransform {
  transform(model): any {
    const errors: string[] = [];
    if (!model.selectedCourse) {
      errors.push('Please set course');
    }
    if (model.note.note_fragments && model.note.note_fragments.length === 0) {
      errors.push('Please enter note');
    }
    return errors;
  }
}

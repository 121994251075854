import { Injectable, NgZone } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Mutex } from 'async-mutex';
import {
  BehaviorSubject,
  filter,
  map,
  Subject,
  Subscription,
  tap,
  switchMap,
  combineLatest,
  shareReplay,
  take,
} from 'rxjs';
import {
  ParticipantEventAction,
  TrackEventAction,
  TrackType,
} from '../common/interfaces/rtc-interface';
import {
  DEFAULT_DEVICE_ID,
  DeviceType,
  DevicesHandleUtil,
} from '../common/utils/devices-handle-util';
import { AnalyticsEventType } from '../models/analytics';
import {
  DeviceState,
  DEFAULT_DEVICE_STATE,
  OpenDeviceSelectionParams,
  DeviceErrorType,
  JoinCallDevicesState,
  VIDEO_CALL_BUTTONS_TOGGLING_TIMEOUT,
  DeviceTogglingStatus,
  CallRelatedModalAction,
} from '../models/device-manger';
import { VolumeDetector } from '../sessions/common/volume-detector';
import { SpaceRepository } from '../state/space.repository';
import { modifiedSetTimeout } from '../utilities/ZoneUtils';
import { AnalyticsService } from './analytics.service';
import { AudioChimes, AudioService } from './audio.service';
import { ProviderStateService } from './provider-state.service';
import { RtcServiceController } from './rtc.service';
import { TelemetryService } from './telemetry.service';
import { VirtualBackgroundInsertableStreamService } from './virtual-background-insertable-stream.service';
import { VideoCallTracksStateService } from './video-call-tracks-state.service';
import { LocalAudioTrackService } from './track/local-audio-track';
import { LocalVideoTrackService } from './track/local-video-track';
import {
  VirtualBackgroundLocalVideoDecorator,
  VirtualBackgroundState,
} from './track/virtual-background-local-video-decorator';
import { SpacePermissionsManagerService } from './space-permissions-manager.service';
import { FlagsService } from './flags.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class LocalTracksManagerService {
  private localVideoTrack = new VirtualBackgroundLocalVideoDecorator(
    new LocalVideoTrackService(),
    this.virtualBackgroundInsertableStreamService,
  );
  private localAudioTrack = new LocalAudioTrackService();

  public camState$ = this.localVideoTrack.deviceState$;
  public micState$ = this.localAudioTrack.deviceState$;

  private camTogglingStatus = new BehaviorSubject<DeviceTogglingStatus>(DeviceTogglingStatus.DONE);
  private micTogglingStatus = new BehaviorSubject<DeviceTogglingStatus>(DeviceTogglingStatus.DONE);
  private stopCamTogglingTimer?: NodeJS.Timer;
  private stopMicTogglingTimer?: NodeJS.Timer;
  public camTogglingStatus$ = this.camTogglingStatus.asObservable();
  public micTogglingStatus$ = this.micTogglingStatus.asObservable();

  private videoTrackPlaying?: Subscription;
  private audioTrackPlaying?: Subscription;

  private isVideoMuted = false;

  // Cover the case that the user enabling his devices from the device modal before starting/joining the call,
  // and the joined event isn't fired yet
  private isExistPendingCamToggling = false;
  private isExistPendingMicToggling = false;
  private pendingCamTogglingMutex = new Mutex();
  private pendingMicTogglingMutex = new Mutex();

  private speakingDetector = new VolumeDetector(1500, this.ngZone);
  public speakingDetected$ = this.speakingDetector.asObservable().pipe(
    map((currentlyDetectingAudio) => currentlyDetectingAudio.isCurrentlyDetected),
    tap((detected: boolean) => {
      if (detected) {
        this.speakingDetector.stopAudioRenderer(TrackType.AUDIO);
      }
    }),
  );

  public openDeviceSelection$ = new Subject<OpenDeviceSelectionParams>();

  public isCallTracksNeededByCallModal = false;

  // Added to limit the execution of the workaroud for resetting the video stream track after receving track-stopped event
  // Reset the track if this event is just received after a mute video request otherwise return
  private isMuteVideoRequested = false;

  constructor(
    private rtcServiceController: RtcServiceController,
    private telemetry: TelemetryService,
    private providerStateService: ProviderStateService,
    private spaceRepo: SpaceRepository,
    private virtualBackgroundInsertableStreamService: VirtualBackgroundInsertableStreamService,
    private audioService: AudioService,
    private analyticsService: AnalyticsService,
    private videoCallTracksStateService: VideoCallTracksStateService,
    private ngZone: NgZone,
    private spacePermissionsManager: SpacePermissionsManagerService,
    private flagsService: FlagsService,
  ) {
    this.setupLocalParticipantEventsListener();
    this.setupLocalTracksLogsCallback();
    this.localVideoTrack.onVbProcessingError = async () => {
      if (rtcServiceController.service.isConnected()) {
        await this.mute(DeviceType.VIDEO);
      } else {
        // to emit the video stream without effect
        this.acquireVideoStream();
      }
    };
    rtcServiceController.providerChanged
      .pipe(
        untilDestroyed(this),
        switchMap(() =>
          rtcServiceController.service.openDeviceSelection$().pipe(untilDestroyed(this)),
        ),
      )
      .subscribe((params) => {
        this.openDeviceSelection$.next(params);
      });

    rtcServiceController.providerChanged
      .pipe(
        untilDestroyed(this),
        switchMap(() =>
          rtcServiceController.service.playLocalVideoTimeout$().pipe(untilDestroyed(this)),
        ),
      )
      .subscribe(() => {
        this.mute(DeviceType.VIDEO);
        this.updateCamDeviceState({ hasError: true, errorType: DeviceErrorType.NO_INPUT_DETECTED });
      });
  }

  public async unmute(
    deviceType: DeviceType,
    showDeviceModalOnError = false,
    restartTrack = false,
  ) {
    this.startLoadingOnDeviceToggle(deviceType);

    // There is no force acquiring a new stream, so normal flow will be executed
    if (!restartTrack) {
      const continueAfterDevicePrechecks = await this.devicePrechecks(
        showDeviceModalOnError,
        deviceType,
      );

      if (!continueAfterDevicePrechecks) {
        this.stopLoadingDeviceToggling(deviceType);
        return;
      }

      const failedToBroadcastExistingStream = await this.broadcastExistingTrack(deviceType);
      if (!failedToBroadcastExistingStream) {
        this.setupActiveStreamListeners(deviceType);
        return;
      }
    }

    this.logControlsActions('Unmute', deviceType, {
      action:
        "No acquired track OR can't unmute already acquired stream. Acquiring a new stream...",
    });
    this.resetExistingTrack(deviceType);
    try {
      deviceType === DeviceType.VIDEO
        ? await this.acquireVideoStream()
        : await this.acquireAudioStream();

      const currentActiveTrack =
        deviceType === DeviceType.VIDEO
          ? this.localVideoTrack.getMediaStreamTrack()!
          : this.localAudioTrack.getMediaStreamTrack()!;
      await this.broadcastActiveTrackToRTCProvider(deviceType, currentActiveTrack);
      this.setupActiveStreamListeners(deviceType);
    } catch (deviceError) {
      this.stopLoadingDeviceToggling(deviceType);
      await this.handleDeviceErrorOnAcquiring(deviceType, showDeviceModalOnError);
    }
  }

  // === Start of unmute flow helper methods ===

  // return false if we should show the modal on error and the permission status is prompt
  private async devicePrechecks(
    showDeviceModalOnError: boolean,
    deviceType: DeviceType,
  ): Promise<boolean> {
    if (!showDeviceModalOnError) {
      return true;
    }

    const permissionIsPrompt = await DevicesHandleUtil.isDevicePermissionStatusPrompt(deviceType);
    if (permissionIsPrompt) {
      this.openDeviceSelection$.next({
        returnBackToJoinCallModal: false,
        callbackAfterModalClosed: undefined,
        deviceErrorType:
          deviceType === DeviceType.VIDEO
            ? this.localVideoTrack.deviceState.errorType
            : this.localAudioTrack.deviceState.errorType,
      });
      this.logControlsActions('Unmute', deviceType, {
        action: 'Permission is denied. Permissions helper should be opened',
      });
      return false;
    }
    return true;
  }

  // If the previous acquired stream is still working, we are re-broadcasting it instead of acquiring a new one
  private async broadcastExistingTrack(deviceType: DeviceType): Promise<boolean> {
    if (
      // in case the broadcasted stream has a problem we will force re-aquiring the stream
      !this.isBroadcastedStreamHasProblem(deviceType)
    ) {
      if (deviceType === DeviceType.AUDIO) {
        this.speakingDetector.stopAudioRenderer(TrackType.AUDIO);

        await this.rtcServiceController.service.startBroadcastingAudioStream(
          this.localAudioTrack.getMediaStreamTrack()!,
        );
      } else {
        await this.rtcServiceController.service.publishAndStartBroadcastingVideoStream(
          this.localVideoTrack.getMediaStreamTrack()!,
        );
      }

      this.logControlsActions('Unmute', deviceType, {
        action: 'No need to re-acquire a new stream. Unmuted existing one',
      });
      return false;
    }

    return true;
  }

  private async extractAndLogAcquiredDeviceInfo(
    deviceType: DeviceType,
    mediaTrackSettings: MediaTrackSettings,
  ) {
    const kind = deviceType === DeviceType.AUDIO ? 'audioinput' : 'videoinput';
    const { groupId } = mediaTrackSettings;

    if (!groupId) {
      return;
    }

    const deviceInfo = await this.rtcServiceController.getCurrentDeviceInfo(kind, groupId);

    if (!deviceInfo) {
      return;
    }

    this.logDeviceInfo(deviceType, deviceInfo);
  }

  // === End of unmute flow helper methods ===

  private async setupAreYouSpeakingIndicator() {
    try {
      await this.acquireAudioStream();

      if (!this.localAudioTrack.isTrackAcquired()) {
        return;
      }
      this.setupActiveStreamListeners(DeviceType.AUDIO);
      this.speakingDetector.startAudioRender(
        this.localAudioTrack.getMediaStreamTrack()!,
        TrackType.AUDIO,
      );
    } catch (deviceError) {
      await this.handleDeviceErrorOnAcquiring(DeviceType.AUDIO, false);
    }
  }

  public async mute(deviceType: DeviceType) {
    switch (deviceType) {
      case DeviceType.VIDEO:
        this.muteVideo();
        break;
      case DeviceType.AUDIO:
        this.muteAudio();
        break;
      default:
        return;
    }
  }

  public async toggleDeviceState(deviceType: DeviceType, showDeviceModalOnError = false) {
    if (deviceType === DeviceType.VIDEO) {
      this.toggleCamDevice(showDeviceModalOnError);
    } else {
      this.toggleMicDevice(showDeviceModalOnError);
    }
  }

  // As Daily won't broadcast the stop event when the track ends because of error or if broadcasting the stream stuck
  // or if the stream is muted
  private isBroadcastedStreamHasProblem(deviceType: DeviceType) {
    if (deviceType === DeviceType.AUDIO) {
      return (
        this.localAudioTrack.deviceState.hasError ||
        this.micTogglingStatus.value === DeviceTogglingStatus.TIMEOUT ||
        !this.localAudioTrack.isTrackAcquired() ||
        this.localAudioTrack.getMediaStreamTrack()!.muted
      );
    } else {
      return (
        this.localVideoTrack.deviceState.hasError ||
        this.camTogglingStatus.value === DeviceTogglingStatus.TIMEOUT ||
        !this.localVideoTrack.isTrackAcquired() ||
        this.isVideoMuted ||
        this.localVideoTrack.getMediaStreamTrack()!.muted
      );
    }
  }

  private async toggleCamDevice(showDeviceModalOnError = false) {
    if (
      this.rtcServiceController.service.isCameraEnabled() &&
      !this.isBroadcastedStreamHasProblem(DeviceType.VIDEO)
    ) {
      await this.mute(DeviceType.VIDEO);
      this.sendAnalyticsEvent(AnalyticsEventType.MUTE_VIDEO);
      return;
    }
    await this.unmute(DeviceType.VIDEO, showDeviceModalOnError);
    this.sendAnalyticsEvent(AnalyticsEventType.UNMUTE_VIDEO);
  }

  private async toggleMicDevice(showDeviceModalOnError = false) {
    if (
      this.rtcServiceController.service.isMicEnabled() &&
      !this.isBroadcastedStreamHasProblem(DeviceType.AUDIO)
    ) {
      await this.mute(DeviceType.AUDIO);
      this.sendAnalyticsEvent(AnalyticsEventType.MUTE_AUDIO);
      this.audioService.playAudio(AudioChimes.mute);
      return;
    }
    await this.unmute(DeviceType.AUDIO, showDeviceModalOnError);
    this.sendAnalyticsEvent(AnalyticsEventType.UNMUTE_AUDIO);
    this.audioService.playAudio(AudioChimes.unmute, 0.5);
  }

  private sendAnalyticsEvent(event: AnalyticsEventType) {
    this.analyticsService.addToAnalyticsEventsBatch(event);
  }

  // Used to reflect current devices states (On/off) prior joining the call
  public applyDevicesStatesOnJoinCall(joinCallDevicesState: JoinCallDevicesState) {
    this.isExistPendingCamToggling = joinCallDevicesState.unmuteCam;
    this.isExistPendingMicToggling = joinCallDevicesState.unmuteMic;

    if (joinCallDevicesState.unmuteCam) {
      this.sendAnalyticsEvent(AnalyticsEventType.UNMUTE_VIDEO);
    }

    if (joinCallDevicesState.unmuteMic) {
      this.sendAnalyticsEvent(AnalyticsEventType.UNMUTE_AUDIO);
    }
  }

  public resetDevicesStates() {
    this.updateCamDeviceState(DEFAULT_DEVICE_STATE);
    this.updateMicDeviceState(DEFAULT_DEVICE_STATE);
  }

  // Used to update mic button state dynamically if there are no mics at all in real-time
  public updateMicNotFound(notFound: boolean) {
    this.updateMicDeviceState({
      hasError: notFound,
      errorType: notFound ? DeviceErrorType.NOT_FOUND : undefined,
    });
  }

  private async muteAudio() {
    if (!this.rtcServiceController.service.isMicEnabled()) {
      return;
    }
    // to not try to unmute a new audio track if the track ended while being muted
    this.localAudioTrack.onended = null;
    await this.rtcServiceController.service.stopBroadcastingAudioStream();
    if (this.localAudioTrack.isTrackAcquired()) {
      this.speakingDetector.startAudioRender(
        this.localAudioTrack.getMediaStreamTrack()!,
        TrackType.AUDIO,
      );
    }
    this.logControlsActions('Mute', DeviceType.AUDIO, {
      action: 'Stop broadcasting audio track. Mic is muted!',
    });
  }

  private async muteVideo() {
    if (!this.rtcServiceController.service.isCameraEnabled()) {
      return;
    }
    this.rtcServiceController.resetHideSelfViewState();
    this.isMuteVideoRequested = true;
    await this.rtcServiceController.service.stopBroadcastingVideoStream();
  }

  private handleStartCallWithLoadingDeviceTogglingState() {
    if (this.isExistPendingCamToggling) {
      this.startLoadingOnDeviceToggle(DeviceType.VIDEO);
    }
    if (this.isExistPendingMicToggling) {
      this.startLoadingOnDeviceToggle(DeviceType.AUDIO);
    }
  }

  private startLoadingOnDeviceToggle(deviceType: DeviceType) {
    if (deviceType === DeviceType.VIDEO) {
      this.startDeviceToggleLoadingTimeout(DeviceType.VIDEO);
      this.camTogglingStatus.next(DeviceTogglingStatus.LOADING);
    } else if (deviceType === DeviceType.AUDIO) {
      this.startDeviceToggleLoadingTimeout(DeviceType.AUDIO);
      this.micTogglingStatus.next(DeviceTogglingStatus.LOADING);
    }
  }

  private stopLoadingDeviceToggling(deviceType: DeviceType) {
    if (deviceType === DeviceType.VIDEO) {
      this.clearTimeoutIfExist(this.stopCamTogglingTimer);
      this.camTogglingStatus.next(DeviceTogglingStatus.DONE);
    } else if (deviceType === DeviceType.AUDIO) {
      this.clearTimeoutIfExist(this.stopMicTogglingTimer);
      this.micTogglingStatus.next(DeviceTogglingStatus.DONE);
    }
  }

  private setupLocalParticipantEventsListener() {
    this.providerStateService.participantEvents$
      .pipe(
        untilDestroyed(this),
        filter(
          (participantEvent) =>
            !!participantEvent?.participant.local &&
            participantEvent.action !== ParticipantEventAction.UPDATED,
        ),
      )
      .subscribe(async (localParticipantEvent) => {
        switch (localParticipantEvent?.action) {
          case ParticipantEventAction.JOINED:
            this.handleStartCallWithLoadingDeviceTogglingState();
            this.logDevicesStatesOnJoiningCall();
            await this.handlePendingMicToggling();
            this.handlePendingCamToggling();
            break;
          case ParticipantEventAction.LEFT:
            this.resetLocalTracksManager();
            break;
          default:
            return;
        }
      });

    // Clear any pending toggling in case the joining is failed
    this.providerStateService.callErrors$.pipe(untilDestroyed(this)).subscribe(() => {
      this.resetLocalTracksManager();
    });

    // Used as a workaround for Daily to enable v2CamAndMic
    this.providerStateService.trackEvents$
      .pipe(
        untilDestroyed(this),
        filter(
          (trackEvent) =>
            !!trackEvent?.participant.local &&
            trackEvent.action === TrackEventAction.STOP &&
            (trackEvent.type === TrackType.VIDEO || trackEvent.type === TrackType.AUDIO),
        ),
        tap((trackEvent) => {
          // enable the track if the call provider disabled it while muting
          // as we use the audio track to show audio detected on the device settigns modal
          // even if the track is muted from the call provider point of view
          trackEvent!.type === TrackType.VIDEO
            ? this.localVideoTrack.enableExistingTrack()
            : this.localAudioTrack.enableExistingTrack();

          if (trackEvent!.type === TrackType.VIDEO) {
            if (!this.isMuteVideoRequested) {
              return;
            }
            this.resetExistingTrack(DeviceType.VIDEO);
            this.logControlsActions('Mute', DeviceType.VIDEO, {
              action: 'Stop broadcasting video track & free memory. Cam is muted!',
            });
          }
        }),
      )
      .subscribe();

    this.providerStateService.localParticipantId$
      .pipe(untilDestroyed(this))
      .subscribe((localParticipantId) => {
        this.setupPlayingStateUpdateListeners(localParticipantId);
      });
  }

  private setupLocalTracksLogsCallback() {
    this.localAudioTrack.beforeAcquireStreamCallback = () => {
      this.logControlsActions('Unmute', DeviceType.AUDIO, {
        action: 'start acquiring a new audio stream',
      });
    };
    this.localAudioTrack.afterAcquireStreamCallback = () => {
      this.logControlsActions('Unmute', DeviceType.AUDIO, {
        action: 'successfully acquired a new audio stream',
      });
      this.extractAndLogAcquiredDeviceInfo(DeviceType.AUDIO, this.localAudioTrack.getSettings()!);
    };
    this.localVideoTrack.beforeAcquireStreamCallback = () => {
      this.logControlsActions('Unmute', DeviceType.VIDEO, {
        action: 'start acquiring a new video stream',
      });
    };
    this.localVideoTrack.afterAcquireStreamCallback = () => {
      this.logControlsActions('Unmute', DeviceType.VIDEO, {
        action: 'successfully acquired a new video stream',
      });
      this.extractAndLogAcquiredDeviceInfo(DeviceType.VIDEO, this.localVideoTrack.getSettings()!);
    };
  }

  private async handlePendingCamToggling() {
    if (!this.isExistPendingCamToggling) {
      return;
    }

    this.pendingCamTogglingMutex.runExclusive(async () => {
      this.isExistPendingCamToggling = false;
      this.unmute(DeviceType.VIDEO, false);
    });
  }

  private async handlePendingMicToggling() {
    if (!this.isExistPendingMicToggling) {
      this.setupAreYouSpeakingIndicator();
      return;
    }

    this.pendingMicTogglingMutex.runExclusive(async () => {
      this.isExistPendingMicToggling = false;
      await this.unmute(DeviceType.AUDIO, false);
    });
  }

  private resetExistingTrack(deviceType: DeviceType) {
    const localTrack =
      deviceType === DeviceType.VIDEO ? this.localVideoTrack : this.localAudioTrack;
    localTrack.onmute = null;
    localTrack.onunmute = null;
    localTrack.onended = null;
    if (!this.isCallTracksNeededByCallModal) {
      localTrack.closeExistingTrack();
    }

    if (deviceType === DeviceType.VIDEO) {
      this.isVideoMuted = false;
      this.isMuteVideoRequested = false;
    } else {
      this.speakingDetector.stopAudioRenderer(TrackType.AUDIO);
    }
  }

  async changeVbEffect(state: VirtualBackgroundState) {
    const isCameraEnabled = this.rtcServiceController.service.isCameraEnabled();
    try {
      await this.localVideoTrack.changeVbEffect(state);

      if (isCameraEnabled) {
        await this.unmute(DeviceType.VIDEO, false);
      }
    } catch (e: unknown) {
      if (isCameraEnabled) {
        await this.mute(DeviceType.VIDEO);
      }
    }
  }

  private async broadcastActiveTrackToRTCProvider(
    deviceType: DeviceType,
    activeStream: MediaStreamTrack,
  ) {
    this.logControlsActions('Unmute', deviceType, {
      action: 'Track is acquired. Broadcasting active stream to RTC provider...',
    });
    if (!activeStream) {
      return;
    }

    if (deviceType === DeviceType.VIDEO) {
      await this.rtcServiceController.service.publishAndStartBroadcastingVideoStream(activeStream);
    } else {
      await this.rtcServiceController.service.startBroadcastingAudioStream(activeStream);
    }
    this.logControlsActions('Unmute', deviceType, {
      action: 'Set active track to RTC provider is done',
    });
  }

  private setupActiveStreamListeners(deviceType: DeviceType) {
    const localTrack =
      deviceType === DeviceType.VIDEO ? this.localVideoTrack : this.localAudioTrack;

    // Do another unmute call (Extra gUM) to know the exact reason for ending the track OR
    // Acquire stream again if the issue was transient
    localTrack.onended = () => {
      this.logControlsActions('Unmute', deviceType, {
        action: 'Track is ended Re-acquiring the stream again...',
      });

      this.unmute(deviceType);
    };

    // Edge case for video stream especially on iOS
    // Once putting the app on background, video stream turns to black screen
    // To avoid this case, we will stop broadcasting the active video stream to show the avatar instead
    if (deviceType === DeviceType.VIDEO) {
      this.setupSpecificListenersForVideoStream();
    }
  }

  setupSpecificListenersForVideoStream() {
    this.isVideoMuted = false;
    this.isMuteVideoRequested = false;
    this.localVideoTrack.onmute = () => {
      this.logControlsActions('Unmute', DeviceType.VIDEO, {
        action: 'Video is muted. Pausing broadcasting...',
      });
      this.rtcServiceController.service.stopBroadcastingVideoStream();
      this.isVideoMuted = true;
    };
    this.localVideoTrack.onunmute = () => {
      if (this.isVideoMuted) {
        this.logControlsActions('Unmute', DeviceType.VIDEO, {
          action: 'Video is unmuted. Resuming broadcasting...',
        });
        if (this.localVideoTrack.isTrackAcquired()) {
          // added the check for safety but the track should be acquired
          this.rtcServiceController.service.startBroadcastingVideoStream(
            this.localVideoTrack.getMediaStreamTrack()!,
          );
        } else {
          this.logControlsActions('Unmute', DeviceType.VIDEO, {
            action: 'unmuted video ended before broadcasting again',
          });
        }
        this.isVideoMuted = false;
      }
    };
  }

  private async handleDeviceErrorOnAcquiring(
    deviceType: DeviceType,
    showDeviceModalOnError: boolean,
  ) {
    this.logControlsActions('Unmute', deviceType, {
      action: 'handle error on unmute',
    });

    const localTrack =
      deviceType === DeviceType.VIDEO ? this.localVideoTrack : this.localAudioTrack;

    // close the track in case a post processing error happen but the track is already acquired
    // EX. apply virtual background error
    this.resetExistingTrack(deviceType);

    if (
      localTrack.deviceState.hasError &&
      localTrack.deviceState.errorType === DeviceErrorType.NOT_FOUND &&
      localTrack.deviceId !== DEFAULT_DEVICE_ID
    ) {
      this.logControlsActions('Unmute', deviceType, {
        action:
          'There is not found error. Trying getting an active stream using default as a device ID',
      });
      if (deviceType === DeviceType.VIDEO) {
        await this.changeVideoDeviceId(DEFAULT_DEVICE_ID);
      } else {
        await this.changeAudioDeviceId(DEFAULT_DEVICE_ID);
      }
      this.unmute(deviceType);
      return;
    }

    // Handle other errors
    if (localTrack.deviceState.hasError) {
      this.logControlsActions('Unmute', deviceType, {
        action: 'There is a device error on acquiring stream',
        deviceState: localTrack.deviceState,
      });
      if (showDeviceModalOnError) {
        this.openDeviceSelection$.next({ deviceErrorType: localTrack.deviceState.errorType });
      }
      this.resetExistingTrack(deviceType);
    }
  }

  private logControlsActions(action: string, deviceType: DeviceType, payload: any) {
    this.telemetry.event(`[Meeting Event] [${action} ${deviceType}]`, {
      ...payload,
      videoDevice: this.localVideoTrack.deviceId,
      audioDeviceId: this.localAudioTrack.deviceId,
    });
  }

  private logDeviceInfo(deviceType: DeviceType, deviceInfo: MediaDeviceInfo) {
    this.telemetry.event(`[Meeting Event] [Device info ${deviceType}]`, {
      deviceInfo: JSON.stringify(deviceInfo),
    });
  }

  private getDeviceStatesLogsObject() {
    return {
      is_cam_error: this.localVideoTrack.deviceState.hasError,
      cam_error: this.localVideoTrack.deviceState.hasError
        ? this.localVideoTrack.deviceState.errorType
        : undefined,
      is_mic_error: this.localAudioTrack.deviceState.hasError,
      mic_error: this.localAudioTrack.deviceState.hasError
        ? this.localAudioTrack.deviceState.errorType
        : undefined,
    };
  }

  private logDevicesStatesOnJoiningCall() {
    // Used to capture a successful joined event for the local participant without any error with his devices
    // Added currentBreakoutRoomId to facilitate searching through RTC provider dashboard
    this.telemetry.event('v1_started_joined_call_succesfully', {
      errors:
        this.localVideoTrack.deviceState.hasError || this.localAudioTrack.deviceState.hasError,
      currentBreakoutRoomId: this.spaceRepo.activeSpaceCurrentRoom?.uid,
      ...this.getDeviceStatesLogsObject(),
    });
  }

  private resetLocalTracksManager() {
    this.speakingDetector.stopAudioRenderer(TrackType.AUDIO);
    this.resetExistingTrack(DeviceType.VIDEO);
    this.resetExistingTrack(DeviceType.AUDIO);

    this.isExistPendingCamToggling = false;
    this.isExistPendingMicToggling = false;

    this.camTogglingStatus.next(DeviceTogglingStatus.DONE);
    this.micTogglingStatus.next(DeviceTogglingStatus.DONE);

    this.clearTimeoutIfExist(this.stopCamTogglingTimer);
    this.clearTimeoutIfExist(this.stopMicTogglingTimer);

    this.resetDevicesStates();
  }

  private startDeviceToggleLoadingTimeout(deviceType: DeviceType) {
    switch (deviceType) {
      case DeviceType.VIDEO:
        this.clearTimeoutIfExist(this.stopCamTogglingTimer);
        this.stopCamTogglingTimer = modifiedSetTimeout(() => {
          if (this.camTogglingStatus.value === DeviceTogglingStatus.LOADING) {
            this.mute(DeviceType.VIDEO);
            this.camTogglingStatus.next(DeviceTogglingStatus.TIMEOUT);
            this.logDeviceTogglingTimeout(deviceType);
          }
        }, this.getVideoUnmuteTimeout());
        break;
      case DeviceType.AUDIO:
        this.clearTimeoutIfExist(this.stopMicTogglingTimer);
        this.stopMicTogglingTimer = modifiedSetTimeout(() => {
          if (this.micTogglingStatus.value === DeviceTogglingStatus.LOADING) {
            this.mute(DeviceType.AUDIO);
            this.micTogglingStatus.next(DeviceTogglingStatus.TIMEOUT);
            this.logDeviceTogglingTimeout(deviceType);
          }
        }, this.getAudioUnmuteTimeout());
        break;
    }
  }

  private getAudioUnmuteTimeout() {
    return (
      (this.flagsService.featureFlagsVariables?.play_audio_settings?.unmute_timeout as number) ||
      VIDEO_CALL_BUTTONS_TOGGLING_TIMEOUT
    );
  }

  private getVideoUnmuteTimeout() {
    let timeoutConfig;
    if (this.virtualBackgroundInsertableStreamService.isVirtualBackgroundMode()) {
      timeoutConfig = this.flagsService.featureFlagsVariables?.play_video_settings
        ?.unmute_with_vb_timeout as number;
    } else {
      timeoutConfig = this.flagsService.featureFlagsVariables?.play_video_settings
        ?.unmute_timeout as number;
    }

    return timeoutConfig || VIDEO_CALL_BUTTONS_TOGGLING_TIMEOUT;
  }

  private logDeviceTogglingTimeout(deviceType: DeviceType) {
    this.telemetry.event('[Meeting event]', {
      deviceType,
      action: 'loading device toggling timeout',
    });
  }

  private clearTimeoutIfExist(timeoutId: NodeJS.Timer | undefined) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }

  private setupPlayingStateUpdateListeners(localParticipantId: string) {
    this.videoTrackPlaying?.unsubscribe();
    this.audioTrackPlaying?.unsubscribe();
    this.videoTrackPlaying = this.videoCallTracksStateService
      .getPariticpantTrackPlayingObservable(localParticipantId, TrackType.VIDEO, this)
      .pipe(untilDestroyed(this))
      .subscribe((isPlaying) => {
        if (this.camTogglingStatus.value === DeviceTogglingStatus.LOADING && isPlaying) {
          this.camTogglingStatus.next(DeviceTogglingStatus.DONE);
          this.clearTimeoutIfExist(this.stopCamTogglingTimer);
        }
      });

    this.audioTrackPlaying = this.videoCallTracksStateService
      .getPariticpantTrackPlayingObservable(localParticipantId, TrackType.VIDEO, this, true)
      .pipe(untilDestroyed(this))
      .subscribe((isPlaying) => {
        if (this.micTogglingStatus.value === DeviceTogglingStatus.LOADING && isPlaying) {
          this.micTogglingStatus.next(DeviceTogglingStatus.DONE);
          this.clearTimeoutIfExist(this.stopMicTogglingTimer);
        }
      });
  }

  /**
   * use this observable when you need to make sure that the device helper is shown before doing other action
   * or to get first mic and cam state after joining a call
   * @returns Observable<{
          camState: DeviceState;
          micState: DeviceState;
      }>
   */
  public firstDevicesStateAfterJoiningCall = this.providerStateService.callConnected$
    .pipe(filter((callConnected) => callConnected))
    .pipe(
      switchMap(() =>
        combineLatest([this.camState$, this.micState$])
          .pipe(
            filter(([camState, micState]) => camState.isToggable && micState.isToggable),
            map(([camState, micState]) => ({ camState, micState })),
          )
          .pipe(take(1)),
      ),
    )
    .pipe(
      tap(({ camState, micState }) => {
        if (micState.hasError) {
          this.openDeviceSelection$.next({ deviceErrorType: micState.errorType });
        } else if (camState.hasError) {
          this.openDeviceSelection$.next({ deviceErrorType: camState.errorType });
        }
      }),
    )
    .pipe(shareReplay(1)); // to ensure that the modal will be shown only one time

  /** ************************ Handling actions taken when closing call modals **************************/
  closeUnneededStreamsOnCallModalClose(
    action: CallRelatedModalAction,
    joinCallDevicesState?: JoinCallDevicesState,
  ) {
    switch (action) {
      case CallRelatedModalAction.NOT_PROCEEDING_WITH_THE_CALL:
        this.resetExistingTrack(DeviceType.AUDIO);
        this.resetExistingTrack(DeviceType.VIDEO);
        break;
      case CallRelatedModalAction.START_JOINING_THE_CALL:
        if (!joinCallDevicesState?.unmuteCam || !this.spacePermissionsManager.canOpenCam()) {
          this.resetExistingTrack(DeviceType.VIDEO);
        }
        break;
      case CallRelatedModalAction.CLOSE_SPACE_DEVICE_MODAL:
        if (
          this.rtcServiceController.service.isConnected() &&
          !this.rtcServiceController.service.isCameraEnabled()
        ) {
          this.resetExistingTrack(DeviceType.VIDEO);
        }
        break;
    }
  }
  /** ************************ End of handling actions taken when closing call modals **************************/

  /** ************************ Start of audio and video track toggling functions **************************/

  /** *** audio related functions *****/

  getAudioDeviceId() {
    return this.localAudioTrack.deviceId;
  }

  getAudioStream$() {
    return this.localAudioTrack.stream$;
  }

  async acquireAudioStream(): Promise<MediaStream | null> {
    return this.localAudioTrack.acquireStream();
  }

  isAudioTrackAcquired() {
    return this.localAudioTrack.isTrackAcquired();
  }

  async changeAudioDeviceId(deviceId: string) {
    try {
      const isMicEnabled = this.rtcServiceController.service.isMicEnabled();
      await this.localAudioTrack.changeDevice(deviceId);
      if (isMicEnabled) {
        await this.unmute(DeviceType.AUDIO);
      }
    } catch (e: unknown) {
      // empty catch block as the error state will exist on the device state
    }
  }

  closeAudioTrack() {
    if (this.rtcServiceController.service.isMicEnabled()) {
      this.mute(DeviceType.AUDIO);
    } else {
      this.localAudioTrack.closeExistingTrack();
    }
  }

  /** *** video related functions *****/

  getVideoDeviceId() {
    return this.localVideoTrack.deviceId;
  }

  getVideoStream$() {
    return this.localVideoTrack.stream$;
  }

  async acquireVideoStream(): Promise<MediaStream | null> {
    return this.localVideoTrack.acquireStream();
  }

  getVideoStream() {
    return this.localVideoTrack.getMediaStream();
  }

  isVideoTrackAcquired() {
    return this.localVideoTrack.isTrackAcquired();
  }

  async changeVideoDeviceId(deviceId: string) {
    try {
      const isCamEnabled = this.rtcServiceController.service.isCameraEnabled();
      await this.localVideoTrack.changeDevice(deviceId);
      if (isCamEnabled) {
        await this.unmute(DeviceType.VIDEO);
      }
    } catch (e: unknown) {
      // empty catch block as the error state will exist on the device state
    }
  }

  closeVideoTrack() {
    if (this.rtcServiceController.service.isCameraEnabled()) {
      this.mute(DeviceType.VIDEO);
    } else {
      this.localVideoTrack.closeExistingTrack();
    }
  }

  /** *** devices states related functions *****/
  getCamDeviceState(): Readonly<DeviceState> {
    return this.localVideoTrack.deviceState;
  }

  getMicDeviceState(): Readonly<DeviceState> {
    return this.localAudioTrack.deviceState;
  }

  updateCamDeviceState(newState: Partial<DeviceState>) {
    this.localVideoTrack.updateDeviceState(newState);
    this.telemetry.setSessionVars({
      is_device_error:
        this.localVideoTrack.deviceState.hasError || this.localAudioTrack.deviceState.hasError,
      ...this.getDeviceStatesLogsObject(),
    });
  }

  updateMicDeviceState(newState: Partial<DeviceState>) {
    this.localAudioTrack.updateDeviceState(newState);
    this.telemetry.setSessionVars({
      is_device_error:
        this.localVideoTrack.deviceState.hasError || this.localAudioTrack.deviceState.hasError,
      ...this.getDeviceStatesLogsObject(),
    });
  }

  /** ************************ End of audio and video track toggling functions **************************/
}

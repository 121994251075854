<div class="points-container" [class.active]="active" #pointContainer>
  <div class="fixed">
    <span>{{ points }}</span>
    <span class="gray"> {{ (points === 1 ? 'Mark' : 'Marks') | translate }}</span>
  </div>
  <div class="not-fixed align-items-center" (click)="active = true">
    <div class="d-flex align-items-center change-box">
      <span>{{ points }}</span>
      <i class="material-icons cmx-8" (click)="add($event)">add</i>
      <i class="material-icons" (click)="remove($event)">remove</i>
    </div>
    <i *ngIf="active" class="material-icons-outlined cml-8" (click)="changePoints.emit(0)"
      >delete_outline</i
    >
  </div>
</div>

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ERRORS, WARNINGS } from 'src/app/common//utils/notification-constants';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from 'src/app/services/notification-toaster.service';
import { CustomErrorCodes } from 'src/custom_error_codes.constants';
import { UpgradeVersionNotificationComponent } from './popup-notifications/upgrade-version-notification/upgrade-version-notification.component';
import { AuthService } from './services/auth.service';
import { ModalManagerService } from './services/modal-manager.service';
import { UserService } from './services/user.service';
import { ToasterPopupStyle } from './ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { IconMessageToasterElement } from './ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { URL_CONSTANTS } from './common/utils/url';
import { TelemetryService } from './services/telemetry.service';
import { modifiedSetTimeout, modifiedTimer } from './utilities/ZoneUtils';

// To be added as a header in request we do not want their error to be intercepted. But sent as received.
export const ErrorInterceptorSkipHeader = 'X-Skip-Error-Interceptor';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private DEFAULT_ERROR_TITLE_MSG = this.translateService.instant('Something went wrong!');
  private DEFAULT_ERROR_CONTENT_MSG = this.translateService.instant(
    'We’re sorry, but we could not complete your request. Please check your internet connection and try again later.',
  );
  private MAX_RETRY_ATTEMPTS = 5;
  private EXP_BACKOFF_SCALING_FACTOR = 600;
  private TIMEOUT_ERROR_CODES = [0, 504, 408];

  private readonly MONGO_DB_ID_REGEX = '[0-9a-fA-F]{24}';
  private readonly retryUrlPatterns: RegExp[] = [
    /\/tutor\/messages\/search\//,
    /\/utils\/time/,
    /\/attendance\/host\/analytics\/\?days=\d{1,5}/,
    new RegExp(`/spaces/${this.MONGO_DB_ID_REGEX}/event/\\?time=\\d{1,5}`),
    new RegExp(`/attendance/attendee/${this.MONGO_DB_ID_REGEX}`),
    new RegExp(`/attendance/host/${this.MONGO_DB_ID_REGEX}`),
    new RegExp(`/attendance/${this.MONGO_DB_ID_REGEX}`),
  ];

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private modalManagerService: ModalManagerService,
    private translateService: TranslateService,
    private notificationToasterService: NotificationToasterService,
    private authService: AuthService,
    private userService: UserService,
    private telemetryService: TelemetryService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /** If ErrorInterceptorSkipHeader header is present. Don't intercept error.
     Return them as received so they can be handled where they were initiated. */
    if (request.headers.has(ErrorInterceptorSkipHeader)) {
      const headers = request.headers.delete(ErrorInterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }
    return next.handle(request).pipe(
      retry({
        count: this.MAX_RETRY_ATTEMPTS,
        delay: (err: HttpErrorResponse, index) => {
          // TODO: remove the URL guard condition when we're sure about the interceptor
          if (!this.TIMEOUT_ERROR_CODES.includes(err.status)) {
            throw err;
          } else {
            // TODO: remove the URL guard condition when we're sure about the interceptor
            if (this.isRetryURL(err.url)) {
              // Log API retries in the form [URL][StatusCode]
              this.telemetryService.log(
                'warn',
                `[API Retry] Retrying request: [count: ${index}] [${err.status}] [${err.url}]`,
              );
              const backOffTime = Math.pow(2, index - 1) * this.EXP_BACKOFF_SCALING_FACTOR;
              return modifiedTimer(backOffTime);
            } else {
              this.telemetryService.log(
                'error',
                `[API Retry] Not retrying request: [${err.status}] [${err.url}]`,
              );
              throw err;
            }
          }
        },
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Throw error to continue chain - Sentry will ignore 401s
          this.toastr.error(error.error && error.error.err, error.status.toString());
          return throwError(() => error);
        } else if (this.TIMEOUT_ERROR_CODES.includes(error.status)) {
          // TODO: remove the guard condition when we're sure about the interceptor
          if (error.url?.includes('tutor/messages/search')) {
            this.notificationToasterService.showDefaultErrorNotification(
              this.DEFAULT_ERROR_TITLE_MSG,
              this.DEFAULT_ERROR_CONTENT_MSG,
              ERRORS.NETWORK_DISCONNECTED,
            );
          }
          return throwError(() => error);
        } else if (error?.error?.status_code === CustomErrorCodes.BYPASS_ERROR_INTERCEPTOR) {
          return throwError(() => error);
        } else if (error?.error?.status_code === CustomErrorCodes.CLIENT_VERSION_UPDATE_NEEDED) {
          // Release version smaller than min FE version
          this.modalManagerService.showModal(UpgradeVersionNotificationComponent, {
            height: '465px',
            width: '390px',
            panelClass: 'upgrade-version-modal',
            disableClose: true,
            hasBackdrop: true,
          });
        } else if (
          error.error?.err?.includes('space not found') ||
          error.error?.err?.includes('invalid space id')
        ) {
          this.showSpaceDoesNotExistNotification();
        } else if (error.error?.status_code === CustomErrorCodes.FB_EMAIL_NOT_IN_TOKEN) {
          this.authService.signout();
          this.showEmailNotInUserNotification();
          this.userService.appLoading.next(false);
        } else if (
          error.error?.error_code === CustomErrorCodes.CANNOT_CLONE_SPACE_TEMPLATE ||
          error.error?.error_code === CustomErrorCodes.SPACE_IS_NOT_TEMPLATE
        ) {
          this.showCannotCloneSpaceTemplateNotification();
        } else if (
          !this.router.url.includes('print') &&
          error.error?.err &&
          !error.error.err?.includes('Message not found')
        ) {
          this.toastr.error(error.error.err, error.status.toString());
        } else if (error?.status === 404 && error?.error?.error_code === 5001) {
          // Vimeo error, video not found because not owned by us
          // Pass it for handling in the vimeo-upload service.
          return throwError(() => error);
        } else if (error?.status === 500 && error?.url?.includes('pencil-ai-assistant')) {
          // Pass it for handling in the pencil-ai-assistant service.
          return throwError(() => error);
        }
        // Do not throw an error here otherwise sentry will capture and report it.
        return of<HttpEvent<any>>();
      }),
    );
  }

  showCannotCloneSpaceTemplateNotification(): void {
    const titleElement = new IconMessageToasterElement(
      {
        icon: 'warning',
        size: 16,
      },
      this.translateService.instant('Template not found'),
    );
    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(
        'We could not find the template you were looking for. Please verify you copied the template link correctly.',
      ),
    );
    const cannotCloneSpaceTemplateData = new NotificationDataBuilder(WARNINGS.WEAK_NETWORK_DETECTED)
      .style(ToasterPopupStyle.WARN)
      .type(NotificationType.WARNING)
      .topElements([titleElement])
      .middleElements([messageElement])
      .timeOut(5)
      .dismissable(false)
      .priority(790)
      .build();
    this.notificationToasterService.showNotification(cannotCloneSpaceTemplateData);
    // Redirecting immediately after showing a notification will crash the page,
    modifiedSetTimeout(() => {
      window.location.href = `/${URL_CONSTANTS.SPACES}`;
    }, 3500);
  }

  showSpaceDoesNotExistNotification() {
    const title = ['warn', this.translateService.instant('Oops, this space doesn’t exist!')];
    const titleElement = new IconMessageToasterElement(...title);
    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant('Please check the URL and try again.'),
    );
    const spaceDoesNotExistNotificationData = new NotificationDataBuilder(
      WARNINGS.WEAK_NETWORK_DETECTED,
    )
      .style(ToasterPopupStyle.WARN)
      .type(NotificationType.WARNING)
      .topElements([titleElement])
      .middleElements([messageElement])
      .timeOut(5)
      .dismissable(false)
      .priority(790)
      .build();
    this.notificationToasterService.showNotification(spaceDoesNotExistNotificationData);
  }

  showEmailNotInUserNotification() {
    const titleElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant('Unsupported Facebook account'),
    );
    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(
        "Sorry! We couldn't sign you up because your Facebook account is not associated with an email address. Please add a valid email to your Facebook account or consider using another login method.",
      ),
    );

    const unsupportedFBAccountError = new NotificationDataBuilder(ERRORS.UNSUPPORTED_FB_ACCOUNT)
      .style(ToasterPopupStyle.ERROR)
      .type(NotificationType.ERROR)
      .timeOut(10)
      .topElements([titleElement])
      .middleElements([messageElement])
      .dismissable(true)
      .build();
    this.notificationToasterService.showNotification(unsupportedFBAccountError);
  }

  isRetryURL(url: string | null): boolean {
    if (!url) {
      return false;
    }
    return this.retryUrlPatterns.some((pattern) => pattern.test(url));
  }
}

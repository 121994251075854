import { Injectable } from '@angular/core';

export enum LocalStorageItemIDs {
  REQUEST_ACCESS_ENABLED_TIME = 'REQUEST_ACCESS_ENABLED_TIME',
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  hasItem(itemID: LocalStorageItemIDs, spaceID: string = '', userID: string = ''): boolean {
    return !!this.getItem(itemID, spaceID, userID);
  }

  getItem(itemID: LocalStorageItemIDs, spaceID: string = '', userID: string = ''): string {
    const id: string = this.getID(itemID, spaceID, userID);
    return localStorage.getItem(id) ?? '';
  }

  setItem(itemID: LocalStorageItemIDs, value: string, spaceID: string = '', userID: string = '') {
    const id: string = this.getID(itemID, spaceID, userID);
    localStorage.setItem(id, value);
  }

  removeItem(itemID: LocalStorageItemIDs, spaceID: string = '', userID: string = '') {
    const id: string = this.getID(itemID, spaceID, userID);
    localStorage.removeItem(id);
  }

  clear() {
    localStorage.clear();
  }

  private getID(itemID: LocalStorageItemIDs, spaceID: string = '', userID: string = ''): string {
    const spaceSubStr = spaceID ? `-${spaceID}` : '';
    const userSubStr = userID ? `-${userID}` : '';
    return `${itemID}${spaceSubStr}${userSubStr}`;
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ui-difficulty',
  templateUrl: './difficulty.component.html',
  styleUrls: ['./difficulty.component.scss'],
})
export class DifficultyComponent {
  @Input() difficulty?: number;
  @Output() changeDifficulty = new EventEmitter();
}

import { FragmentType } from '../common/typed-fragment/typed-fragment';
import { Institution, User } from './user';

// TODO we may need to refactor this class and separate thing properly.
// because it contains a lot of different classes/interfaces

export enum Practice {
  // enum to keep track of a question's state in practice mode
  PRACTICED = 'PRACTICED',
  PRACTICED_CORRECT = 'PRACTICED_CORRECT',
  PRACTICED_WRONG = 'PRACTICED_WRONG',
  NOT_PRACTICED = 'NOT_PRACTICED',
}

export enum VISIBILITY {
  PUBLIC = 'PUBLIC',
  TEACHERS = 'TEACHERS',
  CLASS = 'CLASS',
  ME = 'ME',
}

export enum DIFFICULTY {
  BASIC = 'BASIC',
  AVERAGE = 'AVERAGE',
  ADVANCED = 'ADVANCED',
  CHALLENGE = 'CHALLENGE',
}

export const DIFFICULTY_TO_LABEL: { [key: number]: DIFFICULTY } = {
  1: DIFFICULTY.BASIC,
  2: DIFFICULTY.AVERAGE,
  3: DIFFICULTY.ADVANCED,
  4: DIFFICULTY.CHALLENGE,
};

// TODO: Convert to interface and convert static method to standalone function (??)
export interface Question {
  _id?: string;
  courses: string[];
  source_info: SourceInfo;
  number: string;
  question_fragments: Fragment[];
  sub_questions: any[];
  notes_fragments: Fragment[];
  note_fragments?: Fragment[];
  title_fragments?: Fragment[];
  authors_notes_fragments?: Fragment[];
  screenshot_fragments: Fragment[];
  multiple_choice: MultipleChoice[];
  institution: Institution;
  answer: MultipleChoice;
  next_question?: NextQuestionIdentifier[];

  // @TODO mfmansoo deprecate the timestamps https://pncl.atlassian.net/browse/BUGS-130
  published_timestamp?: string;
  updated_timestamp?: string;
  answered_timestamp: string;
  author: User;
  metadata: Metadata;
  ingestion_metadata: IngestionMetadata;
  acl: AclData;
  quality: number;
  state: string;
  type: string;
  extra_set?: Explanation[];
  worksheets: WorksheetInfo[];
  custom_attributes: string[];
  annotations: Annotation[];
  rec_type: string;

  createdAt: string;
  updatedAt: string;
  level?: number;
}

export interface Annotation {
  title: string;
  type: string;
  url: string;
  score: number;
  spot: string;
  debug: any;
}

export class Youtube {
  url: string;
  youtube_id: string;
  lang: string;
  score: number;

  constructor(youtube: { url: string; youtube_id: string; lang: string; score: number }) {
    this.url = youtube.url;
    this.youtube_id = youtube.youtube_id;
    this.lang = youtube.lang;
    this.score = youtube.score;
  }
}

export class Wiki {
  url: string;
  wiki_id: string;
  name: string;
  score: number;

  constructor(wiki: { url: string; wiki_id: string; name: string; score: number }) {
    this.url = wiki.url;
    this.wiki_id = wiki.wiki_id;
    this.name = wiki.name;
    this.score = wiki.score;
  }
}
export class Phet {
  url: string;
  name: string;
  topics: [string];
  description: string;
  learning_goals: string;
  score: number;

  constructor(phet: {
    url: string;
    name: string;
    topics: [string];
    description: string;
    learning_goals: string;
    score: number;
  }) {
    this.url = phet.url;
    this.name = phet.name;
    this.topics = phet.topics;
    this.description = phet.description;
    this.learning_goals = phet.learning_goals;
    this.score = phet.score;
  }
}

export interface WorksheetInfo {
  _id: string;
  title: string;
}

export interface SourceInfo {
  school?: string;
  class?: string;
  from?: string;
  paper?: string;
  exam?: string;
  grade?: string;
  booklet?: string;
  timezone?: string;
  session?: string;
  set?: string;
  series?: string;
  day?: number;
  month?: number;
  year?: number;
  question_number?: string;
  topics_covered?: any[]; // The format is {"oid", "topic_root_id"}
}

export const defaultSourceInfo = (): SourceInfo => ({
  school: '',
  class: '',
  from: '',
  paper: '',
  booklet: '',
  timezone: '',
  day: 0,
  month: 0,
  year: 0,
  question_number: '',
  grade: '',
  exam: '',
  topics_covered: [],
});

export class Fragment {
  type?: FragmentType;
  data: any;
  metadata?: FragmentMetadata;
  interface_metadata?: [FragmentMetadata];
  uploadProgress?: number;
}

export class FragmentMetadata {
  img_height?: string;
  img_width?: string;
  is_col_header?: boolean;
  is_row_header?: boolean;
  // The possibly alignments are:
  // 1) flex-start
  // 2) center
  // 3) flex-end
  content_alignment? = 'flex-start';
  // Based on each interface. Possible values are:
  // 1) worksheet - corresponding to sets.
  // 2) presentation - corresponding to presentation view of a question / note.
  // 3) landing - corresponding to independent question view landing page, also used for public question page.
  interface?: string;
}

export class MultipleChoice {
  option_id = '';
  choice_fragments: Fragment[] = [];
  num_lines?: number;
  explanation_na = false;
  explanation_pending = false;
  explanation_complete?: boolean = false;
}

export interface NextQuestionIdentifier {
  answer_type: string;
  question_id: string;
}

export class Metadata {
  time_to_answer?: number;
  difficulty?: number;
  language?: string;
  topics_ids?: string[];
  marks?: number;
  lines?: number;
  answerFormat?: string;
  time_taken?: number;
  correct?: boolean;
  minGrade = 0;
  maxGrade = 10;
}

export interface IngestionMetadata {
  approval_state: string;
  ingested: boolean;
}

export interface AclData {
  visibility: string;
}

export interface InputData {
  id: number;
  image_url: string;
  course_id: string;
}

export interface Explanation {
  _id: string;
  type: string;
  remark: Fragment[];
  sub_question_id: string;
  explanation_complete?: boolean;

  createdAt: string;
  updatedAt: string;
}

export interface ExplanationBuilder {
  type: string;
  remark: Fragment[];
  sub_question_id?: string;
  explanation_complete?: boolean;
}

import * as MarkdownIt from 'markdown-it';
import TurndownService from 'turndown';
import { v4 as uuid } from 'uuid';

import { extractMath } from 'extract-math';
import decode from 'unescape';
import { Fragment } from '../../models/question';
import { getHtmlFromFormattedText } from '../utils/common-util';

const htmlToMarkdown = new TurndownService({ emDelimiter: '*' });
htmlToMarkdown.keep(['sub', 'sup', 'u', 'user-mention']);

const markdownToHtml = new MarkdownIt({
  html: true,
});
markdownToHtml.disable(['hr', 'emphasis', 'escape', 'link']);

export enum FragmentType {
  Text = 'TEXT',
  Image = 'IMAGE',
  Katex = 'KATEX',
  Youtube = 'YOUTUBE',
  Phet = 'PHET',
  AnswerText = 'ANSWERTEXT',
  MIXED = 'MIXED',
  PDF = 'PDF',
  Table = 'TABLE',
  Iframe = 'IFRAME',
  WebViewer = 'BROWSER',
  Mario = 'MARIO',
  Chat_GPT = 'Chat_GPT',
  RemoteVideo = 'REMOTEVIDEO',
}

export class TypedFragment {
  uid?: string;
  type?: FragmentType;
  description: string;
  fragment: Fragment;
  option_data: string;
  myscript: boolean;
  hideMyscript = false;
  videoUrl: any;
  left?: number;

  constructor(
    type?: FragmentType,
    description = '',
    option_data = '',
    initial_data?,
    myscript = false,
  ) {
    if (type) {
      this.type = type;
    }
    this.description = description;
    this.option_data = option_data;
    this.fragment = new Fragment();
    this.fragment.type = type;
    this.myscript = myscript;
    this.uid = uuid();
    if (initial_data) {
      this.fragment.data = initial_data;
    }
  }

  get html() {
    let str = this.fragment.data ? markdownToHtml.render(this.fragment.data) : '';
    if (str) {
      str = str.replace('<pre><code>', '<p>');
      str = str.replace('</code></pre>', '</p>');
    }
    return str;
  }

  set html(value) {
    if (value) {
      if (this.fragment?.type) {
        this.fragment.data = this.cleanUserInput(value);
      } else {
        this.fragment = {
          type: FragmentType.Text,
          data: this.cleanUserInput(value),
        };
      }
    }
  }

  get editorHtml() {
    const convertedData = this.fragment.data;

    let newData = '';
    if (convertedData) {
      extractMath(convertedData).forEach((segment) => {
        // escape single $ when it's not escaped already
        segment.raw = this.escapeSingleDollar(segment.raw);

        if (!segment.math) {
          // disable listing in case of 2., 3., 1), 2), 3), ...
          if (/\d\. (.*?)/.test(segment.raw)) {
            const arr = segment.raw.split('.');
            if (arr[0] !== '1') {
              arr[0] += '\\';
            }
            newData += arr.join('.');
          } else if (/\d\) (.*?)/.test(segment.raw)) {
            newData += segment.raw.replace(/\)/g, '\\)');
          } else {
            newData += segment.raw;
          }
        } else {
          newData += `$$${segment.raw}$$`;
        }
      });
    }

    let str = newData ? markdownToHtml.render(newData) : null;
    if (str) {
      str = getHtmlFromFormattedText(str);
    }
    return str;
  }

  escapeSingleDollar(input: string): string {
    // Replace only instances of unescaped $ in input string (i.e. don't replace $$ or \$)
    // We look for the char before and after a matched '$' to decide whether to escape or not.
    const result = input.replace(/(.?.?.?)\$(.?.?.?)/g, (match, prevChars, nextChars): string => {
      if (
        (prevChars.length && prevChars[prevChars.length - 1] === '\\') ||
        (nextChars.length && nextChars.startsWith('\\$'))
      ) {
        return match;
      }
      if (prevChars === '$' || nextChars === '$') {
        return `${prevChars}$${nextChars}`;
      }
      if (prevChars === '\\') {
        return `${prevChars}$${nextChars}`;
      }
      return `${prevChars}\\$${nextChars}`;
    });
    return result;
  }

  static fromFragment(fragment: Fragment): TypedFragment {
    const frag = new TypedFragment();
    frag.fragment = fragment;
    frag.type = fragment.type as FragmentType;
    frag.description = 'dummy description';
    frag.option_data = 'dummy data';
    return frag;
  }

  cleanUserInput(input: string): string {
    if (this.type === FragmentType.Table) {
      return input;
    }
    const segments = extractMath(input);
    let cleanedInput = '';
    for (const segment of segments) {
      if (segment.math) {
        cleanedInput = `${cleanedInput} $$${decode(segment.raw)}$$ `;
      } else {
        const sub1 = segment.value.substring(segment.value.length - 3, segment.value.length);
        const sub2 = segment.value.substring(segment.value.length - 9, segment.value.length);
        if (
          sub1 === '<p>' ||
          sub2 === '<p>&nbsp;' ||
          segment.value.includes('white-space: pre-wrap;')
        ) {
          cleanedInput = `${cleanedInput}${htmlToMarkdown.turndown(segment.value)}\n\n`;
        } else {
          cleanedInput = `${cleanedInput}${htmlToMarkdown.turndown(segment.value)}`;
        }
      }
    }

    return cleanedInput;
  }
}

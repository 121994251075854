import { Directive, Input, OnInit } from '@angular/core';
import { FragmentCollection } from 'src/app/content/course/create/create.model';
import { EmbedVideoService } from '../../services/embed-video.service';
import { TypedFragment } from '../../common/typed-fragment/typed-fragment';

@Directive({
  selector: '[advanced-video-fragment][fragment][coll]',
})
export class AdvancedVideoFragmentDirective implements OnInit {
  @Input() fragment!: TypedFragment;
  @Input() coll!: FragmentCollection;
  @Input() placeholder?: string;

  constructor(private embedService: EmbedVideoService) {}

  ngOnInit() {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    if (
      this.fragment &&
      this.fragment.fragment.type === 'YOUTUBE' &&
      typeof this.fragment.fragment.data === 'string' &&
      (this.fragment.fragment.data.match(regExp) ||
        this.fragment.fragment.data.includes('https://vimeo.com/'))
    ) {
      this.fragment.videoUrl = this.embedService.embed(this.fragment.fragment.data, {
        attr: { width: 600, height: 400 },
      });
    }
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SessionStatus } from 'src/app/models/analytics';
import { BehaviorSubject } from 'rxjs';
import { IAnalyticsInsightParsed } from '../session_analytics_utils';

@Component({
  selector: 'app-session-analytics-sidebar',
  templateUrl: './session-analytics-sidebar.component.html',
  styleUrls: ['./session-analytics-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionAnalyticsSidebarComponent implements OnChanges, OnInit {
  @Input() allSessions: Array<IAnalyticsInsightParsed> = [];
  @Input() fetchingMoreSessions!: boolean;
  @Output() currentSession = new EventEmitter();
  allSessionsTable?: Array<any>;
  displayedColumns = ['sessions'];
  SessionStatusType = SessionStatus;
  selectedSessionId!: string;
  protected fetchingMoreSessions$ = new BehaviorSubject<boolean>(false);

  readonly WIDTH_TO_AVATAR = 20;
  readonly AVATAR_WIDTH = 26;

  constructor(private changeDetectionRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.selectedSessionId = this.allSessions[0]?.sessionId;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fetchingMoreSessions) {
      this.fetchingMoreSessions$.next(changes.fetchingMoreSessions.currentValue);
    }
    if (changes.allSessions) {
      this.allSessionsTable = this.allSessions.map((session) => ({
        ...session,
        attendees: (session.attendees as Array<any>).map((obj: any) => ({
          ...obj,
          userImgObject: { profile_image: obj.profile_image, name: obj.name },
        })),
      }));
      this.changeDetectionRef.markForCheck();
    }
  }

  handleCurrentSession(sessionId: string): void {
    this.selectedSessionId = sessionId;
    this.currentSession.emit(sessionId);
    this.changeDetectionRef.markForCheck();
  }
}

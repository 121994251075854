<div
  class="chip {{ 'filter-' + type | hyphenate }}"
  [ngClass]="{
    selected: selected,
    optionFilters: optionalFilters
  }"
  [ngStyle]="color?.length && { 'background-color': color }"
  (click)="selected = !selected; onClick.emit()"
  #tooltip="appTooltip"
  [appTooltipPlacement]="'bottom-center'"
  [appTooltipOffsetY]="10"
  [appTooltip]="
    (subtext && subtext.length
      ? text.concat(' in ').concat(subtext).concat('\n').concat(type)
      : text.concat('\n').concat(type)
    ) | translate
  "
>
  <svg-icon *ngIf="svgIcon" height="12" width="12" [icon]="svgIcon"></svg-icon>
  {{ text | middleTruncate: (subtext?.length ? maxSubtextLength : maxTextLength) | translate }}
  <span *ngIf="subtext && subtext.length">
    &nbsp;{{ 'in ' | translate }} {{ subtext | middleTruncate: maxSubtextLength | translate }}
  </span>
  <mat-icon [inline]="true" *ngIf="icon" class="icons-chips">
    {{ icon }}
  </mat-icon>
  <span *ngIf="showXButton && selected" class="closebtn" (click)="close.emit()"> &times; </span>
</div>

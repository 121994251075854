import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { fabric } from 'fabric';
import { clamp } from 'lodash';
import { SessionSharedDataService } from '../services/session-shared-data.service';
import { ItemModel } from '../models/session';
import {
  APPS_MAX_HEIGHT,
  APPS_MAX_WIDTH,
  APPS_MIN_HEIGHT,
  APPS_MIN_WIDTH,
} from '../sessions/session/iframe/additional-apps.utils';

@Directive({
  selector: '[appCanvasItemState]',
})
export class CanvasItemStateDirective implements OnChanges, OnDestroy {
  // The relatedFabricObject is a transparent rectangle that represents the bounding box of a canvas item in the fabric canvas. We use its state to position canvas items in the viewport.
  @Input() relatedFabricObject?: fabric.Object;
  @Input() syncPosition = true;
  @Input() syncDimensions = true;
  @Input() syncRotation = true;
  @Input() useBoundingBox = false;
  @Input() hideWhileModified = false;
  @Input() maintainAspectRatio = false;
  @Input() itemModel: ItemModel | undefined;
  @Input() translateOrigin = false;
  @Input() stateCheckActive = true;
  @Input() static = false;
  @Output() canvasItemHiddenOnEdit = new EventEmitter<boolean | undefined>(undefined);
  ratio?: number;

  boundSetState = this.setState.bind(this);
  boundAddGroupListeners = this.addGroupListeners.bind(this);
  boundSetCoords = this.setCoords.bind(this);
  boundHideEl = this.hideEl.bind(this);
  constructor(private el: ElementRef, private sessionSharedDataService: SessionSharedDataService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.relatedFabricObject?.currentValue && this.relatedFabricObject) {
      this.ratio = (this.relatedFabricObject.height ?? 1) / (this.relatedFabricObject.width ?? 1);
      this.setState();
      const events = [
        'moving',
        'moved',
        'scaling',
        'rotating',
        'rotated',
        'remoteChange',
        'deselected',
      ];
      for (const event of events) {
        this.relatedFabricObject?.on(event, this.boundSetState);
      }
      this.relatedFabricObject?.on('selected', this.boundAddGroupListeners);
      this.relatedFabricObject?.on('modified', this.boundSetCoords);
      this.sessionSharedDataService.fabricCanvas?.on(
        'selection:updated',
        this.boundAddGroupListeners,
      );
      if (this.hideWhileModified) {
        this.relatedFabricObject?.on('moving', this.boundHideEl);
        this.relatedFabricObject?.on('scaling', this.boundHideEl);
        this.relatedFabricObject?.on('rotating', this.boundHideEl);
      }
      if (this.relatedFabricObject.group) {
        this.addGroupListeners();
      }
    }

    if (changes.stateCheckActive) {
      if (changes.stateCheckActive.currentValue) {
        this.setState();
      } else {
        this.el.nativeElement.style.transform = 'none';
      }
    }
  }

  ngOnDestroy(): void {
    this.relatedFabricObject?.off('moving', this.boundSetState);
    this.relatedFabricObject?.off('moved', this.boundSetState);
    this.relatedFabricObject?.off('scaling', this.boundSetState);
    this.relatedFabricObject?.off('rotating', this.boundSetState);
    this.relatedFabricObject?.off('rotated', this.boundSetState);
    this.relatedFabricObject?.off('remoteChange', this.boundSetState);
    this.relatedFabricObject?.off('deselected', this.boundSetState);
    this.relatedFabricObject?.off('selected', this.boundAddGroupListeners);
    this.relatedFabricObject?.off('modified', this.boundSetCoords);
    this.sessionSharedDataService.fabricCanvas?.off(
      'selection:updated',
      this.boundAddGroupListeners,
    );
    if (this.hideWhileModified) {
      this.relatedFabricObject?.off('moving', this.boundHideEl);
      this.relatedFabricObject?.off('scaling', this.boundHideEl);
      this.relatedFabricObject?.off('rotating', this.boundHideEl);
    }
  }

  setState(): void {
    // Note: Do not change order of these two functions
    this.validateRelatedFabricDimensions();
    this.setTransformation();
    this.setDimensions();
  }

  validateRelatedFabricDimensions(): void {
    if (
      !this.relatedFabricObject ||
      !this.itemModel ||
      ![ItemModel.IFrame, ItemModel.WebViewer, ItemModel.Chat_GPT].includes(this.itemModel)
    ) {
      return;
    }

    const currentHeight = this.relatedFabricObject.getScaledHeight() ?? 0;
    const newHeight = clamp(currentHeight, APPS_MIN_HEIGHT, APPS_MAX_HEIGHT);

    const currentWidth = this.relatedFabricObject.getScaledWidth() ?? 0;
    const newWidth = clamp(currentWidth, APPS_MIN_WIDTH, APPS_MAX_WIDTH);

    if (currentWidth !== newWidth && currentHeight !== newHeight) {
      this.relatedFabricObject.set({
        height: newHeight,
        width: newWidth,
      });
    } else if (currentWidth !== newWidth) {
      this.relatedFabricObject.set({
        width: newWidth,
      });
    } else if (currentHeight !== newHeight) {
      this.relatedFabricObject.set({
        height: newHeight,
      });
    }
  }

  hideEl(): void {
    this.canvasItemHiddenOnEdit.emit(true);
    if (this.el.nativeElement.style.display !== 'none') {
      this.el.nativeElement.style.display = 'none';
    }
  }

  setTransformation(): void {
    if (!this.syncPosition || !this.relatedFabricObject || !this.stateCheckActive) {
      return;
    }
    const group = this.relatedFabricObject?.group;
    if (group) {
      // Transform matrix is a matrix that describes the state of a html element in the viewPort.
      // Check this: http://www.senocular.com/flash/tutorials/transformmatrix/
      // The origin of the fabric item is center center while for the canvas item it's left top.
      // That's why we are subtracting height/2 and width/2
      const matrix = [...this.relatedFabricObject.calcTransformMatrix()];
      if (this.translateOrigin) {
        // translateX
        matrix[4] = matrix[4] - (this.relatedFabricObject.width ?? 0) / 2;
        // translateY
        matrix[5] = matrix[5] - (this.relatedFabricObject.height ?? 0) / 2;
      }
      this.el.nativeElement.style.transformOrigin = 'center center';
      this.el.nativeElement.style.transform = `matrix(${matrix.join(',')})`;
    } else {
      const { top, left, angle } = this.setBoxDimensions();
      this.el.nativeElement.style.transformOrigin = 'top left';
      this.el.nativeElement.style.transform = `translate3d(${left}px,${top}px, 0px)`;
      this.el.nativeElement.style.transform += this.syncRotation ? `rotate(${angle}deg)` : '';
    }
  }

  private setBoxDimensions() {
    if (!this.relatedFabricObject) {
      return {};
    }

    let top = 0;
    let left = 0;
    let angle = 0;
    if (this.useBoundingBox) {
      const point = this.relatedFabricObject.getCenterPoint();
      top = point.y;
      left = point.x;
    } else {
      top = this.relatedFabricObject.top ?? 0;
      left = this.relatedFabricObject.left ?? 0;
      angle = this.relatedFabricObject.angle ?? 0;
    }

    return { top, left, angle };
  }

  setDimensions(): void {
    const canSetDimensions = this.syncDimensions && this.stateCheckActive;
    if (!canSetDimensions || !this.relatedFabricObject) {
      return;
    }

    const width = this.relatedFabricObject.width ?? 0;
    const height = this.relatedFabricObject.height ?? 0;

    const scaleX = this.relatedFabricObject.scaleX ?? 1;
    const scaleY = this.relatedFabricObject.scaleY ?? 1;

    if (this.maintainAspectRatio) {
      this.updateDimensionsWithAspectRatio(scaleX, scaleY);
    } else {
      this.updateDimensionsWithoutAspectRatio(scaleX, scaleY);
    }

    this.updateElementStyle(width, height);

    this.relatedFabricObject.scaleX = 1;
    this.relatedFabricObject.scaleY = 1;
  }

  private updateDimensionsWithAspectRatio(scaleX: number, scaleY: number): void {
    if (!this.relatedFabricObject) {
      return;
    }

    this.relatedFabricObject.width = (this.relatedFabricObject.width ?? 0) * scaleX;
    this.relatedFabricObject.height =
      (this.relatedFabricObject.width ?? 0) * (this.ratio ? this.ratio : scaleY);
  }

  private updateDimensionsWithoutAspectRatio(scaleX: number, scaleY: number): void {
    if (!this.relatedFabricObject) {
      return;
    }

    const groupScaleX = this.relatedFabricObject.group?.scaleX ?? 1;
    const groupScaleY = this.relatedFabricObject.group?.scaleY ?? 1;

    this.relatedFabricObject.width = (this.relatedFabricObject.width ?? 0) * scaleX * groupScaleX;
    this.relatedFabricObject.height = (this.relatedFabricObject.height ?? 0) * scaleY * groupScaleY;
  }

  private updateElementStyle(width: number, height: number): void {
    if (width !== 0) {
      this.el.nativeElement.style.width = `${width}px`;
    }
    if (height !== 0) {
      this.el.nativeElement.style.height = `${height}px`;
    }
  }

  setCoords(): void {
    (this.relatedFabricObject as any)?.calcACoords();
    this.relatedFabricObject?.fire('moved');
    this.canvasItemHiddenOnEdit.emit(false);
    if (this.el.nativeElement.style.display === 'none') {
      this.el.nativeElement.style.display = '';
    }
  }

  addGroupListeners(): void {
    if (this.syncPosition) {
      this.relatedFabricObject?.group?.on('moving', this.setTransformation.bind(this));
      this.relatedFabricObject?.group?.on('moved', this.setTransformation.bind(this));
    }
    if (this.syncDimensions) {
      this.relatedFabricObject?.group?.on('scaling', this.setState.bind(this));
      this.relatedFabricObject?.group?.on('scaled', this.setState.bind(this));
    }
    if (this.hideWhileModified) {
      this.relatedFabricObject?.group?.on('moving', this.hideEl.bind(this));
      this.relatedFabricObject?.group?.on('moved', this.setCoords.bind(this));
    }
    if (this.syncRotation) {
      this.relatedFabricObject?.group?.on('rotating', this.boundSetState);
    }
  }
}

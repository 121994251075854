import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventAttendance } from '../models/event';
import { AttendanceUser } from '../sessions/session/attendance/attendance-ui-models';
import { URLService } from './dynamic-url.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AttendanceService {
  constructor(private http: HttpClient, private urlService: URLService) {}

  attendEvent(eventId: string): Observable<EventAttendance> {
    return this.http.post<EventAttendance>(
      `${this.urlService.getDynamicUrl()}/attendance/attendee/${eventId}`,
      JSON.stringify({}),
      httpOptions,
    );
  }
  getEventAttendance(eventId: string): Observable<Partial<AttendanceUser>[]> {
    return this.http.get<Partial<AttendanceUser>[]>(
      `${this.urlService.getDynamicUrl()}/attendance/${eventId}`,
    );
  }
  updateUserAttendanceInEvent(
    eventId: string,
    attendance: Partial<EventAttendance>,
  ): Observable<any> {
    return this.http.post<any>(
      `${this.urlService.getDynamicUrl()}/attendance/host/${eventId}`,
      JSON.stringify({ attendance }),
      httpOptions,
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { LikeContext, LikeState } from '../models/user';
import { URLService } from './dynamic-url.service';

@Injectable({
  providedIn: 'root',
})
export class LikeService {
  content = new BehaviorSubject({});

  constructor(private http: HttpClient, private urlService: URLService) { }

  getLikeState(context: LikeContext): Observable<LikeState> {
    const encodedContext = btoa(JSON.stringify(context));
    return this.http.get<LikeState>(`${this.urlService.getDynamicUrl()}/tutor/like/${encodedContext}`);
  }

  setLikeState(context: LikeContext, likedState: boolean): Observable<LikeState> {
    const encodedContext = btoa(JSON.stringify(context));
    return this.http.post<LikeState>(`${this.urlService.getDynamicUrl()}/tutor/like/${encodedContext}/${likedState}`, null);
  }
}

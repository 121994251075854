import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { buildRouterParamsFromNotification } from 'src/app/common/notification/notification';
import { NotificationExtended } from 'src/app/models/global.interface';
import { MessageContentType } from 'src/app/services/notifications.service';
import { SessionSharedDataService } from 'src/app/services/session-shared-data.service';
import { SpacesService } from 'src/app/services/spaces.service';
import { PanelView } from 'src/app/sessions/panel/panel.component';
import { SpaceRepository } from 'src/app/state/space.repository';

@Component({
  selector: 'app-notification-menu-item',
  templateUrl: './notification-menu-item.component.html',
  styleUrls: ['./notification-menu-item.component.scss'],
})
export class NotificationMenuItemComponent {
  @Input() notification?: NotificationExtended;
  @Input() lastViewed?: string;
  @Input() isClickEnabled = true;

  @Output() itemClicked = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private spacesService: SpacesService,
    private sharedDataService: SessionSharedDataService,
    private spaceRepo: SpaceRepository,
  ) {}

  MessageContentType = MessageContentType;

  open(): void {
    if (!this.notification) {
      return;
    }

    // if notification is from the same space prevent routing
    if (this.notification.context?.session) {
      if (this.notification.context?.session !== this.spaceRepo.activeSpace?._id) {
        this.spacesService.navigateToAnotherSession(this.notification.context?.session);
        return;
      } else {
        this.sharedDataService.changeLeftPanelView.next(PanelView.chat);
      }
    }

    const [actionUrl, fragment] = buildRouterParamsFromNotification(this.notification);
    this.router.navigate([actionUrl], fragment);
    this.itemClicked.emit(true);
  }
}

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SVGIconComponentsModule } from '../common/svg-icon/svg-icon-components.module';
import { UiModule } from '../ui/ui.module';
import { PipeModule } from '../pipes/pipes.module';
import { TooltipDirective } from '../standalones/directives/pncl-tooltip.directive';
import { AnalyticsEventsTableComponent } from './analytics-events-table/analytics-events-table.component';
import { AnalyticsHeaderComponent } from './analytics-header/analytics-header.component';
import { EndOngoingSessionComponent } from './end-ongoing-session/end-ongoing-session.component';
import { SessionAnalyticsSidebarComponent } from './session-analytics-sidebar/session-analytics-sidebar.component';
import { SessionAnalyticsComponent } from './session-analytics.component';
import { SessionInsightsTableComponent } from './session-insights-table/session-insights-table.component';
import { AnalyticsRecordingTableComponent } from './analytics-recording-table/analytics-recording-table.component';
import { RecordingPreviewModalComponent } from './analytics-recording-table/recording-preview-modal/recording-preview-modal.component';
import { SessionInsightsHeaderComponent } from './session-insights-header/session-insights-header.component';
import { RoomsSelectionDropdownComponent } from './rooms-selection-dropdown/rooms-selection-dropdown.component';

@NgModule({
  declarations: [
    AnalyticsHeaderComponent,
    SessionAnalyticsComponent,
    SessionInsightsTableComponent,
    SessionAnalyticsSidebarComponent,
    EndOngoingSessionComponent,
    AnalyticsEventsTableComponent,
    AnalyticsRecordingTableComponent,
    RecordingPreviewModalComponent,
    SessionInsightsHeaderComponent,
    RoomsSelectionDropdownComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatSidenavModule,
    MatTableExporterModule.forRoot({ xlsxLightWeight: true }),
    DragDropModule,
    SVGIconComponentsModule,
    UiModule,
    MatSelectModule,
    MatFormFieldModule,
    PipeModule,
    MatIconModule,
    MatMenuModule,
    NgbTooltipModule,
    TooltipDirective,
  ],
  exports: [SessionAnalyticsComponent],
})
export class SessionAnalyticsModule {}

<app-loading [loading]="isLoadingSpaces" [showTipsIndicator]="true"> </app-loading>
<div class="spaces-view" *ngIf="!isLoadingSpaces">
  <!-- Header -->
  <app-spaces-header
    [user]="user"
    [spaceBeingCreated]="disableCreateSpace"
    (createNewSpaceEventEmitter)="createNewSpace()"
  ></app-spaces-header>

  <div class="spaces-sections">
    <app-generic-filters-view
      *ngIf="filtersPanelOpen"
      (togglePanel)="toggleFilterPanel()"
    ></app-generic-filters-view>
    <div
      class="spaces-body"
      [class.filter-open]="filtersPanelOpen"
      [class.templates-view]="currentPageView === SpacesHomePageView.ALL_TEMPLATES_VIEW"
    >
      <div class="space-search-section">
        <div
          class="filters-button"
          *ngIf="
            (uiService.isMobile | async) === false &&
            (filtersEnabled$ | async) &&
            !filtersPanelOpen &&
            currentPageView === SpacesHomePageView.SPACES_LIST_VIEW
          "
          (click)="toggleFilterPanel()"
        >
          <app-pencil-icon name="tune" [type]="IconTypes.svgIcon"></app-pencil-icon>
          <div class="filters-length" *ngIf="(filtersService.appliedFilters$ | async)?.length">
            {{ (filtersService.appliedFilters$ | async)?.length }}
          </div>
        </div>
        <div
          class="space-searchbar"
          *ngIf="currentPageView === SpacesHomePageView.SPACES_LIST_VIEW"
        >
          <app-search-bar
            class="d-flex"
            [searchTitle]="'Search for Spaces' | translate"
            (filter)="onSearch($event)"
            [editSearchValue]="spaceSearchQuery"
            [searchOnClear]="false"
          >
          </app-search-bar>
        </div>
        <button
          *ngIf="(uiService.isMobile | async) && canCreateSpace"
          mat-icon-button
          class="btn-space-new ms-2"
          color="primary"
          (click)="createNewSpace()"
          [disabled]="disableCreateSpace"
        >
          <span class="material-icons">add</span>
        </button>

        <!--      Back to spaces dashboard when all templates view is the current view-->
        <div class="top-bar" *ngIf="currentPageView === SpacesHomePageView.ALL_TEMPLATES_VIEW">
          <div
            class="d-flex align-items-center cursor-pointer"
            (click)="changeCurrentPageView(SpacesHomePageView.SPACES_LIST_VIEW)"
          >
            <svg-icon width="24" height="24" icon="arrow-back"></svg-icon>

            <span class="back-text">
              {{ 'Back to Spaces Dashboard' | translate }}
            </span>
          </div>
        </div>
      </div>

      <!--        Applied Filters -->
      <ng-container
        *ngIf="
          (filtersService.appliedFilters$ | async)?.length &&
          currentPageView !== SpacesHomePageView.ALL_TEMPLATES_VIEW
        "
      >
        <app-applied-filter-pills></app-applied-filter-pills>
      </ng-container>
      <!--    displaying templates view only if user can create spaces -->
      <div class="main-spaces-section" (scroll)="onScroll()" #spacesList>
        <div
          class="spaces-templates-container"
          [class.full-screen]="currentPageView === SpacesHomePageView.ALL_TEMPLATES_VIEW"
          *ngIf="
            canCreateSpace &&
            isSpacesTemplatesEnabled &&
            (uiService.isMobile | async) === false &&
            !isLoadingSearch &&
            !(filtersService.appliedFilters$ | async)?.length
          "
        >
          <app-spaces-templates-view
            [currentPageView]="currentPageView"
            (changeCurrentPageView)="changeCurrentPageView($event)"
            (handleTemplateDeleted)="removeSpaceTemplateStatus($event)"
          >
          </app-spaces-templates-view>
        </div>

        <!--        List of Spaces-->
        <div
          [hidden]="currentPageView === SpacesHomePageView.ALL_TEMPLATES_VIEW"
          class="spaces-container-list"
          [class.filter-active]="(filtersService.appliedFilters$ | async)?.length"
        >
          <!--        Loading When Searching Or Filtering-->
          <app-loading
            class="spaces-search-loading"
            [loading]="isLoadingSearch"
            [showTipsIndicator]="true"
          >
          </app-loading>

          <app-spaces-list
            (spacesListInviteUpdate)="spacesListInviteUpdate($event)"
            *ngIf="!isLoadingSearch && loadedSpaces.length > 0"
            [user]="user"
            (openSpaceEmitter)="onOpenSpace($event)"
            (openSpaceDetailsEmitter)="onOpenSpaceDetails($event)"
            [isFetchingSpaces]="isFetchingSpaces"
            [spaces]="loadedSpaces"
            [isScrolling]="isScrolling"
            [selectedSpace]="currentlyOpenedSpaceDetails$ | async"
          ></app-spaces-list>
          <div
            *ngIf="loadedSpaces.length === 0 && spaceSearchQuery && !isLoadingSearch"
            class="space-container-list-empty px-1"
          >
            {{
              'Sorry, we could not find any matching Spaces. Please try searching for another Space.'
                | translate
            }}
          </div>
          <div
            *ngIf="
              loadedSpaces.length === 0 &&
              !spaceSearchQuery &&
              !isLoadingSearch &&
              (filtersService.appliedFilters$ | async)
            "
            class="space-container-list-empty px-1"
          >
            {{ 'You do not have any Spaces within these conditions.' | translate }}
          </div>
          <div
            *ngIf="
              loadedSpaces.length === 0 &&
              spaceSearchQuery &&
              !isLoadingSearch &&
              (filtersService.appliedFilters$ | async) === null
            "
            class="space-container-list-empty px-1"
          >
            {{ "You don't have any Spaces as yet." | translate }}
          </div>
        </div>
      </div>
    </div>
    <app-spaces-details-view
      *ngIf="(currentlyOpenedSpaceDetails$ | async) && (uiService.isMobile | async) === false"
      [session]="currentlyOpenedSpaceDetails$ | async"
      (exitClick)="onCloseSpaceDetails()"
      [user]="user"
      (spacesListInviteUpdate)="spacesListInviteUpdate($event)"
      (cloneSpaceEventEmitter)="cloneSpace($event)"
      (deleteSpaceEmitter)="onDeleteSpace($event)"
      (spaceDetailsChangeEmitter)="updateSpace($event)"
      (changeSession)="onOpenSpaceDetails($event)"
      [class.spaces-details-view-closed]="!isSpaceDetailsOpen"
    ></app-spaces-details-view>
  </div>
</div>

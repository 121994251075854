import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GpuDetectorService {
  private _hasMajorPerformanceCaveat: boolean | null = null;

  /**
   * Gets a boolean indicating if the engine can be instantiated on a performant device (ie. if a webGL context can be found and it does not use a slow implementation)
   * ref: https://github.com/BabylonJS/Babylon.js/blob/master/packages/dev/core/src/Engines/thinEngine.ts#L6092
   */
  hasMajorPerformanceCaveat(): boolean {
    if (this._hasMajorPerformanceCaveat === null) {
      try {
        const tempcanvas = document.createElement('canvas') as any;
        tempcanvas.width = 1;
        tempcanvas.height = 1;
        const gl =
          tempcanvas.getContext('webgl', { failIfMajorPerformanceCaveat: true }) ||
          tempcanvas.getContext('experimental-webgl', {
            failIfMajorPerformanceCaveat: true,
          });

        this._hasMajorPerformanceCaveat = !gl;
      } catch (e) {
        this._hasMajorPerformanceCaveat = true;
      }
    }

    return this._hasMajorPerformanceCaveat;
  }
}

<div class="bg-light border-bottom cpt-16 cpb-12">
  <p class="text-center fw-6">{{ data?.title | translate }}</p>
</div>
<div class="cmb-32 cmt-32 cp-12 text-center w-100">
  <div *ngIf="data?.icon" class="d-flex justify-content-center cpb-8" [ngStyle]="data?.icon_style">
    <i class="material-icons">{{ data?.icon }}</i>
  </div>
  <p [ngStyle]="data?.content_style" innerHTML="{{ data?.content | translate }}">
    {{ data?.content | translate }}
  </p>
</div>
<div *ngIf="data?.checkboxText" class="d-flex justify-content-center align-items-center cmb-16">
  <input class="cmr-4" type="checkbox" (change)="checked = !checked" />
  {{ data?.checkboxText | translate }}
</div>
<div mat-dialog-actions class="d-flex justify-content-center cmb-16">
  <button mat-button class="btn btn-light" (click)="close(true)">
    <span class="material-icons-outlined">clear</span>{{ 'Cancel' | translate }}
  </button>
  <button
    *ngIf="data?.okButtonText"
    mat-button
    class="btn btn-primary text-white"
    (click)="close(false)"
    cdkFocusInitial
  >
    <span class="material-icons">done</span>{{ data?.okButtonText | translate }}
  </button>
</div>

<div class="iframe-dialog">
  <div class="iframe-dialog-header">
    <svg-icon
      *ngIf="data.modalConfig.icon"
      [icon]="data.modalConfig.icon"
      [width]="20"
      [height]="20"
    >
    </svg-icon>
    <div class="iframe-dialog-title">{{ data.modalConfig?.title }}</div>
    <div class="iframe-dialog-close" (click)="addIFrame()" data-name="iframe-dialog-close">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="iframe-dialog-body">
    <p class="iframe-description" *ngIf="data.modalConfig?.description">
      {{ data.modalConfig?.description }}
    </p>
    <div class="input-icons">
      <mat-icon matPrefix class="link-icon">link</mat-icon>
      <input
        class="iframe-dialog-input"
        matInput
        [(ngModel)]="url"
        [ngClass]="{ error: error !== UrlValidationError.NoError }"
      />
      <div *ngIf="error !== UrlValidationError.NoError" class="url-error">
        <mat-icon class="icon-error cmr-4">error_outline</mat-icon>
        <mat-error *ngIf="error === UrlValidationError.MalformedUrl"
          >{{ 'Please enter a valid web URL. ' | translate | uppercase }}
          <a
            target="_blank"
            href="https://intercom.help/pencil/en/articles/6591754-troubleshooting-iframe-loading-issues"
            >{{ 'Learn more' | translate | uppercase }}</a
          >
        </mat-error>
        <mat-error
          *ngIf="
            error === UrlValidationError.InvalidUrlResponse ||
            error === UrlValidationError.NotEmbeddable
          "
          >{{
            'The developer of this website has prevented it from being embedded in a Space. '
              | translate
              | uppercase
          }}
          <a
            target="_blank"
            href="https://intercom.help/pencil/en/articles/6591754-troubleshooting-iframe-loading-issues"
            >{{ 'Learn more' | translate | uppercase }}</a
          >
        </mat-error>
        <mat-error *ngIf="error === UrlValidationError.CustomError"
          >{{ data.modalConfig.errorMessage || 'An error has occured' | translate | uppercase }}
        </mat-error>
      </div>
    </div>
  </div>
  <div class="iframe-dialog-footer">
    <button
      [disabled]="url.length < 1 || isValidating"
      class="iframe-dialog-confirm"
      (click)="addIFrame(true)"
      data-name="iframe-dialog-confirm"
    >
      <mat-icon>add</mat-icon>{{ data.modalConfig?.buttonTitle ?? 'Import' | translate }}
    </button>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { SpaceRepository } from '../state/space.repository';
import { URLService } from './dynamic-url.service';
import { TelemetryService } from './telemetry.service';
import { UserService } from './user.service';

export const STALE_SESSION_ID = 'stale-session-id';

export enum HB_VM_STATE {
  Unknown,
  Error,
  Terminated,
  NotFound,
  Alive,
}

export interface HbSessionResponse {
  state: HB_VM_STATE;
  data?: {
    session_id: string;
    embed_url: string;
    admin_token: string;
    termination_date?: string;
    snapshot_session_id?: string;
  };
}

export interface WebViewerConfig {
  webgl: boolean;
  adblock: boolean;
  defaultUrl?: string;
  country?: string;
}

/*
Provide access to Hyperbeam (co-browser) functionality.
*/
@Injectable({
  providedIn: 'root',
})
export class HyperbeamService {
  constructor(
    private urlService: URLService,
    private http: HttpClient,
    private telemetry: TelemetryService,
    private spaceRepo: SpaceRepository,
    private userService: UserService,
  ) {}

  createHbSession$(
    existingSessionId: string | undefined,
    webViewerConfig: WebViewerConfig,
    checkForSessionTermination: boolean = false,
  ): Observable<HbSessionResponse> {
    const body = {
      userId: this.userService.userId,
      spaceId: this.spaceRepo.activeSpaceId,
      checkForSessionTermination,
      webgl: webViewerConfig.webgl,
      adblock: webViewerConfig.adblock,
      defaultUrl: webViewerConfig.defaultUrl,
      country: webViewerConfig.country,
    };
    if (existingSessionId) {
      return this.http.post<HbSessionResponse>(
        `${this.urlService.getDynamicUrl()}/hb/vm/${existingSessionId}`,
        body,
      );
    }
    return this.http.post<HbSessionResponse>(`${this.urlService.getDynamicUrl()}/hb/vm`, body);
  }

  async createHbSession(
    existingSessionId: string | undefined,
    webViewerConfig: WebViewerConfig,
    checkForSessionTermination = false,
  ) {
    const hbSessionResponse = await firstValueFrom(
      this.createHbSession$(existingSessionId, webViewerConfig, checkForSessionTermination),
    );
    if (
      hbSessionResponse &&
      hbSessionResponse.state === HB_VM_STATE.Alive &&
      hbSessionResponse.data
    ) {
      this.telemetry.event('Web Viewer VM Created', {
        sessionId: hbSessionResponse.data.session_id,
        spaceId: this.spaceRepo.activeSpaceId,
      });
    }
    return hbSessionResponse;
  }
}

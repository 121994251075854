import { Component, Input } from '@angular/core';
import { QuestionsService } from 'src/app/services/questions.service';
import { FlagsService, FLAGS } from 'src/app/services/flags.service';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FragmentCollection } from 'src/app/content/course/create/create.model';

@UntilDestroy()
@Component({
  selector: 'app-editor-toolbox',
  templateUrl: './editor-toolbox.component.html',
  styleUrls: ['./editor-toolbox.component.scss'],
})
export class EditorToolboxComponent {
  @Input() collection?: FragmentCollection;
  @Input() showRefer = false;

  selectedFormat;
  formulaSelected = false;
  tableEnabled = false;
  selectedPreset?: string;

  constructor(public questionsService: QuestionsService, public flagsService: FlagsService) {
    this.tableEnabled = this.flagsService.isFlagEnabled(FLAGS.AUTHORING_TABLES);
    this.questionsService.selectedFormat.pipe(untilDestroyed(this)).subscribe((format) => {
      this.selectedFormat = format;
    });
    this.questionsService.formulaSelected.pipe(untilDestroyed(this)).subscribe((res) => {
      this.formulaSelected = res;
    });
  }

  onClick(type: string, value?: string): void {
    this.selectedPreset = type;
    this.questionsService.operation.next({ type, value });
    this.questionsService.selectedFormat.next({
      ...this.selectedFormat,
      sizePresetActive: true,
    });
  }
}

<div
  id="sessions-modal-wrapper"
  class="border bg-white"
  [ngClass]="{ noSessions: allSessions?.length === 0 }"
>
  <app-analytics-header
    [noOfSessions]="totalRecords"
    [spaceTitle]="currentSpaceName"
    (closed)="closed.emit()"
  >
  </app-analytics-header>
  <mat-drawer-container id="sessions-container" *ngIf="allSessions?.length > 0">
    <!-- List of sessions -->
    <mat-drawer id="sessions-list" mode="side" [disableClose]="true" opened>
      <mat-drawer-content id="sidebar-content" #sidebarContent>
        <app-session-analytics-sidebar
          [allSessions]="allSessions"
          [fetchingMoreSessions]="fetchingMoreSessions"
          (currentSession)="handleCurrentSession($event)"
        ></app-session-analytics-sidebar>
      </mat-drawer-content>
    </mat-drawer>

    <mat-drawer-content class="mat-elevation-z8" id="session-insights">
      <app-session-insights-header
        [currentSession]="currentSession"
        [enterpriseSpace]="!!(currentSpace?.institution || currentSpace?.institutionID)"
        [hidden]="!currentSession"
        (sessionTitleChanged)="handleSessionTitleChanged($event)"
        (endSession)="handleEndSession($event)"
      ></app-session-insights-header>
      <div *ngIf="currentSession">
        <ui-header class="border-bottom header events-tab">
          <div class="position-relative flex-1 h-100">
            <nav class="navbar navbar-expand-lg cp-0 d-sm-block">
              <ul class="navbar-nav flex-row">
                <li
                  class="nav-item hover"
                  [class.active]="currentTab === AnalyticTab.ANALYTICS"
                  (click)="currentTab = AnalyticTab.ANALYTICS"
                >
                  <a class="nav-link">{{ AnalyticTab.ANALYTICS | translate }}</a>
                </li>
                <li
                  *ngIf="cloudRecordingEnabled"
                  class="nav-item hover"
                  [class.active]="currentTab === AnalyticTab.PLAYBACK"
                  (click)="currentTab = AnalyticTab.PLAYBACK"
                >
                  <a class="nav-link">{{ AnalyticTab.PLAYBACK | translate }}</a>
                </li>
                <li
                  class="nav-item hover"
                  [class.active]="currentTab === AnalyticTab.EVENTS"
                  (click)="currentTab = AnalyticTab.EVENTS"
                >
                  <a class="nav-link">{{ AnalyticTab.EVENTS | translate }}</a>
                </li>
              </ul>
            </nav>
          </div>
        </ui-header>
      </div>
      <!-- Session Insights -->
      <app-session-insights-table
        [currentSession]="currentSession"
        [hidden]="!currentSession || currentTab !== AnalyticTab.ANALYTICS"
        (refetchSession)="fetchSession(currentSession!.sessionId)"
      ></app-session-insights-table>
      <app-analytics-events-table
        [currentSessionId]="currentSession?.sessionId"
        [hidden]="!currentSession || currentTab !== AnalyticTab.EVENTS"
      ></app-analytics-events-table>
      <app-analytics-recording-table
        [currentSession]="currentSession"
        [hidden]="!currentSession || currentTab !== AnalyticTab.PLAYBACK"
      ></app-analytics-recording-table>
      <div *ngIf="!currentSession" class="no-session-message">
        {{ 'Select a session' | translate }}
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
  <div *ngIf="allSessions?.length === 0 && !fetchingMoreSessions" class="no-session-message">
    {{ 'No sessions found' | translate }}
  </div>
  <div *ngIf="allSessions.length === 0 && fetchingMoreSessions" class="no-session-message">
    <mat-spinner [strokeWidth]="6"></mat-spinner>
  </div>
</div>

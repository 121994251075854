export const DEFAULT_CAM_ID_KEY = 'defaultCameraId';
export const DEFAULT_MIC_ID_KEY = 'defaultMicId';

export const VB_WIDTH = 640;
export const VB_HEIGHT = 360;
export const VB_FRAME_RATE = 15;

export const VIDEO_STREAM_CAPTURE_WIDTH = 640;
export const VIDEO_STREAM_CAPTURE_HEIGHT = 360;

export const DEFAULT_SCREEN_SHARE_VIDEO_WIDTH = 1920;
export const DEFAULT_SCREEN_SHARE_VIDEO_HEIGHT = 1080;
// kps
export const DEFAULT_SCREEN_SHARE_MAX_BITRATE = 1200;
export const DEFAULT_SCREEN_SHARE_MAX_FPS = 15;
export const DEFAULT_SCREEN_SHARE_CODEC = 'vp9';

export enum DEVICE_STATE {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const VIDEO_CALL_BUTTONS_TOGGLING_TIMEOUT = 15000;
export const SPEAKING_INDICATOR_TIMEOUT = 5000;

export enum DeviceErrorType {
  PERMISSION_DENIED = 'permission-denied',
  PERMISSION_DENIED_BY_SYSTEM = 'permission-denied-by-system',
  NOT_FOUND = 'not-found',
  NO_INPUT_DETECTED = 'no-input-detected',
  GET_USER_MEDIA_TIMEOUT = 'get-user-media-timeout',
  DEFAULT_ERROR = 'default',
}

export interface DeviceState {
  state: boolean;
  isToggable: boolean;
  isGettingStream: boolean;
  hasError: boolean;
  errorType?: DeviceErrorType;
}

export const DEFAULT_DEVICE_STATE: DeviceState = {
  state: false,
  isToggable: false,
  isGettingStream: true,
  hasError: false,
};

export interface OpenDeviceSelectionParams {
  returnBackToJoinCallModal?: boolean;
  callbackAfterModalClosed?: Function;
  deviceErrorType?: DeviceErrorType;
}

export interface JoinCallDevicesState {
  unmuteCam: boolean;
  unmuteMic: boolean;
}

export enum DeviceTogglingStatus {
  DONE,
  TIMEOUT,
  LOADING,
}

export enum DeviceToggleErrorType {
  CAM,
  MIC,
  CAM_AND_MIC,
}

export enum CallRelatedModalAction {
  OPEN_SPACE_DEVICE_MODAL,
  CLOSE_SPACE_DEVICE_MODAL,
  OPEN_VIRTUAL_BACKGROUND_MODAL,
  NOT_PROCEEDING_WITH_THE_CALL,
  START_JOINING_THE_CALL,
}

<div class="bg-light border-bottom cpt-16 cpb-12">
  <p class="text-center title fw-6">{{ data?.title | translate }}</p>
</div>

<div class="upload-container w-100">
  <div class="drop-container w-100">
    <div
      [ngStyle]="{ background: isFileHovering ? '#f8f8f8' : '#fff' }"
      class="drop-zone d-flex justify-content-center align-items-center flex-wrap h-100"
    >
      <div class="ngx-drop-zone">
        <ngx-file-drop
          (onFileDrop)="onDrop($event)"
          (onFileOver)="onFileOver()"
          (onFileLeave)="onFileLeave()"
          [accept]="acceptFiles"
          dropZoneClassName="drop-area"
          contentClassName="drop-area"
        ></ngx-file-drop>
      </div>

      <div class="w-100 text-center">
        {{ 'Drag &amp; drop files here' | translate }} <br />
        <span class="text-black-50">{{ 'or' | translate }}</span> <br />
        <div class="attach-zone cpt-24">
          <input multiple type="file" [accept]="acceptFiles" required (change)="readFile($event)" />
          <button mat-flat-button color="primary">
            <span class="material-icons"> attach_file </span> {{ 'Browse files' | translate }}
          </button>
        </div>
      </div>
      <p *ngIf="files.length > 0">
        {{
          (files[0].name | fileNameFormat) +
            (files.length > 1 ? ', +' + (files.length - 1).toString() + ' more' : '')
        }}
      </p>
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <button
      class="btn btn-light cmr-sm-16 cmx-0 cmt-16 cmt-sm-0 fw-6 d-flex align-items-center"
      mat-dialog-close
    >
      <i class="material-icons cmr-8 font-18">close</i> {{ 'Cancel' | translate }}
    </button>
    <button
      class="btn btn-primary text-white fw-6 d-flex align-items-center"
      [disabled]="!files.length"
      [mat-dialog-close]="files"
      cdkFocusInitial
    >
      <i class="material-icons cmr-8 font-18">arrow_forward</i>
      {{ 'Next' | translate }}
    </button>
  </div>
</div>

import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appTextareaAutoResize]',
})
export class TextareaAutosizeDirective {
  @Input() minRows = 1;
  @Input() maxRows = 3;

  private offsetHeight = 0;
  private readonly avgLineHeight = 17.6;

  constructor(private elementRef: ElementRef) {}

  @HostListener('input')
  onInput(): void {
    if (this.offsetHeight <= 0) {
      this.offsetHeight = this.input.scrollHeight;
    }

    this.input.rows = this.minRows;

    const rows = Math.floor((this.input.scrollHeight - this.offsetHeight) / this.avgLineHeight);

    const rowsCount = this.minRows + rows;

    if (rowsCount <= this.maxRows && rowsCount >= this.minRows) {
      this.input.rows = rowsCount;
      return;
    }

    this.input.rows = rowsCount > this.maxRows ? this.maxRows : this.minRows;
  }

  get input(): HTMLTextAreaElement {
    return this.elementRef.nativeElement;
  }
}

import { Injectable, NgZone } from '@angular/core';
import * as FS from '@fullstory/browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as Sentry from '@sentry/browser';
import { Mutex } from 'async-mutex';
import { Observable, Subject, distinctUntilChanged } from 'rxjs';
import { SessionAuth } from '../models/session-sync';
import { User } from '../models/user';
import { enterZone } from '../utilities/ZoneUtils';

import { HeartbeatSource, WebSocketEventType } from '../common/interfaces/sync-service-interface';
import { ParticipantPresenceStatus, UserAction, getHash } from '../common/utils/presence.util';
import { PresenceRepository } from '../state/presence.repository';
import { SpaceRepository } from '../state/space.repository';
import {
  TemporaryUserMetadataEntryRedis,
  TemporaryUserMetadataRepositoryService,
} from '../state/temporary-user-metadata-repository.service';
import { WaitingRoomRepoService } from '../sessions/session/request-access/waiting-room/waiting-room-repo.service';
import { FLAGS, FlagsService } from './flags.service';
import { PresenceMonitoringService } from './presence-monitoring.service';
import { ProviderStateService } from './provider-state.service';
import { RealtimeSpaceService } from './realtime-space.service';
import { TimerService } from './timer.service';
import { PresenceRoomInfo } from './presence-provider';
import { UserIdleService } from './user-idle.service';
import { UserService } from './user.service';

export interface PresenceData {
  context: PresenceType;
  spaceId: string;
  contextType: ContextTypeEnum;
  userId: string;
  uniqueHash: string;
  breakoutRoomId?: string;
  version: string;
  frameId: string;
  timestamp?: Date;
}

export interface JoinRoomRequest {
  sessionsAuth: SessionAuth[];
  context: PresenceType;
  version: string;
}

export interface QueryRoomsRequest {
  presenceInfos: Array<PresenceRoomInfo>;
  context: PresenceType;
}
export interface HeartbeatEvent {
  source: HeartbeatSource;
  data: unknown;
}

export enum ContextTypeEnum {
  SPACE = 'space',
  CALL = 'call',
  RECORDING = 'recording',
  LMS = 'lms',
  WAITING_ROOM = 'waiting-room',
}

export interface LeaveRoomRequest {
  spacesIds: Array<string>;
  presenceInfos: Array<PresenceRoomInfo>;
  context: PresenceType;
  version: string;
}

export enum PresenceType {
  LMS_PRESENCE = 'LMS-presence',
  SPACE_PRESENCE = 'space-presence',
}

export interface PresenceRoom {
  spaceId: string;
  presence: Array<PresenceData>;
  timestamp: number;
  type: PresenceType;
  serverId: string;
  usersTemporaryMetadata: Array<TemporaryUserMetadataEntryRedis>;
}

export enum PresenceParticipantStatusType {
  joined = 'joined',
  left = 'left',
}

export interface IPresence {
  /**
   Validates an array of PresenceInfo objects containing space IDs and breakout room IDs,
   and authenticates each space ID before communicating with the server to join a WebSocket
   room using Socket.IO based on the given presence type (either "space" or "LMS").
   If the room join is successful, the function returns a Promise that resolves to true.
   If the room join fails, the function rejects the Promise with an error.
   @param rooms An array of PresenceInfo objects containing space IDs and breakout room IDs
   @param type A string representing the presence type ("space" or "LMS")
   @returns A Promise that resolves to true if the room join was successful, or rejects with an error if it fails
   */
  join(rooms: Array<PresenceRoomInfo>, type: PresenceType): Promise<boolean>;

  /**
   Sends a request to the server to leave the current WebSocket room, and clears any associated data.
   This function does the opposite of joining a room and does not perform any validation.
   it does not return any value.
   @param rooms An array of PresenceInfo objects containing space IDs and breakout room IDs
   @param type A string representing the presence type ("space" or "LMS")
   */
  leave(rooms: Array<PresenceRoomInfo>, type: PresenceType): void;

  /**
   Sends a WebSocket event to the server to query room presence data from Redis, based on the given presence type (either "space" or "LMS").
   This function accepts an object containing the payload data, including space IDs and breakout room IDs.
   The retrieved data is set in the client store entity.
   @param payload An object containing the payload data, including space IDs and breakout room IDs
   @param type A string representing the presence type ("space" or "LMS")
   @returns void
   */
  query(rooms: Array<PresenceRoomInfo>, type: PresenceType);

  /**
   Generates presence data based on the given input, and sends the data to the WebSocket server to store a new key in Redis that represents the user's active presence.
   It also publishes a WebSocket event inside the socket room to inform other clients who have joined the room with the recent presence status in Redis.
   @param spaceId A string representing the space ID
   @param type The presence type ("online", "offline", "idle", "dnd")
   @param contextType A ContextTypeEnum indicating the context type
   @param breakoutRoomId Optional. A string representing the breakout room ID.
   @param retry Optional. A number indicating the number of retry attempts in case of failure.
   @returns void
 */
  set(
    spaceId: string,
    type: PresenceType,
    contextType: ContextTypeEnum,
    breakoutRoomId?: string,
    retry?: number,
    sendEvent?: boolean,
  ): void;

  /**
   Generates the key data based on the given input, and sends a WebSocket event to the server to remove the user's presence key from Redis.
   It also publishes a WebSocket event inside the socket room to inform other clients who have joined the room with the recent presence status in Redis.
   @param spaceId A string representing the space ID
   @param type The presence type ("online", "offline", "idle", "dnd")
   @param contextType A ContextTypeEnum indicating the context type
   @param breakoutRoomId Optional. A string representing the breakout room ID.
   @param retry Optional. A number indicating the number of retry attempts in case of failure.
   @returns void
 */
  remove(
    spaceId: string,
    type: PresenceType,
    contextType: ContextTypeEnum,
    breakoutRoomId?: string,
    publish?: boolean,
    retry?: number,
  ): void;

  /**
   Sends a WebSocket event to the server to remove all of the user's presence keys for a given space ID or breakout room.
   It generates the necessary key data based on the input and sends the WebSocket event to remove the keys from Redis.
   It also publishes a WebSocket event inside the socket room to inform other clients who have joined the room with the recent presence status in Redis.
   @param spaceId A string representing the space ID
   @param type The presence type ("online", "offline", "idle", "dnd")
   @param breakoutRoomId Optional. A string representing the breakout room ID.
   @returns void
 */
  disconnect(spaceId: string, type: PresenceType, breakoutRoomId?: string): void;
}

export const WAITING_ROOM_SUFFIX: string = 'waiting-room';
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class RedisPresenceV2 implements IPresence {
  get participantPresenceStatus$(): Observable<ParticipantPresenceStatus> {
    return this.participantPresenceStatusSubject.pipe(enterZone(this.zone));
  }

  get framePresenceStatus$(): Observable<{ [key: string]: string[] }> {
    return this.framePresenceStatusSubject.pipe(enterZone(this.zone));
  }

  private user!: User;

  private currentSpaceId: string | undefined;
  private currentBreakoutRoomId: string | undefined;

  private heartbeatInterval!: NodeJS.Timer;
  private activeHeartbeatTypes: Array<ContextTypeEnum> = [];

  private readonly heartbeatRate = 3000; // Milliseconds
  private readonly WB_SERVER_ACK_TIMEOUT = 5000;

  private readonly USER_IDLE_THREASHOLD = 300; // 5 minutes

  private mutex = new Mutex();

  private participantPresenceStatusSubject: Subject<ParticipantPresenceStatus> = new Subject();
  private framePresenceStatusSubject: Subject<{ [key: string]: string[] }> = new Subject();

  private checkPresenceIdleState = true;

  private currentJoinedPresenceRooms_v0 = new Map<PresenceType, Array<string>>([
    [PresenceType.SPACE_PRESENCE, []],
  ]);

  private currentJoinedPresenceRooms = new Map<PresenceType, Array<PresenceRoomInfo>>([
    [PresenceType.SPACE_PRESENCE, []],
  ]);

  private breakoutRoomsEnabled = true;

  constructor(
    private zone: NgZone,
    private spaceRepo: SpaceRepository,
    private userService: UserService,
    private realtimeSpaceService: RealtimeSpaceService,
    private providerStateService: ProviderStateService,
    private timerService: TimerService,
    private userIdleService: UserIdleService,
    private flagsService: FlagsService,
    private presenceRepo: PresenceRepository,
    private presenceMonitoringService: PresenceMonitoringService,
    private temporaryUserMetadataRepositoryService: TemporaryUserMetadataRepositoryService,
    private waitingRoomRepoService: WaitingRoomRepoService,
  ) {
    // run the following tasks outside of angular to zone to avoid unnecessary change detection cycles.
    this.zone.runOutsideAngular(() => {
      // handle initializing Websocket client, listening to events, and hearbeat.
      this.setupWSConnection();

      // disconnected and reconnected user base on idle state
      if (this.checkPresenceIdleState) {
        this.handleUserIdleness();
      }
    });

    this.breakoutRoomsEnabled = this.flagsService.isFlagEnabled(FLAGS.BREAKOUT_ROOMS);

    this.spaceRepo.activeSpace$.pipe(untilDestroyed(this)).subscribe(async (val) => {
      if (val === undefined) {
        // user exit from session
        if (this.currentSpaceId !== undefined) {
          await this.terminateHeartbeat();
          this.currentSpaceId = undefined;
        }
      } else {
        // user join session for the first time => start heart
        // handles the case when user is directed from waiting room
        if (
          this.currentSpaceId === undefined ||
          this.currentBreakoutRoomId?.includes(WAITING_ROOM_SUFFIX)
        ) {
          this.zone.runOutsideAngular(async () => {
            await this.startHeartbeat(PresenceType.SPACE_PRESENCE);
          });
        }
        this.currentSpaceId = val._id;
        this.currentBreakoutRoomId = this.breakoutRoomsEnabled ? val.currentRoomUid : undefined;
      }
    });

    this.realtimeSpaceService.service.updateUserPresence$
      .pipe(untilDestroyed(this))
      .subscribe(async (action) => {
        FS.log('info', `Received network update: ${action}`);
        switch (action) {
          case 'reconnected':
            // Get latest presence on reconnection
            this.restoreClientPresence();
            await this.mutex.runExclusive(async () => {
              await this.query(
                this.getCurrentJoinedPresenceRooms(PresenceType.SPACE_PRESENCE),
                PresenceType.SPACE_PRESENCE,
              );
            });
            break;
          case 'disconnected':
            this.onClientDisconnection();
            break;
          default:
            break;
        }
      });

    this.spaceRepo.activeSpaceSelectedBoardUid$
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(() => {
        if (!this.spaceRepo.activeSpaceId || !this.spaceRepo.activeSpaceCurrentRoomUid) {
          return;
        }
        const presenceData = this.createPresenceData(
          this.spaceRepo.activeSpaceId,
          PresenceType.SPACE_PRESENCE,
          ContextTypeEnum.SPACE,
          this.spaceRepo.activeSpaceCurrentRoomUid,
        );
        this.sendPresenceEventWithTimeout(
          presenceData,
          WebSocketEventType.UPDATE_FRAME_PRESENCE,
          3,
          () => {
            // This callback function is passed, so that latest presence data is fetched before sending retry attempt
            if (this.spaceRepo.activeSpaceId && this.spaceRepo.activeSpaceCurrentRoomUid) {
              return this.createPresenceData(
                this.spaceRepo.activeSpaceId,
                PresenceType.SPACE_PRESENCE,
                ContextTypeEnum.SPACE,
                this.spaceRepo.activeSpaceCurrentRoomUid,
              );
            }
            return undefined;
          },
        );
      });

    this.userService.user
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged((a, b) => a?.user._id === b?.user._id),
      )
      .subscribe((data) => {
        if (data && data?.user) {
          this.user = data.user;
        }
      });

    this.waitingRoomRepoService.waitingRoom$
      .pipe(distinctUntilChanged())
      .subscribe(
        ([spaceID, waitingRoomID]) =>
          spaceID && waitingRoomID && this.setSpaceAndRoom(spaceID, waitingRoomID),
      );
  }

  join_v0(rooms: string[], type: PresenceType): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (rooms.length > 0) {
        const sessionsWithAuth = this.realtimeSpaceService.service.populateSessionAuth(rooms);
        if (sessionsWithAuth) {
          const joinRoomRequest = {
            sessionsAuth: sessionsWithAuth,
            context: type,
            version: 'v2',
          } as JoinRoomRequest;

          this.sendPresenceEventWithTimeout(
            joinRoomRequest,
            WebSocketEventType.JOIN_PRESENCE_ROOMS,
            3,
          )
            .then((res) => resolve(true))
            .catch((err) => reject(err));

          this.setCurrentJoinedPresenceRooms_v0(type, [...rooms]);
        } else {
          Sentry.captureException(
            'Unable to populate sessions auth data on joining presence rooms',
          );
          reject('Unable to populate sessions auth data on joining presence rooms');
        }
      }
    });
  }

  join(rooms: PresenceRoomInfo[], type: PresenceType): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (rooms.length > 0) {
        const spacesIds = rooms.map((x) => x.spaceId);
        const sessionsWithAuth = this.realtimeSpaceService.service.populateSessionAuth(spacesIds);
        if (sessionsWithAuth) {
          const breakoutRoomsSessionAuth = this.populateBreakoutRoomSessionAuth(
            sessionsWithAuth,
            rooms,
          );

          const joinRoomRequest = {
            sessionsAuth: breakoutRoomsSessionAuth,
            context: type,
            version: 'v2',
          } as JoinRoomRequest;

          this.sendPresenceEventWithTimeout(
            joinRoomRequest,
            WebSocketEventType.JOIN_PRESENCE_ROOMS,
            3,
          )
            .then((res) => resolve(true))
            .catch((err) => reject(err));

          this.setCurrentJoinedPresenceRooms(type, [...rooms]);
        } else {
          Sentry.captureException(
            'Unable to populate sessions auth data on joining presence rooms',
          );
          reject('Unable to populate sessions auth data on joining presence rooms');
        }
      }
    });
  }

  query_v0(rooms: string[], context: PresenceType): Promise<Array<PresenceRoom>> {
    // bypass room listener's logic if client is reconnecting
    if (rooms.length > 0) {
      const joinRoomRequest = {
        context: context,
        presenceInfos: rooms.map((x) => ({ spaceId: x } as PresenceRoomInfo)),
      } as QueryRoomsRequest;

      const presenceRoomData = this.sendPresenceEventWithTimeout(
        joinRoomRequest,
        WebSocketEventType.QUERY_PRESENCE_DATA,
        3,
      );

      presenceRoomData
        .then((res: Array<PresenceRoom>) => {
          this.handleQueryPresenceRoomsResponse(res, context);
        })
        .catch((err) => {
          Sentry.captureException('Unable to fetch query presence data');
        });

      return presenceRoomData;
    } else {
      return Promise.resolve([]);
    }
  }

  query(rooms: PresenceRoomInfo[], context: PresenceType): Promise<Array<PresenceRoom>> {
    // bypass room listener's logic if client is reconnecting
    if (rooms.length > 0) {
      const joinRoomRequest = {
        context: context,
        presenceInfos: rooms,
      } as QueryRoomsRequest;

      const presenceRoomData = this.sendPresenceEventWithTimeout(
        joinRoomRequest,
        WebSocketEventType.QUERY_PRESENCE_DATA,
        3,
      );

      presenceRoomData
        .then((res: Array<PresenceRoom>) => {
          this.handleQueryPresenceRoomsResponse(res, context);
        })
        .catch((err) => {
          Sentry.captureException('Unable to fetch query presence data');
        });

      return presenceRoomData;
    } else {
      return Promise.resolve([]);
    }
  }

  leave_v0(rooms: Array<string>, context: PresenceType): void {
    if (rooms.length > 0) {
      const leaveRoomRequest: LeaveRoomRequest = {
        context: context,
        spacesIds: rooms,
        presenceInfos: [],
        version: 'v2',
      };

      this.sendPresenceEventWithTimeout(
        leaveRoomRequest,
        WebSocketEventType.LEAVE_PRESENCE_ROOMS,
        3,
      ).then((res) => {
        this.presenceRepo.clearPresenceRooms(
          rooms.map((x) => ({ spaceId: x } as PresenceRoomInfo)),
        );
      });

      const currentJoinedRooms = this.getCurrentJoinedPresenceRooms_v0(context);

      this.setCurrentJoinedPresenceRooms_v0(
        context,
        currentJoinedRooms.filter((x) => !rooms.includes(x)),
      );
    }
  }

  leave(rooms: Array<PresenceRoomInfo>, context: PresenceType): void {
    if (rooms.length > 0) {
      const leaveRoomRequest: LeaveRoomRequest = {
        context: context,
        spacesIds: [],
        presenceInfos: rooms,
        version: 'v2',
      };

      this.sendPresenceEventWithTimeout(
        leaveRoomRequest,
        WebSocketEventType.LEAVE_PRESENCE_ROOMS,
        3,
      ).then((res) => {
        this.presenceRepo.clearPresenceRooms(rooms);
      });

      const currentJoinedRooms = this.getCurrentJoinedPresenceRooms(context);
      this.setCurrentJoinedPresenceRooms(
        context,
        currentJoinedRooms.filter((x) => !rooms.includes(x)),
      );
    }
  }

  set(
    spaceId: string,
    type: PresenceType,
    contextType: ContextTypeEnum,
    breakoutRoomId?: string,
    retry = 3,
    sendEvent: boolean = false,
  ): void {
    const presenceData = this.createPresenceData(spaceId, type, contextType, breakoutRoomId);
    this.setWithCustomData(presenceData, retry, sendEvent);
  }

  public setWithCustomData(presenceData: PresenceData, retry = 3, sendEvent: boolean = false) {
    if (
      !this.waitingRoomRepoService.isCurrentlyWaitingRoomActive() &&
      presenceData.contextType === ContextTypeEnum.WAITING_ROOM
    ) {
      this.removeHeartbeats(ContextTypeEnum.WAITING_ROOM);
      return;
    }

    // only rely on frame change to set/update presence
    // Client does not have current frame id on load, so better to rely only on frame change event
    // + only using this for cases where no active space yet or no frame id is set
    if (sendEvent) {
      this.sendPresenceEventWithTimeout(presenceData, WebSocketEventType.SET_PRESENCE, retry);
    }

    this.addToLocalPresence(presenceData);
    this.addHeartbeats(presenceData.contextType);
  }

  remove(
    spaceId: string,
    type: PresenceType,
    contextType: ContextTypeEnum,
    breakoutRoomId?: string,
    publish = true,
    retry = 3,
  ): void {
    const presenceData = this.createPresenceData(spaceId, type, contextType, breakoutRoomId);

    this.removeWithCustomData(presenceData, retry, publish);
  }

  public removeWithCustomData(
    presenceData: PresenceData,
    retry = 3,
    publish: boolean = true,
    isRemovingOtherUsers: boolean = false,
  ) {
    if (!isRemovingOtherUsers) {
      this.removeHeartbeats(presenceData.contextType);
    }

    this.removeFromLocalPresence(presenceData);

    this.sendPresenceEventWithTimeout(
      { ...presenceData, publish },
      WebSocketEventType.REMOVE_PRESENCE,
      retry,
    );
  }

  disconnect(spaceId: string, type: PresenceType, breakoutRoomId?: string): void {
    const presenceData = this.createPresenceData(
      spaceId,
      type,
      ContextTypeEnum.SPACE,
      breakoutRoomId,
    );
    this.removeHeartbeats(ContextTypeEnum.CALL, ContextTypeEnum.SPACE);
    this.removeFromLocalPresence(presenceData);
    this.sendPresenceEventWithTimeout(presenceData, WebSocketEventType.USER_DISCONNECTED, 0);
  }

  // terminate heart beat is handeled by default , only call it in case active space is not set yet
  public async terminateHeartbeat(): Promise<void> {
    await this.timerService.clearInterval(this.heartbeatInterval);
    this.removeHeartbeats(
      ContextTypeEnum.SPACE,
      ContextTypeEnum.CALL,
      ContextTypeEnum.WAITING_ROOM,
    );
  }

  public createPresenceData(
    spaceId: string,
    context: PresenceType,
    contextType: ContextTypeEnum,
    breakoutRoomId?: string,
    userId?: string,
  ): PresenceData {
    const useIDAsHash = contextType === ContextTypeEnum.WAITING_ROOM;
    const user_id = userId ?? this.user._id;

    const presenceData: PresenceData = {
      context: context,
      spaceId: spaceId,
      contextType: contextType,
      userId: user_id,
      uniqueHash: useIDAsHash ? user_id : this.userService.userUniqueHash,
      breakoutRoomId: breakoutRoomId,
      version: 'v2',
      frameId: this.spaceRepo.activeSpaceSelectedBoardUid ?? '',
    };

    return presenceData;
  }

  private handleQueryPresenceRoomsResponse(
    rooms: Array<PresenceRoom>,
    context: PresenceType,
  ): void {
    rooms.forEach((room: PresenceRoom) => {
      this.handlePresenceUpdateEvent(room);
      const roomHash = getHash(room.spaceId, context);
      this.presenceRepo.setPresenceState({
        hash: roomHash,
        presenceData: room.presence,
        timestamp: room.timestamp,
      });
    });
  }

  private setupWSConnection() {
    this.listenToWSEvents();
  }

  private listenToWSEvents(): void {
    this.realtimeSpaceService.service.presenceUpdate$
      .pipe(untilDestroyed(this))
      .subscribe(async (updates: Array<PresenceRoom>) => {
        FS.log('info', `Received presence update: ${JSON.stringify(updates)}`);
        updates.forEach((update) => {
          this.handlePresenceUpdateEvent(update);
        });
      });
  }

  private handlePresenceUpdateEvent(update: PresenceRoom) {
    // Ignore update if user is in a space & empty presence is received
    const isValidPresenceUpdate = this.isValidPresenceUpdate(update);
    if (!isValidPresenceUpdate) {
      return;
    }

    // Get current space presence data
    const currentRoomPresenceData =
      this.getPresenceActivityValue(
        update.spaceId,
        update.type,
        ContextTypeEnum.SPACE,
        this.spaceRepo.activeSpaceCurrentRoomUid,
      ) ?? [];
    const currentRoomUsersSet = new Set(currentRoomPresenceData.map((x) => x.userId));
    const currentSpacePresence = this.presenceRepo.getCurrentSpacePresenceData(update.spaceId);
    const currentSpaceUsersSet = new Set(currentSpacePresence.map((x) => x.userId));

    // Set state with the new updated presence
    this.setPresenceActivity(update.spaceId, update.presence, update.type, update.timestamp);

    if (update.spaceId !== this.spaceRepo.activeSpaceId) {
      return;
    }

    this.temporaryUserMetadataRepositoryService.setNewFullState(update);

    // Create Array<PresenceData> to hold updated space presence
    const updatedSpacePresence = update.presence.filter(
      (x) => x.contextType === ContextTypeEnum.SPACE,
    );
    const updatedRoomPresence = updatedSpacePresence.filter(
      (x) => x.breakoutRoomId === this.spaceRepo.activeSpaceCurrentRoomUid,
    );

    // Create set of userIds for faster computation when filtering in the remaining operations
    const updatedSpaceUsersSet = new Set(updatedSpacePresence.map((x) => x.userId));
    const updatedRoomUsersSet = new Set(updatedRoomPresence.map((x) => x.userId));

    // Get users which have joined space/rooms
    const usersJoinedSpacePresenceData = updatedSpacePresence.filter(
      (x) => !currentSpaceUsersSet.has(x.userId),
    );
    const usersJoinedSpaceSet = new Set(usersJoinedSpacePresenceData.map((x) => x.userId));
    const usersJoinedRoomPresenceData = updatedRoomPresence.filter(
      (x) => !currentRoomUsersSet.has(x.userId) && !usersJoinedSpaceSet.has(x.userId),
    );

    // Get users which have left space/rooms
    const usersLeftSpacePresenceData = currentSpacePresence.filter(
      (x) => !updatedSpaceUsersSet.has(x.userId),
    );
    const usersLeftSpaceSet = new Set(usersLeftSpacePresenceData.map((x) => x.userId));
    const usersLeftRoomAndSpacePresenceData = currentRoomPresenceData.filter(
      (x) => !updatedRoomUsersSet.has(x.userId),
    );
    const usersLeftRoomPresenceData = usersLeftRoomAndSpacePresenceData.filter(
      (x) => !usersLeftSpaceSet.has(x.userId),
    );

    FS.log(
      'info',
      `Applied presence update: ${JSON.stringify([
        this.presenceRepo.getPresenceDataValue(
          update.spaceId,
          PresenceType.SPACE_PRESENCE,
          ContextTypeEnum.SPACE,
          this.spaceRepo.activeSpaceCurrentRoomUid,
        ),
        this.presenceRepo.getPresenceDataValue(
          update.spaceId,
          PresenceType.SPACE_PRESENCE,
          ContextTypeEnum.CALL,
          this.spaceRepo.activeSpaceCurrentRoomUid,
        ),
      ])}`,
    );

    // Handle user join/leave space/room
    usersJoinedRoomPresenceData.forEach((presence) =>
      this.handleParticipantPresenceStatusEvent(presence, UserAction.JOIN_ROOM),
    );
    usersJoinedSpacePresenceData.forEach((presence) =>
      this.handleParticipantPresenceStatusEvent(presence, UserAction.JOIN_SPACE),
    );
    usersLeftRoomPresenceData.forEach((presence) =>
      this.handleParticipantPresenceStatusEvent(presence, UserAction.LEAVE_ROOM),
    );
    usersLeftSpacePresenceData.forEach((presence) =>
      this.handleParticipantPresenceStatusEvent(presence, UserAction.LEAVE_SPACE),
    );

    this.presenceMonitoringService.notifyPresenceUpdate(
      this.currentSpaceId,
      this.currentBreakoutRoomId,
      update,
      usersLeftSpacePresenceData,
      usersJoinedSpacePresenceData,
    );
    const framePresence = this.mapPresenceDataToFramePresence(updatedRoomPresence);
    this.framePresenceStatusSubject.next(framePresence);
  }

  private isValidPresenceUpdate(update: PresenceRoom): boolean {
    // Ignore update if user is in a space & empty presence is received
    if (
      this.currentSpaceId &&
      update.spaceId === this.currentSpaceId &&
      update.presence.length === 0
    ) {
      return false;
    }

    // Ignore update if a repo already has a newer update
    if (!this.presenceRepo.isLatestUpdate(update)) {
      return false;
    }

    // Lastly, check if it's relevant update
    if (this.currentSpaceId) {
      return update.spaceId === this.currentSpaceId;
    } else {
      return Boolean(
        this.currentJoinedPresenceRooms
          .get(PresenceType.SPACE_PRESENCE)
          ?.find((x) => x.spaceId === update.spaceId),
      );
    }
  }

  private addToLocalPresence(data: PresenceData): void {
    this.presenceRepo.updatePresence(data);
  }

  private removeFromLocalPresence(data: PresenceData): void {
    this.presenceRepo.removePresence(data);
  }

  /**
   * Handle the presence status of a participant in a chat or video call.
   */
  private handleParticipantPresenceStatusEvent(data: PresenceData, userAction: UserAction): void {
    // Get the presence activity value of the participant.
    const presenceActivity = this.presenceRepo.getCurrentSpacePresenceData(data.spaceId);

    const localPresence = presenceActivity?.find(
      (activity) => activity.uniqueHash === this.userService.userUniqueHash,
    );
    if (
      !localPresence ||
      data.userId === this.user._id ||
      !localPresence.timestamp ||
      !data.timestamp ||
      (userAction === UserAction.JOIN_SPACE && data.timestamp < localPresence.timestamp)
    ) {
      return;
    }

    // Emit a presence status event.
    const presenceStatus: ParticipantPresenceStatus = {
      userAction,
      userId: data.userId,
      breakoutRoomId: data.breakoutRoomId!,
      spaceId: data.spaceId,
    };
    FS.log('info', `Emitting event: ${JSON.stringify(presenceStatus)}`);
    this.participantPresenceStatusSubject.next(presenceStatus);
  }

  // start heart beat is handeled by default , only call it in case active space is not set yet
  public async startHeartbeat(type: PresenceType): Promise<void> {
    if (!this.waitingRoomRepoService.isCurrentlyWaitingRoomActive()) {
      this.removeHeartbeats(ContextTypeEnum.WAITING_ROOM);
    }
    await this.timerService.clearInterval(this.heartbeatInterval);
    this.heartbeatInterval = await this.timerService.setInterval(() => {
      const currentSpaceId = this.currentSpaceId;
      const currentBreakoutRoomId = this.currentBreakoutRoomId;
      if (!currentSpaceId) {
        return;
      }

      this.activeHeartbeatTypes.forEach((contextType) => {
        const presenceData = this.createPresenceData(
          currentSpaceId,
          type,
          contextType,
          currentBreakoutRoomId,
        );
        const payload: HeartbeatEvent = {
          source: this.getHeartbeat(contextType),
          data: presenceData,
        };

        this.realtimeSpaceService.service.socket?.emit(WebSocketEventType.HEARTBEAT_V2, payload);

        this.presenceMonitoringService.notifyHeartbeatEvent(presenceData);
      });
    }, this.heartbeatRate);
  }

  private getHeartbeat(contextType: ContextTypeEnum): HeartbeatSource {
    switch (contextType) {
      case ContextTypeEnum.CALL:
        return HeartbeatSource.CALL_HEARTBEAT;
      case ContextTypeEnum.SPACE:
        return HeartbeatSource.SPACE_HEARTBEAT;
      case ContextTypeEnum.LMS:
        return HeartbeatSource.LMS_HEARTBEAT;
      case ContextTypeEnum.RECORDING:
        return HeartbeatSource.RECORDING_HEARTBEAT;
      case ContextTypeEnum.WAITING_ROOM:
        return HeartbeatSource.WAITING_ROOM_HEARTBEAT;
    }
  }

  private setPresenceActivity(
    spaceId: string,
    data: Array<PresenceData>,
    type: PresenceType,
    timestamp: number,
  ): void {
    const hash = getHash(spaceId, type);
    this.presenceRepo.setPresenceState({ hash: hash, presenceData: data, timestamp: timestamp });
  }

  private restoreClientPresence() {
    const spaceId = this.currentSpaceId;
    const userId = this.user._id;
    const breakoutRoomId = this.currentBreakoutRoomId;

    if (spaceId && this.currentBreakoutRoomId?.includes(WAITING_ROOM_SUFFIX)) {
      const presenceData = this.createPresenceData(
        spaceId,
        PresenceType.SPACE_PRESENCE,
        ContextTypeEnum.WAITING_ROOM,
        breakoutRoomId,
      );
      this.setWithCustomData(presenceData);
      return;
    }

    // if current space  -> is active re-add user presnece key
    if (userId && spaceId) {
      this.set(spaceId, PresenceType.SPACE_PRESENCE, ContextTypeEnum.SPACE, breakoutRoomId);

      if (this.providerStateService.callConnectedValue) {
        this.set(spaceId, PresenceType.SPACE_PRESENCE, ContextTypeEnum.CALL, breakoutRoomId);
      }
    }

    // join the new rooms that were created after client disconecction
    if (breakoutRoomId) {
      this.rejoinPresenceRooms(PresenceType.SPACE_PRESENCE);
    } else {
      // backward compatibility
      this.rejoinPresenceRooms_v0(PresenceType.SPACE_PRESENCE);
    }
  }

  private onClientDisconnection(): void {
    this.removeHeartbeats(ContextTypeEnum.CALL, ContextTypeEnum.SPACE);
  }

  private rejoinPresenceRooms_v0(...contexts: Array<PresenceType>): void {
    contexts.forEach((context) => {
      this.join_v0([...this.getCurrentJoinedPresenceRooms_v0(context)], context);
    });
  }

  private rejoinPresenceRooms(...contexts: Array<PresenceType>): void {
    contexts.forEach((context) => {
      this.join([...this.getCurrentJoinedPresenceRooms(context)], context);
    });
  }

  private getPresenceActivityValue(
    spaceId: string,
    context: PresenceType,
    contextType: ContextTypeEnum,
    breakoutRoomId?: string,
  ): Array<PresenceData> | undefined {
    return this.presenceRepo.getPresenceDataValue(spaceId, context, contextType, breakoutRoomId);
  }

  private addHeartbeats(...sources: Array<ContextTypeEnum>): void {
    sources.forEach((source) => {
      if (!this.activeHeartbeatTypes.find((val) => val === source)) {
        this.activeHeartbeatTypes.push(source);
      }
    });
  }

  private removeHeartbeats(...sources: Array<ContextTypeEnum>): void {
    this.activeHeartbeatTypes = this.activeHeartbeatTypes.filter((val) => !sources.includes(val));
  }

  private sendPresenceEventWithTimeout(
    data: any,
    event: WebSocketEventType,
    retry = 3,
    getUpdatedData?: CallableFunction,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.realtimeSpaceService.service.socket
        ?.timeout(this.WB_SERVER_ACK_TIMEOUT)
        .emit(event, data, (err, res) => {
          if (err) {
            if (retry === 0) {
              console.log('WB server ack timeout sending presence event');
              Sentry.captureException('WB server ack timeout sending presence event');
              reject(err);
            } else {
              const newData = getUpdatedData ? getUpdatedData() : data;
              if (!newData) {
                return;
              }
              this.sendPresenceEventWithTimeout(newData, event, --retry, getUpdatedData);
            }
          } else {
            resolve(res);
          }
        });
    });
  }

  private setCurrentJoinedPresenceRooms_v0(type: PresenceType, rooms: Array<string>): void {
    this.currentJoinedPresenceRooms_v0.set(type, rooms);
  }

  private setCurrentJoinedPresenceRooms(
    context: PresenceType,
    rooms: Array<PresenceRoomInfo>,
  ): void {
    this.currentJoinedPresenceRooms.set(context, rooms);
  }

  private getCurrentJoinedPresenceRooms_v0(context: PresenceType): Array<string> {
    const joinedRooms = this.currentJoinedPresenceRooms_v0.get(context);

    if (!joinedRooms) {
      this.currentJoinedPresenceRooms_v0.set(context, []);
      return [];
    } else {
      return joinedRooms;
    }
  }

  private getCurrentJoinedPresenceRooms(context: PresenceType): Array<PresenceRoomInfo> {
    const joinedRooms = this.currentJoinedPresenceRooms.get(context);

    if (!joinedRooms) {
      this.currentJoinedPresenceRooms.set(context, []);
      return [];
    } else {
      return joinedRooms;
    }
  }

  private populateBreakoutRoomSessionAuth(sessions: Array<SessionAuth>, rooms: PresenceRoomInfo[]) {
    const res = new Array<SessionAuth>();
    sessions.forEach((session) => {
      const room = rooms.find((x) => x.spaceId === session.id);

      if (room) {
        res.push({ ...session, breakoutRoomId: room.breakoutRoomId });
      }
    });

    return res;
  }

  private async handleUserIdleness() {
    const userIdleEvent = await this.userIdleService.getUserIdleSubject(this.USER_IDLE_THREASHOLD);

    userIdleEvent
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(async (isUserActive) => {
        if (isUserActive) {
          // only restore if user was idle
          await this.startHeartbeat(PresenceType.SPACE_PRESENCE);
          this.restoreClientPresence();
        } else {
          await this.terminateHeartbeat();
        }
      });
  }

  private mapPresenceDataToFramePresence(
    data: Array<PresenceData>,
    includeUserHash = true,
  ): { [key: string]: string[] } {
    const output: { [key: string]: string[] } = {};
    for (const userPresence of data) {
      if (userPresence.contextType !== ContextTypeEnum.SPACE) {
        continue;
      }
      const userFrameId = userPresence.frameId;
      if (!userFrameId) {
        continue;
      }
      if (!output[userFrameId]) {
        output[userFrameId] = [];
      }

      if (includeUserHash) {
        output[userFrameId].push(`${userPresence.userId}-${userPresence.uniqueHash}`);
      } else {
        output[userFrameId].push(userPresence.userId);
      }
    }
    return output;
  }

  // used to set space and room id for cases where no active space is set yet
  private setSpaceAndRoom(spaceID: string, roomID: string) {
    this.currentSpaceId = spaceID;
    this.currentBreakoutRoomId = roomID;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { MatDialogModule } from '@angular/material/dialog';
import { LayoutModule } from '@angular/cdk/layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { TooltipDirective } from '../standalones/directives/pncl-tooltip.directive';
import { UiModule } from '../ui/ui.module';
import { PipeModule } from '../pipes/pipes.module';
import { CommentingModule } from '../commenting/commenting.module';
import { SVGIconComponentsModule } from '../common/svg-icon/svg-icon-components.module';
import { DirectivesModule } from '../directives/directives.module';
import { MessagesRoutingModule } from './messages-routing.module';
import { MessagesComponent } from './messages.component';
import { ConversationPreviewComponent } from './conversation-preview/conversation-preview.component';
import { LandingComponent } from './landing/landing.component';
import { ConversationContainerComponent } from './conversation-container/conversation-container.component';
import { NewMessageDialogComponent } from './new-message-dialog/new-message-dialog.component';
import { MessageListComponent } from './message-list/message-list.component';
import { ConversationHistoryComponent } from './conversation-history/conversation-history.component';
import { MessageContentWrapperComponent } from './message-content-wrapper/message-content-wrapper.component';
import { FailedMessageRetryComponent } from './failed-message-retry/failed-message-retry.component';

@NgModule({
  declarations: [
    MessagesComponent,
    ConversationPreviewComponent,
    LandingComponent,
    ConversationContainerComponent,
    NewMessageDialogComponent,
    MessageListComponent,
    ConversationHistoryComponent,
    MessageContentWrapperComponent,
    FailedMessageRetryComponent,
  ],
  imports: [
    CommonModule,
    MessagesRoutingModule,
    SVGIconComponentsModule,
    UiModule,
    DirectivesModule,
    MatMenuModule,
    FormsModule,
    CommentingModule,
    NgbTooltipModule,
    MatDialogModule,
    MatButtonModule,
    LayoutModule,
    PipeModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatChipsModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          breaks: true,
        },
      },
    }),
    TooltipDirective,
  ],
  exports: [ConversationContainerComponent, ConversationHistoryComponent],
})
export class MessagesModule {}

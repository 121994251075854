import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { User as IFBUser } from '@angular/fire/auth';
import { firstValueFrom } from 'rxjs';
import { captureException } from '@sentry/browser';
import { SpaceSharingService } from './services/space-sharing.service';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { User } from './models/user';
import { SpacesService } from './services/spaces.service';
import {
  anonymousUserTryingToAccessPrivateSpace,
  canUserAccessSpace,
  checkIfCodeTriggeredNavigation,
  checkSkipWaitingRoomState,
  checkUserHasAccessToSessionState,
  clearCurrentNavigationState,
  navigateToRequestAccessAndSkipReloading,
  navigateToWaitingRoomAndSkipReloading,
  userShouldSkipWaitingRoom,
} from './guards/session-guards-util';
import { URL_CONSTANTS } from './common/utils/url';
import { WaitingRoomNotificationService } from './sessions/session/request-access/waiting-room/waiting-room-notification.service';
import { SessionSharedDataService } from './services/session-shared-data.service';
import { GlobalPermissionsService } from './services/global-permissions.service';

@UntilDestroy()
@Injectable()
export class SessionActivate implements CanActivate {
  user?: User;
  fbUser?: IFBUser | null;

  constructor(
    private spaceSharingService: SpaceSharingService,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private spacesService: SpacesService,
    private waitingRoomNotificationService: WaitingRoomNotificationService,
    private sessionSharedDataService: SessionSharedDataService,
    private globalPermissionsService: GlobalPermissionsService,
  ) {
    this.userService.user.subscribe((res) => {
      if (!res?.user) {
        return;
      }
      this.user = res.user;
    });
    this.authService.firebaseUser.subscribe((res) => {
      this.fbUser = res;
    });
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      if (
        checkUserHasAccessToSessionState(this.router) &&
        checkIfCodeTriggeredNavigation(this.router)
      ) {
        clearCurrentNavigationState();
        return true;
      }

      const { session, isAcceptingEmailInvite } = await firstValueFrom(
        this.spacesService.getSession(route.params.id),
      );

      if (checkSkipWaitingRoomState(this.router) && checkIfCodeTriggeredNavigation(this.router)) {
        clearCurrentNavigationState();
        return true;
      }

      if (anonymousUserTryingToAccessPrivateSpace(session, this.user)) {
        this.waitingRoomNotificationService.warning(
          'This Space is private. Please create an account to access this Space.',
        );
        this.sessionSharedDataService.dataLoading.next(false);
        this.router.navigate([`/${URL_CONSTANTS.SPACES}`]);
        return false;
      }

      // user has no access to session redirected to request access page
      if (!canUserAccessSpace(session, this.user)) {
        navigateToRequestAccessAndSkipReloading(this.router, route);
        return false;
      }

      // user has access to session and should continue to session directly
      const isWaitingRoomEnabled =
        this.globalPermissionsService.isWaitingRoomEnabledForSpace(session);
      if (userShouldSkipWaitingRoom(session, isWaitingRoomEnabled, this.user)) {
        if (isAcceptingEmailInvite !== undefined) {
          this.spaceSharingService.fireFSEventAcceptInvite(
            isAcceptingEmailInvite,
            session?._id ?? '',
          );
        }
        return true;
      }

      // user has access to session but should go to waiting room
      navigateToWaitingRoomAndSkipReloading(this.router, route);
      return false;
    } catch (e) {
      captureException(e);
      this.router.navigate(['/']);
      return false;
    }
  }
}

import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-periodic-table',
  templateUrl: './periodic-table.component.html',
  styleUrls: ['./periodic-table.component.scss'],
})
export class PeriodicTableComponent {
  @Output() closed = new EventEmitter();

  tableBody = [
    [
      {content: [1, 'H', 'hydrogen', 1], color: '#f0ff8f'},
      {content: [], class: 'no-border', colspan: 16},
      {content: [2, 'He', 'helium', 4], color: '#c0ffff'},
    ],
    [
      {content: [3, 'Li', 'lithium', 7], color: '#ff6666'},
      {content: [4, 'Be', 'beryllium', 9], color: '#ffdead'},
      {content: [], class: 'no-border', colspan: 3},
      {content: ['atomic number', 'atomic symbol', 'name', 'relative atomic mass'], colspan: 3},
      {content: [], class: 'no-border', colspan: 4},
      {content: [5, 'B', 'boron', 11], color: '#cccc99'},
      {content: [6, 'C', 'carbon', 12], color: '#f0ff8f'},
      {content: [7, 'N', 'nitrogen', 14], color: '#f0ff8f'},
      {content: [8, 'O', 'oxygen', 16], color: '#f0ff8f'},
      {content: [9, 'F', 'fluorine', 19], color: '#f0ff8f'},
      {content: [10, 'Ne', 'neon', 20], color: '#c0ffff'},
    ],
    [
      {content: [11, 'Na', 'sodium', 23], color: '#ff6666'},
      {content: [12, 'Mg', 'magnesium', 24], color: '#ffdead'},
      {content: [], class: 'no-border', colspan: 10},
      {content: [13, 'Al', 'aluminium', 27], color: '#cccccc'},
      {content: [14, 'Si', 'sillicon', 28], color: '#cccc99'},
      {content: [15, 'P', 'phosphorus', 31], color: '#f0ff8f'},
      {content: [16, 'S', 'sulfur', 32], color: '#f0ff8f'},
      {content: [17, 'Cl', 'chlorine', 35.5], color: '#f0ff8f'},
      {content: [18, 'Ar', 'argon', 40], color: '#c0ffff'},
    ],
    [
      {content: [19, 'K', 'potassium', 39], color: '#ff6666'},
      {content: [20, 'Ca', 'calcium', 40], color: '#ffdead'},
      {content: [21, 'Sc', 'scandium', 45], color: '#ffc0c0'},
      {content: [22, 'Ti', 'titanium', 48], color: '#ffc0c0'},
      {content: [23, 'V', 'vanadium', 51], color: '#ffc0c0'},
      {content: [24, 'Cr', 'chromium', 52], color: '#ffc0c0'},
      {content: [25, 'Mn', 'manganese', 55], color: '#ffc0c0'},
      {content: [26, 'Fe', 'iron', 56], color: '#ffc0c0'},
      {content: [27, 'Co', 'cobalt', 59], color: '#ffc0c0'},
      {content: [28, 'Ni', 'nickel', 59], color: '#ffc0c0'},
      {content: [29, 'Cu', 'copper', 64], color: '#ffc0c0'},
      {content: [30, 'Zn', 'zinc', 65], color: '#cccccc'},
      {content: [31, 'Ga', 'gallium', 70], color: '#cccccc'},
      {content: [32, 'Ge', 'germanium', 73], color: '#cccc99'},
      {content: [33, 'As', 'arsenic', 75], color: '#cccc99'},
      {content: [34, 'Se', 'selenium', 79], color: '#f0ff8f'},
      {content: [35, 'Br', 'bromine', 80], color: '#f0ff8f'},
      {content: [36, 'Kr', 'krypton', 84], color: '#c0ffff'},
    ],
    [
      {content: [37, 'Rb', 'rubidium', 85], color: '#ff6666'},
      {content: [38, 'Sr', 'strontium', 88], color: '#ffdead'},
      {content: [39, 'Y', 'yttrium', 89], color: '#ffc0c0'},
      {content: [40, 'Zr', 'zirconium', 91], color: '#ffc0c0'},
      {content: [41, 'Nb', 'niobium', 93], color: '#ffc0c0'},
      {content: [42, 'Mo', 'molybdenum', 96], color: '#ffc0c0'},
      {content: [43, 'Tc', 'technetium', '-'], color: '#ffc0c0'},
      {content: [44, 'Ru', 'ruthenium', 101], color: '#ffc0c0'},
      {content: [45, 'Rh', 'rhodium', 103], color: '#ffc0c0'},
      {content: [46, 'Pd', 'palladium', 106], color: '#ffc0c0'},
      {content: [47, 'Ag', 'silver', 108], color: '#ffc0c0'},
      {content: [48, 'Cd', 'cadmium', 112], color: '#cccccc'},
      {content: [49, 'In', 'indium', 115], color: '#cccccc'},
      {content: [50, 'Sn', 'tin', 119], color: '#cccccc'},
      {content: [51, 'Sb', 'antimony', 122], color: '#cccc99'},
      {content: [52, 'Te', 'tellurium', 128], color: '#cccc99'},
      {content: [53, 'I', 'iodine', 127], color: '#f0ff8f'},
      {content: [54, 'Xe', 'xenon', 131], color: '#c0ffff'},
    ],
    [
      {content: [55, 'Cs', 'caesium', 133], color: '#ff6666'},
      {content: [56, 'Ba', 'barium', 137], color: '#ffdead'},
      {content: ['57-71', '', 'lanthanoids'], color: '#ffbfff'},
      {content: [72, 'Hf', 'hafnium', 178], color: '#ffc0c0'},
      {content: [73, 'Ta', 'tantalum', 181], color: '#ffc0c0'},
      {content: [74, 'W', 'tungsten', 184], color: '#ffc0c0'},
      {content: [75, 'Re', 'rhenium', 186], color: '#ffc0c0'},
      {content: [76, 'Os', 'osmium', 190], color: '#ffc0c0'},
      {content: [77, 'Ir', 'iridium', 192], color: '#ffc0c0'},
      {content: [78, 'Pt', 'platinum', 195], color: '#ffc0c0'},
      {content: [79, 'Au', 'gold', 197], color: '#ffc0c0'},
      {content: [80, 'Hg', 'mercury', 201], color: '#cccccc'},
      {content: [81, 'Tl', 'thallium', 204], color: '#cccccc'},
      {content: [82, 'Pb', 'lead', 207], color: '#cccccc'},
      {content: [83, 'Bi', 'bismuth', 209], color: '#cccccc'},
      {content: [84, 'Po', 'polonium', '-'], color: '#cccccc'},
      {content: [85, 'At', 'astatine', '-'], color: '#cccc99'},
      {content: [86, 'Rn', 'radon', '-'], color: '#c0ffff'},
    ],
    [
      {content: [87, 'Fr', 'francium', '-'], color: '#ff6666'},
      {content: [88, 'Ra', 'radium', '-'], color: '#ffdead'},
      {content: ['89-103', '', 'actinoids'], color: '#ff99cc'},
      {content: [104, 'Rf', 'rutherfordium', '-'], color: '#ffc0c0'},
      {content: [105, 'Db', 'dubnium', '-'], color: '#ffc0c0'},
      {content: [106, 'Sg', 'seaborgium', '-'], color: '#ffc0c0'},
      {content: [107, 'Bh', 'bohrium', '-'], color: '#ffc0c0'},
      {content: [108, 'Hs', 'hassium', '-'], color: '#ffc0c0'},
      {content: [109, 'Mt', 'meitnerium', '-'], color: '#e8e8e8'},
      {content: [110, 'Ds', 'darmstadtium', '-'], color: '#e8e8e8'},
      {content: [111, 'Rg', 'roentgenium', '-'], color: '#e8e8e8'},
      {content: [112, 'Cn', 'copernicium', '-'], color: '#cccccc'},
      {content: [113, 'Nh', 'nihonium', '-'], color: '#e8e8e8'},
      {content: [114, 'Fl', 'flerovium', '-'], color: '#e8e8e8'},
      {content: [115, 'Mc', 'moscovium', '-'], color: '#e8e8e8'},
      {content: [116, 'Lv', 'livermorium', '-'], color: '#e8e8e8'},
      {content: [117, 'Ts', 'tennessine', '-'], color: '#e8e8e8'},
      {content: [118, 'Og', 'oganesson', '-'], color: '#e8e8e8'},
    ],
    [],
    [
      {content: ['', 'lanthanoids'], class: 'no-border', colspan: 2},
      {content: [57, 'La', 'lanthanum', 139], color: '#ffbfff'},
      {content: [58, 'Ce', 'cerium', 140], color: '#ffbfff'},
      {content: [59, 'Pr', 'praseodymium', 141], color: '#ffbfff'},
      {content: [60, 'Nd', 'neodymium', 144], color: '#ffbfff'},
      {content: [61, 'Pm', 'promethium', '-'], color: '#ffbfff'},
      {content: [62, 'Sm', 'samarium', 150], color: '#ffbfff'},
      {content: [63, 'Eu', 'europium', 152], color: '#ffbfff'},
      {content: [64, 'Gd', 'gadolinium', 157], color: '#ffbfff'},
      {content: [65, 'Tb', 'terbium', 159], color: '#ffbfff'},
      {content: [66, 'Dy', 'dysprosium', 163], color: '#ffbfff'},
      {content: [67, 'Ho', 'holmium', 165], color: '#ffbfff'},
      {content: [68, 'Er', 'erbium', 167], color: '#ffbfff'},
      {content: [69, 'Tm', 'thulium', 169], color: '#ffbfff'},
      {content: [70, 'Yb', 'ytterbium', 173], color: '#ffbfff'},
      {content: [71, 'Lu', 'lutetium', 175], color: '#ffbfff'},
      {content: [], class: 'no-border'},
    ],
    [
      {content: ['', 'actinoids'], class: 'no-border', colspan: 2},
      {content: [89, 'Ac', 'actinium', '-'], color: '#ff99cc'},
      {content: [90, 'Th', 'thorium', 232], color: '#ff99cc'},
      {content: [91, 'Pa', 'protactinium', 231], color: '#ff99cc'},
      {content: [92, 'U', 'uranium', 238], color: '#ff99cc'},
      {content: [93, 'Np', 'neptunium', '-'], color: '#ff99cc'},
      {content: [94, 'Pu', 'plutonium', '-'], color: '#ff99cc'},
      {content: [95, 'Am', 'americium', '-'], color: '#ff99cc'},
      {content: [96, 'Cm', 'curium', '-'], color: '#ff99cc'},
      {content: [97, 'Bk', 'berkelium', '-'], color: '#ff99cc'},
      {content: [98, 'Cf', 'californium', '-'], color: '#ff99cc'},
      {content: [99, 'Es', 'einsteinium', '-'], color: '#ff99cc'},
      {content: [100, 'Fm', 'fermium', '-'], color: '#ff99cc'},
      {content: [101, 'Md', 'mendelevium', '-'], color: '#ff99cc'},
      {content: [102, 'No', 'nobelium', '-'], color: '#ff99cc'},
      {content: [103, 'Lr', 'lawrencium', '-'], color: '#ff99cc'},
      {content: [], class: 'no-border'},
    ],
  ];
}

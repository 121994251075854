import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IconTypes } from 'src/app/standalones/components/pencil-icon/pencil-icon.component';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  IconTypes = IconTypes;
  readonly DEFAULT_PARAMS = {
    dialogWidth: '320px',
  };
  params: {
    dialogWidth?: string;
    title: string;
    body: string;
    positiveBtn: string;
    positiveBtnIcon?: string;
    positiveBtnClass?: string;
    negativeBtn: string;
    negativeBtnIcon?: string;
    negativeBtnClass?: string;
  };
  constructor(
    private dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      body: string;
      positiveBtn: string;
      positiveBtnIcon?: string;
      positiveBtnClass?: string;
      negativeBtn: string;
      negativeBtnIcon?: string;
      negativeBtnClass?: string;
      dialogWidth?: string;
    },
  ) {
    this.params = {
      ...this.DEFAULT_PARAMS,
      ...data,
    };
  }

  close() {
    this.dialogRef.close(false);
  }

  finish() {
    this.dialogRef.close(true);
  }
}

<div class="top-bar">
  <div class="d-flex align-items-center">
    <svg-icon
      [width]="24"
      height="24"
      [icon]="'arrow-back'"
      (click)="handleCloseClick()"
    ></svg-icon>
    <span class="m-2">{{ spaceTitle }} </span>
    <span style="color: #bdbdbd">|</span>
    <span class="m-2">
      {{ 'Sessions' | translate }}
      {{ noOfSessions ? '(' + noOfSessions + ')' : '' }}
    </span>
  </div>
  <i class="material-icons close-button" (click)="handleCloseClick()"> close </i>
  <ng-content></ng-content>
</div>

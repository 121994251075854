import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, first } from 'rxjs';
import { Message } from 'src/app/models/user';
import { MessagingService } from 'src/app/services/messaging.service';
import { NetworkService } from 'src/app/services/network.service';
import { TelemetryService } from 'src/app/services/telemetry.service';
import { UserService } from 'src/app/services/user.service';

@UntilDestroy()
@Component({
  selector: 'failed-message-retry',
  templateUrl: './failed-message-retry.component.html',
  styleUrls: ['./failed-message-retry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FailedMessageRetryComponent implements OnInit {
  @Input() public pendingMessage: Message | null = null;

  public sending = new BehaviorSubject<boolean>(false);

  constructor(
    private userService: UserService,
    private telementry: TelemetryService,
    private networkService: NetworkService,
    private messagingService: MessagingService,
  ) {}

  ngOnInit(): void {
    this.networkService.networkReconnected$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.autoRetryAddMessage());
  }

  public setFailedMessageSendingStatus(isSending: boolean) {
    this.sending.next(isSending);
  }

  private autoRetryAddMessage() {
    this.telementry.event('chat_message_retry_after_reconnect', {
      messageId: this.pendingMessage?._id,
      messageContext: this.pendingMessage?.context,
      sessionId: this.pendingMessage?.currentSession,
    });
    this.retryAddMessage();
  }

  public retryAddMessage(): void {
    if (this.pendingMessage && !this.sending.value) {
      this.setFailedMessageSendingStatus(true);
      this.userService
        .createComment(this.pendingMessage)
        .pipe(first())
        .subscribe({
          next: () => {
            if (this.pendingMessage) {
              this.messagingService.handleLocalMessageSentSuccessfully(this.pendingMessage);
            }
            this.setFailedMessageSendingStatus(false);
          },
          error: () => {
            this.setFailedMessageSendingStatus(false);
          },
        });
    }
  }
}

<div class="schoolContainer">
  <div class="checkboxContainer">
    <mat-checkbox
      color="primary"
      [checked]="model.isPastExam"
      (change)="model.isPastExam = !model.isPastExam"
    ></mat-checkbox>
    <div class="checkboxText">
      {{ 'Past exam question' | translate }}
    </div>
  </div>
  <div class="checkboxList" *ngIf="model.isPastExam">
    <div class="dateContainer cmt-8">
      <ng-select
        class="w-100"
        [searchable]="false"
        [placeholder]="'Day' | translate"
        [items]="days"
        [(ngModel)]="model.day"
      >
      </ng-select>
      <ng-select
        class="w-100 cmx-16"
        [searchable]="false"
        [placeholder]="'Month' | translate"
        [items]="months"
        [bindLabel]="'name'"
        bindValue="index"
        [(ngModel)]="model.month"
      >
      </ng-select>
      <ng-select
        class="w-100"
        [searchable]="false"
        [items]="years"
        [placeholder]="'Year' | translate"
        [(ngModel)]="model.year"
      >
      </ng-select>
    </div>
    <ng-container *ngIf="model.year">
      <ng-select
        class="w-100 cmt-8"
        [searchable]="false"
        bindLabel="name"
        bindValue="exam"
        [items]="exams"
        [placeholder]="'Exam'"
        [(ngModel)]="model.from"
      >
      </ng-select>
      <ng-container *ngIf="model.from === 'School'">
        <ng-select
          class="w-100 cmy-8"
          [searchable]="true"
          placeholder="School"
          [items]="schools"
          [(ngModel)]="model.school"
        >
        </ng-select>
        <ng-select
          class="w-100 cmy-8"
          [searchable]="false"
          placeholder="Grade"
          [items]="schoolGrades"
          [(ngModel)]="model.grade"
        >
        </ng-select>
        <ng-select
          class="w-100 cmy-8"
          [searchable]="false"
          placeholder="Exam Type"
          [items]="schoolExamType"
          [(ngModel)]="model.exam"
        >
        </ng-select>
        <ng-select
          class="w-100 cmy-8"
          [searchable]="false"
          placeholder="Paper"
          [items]="papers"
          [(ngModel)]="model.paper"
        >
        </ng-select>
      </ng-container>
      <ng-container *ngIf="model.from === 'PSLE'">
        <ng-select
          class="w-100 cmy-8"
          [searchable]="false"
          placeholder="Booklet"
          [items]="psleBooklet"
          bindLabel="name"
          bindValue="booklet"
          [(ngModel)]="model.booklet"
        >
        </ng-select>
      </ng-container>
      <ng-container *ngIf="model.from === 'NEET'">
        <div class="questionNumber">
          <mat-form-field>
            <input matInput [(ngModel)]="model.booklet" placeholder="Booklet Code" />
          </mat-form-field>
        </div>
      </ng-container>
      <ng-container *ngIf="model.from === 'IB Exam'">
        <ng-select
          class="w-100 cmy-8"
          [searchable]="false"
          placeholder="Paper"
          [items]="papers"
          bindLabel="name"
          bindValue="paper"
          [(ngModel)]="model.paper"
        >
        </ng-select>
        <ng-select
          class="w-100 cmy-8"
          [searchable]="false"
          placeholder="Timezone"
          [items]="ibTimezone"
          bindLabel="name"
          bindValue="tz"
          [(ngModel)]="model.timezone"
        >
        </ng-select>
      </ng-container>
      <ng-container *ngIf="model.from === 'GCE O-Level'">
        <ng-select
          class="w-100 cmy-8"
          [searchable]="false"
          placeholder="Paper"
          [items]="papers"
          bindLabel="name"
          bindValue="paper"
          [(ngModel)]="model.paper"
        >
        </ng-select>
      </ng-container>
      <ng-container *ngIf="model.from === 'GCE A-Level'">
        <ng-select
          class="w-100 cmy-8"
          [searchable]="false"
          placeholder="Paper"
          [items]="papers"
          bindLabel="name"
          bindValue="paper"
          [(ngModel)]="model.paper"
        >
        </ng-select>
      </ng-container>
      <ng-container *ngIf="model.from === 'IIT-JEE Main'">
        <ng-select
          class="w-100 cmy-8"
          [searchable]="false"
          placeholder="Paper"
          [items]="papers"
          bindLabel="name"
          bindValue="paper"
          [(ngModel)]="model.paper"
        >
        </ng-select>
        <ng-select
          class="w-100 cmy-8"
          [searchable]="false"
          placeholder="Session"
          [items]="iitJeeSession"
          bindLabel="name"
          bindValue="session"
          [(ngModel)]="model.session"
        >
        </ng-select>
        <div class="questionNumber">
          <mat-form-field>
            <input matInput [(ngModel)]="model.booklet" placeholder="Exam Set" />
          </mat-form-field>
        </div>
      </ng-container>
      <ng-container *ngIf="model.from === 'UPSC IAS Prelims'">
        <ng-select
          class="w-100 cmy-8"
          [searchable]="false"
          placeholder="Paper"
          [items]="upscIasPrelimsPapers"
          bindLabel="name"
          bindValue="paper"
          [(ngModel)]="model.paper"
        >
        </ng-select>
        <ng-select
          class="w-100 cmy-8"
          [searchable]="false"
          placeholder="Booklet"
          [items]="upscIasPrelimsBooklet"
          bindLabel="name"
          bindValue="booklet"
          [(ngModel)]="model.booklet"
        >
        </ng-select>
      </ng-container>
      <ng-container *ngIf="model.from === 'CBSE'">
        <div class="questionNumber">
          <mat-form-field>
            <input matInput [(ngModel)]="model.booklet" placeholder="Code No." />
          </mat-form-field>
        </div>
        <div class="questionNumber">
          <mat-form-field>
            <input matInput [(ngModel)]="model.set" placeholder="Set" />
          </mat-form-field>
        </div>
        <div class="questionNumber">
          <mat-form-field>
            <input matInput [(ngModel)]="model.series" placeholder="Series" />
          </mat-form-field>
        </div>
      </ng-container>
      <ng-container *ngIf="model.from === 'Royal College Physician Licensing Exam'">
        <ng-select
          class="w-100 cmy-8"
          [searchable]="false"
          placeholder="Paper"
          [items]="royalCollegeExamPapers"
          bindLabel="name"
          bindValue="paper"
          [(ngModel)]="model.paper"
        >
        </ng-select>
      </ng-container>
      <div class="questionNumber">
        <mat-form-field>
          <input
            matInput
            [(ngModel)]="model.question_number"
            [placeholder]="'Question Number' | translate"
          />
        </mat-form-field>
      </div>
    </ng-container>
  </div>
</div>

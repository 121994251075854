export interface SpaceTranscriptItem {
  _id: string;
  speakerId: string;

  spaceId: string;
  sessionId: string;
  roomId?: string;
  timestamp: Date;
  transcript: string;
  language: string;

  speaker?: ISpeakerEntity;

  contextType?: ChatContextType;

  lastModified?: Date;
}

export interface ISpeakerEntity {
  _id: string;
  name: string;
  profile_image: string;
}

export enum ChatContextType {
  sender = 'sender',
  receiver = 'receiver'
}

export interface TranscriptionsResponse {
  transcriptions: Array<SpaceTranscriptItem>;
  total: number;
}

import { NgZone } from '@angular/core';
import { Subject, Subscription, distinctUntilChanged } from 'rxjs';
import { AnalyticsEventType } from '../models/analytics';
import { AnalyticsService } from '../services/analytics.service';
import { TelemetryService } from '../services/telemetry.service';
import { UserService } from '../services/user.service';
import { CurrentlyDetectingAudio } from '../sessions/common/volume-detector';

class UserSpeakingAnalytics {
  private _userSpeechStateOnStart?: CurrentlyDetectingAudio & { timestamp: Date };
  private _subject = new Subject<CurrentlyDetectingAudio>();
  private _userSpeakingDuration = 0; // Saves user talking time in seconds
  private _subscription?: Subscription;
  private readonly SPEECH_DURATION_THRESHOLD = 1; // The minimum duration user needs to speak for consecutively for it to be counted

  constructor(
    private analyticsService: AnalyticsService,
    private ngZone: NgZone,
    private isLocalUser = false,
  ) {
    this.ngZone.runOutsideAngular(() => {
      this._subscription = this._subject
        .pipe(distinctUntilChanged())
        .subscribe((userSpeechState) => {
          if (userSpeechState.isCurrentlyDetected) {
            this._userSpeechStateOnStart = { ...userSpeechState, timestamp: new Date() };
          } else {
            this.onStopSpeech(userSpeechState);
          }
        });
    });
  }

  clearSubscription() {
    this._subscription?.unsubscribe();
  }

  getUserSpeakingDuration() {
    // Add pending duration to the counter before returning the total duration
    if (this._userSpeechStateOnStart) {
      const elapsedDuration =
        (new Date().getTime() - this._userSpeechStateOnStart.timestamp.getTime()) / 1000;
      return this._userSpeakingDuration + elapsedDuration;
    }
    return this._userSpeakingDuration;
  }

  getSubject(): Subject<CurrentlyDetectingAudio> {
    return this._subject;
  }

  reset() {
    this._userSpeakingDuration = 0;
    this._userSpeechStateOnStart = undefined;
  }

  onStopSpeech(userSpeechState: CurrentlyDetectingAudio) {
    if (!this._userSpeechStateOnStart) {
      return;
    }
    const timestamp = new Date();
    const duration =
      (timestamp.getTime() - this._userSpeechStateOnStart.timestamp.getTime()) / 1000;
    if (duration > this.SPEECH_DURATION_THRESHOLD) {
      this._userSpeakingDuration += duration;
      if (this.isLocalUser) {
        this.analyticsService.addToAnalyticsEventsBatch(
          AnalyticsEventType.START_SPEECH,
          this._userSpeechStateOnStart.timestamp,
          {
            meanAmplitude: this._userSpeechStateOnStart.meanAmplitude,
            medianAmplitude: this._userSpeechStateOnStart.medianAmplitude,
          },
        );
        this.analyticsService.addToAnalyticsEventsBatch(AnalyticsEventType.END_SPEECH, timestamp, {
          duration,
          meanAmplitude: userSpeechState.meanAmplitude,
          medianAmplitude: userSpeechState.medianAmplitude,
        });
      }
    }
    this._userSpeechStateOnStart = undefined;
  }
}

export class UserSpeakingLiveAnalyticsManager {
  private participantManager = new Map<string, UserSpeakingAnalytics>();

  constructor(
    private ngZone: NgZone,
    private telemetry: TelemetryService,
    private analyticsService: AnalyticsService,
    private userService: UserService,
  ) {}

  /**
   * Returns the subject which needs to be invoked by a component when user is speaking
   */
  getUserSubject(userId: string): Subject<CurrentlyDetectingAudio> {
    if (!this.participantManager.has(userId)) {
      this.participantManager.set(
        userId,
        new UserSpeakingAnalytics(
          this.analyticsService,
          this.ngZone,
          userId === this.userService.userId,
        ),
      );
    }
    return this.participantManager.get(userId)!.getSubject();
  }

  /**
   * Returns hashmap which contains speech time for each user on call
   */
  getParticipantsSpeechDuration(): Record<string, number> {
    return Object.fromEntries(
      [...this.participantManager.entries()].map(([key, val]) => [
        key,
        val.getUserSpeakingDuration(),
      ]),
    );
  }
  /**
   * Returns user speaking time ratio
   * This is calculated by user_speech_time/sum_all_users_speech_time
   */
  getUserSpeakingRatio(userId: string): number {
    const participantSpeechDuration = this.getParticipantsSpeechDuration();
    this.telemetry.log('debug', JSON.stringify(participantSpeechDuration));
    const totalTalkTime = Object.values(participantSpeechDuration).reduce(
      (sum, curr) => sum + curr,
      0,
    );
    if (!totalTalkTime) {
      return 0;
    }
    const speechRatio = (participantSpeechDuration[userId] ?? 0) / totalTalkTime;
    return Math.round(speechRatio * 100) / 100;
  }

  /**
   * Resets speech time for all users
   * To be done once a new call is started
   */
  resetSpeechTime() {
    for (const participant of this.participantManager.values()) {
      participant.reset();
    }
  }

  unsubscribe() {
    for (const participant of this.participantManager.values()) {
      participant.clearSubscription();
    }
    this.participantManager.clear();
  }
}

import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
  AfterViewInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { EMAIL_CODE_ERROR } from 'src/app/models/user';

@UntilDestroy()
@Component({
  selector: 'app-code-input',
  templateUrl: './code-input.component.html',
  styleUrls: ['./code-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeInputComponent implements AfterViewInit {
  @Output() inputFinished = new EventEmitter<string>();
  @Input() error?: EMAIL_CODE_ERROR;
  @Input() disabled = false;
  @Input() clearInputs: Subject<boolean> = new Subject();

  inputs: HTMLInputElement[] = [];

  ngAfterViewInit() {
    this.clearInputs.pipe(untilDestroyed(this)).subscribe(() => {
      for (let i = 0; i < 6; i++) {
        this.inputs[i].value = '';
      }
    });

    for (let i = 0; i < 6; i++) {
      this.inputs.push(document.getElementById(`digit-${i}`) as HTMLInputElement);
    }

    this.inputs[0].select();
  }

  get digits() {
    let digits = '';
    for (const input of this.inputs) {
      digits = digits + input.value;
    }
    return digits;
  }

  handleKeydownEvent(e, index: number) {
    // mobile webview keyboards use a different event for some characters, which fire differently.
    // https://bugs.chromium.org/p/chromium/issues/detail?id=118639
    if (e.key !== 'Unidentified') {
      const target = this.inputs[index];
      // Set the value, and select the next field
      if (e.key >= 0 && e.key <= 9 && e.key.trim() && e.key !== e) {
        target.value = e.key;
        this.getNextInput(index)?.select();
        this.checkComplete();
      }
      // Delete currently selected digit or previous digit
      if (e.key === 'Backspace') {
        this.handleBackspace(index);
      }
      if (e.key === 'ArrowLeft') {
        this.getPreviousInput(index)?.select();
      }
      if (e.key === 'ArrowRight') {
        this.getNextInput(index)?.select();
      }
      // prevent default as long as we aren't pasting
      if (!(e.key === 'v' && (e.ctrlKey || e.metaKey))) {
        e.preventDefault();
      }
    }
  }

  handleBackspace(index) {
    const target = this.inputs[index];
    const prevTarget = this.getPreviousInput(index);
    if (target.value) {
      target.value = '';
    } else if (prevTarget) {
      prevTarget.value = '';
      prevTarget.select();
    }
  }

  // handle mobile webview input strings, or inputs that don't support keycodes
  handleInputEvent(e, index) {
    if (e.data) {
      this.parseUserInputString(e.data, index);
    }
    e.preventDefault();
  }

  // Paste any numbers from pasted string
  handlePasteEvent(e: ClipboardEvent, index: number) {
    const pasteData = e.clipboardData?.getData('Text') || '';
    this.parseUserInputString(pasteData, index);
    e.preventDefault();
  }

  // Add any numbers to input fields from user input string
  parseUserInputString(string: string, index: number) {
    let target = this.inputs[index];
    for (const char of string) {
      // trim spaces and whitespace
      const numChar = char.trim() ? Number(char) : '';
      if (char.trim() && numChar >= 0 && numChar <= 9) {
        target.value = char;
        const nextSibling = this.getNextInput(index);
        if (nextSibling) {
          target = nextSibling;
          target.select();
          index += 1;
        } else {
          break;
        }
      }
    }
    this.checkComplete();
  }

  checkComplete() {
    if (this.digits.length === 6) {
      this.inputFinished.emit(this.digits);
      this.inputs.forEach((input) => {
        input.blur();
      });
    }
  }

  getNextInput(index: number): HTMLInputElement | null {
    if (index !== 5) {
      return this.inputs[index + 1];
    }
    return null;
  }

  getPreviousInput(index: number): HTMLInputElement | null {
    if (index !== 0) {
      return this.inputs[index - 1];
    }
    return null;
  }
}

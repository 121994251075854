import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { buildRouterParamsFromNotification } from 'src/app/common/notification/notification';
import { NotificationExtended } from 'src/app/models/global.interface';
import { MessageContentType } from 'src/app/services/notifications.service';

@Component({
  selector: 'ui-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  isMenuOpened = false;

  @Input() notification?: NotificationExtended;
  @Input() lastViewed?: string;
  @Output() closeNotification = new EventEmitter();
  MessageContentType = MessageContentType;

  constructor(private router: Router) {}

  open(): void {
    if (this.notification) {
      const [actionUrl, fragment] = buildRouterParamsFromNotification(this.notification);
      this.router.navigate([actionUrl], fragment);
    }
  }
}

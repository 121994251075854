<div class="content" [style.width]="params.dialogWidth">
  <div class="header">
    {{ params.title }}
  </div>

  <div class="body">
    <div class="text">
      <p [innerHTML]="params.body"></p>
    </div>

    <div class="actions">
      <app-pencil-button
        *ngIf="params.negativeBtn"
        class="pencil-button"
        [ngClass]="params.negativeBtnClass || 'light'"
        [iconConfig]="{
          type: IconTypes.matIcon
        }"
        [label]="params.negativeBtn"
        [icon]="params.negativeBtnIcon"
        (click)="close()"
      >
      </app-pencil-button>
      <app-pencil-button
        *ngIf="params.positiveBtn"
        class="pencil-button"
        [ngClass]="params.positiveBtnClass || 'danger'"
        [label]="params.positiveBtn"
        [icon]="params.positiveBtnIcon"
        [iconConfig]="{
          type: IconTypes.matIcon
        }"
        (click)="finish()"
      >
      </app-pencil-button>
    </div>
  </div>
</div>

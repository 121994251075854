<div
  [class]="
    'app-spinner center-container d-flex align-items-center justify-content-center position-relative ' +
    style
  "
  [style.height]="size + 'px'"
  [style.width]="size + 'px'"
>
  <div
    class="centered-item position-absolute background-circle"
    [style.width]="size + 'px'"
    [style.height]="size + 'px'"
    [style.borderWidth]="size * 0.052 + 'px'"
  ></div>
  <svg-icon
    class="centered-item position-absolute"
    [width]="size * iconRatio + 'px'"
    [height]="size * iconRatio + 'px'"
    [icon]="icon"
    [style.width]="size * iconRatio + 'px'"
  ></svg-icon>
  <app-pencil-spinner
    [style.align-self]="'start !important'"
    [diameter]="size"
    [strokeWidth]="size * 0.052"
  ></app-pencil-spinner>
</div>

import { TranslateService } from '@ngx-translate/core';
import { DeviceState } from 'src/app/models/device-manger';
import { DeviceAndBrowserDetectorService } from 'src/app/services/device-and-browser-detector.service';
import { DeviceType } from '../devices-handle-util';

const GAP_BETWEEN_STEPS = 55;
const MIN_MARGIN_BETWEEN_CONTAINER_STEPS = 16;

export interface DeviceErrorHelperStep {
  showStepLocation: boolean;
  stepLocationImgSrc?: string;
  stepLocationText?: string;
  stepText: string;
  stepImgSrc?: string;
  stepImgCustomStyle?: string;
  stepWidth: number;
}

export enum Browser {
  CHROME = 'chrome',
  EDGE = 'edge',
  SAFARI = 'safari',
  FIREFOX = 'firefox',
  OPERA = 'opera',
}

export abstract class DeviceErrorHelperModelBase {
  protected helperSupportedBrowsers: string[] = [
    Browser.CHROME,
    Browser.EDGE,
    Browser.SAFARI,
    Browser.FIREFOX,
  ];
  guideWidth = 1000;

  constructor(
    protected translateService: TranslateService,
    protected deviceDetector: DeviceAndBrowserDetectorService,
    protected deviceType: DeviceType,
  ) {}

  abstract getSteps(): DeviceErrorHelperStep[];
  abstract getMobileTitle(): string;
  abstract getDesktopTitle(): string;
  abstract dismissErrorNotification(): void;
  abstract hasRelatedError(cam: DeviceState, mic: DeviceState): boolean;
  abstract showErrorNotification(cam: DeviceState, mic: DeviceState): void;

  canFitAllSteps(): boolean {
    const steps = this.getSteps();
    const availableWidth = Math.min(Math.floor(window.innerWidth * 0.8), this.guideWidth);
    let steps_width = 0;
    steps.forEach((step) => {
      steps_width += step.stepWidth;
    });
    const canFit =
      availableWidth -
        steps_width -
        GAP_BETWEEN_STEPS * (steps.length - 1) -
        MIN_MARGIN_BETWEEN_CONTAINER_STEPS >=
      0;

    return canFit;
  }

  showDeviceSettingsButton() {
    return this.showRealDeviceSettingsButtonByGuide() && this.isDeviceSettingsSupportedByOS();
  }

  deviceSettingButtonInerHTML(): string {
    const imgSrc = this.deviceDetector.isMacOS()
      ? 'apple_settings_icon.png'
      : 'general-settings-icon.png';
    return `
      <img 
        [width]="20"
        [height]="20"
        src="assets/imgs/${imgSrc}"/> ${this.translateService.instant('Open System Preferences')}`;
  }

  // can be overwritten by sub classes to support device setting button
  deviceSettingButtonSrc(): string {
    return '';
  }

  // can be overwritten by sub classes to support device setting button
  protected showRealDeviceSettingsButtonByGuide(): boolean {
    return false;
  }

  protected currentBrowserName(): string {
    let currentBrowser = this.deviceDetector.browser.toLowerCase();
    // Handle special case with Edge
    if (currentBrowser.includes('ms-edge')) {
      currentBrowser = Browser.EDGE;
    }

    if (this.helperSupportedBrowsers.includes(currentBrowser)) {
      return currentBrowser;
    }

    return Browser.CHROME;
  }

  private isDeviceSettingsSupportedByOS() {
    return this.deviceDetector.isWindows() || this.deviceDetector.isMacOS();
  }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  @Input() size = 170;
  @Input() icon = 'pencil_loading';
  @Input() style = '';
  @Input() iconRatio = 0.505;
}

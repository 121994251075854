import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  FilterData,
  FilterKey,
  IFilterAPI,
} from '../standalones/components/generic-filters-view/filters.interface';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  private _filtersData = new BehaviorSubject<FilterData[]>([]);
  public filtersData$ = this._filtersData.asObservable();

  public appliedFilters$: Observable<FilterData[]> = this.filtersData$.pipe(
    map((filters: FilterData[]) => filters.filter((filter: FilterData) => filter.isApplied())),
  );

  get filtersData(): FilterData[] {
    return this._filtersData.value;
  }

  initFilters(filters: FilterData[]) {
    this._filtersData.next(filters);
  }

  clearFilters(): void {
    const currentFilters = this.filtersData;
    currentFilters.forEach((filter: FilterData) => filter.handleClearFilter());
    this._filtersData.next(currentFilters);
  }

  updateFilter(filter: FilterData): void {
    const currentFilters = this.filtersData;
    const filterToUpdate = currentFilters.find(
      (filterData: FilterData) => filterData.key === filter.key,
    );
    if (filterToUpdate) {
      Object.assign(filterToUpdate, filter);
    } else {
      currentFilters.push(filter);
    }
    this._filtersData.next(currentFilters);
  }

  removeFilter(filterKey: FilterKey): void {
    const currentFilters = this.filtersData;
    currentFilters.find((filter: FilterData) => filter.key === filterKey)?.handleClearFilter();
    this._filtersData.next(currentFilters);
  }
}

export function changeFiltersToAPIFormat(filters?: FilterData[]): IFilterAPI | undefined {
  return filters
    ?.filter((filter: FilterData) => filter.isApplied())
    .reduce((apiFilters, filter: FilterData) => {
      filter.convertToAPIFormat(apiFilters);
      return apiFilters;
    }, {} as IFilterAPI);
}

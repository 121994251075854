import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { map } from 'rxjs';
import {
  LockObjectAction,
  LockObjectTarget,
  SessionSharedDataService,
} from 'src/app/services/session-shared-data.service';
import { CanvasItem } from 'src/app/sessions/session/items-canvas/items-canvas.component';
import { SpaceRepository } from 'src/app/state/space.repository';

@Component({
  selector: 'app-lock-snackbar',
  templateUrl: './lock-snackbar.component.html',
  styleUrls: ['./lock-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LockSnackbarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<LockSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: CanvasItem,
    private sharedDataService: SessionSharedDataService,
    private spaceRepo: SpaceRepository,
  ) {}

  isHost$ = this.spaceRepo.currentUserRole$.pipe(map((data) => data.owner));

  unlockAndDismiss(): void {
    this.sharedDataService.toolbarChange.next({
      action: LockObjectAction.LOCK_OBJECT,
      target: LockObjectTarget.CANVAS_ITEM,
      args: [this.data, false],
    });
    this.snackBarRef.dismiss();
  }
}

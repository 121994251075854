import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, take } from 'rxjs';
import { isEmpty } from 'lodash-es';

import { UiService } from 'src/app/services/ui.service';
import { FLAGS, FlagsService } from 'src/app/services/flags.service';
import { SUBSCRIPTION_TYPES } from 'src/app/models/payment';
import { RecordingMode, User, InstitutionPermissionState } from 'src/app/models/user';
import { DomListenerFactoryService } from 'src/app/services/dom-listener-factory.service';
import { UserSettingsPanelData } from 'src/app/services/settings.service';
import { PanelData, SettingsConstantsTransformer, panelID } from '../settings.constants';
import { ConfirmationModalV2Component } from '../../dialogs/confirmation-modal-v2/confirmation-modal-v2.component';
import { UserService } from '../../services/user.service';
import { AclService, Feature } from '../../services/acl.service';
import { IconTypes } from './../../standalones/components/pencil-icon/pencil-icon.component';

@UntilDestroy()
@Component({
  selector: 'app-user-settings-panel',
  templateUrl: './user-settings-panel.component.html',
  styleUrls: ['./user-settings-panel.component.scss'],
  animations: [
    trigger('slideUp', [
      transition(':enter', [
        style({ top: '100vh' }),
        animate('500ms ease-in-out', style({ top: 0 })),
      ]),
    ]),
  ],
})
export class UserSettingsPanelComponent implements OnInit, OnDestroy {
  activePanel;
  PANELS;
  accountForm?: UntypedFormGroup;
  institutionForm?: UntypedFormGroup;
  advancedSettingsForm?: UntypedFormGroup;
  aiAssistForm?: UntypedFormGroup;
  isRTL = false;
  isMobile = false;
  BillingStatus = SUBSCRIPTION_TYPES;
  currentBilling = SUBSCRIPTION_TYPES.TRIAL;
  isBillingEnabled = false;
  user?: User;
  domListener = this.domListenerFactoryService.createInstance();
  showInstitutionSettings = false;
  isInstitutionUser = false;
  inactivityNotificationsEnabled = false;
  institutionCustomAttributesEnabled = this.flagsService.isFlagEnabled(FLAGS.ENABLE_SETTINGS_CA);
  isBillableUsageSettingEnabled = this.flagsService.isFlagEnabled(
    FLAGS.ENABLE_INSTITUTION_BILLING_SETTINGS,
  );
  formChanged: boolean = false;

  constructor(
    private translateService: TranslateService,
    public uiService: UiService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    public userService: UserService,
    private flagsService: FlagsService,
    private domListenerFactoryService: DomListenerFactoryService,
    private aclService: AclService,
    private dialogRef?: MatDialogRef<UserSettingsPanelComponent>,
    private bottomSheetRef?: MatBottomSheetRef<UserSettingsPanelComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData?: UserSettingsPanelData,
    @Inject(MAT_BOTTOM_SHEET_DATA) private bottomSheetData?: UserSettingsPanelData,
  ) {
    const panelData = isEmpty(this.bottomSheetData) ? this.dialogData : this.bottomSheetData;
    this.setupKeyListenerForClosingModal();
    if (this.flagsService.isFlagEnabled(FLAGS.ENABLE_BILLING)) {
      this.isBillingEnabled = true;
    }
    const settingsConstants = new SettingsConstantsTransformer(this.translateService);
    this.PANELS = settingsConstants.PANELS;
    this.uiService.isMobile.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe((res) => {
      this.isMobile = res;
      this.dialog.closeAll();
    });
    this.userService.user.pipe(untilDestroyed(this)).subscribe((res) => {
      this.user = res?.user;

      if (res?.user?.subscriptionType) {
        this.currentBilling = res?.user?.subscriptionType;
      }
      this.showInstitutionSettings =
        !!res?.user && this.aclService.isAllowed(res.user, Feature.edit_institution);
      this.isInstitutionUser = Boolean(res?.user.institution);
    });

    // Initialize all forms here and pass them to subpanels
    this.accountForm = this.formBuilder.group(
      {
        name: ['', Validators.required],
        profile_image: [''],
      },
      { updateOn: 'change' },
    );

    this.advancedSettingsForm = this.formBuilder.group(
      {
        settings: this.formBuilder.group({
          inactivityNotifications: [false, Validators.required],
        }),
      },
      { updateOn: 'change' },
    );

    this.institutionForm = this.formBuilder.group(
      {
        // institution settings
        settings: this.formBuilder.group({
          addAdminsAsHostsInSpace: [false, Validators.required],
          autoDesignateTeachersAsHosts: [false, Validators.required],
          preventTeachersfromCreatingSpaces: [false, Validators.required],
          inactivityNotifications: [true, Validators.required],
          crRecordingMode: [RecordingMode.MANUAL, Validators.required],
          crEnableHostDeletion: [false, Validators.required],
          crEnableDownloads: [false, Validators.required],
          crEnableAISummarization: [false, Validators.required],
          crEnablePrivacyAwareCloudRecording: [false, Validators.required],
          forceScreenShareInAutomaticCr: [false, Validators.required],
          liveCallAlerts: [false, Validators.required],
        }),
        // inside spaces permissions for institution
        permissions: this.formBuilder.group({
          enableWaitingRoom: [InstitutionPermissionState.DEFAULT_OFF, Validators.required],
          messageOtherParticipants: [InstitutionPermissionState.DEFAULT_OFF, Validators.required],
        }),
      },
      { updateOn: 'change' },
    );

    this.aiAssistForm = this.formBuilder.group(
      {
        settings: this.formBuilder.group({
          inactivityNotifications: [false, Validators.required],
          crEnableAISummarization: [false, Validators.required],
          liveCallAlerts: [false, Validators.required],
        }),
      },
      { updateOn: 'change' },
    );

    if (!this.isMobile) {
      this.activePanel = this.PANELS.ACCOUNT.id;
    }

    if (panelData && panelData.openTab) {
      (Object.values(this.PANELS) as PanelData[]).forEach((panel: PanelData) => {
        if (panel.id === panelData.openTab) {
          this.switchSubPanel(panelData.openTab);
        }
      });
    }

    this.flagsService
      .featureFlagChanged(FLAGS.INACTIVITY_NOTIFICATIONS)
      .pipe(untilDestroyed(this))
      .subscribe(
        (inactivityNotificationsEnabled) =>
          (this.inactivityNotificationsEnabled = inactivityNotificationsEnabled),
      );
  }
  ngOnDestroy(): void {
    this.domListener.clear();
  }

  private setupKeyListenerForClosingModal() {
    this.domListener.add(
      'document',
      'keyup.esc',
      () => {
        this.closePanel();
      },
      true,
    );
  }

  ngOnInit(): void {
    this.userService.rtl.pipe(untilDestroyed(this)).subscribe((res) => (this.isRTL = res));
  }

  async closePanel(): Promise<void> {
    if (this.formChanged) {
      const confirm = await this.confirmUnsavedChanges();
      if (!confirm) {
        return;
      }
    }

    if (this.isMobile) {
      this.bottomSheetRef?.dismiss();
    } else {
      this.dialogRef?.close();
    }
  }

  async switchSubPanel(id?: panelID): Promise<void> {
    if (this.formChanged) {
      const confirm = await this.confirmUnsavedChanges();
      if (!confirm) {
        return;
      }
    }

    if (this.isMobile) {
      const [matBottomSheetContainer] = Array.from(
        document.getElementsByTagName('mat-bottom-sheet-container'),
      );
      if (matBottomSheetContainer) {
        (matBottomSheetContainer as HTMLElement).style.height = id ? 'min(80vh, 600px)' : 'auto';
      }
    }
    this.activePanel = id;
    this.formChanged = false;
  }

  handleFormChanged(changedValue: boolean) {
    this.formChanged = changedValue;
  }

  async confirmUnsavedChanges(): Promise<boolean> {
    const messageLine = this.translateService.instant(
      'You have unsaved changes. Are you sure you want to close Settings?',
    );
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(ConfirmationModalV2Component, {
        panelClass: 'user-confirmation-dialog',
        data: {
          title: this.translateService.instant('Unsaved Changes'),
          body: `<div>${messageLine}</div>`,
          cancelButtonText: this.translateService.instant("Don't close"),
          confirmButtonText: this.translateService.instant('Discard changes'),
        },
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((res) => resolve(res));
    });
  }

  protected IconTypes = IconTypes;
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverURL: 'https://staging-api.pencilapp.com',
  firebase: {
    apiKey: 'AIzaSyAHox2vbFGB8hK6so-Hw-isb6EcGlsGOfo',
    authDomain: 'autopush.pencilapp.com',
    databaseURL: 'https://pencil-frontend-staging.firebaseio.com',
    projectId: 'pencil-frontend-staging',
    storageBucket: 'pencil-frontend-staging.appspot.com',
    messagingSenderId: '744637449088',
  },
  vapidPublicKey:
    'BKQc0AG-dGNzKr-hMDRhtkc1fTTznQVRs_M-MrhzVXOqrx2McoEn7OQX4i-EDD0DNBXOE5zSxpROMOp2jyjYrUc',
  sentryKey: 'https://ede17d29b50e449b8e253bb4e3fd759e@sentry.io/1501830',
  sentryOrganisationSlug: 'pencil',
  trainerUrl: 'https://staging.teachertrainers.com',
  fullstoryOrgId: 'YQF1C',
  fayeServer: 'https://rt-staging-api.pencilapp.com/faye',
  galactusServer: 'https://staging-apis.pencilapp.com',
  whiteboardServer: 'https://staging-apis.pencilapp.com',
  dailyDomain: 'https://pncl.daily.co',
  gtmId: 'GTM-MTTDPBB',
  doorbellProjectId: '12361',
  doorbellKey: 'NtenNU0c6N7XBOi06DAExGVBG1FyERh7g95PEDDAoqbecfq25txXejqt9FqXMeyn',
  youtube: {
    clientId: '744637449088-gsfokicc2n787r1g62ahpfhc1eecvict.apps.googleusercontent.com',
  },
  drive: {
    clientId: '744637449088-gsfokicc2n787r1g62ahpfhc1eecvict.apps.googleusercontent.com',
    developerKey: 'AIzaSyD6MzIiJGtL5Tl8TPLrQsLANevlwuLv5gE',
  },
  chargebee: {
    site: 'pncl-test',
    publishableKey: 'test_hITzZIyVpsqCgLOSwrhaBlcOchCwgI5D',
  },
  onedrive: {
    clientId: 'eca18dc9-7747-4a65-8f4c-a374b946968d',
  },
  pexels: {
    apiKey: 'RHfXkCr8NALkFLm4ILQBuDFkMcgtjHJunn5ZJoZEgDCTgkBGe97EUVuH',
  },
  clever: {
    clientId: '780956de9d4367dc6cc7',
    redirectURI: 'https://clever-login-hoigjxargq-uc.a.run.app',
  },
  classlink: {
    clientId: 'c1686507119175f02a1b43466faef990216136620337',
    redirectURI: 'https://classlink-login-staging-hoigjxargq-uc.a.run.app',
  },
  appScriptURL: 'https://pencilspaces.app.link/gcal',
  thirdPartyEncryptionSalt: 'Dks983$%KDJS2#$!%567LKjdHDKJksjdwie_shaduE726##DHUASTsgaue',
  googleStorageBucketURL: 'https://storage.googleapis.com/pencil-frontend-staging.appspot.com',
  firebaseAssetsPublicUrl:
    'https://storage.googleapis.com/pencil-frontend-staging.appspot.com/assets/',
  resourceLibraryFolderName: 'resource_library/',
  firebaseMathematicsPath: 'mathematics/',
  firebasePhysicsPath: 'physics/',
  firebaseChemistryPath: 'chemistry/',
  firebaseLiteracyPath: 'literacy/',
  firebaseEmojisAndStickersPath: 'others/',
  resourceLibraryItemsFilePath: 'resource-library-items_1.json',
  phetSimulationsFileName: 'phet_simulations_items_with_categories_.json',
  phetSimulationsPreviewImagesPath: 'phet_simulations_preview_images/',
  bioDigitalModelsFileName: 'bio_digital_items_1.json',
  bioDigitalModelsPreviewImagesPath: 'bio_digital_preview_images/',
  bioDigitalViewerUrl: 'https://human.biodigital.com/widget/?m={modelId}',
  simulcastScreenShare: false,
  svgSpriteName: 'sprite.71633b8c.svg',
  picassoServer: 'https://picasso.pencilapp.com:5000',
  marketingUrl: 'https://www.pencilspaces.com',
  pdfConverterServer: 'https://pdf-converter-staging.pencilapp.com',
  useDailyScreenShareBalancedPreset: true,
  resourceSignerServer: 'https://staging-apis.pencilapp.com/resource-signer',
  openAiServer: 'https://staging-apis.pencilapp.com/pencil-ai-assistant',
  runServiceWorkerLocally: false, // flip this to true to run service-worker locally using http-server (see package.json)
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// Import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-course-archive-confirm-dialog',
  templateUrl: './course-archive-confirm-dialog.component.html',
  styleUrls: ['./course-archive-confirm-dialog.component.scss']
})
export class CourseArchiveConfirmDialogComponent {
  courseName: string;
  courseNameCheck = '';

  constructor(
    public dialogRef: MatDialogRef<CourseArchiveConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.courseName = data.name;
  }

  close(): void {
    this.dialogRef.close();
  }

  submit(): void {
    this.dialogRef.close(true);
  }
}

import { EventEmitter, NgZone } from '@angular/core';
import { TrackType } from 'src/app/common/interfaces/rtc-interface';
import { modifiedSetInterval } from 'src/app/utilities/ZoneUtils';

interface AudioRender {
  polling?: NodeJS.Timer;
  audioContext?: AudioContext;
}

export interface CurrentlyDetectingAudio {
  trackType: TrackType;
  isCurrentlyDetected: boolean;
  meanAmplitude?: number | string;
  medianAmplitude?: number | string;
}

// Checks the volume of a MediaStreamTrack and if it's above
// a threshold then it emits an event using an EventEmitter
export class VolumeDetector extends EventEmitter<CurrentlyDetectingAudio> {
  private audioRender?: AudioRender;
  private lastEmittedCurrentlyDetectingAudio?: Partial<CurrentlyDetectingAudio>;
  private _audioTrack?: MediaStreamTrack;
  private _calculateSpeechStats = false;
  constructor(
    private readonly threshold: number,
    private ngZone: NgZone,
    enableSpeechStats?: boolean,
  ) {
    super();
    if (enableSpeechStats !== undefined) {
      this.calculateSpeechStats = enableSpeechStats;
    }
  }

  public set calculateSpeechStats(value: boolean) {
    this._calculateSpeechStats = value;
  }

  public startAudioRender(audioTrack: MediaStreamTrack, trackType: TrackType): void {
    // Added to avoid any weirdness from having two volume detectors on the same track
    this._audioTrack = audioTrack.clone();
    const context = window.AudioContext || (window as any).webkitAudioContext;

    if (!context) {
      return;
    }

    if (this.audioRender) {
      this.stopAudioRenderer(trackType);
    }

    const audioContext = new context();
    const audioAnalyzer = audioContext.createAnalyser();
    audioAnalyzer.smoothingTimeConstant = 0.3;
    audioAnalyzer.fftSize = 32;
    // Added to avoid having a disabled audio track while mic is muted as LiveKit is changing this property on mute state
    // Also it won't hurt as we are cloning the track entirely once starting the audio renderer
    // So, acutal broadcasted track won't be affected
    this._audioTrack.enabled = true;
    const audioStream = new MediaStream([this._audioTrack]);
    const source = audioContext.createMediaStreamSource(audioStream);
    source.connect(audioAnalyzer);
    const frequencyData = new Uint8Array(audioAnalyzer.frequencyBinCount);
    audioAnalyzer.getByteFrequencyData(frequencyData);

    // Returns amplitude values of the audio signal
    const audioAmplitudeData = new Uint8Array(audioAnalyzer.frequencyBinCount);

    this.ngZone.runOutsideAngular(() => {
      const polling = modifiedSetInterval(() => {
        audioAnalyzer.getByteFrequencyData(frequencyData);
        const volume = frequencyData.reduce((acc, curr) => acc + curr);

        const currentlyDetectingAudio: CurrentlyDetectingAudio = {
          trackType: trackType,
          isCurrentlyDetected: false,
        };

        if (volume > this.threshold) {
          currentlyDetectingAudio.isCurrentlyDetected = true;
        } else {
          currentlyDetectingAudio.isCurrentlyDetected = false;
        }
        if (
          this.lastEmittedCurrentlyDetectingAudio?.isCurrentlyDetected !==
          currentlyDetectingAudio.isCurrentlyDetected
        ) {
          this.lastEmittedCurrentlyDetectingAudio = currentlyDetectingAudio;
          // Only emits if there is a change & run this emission inside zone
          this.ngZone.run(() => {
            if (!this._calculateSpeechStats) {
              this.emit(currentlyDetectingAudio);
              return;
            }
            // Calculate amplitude stats only if audio detected is changed
            audioAnalyzer.getByteTimeDomainData(audioAmplitudeData);
            if (audioAmplitudeData.length === 0) {
              this.emit({
                ...currentlyDetectingAudio,
                meanAmplitude: 'N/A',
                medianAmplitude: 'N/A',
              });
              return;
            }
            const rescaledAmplitudeData = [...audioAmplitudeData.values()].map(
              (val) => (val - 127.5) / 127.5,
            );
            const meanAmplitude =
              rescaledAmplitudeData.reduce((acc, curr) => acc + curr) / audioAmplitudeData.length;
            // Sort the data to get the median
            rescaledAmplitudeData.sort((a, b) => a - b);
            const medianAmplitude =
              rescaledAmplitudeData[Math.floor(audioAmplitudeData.length / 2)];
            this.emit({ ...currentlyDetectingAudio, meanAmplitude, medianAmplitude });
          });
        }
      }, 200);

      this.audioRender = { audioContext, polling };
    });
  }

  public stopAudioRenderer(trackType: TrackType): void {
    if (this.audioRender?.polling) {
      clearInterval(this.audioRender.polling);
    }

    if (this.audioRender?.audioContext) {
      this.audioRender.audioContext.close();
    }
    const currentlyDetectingAudio = {
      trackType: trackType,
      isCurrentlyDetected: false,
      meanAmplitude: 0,
      medianAmplitude: 0,
    };
    this.emit(currentlyDetectingAudio);
    this.audioRender = undefined;
    this.lastEmittedCurrentlyDetectingAudio = undefined;
    this._audioTrack?.stop();
    this._audioTrack = undefined;
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IconTypes } from 'src/app/standalones/components/pencil-icon/pencil-icon.component';

@Component({
  selector: 'app-add-pencil-ai',
  templateUrl: './add-pencil-ai.component.html',
  styleUrls: ['./add-pencil-ai.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPencilAiComponent {
  userQuery?: string;
  placeholder?: string;
  readonly IconTypes = IconTypes;
  @Output() cancelAiRequest = new EventEmitter<void>();

  constructor(
    private dialogRef: MatDialogRef<AddPencilAiComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { type: 'promptInput' | 'processing' },
  ) {
    if (data.type === 'promptInput') {
      this.placeholder = 'Ask our AI to prepare a lesson about...';
    }
  }

  close() {
    this.dialogRef.close();
  }

  query() {
    this.dialogRef.close(this.userQuery);
  }

  cancel() {
    this.cancelAiRequest.emit();
    this.dialogRef.close();
  }
}

<svg width="12" height="32" viewBox="0 0 12 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M7.78479 28.6194L3.20656 32L0 27.4137L2.99872 9.16107L10.7776 10.3668L7.78479 28.6194Z"
    fill="#FEE133"
    [ngStyle]="logo?.logoType === sidebarLogoType.solid ? { fill: logo?.color || '' } : {}"
  />
  <path
    d="M10.3144 0.610581L6.53186 0.0221574C5.4155 -0.150908 4.34665 0.708651 4.14476 1.94319L3.63409 5.05837C3.55096 5.56603 3.8538 6.0333 4.31103 6.10253L10.4747 7.06016C10.932 7.12938 11.3714 6.77748 11.4545 6.26983L11.9652 3.15464C12.173 1.92588 11.4308 0.783646 10.3144 0.610581Z"
    fill="#E5616D"
    [ngStyle]="logo?.logoType === sidebarLogoType.solid ? { fill: logo?.color || '' } : {}"
  />
  />
</svg>

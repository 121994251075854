import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hiddenEmail',
})
export class HiddenEmailPipe implements PipeTransform {
  transform(email: string, ...args: unknown[]): string {
    if (!email) {
      return '';
    }
    const splittedEmail = email.split('@');
    const charsFromStart =
      splittedEmail[0].length < 2 ? 0 : Math.min(Math.floor(splittedEmail[0].length / 2), 3);
    if (splittedEmail.length === 1) {
      return `${splittedEmail[0].substring(0, charsFromStart)}...`;
    }
    const charsFromEnd = Math.min(Math.floor(splittedEmail[1].length / 2), 8);
    return `${splittedEmail[0].substring(0, charsFromStart)}...@...${splittedEmail[1].substring(
      splittedEmail[1].length - charsFromEnd,
    )}`;
  }
}

import { Injectable } from '@angular/core';
import { NSFWDetectionWorker } from 'src/app/web-workers/nsfw.worker';
import * as Comlink from 'comlink';
import * as FullStory from '@fullstory/browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap, tap, filter, take } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ImageClassifierResult } from '@mediapipe/tasks-vision';
import { modifiedSetTimeout } from './utilities/ZoneUtils';
import { GpuDetectorService } from './services/gpu-detector.service';
import { ProviderStateService } from './services/provider-state.service';
import { TrackEventAction, TrackType } from './common/interfaces/rtc-interface';
import { FLAGS, FlagsService } from './services/flags.service';
import { UploadFileService } from './services/upload-file.service';

declare let MediaStreamTrackProcessor: any;
declare let VideoFrame: any;
declare type VideoFrame = any;
declare let OffscreenCanvas: any;
declare type OffscreenCanvas = any;
declare let OffscreenCanvasRenderingContext2D: any;
declare type OffscreenCanvasRenderingContext2D = any;

export interface NSFWResult {
  result: ImageClassifierResult | undefined;
}

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class NsfwDetectorService {
  private _nsfwWorker?: Comlink.Remote<NSFWDetectionWorker>;
  private nsfwResult$: Subject<NSFWResult> = new Subject<NSFWResult>();
  private isProcessing = false;

  constructor(
    private gpuDetectorService: GpuDetectorService,
    private providerStateService: ProviderStateService,
    private flagsService: FlagsService,
    private uploadService: UploadFileService,
  ) {
    this.flagsService.featureFlagsChanged
      .asObservable()
      .pipe(
        filter(() => this.flagsService.isFlagEnabled(FLAGS.ENABLE_NSFW_DETECTION)), // Check the flag
        take(1), // Ensure subscription is set up only once
        switchMap(() =>
          this.providerStateService.trackEvents$.pipe(
            filter(
              (trackEvent) =>
                !!trackEvent?.participant.local && trackEvent.type === TrackType.VIDEO,
            ),
            tap((trackEvent) => {
              if (trackEvent?.action === TrackEventAction.START) {
                this.start(trackEvent?.participant.videoTrack);
              } else {
                // No need to do anything here since the moment the track is stopped the
                // look will stop automatically.
              }
            }),
          ),
        ),
        untilDestroyed(this), // Moved here to manage the subscription lifecycle
      )
      .subscribe();
  }

  private async start(videoTrack: MediaStreamTrack | undefined) {
    if (!videoTrack || !this.isBrowserSupported() || this.isProcessing) {
      // TODO: Maybe return an error in the subject, if processing start failed.
      return;
    }
    this.isProcessing = true;

    // If current user, then extract frames and send to nsfw service.
    // Initialize the web worker lazily if needed.
    // Do not put await on response from web worker.
    const trackProcessor = new MediaStreamTrackProcessor({ track: videoTrack });

    if (!this._nsfwWorker) {
      // lazy load the webgl worker only when needed
      const nsfwWorker = Comlink.wrap<typeof NSFWDetectionWorker>(
        new Worker(new URL('./web-workers/nsfw.worker', import.meta.url)),
      );
      this._nsfwWorker = await new nsfwWorker();
      await this._nsfwWorker.init(this.gpuDetectorService.hasMajorPerformanceCaveat());
    }

    const reader = trackProcessor.readable.getReader();
    // Listen for the end of the track and stop processing when it ends
    reader.closed.then(() => {
      this.isProcessing = false;
    });

    let imageCanvas: OffscreenCanvas;
    let context: OffscreenCanvasRenderingContext2D;
    this.processFrame(reader, imageCanvas, context);
  }

  private async processFrame(
    reader: ReadableStreamDefaultReader,
    imageCanvas: OffscreenCanvas,
    context: OffscreenCanvasRenderingContext2D,
  ) {
    const { value: videoFrame, done } = await reader.read();
    if (done) {
      return;
    }
    if (videoFrame) {
      await this.performClassification(videoFrame, imageCanvas, context);
    }
    modifiedSetTimeout(() => this.processFrame(reader, imageCanvas, context), 1000, true);
  }

  private async performClassification(
    videoFrame: VideoFrame,
    imageCanvas: OffscreenCanvas,
    context: OffscreenCanvasRenderingContext2D,
  ) {
    const returnFrameAsBitmap =
      this.flagsService.featureFlagsVariables[FLAGS.ENABLE_NSFW_DETECTION]?.log_positives;
    const classification = await this._nsfwWorker?.classify(
      Comlink.transfer(videoFrame, [videoFrame]),
      !!returnFrameAsBitmap,
    );
    if (!classification) {
      return;
    }
    if (classification.result?.classifications[0].categories[0].categoryName === 'nude') {
      if (classification.bitmap) {
        if (!imageCanvas) {
          imageCanvas = new OffscreenCanvas(
            classification.bitmap.width,
            classification.bitmap.height,
          );
          context = imageCanvas.getContext('2d');
        }
        context.drawImage(classification.bitmap, 0, 0);
        const blob = await imageCanvas.convertToBlob({ type: 'image/jpeg' });
        const file = new File([blob], 'positive.jpg', { type: 'image/jpeg' });
        this.uploadService.uploadToFireStorage(file, (url) => {
          FullStory.event('Safety', {
            ...classification.result.classifications[0].categories[0],
            image_url: url,
          });
        });
      } else {
        FullStory.event('Safety', classification.result.classifications[0].categories[0]);
      }
    }
    this.nsfwResult$.next({
      result: classification.result,
    });
  }

  public get nsfwResults$(): Observable<NSFWResult> {
    return this.nsfwResult$;
  }

  isBrowserSupported() {
    return 'MediaStreamTrackProcessor' in window && 'MediaStreamTrackGenerator' in window;
  }
}

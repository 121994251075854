import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {take} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {SpacesTemplatesCategories, SpacesTemplatesService} from '../../services/spaces-templates.service';
import {UserService} from '../../services/user.service';
import {User} from '../../models/user';
import {ISpaceTemplate} from '../../models/session';
import { NetworkService } from '../../services/network.service';

@UntilDestroy()
@Component({
  selector: 'app-choose-space-template-dialog',
  templateUrl: './choose-space-template-dialog.component.html',
  styleUrls: ['./choose-space-template-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseSpaceTemplateDialogComponent implements OnInit {

  isInstitutionTemplatesAvailable = false;
  user?: User;
  availableTemplates: ISpaceTemplate[] = [];
  currentDisplayedSpacesTemplates$ = new BehaviorSubject<ISpaceTemplate[]>([]);
  uiTemplates: ISpaceTemplate[] = [];
  selectedCategory = SpacesTemplatesCategories.INSTITUTION;
  templatesLoaded = false;

  constructor(
    private dialogRef: MatDialogRef<ChooseSpaceTemplateDialogComponent>,
    protected spacesTemplatesService: SpacesTemplatesService,
    protected userService: UserService,
    private networkService: NetworkService,
  ) { }

  ngOnInit(): void {
    this.userService
      .user
      .pipe(take(1))
      .subscribe(value => {
        this.user = value?.user;
        this.isInstitutionTemplatesAvailable = !!this.user?.institution._id;
      });

    this.loadTemplates();

    this.networkService.networkReconnected$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (!this.templatesLoaded) {
          this.loadTemplates();
        }
      });
  }

  loadTemplates() {
    this.spacesTemplatesService
      .getAllSpacesTemplates()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.templatesLoaded = true;
        this.availableTemplates = value;
        this.filterTemplatesBasedOnCurrentTab();
      });
  }

  close() {
    this.dialogRef.close();
  }

  filterTemplatesBasedOnCurrentTab() {
    if (this.selectedCategory === SpacesTemplatesCategories.INSTITUTION) {
      this.uiTemplates = this.availableTemplates.filter(template => template.institution && !template.isGeneral);
    } else {
      this.uiTemplates = this.availableTemplates.filter(template => template.isGeneral);
    }

    this.currentDisplayedSpacesTemplates$.next(this.uiTemplates);
  }

  selectedTabChanged(tabIndex: number) {
    if (this.isInstitutionTemplatesAvailable && tabIndex === 0) {
      this.selectedCategory = SpacesTemplatesCategories.INSTITUTION;
    } else {
      this.selectedCategory = SpacesTemplatesCategories.GENERAL;
    }

    this.filterTemplatesBasedOnCurrentTab();
  }

  filterTemplates(value: string) {
    this.currentDisplayedSpacesTemplates$.next(
      this.uiTemplates.filter(template => template.name.toLowerCase().includes(value.toLowerCase()))
    );
  }

  handleTemplateClicked(template?: ISpaceTemplate) {
    this.dialogRef.close({
      isTemplateClicked: true,
      template,
    });
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subscription, firstValueFrom } from 'rxjs';
import { debounceTime, filter, map, pairwise, timeout } from 'rxjs/operators';
import { NetworkEvent, NetworkThreshold } from '../common/interfaces/rtc-interface';
import { ConnectionStatus, NetworkQuality } from '../models/network';
import { FlagsService } from './flags.service';
import { ProviderStateService } from './provider-state.service';
import { TelemetryService } from './telemetry.service';

export interface ClientNetworkState {
  globalEndpointLatency: number;
  gcpEndpointLatency: number;
  gcpGlobalRegionProxy: string;
  globalEndpointError: string;
  gcpEndpointError: string;
  globalEndpointSuccess: number;
  gcpEndpointSuccess: number;
  globalEndpointFailure: number;
  gcpEndpointFailure: number;
  disconnectionReason?: string;
}

export interface NavigatorConnectionTarget {
  downlink: number;
  effectiveType: string;
  rtt: number;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class NetworkService implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private rtcNetworkEvent?: NetworkEvent;
  private readonly NETWORK_QUALITY_CHANGE_DELAY;
  browserIndicator: BehaviorSubject<ConnectionStatus> = new BehaviorSubject<ConnectionStatus>(
    window.navigator.onLine ? ConnectionStatus.CONNECTED : ConnectionStatus.DISCONNECTED,
  );
  networkReconnected$ = this.browserIndicator.pipe(
    pairwise(),
    filter(
      ([previousStatus, currentStatus]) =>
        previousStatus === ConnectionStatus.DISCONNECTED &&
        currentStatus === ConnectionStatus.CONNECTED,
    ),
    map(() => {}),
  );
  wbServer: BehaviorSubject<ConnectionStatus> = new BehaviorSubject<ConnectionStatus>(
    ConnectionStatus.UNKNOWN,
  );
  private _navigatorConnectionTarget?: NavigatorConnectionTarget;

  quality: BehaviorSubject<NetworkQuality> = new BehaviorSubject<NetworkQuality>(
    NetworkQuality.UNKNOWN,
  );

  readonly stableQuality: Observable<NetworkQuality>;

  private globalEndpoint = 'https://www.cloudflare.com/cdn-cgi/trace';
  private gcpEndpoint = 'https://global.gcping.com/api/ping';
  private failureCounts = { globalEndpoint: 0, gcpEndpoint: 0 };
  private successCounts = { globalEndpoint: 0, gcpEndpoint: 0 };

  constructor(
    private providerState: ProviderStateService,
    private http: HttpClient,
    private telemetryService: TelemetryService,
    private flagsService: FlagsService,
  ) {
    window.addEventListener('online', () => {
      // Not necessarily online.
      // It's true if the device is connected to the router but the router isn't connected to the internet
      this.browserIndicator.next(ConnectionStatus.CONNECTED);
    });

    window.addEventListener('offline', () => {
      // Definitely offline
      this.browserIndicator.next(ConnectionStatus.DISCONNECTED);
    });
    this.providerState.networkEvents$.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event) {
        this.rtcNetworkEvent = event;
        this.updateQuality();
      }
    });

    // This part is only supported in Chrome and Edge.
    if (navigator['connection']) {
      this.navigatorConnectionTarget = navigator[
        'connection'
      ] as unknown as NavigatorConnectionTarget;
      this.updateQuality();

      navigator['connection'].addEventListener('change', (e) => {
        this.navigatorConnectionTarget = e.target;
        this.updateQuality();
      });
    }

    this.NETWORK_QUALITY_CHANGE_DELAY =
      ((this.flagsService?.featureFlagsVariables?.weak_network_detection
        ?.weak_network_detection_delay as number) ?? 30) * 1000; // 30 seconds

    this.stableQuality = this.quality.pipe(debounceTime(this.NETWORK_QUALITY_CHANGE_DELAY));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  updateQuality() {
    const rtcQuality = this.calculateQualityFromRtcNetowrkEvent();
    const connectionQuality = this.calculateQualityFromConnectionTarget();
    if (rtcQuality === NetworkQuality.UNKNOWN) {
      this.setQuality(connectionQuality);
    } else if (connectionQuality === NetworkQuality.UNKNOWN) {
      this.setQuality(rtcQuality);
    } else if (
      rtcQuality === NetworkQuality.STRONG &&
      connectionQuality === NetworkQuality.STRONG
    ) {
      this.setQuality(NetworkQuality.STRONG);
    } else if (rtcQuality !== NetworkQuality.WEAK && connectionQuality !== NetworkQuality.WEAK) {
      this.setQuality(NetworkQuality.MEDIUM);
    } else {
      this.setQuality(NetworkQuality.WEAK);
    }
  }

  calculateQualityFromRtcNetowrkEvent(): NetworkQuality {
    if (!this.rtcNetworkEvent) {
      return NetworkQuality.UNKNOWN;
    }

    if (
      this.rtcNetworkEvent.threshold === NetworkThreshold.GOOD &&
      this.rtcNetworkEvent.quality &&
      this.rtcNetworkEvent.quality > 80
    ) {
      return NetworkQuality.STRONG;
    }

    const weakNetworkQualityThreshold =
      (this.flagsService.featureFlagsVariables?.weak_network_detection
        ?.weak_network_quality_threshold as number) ?? 50;
    if (
      this.rtcNetworkEvent.threshold !== NetworkThreshold.VERY_LOW &&
      this.rtcNetworkEvent.quality &&
      this.rtcNetworkEvent.quality > weakNetworkQualityThreshold
    ) {
      return NetworkQuality.MEDIUM;
    }

    return NetworkQuality.WEAK;
  }

  calculateQualityFromConnectionTarget(): NetworkQuality {
    if (!this.navigatorConnectionTarget) {
      return NetworkQuality.UNKNOWN;
    }

    if (
      this.navigatorConnectionTarget.effectiveType === '4g' &&
      this.navigatorConnectionTarget.rtt < 400
    ) {
      return NetworkQuality.STRONG;
    }

    if (
      (this.navigatorConnectionTarget.effectiveType === '4g' ||
        this.navigatorConnectionTarget.effectiveType === '3g') &&
      this.navigatorConnectionTarget.rtt < 700
    ) {
      return NetworkQuality.MEDIUM;
    }

    return NetworkQuality.WEAK;
  }

  setQuality(quality: NetworkQuality) {
    if (this.quality.value !== quality) {
      this.quality.next(quality);
      this.telemetryService.event('[Resources] network_quality_updated', {
        network_quality: quality,
      });
    }
  }

  async checkConnection(url: string, timeoutDuration: number, headers: HttpHeaders) {
    const start = new Date().getTime();
    try {
      const response: any = await firstValueFrom(
        this.http.get(url, { headers, responseType: 'text' }).pipe(timeout(timeoutDuration)),
      );

      const latency = new Date().getTime() - start;
      if (url === this.globalEndpoint) {
        this.failureCounts.globalEndpoint = 0; // reset failure counter on success
        this.successCounts.globalEndpoint++; // increment success counter on success
      } else if (url === this.gcpEndpoint) {
        this.failureCounts.gcpEndpoint = 0; // reset failure counter on success
        this.successCounts.gcpEndpoint++; // increment success counter on success
      }
      return { latency, globalRegionProxy: url === this.gcpEndpoint ? response.trim() : undefined };
    } catch (error) {
      if (url === this.globalEndpoint) {
        this.failureCounts.globalEndpoint++;
        this.successCounts.globalEndpoint = 0; // reset success counter on failure
      } else if (url === this.gcpEndpoint) {
        this.failureCounts.gcpEndpoint++;
        this.successCounts.gcpEndpoint = 0; // reset success counter on failure
      }
      return { error: error.message, latency: 'N/A' };
    }
  }

  async checkInternetAndGcpConnection(attempt: number): Promise<ClientNetworkState> {
    const timeoutDuration = 1000; // 1 second timeout
    const globalHeaders = new HttpHeaders({ 'X-Skip-Interceptor': '' });
    const gcpHeaders = new HttpHeaders({ 'X-Skip-Interceptor': '' });

    if (attempt == 1) {
      this.failureCounts = { globalEndpoint: 0, gcpEndpoint: 0 };
      this.successCounts = { globalEndpoint: 0, gcpEndpoint: 0 };
    }

    const [globalResponse, gcpResponse] = await Promise.all([
      this.checkConnection(this.globalEndpoint, timeoutDuration, globalHeaders),
      this.checkConnection(this.gcpEndpoint, timeoutDuration, gcpHeaders),
    ]);

    return {
      globalEndpointLatency: Number(globalResponse.latency),
      gcpEndpointLatency: Number(gcpResponse.latency),
      gcpGlobalRegionProxy: gcpResponse.globalRegionProxy,
      globalEndpointError: globalResponse.error,
      gcpEndpointError: gcpResponse.error,
      globalEndpointSuccess: this.successCounts.globalEndpoint,
      gcpEndpointSuccess: this.successCounts.gcpEndpoint,
      globalEndpointFailure: this.failureCounts.globalEndpoint,
      gcpEndpointFailure: this.failureCounts.gcpEndpoint,
    };
  }

  private get navigatorConnectionTarget(): NavigatorConnectionTarget | undefined {
    return this._navigatorConnectionTarget;
  }

  private set navigatorConnectionTarget(
    navigatorConnectionTarget: NavigatorConnectionTarget | undefined,
  ) {
    this._navigatorConnectionTarget = navigatorConnectionTarget;
    this.telemetryService.setNavigatorConnectionTarget(navigatorConnectionTarget);
  }
}

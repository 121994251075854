import { CommonModule } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IconTypes, PencilIconComponent } from '../pencil-icon/pencil-icon.component';

@Component({
  selector: 'app-pencil-pill',
  templateUrl: './pencil-pill.component.html',
  styleUrls: ['./pencil-pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, PencilIconComponent],
})
export class PencilPillComponent implements AfterContentChecked {
  @Input() text = '';
  @Input() icon = '';
  @Input() iconType: IconTypes = IconTypes.svgIcon;
  @Input() image = '';
  @Input() isRemovable = true;
  @Output() remove = new EventEmitter();
  @ContentChild('ng-content') ngContent?: ElementRef;

  isContentEmpty: boolean = true;

  removePill(): void {
    this.remove.emit();
  }

  ngAfterContentChecked() {
    this.isContentEmpty = this.ngContent === undefined;
  }

  protected IconTypes = IconTypes;
}

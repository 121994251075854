import { Injectable } from '@angular/core';
import { filterNil } from '@ngneat/elf';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Mutex } from 'async-mutex';
import { EMPTY, Subject, combineLatest, distinctUntilChanged, switchMap } from 'rxjs';
import { SpaceRepository } from '../state/space.repository';
import { ObservableUtils } from '../utilities/ObservableUtils';
import { modifiedInterval, modifiedSetTimeout } from '../utilities/ZoneUtils';
import { DomListenerFactoryService } from './dom-listener-factory.service';
import { ProviderStateService } from './provider-state.service';
import { SessionSharedDataService, SessionView } from './session-shared-data.service';
import { TimerService } from './timer.service';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class UserIdleService {
  private userIdleSubjectManager: Map<
    number,
    { subject: Subject<boolean>; timeoutId: NodeJS.Timer }
  > = new Map();
  private userIdleSubjectMutex = new Mutex();
  private idleTimeouts: Array<number> = [];
  public onHeartbeat$ = new Subject<void>();
  private domListener = this.domListenerFactoryService.createInstance();
  private mouseListenerPauseTimeout?: NodeJS.Timer;
  private isMouseListenerEnabled$ = new Subject<boolean>();

  constructor(
    private observerUtils: ObservableUtils,
    private timerService: TimerService,
    private spaceRepo: SpaceRepository,
    private sharedDataService: SessionSharedDataService,
    private providerStateService: ProviderStateService,
    private domListenerFactoryService: DomListenerFactoryService,
  ) {
    this._startListeningToPageActivity();
  }

  /**
   * @param timeoutDurationInSeconds
   * Note: This function only returns activity state when user is in a space
   * @returns A subject which will notify if user is active/inactive (boolean)
   */
  public async getUserIdleSubject(timeoutDurationInSeconds: number): Promise<Subject<boolean>> {
    return this.userIdleSubjectMutex.runExclusive(async () => {
      if (this.userIdleSubjectManager.has(timeoutDurationInSeconds)) {
        return this.userIdleSubjectManager.get(timeoutDurationInSeconds)!.subject;
      }
      const userIdleSubject = new Subject<boolean>();
      const timeoutId = await this.startIdleTimer(timeoutDurationInSeconds, userIdleSubject);
      this.userIdleSubjectManager.set(timeoutDurationInSeconds, {
        subject: userIdleSubject,
        timeoutId,
      });
      return userIdleSubject;
    });
  }

  private async onHeartbeat() {
    for (const [
      timeoutDurationInSeconds,
      { timeoutId, subject },
    ] of this.userIdleSubjectManager.entries()) {
      this.onHeartbeat$.next();
      await this.timerService.clearTimeout(timeoutId);
      const newTimeoutId = await this.timerService.setTimeout(() => {
        subject.next(false);
        this.idleTimeouts.push(timeoutDurationInSeconds);
      }, timeoutDurationInSeconds * 1000);
      this.userIdleSubjectManager.get(timeoutDurationInSeconds)!.timeoutId = newTimeoutId;

      if (this.idleTimeouts.length > 0) {
        for (const idleTimeoutDuration of this.idleTimeouts) {
          this.userIdleSubjectManager.get(idleTimeoutDuration)?.subject.next(true);
        }
        this.idleTimeouts = [];
      }
    }
  }

  private startIdleTimer(
    timeoutDurationInSeconds: number,
    userIdleSubject: Subject<boolean>,
  ): Promise<NodeJS.Timer> {
    return this.timerService.setTimeout(() => {
      userIdleSubject.next(false);
      this.idleTimeouts.push(timeoutDurationInSeconds);
    }, timeoutDurationInSeconds * 1000);
  }

  private _startListeningToPageActivity(): void {
    combineLatest([this.spaceRepo.activeSpaceId$, this.sharedDataService.sessionView.current$])
      .pipe(untilDestroyed(this))
      .subscribe(([spaceId, sessionView]) => {
        if (spaceId) {
          if ([SessionView.BREAKOUT_ROOMS, SessionView.FULLSCREEN_APP].includes(sessionView.view)) {
            this.isMouseListenerEnabled$.next(true);
          } else {
            this.isMouseListenerEnabled$.next(false);
          }
        } else if (sessionView.view === SessionView.WAITING_ROOM) {
          this.isMouseListenerEnabled$.next(true);
        } else {
          this._stopIdleTimers();
          this.isMouseListenerEnabled$.next(false);
        }
      });

    this.isMouseListenerEnabled$
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((isEnabled) => {
        if (isEnabled) {
          this.startMouseListener();
        } else {
          this.stopMouseListener();
        }
      });

    this.spaceRepo.activeSpaceId$
      .pipe(
        untilDestroyed(this),
        filterNil(),
        distinctUntilChanged(),
        switchMap(() => this.providerStateService.callConnected$),
        switchMap((isConnected) =>
          isConnected
            ? modifiedInterval(10000)
            : this.observerUtils.throttledObservableOf(
                this.sharedDataService.fabricCanvas?.onMouseMove$ || EMPTY,
                10000,
              ),
        ),
      )
      .subscribe(async () => {
        await this.onHeartbeat();
      });
  }
  /**
   * Called to stop existing idle timers when there is no active space
   */
  private _stopIdleTimers(): void {
    for (const { timeoutId } of this.userIdleSubjectManager.values()) {
      this.timerService.clearTimeout(timeoutId);
    }
  }

  private startMouseListener() {
    this.domListener.add(
      'document',
      'mousemove',
      () => {
        this.onHeartbeat();
        this.domListener.pauseAllListeners();
        const LISTENER_PAUSE_DURATION_MS = (Math.min(...this.idleTimeouts) * 1000) / 5;
        this.mouseListenerPauseTimeout = modifiedSetTimeout(
          () => {
            this.domListener.resumeAllListeners();
          },
          LISTENER_PAUSE_DURATION_MS,
          true,
        );
      },
      true,
    );
  }

  private stopMouseListener() {
    this.domListener.clear();
    if (this.mouseListenerPauseTimeout) {
      clearTimeout(this.mouseListenerPauseTimeout);
    }
  }
}

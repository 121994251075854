import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { retry } from 'rxjs/operators';
import {
  FilterData,
  IFilterAPI,
} from '../standalones/components/generic-filters-view/filters.interface';
import { Event, RECURRENCE_ACTION_TYPE } from '../models/event';
import { changeFiltersToAPIFormat } from './filters.service';
import { URLService } from './dynamic-url.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class EventService {
  newEventEmitter: EventEmitter<void> = new EventEmitter();
  currentEvent = new Subject<Event | null>();

  constructor(private http: HttpClient, private urlService: URLService) {}

  openCreateNewEvent() {
    this.newEventEmitter.emit();
  }

  createEvent(event: Event): Observable<any> {
    const createEventUrl = `${this.urlService.getDynamicUrl()}/events/`;
    return this.http
      .post(createEventUrl, JSON.stringify(event), {
        ...httpOptions,
        observe: 'response',
      })
      .pipe(retry(3));
  }

  getEventsUsingFilters(start: string, end: string, filters?: FilterData[]): Observable<any> {
    return this.getAllEvents(start, end, changeFiltersToAPIFormat(filters));
  }

  getAllEvents(start: string, end: string, filters?: IFilterAPI): Observable<any> {
    let params = new HttpParams().set('start', start).set('end', end);
    if (filters) {
      const encodedFilters = btoa(encodeURIComponent(JSON.stringify(filters)));
      params = params.set('filters', encodedFilters);
    }

    return this.http.get(`${this.urlService.getDynamicUrl()}/events/all`, { params });
  }

  deleteEvent(id: string, deleteType = RECURRENCE_ACTION_TYPE.CURRENT): Observable<any> {
    const deleteEventUrl = `${this.urlService.getDynamicUrl()}/events/${id}`;
    return this.http.delete(deleteEventUrl, {
      body: { deleteType },
    });
  }

  editEvent(event: Event, editType = RECURRENCE_ACTION_TYPE.CURRENT): Observable<any> {
    const editEventUrl = `${this.urlService.getDynamicUrl()}/events/${event._id}`;
    return this.http.patch(editEventUrl, {
      event,
      editType,
    });
  }
}

<div class="prompt-outline">
  <div class="header">
    <div>{{ 'Delete comment?' | translate }}</div>
    <button mat-icon-button (click)="close(false)"><mat-icon>close</mat-icon></button>
  </div>
  <div class="content">
    <div class="text">
      {{
        'Deleting this comment means no one can see this thread again. Are you sure you want to do this?'
          | translate
      }}
    </div>
    <div class="control-buttons">
      <button mat-button class="cancel" (click)="close(false)">
        <mat-icon>close</mat-icon>
        <span class="cancel-text">{{ 'Cancel' | translate }}</span>
      </button>
      <button mat-button class="delete" (click)="close(true)">
        <mat-icon>delete_outline</mat-icon>
        <span class="delete-text">{{ 'Delete' | translate }}</span>
      </button>
    </div>
  </div>
</div>

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-tag-confirmation',
  templateUrl: './tag-confirmation.component.html',
  styleUrls: ['./tag-confirmation.component.scss']
})
export class TagConfirmationComponent {
  public tag;
  action;

  constructor(private dialog: MatDialogRef<TagConfirmationComponent>, @Inject(MAT_DIALOG_DATA) public data) { 
    this.tag = data.tag;
    this.action = data.action;
  }

  cancel() {
    this.dialog.close(false)
  }

  confirm() {
    this.dialog.close(true)
  }
}

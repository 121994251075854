import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionSharedDataService } from 'src/app/services/session-shared-data.service';
import { NotificationToasterService } from 'src/app/services/notification-toaster.service';
import { ACCESS_REQUESTS } from 'src/app/common/utils/notification-constants';
import { ToastrService } from 'ngx-toastr';
import { AclService } from 'src/app/services/acl.service';
import { RequestAccessNotificationBaseService } from './base/request-access-notifications-base.service';

@Injectable({
  providedIn: 'root',
})
export class RequestAccessNotificationService extends RequestAccessNotificationBaseService {
  protected notificationCode: string;
  protected warningCode: string;
  protected errorCode: string;

  protected acceptButtontext: string;
  protected acceptAllButtontext: string;
  protected singleRequestNotificationText: string;
  protected multiRequestsNotificationText: string;
  protected msg: string;

  constructor(
    protected sessionSharedDataService: SessionSharedDataService,
    protected translateService: TranslateService,
    protected notificationToasterService: NotificationToasterService,
    protected toastr: ToastrService,
    protected aclService: AclService,
  ) {
    super(
      sessionSharedDataService,
      translateService,
      notificationToasterService,
      toastr,
      aclService,
    );

    this.notificationCode = ACCESS_REQUESTS.ACCESS_REQUESTS_NOTIFICATION;
    this.warningCode = ACCESS_REQUESTS.ACCESS_REQUESTS_WARNING;
    this.errorCode = ACCESS_REQUESTS.ACCESS_REQUESTS_ERROR;

    this.acceptButtontext = this.translateService.instant('Accept');
    this.acceptAllButtontext = this.translateService.instant('Accept All');
    this.singleRequestNotificationText = this.translateService.instant(
      'requested access to this Space',
    );
    this.multiRequestsNotificationText = this.translateService.instant('users requested access');
    // this is a necessary work around to avoid Toastr service considering WR and RA
    // notifications as duplicates, if they have the same message
    this.msg = `${this.translateService.instant('You can review them in Participant Manager.')} `;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { extractMath } from 'extract-math';
import { Fragment } from '../models/question';

@Pipe({
  name: 'includesMath',
})
export class IncludesMathPipe implements PipeTransform {
  transform(fragment: Fragment): any {
    let flag = false;
    if (fragment.data && extractMath(fragment.data)) {
      extractMath(fragment.data).forEach((d) => {
        if (d.math) {
          flag = true;
        }
      });
    }
    return flag;
  }
}

import {
  Directive,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  AfterViewInit,
  NgZone,
} from '@angular/core';

@Directive({
  selector: '[appIntersectionObserveElement]',
  exportAs: 'intersection',
})
export class IntersectionObserveElementDirective implements AfterViewInit, OnDestroy {
  @Input() root: HTMLElement | null = null;
  @Input() rootMargin = '20px';
  @Input() threshold = 0.5;

  @Output() isLastElementInView = new EventEmitter<boolean>();

  private intersectionObserver!: IntersectionObserver;
  private mutationObserver!: MutationObserver;
  private _isIntersecting = false;

  constructor(private element: ElementRef, private ngZone: NgZone) {}

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.initIntersectionObserver();
      this.initMutationObserver();
    });
  }

  ngOnDestroy() {
    this.intersectionObserver.disconnect();
    this.mutationObserver.disconnect();
  }

  private initMutationObserver() {
    this.mutationObserver = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          this.intersectionObserver.disconnect();
          this.intersectionObserver.observe(this.element.nativeElement.lastElementChild);
        }
      }
    });
    this.mutationObserver.observe(this.element.nativeElement, { childList: true });
  }

  private initIntersectionObserver() {
    const options: IntersectionObserverInit = {
      root: this.root,
      rootMargin: this.rootMargin,
      threshold: this.threshold,
    };

    this.intersectionObserver = new IntersectionObserver((entries) => {
      const { isIntersecting } = entries[0];
      if (this._isIntersecting === isIntersecting) {
        return;
      }
      this._isIntersecting = isIntersecting;
      this.ngZone.run(() => {
        if (isIntersecting) {
          this.isLastElementInView.emit(isIntersecting);
        }
      });
    }, options);
    this.intersectionObserver.observe(this.element.nativeElement.lastElementChild);
  }
}

import { fabric } from 'fabric';
import { calculateLines, LineCalculation } from './text-utils';

export class AppIText extends fabric.IText {
  __break_reason: string[] = [];
  cache: Map<string, LineCalculation[]> = new Map();

  wrapText(desiredWidth: number) {
    this._clearCache();
    const result = calculateLines(this.text as string, this.context, desiredWidth, this.cache);
    this.__break_reason = result.map((r) => r.breakType);
    const newLines = result.length ? result.map(({ line }) => line) : [''];
    this.textLines = newLines;
    this._unwrappedTextLines = this._textLines = newLines.map((line) => line.split(''));
    this._text = (this.text as string).split('');
    newLines.forEach((_, index) => this.getLineWidth(index));
    this.set('width', desiredWidth);
  }

  missingNewlineOffset(lineIndex: number) {
    if (this.__break_reason[lineIndex] === 'break-line') {
      return 1;
    }

    if (this.__break_reason[lineIndex] === 'no-space-for-word') {
      return 1;
    }
    return 0;
  }

  getSpaceOffset(lineIndex: number): number {
    if (this.textLines[lineIndex] && this.textLines[lineIndex].startsWith(' ')) {
      return 1;
    }

    return 0;
  }

  get2DCursorLocation(
    selectionStart?: number | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    skipWrapping?: boolean | undefined,
  ): { lineIndex: number; charIndex: number } {
    if (typeof selectionStart === 'undefined') {
      selectionStart = this.selectionStart as number;
    }

    let lineIndex = 0;
    for (lineIndex; lineIndex < this._unwrappedTextLines.length; lineIndex++) {
      const currentLine = this._unwrappedTextLines[lineIndex];

      if (selectionStart <= currentLine.length) {
        return {
          lineIndex,
          charIndex: selectionStart + this.getSpaceOffset(lineIndex),
        };
      }

      selectionStart -= currentLine.length + this.missingNewlineOffset(lineIndex);
    }
    const lastLine = this._unwrappedTextLines[this._unwrappedTextLines.length - 1];

    return {
      lineIndex: lineIndex - 1,
      charIndex: Number.isInteger(lastLine.length) ? lastLine.length + 1 : selectionStart,
    };
  }

  getSelectionStartFromPointer(e: MouseEvent): number {
    const mouseOffset = this.getLocalPointer(e);

    let height = 0;
    let charIndex = 0;
    let lineIndex = 0;

    // selecting the current line
    for (let i = 0, len = this._textLines.length; i < len; i++) {
      if (height <= mouseOffset.y) {
        height += this.__lineHeights[i];
        lineIndex = i;
        if (i > 0) {
          charIndex += this._textLines[i - 1].length + this.missingNewlineOffset(i - 1);
        }
      } else {
        break;
      }
    }

    const lineLeftOffset = Math.abs(this._getLineLeftOffset(lineIndex));
    let width = lineLeftOffset;
    const currentLineLength = this._textLines[lineIndex].length;
    let prevWidth = 0;
    for (let j = 0; j < currentLineLength; j++) {
      prevWidth = width;
      width = this.context.measureText(this.textLines[lineIndex].slice(0, j)).width;
      if (width <= mouseOffset.x) {
        charIndex++;
      } else {
        break;
      }
    }

    const newCursorPosition = this._getNewSelectionStartFromOffset(
      mouseOffset,
      prevWidth,
      width,
      charIndex,
    );

    return newCursorPosition;
  }

  _getNewSelectionStartFromOffset(
    mouseOffset: fabric.IPoint,
    prevWidth: number,
    width: number,
    index: number,
  ) {
    const distanceBtwLastCharAndCursor = mouseOffset.x - prevWidth;
    const distanceBtwNextCharAndCursor = width - mouseOffset.x;
    const offset =
      distanceBtwNextCharAndCursor > distanceBtwLastCharAndCursor ||
      distanceBtwNextCharAndCursor < 0
        ? 0
        : 1;
    let newSelectionStart = index + offset;

    if (newSelectionStart > this._text.length) {
      newSelectionStart = this._text.length;
    }

    return newSelectionStart;
  }

  fromStringToGraphemeSelection(start: number, end: number, text: string) {
    const smallerTextStart = text.slice(0, start);
    const graphemeStart = fabric.util.string.graphemeSplit(smallerTextStart).length;
    if (start === end) {
      return { selectionStart: graphemeStart, selectionEnd: graphemeStart };
    }
    const smallerTextEnd = text.slice(start, end);
    const graphemeEnd = fabric.util.string.graphemeSplit(smallerTextEnd).length;

    return {
      selectionStart: graphemeStart,
      selectionEnd: graphemeStart + graphemeEnd,
    };
  }

  _splitTextIntoLines(textInput: string) {
    const text = textInput || '';
    const result = calculateLines(
      (this.text as string) || '',
      this.context,
      this.width as number,
      this.cache,
    );
    this.__break_reason = result.map((r) => r.breakType);
    const newLines = result.length ? result.map(({ line }) => line) : [''];
    const _unwrappedLines = newLines.map((line) => line.split(''));

    return {
      _unwrappedLines: _unwrappedLines,
      lines: newLines,
      graphemeText: (text as string)?.split('') || '',
      graphemeLines: _unwrappedLines,
    };
  }

  get context(): CanvasRenderingContext2D {
    const context = this.getMeasuringContext();
    context.font = `${this.fontSize}px ${this.fontFamily}`;
    return context;
  }
}

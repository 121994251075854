<div
  class="
    d-flex
    align-items-center
    topics-topbar
    border-bottom
    cpx-16
    cpy-8
    bg-light
    justify-content-between
  "
>
  <div class="topics-topbar-left d-flex">
    <div
      class="d-flex align-items-center cmr-8 bold"
      [style.margin-top]="'-1px'"
      *ngIf="fromQuestionList && !fromPractice"
    >
      <i class="material-icons cmr-4 rotate-180 pcl-topics-list-alt" style="margin-left: -2px"
        >list_alt</i
      >{{ 'Topics' | translate }}
    </div>
    <app-search-bar
      [searchTitle]="'Search topics'"
      [searchContinuously]="true"
      (filter)="setSearchValue($event)"
    >
    </app-search-bar>
  </div>
  <div class="topics-topbar-right d-flex align-items-center">
    <i
      class="material-icons cursor-pointer btn-expand"
      *ngIf="isAllCollapsed"
      appTooltip="{{ 'Expand all' | translate }}"
      appTooltipArrow="bottom"
      (click)="expandAll()"
      >unfold_more</i
    >
    <i
      class="material-icons cursor-pointer btn-collapse"
      *ngIf="!isAllCollapsed"
      appTooltip="{{ 'Collapse all' | translate }}"
      appTooltipArrow="bottom"
      (click)="collapseAll()"
      >unfold_less</i
    >
    <button mat-icon-button (click)="closed.emit()" *ngIf="fromQuestionList && !fromPractice">
      <i class="material-icons">close</i>
    </button>
  </div>
</div>
<div *ngIf="topics?.length > 0 && !loading" class="topic-list-container">
  <div class="cpx-16 border-bottom d-flex cpy-8" *ngIf="mode === 'read_only'">
    <div class="flex-1">{{ 'Topic' | translate }}</div>
    <div class="question-counts-header">{{ 'MCQ' | translate }}</div>
    <div class="question-counts-header">{{ 'OEQ' | translate }}</div>
    <div [style.width]="chartConfig.width + 'px'" class="text-center">
      {{ 'Difficulty' | translate }}
    </div>
    <div class="topic-item-actions" [style.width]="mode !== 'read_only' ? '44px' : '18px'"></div>
  </div>
  <div
    *ngFor="let t of filteredTopics"
    class="cursor-pointer topic-item ugcFont"
    [class.checked]="t.checked || t.isChildChecked"
    [class.childChecked]="!t.checked && t.isChildChecked"
    [class.level1]="t.level === 1"
    (click)="expand(t, $event)"
    [ngClass]="t.class"
    [id]="'topic-item-' + t._id"
  >
    <div
      class="cpx-16 cpy-8 border-bottom justify-content-between align-items-center d-flex w-100"
      [style.margin-left]="
        (userService.rtl | async) ? 0 : (t.level - 1) * 20 + (t.level > 1 ? 48 : 0) + 'px'
      "
    >
      <div
        class="d-flex flex-1 systemFont"
        [style.margin-left]="(userService.rtl | async) ? 0 : (t.level > 1 ? -48 : 0) + 'px'"
      >
        <mat-checkbox
          color="primary"
          class="cmr-16 d-flex"
          [disabled]="t.child && mode === 'selection'"
          [style.visibility]="t.child && mode === 'selection' ? 'hidden' : 'visible'"
          *ngIf="mode === 'selection' || fromQuestionList"
          [checked]="t.checked"
          (change)="fromFiles ? null : checkTopic(t, $event)"
          (click)="fromFiles ? checkTopic(t, $event) : null"
        ></mat-checkbox>
        <div
          class="d-flex flex-1"
          [style.margin-right]="(userService.rtl | async) ? (t.level - 1) * 20 + 'px' : 0"
        >
          <span class="topic-number">{{ t.numbering }}.</span>
          <span class="flex-1">
            {{ t.name }}
            <span class="checked-children-count">{{
              { topics: filteredTopics, selectedIds: selectedTopicIds } | checkedTopicNum: t
            }}</span>
          </span>
        </div>
      </div>
      <div
        *ngIf="mode === 'read_only'"
        (click)="triggerTopicSearch(t._id, 'mcq')"
        class="question-counts systemFont"
      >
        {{ t.stats.multipleChoiceQuestions }}
      </div>
      <div
        *ngIf="mode === 'read_only'"
        (click)="triggerTopicSearch(t._id, 'oeq')"
        class="question-counts systemFont"
      >
        {{ t.stats.openEndedQuestions }}
      </div>
      <div *ngIf="mode === 'read_only'" [id]="'syllabusChart' + t._id">
        <app-charts
          [chartId]="'syllabusChart' + t._id"
          [chartConfig]="chartConfig"
          [topicStatsMap]="t.stats"
          [levels]="difficultyLevels"
          [levelString]="'Difficulty Level'"
        >
        </app-charts>
      </div>
      <img
        *ngIf="recommendedTopicIds?.includes(t._id)"
        class="cmr-16"
        src="assets/icons/topic-recommend.svg"
      />
      <div class="d-flex topic-item-actions align-items-center">
        <i class="material-icons expand-indicator" *ngIf="t.child && !t.expanded"
          >keyboard_arrow_down</i
        >
        <i class="material-icons expand-indicator" *ngIf="t.child && t.expanded"
          >keyboard_arrow_up</i
        >
      </div>
    </div>
  </div>
</div>
<div *ngIf="showMessageForNoCourse && !loading && !syllabusCode.length" class="empty-topics-msg">
  <span>Please select a course from the filter panel to view its topics.</span>
</div>
<app-content-loader
  *ngIf="loading && enableLoading"
  [blockCount]="11"
  [singleLineList]="true"
  [border]="false"
  [list]="true"
  [top]="20"
>
</app-content-loader>

<div class="move-to-folder-container">
  <div
    class="bg-light border-bottom cpt-16 cpb-12 cpl-32 cpr-20 title-container d-flex flex-row justify-content-between"
  >
    <div class="d-flex flex-row">
      <em
        *ngIf="currentFolder?._id !== rootFolder?._id"
        class="material-icons cpr-8 cursor-pointer"
        (click)="goToFolder(currentFolder?.parentFolder)"
      >
        arrow_back
      </em>
      <p class="text-center title">
        {{
          currentFolder?._id === rootFolder?._id
            ? ('Pencil Files' | translate)
            : (currentFolder?.name | translate)
        }}
      </p>
    </div>
    <span class="material-icons" mat-dialog-close> clear </span>
  </div>

  <div class="d-flex flex-row">
    <div class="search-container d-flex align-items-center">
      <div class="searchBoxContainer cml-32">
        <i style="cursor: pointer" class="material-icons" (click)="search()">search</i>
        <div class="searchInputContainer">
          <input
            class="searchBox searchBox-messages"
            [(ngModel)]="searchVal"
            [placeholder]="'Search folder' | translate"
            (keyup)="search()"
          />
          <i
            style="cursor: pointer"
            *ngIf="searchMode"
            (click)="cancelSearch()"
            class="material-icons"
            >cancel</i
          >
        </div>
      </div>
    </div>
    <div class="result-text d-flex align-items-center cml-8" *ngIf="searchMode">
      {{ filteredFolders.length + ' ' + 'results found' | translate }}
    </div>
  </div>

  <div class="file-list-container w-100">
    <div
      class="list-item-folder d-flex align-items-center"
      *ngFor="let folder of filteredFolders; let i = index"
      (mouseover)="selectedIndex = i"
      (mouseout)="setHoveredIndex(null)"
      [class.hover]="i === selectedIndex"
      [ngClass]="{ disabled: !folder._id }"
      (click)="goToFolder(folder._id)"
    >
      <div class="cml-24 d-flex">
        <i class="material-icons folder-icon cpr-8">folder</i>
        <div class="name">{{ folder.name | translate }}</div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-end btn-container align-items-center w-100">
    <button mat-button mat-dialog-close class="cmr-16 btn-dialog">
      <span class="material-icons"> clear </span>{{ 'Cancel' | translate }}
    </button>
    <button
      [disabled]="!currentFolder || currentFolder._id === data.activeFolderID"
      mat-raised-button
      class="cmr-24 btn-move"
      (click)="moveToFolder()"
    >
      <span class="material-icons"> check </span>
      {{ 'Move' | translate }}
    </button>
  </div>
</div>

<div class="d-flex align-items-center" #toolbox>
  <button class="btn-add-text btn" *ngIf="!hideAddText" (click)="add.emit('TEXT')">
    <i class="material-icons">subdirectory_arrow_right</i>
    <span>{{ 'Add text' | translate }}</span>
  </button>
  <button
    *ngIf="showSub && !(!hideAddText && toolboxWidth < 280)"
    class="btn-add btn visible"
    [disabled]="!enableAddSub"
    (click)="add.emit('SUB')"
  >
    <i class="material-icons">add</i>
    <span>{{ 'Sub-part' | translate }}</span>
  </button>
  <button
    *ngIf="showChoice && !(!hideAddText && toolboxWidth < 280)"
    class="btn-add btn visible"
    (click)="add.emit('OPTION')"
  >
    <i class="material-icons">add</i>
    <span class="pncl-editor-additional-toolbox-choice1">{{ 'Choice' | translate }}</span>
  </button>
  <button
    *ngIf="
      showOthers && !((!hideAddText && toolboxWidth < 380) || (hideAddText && toolboxWidth < 280))
    "
    class="btn-add btn visible"
    [disabled]="disableAnswer"
    (click)="add.emit('ANSWER')"
  >
    <i class="material-icons">add</i>
    <span>{{ 'Answer' | translate }}</span>
  </button>
  <div
    [ngbTooltip]="
      !disableAnswer ? ('Add an answer first before adding an explanation' | translate) : ''
    "
  >
    <button
      *ngIf="
        showOthers && !((!hideAddText && toolboxWidth < 500) || (hideAddText && toolboxWidth < 400))
      "
      class="btn-add btn"
      [class.show]="showMore && closePanel"
      [class.visible]="showMore && !closePanel"
      [disabled]="disableExplanation || !disableAnswer"
      (click)="add.emit('SOLUTION')"
    >
      <i class="material-icons">add</i>
      <span>{{ 'Explanation' | translate }}</span>
    </button>
  </div>
  <button
    *ngIf="
      showOthers && !((!hideAddText && toolboxWidth < 630) || (hideAddText && toolboxWidth < 540))
    "
    class="btn-add btn"
    [class.show]="showMore && closePanel"
    [class.visible]="showMore && !closePanel"
    [disabled]="disableResponse"
    (click)="add.emit('RESPONSE')"
  >
    <i class="material-icons">add</i>
    <span>{{ 'Response Box' | translate }}</span>
  </button>
  <button
    *ngIf="
      (showOthers &&
        !((!hideAddText && toolboxWidth < 710) || (hideAddText && toolboxWidth < 610))) ||
      showHint
    "
    class="btn-add btn"
    [class.show]="showMore && closePanel"
    [class.visible]="showMore && !closePanel"
    [disabled]="disableHint"
    (click)="add.emit('HINT')"
  >
    <i class="material-icons">add</i>
    <span>{{ 'Hint' | translate }}</span>
  </button>
  <button
    *ngIf="
      showOthers && !((!hideAddText && toolboxWidth < 800) || (hideAddText && toolboxWidth < 650))
    "
    class="btn-add btn"
    [class.show]="showMore && closePanel"
    [class.visible]="showMore && !closePanel"
    [disabled]="disablePoints"
    (click)="add.emit('POINTS')"
  >
    <i class="material-icons">add</i>
    <span>{{ 'Marks' | translate }}</span>
  </button>
  <button
    *ngIf="
      showOthers && ((!hideAddText && toolboxWidth < 800) || (hideAddText && toolboxWidth < 650))
    "
    class="btn-add btn"
    [class.show]="showMore && closePanel"
    [class.visible]="showMore && !closePanel"
    [matMenuTriggerFor]="more"
  >
    <i class="material-icons">more_horiz</i>
  </button>
  <button
    *ngIf="showOthers || showHint"
    class="btn-slide btn"
    [class.opened]="showMore"
    (click)="handleClose()"
  >
    <i class="material-icons" *ngIf="!showMore">keyboard_arrow_right</i>
    <i class="material-icons" *ngIf="showMore">keyboard_arrow_left</i>
  </button>
  <mat-menu #more="matMenu">
    <button
      mat-menu-item
      class="btn-menu"
      *ngIf="showSub && !hideAddText && toolboxWidth < 280"
      [disabled]="!enableAddSub"
      (click)="add.emit('SUB')"
    >
      <i class="material-icons">add</i>
      <span>{{ 'Sub-part' | translate }}</span>
    </button>
    <button
      mat-menu-item
      class="btn-menu"
      *ngIf="showChoice && !hideAddText && toolboxWidth < 280"
      (click)="add.emit('OPTION')"
    >
      <i class="material-icons">add</i>
      <span class="pncl-editor-additional-toolbox-choice2">{{ 'Choice' | translate }}</span>
    </button>
    <button
      mat-menu-item
      class="btn-menu"
      *ngIf="(!hideAddText && toolboxWidth < 380) || (hideAddText && toolboxWidth < 280)"
      [disabled]="disableAnswer"
      (click)="add.emit('ANSWER')"
    >
      <i class="material-icons">add</i>
      <span>{{ 'Answer' | translate }}</span>
    </button>
    <div
      [ngbTooltip]="
        !disableAnswer ? ('Add an answer first before adding an explanation' | translate) : ''
      "
    >
      <button
        mat-menu-item
        class="btn-menu"
        *ngIf="(!hideAddText && toolboxWidth < 500) || (hideAddText && toolboxWidth < 400)"
        [disabled]="disableExplanation || !disableAnswer"
        (click)="add.emit('SOLUTION')"
      >
        <i class="material-icons">add</i>
        <span>{{ 'Explanation' | translate }}</span>
      </button>
    </div>
    <button
      mat-menu-item
      class="btn-menu"
      *ngIf="(!hideAddText && toolboxWidth < 630) || (hideAddText && toolboxWidth < 540)"
      [disabled]="disableResponse"
      (click)="add.emit('RESPONSE')"
    >
      <i class="material-icons">add</i>
      <span>{{ 'Response Box' | translate }}</span>
    </button>
    <button
      mat-menu-item
      class="btn-menu"
      *ngIf="(!hideAddText && toolboxWidth < 710) || (hideAddText && toolboxWidth < 610)"
      [disabled]="disableHint"
      (click)="add.emit('HINT')"
    >
      <i class="material-icons">add</i>
      <span>{{ 'Hint' | translate }}</span>
    </button>
    <button
      mat-menu-item
      class="btn-menu"
      *ngIf="(!hideAddText && toolboxWidth < 800) || (hideAddText && toolboxWidth < 650)"
      [disabled]="disablePoints"
      (click)="add.emit('POINTS')"
    >
      <i class="material-icons">add</i>
      <span>{{ 'Marks' | translate }}</span>
    </button>
  </mat-menu>
</div>

import { TranslateService } from '@ngx-translate/core';
import { DeviceAndBrowserDetectorService } from 'src/app/services/device-and-browser-detector.service';
import { DeviceErrorsNotificationsService } from 'src/app/services/device-errors-notifications.service';
import { DeviceErrorType, DeviceState } from 'src/app/models/device-manger';
import { DeviceType } from '../devices-handle-util';
import {
  DeviceErrorHelperModelBase,
  DeviceErrorHelperStep,
} from './device-error-helper-model-base';

export class NotFoundHelper extends DeviceErrorHelperModelBase {
  private _steps: DeviceErrorHelperStep[] = [];

  constructor(
    deviceDetector: DeviceAndBrowserDetectorService,
    translateService: TranslateService,
    private deviceErrorsNotificationsService: DeviceErrorsNotificationsService,
    deviceType: DeviceType,
  ) {
    super(translateService, deviceDetector, deviceType);
  }

  getSteps(): DeviceErrorHelperStep[] {
    this._steps = [];
    if (this.deviceDetector.isDesktop()) {
      this.desktopSteps();
    } else {
      this.mobileSteps();
    }

    return this._steps;
  }

  getMobileTitle(): string {
    return this.translateService.instant('Fix No Camera / Mic Detected Error');
  }

  getDesktopTitle(): string {
    return this.translateService.instant('Fix No Camera / Mic Detected Error');
  }

  dismissErrorNotification() {
    this.deviceErrorsNotificationsService.dismissNotFoundErrorNotification();
  }

  hasRelatedError(cam: DeviceState, mic: DeviceState) {
    return (
      this.deviceErrorsNotificationsService.hasErrorOfType(cam, DeviceErrorType.NOT_FOUND) ||
      this.deviceErrorsNotificationsService.hasErrorOfType(mic, DeviceErrorType.NOT_FOUND)
    );
  }

  showErrorNotification(cam: DeviceState, mic: DeviceState) {
    this.deviceErrorsNotificationsService.showNotFoundErrorNotification(cam, mic);
  }

  desktopSteps() {
    this._steps = [
      {
        showStepLocation: false,
        stepText: `\
${this.translateService.instant('Re-connect your devices and check\n')}\
${this.translateService.instant('your system settings to verify they are\n')}\
${this.translateService.instant('working. Refresh Pencil Spaces.')}`,
        stepImgSrc: 'notFoundSteps/desktop/step-1.png',
        stepWidth: 256,
      },
      {
        showStepLocation: false,
        stepText: `\
${this.translateService.instant('If that doesn’t work, check if your devices\n')}\
${this.translateService.instant(
  'work on <a style="color: #BBD8FF; text-decoration: underline" target="_blank"  href="https://meet.google.com/new"> meet.google.com </a>. If Google Meet\n',
)}\
${this.translateService.instant('doesn’t work, try restarting your browser.')}`,
        stepImgSrc: 'notFoundSteps/desktop/step-2.png',
        stepWidth: 256,
      },
      {
        showStepLocation: false,
        stepText: `\
${this.translateService.instant('If issues persist, try a different browser.\n')}\
${this.translateService.instant('Install any pending software updates\n')}\
${this.translateService.instant('and restart your computer.')}`,
        stepImgSrc: 'notFoundSteps/desktop/step-3.png',
        stepWidth: 256,
      },
    ];
  }

  mobileSteps() {
    this._steps = [
      {
        showStepLocation: false,
        stepText: `\
${this.translateService.instant('Reconnect your device and check your\n')}\
${this.translateService.instant('system settings. Refresh Pencil Spaces.')}`,
        stepImgSrc: 'notFoundSteps/mobile/step-1.png',
        stepWidth: 256,
      },
      {
        showStepLocation: false,
        stepText: `\
${this.translateService.instant('If that doesn’t work, check if your devices\n')}\
${this.translateService.instant(
  'work on <a style="color: #BBD8FF; text-decoration: underline" target="_blank"  href="https://meet.google.com/new"> meet.google.com </a>. If Google Meet\n',
)}\
${this.translateService.instant('doesn’t work, try restarting your browser.')}`,
        stepImgSrc: 'notFoundSteps/mobile/step-2.png',
        stepWidth: 256,
      },
      {
        showStepLocation: false,
        stepText: `\
${this.translateService.instant('If issues persist, try a different browser.\n')}\
${this.translateService.instant('Install any pending software updates and\n')}\
${this.translateService.instant('restart your computer.')}`,
        stepImgSrc: 'notFoundSteps/mobile/step-3.png',
        stepWidth: 256,
      },
    ];
  }
}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {
  combineLatest,
  delay,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  FEEDBACK_FEATURE,
  RATING_SELECTION,
  SpacesExperienceDialogComponent,
} from '../dialogs/spaces-experience-dialog/spaces-experience-dialog.component';
import { IconMessageToasterElement } from '../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { ToasterPopupStyle } from '../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { SUCCESSES } from '../common/utils/notification-constants';
import { UserData, roleValueIDs } from '../models/user';
import { SpaceRepository } from '../state/space.repository';
import { modifiedTimer } from '../utilities/ZoneUtils';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from './notification-toaster.service';
import { UiService } from './ui.service';
import { FLAGS, FlagsService } from './flags.service';
import { UserService } from './user.service';
import { AclService } from './acl.service';
import { ProviderStateService } from './provider-state.service';
import { AppRatingService } from './app-rating.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SpacesExperienceDialogService {
  private dialogRef: MatDialogRef<SpacesExperienceDialogComponent> | null = null;
  private sheetRef: MatBottomSheetRef<SpacesExperienceDialogComponent> | null = null;
  private static readonly SPACES_EXPERIENCE_DIALOG_CACHE_KEY = 'experience-dialog-cache';
  private ratingShownInSession = false;
  private isStudent = false;
  private isFlagEnabled = false;
  private flagConfigVars: Record<string, any> = {};
  private ignoreCallConnectionChange = false;

  constructor(
    private ratingDialog: MatDialog,
    private ratingBottomSheet: MatBottomSheet,
    private uiService: UiService,
    private translateService: TranslateService,
    private notificationToasterService: NotificationToasterService,
    private flagsService: FlagsService,
    private userService: UserService,
    private aclService: AclService,
    private spaceRepo: SpaceRepository,
    private providerStateService: ProviderStateService,
    private appRatingService: AppRatingService,
  ) {
    this.flagsService.featureFlagChanged(FLAGS.SPACES_EXPERIENCE_RATING).subscribe(() => {
      this.isFlagEnabled = this.flagsService.isFlagEnabled(FLAGS.SPACES_EXPERIENCE_RATING);
      this.flagConfigVars = this.flagsService.featureFlagsVariables[FLAGS.SPACES_EXPERIENCE_RATING];
      this._initFireOnCallComplete();
      this._initFireDuringSession();
    });
  }

  private _initFireDuringSession(): void {
    // Start timer when someone joins a Space
    combineLatest([
      this.userService.user.pipe(map((res) => this._checkIfStudent(res))),
      this.spaceRepo.hasActiveSpaceSynced$,
      this.spaceRepo.activeSpaceId$,
      this.spaceRepo.isCurrentUserHost$,
    ])
      .pipe(
        tap(() => (this.ratingShownInSession = false)),
        distinctUntilChanged(this._isNewSpaceSession),
        filter((res) => this._checkIfShouldShowDuringSession(res)),
        delay(this.flagConfigVars.minSessionTime as number),
        switchMap(() => this._showShowRatingTimer()),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.triggerDialog();
      });
  }

  private _initFireOnCallComplete(): void {
    // to not show the rating dialog when the call is disconnected
    // then reconnected due to a room change.
    this.spaceRepo.activeSpaceCurrentRoom$.subscribe(
      () => (this.ignoreCallConnectionChange = true),
    );
    this.providerStateService.callConnected$
      .pipe(
        tap((isCallActive) => {
          if (isCallActive) {
            this.ignoreCallConnectionChange = false;
          }
        }),
        filter((isCallActive) => !!this.spaceRepo.activeSpace && !isCallActive),
        tap(() => {
          if (!this.appRatingService.shouldShowRatingModal && !this.ignoreCallConnectionChange) {
            this.showDialogOnCallEnd();
          }
        }),
      )
      .subscribe();
  }

  private _checkIfStudent(userData: UserData | null): boolean {
    return userData && userData.user
      ? (userData.user.info?.role === roleValueIDs.student && !userData.user.institution) ||
          (userData.user.institution && this.aclService.isStudent(userData.user))
      : false;
  }

  private _isNewSpaceSession(prev: Array<any>, curr: Array<any>): boolean {
    // Pass through if activeSpaceId is null -> so joining / leaving and rejoining Space again should work
    return JSON.stringify(prev) == JSON.stringify(curr) || !curr[2];
  }

  private _checkIfShouldShowDuringSession(res: any[]): boolean {
    const [isStudent, spaceSynced, activeSpaceId, userIsHost] = res;
    if (!Object.keys(this.flagConfigVars).length || !activeSpaceId || !spaceSynced) {
      return false;
    }
    // Determine whether we want to show based on prob configured in environment variables
    this.isStudent = isStudent || !userIsHost; // Also restrict non-hosts from receiving notifications according to `studentProb`
    const showProb = (
      this.isStudent ? this.flagConfigVars['studentProb'] : this.flagConfigVars['nonStudentProb']
    ) as number;
    return this.isFlagEnabled && Math.random() < showProb && spaceSynced;
  }

  private _showShowRatingTimer() {
    return modifiedTimer(0, this.flagConfigVars.intervalLength as number).pipe(
      takeWhile(
        () =>
          // Only enable if rating not shown today and the user is in a Space; if not, complete
          (this.flagConfigVars['restrictPerDay'] ? !this._isRatingShownToday() : true) &&
          !!this.spaceRepo.activeSpace &&
          !this.ratingShownInSession,
      ),
      filter(() => Math.random() < (this.flagConfigVars.intervalShowProb as number)),
      take(1), // Take the first case where this fires and complete
    );
  }

  private _sendPositiveFeedbackNotification(): void {
    const topElement = new IconMessageToasterElement(
      { icon: 'check', size: 16 },
      this.translateService.instant('Thanks for sharing your feedback!'),
    );
    const notificationData = new NotificationDataBuilder(SUCCESSES.RATING_POSITIVE_GIVEN)
      .type(NotificationType.SUCCESS)
      .style(ToasterPopupStyle.SUCCESS)
      .priority(380)
      .timeOut(5)
      .dismissable(false)
      .topElements([topElement])
      .middleElements([])
      .bottomElements([])
      .build();
    this.notificationToasterService.showNotification(notificationData);
  }

  private _sendNegativeFeedbackNotification(): void {
    const topElement = new IconMessageToasterElement(
      { icon: 'check', size: 16 },
      this.translateService.instant(
        'Thanks for sharing - we’re working hard to improve your experience',
      ),
    );
    const notificationData = new NotificationDataBuilder(SUCCESSES.RATING_NEGATIVE_GIVEN)
      .type(NotificationType.SUCCESS)
      .style(ToasterPopupStyle.SUCCESS)
      .priority(380)
      .timeOut(5)
      .dismissable(false)
      .topElements([topElement])
      .middleElements([])
      .bottomElements([])
      .build();
    this.notificationToasterService.showNotification(notificationData);
  }

  private _isRatingShownToday(): boolean {
    try {
      const today = new Date().toLocaleDateString();
      const lastFiredDate = localStorage.getItem(
        SpacesExperienceDialogService.SPACES_EXPERIENCE_DIALOG_CACHE_KEY,
      );
      return today === lastFiredDate;
    } catch (e) {
      return false;
    }
  }

  private _markRatingShownToday(): void {
    try {
      const today = new Date().toLocaleDateString();
      localStorage.setItem(SpacesExperienceDialogService.SPACES_EXPERIENCE_DIALOG_CACHE_KEY, today);
    } catch (error) {
      return;
    }
  }

  showDialogOnCallEnd(): void {
    const showProb = (
      this.isStudent ? this.flagConfigVars['studentProb'] : this.flagConfigVars['nonStudentProb']
    ) as number;
    if (
      this.isFlagEnabled &&
      Math.random() < showProb &&
      (this.flagConfigVars['restrictPerDay'] ? !this._isRatingShownToday() : true) &&
      !this.ratingShownInSession
    ) {
      this.triggerDialog(true);
    }
  }

  triggerDialog(eventInPast?: boolean, feedbackFeature = FEEDBACK_FEATURE.SPACES_EXPERIENCE): void {
    if (!eventInPast && this.notificationToasterService.getActiveNotificationsCount() > 0) {
      return; // Skip if there any active notifications and try later. Always show during end call.
    }
    this.forceDismissDialog(); // Force close dialog if already active (shouldn't be)
    this.ratingShownInSession = true;
    if (this.uiService.isMobile.getValue()) {
      if (feedbackFeature === FEEDBACK_FEATURE.LESSON_GENERATOR) {
        return;
      }
      if (!this.sheetRef) {
        this.sheetRef = this.ratingBottomSheet.open(SpacesExperienceDialogComponent, {
          panelClass: 'spaces-experience-mat-sheet',
          disableClose: true,
          autoFocus: false,
          data: { isSessionCompleted: eventInPast, feedbackFeature },
        });
        this.sheetRef.instance.closed.subscribe((r) => {
          this.handleDialogClose(r);
        });
        this.sheetRef.afterDismissed().subscribe(() => {
          this.sheetRef = null;
        });
      }
    } else {
      if (!this.dialogRef) {
        this.dialogRef = this.ratingDialog.open(SpacesExperienceDialogComponent, {
          disableClose: true,
          autoFocus: false,
          hasBackdrop: false,
          panelClass: 'spaces-experience-mat-dialog',
          data: { isSessionCompleted: eventInPast, feedbackFeature },
          width: '320px',
          maxWidth: '320px',
        });

        this.dialogRef.componentInstance.closed.subscribe((r) => {
          this.handleDialogClose(r, feedbackFeature);
        });

        this.dialogRef.afterClosed().subscribe(() => {
          this.dialogRef = null;
        });
      }
    }
  }

  forceDismissDialog(): void {
    this.handleDialogClose(RATING_SELECTION.UNSELECTED);
  }

  handleDialogClose(status: RATING_SELECTION, feedbackFeature?: FEEDBACK_FEATURE): void {
    switch (status) {
      case RATING_SELECTION.POSITIVE:
        this._sendPositiveFeedbackNotification();
        break;
      case RATING_SELECTION.NEGATIVE:
        this._sendNegativeFeedbackNotification();
        break;
      case RATING_SELECTION.UNSELECTED:
        break;
    }
    if (
      feedbackFeature === FEEDBACK_FEATURE.SPACES_EXPERIENCE &&
      status !== RATING_SELECTION.UNSELECTED
    ) {
      this._markRatingShownToday();
    }
    if (this.dialogRef) {
      this.dialogRef?.close();
    }
    if (this.sheetRef) {
      this.sheetRef.dismiss();
    }
  }
}

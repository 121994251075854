<h1 mat-dialog-title>{{ 'Do you want to accept the video call?' | translate }}</h1>
<app-profile-photo
  class="call-animation d-inline-block mx-auto"
  [user]="data.profile"
  [fontSize]="30"
  [width]="100"
></app-profile-photo>
<div mat-dialog-actions class="d-flex justify-content-between">
  <button mat-raised-button color="primary" (click)="dialogRef.close(true)">
    {{ 'Yes' | translate }}
  </button>
  <button mat-raised-button color="warn" (click)="dialogRef.close(false)">
    {{ 'No' | translate }}
  </button>
</div>

import { Component } from '@angular/core';
import { NetworkQuality } from 'src/app/models/network';
import { NetworkService } from 'src/app/services/network.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from 'src/app/services/user.service';
import { SwUpdate } from '@angular/service-worker';
import { versions } from 'src/environments/versions';
import { modifiedSetTimeout } from 'src/app/utilities/ZoneUtils';

@UntilDestroy()
@Component({
  selector: 'app-upgrade-version-notification',
  templateUrl: './upgrade-version-notification.component.html',
  styleUrls: ['./upgrade-version-notification.component.scss'],
})
export class UpgradeVersionNotificationComponent {
  weakNetwork = false;
  public versions = versions;

  constructor(
    private userService: UserService,
    private networkService: NetworkService,
    private swUpdate: SwUpdate,
  ) {
    this.networkService.quality.pipe(untilDestroyed(this)).subscribe((quality) => {
      this.weakNetwork = quality === NetworkQuality.WEAK;
    });

    this.swUpdate.checkForUpdate();
    this.swUpdate.available.pipe(untilDestroyed(this)).subscribe(() => {
      this.swUpdate.activateUpdate().then(() => {
        this.reload();
      });
    });

    modifiedSetTimeout(() => {
      this.reload();
    }, 60000);
  }

  reload(): void {
    this.userService.isUpdateButtonClicked.next(true);
    window.location.href = this.addQueryParamToUrl(window.location.href);
    window.location.reload();
  }

  addQueryParamToUrl(url: string) {
    const ulrArr = url.split('#');
    const urlQry = ulrArr[0].split('?');
    const usp = new URLSearchParams(urlQry[1] || '');
    usp.set('reload' || '_z', `${Date.now()}`);
    urlQry[1] = usp.toString();
    ulrArr[0] = urlQry.join('?');
    return ulrArr.join('#');
  }
}

<div
  class="pill-details"
  [class.isTextOnly]="image === '' && icon === ''"
  [class.no-close-btn]="!isRemovable"
>
  <img [src]="image" *ngIf="image !== ''" alt="pill icon" class="pill-icon" />
  <app-pencil-icon
    *ngIf="icon !== ''"
    [name]="icon"
    [type]="iconType"
    [wrapperStyle]="{ background: 'var(--pencil-pill-icon-background, #f2f2f2)' }"
    class="pill-icon"
  ></app-pencil-icon>
  <span
    *ngIf="text"
    class="pill-text"
  >
    {{ text }}
  </span>
  <ng-content></ng-content>
</div>
<app-pencil-icon
  *ngIf="isRemovable"
  name="close"
  [type]="IconTypes.materialIcon"
  class="remove-pill"
  (click)="removePill()"
></app-pencil-icon>

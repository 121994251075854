import { NavigationExtras } from '@angular/router';
import { NotificationContent, NotificationExtended } from 'src/app/models/global.interface';
import { URL_CONSTANTS } from '../utils/url';

/* The TypeScript interfaces for Notification and NotificationContent suggest
 * that the null checks are unnecessary here, but including to match existing
 * code.
 */
const buildMessageFragmentFromContent = (content: NotificationContent): NavigationExtras => {
  if (content?.message_id) {
    return { fragment: content.message_id._id };
  } else {
    return {};
  }
};

type url = string;

const buildRouterParamsFromNotification = ({
  content,
  context,
  user_id,
}: NotificationExtended): [url, NavigationExtras] => {
  const { question, note, session, worksheet, users, event } = context;

  if (question) {
    /* If we are navigating to comments we want to add fragment to url
     * so that we can scroll to that comment on comments page.
     */
    return [`/content/view/${question}`, buildMessageFragmentFromContent(content)];
  } else if (note) {
    return [`/content/notes/view/${note}`, {}];
  } else if (session) {
    /* Eventually want to buildMessageFragmentFromContent(content) here, but
     * currently not handled by whiteboard chat.
     */
    return [`/${URL_CONSTANTS.SPACES}/${session}`, {}];
  } else if (worksheet) {
    return [`/content/worksheets/view/${worksheet}`, {}];
  } else if (users) {
    /* Current implementation assumes that direct messaging is only one to one,
     * meaning no group messaging yet.
     */
    const targetUserId = users.find((userId) => userId !== user_id);
    return [`/messages/user/${targetUserId}`, {}];
  } else if (event) {
    return ['/schedule', {}];
  } else {
    // Not sure what would fall all the way through to here
    return ['', {}];
  }
};

export { buildRouterParamsFromNotification };

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EmbedVideoService } from 'src/app/services/embed-video.service';
import { IDisplayFile } from 'src/app/models/resource';
import { ResourcesService } from 'src/app/services/resources.service';
import { ThumbnailService } from 'src/app/services/thumbnail.service';
import { FileViewComponent } from '../base-file-view.component';

@Component({
  selector: 'app-file-grid',
  templateUrl: './file-grid.component.html',
  styleUrls: ['./file-grid.component.scss', '../../files.component.scss'],
})
export class FileGridComponent extends FileViewComponent implements OnInit {
  constructor(
    _router: Router,
    _embedService: EmbedVideoService,
    _thumbnail: ThumbnailService,
    _resourceService: ResourcesService,
    _translate: TranslateService,
    _dialog: MatDialog,
  ) {
    super(_dialog, _router, _embedService, _thumbnail, _resourceService, _translate);
  }

  ngOnInit(): void {
    this.maxNameLength = 20;
  }

  handleDrag(files: IDisplayFile[], elem: HTMLDivElement) {
    const fileElem = this.createDragImageTile(files[0]);
    elem.appendChild(fileElem);
    if (this.selectedFiles.length > 1) {
      const secondFile = this.createDragImageTile(files[1]);
      secondFile.style.top = '-8px';
      secondFile.style.right = '-8px';
      secondFile.style.zIndex = '-1';
      secondFile.classList.add('position-absolute');
      const numElem = this.createDragImageCount();
      numElem.style.top = '90px';
      numElem.style.right = '-12px';
      elem.appendChild(secondFile);
      elem.appendChild(numElem);
    }
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User, REVOKED_TYPES } from '../../models/user';

@Component({
  selector: 'app-revoked-info-dialog',
  templateUrl: './revoked-info-dialog.component.html',
  styleUrls: ['./revoked-info-dialog.component.scss'],
})
export class RevokedInfoDialogComponent {
  user?: User;
  title = '';
  REVOKED_TYPES = REVOKED_TYPES;

  constructor(
    public dialogRef: MatDialogRef<RevokedInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (this.data.user && this.data.user.revoked) {
      this.user = this.data?.user;
      this.title =
        this.data?.user?.revoked.type === REVOKED_TYPES.SUSPENDED
          ? 'Account Suspended'
          : 'Account Removed';
    }
  }

  close() {
    this.dialogRef.close();
  }
}

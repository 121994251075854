import { Context } from './context';
import { Message, User, UserInfo } from './user';

// TODO: Not sure we need this anymore...
export enum MessageScrollPosition {
  Top,
  Bottom,
}

// Helper type for function arguments.
export interface MessageWithUser {
  message: TypedMessage;
  currentUser: User;
}

/* Output from from MessagePreviewTypePipe that is used to simplify template
 * logic for rendering message content in conversation previews.
 */
export enum MessagePreviewType {
  Text,
  Equation,
  Attachment,
  CreateGroup,
  None,
}

/* ConversationPreview type definition STARTS here.
 *
 * A ConversationPreview holds information about one entry in the conversation
 * list on the left side of the main messaging page.
 *
 * Every ConversationPreview holds information about the current user and
 * conversation context. Every conversation is either a user or group
 * conversation, and optionally a draft conversation. A draft conversation is a
 * conversation that has no messages. Non-draft ConversationPreviews include the
 * most recent message from that conversation, which is displayed in the
 * rendered preview.
 */

export enum ConversationPreviewType {
  UserPreviewType,
  UserDraftPreviewType,
  GroupPreviewType,
  GroupDraftPreviewType,
}
interface ConversationPreviewBase {
  currentUser: User;
  context: Context;
}

export interface UserConversationPreview extends ConversationPreviewBase {
  previewType: ConversationPreviewType.UserPreviewType;
  userInfo: UserInfo;
  message: TypedMessage;
}

export interface UserDraftConversationPreview extends ConversationPreviewBase {
  previewType: ConversationPreviewType.UserDraftPreviewType;
  userInfo: UserInfo;
}

export interface GroupConversationPreview extends ConversationPreviewBase {
  previewType: ConversationPreviewType.GroupPreviewType;
  group: Group;
  message: TypedMessage;
}

export interface GroupDraftConversationPreview extends ConversationPreviewBase {
  previewType: ConversationPreviewType.GroupDraftPreviewType;
  group: Group;
}

/* Narrow union crosswise using isUserPreview() and isDraftPreview() and down to
 * a single member using calls to both.
 */
export type ConversationPreview =
  | UserConversationPreview
  | UserDraftConversationPreview
  | GroupConversationPreview
  | GroupDraftConversationPreview;

// ConversationPreview type definition ENDS here.

/* TypedMessage type definition STARTS here.
 *
 * A TypedMessage is an extension of the existing Message type, and adds support
 * for metadata messages.
 *
 * A ContentMessage is a normal message that is created and sent manually by a
 * user, and includes content such as text, equations, images, etc. A
 * MetadataMessage is a message that is sent programmatically in response to a
 * user action, such as a new group being created or a new user being added to
 * an existing group.
 */

export enum MessageType {
  Content = 'CONTENT',
  Update = 'UPDATE',
}

export enum UpdateType {
  CreateGroup = 'CREATE_GROUP',
  RenameGroup = 'RENAME_GROUP',
  AddUsersToGroup = 'ADD_USERS_TO_GROUP',
  LeaveGroup = 'LEAVE_GROUP',
}

export enum NotificationPlacement {
  LEFT = 'left',
  BOTTOM = 'bottom',
}

export interface ContentMessage extends Message {
  messageType?: MessageType.Content;
  messageAction?: MessageAction;
}

export interface CreateGroupMessage extends Message {
  messageType: MessageType.Update;
  updateType: UpdateType.CreateGroup;
  messageAction?: MessageAction;
}

// Will become discriminated union of update types as they are implemented.
export type MetadataMessage = CreateGroupMessage;

// Narrow union using isContentMessage().
export type TypedMessage = ContentMessage | MetadataMessage;

// TypedMessage type definition ENDS here.

/* MessageUpdate type definition STARTS here.
 *
 * A MessageUpdate represents a real-time change related to a single message
 * that is broadcast over a pub/sub channel.
 *
 * A MessageUpdate can represent the creation of a new message, an edit made to
 * an existing message, or the deletion of an existing message. MessageUpdates
 * trigger changes to the most recent message in conversation previews, as well
 * as individual messages within ConversationContent.
 */

export enum MessageAction {
  Add = 'message add',
  Edit = 'message edit',
  Delete = 'message delete',
  DeleteAll = 'message delete all',
  AddComment = 'comment add',
  EditComment = 'comment edit',
  DeleteComment = 'comment delete',
  DeleteBoardComments = 'board comments delete',
  DeleteAllComments = 'comments delete all',
  DeleteAllMessagesAndComments = 'messages and comments delete all',
  MarkDeleteComment = 'comment mark delete',
  AppUpdated = 'app updated',
}

type MessageUpdateBase = TypedMessage & {
  messageAction: MessageAction;
};

type UserMessageUpdate = MessageUpdateBase & {
  conversationType: ConversationType.UserConversation;
};

type GroupMessageUpdate = MessageUpdateBase & {
  conversationType: ConversationType.GroupConversation;
  group: Group;
};

export type MessageUpdate = UserMessageUpdate | GroupMessageUpdate;

// MessageUpdate type definition ENDS here.

/* ConversationUpdate type definition STARTS here.
 *
 * A ConversationUpdate represents a change in ConversationContent for a single
 * conversation.
 *
 * A ConversationUpdate can be a MessageUpdate received over a pub/sub channel
 * that only affects a single message within ConversationContent. It can also be
 * a HistoryUpdate triggered by infinite scroll that includes older messages to
 * add to ConversationContent. ConversationUpdates trigger changes to the
 * rendered message list for a conversation.
 */

export enum ConversationUpdateType {
  MessageUpdateType,
  HistoryUpdateType,
}

export type ConversationMessageUpdate = MessageUpdate & {
  type: ConversationUpdateType.MessageUpdateType;
};

export interface ConversationHistoryUpdate {
  type: ConversationUpdateType.HistoryUpdateType;
  messages: TypedMessage[];
}

export type ConversationUpdate = ConversationMessageUpdate | ConversationHistoryUpdate;

// ConversationUpdate type definition ENDS here.

/* MessageGroup type definition STARTS here.
 *
 * A MessageGroup is a ordered set of content messages sent by the same author
 * that are rendered together. Metadata messages are always rendered
 * independently.
 *
 * MessageGroups are created by MessageGroupsPipe, and simplify the template
 * logic for rendering lists of individual messages.
 */

export interface ContentMessageGroup {
  messageType: MessageType.Content;
  author: UserInfo;
  date?: string;
  messages: ContentMessage[];
  mostRecentTimestamp: string;
}

export type MetadataMessageGroup = MetadataMessage;

export type MessageGroup = ContentMessageGroup | MetadataMessageGroup;

export type MessageGroupByDate = ContentMessageGroupByDate | MetadataMessageGroup;
export interface ContentMessageGroupByDate {
  date?: string;
  userId?: string;
  messages: ContentMessage[];
  mostRecentTimestamp: string;
}

// MessageGroup type definition ENDS here.

/* NewConversationRequest type definition STARTS here.
 *
 * A NewConversationRequest represents the selection output from the new message
 * dialog, which is used to update the conversation list and trigger group
 * creation if a group is selected.
 *
 * This is distinct from the NewConversation type used internally by
 * MessagingService, which describes an internally processed version of this
 * type with additional fields added from API calls.
 */

export enum ConversationType {
  UserConversation,
  GroupConversation,
}

export interface NewUserConversationRequest {
  conversationType: ConversationType.UserConversation;
  user: UserInfo;
}

export interface NewGroupConversationRequest {
  conversationType: ConversationType.GroupConversation;
  users: UserInfo[];
}

export type NewConversationRequest = NewUserConversationRequest | NewGroupConversationRequest;

// NewConversationRequest type definition ENDS here.

// Response format for making a GET request to the /messages/search endpoint.
export interface RealtimeToken {
  faye: string;
  galactus: string;
}

export interface ConversationContentResponse {
  user: User;
  messages: TypedMessage[];
  total_messages: number;
  realtime: {
    realtime_user_message: RealtimeToken;
  };
}

// Response format for making a POST request to the /groups endpoint.
export interface NewGroupConversationResponse {
  group: Group;
}

/* ConversationContent holds information about messages for a single
 * conversation that are rendered by MessageListComponent.
 */
export interface ConversationContent {
  currentUser: User;
  context: Context;
  messages: TypedMessage[];
  /* May be different from messages.length, since we fetch and display messages
   * in pages.
   */
  totalMessages: number;
}
export interface Group {
  _id: string;
  name: string;
  image?: string;
  users: string[];
}

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-not-supported-browsers',
  templateUrl: './not-supported-browsers.component.html',
  styleUrls: ['./not-supported-browsers.component.scss']
})
export class NotSupportedBrowsersComponent {

  constructor(
    public dialogRef: MatDialogRef<NotSupportedBrowsersComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  close(status: boolean): void {
    this.dialogRef.close(status);
  }
}

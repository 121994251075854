import { ElementRef, Injectable, NgZone } from '@angular/core';
import { fabric } from 'fabric';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  tap,
} from 'rxjs';
import { isPermissionEnabled } from '../common/utils/common-util';
import { RealtimeToken } from '../models/messaging';
import { Frame, ISession, Room, Session, SessionUser } from '../models/session';
import { FrameEvent, FrameEventType, SessionAuth } from '../models/session-sync';
import { User } from '../models/user';
import { YCanvasItemObject } from '../sessions/common/y-session';
import { PanelView } from '../sessions/panel/panel.component';
import { Section } from '../sessions/panel/participants-manager/participants-manager.component';
import { SessionEventController } from '../sessions/session/SessionEvent';
import { CollaborativeApps } from '../sessions/session/iframe/additional-apps.utils';
import {
  CanvasItem,
  ItemData,
  ItemsCanvasComponent,
  UploadFile,
} from '../sessions/session/items-canvas/items-canvas.component';
import { YCanvas } from '../sessions/session/wb-canvas/fabric-utils';
import { MainToolbar } from '../sessions/session/wb-toolbar/toolbars/main-toolbar';
import { VideoLayout } from '../sessions/session/wb-video-call-participant-id/wb-video-call-participant-id.component';
import { ISpaceUI, SpaceRepository } from '../state/space.repository';
import { enterZone, modifiedSetTimeout } from '../utilities/ZoneUtils';
import { RealtimeService } from './realtime.service';
import { SpaceBoardsService } from './space-boards.service';
import { KeyScenariosOnSpaces, TelemetryService } from './telemetry.service';
import { UserService } from './user.service';

export interface SessionData {
  sessionAuth: SessionAuth;
  realtimeToken: RealtimeToken;
}
export interface SessionChange {
  oldSessionId?: string;
  newSessionId: string;
  oldBreakoutRoomId?: string;
}

export interface ITileData {
  trackType: string;
  participantId: string;
  userId: string;
}
export interface IUserPointerData {
  pointerColor: string;
  user: string;
}

export interface IExpandAndFullScreenChange {
  id: string;
  fullscreen?: boolean;
  tileData: ITileData;
  isLeaderEvent?: boolean;
}

export interface CallState {
  audio: boolean;
  video: boolean;
}

export enum DisableIncomingVideosSource {
  USER,
  CPU_PERFORMANCE,
}

export enum ShareSessionSource {
  TOP_CONTROLS = 'TOP_CONTROLS',
  SPACES_LIST = 'SPACES_LIST',
  SPACES_LANDING = 'SPACES_LANDING',
  LEFT_NAVBAR = 'LEFT_NAVBAR',
  REFERRAL = 'REFERRAL',
}

export enum SessionView {
  WHITEBOARD = 'WHITEBOARD',
  EXPANDED = 'EXPANDED',
  FULLSCREEN = 'FULLSCREEN',
  BIRD_VIEW = 'BIRD_VIEW',
  GALLERY_VIEW = 'GALLERY_VIEW',
  SPACES_LANDING_VIEW = 'SPACES_LANDING_VIEW',
  BREAKOUT_ROOMS = 'BREAKOUT_ROOMS',
  FULLSCREEN_APP = 'FULLSCREEN_APP',
  WAITING_ROOM = 'WAITING_ROOM',
  LOADING = 'LOADING',
}

export interface FrameBackground {
  pattern: WhiteboardBackground;
  color: string;
}

export enum WhiteboardBackground {
  DOT,
  SQUARE,
  SMALL_SQUARE,
  LINE,
  NARROW_LINE,
  TRIANGLE,
  NONE,
  WRITING_RULE_MEDIUM,
  WRITING_RULE_SMALL,
  WRITING_RULE_LARGE,
}

export enum ScribbleType {
  DRAW = 'draw',
  HIGHLIGHT = 'highlight',
  NOT_SCRIBBLE = 'not_scribble',
}
export interface ISessionViewState {
  view: SessionView;
  sessionViewMetaData?: any;
  expandedFullScreenChange?: IExpandAndFullScreenChange | null | undefined;
  openTabs?: boolean;
}

export enum FollowLeaderModeState {
  POLL = 'poll',
  PROCEED = 'proceed',
}
class SessionViewManager {
  private constructor(private telemetry: TelemetryService) {}
  private _current: BehaviorSubject<ISessionViewState> = new BehaviorSubject<ISessionViewState>({
    view: SessionView.LOADING,
  });
  private static instance: SessionViewManager;
  private _sessionViewHistory: ISessionViewState[] = [];

  public current$: Observable<ISessionViewState> = this._current.asObservable().pipe(
    tap((current) => {
      this.telemetry.setSessionVars({
        breakouts_manage_mode_open: current.view === SessionView.BREAKOUT_ROOMS,
      });
    }),
  );

  public async switchToSessionView(
    view: SessionView,
    expandedFullScreenChange?: IExpandAndFullScreenChange,
    openTabs?: boolean,
    sessionViewMetaData?: any,
  ): Promise<void> {
    if (this.getSessionView() === SessionView.GALLERY_VIEW && view === SessionView.WHITEBOARD) {
      this.telemetry.startPerfScenario(KeyScenariosOnSpaces.SWITCHING_VIEW, {
        currentView: this.getSessionView(),
        newView: view,
      });
    }

    const previousView = this._current.getValue();
    if (previousView.view !== SessionView.FULLSCREEN) {
      this._sessionViewHistory.push(this._current.getValue());
    }

    // emit new view value
    this._current.next({ view, expandedFullScreenChange, openTabs, sessionViewMetaData });

    // empty the history
    if (view === SessionView.WHITEBOARD) {
      this._sessionViewHistory = [];
    }
  }

  public updateExpandedFullScreen(
    expandedFullScreenChange: IExpandAndFullScreenChange | undefined | null,
    sessionViewMetaData?: any,
  ) {
    this._current.next({
      view: this.getSessionView(),
      expandedFullScreenChange,
      sessionViewMetaData,
    });
  }

  public switchBackToPreviousView(): void {
    const previousState = this._sessionViewHistory.pop();
    this._current.next(previousState || { view: SessionView.WHITEBOARD });
  }

  public getPreviousSessionView(): SessionView {
    const previousState = this._sessionViewHistory[this._sessionViewHistory.length - 1];
    return previousState?.view ?? SessionView.WHITEBOARD;
  }

  public getPreviousSessionViewMetaData(): SessionView {
    const previousState = this._sessionViewHistory[this._sessionViewHistory.length - 1];
    return previousState?.sessionViewMetaData;
  }

  public getSessionView(): SessionView {
    return this._current.getValue().view;
  }

  public getSessionViewMetaData(): any {
    return this._current.getValue().sessionViewMetaData;
  }

  public expandedFullScreenChange(): IExpandAndFullScreenChange | undefined | null {
    return this._current.getValue().expandedFullScreenChange;
  }

  public static init(telemetry: TelemetryService): SessionViewManager {
    if (!SessionViewManager.instance) {
      SessionViewManager.instance = new SessionViewManager(telemetry);
    }
    return SessionViewManager.instance;
  }

  public reset(): void {
    this._current.next({ view: SessionView.LOADING });
    this._sessionViewHistory = [];
  }
}

export enum LockObjectAction {
  TOGGLE_LOCK,
  LOCK_OBJECT,
}

export enum LockObjectTarget {
  FABRIC_OBJECT,
  CANVAS_ITEM,
}

export interface LockObjectOptions {
  action: LockObjectAction;
  args: any[];
  target: LockObjectTarget;
}

export interface PanelsWidth {
  leftPanelWidth: number;
  rightPanelWidth: number;
}

@Injectable({
  providedIn: 'root',
})
export class SessionSharedDataService {
  private readonly APP_CREATION_TIMEOUT_DURATION = 5 * 1000; // 5 seconds.

  constructor(
    private realtimeService: RealtimeService,
    private userService: UserService,
    private spaceRepo: SpaceRepository,
    private spaceBoardsService: SpaceBoardsService,
    private telemetry: TelemetryService,
    private zone: NgZone,
  ) {
    this.userService.user.subscribe((user) => {
      if (user) {
        this.user = user.user;
      }
    });
    this.appDisabled$.subscribe((disabledApp) => {
      if (disabledApp) {
        modifiedSetTimeout(() => {
          // the app is still disabled after 5 seconds
          if (this.appDisabled$.getValue() === disabledApp) {
            // enable insertion again
            this.appDisabled$.next(null);
          }
        }, this.APP_CREATION_TIMEOUT_DURATION);
      }
    });
  }

  user?: User;
  canvas?: ElementRef<HTMLCanvasElement>;
  fabricCanvas?: YCanvas;
  lastMouseLocation: fabric.IEvent | null = null;
  userPointerDataEvent = new BehaviorSubject<IUserPointerData | null>(null);
  endPreview: Subject<void> = new Subject<void>();
  itemsCanvas?: ItemsCanvasComponent;
  private renderedItemsIds = new Set<string>();
  renderedItemsIds$ = new Subject<Set<string>>();
  sessionEventController?: SessionEventController;
  public readonly enableCanvasItemsInteractions$ = new BehaviorSubject<boolean>(false);
  enableFloatingMenu = false;
  enablePrintPreview: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectedItemsToInsert: Subject<ItemData[]> = new Subject<ItemData[]>();
  public readonly newSessionCreated = new BehaviorSubject<boolean>(false);
  requestCollectingLogs: Subject<boolean> = new Subject<boolean>();
  openMobileGalleryView: Subject<boolean> = new Subject<boolean>(); // only used in mobile view
  showOthersEmotes: Subject<boolean> = new Subject<boolean>();
  sendLogData: Subject<any> = new Subject<any>();
  leftPanelView: BehaviorSubject<{ panelView: PanelView | undefined; expanded: boolean } | null> =
    new BehaviorSubject<{ panelView: PanelView | undefined; expanded: boolean } | null>(null);
  rightPanelView: BehaviorSubject<{ panelView: PanelView | undefined; expanded: boolean } | null> =
    new BehaviorSubject<{ panelView: PanelView | undefined; expanded: boolean } | null>(null);
  uploadItem: Subject<UploadFile[] | null> = new Subject<UploadFile[] | null>();
  // It caches items loaded to the wb to reuse it in frames preview or switching between frames ..etc.
  itemsData$: { [id: string]: BehaviorSubject<any> } = {};
  mainToolbar?: MainToolbar;
  // Sessions that are not being acted upon (are not directly editable)
  private realtimeSubscriptions: { [key: string]: Subscription } = {};
  public removeCanvasItem: Subject<string> = new Subject();
  public changeLeftPanelView: BehaviorSubject<PanelView | undefined> = new BehaviorSubject<
    PanelView | undefined
  >(undefined);
  public changeRightPanelView: BehaviorSubject<PanelView | undefined> = new BehaviorSubject<
    PanelView | undefined
  >(undefined);
  // changed to behavior subject be able to open on a specific tab
  public changeParticipantsManagerSection: BehaviorSubject<Section | undefined> =
    new BehaviorSubject<Section | undefined>(undefined);
  hasUserLeftSpace$ = new BehaviorSubject(false);
  // Stores the auth data for sessions to get the data from the socket server
  sessionAuthData: { [key: string]: SessionData } = {};
  // Stores the session realtime data
  sessionrealtimeData: { [key: string]: string } = {};
  // Set to true when we are loading a session.
  dataLoading = new BehaviorSubject<boolean>(false);
  // Emitted when a session is ended
  sessionEnded = new Subject<string>();
  // Emitted when the session is changed from the parallel sessions
  sessionChanged = new Subject<SessionChange>();
  // Emitted when an item is deleted
  itemDeleted = new Subject<boolean>();
  // Emitted when the item loaded to a frame is a video
  startVideoAnimation: Subject<void> = new Subject<void>();
  // check call status
  callStarted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  openFormulaEditDialog$: Subject<string> = new Subject<string>(); // Pass the canvas item id
  showJoinCallTooltip$: Subject<boolean> = new Subject<boolean>();
  toolbarChange: Subject<LockObjectOptions> = new Subject();
  toolbarToggleUnlock: Observable<LockObjectOptions> = this.toolbarChange
    .asObservable()
    .pipe(filter((data) => data.action === LockObjectAction.TOGGLE_LOCK));

  changeCanvasItemsDict: Subject<{ itemId: string; item?: CanvasItem }> = new Subject();

  clearFrameContent$ = new Subject<string>();
  lastMouseMoveEvent: FrameEvent = { type: FrameEventType.Realtime };

  // Map of users that are in each frame
  framePresence: { [key: string]: User[] } = {};

  leaveSpace = new Subject<void>();
  leavingSpace$ = this.leaveSpace.asObservable();

  trackUndoRedo: Subject<{ beforeUndoRedoObjects: fabric.Object[]; changedUIDs: Set<string> }> =
    new Subject();

  private isDisableKeyEventsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public sessionView: SessionViewManager = SessionViewManager.init(this.telemetry);

  private startsCallRequest = new Subject<{ action: string }>();

  // for both fabric and canvas items
  public readonly copiedObjects = new BehaviorSubject<{ [key: string]: any }[] | null>(null);

  // Tabs component subscribes to this. Passing it an index allows any component to select frames
  public readonly selectFrameSubject: Subject<string | undefined> = new Subject();

  // Emitted when there is a yjs update from the socket server
  public readonly socketYUpdate: Subject<void> = new Subject();
  public socketReconnected: Subject<void> = new Subject();

  // Emitted when the session title is updated
  public titleUpdate: Subject<{ id: string; title: string }> = new Subject();

  // Queue of the frameUID before reconnecting after an update
  // this is because the update can unexpectedly change the frames array
  public readonly frameUIDBeforeDisconnect: string[] = [];

  // This flag holds wether or not the local user can change the viewport transform
  // if it is locked the only changes that can be made is from remote changes (Leader Mode)
  public readonly vptLocked = new BehaviorSubject(false);

  // This is used to define layout of video controls
  public controlsLayout = new BehaviorSubject(VideoLayout.TOP);

  // This is used to minimize/maximize video controls in mobile
  public isControlsCollapsed = new BehaviorSubject(false);

  // This is used for cursor muting
  public cursorsHidden = new BehaviorSubject(false);

  // Map of users pointer data
  public usersPointerData = new Map();

  public disableVideoStreamChanged = new BehaviorSubject<{
    disabled: boolean;
    source: DisableIncomingVideosSource;
  }>({
    disabled: false,
    source: DisableIncomingVideosSource.USER,
  });

  // will be used to create a new public frame if all current frames are private
  public createNewPublicFrame = new Subject<boolean>();
  public disabledThroughWeakNotification = false;

  public createComment$ = new BehaviorSubject<boolean>(false);
  public appDisabled$ = new BehaviorSubject<CollaborativeApps | null>(null);

  private blueBorderActiveSpeakerChange = '';

  private _panelsWidth$ = new BehaviorSubject<PanelsWidth>({
    leftPanelWidth: 0,
    rightPanelWidth: 0,
  });

  public panelsWidth$ = this._panelsWidth$.asObservable();

  private _topControlsHeight = new BehaviorSubject<number>(0);
  public topControlsHeight$ = this._topControlsHeight.asObservable().pipe(distinctUntilChanged());

  getBlueBorderActiveSpeakerVal() {
    return this.blueBorderActiveSpeakerChange;
  }

  updateBlueBorderActiveSpeaker(val: string) {
    this.blueBorderActiveSpeakerChange = val;
  }

  public populateData(sessions: Partial<Session>[]) {
    if (sessions) {
      sessions.forEach((x) => {
        try {
          const metadata = x.metadata;

          if (!metadata || !x._id) {
            return;
          }

          const requiredFields = ['realtime_token', 'auth_token'];
          const hasAll = requiredFields.every((field) =>
            Object.prototype.hasOwnProperty.call(metadata, field),
          );

          if (!hasAll) {
            return;
          }

          const sessionAuth: SessionAuth = {
            id: x._id,
            token: metadata.auth_token,
          };

          const sessionData = {
            sessionAuth,
            realtimeToken: metadata.realtime_token,
          };

          this.sessionAuthData[x._id] = sessionData;
        } catch (err) {
          console.log(err);
        }
      });
    }
  }

  isAppInWhiteboardView() {
    const currentSessionView = this.sessionView.getSessionView();
    return (
      currentSessionView === SessionView.WHITEBOARD ||
      currentSessionView === SessionView.FULLSCREEN_APP
    );
  }

  isAppInMinimizeView() {
    return this.controlsLayout.getValue() === VideoLayout.MINIMIZED && this.isAppInWhiteboardView();
  }

  setuprealtimeHandlers(sessionIds): void {
    if (sessionIds) {
      sessionIds.forEach((sessionId) => {
        if (this.realtimeSubscriptions[sessionId]) {
          return;
        }

        const authData = this.sessionAuthData[sessionId];
        const realtimeToken = authData?.realtimeToken;

        if (!realtimeToken) {
          return;
        }

        const realtimeObservable = this.realtimeService.subscribeSession(sessionId, realtimeToken);
        this.realtimeSubscriptions[sessionId] = realtimeObservable.subscribe((message) => {
          if (message) {
            this.handlerealtimeSessionUpdates(sessionId, message);
          }
        });
      });
    }
  }

  cleanuprealtimeSession(sessionId: string): void {
    if (this.realtimeSubscriptions[sessionId]) {
      this.realtimeService.unsubscribeSession(sessionId);
      this.realtimeSubscriptions[sessionId]?.unsubscribe();
      delete this.realtimeSubscriptions[sessionId];
    }
  }

  public readonly isDisableKeyEvents$ = this.isDisableKeyEventsSubject.pipe(
    distinctUntilChanged(),
    enterZone(this.zone),
  );

  set isDisableKeyEvents(value: boolean) {
    if (value !== this.isDisableKeyEventsSubject.getValue()) {
      this.isDisableKeyEventsSubject.next(value);
    }
  }

  set topControlsHeight(value: number) {
    console.log('[topControlsHeight]:next', value);
    this._topControlsHeight.next(value);
  }

  get topControlsHeight(): number {
    return this._topControlsHeight.getValue();
  }

  private updatePopulatedUsers(message: any, updatedSession: ISession & ISpaceUI) {
    if (!message.data.userObjects) {
      return;
    }
    if (!updatedSession?.populatedUsers) {
      updatedSession.populatedUsers = [];
    }
    message.data.userObjects.forEach((user: User) => {
      const index = updatedSession.populatedUsers?.findIndex((u) => u._id === user._id);
      if (index < 0) {
        updatedSession.populatedUsers.push(user);
      }
    });
  }

  private updateSpaceUsers(message: any, updatedSession: ISession & ISpaceUI) {
    if (!message.data.addUpdateUsers) {
      return;
    }
    message.data.addUpdateUsers.forEach((sessionUser: SessionUser) => {
      const index = updatedSession.users.findIndex((u) => u._id === sessionUser._id);
      if (index >= 0) {
        updatedSession.users[index] = sessionUser;
      } else {
        updatedSession.users.push(sessionUser);
      }
    });
  }

  private updateRemovedUsers(message: any, updatedSession: ISession & ISpaceUI) {
    if (!message.data.removeUsers) {
      return;
    }
    updatedSession.users = updatedSession.users.filter(
      (sessionUser) =>
        !message.data.removeUsers.find((removedUser) => removedUser._id === sessionUser._id),
    );
  }

  private removeFromAccessRequesters(message: any, updatedSession: ISession & ISpaceUI) {
    if (!updatedSession || !updatedSession.accessRequesters || !message.addUpdateUsers) {
      return;
    }
    const IDsToBeRemovedSet = new Set(message.addUpdateUsers.map((user: any) => user._id));

    updatedSession.accessRequesters = updatedSession.accessRequesters.filter(
      (user: any) => !IDsToBeRemovedSet.has(user._id),
    );

    updatedSession.populatedAccessRequesters =
      updatedSession.populatedAccessRequesters?.filter(
        (user: any) => !IDsToBeRemovedSet.has(user._id),
      ) ?? [];
  }

  private handlerealtimeSessionUpdates(sessionId: string, message: any): void {
    if (message.action === 'endSession' && message.sessionId === sessionId) {
      // stop listening to realtime session and filter from active events
      this.cleanuprealtimeSession(sessionId);
    } else if (message.action !== 'updateData' || message.id !== sessionId) {
      return;
    }
    const updatedSession = this.spaceRepo.getSpace(sessionId);
    if (!updatedSession || message?.data === undefined) {
      return;
    }
    // add new users to session.users, session.populatedUsers, and session.context.users
    this.updatePopulatedUsers(message, updatedSession);
    this.updateSpaceUsers(message, updatedSession);
    /* After merging existing users and added/updated users, filter out users
     * that were removed.
     */
    this.updateRemovedUsers(message, updatedSession);
    this.removeFromAccessRequesters(message, updatedSession);
    this.handleDataUpdate(message, updatedSession);
    this.spaceRepo.updateSpace(sessionId, updatedSession);
  }

  /* @TODO: Move this (and underlying helper function to SpacePermissionsManagerService)
   * A user can modify the Space if:
   * 1. They are the owner/host of the space, OR
   * 2. (The space is not locked) AND (the board is not locked) AND (they have room editSpace permissions)
   */
  canModifySession(
    space?: ISession | undefined,
    currentRoom?: Room | undefined,
    selectedBoard?: Frame | undefined,
  ): boolean {
    // if space is not specified, use the activeSpace
    if (!space) {
      space = this.spaceRepo.activeSpace;
    }
    if (!currentRoom) {
      currentRoom = this.spaceRepo.activeSpaceCurrentRoom;
    }
    if (!selectedBoard) {
      selectedBoard = this.spaceBoardsService.activeSpaceSelectedBoard;
    }
    if (Session.isOwnedByUser(space, this.user)) {
      return true;
    } else if (currentRoom && space) {
      return space?.isLocked || selectedBoard?.locked || !currentRoom.permissions
        ? false
        : isPermissionEnabled(currentRoom.permissions, 'editSpace', this.user?._id as string);
    } else {
      return !!space?.users?.find((sessionUser) => sessionUser._id === this.user?._id)
        ?.userPermissions?.editSpace;
    }
  }

  public readonly canModifySession$ = combineLatest([
    this.spaceRepo.activeSpace$,
    this.spaceRepo.activeSpaceCurrentRoom$,
    this.spaceBoardsService.activeSpaceSelectedBoard$,
  ]).pipe(
    map(([space, currentRoom, selectedBoard]) =>
      this.canModifySession(space, currentRoom, selectedBoard),
    ),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: false /* false for root service */ }), // remember the last emitted value for late subscribers
  );

  /**
   * check if the user has access to the current frame if it is locked
   */
  canModifyCurrentActiveFrame(space?: ISession): boolean {
    // if space is not specified, use the activeSpace
    if (!space) {
      space = this.spaceRepo.activeSpace;
    }
    if (Session.isOwnedByUser(space, this.user)) {
      return true;
    }
    return !this.spaceBoardsService.activeSpaceSelectedBoard?.locked;
  }

  public readonly canModifyCurrentActiveFrame$ = this.spaceRepo.activeSpace$?.pipe(
    map((space) => this.canModifyCurrentActiveFrame(space)),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: false /* false for root service */ }), // remember the last emitted value for late subscribers;
  );

  resetService(): void {
    this.spaceRepo.resetActiveSpace();
    this.canvas = undefined;
    this.fabricCanvas = undefined;
    this.enableCanvasItemsInteractions$.next(false);
    this.enableFloatingMenu = false;
    this.enablePrintPreview = new BehaviorSubject<boolean>(false);
    this.itemsData$ = {};
    this.selectedItemsToInsert = new Subject<ItemData[]>();
    this.leftPanelView = new BehaviorSubject<{
      panelView: PanelView | undefined;
      expanded: boolean;
    } | null>(null);
    this.rightPanelView = new BehaviorSubject<{
      panelView: PanelView | undefined;
      expanded: boolean;
    } | null>(null);
    this.uploadItem = new Subject<UploadFile[] | null>();
    this.removeCanvasItem = new Subject();
    this.changeRightPanelView = new BehaviorSubject<PanelView | undefined>(undefined);
    this.changeParticipantsManagerSection = new BehaviorSubject<Section | undefined>(undefined);
    this.dataLoading = new BehaviorSubject<boolean>(false);
    this.sessionEnded = new Subject<string>();
    this.sessionChanged = new Subject<SessionChange>();
    this.itemDeleted = new Subject<boolean>();
    this.vptLocked.next(false);
    this.controlsLayout.next(VideoLayout.TOP);
    this.hasUserLeftSpace$ = new BehaviorSubject<boolean>(false);
    this.usersPointerData = new Map();
    this.callStarted = new BehaviorSubject<boolean>(false);
    this.sessionView.reset();
    this.renderedItemsIds = new Set<string>();
    this.renderedItemsIds$ = new Subject<Set<string>>();
    this.lastMouseLocation = null;
  }

  resetDisableVideoFlow(): void {
    this.disableVideoStreamChanged.next({
      disabled: false,
      source: DisableIncomingVideosSource.USER,
    });
    this.disabledThroughWeakNotification = false;
  }

  // Return the current frame from the session context
  getCurrentFrame(): Frame | undefined {
    return this.spaceBoardsService.activeSpaceSelectedBoard;
  }

  get userStartsCallRequest(): Observable<{ action: string }> {
    return this.startsCallRequest.asObservable();
  }

  /**
   * From any place in the system when the user starts a call this action should be dispatched, so only one component handle the logic
   */
  dispatchUserStartsCallRequest(): void {
    this.startsCallRequest.next({ action: 'start_call' });
  }

  currentFrameHasUser(userId: string): boolean {
    const currentFrameUid = this.spaceRepo.activeSpace?.selectedBoardUid;

    const currentFrameUsersArray = this.framePresence[currentFrameUid!]
      ? this.framePresence[currentFrameUid!].map((user) => user._id)
      : [];

    return new Set(currentFrameUsersArray).has(userId);
  }

  addToRenderedItemsIds(id: string): void {
    this.renderedItemsIds.add(id);
    this.renderedItemsIds$.next(this.renderedItemsIds);
  }

  removeRenderedItem(id: string): void {
    if (this.renderedItemsIds.has(id)) {
      this.renderedItemsIds.delete(id);
    }
    this.renderedItemsIds$.next(this.renderedItemsIds);
  }

  /**
   * return fabric/canvas item id
   * itemId exists on selected item (e.target) for canvas items
   * _id exists on YCanvasItemObject
   * uid exists on fabric.Object
   */
  getItemId(selectedItem: fabric.Object | YCanvasItemObject): string {
    return (selectedItem as any)._id ?? (selectedItem as any).itemId ?? (selectedItem as any).uid;
  }

  getScribbleType(): ScribbleType {
    if (this.mainToolbar?.activeToolName === 'draw') {
      return ScribbleType.DRAW;
    } else if (this.mainToolbar?.activeToolName === 'highlight') {
      return ScribbleType.HIGHLIGHT;
    }
    return ScribbleType.NOT_SCRIBBLE;
  }

  isBoardLockedByHost() {
    return (
      this.user &&
      !this.canModifySession() &&
      !Session.isOwnedByUser(this.spaceRepo.activeSpace, this.user)
    );
  }

  private handleDataUpdate(message: any, updatedSession: ISession & ISpaceUI) {
    switch (message.dataType.toLowerCase()) {
      case 'user_joined':
        this.handleUserJoined(message, updatedSession);
        break;
      case 'visibility':
        this.handleVisibilityUpdate(message, updatedSession);
        break;
      case 'title':
        this.handleTitleUpdate(message, updatedSession);
        break;
    }
  }

  handleUserJoined(message: any, updatedSession: ISession & ISpaceUI): void {
    const users = updatedSession.users.filter(
      (sessionUser) => sessionUser._id === message.data.userId,
    );
    if (users.length > 0) {
      const user = users[0];
      user.joined = true;
    }
  }

  private handleVisibilityUpdate(message: any, updatedSession: ISession & ISpaceUI) {
    const visibility = message.data.visibility;
    updatedSession.visibility = visibility;
  }

  private handleTitleUpdate(message: any, updatedSession: ISession & ISpaceUI) {
    const title = message.data.title;
    updatedSession.title = title;
  }

  setPanelWidth(panelsWidth: PanelsWidth): void {
    if (panelsWidth.leftPanelWidth !== undefined || panelsWidth.rightPanelWidth !== undefined) {
      this._panelsWidth$.next(panelsWidth);
    }
  }

  getPanelWidth(): PanelsWidth {
    return this._panelsWidth$.getValue();
  }

  set enableCanvasItemsInteractions(enableCanvasItemsInteractions: boolean) {
    this.enableCanvasItemsInteractions$.next(enableCanvasItemsInteractions);
  }
}

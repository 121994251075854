import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';
import { User } from '../models/user';

@Pipe({
  name: 'author',
})
export class AuthorPipe implements PipeTransform, OnDestroy {
  siblings: User[] = [];
  subscriber: Subscription;

  constructor(private userService: UserService) {
    this.subscriber = this.userService.siblings.subscribe((res: any) => {
      if (res) {
        this.siblings = res;
      }
    });
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

  transform(authorId: string): any {
    if (!this.siblings) {
      return null;
    }
    const user = this.siblings.find((u) => authorId === u._id);
    return user;
  }
}

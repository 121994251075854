import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import {
  AnalyticsRoomUserStat,
  AnalyticsSpaceUserStat,
  AnalyticsUserStat,
} from 'src/app/models/analytics';
import { SpaceDataLevel, convertMinutesDurationToString } from '../session_analytics_utils';

// TODO: Replace this with your own data model type
export interface ISessionInsightsTableItem {
  // basic metrics
  userId: string;
  name: string;
  email: string;
  timeJoined: Date;
  timeLeft: Date;

  // Call metrics
  timeOnCall: string;
  audioDuration: string;
  speechDuration: string;
  videoDuration: string;
  raisedHandCount: number | string;
  reactionsCount: number | string;

  // other metrics
  userSpaceDuration: string;
}
export class SessionInsightsTableItem
  extends AnalyticsUserStat
  implements ISessionInsightsTableItem
{
  public timeJoined: Date;
  public timeLeft: Date;
  public timeOnCall: string;
  public audioDuration: string;
  public videoDuration: string;
  public raisedHandCount: number | string;
  public reactionsCount: number | string;
  public activeDuration: string;
  public backgroundDuration: string;
  public publicMsgCount: number;
  public privateMsgCount: number;
  public speechDuration: string;
  public userSpaceDuration: string;
  public readonly NA = 'N/A';

  constructor(obj: AnalyticsRoomUserStat | AnalyticsSpaceUserStat, spaceDataLevel: SpaceDataLevel) {
    super(obj);
    this.timeOnCall = convertMinutesDurationToString(obj.callDuration / 60);
    if (this.isAnalyticsSpaceUserStat(obj)) {
      this.timeJoined = obj.spaceFirstJoinTime;
      this.timeLeft = obj.spaceLastLeaveTime;
      this.userSpaceDuration = convertMinutesDurationToString(obj.spaceDuration / 60);
      this.activeDuration = convertMinutesDurationToString(obj.spaceDuration / 60);
      this.backgroundDuration = convertMinutesDurationToString(obj.spaceDurationInBackground / 60);
      this.publicMsgCount = obj.spaceCountPublicMessagesSent ?? 0;
      this.privateMsgCount = obj.spaceCountPrivateMessagesSent ?? 0;
    } else {
      this.timeJoined = obj.roomFirstJoinTime;
      this.timeLeft = obj.roomLastLeaveTime;
      this.userSpaceDuration = convertMinutesDurationToString(obj.roomDuration / 60);
      this.activeDuration = convertMinutesDurationToString(obj.roomDuration / 60);
      this.backgroundDuration = convertMinutesDurationToString(obj.roomDurationInBackground / 60);
      this.publicMsgCount = obj.roomCountPublicMessagesSent ?? 0;
      this.privateMsgCount = obj.roomCountPrivateMessagesSent ?? 0;
    }
    if (obj.callDuration === 0) {
      this.timeOnCall = this.NA;
      this.audioDuration = this.NA;
      this.videoDuration = this.NA;
      this.speechDuration = this.NA;
      this.raisedHandCount = this.NA;
      this.reactionsCount = this.NA;
    } else {
      this.audioDuration = convertMinutesDurationToString(obj.callMicOnDuration / 60);
      this.videoDuration = convertMinutesDurationToString(obj.callVideoOnDuration / 60);
      this.speechDuration = convertMinutesDurationToString(obj.callActiveTalkingDuration / 60);
      this.raisedHandCount = obj.callCountRaisedHands;
      this.reactionsCount = obj.callCountReactions;
    }
  }

  isAnalyticsSpaceUserStat(
    obj: AnalyticsRoomUserStat | AnalyticsSpaceUserStat,
  ): obj is AnalyticsSpaceUserStat {
    const optionalKeys: (keyof AnalyticsSpaceUserStat)[] = ['userImgObject'];
    const requiredKeys = Object.keys(new AnalyticsSpaceUserStat({})).filter(
      (key) => !optionalKeys.includes(key as keyof AnalyticsSpaceUserStat),
    );
    const objKeys = Object.keys(obj);
    return requiredKeys.every((key) => objKeys.includes(key));
  }
}

/**
 * Data source for the SessionInsightsTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class SessionInsightsTableDataSource extends DataSource<SessionInsightsTableItem> {
  data: SessionInsightsTableItem[];
  paginator: MatPaginator | undefined;
  sort: MatSort | undefined;
  spaceDataLevel: SpaceDataLevel;

  constructor(
    data: (AnalyticsRoomUserStat | AnalyticsSpaceUserStat)[],
    spaceDataLevel: SpaceDataLevel,
  ) {
    super();
    this.data = data.map((item) => new SessionInsightsTableItem(item, spaceDataLevel));
    this.spaceDataLevel = spaceDataLevel;
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<SessionInsightsTableItem[]> {
    if (this.paginator && this.sort) {
      // Combine everything that affects the rendered data into one update
      // stream for the data-table to consume.
      return merge(observableOf(this.data), this.paginator.page, this.sort.sortChange).pipe(
        map(() => this.getPagedData(this.getSortedData([...this.data]))),
      );
    } else {
      throw Error('Please set the paginator and sort on the data source before connecting.');
    }
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect(): void {
    return;
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: SessionInsightsTableItem[]): SessionInsightsTableItem[] {
    if (this.paginator) {
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      return data.splice(startIndex, this.paginator.pageSize);
    } else {
      return data;
    }
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: SessionInsightsTableItem[]): SessionInsightsTableItem[] {
    if (!this.sort || !this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort?.direction === 'asc';
      switch (this.sort?.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'timeJoined':
          return compare(new Date(a.timeJoined).getTime(), new Date(b.timeJoined).getTime(), isAsc);
        case 'timeLeft':
          return compare(new Date(a.timeLeft).getTime(), new Date(b.timeLeft).getTime(), isAsc);
        case 'timeOnCall':
          return compare(+a.callDuration, +b.callDuration, isAsc);
        case 'userSpaceDuration':
          return compare(+a.activeDuration, +b.activeDuration, isAsc);
        case 'backgroundDuration':
          return compare(+a.backgroundDuration, +b.backgroundDuration, isAsc);
        case 'audioDuration':
          return compare(+a.callMicOnDuration, +b.callMicOnDuration, isAsc);
        case 'videoDuration':
          return compare(+a.callVideoOnDuration, +b.callVideoOnDuration, isAsc);
        case 'speechDuration':
          return compare(+a.callActiveTalkingDuration, +b.callActiveTalkingDuration, isAsc);
        case 'raisedHandCount':
          return compare(+a.callCountRaisedHands, +b.callCountRaisedHands, isAsc);
        case 'reactionsCount':
          return compare(+a.callCountReactions, +b.callCountReactions, isAsc);
        case 'spaceCountPublicMessagesSent':
          return compare(+a.publicMsgCount, +b.publicMsgCount, isAsc);
        case 'spaceCountPrivateMessagesSent':
          return compare(+a.privateMsgCount, +b.privateMsgCount, isAsc);
        default:
          return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc?: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { Event } from '../models/event';

@Pipe({
  name: 'eventIsAcrossDay',
})
export class EventIsAcrossDayPipe implements PipeTransform {
  transform(event: Event): boolean {
    return moment(event.end_time).isAfter(moment(event.start_time), 'd');
  }
}

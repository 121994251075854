export const splitWord = (
  word: string,
  context: CanvasRenderingContext2D,
  desiredWidth: number,
): string[] => {
  let measures = context.measureText(word);

  if (measures.width <= desiredWidth) {
    return [word];
  }

  let subWord = '';
  const chunks: string[] = [];
  word.split('').forEach((c, index) => {
    measures = context.measureText(subWord + c);
    if (measures.width <= desiredWidth) {
      subWord += c;
    } else {
      chunks.push(subWord);
      subWord = c;
    }
    if (index + 1 === word.length) {
      chunks.push(subWord);
    }
  });

  return chunks;
};

export interface LineCalculation {
  line: string;
  breakType: string;
}

export const calculateLines = (
  text: string,
  context: CanvasRenderingContext2D,
  desiredWidth: number,
  cache?: Map<string, LineCalculation[]>,
): LineCalculation[] => {
  const output: LineCalculation[] = [];

  // split the given text by break lines
  text.split(/\n/g).forEach((textLine, lineIndex) => {
    if (textLine === '') {
      output.push({
        line: textLine,
        breakType: 'break-line',
      });
      return;
    }

    const lineCache = cache?.get(textLine);
    if (lineCache) {
      output.push(...lineCache);
      return;
    }
    const cachedLines: LineCalculation[] = [];
    // the line variable is where the new calculated line is stored
    let line = '';
    // split the current text line into multiple words
    const words = textLine.split(/[ \t\r]/);
    words.forEach((word) => {
      // reason of this statement is when word is empty text, originally it's a ' ' space
      // check if the current sentence line + word can is smaller than the desired width
      // if widthOf(line + word) > desiredWidth :
      /**
       * 1. skip the current line, and start new one
       * 2. split the current word into multiple chunks
       */
      if (widthOf(line, word, context) > desiredWidth) {
        // skip the current line, and start new one
        if (line) {
          cachedLines.push({
            line,
            breakType: 'no-space-for-word',
          });
          line = '';
        }
        // split the current word into multiple chunks
        const wordChunks = splitWord(word, context, desiredWidth);
        // for each word chunk
        wordChunks.forEach((wordChunk) => {
          // compare line + world chunk to desiredWidth
          if (widthOf(line, wordChunk, context) > desiredWidth) {
            cachedLines.push({
              line,
              breakType: 'word-break',
            });
            line = wordChunk;
            return;
          }

          line += line ? ` ${wordChunk}` : `${wordChunk}`;
        });

        return;
      }

      line += line || !word ? ` ${word}` : `${word}`;
    });

    if (line) {
      cachedLines.push({
        line,
        breakType: 'break-line',
      });
    }

    output.push(...cachedLines);
    cache?.set(textLine, cachedLines);
  });

  return output;
};

export const widthOf = (
  prev: string,
  current: string,
  context: CanvasRenderingContext2D,
): number => {
  if (prev === '') {
    return context.measureText(current).width;
  }

  return context.measureText(`${prev} ${current}`).width;
};

import { AfterViewInit, Directive, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[appElementRendered]',
  exportAs: 'appElementRendered',
  standalone: true,
})
export class ElementRenderedDirective implements AfterViewInit {
  @Output() rendered: EventEmitter<void> = new EventEmitter<void>();

  ngAfterViewInit() {
    this.rendered.emit();
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Fragment } from 'src/app/models/question';

interface ImageFragmentDialogData {
  fragment: Fragment;
}

@Component({
  selector: 'app-image-fragment-dialog',
  templateUrl: './image-fragment-dialog.component.html',
  styleUrls: ['./image-fragment-dialog.component.scss'],
})
export class ImageFragmentDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ImageFragmentDialogData) {}
}

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  startWith,
  Subscription,
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { debounce } from 'ts-debounce';
import {
  EventCategory,
  SessionEvent,
  SessionEventSubscriber,
} from '../sessions/session/SessionEvent';

import { SpaceRepository } from '../state/space.repository';
import {
  ButtonToasterElement,
  ButtonToasterElementStyle,
} from '../ui/notification-toaster/button-toaster-element/button-toaster-element.component';
import { PanelView } from '../sessions/panel/panel.component';
import { IconMessageToasterElement } from '../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { INFOS } from '../common/utils/notification-constants';
import { ToasterPopupStyle } from '../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import { SUBSCRIPTION_TYPES } from '../models/payment';
import { User } from '../models/user';
import { HighlighterPipe } from '../pipes/highlighter.pipe';
import { ParticipantEventAction } from '../common/interfaces/rtc-interface';
import { modifiedSetTimeout } from '../utilities/ZoneUtils';
import { ForegroundActivityService } from './foreground-activity.service';
import { RealtimeSpaceService } from './realtime-space.service';
import { SessionSharedDataService } from './session-shared-data.service';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from './notification-toaster.service';
import { ProviderStateService } from './provider-state.service';
import { FLAGS, FlagsService } from './flags.service';

enum ForegroundActivityState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
interface InactivityRecord {
  inactive: boolean;
  name: string;
}
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ForegroundActivityNotificationsService implements SessionEventSubscriber {
  private WAIT_TIME_BEFORE_SENDING_INACTIVITY_STATUS = 30 * 1000; // 30 seconds
  private TEMPORARY_MUTE_DURATION = 5 * 60 * 1000; // 5 minutes

  private notificationsMuted = false;

  private localForegroundActivitySubscription?: Subscription;
  private currentSpaceSubscription?: Subscription;
  private participantsEventsSubscriptions?: Subscription;

  private usersForegroundInactivityState: Record<string, InactivityRecord> = {};

  private _userInactivityStatusChanged$ = new BehaviorSubject<string>('');
  public userInactivityStatusChanged$ = this._userInactivityStatusChanged$.asObservable();
  public readonly requiredSubscriptionTypes = [SUBSCRIPTION_TYPES.ENTERPRISE];

  private debouncedSendInactivityEvent = debounce(this.sendInactivityEvent.bind(this), 2 * 1000);
  private debouncedCheckForNotificationTextChanges = debounce(
    this._checkForNotificationTextChanges.bind(this),
    2 * 1000,
  );

  constructor(
    private foregroundInactivityService: ForegroundActivityService,
    private realtimeSpaceService: RealtimeSpaceService,
    private spaceRepository: SpaceRepository,
    private translateService: TranslateService,
    private sharedDataService: SessionSharedDataService,
    private notificationToasterService: NotificationToasterService,
    private providerStateService: ProviderStateService,
    private flagsService: FlagsService,
  ) {}

  public setUpInactivityNotifications(): void {
    combineLatest([
      this.flagsService.featureFlagChanged(FLAGS.INACTIVITY_NOTIFICATIONS),
      this.spaceRepository.isCurrentUserSpaceHost$,
      this.providerStateService.callConnected$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([inactivityNotificationsEnabled, isCurrentUserSpaceHost, callConnected]) => {
        if (!inactivityNotificationsEnabled) {
          return;
        }
        if (!callConnected) {
          this.stopInactivityNotifications();
        } else if (isCurrentUserSpaceHost) {
          this.startReceivingInactivityStatusAndStopSendingIt();
        } else {
          this.startSendingInactivityStatusAndStopReceivingIt();
        }
      });
  }

  public stopInactivityNotifications(): void {
    this.realtimeSpaceService.service.unSubscribeFromEvent(
      this,
      EventCategory.UsersForegroundActivity,
    );
    this.localForegroundActivitySubscription?.unsubscribe();
    this.currentSpaceSubscription?.unsubscribe();
    this.participantsEventsSubscriptions?.unsubscribe();

    this.dismissNotification();

    this.resetInactivityStatuses();

    this.usersForegroundInactivityState = {};
  }

  private startReceivingInactivityStatusAndStopSendingIt(): void {
    // if user was a participant and promoted to host,
    // we should remove the inactivity indicator at the other hosts side
    this.sendInactivityEvent();

    const user = this.sharedDataService.user;

    const notificationsEnabledInUsersSettings =
      user?.institution?.settings?.inactivityNotifications ||
      user?.settings?.inactivityNotifications;

    if (!notificationsEnabledInUsersSettings || !this.userPaidForFeature(user)) {
      return;
    }

    this.realtimeSpaceService.service.subscribeToEvent(this, EventCategory.UsersForegroundActivity);

    this.participantsEventsSubscriptions = this.providerStateService.participantEvents$
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        if (event?.action === ParticipantEventAction.LEFT && event.participant.userId) {
          delete this.usersForegroundInactivityState[event.participant.userId];
          this.debouncedCheckForNotificationTextChanges();
        }
      });

    this.localForegroundActivitySubscription?.unsubscribe();
    this.currentSpaceSubscription?.unsubscribe();
  }

  private startSendingInactivityStatusAndStopReceivingIt(): void {
    this.realtimeSpaceService.service.unSubscribeFromEvent(
      this,
      EventCategory.UsersForegroundActivity,
    );
    this.participantsEventsSubscriptions?.unsubscribe();

    this.localForegroundActivitySubscription =
      this.foregroundInactivityService.isForegroundInactive$
        .pipe(
          untilDestroyed(this),
          debounceTime(this.WAIT_TIME_BEFORE_SENDING_INACTIVITY_STATUS),
          distinctUntilChanged(),
        )
        .subscribe(() => this.debouncedSendInactivityEvent());

    // to send the inactivity event whenever a new user becomes a host
    this.currentSpaceSubscription = this.spaceRepository.activeSpace$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.debouncedSendInactivityEvent());

    // we need the call presence inorder to send our current status whenever a user joins the call
    this.participantsEventsSubscriptions = this.providerStateService.participantEvents$
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        if (event?.action === ParticipantEventAction.JOINED) {
          this.debouncedSendInactivityEvent();
        }
      });

    // removing the status and notification for the case of hosts that are demoted for participants
    this.resetInactivityStatuses();
    this.dismissNotification();
  }

  private sendInactivityEvent(): void {
    // Host can't be considered inactive because this feature is only to show inactive participants
    const foregroundInactive =
      !this.spaceRepository.isCurrentUserSpaceHost &&
      this.foregroundInactivityService.isForegroundInactive;

    this.realtimeSpaceService.service.sendEvent(
      this,
      new SessionEvent(
        EventCategory.UsersForegroundActivity,
        foregroundInactive ? ForegroundActivityState.INACTIVE : ForegroundActivityState.ACTIVE,
        {
          userId: this.sharedDataService.user?._id,
          userName: this.sharedDataService.user?.name,
          roomId: this.spaceRepository.activeSpaceCurrentRoom?.uid,
        },
      ),
    );
  }

  sessionEventReceived(event: SessionEvent, userId: string): void {
    if (
      event.category !== EventCategory.UsersForegroundActivity ||
      !this.spaceRepository.isCurrentUserSpaceHost ||
      !event.data.userId ||
      !event.data.userName ||
      event.data.roomId !== this.spaceRepository.activeSpaceCurrentRoom?.uid
    ) {
      return;
    }

    const inactive = event.type === ForegroundActivityState.INACTIVE;

    if (this.usersForegroundInactivityState[event.data.userId]?.inactive === inactive) {
      // ignore unnecessary updates
      // because they might have been sent from a participant to initialize the state of a newly joined host
      return;
    }

    this.usersForegroundInactivityState[event.data.userId] = {
      inactive: inactive,
      name: event.data.userName,
    };

    this._userInactivityStatusChanged$.next(event.data.userId);
    this.debouncedCheckForNotificationTextChanges();
  }

  private _checkForNotificationTextChanges(): void {
    const inactiveParticipants = Object.values(this.usersForegroundInactivityState).filter(
      (inactivityRecord) => inactivityRecord.inactive,
    );

    if (inactiveParticipants.length === 0) {
      this.dismissNotification();
    } else {
      this.showInactivityNotification(inactiveParticipants);
    }
  }

  private resetInactivityStatuses(): void {
    // resetting the status of all users to be active and publishing that change to front-end components
    for (const userId of Object.keys(this.usersForegroundInactivityState)) {
      delete this.usersForegroundInactivityState[userId];
      this._userInactivityStatusChanged$.next(userId);
    }
    this.usersForegroundInactivityState = {};
  }

  private showInactivityNotification(inactiveParticipants: InactivityRecord[]) {
    if (this.notificationsMuted) {
      return;
    }

    const numberOfInactiveParticipants = inactiveParticipants.length;

    const openParticipantsManagerButton = new ButtonToasterElement(
      [{ icon: 'people', size: 16 }, this.translateService.instant('View inactive users')],
      {
        handler: () => {
          this.sharedDataService.changeRightPanelView.next(PanelView.participantsManager);
        },
        close: false,
      },
      ButtonToasterElementStyle.RAISED,
    );

    const dismissButton = new ButtonToasterElement(
      [{ icon: 'close', size: 16 }, this.translateService.instant('Dismiss')],
      {
        handler: () => {
          this.dismissNotification();
        },
        close: true,
      },
      ButtonToasterElementStyle.FLAT,
    );

    const title = new IconMessageToasterElement(
      { icon: 'bedtime', size: 16 },
      this.translateService.instant('Inactive participants'),
    );

    let otherInactiveUsersString = '';
    if (numberOfInactiveParticipants > 1) {
      otherInactiveUsersString = `${
        numberOfInactiveParticipants - 1
      } ${this.translateService.instant('others')}`;

      if (numberOfInactiveParticipants == 2) {
        otherInactiveUsersString = inactiveParticipants[1].name;
      }
      otherInactiveUsersString = `${this.translateService.instant(
        'and',
      )} ${otherInactiveUsersString}`;
    }
    const notificationSuffix = ` ${this.translateService.instant(
      numberOfInactiveParticipants > 1
        ? 'do not have Pencil Spaces active and may be distracted'
        : 'does not have Pencil Spaces active and may be distracted',
    )}.`;
    const notificationText = `${inactiveParticipants[0].name} ${otherInactiveUsersString} ${notificationSuffix}`;

    const message = new IconMessageToasterElement(
      undefined,
      new HighlighterPipe().transform(
        notificationText,
        inactiveParticipants
          .splice(0, Math.min(inactiveParticipants.length, 2))
          .map((record) => record.name),
        'array',
      ) as string,
    );

    const participantsManagerAlreadyOpened =
      this.sharedDataService.rightPanelView.getValue()?.panelView === PanelView.participantsManager;

    const inactiveParticipantsNotificationData = new NotificationDataBuilder(
      INFOS.PARTICIPANTS_INACTIVE,
    )
      .type(NotificationType.INFO)
      .style(ToasterPopupStyle.INFO)
      .topElements([title])
      .dismissable(false)
      .topRightOptions({
        icon: 'notifications_off',
        items: [
          {
            text: this.translateService.instant('Mute for 5 min'),
            onClick: () => this.muteNotifications(),
          },
          {
            text: this.translateService.instant('Mute for session'),
            onClick: () => this.muteNotifications(true),
          },
        ],
      })
      .middleElements([message])
      .bottomElements(
        participantsManagerAlreadyOpened
          ? [dismissButton]
          : [openParticipantsManagerButton, dismissButton],
        true,
      )
      .priority(590)
      .build();
    this.dismissNotification();
    this.notificationToasterService.showNotification(inactiveParticipantsNotificationData);
  }

  private dismissNotification(): void {
    this.notificationToasterService.dismissNotificationsByCode([INFOS.PARTICIPANTS_INACTIVE]);
  }

  public muteNotifications(entireSession = false) {
    this.notificationsMuted = true;
    this.dismissNotification();
    if (!entireSession) {
      modifiedSetTimeout(() => (this.notificationsMuted = false), this.TEMPORARY_MUTE_DURATION);
    }
  }

  public getUserInactivityObservable(userId: string): Observable<boolean> {
    return this.userInactivityStatusChanged$.pipe(
      untilDestroyed(this),
      filter((currentUserId) => currentUserId === userId),
      map(() => this.getInactivityState()[userId]?.inactive ?? false),
      startWith(this.getInactivityState()[userId]?.inactive ?? false),
    );
  }

  public getInactivityState() {
    return this.usersForegroundInactivityState;
  }

  public userPaidForFeature(user: User | undefined): boolean {
    return this.requiredSubscriptionTypes.includes(user?.subscriptionType as SUBSCRIPTION_TYPES);
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  Subject,
  catchError,
  combineLatest,
  filter,
  map,
  merge,
  of,
  pairwise,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { INFOS } from '../common/utils/notification-constants';
import { PanelView } from '../sessions/panel/panel.component';
import {
  AttendanceUser,
  EventWithTimingInfo,
} from '../sessions/session/attendance/attendance-ui-models';
import { formatMillis } from '../sessions/session/attendance/attendance-utils';
import { SpaceRepository } from '../state/space.repository';
import {
  ButtonToasterElement,
  ButtonToasterElementStyle,
} from '../ui/notification-toaster/button-toaster-element/button-toaster-element.component';
import { ToasterPopupStyle } from '../ui/notification-toaster/custom-notification-toastr/custom-notification-toastr.component';
import {
  IconBackground,
  IconMessageToasterElement,
} from '../ui/notification-toaster/icon-message-toaster-element/icon-message-toaster-element.component';
import { AttendanceService } from './attendance.service';
import { FLAGS, FlagsService } from './flags.service';
import {
  NotificationDataBuilder,
  NotificationToasterService,
  NotificationType,
} from './notification-toaster.service';
import { RealtimeService } from './realtime.service';
import { SessionSharedDataService } from './session-shared-data.service';
import { SpacesService } from './spaces.service';
import { TelemetryService } from './telemetry.service';
import { TimerService } from './timer.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AttendanceManagerService implements OnDestroy {
  private readonly MAX_TIMEOUT_DURATION_IN_MILLI_SECONDS = 1000 * 60 * 60 * 12;
  private readonly eventRealtimeDataTypes = ['event'];
  private readonly attendanceRealtimeDataTypes = [
    'user_attendance_update',
    'user_joins_attendance_event',
  ];
  private timeout?: NodeJS.Timer;
  private events: EventWithTimingInfo[] = [];
  private joinedOnGoingEvent = false;
  private lastFetchDate = new Date();
  private prevShowedNotificationEventId?: string;

  // Observables/Subjects
  public attendanceFlags$ = this.flagsService.featureFlagChanged(FLAGS.PROJECT_SAM).pipe(
    map((flagStatus) => ({
      markAttendance:
        flagStatus && !!this.flagsService.featureFlagsVariables.project_sam.space_attendance,
      attendanceList:
        flagStatus && !!this.flagsService.featureFlagsVariables.project_sam.space_attendance_list,
      logAttendance:
        flagStatus && !!this.flagsService.featureFlagsVariables.project_sam.attendance_logging,
    })),
    tap((flags) => {
      this.logAttendance = flags.logAttendance;
    }),
  );

  private attendanceEnabledSpaceId$ = combineLatest([
    this.attendanceFlags$,
    this.spaceRepo.activeSyncedOrLeftSpaceId$,
  ]).pipe(
    map(([attendanceFlags, id]) => (attendanceFlags.markAttendance && !!id ? id : undefined)),
  );

  private _onGoingEvent$ = new BehaviorSubject<EventWithTimingInfo | undefined>(undefined);
  private refresh$ = new Subject<string | undefined>();
  private resetTrigger$ = this.attendanceEnabledSpaceId$.pipe(filter((id) => !id));

  // Fetching Events in Space
  private fetchEventsTrigger$ = merge(
    this.attendanceEnabledSpaceId$,
    this.realtimeService.realtimeInitOrReconnect$,
    this.refresh$,
  ).pipe(
    withLatestFrom(this.attendanceEnabledSpaceId$),
    map(([_, id]) => id),
    filter((id) => !!id),
    switchMap((id) =>
      this.spacesService
        .getAttendanceEventsInSpace(id!, this.MAX_TIMEOUT_DURATION_IN_MILLI_SECONDS)
        .pipe(
          catchError((error) => {
            this.log({ message: 'Error in fetching current event in space', error });
            return of([]);
          }),
        ),
    ),
  );

  private realtimeUpdateTrigger$ = merge(of(undefined), this.attendanceEnabledSpaceId$).pipe(
    pairwise(),
    tap(([oldSpaceId, _]) => {
      !!oldSpaceId && this.realtimeService.unsubscribeSession(oldSpaceId);
    }),
    map(([_, newSpaceId]) => newSpaceId),
    switchMap((spaceId) => {
      const realtimeToken = spaceId
        ? this.sessionSharedDataService.sessionAuthData[spaceId]?.realtimeToken
        : undefined;
      if (!realtimeToken) {
        return of();
      }
      return this.realtimeService.subscribeSession(spaceId!, realtimeToken).pipe(
        catchError((error) => {
          this.log({ message: 'Error in subscribing to faye session', error });
          return of();
        }),
      );
    }),
    filter(
      (value) =>
        !!value &&
        (this.eventRealtimeDataTypes.includes(value.dataType) ||
          this.attendanceRealtimeDataTypes.includes(value.dataType)),
    ),
  );

  private _attendanceRealtimeUpdates$ = new Subject<AttendanceUser>();
  public attendanceRealtimeUpdates$ = this._attendanceRealtimeUpdates$.asObservable();

  public onGoingEvent$ = this._onGoingEvent$.asObservable();
  private logAttendance = false;

  // Private Methods
  constructor(
    private translateService: TranslateService,
    private notificationToasterService: NotificationToasterService,
    private timerService: TimerService,
    private spaceRepo: SpaceRepository,
    private spacesService: SpacesService,
    private attendanceService: AttendanceService,
    private flagsService: FlagsService,
    private realtimeService: RealtimeService,
    private sessionSharedDataService: SessionSharedDataService,
    private telemetryService: TelemetryService,
  ) {
    this.realtimeUpdateTrigger$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.log({ message: 'realtime update', updateObject: value });
      if (this.attendanceRealtimeDataTypes.includes(value.dataType)) {
        this._attendanceRealtimeUpdates$.next(value.data);
      } else {
        this.refetchEvents();
      }
    });
    this.resetTrigger$.pipe(untilDestroyed(this)).subscribe(() => {
      this.reset();
    });
    this.fetchEventsTrigger$.pipe(untilDestroyed(this)).subscribe((events) => {
      this.lastFetchDate = new Date();
      this.events = events;
      this.checkIfOngoingEvent();
    });
  }

  private async checkIfOngoingEvent() {
    if (!this.events.length) {
      this.log({ message: 'events array is empty' });
      this.reset();
      return;
    }
    let { eventStartTimeOffsetMillis, eventEndtTimeOffsetMillis } = this.events[0];
    const millisSinceLastFetch = Date.now() - this.lastFetchDate.getTime();
    eventStartTimeOffsetMillis += millisSinceLastFetch;
    eventEndtTimeOffsetMillis -= millisSinceLastFetch;

    // Earliest event isn't started yet
    if (eventStartTimeOffsetMillis < 0) {
      this.log({
        message: "event isn't started",
        eventTitle: this.events[0].title,
        timeToStart: `${formatMillis(-eventStartTimeOffsetMillis)}`,
      });
      this.setTimeout(eventStartTimeOffsetMillis);
      this._onGoingEvent$.next(undefined);
      this.joinedOnGoingEvent = false;
    }
    // Earliest event already ended
    else if (eventEndtTimeOffsetMillis <= 0) {
      this.log({
        message: 'event ended',
        eventTitle: this.events[0].title,
        timeToEnd: `${formatMillis(-eventEndtTimeOffsetMillis)}`,
      });
      this.notificationToasterService.dismissNotificationsByCode([INFOS.EVENT_STARTED_IN_SPACE]);
      this.events = this.events.slice(1);
      this._onGoingEvent$.next(undefined);
      this.joinedOnGoingEvent = false;
      this.checkIfOngoingEvent();
    }
    // Earliest event is ongoing and attendance isn't marked yet
    // Make the call to mark the attendance and then re-check if the event is still ongoing or not
    // Because if eventEndtTimeOffsetMillis is too small the event might end while making the API call to update attendance
    else if (!this.joinedOnGoingEvent) {
      this.log({
        message: 'event is ongoing, making mark attendance API call',
        eventTitle: this.events[0].title,
        timeToStart: `${formatMillis(eventStartTimeOffsetMillis)}`,
        timeToEnd: `${formatMillis(eventEndtTimeOffsetMillis)}`,
      });
      this.attendanceService.attendEvent(this.events[0]._id).subscribe((eventAttendance) => {
        this.joinedOnGoingEvent = true;
        this.checkIfOngoingEvent();
        if (eventAttendance) {
          this.log({
            message: 'attendEvent Response',
            eventAttendance: eventAttendance,
          });
        } else {
          this.telemetryService.event(
            "User trying to hit '/attendee/:eventId' endpoint after event ended",
            {
              message: 'attendEvent Response is Empty',
              eventTitle: this.events[0].title,
              eventID: this.events[0]._id,
              eventEndTime: this.events[0].end_time,
              clientTime: new Date(),
              timeToStart: `${formatMillis(eventStartTimeOffsetMillis)}`,
              timeToEnd: `${formatMillis(eventEndtTimeOffsetMillis)}`,
            },
          );
        }
      });
    }
    // Earliest event is ongoing and attendance is marked, it's safe to emit the onGoingEvent to subscribers
    else {
      // Set to false for the upcoming event
      this.joinedOnGoingEvent = false;
      this.events[0].startTimeOnClient = new Date(Date.now() - eventStartTimeOffsetMillis);
      this.log({
        message: 'event is ongoing, setting the timeout',
        eventTitle: this.events[0].title,
        timeToStart: formatMillis(eventStartTimeOffsetMillis),
        timeToEnd: formatMillis(eventEndtTimeOffsetMillis),
        timeSinceLastFetch: formatMillis(millisSinceLastFetch),
        startTimeOnClient: this.events[0].startTimeOnClient,
        lastFetchDate: this.lastFetchDate,
      });
      this._onGoingEvent$.next(this.events[0]);
      this.showEventStartedNotification(this.events[0]);
      this.setTimeout(eventEndtTimeOffsetMillis);
    }
  }

  private reset() {
    this._onGoingEvent$.next(undefined);
    this.joinedOnGoingEvent = false;
    this.clearTimeout();
    this.events = [];
    this.prevShowedNotificationEventId = undefined;
    this.notificationToasterService.dismissNotificationsByCode([INFOS.EVENT_STARTED_IN_SPACE]);
  }

  private async clearTimeout() {
    if (this.timeout) {
      await this.timerService.clearTimeout(this.timeout);
    }
  }
  private async setTimeout(durationInMillis: number) {
    durationInMillis = Math.abs(durationInMillis);
    if (durationInMillis > this.MAX_TIMEOUT_DURATION_IN_MILLI_SECONDS) {
      this.log({ message: 'timeout not set, durationInMillis exceeds MAX_TIMOUT_DURATION' });
      return;
    }
    this.clearTimeout();
    this.timeout = await this.timerService.setTimeout(() => {
      this.checkIfOngoingEvent();
    }, durationInMillis);
  }

  // Public Methods
  public log(logObject: { [key: string]: any }) {
    if (!this.logAttendance) {
      return;
    }
    const logStr = Object.keys(logObject)
      .map((key) => `${key}=${JSON.stringify(logObject[key])}`)
      .join('\r\n');

    this.telemetryService.log('debug', `[attendance-monitoring]\r\n${logStr}`);
  }
  async ngOnDestroy() {
    await this.clearTimeout();
  }
  public refetchEvents() {
    this.refresh$.next(this.spaceRepo.activeSpace?._id);
  }
  public showEventStartedNotification(event: EventWithTimingInfo) {
    if (
      !this.spaceRepo.isCurrentUserHost() ||
      event._id === this.prevShowedNotificationEventId ||
      this.sessionSharedDataService.rightPanelView.getValue()?.panelView ===
        PanelView.participantsManager
    ) {
      return;
    }
    this.prevShowedNotificationEventId = event._id;
    const titleElement = new IconMessageToasterElement(
      { icon: 'info', size: 16 },
      this.translateService.instant('Event started in Space'),
      undefined,
      undefined,
      undefined,
      IconBackground.INFO,
      true,
      true,
    );
    const messageElement = new IconMessageToasterElement(
      undefined,
      this.translateService.instant(
        `Your event “${event.title}” has started in this Space. Click below to view attendance for this event.`,
      ),
    );

    const confirmButtonElement = new ButtonToasterElement(
      [{ icon: 'arrow_forward', size: 16 }, this.translateService.instant('Go to Attendance')],
      {
        handler: () => {
          this.sessionSharedDataService.changeRightPanelView.next(PanelView.participantsManager);
        },
        close: true,
      },
      ButtonToasterElementStyle.RAISED_ACTIVE,
    );

    const cancelButtonElement = new ButtonToasterElement(
      [{ icon: 'close', size: 16 }, this.translateService.instant('Dismiss')],
      {
        handler: () => {
          this.notificationToasterService.dismissNotificationsByCode([
            INFOS.EVENT_STARTED_IN_SPACE,
          ]);
        },
        close: true,
      },
      ButtonToasterElementStyle.FLAT,
    );

    const notificationData = new NotificationDataBuilder(INFOS.EVENT_STARTED_IN_SPACE)
      .style(ToasterPopupStyle.WARN)
      .type(NotificationType.WARNING)
      .width(304)
      .topElements([titleElement])
      .middleElements([messageElement])
      .bottomElements([confirmButtonElement, cancelButtonElement])
      .version2Notification(true)
      .dismissable(false)
      .build();

    this.notificationToasterService.showNotification(notificationData);
  }
}

<section *ngIf="parentMessage" class="replied-to-prview">
  <div class="replied-to-prview-header">
    <h6 class="replied-to-prview-header-text">
      Replying to
      <span *ngIf="user?._id !== parentMessage?.author._id" class="replied-to-prview-user-name">
        {{ parentMessage?.author.name || parentMessage?.author.email }}
      </span>
      <span *ngIf="user?._id === parentMessage?.author._id" class="replied-to-prview-user-name">
        yourself
      </span>
    </h6>
    <div [appTooltip]="closeMessageTooltip" (click)="cancelReply()" class="replied-to-prview-icon">
      <svg-icon [icon]="'close-icon'" [width]="9.33" [height]="9.33"></svg-icon>
    </div>
  </div>
  <p *ngIf="parentMessage?.content[0].type !== 'IMAGE'" class="replied-to-prview-content">
    {{ parentMessage?.content[0].data }}
  </p>
  <div *ngIf="parentMessage?.content[0].type === 'IMAGE'" class="image-chat-box">
    <img [src]="parentMessage?.content[0].data" alt="preview" />
  </div>
</section>
<div
  class="create-div cpt-8"
  [class.cpt-8]="type === 'comment'"
  [class.auto-height]="type === 'message'"
>
  <div class="d-flex justify-content-between position-relative container align-items-center">
    <app-advanced-editor
      class="nonQuestionFragment overflow-hidden"
      style="font-size: 14px"
      #advancedEditor
      [placeholder]="
        (type === 'message' ? 'Type message here...' : 'Please enter comment') | translate
      "
      [collection]="collection"
      [emitTyping]="emitTyping"
      [hideBorder]="true"
      [isFocused]="type === 'message' && autoFocus && !isMobileView"
      [type]="type === 'comment' ? editorTypes.COMMENT : editorTypes.MESSAGE"
      [showUploadDialog]="type === 'comment'"
      [messageContext]="context"
      [popUpMessage]="popUpMessage"
      [hideToolbar]="!enableControls"
      [chatIdentifier]="chatIdentifier"
      [maxHeight]="maxHeight"
      [enableFragmentHover]="false"
      [componentUsedFromChatBox]="true"
      (saveEvent)="updateCollection($event)"
      (keydown.enter)="sendMessageFromEnterKey($event)"
      (focused)="focused.emit($event)"
      (blurred)="blurred.emit($event)"
    >
    </app-advanced-editor>
    <button
      class="add-comment"
      type="button"
      [disabled]="
        (collection?.fragments | emptyCheck) ||
        (collection?.fragments | fragmentUploadInprogressCheck)
      "
      [appTooltip]="'Enter to send' | translate"
      [appTooltipArrow]="'bottom'"
      [appTooltipTipStyle]="{ left: '70%' }"
      (click)="addComment()"
      data-name="send-message-button-confirm"
    >
      <i class="material-icons send-button-active">send</i>
    </button>
    <div *ngIf="collection?.fragments | fragmentUploadInprogressCheck" class="tooltip-div">
      Waiting for upload to complete
    </div>
  </div>
</div>

<ng-template #closeMessageTooltip>
  <app-tooltip
    [text]="'Close' | translate"
    [arrow]="'bottom'"
    [tipStyle]="{ left: '65%' }"
  ></app-tooltip>
</ng-template>

<div class="web-viewer-dialog">
  <div class="web-viewer-dialog-header">
    <div class="web-viewer-dialog-title">
      <svg-icon class="cmr-8 svg-icon" [icon]="'alert'"></svg-icon>
      {{ 'Web Viewer: Security Warning' | translate }}
    </div>
    <button class="btn btn-close" (click)="addWebViewer()" data-name="web-viewer-close">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="web-viewer-dialog-body">
    <div>
      <span class="bold">{{ 'Note:' | translate }}&nbsp;</span
      ><span>{{
        'Anyone with access to this Space can view and interact with this web viewer.' | translate
      }}</span>
    </div>
    <div class="cmt-20">
      {{
        'For security reasons, before exiting this Space, log out of any websites you logged into or, alternatively, delete the web viewer.'
          | translate
      }}
      <a
        rel="noopener"
        href="https://intercom.help/pencil/en/articles/6621448-introduction-to-the-collaborative-browser"
        target="_blank"
        >{{ 'Learn more' | translate }}</a
      >
    </div>
    <button
      class="btn btn-primary btn-confirm"
      (click)="addWebViewer(true)"
      data-name="web-viewer-confirm"
    >
      <mat-icon>chevron_right</mat-icon>{{ 'Proceed' | translate }}
    </button>
  </div>
</div>

<div class="custom-attributes-pills-container">
  <div class="attribute-tag-container">
    <div
      class="attribute-tag"
      [ngbTooltip]="singleTooltip"
      [disableTooltip]="!withTooltip"
      placement="bottom"
    >
      {{ getUserCustomAttributeValues()[0] }}
    </div>

    <ng-template #singleTooltip>
      <app-tooltip
        [text]="getUserCustomAttributeValues()[0]"
        arrow="top"
        [textStyle]="{ 'white-space': 'pre !important', 'text-align': 'center' }"
      >
      </app-tooltip>
    </ng-template>
    <div
      class="attribute-tag counter"
      *ngIf="getUserCustomAttributeValues().length > 1"
      [ngbTooltip]="multiTooltip"
      placement="bottom"
    >
      {{ '+' + (getUserCustomAttributeValues().length - 1) }}
    </div>

    <ng-template #multiTooltip>
      <app-tooltip
        [text]="getUserCustomAttributeValues().join('\n')"
        arrow="top"
        [textStyle]="{ 'white-space': 'pre !important', 'text-align': 'start' }"
      >
      </app-tooltip>
    </ng-template>
  </div>
</div>

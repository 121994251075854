<div #pdfContainer>
  <ng-container *ngIf="previewBoard; else activeBoard">
    <app-pdf-hidden-page></app-pdf-hidden-page>
  </ng-container>
  <!-- loading pdf from url -->
  <app-loading [loading]="loadingData | async"></app-loading>
  <ng-template #activeBoard>
    <ng-container *ngIf="viewModel$ | async as viewModel">
      <app-loading [loading]="viewModel.loading && !viewModel.hidePage"></app-loading>
      <ng-container *ngFor="let pageItem$ of pdfPages; let i = index">
        <ng-container *ngIf="pageItem$ | async as pageItem">
          <app-pdf-page
            #pdfPage
            [pdfName]="viewModel.pdfName"
            [page]="pageItem.page"
            [viewport]="pageItem.viewport"
            [scaleFactor]="scaleFactor"
            [height]="pageItem.height"
            [width]="pageItem.width"
            [hidePage]="viewModel.hidePage"
            [totalPage]="pdfPages.length"
            (pageRendered)="pageLoaded()"
          ></app-pdf-page>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
</div>

<ng-container *ngIf="fragment">
  <div [ngStyle]="style" *ngIf="fragment.type === 'TEXT'">
    <span
      id="questionContent"
      class="questionContent"
      [class.adpative-width]="adaptiveWidth"
      (copy)="onCopy()"
      [ngClass]="{ ugcFont: !styleOverride }"
      ngPreserveWhitespaces
    >
      <ng-container *ngFor="let segment of segments; index as i">
        <div
          [class]="'segment-outer ' + number + i"
          *ngIf="segment.math"
          [ngClass]="{ firstSegment: i === 0 }"
        >
          <span class="fragment-number" *ngIf="number && i === 0">{{ number }}. </span>
          <span
            *ngIf="replaceEquations; else renderEquation"
            class="equation-replacement mainText2"
          >
            {{ 'equation' | translate }}
          </span>
          <ng-template #renderEquation>
            <ng-katex
              [equation]="segment.raw"
              [options]="{ displayMode: segment.type === 'display' }"
            >
            </ng-katex>
          </ng-template>
        </div>
        <div
          class="segment-outer"
          [ngClass]="{ firstSegment: i === 0 }"
          *ngIf="!segment.math && (segment?.value | markdownHtml: compressedRendering)"
        >
          <span class="fragment-number" *ngIf="number && i === 0">{{ number }}.</span>
          <span
            [innerHTML]="segment?.value | markdownHtml: compressedRendering:true | safeHtml"
          ></span>
        </div>
        <div
          class="segment-outer"
          *ngIf="!segment.math && !(segment?.value | markdownHtml: compressedRendering)"
        >
          <span class="fragment-number" *ngIf="number && i === 0">{{ number }}.</span>
        </div>
      </ng-container>
      <span *ngIf="marks" class="marks-text">[{{ marks }}]</span>
    </span>
  </div>
  <div class="fragment-content segment-outer" *ngIf="fragment.type === 'TABLE'">
    <span class="fragment-number" *ngIf="number">{{ number }}.</span>
    <app-table-fragment [fragment]="fragment"></app-table-fragment>
  </div>
  <ng-container *ngIf="fragment.type === 'ANSWERTEXT' && lines.length > 0">
    <div
      [class.ruled]="metadata.answerFormat === 'Ruled' || metadata.answerFormat === 'RULED'"
      [class.blank]="metadata.answerFormat === 'Blank' || metadata.answerFormat === 'BLANK'"
      [class.graph]="metadata.answerFormat === 'Graph' || metadata.answerFormat === 'GRAPH'"
      [class.lined]="metadata.answerFormat === 'Lined' || metadata.answerFormat === 'LINED'"
      class="position-relative fragment-content segment-outer"
      (copy)="onCopy()"
    >
      <span class="fragment-number" *ngIf="number">{{ number }}.</span>
      <div class="answerText" *ngFor="let l of lines"></div>
    </div>
  </ng-container>
  <span
    *ngIf="fragment.type === 'IMAGE'"
    class="d-flex img-fragment fragment-content segment-outer"
    [class.image-loading]="
      showLoadingIndicator || (signImage && (imageIsSigned$ | async) === false)
    "
    [style.justify-content]="style ? style['justify-content'] : ''"
    (copy)="onCopy()"
  >
    <span class="fragment-number" *ngIf="number">{{ number }}.</span>
    <app-image-resize-new
      [frag]="newFragment"
      [posStyle]="style"
      *ngIf="
        router.url.includes('/view') ||
        router.url.includes('/present') ||
        router.url.includes('/print') ||
        router.url.includes('/worksheets/list')
      "
      (saveData)="saveSize()"
    ></app-image-resize-new>
    <ng-container
      *ngIf="
        !router.url.includes('/view') &&
        !router.url.includes('/present') &&
        !router.url.includes('/print') &&
        !router.url.includes('/worksheets/list')
      "
    >
      <div
        *ngIf="showLoadingIndicator || (signImage && (imageIsSigned$ | async) === false)"
        class="image-loading-spinner"
      >
        <app-spinner [size]="30"></app-spinner>
      </div>
      <img
        *ngIf="!showLoadingIndicator && (imageIsSigned$ | async)"
        alt=""
        class="img-default"
        [style.max-width]="'100%'"
        [style.height]="'100%'"
        [style.margin-top]="'5px'"
        [ngStyle]="style"
        (click)="openImageDialog(fragment)"
        src="{{ fragment.data }}"
      />
    </ng-container>
  </span>
  <div
    *ngIf="fragment.type == 'KATEX'"
    class="text-center fragment-content segment-outer"
    (copy)="onCopy()"
  >
    <span class="fragment-number" *ngIf="number">{{ number }}.</span>
    <ng-katex equation="{{ fragment.data }}" alt="No equation entered"></ng-katex>
  </div>
  <div
    *ngIf="fragment.type === 'YOUTUBE'"
    class="text-center fragment-content segment-outer"
    style="height: 100%"
    [style.display]="showVideoThumbnail ? 'block' : ''"
    (copy)="onCopy()"
  >
    <span class="fragment-number" *ngIf="number">{{ number }}.</span>
    <div *ngIf="print">
      <ngx-qrcode
        [value]="(question?._id ? question?._id : note?._id) | qrcode"
        errorCorrectionLevel="L"
        class="qrcode w-108 cpr-8"
      >
      </ngx-qrcode>
      Video
    </div>
    <ng-container
      *ngIf="
        fragment.data | embedVimeoVideo: { attr: { width: 600, height: 400 } } | async as vimeoData
      "
    >
      <ng-container *ngIf="vimeoData.status === 'ready'; else videoProcessing">
        <img
          *ngIf="showVideoThumbnail"
          class="thumbnail"
          [src]="videoThumbnail"
          [alt]="videoThumbnail"
        />
        <div
          appElementRendered
          (rendered)="handleVideoRendered()"
          *ngIf="!print"
          class="video-container"
          [innerHtml]="vimeoData.innerHTML"
        ></div>
      </ng-container>
      <ng-template #videoProcessing>
        <app-loading
          [loading]="true"
          [layoutType]="'processing'"
          [size]="70"
          icon="pencil_loading_black"
        ></app-loading>
      </ng-template>
    </ng-container>
  </div>
  <div class="fragment-content segment-outer" *ngIf="fragment.type === 'PDF'" (copy)="onCopy()">
    <div class="fragment-content-pdf">
      <span class="fragment-number" *ngIf="number">{{ number }}.</span>
    </div>
    <app-pdf-fragment-container [src]="fragment.data"></app-pdf-fragment-container>
  </div>
  <div class="fragment-content segment-outer" *ngIf="fragment.type === 'PHET'" (copy)="onCopy()">
    <span class="fragment-number" *ngIf="number">{{ number }}.</span>
    <div *ngIf="print">
      <ngx-qrcode
        [value]="(question?._id ? question?._id : note?._id) | qrcode"
        errorCorrectionLevel="L"
        class="qrcode w-108 cpr-8"
      >
      </ngx-qrcode>
      Simulation
    </div>
    <app-phet-embed *ngIf="!print" [frag]="fragment"></app-phet-embed>
  </div>
</ng-container>
<div *ngIf="!fragment" class="fragment-conetnt segment-outer">
  <div class="fragment-number" *ngIf="number">{{ number }}.</div>
  <div class="empty-fragment"></div>
</div>

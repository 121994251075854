import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { from, take } from 'rxjs';
import { RtcServiceController } from 'src/app/services/rtc.service';
import { IconTypes } from 'src/app/standalones/components/pencil-icon/pencil-icon.component';

@Component({
  selector: 'app-logout-dialog',
  templateUrl: 'logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LogoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private rtcControllerService: RtcServiceController,
  ) {}

  ngOnInit(): void {
    if (this.rtcControllerService.service.isConnected()) {
      from(Promise.resolve(window.confirm(this.translate.instant('Changes not saved'))))
        .pipe(take(1))
        .subscribe((result) => {
          if (!result) {
            this.dialogRef.close(false);
          }
        });
    }
  }

  protected readonly IconTypes = IconTypes;
}

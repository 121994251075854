<div *ngIf="messages">
  <div *ngIf="user && (user | isStudent)">
    <div class="border-bottom cpy-8 systemFont commentHeader" *ngIf="type === 'comment'">
      {{ 'History' | translate }}
    </div>
    <div>
      <ng-container *ngFor="let comment of history">
        <div class="d-flex cpy-24 justify-content-between border-bottom">
          <div (click)="comment['clicked'] = !comment['clicked']" class="cursor-pointer w-100">
            <div class="d-flex history">
              <app-profile-photo
                class="cmr-16"
                width="32"
                [user]="comment?.author"
              ></app-profile-photo>
              <div class="flex-1 history-content">
                <div class="d-flex align-items-center justify-content-between">
                  <p class="systemFont" style="font-size: 13px">
                    <span>{{ comment?.author?.name }}{{ comment?.log | translate }}</span>
                    <span class="cml-8" style="font-size: 12px">
                      {{ comment?.createdAt | dateLocale: DateFormatEnum.MEDIUM }}</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <a
    *ngIf="remainingMessageCount > 0"
    (click)="fetchMore()"
    class="more-btn systemFont commentHeader cmt-32 cmb-0"
    [class.text-center]="type === 'message'"
    >{{ remainingMessageCount }}
    {{ type === 'comment' ? ('more comments' | translate) : ('more messages' | translate) }}</a
  >
  <mat-expansion-panel
    [expanded]="true"
    class="comment-exp-panel cmt-8"
    *ngIf="type === 'comment' && messages.messages.length"
  >
    <mat-expansion-panel-header class="comment-exp-panel-header">
      <mat-panel-description class="comment-exp-panel-description">
        <mat-panel-title class="comment-exp-panel-title">
          {{ 'COMMENTS' | translate }}
        </mat-panel-title>
        <span class="d-inline-flex align-items-center">
          <span class="comment-exp-panel-count">({{ messages.messages.length }})</span>
          <i class="material-icons expand-indicator comment-exp-panel-expand-indicator"
            >keyboard_arrow_down</i
          >
        </span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="comment-container cpt-8">
      <ng-container *ngFor="let comment of messages.messages; trackBy: trackById">
        <div class="d-flex cpy-8 justify-content-between" id="{{ comment._id }}">
          <div (click)="comment['clicked'] = !comment['clicked']" class="cursor-pointer w-100">
            <div class="d-flex w-100">
              <app-profile-photo
                class="cmr-8 cml-8"
                width="28"
                [user]="comment?.author"
              ></app-profile-photo>
              <div class="flex-1 comment-content" [class.w-100]="comment.content | includesVideo">
                <div class="comment-wrapper d-flex align-items-center justify-content-between">
                  <p class="systemFont comment-name" style="font-size: 10px">
                    <span>{{ comment?.author?.name }}{{ comment?.log | translate }}</span>
                    <span class="comment-date">{{
                      ' | ' + (comment?.createdAt | dateLocale: DateFormatEnum.SHORTYEAR)
                    }}</span>
                  </p>
                  <div class="d-flex cml-8">
                    <span
                      *ngIf="comment?.author?._id === user?._id"
                      class="material-icons comment-more-btn"
                      [matMenuTriggerFor]="questionMenu"
                      [class.active]="isMenuOpened"
                      (menuOpened)="isMenuOpened = true"
                      (menuClosed)="isMenuOpened = false"
                      >more_horiz</span
                    >
                    <mat-menu #questionMenu="matMenu">
                      <button
                        *ngIf="comment?._id != messageBeingEdited"
                        mat-menu-item
                        (click)="editComment(comment)"
                      >
                        {{ 'Edit' | translate }}
                      </button>
                      <button
                        *ngIf="comment?._id === messageBeingEdited"
                        mat-menu-item
                        (click)="saveEditedComment(comment)"
                      >
                        {{ 'Save' | translate }}
                      </button>
                      <button
                        *ngIf="comment?._id === messageBeingEdited"
                        mat-menu-item
                        (click)="cancelEditing()"
                      >
                        {{ 'Close' | translate }}
                      </button>
                      <button mat-menu-item (click)="deleteComment(comment)">
                        {{ 'Delete' | translate }}
                      </button>
                    </mat-menu>
                  </div>
                </div>
                <div
                  *ngIf="comment?._id !== messageBeingEdited"
                  class="nonQuestionFragment ugcFont text-content"
                >
                  <ui-fragment-container
                    class="nonQuestionFragment"
                    [fragments]="comment?.content"
                  ></ui-fragment-container>
                </div>
                <!-- ***** like and reply buttons are temporrary removed ***** -->
                <!-- <div class="comment-action-bar">
                  <button class="systemFont comment-action-btn comment-reply-btn">
                    <span class="comment-action-ico material-icons">reply</span
                    >{{ 'Reply' | translate }}
                  </button>
                  <button class="systemFont comment-action-btn comment-like-btn">
                    <span class="comment-action-ico material-icons-outlined">thumb_up_alt</span
                    >{{ 'Like' | translate }}
                  </button>
                </div> -->
                <!-- ***** like and reply buttons are temporrary removed ***** -->
                <div *ngIf="comment?._id === messageBeingEdited" class="cpt-8">
                  <app-advanced-editor
                    class="nonQuestionFragment"
                    style="font-size: 14px"
                    [type]="editorTypes.COMMENT"
                    [collection]="messageCollectionBeingEdited"
                  >
                  </app-advanced-editor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </mat-expansion-panel>
  <div *ngIf="type === 'message' && messages" class="message-container">
    <ng-container *ngFor="let message of messages.messages; trackBy: trackById; let i = index">
      <div
        [style.padding-bottom.px]="
          message?.author?._id === messages.messages[i + 1]?.author?._id ? 5 : 10
        "
      >
        <div (click)="message['clicked'] = !message['clicked']" class="cursor-pointer">
          <!--TODO(dt): Cross reference the author id with the siblings list.-->
          <div
            class="d-flex"
            [class.justify-content-start]="messages.user?._id !== message.author?._id"
            [class.justify-content-end]="messages.user?._id === message.author?._id"
          >
            <app-profile-photo
              *ngIf="
                messages.user?._id !== message.author?._id &&
                message?.author?._id !== messages.messages[i + 1]?.author?._id
              "
              class="cmr-16 message-avatar"
              width="20"
              [user]="message?.author"
            ></app-profile-photo>
            <div
              [class.sent]="messages.user?._id === message.author?._id"
              [class.received]="messages.user?._id !== message.author?._id"
              [class.no-avatar]="message?.author?._id === messages.messages[i + 1]?.author?._id"
              [class.has-video]="message.content | includesVideo"
            >
              <div
                class="nonQuestionFragment ugcFont message-content"
                *ngIf="message?._id != messageBeingEdited"
              >
                <ui-fragment-container
                  class="nonQuestionFragment"
                  [fragments]="message?.content"
                ></ui-fragment-container>
              </div>
              <p
                *ngIf="message?.author?._id !== messages.messages[i + 1]?.author?._id"
                class="systemFont message-time"
                [class.text-left]="messages.user?._id !== message.author?._id"
                [class.text-right]="messages.user?._id === message.author?._id"
              >
                <span>{{ message?.createdAt | dateLocale: DateFormatEnum.MEDIUM }}</span>
              </p>
              <app-advanced-editor
                class="nonQuestionFragment"
                style="font-size: 14px"
                *ngIf="message?._id === messageBeingEdited"
                [type]="editorTypes.COMMENT"
                [collection]="messageCollectionBeingEdited"
              >
              </app-advanced-editor>
            </div>
          </div>
        </div>
        <div
          class="d-flex cml-8"
          *ngIf="message.clicked"
          [class.justify-content-end]="messages.user?._id === message.author?._id"
        >
          <button
            *ngIf="
              !isPopup && message?.author?._id === user?._id && message?._id != messageBeingEdited
            "
            class="mutate-button material-icons"
            type="button"
            (click)="editComment(message)"
          >
            edit
          </button>
          <button
            *ngIf="message?._id === messageBeingEdited"
            class="mutate-button material-icons"
            type="button"
            (click)="saveEditedComment(message)"
          >
            save
          </button>
          <button
            *ngIf="message?._id === messageBeingEdited"
            class="mutate-button material-icons"
            type="button"
            (click)="cancelEditing()"
          >
            close
          </button>
          <button
            *ngIf="message?.author?._id === user?._id"
            class="mutate-button material-icons"
            type="button"
            (click)="deleteComment(message)"
          >
            delete
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>

/**
 * changes to this file will affect both request access and waiting room.
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, catchError, take } from 'rxjs';
import { AdmissionStatus } from 'src/app/models/session';
import { User } from 'src/app/models/user';
import { FlagsService } from 'src/app/services/flags.service';
import { RealtimeService } from 'src/app/services/realtime.service';
import { SessionSharedDataService } from 'src/app/services/session-shared-data.service';
import { UserService } from 'src/app/services/user.service';
import { SpaceRepository, filterNotSynced } from 'src/app/state/space.repository';
import { AclService } from 'src/app/services/acl.service';
import { Router } from '@angular/router';
import { RequestAccessClientBaseService } from './request-access-client-base.service';
import { RequestAccessNotificationBaseService } from './request-access-notifications-base.service';

export interface AccessResponseBody {
  requestersIDs: string[];
  admissionStatus: AdmissionStatus;
}

export const SPACE_NOT_FOUND_ERR = 'space not found';
export const INVALID_SPACE_ID_ERR = 'invalid space id';

@Injectable()
export abstract class RequestAccessBaseService {
  protected sessionChannelSub?: Subscription = new Subscription();
  protected userChannelSub: Subscription = new Subscription();
  protected userApprovedFromNotificationSub: Subscription = new Subscription();
  protected userRejectedFromNotificationSub: Subscription = new Subscription();
  protected accessRequestsOpenedSub: Subscription = new Subscription();
  protected allUsersApprovedFromNotificationSub: Subscription = new Subscription();

  protected _requestAccessSent$: Subject<boolean> = new Subject<boolean>();
  protected _approved$: Subject<boolean> = new Subject<boolean>();
  protected _denied$: Subject<boolean> = new Subject<boolean>();

  protected _accessRequesters$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  protected _accessRequestersSize$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  protected _shouldShowAccessRequests$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  protected spaceID: string = '';
  protected user!: User;

  protected constructor(
    protected userService: UserService,
    protected flagsService: FlagsService,
    protected realtimeService: RealtimeService,
    protected spaceRepo: SpaceRepository,
    protected aclService: AclService,
    protected sessionSharedDataService: SessionSharedDataService,
    protected requestAccessClientService: RequestAccessClientBaseService,
    protected requestAccessNotificationService: RequestAccessNotificationBaseService,
    protected router: Router,
  ) {
    this.userService.user.subscribe((res: any) => {
      if (!res?.user) {
        return;
      }
      this.user = res.user;
    });
  }

  setUserChannelSub() {
    this.userChannelSub.unsubscribe();
    this.userChannelSub = this.realtimeService.userSignal.subscribe((message) =>
      this.handleUserMessage(message),
    );
  }

  protected abstract handleUserMessage(message: any): void;

  protected handleAccessRequestUserUpdates(message: any) {
    if (!message.data || !message.dataId || message.id !== this.user._id) {
      return;
    }
    const admissionStatus: AdmissionStatus = message.data;
    this.emitResponse(admissionStatus);
  }

  protected emitResponse(admissionStatus: AdmissionStatus) {
    if (admissionStatus === AdmissionStatus.ADMITTED) {
      this._approved$.next(true);
    } else if (admissionStatus === AdmissionStatus.DENIED) {
      this._denied$.next(true);
    }
  }

  protected setSessionChannelSub() {
    const realtimeToken =
      this.sessionSharedDataService.sessionAuthData[this.spaceID]?.realtimeToken;
    if (realtimeToken) {
      this.sessionChannelSub?.unsubscribe();
      this.sessionChannelSub = this.realtimeService
        .subscribeSession(this.spaceID, realtimeToken)
        .subscribe((message) => this.handleSessionMessage(message));
    }
  }

  protected abstract handleSessionMessage(message: any): void;
  protected abstract handleSessionUpdates(message: any): void;

  protected setNotificationsSubs() {
    this.userApprovedFromNotificationSub.unsubscribe();
    this.userApprovedFromNotificationSub =
      this.requestAccessNotificationService.requesterApproved$.subscribe((requester) =>
        this.approve([requester._id]),
      );
    this.userRejectedFromNotificationSub.unsubscribe();
    this.userRejectedFromNotificationSub =
      this.requestAccessNotificationService.requesterRejected$.subscribe((requester) =>
        this.reject([requester._id]),
      );
    this.allUsersApprovedFromNotificationSub.unsubscribe();
    this.allUsersApprovedFromNotificationSub =
      this.requestAccessNotificationService.approveAll$.subscribe(() => this.approveAll());
  }

  protected fireNotificationAfterSpaceSynced() {
    this.requestAccessNotificationService.stopShowingNotifications();
    // this is to fix an issue that causes the notification to appear on the loading screen
    this.spaceRepo.activeSpace$.pipe(filterNotSynced(), take(1)).subscribe(() => {
      this.requestAccessNotificationService.startShowingNotifications();
      this.requestAccessNotificationService.showNotificationIfThereAreRequests(
        this._accessRequesters$.value,
      );
    });
  }

  protected cleanupSubs(unsubscribeFromAllSubs: boolean) {
    // clear current requesters
    this._accessRequesters$.next([]);
    this._accessRequestersSize$.next(0);

    // only if will stop listening too
    if (unsubscribeFromAllSubs) {
      if (this.sessionChannelSub) {
        this.sessionChannelSub.unsubscribe();
        this.sessionChannelSub = undefined;
        this.realtimeService.unsubscribeSession(this.spaceID);
      }
    }
    // to stop showing
    this._shouldShowAccessRequests$.next(false);
    this.accessRequestsOpenedSub.unsubscribe();
    this.userApprovedFromNotificationSub.unsubscribe();
    this.userRejectedFromNotificationSub.unsubscribe();
    this.allUsersApprovedFromNotificationSub.unsubscribe();
    this.requestAccessNotificationService.dismissRequestsNotification();
  }

  /* methods called from outside this service */

  // NOTE : in the request access scenario , the active session is not set yet , so this is used to set the space id from
  //        the request access component in this case.

  public setCurrentSpaceID(spaceID: string) {
    this.spaceID = spaceID;
  }

  public requestAccess() {
    this.requestAccessClientService
      .sendAccessRequest(this.spaceID)
      .pipe(catchError((error) => this.handleRequestAccessErrors(error)))
      .subscribe((res) => {
        this._requestAccessSent$.next(true);
      });
  }

  protected abstract handleRequestAccessErrors(error: any): any;

  public approve(requestersIDs: string[]) {
    if (requestersIDs.length > 0) {
      this.accessRespond(requestersIDs, AdmissionStatus.ADMITTED);
    }
  }

  public approveAll() {
    const requestersIDs: string[] = this.getAllRequestersIDs();
    this.approve(requestersIDs);
  }

  public approveAllNonAnonymousRequests() {
    const nonAnonymousRequestersIDs: string[] = this.getAllNonAnonymousRequestersIDs();
    if (nonAnonymousRequestersIDs.length > 0) {
      this.approve(nonAnonymousRequestersIDs);
    }
  }

  public reject(requestersIDs: string[]) {
    if (requestersIDs.length > 0) {
      this.accessRespond(requestersIDs, AdmissionStatus.DENIED);
    }
  }

  public rejectAll() {
    const requestersIDs: string[] = this.getAllRequestersIDs();
    this.reject(requestersIDs);
  }

  protected abstract accessRespond(requestersIDs: string[], admissionStatus: AdmissionStatus): void;

  public getAllNonAnonymousRequestersIDs(): string[] {
    return this._accessRequesters$.value
      .filter((user) => !this.aclService.isAnonymous(user))
      .map((requester) => requester._id);
  }

  public getAllRequestersIDs(): string[] {
    return this._accessRequesters$.value.map((requester) => requester._id);
  }

  public get requestAccessSent$() {
    return this._requestAccessSent$.asObservable();
  }

  public get approved$() {
    return this._approved$.asObservable();
  }

  public get denied$() {
    return this._denied$.asObservable();
  }

  public get accessRequesters$() {
    return this._accessRequesters$.asObservable();
  }

  public get accessRequestersSize$() {
    return this._accessRequestersSize$.asObservable();
  }

  public get shouldShowAccessRequests$() {
    return this._shouldShowAccessRequests$.asObservable();
  }

  public shouldShowAccessRequestsValue() {
    return this._shouldShowAccessRequests$.value;
  }
}

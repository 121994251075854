import {
  Component,
  ChangeDetectionStrategy,
  Input,
  DoCheck,
  ViewChildren,
  QueryList,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  from,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import pdfjsLib from 'src/assets/pdfjs-config';
import { FLAGS, FlagsService } from 'src/app/services/flags.service';
import { SessionSharedDataService } from 'src/app/services/session-shared-data.service';
import { ItemsCanvasService } from 'src/app/services/items-canvas.service';
import { PdfPageComponent } from 'src/app/standalones/components/pdf-viewer/pdf-page/pdf-page.component';
import { AccessState, CanvasItem } from '../../items-canvas/items-canvas.component';
import { PdfContext } from '../../item-wrapper/item-wrapper.component';
import { SpaceZoomCutoffService } from '../../../../services/space-zoom-cutoff.service';
import { DeviceAndBrowserDetectorService } from '../../../../services/device-and-browser-detector.service';
import { BoundingRect } from '../../../../services/sessions-vpt.service';

export const MAX_PDF_PAGES_TO_RENDER = 500;
const MIN_PAGE_ENDER_ZOOM_LEVEL = 0.25;
export const PDF_VIEWPORT_SCALE_FACTOR = 1.4;

export const PDF_PAGE_DEFAULT_HEIGHT = 1100;
export const PDF_PAGE_DEFAULT_WIDTH = 777;

@Component({
  selector: 'app-pdf-viewer[canvasItem]',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfViewerComponent implements DoCheck, OnInit {
  @Input() canvasItem?: CanvasItem;
  @Input() previewBoard = false;
  @Output() pdfRendered = new EventEmitter<BoundingRect>();

  constructor(
    private flagsService: FlagsService,
    private sharedDataService: SessionSharedDataService,
    private cdRef: ChangeDetectorRef,
    private itemsCanvasService: ItemsCanvasService,
    private spaceZoomCutoffService: SpaceZoomCutoffService,
    private deviceAndBrowserDetectorService: DeviceAndBrowserDetectorService,
  ) {
    this.scaleFactor = this.flagsService.isFlagEnabled(FLAGS.ENABLE_PDF_UPSCALE)
      ? (this.flagsService.featureFlagsVariables.enable_pdf_upscale.pdf_scale_factor as number)
      : 1.0;
  }

  @ViewChild('pdfContainer') pdfContainer?: ElementRef<HTMLDivElement>;
  @ViewChildren('pdfPage') vPdfPages?: QueryList<PdfPageComponent>;

  isLoading = new BehaviorSubject<boolean>(true);
  loadingData = new BehaviorSubject<boolean>(false);

  pdfPages: Observable<{
    page: pdfjsLib.PDFPageProxy;
    viewport: pdfjsLib.PageViewport;
    height: number;
    width: number;
  }>[] = [];
  private pageCache = new Map<number, pdfjsLib.PDFPageProxy>();

  viewModel$ = of({
    hidePage: this.zoomLevel < MIN_PAGE_ENDER_ZOOM_LEVEL,
    pdfName: '',
    loading: false,
  });

  scaleFactor = 1.0;

  getPdfSource(contentId: string): Observable<string | Uint8Array> {
    const observable$ = this.itemsCanvasService.getFileByItemId(contentId);
    if (observable$) {
      return observable$;
    }

    return from(
      this.itemsCanvasService.getTrustedUrl(
        this.canvasItem?.relatedItem?.resource_state?.resource_url as string,
        this.canvasItem?.relatedItem?.resource_state?.public_url,
      ),
    );
  }

  ngOnInit(): void {
    const contentId = this.canvasItem?.contentId as string;

    if (this.previewBoard) {
      return;
    }

    this.itemsCanvasService.removeRelatedResourceLoadingItems(contentId, this.canvasItem?.frameUid);

    if (this.sharedDataService.itemsData$[contentId]) {
      this.initObservables();
    } else {
      this.loadingData.next(true);
      this.getPdfSource(contentId)
        .pipe(
          take(1),
          switchMap((pdfResourceUrl) => from(pdfjsLib.getDocument(pdfResourceUrl).promise)),
        )
        .subscribe({
          next: (pdf: pdfjsLib.PDFDocumentProxy) => {
            this.sharedDataService.itemsData$[contentId] = new BehaviorSubject(null);
            const pdfContext = {
              documentProxy: pdf,
              pages$: {},
            };

            if (this.canvasItem?.itemSettings?.resourceComponentInputs) {
              this.canvasItem.itemSettings.resourceComponentInputs.pdf = pdfContext;
            }

            // Store the pdfContext in the session shared data service cache.
            this.sharedDataService.itemsData$[contentId].next({
              accessState: AccessState.CanAccess,
              item: pdfContext,
            });
            this.initObservables();
          },
          error: () => {
            this.sharedDataService.changeCanvasItemsDict.next({ itemId: contentId });
          },
          complete: () => {
            this.loadingData.next(false);
          },
        });
    }
  }

  initObservables(): void {
    this.viewModel$ = combineLatest([
      this.spaceZoomCutoffService.shouldCutOff$,
      of(this.canvasItem?.relatedItem?.resource_state?.resource_name as string),
      this.isLoading.asObservable(),
    ]).pipe(
      tap(() => {
        if (!this.hidePage && !this.isLoading.getValue() && this.pdfPages.length === 0) {
          this.isLoading.next(true);
        }
      }),
      map(([shouldCutOff, pdfName, loading]) => ({
        hidePage: shouldCutOff,
        pdfName,
        loading,
      })),
    );
  }

  ngDoCheck(): void {
    this.initPages();
  }

  initPages(): void {
    if (!this.canInitPage) {
      return;
    }

    this.isLoading.next(true);
    this.pdfPages = Array.from(
      {
        length: Math.min(this.pdf?.documentProxy.numPages as number, MAX_PDF_PAGES_TO_RENDER),
      },
      (_, pageIndex) => {
        const pdfPageSource$ = this.pageCache.has(pageIndex)
          ? of(this.pageCache.get(pageIndex) as pdfjsLib.PDFPageProxy)
          : from((this.pdf as PdfContext).documentProxy.getPage(pageIndex + 1)).pipe(
              tap((page) => this.pageCache.set(pageIndex, page)),
            );

        return pdfPageSource$.pipe(
          map((page) => {
            const viewport = page.getViewport({ scale: this.scaleForPdfPages });
            return {
              page,
              viewport,
              height: Math.floor(viewport.height / this.scaleFactor),
              width: Math.floor(viewport.width / this.scaleFactor),
            };
          }),
        );
      },
    );
  }

  get scaleForPdfPages(): number {
    if (this.deviceAndBrowserDetectorService.isiOSiPhone()) {
      return (
        (this.flagsService.featureFlagsVariables.enable_pdf_upscale
          .pdf_scale_factor_mobile_ios as number) || 1
      );
    }
    if (this.deviceAndBrowserDetectorService.isAndroid()) {
      return (
        (this.flagsService.featureFlagsVariables.enable_pdf_upscale
          .pdf_scale_factor_mobile_android as number) || 1.2
      );
    }
    return this.scaleFactor * PDF_VIEWPORT_SCALE_FACTOR;
  }

  pageLoaded(): void {
    this.isLoading.next(false);
    this.cdRef.detectChanges();
    if (this.pdfContainer?.nativeElement) {
      this.pdfRendered.emit(this.pdfContainer.nativeElement.getBoundingClientRect());
    }
  }

  get pdf(): PdfContext | undefined {
    return this.canvasItem?.itemSettings?.resourceComponentInputs?.pdf;
  }

  get zoomLevel(): number {
    return this.sharedDataService.fabricCanvas?.getZoom() || 1;
  }

  get hidePage(): boolean {
    return this.zoomLevel < MIN_PAGE_ENDER_ZOOM_LEVEL;
  }

  get canInitPage(): boolean {
    return this.pdfPages.length === 0 && !this.previewBoard;
  }
}

<mat-expansion-panel class="custom-panel" expanded hideToggle="true" #expansionPanel>
  <mat-expansion-panel-header class="mat-header">
    <mat-panel-title>
      <div class="header-column">
        <div class="title">{{ title }}</div>
        <div class="description">{{ description }}</div>
      </div>
    </mat-panel-title>
    <mat-icon *ngIf="!expansionPanel.expanded">keyboard_arrow_down</mat-icon>
    <mat-icon *ngIf="expansionPanel.expanded">keyboard_arrow_up</mat-icon>
  </mat-expansion-panel-header>
  <div class="horizontal-line"></div>
  <div class="mat-expansion-panel-body mat-expansion-panel-content">
    <ng-content></ng-content>
  </div>
</mat-expansion-panel>

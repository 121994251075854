import { Pipe, PipeTransform } from '@angular/core';
import { QuestionContainer } from '../content/lhs/lhs.component';

@Pipe({
  name: 'worksheetTooltip',
})
export class WorksheetTooltipPipe implements PipeTransform {
  transform(container: QuestionContainer, worksheets?: any): any {
    const tips: any[] = [];
    worksheets.forEach(ws => {
      if (ws.items && ws.items.findIndex(item => item._id === container.item._id) > -1 && ws.title) {
        tips.push(ws.title);
      }
    });

    if (tips.length === 1) {
      return `Part of set: ${tips[0]}`;
    }

    if (tips.length > 1) {
      return `Part of ${tips.length} sets: ${tips.join(', ')}`;
    }
    
    return '';
  }
}

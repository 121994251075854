import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';

import { Event } from '../../models/event';
import { IconTypes } from '../../standalones/components/pencil-icon/pencil-icon.component';
import { AvailabilityService, GetAvailabilityResponse } from '../../services/availability.service';
import { ConfirmationModalV2Component } from '../confirmation-modal-v2/confirmation-modal-v2.component';
import { AvailabilityStatus, UserAvailabilityData } from '../../models/availability';
import { DateFormatEnum } from '../../pipes/dateUserLocale.pipe';

@Component({
  selector: 'app-available-event-hosts-dialog',
  templateUrl: './available-event-hosts-dialog.component.html',
  styleUrls: ['./available-event-hosts-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailableEventHostsDialogComponent implements AfterViewInit {
  event: Event;
  isLoading = true;
  IconTypes = IconTypes;
  pageSize = 20;
  hostsToAdd: string[] = [];
  DateFormatEnum = DateFormatEnum;
  tableColumns = ['user', 'status', 'actions'];
  dataSource?: MatTableDataSource<UserAvailabilityData>;
  sortingOrder = {
    [AvailabilityStatus.AVAILABLE]: 1,
    [AvailabilityStatus.UNAVAILABLE]: 2,
    [AvailabilityStatus.UNKNOWN]: 3,
  };

  @ViewChild('paginator')
  private paginator!: MatPaginator;

  @ViewChild('userListContent')
  public userListContent!: ElementRef<HTMLElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AvailableEventHostsDialogComponent>,
    private availabilityService: AvailabilityService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private dialog: MatDialog,
  ) {
    this.event = data.event;
  }

  ngAfterViewInit(): void {
    this.getAvailableTutors();
  }

  getAvailableTutors(): void {
    this.availabilityService
      .getAvailableTutors(this.event)
      .pipe(take(1))
      .subscribe({
        next: (res: GetAvailabilityResponse) => {
          this.dataSource = new MatTableDataSource(this.sortResults(res.users));
          this.dataSource.paginator = this.paginator;
        },
        complete: () => {
          this.isLoading = false;
          this.changeDetectorRef.detectChanges();
        },
      });
  }

  handleSearch(value: string): void {
    if (!this.dataSource) {
      return;
    }

    this.dataSource.filter = value.trim().toLowerCase();
  }

  close(): void {
    const hostsSet = new Set(this.hostsToAdd);
    const selectionContainsNonAvailableHosts = this.dataSource?.data.some(
      (userData) =>
        hostsSet.has(userData._id) && userData.availabilityStatus !== AvailabilityStatus.AVAILABLE,
    );
    if (selectionContainsNonAvailableHosts) {
      this.showConfirmationDialog(
        this.submitAndClose,
        `<div>${this.translateService.instant(
          'Some organizers on this event may',
        )} <strong>${this.translateService.instant(
          'not be available at this time',
        )}</strong>. ${this.translateService.instant('Are you sure you want to continue?')}</div>`,
        this.translateService.instant('Cancel'),
        this.translateService.instant('Done'),
      );
    } else {
      this.submitAndClose();
    }
  }

  submitAndClose = (): void => {
    this.dialogRef.close(this.hostsToAdd);
  };

  closeDialog = (): void => {
    this.dialogRef.close();
  };

  cancel(): void {
    if (this.hostsToAdd.length) {
      this.showConfirmationDialog(
        this.closeDialog,
        `<div>${this.translateService.instant('Are you sure you want to cancel?')}</div>`,
        this.translateService.instant('No'),
        this.translateService.instant('Yes'),
      );
    } else {
      this.closeDialog();
    }
  }

  selectHost(id: string): void {
    this.hostsToAdd.push(id);
  }

  removeHost(id: string): void {
    this.hostsToAdd = this.hostsToAdd.filter((hostId) => hostId !== id);
  }

  showConfirmationDialog(
    callback: () => unknown,
    body: string,
    cancelButtonText: string,
    confirmButtonText: string,
  ): void {
    const dialogRef = this.dialog.open(ConfirmationModalV2Component, {
      panelClass: 'user-confirmation-dialog',
      data: {
        title: this.translateService.instant('Are you sure'),
        body,
        cancelButtonText,
        confirmButtonText,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (res) {
          callback();
        }
      });
  }

  sortResults(availabilityResults: UserAvailabilityData[]): UserAvailabilityData[] {
    return availabilityResults.sort(
      (a, b) => this.sortingOrder[a.availabilityStatus!] - this.sortingOrder[b.availabilityStatus!],
    );
  }
}

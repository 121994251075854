import { SessionView } from 'src/app/services/session-shared-data.service';

export interface TilesContainerParameters {
  availableWidth: number;
  availableHeight: number;
  maxColsNum: number;
  maxRowsNum: number;
  aspectRatio: number;
  minTileWidth: number;
  minTileHeight: number;
  spaceBetweenTiles: number;
  anchorTopWithoutVerticallyCentered?: number;
  anchorLeftWithoutHorizontallyCentered?: number;
  // Used for mobile top view
  maxTileWidth?: number;
  // Used for right view tower
  videoControlsFirstDivHeight?: number;
  videoControlsSecondDivHeight?: number;
  skipSettingComponentDimsWidth?: boolean;
  // Used for resizable right tower
  isResized?: boolean;
  // Used for expanded view
  expandedTileWidth?: number;
  expandedTileHeight?: number;
  anchorTopExpanded?: number;
  anchorLeftExpanded?: number;
  anchorTopCarousel?: number;
}

export interface TilePositionParameters {
  currentTileWidth: number;
  currentTileHeight: number;
  currentColsNum: number;
  currentRowsNum: number;
  spaceBetweenTiles: number;
  anchorTop: number;
  anchorLeft: number;
  anchorLeftLastRow?: number;
  expandedTileWidth?: number;
  expandedTileHeight?: number;
  anchorTopExpanded?: number;
  anchorLeftExpanded?: number;
  navigationButtonsCoordinates: NavigationButtonsCoordinates;
}

export interface NavigationButtonsCoordinates {
  topLeftButton: number;
  leftLeftButton: number;
  topRightButton: number;
  leftRightButton: number;
}

// Gallery view constants
export const MARGIN_LEFT_RIGHT_GALLERY_VIEW = 8 / 100;
export const MARGIN_TOP_GALLERY_VIEW = 8;
export const MARGIN_BOTTOM_GALLERY_VIEW = 8;
export const GALLERY_VIEW_ASPECT_RATIO = 16 / 9;
export const GALLERY_VIEW_MIN_TILE_WIDTH_16_9 = 240;
export const GALLERY_VIEW_MIN_TILE_HEIGHT_16_9 = 135;
export const GALLERY_VIEW_SPACING_16_9 = 10;
export const GALLERY_VIEW_MIN_TILE_WIDTH_HEIGHT_1_1 = 150;
export const GALLERY_VIEW_SPACING_1_1 = 5;
export const GALLERY_VIEW_NAVIGATION_BUTTONS_HEIGHT = 46;
export const GALLERY_VIEW_NAVIGATION_BUTTONS_WIDTH = 46;
export const GALLERY_VIEW_NAVIGATION_BUTTONS_MARGIN = 16;
// Expanded view constants
export const MIN_TILE_HEIGHT_IN_CAROUSEL_EXPANDED = 135;
export const SPLIT_RATIO_EXPANDED = 3 / 4;
export const ASPECT_RATIO_EXPANDED = 16 / 9;
export const SPACE_BETWEEN_CAROUSEL_TILES = 10;
export const SPACE_BETWEEN_EXPANDED_AND_CAROUSEL = 10;
export const SPACE_BOTTOM_RIGHT_VIEW_TOWER = 100;
// Right view tower constants
export const RIGHT_VIEW_NAVIGATION_BUTTONS_HEIGHT = 40;
export const RIGHT_VIEW_MIN_TILE_HEIGHT = 120;
export const RIGHT_VIEW_HOST_MAX_NUMBER_OF_COLUMNS = 3;
export const RIGHT_VIEW_PARTICIPANT_MAX_NUMBER_OF_COLUMNS = 2;
export const RIGHT_VIEW_MAX_TILE_HEIGHT = 195;
export const RIGHT_VIEW_SPACING = 0;
export const RIGHT_VIEW_NAVIGATION_BUTTONS_MARGIN = 8;
export const RIGHT_VIEW_NAVIGATION_BUTTONS_GAP = 8;
// Resizable right tower constant
export const RESIZABLE_RIGHT_VIEW_MIN_WIDTH = 120;
export const RIGHT_VIEW_MAX_NUM_TILES_PER_PAGE = 16;
// Top view contants
export const TOP_VIEW_MIN_TILE_WIDTH = 130;
export const TOP_VIEW_MAX_TILE_WIDTH = 255;
export const TOP_VIEW_HEIGHT = 116;
export const TOP_VIEW_SPACING = 0;
export const TOP_VIEW_NAVIGATION_BUTTON_HEIGHT = 40;
export const TOP_VIEW_NAVIGATION_BUTTON_WIDTH = 36;
// Default constants that are used in case of getting CSS attributes failed in run-time
export const VIDEO_CONTROLS_HEIGHT_RIGHT_VIEW = 48;
export const MARGIN_TOP_RIGHT_VIEW = 62;
export const HEADER_HEIGHT_MOBILE = 46;
export const TOP_BAR_LEFT_SECTION_HEIGHT_MOBILE = 46;
export const TOP_NAV_BAR_HEIGHT_DESKTOP = 62;
export const BUTTONS_CONTAINER_HEIGHT = 60;
export const VIDEO_CONTROLS_HEIGHT_DESKTOP = 50;

export function calculateMaxNumRowsOrCols(
  availableSpace: number,
  minTileWidthOrHeight: number,
  spaceBetweenTiles: number,
): number {
  // the equation to find n in: availableSpace = n * minTileWidthOrHeight + (n - 1) * spaceBetweenTiles
  return Math.max(
    1,
    Math.floor((availableSpace + spaceBetweenTiles) / (minTileWidthOrHeight + spaceBetweenTiles)),
  );
}

export function getCurrentStyleFromHTMLElement(element: HTMLElement): any {
  return (element as any).currentStyle || window.getComputedStyle(element);
}

export function checkStyleValueIsValid(value: string): boolean {
  return value.includes('px');
}

export function extractNumberValueFromStyle(value: string): number {
  return Number(value.slice(0, -2));
}

export function calculateTotalWidthOrHeight(
  currentColsOrRowsNum: number,
  currentTileWidthOrHeight: number,
  spaceBetweenTiles: number,
): number {
  return (
    currentColsOrRowsNum * currentTileWidthOrHeight + (currentColsOrRowsNum - 1) * spaceBetweenTiles
  );
}

export function setTileParameters(
  tilesContainerParameters: TilesContainerParameters,
  numberOfTilesInCurrentPage: number,
  currentSessionView: SessionView,
  isMobileView: boolean,
): TilePositionParameters | undefined {
  switch (currentSessionView) {
    case SessionView.WHITEBOARD:
    case SessionView.GALLERY_VIEW:
    case SessionView.FULLSCREEN:
    case SessionView.FULLSCREEN_APP:
      return setupTileParametersTabularViews(
        tilesContainerParameters,
        numberOfTilesInCurrentPage,
        currentSessionView,
        isMobileView,
      );
    case SessionView.EXPANDED:
      return setupTileParametersExpanded(
        tilesContainerParameters,
        numberOfTilesInCurrentPage,
        currentSessionView,
        isMobileView,
      );
    default:
      return undefined;
  }
}

function setupTileParametersTabularViews(
  tilesContainerParameters: TilesContainerParameters,
  numberOfTilesInCurrentPage: number,
  currentSessionView: SessionView,
  isMobileView: boolean,
): TilePositionParameters {
  const availableWidth = tilesContainerParameters.availableWidth;
  const availableHeight = tilesContainerParameters.availableHeight;
  const maxColsNumWithSpace = tilesContainerParameters.maxColsNum;
  const maxRowsNumWithSpace = tilesContainerParameters.maxRowsNum;
  let aspectRatio =
    tilesContainerParameters.aspectRatio ??
    tilesContainerParameters.minTileWidth / tilesContainerParameters.minTileHeight;
  const spaceBetweenTiles = tilesContainerParameters.spaceBetweenTiles;
  const maxTileWidthTopView = tilesContainerParameters?.maxTileWidth;

  // Used to decide the actual cols number by knowing the current visible users to achieve squared tabular view as much as possible
  let currentColsNum = Math.min(
    Math.ceil(Math.sqrt(numberOfTilesInCurrentPage)),
    maxColsNumWithSpace,
  );

  // In case of the current cols number can't fit the maximum rows number
  // We will use height to decide current cols number
  if (Math.ceil(numberOfTilesInCurrentPage / currentColsNum) > maxRowsNumWithSpace) {
    currentColsNum = Math.min(
      Math.ceil(numberOfTilesInCurrentPage / maxRowsNumWithSpace),
      maxColsNumWithSpace,
    );
  }

  // To force aspect ratio 16/9 in case of gallery view if the current cols number is 1
  if (
    currentColsNum === 1 &&
    ![SessionView.WHITEBOARD, SessionView.FULLSCREEN_APP, SessionView.FULLSCREEN].includes(
      currentSessionView ?? SessionView.WHITEBOARD,
    )
  ) {
    aspectRatio = 16 / 9;
  }
  const currentRowsNum = Math.min(
    Math.ceil(numberOfTilesInCurrentPage / currentColsNum),
    maxRowsNumWithSpace,
  );

  let currentTileWidth = isTopView(currentSessionView, isMobileView)
    ? Math.min(
        (availableWidth - (currentColsNum - 1) * spaceBetweenTiles) / currentColsNum,
        maxTileWidthTopView ?? TOP_VIEW_MAX_TILE_WIDTH,
      )
    : (availableWidth - (currentColsNum - 1) * spaceBetweenTiles) / currentColsNum;
  let currentTileHeight = isTopView(currentSessionView, isMobileView)
    ? tilesContainerParameters.minTileHeight
    : currentTileWidth * (1 / aspectRatio);

  let totalTilesWidth = calculateTotalWidthOrHeight(
    currentColsNum,
    currentTileWidth,
    spaceBetweenTiles,
  );
  let totalTilesHeight = calculateTotalWidthOrHeight(
    currentRowsNum,
    currentTileHeight,
    spaceBetweenTiles,
  );

  // Handle if the proposed tiles height is greater than the actual available height
  // So, we will use the height to decide tile width, and height
  if (totalTilesHeight > availableHeight && !isTopView(currentSessionView, isMobileView)) {
    currentTileHeight =
      (availableHeight - (currentRowsNum - 1) * spaceBetweenTiles) / currentRowsNum;
    currentTileWidth = currentTileHeight * aspectRatio;

    totalTilesWidth = calculateTotalWidthOrHeight(
      currentColsNum,
      currentTileWidth,
      spaceBetweenTiles,
    );
    totalTilesHeight = calculateTotalWidthOrHeight(
      currentRowsNum,
      currentTileHeight,
      spaceBetweenTiles,
    );
  }

  let spaceToCenterHeight = 0;
  let spaceToCenterWidth = 0;

  // Only these values if the gallery (Gallery view, full screen view, expanded, also top view) is opened
  if (
    (currentSessionView !== SessionView.WHITEBOARD &&
      currentSessionView !== SessionView.FULLSCREEN_APP) ||
    isTopView(currentSessionView, isMobileView)
  ) {
    spaceToCenterHeight = Math.max((availableHeight - totalTilesHeight) / 2, 0);
    spaceToCenterWidth = Math.max((availableWidth - totalTilesWidth) / 2, 0);
  }

  const anchorTop =
    (tilesContainerParameters.anchorTopWithoutVerticallyCentered ?? 0) + spaceToCenterHeight;
  const anchorLeftWithoutHorizontallyCentered =
    tilesContainerParameters.anchorLeftWithoutHorizontallyCentered ?? 0;
  const anchorLeft = anchorLeftWithoutHorizontallyCentered + spaceToCenterWidth;

  // Only center the last row tiles if the gallery view is opened
  const anchorLeftLastRow = calculateAnchorLeftLastRow(
    anchorLeft,
    numberOfTilesInCurrentPage,
    availableWidth,
    currentTileWidth,
    currentColsNum,
    spaceBetweenTiles,
    anchorLeftWithoutHorizontallyCentered,
    currentSessionView,
    isMobileView,
  );

  const tilePositionParameters: TilePositionParameters = {
    currentTileWidth: currentTileWidth,
    currentTileHeight: currentTileHeight,
    currentColsNum: currentColsNum,
    currentRowsNum: currentRowsNum,
    spaceBetweenTiles: spaceBetweenTiles,
    anchorTop: anchorTop,
    anchorLeft: anchorLeft,
    anchorLeftLastRow: anchorLeftLastRow,
    navigationButtonsCoordinates: setupNavigationButtonsPosition(
      anchorTop,
      anchorLeft,
      totalTilesWidth,
      totalTilesHeight,
      currentSessionView,
      isMobileView,
    ),
  };
  return tilePositionParameters;
}

function calculateAnchorLeftLastRow(
  anchorLeft: number,
  numberOfTilesInCurrentPage: number,
  availableWidth: number,
  currentTileWidth: number,
  currentColsNum: number,
  spaceBetweenTiles: number,
  anchorLeftWithoutHorizontallyCentered: number,
  currentSessionView: SessionView,
  isMobileView: boolean,
): number {
  if (!isTopView(currentSessionView, isMobileView)) {
    const lastRowColsNum = numberOfTilesInCurrentPage % currentColsNum;
    if (lastRowColsNum > 0) {
      const exactAvailableWidth = isRightView(currentSessionView, isMobileView)
        ? currentColsNum * currentTileWidth
        : availableWidth;
      return (
        (exactAvailableWidth -
          (lastRowColsNum * currentTileWidth + (lastRowColsNum - 1) * spaceBetweenTiles)) /
          2 +
        anchorLeftWithoutHorizontallyCentered
      );
    }
  }
  return anchorLeft;
}

function setupTileParametersExpanded(
  tilesContainerParameters: TilesContainerParameters,
  numberOfTilesInCurrentPage: number,
  currentSessionView: SessionView,
  isMobileView: boolean,
): TilePositionParameters {
  const availableWidth = tilesContainerParameters.availableWidth;
  const carouselTileWidth = tilesContainerParameters.minTileWidth;
  const carouselTileHeight = tilesContainerParameters.minTileHeight;
  const spaceBetweenCarouselTiles = tilesContainerParameters.spaceBetweenTiles;

  const totalCarouselWidth = calculateTotalWidthOrHeight(
    numberOfTilesInCurrentPage,
    carouselTileWidth,
    spaceBetweenCarouselTiles,
  );

  const spaceToCenterCarousel = Math.max((availableWidth - totalCarouselWidth) / 2, 0);

  const anchorTopCarousel = tilesContainerParameters.anchorTopCarousel ?? 0;
  const anchorLeftCarousel =
    (tilesContainerParameters.anchorLeftWithoutHorizontallyCentered ?? 0) + spaceToCenterCarousel;

  const tilePositionParameters: TilePositionParameters = {
    currentTileWidth: carouselTileWidth,
    currentTileHeight: carouselTileHeight,
    currentColsNum: numberOfTilesInCurrentPage,
    currentRowsNum: 1,
    spaceBetweenTiles: spaceBetweenCarouselTiles,
    anchorTop: anchorTopCarousel,
    anchorLeft: anchorLeftCarousel,
    expandedTileWidth: tilesContainerParameters.expandedTileWidth,
    expandedTileHeight: tilesContainerParameters.expandedTileHeight,
    anchorTopExpanded: tilesContainerParameters.anchorTopExpanded,
    anchorLeftExpanded: tilesContainerParameters.anchorLeftExpanded,
    navigationButtonsCoordinates: setupNavigationButtonsPosition(
      anchorTopCarousel,
      anchorLeftCarousel,
      totalCarouselWidth,
      carouselTileHeight,
      currentSessionView,
      isMobileView,
    ),
  };
  return tilePositionParameters;
}

// Main goal here find the start point for each navigation button
// 1. Be at the middle of the height
// 2. How far from the tiles is determined by which view the user is
function setupNavigationButtonsPosition(
  anchorTop: number,
  anchorLeft: number,
  totalTilesWidth: number,
  totalTilesHeight: number,
  currentSessionView: SessionView,
  isMobileView: boolean,
): NavigationButtonsCoordinates {
  let topLeftButton = 0;
  let leftLeftButton = 0;
  let topRightButton = 0;
  let leftRightButton = 0;
  if (isTopView(currentSessionView, isMobileView)) {
    topLeftButton = anchorTop + totalTilesHeight / 2 - TOP_VIEW_NAVIGATION_BUTTON_HEIGHT / 2;
    leftLeftButton = anchorLeft;
    topRightButton = topLeftButton;
    leftRightButton = anchorLeft + totalTilesWidth - TOP_VIEW_NAVIGATION_BUTTON_WIDTH;
  } else if (isRightView(currentSessionView, isMobileView)) {
    topLeftButton = totalTilesHeight + RIGHT_VIEW_NAVIGATION_BUTTONS_MARGIN;
    leftLeftButton = RIGHT_VIEW_NAVIGATION_BUTTONS_MARGIN;
    topRightButton = topLeftButton;
    leftRightButton =
      totalTilesWidth -
      (totalTilesWidth / 2 -
        RIGHT_VIEW_NAVIGATION_BUTTONS_MARGIN -
        RIGHT_VIEW_NAVIGATION_BUTTONS_GAP / 2) -
      8;
  } else {
    topLeftButton = anchorTop + totalTilesHeight / 2 - GALLERY_VIEW_NAVIGATION_BUTTONS_HEIGHT / 2;
    leftLeftButton =
      anchorLeft - GALLERY_VIEW_NAVIGATION_BUTTONS_WIDTH - GALLERY_VIEW_NAVIGATION_BUTTONS_MARGIN;
    topRightButton = topLeftButton;
    leftRightButton = anchorLeft + totalTilesWidth + GALLERY_VIEW_NAVIGATION_BUTTONS_MARGIN;
  }
  return {
    topLeftButton: topLeftButton,
    leftLeftButton: leftLeftButton,
    topRightButton: topRightButton,
    leftRightButton: leftRightButton,
  };
}

function isTopView(currentSessionView: SessionView, isMobileView: boolean): boolean {
  return (
    (currentSessionView === SessionView.WHITEBOARD ||
      currentSessionView === SessionView.FULLSCREEN_APP) &&
    isMobileView
  );
}

export function isRightView(currentSessionView: SessionView, isMobileView: boolean): boolean {
  return (
    (currentSessionView === SessionView.WHITEBOARD ||
      currentSessionView === SessionView.FULLSCREEN_APP) &&
    !isMobileView
  );
}

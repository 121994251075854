<i
  style="cursor: pointer"
  [ngClass]="{
    likeButton: true,
    'material-icons': likedState,
    selected: likedState,
    'material-icons-outlined': !likedState
  }"
  (click)="onClick()"
  [appTooltip]="likedState ? ('Unlike' | translate) : ('Like this' | translate)"
  appTooltipArrow="bottom"
  >thumb_up</i
>
<span *ngIf="numLikes > 0" class="badge align-top">{{ numLikes }}</span>

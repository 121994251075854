import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, takeUntil } from 'rxjs';
import { deepCopy } from 'src/app/content/files/utils/common';
import { modifiedTimer } from 'src/app/utilities/ZoneUtils';
import { IconTypes } from 'src/app/standalones/components/pencil-icon/pencil-icon.component';

export interface SpeedbumpModalData {
  title: string;
  body: { header: string; main: string };
  controls: { icon: string; text: string; isSvg?: boolean }[];
  bodyHeaderTimeoutSeconds?: number;
}
@UntilDestroy()
@Component({
  selector: 'app-speedbump-modal',
  templateUrl: './speedbump-modal.component.html',
  styleUrls: ['./speedbump-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpeedbumpModalComponent {
  public _bodyHeader$ = new Subject<string>();
  dialogInputData: SpeedbumpModalData;
  constructor(
    private dialogRef: MatDialogRef<SpeedbumpModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: SpeedbumpModalData,
  ) {
    this.dialogInputData = deepCopy(data);
    if (this.dialogInputData.bodyHeaderTimeoutSeconds) {
      this.handleBodyHasTimedValues(this.dialogInputData.bodyHeaderTimeoutSeconds);
    }
  }

  close(option: boolean) {
    this.dialogRef.close(option);
  }

  private handleBodyHasTimedValues(timeout) {
    const timeoutAfter$ = modifiedTimer((timeout - 1) * 1000);
    let bodyHeader = this.dialogInputData.body.header;
    modifiedTimer(0, 1000)
      .pipe(untilDestroyed(this), takeUntil(timeoutAfter$))
      .subscribe(() => {
        timeout--;
        bodyHeader = bodyHeader.replace(/\d+/g, String(timeout));
        this._bodyHeader$.next(bodyHeader);
      });
  }

  protected IconTypes = IconTypes;
}

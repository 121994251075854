import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

export interface DropDownOption {
  text: string;
  onClick: () => void;
}

@Component({
  selector: 'app-drop-down-button',
  templateUrl: './drop-down-button.component.html',
  styleUrls: ['./drop-down-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatMenuModule, MatIconModule], // TODO: Migrate to pncl-button when merged @yosra
})
export class DropDownButtonComponent {
  isMenuOpened = false;
  @Input() items: DropDownOption[] = [];
  @Input() buttonText: string = '';
  @Input() dropDownTitle: string = '';
  @Input() onClick = () => {};

  @Output() primaryButtonClick = new EventEmitter<void>();
  @Output() menuItemButtonClick = new EventEmitter<void>();

  onPrimaryButtonClick() {
    this.onClick();
    this.primaryButtonClick.emit();
  }

  onMenuItemClick(option: DropDownOption) {
    option.onClick();
    this.menuItemButtonClick.emit();
  }
}

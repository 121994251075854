import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DateRange } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dateRangePickerSelectionNoLabel',
})
export class DateRangePickerSelectionNoLabelPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(
    datepickerRange: DateRange<Date | null> | undefined,
    format: string = 'MMMM DD, YYYY',
  ): string {
    const defaultErrorText = this.translateService.instant('Select Date');
    try {
      if (!datepickerRange) {
        return defaultErrorText;
      }
      const startDate = moment(datepickerRange.start);
      const endDate = moment(datepickerRange.end);

      if (startDate.isSame(endDate, 'day')) {
        return moment(datepickerRange.start).format(format);
      }
      return `${startDate.format(format)} - ${endDate.format(format)}`;
    } catch (error) {
      return defaultErrorText;
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { PermissionStatus } from '../consts';

@Pipe({
  name: 'checkIndeterminatePermission',
})
export class CheckIndeterminatePermissionPipe implements PipeTransform {
  transform(permissionObj, selectedPermission: string): PermissionStatus {
    if (typeof permissionObj[selectedPermission] === 'boolean') {
      return permissionObj[selectedPermission]
        ? PermissionStatus.ENABLED_FOR_ALL
        : PermissionStatus.DISABLED_FOR_ALL;
    }
    return PermissionStatus.PARTIAL_ENABLED;
  }
}

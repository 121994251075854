<div class="dialog-link-container">
  <div class="title-container">
    <h5 mat-dialog-title>{{ 'Link your Account' | translate }}</h5>
    <button mat-icon-button class="close-icon" (click)="closeDialog()" aria-label="Close">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="content" mat-dialog-content>
    <p>
      The email associated to <strong>{{ data.newProvider }}</strong> is associated to an existing
      account.<br />
      You can Link your <strong>{{ data.newProvider }}</strong> account with
      <strong>{{ data.oldProvider }}</strong> account<br />
      After Linking, you will be able to sign in using your <strong>{{ data.newProvider }}</strong
      ><br />
      To Link, You will have to login first using <strong>{{ data.oldProvider }}</strong>
    </p>
  </div>
  <div class="buttons" mat-dialog-actions>
    <button class="cancel-button" mat-raised-button (click)="closeDialog()">
      {{ 'Cancel' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="link()">
      {{ 'Login and Link' | translate }}
    </button>
  </div>
</div>

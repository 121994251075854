<span>
  <app-profile-photo
    [user]="user"
    [isClickEnabled]="isClickEnabled"
    [showName]="showName"
    [fontSize]="fontSize"
    [width]="width"
    [showYouWord]="showYouWord"
  ></app-profile-photo>

  <svg-icon
    *ngIf="enableInactivityIndicator"
    [hidden]="!foregroundInactivityObservable || (foregroundInactivityObservable | async) !== true"
    [ngClass]="{ 'svg-small': small, 'with-name': showName }"
    [icon]="'inactive_indicator'"
    [appTooltipDisabled]="!enableTooltips || (tooltip$ | async) === ''"
    [appTooltip]="tooltip$ | async"
    [appTooltipPlacement]="'bottom-center'"
    [appTooltipArrow]="'top'"
  ></svg-icon>
</span>

<div id="app-switcher">
  <div role="button" class="app pencil isSelected" *ngIf="!user?.institution">
    <svg-icon icon="PencilLogo"></svg-icon>
  </div>
  <div role="button" class="app isSelected" *ngIf="user?.institution">
    <div class="initials" *ngIf="!user?.institution?.logo">
      {{ user?.institution?.name[0] | uppercase }}
    </div>
    <div class="picture" *ngIf="user?.institution?.logo">
      <img [src]="user?.institution.logo" [alt]="user?.institution?.name" />
    </div>
  </div>
</div>

<div
  class="filter-panel"
  [class]="(isRtl$ | async) ? 'border-start' : 'border-end'"
  *ngIf="filterPanelOpen"
>
  <div
    class="
      file-nav
      top-section
      border-bottom
      d-flex
      align-items-center
      justify-content-between
      w-100
    "
  >
    <div class="float-left">
      <mat-icon class="align-middle user-select-none"> tune </mat-icon>
      Filters
    </div>
    <button class="float-right" mat-icon-button (click)="filterPanelOpen = !filterPanelOpen">
      <mat-icon> close </mat-icon>
    </button>
  </div>
  <ng-container *ngFor="let filterSet of fileFilterService.filterSets">
    <ng-container
      *ngIf="
        filterSet.queryParam !== fileFilterService.enforcingFilter &&
        filterSet.name !== filterTypes.Topic
      "
    >
      <app-filter
        *ngIf="filterSet.name !== filterTypes.Course"
        [filterSet]="filterSet"
        [searchbar]="true"
        [sort]="true"
        [checkbox]="true"
        [showCourses]="this.enforcingFilter !== ResourceFilterParamsEnum.Course"
        [filtersUpdated]="filtersUpdated"
        (toggleFilter)="
          fileFilterService.toggleFilter($event.filter, filterSet, $event.resetView); resetView()
        "
      ></app-filter>
      <app-filter
        *ngIf="filterSet.name === filterTypes.Course"
        [filterSet]="filterSet"
        [dropdown]="true"
        [filtersUpdated]="filtersUpdated"
        [panelOpenState]="highlightingCourseFilter"
        [placeholderForDropdown]="'All Courses'"
        (toggleFilter)="handleToggleFilter($event, filterSet)"
      ></app-filter>
    </ng-container>
  </ng-container>
</div>
<div
  class="syllabus-section border-end float-left flex-1 position-relative"
  *ngIf="topicFilterPanelOpen"
>
  <app-topics-list
    data-intercom-target="Filter by Topics"
    class="d-block"
    [mode]="'filter'"
    [syllabusCode]="
      fileFilterService.selectedCourse?.syllabus_code?.length
        ? fileFilterService.selectedCourse?.syllabus_code
        : ''
    "
    [fromQuestionList]="true"
    [selectedTopicIds]="fileFilterService.topicsSelectionIDs"
    [showMessageForNoCourse]="true"
    [dependOnlyOnSyllabusCode]="true"
    [enableLoading]="true"
    (closed)="toggleTopicFilterPanel()"
    (selectedIdsChanged)="fileFilterService.addTopicFilter($event); resetView()"
    (topicsChanged)="fileFilterService.setTopics($event)"
  ></app-topics-list>
</div>
<div
  class="container-fluid d-inline-block float-right files-container"
  style="padding: 0"
  [ngClass]="{
    'open-panel': filterPanelOpen != topicFilterPanelOpen,
    'open-panels': filterPanelOpen && topicFilterPanelOpen,
    'file-summary-open': showFileSummary
  }"
>
  <div
    class="
      file-nav
      top-section
      border-bottom
      d-flex
      align-items-center
      justify-content-between
      w-100
    "
    [style.background-color]="selectedFiles?.length > 0 ? '#F0F4FF' : '#F8F8F8'"
  >
    <div class="d-flex justify-content-start">
      <button
        mat-icon-button
        *ngIf="!filterPanelOpen"
        class="pcl-files-tune-filter"
        (click)="filterPanelOpen = !filterPanelOpen"
      >
        <mat-icon> tune </mat-icon>
      </button>
      <div *ngIf="!topicFilterPanelOpen && !filterPanelOpen" class="divider"></div>
      <button
        mat-icon-button
        *ngIf="!topicFilterPanelOpen"
        class="pcl-files-list-alt"
        (click)="toggleTopicFilterPanel()"
      >
        <mat-icon> list_alt </mat-icon>
      </button>
      <app-search-bar
        class="me-1 search-bar"
        [searchTitle]="'Search Files' | translate"
        (filter)="setSearchValue($event)"
        [editSearchValue]="fileFilterService.search"
      >
      </app-search-bar>
      <mat-checkbox
        class="ms-1 selected-text"
        style="font-style: italic !important"
        *ngIf="selectedFiles?.length || selectedFolders?.length"
        [checked]="allSelected"
        [indeterminate]="(selectedFiles?.length || selectedFolders?.length) && !allSelected"
        (change)="toggleAll(false)"
        >{{ selectedFiles.length + selectedFolders.length }}
        {{ selectedFiles.length + selectedFolders.length === 1 ? 'item' : 'items' }}
        selected</mat-checkbox
      >
      <span *ngIf="(filesLoading$ | async) === false && activeFolder" class="searchResult">
        {{
          activeFolder.resources.total + activeFolder.total === 0
            ? 'No'
            : activeFolder.resources.total + activeFolder.total
        }}
        results found
      </span>
    </div>
    <div class="d-flex justify-content-end">
      <button
        mat-icon-button
        class="
          btn-header
          d-flex
          justify-content-center
          align-items-center
          cpr-8
          pcl-files-preview-selection
        "
        *ngIf="selectedFiles?.length > 0"
        (click)="fileList.openPreview()"
        [disabled]="selectedFiles.length === 0"
      >
        <i class="material-icons-outlined icon cpr-8">visibility</i>
        <span class="text">{{ 'Preview Selection' | translate }}</span>
      </button>
      <button
        mat-icon-button
        [matMenuTriggerFor]="addTags"
        *ngIf="selectedFiles.length > 0 || selectedFolders.length > 0"
      >
        <i class="material-icons-outlined">label</i>
      </button>
      <mat-menu #addTags="matMenu" closed="new">
        <app-tag-selection-menu
          #tagMenu
          [files]="selectedFiles"
          [folders]="selectedFolders"
          [courses]="user?.courses ?? []"
          [user]="user"
          [enforcingCourse]="enforcingFilter === ResourceFilterParamsEnum.Course"
          [currentCourse]="selectedCourse"
        ></app-tag-selection-menu>
      </mat-menu>
      <button
        mat-icon-button
        *ngIf="selectedFiles?.length > 0 || selectedFolders.length > 0"
        (click)="fileList?.deleteSelected()"
        [disabled]="
          selectedFiles.length + selectedFolders.length === 0 ||
          !(user | has: Features.delete_resource)
        "
      >
        <i
          [ngbTooltip]="
            (user | has: Features.delete_resource)
              ? ('Delete Selection' | translate)
              : ('Your role does not allow this operation' | translate)
          "
          class="material-icons-outlined"
          >delete</i
        >
      </button>
      <!--Hiding until we get mocks-->
      <button mat-icon-button class="btn-close" *ngIf="false">
        <i class="material-icons-outlined" [matMenuTriggerFor]="visibilityMenu"> visibility </i>
      </button>
      <button mat-icon-button (click)="switchView()" *ngIf="view === views.LIST">
        <i class="material-icons-outlined">grid_on</i>
      </button>
      <button
        mat-icon-button
        class="pcl-files-sort-filter"
        (click)="switchView()"
        *ngIf="view === views.GRID"
      >
        <i class="material-icons-outlined">sort</i>
      </button>
      <button mat-icon-button class="pcl-create-new-folder">
        <i
          class="material-icons-outlined"
          [matMenuTriggerFor]="folderMenu"
          [class.active]="isMenuOpened"
          (menuOpened)="isMenuOpened = true"
          (menuClosed)="isMenuOpened = false"
          >create_new_folder</i
        >
      </button>
      <button mat-icon-button (click)="showFileSummary = !showFileSummary">
        <i class="material-icons-outlined"> list_alt </i>
      </button>
    </div>
  </div>
  <div
    *ngIf="activeFolder && activeFolder.parentFolder && (filesLoading$ | async) === false"
    class="folder-breadcrumb d-flex flex-row cmt-8 cmb-8"
  >
    <ng-container *ngIf="activeFolder.parentFolder as parent">
      <div *ngIf="parent.name !== institutionId">
        <span class="material-icons"> more_horiz </span>
        <i class="material-icons">chevron_right</i>
      </div>
      <div class="folder" (click)="openFolder(parent._id)">
        {{ parent.name === institutionId ? ('Pencil Cloud' | translate) : parent.name }}
      </div>
      <i class="material-icons">chevron_right</i>
      <div class="active">
        {{ activeFolder.name === institutionId ? ('Pencil Cloud' | translate) : activeFolder.name }}
      </div>
    </ng-container>
  </div>
  <!-- Applying Filters -->
  <div
    *ngIf="
      fileFilterService.appliedFilters > 1 ||
      (fileFilterService.appliedFilters && !fileFilterService.search)
    "
    class="filter-border"
  >
    <span class="cmr-4 filterHeader">
      {{ 'Filters' | translate }} ({{ fileFilterService.appliedFilters }}):
    </span>
    <div *ngFor="let filterSet of fileFilterService.filterSets" class="d-inline-block align-top">
      <div
        *ngIf="filterSet.queryParam !== enforcingFilter && filterSet.hasActiveFilter()"
        class="filter-pills"
      >
        <div *ngFor="let filter of filterSet.filters; let i = index">
          <app-pill
            *ngIf="filter.value"
            [text]="filter | displayFilter"
            [type]="filterSet.name"
            [subtext]="
              filterSet.name !== filterTypes.CourseTags || !enforcingFilter
                ? filter.course?.name
                : undefined
            "
            [icon]="filterSet.icon"
            (close)="fileFilterService.removeFilter(filter, filterSet, i); resetView()"
          ></app-pill>
        </div>
      </div>
    </div>
    <button class="clear-all-button" (click)="fileFilterService.clearAllFilters(); resetView()">
      <i class="material-icons-outlined mx-1">delete</i>
      <span class="align-middle me-1">Clear All</span>
    </button>
  </div>
  <div
    *ngIf="!currentFolders?.length && !currentFiles?.length && (filesLoading$ | async) === false"
    class="no-res-background"
  >
    <!-- Any search/filter applied, then show different message-->
    <ng-container *ngIf="fileFilterService.appliedFilters || fileFilterService.search?.length">
      {{ 'No matching items found' | translate }} <br />
      <span class="sub-message"
        ><small>{{ 'Please review your filter settings' | translate }}</small></span
      >
    </ng-container>
    <!--No search/filter applied -->
    <ng-container *ngIf="!fileFilterService.appliedFilters && !fileFilterService.search.length"
      >{{ 'Click “Upload” to start adding files to this folder.' | translate }}<br />
      <button
        mat-flat-button
        class="btn-practice cmr-16 d-sm-inline-block sub-message pcl-files-file-upload"
        color="primary"
        (click)="resourceService.createResourceIntent.next(true)"
        [disabled]="!((userService.user | async)?.user | has: Features.upload_resource)"
      >
        <span class="material-icons">file_upload</span>
        <span>{{ 'Upload' | translate }}</span>
      </button>
    </ng-container>
  </div>
  <mat-menu #visibilityMenu="matMenu" class="m-2">
    <app-visibility-slider
      [visibility]="visibility"
      [multiSelect]="false"
      [deselectable]="true"
      (selectedEmitter)="visibilitySelected($event)"
    ></app-visibility-slider>
  </mat-menu>
  <mat-menu #folderMenu="matMenu" class="folder-dropdown">
    <button
      mat-menu-item
      class="dropdown-row d-flex align-items-center pcl-create-folder-from-selection"
      (click)="createFolderDialog()"
    >
      <i class="material-icons-outlined dropdown-list-icon cpr-8">add</i>
      {{
        (this.selectedFiles.length > 0 || this.selectedFolders.length > 0
          ? 'Create folder from selection'
          : 'Create folder'
        ) | translate
      }}
    </button>
    <button
      mat-menu-item
      class="dropdown-row d-flex align-items-center pcl-move-folder-to-selection"
      *ngIf="selectedFiles?.length !== 0 || selectedFolders?.length !== 0"
      (click)="showMoveToFolderDialog()"
    >
      <i class="material-icons-outlined dropdown-list-icon cpr-8">arrow_forward</i>
      {{ 'Move selection to folder' | translate }}
    </button>
  </mat-menu>

  <app-file-grid
    *ngIf="view === views.GRID"
    #filesList
    [activeFolder]="activeFolder"
    [courseLookup]="fileFilterService.courseLookup"
    [draggingWithoutSelect]="true"
    [enforcingFilter]="enforcingFilter"
    [isCreatingFolder]="false"
    [canSelect]="true"
    [maxNameLength]="maxNameLength"
    [maxCourseNameLength]="maxCourseNameLength"
    [user]="user"
    [loading]="(filesLoading$ | async) && !scrolling"
    (toggleSelectionEmitter)="handleToggleSelection()"
    (openFolder)="openFolder($event)"
    (scroll)="onScrollDown()"
    (snackbar)="showSnackbar($event)"
    (updateCache)="updateCacheFor($event.folderId, $event.resources, $event.folders)"
    (deletedResources)="fileSummary?.updateFileSummary($event, true)"
  >
  </app-file-grid>

  <app-file-list
    *ngIf="view === views.LIST"
    #filesList
    [activeFolder]="activeFolder"
    [courseLookup]="fileFilterService.courseLookup"
    [draggingWithoutSelect]="true"
    [enforcingFilter]="enforcingFilter"
    [isCreatingFolder]="false"
    [canSelect]="true"
    [maxNameLength]="maxNameLength"
    [maxCourseNameLength]="maxCourseNameLength"
    [user]="user"
    [loading]="(filesLoading$ | async) && !scrolling"
    (toggleSelectionEmitter)="handleToggleSelection()"
    (openFolder)="openFolder($event)"
    (scroll)="onScrollDown()"
    (snackbar)="showSnackbar($event)"
    (updateCache)="updateCacheFor($event.folderId, $event.resources, $event.folders)"
    (deletedResources)="fileSummary?.updateFileSummary($event, true)"
  >
  </app-file-list>

  <app-content-loader
    *ngIf="filesLoading$ | async"
    [blockCount]="25"
    [file]="view === views.GRID"
    [tags]="view === views.GRID"
    [singleLineList]="view === views.LIST"
    [border]="false"
    [list]="view === views.LIST"
  >
  </app-content-loader>
</div>
<app-rhs-details
  #fileSummary
  *ngIf="showFileSummary"
  [isDetailOpened]="showFileSummary"
  [fileSummary]="true"
  [isLandingPage]="false"
  [user]="user"
  (openDetails)="openFileSummary()"
  [class.isOpened]="showFileSummary"
>
</app-rhs-details>
<div class="file-upload-container" *ngIf="isUploading">
  <div class="file-upload-header">Uploading {{ uploadFiles.length }} files</div>
  <div class="file-upload-subheader">
    {{ actionText }}
    <button
      class="float-right mat-flat-button text-muted"
      (click)="onCancel()"
      [disabled]="finalizedFiles === uploadFiles.length"
    >
      Cancel
    </button>
  </div>
  <div class="file-upload-tasks">
    <div class="w-100" *ngFor="let ufile of uploadFiles">
      <app-upload-task
        #uploadTask
        [file]="ufile"
        (uploadDone)="saveResource($event)"
      ></app-upload-task>
    </div>
  </div>
</div>

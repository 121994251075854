import { Pipe, PipeTransform } from '@angular/core';
import { Site } from '../models/user';

@Pipe({
  name: 'SiteFromSiteId',
})
export class SiteFromSiteId implements PipeTransform {
  transform(siteId?: string, sites?: Site[]): Site | undefined {
    return sites?.find((site) => site._id === siteId);
  }
}

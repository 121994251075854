<div
  [class.selected]="selected"
  (click)="selectConversationPreview.emit()"
  class="conversation-container border-bottom border-right"
>
  <app-profile-photo
    #userPhoto
    *ngIf="isUserPreview(conversationPreview); else groupPhoto"
    [user]="conversationPreview.userInfo"
    [fontSize]="18"
    [width]="38"
    class="cmr-16"
  ></app-profile-photo>
  <ng-template #groupPhoto>
    <div class="group-photo-placeholder">
      <i class="material-icons">group</i>
    </div>
  </ng-template>
  <div class="text-content">
    <p class="mainText1 name">
      <!-- Can't use else clause with ngTemplate because preview type doesn't
      get narrowed -->
      <ng-container #userName *ngIf="isUserPreview(conversationPreview)">
        {{ conversationPreview.userInfo.name }}
      </ng-container>
      <ng-container #groupName *ngIf="!isUserPreview(conversationPreview)">
        {{ conversationGroupName }}
      </ng-container>
    </p>
    <ng-container #message *ngIf="!isDraftPreview(conversationPreview); else draft">
      <p
        #messageDeleted
        *ngIf="conversationPreview.message.deleted; else messagePreview"
        class="message-preview mainText2"
      >
        {{ 'This message was deleted.' | translate }}
      </p>
      <ng-template #messagePreview>
        <div
          [ngSwitch]="conversationPreview.message | messagePreviewType"
          class="position-relative"
        >
          <ui-fragment
            *ngSwitchCase="MessagePreviewType.Text"
            [fragment]="conversationPreview.message.content[0]"
            [replaceEquations]="true"
            class="message-preview mainText2 ugcFont"
          >
          </ui-fragment>
          <p *ngSwitchCase="MessagePreviewType.Equation" class="message-preview mainText2">
            {{ (conversationPreview | messageAuthor) + ' sent an equation.' | translate }}
          </p>
          <p *ngSwitchCase="MessagePreviewType.Attachment" class="message-preview mainText2">
            {{ (conversationPreview | messageAuthor) + ' sent an attachment.' | translate }}
          </p>
          <p *ngSwitchCase="MessagePreviewType.CreateGroup" class="message-preview mainText2">
            {{ (conversationPreview | messageAuthor) + ' created the group.' | translate }}
          </p>
          <p *ngSwitchCase="MessagePreviewType.None" class="message-preview mainText2">
            {{ 'No preview available.' | translate }}
          </p>
          <div
            #messagePreviewFade
            class="message-preview-fade"
            [class.selected-fade]="selected"
          ></div>
        </div>
      </ng-template>
      <p class="mainText5 message-date">
        {{ conversationPreview.message.createdAt | relativeDate | async }}
      </p>
    </ng-container>
    <ng-template #draft>
      <p class="mainText2 draft-preview">{{ 'New message' | translate }}</p>
    </ng-template>
  </div>
  <svg-icon class="svg-icon" *ngIf="selected" [icon]="'expanded_list_item'"></svg-icon>
</div>

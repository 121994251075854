<div class="pdf-preview-selector-container">
  <!--  Header: Selected Page count and selection buttons-->
  <div class="pdf-preview-selector-header">
    <p class="pdf-preview-selector-header-text">
      {{ selectedPagesCount }} of {{ pageItems.length }} selected
    </p>
    <div class="pdf-preview-selector-header-spacer"></div>
    <app-pencil-button
      class="pencil-button small rounded light pdf-preview-selector-header-button"
      [label]="'Select All' | translate"
      [disabled]="selectedPagesCount === pageItems.length"
      (clickEvent)="selectAll()"
      data-name="upload-pdf-select-all"
    >
    </app-pencil-button>
    <div style="width: 8px"></div>
    <app-pencil-button
      class="pencil-button small rounded light pdf-preview-selector-header-button"
      [label]="'Deselect All' | translate"
      [disabled]="selectedPagesCount === 0"
      (clickEvent)="deselectAll()"
      data-name="upload-pdf-deselect-all"
    >
    </app-pencil-button>
  </div>
  <mat-divider class="pdf-preview-selector-header-divider"></mat-divider>

  <div class="pdf-preview-selector-body">
    <p class="pdf-preview-selector-filename-text">{{ fileName }}</p>

    <!--  Pages Grid and selection-->
    <!--    TODO: Consider cdk-virtual-scroll-->
    <div class="pdf-preview-selector-grid">
      <app-preview-page-item
        *ngFor="let item of pageItems; index as i"
        [pageNumber]="i + 1"
        [page]="item.pdfPage"
        [isSelected]="item.isSelected"
        (toggleSelection)="toggleItemSelection(i)"
        [style.margin-right.px]="i % 2 === 0 ? 16 : 0"
      >
      </app-preview-page-item>
    </div>
  </div>

  <div class="upload-file-bottom-bar">
    <div style="flex-grow: 1"></div>
    <app-pencil-button
      class="pencil-button bottom-bar-button bottom-bar-button-bordered light medium"
      [label]="'Cancel' | translate"
      [icon]="'close'"
      [iconConfig]="{ type: IconTypes.matIcon }"
      (clickEvent)="onCancelClick()"
      data-name="upload-pdf-cancel"
    >
    </app-pencil-button>
    <div style="width: 8px"></div>
    <app-pencil-button
      class="pencil-button bottom-bar-button primary medium"
      [label]="'Next' | translate"
      [icon]="'arrow_forward'"
      [iconConfig]="{ type: IconTypes.matIcon }"
      [disabled]="selectedPagesCount < 1"
      (clickEvent)="onNextClick()"
      data-name="upload-pdf-next"
    >
    </app-pencil-button>
  </div>
</div>
